import React, { useEffect, useState } from "react";
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Toster from '../../../Component/Toster';
import Loder from '../../../Component/Loader';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from "../../../assets/images/icons/clear.svg";
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import updateIcon from '../../../assets/images/icons/updateIcon.svg';
// import editBtnIcon from '../../assets/images/icons/edit.svg';
import editBtnIcon from '../../../assets/images/icons/edit.svg';

import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import Heading from "../../../Component/Heading";
import BoxContainer from "../../../Component/BoxContainer";
import TableContainer from "../../../Component/TableContainer";
import GetCompanyMaster from "../../API/GetCompanyMaster";
import PostCompanyMaster from "../../API/PostCompanyMaster";
import GetCityMaster from "../../API/GetCityMaster";
import GetStateMaster from "../../API/GetStateMaster";
import GetCountryMaster from "../../API/GetCountryMaster";
import DeleteCompanyMaster from "../../API/DeleteCompanyMaster";
import PutCompanyMaster from "../../API/PutCompanyMaster";

export default function CompanyMaster() {
    const { t } = useTranslation();
    const [showUnderProcess, setShowUnderProcess] = useState(0);
    const [showToster, setShowToster] = useState(0);
    const [tosterMessage, setTosterMessage] = useState("");
    const [tosterValue, setTosterValue] = useState(0);
    const [showLoder, setShowLoder] = useState(0);
    const [rowID, setRowID] = useState(0);
    const [isUpdateBtnShow, setIsUpdateBtnShow] = useState(false);
    let [selectReadBy, setselectReadBy] = useState([]);
    const [EnergyReadingTable, setEnergyReadingTable] = useState([])
    const [IsDisable, setIsDisable] = useState(false)
    const [EnterreadingPoint, setEnterreadingPoint] = useState('')
    // const [entercompanyName, setEntercompanyName] = useState(null)
    const [entercompanyLogo, setEntercompanyLogo] = useState('')
    const [enterCompanyContact, setEnterCompanyContact] = useState('')
    const [enterCompanyAddress, setEnterCompanyAddress] = useState('')
    const [enterCompanyEmail, setEnterCompanyEmail] = useState('')
    const [EnterReadBy, setEnterReadBy] = useState(null)
    const [ReadingDate, setReadingDate] = useState("")
    const [EnergyMeterTable, setEnergyMeterTable] = useState([])
    const [newlyAddedRowIndex, setNewlyAddedRowIndex] = useState(null);
    const [editRowIndex, setEditRowIndex] = useState(null);
    const [isNewRowAdded, setIsNewRowAdded] = useState(false);
    const [isClearable,] = useState(true);
    const [isSearchable,] = useState(true);
    const [companyName,setCompanyName] = useState('');
    const [companylogo,setCompanylogo] = useState("");
    const [companycontact,setCompanycontact] = useState("");
    const [companyaddress,setCompanyaddress] = useState("");
    const [companyemail,setCompanyemail] = useState("");
    const [remark,setRemark] = useState("");
    const [SelectedCity,setSelectedCity] = useState(null);
    const [cityList,setCityList] = useState(null);
    const [SelectedState,setSelectedState] = useState(null);
    const [StateList,setStateList] = useState(null);
    const [SelectedCountry,setSelectedCountry] = useState(null);
    const [CountryList,setCountryList] = useState(null);
    let [companyMasterList, setCompanyMasterList] = useState([]);
    let clientId = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
    let [userID, setUserID] = useState(JSON.parse(sessionStorage.getItem("LoginData")).userId);
    let userId = JSON.parse(sessionStorage.getItem("LoginData")).userId;
  

  const handleOnChange = (e)=>{

    document.getElementById('errcompanyName').style.display = 'none'
    document.getElementById('errcompanylogo').style.display = 'none'
    document.getElementById('errcompanycontact').style.display = 'none'
    document.getElementById('errcompanyaddress').style.display = 'none'
    document.getElementById('errcompanyemail').style.display = 'none'
    document.getElementById('errSelectedCountry').style.display = 'none'
    document.getElementById('errSelectedState').style.display = 'none'
    document.getElementById('errSelectedCity').style.display = 'none'
    document.getElementById('errremark').style.display = 'none'
    
    const { name,value }= e.target;

     if(name === 'companyName'){
      setCompanyName(value);
      
     }

     if(name === 'companylogo'){
      setCompanylogo(value);
     }

     if(name === 'companycontact'){
      setCompanycontact(value);
     }

     if(name === 'companyaddress'){
      setCompanyaddress(value);
     }

     if(name === 'companyemail'){
      setCompanyemail(value);
     }

     if(name === 'remark'){
      setRemark(value);
     }
  }

  const getData = async () => {
    let data = await GetCompanyMaster();
    if (data.status === 1) {
        setCompanyMasterList(data.responseValue);
        console.log("asdf",data.responseValue)
    }

}

const getCityData=async()=>{
  let data = await GetCityMaster()
  if(data.status ===1){
    setCityList(data.responseValue.map(val => ({
      value: val.id,
      label: val.name
    })))
    console.log("cityNames",data)
  }
  else {
    setCityList([])
    setIsDisable(true)
  }
}

const getStateData = async()=>{
  let data = await GetStateMaster()
    if(data.status === 1){
      setStateList(data.responseValue.map(val=>({
        value : val.id,
        countryId: val.countryId,
        label : val.stateName
      })));
    }
    else {
      setCityList([])
      setIsDisable(true)
    }
}



const getCountryData = async()=>{
  let data = await GetCountryMaster()
    if(data.status === 1){
      setCountryList(data.responseValue.map(val=>({
        value : val.id,
        label : val.countryName
      })));
     
    }
}


  const handleOnSave=async()=>{

    if (companyName === '') {
      document.getElementById('errcompanyName').innerHTML = 'Enter Company Name';
      document.getElementById('errcompanyName').style.display = 'block';
      return;
    }
   else if(companylogo === ''){
      document.getElementById('errcompanylogo').innerHTML = 'Enter Company Logo';
      document.getElementById('errcompanylogo').style.display = 'block';
      return;
   }
   else if (companycontact === ''){
      document.getElementById('errcompanycontact').innerHTML = 'Enter Company Contact';
      document.getElementById('errcompanycontact').style.display = 'block';
      return;
   }
   else if(companyaddress === ''){
      document.getElementById('errcompanyaddress').innerHTML = 'Enter Company Address';
      document.getElementById('errcompanyaddress').style.display = 'block';
      return;
   }
   else if(companyemail === ''){
      document.getElementById('errcompanyemail').innerHTML = 'Enter Company Email';
      document.getElementById('errcompanyemail').style.display = 'block';
      return;
   }
   else if(SelectedCountry === null){
      document.getElementById('errSelectedCountry').innerHTML = 'Please Select Country';
      document.getElementById('errSelectedCountry').style.display = 'block';
      return;
   }
   else if(SelectedState === null){
    document.getElementById('errSelectedState').innerHTML = 'Please Select State';
    document.getElementById('errSelectedState').style.display = 'block';
    return;
   }
   else if(SelectedCity === null){
    document.getElementById('errSelectedCity').innerHTML = 'Please Select City';
    document.getElementById('errSelectedCity').style.display = 'block';
    return;
   }
    else if(remark === ''){
      document.getElementById('errremark').innerHTML = 'Enter remark';
      document.getElementById('errremark').style.display = 'block';
      return;

    }


    const obj ={
      companyName: companyName,
      companyLogo: companylogo,
      companyContact: companycontact,
      companyAddress: companyaddress,
      companyEmai : companyemail,
      companyCityID :SelectedCity.value,
      companyStateID: SelectedState.value,
      companyCountryID: SelectedCountry.value,
      remark: remark,
      userID: window.userId,
      
     }
     console.log("saveObj" , obj)
    //  return
    let response = await PostCompanyMaster(obj)
      if(response.status === 1){
        setShowUnderProcess(0)
            setShowToster(1)
            setTosterValue(0)
            setTosterMessage("Data Save SuccessFully!")
            setNewlyAddedRowIndex(0);
            getData();
            handleClear();
            setTimeout(() => {
              setShowToster(0)
            }, 2000)
      }

      else{
        setShowUnderProcess(0);
        setShowToster(1);
        setTosterValue(1);
        setTosterMessage("Already Exist!");
        setTosterMessage(response.responseValue)
        // setTosterValue(1)
        setTimeout(() => {
          setShowToster(0)
        
        }, 2000)
      }
  }

  const handleClear=()=>{
    // document.getElementById('errcompanyName').style.display = 'none';
    document.getElementById('errcompanyName').style.display = 'none'
    document.getElementById('errcompanylogo').style.display = 'none'
    document.getElementById('errcompanycontact').style.display = 'none'
    document.getElementById('errcompanyaddress').style.display = 'none'
    document.getElementById('errcompanyemail').style.display = 'none'
    document.getElementById('errSelectedCountry').style.display = 'none'
    document.getElementById('errSelectedState').style.display = 'none'
    document.getElementById('errSelectedCity').style.display = 'none'
    document.getElementById('errremark').style.display = 'none'
    setCompanyName('');
    setCompanylogo('');
    setCompanycontact('');
    setCompanyaddress('');
    setCompanyemail('');
    setRemark('');
    setSelectedCity(null);
    setSelectedCountry(null);
    setSelectedState(null);
    setIsDisable(true)
   
   
}

const handleUpdate=async()=>{

  if (companyName === '') {
    document.getElementById('errcompanyName').innerHTML = 'Enter Company Name';
    document.getElementById('errcompanyName').style.display = 'block';
    return;
  }
 else if(companylogo === ''){
    document.getElementById('errcompanylogo').innerHTML = 'Enter Company Logo';
    document.getElementById('errcompanylogo').style.display = 'block';
    return;
 }
 else if (companycontact === ''){
    document.getElementById('errcompanycontact').innerHTML = 'Enter Company Contact';
    document.getElementById('errcompanycontact').style.display = 'block';
    return;
 }
 else if(companyaddress === ''){
    document.getElementById('errcompanyaddress').innerHTML = 'Enter Company Address';
    document.getElementById('errcompanyaddress').style.display = 'block';
    return;
 }
 else if(companyemail === ''){
    document.getElementById('errcompanyemail').innerHTML = 'Enter Company Email';
    document.getElementById('errcompanyemail').style.display = 'block';
    return;
 }
 else if(SelectedCountry === null){
    document.getElementById('errSelectedCountry').innerHTML = 'Please Select Country';
    document.getElementById('errSelectedCountry').style.display = 'block';
    return;
 }
 else if(SelectedState === null){
  document.getElementById('errSelectedState').innerHTML = 'Please Select State';
  document.getElementById('errSelectedState').style.display = 'block';
  return;
 }
 else if(SelectedCity === null){
  document.getElementById('errSelectedCity').innerHTML = 'Please Select City';
  document.getElementById('errSelectedCity').style.display = 'block';
  return;
 }
  else if(remark === ''){
    document.getElementById('errremark').innerHTML = 'Enter remark';
    document.getElementById('errremark').style.display = 'block';
    return;

  }

 const obj ={
      id : rowID,
      companyName: companyName,
      companyLogo: companylogo,
      companyContact: companycontact,
      companyAddress: companyaddress,
      companyEmai : companyemail,
      companyCityID :SelectedCity.value,
      companyStateID: SelectedState.value,
      companyCountryID: SelectedCountry.value,
      remark: remark,
      userID: window.userId,
      
     }

const data = await PutCompanyMaster(obj);
if (data.status === 1) {
  setShowUnderProcess(0);
  setTosterValue(0);
  setShowToster(1);
  setTosterMessage('Data Updated Successfully!');
  getData();
  handleClear()
  setTimeout(() => {
    setShowToster(0);
    // setNewlyAddedRowIndex(null);
  }, 2000);
  // setIsUpdateBtnShow(false);
} 
else {
  setShowUnderProcess(0);

  setTosterMessage(data.responseValue);
  setTosterValue(1);
  setTimeout(() => {
    setShowToster(0);
  }, 2000);
}
}

const handleCancel=async()=>{
  handleClear()
  setIsUpdateBtnShow(false);
  setEditRowIndex(null);
  setNewlyAddedRowIndex(null);
};



const deleteRow = async () => {

  const obj = {
    id: rowID,
    userId: userID
  }
  let data = await DeleteCompanyMaster(obj);
  if (data.status === 1) {
    setShowUnderProcess(0);
    setTosterValue(0);
    setShowToster(1);
    setTosterMessage("Data Deleted Successfully!!");
    setNewlyAddedRowIndex(false)
    setIsUpdateBtnShow(false)
    setIsNewRowAdded(false)
    GetCompanyMaster();
    handleClear();
    setTimeout(() => {
      setShowToster(0);
      handleClear()

    }, 1000)
  }
 
  else {
    setShowUnderProcess(0)
    setShowToster(0)
    setTosterMessage(data.responseValue)
    setTosterValue(1)
    setTimeout(() => {
      setShowToster(0);
    }, 2000)
  }
};

const edit = async(data, index) => {
  setRowID(data.id)
  setIsUpdateBtnShow(true);
  setCompanyName(data.companyName)
  setCompanylogo(data.companyLogo)
  setCompanycontact(data.companyContact)
  setCompanyaddress(data.companyAddress)
  setCompanyemail(data.companyEmail)

  setSelectedCountry({
    value: data.companyCountryID,
    //  label: data.companyName
  })
  setSelectedCity({
    value: data.companyCityID,
    //  label: data.companyName
  })
  let dataState = await GetStateMaster(data.companyCountryID)
  if(dataState.status === 1){
    setStateList(dataState.responseValue.map(val=>({
      value : val.id,
     
      label : val.stateName
    })));
        console.log("sfdsfdfdf",dataState.responseValue)
  }
  setSelectedState({
   value : data.companyStateID
  })
    let dataCity = await GetCityMaster(data.companyStateID)
   if(dataCity.status ===1){
     setCityList(dataCity.responseValue.map(val => ({
       value: val.id,
       label: val.name
     })))
     console.log("cityNames",dataCity.responseValue)
   }
   setSelectedCity({
    value : data.companyCityID
   })
  
  setRemark(data.remark)
  setIsDisable(false)
  setNewlyAddedRowIndex(index)
}



const handleSelectChange = async(selectedOption , setSelectedFunction) => {
  
  setSelectedFunction(selectedOption);
  if(selectedOption !== null){
     let data = await GetStateMaster(selectedOption.value)
  if(data.status === 1){
    setStateList(data.responseValue.map(val=>({
      value : val.id,
      countryId: val.countryId,
      label : val.stateName
    })));
  }
  
  setIsDisable(false)
  }
};

const handleSelectChangeState = async(selectedOption , setSelectedFunction) => {
  
  setSelectedFunction(selectedOption);
if(selectedOption !==null ){
   let data = await GetCityMaster(selectedOption.value)
  if(data.status ===1){
    setCityList(data.responseValue.map(val => ({
      value: val.id,
      label: val.name
    })))
    console.log("cityNames",data)
  }
}
 

};

useEffect(() => {
    getData();
    getCityData();
    getStateData();
    getCountryData();
}, [])

  return (
    <section className="main-content mt-5 pt-3">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="med-box">
            {/* <div className="title">{t("Company Master")}</div> */}
            <Heading text={t("Company Master")} />
            <BoxContainer>
            <div className="inner-content">
              <div className='row'>

                <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-2">
                  <label htmlFor="companyName" className="form-label">{t("Company Name")}<span className="starMandatory">*</span></label>
                  <input type="text" className="form-control form-control-sm" id ='companyName' name='companyName' value={companyName} placeholder={t("Enter Company Name")} onChange={handleOnChange} />
                  <small id="errcompanyName" className="form-text text-danger" style={{ display: 'none' }}></small>
                </div>

                
                <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-2">
                  <label htmlFor="companylogo" className="form-label">{t("Company Logo")}<span className="starMandatory">*</span></label>
                  <input type="text" className="form-control form-control-sm" id='companylogo' name='companylogo' value={companylogo} placeholder={t("Enter_Company_Logo")} onChange={handleOnChange} />
                  <small id="errcompanylogo" className="form-text text-danger" style={{ display: 'none' }}></small>
                </div>

                <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-2">
                  <label htmlFor="companycontact" className="form-label">{t("Company Contact")}<span className="starMandatory">*</span></label>
                  <input type="text" className="form-control form-control-sm" id='companycontact' name='companycontact' value={companycontact} placeholder={t("Enter Company Contact")} onChange={handleOnChange} />
                  <small id="errcompanycontact" className="form-text text-danger" style={{ display: 'none' }}></small>
                </div>

                <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-2">
                  <label htmlFor="companyaddress" className="form-label">{t("Company Address")}<span className="starMandatory">*</span></label>
                  <input type="text" className="form-control form-control-sm" id='companyaddress' name='companyaddress' value={companyaddress} placeholder={t("Enter Company Address")} onChange={handleOnChange} />
                  <small id="errcompanyaddress" className="form-text text-danger" style={{ display: 'none' }}></small>
                </div>

                <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-2">
                  <label htmlFor="companyemail" className="form-label">{t("Company Email")}<span className="starMandatory">*</span></label>
                  <input type="text" className="form-control form-control-sm" id='companyemail' name='companyemail' value={companyemail} placeholder={t("Enter Company Email")} onChange={handleOnChange} />
                  <small id="errcompanyemail" className="form-text text-danger" style={{ display: 'none' }}></small>
                </div>

                <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-2">
                      <label htmlFor="ddlitemmaster" className="form-label ">{t("Country")}<span className="starMandatory">*</span></label>
                      <Select value={SelectedCountry} options={CountryList} className=" create-select" id="serviceType" placeholder={t("Select Country")} isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, setSelectedCountry)} />
                      <small id="errSelectedCountry" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>
                    
                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-2">
                      <label htmlFor="ddlitemmaster" className="form-label ">{t("State")}<span className="starMandatory">*</span></label>
                      <Select value={SelectedState}  isDisabled={IsDisable} options={StateList} className=" create-select" id="serviceType" placeholder={t("Select State")} isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChangeState(selectedOption, setSelectedState)} />
                      <small id="errSelectedState" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>


                <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-2">
                      <label htmlFor="ddlitemmaster" className="form-label ">{t("City")}<span className="starMandatory">*</span></label>
                      <Select value={SelectedCity} isDisabled={IsDisable} options={cityList} className=" create-select" id="serviceType" placeholder={t("Select City")} isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, setSelectedCity)} />
                      <small id="errSelectedCity" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>

                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-2">
                  <label htmlFor="remark" className="form-label">{t("Remark")}<span className="starMandatory">*</span></label>
                  <input type="text" className="form-control form-control-sm" id='remark' name='remark' value={remark} placeholder={t("Enter Remark")} onChange={handleOnChange} />
                  <small id="errremark" className="form-text text-danger" style={{ display: 'none' }}></small>
                </div>

                  
                <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3 relative">
                  <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>

                  {showUnderProcess === 1 ? <><TosterUnderProcess />  </> :
                    showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} />
                      :
                      <div>
                        {isUpdateBtnShow !== true ? <>
                          <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleOnSave}><img src={saveButtonIcon} className='icnn' alt="" />{t("Save")}</button>
                          <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}>
                            <img src={clearIcon} className='icnn' alt="" />
                          {t("Clear")}</button>
                        </> :
                          <>
                             <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1 mx-1" onClick={handleUpdate} ><img src={updateIcon} className='icnn' alt="" />Update</button>
                            <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleCancel}>{t("Cancel")}</button>
                          </>
                        }
                      </div>
                  }
                </div>
              </div>
            </div>
            </BoxContainer>
  
          </div>
        </div>


        {/* table is made using getAllItemMaster API and mapped the data   */}


        <div className="col-12 mt-2">
        <Heading text={t("Company Master List")} />
          <div className="med-table-section" style={{ "height": "75vh" }}>
            <TableContainer>
            <thead>
                <tr>
                  <th className="text-center" style={{ "width": "5%" }}>{t("#")}</th>
                  <th>{t("Company Name")}</th>
                  <th>{t("Company Logo")}</th>
                  <th>{t("Company Contact")}</th>
                  <th>{t("Company Address")}</th>
                  <th>{t("Country")}</th>
                  <th>{t("State")}</th>
                  <th>{t("City")}</th>
                  <th>{t("Company Email")}</th>
                  <th>{t("Remark")}</th>
                 
                 

                  
                  <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {companyMasterList && companyMasterList.map((data, index) => {
                 const isEditing = index === editRowIndex;
                  return (
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td>{data.companyName}</td>
                      <td>{data.companyLogo}</td>
                      <td>{data.companyContact}</td>
                      <td>{data.companyAddress}</td>
                      <td>{data.companyCountryID}</td>
                      <td>{data.companyStateID}</td>
                      <td>{data.companyCityID}</td>
                      <td>{data.companyEmail}</td>
                      <td>{data.remark}</td>
                      <td>
                        <div className="action-button">
                          <div
                            data-bs-toggle="tooltip"
                            data-bs-title="Edit Row"
                            data-bs-placement="bottom"

                          >
                            <img src={editBtnIcon} className={isEditing ? 'edited-row' : ''} alt='' onClick={() => { edit(data, index) }} />
                          </div>
                          <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowID(data.id); }} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </TableContainer>
            {/* <table className="med-table border_ striped"> */}
            {/* </table> */}
          </div>
        </div>
      </div>
    </div>
    {/* -----------------------Start Delete Modal Popup-------------------    */}

    <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div className="modal-dialog modalDelete">
        <div className="modal-content">
          <div className="modal-body modelbdy text-center">
            <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
            <div className='popDeleteTitle mt-3'> {t("Delete?")}</div>
            <div className='popDeleteContent'>{t("Are_you_sure_you_want_to_delete?")}</div>
          </div>
          <div className="modal-footer1 text-center">

            <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
            <button type="button" className="btn-delete popBtnDelete" data-bs-dismiss="modal" onClick={deleteRow} >{t("Delete")}</button>
          </div>
        </div>
      </div>
    </div>
    {/* -----------------------End Delete Modal Popup---------------------  */}
    
  </section>
  )
}


