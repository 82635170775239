async function GetAutomateDutyForAssignedUser(params) {
    let url = window.AppbaseUrl + `/api/DoctorOrders/GetAllDoctorOrdersForAssignedUser?assignedUserId=${params.assignedUserId}&clientId=${params.clientId}&fromDate=${params.fromDate}&toDate=${params.toDate}&dutyType=${params.dutyType}`;
    let head = { "Content-Type": "application/JSON", accept: '*/*' };

    let response = await fetch(url, {
        headers: head,
        method: 'GET'
    })
        .then((res) => res.json())
        .then(params);

    return response;
}

export default GetAutomateDutyForAssignedUser;
  
  