import React, { useEffect, useState } from 'react'
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import clearIcon from '../../assets/images/icons/clear.svg';
import GetRegistartionFor from '../API/RegistartionFormDynamic/GetRegistartionFor';
import editBtnIcon from '../../assets/images/icons/edit.svg';
import checksign from '../../assets/images/icons/check-circle-fill.svg';
export default function FieldMaster() {
  const fieldsDetails = [
    { name: 'name', labelName: 'Name', isrequired: false, type: 'text' },
    { name: 'rollNo', labelName: 'Roll Number', isrequired: false, type: 'number' },
    { name: 'gender', labelName: 'Gender', isrequired: false, type: 'text' },
    { name: 'contactNo', labelName: 'Contact Number', isrequired: false, type: 'number' },
    { name: 'address', labelName: 'Address', isrequired: false, type: 'text' },
    { name: 'admissionNo', labelName: 'Admission Number', isrequired: false, type: 'text' },
    { name: 'dob', labelName: 'Date of Birth', isrequired: false, type: 'date' },
    { name: 'nationality', labelName: 'Nationality', isrequired: false, type: 'text' },
    { name: 'city', labelName: 'City', isrequired: false, type: 'text' },
    { name: 'state', labelName: 'State', isrequired: false, type: 'text' },
    { name: 'postalCode', labelName: 'Postal Code', isrequired: false, type: 'number' },
    { name: 'email', labelName: 'Email Address', isrequired: false, type: 'email' },
    { name: 'parentName', labelName: 'Parent/Guardian Full Name', isrequired: false, type: 'text' },
    { name: 'relationship', labelName: 'Relationship to Student', isrequired: false, type: 'text' },
    { name: 'parentContactNo', labelName: 'Parent/Guardian Contact Number', isrequired: false, type: 'number' },
    { name: 'previousSchoolName', labelName: 'Previous School Name', isrequired: false, type: 'text' },
    { name: 'previousSchoolAddress', labelName: 'Previous School Address', isrequired: false, type: 'text' },
    { name: 'gradeCompleted', labelName: 'Grade/Year Completed', isrequired: false, type: 'number' },
    { name: 'gradeApplyingFor', labelName: 'Grade/Year Applying For', isrequired: false, type: 'number' }
];


    const [regTypeList, setregTypeList] = useState([])
    const [RegTypeID, setRegTypeID] = useState("");
    const [editingIndex, setEditingIndex] = useState(null);
    const [editingLabel, setEditingLabel] = useState('');
    const [fieldArray, setFieldArray] = useState(fieldsDetails);

    const getRegType=async()=>{
        let data = await GetRegistartionFor();
        if(data.status === 1){
            setregTypeList(data.responseValue);
            console.log("regType" , data.responseValue)
        }
    }
    
    const handleOnChange = async (e) => {
        const { name, value } = e.target;
        if (name === "regType") {
          setRegTypeID(value);

        }
      };
      const handleInputChange = (index, event) => {
        const updatedFields = [...fieldArray];
        updatedFields[index].value = event.target.value;
        setFieldArray(updatedFields);
    };

    const handleLabelCheckboxChange = (index, event) => {
        const updatedFields = [...fieldArray];
        updatedFields[index].labelChecked = event.target.checked;
        setFieldArray(updatedFields);
    };

    const handleIsRequiredCheckboxChange = (index, event) => {
        const updatedFields = [...fieldArray];
        updatedFields[index].isrequired = event.target.checked;
        setFieldArray(updatedFields);
    };

    const handleEditClick = (index) => {
        setEditingIndex(index);
        setEditingLabel(fieldArray[index].labelName);
    };

    const handleLabelChange = (event) => {
        setEditingLabel(event.target.value);
    };

    const handleConfirmEdit = (index) => {
        const updatedFields = [...fieldArray];
        updatedFields[index].labelName = editingLabel;
        setFieldArray(updatedFields);
        setEditingIndex(null);
    };

    const handleSave = () => {
      const checkedFields = fieldArray.filter(field => field.labelChecked);
      console.log("Checked fields:", checkedFields);
  };
    useEffect(() => {
        getRegType()
    }, [])


  return (
<>
<section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div class="col-12"><div class="med-box  mb-1 d-flex justify-content-between">
                <div class="title">
                Registration Field Master
                </div>
                <div className='p-2'>
                <select id="Payment" className="form-control form-control-sm" name="regType" value={RegTypeID} onChange={handleOnChange} >
                <option value="0">Select Registration For</option>
                    {regTypeList && regTypeList.map((val, index)=>{
                         
                        return(
                            
                            <option value={val.id}>{val.type}</option>
                        )
                    })}
                    
                   
                           </select>
                </div>
            {/* <div class="title pe-3" style={{fontSize: '12px'}}>GSTIN: {LicenseDetails.licenseNo}</div> */}
            </div></div>
            <div className="col-12">
              <div className="med-box">
                <div className="inner-content" style={{ paddingRight: '22px' }}>
                  <div className="row">
                   
                      <div className='fieldsett-in col-md-12'>
                        <div className='fieldsett'>
                          <span className='fieldse'>Registration Details</span>
                      
                          <div className="row">

                          {fieldArray.map((val, index) => (
                <div key={index} className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3 regularCheck">
                    <div className="d-flex column-gap-3 mt-4 me-3">
                        {editingIndex === index ? (
                            <input
                                type="text"
                                className="form-control custom-input-editable"
                                value={editingLabel}
                                onChange={handleLabelChange}
                                onBlur={() => handleConfirmEdit(index)}
                                autoFocus
                            />
                        ) : (
                            <label className="form-label">
                                {val.labelName}
                                <span className="starMandatory"></span>
                            </label>
                        )}

                        <div className="form-check">
                            <input
                                id={`labelCheckbox-${index}`}
                                name="labelCheckbox"
                                className="form-check-input"
                                type="checkbox"
                                style={{ borderRadius: '50%' }}
                                checked={val.labelChecked || false}
                                onChange={(e) => handleLabelCheckboxChange(index, e)}
                            />
                            {editingIndex === index ? (
                                <i
                                    className="bi bi-check-circle-fill"
                                    style={{ color: '#3684c9' }}
                                    onClick={() => handleConfirmEdit(index)}
                                ></i>
                            ) : (
                                <img
                                    src={editBtnIcon}
                                    alt=""
                                    onClick={() => handleEditClick(index)}
                                    style={{ cursor: 'pointer', width: '21px' }}
                                />
                            )}
                        </div>
                        <small
                            id="erraccessallowed"
                            className="form-text text-danger"
                            style={{ display: 'none' }}
                        ></small>
                    </div>
                    <div className="d-flex column-gap-3 me-3">
                        <label className="form-label">
                            Is Mandatory?
                            <span className="starMandatory"></span>
                        </label>
                        <div className="form-check">
                            <input
                                id={`isRequiredCheckbox-${index}`}
                                name="isRequiredCheckbox"
                                className="form-check-input"
                                type="checkbox"
                                style={{ borderRadius: '50%' }}
                                checked={val.isrequired}
                                onChange={(e) => handleIsRequiredCheckboxChange(index, e)}
                            />
                        </div>
                        <small
                            id="erraccessallowed"
                            className="form-text text-danger"
                            style={{ display: 'none' }}
                        ></small>
                    </div>
                </div>
            ))}
                             
   

                          </div>
                                

                        </div>
                        <div className="row justify-content-end">
                                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3 mt-3 rt-btns ">
                                                   <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                                   <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1"  onClick = {handleSave}><img src={saveButtonIcon} className='icnn' alt="" />Submit</button>
                                                    <button type="button" className="btn btn-clear btn-sm mb-1 me-1" ><img src={clearIcon} className='icnn' alt= ""/>Clear</button>
                                                   </div>
                                      </div>

                      </div>



                  
                  
                  </div>


                </div>



              </div>

            </div>
          </div>




        </div>

      </section>


</>
  )
}
