import React, { useEffect, useState } from 'react'
import Patientname, { FamilyHistory, IsActive, PatientADRReport, PatientAdmitTime, PatientAge, PatientBed, PatientCalculator, PatientCamera, PatientChat, PatientDepartment, PatientFeedBack, PatientGender, PatientProfile, PatientSortIndex, PatientUhId, PatientWard, Supportive } from './AllFieldPatientDeatils'
import GetAllDashboardParameterColumnAssign from '../../../Api/GetAllDashboardParameterColumnAssign'
import { onDrop } from '../../../Code/DragDropData'
import ShowStethoScopePopUp, { FoodIntakePopUp, MedicineCheckPopUp, PAtientPACSDeatils, PatientClinicalNotifications, PatientECGGraph, PatientInvestigationDetails, PhysicalActivityPopUp, SupplementPopUp } from '../Investigation/AllFieldsInvestigation'
import PatientLifeSupport, { PatientDVTPump, PatientInfusionPump, PatientInfusionPumpDataList, PatientVentiMode } from '../LifeSupport/AllFieldLifeSupport'
import PatientDiagonsisList, { PatientHomeCareSymtomsList } from '../Diagnosis/AllFieldDiagnosis'
import { useSelector } from 'react-redux'


export default function PatientDeatils(props) {
    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
           if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

    }
    useEffect(() => {
        getColumnsParameters()
    }, [props])
    return (
        <div className="overflow-max d-flex flex-wrap " id={props.columnId} key={Date.now().toString(36)}>
            {
                paramenterData && paramenterData.map((val, ind) => {

                    if (val.parameterId === 1) {
                        return (<>
                            <Patientname patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 2) {
                        return (<>
                            <PatientAge patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 3) {
                        return (<>
                            <PatientGender patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 4) {
                        return (<>
                            <PatientUhId patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} />&nbsp;

                        </>)
                    }
                    else if (val.parameterId === 5) {
                        return (<>
                            <PatientWard patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} />&nbsp;

                        </>)
                    }
                    else if (val.parameterId === 6) {
                        return (<>
                            <PatientBed patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} />&nbsp;

                        </>)
                    }
                    else if (val.parameterId === 7) {
                        return (<>
                            <PatientDepartment patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} />&nbsp;

                        </>)
                    }

                    else if (val.parameterId === 8) {
                        return (<>
                            ( <PatientAdmitTime patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> )&nbsp;

                        </>)
                    }
                    else if (val.parameterId === 9) {
                        return (<>
                            <PatientProfile patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} />&nbsp;

                        </>)
                    }
                    else if (val.parameterId === 10) {
                        return (<>
                            <PatientCamera patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;

                        </>)
                    }
                    else if (val.parameterId === 11) {
                        return (<>
                            <PatientCalculator patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} />&nbsp;

                        </>)
                    }
                    else if (val.parameterId === 12) {
                        return (<>
                            <PatientADRReport patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} />&nbsp;

                        </>)
                    }
                    else if (val.parameterId === 13) {
                        return (<>
                            <FamilyHistory patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} />&nbsp;

                        </>)
                    }
                    else if (val.parameterId === 14) {
                        return (<>
                            <Supportive patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} />&nbsp;
                        </>)
                    }
                    else if (val.parameterId === 15) {
                        return (<>
                            <PatientChat patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} />&nbsp;
                        </>)
                    }
                    else if (val.parameterId === 119) {
                        return (<>
                            <IsActive patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} />&nbsp;
                        </>)
                    }


                    // investigation

                    else if (val.parameterId === 20) {
                        return (<>
                            <PatientInvestigationDetails patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 21) {
                        return (<>
                            <PAtientPACSDeatils patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 22) {
                        return (<>
                            <PatientClinicalNotifications patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 24) {
                        return (<>
                            <PatientECGGraph patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 25) {
                        return (<>
                            <ShowStethoScopePopUp patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 26) {
                        return (<>
                            <MedicineCheckPopUp patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 27) {
                        return (<>
                            <FoodIntakePopUp patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 28) {
                        return (<>
                            <SupplementPopUp patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 29) {
                        return (<>
                            <PhysicalActivityPopUp patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }

                    // Life Support 
                    else if (val.parameterId === 16) {
                        return (<>
                            <PatientLifeSupport patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 18) {
                        return (<>
                            <PatientVentiMode patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 19) {
                        return (<>
                            <PatientInfusionPump patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 117) {
                        return (<>
                            <PatientDVTPump patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 118) {
                        return (<>
                            <PatientInfusionPumpDataList patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 116) {
                        return (<>
                            <PatientDiagonsisList patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 120) {
                        return (<>
                            <PatientHomeCareSymtomsList patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }


                })
            }


        </div>
    )
}
