// let GetOtTeamList = async(clientId)=>{
//     const url= window.OTBaseURL+"OTTeam/GetOTTeam";
//     const head={'content-type':'application/json','accept':'*/*',}
//     let data={};
//     let response= await fetch(url,{
//         method:"GET",
//         header:head,
//     }).then(res=> res.json()).then(data);
//     return response;
// }
// export default GetOtTeamList



async function GetOtTeamList(clientId) {
    // http://172.16.61.31:7091/api/OtTeam/GetOTTeam?ClientId=176
    let url = window.OTBaseURL+'OtTeam/GetOTTeam?ClientId=' + clientId;
    let head = { "Content-Type": "application/JSON", accept : '*/*' };
   
    let data  = {};
 
  let response = fetch(url, {
    headers: head,
    method : 'GET'
  }).then((res) => res.json()).then(data);
 
  return response;
}
export default GetOtTeamList;