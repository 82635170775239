 
async function GetItemTypeMaster(ClientID) {
    let url = window.InventoryBaseUrl + "/api/ItemTypeMaster/GetItemTypeMaster?ClientID=" + ClientID;
    let head = { "Content-Type": "application/JSON", accept: '*/*'
 };
  
    let response = await fetch(url, {
      headers: head,
      method: 'GET'
    })
      .then((res) => res.json())
      .then();
  
    return response;
  }
  export default GetItemTypeMaster;
  
  