
// async function GetCityMaster() {
//     // let url = window.HRServiceUrl+ `/api/CityMaster/GetCityMasterByStateId`;
//     // 172.16.61.31:7083/api/CityMaster/GetCityMasterByStateId
//     let url = window.AdminbaseUrl+ `/api/CityMaster/GetCityMasterByStateId?id=${1}+languageId=${1}`;
   
//   let head = { "Content-Type": "application/JSON", accept : '*/*' };
  
//   let data = {};
//   let response = fetch(url, {
//     headers: head,
//     method : 'GET'
//   })
//     .then((res) => res.json())
//     .then(data);
  
//   return response;
//   }
//   export default GetCityMaster;

async function GetCityMaster(id) {
    // Constructing the URL to fetch city data
    let url = window.AdminbaseUrl + `/api/CityMaster/GetCityMasterByStateId?id=${id}`;

    // Setting up headers for the fetch request
    let head = { "Content-Type": "application/JSON", accept: '*/*' };

    // Initializing an empty data object
    let data = {};

    // Fetching data from the constructed URL
    let response = fetch(url, {
        headers: head,
        method: 'GET'
    })
    .then((res) => res.json()) // Parsing response as JSON
    .then(data); // Not sure why this line exists, it doesn't do anything useful

    return response; // Returning the response from the fetch request
}

export default GetCityMaster; // Exporting the function
