

export default async function GetMicrobiologyInvestigation(requiredUHID){    
    const clientID=JSON.parse(sessionStorage.getItem("LoginData")).clientId;
    let URL = window.SendXMLDatabaseUl + `/api/MicrobiologyInvestigationRequestForm/Get?UHID=${requiredUHID}&clientId=${clientID}`;  
    let head = {"Content-Type":"application/json", 'accept':'*/*'};
   
   let response = "";
   response = await fetch(URL, {
       headers:head,
       method:"GET"        
   })
   .then(res => res.json())  
   .catch(error => response=error);    
   return response;
}