

export default async function GetAdvancePaymentList(UHID,FromDate,ToDate){    
    let clientId = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId
    let URL = window.BillingbaseUrl +`/api/AddAdvance/GetAllAdvanceList?uhid=${UHID}&clientId=${clientId}`;
   let head = {"Content-Type":"application/json", 'accept':'*/*'};
   
   let response = "";
   response = await fetch(URL, {
       headers:head,
       method:"GET"        
   })
   .then(res => res.json())  
   .catch(error => response=error);    
   return response;
}