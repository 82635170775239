import React, { useState } from 'react'
import i18n, { t } from "i18next";

import TosterUnderProcess from '../../../../../Component/TosterUnderProcess';
import Toster from '../../../../../Component/Toster';

import boxArrow from '../../../../../assets/images/dmsimg/box-arrow-in-right.png';
import vectorPrint from '../../../../../assets/images/icons/vectorPrint.png'
import xcircle from '../../../../../assets/images/dmsimg/x-circle.svg';
import minusIcon from '../../../../../assets/images/dmsimg/icon.svg';



export default function InwardGatePass() {

  let [showUnderProcess, setShowUnderProcess] = useState(0);
  let [showToster, setShowToster] = useState(0);
  let [tosterMessage, setTosterMessage] = useState("");
  let [tosterValue, setTosterValue] = useState(0);
  let [updateBool, setUpdateBool] = useState(0);
  return (
    <section className="main-content mt-5 pt-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className='handlser serinventory med-box'>
              <div className='demandMain-inn p-2'>
                <div className="title mt-2 pe-2 ps-2 bordertitle">{t("Inward Gatepass")}</div>
              </div>
              <div className='serchbtn-sec'>
                <div className="tradeApproved">
                  <div className='d-flex flex-wrap align-items-center'>
                    <span className='demandText'>Godown</span>
                    <span className='ms-2'>
                      <select name="" id="" className="form-select form-select-sm tradeDropdown" style={{ color: '#2A62C8', fontWeight: '500', fontSize: '14px', backgroundColor: '#F9FAFC', border: '0px' }} >
                        <option value='0' selected>All Demand</option>
                        <option value='1'>Normal</option>
                        <option value='2'>Urgent</option>
                        <option value='3'>Must</option>
                      </select>
                    </span>
                  </div>
                  <div style={{ borderLeft: '1px solid #5E707B' }}>
                  </div>
                  <div className="d-flex  flex-wrap">
                    <div className='purchasedemand'>
                      <div className='d-flex flex-wrap align-items-center'>
                        <span className='demandText'>From</span>
                        <span> <input type='date' className='form-control form-control-sm' id='fromDateID' name='fromDateName' style={{ color: '#2A62C8', fontWeight: '500', fontSize: '14px', backgroundColor: '#F9FAFC', border: '0px' }} /></span>
                      </div>
                    </div>
                    <div className='purchasedemand'>
                      <div className='d-flex flex-wrap align-items-center'>
                        <span className=''>To</span>
                        <span> <input type='date' className='form-control form-control-sm' id='toDateID' name='toDateName' style={{ color: '#2A62C8', fontWeight: '500', fontSize: '14px', backgroundColor: '#F9FAFC', border: '0px' }} /></span><br />
                        {/* <div><small id="errToDate" className="invalid-feedback" style={{ display: 'none' }}></small></div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ position: 'relative' }}>
                  <input type="text" className='form-control form-control-sm' placeholder={t("Search")} onChange={'handleSearch'} />
                  <span className="sericnInventory"><i className="fas fa-search"></i></span>
                </div>
                <div data-bs-toggle="modal" data-bs-target="#itemDetailsBackdrop">
                  <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1">{t("Generate Gate Pass")}<img src={boxArrow} className='icnn' alt='' style={{ width: '20px', paddingLeft: '5px' }} /> </button>
                </div>
              </div>
            </div>
            <div className="med-table-section" style={{ "height": "84vh" }}>
              <table className='med-table thinventory striped'>
                <thead>
                  <tr>
                    <th className="text-center" style={{ "width": "5%" }}>#</th>
                    <th>Receiving Date</th>
                    <th>PO No.</th>
                    <th>Invoice No.</th>
                    <th>Godown</th>
                    <th>Supplier Name</th>
                    <th>Number of Item(s)</th>
                    <th className="text-center">Action</th>
                    {/* <th style={{ "width": "10%" }} className="text-center">Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">1</td>
                    <td className="">12-02-2023</td>
                    <td className="">PO/5665</td>
                    <td className="">IN/76766</td>
                    <td className="">Era Godown One</td>
                    <td className="">Kahef Rizvi</td>
                    <td className="">10</td>
                    <td className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><i className="fa fa-eye actionedit viewaction " ></i><img src={vectorPrint} alt="" style={{ paddingLeft: '10px' }} /></td>
                  </tr>
                  <tr>
                    <td className="text-center">1</td>
                    <td className="">12-02-2023</td>
                    <td className="">PO/5665</td>
                    <td className="">IN/76766</td>
                    <td className="">Era Godown One</td>
                    <td className="">Kahef Rizvi</td>
                    <td className="">10</td>
                    <td className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><i className="fa fa-eye actionedit viewaction " ></i><img src={vectorPrint} alt="" style={{ paddingLeft: '10px' }} /></td>
                  </tr>
                  <tr>
                    <td className="text-center">1</td>
                    <td className="">12-02-2023</td>
                    <td className="">PO/5665</td>
                    <td className="">IN/76766</td>
                    <td className="">Era Godown One</td>
                    <td className="">Kahef Rizvi</td>
                    <td className="">10</td>
                    <td className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><i className="fa fa-eye actionedit viewaction " ></i><img src={vectorPrint} alt="" style={{ paddingLeft: '10px' }} /></td>
                  </tr>
                </tbody>

                {/* <tbody>
                                        {itemList && itemList.length > 0 ? (
                                            itemList.map((list, ind) => (

                                                <tr key={list.id}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td style={{ minWidth: '110px' }}>
                                                        <div className='invImg'>
                                                            <img src={window.InventoryBaseUrl + '/' + list.imgURL} alt='' />
                                                        </div>
                                                    </td>
                                                    <td>{list.itemName}</td>
                                                    <td>{list.itemCode}</td>
                                                    <td style={{ minWidth: '180px' }}> {list.jsonItemBrand && JSON.parse(list.jsonItemBrand).map((unit, ind) => {
                                                        return (
                                                            <span key={unit.BrandId}>{unit.BrandName}  {JSON.parse(list.jsonItemBrand).length !== 0 && ind + 1 !== JSON.parse(list.jsonItemBrand).length ? "," : ""} </span>
                                                        )
                                                    })}</td>
                                                    <td style={{ minWidth: '180px' }}>
                                                        {list.jsonItemUnit && JSON.parse(list.jsonItemUnit).map((unit, ind) => {
                                                            return (
                                                                <span key={unit.UnitId}>{unit.UnitName}  {JSON.parse(list.jsonItemUnit).length !== 0 && ind + 1 !== JSON.parse(list.jsonItemUnit).length ? "," : ""} </span>
                                                            )
                                                        })}
                                                    </td>
                                                    <td style={{ minWidth: '180px' }}>
                                                        {list.jsonItemUnit && JSON.parse(list.jsonItemUnit).map((unit, ind) => {
                                                            return (
                                                                <span key={unit.UnitId}>
                                                                    {unit.isDefault === 1 ? unit.UnitName : ''}
                                                                </span>
                                                            )
                                                        })}
                                                    </td>
                                                    <td>{list.urgencyLevelName}</td>
                                                    <td className="text-center">{list.minStockQty}</td>
                                                    <td>{list.categoryName}</td>
                                                    <td>{list.subCategoryName}</td>
                                                    <td>{list.itemType}</td>
                                                    <td>{list.subcriptionName}</td>
                                                    <td className="text-center">{list.hsnCode}</td>
                                                    <td className="text-center">{list.gstRatePer}</td>
                                                    <td>{list.specification}</td>
                                                    <td className="text-center">{list.isEquipment === 1 ? "Yes" : "No"}</td>
                                                   

                                                    
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4" className="text-center">
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                    </tbody> */}

              </table>
              {/* -----------------------Start Delete Modal Popup-------------------   */}

              {/*  <!-- Modal -->  */}
              <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                <div className="modal-dialog modalDelete">
                  <div className="modal-content">

                    <div className="modal-body modelbdy text-center">
                      <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                      <div className='popDeleteTitle mt-3'>Delete?</div>
                      <div className='popDeleteContent'>Are you sure want to delete?</div>
                    </div>
                    <div className="modal-footer1 text-center">

                      <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                      <button type="button" className="btn-delete popBtnDelete" onClick={'handleDelete'} data-bs-dismiss="modal">Delete</button>
                    </div>
                  </div>
                </div>
              </div>
              {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

            </div>

            {/* --------------------------------------------Start Create New Item Popup-------------------------------------- */}
            <div className="modal fade" id="itemDetailsBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="itemDetailsBackdropLabel" aria-hidden="true">
              <div className="modal-dialog  modal-lg additonaldetails" style={{ margin: '6% auto' }}>
                <div className="modal-content">
                  {/* <div className="modal-header_ bg-clrr mt-0 pt-0 mb-0 pb-0">
                                <div className="heading text-wrap">
                                    <div className='demandMain-inn p-2'>
                                        <div className="title pe-2 ps-2 bordertitle">{t("Create New Item")}</div>
                                    </div>
                                </div>
                                <div>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div> */}
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="inventoryMedBox">
                          <div className='popupheadr'>
                            <div className='demandMain-inn'>
                              <div className="title ps-2 bordertitle">{t("Generate Gate Pass")}</div>
                            </div>
                            <div>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                          </div>


                          <div className="inner-content mt-2">
                            <div className='row'>
                              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                                <label htmlFor="gatePass" className="form-label">{t("Gate Pass Number")}<span className="starMandatory">*</span></label>
                                <input type="text" className="form-control form-control-sm" id="gatePassID" name="gatePassName" placeholder="Enter gate pass no." />
                                <small id="errgatePassName" className="form-text text-danger" style={{ display: 'none' }}></small>
                              </div>

                              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2" >
                                <label htmlFor="poNo" className="form-label">{t("PO Number")}<span className="starMandatory">*</span></label>
                                <input type="text" className="form-control form-control-sm" id="poNoID" name="poNoName" placeholder="Enter PO No." />
                                <small id="errPONo" className="form-text text-danger" style={{ display: 'none' }}></small>
                              </div>
                              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2" >
                                <label htmlFor="billNo" className="form-label">{t("Bill/Invoice Number")}<span className="starMandatory">*</span></label>
                                <input type="text" className="form-control form-control-sm" id="billNoID" name="billNoName" placeholder="Enter bill/invoice no." />
                                {/* <Select value={selectedBrand} placeholder="Select Brand" isMulti options={brandList} className="create-select create-select-overflow" id="brandID" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedBrandOption => handleSelectBrandChange(selectedBrandOption, "errBrand", setselectedBrand)} /> */}
                                <small id="errBill" className="form-text text-danger" style={{ display: 'none' }}></small>
                              </div>

                              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2" >
                                <label htmlFor="" className="form-label">{t("Godown")}<span className="starMandatory">*</span></label>
                                <select name="godownName" id="godownID" className='form-select form-select-sm'>
                                  <option value="">Test</option>
                                  <option value="">Test</option>
                                  <option value="">Test</option>
                                </select>
                                <small id="errGodown" className="form-text text-danger" style={{ display: 'none' }}></small>
                              </div>

                              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2" >
                                <label htmlFor="" className="form-label">{t("Dealer")}<span className="starMandatory">*</span></label>
                                <select name="dealerName" id="dealerID" className='form-select form-select-sm'>
                                  <option value="">Test</option>
                                  <option value="">Test</option>
                                  <option value="">Test</option>
                                </select>
                                <small id="errDealer" className="form-text text-danger" style={{ display: 'none' }}></small>
                              </div>
                              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2" >
                                <label htmlFor="" className="form-label">{t("Cleared/Inspected By")}<span className="starMandatory">*</span></label>
                                <select name="inspectedName" id="inspectedID" className='form-select form-select-sm'>
                                  <option value="">Test</option>
                                  <option value="">Test</option>
                                  <option value="">Test</option>
                                </select>
                                <small id="errInspected" className="form-text text-danger" style={{ display: 'none' }}></small>
                              </div>


                              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2" >
                                <label htmlFor="deliveryPerson" className="form-label">{t("Delivered By")}<span className="starMandatory">*</span></label>
                                <input type="text" className="form-control form-control-sm" id="deliveredID" name="deliveredName" placeholder="Enter deleivery person name." />
                                {/* <Select value={selectedBrand} placeholder="Select Brand" isMulti options={brandList} className="create-select create-select-overflow" id="brandID" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedBrandOption => handleSelectBrandChange(selectedBrandOption, "errBrand", setselectedBrand)} /> */}
                                <small id="errDelivery" className="form-text text-danger" style={{ display: 'none' }}></small>
                              </div>


                              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2" >
                                <label htmlFor="itemsQuantity" className="form-label">{t("Number of Items")}<span className="starMandatory">*</span></label>
                                <input type="number" className="form-control form-control-sm" id="itemsID" name="itemsName" placeholder="Enter items quantity." />
                                {/* <Select value={selectedBrand} placeholder="Select Brand" isMulti options={brandList} className="create-select create-select-overflow" id="brandID" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedBrandOption => handleSelectBrandChange(selectedBrandOption, "errBrand", setselectedBrand)} /> */}
                                <small id="errItems" className="form-text text-danger" style={{ display: 'none' }}></small>
                              </div>
                              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2" >
                                <label htmlFor="inTime" className="form-label">{t("In Time")}<span className="starMandatory">*</span></label>
                                <input type="time" className="form-control form-control-sm" id="timeID" name="timeName" />
                                {/* <Select value={selectedBrand} placeholder="Select Brand" isMulti options={brandList} className="create-select create-select-overflow" id="brandID" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedBrandOption => handleSelectBrandChange(selectedBrandOption, "errBrand", setselectedBrand)} /> */}
                                <small id="errTime" className="form-text text-danger" style={{ display: 'none' }}></small>
                              </div>
                              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" >
                                <label htmlFor="inTime" className="form-label">{t("Remark")}</label>
                                <input type="text" className="form-control form-control-sm" id="remarkID" name="remarkName" />
                                {/* <Select value={selectedBrand} placeholder="Select Brand" isMulti options={brandList} className="create-select create-select-overflow" id="brandID" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedBrandOption => handleSelectBrandChange(selectedBrandOption, "errBrand", setselectedBrand)} /> */}
                                <small id="errRemark" className="form-text text-danger" style={{ display: 'none' }}></small>
                              </div>
                            </div>

                            <div className='col-12 mt-2 mb-2'>
                              <div className='vehicleHeadMain'>
                                <div className="vehicleHeading">
                                  Vehicle Details
                                </div>
                                <div>
                                  <img src={minusIcon} alt="" />
                                </div>
                              </div>
                              <div className="inner-content mt-2">
                                <div className='row'>
                                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2" >
                                    <label htmlFor="vehicleNo" className="form-label">{t("Vehicle Number")}<span className="starMandatory">*</span></label>
                                    <input type="text" className="form-control form-control-sm" id="vehicleID" name="vehicleName" placeholder="Enter Vehicle No." />
                                    <small id="errVehicleNo" className="form-text text-danger" style={{ display: 'none' }}></small>
                                  </div>
                                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2" >
                                    <label htmlFor="driver" className="form-label">{t("Driver Name")}<span className="starMandatory">*</span></label>
                                    <input type="text" className="form-control form-control-sm" id="driverID" name="driverName" placeholder="Enter Driver Name" />
                                    <small id="errDriver" className="form-text text-danger" style={{ display: 'none' }}></small>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='addVehicle'>
                              <div className='addVehicle-inn'> + Add Vehicle Details</div>
                            </div>

                            <div className="relative">

                              <div className="d-flex flex-wrap align-content-end justify-content-end">
                                <div className="mb-2 relative">
                                  {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                    <>
                                      {showToster === 1 ?
                                        <Toster value={tosterValue} message={tosterMessage} />
                                        : <div>
                                          {updateBool === 0 ?
                                            <>
                                              <button type="button" className="btn btn-cancel btn-sm mb-1 me-1"  ><img src={xcircle} className='icnn' alt='' />{t("Cancel")}</button>
                                              <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1">{t("Generate Gate Pass")} <img src={boxArrow} className='icnn' alt='' /> </button>
                                            </>
                                            :
                                            <>
                                              <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={'handlerUpdate'}>Update</button>
                                              <button type="button" className="btn btn-clear btn-sm mb-1" ><img src={xcircle} className='icnn' alt='' />{t("Cancel")}</button>
                                            </>
                                          }
                                        </div>}
                                    </>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* --------------------------------------------End Create New Item Popup-------------------------------------- */}
          </div>
        </div>
      </div>
    </section>
  )
}
