import React, { useEffect, useState } from 'react'

import alaram from '../../assets/images/DynamicDashboard/alaram.png'
import monitoring from '../../assets/images/DynamicDashboard/monitoring.png'
import expend from '../../assets/images/DynamicDashboard/expend.png'
import Freezer from '../../assets/images/DynamicDashboard/Freezer.png'
import pasystem from '../../assets/images/DynamicDashboard/pasystem.png'
import gas from '../../assets/images/DynamicDashboard/gas.png'
import lpg from '../../assets/images/DynamicDashboard/lpg.png'
import defaulticon from '../../assets/images/DynamicDashboard/default.png'
import call from '../../assets/images/DynamicDashboard/call.png'
import sideline from '../../assets/images/DynamicDashboard/sideline.png'
import callbg from '../../assets/images/DynamicDashboard/callbg.gif'
import bar from '../../assets/images/DynamicDashboard/bar.gif'
import timeclock from '../../assets/images/DynamicDashboard/timeclock.svg'
import redbg from '../../assets/images/DynamicDashboard/redbg.png'
import ybg from '../../assets/images/DynamicDashboard/ybg.png'
import l1 from '../../assets/images/DynamicDashboard/l1.png'
import l2 from '../../assets/images/DynamicDashboard/l2.png'
import compress from '../../assets/images/DynamicDashboard/compress.png'


export default function TempDynamicBox(props) {

    let [alarmDataList, setAlarmDataList] = useState([])
    let [tempratureDataList, setTempratureDataList] = useState([])
    let [pADataList, setPADataList] = useState([])
    let [gasDataList, setGasDataList] = useState([])
    let [airQualityDataList, setAirQualityDataList] = useState([])
    let [lpgDataList, setLPGDataList] = useState([])

    let fetechAPI = async (url) => {
        try {
            let head = { "Content-Type": "application/JSON", accept: '*/*' };
            let data = {}
            let response = fetch(url, {
                headers: head,
                method: 'GET'
            }).then((res) => res.json()).then(data);

            return response;
        }
        catch (ex) {
            return {
                status: 0,
                responseValue: [],
                message: "failed"
            }
        }

    }

    let getData = async () => {
        let alarmData = await fetechAPI("https://demo.medvantage.tech:7094/api/AlarmWarningLog/GetAllAlarmWarningLog?ClientId=176&dashboardFilte=")
        let tempData = await fetechAPI("https://demo.medvantage.tech:7094/api/LocationEnvironment/GetDeviceTemperature?dashboardFilte=")
        let pAData = await fetechAPI("https://demo.medvantage.tech:7094/api/PaSystem/GetAllPaSystem?ClientId=176")
        let gasData = await fetechAPI("https://demo.medvantage.tech:7091/api/OtGasPanel/GetAllGasDetails?clintId=176&dashboardFilte=")
        let airQualityData = await fetechAPI("https://demo.medvantage.tech:7094/api/DevicequalityMonitoring/GetAllDeviceQualityMonitoring?dashboardFilte=")
        let lpgData = await fetechAPI("https://demo.medvantage.tech:7094/api/LpgGasDevices/GetAllLpgGasDevices?dashboardFilte=")
        if (alarmData.status === 1) {
            setAlarmDataList(alarmData.responseValue)
        }
        if (tempData.status === 1) {
            setTempratureDataList(tempData.responseValue)
        }
        if (pAData.status === 1) {
            setPADataList(pAData.responseValue)
        }
        if (gasData.status === 1) {
            setGasDataList(gasData.responseValue)
        }
        if (airQualityData.status === 1) {
            setAirQualityDataList(airQualityData.responseValue)
        }
        if (lpgData.status === 1) {
            setLPGDataList(lpgData.responseValue)
        }
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <>
            <div className='bg1'>
                <div className='linebg1'><img src={sideline} alt='' /></div>
                <div className='dynamicgridbox mt-1'>

                    <div className='gridbox-items'>
                        <div className='gridbox1'>
                            <div className='gridboxheading-in'>
                                <div className='gridboxheading'>
                                    <div className='title-icn'><img src={alaram} alt='' /></div>
                                    <div className='title-txt'>Alarm Panel</div>
                                    <div className='title-bar'><img src={bar} alt='' /></div>
                                </div>
                            </div>
                            <div className='lef1'>
                                {
                                    alarmDataList.length !== 0 ? alarmDataList.map((val, ind) => (

                                        <div className='red-in'>
                                            <div className='red1'> <img src={ind % 2 === 0 ? redbg : ybg} alt='' /></div>
                                            <div className='gridbox-cn-1'>
                                                <div className='gridbox-cn-left'>
                                                    <div className='gridbox-cn-pic'> <img src={defaulticon} alt='' /></div>
                                                    <div className='gridbox-cn-content'>
                                                        <h4>{val.itemName}e</h4>
                                                        {/* <p>Building 06 - Floor 4th - Room 25</p> */}
                                                        <div className='typesec'>
                                                            <span>{val.subCategoryName}</span>
                                                            {/* <span>Pressure - 500psi</span> */}
                                                        </div>
                                                        <span className='alartime'><img src={timeclock} alt='' /> {val.warningViewTime} | 03-12-2023 </span>
                                                    </div>
                                                </div>
                                                <div className='gridbox-cn-rt'>
                                                    <div className='gp'><img src={callbg} alt='' /></div>
                                                    <div className='gp1'><img src={call} alt='' /></div>
                                                </div>
                                            </div>
                                        </div>

                                    ))
                                        :
                                        <div className='red-in'>
                                            <div className='red1'> <img src={redbg} alt='' /></div>
                                            <div className='gridbox-cn-1 d-flex flex-row justify-content-center align-items-center  ' style={{ width: "100%" }}>

                                                <div style={{ fontWeight: "600", fontSize: "16px" }}>
                                                    Data not found
                                                </div>


                                            </div>
                                        </div>
                                    // <div className='d-flex flex-row justify-content-center align-items-center ' style={{height:"250px"}}>
                                    //     <div style={{fontWeight:"600", fontSize:"16px"}}>
                                    //         Data not found
                                    //     </div>
                                    // </div>

                                }
                            </div>
                        </div>
                    </div>

                    <div className='gridbox-items'>
                        <div className='gridbox1 gridbox2'>
                            {/* <div className='ambient-monitoring'>
                                <div className='l1'><img src={l1} alt='' /></div>
                                <div className='l2'><img src={l2} alt='' /></div>
                                <div className='gridboxheading-in'>
                                    <div className='gridboxheading'>
                                        <div className='title-icn'><img src={monitoring} alt='' /></div>
                                        <div className='title-txt'>Ambient Monitoring</div>
                                        <div className='title-bar'><img src={bar} alt='' /></div>
                                    </div>
                                    <div className='gridboxrticon'>
                                        <img src={expend} alt='' />
                                    </div>
                                </div>
                                <div className='ambient-tbl'>

                                    <table className='ambient-inner'>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Location</th>
                                                <th>Floor</th>
                                                <th>Value</th>
                                                <th>Status</th>
                                                <th>Extension no.</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td colSpan={6}>
                                                    <table className='amn'>

                                                        {
                                                            airQualityDataList.length !== 0 ?
                                                                <>
                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td>ERA</td>
                                                                        <td>6th Floor</td>
                                                                        <td>-</td>
                                                                        <td>Inactive</td>
                                                                        <td>102</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={6}>
                                                                            <div className='tbdic-in'>
                                                                                <div className='tbdic'>PM1 <span>-21</span></div>
                                                                                <div className='tbdic'>PM1 <span>-21</span></div>
                                                                                <div className='tbdic'>PM1 <span>-21</span></div>
                                                                                <div className='tbdic'>PM1 <span>-21</span></div>
                                                                                <div className='tbdic'>PM1 <span>-21</span></div>
                                                                                <div className='tbdic'>PM1 <span>-21</span></div>
                                                                                <div className='tbdic'>PM1 <span>-21</span></div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                                :
                                                                <tr >
                                                                    <td colSpan={6} className='text-center'>
                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                            Data not found
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                        }

                                                    </table>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div> */}

                            <div className='ambient-monitoring'>
                                <div className='l1'><img src={l1} alt='' /></div>
                                <div className='l2'><img src={l2} alt='' /></div>
                                <div className='gridboxheading-in'>
                                    <div className='gridboxheading'>
                                        <div className='title-icn'><img src={lpg} alt='' /></div>
                                        <div className='title-txt'>LPG Leakage monitoring</div>
                                        <div className='title-bar'><img src={bar} alt='' /></div>
                                    </div>
                                    <div className='gridboxrticon' data-bs-toggle="modal" data-bs-target="#LPGLeakage">
                                        <img src={expend} alt='' />
                                    </div>
                                </div>
                                <div className='ambient-tbl'>

                                    <table className='ambient-inner'>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Device Name</th>
                                                <th>Location</th>
                                                <th>Leakage</th>
                                                <th>Status</th>
                                                <th>Status Date</th>
                                                <th>Emergency No.</th>
                                                {/* <th>DateTime</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                lpgDataList.length !== 0 ? lpgDataList.map((item, index) => (
                                                    <tr >
                                                        <td style={{ background: item.deviceStatus === "Active" ? "" : "red" }}>{index + 1}</td>
                                                        <td style={{ background: item.deviceStatus === "Active" ? "" : "red" }}>{item.deviceId}</td>
                                                        <td style={{ background: item.deviceStatus === "Active" ? "" : "red" }}>{item.locationName}</td>
                                                        <td style={{ background: item.deviceStatus === "Active" ? "" : "red" }}>{item.leakStatus}</td>
                                                        <td style={{ background: item.deviceStatus === "Active" ? "" : "red" }}>{item.deviceStatus}</td>
                                                        <td style={{ background: item.deviceStatus === "Active" ? "" : "red" }}>{item.leakStatusDate.split("T")[0] + " " + item.leakStatusDate.split("T")[1]}</td>
                                                        <td style={{ background: item.deviceStatus === "Active" ? "" : "red" }}>176</td>
                                                        {/* <td>{item.createdDate && item.createdDate.split("T")[0] +"  "+item.createdDate.split("T")[1] }</td> */}
                                                    </tr>
                                                ))

                                                    :
                                                    <tr >
                                                        <td colSpan={7} className='text-center'>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                Data not found
                                                            </div>
                                                        </td>
                                                    </tr>

                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className='Freezer-monitoring'>
                                <div className='l1'><img src={l1} alt='' /></div>
                                <div className='l2'><img src={l2} alt='' /></div>
                                <div className='gridboxheading-in'>
                                    <div className='gridboxheading'>
                                        <div className='title-icn'><img src={Freezer} alt='' /></div>
                                        <div className='title-txt'>Freezer temperature monitoring</div>
                                        <div className='title-bar'><img src={bar} alt='' /></div>
                                    </div>
                                    <div className='gridboxrticon' data-bs-toggle="modal" data-bs-target="#Freezermonitoring">
                                        <img src={expend} alt='' />
                                    </div>
                                </div>
                                <div className='Freezer-tbl'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Device Name</th>
                                                <th>Location</th>
                                                <th>Temperature</th>
                                                <th>Device Status</th>
                                                <th>Extension no.</th>
                                                <th>DateTime</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tempratureDataList.length !== 0 ? tempratureDataList.map((val, ind) => (
                                                    <tr >
                                                        <td style={{ color: "white", fontSize: "14px", background: val.deviceStatus === "Active" ? "" : "red" }}>{ind + 1}</td>
                                                        <td style={{ color: "white", fontSize: "14px", background: val.deviceStatus === "Active" ? "" : "red" }}>{val.deviceName}</td>
                                                        <td style={{ color: "white", fontSize: "14px", background: val.deviceStatus === "Active" ? "" : "red" }}>{val.location}</td>
                                                        <td style={{ color: "white", fontSize: "14px", background: val.deviceStatus === "Active" ? "" : "red" }}>{val.temperature}</td>
                                                        <td style={{ color: "white", fontSize: "14px", background: val.deviceStatus === "Active" ? "" : "red" }}>{val.deviceStatus}</td>
                                                        <td style={{ color: "white", fontSize: "14px", background: val.deviceStatus === "Active" ? "" : "red" }}>176</td>
                                                        <td style={{ color: "white", fontSize: "14px", background: val.deviceStatus === "Active" ? "" : "red" }}>{val.createdDate && val.createdDate.split("T")[0] + "  " + val.createdDate.split("T")[1]}</td>
                                                    </tr>
                                                ))
                                                    :
                                                    <tr >
                                                        <td colSpan={6} className='text-center'>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                Data not found
                                                            </div>
                                                        </td>
                                                    </tr>

                                            }


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='gridbox-items'>
                        <div className='gridbox1'>
                            <div className='Pasystem-monitoring'>
                                <div className='gridboxheading-in'>
                                    <div className='gridboxheading'>
                                        <div className='title-icn'><img src={pasystem} alt='' /></div>
                                        <div className='title-txt'>PA System</div>
                                        <div className='title-bar'><img src={bar} alt='' /></div>
                                    </div>
                                    <div className='gridboxrticon' data-bs-toggle="modal" data-bs-target="#PASystem">
                                        <img src={expend} alt='' />
                                    </div>
                                </div>
                                <div className='pas-tbl'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Device Name</th>
                                                <th>Location</th>
                                                <th>Device Status</th>
                                                <th>Emergency No.</th>
                                                <th>DateTime</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                pADataList.length !== 0 ? pADataList.map((val, ind) => (
                                                    <tr >
                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{ind + 1}</td>
                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.machineName}</td>
                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.loacationName}</td>
                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.deviceStatus}</td>
                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>176</td>
                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.createdDate && val.createdDate.split("T")[0] + "  " + val.createdDate.split("T")[1]}</td>
                                                    </tr>
                                                ))
                                                    :
                                                    <tr >
                                                        <td colSpan={6} className='text-center'>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                Data not found
                                                            </div>
                                                        </td>
                                                    </tr>

                                            }


                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className='gas-monitoring'>
                                <div className='gridboxheading-in'>
                                    <div className='gridboxheading'>
                                        <div className='title-icn'><img src={gas} alt='' /></div>
                                        <div className='title-txt'>Modular OT Gas Monitoring </div>
                                        <div className='title-bar'><img src={bar} alt='' /></div>
                                    </div>
                                    <div className='gridboxrticon' data-bs-toggle="modal" data-bs-target="#GasMonitoring">
                                        <img src={expend} alt='' />
                                    </div>
                                </div>
                                <div className='gas-tbl'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Parameters</th>
                                                <th>Location</th>
                                                <th><div className='d-flex flex-column'>
                                                    <span>Live Pressure</span>
                                                    <span>(Low | Normal | High)</span>
                                                </div></th>
                                                <th>Status</th>
                                                <th>Emergency No.</th>
                                                <th>DateTime</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                gasDataList.length !== 0 ? gasDataList.map((val, ind) => (
                                                    <tr >
                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{ind + 1}</td>
                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.gasName}</td>
                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.operationTheterName}</td>
                                                        {/* <td style={{background:val.deviceStatus === "Active"?"":"#D28282"}}>{val.pressure} Normal</td> */}
                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}> Normal</td>
                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.deviceStatus}</td>
                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>176</td>
                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.createdDate && val.createdDate}</td>
                                                    </tr>
                                                )) :
                                                    <tr>
                                                        <td colSpan={6} className='text-center' style={{ background: 'transparent' }}>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                <div className='loadersec'>
                                                                    <h3>Data not found</h3>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                            }



                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* <div className='Lpg-monitoring'>
                                <div className='gridboxheading-in'>
                                    <div className='gridboxheading'>
                                        <div className='title-icn'><img src={gas} alt='' /></div>
                                        <div className='title-txt'>LPG Leakage monitoring</div>
                                        <div className='title-bar'><img src={bar} alt='' /></div>
                                    </div>
                                    <div className='gridboxrticon'>
                                        <img src={expend} alt='' />
                                    </div>
                                </div>
                                <div className='Lpg-tbl'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Device Name</th>
                                                <th>Location</th>
                                                <th>Leakage</th>
                                                <th>Status</th>
                                                <th>Status Date</th>
                                                <th>Emergency No.</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                lpgDataList.length !== 0 ? lpgDataList.map((item, index) => (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.deviceId}</td>
                                                        <td>{item.locationName}</td>
                                                        <td>{item.leakStatus}</td>
                                                        <td>Active</td>
                                                        <td>{item.leakStatusDate.split("T")[0]}</td>
                                                        <td>176</td>
                                                    </tr>
                                                ))

                                                    :
                                                    <tr >
                                                        <td colSpan={6} className='text-center'>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                Data not found
                                                            </div>
                                                        </td>
                                                    </tr>

                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div> */}

                            <div className='Lpg-monitoring'>
                                <div className='gridboxheading-in'>
                                    <div className='gridboxheading'>
                                        <div className='title-icn'><img src={monitoring} alt='' /></div>
                                        <div className='title-txt'>Ambient Monitoring</div>
                                        <div className='title-bar'><img src={bar} alt='' /></div>
                                    </div>
                                    <div className='gridboxrticon' data-bs-toggle="modal" data-bs-target="#AmbientMonitoring">
                                        <img src={expend} alt='' />
                                    </div>
                                </div>
                                
                                <div className='Lpg-tbl'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Device Name</th>
                                                <th>Location Name</th>
                                                <th>AQI</th>
                                                <th>PM1</th>
                                                <th>PM2_5</th>
                                                <th>PM10</th>
                                                <th>PM1_air</th>
                                                <th>PM2_5_AIR</th>
                                                <th>PM10_AIR</th>
                                                <th>Particles_0_3</th>
                                                <th>Particles_0_5</th>
                                                <th>Particles_1_0</th>
                                                <th>Particles_2_5</th>
                                                <th>Particles_5_0</th>
                                                <th>Particles_10_0</th>
                                                <th>TVOC</th>
                                                <th>ECO2</th>
                                                <th>AQI_STR</th>
                                                <th>Temp.</th>
                                                <th>Humidity</th>
                                                <th>Status</th>
                                                <th>DateTime</th>
                                            </tr>
                                        </thead>
                                        <tbody>



                                            {
                                                airQualityDataList.length !== 0 ?
                                                    <>
                                                        {
                                                            airQualityDataList.map((val, ind) => {
                                                                return (
                                                                    <tr >
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>1</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.deviceType}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.locationName}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.aqi}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.pm1}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.pm2_5}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.pm10}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.pm1_air}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.pm2_5_air}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.pm10_air}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.particles_0_3}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.particles_0_5}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.particles_1_0}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.particles_2_5}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.particles_5_0}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.particles_10_0}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.eco2}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.tvoc}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.aqi_str}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.temp}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.humidity}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.deviceStatus}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.createdDate && val.createdDate.split("T")[0] + "  " + val.createdDate.split("T")[1]}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <tr >
                                                        <td colSpan={6} className='text-center' style={{ background: 'transparent' }}>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                <div className='loadersec'>
                                                                    <h3>Data not found</h3>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                            }





                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>





                    {/* modal popup  LPGLeakage*/}
                    <div className="modal fade" id="LPGLeakage" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="LPGLeakageLabel" aria-hidden="true">
                        <div className="modal-dialog  modal-xl custummodalbms">
                            <div className="modal-content custommodalcontent">

                                <div className="modal-body custumbodybms">
                                    <div className='gridbox1 gridbox2'>
                                        <div className='mixxbg'>
                                            <div className='ambient-monitoring'>
                                                <div className='l1'><img src={l1} alt='' /></div>
                                                <div className='l2'><img src={l2} alt='' /></div>
                                                <div className='gridboxheading-in'>
                                                    <div className='gridboxheading'>
                                                        <div className='title-icn'><img src={lpg} alt='' /></div>
                                                        <div className='title-txt'>LPG Leakage monitoring</div>
                                                        <div className='title-bar'><img src={bar} alt='' /></div>
                                                    </div>
                                                    <div className='gridboxrticon' data-bs-dismiss="modal" aria-label="Close">
                                                     <img src={compress} alt=''  />
                                                    </div>
                                                </div>
                                                <div className='ambient-tbl'>

                                                    <table className='ambient-inner'>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Device Name</th>
                                                                <th>Location</th>
                                                                <th>Leakage</th>
                                                                <th>Status</th>
                                                                <th>Status Date</th>
                                                                <th>Emergency No.</th>
                                                                {/* <th>DateTime</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                lpgDataList.length !== 0 ? lpgDataList.map((item, index) => (
                                                                    <tr >
                                                                        <td style={{ background: item.deviceStatus === "Active" ? "" : "red" }}>{index + 1}</td>
                                                                        <td style={{ background: item.deviceStatus === "Active" ? "" : "red" }}>{item.deviceId}</td>
                                                                        <td style={{ background: item.deviceStatus === "Active" ? "" : "red" }}>{item.locationName}</td>
                                                                        <td style={{ background: item.deviceStatus === "Active" ? "" : "red" }}>{item.leakStatus}</td>
                                                                        <td style={{ background: item.deviceStatus === "Active" ? "" : "red" }}>{item.deviceStatus}</td>
                                                                        <td style={{ background: item.deviceStatus === "Active" ? "" : "red" }}>{item.leakStatusDate.split("T")[0] + " " + item.leakStatusDate.split("T")[1]}</td>
                                                                        <td style={{ background: item.deviceStatus === "Active" ? "" : "red" }}>176</td>
                                                                        {/* <td>{item.createdDate && item.createdDate.split("T")[0] +"  "+item.createdDate.split("T")[1] }</td> */}
                                                                    </tr>
                                                                ))

                                                                    :
                                                                    <tr >
                                                                        <td colSpan={7} className='text-center'>
                                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                                Data not found
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* modal popup  LPGLeakage*/}

                    {/* modal popup  FREEZER TEMPERATURE MONITORING*/}
                    <div className="modal fade" id="Freezermonitoring" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="FreezermonitoringLabel" aria-hidden="true">
                        <div className="modal-dialog  modal-xl custummodalbms">
                            <div className="modal-content custommodalcontent">

                                <div className="modal-body custumbodybms">
                                    <div className='gridbox1 gridbox2'>
                                        <div className='mixxbg'>
                                            <div className='Freezer-monitoring'>
                                                <div className='l1'><img src={l1} alt='' /></div>
                                                <div className='l2'><img src={l2} alt='' /></div>
                                                <div className='gridboxheading-in'>
                                                    <div className='gridboxheading'>
                                                        <div className='title-icn'><img src={Freezer} alt='' /></div>
                                                        <div className='title-txt'>Freezer temperature monitoring</div>
                                                        <div className='title-bar'><img src={bar} alt='' /></div>
                                                    </div>
                                                    <div className='gridboxrticon' data-bs-dismiss="modal" aria-label="Close">
                                                    <img src={compress} alt='' />
                                                    </div>
                                                </div>
                                                <div className='Freezer-tbl'>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Device Name</th>
                                                                <th>Location</th>
                                                                <th>Temperature</th>
                                                                <th>Device Status</th>
                                                                <th>Extension no.</th>
                                                                <th>DateTime</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                tempratureDataList.length !== 0 ? tempratureDataList.map((val, ind) => (
                                                                    <tr >
                                                                        <td style={{ color: "white", fontSize: "14px", background: val.deviceStatus === "Active" ? "" : "red" }}>{ind + 1}</td>
                                                                        <td style={{ color: "white", fontSize: "14px", background: val.deviceStatus === "Active" ? "" : "red" }}>{val.deviceName}</td>
                                                                        <td style={{ color: "white", fontSize: "14px", background: val.deviceStatus === "Active" ? "" : "red" }}>{val.location}</td>
                                                                        <td style={{ color: "white", fontSize: "14px", background: val.deviceStatus === "Active" ? "" : "red" }}>{val.temperature}</td>
                                                                        <td style={{ color: "white", fontSize: "14px", background: val.deviceStatus === "Active" ? "" : "red" }}>{val.deviceStatus}</td>
                                                                        <td style={{ color: "white", fontSize: "14px", background: val.deviceStatus === "Active" ? "" : "red" }}>176</td>
                                                                        <td style={{ color: "white", fontSize: "14px", background: val.deviceStatus === "Active" ? "" : "red" }}>{val.createdDate && val.createdDate.split("T")[0] + "  " + val.createdDate.split("T")[1]}</td>
                                                                    </tr>
                                                                ))
                                                                    :
                                                                    <tr >
                                                                        <td colSpan={6} className='text-center'>
                                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                                Data not found
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                            }


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* modal popup  FREEZER TEMPERATURE MONITORING*/}

                    {/* modal popup  PA System*/}
                    <div className="modal fade" id="PASystem" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="PASystemLabel" aria-hidden="true">
                        <div className="modal-dialog  modal-xl custummodalbms">
                            <div className="modal-content custommodalcontent">

                                <div className="modal-body custumbodybms">
                                    <div className='gridbox1 gridbox2'>
                                        <div className='mixxbg'>
                                            <div className='Pasystem-monitoring'>
                                                <div className='gridboxheading-in'>
                                                    <div className='gridboxheading'>
                                                        <div className='title-icn'><img src={pasystem} alt='' /></div>
                                                        <div className='title-txt'>PA System</div>
                                                        <div className='title-bar'><img src={bar} alt='' /></div>
                                                    </div>
                                                    <div className='gridboxrticon' data-bs-dismiss="modal" aria-label="Close">
                                                    <img src={compress} alt='' />
                                                    </div>
                                                </div>
                                                <div className='pas-tbl'>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Device Name</th>
                                                                <th>Location</th>
                                                                <th>Device Status</th>
                                                                <th>Emergency No.</th>
                                                                <th>DateTime</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                pADataList.length !== 0 ? pADataList.map((val, ind) => (
                                                                    <tr >
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{ind + 1}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.machineName}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.loacationName}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.deviceStatus}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>176</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.createdDate && val.createdDate.split("T")[0] + "  " + val.createdDate.split("T")[1]}</td>
                                                                    </tr>
                                                                ))
                                                                    :
                                                                    <tr >
                                                                        <td colSpan={6} className='text-center'>
                                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                                Data not found
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                            }


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* modal popup PA System*/}

                    {/* modal popup  Gas monitoring*/}
                    <div className="modal fade" id="GasMonitoring" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="GasMonitoringLabel" aria-hidden="true">
                        <div className="modal-dialog  modal-xl custummodalbms">
                            <div className="modal-content custommodalcontent">

                                <div className="modal-body custumbodybms">
                                    <div className='gridbox1 gridbox2'>
                                        <div className='mixxbg mixbg1'>
                                            <div className='gas-monitoring'>
                                                <div className='gridboxheading-in'>
                                                    <div className='gridboxheading'>
                                                        <div className='title-icn'><img src={gas} alt='' /></div>
                                                        <div className='title-txt'>Modular OT Gas Monitoring </div>
                                                        <div className='title-bar'><img src={bar} alt='' /></div>
                                                    </div>
                                                    <div className='gridboxrticon' data-bs-dismiss="modal" aria-label="Close">
                                                    <img src={compress} alt='' />
                                                    </div>
                                                </div>
                                                <div className='gas-tbl'>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Parameters</th>
                                                                <th>Location</th>
                                                                <th><div className='d-flex flex-column'>
                                                                    <span>Live Pressure</span>
                                                                    <span>(Low | Normal | High)</span>
                                                                </div></th>
                                                                <th>Status</th>
                                                                <th>Emergency No.</th>
                                                                <th>DateTime</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                gasDataList.length !== 0 ? gasDataList.map((val, ind) => (
                                                                    <tr >
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{ind + 1}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.gasName}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.operationTheterName}</td>
                                                                        {/* <td style={{background:val.deviceStatus === "Active"?"":"#D28282"}}>{val.pressure} Normal</td> */}
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}> Normal</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.deviceStatus}</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>176</td>
                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.createdDate && val.createdDate}</td>
                                                                    </tr>
                                                                )) :
                                                                    <tr>
                                                                        <td colSpan={6} className='text-center' style={{ background: 'transparent' }}>
                                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                                <div className='loadersec'>
                                                                                    <h3>Data not found</h3>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                            }



                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* modal popup Gas monitoring*/}

                    {/* modal popup  Ambient Monitoring*/}
                    <div className="modal fade" id="AmbientMonitoring" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="AmbientMonitoringLabel" aria-hidden="true">
                        <div className="modal-dialog  modal-xl custummodalbms">
                            <div className="modal-content custommodalcontent">

                                <div className="modal-body custumbodybms">
                                    <div className='gridbox1 gridbox2'>
                                        <div className='mixxbg mixbg1'>
                                            <div className='Lpg-monitoring'>
                                                <div className='gridboxheading-in'>
                                                    <div className='gridboxheading'>
                                                        <div className='title-icn'><img src={monitoring} alt='' /></div>
                                                        <div className='title-txt'>Ambient Monitoring</div>
                                                        <div className='title-bar'><img src={bar} alt='' /></div>
                                                    </div>
                                                    <div className='gridboxrticon' data-bs-dismiss="modal" aria-label="Close">
                                                    <img src={compress} alt='' />
                                                    </div>
                                                </div>
                                                <div className='Lpg-tbl'>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Device Name</th>
                                                                <th>Location Name</th>
                                                                <th>AQI</th>
                                                                <th>PM1</th>
                                                                <th>PM2_5</th>
                                                                <th>PM10</th>
                                                                <th>PM1_air</th>
                                                                <th>PM2_5_AIR</th>
                                                                <th>PM10_AIR</th>
                                                                <th>Particles_0_3</th>
                                                                <th>Particles_0_5</th>
                                                                <th>Particles_1_0</th>
                                                                <th>Particles_2_5</th>
                                                                <th>Particles_5_0</th>
                                                                <th>Particles_10_0</th>
                                                                <th>TVOC</th>
                                                                <th>ECO2</th>
                                                                <th>AQI_STR</th>
                                                                <th>Temp.</th>
                                                                <th>Humidity</th>
                                                                <th>Status</th>
                                                                <th>DateTime</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>



                                                            {
                                                                airQualityDataList.length !== 0 ?
                                                                    <>
                                                                        {
                                                                            airQualityDataList.map((val, ind) => {
                                                                                return (
                                                                                    <tr >
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>1</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.deviceType}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.locationName}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.aqi}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.pm1}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.pm2_5}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.pm10}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.pm1_air}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.pm2_5_air}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.pm10_air}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.particles_0_3}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.particles_0_5}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.particles_1_0}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.particles_2_5}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.particles_5_0}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.particles_10_0}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.eco2}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.tvoc}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.aqi_str}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.temp}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.humidity}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.deviceStatus}</td>
                                                                                        <td style={{ background: val.deviceStatus === "Active" ? "" : "#D28282" }}>{val.createdDate && val.createdDate.split("T")[0] + "  " + val.createdDate.split("T")[1]}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                    :
                                                                    <tr >
                                                                        <td colSpan={6} className='text-center' style={{ background: 'transparent' }}>
                                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                                <div className='loadersec'>
                                                                                    <h3>Data not found</h3>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* modal popup Ambient Monitoring*/}

                </div>
            </div>
        </>
    )
}
