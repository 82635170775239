import React, { useEffect, useState } from 'react'
import TosterUnderProcess from '../../../Component/TosterUnderProcess'
import Toster from '../../../Component/Toster'
import IconEdit from '../../../assets/images/icons/IconEdit.svg'
import IconDelete from '../../../assets/images/icons/IconDelete.svg'
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import Heading from '../../../Component/Heading';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next'

import InsertTopicGroupMaster from '../../apis/Master/TopicGroupMaster/InsertTopicGroupMaster'
import UpdateTopicGroupMaster from '../../apis/Master/TopicGroupMaster/UpdateTopicGroupMaster'
import GetTopicGroupMaster from '../../apis/Master/TopicGroupMaster/GetTopicGroupMaster'
import DeleteTopicGroupMaster from '../../apis/Master/TopicGroupMaster/DeleteTopicGroupMaster'
import DropdownWithSearch from '../../../Component/DropdownWithSearch'
import GetSetMaster from '../../apis/Master/SetMaster/GetSetMaster'
import GetQuestionMaster from '../../apis/Master/QuestionMaster/GetQuestionMaster'
import InsertTopicGroupAssign from '../../apis/Master/TopicGroupAssign/InsertTopicGroupAssign'
import GetTopicGroupAssign from '../../apis/Master/TopicGroupAssign/GetTopicGroupAssign'
import UpdateTopicGroupAssign from '../../apis/Master/TopicGroupAssign/UpdateTopicGroupAssign'
import DeleteTopicGroupAssign from '../../apis/Master/TopicGroupAssign/DeleteTopicGroupAssign'

export default function TopicGroupmAssign() {
  let [topicGroupAssignData, setTopicGroupAssignData] = useState([])
  let [updateBool, setUpdateBool] = useState(0)
  let [sendForm, setSendForm] = useState({
    "setID": 0,
    "topicGroupID": 0,
    "questionID": 0,
    "userId": window.userId,
    "clientId": window.clientId
  })
  let [loder, setLoder] = useState(1)
  let [rowId, setRowId] = useState('')

  let [showUnderProcess, setShowUnderProcess] = useState(0)
  let [showToster, setShowToster] = useState(0)
  let [tosterMessage, setTosterMessage] = useState("")
  let [tosterValue, setTosterValue] = useState(0)
  let [setList, setSetList] = useState([])
  let [topicGroupList, setTopicGroupList] = useState([])
  let [questionList, setQuestionList] = useState([])

  let [setText, setSetText] = useState([])
  let [topicGroupText, setTopicGroupText] = useState([])
  let [questionText, setQuestionText] = useState([])

  let [clearDropdown, setClearDropdown] = useState(0)

  const [searchTerm, setSearchTerm] = useState('');

  const { t } = useTranslation();
  let [content, setContent] = useState('');
  // Function to handle changes in the search term
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };



  //Handle Save
  let saveForm = async () => {
    console.log('sendForm', sendForm)
    let valresponse = sendForm.setID !== 0 && sendForm.topicGroupID !== 0 && sendForm.questionID !== 0? true : false
    if (valresponse) {
      setShowUnderProcess(1)
      // let response = await PostAPIBedMaster(sendForm);
      let response = await InsertTopicGroupAssign(sendForm)
      if (response.status === 1) {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage("Data Save SuccessFully!")
        setTosterValue(0)
        setSendForm({ "userId": window.userId })
        setTimeout(() => {
          setShowToster(0)
        }, 2000)

        handleClear(1);
      }
      else {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage(response.responseValue)
        setTosterValue(1)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
      }
      // setSendForm({ "userId": window.userId })
      getdata()
    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage("Field can't be blank!")
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
    }

  }

  // get data from api
  let getdata = async () => {
    let response = await GetTopicGroupMaster()
    let responseSet = await GetSetMaster()
    let responseQuestion = await GetQuestionMaster()
    let responseTopicGroupAssignList = await GetTopicGroupAssign()
    // console.log(response);

    if (responseTopicGroupAssignList.status === 1) {
      setLoder(0)
      setTopicGroupAssignData(responseTopicGroupAssignList.responseValue)
    }
    if (responseSet.status === 1) {
      setLoder(0)
      setSetList(responseSet.responseValue)
    }
    if (response.status === 1) {
      setLoder(0)
      setTopicGroupList(response.responseValue)
    }
    if (responseQuestion.status === 1) {
      setLoder(0)
      setQuestionList(responseQuestion.responseValue)
    }
  }

  //Handle Change
  let handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setSendForm(sendForm => ({
      ...sendForm,
      [name]: value,
      "userId": window.userId,
      "clientId": window.clientId
    }))
    setSetText("")
    setTopicGroupText("")
    setQuestionText("")
  }



  // get data for update 
  let handleUpdate = (data) => {
    // console.table(id, bedname);
    setUpdateBool(1)
    setSendForm(sendForm => ({
      ...sendForm,
      "id": data.id,
      "setID": data.setID,
      "topicGroupID": data.topicGroupID,
      "questionID": data.questionID,
      "clientId": window.clientId
    }))
    setSetText(data.setName)
    setTopicGroupText(data.topicName)
    setQuestionText(data.questionText)
  }

  // Handle Update
  let saveUpdate = async () => {
    let valresponse = sendForm.setID !== 0 && sendForm.topicGroupID !== 0 && sendForm.questionID !== 0? true : false

    if (valresponse) {
      setShowUnderProcess(1)
      // let response = await PutAPIBedMaster(sendForm)
      let response = await UpdateTopicGroupAssign(sendForm)
      if (response.status === 1) {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage("Data Updated SuccessFully!")
        setTosterValue(0)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)

        setUpdateBool(0)
        getdata()
        handleClear(1);
      }
      else {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage(response.responseValue)
        setTosterValue(1)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
      }

    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage("Field can't be blank!")
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
    }
  }

  //Handle Delete
  let handleDeleteRow = async () => {
    setShowUnderProcess(1);
    let obj = {
      id: rowId,
      "userId": window.userId,
      "clientId": window.clientId
    }
    // let response = await DeleteAPIBedMaster(obj)
    let response = await DeleteTopicGroupAssign(obj)
    if (response.status === 1) {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage("Data Deleted SuccessFully!")
      setTosterValue(0)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
      getdata()
      handleClear(1);
    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage(response.responseValue)
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
    }
  }

  //  clear data 
  let handleClear = (val) => {
    setUpdateBool(0)
    setSendForm({
      "setID": 0,
      "topicGroupID": 0,
      "questionID": 0,
      "userId": window.userId,
      "clientId": window.clientId
    })

    setClearDropdown(val)
  }

  useEffect(() => {
    getdata();

    ///################### table row ative
    let getTableRows = document.querySelectorAll('.med-table tbody tr');
    for (const getTableRow of getTableRows) {
      getTableRow.addEventListener("click", function () {
        // alert('Hello');                 
        resetTableRows();
        getTableRow.classList.add("active");
      });
    }

    function resetTableRows() {
      for (const getTableRow of getTableRows) {
        getTableRow.classList.remove("active");
      }
    }
    setContent(JSON.parse(window.sessionStorage.getItem("departmentmenu")).menuList[0].subMenuList[0].content)
  }, [])
  document.body.dir = i18next.dir();
  return (
    <>
      <section className="main-content pt-3 mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="med-box">
                <div className="title">Topic Group Assign </div>
                {/* <div className="title">{content} </div> */}
                <div className="inner-content">
                  <div className="d-flex flex-wrap align-content-end">
                    <div className="mb-2 me-2">
                      <label htmlFor="locationId" className="form-label">Set<span className="starMandatory">*</span></label>

                      {setList && <DropdownWithSearch defaulNname={"Select Set"} name="setID" list={setList} valueName="id" displayName="setName" editdata={setText} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                    </div>
                    <div className="mb-2 me-2">
                      <label htmlFor="locationId" className="form-label">Topic<span className="starMandatory">*</span></label>

                      {topicGroupList && <DropdownWithSearch defaulNname={"Select Topic"} name="topicGroupID" list={topicGroupList} valueName="id" displayName="topicName" editdata={topicGroupText} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                    </div>
                    <div className="mb-2 me-2">
                      <label htmlFor="locationId" className="form-label">Question<span className="starMandatory">*</span></label>

                      {questionList && <DropdownWithSearch defaulNname={"Select Question"} name="questionID" list={questionList} valueName="id" displayName="questionText" editdata={questionText} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                    </div>
                    <div className="mb-2 relative">
                      <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                      <div>
                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                          <>
                            {showToster === 1 ?
                              <Toster value={tosterValue} message={tosterMessage} />

                              : <div>
                                {updateBool === 0 ?
                                  <>
                                    <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={saveForm}><img src={saveButtonIcon} className='icnn' alt='' />{t("Save")} </button>
                                    <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={()=>handleClear(1)}><img src={clearIcon} className='icnn' alt='' />{t("Clear")}</button>
                                  </>
                                  :
                                  <>
                                    <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={saveUpdate}>{t("UPDATE")}</button>
                                    <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { setUpdateBool(0); handleClear(1) }}>{t("Cancel")}</button>
                                  </>
                                }
                              </div>}
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-1">
              <div className='handlser'>
                <Heading text={"Topic Group Assign List"} />
                {/* <Heading text={content} /> */}
                <div style={{ position: 'relative' }}>
                  <input type="text" className='form-control form-control-sm' placeholder={t("Search")} value={searchTerm} onChange={handleSearch} />
                  <span className="tblsericon"><i className="fas fa-search"></i></span>
                </div>
              </div>
              <div className="med-table-section" style={{ "height": "74vh" }}>
                <table className="med-table border_ striped">
                  <thead>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>{t("S.No.")} </th>
                      <th>Set Name</th>
                      <th>Topic </th>
                      <th>Question </th>
                      {/* <th>{t("Bed Code")}</th> */}
                      <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topicGroupAssignData && topicGroupAssignData.filter((val) => `${val.bedName}`.toLowerCase().includes(searchTerm.toLowerCase())).map((key, index) => {
                      return (
                        <tr>
                          <td className="text-center">{index + 1}</td>
                          <td>{key.setName}</td>
                          <td>{key.topicName}</td>
                          <td>{key.questionText}</td>
                          {/* <td>{key.bedCode}</td> */}
                          <td>
                            <div className="action-button">
                              <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom" title="Edit Row" onClick={() => { handleUpdate(key) }}><img src={IconEdit} alt='' /></div>
                              <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={IconDelete} onClick={() => { setRowId(key.id) }} alt='' /></div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>


          </div>
        </div>


        {/*  <!------------------- Start Delete Modal ---------------------------------->  */}
        <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
          <div className="modal-dialog modalDelete">
            <div className="modal-content">

              <div className="modal-body modelbdy text-center">
                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                <div className='popDeleteTitle mt-3'>{t("Delete?")}</div>
                <div className='popDeleteContent'>{t("Are_you_sure_you_want_to_delete?")}</div>
              </div>
              <div className="modal-footer1 text-center">

                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                <button type="button" className="btn-delete popBtnDelete" onClick={handleDeleteRow} data-bs-dismiss="modal">{t("Delete")}</button>
              </div>
            </div>
          </div>
        </div>
        {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}





      </section>
    </>
  )
}
