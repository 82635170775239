import React, { useEffect, useState } from 'react'
import profilepic from '../../../../../assets/images/DischargePatient/Profile.png'
import blankprofilepic from '../../../../../assets/images/DischargePatient/blankprofilepic.svg'
import m1 from '../../../../../assets/images/DischargePatient/m1.png'
import m2 from '../../../../../assets/images/DischargePatient/m2.png'
import m3 from '../../../../../assets/images/DischargePatient/m3.png'
import m4 from '../../../../../assets/images/DischargePatient/m4.png'
import m5 from '../../../../../assets/images/DischargePatient/m5.png'
import GetPatientDetails from './Api/GetPatientDetails';
import AlertToster from '../../../../../Component/AlertToster'
import Loader from '../../../../../Component/Loader'
import GetPatientIPDAllHistory from './Api/GetPatientIPDAllHistory'
import DepartmentNavbar from '../../../OPD/OPDSharePage/OPDInvestigation/DepartmentNavbar'
import OPDInvestigationRight from '../../../OPD/OPDSharePage/OPDInvestigation/OPDInvestigationRight'
import pulseVitalIcon from '../../../../../../src/assets/images/icons/pulseVitalIcon.svg'
import bpSysDysVitalIcon from '../../../../../../src/assets/images/icons/bpSysDysVitalIcon.svg'
import bloodPressureVitalIcon from '../../../../../../src/assets/images/icons/bloodPressureVitalIcon.svg'
import tempratureVitalIcon from '../../../../../../src/assets/images/icons/tempratureVitalIcon.svg'
import sketchVitalIcon from '../../../../../../src/assets/images/icons/sketchVitalIcon.svg'
import { Link } from 'react-router-dom'
import PersonalDashboardIndexSecond from './patientpersonalDashboardBody/personalDashboardIndexSecond'
import GetPatientHistoryByUHID from '../../../../API/OPD/Prescription/PatientHistoryOnClick/GetPatientHistoryByUHID'
import TableContainer from '../../../../../Component/TableContainer'
import GetAllPaymentTypeByUHID from './Api/GetAllPaymentTypeByUHID'
import GetAdmissionHistoryByUHID from './Api/GetAdmissionHistoryByUHID'
import IPDPatientHistoryPopUp from './IPDPatientHistoryPopUp'
import viewicn from "../../../../../assets/images/PatientListIcons/viewicn.svg"
import ScifiDashboard from '../../../../../ScifiDashboard/ScifiDashboard'

export default function PatientProfile() {
  let [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
  let [UHID, setUHID] = useState("");
  let [showLoder, setShowLoder] = useState(0);
  let [showMedicationLoader, setShowMedicationLoader] = useState(0);
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [showErrMessage, setShowErrMessage] = useState('');
  let [patientDetails, setPatientDetails] = useState([]);
  let [medicationList, setMedicationList] = useState([]);
  let [patientComplainHistory, setPatientComplainHistory] = useState([]);
  let [activeId, setActiveId] = useState("");
  let [activeUHID, setActiveUHID] = useState(JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid);
  let [patientVitals, setPatientVitals] = useState([]);
  let [showBodyParts, setShowBodyParts] = useState(0);
  let [patientHistoryList, setPatientHistoryList] = useState([]);
  let [patientInsuranceList, setPatientInsuranceList] = useState([]);
  let [pmid, setPmid] = useState("");
  let [admissionHistory, setAdmissionHistory] = useState([]);


  // console.log(  'activeUHID', activeUHID)
  console.log('patientComplainHistory', patientComplainHistory)






  let getPatientDetails = async () => {
    setShowLoder(1)
    let activeUHID = JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid;
    // (activeUHID);setUHID
    const response = await GetPatientDetails(activeUHID);
    console.log('patient details', response);
    // console.log('Patient Details', response.responseValue);
    if (response.status === 1) {
      setShowLoder(0);
      setPatientDetails(response.responseValue[0])
    }
    else {
      setShowLoder(0);
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
    }


  }

  let getPatientIPDAllHistory = async () => {
    setShowMedicationLoader(1)

    //let activeUHID = JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid //window["uhid"] === null ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : window["uhid"];

    const response = await GetPatientIPDAllHistory(activeUHID, userID);
    let patientHistoryByUHIDResp = await GetPatientHistoryByUHID(activeUHID);

    const issuanceResp = await GetAllPaymentTypeByUHID(activeUHID);
    const admissionHistoryResp = await GetAdmissionHistoryByUHID(activeUHID);

    // console.log("admissionHistoryResp", admissionHistoryResp);   

    // console.log('issuanceResp', issuanceResp);
    // console.log('Historty', response.responseValue)
    if (response.status === 1) {
      setShowMedicationLoader(0);
      setMedicationList(response.responseValue.runningPrescription);
      setPatientComplainHistory(response.responseValue.patientComplainHistory);
      setPatientVitals(response.responseValue.patientVitals);

    }
    if (patientHistoryByUHIDResp.status === 1) {
      setPatientHistoryList(patientHistoryByUHIDResp.responseValue.patientDetails);
    }
    if (issuanceResp.status === 1) {

      setPatientInsuranceList(issuanceResp.responseValue);
    }
    if (admissionHistoryResp.status === 1) {
      setAdmissionHistory(admissionHistoryResp.responseValue);

    }
    else {
      setShowMedicationLoader(0)
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
    }

  }


  useEffect(() => {
    getPatientDetails();
    getPatientIPDAllHistory();


  }, []);

  return (
    <>

      <div className='btnToggleDigitalTwin'>
        {
          showBodyParts === 1 ?
            <div onClick={() => { setShowBodyParts(0) }}>
              <button type="button" className="btn btn-save-fill btn-sm">Personal Dashboard</button>
            </div>
            :
            <div onClick={() => { setShowBodyParts(1) }}>
              <button type="button" className="btn btn-save-fill btn-sm">Digital Twin</button>
            </div>
        }
      </div>

      {
        showBodyParts === 1 ?
          <div className='patientDT'>
            <ScifiDashboard uhid="UHID01142" />
          </div>
          :
          // <PersonalDashboardIndexSecond />

        <div className="row">
          <div className="col-md-8 col-sm-12 plt1 wt">

            <div className='whitebg'>
              <div className='cheifcProfile-in'>
                <div className='cheifcProfile1'>
                  {/* <div className='cheifp'><img src={profilepic}/></div> */}
                  <div className='cheifp'><img src={blankprofilepic} alt='' /></div>
                </div>
                <div className='cheifcProfile'>
                  <div className='cprp'>
                    <div className='cprp-in'>
                      <div className='cprp-in2'>
                        <div className='cprp-in21'><h3>{patientDetails.patientName}</h3></div>
                        <div className='cprp-in21'> <Link to={'tel:' + patientDetails.mobileNo}><i className='fa fa-phone'></i></Link></div>
                        <div className='cprp-in21' style={{ cursor: "pointer" }}><i className='fa fa-envelope'></i></div>
                      </div>
                      <div className='cprp-in2'>
                        <div className='cprp-in3'><i className='fa fa-user'></i> {patientDetails.gender}</div>
                        <div className='cprp-in3'><i className='fa fa-calendar'></i> {patientDetails.dob}&nbsp;({patientDetails.age + '' + patientDetails.agetype})</div>
                        <div className='cprp-in3'><i className='fa fa-map-marker'></i> {patientDetails.address}</div>
                      </div>
                    </div>
                    <div className='cprp-in desstaus'>
                      <h4>Discharge Status</h4>
                      <span>Stable</span>
                    </div>
                  </div>
                  <div className='cprp1'>
                    <div className='cprp1-in'><h4>Admission Date</h4><p>{patientDetails.admitDate}</p></div>
                    <div className='cprp1-in'><h4>Discharge Date</h4><p>{patientDetails.dischargeDate !== '0000-00-00' ? patientDetails.dischargeDate : ''}</p></div>
                    <div className='cprp1-in'><h4>Treating Doctor</h4><p>{patientDetails.doctorName}</p></div>
                    <div className='cprp1-in'><h4>Ward</h4><p>{patientDetails.wardName}</p></div>
                    <div className='cprp1-in'><h4>Bed No.</h4><p></p></div>
                    {/* <div className='cprp1-in'><h4>Height</h4><p></p></div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className='whitebg'>
              <div className='didp_d'>
                <h2>Diagnosis</h2>
                {/* <div className='diagnosis-sub-text'>Fracture both Bone Shaft Leg Right</div> */}
                <div className='didp_ac'>
                  <h4>
                    {/* {patientComplainHistory && patientComplainHistory.map((val, ind) => {
                  return (
                    <>
                      {val.pdmId === 4 ?
                        <span>{val.problemName}</span>
                        : ''}
                    </>
                  )
                })} */}
                    {patientComplainHistory
                      .filter(val => val.pdmId === 4)
                      .map((val, i, arr) => (
                        <span key={i}>
                          {val.problemName}
                          {i !== arr.length - 1 ? ',  ' : '  '}
                        </span>
                      )
                      )}
                  </h4>
                </div>
                {/* <h4>Fracture both Bone Shaft Leg Right</h4> */}
                {/* <p className='notedp'>Note:</p>
                <p>Hand Reconstruction Surgery 
                  <span className='spd'>(Time: 06/06/2023, 12:30 AM, Duration: 1 hr)</span>.
                   Hand Reconstruction Surgery done successfully. The surgery and it's 
                   alternatives were discussed with the patient. The patient tolerated the 
                   surgery well, with no complications. Any loculated adhesions 
                   were dissected and more purulent material was expressed. 
                   The abscess was explored thoroughly.</p>
                   <p className='notea'><strong>Name of Anesthetist :</strong>Dr. Vijay Kumar Goel</p>
                   <p className='notea'><strong>Type of Anesthesia :</strong>Local Anesthesia</p> */}
              </div>
            </div>

            <div className='whitebg'>
              <div className='didp_d'>
                <h2>Chief Complaints/Medical History</h2>
                <div className='didp_ac1'>
                  <h4>
                    {/* {patientComplainHistory && patientComplainHistory.map((val, i) => {
                    return (
                      <>
                        {val.pdmId === 2 ?
                           <span>{val.problemName}  </span>                             
                          : ''}
                      </>
                    )
                  })} */}

                    {patientComplainHistory
                      .filter(val => val.pdmId === 2)
                      .map((val, i, arr) => (
                        <span key={i}>
                          {!val.problemName ? 'NA' : val.problemName}
                          {i !== arr.length - 1 ? ',  ' : '  '}
                        </span>
                      )
                      )}

                  </h4>
                </div>
              </div>
            </div>

            <div className='whitebg'>
              <div className='didp_d cheifc'>
                <h2>Vital</h2>
                <div className='d-flex flex-wrap mb-2' style={{ gap: '17px' }}>
                  {patientVitals && patientVitals.map((val, i) => {
                    return (
                      val.vmId === 3 ?
                        <div className='d-flex gap-1 repeat'>
                          <div><img src={pulseVitalIcon} alt="pulseVitalIcon" className='vitalIcon' /></div>
                          <div className='d-flex flex-column'>
                            <b className="vitalName">Pulse</b>
                            <span className="vitalVal">{val.vmValue}</span>
                          </div>
                        </div>
                        :
                        val.vmId === 4 ?
                          <div className='d-flex gap-1 repeat'>
                            <div><img src={bpSysDysVitalIcon} alt="pulseVitalIcon" className='vitalIcon' /></div>
                            <div className='d-flex flex-column'>
                              <b className="vitalName">BP_Sys</b>
                              <span className="vitalVal">{val.vmValue}</span>
                            </div>
                          </div>
                          : val.vmId === 6 ?
                            <div className='d-flex gap-1 repeat'>
                              <div><img src={bloodPressureVitalIcon} alt="bloodPressureVitalIcon" className='vitalIcon' /></div>
                              <div className='d-flex flex-column'>
                                <b className="vitalName">BP_Dias</b>
                                <span className="vitalVal">{val.vmValue}</span>
                              </div>
                            </div>
                            : val.vmId === 5 ?
                              <div className='d-flex gap-1 repeat'>
                                <div><img src={tempratureVitalIcon} alt="tempratureVitalIcon" className='vitalIcon' /></div>
                                <div className='d-flex flex-column'>
                                  <b className="vitalName">Temp</b>
                                  <span className="vitalVal">{val.vmValue}</span>
                                </div>
                              </div>
                              : val.vmId === 56 ?
                                <div className='d-flex gap-1 repeat'>
                                  <div><img src={sketchVitalIcon} alt="sketchVitalIcon" className='vitalIcon' /></div>
                                  <div className='d-flex flex-column'>
                                    <b className="vitalName">SpO2</b>
                                    <span className="vitalVal">{val.vmValue}</span>
                                  </div>
                                </div>
                                : ''
                    )
                  })}
                </div>

              </div>
            </div>

            <div className='medica'>

              <div className='medica-in' style={{ minHeight: '416px' }}>
                <div className='whitebgg'>
                  <div className='didp_d brt'>
                    <h2>Medication</h2>
                    <div className='inc brt position-relative' style={{ minHeight: '370px', overflow: 'auto' }}>
                      <table >
                        {medicationList && medicationList.map((list, ind) => {
                          return (
                            <tr className='didp_d2'>
                              <td className='didp_d2_in'>
                                <img src={m1} alt='' />
                              </td>
                              <td className='didp_d2_in'>
                                <h3>{list.drugName}</h3>
                                <p>{list.doseFrequency}</p>
                              </td>
                              <td className='didp_d2_in' style={{ 'width': '50px' }}>
                                <p className='daysd'>{list.medicineStatus}</p>
                              </td>
                            </tr>
                          )
                        })}
                        {/* <tr className='didp_d2'>
                        <td className='didp_d2_in'>
                          <img src={m1}/>
                        </td>
                        <td className='didp_d2_in'>
                          <h3>Inha-Respule-Duolin - 500mg Inha-Respule-Duolin - 500mg</h3>
                          <p>Once in a day</p>
                        </td>
                        <td className='didp_d2_in' style={{'width':'50px'}}>
                         <p className='daysd'>10 Days</p>
                        </td>
                      </tr> */}
                        {/* <tr className='didp_d2'>
                        <td className='didp_d2_in'>
                          <img src={m2}/>
                        </td>
                        <td className='didp_d2_in'>
                          <h3>Inha-Respule-Duolin - 500mg</h3>
                          <p>Once in a day</p>
                        </td>
                        <td className='didp_d2_in'>
                         <p className='daysd'>10 Days</p>
                        </td>
                      </tr>
                      <tr className='didp_d2'>
                        <td className='didp_d2_in'>
                          <img src={m3}/>
                        </td>
                        <td className='didp_d2_in'>
                          <h3>Inha-Respule-Duolin</h3>
                          <p>Once in a day</p>
                        </td>
                        <td className='didp_d2_in'>
                         <p className='daysd'>10 Days</p>
                        </td>
                      </tr>
                      <tr className='didp_d2'>
                        <td className='didp_d2_in'>
                          <img src={m4}/>
                        </td>
                        <td className='didp_d2_in'>
                          <h3>Inha-Respule-Duolin</h3>
                          <p>Once in a day</p>
                        </td>
                        <td className='didp_d2_in'>
                         <p className='daysd'>10 Days</p>
                        </td>
                      </tr>
                      <tr className='didp_d2'>
                        <td className='didp_d2_in'>
                          <img src={m5}/>
                        </td>
                        <td className='didp_d2_in'>
                          <h3>Inha-Respule-Duolin - 500mg</h3>
                          <p>Once in a day</p>
                        </td>
                        <td className='didp_d2_in'>
                         <p className='daysd'>10 Days</p>
                        </td>
                      </tr> */}
                      </table>
                      <div className='boxLoader'>
                        <Loader val={showMedicationLoader} />
                      </div>
                    </div>
                  </div>

                </div>


              </div>

              <div className='medica-in'>
                <div className='whitebgg'>
                  <div className='didp_d'>
                    <h2>Investigation</h2>
                    <div className='inc'>
                      {/* <table >
                      <tr>
                        <td className='didp_d2_in'>
                          <h3>Inha-Respule-Duolin - 500mg</h3>
                        </td>
                        <td className='didp_d2_in text-right'>
                          <p className='daysdgrm'>11.6 g/dl</p>
                        </td>
                      </tr>
                      <tr>
                        <td className='didp_d2_in'>
                          <h3>Blood Sugar (Random)</h3>
                        </td>
                        <td className='didp_d2_in text-right'>
                          <p className='daysdgrm'>118 mg/dl</p>
                        </td>
                      </tr>
                      <tr>
                        <td className='didp_d2_in'>
                          <h3>Neutrophils</h3>
                        </td>
                        <td className='didp_d2_in text-right'>
                        <p className='redtxt1'>84 %</p>
                        </td>
                      </tr>
                      <tr>
                        <td className='didp_d2_in'>
                          <h3>Platelet Count</h3>
                        </td>
                        <td className='didp_d2_in text-right'>
                        <p className='daysdgrm'>2.4 Lakh</p>
                        </td>
                      </tr>
                      <tr>
                        <td className='didp_d2_in'>
                          <h3>HCV</h3>
                        </td>
                        <td className='didp_d2_in text-right'>
                          <p className='redtxt1'>0.04</p>
                        </td>
                      </tr>
                      <tr>
                        <td className='didp_d2_in'>
                          <h3>HbsAg-Interpretation</h3>
                        </td>
                        <td className='didp_d2_in text-right'>
                        <p className='daysdgrm'>Non-Reactive</p>
                        </td>
                      </tr>
                      <tr>
                        <td className='didp_d2_in'>
                          <h3>HCV-Interpretation</h3>
                        </td>
                        <td className='didp_d2_in text-right'>
                        <p className='daysdgrm'>Non-Reactive</p>
                        </td>
                      </tr>
                      <tr>
                        <td className='didp_d2_in'>
                          <h3>HCV</h3>
                        </td>
                        <td className='didp_d2_in text-right'>
                          <p className='redtxt1'>0.04</p>
                        </td>
                      </tr>
                      <tr>
                        <td className='didp_d2_in'>
                          <h3>HbsAg-Interpretation</h3>
                        </td>
                        <td className='didp_d2_in text-right'>
                        <p className='daysdgrm'>Non-Reactive</p>
                        </td>
                      </tr>
                    </table> */}
                      <div className='roww pdashboard-bg mb-2' style={{ 'padding': '0', boxShadow: 'none' }}>
                        <div className='row p-0 m-0 boxcontainer'>
                          <DepartmentNavbar getActiveID={setActiveId} callingpage={0} />
                        </div>
                        <div className='d-flex flex-column p-2 overflow-auto pb-2' style={{ minHeight: "300px", maxHeight: "300px" }}>
                          <OPDInvestigationRight activeSubId={activeId} callingpage={1} uhid={activeUHID} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="col-md-4 col-sm-12 prt1 wt">

            <div className='whitebg' style={{ height: '408px', overflow: 'auto', overflowY: "hidden" }}>
              <div className='opdvisit'>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  {/* <li className="nav-item" role="presentation">
                    <div className="opdvisit-in nav-link active_" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">OPD Visit History</div>


                  </li> */}
                  <li className="nav-item" role="presentation">
                    <div className="opdvisit-in nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Admission History</div>
                  </li>
                </ul>
              </div>


              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade_ show_ active_" id="home" role="tabpanel" aria-labelledby="home-tab">
                  {/* <h3 className='comingsoontxt'>Coming  Soon</h3> */}

                  <div className="med-table-section" style={{ height: "calc(100vh - 290px)" }}>
                    <TableContainer>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ "width": "5%" }}>#</th>
                          <th>Visit Date</th>
                          <th>Department</th>
                          <th>Consultant</th>
                          <th>Diagnosis</th>
                          <th>Action</th>


                        </tr>
                      </thead>

                      <tbody>

                        {patientHistoryList && patientHistoryList.map((val, ind) => {

                          return (
                            <tr key={val.id}>
                              <td className="text-center">{ind + 1}</td>
                              <td>{val.visitDate}</td>
                              <td>{val.departmentName}</td>
                              <td>{val.consultantName}</td>
                              <td>{val.diagnosis}</td>
                              <td data-bs-toggle="modal" data-bs-title="ipddashboardModal" data-bs-placement="bottom" data-bs-target="#ipddashboardModal" onClick={() => { setPmid(val.pmID) }}>View</td>

                            </tr>
                          )
                        })}

                      </tbody>
                    </TableContainer>

                  </div>

                  <div className='d-none'>
                    <div className='datee'>
                      <div className='datee-1'>
                        <p className='tim1'>24 Nov, <span>Tue</span></p>
                        <p className='tim'>9AM - 10AM</p>
                      </div>
                      <div className='datee-1 brft'>
                        <h4>Hypertension</h4>
                        <p className='tim2'>Diagnosis</p>
                        <p><i className='fa fa-user'></i> General Physician <span className='drt'>(Dr. Vijay Kumar Goel)</span></p>
                      </div>
                      <div className='datee-1'>
                        <i className='fa fa-ellipsis-v'></i>
                      </div>
                    </div>
                    <div className='datee'>
                      <div className='datee-1'>
                        <p className='tim1'>24 Nov, <span>Tue</span></p>
                        <p className='tim'>9AM - 10AM</p>
                      </div>
                      <div className='datee-1 brft'>
                        <h4>Hypertension</h4>
                        <p className='tim2'>Diagnosis</p>
                        <p><i className='fa fa-user'></i> General Physician <span className='drt'>(Dr. Vijay Kumar Goel)</span></p>
                      </div>
                      <div className='datee-1'>
                        <i className='fa fa-ellipsis-v'></i>
                      </div>
                    </div>
                    <div className='datee'>
                      <div className='datee-1'>
                        <p className='tim1'>24 Nov, <span>Tue</span></p>
                        <p className='tim'>9AM - 10AM</p>
                      </div>
                      <div className='datee-1 brft'>
                        <h4>Hypertension</h4>
                        <p className='tim2'>Diagnosis</p>
                        <p><i className='fa fa-user'></i> General Physician <span className='drt'>(Dr. Vijay Kumar Goel)</span></p>
                      </div>
                      <div className='datee-1'>
                        <i className='fa fa-ellipsis-v'></i>
                      </div>
                    </div>
                    <div className='datee'>
                      <div className='datee-1'>
                        <p className='tim1'>24 Nov, <span>Tue</span></p>
                        <p className='tim'>9AM - 10AM</p>
                      </div>
                      <div className='datee-1 brft'>
                        <h4>Hypertension</h4>
                        <p className='tim2'>Diagnosis</p>
                        <p><i className='fa fa-user'></i> General Physician <span className='drt'>(Dr. Vijay Kumar Goel)</span></p>
                      </div>
                      <div className='datee-1'>
                        <i className='fa fa-ellipsis-v'></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">

                  {/*<h3 className='comingsoontxt'>Coming  Soon</h3>*/}

                  <div className="med-table-section" style={{ height: "calc(100vh - 290px)" }}>

                    <TableContainer>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ "width": "5%" }}>#</th>
                          <th>Admit Date</th>
                          <th>Discharge Date</th>
                          <th>Department</th>
                          <th>Admitted By</th>
                          <th>Discharged By</th>
                          <th>Discharge Type</th>
                          <th>Action</th>

                        </tr>
                      </thead>
                      <tbody>
                        {admissionHistory &&
                          admissionHistory.map((val, ind) => {
                            // Separate date and time components
                            const admitDate = new Date(val.admitDateTime).toLocaleDateString();
                            const admitTime = new Date(val.admitDateTime).toLocaleTimeString();
                            const dischargeDate = new Date(val.dischargeDateTime).toLocaleDateString();
                            const dischargeTime = new Date(val.dischargeDateTime).toLocaleTimeString();

                            return (
                              <tr key={val.id}>
                                <td className="text-center">{ind + 1}</td>
                                <td>
                                  <div>
                                    <span>{admitDate}</span> <br></br>
                                    <span >{admitTime}</span>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <span>{val.dischargeDateTime !== "" ? val.dischargeDateTime : "-"}</span><br></br>
                                    {/* <span>{dischargeTime && dischargeTime}</span> */}
                                  </div>
                                </td>
                                <td>{val.department}</td>
                                <td>{val.admittedBy !== null ? val.admittedBy : "-"}</td>
                                <td>{val.dischargedBy !== null ? val.dischargedBy : "-"}</td>
                                <td>{val.dischargeType !== "" ? val.dischargeType : "-"}</td>
                                <td
                                  data-bs-toggle="modal"
                                  data-bs-title="ipddashboardModal"
                                  data-bs-placement="bottom"
                                  data-bs-target="#ipddashboardModal"
                                  onClick={() => {
                                    setPmid(val.id);
                                  }}
                                >
                                  <img src={viewicn} className='bgicn' alt="View" title="View" />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>


                      {/* <tbody>

                                  {admissionHistory && admissionHistory.map((val, ind) => {

                                      return (
                                          <tr key={val.id}>
                                              <td className="text-center">{ind + 1}</td>
                                              <td>{val.admitDateTime}</td>
                                              <td>{val.dischargeDateTime}</td>
                                              <td>{val.department}</td>
                                              <td>{val.admittedBy}</td>
                                              <td>{val.dischargedBy}</td>
                                              <td>{val.dischargeType}</td>
                                              <td data-bs-toggle="modal" data-bs-title="ipddashboardModal" data-bs-placement="bottom" data-bs-target="#ipddashboardModal" onClick={()=>{setPmid(val.pmID)}}>View</td>

                                          </tr>
                                      )
                                  })}

                              </tbody> */}
                    </TableContainer>

                  </div>

                  <div className='d-none'>
                    <div className='datee'>
                      <div className='datee-1'>
                        <p className='tim1'>24 Nov, <span>Tue</span></p>
                        <p className='tim'>9AM - 10AM</p>
                      </div>
                      <div className='datee-1'>
                        <h4>Hypertension</h4>
                        <p className='tim2'>Diagnosis</p>
                        <p><i className='fa fa-user'></i> General Physician <span className='drt'>(Dr. Vijay Kumar Goel)</span></p>
                      </div>
                      <div className='datee-1'>
                        <i className='fa fa-ellipsis-v'></i>
                      </div>
                    </div>
                    <div className='datee'>
                      <div className='datee-1'>
                        <p className='tim1'>24 Nov, <span>Tue</span></p>
                        <p className='tim'>9AM - 10AM</p>
                      </div>
                      <div className='datee-1'>
                        <h4>Hypertension</h4>
                        <p className='tim2'>Diagnosis</p>
                        <p><i className='fa fa-user'></i> General Physician <span className='drt'>(Dr. Vijay Kumar Goel)</span></p>
                      </div>
                      <div className='datee-1'>
                        <i className='fa fa-ellipsis-v'></i>
                      </div>
                    </div>
                    <div className='datee'>
                      <div className='datee-1'>
                        <p className='tim1'>24 Nov, <span>Tue</span></p>
                        <p className='tim'>9AM - 10AM</p>
                      </div>
                      <div className='datee-1'>
                        <h4>Hypertension</h4>
                        <p className='tim2'>Diagnosis</p>
                        <p><i className='fa fa-user'></i> General Physician <span className='drt'>(Dr. Vijay Kumar Goel)</span></p>
                      </div>
                      <div className='datee-1'>
                        <i className='fa fa-ellipsis-v'></i>
                      </div>
                    </div>
                    <div className='datee'>
                      <div className='datee-1'>
                        <p className='tim1'>24 Nov, <span>Tue</span></p>
                        <p className='tim'>9AM - 10AM</p>
                      </div>
                      <div className='datee-1'>
                        <h4>Hypertension</h4>
                        <p className='tim2'>Diagnosis</p>
                        <p><i className='fa fa-user'></i> General Physician <span className='drt'>(Dr. Vijay Kumar Goel)</span></p>
                      </div>
                      <div className='datee-1'>
                        <i className='fa fa-ellipsis-v'></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='whitebg' style={{ height: '420px', overflow: 'auto', overflowY: "hidden" }}>
              <div className='didp_d'>
                <h2>Insurance Summary</h2>
              </div>

              {/* <h3 className='comingsoontxt'>Coming  Soon</h3> */}
              <div className="med-table-section" style={{ height: "calc(100vh - 290px)" }}>
                <TableContainer>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>Card Number</th>
                      <th>Company Name</th>
                      <th>Payment Type</th>
                    </tr>
                  </thead>

                  <tbody>
                    {patientInsuranceList && patientInsuranceList.map((val, ind) => {

                      return (
                        <tr key={val.id}>
                          <td className="text-center">{ind + 1}</td>
                          <td className="text-center">{val.cardNo ? val.cardNo : '-'}</td>
                          <td className="text-center">{val.companyname ? val.companyname : '-'}</td>
                          <td>{val.paymentType ? val.paymentType : '-'}</td>
                        </tr>
                      )
                    })}

                  </tbody>
                </TableContainer>

              </div>

              <div className='d-none'>
                <div className='insuran'>
                  <h4>Insurance Profile</h4>
                  <p>Auto Insurance / Auto Insurance HMO / HMO</p>
                </div>
                <div className='row mt-2'>
                  <div className='insuran col-md-6 col-sm-12'>
                    <h4>Primary Insurance</h4>
                    <p>Auto Insurance</p>
                    <p>STATE FARM</p>
                    <p>Member Number - 568821221</p>
                  </div>
                  <div className='insuran col-md-6 col-sm-12'>
                    <h4>Secondary Insurance</h4>
                    <p>Auto Insurance</p>
                    <p>STATE FARM</p>
                    <p>Member Number - 568821221</p>
                  </div>
                  <div className='insuran col-md-6 col-sm-12'>
                    <h4>Tertiary Insurance</h4>
                    <p>Auto Insurance</p>
                    <p>STATE FARM</p>
                    <p>Member Number - 568821221</p>
                  </div>
                  <div className='insuran col-md-6 col-sm-12'>
                    <h4>Responsible Party</h4>
                    <p>Auto Insurance</p>
                    <p>STATE FARM</p>
                    <p>Member Number - 568821221</p>
                  </div>
                </div>

              </div>
            </div>
          </div>

          {
            showLoder === 1 ? <Loader val={showLoder} /> : ""
          }
          {
            showAlertToster === 1 ?
              <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
          }

          <IPDPatientHistoryPopUp pmid={pmid} />

        </div>

      }

    </>
  )
}