import React, { useState, useEffect } from 'react';
import TableContainer from '../../Component/TableContainer';
import Heading from '../../Component/Heading';
import exportFromJSON from "export-from-json";
import DeveloperProjectRoleAPI from '../API/Report/DeveloperProjectRole';
import noData from '../../assets/images/icons/No data-rafiki.svg';

export default function DeveloperProjectRoleComponent() {
    const [getDeveloperProjectRoleList, setDeveloperProjectRoleList] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const fetchDeveloperProjectRole = async () => {
        const result = await DeveloperProjectRoleAPI(window.userId);
        setDeveloperProjectRoleList(result.responseValue);
    };
    let handleExportFile = () => {
        var arrFormat = [];
        getDeveloperProjectRoleList.map((val, ind) => {
            let param = ['SR', 'Name', 'Role', 'Sprint', 'Project'];

            arrFormat.push({
                [param[0]]: ind + 1,
                [param[1]]: val.name,
                [param[2]]: val.roleTitle,
                [param[3]]: val.sprintBacklogText,
                [param[4]]: val.projectName
            });
        });
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
        const data = arrFormat;
        const fileName = 'DeveloperDailyProjectBacklog'+ '' + formattedDate;
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType });
    }
    let handleOnChange = (e) => {
        const { name, value } = e.target;
        if (name === 'searchBox') {
            setSearchInput(value)
        }
    }
    useEffect(() => {
        fetchDeveloperProjectRole();
    }, []);

    return (
        <>
            <section className="main-content pt-3 mt-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 mt-1">
                            <div className='handlser'>
                                <Heading text="Daily Developer Project Backlog" />
                                <div style={{ position: 'relative', display: 'flex' }}>
                                    <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleExportFile}>Export To CSV</button>
                                    <input value={searchInput} onChange={handleOnChange} name="searchBox" type="search" className="form-control form-control-sm" placeholder="Search...." aria-label="Search" aria-describedby="search-addon" />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>

                                </div>
                            </div>

                            <div className="med-table-section relative" style={{ "height": "77vh" }}>
                                <TableContainer>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>S.No.</th>
                                            <th>Developer</th>
                                            <th>Role</th>
                                            <th>Backlog</th>
                                            <th>Project</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getDeveloperProjectRoleList.length > 0 ?
                                            getDeveloperProjectRoleList && getDeveloperProjectRoleList.filter((val) => `${val.projectName} ${val.name}${val.sprintBacklogText}${val.roleTitle}`.toLowerCase().includes(searchInput.toLowerCase())).map((val, ind) => {
                                            return (
                                                <tr key={val.id}>

                                                    <td className="text-center">{ind + 1}</td>
                                                    <td>{val.name}</td>
                                                    <td>{val.roleTitle}</td>
                                                    <td>{val.sprintBacklogText}</td>
                                                    <td>{val.projectName}</td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <img className='haveNodataImg' src={noData} alt="No Data" />}
                                    </tbody>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
