import React, { useMemo } from 'react'
// import { Link } from 'react-router-dom'
import "../assets/css/patientMonitoringDashboard.css";
// import Navbar from '../Components/Navbar'
import PMDTop from './DynamicComponent/PMDTop'
import LeftTable from './DynamicComponent/LeftTable'
import RightTable from './DynamicComponent/RightTable'
import { HubConnectionBuilder } from '@microsoft/signalr'
import SignalRConnection from './Code/SignalRConnection'
import handleSearchs from './Code/PMDCode'
import { useState, useEffect } from 'react'
// import OffcanvasLogo from "../../assets/images/Navbar/offcanvas-logo.png";
import Loder from '../Component/Loader';
import GetAllDashboardColumnMaster from './Api/GetAllDashboardColumnMaster';
import GetAllDashboardColumnSequence from './Api/GetAllDashboardColumnSequence';
import AllTable from './DynamicComponent/AllTable';
import GetAllDashboardParameterColumnAssign from './Api/GetAllDashboardParameterColumnAssign';
import store from '../Store';
import { getAllColumnsParamters } from '../Reduce/PatientMonitordashboard/AllColumnsParamters';
import GridPMDIndex from './GridPMDIndex';
import MakeTable from './DynamicComponent/MakeTable';

export default function Test() {
  let [connection, setRedishConnection] = useState();
  let [fullPatientDataList, setFullPatientDataList] = useState([]);
  let [tempFullPatientDataList, setTempFullPatientDataList] = useState([]);
  let [allWard, setAllWard] = useState([]);
  let [loderBool, setLoderBool] = useState(1)
  let [updateData, setUpdateData] = useState();
  let [scroll, setScroll] = useState()
  let [selectedWard, setSelectedWard] = useState(null)
  let [showNextData, setShowNextData] = useState(25)
  let [fullscreen, setFullScreen] = useState('')
  let [showAllColumns, setShowAllColumns] = useState([])
  let [sequenceAllColumns, setSequenceAllColumns] = useState([])
  let [showHideColumn, setShowHideColumn] = useState(-1)
  let [isGridView, setIsGridView] = useState(window.sessionStorage.getItem("isGridView")?JSON.parse(window.sessionStorage.getItem("isGridView")).isGridView:1)

  let [originalColumn, setOriginalColumn] = useState([])

  let [randomValue, setRandomValue] = useState(Math.random())

  let makeData = (sequencedata, showColumnsData) => {
    // console.log("sequencedata", sequencedata, "master", showColumnsData)
    // console.log("pdata", showAllColumns)
    let temp = new Array(showAllColumns.length).fill(0)
    sequencedata.map((value, index) => {
      showColumnsData.map((val, ind) => {
        if (value.columnId === val.id) {
          temp[value.sequenceNumber - 1] = value
        }
      })
    })
    // console.log("make data", temp)
    getColumnsParameters(temp.filter(val => val))
    setTimeout(() => {
      setOriginalColumn(temp.filter(val => val))
    }, 600)
  }
  
  // let getAllColumnsParameter = (object) => {
  //   let allColumnsParamter = []

  //   object.map((val) => {
  //     let temp = {}
  //     let data = getColumnsParameters(val.columnId)
  //     temp["columnId"] = val.columnId
  //     temp["data"] = data
  //     allColumnsParamter.push(temp)
  //   })
  //   console.log("dataaaaaaaaaaaaa", allColumnsParamter)
  //   store.dispatch(getAllColumnsParamters(allColumnsParamter))
  // }

  let getColumnsParameters = async (object) => {

    // Use Promise.all to wait for all async operations to complete
    let allColumnsParamter = await Promise.all(object.map(async (val) => {
      let temp = {};
      let response = await GetAllDashboardParameterColumnAssign(val.columnId);
      if (response.status === 1 && response.responseValue.length !== 0) {
        temp["columnId"] = val.columnId;
        temp["data"] = response.responseValue;
        return temp;
      } else {
        return []; // Handle the case where the response status is not 1
      }
    }));

    // Filter out null values (responses with status !== 1)
    allColumnsParamter = allColumnsParamter.filter(value => value !== null && value.length !== 0) ;

    console.log("allColumnsParamter", allColumnsParamter);
    if (allColumnsParamter.length !== 0) {
      store.dispatch(getAllColumnsParamters(allColumnsParamter));
    }
    else {
      let temp = [
        {
          "columnId": 1,
          "data": []
        },
        {
          "columnId": 2,
          "data": []
        },
        {
          "columnId": 3,
          "data": []
        },
        {
          "columnId": 4,
          "data": []
        },
        {
          "columnId": 5,
          "data": []
        },
        {
          "columnId": 6,
          "data": []
        },
        {
          "columnId": 7,
          "data": []
        },
        {
          "columnId": 8,
          "data": []
        },
        {
          "columnId": 9,
          "data": []
        },
        {
          "columnId": 10,
          "data": []
        },
        {
          "columnId": 11,
          "data": []
        },
        {
          "columnId": 12,
          "data": []
        },
        {
          "columnId": 13,
          "data": []
        },
        {
          "columnId": 14,
          "data": []
        },
        {
          "columnId": 15,
          "data": []
        },
        {
          "columnId": 16,
          "data": []
        },
        {
          "columnId": 17,
          "data": []
        },
        {
          "columnId": 18,
          "data": []
        },
        {
          "columnId": 19,
          "data": []
        },
        {
          "columnId": 20,
          "data": []
        },
        {
          "columnId": 21,
          "data": []
        },
        {
          "columnId": 22,
          "data": []
        },
        {
          "columnId": 23,
          "data": []
        },
        {
          "columnId": 24,
          "data": []
        },
        {
          "columnId": 25,
          "data": []
        },
        {
          "columnId": 26,
          "data": []
        },
        {
          "columnId": 27,
          "data": []
        },
        {
          "columnId": 28,
          "data": []
        },
        {
          "columnId": 29,
          "data": []
        },
        {
          "columnId": 30,
          "data": []
        },
        {
          "columnId": 31,
          "data": []
        },
        {
          "columnId": 32,
          "data": []
        },
        {
          "columnId": 33,
          "data": []
        },
        {
          "columnId": 34,
          "data": []
        },
        {
          "columnId": 35,
          "data": []
        }
      ]
      console.log("else part run", temp)
      store.dispatch(getAllColumnsParamters(temp));
    }

    // store.dispatch(getAllColumnsParamters(allColumnsParamter));
  }

  // getData 
  let GetColumns = async () => {
    let response = await GetAllDashboardColumnMaster()
    let responseSequence = await GetAllDashboardColumnSequence()

    if (response.status === 1 ) {
      setShowAllColumns(response.responseValue)
    }
    if (response.status === 1 && responseSequence.status === 1) {
      setSequenceAllColumns(responseSequence.responseValue)
      makeData(responseSequence.responseValue, response.responseValue)
    }
    // console.log("columns", response.responseValue, "columns assign", responseSequence.responseValue)

  }

  // create ward list 
  let createWardList = (patientdatas) => {
    let temp = []
    temp = [...new Set(patientdatas.map(q => JSON.parse(q.patientDataList).Ward))];
    setAllWard(temp.filter(item => item))

  }

  let handleUpdatedata = () => {
    setUpdateData(updateData ? 0 : 1)
  }

  let handleshowHide = (val) => {
    setShowHideColumn(val)
  }

  // handle search 
  let handleSearch = (e, val) => {
    if (typeof e === "object") {
      if (val === 1) {
        handleSearchs(e, tempFullPatientDataList, setFullPatientDataList)
        document.getElementById("wardDropdown").value = 2
        setSelectedWard(2)
      }
      else {
        handleSearchs(e, tempFullPatientDataList, setFullPatientDataList)
      }
    }
    else {
      handleSearchs(e, tempFullPatientDataList, setFullPatientDataList)
    }
  }

  // scrolling function
  function scrollHandle(e) {

    if (isGridView !== 1) {

      var scrollTop = this.scrollTop;
      const firstElement = document.querySelector(".fixedTheadFirst");
      const secondElement = document.querySelector(".fixedTheadSecond");

      if (firstElement && secondElement) {
        document.querySelector(".fixedTheadFirst").style.transform =
          "translateY(" + scrollTop + "px)";
        document.querySelector(".fixedTheadFirst").style.position = "sticky";
        document.querySelector(".fixedTheadFirst").style.zIndex = "2";
        document.querySelector(".fixedTheadSecond").style.transform = "translateY(" + scrollTop + "px)";
        document.querySelector(".fixedTheadSecond").style.position = "sticky";
        document.querySelector(".fixedTheadSecond").style.zIndex = "2";
      }
    }



  }

  // set patient Data
  // let handlePatientData = () => {
  //   console.log("callind top")

  //   return (
  //     <LeftTable
  //       fullPatientDataList={fullPatientDataList} showNextData={showNextData} originalColumn={originalColumn.slice(0, 11)} fullColumns={originalColumn.length} handleshowHide={handleshowHide}
  //     />
  //   );


  // };

  // set patient Data
  
  let handleData = () => {

    return (
      <>
        {showHideColumn === -1 ?
          <>
            {
              originalColumn.length <= 11 ?
                <LeftTable fullPatientDataList={fullPatientDataList} showNextData={showNextData} originalColumn={originalColumn.slice(0, 6)} fullColumns={originalColumn.length} handleshowHide={handleshowHide} />
                :
                <>
                  <LeftTable fullPatientDataList={fullPatientDataList} showNextData={showNextData} originalColumn={originalColumn.slice(0, 6)} handleshowHide={handleshowHide} />
                  <RightTable fullPatientDataList={fullPatientDataList} showNextData={showNextData} originalColumn={originalColumn.slice(6, originalColumn.length)} handleshowHide={handleshowHide} />
                </>
            }
          </>
          : showHideColumn === 0 ?
            // <LeftTable fullPatientDataList={fullPatientDataList} showNextData={showNextData} originalColumn={originalColumn.slice(0, 12)} handleshowHide={handleshowHide} />
            // :
            // <RightTable fullPatientDataList={fullPatientDataList} showNextData={showNextData} originalColumn={originalColumn.slice(12, originalColumn.length  )} handleshowHide={handleshowHide} />
            <AllTable fullPatientDataList={fullPatientDataList} showNextData={showNextData} originalColumn={originalColumn} handleshowHide={handleshowHide} /> : ""

        }
      </>
    );

  };

  // let callPatientDatamemo = useMemo(handlePatientData, [
  //   fullPatientDataList,
  //   showNextData,
  //   originalColumn.length <= 11,
  //   setInterval(() => {
  //     return;
  //   }, 600),
  // ]);
  let callPatientVitalDatamemo = useMemo(handleData, [
    fullPatientDataList,
    // showNextData,
    // randomValue
    ,
  ]);



  let infinityScroll = () => {
    let getScrollSection = document.querySelector(".scroll-in-section");
    setScroll(getScrollSection.scrollTop)
    let scrollTop = getScrollSection.scrollTop;
    let scrollHeight = getScrollSection.scrollHeight;
    let clientHeight = getScrollSection.clientHeight;
    if ((scrollTop + clientHeight >= scrollHeight) && (showNextData <= fullPatientDataList.length)) {
      console.log("ddddddd")
      setShowNextData(showNextData + 10)
    }


  }
  // establish the connection
  useEffect(() => {
    GetColumns()
    // setTimeout(() => {

    // }, 2500)
    const connection = new HubConnectionBuilder().withUrl(window.PatientMonitorDashboard + "/PatientDashboard", 4).build();
    setRedishConnection(connection)
    let getScrollSection = document.querySelector(".scroll-in-section");
    getScrollSection.addEventListener("scroll", scrollHandle);
    if (JSON.parse(window.sessionStorage.getItem("patientData")) != null) {
      let getdata = JSON.parse(window.sessionStorage.getItem("patientData"))
      delete getdata.uhId
      window.sessionStorage.setItem("patientData", JSON.stringify(getdata))
    }
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
    
  }, [])

  // now recall the connection
  useEffect(() => {
    
    // console.log("full", fullPatientDataList)
    SignalRConnection(connection, setTempFullPatientDataList, setLoderBool, createWardList, handleUpdatedata)
   
  }, [connection]);

 
  useEffect(() => {
    let searchvalue = document.getElementById("searchData") ? document.getElementById("searchData").value : "";
    let wardvalue = parseInt(document.getElementById("wardDropdown") ? document.getElementById("wardDropdown").value : 1);

    if (searchvalue === "") {
      setUpdateData()

      setFullPatientDataList(tempFullPatientDataList)
      handleSearch(wardvalue)

    }
    else {
      setUpdateData()
      console.log("serach value")
      handleSearchs(searchvalue, tempFullPatientDataList, setFullPatientDataList)

      // handleSearch(searchvalue)
      // setFullPatientDataList(tempFullPatientDataList)
    }
  }, [tempFullPatientDataList, updateData === 1])


  // infinity scroll 
  useEffect(() => {
    let getScrollSection = document.querySelector(".scroll-in-section");
    getScrollSection.addEventListener("scroll", infinityScroll);

  }, [scroll])


  function showContextMenu(event) {
    event.preventDefault();

    const contextMenu = document.getElementById('contextMenu');
    contextMenu.style.display = 'block';
    contextMenu.style.left = `${event.clientX}px`;
    contextMenu.style.top = `${event.clientY}px`;

    document.addEventListener('click', hideContextMenu);
  }

  function hideContextMenu() {
    const contextMenu = document.getElementById('contextMenu');
    contextMenu.style.display = 'none';

    document.removeEventListener('click', hideContextMenu);
  }

  function handleContextMenu() {
      setShowHideColumn(showHideColumn === -1?0:-1)
  }


  return (
    <div className="patientMonitoringTopWrapper" >

      {/* <div className='layOutSurgeryOTNavbar'>
        <div>
          <div className="offcanvas-logo">
            <Link to="/dashboard/"><img src={OffcanvasLogo} /></Link>
          </div>
        </div>

        <Navbar connection={connection} />
      </div> */}
      <div id="contextMenu" className="context-menu">
        <div className="context-menu-item" onClick={handleContextMenu}>{showHideColumn === -1?"Edit Dashboard":"Back to Dashboard"} </div>
        {/* <div className="context-menu-item" onclick="handleContextMenu('Action 2')">Action 2</div>
        <div className="context-menu-item" onclick="handleContextMenu('Action 3')">Action 3</div> */}
      </div>

      <div className={`dashboardtopSecond mt-5 pt-1 ${fullscreen}`} id="mainDiv">
        <PMDTop isGridView={isGridView} setIsGridView={setIsGridView} handleSearch={handleSearch} setFullScreen={setFullScreen} setSelectedWard={setSelectedWard} showNextData={showNextData} setShowNextData={setShowNextData} allWard={allWard} fullPatientDataList={fullPatientDataList} fullscreen={fullscreen} />
       
        <MakeTable isGridView={isGridView} setIsGridView={setIsGridView} showContextMenu={showContextMenu} showHideColumn={showHideColumn} originalColumn={originalColumn} fullPatientDataList={fullPatientDataList} showNextData={showNextData} handleshowHide={handleshowHide} handleSearch={handleSearch}/>
        {/* {
          
          isGridView === 0 ?


            <div className="scroll-in-section" id="scrollinsection" onContextMenu={showContextMenu}>
              <div className="table-monitor-section">
                {showHideColumn === -1 ?
                  <>
                    {
                      originalColumn.length <= 11 ?
                        <LeftTable fullPatientDataList={fullPatientDataList} showNextData={showNextData} originalColumn={originalColumn.slice(0, 11)} fullColumns={originalColumn.length} handleshowHide={handleshowHide} />
                        :
                        <>
                          <LeftTable fullPatientDataList={fullPatientDataList} showNextData={showNextData} originalColumn={originalColumn.slice(0, 11)} handleshowHide={handleshowHide} />


                          <RightTable fullPatientDataList={fullPatientDataList} showNextData={showNextData} originalColumn={originalColumn.slice(11, originalColumn.length)} handleshowHide={handleshowHide} />
                        </>
                    }
                  </>
                  : showHideColumn === 0 ?

                    <AllTable fullPatientDataList={fullPatientDataList} showNextData={showNextData} originalColumn={originalColumn} handleshowHide={handleshowHide} /> : ""

                }
             



              </div>
            </div>
            :

            <div className='scroll-in-section' id="scrollinsection">

              <GridPMDIndex fullPatientDataList={fullPatientDataList} showNextData={showNextData} />

            </div>

        } */}
      </div>
      <Loder val={loderBool} />
    </div>
  )
}

