import React, { useState } from 'react'
import { useEffect } from 'react';
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import Toster from '../../../Component/Toster';
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Heading from '../../../Component/Heading';
import BoxContainer from '../../../Component/BoxContainer';
import TableContainer from '../../../Component/TableContainer';
import SuccessToster from '../../../Component/SuccessToster';
import AlertToster from '../../../Component/AlertToster';
import Loader from '../../../Component/Loader';

import DropdownWithSearch from '../../../Component/DropdownWithSearch';

import Search from '../../../Code/Serach';
import { t } from 'i18next';
import GetAlllClientMaster from '../../Api/Master/HeadMaster/GetAlllClientMaster';
import GetAllPackageMaster from '../../Api/Master/HeadMaster/GetAllPackageMaster';
import UpdateClientMasterPackage from '../../Api/Master/HeadMaster/UpdateClientMasterPackage';


export default function UpdatePackageInClientmaster() {
  let [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
  let [clientID, setClientID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientId);
  let [showUnderProcess, setShowUnderProcess] = useState(0);
  let [showToster, setShowToster] = useState(0);
  let [tosterMessage, setTosterMessage] = useState("");
  let [tosterValue, setTosterValue] = useState(0);
  let [showLoder, setShowLoder] = useState(0);
  let [isShowToaster, setisShowToaster] = useState(0);
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [showErrMessage, setShowErrMessage] = useState('');
  let [showSuccessMsg, setShowSuccessMsg] = useState('');
  let [designationList, setDesignationList] = useState([]);
  let [menuList, setMenuList] = useState([]);
  let [taskCategoryMasterList, setTaskCategoryMasterList] = useState([]);
  let [taskCategoryMasterListMain, setTaskCategoryMasterListMain] = useState([]);
  let [shiftList, setShiftList] = useState('');
  let [updateBool, setUpdateBool] = useState(0);
  let [rowID, setRowId] = useState(0);
  let [editDesignationDdl, setEditeditDesignationDdltDdl] = useState('');
  let [editMenuDDL, setEditMenuDDL] = useState('');
  let [clearDropdown, setClearDropdown] = useState(0);
  let [isOrder, setIsOrder] = useState(true);
  let [sendForm, setSendForm] = useState({
    clientName: '',
    designation: '',
    menu: '',
  })
  let handleChange = async (e) => {
    clearValidationErrMessage();
    setEditeditDesignationDdltDdl("");
    setEditMenuDDL("");
    const { name, value } = e.target;
    setSendForm((prevData) => ({ ...prevData, [name]: value }));

  }
  const getTaskCategoryList = async () => {
    setShowLoder(1);
    const response = await GetAlllClientMaster();
    if (response.status === 1) {
      setTaskCategoryMasterList(response.responseValue);
      setTaskCategoryMasterListMain(response.responseValue);
      setShowLoder(0)
    }
    else {
      setShowLoder(0);
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
      setTimeout(() => {
        setShowAlertToster(0);
      }, 1500)
    }
  }

  const getDesignationList = async () => {
    const response = await GetAllPackageMaster();
    console.log("test dropdown data",response)
    if (response.status === 1) {
      setDesignationList(response.responseValue);
    }
    else {
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
      setTimeout(() => {
        setShowAlertToster(0);
      }, 1500)
    }
  }


  let handleSearch = (e) => {
    let resp = Search(taskCategoryMasterListMain, e.target.value)
    if (e.target !== "") {
      if (resp.length !== 0) {
        setTaskCategoryMasterList(resp)
      }
      else {
        setTaskCategoryMasterList([])
      }
    }
    else {
      setTaskCategoryMasterList(taskCategoryMasterListMain)
    }
  }

  const handelUpdate = async () => {
    if (sendForm.clientName === '' || sendForm.clientName === null || sendForm.clientName === undefined) {
      document.getElementById('errclientName').innerHTML = "client Name is Required";
      document.getElementById('errclientName').style.display = "block";
    }
    else if (sendForm.designation === '' || sendForm.designation === 0 || sendForm.designation === null || sendForm.designation === undefined) {
      document.getElementById('errPackageName').innerHTML = "Package Name is Required";
      document.getElementById('errPackageName').style.display = "block";
    }
   
  

    else {

      const response = await UpdateClientMasterPackage(rowID,sendForm.designation);
      if (response.status === 1) {
        setShowUnderProcess(0);
        setTosterValue(0);
        setShowToster(1);
        setTosterMessage("Updated Successfully..");
        setTimeout(() => {
          setShowToster(0);
          handleClear(1);
          getTaskCategoryList();

        }, 1500)
      }
      else {
        setShowUnderProcess(0);
        setTosterValue(1);
        setShowToster(1);
        setTosterMessage(response.responseValue);
        setTimeout(() => {
          setShowToster(0);
        }, 1500)
      }
    }
  }
  const handleEdit = (params, value) => {
    setUpdateBool(1);
    clearValidationErrMessage();
    setRowId(params.id);
    setEditeditDesignationDdltDdl(params.packageNumber);

    setSendForm({
        clientName: params.clientName,
      designation: params.packageId,
  
    })
  }
 
  const clearValidationErrMessage = () => {
   
  }
  const handleClear = (value) => {
    clearValidationErrMessage();
    setRowId('')
    setUpdateBool(0);
    setEditeditDesignationDdltDdl("");
    setEditMenuDDL("");
    setClearDropdown(value);
    setIsOrder(true);
    setSendForm({
        clientName: '',
      designation: '',

    });


  }

  useEffect(() => {
    getTaskCategoryList();
    getDesignationList();
 
  }, []);

  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Heading text="Client Master"/>
              <BoxContainer>
              <div className="col-2 mb-2 me-2">
                  <label htmlFor="ShiftFrom" className="form-label">{t("Package_Name")}<span className="starMandatory">*</span></label>
                  {designationList &&
                    <DropdownWithSearch
                      defaulNname="Select Designation"
                      name="designation"
                      list={designationList}
                      valueName="id"
                      displayName="packageTitle"
                      editdata={editDesignationDdl}
                      getvalue={handleChange}
                      clear={clearDropdown}
                      clearFun={handleClear}
                    />
                  }
                  <small id="errPackageName" className="invalid-feedback" style={{ display: 'none' }}></small>

                </div>
                <div className="col-2 mb-2 me-2">
                  <label htmlFor="clientName" className="form-label">{t("Client_Name")}<span className="starMandatory">*</span></label>
                  <input type="text" className="form-control form-control-sm" name="clientName" id="clientName" value={sendForm.clientName} disabled />
                  <small id="errclientName" className="invalid-feedback" style={{ display: 'none' }}></small>
                </div>
             

                <div className="mb-2 relative">
                  <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                  <div>
                    {showUnderProcess === 1 ? <TosterUnderProcess /> :
                      <>
                        {showToster === 1 ?
                          <Toster value={tosterValue} message={tosterMessage} />

                          : <div>
                            {updateBool === 1 ?

                              <>
                                {/* <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={handlerSave}>Update</button> */}
                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={handelUpdate}>{t("Update")}</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { handleClear(1) }}>{t("Cancel")}</button>
                              </>
                              :
                              ''
                            }
                          </div>}
                      </>
                    }
                  </div>
                </div>
                {/* <>
                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handlerSave}><img src={saveButtonIcon} className='icnn' />Save</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { handleClear(1) }}><img src={clearIcon} className='icnn' />Clear</button>
                              </> */}

              </BoxContainer>

            </div>
            <div className="col-12 mt-2">
              <div className='handlser'>
                <Heading text={t("Client_Master_List")} />
                <div style={{ position: 'relative' }}>
                  <input type="text" className='form-control form-control-sm' placeholder={t("Search")} onChange={handleSearch} />
                  <span className="tblsericon"><i className="fas fa-search"></i></span>
                </div>
              </div>
              <div className="med-table-section" style={{ "height": "75vh" }}>
                <TableContainer>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>{t("Package_Name")}</th>
                      <th>{t("Client_Name")}</th>
                      <th>{t("EMAIL_ID")}</th>
                   
                      <th>{t("MOBILE_NUMBER")}</th>
                      <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {taskCategoryMasterList && taskCategoryMasterList.map((val, ind) => {
                      return (
                        <tr key={val.id}>
                          <td className="text-center">{ind + 1}</td>
                          <td>{val.packageNumber}</td>
                          <td>{val.clientName}</td>
                          <td>{val.emailID}</td>
                          <td>{val.mobileNo}</td>
                      
                          <td>
                            <div className="action-button">
                              <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={editBtnIcon} className='' alt='' onClick={() => { handleEdit(val, 1) }} /></div>
                              {/* <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowId(val.id) }} />
                              </div> */}
                            </div>
                          </td>
                        </tr>
                      )
                    })}


                  </tbody>
                </TableContainer>
                {/* -----------------------Start Delete Modal Popup-------------------   */}

                {/*  <!-- Modal -->  */}
                {/* <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                  <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                      <div className="modal-body modelbdy text-center">
                        <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                        <div className='popDeleteTitle mt-3'>Delete?</div>
                        <div className='popDeleteContent'>Are you sure want to delete?</div>
                      </div>
                      <div className="modal-footer1 text-center">

                        <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn-delete popBtnDelete" onClick={handleDelete} data-bs-dismiss="modal">Delete</button>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

              </div>
            </div>
          </div>
        </div>
        {
          showLoder === 1 ? <Loader val={showLoder} /> : ""
        }
        {/* Toaster */}
        {
          isShowToaster === 1 ?
            <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
        }

        {
          showAlertToster === 1 ?
            <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
        }
      </section>
    </>
  )
}
