import React, { useEffect, useState } from 'react'
import Heading from '../../../../../Component/Heading'
import TableContainer from '../../../../../Component/TableContainer'
import save from '../../../../../assets/images/icons/save.svg'

import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import IPDMedication from './IPDMedicationForICU';
import MedicationAddComponent from './MedicationAddComponent';
import SedationMedicationAdd from './SedationMedicationAdd';
export default function SedationMedicationComponent({height = "265px"}) {
    const { t } = useTranslation();
    let [patientPrecriptionHistory, setPatientPrecriptionHistory] = useState([])
    let setData = () => {

        function query(data, query, value) {
            return data
                .filter(item => item[value] == query)

        }

        let temp = window.sessionStorage.getItem("IPDpatientsendData") ? JSON.parse(window.sessionStorage.getItem("IPDpatientsendData")) : []
        let activeUHID = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : []
        let medicationsData = []
        let druginteractionId = []
        temp.map((value, index) => {
            value.map((val, ind) => {
                if (value[0] === activeUHID) {
                    let key = Object.keys(val)
                    if (key[0] === "jsonArray") {
                        val.jsonArray.map((val, i) => {
                            if (val != null) {
                                // setPatientPrecriptionHistory([...medicationsData, val])
                                if (val.stopBy === null || val.stopBy === "") {
                                    // let resp = rationalList ? query(rationalList, val.rationalId, "id") : []
                                    // if (resp.length !== 0) {
                                    //     val["problemName"] = resp[0].problemName
                                    // }
                                    // else {
                                    //     val["problemName"] = ""

                                    // }
                                    medicationsData.push(val)
                                }
                            }


                        })
                    }
                }
            })
        })
        if (medicationsData.length != 0) {
            let index = medicationsData.findIndex(temp => temp.id === 0);
            if (index != -1) {
                setPatientPrecriptionHistory([...medicationsData])
            }
            else {
                setPatientPrecriptionHistory([...medicationsData])
            }
        }
        else {
            setPatientPrecriptionHistory([...medicationsData])
        }
    }

    useEffect(()=>{
        setData()
    },[])
    return (
        <>
            <div className='icuChartHeading-inn pointSpan mt-2'>
                <span className='col-md-12 ml-2' style={{ textAlign: 'right' }}>
                    <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" data-bs-toggle="modal" data-bs-title="Forgot" data-bs-target="#medicalicumodal">+ Add</button>
                </span>
            </div>
            <div className='roww mt-2'>
                <div className={` boxcontainer med-table-section `} style={{ height: height, overflowX: "auto" }}>
                    <TableContainer>
                        <thead>
                            <th>{t("MEDICINE")}</th>
                            {/* <th>{t("DOSAGE_FORM")}</th> */}
                            {/* <th>{t("STRENGTH")}</th> */}
                            {/* <th>{t("UNIT")}</th> */}
                            <th>{t("FREQUENCY")}</th>
                            <th>{t("DURATION")}</th>
                            <th>{t("Remark")}</th>



                        </thead>
                        <tbody>
                            {patientPrecriptionHistory && patientPrecriptionHistory.map((val, ind) => {
                                return (
                                    <tr>
                                        <td>{val.drugName.toUpperCase()}</td>
                                        {/* <td>Dosage Form</td> */}
                                        {/* <td>Strength</td> */}
                                        {/* <td>{val.doseUnit}</td> */}
                                        <td>{val.doseFrequency.length !== 0 ? val.doseFrequency : "-"}</td>
                                        <td>{val.duration.length !== 0 ? val.duration : "-"}</td>
                                        <td>{val.remark.length !== 0 ? val.remark.length : "-"}</td>
                                        {/* <td>Written By</td> */}
                                        {/* <td>{val.stopByName}</td> */}
                                        {/* <td>{val.stopDate}</td> */}

                                    </tr>
                                )
                            })}

                        </tbody>
                    </TableContainer>
                </div>
            </div>
            <div className='modal fade' id="medicalicumodal" aria-labelledby="medicalicumodal" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modal-xl  " style={{ margin: '3% auto' }}>
                    <div className="modal-content position-relative">
                        {/* <button type="button" className="btn-close_ position-absolute top-0 start-100 translate-middle bg-dark bg-gradient text-white border-0 rounded-circle_ rounded" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg"></i></button> */}
                        <div className="modal-header modalheading">
                            <h5 className="modal-title">Add Medicine </h5>
                            <i className="fa fa-times" data-bs-dismiss="modal" aria-label="Close" ></i>
                        </div>

                        <div className="modal-body text-center1">
                            <div className='med-box1'>
                                <div className='inner-content1'>
                                    <SedationMedicationAdd setData={setData}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
