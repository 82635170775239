import React, { useState, useEffect } from 'react'
//import GetProjectType from '../API/ProjectTypeMaster/GetProjectTypeMaster'
//import GetProjectMaster from '../API/ProjectMaster/GetProjectMaster'
import GetStory from '../API/StoryMaster/GetStory'
import GetModuleByProjectUser from '../API/ModuleMaster/GetModuleByProjectUser'
import SaveStory from '../API/StoryMaster/SaveStory'
import GetStatusPriority from '../API/StatusMaster/GetStatusPriority'
import UpdateStory from '../API/StoryMaster/UpdateStory'
import DeleteStory from '../API/StoryMaster/DeleteStory'
import GetProjectByUser from '../API/ProjectMaster/GetProjectByUser'
import SuccessToster from '../../Component/SuccessToster'
import WarningToaster from '../../Component/WarningToaster'
import AlertToster from '../../Component/AlertToster'
import Loder from '../../Component/Loader'
import editbtn from '../../assets/images/icons/editbtn.svg'
import delbtn from '../../assets/images/icons/delbtn.svg'
import save from "../../assets/images/icons/save.svg";
import reset from "../../assets/images/icons/reset.svg";
import Heading from '../../Component/Heading'
import DropdownWithSearch from '../../Component/DropdownWithSearch'
import noData from '../../assets/images/icons/No data-rafiki.svg';
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import TextEditor from '../../Component/TextEditor'



export default function StoryMaster() {

    const [getStoryMasterList, setStoryMasterList] = useState([]);
    const [getProjectList, setProjectList] = useState([]);
    const [getStatusPriorityList, setStatusPriorityList] = useState([]);
    const [getModuleByProjectList, setModuleByProjectList] = useState([]);

    const [loder, setLoder] = useState(1);
    const [showToster, setShowToster] = useState(0);
    const [message, setMessage] = useState("");

    const [getSelectedOptionProject, setSelectedOptionProject] = useState('');
    const [getSelectedOptionModule, setSelectedOptionModule] = useState('');
    const [getSelectedOptionPriority, setSelectedOptionPriority] = useState('');
    const [getWantToAble, setWantToAble] = useState('');
    const [getSoThat, setSoThat] = useState('');
    const [getAppURL, setAppURL] = useState('');
    const [getWebURL, setWebURL] = useState('');
    const [getRemark, setRemark] = useState('');
    const [getRowID, setRowID] = useState('');
    const [searchInput, setSearchInput] = useState('');

    const [clearDropdown, setClearDropdown] = useState(0);
    const [editProjectName, seteditProjectName] = useState("");
    const [editModuleName, seteditModuleName] = useState("");
    const { t } = useTranslation();

    const [getSaveUpdateBool, setSaveUpdateBool] = useState(0);
    const [filteredData, setFilteredData] = useState([]);

    //Handle Change
    const handleChange = async (e) => {
        const { name, value } = e.target;
        filterDataByProject(value, name);
        if (name === "projectID") {
            setSelectedOptionProject(value);
            await funGetModule(value);
        }
        else {
            setSelectedOptionModule(value);
        }
    };

    const funGetStory = async () => {
        const getResult = await GetStory(window.userId);
        setLoder(1);
        if (getResult.status === 1) {
            setLoder(0);
            setStoryMasterList(getResult.responseValue);
            setFilteredData(getResult.responseValue);
        }
    };

    const filterDataByProject = (value, type) => {
        let filterData = [];
        if (type === "projectID") {
            filterData = getStoryMasterList.filter((data) => {
                return data.projectId === value;
            });
        }
        else {
            filterData = getStoryMasterList.filter((data) => {
                return data.projectId === getSelectedOptionProject && data.moduleID === value;
            });
        }
        setFilteredData(filterData);
    };

    const GetFilterDataByProject = async (projectId, moduleId) => {

        let filterData = [];
        const getResult = await GetStory(window.userId);
        setLoder(1);
        if (getResult.status === 1) {
            setLoder(0);
            const data = getResult.responseValue;
            setStoryMasterList(data);
            if (projectId && moduleId) {
                filterData = data.filter((data) => {
                    return data.projectId === projectId && data.moduleID === moduleId;
                });
                setFilteredData(filterData);
            }
            else if (projectId) {
                filterData = data.filter((data) => {
                    return data.projectId === projectId;
                });
            }
            else {
                setFilteredData(data);
            }
        }
    };


    const funGetProject = async () => {
        const getResult = await GetProjectByUser(window.userId);
        setProjectList(getResult.responseValue);
    };


    const funGetModule = async (projectID) => {
        const getResult = await GetModuleByProjectUser(projectID, window.userId);
        if (getResult.status === 1) {
            setLoder(0);
            setModuleByProjectList(getResult.responseValue);
        }
    };

    const funGetStatusPriority = async () => {
        const getResult = await GetStatusPriority()
        setStatusPriorityList(getResult.responseValue);
    };

    const SelectedOptionPriority = async (event) => {
        setSelectedOptionPriority(event.target.value);
    }
    const handleTextboxChange = (event) => {
        if (event.target.name === "wantToAble") {
            setWantToAble(event.target.value);
        }
        else if (event.target.name === "soThat") {
            setSoThat(event.target.value);
        }
        else if (event.target.name === "appURL") {
            setAppURL(event.target.value);
        }
        else if (event.target.name === "webURL") {
            setWebURL(event.target.value);
        }
        else if (event.target.name === "remark") {
            setRemark(event.target.value);
        }
    };

    const SaveData = async () => {
        const getSelectedOptionPriority = document.getElementById("sldPriority").value;
        const specialCharsRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        const numbersRegex = /^[0-9]*$/;
        if (getSelectedOptionProject === '0' || getSelectedOptionProject === undefined || getSelectedOptionProject === null || getSelectedOptionProject === "") {
            setMessage("Project not fill !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSelectedOptionModule === '0' || getSelectedOptionModule === undefined || getSelectedOptionModule === null || getSelectedOptionModule === "") {
            setMessage("Module not fill !")
            setShowToster(3)
            setLoder(0)

        }
        else if (getWantToAble === '0' || getWantToAble === undefined || getWantToAble === null || getWantToAble === "") {
            setMessage("Want to able not fill !")
            setShowToster(3)
            setLoder(0)

        }
        else if (getWantToAble.trim().length === 0 || getWantToAble.trim().length === '') {
            setMessage("Fields can't blank or space !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSoThat === '0' || getSoThat === undefined || getSoThat === null || getSoThat === "") {
            setMessage("So that not fill !")
            setShowToster(3)
            setLoder(0)

        }
        else if (getSelectedOptionPriority === '0' || getSelectedOptionPriority === undefined || getSelectedOptionPriority === null || getSelectedOptionPriority === "") {
            setMessage("Priority not fill !")
            setShowToster(3)
            setLoder(0)

        }
        else if (getSoThat.trim().length === 0 || getSoThat.trim().length === '') {
            setMessage("Fields can't blank or space !")
            setShowToster(3)
            setLoder(0)
        }
        else if (numbersRegex.test(getWantToAble)) {
            setMessage("Want to able contains numbers !");
            setShowToster(3);
            setLoder(0);
        }
        else if (numbersRegex.test(getSoThat)) {
            setMessage("So that contains numbers !");
            setShowToster(3);
            setLoder(0);
        }
        else {
            const getWantToAbleWithoutTags = getWantToAble.replace(/(<([^>]+)>)/gi, ""); // Remove HTML tags
            const getSoThatWithoutTags = getSoThat.replace(/(<([^>]+)>)/gi, ""); // Remove HTML tags
            var obj = {
                ProjectId: getSelectedOptionProject,
                ModuleId: getSelectedOptionModule,
                WantAbleTo: getWantToAbleWithoutTags,
                SoThat: getSoThatWithoutTags,
                AppURL: getAppURL,
                WebURL: getWebURL,
                Remark: getRemark,
                priorityID: getSelectedOptionPriority,
                UserID: window.userId,
                clientId: JSON.parse(window.sessionStorage.getItem('LoginData')).clientId
            }
            let response = await SaveStory(obj);
            setLoder(1)
            if (response.status === 1) {
                setLoder(0);
                setMessage('Data saved successfully !!');
                setShowToster(1);
                setSaveUpdateBool(0);
                GetFilterDataByProject(getSelectedOptionProject, getSelectedOptionModule);
                clearFields(1);
            }
            else {
                setLoder(0);
                setMessage(response.responseValue);
                setShowToster(2);
                setSaveUpdateBool(0);
                //funGetStory();
            }
        }
    };

    const clearValues = (value) => {
        setSaveUpdateBool(0);
        setSelectedOptionPriority('');
        setWantToAble('');
        setSoThat('');
        setAppURL('');
        setWebURL('');
        setRemark('');
        setClearDropdown(value)
        seteditProjectName("");
        seteditModuleName("");
        setSelectedOptionProject("");
        setSelectedOptionModule("");
        setFilteredData(getStoryMasterList);
        document.getElementById("sldPriority").value = 0;
    };
    const clearFields = (value) => {
        document.getElementById("sldPriority").value = 0;
        setSaveUpdateBool(0);
        setWantToAble('');
        setSoThat('');
        setAppURL('');
        setWebURL('');
        setRemark('');
        seteditProjectName("");
        seteditModuleName("");
    };

    const EditStory = async (Id, projectId, moduleId, priorityID, wantAbleTo, soThat, webURL, appURL, remark, projectName, moduleName) => {
        setSaveUpdateBool(1);
        document.getElementById('sldPriority').value = priorityID;
        setRowID(Id)
        setSelectedOptionProject(projectId);
        setSelectedOptionModule(moduleId);
        setSelectedOptionPriority(priorityID);
        setWantToAble(wantAbleTo);
        setSoThat(soThat);
        setAppURL(appURL);
        setWebURL(webURL);
        setRemark(remark);
        seteditProjectName(projectName);
        seteditModuleName(moduleName);

        let getData = await GetModuleByProjectUser(projectId, window.userId);
        if (getData.status === 1) {
            setModuleByProjectList(getData.responseValue);
        }
    };

    const funUpdateStory = async () => {
        const getSelectedOptionPriority = document.getElementById("sldPriority").value;
        const specialCharsRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        const numbersRegex = /^[0-9]*$/;

        if (getSelectedOptionProject === '0' || getSelectedOptionProject === undefined || getSelectedOptionProject === null || getSelectedOptionProject === "") {
            setMessage("Project not fill !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSelectedOptionModule === '0' || getSelectedOptionModule === undefined || getSelectedOptionModule === null || getSelectedOptionModule === "") {
            setMessage("Module not fill !")
            setShowToster(3)
            setLoder(0)

        }
        else if (getWantToAble === '0' || getWantToAble === undefined || getWantToAble === null || getWantToAble === "") {
            setMessage("Want to able not fill !")
            setShowToster(3)
            setLoder(0)

        }
        else if (getWantToAble.trim().length === 0 || getWantToAble.trim().length === '') {
            setMessage("Fields can't blank or space !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSoThat === '0' || getSoThat === undefined || getSoThat === null || getSoThat === "") {
            setMessage("So that not fill !")
            setShowToster(3)
            setLoder(0)

        }
        else if (getSelectedOptionPriority === '0' || getSelectedOptionPriority === undefined || getSelectedOptionPriority === null || getSelectedOptionPriority === "") {
            setMessage("Priority not fill !")
            setShowToster(3)
            setLoder(0)

        }
        else if (getSoThat.trim().length === 0 || getSoThat.trim().length === '') {
            setMessage("Fields can't blank or space !")
            setShowToster(3)
            setLoder(0)
        }
        else {
            const getWantToAbleWithoutTags = getWantToAble.replace(/(<([^>]+)>)/gi, ""); // Remove HTML tags
            const getSoThatWithoutTags = getSoThat.replace(/(<([^>]+)>)/gi, ""); // Remove HTML tags

            var obj = {
                Id: getRowID,
                projectId: getSelectedOptionProject,
                moduleId: getSelectedOptionModule,
                priorityID: getSelectedOptionPriority,
                wantAbleTo: getWantToAbleWithoutTags,
                soThat: getSoThatWithoutTags,
                appURL: getAppURL,
                webURL: getWebURL,
                remark: getRemark,
                clientId: JSON.parse(window.sessionStorage.getItem('LoginData')).clientId
            }
            let result = await UpdateStory(obj);
            setLoder(1);
            if (result.status === 1) {
                setLoder(0);
                setMessage('Data updated successfully !!')
                setShowToster(1);
                setSaveUpdateBool(0);
                GetFilterDataByProject(getSelectedOptionProject, getSelectedOptionModule);
                clearFields(1);
            }
            else {
                setLoder(0);
                setMessage(result.responseValue);
                setShowToster(2);
            }
        }
    };

    const funDeleteStory = async () => {
        var obj = {
            Id: getRowID,
        }
        let result = await DeleteStory(obj);
        setLoder(1);
        if (result.status === 1) {
            setLoder(0);
            setMessage('Data deleted successfully !!')
            setShowToster(1)
            GetFilterDataByProject(getSelectedOptionProject, getSelectedOptionModule);
            clearFields(1);
        }
        else {
            setLoder(0);
            setMessage(result.responseValue)
            setShowToster(2)
        }
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        if (name === 'searchBox') {
            setSearchInput(value)
        }
    };

    useEffect(() => {
        funGetStory()
        funGetProject()
        funGetStatusPriority()
    }, []);

    return (
        <>
            <section className="main-content pt-3 mt-5">

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="med-box">
                                <div className="title">Add Story </div>
                                <div className="inner-content">
                                    <div className="d-flex flex-wrap align-content-end">
                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="projectName" className="form-label">Project<span className="starMandatory">*</span></label>
                                            <div className='drpWithSearch'>
                                                {getProjectList && <DropdownWithSearch defaulNname={t("Select Project")} name="projectID" list={getProjectList} valueName="projectId" displayName="projectName" editdata={editProjectName} getvalue={handleChange} clear={clearDropdown} clearFun={clearValues} />}
                                            </div>
                                        </div>
                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="moduleName" className="form-label">Module<span className="starMandatory">*</span></label>
                                            <div className='drpWithSearch'>
                                                {getModuleByProjectList && <DropdownWithSearch defaulNname={t("Select Module")} name="moduleID" list={getModuleByProjectList} valueName="id" displayName="moduleName" editdata={editModuleName} getvalue={handleChange} clear={clearDropdown} clearFun={clearValues} />}
                                            </div>
                                        </div>


                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="projectName" className="form-label">URL</label>
                                            <input type="text" name='webURL' id='webURL' onChange={handleTextboxChange} value={getWebURL} className="form-control form-control-sm" placeholder='Enter URL' />
                                        </div>
                                        <div className="mb-2 col-md-2 me-2">
                                            <label htmlFor="projectName" className="form-label">Priority<span className="starMandatory">*</span></label>
                                            <select name='widgetId' id="sldPriority" onChange={SelectedOptionPriority} className="form-select form-select-sm" aria-label=".form-select-sm example">
                                                <option value="0">Select Priority</option>
                                                {getStatusPriorityList && getStatusPriorityList.map((val, index) => {
                                                    return (
                                                        <option value={val.id}>{val.statusText}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className="mb-2 col-md-4 flex-grow-1 me-2">
                                            <label htmlFor="wantToAble" className="form-label">I want to able to<span className="starMandatory">*</span></label>
                                            <TextEditor getTextvalue={handleTextboxChange} setValue={getWantToAble} name="wantToAble" id="wantToAble" />
                                        </div>
                                        <div className="mb-2 col-md-4 flex-grow-1 me-2">
                                            <label htmlFor="soThat" className="form-label">So that<span className="starMandatory">*</span></label>
                                            <TextEditor getTextvalue={handleTextboxChange} setValue={getSoThat} name="soThat" id="soThat" />
                                        </div>
                                        <div className="mb-2 relative">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                            <div>
                                                {getSaveUpdateBool === 0 ?
                                                    <>
                                                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={SaveData}><img src={save} className='icnn' />Save</button>
                                                        <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={() => { clearValues(1) }}><img src={reset} className='icnn' /> Clear</button>
                                                    </>
                                                    :
                                                    <>
                                                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={funUpdateStory}><img src={save} className='icnn' />Update</button>
                                                        <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={() => { clearValues(1) }}><img src={reset} className='icnn' /> Clear</button>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-1">
                            <div className='handlser'>
                                <Heading text="Story List" />
                                <div style={{ position: 'relative' }}>
                                    <input value={searchInput} onChange={handleOnChange} name="searchBox" type="search" className="form-control form-control-sm" placeholder="Search...." aria-label="Search" aria-describedby="search-addon" />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
                            <div className="med-table-section relative" style={{ "height": "77vh" }}>
                                <table className="med-table border_ striped">
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>S.No.</th>
                                            <th>Project</th>
                                            <th>Module</th>
                                            <th>Story</th>
                                            <th>URL</th>
                                            <th>Priority</th>
                                            <th style={{ "width": "10%" }} className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { filteredData.length > 0 ? 
                                            filteredData && filteredData.filter((val) => `${val.projectName} ${val.moduleName} ${val.wantAbleTo + ' ' + val.soThat} ${val.prioprity}`.toLowerCase().includes(searchInput.toLowerCase())).map((val, ind) => {

                                            return (
                                                <tr key={val.id}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td>{val.projectName}</td>
                                                    <td>{val.moduleName}</td>
                                                    <td>{val.wantAbleTo + ' ' + val.soThat}</td>
                                                    <td>{val.webURL}</td>
                                                    <td>{val.prioprity}</td>
                                                    <td>
                                                        <div className="action-button">
                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom" title="Edit Row" onClick={() => { EditStory(val.id, val.projectId, val.moduleID, val.priorityID, val.wantAbleTo, val.soThat, val.webURL, val.appURL, val.remark, val.projectName, val.moduleName) }}><span className='btnbg' style={{ background: "#FFEDD2" }}> <img src={editbtn} className='' /></span></div>
                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" title="Delete Row" data-bs-target="#deleteModal" ><span className='btnbg' style={{ background: "#FFEFEF" }} onClick={() => { setRowID(val.id) }}><img src={delbtn} className='icnn' /></span></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <img className='haveNodataImg' src={noData} alt="No Data" />
                                        }
                                    </tbody>
                                </table>
                                {/* -----------------------Start Delete Modal Popup-------------------   */}

                                {/*  <!-- Modal -->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">
                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'> Delete?</div>
                                                <div className='popDeleteContent'> Are you sure you want to delete?</div>
                                            </div>
                                            <div className="modal-footer1 text-center">
                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={funDeleteStory} data-bs-dismiss="modal">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}
                            </div>

                        </div>


                    </div>
                </div>
                {showToster === 1 ? <SuccessToster message={message} handle={setShowToster} /> : ""}
                {showToster === 2 ? <WarningToaster message={message} handle={setShowToster} /> : ""}
                {showToster === 3 ? <AlertToster message={message} handle={setShowToster} /> : ""}
            </section>
            <Loder val={loder} />
        </>
    )
}
