import React, { useEffect, useState } from 'react'
import Heading from '../../../Component/Heading'
import BoxContainer from '../../../Component/BoxContainer'
import TableContainer from '../../../Component/TableContainer'
import Toster from '../../../Component/Toster'
import TosterUnderProcess from '../../../Component/TosterUnderProcess'
import ValidationCareTakerMaster from '../../../Validation/Admin/Master/ValidationCareTakerMaster'
import PostCareTakerMaster from '../../Api/Master/CareTakerMaster/PostCareTakerMaster'
import GetCareTakerMaster from '../../Api/Master/CareTakerMaster/GetCareTakerMaster'
import DeleteCareTakerMaster from '../../Api/Master/CareTakerMaster/DeleteCareTakerMaster'
import PutCareTakerMaster from '../../Api/Master/CareTakerMaster/PutCareTakerMaster'
import IconEdit from '../../../assets/images/icons/IconEdit.svg'
import IconDelete from '../../../assets/images/icons/IconDelete.svg'
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
export default function CareTakerMaster() {
    let [caretakerList, setcaretakerList] = useState([])
    let [updateBool, setUpdateBool] = useState(0)
    let [sendForm, setSendForm] = useState({ "userId": window.userId, "clientId": window.clientId })
    let [loder, setLoder] = useState(1)
    let [rowId, setRowId] = useState('')

    let [showUnderProcess, setShowUnderProcess] = useState(0)
    let [showToster, setShowToster] = useState(0)
    let [tosterMessage, setTosterMessage] = useState("")
    let [tosterValue, setTosterValue] = useState(0)
    const [numberError, setNumberError] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const { t } = useTranslation();
    // Function to handle changes in the search term
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    //Handle Save
    let saveForm = async () => {
        let valresponse = ValidationCareTakerMaster(sendForm.caretakerName);
        if (valresponse && !numberError) {
            setShowUnderProcess(1);
            let response = await PostCareTakerMaster(sendForm);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setShowToster(1);
                setTosterMessage("Data Save SuccessFully!");
                setTosterValue(0);
                setTimeout(() => {
                    setShowToster(0);
                }, 2000);

                handleClear();
            } else {
                setShowUnderProcess(0);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTosterValue(1);
                setTimeout(() => {
                    setShowToster(0);
                }, 2000);
            }
            getdata();
        } else {
            setShowUnderProcess(0);
            setShowToster(1);
            setTosterMessage("Field can't be blank or contain numbers!");
            setTosterValue(1);
            setTimeout(() => {
                setShowToster(0);
            }, 2000);
        }
    }

    //Get data
    let getdata = async () => {
        const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
        let getResponse = await GetCareTakerMaster(clientID);
        if (getResponse.status === 1) {
            setcaretakerList(getResponse.responseValue)
        }

    }
    //Handle Change
    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (/^[a-zA-Z\s]+$/.test(value) || value === "") {
            setSendForm(sendForm => ({
                ...sendForm,
                [name]: value
            }));
            setNumberError(false);
        } else {
            setNumberError(true);
        }
    }

    //Handle Delete
    let handleDeleteRow = async () => {
        // setLoder(1)
        setShowUnderProcess(1);
        let obj = {
            id: rowId
        }
        let response = await DeleteCareTakerMaster(obj)
        if (response.status === 1) {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Data Deleted SuccessFully!")
            setTosterValue(0)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
            getdata();
            handleClear();
        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage(response.responseValue)
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }
    //Handle Button Change
    let handleUpdate = async (id, caretakerName, userId) => {
        setUpdateBool(1)
        setSendForm(sendForm => ({
            ...sendForm,
            "id": id,
            "caretakerName": caretakerName,
            "userId": userId,
        }))

        document.getElementById("caretakerName").value = caretakerName;
    }


    // Handle Update
    let saveUpdate = async () => {
        let valresponse = ValidationCareTakerMaster(sendForm.caretakerName);

        if (valresponse && !numberError) {
            setShowUnderProcess(1);
            let response = await PutCareTakerMaster(sendForm);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setShowToster(1);
                setTosterMessage("Data Updated Successfully!");
                setTosterValue(0);
                setTimeout(() => {
                    setShowToster(0);
                }, 2000);

                setUpdateBool(0);
                getdata();
                handleClear();
            } else {
                setShowUnderProcess(0);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTosterValue(1);
                setTimeout(() => {
                    setShowToster(0);
                }, 2000);
            }
        } else {
            setShowUnderProcess(0);
            setShowToster(1);
            setTosterMessage("Field can't be blank or contain numbers!");
            setTosterValue(1);
            setTimeout(() => {
                setShowToster(0);
            }, 2000);
        }
    }

    //Handle Clear
    let handleClear = () => {
        document.getElementById("caretakerName").value = "";
        setSendForm({ "userId": window.userId, "clientId": window.clientId })
        setUpdateBool(0)
        setNumberError(false);
    }
    useEffect(() => {
        getdata()
    }, [])
    document.body.dir = i18n.dir()
    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <Heading text={t("Caretaker_Master")} />
                            <BoxContainer>
                                <div className="mb-2 me-2">
                                    <label htmlFor="caretakerName" className="form-label">{t("Caretaker_Name")}<span className="starMandatory">*</span></label>
                                    {/* <input type="text" className="form-control form-control-sm" id="caretakerName" name='caretakerName' onChange={handleChange} placeholder={t("Enter_Caretaker_Name")} /> */}
                                    <input type="text" className={`form-control form-control-sm ${numberError ? 'is-invalid' : ''}`} id="caretakerName" name='caretakerName' onChange={handleChange} placeholder={t("Enter_Caretaker_Name")} />
                                    {numberError && <div className="invalid-feedback">Please enter a valid name without numbers.</div>}
                                </div>

                                <div className="mb-2 relative">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                    <div>
                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />

                                                    : <div>
                                                        {updateBool === 0 ?
                                                            <>
                                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={saveForm}><img src={saveButtonIcon} className='icnn' alt='' />{t("Save")}</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt='' />{t("Cancel")}</button>
                                                            </>
                                                            :
                                                            <>
                                                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={saveUpdate}>{t("UPDATE")}</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { setUpdateBool(0); handleClear() }}>{t("Cancel")}</button>
                                                            </>
                                                        }
                                                    </div>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </BoxContainer>

                        </div>
                        <div className="col-12 mt-3">
                            <div className='handlser'>
                                <Heading text={t("Caretaker_List")} />
                                <div style={{ position: 'relative' }}>
                                    <input type="text" className='form-control form-control-sm' placeholder={t("Search")} value={searchTerm} onChange={handleSearch} />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
                            <div className="med-table-section" style={{ "height": "74vh" }}>

                                <TableContainer>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>#</th>
                                            <th>{t("Caretaker_Name")}</th>
                                            <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {caretakerList && caretakerList.filter((val) => `${val.caretakerName}`.toLowerCase().includes(searchTerm.toLowerCase())).map((val, ind) => {
                                            return (
                                                <tr key={val.id}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td>{val.caretakerName}</td>
                                                    <td>
                                                        <div className="action-button">
                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={IconEdit} onClick={() => { handleUpdate(val.id, val.caretakerName, val.userId) }} alt='' /></div>
                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={IconDelete} onClick={() => { setRowId(val.id) }} alt='' /></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}

                                    </tbody>
                                </TableContainer>
                                {/*  <!------------------- Start Delete Modal ---------------------------------->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">

                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'>{t("Delete?")}</div>
                                                <div className='popDeleteContent'>{t("Are_you_sure_you_want_to_delete?")}</div>
                                            </div>
                                            <div className="modal-footer1 text-center">

                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={handleDeleteRow} data-bs-dismiss="modal">{t("Delete")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}
                            </div>

                        </div>


                    </div>
                </div>


            </section>
        </>
    )
}
