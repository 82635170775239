// logoutModule.js
const userId = window.userId; // Ensure window.userId is set globally

// Function to initialize logout handling via BroadcastChannel
const initBroadcastChannelLogout = (userIds) => {
    const logoutChannel = new BroadcastChannel('logout_channel');
    console.log("call", userIds)
    logoutChannel.onmessage = (message) => {
        if (message.data.action === 'logout' && message.data.userId === userIds) {
            handleLogout();      
        }
    };

    const sendBroadcastLogout = () => {
        logoutChannel.postMessage({ action: 'logout', userId });
    };

    return sendBroadcastLogout;
};

// Function to initialize logout handling via localStorage
const initLocalStorageLogout = (userIds) => {
    console.log("call", userIds)
    window.addEventListener('storage', (event) => {
        if (event.key === 'logout') {
            const logoutData = JSON.parse(event.newValue);
            if (logoutData.userId === userIds) {
                handleLogout();
            }
        }
    });

    const sendLocalStorageLogout = () => {
        localStorage.setItem('logout', JSON.stringify({ userId, timestamp: Date.now() }));
    };

    return sendLocalStorageLogout;
};

// Shared logout handling function
const handleLogout = () => {
    // Perform logout operations
    sessionStorage.clear();
    // Redirect to login or home page
    window.location.href = '/login/';
};

// Exported initialization functions
export { initBroadcastChannelLogout, initLocalStorageLogout };
