let ECGBodyPartsVirtualView = async () => {   
    let url = window.PatientMonitorDashboardAPI+"/api/EcgGraphForExternal/GetECGGraphForExternal"
    let headers =  {"content-type":"application/json", "accept": "application/json"}; 
  
    let uhID = JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid;
    let clientData = JSON.parse(sessionStorage.getItem("LoginData"));    
    let userId = clientData.userId;

    const data = {
        "uhid": uhID,
        "rawData": "",
        "mainData": "",
        "leadData": "",
        "leadType": "",
        "userId": userId
      }

    let response = await fetch(url,{
        method:"POST",
        headers:headers,
        body: JSON.stringify(data)
    }).then((res)=>res.json()).then(data); 
    
    return response;
}

export default ECGBodyPartsVirtualView;
