let GetPatientHistoryByUHID = async (uhid) => {
  let ClientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId
  let url = window.AppbaseUrl + '/api/PatientHistoryByUHID/GetPatientHistoryByUHID?UHID=' + uhid + "&clientId=" + ClientID;
  let head = { 'Content-Type': 'application/json-patch+json', 'accept': '*/*', };
  let data = {};
  let response = await fetch(url, {
    method: 'GET',
    headers: head
  }).then(res => res.json()).then(data);
  return response;
}
export default GetPatientHistoryByUHID