import React, { useState, useEffect } from 'react'
import GetAgendaForDdl from '../API/Agenda/GetAgendaForDdl';
import GetMeetingForDdl from '../API/Meeting/GetMeetingForDdl';
import GetParticipantForDdl from '../API/Participant/GetParticipantForDdl';
import GetActionItem from '../API/ActionItem/GetActionItem';
import DeleteActionItem from '../API/ActionItem/DeleteActionItem';
import PostActionItem from '../API/ActionItem/PostActionItem';
import PutActionItem from '../API/ActionItem/PutActionItem';
import SuccessToster from '../../Component/SuccessToster'
import WarningToaster from '../../Component/WarningToaster'
import AlertToster from '../../Component/AlertToster'
import Loder from '../../Component/Loader'
import editbtn from '../../assets/images/icons/editbtn.svg'
import delbtn from '../../assets/images/icons/delbtn.svg'
import save from "../../assets/images/icons/save.svg";
import reset from "../../assets/images/icons/reset.svg";
import Heading from '../../Component/Heading'
import DropdownWithSearch from '../../Component/DropdownWithSearch'
import { useTranslation } from 'react-i18next'
import noData from '../../assets/images/icons/No data-rafiki.svg';
import i18n from 'i18next'

export default function ActionItem() {

    const [getAgendaList, setAgendaList] = useState([])
    const [getMeetingList, setMeetingList] = useState([])
    const [getParticipantList, setParticipantList] = useState([])
    const [getActionItemList, setActionItemList] = useState([])

    const [getId, setId] = useState('')
    const [getActionItemName, setActionItemName] = useState('')
    const [getDueDate, setDueDate] = useState('')

    const [getSelectedAgenda, setSelectedAgenda] = useState([''])
    const [getSelectedMeeting, setSelectedMeeting] = useState([''])
    const [getSelectedParticipant, setSelectedParticipant] = useState([''])

    const [getSaveUpdateBool, setSaveUpdateBool] = useState(0)
    const [clearDropdown, setClearDropdown] = useState(0)
    const [loder, setLoder] = useState(1)
    const [showToster, setShowToster] = useState(0)
    const [message, setMessage] = useState("")
    const [searchInput, setSearchInput] = useState('');

    const [editAgenda, seteditAgenda] = useState("")
    const [editMeeting, seteditMeeting] = useState("")
    const [editParticipant, seteditParticipant] = useState("")

    const { t } = useTranslation();

    //Handle Change
    const handleChange = async (e) => {
        const {name, value} = e.target;
        if (name === "AgendaID") {
            setSelectedAgenda(value)
            await funGetMeeting(value)
        }
        else if (name === "MeetingID") {
            setSelectedMeeting(value)
            await funGetParticipant(value)
        }
        else {
            setSelectedParticipant(value)
        }
    };

    const funGetActionItem = async () => {
        const {status, responseValue} = await GetActionItem();
        if (status) {
            setLoder(0)
            setActionItemList(responseValue);
        }
    };

    const funGetAgenda = async () => {
        const {status, responseValue} = await GetAgendaForDdl();
        if (status) {
            setLoder(0)
            setAgendaList(responseValue);
        }
    };

    const funGetMeeting = async (agendaId) => {
        const {status, responseValue} = await GetMeetingForDdl(agendaId, window.userId);
        if (status) {
            setLoder(0)
            setMeetingList(responseValue);
        }
    };

    const funGetParticipant = async (meetingId) => {
        const {status, responseValue} = await GetParticipantForDdl(meetingId,window.userId);
        if (status) {
            setLoder(0)
            setParticipantList(responseValue);
        }
    };

    const handleTextboxChange = (event) => {
        if (event.target.name === "actionItemName") {
            setActionItemName(event.target.value);
        }
        if (event.target.name === "dueDate") {
            setDueDate(event.target.value);
        }
    };

    const clearValues = (value) => {
        setSaveUpdateBool(0);
        setActionItemName('');
        setDueDate('');
        setClearDropdown(value)
        seteditMeeting("");
        seteditAgenda("");
        seteditParticipant("");
    };

    const funSaveActionItem = async () => {
        const numbersRegex = /^[0-9]*$/;
        const specialCharsRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (getSelectedAgenda === '0' || getSelectedAgenda === undefined || getSelectedAgenda === null || getSelectedAgenda === "") {
            setMessage("Fill Agenda !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSelectedMeeting === '0' || getSelectedMeeting === undefined || getSelectedMeeting === null || getSelectedMeeting === "") {
            setMessage("Fill Meeting !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSelectedParticipant === '0' || getSelectedParticipant === undefined || getSelectedParticipant === null || getSelectedParticipant === "") {
            setMessage("Fill Participant !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getActionItemName === '0' || getActionItemName === undefined || getActionItemName === null || getActionItemName === "") {
            setMessage("Fill Action Item Name !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getDueDate === '0' || getDueDate === undefined || getDueDate === null || getDueDate === "") {
            setMessage("Fill Due Date !")
            setShowToster(3)
            setLoder(0)
        }
        else {
            var obj = {
                agendaId: getSelectedAgenda,
                meetingId: getSelectedMeeting,
                participantId: getSelectedParticipant,
                actionItemName: getActionItemName,
                dueDate: getDueDate,
                userID: window.userId,
                clientId: JSON.parse(window.sessionStorage.getItem('LoginData')).clientId
            }
            setLoder(1)
            let response = await PostActionItem(obj);
            if (response.status === 1) {
                setMessage("Data saved successfully !")
                setShowToster(1)
                setLoder(0)
                setSaveUpdateBool(0)
                funGetActionItem()
                clearValues(1)
            }
            else {
                setLoder(0);
                setMessage(response.responseValue)
                setShowToster(1)
            }
        }
    };

    const funeditActionItem = (Id, actionItemName, dueDate, agendaId, itemTitle, meetingId, title, participantId, name) => {
        setSaveUpdateBool(1)
        setId(Id);
        setActionItemName(actionItemName);
        setDueDate(reformatDateString(dueDate));
        setSelectedAgenda(agendaId);
        seteditAgenda(itemTitle);
        setSelectedMeeting(meetingId);
        seteditMeeting(title);
        setSelectedParticipant(participantId);
        seteditParticipant(name);
    };

    const funUpdateActionItem = async () => {
        const specialCharsRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        const numbersRegex = /^[0-9]*$/;
        if (getSelectedAgenda === '0' || getSelectedAgenda === undefined || getSelectedAgenda === null || getSelectedAgenda === "") {
            setMessage("Fill Agenda !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSelectedMeeting === '0' || getSelectedMeeting === undefined || getSelectedMeeting === null || getSelectedMeeting === "") {
            setMessage("Fill Meeting !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSelectedParticipant === '0' || getSelectedParticipant === undefined || getSelectedParticipant === null || getSelectedParticipant === "") {
            setMessage("Fill Participant !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getActionItemName === '0' || getActionItemName === undefined || getActionItemName === null || getActionItemName === "") {
            setMessage("Fill Action Item Name !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getDueDate === '0' || getDueDate === undefined || getDueDate === null || getDueDate === "") {
            setMessage("Fill Due Date !")
            setShowToster(3)
            setLoder(0)
        }
        else {
            var obj = {
                Id: getId,
                agendaId: getSelectedAgenda,
                meetingId: getSelectedMeeting,
                participantId: getSelectedParticipant,
                actionItemName: getActionItemName,
                dueDate: getDueDate,
                userID: window.userId,
                clientId: JSON.parse(window.sessionStorage.getItem('LoginData')).clientId
            }
            setLoder(1)
            let result = await PutActionItem(obj);
            if (result.status === 1) {
                setLoder(0)
                setMessage("Data update successfully !")
                setShowToster(1)
                setSaveUpdateBool(0)
                funGetActionItem()
                clearValues(1)
            }
            else {
                setMessage(result.responseValue)
                setShowToster(1)
                setLoder(0)
            }
        }
    };

    const funDeletedActionItem = async () => {
        var obj = {
            Id: getId,
        }
        setLoder(1)
        let result = await DeleteActionItem(obj);
        if (result.status === 1) {
            setLoder(0)
            setMessage("Data delete successfully !")
            setShowToster(1)
            clearValues()
            funGetActionItem()
        }
        else {
            setMessage(result.responseValue)
            setShowToster(1)
            setLoder(0)
        }
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        if (name === 'searchBox') {
            setSearchInput(value)
        }
    };

    function reformatDateString(s) {
        if (typeof s === 'string' && s.trim() !== '') {
            var b = s.split(/\D/);
            var day = parseInt(b[2], 10).toString();
            var month = parseInt(b[1], 10).toString();
            var year = b[0];
            return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
        } else {
            return ''; 
        }
    }

    useEffect(() => {
        funGetActionItem()
        funGetAgenda()
    }, [])

    return (
        <>
            <section className="main-content pt-3 mt-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="med-box">
                                <div className="title">Add Action Item</div>
                                <div className="inner-content">
                                    <div className="d-flex flex-wrap align-content-end">
                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="Agenda" className="form-label">Agenda<span className="starMandatory">*</span></label>
                                            {getAgendaList && <DropdownWithSearch defaulNname={t("Select Agenda")} name="AgendaID" list={getAgendaList} valueName="agendaId" displayName="itemTitle" editdata={editAgenda} getvalue={handleChange} clear={clearDropdown} clearFun={clearValues} />}
                                        </div>
                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="meeting" className="form-label">Meeting<span className="starMandatory">*</span></label>
                                            {getMeetingList && <DropdownWithSearch defaulNname={t("Select Meeting")} name="MeetingID" list={getMeetingList} valueName="meetingId" displayName="title" editdata={editMeeting} getvalue={handleChange} clear={clearDropdown} clearFun={clearValues} />}
                                        </div>
                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="participantName" className="form-label">Participant<span className="starMandatory">*</span></label>
                                            {getParticipantList && <DropdownWithSearch defaulNname={t("Select Participant")} name="ActionItemId" list={getParticipantList} valueName="actionItemId" displayName="participantName" editdata={editParticipant} getvalue={handleChange} clear={clearDropdown} clearFun={clearValues} />}
                                        </div>
                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="actionItemName" className="form-label">Action Item Name<span className="starMandatory">*</span></label>
                                            <input type="text" className="form-control form-control-sm" value={getActionItemName} onChange={handleTextboxChange} id="txtactionItemName" name="actionItemName" placeholder='Enter Action Item Name' />
                                        </div>
                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="dueDate" className="form-label">Due Date<span className="starMandatory">*</span></label>
                                            <input type="date" className="form-control form-control-sm" value={getDueDate} onChange={handleTextboxChange} id="txtdueDate" name="dueDate" placeholder='Enter Name' />
                                        </div>



                                        <div className="mb-2 relative">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                            <div>
                                                {getSaveUpdateBool === 0 ?
                                                    <>
                                                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={funSaveActionItem}><img src={save} className='icnn' />Save</button>
                                                        <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={() => { clearValues(1) }}><img src={reset} className='icnn' /> {t("Clear")}</button>
                                                    </>
                                                    :
                                                    <>
                                                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={funUpdateActionItem}><img src={save} className='icnn' />Update</button>
                                                        <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={() => { setSaveUpdateBool(0); clearValues(1) }}><img src={reset} className='icnn' /> {t("Clear")}</button>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-1">
                            <div className='handlser'>
                                <Heading text="Action Item List" />
                                <div style={{ position: 'relative' }}>
                                    <input value={searchInput} onChange={handleOnChange} name="searchBox" type="search" className="form-control form-control-sm" placeholder="Search...." aria-label="Search" aria-describedby="search-addon" />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
                            <div className="med-table-section relative" style={{ "height": "77vh" }}>
                                <table className="med-table border_ striped">
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>S.No.</th>
                                            <th>Agenda</th>
                                            <th>Meeting</th>
                                            <th>Participant</th>
                                            <th>Action Item Name</th>
                                            <th>Due Date</th>
                                            <th style={{ "width": "10%" }} className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getActionItemList.length > 0 ?
                                            getActionItemList && getActionItemList.filter((val) => `${val.itemTitle} ${val.title}`.toLowerCase().includes(searchInput.toLowerCase())).map((val, ind) => {                                            
                                            return (
                                                <tr key={val.id}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td>{val.itemTitle}</td>
                                                    <td>{val.title}</td>
                                                    <td>{val.name}</td>
                                                    <td>{val.actionItemName}</td>
                                                    <td>{reformatDateString(val.dueDate)}</td>
                                                    <td>
                                                        <div className="action-button">
                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom" title="Edit Row"><span className='btnbg' style={{ background: "#FFEDD2" }} onClick={() => { funeditActionItem(val.id, val.actionItemName, val.dueDate, val.agendaId, val.itemTitle, val.meetingId, val.title, val.participantId, val.name) }}> <img src={editbtn} className='' /></span></div>
                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" title="Delete Row" data-bs-target="#deleteModal"><span onClick={() => { setId(val.id) }} className='btnbg' style={{ background: "#FFEFEF" }}> <img src={delbtn} className='icnn' /></span ></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <img className='haveNodataImg' src={noData} alt="No Data" />
                                        }
                                    </tbody>
                                </table>
                                {/* -----------------------Start Delete Modal Popup-------------------   */}

                                {/*  <!-- Modal -->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">
                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'> Delete?</div>
                                                <div className='popDeleteContent'> Are you sure you want to delete?</div>
                                            </div>
                                            <div className="modal-footer1 text-center">
                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={funDeletedActionItem} data-bs-dismiss="modal">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}
                            </div>
                        </div>
                    </div>
                </div>
                {showToster === 1 ? <SuccessToster message={message} handle={setShowToster} /> : ""}
                {showToster === 2 ? <WarningToaster message={message} handle={setShowToster} /> : ""}
                {showToster === 3 ? <AlertToster message={message} handle={setShowToster} /> : ""}
            </section>
            <Loder val={loder} />
        </>
    )

}
