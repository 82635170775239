import React, { useState } from 'react'
import BPSystolic from '../../../../../assets/images/vitalsicons/BPSystolic.svg'
import bp from '../../../../../assets/images/vitalsicons/bp.svg'
import temprature from '../../../../../assets/images/vitalsicons/temprature.svg'
import lungs from '../../../../../assets/images/vitalsicons/lungs.svg'
import heart from '../../../../../assets/images/vitalsicons/heart.svg'
import pulse from '../../../../../assets/images/vitalsicons/pulse.svg'
import spo from '../../../../../assets/images/vitalsicons/spo.svg'
import height1 from '../../../../../assets/images/vitalsicons/height1.svg'
import weight1 from '../../../../../assets/images/vitalsicons/weight1.svg'
import save from '../../../../../assets/images/icons/save.svg'
import clear from '../../../../../assets/images/icons/clear.svg'
import TextEditor from '../../../../../Component/TextEditor'
import { useTranslation } from 'react-i18next'
import i18n from "i18next";
import OPDVitalsymptomInput from '../../../../API/OPD/OPDVitalsymptomInput/OPDVitalsymptomInput'
import TosterUnderProcess from '../../../../../Component/TosterUnderProcess'
import Toster from '../../../../../Component/Toster'

export default function OPDVitalSymptomsInput() {
    const { t } = useTranslation();
    document.body.dir = i18n.dir();
    let [patientHistory, setPatientHistory] = useState("")
    let [showUnderProcess, setShowUnderProcess] = useState(0)
    let [showToster, setShowToster] = useState(0)
    let [tosterMessage, setTosterMessage] = useState("")
    let [tosterValue, setTosterValue] = useState(0)

    let [sendForm, setSendForm] = useState(
        {
            "uhid": window.sessionStorage.getItem("activePatient") ? JSON.parse(window.sessionStorage.getItem("activePatient")).Uhid : [],
            "userID": window.userId,
            "symptomDetails": "",
            "vitalDetails": "",
            "patientHistory": "",
            "clientId": window.clientId,
            "departmentId": window.sessionStorage.getItem("activePage") ? JSON.parse(window.sessionStorage.getItem("activePage")).DepartmentId : 0
        }
    )
    let [vitalsData, setVitalsData] = useState([

        {
            "vmId": 4,
            "vmValue": "",
            "name": "BP_Sys",
            "img": BPSystolic,
            "unit": "mmHg",
            "shortname": t("BPS"),
            "width": "90px",
            "maxLimit": 500,
            placeholder: "Enter BP Systolic (mm Hg)"

        },
        {
            "vmId": 6,
            "vmValue": "",
            "name": "BP_Dias",
            "img": BPSystolic,
            "unit": "mmHg",
            "shortname": t("BPD"),
            "width": "92px",
            "maxLimit": 500,
            placeholder: "Enter BP Diastolic (mm Hg)"


        },
        {
            "vmId": 56,
            "vmValue": "",
            "name": "spo2",
            "img": spo,
            "unit": "%",
            "shortname": t("SPO2"),
            "width": "80px",
            "maxLimit": 100,
            "placeholder": "Enter SPO2"

        },
        {
            "vmId": 3,
            "vmValue": "",
            "name": "Pulse",
            "img": pulse,
            "unit": "bpm",
            "shortname": t("PR"),
            "width": "80px",
            "maxLimit": 170,
            placeholder: "Enter Pulse (Beats/Minute)"


        },
        {
            "vmId": 7,
            "vmValue": "",
            "name": "respRate",
            "img": lungs,
            "unit": "bpm",
            "shortname": t("RR"),
            "width": "95px",
            "maxLimit": 50,
            "placeholder": "Enter Respiratory Rate/min"


        },
        {
            "vmId": 5,
            "vmValue": "",
            "name": "Temperature",
            "img": temprature,
            "unit": "°F",
            "shortname": t("Temp"),
            "width": "90px",
            "maxLimit": 109,
            "placeholder": "Enter Temperature (C)"


        },
        {
            "vmId": 2,
            "vmValue": "",
            "name": "Weight",
            "img": weight1,
            "unit": "kg",
            "shortname": t("Wt"),
            "width": "70px",
            "maxLimit": 500,
            "placeholder": "Enter Weight (kg)"


        },
        {
            "vmId": 1,
            "vmValue": "",
            "name": "Height",
            "img": height1,
            "unit": "CM",
            "shortname": t("Ht"),
            "width": "70px",
            "maxLimit": 272,
            "placeholder": "Enter Height (cm)"

        }
    ])
    let [symptomData, setSymptomsData] = useState("")

    let handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (name === "patientHistory") {
            setPatientHistory(value)
        }
        else if (name === "symptomText") {
            setSymptomsData(value)
        }
    }

    let handleOnchange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        let temp = [...vitalsData]
        if (value !== "" && parseFloat(value) < 0) {
            // Prevent negative values
            // You can show an error message, set the input to 0, or handle it as you prefer
            // For example, setting it to 0:
            value = "0";
        }
        if (value !== '') {

            vitalsData.map(((val, ind) => {

                if (val.vmId === parseInt(name)) {
                    if (value.toString().length <= 3) {
                        if ((val.maxLimit && parseInt(value) <= val.maxLimit)) {
                            // document.getElementById('vitalId' + parseInt(name)).style.border = "2px solid red";
                            // document.getElementById('vitalLabel' + parseInt(name)).style.color = "red";
                            document.getElementById("err" + val.vmId).style.display = "none";
                            temp[ind].vmValue = parseFloat(value);
                        }
                        else {

                            temp[ind].vmValue = parseFloat(value);
                            // document.getElementById("err"+val.vmId).style.display ="none";
                            document.getElementById("err" + val.vmId).innerHTML = "Enter valid value"
                            document.getElementById("err" + val.vmId).style.display = "block";
                        }
                    }
                }
            }))
        }
        else {
            vitalsData.map(((val, ind) => {
                if (val.vmId === parseInt(name)) {
                    temp[ind].vmValue = ""
                    document.getElementById("err" + val.vmId).style.display = "none";

                }
            }))
        }
        setVitalsData(temp)
    }

    let verifyVitalRange = () => {
        let flag =  0
        vitalsData.map((val, ind) => {
            if (val.vmValue !== "" && val.vmValue !== undefined && val.vmValue !== null) {
                if (val.vmValue <= val.maxLimit) {
                    document.getElementById("err" + val.vmId).innerHTML = "Enter valid value"
                    document.getElementById("err" + val.vmId).style.display = "block";
                    flag = 1
                }
            }

        })
        return flag === 0?true:false
    }


    let saveData = async () => {
        if (symptomData.toString().trim().length !== 0 && patientHistory.toString().trim().length !== 0 && vitalsData.length !== 0) {

            let vitalValue = verifyVitalRange()
            if (vitalValue) {
                // setShowUnderProcess(1)
                sendForm.symptomDetails = symptomData
                sendForm.patientHistory = patientHistory
                sendForm.vitalDetails = JSON.stringify(vitalsData)

                console.log(sendForm)
                let response = await OPDVitalsymptomInput(sendForm)
                console.log("response", response)
                if (response.status === 1) {
                    setShowUnderProcess(0)
                    setShowToster(1)
                    setTosterMessage("Data Saved SuccessFully!")
                    setTosterValue(0)
                    setTimeout(() => {
                        setShowToster(0)
                    }, 2000)
                }
                else {
                    setShowUnderProcess(0)
                    setShowToster(1)
                    setTosterMessage(response.responseValue)
                    setTosterValue(1)
                    setTimeout(() => {
                        setShowToster(0)
                    }, 2000)

                }
            }

        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Please Fill All Fields")
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }
    return (
        <>
            <div className='pt-2 boxcontainer whitebackgroundnopad'>
                <div className='row container-fluid'>
                    <div className='col-4'>
                        <div className='tblheading'>Vital Input</div>
                        {vitalsData && vitalsData.map((val, ind) => {
                            return (<>
                                <div className='d-flex flex-column gap-1 mb-3 pb-3'>
                                    <div className="input-group  ">
                                        <span className="input-group-text" id="basic-addon1"><img src={val.img} alt='bpsys' /></span>
                                        <input type="text" className="form-control" placeholder={val.placeholder} aria-label="Username" aria-describedby="basic-addon1" value={val.vmValue} name={val.vmId} onChange={handleOnchange} />
                                    </div>
                                    <small id={"err" + val.vmId} className="" style={{ display: 'none', color: "red", height: "5px" }}></small>
                                </div>
                            </>)
                        })}
                        {/* <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1"><img src={val.img} alt='bpsys' /></span>
                            <input type="text" className="form-control" placeholder="Enter BP Systolic (mm Hg)" aria-label="Username" aria-describedby="basic-addon1" value={vitalsData.vmValues} name={vitalsData.name} onChange={handleOnchange} />
                        </div> */}
                        {/* <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1"><img src={BPSystolic} alt='bpsys' /></span>
                            <input type="text" className="form-control" placeholder="Enter BP Diastolic (mm Hg)" aria-label="Username" aria-describedby="basic-addon1" value={vitalsData.vmValues} name={vitalsData.name} onChange={handleOnchange} />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1"><img src={heart} alt='bpsys' /></span>
                            <input type="text" className="form-control" placeholder="Enter Heart Rate" aria-label="Username" aria-describedby="basic-addon1" value={vitalsData.vmValues} name={vitalsData.name} onChange={handleOnchange} />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1"><img src={pulse} alt='bpsys' /></span>
                            <input type="text" className="form-control" placeholder="Enter Pulse (Beats/Minute)" aria-label="Username" aria-describedby="basic-addon1" value={vitalsData.vmValues} name={vitalsData.name} onChange={handleOnchange} />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1"><img src={temprature} alt='bpsys' /></span>
                            <input type="text" className="form-control" placeholder="Enter Temperature (C)" aria-label="Username" aria-describedby="basic-addon1" value={vitalsData.vmValues} name={vitalsData.name} onChange={handleOnchange} />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">RBS</span>
                            <input type="text" className="form-control" placeholder="Enter RBS" aria-label="Username" aria-describedby="basic-addon1" value={vitalsData.vmValues} name={vitalsData.name} onChange={handleOnchange} />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1"><img src={lungs} alt='bpsys' /></span>
                            <input type="text" className="form-control" placeholder="Enter Respiratory Rate/min" aria-label="Username" aria-describedby="basic-addon1" value={vitalsData.vmValues} name={vitalsData.name} onChange={handleOnchange} />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1"><img src={spo} alt='bpsys' /></span>
                            <input type="text" className="form-control" placeholder="Enter SPO2" aria-label="Username" aria-describedby="basic-addon1" value={vitalsData.vmValues} name={vitalsData.name} onChange={handleOnchange} />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1"><img src={height1} alt='bpsys' /></span>
                            <input type="text" className="form-control" placeholder="Enter Height (cm)" aria-label="Username" aria-describedby="basic-addon1" value={vitalsData.vmValues} name={vitalsData.name} onChange={handleOnchange} />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1"><img src={weight1} alt='bpsys' /></span>
                            <input type="text" className="form-control" placeholder="Enter Weight (kg)" aria-label="Username" aria-describedby="basic-addon1" value={vitalsData.vmValues} name={vitalsData.name} onChange={handleOnchange} />
                        </div> */}

                    </div>
                    <div className='col-4' >
                        <div className='tblheading'>Sign & Symptom</div>
                        <textarea placeholder='Enter Sign & Symptoms Deatils' name='symptomText' value={symptomData} onChange={handleChange} style={{ width: "100%", height: "58vh" }}>

                        </textarea>
                    </div>
                    <div className='col-4' >
                        <div className='tblheading'>Patient Historty</div>
                        <TextEditor getTextvalue={handleChange} setValue={patientHistory} name="patientHistory" id="patientHistory" />

                    </div>
                </div>
            </div>
            <div className='d-flex flex-row gap-2 justify-content-end'>

                {showUnderProcess === 1 ? <TosterUnderProcess /> : <>
                    {
                        showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} /> :
                            <button type="button" className="btn btn-save btn-save-fill btn-sm mt-2" onClick={saveData}><img src={save} className='icnn' /> Save</button>

                    }
                </>}
                {/* <button type="button" className="btn btn-save btn-save-fill btn-sm " onClick={saveData} ><img src={save} className='icnn' /> {t("Save")}</button> */}
                <button type="button" className="btn btn-save btn-sm btnbluehover btn-sm mt-2" onClick={"handleClear"}><img src={clear} className='icnn' /> {t("Clear")}</button>

            </div>


        </>
    )
}
