import React, { useEffect, useState } from 'react'
import SuccessToster from "../../Component/SuccessToster";
import AlertToster from "../../Component/AlertToster";
import Select from 'react-select';
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import clearIcon from '../../assets/images/icons/clear.svg';
import GetDepartmentList from '../../Registartion/API/GET/GetDepartmentList'
import DropdownWithSearch from '../../Component/DropdownWithSearch'
import GetWardList from '../../Registartion/API/GET/GetWardList'
import GetPatientDetailsforSarvodaya from './API/GetPatientDetailsforSarvodaya';
import GetBloodGroup from './API/GetBloodGroup';
import PostImmunohematogy from './API/PostImmunohematogy';
import ProvisonalDiagnosisMainComponent from './ProvisonalDiagnosisMainComponent';
import MedicineProcedure from './MedicineProcedure';
import InvestigationsRows from './InvestigationsRows';
import AntiDProphylaxis from './AntiDProphylaxis';
import Prophylaxis from './Prophylaxis';
import imgPrint from '../../assets/images/icons/imgPrint.svg'
import GetImmuneohemotology from './API/GetImmuneohemotology';
import PostRBSGraph from './API/PostRBSGraph';
import GetRBSChart from './API/GetRBSChart';
import GetBrandList from '../../Clinical/API/KnowMedsAPI/GetBrandList'
import Search, { FindByQuery } from '../../Code/Serach'
import Heading from "../../Component/Heading";
import PatientDeatils from '../../Component/PatientDeatils';
export default function RBSMonitoringGraph() {

  let bloodData = {
    vitalDate: '',
    vitalTime: '',
    vmValue: ''

  }
  let DrugData = {
    time: '',
    Drug: ''

  }
  const currentDate = new Date();
  const DateFormated = currentDate.toISOString().substr(0, 10);
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  const formattedTime = `${hours}:${minutes}`;
  const formattedTimeVital = `${hours}:${minutes}`;

  let [drugDate, setdrugDate] = useState(DateFormated);
  let [drugTime, setdrugTime] = useState(formattedTimeVital);
  let [bloodGluco, setbloodGluco] = useState("");
  let [rowBloodData, setrowBloodData] = useState([bloodData]);
  let [rowDrugData, setrowDrugData] = useState([DrugData]);
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [showSuccessToster, setShowSuccessToster] = useState(0)
  let [showMessage, setShowMeassage] = useState("");
  let [drugName, setdrugName] = useState(null);
  let [showSearchBoxMedic, setShowSearchBoxMedic] = useState(-1)
  let [drugDetailsJSON, setdrugDetailsJSON] = useState([])
  let [VitalDetailsJSON, setVitalDetailsJSON] = useState([])
  let [departmentList, setDepartmentList] = useState([])
  let [selectedBloodGroup, setselectedBloodGroup] = useState(null)
  const [isClearable,] = useState(true);
  const [isSearchable,] = useState(true)
  const [PatientData, setPatientData] = useState();
  const [parameterQuantity, setparameterQuantity] = useState("");
  let [saveDiagnosis, setSaveDiagnosis] = useState([]);
  let [HistoryPreganancy, setHistoryPreganancy] = useState(0);
  let [bloodtransfusion, setbloodtransfusion] = useState(0);
  const [medicationData, setMedicationData] = useState([])
  const [medications, setmedications] = useState([])
  const [antiDProphylaxis, setantiDProphylaxis] = useState([])
  const [investigationJson, setinvestigationJson] = useState([])
  const [getVitalsDetails, setgetVitalsDetails] = useState([])
  const [TableData, setTableData] = useState([])
  let [getDiagnosis, setgetDiagnosis] = useState([])
  let [medicationListTemp, setMedicationListTemp] = useState([])





  const clientID = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
  let [userID] = useState(JSON.parse(sessionStorage.getItem("LoginData")).userId);



  let formattedDate = '';
  if (PatientData && PatientData.admitDate) {
    const parts = PatientData.admitDate.split('/');
    formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;

  }



  let requiredUHID = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].uhId;


  const GetPatientData = async () => {
    let data = await GetPatientDetailsforSarvodaya(requiredUHID)
    if (data.status === 1) {
      setPatientData(data.responseValue[0])
      console.log("PatientData", data.responseValue)
    }

  }




  const handleMedicationDataUpdate = (temp, data) => {
    setMedicationData(temp, data);
  };












  const handleBloodDataChange = (e) => {
    const { name, value } = e.target
    if (name === "date") {
      setdrugDate(value)
    }
    if (name === "time") {
      setdrugTime(value)
    }
    if (name === "bloodGluco") {
      setbloodGluco(value)
    }
    if (name === "parameterQuantity") {
      setparameterQuantity(value)
    }
  };





  const handleOnSave = async () => {

    if (bloodGluco === '') {
      setShowMeassage("Please Enter Blood Glucose")
      setShowAlertToster(1)
      return
    }
    if (bloodGluco > 1000) {
      setShowMeassage("Please Enter valid Blood Glucose value")
      setShowAlertToster(1)
      return
    }

    if (drugName === null) {
      setShowMeassage("Please Select Drug Name ")
      setShowAlertToster(1)
      return
    }


    let druDetails = {
      drugName: drugName.label,
      drugId: drugName.value,
      dosageForm: drugName.dosageForm,
      dosageStrength: drugName.dosageStrength,
      doseUnit: drugName.doseUnit,
      quantity: parameterQuantity
    }
    let vitalDetails = {
      vitalDate: drugDate,
      vitalTime: drugTime,
      vmValue: bloodGluco
    }

    const obj = {
      uhId: requiredUHID,
      jsonArray: JSON.stringify([druDetails]),
      jsonVital: JSON.stringify([vitalDetails]),
      clientId: clientID,
      userId: userID,
    }
    console.log("savedData", obj)

    let data = await PostRBSGraph(obj)

    if (data.status === 1) {

      setShowSuccessToster(1)
      setSaveDiagnosis(1)
      setShowMeassage("Data Saved Successfully")
      GetRBSDetails()
      handleClear()

      setTimeout(() => {
        setShowSuccessToster(1)

      }, 2000);
    } else {
      setShowAlertToster(1)
      setShowMeassage(data.responseValue)
      setTimeout(() => {
        setShowAlertToster(0)
      }, 2000);
    }

  }


  const handlePrint = (index) => {

    let obj = {
      mainData: TableData,
      PatientData: PatientData
    }
    console.log("PrintData", obj)

    window.sessionStorage.setItem("RBSGraphData", JSON.stringify(obj));
    window.open("/ReceiptRbsMonitoringFormprint/", "noopener,noreferrer");

  }


  const handleClear = () => {
    setbloodGluco('')
    setdrugName(null)
    setparameterQuantity("")


  }

  const handleAddBloodRow = () => {
    const isEmpty = rowBloodData.some((item) => item.date == "");
    const isitemQuantityValid = rowBloodData.some((item) => item.BloodGlucose == '');



    if (isEmpty) {
      setShowAlertToster(1);
      setShowMeassage("Please Enter Date");
      return;
    }
    if (isitemQuantityValid) {
      setShowAlertToster(1);
      setShowMeassage("Please Enter Blood Glucose");
      return;
    }



    else {
      setrowBloodData([...rowBloodData, bloodData,]);
    }

  }


  let removeRow = async (ind) => {
    let temp = [...rowBloodData]

    temp.splice(ind, 1);
    if (temp.length > 0) {
      setrowBloodData(temp);
    }

  };

  const GetRBSDetails = async () => {
    let data = await GetRBSChart(requiredUHID)
    if (data.status === 1) {
      setTableData(data.responseValue)
      console.log("mainData", data.responseValue)
      // let VitalData = data.responseValue[0].vitals
      // let medData = data.responseValue[0].medications
      // let diagnosisData = data.responseValue[0].diagnosis
      // setgetVitalsDetails(VitalData ? JSON.parse(VitalData) : [])
      // setgetDiagnosis(diagnosisData ?JSON.parse(diagnosisData) : [])
      // setmedications(medData ? JSON.parse(medData) : [])
      // console.log("VitalData" , JSON.stringify(VitalData))
    }
  }
  let getData = async () => {
    let response = await GetBrandList()
    if (response.status === 1) {
      console.log("drugName", response.responseValue)
      setMedicationListTemp(response.responseValue.map(val => ({

        value: val.id,
        label: val.brandName,
        dosageForm: val.dosageFormName,
        dosageStrength: val.doseStrength,
        doseUnit: val.doseUnitID
      })));

    }

  }
  const handleSelectChange = (selectedOption, setSelectedFunction) => {
    setSelectedFunction(selectedOption);


  };

  useEffect(() => {
    const updateTime = () => {
      const currentDate = new Date();
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');
      const seconds = String(currentDate.getSeconds()).padStart(2, '0');
      const formattedTime = `${hours}:${minutes}`;
      setdrugTime(formattedTime);
    };
    GetRBSDetails()
    GetPatientData()
    getData()
    updateTime();
    const intervalId = setInterval(updateTime, 1000);
    return () => clearInterval(intervalId);


  }, [])

  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">

          <div className="row">
            <div className="col-12"><div className="med-box mb-1"><div className="title">RBS Monitoring Chart</div></div></div>
            <div className="col-12">
              <PatientDeatils />
              <div className="med-box">

                {/* <div className="inner-content">
                  <div className='fieldsett-in col-md-12'>
                    <div className='fieldsett'>

                      <div className='row'>
                        <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 mb-3">
                          <label htmlFor="Code" className="form-label">Patient Name<span className="starMandatory"></span></label>
                          <input id="pName" type="text" value={PatientData && PatientData.patientName} className="form-control form-control-sm" disabled placeholder='Patient Name' name="PName" />
                        </div>


                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-3" id="paymentModediv">
                          <label htmlFor="Code" className="form-label">Age/Gender<span className="starMandatory"></span></label>
                          <input id="PAgeGender" type="text" className="form-control form-control-sm" disabled placeholder="Age/Gender" value={`${PatientData && PatientData.age}/${PatientData && PatientData.gender}`} />
                        </div>


                        <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 mb-3" id="paymentModediv">
                          <label htmlFor="Code" className="form-label">Gender<span className="starMandatory"></span></label>
                          <select id="pGender" className="form-control form-control-sm" name='gender' disabled value={PatientData && PatientData.genderId}>
                            <option value="0" >Select</option>
                            <option value="1">Male</option>
                            <option value="2">Female</option>
                          </select>
                        </div>


                        <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 mb-3">
                          <label htmlFor="Code" className="form-label">Date of Admission<span className="starMandatory"></span></label>
                          <input id="dob" type="text" className="form-control form-control-sm" disabled value={formattedDate} name="doa" />
                        </div>

                        <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 mb-3">
                          <label htmlFor="Code" className="form-label">Ward<span className="starMandatory"></span></label>
                          <input id="Ward" type="text" disabled value={PatientData && PatientData.wardName} className="form-control form-control-sm" name="Ward" placeholder='Ward' />
                        </div>
                        <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 mb-3">
                          <label htmlFor="Code" className="form-label">Bed No.<span className="starMandatory"></span></label>
                          <input id="bedNo" type="text" className="form-control form-control-sm" disabled value={PatientData && PatientData.bedName} name="bed" placeholder='Bed No.' />
                        </div>

                        <div className="row">


                          <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 mb-3">
                            <label htmlFor="Code" className="form-label">IP No.<span className="starMandatory"></span></label>
                            <input id="ipNo" type="text" disabled value={PatientData && PatientData.ipNo} className="form-control form-control-sm" name="IPNo" placeholder='IP No.' />
                          </div>
                          <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 mb-3">
                            <label htmlFor="Code" className="form-label">MR No.<span className="starMandatory"></span></label>
                            <input id="mrNo" type="text" disabled value={PatientData && PatientData.uhId} className="form-control form-control-sm" name="AdvisedBy" placeholder='MR No.' />
                          </div>

                          <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 mb-3">
                            <label htmlFor="Code" className="form-label">Consultant Doctor<span className="starMandatory"></span></label>
                            <input id="consulteDr" type="text" disabled value={PatientData && PatientData.doctorName} className="form-control form-control-sm" name="CDoctor" placeholder='Enter Consultant Doctor' />
                          </div>





                        </div>

                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>



          </div>

          <div className="col-12 mt-2">
            <div className="med-box">

              <div className="inner-content">
                <div className='fieldsett-in col-md-12'>
                  <div className='fieldsett' style={{ padding: '20px' }}>
                    <span className='fieldse'>Provisional Diagnosis</span>
                    <div className="row">
                      <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 mb-3">
                        <label htmlFor="Code" className="form-label">Date<span className="starMandatory"></span></label>
                        <input id="ddalarmtime" type="date" value={DateFormated} className="form-control form-control-sm" name="Date" />
                      </div>

                      <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 mb-3">
                        <label htmlFor="Code" className="form-label">Time<span className="starMandatory"></span></label>
                        <input id="ddalarmtime" type="time" value={formattedTime} className="form-control form-control-sm" name="Time" />
                      </div>
                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">

                        <ProvisonalDiagnosisMainComponent isProvisional={0} saveDiagnosis={saveDiagnosis} setSaveDiagnosis={setSaveDiagnosis} />
                      </div>



                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>





          <div className="row mt-1">
            <div className="col-12">
              <div className="med-box">
                <div
                  className="med-table-section"
                  style={{ minHeight: "220px", overflow: "auto" }}
                >
                  <table className="med-table border_ striped billingTable">
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Blood Glucose</th>
                        <th> Quantity</th>
                        <th>Drug Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rowBloodData.map((val, ind) => {
                        return (
                          <tr key={ind}>
                            <td className="text-center">{ind + 1}</td>
                            <td>
                              <input type="date" name='date' value={drugDate} onChange={handleBloodDataChange} />
                            </td>
                            <td>
                              <input type="time" name='time' value={drugTime} onChange={handleBloodDataChange} />
                            </td>
                            <td>
                              <input type="number" name="bloodGluco" className="" placeholder="Enter Blood Glucose" value={bloodGluco} onChange={handleBloodDataChange} />
                            </td>
                            <td>
                              <input type="text" name="parameterQuantity" className="" placeholder="Enter Quantity" value={parameterQuantity} onChange={handleBloodDataChange} />
                            </td>
                            <td>
                              <Select value={drugName} options={medicationListTemp} className=" create-select" id="serviceType" placeholder="Select Drug " isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, setdrugName,)} />
                              <small id="errEquipment" className="form-text text-danger" style={{ display: 'none' }}></small>
                            </td>
                          </tr>
                        );
                      })}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>







          </div>





          <div className="col-12 ">
            <div className="med-box mt-2">
              <div className="inner-content">
                <div className="row">

                  <div className="inner-content text-right">
                    <div className="mb-2 mt-2 relative">
                      <div>
                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1_ me-1" onClick={handleOnSave} ><img src={saveButtonIcon} className="icnn" alt='' />Save</button>
                        <button type="button" className="btn btn-clear btn-sm mb-1_ me-1" onClick={handleClear} ><img alt='' />Clear</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="col-12 mt-3">
            <div className='handlser'>
              <Heading text="RBS Monitoring Chart" />
              <div style={{ position: 'relative' }}>
                <div className="action-button">
                  <button type="button" className="btn btn-save bg-white text-black btn-save-fill btn-sm mb-1_ me-1" onClick={handlePrint} ><img src={imgPrint} style={{ width: '15px' }} className='mx-2' alt='' />Print</button>

                </div>

              </div>
            </div>
            <div className="med-table-section" style={{ "height": "80vh" }}>
              <table className="med-table border striped">
                <thead style={{ zIndex: '0' }}>
                  <tr>
                    <th className="text-center" style={{ "width": "5%" }}>#</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Blood Glucose</th>
                    <th>Drug Name</th>
                    <th>Drug Time</th>
                    <th>Staff Name </th>

                  </tr>
                </thead>
                <tbody>
                  {TableData && TableData.map((data, index) => {

                    return (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td>{data.vitalDate}</td>
                        <td>{data.vitalTime}</td>
                        <td>{data.vmValue}</td>
                        <td>{data.drugName}</td>
                        <td>{data.vitalDateTime}</td>
                        <td>{data.staffName}</td>

                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>


        </div>





        {showAlertToster === 1 ? (
          <AlertToster message={showMessage} handle={setShowAlertToster} />
        ) : (
          ""
        )}
        {showSuccessToster === 1 ? (
          <SuccessToster
            message={showMessage}
            handle={setShowSuccessToster}
          />
        ) : (
          ""
        )}
      </section>

    </>
  )
}


