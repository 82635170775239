export default async function GetPatientOutputForDashboard(uhid, duration) {

    let url = window.AppbaseUrl + "/api/output/GetPatientOutputForDashboard?uhId="+uhid+"&outputDuration="+duration
    let head = { 'Content-Type': 'application/json', 'accept': '*/*', };
    let data = {};
    let responsonse = "";
    await fetch(url, {
        method: "GET",
        headers: head,
        // body: JSON.stringify(sendData),
    })
        .then(res => res.json())
        .then(data => { responsonse = data })
        .catch(error => { responsonse = error })


    return responsonse
}
