import React from 'react'
import printuse from '../../../src/assets/css/printuse.css'
// import SarvodayaLogo from '../../../assets/images/icons/sarvodayalogo.png'

export default function ConsentForGastrointestinalEndoscopy() {
    return (
        <>
            <div className="main-table" style={{ padding: '10px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <table className='microtble'>

                    {/* ....header.. */}
                    <tr>
                        <td colSpan={8}>
                            <div className="sarvodya-header">
                                <div className='lft-header mb-3'>
                                    {/* <img alt='' className='sv-log'/>                                   */}
                                </div>
                                <div className='rght-header'>
                                    <div> SARVODAYA HOSPITAL <div className='addrs'>SECTOR-8, FARIDABAD | HELPLINE: 1800 313 1414</div></div>
                                </div>
                            </div>

                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td colSpan={8}>
                            <div className='heading-form mt-3 mb-3'>
                                CONSENT FOR GASTROINTESTINAL ENDOSCOPY
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <table className='tbl-bordr mt-0' style={{ border: '1px solid black' }}>
                                <tr>
                                    <td colSpan={2}><div className='assesmnt mt-3 mb-3'>Date of Consent:  <span style={{ width: '395px', borderBottom: '1px dashed black', display: 'inline-block' }}> 12-03-24</span> </div></td>
                                    <td rowSpan={4} style={{ borderLeft: '1px solid black', textAlign: 'center' }}>Patients Label</td>
                                </tr>
                                <tr>
                                    <td ><div className='assesmnt mt-2 mb-2'>Time of Consent: <span style={{ width: '160px', borderBottom: '1px dashed black', display: 'inline-block' }}> 12:00am</span> </div></td>
                                    <td ><div className='assesmnt mt-2 mb-2'>Ward.: <span style={{ width: '155px', borderBottom: '1px dashed black', display: 'inline-block' }}>emergency</span> </div></td>
                                </tr>
                                <tr>
                                    <td ><div className='assesmnt mt-3 mb-3'>Diagnosis:  <span style={{ width: '190px', borderBottom: '1px dashed black', display: 'inline-block' }}> fever</span> </div></td>
                                    <td ><div className='assesmnt mt-3 mb-3'>Procedure:  <span style={{ width: '140px', borderBottom: '1px dashed black', display: 'inline-block' }}> admit</span> </div></td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={8}>
                            <div className='ptndetais mt-2'>
                                <div> <strong>I, </strong> <span style={{ width: '280px', borderBottom: '1px dashed black', display: 'inline-block' }}>
                                    sabi</span>  </div>
                                <div><label> <input type="checkbox" /> </label>    The Patient or </div>
                                <div>  <label> <input type="checkbox" /> </label> representative of patient have </div>

                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={8}>
                            <div className='parainfo2 mt-1'>
                                <strong>(Please tick the correct option above and below)</strong>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='parainfo2 mt-1'>
                                I/We read the content in details.
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-1'>
                                I/We have been explained the current
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-1'>
                                I/We have been explained the current clinical condition of me/my patient.
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-1'>
                                Been explained this consent form in <span style={{ width: '200px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span>which I fully understand and understood the information  provided about Operation/procedure.

                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-2'>
                                <strong>1.</strong>
                                <span>  You have been advised to undergo an endoscopic procedure called ERCP. The procedure is performed to try and relieve obstruction in the bile or pancreatic ducts either due to gallstones or narrowing of the ducts. It is done under sedation or general anaesthesia. The procedure takes about 30-60 min. You need to get hospitalized a day prior to the procedure and usually discharged the day next to the procedure.
                                </span>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-2'>
                                <div> <strong>INFORMATION REGARDING GASTROINTESTINAL ENDOSCOPY</strong></div>
                                <strong>1.</strong>
                                <span>  Direct visualization of the digestive tract and abdominal cavity with lighted instruments is referred to as gastrointestinal endoscopy. You have been advised by your physician to have this type of examination.
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo'>
                                <strong>2.</strong>
                                <span>  The following information describes the reasons for, and possible risks of, these procedures. At the time of your examination, the inside lining of the digestive tract will be inspected thoroughly. If an abnormality is seen or suspected, a small portion of tissue (biopsy) may be removed for microscopic study, or the lining may be brushed and washed with a solution that can be sent for analysis of abnormal cells (cytology). If during the course of your examination your physician determines that you may be eligible to participate in one of our investigational studies, additional biopsies or sampling may be obtained. Small growths can frequently be completely removed (polypectomy). Occasionally during the examination a narrowed portion (stricture) will be stretched to a more normal size (dilation).
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-2'>
                                <div> <strong>A brief description of each endoscopic procedure follows:</strong></div>
                                <strong>1.</strong>
                                <span>  Upper GI Endoscopy (UGIE). The examination of the esophagus, stomach, and duodenum to look for ulcers, tumors, inflammation, and areas of bleeding. Biopsy, cytology, specimen collection, and dilation of strictures may be necessary.
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo'>
                                <strong>2.</strong>
                                <span> Flexible Sigmoidoscopy or Colonoscopy with possible Polypectomy. Examination of all or a portion of the colon requiring careful preparation with diet, medication, and/or enemas. Polypectomies are done using a wire loop and electric current to remove small growths that protrude into the colon.
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo'>
                                <strong>3.</strong>
                                <span> Percutaneous Endoscopic Gastrostomy (PEG) Placement/Replacement. Placement of a feeding tube through the abdominal wall directly into the stomach, under endoscopic guidance or replacement of the PEG tube without endoscopic guidance.   </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-2'>
                                <div> <strong>RISKS</strong></div>
                                <div>The following are the principal risks of these procedures:</div>
                                <div> <strong>1.</strong> <span>  Injury to the lining of the digestive tract by the instrument that may result in perforation of the wall and leakage into body cavities. If this occurs, surgical operation to close the leak and drain the region is often necessary.
                                </span>
                                </div>

                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo2'>
                                <div> <strong>2.</strong> <span>Bleeding may be a complication of biopsy, polypectomy, or dilation. Management of this complication may consist of careful observation or may require blood transfusions or possibly a surgical operation.
                                </span>
                                </div>

                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo2'>
                                <div> <strong>3.</strong> <span> Medications to obtain conscious sedation may be given in the vein to help relax during the procedure and may cause vein irritation (phlebitis) or pain, allergic reaction, cardiorespiratory depression or possible arrest.
                                </span>
                                </div>

                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo2'>
                                <div> <strong>4.</strong> <span>Sore throat and rectal irritation may occur after these procedures.
                                </span>
                                </div>
                            </div>
                        </td>
                    </tr>



                </table>

            </div>
        </>
    )
}
