import React, { useEffect } from 'react'
import handleSearchs from '../Code/PMDCode'
import { useState } from 'react'
import SettingPopup from '../Components/SettingPopup'
import GetBedMaster from "../Api/GetBedMaster";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import PostAPIBedMaster from '../Api/PostAPIBedMaster';
// import TosterUnderProcess from "../Component/TosterUnderProcess";
// import Toster from "../Component/Toster";
import AdmitPatient from '../Api/AdmitPatient';
import DischargePatient from '../Api/DischargePatient';
import SuccessToster from '../../Component/SuccessToster';
import AlertToster from '../../Component/AlertToster';
import Toster from '../../Component/Toster';
import TosterUnderProcess from '../../Component/TosterUnderProcess';
import clearIcon from '../../assets/images/icons/clear.svg';
import DeleteBedSarvodhya from '../Api/DeleteBedSarvodhya';
import StartSimulate from '../Api/StartSimulate';
import GetAllDashboardHeaderParameter from '../Api/GetAllDashboardHeaderParameter';
import GetAllAssignDashboardHeaderParameter from '../Api/GetAllAssignDashboardHeaderParameter';

export default function PMDTop(props) {
    let [showSetting, setShowSetting] = useState(0)
    let [allWard, setAllWard] = useState([]);
    let [bedName, setBedName] = useState('');
    let [bedCode, setBedCode] = useState('');
    let [bedlist, setBedlist] = useState([]);
    let [updateBool, setUpdateBool] = useState(0)
    let [showUnderProcess, setShowUnderProcess] = useState(0)
    let [showToster, setShowToster] = useState(0)
    let [tosterMessage, setTosterMessage] = useState("")
    let [tosterValue, setTosterValue] = useState(0)
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [dashboardShowHead, setSashboardShowHead] = useState([]);

    let [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    const { t } = useTranslation();
    document.body.dir = i18next.dir();



    // handle Ward Select
    let handleWardSelect = (e) => {
        props.setSelectedWard(e)
        props.handleSearch(e, 2)
    }

    let handlefullscreen = (maximaze) => {
        let elem = document.getElementById("mainDiv");
        if (maximaze != 0) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) {
                /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                /* IE11 */
                elem.msRequestFullscreen();
            }
            if (props.showNextData <= 10) {
                props.setShowNextData(20);
            }
            document.getElementById("scrollinsection").style.height = "95vh";

            props.setFullScreen("maximaze");
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                /* IE11 */
                document.msExitFullscreen();
            }
            document.getElementById("scrollinsection").style.height =
                "calc(100vh - 103px)";

            props.setFullScreen("");
        }
    };


    // HandleChange Add Bed

    let handlebed = (e) => {
        document.getElementById('errBedName').style.display = "none"
        document.getElementById('errBedCode').style.display = "none"
        const name = e.target.name;
        const value = e.target.value;
        if (name === "bedName") {
            setBedName(value);
        }
        if (name === "bedCode") {
            setBedCode(value);
        }
    }

    let getdata = async () => {
        const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
        let response = await GetBedMaster(clientID);
        let responsedashboard = await GetAllAssignDashboardHeaderParameter();
        if (response.status === 1) {
            // setLoder(0)
            setBedlist(response.responseValue)
        }
        if (responsedashboard.status === 1 && responsedashboard.responseValue.length !== 0) {
            setSashboardShowHead(responsedashboard.responseValue)
        }
    }

    let saveBed = async () => {
        if (bedName.trim().length === 0) {
            document.getElementById("errBedName").innerHTML = "Enter Bed Name"
            document.getElementById('errBedName').style.display = "block"
            return
        }
        else if (bedCode.trim().length === 0) {
            document.getElementById('errBedCode').innerHTML = "Enter Reference Number"
            document.getElementById('errBedCode').style.display = "block"
            return
        }
        const obj = {
            "bedName": bedName,
            "userId": userID,
            "clientId": clientID,
            "bedCode": bedCode
        }
        console.log("save data:::>", obj)
        // return;
        const response = await PostAPIBedMaster(obj);
        setShowUnderProcess(1)
        if (response.status == 1) {
            setShowUnderProcess(0);
            setTosterValue(0);
            setShowToster(1);
            setTosterMessage("Save Successfully");
            getdata();
            setTimeout(() => {
                setShowToster(0);
                handleClear(1);
            }, 2000)
        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage(response.responseValue)
            setTosterValue(1)
            setInterval(() => {
                setShowToster(0)
            }, 2000)
        }
    }

    //  clear data 
    let handleClear = (e) => {
        setBedName('');
        setBedCode('');
        document.getElementById("bedName").value = "";
        document.getElementById("bedCode").value = "";
        document.getElementById('errBedName').style.display = "none"
        document.getElementById('errBedCode').style.display = "none"
    }

    let handleAdmit = async (id, bedCode) => {
        console.log("Admit invoked::>")
        const obj = {
            bedId: id,
            bedRefrenceCode: bedCode,
            clientId: clientID,
            userId: userID

        }
        console.log("admit ID", obj)
        // return;
        const response = await AdmitPatient(obj);
        if (response.status === 1) {
            setShowToster(1);
            setTosterMessage("Admitted Successfully");
            getdata();
            setTimeout(() => {
                setShowToster(0);
                handleClear(1);
            }, 2000)
        }
        else {
            setShowToster(1)
            setTosterMessage(response.responseValue)
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }

    // Handle Dischrage
    let handleDischarge = async (ind, uhid) => {
        const obj = {
            uhid: uhid,
            dischargeTypeId: 1,
            consultantID: 0,
            clientId: clientID,
            userId: userID
        }
        console.log("Discharge Data", obj)
        // return;
        const response = await DischargePatient(obj);
        if (response.status === 1) {
            setShowToster(1);
            setTosterMessage("Discharge Successfully");
            getdata();
            setTimeout(() => {
                setShowToster(0);
                handleClear(1);
            }, 2000)
        }
        else {
            setShowToster(1)
            setTosterMessage(response.responseValue)
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }

    let handleDelete = async (val) => {

        console.log(val)
        let resp = await DeleteBedSarvodhya(val);
        if (resp.status === 1) {
            setShowToster(1);
            setTosterMessage("Deleted Successfully");
            getdata();
            setTimeout(() => {
                setShowToster(0);
                handleClear(1);
            }, 2000)
        }
        else {
            setShowToster(1)
            setTosterMessage(resp.responseValue)
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }

    // handle simulator
    let handleSimulator = async () => {
        let resp = await StartSimulate()
    }

    useEffect(() => {
        getdata();
    }, []);

    return (
        <div className="dashboartitleSearch">
            <div className="title-indicators">
                <div className="title">{t("Patient Monitoring Dashboard")}</div>
                <div className="indicators">
                    <div>
                        <span>{t("Normal")}</span>{" "}
                        <i className="bi bi-square-fill normal"></i>
                    </div>
                    <div>
                        <span>{t("Borderline Normal Side")}</span>
                        <i className="bi bi-square-fill borderlineNormal"></i>
                    </div>
                    <div>
                        <span>{t("Border Line")}</span>
                        <i className="bi bi-square-fill borderLine"></i>
                    </div>
                    <div>
                        <span>{t("Borderline Abnormal Side")}</span>
                        <i className="bi bi-square-fill borderlineAbnormal"></i>
                    </div>
                    <div>
                        <span>{t("Abnormal")}</span>{" "}
                        <i className="bi bi-square-fill abnormal"></i>
                    </div>
                </div>

                <div className="fliters">
                    <span className="patientCount">
                        {t("Total Patients In Current Ward")}:{" "}
                        {props.fullPatientDataList && props.fullPatientDataList.length}
                    </span>
                </div>
                <div className="fliters">
                    <select
                        className="form-select form-select-sm "
                        aria-label=".form-select-sm example"
                        onChange={handleWardSelect}
                        id="wardDropdown"
                    >
                        <option value={2}>{t("All")}</option>
                        <option value={1}>{t("Critical Care Ward")}</option>
                        {props.allWard &&
                            props.allWard.map((ward) => {
                                return <option value={ward}>{ward}</option>;
                            })}
                    </select>
                </div>
                <div className="fliters">
                    <input
                        className="form-control form-select-sm inputText"
                        type="text"
                        placeholder={t("Search Here...")}
                        aria-label="default input example"
                        id="searchData"
                        onChange={(e) => {
                            props.handleSearch(e, 1);
                        }}
                    />
                </div>
            {/* </div>
            <div className='title-indicators'> */}
                {
                    dashboardShowHead && dashboardShowHead.map((val, ind) => {
                        if (val.headerParameterId === 1) {
                            return (<>
                                {
                                    <div className="fliters pointer ">
                                        <span
                                            className="patientCount"
                                            data-bs-toggle="modal"
                                            data-bs-target="#addbedModal"
                                            
                                        >
                                            Add Bed <i className="fa-solid fa-bed"></i>
                                        </span>
                                    </div>
                                }
                            </>)
                        }

                        else if (val.headerParameterId === 2) {
                            return (
                                <div className="fliters">
                                    {
                                        props.isGridView === 0 ?
                                            <i className='bi bi-grid' title={"Grid View"} onClick={() => { props.setIsGridView(1); window.sessionStorage.setItem("isGridView", JSON.stringify({isGridView:1})) ; document.body.style.overflow = 'hidden'; }}></i> :
                                            <i className='bi bi-list' title={"List View"} onClick={() => { props.setIsGridView(0); window.sessionStorage.setItem("isGridView", JSON.stringify({isGridView:0})); document.body.style.overflow = ''; }}></i>
                                    }

                                </div>
                            )
                        }
                        else if (val.headerParameterId === 4) {
                            return (
                                <div className="fliters">

                                    <i
                                        className="bi bi-arrow-clockwise"
                                        title={t("Refresh")}
                                        onClick={() => {
                                            window.location.reload(true);
                                        }}
                                    ></i>
                                </div>
                            )
                        }
                        else if (val.headerParameterId === 3) {
                            return (<div className="fliters">
                                {props.fullscreen !== "maximaze" ? (
                                    <i
                                        className="bi bi-arrows-fullscreen"
                                        title={t("Full Screen")}
                                        id="btnFullscreen"
                                        onClick={() => handlefullscreen(1)}
                                    ></i>
                                ) : (
                                    <i
                                        className="bi bi-fullscreen-exit"
                                        title={t("Exit Fullscreen")}
                                        id="btnExitFullscreen"
                                        onClick={() => handlefullscreen(0)}
                                    ></i>
                                )}
                            </div>)
                        }

                        else if (val.headerParameterId === 6) {
                            return (<div className="fliters">

                                <span className='pointer'>
                                    <svg onClick={handleSimulator} xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" width="28" height="32" x="0" y="0" viewBox="0 0 64 64" style={{ enableBackground: 'new 0 0 512 512' }} xmlSpace="preserve" className="">
                                        <title>Simulator</title>
                                        <g>
                                            <path d="M6 50h11v2h-7a5.006 5.006 0 0 0-5 5 1 1 0 0 0 1 1h52a1 1 0 0 0 1-1 5.006 5.006 0 0 0-5-5h-7v-2h11a5.006 5.006 0 0 0 5-5V11a5.006 5.006 0 0 0-5-5H6a5.006 5.006 0 0 0-5 5v34a5.006 5.006 0 0 0 5 5zm13 0h2v2h-2zm4 2v-2h18v2zm33.829 4H7.171A3.006 3.006 0 0 1 10 54h44a3.006 3.006 0 0 1 2.829 2zM45 52h-2v-2h2zM3 11a3 3 0 0 1 3-3h52a3 3 0 0 1 3 3v34a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3z" fill="#ffffff" opacity="1" data-original="#000000" className=""></path>
                                            <path d="M6 46h52a1 1 0 0 0 1-1V11a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1zm1-14h14v12h-2v-1a4.983 4.983 0 0 0-2.52-4.315 3 3 0 1 0-4.96 0A4.983 4.983 0 0 0 9 43v1H7zm32 0v12H23V32zm0-2H7v-8h5a1 1 0 0 0 .929-.628l.709-1.774 1.378 7.581a1 1 0 0 0 .913.819L16 28a1 1 0 0 0 .949-.684l2.726-8.18.339 2.029A1 1 0 0 0 21 22h4.279l1.772 5.316a1 1 0 0 0 .92.684.977.977 0 0 0 .958-.628l2.851-7.129.25 1A1 1 0 0 0 33 22h6zm-26 7a1 1 0 1 1 1 1 1 1 0 0 1-1-1zm1 3a3 3 0 0 1 3 3v1h-6v-1a3 3 0 0 1 3-3zm43 4H41V12h16zM39 12v8h-5.219l-.811-3.243a1 1 0 0 0-1.9-.129l-2.986 7.466-1.136-3.41A1 1 0 0 0 26 20h-4.153l-.861-5.165a1 1 0 0 0-.908-.835.989.989 0 0 0-1.027.681l-2.765 8.3-1.3-7.16a1 1 0 0 0-1.913-.193L11.323 20H7v-8z" fill="#ffffff" opacity="1" data-original="#000000" className=""></path>
                                            <path d="M33 42h4a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1zm1-6h2v4h-2zM25 42h4a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1zm1-6h2v4h-2zM44 24h4a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1zm1-8h2v6h-2zM51 14h3v2h-3zM51 18h4v2h-4zM43 26h10v2H43zM43 30h12v2H43zM49 34a4 4 0 1 0 4 4 4 4 0 0 0-4-4zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2z" fill="#ffffff" opacity="1" data-original="#000000" className=""></path>
                                        </g>
                                    </svg>
                                </span>
                            </div>)
                        }
                        else if (val.headerParameterId === 5) {
                            return (<div className="fliters">
                                <i className="bi bi-gear" onClick={() => setShowSetting(1)}></i>
                            </div>)
                        }
                    })
                }

                {/* <button onClick={()=>props.setIsGridView(0)}>
                    click Me
                </button> */}
            </div>





            {/* modal start */}
            <div className="modal fade" id="addbedModal" tabIndex="-1" aria-labelledby="addbedModalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered_ modal-lg">
                    <div className="modal-content">
                        <span className="closee" data-bs-dismiss="modal" aria-label="Close" title="Close Window"><i className="fa fa-times"></i></span>
                        <div className="p-profile"><div className="p-profile-h addbed">Add Bed</div></div>
                        <div className="row">
                            <div className="col-md-12 pt-2 px-4">
                                <div className="d-flex flex-wrap align-content-end">
                                    <div className="mb-2 me-2">
                                        <label htmlFor="bedName" className="form-label  position-relative" style={{ color: 'black' }}>
                                            Bed Name <span className="starMandatory">*</span>
                                        </label>
                                        <input type="text" className="form-control form-control-sm" id="bedName" placeholder="Enter Bed Name" name="bedName" onChange={handlebed} />
                                        <small id="errBedName" className="form-text text-danger" style={{ display: 'none' }}></small>
                                    </div>
                                    <div className="mb-2 me-2">
                                        <label htmlFor="bedCode" className="form-label  position-relative" style={{ color: 'black' }}>
                                            Bed Reference Code <span className="starMandatory">*</span>
                                        </label>
                                        <input type="text" className="form-control form-control-sm" id="bedCode" placeholder="Enter Bed Code" name="bedCode" onChange={handlebed} />
                                        <small id="errBedCode" className="form-text text-danger" style={{ display: 'none' }}></small>
                                    </div>
                                    <div>
                                        {showUnderProcess === 1 ?
                                            <TosterUnderProcess />
                                            :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />
                                                    :
                                                    <div>
                                                        {updateBool === 0 ?
                                                            <>
                                                                <button type="button" className="btn btn-add btn-save-fill btn-sm me-1" style={{ marginTop: "31px" }} onClick={saveBed}>+ Add</button>
                                                                <button type="button" className="btn btn-clear btn-sm " style={{ marginTop: "31px" }} onClick={() => { handleClear(1) }}><img src={clearIcon} className='icnn' />Clear</button>
                                                            </>
                                                            :
                                                            <>
                                                                {/* <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={'handlerUpdate'}>Update</button> */}
                                                                {/* <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { handleClear(1) }}>Cancel</button> */}
                                                            </>
                                                        }
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>

                                </div>
                            </div>

                            <div className="col-12 mt-2 px-4">
                                <div className="pdtable" style={{ maxHeight: '550px', overflowY: 'auto' }}>
                                    <table className="med-table_ border_ striped_">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Bed Name</th>
                                                <th>Bed Reference Code</th>
                                                <th>Status</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {bedlist && bedlist.map((key, ind) => {
                                                if (key.isOccupied === 1) {
                                                    return (
                                                        <tr key={ind}>
                                                            <td style={{ color: 'black' }}>{ind + 1}</td>
                                                            <td style={{ color: 'black' }}>{key.bedName}</td>
                                                            <td style={{ color: 'black' }}>{key.bedCode}</td>
                                                            <td ><span className="badge" style={{ backgroundColor: "#bff5db", color: "#0daa5b", fontSize: "12px", fontweight: "600" }}>Occupied</span></td>
                                                            <td>
                                                                <>
                                                                    <div className="d-flex gap-2 justify-content-center">
                                                                        {/* <button className="Pending-button" onClick={()=>{handleAdmit(key.bedCode)}}>Admit</button> */}
                                                                        <button className="Pending-button" onClick={() => { handleDischarge(ind, key.uhID) }}> Discharge</button>

                                                                    </div>

                                                                </>


                                                            </td>
                                                        </tr>
                                                    )

                                                }
                                                else {
                                                    return (
                                                        <tr key={ind}>
                                                            <td style={{ color: 'black' }}>{ind + 1}</td>
                                                            <td style={{ color: 'black' }}>{key.bedName}</td>
                                                            <td style={{ color: 'black' }}>{key.bedCode}</td>
                                                            <td><span className="badge" style={{ backgroundColor: "#ffd4d4", color: "#F1302E", fontSize: "12px", fontweight: "600" }}>Not Occupied</span></td>
                                                            <td>
                                                                <div className="d-flex gap-2 justify-content-center">
                                                                    <button className="Done-button" onClick={() => { handleAdmit(key.id, key.bedCode) }}>Admit</button>
                                                                    {/* <button className="Done-button">Discharge</button> */}
                                                                    <i className="fa-solid fa-trash" style={{ color: "red" }} onClick={() => { handleDelete(key) }}></i>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }

                                            })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <--- ADD BED POP-UP END ---> */}

            {/* Toaster */}
            {
                isShowToaster === 1 ?
                    <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
            }

            {
                showAlertToster === 1 ?
                    <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
            }

            {
                showSetting === 1 ? <SettingPopup showSetting={showSetting} close={() => setShowSetting(0)} /> : ""
            }


        </div>
    )
}
