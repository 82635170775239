const GetAllTrackDemand = async(fromDate, toDate,  clientId,demandType) => {
  const url = window.InventoryBaseUrl+'/api/InventoryRaiseDemand/GetAllTrackDemand?ClientID='+clientId+'&FromDate='+fromDate+'&ToDate='+toDate+'&DemandType='+demandType
  // const url = 'https://localhost:7264/api/InventoryRaiseDemand/GetAllTrackDemand?ClientID='+clientId+'&FromDate='+fromDate+'&ToDate='+toDate+'&DemandType='+demandType
  const head ={'content-type' : 'application/json','accept':'*/*'};
  const data = {};
  const response = await fetch(url, {
    method :'GET',
    header : head,
  }).then(res => res.json()).then(data);
  return response;

};
export default GetAllTrackDemand;