import React, { useState, useEffect } from 'react'
import GetSprintBAcklogStatus from '../API/SprintBacklog/GetSprintBacklogStatus'
import GetSprint from '../API/SprintMaster/GetSprint'
import GetUser from '../API/OutsideAPI/GetUser'
import GetStory from '../API/StoryMaster/GetStory'
import SaveSprintBacklog from '../API/SprintBacklog/SaveSprintBacklog'
import GetSprintBacklogNotStart from '../API/SprintBacklog/GetSprintBacklogNotStart'
import GetSprintBacklogProgerssHold from '../API/SprintBacklog/GetSprintBacklogProgerssHold'
import GetSprintBacklogComplete from '../API/SprintBacklog/GetSprintBacklogComplete'
import DeleteSprintBacklog from '../API/SprintBacklog/DeleteSprintBacklog'
import UpdateSprintBacklog from '../API/SprintBacklog/UpdateSprintBacklog'
import UpdateStatusSprintBacklog from '../API/SprintBacklog/UpdateStatusSprintBacklog'
import GetProjectByUser from '../API/ProjectMaster/GetProjectByUser'
import GetModuleByProjectUser from '../API/ModuleMaster/GetModuleByProjectUser'
import GetStoryByModule from '../API/StoryMaster/GetStoryByModule'
import SuccessToster from '../../Component/SuccessToster'
import WarningToaster from '../../Component/WarningToaster'
import AlertToster from '../../Component/AlertToster'
import Loder from '../../Component/Loader'
import GetAssignProject from '../API/AssignProject/GetAssignProject'
import UpdateHoldStartSprintBacklog from '../API/SprintBacklog/UpdateHoldStartSprintBacklog'
import GetSprintByUserProject from '../API/SprintMaster/GetSprintByUserProject'
import editbtn from '../../assets/images/icons/editbtn.svg'
import delbtn from '../../assets/images/icons/delbtn.svg'
import save from "../../assets/images/icons/save.svg";
import reset from "../../assets/images/icons/reset.svg";
import updatestatus from "../../assets/images/icons/updatestatus.svg";
import Heading from '../../Component/Heading'
import TaskIsverifyByTL from '../API/SprintBacklog/TaskIsVerifyByTL'
import chat from '../../assets/images/patientmonitordashboard/chat.png'
import ChatingPopupSpringBoard from './ChatingPopupSpringBoard'
import noData from '../../assets/images/icons/No data-rafiki.svg';
import DropdownWithSearch from '../../Component/DropdownWithSearch'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { set } from 'immutable'


export default function SprintBacklog() {
    const [getProjectAssignList, setProjectAssignList] = useState([]);
    const [getId, setId] = useState('');
    const [getSprintBacklogListNotStart, setSprintBacklogListNotStart] = useState([]);
    const [getSprintBacklogListProgerssHold, setSprintBacklogListProgerssHold] = useState([]);
    const [getSprintBacklogListComplete, setSprintBacklogListComplete] = useState([]);
    const [getStoryList, setStoryList] = useState([]);
    const [getSprintList, setSprintList] = useState([]);
    const [getAssignedToList, setAssignedToList] = useState([]);
    const [getSprintBacklogStatusList, setSprintBacklogStatusList] = useState([]);
    const [getProjectMasterLit, setProjectMasterList] = useState([]);
    const [getModuleByProjectList, setModuleByProjectList] = useState([]);
    const [getSaveUpdateBool, setSaveUpdateBool] = useState(0);
    const [getSelectedOptionProject, setSelectedOptionProject] = useState('');
    const [getSelectedOptionStory, setSelectedOptionStory] = useState('');
    const [getSelectedOptionModule, setSelectedOptionModule] = useState('');
    const [getStoryDLL, setStoryDLL] = useState('')
    const [getSprintDLL, setSprintDLL] = useState('')
    const [getSprintBacklogText, setSprintBacklogText] = useState('')
    const [getSprintBacklogTextNotification, setSprintBacklogTextNotification] = useState('')
    const [getFileUploadedPath, setFileUploadPath] = useState('')
    const [getAssignedToDLL, setAssignedToDLL] = useState('')
    const [getEstimatedHourText, setEstimatedHourText] = useState('')
    const [getSprintStatusIDDLL, setSprintStatusIDDLL] = useState('')
    const [getLoginUserID, setLoginUserID] = useState('')
    const [getIsHoldStart, setIsHoldStart] = useState(0)
    const [searchInput, setSearchInput] = useState('');
    const [getProjectName, setProjectName] = useState('')
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionProjectName, setSelectedOptionProjectName] = useState(null);
    const [getAssignedByID, setAssignedByID] = useState('')
    const [getAssignedByName, setAssignedByName] = useState('')
    const [getAssignedToName, setAssignedToName] = useState('')
    const [getBacklogStatusID, setBacklogStatusID] = useState('')
    const [getTaskHoldRemark, setTaskHoldRemark] = useState('')
    const [getShowTaskHoldRemark, setShowTaskHoldRemark] = useState('')
    const [getIsVerifyByTL, setIsVerifyByTL] = useState('')
    const [getAssignedDate, setAssignedDate] = useState('')
    const [clearDropdown, setClearDropdown] = useState(0)
    const [editProjectName, seteditProjectName] = useState("")
    const [editstory, seteditstory] = useState("")
    const [editUser, seteditUser] = useState("")


    

    const { t } = useTranslation();


    const [loder, setLoder] = useState(1)
    const [showToster, setShowToster] = useState(0)
    const [message, setMessage] = useState("")
    const [isChecked, setIsChecked] = useState(true);
    const [isCheckedVerify, setIsCheckedVerify] = useState(true);

    const [ShowChatPopup, setShowChatPopup] = useState(0)
    const [reciverId, setReciverId] = useState(0)
    const [reciverName, setReciverName] = useState(0)

    const userID = JSON.parse(window.sessionStorage.getItem('LoginData')).userId;
    const modelCloseFun = () => {
        setShowChatPopup(0)
    }

    //Handle Change
    const handleChange = async (e) => {
        let name = e.target.name;
        let value = e.target.allValue;
        if (name === "projectID") {

            setSelectedOptionProject(value.projectId)
            seteditProjectName(value.projectName)
            await funGetStoryAndUser(value.projectId)
        }
        else if (name === "storyID") {

            setSelectedOptionStory(value.storyID)
            seteditstory(value.story)
        }
        else {

            setAssignedToDLL(value.teamMembersId)
            seteditUser(value.name)
        }
    };

    const funGetSprintBacklogNotStart = async () => {
        let getResult = await GetSprintBacklogNotStart(userID);
        setLoder(1);
        if (getResult.status === 1) {
            setLoder(0);
            setSprintBacklogListNotStart(getResult.responseValue)
        }
        else {
            setLoder(0);
            setSprintBacklogListNotStart(getResult.responseValue)
        }
    };

    const funGetSprintBacklogProgerssHold = async () => {
        let getResult = await GetSprintBacklogProgerssHold(userID);
        setLoder(1);
        if (getResult.status === 1) {
            setLoder(0);
            setSprintBacklogListProgerssHold(getResult.responseValue)
        }
        else {
            setLoder(0);
            setSprintBacklogListProgerssHold(getResult.responseValue)
        }
    };

    const funGetSprintBacklogComplete = async () => {
        let getResult = await GetSprintBacklogComplete(userID);
        setLoder(1);
        if (getResult.status === 1) {
            setLoder(0);
            setSprintBacklogListComplete(getResult.responseValue)
        }
        else {
            setLoder(0);
            setSprintBacklogListComplete(getResult.responseValue)
        }
    };

    const funGetStoryAndUser = async (projectID) => {
        let getResult = await GetSprintByUserProject(projectID, window.userId)
        if (getResult.status === 1) {
            setModuleByProjectList(getResult.moduleLists);
            setAssignedToList(getResult.userList)
        }
    };

    const SelectedOptionProjectHandler = async (event) => {
        setSelectedOptionProject(event.target.value);

        const ProjectId = document.getElementById('sldProject').value;
        let getResult = await GetSprintByUserProject(ProjectId, window.userId)
        if (getResult.status === 1) {
            setModuleByProjectList(getResult.moduleLists);
            setAssignedToList(getResult.userList)
        }
        const selectedProjectName = event.target.options[event.target.selectedIndex];
        setSelectedOptionProjectName(selectedProjectName);
    };

    const SelectedOptionModuleHandler = async (event) => {
        setSelectedOptionModule(event.target.val);
        const moduleID = document.getElementById('sldstoryID').value;
        const { status, responseValue } = await GetStoryByModule(moduleID);
        status ? setStoryList(responseValue) : setStoryList([]);
    };

    const funGetAllProjectMaster = async () => {
        const { status, responseValue } = await GetProjectByUser(window.userId)
        status ? setProjectMasterList(responseValue) : setProjectMasterList([]);
    };

    const funGetSprint = async () => {
        const { status, responseValue } = await GetSprint(window.userId);
        status ? setSprintList(responseValue) : setSprintList([]);
    };

    const funGetSprintBacklogStatus = async () => {
        const { status, responseValue } = await GetSprintBAcklogStatus();
        status ? setSprintBacklogStatusList(responseValue) : setSprintBacklogStatusList([]);
    };

    function reformatDateString(s) {
        if (typeof s === 'string' && s.trim() !== '') {
            var b = s.split(/\D/);
            var day = parseInt(b[2], 10).toString();
            var month = parseInt(b[1], 10).toString();
            var year = b[0];
            return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
        } else {
            return ''; // Return an empty string or handle the error as needed
        }
    };


    const SaveSprintBacklogData = async () => {
        const getSprintBacklogText = document.getElementById("txtsprintBacklogText").value;
        const getEstimatedHourText = document.getElementById("txtestimatedHour").value;
        const specialCharsRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        const numbersRegex = /^[0-9]*$/;
        const isPositiveNumber = /^\d+(\.\d+)?$/.test(getEstimatedHourText);
        if (getSelectedOptionProject === '0' || getSelectedOptionProject === undefined || getSelectedOptionProject === null || getSelectedOptionProject === "") {
            setMessage("Project not fill !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSelectedOptionStory === '0' || getSelectedOptionStory === undefined || getSelectedOptionStory === null || getSelectedOptionStory === "") {
            setMessage("Story not fill !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSprintBacklogText === '0' || getSprintBacklogText === undefined || getSprintBacklogText === null || getSprintBacklogText === "") {
            setMessage("Sprint backlog not fill !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSprintBacklogText.trim().length === 0 || getSprintBacklogText.trim().length === '') {
            setMessage("Fields can't blank or space !")
            setShowToster(3)
            setLoder(0)
        }

        else if (getAssignedToDLL === '0' || getAssignedToDLL === undefined || getAssignedToDLL === null || getAssignedToDLL === "") {
            setMessage("Assigned to not fill !")
            setShowToster(3)
            setLoder(0)
        }

        else if (getEstimatedHourText === '0' || getEstimatedHourText === undefined || getEstimatedHourText === null || getEstimatedHourText === "") {
            setMessage("Estimate hour not fill !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getEstimatedHourText.trim().length === 0 || getEstimatedHourText.trim().length === '') {
            setMessage("Fields can't blank or space !")
            setShowToster(3)
            setLoder(0)
        }
        else if (!isPositiveNumber) {
            setMessage("Estimated hour positive number not string or negative number !");
            setShowToster(3)
            setLoder(0)
        }
        else if (numbersRegex.test(getSprintBacklogText)) {
            setMessage("Sprint backlog contains numbers !");
            setShowToster(3);
            setLoder(0);
        }
        else if (specialCharsRegex.test(getEstimatedHourText)) {
            setMessage("Estimated hour contains special characters or negative value !");
            setShowToster(3);
            setLoder(0);
        }
        else {
            var obj = {
                storyID: getSelectedOptionStory,
                sprintBacklogText: getSprintBacklogText,
                fileUploadedPath: '/testfilepath',
                assignedTo: getAssignedToDLL,
                estimatedHour: getEstimatedHourText,
                backlogStatusID: 12,
                userID: JSON.parse(window.sessionStorage.getItem('LoginData')).userId,
                userName: JSON.parse(window.sessionStorage.getItem('LoginData')).name,
                AssignedToname: editUser,
                projectName: editProjectName,
                clientId: JSON.parse(window.sessionStorage.getItem('LoginData')).clientId
            }
            let result = await SaveSprintBacklog(obj);
            setLoder(1)
            if (result.status === 1) {
                setLoder(0);
                setMessage('Data save successfully !')
                setShowToster(1)
                funGetSprintBacklogNotStart();
                setSaveUpdateBool(0);
                clearTextValues(1);

            }
            else {
                setLoder(0);
                setMessage(result.responseValue)
                setShowToster(2)
                funGetSprintBacklogNotStart();
                setSaveUpdateBool(0);
            }
        }
    };

    const funDeleteSprintBacklog = async () => {
        var obj = {
            Id: getId,
        }
        let result = await DeleteSprintBacklog(obj)
        setLoder(1)
        if (result.status === 1) {
            setLoder(0)
            setMessage('Data delete successfully !')
            setShowToster(1)
            funGetSprintBacklogNotStart();
            clearTextValues(1);
        }
        else {
            setLoder(0)
            setMessage(result.responseValue)
            setShowToster(2)
            funGetSprintBacklogNotStart();
        }
    };

    const funUpdateStatusSprintBacklog = async () => {
        const getSprintStatusIDDLL = document.getElementById("sldbacklogStatusID").value;

        if (getSprintStatusIDDLL === '0' || getSprintStatusIDDLL === undefined || getSprintStatusIDDLL === null || getSprintStatusIDDLL === "") {
            setMessage("Status not fill !")
            setShowToster(3)
            setLoder(0)
        }
        else {
            var obj = {
                Id: getId,
                backlogStatusID: getSprintStatusIDDLL,
                sprintBacklogText: getSprintBacklogTextNotification,
                userID: JSON.parse(window.sessionStorage.getItem('LoginData')).userId,
                AssignedByID: getAssignedByID,
                AssignedByName: getAssignedByName,
                AssignedToName: getAssignedToName,
                projectName: getProjectName,
                assignedDate: getAssignedDate,
                TaskHoldRemark: getTaskHoldRemark,

            }
            let result = await UpdateStatusSprintBacklog(obj)
            setLoder(1)
            if (result.status === 1) {
                setLoder(0)
                setMessage('Status update successfully !')
                setShowToster(1)
                funGetSprintBacklogNotStart();
                funGetSprintBacklogProgerssHold();
                funGetSprintBacklogComplete();
                setSprintStatusIDDLL('');
            }
            else {
                setLoder(0)
                setMessage(result.responseValue)
                setShowToster(2)
                funGetSprintBacklogNotStart();
                funGetSprintBacklogProgerssHold();
                funGetSprintBacklogComplete();
            }
        }
    }

    const EditSprintBackLogMaster = async (Id, storyID, sprintBacklogText, assignedTo, estimatedHour, projectId, projectName, story, name) => {
        setId(Id);
        setSprintBacklogText(sprintBacklogText);
        setAssignedToDLL(assignedTo);
        setEstimatedHourText(estimatedHour)
        setSelectedOptionProject(projectId);
        setSelectedOptionStory(storyID);
        setSaveUpdateBool(1);
        seteditProjectName(projectName);
        seteditstory(story);
        seteditUser(name);


        let getResult = await GetSprintByUserProject(projectId, window.userId)
        if (getResult.status === 1) {
            setModuleByProjectList(getResult.moduleLists);
            setAssignedToList(getResult.userList)
        }
    };

    const funUpdateSprintBacklog = async () => {
        const getSprintBacklogText = document.getElementById("txtsprintBacklogText").value;
        const getEstimatedHourText = document.getElementById("txtestimatedHour").value;
        const specialCharsRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        const numbersRegex = /^[0-9]*$/;
        const isPositiveNumber = /^\d+(\.\d+)?$/.test(getEstimatedHourText);
        if (!isPositiveNumber) {
            setMessage("Estimated hour should be a positive number !");
            setShowToster(3)
            setLoder(0)
        }
        else if (getSelectedOptionProject === '0' || getSelectedOptionProject === undefined || getSelectedOptionProject === null || getSelectedOptionProject === "") {
            setMessage("Project not fill !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSelectedOptionStory === '0' || getSelectedOptionStory === undefined || getSelectedOptionStory === null || getSelectedOptionStory === "") {
            setMessage("Story not fill !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSprintBacklogText === '0' || getSprintBacklogText === undefined || getSprintBacklogText === null || getSprintBacklogText === "") {
            setMessage("Sprint backlog not fill !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSprintBacklogText.trim().length === 0 || getSprintBacklogText.trim().length === '') {
            setMessage("Fields can't blank or space !")
            setShowToster(3)
            setLoder(0)
        }

        else if (getAssignedToDLL === '0' || getAssignedToDLL === undefined || getAssignedToDLL === null || getAssignedToDLL === "") {
            setMessage("Assigned to not fill !")
            setShowToster(3)
            setLoder(0)
        }

        else if (getEstimatedHourText === '0' || getEstimatedHourText === undefined || getEstimatedHourText === null || getEstimatedHourText === "") {
            setMessage("Estimate hour not fill !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getEstimatedHourText.trim().length === 0 || getEstimatedHourText.trim().length === '') {
            setMessage("Fields can't blank or space !")
            setShowToster(3)
            setLoder(0)
        }
        else if (numbersRegex.test(getSprintBacklogText)) {
            setMessage("Sprint backlog contains numbers !");
            setShowToster(3);
            setLoder(0);
        }
        else if (specialCharsRegex.test(getEstimatedHourText)) {
            setMessage("Estimated hour contains special characters or negative value !");
            setShowToster(3);
            setLoder(0);
        }
        else {
            var obj = {
                Id: getId,
                storyID: getSelectedOptionStory,
                SprintBacklogtext: getSprintBacklogText,
                fileUploadedPath: '/abctest',
                assignedTo: getAssignedToDLL,
                estimatedHour: getEstimatedHourText,
                backlogStatusID: 12,
                userID: JSON.parse(window.sessionStorage.getItem('LoginData')).userId,
                userName: JSON.parse(window.sessionStorage.getItem('LoginData')).name,
                AssignedToname: editUser,
                projectName: editProjectName,
                clientId: JSON.parse(window.sessionStorage.getItem('LoginData')).clientId
            }
            let result = await UpdateSprintBacklog(obj);
            setLoder(1)
            if (result.status === 1) {
                setLoder(0)
                setMessage('Data update successfully !')
                setShowToster(1)
                funGetSprintBacklogNotStart();
                funGetSprintBacklogProgerssHold();
                funGetSprintBacklogComplete();
                clearTextValues(1);
                setSaveUpdateBool(0);
            }
            else {
                setLoder(0)
                setMessage(result.responseValue)
                setShowToster(2)
                funGetSprintBacklogNotStart();
                funGetSprintBacklogProgerssHold();
                funGetSprintBacklogComplete();
            }
        }
    }

    let handleTextboxChangeSprint = (event) => {
        if (event.target.name === "sprintID") {
            setSprintDLL(event.target.value);
        }
    }
    let handleTextboxChangeSprintBacklogText = (event) => {
        if (event.target.name === "sprintBacklogText") {
            setSprintBacklogText(event.target.value);
        }
    }
    let handleTextboxChangeFileUpload = (event) => {
        if (event.target.name === "fileUploadedPath") {
            setFileUploadPath(event.target.value);
        }
    }
    let handleTextboxChangeAssignedTo = (event) => {
        if (event.target.name === "assignedTo") {
            setAssignedToDLL(event.target.value);
        }
        const selectedOption = event.target.options[event.target.selectedIndex];
        setSelectedOption(selectedOption);
    }
    let handleTextboxChangeEstimatedHour = (event) => {
        if (event.target.name === "estimatedHour") {
            setEstimatedHourText(event.target.value);
        }
    }
    let handleTextboxChangeSprintStatusID = (event) => {
        if (event.target.name === "backlogStatusID") {
            setSprintStatusIDDLL(event.target.value);
        }
    }

    let handleTextboxChangeTaskHoldRemark = (event) => {
        if (event.target.name === "TaskHoldRemark") {
            setTaskHoldRemark(event.target.value);
        }
    }

    const clearTextValues = (value) => {
        setSprintDLL('');
        setSprintBacklogText('');
        setFileUploadPath('');
        setEstimatedHourText('');
        setSprintStatusIDDLL('');
        setSaveUpdateBool(0);
        setClearDropdown(value)
        seteditProjectName("");
        seteditstory("");
        seteditUser("")
        setSelectedOptionProject("");
        setSelectedOptionStory("");
        setAssignedToDLL("");
    };

    const funUpdateHoldStart = async (id, backlogStatusID) => {
        setIsChecked(!isChecked);
        if (backlogStatusID === "13") {
            var obj = {
                id: id,
                backlogStatusID: 1
            }
            let result = await UpdateHoldStartSprintBacklog(obj)
            setLoder(1)
            if (result.status === 1) {
                setLoder(0)
            }
            else {
                setLoder(0)
            }
        }
        if (backlogStatusID === "1") {
            var obj = {
                id: id,
                backlogStatusID: 13
            }
            let result = UpdateHoldStartSprintBacklog(obj)

            setLoder(1)
            if (result.status === 1) {
                setLoder(0)
            }
            else {
                setLoder(0)
            }
        }
    };

    const funTaskVerifyByTL = async (id, backlogStatusID) => {
        setIsCheckedVerify(!isCheckedVerify);
        var obj = {
            id: id,
            backlogStatusID: backlogStatusID,
            IsVerifyByTL: isCheckedVerify
        }
        let result = await TaskIsverifyByTL(obj);
        setLoder(1)
        if (result.status === 1) {
            setLoder(0)
            setMessage('Task Verify successfully !')
            setShowToster(1)
            funGetSprintBacklogNotStart();
            funGetSprintBacklogProgerssHold();
            funGetSprintBacklogComplete();
        }
        else {
            setLoder(0)
            setMessage(result.responseValue)
            setShowToster(2)
            funGetSprintBacklogNotStart();
            funGetSprintBacklogProgerssHold();
            funGetSprintBacklogComplete();
        }
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        if (name === 'searchBox') {
            setSearchInput(value)
        }
    };

    useEffect(() => {
        setLoginUserID(window.userId);
        funGetSprintBacklogNotStart();
        funGetSprintBacklogProgerssHold();
        funGetSprintBacklogComplete();
        funGetSprint();
        funGetSprintBacklogStatus();
        funGetAllProjectMaster();
    }, []);
    return (
        <>
            <section className="main-content pt-3 mt-5">
                <div className="container-fluid">
                    <div className="row">

                        {JSON.parse(window.sessionStorage.getItem('LoginData')).roleId === 6 ? <></> :
                            <div className="col-12">
                                <div className="med-box">
                                    <div className="title">Add Sprint Backlog</div>
                                    <div className="inner-content">

                                        <div className="d-flex gap-2 flex-wrap">
                                            <div className="mb-2 col-md-2">
                                                <label htmlFor="projectName" className="form-label">Project<span className="starMandatory">*</span></label>
                                                <div className='drpWithSearch'>
                                                    {getProjectMasterLit && <DropdownWithSearch defaulNname={t("Select Project")} name="projectID" list={getProjectMasterLit} valueName="projectId" text="projectName" displayName="projectName" editdata={editProjectName} getvalue={handleChange} clear={clearDropdown} clearFun={clearTextValues} />}
                                                </div>
                                            </div>
                                            <div className="mb-2 col-md-3">
                                                <label htmlFor="story" className="form-label">Story<span className="starMandatory">*</span></label>
                                                <div className='drpWithSearch'>
                                                    {getModuleByProjectList && <DropdownWithSearch defaulNname={t("Select Story")} name="storyID" list={getModuleByProjectList} valueName="storyID" displayName="story" editdata={editstory} getvalue={handleChange} clear={clearDropdown} clearFun={clearTextValues} />}
                                                </div>
                                            </div>

                                            <div className="mb-2 col-md-4">
                                                <label htmlFor="SprintBacklogtext" className="form-label">Sprint Backlog Text<span className="starMandatory">*</span></label>
                                                <input type="text" className="form-control form-control-sm" id="txtsprintBacklogText" name="sprintBacklogText" value={getSprintBacklogText} onChange={handleTextboxChangeSprintBacklogText} placeholder='Enter Sprint Backlog' />
                                            </div>
                                            <div className="mb-2  col-md-2">
                                                <label htmlFor="AssignedTo" className="form-label">Assigned To<span className="starMandatory">*</span></label>
                                                <div className='drpWithSearch'>
                                                    {getAssignedToList && <DropdownWithSearch defaulNname={t("Select User")} name="teamMembersID" list={getAssignedToList} valueName="teamMembersId" displayName="name" editdata={editUser} getvalue={handleChange} clear={clearDropdown} clearFun={clearTextValues} />}
                                                </div>
                                            </div>
                                            <div className="mb-2 col-md-1">
                                                <label htmlFor="Estimatehour" className="form-label">Estimate Hour<span className="starMandatory">*</span></label>
                                                <input type="text" className="form-control form-control-sm" id="txtestimatedHour" name="estimatedHour" value={getEstimatedHourText} onChange={handleTextboxChangeEstimatedHour} placeholder='Enter Hour' />
                                            </div>
                                            <div className="mb-2 relative col-md-2">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                                <div>
                                                    {getSaveUpdateBool === 0 ?
                                                        <>
                                                            <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={SaveSprintBacklogData} ><img src={save} className='icnn' />Save</button>
                                                            <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={() => { clearTextValues(1) }} ><img src={reset} className='icnn' /> Clear</button>
                                                        </>
                                                        :
                                                        <>
                                                            <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={funUpdateSprintBacklog}><img src={save} className='icnn' />Update</button>
                                                            <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={() => { clearTextValues(1) }} ><img src={reset} className='icnn' /> Clear</button>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }


                        <div className="col-12 mt-1">
                            <div className='handlser'>
                                <Heading text="Sprint Backlog List" />
                                <div style={{ position: 'relative' }}>
                                    <input value={searchInput} onChange={handleOnChange} name="searchBox" type="search" className="form-control form-control-sm" placeholder="Search...." aria-label="Search" aria-describedby="search-addon" />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                </div>
                            </div>


                            <nav className='navbg'>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-NotStarted" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => { funGetSprintBacklogNotStart() }}>Not Started</button>
                                    <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-WorkInProgress" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => { funGetSprintBacklogProgerssHold() }}>Work In Progress</button>
                                    <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-Completed" type="button" role="tab" aria-controls="nav-contact" aria-selected="false" onClick={() => { funGetSprintBacklogComplete() }}>Completed</button>

                                </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-NotStarted" role="tabpanel" aria-labelledby="nav-NotStarted-tab" tabIndex="0">
                                    <div className="med-table-section relative" style={{ "height": "77vh" }}>
                                        <table className="med-table border_ striped">
                                            <thead>

                                                <tr>
                                                    <th className="text-center" style={{ "width": "5%" }}>S.No.</th>
                                                    <th>project</th>
                                                    <th>Story</th>
                                                    <th>Start Date</th>
                                                    <th>Due Date</th>
                                                    <th>Sprint backlog</th>
                                                    <th>Assigned To</th>
                                                    <th>EST Hour</th>
                                                    <th>Assign Date</th>
                                                    <th style={{ "width": "20%" }} className="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getSprintBacklogListNotStart.length > 0 ?
                                                    getSprintBacklogListNotStart && getSprintBacklogListNotStart.filter((val) => `${val.projectName} ${val.story} ${reformatDateString(val.startDate)} ${val.sprintBacklogText} ${val.name} ${reformatDateString(val.endDate)} ${val.estimatedHour}`.toLowerCase().includes(searchInput.toLowerCase())).map((val, ind) => {

                                                    return (
                                                        <tr key={val.id}>
                                                            <td className="text-center">{ind + 1}</td>
                                                            <td>{val.projectName}</td>
                                                            <td>{val.story}</td>
                                                            <td>{reformatDateString(val.startDate)}</td>
                                                            <td>{reformatDateString(val.endDate)}</td>
                                                            <td>{val.sprintBacklogText}</td>
                                                            <td>{val.name}</td>
                                                            <td>{val.estimatedHour}</td>
                                                            <td>{reformatDateString(val.assignDate)}</td>
                                                            <td>
                                                                <div className="action-button">
                                                                    {JSON.parse(window.sessionStorage.getItem('LoginData')).roleId === 6 ?

                                                                        <img src={chat} alt='' className='pointer imgHover' title='Chat' onClick={() => { setShowChatPopup(1); setReciverId(val.assignByID); setReciverName(val.assignByName); }} style={{ width: "16px" }} /> :
                                                                        <img src={chat} alt='' className='pointer imgHover' title='Chat' onClick={() => { setShowChatPopup(1); setReciverId(val.assignedTo); setReciverName(val.name); }} style={{ width: "16px" }} />
                                                                    }
                                                                    <div data-bs-toggle="modal" data-bs-title="Edit Row" data-bs-placement="bottom" title="Update Status" data-bs-target="#statusUpdateModal" ><img src={updatestatus} alt='' onClick={() => { setId(val.id); setSprintBacklogTextNotification(val.sprintBacklogText); setAssignedByID(val.assignByID); setProjectName(val.projectName); setAssignedByName(val.assignByName); setAssignedToName(val.name); setAssignedDate(reformatDateString(val.assignDate)); }} /></div>
                                                                    {JSON.parse(window.sessionStorage.getItem('LoginData')).roleId === 6 ?
                                                                        <></> : <>
                                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom" title="Edit Row" onClick={() => { EditSprintBackLogMaster(val.id, val.storyID, val.sprintBacklogText, val.assignedTo, val.estimatedHour, val.projectId, val.projectName, val.story, val.name) }} ><span className='btnbg' style={{ background: "#FFEDD2" }}> <img src={editbtn} className='' /></span></div>
                                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" title="Delete Row" data-bs-target="#deleteModal" ><span className='btnbg' style={{ background: "#FFEFEF" }} onClick={() => { setId(val.id) }}><img src={delbtn} className='icnn' /></span></div>
                                                                        </>}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                    // }
                                                })
                                                :
                                                <img className='haveNodataImg' src={noData} alt="No Data" />}
                                            </tbody>
                                          
                                        </table>
                                    </div>
                                </div>



                                <div className="tab-pane fade" id="nav-WorkInProgress" role="tabpanel" aria-labelledby="nav-WorkInProgress-tab" tabIndex="0">
                                    <div className="med-table-section relative" style={{ "height": "77vh" }}>
                                        <table className="med-table border_ striped">
                                            <thead>
                                                <tr>
                                                    <th className="text-center" style={{ "width": "5%" }}>S.No.</th>
                                                    <th>project</th>
                                                    <th>Story</th>
                                                    <th>Start Date</th>
                                                    <th>Due Date</th>
                                                    <th>Sprint backlog</th>
                                                    <th>Assigned To</th>
                                                    <th>EST Hour</th>
                                                    <th>Assign Date</th>
                                                    <th>Hold/Remark</th>
                                                    <th style={{ "width": "15%" }} className="text-center">Action</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getSprintBacklogListProgerssHold.length > 0 ?
                                                    getSprintBacklogListProgerssHold && getSprintBacklogListProgerssHold.filter((val) => `${val.projectName} ${val.story} ${reformatDateString(val.startDate)} ${val.sprintBacklogText} ${val.name} ${reformatDateString(val.endDate)} ${val.estimatedHour}`.toLowerCase().includes(searchInput.toLowerCase())).map((val, ind) => {
                                                    return (
                                                        <tr key={val.id}>
                                                            <td className="text-center">{ind + 1}</td>
                                                            <td>{val.projectName}</td>
                                                            <td>{val.story}</td>
                                                            <td>{reformatDateString(val.startDate)}</td>
                                                            <td>{reformatDateString(val.endDate)}</td>
                                                            <td>{val.sprintBacklogText}</td>
                                                            <td>{val.name}</td>
                                                            <td>{val.estimatedHour}</td>
                                                            <td>{reformatDateString(val.assignDate)}</td>
                                                            <td>
                                                                <div className="form-check form-switch">
                                                                    {val.backlogStatusID === "13" ?
                                                                        <input className="form-check-input" name={ind} type="checkbox" role="switch" defaultChecked={isChecked} disabled onChange={() => { funUpdateHoldStart(val.id, val.backlogStatusID) }} /> :
                                                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" disabled onChange={() => { funUpdateHoldStart(val.id, val.backlogStatusID) }} />
                                                                    }
                                                                    {val.backlogStatusID === "13" ?
                                                                        <div data-bs-toggle="modal" data-bs-title="Edit Row" data-bs-placement="bottom" title="View Task Hold Remark" data-bs-target="#showRemarkModal" onClick={() => { setId(val.id); setShowTaskHoldRemark(val.taskHoldRemark) }} ><span className='btnbg' style={{ background: "#FFEDD2" }}> <i className='fa fa-eye'></i></span></div> : <></>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="action-button">
                                                                    {JSON.parse(window.sessionStorage.getItem('LoginData')).roleId === 6 ?

                                                                        <img src={chat} alt='' className='pointer imgHover' title='Chat' onClick={() => { setShowChatPopup(1); setReciverId(val.assignByID); setReciverName(val.assignByName); }} style={{ width: "16px" }} /> :
                                                                        <img src={chat} alt='' className='pointer imgHover' title='Chat' onClick={() => { setShowChatPopup(1); setReciverId(val.assignedTo); setReciverName(val.name); }} style={{ width: "16px" }} />
                                                                    }
                                                                    <div data-bs-toggle="modal" data-bs-title="Edit Row" data-bs-placement="bottom" title="Update Status" data-bs-target="#statusUpdateModal" ><img src={updatestatus} alt='' onClick={() => { setId(val.id); setSprintBacklogTextNotification(val.sprintBacklogText); setAssignedByID(val.assignByID); setBacklogStatusID(val.backlogStatusID); setProjectName(val.projectName); setAssignedByName(val.assignByName); setAssignedToName(val.name); setAssignedDate(val.assignDate); }} /></div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <img className='haveNodataImg' src={noData} alt="No Data" />}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                                <div className="tab-pane fade" id="nav-Completed" role="tabpanel" aria-labelledby="nav-Completed-tab" tabIndex="0">
                                    <div className="med-table-section relative" style={{ "height": "77vh" }}>
                                        <table className="med-table border_ striped">
                                            <thead>

                                                <tr>
                                                    <th className="text-center" style={{ "width": "5%" }}>S.No.</th>
                                                    <th>project</th>
                                                    <th>Story</th>
                                                    <th>Start Date</th>
                                                    <th>Due Date</th>
                                                    <th>Sprint backlog</th>
                                                    <th>Assigned To</th>
                                                    <th>EST Hour</th>
                                                    <th>Assign Date</th>
                                                    <th>Completed Date</th>
                                                    {JSON.parse(window.sessionStorage.getItem('LoginData')).roleId === 6 ?
                                                        <></> : <> <th>Is Verify</th></>}

                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getSprintBacklogListComplete.length > 0 ?
                                                    getSprintBacklogListComplete && getSprintBacklogListComplete.filter((val) => `${val.projectName} ${val.story} ${reformatDateString(val.startDate)} ${val.sprintBacklogText} ${val.name} ${reformatDateString(val.endDate)} ${val.estimatedHour}`.toLowerCase().includes(searchInput.toLowerCase())).map((val, ind) => {

                                                    return (
                                                        <tr key={val.id}>
                                                            <td className="text-center">{ind + 1}</td>
                                                            <td>{val.projectName}</td>
                                                            <td>{val.story}</td>
                                                            <td>{reformatDateString(val.startDate)}</td>
                                                            <td>{reformatDateString(val.endDate)}</td>
                                                            <td>{val.sprintBacklogText}</td>
                                                            <td>{val.name}</td>
                                                            <td className='text-center'>{val.estimatedHour}</td>
                                                            <td>{reformatDateString(val.assignDate)}</td>
                                                            <td>{reformatDateString(val.taskCompletedDate)}</td>
                                                            {JSON.parse(window.sessionStorage.getItem('LoginData')).roleId === 6 ?
                                                                <></> : <><td>
                                                                    {<div className="form-check form-checkbox">
                                                                        {val.isVerifyByTL === "1" ?
                                                                            <input className="form-check-input" name={ind} type="checkbox" role="switch" disabled defaultChecked={isCheckedVerify} onChange={() => { funTaskVerifyByTL(val.id, val.backlogStatusID) }} /> :
                                                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={() => { funTaskVerifyByTL(val.id, val.backlogStatusID) }} />
                                                                        }
                                                                    </div>
                                                                    }
                                                                </td></>}

                                                            <td>
                                                                <div className="action-button">
                                                                    {JSON.parse(window.sessionStorage.getItem('LoginData')).roleId === 6 ?

                                                                        <img src={chat} alt='' className='pointer imgHover' title='Chat' onClick={() => { setShowChatPopup(1); setReciverId(val.assignByID); setReciverName(val.assignByName); }} style={{ width: "16px" }} /> :
                                                                        <img src={chat} alt='' className='pointer imgHover' title='Chat' onClick={() => { setShowChatPopup(1); setReciverId(val.assignedTo); setReciverName(val.name); }} style={{ width: "16px" }} />
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )

                                                })
                                                :
                                                <img className='haveNodataImg' src={noData} alt="No Data" />}
                                            </tbody>
                                        </table>

                                    </div>


                                </div>
                                {/* -----------------------Start Delete Modal Popup-------------------   */}

                                {/*  <!-- Modal -->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">
                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'> Delete?</div>
                                                <div className='popDeleteContent'> Are you sure you want to delete?</div>
                                            </div>
                                            <div className="modal-footer1 text-center">
                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={funDeleteSprintBacklog} data-bs-dismiss="modal">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

                                {/* -----------------------Start Status Update Modal Popup-------------------   */}

                                {/*  <!-- Modal -->  */}
                                <div className="modal fade" id="statusUpdateModal" tabIndex="-1" aria-labelledby="statusUpdateModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">

                                            <div className="modal-body modelbdy text-center">
                                                <div className="mb-2 me-2">
                                                    <label htmlFor="sldStatus" className="form-label">Sprint Backlog Status</label>
                                                    <select name='backlogStatusID' id="sldbacklogStatusID" value={getSprintStatusIDDLL} onChange={handleTextboxChangeSprintStatusID} className="form-select form-select-sm" aria-label=".form-select-sm example">
                                                        <option value="0">Select Status</option>
                                                        {getSprintBacklogStatusList && getSprintBacklogStatusList.map((val, index) => {
                                                            return (
                                                                <option value={val.id}>{val.statusText}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="mb-2 me-2">
                                                    {getSprintStatusIDDLL === "13" ? (
                                                        <input type="text" className="form-control form-control-sm" id="txtTaskHoldRemark" name="TaskHoldRemark" value={getTaskHoldRemark} onChange={handleTextboxChangeTaskHoldRemark} placeholder='Enter Remark'
                                                        />
                                                    ) : null}

                                                </div>
                                            </div>
                                            <div className="modal-footer1 text-center">
                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal" onClick={() => setSprintStatusIDDLL("")}>Cancel</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={funUpdateStatusSprintBacklog} data-bs-dismiss="modal">Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Status Update Modal Popup--------------------- /} */}

                                {/* -----------------------Start show remark Popup-------------------   */}

                                {/*  <!-- Modal -->  */}
                                <div className="modal fade" id="showRemarkModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">
                                            <div className="modal-body modelbdy text-center">
                                                <div className="mb-2 me-2">
                                                    <label htmlFor="showremark" className="form-label">Remark : {getShowTaskHoldRemark}</label>
                                                </div>
                                            </div>
                                            <div className="modal-footer1 text-center">
                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End show remark Modal Popup--------------------- /} */}
                            </div>


                            {/* tabIndex */}




                        </div>
                    </div>
                </div>
                {showToster === 1 ? <SuccessToster message={message} handle={setShowToster} /> : ""}
                {showToster === 2 ? <WarningToaster message={message} handle={setShowToster} /> : ""}
                {showToster === 3 ? <AlertToster message={message} handle={setShowToster} /> : ""}

                {
                    ShowChatPopup === 1 ? <ChatingPopupSpringBoard ShowChatPopup={ShowChatPopup} modelCloseFun={modelCloseFun} reciverId={reciverId} reciverName={reciverName} /> : ""
                }
            </section>
            <Loder val={loder} />
        </>
    )
}