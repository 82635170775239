import React, { useEffect, useState } from "react";
import imgPrint from "../../assets/images/icons/imgPrint.svg";
import TosterUnderProcess from "../../Component/TosterUnderProcess";
import Toster from "../../Component/Toster";
import saveButtonIcon from "../../assets/images/icons/saveButton.svg";
import clearIcon from "../../assets/images/icons/clear.svg";
import GetInvestigationDetails from "./API/GetInvestigationDetails";
import GetPatientDetailsforSarvodaya from "./API/GetPatientDetailsforSarvodaya";
import Select from "react-select";
import GetDiagnosis from "./API/GetDiagnosis";
import PostClearanceSlip from "./API/ClearanceSlip/PostClearanceSlip";
import GetClearanceSlip from "./API/ClearanceSlip/GetClearanceSlip";
import ProvisonalDiagnosisMainComponent from "./ProvisonalDiagnosisMainComponent";
import PostDiagnosisData from "./API/PostDiagnosisData";
import { t } from "i18next";
import AlertToster from "../../Component/AlertToster";
import SuccessToster from "../../Component/SuccessToster";
import Heading from "../../Component/Heading";
import GetUsers from "./API/GetUsers";
// import DropdownWithSearch from '../../../Component/DropdownWithSearch'
import DropdownWithSearch from '../../Component/DropdownWithSearch'
import InvestigationsRows from "./InvestigationsRows";
import PatientDeatils from "../../Component/PatientDeatils";

export default function ClearanceSlip() {
  const [clearancedata, setClearanceData] = useState("");
  const [showUnderProcess, setShowUnderProcess] = useState(0);
  const [showToster, setShowToster] = useState(0);
  const [tosterMessage, setTosterMessage] = useState("");
  const [tosterValue, setTosterValue] = useState(0);
  let [Investigationlist, setInvestigationlist] = useState([]);
  let [ClearanceslipData, setClearanceslipData] = useState([]);
  let [saveDiagnosis, setSaveDiagnosis] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [PatientData, setPatientData] = useState();
  const [isNewRowAdded, setIsNewRowAdded] = useState(false);
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [showSuccessToster, setShowSuccessToster] = useState(0)
  let [showMessage, setShowMeassage] = useState("");
  const [selectedProvisonalDiagnosis, setselectedProvisonalDiagnosis] =
    useState();
  const [DiagnosisList, setDiagnosisList] = useState([]);
  let [userID, setUserID] = useState(
    JSON.parse(sessionStorage.getItem("LoginData")).userId
  );
  let [updateBool, setUpdateBool] = useState(0);
  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  let [billingCategory, setbillingCategory] = useState('');
  let [sendForm, setSendForm] = useState({ billingCategory: '' })
  let [userlist, setUserList] = useState('')
  let [editDepartment, setEditDepartment] = useState("")
  let [clearDropdown, setClearDropdown] = useState(0)


  // For DateTime
  const currentDate = new Date();
  const DateFormated = currentDate.toISOString().substr(0, 10);
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  const formattedTime = `${hours}:${minutes}:${seconds}`;
  
  let formattedDate = "";
  if (PatientData && PatientData.admitDate) {
    const parts = PatientData.admitDate.split("/");
    formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
  }
  let [InvestigationJSON, setInvestigationJSON] = useState([]);

  const handleInvestigationJSON = (temp, data) => {
    setInvestigationJSON(temp, data);

  };

  let requiredUHID = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0]
    .uhId;
  const clientID = JSON.parse(sessionStorage.getItem("LoginData")).clientId;

  let handlerSelectAll = () => {
    const isSelectedAll = document.getElementById("ddlSelectAllUser").checked;
    let tempArr = [];
    console.log("isSelectedAll", isSelectedAll);
    for (var i = 0; i < Investigationlist.length; i++) {
      let getID = Investigationlist[i].id;
      if (isSelectedAll === true) {
        document.getElementById(getID).checked = true;
        tempArr.push({
          id: getID,
        });
      } else {
        document.getElementById(getID).checked = false;
      }
    }

    console.log("tempArr", tempArr);
  };


  let handleOnChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setSendForm(sendForm => ({
      ...sendForm,
      [name]: value,
      "investigationJson": "",
    }))
  }

  const InvestigationChange = (id) => {
    const index = selectedItems.indexOf(id);
    if (index === -1) {
      setSelectedItems([...selectedItems, id]);
      console.log("value", selectedItems);
    } else {
      const newSelectedItems = [...selectedItems];
      newSelectedItems.splice(index, 1);
      setSelectedItems(newSelectedItems);
    }
  };

  const getProvisionalDiagnosis = async () => {
    let data = await GetDiagnosis(requiredUHID);
    if (data.status === 1) {
      setDiagnosisList(
        data.responseValue.map((val) => ({
          value: val.detailID,
          label: val.details,
        }))
      );
      console.log("data", data.responseValue);
    }
  };

  const handleSelectChange = (
    selectedOption,
    errorElementId,
    setSelectedFunction
  ) => {
    // document.getElementById(errorElementId).style.display = 'none';
    setSelectedFunction(selectedOption);
  };

  let getInvestagtions = async () => {
    let items = await GetInvestigationDetails();
    if (items.status === 1) {
      // setInvestigationJSON(items.responseValue);
      setInvestigationlist(items.responseValue);
      // console.log("items",items.responseValue)
    }
  };

  const GetPatientData = async () => {
    let data = await GetPatientDetailsforSarvodaya(requiredUHID);
    if (data.status === 1) {
      setPatientData(data.responseValue[0]);
      console.log("PatientData", data.responseValue);
    }
  };

  const GetClearanceSlipData = async () => {
    let data = await GetClearanceSlip();
    if (data.status === 1) {
      setClearanceslipData(data.responseValue);
      console.log("setClearanceslipData", data.responseValue);
    }
  };

  let GetUserDate = async () => {
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    let GetUserDate = await GetUsers()
    if (GetUserDate.status === 1) {
      setUserList(GetUserDate.responseValue);
    }
  }

  const handleOnPrint = async (index) => {
    const obj = {
      pateintName: PatientData.patientName,
      age: PatientData.age,
      gender: PatientData.gender,
      bedName: PatientData.bedName,
      ipNo: PatientData.ipNo,
      DOA: PatientData.admitDate,
      date: DateFormated,
      time: formattedTime,
      billingCategory: billingCategory,
      billingCategoryName: ClearanceslipData[index].billingCategory,
      sum: ClearanceslipData[index].sum,
      advisedBy: ClearanceslipData[index].advisedBy,
      createdDate: ClearanceslipData[index].createdDate,
      rowData: ClearanceslipData[index],
    };
    console.log("rowData", obj);
    sessionStorage.setItem('ClearanceSlipPrintData', JSON.stringify(obj));
    window.open("/ReceiptClearSlipprint/", "noopener,noreferrer");
  };


  const handleSave = async () => {
    // sendForm["investigationJson"] = JSON.stringify(InvestigationJSON)
    if (sendForm.billingCategory == '' || sendForm.billingCategory == '0') {
      setShowAlertToster(1);
      setShowMeassage("Billing Category is required")
      return
    }
    if (InvestigationJSON == '') {
      setShowAlertToster(1)
      setShowMeassage('Investigation is Required')
      return
    }

    // let convertedJSON = selectedItems.map((itemID) => ({ itemID }));
    setSaveDiagnosis(1);
    const obj = {
      uhid: requiredUHID,
      billingCategoryId: sendForm.billingCategory,
      investigationsJson: JSON.stringify(InvestigationJSON),
      approvedBy: 0,
      advisedBy: sendForm.advisedBy,
      sum: sendForm.sum,
      userId: userID,
      clientId: clientID,
    };
    console.log("DAATTAA", obj)
 
    let data = await PostClearanceSlip(obj);
    // let postDiag = await PostDiagnosisData(requiredUHID, JSON.stringify(DiagnosticJSON),userID , clientID)
    if (data.status === 1) {
      setShowSuccessToster(1)
      setShowMeassage("Data Saved Successfully")
      handleClear();
      setIsNewRowAdded(true);
      setTimeout(() => {
        setShowSuccessToster(1)
        // setShowMeassage("Data Saved Successfully")
        setIsNewRowAdded(null);
        getProvisionalDiagnosis();
        GetClearanceSlipData();
        // setNewlyAddedRowIndex(null);
      }, 2000);
    } else {
      setShowUnderProcess(0);
      setShowToster(1);
      setTosterValue(1);
      setTosterMessage("Already Exist!");
      setTosterMessage(data.responseValue);
      setTimeout(() => {
        setShowToster(0);
      }, 2000);
    }

    // console.log("formData" , obj)
  };

  const handleClear = (value) => {
    setbillingCategory("");
    setSendForm({ ...sendForm, billingCategory: '' });
    setSelectedItems([]);
    setClearDropdown(value)
    setEditDepartment(0);


  }

  useEffect(() => {
    // Getdata();
    //setInvestigationJSON();
    getInvestagtions();
    GetPatientData();
    getProvisionalDiagnosis();
    GetClearanceSlipData();
    GetUserDate();
  }, []);

  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">

            <div className="col-12">
              <div className="med-box mb-1">
                <div className="title">Clearance Slip</div>
              </div>
            </div>
            <div className="col-12">
              <div className="med-box">
                <div className="inner-content">
                  <PatientDeatils />

                  <div className="fieldsett-in col-md-12">
                    <div className="fieldsett">
                      <span className="fieldse">
                        For Procedure/Investigation
                      </span>
                      <div className="row">
                        {/* <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">
                            Patient Name<span className="starMandatory">*</span>
                          </label>
                          <input
                            id="ddalarmtime"
                            type="text"
                            value={PatientData && PatientData.patientName}
                            className="form-control form-control-sm"
                            disabled
                            placeholder="Patient Name"
                            name="PName"
                          />
                        </div> */}

                        {/* <div className="col-xxl-1 col-xl-3 col-lg-4 col-md-6 mb-3">
                      <label htmlFor="Code" className="form-label">MRD No.<span className="starMandatory">*</span></label>
                      <input id="ddwarningviewtime" type="text" value={sendForm.mrdNo} className="form-control form-control-sm" disabled placeholder='MRD No.' name="MRD" />
                    </div> */}

                        {/* <div
                          className="col-xxl-1 col-xl-3 col-lg-4 col-md-6 mb-3"
                          id="paymentModediv"
                        >
                          <label htmlFor="Code" className="form-label">
                            Age<span className="starMandatory">*</span>
                          </label>
                          <input
                            id="ddwarningviewtime"
                            type="number"
                            value={PatientData && PatientData.age}
                            className="form-control form-control-sm"
                            disabled
                            placeholder="Age"
                            name="Age"
                          />
                        </div> */}
                        {/* <div
                          className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3"
                          id="paymentModediv"
                        >
                          <label htmlFor="Code" className="form-label">
                            Gender<span className="starMandatory">*</span>
                          </label>
                          <select
                            id="Payment"
                            className="form-control form-control-sm"
                            name="gender"
                            disabled
                            value={PatientData && PatientData.genderId}
                          >
                            <option value="0" selected>
                              Male
                            </option>
                            <option value="1">Female</option>
                            <option value="2">Others</option>
                          </select>
                        </div> */}
                        {/* <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">
                            Bed No.<span className="starMandatory">*</span>
                          </label>
                          <input
                            id="ddalarmtime"
                            type="text"
                            disabled
                            value={PatientData && PatientData.bedName}
                            className="form-control form-control-sm"
                            placeholder="Enter Bed No."
                            name="admissionDate"
                          />
                        </div> */}

                        {/* <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">
                            IP No.<span className="starMandatory">*</span>
                          </label>
                          <input
                            id="ddwarningviewtime"
                            type="text"
                            disabled
                            value={PatientData && PatientData.ipNo}
                            className="form-control form-control-sm"
                            placeholder="Enter IP No."
                            name="admissionTime"
                          />
                        </div> */}

                        {/* <div
                          className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3"
                          id="paymentModediv"
                        >
                          <label htmlFor="Code" className="form-label">
                            Date of Admission
                            <span className="starMandatory">*</span>
                          </label>
                          <input
                            id="ddwarningviewtime"
                            type="text"
                            disabled
                            value={formattedDate}
                            className="form-control form-control-sm"
                            placeholder="Enter Diagnosis"
                            name="Diagnosis"
                          />
                        </div> */}

                        <div
                          className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3"
                          id="paymentModediv"
                        >
                          <label htmlFor="Code" className="form-label">
                            Billing Category
                            <span className="starMandatory">*</span>
                          </label>
                          <select
                            id="Payment"
                            className="form-control form-control-sm"
                            onChange={handleOnChange}
                            value={sendForm.billingCategory}
                            name="billingCategory"
                          >
                            <option value="0" selected>
                              Select
                            </option>
                            <option value="1">Cash</option>
                            <option value="2">CGHS</option>
                            <option value="3">Panel</option>
                            <option value="4">TPA</option>

                            {/* <option value={0}>By Online Payment</option> */}
                          </select>
                        </div>

                        <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="departmentId" className="form-label">{t("Advised By")} <span className="starMandatory">*</span></label>
                          {userlist && <DropdownWithSearch defaulNname={t("Select Name")} name="advisedBy" list={userlist} valueName="id" displayName="name" editdata={editDepartment} getvalue={handleOnChange} clear={clearDropdown} clearFun={handleClear} />}
                        </div>

                        {/* <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">
                            Investigation Required
                            <span className="starMandatory"></span>
                          </label>
                          <div className="dropdown">
                            <button
                              className="btn btn-light dropdown-toggle multi-btn"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Select Investigation
                            </button>
                            <ul className="dropdown-menu multistyl multiDrp">
                              {Investigationlist?.length > 0 ? (
                                <li className="d-flex flex-row ps-1 gap-2">
                                  <input
                                    type="checkbox"
                                    id="ddlSelectAllUser"
                                    onChange={handlerSelectAll}
                                  />
                                  <span>Select all</span>
                                </li>
                              ) : (
                                ""
                              )}

                              {Investigationlist &&
                                Investigationlist.map((val, index) => {
                                  return (
                                    <>
                                      <li className="d-flex flex-row ps-1 gap-2">
                                        <input
                                          type="checkbox"
                                          name="investigationForm"
                                          key={index}
                                          id={val.id}
                                          onChange={() =>
                                            InvestigationChange(val.id)
                                          }
                                          checked={selectedItems.includes(
                                            val.id
                                          )}
                                        />
                                        <span htmlFor={val.id}>
                                          {val.itemName}
                                        </span>
                                      </li>
                                    </>
                                  );
                                })}
                            </ul>
                            <small
                              id="errUser"
                              className="form-text text-danger"
                              style={{ display: "none" }}
                            ></small>
                          </div>
                        </div> */}
                        <div className="col-12 col-sm-6 " style={{ height: "10vh" }}>

                          <label htmlFor="ddlitemmaster" className="form-label">
                            Investigation
                            <span className="starMandatory">*</span>
                          </label>
                          <InvestigationsRows investigationsJson={handleInvestigationJSON} clear={clearDropdown}/>
                        </div>
                        <div className="col-6">

                          {/* <Select  value={selectedProvisonalDiagnosis} placeholder="Select Provisonal Diagosis" isMulti options={DiagnosisList} className="create-select create-select-overflow" id="DialysisTypeTable" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, "errdialysistype", setselectedProvisonalDiagnosis)} /> */}
                          {/* <ProvisonalDiagnosisMainComponent  diagnosisData = {handleDiagnosticJSON}/> */}
                          <ProvisonalDiagnosisMainComponent
                            isProvisional={0}
                            saveDiagnosis={saveDiagnosis}
                            setSaveDiagnosis={setSaveDiagnosis}
                          />
                          <small
                            id="errperformdoctor"
                            className="form-text text-danger"
                            style={{ display: "none" }}
                          ></small>
                        </div>
                          

                        {showUnderProcess === 1 ? (
                        <>
                          <TosterUnderProcess />{" "}
                        </>
                      ) : showToster === 1 ? (
                        <Toster value={tosterValue} message={tosterMessage} />
                      ) : (
                        <div className="d-flex flex-row justify-content-end">
                          <button type="button" data-bs-toggle="modal" data-bs-target="#deleteModal" className="btn btn-save btn-save-fill btn-sm mb-1 me-1">
                            <img src={saveButtonIcon} className="icnn" />Save</button>

                          <button type="button" className="btn btn-clear btn-sm mb-1 me-1"
                            onClick={handleClear}
                          ><img src={clearIcon} className='icnn' alt='' />{t("Clear")}</button>
                        </div>
                      )}
                        {/* <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                      <label htmlFor="Code" className="form-label">Diagnosis<span className="starMandatory">*</span></label>
                      <input  id="ddalarmtime" type="text" className="form-control form-control-sm" onChange={handleChange} name="Diagnosis"  placeholder='Enter'/>
                    </div> */}

                        {/* <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                      <label htmlFor="Code" className="form-label">Procedure Charges<span className="starMandatory">*</span></label>
                      <input  id="ddalarmtime" type="text" className="form-control form-control-sm" onChange={handleChange} name="ProcedureCharges"  placeholder='Enter'/>
                    </div>*/}

                        {/* <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                      <label htmlFor="Code" className="form-label">Approved By<span className="starMandatory">*</span></label>
                      <input  id="ddalarmtime" type="text" className="form-control form-control-sm" onChange={handleChange} name="ApprovedBy"  placeholder='Enter'/>
                    </div>  */}


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-12">
            <div className="med-box mt-2">
              <div className="inner-content">
                <div className="row">

                  <div className="inner-content text-right">
                    <div className="mb-2 mt-2 relative">

                      {showUnderProcess === 1 ? (
                        <>
                          <TosterUnderProcess />{" "}
                        </>
                      ) : showToster === 1 ? (
                        <Toster value={tosterValue} message={tosterMessage} />
                      ) : (
                        <div>
                          <button
                            type="button"
                            className="btn btn-save btn-save-fill btn-sm mb-1 me-1"
                            onClick={handleSave}
                          >
                            <img src={saveButtonIcon} className="icnn" />
                            Save
                          </button>

                          <button type="button" className="btn btn-clear btn-sm mb-1 me-1"
                            onClick={handleClear}
                          >

                            <img src={clearIcon} className='icnn' alt='' />{t("Clear")}</button>
                        </div>
                      )}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div> */}

          <div className="col-12 mt-3">
            <Heading text="Clearance Slip List" />
            <div className="med-table-section" style={{ height: "80vh" }}>
              <table className="med-table border striped">
                <thead style={{ zIndex: "0" }}>
                  <tr>
                    <th className="text-center" style={{ width: "5%" }}>
                      #
                    </th>
                    {/* <th>PMID</th> */}
                    <th>Billing Category</th>
                    <th>Investigation</th>
                    <th>Charges</th>
                    <th>Diagnosis</th>
                    <th>AdvisedBy</th>
                    <th>Date</th>
                    <th style={{ width: "2%" }} className="text-center">
                      {"Action"}
                    </th>
                    {/* <th>Dialysis Perform Doctors</th>
                      <th>Referred By</th>
                      <th>Dialysis Booking Date/Time</th> */}

                    {/* <th style={{ "width": "10%" }} className="text-center">Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {ClearanceslipData &&
                    ClearanceslipData.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td>{data.billingCategory}</td>
                          <td>
                            {data.investigationJson &&
                              JSON.parse(data.investigationJson).map(
                                (val, index) => (
                                  <span key={val.TestId}>
                                    {val.TestName}
                                    {index <
                                      (JSON.parse(data.investigationJson) || [])
                                        .length -
                                      1 && ", "}
                                  </span>
                                )
                              )}
                          </td>
                          <td>{data.sum}</td>
                          <td>
                            {data.diagnosisJson &&
                              JSON.parse(data.diagnosisJson).map(
                                (val, index) => (
                                  <span key={val.detailID}>
                                    {val.details}
                                    {index <
                                      (JSON.parse(data.diagnosisJson) || [])
                                        .length -
                                      1 && ", "}
                                  </span>
                                )
                              )}
                          </td>
                          <td>{data.advisedBy}</td>
                          <td>{data.createdDate}</td>

                          <td>
                            <div className="action-button">
                              <div>
                                <span
                                  className={
                                    data.isRead === 1
                                      ? "unreadColor"
                                      : "readColor"
                                  }
                                  title={
                                    data.isRead === 1
                                      ? "Message Read"
                                      : "Message Unread"
                                  }
                                >
                                  <img
                                    src={imgPrint}
                                    alt=""
                                    onClick={() => handleOnPrint(index)}
                                    style={{ width: "19px" }}
                                  />
                                </span>
                              </div>
                            </div>
                          </td>

                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {showAlertToster === 1 ? (
          <AlertToster message={showMessage} handle={setShowAlertToster} />
        ) : (
          ""
        )}
        {showSuccessToster === 1 ? (
          <SuccessToster
            message={showMessage}
            handle={setShowSuccessToster}
          />
        ) : (
          ""
        )}
      </section>

       {/*  <!------------------- Start Delete Modal ---------------------------------->  */}
       <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                        <div className="modal-body modelbdy text-center">
                            <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                            <div className='popDeleteTitle mt-3'> {t("Save?")}</div>
                            <div className='popDeleteContent'>{t("Are_you_sure_you_want_to_save?")}</div>
                        </div>
                        <div className="modal-footer1 text-center">

                            <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                            <button type="button" className="btn-delete popBtnDelete" onClick={handleSave} data-bs-dismiss="modal">{t("Save")}</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

    </>
  );
}
