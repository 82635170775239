import React, { useEffect, useState } from 'react'
import GridCard from './Components/GridCard'
import Patientname, { PatientAdmitTime, PatientAge, PatientBed, PatientCalculator, PatientCamera, PatientChat, PatientDepartment, PatientGender, PatientProfile, PatientUhId, PatientWard } from './DynamicComponent/LeftTableData/PatientDeatils/AllFieldPatientDeatils'
import PatientDiagonsisList from './DynamicComponent/LeftTableData/Diagnosis/AllFieldDiagnosis'
import PatientLifeSupport, { PatientInfusionPump, PatientVentiMode } from './DynamicComponent/LeftTableData/LifeSupport/AllFieldLifeSupport'
import { FoodIntakePopUp, MedicineCheckPopUp, PAtientPACSDeatils, PatientClinicalNotifications, PatientECGGraph, PatientInvestigationDetails, PhysicalActivityPopUp, SupplementPopUp } from './DynamicComponent/LeftTableData/Investigation/AllFieldsInvestigation'
import PatientBPR from './DynamicComponent/RightTableData/BPR/AllFieldBPR'
import PatientSpo2R from './DynamicComponent/RightTableData/SPO2R/AllFieldSPO2R'
import AllfieldRRR from './DynamicComponent/RightTableData/RRR/AllfieldRRR'
import PatientHRR from './DynamicComponent/RightTableData/HRR/AllFieldHRR'
import PatientPRR from './DynamicComponent/RightTableData/PRR/AllFieldPRR'
import RBSR from './DynamicComponent/RightTableData/RBSR/RBSR'
import TempR from './DynamicComponent/RightTableData/TempR/TempR'
import CaplusplusR from './DynamicComponent/RightTableData/CaplusplusR/CaplusplusR'
import KPlusR from './DynamicComponent/RightTableData/KplusR/KPlusR'
import NaPlusR from './DynamicComponent/RightTableData/NaplusR/NaPlusR'
import MgR from './DynamicComponent/RightTableData/MgR/MgR'
import PatientPHR from './DynamicComponent/RightTableData/PHR/AllFieldPHR'
import PCO2R from './DynamicComponent/RightTableData/PCO2R/PCO2R'
import EtCO2R from './DynamicComponent/RightTableData/EtCO2R/EtCO2R'
import PO2R from './DynamicComponent/RightTableData/PO2R/PO2R'
import LactateR from './DynamicComponent/RightTableData/LactateR/LactateR'
import HCO3R from './DynamicComponent/RightTableData/HCO3R/HCO3R'
import CreatinineR from './DynamicComponent/RightTableData/CreatinineR/CreatinineR'
import BUreaR from './DynamicComponent/RightTableData/BUreaR/BUreaR'
import IOR from './DynamicComponent/RightTableData/IOR/IOR'
import SGOTR from './DynamicComponent/RightTableData/SGOTR/SGOTR'
import SGTR from './DynamicComponent/RightTableData/SGPTR/SGTR'
import Consultant from './DynamicComponent/LeftTableData/Consultant/Consultant'
import NSDetails from './DynamicComponent/LeftTableData/NSDetail/NSDetails'
import PatientBPL from './DynamicComponent/LeftTableData/BPL/AllFieldsBPL'
import PatientSPO2L from './DynamicComponent/LeftTableData/SPO2L/AllFieldSpo2L'
import AllFieldsPRL from './DynamicComponent/LeftTableData/PRL/AllFieldsPRL'
import PatientTempL from './DynamicComponent/LeftTableData/TempL/AllFieldTempL'
import AllFieldALBR from './DynamicComponent/RightTableData/ALBR/AllFieldALBR'
import ScifiDashboard from '../ScifiDashboard/ScifiDashboard'

export default function GridPMDIndex(props) {
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);

    let [activeIndex, setActiveIndex] = useState(null)
    // let [colors, setColors] = useState([
    //     {
    //         "columnId": 1,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 2,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 3,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 4,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 5,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 6,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 7,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 8,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 9,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 10,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 11,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 12,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 13,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 14,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 15,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 16,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 17,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 18,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 19,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 20,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 21,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 22,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 23,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 24,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 25,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 26,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 27,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 28,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 29,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 30,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 31,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 32,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 33,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 34,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     },
    //     {
    //         "columnId": 35,
    //         "data": {
    //             "BP": "gray",
    //             "SPO2": "gray",
    //             "RR": "gray",
    //             "HR": "gray",
    //             "PR": "gray",
    //             "RBS": "gray",
    //             "Temp": "gray",
    //             "Ca+": "gray",
    //             "K+": "gray",
    //             "Na+": "gray",
    //             "Mg+": "gray",
    //             "PH": "gray",
    //             "PCO2": "gray",
    //             "EtCO2": "gray",
    //             "PO2": "gray",
    //             "Lactate": "gray",
    //             "HCO3": "gray",
    //             "Creatinine": "gray",
    //             "BUreaR": "gray",
    //             "IO": "gray",
    //             "SGOT": "gray",
    //             "SGT": "gray",
    //         }
    //     }
    // ])

    let [isScifi, setIsSciFi] = useState(false)
    let [tempPatientList, setTempPatientList] = useState(props.fullPatientDataList)
    let [isSelect, setIsSelect] = useState(false)
    const handleColor = (colors = "white", columnId) => {
        setTimeout(() => {
            // console.log("columnss","colors" + columnId + "" + activeIndex )
            document.getElementById("colors" + columnId + "" + activeIndex).style.backgroundColor = convertHexColorToRGBA(colors)
        }, 600)

        // setColors(temp)
    }
    let convertHexColorToRGBA = (colors, alpha = 0.15) => {
        // Create a temporary canvas element if it doesn't exist
        if (colors !== undefined) {
            // let getcolor = colors
            // console.log("colors", getcolor, key)
            let canvas = document.getElementById('temporaryCanvas');
            if (!canvas) {
                canvas = document.createElement('canvas');
                canvas.id = 'temporaryCanvas';
                document.body.appendChild(canvas);
            }

            const ctx = canvas.getContext('2d');

            // Set the fillStyle to the input colors
            ctx.fillStyle = colors;

            // Draw a single pixel and extract the RGBA value
            ctx.fillRect(0, 0, 1, 1);
            const pixel = ctx.getImageData(0, 0, 1, 1).data;

            // Construct the RGBA string with the fixed alpha value
            const rgbaColor = `rgba(${pixel[0]}, ${pixel[1]}, ${pixel[2]}, ${alpha})`;

            return rgbaColor;
        }

    };
    let sortList = (obj) => {
        let arr = obj.sort((a, b) => {
            return b.sortOrderIndex - a.sortOrderIndex;
        });
        return arr;
    }
    let handleSearch = (e) => {
        let serachData = e.target.value;
        if (serachData.length !== 0) {
            let searchResult = []
            searchResult = props.fullPatientDataList?.filter(patientData => {
                const parsedData = JSON.parse(patientData.patientDataList);
                const searchText = serachData.toLowerCase();

                return parsedData.PntName?.toString().toLowerCase().includes(searchText) ||
                    parsedData.UhId?.toString().toLowerCase().includes(searchText);
            }) || [];
            if (searchResult.length !== 0) {
                let arr = sortList(searchResult)
                setTempPatientList(arr)
            }
            else {
                setTempPatientList([])
            }
        }
        else {
            setTempPatientList(props.fullPatientDataList)
        }


    }

    let handleOnClick = (val) => {
        let e = {
            target:
            {
                value: val
            }

        }
        props.handleSearch(e, 1)
        if (val.length === 0) {
            document.getElementById("searchDataGrid").value = ""
            setIsSelect(false)
        }
        else {
            setIsSelect(true)

        }
    }

    useEffect(() => {
        document.body.style.overflowY = "hide"
        setTempPatientList(props.fullPatientDataList)
        var elements = document.querySelectorAll('.overflow-max');

        // Remove the 'height' property
        elements.forEach(function (element) {
            // element.style.padding = '0 !important';
            element.classList.remove('overflow-max');

            // element.classList.add('temp-overflow');
            // element.removeAttribute('class');
        });
    }, [props.fullPatientDataList])

    useEffect(() => {

        setTimeout(() => {
            // Select elements with a specific class
            var elements = document.querySelectorAll('.overflow-max');

            // Remove the 'height' property
            elements.forEach(function (element) {
                // element.style.padding = '0 !important';
                element.classList.remove('overflow-max');

                // element.classList.add('temp-overflow');
                // element.removeAttribute('class');
            });

            const offcanvas = document.getElementById('offcanvasRight');
            const mainContent = document.getElementById('main-content');

            // Add event listener for when the offcanvas is shown
            offcanvas.addEventListener('shown.bs.offcanvas', function () {
                mainContent.classList.add('blur');
            });

            // Add event listener for when the offcanvas is hidden
            offcanvas.addEventListener('hidden.bs.offcanvas', function () {
                mainContent.classList.remove('blur');
            });
        }, 300)

        setIsSciFi(true)

    }, [activeIndex !== null])



    const handleToggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };
 
    return (
        <>
            <div className='d-flex flex-row ' id="main-content" style={{ borderTop: "1.5px solid gray", }} >
            <div className='togglearrow' onClick={handleToggleSidebar}>
                <i className={isSidebarVisible ? 'fas fa-chevron-left' : 'fas fa-chevron-right'}></i>
            </div>
            {isSidebarVisible && (
                <div className='col-2_ d-flex flex-column gap-1 ' style={{ borderRight: "1.5px solid gray", overflowY:"auto", height:"100vh" }}>
                    <div className="dashboartitleSearch">
                        <div className="title-indicators">
                            <div className="title">Patient List</div>
                        </div>
                    </div>
                    <div className="dashboartitleSearch">
                        <div className="fliters">
                            <div className=''>
                                <input
                                    className="form-control form-select-sm inputText"
                                    type="text"
                                    placeholder="Search patient here..."
                                    aria-label="default input example"
                                    id="searchDataGrid"
                                    style={{ borderRadius: "2px", height: "35px", width: "220px" }}
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>
                    </div>

                    <div >
                        <div className={`d-flex flex-row justify-content-center_ pointer ps-3 align-items-center patientList ${isSelect === false ? "patientList-select" : ""}`} onClick={() => handleOnClick("")}>
                            <div className='d-flex flex-column' style={{ fontSize: "14px" }}>
                                <span style={{ color: "white" }}>All Patient List</span>
                                {/* <span style={{ color: "#F0AB25" }}>{json.UhId}</span> */}
                            </div>
                        </div>

                        {
                            tempPatientList && tempPatientList.map((val, ind) => {
                                let json = JSON.parse(val.patientDataList)
                                return (
                                    <div className={`d-flex flex-row justify-content-center_ pointer ps-3 align-items-center patientList ${isSelect === true ? "patientList-select" : ""}`} onClick={() => handleOnClick(json.UhId)}>
                                        <div className='d-flex flex-column' style={{ fontSize: "14px" }}>
                                            <span style={{ color: "white" }}>{json.PntName}</span>
                                            <span style={{ color: "#F0AB25" }}>{json.UhId}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            )}
                <div className='col-10_ overflow-auto' style={{width:'100%'}}>
                    <div className=' gridContainer ps-3 pe-3 mt-2'  >
                        {
                            props.fullPatientDataList && props.fullPatientDataList.map((val, ind) => {
                                return (
                                    <>

                                        <GridCard originalColumn={props.originalColumn} data={val} setActiveIndex={setActiveIndex}  activeIndex={activeIndex} ind={ind} key={val.patientId} />
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className={`${activeIndex !== null ? 'offcanvas show' : "offcanvas"} offcanvas-end sidebar-dashboard`} data-bs-backdrop="static" style={{ height: "90vh", marginTop: "10vh", width:isScifi === true?"87%":"" }} tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-headerss">

                    <div className="text-white ps-2 pe-2" id="offcanvasRightLabel" >

                        {
                            activeIndex !== null ?
                                <div className='d-flex flex-column '>
                                    <div className='d-flex flex-row justify-content-center align-items-center gap-3'>
                                        <Patientname patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                        <div className='d-flex flex-row justify-content-center align-items-center gap-1'>
                                            <PatientAge patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />/
                                            <PatientGender patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                        </div>
                                    </div>
                                    <PatientUhId patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                    <div className='d-flex flex-row gap-1'>
                                        <PatientWard patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                        <PatientBed patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                        <PatientDepartment patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                        ( <PatientAdmitTime patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} /> )
                                    </div>
                                </div> : ""
                        }

                    </div>
                    <span data-bs-dismiss="offcanvas" className='me-2 d-flex flex-row justify-content-center aligns-items-center mt-2' aria-label="Close" onClick={() => { setActiveIndex(null) }} style={{ colors: "white", borderRadius: "20px", width: "28px", height: "28px", border: "2px solid rgba(255, 180, 31, 1)", position: "relative" }}>
                        <i className="bi bi-x " style={{ position: "absolute", marginBottom: "12px", cursor:"pointer", fontSize: "18px", colors: "rgba(255, 180, 31, 1)", fontWeight: "bolder" }}></i>
                    </span>
                </div>
                <hr />

                <div className="offcanvas-body ">
                    {
                        activeIndex !== null && isScifi === false ?
                            <div className='d-flex flex-column gap-1'>
                                <div className='d-flex flex-row gap-1 align-items-center'>
                                    <PatientProfile patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                    <PatientCamera patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                    <PatientCalculator patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                    <PatientChat patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                </div>
                                <hr />
                                <span className='sidebar-title '>
                                    Life Support & Diagnosis
                                </span>
                                <div className='pt-2' style={{ overflow: "auto", maxHeight: "100px" }}>
                                    <PatientDiagonsisList patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                </div>
                                <div className='d-flex flex-row gap-1 align-items-center'>
                                    <PatientLifeSupport patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                    <PatientVentiMode patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                </div>
                                <div className='d-flex flex-row gap-1 align-items-center' >
                                    <PatientInfusionPump patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                    {/* <SupplementPopUp patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} /> */}
                                    {/* <FoodIntakePopUp patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} /> */}
                                    {/* <MedicineCheckPopUp patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} /> */}
                                    {/* <PhysicalActivityPopUp patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} /> */}
                                    {/* <PatientInvestigationDetails patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} /> */}
                                    {/* <PAtientPACSDeatils patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} /> */}
                                    {/* <PatientClinicalNotifications patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} /> */}
                                    {/* <PatientECGGraph patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} /> */}
                                    {/* <PatientInfusionPump patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} /> */}
                                </div>

                                <hr />
                                <span className='sidebar-title '>
                                    Investigations & ADR
                                </span>

                                <div className='pt-2 d-flex flex-row gap-1 align-items-center' >
                                    {/* <PatientInfusionPump patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} /> */}
                                    <SupplementPopUp patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                    <FoodIntakePopUp patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                    <MedicineCheckPopUp patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                    <PhysicalActivityPopUp patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                    <PatientInvestigationDetails patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                    <PAtientPACSDeatils patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                    <PatientClinicalNotifications patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                    <PatientECGGraph patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} />
                                    {/* <PatientInfusionPump patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} /> */}
                                </div>


                                <hr />
                                <span className='sidebar-title '>
                                    Vitals
                                </span>

                                <div className='d-flex flex-row gap-2 justify-content-between align-items-center mt-3' >
                                    <div className='vitals-wrapsidebar' >
                                        <div className='vitals-containersidebar d-flex flex-column  align-items-center justify-content-center' id={"colors" + 12 + "" + activeIndex}>
                                            <span>BP</span>
                                            <PatientBPR val={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={12} handleColor={handleColor} key={activeIndex + "bp"} />
                                        </div>
                                        <div className='vitals-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 13 + "" + activeIndex}>
                                            <span>SPO2</span>
                                            <PatientSpo2R val={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={13} handleColor={handleColor} key={activeIndex + "spo2"} />
                                        </div>
                                        <div className='vitals-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 14 + "" + activeIndex}>
                                            <span>RR</span>
                                            <AllfieldRRR val={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={14} handleColor={handleColor} key={activeIndex + "rr"} />
                                        </div>
                                        <div className='vitals-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 15 + "" + activeIndex}>
                                            <span>HR</span>
                                            <PatientHRR val={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={15} handleColor={handleColor} key={activeIndex + "hr"} />
                                        </div>
                                        <div className='vitals-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 16 + "" + activeIndex}>
                                            <span>PR</span>
                                            <PatientPRR val={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={16} handleColor={handleColor} key={activeIndex + "pr"} />
                                        </div>
                                        <div className='vitals-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 28 + "" + activeIndex}>
                                            <span>RBS</span>
                                            <RBSR patientData={props.fullPatientDataList[activeIndex]} columnId={28} handleColor={handleColor} key={activeIndex + "pr"} />
                                        </div>
                                        <div className='vitals-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 29 + "" + activeIndex}>
                                            <span>Temp.</span>
                                            <TempR patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={29} handleColor={handleColor} key={activeIndex + "pr"} />
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <span className='sidebar-title '>
                                    Investigation
                                </span>

                                <div className='d-flex flex-row gap-2 justify-content-between align-items-center mt-3' >
                                    <div className='investigation-wrapsidebar' >
                                        <div className='investigation-containersidebar d-flex flex-column  align-items-center justify-content-center' id={"colors" + 18 + "" + activeIndex}>
                                            <span>Ca+</span>
                                            <CaplusplusR patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={18} handleColor={handleColor} key={activeIndex + "ca+"} />

                                        </div>
                                        <div className='investigation-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 19 + "" + activeIndex}>
                                            <span>K+</span>
                                            <KPlusR patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={19} handleColor={handleColor} key={activeIndex + "k+"} />
                                        </div>

                                        <div className='investigation-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 20 + "" + activeIndex}>
                                            <span>Na+</span>
                                            <NaPlusR patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={20} handleColor={handleColor} key={activeIndex + "na+"} />
                                        </div>

                                        <div className='investigation-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 21 + "" + activeIndex}>
                                            <span>Mg+</span>
                                            <MgR patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={21} handleColor={handleColor} key={activeIndex + "mg+"} />
                                        </div>
                                        <div className='investigation-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 22 + "" + activeIndex}>
                                            <span>PH</span>
                                            <PatientPHR patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={22} handleColor={handleColor} key={activeIndex + "ph+"} />
                                        </div>
                                        <div className='investigation-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 23 + "" + activeIndex}>
                                            <span>PCO2</span>
                                            <PCO2R patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={23} handleColor={handleColor} key={activeIndex + "pco2"} />
                                        </div>
                                        <div className='investigation-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 24 + "" + activeIndex}>
                                            <span>EtCO2</span>
                                            <EtCO2R patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={24} handleColor={handleColor} key={activeIndex + "etco2"} />
                                        </div>
                                        <div className='investigation-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 25 + "" + activeIndex}>
                                            <span>PO2</span>
                                            <PO2R patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={25} handleColor={handleColor} key={activeIndex + "po2"} />
                                        </div>
                                        <div className='investigation-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 17 + "" + activeIndex}>
                                            <span>ALB</span>
                                            <AllFieldALBR patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={17} handleColor={handleColor} key={activeIndex + "po2"} />
                                        </div>



                                        <div className='investigation-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 26 + "" + activeIndex}>
                                            <span>Lactate</span>
                                            <LactateR patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={26} handleColor={handleColor} key={activeIndex + "Lactate"} />
                                        </div><div className='investigation-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 27 + "" + activeIndex}>
                                            <span>HCO3</span>
                                            <HCO3R patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={27} handleColor={handleColor} key={activeIndex + "hco3"} />
                                        </div>
                                        <div className='investigation-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 30 + "" + activeIndex}>
                                            <span>Creatinine</span>
                                            <CreatinineR patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={30} handleColor={handleColor} key={activeIndex + "creatinine"} />
                                        </div>
                                        <div className='investigation-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 31 + "" + activeIndex}>
                                            <span>BUrea</span>
                                            <BUreaR patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={31} handleColor={handleColor} key={activeIndex + "burea"} />
                                        </div>
                                        <div className='investigation-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 32 + "" + activeIndex}>
                                            <span>IO</span>
                                            <IOR patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={32} handleColor={handleColor} key={activeIndex + "io"} />
                                        </div>
                                        <div className='investigation-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 33 + "" + activeIndex}>
                                            <span>SGOT</span>
                                            <SGOTR patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={33} handleColor={handleColor} key={activeIndex + "sgot"} />
                                        </div>
                                        <div className='investigation-containersidebar d-flex flex-column  align-items-center  justify-content-center' id={"colors" + 34 + "" + activeIndex}>
                                            <span>SGT</span>
                                            <SGTR patientData={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={34} handleColor={handleColor} key={activeIndex + "sgt"} />
                                        </div>

                                    </div>
                                </div>


                                <hr />
                                <span className='sidebar-title '>
                                    Other Details
                                </span>
                                <div className='d-flex flex-row gap-2'>
                                    <div className='d-flex flex-column justify-content-center align-items-center other-data ' >
                                        <span className='sidebar-title ' style={{ fontSize: "13px" }}>
                                            Consultant
                                        </span>
                                        <span className='sidebar-title'>
                                            <Consultant val={props.fullPatientDataList[activeIndex]} columnId={6} />
                                        </span>
                                    </div>
                                    <div className='d-flex flex-column justify-content-center align-items-center other-data '>
                                        <span className='sidebar-title ' style={{ fontSize: "13px" }}>
                                            NS Detail
                                        </span>
                                        <NSDetails val={props.fullPatientDataList[activeIndex]} columnId={6} />
                                    </div>
                                </div>

                                <div className='d-flex flex-row gap-2 justify-content-between align-items-center mt-3' >
                                    <div className='vitals-wrapsidebar' >
                                        <div className='vitals-containersidebar d-flex flex-column  align-items-center justify-content-center'>
                                            <span>BP-R</span>
                                            <PatientBPL val={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={8} />
                                        </div>
                                        <div className='vitals-containersidebar d-flex flex-column  align-items-center  justify-content-center'>
                                            <span>SPO2-R</span>
                                            <PatientSPO2L val={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={9} />
                                        </div>
                                        <div className='vitals-containersidebar d-flex flex-column  align-items-center  justify-content-center'>
                                            <span>PR-R</span>
                                            <AllFieldsPRL val={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={10} />
                                        </div>

                                        <div className='vitals-containersidebar d-flex flex-column  align-items-center  justify-content-center'>
                                            <span>Temp.-R</span>
                                            <PatientTempL val={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList)} columnId={11} />
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            activeIndex !== null && isScifi === true ?
                                < ScifiDashboard uhid={JSON.parse(props.fullPatientDataList[activeIndex].patientDataList).UhId}/> : ""
                    }


                </div>
            </div>
        </>
    )
}
