import React, { useEffect, useState } from 'react'
import LineVitalGrap from '../../../Components/LineVitalGrap';
import TimeCalculate from '../../../Code/TimeCalculate';
import GetAllDashboardParameterColumnAssign from '../../../Api/GetAllDashboardParameterColumnAssign';
import { useSelector } from 'react-redux';

export default function TempR(props) {
  let [temp, setTemp] = useState("");
  let [tempId, setTempId] = useState("");
  let [tempTime, setTempTime] = useState("");
  let [tempColor, setTempColor] = useState("");

  let [uhId, setUhId] = useState("")

  // set grap vital data
  let [grapVitalData, setGrapVitalData] = useState({
    showGrap: 0,
    vitalIdSearchNew: '',
    patientName: '',
    UHID: '',
    userId: ''
  })

  let getData = () => {
    setTemp("");
    setTempId("");
    setTempTime("");
    setTempColor("");

    props.patientData.VitalParametersList && props.patientData.VitalParametersList.map((vital, ind) => {
      if (vital.VitalID.toString() === "5") {
        setTemp(Math.round(vital.VitalValue * 100) / 100)
        setTempColor(vital.VitalColor)
        if (props.handleColor !== undefined) {
          props.handleColor(vital.VitalColor !== null ? vital.VitalColor : "white", props.columnId)
        }

        setTempId(vital.VitalID)
        setUhId(props.patientData.UhId)
        if (vital.VitalDateTime !== '') {
          setTempTime(TimeCalculate(vital.VitalDateTime))

        }

      }
    })
  }

  let modelCloseFun = () => {
    setGrapVitalData({
      showGrap: 0,
      vitalIdSearchNew: '',
      UHID: '',
      userId: ''
    })
  }

  let [paramenterData, setPatameterData] = useState([])
  let response = useSelector((state) => state.AllColumnsParamters)
  let getColumnsParameters = () => {
    let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
    if (resp.length !== 0) {

      setPatameterData(resp[0].data)

    }
  }

  useEffect(() => {
    getColumnsParameters()

    getData()
  }, [props.patientData])

  return (
    <>
      {/* <div className={`overflow-max vitalClass ${(tempColor !== "#008000" && tempColor !== "#94e094" && tempColor !== "#F5F8FF" && tempColor !== "" ? "blink" : "")} `} style={{ border: (tempColor !== "#008000" && tempColor !== "#94e094" && tempColor !== "#F5F8FF" && tempColor !== "" ? "2px solid " + tempColor : "") }}> */}
      <div className={`overflow-max vitalClass`}>
        {temp.length != 0 ? temp ? <span>


          {
            paramenterData && paramenterData.map((val, ind) => {
              if (val.parameterId === 92) {
                return (
                  <>
                    <div style={{ color: tempColor }}>{temp}</div>
                  </>
                )
              }

              else if (val.parameterId === 93) {
                return (
                  <>
                    {
                      tempTime.split("-")[1] === "D" || (tempTime.split("-")[0].split(":")[0] >= 2 && tempTime.split("-")[1] === "H") ? <span style={{ 'color': 'red', fontSize: "0.7rem" }} className="blink"><i className="bi bi-clock"></i> {tempTime.toLowerCase().replace("-", "")}</span>

                        : (tempTime.split(":")[0] >= 1 && tempTime.split("-")[1] === "H") ? <span style={{ "color": 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {tempTime.toLowerCase().replace("-", "")}</span>

                          : (tempTime.split("-")[0].split(":")[0] <= 1 && tempTime.split("-")[1] === "H") || (tempTime.split("-")[0] >= 10 && tempTime.split("-")[1] === "M") ? <span style={{ 'color': '#ffa500', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {tempTime}</span> : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {tempTime.toLowerCase().replace("-", "")}</span>}
                     
                  </>
                )
              }

              else if (val.parameterId === 94) {
                return (
                  <>
                    <span className="" title='Temperature Graph' style={{marginLeft:'3px'}}><i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapVitalData({ showGrap: 1, vitalIdSearchNew: [tempId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "Temperature Graph", patientName: props.patientData.PntName }) }}></i></span>
                  </>

                )
              }


            })

          }



        </span> : 'N/A' : "N/A"}
      </div>
      {grapVitalData.showGrap ? <LineVitalGrap grapVitalData={grapVitalData} modelCloseFun={modelCloseFun} /> : ""}

    </>
  )
}
