import React, { useEffect, useState } from 'react'
import GetAllDoctorOrdersForAssignedUser from '../ICUChartAPi/GetAllDoctorOrdersForAssignedUser';
import DutyCompletionOrders from '../../../../../../assets/images/icons/DutyCompletionOrders.svg';
import rejectedIcon from '../../../../../../assets/images/icons/rejectedIcon.svg';
import doneIcon from '../../../../../../assets/images/icons/doneIcon.svg';
import pending from '../../../../../../assets/images/icons/pending.svg';
export default function DutyCompletion(props) {
  let [getOrderList, setOrderList] = useState();
  const [getClientId, setClientId] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientId);
  const [getUserId, setUserId] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);


  const getStatusTextColor = (row) => {
    if (row.isCompleted) {
      return '#00ba00'; // Green text for completed tasks
    } else if (row.isDNC) {
      return '#fe3f3f'; // Red text for rejected tasks
    } else {
      return '#FFA33C'; // Yellow text for pending tasks
    }
  };
 
  const getStatusText = (row) => {
    if (row.isCompleted) {
      return 'Completed';
    } else if (row.isDNC) {
      return 'Rejected';
    } else {
      return 'Pending';
    }
  };

  let curdate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    console.log('ICU date', formattedDate)
    return formattedDate;
  };

  let getAllDoctorOrdersByAssignedUserID = async () => {
    var fromDate = curdate();
    var toDate = curdate();

    console.log("toDate", toDate);
    let OrderList = await GetAllDoctorOrdersForAssignedUser(getUserId, getClientId, fromDate, toDate);

    if (OrderList.status === 1) {
      setOrderList(OrderList.responseValue);
      console.log('Updated OrderList:', OrderList.responseValue);
    }
  };

  useEffect(() => {

    getAllDoctorOrdersByAssignedUserID();
    curdate();
  }, []);

  return (
    <div>
      <div className='icuChartHeading mb-2'>
        <div className='icuChartHeading-inn'>
          <div className='ICUHead-lft'>
            <span><img src={DutyCompletionOrders} alt='' /></span>
            <span><h3>Duty Completion</h3></span>
          </div>
        </div>
      </div>
      {getOrderList && getOrderList.length > 0 && (
        <div className="med-table-section IcuPhysicalTbl" style={{ "height": "35vh" }}>
          <table className='med-table striped table-responsive'>
            <thead>
              <tr>
                <th>Task</th>
                <th>Action</th>

              </tr>
            </thead>
            <tbody>
              {getOrderList[0].taskDetails.map((task, taskIndex) => (
                <tr key={taskIndex}>
                  <td style={{ border: 'none', whiteSpace: 'nowrap' }}>{task.taskRemark}</td>
                  <td style={{ border: 'none' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {getStatusText(task) === 'Completed' && <img src={doneIcon} alt="Done" style={{ marginRight: '5px' }} />}
                      {getStatusText(task) === 'Rejected' && <img src={rejectedIcon} alt="Rejected" style={{ marginRight: '5px' }} />}
                      {getStatusText(task) === 'Pending' && <img src={pending} alt="Pending" style={{ marginRight: '5px' }} />}
                      <span>{getStatusText(task)}</span>
                    </div>
                    <div>
                      {getStatusText(task) === 'Completed' && task.inchargeComment}
                      {getStatusText(task) === 'Rejected' && task.inchargeComment}
                      {getStatusText(task) === 'Pending' && task.staffComment}
                      
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
