function ValidationHRCategoryMaster(categoryName = "") {
    if (categoryName) {
        return [true, ""];
    }

    else if (!categoryName) {
        return [false, "Category Name field can't be blank"];
    }
}

export default ValidationHRCategoryMaster;