import React, { useEffect, useState } from "react";
import Loader from "../../Component/Loader";
import SuccessToster from "../../Component/SuccessToster";
import AlertToster from "../../Component/AlertToster";
import TableContainer from "../../Component/TableContainer";
import Heading from "../../Component/Heading";
import GetTicketListForDevelopers from "../Api/GetTicketListForDevelopers";
import GetStatusList from "../Api/GetStatusList";
import { Link } from "react-router-dom";
import CloseTicket from "../Api/CloseTicket";
import GetDeveloperList from "../Api/GetDeveloperList";
import TransferTicket from "../Api/TransferTicket";
import TosterUnderProcess from "../../Component/TosterUnderProcess";
import Toster from "../../Component/Toster";

export function Developer(){
   let [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
  //let [userID, setUserID] = useState(123);//12
  let [supportTicketClientID, setsupportTicketClientID] = useState(37);
  let [showToster, setShowToster] = useState(0);
  let [showLoder, setShowLoder] = useState(0);
  let [isShowToaster, setisShowToaster] = useState(0);
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [showErrMessage, setShowErrMessage] = useState('');
  let [showSuccessMsg, setShowSuccessMsg] = useState('');
  let [devTicketList, setDevTicketList] = useState([]);
  let [ticketStatusList,setTicketStatusList]=useState([]);
  let [ticketID,setTicketID]=useState(0);
  let [ticketNumber,setTicketNumber]=useState("");
  let [transferTicketRemark,setTransferTicketRemark]=useState("");
  let [developerList,setDeveloperList]=useState([]);
  let [showPopUp,setShowPopUp]=useState(0);
  let [showUnderProcess, setShowUnderProcess] = useState(0);
  let [showUpdateToster, setShowUpdateToster] = useState(0);
  let [tosterMessage, setTosterMessage] = useState("");
  let [tosterValue, setTosterValue] = useState(0);
//   JS Code  Start Here
const handleChange =(e)=>{
  const value =e.target.value;
  const name =e.target.name;
  if(name === "transferTicketRemark"){
    setTransferTicketRemark(value)
  }
}
  const getTicketListForDeveloper = async()=>{
    const getValue= document.getElementById("ddlStatusFilter").value;
    setShowLoder(1);
    var obj = {
     ClientId:supportTicketClientID,
      StatusId: 1, // parseInt(getValue) === 0 || parseInt(getValue) === und ? 1 : parseInt(getValue),
      //UserId: 323
       UserId: userID
    }
    let response = await GetTicketListForDevelopers(obj);
    if (response.status === 1) {
        // setDevTicketList([])
        setDevTicketList(response.responseValue)
      setShowLoder(0);
    }
    else {
      setShowLoder(0);
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
    }
  }
  const getStatusList= async ()=>{
    const response = await GetStatusList();
    if(response.status === 1){
      setTicketStatusList(response.responseValue)
    }
  }
  const getTicketListByFilter = async()=>{
    setShowLoder(1);
    setDevTicketList([])

    const getValue= document.getElementById("ddlStatusFilter").value;
    let obj={
      ClientId:supportTicketClientID,
      StatusId:parseInt(getValue),
      UserId:userID
    }
    
    let response = await GetTicketListForDevelopers(obj)
    if (response.status === 1) {
      // setDevTicketList([])
      setDevTicketList(response.responseValue)
      setShowLoder(0);
    }
    else {
      setShowLoder(0);
      setShowAlertToster(1);
      setShowErrMessage(response.message);
    }
  }
  const handleCloseTicket = async()=>{
    setShowLoder(1);
  var obj={
    UserID:userID,
    StatusID:2,
    TicketID:ticketID
  }
    const response = await CloseTicket(obj);
    if(response.status === 1){
      setShowLoder(0);
      setisShowToaster(1);
      setShowSuccessMsg('Ticket Closed Successfully.!!');
      getTicketListForDeveloper();
      setTimeout(()=>{
        setisShowToaster(0);
      },1300)
    }
    else{
      setShowLoder(0);
      setShowAlertToster(1);
      setShowErrMessage(response.message);
      setTimeout(()=>{
        setShowAlertToster(0);
      },1300)
    }
  }
  const getDeveloperList = async(id)=>{
    setTicketID(id);
    const response = await GetDeveloperList(supportTicketClientID);
    setShowPopUp(1);
    if(response.status === 1){
      let tempArr=[];
      const dataList = response.responseValue;
      dataList.forEach((val,i)=>{
        if(val.isDeveloper === "Developer"){
          tempArr.push(val)
        }
      });
      setDeveloperList(tempArr);
    }
    else{
      setShowAlertToster(1);
      setShowErrMessage(response.message);
      setTimeout(()=>{
        setShowAlertToster(0);
      },1300)
    }
  }
  const handleTransferTicket= async()=>{
    document.getElementById('errDevloperList').style.display="none";
   const selectedDeveloper= document.getElementById('ddlDeveloperList').value;
   if(parseInt(selectedDeveloper) === 0 || parseInt(selectedDeveloper)  === undefined || parseInt(selectedDeveloper)  === null){
    document.getElementById('errDevloperList').innerHTML="Select Developer";
    document.getElementById('errDevloperList').style.display="block";
   }
   else{
     var obj={
      TicketID: ticketID,
      TransferBy:userID,
      TransferTo:selectedDeveloper,
      Remark:transferTicketRemark
    }
    setShowUnderProcess(1);
    const response = await TransferTicket(obj);
    if(response.status === 1){
      setShowUnderProcess(0);
      setTosterValue(0);
      setShowUpdateToster(1);
      setTosterMessage("Ticket Transfered Successfully!");
      setTimeout(()=>{
        setShowUpdateToster(0);
        setTransferTicketRemark("");
        setTicketID(0);
        setShowPopUp(0);
        getTicketListForDeveloper();

      },1200)
    }
    else{
      setShowUnderProcess(0);
      setTosterValue(1);
      setShowUpdateToster(1);
      setTosterMessage(response.responseValue);
      setTimeout(()=>{
        setShowUpdateToster(0);
      },1200)
    }
   }
  }
  const handleCloseModal= ()=>{
    setShowPopUp(0);
    setTicketID(0);
    setTransferTicketRemark("");
  }
  useEffect(()=>{
    getTicketListForDeveloper();
    getStatusList();
  },[]);
    return(
        <>
             <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <Heading text="Generate Ticket" />
          <div className="row">
            <div className="col-12 mt-2">
            <div className="row flterbg">
              <div className="col-6 text-left">Ticket List</div>
              <div className="col-6 text-right filtersec">
                  <select onChange={getTicketListByFilter} id="ddlStatusFilter">
                       <option value="0">All</option>
                      {ticketStatusList && ticketStatusList.map((value,index)=>{
                        return( 
                          <>
                          {/* Status(Open) Will Be Selected By Default */}
                          {value.id === 1 ? <option value={value.id} selected>{value.name}</option>   : <option value={value.id}>{value.name}</option> }
                          </>
                         
                          )
                      })}
                  </select>
              </div>
            </div>
              <div className="med-table-section" style={{ "height": "75vh" }}>
                <TableContainer>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>Ticket No</th>
                      <th>Date Time</th>
                      <th>Ticket Status	</th>
                      <th>Subject</th>
                      <th>Description</th>
                      <th>Attachment</th>
                      <th>Priority</th>
                      <th>Requested by</th>
                      <th style={{ "width": "10%" }} className="text-center">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                   {/* Use Map function To Bind Ticket List */}
                    {devTicketList && devTicketList.map((val, ind) => {
                      // Create a unique ID for the dt element in each iteration
                      const descriptionId = `dt_${ind}`;
                      // Create a variable to store the parsed content
                      const parsedContent = new DOMParser().parseFromString(val.description, "text/html");
                      // Use a function to set the content
                      const setDtContent = () => {
                        const dtElement = document.getElementById(descriptionId);
                        if (dtElement) {
                          dtElement.innerHTML = parsedContent.body.innerHTML;
                        }
                      };

                      // Call the setDtContent function after 1000 milliseconds
                      setTimeout(setDtContent, 200);
                      return (
                        <tr key={val.id}>
                          <td className="text-center">{ind + 1}</td>
                          <td>{val.ticketNo}</td>
                          <td>{val.ticketTransferDate}</td>
                          <td>{val.status}</td>
                          <td>{val.subject}</td>
                          {/* <td>{val.description}</td> */}
                          <td> <div id={descriptionId} className='mt-2 mx-2'></div></td>
                          <td><Link to={val.filePath} target="_blank"><img src={val.filePath} alt="Attachment" style={{ height: '5rem' }} /></Link></td>
                          <td>{val.priority}</td>
                          <td>{val.requestedby}</td>
                          <td>
                            <div className="action-button">
                            <span title="Transfer Ticket" onClick={()=>{getDeveloperList(val.id)}}><i className="fa-solid fa-money-bill-transfer"></i></span>
                            <span  data-bs-toggle="modal" title="Click Here To Close Ticket" data-bs-placement="bottom" data-bs-target="#confirmationModal" onClick={()=>{setTicketID(val.id)}}><i className="fa-solid fa-circle-check text-success"></i></span>
                              {/* <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={editBtnIcon} className='' alt='' onClick={() => { handleEdit(val) }} /></div>
                              <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowId(val.id) }} />
                              </div>
                              <div><i className="fa fa-eye" aria-hidden="true" title="Cilck Here To Track Ticket" onClick={() => { handleTrackTicket(val) }}></i></div> */}
                            </div>
                          </td>
                        </tr>
                      )
                    })}


                  </tbody>
                </TableContainer>
                {/* -----------------------Start confirmation Modal Popup-------------------   */}

                {/*  <!-- Modal -->  */}
                <div className="modal fade" id="confirmationModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                  <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                      <div className="modal-body modelbdy text-center">
                        <div className='popDeleteIcon'><i className="fa-solid fa-check-to-slot" style={{paddingRight:'6px'}}></i></div>
                        <div className='popDeleteTitle mt-3'>Close Ticket</div>
                        <div className='popDeleteContent'>Do you want to close this ticket?</div>
                      </div>
                      <div className="modal-footer1 text-center">

                        <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">No,Cancel</button>
                        <button type="button" className="btn-delete popBtnDelete" onClick={handleCloseTicket} data-bs-dismiss="modal">Yes</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {/ -----------------------End confirmation Modal Popup--------------------- /} */}
                {/* -----------------------Start Transfer Ticket Modal Popup-------------------   */}

                {/*  <!-- Modal -->  */}
                {showPopUp === 1 ?
                  <div className={`modal d-${showPopUp === 1 ? "block":""}`} id="transferTicketModal" tabIndex="-1" aria-labelledby="transferTicketModalLabel" aria-hidden="true">
                  <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                      <div className="modal-body modelbdy text-center">
                        {/* <div className='popDeleteIcon'><i className="fa-solid fa-check-to-slot" style={{paddingRight:'6px'}}></i></div> */}
                        <div className='popDeleteTitle mt-3'>Transfer Ticket</div>
                        <div className='popDeleteContent'>
                        <div className="row bg-white">
                          <div className="col-12">
                              <div className="col-12 mb-2">
                                <label htmlFor="TestName" className="form-label">Remark</label>
                                <textarea rows="3" className="form-control form-control-sm" name="transferTicketRemark" id="transferTicketRemark" onChange={handleChange} value={transferTicketRemark}></textarea>
                                <small id="errSubject" className="form-text text-danger" style={{ display: 'none' }}></small>
                              </div>
                              <div className="col-12 mb-2">
                                <label htmlFor="TestName" className="form-label">Developer List</label>
                                <select className="col-12" id="ddlDeveloperList">
                                  <option value="0">Select Developer</option>
                                  {developerList && developerList.map((val)=>{
                                    return(
                                     <option value={val.id}>{val.userName}</option>
                                    )
                                  })}
                                </select>
                                <small id="errDevloperList" className="form-text text-danger" style={{ display: 'none' }}></small>
                              </div>
                          </div>
                         </div>
                        </div>
                      </div>
                      <div className="modal-footer1 text-center">
                      {showUnderProcess === 1 ? <><TosterUnderProcess />  </> :
                      showUpdateToster === 1 ? <Toster value={tosterValue} message={tosterMessage} />:
                        <>
                        <button type="button" className="btncancel popBtnCancel me-2" onClick={handleCloseModal}>Cancel</button>
                        <button type="button" className="btn-delete popBtnDelete" onClick={handleTransferTicket} data-bs-dismiss="modal">Transfer</button>
                        </>
                      }
                        
                      </div>
                    </div>
                  </div>
                </div>
                :''}
               
                {/* {/ -----------------------End Transfer Ticket Modal Popup--------------------- /} */}

              </div>
            </div>
          </div>
        </div>
        {
          showLoder === 1 ? <Loader val={showLoder} /> : ""
        }
        {/* Toaster */}
        {
          isShowToaster === 1 ?
            <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
        }

        {
          showAlertToster === 1 ?
            <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
        }
      </section>
        </>
    )
}