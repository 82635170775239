import React, { useEffect, useState } from 'react'
import GetBillingActivity from '../API/GetBillingActivity'
import printerwhite from "../../../assets/images/icons/printerwhite.svg"
import PatientDeatils from '../../../Component/PatientDeatils'
import AlertToster from '../../../Component/AlertToster'
import Loader from '../../../Component/Loader'
export default function ReceiptDailyBillingActivitySheet() {
  let [bedNo, setBedNo] = useState("-")
  let [bedsideInsertions, setBedsideInsertions] = useState([])
  let [airBed, setAirBed] = useState([])
  let [biPAP, setBiPAP] = useState([])
  let [cardiacMonitor, setCardiacMonitor] = useState([])
  let [dVTPump, setDVTPump] = useState([])
  let [infusion1, setInfusion1] = useState([])
  let [infusion2, setInfusion2] = useState([])
  let [oxygen, setOxygen] = useState([])
  let [ventilator, setVentilator] = useState([])
  let [abg, setAbg] = useState([])
  let [bloodTransfusion, setBloodTransfusion] = useState([])
  let [nebulization, setNebulization] = useState([])
  let [dressing, setDressing] = useState([])
  let [chestXray, setChestXray] = useState([])
  let [echo, setEcho] = useState([])
  let [ecg, setEcg] = useState([])
  let [rbs, setRbs] = useState([])
  let [showAlertToster, setShowAlertToster] = useState(0)
  let [showAlertMessage, setShowAlertMessage] = useState("")
  let [loader, setLoader] = useState(0)

  let getData = async () => {
    setLoader(1)
    let uhid = JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid;

    JSON.parse(window.sessionStorage.getItem("IPDpatientList")).map((val) => {
      if (val.uhId === uhid) {
        setBedNo(val.bedName)
      }
    })
    let response = await GetBillingActivity(uhid)
    if (response.status === 1) {
      setLoader(0)

      let data = response.responseValue
      console.log("data", data)
      setBedsideInsertions(data.bedSideData[0].insertionData !== null ? JSON.parse(data.bedSideData[0].insertionData) : [])
      setAirBed(data.bedSideData[0].airBed !== null ? JSON.parse(data.bedSideData[0].airBed) : [])
      setBiPAP(data.bedSideData[0].bipap !== null ? JSON.parse(data.bedSideData[0].bipap) : [])
      setCardiacMonitor(data.bedSideData[0].cardiac !== null ? JSON.parse(data.bedSideData[0].cardiac) : [])
      setDVTPump(data.bedSideData[0].dvtPump !== null ? JSON.parse(data.bedSideData[0].dvtPump) : [])
      setInfusion1(data.bedSideData[0].infusion !== null ? JSON.parse(data.bedSideData[0].infusion) : [])
      setInfusion2(data.bedSideData[0].infusion2 !== null ? JSON.parse(data.bedSideData[0].infusion2) : [])
      setOxygen(data.bedSideData[0].oxygen !== null ? JSON.parse(data.bedSideData[0].oxygen) : [])
      setVentilator(data.bedSideData[0].oxygen !== null ? JSON.parse(data.bedSideData[0].oxygen) : [])
      setAbg(data.abgData !== null ? JSON.parse(data.abgData) : [])
      setBloodTransfusion(data.bloodTransfusion.length !== 0 ? JSON.parse(data.bloodTransfusion) : [])
      setNebulization(data.dressingAndNebulization[0].nebulization !== null ? JSON.parse(data.dressingAndNebulization[0].nebulization) : [])
      setDressing(data.dressingAndNebulization[0].dressing !== null ? JSON.parse(data.dressingAndNebulization[0].dressing) : [])
      setChestXray(data.portableData[0].chestXray !== null ? JSON.parse(data.portableData[0].chestXray) : [])
      setEcho(data.portableData[0].echo !== null ? JSON.parse(data.portableData[0].echo) : [])
      setEcg(data.bedSideData[0].ecg !== null ? JSON.parse(data.bedSideData[0].ecg) : [])
      setRbs(data.bedSideData[0].rbs !== null ? JSON.parse(data.bedSideData[0].rbs) : [])
    }
    else {
      setShowAlertToster(1)
      setShowAlertMessage(response.message)
      setLoader(0)

    }
  }

  let handlePrint = () => {

    // var originalContent = document.body.innerHTML;
    //    const printContainer = document.getElementById("printDiv").innerHTML;
    //    document.body.innerHTML = printContainer;
    //    document.body.innerHTML = originalContent;
    //  window.print();
    window.open("/receiptdailybillingactivitysheetprint/", 'noopener,noreferrer');


  }

  useEffect(() => {
    getData()
  }, [])
  return (
    <div id="printDiv" className='boxcontainer'>
      <PatientDeatils />
      <table className='med-table border mt-2'>
        <tr className='border'>
          <td colSpan={3}><strong className='mt-0 mb-0 pt-0 pb-0'>Stable/LAMA/DOR/Referred/Expired
            <div className='heading-form mt-0' style={{ textAlign: 'left', fontSize: '17px' }}>DAILY BILLING ACTIVITY SHEET (ICU)
            </div></strong></td>
          <td className='mt-0 mb-0 pt-0 pb-0 border' colSpan={3} >
            <strong className='mt-0 mb-0 pt-0 pb-0'>Bed Transfer Details From: </strong>
            <div className='dailybillform'> <label>From : </label> <label>To :</label>
            </div></td>
          <td colSpan={2} className='text-center' style={{ borderLeft: '1px solid black', textAlign: 'center', fontSize: '15px' }}>
            <strong><div className='mt-0 mb-0 pt-0 pb-0'>Patients Label</div>
            </strong></td>
        </tr>
      </table>
      <table className='med-table border '>
        <tr className='border'>
          <td className='border p-0 m-0' >
            <strong>Date: </strong>
            <strong>{new Date().toLocaleDateString('en-GB')} </strong>
          </td>
          <td className='border' >
            <strong>Bed No. </strong>
            <strong>{bedNo}</strong>
          </td>
          <td className='border' >
            <strong>12 AM - 8 AM</strong>
          </td>
          <td className='border' >
            <strong>8 AM - 2 PM</strong>
          </td>
          <td className='border' >
            <strong>2 PM - 8 PM</strong>
          </td>
          <td className='border' >
            <strong>8 PM - 12 AM</strong>
          </td>
        </tr>

        {/* heading 1 */}
        <tr>
          <td colSpan={12} className='ps-5 border'>
            <strong>Surgery Details</strong>
          </td>
        </tr>
        <tr>
          <td className='border'>
            <strong>1.</strong>
          </td>
          <td className='border' >
            <strong >Name of Procedure/Doctor Name </strong>
          </td>
        </tr>

        {/* heading 2 */}

        <tr>
          <td colSpan={12} className='ps-5 border'>
            <strong>Consultations</strong>
          </td>
        </tr>
        <tr>
          <td className='border'>
            <strong>1.</strong>
          </td>
          <td className='border' >
            <strong >Cross Consult Visit (Doctor name) </strong>
          </td>
          <td className='border' >
          </td>
          <td className='border' >
          </td>
          <td className='border' >
          </td>
          <td className='border' >
          </td>
        </tr>
        <tr>
          <td className='border'>
            <strong>2.</strong>
          </td>
          <td className='border' >
            <strong >Physiotherapist Visit (Therapy) </strong>
          </td>
          <td className='border' >
          </td>
          <td className='border' >
          </td>
          <td className='border' >
          </td>
          <td className='border' >
          </td>
        </tr>


        {/* heading 3 */}

        <tr>
          <td colSpan={2} className='ps-5 border'>
            <strong>Bedside Insertions</strong>
          </td>
          <td className='border'><strong>Count</strong></td>
          <td className='border'><strong>Count</strong></td>
          <td className='border'><strong>Count</strong></td>
          <td className='border'><strong>Count</strong></td>
        </tr>



        <tr>
          <td className='border'>
            <strong>1.</strong>
          </td>
          <td className='border' >
            <strong >Arterial Line Insertion </strong>
          </td>

          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Arterial Line Insertion" && val.time_frame === "12 AM to 8 AM") !== -1 ?

              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Arterial Line Insertion" && val.time_frame === "12 AM to 8 AM")].count
                }
              </td> :
              <td className='border' >
                <strong>-</strong>
              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Arterial Line Insertion" && val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Arterial Line Insertion" && val.time_frame === "8 AM to 2 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Arterial Line Insertion" && val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Arterial Line Insertion" && val.time_frame === "2 PM to 8 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Arterial Line Insertion" && val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Arterial Line Insertion" && val.time_frame === "8 PM to 12 AM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
        </tr>

        <tr>
          <td className='border'>
            <strong>2.</strong>
          </td>
          <td className='border' >
            <strong >CVP Line Insertion </strong>
            {/* <strong >{val.lineCathetorType}</strong> */}
          </td>
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "CVP Line Insertion" && val.time_frame === "12 AM to 8 AM") !== -1 ?

              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "CVP Line Insertion" && val.time_frame === "12 AM to 8 AM")].count
                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "CVP Line Insertion" && val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "CVP Line Insertion" && val.time_frame === "8 AM to 2 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "CVP Line Insertion" && val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "CVP Line Insertion" && val.time_frame === "2 PM to 8 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "CVP Line Insertion" && val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "CVP Line Insertion" && val.time_frame === "8 PM to 12 AM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
        </tr>

        <tr>
          <td className='border'>
            <strong>3.</strong>
          </td>
          <td className='border' >
            <strong >Foley's Catheter</strong>
            {/* <strong >{val.lineCathetorType}</strong> */}
          </td>
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Foley's Catheter" && val.time_frame === "12 AM to 8 AM") !== -1 ?

              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Foley's Catheter" && val.time_frame === "12 AM to 8 AM")].count
                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Foley's Catheter" && val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Foley's Catheter" && val.time_frame === "8 AM to 2 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Foley's Catheter" && val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Foley's Catheter" && val.time_frame === "2 PM to 8 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Foley's Catheter" && val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Foley's Catheter" && val.time_frame === "8 PM to 12 AM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
        </tr>

        <tr>
          <td className='border'>
            <strong>4.</strong>
          </td>
          <td className='border' >
            <strong >HD Catheter Insertion</strong>
            {/* <strong >{val.lineCathetorType}</strong> */}
          </td>
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "HD Catheter Insertion" && val.time_frame === "12 AM to 8 AM") !== -1 ?

              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "HD Catheter Insertion" && val.time_frame === "12 AM to 8 AM")].count
                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "HD Catheter Insertion" && val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "HD Catheter Insertion" && val.time_frame === "8 AM to 2 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "HD Catheter Insertion" && val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "HD Catheter Insertion" && val.time_frame === "2 PM to 8 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "HD Catheter Insertion" && val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "HD Catheter Insertion" && val.time_frame === "8 PM to 12 AM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
        </tr>


        <tr>
          <td className='border'>
            <strong>5.</strong>
          </td>
          <td className='border' >
            <strong >ICD Insertion</strong>
            {/* <strong >{val.lineCathetorType}</strong> */}
          </td>
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "ICD Insertion" && val.time_frame === "12 AM to 8 AM") !== -1 ?

              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "ICD Insertion" && val.time_frame === "12 AM to 8 AM")].count
                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "ICD Insertion" && val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "ICD Insertion" && val.time_frame === "8 AM to 2 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "ICD Insertion" && val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "ICD Insertion" && val.time_frame === "2 PM to 8 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "ICD Insertion" && val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "ICD Insertion" && val.time_frame === "8 PM to 12 AM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
        </tr>

        <tr>
          <td className='border'>
            <strong>6.</strong>
          </td>
          <td className='border' >
            <strong >Intubation</strong>
            {/* <strong >{val.lineCathetorType}</strong> */}
          </td>
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Intubation" && val.time_frame === "12 AM to 8 AM") !== -1 ?

              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Intubation" && val.time_frame === "12 AM to 8 AM")].count
                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Intubation" && val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Intubation" && val.time_frame === "8 AM to 2 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Intubation" && val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Intubation" && val.time_frame === "2 PM to 8 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Intubation" && val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Intubation" && val.time_frame === "8 PM to 12 AM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
        </tr>


        <tr>
          <td className='border'>
            <strong>7.</strong>
          </td>
          <td className='border' >
            <strong >IV Cannulation</strong>
            {/* <strong >{val.lineCathetorType}</strong> */}
          </td>
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "IV Cannulation" && val.time_frame === "12 AM to 8 AM") !== -1 ?

              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "IV Cannulation" && val.time_frame === "12 AM to 8 AM")].count
                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "IV Cannulation" && val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "IV Cannulation" && val.time_frame === "8 AM to 2 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "IV Cannulation" && val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "IV Cannulation" && val.time_frame === "2 PM to 8 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "IV Cannulation" && val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "IV Cannulation" && val.time_frame === "8 PM to 12 AM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
        </tr>

        <tr>
          <td className='border'>
            <strong>8.</strong>
          </td>
          <td className='border' >
            <strong >Lumbar Puncture</strong>
            {/* <strong >{val.lineCathetorType}</strong> */}
          </td>
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Lumbar Puncture" && val.time_frame === "12 AM to 8 AM") !== -1 ?

              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Lumbar Puncture" && val.time_frame === "12 AM to 8 AM")].count
                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Lumbar Puncture" && val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Lumbar Puncture" && val.time_frame === "8 AM to 2 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Lumbar Puncture" && val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Lumbar Puncture" && val.time_frame === "2 PM to 8 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Lumbar Puncture" && val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Lumbar Puncture" && val.time_frame === "8 PM to 12 AM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
        </tr>

        <tr>
          <td className='border'>
            <strong>9.</strong>
          </td>
          <td className='border' >
            <strong >PICC Line Insertion</strong>
            {/* <strong >{val.lineCathetorType}</strong> */}
          </td>
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "PICC Line Insertion" && val.time_frame === "12 AM to 8 AM") !== -1 ?

              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "PICC Line Insertion" && val.time_frame === "12 AM to 8 AM")].count
                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "PICC Line Insertion" && val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "PICC Line Insertion" && val.time_frame === "8 AM to 2 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "PICC Line Insertion" && val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "PICC Line Insertion" && val.time_frame === "2 PM to 8 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "PICC Line Insertion" && val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "PICC Line Insertion" && val.time_frame === "8 PM to 12 AM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
        </tr>


        <tr>
          <td className='border'>
            <strong>10.</strong>
          </td>
          <td className='border' >
            <strong >Pleural/Ascetic Tapping</strong>
            {/* <strong >{val.lineCathetorType}</strong> */}
          </td>
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Pleural/Ascetic Tapping" && val.time_frame === "12 AM to 8 AM") !== -1 ?

              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Pleural/Ascetic Tapping" && val.time_frame === "12 AM to 8 AM")].count
                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Pleural/Ascetic Tapping" && val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Pleural/Ascetic Tapping" && val.time_frame === "8 AM to 2 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Pleural/Ascetic Tapping" && val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Pleural/Ascetic Tapping" && val.time_frame === "2 PM to 8 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Pleural/Ascetic Tapping" && val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Pleural/Ascetic Tapping" && val.time_frame === "8 PM to 12 AM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
        </tr>

        <tr>
          <td className='border'>
            <strong>11.</strong>
          </td>
          <td className='border' >
            <strong >Ryle's Tube Insertion</strong>
            {/* <strong >{val.lineCathetorType}</strong> */}
          </td>
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Ryle's Tube Insertion" && val.time_frame === "12 AM to 8 AM") !== -1 ?

              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Ryle's Tube Insertion" && val.time_frame === "12 AM to 8 AM")].count
                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Ryle's Tube Insertion" && val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Ryle's Tube Insertion" && val.time_frame === "8 AM to 2 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Ryle's Tube Insertion" && val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Ryle's Tube Insertion" && val.time_frame === "2 PM to 8 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Ryle's Tube Insertion" && val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Ryle's Tube Insertion" && val.time_frame === "8 PM to 12 AM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
        </tr>


        <tr>
          <td className='border'>
            <strong>12.</strong>
          </td>
          <td className='border' >
            <strong >Tracheostomy/Tube Change</strong>
            {/* <strong >{val.lineCathetorType}</strong> */}
          </td>
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Tracheostomy/Tube Change" && val.time_frame === "12 AM to 8 AM") !== -1 ?

              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Tracheostomy/Tube Change" && val.time_frame === "12 AM to 8 AM")].count
                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Tracheostomy/Tube Change" && val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Tracheostomy/Tube Change" && val.time_frame === "8 AM to 2 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Tracheostomy/Tube Change" && val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Tracheostomy/Tube Change" && val.time_frame === "2 PM to 8 PM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
          {
            bedsideInsertions.findIndex(val => val.lineCathetorType === "Tracheostomy/Tube Change" && val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {
                  bedsideInsertions[bedsideInsertions.findIndex(val => val.lineCathetorType === "Tracheostomy/Tube Change" && val.time_frame === "8 PM to 12 AM")].count

                }
              </td> :
              <td className='border' >
                <strong>-</strong>

              </td>
          }
        </tr>

        <tr>
          <td className='border'>
            <strong>13.</strong>
          </td>
          <td className='border' >
            <strong >Other</strong>
          </td>

          <td className='border' >


          </td>

          <td className='border' >


          </td>

          <td className='border' >

          </td>


          <td className='border' >
          </td>

        </tr>

        {/* heading 4 */}

        <tr>
          <td colSpan={2} className='ps-5 border'>
            <strong>Bedside Insertions</strong>
          </td>
          <td className='border'>
            <table>
              <tr>
                <td>
                  <strong>Start</strong>
                </td>
                <td style={{ borderLeft: "1px solid #ddd" }}>
                  <strong>Stop</strong>
                </td>
              </tr>
            </table>
          </td>
          <td className='border'>
            <table>
              <tr>
                <td>
                  <strong>Start</strong>
                </td>
                <td style={{ borderLeft: "1px solid #ddd" }}>
                  <strong>Stop</strong>
                </td>
              </tr>
            </table>
          </td>
          <td className='border'>
            <table>
              <tr>
                <td>
                  <strong>Start</strong>
                </td>
                <td style={{ borderLeft: "1px solid #ddd" }}>
                  <strong>Stop</strong>
                </td>
              </tr>
            </table>
          </td>
          <td className='border'>
            <table>
              <tr>
                <td>
                  <strong>Start</strong>
                </td>
                <td style={{ borderLeft: "1px solid #ddd" }}>
                  <strong>Stop</strong>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td className='border'>
            <strong>1.</strong>
          </td>
          <td className='border' >
            <strong >Air-Bed </strong>
          </td>
          {
            airBed.findIndex(val => val.fromtime_frame === "12 AM to 8 AM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{airBed[airBed.findIndex(val => val.fromtime_frame === "12 AM to 8 AM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{airBed[airBed.findIndex(val => val.fromtime_frame === "12 AM to 8 AM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            airBed.findIndex(val => val.fromtime_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{airBed[airBed.findIndex(val => val.fromtime_frame === "8 AM to 2 PM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{airBed[airBed.findIndex(val => val.fromtime_frame === "8 AM to 2 PM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            airBed.findIndex(val => val.fromtime_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{airBed[airBed.findIndex(val => val.fromtime_frame === "2 PM to 8 PM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{airBed[airBed.findIndex(val => val.fromtime_frame === "2 PM to 8 PM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            airBed.findIndex(val => val.fromtime_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{airBed[airBed.findIndex(val => val.fromtime_frame === "8 PM to 12 AM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{airBed[airBed.findIndex(val => val.fromtime_frame === "8 PM to 12 AM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }

          {/* <td className='border' >
            <table>
              <tr>
                <td >
                  <strong></strong>
                </td>
                <td style={{ borderLeft: "1px solid #ddd" }}>
                  <strong></strong>
                </td>
              </tr>
            </table>
          </td>
          <td className='border' >
            <table>
              <tr>
                <td >
                  <strong></strong>
                </td>
                <td style={{ borderLeft: "1px solid #ddd" }}>
                  <strong></strong>
                </td>
              </tr>
            </table>
          </td>
          <td className='border' >
            <table>
              <tr>
                <td >
                  <strong></strong>
                </td>
                <td style={{ borderLeft: "1px solid #ddd" }}>
                  <strong></strong>
                </td>
              </tr>
            </table>
          </td> */}
        </tr>
        <tr>
          <td className='border'>
            <strong>2.</strong>
          </td>
          <td className='border' >
            <strong >Bi-PAP </strong>
          </td>
          {
            biPAP.findIndex(val => val.fromtime_frame === "12 AM to 8 AM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{biPAP[biPAP.findIndex(val => val.fromtime_frame === "12 AM to 8 AM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{biPAP[biPAP.findIndex(val => val.fromtime_frame === "12 AM to 8 AM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            biPAP.findIndex(val => val.fromtime_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{biPAP[biPAP.findIndex(val => val.fromtime_frame === "8 AM to 2 PM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{biPAP[biPAP.findIndex(val => val.fromtime_frame === "8 AM to 2 PM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            biPAP.findIndex(val => val.fromtime_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{biPAP[biPAP.findIndex(val => val.fromtime_frame === "2 PM to 8 PM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{biPAP[biPAP.findIndex(val => val.fromtime_frame === "2 PM to 8 PM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            biPAP.findIndex(val => val.fromtime_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{biPAP[biPAP.findIndex(val => val.fromtime_frame === "8 PM to 12 AM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{biPAP[biPAP.findIndex(val => val.fromtime_frame === "8 PM to 12 AM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
        </tr>

        <tr>
          <td className='border'>
            <strong>3.</strong>
          </td>
          <td className='border' >
            <strong >Cardiac Monitor</strong>
          </td>
          {
            cardiacMonitor.findIndex(val => val.fromtime_frame === "12 AM to 8 AM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{cardiacMonitor[cardiacMonitor.findIndex(val => val.fromtime_frame === "12 AM to 8 AM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{cardiacMonitor[cardiacMonitor.findIndex(val => val.fromtime_frame === "12 AM to 8 AM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            cardiacMonitor.findIndex(val => val.fromtime_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{cardiacMonitor[cardiacMonitor.findIndex(val => val.fromtime_frame === "8 AM to 2 PM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{cardiacMonitor[cardiacMonitor.findIndex(val => val.fromtime_frame === "8 AM to 2 PM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            cardiacMonitor.findIndex(val => val.fromtime_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{cardiacMonitor[cardiacMonitor.findIndex(val => val.fromtime_frame === "2 PM to 8 PM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{cardiacMonitor[cardiacMonitor.findIndex(val => val.fromtime_frame === "2 PM to 8 PM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            cardiacMonitor.findIndex(val => val.fromtime_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{cardiacMonitor[cardiacMonitor.findIndex(val => val.fromtime_frame === "8 PM to 12 AM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{cardiacMonitor[cardiacMonitor.findIndex(val => val.fromtime_frame === "8 PM to 12 AM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
        </tr>

        <tr>
          <td className='border'>
            <strong>4.</strong>
          </td>
          <td className='border' >
            <strong >DVT Pump</strong>
          </td>
          {
            dVTPump.findIndex(val => val.fromtime_frame === "12 AM to 8 AM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{dVTPump[dVTPump.findIndex(val => val.fromtime_frame === "12 AM to 8 AM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{dVTPump[dVTPump.findIndex(val => val.fromtime_frame === "12 AM to 8 AM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            dVTPump.findIndex(val => val.fromtime_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{dVTPump[dVTPump.findIndex(val => val.fromtime_frame === "8 AM to 2 PM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{dVTPump[dVTPump.findIndex(val => val.fromtime_frame === "8 AM to 2 PM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            dVTPump.findIndex(val => val.fromtime_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{dVTPump[dVTPump.findIndex(val => val.fromtime_frame === "2 PM to 8 PM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{dVTPump[dVTPump.findIndex(val => val.fromtime_frame === "2 PM to 8 PM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            dVTPump.findIndex(val => val.fromtime_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{dVTPump[dVTPump.findIndex(val => val.fromtime_frame === "8 PM to 12 AM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{dVTPump[dVTPump.findIndex(val => val.fromtime_frame === "8 PM to 12 AM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
        </tr>

        <tr>
          <td className='border'>
            <strong>5.</strong>
          </td>
          <td className='border' >
            <strong >Infusion Pump 1</strong>
          </td>
          {
            infusion1.findIndex(val => val.fromtime_frame === "12 AM to 8 AM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{infusion1[infusion1.findIndex(val => val.fromtime_frame === "12 AM to 8 AM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{infusion1[infusion1.findIndex(val => val.fromtime_frame === "12 AM to 8 AM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            infusion1.findIndex(val => val.fromtime_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{infusion1[infusion1.findIndex(val => val.fromtime_frame === "8 AM to 2 PM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{infusion1[infusion1.findIndex(val => val.fromtime_frame === "8 AM to 2 PM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            infusion1.findIndex(val => val.fromtime_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{infusion1[infusion1.findIndex(val => val.fromtime_frame === "2 PM to 8 PM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{infusion1[infusion1.findIndex(val => val.fromtime_frame === "2 PM to 8 PM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            infusion1.findIndex(val => val.fromtime_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{infusion1[infusion1.findIndex(val => val.fromtime_frame === "8 PM to 12 AM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{infusion1[infusion1.findIndex(val => val.fromtime_frame === "8 PM to 12 AM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
        </tr>

        <tr>
          <td className='border'>
            <strong>6.</strong>
          </td>
          <td className='border' >
            <strong >Infusion Pump 2</strong>
          </td>
          {
            infusion2.findIndex(val => val.fromtime_frame === "12 AM to 8 AM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{infusion2[infusion2.findIndex(val => val.fromtime_frame === "12 AM to 8 AM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{infusion2[infusion2.findIndex(val => val.fromtime_frame === "12 AM to 8 AM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            infusion2.findIndex(val => val.fromtime_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{infusion2[infusion2.findIndex(val => val.fromtime_frame === "8 AM to 2 PM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{infusion2[infusion2.findIndex(val => val.fromtime_frame === "8 AM to 2 PM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            infusion2.findIndex(val => val.fromtime_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{infusion2[infusion2.findIndex(val => val.fromtime_frame === "2 PM to 8 PM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{infusion2[infusion2.findIndex(val => val.fromtime_frame === "2 PM to 8 PM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            infusion2.findIndex(val => val.fromtime_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{infusion2[infusion2.findIndex(val => val.fromtime_frame === "8 PM to 12 AM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{infusion2[infusion2.findIndex(val => val.fromtime_frame === "8 PM to 12 AM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
        </tr>

        <tr>
          <td className='border'>
            <strong>7.</strong>
          </td>
          <td className='border' >
            <strong >Oxygen</strong>
          </td>
          {
            oxygen.findIndex(val => val.fromtime_frame === "12 AM to 8 AM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{oxygen[oxygen.findIndex(val => val.fromtime_frame === "12 AM to 8 AM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{oxygen[oxygen.findIndex(val => val.fromtime_frame === "12 AM to 8 AM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            oxygen.findIndex(val => val.fromtime_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{oxygen[oxygen.findIndex(val => val.fromtime_frame === "8 AM to 2 PM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{oxygen[oxygen.findIndex(val => val.fromtime_frame === "8 AM to 2 PM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            oxygen.findIndex(val => val.fromtime_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{oxygen[oxygen.findIndex(val => val.fromtime_frame === "2 PM to 8 PM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{oxygen[oxygen.findIndex(val => val.fromtime_frame === "2 PM to 8 PM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            oxygen.findIndex(val => val.fromtime_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{oxygen[oxygen.findIndex(val => val.fromtime_frame === "8 PM to 12 AM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{oxygen[oxygen.findIndex(val => val.fromtime_frame === "8 PM to 12 AM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
        </tr>

        <tr>
          <td className='border'>
            <strong>8.</strong>
          </td>
          <td className='border' >
            <strong >Ventilator</strong>
          </td>
          {
            ventilator.findIndex(val => val.fromtime_frame === "12 AM to 8 AM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{ventilator[ventilator.findIndex(val => val.fromtime_frame === "12 AM to 8 AM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{ventilator[ventilator.findIndex(val => val.fromtime_frame === "12 AM to 8 AM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            ventilator.findIndex(val => val.fromtime_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{ventilator[ventilator.findIndex(val => val.fromtime_frame === "8 AM to 2 PM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{ventilator[ventilator.findIndex(val => val.fromtime_frame === "8 AM to 2 PM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            ventilator.findIndex(val => val.fromtime_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{ventilator[ventilator.findIndex(val => val.fromtime_frame === "2 PM to 8 PM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{ventilator[ventilator.findIndex(val => val.fromtime_frame === "2 PM to 8 PM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
          {
            ventilator.findIndex(val => val.fromtime_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>{ventilator[ventilator.findIndex(val => val.fromtime_frame === "8 PM to 12 AM")].fromDateTime}</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>{ventilator[ventilator.findIndex(val => val.fromtime_frame === "8 PM to 12 AM")].toDateTime}</strong>
                    </td>
                  </tr>
                </table>
              </td>
              :
              <td className='border' >
                <table>
                  <tr>
                    <td >
                      <strong>-</strong>
                    </td>
                    <td style={{ borderLeft: "1px solid #ddd" }}>
                      <strong>-</strong>
                    </td>
                  </tr>
                </table>
              </td>
          }
        </tr>

        {/* heading 5 */}


        <tr>
          <td colSpan={2} className='ps-5 border'>
            <strong>Bedside Procedures/Tests</strong>
          </td>
          <td className='border'><strong>Count</strong></td>
          <td className='border'><strong>Count</strong></td>
          <td className='border'><strong>Count</strong></td>
          <td className='border'><strong>Count</strong></td>
        </tr>
        <tr>
          <td className='border'>
            <strong>1.</strong>
          </td>
          <td className='border' >
            <strong >ABG </strong>
          </td>
          {
            abg.findIndex(val => val.time_frame === "12 AM to 8 AM") !== -1 ?
              <td className='border' >
                {abg[abg.findIndex(val => val.time_frame === "12 AM to 8 AM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }

          {
            abg.findIndex(val => val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {abg[abg.findIndex(val => val.time_frame === "8 AM to 2 PM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
          {
            abg.findIndex(val => val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {abg[abg.findIndex(val => val.time_frame === "2 PM to 8 PM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
          {
            abg.findIndex(val => val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {abg[abg.findIndex(val => val.time_frame === "8 PM to 12 AM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
        </tr>
        <tr>
          <td className='border'>
            <strong>2.</strong>
          </td>
          <td className='border' >
            <strong >Blood Transfusion</strong>
          </td>
          {
            bloodTransfusion.findIndex(val => val.time_frame === "12 AM to 8 AM") !== -1 ?
              <td className='border' >
                {bloodTransfusion[bloodTransfusion.findIndex(val => val.time_frame === "12 AM to 8 AM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }

          {
            bloodTransfusion.findIndex(val => val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {bloodTransfusion[bloodTransfusion.findIndex(val => val.time_frame === "8 AM to 2 PM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
          {
            bloodTransfusion.findIndex(val => val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {bloodTransfusion[bloodTransfusion.findIndex(val => val.time_frame === "2 PM to 8 PM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
          {
            bloodTransfusion.findIndex(val => val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {bloodTransfusion[bloodTransfusion.findIndex(val => val.time_frame === "8 PM to 12 AM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
        </tr>
        <tr>
          <td className='border'>
            <strong>3.</strong>
          </td>
          <td className='border' >
            <strong >Dressings-Major/Minor</strong>
          </td>
          {
            dressing.findIndex(val => val.time_frame === "12 AM to 8 AM") !== -1 ?
              <td className='border' >
                {dressing[dressing.findIndex(val => val.time_frame === "12 AM to 8 AM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }

          {
            dressing.findIndex(val => val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {dressing[dressing.findIndex(val => val.time_frame === "8 AM to 2 PM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
          {
            dressing.findIndex(val => val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {dressing[dressing.findIndex(val => val.time_frame === "2 PM to 8 PM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
          {
            dressing.findIndex(val => val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {dressing[dressing.findIndex(val => val.time_frame === "8 PM to 12 AM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
        </tr>
        <tr>
          <td className='border'>
            <strong>4.</strong>
          </td>
          <td className='border' >
            <strong >ECG</strong>
          </td>
          {
            ecg.findIndex(val => val.time_frame === "12 AM to 8 AM") !== -1 ?
              <td className='border' >
                {ecg[ecg.findIndex(val => val.time_frame === "12 AM to 8 AM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }

          {
            ecg.findIndex(val => val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {ecg[ecg.findIndex(val => val.time_frame === "8 AM to 2 PM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
          {
            ecg.findIndex(val => val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {ecg[ecg.findIndex(val => val.time_frame === "2 PM to 8 PM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
          {
            ecg.findIndex(val => val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {ecg[ecg.findIndex(val => val.time_frame === "8 PM to 12 AM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
        </tr>
        <tr>
          <td className='border'><strong>5.</strong></td>
          <td className='border'><strong>Nebulization</strong></td>
          {
            nebulization.findIndex(val => val.time_frame === "12 AM to 8 AM") !== -1 ?
              <td className='border' >
                {nebulization[nebulization.findIndex(val => val.time_frame === "12 AM to 8 AM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }

          {
            nebulization.findIndex(val => val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {nebulization[nebulization.findIndex(val => val.time_frame === "8 AM to 2 PM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
          {
            nebulization.findIndex(val => val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {nebulization[nebulization.findIndex(val => val.time_frame === "2 PM to 8 PM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
          {
            nebulization.findIndex(val => val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {nebulization[nebulization.findIndex(val => val.time_frame === "8 PM to 12 AM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
        </tr>
        <tr>
          <td className='border'><strong>6.</strong></td>
          <td className='border'><strong>Portable Chest X ray</strong></td>
          {
            chestXray.findIndex(val => val.time_frame === "12 AM to 8 AM") !== -1 ?
              <td className='border' >
                {chestXray[chestXray.findIndex(val => val.time_frame === "12 AM to 8 AM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }

          {
            chestXray.findIndex(val => val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {chestXray[chestXray.findIndex(val => val.time_frame === "8 AM to 2 PM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
          {
            chestXray.findIndex(val => val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {chestXray[chestXray.findIndex(val => val.time_frame === "2 PM to 8 PM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
          {
            chestXray.findIndex(val => val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {chestXray[chestXray.findIndex(val => val.time_frame === "8 PM to 12 AM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
        </tr>
        <tr>
          <td className='border'><strong>7.</strong></td>
          <td className='border'><strong>Portable ECHO</strong></td>
          {
            echo.findIndex(val => val.time_frame === "12 AM to 8 AM") !== -1 ?
              <td className='border' >
                {echo[echo.findIndex(val => val.time_frame === "12 AM to 8 AM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }

          {
            echo.findIndex(val => val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {echo[echo.findIndex(val => val.time_frame === "8 AM to 2 PM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
          {
            echo.findIndex(val => val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {echo[echo.findIndex(val => val.time_frame === "2 PM to 8 PM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
          {
            echo.findIndex(val => val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {echo[echo.findIndex(val => val.time_frame === "8 PM to 12 AM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
        </tr>
        <tr>
          <td className='border'><strong>8.</strong></td>
          <td className='border'><strong>RBS</strong></td>
          {
            rbs.findIndex(val => val.time_frame === "12 AM to 8 AM") !== -1 ?
              <td className='border' >
                {rbs[rbs.findIndex(val => val.time_frame === "12 AM to 8 AM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }

          {
            rbs.findIndex(val => val.time_frame === "8 AM to 2 PM") !== -1 ?
              <td className='border' >
                {rbs[rbs.findIndex(val => val.time_frame === "8 AM to 2 PM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
          {
            rbs.findIndex(val => val.time_frame === "2 PM to 8 PM") !== -1 ?
              <td className='border' >
                {rbs[rbs.findIndex(val => val.time_frame === "2 PM to 8 PM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
          {
            rbs.findIndex(val => val.time_frame === "8 PM to 12 AM") !== -1 ?
              <td className='border' >
                {rbs[rbs.findIndex(val => val.time_frame === "8 PM to 12 AM")].count}
              </td> :
              <td className='border' >
                -
              </td>
          }
        </tr>
        <tr>
          <td className='border'><strong></strong></td>
          <td className='border' rowSpan={2}>Billing Executive <div>Name :</div>
            <div> Employee ID : </div></td>
          <td className='border'>Staff Name : <div> Employee ID : </div></td>
          <td className='border'>Staff Name : <div> Employee ID : </div></td>
          <td className='border'>Staff Name : <div> Employee ID : </div></td>
          <td className='border'>Staff Name : <div> Employee ID : </div></td>
        </tr>
        <tr>
          <td><strong></strong></td>
          {/* <td colSpan={3}></td> */}
          <td className='border'>Audit Staff Name : <div> Employee ID : </div></td>

          <td></td>
        </tr>
      </table>

      <div className='d-flex flex-row justify-content-end'>
        <button className='saveprintopd ps-3 pe-3 btn-save-fill mt-2' onClick={handlePrint} >
          <img src={printerwhite} className='icnn' alt='' /> {"Print"}
        </button>
      </div>
      {
        showAlertToster === 1 ? <AlertToster handle={setShowAlertToster} message={showAlertMessage} /> : ""
      }
      <Loader val={loader} />

    </div>
  )
}
