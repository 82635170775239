import React, { useState, useEffect } from 'react'
import TableContainer from '../../../../src/Component/TableContainer';
import Heading from '../../../../src/Component/Heading';
import BoxContainer from '../../../../src/Component/BoxContainer';
import Toster from '../../../../src/Component/Toster'
import TosterUnderProcess from '../../../../src/Component/ToStartUnderProcess'
import PostUnitMaster from '../API/PostUnitMaster'
import PutUnitMaster from '../API/PutUnitMaster'
import DeleteUnitMaster from '../API/DeleteUnitMaster'
import ValidationUnitMaster from '../../../../src/Validation/Pharmacy/ValidationUnitMaster'
import GetUnitMaster from '../API/GetUnitMaster';
import IconEdit from '../../../assets/images/icons/IconEdit.svg'
import IconDelete from '../../../assets/images/icons/IconDelete.svg'
import { useTranslation } from 'react-i18next';
import  i18n from "i18next";
import clearIcon from '../../../assets/images/icons/clear.svg';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';


export default function PharmacyUnitMaster() {

  let [unitData, setUnitData] = useState()
  let [updateBool, setUpdateBool] = useState(0)
  let [sendForm, setSendForm] = useState({ "userId": window.userId })
  let [loder, setLoder] = useState(1)
  let [rowId, setRowId] = useState('');

  let [showUnderProcess, setShowUnderProcess] = useState(0)
  let [showToster, setShowToster] = useState(0)
  let [tosterMessage, setTosterMessage] = useState("")
  let [tosterValue, setTosterValue] = useState(0)
  const {t} = useTranslation();
  const [newlyAddedRowIndex, setNewlyAddedRowIndex] = useState(null);
  const [editRowIndex, setEditRowIndex] = useState(null);

  //Handle Save
  let saveForm = async () => {
    let valresponse = ValidationUnitMaster(sendForm.unitName)

    if (valresponse) {
      setShowUnderProcess(1)
      let response = await PostUnitMaster(sendForm);
      console.log("sendForm", sendForm)
      if (response.status === 1) {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage("Data Save Successfully!")
        setTosterValue(0)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
        setNewlyAddedRowIndex(0);
        handleClear();
      }
      else {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage(response.responseValue)
        setTosterValue(1)
        setTimeout(() => {
          setShowToster(0)
          setNewlyAddedRowIndex(null);
        }, 2000)
      }
      getdata()
    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage("Field can't be blank!")
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
    }
  }

  //Get data
  let getdata = async () => {
    let getResponse = await GetUnitMaster();
    console.log("getResponse", getResponse)

    if (getResponse.status === 1) {
      // setLoder(0)
      setUnitData(getResponse.responseValue.table)
    }

  }
  //Handle Change
  let handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setSendForm(sendForm => ({
      ...sendForm,
      [name]: value.trim()
    }))
  }


  //Handle Delete
  let handleDeleteRow = async () => {
    // setLoder(1)
    setShowUnderProcess(1)
    let obj = {
      id: rowId
    }
    let response = await DeleteUnitMaster(obj)
    if (response.status === 1) {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage("Data Deleted Successfully!")
      handleClear()
      setUpdateBool(0)
      setTosterValue(0)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
      getdata()
    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage(response.responseValue)
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
    }
  }

  //Handle Button Change
  let handleUpdate = async (id, unitName, userId, ind) => {

    setUpdateBool(1)
    setSendForm(sendForm => ({
      ...sendForm,
      "id": id,
      "unitName": unitName,
      "userId": userId

    }))
    console.log("sendForm", sendForm);
    document.getElementById("unitName").value = unitName;
    setNewlyAddedRowIndex(ind)
  }



  // Handle Update
  let saveUpdate = async () => {
    let valresponse = ValidationUnitMaster(sendForm.unitName)
    console.log("valresponse", valresponse);
    if (valresponse) {
      setShowUnderProcess(1)
      let response = await PutUnitMaster(sendForm)
      if (response.status === 1) {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage("Data Updated Successfully!")
        setTosterValue(0)
        setTimeout(() => {
          setShowToster(0)
          setNewlyAddedRowIndex(null);
        }, 2000)

        setUpdateBool(0)
        getdata()
        handleClear();
      }
      else {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage(response.responseValue)
        setTosterValue(1)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
      }

    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage("Field can't be blank!") 
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
    }
  }

  //Handle Clear
  let handleClear = () => {
    setSendForm({ "userId": window.userId })
    document.getElementById("unitName").value = "";
    setUpdateBool(0)
    setNewlyAddedRowIndex(null);
  }
  useEffect(() => {
    getdata()
  }, [])
  document.body.dir = i18n.dir();
  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* <Heading text={t("Unit_Master")} /> */}
           

              <div className="med-box">
                        <div className="inner-content">
                           
                                <div className="row">
                                     <div className="fieldsett-in">
                                     <div className='fieldsett'>
                                    <span className="fieldse">{t("Unit_Master")}</span>
                                    <div className="row mt-2 px-2">
                                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <div className="mb-2 me-2">
                         <label htmlFor="unitName" className="form-label">{t("Unit_Name")}<span class="starMandatory">*</span></label>
                       <input type='text' className='form-control form-control-sm' id='unitName' name='unitName' onChange={handleChange} placeholder={t("Enter Unit Name")} />
                                      </div>

                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 ">
                  <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                  <div>
                    {showUnderProcess === 1 ? <TosterUnderProcess /> :
                      <>
                        {showToster === 1 ?
                          <Toster value={tosterValue} message={tosterMessage} />

                          : <div>
                            {updateBool === 0 ?
                              <>
                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={saveForm}><img src={saveButtonIcon} className='icnn' />{t("Save")}</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' />{t("Clear")}</button>
                              </>
                              :
                              <>
                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={saveUpdate}>{t("Update")}</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { setUpdateBool(0); handleClear() }}>{t("Cancel")}</button>
                              </>
                            }
                          </div>}
                      </>
                    }
                  </div>
                                         </div>
                                    
                                      </div>
                                     
                                      </div>
                                      </div>
                                      
                                      </div>
                                      </div>
                                     
                                      </div>

             




            
             
            </div>
            <div className="col-12 mt-2">
              <Heading text={t("Unit_List")} />
              <div className="med-table-section" style={{ "height": "75vh" }}>
                <TableContainer>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>{t("Unit_Name")}</th>
                      <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {unitData && unitData.map((key, ind) => {
                       const isNewRow = newlyAddedRowIndex === ind;
                       const isEditing = ind === editRowIndex;
                      return (
                        <tr className={isNewRow ? 'new-row' : ''} value={key}>
                          <td className="text-center">{ind + 1}</td>
                          <td>{key.unitName}</td>
                          <td>
                            <div className="action-button">
                              <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={IconEdit} className={isEditing ? 'edited-row' : ''} title='Edit' onClick={() => { handleUpdate(key.id, key.unitName, key.userId, ind) }} alt='' /></div>
                              <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={IconDelete} title='Delete' onClick={() => { setRowId(key.id) }} alt='' /></div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}


                  </tbody>
                </TableContainer>
                {/* -----------------------Start Delete Modal Popup-------------------   */}

                {/*  <!-- Modal -->  */}
                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                  <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                      <div className="modal-body modelbdy text-center">
                        <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                        <div className='popDeleteTitle mt-3'>{t("Delete?")}</div>
                        <div className='popDeleteContent'> {t("Are_you_sure_you_want_to_delete?")}</div>
                      </div>
                      <div className="modal-footer1 text-center">

                        <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                        <button type="button" className="btn-delete popBtnDelete" onClick={handleDeleteRow} data-bs-dismiss="modal">{t("Delete")}</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  )
}
