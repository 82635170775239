let getdepartment=async()=>{
    const url=window.AdminbaseUrl+'/api/DepartmentMaster/GetAllDepartmentMaster';
    let head={  'Content-Type': 'application/JSON',accept: '*/*',}
    let response=await fetch (url,{
        method:'GET',
        headers:head
    }).then((res) => res.json())
    .then();
    return response;

}
export default getdepartment;