import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    alertData: []
}

export const AllColumnsParamtersSlice = createSlice({
    name: 'AllColumnsParamters',
    initialState,
    reducers:{
        getAllColumnsParamters(state, action){
            state.alertData = []
            state.alertData = action.payload
            return state
        },
    }
})

export const {getAllColumnsParamters} = AllColumnsParamtersSlice.actions
export default AllColumnsParamtersSlice.reducer