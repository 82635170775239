// async function GetTaskInprogressUser(data) {
//     let url = window.SpringBoardServicesUrl+"api/SprintBacklogMaster/GetTaskAssigneInprogressUserList?ReportDate="+data;
//     let head = { "Content-Type": "application/JSON", accept : '*/*' };

//   let response = fetch(url, {
//     headers: head,
//     method : 'GET'
//   }).then((res) => res.json()).then(data);

//   return response;
// }
// export default GetTaskInprogressUser;

async function GetTaskInprogressUser(data) {
  let url = window.SpringBoardServicesUrl + "api/SprintBacklogMaster/GetTaskAssigneInprogressUserList?ReportDate=" + data;
  let head = { "Content-Type": "application/json", "accept": "*/*" };

  let response = await fetch(url, {
      headers: head,
      method: 'GET'
  });
  
  return response.json();
}

export default GetTaskInprogressUser;