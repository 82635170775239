
import React, { useState, useEffect } from 'react';
import WarningToaster from '../../Component/WarningToaster';
import AlertToster from '../../Component/AlertToster';
import Loder from '../../Component/Loader';
import editbtn from '../../assets/images/icons/editbtn.svg';
import delbtn from '../../assets/images/icons/delbtn.svg';
import reset from "../../assets/images/icons/reset.svg";
import Heading from '../../Component/Heading';
import DropdownWithSearch from '../../Component/DropdownWithSearch';

import GetDiscussion from '../API/Discussion/GetDiscussion'
import SaveDiscussion from '../API/Discussion/SaveDiscussion'
import PutDiscussion from '../API/Discussion/PutDiscussion';
import DeleteDiscussion from '../API/Discussion/DeleteDiscussion'

import GetAgendaForDdl from '../API/Agenda/GetAgendaForDdl';
import GetMeetingForDdl from '../API/Meeting/GetMeetingForDdl'
import GetParticipantForDdl from '../API/Participant/GetParticipantForDdl';
//import GetParticipant from '../API/Participant/GetParticipant';
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import SuccessToster from '../../Component/SuccessToster'
import noData from '../../assets/images/icons/No data-rafiki.svg';


export default function Discussion() {
    const [loder, setLoder] = useState(1)
    const [getAgendaList, setAgendaList] = useState([])
    const [getMeetingList, setMeetingList] = useState([])
    const [getParticipantList, setParticipantList] = useState([])

    const [getSaveUpdateBool, setSaveUpdateBool] = useState(0)

    const [editAgenda, seteditAgenda] = useState("")
    const [editMeeting, seteditMeeting] = useState("")
    const [editParticipant, seteditParticipant] = useState("")

    const [clearDropdown, setClearDropdown] = useState(0)

    const [getSelectedAgenda, setSelectedAgenda] = useState([''])
    const [getSelectedMeeting, setSelectedMeeting] = useState([''])
    const [getSelectedParticipant, setSelectedParticipant] = useState([''])
    const [getDiscussionTopic, setDiscussionTopic] = useState('')
    const [getDiscussionDetails, setDiscussionDetails] = useState('')
    const [getDiscussionList, setDiscussionList] = useState('')


    const [showToster, setShowToster] = useState(0)
    const [message, setMessage] = useState("")
    const [getId, setId] = useState('')
    const [searchInput, setSearchInput] = useState('');
    const { t } = useTranslation();
    const handleChange = async (e) => {
        const { name, value } = e.target;
        if (name === "AgendaID") {
            setSelectedAgenda(value);
            await funGetMeeting(value);
        }
        else if (name === "MeetingID") {
            setSelectedMeeting(value);
            await funGetParticipant(value);
        }
        else {
            setSelectedParticipant(value)
        }
    };

    const funGetAgenda = async () => {
        const { status, responseValue } = await GetAgendaForDdl()
        if (status) {
            setLoder(0)
            setAgendaList(responseValue);
        }
    };

    const funGetMeeting = async (agendaId) => {
        const { status, responseValue } = await GetMeetingForDdl(agendaId, window.userId)
        if (status) {
            setLoder(0);
            setMeetingList(responseValue);
        }
    };

    const funGetParticipant = async (meetingId) => {
        const { status, responseValue } = await GetParticipantForDdl(meetingId, window.userId)
        if (status) {
            setLoder(0)
            setParticipantList(responseValue);
        }
    };


    const clearValues = (value) => {
        setSaveUpdateBool(0);
        setClearDropdown(value);
        setDiscussionTopic('');
        setDiscussionDetails('');
        seteditAgenda("");
        seteditMeeting("");
        seteditParticipant("");
    };

    const handleTextboxChange = (event) => {
        if (event.target.name === "DiscussionTopic") {
            setDiscussionTopic(event.target.value);
        }
        if (event.target.name === "DiscussionDetails") {
            setDiscussionDetails(event.target.value);
        }
    };
    // ---------------------------------------START Save function here-----------------------------------------
    const funGetDiscussion = async () => {
        const { status, responseValue } = await GetDiscussion()
        if (status) {
            setLoder(0)
            setDiscussionList(responseValue);
        }

    }

    // ---------------------------------------Save function here-----------------------------------------
    const funSaveDiscussion = async () => {
        if (getSelectedMeeting === '0' || getSelectedMeeting === undefined || getSelectedMeeting === null || getSelectedMeeting === "") {
            setMessage("Fill Meeting !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSelectedAgenda === '0' || getSelectedAgenda === undefined || getSelectedAgenda === null || getSelectedAgenda === "") {
            setMessage("Fill Agenda !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSelectedParticipant === '0' || getSelectedParticipant === undefined || getSelectedParticipant === null || getSelectedParticipant === "") {
            setMessage("Fill Participant !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getDiscussionTopic === '0' || getDiscussionTopic === undefined || getDiscussionTopic === null || getDiscussionTopic === "") {
            setMessage("Fill Discussion topic !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getDiscussionDetails === '0' || getDiscussionDetails === undefined || getDiscussionDetails === null || getDiscussionDetails === "") {
            setMessage("Fill Discussion details !")
            setShowToster(3)
            setLoder(0)
        }

        else {
            var obj = {
                meetingId: getSelectedMeeting,
                agendaId: getSelectedAgenda,
                participantId: getSelectedParticipant,
                discussionTopic: getDiscussionTopic,
                discussionDetails: getDiscussionDetails,
                userID: window.userId,
                clientId: JSON.parse(window.sessionStorage.getItem('LoginData')).clientId
            }
            setLoder(1)
            let response = await SaveDiscussion(obj);
            if (response.status === 1) {
                setMessage("Data saved successfully !")
                setShowToster(1)
                setLoder(0)
                setSaveUpdateBool(0)
                clearValues(1)
                funGetDiscussion()
            }
            else {
                setLoder(0);
                setMessage(response.responseValue)
                setShowToster(1)
            }
        }
    };
    // ---------------------------------------END Save function here-----------------------------------------
    //---------------------Start Edit and Update function-----------------------------
    const funeditDiscussion = (Id, discussionTopic, discussionDetails, agendaId, agendaTitle, meetingId, meetingTitle, participantId, participantName) => {
        setSaveUpdateBool(1)
        setId(Id);
        setDiscussionTopic(discussionTopic);
        setDiscussionDetails(discussionDetails);
        setSelectedAgenda(agendaId);
        seteditAgenda(agendaTitle);
        setSelectedMeeting(meetingId);
        seteditMeeting(meetingTitle);
        setSelectedParticipant(participantId);
        seteditParticipant(participantName);
    }

    const funUpdateDiscussion = async () => {
        if (getSelectedMeeting === '0' || getSelectedMeeting === undefined || getSelectedMeeting === null || getSelectedMeeting === "") {
            setMessage("Fill Meeting !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSelectedAgenda === '0' || getSelectedAgenda === undefined || getSelectedAgenda === null || getSelectedAgenda === "") {
            setMessage("Fill Agenda !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSelectedParticipant === '0' || getSelectedParticipant === undefined || getSelectedParticipant === null || getSelectedParticipant === "") {
            setMessage("Fill Participant !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getDiscussionTopic === '0' || getDiscussionTopic === undefined || getDiscussionTopic === null || getDiscussionTopic === "") {
            setMessage("Fill Discussion topic !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getDiscussionDetails === '0' || getDiscussionDetails === undefined || getDiscussionDetails === null || getDiscussionDetails === "") {
            setMessage("Fill Discussion details !")
            setShowToster(3)
            setLoder(0)
        }

        else {
            var obj = {
                Id: getId,
                meetingId: getSelectedMeeting,
                agendaId: getSelectedAgenda,
                participantId: getSelectedParticipant,
                discussionTopic: getDiscussionTopic,
                discussionDetails: getDiscussionDetails,
                userID: window.userId,
                clientId: JSON.parse(window.sessionStorage.getItem('LoginData')).clientId
            }
            setLoder(1)
            let response = await PutDiscussion(obj);
            if (response.status === 1) {
                setMessage("Data update successfully !")
                setShowToster(1)
                setLoder(0)
                setSaveUpdateBool(0)
                clearValues(1)
                funGetDiscussion()
            }
            else {
                setLoder(0);
                setMessage(response.responseValue)
                setShowToster(1)
            }
        }
    }
    //---------------------End Edit and Update function-----------------------------
    //---------------------Start Delete function------------------------------------
    const funDeletedDiscussion = async () => {
        var obj = {
            Id: getId,
        }
        setLoder(1)
        const { status, responseValue } = await DeleteDiscussion(obj);
        if (status) {
            setLoder(0)
            setMessage("Data delete successfully !")
            setShowToster(1)
            clearValues()
            funGetDiscussion()
        }
        else {
            setMessage(responseValue)
            setShowToster(1)
            setLoder(0)
        }
    }
    //-----------------End Delete function

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        if (name === 'searchBox') {
            setSearchInput(value)
        }
    }
    // ---------------------------------------END Save function here-----------------------------------------
    useEffect(() => {
        funGetDiscussion();
        funGetAgenda();
    }, []);

    return (
        <>
            <section className="main-content pt-3 mt-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="med-box">
                                <div className="title">Add Discussion</div>
                                <div className="inner-content">
                                    <div className="d-flex flex-wrap align-content-end">
                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="Agenda" className="form-label">Agenda<span className="starMandatory">*</span></label>
                                            {getAgendaList && <DropdownWithSearch defaulNname={t("Select Agenda")} name="AgendaID" list={getAgendaList} valueName="agendaId" displayName="itemTitle" editdata={editAgenda} getvalue={handleChange} clear={clearDropdown} clearFun={clearValues} />}
                                        </div>
                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="meeting" className="form-label">Meeting<span className="starMandatory">*</span></label>
                                            {getMeetingList && <DropdownWithSearch defaulNname={t("Select Meeting")} name="MeetingID" list={getMeetingList} valueName="meetingId" displayName="title" editdata={editMeeting} getvalue={handleChange} clear={clearDropdown} clearFun={clearValues} />}
                                        </div>
                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="participantName" className="form-label">Participant<span className="starMandatory">*</span></label>
                                            {getParticipantList && <DropdownWithSearch defaulNname={t("Select Participant")} name="ActionItemId" list={getParticipantList} valueName="actionItemId" displayName="participantName" editdata={editParticipant} getvalue={handleChange} clear={clearDropdown} clearFun={clearValues} />}
                                        </div>

                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="title" className="form-label">Discussion Topic<span className="starMandatory">*</span></label>
                                            <input type="text" className="form-control form-control-sm" value={getDiscussionTopic} onChange={handleTextboxChange} id="txtDiscussionTopic" name="DiscussionTopic" placeholder='Enter Discussion Topic' />
                                        </div>
                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="title" className="form-label">Discussion Details<span className="starMandatory">*</span></label>
                                            <input type="text" className="form-control form-control-sm" value={getDiscussionDetails} onChange={handleTextboxChange} id="txtDiscussionDetails" name="DiscussionDetails" placeholder='Enter Discussion Details' />
                                        </div>


                                        <div className="mb-2 relative">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                            <div>
                                                {getSaveUpdateBool === 0 ?
                                                    <>
                                                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={funSaveDiscussion}> Save</button>
                                                        {/* <button type="button" className="btn btn-clear btn-sm mb-1 me-1"> Clear </button> */}
                                                        <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={() => { clearValues(1) }}><img src={reset} className='icnn' /> {t("Clear")}</button>
                                                    </>
                                                    :
                                                    <>
                                                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={funUpdateDiscussion}> Update</button>
                                                        <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={() => { setSaveUpdateBool(0); clearValues(1) }}> Clear</button>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-1">
                            <div className='handlser'>
                                <Heading text="Discussion List" />
                                <div style={{ position: 'relative' }}>
                                    <input value={searchInput} onChange={handleOnChange} name="searchBox" type="search" className="form-control form-control-sm" placeholder="Search...." aria-label="Search" aria-describedby="search-addon" />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
                            <div className="med-table-section relative" style={{ "height": "77vh" }}>
                                <table className="med-table border_ striped">
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>S.No.</th>
                                            <th>Agenda</th>
                                            <th>Meeting Title</th>
                                            <th>Participant Name</th>
                                            <th>Discussion Topic</th>
                                            <th>Discussion Details</th>
                                            <th style={{ "width": "10%" }} className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getDiscussionList.length > 0 ? 
                                            getDiscussionList && getDiscussionList.filter((val) => `${val.discussionTopic} ${val.discussionDetails}`.toLowerCase().includes(searchInput.toLowerCase())).map((val, ind) => {

                                            return (
                                                <tr key={val.id}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td>{val.agendaTitle}</td>
                                                    <td>{val.meetingTitle}</td>
                                                    <td>{val.participantName}</td>
                                                    <td>{val.discussionTopic}</td>
                                                    <td>{val.discussionDetails}</td>
                                                    <td>
                                                        <div className="action-button">
                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom" title="Edit Row"><span className='btnbg' style={{ background: "#FFEDD2" }} onClick={() => { funeditDiscussion(val.id, val.discussionTopic, val.discussionDetails, val.agendaId, val.agendaTitle, val.meetingId, val.meetingTitle, val.participantId, val.participantName) }}> <img src={editbtn} className='' /></span></div>
                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" title="Delete Row" data-bs-target="#deleteModal"><span onClick={() => { setId(val.id) }} className='btnbg' style={{ background: "#FFEFEF" }}> <img src={delbtn} className='icnn' /></span ></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <img className='haveNodataImg' src={noData} alt="No Data" />}
                                    </tbody>
                                </table>
                                {/* -----------------------Start Delete Modal Popup-------------------   */}

                                {/*  <!-- Modal -->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">
                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'> Delete?</div>
                                                <div className='popDeleteContent'> Are you sure you want to delete?</div>
                                            </div>
                                            <div className="modal-footer1 text-center">
                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={funDeletedDiscussion} data-bs-dismiss="modal">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}
                            </div>
                        </div>
                    </div>
                </div>
                {showToster === 1 ? <SuccessToster message={message} handle={setShowToster} /> : ""}
                {showToster === 2 ? <WarningToaster message={message} handle={setShowToster} /> : ""}
                {showToster === 3 ? <AlertToster message={message} handle={setShowToster} /> : ""}
            </section>
            <Loder val={loder} />
        </>
    )

}


