import React, { useEffect, useRef, useState } from 'react'
import i18n, { t } from "i18next";
import TableContainer from '../../../Component/TableContainer';
import Heading from '../../../Component/Heading';
import BoxContainer from '../../../Component/BoxContainer';
import clearIcon from '../../../assets/images/icons/clear.svg';
import iconItemPrint from '../../../assets/images/icons/iconItemPrint.svg';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg'
import DropdownWithSearch from '../../../Component/DropdownWithSearch'
import Createitem from '../../../assets/images/dmsimg/creatitemicon.svg'
import InventoryDefaultImage from '../../../assets/images/dmsimg/InventoryDefaultImage.svg'
import GetBrandMaster from '../../API/Master/BrandMaster/GetBrandMaster';
import GetInventoryUnitMaster from '../../API/Master/InventoryUnitMaster/GetInventoryUnitMaster';
import GetInventoryItemMaster from '../../API/CreateNewItem/GetInventoryItemMaster';
import GetUrgencyLevelMaster from '../../API/Master/UrgencyLevelMaster/GetUrgencyLevelMaster';
import GetItemCatgoryMaster from '../../API/ItemCategoryMaster/GetItemCatgoryMaster';
import GetItemSubcategoryMaster from '../../API/ItemSubCategoryMaster/GetItemSubcategoryMaster';
import GetItemTypeMaster from '../../API/Master/ItemTypeMaster/GetItemTypeMaster';
import GetSubscriptionTypeMaster from '../../API/Master/SubscriptionTypeMaster/GetSubscriptionTypeMaster';
import Select from 'react-select';
import PostInventoryItemMaster from '../../API/CreateNewItem/PostInventoryItemMaster';
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Toster from '../../../Component/Toster';
import Loader from '../../../Component/Loader';
import SuccessToster from '../../../Component/SuccessToster';
import AlertToster from '../../../Component/AlertToster';
import FileUpload from '../../../Clinical/API/FileUpload';
import { createLogger } from '@microsoft/signalr/dist/esm/Utils';
import Search from '../../../Code/Serach';
import GetHSNCode from '../../../Billing/API/POST/GetHSNCode';

export default function CreateNewItem() {
    let [itemList, setItemList] = useState("")
    let [itemListMain, setItemListMain] = useState("")
    let [brandList, setBrandList] = useState("")
    let [unitList, setUnitList] = useState("")
    let [urgencyList, setUrgencyList] = useState("")
    let [categoryList, setCategoryList] = useState("")
    let [subCategoryList, setSubCategoryList] = useState("")
    let [itemTypeList, setItemTypeList] = useState("")
    let [subscriptionList, setSubscriptionList] = useState("")
    let [hsnCodeList, setHsnCodeList] = useState("")

    let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [showToster, setShowToster] = useState(0);
    let [tosterMessage, setTosterMessage] = useState("");
    let [tosterValue, setTosterValue] = useState(0);
    let [showLoder, setShowLoder] = useState(0);
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [updateBool, setUpdateBool] = useState(0);
    let [rowId, setRowId] = useState(0);

    let [clearDropdown, setClearDropdown] = useState(0)
    let [editUrgency, setEditUrgency] = useState("")
    let [editCategory, setEditCategory] = useState("")
    let [edithsnCode, setEdithsnCode] = useState("")
    let [editSubCategory, setEditSubCategory] = useState("")
    let [editItemType, setEditItemType] = useState("")
    let [editSubscriptionType, setEditSubscriptionType] = useState("")
    let [unitJSONList, setUnitJSONList] = useState([])
    let [brandJSONList, setBrandJSONList] = useState("")

    const [selectedUnit, setselectedUnit] = useState(null);
    const [selectedUnitList, setselectedUnitList] = useState();
    const [selectedBrand, setselectedBrand] = useState(null);
    const [isClearable,] = useState(true);
    const [isSearchable,] = useState(true);
    let [imagePath, setImagePath] = useState("")
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;

    const [filteredSubCategories, setFilteredSubCategories] = useState([]);
    const [isSubscriptionBased, setIsSubscriptionBased] = useState(false);

    console.log("itemList", itemList)
    let [sendForm, setSendForm] = useState({
        "UserID": window.userId,
        "ClientID": window.clientId,
        "ItemName": "",
        "ItemCode": "",
        "UrgencyLevelID": 0,
        "MinStockQty": "",
        "ItemCategoryID": 0,
        "ItemSubCategoryID": 0,
        "ItemTypeID": 0,
        "SubscriptionTypeID": 0,
        "HsnCodeID": "",
        "GstRatePer": "",
        "Specification": "",
        "IsEquipment": 0,
        "IsReturnable": 0,
        "ItemUnitJson": "",
        "ItemBrandJson": "",
        "formFile": "",
    })

    //Handle Change
    let handleChange = async (e) => {
        clearValidationErrMessage();
        setEditUrgency("");
        setEditCategory("");
        setEdithsnCode("");
        setEditSubCategory("");
        setEditItemType("");
        setEditSubscriptionType("");
        const { name, value, checked } = e.target;
        if (name === "IsEquipment" || name === "IsReturnable") {
            setSendForm((prevData) => ({
                ...prevData,
                [name]: checked,
            }));
        }
        else if (name === "formFile") {
            setSendForm(sendForm => ({
                ...sendForm,
                ["formFile"]: e.target.files[0],
            }))
            setImagePath(e.target.files[0])
        }
        else if (name === "selectedUnitId") {
            setSendForm(sendForm => ({
                ...sendForm,
                ["selectedUnitId"]: value,
            }));
        } else if (name === "HsnCodeID") {
            handleHsnCodeChange(value);
            setSendForm((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
        else {
            setSendForm((prevData) => ({
                ...prevData,
                [name]: value,
                "userId": window.userId,
                "clientId": window.clientId
            }));
        }


        if (name === "ItemCategoryID") {
            setSendForm((prevData) => ({
                ...prevData,
                ItemSubCategoryID: 0
            }));
            filterSubCategories(value);
        }

        if (name === "ItemTypeID") {
            const selectedItemType = itemTypeList.find(type => type.id === parseInt(value));
            if (selectedItemType) {
                setIsSubscriptionBased(selectedItemType.isSubscriptionBased === 1);
            } else {
                setIsSubscriptionBased(false);
            }
            // setEditSubscriptionType("");
            setEditSubscriptionType(0);
        }
    }

    const filterSubCategories = (categoryID) => {
        const filtered = subCategoryList.filter(sub => sub.categoryID === parseInt(categoryID));
        setFilteredSubCategories(filtered);
    };

    const handleSelectBrandChange = (selectedBrandOption, errorElementId, setSelectedFunction) => {
        document.getElementById(errorElementId).style.display = 'none';
        setSelectedFunction(selectedBrandOption);
    };
    const handleSelectChange = (selectedOption, errorElementId, setSelectedFunction) => {
        document.getElementById(errorElementId).style.display = 'none';
        setSelectedFunction(selectedOption);
        setselectedUnitList(selectedOption);
    };
    //Get Data
    const getdata = async () => {
        const [itemResponse, brandResponse, unitResponse, urgencyResponse, categoryResponse, subCategoryResponse, itemTypeResponse, subscriptionResponse] = await Promise.all([
            GetInventoryItemMaster(sendForm.ItemCategoryID ? sendForm.ItemCategoryID : 0, sendForm.UrgencyLevelID ? sendForm.UrgencyLevelID : 0, clientID),
            GetBrandMaster(clientID),
            GetInventoryUnitMaster(clientID),
            GetUrgencyLevelMaster(clientID),
            GetItemCatgoryMaster(clientID),
            GetItemSubcategoryMaster(clientID),
            GetItemTypeMaster(clientID),
            GetSubscriptionTypeMaster(clientID),
        ]);

        if (itemResponse.status === 1) {
            // setShowLoder(0)
            const itemdata = itemResponse.responseValue;
            setItemList(itemdata);
            setItemListMain(itemdata);
        }

        if (brandResponse.status === 1) {
            setBrandList(brandResponse.responseValue.map(val => ({
                value: val.id,
                label: val.brandName
            })));
        }

        if (unitResponse.status === 1) {
            setUnitList(unitResponse.responseValue.map(val => ({
                value: val.id,
                label: val.unitName
            })));
        }

        if (urgencyResponse.status === 1) {
            setUrgencyList(urgencyResponse.responseValue);
        }
        if (categoryResponse.status === 1) {
            setCategoryList(categoryResponse.responseValue);
        }
        if (subCategoryResponse.status === 1) {
            setSubCategoryList(subCategoryResponse.responseValue);
        }
        if (itemTypeResponse.status === 1) {
            setItemTypeList(itemTypeResponse.responseValue);
        }
        if (subscriptionResponse.status === 1) {
            setSubscriptionList(subscriptionResponse.responseValue);
        }
        // else {
        //     setShowLoder(0);
        //     setShowAlertToster(1);
        //     setShowErrMessage(itemResponse.responseValue);
        //     setTimeout(() => {
        //         setShowAlertToster(0);
        //     }, 1500)
        // }
    }

    //get HSNCOde
    const getHSNCode = async () => {
        const response = await GetHSNCode(clientID);
        if (response.status === 1) {
            setHsnCodeList(response.responseValue);
        }
    }
    //Get GSTRate Per from HSNCode
    const handleHsnCodeChange = (hsnCodeId) => {
        const selectedHsnCode = hsnCodeList.find(hsn => hsn.id === parseInt(hsnCodeId));
        if (selectedHsnCode) {
            setSendForm((prevData) => ({
                ...prevData,
                GstRatePer: selectedHsnCode.gst
            }));
        }
    };

    //handle search
    let handleSearch = (e) => {
        let resp = Search(itemListMain, e.target.value)
        if (e.target !== "") {
            if (resp.length !== 0) {
                setItemList(resp)
            }
            else {
                setItemList([])
            }
        }
        else {
            setItemList(itemListMain)
        }
    }


    //Handle Save
    const handlerSave = async () => {
        // console.log('sendForm', sendForm)
        const IsEquipmentData = document.getElementById("IsEquipment").checked;
        const IsReturnableData = document.getElementById("IsReturnable").checked;
        let itemUnitSelected = selectedUnit?.map((unit) => ({
            unitID: unit.value,
            isDefault: unit.value === parseInt(sendForm.selectedUnitId || 0) ? 1 : 0,
        }));
        // console.log('itemUnitSelected', itemUnitSelected)
        let itemBrandSelected = selectedBrand?.map((brand) => ({
            brandId: brand.value,
        }));
        if (sendForm.ItemName === '' || sendForm.ItemName === null || sendForm.ItemName === undefined) {
            document.getElementById('errItemName').innerHTML = "Item name is required.";
            document.getElementById('errItemName').style.display = "block";
        }
        // else if (sendForm.ItemCode === '' || sendForm.ItemCode === null || sendForm.ItemCode === undefined) {
        //     document.getElementById('errItemCode').innerHTML = "Item code is required.";
        //     document.getElementById('errItemCode').style.display = "block";
        // }
        else if (selectedBrand === null || undefined) {
            document.getElementById('errBrand').innerHTML = 'Please select brand.';
            document.getElementById('errBrand').style.display = 'block';
        }
        else if (selectedUnit === null || undefined) {
            document.getElementById('errUnit').innerHTML = 'Please select unit.';
            document.getElementById('errUnit').style.display = 'block';
        }

        else if (sendForm.selectedUnitId === '' || sendForm.selectedUnitId === 0 || sendForm.selectedUnitId === null || sendForm.selectedUnitId === undefined) {
            document.getElementById('errdefaultUnit').innerHTML = "Please select default unit";
            document.getElementById('errdefaultUnit').style.display = "block";
        }
        else if (sendForm.UrgencyLevelID === '' || sendForm.UrgencyLevelID === 0 || sendForm.UrgencyLevelID === null || sendForm.UrgencyLevelID === undefined) {
            document.getElementById('errSeverity').innerHTML = "Please select severity";
            document.getElementById('errSeverity').style.display = "block";
        }
        else if (sendForm.MinStockQty === '' || sendForm.MinStockQty === null || sendForm.MinStockQty === undefined) {
            document.getElementById('errStockQty').innerHTML = "minimum stock quantity is required.";
            document.getElementById('errStockQty').style.display = "block";
        }
        else if (sendForm.ItemCategoryID === '' || sendForm.ItemCategoryID === 0 || sendForm.ItemCategoryID === null || sendForm.ItemCategoryID === undefined) {
            document.getElementById('errCategory').innerHTML = "Please select category.";
            document.getElementById('errCategory').style.display = "block";
        }
        else if (sendForm.ItemTypeID === '' || sendForm.ItemTypeID === 0 || sendForm.ItemTypeID === null || sendForm.ItemTypeID === undefined) {
            document.getElementById('errItemType').innerHTML = "Please select item type.";
            document.getElementById('errItemType').style.display = "block";
        }
        else if (sendForm.HsnCodeID === '' || sendForm.HsnCodeID === null || sendForm.HsnCodeID === undefined) {
            document.getElementById('errHsn').innerHTML = "HSN code is required.";
            document.getElementById('errHsn').style.display = "block";
        }
        // else if (sendForm.GstRatePer === '' || sendForm.GstRatePer === null || sendForm.GstRatePer === undefined) {
        //     document.getElementById('errgst').innerHTML = "GST percentage is required.";
        //     document.getElementById('errgst').style.display = "block";
        // }
        else {
            // setShowUnderProcess(1);
            const itemObj = {
                "ItemName": sendForm.ItemName,
                "ItemCode": sendForm.ItemCode,
                "UrgencyLevelID": sendForm.UrgencyLevelID,
                "MinStockQty": sendForm.MinStockQty,
                "ItemCategoryID": sendForm.ItemCategoryID,
                "ItemSubCategoryID": sendForm.ItemSubCategoryID,
                "ItemTypeID": sendForm.ItemTypeID,
                "SubscriptionTypeID": sendForm.SubscriptionTypeID,
                "HsnCodeID": sendForm.HsnCodeID,
                "GstRatePer": sendForm.GstRatePer,
                "Specification": sendForm.Specification,
                "formFile": imagePath,
                "IsEquipment": IsEquipmentData,
                "IsReturnable": IsReturnableData,
                "ItemUnitJson": JSON.stringify(itemUnitSelected),
                "ItemBrandJson": JSON.stringify(itemBrandSelected),
                "userID": window.userId,
                "clientID": clientID
            };
            console.log("itemObj", itemObj);
            // return;
            const response = await PostInventoryItemMaster(itemObj);

            if (response.data.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Data saved successfully..");
                setTimeout(() => {
                    setShowToster(0);
                    handleClear();
                    getdata();
                }, 2000);
            } else {
                setShowLoder(0);
                setShowAlertToster(1);
                setShowErrMessage(response.responseValue);
                setTimeout(() => {
                    setShowAlertToster(0);
                }, 1500);
            }
        }
    };




    //Clear Error Message
    const clearValidationErrMessage = () => {
        document.getElementById('errItemName').style.display = 'none';
        document.getElementById('errItemCode').style.display = 'none';
        document.getElementById('errBrand').style.display = 'none';
        document.getElementById('errUnit').style.display = 'none';
        document.getElementById('errSeverity').style.display = 'none';
        document.getElementById('errStockQty').style.display = 'none';
        document.getElementById('errCategory').style.display = 'none';
        document.getElementById('errItemType').style.display = 'none';
        document.getElementById('errHsn').style.display = 'none';
        document.getElementById('errgst').style.display = 'none';
        document.getElementById('errdefaultUnit').style.display = 'none';
    }
    //Handle Clear
    let handleClear = (value) => {
        clearValidationErrMessage();
        setClearDropdown(value);
        setEditUrgency(0);
        setEditCategory(0);
        setEdithsnCode(0);
        // setEditSubCategory(0);
        setEditItemType(0);
        setEditSubscriptionType(0);
        setselectedUnit(null);
        setselectedBrand(null);
        setselectedUnitList("")
        setImagePath("");
        setSendForm({
            "userID": window.userId,
            "clientID": window.clientId
        })
        document.getElementById("ItemName").value="";
        document.getElementById("ItemCode").value="";
        document.getElementById("MinStockQty").value="";
        document.getElementById("GstRatePer").value="";
        document.getElementById("Specification").value="";
        document.getElementById("IsEquipment").checked = false;
        document.getElementById("IsReturnable").checked = false;
        document.getElementById("formFile").value = "";
        setUpdateBool(0)
    }

    const printRef = useRef();
    const handlePrint = () => {
        const printContents = printRef.current.innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    };

    useEffect(() => {
        getdata();
        getHSNCode();
    }, [])
    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 mt-2">
                            <div className='handlser serinventory med-box'>
                                <div className='demandMain-inn p-2'>
                                    <div className="title mt-2 pe-2 ps-2 bordertitle">{t("Item List")}</div>
                                </div>
                                <div className='serchbtn-sec'>
                                    <div style={{ position: 'relative' }}>
                                        <input type="text" className='form-control form-control-sm' placeholder={t("Search")} onChange={handleSearch} />
                                        <span className="sericnInventory"><i className="fas fa-search"></i></span>
                                    </div>
                                    <div data-bs-toggle="modal" data-bs-target="#itemDetailsBackdrop">
                                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1"><i class="bi bi-plus-circle-dotted"></i> {t("Create New Item")}

                                            {/* <i class="bi bi-file-plus ms-1"></i> */}
                                            {/* <img src={Createitem} className='icnn' alt='' />  */}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="med-table-section ps-2 pe-2" style={{ "height": "84vh" }}>
                                <table className='med-table thinventory striped'>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>#</th>
                                            <th>Image</th>
                                            <th>Item Name</th>
                                            <th>Brand</th>
                                            <th>Item Code</th>
                                            <th>Default Unit</th>
                                            <th>Severity</th>
                                            <th>Category</th>
                                            <th>Min. Qty.</th>
                                            {/* <th>Unit</th>
                                            <th>Sub Category</th>
                                            <th>Item Type</th>
                                            <th>Subscription Type</th>
                                            <th>HSN Code</th>
                                            <th>GST Rate (%)</th>
                                            <th>Specification</th>
                                            <th>Is Equipment?</th> */}
                                            <th style={{ "width": "10%" }} className="text-center">Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {itemList && itemList.length > 0 ? (
                                            itemList.map((list, ind) => (

                                                <tr key={list.id}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td style={{ minWidth: '110px' }}>
                                                        <div className='invImg'>
                                                            <img src={list.imgURL ? `${window.InventoryBaseUrl}/${list.imgURL}` : `${InventoryDefaultImage}`} alt='' />
                                                        </div>
                                                    </td>
                                                    <td>{list.itemName}</td>
                                                    <td style={{ minWidth: '180px' }}> {list.jsonItemBrand && JSON.parse(list.jsonItemBrand).map((unit, ind) => {
                                                        return (
                                                            <span key={unit.BrandId}>{unit.BrandName}  {JSON.parse(list.jsonItemBrand).length !== 0 && ind + 1 !== JSON.parse(list.jsonItemBrand).length ? "," : ""} </span>
                                                        )
                                                    })}</td>
                                                    <td>{list.itemCode}</td>
                                                    <td style={{ minWidth: '180px' }}>
                                                        {list.jsonItemUnit && JSON.parse(list.jsonItemUnit).map((unit, ind) => {
                                                            return (
                                                                <span key={unit.UnitId}>
                                                                    {unit.isDefault === 1 ? unit.UnitName : ''}
                                                                </span>
                                                            )
                                                        })}
                                                    </td>
                                                    <td>{list.urgencyLevelName}</td>
                                                    <td>{list.categoryName}</td>
                                                    <td className="text-center">{list.minStockQty}</td>

                                                    {/* <td style={{ minWidth: '180px' }}>
                                                        {list.jsonItemUnit && JSON.parse(list.jsonItemUnit).map((unit, ind) => {
                                                            return (
                                                                <span key={unit.UnitId}>{unit.UnitName}  {JSON.parse(list.jsonItemUnit).length !== 0 && ind + 1 !== JSON.parse(list.jsonItemUnit).length ? "," : ""} </span>
                                                            )
                                                        })}
                                                    </td>
                                                    <td>{list.subCategoryName}</td>
                                                    <td>{list.itemType}</td>
                                                    <td>{list.subcriptionName}</td>
                                                    <td className="text-center">{list.hsnCode}</td>
                                                    <td className="text-center">{list.gstRatePer}</td>
                                                    <td>{list.specification}</td>
                                                    <td className="text-center">{list.isEquipment === 1 ? "Yes" : "No"}</td> */}


                                                    <td className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <span data-bs-toggle="modal" data-bs-target={"#itemDetailsBackdrop" + ind}>
                                                            <i className="fa fa-eye actionedit viewaction "></i>
                                                        </span>

                                                        {/* <div className="modal fade" id={"itemDetailsModal" + ind} tabIndex="-1" aria-labelledby="itemDetailsModalLabel" aria-hidden="true"> */}
                                                        <div className="modal fade" id={"itemDetailsBackdrop" + ind} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="itemDetailsBackdropLabel" aria-hidden="true">
                                                            <div className="modal-dialog  modal-lg additonaldetails" style={{ margin: '5% auto' }}>
                                                                <div className="modal-content position-relative" style={{ background: '#F9FAFC' }}>
                                                                    <div className="modal-header_ bg-clrr mt-0 pt-0 mb-0 pb-0">
                                                                        {/* <div className="heading text-wrap">Item Details</div> */}
                                                                        {/* <div>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                        </div> */}
                                                                        <div className="modalheading">
                                                                            <i className="fa fa-times" data-bs-dismiss="modal" aria-label="Close"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-body mt-0 pt-0">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <div className="itemMedBox">
                                                                                    <div className='itempopupheadr'>
                                                                                        <div className='demandMain-inn'>
                                                                                            <div className="title ps-2 bordertitle">{t("Item Details")}</div>
                                                                                        </div>
                                                                                        {/* <div onclick={handlePrint} style={{ cursor: 'pointer' }}>
                                                                                            <span className='itemprint'>Print Product Details</span>
                                                                                            <span><img src={iconItemPrint} alt='' /></span>
                                                                                        </div> */}
                                                                                    </div>
                                                                                    <div className="inner-content mt-2" id='printsection' ref={printRef}>
                                                                                        <div className='row'>
                                                                                            <div className='col-4'>
                                                                                                <div className='prodctdtls'>
                                                                                                    <label>Product Image</label>
                                                                                                    <img src={list.imgURL ? `${window.InventoryBaseUrl}/${list.imgURL}` : `${InventoryDefaultImage}`} alt='' />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='col-8'>
                                                                                                <div className="med-table-section_ alldemandtbl_">
                                                                                                    <table className='med-table border_ striped_'>
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Item Name</label>
                                                                                                                        <span>{list.itemName}</span>
                                                                                                                    </div>

                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Item Code</label>
                                                                                                                        <span>{list.itemCode}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Brands</label>
                                                                                                                        <span>
                                                                                                                            {list.jsonItemBrand && JSON.parse(list.jsonItemBrand).map((unit, ind) => {
                                                                                                                                return (
                                                                                                                                    <span key={unit.BrandId}>{unit.BrandName}  {JSON.parse(list.jsonItemBrand).length !== 0 && ind + 1 !== JSON.parse(list.jsonItemBrand).length ? "," : ""} </span>
                                                                                                                                )
                                                                                                                            })}
                                                                                                                        </span>
                                                                                                                    </div>

                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Unit</label>
                                                                                                                        <span>
                                                                                                                            {list.jsonItemUnit && JSON.parse(list.jsonItemUnit).map((unit, ind) => {
                                                                                                                                return (
                                                                                                                                    <span key={unit.UnitId}>{unit.UnitName}  {JSON.parse(list.jsonItemUnit).length !== 0 && ind + 1 !== JSON.parse(list.jsonItemUnit).length ? "," : ""} </span>
                                                                                                                                )
                                                                                                                            })}
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Default Unit</label>
                                                                                                                        <span>
                                                                                                                            {list.jsonItemUnit && JSON.parse(list.jsonItemUnit).map((unit, ind) => {
                                                                                                                                return (
                                                                                                                                    <span key={unit.UnitId}>
                                                                                                                                        {unit.isDefault === 1 ? unit.UnitName : ''}
                                                                                                                                    </span>
                                                                                                                                )
                                                                                                                            })}
                                                                                                                        </span>
                                                                                                                    </div>

                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Severity</label>
                                                                                                                        <span>{list.urgencyLevelName}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Min. Stock Qty.</label>
                                                                                                                        <span>{list.minStockQty}</span>
                                                                                                                    </div>

                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Item Type</label>
                                                                                                                        <span>{list.itemType}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Category</label>
                                                                                                                        <span>{list.categoryName}</span>
                                                                                                                    </div>

                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Sub-Category</label>
                                                                                                                        <span>{list.subCategoryName}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Subscription Type</label>
                                                                                                                        <span>{list.subcriptionName}</span>
                                                                                                                    </div>

                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>HSN Code</label>
                                                                                                                        <span>{list.hsnCode}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>GST Rate (%)</label>
                                                                                                                        <span>{list.gst}</span>
                                                                                                                    </div>

                                                                                                                </td>
                                                                                                                <td>

                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Is Equipment?</label>
                                                                                                                        <span>{list.isEquipment === 1 ? "Yes" : "No"}</span>
                                                                                                                    </div>

                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Is Returnable?</label>
                                                                                                                        <span>{list.isReturnable === 1 ? "Yes" : "No"}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td colSpan={2}>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Specification</label>
                                                                                                                        <span>{list.specification}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4" className="text-center">
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>

                                </table>
                                {/* -----------------------Start Delete Modal Popup-------------------   */}

                                {/*  <!-- Modal -->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">

                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'>Delete?</div>
                                                <div className='popDeleteContent'>Are you sure want to delete?</div>
                                            </div>
                                            <div className="modal-footer1 text-center">

                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={'handleDelete'} data-bs-dismiss="modal">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

                            </div>
                        </div>
                    </div>
                </div>

                {/* --------------------------------------------Start Create New Item Popup-------------------------------------- */}
                <div className="modal fade" id="itemDetailsBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="itemDetailsBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-xl additonaldetails" style={{ margin: '5% auto' }}>
                        <div className="modal-content">
                            {/* <div className="modal-header_ bg-clrr mt-0 pt-0 mb-0 pb-0">
                                <div className="heading text-wrap">
                                    <div className='demandMain-inn p-2'>
                                        <div className="title pe-2 ps-2 bordertitle">{t("Create New Item")}</div>
                                    </div>
                                </div>
                                <div>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div> */}
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="inventoryMedBox">
                                            <div className='popupheadr'>
                                                <div className='demandMain-inn'>
                                                    <div className="title ps-2 bordertitle">{t("Create New Item")}</div>
                                                </div>
                                                <div>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                            </div>


                                            <div className="inner-content mt-2">
                                                <div className='row'>
                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2">
                                                        <label htmlFor="ItemName" className="form-label">{t("Item Name")}<span className="starMandatory">*</span></label>
                                                        <input type="text" className="form-control form-control-sm" id="ItemName" name="ItemName" onChange={handleChange} value={sendForm.ItemName} placeholder="Enter item name" />
                                                        <small id="errItemName" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </div>

                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2" >
                                                        <label htmlFor="ItemCode" className="form-label">{t("Inventory Item Code")}<span className="starMandatory"></span></label>
                                                        <input type="text" className="form-control form-control-sm" id="ItemCode" name="ItemCode" onChange={handleChange} value={sendForm.ItemCode} placeholder="Item Code" />
                                                        <small id="errItemCode" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </div>
                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2" >
                                                        <label htmlFor="UrgencyLevelID" className="form-label">{t("Brands")}<span className="starMandatory">*</span></label>
                                                        <Select value={selectedBrand} placeholder="Select Brand" isMulti options={brandList} className="create-select create-select-overflow" id="brandID" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedBrandOption => handleSelectBrandChange(selectedBrandOption, "errBrand", setselectedBrand)} />
                                                        <small id="errBrand" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </div>

                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2" >
                                                        <label htmlFor="" className="form-label">{t("Unit")}<span className="starMandatory">*</span></label>
                                                        <Select value={selectedUnit} placeholder="Select Unit" isMulti options={unitList} className="create-select create-select-overflow" id="unitID" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, "errUnit", setselectedUnit)} />
                                                        <small id="errUnit" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </div>
                                                    {/* {console.log("selectedUnitList", selectedUnitList)}
                                        {console.log("selectedUnitId--", sendForm.selectedUnitId)} */}
                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2" >
                                                        <label htmlFor="selectedUnitId" className="form-label">{t("Default Unit")}<span className="starMandatory">*</span></label>
                                                        <select className="form-select form-select-sm" id="selectedUnitId" name='selectedUnitId' value={sendForm.selectedUnitId} aria-label=".form-select-sm example" onChange={handleChange}>
                                                            <option value="0">Select Default Unit</option>
                                                            {selectedUnitList && selectedUnitList.map((list) => {
                                                                return (
                                                                    <option value={list.value}>{list.label}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        <small id="errdefaultUnit" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </div>

                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2" >
                                                        <label htmlFor="UrgencyLevelID" className="form-label">{t("Severity Category")}<span className="starMandatory">*</span></label>
                                                        {urgencyList && <DropdownWithSearch defaulNname={t("Select Severity")} name="UrgencyLevelID" list={urgencyList} valueName="id" displayName="urgencyLevelName" editdata={editUrgency} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                                        <small id="errSeverity" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </div>



                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2" >
                                                        <label htmlFor="ItemCategoryID" className="form-label">{t("Category")}<span className="starMandatory">*</span></label>
                                                        {categoryList && <DropdownWithSearch defaulNname={t("Select Category")} name="ItemCategoryID" list={categoryList} valueName="id" displayName="categoryName" editdata={editCategory} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                                        <small id="errCategory" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </div>
                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2" >
                                                        <label htmlFor="ItemSubCategoryID" className="form-label">{t("Sub Category")}<span className="starMandatory"></span></label>
                                                        {/* {filteredSubCategories && <DropdownWithSearch defaulNname={t("Select Sub Category")} name="ItemSubCategoryID" list={filteredSubCategories} valueName="id" displayName="subCategoryName?subsubCategoryName:<div>No subcategories available</div>" editdata={editSubCategory} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />} */}
                                                        {filteredSubCategories.length > 0 ? (
                                                            <DropdownWithSearch defaulNname={t("Select Sub Category")} name="ItemSubCategoryID" list={filteredSubCategories} valueName="id" displayName="subCategoryName" editdata={editSubCategory} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />
                                                        ) : (
                                                            <div className='notavlble'>No subcategories available</div>
                                                        )}
                                                    </div>
                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2" >
                                                        <label htmlFor="ItemTypeID" className="form-label">{t("Item Type")}<span className="starMandatory">*</span></label>
                                                        {itemTypeList && <DropdownWithSearch defaulNname={t("Select Item Type")} name="ItemTypeID" list={itemTypeList} valueName="id" displayName="itemType" editdata={editItemType} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                                        <small id="errItemType" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2" >
                                                        <label htmlFor="MinStockQty" className="form-label">{t("Minimum Stock Quantity")}<span className="starMandatory">*</span></label>
                                                        <input type="number" className="form-control form-control-sm" id="MinStockQty" name="MinStockQty" onChange={handleChange} value={sendForm.MinStockQty} placeholder="Enter Minimum Stock Quantity" />
                                                        <small id="errStockQty" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </div>


                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2" >
                                                        <label htmlFor="SubscriptionTypeID" className="form-label">{t("Subscription Type")}<span className="starMandatory"></span></label>
                                                        {subscriptionList && <DropdownWithSearch defaulNname={t("Select Subscription Type")} name="SubscriptionTypeID" list={subscriptionList} valueName="id" displayName="subcriptionName" editdata={editSubscriptionType} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} disabled={!isSubscriptionBased}/>}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2" >
                                                        <label htmlFor="HsnCodeID" className="form-label">{t("HSN Code")}<span className="starMandatory">*</span></label>
                                                        {hsnCodeList && <DropdownWithSearch defaulNname={t("Select Category")} name="HsnCodeID" list={hsnCodeList} valueName="id" displayName="hsnCode" editdata={edithsnCode} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                                        {/* <input type='number' className='form-control form-control-sm' id='hsnCode' name='hsnCode' value={sendForm.hsnCode} onChange={handleChange} placeholder='Enter HSN Code' /> */}
                                                        <small id="errHsn" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </div>
                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2" >
                                                        <label htmlFor="GstRatePer" className="form-label">{t("GST Rate (%)")}<span className="starMandatory"></span></label>
                                                        {/* <input type='number' className='form-control form-control-sm' name='GstRatePer' id='GstRatePer' value={sendForm.GstRatePer} onChange={handleChange} placeholder=" Enter GST %" /> */}
                                                        <input type="number" className="form-control form-control-sm" name="GstRatePer" id='GstRatePer' value={sendForm.GstRatePer} onChange={handleChange} readOnly />
                                                        <small id="errgst" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-2" >
                                                        <label htmlFor="Specification" className="form-label">{t("Specification")}<span className="starMandatory"></span></label>
                                                        <input type="text" className="form-control form-control-sm" id="Specification" name="Specification" value={sendForm.Specification} onChange={handleChange} placeholder="Select specification" />
                                                    </div>

                                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-2">
                                                        <div className="accordion-body inventoryChk mt-4">
                                                            <div className="dflex">
                                                                <div className='pe-2'>
                                                                    <label className="form-label" for="IsEquipment">Is Equipment? <span className="starMandatory"></span></label>
                                                                </div>
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" role="switch" id="IsEquipment" name='IsEquipment' />
                                                                </div>
                                                            </div>
                                                            <div className="dflex">
                                                                <div className='pe-2'>
                                                                    <label className="form-label" for="IsReturnable">Is Returnable? <span className="starMandatory"></span></label>
                                                                </div>
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" role="switch" id="IsReturnable" name='IsReturnable' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2 mt-4" >
                                                        <label htmlFor="formFile" className="form-label">{t("Upload Image")}<span className="starMandatory"></span></label>
                                                        <input type="file" className='form-control form-control-sm' id='formFile' name="formFile" value={sendForm.imgURL} onChange={handleChange} placeholder='Upload Image' />
                                                        {/* <input type="file" className='form-control form-control-sm' id='imgURL' name="imgURL" value={sendForm.imgURL} onChange={handleChange} placeholder='Upload Image' /> */}
                                                    </div>
                                                </div>





                                                <div className="relative">
                                                    <hr />
                                                    <div className="d-flex flex-wrap align-content-end justify-content-end">
                                                        <div className="mb-2 relative">
                                                            {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                                                <>
                                                                    {showToster === 1 ?
                                                                        <Toster value={tosterValue} message={tosterMessage} />
                                                                        : <div>
                                                                            {updateBool === 0 ?
                                                                                <>
                                                                                    <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={() => { handleClear(1) }} ><img src={clearIcon} className='icnn' alt='' />{t("Clear")}</button>
                                                                                    <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handlerSave}>{t("Create Item")} <img src={Createitem} className='icnn' alt='' /> </button>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={'handlerUpdate'}>Update</button>
                                                                                    <button type="button" className="btn btn-clear btn-sm mb-1" onClick={handleClear}>Cancel</button>
                                                                                </>
                                                                            }
                                                                        </div>}
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* --------------------------------------------End Create New Item Popup-------------------------------------- */}



                {
                    showLoder === 1 ? <Loader val={showLoder} /> : ""
                }
                {/* Toaster */}
                {
                    isShowToaster === 1 ?
                        <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                }

                {
                    showAlertToster === 1 ?
                        <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                }
            </section>
        </>
    )
}
