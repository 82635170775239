import React, { useEffect, useState } from 'react';
// import GetDiseaseList from '../API/GET/GetDiseaseList';
// import GetSurgeryList from '../API/GET/GetSurgeryList';
// import SaveSurgeryMaster from '../API/POST/SaveSurgeryMaster';
// import UpdateSurgeryMaster from '../API/UPDATE/UpdateSurgeryMaster';
// import DeleteSurgery from '../API/DELETE/DeleteSurgery';
import Loder from '../../Component/Loader';
import Select from 'react-select';
import SuccessToster from "../../Component/SuccessToster";
import AlertToster from "../../Component/AlertToster";
import TosterUnderProcess from '../../Component/TosterUnderProcess';
import Toster from '../../Component/Toster';
import saveBtnIcon from '../../assets/images/icons/saveButton.svg';
import clearBtnIcon from '../../assets/images/icons/clear.svg';
import deleteBtnIcon from '../../assets/images/icons/delete.svg';
import editBtnIcon from '../../assets/images/icons/edit.svg';
import Heading from "../../Component/Heading";
//Api Import
import getItems from '../API/getItems';
import SaveItemMaster from '../API/POST/SaveItems';
import UpdateItemMaster from '../API/POST/UpdateItemMaster';
import DeleteItem from '../API/POST/DeleteItem';
import GetHSNCode from '../API/POST/GetHSNCode';
import GetHsnCodeDetailsByID from '../API/GetHsnCodeDetailsByID';
import GetCategoryList from '../API/GetCategoryList';
//End Api Import
export default function SurgeryMaster() {
    let [isUpdateBtnShow, setIsUpdateBtnShow] = useState(false);
    let [itemName, setitemName] = useState('');
    let [itemCharge, setitemCharge] = useState('');
    let [showSuccessToster, setShowSuccessToster] = useState(0)
    let [itemChargewithoutGst, setitemChargewithoutGst] = useState('');
    let [selectedCategory, setselectedCategory] = useState('');
    let [showMessage, setShowMeassage] = useState("");
    let [rateIncluding, setrateIncluding] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [rateExcluding, setrateExcluding] = useState(0);
    let [hsnCode, sethsnCode] = useState(null);
   
    let [itemList, setitemList] = useState([]);
    let [HSNCodeDropdown, setHSNCodeDropdown] = useState([]);
    let [CategoryList, setCategoryList] = useState([]);
    let [rowID, setRowID] = useState([]);
    let [showLoder, setShowLoder] = useState(0);
    let [isIncludegstratesDisabled, setisIncludegstratesDisabled] = useState(true);
    let [isExcludegstratesDisabled, setisExcludegstratesDisabled] = useState(false);
   
    let [showUnderProcess, setShowUnderProcess] = useState(0)
    let [showToster, setShowToster] = useState(0)
    let [tosterMessage, setTosterMessage] = useState("")
    let [itemwithGST, setitemwithGST] = useState("")
    let [GST, setGST] = useState(0)
    let [tosterValue, setTosterValue] = useState(0)
    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [searchInput, setsearchInput] = useState("");
    const [newlyAddedRowIndex, setNewlyAddedRowIndex] = useState(null);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [isNewRowAdded, setIsNewRowAdded] = useState(false);

    let handlerChange = async (e) => {
        document.getElementById('errItemTitile').style.display = "none";


        if (e.target.name === "itemName") {
            setitemName(e.target.value)
        }

        if (e.target.name === "itemwithGST") {
         
            if(isIncludegstratesDisabled == false && rateIncluding == 1){
                setitemwithGST(e.target.value)
                // setitemChargewithoutGst(parseFloat(parseInt(e.target.value) - ( (GST / 100) * parseInt(e.target.value))).toFixed(2))
                setitemChargewithoutGst(parseFloat((parseInt(e.target.value)).toFixed(2)/(1+GST/100)).toFixed(2))
            }
     


        }

          if (e.target.name === "itemChargewithoutGst") {
            const checkLength = e.target.value;
            if (checkLength.length > 8) {
                return false
        
              }
            setitemChargewithoutGst(e.target.value)
            setitemwithGST(parseFloat(parseInt(e.target.value) + ( (GST / 100) * parseInt(e.target.value))).toFixed(2))
        
        
       
        // if(e.target.value == "" || undefined || null){
        //     setitemwithGST(0)
        // }
      
       
    }
    

    else if (e.target.name  === 'searchBox') {
        setsearchInput(e.target.value)
      }

    else if (e.target.name  === 'category') {
        setselectedCategory(e.target.value)
      }

}

    const handleSelectChange = async(selectedOption, errorElementId, setSelectedFunction) => {
        document.getElementById(errorElementId).style.display = 'none';
        setitemChargewithoutGst('')
        setitemwithGST('')
        setSelectedFunction(selectedOption);
        if(selectedOption !== null){
            let data = await GetHsnCodeDetailsByID(selectedOption.value)

            if(data.status === 1){
                console.log("hsnCodeid",data.responseValue)
                setGST(data.responseValue[0].gst)
             
              }
        }

        else{
            setGST(0)
            } 
    };

    let HSNCodeList = async () => {
        let data = await GetHSNCode();
        setHSNCodeDropdown(data.responseValue.map(val =>({
       value : val.id,
       label : val.hsnCode
        })));
        console.log('data', data);
    }


    let getAllItemList = async () => {
        setShowLoder(1)
        let data = await getItems();
        if (data.status === 1) {
            setShowLoder(0)
            setitemList(data.responseValue);
        }
        else {
            setShowLoder(0);
         }
    }

    let handlerSave = async () => {
     
        document.getElementById('errItemTitile').style.display = "none";
  

        if (itemName.trim() === '' || itemName === null || itemName === undefined) {
            setShowMeassage("Please Enter Item Name");
            setShowAlertToster(1);
            return;
        }
        // if (hsnCode === null) {
        //     setShowMeassage("Please Select HSN Code");
        //     setShowAlertToster(1);
        //     return false;
        // }
  if( selectedCategory == '' ||selectedCategory ==  undefined) {
            setShowMeassage("Please Select Category");
            setShowAlertToster(1);
            return false;
        }
  

        if( itemChargewithoutGst == '' ||itemChargewithoutGst === undefined) {
            setShowMeassage("Please Enter Item Rate Excluding GST");
            setShowAlertToster(1);
            return false;
        }
      
     


       
        else {
            setShowUnderProcess(1);
            var obj = {
                itemName: itemName,
                status: true,
                itemCharge : parseFloat(itemChargewithoutGst).toFixed(2),
                userId: JSON.parse(window.sessionStorage.getItem("LoginData")).userId,
                categoryId: parseInt(selectedCategory),
                hsnCodeId: hsnCode && hsnCode.value !== null ? hsnCode.value : 0,
                isWithTax  : 0,
                clientId : JSON.parse(window.sessionStorage.getItem("LoginData")).clientId

            }
            console.log('OBJ',obj);
            
            let data = await SaveItemMaster(obj);
             
            if (data.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Data Save Successfully!");
                handlerClear()
                setTimeout(() => {
                    setShowToster(0);
                    handlerSaveUpdateClear();
                    getAllItemList();

                }, 2000)
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage(data.responseValue)
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }
        }

     }

    //  const  getHSNCodeDetails = async()=>{
    //     let data = await GetHsnCodeDetailsByID(hsnCode.value)
    //     if(data.status === 1){
    //         console.log("hsnCodeid")
    //     }
    //  }

//Edit List 

    let edit = (list,index) => {
        document.getElementById('errItemTitile').style.display = "none";
        setRowID(list.id);
        setIsUpdateBtnShow(true);
        setitemName(list.itemName);
        setitemChargewithoutGst(list.itemCharge);
        setselectedCategory(list.categoryId)
        if(list.hsnCodeId == ""){
            sethsnCode(null)
        }
        else{
           sethsnCode({
            value : list.hsnCodeId,
            label : list.hsnCode
        })  

       
        }
        if(list.hsnCodeId !==""){
            setitemwithGST(parseFloat(parseInt(list.itemCharge) + ( (list.gst / 100) * parseInt(list.itemCharge))).toFixed(2))
       }
       else{
           setitemwithGST("")
       }
    
    setEditRowIndex(true)
    setNewlyAddedRowIndex(index)
    setGST(list.gst)
        

    }
//Edit List End

     let handlerUpdate = async () => {
        
        document.getElementById('errItemTitile').style.display = "none";
  
        if (itemName.trim() === '' || itemName === null || itemName === undefined) {
            setShowMeassage("Please Enter Item Name");
            setShowAlertToster(1);
            return;
        }
    
         if( selectedCategory == '' ||selectedCategory ==  undefined) {
            setShowMeassage("Please Select Category");
            setShowAlertToster(1);
            return false;
        }
  

        if( itemChargewithoutGst == '' ||itemChargewithoutGst === undefined) {
            setShowMeassage("Please Enter Item Rate Excluding GST");
            setShowAlertToster(1);
            return false;
        }

        setShowUnderProcess(1);
        var obj = {

            Id: rowID,
            itemName: itemName,
            status: true,
            itemCharge : parseFloat(itemChargewithoutGst),
            categoryId: parseInt(selectedCategory),
            userId: JSON.parse(window.sessionStorage.getItem("LoginData")).userId,
            hsnCodeId: hsnCode && hsnCode.value !== null ? hsnCode.value : 0,
            clientId : JSON.parse(window.sessionStorage.getItem("LoginData")).clientId
        }

          console.log('obj update', obj);
             let data = await UpdateItemMaster(obj);
             if (data.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Data Updated Successfully!");
                handlerClear()
                setTimeout(() => {
                    setShowToster(0);
                    handlerSaveUpdateClear();
                    getAllItemList();

                }, 2000)
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage(data.responseValue)
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }
        }

     

    let handlerClear =  () => {
        // document.getElementById('success').style.display = "none";
        // document.getElementById('successUpdate').style.display = "none";
        document.getElementById('errItemTitile').innerHTML = "";
        document.getElementById('errItemTitile').style.display = "none";
        setitemName('');
        setIsUpdateBtnShow(false);
        sethsnCode(null)
        setitemChargewithoutGst('')
        setitemwithGST('')
        setrateIncluding(0)
        setGST(0)
        setselectedCategory('')
    }

    let handlerSaveUpdateClear = () => {
        // document.getElementById('errSurgeryTitile').innerHTML = "";
        // document.getElementById('errSurgeryTitile').style.display = "none";
        // document.getElementById('errDisease').innerHTML = "";
        // document.getElementById('errDisease').style.display = "none";
        setitemName('');
        setIsUpdateBtnShow(false);
        
      
    }
    
    let getRowID = (id) => {
        setRowID(id);
    }
    let deleteRow = async () => {
        console.log(rowID);
        setShowUnderProcess(1);
        const userID = JSON.parse(window.sessionStorage.getItem("LoginData")).userId;
        let data = await DeleteItem(rowID, userID);
        console.log('s'.data);
        if (data.status === 1) {
            setShowUnderProcess(0);
            setShowToster(1);
            setTosterValue(0);
            setTosterMessage("Data Deleted Successfully!");
            setTimeout(() => {
                setShowToster(0);
                handlerSaveUpdateClear();
                getAllItemList();
            }, 2000)
        }
        else {
            setShowUnderProcess(0);
                setShowToster(1);
                setTosterValue(1);
                setTosterMessage(data.responseValue);
                setTimeout(() => {
                    setShowToster(0);
                }, 2000)
        }
    }
    let clearToaster = () => {
        document.getElementById('errItemTitile').style.display = "none";
        document.getElementById('errDisease').style.display = "none";
    }

    let toggleExcludingGstRates = () => {
        setitemChargewithoutGst('')
        setitemwithGST('')
     setrateExcluding(1)
     setrateIncluding(0)
     setisExcludegstratesDisabled(false)
     setisIncludegstratesDisabled(true)
    }
    let toggleIncludingGstRates = () => {
        setitemChargewithoutGst('')
        setitemwithGST('')
        setrateExcluding(0)
        setrateIncluding(!rateIncluding)
        setisIncludegstratesDisabled(!isIncludegstratesDisabled)
        setisExcludegstratesDisabled(!isExcludegstratesDisabled)
      
    }

    const getItemCategory=async()=>{
        let data = await GetCategoryList()
        if(data.status){
            console.log("categoryList" , data.responseValue)
            setCategoryList(data.responseValue)
        }
    }

    useEffect(() => {
        HSNCodeList();
        getAllItemList();
        getItemCategory()
        // getHSNCodeDetails()


    }, []);
    let GetId = (id)=>{
        console.log("ID", id)
    }
    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                    <div className="col-12"><div className="med-box  mb-1"><div className="title">Item / Service Master</div></div></div>
                        <div className="col-12">
                            <div className="med-box">
                                
                                <div className="inner-content">
                                    <div className="row">
                                    <div className='fieldsett-in'>
                                 <div className='fieldsett'>
                                    <span className='fieldse'>Item Master</span>
                                    <div className='row'>
                                 
                                                <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3">
                                                    <label htmlFor="Code" className="form-label">Item Name<span className="starMandatory">*</span></label>
                                                    <input type="text" className="form-control form-control-sm" name="itemName" value={itemName} placeholder="Enter Item Name" onChange={handlerChange} />
                                                    <small id="errItemTitile" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                </div>
                                                <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3">
                                                <label htmlFor="Code" className="form-label">Select Item Category<span className="starMandatory">*</span></label>
                                                    <select className='form-select form-select-sm' name='category' value={selectedCategory} onChange={handlerChange} >
                                                        <option>Select Item Category</option>
                                                        {CategoryList && CategoryList.map((val , ind)=>{
                                                            return(
                                                                <option key={ind} value={val.id}>{val.categoryName}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>

                                                <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3">
                                                   <label htmlFor="ddlitemmaster" className="form-label ">HSN Code<span className="starMandatory"></span></label>
                                                   <Select value={hsnCode} options={HSNCodeDropdown} className=" create-select" id="serviceType" placeholder="Select HSN Code" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, "errhsnCode", sethsnCode)} />
                                                    <small id="errhsnCode" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                  </div>
                                                <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3">
                                                   <label htmlFor="ddlitemmaster" className="form-label ">GST(%)<span className="starMandatory"></span></label>
                                                   <input type="text" className="form-control form-control-sm" name="itemName" disabled value={GST} placeholder="GST" onChange={handlerChange} />
                                                    <small id="errhsnCode" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                  </div>

                                                  <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3">
                                                    <label htmlFor="Code" className="form-label">Item Charge Excluding <span className="starMandatory">*</span></label>
                                                    <input type="number" className="form-control form-control-sm" name="itemChargewithoutGst" value={itemChargewithoutGst} disabled={isExcludegstratesDisabled} placeholder="Item Charge Excluding GST" onChange={handlerChange} />
                                                    <small id="erritemCharge" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                </div>




                                                  <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3">
                      <div className="d-flex column-gap-3 mt-4 me-3">
                        <label htmlFor="canRead" className="form-label">Rate Including GST <span className="starMandatory"></span></label>
                        <div className="form-check">
                          <input checked={rateIncluding} id="canRead" name="canread" className="form-check-input" type="checkbox" onClick={toggleIncludingGstRates} />
                        </div>
                        <small id="errcanread" className="form-text text-danger" style={{ display: 'none' }}></small>
                      </div>

                      {/* <div className="d-flex column-gap-3 mt-4 me-3">
                        <label htmlFor="canWrite" className="form-label">Rate Excluding GST<span className="starMandatory"></span></label>
                        <div className="form-check">
                          <input checked={rateExcluding} id="canWrite" name="canWrite" className="form-check-input" type="checkbox" onClick={toggleExcludingGstRates} />
                        </div>
                        <small id="errcanwrite" className="form-text text-danger" style={{ display: 'none' }}></small>
                      </div> */}

                    
                    </div >


                                              

                                                <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3 ">
                                                    <label htmlFor="Code" className="form-label">Item Charge Including <span className="starMandatory"></span></label>
                                                    <input type="number" className="form-control form-control-sm" name="itemwithGST"  value={itemwithGST} disabled={isIncludegstratesDisabled} placeholder="Item Charge Including GST" onChange={handlerChange} />
                                                    <small id="erritemCharge" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                </div>

                                             

                                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-1 mt-4 relative d-flex justify-content-end">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>

                                                    {showUnderProcess === 1 ? <><TosterUnderProcess />  </> :
                                                        showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} />
                                                            :
                                                            <div>
                                                                {isUpdateBtnShow !== true ? <>
                                                                    <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handlerSave} ><img src={saveBtnIcon}  className='icnn' alt='' />Save</button>
                                                                    <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handlerClear}><img src={clearBtnIcon} className='icnn' alt='' />Clear</button>
                                                                </> :
                                                                    <>
                                                                        <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={handlerUpdate}>Update</button>
                                                                        <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handlerClear}>Cancel</button>
                                                                    </>
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                            </div>
                                           
                                            </div>
                                         
                                    </div>
                                 
                                 
                                       

                                </div>

                            </div>
                        </div>
                        <div className="col-12 mt-3">
                        <div className='handlser'>
              <Heading text="Items List" />
                <div style={{ position: 'relative' }}>
                  <input type="text" name="searchBox" className='form-control form-control-sm' placeholder="Search" value={searchInput} onChange={handlerChange} />
                  <span className="tblsericon"><i className="fas fa-search"></i></span>
                </div>
              </div>
                            <div className="med-table-section" style={{ "height": "80vh" }}>
                                <table className="med-table border_ striped">
                                    <thead style={{zIndex: '0'}}>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>#</th>
                                            <th>Item Name</th>
                                            <th>Item Category</th>
                                            <th>Item Charge</th>
                                            <th>HSN Code</th>
                                            <th>GST(%)</th>
                                            {/* <th>SGST</th> */}
                                            
                                            <th style={{ "width": "10%" }} className="text-center">Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {itemList && itemList.filter((val) => `${val.itemName}`.toLowerCase().includes(searchInput.toLowerCase())).sort((a, b) => a.itemName.localeCompare(b.itemName)).map((list, index) => {
                                             const isNewRow = newlyAddedRowIndex === index;
                                             const isEditing = index === editRowIndex;
                                            return (
                                                <tr className={index === itemList.length - 1 && isNewRowAdded ? 'new-row' : '' || isNewRow ? 'new-row': ''}>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td>{list.itemName}</td>
                                                    <td>{list.categoryName == "" ? '---' : list.categoryName}</td>
                                                    <td>{list.itemCharge}</td>
                                                    <td>{list.hsnCode}</td>
                                                    <td>{list.gst}</td>
                                                    {/* <td>{list.sgst}</td> */}
                                                    <td>
                                                        <div className="action-button">
                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={editBtnIcon} className={isEditing ? 'edited-row' : ''} title='Edit' alt='' onClick={() => { edit(list,index) }}/></div>
                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' title='Delete' onClick={() => { setRowID(list.id); }}/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {/* -----------------------Start Delete Modal Popup-------------------    */}

                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                    <div className="modal-dialog modalDelete">
                        <div className="modal-content">
                            <div className="modal-body modelbdy text-center">
                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                <div className='popDeleteTitle mt-3'> Delete?</div>
                                <div className='popDeleteContent'> Are you sure you want to delete?</div>
                            </div>
                            <div className="modal-footer1 text-center">

                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn-delete popBtnDelete" onClick={deleteRow} data-bs-dismiss="modal">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* -----------------------End Delete Modal Popup---------------------  */}
                {
                    showLoder === 1 ? <Loder val={showLoder} /> : ""
                }

{showAlertToster === 1 ? (
              <AlertToster message={showMessage} handle={setShowAlertToster} />
            ) : (
              ""
            )}
           {showSuccessToster === 1 ? (
              <SuccessToster
                message={showMessage}
                handle={setShowSuccessToster}
              />
            ) : (
              ""
            )}


            </section>
        </>
    )
}