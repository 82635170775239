
async function DeleteNursingAssessment (id) {

    let url = window.AppbaseUrl + `/api/NursingAssessment/DeleteNursingAssessment?id=${id}&clientID=${window.clientId}`
    let head = {
      'Content-Type': 'application/JSON',
      accept: '*/*',
    }
    let response =
      await fetch(url, {
        method: 'DELETE',
        headers: head,
        body: JSON.stringify({})
      })
        .then((res) => res.json())  
    return response;
  }
  export default DeleteNursingAssessment;
  