
let GetCategoryList = async () => {
    let clientId = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId

    let URL = window.BillingbaseUrl  + `/api/CategoryMaster/GetAllCategory?clientId=${clientId}`;
    let head = {"Content-Type":"application/JSON", 'accept':'*/*'}
    let response="";
  
    response = await fetch(URL, {
          headers : head,
          method : "GET"
    })
      .then(res => res.json())
      .then(data => response=data)
      .catch(error => error)
      return response;
  }
  
  export default GetCategoryList;