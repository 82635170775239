import React, { useEffect, useState } from 'react'
import Heading from '../../../../Component/Heading'
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import save from '../../../../assets/images/icons/save.svg';
import clear from '../../../../assets/images/icons/clear.svg';
import TableContainer from '../../../../Component/TableContainer';
import { act } from '@testing-library/react';
import GetPatientDetailsforSarvodaya from '../../../../Sarvodaya/Procedure/API/GetPatientDetailsforSarvodaya';
import GetAllPastMedicalHistory from './API/GetAllPastMedicalHistory';
import InsertPatientInitialAssessment from './API/InsertPatientInitialAssessment';
import ProvisonalDiagnosisMainComponent from '../../../../Sarvodaya/Procedure/ProvisonalDiagnosisMainComponent';
import ChiefCaomplainComponent from '../../../../Sarvodaya/Procedure/ChiefCaomplainComponent';
import IPDMedicationForICU from '../IPDSharePages/ICUChart/IPDMedicationForICU';
import MedicationComponent from '../IPDSharePages/ICUChart/MedicationComponent';
import MedicalHistoryComponent from '../../../../Sarvodaya/Procedure/MedicalHistoryComponent';
import IPDAllergiesComponent from '../../Component/IPDAllergiesComponent';
import Loader from '../../../../Component/Loader';
import SuccessToster from '../../../../Component/SuccessToster';
import AlertToster from '../../../../Component/AlertToster';
import InvestigationsRows from '../../../../Sarvodaya/Procedure/InvestigationsRows';
import TableInitialAssessmentInpatientAdult from './TableInitialAssessmentInpatientAdult';
import TosterUnderProcess from '../../../../Component/TosterUnderProcess';
import Toster from '../../../../Component/Toster';
import GetInvestigationDetails from '../../../../Sarvodaya/Procedure/API/GetInvestigationDetails';
import DropdownWithSearch from '../../../../Component/DropdownWithSearch';
import IPDMedication from '../IPDSharePages/ICUChart/IPDMedicationForICU';
import GetAllPatientInitialDetails from './API/GetAllPatientInitialDetails';
import GetAllSavedPastHistory from './API/GetAllSavedPastHistory';
import PatientDeatils from '../../../../Component/PatientDeatils';

export default function InitialAssessmentInpatientAdult() {
  const { t } = useTranslation();
  document.body.dir = i18n.dir();
  // const getCurrentDateTime = () => {
  //   const now = new Date();
  //   const currentDate = now.toISOString().split('T')[0];
  //   const currentTime = now.toTimeString().slice(0, 5);
  //   return { currentDate, currentTime };
  // };

  const getCurrentDateTime = () => {
    const now = new Date();
    if (now) {
      const currentDate = now.toISOString().split('T')[0];
      const currentTime = now.toTimeString().slice(0, 5);
      return { currentDate, currentTime };
    }
    return { currentDate: '', currentTime: '' }; // Return default values if now is null
  };
  const [sendForm, setSendForm] = useState({
    assesmentDateTime: getCurrentDateTime().currentDate,
    assessmentTimeName: getCurrentDateTime().currentTime,
    procedureID: 0,
    userId: window.userId,
    clientId: window.clientId
  })

  let [InvestigationJSON, setInvestigationJSON] = useState([]);
  const [patientData, setPatientData] = useState();
  const [historyList, setHistoryList] = useState();
  const [savedHistoryList, setSavedHistoryList] = useState();
  const [savedHistoryJsonList, setSavedHistoryJsonList] = useState();

  console.log("savedHistoryList", savedHistoryList)
  console.log("savedHistoryJsonList", savedHistoryJsonList)

  const [parameterList, setparameterList] = useState();
  let [saveDiagnosis, setSaveDiagnosis] = useState(0);
  let [saveSymtoms, setSaveSymtoms] = useState(0)
  let [saveAllergie, setSaveAllergies] = useState(0)
  let [saveMedicalHistory, setSaveMedicalHistory] = useState([]);
  const [selectedParameterId, setSelectedParameterId] = useState({});

  let [showLoder, setShowLoder] = useState(0);
  let [isShowToaster, setisShowToaster] = useState(0);
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [showErrMessage, setShowErrMessage] = useState('');
  let [showSuccessMsg, setShowSuccessMsg] = useState('');
  const [showUnderProcess, setShowUnderProcess] = useState(0);
  const [showToster, setShowToster] = useState(0);
  const [tosterMessage, setTosterMessage] = useState("");
  const [tosterValue, setTosterValue] = useState(0);

  let [procedureList, setProcedureList] = useState([])
  let [clearDropdown, setClearDropdown] = useState(0);
  let [editProcedure, setEditProcedure] = useState('');

  const userId = JSON.parse(sessionStorage.getItem("LoginData")).userId;
  const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;
  const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;


  console.log("parameterList", parameterList)

  //GEt Procedure
  let getProcedure = async () => {
    let items = await GetInvestigationDetails()
    if (items.status === 1) {
      setProcedureList(items.responseValue)
      // console.log("Investigation data", items.responseValue)
    }
  }

  //Get Patient Details
  const getPatientData = async () => {
    let data = await GetPatientDetailsforSarvodaya(uhID)
    if (data.status === 1) {
      setPatientData(data.responseValue[0])
    }
  }

  //Get History Details
  const getHistory = async () => {
    const historyId = 18;
    let data = await GetAllPastMedicalHistory(clientID, historyId);
    if (data.status === 1) {
      setHistoryList(data.responseValue);
      const parsedParameterList = JSON.parse(data.responseValue[0].parameterJson);
      setparameterList(parsedParameterList);
    }
  };

  //Get saved History Details
  const getSavedHistory = async () => {
    let response = await GetAllSavedPastHistory(clientID, uhID);
    if (response.status === 1 && response.responseValue && response.responseValue.length > 0) {
      setSavedHistoryList(response.responseValue);
      console.log("setSavedHistoryList", response.responseValue)
      const parsedmedicalHistoryJson = JSON.parse(response.responseValue[0].medicalHistoryJson);
      setSavedHistoryJsonList(parsedmedicalHistoryJson);
    }
  };

  /////////////////// for setting values in fields ///////////////////////

  // const handleChange = (e) => {
  //   let name = e.target.name;
  //   let value = e.target.value;
  //   setEditProcedure("");
  //   setSendForm((prevSendForm) => ({
  //     ...prevSendForm,
  //     [name]: value
  //   }));
  // };
  const handleChange = (e, index) => {
    let name = e.target.name;
    let value = e.target.value;
    setEditProcedure("");
    setSendForm((prevSendForm) => ({
      ...prevSendForm,
      [name]: value
     
    }));
    console.log("dateValue" , value);

    const updatedParameterList = parameterList.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          parameterValueId: value
        };
      }
      return item;
    });
   
    
    if (value === "0") {
      const updatedParameterListWithClearedDate = updatedParameterList.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            parameterValueId: value,
            date:  '' 
          };
        }
        return item;
      });
      setparameterList(updatedParameterListWithClearedDate);
    }
    else {
      const updatedParameterListWithNewValue = updatedParameterList.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            parameterValueId: value,
            date: value 
          };
        }
        return item;
      });
      setparameterList(updatedParameterListWithNewValue);
    }
};



  //Save Data
  const handleSave = async () => {
    setSaveDiagnosis(1)
    setSaveSymtoms(1)
    setSaveMedicalHistory(1)
    setSaveAllergies(1)
    const pastMedicalHistoryJson = parameterList.map((val, index) => {
      const parameterValueId = document.querySelector(`input[name="parameterValueId-${index}"]:checked`)?.value || '0';
      const dateValue = document.querySelector(`input[name="dateValue-${index}"]`)?.value || '';

      return {
        parameterId: val.parameterId,
        parameterValueId: parameterValueId,
        parameterStatement: '',
        clinicalDataTypeId: val.clinicalDatatypeId,
        dateof: dateValue
      };
    });
    const dateTime = sendForm.assesmentDateTime + ' ' + sendForm.assessmentTimeName;
    const finalObj = {
      uhid: uhID,
      assesmentDateTime: dateTime,
      pastMedicalHistory: JSON.stringify(pastMedicalHistoryJson),
      // presentMedication: "",
      // allergies: '',
      procedureID: sendForm.procedureID,
      userId: userId,
      clientId: clientID
    };

    console.log("finalObj", finalObj)
    // return;
    // if (sendForm.procedureID === '' || sendForm.procedureID === 0 || sendForm.procedureID === null || sendForm.procedureID === undefined) {
    //   document.getElementById('errProcedure').innerHTML = "Select procedure";
    //   document.getElementById('errProcedure').style.display = "block";
    // }
    const response = await InsertPatientInitialAssessment(finalObj);
    if (response.status === 1) {
      setShowUnderProcess(0);
      setTosterValue(0);
      setShowToster(1);
      setTosterMessage("Data saved successfully..");
      setTimeout(() => {
        setShowToster(0);
        handlePrintAssessment();
        handleClear();
      }, 1500)
    }
    else {
      setShowUnderProcess(0);
      setTosterValue(1);
      setShowToster(1);
      handlePrintAssessment();
      setTosterMessage(response.responseValue);
      setTimeout(() => {
        setShowToster(0);
      }, 1500)
    }
  }

  //Handle Clear
  let handleClear = (value) => {
    setClearDropdown(value);
    setEditProcedure(0);
    setSendForm({ "userId": window.userId, "clientId": window.clientId });
    setSelectedParameterId({});
  };

  // handle changes in date input
  const handleDateChange = (e) => {
    const { value } = e.target;
    setSendForm((prevState) => ({
      ...prevState,
      assesmentDateTime: value
    }));
  };

  // handle changes in time input
  const handleTimeChange = (e) => {
    const { value } = e.target;
    setSendForm((prevState) => ({
      ...prevState,
      assessmentTimeName: value
    }));
  };


  const preCheckRadioButtons = () => {
    console.log("Inside preCheckRadioButtons");
    if (!parameterList || !savedHistoryJsonList) return;
    parameterList.forEach((parameter, index) => {
      const savedParameter = savedHistoryJsonList.find(item => item.parameterName === parameter.parameterName);
      if (savedParameter) {
        const radioButtonYes = document.getElementById(`parameterValueId-${index}-yes`);
        const radioButtonNo = document.getElementById(`parameterValueId-${index}-no`);
        const dateInput = document.querySelector(`input[name="dateValue-${index}"]`);
        if (radioButtonYes && radioButtonNo && dateInput) {
          radioButtonYes.checked = savedParameter.ParameterValue === "YES";
          radioButtonNo.checked = savedParameter.ParameterValue === "NO";
          // dateInput.value = savedParameter.date.split(' ')[0];
          dateInput.value = savedParameter.date ? savedParameter.date.split(' ')[0] : '';
        }
      }
    });
  };

  const handlePrintAssessment = () => {
    // const assessmentData = {

    // };
    // window.sessionStorage.setItem('initialAssessmentList', JSON.stringify(assessmentData));
    window.open("/ReceiptInitialAssesmentPatiantAdultPrint/", 'noopener,noreferrer')
  };
  const formatDOB = (dob) => {
    const [day, month, year] = dob.split('/');
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    preCheckRadioButtons();
  }, [savedHistoryJsonList])

  useEffect(() => {
    getPatientData();
    getHistory();
    getProcedure();
    getSavedHistory();
  }, [])
  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Heading text='Initial Assessment Inpatient Adult' id='top' />
              <PatientDeatils />
              <div className="fieldsett-in">
                <div className="fieldsett">
                  <span className='fieldse'>{t("Assessment Details")}</span>
                  <div className="inner-content mt-2">
                    <div className="d-flex flex-wrap align-content-end gap-3">
                      {/* <div className="mb-2 me-2">
                        <label htmlFor="*" className="form-label">Patient Name<span className="starMandatory"></span></label>
                        <input type="text" className="form-control form-control-sm" value={patientData && patientData.patientName} disabled placeholder="Patient name" />
                      </div> */}
                      {/* <div className="mb-2 me-2">
                        <label htmlFor="*" className="form-label">MR No.<span className="starMandatory"></span></label>
                        <input type="text" className="form-control form-control-sm" value={patientData && patientData.uhId} disabled placeholder="MR No." />
                      </div> */}
                      {/* <div className="mb-2 me-2">
                        <label htmlFor="*" className="form-label">Age<span className="starMandatory"></span></label>
                        <input type="text" className="form-control form-control-sm" value={patientData && patientData.age} disabled placeholder="Age" />
                      </div> */}
                      {/* <div className="mb-2 me-2">
                        <label htmlFor="*" className="form-label">Gender<span className="starMandatory"></span></label>
                        <input type="text" className="form-control form-control-sm" value={patientData && patientData.gender} disabled placeholder="Gender" />

                      </div> */}
                      {/* <div className="mb-2 me-2">
                        <label htmlFor="*" className="form-label">DOB<span className="starMandatory"></span></label>
                        <input type="date" className="form-control form-control-sm" value={patientData && formatDOB(patientData.dob)} disabled placeholder="Date of birth" />
                      </div>
                      <div className="mb-2 me-2">
                        <label htmlFor="*" className="form-label">Ward/Bed<span className="starMandatory"></span></label>
                        <input type="text" className="form-control form-control-sm" value={patientData && patientData.wardName + "/" + patientData.bedName} disabled placeholder="Ward" />
                      </div> */}
                      <div className="mb-2 me-2">
                        <label htmlFor="assesmentDateTime" className="form-label">Date of Assessment<span className="starMandatory"></span></label>
                        <input id="assessmentDateId" type="date" className="form-control form-control-sm" name="assesmentDateTime" value={sendForm.assesmentDateTime} onChange={handleDateChange} />
                      </div>
                      <div className="mb-2 me-2">
                        <label htmlFor="assessmentTimeName" className="form-label">Time of Assessment<span className="starMandatory"></span></label>
                        <input id="assessmentTimeId" type="time" className="form-control form-control-sm" name="assessmentTimeName" value={sendForm.assessmentTimeName} onChange={handleTimeChange} />
                      </div>
                      <div className="mb-2 me-2">
                        <label htmlFor="Code" className="form-label">Procedure<span className="starMandatory"></span></label>
                        {procedureList && <DropdownWithSearch defaulNname={t("Select Procedure")} name="procedureID" list={procedureList} valueName="id" displayName="itemName" editdata={editProcedure} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                        <small id="errProcedure" className="invalid-feedback" style={{ display: 'none' }}></small>
                      </div>
                      <div className="mb-2 me-2">
                        <label htmlFor="Code" className="form-label">Diagnosis<span className="starMandatory"></span></label>
                        <ProvisonalDiagnosisMainComponent isProvisional={0} saveDiagnosis={saveDiagnosis} setSaveDiagnosis={setSaveDiagnosis} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="row mt-2">
                <div className="col-md-12 col-sm-12">
                  <div className='allegydiagnosismain'>
                    {/* <div className='allergy-inn'>
                      <div className="fieldsett-in">
                        <div className="fieldsett">
                          <span className='fieldse'>{t("Diagnosis")}</span>
                          <div className="inner-content mt-2" style={{ minHeight: '18vh', maxHeight: '25vh', overflow: 'auto' }}>
                            <ProvisonalDiagnosisMainComponent isProvisional={0} saveDiagnosis={saveDiagnosis} setSaveDiagnosis={setSaveDiagnosis} />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className='allergy-inn all-width100'>
                      <div className="fieldsett-in">
                        <div className="fieldsett">
                          <span className='fieldse'>{t("Allergies")}</span>
                          <div className="inner-content mt-2" style={{ minHeight: '18vh', maxHeight: '25vh', overflow: 'auto' }}>
                            <IPDAllergiesComponent saveAllergie={saveAllergie} setSaveAllergies={setSaveAllergies} />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>


              <div className="row mt-2">
                <div className="col-md-12 col-sm-12">
                  <div className='allegydiagnosismain'>
                    <div className='allergy-inn'>
                      <div className="fieldsett-in">
                        <div className="fieldsett">
                          <span className='fieldse'>{t("Chief Complaints & History of Present Illness")}</span>
                          <div className="inner-content mt-2" style={{ maxHeight: '25vh', overflow: 'auto' }}>
                            <ChiefCaomplainComponent saveSymtoms={saveSymtoms} setSaveSymtoms={setSaveSymtoms} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='allergy-inn'>
                      <div className="fieldsett-in">
                        <div className="fieldsett">
                          <span className='fieldse'>{t("Past Surgical History")}</span>
                          <div className="inner-content mt-2" style={{ maxHeight: '25vh', overflow: 'auto' }}>
                            <MedicalHistoryComponent saveMedicalHistory={saveMedicalHistory} setSaveMedicalHistory={setSaveMedicalHistory} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-12 col-sm-12">
                  <div className='allegydiagnosismain'>
                    <div className='allergy-inn all-width100'>
                      <div className="fieldsett-in">
                        <div className="fieldsett">
                          <span className='fieldse'>{t("Past Medical History")}</span>
                          <div className='row mt-2 px-2'>
                            <div className="col-12 mt-1">
                              <div className="med-table-section" style={{ minHeight: '30vh', maxHeight: '30vh', overflow: 'scroll' }}>
                                <table className="med-table tbl-quest border_ striped">
                                  <thead>
                                    <tr>
                                      <th>{t("Illness")}</th>
                                      <th>{t("Status")}</th>
                                      <th>{t("Duration")}</th>
                                    </tr>
                                  </thead>
                                  <tbody>

                                    {parameterList && parameterList.map((val, index) => (
                                      <tr key={index}>
                                        <td>{val.parameterName}</td>
                                        <td>
                                          <div className="statusRadio">
                                            <div className="form-check">
                                              <input
                                                className="form-check-input" type="radio" id={`parameterValueId-${index}-yes`} name={`parameterValueId-${index}`} value="1" onChange={(e) => handleChange(e, index)} />
                                              <label className="form-check-label" htmlFor={`parameterValueId-${index}-yes`}> Yes </label>
                                            </div>
                                            <div className="form-check">
                                              <input className="form-check-input" type="radio" id={`parameterValueId-${index}-no`} name={`parameterValueId-${index}`} value="0" onChange={(e) => handleChange(e, index)} />
                                              <label className="form-check-label" htmlFor={`parameterValueId-${index}-no`}> No </label>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="" style={{ width: '300px' }}>
                                            <input type="date" className="form-control form-control-sm" name={`dateValue-${index}`} value={val.date}  onChange={(e) => handleChange(e, index)}  disabled={val.parameterValueId === '0' ? true : false} />
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='allergy-inn'>
                      <div className="fieldsett mt-3">
                        <span className='fieldse'>{t("Present Medication")}</span>
                        <div className="roww">
                          <div className={` boxcontainer med-table-section  ipdtablemedication`} style={{ maxHeight: '30vh', overflow: 'scroll' }}>
                            {/* <IPDMedicationForICU getData={getPatientData} /> */}
                            <MedicationComponent getData={getPatientData} />
                            {/* <IPDMedication getData={getPatientData}/> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-12 mt-2">
                <div className='whitebg1'>
                  <div className='row'>
                    <div className="col-12">
                      <div className='whitebg' style={{ padding: "3px" }}>
                        <div className="d-flex  gap-2 mt-2 samplebtnn">
                          {showUnderProcess === 1 ? <TosterUnderProcess /> :
                            <>
                              {showToster === 1 ?
                                <Toster value={tosterValue} message={tosterMessage} />

                                : <div>
                                  <>
                                    <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleSave}><img src={save} className='icnn' alt='' />Save & Print</button>
                                    <button type="button" className="btn btn-clear btn-sm mb-1" onClick={handleClear}><img src={clear} className='icnn' alt='' />Clear</button>
                                  </>

                                </div>}
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              {/* -----------------------------------------Start Get Data Section------------------------------- */}
              <div className='whitebg mt-2 mb-2' style={{ paddingTop: '0' }}>
                {/* <TableInitialAssessmentInpatientAdult /> */}
              </div>
              {/* -----------------------------------------End Get Data Section------------------------------- */}

            </div>
          </div>

          {
            showLoder === 1 ? <Loader val={showLoder} /> : ""
          }
          {/* Toaster */}
          {
            isShowToaster === 1 ?
              <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
          }

          {
            showAlertToster === 1 ?
              <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
          }
        </div>

      </section>
    </>
  )
}
