import React from 'react'
import printuse from '../../../assets/css/printuse.css'
export default function ReceiptNursingShiftHandoffForm() {
    return (
        <>
            <div className="main-table" style={{ padding: '5px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <table className='clearslip pt-4 mt-4'>
                    {/* ....header.. */}
                    <tr>
                        <td colSpan={10}>
                            <table>
                                <tr>
                                    <td>
                                        <div className="sarvodya-header" style={{borderBottom:'none', paddingBottom:'0px'}}>
                                            <div className='lft-header mb-0 pb-0'>
                                                <img alt='' className='sv-log' />
                                            </div>
                                            <div className='heading-form mt-2 mb-0 pb-0' style={{fontSize:'15px'}}>
                                                Nursing Shift Handoff Form-ICU
                                            </div>
                                            <div className='rght-header mb-0 pb-0'>
                                                <div> SARVODAYA HOSPITAL <div className='addrs'> SECTOR-8, FARIDABAD | HELPLINE: 1800 313 1414</div></div>
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                  
                    <tr>
                        <td colSpan={10} className='pt-0 mt-0 mb-0 pb-0'>
                            <table className='nursngformdetails' style={{ border: '1px solid black' }}>
                                <tr>
                                    <td colSpan={8}><strong>Patient Full Name:<span style={{ width: '475px', borderBottom: '1px solid black', display: 'inline-block' }}></span> </strong></td>
                                    <td rowSpan={3} style={{ borderLeft: '1px solid black', textAlign: 'center' }}>Patients Label</td>
                                </tr>
                                <tr>
                                    <td colSpan={8} className='pt-0 mt-0'>
                                        <div className='nursingshiftt'>
                                            <div><strong>Age/Gender :- <span style={{ width: '90px', borderBottom: '1px solid black', display: 'inline-block' }}></span></strong></div>
                                            <div><strong>MR No. :-<span style={{ width: '60px', borderBottom: '1px solid black', display: 'inline-block' }}></span> </strong></div>
                                            <div><strong>Diagnosis :-<span style={{ width: '210px', borderBottom: '1px solid black', display: 'inline-block' }}></span> </strong></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={8} className='pt-0 mt-0 '>
                                        <div className='nursingshiftt'>
                                            <div><strong>D.O. Surgery :-<span style={{ width: '50px', borderBottom: '1px solid black', display: 'inline-block' }}></span> </strong></div>
                                            <div><strong>Name of Surgery/Procedure :-  <span style={{ width: '110px', borderBottom: '1px solid black', display: 'inline-block' }}></span> </strong></div>
                                            <div><strong>Post op Day :-<span style={{ width: '50px', borderBottom: '1px solid black', display: 'inline-block' }}></span> </strong></div>
                                        </div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={10} className='pt-0 mt-0 mb-0 pb-0'>
                            <table className='tblnursingg'>
                                <tr>
                                    <td colSpan={0}><strong>Date</strong></td>
                                    <td colSpan={3}>ss</td>
                                    <td colSpan={3}>ss</td>
                                    <td colSpan={3}>ss</td>
                                </tr>
                                <tr>
                                    <td colSpan={0}><strong>Shift</strong></td>
                                    <td><strong>Mornng</strong></td>
                                    <td><strong>Evening</strong></td>
                                    <td><strong>Night</strong></td>
                                    <td><strong>Mornng</strong></td>
                                    <td><strong>Evening</strong></td>
                                    <td><strong>Night</strong></td>
                                    <td><strong>Mornng</strong></td>
                                    <td><strong>Evening</strong></td>
                                    <td><strong>Night</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Vitals</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>GCS</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Pain Score</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>New Complaints if any</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Skin Status Intact/Non intact/Braden Score)</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>VIP Score</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Fal risk assessment</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Lines & tubes in shu(Yes/No)</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Drain/Dressing</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong> Restraint Yes/No</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong> RA/02/Tracheostomy/Ventilator</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong> Pending medication(Yes/No, if yes specify)</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong> Pending Investigation(Yes/No If yes specify)</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Critical value alert(Yes/No)</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Intake Output charting(Yes/No)</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Diet</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Any Cross References</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Any pending doctor's orders/procedures</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Any Other</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Handover By</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Emp.Id</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Initial</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Taken Over By</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Emp.d</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Initial</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Handover for any other reason</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Handed over by:</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Taken Overby:</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Taken Overby:</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    {/* <tr>
                        <td colSpan={8} className='pt-0 mt-0 mb-0 pb-0'>
                            <table className='tblnursingg'>
                              

                            </table>
                        </td>
                    </tr> */}





                </table>
            </div>
        </>
    )
}
