async function InvestigationOfSHFCAPI(data = "") {
  const clientID = window.clientId
  let url = ""
  if (data === "") {
    url = window.LabServicebaseUrl + "/api/DirectTestResult/GetAllDirectInvestigation?clientId=" + clientID;
  }
  else{
    url = window.LabServicebaseUrl + `/api/DirectTestResult/GetAllDirectInvestigation?clientId=${clientID}&fromDate=${data.fromDate}&toDate=${data.toDate}&Uhid=${data.Uhid}`;

  }
  let head = { "Content-Type": "application/JSON", accept: '*/*' };


  let response = fetch(url, {
    headers: head,
    method: 'GET'
  }).then((res) => res.json()).then(data);

  return response;
}
export default InvestigationOfSHFCAPI;
