

async function GetRequirement (data) {

      let url = window.BloodbaseUrl + "/api/Product/GetAllProduct";
      let head = {
        'Content-Type': 'application/JSON',
        accept: '*/*',
      }
      let response =
        await fetch(url, {
          method: 'GET',
          headers: head,
          body: JSON.stringify(data)
        })
          .then((res) => res.json())
          .then(data)
      return response;
    }
    export default GetRequirement;
   