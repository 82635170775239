
async function GetAllPurchase(clientId) {
    let url = window.PharmacyServicesUrl+"/api/PurchaseMaster/GetAllPurchaseMaster?ClientId="+clientId;
    // let url = window.LocalUrl+"/api/PurchaseMaster/GetAllPurchaseMaster?ClientId="+clientId;
  let head = { "Content-Type": "application/JSON", accept : '*/*' };
  
  let data = {};
  let response = fetch(url, {
    headers: head,
    method : 'GET'
  })
    .then((res) => res.json())
    .then(data);
  
  return response;
  }
  export default GetAllPurchase;
  