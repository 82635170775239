import React from 'react'
// import SarvodayaLogo from '../../../assets/images/icons/sarvodayalogo.png'

export default function ProgressNotes() {
    return (
        <>
            <div className="main-table" style={{ padding: '5px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <table className='microtble pt-4 mt-4'>
                    {/* ....header.. */}
                    <tr>
                        <td colSpan={6}>
                            <table>
                                <tr>
                                    <td>
                                        <div className="sarvodya-header">
                                            <div className='lft-header '>
                                                <img alt='' className='sv-log' />
                                            </div>
                                            <div className='rght-header'>
                                                <div> SARVODAYA HOSPITAL <div className='addrs'>SECTOR-8, FARIDABAD | HELPLINE: 1800 313 1414</div></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td colSpan={3} style={{ verticalAlign: 'top' }}>
                            <div className='micro-heading' >
                                Progress Notes
                            </div>
                        </td>
                        <td colSpan={3}>
                            <table className="tb-rght">
                                <tr>
                                    <th colSpan={6}>Patient Name: <span style={{ width: '175px', borderBottom: '1px dashed black', display: 'inline-block' }}></span> </th>

                                </tr>
                                <tr>
                                    <th colSpan={6}>MRD No. <span style={{ width: '220px', borderBottom: '1px dashed black', display: 'inline-block' }}></span> </th>
                                </tr>
                                <tr>
                                    <th colSpan={3}>Age: <span style={{ width: '70px', borderBottom: '1px dashed black', display: 'inline-block' }}></span> </th>
                                    <th colSpan={3}>Sex: <span style={{ width: '80px', borderBottom: '1px dashed black', display: 'inline-block' }}></span> </th>
                                </tr>
                                <tr>
                                    <th colSpan={3}>DOA: <span style={{ width: '70px', borderBottom: '1px dashed black', display: 'inline-block' }}></span> </th>
                                    <th colSpan={3}>Unit: <span style={{ width: '75px', borderBottom: '1px dashed black', display: 'inline-block' }}></span> </th>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr> <th colSpan={6}> <div className="lines" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className='tbl-sectin'>MR No.: <span ></span></div>
                        <div className='tbl-sectin'>IP No.: <span ></span></div>
                        <div className='tbl-sectin'>Account/Bed No: <span ></span></div>
                    </div></th>
                    </tr>
                    <tr> <th colSpan={6}> <div className="lines">Time and Date of Examination</div></th></tr>
                    <tr> <th colSpan={6}> <div className="lines"></div></th></tr>
                    <tr> <th colSpan={6}> <div className="lines"></div></th></tr>
                    <tr> <th colSpan={6}> <div className="lines">Follow up notes</div></th></tr>
                    <tr> <th colSpan={6}> <div className="lines">Date & Time </div></th></tr>
                </table>



            </div>


        </>
    )
}
