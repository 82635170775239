import React, { useId, useState } from 'react'
import { useEffect } from 'react'
import SearchComponent from './SearchComponent'
// import tableDragger from 'table-dragger'

// import UpdateAllDashboardColumnSequence from '../Api/UpdateAllDashboardColumnSequence'
import { useSelector } from 'react-redux'
import UpdateAllDashboardColumnSequence from '../Api/UpdateAllDashboardColumnSequence'

export default function RightTable(props) {
    let [showRow, setShowRow] = useState([])
    let [columnId, setColumnId] = useState(null)

    let [cloumnVisibility, setCloumnVisibility] = useState({
        // bpVisibility: true,
        // spo2Visibility: true,
        // rrVisibility: true,
        // hrVisibility: true,
        // prVisibility: true,
        // tempVisibility: true,
        // rbsVisibility: true,
        // albVisibility: true,
        // caplusVisibility: true,
        // kplusVisibility: true,
        // naplusVisibility: true,
        // mgVisibility: true,
        // phVisibility: true,
        // pco2Visibility: true,
        // etco2Visibility: true,
        // po2Visibility: true,
        // lactateVisibility: true,
        // hco3Visibility: true,
        // creatinineVisibility: true,
        // bureaVisibility: true,
        // ioVisibility: true,
        // sgotVisibility: true,
        // sgptVisibility: true,
    })
    let patientMonitorDashboard = useSelector((state) => state.PatientMonitorDashboard.alertData)

    function mergeTables(table1Id, table2Id) {
        var table1 = document.getElementById(table1Id);
        var table2 = document.getElementById(table2Id);
        var mergedTable = document.createElement("table");

        // Copy attributes from Table 1
        mergedTable.id = table1.id;
        mergedTable.className = table1.className;
        mergedTable.style = table1.style.cssText;

        // Copy headers from Table 1
        var headerRow1 = mergedTable.createTHead().insertRow(0);
        for (var h = 0; h < table1.rows[0].cells.length; h++) {
            var cell = headerRow1.insertCell(h);
            cell.textContent = table1.rows[0].cells[h].textContent;
            // Create th element
            var th = document.createElement("th");
            th.textContent = cell.textContent;
            // Copy attributes from Table 1 header cell to merged table header cell
            for (var i = 0; i < table1.rows[0].cells[h].attributes.length; i++) {
                var attr = table1.rows[0].cells[h].attributes[i];
                th.setAttribute(attr.name, attr.value);
            }
            cell.replaceWith(th);
        }

        // Copy data rows from Table 1
        for (var i = 1; i < table1.rows.length; i++) {
            var newRow = mergedTable.insertRow();
            for (var j = 0; j < table1.rows[i].cells.length; j++) {
                var newCell = newRow.insertCell(j);
                newCell.textContent = table1.rows[i].cells[j].textContent;
            }
        }

        // Copy headers from Table 2
        var headerRow2 = mergedTable.createTHead().insertRow(1);
        for (var h = 0; h < table2.rows[0].cells.length; h++) {
            var cell = headerRow2.insertCell(h);
            cell.textContent = table2.rows[0].cells[h].textContent;
            // Create th element
            var th = document.createElement("th");
            th.textContent = cell.textContent;
            // Copy attributes from Table 2 header cell to merged table header cell
            for (var i = 0; i < table2.rows[0].cells[h].attributes.length; i++) {
                var attr = table2.rows[0].cells[h].attributes[i];
                th.setAttribute(attr.name, attr.value);
            }
            cell.replaceWith(th);
        }

        // Copy data rows from Table 2
        for (var i = 1; i < table2.rows.length; i++) {
            var newRow = mergedTable.insertRow();
            for (var j = 0; j < table2.rows[i].cells.length; j++) {
                var newCell = newRow.insertCell(j);
                newCell.textContent = table2.rows[i].cells[j].textContent;
            }
        }

        // Display the merged table
        return mergedTable;
    }

    let handleColumnVisisbility = async (name) => {

        // const table = document.getElementById('alltable');
        const table = mergeTables("table", "table2");
        const allHead = Array.from(table.getElementsByTagName('th')).slice(1); // Exclude the first element

        const tempsendArray = allHead.map(head => JSON.parse(head.getAttribute("data-json")))
            .filter(senddata => senddata.columnId !== columnId);

        const sendDataArray = tempsendArray.map((senddata, index) => ({
            id: senddata.id,
            columnId: senddata.columnId,
            sequenceNumber: index + 1,
            assignedTo: senddata.assignedTo,
            userId: window.userId,
            clientId: window.clientId,
            isShow: 1

        }));

        console.log("tempsendArray", tempsendArray, sendDataArray);
        if (sendDataArray.length === 0) {
            sendDataArray.push({
                id: 0,
                columnId: 0,
                sequenceNumber: 0,
                assignedTo: 0,
                userId: window.userId,
                clientId: window.clientId
            });
        }

        const sendData = {
            jsonSequence: JSON.stringify(sendDataArray)
        };

        const response = await UpdateAllDashboardColumnSequence(sendData);

        if (response.status === 1) {
            console.log("Data Save");
            props.handleshowHide(-1);
            window.location.reload(true);
        } else {
            window.location.reload(true);
        }

        setCloumnVisibility(value => ({
            ...value,
            [name]: false
        }));
    }

    // let getAllColumnIds = async () => {
    //     let table = document.getElementById('table2')
    //     let allHead = table.getElementsByTagName('th')
    //     // console.log("all head", allHead)
    //     let sendDataArray = []
    //     for (var i = 0; i <= allHead.length - 1; i++) {
    //         // console.log("alll columns", allHead[i].getAttribute("id"))
    //         // console.log("alll columns", allHead[i].getAttribute("data-json"))
    //         let senddata = JSON.parse(allHead[i].getAttribute("data-json"))
    //         let data = {
    //             "id": senddata.id,
    //             "columnId": senddata.columnId,
    //             "sequenceNumber": i + 13,
    //             "assignedTo": senddata.assignedTo,
    //             "userId": window.userId,
    //             "clientId": window.clientId
    //         }
    //         sendDataArray.push(data)

    //     }

    //     let sendData = {
    //         jsonSequence: JSON.stringify(sendDataArray)
    //     }
    //     // console.log("data save", sendData)
    //     let response = await UpdateAllDashboardColumnSequence(sendData);
    //     if (response.status === 1) {
    //         console.log("Data Save")
    //         props.handleshowHide(-1)
    //         window.location.reload(true)


    //     }
    //     else {
    //         window.location.reload(true)
    //     }

    //     // console.log("sendData ", sendDataArray)

    // }

    let moveCol = () => {
        props.handleshowHide(0)
        // tableDragger(document.querySelector("#table2"))
        // .on('drag', (e) => {
        //     console.log('pick a column');
        // })
        // .on('drop', async (from, to, el, mode) => {
        //     if(from !==  to)
        //     {

        //         await getAllColumnIds()
        //     }

        // })
        // .on('shadowMove', (from, to, el, mode) => {
        //     console.log(`moving a column`);
        // })
        // .on('out', (e, mode) => {
        //     console.log(`extra dont know`);
        // });




    }



    useEffect(() => {
        // console.log("data to show", props.originalColumn)
        // console.log("data to show", props.fullPatientDataList)
        let temp = []
        props.originalColumn.map((val, ind) => {
            setCloumnVisibility(value => ({
                ...value, ["Visibility" + val.columnId]: true
            }))
        })
        setShowRow(temp)
    }, [props.originalColumn])
    const keyId = useId();

    useEffect(() => {
    }, [props])
    return (
        <div className="secondSection">
            <table className="table-monitor" id="table2">
                <thead className="fixedTheadSecond">
                    <tr key={"TableheadKey2" + keyId}>
                        {props.originalColumn && props.originalColumn.map((value, index) => {
                            let allObje = Object.keys(patientMonitorDashboard)
                            let color1 = ""
                            let color2 = ""
                            let color3 = ""
                            // allObje.map((val, ind) => {
                            //     let splitData = Number(val.split("_")[1])
                            //     // console.log("columns", value.columnId)
                            //     if (value.columnId === splitData) {
                            //         color1 = patientMonitorDashboard[val][0]
                            //         color2 = patientMonitorDashboard[val][1]
                            //         color3 = patientMonitorDashboard[val][2]
                            //     }
                            // })

                            return (

                                cloumnVisibility["Visibility" + value.columnId]
                                &&
                                <th key={index} className='' style={{ width: value.columnWidth + "px" }}  name={value.columnId} id={value.columnId} data-json={JSON.stringify(value)}>
                                    {/* <div onClick={moveCol}> */}
                                    <div >
                                        {value.columnName}{" "}
                                        <div className='d-flex flex-row gap-1'>
                                            <span style={{ background: color1, width: "10px", height: "10px", borderRadius: "10px" }}></span>
                                            <span style={{ background: color2, width: "10px", height: "10px", borderRadius: "10px" }}></span>
                                            <span style={{ background: color3, width: "10px", height: "10px", borderRadius: "10px" }}></span>
                                        </div>
                                    </div>
                                    <i
                                        data-bs-toggle="modal" data-bs-target="#deleteModal"
                                        className="bi bi-dash-circle remove-icon"
                                        title="Remove Column" onClick={() => { setColumnId(value.columnId); props.setIsGridView(0) }}
                                    ></i>
                                </th>

                            )

                        })}

                    </tr>
                </thead>

                <tbody>
                    {/* show data for patient vitals or second halif*/}
                    {
                        props.fullPatientDataList && props.fullPatientDataList.slice(0, props.showNextData).map((val, ind) => {
                            return (
                                <tr key={ind}>

                                    {props.originalColumn && props.originalColumn.map((values, ind) => {
                                        return (
                                            cloumnVisibility["Visibility" + values.columnId] &&
                                            <td >
                                                <SearchComponent columnId={values.columnId} val={val} />
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {/* <!-- Modal --> */}
            <div className={`modal d-${columnId === null ? 'none' : 'block'}`} >
                <div className="modal-dialog modalDelete" style={{ width: "300px" }}>
                    <div className="modal-content">

                        <div className="modal-body modelbdy text-center">
                            <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                            <div className='popDeleteTitle mt-3'>Remove?</div>
                            <div className='popDeleteContent'>Are you sure you want to remove this column?</div>
                        </div>
                        <div className="modal-footer1 text-center">

                            <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal" onClick={()=>{setColumnId(null)}}>No</button>
                            <button type="button" className="btn-delete popBtnDelete" onClick={handleColumnVisisbility} data-bs-dismiss="modal">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
