import React, { useEffect, useState } from 'react'
import ventilatorGif from "../assests/images/ventiGif.gif"
import Vectorline from "../assests/images/Vectorline.png"

export default function IOSection(props) {
  let [IOData, setIOData] = useState(null)
  let [IOProblemType, setIOProblemType] = useState(null)
  useEffect(() => {
    console.log("IOs", props.patientIOData)
    if (props.patientIOData && props.patientIOData.length > 1 && props.patientIOData[0] && props.patientIOData[0].length !== 0) {
      let ioinputValue = 0
      let iooutputValue = 0
      props.patientIOData[0].map((val) => {
        ioinputValue = ioinputValue + val.input
      })
      props.patientIOData[0].map((val) => {
        iooutputValue = iooutputValue + val.output
      })
      setIOData({ "input": ioinputValue, "output": iooutputValue })
    }
    if (props.patientIOData && props.patientIOData.length > 1 && props.patientIOData[1] && props.patientIOData[1].length !== 0) {
      props.patientIOData && setIOProblemType(props.patientIOData[1][0])
    }
  }, [props])
  return (
    <>
      <div className='arrow-IOwrawrap'>
        <div className='IO-wrap'>
          <div className='IO-cotainer'>
            <div className='d-flex flex-column justify-content-center IO-data'>
              <div className='d-flex flex-column gap-1'>
                <div className='d-flex flex-row justify-content-center align-items-center gap-1'>
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                  <span>{IOData && IOData.input}</span>
                </div>
                <div className='d-flex flex-row justify-content-center align-items-center gap-1'>
                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                  <span>{IOData && IOData.output}</span>
                </div>
              </div>
              <div className='text-center'>
                <span style={{ color: IOProblemType !== null ? IOProblemType.urineOutputColor : "black", fontSize: "12px", fontWeight: "600", }} >
                  {/* <i className="bi bi-lungs-fill"></i>&nbsp; */}
                  {/* {
                  IOProblemType && IOProblemType.urineProblem
                } */}
                  Oliguria
                </span>
              </div>

            </div>
          </div>
        </div>
        <div className='arrow1'><img src={Vectorline} alt='' className='vectorpc' /></div>
      </div>
    </>
  )
}
