import React, { useEffect, useRef, useState } from 'react'
import i18n, { t } from "i18next";
import TableContainer from '../../../Component/TableContainer';
import Heading from '../../../Component/Heading';
import BoxContainer from '../../../Component/BoxContainer';
import clearIcon from '../../../assets/images/icons/clear.svg';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg'
import Iconcopy from '../../../assets/images/icons/Icon_copy.png'
import DropdownWithSearch from '../../../Component/DropdownWithSearch'
import Createitem from '../../../assets/images/dmsimg/creatitemicon.svg'
import GetStateMasterById from '../../../SuperAdmin/Api/Master/StateMaster/GetStateMasterById';
import PostVendorMaster from '../../API/VendorMaster/PostVendorMaster';
import AlertToster from '../../../Component/AlertToster';
import SuccessToster from '../../../Component/SuccessToster';
import { useTranslation } from 'react-i18next';
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Toster from '../../../Component/Toster';
import Loader from '../../../Component/Loader';
import GetAllSupplier from '../../API/SupplierMaster/GetAllSupplier';
import plussign from '../../../assets/images/dmsimg/plus_sign.png';
import GetAllCountry from '../../API/SupplierMaster/GetAllCountry';
import GetCItyById from '../../../SuperAdmin/Api/Master/CityMaster/GetCItyById';
import DropDownSearchComponent from '../../../Component/DropDownSearchComponent';
import GetAllBankByCountry from '../../API/SupplierMaster/GetAllBankByCountry';
import GetAccountType from '../../API/SupplierMaster/GetAccountType';
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import DeleteVendorMaster from '../../API/VendorMaster/DeleteVendorMaster';

export default function CreateNewSupplier() {

    const { t } = useTranslation();
    document.body.dir = i18n.dir();

    /////*******************************Usestate section **********************************///////////////////////////////
    // const [sendForm, setSendForm] = useState({ "UserID": window.userId,  "ClientID": window.clientId,
    //     supplierName: '', gstName: '', gstTypeName: 'Regular', contactPersonName: '', contactNumber1Name: '', contactNumber2Name: '', emailName: '', address1Name: '', address2Name: '',
    //     cityName: '', stateName: 0, pincodeName: '', bankName: '', branchName: '', accountNoName: '', ifscName: '', additionalNotesName: ''
    // });
    let [sendForm, setSendForm] = useState({
        "UserID": window.userId,
        "ClientID": window.clientId,
        "vendorName": "",
        "gstIn": "",
        "gstType": "Regular",
        "contactPerson": "",
        "contactNo": "",
        "officeContactNo": "",
        "emailID": "",
        "addressLine1": "",
        "addressLine2": "",
        "countryID": 0,
        "stateID": 0,
        "cityID": 0,
        "pinCode": "",
        "additionalNotes": "",
        "vendorAccountJson": ""
    })
    const [supplierList, setSupplierList] = useState("")
    const [bankDetails, setBankDetails] = useState([])
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [bankList, setBankList] = useState([]);
    const [allBanks, setAllBanks] = useState([]);
    const [accountTypeList, setAccountTypeList] = useState([]);
    const [showMessage, setShowMeassage] = useState("")
    const [showSuccessToster, setShowSuccessToster] = useState(0)

    const [showUnderProcess, setShowUnderProcess] = useState(0);
    const [showToster, setShowToster] = useState(0);
    const [tosterMessage, setTosterMessage] = useState("");
    const [tosterValue, setTosterValue] = useState(0);
    const [showLoder, setShowLoder] = useState(0);
    const [isShowToaster, setisShowToaster] = useState(0);
    const [showAlertToster, setShowAlertToster] = useState(0);
    const [showErrMessage, setShowErrMessage] = useState('');
    const [showSuccessMsg, setShowSuccessMsg] = useState('');
    const [updateBool, setUpdateBool] = useState(0);
    const [rowId, setRowId] = useState(0);

    let [clearDropdown, setClearDropdown] = useState(0)
    let [editCountry, setEditCountry] = useState("")
    let [editState, setEditState] = useState("")
    let [editCity, setEditCity] = useState("")
    let [editType, setEditType] = useState("")
    let [editBank, setEditBank] = useState("")

    const [countrySelected, setCountrySelected] = useState(false);
    const [errEmailMessage, setErrEmailMessage] = useState(false);

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    const [rows, setRows] = useState([{ id: 1 }]);

    const newRow = {
        bankID: 0,
        accountHolderName: "",
        branchName: "",
        accountTypeID: 0,
        accountNo: "",
        ifsc: "",
    };
    const [bankDetailsRows, setBankDetailsRows] = useState([newRow]);

    const validateLastRow = () => {
        const lastRow = bankDetailsRows[bankDetailsRows.length - 1];
        return Object.values(lastRow).every(value => value !== "" && value !== 0);
    };
    //add and remove function supplier bank details rows
    // const addbankdataRow = () => {
    //     setBankDetailsRows((prevRows) => [...prevRows, newRow]);
    //     console.log("newRow", newRow)
    // };
    const addbankdataRow = () => {
        if (validateLastRow()) {
            // setBankDetailsRows((prevRows) => [...prevRows, {newRow}]);
            setBankDetailsRows((prevRows) => [...prevRows, {
                bankID: 0,
                accountHolderName: "",
                branchName: "",
                accountTypeID: 0,
                accountNo: "",
                ifsc: "",
            }]);
        } else {
            setTosterValue(1);
            setShowToster(1);
            setTosterMessage("Please fill out all fields in the current row before adding a new one.")
            setTimeout(() => {
                setShowToster(0);
            }, 2000)

        }
    };

    const removebankdataRow = (ind) => {
        let updateRow = [...bankDetailsRows];
        if (bankDetailsRows.length === 1) {
            updateRow.splice(ind, 1);
            setBankDetailsRows([{
                bankID: 0,
                accountHolderName: "",
                branchName: "",
                accountTypeID: 0,
                accountNo: "",
                ifsc: "",
            }]);
        } else if (bankDetailsRows.length > 1) {
            updateRow.splice(ind, 1);
            setBankDetailsRows([...updateRow]);
        }
    };


    //Get Supplier data
    const getdata = async () => {
        const { status, responseValue } = await GetAllSupplier(clientID);
        if (status === 1) {
            setSupplierList(responseValue);
            const parsedBankDetails = responseValue.map(supplier => {
                return supplier.bankDetails ? JSON.parse(supplier.bankDetails) : [];
            });
            const flatBankDetails = parsedBankDetails.flat();
            setBankDetails(flatBankDetails);
        }
    };

    // Get Country
    let getCountry = async () => {
        let getResponse = await GetAllCountry();
        if (getResponse.status === 1) {
            setCountryList(getResponse.responseValue);
        }
    };

    // Get State
    let getState = async (countryID) => {
        const resState = await GetStateMasterById(countryID);
        if (resState.status === 1) {
            setStateList(resState.responseValue);
            console.log("stateList", resState.responseValue)
        } else {
            setStateList([]);
        }
    };


    // Get City
    let getCity = async (stateID) => {
        const resCity = await GetCItyById(stateID);
        if (resCity.status === 1) {
            setCityList(resCity.responseValue);
            console.log("cityList", resCity.responseValue)
        } else {
            setCityList([]);
        }
    };
    // Get Bank
    let getBank = async () => {
        let resbank = await GetAllBankByCountry(clientID);
        if (resbank.status === 1) {
            setAllBanks(resbank.responseValue);
        }
    };
    // Get Country
    let getAccountType = async () => {
        let restype = await GetAccountType(clientID);
        if (restype.status === 1) {
            setAccountTypeList(restype.responseValue);
            console.log("accounttype", restype.responseValue)
        }
    };
    const userID = JSON.parse(window.sessionStorage.getItem("LoginData")).userId;

    //HandleChange
    const handleChange = (e, index) => {
        clearValidationErrMessage();
        const { name, value, type, checked } = e.target;
        setEditCountry("");
        setEditState("");
        setEditCity("");
        setEditType("")
        setEditBank("")
        setSendForm(sendForm => ({
            ...sendForm,
            [name]: value
        }));
        if (name === 'emailID') {
            if (!emailRegex.test(value)) {
                console.log('Invalid email');
                setErrEmailMessage(true);
            } else {
                console.log('Valid email');
                setErrEmailMessage(false);
            }
        }
        if (name === "countryID") {
            setEditState(0);
            setEditCity(0);
            setStateList([]);
            setCityList([]);
            setBankList([]);
            setCountrySelected(true);
            getState(value);
            const filteredBanks = allBanks.filter(bank => bank.countryId === parseInt(value));
            setBankList(filteredBanks);
        }
        if (name === "stateID") {
            setEditCity(0);
            setCityList([]);
            getCity(value);
        }
        if (type === 'radio') {
            setSendForm(prevForm => ({
                ...prevForm,
                [name]: value
            }));
        }
        const updatedRows = [...bankDetailsRows];
        if (name === "accountNo" || name === "accountHolderName" || name === "accountTypeID" || name === "bankID" || name === "branchName" || name === "ifsc") {
            updatedRows[index][name] = value;
        }
        setRows(updatedRows)
    };





    //Handle Save
    const handleSave = async () => {
        const bankData = rows.map((row, index) => {
            return {
                bankID: rows[index].bankID,
                accountHolderName: rows[index].accountHolderName,
                branchName: rows[index].branchName,
                accountTypeID: rows[index].accountTypeID,
                accountNo: rows[index].accountNo,
                ifsc: rows[index].ifsc,
            }
        });
        if (!validateLastRow()) {
            setTosterValue(1);
            setShowToster(1);
            setTosterMessage("Please fill out all fields of bank details.");
            setTimeout(() => {
                setShowToster(0);
            }, 2000);
            return;
        }

        if (errEmailMessage) {
            document.getElementById('errEmailID').innerHTML = 'Please enter a valid email address.';
            document.getElementById('errEmailID').style.display = 'block';
        }
        else if (sendForm.vendorName.trim().length === '' || sendForm.vendorName === '' || sendForm.vendorName === null || sendForm.vendorName === undefined) {
            document.getElementById('errSupplierName').innerHTML = 'Supplier name is required.';
            document.getElementById('errSupplierName').style.display = 'block';
        }
        else if (sendForm.gstIn.trim().length === '' || sendForm.gstIn === '' || sendForm.gstIn === null || sendForm.gstIn === undefined) {
            document.getElementById('errGstIn').innerHTML = 'Please fill GST number.';
            document.getElementById('errGstIn').style.display = 'block';
        }
        else if (sendForm.contactNo.trim().length === '' || sendForm.contactNo === '' || sendForm.contactNo === null || sendForm.contactNo === undefined) {
            document.getElementById('errContact').innerHTML = 'Please fill valid contact no.';
            document.getElementById('errContact').style.display = 'block';
        }
        else if (sendForm.addressLine1.trim().length === '' || sendForm.addressLine1 === '' || sendForm.addressLine1 === null || sendForm.addressLine1 === undefined) {
            document.getElementById('errAddress').innerHTML = 'Please fill address.';
            document.getElementById('errAddress').style.display = 'block';
        }
        else if (sendForm.countryID === 0 || sendForm.countryID === null || sendForm.countryID === undefined) {
            document.getElementById('errCountry').innerHTML = 'Please select Country.';
            document.getElementById('errCountry').style.display = 'block';
        }
        else if (sendForm.stateID === 0 || sendForm.stateID === null || sendForm.stateID === undefined) {
            document.getElementById('errState').innerHTML = 'Please select state.';
            document.getElementById('errState').style.display = 'block';
        }
        else if (sendForm.cityID === 0 || sendForm.cityID === null || sendForm.cityID === undefined) {
            document.getElementById('errCity').innerHTML = 'Please select city.';
            document.getElementById('errCity').style.display = 'block';
        }
        else if (sendForm.pinCode.trim().length === '' || sendForm.pinCode === '' || sendForm.pinCode === null || sendForm.pinCode === undefined) {
            document.getElementById('errPincode').innerHTML = 'Please fill pincode.';
            document.getElementById('errPincode').style.display = 'block';
        }

        else {
            const finalObj = {
                vendorName: sendForm.vendorName,
                gstIn: sendForm.gstIn,
                gstType: sendForm.gstType,
                contactPerson: sendForm.contactPerson,
                contactNo: sendForm.contactNo,
                officeContactNo: sendForm.officeContactNo,
                emailID: sendForm.emailID,
                addressLine1: sendForm.addressLine1,
                addressLine2: sendForm.addressLine2,
                countryID: sendForm.countryID,
                stateID: sendForm.stateID,
                cityID: sendForm.cityID,
                pinCode: sendForm.pinCode,
                additionalNotes: sendForm.additionalNotes,
                vendorAccountJson: JSON.stringify(bankData),
                userID: userID,
                clientId: clientID

            };
            console.log('finalObj : ', finalObj);
            // return;
            setShowUnderProcess(1);
            const resSave = await PostVendorMaster(finalObj)
            if (resSave.status === 1) {
                setShowUnderProcess(0);
                setShowToster(1);
                setTosterMessage(t("Data Saved Successfully"));
                handleClear();
                getdata();
                setTosterValue(0);
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage(resSave.responseValue)
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }
        }
    }

    //Handle Delete
    const handleDelete = async () => {
        setShowLoder(1);
        // let obj = {
        //     id: rowId
        // }
        const response = await DeleteVendorMaster(rowId);
        if (response.status === 1) {
            setShowLoder(0)
            setisShowToaster(1);
            setShowSuccessMsg("Deleted Successfully.")
            setTimeout(() => {
                setisShowToaster(0);
                getdata();
            }, 1500)
            handleClear();
        }
        else {
            setShowLoder(0);
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }

    const handleClear = (value) => {
        clearValidationErrMessage();
        setClearDropdown(value)
        setEditCountry(0);
        setEditState(0);
        setEditCity(0);
        setEditType(0)
        setEditBank(0)
        setSendForm(prevSendForm => ({
            ...prevSendForm,
            UserID: window.userId,
            ClientID: window.clientId,
            vendorName: "",
            gstIn: "",
            gstType: "",
            contactPerson: "",
            contactNo: "",
            officeContactNo: "",
            emailID: "",
            addressLine1: "",
            addressLine2: "",
            countryID: 0,
            stateID: 0,
            cityID: 0,
            pinCode: "",
            additionalNotes: "",
            vendorAccountJson: ""
        }));
        setStateList([]);
        setCityList([]);
        setBankList([]);
        setBankDetailsRows([newRow]);
    }
    const clearValidationErrMessage = () => {
        document.getElementById('errSupplierName').style.display = 'none';
        document.getElementById('errGstIn').style.display = 'none';
        document.getElementById('errContact').style.display = 'none';
        document.getElementById('errAddress').style.display = 'none';
        document.getElementById('errCountry').style.display = 'none';
        document.getElementById('errState').style.display = 'none';
        document.getElementById('errCity').style.display = 'none';
        document.getElementById('errPincode').style.display = 'none';
        document.getElementById('errEmailID').style.display = 'none';
    }
    const copyBankDetailsToClipboard = (details, index) => {
        const bankDetailsString = `
            Bank Name: ${details.BankName}
            Branch Name: ${details.BranchName}
            Account Holder: ${details.AccountHolderName}
            Account Number: ${details.AccountNo}
            Account Type: ${details.AccountTypeName}
            IFSC: ${details.Ifsc}
        `;

        navigator.clipboard.writeText(bankDetailsString)
            .then(() => {
                const copyMsg = document.getElementById(`copymsg${index}`);
                copyMsg.style.display = "block";
                setTimeout(() => {
                    copyMsg.style.display = "none";
                }, 2000);
            })
            .catch(err => {
                console.error('Failed to copy bank details: ', err);
            });
    };

    useEffect(() => {
        getdata();
        getCountry();
        getState();
        getCity();
        getAccountType();
        getBank();
    }, []);
    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 mt-2">
                            <div className='handlser serinventory med-box'>
                                <div className='demandMain-inn p-2'>
                                    <div className="title mt-2 pe-2 ps-2 bordertitle">{t("All Supplier")}</div>
                                </div>
                                <div className='serchbtn-sec'>
                                    <div style={{ position: 'relative' }}>
                                        <input type="text" className='form-control form-control-sm' placeholder={t("Search")} onChange={'handleSearch'} />
                                        <span className="sericnInventory"><i className="fas fa-search"></i></span>
                                    </div>
                                    <div data-bs-toggle="modal" data-bs-target="#itemDetailsBackdrop">
                                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1"><i class="bi bi-plus-circle-dotted"></i> {t("Create New Item")}

                                            {/* <i class="bi bi-file-plus ms-1"></i> */}
                                            {/* <img src={Createitem} className='icnn' alt='' />  */}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="med-table-section ps-2 pe-2" style={{ "height": "84vh" }}>
                                <table className='med-table thinventory striped'>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>#</th>
                                            <th>Supplier Name</th>
                                            <th>GST No</th>
                                            <th>Contact Person</th>
                                            <th>Phone No</th>
                                            <th>Email</th>
                                            <th>Address</th>
                                            <th style={{ "width": "10%" }} className="text-center">Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {supplierList && supplierList.map((list, ind) => {
                                            const bankDetails = list.bankDetails ? JSON.parse(list.bankDetails) : [];
                                            return (
                                                <tr key={list.id}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td>{list.vendorName}</td>
                                                    <td>{list.gstIn}</td>
                                                    <td>{list.contactPerson}</td>
                                                    <td>{list.contactNo}</td>
                                                    <td>{list.emailID}</td>
                                                    <td>{list.addressLine1}</td>
                                                    <td className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <span data-bs-toggle="modal" data-bs-target={"#supplierDetailsBackdrop" + ind}>
                                                            <i className="fa fa-eye actionedit viewaction "></i>
                                                        </span>
                                                        <div className="action-button">
                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom">
                                                                {/* <img src={editBtnIcon} className="" alt="" onClick={() => { handleUpdate(list); }}   /> */}
                                                            </div>
                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal">
                                                                <img src={deleteBtnIcon} className="" alt="" onClick={() => { setRowId(list.id); }} />
                                                            </div>
                                                        </div>
                                                        <div className="modal fade" id={"supplierDetailsBackdrop" + ind} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="supplierDetailsBackdropLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-lg additonaldetails" >
                                                                <div className="modal-content position-relative" style={{ background: '#F9FAFC' }}>
                                                                    <div className="modal-header_ bg-clrr mt-0 pt-0 mb-0 pb-0">
                                                                        <div className="modalheading">
                                                                            <i className="fa fa-times" data-bs-dismiss="modal" aria-label="Close"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-body mt-0 pt-0">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <div className="itemMedBox">
                                                                                    <div className='itempopupheadr'>
                                                                                        <div className='demandMain-inn'>
                                                                                            <div className="title ps-2 bordertitle">Supplier Details</div>
                                                                                        </div>
                                                                                        {/* <div onclick={handlePrint} style={{ cursor: 'pointer' }}>
                                                        <span className='itemprint'>Print Supplier Details</span>
                                                        <span><img src={iconItemPrint} alt='' /></span>
                                                    </div> */}
                                                                                    </div>
                                                                                    <div className="inner-content mt-2" style={{ maxHeight: '72vh', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                                                        <div className='row'>
                                                                                            <div className='col-12'>
                                                                                                <div className="med-table-section_ alldemandtbl_">
                                                                                                    <table className='med-table border_ striped_'>
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td colSpan={2}>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Supplier Name</label>
                                                                                                                        <span>{list.vendorName}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>GST Number</label>
                                                                                                                        <span>{list.gstIn}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>GST Type</label>
                                                                                                                        <span>{list.gstType}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Contact Person</label>
                                                                                                                        <span>{list.contactPerson}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Contact Number 1</label>
                                                                                                                        <span>{list.contactNo}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Contact Number 2</label>
                                                                                                                        <span>{list.officeContactNo}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Email</label>
                                                                                                                        <span>{list.emailID}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td colSpan={2}>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Address</label>
                                                                                                                        <span>{list.addressLine1}, {list.addressLine2}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>City</label>
                                                                                                                        <span>{list.cityName}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>State</label>
                                                                                                                        <span>{list.stateName}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Country</label>
                                                                                                                        <span>{list.countryName}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Pin Code</label>
                                                                                                                        <span>{list.pincode}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td colSpan={2}>
                                                                                                                    <div className='prodctdtls'>
                                                                                                                        <label>Additional Notes</label>
                                                                                                                        <span>{list.additionalNotes ? list.additionalNotes : 'N/A'}</span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>

                                                                                                </div>

                                                                                                {bankDetails.length > 0 ? (
                                                                                                    bankDetails.map((details, index) => (
                                                                                                        <div key={index} className='bankdtlstbl'>
                                                                                                            <div className='itempopupheadr'>
                                                                                                                <div className="bankheading">Supplier Bank Details</div>

                                                                                                                <div id={`copymsg${index}`} style={{ display: 'none', fontSize: '11px', color: 'green', float: 'right' }}>Bank details copied to clipboard!</div>
                                                                                                                <div style={{ cursor: 'pointer' }} onClick={() => copyBankDetailsToClipboard(details, index)}>
                                                                                                                    <span className='itemprint'>Copy Bank Details</span>
                                                                                                                    <span className={"copyIcon" + index}><img src={Iconcopy} alt='' /></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <table className='med-table'>
                                                                                                                <tbody>
                                                                                                                    <tr>
                                                                                                                        <td>
                                                                                                                            <div className='prodctdtls'>
                                                                                                                                <label>Bank Name</label>
                                                                                                                                <span>{details.BankName}</span>
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <div className='prodctdtls'>
                                                                                                                                <label>Branch Name</label>
                                                                                                                                <span>{details.BranchName}</span>
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>
                                                                                                                            <div className='prodctdtls'>
                                                                                                                                <label>Account Holder</label>
                                                                                                                                <span>{details.AccountHolderName}</span>
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <div className='prodctdtls'>
                                                                                                                                <label>Account Number</label>
                                                                                                                                <span>{details.AccountNo}</span>
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>
                                                                                                                            <div className='prodctdtls'>
                                                                                                                                <label>Account Type</label>
                                                                                                                                <span>{details.AccountTypeName}</span>
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <div className='prodctdtls'>
                                                                                                                                <label>IFSC</label>
                                                                                                                                <span>{details.Ifsc}</span>
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                    <tr>

                                                                                                                    </tr>
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    ))
                                                                                                ) : (
                                                                                                    <div className='bankdtlstbl'>
                                                                                                        <div className='itempopupheadr'>
                                                                                                            <div className="bankheading">Supplier Bank Details</div>
                                                                                                        </div>
                                                                                                        <div>No bank details available.</div>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>


                                </table>
                                {/* -----------------------Start Delete Modal Popup-------------------   */}

                                {/*  <!-- Modal -->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">

                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'>Delete?</div>
                                                <div className='popDeleteContent'>Are you sure want to delete?</div>
                                            </div>
                                            <div className="modal-footer1 text-center">

                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={handleDelete} data-bs-dismiss="modal">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

                            </div>
                        </div>
                    </div>
                </div>

                {/* --------------------------------------------Start Create New Item Popup-------------------------------------- */}
                <div className="modal fade" id="itemDetailsBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="itemDetailsBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-xl additonaldetails">
                        <div className="modal-content">
                            {/* <div className="modal-header_ bg-clrr mt-0 pt-0 mb-0 pb-0">
                                <div className="heading text-wrap">
                                    <div className='demandMain-inn p-2'>
                                        <div className="title pe-2 ps-2 bordertitle">{t("Create New Item")}</div>
                                    </div>
                                </div>
                                <div>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div> */}
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="inventoryMedBox">
                                            <div className='popupheadr'>
                                                <div className='demandMain-inn'>
                                                    <div className="title ps-2 bordertitle">{t("Create New Supplier")}</div>
                                                </div>
                                                <div>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClear}></button>
                                                </div>
                                            </div>


                                            <div className="inner-content mt-2">
                                                <div className='row'>
                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3" >
                                                        <label htmlFor="" className="form-label">{t("Supplier Name")}<span className="starMandatory">*</span></label>
                                                        <input type="text" className="form-control form-control-sm" id="vendorName" placeholder="Enter Supplier Name" name="vendorName" value={sendForm.vendorName} onChange={handleChange} />
                                                        <small id="errSupplierName" className="form-text text-danger" style={{ display: "none" }}></small>
                                                    </div>
                                                    <div className="col-xxl-6 col-xl-6 col-lg-4 col-md-6 mb-3" >
                                                        <label htmlFor="" className="form-label">{t("GSTIN")}<span className="starMandatory">*</span></label>
                                                        <input type="text" className="form-control form-control-sm" id="gstIn" placeholder="Enetr GST No." name="gstIn" value={sendForm.gstIn} onChange={handleChange} />
                                                        <small id="errGstIn" className="form-text text-danger" style={{ display: "none" }}></small>
                                                    </div>
                                                    <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3">
                                                        <label htmlFor="" className="form-label">{t("GST Type")}<span className="starMandatory"></span></label>
                                                        <div className='row pt-1'>
                                                            <div className='col-6'>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="gstType" id="regularID" value="Regular" checked={sendForm.gstType === "Regular"} onChange={handleChange} />
                                                                    <label htmlFor="regularID" className="form-label">{t("Regular")}</label>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 ps-0 pe-0'>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="gstType" id="compositionID" value="Composition" checked={sendForm.gstType === "Composition"} onChange={handleChange} />
                                                                    <label htmlFor="compositionID" className="form-label">{t("Composition")}</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <small id="errgstType" className="form-text text-danger" style={{ display: "none" }}></small>
                                                    </div>
                                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                                        <label htmlFor="" className="form-label">{t("Contact Person")}</label>
                                                        <input type="text" className="form-control form-control-sm" id="contactPerson" placeholder="Enter contact person" name="contactPerson" value={sendForm.contactPerson} onChange={handleChange} />
                                                        {/* <small id="errContactPerson" className="form-text text-danger" style={{ display: "none" }}></small> */}
                                                    </div>
                                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                                        <label htmlFor="" className="form-label">{t("Contact No. 1")}<span className="starMandatory">*</span></label>
                                                        <input type="number" className="form-control form-control-sm" id="contactNo" placeholder="Enter contact number" name="contactNo" minLength="10" maxLength="10" pattern="[0-9]{10}" title="Contact number must be 10 digits long" value={sendForm.contactNo} onChange={handleChange} />
                                                        <small id="errContact" className="form-text text-danger" style={{ display: "none" }}></small>
                                                    </div>
                                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                                        <label htmlFor="" className="form-label">{t("Contact No. 2")}</label>
                                                        <input type="number" className="form-control form-control-sm" id="officeContactNo" placeholder="Enter contact number" name="officeContactNo" minLength="10" maxLength="10" pattern="[0-9]{10}" title="Contact number must be 10 digits long" value={sendForm.officeContactNo} onChange={handleChange} />
                                                    </div>
                                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                                        <label htmlFor="" className="form-label">{t("Email")}</label>
                                                        <input type="email" className="form-control form-control-sm" id="emailID" placeholder="Enter email address" name="emailID" value={sendForm.emailID} onChange={handleChange} />
                                                        <small id="errEmailID" className="form-text text-danger" style={{ display: "none" }}></small>
                                                    </div>
                                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3" >
                                                        <label htmlFor="" className="form-label">{t("Address Line 1")}<span className="starMandatory">*</span></label>
                                                        <input type="text" className="form-control form-control-sm" id="addressLine1" placeholder="Enter address" name="addressLine1" value={sendForm.addressLine1} onChange={handleChange} />
                                                        <small id="errAddress" className="form-text text-danger" style={{ display: "none" }}></small>
                                                    </div>
                                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3" >
                                                        <label htmlFor="" className="form-label">{t("Address Line 2")}</label>
                                                        <input type="text" className="form-control form-control-sm" id="addressLine2" placeholder="Enter address" name="addressLine2" value={sendForm.addressLine2} onChange={handleChange} />
                                                    </div>

                                                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 mb-3" >
                                                        <label htmlFor="" className="form-label">{t("Country")}<span className="starMandatory">*</span></label>
                                                        {countryList && <DropdownWithSearch defaulNname="Select country" name="countryID" list={countryList} valueName="id" displayName="countryName" editdata={editCountry} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}

                                                        <small id="errCountry" className="form-text text-danger" style={{ display: "none" }}></small>
                                                    </div>
                                                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 mb-3" >
                                                        <label htmlFor="" className="form-label">{t("State")}<span className="starMandatory">*</span></label>
                                                        {stateList && <DropdownWithSearch defaulNname="Select state" name="stateID" list={stateList} valueName="id" displayName="stateName" editdata={editState} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                                        <small id="errState" className="form-text text-danger" style={{ display: "none" }}></small>
                                                    </div>
                                                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 mb-3" >
                                                        <label htmlFor="" className="form-label">{t("City")}<span className="starMandatory">*</span></label>
                                                        {cityList && <DropdownWithSearch defaulNname="Select city" name="cityID" list={cityList} valueName="id" displayName="name" editdata={editCity} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                                        <small id="errCity" className="form-text text-danger" style={{ display: "none" }}></small>
                                                    </div>

                                                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 mb-3" >
                                                        <label htmlFor="" className="form-label">{t("Pin Code")}<span className="starMandatory">*</span></label>
                                                        <input type="number" className="form-control form-control-sm" id="pinCode" placeholder="Enter pincode" name="pinCode" value={sendForm.pinCode} onChange={handleChange} min={6} max={6} />
                                                        <small id="errPincode" className="form-text text-danger" style={{ display: "none" }}></small>
                                                        {/* <span id="errPincode" className="text-danger" style={{ display: 'none' }}>Please enter exactly 6 digits.</span> */}

                                                    </div>

                                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-6 mb-3" >
                                                        <label htmlFor="" className="form-label">{t("Additional Notes")}</label>
                                                        <input type="text" className="form-control form-control-sm" id="additionalNotes" placeholder="Enetr additional notes" name="additionalNotes" value={sendForm.additionalNotes} onChange={handleChange} />

                                                    </div>


                                                </div>
                                                {/* ------------------Start Supplier BAnk Details------------------------------ */}
                                                <div className='bankdtlsrows'>
                                                    {bankDetailsRows && bankDetailsRows.map((row, ind) => {
                                                        return (
                                                            <div className='supplrcontent mt-2 p-2'>
                                                                <div className='headmultirow'>
                                                                    <div className="multirowheading mb-2">{t("Supplier Bank Details")}</div>
                                                                    <div onClick={() => removebankdataRow(ind)}><i className="fa fa-minus"></i></div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-3" >
                                                                        <label htmlFor="" className="form-label">{t("Account Number")}<span className="starMandatory"></span></label>
                                                                        <input type="number" className="form-control form-control-sm" id={`accountNo_${ind}`} value={row.accountNo} placeholder="Enter account no." name="accountNo" onChange={(event) => handleChange(event, ind)} />
                                                                        <small id="errAcoountNo" className="form-text text-danger" style={{ display: "none" }}></small>
                                                                    </div>
                                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-3" >
                                                                        <label htmlFor="" className="form-label">{t("Account Holder’s Name")}<span className="starMandatory"></span></label>
                                                                        <input type="text" className="form-control form-control-sm" id={`accountHolderName_${ind}`} value={row.accountHolderName} placeholder="Enter account holder’s name" name="accountHolderName" onChange={(event) => handleChange(event, ind)} />
                                                                        <small id="errAcoountNo" className="form-text text-danger" style={{ display: "none" }}></small>
                                                                    </div>
                                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-3" >
                                                                        <label htmlFor="" className="form-label">{t("Account Type")}<span className="starMandatory"></span></label>
                                                                        {/* {accountTypeList && <DropdownWithSearch defaulNname="Select account type" name="accountTypeID" list={accountTypeList} valueName="id" displayName="accountTypeName" editdata={editType} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />} */}
                                                                        <select id={`accountTypeID_${ind}`} name='accountTypeID' value={row.accountTypeID} className="form-select form-select-sm" onChange={(event) => handleChange(event, ind)}>
                                                                            <option value="0" selected>Select account type</option>
                                                                            {accountTypeList && accountTypeList.map((val, ind) => {
                                                                                return (
                                                                                    <option value={val.id}>{val.accountTypeName}</option>
                                                                                )
                                                                            }
                                                                            )}
                                                                        </select>
                                                                        <small id="errAcoountType" className="form-text text-danger" style={{ display: "none" }}></small>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-3" >
                                                                        <label htmlFor="" className="form-label">{t("Bank Name")}<span className="starMandatory"></span></label>
                                                                        {/* {bankList && <DropdownWithSearch defaulNname="Select bank" name="bankID" list={bankList} valueName="id" displayName="bankName" getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />} */}

                                                                        {countrySelected ? (
                                                                            bankList.length > 0 ? (
                                                                                <select id={`bankID_${ind}`} name='bankID' value={row.bankID} className="form-select form-select-sm" onChange={(event) => handleChange(event, ind)}>
                                                                                    <option value="0" selected>Select bank</option>
                                                                                    {bankList.map((val, index) => (
                                                                                        <option key={index} value={val.bankId}>{val.bankName}</option>
                                                                                    ))}
                                                                                </select>
                                                                            ) : (
                                                                                <div className='notavlble'>No bank available in this country</div>
                                                                            )
                                                                        ) : (
                                                                            <div className='notavlble'>Select a country to show banks</div>
                                                                        )}
                                                                        <small id="errAcoountNo" className="form-text text-danger" style={{ display: "none" }}></small>
                                                                    </div>
                                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-3" >
                                                                        <label htmlFor="" className="form-label">{t("Branch Name")}</label>
                                                                        <input type="text" className="form-control form-control-sm" id={`branchName_${ind}`} value={row.branchName} placeholder="Enter branch name" name="branchName" onChange={(event) => handleChange(event, ind)} />
                                                                    </div>
                                                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-3" >
                                                                        <label htmlFor="" className="form-label">{t("IFSC")}<span className="starMandatory"></span></label>
                                                                        <input type="text" className="form-control form-control-sm" id={`ifsc_${ind}`} value={row.ifsc} placeholder="Enter IFSC" name="ifsc" onChange={(event) => handleChange(event, ind)} />
                                                                        <small id="errIfsc" className="form-text text-danger" style={{ display: "none" }}></small>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3 ps-0 pe-2">
                                                                        <div className='addbtnsuplr d-flex justify-content-end'><button type='button' onClick={addbankdataRow}><img src={plussign} className='icnn' alt='' />Add more bank details</button></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    {/* <small id="errBankDataMsg" className="invalid-feedback" style={{ display: 'none' }}></small> */}
                                                </div>
                                                {/* ------------------End Supplier BAnk Details------------------------------ */}
                                                <div className="row"></div>
                                                <div className="relative">
                                                    <hr />
                                                    <div className="d-flex flex-wrap align-content-end justify-content-end">
                                                        <div className="mb-2 relative">
                                                            {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                                                <>
                                                                    {showToster === 1 ?
                                                                        <Toster value={tosterValue} message={tosterMessage} />
                                                                        : <div>
                                                                            {updateBool === 0 ?
                                                                                <>
                                                                                    <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={() => { handleClear(1) }} ><img src={clearIcon} className='icnn' alt='' />{t("Clear")}</button>
                                                                                    <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleSave}>{t("Create Supplier")} <img src={Createitem} className='icnn' alt='' /> </button>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={'handlerUpdate'}>Update</button>
                                                                                    <button type="button" className="btn btn-clear btn-sm mb-1" onClick={handleClear}>Cancel</button>
                                                                                </>
                                                                            }
                                                                        </div>}
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* --------------------------------------------End Create New Item Popup-------------------------------------- */}



                {
                    showLoder === 1 ? <Loader val={showLoder} /> : ""
                }
                {/* Toaster */}
                {
                    isShowToaster === 1 ?
                        <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                }

                {
                    showAlertToster === 1 ?
                        <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                }

            </section>
        </>
    )
}
