async function PutPhysicalActivityTracker(data) {
   
    let url = window.AppbaseUrl+`/api/PatientPhysicalActivityTracker/UpdatePatientPhysicalActivity?Id=${data.Id}&activityId=${data.activityId}&timeFrom=${data.timeFrom}&totalTimeInMinutes=${data.totalTimeInMinutes}&remark=${data.remark}`;
      // let url = `https://localhost:7225/api/PatientPhysicalActivityTracker/UpdatePatientPhysicalActivity?Id=${data.Id}&activityId=${data.activityId}&timeFrom=${data.timeFrom}&totalTimeInMinutes=${data.totalTimeInMinutes}&remark=${data.remark}`
    let head = { "Content-Type": "application/JSON", accept : '*/*' };


  let response = fetch(url, {
    headers: head,
    method : 'PUT'
  }).then((res) => res.json()).then(data);

  return response;
}
export default PutPhysicalActivityTracker;