import React from "react";
import { useState, useEffect } from "react";
import CriterionTech from "../../assets/images/icons/CriterionTech.png";
import Vector from "../../assets/images/icons/Vector.svg";
import Contact from "../../assets/images/icons/Contact.svg";
import EraLogo from "../../../src/assets/images/icons/EraLogo.png";
export default function ChallanReceipt() {
  let [receiptprint, setreceiptprint] = useState();
  let [itemDetail, setItemDetail] = useState([]);
  const [clientData , setclientData] = useState()
  let GetReceiptDetails = () => {
    let data = JSON.parse(window.sessionStorage.getItem("ChallanReceiptdata"));
    setreceiptprint(data);
    let itemD = JSON.parse(data[0].ics_data);

    setItemDetail(itemD);
  };


  const ClientData=()=>{
    let data = JSON.parse(window.sessionStorage.getItem("LoginData")).clientdata;
    console.log('ClientData' , data)
    setclientData(data)
  }

  useEffect(() => {
    GetReceiptDetails();
    ClientData()
    setTimeout(() => {
      handlepritnt();
    }, 1200);
  }, []);

  let handlepritnt = () => {
    document.title = "Challan Receipt";
    window.print("");
    window.close();
  };
  
  const totalQuantity = itemDetail.reduce((total, data) => total + data.qty, 0);
  return (
    <>
      {receiptprint && (
        <>
          <section className="main-content dpt-3 mt-5">
            <div className="card-wrapper">
              <div className="quater-border right-top"></div>
              <div className="quater-border left-bottom"></div>
              <div className="water-mark">
                <div className="clientText">Medvantage</div>
              </div>

              <div
                className="dis-hed"
                style={{
                  height: "auto",
                  padding: "8px",
                  background: "rgb(245 245 245)",
                  alignItems: "center",
                }}
              >
                <div className="">
                  <img
                    src={clientData.logoUrl}
                    alt="Company Logo"
                    style={{width: "211px", }}
                  />
                </div>

                <div className="CompanyDetailsHeading">
                  <div className="companyNameChallan">
                  {clientData.clientName}
                  </div>
                  <div className="companyLocation">
                  {clientData.address}
                  </div>
                  <div className="companyContact">
                    <img src={Vector} alt=""></img> {clientData.emailID}
                    
                    <img src={Contact} alt=""></img> {clientData.mobileNo}
                  </div>
                </div>
                
              </div>

              <table className="tableHeaderFooter">
                <thead>
                  <tr>
                    <td>
                      <div className="pat-dtls text-center">
                        <div className="Challanheading">CHALLAN</div>
                      </div>
                      {/* ----------------------------Patient Information--------------------------------------- */}
                    </td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <div className="pat-dtls" style={{ minHeight: "600px" }}>
                        {/* <div className='document-title fs-6'>Suggested Investigations</div> */}
                        <div className="Challandetails_">
                          <table className="tableWhiteSmoke">
                           <tbody>
                              <tr>
                                <td>
                                  <div className="whiteSmokeheader">Dispatched From</div>
                                     <div className="containerDetails">{receiptprint.recivedto}</div>
                                  </td>

                                  <td>
                                  <div className="whiteSmokeheader">Dispatched To</div>
                                     <div className="containerDetails">{receiptprint.sendto}</div>
                                  </td>
                                  <td>
                                  <div className="whiteSmokeheader">Challan No.</div>
                                     <div className="containerDetails">{receiptprint.challannumber}</div>
                                      
                                  </td>

                                  <td>
                                  <div className="whiteSmokeheader">Date </div>
                                    <div className="containerDetails">{receiptprint.createdDate}</div>                                     
                                  </td>
                    
                              </tr>
                              </tbody>
                            
                        
                          </table>
                          {/* <div className="challanDispatchedfrom">
                            <div>
                              <h6 className="dispatchedfrom">
                                Dispatched From
                              </h6>
                              <h6 className="containerDetails">
                                {receiptprint.recivedto}
                              </h6>
                            </div>
                          </div>
                          <div className="challanDispatchedfrom">
                            <div>
                              <h6 className="dispatchedfrom">Dispatched To</h6>
                              <h6 className="containerDetails">
                                {receiptprint.sendto}
                              </h6>
                            </div>
                          </div>
                          <div className="challanDispatchedfrom">
                            <div>
                              <h6 className="dispatchedfrom">Challan No.</h6>
                              <h6 className="containerDetails">
                                {receiptprint.challannumber}
                              </h6>
                            </div>
                          </div>
                          <div className="challanDispatchedfrom">
                            <div>
                              <h6 className="dispatchedfrom">Date</h6>
                              <h6 className="containerDetails">
                                {receiptprint.createdDate}
                              </h6>
                            </div>
                          </div> */}
                        </div>

                        <table className="table-certificate_  cert-top1 mt-4 tableWhiteSmoke">
                          <thead className="bgWhiteSmoke_">
                            <tr>
                              <th className="text-center">#</th>
                              <th>Description of Materials</th>
                              <th>Serial No.</th>
                              <th>Quantity</th>
                            </tr>
                          </thead>

                          <tbody>
                            {itemDetail &&
                              itemDetail.map((data, index) => {
                                return (
                                  <tr>
                                    <td className="text-center">{index + 1}</td>
                                    <td>{data.descripation}</td>
                                    <td>{data.serialNumber}</td>
                                    <td>{data.qty}</td>
                                  </tr>
                                );
                              })}

                            <tr>
                              <td className="innerTitle" colspan={2}>
                                Approx value of Materials: NA
                              </td>
                              <td className="innerTitle">Total # of items: </td>
                              <td className="innerTitle">{totalQuantity}</td>
                            </tr>
                            <tr>
                              <td className="innerTitle" colspan={4}>
                                <div>Remarks</div>
                                <div className="remarkText">
                                  {receiptprint.remark}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <div className="forCt">
                  For {clientData.clientName}
                </div>
                <div className="row d-flex justify-content-between mt-3 px-4">
                  <div
                    style={{
                      border: "1px solid #bdb9b9",
                      height: "5rem",
                      width: "17rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "end",
                      padding: "0",
                    }}
                  >
                    <div className="challanSign">Stamp and Sign</div>
                  </div>
                  <div
                    style={{
                      border: "1px solid #bdb9b9",
                      height: "5rem",
                      width: "17rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "end",
                      padding: "0",
                    }}
                  >
                    <div className="challanSign">Authorized Signature</div>
                  </div>
                </div>
              </table>
            </div>

            {/* <div className="row">
			<div className="table-responsive">
                <div className="table-responsive custom_datatable">						
					<table className="table table-bordered" style={{width:"100%",margin:"auto",textAlign:"left"}} >
                        <tbody>
							
									<>
									
									<tr>
                                <td rowspan="2" colspan="2"><img src={offcanvaslogo} alt=""></img><h3 style={{margin:"10px 0 0 10px"}}>Inward Challan</h3></td>
                            </tr>									
                            <tr>
								<td colspan="3"><p>K.No-3,Lucknow - Hardoi - Shahjahanpur Rd,</p>
                                <p className="mt-1">Sarfarazganj,Lucknow,Uttar Pradesh 226003</p>
                                </td>  											
                            </tr>
							<tr  style={{border : 'none'}}>
								<td colspan="2">Consign by (Cust) :{receiptprint.recivedto} </td>
                                <td>Challan No.</td>
								<td colspan="2">{receiptprint.challannumber}</td>
								
							</tr>
                            <tr  style={{border : 'none'}}>
                                <td colspan="2"></td>  
                                <td>Date</td>  
								<td colspan="2">{receiptprint.createdDate}</td>  											
                            </tr>
							<tr>
								<td colspan="2"></td>
								<td colspan="3">
                                    <h6>Destination:</h6>
                                    <p>{receiptprint.destination}</p>
                                </td>
							</tr>
                            <tr>
                                <td colspan="1"><h6>Dispatched from:</h6></td>
                                <td colspan="2">Lucknow, Uttar Pradesh</td>
                                <td >
                                    <h6>Remarks</h6>
                                    <p>{receiptprint.signature}</p>
                                </td>
                            </tr>	
                            <tr>
                                <td><h6>#</h6></td>
                                <td><h6>Description of Materials</h6></td>
                                <td><h6>Serial No.</h6></td>
                                <td><h6>Quantity</h6></td>
                            </tr>
							{itemDetail && itemDetail.map((data,index)=>{
								return(
									<tr>
									<td>{index+1}</td>
									<td>{data.descripation}</td>
									<td>{data.serialNumber}</td>
									<td>{data.qty}</td>	
								</tr>
								)
							})}
		
							<tr>
								<td colspan="2"><h6>Approx value of Materials: NA</h6></td>
								<td colspan="1"><h6>Total No.of items:</h6></td>
								<td>{totalQuantity}</td>
							</tr>
							<tr className = "" style={{height: '8vh'}}>
								<td colspan="4" style={{textAlign: 'center'}}><h6 style={{marginTop: '25px'}}>For Web Works India Pvt,Ltd</h6></td>
							</tr>
							<tr>
								<td><h6>Signature</h6></td>
								<td colspan="2"><h6 style={{textAlign : 'center'}}>Stamp</h6></td>
								<td colspan="2"><h6>Authorised Signature</h6></td>
							</tr>
							<tr style={{height : "8vh"}}>
								<td></td>
								<td colspan="2"></td>
								<td colspan="2"></td>
							</tr>
									</>
							
													

						</tbody>
					</table>
				</div>
			</div> 
            </div> */}
          </section>
        </>
      )}
    </>
  );
}
