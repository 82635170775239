import React, { useEffect, useState } from 'react'
import TimeCalculate from '../../../Code/TimeCalculate';
import LineInvestigationGrap from '../../../Components/LineInvestigationGrap';
import GetAllDashboardParameterColumnAssign from '../../../Api/GetAllDashboardParameterColumnAssign';
import Highcharts from 'highcharts';
import { useSelector } from 'react-redux';

export default function KPlusR(props) {
    let [kplus, setKplus] = useState("");
    let [kplusId, setKplusId] = useState("");
    let [kplusTime, setKplusTime] = useState("");
    let [kplusColor, setKplusColor] = useState("");


    let [kplusSerum, setKplusSerum] = useState("");
    let [kplusSerumId, setKplusSerumId] = useState("");
    let [kplusSerumTime, setKplusSerumTime] = useState("");
    let [kplusSerumColor, setKplusSerumColor] = useState("");

    let [uhId, setUhId] = useState("")

    // set grap Investigation data
    let [grapInvestigationData, setGrapInvestigationData] = useState({
        showGrap: 0,
        subTestId: '',
        patientName: '',
        UHID: '',
        userId: ''
    })

    let getData = () => {
        setKplus("");
        setKplusId("");
        setKplusTime("");
        setKplusColor("");
        setKplusSerum("");
        setKplusSerumId("");
        setKplusSerumTime("");
        setKplusSerumColor("");

        props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
            if (investigation.SubTestID.toString() === "611") {
                setKplus(Math.round(investigation.SubTestValue * 100) / 100)
                setKplusColor(investigation.SubTestColor)
                if (props.handleColor !== undefined) {
                    props.handleColor(investigation.SubTestColor !== null ? investigation.SubTestColor : "white", props.columnId)
                }


                setKplusId(investigation.SubTestID)
                setUhId(props.patientData.UhId)

                if (investigation.SubTestDateTime !== '') {
                    setKplusTime(TimeCalculate(investigation.SubTestDateTime))
                }
                let chartdata = []
                if (props.patientData.PatientNutritionAchievementList !== null && props.patientData.PatientNutritionAchievementList !== undefined) {
                    props.patientData.PatientNutritionAchievementList.map((val, ind) => {

                        if (val.NutrientID === 8265) {
                            if (val.FoodPercentage !== 0) {
                                let slice1 = {
                                    name: '',
                                    y: val.FoodPercentage,
                                    sliced: false,
                                    color: val.FoodPercentageColor
                                }
                                chartdata.push(slice1)
                            }
                            if (val.MedicinePercentage !== 0) {
                                let slice2 = {
                                    name: '',
                                    y: val.MedicinePercentage,
                                    sliced: false,
                                    color: val.MedicinePercentageColor
                                }
                                chartdata.push(slice2)

                            }
                            if (val.FluidPercentage !== 0) {
                                let slice3 = {
                                    name: '',
                                    y: val.FluidPercentage,
                                    sliced: false,
                                    color: val.FluidPercentageColor
                                }
                                chartdata.push(slice3)

                            }
                            if (val.RemainingPercentage !== 0) {
                                let slice4 = {
                                    name: '',
                                    y: val.RemainingPercentage,
                                    color: "white",
                                    sliced: false,
                                }
                                chartdata.push(slice4)

                            }
                            if (document.getElementById("kplusId")) {
                                setTimeout(() => {
                                    Highcharts.chart('kplusId',
                                        {
                                            chart: {

                                                plotShadow: false,
                                                spacing: [0, 0, 0, 0],
                                                margin: 0,
                                                type: 'pie',
                                                backgroundColor: 'transparent',
                                                width: 35,
                                                height: 35

                                            },
                                            legend: {
                                                backgroundColor: '#FFFFFF',
                                            }
                                            ,
                                            title: {
                                                text: null,
                                                align: null
                                            },
                                            tooltip: {
                                                pointFormat: null
                                            },
                                            accessibility: {
                                                point: {
                                                    valueSuffix: '%'
                                                }
                                            },
                                            plotOptions: {
                                                pie: {
                                                    size: '100%',
                                                    allowPointSelect: true,
                                                    cursor: 'pointer',
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    // colors: ['#f00', '#0f0', '#00f'],


                                                }
                                            },
                                            credits: {
                                                enabled: false,
                                            },
                                            series: [{
                                                enableMouseTracking: false,

                                                data: [...chartdata]
                                            },

                                            ]

                                        }
                                    )
                                }, 100)
                            }

                        }
                    })
                }

            }
            if (investigation.SubTestID.toString() === "499") {
                setKplusSerum(Math.round(investigation.SubTestValue * 100) / 100)
                setKplusSerumColor(investigation.SubTestColor)
                setKplusSerumId(investigation.SubTestID)
                setUhId(props.patientData.UhId)
            }
        })
    }

    let modelCloseFun = () => {
        setGrapInvestigationData({
            showGrap: 0,
            subTestId: '',
            patientName: '',
            UHID: '',
            userId: ''
        })
    }

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
           if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

    }

    useEffect(() => {
        getColumnsParameters()
        getData()
    }, [props.patientData])
    return (
        <>
            {/* <div key={props.key} className={`overflow-max investigations ${(kplusColor !== "#008000" && kplusColor !== "#94e094" && kplusColor !== "#F5F8FF" && kplusColor !== "" ? "blink" : "")} `} style={{ border: (kplusColor !== "#008000" && kplusColor !== "#94e094" && kplusColor !== "#F5F8FF" && kplusColor !== "" ? "2px solid " + kplusColor : "") }}> */}
            <div key={props.key} className={`overflow-max investigations `}>

                {kplus.length != 0 ? (kplus ? kplus : kplusSerum) ? <span>
                    {
                        paramenterData && paramenterData.map((val, ind) => {
                            if (val.parameterId === 62) {
                                return (
                                    <>
                                        <span style={{ color: kplusColor }}>{kplus} ({kplusSerum})</span><br />


                                    </>

                                )
                            }

                            else if (val.parameterId === 63) {
                                return (
                                    <>
                                        {
                                            kplusTime.split("-")[1] === "D" && kplusTime.split("-")[0] >= 4 ? <span style={{ 'color': 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {kplusTime.toLowerCase().replace("-", "")}</span>
                                                : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {kplusTime.toLowerCase().replace("-", "")}</span>}
                                        <br />
                                    </>

                                )
                            }

                            // else if (val.parameterId === 64) {
                            //     return (
                            //         <>
                            //             <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='Potassium (K+) Graph' >
                            //                 {console.log("runnnnnnnnn", typeof kplusId)}
                            //                 {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                            //                     if (val.SubtestID === kplusId) {

                            //                         return (
                            //                             <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                            //                         )
                            //                     }
                            //                 })}

                            //             {/* <br /> */}
                            //         </>

                            //     )
                            // }


                        })

                    }
                    <div className="d-flex flex-row gap-2 justify-content-center align-items-center pt-1" title='Potassium (K+) Graph' >
                        {

                            paramenterData && paramenterData.map((val, ind) => {
                                if (val.parameterId === 64 && props.from === undefined) {
                                    return (
                                        <>
                                            <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [kplusId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "Potassium (K+) Graph", patientName: props.patientData.PntName }) }}></i>

                                            {/* <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='ALB Graph' > */}
                                            {/* {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                                                if (val.SubtestID === albId) {
                                                    return (
                                                        <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                                                    )
                                                }
                                            })} */}


                                        </>

                                    )
                                }
                                else if (val.parameterId === 113 && props.from === undefined) {
                                    return (

                                        <div id="kplusId" style={{ width: "30x", height: "30px" }}>
                                        </div>
                                    )
                                }
                            })

                        }
                    </div>


                </span> : 'N/A' : 'N/A'}

            </div>
            {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}

        </>
    )
}


export  function KPlusRG(props) {
    let [kplus, setKplus] = useState("");
    let [kplusId, setKplusId] = useState("");
    let [kplusTime, setKplusTime] = useState("");
    let [kplusColor, setKplusColor] = useState("");


    let [kplusSerum, setKplusSerum] = useState("");
    let [kplusSerumId, setKplusSerumId] = useState("");
    let [kplusSerumTime, setKplusSerumTime] = useState("");
    let [kplusSerumColor, setKplusSerumColor] = useState("");

    let [uhId, setUhId] = useState("")

    // set grap Investigation data
    let [grapInvestigationData, setGrapInvestigationData] = useState({
        showGrap: 0,
        subTestId: '',
        patientName: '',
        UHID: '',
        userId: ''
    })

    let getData = () => {
        setKplus("");
        setKplusId("");
        setKplusTime("");
        setKplusColor("");
        setKplusSerum("");
        setKplusSerumId("");
        setKplusSerumTime("");
        setKplusSerumColor("");

        props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
            if (investigation.SubTestID.toString() === "611") {
                setKplus(Math.round(investigation.SubTestValue * 100) / 100)
                setKplusColor(investigation.SubTestColor)
                if (props.setColors !== undefined) {
                    props.setColors((prevArray) => ({ ...prevArray, "K+": investigation.SubTestColor }))
                }

                setKplusId(investigation.SubTestID)
                setUhId(props.patientData.UhId)

                if (investigation.SubTestDateTime !== '') {
                    setKplusTime(TimeCalculate(investigation.SubTestDateTime))
                }
                let chartdata = []
                if (props.patientData.PatientNutritionAchievementList !== null && props.patientData.PatientNutritionAchievementList !== undefined) {
                    props.patientData.PatientNutritionAchievementList.map((val, ind) => {

                        if (val.NutrientID === 8265) {
                            if (val.FoodPercentage !== 0) {
                                let slice1 = {
                                    name: '',
                                    y: val.FoodPercentage,
                                    sliced: false,
                                    color: val.FoodPercentageColor
                                }
                                chartdata.push(slice1)
                            }
                            if (val.MedicinePercentage !== 0) {
                                let slice2 = {
                                    name: '',
                                    y: val.MedicinePercentage,
                                    sliced: false,
                                    color: val.MedicinePercentageColor
                                }
                                chartdata.push(slice2)

                            }
                            if (val.FluidPercentage !== 0) {
                                let slice3 = {
                                    name: '',
                                    y: val.FluidPercentage,
                                    sliced: false,
                                    color: val.FluidPercentageColor
                                }
                                chartdata.push(slice3)

                            }
                            if (val.RemainingPercentage !== 0) {
                                let slice4 = {
                                    name: '',
                                    y: val.RemainingPercentage,
                                    color: "white",
                                    sliced: false,
                                }
                                chartdata.push(slice4)

                            }
                            if (document.getElementById("kplusId")) {
                                setTimeout(() => {
                                    Highcharts.chart('kplusId',
                                        {
                                            chart: {

                                                plotShadow: false,
                                                spacing: [0, 0, 0, 0],
                                                margin: 0,
                                                type: 'pie',
                                                backgroundColor: 'transparent',
                                                width: 35,
                                                height: 35

                                            },
                                            legend: {
                                                backgroundColor: '#FFFFFF',
                                            }
                                            ,
                                            title: {
                                                text: null,
                                                align: null
                                            },
                                            tooltip: {
                                                pointFormat: null
                                            },
                                            accessibility: {
                                                point: {
                                                    valueSuffix: '%'
                                                }
                                            },
                                            plotOptions: {
                                                pie: {
                                                    size: '100%',
                                                    allowPointSelect: true,
                                                    cursor: 'pointer',
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    // colors: ['#f00', '#0f0', '#00f'],


                                                }
                                            },
                                            credits: {
                                                enabled: false,
                                            },
                                            series: [{
                                                enableMouseTracking: false,

                                                data: [...chartdata]
                                            },

                                            ]

                                        }
                                    )
                                }, 100)
                            }

                        }
                    })
                }

            }
            if (investigation.SubTestID.toString() === "499") {
                setKplusSerum(Math.round(investigation.SubTestValue * 100) / 100)
                setKplusSerumColor(investigation.SubTestColor)
                setKplusSerumId(investigation.SubTestID)
                setUhId(props.patientData.UhId)
            }
        })
    }

    let modelCloseFun = () => {
        setGrapInvestigationData({
            showGrap: 0,
            subTestId: '',
            patientName: '',
            UHID: '',
            userId: ''
        })
    }

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
           if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

    }

    useEffect(() => {
        getColumnsParameters()
        getData()
    }, [props.patientData])
    return (
        <>
            {/* <div key={props.key} className={`overflow-max investigations ${(kplusColor !== "#008000" && kplusColor !== "#94e094" && kplusColor !== "#F5F8FF" && kplusColor !== "" ? "blink" : "")} `} style={{ border: (kplusColor !== "#008000" && kplusColor !== "#94e094" && kplusColor !== "#F5F8FF" && kplusColor !== "" ? "2px solid " + kplusColor : "") }}> */}
            <div key={props.key} className={`overflow-max investigations`}>

                {kplus.length != 0 ? (kplus ? kplus : kplusSerum) ? <span>
                    
                        {

                            paramenterData && paramenterData.map((val, ind) => {
                                if (val.parameterId === 64) {
                                    return (
                                        <>
                                            <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [kplusId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "Potassium (K+) Graph", patientName: props.patientData.PntName }) }}></i>

                                           


                                        </>

                                    )
                                }
                                
                            })

                        }


                </span> : 'N/A' : 'N/A'}

            </div>
            {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}

        </>
    )
}
