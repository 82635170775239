import React, { useEffect, useState } from 'react'
import mainlogo from '../../../../src/assets/images/Navbar/blankLogo.svg'
import PostPatientNotes from '../../API/IPD/PatientsNotes/PostPatientNotes';
import AlertToster from '../../../Component/AlertToster';

const HFConsentForm = () => {
    let [loginData, setLoginData] = useState([]);
    let [patientDetails, setPatientDetails] = useState([]);
    let [dateTime, setDateTime] = useState();
    let [consentName, setConsentName] = useState('');
    let [detailsDate, setDetailsDate] = useState('')
    let [detailsTime, setDetailsTime] = useState('')
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');

    let date = new Date()
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    const userID = JSON.parse(window.sessionStorage.getItem("LoginData")).userId;
    const PID = JSON.parse(window.sessionStorage.getItem("IPDpatientList"))[0].pid;
    const data = JSON.parse(window.sessionStorage.getItem("IPDpatientList"))[0];
    // setPatientDetails(data);
    let functionGetLoginData = () => {
        let response = JSON.parse(window.sessionStorage.getItem("LoginData"));
        let getLoginData = response.clientdata
        setLoginData(getLoginData)
    }
    console.log("data", data)
    // Function to get the current date and time
    const getCurrentDateTime = () => {
        const now = new Date();
        const date = now.toISOString().split('T')[0]; // Format: YYYY-MM-DD
        const time = now.toTimeString().slice(0, 5); // Format: HH:MM
        setDetailsDate(date);
        setDetailsTime(time);
    };
    const handlePrint = async () => {

        const obj = {
            pid: PID,
            doctorID: userID,
            pdmID: 53,
            details: consentName,
            detailsDate: detailsDate + ' ' + detailsTime,
            userID: userID,
            entryDate: detailsTime,
            clientId: clientID

        }
        if (consentName != '') {
            let response = await PostPatientNotes(obj);
            if (response.status === 1) {
                setTimeout(() => {
                    window.print();
                }, 1500);
            }
            else {
                setShowAlertToster(1);
                setShowErrMessage(response.responseValue)
                setTimeout(() => {
                    setShowAlertToster(0);
                }, 1500);
            }
        }
        else {
            setShowAlertToster(1);
            setShowErrMessage("Please fill details")
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500);
        }
    }

    useEffect(() => {
        functionGetLoginData();
        getCurrentDateTime();
        setDateTime(date.toLocaleDateString() + " " + date.toLocaleTimeString());
        // setTimeout(() => {
        //     window.print();
        //     window.close();
        // }, 1000);
    }, []);
    return (
        <>
            <div className='main-content pt-3 mt-5'>
                <div className="card-wrapper">
                    <div className="quater-border right-top"></div>
                    <div className="quater-border left-bottom"></div>
                    <div className='water-mark'>
                        <div className='clientText'>{loginData.clientName}</div>
                    </div>
                    <div className="dis-hed">
                        <div className="discharge-logo">
                            <div className="logo-main_">
                                {/* <img src={mainlogo} /> */}
                                {loginData.logoUrl == null ? <img src={mainlogo} alt='Brand Logo' title='Brand Logo' /> : <img src={loginData.logoUrl} alt='Brand Logo' title='Brand Logo' />}
                            </div>
                        </div>
                        <div className="address-section">
                            <div className='organizationName' style={{ color: 'black' }}>{loginData.clientName}</div>
                            <div className='organizationAddress' style={{ color: 'black', fontSize: '16px' }}>{loginData.address}</div>
                            {loginData.emailID == null ? '' : <div className='email' style={{ color: 'black', fontSize: '14px' }}>Email: {loginData.emailID}</div>}
                            {loginData.mobileNo == null ? '' : <div className='organizationContact' style={{ color: 'black', fontSize: '14px' }}>Phone: +{loginData.countryCode} {loginData.mobileNo}</div>}
                        </div>

                        <div className="address-section">
                            <div className='phone text-right'> {dateTime}</div>
                        </div>
                    </div>

                    {/* -----------------------Patient's Details------------------- */}

                    <div className="pat-dtls">
                        <div className="document1-title">HEART FAILURE INFORMATION & CONSENT FORM FOR MANAGEMENT & PROGNOSIS</div>
                    </div>
                    <div className="pat-dtls">
                        <table className="table-certificate cert-top1 border mb-2">
                            <tr>
                                <td> <b>UHID :</b>  <span className='value'>{data.uhId}</span></td>
                                <td> <b>Patient :</b>  <span className='value'>{data.patientName}</span></td>
                                <td><b>Gender :</b>  <span className='value'>{data.gender}</span></td>
                                <td><b>CrNo :</b> <span className='value'>{data.crNo}</span></td>
                            </tr>
                            <tr>
                                <td><b>IPNo :</b> <span className='value'>{data.ipNo}</span></td>
                                <td><b>Age :</b> <span className='value'> {data.age} {data.ageType}</span></td>
                                <td colSpan={2}> <b>Mobile No. :</b> <span className='value'>{data.mobileNo}</span></td>
                            </tr>
                        </table>
                        <table>
                            <tr>
                                <td>
                                    <div className='informatontext'>
                                        I, <input type='text' className='inputConsentName' onChange={(e) => setConsentName(e.target.value)} /> have been asked to carefully read all the information contained in this consent form & to consent to the investigation & management described below. I have been told that I should ask questions about anything that I do not understand about my condition of Heart failure, it's management, treatment options & prognosis
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='informatontext pt-3'>
                                        I have indicated a desire to undergo my heart failure treatment. I understand & consent on being seen & consulted by a multidisciplinary team for my symptom analysis,Investigations, diagnosis, treatment options,the inherent risk of morbidity & mortality in heart failure include acute decompensated heart failure, repeat Hospital Admissions with heart failure, acute coronary syndrome, myocardial infarction, Cardiogenic Shock, arrythymias, paralytic stroke, sudden Cardiac death & non responders to all form of treatment in heart failure.
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='informatontext pt-3'>
                                        I understand Heart failure is a Journey which needs frequent monitoring, Home care, need for frequent evaluation by various tests & procedures the physician may believe is necessary. Specific blood tests, ECG, Echo, V02 Max for cadio pulm endurance, 6 mins walk test, Stress test, X Ray chest, PFT, CMRI, CCTA, Cardiac perfusion scan, Vascular doppler, Cardiac Catheterization includes Right heart catheterization, Reversibility tests for PAH, Endomyocardial Biopsy, Coronary angiography„ Genetic testing for cardiomyopathy, event recorders for arrythmia detection & other Investigations as adviced by the Physician. By signing the consent form, I will be acknowledging that I have read & understand all the information given to me on all the Investigations & that I voluntarily choose to undergo the various test to assess & evaluate my heart failure condition. I know that I am free to change my mind & withdraw my consent at any time prior to the tests & procedures.
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div className='informatontext pt-3'>
                                        I understand that I am being given all information about my heart failure condition, that it is a Journey with its risks, benefits & alternatives to help me make an informed descion whether to voluntarily & freely undergo the treatment. It may consists my regular home monitoring, Compliance on the ACC/AHA/ASHFS guidelines on GDMT , Diuretics, add on drugs & Cardiac Rehab. I understand GDMT Drugs are beneficial in my condition but I need to be compliant with my medications, undergo tests to monitor the drug dosages to take maximum benefit & reduce side effects I understand & acknowledge that Heart failure is a progressive disease which may be resistant to optimum medical management over a period of time & I may need hospital admission for congestion, acute decompensation heart failure, infections, cardiogenic shock, Complex high Coronary Interventions, CABG, CRT, ICD, Structural heart disease Interventions like Mitra clip, TEER, TAVR, CCU admission needing IV Vasopressors, inotrope, Ventillator support, IV Diuretic, Renal replacement therapy MCS devices like IABP, ECMO, Impella, Centrimag. I understand I may be referred even air lifted to advance centres when my advance heart failure is resistant to all conventional management includes cardiogenic shock requiring mechanical circulatory support, , resistant arrythmia & me be on the listing of Heart & Lung transpplant, LVAD, BVAD, TAH are available & accepted by the Tertiary hospital & medical team.
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='informatontext pt-3'>
                                        I understand dr Merchant is a second opinion cardiologist in Heart Failure available for 8 days in a month on appointment basis & will shadow HF management by a full time Cardiologist available for management of the case.
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div className='informatontext pt-3'>
                                        I understand considerations regarding my quality of life, lenght of life & my personal autonomy & Influencexon response to treatment, Device selection, Coronary Interventions, ICD, CRT Device implant, MCS need, SHI) Intervention, I-VAD & Heart Transplant. I understand there is Clinical uncertainty regarding the likelihood of my individual response to different therapies which adds challenges to me & my medical caregivers.
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='informatontext pt-3'>
                                        I Understand it is my individual choice through the complex disease on diagnosis & management of the grave condition of Heart failure which has high compromise on quality of life & 5 - 10% mortality including los of consciousness, sudden cardiac death despite all forms of optimum medical therapy, Devices, CHIP, MCS , I-VAD & Heart Transplants.
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='informatontext pt-3'>
                                        I Understand that heart failure may involve other Organs like lungs, kidney, liver, brain, low immunity & I may be seen by a multidisc team include intensivist, nephrologist, Pulminologist, ID etc as & when needed & I consent for the same.
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='informatontext pt-3'>
                                        I understand that I may be adviced to be shifted to advance centre in the country or outside the country in Goverment or private facility for management of Advance Heart failure for Device implant, MCS, Air lift on ECMO, I-VAD implant, Listing in Transplant program
                                        I understand, acknowledge & consent advance heart failure has a significant risk including non responders to therapies, restricted quality of life, some even home bound, high morbidity & mortality including death.
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='informatontext pt-3'>
                                        Risk Involving Medical Costs & Insurance. I understand the high & recurrent cost of tests & treatment. Insurance companies may consider me to have pre existing heart conditions & refuse to pay for medical care, treatment & procedures related to those conditions, in which case I & my attendant will pay & complete the formality of hospital bill payments.
                                    </div>
                                </td>
                            </tr>
                        </table>


                    </div>



                    <div
                        className="d-flex justify-content-between"
                        style={{ marginTop: "30px" }}
                    >
                        <div className="text-center">
                            {/* <div> <img src="http://172.16.61.31:7095/MediaFiles/tecnicianSign.png" alt="" style="width: 125px; margin-top: 2px;"/></div> */}
                            <div>
                                {/* <b>Resident Sign</b> */}
                                <b>Signature of Patient/Relative</b>
                            </div>
                        </div>
                        <div className="text-center">
                            {/* <div> <img src="http://172.16.61.31:7095/MediaFiles/doctorSign.png" alt="" style="width: 125px; margin-top: 2px;"/></div> */}
                            <div>
                                {/* <b>Consultant Incharge Sign</b> */}
                                <b>Signature of Witness</b>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center mt-3 '> <button className='btn btn-save btn-save-fill btn-sm hideOnprint' data-bs-toggle="modal" data-bs-title="Print" data-bs-placement="bottom" data-bs-target="#successModal">Save & print</button> </div>
                </div>

            </div>
            {/* -------------------------------------------------------Save Alert----------------------------------------------- */}
            <div className="modal fade" id="successModal" tabIndex="-1" aria-labelledby="successModalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modalSave">
                    <div className="modal-content">
                        <div className="modal-body modelbdy text-center">
                            <div className='popSaveIcon'><i class="fa-solid fa-circle-check"></i></div>
                            <div className='popDeleteTitle mt-3'> Save?</div>
                            <div className='popDeleteContent'> Are you sure you want to Save?</div>
                        </div>
                        <div className="modal-footer1 text-center">
                            <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn-delete popBtnDelete" data-bs-dismiss="modal" onClick={handlePrint}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            {
                showAlertToster === 1 ?
                    <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
            }

        </>
    )
}

export default HFConsentForm
