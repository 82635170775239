import React, { useEffect, useState } from 'react'
import PatinetConsultantName from './AllFieldConsultant'
import { useSelector } from 'react-redux'

export default function Consultant(props) {
    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
           if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

    }
    useEffect(() => {
        getColumnsParameters()
    }, [props])
    return (
        <div className="overflow-max">
            {
                paramenterData && paramenterData.map((val, ind) => {
                    if (val.parameterId === 33) {
                        return (<>
                            <PatinetConsultantName patientData={JSON.parse(props.val.patientDataList)} />
                        </>)
                    }
                })
            }
        </div>
    )
}
