import React, { useState, useEffect } from 'react';
import TaskCategoryMaster from './ICUChartAPi/TaskCategoryMaster';
import InsertDoctorOrders from './ICUChartAPi/InsertDoctorOrders';
import GetDoctorOrdersByPmid from './ICUChartAPi/GetDoctorOrdersByPmid';
import DeleteDoctorOrders from './ICUChartAPi/DeleteDoctorOrders';
import TosterUnderProcess from '../../../../../Component/TosterUnderProcess';
import Toster from '../../../../../Component/Toster';
import IconICUChecklist from '../../../../../assets/images/icons/IconICUChecklist.svg';
import IconIcuAdd from '../../../../../assets/images/icons/IconIcuAdd.svg';
import save from '../../../../../assets/images/icons/save.svg'
//import rejectedIcon from '../../../../../assets/images/icons/rejectedIcon.svg';
//import doneIcon from '../../../../../assets/images/icons/doneIcon.svg';
//sssimport pending from '../../../../../assets/images/icons/pending.svg';

export default function Orders(props) {


    const [selectedItem, setSelectedItem] = useState('');
    const [taskCategoryList, setTaskCategoryList] = useState([]);
    const [getClientId, setClientId] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientId);
    const [remarks, setRemarks] = useState('');
    const [getPmid, setPmid] = useState(JSON.parse(window.sessionStorage.getItem("IPDpatientList"))[0].pmId);
    //const [sendForm, setSendForm] = useState({ userId: window.userId, taskCategoryId: '', remarks: '', });
    const [getOrdersData, setOrdersData] = useState([]);
    const [showUnderProcess, setShowUnderProcess] = useState(0);
    const [showToster, setShowToster] = useState(0)
    const [tosterMessage, setTosterMessage] = useState("")
    const [tosterValue, setTosterValue] = useState(0)
    const [inputValues, setInputValues] = useState({});
   
 
    const handleInputChange = (taskId, value) => {
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [taskId]: value,
        }));
    };

    const saveRemarks = async () => {
        try {
            const newOrdersData = [];
    
            // Insert new remarks
            for (const taskId in inputValues) {
                if (inputValues.hasOwnProperty(taskId)) {
                    const remarks = inputValues[taskId];
                    //console.log('remarks before insertion', remarks);
                    if (remarks.trim() !== '') {
                        await insertOrder(taskId, remarks);
                        newOrdersData.push({ taskCategoryId: taskId, taskRemark: remarks });
                    }
                }
            }
    
            // Fetch existing remarks
            await getOrders();
            console.log('After saving remarks, getOrdersData:', getOrdersData);
            const existingOrdersData = await GetDoctorOrdersByPmid(getPmid, getClientId);
            if (existingOrdersData.status === 1) {
                const ordersWithIsOrderFalse = existingOrdersData.responseValue.filter(order => order.isOrder === false);
                newOrdersData.push(...ordersWithIsOrderFalse);
            }
    
            setOrdersData(newOrdersData);
            setInputValues({});
        } catch (error) {
            console.error('Error saving remarks:', error);
        }
    };
    



    const getTaskCategory = async () => {
        const taskCategoryResp = await TaskCategoryMaster(getClientId);
        if (taskCategoryResp.status === 1) {
            const taskCategoryWithIsOrderFalse = taskCategoryResp.responseValue.filter(task => task.isOrder === false);
            //console.log('Checklist With IsOrder False', taskCategoryWithIsOrderFalse);
            //console.log('Checklist', taskCategoryResp);
            setTaskCategoryList(taskCategoryWithIsOrderFalse);
        }
    };


    const insertOrder = async (taskId, remarks) => {

        let tempArr = [];

        var valResponse = {
            pmId: getPmid,
            taskCategoryId: taskId,
            userId: window.userId,
            isOrder: false,
            dutyType: 2,
            clientId: getClientId,
            taskRemark: remarks,
        };

        tempArr.push(valResponse);
        //console.log('insertedOrder', valResponse);
        //console.log('tempArr', tempArr);

        const response = await InsertDoctorOrders(tempArr);
        if (response.status === 1) {
            setTosterValue(0);
            setTosterMessage('Order inserted successfully!');
        } else {
            setTosterValue(1);
            setTosterMessage(response.responseValue);
        }
    };


    const getOrders = async () => {
        try {
            const ordersData = await GetDoctorOrdersByPmid(getPmid, getClientId);
    
            if (ordersData.status === 1) {
                //console.log('Orders data', ordersData);
                const ordersWithIsOrderFalse = ordersData.responseValue.filter(order => order.isOrder === false);
                //console.log('Orders Segregated data', ordersWithIsOrderFalse);
    
                setOrdersData(prevOrdersData => {
                    //console.log('Prev Orders Data', prevOrdersData);
                    //console.log('Final Orders Data', [...ordersWithIsOrderFalse]);
                    return [...ordersWithIsOrderFalse];
                });
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };
    
    

    // const handleClear = () => {
    //     document.getElementById('taskCategory').value = '';
    //     document.getElementById('remarks').value = '';
    //     setSendForm({ userId: window.userId, taskCategoryId: '', remarks: '' });
    //     setSelectedItem('');
    //     setRemarks('');

    // };


    const getRemarksForTask = (task) => {
        //console.log('Current Task:', task);
        //const taskId = Number(task.id);
        const reversedOrdersData = [...getOrdersData].reverse();
        const orderDataForTask = reversedOrdersData.find((order) => {
            //console.log('order.taskCategoryId:', order.taskCategoryId);
            //console.log('taskId:', task);
            return order.taskCategoryId === task;
        });

       // console.log('ordersData:', getOrdersData);
       // console.log('remarks for corresponding task', orderDataForTask);
        //console.log('remarks data', orderDataForTask ? orderDataForTask.taskRemark : 'no data found');

        return orderDataForTask ? orderDataForTask.taskRemark : '';
        
    };

    useEffect(() => {
        getTaskCategory();
        getOrders();
   

    }, [getPmid, getClientId]);


    useEffect(() => {
        console.log('Final Orders Data for remarks', getOrdersData);
    }, [getOrdersData]);

    useEffect(() => {
        setSelectedItem('');
        setRemarks('');
    }, [getPmid, getClientId]);
    return (
        <>
            <section className="med-box" style={{ boxShadow: 'none' }}>
                <div className='icuChartHeading mb-2'>
                    <div className='icuChartHeading-inn'>
                        <div className='ICUHead-lft'>
                            <span><img src={IconICUChecklist} alt='' /></span>
                            <span><h3>Checklist</h3></span>
                        </div>
                    </div>
                
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 mt-2">
                            {/* Display table  */}
                            {taskCategoryList.length > 0 && (
                                <div className="med-table-section Icu-component" style={{ "height": "30vh" }}>
                                    <table className="med-table tblICUChart mt-3">
                                        <tbody>
                                            {taskCategoryList.map((task, index) => (

                                                <tr key={index}>
                                                    <td style={{ border: 'none', whiteSpace: 'nowrap' }}>{task.categoryName}</td>

                                                    <td style={{ border: 'none', width: '75%' }}>

                                                        {/*<input
                                                        style={{ backgroundColor: '#fcf2e7', border: 'none' }}
                                                        className="form-control form-control-sm"
                                                        type="text"
                                                        value={getRemarksForTask(task.id) || inputValues[task.id] || ''}
                                                        //value={inputValues[task.id] || ''}
                                                        onChange={(e) => setInputValues((prevInputValues) => ({ ...prevInputValues, [task.id]: e.target.value }))}
                                                         />*/}
                                                         <input
                                                        
                                                        //  value={getRemarksForTask(task.id) !== undefined ? getRemarksForTask(task.id) : inputValues[task.id] || ''}
                                                       style={{ backgroundColor: '#fcf2e7', border: 'none' }}
                                                        className="form-control form-control-sm"
                                                        type="text"
                                                        value={inputValues[task.id] !== undefined ? inputValues[task.id] : getRemarksForTask(task.id)}
                                                        onChange={(e) => handleInputChange(task.id, e.target.value)}
                                                   
                                                       />
                                                       
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>

                                    </table>
                                </div>

                            )}
                        </div>
                        <div className='col-md-12 ml-2' style={{ textAlign: 'right' ,display:props.isPrint===1?"none":"block"}}>
                            <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={saveRemarks}><img src={save} className='icnn' />Save</button>
                        </div>
                    </div>
                </div>


            </section>

        </>
    );


}

