import React, { useEffect, useState } from 'react'
// import IPDMedication from '../Prescription/IPDMedication';
// import Loader from '../../../../../Component/Loader';
// import BottomButtons from '../Prescription/BottomButtons';
//import FoodIntake from '../../../../../Dietetics/Pages/FoodIntake'
// import FoodIntakeForICU from './FoodIntakeForICU';
// import SupplementIntakeForICU from './SupplementIntakeForICU';
//import IPDMedicationHistory from '../Prescription/IPDMedicationHistory';
//import SupplementIntake from '../../../../../Dietetics/Pages/SupplementIntake'
// import GetFoodIntakeList from '../../../../../Dietetics/API/FoodIntake/GetFoodIntakeList';
//import IPDMedicationHistoryForICU from './IPDMedicationHistoryForICU'
//import IconICUMedication from '../../../../../assets/images/icons/IconICUMedication.svg';
//import AlertToster from '../../../../../Component/AlertToster';
//import DisplayVitalsHistory from '../Vitals/DisplayVitalsHistory';
//import { HealthViewVitals } from '../HealthView/Components/HealthViewVitals';
//import DisplayVitalsHistory from '../Vitals/DisplayVitalsHistory';
// import { HealthViewCheckList } from '../HealthView/Components/HealthViewCheckList';
// import { HealthViewLines } from '../HealthView/Components/HealthViewLines';
// import { HealthViewLab } from '../HealthView/Components/HealthViewLab';
// import { HealthViewIntakeOutput } from '../HealthView/Components/HealthViewIntakeOutput';
import GetPatientIPDAllHistory from '../PatientPersonalDashboard/Api/GetPatientIPDAllHistory';
import SaveIPDData from '../../../../../Code/SaveIPDData';
import store from '../../../../../Store';
import { getIPDPatientData } from '../../../../../Reduce/IPD/IPDPatientData';
import IPDMedicationForICU from './IPDMedicationForICU';
import Orders from './Orders';
import GetPatientDetails from '../PatientPersonalDashboard/Api/GetPatientDetails';
import ICUVitals from './ICUVitals';
import ICUChecklist from './ICUChecklist';
import { ICULabs } from './ICULabs';
import ICUChartPhysicalActivity from './ICUChartComponent/ICUChartPhysicalActivity';
import TwentyFourHourFeedLimit from './ICUChartComponent/TwentyFourHourFeedLimit';
import LifeSupportICU from './ICUChartComponent/LifeSupportICU';
import LineAndCathetor from './ICUChartComponent/LineAndCathetor';
import { ICUChartIntakeOutput } from './ICUChartIntakeOutput';
import SetRestrictions from './ICUChartComponent/SetRestrictions';
import DutyCompletion from './ICUChartComponent/DutyCompletion';
import InfusionICUChart from './ICUChartComponent/InfusionICUChart';


export default function PatientProfile() {

  let [loader, setLoader] = useState(1)
  const [getUHID, setUHID] = useState(JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid);
  const [patientDetails, setPatientDetails] = useState([]);
  const [showLoder, setShowLoder] = useState(0);
  const [showAlertToster, setShowAlertToster] = useState(0);
  const [showErrMessage, setShowErrMessage] = useState('');
  const [patientComplainHistory, setPatientComplainHistory] = useState([]);
  const [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
  let [isPrint, setIsPrint] = useState(0)

  let getPatientDetails = async () => {
    setShowLoder(1)
    const patientdetailsresponse = await GetPatientDetails(getUHID);
    console.log('Patient Details', patientdetailsresponse)
    if (patientdetailsresponse.status === 1) {
      setShowLoder(0);
      setPatientDetails(patientdetailsresponse.responseValue[0])
    }
    else {
      setShowLoder(0);
      setShowAlertToster(1);
      setShowErrMessage(patientdetailsresponse.responseValue);
    }
  }

  let getOnlySpecificData = async () => {
    setLoader(1)
    let temp = window.sessionStorage.getItem("IPDpatientsendData") ? JSON.parse(window.sessionStorage.getItem("IPDpatientsendData")) : []
    let activeUHID = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : []
    let response = await GetPatientIPDAllHistory(activeUHID)
    if (response.status === 1) {
      let val = response.responseValue
      let key = Object.keys(val)
      let flags = 0
      key.map((vals, ind) => {
        if (vals === "runningPrescription") {
          SaveIPDData(val.runningPrescription, "jsonArray")
        }

        else if (vals === "prescriptionHistory") {
          SaveIPDData(val.prescriptionHistory, "prescriptionHistory")
        }

        flags = 1

      })

    }
    setLoader(0)
  }


  let getData = async () => {
    setLoader(1)
    let temp = window.sessionStorage.getItem("IPDpatientsendData") ? JSON.parse(window.sessionStorage.getItem("IPDpatientsendData")) : []
    let activeUHID = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : []
    // let t = []
    // temp.map((values, index) => {
    //   if (values[0] === activeUHID) {
    //     values.map((val, ind) => {
    //       let keys = Object.keys(val)
    //       t.push(keys[0])
    //     })
    //   }
    // })
    let response = await GetPatientIPDAllHistory(activeUHID)
    if (response.status === 1) {

      let val = response.responseValue
      let key = Object.keys(val)
      let flags = 0
      key.map((vals, ind) => {
        if (vals === "runningPrescription") {
          SaveIPDData(val.runningPrescription, "jsonArray")
        }
        else if (vals === "patientVitals") {
          SaveIPDData(val.patientVitals, "jsonVital")
        }
        else if (vals === "patientHistoryCategoryResultExistance") {
          SaveIPDData(val.patientHistoryCategoryResultExistance, "patientHistoryCategoryResultExistance")
        }
        else if (vals === "patientComplainHistory") {
          SaveIPDData(val.patientComplainHistory, "jsonDiagnosis")
        }
        else if (vals === "prescriptionHistory") {
          SaveIPDData(val.prescriptionHistory, "prescriptionHistory")
        }
        else if (vals === "patientCategoryResult") {
          SaveIPDData(val.patientCategoryResult, "patientCategoryResult")
        }
        else if (vals === "patientExaminationResult") {
          SaveIPDData(val.patientExaminationResult, "patientExaminationResult")
        }
        else if (vals === "patientHistoryCategoryResult") {
          SaveIPDData(val.patientHistoryCategoryResult, "patientHistoryCategoryResult")
        }
        else if (vals === "patientInvestigation") {
          SaveIPDData(val.patientInvestigation, "patientInvestigation")
        }


      })
      store.dispatch(getIPDPatientData(val))

    }

    setLoader(0)

  }
  let getPatientIPDAllHistory = async () => {
    //setShowMedicationLoader(1)
    const historyData = await GetPatientIPDAllHistory(getUHID, userID);
    if (historyData.status === 1) {
      console.log('ICU complain history', historyData)

      setPatientComplainHistory(historyData.responseValue.patientComplainHistory);

    }
    else {
      //setShowMedicationLoader(0)
      setShowAlertToster(1);
      setShowErrMessage(historyData.responseValue);
    }

  }
  let handleprint = () => {
    setIsPrint(1)
    document.getElementsByClassName("hideOnprint")[0].style.display = "none"
    setTimeout(() => {
      window.print()
      setIsPrint(0)
      document.getElementsByClassName("hideOnprint")[0].style.display = "block"

    }, 600)
  }
  useEffect(() => {
    getPatientDetails();
    getPatientIPDAllHistory();
    getData()

  }, []);
  return (
    <div className='row p-0 m-0 ' >
      <div className={`d-${isPrint === 0 ? "flex" : "none"} justify-content-end`} > <i className="bi bi-printer-fill fs-4 pe-3 pointer" onClick={handleprint}></i> </div>

      <div className='whitebg' style={{ height: '40vh', overflow: 'scroll' }} >
        <div className="row">
          <div className="cxcxcxc" style={{ paddingRight: '12px', paddingLeft: '12px', marginTop: '0px' }}>
            <div className="col-md-12" style={{ backgroundColor: '#c1d4f6' }}>
              <span className="listd-in showing" style={{ marginLeft: '10px' }}> Patient Details </span>
            </div>

          </div>
        </div>
        <div className="row">
          <div className='cheifcProfile-in'>
            <div className='cheifcProfile1' style={{ width: '100%' }}>
              <div className='cprp1'>
                <div className='cprp1-in'><h4>Name</h4><p>{patientDetails.patientName}</p></div>
                <div className='cprp1-in'><h4>Age/Gender</h4><p>{patientDetails.age}/{patientDetails.gender}</p></div>
                <div className='cprp1-in'><h4>Height/weight</h4><p>{patientDetails.height}/{patientDetails.weight}</p></div>
                <div className='cprp1-in'><h4>Admission Date</h4><p>{patientDetails.admitDate}</p></div>
                <div className='cprp1-in'><h4>Treating Doctor</h4><p>{patientDetails.doctorName}</p></div>
                <div className='cprp1-in'><h4>Ward</h4><p>{patientDetails.wardName}</p></div>
                <div className='cprp1-in'><h4>Bed No.</h4><p>{patientDetails.bedName}</p></div>
                {/* <div className='cprp1-in'><h4>Bed No.</h4><p>16</p></div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className='didp_d'>
            <h2>Diagnosis</h2>
            <div className='didp_ac'>
              <h4>

                {patientComplainHistory
                  .filter(val => val.pdmId === 4)
                  .map((val, i, arr) => (
                    <span key={i}>
                      {val.problemName}
                      {i !== arr.length - 1 ? ',  ' : '  '}
                    </span>
                  )
                  )}
              </h4>
            </div>

          </div>
        </div>
        {/*<div className="row mat-2">
          <div className='didp_d'>
            <h2>Chief Complaints</h2>
            <div className='didp_ac1' style={{ backgroundColor: '#f2f5f9', padding: '10px' }}>
              <h4>
                {patientComplainHistory
                  .filter(val => val.pdmId === 2)
                  .map((val, i, arr) => (
                    <span key={i}>
                      {val.problemName}
                      {i !== arr.length - 1 ? ',  ' : '  '}
                    </span>


                  )
                  )}
              </h4>
            </div>
          </div>
                  </div>*/}
        <div className="row mat-2">
          <div className="didp_d">
            <h2>Chief Complaints</h2>
            <div className="didp_ac1-container" style={{ display: 'flex', flexWrap: 'wrap' }}>
              {patientComplainHistory
                .filter(val => val.pdmId === 2)
                .map((val, i) => (
                  <div key={i} className="didp_ac1" style={{ backgroundColor: '#f2f5f9', padding: '10px', margin: '5px', borderRadius: '10px' }}>
                    <p>{val.problemName}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>


      </div>
      {/* <div className='c'>
        <div className='whitebg'>

          <div className="row">
            <div className="whitebg">
              <span className="listd-in showing"> Medication</span>
            </div>
          </div>
          <IPDMedicationForICU getData={getOnlySpecificData} />
        </div>
      </div> */}



      <div className={`${isPrint === 0 ? "ICUChartsection" : ""} `} >
        <div className='ICUChartsection-inn '>
          {/* <span className="listd-in showing"> Medication</span> */}
          {/*<div className='icuChartHeading mb-2'>
                <div className='icuChartHeading-inn'>
                    <div className='ICUHead-lft'>
                    <span><img src={IconICUMedication} alt='' /></span>
                        <span><h3>Medication</h3></span>
                    </div>
                </div>              
    </div>*/}
          <IPDMedicationForICU getData={getOnlySpecificData} isPrint={isPrint} />
        </div>
        <div className='ICUChartsection-inn d-none' >
          <Orders isPrint={isPrint} />
        </div>
        <div className='ICUChartsection-inn'>
          <ICUVitals isPrint={isPrint} />
        </div>
        <div className='ICUChartsection-inn'>
          <ICULabs isPrint={isPrint} />
        </div>
        <div className='ICUChartsection-inn '>
          <InfusionICUChart isPrint={isPrint} />
        </div>
        <div className='ICUChartsection-inn'>
          <LifeSupportICU isPrint={isPrint} />
        </div>

      
      
        <div className='ICUChartsection-inn d-none'>
          <ICUChecklist isPrint={isPrint} />
        </div>
       
        <div className='ICUChartsection-inn'>
          <ICUChartIntakeOutput isPrint={isPrint} />
        </div>
        <div className='ICUChartsection-inn'>
          <LineAndCathetor isPrint={isPrint} />
        </div>

        <div className='ICUChartsection-inn d-none'>
          <DutyCompletion isPrint={isPrint} />
        </div>
        <div className='ICUChartsection-inn'>
          <ICUChartPhysicalActivity isPrint={isPrint} />
        </div>
        <div className='ICUChartsection-inn' style={{ marginBottom: '10px' }}>
          <SetRestrictions isPrint={isPrint} />
        </div>
        <div className='ICUChartsection-inn' style={{ marginBottom: '10px' }}>
          <TwentyFourHourFeedLimit isPrint={isPrint} />
        </div>
      </div>

    </div>

  )
}