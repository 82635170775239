import React, { useEffect, useState } from 'react'
import printuse from '../../../assets/css/printuse.css'
import PatientLabel from '../../../Component/PatientLabel'
// import SarvodayaLogo from '../../../assets/images/icons/sarvodayalogo.png'

export default function ReceiptDailyReassesmentSheet() {

    let [reassesmentData, setReassesmentData] = useState()
    let [repiratoryAssessmentJson, setRepiratoryAssessmentJson] = useState()
    let [icuAdmisssion, setIcuAdmisssion] = useState()
    let [cardiacAssessmentJson, setCardiacAssessmentJson] = useState()
    let [generalAssessment, setGeneralAssessment] = useState()
    let [neuroAssessmentJson, setNeuroAssessmentJson] = useState()
    let [giAssessmentJson, setGiAssessmentJson] = useState()
    let [renalAssessmentJson, setRenalAssessmentJson] = useState()
    let [nutritionalAssessmentJson, setNutritionalAssessmentJson] = useState()
    let [metabolicAssessmentJson, setMetabolicAssessmentJson] = useState()
    let [assessmentOfLineTubesInfectionsJson, setAssessmentOfLineTubesInfectionsJson] = useState()
    let [pertinentInvestigationReportOnAdmissionJson, setPertinentInvestigationReportOnAdmissionJson] = useState()
    let [problemnsJson, setProblemnsJson] = useState()
    let [planOfTreatmentJson, setPlanOfTreatmentJson] = useState()
    let [crossReferralJson, setCrossReferralJson] = useState()
    let [workingdiagnosis, setWorkingDiagnosis] = useState()
    let [patientEducationFamilyCounsellingAboutPrognosisJson, setPatientEducationFamilyCounsellingAboutPrognosisJson] = useState()
    let [planOfTheDayJson, setPlanOfTheDayJson] = useState()
    let [diagnosis, setDiagnosis] = useState()
    let [clientdata, setClientdata] = useState([])


    let getData = () => {
        console.log('object show');
        let data = JSON.parse(window.sessionStorage.getItem("DailyReaassessmentSheetPrintData"));
        setReassesmentData(data)
        console.log("JSONDATTTT", data)
        let icuAdmisssionData = [];
        let generalAssessmentJsonData = [];
        let cardiacAssessmentJsonData = [];
        let repiratoryAssessmentJsonData = [];
        let neuroAssessmentJsonData = [];
        let giAssessmentJsonData = [];
        let renalAssessmentJsonData = [];
        let metabolicAssessmentJsonData = [];
        let assessmentOfLineTubesInfectionsJsonData = [];
        let nutritionalAssessmentJsonData = [];
        let pertinentInvestigationReportOnAdmissionJsonData = [];
        let problemnsJsonData = [];
        let planOfTreatmentJsonData = [];
        let crossReferralJsonData = [];
        let patientEducationFamilyJsonData = [];
        let planOfTheDayJsonData = [];
        let diagnosisData = [];
        let workingdiagnosisData = [];
        data.jsondata.forEach((datas) => {
            if (datas.icuAdmisssionJson !== null && datas.icuAdmisssionJson !== "") {
                icuAdmisssionData.push(...JSON.parse(datas.icuAdmisssionJson));
            }
            if (datas.generalAssessmentJson !== null  && datas.generalAssessmentJson !== "") {
                generalAssessmentJsonData.push(...JSON.parse(datas.generalAssessmentJson));
            }
            if (datas.cardiacAssessmentJson !== null  && datas.cardiacAssessmentJson !== "") {
                cardiacAssessmentJsonData.push(...JSON.parse(datas.cardiacAssessmentJson));
            }
            if (datas.repiratoryAssessmentJson !== null && datas.repiratoryAssessmentJson !== "") {
                repiratoryAssessmentJsonData.push(...JSON.parse(datas.repiratoryAssessmentJson));
            }
            if (datas.neuroAssessmentJson !== null && datas.neuroAssessmentJson !== "") {
                neuroAssessmentJsonData.push(...JSON.parse(datas.neuroAssessmentJson));
            }
            if (datas.giAssessmentJson !== null && datas.giAssessmentJson !== "") {
                giAssessmentJsonData.push(...JSON.parse(datas.giAssessmentJson));
            }
            if (datas.renalAssessmentJson !== null && datas.renalAssessmentJson !== "") {
                renalAssessmentJsonData.push(...JSON.parse(datas.renalAssessmentJson));
            }
            if (datas.metabolicAssessmentJson !== null && datas.metabolicAssessmentJson !== "") {
                metabolicAssessmentJsonData.push(...JSON.parse(datas.metabolicAssessmentJson));
            }
            // if (datas.assessmentOfLineTubesInfectionsJson !== null && datas.assessmentOfLineTubesInfectionsJson !== "") {
            //     assessmentOfLineTubesInfectionsJsonData.push(...JSON.parse(datas.assessmentOfLineTubesInfectionsJson));
            // }
            if (datas.nutritionalAssessmentJson !== null && datas.nutritionalAssessmentJson !== "") {
                nutritionalAssessmentJsonData.push(...JSON.parse(datas.nutritionalAssessmentJson));
            }
            // if (datas.pertinentInvestigationReportOnAdmissionJson !== null && datas.pertinentInvestigationReportOnAdmissionJson !== "") {
            //     pertinentInvestigationReportOnAdmissionJsonData.push(...JSON.parse(datas.pertinentInvestigationReportOnAdmissionJson));
            // }
            // if (datas.problemnsJson !== null && datas.problemnsJson !== "") {
            //     problemnsJsonData.push(...JSON.parse(datas.problemnsJson));
            // }
            // if (datas.planOfTreatmentJson !== null && datas.planOfTreatmentJson !== "") {
            //     planOfTreatmentJsonData.push(...JSON.parse(datas.planOfTreatmentJson));
            // }
            // if (datas.crossReferralJson !== null && datas.crossReferralJson !== "") {
            //     crossReferralJsonData.push(...JSON.parse(datas.crossReferralJson));
            // }
            // if (datas.patientEducationFamilyCounsellingAboutPrognosisJson !== null && datas.patientEducationFamilyCounsellingAboutPrognosisJson !== "") {
            //     patientEducationFamilyJsonData.push(...JSON.parse(datas.patientEducationFamilyCounsellingAboutPrognosisJson));
            // }
            if (datas.planOfTheDayJson !== null && datas.planOfTheDayJson !== "") {
                planOfTheDayJsonData.push(...JSON.parse(datas.planOfTheDayJson));
            }
            // if (datas.diagnosis !== null && datas.diagnosis !== "") {
            //     diagnosisData.push(...JSON.parse(datas.diagnosis));
            // }
            // if (datas.diagnosis !== null && datas.diagnosis !== "") {
            //     workingdiagnosisData.push(...JSON.parse(datas.diagnosis));
            // }
        });
        setIcuAdmisssion(icuAdmisssionData);
        setGeneralAssessment(generalAssessmentJsonData);
        setCardiacAssessmentJson(cardiacAssessmentJsonData);
        setRepiratoryAssessmentJson(repiratoryAssessmentJsonData);
        setGiAssessmentJson(giAssessmentJsonData);
        setRenalAssessmentJson(renalAssessmentJsonData);
        setMetabolicAssessmentJson(metabolicAssessmentJsonData);
        setAssessmentOfLineTubesInfectionsJson(assessmentOfLineTubesInfectionsJsonData);
        setNutritionalAssessmentJson(nutritionalAssessmentJsonData);
        setPlanOfTheDayJson(planOfTheDayJsonData);
        // setPlanOfTreatmentJson(planOfTreatmentJsonData);
        // setPertinentInvestigationReportOnAdmissionJson(pertinentInvestigationReportOnAdmissionJsonData);
        // setNeuroAssessmentJson(neuroAssessmentJsonData);
        // setProblemnsJson(problemnsJsonData);
        // setCrossReferralJson(crossReferralJsonData);
        // setPatientEducationFamilyCounsellingAboutPrognosisJson(patientEducationFamilyJsonData);
        // setDiagnosis(diagnosisData);
    }

   
    const ClientData=()=>{
        let data = JSON.parse(window.sessionStorage.getItem("LoginData")).clientdata;
        console.log('ClientData' , data)
        setClientdata(data)
      }

    useEffect(() => {
        getData();
        ClientData();
        setTimeout(() => {
            handlepritnt();
        }, 1200)

    }, []);
    let handlepritnt = () => {
        document.title = 'Daily Reassesment Sheet Receipt';
        window.print("");
        window.close();

    }

    return (
        <>
            <div className="main-table" style={{ padding: '10px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}
                <table className='mainsedationtbl'>
                    {/* ....header.. */}
                    <tr>
                        <td colSpan={6}>
                            <table>
                                <tr>
                                <div className="sarvodya-header">
                                            <div className='lft-header '>
                                            <img src={clientdata && clientdata.logoUrl} alt='' className='sv-log' />
                                            </div>
                                            <div className='rght-header mt-2'>
                                                <div> {clientdata && clientdata.clientName}<div className='addrs'>{clientdata && clientdata.address}</div></div>
                                            </div>
                                        </div>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td className='pt-1 mb-0 mt-0 pb-0' colSpan={6}>
                            <table className='pt-0 mb-0 pb-0 mt-0'>
                                <tr>
                                    <td colSpan={4} style={{ verticalAlign: 'top' }}>
                                        <div className='micro-heading' >
                                            DAILY REASSESSMENT SHEET
                                        </div>
                                    </td>
                                    <td rowSpan={4} style={{ border:'1px solid black', width:'35%' }}>
                                 
                                  <PatientLabel/>
                               
                                        {/* <table className="tb-rght">
                                            <tr>
                                                <th colSpan={6}>Patient Name:  <span style={{ width: '175px', borderBottom: '1px dashed black', display: 'inline-block' }}>{reassesmentData && reassesmentData.pateintName}</span> </th>

                                            </tr>
                                            <tr>
                                                <th colSpan={6}>MRD No. <span style={{ width: '220px', borderBottom: '1px dashed black', display: 'inline-block' }}></span> </th>
                                            </tr>
                                            <tr>
                                                <th colSpan={3}>Age: <span style={{ width: '70px', borderBottom: '1px dashed black', display: 'inline-block' }}>{reassesmentData && reassesmentData.age}</span> </th>
                                                <th colSpan={3}>Sex: <span style={{ width: '80px', borderBottom: '1px dashed black', display: 'inline-block' }}>{reassesmentData && reassesmentData.gender}</span> </th>
                                            </tr>
                                            <tr>
                                                <th colSpan={3}>DOA: <span style={{ width: '70px', borderBottom: '1px dashed black', display: 'inline-block' }}>{reassesmentData && reassesmentData.DOA}</span> </th>
                                                <th colSpan={3}>Unit: <span style={{ width: '75px', borderBottom: '1px dashed black', display: 'inline-block' }}></span> </th>
                                            </tr>
                                        </table> */}
                                       
                                    </td>
                                </tr>
                                <tr>
                                    {/* <td colSpan={2}>
                                        <div>MR NO : <span>{reassesmentData && reassesmentData.ipNo}</span></div>
                                    </td> */}
                                    <td colSpan={2}>
                                        <div><strong>IP NO :</strong> <span>{reassesmentData && reassesmentData.ipNo}</span></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <div><strong>Consultant :</strong> <span>{reassesmentData && reassesmentData.consultant}</span></div>
                                    </td>
                                    <td colSpan={2}>
                                        <div><strong>Intensivity:</strong>  <span>{reassesmentData && reassesmentData.Intensivist}</span></div>
                                    </td>

                                </tr>
                                <tr>
                                    {/* <td colSpan={2}>
                                        <div>DOA: <span>ss</span></div>
                                    </td> */}
                                    <td colSpan={2}>
                                        <div><strong>Date of Admission to ICU:</strong> <span>{reassesmentData && reassesmentData.dat}</span></div>
                                    </td>
                                    <td colSpan={2}>
                                        <div><strong>Bed No :</strong> <span>{reassesmentData && reassesmentData.bedName}</span></div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl mt-2 pt-4' colSpan={6} style={{ borderTop: 'none' }}>
                            <table className='admisntbll'>
                                <tr>
                                    {/* <td>
                                        <div>1.</div>
                                    </td> */}
                                   <td colSpan={6} style={{verticalAlign:'top'}}>
                                   <div style={{display:'flex', gap: '3px'}}>
                                        <strong> Problems/Diagnosis :</strong>
                                         <span style={{ width: '520px', borderBottom: '1px dashed black', display: 'inline-table' }}>{diagnosis && diagnosis.map((val) => val.details).join(', ')}</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    {/* <td>
                                        <div>2.</div>
                                    </td> */}
                                    <td colSpan={6} >
                                        <div><strong> Reason for ICU admission:</strong> <span style={{ width: '510px', borderBottom: '1px dashed black', display: 'inline-table' }}>{icuAdmisssion && icuAdmisssion.map((val) => val.details).join(', ')}</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    {/* <td>
                                        <div>2.</div>
                                    </td> */}
                                    <td colSpan={6}>
                                        <div><strong> General Assessment:</strong> <span style={{ width: '510px', borderBottom: '1px dashed black', display: 'inline-table' }}>{generalAssessment && generalAssessment.map((val) => val.details).join(', ')}</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    {/* <td>
                                        <div>2.</div>
                                    </td> */}
                                    <td colSpan={6}>
                                        <div><strong> Cardiac Assessment:</strong> <span style={{ width: '510px', borderBottom: '1px dashed black', display: 'inline-table' }}>{cardiacAssessmentJson && cardiacAssessmentJson.map((val) => val.details).join(', ')}</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    {/* <td>
                                        <div>2.</div>
                                    </td> */}
                                    <td colSpan={6}>
                                        <div><strong> Repiratory Assessment:</strong> <span style={{ width: '510px', borderBottom: '1px dashed black', display: 'inline-table' }}>{repiratoryAssessmentJson && repiratoryAssessmentJson.map((val) => val.details).join(', ')}</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    {/* <td>
                                        <div>2.</div>
                                    </td> */}
                                    <td colSpan={6}>
                                        <div><strong> Neuro-Assessment:</strong> <span style={{ width: '510px', borderBottom: '1px dashed black', display: 'inline-table' }}>{neuroAssessmentJson && neuroAssessmentJson.map((val) => val.details).join(', ')}</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    {/* <td>
                                        <div>2.</div>
                                    </td> */}
                                    <td colSpan={6}>
                                        <div><strong> GI Assessment:</strong> <span style={{ width: '510px', borderBottom: '1px dashed black', display: 'inline-table' }}>{giAssessmentJson && giAssessmentJson.map((val) => val.details).join(', ')}</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    {/* <td>
                                        <div>2.</div>
                                    </td> */}
                                    <td colSpan={6}>
                                        <div><strong> Renal Assessment:</strong> <span style={{ width: '510px', borderBottom: '1px dashed black', display: 'inline-table' }}>{renalAssessmentJson && renalAssessmentJson.map((val) => val.details).join(', ')}</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    {/* <td>
                                        <div>2.</div>
                                    </td> */}
                                    <td colSpan={6}>
                                        <div><strong> Endocrine/Metabolic Assessment:</strong> <span style={{ width: '400px', borderBottom: '1px dashed black', display: 'inline-table' }}>{metabolicAssessmentJson && metabolicAssessmentJson.map((val) => val.details).join(', ')}</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    {/* <td>
                                        <div>2.</div>
                                    </td> */}
                                    <td colSpan={6}>
                                        <div><strong>Assessment of Lines / Tubes / Infections:</strong> <span style={{ width: '510px', borderBottom: '1px dashed black', display: 'inline-table' }}>{assessmentOfLineTubesInfectionsJson && assessmentOfLineTubesInfectionsJson.map((val) => val.details ? val.details : "").join(', ')}</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    {/* <td>
                                        <div>2.</div>
                                    </td> */}
                                    <td colSpan={6}>
                                        <div><strong>Nutritional Assessment:</strong> <span style={{ width: '510px', borderBottom: '1px dashed black', display: 'inline-table' }}>{nutritionalAssessmentJson && nutritionalAssessmentJson.map((val) => val.details).join(', ')}</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    {/* <tr >
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    <td>
                                        <div>4.</div>
                                    </td>
                                    <td colSpan={6}>
                                        <div><strong>Pertinent Investigation / Reports on admission :</strong> <span style={{ width: '590px', borderBottom: '1px dashed black', display: 'inline-block' }}>{pertinentInvestigationReportOnAdmissionJson && pertinentInvestigationReportOnAdmissionJson.map((val) => val.details).join(', ')}</span></div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr> */}
                    {/* <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    <td>
                                        <div>5.</div>
                                    </td>
                                    <td colSpan={5}>
                                        <div><strong>Problems:</strong> <span style={{ width: '590px', borderBottom: '1px dashed black', display: 'inline-block' }}>{problemnsJson && problemnsJson.map((val) => val.details).join(', ')}</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr> */}
                    {/* <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll '>
                                <tr>
                                    <td>
                                        <div>6.</div>
                                    </td>
                                    <td colSpan={2}>
                                        <div><strong>Working Diagnosis :</strong><span style={{ width: '590px', borderBottom: '1px dashed black', display: 'inline-block' }}>{workingdiagnosis && workingdiagnosis.map((val) => val.details).join(', ')}</span></div> 
                                    </td>

                                    <td colSpan={2} style={{ float: 'right' }}>
                                        <div ><strong>Investigation :</strong> <span style={{ width: '590px', borderBottom: '1px dashed black', display: 'inline-block' }}>{problemnsJson && problemnsJson.map((val) => val.details).join(', ')}</span></div>
                                    </td>
                                    <td style={{ float: 'right' }}>
                                        <div >9.</div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr> */}
                    {/* <tr >
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    <td>
                                        <div>7.</div>
                                    </td>
                                    <td colSpan={5}>
                                        <div><strong>Plan of treatment:</strong> <span style={{ width: '590px', borderBottom: '1px dashed black', display: 'inline-block' }}>{planOfTreatmentJson && planOfTreatmentJson.map((val) => val.details).join(', ')}</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr> */}

                    {/* <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    <td>
                                        <div>8.</div>
                                    </td>
                                    <td colSpan={5}>
                                        <div><strong>Cross Referral:</strong> <span style={{ width: '590px', borderBottom: '1px dashed black', display: 'inline-block' }}>{crossReferralJson && crossReferralJson.map((val) => val.details).join(', ')}</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr> */}
                    {/* <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    <td>
                                        <div>10.</div>
                                    </td>
                                    <td colSpan={5}>
                                        <div><strong>Patient education & Family about Prognosis:</strong> <span style={{ width: '590px', borderBottom: '1px dashed black', display: 'inline-block' }}>{patientEducationFamilyCounsellingAboutPrognosisJson && patientEducationFamilyCounsellingAboutPrognosisJson.map((val) => val.details).join(', ')}</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr> */}
                    
                    <tr >
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    {/* <td>
                                        <div></div>
                                    </td> */}
                                    <td colSpan={6}>
                                        <div><strong>Plan for the day:</strong> <span style={{ width: '510px', borderBottom: '1px dashed black', display: 'inline-table' }}>{planOfTheDayJson && planOfTheDayJson.map((val) => val.details).join(', ')}</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    {/* <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    <td>
                                        <div>11.</div>
                                    </td>
                                    <td colSpan={5}>
                                        <div><strong>Name and Signature of Doctor:</strong> <span>ss</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr> */}
                    <tr>
                        <td className='bdrtbltime mb-0 mt-0 pb-0 pt-0' colSpan={6}>
                            <table className='admtntbltree'>
                                <tr>
                                    <td colSpan={2} style={{ borderRight: '1px solid', textAlign: 'center', borderTop: 'none' }}><strong>Time</strong> </td>
                                    <td colSpan={2} style={{ borderRight: '1px solid', textAlign: 'center', borderTop: 'none' }}><strong>Significant Event</strong></td>
                                    <td colSpan={2} style={{ textAlign: 'center', borderTop: 'none' }}><strong>Response and Result (Signed)</strong></td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ borderRight: '1px solid' }}><strong></strong> </td>
                                    <td colSpan={2} style={{ borderRight: '1px solid' }}><strong>Investigations Pending</strong></td>
                                    <td colSpan={2}><strong></strong></td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ borderRight: '1px solid' }}><strong></strong> </td>
                                    <td colSpan={2} style={{ borderRight: '1px solid' }}><strong>Procedure Planned (if any)</strong></td>
                                    <td colSpan={2}><strong></strong></td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ borderRight: '1px solid' }}><strong></strong> </td>
                                    <td colSpan={2} style={{ borderRight: '1px solid' }}><strong>Referrals if any</strong></td>
                                    <td colSpan={2}><strong></strong></td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ borderRight: '1px solid' }}><strong></strong> </td>
                                    <td colSpan={2} style={{ borderRight: '1px solid' }}><strong>Other Important Information</strong></td>
                                    <td colSpan={2}><strong></strong></td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ borderRight: '1px solid' }}><strong></strong> </td>
                                    <td colSpan={2} style={{ borderRight: '1px solid' }}><strong>Hand Over given by (Name) & Sign</strong></td>
                                    <td colSpan={2}><strong></strong></td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ borderRight: '1px solid' }}><strong></strong> </td>
                                    <td colSpan={2} style={{ borderRight: '1px solid' }}><strong>Taken Over by (Name) & Sign</strong></td>
                                    <td colSpan={2}><strong></strong></td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ borderRight: '1px solid' }}><strong></strong> </td>
                                    <td colSpan={2} style={{ borderRight: '1px solid' }}><strong>Date & Time</strong></td>
                                    <td colSpan={2}><strong></strong></td>
                                </tr>


                            </table>
                        </td>
                    </tr>
                </table>
            </div>


        </>
    )
}
