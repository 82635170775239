import React from 'react'
import { useEffect, useState } from 'react'
import GetPatinetCondition from '../API/GetPatinetCondition';
import GetPatientRisk from '../API/GetPatientRisk';
import GetTransferMethod from '../API/GetTransferMethod';
import GetLanguageData from '../API/GetLanguageData';
import PrintPageHeader from '../../../Component/PrintPageHeader';
import PatientLabel from '../../../Component/PatientLabel';
export default function ReceiptPateintTransferForm() {

    let [PatientTransferMainData, setPatientTransferMainData] = useState();
    let [diagnosisJson, setdiagnosisJson] = useState();
    let [microDiagnosisJSON, setDiagnosisJSON] = useState([]);
    let [VitalsbeforeProcedure, setVitalsbeforeProcedure] = useState([]);
    let [VitalsafterProcedure, setVitalsafterProcedure] = useState([]);
    const [clientData, setclientData] = useState()
    const [PatientCondition, setPatientCondition] = useState([]);
    const [Risk, setRisk] = useState([]);
    const [PatientTransfer, setPatientTransfer] = useState([]);
    const [LanguageList, setLanguageList] = useState([]);
    const [TransferMethod, setTransferMethod] = useState([]);

    let getPatientTransferDetails = () => {
        console.log('object show');
        let data = JSON.parse(window.sessionStorage.getItem("PatientTransferFormPrint"));
        setPatientTransferMainData(data)
        console.log("rowData", data.rowData)
        let diagJSON = data.rowData.diagnosisJson
        let vitalsBefore = data.rowData.vitalJson
        let vitalafer = data.rowData.handoverDetailsAfterProcedure
        setVitalsafterProcedure(JSON.parse(vitalafer))
        setVitalsbeforeProcedure(vitalsBefore ? JSON.parse(vitalsBefore) : '')
        console.log("vitalsBefore", vitalsBefore ? JSON.parse(vitalsBefore) : '')
        console.log("vitalafer", JSON.parse(vitalafer))
        setdiagnosisJson(JSON.parse(diagJSON))
        console.log("data", data)


    }



    const ClientData = () => {
        let data = JSON.parse(window.sessionStorage.getItem("LoginData")).clientdata;
        console.log('ClientData', data)
        setclientData(data)
    }

    const GetConditions = async () => {
        let data = await GetPatinetCondition()
        if (data.status === 1) {
            console.log("PCondition", data.responseValue)
            setPatientCondition(data.responseValue)
        }
    }

    const GetRisk = async () => {
        let data = await GetPatientRisk()
        if (data.status === 1) {
            console.log("GetRisk", data.responseValue)
            setRisk(data.responseValue)
        }
    }
    const GetMethodTransfer = async () => {
        let data = await GetTransferMethod()
        if (data.status === 1) {
            console.log("GetTransferMethod", data.responseValue)
            setTransferMethod(data.responseValue)
        }
    }
    const GetLanguage = async () => {

        let data = await GetLanguageData()
        if (data.status === 1) {
            setLanguageList(data.responseValue)
            console.log("LangData", data.responseValue)
        }

    }



    useEffect(() => {
        getPatientTransferDetails();
        ClientData()
        GetConditions()
        GetRisk()
        GetMethodTransfer()
        GetLanguage()
        setTimeout(() => {
            handlepritnt();
        }, 1200)

    }, []);
    let handlepritnt = () => {
        document.title = 'Patient Transfer Form';
        window.print("");
        window.close();
    }



    return (
        <>
            <div className="main-table" style={{ padding: '5px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <div className="transform-pts ">

                    {/* ....header.. */}
                    <table>
                        <tr>
                            <td>
                                <table>
                                    <tr>
                                        <td colSpan={8}>
                                            <PrintPageHeader />
                                            <div className='heading-form pt-2 mt-2 pb-1 mb-1'>
                                                Patient Transfer Form - Diagnostics/ Procedures
                                            </div>
                                        </td>
                                    </tr>

                                    {/* ....header.. end*/}
                                    <tr>
                                        <td colSpan={8} className='mt-1' >
                                           
                                            <table className='tbl-bordr mt-0 mb-2' style={{ border: '1px solid black' }}>
                                                <tr>
                                                    <td colSpan={4} style={{ verticalAlign: 'top' }}>
                                                        <div className='assesmnt mt-1 mb-1 pt-2 pb-1'><strong>Date of Admission:</strong> <span style={{ borderBottom: '1px dashed black', display: 'inline-block' }}>{PatientTransferMainData && PatientTransferMainData.doa}</span>

                                                        </div>
                                                        <div className='d-flex '>
                                                            <div className='assesmnt mt-1 mb-1 pt-1 pb-1 d-flex' style={{ width: '100%' }}><strong>Time of Assessment:</strong> <span style={{ marginLeft: '5px', width: '50px', borderBottom: '1px dashed black', display: 'inline-block' }}>{PatientTransferMainData && PatientTransferMainData.rowData.timeOfAssessment}</span> </div>
                                                            <div className='assesmnt mt-1 mb-1 pt-1 pb-1 d-flex' style={{ width: '100%' }}><strong>Ward.:</strong> <span style={{ marginLeft: '5px', width: '90px', borderBottom: '1px dashed black', display: 'inline-block' }}>&nbsp;{PatientTransferMainData && PatientTransferMainData.ward}</span></div>
                                                        </div>
                                                        <div className='d-flex mt-1 mb-1'>
                                                            <div className='assesmnt mt-1 mb-1 pt-1 pb-1'><strong>Diagnosis:</strong>  <span style={{ borderBottom: '1px dashed black', display: 'inline-block' }}>{diagnosisJson && diagnosisJson.map((val) => val.details)}</span> </div>
                                                            <div className='assesmnt mt-1 mb-1 pt-1 pb-1'><strong>Procedure.:</strong>  <span style={{ borderBottom: '1px dashed black', display: 'inline-block' }}>{PatientTransferMainData && PatientTransferMainData.rowData.procedureName}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td rowSpan={4} >
                                                        <div className='mt-0 pt-0 mb-0 pb-0 d-flex' style={{ width: '245px', borderLeft: '1px solid black' }}>
                                                            <PatientLabel />
                                                        </div>
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                    <td colSpan={4}>
                                                        <div className='d-flex '>
                                                            <div className='assesmnt mt-0 mb-0 d-flex' style={{ width: '100%' }}><strong>Time of Assessment:</strong> <span style={{ marginLeft: '5px', width: '50px', borderBottom: '1px dashed black', display: 'inline-block' }}>{PatientTransferMainData && PatientTransferMainData.rowData.timeOfAssessment}</span> </div>
                                                            <div className='assesmnt mt-0 mb-0 d-flex' style={{ width: '100%' }}><strong>Ward.:</strong> <span style={{ marginLeft: '5px', width: '90px', borderBottom: '1px dashed black', display: 'inline-block' }}>&nbsp;{PatientTransferMainData && PatientTransferMainData.ward}</span></div>
                                                        </div>
                                                        <div className='d-flex mt-0 mb-0 pb-0 pt-0'>
                                                            <div className='assesmnt mt-0 mb-0 pb-0 pt-0'><strong>Diagnosis:</strong>  <span style={{ borderBottom: '1px dashed black', display: 'inline-block' }}>{diagnosisJson && diagnosisJson.map((val) => val.details)}</span> </div>
                                                            <div className='assesmnt mt-0 mb-0 pb-0 pt-0'><strong>Procedure.:</strong>  <span style={{ borderBottom: '1px dashed black', display: 'inline-block' }}>{PatientTransferMainData && PatientTransferMainData.rowData.procedureName}</span>
                                                            </div>
                                                        </div>
                                                    </td>

                                                </tr> */}
                                                {/* <tr>
                                                    <td className='mt-0 mb-0 pb-0 pt-0'>
                                                        <div className='d-flex mt-0 mb-0 pb-0 pt-0'>
                                                            <div className='assesmnt mt-0 mb-0 pb-0 pt-0'><strong>Diagnosis:</strong>  <span style={{ borderBottom: '1px dashed black', display: 'inline-block' }}>{diagnosisJson && diagnosisJson.map((val) => val.details)}</span> </div>
                                                            <div className='assesmnt mt-0 mb-0 pb-0 pt-0'><strong>Procedure.:</strong>  <span style={{ borderBottom: '1px dashed black', display: 'inline-block' }}>{PatientTransferMainData && PatientTransferMainData.rowData.procedureName}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr> */}
                                            </table>
                                           
                                        </td>
                                    </tr>
                                </table>

                                <table>
                                    <tr>
                                        <td colSpan={8} className='pt-0 mt-0 pb-0 mb-0 '>
                                            {/* <div className='tbl2-bdr mt-3'> */}
                                            <table className='pt-0 mt-0 pb-0 mb-0' cellpadding="0" cellspacing="0">
                                                <tr>
                                                    <td colSpan={8} className=' ps-0 pe-0 pt-0 mt-0 pb-0 mb-0'>
                                                        <table className='bl2-bdr pt-0 mt-0 pb-0 mb-0' style={{ border: '1px solid black' }}>
                                                            <tr>
                                                                <th> From (Area)  </th>
                                                                <th>To (Area)</th>
                                                                <th>Date</th>
                                                                <th>Time</th>
                                                                <th colSpan={4}>Reason for Transfer/Name of Procedure</th>
                                                            </tr>
                                                            <tr>
                                                                <td>{PatientTransferMainData && PatientTransferMainData.rowData.transferFromName}</td>
                                                                <td>{PatientTransferMainData && PatientTransferMainData.rowData.transferToName}</td>
                                                                <td>{PatientTransferMainData && PatientTransferMainData.rowData.transferDate}</td>
                                                                <td>{PatientTransferMainData && PatientTransferMainData.rowData.transferTime}</td>
                                                                <td colSpan={4}>{PatientTransferMainData && PatientTransferMainData.rowData.transferReason}</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={8} className='ps-0 pe-0 pt-0 mt-0 pb-0 mb-0'>
                                                        <table className='pt-0 mt-0 pb-0 mb-0'>
                                                            <tr>
                                                                <td colSpan={8} style={{ border: '1px solid black' }}>
                                                                    <div className='maintblbox ps-0 pe-0 mt-1'>
                                                                        <div className='assesmnt '><strong>Method of Transfer :</strong> </div>
                                                                        <div className='tblt-rght'>
                                                                            {TransferMethod && TransferMethod.map((val) => {
                                                                                return (
                                                                                    <>
                                                                                        &nbsp; <input type='checkbox' checked={val.id === PatientTransferMainData.rowData.transferMethodStatusId ? true : false} /><span>&nbsp;{val.remark}</span>&nbsp;
                                                                                    </>
                                                                                )
                                                                            })}

                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={8} className='ps-0 pe-0 pt-0 mt-0 pb-0 mb-0'>
                                                        <table className='ps-0 pe-0' style={{ border: '1px solid black' }}>
                                                            <tr>
                                                                <td style={{ border: 'none', }}>
                                                                    <div className='assesmnt mt-1 mb-1 '> <strong>ASSESSMENT OF PATIENT:</strong></div>
                                                                    <div className='maintblbox '>
                                                                        <div className='assesmnt mt-1 mb-1'><strong>General condition of Patient:</strong>
                                                                        </div>
                                                                        <div className='tblt-rght '>
                                                                            {PatientCondition && PatientCondition.map((val) => {
                                                                                return (
                                                                                    <>
                                                                                        &nbsp;<input type='checkbox' checked={val.id === PatientTransferMainData.rowData.healthStatusId ? true : false} /><span>&nbsp;{val.remark}</span>
                                                                                    </>

                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={6} style={{ border: 'none' }} >
                                                                    <div className='maintblbox mt-1 mb-1'>
                                                                        <div className='assesmnt'><strong>Language Barrier:</strong> </div> <div className='tblt-rght d-flex'> &nbsp; <input type='checkbox' checked={PatientTransferMainData && PatientTransferMainData.rowData.languageBarrier == 'YES' ? true : false} />&nbsp;Yes &nbsp; <input type='checkbox' checked={PatientTransferMainData && PatientTransferMainData.rowData.languageBarrier == 'NO' ? true : false} /> &nbsp; No if yes, Specify:
                                                                            <div className={`${PatientTransferMainData && PatientTransferMainData.rowData.languageBarrier == 'YES' ? 'd-block' : 'd-none'}`} style={{ width: '340px', borderBottom: '1px dashed black', display: 'inline-block', marginLeft: '3px', fontWeight: '500' }}><strong>&nbsp;{PatientTransferMainData && PatientTransferMainData.rowData.language}</strong> </div></div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={6} style={{ border: 'none' }}>
                                                                    <div className='maintblbox mt-1 mb-1'>
                                                                        <div className='assesmnt'> <strong>Fall Risk Category:</strong> </div> <div className='tblt-rght'>
                                                                            {Risk && Risk.map((val) => {
                                                                                return (
                                                                                    <>
                                                                                        &nbsp; <input type='checkbox' checked={val.id === PatientTransferMainData.rowData.riskcategoryStatusId ? true : false} /><span>&nbsp;{val.remark}</span>
                                                                                    </>

                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>


                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={8} style={{ border: '1px solid black' }}>  <div className='maintblbox pt-1 mt-1 ps-0 pe-0'> <div className='assesmnt'><strong>Vital Signs</strong> </div> <div className='tblt-rght'><em>(to be documented at the shifting):</em></div>
                                                    </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={8} className='ps-0 pe-0 pt-0 mt-0 pb-0 mb-0'>
                                                        <table className='bl2-bdr pt-0 mt-0 pb-0 mb-0'>
                                                            <tr>
                                                                <th>BPS (mmHg)</th>
                                                                <th>BPD (mmHg)</th>
                                                                <th>RR (breaths/min)</th>
                                                                <th> Temp (°F) </th>

                                                                <th>Pulse (beats/min)</th>
                                                                <th>SpO, (%)</th>

                                                                <th>Pain Score</th>
                                                            </tr>

                                                            <tr>
                                                                {VitalsbeforeProcedure && VitalsbeforeProcedure.map((val, index) => {
                                                                    return (
                                                                        <td>{val.vmValue}{val.unit}</td>
                                                                    )
                                                                })}
                                                                <td>{PatientTransferMainData && PatientTransferMainData.rowData.painScaleUsed}</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={8} className='ps-0 pe-0' style={{ border: '1px solid black' }}>
                                                        <table className='pt-0 mt-0 pb-0 mb-0'>
                                                            <tr>
                                                                <td colSpan={6} style={{ border: 'none', }}><div className='assesmnt pb-2 pt-2'><strong>Pain Scale Used:</strong>
                                                                    <span style={{ width: '420px', borderBottom: '1px dashed black', display: 'inline-block', marginLeft: '3px' }}>{PatientTransferMainData && PatientTransferMainData.rowData.painScaleUsed}</span>
                                                                </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={6} style={{ border: 'none', }}><div className='assesmnt pb-2 pt-2'><strong>Any pre-medication given:</strong>
                                                                    <span style={{ width: '410px', borderBottom: '1px dashed black', display: 'inline-block', marginLeft: '3px' }}></span>
                                                                </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={6} style={{ border: 'none', }}><div className='assesmnt pb-2 pt-2'><strong>Any critical information:</strong>
                                                                    <span style={{ width: '420px', borderBottom: '1px dashed black', display: 'inline-block', marginLeft: '3px' }}>{PatientTransferMainData && PatientTransferMainData.rowData.criticalInformation}</span>
                                                                </div>
                                                                </td>  </tr>
                                                            <tr>
                                                                <td colSpan={6} style={{ border: 'none', }}>
                                                                    <div className='assesmnt pb-2 pt-2'><strong>Any specific recommendation:</strong>
                                                                        <span style={{ width: '420px', borderBottom: '1px dashed black', display: 'inline-block', marginLeft: '3px' }}>{PatientTransferMainData && PatientTransferMainData.rowData.specificRecommendation}</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={8} className=' ps-0 pe-0 pt-0 mt-0 pb-0 mb-0'>
                                                        <table className='bl2-bdr pt-0 mt-0 pb-0 mb-0' style={{ border: '1px solid black' }}>
                                                            <tr>
                                                                <th>  </th>
                                                                <th>Name</th>
                                                                <th>Signature</th>
                                                                <th>Emp. No.</th>
                                                                <th>Date.</th>
                                                                <th colSpan={4}>Time</th>
                                                            </tr>

                                                            <tr>
                                                                <th>Handover by</th>
                                                                <td>{PatientTransferMainData && PatientTransferMainData.rowData.handOverFromName}</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td>{PatientTransferMainData && PatientTransferMainData.rowData.handoverDate}</td>
                                                                <td colSpan={4}>{PatientTransferMainData && PatientTransferMainData.rowData.handoverTime}</td>

                                                            </tr>
                                                            <tr>
                                                                <th>Handed over to</th>
                                                                <td>{PatientTransferMainData && PatientTransferMainData.rowData.handToName}</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td>{PatientTransferMainData && PatientTransferMainData.rowData.handoverDate}</td>
                                                                <td colSpan={4}>{PatientTransferMainData && PatientTransferMainData.rowData.handoverTime}</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={8} className='ps-0 pe-0 pt-0 mt-0 pb-0 mb-0' style={{ border: '1px solid black' }}>
                                                        <table className=''>
                                                            <tr>
                                                                <td colSpan={6} style={{ border: 'none' }} className='pt-0 mt-0 pb-0 mb-0'>
                                                                    <div className='assesmnt mb-1 mt-1 pb-1'> <strong>After Procedure:</strong></div>
                                                                    <div className='maintblbox mt-0 pt-0 mb-0'>
                                                                        <div className='assesmnt'><strong>Procedure completed:</strong>  </div>
                                                                        <div className='tblt-rght '>&nbsp;<input type='checkbox' checked={VitalsafterProcedure && VitalsafterProcedure.isProcedureCompleted === 1 ? true : ''} /> Yes
                                                                            &nbsp; <input type='checkbox' checked={VitalsafterProcedure && VitalsafterProcedure.isProcedureCompleted === 0 ? false : ''} /> No
                                                                            <label> &nbsp;/ Any critical Information :  <span style={{ width: '220px', borderBottom: '1px dashed black', display: 'inline-block', marginLeft: '3px' }}><strong> {VitalsafterProcedure && VitalsafterProcedure.anyCriticalinformation}</strong></span></label>
                                                                        </div>
                                                                    </div>
                                                                    <div className='maintblbox mt-3 pt-0 mb-0'>
                                                                        <div className='assesmnt '><strong>Vital Signs</strong> </div> <div className='tblt-rght'>(to be documented at the shifting): </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={8} className=' ps-0 pe-0 pt-0 mt-0 pb-0 mb-0'>
                                                        <table className='bl2-bdr' style={{ border: '1px solid black' }}>
                                                            <tr>
                                                                <th>BP (mmHg)</th>
                                                                <th>BPD (mmHg)</th>
                                                                <th>RR (breaths/min)</th>
                                                                <th>Temp (°F) </th>
                                                                <th>Pulse (beats/min)</th>
                                                                <th>SpO, (%)</th>
                                                                <th>Pain Score</th>
                                                            </tr>

                                                            <tr>

                                                                <td>{VitalsafterProcedure && VitalsafterProcedure.vmValueBPSys}</td>
                                                                <td>{VitalsafterProcedure && VitalsafterProcedure.vmValueBPDias}</td>
                                                                <td>{VitalsafterProcedure && VitalsafterProcedure.vmValueRespiratoryRate}</td>
                                                                <td>{VitalsafterProcedure && VitalsafterProcedure.vmValueTemperature}</td>
                                                                <td>{VitalsafterProcedure && VitalsafterProcedure.vmValuePulse}</td>
                                                                <td>{VitalsafterProcedure && VitalsafterProcedure.vmValueSPO2}</td>
                                                                <td>{VitalsafterProcedure && VitalsafterProcedure.painScaleUsed}</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td colSpan={8} style={{ border: '1px solid black' }}><div className='assesmnt pt-1 mt-1 mb-1 pb-1 ps-0 pe-0'><strong>Pain Scale Used:</strong>
                                                        <span style={{ width: '500px', borderBottom: '1px solid black', display: 'inline-block', marginLeft: '3px' }}>{VitalsafterProcedure && VitalsafterProcedure.painScaleUsed}</span>
                                                    </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={8} className=' ps-0 pe-0 pt-0 mt-0 pb-0 mb-0'>
                                                        <table className='bl2-bdr' style={{ border: '1px solid black' }}>
                                                            <tr>
                                                                <th>  </th>
                                                                <th>Name</th>
                                                                <th>Signature</th>

                                                                <th>Global Id</th>
                                                                <th>Date.</th>
                                                                <th colSpan={4}>Time</th>
                                                            </tr>

                                                            <tr>
                                                                <th>Handover by</th>
                                                                <td>{VitalsafterProcedure && VitalsafterProcedure.handoverFromName}</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td>{VitalsafterProcedure && VitalsafterProcedure.handoverDate}</td>
                                                                <td colSpan={4}>{VitalsafterProcedure && VitalsafterProcedure.handoverTime}</td>

                                                            </tr>
                                                            <tr>
                                                                <th>Handed over to</th>
                                                                <td>{VitalsafterProcedure && VitalsafterProcedure.handoverToName}</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td>{VitalsafterProcedure && VitalsafterProcedure.handoverDate}</td>
                                                                <td colSpan={4}>{VitalsafterProcedure && VitalsafterProcedure.handoverTime}</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>

                                            {/* </div> */}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>

                </div>




            </div>
        </>
    )
}
