let PostImageForEcho = async (obj, file) => {
  console.log("image", obj.uhId)
  let url = window.AppbaseUrl+"/api/PatientMediaData/InsertPatientMediaData?uhId="+obj.uhId+"&category="+obj.category+"&dateTime="+obj.dateTime;
  // let url = window.AppbaseUrl+"/api/PatientMediaData/InsertPatientMediaData?uhId="+"UHID00854"+"&category="+obj.category+"&dateTime="+obj.dateTime;
  let head = {'Content-Type':'application/json','accept':'text/plain',}
  let data = {};
  let response = await fetch(url, {
    method: 'POST',
    header: head,
    body: file
  }).then(res => res.json()).then(data)
  return response;
}
export default PostImageForEcho;