import React, {useState} from 'react'
import Heading from '../../../Component/Heading'
import TableContainer from '../../../Component/TableContainer'
import GetAllStory from '../../API/AdminDashBoard/GetAllStory';
import noData from '../../../assets/images/icons/No data-rafiki.svg';

const StoryList = () => {
    
    const [searchInputstorylist, setSearchInputstorylist] = useState('');    
    const [getAllStoryList, setAllStoryList] = useState([])
    const handleOnChangeCurrentStoryList = (e) => {
        const { name, value } = e.target;
        if (name === 'searchBoxStoryList') {
            setSearchInputstorylist(value)
        }
    };

    const funAllStory = async () => {
        const  {status, responseValue} = await GetAllStory(window.userId);
        status ? setAllStoryList(responseValue) : setAllStoryList([]);
    };

    useState(() =>{
        funAllStory();
    },[]);
    return (
    <>
        <div className='handlser'>
            <Heading text="Story List" />
            <div style={{ position: 'relative' }}>
                <input value={searchInputstorylist} onChange={handleOnChangeCurrentStoryList} name="searchBoxStoryList" type="search" className="form-control form-control-sm" placeholder="Search...." aria-label="Search" aria-describedby="search-addon" />
                <span className="tblsericon"><i className="fas fa-search"></i></span>
            </div>
        </div>

        <TableContainer>
            <thead>
                <tr>
                    <th className="text-center" style={{ "width": "5%" }}>S.No.</th>
                    <th>Project</th>
                    <th>Story</th>
                    <th>Created By</th>
                </tr>
            </thead>
            <tbody>
                {
                    getAllStoryList.length > 0 ?
                    getAllStoryList && getAllStoryList.filter((val) => `${val.projectName} ${val.wantAbleTo + '' + val.soThat} ${val.name} `.toLowerCase().includes(searchInputstorylist.toLowerCase())).map((val, ind) => {
                    return (
                        <tr key={val.id}>
                            <td className="text-center">{ind + 1}</td>
                            <td>{val.projectName}</td>
                            <td>{val.wantAbleTo + ' ' + val.soThat}</td>
                            <td>{val.name}</td>
                        </tr>
                    )
                })                
                :
                <img className='haveNodataImg' src={noData} alt="No Data" />
            }
            </tbody>
        </TableContainer>
    </>
    )
}

export default StoryList