function ValidationPharmacyPurchase(supplierId='', billNo='',  dateOfPurchase='', productId='', unitId='', hsnCodeId='', batchNo='', batchMfg='',batchExpiry='',mrp='',purchasePrice='',unitPrice='',quantity='') {
   
    if(supplierId !== '' && billNo !=='' && dateOfPurchase !=='' && productId !=='' && unitId !=='' && hsnCodeId !=='' && batchNo !==''&& batchMfg !==''&& batchExpiry !==''&& mrp !==''&& purchasePrice !==''&& unitPrice !==''&& quantity !=='')
    {
        return true
    }
    else {
        return false
    }
}
export default ValidationPharmacyPurchase;


