import { useEffect, useState } from "react"
import TimeCalculate from "../../../Code/TimeCalculate";
import GetAllDashboardParameterColumnAssign from "../../../Api/GetAllDashboardParameterColumnAssign";
import { useSelector } from "react-redux";

export default function PatientBPL(props) {
    let [bpSys, setBpSys] = useState({
        VitalValue: "N/A",
        VitalColor: "gray"
    })
    let [bpDis, setBpDis] = useState({
        VitalValue: "N/A",
        VitalColor: "gray"
    })

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
        if (resp.length !== 0) {
            setPatameterData(resp[0].data)
        }

    }

    useEffect(() => {
        getColumnsParameters()
        let bpSys = 0;
        let bpDis = 0;
        // console.log("  props.val.AdmitTimeVitalsList", props)
        props.val.AdmitTimeVitalsList && props.val.AdmitTimeVitalsList.map((val) => {
            if (val.VitalID === 6) {
                bpDis = val.VitalValue
                let map = Math.round((bpSys + (2 * bpDis)) / 3, 2)
                let time = TimeCalculate(val.VitalDateTime)
                val['time'] = time
                val["map"] = map

                setBpDis(val)


            }
            else if (val.VitalID === 4) {
                bpSys = val.VitalValue
                let map = Math.round((bpSys + (2 * bpDis)) / 3, 2)

                let time = TimeCalculate(val.VitalDateTime)
                val['time'] = time
                val["map"] = map

                setBpSys(val)

            }
        })
    }, [props])
    return (
        <>
            <div className="overflow-max vitalClass text-white" >
                {

                    paramenterData && paramenterData.map((val, ind) => {
                        if (val.parameterId === 35) {
                            return (
                                <span style={{ color: bpSys.VitalColor }}>{bpSys.VitalValue}</span>

                            )
                        }
                        else if (val.parameterId === 36) {
                            return (
                                <span style={{ color: bpDis.VitalColor }}> <span style={{ color: "white"}}>/</span> {bpDis.VitalValue}</span>
                            )
                        }
                        else {
                            return (<span style={{ color: bpDis.VitalColor }}> N/A </span>)
                        }
                    })

                }


            </div>
        </>
    )
}