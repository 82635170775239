import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageNotFounds from '../../assets/images/LoginPage/PageNotfound.png';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("ErrorBoundary caught an error", error, errorInfo);
        // Log error to an error reporting service
    }

    handleNavigate = () => {
        this.props.navigate(-1);
        setTimeout(() => {
            window.location.reload()
        }, 300)
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className='d-flex flex-column '>
                    <div className='otDashboardWrapper'>
                        <div className='d-flex justify-content-center align-items-center' style={{ height: "90vh" }}>
                            <div className='d-flex flex-column gap-2'>
                                <img src={PageNotFounds} />
                                <div className='d-flex flex-column justify-content-center align-items-center gap-2' >
                                    <span className='text-center' style={{ font: "normal normal 600 30px/37px Poppins", color: "#081834", width: "342px" }}>
                                        Something wrong here....
                                    </span>
                                    <span className='text-center' style={{ font: " normal normal normal 14px/21px Poppins", color: "#474A51", width: "414px" }}>
                                        Sorry, we’re having some technical issues try to refresh the page, something works or go back home.
                                    </span>
                                    <button onClick={this.handleNavigate} className='text-white' style={{ width: "151px", height: "38px", borderRadius: "5px", font: "normal normal medium 16px/25px Poppins", background: "#1D4999 0% 0% no-repeat padding-box", border: "none" }}>
                                        Go Back Home
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

function ErrorBoundaryWithNavigate(props) {
    const navigate = useNavigate();
    return <ErrorBoundary {...props} navigate={navigate} />;
}

export default ErrorBoundaryWithNavigate;
