import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import "./BaseURL.js"
import { Provider } from 'react-redux';
import store from './Store';
import "./Code/LanguageManage.js"

const root = ReactDOM.createRoot(document.getElementById('root'));

// let baseUrl = "https://demo.medvantage.tech"
// let baseUrl = "http://172.16.61.31"
let baseUrl = "https://api.medvantage.tech"
// let baseUrl = "https://apimedcareroyal.medvantage.tech"


window.AppbaseUrl = baseUrl + ":7082"
window.AdminbaseUrl = baseUrl + ":7083"
window.UserbaseUrl = baseUrl + ":7084"
window.BillingbaseUrl = baseUrl + ":7088"
window.OTBaseURL = baseUrl + ":7091/api/"
window.BloodbaseUrl = baseUrl + ":7092"
window.LabServicebaseUrl = baseUrl + ":7090"
window.SendXMLDatabaseUl = baseUrl + ":7082"
window.PatientMonitorDashboard = baseUrl + ":7085"
window.PatientMonitorDashboardAPI = baseUrl + ":7087"
window.SendXMLDatabaseUl = baseUrl + ":7082"
window.InventoryBaseUrl = baseUrl + ":7093"
window.SpringBoardServicesUrl = baseUrl + ":7097/"
window.MaintenanceUrl = baseUrl + ":7099"
window.BMSservicesUrl = baseUrl + ":7094"
window.DietservicesUrl = baseUrl + ":7096"
window.RadiologyservicesUrl = baseUrl + ":7086"
window.PharmacyServicesUrl = baseUrl + ":8000"
window.NotificationUrl = baseUrl + ":7101"
window.ChatingUrl = baseUrl + ":7100"
window.LogBaseUrl = baseUrl + ":7089/"
window.CounsellingUrl = baseUrl + ":7102/"

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
