import React, { useEffect, useState } from 'react'

export default function PatientDeatils() {

    let [patientData, setPatientData] = useState([])

    useEffect(() => {
        let data = window.sessionStorage.getItem("IPDpatientList") ? JSON.parse(window.sessionStorage.getItem("IPDpatientList")) : []
        const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;

        data.forEach((val, ind) => {
            if (uhID === val.uhId) {

                setPatientData(val)
            }
        })
    }, [])
    return (
        <div className="fieldsett-in">
            <div className='fieldsett'>
                <span className='fieldse'>Patient Details</span>
                <div className='row'>


                    <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-4 mb-3">
                        <label htmlFor="Code" className="form-label">Patient Name<span className="starMandatory"></span></label>
                        <input id="pName" type="text" value={patientData && patientData.patientName} className="form-control form-control-sm" disabled placeholder='Patient Name' name="PName" />
                    </div>

                    {/* <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 mb-3">
                      <label htmlFor="Code" className="form-label">MRD No.<span className="starMandatory"></span></label>
                      <input  id="mrdNo" type="text" value={patientData && patientData.uhId} className="form-control form-control-sm" disabled placeholder='MRD No.' name="MRD" />
                    </div> */}

                    <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-4 mb-3" id="paymentModediv">
                        <label htmlFor="Code" className="form-label">Age/Gender<span className="starMandatory"></span></label>
                        <input id="PAge" type="text" value={patientData && patientData.age + "/" + patientData.gender} className="form-control form-control-sm" disabled placeholder="Age" name="Age" />
                    </div>
                    {/* <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-3" id="paymentModediv">
                        <label htmlFor="Code" className="form-label">Gender<span className="starMandatory"></span></label>
                        <input id="pGender" type="text" value={patientData && patientData.gender} className="form-control form-control-sm" disabled placeholder="gender" name="gender" />
                        <select id="pGender" className="form-control form-control-sm" name='gender' disabled >
                            <option value="0" >Select</option>
                            <option value="1">Male</option>
                            <option value="2">Female</option>
                        </select>

                    </div> */}
                    <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-4 mb-3">
                        <label htmlFor="Code" className="form-label">Date of Admission<span className="starMandatory"></span></label>
                        <input id="dob" type="text" className="form-control form-control-sm" disabled value={patientData && patientData.admittedDate} name="doa" />
                    </div>

                    <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-4 mb-3">
                        <label htmlFor="Code" className="form-label">Ward/Bed No.<span className="starMandatory"></span></label>
                        <input id="Ward" type="text" disabled value={patientData && patientData.wardName + "/" + patientData && patientData.bedName} className="form-control form-control-sm" name="Ward" placeholder='Ward' />
                    </div>


                    {/* <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <label htmlFor="Code" className="form-label">Bed No.<span className="starMandatory"></span></label>
                        <input id="bedNo" type="text" className="form-control form-control-sm" disabled value={patientData && patientData.name} name="bed" placeholder='Bed No.' />
                    </div> */}

                    <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-4 mb-3">
                        <label htmlFor="Code" className="form-label">IP No.<span className="starMandatory"></span></label>
                        <input id="ipNo" type="text" disabled value={patientData && patientData.ipNo} className="form-control form-control-sm" name="IPNo" placeholder='IP No.' />
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-4 mb-3">
                        <label htmlFor="Code" className="form-label">MRN No.<span className="starMandatory"></span></label>
                        <input id="mrNo" type="text" disabled value={patientData && patientData.uhId} className="form-control form-control-sm" name="AdvisedBy" placeholder='MR No.' />
                    </div>

                    <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-4 mb-3">
                        <label htmlFor="Code" className="form-label">Consultant Doctor<span className="starMandatory"></span></label>
                        <input id="consulteDr" type="text" disabled value={patientData && patientData.name} className="form-control form-control-sm" name="CDoctor" placeholder='Consultant Doctor' />
                    </div>


                    {/* <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <label htmlFor="Code" className="form-label">Date<span className="starMandatory"></span></label>
                        <input id="ddalarmtime" type="date" value={DateFormated} className="form-control form-control-sm" name="Date" />
                    </div>

                    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <label htmlFor="Code" className="form-label">Time<span className="starMandatory"></span></label>
                        <input id="ddalarmtime" type="time" value={formattedTime} className="form-control form-control-sm" name="Time" />
                    </div> */}
                </div>
            </div>
        </div>
    )
}
