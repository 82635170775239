async function PutWorkflowLevelSetting(data) {
    let url = window.UserbaseUrl + '/api/WorkflowLevelSetting/UpdateWorkflowLevelSetting';
    // let url ='https://localhost:7235/api/WorkflowLevelSetting/UpdateWorkflowLevelSetting';
    // let token ="bearer "+ window.SuperAdminToken;
    let head = {
        'Content-Type': 'application/JSON',
        accept: '*/*',
        // 'Authorization': token
    }


    let response = 
    await fetch(url, {
        method: 'PUT',
        headers: head,
        body: JSON.stringify(data)
    })
        .then((res) => res.json())
        .then(data)

    return response;
}
export default PutWorkflowLevelSetting