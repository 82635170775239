import React from 'react'
import exprimentalBodyGif from "../assests/images/experimentalBodyGif.gif"
import exprimentalCircleGif from "../assests/images/experimentalCircleGif.gif"
export default function ExperimentModeSection() {
    return (
        <>
            <div className='experimentalMode'>
                <img src={exprimentalCircleGif} className='exprimentalCircleGif' alt="" />
                <img src={exprimentalBodyGif} className='exprimentalBodyGif' alt="" />
                <span className='experimentalText pt-1 ps-2'>Experiment Mode</span>
            </div>
        </>
    )
}
