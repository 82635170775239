import React, { useState, useEffect } from 'react';
import TaskCategoryMaster from './ICUChartAPi/TaskCategoryMaster';
import InsertDoctorOrders from './ICUChartAPi/InsertDoctorOrders';
import GetDoctorOrdersByPmid from './ICUChartAPi/GetDoctorOrdersByPmid';
import deleteIcon from '../../../../../assets/images/icons/delete.svg'
import DeleteDoctorOrders from './ICUChartAPi/DeleteDoctorOrders';
import TosterUnderProcess from '../../../../../Component/TosterUnderProcess';
import Toster from '../../../../../Component/Toster';
import IconOrders from '../../../../../assets/images/icons/IconOrders.svg';
import IconIcuAdd from '../../../../../assets/images/icons/IconIcuAdd.svg';
import rejectedIcon from '../../../../../assets/images/icons/rejectedIcon.svg';
import doneIcon from '../../../../../assets/images/icons/doneIcon.svg';
import pending from '../../../../../assets/images/icons/pending.svg';



export default function Orders(props) {


    const [selectedItem, setSelectedItem] = useState('');
    const [taskCategoryList, setTaskCategoryList] = useState([]);
    const [getClientId, setClientId] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientId);
    const [remarks, setRemarks] = useState('');
    const [getPmid, setPmid] = useState(JSON.parse(window.sessionStorage.getItem("IPDpatientList"))[0].pmId);
    const [sendForm, setSendForm] = useState({ userId: window.userId, taskCategoryId: '', remarks: '', });
    const [getOrdersData, setOrdersData] = useState([]);
    const [showUnderProcess, setShowUnderProcess] = useState(0);
    const [showToster, setShowToster] = useState(0)
    const [tosterMessage, setTosterMessage] = useState("")
    const [tosterValue, setTosterValue] = useState(0)
    const [rowID, setRowID] = useState([]);
  

    const getStatusTextColor = (row) => {
        if (row.isCompleted) {
            return '#00ba00'; // Green text for completed tasks
        } else if (row.isDNC) {
            return '#fe3f3f'; // Red text for rejected tasks
        } else {
            return '#FFA33C'; // Yellow text for pending tasks
        }
    };
    const getStatusColor = (row) => {

        if (row.isCompleted) {
            return '#e6ffe6'; // Completed tasks will have a green background
        } else if (row.isDNC) {
            return '#ffefef'; // Rejected tasks will have a red background
        } else {
            return '#FFF5C2'; // Pending tasks will have a yellow background
        }
    };

    const getStatusText = (row) => {
        if (row.isCompleted) {
            return 'Done';
        } else if (row.isDNC) {
            return 'Rejected';
        } else {
            return 'Pending';
        }
    };

    const getTaskCategory = async () => {
        const taskCategoryResp = await TaskCategoryMaster(getClientId);
        if (taskCategoryResp.status === 1) {
            const taskCategoryWithIsOrderTrue = taskCategoryResp.responseValue.filter(task => task.isOrder === true);
            console.log('task Category With IsOrder True', taskCategoryWithIsOrderTrue);
            console.log('task Category', taskCategoryResp);
            setTaskCategoryList(taskCategoryWithIsOrderTrue);
        }
    };

    const insertOrder = async () => {
        try {

            let tempArr = [];

            var valResponse = {
                pmId: getPmid,
                taskCategoryId: sendForm.taskCategoryId,
                taskRemark: sendForm.remarks,
                isOrder: true,
                dutyType: 1,
                userId: window.userId,
                clientId: getClientId,
            };
            tempArr.push(valResponse)
            console.log('insertedOrder', valResponse);
            console.log('tempArr', tempArr);
            const response = await InsertDoctorOrders(tempArr);
            //setOrdersData((prevData) => [...prevData, valResponse]);
            if (response.status === 1) {
                setTosterValue(0);
                setTosterMessage("Order inserted successfully!");
            } else {
                setTosterValue(1);
                setTosterMessage(response.responseValue);
            }
            setSendForm({ userId: window.userId, taskCategoryId: '', remarks: '' });

            closeModal();
        }
        catch (error) {
            console.error('Error inserting order:', error);
        }
    };

    const getOrders = async () => {
        try {
            const ordersData = await GetDoctorOrdersByPmid(getPmid, getClientId);
            if (ordersData.status === 1) {
                console.log('Orders data', ordersData);
                const ordersWithIsOrderTrue = ordersData.responseValue.filter(order => order.isOrder);
                console.log('Orders Segregated data', ordersWithIsOrderTrue);
                setOrdersData(ordersWithIsOrderTrue);
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };
    const handleClear = () => {
        document.getElementById('taskCategory').value = '';
        document.getElementById('remarks').value = '';
        setSendForm({ userId: window.userId, taskCategoryId: '', remarks: '' });
        setSelectedItem('');
        setRemarks('');

    };
    const closeModal = () => {
        const modal = document.getElementById('saveTaskCategory');
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
            document.body.classList.remove('modal-open');
            document.body.style.paddingRight = '0px';
            handleClear();
        }
    };

    let handleChange = (e) => {
        document.getElementById('taskCategoryId').style.display = 'none';
        document.getElementById('remarksId').style.display = 'none';

        let name = e.target.name;
        let value = e.target.value;

        if (name === 'taskCategory') {
            const selectedCategoryId = taskCategoryList.find(
                (category) => category.categoryName === value
            )?.id;
            setSendForm((sendForm) => ({
                ...sendForm,
                taskCategoryId: selectedCategoryId,
            }));
        } else {
            setSendForm((sendForm) => ({
                ...sendForm,
                [name]: value,
            }));
        }
    };

    const deleteRow = async () => {
        const id = rowID;
        console.log('RowID', id);
        setShowUnderProcess(1);

        let DataToBeDeleted = await DeleteDoctorOrders(id);
        console.log('deleted data', DataToBeDeleted);
        if (DataToBeDeleted.status === 1) {

            //logic foe removing the deleted rows from the table
            setOrdersData((prevData) => prevData.filter((row) => row.id !== id));

            setShowUnderProcess(0);
            setShowToster(1);
            setTosterValue(0);
            setTosterMessage("Data Deleted Successfully!");
            setTimeout(() => {
                setShowToster(0);


            }, 2000)
        }
        else {
            setShowUnderProcess(0);
            setShowToster(1);
            setTosterValue(1);
            setTosterMessage(DataToBeDeleted.responseValue);
            setTimeout(() => {
                setShowToster(0);
            }, 2000)
        }
    }


    useEffect(() => {
        getTaskCategory();
        getOrders();


    }, [getPmid, getClientId]);
    useEffect(() => {
        setSelectedItem('');
        setRemarks('');
    }, [getPmid, getClientId]);
    return (
        <>
            <section className="med-box" style={{ boxShadow: 'none' }}>
                <div className='icuChartHeading mb-2'>
                    <div className='icuChartHeading-inn'>
                        <div className='ICUHead-lft'>
                            <span><img src={IconOrders} alt='' /></span>
                            <span><h3>Orders</h3></span>
                        </div>
                    </div>
                    <div className='icuChartHeading-inn pointSpan' style={{display:props.isPrint===1?"none":"block"}}>
                        <span><img src={IconIcuAdd} onClick={() => {
                            const modal = document.getElementById('saveTaskCategory');
                            if (modal) {
                                modal.classList.add('show');
                                modal.style.display = 'block';
                                document.body.classList.add('modal-open');
                                document.body.style.paddingRight = '17px'; // modal's scrollbar width

                            }
                        }} alt='' /></span>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 mt-2">
                            {/* Bootstrap Modal */}
                            <div
                                className="modal fade"
                                id="saveTaskCategory"
                                tabIndex="-1"
                                aria-labelledby="deleteModalLabel"
                                aria-hidden="true">
                                <div className="modal-dialog" >
                                    <div className="modal-content p-0">
                                        <div className="modal-header">
                                            {/*  modal header content */}
                                            <h5 className="modal-title" id="deleteModalLabel" style={{ color: '#fff' }}>
                                                Orders
                                            </h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={closeModal}
                                            ></button>
                                        </div>
                                        <div className="modal-body">
                                            {/*  modal body content  */}
                                            <label htmlFor="taskCategory" className="form-label">
                                                Select Order
                                            </label>
                                            <select
                                                className="form-select form-select-sm"
                                                id="taskCategory"
                                                name="taskCategory"
                                                value={selectedItem ? selectedItem.categoryName : ''}
                                                onChange={(e) => {
                                                    setSelectedItem(
                                                        taskCategoryList.find(
                                                            (category) => category.categoryName === e.target.value
                                                        )
                                                    );
                                                    handleChange(e);
                                                }}
                                            >
                                                <option value="">Select Order</option>
                                                {taskCategoryList.map((category) => (
                                                    <option key={category.id} value={category.categoryName}>
                                                        {category.categoryName}
                                                    </option>
                                                ))}
                                            </select>{' '}
                                            <small
                                                id="taskCategoryId"
                                                className="form-text text-danger"
                                                style={{ display: 'none' }}
                                            ></small>
                                            <div>
                                                <label htmlFor="remarks" className="form-label mt-3">
                                                    Critical Care
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    id="remarks"
                                                    name="remarks"
                                                    value={remarks}
                                                    onChange={(e) => {
                                                        setRemarks(e.target.value);
                                                        handleChange(e);
                                                    }}
                                                    placeholder="Enter Remarks"
                                                />
                                                <small
                                                    id="remarksId"
                                                    className="form-text text-danger"
                                                    style={{ display: 'none' }}
                                                ></small>
                                            </div>
                                            <div className="col sm-12" style={{ textAlign: 'right' }}>
                                                <button
                                                    type="button"
                                                    className="btn btn-save btn-save-fill btn-sm mt-3"
                                                    onClick={() => {
                                                        insertOrder().then(() => getOrders());
                                                    }}
                                                >
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ------------------------End of Bootstrap Modal----------------------------*/}
                            {/* -----------------------Start Delete Modal Popup-------------------    */}

                            <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                <div className="modal-dialog modalDelete">
                                    <div className="modal-content">
                                        <div className="modal-body modelbdy text-center">
                                            <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                            <div className='popDeleteTitle mt-3'> Delete?</div>
                                            <div className='popDeleteContent'> Are you sure you want to delete?</div>
                                        </div>
                                        <div className="modal-footer1 text-center">

                                            <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                            <button type="button" className="btn-delete popBtnDelete" onClick={deleteRow} data-bs-dismiss="modal">Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* -----------------------End Delete Modal Popup---------------------  */}

                            {getOrdersData.length > 0 && (
                                <div className="med-table-section Icu-component" style={{ "height": "30vh" }}>
                                <table className='med-table tblICUChart'>
                                    <tbody>
                                        {getOrdersData.map((row, index) => (
                                            <tr key={index}>
                                                <td style={{ border: 'none', whiteSpace: 'nowrap' }}>{row.categoryName}</td>
                                                <td style={{ border: 'none', width: '75%' }}>
                                                    <input
                                                        style={{ backgroundColor: '#fcf2e7', border: 'none' }}
                                                        className="form-control form-control-sm"
                                                        type="text"
                                                        value={row.taskRemark}
                                                        onChange={(e) => {
                                                            const updatedRows = [...getOrdersData];
                                                            updatedRows[index].taskRemark = e.target.value;
                                                            setOrdersData(updatedRows);
                                                        }}
                                                    />
                                                </td>
                                                {/*<td style={{ border: 'none', width: '10%', height: '100%' }}>
                                                    <div style={{ backgroundColor: getStatusColor(row), padding: '2px 6px', color: getStatusTextColor(row), textAlign: 'center', height: '100%', borderRadius:'3px' }}>
                                                        {getStatusText(row)}
                                                    </div>
                                                    </td>*/}
                                                <td style={{ border: 'none', width: '10%', height: '100%' }}>
                                                    <div style={{ backgroundColor: getStatusColor(row), padding: '2px 6px', textAlign: 'center', height: '100%', borderRadius: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        {getStatusText(row) === 'Completed' && <img src={doneIcon} alt="Completed" style={{ marginRight: '5px' }} />}
                                                        {getStatusText(row) === 'Rejected' && <img src={rejectedIcon} alt="Rejected" style={{ marginRight: '5px' }} />}
                                                        {getStatusText(row) === 'Pending' && <img src={pending} alt="Pending" style={{ marginRight: '5px' }} />}
                                                        <span style={{ color: getStatusTextColor(row) }}>{getStatusText(row)}</span>
                                                    </div>
                                                </td>
                                                <td style={{ border: 'none', width: '7%' }}>
                                                    <div className="action-button">

                                                        <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteIcon} className='' alt='' onClick={() => { setRowID(row.id); }} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

