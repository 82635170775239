import React, { useEffect, useId, useState } from 'react'
import LineInvestigationGrap from '../../../Components/LineInvestigationGrap';
import TimeCalculate from '../../../Code/TimeCalculate';
import GetAllDashboardParameterColumnAssign from '../../../Api/GetAllDashboardParameterColumnAssign';
import { useSelector } from 'react-redux';

export default function SGOTR(props) {

    let [sgot, setSgot] = useState("");
    let [sgotId, setSgotId] = useState("");
    let [sgotTime, setSgotTime] = useState("");
    let [sgotColor, setSgotColor] = useState("");

    let [uhId, setUhId] = useState("")

    // set grap Investigation data
    let [grapInvestigationData, setGrapInvestigationData] = useState({
        showGrap: 0,
        subTestId: '',
        patientName: '',
        UHID: '',
        userId: ''
    })

    let getData = () => {
        setSgot("");
        setSgotId("");
        setSgotTime("");
        setSgotColor("");

        props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
            if (investigation.SubTestID.toString() === "743" || investigation.SubTestID.toString() === "1637") {
                setSgot(Math.round(investigation.SubTestValue * 100) / 100)
                setSgotColor(investigation.SubTestColor)
                if (props.handleColor !== undefined) {
                    props.handleColor(investigation.SubTestColor !== null ? investigation.SubTestColor : "white", props.columnId)
                }

                setSgotId(investigation.SubTestID)
                setUhId(props.patientData.UhId)

                if (investigation.SubTestDateTime !== '') {
                    setSgotTime(TimeCalculate(investigation.SubTestDateTime))


                }

            }
        })
    }

    let modelCloseFun = () => {
        setGrapInvestigationData({
            showGrap: 0,
            subTestId: '',
            patientName: '',
            UHID: '',
            userId: ''
        })
    }
    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
           if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

    }


    const keyId = useId();
    useEffect(() => {
        getColumnsParameters()

        getData()
    }, [props.patientData])
    return (
        <>
            {/* <div key={props.key} className={`overflow-max investigations ${(sgotColor !== "#008000" && sgotColor !== "#94e094" && sgotColor !== "#F5F8FF" && sgotColor !== "" ? "blink" : "")} `} style={{ border: (sgotColor !== "#008000" && sgotColor !== "#94e094" && sgotColor !== "#F5F8FF" && sgotColor !== "" ? "2px solid " + sgotColor : "") }}> */}
            <div key={props.key} className={`overflow-max investigations`}>

                {sgot.length != 0 ? sgot ? <span>

                    {
                        paramenterData && paramenterData.map((val, ind) => {
                            if (val.parameterId === 105) {
                                return (
                                    <>
                                        <span style={{ color: sgotColor }}>{sgot}</span><br />



                                    </>

                                )
                            }

                            else if (val.parameterId === 106) {
                                return (
                                    <>
                                        {
                                            sgotTime.split("-")[1] === "D" && sgotTime.split("-")[0] >= 4 ? <span style={{ 'color': 'red' }} className="blink">{sgotTime.toLowerCase().replace("-", "")}</span>
                                                : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {sgotTime.toLowerCase().replace("-", "")}</span>}
                                        <br />

                                    </>

                                )
                            }

                            else if (val.parameterId === 107) {
                                return (
                                    <>
                                        <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='S.G.O.T Graph' >
                                            {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                                                if (val.SubtestID === sgotId) {
                                                    return (
                                                        <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                                                    )
                                                }
                                            })}
                                            <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [sgotId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "S.G.O.T Graph", patientName: props.patientData.PntName }) }}></i></div>

                                        {/* <br /> */}
                                    </>

                                )
                            }


                        })

                    }
                    {/* <span >{sgot_time}</span> */}

                </span> : 'N/A' : "N/A"}
            </div>
            {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}
        </>
    )
}
