import React, { useEffect, useState } from 'react'
import SuccessToster from "../../Component/SuccessToster";
import AlertToster from "../../Component/AlertToster";
import Select from 'react-select';
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import clearIcon from '../../assets/images/icons/clear.svg';
import GetDepartmentList from '../../Registartion/API/GET/GetDepartmentList'
import DropdownWithSearch from '../../Component/DropdownWithSearch'
import GetWardList from '../../Registartion/API/GET/GetWardList'
import GetPatientDetailsforSarvodaya from './API/GetPatientDetailsforSarvodaya';
import GetBloodGroup from './API/GetBloodGroup';
import PostImmunohematogy from './API/PostImmunohematogy';
import ProvisonalDiagnosisMainComponent from './ProvisonalDiagnosisMainComponent';
import MedicineProcedure from './MedicineProcedure';
import InvestigationsRows from './InvestigationsRows';
import AntiDProphylaxis from './AntiDProphylaxis';
import Prophylaxis from './Prophylaxis';
import imgPrint from '../../assets/images/icons/imgPrint.svg'
import GetImmuneohemotology from './API/GetImmuneohemotology';
import GetDoctor from './API/GetDoctor';
import PatientDeatils from '../../Component/PatientDeatils.js';

export default function Immunohematology() {
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [showSuccessToster, setShowSuccessToster] = useState(0)
  let [showMessage, setShowMeassage] = useState("");
  let [historyofTransfusionSpecify, sethistoryofTransfusionSpecify] = useState("");
  let [departmentList, setDepartmentList] = useState([])
  let [Saverow, setSaverow] = useState([])
  let [selectedBloodGroup, setselectedBloodGroup] = useState(null)
  let [selectedRequestedBy, setselectedRequestedBy] = useState(null)
  const [isClearable,] = useState(true);
  const [isSearchable,] = useState(true)
  const [PatientData, setPatientData] = useState();
  let [saveDiagnosis, setSaveDiagnosis] = useState([]);
  let [HistoryPreganancy, setHistoryPreganancy] = useState(0);
  let [bloodtransfusionYes, setbloodtransfusionYes] = useState(0);
  let [HistoryPregYes, setHistoryPregYes] = useState(0);
  let [HistoryPregNo, setHistoryPregNo] = useState(0);
  let [bloodtransfusionNo, setbloodtransfusionNo] = useState(0);
  const [medicationData, setMedicationData] = useState([])
  const [BloodGroup, setBloodGroup] = useState([])
  const [antiDProphylaxis, setantiDProphylaxis] = useState([])
  const [investigationJson, setinvestigationJson] = useState([])
  const [ProphylaxisJson, setProphylaxisJson] = useState([])
  const [TableData, setTableData] = useState([])
  let [getInvestigaionJSON, setgetInvestigaionJSON] = useState([]);
  let [getProphylaxisJSON, setgetProphylaxisJSON] = useState([]);
  let [DoctorDropdown, setDoctorDropdown] = useState([]);
  let [getDiagnosis, setgetDiagnosis] = useState([]);
  let [GetMedication, setGetMedication] = useState([]);
  let [GetAntiDProphylaxisJson, setGetAntiDProphylaxisJson] = useState([]);




  const clientID = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
  let [userID] = useState(JSON.parse(sessionStorage.getItem("LoginData")).userId);


  const currentDate = new Date();
  const DateFormated = currentDate.toISOString().substr(0, 10);
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  const formattedTime = `${hours}:${minutes}`;
  let formattedDate = '';
  if (PatientData && PatientData.admitDate) {
    const parts = PatientData.admitDate.split('/');
    formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;

  }



  let requiredUHID = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].uhId;
  let getData = async () => {
    let responseDepartment = await GetDepartmentList()
    if (responseDepartment.status === 1) {
      setDepartmentList(responseDepartment.responseValue)
    }
  }
  const handleSelectChange = (selectedOption, errorElementId, setSelectedFunction) => {
    // document.getElementById(errorElementId).style.display = 'none';
    setSelectedFunction(selectedOption);
  };
  const GetPatientData = async () => {
    let data = await GetPatientDetailsforSarvodaya(requiredUHID)
    if (data.status === 1) {
      setPatientData(data.responseValue[0])
      console.log("PatientData", data.responseValue)
    }

  }


const handleClearInvestigation=(row)=>{
setSaverow([row])
}

  const handleMedicationDataUpdate = (temp, data) => {
    setMedicationData(temp, data);
  };
  const handleInvestigationJSON = (temp, data) => {
    setinvestigationJson(temp, data);

  };
  const handleantiDProphylaxis = (temp, data) => {
    setantiDProphylaxis(temp, data);

  };
  const handleProphylaxisJson = (temp, data) => {
    setProphylaxisJson(temp, data);

  };


  const getWardDetails = async () => {
    let responseWard = await GetWardList()
    if (responseWard.status === 1) {
      setDepartmentList(responseWard.responseValue.map(val => ({
        value: val.id,
        label: val.departmentName
      })))
      console.log("responseWard", responseWard.responseValue)
    }
  }


  const handleOnchange = (e) => {
    const { name , value} = e.target;

    if (name === 'HistoryPregYes') {
      setHistoryPregYes(1);
      setHistoryPregNo(0);
    }
    if (name === 'HistoryPregNo') {
      setHistoryPregNo(1);
      setHistoryPregYes(0);
    }

    if (name === 'HoBloodYes') {
      setbloodtransfusionYes(1);
      document.getElementById("textFieldbloodtransfusion").style.display = 'block'
      setbloodtransfusionNo(0);
    }
    if (name === 'HoBloodNo') {
      setbloodtransfusionNo(1);
      document.getElementById("textFieldbloodtransfusion").style.display = 'none'
      setbloodtransfusionYes(0);
    }
    if (name === 'historyofTransfusionSpecify') {
      sethistoryofTransfusionSpecify(value);
     
    }
  };


  const GetBlood = async () => {
    let data = await GetBloodGroup()
    if (data.status === 1) {
      console.log("Blood Group", data.responseValue)
      setBloodGroup(data.responseValue.map(val => ({
        value: val.id,
        label: val.groupName
      })));
    }
  }
  const GetDoctors = async () => {
    let data = await GetDoctor()
    if (data.status === 1) {
      console.log("requestedBy", data.responseValue)
      setDoctorDropdown(data.responseValue.map(val => ({
        value: val.id,
        label: val.name
      })));
    }
  }


  const handleOnSave = async () => {
    if(bloodtransfusionYes === 0 && bloodtransfusionNo === 0){
      setShowAlertToster(1)
      setShowMeassage("Please Select History of Blood Transfusion")
      return
    }

    if(bloodtransfusionYes === 1 && historyofTransfusionSpecify.trim() == ''){
      setShowAlertToster(1)
      setShowMeassage("Please Enter History of Blood Transfusion");
      return
    }

    if(PatientData && PatientData.gender == "Female" && HistoryPregYes === 0 && HistoryPregNo === 0){
      setShowAlertToster(1)
      setShowMeassage("Please Select History of Pregnancy");
      return
    }

    if (selectedBloodGroup === null) {
      setShowAlertToster(1)
      setShowMeassage("Please Select Blood Group")
      return
    }

    const obj = {
      uhid: requiredUHID,
      pregnencyHistory: HistoryPregYes ,
      isbloodTransFution: bloodtransfusionYes === 1 ? `Yes ${historyofTransfusionSpecify}` : "No",
      medicationJson: JSON.stringify(medicationData),
      investigationJson: JSON.stringify(investigationJson),
      // antiDProphylaxis: JSON.stringify(antiDProphylaxis),
      prophylaxisJson: JSON.stringify(antiDProphylaxis),
      bloodGroupId: selectedBloodGroup.value,
      clientId: clientID,
      userId: userID,
    }
    console.log("savedData", obj)
    
     let clearData ={
      setClear : 1
     }
   
    let data = await PostImmunohematogy(obj)

    if (data.status === 1) {
      console.log("savedData", obj)
      setShowSuccessToster(1)
      setSaveDiagnosis(1)
      setShowMeassage("Data Saved Successfully")
      window.sessionStorage.setItem("runClearData" , JSON.stringify(clearData))
      handleClearInvestigation()
      handleClear()
      GetImmuneohemotologydata()
      setTimeout(() => {
        window.sessionStorage.removeItem("runClearData");
      }, 1000);
      setTimeout(() => {
        setShowSuccessToster(1)
   
      }, 2000);
    } else {
      setShowAlertToster(1)
      setShowMeassage(data.responseValue)
      setTimeout(() => {
        setShowAlertToster(0)
      }, 2000);
    }

  }


  const handlePrint = (index) => {
    let obj = {

      pName: PatientData.patientName,
      age: PatientData.age,
      gender: PatientData.gender,
      ward: PatientData.wardName,
      bedNo: PatientData.bedName,
      ipNo: PatientData.ipNo,
      MRno: PatientData.ipNo,
      doa: PatientData.admitDate,
      doctorName: PatientData.doctorName,
      date: DateFormated,
      time: formattedTime,
      rowData: TableData[index]

    }
    window.sessionStorage.setItem("Immuneohemotologydata", JSON.stringify(obj));
    window.open("/ReceiptRequestFormprint/", "noopener,noreferrer");
    console.log("rowData", obj)
  }


  const handleClear = () => {
    let clearData ={
      setClear : 1
     }
    window.sessionStorage.setItem("runClearData" , JSON.stringify(clearData))
    handleClearInvestigation()
   
   
    setTimeout(() => {
      window.sessionStorage.removeItem("runClearData");
    }, 1000);
    setselectedBloodGroup(null);
    setHistoryPregYes(0)
    setHistoryPregNo(0)
    setbloodtransfusionYes(0)
    setbloodtransfusionNo(0)
    setselectedRequestedBy(null)
    setinvestigationJson('')
    setantiDProphylaxis('')
    setProphylaxisJson('')
    document.getElementById("textFieldbloodtransfusion").style.display = 'none'
  }


  const GetImmuneohemotologydata = async () => {
    let data = await GetImmuneohemotology(requiredUHID)
    if (data.status === 1) {
      const mappedData = data.responseValue.map(item => {
        return {
          ...item,
          investigationFormated: item.investigationJson ? JSON.parse(item.investigationJson) : [],
          medicationFormated: item.medicationJson ? JSON.parse(item.medicationJson) : [],
          diagnosisFormated: item.diagnosisJson ? JSON.parse(item.diagnosisJson) : [],
          prophyaxisFormated: item.prophylaxisJson ? JSON.parse(item.prophylaxisJson) : [],
          historyOfPreg: item.historyOfPregnency ? JSON.parse(item.historyOfPregnency) : [],
          historyOfBloodTrans: item.historyOfBloodTransfusion ? JSON.parse(item.historyOfBloodTransfusion) : [],
          // diagnosticFormnated : item.diagnosisJson ? JSON.parse(item.diagnosisJson) : [],
          // investigationFormated : item.investigationJson ? JSON.parse(item.investigationJson) : [],
        };
      });
      setTableData(mappedData)
      let allInvestigations = []
      let allMedications = []
      let allDiagnosis = []
      let allprophylaxis = []
      let historyOfPregnencyArray = []
      let historyOfBloodTransfusionArray = []



      data.responseValue.forEach((item, index) => {
        allInvestigations.push(item.investigationFormated);
        allMedications.push(item.medicationFormated)
        allprophylaxis.push(item.prophyaxisFormated)
        allDiagnosis.push(item.diagnosisFormated)
        historyOfPregnencyArray.push(item.historyOfPreg)
        historyOfBloodTransfusionArray.push(item.historyOfBloodTrans)


      })



      setgetInvestigaionJSON(allInvestigations)
      console.log("GetImmuneohemotology", mappedData)
    }




  }

  console.log("GetMedication", GetMedication)
  console.log("getInvestigaionJSON", getInvestigaionJSON)

  let drugName = GetMedication && GetMedication.map((val) => val.drugName)
  console.log("drugName", drugName)
  useEffect(() => {
    getData()
    GetImmuneohemotologydata()
    GetPatientData()
    getWardDetails()
    GetBlood()
    GetDoctors()

  }, [])

  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12"><div className="med-box mb-1"><div className="title">Request Form For Immunohematology Tests/Blood Group</div></div></div>
            <div className="col-12">
              <div className="med-box">

                <div className="inner-content">
                <PatientDeatils/>
                  <div className='fieldsett-in col-md-12'>
                    <div className='fieldsett'>
                    <span className='fieldse'>Provisional Diagnosis</span>
                      <div className="row px-4 mt-1">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">

                          {/* <ProvisionalDiagnosiRows dignosticDetails={handleDiagnosticJSON} /> */}
                          <ProvisonalDiagnosisMainComponent isProvisional={0} saveDiagnosis={saveDiagnosis} setSaveDiagnosis={setSaveDiagnosis} />
                        </div>



                      </div>
                    </div>
                 
                  </div>
                  <div className='fieldsett-in col-md-12'>
                  
                    <div className='fieldsett mt-1'>
                    {/* <span className='fieldse'>Provisional Diagnosis</span> */}
                      <div className="row px-2 ">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">

                        <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-6 mb-2">



                               <div className="col-xxl-7 col-xl-7 col-lg-12 col-md-12 d-flex column-gap-3 mt-4 me-3">
                                  <label htmlFor="canWrite" className="form-label">History Of Blood Transfusion<span className="starMandatory"></span></label>
                                  <div className="form-check">
                                  <label for="canRead" className="form-label">Yes<span className="starMandatory"></span></label><input id="canWrite" className="form-check-input" checked={bloodtransfusionYes} name='HoBloodYes' type="checkbox" onChange={handleOnchange} />
                                  </div>
                                 
                                  <div className="form-check">
                                  <label for="canRead" className="form-label">No<span className="starMandatory"></span></label><input id="canWrite" className="form-check-input" checked={bloodtransfusionNo} name='HoBloodNo' type="checkbox" onChange={handleOnchange} />
                                  </div>
                                  <div className="col-xxl-6 col-xl-3 col-lg-3 col-md-6 mb-3" id="textFieldbloodtransfusion" style={{display: 'none'}}>
                                     <input id="PAgeGender" type="text" name='historyofTransfusionSpecify' className="form-control form-control-sm"  placeholder="Enter History of Blood Tranfusion" value={historyofTransfusionSpecify} onChange={handleOnchange} />
                                   </div>
                                  <small id="errcanwrite" className="form-text text-danger" style={{ display: 'none' }}></small>
                               </div>

{/* <div className="d-flex column-gap-3 mt-4">
  <label htmlFor="canDelete" className="form-label">H/o Pregnancy<span className="starMandatory"></span></label>
  <div className="form-check">
    <input id="canDelete" name="HoPregnancy" className="form-check-input" type="checkbox" checked={HistoryPreganancy === 1} onChange={handleOnchange} />
  </div>
  <small id="errcandelete" className="form-text text-danger" style={{ display: 'none' }}></small>
</div> */}
                          </div>
                        </div>
                        {/* <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2">
                            <label htmlFor="Code" className="form-label">Consultant Doctor<span className="starMandatory"></span></label>
                            <input id="consulteDr" type="text" disabled value={PatientData && PatientData.doctorName} className="form-control form-control-sm" name="CDoctor" placeholder='Enter Consultant Doctor' />
                          </div> */}


                      </div>
                    </div>
                  </div>
                  
                  <div className={`fieldsett-in col-md-12 ${PatientData && PatientData.gender == "Male" ? 'd-none' : 'd-block'}`}>
                    <div className='fieldsett'>
                    <span className='fieldse'>Pregnancy Details</span>
                    <div className="row px-2 ">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">

                        <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-6 mb-2">




 <div className="d-flex column-gap-3 mt-4">
  <label htmlFor="canDelete" className="form-label">H/o Pregnancy<span className="starMandatory"></span></label>
                         <div className="form-check">
                                  <label for="canRead" className="form-label">Yes<span className="starMandatory"></span></label><input id="canWrite" className="form-check-input" checked={HistoryPregYes} name='HistoryPregYes' type="checkbox" onChange={handleOnchange} />
                                  </div>
                                  <div className="form-check">
                                  <label for="canRead" className="form-label">No<span className="starMandatory"></span></label><input id="canWrite" className="form-check-input" checked={HistoryPregNo} name='HistoryPregNo' type="checkbox" onChange={handleOnchange} />
                                  </div>
                               <small id="errcandelete" className="form-text text-danger" style={{ display: 'none' }}></small>
                                  </div> 
                          </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2">
                            <label htmlFor="Code" className="form-label">Anti D Prophylaxis<span className="starMandatory"></span></label>
                            <AntiDProphylaxis antiDProphylaxis={handleantiDProphylaxis} />
                          </div> 
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2">
                            <label htmlFor="Code" className="form-label">Medication<span className="starMandatory"></span></label>
                            <MedicineProcedure onMedicationDataUpdate={handleMedicationDataUpdate} />
                          </div> 
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2">
                            <label htmlFor="Code" className="form-label">Consultant Doctor<span className="starMandatory"></span></label>
                            <input id="consulteDr" type="text" disabled value={PatientData && PatientData.doctorName} className="form-control form-control-sm" name="CDoctor" placeholder='Enter Consultant Doctor' />
                          </div> 
                      </div>
                    </div>
                 
                  </div>




                
                </div>
              </div>
            </div>


            <div className="col-12 mt-2">
              <div className="med-box">

                <div className="inner-content">
                  <div className="row">
                  
                   

                    <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 mt-2">

                      <div className='fieldsett-in col-md-12'>
                        <div className='fieldsett'  style={{minHeight: '110px' , maxHeight: '110px'}}>
                          <span className='fieldse'>Test Required/Investigations</span>
                          <div className="row">

                           <div style={{minHeight: '90px' , maxHeight: '90px' , overflow: 'auto'}}>
                            <InvestigationsRows investigationsJson={handleInvestigationJSON} clearData={handleClearInvestigation}/>
                           </div>
                            


                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 mt-2">
                        <div className='fieldsett-in col-md-12'>
                          <div className='fieldsett'  style={{minHeight: '110px' , maxHeight: '110px'}}>
                          <span className='fieldse'>Blood Group</span>
                             <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-6">
                              <label htmlFor="ddlitemmaster" className="form-label "> <span className="starMandatory"></span></label>
                              <Select value={selectedBloodGroup} options={BloodGroup} className=" create-select" id="serviceType" placeholder="Select Blood Group" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, "errBloodGroup", setselectedBloodGroup)} />
                              <small id="errBloodGroup" className="form-text text-danger" style={{ display: 'none' }}></small>
                            </div>
                          </div>
                        </div>
                           </div>


                    <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 mt-2">
                       <div className='fieldsett-in col-md-12'>
                         <div className='fieldsett'  style={{minHeight: '110px' , maxHeight: '110px'}}>
                         <span className='fieldse'>Requested By</span>
                         <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-6">
                             <label htmlFor="ddlitemmaster" className="form-label "> <span className="starMandatory"></span></label>
                             <Select value={selectedRequestedBy} options={DoctorDropdown} className=" create-select" id="serviceType" placeholder="Select Requested By" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, "errselectedRequestedBy", setselectedRequestedBy)} />
                             <small id="errselectedRequestedBy" className="form-text text-danger" style={{ display: 'none' }}></small>
                           </div>
                         </div>
                       </div>
                           </div>

                    {/* <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 mt-2">
              
                <div className='fieldsett-in col-md-12'>
                <div className='fieldsett' style={{padding: '20px'}}>
                <span className='fieldse'>Prophylaxis</span>
               <div className="row">
              
                   
                     <Prophylaxis  ProphylaxisJson = {handleProphylaxisJson}/>
          
               </div>
               </div>
               </div>
               </div> */}
                  </div>
                </div>
              </div>
            </div>




            <div className="col-12 ">
              <div className="med-box mt-2">
                <div className="inner-content">
                  <div className="row">

                    <div className="inner-content text-right">
                      <div className="mb-2 mt-2 relative">
                        <div>
                          <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1_ me-1" onClick={handleOnSave} ><img src={saveButtonIcon} className="icnn" alt='' />Save</button>
                          <button type="button" className="btn btn-clear btn-sm mb-1_ me-1" onClick={handleClear} ><img alt='' />Clear</button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="col-12 mt-3">
          <div className="med-table-section" style={{ "height": "80vh" }}>
            <table className="med-table border striped">
              <thead style={{ zIndex: '0' }}>
                <tr>
                  <th className="text-center" style={{ "width": "5%" }}>#</th>
                  <th>UHID</th>
                  <th>Diagnosis</th>
                  <th>History of Blood Transfusion</th>
                  <th className={`${PatientData && PatientData.gender == "Male" ? 'd-none' : ''}`}>History of Pregnancy</th>
                  <th className={`${PatientData && PatientData.gender == "Male" ? 'd-none' : ''}`}>Anti D Prophylaxis</th>
                  <th className={`${PatientData && PatientData.gender == "Male" ? 'd-none' : ''}`}>Medications</th>
                  <th>Diagnosis</th>
                  <th>Investigations</th>
                  
                  <th>Blood Group</th>
                
                 
                  <th style={{ "width": "10%" }} className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>

                {TableData && TableData.map((data, index) => {

                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{requiredUHID}</td>

                      <td>{data.diagnosisFormated.map((val, index) => (
                        <span key={index}>{val.details}
                          {index < data.diagnosisFormated.length - 1 && <br />}
                        </span>
                      ))}
                      </td>
                    
                      <td>{data.historyOfBloodTrans.map((val, index) => (
                        <span key={index}>{val.details}
                          {index < data.historyOfBloodTrans.length - 1 && <br />}
                        </span>
                      ))}
                      
                      </td>
                        <td className={`${PatientData && PatientData.gender == "Male" ? 'd-none' : ''}`}>{data.historyOfPreg.map((val, index) => (
                        <span key={index}>{val.details == "No" ? 'NA' : val.details }
                          {index < data.historyOfPreg.length - 1 && <br />}
                        </span>
                      ))}
                      </td>

                         <td className={`${PatientData && PatientData.gender == "Male" ? 'd-none' : ''}`}>
                            {data.prophyaxisFormated.length > 0 ? (
                              data.prophyaxisFormated.map((val, index) => (
                                <span key={index}>
                                  {val.drugName}
                                  {index < data.prophyaxisFormated.length - 1 && <br />}
                                </span>
                              ))
                            ) : (
                              "NA"
                            )}
                          </td>


                             <td className={`${PatientData && PatientData.gender == "Male" ? 'd-none' : ''}`}>
                              {data.medicationFormated.length > 0 ? (
                                data.medicationFormated.map((val, index) => (
                                  <span key={index}>
                                    {val.drugName}
                                    {index < data.medicationFormated.length - 1 && <br />}
                                  </span>
                                ))
                              ) : (
                                "NA"
                              )}
                            </td>

                      <td>{data.diagnosisFormated.map((val, index) => (
                        <span key={index}>{val.details}
                          {index < data.diagnosisFormated.length - 1 && <br />}
                        </span>
                      ))}

                      </td>


                      <td>{data.investigationFormated.map((val, index) => (
                        <span key={index}>{val.TestName}
                          {index < data.investigationFormated.length - 1 && <br />}
                        </span>
                      ))}

                      </td>


                      <td>{data.bloodGroupName}</td>
                      <td>

                        <div className="action-button">
                          <div data-bs-title="Delete Row" data-bs-placement="bottom"  ><img src={imgPrint} style={{ width: '20px' }} className='' alt='' onClick={() => handlePrint(index)} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })}


              </tbody>

            </table>
          </div>
        </div>



        {showAlertToster === 1 ? (
          <AlertToster message={showMessage} handle={setShowAlertToster} />
        ) : (
          ""
        )}
        {showSuccessToster === 1 ? (
          <SuccessToster
            message={showMessage}
            handle={setShowSuccessToster}
          />
        ) : (
          ""
        )}
      </section>

    </>
  )
}


