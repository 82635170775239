import React, { useEffect, useState } from 'react'
import ChangePassword from './ChangePassword'
import UserProfile from './UserProfile'
import languageIcon from "../../../../assets/images/icons/languageIcon.png";
import { useNavigate } from "react-router-dom";
import HandleLanguage from '../../../../Code/LanguageManage';
import GetMenuAndDeptByHeadId from '../../../../Dashboard/API/GetMenuAndDeptByHeadId';
import GetHeadListByUserId from '../../../../Dashboard/API/GetHeadListByUserId';
import GetAllLanguageMaster from './API/GetAllLanguageMaster';
import AssignSettingParameter from '../../../../Admin/Pages/AssignSettingParameter/AssignSettingParameter';

export default function UserProfileSetting() {
    let [activeTab, setActiveTab] = useState(1)

    const storedLanguageId = JSON.parse(window.sessionStorage.getItem('languageId'))?.languageId;
    const [LanguageList, setLanguagelist] = useState([]);
    const [Languageselect, setLanguageselect] = useState('1'); // Initialize with English (ID 1)
    const [selectedValue, setSelectedValue] = useState("0");
    const navigate = useNavigate();
    const GetLanguageList = async () => {
        let langList = await GetAllLanguageMaster();
        if (langList.status === 1) {
            window.sessionStorage.setItem("LanguageData", JSON.stringify(langList.responseValue));
            setLanguagelist(langList.responseValue);
        }

        const departmentMenu = window.sessionStorage.getItem("departmentmenu");

        if (departmentMenu) {
            const menuList = JSON.parse(departmentMenu).menuList;
            const settingElement = document.getElementById("setting");

            if (menuList.length !== 0) {
                settingElement.classList.add("main-content");
            } else {
                settingElement.classList.remove("main-content");
            }
        }
    };

    const handleOnchange = async (e) => {
        const selectedLanguageId = e.target.value;
        console.log("data", selectedLanguageId)
        window.languageId = selectedLanguageId
        setLanguageselect(selectedLanguageId);
        const selectedOption = LanguageList.find(val => val.id == selectedLanguageId);
        let res = await GetHeadListByUserId(selectedLanguageId)
        let wardId = JSON.parse(window.sessionStorage.getItem("activePage")).WardId
        let respDepMenu = await GetMenuAndDeptByHeadId(wardId, selectedLanguageId)
        if (respDepMenu.status === 1) {
            console.log("sdasfs", respDepMenu)
            let data = JSON.parse(window.sessionStorage.getItem("departmentmenu"))
            data.departmentList = respDepMenu.responseValue.departmentList
            data.menuList = respDepMenu.responseValue.menuList
            window.sessionStorage.removeItem("departmentmenu")
            window.sessionStorage.setItem("departmentmenu", JSON.stringify(data))
            window.location.reload()

        }
        if (res.status === 1) {
            let headData = JSON.parse(window.sessionStorage.getItem("LoginData"))
            headData.headList = res.responseValue
            window.sessionStorage.removeItem("LoginData")
            window.sessionStorage.setItem("LoginData", JSON.stringify(headData))
            // window.location.reload()


        }
        if (selectedOption) {
            const abbreviation = selectedOption.abbrivation;
            const languageName = selectedOption.languageName;
            setSelectedValue(selectedOption);
            HandleLanguage(abbreviation);
            const languageData = {
                languageId: selectedLanguageId,
                languageName: languageName,
            };

            const languageDataJSON = JSON.stringify(languageData);
            window.sessionStorage.setItem("languageId", languageDataJSON);
            navigate(-1)

        }
    };

    useEffect(() => {
        if (!LanguageList.length) {
            GetLanguageList();
        }

        if (!storedLanguageId) {
            setLanguageselect('1');
        } else {
            setLanguageselect(storedLanguageId);
            const selectedOption = LanguageList.find(val => val.id == storedLanguageId);
            if (selectedOption) {
                HandleLanguage(selectedOption.abbrivation);
            }
        }
    }, [LanguageList, storedLanguageId]);

    return (
        <>
            <section className=" pt-3 mt-5" id="setting">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="med-box_">
                                <div className="profilesec" >
                                    <div className="wt-box profile-tabsec " >
                                        <div className='userhedaing'>Setting</div>
                                        <div className="tab-sec_">
                                            <div className="tabbtnn">
                                                <ul className="nav nav-tabs tabusersetting profile-tab-custome" id="myTab" role="tablist">
                                                    <li className="nav-item" role="presentation" onClick={() => { setActiveTab(1) }}>
                                                        <button className="nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="true">
                                                            <span>User Profile</span>
                                                            <i className="fas fa-chevron-right"></i>
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation" onClick={() => { setActiveTab(2) }}>
                                                        <button className="nav-link" id="changepassword-tab" data-bs-toggle="tab" data-bs-target="#changepassword-tab-pane" type="button" role="tab" aria-controls="changepassword-tab-pane" aria-selected="false" >
                                                            <span>Change Password</span>
                                                            <i className="fas fa-chevron-right"></i>
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation" onClick={() => { setActiveTab(3) }}>
                                                        <button className="nav-link" id="language-tab" data-bs-toggle="tab" data-bs-target="#language-tab-pane" type="button" role="tab" aria-controls="language-tab-pane" aria-selected="false">
                                                            <span>Change Language</span>
                                                            <i className="fas fa-chevron-right"></i>
                                                        </button>
                                                    </li>
                                                    {/* <li className="nav-item" role="presentation" onClick={() => { setActiveTab(4) }}>
                                                        <button className="nav-link" id="assignparameter-tab" data-bs-toggle="tab" data-bs-target="#assignparameter-tab-pane" type="button" role="tab" aria-controls="assignparameter-tab-pane" aria-selected="false">
                                                            <span>Assign Parameter</span>
                                                            <i className="fas fa-chevron-right"></i>
                                                        </button>
                                                    </li> */}
                                                </ul>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="wt-box flex-grow-1 profile-dtsec ">

                                        <div className="tab-content customtabcontent bgwhite" id="myTabContent">
                                            <div className="tab-pane fade show active userfrofileContainer bgwhite" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                                                <div className='userhedaing ps-2'>User Profile</div>
                                                <UserProfile />
                                            </div>

                                            <div className="tab-pane fade bgwhite" id="changepassword-tab-pane" role="tabpanel" aria-labelledby="changepassword-tab" tabIndex="0">
                                                <div className='userhedaing'>Change Password</div>
                                                <ChangePassword activeTab={activeTab} />
                                            </div>
                                            <div className="tab-pane fade bgwhite" id="language-tab-pane" role="tabpanel" aria-labelledby="language-tab" tabIndex="0" style={{ height: '490px' }}>
                                                <div className='userhedaing'>Change Language</div>
                                                <div className="userlaguagesect_ mb-1">
                                                    <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" data-placement="bottom" title="Exit"></span>
                                                    <div className="dropdown profile_dd-cnt ps-2">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <label htmlFor="ddlitemmaster" className="form-label selang"><img src={languageIcon} className="me-1" alt="" />Select Language</label><br />
                                                                <select className="selanghj form-select" value={Languageselect} onChange={handleOnchange}>
                                                                    {LanguageList && LanguageList.map((val, index) => {
                                                                        return (
                                                                            <option key={index} value={val.id}>{val.languageName}</option>

                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="tab-pane fade bgwhite" id="assignparameter-tab-pane" role="tabpanel" aria-labelledby="assignparameter-tab" tabIndex="0">
                                                <div className='userhedaing'>Assign Parameter</div>
                                                <AssignSettingParameter />
                                            </div> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
