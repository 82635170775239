import React, { useState, useEffect } from 'react'
import TableContainer from '../../Component/TableContainer'
import Heading from '../../Component/Heading'
import GetTaskNotAssignUser from '../API/Report/GetTaskNotAssignUser'
import GetTaskInprogressUser from '../API/Report/GetTaskInprogressUser'
import exportFromJSON from 'export-from-json';
//  import noData from '../../assets/images/icons/No data-rafiki.svg';
import noData from '../../assets/images/icons/No data-rafiki.svg';



export default function TaskNotAssignUser() {

    let [getTaskNotAssignUser, setTaskNotAssignUser] = useState([]);
    let [getTaskInprogressUser, setTaskInprogressUser] = useState([]);
    // const [searchInputTaskNotAssignUser, setSearchInputTaskNotAssignUser] = useState('');
    const [searchInputTaskInprogressUser, setSearchInputTaskInprogressUser] = useState('');
    const [currDateTaskNotAssign, setCurrDateTaskNotAssign] = useState('');
    const [currDateTaskInProgress, setCurrDateTaskInProgress] = useState('');
    const [finalTaskNotAssignList, setFinalTaskNotAssignList] = useState([]);
    const [finalTaskInProgressList, setFinalTaskInProgressList] = useState([]);


    const handleDateChange = (event) => {
        const { name, value } = event.target;
        if (name === 'taskNotAssign') {
            setCurrDateTaskNotAssign(value);
            funGetTaskNotAssignUser(value);
        };

        if (name === 'taskInProgress') {
            setCurrDateTaskInProgress(value);
            funGetTaskInprogressUser(value);
        };
        
    };

    const funGetTaskNotAssignUser = async (param) => {
        // const result = await GetTaskNotAssignUser(window.userId);        
        const result = await GetTaskNotAssignUser(param);
        if(result.status === 1){
            setTaskNotAssignUser(result.responseValue);
            setFinalTaskNotAssignList(result.responseValue);
        }
        else{
            setTaskNotAssignUser([]);
            setFinalTaskNotAssignList([]);
        }
    };

    const funGetTaskInprogressUser = async (param) => {
        // const result = await GetTaskInprogressUser(window.userId);
        const result = await GetTaskInprogressUser(param);
        if(result.status === 1){
            setTaskInprogressUser(result.responseValue);
            setFinalTaskInProgressList(result.responseValue);
        }
        else{
            setTaskInprogressUser([]);
            setFinalTaskInProgressList([]);
        }
    };

    const handleOnChangeTaskNotAssignUser = (e) => {
        const { name, value } = e.target;
        if(value){
            const filteredList = getTaskNotAssignUser.filter((val) => val.name.toLowerCase().includes(value.toLowerCase()));
            console.log('filteredList',filteredList);
            setFinalTaskNotAssignList(filteredList);
        }
        else{
            setFinalTaskNotAssignList(getTaskNotAssignUser);
        }        
    };


    const handleOnChangeTaskInprogressUser = (e) => {
        const { name, value } = e.target;
        if(value){
            const filteredList = getTaskInprogressUser.filter((val) => `${val.name} `.toLowerCase().includes(value.toLowerCase()));
            setFinalTaskInProgressList(filteredList);
        }
        else{
            setFinalTaskInProgressList(getTaskInprogressUser);
        }
    };
    
    let handleExportFileTaskNotAssignUser = () => {
        var arrFormat = [];
        getTaskNotAssignUser.map((val, ind) => {
            let param = ['SR', 'Name'];

            arrFormat.push({
                [param[0]]: ind + 1,
                [param[1]]: val.name,
            });
        });
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
        const data = arrFormat;
        const fileName = 'TaskNotAssigneUserList' + '' + formattedDate;
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType });
    };

    let handleExportFileTaskInprogressUser = () => {
        var arrFormat = [];
        getTaskInprogressUser.map((val, ind) => {
            let param = ['SR', 'Name', 'Sprint Backlog', 'Assign Date'];

            arrFormat.push({
                [param[0]]: ind + 1,
                [param[1]]: val.name,
                [param[2]]: val.sprintBacklogText,
                [param[3]]: reformatDateString(val.assignDate),
            });
        });
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
        const data = arrFormat;
        const fileName = 'TaskInprogressUserList' + '' + formattedDate;
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType });
    };

    function reformatDateString(s) {
        if (typeof s === 'string' && s.trim() !== '') {
            var b = s.split(/\D/);
            var day = parseInt(b[2], 10).toString();
            var month = parseInt(b[1], 10).toString();
            var year = b[0];
            return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
        } else {
            return ''; // Return an empty string or handle the error as needed
        }
    };    

    useEffect(() => {
        // getFinalTaskNotAssignList();
        const getCurrentDate = new Date().toISOString().substr(0, 10);
        setCurrDateTaskNotAssign(getCurrentDate);
        setCurrDateTaskInProgress(getCurrentDate);
        setTimeout(() => {
            funGetTaskNotAssignUser(getCurrentDate);
            funGetTaskInprogressUser(getCurrentDate);
        }, 1000);
    }, []);



    return (
        <>
            <section className="main-content pt-3 mt-5">
                <div className="container-fluid">
                    <div className="row mb-1">

                        {/* <div className="col-12 maindashb">
                            <Heading text='User List' />
                        </div> */}
                        <div className="col-md-6 col-sm-12 mt-3 maindashb1 pe-0">
                            <div className='backlogStatus med-table-section_'>
                                <div className="row">
                                    <div className="col-md-5">
                                        <Heading text="Current Date Task Not Assign User List" />
                                    </div>
                                    <div className="col-md-7">
                                        <div className="d-flex gap-1 justify-content-end align-items-center pe-1">
                                            <div className='handlser'>
                                                <div style={{ position: 'relative' }}>
                                                    {/* <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleExportFileTaskNotAssignUser}>Export To CSV</button> */}
                                                    <input id='txtSearchTaskNotAssign' name="searchBoxTaskNotAssignUser" type="search" className="form-control rounded" placeholder="Search...." aria-label="Search" aria-describedby="search-addon" style={{ height: '30px' }} onChange={handleOnChangeTaskNotAssignUser}/>
                                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                                </div>
                                            </div>

                                            <div>
                                                {/* <input type='date' className='form-control' style={{ height: '30px' }} name='taskNotAssign' value={currDateTaskNotAssign} onChange={handleDateChange} /> */}
                                                <input type='date' className='form-control' style={{ height: '30px' }} name='taskNotAssign' value={currDateTaskNotAssign} onChange={handleDateChange} max={new Date().toISOString().split('T')[0]} />

                                            </div>

                                            <div>
                                                <i className="bi bi-filetype-csv pointer fs-4" style={{ color: 'white', background: '#1d4999', borderRadius: '4px', padding: '2px', fontSize: '20px' }} onClick={handleExportFileTaskNotAssignUser}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-12" >
                                    <div style={{ height: '87vh', overflow: 'auto', position:'relative' }}>
                                    <TableContainer>
                                        <thead>
                                            <tr>
                                                <th className="text-center" style={{ "width": "5%" }}>S.No.</th>
                                                <th>Developer</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                               {console.log('finalTaskNotAssignList',finalTaskNotAssignList)}      
                                            {finalTaskNotAssignList.length > 0 ?
                                                finalTaskNotAssignList && finalTaskNotAssignList.map((val, ind) => {
                                                    return (
                                                        <tr key={val.id}>
                                                            <td className="text-center">{ind + 1}</td>
                                                            <td>{val.name}</td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <img className='haveNodataImg' src={noData} alt="No Data" />
                                            }
                                        </tbody>
                                    </TableContainer>
                                    </div>                                    
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12 mt-3 maindashb1">
                            <div className='backlogStatus med-table-section_'>
                                <div className="row">
                                    <div className="col-md-5">
                                        <Heading text="Current Date Task Inprogress user List" />
                                    </div>
                                    <div className="col-md-7">
                                        <div className="d-flex gap-1 justify-content-end align-items-center pe-1">
                                            <div className='handlser'>
                                                <div style={{ position: 'relative' }}>
                                                    <input  name="searchBoxTaskInprogressUser" type="search" className="form-control rounded" placeholder="Search...." aria-label="Search" aria-describedby="search-addon"  style={{ height: '30px' }} onChange={handleOnChangeTaskInprogressUser}/>
                                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                                </div>
                                            </div>

                                            <div>
                                                <input type='date' className='form-control' style={{ height: '30px' }} name='taskInProgress' value={currDateTaskInProgress} onChange={handleDateChange} max={new Date().toISOString().split('T')[0]}/>
                                            </div>

                                            <div>
                                                <i className="bi bi-filetype-csv pointer fs-4" style={{ color: 'white', background: '#1d4999', borderRadius: '4px', padding: '2px', fontSize: '20px' }} onClick={handleExportFileTaskInprogressUser}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row' >
                                <div className="col-12" >
                                <div style={{ height: '87vh', overflow: 'auto', position: 'relative'}}>
                                <TableContainer>
                                        <thead>
                                            <tr>
                                                <th className="text-center" style={{ "width": "5%" }}>S.No.</th>
                                                <th>Developer</th>
                                                <th>Sprint Backlog</th>
                                                <th>Assign Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {finalTaskInProgressList.length > 0 ?
                                                finalTaskInProgressList && finalTaskInProgressList.map((val, ind) => {
                                                    return (
                                                        <tr key={val.id}>
                                                            <td className="text-center">{ind + 1}</td>
                                                            <td>{val.name}</td>
                                                            <td>{val.sprintBacklogText}</td>
                                                            <td>{reformatDateString(val.assignDate)}</td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <img className='haveNodataImg' src={noData} alt="No Data" />
                                            }
                                        </tbody>
                                    </TableContainer>
                                </div>
                               
                                </div>
                                    

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
