import React, { useEffect, useState } from 'react'
import LineInvestigationGrap from '../../../Components/LineInvestigationGrap';
import TimeCalculate from '../../../Code/TimeCalculate';
import GetAllDashboardParameterColumnAssign from '../../../Api/GetAllDashboardParameterColumnAssign';
import { useSelector } from 'react-redux';

export default function PO2R(props) {
    let [po2, setPo2] = useState("");
    let [po2Id, setPo2Id] = useState("");
    let [po2Time, setPo2Time] = useState("");
    let [po2Color, setPo2Color] = useState("");


    let [uhId, setUhId] = useState("")

    // set grap Investigation data
    let [grapInvestigationData, setGrapInvestigationData] = useState({
        showGrap: 0,
        subTestId: '',
        patientName: '',
        UHID: '',
        userId: ''
    })

    let getData = () => {
        setPo2("");
        setPo2Id("");
        setPo2Time("");
        setPo2Color("");

        props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
            if (investigation.SubTestID.toString() === "609") {
                setPo2(Math.round(investigation.SubTestValue * 100) / 100)
                setPo2Color(investigation.SubTestColor)
                if (props.handleColor !== undefined) {
                    props.handleColor(investigation.SubTestColor !== null ? investigation.SubTestColor : "white", props.columnId)
                }
          

                setPo2Id(investigation.SubTestID)
                setUhId(props.patientData.UhId)

                if (investigation.SubTestDateTime !== '') {
                    setPo2Time(TimeCalculate(investigation.SubTestDateTime))


                }

            }
        })
    }

    let modelCloseFun = () => {
        setGrapInvestigationData({
            showGrap: 0,
            subTestId: '',
            patientName: '',
            UHID: '',
            userId: ''
        })
    }

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
           if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

    }

    useEffect(() => {
        getColumnsParameters()

        getData()
    }, [props.patientData])
    return (
        <>
            {/* <div key={props.key} className={`overflow-max investigations ${(po2Color !== "#008000" && po2Color !== "#94e094" && po2Color !== "#F5F8FF" && po2Color !== "" ? "blink" : "")} `} style={{ border: (po2Color !== "#008000" && po2Color !== "#94e094" && po2Color !== "#F5F8FF" && po2Color !== "" ? "2px solid " + po2Color : "") }}> */}
            <div key={props.key} className={`overflow-max investigations `} >

                {po2.length != 0 ? po2 ? <span>

                    {
                        paramenterData && paramenterData.map((val, ind) => {
                            if (val.parameterId === 80) {
                                return (
                                    <>
                                        <span style={{ color: po2Color }}>{po2}</span><br />


                                    </>

                                )
                            }

                            else if (val.parameterId === 81) {
                                return (
                                    <>
                                        {
                                            po2Time.split("-")[1] === "D" && po2Time.split("-")[0] >= 4 ? <span style={{ 'color': 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {po2Time.toLowerCase().replace("-", "")}</span>
                                                : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {po2Time.toLowerCase().replace("-", "")}</span>}
                                        <br />
                                    </>

                                )
                            }

                            else if (val.parameterId === 82 && props.from === undefined) {
                                return (
                                    <>
                                        <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='PO2 Graph' >
                                            {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                                                if (val.SubtestID === po2Id) {
                                                    return (
                                                        <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                                                    )
                                                }
                                            })}
                                            <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [po2Id], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "PO2 Graph", patientName: props.patientData.PntName }) }}></i></div>

                                        {/* <br /> */}
                                    </>

                                )
                            }


                        })

                    }


                    {/* <span>{po2_time}</span> */}
                </span> : 'N/A' : "N/A"}

            </div>
            {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}
        </>
    )
}





export  function PO2RG(props) {
    let [po2, setPo2] = useState("");
    let [po2Id, setPo2Id] = useState("");
    let [po2Time, setPo2Time] = useState("");
    let [po2Color, setPo2Color] = useState("");


    let [uhId, setUhId] = useState("")

    // set grap Investigation data
    let [grapInvestigationData, setGrapInvestigationData] = useState({
        showGrap: 0,
        subTestId: '',
        patientName: '',
        UHID: '',
        userId: ''
    })

    let getData = () => {
        setPo2("");
        setPo2Id("");
        setPo2Time("");
        setPo2Color("");

        props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
            if (investigation.SubTestID.toString() === "609") {
                setPo2(Math.round(investigation.SubTestValue * 100) / 100)
                setPo2Color(investigation.SubTestColor)
                if (props.setColors !== undefined) {
                    props.setColors((prevArray) => ({ ...prevArray, "PO2": investigation.SubTestColor }))
                }

                setPo2Id(investigation.SubTestID)
                setUhId(props.patientData.UhId)

                if (investigation.SubTestDateTime !== '') {
                    setPo2Time(TimeCalculate(investigation.SubTestDateTime))


                }

            }
        })
    }

    let modelCloseFun = () => {
        setGrapInvestigationData({
            showGrap: 0,
            subTestId: '',
            patientName: '',
            UHID: '',
            userId: ''
        })
    }

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
           if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

    }

    useEffect(() => {
        getColumnsParameters()

        getData()
    }, [props.patientData])
    return (
        <>
            {/* <div key={props.key} className={`overflow-max investigations ${(po2Color !== "#008000" && po2Color !== "#94e094" && po2Color !== "#F5F8FF" && po2Color !== "" ? "blink" : "")} `} style={{ border: (po2Color !== "#008000" && po2Color !== "#94e094" && po2Color !== "#F5F8FF" && po2Color !== "" ? "2px solid " + po2Color : "") }}> */}
            <div key={props.key} className={`overflow-max investigations  `}>

                {po2.length != 0 ? po2 ? <span>

                    {
                        paramenterData && paramenterData.map((val, ind) => {
                            

                             if (val.parameterId === 82) {
                                return (
                                    <>
                                        <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='PO2 Graph' >
                                            {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                                                if (val.SubtestID === po2Id) {
                                                    return (
                                                        <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                                                    )
                                                }
                                            })}
                                            <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [po2Id], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "PO2 Graph", patientName: props.patientData.PntName }) }}></i></div>

                                        {/* <br /> */}
                                    </>

                                )
                            }


                        })

                    }


                    {/* <span>{po2_time}</span> */}
                </span> : 'N/A' : "N/A"}

            </div>
            {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}
        </>
    )
}
