import React, { useEffect, useState } from 'react'
import TimeCalculate from '../../../Code/TimeCalculate';
import LineInvestigationGrap from '../../../Components/LineInvestigationGrap';
import GetAllDashboardParameterColumnAssign from '../../../Api/GetAllDashboardParameterColumnAssign';
import Highcharts from 'highcharts';
import { useSelector } from 'react-redux';

export default function NaPlusR(props) {
  let [naplus, setNaplus] = useState("");
  let [naplusId, setNaplusId] = useState("");
  let [naplusTime, setNaplusTime] = useState("");
  let [naplusColor, setNaplusColor] = useState("");


  let [naplusSerum, setNaplusSerum] = useState("");
  let [naplusSerumId, setNaplusSerumId] = useState("");
  let [naplusSerumTime, setNaplusSerumTime] = useState("");
  let [naplusSerumColor, setNaplusSerumColor] = useState("");

  let [uhId, setUhId] = useState("")

  // set grap Investigation data
  let [grapInvestigationData, setGrapInvestigationData] = useState({
    showGrap: 0,
    subTestId: '',
    patientName: '',
    UHID: '',
    userId: ''
  })

  let getData = () => {
    setNaplus("");
    setNaplusId("");
    setNaplusTime("");
    setNaplusColor("");

    setNaplusSerum("");
    setNaplusSerumId("");
    setNaplusSerumTime("");
    setNaplusSerumColor("");
    props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
      if (investigation.SubTestID.toString() === "610") {
        setNaplus(Math.round(investigation.SubTestValue * 100) / 100)
        setNaplusColor(investigation.SubTestColor)
        if (props.handleColor !== undefined) {
          props.handleColor(investigation.SubTestColor !== null ? investigation.SubTestColor : "white", props.columnId)
        }

        setNaplusId(investigation.SubTestID)
        setUhId(props.patientData.UhId)
        if (investigation.SubTestDateTime !== '') {
          setNaplusTime(TimeCalculate(investigation.SubTestDateTime))
        }

      }

      if (investigation.SubTestID.toString() === "498") {
        setNaplusSerum(Math.round(investigation.SubTestValue * 100) / 100)
        setNaplusSerumColor(investigation.SubTestColor)
        setNaplusSerumId(investigation.SubTestID)
        setUhId(props.patientData.UhId)
      }
    })

    // if (props.patientData.PatientNutritionAchievementList !== null && props.patientData.PatientNutritionAchievementList !== undefined) {
    //   props.patientData.PatientNutritionAchievementList.map((val, ind) => {
    //     if (val.NutrientID === 336) {

    //        // chartdata = [slice2,slice4]
    //        let chartdata = []


    //        if (document.getElementById("SodiumId")) {

    //          let slice1 = {
    //            name: 'Food ' + val.FoodPercentage.toFixed(2)+ '%',
    //            y: val.FoodPercentage,
    //            // y: 20.00,
    //            sliced: false,
    //            color: val.FoodPercentageColor

    //          }


    //          let slice2 = {
    //            name: 'Medicine ' + val.MedicinePercentage.toFixed(2) + '%',
    //            y: val.MedicinePercentage,
    //            // y: 50.00,
    //            sliced: false,
    //            color: val.MedicinePercentageColor
    //          }


    //          let slice3 = {
    //            name: 'Fluid ' + val.FluidPercentage.toFixed(2) + '%',
    //            y: val.FluidPercentage,
    //            // y: 20.00,
    //            sliced: false,
    //            color: val.FluidPercentageColor
    //          }



    //          let slice4 = {
    //            name: 'Remaining ' + val.RemainingPercentage.toFixed(2) + '%',
    //            y: val.RemainingPercentage,
    //            // y: 100.00,
    //            color: "white",
    //            sliced: false,
    //          }
    //          chartdata = [slice1, slice2, slice3, slice4]
    //          setTimeout(() => {
    //            Highcharts.chart('SodiumId',
    //              {
    //                chart: {
    //                  type: 'pie',
    //                  plotBackgroundColor: null,
    //                  plotBorderWidth: null,
    //                  plotShadow: false,
    //                  width: 65,
    //                  height: 65

    //                },
    //                legend: {
    //                  backgroundColor: '#FFFFFF',
    //                }
    //                ,
    //                title: {
    //                  text: null,
    //                  align: null
    //                },
    //                tooltip: {
    //                  pointFormat: null,
    //                  enabled: true // Enable tooltips
    //                },
    //                // accessibility: {
    //                //   point: {
    //                //     valueSuffix: '%'
    //                //   }
    //                // },
    //                plotOptions: {
    //                  pie: {
    //                    allowPointSelect: true,
    //                    cursor: 'pointer',
    //                    dataLabels: {
    //                      enabled: false
    //                    },
    //                    showInLegend: false,
    //                    borderRadius: 0
    //                  }
    //                },
    //                credits: {
    //                  enabled: false,
    //                },
    //                series: [{
    //                  enableMouseTracking: true,

    //                  data: [...chartdata]
    //                },

    //                ]

    //              }
    //            )
    //          }, 100)
    //        }

    //     }

    //     console.log("dscdsjbcmsdnbcsdbc")
    //   })
    // }
  }



  let createPieChart = () => {
    if (props.patientData.PatientNutritionAchievementList !== null && props.patientData.PatientNutritionAchievementList !== undefined) {
      props.patientData.PatientNutritionAchievementList.map((val, ind) => {

        if (val.NutrientID === 336) {

          // chartdata = [slice2,slice4]

          let chartdata = []
          let slice1 = {
            name: 'Food ' + val.FoodPercentage.toFixed(2) + '%',
            y: val.FoodPercentage,
            // y: 20.00,
            sliced: false,
            color: val.FoodPercentageColor

          }


          let slice2 = {
            name: 'Medicine ' + val.MedicinePercentage.toFixed(2) + '%',
            y: val.MedicinePercentage,
            // y: 50.00,
            sliced: false,
            color: val.MedicinePercentageColor
          }


          let slice3 = {
            name: 'Fluid ' + val.FluidPercentage.toFixed(2) + '%',
            y: val.FluidPercentage,
            // y: 20.00,
            sliced: false,
            color: val.FluidPercentageColor
          }



          let slice4 = {
            name: 'Remaining ' + val.RemainingPercentage.toFixed(2) + '%',
            y: val.RemainingPercentage,
            // y: 100.00,
            color: "white",
            sliced: false,
          }
          chartdata = [slice1, slice2, slice3, slice4]
          if (document.getElementById("SodiumId")) {


            setTimeout(() => {
              Highcharts.chart('SodiumId',
                {
                  chart: {
                    type: 'pie',
                    backgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    width: 65,
                    height: 65

                  },
                  legend: {
                    backgroundColor: '#FFFFFF',
                  }
                  ,
                  title: {
                    text: null,
                    align: null
                  },
                  tooltip: {
                    pointFormat: null,
                    enabled: true // Enable tooltips
                  },
                  // accessibility: {
                  //   point: {
                  //     valueSuffix: '%'
                  //   }
                  // },
                  plotOptions: {
                    pie: {
                      allowPointSelect: true,
                      cursor: 'pointer',
                      dataLabels: {
                        enabled: false
                      },
                      showInLegend: false,
                      borderRadius: 0,
                      borderWidth: 0
                    }
                  },
                  credits: {
                    enabled: false,
                  },
                  series: [{
                    enableMouseTracking: true,

                    data: [...chartdata]
                  },

                  ]

                }
              )
            }, 100)
          }



        }
      })
    }
  }

  let modelCloseFun = () => {
    setGrapInvestigationData({
      showGrap: 0,
      subTestId: '',
      patientName: '',
      UHID: '',
      userId: ''
    })
  }

  let [paramenterData, setPatameterData] = useState([])
  let response = useSelector((state) => state.AllColumnsParamters)
  let getColumnsParameters = () => {
    let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
    if (resp.length !== 0) {
      setPatameterData(resp[0].data)
    }

  }

  useEffect(() => {
    getColumnsParameters()
    createPieChart()
    getData()
  }, [props.patientData])
  return (
    <>
      {/* <div className={`overflow-max investigations ${(naplusColor !== "#008000" && naplusColor !== "#94e094" && naplusColor !== "#F5F8FF" && naplusColor !== "" ? "blink" : "")} `} style={{ border: (naplusColor !== "#008000" && naplusColor !== "#94e094" && naplusColor !== "#F5F8FF" && naplusColor !== "" ? "2px solid " + naplusColor : "") }}> */}
      <div key={props.key} className={`overflow-max investigations`}>

        {naplus.length != 0 ?
          naplus ?
            <span>
              {
                paramenterData && paramenterData.map((val, ind) => {
                  if (val.parameterId === 65) {
                    return (
                      <>
                        <span style={{ color: naplusColor }}>{naplus}</span> {naplusSerum ? <span style={{ color: naplusSerumColor }}>({naplusSerum})</span> : ""}
                        <br />


                      </>

                    )
                  }

                  else if (val.parameterId === 66) {
                    return (
                      <>
                        {
                          naplusTime.split("-")[1] === "D" && naplusTime.split("-")[0] >= 4 ? <span style={{ 'color': 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {naplusTime.toLowerCase().replace("-", "")}</span>
                            : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {naplusTime.toLowerCase().replace("-", "")}</span>}
                        <br />
                      </>

                    )
                  }

                  // else if (val.parameterId === 67) {
                  //   return (
                  //     <>
                  //       <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='Sodium (Na+) Graph' >
                  //         {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                  //           if (val.SubtestID === parseInt(naplusId)) {
                  //             return (
                  //               <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                  //             )
                  //           }
                  //         })}
                  //         <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [naplusId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "Sodium (Na+) Graph", patientName: props.patientData.PntName }) }}></i></div>

                  //       {/* <br /> */}
                  //     </>

                  //   )
                  // }


                })

              }
              <div className="d-flex flex-row gap-2 justify-content-center align-items-center pt-1" title='Sodium (Na+) Graph' >
                {

                  paramenterData && paramenterData.map((val, ind) => {

                    if (val.parameterId === 67) {
                      return (
                        <>
                          <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [naplusId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "Sodium (Na+) Graph", patientName: props.patientData.PntName }) }}></i>


                          {/* <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='ALB Graph' > */}
                          {/* {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                                                if (val.SubtestID === albId) {
                                                    return (
                                                        <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                                                    )
                                                }
                                            })} */}


                        </>

                      )
                    }
                    else if (val.parameterId === 114) {

                      return (

                        <div id="naplusId" style={{ width: "30x", height: "30px" }}>
                        </div>
                      )
                    }
                  })

                }
              </div>


            </span> : 'N/A' : 'N/A'}
        <div className="d-flex flex-row gap-2 justify-content-center align-items-center pt-1" title='Sodium (Na+) Graph' >
          {

            paramenterData && paramenterData.map((val, ind) => {
              if (val.parameterId === 114) {
                return (
                  <div id="SodiumId" className='piechartcontainer'></div>
                  
                )
              }
            })

          }
        </div>

      </div>
      {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}

    </>
  )
}



export function NaPlusRG(props) {
  let [naplus, setNaplus] = useState("");
  let [naplusId, setNaplusId] = useState("");
  let [naplusTime, setNaplusTime] = useState("");
  let [naplusColor, setNaplusColor] = useState("");


  let [naplusSerum, setNaplusSerum] = useState("");
  let [naplusSerumId, setNaplusSerumId] = useState("");
  let [naplusSerumTime, setNaplusSerumTime] = useState("");
  let [naplusSerumColor, setNaplusSerumColor] = useState("");

  let [uhId, setUhId] = useState("")

  // set grap Investigation data
  let [grapInvestigationData, setGrapInvestigationData] = useState({
    showGrap: 0,
    subTestId: '',
    patientName: '',
    UHID: '',
    userId: ''
  })

  let getData = () => {
    setNaplus("");
    setNaplusId("");
    setNaplusTime("");
    setNaplusColor("");

    setNaplusSerum("");
    setNaplusSerumId("");
    setNaplusSerumTime("");
    setNaplusSerumColor("");
    props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
      if (investigation.SubTestID.toString() === "610") {
        setNaplus(Math.round(investigation.SubTestValue * 100) / 100)
        setNaplusColor(investigation.SubTestColor)
        if (props.setColors !== undefined) {
          props.setColors((prevArray) => ({ ...prevArray, "Na+": investigation.SubTestColor }))
        }

        setNaplusId(investigation.SubTestID)
        setUhId(props.patientData.UhId)
        if (investigation.SubTestDateTime !== '') {
          setNaplusTime(TimeCalculate(investigation.SubTestDateTime))
        }
        // let chartdata = []
        // if (props.patientData.PatientNutritionAchievementList !== null && props.patientData.PatientNutritionAchievementList !== undefined) {
        //   props.patientData.PatientNutritionAchievementList.map((val, ind) => {

        //     if (val.NutrientID === 336) {
        //       if (val.FoodPercentage !== 0) {
        //         let slice1 = {
        //           name: '',
        //           y: val.FoodPercentage,
        //           sliced: false,
        //           color: val.FoodPercentageColor
        //         }
        //         chartdata.push(slice1)
        //       }
        //       if (val.MedicinePercentage !== 0) {
        //         let slice2 = {
        //           name: '',
        //           y: val.MedicinePercentage,
        //           sliced: false,
        //           color: val.MedicinePercentageColor
        //         }
        //         chartdata.push(slice2)

        //       }
        //       if (val.FluidPercentage !== 0) {
        //         let slice3 = {
        //           name: '',
        //           y: val.FluidPercentage,
        //           sliced: false,
        //           color: val.FluidPercentageColor
        //         }
        //         chartdata.push(slice3)

        //       }
        //       if (val.RemainingPercentage !== 0) {
        //         let slice4 = {
        //           name: '',
        //           y: val.RemainingPercentage,
        //           color: "white",
        //           sliced: false,
        //         }
        //         chartdata.push(slice4)

        //       }


        //       // chartdata = [slice1, slice2, slice3, slice4]
        //       if (document.getElementById("naplusId")) {
        //         setTimeout(() => {
        //           Highcharts.chart('naplusId',
        //             {
        //               chart: {

        //                 plotShadow: false,
        //                 spacing: [0, 0, 0, 0],
        //                 margin: 0,
        //                 type: 'pie',
        //                 backgroundColor: 'transparent',
        //                 width: 35,
        //                 height: 35,

        //               },
        //               legend: {
        //                 backgroundColor: '#FFFFFF',
        //               }
        //               ,
        //               title: {
        //                 text: null,
        //                 align: null
        //               },
        //               tooltip: {
        //                 pointFormat: null
        //               },
        //               accessibility: {
        //                 point: {
        //                   valueSuffix: '%'
        //                 }
        //               },
        //               plotOptions: {
        //                 pie: {
        //                   size: '100%',
        //                   allowPointSelect: true,
        //                   cursor: 'pointer',
        //                   dataLabels: {
        //                     enabled: false,
        //                   },
        //                   showInLegend: false
        //                   // colors: ['#f00', '#0f0', '#00f'],


        //                 }
        //               },
        //               credits: {
        //                 enabled: false,
        //               },
        //               series: [{
        //                 enableMouseTracking: false,

        //                 data: [...chartdata]
        //               },

        //               ]

        //             }
        //           )
        //         }, 100)
        //       }

        //     }
        //   })
        // }

      }
      if (investigation.SubTestID.toString() === "498") {
        setNaplusSerum(Math.round(investigation.SubTestValue * 100) / 100)
        setNaplusSerumColor(investigation.SubTestColor)
        setNaplusSerumId(investigation.SubTestID)
        setUhId(props.patientData.UhId)
      }
    })
  }

  let modelCloseFun = () => {
    setGrapInvestigationData({
      showGrap: 0,
      subTestId: '',
      patientName: '',
      UHID: '',
      userId: ''
    })
  }

  let [paramenterData, setPatameterData] = useState([])
  let response = useSelector((state) => state.AllColumnsParamters)
  let getColumnsParameters = () => {
    let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
    if (resp.length !== 0) {
      setPatameterData(resp[0].data)
    }

  }

  useEffect(() => {
    getColumnsParameters()

    getData()
  }, [props.patientData])
  return (
    <>
      {/* <div className={`overflow-max investigations ${(naplusColor !== "#008000" && naplusColor !== "#94e094" && naplusColor !== "#F5F8FF" && naplusColor !== "" ? "blink" : "")} `} style={{ border: (naplusColor !== "#008000" && naplusColor !== "#94e094" && naplusColor !== "#F5F8FF" && naplusColor !== "" ? "2px solid " + naplusColor : "") }}> */}
      <div key={props.key} className={`overflow-max_ investigations`}>

        {naplus.length != 0 ?
          naplus ?
            <span>

              {

                paramenterData && paramenterData.map((val, ind) => {
                  if (val.parameterId === 67) {
                    return (
                      <>
                        <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [naplusId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "Sodium (Na+) Graph", patientName: props.patientData.PntName }) }}></i>


                        {/* <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='ALB Graph' > */}
                        {/* {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                                                if (val.SubtestID === albId) {
                                                    return (
                                                        <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                                                    )
                                                }
                                            })} */}


                      </>

                    )
                  }

                })

              }


            </span> : 'N/A' : 'N/A'}
      </div>
      {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}

    </>
  )
}
