import React, { useEffect } from 'react'
import userDetailsGif from "../assests/images/patientNameGif.gif"

export default function PatientDetails({Patientdata}) {
    return (
        <>
            <div className='text-userDeatils-container'>
            <div className='userDetailsText'>
              <span >
                <label htmlFor="">{Patientdata.pntName}</label>&nbsp;&nbsp;
                <label htmlFor="" style={{ color: "#F9C200" }}>{Patientdata.uhId}</label>
              </span><br />
              <span>
                <label>{Patientdata.pntGender} / {Patientdata.pntAge}</label>
              </span>
            </div>
            <img src={userDetailsGif} className='userDeatils-gif' alt='textgif' />

          </div>
        </>
    )
}
