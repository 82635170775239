
async function PostAssignDashboardHeaderParameter(dashboardParameterId,clientID,userID,assignUserId,data) {
    let token = window.SuperAdminToken;
    let url = window.SendXMLDatabaseUl + `/api/Dashboardheaderparameters/InsertAssignDashboardHeaderParameter?dashboardParameterId=${dashboardParameterId}&clientId=${clientID}&userId=${userID}&assignUserId=${assignUserId}`;
    let head = {
      'Content-Type': 'application/JSON',
      accept: '*/*',
    }
    let response =
      await fetch(url, {
        method: 'POST',
        headers: head,
        body: JSON.stringify(data)
      })
        .then((res) => res.json())
        .then(data)
      
  
    return response;
  }
  export default PostAssignDashboardHeaderParameter;