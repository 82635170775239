import React, { useEffect, useState } from 'react';
import '../../assets/css/MultiSelectDropdown.css'
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import clearIcon from '../../assets/images/icons/clear.svg';
import deleteBtnIcon from '../../assets/images/icons/delete.svg';
import editBtnIcon from '../../assets/images/icons/edit.svg';
import GetDoctorScheduleList from './Api/GetDoctorScheduleList';
import DropdownWithSearch from '../../Component/DropdownWithSearch';
import GetDesignationList from '../Master/Api/ShiftDesignationMapping/GetDesignationList';
import GetShiftList from '../Master/Api/ShiftMaster/GetShiftList';
import GetWardList from './Api/GetWardList';
import Heading from '../../Component/Heading';
import BoxContainer from '../../Component/BoxContainer';
import Toster from '../../Component/Toster';
import Loader from '../../Component/Loader';
import SuccessToster from '../../Component/SuccessToster';
import TosterUnderProcess from '../../Component/TosterUnderProcess';
import AlertToster from '../../Component/AlertToster';
import GetUserListByDesignation from './Api/GetUserListByDesignation';
import TableContainer from '../../Component/TableContainer';
import GetDayList from './Api/GetDayList';
import GetLocationList from './Api/GetLocationList';
import SaveDoctorSchedule from './Api/SaveDoctorSchedule';
import UpdateDoctorSchedule from './Api/UpdateDoctorSchedule';
import DeleteDoctorSchedule from './Api/DeleteDoctorSchedule';
import Search from '../../Code/Serach';
import { t } from 'i18next';
export default function DoctorSchedule() {
    const [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
    const [clientID, setClientID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientId);
    const [showUnderProcess, setShowUnderProcess] = useState(0);
    const [showToster, setShowToster] = useState(0);
    const [tosterMessage, setTosterMessage] = useState("");
    const [tosterValue, setTosterValue] = useState(0);
    const [showLoder, setShowLoder] = useState(0);
    const [isShowToaster, setisShowToaster] = useState(0);
    const [showAlertToster, setShowAlertToster] = useState(0);
    const [showErrMessage, setShowErrMessage] = useState('');
    const [showSuccessMsg, setShowSuccessMsg] = useState('');
    const [clearDropdown, setClearDropdown] = useState(0);
    const [editShiftDropdown, setEditShiftDropdown] = useState('');
    const [editDesignationDropdown, setEditDesignationDropdown] = useState('');
    const [editWardDropdown, setEditWardDropdown] = useState('');
    const [editUserDropdown, setEditUserDropdown] = useState('');
    const [editLocationDropdown, setEditLocationDropdown] = useState('');
    const [updateBool, setUpdateBool] = useState(0);
    const [scheduleList, setScheduleList] = useState([]);
    const [scheduleListMain, setScheduleListMain] = useState([]);
    const [shiftList, setShiftList] = useState([]);
    const [dayList, setDayList] = useState([]);
    const [designationList, setDesignationList] = useState([]);
    const [wardList, setWardList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [selectedDay, setSelectedDay] = useState([]);
    const [rowId, setRowId] = useState('');
    // Used In Multi Select Dropdown
    const [dataContainer, setDataContainer] = useState({
        scheduleType: '',
        assignedUser: '',
        designation: '',
        fromDate: '',
        toDate: '',
        shift: '',
        ward: '',
        location: ''

    })
    // Functions Start Here
    const handleChange = (e) => {
        const { name, value } = e.target;
        clearEditData();
        clearValidationErrMessage();
        setDataContainer((prevData) => ({ ...prevData, [name]: value }));
        if (name === "designation") {
            getUserListByDesignation(value);
        }
    }
    const checkValidation = () => {
        let isValid = true;
        if (dataContainer.designation === '' || dataContainer.designation === null || dataContainer.designation === undefined) {
            document.getElementById('errDesignation').style.display = "block";
            document.getElementById('errDesignation').innerHTML = "Designation is Required";
            isValid = false
        }
        else if (dataContainer.assignedUser === '' || dataContainer.assignedUser === null || dataContainer.assignedUser === undefined) {
            document.getElementById('errUser').style.display = "block";
            document.getElementById('errUser').innerHTML = "User is Required";
            isValid = false
        }
        else if (dataContainer.shift === '' || dataContainer.shift === null || dataContainer.shift === undefined) {
            document.getElementById('errShift').style.display = "block";
            document.getElementById('errShift').innerHTML = "Shift is Required";
            isValid = false
        }
        else if (dataContainer.fromDate === '' || dataContainer.fromDate === null || dataContainer.fromDate === undefined) {
            document.getElementById('errFromDate').style.display = "block";
            document.getElementById('errFromDate').innerHTML = "From Date is Required";
        }
        else if (dataContainer.toDate === '' || dataContainer.toDate === null || dataContainer.toDate === undefined) {
            document.getElementById('errToDate').style.display = "block";
            document.getElementById('errToDate').innerHTML = "To Date is Required";
            isValid = false
        }
        else if (selectedDay.length === 0 ||  selectedDay === undefined) {
            document.getElementById('errDay').style.display = "block";
            document.getElementById('errDay').innerHTML = "Day is Required";
            isValid = false
        }
        else if (dataContainer.ward === '' || dataContainer.ward === null || dataContainer.ward === undefined) {
            document.getElementById('errWard').style.display = "block";
            document.getElementById('errWard').innerHTML = "Ward is Required";
            isValid = false
        }
        return isValid;

    }
    const getShiftList = async () => {
        const response = await GetShiftList();
        if (response.status === 1) {
            setShiftList(response.responseValue);
        }
        else {
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }
    const getDesignationList = async () => {
        const response = await GetDesignationList();
        if (response.status === 1) {
            setDesignationList(response.responseValue);
        }
        else {
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }
    const getWardList = async () => {
        const response = await GetWardList();
        if (response.status === 1) {
            setWardList(response.responseValue);
        }
        else {
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }
    const getDayList = async () => {
        const response = await GetDayList();
        if (response.status === 1) {
            setDayList(response.responseValue);
        }
        else {
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }
    const getLocationList = async () => {
        const response = await GetLocationList();
        console.log('response location', response)
        if (response.status === 1) {
            setLocationList(response.responseValue);
        }
        else {
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }
    const getUserListByDesignation = async (param) => {
        console.log('desigID', param);
        setShowLoder(1);
        const response = await GetUserListByDesignation(param, clientID);
        console.log('user response', response)
        if (response.status === 1) {
            setShowLoder(0);
            setUserList(response.responseValue);
        }
        else {
            setShowLoder(0);
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1200)
        }
    }
    const getScheduleList = async () => {
        setShowLoder(1)
        const response = await GetDoctorScheduleList(clientID);
        console.log('response ScheduleList', response)
        if (response.status === 1) {
            setShowLoder(0);
            setScheduleList(response.responseValue);
            setScheduleListMain(response.responseValue);
        }
        else {
            setShowLoder(0);
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }
    //Handle Search
    // let handleSearch = (e) => {
    //     let resp = Search(scheduleListMain, e.target.value)
    //     if (e.target.value !== "") {
    //         if (resp.length !== 0) {
    //             setScheduleList(resp)
    //         }
    //         else {
    //             setScheduleList([])
    //         }
    //     }
    //     else {
    //         setScheduleList(scheduleListMain)
    //     }
    // }
    // Update the handleSearch function
    let handleSearch = (e) => {
        let resp = Search(scheduleListMain, e.target.value);
        if (e.target.value !== "") {
            if (resp.length !== 0) {
                const filteredList = resp.filter(schedule => {
                    const locationDetails = schedule.locationDetails;
                    const locationString = `${locationDetails.roomNumber} ${locationDetails.floorName} ${locationDetails.buildingName}`;
                    return locationString.toLowerCase().includes(e.target.value.toLowerCase());
                });
                setScheduleList(filteredList);
            } else {
                setScheduleList([]);
            }
        } else {
            setScheduleList(scheduleListMain);
        }
    };

    const handlerSave = async () => {
        if (checkValidation()) {
            var obj =
            {
                "id": 0,
                "scheduleType": dataContainer.scheduleType,
                "assignedUserId": dataContainer.assignedUser,
                "designationId": dataContainer.designation,
                "dayId": selectedDay,
                "fromDate": dataContainer.fromDate,
                "toDate": dataContainer.toDate,
                "shiftId": dataContainer.shift,
                "wardId": dataContainer.ward,
                "locationId": dataContainer.location === "" || dataContainer.location === null || dataContainer.location === undefined ? 0 : dataContainer.location,
                "clientId": clientID,
                "userId": userID
            }
            setShowUnderProcess(1);
            const response = await SaveDoctorSchedule(obj);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Saved Successfully..");
                setTimeout(() => {
                    setShowToster(0);
                    handleClear(1);
                    getScheduleList();
                }, 1500)
            }
            else {
                setShowUnderProcess(0);
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTimeout(() => {
                    setShowToster(0);
                }, 1500)
            }
        }
    }
    const handlerUpdate = async () => {
        if (checkValidation()) {
            if (selectedDay.length > 1) {
                document.getElementById('errDay').style.display = 'block';
                document.getElementById('errDay').innerHTML = 'Can Not Update Multiple Day...';
            }
            else {
                var obj =
                {
                    "id": rowId,
                    "scheduleType": dataContainer.scheduleType,
                    "assignedUserId": dataContainer.assignedUser,
                    "designationId": dataContainer.designation,
                    "dayId": selectedDay[0],
                    "fromDate": dataContainer.fromDate,
                    "toDate": dataContainer.toDate,
                    "shiftId": dataContainer.shift,
                    "wardId": dataContainer.ward,
                    "locationId": dataContainer.location,
                    "clientId": clientID,
                    "userId": userID
                }
                setShowUnderProcess(1);
                const response = await UpdateDoctorSchedule(obj);
                if (response.status === 1) {
                    setShowUnderProcess(0);
                    setTosterValue(0);
                    setShowToster(1);
                    setTosterMessage("Updated Successfully..");
                    setTimeout(() => {
                        setShowToster(0);
                        handleClear(1);
                        getScheduleList();
                    }, 1500)
                }
                else {
                    setShowUnderProcess(0);
                    setTosterValue(1);
                    setShowToster(1);
                    setTosterMessage(response.responseValue);
                    setTimeout(() => {
                        setShowToster(0);
                    }, 1500)
                }
            }
        }
    }
    const handleDelete = async () => {
        const response = await DeleteDoctorSchedule(rowId);
        if (response.status === 1) {
            setShowUnderProcess(0);
            setTosterValue(0);
            setShowToster(1);
            setTosterMessage("Deleted Successfully..");
            setTimeout(() => {
                setShowToster(0);
                getScheduleList();
            }, 1500)
        }
        else {
            setShowUnderProcess(0);
            setTosterValue(1);
            setShowToster(1);
            setTosterMessage(response.responseValue);
            setTimeout(() => {
                setShowToster(0);
            }, 1500)
        }
    }
    const handleEdit = (params) => {
        var tempArr = [];
        setUpdateBool(1);
        setRowId(params.id);
        setEditShiftDropdown(params.shiftName);
        setEditDesignationDropdown(params.designationName);
        setEditWardDropdown(params.wardName);
        setEditUserDropdown(params.name);
        setEditLocationDropdown(params.locationDetails.roomNumber + ' ' + params.locationDetails.floorName + ' ' + params.locationDetails.buildingName);
        dayList.forEach((day) => { document.getElementById('ms-dropdown-value' + day.id).checked = false; });
        tempArr.push(params.dayId);
        setSelectedDay(tempArr);
        document.getElementById('ms-dropdown-value' + params.dayId).checked = true;
        setDataContainer({
            scheduleType: params.scheduleType,
            assignedUser: params.assignedUserId,
            designation: params.designationId,
            fromDate: splitData(params.fromDate, 'T', 0),
            toDate: splitData(params.toDate, 'T', 0),
            shift: params.shiftId,
            ward: params.wardId,
            location: params.locationDetails.locationId

        })
    }
    const handleClear = (value) => {
        clearValidationErrMessage();
        clearEditData();
        setClearDropdown(value);
        setUpdateBool(0);
        setRowId('');
        setSelectedDay([]);
        setDataContainer({
            scheduleType: '',
            assignedUser: '',
            designation: '',
            fromDate: '',
            toDate: '',
            shift: '',
            ward: '',
            location: ''
        });
        dayList.forEach((day) => {
            document.getElementById('ms-dropdown-value' + day.id).checked = false;
        })
    }
    const clearValidationErrMessage = () => {
        document.getElementById('errDesignation').style.display = "none";
        document.getElementById('errUser').style.display = "none";
        document.getElementById('errShift').style.display = "none";
        document.getElementById('errFromDate').style.display = "none";
        document.getElementById('errToDate').style.display = "none";
        document.getElementById('errDay').style.display = 'none';
        document.getElementById('errWard').style.display = 'none';

    }
    const clearEditData = () => {
        setEditShiftDropdown('');
        setEditDesignationDropdown('');
        setEditWardDropdown('');
        setEditUserDropdown('');
        setEditLocationDropdown('');
    }
    const handleSelectAllMsDrpDwn = () => {
        clearValidationErrMessage();
        let tempArr = [];
        setSelectedDay([]);
        const getDataList = dayList;
        const getSelectAllValue = document.getElementById('ms-dropdown-selectall').checked;
        getDataList.forEach((data, i) => {
            if (getSelectAllValue === true) {
                document.getElementById('ms-dropdown-value' + data.id).checked = true;
                tempArr.push(
                    data.id
                )
            }
            else {
                tempArr.push()
                document.getElementById('ms-dropdown-value' + data.id).checked = false;
            }
        });
        setSelectedDay(tempArr);
    }
    const handleSelectMsDrpDwn = (param) => {
        clearValidationErrMessage();
        let temArr = selectedDay;
        if (temArr.length === 0) {
            temArr.push(
                param
            )
        }
        else {
            var index = temArr.findIndex((arr) => arr === param);
            if (index != -1) {
                temArr.splice(index, 1);
                document.getElementById('ms-dropdown-selectall').checked = false;
            }
            else {
                temArr.push(param);
            }
        }
        setSelectedDay(temArr);
    }
    const splitData = (value, split, index) => {
        if (value !== '' || value !== null || value !== undefined) {
            return value.split(split)[index]
        }
    }
    useEffect(() => {
        getWardList();
        getShiftList();
        getDesignationList();
        getDayList();
        getLocationList();
        getScheduleList();
    }, [])
    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <Heading text="Doctor Schedule" />
                            <BoxContainer>
                                <div className="col-2 mb-2 me-2">
                                    <label htmlFor="scheduleType" className="form-label">Schedule Type</label>
                                    <select className="form-select form-select-sm" name="scheduleType" id="scheduleType" value={dataContainer.scheduleType} onChange={handleChange} >
                                        <option value=''>Select Schedule Type</option>
                                        <option value='1'>OPD</option>
                                        <option value='2'>IPD</option>
                                    </select>
                                    {/* <small id="errScheduleType" className="invalid-feedback" style={{ display: 'none' }}></small> */}
                                </div>
                                <div className="col-2 mb-2 me-2">
                                    <label htmlFor="designation" className="form-label">Designation<span className="starMandatory">*</span></label>

                                    {designationList &&
                                        <DropdownWithSearch defaulNname="Select Designation" name="designation" list={designationList} valueName="id" displayName="designationName" editdata={editDesignationDropdown}
                                            getvalue={handleChange}
                                            clear={clearDropdown}
                                            clearFun={handleClear}
                                        />
                                    }
                                    <small id="errDesignation" className="invalid-feedback" style={{ display: 'none' }}></small>

                                </div>
                                <div className="col-2 mb-2 me-2">
                                    <label htmlFor="designation" className="form-label">User List<span className="starMandatory">*</span></label>
                                    {userList &&
                                        <DropdownWithSearch defaulNname="Select User" name="assignedUser" list={userList} valueName="id" displayName="name" editdata={editUserDropdown}
                                            getvalue={handleChange}
                                            clear={clearDropdown}
                                            clearFun={handleClear}
                                        />
                                    }
                                    <small id="errUser" className="invalid-feedback" style={{ display: 'none' }}></small>
                                </div>
                                <div className="col-2 mb-2 me-2">
                                    <label htmlFor="shift" className="form-label">Shift<span className="starMandatory">*</span></label>
                                    {shiftList &&
                                        <DropdownWithSearch
                                            defaulNname="Select Shift"
                                            name="shift"
                                            list={shiftList.map(val => ({
                                                id: val.id,
                                                displayName: `${val.shiftName}  (${val.shiftTimeFrom} - ${val.shiftTimeTo})`,
                                            }))}
                                            valueName="id"
                                            displayName="displayName"
                                            editdata={editShiftDropdown}
                                            getvalue={handleChange}
                                            clear={clearDropdown}
                                            clearFun={handleClear}
                                        />
                                    }
                                    <small id="errShift" className="invalid-feedback" style={{ display: 'none' }}></small>
                                </div>
                                <div className="col-2 mb-2 me-2">
                                    <label htmlFor="fromDate" className="form-label">From Date<span className="starMandatory">*</span></label>
                                    <input type="date" className="form-control form-control-sm" name="fromDate" id="fromDate" value={dataContainer.fromDate} onChange={handleChange} />
                                    <small id="errFromDate" className="invalid-feedback" style={{ display: 'none' }}></small>
                                </div>
                                <div className="col-2 mb-2 me-2">
                                    <label htmlFor="toDate" className="form-label">To Date<span className="starMandatory">*</span></label>
                                    <input type="date" className="form-control form-control-sm" name="toDate" id="toDate" value={dataContainer.toDate} onChange={handleChange} />
                                    <small id="errToDate" className="invalid-feedback" style={{ display: 'none' }}></small>
                                </div>
                                <div className="col-2 mb-2 me-2">
                                    <label htmlFor="toDate" className="form-label">Day<span className="starMandatory">*</span></label>
                                    {/* <input type="date" className="form-control form-control-sm" name="toDate" id="toDate" value={dataContainer.toDate} onChange={handleChange} /> */}
                                    <div>
                                        <button className="dropbtn ps-2 pe-2" onClick={() => { document.getElementById("ms-dd-content").style.display = document.getElementById("ms-dd-content").style.display === "none" ? 'block' : 'none' }}>
                                            <span className='ms-dropdown-label'>Select Day</span>
                                            <i className="bi bi-chevron-down"></i>
                                        </button>
                                        <div id="ms-dd-content" className='ms-dropdown-content'>
                                            <ul className='ms-dropdown-list-container' >
                                                <li><input type='checkbox' id='ms-dropdown-selectall' className='ms-dropdown-checkbox ms-dropdown-selectall' onClick={handleSelectAllMsDrpDwn} />Select All</li>
                                                {dayList && dayList.map((day, index) => {
                                                    return (
                                                        <li key={day.id}><input type='checkbox' id={'ms-dropdown-value' + day.id} className='ms-dropdown-checkbox' onClick={() => handleSelectMsDrpDwn(day.id)} />{day.dayName}</li>
                                                    )
                                                })}
                                            </ul>
                                            
                                        </div>
                                    </div>
                                    <small id="errDay" className="invalid-feedback" style={{ display: 'none' }}></small>
                                </div>
                                <div className="col-2 mb-2 me-2">
                                    <label htmlFor="" className="form-label">Ward<span className="starMandatory">*</span></label>
                                    {wardList &&
                                        <DropdownWithSearch defaulNname="Select Ward" name="ward" list={wardList} valueName="id" displayName="wardName" editdata={editWardDropdown}
                                            getvalue={handleChange}
                                            clear={clearDropdown}
                                            clearFun={handleClear}
                                        />
                                    }
                                    <small id="errWard" className="invalid-feedback" style={{ display: 'none' }}></small>

                                </div>
                                <div className="col-2 mb-2 me-2">
                                    <label htmlFor="shift" className="form-label">Location</label>
                                    {locationList &&
                                        <DropdownWithSearch
                                            defaulNname="Select Location"
                                            name="location"
                                            list={locationList.map(val => ({
                                                id: val.id,
                                                displayName: `${val.roomNumber} ${val.floorName} ${val.buildingName}`,
                                            }))}
                                            valueName="id"
                                            displayName="displayName"
                                            editdata={editLocationDropdown}
                                            getvalue={handleChange}
                                            clear={clearDropdown}
                                            clearFun={handleClear}
                                        />
                                    }
                                </div>
                                <div className="mb-2 relative">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                    <div>
                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />

                                                    : <div>
                                                        {updateBool === 0 ?
                                                            <>
                                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handlerSave}><img src={saveButtonIcon} className='icnn' alt='' />Save</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { handleClear(1) }}><img src={clearIcon} className='icnn' alt='' />Clear</button>
                                                            </>
                                                            :
                                                            <>
                                                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={handlerUpdate}>Update</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { handleClear(1) }}>Cancel</button>
                                                            </>
                                                        }
                                                    </div>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </BoxContainer>
                        </div>
                        <div className="col-12 mt-2">
                            <div className='handlser'>
                                <Heading text={t("Doctor Schedule List")} />
                                <div style={{ position: 'relative' }}>
                                    <input type="text" className='form-control form-control-sm' placeholder={t("Search")} onChange={handleSearch} />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
                            <div className="med-table-section" style={{ "height": "75vh" }}>
                                <TableContainer>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>#</th>
                                            <th>Schedule Type</th>
                                            <th>Designation</th>
                                            <th>Assigned User</th>
                                            <th>Ward</th>
                                            <th>Shift</th>
                                            <th>Day</th>
                                            <th>From Date</th>
                                            <th>To Date</th>
                                            <th>Location</th>
                                            <th style={{ "width": "10%" }} className="text-center">Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {scheduleList && scheduleList.map((schedule, ind) => {
                                            return (
                                                <tr key={schedule.id}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td>{schedule.scheduleTypeName}</td>
                                                    <td>{schedule.name}</td>
                                                    <td>{schedule.name}</td>
                                                    <td>{schedule.wardName}</td>
                                                    <td>{schedule.shiftName}</td>
                                                    <td>{schedule.dayName}</td>
                                                    <td>{schedule.fromDateEdit}</td>
                                                    <td>{schedule.toDateEdit}</td>
                                                    <td>{schedule.locationDetails.roomNumber + ' ' + schedule.locationDetails.floorName + ' ' + schedule.locationDetails.buildingName}</td>
                                                    <td>
                                                        <div className="action-button">
                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={editBtnIcon} className='' alt='' onClick={() => { handleEdit(schedule) }} /></div>
                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowId(schedule.id) }} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </TableContainer>
                                {/* -----------------------Start Delete Modal Popup-------------------   */}

                                {/*  <!-- Modal -->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">

                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'>Delete?</div>
                                                <div className='popDeleteContent'>Are you sure want to delete?</div>
                                            </div>
                                            <div className="modal-footer1 text-center">

                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={handleDelete} data-bs-dismiss="modal">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

                            </div>
                        </div>
                    </div>
                </div>
                {
                    showLoder === 1 ? <Loader val={showLoder} /> : ""
                }
                {/* Toaster */}
                {
                    isShowToaster === 1 ?
                        <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                }

                {
                    showAlertToster === 1 ?
                        <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""

                }
            </section>
        </>
    )
}