async function AdmitPatient(obj) {
    let url = window.PatientMonitorDashboardAPI + '/api/PatientRegistrationForExternal/PatientRegistrationWithAdmitForExternal?bedId='+obj.bedId+'&BedRefrenceCode='+obj.bedRefrenceCode+'&clientId='+obj.clientId+'&userId='+obj.userId;
    let head = {
      'Content-Type': 'application/JSON',
      accept: '*/*',
    }
    let response =
      await fetch(url, {
        method: 'POST',
        headers: head,
        body: JSON.stringify()
      })
        .then((res) => res.json())
        .then()


    return response;
  }
  export default AdmitPatient;