import React, { useEffect, useState } from 'react'
import i18n, { t } from "i18next";
import TableContainer from '../../../Component/TableContainer';
import Heading from '../../../Component/Heading';
import oximeter from '../../../assets/images/dmsimg/oximeterstore.png'
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import Checkwithcircle from '../../../assets/images/dmsimg/Checkwithcircle.svg';
import senddemand from '../../../assets/images/dmsimg/senddemand.svg'
import uploadimage from '../../../assets/images/dmsimg/uploadimg.svg'
import clearIcon from '../../../assets/images/icons/clear.svg';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg'
import SearchItem from '../../API/UserDemand/GET/SearchItem';
import GetItemInformation from '../../API/UserDemand/GET/GetItemInformation';
import GetUrgency from '../../API/UserDemand/GET/GetUrgency';
import Search from '../../../Code/Serach';
import InsertRaiseDemand from '../../API/UserDemand/POST/InsertRaiseDemand';
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Toster from '../../../Component/Toster';



export default function UserDemand() {
    const [showModal, setShowModal] = useState(false)
    // const handleModal = () => {setShowModal(true)}
    let [getIndex, setIndex] = useState(0);
    const [isScreen1199, setIsScreen1199] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [itemName, setItemName] = useState('');
    const [brandName, setBrandName] = useState('');
    const [specification, setSpecification] = useState('');
    const [itemCode, setItemCode] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [itemImage, setItemImage] = useState('');
    const [itemImageUpdate, setItemImageUpdate] = useState('');
    const [showDetails, setShowDetails] = useState(false);
    const [urgencyList, setUrgencyList] = useState([]);
    const [tempArrOfProduct, setTempArrOfProduct] = useState([]);
    const [tempArrOfProductMain, setTempArrOfProductMain] = useState([]);
    let [sendForm, setSendForm] = useState({"quantityName": '',"urgencyName": 0,"justificationName": '', "remarkName":''});
    const [updateClick, setUpdateClick] = useState(false);
    const [finalArr, setFinalArr] = useState([]);
    const [getItemID, setItemID] = useState('');
    const [getBrandID, setBrandID] = useState('');
    const [getDemandTypeID, setDemandTypeID] = useState()
    let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [showToster, setShowToster] = useState(0);
    let [tosterMessage, setTosterMessage] = useState("");
    let [tosterValue, setTosterValue] = useState(0);
    const [loaderRing, setLoaderRing] = useState(false)
 
    const clientID = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
    
    // let tempItemImage =  document.getElementById('itemImageId').value??'';

    function isIndexAvailable(array, index) {
        return index >= 0 && index < array.length;
    }

    const debouncingSearchItem=(func,delay)=>{
            let timer;
           return (...args)=>{
            clearTimeout(timer);
            timer = setTimeout(()=>{
                func.apply(this,args)
            },delay)
           }
    }
    const getData = async()=>{
        setLoaderRing(true)
        const searchItem=document.getElementById("txtSearchItem").value;
        const {status,responseValue} = await SearchItem(searchItem, clientID);
        if(status){
        setItemList(responseValue);
        setLoaderRing(false)
        }
        else{
        setItemList([]);
        }
    }
    const funSearchItem = debouncingSearchItem(()=>{getData()},500)

    const handleGetInfo = async ({itemId, brandId}) => {
        setUpdateClick(false)
        const itemRes = await GetItemInformation(itemId, brandId, clientID);
        if(itemRes.status === 1){
            setItemID(itemId); setBrandID(brandId);
            const itemValues = itemRes.responseValue[0];
            setShowDetails(true)
            setItemName(itemValues.itemName); setBrandName(itemValues.brandName); setSpecification(itemValues.specification); setItemCode(itemValues.itemCode);
            setCategoryName(itemValues.categoryName); setItemImage(itemValues.imgURL)
        }
    }
    const closeDropdown = () => {
        setTimeout(() => {
            setItemList([]);
            document.getElementById("txtSearchItem").value = '';
        },1000)
    }


    const handleSelectDemandType = (event) => {
        document.getElementById('errDemandType').style.display = 'none';
        const selectedValue = event.target.value;
        // Do something with the selected value, like sending it to an API, updating state, etc.
        setDemandTypeID(selectedValue)
    }

    const funUrgency  = async () => {
        const resUrg = await GetUrgency(176);
        if(resUrg.status === 1){
            setUrgencyList(resUrg.responseValue)
        }
    }

    let handleChange = (e) => {
        
        let name = e.target.name;
        let value = e.target.value;
        if(name === 'quantityName'){
            document.getElementById('errQuantity').style.display = 'none';
        }
        setSendForm(sendForm => ({
            ...sendForm,
            [name]: value
        }))
    }

    // to add product in temporary array of products
    const funAddProduct = () => {
        const theItemID = getItemID;
        const theBrandID = getBrandID;
        const getItemName = itemName;  
        const getBrandName = brandName;   
        const getItemCode = itemCode; 
        const getCategory = categoryName; 
        const getItemImage = itemImage;
        const getJustification = sendForm.justificationName;
        const getQuantity = sendForm.quantityName;
        const getRemark = sendForm.remarkName;
        const getUrgencyy = sendForm.urgencyName;
    
        if(sendForm.quantityName){
            // Check if tempArrOfProduct is empty or length is 0
        if (tempArrOfProduct.length === 0) {
            const newItem = [{
                itemID: theItemID,
                brandID : theBrandID,
                itemImage: window.InventoryBaseUrl + '/' + getItemImage,
                itemName: getItemName,
                product: getBrandName,
                itemCode: getItemCode,
                category: getCategory,
                quantity: getQuantity,  
                justification: getJustification,
                remark: getRemark,
                urgency: getUrgencyy
            }];
            setTempArrOfProduct(newItem);
            setTempArrOfProductMain(newItem);
            funToClearProductDetails();
            return;
        }
    
        // If tempArrOfProduct is not empty, check for duplicate items
        for(let i = 0; i < tempArrOfProduct.length; i++){
            if(tempArrOfProduct[i].itemName === getItemName && tempArrOfProduct[i].product === getBrandName){
                alert('Item already present in it.');
                return;
            }
        }
         // If no duplicate found, add the new item
         const newItem = [...tempArrOfProduct];
         newItem.push({
             itemID: theItemID,
                 brandID : theBrandID,
             itemImage: window.InventoryBaseUrl + '/' + getItemImage,
             itemName: getItemName,
             product: getBrandName,
             itemCode: getItemCode,
             category: getCategory,
             quantity: getQuantity,  
             justification: getJustification,
             remark: getRemark,
             urgency: getUrgencyy
         });
         setTempArrOfProduct(newItem);
         setTempArrOfProductMain(newItem);
         funToClearProductDetails();

        }
        else{
            document.getElementById('errQuantity').innerHTML = "Please fill quantity.";
            document.getElementById('errQuantity').style.display = "block";
            document.getElementById('errQuantity').style.display = 'block'
        }
        
    
       
    }
    
      //handle search
      let handleSearch = (e) => {
        let resp = Search(tempArrOfProductMain, e.target.value)
        if (e.target !== "") {
            if (resp.length !== 0) {
                setTempArrOfProduct(resp)
            }
            else {
                setTempArrOfProduct([])
            }
        }
        else {
            setTempArrOfProduct(tempArrOfProductMain)
        }
    }


    // to remove element from the list of added products
    const funRemoveItem = () => {
        const newArray = [...tempArrOfProduct];
        const newFinalArray = [...finalArr] // Create a copy of the original array
        newArray.splice(getIndex, 1); // Remove one element at getIndex
        setTempArrOfProduct(newArray); // Update the state with the new array
        if (isIndexAvailable(finalArr, getIndex)) {
            newFinalArray.splice(getIndex,1)
            setFinalArr(newFinalArray)
        } 
        if(newArray.length === 0){
            document.getElementById("mainCheckID").checked = false;
        }
    }

   // to edit the specific item with in the itemlist
   const funToEdit = (list, ind) => {
    setIndex(ind);
    setUpdateClick(true)
    setShowDetails(true); setItemName(list.itemName); setBrandName(list.product); setCategoryName(list.category) ; setItemCode(list.itemCode); setItemImageUpdate(list.itemImage); setSpecification(list.specification);
    setSendForm({"quantityName": list.quantity,"urgencyName": list.urgency,"justificationName": list.justification, "remarkName":list.remark});
   }

   // to update the values in specific row of items
   const funUpdateProduct = () => {
    const newItem =
        {
            itemImage:itemImageUpdate,
            itemName: itemName,
            product: brandName,
            itemCode: itemCode,
            category: categoryName,
            quantity: sendForm.quantityName,  
            justification: sendForm.justificationName,
            remark: sendForm.remarkName,
            urgency: sendForm.urgencyName
        };

        const newArray = [...tempArrOfProduct]; // Create a copy of the original array
        newArray.splice(getIndex, 1,newItem); // Remove one element at getIndex
        setTempArrOfProduct(newArray); // Update the state with the new array
        funCancelUpdate();
   }

   const funAddFinalArray = (list,ind) => {

    const targetInputBox = document.getElementById("checkBoxID" + ind).checked;
    if(targetInputBox === false){
        let newFinalArr = [...finalArr];
        for(let i = 0; i<newFinalArr.length;i++){
             if(i === ind){ 
                newFinalArr.splice(i,1)
             }
        }
        setFinalArr(newFinalArr);
    }
    else{
        let newFinalArr = [...finalArr];
    newFinalArr.push({
        itemID: list.itemID,  //
        brandID : list.brandID, //
        imageUrl: list.itemImage, //
            itemName: list.itemName,
            product: list.product,
            itemCode: list.itemCode,
            category: list.category,
            requiredQty: list.quantity,  //
            justification: list.justification,
            specification: list.remark, //
            urgencyType: list.urgency //
    });
    setFinalArr(newFinalArr);

    }
}


const funCheckAllItem = () => {
    const mainInputBox = document.getElementById("mainCheckID").checked;
    let tempAllArray = [...finalArr];
    if (tempArrOfProduct.length > 0 && mainInputBox === true) {
        for (let i = 0; i < tempArrOfProduct.length; i++) {
            const itemExists = tempAllArray.some(item => item.itemName === tempArrOfProduct[i].itemName && item.product === tempArrOfProduct[i].product);
            if (!itemExists) {
                document.getElementById('checkBoxID' + i).checked = true;
                tempAllArray.push({
                    itemID:tempArrOfProduct[i].itemID,
                    brandID:tempArrOfProduct[i].brandID,
                    imageUrl: tempArrOfProduct[i].itemImage,
                    itemName: tempArrOfProduct[i].itemName,
                    product: tempArrOfProduct[i].product,
                    itemCode: tempArrOfProduct[i].itemCode,
                    category: tempArrOfProduct[i].category,
                    requiredQty: tempArrOfProduct[i].quantity,
                    justification: tempArrOfProduct[i].justification,
                    specification: tempArrOfProduct[i].remark,
                    urgencyType: tempArrOfProduct[i].urgency
                });
            }
        }
        setFinalArr(tempAllArray);
    }
    else{
        for (let i = 0; i < tempArrOfProduct.length; i++){
            document.getElementById('checkBoxID' + i).checked = false;
            
            
        }
        setFinalArr([]);
    }
}


   // to clear the product details///
   const funToClearProductDetails = () => {
    setSendForm({"quantityName": '',"urgencyName": 0,"justificationName": '', "remarkName":''});
        setShowDetails(false); setBrandName(); setCategoryName('') ; setItemCode(''); setItemImage(''); setSpecification('')
   }

   const handleClearList = () => {
    if(tempArrOfProduct.length>0){
        document.getElementById('mainCheckID').checked = false;
        for (let i = 0; i < tempArrOfProduct.length; i++){
            document.getElementById('checkBoxID' + i).checked = false;    
        }
        setFinalArr([]);
    }  
   }

   // to cancel the update
   const funCancelUpdate = () => {
    setUpdateClick(false); funToClearProductDetails();
   }

   const funClearArrays = () => {
    document.getElementById('mainCheckID').checked = false;
    setTempArrOfProduct([]); setTempArrOfProductMain([]); setFinalArr([]);
   }

   // to save demand//
   const funSendDemand = async () => {
    if(getDemandTypeID === 0){
        // alert('please select demand type')
        document.getElementById('errDemandType').innerHTML = 'Please select demand type.';
        document.getElementById('errDemandType').style.display = 'block';
    }
    else{
        const toSendObj = {
            id: 0,
            demandType: getDemandTypeID,
            userID: window.userId,
            clientID: clientID,
            userDemandJson: JSON.stringify(finalArr)
        }
        setShowUnderProcess(1);

    let resSend = await InsertRaiseDemand(toSendObj)
    if(resSend.status === 1){
        
        setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Demand sent successfully.");
                setTimeout(() => {
                    setShowToster(0);
                }, 1500)
                funClearArrays();
    }
    else{
        setShowUnderProcess(0);
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage(resSend.responseValue);
                setTimeout(() => {
                    setShowToster(0);
                }, 1500)
    }
    }
    
    
   }


    useEffect(() => {
        funUrgency();
        const handleResize = () => {
            setIsScreen1199(window.innerWidth <= 1199,);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        
    }, []);

    return (
        <>
            <section className="main-content mt-5 pt-3 p-2">
                <div className="container-fluid">
                    <div className={`row ${isScreen1199 ? "flex-column-reverse" : ''}`} >
                        <div className="col-xxl-9 col-xl-6 col-lg-12 col-md-12 col-sm-12 ps-0">
                            {/* <div className="userdemandmain" > */}
                            <div className="med-box pdingtbl">
                                <div className="handlser flex-wrap p-1 pb-1">
                                    <div className="heading text-wrap">Raise New Demand</div>
                                    <div className='mt-2' style={{position: 'relative'}}><span className="starMandatory">*</span>
                                    <select name="demandTypeName" className='form-select form-select-sm' id="demandTypeID" onChange={handleSelectDemandType}>
                                            <option value="0">--Demand Type--</option>
                                            <option value="1">Normal</option>
                                            <option value="2">Urgent</option>
                                            <option value="3">Must</option>
                                        </select>
                                        <small id="errDemandType" className="invalid-feedback" style={{ display: 'none' }}></small>
                                    </div>
                                    <div className='mt-2' style={{ position: 'relative' }}>                                        
                                        <input type="text" className="form-control form-control-sm"  placeholder="Search" onChange={handleSearch}/>
                                        <span className="tblsericon"><i className="fas fa-search"></i></span>
                                    </div>
                                </div>
                                <div className='userdemandheight'>
                                <div className="med-table-section p-2" style={{ height: '78vh' }}>
                                    <TableContainer>
                                        <thead>
                                            <tr style={{ background: '#EAF6FF' }}>
                                                <th><input type='checkbox' className='form-check-input' id='mainCheckID' onClick={funCheckAllItem}/></th>
                                                <th className="text-center" style={{ "width": "4%" }}>#</th>
                                                <th></th>
                                                <th>{t("Product")}</th>
                                                <th>{t("Brand")}</th>
                                                <th>{t("Item Code")}</th>
                                                <th>{t("Category")}</th>
                                                <th style={{ "width": "8%" }}>{t("Qty")}</th>
                                                <th>{t("Justification")} </th>
                                                <th>{t("Remark")}</th>
                                                <th>{t("Action")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {tempArrOfProduct.length>0 && tempArrOfProduct.map((list, ind) => {
                                            return(
                                                <tr key={ind}>
                                                <td className="text-center"> <input type="checkbox" id={'checkBoxID'+ind} name="" value="" onClick={() => {funAddFinalArray(list,ind)}} /></td>
                                                <td className="text-center">{ind + 1}</td>
                                                <td className='storitems invImg mt-0'><img src={list.itemImage} alt="" /></td>
                                                <td><input type='text' className='form-control form-control-sm' id={'itemRowID'+ind} value={list.itemName} readOnly/></td>
                                                <td><input type='text' className='form-control form-control-sm' id={'brandRowID'+ind} value={ list.product} readOnly/></td>
                                                <td><input type='text' className='form-control form-control-sm' id={'itemCodeRowID'+ind} value={list.itemCode} readOnly/></td>
                                                <td><input type='text' className='form-control form-control-sm' id={'categoryRowID'+ind} value={list.category} readOnly/></td>
                                                <td><input type='number' className='form-control form-control-sm' id={'quantityRowID'+ind} value={list.quantity} readOnly/></td>
                                                <td><input type='text' className='form-control form-control-sm' id={'justificationRowID'+ind} style={{ width: '100%' }} value={list.justification} readOnly/></td>
                                                <td> <textarea rows="1" className='form-control form-control-sm' id={'remarkRowID'+ind} cols="50" style={{ width: '100%' }} value={list.remark} readOnly></textarea></td>
                                                <td>
                                                    <div className="action-button">
                                                        <div data-bs-toggle="tooltip" title='Edit' data-bs-placement="bottom" onClick={() => funToEdit(list,ind)}><img src={editBtnIcon} alt='' /></div>
                                                        <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal" onClick={() => setIndex(ind)}><img src={deleteBtnIcon} className='' alt='' />
                                                        </div>
                                                    </div>
                                                </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </TableContainer>

                                </div>
                              
                                
                                <div className="mb-0 mt-0 pt-0 pb-0 relative" >                              
                                    {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                    <>
                                        {showToster === 1 ? 
                                        <Toster value={tosterValue} message = {tosterMessage} /> : 
                                        <>{
                                            tempArrOfProduct.length>0 && <>
                                            <hr className='mb-0 mt-0 pt-0 pb-0 pt-3 '/>
                                            <div className="d-flex flex-wrap align-content-end justify-content-end pt-0 pb-0 mt-0 mb-0 p-1" >
                                        <button type="button" className="btn btn-clear btn-sm me-1"  onClick={handleClearList}><img src={clearIcon} className='icnn' alt=''/>{t("Clear")}</button>
                                        <button type="button" className="btn btn-save btn-save-fill btn-sm me-1"  onClick={funSendDemand} ><img src={senddemand} className='icnn' alt=''/>{t("Send Demand")} </button>
                                        
                                    </div> 
                                        </>}
                                    </>}
                                    
                                </>        
                                }
                                </div> 
                                </div>                              

                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-6 col-lg-12 col-md-12 col-sm-12 mt-0 ps-0">
                            <div className="med-box " style={{ minHeight:'91vh' , maxHeight:'91vh', overflow:'auto'}}>
                                <div className="inner-content clrbg" >
                                    <div className='storeitemspurchase'>
                                        {!updateClick && <div className='mb-2' >
                                        
                                        {/* <div style={{position: 'relative'}}>
                                        <input type="text" className="form-control form-control-sm" placeholder="Search Item"  onKeyUp={funSearchItem} id='txtSearchItem'/>
                                        <span className='loaderSearch'></span>
                                        </div> */}
                                        <div className="input-with-loader">
                                            <input type="text" className="form-control form-control-sm" placeholder="Search Item" onKeyUp={funSearchItem} id="txtSearchItem"/>
                                            {loaderRing && <div className="loaderSearch"></div>}
                                           
                                        </div>
                                    
                                        {/* <input type="text" className="form-control form-control-sm" placeholder="Search Item" value={inputValue} onChange={(e) => {funSearchItem(e)}}/> */}
                                        {itemList && itemList.length > 0 && ( // Add null check and length check
                                            <>
                                              <ul className='dropdown form-control form-control-sm' style={{ listStyleType: 'none', cursor:'pointer', maxHeight:'30vh', overflowY: 'auto'}}>
                                                {itemList.map((list, ind) => (
                                                  <li key={ind} onClick={() => {handleGetInfo(list);closeDropdown()}}>{list.itemName + ' ' + list.brandName}</li> // Added key attribute
                                                ))}
                                              </ul>
                                            </>
                                        )}
                                    </div>}
                                        {showDetails && (
                                            <>
                                            <div className='rightitems'>
                                            <div className='storestock'>
                                                <div className='headng-Pulse'>
                                                    <div className='pulsoxi'>{itemName}</div>
                                                    <div className='insignia'>{brandName}</div>
                                                </div>
                                                <div className='storitems'>
                                                    <div className='invImg'>
                                                        <img src={updateClick?itemImageUpdate : window.InventoryBaseUrl + '/' + itemImage} id='itemImageId' value={itemImage} alt=''/>
                                                    </div>
                                                    <div>
                                                        <p className='itemcode'>  Item Code  </p>
                                                        <p className='itemnumbr'>{itemCode}</p>
                                                        {/* <p className='units'>Unit  </p>
                                                        <p className='unitpeice'> peice 2</p> */}

                                                    </div>
                                                    <div>
                                                        <p className='itemcode'> Category  </p>
                                                        <p className='itemnumbr'>{categoryName ? categoryName : 'Not available'}</p>
                                                        {/* <p className='units'>Severity  </p>
                                                        <p className='unitpeice clrlfe' > Life Saving</p> */}

                                                    </div>

                                                </div>
                                            </div>

                                            <div className='storestock mt-1'>
                                                <div className='headng-Pulse'>
                                                    <div className='otherdetls'>Other Details:</div>
                                                </div>
                                                {/* <div className='storitems'>
                                                    <div>
                                                        <p className='itemcode'> Last Issuing  </p>
                                                        <p className='itemnumbr'>21 Jan 2024</p>
                                                    </div>
                                                    <div>
                                                        <p className='itemcode'> Last Issue Qty.  </p>
                                                        <p className='itemnumbr'>03</p>

                                                    </div>
                                                    <div>
                                                        <p className='itemcode'> Total Issued  </p>
                                                        <p className='itemnumbr'>10</p>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className='specificatin'>
                                                <div className='specifctn'>Specification</div>
                                                <div className='blodoner'>{specification}</div>
                                            </div>
                                        </div>
                                        <div className="med-box rightitems mt-3" style={{ minHeight:'51.3vh', maxHeight:'51.3vh'}}>
                                            <div className="d-flex ">
                                                <div className="Priorityy mb-1 mx-1">
                                                    <label htmlFor="" className="form-label">{t("Quantity")}<span className="starMandatory">*</span></label>
                                                    <input type="number" className="form-control form-control-sm" id="quantityID" placeholder={t("Enter quantity")} name="quantityName" onChange={handleChange} value={sendForm.quantityName}/>
                                                    <small id="errQuantity" className="invalid-feedback" style={{ display: 'none' }}></small>
                                                </div>
                                                <div className="Priorityy mb-1 ">
                                                    <label htmlFor="" className="form-label">{t("Priority")}</label>
                                                    {/* <input type="text" className="form-control form-control-sm" id="" placeholder={t("Urgent")} name="" /> */}
                                                    <select name="urgencyName" className='form-select form-select-sm' id="urgencyID" onChange={handleChange} value={sendForm.urgencyName}>
                                                        <option value="0">--Select--</option>
                                                        {urgencyList && urgencyList.map((list, ind) => {
                                                            return(
                                                                <option key={ind} value={list.id}>{list.urgencyLevelName}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-1 me-2" style={{ width: '100%' }}>
                                                <label htmlFor="" className="form-label">{t("Justification")}</label>
                                                <input type="text" className="form-control form-control-sm" id="justificationID" placeholder={t("Enter justification")} name="justificationName" onChange={handleChange} value={sendForm.justificationName}/>
                                            </div>
                                            <div className="me-2" style={{ width: '100%' }}>
                                                <label htmlFor="" className="form-label">{t("Remarks")}</label>
                                                <input type="text" className="form-control form-control-sm" id="remarkID" placeholder={t("Enter remark")} name="remarkName" onChange={handleChange} value={sendForm.remarkName}/>
                                            </div>
                                        </div>
                                        <div className="relative mt-4 pt-4 mb-1 pb-1">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                            <div className="d-flex flex-wrap align-content-end justify-content-end">
                                                {/* <button type="button" className="btn btn-clear btn-sm mb-1 me-1" ><img src={clear} className='icnn' alt='' />{t("Clear")}</button> */}
                                                
                                                {!updateClick?<>
                                                    <button type="button" className="btn btn-save btn-save-fill btn-sm me-1" onClick={funAddProduct}><img src={uploadimage} className='icnn' alt='' />{t("Add Product")} </button>
                                                <button type="button" className="btn btn-clear btn-sm me-1"><img src={clearIcon} className='icnn' alt=''/>{t("Clear")}</button>
                                                
                                                </>:<>
                                                <button type="button" className="btn btn-clear btn-sm me-1" onClick={funCancelUpdate}><img src={clearIcon} className='icnn' alt=''/>{t("Cancel")}</button>
                                                <button type="button" className="btn btn-save btn-save-fill btn-sm me-1" onClick={funUpdateProduct}><img src={uploadimage} className='icnn' alt='' />{t("Update Product")} </button>
                                                
                                                </>}
                                            </div>
                                        </div>
                                            </>
                                        )}
                                        

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    {/* -----------------------Start Delete Modal Popup-------------------    */}
                    {showModal === true ? (
                        <div className={`modal d-${showModal === true ? "block" : "none"}`} tabIndex="-1" aria-labelledby="" aria-hidden="true">
                            <div className="modal-dialog modal-sm" style={{ margin: '12% auto' }}>
                                <div className="modal-content">
                                    <div className="modal-header bg-clrr " style={{borderBottom:'0'}}>
                                        <div className="heading text-wrap"></div>
                                        <div>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}></button>
                                        </div>
                                    </div>
                                    <div className="modal-body mt-0 pt-0">
                                        <div className="med-box" style={{ boxShadow: 'inherit', textAlign:'center' }}>
                                            <div className="inner-content ">
                                                <div className='row'>
                                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                                                        <div className='ps-0 pe-0 mt-0 pt-0 mb-2  '><img src={Checkwithcircle} alt='' /></div>
                                                        <div className='ps-0 pe-0 popupdemandtxt' style={{ textAlign: 'center', fontSize:'15px' }}> Demand Sent Successfully!</div>
                                                        <button type="button" className="btn btn-save btn-save-fill btn-sm me-1 mb-4 mt-2">{t("Check Status")} </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {/* -----------------------End Delete Modal Popup---------------------  */}

                    {/*  <!-- Modal -->  */}
                    <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">

                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'>Delete?</div>
                                                <div className='popDeleteContent'>Are you sure want to delete?</div>
                                            </div>
                                            <div className="modal-footer1 text-center">

                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={funRemoveItem} data-bs-dismiss="modal">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

                                {/*  <!-- Modal -->  */}
                    <div className="modal fade" id="sendModal" tabIndex="-1" aria-labelledby="sendModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">

                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'>Delete?</div>
                                                <div className='popDeleteContent'>Are you sure want to delete?</div>
                                            </div>
                                            <div className="modal-footer1 text-center">

                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={funRemoveItem} data-bs-dismiss="modal">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}
                </div>


            </section>

        </>
    )
}
