import React, { useEffect, useState } from 'react'
import TosterUnderProcess from '../../Component/TosterUnderProcess';
import Toster from '../../Component/Toster';
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import clearIcon from '../../assets/images/icons/clear.svg';
import PostMriQuestionaire from './API/PostMriQuestionaire';
import GetMRIQuestionaireData from './API/GetMRIQuestionaireData';
import GetPatientMriData from './API/GetPatientMriData'
import SuccessToster from '../../../src/Component/SuccessToster'
import AlertToster from '../../../src/Component/AlertToster'
import imgPrint from "../../assets/images/icons/imgPrint.svg";
import { t } from 'i18next';
import IconDateTime from "../../assets/images/icons/IconDateTime.svg";
import allergies from '../../assets/images/icons/adrDrugAllergyScreeningIcon.svg'
import creatinineallergy from '../../assets/images/icons/Creatinine.svg'
import GetPatientDetailsByUHID from '../../../src/Clinical/API/IPD/personalDashboardIndexSecond/GetPatientDetailsByUHID'
import PatientDeatils from '../../Component/PatientDeatils'

export default function MRIQuestionNaire() {
  const [showUnderProcess, setShowUnderProcess] = useState(0);
  const [showToster, setShowToster] = useState(0);
  const [tosterMessage, setTosterMessage] = useState("");
  const [tosterValue, setTosterValue] = useState(0);
  let [mriQuestList, setMRIQuestList] = useState([]);
  let [patientMriData, setPatientMriData] = useState([]);
  let [mriData, setMriData] = useState([]);
  let [inputValues, setInputValues] = useState('');
  let [selectedPregnancy, setSelectedPregnancy] = useState(0);
  // let [selectedPaceMaker, setSelectedPaceMaker] = useState(null);
  // let [selectedEarCochlear, setSelectedEarCochlear] = useState(null);
  // let [selectedBrain, setSelectedBrain] = useState(null);
  // let [selectedReactionMri, setSelectedReactionMri] = useState(null);
  // let [selectedOtherMetal, setSelectedOtherMetal] = useState(null);
  // let [selectedImplantedElect, setSelectedImplantedElect] = useState(null);
  // let [selectedNeurostimulator, setSelectedNeurostimulator] = useState(null);
  // let [selectedStents, setSelectedStents] = useState(null);
  // let [selectedMagneticDental, setSelectedMagneticDental] = useState(null);
  // let [selectedContrastCT, setSelectedContrastCT] = useState(null);
  // let [selectedPrevMRI, setSelectedPrevMRI] = useState(null);
  // let [selectedPrevCT, setSelectedPrevCT] = useState(null);
  // let [selectedPrevXRay, setSelectedPrevXRay] = useState(null);
  // let [selectedPrevUltra, setSelectedPrevUltra] = useState(null);
  // let [selectedMetalEyes, setSelectedMetalEyes] = useState(null);
  // let [selectedMetalFreg, setSelectedMetalFreg] = useState(null);
  let [showSuccessToster, setShowSuccessToster] = useState(0)
  let [showAlertToster, setShowAlertToster] = useState(0)
  let [showMessage, setShowMeassage] = useState("")
  // let [categoryId, setCategoryId] = useState("")
  // let [jsonData, setJsonData] = useState([])
  // let [selectedPaceMakerObj, setSelectedPaceMakerObj] = useState(null);
  // let [selectedEarCochlearObj, setSelectedEarCochlearObj] = useState(null);
  // let [selectedBrainObj, setSelectedBrainObj] = useState(null);
  // let [selectedReactionMriObj, setSelectedReactionMriObj] = useState(null);
  // let [selectedOtherMetalObj, setSelectedOtherMetalObj] = useState(null);
  // let [selectedNeurostimulatorObj, setSelectedNeurostimulatorObj] = useState(null);
  // let [selectedStentsObj, setSelectedStentsObj] = useState(null);
  // let [selectedMagneticDentalObj, setSelectedMagneticDentalObj] = useState(null);
  // let [selectedContrastCTObj, setSelectedContrastCTObj] = useState(null);
  // let [selectedImplantedElectObj, setSelectedImplantedElectObj] = useState(null);
  // let [selectedPrevMRIObj, setSelectedPrevMRIObj] = useState(null);
  // let [selectedPrevCTObj, setSelectedPrevCTObj] = useState(null);
  // let [selectedPrevXRayObj, setSelectedPrevXRayObj] = useState(null);
  // let [selectedPrevUltraObj, setSelectedPrevUltraObj] = useState(null);
  // let [selectedMetalEyesObj, setSelectedMetalEyesObj] = useState(null);
  // let [selectedMetalFregObj, setSelectedMetalFregObj] = useState(null);
  // let [dateTime, setDateTime] = useState();
  // let [isAsthma, setIsAsthma] = useState('');
  let [asthmaDetails, setAsthmaDetails] = useState('');
  let [allergy, setAllergy] = useState('');
  let [serumUrea, setSerumUrea] = useState(null);
  let [creatinine, setCreatinine] = useState(null);
  let [appointmentTime, setAppointmentTime] = useState(0);
  let date = new Date()
  let [patientData, setPatientData] = useState([]);
  let [isAsthmaSelected, setIsAsthmaSelected] = useState(null);
  let requiredUHID = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].uhId;
  let pmId = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].pmId;
  let userId = JSON.parse(sessionStorage.getItem("LoginData")).userId
  let clientId = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [optionList, setOptionList] = useState([]);

  const formatDateTime = (dateTimeString) => {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false // 24-hour format
    };
    const date = new Date(dateTimeString);
    const formattedDate = date.toLocaleString('en-IN', options);

    // Extracting date and time parts and formatting them separately
    const [datePart, timePart] = formattedDate.split(', ');
    const [day, month, year] = datePart.split('/');
    const [hour, minute] = timePart.split(':');

    // Constructing the desired format
    const formattedDateTime = `${day}-${month}-${year} ${hour}:${minute}`;
    return formattedDateTime;
  };

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const formatDateTimeNew = (dateTimeString) => {
    const date = new Date(dateTimeString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  let setId = 6;
  let getdata = async () => {
    try {
     
      let response = await GetMRIQuestionaireData(setId)
      let responseData = await GetPatientMriData(requiredUHID)
      let responseptdata = await GetPatientDetailsByUHID(requiredUHID)

      if (responseptdata.status === 1) {
        setPatientData(responseptdata.responseValue[0])

      }

      if (response.status === 1) {
        setQuestionList(response.responseValue.questions);
        setOptionList(response.responseValue.options);
        const options = response.responseValue
        console.log("options", options)
      }

      if (responseData.status === 1) {
       
        const mriData = responseData.responseValue.map(item => {
          return {
            ...item,
            answers: item.answers ? JSON.parse(item.answers) : [],

          };
        });
        setPatientMriData(mriData)
        let allAnswers = [];
        responseData.responseValue.forEach(item => {
          allAnswers.push(item.answers);
        });


      }
    } catch (e) {
      setShowAlertToster(1)
      setShowMeassage('An unexpected error occurred.')
    }
  }

  const handleChange = (event) => {
    clearValidationErrMessage()
    const { name, value } = event.target;

    setInputValues(inputValues => ({
      ...inputValues,
      [name]: value,
    }));

    if (name === 'isAsthma') {
      setIsAsthmaSelected(value === 'true');
      // Reset asthmaDetails when isAsthmaSelected is false
      if (value !== 'true') {
        setAsthmaDetails('');
      }
    } else if (name === 'asthmaDetails') {
      setAsthmaDetails(value);
    }
    if (name === 'Allergy') {
      setAllergy(value)
    }
    if (name === 'creatinine') {
      setCreatinine(value)
    }
    if (name === 'serumUrea') {
      setSerumUrea(value)
    }

  }

  const handlePregnancyCheckboxChange = (event) => {

    setSelectedPregnancy(event.target.value === selectedPregnancy ? null : event.target.value);
    console.log("selectedPregnancy", selectedPregnancy)
  };

  // const handlePaceMakerCheckboxChange = (event) => {
  //   const newValue = event.target.value === selectedPaceMaker ? null : event.target.value;

  //   const obj = {
  //     questionID: 31, optionID: 7, optionText: newValue, optionTextValue: newValue
  //   }
  //   setSelectedPaceMaker(newValue);
  //   setSelectedPaceMakerObj(obj);

  //   console.log("selectedPaceMakerObj", selectedPaceMakerObj)


  // };



  // const handleEarCochlearCheckboxChange = (event) => {
  //   const newValue = event.target.value === selectedEarCochlear ? null : event.target.value;

  //   const obj = {
  //     questionID: 32, optionID: 7, optionText: newValue, optionTextValue: newValue
  //   }
  //   setSelectedEarCochlear(newValue);
  //   setSelectedEarCochlearObj(obj);

  //   console.log("selectedEarCochlearObj", selectedEarCochlearObj)
  // };

  // const handleBrainCheckboxChange = (event) => {

  //   const newValue = event.target.value === selectedBrain ? null : event.target.value;

  //   const obj = {
  //     questionID: 33, optionID: 7, optionText: newValue, optionTextValue: newValue
  //   }
  //   setSelectedBrain(newValue);
  //   setSelectedBrainObj(obj);

  //   console.log("selectedBrainObj", selectedBrainObj)
  // };

  // const handleReactionMriCheckboxChange = (event) => {

  //   const newValue = event.target.value === selectedReactionMri ? null : event.target.value;

  //   const obj = {
  //     questionID: 36, optionID: 7, optionText: newValue, optionTextValue: newValue
  //   }
  //   setSelectedReactionMri(newValue);
  //   setSelectedReactionMriObj(obj);

  //   console.log("selectedReactionMriObj", selectedReactionMriObj)

    
  // };

  // const handleOtherMetalCheckboxChange = (event) => {

  //   const newValue = event.target.value === selectedOtherMetal ? null : event.target.value;

  //   const obj = {
  //     questionID: 37, optionID: 7, optionText: newValue, optionTextValue: newValue
  //   }
  //   setSelectedOtherMetal(newValue);
  //   setSelectedOtherMetalObj(obj);

  //   console.log("selectedOtherMetalObj", selectedOtherMetalObj)

  // }

  // const handleNeurostimulatorCheckboxChange = (event) => {

  //   const newValue = event.target.value === selectedNeurostimulator ? null : event.target.value;

  //   const obj = {
  //     questionID: 39, optionID: 7, optionText: newValue, optionTextValue: newValue
  //   }
  //   setSelectedNeurostimulator(newValue);
  //   setSelectedNeurostimulatorObj(obj);

  //   console.log("selectedBrainObj", selectedBrainObj)

  
  // };

  // const handleStentsCheckboxChange = (event) => {

  //   const newValue = event.target.value === selectedStents ? null : event.target.value;

  //   const obj = {
  //     questionID: 40, optionID: 7, optionText: newValue, optionTextValue: newValue
  //   }
  //   setSelectedStents(newValue);
  //   setSelectedStentsObj(obj);



 
  // };

  // const handleMagneticDentalCheckboxChange = (event) => {

  //   const newValue = event.target.value === selectedMagneticDental ? null : event.target.value;

  //   const obj = {
  //     questionID: 41, optionID: 7, optionText: newValue, optionTextValue: newValue
  //   }
  //   setSelectedMagneticDental(newValue);
  //   setSelectedMagneticDentalObj(obj);

  //   console.log("selectedBrainObj", selectedBrainObj)

   
  // };

  // const handleContrastCTCheckboxChange = (event) => {

  //   const newValue = event.target.value === selectedContrastCT ? null : event.target.value;

  //   const obj = {
  //     questionID: 42, optionID: 7, optionText: newValue, optionTextValue: newValue
  //   }
  //   setSelectedContrastCT(newValue);
  //   setSelectedContrastCTObj(obj);

  //   console.log("selectedBrainObj", selectedBrainObj)

   
  // };

  // const handleImplantedElectCheckboxChange = (event) => {

  //   const newValue = event.target.value === selectedImplantedElect ? null : event.target.value;

  //   const obj = {
  //     questionID: 38, optionID: 7, optionText: newValue, optionTextValue: newValue
  //   }
  //   setSelectedImplantedElect(newValue);
  //   setSelectedImplantedElectObj(obj);

  //   console.log("selectedBrainObj", selectedBrainObj)

  
  // };

  // const handlePrevMRICheckboxChange = (event) => {

    
  //   const newValue = event.target.value === selectedPrevMRI ? null : event.target.value;

  //   const obj = {
  //     questionID: 47, optionID: 7, optionText: newValue, optionTextValue: newValue
  //   }
  //   setSelectedPrevMRI(newValue);
  //   setSelectedPrevMRIObj(obj);

  //   console.log("selectedBrainObj", selectedBrainObj)
  // };

  // const handlePrevCTCheckboxChange = (event) => {


  //   const newValue = event.target.value === selectedPrevCT ? null : event.target.value;

  //   const obj = {
  //     questionID: 44, optionID: 7, optionText: newValue, optionTextValue: newValue
  //   }
  //   setSelectedPrevCT(newValue);
  //   setSelectedPrevCTObj(obj);

  //   console.log("selectedBrainObj", selectedBrainObj)
  // };

  // const handlePrevXRayCheckboxChange = (event) => {

  

  //   const newValue = event.target.value === selectedPrevXRay ? null : event.target.value;

  //   const obj = {
  //     questionID: 45, optionID: 7, optionText: newValue, optionTextValue: newValue
  //   }
  //   setSelectedPrevXRay(newValue);
  //   setSelectedPrevXRayObj(obj);

  //   console.log("selectedBrainObj", selectedBrainObj)
  // };

  // const handlePrevUltraCheckboxChange = (event) => {

   

  //   const newValue = event.target.value === selectedPrevUltra ? null : event.target.value;

  //   const obj = {
  //     questionID: 46, optionID: 7, optionText: newValue, optionTextValue: newValue
  //   }
  //   setSelectedPrevUltra(newValue);
  //   setSelectedPrevUltraObj(obj);

  //   console.log("selectedBrainObj", selectedBrainObj)
  // };

  // const handleMetalEyesCheckboxChange = (event) => {

   
  //   const newValue = event.target.value === selectedMetalEyes ? null : event.target.value;

  //   const obj = {
  //     questionID: 34, optionID: 7, optionText: newValue, optionTextValue: newValue
  //   }
  //   setSelectedMetalEyes(newValue);
  //   setSelectedMetalEyesObj(obj);

  //   console.log("selectedBrainObj", selectedBrainObj)
  // };

  // const handleMetalFregCheckboxChange = (event) => {

    
  //   const newValue = event.target.value === selectedMetalFreg ? null : event.target.value;

  //   const obj = {
  //     questionID: 35, optionID: 7, optionText: newValue, optionTextValue: newValue
  //   }
  //   setSelectedMetalFreg(newValue);
  //   setSelectedMetalFregObj(obj);

  //   console.log("selectedBrainObj", selectedBrainObj)
  // };


  const handleCheckboxChange = (questionId, option) => {
    let dataContainer = [...selectedOptions];
    const optionID = 'optType' + questionId + option.id + '' + option.optionText;
    const getValue = document.getElementById(optionID).checked;
    let index = dataContainer.findIndex((arr) => arr.questionID === questionId);
    if (index !== -1) {
      document.getElementById('optType' + dataContainer[index].questionID + dataContainer[index].optionID + '' + dataContainer[index].optionText).checked = false;
      dataContainer.splice(index, 1);
      if (getValue) {
        dataContainer.push({
          questionID: questionId,
          optionID: option.id,
          optionText: option.optionText,
          optionTextValue: option.optionText,
          userID: window.userId
        });
      }
    }
    else {
      dataContainer.push({
        questionID: questionId,
        optionID: option.id,
        optionText: option.optionText,
        optionTextValue: option.optionText,
        userID: window.userId
      });
    }
    console.log('object arr', dataContainer)
    setSelectedOptions(dataContainer);
  };

  const handlePrint = (data) => {
    const tentativeDateTime = formatDateTime(data.tentativeDateTime)
    const allergies = (data.allergies)
    const isAsthama = (data.isAsthama)
    const asthama = (data.asthama)
    const urea = (data.urea)
    const creatinine = (data.creatinine)
    const isPregnent = (data.isPregnent)
    const jsonQuestionaire = (data.answers)




    window.sessionStorage.setItem("PrintQuestionaire", JSON.stringify({
      "tentativeDateTime": tentativeDateTime, "allergies": allergies, "isAsthama":isAsthama, "asthama":asthama, "urea": urea, "creatinine": creatinine, "isPregnent": isPregnent,
      "jsonQuestionaire": jsonQuestionaire

    }));
    window.open("/ReceiptQuentionnarePrint/", 'noopener,noreferrer');

  }

  const currentDate = new Date();
const year = currentDate.getFullYear();
let month = currentDate.getMonth() + 1;
month = month < 10 ? '0' + month : month; // Add leading zero if needed
let day = currentDate.getDate();
day = day < 10 ? '0' + day : day; // Add leading zero if needed
let hours = currentDate.getHours();
hours = hours < 10 ? '0' + hours : hours; // Add leading zero if needed
let minutes = currentDate.getMinutes();
minutes = minutes < 10 ? '0' + minutes : minutes; // Add leading zero if needed

const minDate = `${year}-${month}-${day}T${hours}:${minutes}`;
  
  const handleSaveMriQestionaire = async () => {

    // const paceMaker = [selectedPaceMakerObj]
    // const EarCochlear = [selectedEarCochlearObj]
    // const Brain = [selectedBrainObj]
    // const ReactionMri = [selectedReactionMriObj]
    // const OtherMetal = [selectedOtherMetalObj]
    // const Neurostimulator = [selectedNeurostimulatorObj]
    // const Stents = [selectedStentsObj]
    // const MagneticDental = [selectedMagneticDentalObj]
    // const ContrastCT = [selectedContrastCTObj]
    // const ImplantedElect = [selectedImplantedElectObj]
    // const MetalEyes = [selectedMetalEyesObj]
    // const MetalFreg = [selectedMetalFregObj]
    // const PrevMri = [selectedPrevMRIObj]
    // const PrevCT = [selectedPrevCTObj]
    // const PrevXRay = [selectedPrevXRayObj]
    // const PrevUltra = [selectedPrevUltraObj]


    // const mergedArray = [...paceMaker, ...EarCochlear, ...Brain, ...ReactionMri, ...OtherMetal, ...Neurostimulator,
    // ...Stents, ...MagneticDental, ...ContrastCT, ...ImplantedElect, ...MetalEyes, ...MetalFreg, ...PrevMri, ...PrevCT, ...PrevXRay, ...PrevUltra];
    // console.log("mergedArray", mergedArray)

    // if (inputValues.appointmentTime === '' || inputValues.appointmentTime === null || inputValues.appointmentTime === undefined) {
    //   document.getElementById('errappointmentTime').innerHTML = "Tentative Date Time is required";
    //   document.getElementById('errappointmentTime').style.display = "block";
    // }
    if (allergy=== '' || allergy === null || allergy === undefined) {
      document.getElementById('errAllergy').innerHTML = "Drug Allergy field is required";
      document.getElementById('errAllergy').style.display = "block";
    }
    else if (isAsthmaSelected === '' || isAsthmaSelected === null || isAsthmaSelected === undefined) {
      document.getElementById('errAsthma').innerHTML = "Asthma field is required";
      document.getElementById('errAsthma').style.display = "block";
    }

    else if (creatinine === '' || creatinine === null || creatinine === undefined) {
      document.getElementById('errcretanine').innerHTML = "Cretanine field is required";
      document.getElementById('errcretanine').style.display = "block";
    }
    else if (serumUrea === '' || serumUrea === null || serumUrea === undefined) {
      document.getElementById('errserumUrea').innerHTML = "Serum Urea field is required";
      document.getElementById('errserumUrea').style.display = "block";
    }

    else {
      const obj = {

        tentativeDateTime: formatDateTimeNew(inputValues.appointmentTime),
        uhId: requiredUHID,
        pmId: pmId,
        isPregnency: selectedPregnancy,
        allergies: allergy,
        isAsthama: isAsthmaSelected,
        asthama: asthmaDetails,
        urea: parseFloat(serumUrea),
        creatinine: parseFloat(creatinine),
        userId: userId,
        clientId: clientId,
        categoryID: 3,
        questionaireSetID: 6,
        questionnaireDateTime: formatDateTimeNew(inputValues.appointmentTime),
        jsonData: JSON.stringify(selectedOptions),
      }



      console.log("obj", obj)

      let response = await PostMriQuestionaire(obj);

      if (response.status === 1) {
        setShowMeassage("Data Saved Successfully!!")
        setShowSuccessToster(1)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
        getdata()
        resetForm(1);

      }
      else {
        setShowMeassage(response.responseValue)
        setShowAlertToster(1)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
      }
    }
  }

  const clearValidationErrMessage = () => {
    // document.getElementById('errappointmentTime').style.display = "none";
    document.getElementById('errAllergy').style.display = "none";
    document.getElementById('errAsthma').style.display = "none";
    document.getElementById('errcretanine').style.display = "none";
    document.getElementById('errserumUrea').style.display = "none";

  }

  const resetForm = () => {
    // setInputValues({
    //   appointmentTime: '',
      
    // });
    clearValidationErrMessage()
    document.getElementById('appointmentTime').value = inputValues.appointmentTime;
   
    document.getElementById('Allergy').value = '';
    document.getElementById('isAsthma').value = ''
    document.getElementById('creatinine').value = '';
    document.getElementById('serumUrea').value = '';
    // document.getElementById('asthmaDetails').value = '';
    const checkboxes = document.querySelectorAll('.clrchk input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    setSelectedOptions([]);
    
    setAllergy('')
    setCreatinine(null)
    setSerumUrea(null)
    setAsthmaDetails('')
    setIsAsthmaSelected()

  }


  useEffect(() => {
    getdata()
    // setDateTime(date.toLocaleDateString() + " " + date.toLocaleTimeString());
    setInputValues(prevData => ({
      ...prevData,

      appointmentTime: `${getCurrentDate()} ${getCurrentTime()}`
    }));
    

  }, [])
  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">


          <div className="row">
            <div className="col-12"><div className="med-box mb-1"><div className="title">MRI QuestionNaire</div></div>
              <PatientDeatils />
            </div>

            <div className="col-12">
              <div className="med-box">

                {/* <div className="inner-content">
                  <div className='fieldsett-in col-md-12'>
                    <div className='fieldsett'>
                      <div className='row mb-3'>


                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Tentative Date and Time of Appointment<span className="starMandatory"></span></label>
                          <input id="appointmentTime" type="text" className="form-control form-control-sm" name="appointmentTime" value={dateTime} onChange={handleChange} />
                          <small id='errappointmentTime' className='form-text text-danger' style={{ display: 'none' }}></small>
                        </div>



                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" id="paymentModediv">
                          <label htmlFor="Code" className="form-label">Drug Allergy (if any)<span className="starMandatory"></span></label>
                          <input id="Allergy" type="text" className="form-control form-control-sm" placeholder="Enter Drug Allergy" name="Allergy" onChange={handleChange} />
                          <small id='errAllergy' className='form-text text-danger' style={{ display: 'none' }}></small>
                        </div>




                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Asthma<span className="starMandatory"></span></label>
                          <input id="asthma" type="text" className="form-control form-control-sm" name="asthma" placeholder='Enter Asthama' onChange={handleChange} />
                          <small id='errAsthma' className='form-text text-danger' style={{ display: 'none' }}></small>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Creatinine(For Contrast Studies)<span className="starMandatory"></span></label>
                          <input id="cretanine" type="number" className="form-control form-control-sm" name="cretanine" placeholder='Enter Cretanine' onChange={handleChange} />
                          <small id='errcretanine' className='form-text text-danger' style={{ display: 'none' }}></small>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Serum Urea(For Contrast Studies)<span className="starMandatory"></span></label>
                          <input id="serumUrea" type="number" className="form-control form-control-sm" name="serumUrea" placeholder='Enter Urea' onChange={handleChange} />
                          <small id='errserumUrea' className='form-text text-danger' style={{ display: 'none' }}></small>
                        </div>


                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <div><label htmlFor="Code" className="form-label mt-2 ">Pregnancy<span className="starMandatory"></span></label></div>
                          <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                            <div className="d-flex column-gap-3 mt-2 me-3">
                              <label htmlFor="canRead" className="form-label">Yes<span className="starMandatory"></span></label>
                              <div className="form-check">
                                <input name="pregnancy" id='pregnancy' value="1" className="form-check-input" type="checkbox"
                                  checked={selectedPregnancy === '1'}
                                  onChange={handlePregnancyCheckboxChange} />
                              </div>

                            </div>

                            <div className="d-flex column-gap-3 mt-2 me-3">
                              <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                              <div className="form-check">
                                <input name="pregnancyNo" id='pregnancyNo' value="0" className="form-check-input" type="checkbox"
                                  checked={selectedPregnancy === '0'}
                                  onChange={handlePregnancyCheckboxChange} />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">

                          <label htmlFor="Code" className="form-label">Pregnancy Status<span className="starMandatory">*</span></label>
                          <select id="isPregnant" name='isPregnant' className="form-control form-control-sm" onChange={handleChange} >
                            <option value="" selected>Select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}


                <div className="fieldsett-in">
                  <div className="fieldsett">
                    {/* <span className='fieldse'>{t("MRI QuestionNaire")}</span> */}
                    <div className="inner-content mt-2">
                      <div className="d-flex flex-wrap align-content-end gap-3">
                        <div className="mb-2 me-2">
                          <img src={IconDateTime} className="icnn" alt="" />
                          <label htmlFor="Code" className="form-label">Tentative Date and Time of Appointment<span className="starMandatory"></span></label>
                          <input id="appointmentTime" type="datetime-local"  className="form-control form-control-sm" name="appointmentTime" value={inputValues.appointmentTime}   min={minDate} onChange={handleChange} />
                        </div>
                        <div className="mb-2 me-2">
                          <img src={allergies} className="icnn" alt="" />
                          <label htmlFor="Code" className="form-label">Drug Allergy (if any)<span className="starMandatory"></span></label>
                          <input id="Allergy" type="text" className="form-control form-control-sm" placeholder="Enter Drug Allergy" name="Allergy" onChange={handleChange} />
                          <small id='errAllergy' className='form-text text-danger' style={{ display: 'none' }}></small>
                        </div>
                        {/* <div className="mb-2 me-2">
                        <img src={allergy} className="icnn" alt="" />
                          <label htmlFor="Code" className="form-label">Asthma<span className="starMandatory"></span></label>
                          <input id="asthma" type="text" className="form-control form-control-sm" name="asthma" placeholder='Enter Asthama' onChange={handleChange} />
                          <small id='errAsthma' className='form-text text-danger' style={{ display: 'none' }}></small>
                        </div> */}
                      
                          <div className="mb-2 me-2">
                            <img src={allergies} className='icnn' alt='' />
                            <label htmlFor="Code" className="form-label">Asthma<span className="starMandatory"></span></label>
                            <select id="isAsthma" name='isAsthma' className="form-select form-select-sm" onChange={handleChange}>
                              <option value="" selected>Select</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                            <small id='errAsthma' className='form-text text-danger' style={{ display: 'none' }}></small>
                          </div>
                        

                        {isAsthmaSelected && (
                          <div className="mb-2 me-2">
                            <label htmlFor="asthmaDetails" className="form-label">Asthma Details</label>
                            <input type="text" id="asthmaDetails" name="asthmaDetails" className="form-control form-control-sm"  placeholder='Enter Asthma Details' onChange={handleChange} />
                          </div>
                        )}
                       
                        <div className="mb-2 me-2">
                          <img src={creatinineallergy} className="icnn" alt="" />
                          <label htmlFor="Code" className="form-label">Creatinine(For Contrast Studies)<span className="starMandatory"></span></label>
                          <input id="creatinine" type="number" className="form-control form-control-sm" name="creatinine" placeholder='Enter Creatinine' onChange={handleChange} />
                          <small id='errcretanine' className='form-text text-danger' style={{ display: 'none' }}></small>
                        </div>
                        <div className="mb-2 me-2">
                          <img src={creatinineallergy} className="icnn" alt="" />
                          <label htmlFor="Code" className="form-label">Serum Urea(For Contrast Studies)<span className="starMandatory"></span></label>
                          <input id="serumUrea" type="number" className="form-control form-control-sm" name="serumUrea" placeholder='Enter Urea' onChange={handleChange} />
                          <small id='errserumUrea' className='form-text text-danger' style={{ display: 'none' }}></small>
                        </div>
                        <div className={`mb-2 me-2 ${patientData && patientData.gender == "Male" ? 'd-none' : 'd-block'}`}>
                          <div><label htmlFor="Code" className="form-label mt-2 ">Pregnancy<span className="starMandatory"></span></label></div>
                          <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                            <div className="d-flex column-gap-3 mt-2 me-3">
                              <label htmlFor="canRead" className="form-label">Yes<span className="starMandatory"></span></label>
                              <div className="form-check">
                                <input name="pregnancy" id='pregnancy' value="1" className="form-check-input" type="checkbox"
                                  checked={selectedPregnancy === '1'}
                                  onChange={handlePregnancyCheckboxChange} />
                              </div>

                            </div>

                            <div className="d-flex column-gap-3 mt-2 me-3">
                              <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                              <div className="form-check">
                                <input name="pregnancyNo" id='pregnancyNo' value="0" className="form-check-input" type="checkbox"
                                  checked={selectedPregnancy === '0'}
                                  onChange={handlePregnancyCheckboxChange} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>




              </div>

              {/* <div className="med-box">

                <div className="inner-content">
                  <div className="row">
                    <div className='fieldsett-in col-12'>
                      <div className='fieldsett'>
                        <span className='fieldse'>Do you have any of these?(to be field by patient)</span>
                        <div className='row px-2'>




                          <div className="col-sm-2">
                            <div><label htmlFor="Code" className="form-label mt-2 ">Pacemaker<span className="starMandatory"></span></label></div>
                            <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="pacemaker" id='yes' value="Yes" className="form-check-input" type="checkbox"
                                    checked={selectedPaceMaker === 'Yes'}
                                    onChange={handlePaceMakerCheckboxChange} />
                                </div>

                              </div>

                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="pacemaker" id='no' value="No" className="form-check-input" type="checkbox"
                                    checked={selectedPaceMaker === 'No'}
                                    onChange={handlePaceMakerCheckboxChange} />
                                </div>

                              </div>







                            </div>
                          </div >

                          <div className="col-sm-2">
                            <div><label htmlFor="Code" className="form-label mt-2 ">Ear Cochlear implant<span className="starMandatory"></span></label></div>
                            <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="Cochlear" id='yes' value="Yes" className="form-check-input" type="checkbox"
                                    checked={selectedEarCochlear === 'Yes'}
                                    onChange={handleEarCochlearCheckboxChange} />
                                </div>

                              </div>

                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="Cochlear" id='no' value="No" className="form-check-input" type="checkbox"
                                    checked={selectedEarCochlear === 'No'}
                                    onChange={handleEarCochlearCheckboxChange} />
                                </div>

                              </div>


                            </div>
                          </div >

                          <div className="col-sm-2">
                            <div><label htmlFor="Code" className="form-label mt-2 ">Brain Aneurysm Clip<span className="starMandatory"></span></label></div>
                            <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="deformity" id='yes' value="Yes" className="form-check-input" type="checkbox"
                                    checked={selectedBrain === 'Yes'}
                                    onChange={handleBrainCheckboxChange} />
                                </div>

                              </div>

                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="deformity" id='no' value="No" className="form-check-input" type="checkbox"
                                    checked={selectedBrain === 'No'}
                                    onChange={handleBrainCheckboxChange} />
                                </div>

                              </div>







                            </div>
                          </div >

                          <div className="col-sm-2">
                            <div><label htmlFor="Code" className="form-label mt-2 ">Metal Implant in eyes:<span className="starMandatory"></span></label></div>
                            <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="swelling" id='yes' value="Yes" className="form-check-input" type="checkbox"
                                    checked={selectedMetalEyes === 'Yes'}
                                    onChange={handleMetalEyesCheckboxChange} />
                                </div>

                              </div>

                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="swelling" id='no' value="No" className="form-check-input" type="checkbox"
                                    checked={selectedMetalEyes === 'No'}
                                    onChange={handleMetalEyesCheckboxChange} />
                                </div>

                              </div>


                            </div>
                          </div >
                          <div className="col-sm-2">
                            <div><label htmlFor="Code" className="form-label mt-2 ">Metal Implants Fregments:<span className="starMandatory"></span></label></div>
                            <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="Condition" id='yes' value="Yes" className="form-check-input" type="checkbox"
                                    checked={selectedMetalFreg === 'Yes'}
                                    onChange={handleMetalFregCheckboxChange} />
                                </div>

                              </div>

                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="Condition" id='no' value="No" className="form-check-input" type="checkbox"
                                    checked={selectedMetalFreg === 'No'}
                                    onChange={handleMetalFregCheckboxChange} />
                                </div>

                              </div>

                            </div>
                          </div >

                          <div className="col-sm-2">
                            <div><label htmlFor="Code" className="form-label mt-2 ">Reaction to MRI Contrast<span className="starMandatory"></span></label></div>
                            <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="Condition" id='yes' value="Yes" className="form-check-input" type="checkbox"
                                    checked={selectedReactionMri === 'Yes'}
                                    onChange={handleReactionMriCheckboxChange} />
                                </div>

                              </div>

                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="Condition" id='no' value="No" className="form-check-input" type="checkbox"
                                    checked={selectedReactionMri === 'No'}
                                    onChange={handleReactionMriCheckboxChange} />
                                </div>

                              </div>







                            </div>
                          </div >

                          <div className="col-sm-2">
                            <div><label htmlFor="Code" className="form-label mt-2 ">Other Metal Objects<span className="starMandatory"></span></label></div>
                            <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="Condition" id='yes' value="Yes" className="form-check-input" type="checkbox"
                                    checked={selectedOtherMetal === 'Yes'}
                                    onChange={handleOtherMetalCheckboxChange} />
                                </div>

                              </div>

                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="Condition" id='no' value="No" className="form-check-input" type="checkbox"
                                    checked={selectedOtherMetal === 'No'}
                                    onChange={handleOtherMetalCheckboxChange} />
                                </div>

                              </div>

                            </div>
                          </div >

                          <div className="col-sm-2">
                            <div><label htmlFor="Code" className="form-label mt-2 ">Implanted Electrical Device<span className="starMandatory"></span></label></div>
                            <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="Condition" id='yes' value="Yes" className="form-check-input" type="checkbox"
                                    checked={selectedImplantedElect === 'Yes'}
                                    onChange={handleImplantedElectCheckboxChange} />
                                </div>

                              </div>

                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="Condition" id='no' value="No" className="form-check-input" type="checkbox"
                                    checked={selectedImplantedElect === 'No'}
                                    onChange={handleImplantedElectCheckboxChange} />
                                </div>

                              </div>







                            </div>
                          </div >

                          <div className="col-sm-2">
                            <div><label htmlFor="Code" className="form-label mt-2 ">Neurostimulator<span className="starMandatory"></span></label></div>
                            <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="Condition" id='yes' value="Yes" className="form-check-input" type="checkbox"
                                    checked={selectedNeurostimulator === 'Yes'}
                                    onChange={handleNeurostimulatorCheckboxChange} />
                                </div>

                              </div>

                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="Condition" id='no' value="No" className="form-check-input" type="checkbox"
                                    checked={selectedNeurostimulator === 'No'}
                                    onChange={handleNeurostimulatorCheckboxChange} />
                                </div>

                              </div>







                            </div>
                          </div >

                          <div className="col-sm-2">
                            <div><label htmlFor="Code" className="form-label mt-2 ">Stents (Cardiac)<span className="starMandatory"></span></label></div>
                            <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="Condition" id='yes' value="Yes" className="form-check-input" type="checkbox"
                                    checked={selectedStents === 'Yes'}
                                    onChange={handleStentsCheckboxChange} />
                                </div>

                              </div>

                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="Condition" id='no' value="No" className="form-check-input" type="checkbox"
                                    checked={selectedStents === 'No'}
                                    onChange={handleStentsCheckboxChange} />
                                </div>

                              </div>







                            </div>
                          </div >

                          <div className="col-sm-2">
                            <div><label htmlFor="Code" className="form-label mt-2 ">Magnetic Dental Implant<span className="starMandatory"></span></label></div>
                            <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="Condition" id='yes' value="Yes" className="form-check-input" type="checkbox"
                                    checked={selectedMagneticDental === 'Yes'}
                                    onChange={handleMagneticDentalCheckboxChange} />
                                </div>

                              </div>

                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="Condition" id='no' value="No" className="form-check-input" type="checkbox"
                                    checked={selectedMagneticDental === 'No'}
                                    onChange={handleMagneticDentalCheckboxChange} />
                                </div>

                              </div>
                            </div>
                          </div >

                          <div className="col-sm-2">
                            <div><label htmlFor="Code" className="form-label mt-2 ">CT (MRI) /CT MRI<span className="starMandatory"></span></label></div>
                            <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="Condition" id='yes' value="Yes" className="form-check-input" type="checkbox"
                                    checked={selectedContrastCT === 'Yes'}
                                    onChange={handleContrastCTCheckboxChange} />
                                </div>

                              </div>

                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="Condition" id='no' value="No" className="form-check-input" type="checkbox"
                                    checked={selectedContrastCT === 'No'}
                                    onChange={handleContrastCTCheckboxChange} />
                                </div>
                              </div>
                            </div>
                          </div >
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div> */}

              <div className="fieldsett-in mt-2">
                <div className="fieldsett">
                  <span className='fieldse'>{t("Do you have any of these?(to be filled by patient)")}</span>
                  <div className="inner-content mt-2">
                    <div className="d-flex flex-wrap align-content-end gap-3">

                      {questionList && questionList.map(question => (
                        <div key={question.id} className="mb-2 me-2 restraintChk-in clrchk">
                          <label htmlFor="" className="form-label">{question.questionText}</label>
                          <div className='monit-checkbox checklabel'>
                            {optionList && optionList
                              .filter(option => option.questionMasterID === question.id)
                              .map(option => (
                                <div key={option.id} className="risk-factors-check regularCheck">
                                  <input
                                    type="checkbox"
                                    className="me-2 custom-checkbox form-check-input"
                                    id={"optType" + question.id + option.id + '' + option.optionText}
                                    onClick={() => handleCheckboxChange(question.id, option)}
                                  />
                                  <label htmlFor={"optType" + option.optionText}>{option.optionText}</label>
                                </div>
                              ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                </div>
              </div>

              {/* <div className="med-box">

                <div className="inner-content">
                  <div className="row">
                    <div className='fieldsett-in col-12'>
                      <div className='fieldsett'>
                        <span className='fieldse'>Are You Carrying these?</span>
                        <div className='row px-2'>
                          <div className="col-sm-2">
                            <div><label htmlFor="Code" className="form-label mt-2 ">Previous MRI<span className="starMandatory"></span></label></div>
                            <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">
                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="pacemaker" id='yes' value="Yes" className="form-check-input" type="checkbox"
                                    checked={selectedPrevMRI === 'Yes'}
                                    onChange={handlePrevMRICheckboxChange} />
                                </div>

                              </div>

                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="pacemaker" id='no' value="No" className="form-check-input" type="checkbox"
                                    checked={selectedPrevMRI === 'No'}
                                    onChange={handlePrevMRICheckboxChange} />
                                </div>
                              </div>
                            </div>
                          </div >

                          <div className="col-sm-2">
                            <div><label htmlFor="Code" className="form-label mt-2 ">Previous CT<span className="starMandatory"></span></label></div>
                            <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="pacemaker" id='yes' value="Yes" className="form-check-input" type="checkbox"
                                    checked={selectedPrevCT === 'Yes'}
                                    onChange={handlePrevCTCheckboxChange} />
                                </div>

                              </div>

                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="pacemaker" id='no' value="No" className="form-check-input" type="checkbox"
                                    checked={selectedPrevCT === 'No'}
                                    onChange={handlePrevCTCheckboxChange} />
                                </div>
                              </div>
                            </div>
                          </div >

                          <div className="col-sm-2">
                            <div><label htmlFor="Code" className="form-label mt-2 ">Previous X-Ray<span className="starMandatory"></span></label></div>
                            <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="pacemaker" id='yes' value="Yes" className="form-check-input" type="checkbox"
                                    checked={selectedPrevXRay === 'Yes'}
                                    onChange={handlePrevXRayCheckboxChange} />
                                </div>
                              </div>

                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="pacemaker" id='no' value="No" className="form-check-input" type="checkbox"
                                    checked={selectedPrevXRay === 'No'}
                                    onChange={handlePrevXRayCheckboxChange} />
                                </div>
                              </div>
                            </div>
                          </div >

                          <div className="col-sm-2">
                            <div><label htmlFor="Code" className="form-label mt-2 ">Previous Ultrasound<span className="starMandatory"></span></label></div>
                            <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="pacemaker" id='yes' value="Yes" className="form-check-input" type="checkbox"
                                    checked={selectedPrevUltra === 'Yes'}
                                    onChange={handlePrevUltraCheckboxChange} />
                                </div>

                              </div>

                              <div className="d-flex column-gap-3 mt-2 me-3">
                                <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                <div className="form-check">
                                  <input name="pacemaker" id='no' value="No" className="form-check-input" type="checkbox"
                                    checked={selectedPrevUltra === 'No'}
                                    onChange={handlePrevUltraCheckboxChange} />
                                </div>

                              </div>



                            </div>
                          </div >

                        </div>

                      </div>
                    </div>

                  </div>

                </div>
              </div > */}
            </div>



            {/* ----------------------------New Line Patient Details---------------------------------- */}







            {/* <div className="col-12 mt-1">
              <div className="med-box">

                <div className="inner-content">
                  <div className='fieldsett-in col-md-12'>
                    <div className='fieldsett'>
                      <div className="row">
                        <label htmlFor="Code" className="form-label">Are You Carrying these ?<span className="starMandatory"></span></label>
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                          <div className=" col-xxl-12 col-xl-12 col-lg-12 col-md-12 d-flex justify-content-between flex-wrap pe-4">
                            <div className="risk-factors-check regularCheck">
                              <input type="checkbox" id='yes' value="Yes" className="me-2 custom-checkbox form-check-input" checked={selectedPrevMRI === 'Yes'}
                                onChange={handlePrevMRICheckboxChange} />Previous MRI
                            </div>
                            <div className="risk-factors-check regularCheck">
                              <input type="checkbox" id='yes' value="Yes" className="me-2 custom-checkbox form-check-input" checked={selectedPrevCT === 'Yes'}
                                onChange={handlePrevCTCheckboxChange} />Previous CT
                            </div>
                            <div className="risk-factors-check regularCheck">
                              <input type="checkbox" id='yes' value="Yes" className="me-2 custom-checkbox form-check-input" checked={selectedPrevXRay === 'Yes'}
                                onChange={handlePrevXRayCheckboxChange} />Previous X-Ray
                            </div>
                            <div className="risk-factors-check regularCheck">
                              <input type="checkbox" id='yes' value="Yes" className="me-2 custom-checkbox form-check-input" checked={selectedPrevUltra === 'Yes'}
                                onChange={handlePrevUltraCheckboxChange} />Previous Ultrasound
                            </div>


                          </div>



                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}




            <div className="col-12 ">
              <div className="med-box mt-2">
                <div className="inner-content">
                  <div className="row">

                    <div className="inner-content text-right">
                      <div className="mb-2 mt-2 relative">
                        <div>
                          <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1_ me-1" onClick={handleSaveMriQestionaire}><img src={saveButtonIcon} className="icnn" alt='' />Save</button>
                          <button type="button" className="btn btn-clear btn-sm mb-1_ me-1" onClick={resetForm}><img alt='' />Clear</button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="col-12 mt-3">
          <div className="med-table-section" style={{ "height": "80vh" }}>
            <table className="med-table border striped">
              <thead style={{ zIndex: '0' }}>
                <tr>
                  <th className="text-center" style={{ "width": "5%" }}>#</th>
                  <th>Tentative Date & Time Of Appointment</th>
                  <th>Pregnancy</th>
                  <th>Drug Allergy(if any)</th>
                  <th>Asthama</th>
                  <th>Serum Urea</th>
                  <th>Creatinine</th>
                  <th>MRI Questionare Answer</th>

                  <th style={{ "width": "10%" }} className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {patientMriData && patientMriData.map((data, index) => {

                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{formatDateTime(data.tentativeDateTime)}</td>
                      <td>{data.isPregnent}</td>
                      <td>{data.allergies}</td>
                      <td> {data.isAsthama === 1 ? "Yes" : "No"},{data.asthama}</td>
                      <td>{data.urea}</td>
                      <td>{data.creatinine}</td>


                      {/* {/* <td>
                        {diagnosisData && diagnosisData[index].map((val, index) => (
                          index > 0 ? `, ${val.details}` : val.details
                        ))}

                      </td> */}

                      {/* <td>
                        {data.answers[index].map((val, index) => (
                          index > 0 ? `, ${val.questionName} - ${val.optionText}` : `${val.questionName} - ${val.optionText}`
                        ))}
                      </td> */}

                      <td>
                        {data.answers.map((val, index) => (
                          <span key={index}>{val.questionName} - {val.optionText}
                            {index < data.answers.length - 1 && <br />}
                          </span>
                        ))}
                      </td>




                      {/* <td>

                        Qestion: {mriData && mriData[index].questionName} , Answer: {mriData && mriData[index].optionText}

                       


                      </td> */}








                      <td>

                        <div className="action-button">
                          <div data-bs-title="Delete Row" data-bs-placement="bottom"  ><img src={imgPrint} style={{ width: '20px' }} className='' alt='' onClick={() => handlePrint(data, index)} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        {
          showAlertToster === 1 ? <AlertToster message={showMessage} handle={setShowAlertToster} /> : ""
        }
        {
          showSuccessToster === 1 ? <SuccessToster message={showMessage} handle={setShowSuccessToster} /> : ""
        }

      </section>

    </>
  )
}


