import React, { useState, useEffect } from 'react'
import TableContainer from '../../../../src/Component/TableContainer';
import Heading from '../../../../src/Component/Heading';
import BoxContainer from '../../../../src/Component/BoxContainer';
import Toster from '../../../../src/Component/Toster'
import TosterUnderProcess from '../../../../src/Component/ToStartUnderProcess'
import PostLedgerMaster from '../API/PostLedgerMaster'
import PutLedgerMaster from '../API/PutLedgerMaster'
import DeleteLedgerMaster from '../API/DeleteLedgerMaster'
import ValidationLedgerMaster from '../../../../src/Validation/Pharmacy/ValidationLedgerMaster'
import GetLedgerMaster from '../API/GetLedgerMaster';
import IconEdit from '../../../assets/images/icons/IconEdit.svg'
import IconDelete from '../../../assets/images/icons/IconDelete.svg'
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import clearIcon from '../../../assets/images/icons/clear.svg';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';


export default function LedgerMaster() {

  let [ledgerList, setLedgerList] = useState()
  let [updateBool, setUpdateBool] = useState(0)
  let [sendForm, setSendForm] = useState({ "userId": window.userId })
  let [loder, setLoder] = useState(1)
  let [rowId, setRowId] = useState('');

  let [showUnderProcess, setShowUnderProcess] = useState(0)
  let [showToster, setShowToster] = useState(0)
  let [tosterMessage, setTosterMessage] = useState("")
  let [tosterValue, setTosterValue] = useState(0)
  const { t } = useTranslation();
  const [newlyAddedRowIndex, setNewlyAddedRowIndex] = useState(null);
  const [editRowIndex, setEditRowIndex] = useState(null);

  //Handle Save
  let saveForm = async () => {
    let valresponse = ValidationLedgerMaster(sendForm.ledgerName)

    if (valresponse) {
      setShowUnderProcess(1)
      let response = await PostLedgerMaster(sendForm);
      console.log("sendForm", sendForm)
      if (response.status === 1) {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage("Data Save Successfully!")
        setTosterValue(0)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
        setNewlyAddedRowIndex(0);
        handleClear();
      }
      else {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage(response.responseValue)
        setTosterValue(1)
        setTimeout(() => {
          setShowToster(0)
          setNewlyAddedRowIndex(null);
        }, 2000)
      }
      getdata()
    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage("Field can't be blank!")
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
    }
  }

  //Get data
  let getdata = async () => {
    let getResponse = await GetLedgerMaster();
    console.log("getResponse", getResponse)

    if (getResponse.status === 1) {
      // setLoder(0)
      setLedgerList(getResponse.responseValue)
    }

  }
  //Handle Change
  let handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setSendForm(sendForm => ({
      ...sendForm,
      [name]: value
    }))
  }


  //Handle Delete
  let handleDeleteRow = async () => {
    // setLoder(1)
    setShowUnderProcess(1)
    let obj = {
      id: rowId
    }
    let response = await DeleteLedgerMaster(obj)
    if (response.status === 1) {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage("Data Deleted Successfully!")
      handleClear();
      setUpdateBool(0)
      setTosterValue(0)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
      getdata()
    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage(response.responseValue)
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
    }
  }

  //Handle Button Change
  let handleUpdate = async (id, ledgerName, code, userId, ind) => {

    setUpdateBool(1)
    setSendForm(sendForm => ({
      ...sendForm,
      "id": id,
      "ledgerName": ledgerName,
      'code': code,
      "userId": userId

    }))

    document.getElementById("ledgerName").value = ledgerName;
    document.getElementById("code").value = code;
    setNewlyAddedRowIndex(ind)
  }



  // Handle Update
  let saveUpdate = async () => {
    let valresponse = ValidationLedgerMaster(sendForm.ledgerName)
    console.log("valresponse", valresponse);
    if (valresponse) {
      setShowUnderProcess(1)
      let response = await PutLedgerMaster(sendForm)
      if (response.status === 1) {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage("Data Updated Successfully!")
        setTosterValue(0)
        setTimeout(() => {
          setShowToster(0)
          setNewlyAddedRowIndex(null);
        }, 2000)

        setUpdateBool(0)
        getdata()
        handleClear();
      }
      else {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage(response.responseValue)
        setTosterValue(1)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
      }

    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage("Field can't be blank!")
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
    }
  }

  //Handle Clear
  let handleClear = () => {
    setSendForm({ "userId": window.userId })
    document.getElementById("ledgerName").value = "";
    document.getElementById("code").value = "";
    setUpdateBool(0)
    setNewlyAddedRowIndex(null);
  }
  useEffect(() => {
    getdata()
  }, [])
  document.body.dir = i18n.dir();
  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="med-box">
                <div className="inner-content">

                  <div className="row">
                    <div className="fieldsett-in">
                      <div className='fieldsett'>
                        <span className="fieldse">Ledger Master</span>
                        <div className="row mt-2 px-2">

                          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-2 me-2">
                            <label htmlFor="ledgerName" className="form-label">{t("Ledger Name")}<span className="starMandatory">*</span></label>
                            <input type='text' className='form-control form-control-sm' id='ledgerName' name='ledgerName' onChange={handleChange} placeholder={t("Enter Ledger Name")} />
                          </div>

                          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-2 me-2">
                            <label htmlFor="code" className="form-label">{t("Code")}<span className="starMandatory"></span></label>
                            <input type='text' className='form-control form-control-sm' id='code' name='code' onChange={handleChange} placeholder={t("Enter Code")} />
                          </div>

                          <div className="d-flex justify-content-end mt-4 mb-2 relative">
                            <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                            <div>
                              {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                <>
                                  {showToster === 1 ?
                                    <Toster value={tosterValue} message={tosterMessage} />

                                    : <div>
                                      {updateBool === 0 ?
                                        <>
                                          <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={saveForm}><img src={saveButtonIcon} className='icnn' />{t("Save")}</button>
                                          <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' />{t("Clear")}</button>
                                        </>
                                        :
                                        <>
                                          <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={saveUpdate}>{t("Update")}</button>
                                          <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { setUpdateBool(0); handleClear() }}>{t("Cancel")}</button>
                                        </>
                                      }
                                    </div>}
                                </>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>




            </div>
            <div className="col-12 mt-2">
              <Heading text={t("Ledger List")} />
              <div className="med-table-section" style={{ "height": "75vh" }}>
                <TableContainer>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>{t("Ledger Name")}</th>
                      <th>{t("Code")}</th>
                      <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {ledgerList && ledgerList.map((key, ind) => {
                      const isNewRow = newlyAddedRowIndex === ind;
                      const isEditing = ind === editRowIndex;
                      return (
                        <tr className={isNewRow ? 'new-row' : ''} value={key}>
                          <td className="text-center">{ind + 1}</td>
                          <td>{key.ledgerName}</td>
                          <td>{key.code}</td>
                          <td>
                            <div className="action-button">
                              <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={IconEdit} className={isEditing ? 'edited-row' : ''} onClick={() => { handleUpdate(key.id, key.ledgerName, key.code, key.userId, ind) }} alt='' /></div>
                              <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={IconDelete} onClick={() => { setRowId(key.id) }} alt='' /></div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}


                  </tbody>
                </TableContainer>
                {/* -----------------------Start Delete Modal Popup-------------------   */}

                {/*  <!-- Modal -->  */}
                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                  <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                      <div className="modal-body modelbdy text-center">
                        <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                        <div className='popDeleteTitle mt-3'>{t("Delete?")}</div>
                        <div className='popDeleteContent'> {t("Are_you_sure_you_want_to_delete?")}</div>
                      </div>
                      <div className="modal-footer1 text-center">

                        <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                        <button type="button" className="btn-delete popBtnDelete" onClick={handleDeleteRow} data-bs-dismiss="modal">{t("Delete")}</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  )
}
