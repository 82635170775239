

export default async function GetLanguageData(){    
   
    let URL = window.SendXMLDatabaseUl + `/api/LanguageMaster/GetAllLanguageMaster`;  
   let head = {"Content-Type":"application/json", 'accept':'*/*'};
   
   let response = "";
   response = await fetch(URL, {
       headers:head,
       method:"GET"        
   })
   .then(res => res.json())  
   .catch(error => response=error);    
   return response;
}