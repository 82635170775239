let GetBloodStock = async(bloodGroup) => {
    if(bloodGroup == ''){
          const url =  window.BloodbaseUrl + `api/GetStock/GetAllStock`;
    const head = {'content-type':'application/json','accept':'*/*',}
    let data = {};
    let response = await fetch(url,{
      method: "GET",
      header: head,
  
    }).then(res => res.json()).then(data);
    return response;
  }
  else{
    const url =  window.BloodbaseUrl + `api/GetStock/GetAllStock?bloodGroupID=${bloodGroup}`;
    const head = {'content-type':'application/json','accept':'*/*',}
    let data = {};
    let response = await fetch(url,{
      method: "GET",
      header: head,
  
    }).then(res => res.json()).then(data);
    return response;
  }

    }
    export default  GetBloodStock;