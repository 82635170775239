import React, { useState } from 'react'
import { useEffect } from 'react';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import Toster from '../../../Component/Toster';
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Heading from '../../../Component/Heading';
import BoxContainer from '../../../Component/BoxContainer';
import TableContainer from '../../../Component/TableContainer';
import SuccessToster from '../../../Component/SuccessToster';
import AlertToster from '../../../Component/AlertToster';
import Loader from '../../../Component/Loader';
import { t } from 'i18next';
import Search from '../../../Code/Serach';
import GetWorkFlowMaster from '../../Api/WorkFlow/WorkFlowMaster/GetWorkFlowMaster';
import PostWorkFlowMaster from '../../Api/WorkFlow/WorkFlowMaster/PostWorkFlowMaster';
import PutWorkFlowMaster from '../../Api/WorkFlow/WorkFlowMaster/PutWorkFlowMaster';
import DeleteWorkFlowMaster from '../../Api/WorkFlow/WorkFlowMaster/DeleteWorkFlowMaster';

export default function WorkFlowMaster() {
    let [workFlowList, setWorkFlowList] = useState("")
    let [workFlowListMain, setWorkFlowListMain] = useState("")
    let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [showToster, setShowToster] = useState(0);
    let [tosterMessage, setTosterMessage] = useState("");
    let [tosterValue, setTosterValue] = useState(0);
    let [showLoder, setShowLoder] = useState(0);
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [updateBool, setUpdateBool] = useState(0);
    let [rowId, setRowId] = useState(0);
    const userID = JSON.parse(window.sessionStorage.getItem("LoginData")).userId;
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    let [sendForm, setSendForm] = useState({
        "userId": userID,
        "clientId": clientID,
        "workFlow": "",
        "workFlowType": -1,
        "tatSettingJson": ""
    })
    // const [tatRow, setTatRow] = useState([{ rowID: 1, },]);
    const [tatRow, setTatRow] = useState([{ rowID: 1 }]);




    // const handleAddTatRow = (param) => {
    //     let tempArr = [...tatRow];
    //     tempArr.push({
    //         rowID: param + 1,
    //     });

    //     setTatRow(tempArr);
    // }

    const handleAddTatRow = (param) => {
        let tempArr = [...tatRow];
        const lastRow = tempArr[tempArr.length - 1];
        const workFlowUrgencyLevelID = document.getElementById('workFlowUrgencyLevelID' + lastRow.rowID)?.value;
        const tatValue = document.getElementById('tatValue' + lastRow.rowID)?.value;
        const errurgencyElement = document.getElementById('errurgency' + lastRow.rowID);
        const errTatElement = document.getElementById('errTat' + lastRow.rowID);

        if (!workFlowUrgencyLevelID || workFlowUrgencyLevelID === "0") {
            if (errurgencyElement) {
                errurgencyElement.style.display = 'block';
            }
            return;
        } else {
            if (errurgencyElement) {
                errurgencyElement.style.display = 'none';
            }
        }

        if (!tatValue) {
            if (errTatElement) {
                errTatElement.style.display = 'block';
            }
            return;
        } else {
            if (errTatElement) {
                errTatElement.style.display = 'none';
            }
        }

        tempArr.push({
            rowID: param + 1,
        });
        setTatRow(tempArr);
    };



    const handleDeleteTatRow = (index, key) => {
        console.log('array', index, '->', tatRow);
        const data = [...tatRow];
        let tempArr = [];
        //const data = [...tatRow];
        console.log('data f', data)
        if (data.length === 1) {
            return;
        }

        data.splice(index, 1);
        for (let i = 0; i < data.length; i++) {
            const workFlowUrgencyLevelID = document.getElementById('workFlowUrgencyLevelID' + data[i].rowID).value;
            const tatValue = document.getElementById('tatValue' + data[i].rowID).value;
            console.log('workFlowUrgencyLevelID : ', workFlowUrgencyLevelID)
            console.log('tatValue : ', tatValue)
            tempArr.push({
                rowID: data[i].rowID,
                workFlowUrgencyLevelID: workFlowUrgencyLevelID,
                tatValue: tatValue,
            });

        }
        setTatRow(data);
        setTimeout(() => {
            for (let i = 0; i < tempArr.length; i++) {
                document.getElementById('workFlowUrgencyLevelID' + tempArr[i].rowID).value = tempArr[i].workFlowUrgencyLevelID;
                document.getElementById('tatValue' + tempArr[i].rowID).value = tempArr[i].tatValue;
            }
        }, 500);
        console.log('data s', tempArr)
    }


    //Handle Change
    let handleChange = (e) => {
        clearValidationErrMessage();
        const { name, value, checked } = e.target;

        setSendForm((prevData) => ({
            ...prevData,
            [name]: value,
            userId: userID,
        }));
    };

    //Get Data
    const getdata = async () => {
        // setShowLoder(1);
        const response = await GetWorkFlowMaster(clientID);
        if (response.status === 1) {
            setWorkFlowList(response.responseValue);
            setWorkFlowListMain(response.responseValue)
            setShowLoder(0)
        }
        else {
            setShowLoder(0);
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }


    //handle search
    let handleSearch = (e) => {
        let resp = Search(workFlowListMain, e.target.value)
        if (e.target !== "") {
            if (resp.length !== 0) {
                setWorkFlowList(resp)
            }
            else {
                setWorkFlowList([])
            }
        }
        else {
            setWorkFlowList(workFlowListMain)
        }
    }
    const handleRowData = () => {
        return tatRow.map((row) => {
            const workFlowUrgencyLevelID = document.getElementById('workFlowUrgencyLevelID' + row.rowID).value;
            const tatValue = document.getElementById('tatValue' + row.rowID).value;
            return {
                rowID: row.rowID,
                workFlowUrgencyLevelID,
                tatValue,
            };
        });
    };

    //Handle Save
    const handlerSave = async () => {
        if (sendForm.workFlow === '' || sendForm.workFlow === null || sendForm.workFlow === undefined) {
            document.getElementById('errWorkFlow').innerHTML = "Work flow is required";
            document.getElementById('errWorkFlow').style.display = "block";
        }
        else if (sendForm.workFlowType === -1 || sendForm.workFlowType === null || sendForm.workFlowType === undefined) {
            document.getElementById('errworkFlowType').innerHTML = "Select work flow type is required";
            document.getElementById('errworkFlowType').style.display = "block";
        }

        else {
            setShowUnderProcess(1);
            const tatJsonData = JSON.stringify(handleRowData());
            const obj = {
                "workFlow": sendForm.workFlow,
                "workFlowType": sendForm.workFlowType,
                "tatSettingJson": tatJsonData,
                "userId": userID,
                "clientId": clientID
            }
            // console.log("obj", obj)
            //  return;
            const response = await PostWorkFlowMaster(obj);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Data saved successfully.");
                setTimeout(() => {
                    setShowToster(0);
                    handleClear();
                    getdata();
                }, 1500)
            }
            else {
                setShowUnderProcess(0);
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTimeout(() => {
                    setShowToster(0);
                }, 1500)
            }
        }
    }

    //Handle Button Change     
    const handleUpdate = async (id, workFlow, workFlowType, list) => {

        console.log('listttt : ', list)
        setUpdateBool(1);
        clearValidationErrMessage();
        const workflowToUpdate = workFlowList.find(workflow => workflow.id === id);
        console.log(' workflowToUpdate  : ', workflowToUpdate)
        setSendForm(prevSendForm => ({
            ...prevSendForm,
            id: id,
            workFlow: workFlow,
            workFlowType: workFlowType,
            userId: userID,
            clientId: clientID,
        }));

        if (workflowToUpdate && workflowToUpdate.jsonTatSetting) {
            console.log('workflowToUpdate.jsonTatSetting : ', JSON.parse(workflowToUpdate.jsonTatSetting))
            const parsedTatSetting = JSON.parse(workflowToUpdate.jsonTatSetting).map((tat, index) => ({
                rowID: index + 1,
                workFlowUrgencyLevelID: tat.workFlowUrgencyLevelID,
                tatValue: tat.tatValue
            }));

            setTimeout(() => {
                for (let i = 0; i < parsedTatSetting.length; i++) {
                    document.getElementById('workFlowUrgencyLevelID' + (i + 1)).value = parsedTatSetting[i].workFlowUrgencyLevelID;
                    document.getElementById('tatValue' + (i + 1)).value = parsedTatSetting[i].tatValue;
                }
            }, 100)
            console.log('parsedTatSetting : ', parsedTatSetting)
            setTatRow(parsedTatSetting);
        } else {
            setTatRow([{ rowID: 1 }]);
        }
    };

    const handleupdateRowData = () => {
        return tatRow.map((row) => {
            const workFlowUrgencyLevelID = document.getElementById('workFlowUrgencyLevelID' + row.rowID).value;
            const tatValue = document.getElementById('tatValue' + row.rowID).value;
            return {
                workFlowID: row.rowID,
                workFlowUrgencyLevelID,
                tatValue,
            };
        });
    };

    //Handle Update
    const handlerUpdate = async () => {
        if (sendForm.workFlow === '' || sendForm.workFlow === null || sendForm.workFlow === undefined) {
            document.getElementById('errWorkFlow').innerHTML = "Work flow is required";
            document.getElementById('errWorkFlow').style.display = "block";
        }
        else if (sendForm.workFlowType === -1 || sendForm.workFlowType === null || sendForm.workFlowType === undefined) {
            document.getElementById('errworkFlowType').innerHTML = "Select work flow type is required";
            document.getElementById('errworkFlowType').style.display = "block";
        }
        else {
            setShowUnderProcess(1);
            const tatupdateJsonData = JSON.stringify(handleupdateRowData());
            const objUpdate = {
                "id": sendForm.id,
                "workFlow": sendForm.workFlow,
                "workFlowType": sendForm.workFlowType,
                "tatSettingJson": tatupdateJsonData,
                "userId": userID,
                "clientId": clientID,
            }
            console.log("objUpdate", objUpdate)
            // return;
            const response = await PutWorkFlowMaster(objUpdate);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Updated Successfully.");
                setTimeout(() => {
                    setShowToster(0);
                    handleClear();
                    getdata();

                }, 1500)
            }
            else {
                setShowUnderProcess(0);
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTimeout(() => {
                    setShowToster(0);
                }, 1500)
            }
        }
    }

    //Handle Delete
    const handleDelete = async () => {
        setShowLoder(1);
        let obj = {
            id: rowId
        }
        const response = await DeleteWorkFlowMaster(obj);
        if (response.status === 1) {
            setShowLoder(0)
            setisShowToaster(1);
            setShowSuccessMsg("Deleted Successfully.")
            setTimeout(() => {
                setisShowToaster(0);
                getdata();
            }, 1500)
            handleClear();
        }
        else {
            setShowLoder(0);
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }

    //Clear Error Message
    const clearValidationErrMessage = () => {
        document.getElementById('errWorkFlow').style.display = "none";
        document.getElementById('errworkFlowType').style.display = "none";
    }

    //Handle Clear
    const handleClear = (value) => {
        clearValidationErrMessage();
        setUpdateBool(0);
        setSendForm({
            "userId": userID,
            "clientId": clientID,
            "workFlow": '',
            "workFlowType": -1
        })
        tatRow.forEach(row => {
            document.getElementById('workFlowUrgencyLevelID' + row.rowID).value = 0;
            document.getElementById('tatValue' + row.rowID).value = "";
            document.getElementById('errurgency' + row.rowID).style.display = "none";
            document.getElementById('errTat' + row.rowID).style.display = "none";
        });
        setTatRow([{ rowID: 1, },]);
    }

    useEffect(() => {
        getdata();
    }, []);

    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div div className="med-box">
                                <Heading text="Work Flow Master" />
                                <div className="inner-content">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label htmlFor="workFlow" className="form-label">{t("Work Flow")} <span className="starMandatory">*</span></label>
                                            <input type="text" className="form-control form-control-sm" id="workFlow" name='workFlow' onChange={handleChange} value={sendForm.workFlow} placeholder={t("Enter work flow")} />
                                            <small id="errWorkFlow" className="invalid-feedback" style={{ display: 'none' }}></small>
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="workFlowType" className="form-label">{t("Work Flow Type")} <span className="starMandatory">*</span></label>
                                            <select className='form-select form-select-sm' id='workFlowType' name='workFlowType' value={sendForm.workFlowType} onChange={handleChange}>
                                                <option value={-1}>Select Work Flow Type</option>
                                                <option value={0}>OR</option>
                                                <option value={1}>AND</option>
                                            </select>
                                            <small id="errworkFlowType" className="invalid-feedback" style={{ display: 'none' }}></small>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='quotationrows'>
                                            <div className='supplrcontent mt-2 p-2'
                                            // style={{ maxHeight: '25vh', overflow: 'scroll' }}
                                            >
                                                {tatRow && tatRow.map((val, index) => {
                                                    return (
                                                        <>
                                                            <div className='addnCharge inner-content_ mt-2 mb-2' key={val.rowID + 1}>
                                                                <div className='addnCharge-in'>
                                                                    <span><label htmlFor="workFlowUrgencyLevelID" className="form-label">{t("Work Flow Urgency Level")} <span className="starMandatory"></span></label></span>
                                                                    <span>
                                                                        <select className='form-select form-select-sm' id={'workFlowUrgencyLevelID' + val.rowID} name='workFlowUrgencyLevelID'>
                                                                            <option value={0}>Select Urgency Level</option>
                                                                            <option value={26}>Normal</option>
                                                                            <option value={189}>Urgency</option>
                                                                            <option value={190}>Critical</option>
                                                                        </select>
                                                                        <small id={'errurgency' + val.rowID} className="invalid-feedback" style={{ display: 'none' }}>Please select an Urgency Level.</small>
                                                                    </span>
                                                                </div>
                                                                <div className='addnCharge-in'>
                                                                    <span> <label htmlFor="tatValue" className="form-label">{t("TAT Value (in minute)")} <span className="starMandatory"></span></label></span>
                                                                    <span>
                                                                        <input type="number" className="form-control form-control-sm" id={'tatValue' + val.rowID} name='tatValue' placeholder={t("Enter value")} />
                                                                        <small id={'errTat' + val.rowID} className="invalid-feedback" style={{ display: 'none' }}>Please enter a TAT Value.</small>
                                                                    </span>
                                                                </div>
                                                                <div className='addnCharge-in'>
                                                                    <div className=' action-button cmlBtn' style={{ alignItems: 'baseline', marginTop: '4px' }}>
                                                                        <span>
                                                                            <div>
                                                                                {index === tatRow.length - 1 ? (
                                                                                    <>
                                                                                        <i className="bi bi-trash3" title='Remove row' onClick={() => { handleDeleteTatRow(index, val.rowID) }}></i>&nbsp;
                                                                                        {/* <i className="bi bi-plus" onClick={() => { handleAddTatRow(val.rowID) }} title='Add new row'></i> */}
                                                                                        <i className="bi bi-plus" onClick={() => { handleAddTatRow(tatRow.length > 0 ? tatRow[tatRow.length - 1].rowID : 0) }} title='Add new row'></i>

                                                                                    </>
                                                                                ) : (
                                                                                    <i className="bi bi-trash3" title='Remove row' onClick={() => { handleDeleteTatRow(index, val.rowID) }}></i>
                                                                                )}
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 relative">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                            <div>
                                                {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                                    <>
                                                        {showToster === 1 ?
                                                            <Toster value={tosterValue} message={tosterMessage} />

                                                            : <div>
                                                                {updateBool === 0 ?
                                                                    <>
                                                                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handlerSave}><img src={saveButtonIcon} className='icnn' alt='' />Save</button>
                                                                        <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt='' />Clear</button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={handlerUpdate}>Update</button>
                                                                        <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}>Cancel</button>
                                                                    </>
                                                                }
                                                            </div>}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 mt-2">
                            <div className='handlser'>
                                <Heading text="Work Flow Master List" />
                                <div style={{ position: 'relative' }}>
                                    <input type="text" className='form-control form-control-sm' placeholder={t("Search")} onChange={handleSearch} />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
                            <div className="med-table-section" style={{ "height": "60vh" }}>
                                <TableContainer>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>#</th>
                                            <th>Work Flow</th>
                                            <th className="text-center" >Work Flow Type</th>
                                            <th>Work Flow Urgency Level / TAT Value</th>
                                            <th style={{ "width": "10%" }} className="text-center">Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {workFlowList && workFlowList.length > 0 ? (
                                            workFlowList.map((list, ind) => (
                                                <tr key={list.id}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td>{list.workFlow}</td>
                                                    <td className="text-center" >{list.workFlowType === 1 ? "AND" : "OR"}</td>
                                                    <td>
                                                        {/* {list.jsonTatSetting && JSON.parse(list.jsonTatSetting).map((tat, i) => {
                                                            return (
                                                                <span className='addnldt' key={i}>
                                                                    <strong>Urgency Level/TAT Value:</strong>{tat.workFlowUrgencyLevelID}/{tat.tatValue}<br />
                                                                </span>
                                                            )
                                                        })} */}

                                                        {list.jsonTatSetting && JSON.parse(list.jsonTatSetting).map((tat, i) => {
                                                            let urgencyLevel;
                                                            if (tat.workFlowUrgencyLevelID === 0) {
                                                                return null;
                                                            }
                                                            switch (tat.workFlowUrgencyLevelID) {
                                                                case 26:
                                                                    urgencyLevel = "Normal";
                                                                    break;
                                                                case 189:
                                                                    urgencyLevel = "Urgency";
                                                                    break;
                                                                case 190:
                                                                    urgencyLevel = "Critical";
                                                                    break;
                                                                default:
                                                                    urgencyLevel = "";
                                                                    break;
                                                            }
                                                            return (
                                                                <span className='addnldt' key={i}>

                                                                    {urgencyLevel} / {tat.tatValue}<br />
                                                                </span>
                                                            );
                                                        })}

                                                    </td>
                                                    <td>
                                                        <div className="action-button">
                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom">
                                                                <img src={editBtnIcon} className="" alt="" onClick={() => { handleUpdate(list.id, list.workFlow, list.workFlowType, list); }} />
                                                            </div>
                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal">
                                                                <img src={deleteBtnIcon} className="" alt="" onClick={() => { setRowId(list.id); }} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4" className="text-center">
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>

                                </TableContainer>
                                {/* -----------------------Start Delete Modal Popup-------------------   */}

                                {/*  <!-- Modal -->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">

                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'>Delete?</div>
                                                <div className='popDeleteContent'>Are you sure want to delete?</div>
                                            </div>
                                            <div className="modal-footer1 text-center">

                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={handleDelete} data-bs-dismiss="modal">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

                            </div>
                        </div>
                    </div>
                </div>
                {
                    showLoder === 1 ? <Loader val={showLoder} /> : ""
                }
                {/* Toaster */}
                {
                    isShowToaster === 1 ?
                        <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                }

                {
                    showAlertToster === 1 ?
                        <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                }
            </section>
        </>
    )
}
