import React, { useEffect, useRef, useState } from 'react';
import i18n, { t } from "i18next";
import tblshorting from '../../../assets/images/dmsimg/shorting.png';
import vectorPrint from '../../../assets/images/icons/vectorPrint.png';
import GetAllTrackDemand from './API/GET/GetAllTrackDemand';

import NoDataFound from '../../../assets/images/icons/No data-rafiki.svg';
import TrackDemandItemList from './TrackDemandItemList';
import { useNavigate } from 'react-router-dom';
import Search from '../../../Code/Serach';

import arrowdd from '../../../assets/images/icons/arrowdd.svg'

export default function TrackDemand() {
  // // Get current date
  // const currentDate = new Date();

  // // Format date to YYYY-MM-DD
  // const formattedDate = currentDate.toISOString().split('T')[0];

  const currentDate = new Date();

  const clientID = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
  const navigate = useNavigate();

  const [fromDate, setFromDate] = useState(new Date(currentDate.setDate(currentDate.getDate() - 30)).toISOString().split('T')[0]);
  const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
    const [demandList, setDemandList] = useState([]);
    const [getDemandType, setDemandType] = useState(0);
    const [demandListMain, setDemandListMain] = useState([]);
    const [noDataStatus, setNoDataStatus] = useState(false);
    const [sendID, setSendID] = useState('');

     //Select filter
     const [selected, setSelected] = useState('Last 30 Days');
     const selectBoxListRef = useRef(null);
     const options = ['All', 'Today', 'Last 7 Days', 'Last 30 Days', 'One Year', 'Custom'];

    const handleFromDate = (event) => {

      setFromDate(event.target.value)
      funGetAllDemands(event.target.value, toDate,clientID,getDemandType);
  }
  const handleToDate = (event) => {


      if (event.target.value < fromDate) {
          document.getElementById('errToDate').innerHTML = 'To date cannot be smaller than from date';
          document.getElementById('errToDate').style.display = 'block'
          setToDate(event.target.value);
      }
      else {
          document.getElementById('errToDate').style.display = 'none';
          setToDate(event.target.value);
          funGetAllDemands(fromDate, event.target.value,clientID,getDemandType);
      }
  }

  const handleSelect = (e) =>{
    setDemandType(e.target.value);
    funGetAllDemands(fromDate, toDate,clientID,e.target.value);
  }

    const funGetAllDemands = async(fromDate, toDate,clientID,getDemandType) => {
      const resDemand = await GetAllTrackDemand(fromDate,toDate,clientID,getDemandType);
      if(resDemand.status === 1){
        setDemandList(resDemand.responseValue); setDemandListMain(resDemand.responseValue); setNoDataStatus(false)
      }
      else{
        setNoDataStatus(true);
      }

    }

    // To navigate to a page that shows details of items based on demand number

    const funToNavigatePage = (demandId) => {      
      setSendID(demandId);
      window.sessionStorage.setItem('trackDemandId',demandId);
      navigate('/trackDemandItemList/', { state: { demandID: demandId } });
      
  }

    //select filter
    const calculateDateRange = (option) => {

      let fromDate, toDate;

      switch (option) {
          case 'All':
              fromDate = '1970-01-01'; // Start of Unix epoch
              toDate = new Date().toISOString().split('T')[0];
              break;
          case 'Today':
              fromDate = new Date().toISOString().split('T')[0];
              toDate = fromDate;
              break;
          // case 'Last 7 Days':
          //     fromDate = new Date(currentDate.setDate(currentDate.getDate() - 7)).toISOString().split('T')[0];
          //     toDate = new Date().toISOString().split('T')[0];
          //     break;
          case 'Last 7 Days':
              let sevenDaysAgo = new Date();
              sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
              fromDate = sevenDaysAgo.toISOString().split('T')[0];
              toDate = new Date().toISOString().split('T')[0];
              break;

          case 'Last 30 Days':
              let monthAgo = new Date();
              monthAgo.setDate(monthAgo.getDate() - 30);
              fromDate = monthAgo.toISOString().split('T')[0];
              toDate = new Date().toISOString().split('T')[0];
              // fromDate = new Date(currentDate.setDate(currentDate.getDate() - 30)).toISOString().split('T')[0];
              // toDate = new Date().toISOString().split('T')[0];
              break;
          case 'One Year':
              let yearAgo = new Date();
              yearAgo.setFullYear(yearAgo.getFullYear()-1);
              fromDate = yearAgo.toISOString().split('T')[0];
              toDate = new Date().toISOString().split('T')[0];
              // fromDate = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1)).toISOString().split('T')[0];
              // toDate = new Date().toISOString().split('T')[0];
              break;
          default:
              fromDate = '0';
              toDate = '0';
      }

      return { fromDate, toDate };
  };

  // to select options for filtering
  const handleOptionClick = (option) => {
    setSelected(option);
    selectBoxListRef.current.classList.remove('active');

    if (option !== 'Custom') {
        const { fromDate, toDate } = calculateDateRange(option);
        
        setFromDate(fromDate);
        setToDate(toDate);
        funGetAllDemands(fromDate, toDate, clientID, getDemandType);
    }
};

// to hide the dropdown of select filter when user clicks outside of it.
const toggleDropdown = () => {
  selectBoxListRef.current.classList.toggle('active');
};

const handleClickOutside = (event) => {
  if (selectBoxListRef.current && !selectBoxListRef.current.contains(event.target)) {
      selectBoxListRef.current.classList.remove('active');
  }
};

  // to searchh
let handleSearch = (e) => {
  let resp = Search(demandListMain, e.target.value)
  if (e.target !== "") {
      if (resp.length !== 0) {
        setDemandList(resp)
      }
      else {
        setDemandList([])
      }
  }
  else {
    setDemandList(demandListMain)
  }
}

    useEffect(() => {
      funGetAllDemands(fromDate, toDate,clientID,getDemandType);

      //select filter
      document.addEventListener('click', handleClickOutside);
      return () => {
          document.removeEventListener('click', handleClickOutside);
      };
  }, [fromDate, toDate, getDemandType])
  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="med-box">
                <div className='trackhead'>
                  <div className='trackhead-inn'>
                    <div className="titleHeading text-wrap" style={{ borderLeft: '2px solid #1d4999', paddingLeft: '10px' }}>Track Demand</div>
                  </div>
                  <div className='trackhead-inn'>
                    <div className="tradeApproved">
                      {/* <div className='d-flex flex-wrap align-items-center'>

                        <span className='demandText'>Only show approved demands</span>
                        <div className="form-check form-switch ms-2">
                          <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                        </div>
                      </div> */}


                      <div style={{ borderLeft: '1px solid #5E707B', marginLeft: '5px' }}>
                      </div>
                      <div className='d-flex flex-wrap align-items-center'>
                        <span className='demandText'>Demand Type</span>
                        <span className='ms-2'>
                          <select name="" id="" className="form-select form-select-sm tradeDropdown" value={getDemandType} style={{ color: '#2A62C8', fontWeight: '500', fontSize: '14px', backgroundColor: '#F9FAFC', border: '0px' }} onChange={(event) => {handleSelect(event)}}>
                            <option value='0' selected>All Demand</option>
                            <option value='1'>Normal</option>
                            <option value='2'>Urgent</option>
                            <option value='3'>Must</option>
                          </select>
                        </span>
                      </div>
                      <div style={{ borderLeft: '1px solid #5E707B' }}>
                      </div>
                      {/* <div className="d-flex  flex-wrap">
                        <div className='purchasedemand'>
                          <div className='d-flex flex-wrap align-items-center'>
                            <span className='demandText'>From</span>
                            <span> <input type='date' className='form-control form-control-sm' value={fromDate} id='fromDateID' name='fromDateName' style={{ color: '#2A62C8', fontWeight: '500', fontSize: '14px', backgroundColor: '#F9FAFC', border: '0px' }} onChange={handleFromDate} /></span>
                          </div>
                        </div>
                        <div className='purchasedemand'>
                          <div className='d-flex flex-wrap align-items-center'>
                            <span className=''>To</span>
                            <span> <input type='date' className='form-control form-control-sm' value={toDate} id='toDateID' name='toDateName' style={{ color: '#2A62C8', fontWeight: '500', fontSize: '14px', backgroundColor: '#F9FAFC', border: '0px' }} onChange={handleToDate}/></span><br />
                            
                          </div>
                        </div>
                      </div> */}
                      <div className='demandMainn demandMainn1'>

                                            <div className="select-box">
                                                <div className="select-box__current" tabIndex="1" onClick={toggleDropdown}>
                                                    <div className="select-box__value"><p className="select-box__input-text">{selected}</p></div>
                                                    <img className="select-box__icon" src={arrowdd} alt="Arrow Icon" aria-hidden="true" />
                                                </div>
                                                <ul className="select-box__list" ref={selectBoxListRef}>
                                                    {options.map((option, index) => (<>

                                                        <li key={index}>
                                                            <label className="select-box__option" onClick={() => handleOptionClick(option)}>{option}</label>
                                                        </li></>
                                                    ))}
                                                </ul>
                                            </div>

                                            {selected === 'Custom' && (
                                                <div className='customrange-cn'>
                                                    <div className="customrange">
                                                        <span className='datetxt'>From:</span>
                                                        <input type="date" className="form-control" value={fromDate} onChange={handleFromDate} />
                                                    </div>
                                                    <div className="customrange">
                                                        <span className='datetxt'>To:</span>
                                                        <input type="date" className="form-control" value={toDate} onChange={handleToDate} />
                                                        <div id="errToDate" style={{ color: 'red', display: 'none' }}>To date cannot be smaller than from date</div>
                                                    </div>
                                                </div>
                                            )}

                                            {/* <DateFilter /> */}
                                        </div>

                    </div>
                  </div>
                  <div className='trackhead-inn'>
                    <form className="d-flex ser">
                      <i className="fa fa-search"></i>
                      <input type="search" className="form-control form-control-sm" placeholder="Search.." onChange={handleSearch}/>
                    </form>
                  </div>

                </div>
                <div className="med-table-section med-table-section alldemandtbl pt-0" style={{ height: '84vh', padding: '0 5px', position: 'relative' }}>
                  <table className='med-table border_ striped'>
                    <thead>

                      <tr style={{ background: ' #EAF6FF' }}>
                        <th className="text-center" style={{ "width": "4%", color: '#546788', fontWeight: '500', fontSize: '14px' }}>#</th>
                        <th style={{ "width": "8%", color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Demand No.")} <img src={tblshorting} alt="" style={{ marginLeft: '5px' }} /></th>
                        <th style={{ "width": "15%", color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Demand Date")} <img src={tblshorting} alt="" style={{ marginLeft: '5px' }} /></th>
                        <th style={{ "width": "15%", color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Approval/Rejection Date")} <img src={tblshorting} alt="" style={{ marginLeft: '5px' }} /></th>
                        <th style={{ "width": "12%", color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Voucher No.")} <img src={tblshorting} alt="" style={{ marginLeft: '5px' }} /></th>
                        <th style={{ "width": "6%", color: '#546788', fontWeight: '500', fontSize: '14px', }}>{t("Item Qty")} <img src={tblshorting} alt="" style={{ marginLeft: '5px' }} /></th>
                        <th style={{ "width": "12%", color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Priority")} <img src={tblshorting} alt="" style={{ marginLeft: '5px' }} /></th>
                        <th style={{ "width": "12%", color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Approval Status")} <img src={tblshorting} alt="" style={{ marginLeft: '5px' }} /></th>
                        <th style={{ "width": "12%", color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Store Status")} <img src={tblshorting} alt="" style={{ marginLeft: '5px' }} /></th>
                        <th className="text-center" style={{ "width": "6%", color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Action")}</th>
                      </tr>

                    </thead>
                    <tbody>
                    {noDataStatus ? (
                                                    <tr>
                                                        <td colSpan="9" className="text-center">
                                                            <div className='imageNoDataFound'>
                                                                <img src={NoDataFound} alt="imageNoDataFound" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ):
                                                <>
                                                  {demandList && demandList.map((list, ind) => {
                                                    return(
                                                      <tr>
                                                      <td className="text-center">{ind + 1}</td>
                                                      <td><span className="trackDemandText">{list.demandNo}</span> </td>
                                                      <td><span className="trackDemandText">{list.demandDate}</span> </td>
                                                      <td><span className="trackDemandText">{list.approvedDate ? list.approvedDate : '---'}</span> </td>
                                                      <td><span className="trackDemandText">VO54544</span> </td>
                                                      <td><span className="trackDemandText">{list.itemQty}</span> </td>
                                                      <td><span className={`${list.demandType === 1 ? 'trackNormal' :(list.demandType === 2 ? 'trackUrgent' : 'trackNormal')}`}>
                                                        {list.demandType === 1 ? 'Normal' : (list.demandType === 2 ? 'Urgent' : 'Very Urgent')}
                                                      </span> </td>
                                                      <td><span className={`${list.itemStatus === 'All approved'? 'trackApproved':(list.itemStatus === 'Pending'? 'trackPending' : 'trackRejected')}`}>{list.itemStatus}</span> </td>
                                                      <td><span className={`${list.storeItemStatus === 'All alloted'? 'trackApproved':(list.storeItemStatus === 'Pending'? 'trackPending' : 'trackRejected')}`}>{list.storeItemStatus}</span> </td>
                                                      <td className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><i className="fa fa-eye actionedit viewaction " onClick={() => funToNavigatePage(list.demandId)}></i><img src={vectorPrint} alt="" style={{ paddingLeft: '10px' }} /></td>

                                                    </tr>
                                                    )
                                                  })}
                                                </>}
                      
                      {/* <tr>
                        <td className="text-center">1</td>
                        <td><span className="trackDemandText">16566</span> </td>
                        <td><span className="trackDemandText">24-05-2024</span> </td>
                        <td><span className="trackDemandText">24-05-2024</span> </td>
                        <td><span className="trackDemandText">VO54544</span> </td>
                        <td><span className="trackDemandText">5</span> </td>
                        <td><span className="trackUrgent">Very Urgent</span> </td>
                        <td><span className="trackApproved">All Approved</span> </td>
                        <td className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><i className="fa fa-eye actionedit viewaction " ></i><img src={vectorPrint} alt="" style={{ paddingLeft: '10px' }} /></td>

                      </tr> */}
                      {/* <tr>
                        <td className="text-center">1</td>
                        <td><span className="trackDemandText">16566</span> </td>
                        <td><span className="trackDemandText">24-05-2024</span> </td>
                        <td><span className="trackDemandText">24-05-2024</span> </td>
                        <td><span className="trackDemandText">VO54544</span> </td>
                        <td><span className="trackDemandText">5</span> </td>
                        <td><span className="trackNormal">Normal</span> </td>
                        <td><span className="trackRejected">Rejected</span> </td>
                        <td className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><i className="fa fa-eye actionedit viewaction " ></i><img src={vectorPrint} alt="" style={{ paddingLeft: '10px' }} /></td>

                      </tr> */}
                    </tbody>

                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      {sendID && <TrackDemandItemList />}
    </>

  )
}
