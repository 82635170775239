function ValidationPharmacySale(productId='', unitId='', batchNo='', unitPrice='', quantity='') {
   
    if(productId !=='' && batchNo !=='' && unitId !=='' && unitPrice !=='' && quantity !=='')
    {
        return true
    }
    else{
        return false
    }
}
export default ValidationPharmacySale;