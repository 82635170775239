import React, { useEffect, useState } from 'react'
import LineInvestigationGrap from '../../../Components/LineInvestigationGrap';
import TimeCalculate from '../../../Code/TimeCalculate';
import GetAllDashboardParameterColumnAssign from '../../../Api/GetAllDashboardParameterColumnAssign';
import { useSelector } from 'react-redux';
import { getPatientMonitorDashboard } from '../../../../Reduce/PatientMonitordashboard/PatientMonitordashboard';
import store from '../../../../Store';

export default function BUreaR(props) {

    let [burea, setBurea] = useState("");
    let [bureaId, setBureaId] = useState("");
    let [bureaTime, setBureaTime] = useState("");
    let [bureaColor, setBureaColor] = useState("");
    let [bureaAlert, setBureaAlert] = useState(0);

    let [uhId, setUhId] = useState("")

    // set grap Investigation data
    let [grapInvestigationData, setGrapInvestigationData] = useState({
        showGrap: 0,
        subTestId: '',
        patientName: '',
        UHID: '',
        userId: ''
    })
    let patientMonitorDashboard = useSelector((state) => state.PatientMonitorDashboard.alertData)

    let getData = () => {
        setBurea("");
        setBureaId("");
        setBureaTime("");
        setBureaColor("");
        setBureaAlert("");

        props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
            if (investigation.SubTestID.toString() === "496" || investigation.SubTestID.toString() === "1609") {
                setBurea(Math.round(investigation.SubTestValue * 100) / 100)
                setBureaColor(investigation.SubTestColor)
                if (props.handleColor !== undefined) {
                    props.handleColor(investigation.SubTestColor !== null ? investigation.SubTestColor : "white", props.columnId)
                }
                setBureaId(investigation.SubTestID)
                setUhId(props.patientData.UhId)

                if (investigation.SubTestDateTime !== '') {
                    setBureaTime(TimeCalculate(investigation.SubTestDateTime))
                }

                if (investigation.SubTestColor !== null && investigation.SubTestColor !== "#008000" && investigation.SubTestColor !== "#94e094" && investigation.SubTestColor !== "#F5F8FF" && investigation.SubTestColor !== "") {
                    setBureaAlert(0)
                    let data = patientMonitorDashboard["burea_31"]

                    if (investigation.SubTestColor.includes("Red")) {
                        let present = data.filter(val => val === investigation.SubTestColor)
                        if (present.length === 0) {
                            store.dispatch(getPatientMonitorDashboard({ ...patientMonitorDashboard, ["burea_31"]: [investigation.SubTestColor, data[1], data[2]] }))
                            // console.log("patientMonitorDashboardspo2", { ...patientMonitorDashboard, ["burea_31"]: [investigation.SubTestColor, data[1], data[2]] })

                        }
                    }

                    else if (investigation.SubTestColor.includes("#c5756d")) {
                        let present = data.filter(val => val === investigation.SubTestColor)
                        if (present.length === 0) {
                            store.dispatch(getPatientMonitorDashboard({ ...patientMonitorDashboard, ["burea_31"]: [data[0], investigation.SubTestColor, data[2]] }))
                        }
                    }
                    else if (investigation.SubTestColor.includes("yellow")) {
                        let present = data.filter(val => val === investigation.SubTestColor)
                        if (present.length === 0) {
                            store.dispatch(getPatientMonitorDashboard({ ...patientMonitorDashboard, ["burea_31"]: [data[0], data[1], investigation.SubTestColor] }))
                        }

                    }



                }

            }
        })
    }

    let modelCloseFun = () => {
        setGrapInvestigationData({
            showGrap: 0,
            subTestId: '',
            patientName: '',
            UHID: '',
            userId: ''
        })
    }

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
        if(resp.length !== 0)
        {
           
            setPatameterData(resp[0].data)
        
        }

    }


    useEffect(() => {
        getColumnsParameters()

        getData()
    }, [props.patientData])
    return (
        <>
            {/* <div key={props.key} className={`overflow-max investigations ${(bureaAlert === 1 ? "blink" : "")} `} style={{ border: (bureaAlert === 1 ? "2px solid " + bureaColor : "") }}> */}
            <div key={props.key} className={`overflow-max investigations`}>

                {burea.length != 0 ? burea ? <span>
                    {
                        paramenterData && paramenterData.map((val, ind) => {
                            if (val.parameterId === 98) {
                                return (
                                    <>
                                        <span style={{ color: bureaColor }}>{burea}</span><br />



                                    </>

                                )
                            }

                            else if (val.parameterId === 99) {
                                return (
                                    <>
                                        {
                                            bureaTime.split("-")[1] === "D" && bureaTime.split("-")[0] >= 4 ? <span style={{ 'color': 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {bureaTime.toLowerCase().replace("-", "")}</span>
                                                : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {bureaTime.toLowerCase().replace("-", "")}</span>}
                                        <br />

                                    </>

                                )
                            }

                            else if (val.parameterId === 100) {
                                return (
                                    <>
                                        <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='Blood Urea Graph' >
                                            {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                                                if (val.SubtestID === bureaId) {
                                                    return (
                                                        <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                                                    )
                                                }
                                            })}
                                            <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [bureaId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "Blood Urea Graph", patientName: props.patientData.PntName }) }}></i></div>

                                        {/* <br /> */}
                                    </>

                                )
                            }


                        })

                    }
                    {/* <span >{burea_time}</span> */}

                </span> : 'N/A' : "N/A"}

            </div>
            {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}
        </>
    )
}
