import React from 'react'
import ventilatorGif from "../assests/images/ventiGif.gif"

export default function CannulaSection() {
  return (
    <>
      <div className='wrap-cannula'>
            <div className='cannula-cotainer' >
              <div className='d-flex flex-column gap-1 cannula-data'>
                <div className='ps-3'>
                  <div className='dnss'>DNS</div>
                  <div className='flowratedetails'>
                    <div className='flowratetext'>Flow Rate </div>
                    <div className='flowval'>20 /<span className='flowunit'>ml</span></div>
                  </div>                  
                </div>

              </div>
            </div>
            <img src={ventilatorGif} className='cannulaGif' alt="cannula" />
          </div>
    </>
  )
}
