import React from "react";

import Page from "../../assets/images/icons/Page-1.svg";
import Select from 'react-select';
import user3 from "../../assets/images/icons/user (3).svg";
import question from "../../assets/images/icons/question.svg";
import category from "../../assets/images/icons/category.svg";
import medicalRoom from "../../assets/images/icons/medical-room.svg";
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import imgDepartment from "../../assets/images/icons/imgDepartment.svg";
import clearIcon from '../../assets/images/icons/clear.svg';
import { useState } from "react";
import { useEffect } from "react";
import SuccessToster from "../../Component/SuccessToster";
import AlertToster from "../../Component/AlertToster";
import PatientDetailforadvancepayment from "../API/PatientDetailforadvancepayment";
import PostAdvancePaymentDetails from "../API/POST/PostAdvancePaymentDetails";
import getAllTpaCompany from "../API/getAllTpaCompany";
import GetAllAppliedCashless from "../API/GetAllAppliedCashless";
import PostAppliedCashlessRequest from "../API/POST/PostAppliedCashlessRequest";
import Heading from "../../Component/Heading";
import GetAllCashLessPeddingRequest from "../API/GetAllCashLessPeddingRequest";
import DateRangePicker from "../../Component/DateRangePicker";

export default function ApplyForCashless() {

  const currentDate = new Date();

  const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

  const fromDate = new Date(currentDate);
fromDate.setDate(fromDate.getDate() - 1);

const formattedFromDate = formatDate(fromDate);

const toDate = new Date(currentDate);
const formattedToDate = formatDate(toDate);



  

  let [UHID, setUHID] = useState('');
  let [patientDetails, setPatientDetails] = useState(1);
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [showSuccessToster, setShowSuccessToster] = useState(0)
  let [showMessage, setShowMeassage] = useState("");
  let [showPaymentDetails, setshowPaymentDetails] = useState(false);
  let [isFieldDisabled, setisFieldDisabled] = useState(false);
  let [advancedpaid, setadvancedpaid] = useState('');
  let [PolicyNumber, setPolicyNumber] = useState('');
  const [selectedRange, setSelectedRange] = useState(null);
  let [companyName, setcompanyName] = useState(null);
  let [companyList, setcompanyList] = useState([]);
  let [CardNumber, setCardNumber] = useState('');
  let [remark, setremark] = useState('');
  let [billType, setBillType] = useState([]);
  let [FromDate, setFromDate] =  useState(formattedFromDate);
  let [ToDate, setToDate] = useState(formattedToDate);
  let [searchUHID, setsearchUHID] = useState('0');
  let [AlertBorder, setAlertBorder] = useState(false);
  const [rowID, setRowID] = useState("");
  let [byCard, setByCard] = useState();
  let [BankName, setBankName] = useState('');
  let [PaymentModeList, setPaymentModeList] = useState([]);
  let [pamentMode, setPaymentMode] = useState(0);
  let [refNo, setRefNo] = useState('');
  let [bankList, setBankList] = useState([])
  let [discountBy, setDiscountBy] = useState();
  let [companyType, setCompanyType] = useState([]);
  let [chequeNo, setchequeNo] = useState('');
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
   let [chequeDate, setchequeDate] = useState('') 
   let [formStatus, setformStatus] = useState('0') 
   let [insCardNo, setinsCardNo] = useState(0)
  let [AppliedCashlessList, setAppliedCashlessList] = useState([]);
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  let [userID, setUserID] = useState(JSON.parse(sessionStorage.getItem("LoginData")).userId);

  let clientId = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId

  



  const handleDateSelect = (start, end) => {
    setSelectedRange({ start, end });
    setIsDateRangePickerOpen(false);
    const fromDate = new Date(start).toISOString().split('T')[0];
    const toDate = new Date(end).toISOString().split('T')[0];
  
    console.log("From date:", fromDate);
    console.log("To date:", toDate);
  };



  let handleUhidEvent = async (e) => {
    let UHID = e.target.value.toUpperCase();
    setUHID(UHID);
    if(UHID.length !== 9){
      
      document.getElementById("Pgender").value = ""
      document.getElementById("PName").value = ""
      document.getElementById("PAge").value = ""
      document.getElementById("PWard").value = ""
      // document.getElementById("Pdepartment").value = ""
      document.getElementById("UHID").value = ""

      setPatientDetails([])
     }


// else {
   
//   setAlertBorder(false)
//   let data = await PatientDetailforadvancepayment(UHID);
//   console.log('data' , data.responseValue)
//   let dt = data.responseValue[0];
//   setPatientDetails(dt); 
//   setshowPaymentDetails(true)
  
//   if(dt.status === 2){
//     setShowAlertToster(1)
//     setShowMeassage("Patient is not eligible for Insurance")
//     setisFieldDisabled(true)
//   }
//   else{
//     setisFieldDisabled(false)
//   }
// }

  

   
  };

const handleOnChange=(e)=>{

  const inputValue = e.target.value;
  const isValidInput = /^[A-Za-z\s]+$/.test(inputValue);


    const {value,name} = e.target;

    if(name === 'PolicyNumber'){
        setPolicyNumber(value)
        
        setAlertBorder(false)
    }
   
    else if(name === 'CardNumber'){
        setAlertBorder(false)
        setCardNumber(value)
    }
    else if(isValidInput || inputValue === ''){
        setAlertBorder(false)
        setremark(inputValue)
    }
}

const handleOnReset=()=>{

  document.getElementById("Pgender").value = ""
  document.getElementById("PName").value = ""
  document.getElementById("PAge").value = ""
  document.getElementById("PWard").value = ""
  // document.getElementById("Pdepartment").value = ""
  document.getElementById("UHID").value = ""

  
    setadvancedpaid('')
    setPolicyNumber('')
    setisFieldDisabled(false)
    setUHID('')
    setCardNumber('')
    setremark('')
    setshowPaymentDetails(false)
    setcompanyName(null)
    setPatientDetails([])
 

  
}

const handleOnsubmit=async()=>{
  if(UHID.trim() === ""){
    setAlertBorder(true)
    setShowAlertToster(1)
    setShowMeassage("UHID is required..!!")
    return;
}

    let data = await PatientDetailforadvancepayment(UHID);
    if(UHID.trim() === ""){
        setAlertBorder(true)
        setShowAlertToster(1)
        setShowMeassage("UHID is required..!!")
        return;
    }
    else if (data.status === 0){
        setShowAlertToster(1)
        setShowMeassage("UHID is not Valid...!!")
        return;
    }
    else if(companyName == null){
      setShowAlertToster(1)
        setShowMeassage("Please Select Company Name")
        return;
    }
    else if(PolicyNumber == "" && CardNumber == "" ){
        setShowAlertToster(1)
        setShowMeassage("Policy Number or Card Number is required")
        return;
    }


    


else{
  let pname = patientDetails.patientName
  let pAge = patientDetails.age
  let pGender = patientDetails.gender

    const obj = {
         uhid : UHID,
         pmid : patientDetails && patientDetails.pmId,
         policyNo  : PolicyNumber,
         cardNo : CardNumber,
        tpaCompanyId : companyName.value,
        userId : userID,
        clientId : clientId

     }
 
    
     console.log('obj' , obj)
     
 let data = await PostAppliedCashlessRequest(obj)

 if(data.status === 1){
    setShowSuccessToster(1)
    setShowMeassage(`Successfully applied cashless reuqest for ${UHID}`)
    getAllAppliedCashlessList()

    handleOnReset()
    document.getElementById("Pgender").value = ""
    document.getElementById("PName").value = ""
    document.getElementById("PAge").value = ""
    document.getElementById("PWard").value = ""
    // document.getElementById("Pdepartment").value = ""
    document.getElementById("UHID").value = ""
 }
 else{
  setShowAlertToster(1)
  setShowMeassage(data.responseValue)
  
 }
}


}


const handleDateChange = async(e)=>{
  const {name , value} = e.target

  if(name === "FromDate"){
      setFromDate(value)
  }
  else if (name === "ToDate"){
      setToDate(value)
  }
  else if (name === "formStatus"){
      setformStatus(value)
  }
  // GetAdvancedPaymentList(FromDate,ToDate,searchUHID)


  

}




const handleTPASelectChange =  (selectedOption, errorElementId, setSelectedFunction) => {
    document.getElementById(errorElementId).style.display = 'none';
    setSelectedFunction(selectedOption)
 
  };


  let getAllTpaCompanyList = async () => {
   
    let data = await getAllTpaCompany();
    
    console.log('Data',data);
    if (data.status === 1) {
       
        setcompanyList(data.responseValue.map(Tpa=>({
            value: Tpa.id,
            label: Tpa.companyname
        })));
    }
    else {
       
     }
}


 const getAllAppliedCashlessList=async()=>{

  let data = await GetAllCashLessPeddingRequest(FromDate,ToDate,formStatus)
  if(data.status === 1){
    setAppliedCashlessList(data.responseValue)
    console.log("setAppliedCashlessList" , data.responseValue )
  }
 
 }

  //*****saveDetails */

  




  //*******Print */


 

 

 useEffect(() => {
  const debounce = setTimeout(async() => {
    let data = await PatientDetailforadvancepayment(UHID);
    console.log('data' , data.responseValue)
    let dt = data.responseValue[0];
    setPatientDetails(dt); 
    setshowPaymentDetails(true)
  
      if(dt.status === 2){
        setShowAlertToster(1)
        setShowMeassage("Patient is not eligible for Insurance")
        setisFieldDisabled(true)
      }
      else{
        setisFieldDisabled(false)
      }
  }, 500)
  return () => clearTimeout(debounce)

 }, [UHID])





  useEffect(() => {
  
    getAllTpaCompanyList()
    getAllAppliedCashlessList()

 
  }, []);
  
  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
          <div className="col-12"><div className="med-box  mb-1"><div className="title">Apply For Cashless</div></div></div>
            <div className="col-12">
              <div className="med-box">
                <div className="inner-content">
                  
                  <div className="row">

                        {patientDetails && (

                      <div className='fieldsett-in col-md-6'>
                        
                      <div className='fieldsett'>
                        <div className="d-flex justify-content-end">
                        <span className="badge rounded-pill text-dark" style={{ fontWeight: '600', padding: '5px 10px' , background: '#cae6ff'}}>Patient Type : {patientDetails && !patientDetails.patientType  ? 'Not Eligible' : patientDetails.patientType} </span>
                        </div>
                     
                     <span className='fieldse'>Patient Details</span>
                         <div className="row">
              
                     <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3">
                          <img src={Page} alt=''/>{" "}
                          <label for="UHID" className="form-label">
                            UHID <span className="starMandatory">*</span>
                          </label>
                         
                          <input
                            type="text"
                            className="form-control form-control-sm ms-2"
                            id="UHID"
                            value={UHID}
                            placeholder="UHID"
                            name="UHID"
                            maxLength={11}
                            onChange={handleUhidEvent}
                          />
                   
                         
                          </div>
       
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3">
                                <div className="mb-2">
                                <div className="d-flex align-items-baseline">
                                <img src={user3} className='icnn' alt='' /><label htmlFor="FullName*" className="form-label">Patient Name</label>
                                </div>
                                  <input type="text" className="form-control form-control-sm" id="PName" name="donor" placeholder="Patient Name" value=  {patientDetails.patientName} disabled />
                                </div>
                              </div>
                              
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3">
                                <div className="mb-2">
                                <div className="d-flex align-items-baseline">
                                <img src={category} className='icnn' alt='' /> <label htmlFor="gender" className="form-label">Gender</label>
                                </div>
                                <input type="text" className="form-control form-control-sm" id="Pgender" name="donor" placeholder="Gender" value=  {patientDetails.gender} disabled />
                         
                                  </div>
                                </div>

                               <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2">
                                <div className="mb-2">
                                <div className="d-flex align-items-baseline">
                                <img src={question} className='icnn' alt='' /><label htmlFor="dob" className="form-label">Age <span className="starMandatory"></span></label>
                                </div>
                                  
                                  <input type="text" className="form-control form-control-sm" id='PAge' name='regDate' placeholder="Age" value={patientDetails.age} disabled />
                                </div>
                              </div>
                               {/* <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3">
                                <div className="mb-2">
                                <div className="d-flex align-items-baseline">
                                <img src={question} className='icnn' alt='' /><label htmlFor="dob" className="form-label">PmID <span className="starMandatory"></span></label>
                                </div>
                                  
                                  <input type="text" className="form-control form-control-sm" id='pmId' name='regDate' placeholder="PmID" value={patientDetails.pmId} disabled />
                                </div>
                              </div> */}
          
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-2">
                                <div className="mb-2">
                                <div className='d-flex align-items-baseline'>
                                <img src={medicalRoom} className='icnn' alt='' /><label htmlFor="bloodGroup" className="form-label">Ward</label>
                                </div>
                                 
                                  <input type="text" className="form-control form-control-sm" id="PWard" value={patientDetails.wardName} name="ddlBloodGroup" placeholder="Ward" disabled />
                                </div>
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3">
                              <div className="mb-2">
                                <div className='d-flex align-items-baseline'>
                                  <img src={imgDepartment} className='icnn' alt='' /><label htmlFor="identity" className="form-label">Department</label>
                                </div>
                                <input type="text" value={patientDetails.departName} className="form-control form-control-sm" id="Pdepartment" name="ddlIdentityType" placeholder="Department" disabled />
                              </div>
                            </div>
     
                          </div>


                            </div>
                          </div>

                        )}


                    <div className='fieldsett-in col-md-6'>
                      <div className='fieldsett'>
                     <span className='fieldse'></span>
                               
                     <div className="row ps-2 mb-4">
                     <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3">
                                        <label htmlFor="ddlitem" className="form-label ">TPA Company<span className="starMandatory">*</span></label>
                                        <Select value={companyName} options={companyList} isDisabled={isFieldDisabled} className=" create-select"  placeholder="Select TPA Company" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleTPASelectChange(selectedOption, "errCompany", setcompanyName)} />
                                        <small id="errCompany" className="form-text text-danger" style={{ display: 'none' }}></small>
                                    </div>

                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3">
                      <label htmlFor="Code" className="form-label">Policy Number<span className="starMandatory"></span></label>
                      <input  id="PolicyNumber" value={PolicyNumber} type="text" disabled={isFieldDisabled} className="form-control form-control-sm" name="PolicyNumber" placeholder= "Enter Policy Number" onChange={handleOnChange} />
                      <small id="errPolicyNumber" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>

                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3">
                      <label htmlFor="Code" className="form-label">Card Number<span className="starMandatory"></span></label>
                      <input  id="CardNumber" value={CardNumber} type="number" disabled={isFieldDisabled} className="form-control form-control-sm" name="CardNumber" placeholder= "Enter Card Number" onChange={handleOnChange}/>
                      <small id="errCardNumber" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>
      
                                     </div>
    
                   </div>
                   </div>

           
                  </div>

               
              
                                      <div className="row justify-content-end">
                                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3 mt-3 rt-btns ">
                                                   <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                                   <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" disabled={isFieldDisabled} onClick = {handleOnsubmit}><img src={saveButtonIcon} className='icnn' alt="" />Submit</button>
                                                    <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleOnReset}><img src={clearIcon} className='icnn' alt= ""/>Clear</button>
                                                   </div>
                                      </div>
                                               
                                                 
                 
                
                </div>
                
    
              </div>
              
            </div>
          </div>


        </div>
        {showAlertToster === 1 ? (
              <AlertToster message={showMessage} handle={setShowAlertToster} />
            ) : (
              ""
            )}
            {showSuccessToster === 1 ? (
              <SuccessToster
                message={showMessage}
                handle={setShowSuccessToster}
              />
            ) : (
              ""
            )}
  <div className="container-fluid col-12 mt-3">
  <div className="med-table-section" >
      <div className='handlser ps-2 mb-3'>
              <Heading text="Cashless Requests List"/>
                <div style={{ position: 'relative' , display : 'flex'}}>
                    
                <label  className="title me-2 mt-1" style={{fontSize: '12px',wordWrap: 'nowrap'}}><span className="starMandatory"></span></label>
                <input
        type="text"
        name="FromDate"
        value={selectedRange ? `${selectedRange.start.toLocaleDateString()} - ${selectedRange.end.toLocaleDateString()}` : ''}
        className='form-control form-control-sm'
        readOnly
        placeholder="Select Date Range"
        onClick={() => setIsDateRangePickerOpen(!isDateRangePickerOpen)}
        style={{ padding: '10px', cursor: 'pointer', width: '300px' }}
      />
  

                  <select id="Payment" className="form-control form-control-sm" name="formStatus"  value={formStatus} onChange={handleDateChange}  >
                    <option value="0">All</option>
                    <option value="1">Under Process</option>
                    <option value="2">Approved</option>
                    <option value="3">Closed</option>
                    <option value="4">Rejected</option>
                  </select>
                    
                  <div className="ms-5">
                    <span className="tblsericon"><i className="fas fa-search" onClick={getAllAppliedCashlessList}></i></span>
                  </div>
                  
                 
                </div>
              </div>
  </div>
  {isDateRangePickerOpen && (
        <DateRangePicker
          onClose={() => setIsDateRangePickerOpen(false)}
          onDateSelect={handleDateSelect}
        />
      )}

              <div className="med-table-section" style={{ "height": "80vh" }}>
              {/* <div className='handlser ps-2 mb-3 justify-content-end'>
             
                <div style={{ position: 'relative' , display : 'flex'}}>
                    
                <label  className="title me-2 mt-1" style={{fontSize: '12px'}}>From<span className="starMandatory"></span></label>
                  <input type="date" name="FromDate" value={FromDate} className='form-control form-control-sm'  onChange={handleDateChange} />
                  <label htmlFor="Code" className="form-label title me-2 mt-1" style={{fontSize: '12px'}}>To<span className="starMandatory"></span></label>
                  <input type="date" name="ToDate" value={ToDate} className='form-control form-control-sm'  onChange={handleDateChange} />
                 
                  <select className="form-select filter-select" aria-label="Default select example" onChange={handleDateChange} name="searchUHID" value={searchUHID}>
                    <option value="0" selected>All</option>
                    <option value="1">Applied</option>
                    <option value="2">Approved</option>
                    <option value="3">Rejected</option>
                  </select>
                  <div className="ms-5">
                    <span className="tblsericon" style={{top: '0px' , fontSize: '16px'}}><i className="fas fa-search" onClick={()=>getAllAppliedCashlessList()}></i></span>
                  </div>
                  
                 
                </div>
              </div> */}
                <table className="med-table border_ striped">
                  <thead style={{ zIndex: '0' }}>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>UHID</th>
                      {/* <th>PmID</th> */}
                      <th>Company Name</th>
                      <th>Policy Number</th>
                      <th>Card Number</th>
                      <th>Status</th>

                      {/* <th>Status</th> */}
                      {/* <th>Action</th> */}

                      <th></th>
                      {/* <th style={{ "width": "10%" }} className="text-center">Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    
                    {AppliedCashlessList && AppliedCashlessList.map((data,index)=>{
                        return(
                            <tr key={index}>
                            <td className="text-center">{index+1}</td>
                            <td>{data.uhid}</td>
                            {/* <td>{data.pmid}</td> */}
                            <td>{data.companyname}</td>
                            <td>{data.policyNo}</td>
                            <td>{data.cardNo == "" || data.cardNo == undefined ? '---' : data.cardNo}</td>
                            <td><span style={{width:"93px" ,fontSize: '12px'}} className={`d-block text-center p-1 ${data.formStatus == "Applied" ? "yellow-transparent" : data.formStatus == "Approved" ? 'green-transparent' : 'red-transparent'} `}>{data.formStatus}</span></td>
                            
                         

                            <td>
                            {/* <div className="action-button">
                    <div className= {data.limitStatus == 2 ? 'd-block' : data.limitStatus == 1 ? 'd-none' : ''}
                      onClick={() => {handleUpdateApproveCredit(index);}}
                    >
                   <img src={active} alt='' title='Activate' />
                    </div>
                    <div className= {data.limitStatus == 1 ? 'd-block' : data.limitStatus == 2 ? 'd-none' : ''}><img src={hold} alt='' title='Hold'   onClick={() => {handleUpdateHoldCredit(index);}}/>
                    </div>
                  </div> */}
                            </td>
                   
                          </tr>
                        )
                    })}
                
                       
                      
                   
                  </tbody>
                </table>
              </div>
            </div>
            {showAlertToster === 1 ? (
              <AlertToster message={showMessage} handle={setShowAlertToster} />
            ) : (
              ""
            )}
            {showSuccessToster === 1 ? (
              <SuccessToster
                message={showMessage}
                handle={setShowSuccessToster}
              />
            ) : (
              ""
            )}
      </section>
    </>
  );
}
