import React, { useEffect, useState } from 'react'
import BoxHeading from './BoxHeading'
import SettingAPI from '../Api/SettingAPI'
import UpdateAllDashboardColumnSequence from '../Api/UpdateAllDashboardColumnSequence.js';
import UpdateDashboardColumnSequence from '../Api/UpdateDashboardColumnSequence.js';
import PostDashboardColumnSequence from '../../Admin/Api/Dashboard/DashboardColumnSequenceAPI/PostDashboardColumnSequence.js';
import DeleteDashboardColumnSequence from '../Api/DeleteDashboardColumnSequence.js';
import Toster from '../../Component/Toster.js';

export default function SettingPopup(props) {
   let [columnsList, setColumnList] = useState([])
   let [dataChange, setDataChange] = useState(0)
   let getdata = async () => {
      let response = await SettingAPI();
      if (response.status === 1) {
         console.log("enter")
         setColumnList(response.responseValue)
      }
   }
   let [message, setMessage] = useState("")
   let [messageValue, setMessageValue] = useState(-1)



   let handleSave = async (val, ind) => {
      let temp = [...columnsList]
      let tempsendArray = []
      let sendDataArray = []
      let sendData = {}
      setDataChange(1)
      if (val.status !== 1) {

         sendData = { ...val, id: 0, "sequenceNumber": val.highestSequence + 1, "isShow": 1, clientID: window.clientId, userID: window.userId, jsonSequence: "", assignedTo: window.userId }
         let response = await PostDashboardColumnSequence(sendData);



         if (response.status === 1) {
            getdata()
            // props.handleshowHide(-1)
            // window.location.reload(true)
            setMessageValue(0)
            setMessage("Column Visibility Updated!! ")
            setTimeout(() => {
               setMessageValue(-1)
            }, 1000)


         }
         else {
            // window.location.reload(true)
            setMessageValue(1)
            setMessage(response.responseValue)
            setTimeout(() => {
               setMessageValue(-1)
            }, 1000)
         }
         // console.log("data", val, ind)
      }
      else {
         // sendData = { ...val, "sequenceNumber": 0, "isShow": 0, clientID: window.clientId, userID: window.userId, jsonSequence: "", assignedTo: window.userId }
         sendData = { ...val }
         // let response = await UpdateDashboardColumnSequence(sendData);
         let response = await DeleteDashboardColumnSequence(sendData);



         if (response.status === 1) {
            console.log("Data Save")
            getdata()
            // props.handleshowHide(-1)
            // window.location.reload(true)
            setMessageValue(0)
            setMessage("Column Visibility Updated!! ")
            setTimeout(() => {
               setMessageValue(-1)
            }, 1000)

         }
         else {
            // window.location.reload(true)
            setMessageValue(1)
            setMessage(response.responseValue)
            setTimeout(() => {
               setMessageValue(-1)
            }, 1000)
         }
         // console.log("data", val, ind)

      }
      // for (var i = 0; i <= temp.length - 1; i++) {
      //    if (ind !== i) {
      //       let data = {
      //          "id": temp[i].id,
      //          "columnId": temp[i].columnId,
      //          "sequenceNumber": i + 1,
      //          "assignedTo": window.userId,
      //          "userId": window.userId,
      //          "clientId": window.clientId,
      //          "isShow": temp[i].status
      //       }
      //       sendDataArray.push(data)
      //    }
      //    else {
      //       let data = {
      //          "id": temp[i].id,
      //          "columnId": temp[i].columnId,
      //          "sequenceNumber": i + 1,
      //          "assignedTo": window.userId,
      //          "userId": window.userId,
      //          "clientId": window.clientId,
      //          "isShow": 1
      //       }
      //       sendDataArray.push(data)
      //    }


      // }
      // // sendDataArray = sendDataArray.filter(n => n)
      // console.log("tempsendArray", tempsendArray, sendDataArray)
      // if (sendDataArray.length === 0) {
      //    sendDataArray[0] = {
      //       "id": 0,
      //       "columnId": 0,
      //       "sequenceNumber": 0,
      //       "assignedTo": 0,
      //       "userId": window.userId,
      //       "clientId": window.clientId,
      //       "isShow": 0
      //    }
      // }
      // let sendData = {
      //    jsonSequence: JSON.stringify(sendDataArray)
      // }
      // let response = await UpdateAllDashboardColumnSequence(sendData);
      // let response = await UpdateDashboardColumnSequence(sendData);

   }
   let handleClose = () => {
      props.close()
      if (dataChange === 1) {
         window.location.reload(true)
      }
   }

   useEffect(() => {
      getdata()
   }, [props])
   return (
      <div className={`modal d-${props.showSetting === 0 ? 'none' : 'block'}`}>
         <div className="modal-dialog modal-dialog-top_ modal-md">
            <div className="modal-content">
               {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill" onClick={props.close}>
               <label className='text-center pt-2' style={{ width: '25px', height: '25px', borderRadius: '15px', backgroundColor: 'red', 'cursor': 'pointer' }}>X</label>
            </span> */}
               {/* <BoxHeading name="Dashboard Setting" textcolor="#7E7E7E" /> */}
               {/* <BoxHeading title="Vital Chart History" /> */}

               <span className="closee" title='Close Window' onClick={handleClose}><i className='fa fa-times'></i></span>
               <div className='p-profile'>
                  <div className='p-profile-h'>Dashboard Setting</div>
                  {/* <div className='p-profile-h '>
                     {
                        messageValue !== -1 ?
                           <Toster value={messageValue} message={message} />
                           : ""
                     }
                  </div> */}
               </div>

               <div className="row">
                  <div className="col-12">
                     <div className='mt-1 p-2'>
                        <div className='med-table-section histry_view_ pdtable' style={{ height: "calc(100vh - 180px)" }}>
                           <table className='table_'>
                              <thead>
                                 <tr>
                                    <th>Columns Names</th>
                                    <th>Set Values</th>
                                 </tr>
                              </thead>
                              <tbody className='tableRow_'>
                                 {
                                    columnsList && columnsList.map((val, ind) => {
                                       return (
                                          <tr>
                                             <td style={{ color: "black" }}>{val.columnName}</td>
                                             <td>
                                                <div className='form-check form-switch'>
                                                   <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={val.status === 1 ? "checked" : ""} onClick={() => { handleSave(val, ind) }} />
                                                   <label className="form-check-label" for="flexSwitchCheckChecked"></label>
                                                </div>
                                             </td>

                                          </tr>
                                       )
                                    })
                                 }
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className='p-profile-h profilehh '>
            {
               messageValue !== -1 ?
                  <Toster value={messageValue} message={message} />
                  : ""
            }
         </div>
      </div>
   )
}
