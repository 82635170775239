// async function PostInventoryItemMaster(data) {
//     let url = window.InventoryBaseUrl + '/api/InventoryItemMaster/InsertInventoryItem';
//     let head = {
//       // 'Content-Type': 'application/JSON',
//       accept: '*/*',
//     }
//     const formData = new FormData();
//     formData.append('formFile', data.formFile)
//     let response =
//       await fetch(url, {
//         method: 'POST',
//         headers: head,
//         body: formData
//       })
//         .then((res) => res.json())
//         .then(data)
      
  
//     return response;
//   }
//   export default PostInventoryItemMaster;
  
import axios from 'axios';
async function PostInventoryItemMaster(data) {
     const url = window.InventoryBaseUrl+`/api/InventoryItemMaster/InsertInventoryItem?ItemName=${data.ItemName}&ItemCode=${data.ItemCode}&UrgencyLevelID=${data.UrgencyLevelID}&MinStockQty=${data.MinStockQty}&ItemCategoryID=${data.ItemCategoryID}&ItemSubCategoryID=${data.ItemSubCategoryID}&ItemTypeID=${data.ItemTypeID}&SubscriptionTypeID=${data.SubscriptionTypeID}&HsnCodeID=${data.HsnCodeID}&GstRatePer=${data.GstRatePer}&Specification=${data.Specification}&IsEquipment=${data.IsEquipment}&IsReturnable=${data.IsReturnable}&ItemUnitJson=${data.ItemUnitJson}&ItemBrandJson=${data.ItemBrandJson}&UserID=${window.userId}&ClientID=${window.clientId}`;
   const formData = new FormData();
    formData.append('formFile', data.formFile);    
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return axios.post(url, formData, config)
    
}
export default PostInventoryItemMaster;