let DeleteCurrency=async(data)=>{
    const api=window.AdminbaseUrl+'/api/CurrencyMaster/DeleteCurrency?id='+data.id;
    let head = { "Content-Type": "application/JSON", accept: '*/*'};
    let response = await fetch(api, {
        headers: head,
        method: 'DELETE'
      })
        .then((res) => res.json())
        .then();
    
      return response;
}
export default DeleteCurrency;