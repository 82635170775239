import React, { useEffect, useState } from 'react'
import Heading from '../../Component/Heading'
import BoxContainer from '../../Component/BoxContainer'
import TableContainer from '../../Component/TableContainer'
import InvestigationOfSHFCAPI from '../Api/InvestigationOfSHFC/InvestigationOfSHFC'
import viewicon from "../../assets/images/PatientListIcons/viewicn.svg"
import Search from '../../Code/Serach'
import Loader from '../../Component/Loader'
import GetAllInvestigationResultOfSHFC from '../Api/InvestigationOfSHFC/GetAllInvestigationResultOfSHFC'
import date from "../../assets/images/PatientAnalyzingGraph/date.svg"
import AlertToster from '../../Component/AlertToster'

export default function InvestigationOfSHFC() {
    let [subTestCList, setSubTestList] = useState([])
    let [investigationOfSHFCList, setInvestigationOfSHFCList] = useState([])
    let [tempInvestigationOfSHFCList, setTempInvestigationOfSHFCList] = useState([])
    let [loader, setLoader] = useState(0)
    let [fromDate, setFromDate] = useState(0)
    let [toDate, setToDate] = useState(0)
    let [uhid, setUhid] = useState("")
    let [showToster, setShowToster] = useState(0)
    let [message, setMessage] = useState("")
    let getSubTestData = async (data) => {
        try{
            let response = await GetAllInvestigationResultOfSHFC(data)
            if (response.status === 1) {
                setSubTestList(response.responseValue)
            }
            else {
                setInvestigationOfSHFCList([])
                setShowToster(1)
                setMessage("Data Not Found")
            }
        }
        catch (e){
            alert(e)
        }
        
    }

    let handleSuntest = (data) => {
        let send = {
            testId: data.testID,
            sampleCollectionSubID: data.samplecollectionSubid,
        }
        getSubTestData(send)
    }
    let getData = async (data) => {
        setLoader(1)
        try{
            let response = await InvestigationOfSHFCAPI(data)
            if (response.status === 1) {
                setInvestigationOfSHFCList(response.responseValue)
                setTempInvestigationOfSHFCList(response.responseValue)
                setLoader(0)
            }
            else {
                setInvestigationOfSHFCList([])
                setLoader(0)
                setShowToster(1)
                setMessage("Data Not Found")
            }
        }
        catch{
            setLoader(0)

        }
        
    }

    let handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (name === "fromDate") {
            setFromDate(value)
        }
        else if (name === "toDate") {
            setToDate(value)
        }
        else if (name === "uhid") {
            setUhid(value)
        }

    }

    let handleSubtest = () => {
        let send = {
            fromDate:fromDate,
            toDate:toDate,
            Uhid:uhid,

        }
        getData(send)
    }

    let handleSearch = (e) => {
        let value = e.target.value
        if (value.length !== 0) {

            let result = Search(investigationOfSHFCList, value)
            setTempInvestigationOfSHFCList(result)

        }
        else {
            setTempInvestigationOfSHFCList(investigationOfSHFCList)
        }

    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className='med-box py-2 ps-2 pe-2'>
                        <div className="row">
                            <div className="col-12">

                                <div className="fieldsett-in">
                                    <div className="fieldsett">
                                        <span className='fieldse'>Investigation List</span>
                                        <div className='d-flex flex-row gap-4'>

                                            <div className="mb-2 me-2">
                                                <img src={date} className='icnn' /> <label htmlFor="FromDate" className="form-label">From Date<span className="starMandatory"></span></label>
                                                <input type="date" className="form-control form-control-sm inn1" id="FromDate" name="fromDate" value={fromDate} placeholder="Select Request Date" onChange={handleChange} />
                                                <small id="errFromDate" className="form-text text-danger" style={{ display: 'none' }}>Please Select From Date</small>
                                            </div>
                                            <div className="mb-2 me-2">
                                                <img src={date} className='icnn' /> <label htmlFor="FromDate" className="form-label">To Date<span className="starMandatory"></span></label>
                                                <input type="date" className="form-control form-control-sm inn1" id="toDate" name="toDate" value={toDate} placeholder="Select Request Date" onChange={handleChange} />
                                                <small id="errFromDate" className="form-text text-danger" style={{ display: 'none' }}>Please Select To Date</small>
                                            </div>
                                            <div className="mb-2 me-2">
                                                <label htmlFor="FromDate" className="form-label">UHID<span className="starMandatory"></span></label>
                                                <input type="text" className="form-control form-control-sm inn1" id="uhid" name="uhid" value={uhid} placeholder="Enter UHID" onChange={handleChange} />
                                                <small id="errFromDate" className="form-text text-danger" style={{ display: 'none' }}>Please Enter UHID</small>
                                            </div>
                                            <div className="mb-2 me-2">
                                                <label htmlFor="FromDate" className="form-label"><span className="starMandatory"></span></label>

                                                <div className='searchbtnn'>
                                                    <button onClick={handleSubtest}><i className='fa fa-search'></i>Search Result</button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <div className='d-flex justify-content-between ps-2 pe-2'>
                                    <div className="title"></div>
                                    <div className={`col-3 mt-2_ pt-2_ pe-2 position-relative serip`} >
                                        <input type='text' placeholder='Search...' className='searchBarOPD searchipdo' onChange={handleSearch} />
                                        {/* <img src={searchIcon} className='searchBarOPDIcon' /> */}
                                        <i className='fa fa-search'></i>
                                    </div>
                                </div>
                                
                                <div className="med-table-section mt-3" style={{ "height": "88vh" }}>
                                    <table className='med-table border_ striped'>
                                        <thead>
                                            <tr>
                                                <th className="text-center" style={{ "width": "5%" }}>#</th>
                                                <th>Patient Deatils</th>
                                                <th>Investigation Name</th>
                                                <th>Result Date</th>
                                                <th style={{ "width": "10%" }} className="text-center">Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {tempInvestigationOfSHFCList && tempInvestigationOfSHFCList.map((val, ind) => {
                                                return (
                                                    <tr key={val.id}>
                                                        <td className="text-center">{ind + 1}</td>
                                                        <td>{val.patientName} ({val.age} {val.ageType}) ({val.uhID})</td>
                                                        <td>{val.testname}</td>
                                                        <td>{val.createdDate}</td>
                                                        <td>
                                                            <div className="action-button">
                                                                <div className="actionItem" title="View" data-bs-toggle="modal" data-bs-target="#subtestModal" onClick={() => { handleSuntest(val) }}><img src={viewicon} className='bgicn' alt="View" title="View" /></div>
                                                                {/* <div className="actionItem" title="View"><img src={IconPrint} alt="IconPrint" /></div> */}
                                                                {/* <div className="actionItem" title="Edit"><img src={IconEdit} alt="Edit" onClick={() => { handleUpdate(val.id, val.pmID, val.pdmID, val.detailsDate, val.detailsTime, val.details, val.userId, "detailsDateProcedure", "detailsTimeProcedure", "detailsProcedure") }} /></div> */}
                                                                {/* <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={IconDelete} alt="Delete" onClick={() => { setRowId(val.id) }} /></div> */}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            {/* modal */}
            <div className="modal fade" id="subtestModal" data-bs-backdrop="static">
                <div className="modal-dialog" style={{ maxWidth: '60vw' }}>
                    <div className="modal-content px-1_" style={{ paddingBottom: '12px' }}>
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 text-white " id="exampleModalLabel">Investigation Test List</h1>
                            <button type="button" className="btn-close_ btnModalClose" data-bs-dismiss="modal" aria-label="Close" title='Close Window'><i className="bi bi-x-octagon"></i></button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="med-table-section pt-3" style={{ "height": "60vh" }}>
                                <table className='med-table border_ striped'>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>#</th>
                                            <th>Test Name</th>
                                            <th>Subtest Name</th>
                                            <th>Result</th>
                                            <th>Unit</th>
                                            <th>Result Date</th>
                                            {/* <th style={{ "width": "10%" }} className="text-center">Action</th> */}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {subTestCList && subTestCList.map((val, ind) => {
                                            return (
                                                <tr key={val.id}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td>{val.testname}</td>
                                                    <td>{val.subTestName}</td>
                                                    <td>{val.result}</td>
                                                    <td>{val.unitName}</td>
                                                    <td>{val.resultDateTime}</td>
                                                    {/* <td>
                                                        <div className="action-button">
                                                            <div className="actionItem" title="View" data-bs-toggle="modal" data-bs-target="#subtestModal"><img src={viewicon} className='bgicn' alt="View" title="View" /></div>
                                                        </div>
                                                    </td> */}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showToster === 1 ? <AlertToster message={message} handle={setShowToster} /> : ""}
            <Loader val={loader} />
        </>
    )
}
