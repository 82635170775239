import React,{ useEffect, useState } from 'react'
import Vector from "../../assets/images/icons/Vector.svg";
import Contact from "../../assets/images/icons/Contact.svg";


export default function PrintBloodIssue() {
  
    let [bool, setBool] = useState(true);
    let [loginData, setLoginData] = useState([]);
    let [BloodIssueData, setBloodIssueData] = useState([]);
    let [PatientDetails, setPatientDetails] = useState([]);
    let [DonorDetails, setDonorDetails] = useState([]);
    let [Objdetail, setObjdetail] = useState([]);

    let functionGetLoginData = ()=> {      
        let response = JSON.parse(window.sessionStorage.getItem("LoginData"));            
        let getLoginData = response.clientdata        
        setLoginData(getLoginData)        
       }

       const getBloodIssueData=()=>{

        let data = window.sessionStorage.getItem("BloodIssueDetails");
        setBloodIssueData(JSON.parse(data))
        setPatientDetails(JSON.parse(data).PatientData)
        setDonorDetails(JSON.parse(data).DonorDetails[0])
        setObjdetail(JSON.parse(data).issueData)
        console.log("dataaaa" , JSON.parse(data))
        console.log("PatientDetailss" , JSON.parse(data).PatientData)

       }


        useEffect(() => {

       getBloodIssueData()
       functionGetLoginData()
       setTimeout(() => {
        handlepritnt();
        }, 1000)


        }, [])
     
    //    useEffect(() => {
    //     functionGetLoginData();
    //     setDateTime(date.toLocaleDateString() + " " + date.toLocaleTimeString());
    //     setTimeout(() => {
    //         handlepritnt();
    //     }, 1000)
    // }, []);

    let handlepritnt = () => {
        document.title = 'Blood Issue'; 
        setBool(false)
        window.print("");
        window.close();
    }

  return (
    <>
            



    <div className="opdSlipContainer">
        
    <div className='water-mark'>
        {/* {loginData.logoUrl == null ? <img src={mainlogo} alt='Brand Logo' title='Brand Logo'/> : <img src={loginData.logoUrl} alt='Brand Logo' title='Brand Logo'/>} */}
        <div className='clientText'>{loginData.clientName}</div>
        </div>

        {/* ----------------Header Sec-------------- */} 
        <div className="dis-hed"
                style={{
                  height: "auto",
                  padding: "8px",
                  background: "rgb(245 245 245)",
                  alignItems: "center",
                }}
              >
                <div className="">
                  <img
                    src={loginData.logoUrl}
                    alt="Company Logo"
                    style={{width: "211px", }}
                  />
                </div>

                <div className="CompanyDetailsHeading">
                  <div className="companyNameChallan">
                  {loginData.clientName}
                  </div>
                  <div className="companyLocation">
                  {loginData.address}
                  </div>
                  <div className="companyContact">
                    <img src={Vector} alt=""></img> {loginData.emailID}
                    
                    <img src={Contact} alt=""></img> {loginData.mobileNo}
                  </div>
                </div>
                
              </div>
       

        <div className="pat-dtls text-center">
            <div className='document-title'>Blood Issue</div>
        </div>               

        <div className="pat-dtls">
        <div className='document-title'>Blood Request Details</div>
            <table className='table-certificate cert-top1 border'>
                <tbody className=''>
                    {/* <div className='document-title fs-6'>ADMIT PATIENT DETAILS</div> */}
                  <tr className=''>
                        <th>Patient Name </th><td className='ps-3 fw-semibold'>{PatientDetails && PatientDetails.patientName}</td>
                        <th>Department : </th><td className='ps-3 fw-semibold'>{PatientDetails && PatientDetails.department}</td>
                        <th>Hospital Name : </th><td className='ps-3 pe-1 fw-semibold'>{PatientDetails && PatientDetails.hospitalName} </td>
                    </tr>

                    <tr>
                        <th>Blood Group : </th><td className='ps-3 fw-semibold'>{PatientDetails && PatientDetails.bloodGroup}</td>
                        <th>Product Name : </th><td className='ps-3 fw-semibold'>{PatientDetails && PatientDetails.productName}</td>
                        <th>Requested Quantity : </th><td className='ps-3 fw-semibold'>{PatientDetails && PatientDetails.requestedQuantity}</td>
                    </tr>

                    <tr>
                        <th>Issue Quantity: </th><td className='ps-3 fw-semibold'>{Objdetail && Objdetail.issuedQuantity}</td>
                        <th>Bag Received By: </th><td className='ps-3 fw-semibold'>{Objdetail && Objdetail.bagReciviedBy}</td>
                        <th>Bag Recivied By Contact : </th><td className='ps-3 fw-semibold'>{Objdetail && Objdetail.bagReciviedByContact}</td>
                    </tr> 
                    <tr>
                        <th>Bag Recivied By Address: </th><td className='ps-3 fw-semibold'>{Objdetail && Objdetail.bagReciviedByAddress}</td>
                        
                    </tr> 

                </tbody>
            </table>


          

        </div>
       
        <div className="pat-dtls">
        <div className='document-title'>Donor Details</div>
            <table className='table-certificate cert-top1 border'>
                <tbody className=''>
                  <tr className=''>
                        <th>Donor Name : </th><td className='ps-3 fw-semibold'>{DonorDetails && DonorDetails.donorName}</td>
                        <th>Age/Gender: </th><td className='ps-3 fw-semibold'>{DonorDetails && DonorDetails.age + '' + DonorDetails && DonorDetails.gender}</td>
                        <th>Bag No : </th><td className='ps-3 pe-1 fw-semibold'>{DonorDetails && DonorDetails.bagSerialNumber} </td>
                    </tr>

                    <tr>
                        <th>Blood Group: </th><td className='ps-3 fw-semibold'>{DonorDetails && DonorDetails.groupName}</td>
                        <th>Product: </th><td className='ps-3 fw-semibold'>{DonorDetails && DonorDetails.productName}</td>
                        <th>Expiry: </th><td className='ps-3 fw-semibold'>{DonorDetails && DonorDetails.expiryDate}</td>
                    </tr>
                </tbody>

            </table>


          

        </div>
       

       

        <div className='signatureSection cert-top3'>
            <div className='borderBottom'>Authorized  Signature</div>
        </div>

        <div className="opdSlipFooter">
            <a href="https://criteriontechnologies.com/" target="_blank" rel="noopener noreferrer">www.criteriontechnologies.com</a>
        </div>
    </div>

</>
  )
}
