const TransferTicket = async (obj)=>{
    let url=window.AppbaseUrl+'/api/SupportTicket/TransferTicket?TicketID='+obj.TicketID+'&TransferBy='+obj.TransferBy+'&TransferTo='+obj.TransferTo+'&Remark='+obj.Remark;
    let head = {'Content-Type':'application/json','accept':'*/*',}
    let resposne="";
    await fetch(url,{
        method:'POST',
        // method:'PUT',
        headers:head,
        body:JSON.stringify()
    }).then((res)=>res.json()).then(data=>{resposne=data}).catch(error=>{resposne=error});
    return resposne;
 }
 export default TransferTicket;