import React, { useEffect, useState } from "react";
import TosterUnderProcess from '../../Component/TosterUnderProcess';
import Toster from '../../Component/Toster';
import Loder from '../../Component/Loader';
import Select from 'react-select';
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import update from '../../assets/images/icons/update.svg';
import updatee from '../../assets/images/icons/updatee.svg';
import clearIcon from '../../assets/images/icons/clear.svg';
import deleteBtnIcon from '../../assets/images/icons/delete.svg';
import editBtnIcon from '../../assets/images/icons/edit.svg';
import PostDialysisType from "../API/DialysisTypeMaster/PostDialysisType";
import DeleteDialysisType from "../API/DialysisTypeMaster/DeleteDialysisType";
import PutDialysisType from "../API/DialysisTypeMaster/PutDialysisType";
import GetDialysisType from "../API/DialysisTypeMaster/GetDialysisType";




 export default function DialysisType() {


  const [showUnderProcess, setShowUnderProcess] = useState(0);
  const [showToster, setShowToster] = useState(0);
  const [tosterMessage, setTosterMessage] = useState("");
  const [tosterValue, setTosterValue] = useState(0);
  const [showLoder, setShowLoder] = useState(0);
  const [rowID, setRowID] = useState(0);
  const [isUpdateBtnShow, setIsUpdateBtnShow] = useState(false);
  const [dialysisType, setdialysisType] = useState('');
  const [dialysisTypeTable, setdialysisTypeTable] = useState([])
  const [newlyAddedRowIndex, setNewlyAddedRowIndex] = useState(null);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [isNewRowAdded, setIsNewRowAdded] = useState(false);

  let [userID, setUserID] = useState(JSON.parse(sessionStorage.getItem("LoginData")).userId);
  const clientID  = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
  



 


  const handleOnChange = (e) => {

    setIsNewRowAdded(false)
    setNewlyAddedRowIndex(null);
    document.getElementById('errdialysisType').style.display = 'none';
  
    const { name, value } = e.target;
    if (name === 'dialysisType') {
      setdialysisType(value);
    }

  };


const GetallDialysisType = async()=>{
let DialysisType = await GetDialysisType()
if(DialysisType.status === 1){
    setdialysisTypeTable(DialysisType.responseValue)
}
}


  const handleOnSave = async () => {
  

    if (dialysisType.trim() === '') {
      document.getElementById('errdialysisType').innerHTML = 'Please Enter Dialysis Type';
      document.getElementById('errdialysisType').style.display = 'block';
      return;
    }


    const obj = {
        dialysisType: dialysisType,
        userId: userID,
        clientId: clientID
    };

    let data = await PostDialysisType(obj);
    if (data.status === 1) {
      setShowUnderProcess(0);
      setShowToster(1);
      setTosterValue(0);
      setTosterMessage("Data Saved Successfully!");
      GetallDialysisType()
      setIsNewRowAdded(true);
      handleClear();
      setTimeout(() => {
        setShowToster(0);
 setIsNewRowAdded(null)

      }, 2000);
    } else {
      setShowUnderProcess(0);
      setShowToster(1);
      setTosterValue(1);
      setTosterMessage("Already Exist!");
      setTosterMessage(data.responseValue);
      setTimeout(() => {
        setShowToster(0);
      }, 2000);
    }
  };

  const handleClear = () => {
    document.getElementById('errdialysisType').style.display = 'none';
    setdialysisType('')

  };


  const edit = (Dialysis , index) => {
    setRowID(Dialysis.id)
    setdialysisType(Dialysis.dialysisType)
    setIsUpdateBtnShow(true)
    setNewlyAddedRowIndex(index)
  }

  const handleUpdate = async () => {

   
    if (dialysisType === '') {
      document.getElementById('errdialysisType').innerHTML = 'Please Enter Meter Number';
      document.getElementById('errdialysisType').style.display = 'block';
      return;
    }



    const obj = {
      id: rowID,
      dialysisType: dialysisType,
      userID: userID,
      clientId: clientID
    };
    const data = await PutDialysisType(obj);
    if (data.status === 1) {
      setShowUnderProcess(0);
      setTosterValue(0);
      setShowToster(1);
      setTosterMessage('Data Updated Successfully!');
      GetallDialysisType()
      handleClear();
      setIsNewRowAdded(false);
      setTimeout(() => {
        setShowToster(0);
        setNewlyAddedRowIndex(null);
      }, 2000);

      setIsUpdateBtnShow(false);
    } else {

      setShowUnderProcess(0);
      setShowToster(1);
      setTosterMessage(data.responseValue);
      setTosterValue(1);
      setTimeout(() => {
        setShowToster(0);
      }, 2000);
    }
  };
  const handleCancel = () => {
    handleClear()
    setIsUpdateBtnShow(false);
    setEditRowIndex(null)
    setNewlyAddedRowIndex(null);

  };

  const deleteRow = async () => {
    setShowUnderProcess(1);
   
    const obj = {
      id: rowID,
      userId: userID,
      clientId : clientID
    }

    let data = await DeleteDialysisType(obj);
    if (data.status === 1) {
      setShowUnderProcess(0);
      setTosterValue(0);
      setShowToster(1);
      setTosterMessage("Data Deleted Successfully!!");
      GetallDialysisType()
      setIsUpdateBtnShow(false)
      setNewlyAddedRowIndex(false)
     
      handleClear()
      setTimeout(() => {
        setShowToster(0);


      }, 1000)
    }
    else {
      setShowUnderProcess(0)
      setShowToster(0)
      setTosterMessage(data.responseValue)
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0);
      }, 2000)
    }
  };




  useEffect(() => {
    GetallDialysisType()
   
  }, []);


  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="med-box">
               
                <div className="inner-content">
                <div className='fieldsett-in col-md-12'>
                <div className="fieldsett">
                    <span className='fieldse'>Dialysis Type</span>
                  <div className='row'>
              
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Dialysis Type<span className="starMandatory">*</span></label>
                          <input value={dialysisType} id="dialysisType" type="text" className="form-control form-control-sm" name="dialysisType" placeholder= "Enter Dialysis Type" onChange={handleOnChange} />
                          <small id="errdialysisType" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>



                        <div className="col-xxl-4 col-xl-3 col-lg-4 col-md-6 mb-3 relative">
                          <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>

                          {showUnderProcess === 1 ? <><TosterUnderProcess />  </> :
                            showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} />
                              :
                              <div>
                                {isUpdateBtnShow !== true ? <>
                                  <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleOnSave}><img src={saveButtonIcon} className='icnn' alt="" />Save</button>
                                  <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt="" />Clear</button>
                                </> :
                                  <>
                                    <button type="button" className="btn btn-save btn-sm mb-1 me-1 " onClick={handleUpdate} > <img src={updatee} className='icnn' alt=""/>Update</button>
                                    <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleCancel} >Cancel</button>
                                  </>
                                }
                              </div>
                          }
                        </div>
                      </div>
                    </div>
                    </div>
   </div>

              </div>
            </div>


            {/* table is made using getAllItemMaster API and mapped the data   */}


            <div className="col-12 mt-3">
              <div className="med-table-section" style={{ "height": "80vh" }}>
                <table className="med-table border_ striped">
                  <thead style={{ zIndex: '0' }}>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>Dialysis Type</th>
                      
                    
                      <th style={{ "width": "10%" }} className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dialysisTypeTable && dialysisTypeTable.map((data, index) => {
                      const isNewRow = newlyAddedRowIndex === index;
                      const isEditing = index === editRowIndex;
                      return (
                        <tr className={index === dialysisTypeTable.length - 1 && isNewRowAdded ? 'new-row' : '' || isNewRow ? 'new-row' : ''} key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td>{data.dialysisType}</td>
                          
      

                          <td>
                            <div className="action-button">
                              <div
                                data-bs-toggle="tooltip"
                                data-bs-title="Edit Row"
                                data-bs-placement="bottom"

                              >
                                <img src={editBtnIcon} className={isEditing ? 'edited-row' : ''} alt='' onClick={() => { edit(data, index) }} />
                              </div>
                              <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowID(data.id); }} />
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* -----------------------Start Delete Modal Popup-------------------    */}

        <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
          <div className="modal-dialog modalDelete">
            <div className="modal-content">
              <div className="modal-body modelbdy text-center">
                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                <div className='popDeleteTitle mt-3'>Delete?</div>
                <div className='popDeleteContent'>Are you sure you want to delete?</div>
              </div>
              <div className="modal-footer1 text-center">

                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn-delete popBtnDelete" data-bs-dismiss="modal" onClick={deleteRow} >Delete</button>
              </div>
            </div>
          </div>
        </div>
        {/* -----------------------End Delete Modal Popup---------------------  */}
        {
          showLoder === 1 ? <Loder val={showLoder} /> : ""
        }
      </section>

    </>

  )
}


