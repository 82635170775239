
async function getheadmenu() {
    let token ='bearer '+ window.AppToken;

    let url = window.UserbaseUrl + `/api/Users/GetUserWiseMenuHead?userId=${window.userId}&clientId=${window.clientId}`;
    let head = { "Content-Type": "application/JSON", accept: '*/*','Authorization': token};
  
    let response = await fetch(url, {
      headers: head,
      method: 'GET',

    })
      .then((res) => res.json())
      .then();
  
    return response;
  }
  export default getheadmenu;