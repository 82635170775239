import React, { useEffect, useState } from 'react'
import i18n, { t } from "i18next";
import TableContainer from '../../../Component/TableContainer';
import Heading from '../../../Component/Heading';
import PersonWorkspace from '../../../assets/images/dmsimg/PersonWorkspace.svg'
import clearIcon from '../../../assets/images/icons/clear.svg';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg'
import DropdownWithSearch from '../../../Component/DropdownWithSearch'
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import GetGodownMaster from '../../API/Master/GodownMaster/GetGodownMaster';
import GetVendorMaster from '../../API/VendorMaster/GetVendorMaster';
import SuccessToster from '../../../Component/SuccessToster';
import AlertToster from '../../../Component/AlertToster';
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Toster from '../../../Component/Toster';
import GetAdditionalChargeType from '../../API/Master/AdditionalChargeType/GetAdditionalChargeType';
import NoDataFound from '../../../assets/images/icons/No data-rafiki.svg'
import vectorPrint from '../../../assets/images/icons/vectorPrint.png';
import GetStockInReport from '../../API/StockIn/GetStockInReport';
import Loader from '../../../Component/Loader';

export default function StockInReport() {
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    let [stockList, setStockList] = useState("")
    let [stockListMain, setStockListMain] = useState("")
    let [godownList, setGodownList] = useState("")
    let [supplierList, setSupplierList] = useState("")
    let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [showToster, setShowToster] = useState(0);
    let [tosterMessage, setTosterMessage] = useState("");
    let [tosterValue, setTosterValue] = useState(0);
    let [showLoder, setShowLoder] = useState(0);
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [updateBool, setUpdateBool] = useState(0);


    let [clearDropdown, setClearDropdown] = useState(0)
    let [editGodown, setEditGodown] = useState("")
    let [editSupplier, setEditSupplier] = useState("")

    const [noDataStatus, setNoDataStatus] = useState(false);
    let [sendForm, setSendForm] = useState({
        "userId": window.userId,
        "clientId": window.clientId,
        "FromDate": '',
        "ToDate": '',
        "PoNo": '',
        "BillNo": '',
        "SupplierID": 0,
        "GodownID": 0,

    })

    //Handle Change
    let handleChange = (e) => {
        // clearValidationErrMessage();
        let name = e.target.name;
        let value = e.target.value;
        setSendForm(sendForm => ({
            ...sendForm,
            [name]: value
        }))
    }
    //Get Data
    const getStockdata = async () => {
        // setShowLoder(1);
        const response = await GetStockInReport(clientID, sendForm.FromDate ? sendForm.FromDate : '', sendForm.ToDate ? sendForm.ToDate : '', sendForm.PoNo ? sendForm.PoNo : '', sendForm.BillNo ? sendForm.BillNo : '', sendForm.SupplierID ? sendForm.SupplierID : 0, sendForm.GodownID ? sendForm.GodownID : 0);
        if (response.status === 1) {
            setStockList(response.responseValue);
            setStockListMain(response.responseValue)
            setNoDataStatus(false)
        }
        else{
            setStockList("");
            setNoDataStatus(true)
        }
    }
    //Get Godown
    const getGodownData = async () => {
        const response = await GetGodownMaster(clientID);
        if (response.status === 1) {
            setGodownList(response.responseValue);
        }
    }
    //Get Supplier
    const getSupplierdata = async () => {
        const response = await GetVendorMaster(clientID);
        if (response.status === 1) {
            setSupplierList(response.responseValue);
        }
    }

    let handleClear = (value) => {
        setClearDropdown(value);
        setEditGodown(0)
        setEditSupplier(0)
        setSendForm({
            "userID": window.userId,
            "clientID": window.clientId,
            "FromDate": '',
            "ToDate": '',
            "PoNo": '',
            "BillNo": '',
            "SupplierID": 0,
            "GodownID": 0,
        })
        getStockdata();
        setUpdateBool(0)
    }
    const formatDateTime = (dateString) => {
        // const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-IN', options);
    };
    useEffect(() => {
        getStockdata();
        getGodownData();
        getSupplierdata();
    }, [])
    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="med-box">
                                <div className="title mt-2 pe-2 ps-2">{t("Stock Report")}</div>
                                <div className="inner-content mt-2">
                                    <div className="d-flex flex-wrap align-content-end gap-3">
                                        <div className="mb-2 me-2">
                                            <label htmlFor="*" className="form-label">PO No.<span className="starMandatory"></span></label>
                                            <input type="text" className="form-control form-control-sm" placeholder="PO No." id="PoNo" name="PoNo" value={sendForm.PoNo} onChange={handleChange} />
                                        </div>
                                        <div className="mb-2 me-2">
                                            <label htmlFor="*" className="form-label">Bill No.<span className="starMandatory"></span></label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Bill No." id="BillNo" name="BillNo" value={sendForm.BillNo} onChange={handleChange} />
                                        </div>
                                        <div className="mb-2 me-2">
                                            <label htmlFor="*" className="form-label">Godown<span className="starMandatory"></span></label>
                                            {godownList && <DropdownWithSearch defaulNname={t("Select Godown")} name="GodownID" list={godownList} valueName="id" displayName="godownName" editdata={editGodown} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                        </div>
                                        <div className="mb-2 me-2">
                                            <label htmlFor="*" className="form-label">Supplier<span className="starMandatory"></span></label>
                                            {supplierList && <DropdownWithSearch defaulNname={t("Select Supplier")} name="SupplierID" list={supplierList} valueName="id" displayName="vendorName" editdata={editSupplier} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}

                                        </div>
                                        <div className="mb-2 me-2">
                                            <label htmlFor="*" className="form-label">Bill From Date<span className="starMandatory"></span></label>
                                            <input type='date' className='form-control form-control-sm reportDate' id='FromDate' name='FromDate' value={sendForm.FromDate} onChange={handleChange} />
                                        </div>
                                        <div className="mb-2 me-2">
                                            <label htmlFor="*" className="form-label">Bill To Date<span className="starMandatory"></span></label>
                                            <input type='date' className='form-control form-control-sm reportDate' id='ToDate' name='ToDate' value={sendForm.ToDate} onChange={handleChange} />
                                        </div>
                                        <div className="mb-2 relative">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                            <div>
                                                {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                                    <>
                                                        {showToster === 1 ?
                                                            <Toster value={tosterValue} message={tosterMessage} />

                                                            : <div>
                                                                <>
                                                                    <button type="button" className="btn btn-save btn-save-fill btn-sm me-1" onClick={getStockdata}><i className='fa fa-search'></i> {t("Search")}</button>
                                                                    <button type="button" className="btn btn-clear btn-sm me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt='' />{t("Clear")}</button>
                                                                </>

                                                            </div>}
                                                    </>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-12 mt-3">
                            <div className="med-table-section med-table-section alldemandtbl pt-2" style={{ height: '74vh', padding: '0 5px' }}>
                                <table className='med-table border_ striped'>
                                    <thead>
                                        <tr style={{ background: '#EAF6FF', borderRadius: '10px' }}>
                                            <th className="text-center" >#</th>
                                            <th>{t("PO No.")}</th>
                                            <th>{t("Bill No.")}</th>
                                            <th>{t("Invoice Date")}</th>
                                            <th>{t("Godown")}</th>
                                            <th>{t("Supplier Name")}</th>
                                            <th>{t("Additional Charge Details")}</th>
                                            <th>Total Amount Exc. GST (<i className="bi bi-currency-rupee"></i>) </th>
                                            <th>Total Discount (<i className="bi bi-currency-rupee"></i>)</th>
                                            <th>Total GST (<i className="bi bi-currency-rupee"></i>)</th>
                                            <th>"Total Gross Amount (<i className="bi bi-currency-rupee"></i>)</th>
                                            <th className="text-center">{t("Item Details")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {noDataStatus ? (
                                            <tr>
                                                <td colSpan="9" className="text-center">
                                                    <div className='imageNoDataFound'>
                                                        <img src={NoDataFound} alt="imageNoDataFound" />
                                                    </div>
                                                </td>
                                            </tr>
                                        ) :
                                            <>
                                                {stockList && stockList.map((val, ind) => {
                                                    return (
                                                        <tr key={ind}>
                                                            <td className="text-center" style={{ fontWeight: '400', fontSize: '14px' }}>{ind + 1}</td>
                                                            <td>{val.poNo}</td>
                                                            <td>{val.billNo}</td>
                                                            <td>{formatDateTime(val.billDate)}</td>
                                                            <td>{val.godownName}</td>
                                                            <td>{val.supplierName}</td>
                                                            <td>
                                                                {val.additionalchargeJson && JSON.parse(val.additionalchargeJson).map((charge, i) => {
                                                                    return (
                                                                        <span className='addnldt' key={i}>
                                                                            {/* <strong className="servicedtls">Charge Type: &nbsp;</strong> */}
                                                                            <strong>{charge.AdditionChargeNme}:</strong><i className="bi bi-currency-rupee"></i>{parseFloat(charge.amount).toFixed(2)}<br/>
                                                                            {/* <strong className="servicedtls">Amount: &nbsp;</strong> */}
                                                                            {/* {charge.amount}<br /> */}
                                                                        </span>
                                                                    )
                                                                })}
                                                            </td>
                                                            {/* <td>{val.totalAmount}</td> */}
                                                            <td>{parseFloat(val.totalAmount).toFixed(2)}</td>
                                                            <td>{parseFloat(val.totalDiscount).toFixed(2)}</td>
                                                            <td>{parseFloat(val.totalGST).toFixed(2)}</td>
                                                            <td>{parseFloat(val.totalGrossAmount).toFixed(2)}</td>
                                                            <td className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <span data-bs-toggle="modal" data-bs-target={"#itemDetailsBackdrop" + ind}>
                                                                    <i className="fa fa-eye actionedit viewaction "></i>
                                                                </span>

                                                                {/* <div className="modal fade" id={"itemDetailsModal" + ind} tabIndex="-1" aria-labelledby="itemDetailsModalLabel" aria-hidden="true"> */}
                                                                <div className="modal fade" id={"itemDetailsBackdrop" + ind} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="itemDetailsBackdropLabel" aria-hidden="true">
                                                                    <div className="modal-dialog  modal-xl additonaldetails" style={{ margin: '5% auto' }}>
                                                                        <div className="modal-content">
                                                                            <div className="modal-header_ bg-clrr mt-0 pt-0 mb-0 pb-0">
                                                                                <div className="heading text-wrap">Item Details</div>
                                                                                <div>
                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                </div>
                                                                            </div>
                                                                            <div className="modal-body mt-0 pt-0">
                                                                                <div className="med-box" style={{ boxShadow: 'inherit' }}>
                                                                                    <div className="inner-content mt-0 pt-0">
                                                                                        <div className="med-table-section med-table-section alldemandtbl pt-2" style={{ height: '60vh', padding: '0 5px' }}>
                                                                                            <table className='med-table border_ striped'>
                                                                                                <thead>
                                                                                                    <tr style={{ background: '#EAF6FF', borderRadius: '10px' }}>
                                                                                                        <th className="text-center" >#</th>
                                                                                                        <th>{t("Item Name")}</th>
                                                                                                        <th>{t("Brand")}</th>
                                                                                                        <th>{t("Item Code")}</th>
                                                                                                        <th>{t("Serial / Batch No.")}</th>
                                                                                                        <th>{t("Exp. Date / Warranty Till")}</th>
                                                                                                        <th>{t("Unit")} </th>
                                                                                                        <th>{t("Quantity")}</th>
                                                                                                        <th>Rate (<i className="bi bi-currency-rupee"></i>)</th>
                                                                                                        <th>GST (<i className="bi bi-currency-rupee"></i>)</th>
                                                                                                        <th>Discount (<i className="bi bi-currency-rupee"></i>)</th>
                                                                                                        <th>Total Amount (<i className="bi bi-currency-rupee"></i>)</th>
                                                                                                        <th>{t("Equipment Additional Details")}</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {val.inwardSubJson && JSON.parse(val.inwardSubJson).map((data, index) => {
                                                                                                        return (
                                                                                                            <tr>
                                                                                                                <td className="text-center" style={{ fontWeight: '400', fontSize: '14px' }}>{index + 1}</td>
                                                                                                                <td>{data.itemName}</td>
                                                                                                                <td>{data.brandName}</td>
                                                                                                                <td>{data.itemCode}</td>
                                                                                                                <td>{data.batchNo}</td>
                                                                                                                <td>{formatDateTime(data.expDate)}</td>
                                                                                                                <td>{data.unitID}</td>
                                                                                                                <td>{data.qty}</td>
                                                                                                                <td>{parseFloat(data.rate).toFixed(2)}</td>
                                                                                                                <td>{parseFloat(data.gst).toFixed(2)}</td>
                                                                                                                <td>{parseFloat(data.discount).toFixed(2)}</td>
                                                                                                                <td>{parseFloat(data.totalAmount).toFixed(2)}</td>
                                                                                                                {/* <td>
                                                                                                                    <span>
                                                                                                                        <strong className="servicedtls">Service Type: &nbsp;</strong>{data.serviceTypeName}<br />
                                                                                                                        <strong className="servicedtls">Service Engineer Name: &nbsp;</strong> {data.serviceEngineerName}<br />
                                                                                                                        <strong className="servicedtls">Service Engineer Contact:&nbsp;</strong>{data.serviceEngineerContact}<br />
                                                                                                                    </span>
                                                                                                                </td> */}
                                                                                                                <td>
                                                                                                                    {(data.serviceTypeName || data.serviceEngineerName || data.serviceEngineerContact) && (
                                                                                                                        <span>
                                                                                                                            {data.serviceTypeName && (
                                                                                                                                <>
                                                                                                                                    <strong className="servicedtls">Service Type: &nbsp;</strong>{data.serviceTypeName}<br />
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                            {data.serviceEngineerName && (
                                                                                                                                <>
                                                                                                                                    <strong className="servicedtls">Service Engineer Name: &nbsp;</strong> {data.serviceEngineerName}<br />
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                            {data.serviceEngineerContact && (
                                                                                                                                <>
                                                                                                                                    <strong className="servicedtls">Service Engineer Contact: &nbsp;</strong>{data.serviceEngineerContact}<br />
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        )
                                                                                                    })}

                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}


                                            </>}

                                    </tbody>
                                </table>
                            </div>
                        </div>



                    </div>

                </div>
                {
                    showLoder === 1 ? <Loader val={showLoder} /> : ""
                }
                {/* Toaster */}
                {
                    isShowToaster === 1 ?
                        <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                }

                {
                    showAlertToster === 1 ?
                        <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                }
            </section>
        </>
    )
}
