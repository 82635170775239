
let AllotItemsInDemand = async(obj) => {
   let url = window.InventoryBaseUrl+'/api/InventoryRaiseDemand/AllotItemsInDemand';
  // let url ='https://localhost:7264/api/InventoryRaiseDemand/AllotItemsInDemand';
  let head = {'Content-Type': 'application/json', 'accept': '*/*',};
  let response ="";
  await fetch(url,{
    method:'PUT',
    headers: head,
    body: JSON.stringify(obj),
  }).then((res) => res.json())
    .then(data => {response = data})
    .catch(error => {response = error})
    return response;
}
export default AllotItemsInDemand