import React, { useEffect, useState, useRef } from 'react'
import GetDoctorList from '../../../src/Registartion/API/GET/GetDoctorList'
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import InvestigationsRows from './InvestigationsRows';
import ProvisonalDiagnosisMainComponent from './ProvisonalDiagnosisMainComponent';
import GetPatientDetailsByUHID from '../../../src/Clinical/API/IPD/personalDashboardIndexSecond/GetPatientDetailsByUHID'
import ChiefCaomplainComponent from './ChiefCaomplainComponent'
import PostAssessmentFormPhysiotherapy from '../Procedure/API/PostAssessmentFormPhysiotherapy'
import SuccessToster from '../../../src/Component/SuccessToster'
import AlertToster from '../../../src/Component/AlertToster'
import GetAssessmentPhysiotherapy from '../Procedure/API/GetAssessmentPhysiotherapy'
import imgPrint from "../../assets/images/icons/imgPrint.svg";
import { json } from 'react-router-dom';
import { map } from 'highcharts';
import MedicalHistoryComponent from './MedicalHistoryComponent';
import { t } from 'i18next';
import IconDateTime from "../../assets/images/icons/IconDateTime.svg";
import IconRestraints from "../../assets/images/icons/IconRestraints.svg";
import IconWard from "../../assets/images/icons/IconWard.svg";
import workSpace from '../../assets/images/icons/person-workspace.svg'
import contact from '../../assets/images/icons/Contact.svg'
import GetDepartmentList from '../../Registartion/API/GET/GetDepartmentList';
import PatientDeatils from '../../Component/PatientDeatils'


export default function AssessmentFormPhysiotherapy() {
    let [dateTime, setDateTime] = useState();
    let date = new Date()
    let [departmentList, setDepartmentList] = useState([]);
    let [getAsstPhysioData, setGetAsstPhysioData] = useState([]);
    let [procedureData, setProcedureData] = useState([]);
    let [palpationData, setPalpationData] = useState([]);
    let [observationData, setObservationData] = useState([]);
    let [riskScreeningData, setRiskScreeningData] = useState([]);
    let [riskPreventionData, setRiskPreventionData] = useState([]);
    let [diagnosisData, setDiagnosisData] = useState([]);
    let [symptomsData, setSymptomsData] = useState([]);
    let [inputValues, setInputValues] = useState('');
    let [InvestigationJSON, setInvestigationJSON] = useState([]);
    let [saveDiagnosis, setSaveDiagnosis] = useState([]);
    let [saveSymtoms, setSaveSymtoms] = useState([]);
    let [saveMedicalHistory, setSaveMedicalHistory] = useState([]);
    let [patientData, setPatientData] = useState([]);
    let [contactNo, setContactNo] = useState('');
    let [doctor, setDoctor] = useState('');
    let [selectedBuilt, setSelectedBuilt] = useState(null);
    let [selectedPostural, setSelectedPostural] = useState(null);
    let [selectedMuscle, setSelectedMuscle] = useState(null);
    let [selectedDeformity, setSelectedDeformity] = useState(null);
    let [selectedSwelling, setSelectedSwelling] = useState(null);
    let [selectedGaitDeviation, setSelectedGaitDeviation] = useState(null);
    let [selectedAppliances, setSelectedAppliances] = useState(null);
    let [selectedTenderness, setSelectedTenderness] = useState(null);
    let [selectedInsignificant, setSelectedInsignificant] = useState(null);
    let [selectedWarmth, setSelectedWarmth] = useState(null);
    let [selectedMuscleSpasm, setSelectedMuscleSpasm] = useState(null);
    let [selectedOedema, setSelectedOedema] = useState(null);
    let [selectedCrepitus, setSelectedCrepitus] = useState(null);
    let [selectedTone, setSelectedTone] = useState(null);
    let [selectedFallRiskAdult, setSelectedFallRiskAdult] = useState(null);
    let [selectedFallRiskPaedia, setSelectedFallRiskPaedia] = useState(null);
    let [selectedFallRiskProtocol, setSelectedFallRiskProtocol] = useState(null);
    let [selectedFallRiskProtocolBells, setSelectedFallRiskProtocolBells] = useState(null);
    let [selectedFallRiskPaediaFall, setSelectedFallRiskPaediaFall] = useState(null);
    let [selectedFallRiskPaediaMobility, setSelectedFallRiskPaediaMobility] = useState(null);
    let [selectedFallRiskPaediaNeurological, setSelectedFallRiskPaediaNeurological] = useState(null);
    let [selectedFallRiskAdultAge, setSelectedFallRiskAdultAge] = useState(null);
    let [selectedFallRiskAdultHistory, setSelectedFallRiskAdultHistory] = useState(null);
    let [selectedFallRiskAdultWalk, setSelectedFallRiskAdultWalk] = useState(null);
    let [selectedFallRiskAdultNeuro, setSelectedFallRiskAdultNeuro] = useState(null);
    let [deformity, setDeformity] = useState(null);
    let [postural, setPostural] = useState(null);
    let [muscle, setMuscle] = useState(null);
    let [swelling, setSwelling] = useState(null);
    let [gait, setGait] = useState(null);
    let [appliances, setAppliances] = useState(null);
    let [tenderness, setTenderness] = useState(null);
    let [warmth, setWarmth] = useState(null);
    let [muscleSpasm, setMuscleSpasm] = useState(null);
    let [oedema, setOedema] = useState(null);
    let [tone, setTone] = useState(null);
    let [crepitus, setCrepitus] = useState(null);
    let [showSuccessToster, setShowSuccessToster] = useState(0)
    let [showAlertToster, setShowAlertToster] = useState(0)
    let [showMessage, setShowMeassage] = useState("")
    const [showToster, setShowToster] = useState(0);
    let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [referredFrom, setReferredFrom] = useState('');
    let [Saverow, setSaverow] = useState([])

    let clientID = JSON.parse(sessionStorage.getItem("LoginData")).clientId
    let requiredUHID = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].uhId;

    const handleClearInvestigation=(row)=>{
        setSaverow([row])
        }

    const formatDateTime = (dateTimeString) => {
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false // 24-hour format
        };
        const date = new Date(dateTimeString);
        const formattedDate = date.toLocaleString('en-IN', options);
    
        // Extracting date and time parts and formatting them separately
        const [datePart, timePart] = formattedDate.split(', ');
        const [day, month, year] = datePart.split('/');
        const [hour, minute] = timePart.split(':');
    
        // Constructing the desired format
        const formattedDateTime = `${day}-${month}-${year} ${hour}:${minute}`;
        return formattedDateTime;
    };

    const formatDateTimeNew = (dateTimeString) => {
        const date = new Date(dateTimeString);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const getCurrentDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const getCurrentTime = () => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };



    let getdata = async () => {
        try {
        let response = await GetDepartmentList()
        let responseptdata = await GetPatientDetailsByUHID(requiredUHID)
        let data = await GetAssessmentPhysiotherapy(requiredUHID, clientID);
        if (response.status === 1) {
            setDepartmentList(response.responseValue)
        }
        if (responseptdata.status === 1) {
            setPatientData(responseptdata.responseValue)
            const data = responseptdata.responseValue
            const contact = data[0].mobileNo
            const doc = data[0].doctorName
            setContactNo(contact)
            setDoctor(doc)

        }

        if (data.status === 1) {
           
            const mriData = data.responseValue.map(item => {
                return {
                    ...item,
                    procedure: item.procedure ? JSON.parse(item.procedure) : [],
                    observation: item.observation ? JSON.parse(item.observation) : [],
                    palpation: item.palpation ? JSON.parse(item.palpation) : [],
                    riskScreening: item.riskScreening ? JSON.parse(item.riskScreening) : [],
                    riskPrevention: item.riskPrevention ? JSON.parse(item.riskPrevention) : [],
                    diagnosisJson: item.diagnosisJson ? JSON.parse(item.diagnosisJson) : [],
                    symtomnsJson: item.symtomnsJson ? JSON.parse(item.symtomnsJson) : [],
                    historyJson: item.historyJson ? JSON.parse(item.historyJson) : [],

                };
            });
            setGetAsstPhysioData(mriData)
            let allProcedures = [];
            let allObservation = [];
            let allPalpation = [];
            let allRiskScreening = [];
            let allRiskPrevention = [];
            let allDiagnosis = [];
            let allSymptoms = [];
            let allHistory = [];
            data.responseValue.forEach(item => {
                allProcedures.push(item.procedure);
                allObservation.push(item.observation);
                allPalpation.push(item.palpation);
                allRiskScreening.push(item.riskPrevention);
                allRiskPrevention.push(item.riskPrevention);
                allDiagnosis.push(item.diagnosisJson);
                allSymptoms.push(item.symtomnsJson);
                allHistory.push(item.historyJson);
            });


        }

        console.log("procedureData", procedureData)
    } catch (e) {
        setShowAlertToster(1)
        setShowMeassage('An unexpected error occurred.')
      }
    }

    const handleInvestigationJSON = (temp, data) => {
        setInvestigationJSON(temp, data);

    };

    const handleBuiltCheckboxChange = (event) => {
        clearValidationErrMessage()
        setSelectedBuilt(event.target.value === selectedBuilt ? null : event.target.value);
        console.log("selectedBuilt", selectedBuilt)

    };

    const handlePosturalCheckboxChange = (event) => {
        // document.getElementById('specify').value = ''
        clearValidationErrMessage()

        setSelectedPostural(event.target.value === selectedPostural ? null : event.target.value);
        console.log("selectedPostural", selectedPostural)
    };

    const handleMuscleCheckboxChange = (event) => {
        // document.getElementById('specifyMuscle').value = ''
        clearValidationErrMessage()
        setSelectedMuscle(event.target.value === selectedMuscle ? null : event.target.value);
        console.log("selectedMuscle", selectedMuscle)
    };

    const handleDeformityCheckboxChange = (event) => {
        // document.getElementById('specifyDeform').value = ''
        clearValidationErrMessage()
        setSelectedDeformity(event.target.value === selectedDeformity ? null : event.target.value);
        console.log("selectedDeformity", selectedDeformity)
    };

    const handleSwellingCheckboxChange = (event) => {
        // document.getElementById('specifySwelling').value = ''
        clearValidationErrMessage()
        setSelectedSwelling(event.target.value === selectedSwelling ? null : event.target.value);
        console.log("selectedSwelling", selectedSwelling)
    };

    const handleGaitDeviationCheckboxChange = (event) => {
        // document.getElementById('specifyGait').value = ''
        clearValidationErrMessage()
        setSelectedGaitDeviation(event.target.value === selectedGaitDeviation ? null : event.target.value);
        console.log("selectedGaitDeviation", selectedGaitDeviation)
    };

    const handleAppliancesCheckboxChange = (event) => {
        // document.getElementById('specifyAppliances').value = ''
        clearValidationErrMessage()
        setSelectedAppliances(event.target.value === selectedAppliances ? null : event.target.value);
        console.log("selectedAppliances", selectedAppliances)
    };

    const handleInsignificantCheckboxChange = (event) => {
        // clearValidationErrMessage()
        setSelectedInsignificant(event.target.value === selectedInsignificant ? null : event.target.value);
        console.log("selectedInsignificant", selectedInsignificant)
    };

    const handleTendernessCheckboxChange = (event) => {
        // document.getElementById('specifyTenderness').value = ''
        clearValidationErrMessage()
        setSelectedTenderness(event.target.value === selectedTenderness ? null : event.target.value);
        console.log("selectedTenderness", selectedTenderness)
    };

    const handleWarmthCheckboxChange = (event) => {
        // document.getElementById('specifyWarmth').value = ''
        clearValidationErrMessage()
        setSelectedWarmth(event.target.value === selectedWarmth ? null : event.target.value);
        console.log("selectedWarmth", selectedWarmth)
    };

    const handleMuscleSpasmCheckboxChange = (event) => {
        // document.getElementById('specifyMuscleSpasm').value = ''
        clearValidationErrMessage()
        setSelectedMuscleSpasm(event.target.value === selectedMuscleSpasm ? null : event.target.value);
        console.log("selectedMuscleSpasm", selectedMuscleSpasm)
    };

    const handleOedemaCheckboxChange = (event) => {
        // document.getElementById('specifyOedema').value = ''
        clearValidationErrMessage()
        setSelectedOedema(event.target.value === selectedOedema ? null : event.target.value);
        console.log("selectedOedema", selectedOedema)
    };

    const handleCrepitusCheckboxChange = (event) => {
        // document.getElementById('specifyCrepitus').value = ''
        clearValidationErrMessage()
        setSelectedCrepitus(event.target.value === selectedCrepitus ? null : event.target.value);
        console.log("selectedCrepitus", selectedCrepitus)
    };

    const handleToneCheckboxChange = (event) => {
        // document.getElementById('specifyTone').value = ''
        clearValidationErrMessage()
        setSelectedTone(event.target.value === selectedTone ? null : event.target.value);
        console.log("selectedTone", selectedTone)
    };

    const handleFallRiskAdultCheckboxChange = (event) => {

        setSelectedFallRiskAdult(event.target.value === selectedFallRiskAdult ? null : event.target.value);
        console.log("selectedFallRiskAdult", selectedFallRiskAdult)
    };

    const handleFallRiskPaediaCheckboxChange = (event) => {

        setSelectedFallRiskPaedia(event.target.value === selectedFallRiskPaedia ? null : event.target.value);
        console.log("selectedFallRiskPaedia", selectedFallRiskPaedia)
    };

    const handleFallRiskProtocolCheckboxChange = (event) => {
        clearValidationErrMessage()
        setSelectedFallRiskProtocol(event.target.value === selectedFallRiskProtocol ? null : event.target.value);

        console.log("selectedFallRiskProtocol", selectedFallRiskProtocol)
    };

    const handleFallRiskProtocolBellsCheckboxChange = (event) => {
        clearValidationErrMessage()

        setSelectedFallRiskProtocolBells(event.target.value === selectedFallRiskProtocolBells ? null : event.target.value);
        console.log("selectedFallRiskProtocolBells", selectedFallRiskProtocolBells)
    };

    const handleFallRiskPaediaFallCheckboxChange = (event) => {
        clearValidationErrMessage()

        setSelectedFallRiskPaediaFall(event.target.value === selectedFallRiskPaediaFall ? null : event.target.value);
        console.log("selectedFallRiskPaediaFall", selectedFallRiskPaediaFall)
    };

    const handleFallRiskPaediaMobilityCheckboxChange = (event) => {
        clearValidationErrMessage()

        setSelectedFallRiskPaediaMobility(event.target.value === selectedFallRiskPaediaMobility ? null : event.target.value);
        console.log("selectedFallRiskPaediaMobility", selectedFallRiskPaediaMobility)
    };

    const handleFallRiskPaediaNeurologicalCheckboxChange = (event) => {
        clearValidationErrMessage()

        setSelectedFallRiskPaediaNeurological(event.target.value === selectedFallRiskPaediaNeurological ? null : event.target.value);
        console.log("selectedFallRiskPaediaNeurological", selectedFallRiskPaediaNeurological)
    };

    const handleFallRiskAdultAgeCheckboxChange = (event) => {
        clearValidationErrMessage()

        setSelectedFallRiskAdultAge(event.target.value === selectedFallRiskAdultAge ? null : event.target.value);
        console.log("selectedFallRiskAdultAge", selectedFallRiskAdultAge)
    };

    const handleFallRiskAdultHistoryCheckboxChange = (event) => {
        clearValidationErrMessage()

        setSelectedFallRiskAdultHistory(event.target.value === selectedFallRiskAdultHistory ? null : event.target.value);
        console.log("selectedFallRiskAdultHistory", selectedFallRiskAdultHistory)
    };

    const handleFallRiskAdultWalkCheckboxChange = (event) => {
        clearValidationErrMessage()

        setSelectedFallRiskAdultWalk(event.target.value === selectedFallRiskAdultWalk ? null : event.target.value);
        console.log("selectedFallRiskAdultWalk", selectedFallRiskAdultWalk)
    };


    const handleFallRiskAdultNeuroCheckboxChange = (event) => {
        clearValidationErrMessage()

        setSelectedFallRiskAdultNeuro(event.target.value === selectedFallRiskAdultNeuro ? null : event.target.value);
        console.log("selectedFallRiskAdultNeuro", selectedFallRiskAdultNeuro)
    };

    const handleChange = (event) => {
        clearValidationErrMessage()
        const { name, value } = event.target;

        setInputValues(inputValues => ({
            ...inputValues,
            [name]: value,
        }));

        if (name === 'referredFrom') {
            // setSelectedPostural(null)
            setReferredFrom(value)


        }

        // if (name === 'dateAssmt') {
        //     // If date is changed, keep the time part from the existing assessmentDateTime
        //     const timePart = assessmentDateTime.split(' ')[1] || ''; // Extract time part if available
        //     setAssessmentDateTime(value + ' ' + timePart);
        // } else if (name === 'timeAssmt') {
        //     // If time is changed, keep the date part from the existing assessmentDateTime
        //     const datePart = assessmentDateTime.split(' ')[0] || ''; // Extract date part if available
        //     setAssessmentDateTime(datePart + ' ' + value);
        // }


        // if (name === 'specify') {
        //     setSelectedPostural(event.target.value)
        //     console.log("selectedPostural", selectedPostural)
        // }

        if (name === 'specify') {
            // setSelectedPostural(null)
            setPostural(event.target.value)


        }


        // if (name === 'specifyMuscle') {
        //     setSelectedMuscle(event.target.value)
        //     console.log("selectedMuscle", selectedMuscle)
        // }

        if (name === 'specifyMuscle') {
            // setSelectedMuscle(null)
            setMuscle(event.target.value)


        }

        // if (name === 'specifyDeform') {
        //     setSelectedDeformity(event.target.value)
        //     console.log("selectedDeformity", selectedDeformity)
        // }

        if (name === 'specifyDeform') {
            // setSelectedDeformity(null)
            setDeformity(event.target.value)


        }

        // if (name === 'specifySwelling') {
        //     setSelectedSwelling(event.target.value)
        //     console.log("selectedSwelling", selectedSwelling)
        // }

        if (name === 'specifySwelling') {
            // setSelectedSwelling(null)
            setSwelling(event.target.value)


        }

        // if (name === 'specifyGait') {
        //     setSelectedGaitDeviation(event.target.value)
        //     console.log("selectedGaitDeviation", selectedGaitDeviation)
        // }

        if (name === 'specifyGait') {
            // setSelectedGaitDeviation(null)
            setGait(event.target.value)


        }

        // if (name === 'specifyAppliances') {
        //     setSelectedAppliances(event.target.value)
        //     console.log("selectedAppliances", selectedAppliances)
        // }

        if (name === 'specifyAppliances') {
            // setSelectedAppliances(null)
            setAppliances(event.target.value)


        }

        // if (name === 'specifyTenderness') {
        //     setSelectedTenderness(event.target.value)
        //     console.log("selectedTenderness", selectedTenderness)
        // }

        if (name === 'specifyTenderness') {
            // setSelectedTenderness(null)
            setTenderness(event.target.value)

        }

        // if (name === 'specifyWarmth') {
        //     setSelectedWarmth(event.target.value)
        //     console.log("selectedWarmth", selectedWarmth)
        // }

        if (name === 'specifyWarmth') {
            // setSelectedWarmth(null)
            setWarmth(event.target.value)

        }

        // if (name === 'specifyMuscleSpasm') {
        //     setSelectedMuscleSpasm(event.target.value)
        //     console.log("selectedMuscleSpasm", selectedMuscleSpasm)
        // }

        if (name === 'specifyMuscleSpasm') {
            // setSelectedMuscleSpasm(null)
            setMuscleSpasm(event.target.value)

        }

        // if (name === 'specifyOedema') {
        //     setSelectedOedema(event.target.value)
        //     console.log("selectedOedema", selectedOedema)
        // }

        if (name === 'specifyOedema') {
            // setSelectedOedema(null)
            setOedema(event.target.value)

        }

        // if (name === 'specifyCrepitus') {
        //     setSelectedCrepitus(event.target.value)
        //     console.log("selectedCrepitus", selectedCrepitus)
        // }

        if (name === 'specifyCrepitus') {
            // setSelectedCrepitus(null)
            setCrepitus(event.target.value)

        }


        // if (name === 'specifyTone') {
        //     setSelectedTone(event.target.value)
        //     console.log("selectedTone", selectedTone)
        // }

        if (name === 'specifyTone') {
            // setSelectedTone(null)
            setTone(event.target.value)

        }

    }


    const handlePrint = (data) => {
        console.log("data", data)
        const assessmentDate = new Date(data.dateTimeOfAssessment);
        const formattedDate = assessmentDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
        const formattedTime = assessmentDate.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit'
        });
        console.log("formattedDate", formattedDate);
        console.log("formattedTime", formattedTime);

        const occupation = (data.occupation)
        const unit = (data.unit)
        console.log("occupation", occupation)
        const refferedFrom = (data.referredFromName)
        const procedure = (data.procedure)
        console.log("procedure", procedure)
        const observation = (data.observation)
        const palpation = (data.palpation)
        const riskPrevention = (data.riskPrevention)
        const riskScreening = (data.riskScreening)
        const diagnosisData = (data.diagnosisJson)
        const symptomsData = (data.symtomnsJson)
        const historyData = (data.historyJson)




        window.sessionStorage.setItem("PrintAssessmentPhysioForm", JSON.stringify({
            "formattedDate": formattedDate, "formattedTime": formattedTime, "unit": unit, "occupation": occupation, "refferedFrom": refferedFrom, "procedure": procedure, "observation": observation,
            "palpation": palpation, "riskPrevention": riskPrevention, "riskScreening": riskScreening, "diagnosisData": diagnosisData, "symptomsData": symptomsData,
            "historyData":historyData

        }));
        window.open("/ReceiptInitialAssesmentFormPhysiotherepyPrint/", 'noopener,noreferrer');
    }


    const handleSaveAssessmentForm = async () => {




        const observationJson = {
            isBuilt: selectedBuilt,
            isPostDeviation: selectedPostural,
            isMuscleWasting: selectedMuscle,
            isDeformity: selectedDeformity,
            isSwelling: selectedSwelling,
            isGaitDeviation: selectedGaitDeviation,
            isAppliances: selectedAppliances,
            isSpecifyDeformity: deformity,
            isSpecifyPostDev: postural,
            isSpecifyMuscleWast: muscle,
            isSpecifySwell: swelling,
            isSpecifyGaitDev: gait,
            isSpecifyAppliance: appliances,

        };
        console.log("observationJson", observationJson)

        const palapationJson = {
            isInsignificant: selectedInsignificant,
            isTenderness: selectedTenderness,
            isWarmth: selectedWarmth,
            isMuscleSpasm: selectedMuscleSpasm,
            isOedema: selectedOedema,
            isCrepitus: selectedCrepitus,
            isTone: selectedTone,
            isSpecifyTenderness: tenderness,
            isSpecifyWarmth: warmth,
            isSpecifyMuscleSpasm: muscleSpasm,
            isSpecifyOedema: oedema,
            isSpecifyTone: tone,
            isSpecifyCrepitus: crepitus


        };
        console.log("palapationJson", palapationJson)

        const fallRiskScreeningJson = {
            riskScreeningAdultsAge: selectedFallRiskAdultAge,
            riskScreeningAdultHistory: selectedFallRiskAdultHistory,
            riskScreeningAdultWalk: selectedFallRiskAdultWalk,
            riskScreeningAdultNeuro: selectedFallRiskAdultNeuro,
            riskScreeningPaediaFall: selectedFallRiskPaediaFall,
            riskScreeningPaediaMobility: selectedFallRiskPaediaMobility,
            riskScreeningPaediaNeurological: selectedFallRiskPaediaNeurological,

        };
        console.log("fallRiskScreeningJson", fallRiskScreeningJson)

        const fallRiskPreventionJson = {
            riskPreventionProtocolSurround: selectedFallRiskProtocol,
            riskPreventionProtocolBells: selectedFallRiskProtocolBells


        };
        console.log("fallRiskPreventionJson", fallRiskPreventionJson)
        // if (inputValues.dateAssmt === '' || inputValues.dateAssmt === null || inputValues.dateAssmt === undefined) {
        //     document.getElementById('errDateTime').innerHTML = "Assessment Date Time is required";
        //     document.getElementById('errDateTime').style.display = "block";
        // }
        if
            (inputValues.unit === '' || inputValues.unit === null || inputValues.unit === undefined) {
            document.getElementById('errUnit').innerHTML = "Unit is required";
            document.getElementById('errUnit').style.display = "block";
        }

        else if
            (inputValues.occupation === '' || inputValues.occupation === null || inputValues.occupation === undefined) {
            document.getElementById('errOccupation').innerHTML = "Occupation is required";
            document.getElementById('errOccupation').style.display = "block";
        }

        else if
            (selectedBuilt === '' || selectedBuilt === null || selectedBuilt === undefined) {
            document.getElementById('errBuilt').innerHTML = "Built field is required";
            document.getElementById('errBuilt').style.display = "block";
        }
        else if
            (!(selectedPostural || postural)) {
            document.getElementById('errPostural').innerHTML = "Postural Deviation field is required";
            document.getElementById('errPostural').style.display = "block";
        }
        else if
            (!(selectedMuscle || muscle)) {
            document.getElementById('errMuscle').innerHTML = "Muscle Wasting field is required";
            document.getElementById('errMuscle').style.display = "block";
        }
        else if
            (!(selectedDeformity || deformity)) {
            document.getElementById('errDeformity').innerHTML = "Deformity field is required";
            document.getElementById('errDeformity').style.display = "block";
        }
        else if
            (!(selectedSwelling || swelling)) {
            document.getElementById('errSwelling').innerHTML = "Swelling field is required";
            document.getElementById('errSwelling').style.display = "block";
        }
        else if
            (!(selectedGaitDeviation || gait)) {
            document.getElementById('errGait').innerHTML = "Gait Deviation field is required";
            document.getElementById('errGait').style.display = "block";
        }
        else if
            (!(selectedAppliances || appliances)) {
            document.getElementById('errAppliance').innerHTML = "External Appliance field is required";
            document.getElementById('errAppliance').style.display = "block";
        }
        // else if
        //     (selectedInsignificant === '' || selectedInsignificant === null || selectedInsignificant === undefined) {
        //     document.getElementById('errinsignificant').innerHTML = "Insignificant field is required";
        //     document.getElementById('errinsignificant').style.display = "block";
        // }
        else if
            (!(selectedTenderness || tenderness)) {
            document.getElementById('errTenderness').innerHTML = "Tenderness field is required";
            document.getElementById('errTenderness').style.display = "block";
        }
        else if
            (!(selectedWarmth || warmth)) {
            document.getElementById('errWarmth').innerHTML = "Warmth field is required";
            document.getElementById('errWarmth').style.display = "block";
        }
        else if
            (!(selectedMuscleSpasm || muscleSpasm)) {
            document.getElementById('errMuscleSpasm').innerHTML = "Muscle Spasm field is required";
            document.getElementById('errMuscleSpasm').style.display = "block";
        }
        else if
            (!(selectedOedema || oedema)) {
            document.getElementById('errOedema').innerHTML = "Oedema field is required";
            document.getElementById('errOedema').style.display = "block";
        }
        else if
            (!(selectedCrepitus || crepitus)) {
            document.getElementById('errCrepitus').innerHTML = "Crepitus field is required";
            document.getElementById('errCrepitus').style.display = "block";
        }
        else if
            (!(selectedTone || tone)) {
            document.getElementById('errTone').innerHTML = "Tone field is required";
            document.getElementById('errTone').style.display = "block";
        }
        else if
            (!(selectedFallRiskAdultAge || selectedFallRiskAdultHistory || selectedFallRiskAdultWalk || selectedFallRiskAdultNeuro)) {
            document.getElementById('errRiskAdult').innerHTML = "RISK SCREENING ADULTS field is required";
            document.getElementById('errRiskAdult').style.display = "block";
        }
        else if
            (!(selectedFallRiskPaediaFall || selectedFallRiskPaediaMobility || selectedFallRiskPaediaNeurological)) {
            document.getElementById('errRiskPaedia').innerHTML = "RISK SCREENING PAEDIA field is required";
            document.getElementById('errRiskPaedia').style.display = "block";
        }
        // else if
        //     (selectedFallRiskProtocol === '' || selectedFallRiskProtocol === null || selectedFallRiskProtocol === undefined) {
        //     document.getElementById('errRiskPrevePro').innerHTML = "ORIENT THE PATIENT field is required";
        //     document.getElementById('errRiskPrevePro').style.display = "block";
        // }
        // else if
        //     (selectedFallRiskProtocolBells === '' || selectedFallRiskProtocolBells === null || selectedFallRiskProtocolBells === undefined) {
        //     document.getElementById('errRiskBells').innerHTML = "BELLS field is required";
        //     document.getElementById('errRiskBells').style.display = "block";
        // }

        else {

            setShowUnderProcess(1);

            const obj = {

                uhid: requiredUHID,
                userId: JSON.parse(sessionStorage.getItem("LoginData")).userId,
                clientId: clientID,
                dateTimeOfAssessment: formatDateTimeNew(inputValues.dateAssmt),
                refferedFrom: referredFrom,
                unit: inputValues.unit,
                procedure: JSON.stringify(InvestigationJSON),
                occupation: inputValues.occupation,
                observation: JSON.stringify(observationJson),
                palpation: JSON.stringify(palapationJson),
                riskScreening: JSON.stringify(fallRiskScreeningJson),
                riskPrevention: JSON.stringify(fallRiskPreventionJson),


            }

            console.log("obj", obj)

            setSaveDiagnosis(1)
            setSaveSymtoms(1)
            setSaveMedicalHistory(1)
            let responseAsssessmentForm = await PostAssessmentFormPhysiotherapy(obj);

            if (responseAsssessmentForm.status === 1) {
                setShowMeassage("Data Saved Successfully!!")
                setShowSuccessToster(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
                getdata()
                resetForm();
                // clearValidationErrMessage()

            }
            else {
                setShowMeassage(responseAsssessmentForm.responseValue)
                setShowAlertToster(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }

        }

    }

    const clearValidationErrMessage = () => {
        // document.getElementById('errDateTime').style.display = "none";
        document.getElementById('errUnit').style.display = "none";
        document.getElementById('errOccupation').style.display = "none";
        document.getElementById('errBuilt').style.display = "none";
        document.getElementById('errPostural').style.display = "none";
        document.getElementById('errMuscle').style.display = "none";
        document.getElementById('errDeformity').style.display = "none";
        document.getElementById('errSwelling').style.display = "none";
        document.getElementById('errGait').style.display = "none";
        document.getElementById('errAppliance').style.display = "none";
        // document.getElementById('errinsignificant').style.display = "none";
        document.getElementById('errTenderness').style.display = "none";
        document.getElementById('errWarmth').style.display = "none";
        document.getElementById('errMuscleSpasm').style.display = "none";
        document.getElementById('errOedema').style.display = "none";
        document.getElementById('errCrepitus').style.display = "none";
        document.getElementById('errTone').style.display = "none";
        document.getElementById('errRiskAdult').style.display = "none";
        document.getElementById('errRiskPaedia').style.display = "none";
        // document.getElementById('errRiskPrevePro').style.display = "none";
        // document.getElementById('errRiskBells').style.display = "none";
    }

    const resetForm = () => {

        document.getElementById('dateAssmt').value = inputValues.dateAssmt;
        // document.getElementById('dateAssmt').value = '';
        document.getElementById('unit').value = '';
        document.getElementById('occupation').value = '';
        document.getElementById('specify').value = '';
        document.getElementById('specifyMuscle').value = '';
        document.getElementById('specifyDeform').value = '';
        document.getElementById('specifySwelling').value = '';
        document.getElementById('specifyGait').value = '';
        document.getElementById('specifyAppliances').value = '';
        document.getElementById('specifyTenderness').value = '';
        document.getElementById('specifyWarmth').value = '';
        document.getElementById('specifyMuscleSpasm').value = '';
        document.getElementById('specifyOedema').value = '';
        document.getElementById('specifyCrepitus').value = '';
        document.getElementById('specifyTone').value = '';
        document.getElementById('referredFrom').value = 0;
        setSelectedMuscleSpasm(null);
        setSelectedWarmth(null);
        setSelectedInsignificant(null);
        setSelectedTenderness(null);
        setSelectedAppliances(null);
        setSelectedAppliances(null);
        setSelectedGaitDeviation(null);
        setSelectedSwelling(null);
        setSelectedDeformity(null);
        setSelectedDeformity(null);
        setSelectedMuscle(null);
        setSelectedPostural(null);
        setSelectedBuilt(null);
        setSelectedCrepitus(null);
        setSelectedOedema(null);
        setSelectedTone(null);
        setSelectedFallRiskAdultNeuro(null);
        setSelectedFallRiskAdultWalk(null);
        setSelectedFallRiskAdultHistory(null);
        setSelectedFallRiskAdultAge(null);
        setSelectedFallRiskPaediaNeurological(null);
        setSelectedFallRiskPaediaMobility(null);
        setSelectedFallRiskPaediaFall(null);
        setSelectedFallRiskProtocolBells(null);
        setSelectedFallRiskProtocol(null);
        setSelectedFallRiskPaedia(null);
        setSelectedFallRiskAdult(null);
        let clearData ={
            setClear : 1
           }
          window.sessionStorage.setItem("runClearData" , JSON.stringify(clearData))
          getdata()
          setTimeout(() => {
            window.sessionStorage.removeItem("runClearData");
          }, 1000);
          handleClearInvestigation()


    }

    useEffect(() => {
        getdata()
        // setDateTime(date.toLocaleDateString() + " " + date.toLocaleTimeString());

        setInputValues(prevData => ({
            ...prevData,
            
            dateAssmt: `${getCurrentDate()} ${getCurrentTime()}`
        }));

    }, [])
    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">

                    <div className="col-12"><div className="med-box  mb-1"><div className="title">IN PATIENT INITIAL ASSESSMENT FORM PHYSIOTHERAPY</div></div></div>

                    <PatientDeatils />

                    {/* <div className="med-box">

                        <div className="inner-content">
                            <div className="row">
                                <div className='fieldsett-in col-12'>
                                    <div className='fieldsett'>
                                        <span className='fieldse'>Assessment Details</span>
                                        <div className='row px-2'>

                                            <div className="col-xxl-4 col-xl-12 col-lg-6 col-md-12 mb-3">
                                                <label htmlFor="Code" className="form-label">Date & Time of Assessment<span className="starMandatory"></span></label>
                                                <input id="dateAssmt" type="text" className="form-control form-control-sm" placeholder='Enter Assessment Date' value={dateTime} name="dateAssmt" onChange={handleChange} />
                                                
                                            </div>                                         

                                            <div className="col-xxl-4 col-xl-12 col-lg-6 col-md-12 mb-3">
                                                <label htmlFor="occupation" className="form-label">Reffered From<span className="starMandatory"></span></label>
                                                <input id="referredFrom" type="text" className="form-control form-control-sm" value={doctor} name="referredFrom" placeholder='' />
                                            </div>


                                            <div className="col-xxl-4 col-xl-12 col-lg-6 col-md-12 mb-3">
                                                <label htmlFor="unit" className="form-label">Unit<span className="starMandatory"></span></label>
                                                <input id="unit" type="number" className="form-control form-control-sm" placeholder="Enter Unit" name="unit" onChange={handleChange} />
                                                <small id='errUnit' className='form-text text-danger' style={{ display: 'none' }}></small>
                                            </div>

                                            <div className="col-xxl-4 col-xl-12 col-lg-6 col-md-12 mb-3">
                                                <label htmlFor="occupation" className="form-label">Occupation<span className="starMandatory"></span></label>
                                                <input id="occupation" type="text" className="form-control form-control-sm" name="occupation" placeholder='Enter Occupation' onChange={handleChange} />
                                                <small id='errOccupation' className='form-text text-danger' style={{ display: 'none' }}></small>
                                            </div>

                                            <div className="col-xxl-4 col-xl-12 col-lg-6 col-md-12 mb-3">
                                                <label htmlFor="occupation" className="form-label">Contact No.<span className="starMandatory"></span></label>
                                                <input id="contact" type="text" className="form-control form-control-sm" value={contactNo} name="contact" placeholder='' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* ----------------------------New Line Patient Details---------------------------------- */}
                    <div className="fieldsett-in">
                        <div className="fieldsett">
                            <span className='fieldse'>{t("Assessment Details")}</span>
                            <div className="inner-content mt-2">
                                <div className="d-flex flex-wrap align-content-end gap-3">
                                    <div className="mb-2 me-2">
                                    <img src={IconDateTime} className="icnn" alt="" />
                                        <label htmlFor="Code" className="form-label">Date & Time of Assessment<span className="starMandatory"></span></label>
                                        <input id="dateAssmt" type="datetime-local" className="form-control form-control-sm" placeholder='Enter Assessment Date' value={inputValues.dateAssmt}  name="dateAssmt" onChange={handleChange} />
                                    </div>
                                    <div className="mb-2 me-2">
                                    <img src={IconRestraints} className="icnn" alt="" />
                                        <label htmlFor="occupation" className="form-label">Reffered From<span className="starMandatory"></span></label>
                                        {/* <input id="referredFrom" type="text" className="form-control form-control-sm" value={doctor} name="referredFrom" placeholder='' /> */}
                                        <select id='referredFrom' name='referredFrom' className="form-select form-select-sm" onChange={handleChange}>
                                            <option value="0" selected>Select Department</option>
                                            {departmentList && departmentList.map((val, ind) => {
                                                return (
                                                    <option value={val.id}>{val.departmentName}</option>
                                                )
                                            }
                                            )}
                                        </select>
                                    </div>
                                    <div className="mb-2 me-2">
                                    <img src={IconWard} className="icnn" alt="" />
                                        <label htmlFor="unit" className="form-label">Unit<span className="starMandatory"></span></label>
                                        <input id="unit" type="text" className="form-control form-control-sm" placeholder="Enter Unit" name="unit" onChange={handleChange} />
                                        <small id='errUnit' className='form-text text-danger' style={{ display: 'none' }}></small>
                                    </div>
                                    <div className="mb-2 me-2">
                                    <img src={workSpace} className="icnn" alt="" />
                                        <label htmlFor="occupation" className="form-label">Occupation<span className="starMandatory"></span></label>
                                        <input id="occupation" type="text" className="form-control form-control-sm" name="occupation" placeholder='Enter Occupation' onChange={handleChange} />
                                        <small id='errOccupation' className='form-text text-danger' style={{ display: 'none' }}></small>
                                    </div>
                                    <div className="mb-2 me-2">
                                    <img src={contact} className="icnn" alt="" />
                                        <label htmlFor="occupation" className="form-label">Contact No.<span className="starMandatory"></span></label>
                                        <input id="contact" type="text" className="form-control form-control-sm" value={contactNo} name="contact" placeholder='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div className="row mt-2">
                        <div className="col-md-12 col-sm-12">
                            <div className='allegydiagnosismain'>
                                <div className='allergy-inn'>
                                    <div className="fieldsett-in">
                                        <div className="fieldsett">
                                            <span className='fieldse'>{t("Procedure")}</span>
                                            <div className="inner-content mt-2" style={{ minHeight: '12vh', maxHeight: '25vh', overflow: 'auto' }}>
                                                <InvestigationsRows investigationsJson={handleInvestigationJSON} clearData={handleClearInvestigation}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='allergy-inn'>
                                    <div className="fieldsett-in">
                                        <div className="fieldsett">
                                            <span className='fieldse'>{t("Diagnosis")}</span>
                                            <div className="inner-content mt-2" style={{ minHeight: '12vh', maxHeight: '25vh', overflow: 'auto' }}>
                                                <ProvisonalDiagnosisMainComponent isProvisional={0} saveDiagnosis={saveDiagnosis} setSaveDiagnosis={setSaveDiagnosis} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>





                    <div className="row mt-2">
                        <div className="col-md-12 col-sm-12">
                            <div className='allegydiagnosismain'>
                                <div className='allergy-inn'>
                                    <div className="fieldsett-in">
                                        <div className="fieldsett">
                                            <span className='fieldse'>{t("Chief Complaint")}</span>
                                            <div className="inner-content mt-2" style={{ maxHeight: '20vh', overflow: 'auto' }}>
                                                <ChiefCaomplainComponent saveSymtoms={saveSymtoms} setSaveSymtoms={setSaveSymtoms} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='allergy-inn'>
                                    <div className="fieldsett-in">
                                        <div className="fieldsett">
                                            <span className='fieldse'>{t("Past Medical & Surgical History")}</span>
                                            <div className="inner-content mt-2" style={{ maxHeight: '20vh', overflow: 'auto' }}>
                                                <MedicalHistoryComponent saveMedicalHistory={saveMedicalHistory} setSaveMedicalHistory={setSaveMedicalHistory} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>






                    <div className="med-box">

                        <div className="inner-content">
                            <div className="row">
                                <div className='fieldsett-in col-12'>
                                    <div className='fieldsett'>
                                        <span className='fieldse'>On Observation</span>
                                        <div className='row px-2'>


                                            <div className="col-sm-4">
                                                <div><label htmlFor="Code" className="form-label mt-2 ">BUILT:<span className="starMandatory"> </span></label><small id='errBuilt' className='form-text text-danger' style={{ display: 'none' }}></small></div>
                                                <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">THIN<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="built" id='thin' className="form-check-input" value="thin" type="checkbox"
                                                                checked={selectedBuilt === 'thin'}
                                                                onChange={handleBuiltCheckboxChange} />

                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">FAIR<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="built" id='fair' className="form-check-input" value="fair" type="checkbox"
                                                                checked={selectedBuilt === 'fair'}
                                                                onChange={handleBuiltCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">WELL BUILT<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="built" id='wellBuilt' value="wellBuilt" className="form-check-input" type="checkbox"
                                                                checked={selectedBuilt === 'wellBuilt'}
                                                                onChange={handleBuiltCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">OBESE<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="built" id='obese' value="obese" className="form-check-input" type="checkbox"
                                                                checked={selectedBuilt === 'obese'}
                                                                onChange={handleBuiltCheckboxChange} />
                                                        </div>

                                                    </div>



                                                </div>
                                            </div >

                                            <div className="col-sm-4">
                                                <div><label htmlFor="Code" className="form-label mt-2 "> Postural Deviation:<span className="starMandatory"></span></label><small id='errPostural' className='form-text text-danger' style={{ display: 'none' }}></small></div>
                                                <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="postural" id='yes' value="yes" className="form-check-input" type="checkbox"
                                                                checked={selectedPostural === 'yes'}
                                                                onChange={handlePosturalCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="postural" id='no' value="no" className="form-check-input" type="checkbox"
                                                                checked={selectedPostural === 'no'}
                                                                onChange={handlePosturalCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">SPECIFY<span className="starMandatory"></span></label>
                                                        <div>
                                                            <input id="specify" name='specify' type="text" className="form-control form-control-sm" placeholder=' ' onChange={handleChange} />

                                                        </div>

                                                    </div>





                                                </div>
                                            </div >

                                            <div className="col-sm-4">
                                                <div><label htmlFor="Code" className="form-label mt-2 ">MUSCLE WASTING:<span className="starMandatory"></span></label><small id='errMuscle' className='form-text text-danger' style={{ display: 'none' }}></small></div>
                                                <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="muscle" id='yes' value="yes" className="form-check-input" type="checkbox"
                                                                checked={selectedMuscle === 'yes'}
                                                                onChange={handleMuscleCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="muscle" id='no' value="no" className="form-check-input" type="checkbox"
                                                                checked={selectedMuscle === 'no'}
                                                                onChange={handleMuscleCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">SPECIFY<span className="starMandatory"></span></label>
                                                        <div>
                                                            <input id="specifyMuscle" type="text" className="form-control form-control-sm" name="specifyMuscle" placeholder=' ' onChange={handleChange} />

                                                        </div>

                                                    </div>





                                                </div>
                                            </div >

                                            <div className="col-sm-4">
                                                <div><label htmlFor="Code" className="form-label mt-2 ">DEFORMITY:<span className="starMandatory"></span></label><small id='errDeformity' className='form-text text-danger' style={{ display: 'none' }}></small></div>
                                                <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="deformity" id='yes' value="yes" className="form-check-input" type="checkbox"
                                                                checked={selectedDeformity === 'yes'}
                                                                onChange={handleDeformityCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="deformity" id='no' value="no" className="form-check-input" type="checkbox"
                                                                checked={selectedDeformity === 'no'}
                                                                onChange={handleDeformityCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">SPECIFY<span className="starMandatory"></span></label>
                                                        <div>
                                                            <input id="specifyDeform" type="text" className="form-control form-control-sm" name="specifyDeform" placeholder='' onChange={handleChange} />

                                                        </div>

                                                    </div>





                                                </div>
                                            </div >

                                            <div className="col-sm-4">
                                                <div><label htmlFor="Code" className="form-label mt-2 ">SWELLING:<span className="starMandatory"></span></label><small id='errSwelling' className='form-text text-danger' style={{ display: 'none' }}></small></div>
                                                <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="swelling" id='yes' value="yes" className="form-check-input" type="checkbox"
                                                                checked={selectedSwelling === 'yes'}
                                                                onChange={handleSwellingCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="swelling" id='no' value="no" className="form-check-input" type="checkbox"
                                                                checked={selectedSwelling === 'no'}
                                                                onChange={handleSwellingCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">SPECIFY<span className="starMandatory"></span></label>
                                                        <div>
                                                            <input id="specifySwelling" type="text" className="form-control form-control-sm" name="specifySwelling" placeholder='' onChange={handleChange} />

                                                        </div>

                                                    </div>





                                                </div>
                                            </div >
                                            <div className="col-sm-4">
                                                <div><label htmlFor="Code" className="form-label mt-2 ">GAIT DEVIATION:<span className="starMandatory"></span></label><small id='errGait' className='form-text text-danger' style={{ display: 'none' }}></small></div>
                                                <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="Condition" id='yes' value="yes" className="form-check-input" type="checkbox"
                                                                checked={selectedGaitDeviation === 'yes'}
                                                                onChange={handleGaitDeviationCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="Condition" id='no' value="no" className="form-check-input" type="checkbox"
                                                                checked={selectedGaitDeviation === 'no'}
                                                                onChange={handleGaitDeviationCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">SPECIFY<span className="starMandatory"></span></label>
                                                        <div>
                                                            <input id="specifyGait" type="text" className="form-control form-control-sm" name="specifyGait" placeholder='' onChange={handleChange} />

                                                        </div>

                                                    </div>





                                                </div>
                                            </div >

                                            <div className="col-sm-4">
                                                <div><label htmlFor="Code" className="form-label mt-2 ">EXTERNAL APPLIANCES:<span className="starMandatory"></span></label><small id='errAppliance' className='form-text text-danger' style={{ display: 'none' }}></small></div>
                                                <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="Condition" id='yes' value="yes" className="form-check-input" type="checkbox"
                                                                checked={selectedAppliances === 'yes'}
                                                                onChange={handleAppliancesCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="Condition" id='no' value="no" className="form-check-input" type="checkbox"
                                                                checked={selectedAppliances === 'no'}
                                                                onChange={handleAppliancesCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">SPECIFY<span className="starMandatory"></span></label>
                                                        <div>
                                                            <input id="specifyAppliances" type="text" className="form-control form-control-sm" name="specifyAppliances" placeholder='' onChange={handleChange} />

                                                        </div>

                                                    </div>





                                                </div>
                                            </div >

                                        </div>

                                    </div>
                                </div>



                            </div>

                        </div>

                    </div>

                    <div className="med-box">

                        <div className="inner-content">
                            <div className="row">
                                <div className='fieldsett-in col-12'>
                                    <div className='fieldsett'>
                                        <span className='fieldse'>ON PALPATION</span>
                                        <div className='row px-2'>



                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div><label htmlFor="Code" className="form-label mt-2 "><span className="starMandatory"></span></label></div>
                                                <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <div className="form-check">
                                                            <input name="insignificant" id='yes' value="yes" className="form-check-input" type="checkbox"
                                                                checked={selectedInsignificant === 'yes'}
                                                                onChange={handleInsignificantCheckboxChange} /> <label htmlFor="canRead" className="form-label">INSIGNIFICANT<span className="starMandatory"></span></label>
                                                            {/* <small id='errinsignificant' className='form-text text-danger' style={{ display: 'none' }}></small> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div >


                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div><label htmlFor="Code" className="form-label mt-2 ">TENDERNESS:<span className="starMandatory"></span></label>
                                                    <small id='errTenderness' className='form-text text-danger' style={{ display: 'none' }}></small>
                                                </div>
                                                <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="tenderness" id='yes' value="yes" className="form-check-input" type="checkbox"
                                                                checked={selectedTenderness === 'yes'}
                                                                onChange={handleTendernessCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="tenderness" id='no' value="no" className="form-check-input" type="checkbox"
                                                                checked={selectedTenderness === 'no'}
                                                                onChange={handleTendernessCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">SPECIFY<span className="starMandatory"></span></label>
                                                        <div>
                                                            <input id="specifyTenderness" type="text" className="form-control form-control-sm" name="specifyTenderness" placeholder=' ' onChange={handleChange} />

                                                        </div>

                                                    </div>
                                                </div>
                                            </div >

                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div><label htmlFor="Code" className="form-label mt-2 ">WARMTH:<span className="starMandatory"></span></label>
                                                    <small id='errWarmth' className='form-text text-danger' style={{ display: 'none' }}></small>
                                                </div>
                                                <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="warmth" id='yes' value="yes" className="form-check-input" type="checkbox"
                                                                checked={selectedWarmth === 'yes'}
                                                                onChange={handleWarmthCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="warmth" id='no' value="no" className="form-check-input" type="checkbox"
                                                                checked={selectedWarmth === 'no'}
                                                                onChange={handleWarmthCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">SPECIFY<span className="starMandatory"></span></label>
                                                        <div>
                                                            <input id="specifyWarmth" type="text" className="form-control form-control-sm" name="specifyWarmth" placeholder=' ' onChange={handleChange} />

                                                        </div>

                                                    </div>





                                                </div>
                                            </div >

                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div><label htmlFor="Code" className="form-label mt-2 ">MUSCLE SPASM:<span className="starMandatory"></span></label>
                                                    <small id='errMuscleSpasm' className='form-text text-danger' style={{ display: 'none' }}></small>
                                                </div>
                                                <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="muscleSpasm" id='yes' value="yes" className="form-check-input" type="checkbox"
                                                                checked={selectedMuscleSpasm === 'yes'}
                                                                onChange={handleMuscleSpasmCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="muscleSpasm" id='no' value="no" className="form-check-input" type="checkbox"
                                                                checked={selectedMuscleSpasm === 'no'}
                                                                onChange={handleMuscleSpasmCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">SPECIFY<span className="starMandatory"></span></label>
                                                        <div>
                                                            <input id="specifyMuscleSpasm" type="text" className="form-control form-control-sm" name="specifyMuscleSpasm" placeholder='' onChange={handleChange} />

                                                        </div>

                                                    </div>





                                                </div>
                                            </div >

                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div><label htmlFor="Code" className="form-label mt-2 ">OEDEMA:<span className="starMandatory"></span></label>
                                                    <small id='errOedema' className='form-text text-danger' style={{ display: 'none' }}></small>
                                                </div>
                                                <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="oedema" id='yes' value="yes" className="form-check-input" type="checkbox"
                                                                checked={selectedOedema === 'yes'}
                                                                onChange={handleOedemaCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="oedema" id='no' value="no" className="form-check-input" type="checkbox"
                                                                checked={selectedOedema === 'no'}
                                                                onChange={handleOedemaCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">SPECIFY<span className="starMandatory"></span></label>
                                                        <div>
                                                            <input id="specifyOedema" type="text" className="form-control form-control-sm" name="specifyOedema" placeholder='' onChange={handleChange} />

                                                        </div>

                                                    </div>





                                                </div>
                                            </div >
                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div><label htmlFor="Code" className="form-label mt-2 ">CREPITUS:<span className="starMandatory"></span></label>
                                                    <small id='errCrepitus' className='form-text text-danger' style={{ display: 'none' }}></small>
                                                </div>
                                                <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="crepitus" id='yes' value="yes" className="form-check-input" type="checkbox"
                                                                checked={selectedCrepitus === 'yes'}
                                                                onChange={handleCrepitusCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="crepitus" id='no' value="no" className="form-check-input" type="checkbox"
                                                                checked={selectedCrepitus === 'no'}
                                                                onChange={handleCrepitusCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">SPECIFY<span className="starMandatory"></span></label>
                                                        <div>
                                                            <input id="specifyCrepitus" type="text" className="form-control form-control-sm" name="specifyCrepitus" placeholder='' onChange={handleChange} />

                                                        </div>

                                                    </div>





                                                </div>
                                            </div >

                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div><label htmlFor="Code" className="form-label mt-2 ">TONE:<span className="starMandatory"></span></label>
                                                    <small id='errTone' className='form-text text-danger' style={{ display: 'none' }}></small>
                                                </div>
                                                <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">YES<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="tone" id='yes' value="yes" className="form-check-input" type="checkbox"
                                                                checked={selectedTone === 'yes'}
                                                                onChange={handleToneCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">NO<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="tone" id='no' value="no" className="form-check-input" type="checkbox"
                                                                checked={selectedTone === 'no'}
                                                                onChange={handleToneCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">SPECIFY<span className="starMandatory"></span></label>
                                                        <div>
                                                            <input id="specifyTone" type="text" className="form-control form-control-sm" name="specifyTone" placeholder='' onChange={handleChange} />

                                                        </div>

                                                    </div>





                                                </div>
                                            </div >

                                        </div>

                                    </div>
                                </div>



                            </div>

                        </div>

                    </div>

                    <div className="med-box">

                        <div className="inner-content">
                            <div className="row">
                                <div className='fieldsett-in col-12'>
                                    <div className='fieldsett'>
                                        <span className='fieldse'>FALL RISK SCREENING</span>
                                        <div className='row px-2'>






                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div><label htmlFor="Code" className="form-label mt-2 "><strong>FALL RISK SCREENING FOR ADULTS:</strong><span className="starMandatory"></span></label>
                                                    <small id='errRiskAdult' className='form-text text-danger' style={{ display: 'none' }}></small>
                                                </div>
                                                <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">AGE MORE THAN 65 YEARS<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="fallRiskAdult" id='age' value="AGE MORE THAN 65 YEARS" className="form-check-input" type="checkbox"
                                                                checked={selectedFallRiskAdultAge === 'AGE MORE THAN 65 YEARS'}
                                                                onChange={handleFallRiskAdultAgeCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">HISTORY OF FALL IN LAST 3 MONTH<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="fallRiskAdult" id='history' value="HISTORY OF FALL IN LAST 3 MONTH" className="form-check-input" type="checkbox"
                                                                checked={selectedFallRiskAdultHistory === 'HISTORY OF FALL IN LAST 3 MONTH'}
                                                                onChange={handleFallRiskAdultHistoryCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">WALK WITH ASSISTANCE<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="fallRiskAdult" id='walk' value="WALK WITH ASSISTANCE" className="form-check-input" type="checkbox"
                                                                checked={selectedFallRiskAdultWalk === 'WALK WITH ASSISTANCE'}
                                                                onChange={handleFallRiskAdultWalkCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">ANY NEUROLOGICAL PROBLEM<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="fallRiskAdult" id='neuro' value="ANY NEUROLOGICAL PROBLEM" className="form-check-input" type="checkbox"
                                                                checked={selectedFallRiskAdultNeuro === 'ANY NEUROLOGICAL PROBLEM'}
                                                                onChange={handleFallRiskAdultNeuroCheckboxChange} />
                                                        </div>

                                                    </div>


                                                </div>
                                            </div >

                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div><label htmlFor="Code" className="form-label mt-2 "><strong>FALL RISK SCREENING FOR PAEDIATRICS:</strong><span className="starMandatory"></span></label>
                                                    <small id='errRiskPaedia' className='form-text text-danger' style={{ display: 'none' }}></small>
                                                </div>
                                                <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">H/O FALL IN LAST 3 MONTHS<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="fallRiskPaedia" id='fall' value="H/O FALL IN LAST 3 MONTHS" className="form-check-input" type="checkbox"
                                                                checked={selectedFallRiskPaediaFall === 'H/O FALL IN LAST 3 MONTHS'}
                                                                onChange={handleFallRiskPaediaFallCheckboxChange} />
                                                        </div>

                                                    </div>

                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">DERARNGED MOBILITY<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="fallRiskPaedia" id='mobility' value="DERARNGED MOBILITY" className="form-check-input" type="checkbox"
                                                                checked={selectedFallRiskPaediaMobility === 'DERARNGED MOBILITY'}
                                                                onChange={handleFallRiskPaediaMobilityCheckboxChange} />
                                                        </div>

                                                    </div>


                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label">NEUROLOGICAL PROBLEM(VERTIGO, SEIZURES,ETC)<span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="fallRiskPaedia" id='neurological' value="NEUROLOGICAL PROBLEM" className="form-check-input" type="checkbox"
                                                                checked={selectedFallRiskPaediaNeurological === 'NEUROLOGICAL PROBLEM'}
                                                                onChange={handleFallRiskPaediaNeurologicalCheckboxChange} />
                                                        </div>

                                                    </div>




                                                </div>
                                            </div >








                                        </div>

                                    </div>
                                </div>



                            </div>

                        </div>

                    </div>

                    <div className="med-box">

                        <div className="inner-content">
                            <div className="row">
                                <div className='fieldsett-in col-12'>
                                    <div className='fieldsett'>
                                        <span className='fieldse'>FALL RISK PREVENTION PROTOCOL</span>
                                        <div className='row px-2'>






                                            <div className="col-md-12">
                                                <div className='d-flex gap-3' style={{ alignItems: 'center' }}>
                                                    <div><label htmlFor="Code" className="form-label mt-2 "><strong>1. ORIENT THE PATIENT AND ATTENDANT ABOUNT THE SURROUNDING:</strong><span className="starMandatory"></span></label>
                                                        {/* <small id='errRiskPrevePro' className='form-text text-danger' style={{ display: 'none' }}></small> */}
                                                    </div>
                                                    <div className="form-check regularCheck mt-2">
                                                        <input name="fallRiskProtocolSurroun" id='surrounding' value="ORIENT THE PATIENT AND ATTENDANT ABOUNT THE SURROUNDING" className="form-check-input" type="checkbox"
                                                            checked={selectedFallRiskProtocol === 'ORIENT THE PATIENT AND ATTENDANT ABOUNT THE SURROUNDING'}
                                                            onChange={handleFallRiskProtocolCheckboxChange} />
                                                    </div>
                                                </div>
                                            </div >
                                            <div className="col-md-12">
                                                <div className='d-flex gap-3' style={{ alignItems: 'center' }}>
                                                    <div><label htmlFor="Code" className="form-label mt-2 "><strong>2. INSTRUCT ON USE OF BELLS</strong><span className="starMandatory"></span></label>
                                                        {/* <small id='errRiskPrevePro' className='form-text text-danger' style={{ display: 'none' }}></small> */}
                                                    </div>
                                                    <div className="form-check regularCheck mt-2">
                                                        <input name="fallRiskProtocolBells" id='bells' value="INSTRUCT ON USE OF BELLS" className="form-check-input" type="checkbox"
                                                            checked={selectedFallRiskProtocolBells === 'INSTRUCT ON USE OF BELLS'}
                                                            onChange={handleFallRiskProtocolBellsCheckboxChange} />
                                                    </div>
                                                </div>
                                            </div >

                                            {/* <div className="col-md-12">
                                                <div><label htmlFor="Code" className="form-label mt-2 "><strong>2. INSTRUCT ON USE OF BELLS</strong><span className="starMandatory"></span></label>
                                                    <small id='errRiskBells' className='form-text text-danger' style={{ display: 'none' }}></small>
                                                </div>
                                                <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                                    <div className="d-flex column-gap-3 mt-2 me-3">
                                                        <label htmlFor="canRead" className="form-label"><span className="starMandatory"></span></label>
                                                        <div className="form-check">
                                                            <input name="fallRiskProtocolBells" id='bells' value="INSTRUCT ON USE OF BELLS" className="form-check-input" type="checkbox"
                                                                checked={selectedFallRiskProtocolBells === 'INSTRUCT ON USE OF BELLS'}
                                                                onChange={handleFallRiskProtocolBellsCheckboxChange} />
                                                        </div>

                                                    </div>

                                                </div>
                                            </div > */}








                                        </div>

                                    </div>
                                </div>



                            </div>

                        </div>

                    </div>


                    <div className="col-12 ">
                        <div className="med-box mt-2">
                            <div className="inner-content">
                                <div className="row">

                                    <div className="inner-content text-right">
                                        <div className="mb-2 mt-2 relative">
                                            <div>

                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1_ me-1" onClick={handleSaveAssessmentForm}><img src={saveButtonIcon} className="icnn" alt='' />Save</button>
                                                <button type="button" className="btn btn-clear btn-sm mb-1_ me-1" onClick={resetForm}><img alt='' />Clear</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-12 mt-3">
                    <div className="med-table-section" style={{ "height": "80vh" }}>
                        <table className="med-table border striped">
                            <thead style={{ zIndex: '0' }}>
                                <tr>
                                    <th className="text-center" style={{ "width": "5%" }}>#</th>
                                    <th>Date & Time Assessment</th>
                                    <th>Reffered From</th>
                                    <th>Unit</th>
                                    <th>Occupation</th>
                                    <th>Diagnosis</th>
                                    <th>Symptoms</th>
                                    <th>Past Medical/Surgical History</th>
                                    <th>Procedure</th>
                                    <th>Observation</th>
                                    <th>Palpation </th>
                                    <th>Risk Screening </th>
                                    <th>Risk Prevention </th>
                                    <th style={{ "width": "10%" }} className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getAsstPhysioData && getAsstPhysioData.map((data, index) => {

                                    return (
                                        <tr key={index}>
                                            <td className="text-center">{index + 1}</td>
                                            <td>{formatDateTime(data.dateTimeOfAssessment)}</td>
                                            <td>{data.referredFromName}</td>
                                            <td>{data.unit}</td>
                                            <td>{data.occupation}</td>

                                            {/* {procedureData && (
                                                <td>{procedureData.map((val, index) => (
                                                    <span key={index}>{val.itemName}
                                                        {index < procedureData.length - 1 && <br />}
                                                    </span>
                                                ))}
                                                </td>
                                            )}  */}

                                            <td>
                                                {/* {diagnosisData && diagnosisData[index].map((val, index) => (
                                                    index > 0 ? `, ${val.details}` : val.details
                                                ))} */}

                                                {data.diagnosisJson.map((val, index) => (
                                                    <span key={index}>{val.details}
                                                        {index < data.diagnosisJson.length - 1 && <br />}
                                                    </span>
                                                ))}

                                            </td>

                                            <td>
                                                {/* {symptomsData && symptomsData[index].map((val, index) => (
                                                    index > 0 ? `, ${val.details}` : val.details
                                                ))} */}

                                                {data.symtomnsJson.map((val, index) => (
                                                    <span key={index}>{val.details}
                                                        {index < data.symtomnsJson.length - 1 && <br />}
                                                    </span>
                                                ))}

                                            </td>

                                            <td>
                                                {/* {symptomsData && symptomsData[index].map((val, index) => (
                                                    index > 0 ? `, ${val.details}` : val.details
                                                ))} */}

                                                {data.historyJson.map((val, index) => (
                                                    <span key={index}>{val.details}
                                                        {index < data.historyJson.length - 1 && <br />}
                                                    </span>
                                                ))}

                                            </td>

                                            <td>
                                                {/* {procedureData && procedureData[index].map((val, index) => (
                                                    index > 0 ? `, ${val.itemName}` : val.itemName
                                                ))} */}

                                                {data.procedure.map((val, index) => (
                                                    <span key={index}>{val.itemName}
                                                        {index < data.procedure.length - 1 && <br />}
                                                    </span>
                                                ))}

                                            </td>




                                            <td>

                                                Built: {data.observation && data.observation.isBuilt} , Postoral Deviation: {data.observation && data.observation.isPostDeviation || data.observation.isSpecifyPostDev}, Muscle Wasting: {data.observation && data.observation.isMuscleWasting || data.observation.isSpecifyMuscleWast}, Deformity: {data.observation && data.observation.isDeformity || data.observation.isSpecifyDeformity}, Swelling: {data.observation && data.observation.isSwelling || data.observation.isSpecifySwell},
                                                Gait Deviation: {data.observation && data.observation.isGaitDeviation || data.observation.isSpecifyGaitDev}, Appliances: {data.observation && data.observation.isAppliances || data.observation.isSpecifyAppliance}



                                            </td>


                                            <td>

                                                Insignificant: {data.palpation && data.palpation.isInsignificant} , Tenderness: {data.palpation && data.palpation.isTenderness || data.palpation.isSpecifyTenderness}, Warmth: {data.palpation && data.palpation.isWarmth || data.palpation.isSpecifyWarmth}, Oedema: {data.palpation && data.palpation.isOedema || data.palpation.isSpecifyOedema}, Crepitus: {data.palpation && data.palpation.isCrepitus || data.palpation.isSpecifyCrepitus},
                                                Tone: {data.palpation && data.palpation.isTone || data.palpation.isSpecifyTone}, Muscle Spasm: {data.palpation && data.palpation.isMuscleSpasm || data.palpation.isSpecifyMuscleSpasm}


                                            </td>

                                            <td>

                                                {data.riskScreening && data.riskScreening.riskScreeningAdultsAge} ,{data.riskScreening && data.riskScreening.riskScreeningAdultHistory},  {data.riskScreening && data.riskScreening.riskScreeningAdultWalk},  {data.riskScreening && data.riskScreening.riskScreeningAdultNeuro},  {data.riskScreening && data.riskScreening.riskScreeningPaediaFall},
                                                {data.riskScreening && data.riskScreening.riskScreeningPaediaMobility}, {data.riskScreening && data.riskScreening.riskScreeningPaediaNeurological}


                                            </td>

                                            <td>

                                                {data.riskPrevention && data.riskPrevention.riskPreventionProtocolSurround} ,{data.riskPrevention && data.riskPrevention.riskPreventionProtocolBells}


                                            </td>


                                            <td>

                                                <div className="action-button">
                                                    <div data-bs-title="Delete Row" data-bs-placement="bottom"  ><img src={imgPrint} style={{ width: '20px' }} className='' alt='' onClick={() => handlePrint(data, index)} />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                {
                    showAlertToster === 1 ? <AlertToster message={showMessage} handle={setShowAlertToster} /> : ""
                }
                {
                    showSuccessToster === 1 ? <SuccessToster message={showMessage} handle={setShowSuccessToster} /> : ""
                }
            </section >

        </>
    )
}
