// let GetServiceTypeMaster = async() => {
  
//     const url =  window.InventoryBaseUrl + "/api/ServiceTypeMaster/GetAllServiceTypeMaster";
//     const head = {'content-type':'application/json','accept':'*/*',}
//     let data = {};
//     let response = await fetch(url,{
//       method: "GET",
//       header: head,
  
//     }).then(res => res.json()).then(data);
//     return response;
//   }
//   export default  GetServiceTypeMaster;

  async function GetServiceTypeMaster(ClientID) {
    let url = window.InventoryBaseUrl + "/api/ServiceTypeMaster/GetAllServiceTypeMaster?ClientId=" + ClientID;
    let head = { "Content-Type": "application/JSON", accept: '*/*'
 };
  
    let response = await fetch(url, {
      headers: head,
      method: 'GET'
    })
      .then((res) => res.json())
      .then();
  
    return response;
  }
  export default GetServiceTypeMaster;
  
  
