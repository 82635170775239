import { useEffect, useState } from "react";
import TimeCalculate from "../../../Code/TimeCalculate";
import LineInvestigationGrap from "../../../Components/LineInvestigationGrap";
import GetAllDashboardParameterColumnAssign from "../../../Api/GetAllDashboardParameterColumnAssign";
import { useSelector } from "react-redux";

export default function PatientPHR(props) {
  let [ph, setPh] = useState("");
  let [phId, setPhId] = useState("");
  let [phTime, setPhTime] = useState("");
  let [phColor, setPhColor] = useState("");

  let [uhId, setUhId] = useState("")

  // set grap Investigation data
  let [grapInvestigationData, setGrapInvestigationData] = useState({
    showGrap: 0,
    subTestId: '',
    patientName: '',
    UHID: '',
    userId: ''
  })

  let getData = () => {
    setPh("");
    setPhId("");
    setPhTime("");
    setPhColor("");

    props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
      if (investigation.SubTestID.toString() === "607") {

        setPh(Math.round(investigation.SubTestValue * 100) / 100)
        setPhColor(investigation.SubTestColor)
        if (props.handleColor !== undefined) {
          props.handleColor(investigation.SubTestColor !== null ? investigation.SubTestColor : "white", props.columnId)
      }

        setPhId(investigation.SubTestID)
        setUhId(props.patientData.UhId)
        if (investigation.SubTestDateTime !== '') {
          setPhTime(TimeCalculate(investigation.SubTestDateTime))

        }

      }
    })
  }

  let modelCloseFun = () => {
    setGrapInvestigationData({
      showGrap: 0,
      subTestId: '',
      patientName: '',
      UHID: '',
      userId: ''
    })
  }

  let [paramenterData, setPatameterData] = useState([])
  let response = useSelector((state) => state.AllColumnsParamters)
  let getColumnsParameters = () => {
    let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
       if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

  }

  useEffect(() => {
    getColumnsParameters()
    getData()
  }, [props.patientData])
  return (
    <>
      {/* <div className={`overflow-max investigations ${(phColor !== "#008000" && phColor !== "#94e094" && phColor !== "#F5F8FF" && phColor !== "" ? "blink" : "")} `} style={{ border: (phColor !== "#008000" && phColor !== "#94e094" && phColor !== "#F5F8FF" && phColor !== "" ? "2px solid " + phColor : "") }}> */}
      <div key={props.key} className={`overflow-max investigations`} >

        {ph.length != 0 ? ph ? <span>

          {
            paramenterData && paramenterData.map((val, ind) => {
              if (val.parameterId === 71) {
                return (
                  <>
                    <span style={{ color: phColor }}>{ph}</span><br />


                  </>

                )
              }

              else if (val.parameterId === 72) {
                return (
                  <>
                    {
                      phTime.split("-")[1] === "D" && phTime.split("-")[0] >= 4 ? <span style={{ 'color': 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {phTime.toLowerCase().replace("-", "")}</span>
                        : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {phTime.toLowerCase().replace("-", "")}</span>}
                    <br />
                  </>

                )
              }

              else if (val.parameterId === 73 && props.from === undefined) {
                return (
                  <>
                    <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='PH Graph' >
                      {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                        if (val.SubtestID === phId) {
                          return (
                            <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                          )
                        }
                      })}
                      <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [phId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "PH Graph", patientName: props.patientData.PntName }) }}></i></div>

                    {/* <br /> */}
                  </>

                )
              }


            })

          }




          {/* <span>{ph_time}</span> */}
        </span> : 'N/A' : "N/A"}

      </div>
      {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}
    </>
  )
}


export  function PatientPHRG(props) {
  let [ph, setPh] = useState("");
  let [phId, setPhId] = useState("");
  let [phTime, setPhTime] = useState("");
  let [phColor, setPhColor] = useState("");

  let [uhId, setUhId] = useState("")

  // set grap Investigation data
  let [grapInvestigationData, setGrapInvestigationData] = useState({
    showGrap: 0,
    subTestId: '',
    patientName: '',
    UHID: '',
    userId: ''
  })

  let getData = () => {
    setPh("");
    setPhId("");
    setPhTime("");
    setPhColor("");

    props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
      if (investigation.SubTestID.toString() === "607") {

        setPh(Math.round(investigation.SubTestValue * 100) / 100)
        setPhColor(investigation.SubTestColor)
        if (props.setColors !== undefined) {
          props.setColors((prevArray) => ({ ...prevArray, "PH": investigation.SubTestColor }))
        }

        setPhId(investigation.SubTestID)
        setUhId(props.patientData.UhId)
        if (investigation.SubTestDateTime !== '') {
          setPhTime(TimeCalculate(investigation.SubTestDateTime))

        }

      }
    })
  }

  let modelCloseFun = () => {
    setGrapInvestigationData({
      showGrap: 0,
      subTestId: '',
      patientName: '',
      UHID: '',
      userId: ''
    })
  }

  let [paramenterData, setPatameterData] = useState([])
  let response = useSelector((state) => state.AllColumnsParamters)
  let getColumnsParameters = () => {
    let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
       if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

  }

  useEffect(() => {
    getColumnsParameters()
    getData()
  }, [props.patientData])
  return (
    <>
      {/* <div className={`overflow-max investigations ${(phColor !== "#008000" && phColor !== "#94e094" && phColor !== "#F5F8FF" && phColor !== "" ? "blink" : "")} `} style={{ border: (phColor !== "#008000" && phColor !== "#94e094" && phColor !== "#F5F8FF" && phColor !== "" ? "2px solid " + phColor : "") }}> */}
      <div key={props.key}  >

        {ph.length != 0 ? ph ? <span>

          {
            paramenterData && paramenterData.map((val, ind) => {
             

               if (val.parameterId === 73) {
                return (
                  <>
                    <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='PH Graph' >
                      {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                        if (val.SubtestID === phId) {
                          return (
                            <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                          )
                        }
                      })}
                      <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [phId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "PH Graph", patientName: props.patientData.PntName }) }}></i></div>

                    {/* <br /> */}
                  </>

                )
              }


            })

          }




          {/* <span>{ph_time}</span> */}
        </span> : 'N/A' : "N/A"}

      </div>
      {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}
    </>
  )
}