import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import financeLogo from '../assets/icons/firedepartment_svgrepo.com.svg' 




export default function FinanceSidebar({ component }) {
    const [menuText, setmenuText] = useState('')

    let [activeMenu, setActiveMenu] = useState(1)

    return (
        <>
            <div className="container-fluid p-0 d-flex" style={{ height: "100vh" }}>
                <div id="bdSidebar" className="d-flex flex-column flex-shrink-0 p-3 offcanvas-md offcanvas-start ">
                    {/* <hr />                  */}
                    <div className='hrmlogo mt-0 pt-0 pb-2 mx-3'>
                        <div> <img src={financeLogo}  alt="HRM Logo" /> <span>Finance</span></div>
                        <div className='closebtn me-4'>
                            <Link to="#" className="navbar-brand">
                                <button className='d-md-none' data-bs-toggle="offcanvas"
                                    data-bs-target="#bdSidebar"><i class="fa fa-close" ></i></button>
                            </Link>
                        </div>
                    </div>
                    <div>

                    </div>
                    <div className='sidebar-content'>
                        <div className='sidbrheding mt-2 mb-2 mx-3'>MAIN MENU</div>
                    </div>
                    <div className='scrollsidebr'>
                        <ul className="mynav hrmsidebar nav nav-pills flex-column mb-auto gap-3">
                          
                            <li className="nav-item mb-1" onClick={() => setActiveMenu(1)}>
                                <Link to="/">
                                    <img    alt="sideiconimages" />
                                    Dashboard
                                    {/* <span className="notification-badge">5</span> */}
                                </Link>
                            </li>
                            <li className="nav-item mb-1" onClick={() => { setmenuText('Recruitment') }}>
                                <Link to={'/recruitment'} >
                                    <img    alt="sideiconimages" />
                                    Recruitment
                                    {/* <span className="notification-badge">5</span> */}
                                </Link>
                            </li>
                            <li className="nav-item mb-1" onClick={() => { setmenuText('Employee') }}>
                                <Link to={'/addEmployee'}>
                                    <img    alt="sideiconimages" />
                                    Employee
                                </Link>
                            </li>
                           
                           
                            <li className="nav-item mb-1" onClick={() => { setmenuText('Attendance') }}>
                                <Link to={'/shift'}
                                    className="sidebar-link collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#attendance"
                                    aria-expanded="false"
                                    aria-controls="attendance" >
                                    <img    alt="sideiconimages" />
                                    Attendance
                                </Link>

                                <ul id="attendance" className="sidebar-dropdown list-unstyled collapse">
                                    <li className="sidebar-item mx-3">
                                        <Link to='/shift' className="sidebar-link">
                                           
                                            <img   alt="circleimages" />
                                            <span className="topic"> Shift</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-item mx-3">
                                        <Link to="/punchinout" className="sidebar-link">
                                           
                                            <img   alt="circleimages" />
                                            <span className="topic">Punch In/Out</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-item mx-3">
                                        <Link to="/holidays" className="sidebar-link">
                                            <img  alt="circleimages" />
                                          
                                            <span className="topic">Holidays</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-item mx-3">
                                        {/* <Link to='/addnewleave' className="sidebar-link"/> */}
                                        <Link to="/addnewleave" className="sidebar-link">
                                          
                                            <img  alt="circleimages" />
                                            <span className="topic">Leaves</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-item mx-3">
                                        {/* <Link to='/addnewleave' className="sidebar-link"/> */}
                                        <Link to="/grievance" className="sidebar-link">
                                          
                                            <img  alt="circleimages" />
                                            <span className="topic">Grievance</span>
                                        </Link>
                                    </li>
                                    
                                </ul>
                            </li>
                            <li className="nav-item mb-1">
                                <Link to="#">
                                    <img  alt="sideiconimages" />
                                    Payroll
                                </Link>
                            </li>
                            <li className="nav-item mb-1">
                                <Link to="#">
                                    <img alt="sideiconimages" />
                                    Benefits
                                </Link>
                            </li>
                            <li className="nav-item mb-1" onClick={() => { setmenuText('Trainings(Dashboard)') }}>
                                <Link to="#"
                                    className="sidebar-link collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#trainings"
                                    aria-expanded="false"
                                    aria-controls="trainings" >
                                    <img  alt="sideiconimages" />
                                    Trainings
                                </Link>
                                <ul id="trainings" className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#recruitment">
                                    <li className="sidebar-item mx-3">
                                        <Link to="/trainingdashboard" className="sidebar-link">
                                            {/* <i class="fa fa-circle" aria-hidden="true"></i> */}
                                            <img alt="circleimages" />
                                            <span className="topic"> Dashboard</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-item mx-3">
                                        <Link to={'/scheduleTraining'} className="sidebar-link">
                                            {/* <i class="fa fa-circle" aria-hidden="true"></i> */}
                                            <img  alt="circleimages" />
                                            <span className="topic">  Schedule Training</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-item mx-3">
                                        <Link to='/topicmaster' className="sidebar-link">
                                            {/* <i class="fa fa-circle" aria-hidden="true"></i> */}
                                            <img   alt="circleimages" />
                                            <span className="topic">Manage Tranig Topic</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-item mb-1">
                                <Link to="#"
                                    className="sidebar-link collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#employeeportal"
                                    aria-expanded="false"
                                    aria-controls="employeeportal" >
                                    <img   alt="sideiconimages" />
                                    Employee Portal
                                </Link>
                                <ul id="employeeportal" className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#recruitment">
                                    <li className="sidebar-item mx-3">
                                        <Link to="/employeedashboard" className="sidebar-link">
                                            {/* <i class="fa fa-circle" aria-hidden="true"></i> */}

                                            <img  alt="circleimages" />
                                            <span className="topic">Dashboard</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-item mx-3">
                                        <Link to={'/employeeattendence/'} className="sidebar-link">
                                            {/* <i class="fa fa-circle" aria-hidden="true"></i> */}
                                            <img  alt="circleimages" />
                                            <span className="topic">My Attendance</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-item mx-3">
                                        <Link to='/employeeleavholiday' className="sidebar-link">
                                            {/* <i class="fa fa-circle" aria-hidden="true"></i> */}
                                            <img  alt="circleimages" />
                                            <span className="topic">Leaves & Holiday's</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-item mx-3">
                                        <Link to='/employeepayments' className="sidebar-link">
                                            {/* <i class="fa fa-circle" aria-hidden="true"></i> */}

                                            <img  alt="circleimages" />
                                            <span className="topic">Payments</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item mb-1">
                                <Link to="#">
                                    <img   alt="sideiconimages" />
                                    Performance
                                </Link>
                            </li>
                            <li className="nav-item mb-1">
                                <Link to="#">
                                    <img  alt="sideiconimages" />
                                    Compliance
                                </Link>
                            </li>

                            {/* Sabiha */}
                            {/* <li className="nav-item mb-1">
                            <Link to="#">
                                <img   {masters} alt="sideiconimages" />
                                Masters
                            </Link>
                        </li> */}


                            <li className="nav-item mb-1">
                                <Link to="#"
                                    className="sidebar-link collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#Masters"
                                    aria-expanded="false"
                                    aria-controls="attendance" >
                                    
                                    Masters
                                </Link>


                                <ul id="Masters" className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#shiftmaster">
                                    <li className="sidebar-item mx-3">
                                        <Link to='/shiftmaster' className="sidebar-link">
                                            {/* <i class="fa fa-circle" aria-hidden="true"></i> */}
                                            <img   alt="circleimages" />
                                            <span className="topic">Shift Master</span>
                                        </Link>
                                    </li>
                                    <li className="sidebar-item mx-3">
                                        <Link to='/leavemaster' className="sidebar-link">
                                            {/* {/* <i class="fa fa-circle" aria-hidden="true"></i>  */}
                                            <img  alt="circleimages" />
                                            <span className="topic">Leave Master</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            {/* <li className="sidebar-item nav-item mb-1">
                                <div className='sidbrheding mt-2 mb-2 mx-3'>SUPPORT</div>
                                <Link to="#"
                                    className="sidebar-link collapsed">
                                    <img   {settings} alt="sideiconimages" />
                                    <span className="topic">Settings </span>
                                </Link>
                            </li>
                            <li className="sidebar-item ">
                                <Link to="#" className="sidebar-link">
                                    <img   {help} alt="sideiconimages" />
                                    <span className="topic"> Help</span>
                                </Link>
                            </li>
                            <li className="sidebar-item">
                                <Link to="#" className="sidebar-link">
                                    <img   {logout} alt="sideiconimages" />
                                    <span className="topic">Log Out</span>
                                </Link>
                            </li> */}
                        </ul>
                    </div>

                    <hr />
                    {/* <div className="d-flex">

                        <i className="fa-solid fa-book  me-2"></i>
                        <span>
                            <h6 className="mt-1 mb-0">
                                Geeks for Geeks Learning Portal
                            </h6>
                        </span>
                    </div> */}
                </div>

                <div className="bg-light flex-fill sidecontentwidh" >
                    <div className="p-2 d-md-none d-flex text-white bg-warning"  >
                        <Link to="#" className="text-white"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#bdSidebar">
                            <i className="fa-solid fa-bars"></i>
                        </Link>
                        <span className="ms-3">HRMS Portal</span>
                    </div>
                    <div className="ms-2" >
                        {/* <nav style={{"--bs-breadcrumb-divider":"'>'", "font-size":"14px"}}>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <i className="fa-solid fa-house"></i>
                                </li>
                                <li className="breadcrumb-item">Learning Content</li>
                                <li className="breadcrumb-item">Next Page</li>
                            </ol>
                        </nav> */}
                        

                        {/* <hr className='mt-2'/> */}
                        <div className="row_  p-2 mt-2" style={{ backgroundColor: "white" }}>
                            <div className="col">
                                {
                                    component
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
