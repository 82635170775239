async function GetAllNurseByShift(params) {
    let url = window.AppbaseUrl + `/api/PatientNurseShiftAssign/GetNurseByShift?clientId=${params.clientId}&shiftId=${params.shiftId}`;
    let head = { "Content-Type": "application/JSON", accept: '*/*' };

    let response = await fetch(url, {
        headers: head,
        method: 'GET'
    })
        .then((res) => res.json())
        .then(params);

    return response;
}

export default GetAllNurseByShift;
  
  