import { useEffect, useState } from "react";
import ProvisonalDiagnosisMainComponent from "../ProvisonalDiagnosisMainComponent";
import GetPatientDetailsforSarvodaya from "../API/GetPatientDetailsforSarvodaya";
import saveButtonIcon from "../../../assets/images/icons/saveButton.svg";
import clearIcon from "../../../assets/images/icons/clear.svg";
import { t } from "i18next";
import GetDiagnosis from "../API/GetDiagnosis";
import imgPrint from "../../../assets/images/icons/imgPrint.svg";
import postDailyReassessmentSheet from "../API/DailyRessessmentSheet/postDailyReassessmentSheet";
import GetDailyassessmentSheet from "../API/DailyRessessmentSheet/GetDailyassessmentSheet";
import Heading from "../../../Component/Heading";
import Loader from "../../../Component/Loader";
import AlertToster from "../../../Component/AlertToster";
import SuccessToster from "../../../Component/SuccessToster";
import PatientDeatils from "../../../Component/PatientDeatils";


export default function DailyReassessmentSheet() {
  const [patientData, setPatientData] = useState([]);
  const [ReasonAdmission, setReasonAdmission] = useState("");
  const [GAssisstent, setGAssisstent] = useState("");
  const [CardAssisstent, setCardAssisstent] = useState("");
  const [showUnderProcess, setShowUnderProcess] = useState(0);
  const [showToster, setShowToster] = useState(0);
  const [tosterMessage, setTosterMessage] = useState("");
  const [tosterValue, setTosterValue] = useState(0);
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [showSuccessToster, setShowSuccessToster] = useState(0);
  let [showMessage, setShowMeassage] = useState("");
  let [loader, setLoader] = useState(0);

  const [nutritionalData, setNutritionalData] = useState("");
  const [reportData, setReportData] = useState("");
  const [probData, setProbData] = useState("");
  const [tubesinfectionsData, settubesinfectionsData] = useState("");
  const [EndoMetabolicData, setEndoMetabolicData] = useState("");
  const [RenalData, setRenalData] = useState("");
  const [GiData, setGiData] = useState("");
  const [NeuroData, setNeuroData] = useState("");
  const [ReplraData, setReplraData] = useState("");
  const [CardiacData, setCardiacData] = useState("");
  const [GeneralData, setGeneralData] = useState("");
  const [ReasonICU, setReasonICU] = useState("");
  const [WorkingDiag, setWorkingDiag] = useState("");
  const [PlanTreatment, setPlanTreatment] = useState("");
  const [CrossReferral, setCrossReferral] = useState("");
  const [prognosis, setprognosis] = useState("");
  const [Investigation, setInvestigation] = useState("");
  const [planOfDay, setplanOfDay] = useState("");
  const [ProblemsDiagnosis, setProblemsdiagnosis] = useState("");



  const [nutrichipsData, setNutrichipsData] = useState([]);
  const [reportchipsData, setReportchipsData] = useState([]);
  const [problesschipsData, setproblesschipsData] = useState([]);
  const [infectionschipsData, setInfectionschipsData] = useState([]);
  const [metabolicChipsData, setMetabolicChipsData] = useState([]);
  const [renalAsstData, setRenalAsstData] = useState([]);
  const [giDataAsst, setGiDataAsst] = useState([]);
  const [neuroAsstData, setNeuroAsstData] = useState([]);
  const [replraasstData, setReplraasstData] = useState([]);
  const [cardiacAsstData, setCardiacAsstData] = useState([]);
  const [generalAsstData, setGeneralAsstData] = useState([]);
  const [reasonICUData, setReasonICUData] = useState([]);
  const [workingDiagData, setWorkingDiagData] = useState([]);
  const [planTreatmentData, setPlanTreatmentData] = useState([]);
  const [crossReferralData, setCrossReferralData] = useState([]);
  const [prognosislData, setprognosislData] = useState([]);
  const [investigationData, setInvestigationData] = useState([]);
  const [planOfdayData, setplanOfdayData] = useState([]);
  const [problemsdiagnosisData, setProblemsdiagnosisData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tubesfections, setTubesfections] = useState([]);
  const [crossReferral, setcrossReferral] = useState([]);

  const [detailsDate, setDetailsDate] = useState("");

  const handleInputChange = (event) => {
    setNutritionalData(event.target.value);
  };
  const handleInp = (event) => {
    setReportData(event.target.value);
  };

  let handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "nutritional") {
      setNutritionalData(value);
    }
    if (name === "investreport") {
      setReportData(value);
    }
    if (name === "ptproblems") {
      setProbData(value);
    }
    if (name === "tubesinfections") {
      settubesinfectionsData(value);
    }
    if (name === "MetabolicAsst") {
      setEndoMetabolicData(value);
    }
    if (name === "renalAssessment") {
      setRenalData(value);
    }
    if (name === "giassesst") {
      setGiData(value);
    }
    if (name === "neuroasst") {
      setNeuroData(value);
    }
    if (name === "replratsst") {
      setReplraData(value);
    }
    if (name === "cardiac") {
      setCardiacData(value);
    }
    if (name === "general") {
      setGeneralData(value);
    }
    if (name === "reason") {
      setReasonICU(value);
    }
    if (name === "workdiag") {
      setWorkingDiag(value);
    }
    if (name === "treatment") {
      setPlanTreatment(value);
    }
    if (name === "crossref") {
      setCrossReferral(value);
    }
    if (name === "aboutprognosis") {
      setprognosis(value);
    }
    if (name === "invetigations") {
      setInvestigation(value);
    }
    if (name === "planofday") {
      setplanOfDay(value);
    }
    if (name === "problemsdiagnosis") {
      setProblemsdiagnosis(value);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      if (nutritionalData.length > 0) {
        setNutrichipsData([...nutrichipsData, nutritionalData]); // Add entered data to nutrichipsData array
        setNutritionalData("");
      } else if (reportData.length > 0) {
        setReportchipsData([...reportchipsData, reportData]); // Add entered data to nutrichipsData array
        setReportData("");
      } else if (probData.length > 0) {
        setproblesschipsData([...problesschipsData, probData]);
        setProbData("");
      } else if (tubesinfectionsData.length > 0) {
        setInfectionschipsData([...infectionschipsData, tubesinfectionsData]); // Add entered data to nutrichipsData array
        settubesinfectionsData("");
      } else if (EndoMetabolicData.length > 0) {
        setMetabolicChipsData([...metabolicChipsData, EndoMetabolicData]); // Add entered data to nutrichipsData array
        setEndoMetabolicData("");
      } else if (RenalData.length > 0) {
        setRenalAsstData([...renalAsstData, RenalData]);
        setRenalData("");
      } else if (GiData.length > 0) {
        setGiDataAsst([...giDataAsst, GiData]);
        setGiData("");
      } else if (NeuroData.length > 0) {
        setNeuroAsstData([...neuroAsstData, NeuroData]);
        setNeuroData("");
      } else if (ReplraData.length > 0) {
        setReplraasstData([...replraasstData, ReplraData]);
        setReplraData("");
      } else if (CardiacData.length > 0) {
        setCardiacAsstData([...cardiacAsstData, CardiacData]);
        setCardiacData("");
      } else if (GeneralData.length > 0) {
        setGeneralAsstData([...generalAsstData, GeneralData]);
        setGeneralData("");
      } else if (ReasonICU.length > 0) {
        setReasonICUData([...reasonICUData, ReasonICU]);
        setReasonICU("");
      } else if (WorkingDiag.length > 0) {
        setWorkingDiagData([...workingDiagData, WorkingDiag]);
        setWorkingDiag("");
      } else if (PlanTreatment.length > 0) {
        setPlanTreatmentData([...planTreatmentData, PlanTreatment]);
        setPlanTreatment("");
      } else if (CrossReferral.length > 0) {
        setCrossReferralData([...crossReferralData, CrossReferral]);
        setCrossReferral("");
      } else if (prognosis.length > 0) {
        setprognosislData([...prognosislData, prognosis]);
        setprognosis("");
      } else if (Investigation.length > 0) {
        setInvestigationData([...investigationData, Investigation]);
        setInvestigation("");
      } else if (planOfDay.length > 0) {
        setplanOfdayData([...planOfdayData, planOfDay]);
        setplanOfDay("");
      } else if (ProblemsDiagnosis.length > 0) {
        setProblemsdiagnosisData([...problemsdiagnosisData, ProblemsDiagnosis]);
        setProblemsdiagnosis("");
      }
    }
  };

  const handleChipDelete = (index, arrayType) => {
    if (arrayType === "nutrichipsData") {
      const newNutriChipsData = [...nutrichipsData];
      newNutriChipsData.splice(index, 1);
      setNutrichipsData(newNutriChipsData);
    } else if (arrayType === "reportchipsData") {
      const newReportChipsData = [...reportchipsData];
      newReportChipsData.splice(index, 1);
      setReportchipsData(newReportChipsData);
    } else if (arrayType === "problesschipsData") {
      const newprblsChipsData = [...problesschipsData];
      newprblsChipsData.splice(index, 1);
      setproblesschipsData(newprblsChipsData);
    } else if (arrayType === "infectionschipsData") {
      const newinfectionChipsData = [...infectionschipsData];
      newinfectionChipsData.splice(index, 1);
      setInfectionschipsData(newinfectionChipsData);
    } else if (arrayType === "MetabolicData") {
      const newMetabolicChipsData = [...metabolicChipsData];
      newMetabolicChipsData.splice(index, 1);
      setMetabolicChipsData(newMetabolicChipsData);
    } else if (arrayType === "RenalAsstData") {
      const newRenalChipsData = [...renalAsstData];
      newRenalChipsData.splice(index, 1);
      setRenalAsstData(newRenalChipsData);
    } else if (arrayType === "GiDataAsst") {
      const newGiChipsData = [...giDataAsst];
      newGiChipsData.splice(index, 1);
      setGiDataAsst(newGiChipsData);
    } else if (arrayType === "NeuroAsstData") {
      const newNeuroChipsData = [...neuroAsstData];
      newNeuroChipsData.splice(index, 1);
      setNeuroAsstData(newNeuroChipsData);
    } else if (arrayType === "ReplraasstData") {
      const newReplrChipsData = [...replraasstData];
      newReplrChipsData.splice(index, 1);
      setReplraasstData(newReplrChipsData);
    } else if (arrayType === "CardiacAsstData") {
      const newCardiacChipsData = [...cardiacAsstData];
      newCardiacChipsData.splice(index, 1);
      setCardiacAsstData(newCardiacChipsData);
    } else if (arrayType === "GeneralAsstData") {
      const newGeneralChipsData = [...generalAsstData];
      newGeneralChipsData.splice(index, 1);
      setGeneralAsstData(newGeneralChipsData);
    } else if (arrayType === "ReasonICUData") {
      const newReasonChipsData = [...reasonICUData];
      newReasonChipsData.splice(index, 1);
      setReasonICUData(newReasonChipsData);
    } else if (arrayType === "WorkingDiagData") {
      const newWorkingDiagChipsData = [...workingDiagData];
      newWorkingDiagChipsData.splice(index, 1);
      setWorkingDiagData(newWorkingDiagChipsData);
    } else if (arrayType === "PlanTreatmentData") {
      const newTreatmentChipsData = [...planTreatmentData];
      newTreatmentChipsData.splice(index, 1);
      setPlanTreatmentData(newTreatmentChipsData);
    } else if (arrayType === "CrossReferralData") {
      const newReferralChipsData = [...crossReferralData];
      newReferralChipsData.splice(index, 1);
      setCrossReferralData(newReferralChipsData);
    } else if (arrayType === "prognosislData") {
      const newprognosisChipsData = [...prognosislData];
      newprognosisChipsData.splice(index, 1);
      setprognosislData(newprognosisChipsData);
    } else if (arrayType === "InvestigationData") {
      const newInvestigationChipsData = [...investigationData];
      newInvestigationChipsData.splice(index, 1);
      setInvestigationData(newInvestigationChipsData);
    } else if (arrayType === "planOfdayData") {
      const newplanOfdayChipsData = [...planOfdayData];
      newplanOfdayChipsData.splice(index, 1);
      setplanOfdayData(newplanOfdayChipsData);
    } else if (arrayType === "problemsdiagnosisData") {
      const newproblemsdiagnosisChipsData = [...problemsdiagnosisData];
      newproblemsdiagnosisChipsData.splice(index, 1);
      setProblemsdiagnosisData(newproblemsdiagnosisChipsData);
    }
  };

  let [saveDiagnosis, setSaveDiagnosis] = useState(0);
  let [userID, setUserID] = useState(
    JSON.parse(sessionStorage.getItem("LoginData")).userId
  );
  const clientID = JSON.parse(
    window.sessionStorage.getItem("LoginData")
  ).clientId;
  const [DiagnosisList, setDiagnosisList] = useState([]);
  const [sendForm, setSendForm] = useState({
    reasonICU: "",
    generalAsst: "",
    cardiacAsst: "",
    replratoryAsst: "",
    neuroAsst: "",
    giAsst: "",
    renalAsst: "",
    endocrinemetabolicAsset: "",
    LinesTubesInfectionsAsset: "",
    NutriAsst: "",
    investigationadmissionreport: "",
    Prblems: "",
    workingdiagnosis: "",
    planoftreatment: "",
    crossreferral: "",
    aboutprognosis: "",
    investigation: "",
    planOfTheDay:""
  });

  const getPatientData = async () => {
    let data = await GetPatientDetailsforSarvodaya(requiredUHID);
    if (data.status === 1) {
      setPatientData(data.responseValue[0]);
    }

  };

  const getProvisionalDiagnosis = async () => {
    let data = await GetDiagnosis(requiredUHID);
    if (data.status === 1) {
      setDiagnosisList(
        data.responseValue.map((val) => ({
          value: val.detailID,
          label: val.details,
        }))
      );
      console.log("data", data.responseValue);
    }
  };
  let requiredUHID = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].uhId;

  let handleOnChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setSendForm((sendForm) => ({
      ...sendForm,
      [name]: value,
    }));
  };

  const handleOnPrint = async (index) => {
    const obj = {
      pateintName: patientData.patientName,
      age: patientData.age,
      gender: patientData.gender,
      ipNo: patientData.ipNo,
      bedName: patientData.bedName,
      ipNo: patientData.ipNo,
      DOA: patientData.admitDate,
      consultant: patientData.doctorName,
      jsondata: tableData
    };
    console.log("rowData", obj);
    sessionStorage.setItem('DailyReaassessmentSheetPrintData', JSON.stringify(obj));
    window.open("/ReceiptDailyReassesmentSheetprint/", "noopener,noreferrer");
  };


  let handleSave = async () => {
    const nutritionaljsonData = {
      detailsId: 0,
      details: nutritionalData,
      detailsDate: detailsDate,
    };
    const infectjsonData = {
      detailsId: 0,
      details: tubesinfectionsData,
      detailsDate: detailsDate,
    };
    const MetaboljsonData = {
      detailsId: 0,
      details: EndoMetabolicData,
      detailsDate: detailsDate,
    };
    const RenalasstjsonData = {
      detailsId: 0,
      details: RenalData,
      detailsDate: detailsDate,
    };
    const GassesstjsonData = {
      detailsId: 0,
      details: GiData,
      detailsDate: detailsDate,
    };
    const neurosstjsonData = {
      detailsId: 0,
      details: NeuroData,
      detailsDate: detailsDate,
    };
    const replrjsonData = {
      detailsId: 0,
      details: ReplraData,
      detailsDate: detailsDate,
    };
    const cardiajsonData = {
      detailsId: 0,
      details: CardiacData,
      detailsDate: detailsDate,
    };
    const gneraljsonData = {
      detailsId: 0,
      details: GeneralData,
      detailsDate: detailsDate,
    };
    const reasonicujsonData = {
      detailsId: 0,
      details: ReasonICU,
      detailsDate: detailsDate,
    };
    const planOfTheDayjsonData = {
      detailsId: 0,
      details: planOfDay,
      detailsDate: detailsDate,
    };
 

   
    let obj = {
      icuAdmisssion: JSON.stringify([reasonicujsonData]),
      generalAssessment: JSON.stringify([gneraljsonData]),
      cardiacAssessment: JSON.stringify([cardiajsonData]),
      repiratoryAssessment: JSON.stringify([replrjsonData]),
      neuroAssessment: JSON.stringify([neurosstjsonData]),
      giAssessment: JSON.stringify([GassesstjsonData]),
      renalAssessment: JSON.stringify([RenalasstjsonData]),
      metabolicAssessment: JSON.stringify([MetaboljsonData]),
      assessmentOfLineTubesInfections: JSON.stringify([infectjsonData]),
      nutritionalAssessment: JSON.stringify([nutritionaljsonData]),
      planOfTheDay: JSON.stringify([planOfTheDayjsonData]),
      userId: userID,
      clientId: clientID,
      uhid: requiredUHID,
    };
    console.log("asdf test", obj);
    //return;
    setLoader(1)
    let data = await postDailyReassessmentSheet(obj);
    if (data.status === 1) {
      setShowSuccessToster(1)
      setShowMeassage("Data Saved Successfully");
      getData();
      handleClear()
    } else {
      setShowUnderProcess(0);
      setShowToster(1);
      setTosterValue(1);
      setTosterMessage("Already Exist!");
      setTosterMessage(data.responseValue);
      setLoader(0)
      setTimeout(() => {
        setShowToster(0);
      }, 2000);
    }
  };



  const getData = async () => {
    setLoader(1)
    let data = await GetDailyassessmentSheet(requiredUHID);

    if (data.status === 1) {
      setTableData(data.responseValue);
      setLoader(0)
    }
    else {
      setLoader(0)
    }
  };

  const handleClear = () => {
    setReasonICU("");
    setNutritionalData("")
    setReportData("")
    settubesinfectionsData("")
    setEndoMetabolicData("")
    setRenalData("")
    setGiData("")
    setNeuroData("")
    setReplraData("")
    setCardiacData("")
    setGeneralData("")
    setWorkingDiag("")
    setPlanTreatment("")
    setCrossReferral("")
    setprognosis("")
    setInvestigation("")
    setplanOfDay("")
    setProblemsdiagnosis("")
    setProbData("")
  
  }

  useEffect(() => {
    getData();
    getPatientData();
    getProvisionalDiagnosis();
    const interval = setTimeout(() => {
      const now = new Date();
      setDetailsDate(now.toISOString());
    }, 200); // Update every second

    // Clear interval on component unmount
    // return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="container-fluid_">
        <div className="row">
          <div className="col-12">
            <div className="med-box mb-1">
              <div className="title">Daily Reassessment Sheet</div>
            </div>
          </div>
          <div className="col-12">
            <PatientDeatils />
          </div>

          <div className="col-12">
            <div className="med-box">
              <div className="inner-content">
                <div className="fieldsett-in col-md-12">
                  <div className="fieldsett">
                    <span className="fieldse">Diagnosis</span>
                    <div className="row">
                      {/* <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                        <label htmlFor="Code" className="form-label">
                          Patient Name
                        </label>
                        <input
                          id="ddalarmtime"
                          type="text"
                          value={patientData && patientData.patientName}
                          className="form-control form-control-sm"
                          disabled
                          placeholder="Patient Name"
                          name="PName"
                        />
                      </div> */}

                      {/* <div className="col-xxl-1 col-xl-3 col-lg-4 col-md-6 mb-3">
                      <label htmlFor="Code" className="form-label">MRD No.<span className="starMandatory">*</span></label>
                      <input id="ddwarningviewtime" type="text" value={sendForm.mrdNo} className="form-control form-control-sm" disabled placeholder='MRD No.' name="MRD" />
                    </div> */}

                      {/* <div
                        className="col-xxl-1 col-xl-3 col-lg-4 col-md-6 mb-3"
                        id="paymentModediv"
                      >
                        <label htmlFor="Code" className="form-label">
                          Age
                        </label>
                        <input
                          id="ddwarningviewtime"
                          type="number"
                          value={patientData && patientData.age}
                          className="form-control form-control-sm"
                          disabled
                          placeholder="Age"
                          name="Age"
                        />
                      </div> */}
                      {/* <div
                        className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3"
                        id="paymentModediv"
                      >
                        <label htmlFor="Code" className="form-label">
                          Gender
                        </label>
                        <input
                          id="Payment"
                          className="form-control form-control-sm"
                          name="gender"
                          disabled
                          value={patientData && patientData.gender}
                        >
                        </input>
                      </div>
                      <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                        <label htmlFor="Code" className="form-label">
                          Bed No.
                        </label>
                        <input
                          id="ddalarmtime"
                          type="text"
                          disabled
                          value={patientData && patientData.bedName}
                          className="form-control form-control-sm"
                          placeholder="Enter Bed No."
                          name="admissionDate"
                        />
                      </div>

                      <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                        <label htmlFor="Code" className="form-label">
                          IP No.
                        </label>
                        <input
                          id="ddwarningviewtime"
                          type="text"
                          disabled
                          value={patientData && patientData.ipNo}
                          className="form-control form-control-sm"
                          placeholder="Enter IP No."
                          name="admissionTime"
                        />
                      </div>

                      <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                        <label htmlFor="Code" className="form-label">
                          Consultant
                        </label>
                        <input
                          id="ddwarningviewtime"
                          type="text"
                          disabled
                          value={patientData && patientData.doctorName}
                          className="form-control form-control-sm"
                          placeholder="Enter Name"
                          name="admissionTime"
                        />
                      </div>

                      <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                        <label htmlFor="Code" className="form-label">
                          Intensivist
                        </label>
                        <input
                          id="ddwarningviewtime"
                          type="text"
                          disabled
                          value={patientData && patientData.Intensivist}
                          className="form-control form-control-sm"
                          name="admissionTime"
                        />
                      </div>

                      <div
                        className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3"
                        id="paymentModediv"
                      >
                        <label htmlFor="Code" className="form-label">
                          Date of Admission
                        </label>
                        <input
                          id="ddwarningviewtime"
                          disabled
                          value={patientData && patientData.admitDate
                          }
                          className="form-control form-control-sm"
                          placeholder="Enter Diagnosis"
                          name="Diagnosis"
                        />
                      </div> */}
                      <div>
                        <ProvisonalDiagnosisMainComponent
                          isProvisional={0}
                          saveDiagnosis={saveDiagnosis}
                          setSaveDiagnosis={setSaveDiagnosis}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mt-3">
            <div className="med-box">
              <div className="inner-content">
                <div className="fieldsett-in ">
                  <div className="fieldsett" >
                    <span className="fieldse"></span>
                    <div className="row" >
                      <div className="col-sm-4">
                        <label htmlFor="Code" className="form-label ">
                          Reason for ICU admission
                        </label>
                        <div className="form-floating ">
                          <div className=" dailyrescontainer_ ">
                            <div className="inputdailyresContainer_ ">
                              <textarea
                                type="text"
                                className="form-control"
                                name="reason"
                                value={ReasonICU}
                                onChange={handleChange}
                                // onKeyDown={handleKeyDown}
                                placeholder="Enter Reason for ICU admission"
                              />
                              {/* <div className="chipsContainer" >
                                {reasonICUData.map((reasonicu, index) => (
                                  <div key={index} className="chip">
                                    {reasonicu}
                                    <button
                                      onClick={() =>
                                        handleChipDelete(index, "ReasonICUData")
                                      }
                                    >
                                      x
                                    </button>
                                  </div>
                                ))}
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="Code" className="form-label">
                          General Assessment
                        </label>
                        <div className="form-floating">
                          <div className=" dailyrescontainer_">
                            <div className="inputdailyresContainer_">
                              <textarea
                                type="text"
                                className="form-control"
                                name="general"
                                value={GeneralData}
                                onChange={handleChange}
                                // onKeyDown={handleKeyDown}
                                placeholder="Enter General Assessment"
                              />
                              {/* <div className="chipsContainer">
                                {generalAsstData.map((gneral, index) => (
                                  <div key={index} className="chip">
                                    {gneral}
                                    <button
                                      onClick={() =>
                                        handleChipDelete(
                                          index,
                                          "GeneralAsstData"
                                        )
                                      }
                                    >
                                      x
                                    </button>
                                  </div>
                                ))}
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="Code" className="form-label">
                          Cardiac Assessment
                        </label>
                        {/* <label htmlFor="Code" className="form-label" style={{color:'black'}}>
                          Cardiac Assessment
                        </label> */}
                        <div className=" form-floating">
                          <div className=" dailyrescontainer_">
                            <div className="inputdailyresContainer_">
                              <textarea
                                type="text"
                                className="form-control"
                                name="cardiac"
                                value={CardiacData}
                                onChange={handleChange}
                                // onKeyDown={handleKeyDown}
                                placeholder="Enter Cardiac Assessment"
                              />
                              {/* <div className="chipsContainer">
                                {cardiacAsstData.map((cardia, index) => (
                                  <div key={index} className="chip">
                                    {cardia}
                                    <button
                                      onClick={() =>
                                        handleChipDelete(
                                          index,
                                          "CardiacAsstData"
                                        )
                                      }
                                    >
                                      x
                                    </button>
                                  </div>
                                ))}
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="row" >
                      <div className="col-sm-4">
                        <label htmlFor="Code" className="form-label ">
                          Replratory Assessment
                        </label>
                        <div className="form-floating">
                          <div className=" dailyrescontainer_">
                            <div className="inputdailyresContainer_">
                              <textarea
                                type="text"
                                className="form-control"
                                name="replratsst"
                                value={ReplraData}
                                onChange={handleChange}
                                // onKeyDown={handleKeyDown}
                                placeholder="Enter Replratory Assessment"
                              />
                              {/* <div className="chipsContainer">
                                {replraasstData.map((replr, index) => (
                                  <div key={index} className="chip">
                                    {replr}
                                    <button
                                      onClick={() =>
                                        handleChipDelete(
                                          index,
                                          "ReplraasstData"
                                        )
                                      }
                                    >
                                      x
                                    </button>
                                  </div>
                                ))}
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-4">
                        <label htmlFor="Code" className="form-label">
                          Neuro Assessment
                        </label>
                        <div className="form-floating">
                          <div className=" dailyrescontainer_">
                            <div className="inputdailyresContainer_">
                              <textarea
                                type="text"
                                className="form-control"
                                name="neuroasst"
                                value={NeuroData}
                                onChange={handleChange}
                                // onKeyDown={handleKeyDown}
                                placeholder="Enter Neuro Assessment"
                              />
                              {/* <div className="chipsContainer">
                                {neuroAsstData.map((neurosst, index) => (
                                  <div key={index} className="chip">
                                    {neurosst}
                                    <button
                                      onClick={() =>
                                        handleChipDelete(index, "NeuroAsstData")
                                      }
                                    >
                                      x
                                    </button>
                                  </div>
                                ))}
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="Code" className="form-label">
                          GI Assessment
                        </label>
                        <div className="form-floating">
                          <div className=" dailyrescontainer_">
                            <div className="inputdailyresContainer_">
                              <textarea
                                type="text"
                                name="giassesst"
                                className="form-control"
                                value={GiData}
                                onChange={handleChange}
                                // onKeyDown={handleKeyDown}
                                placeholder="Enter GI Assessment"
                              />
                              {/* <div className="chipsContainer">
                                {giDataAsst.map((Gassesst, index) => (
                                  <div key={index} className="chip">
                                    {Gassesst}
                                    <button
                                      onClick={() =>
                                        handleChipDelete(index, "GiDataAsst")
                                      }
                                    >
                                      x
                                    </button>
                                  </div>
                                ))}
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row" >
                      <div className="col-sm-4">
                        <label htmlFor="Code" className="form-label ">
                          Renal Assessment
                        </label>
                        <div className="form-floating">
                          <div className=" dailyrescontainer_">
                            <div className="inputdailyresContainer_">
                              <textarea
                                type="text"
                                name="renalAssessment"
                                className="form-control"
                                value={RenalData}
                                onChange={handleChange}
                                // onKeyDown={handleKeyDown}
                                placeholder="Enter Renal Assessment"
                              />
                              {/* <div className="chipsContainer">
                                {renalAsstData.map((Renalasst, index) => (
                                  <div key={index} className="chip">
                                    {Renalasst}
                                    <button
                                      onClick={() =>
                                        handleChipDelete(index, "RenalAsstData")
                                      }
                                    >
                                      x
                                    </button>
                                  </div>
                                ))}
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-4">
                        <label htmlFor="Code" className="form-label">
                          Endocrine / Metabolic Assessment
                        </label>
                        <div className="form-floating">
                          <div className=" dailyrescontainer_">
                            <div className="inputdailyresContainer_">
                              <textarea
                                type="text"
                                name="MetabolicAsst"
                                className="form-control"
                                value={EndoMetabolicData}
                                onChange={handleChange}
                                // onKeyDown={handleKeyDown}
                                placeholder="Enter Endocrine Metabolic"
                              />
                              {/* <div className="chipsContainer">
                                {metabolicChipsData.map((Metabol, index) => (
                                  <div key={index} className="chip">
                                    {Metabol}
                                    <button
                                      onClick={() =>
                                        handleChipDelete(index, "MetabolicData")
                                      }
                                    >
                                      x
                                    </button>
                                  </div>
                                ))}
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="row" style={{height: '10vh'}}> */}
                      <div className="col-sm-4">
                        <label htmlFor="Code" className="form-label" style={{ width: '73%' }}>
                          Assessment of Lines / Tubes / Infections
                        </label>
                        <div className="form-floating">
                          <div className=" dailyrescontainer_">
                            <div className="inputdailyresContainer_">
                              <textarea
                                type="text"
                                name="tubesinfections"
                                className="form-control"
                                value={tubesinfectionsData}
                                onChange={handleChange}
                                // onKeyDown={handleKeyDown}
                                placeholder="Enter Lines/Tubes/Infections"
                              />
                              {/* <div className="chipsContainer">
                                {infectionschipsData.map((infect, index) => (
                                  <div key={index} className="chip">
                                    {infect}
                                    <button
                                      onClick={() =>
                                        handleChipDelete(
                                          index,
                                          "infectionschipsData"
                                        )
                                      }
                                    >
                                      x
                                    </button>
                                  </div>
                                ))}
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row" >
                      <div className="col-sm-4">
                        <label htmlFor="Code" className="form-label ">
                          Nutritional Assessment
                        </label>
                        <div className="form-floating">
                          <div className=" dailyrescontainer_">
                            <div className="inputdailyresContainer_">
                              <textarea
                                type="text"
                                name="nutritional"
                                className="form-control"
                                value={nutritionalData}
                                onChange={handleChange}
                                placeholder="Enter  Nutritional Assessment"
                              />

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="Code" className="form-label ">
                          Plan For the day
                        </label>
                        <div className="form-floating">
                          <div className=" dailyrescontainer_">
                            <div className="inputdailyresContainer_">
                              <textarea
                                type="text"
                                name="planofday"
                                className="form-control"
                                value={planOfDay}
                                onChange={handleChange}
                                placeholder="Enter  Nutritional Assessment"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="d-flex flex-row justify-content-end">
                      <button
                        type="button"
                        className="btn btn-save btn-save-fill btn-sm mb-1 me-1"
                        onClick={handleSave}
                      >
                        <img src={saveButtonIcon} className="icnn" />
                        Save
                      </button>

                      <button
                        type="button"
                        className="btn btn-clear btn-sm mb-1 me-1"
                        onClick={handleClear}
                      >
                        <img src={clearIcon} className="icnn" alt="" />
                        {t("Clear")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className="container-fluid_ mt-3 ">
        <Heading text="Daily Reassessment Sheet List " />
        <div className="med-table-section pt-2" style={{ height: "80vh" }}>
          <table className="med-table border_ striped" style={{ overflowX: 'auto', display: 'block' }}>
            <thead style={{ zIndex: "0" }}>
              <tr>
                <th className="text-center" style={{ width: "5%" }}>
                  #
                </th>
                <th>UHID</th>
                <th>ICU Admisssion</th>
                <th>Cardiac Assessment</th>
                <th>Replratory Assessment</th>
                <th>Neuro Assessment</th>
                <th>GI Assessment</th>
                <th>Renal Assessment</th>
                <th>Metabolic Assessment</th>
                {/* <th>Details</th>
                <th>Detailsdate</th> */}
                <th>Assessment Of Tubes Infections</th>
                {/* <th>Cross Referral</th> */}
                {/* <th>General Assessment</th> */}
                {/* <th>Investigations</th> */}
                {/* <th>Problems</th> */}
                <th>Nutritional Assessment</th>
                {/* <th>Plan Of Treatment</th> */}
                {/* <th>Patient Education Family Counselling</th> */}
                <th>Plan Of The Day</th>
                {/* <th>Problems Diagnosis</th> */}
                <th>Details Date</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((data, index) => {
                  return (
                    <tr key={index.toString() + "" + Math.random().toString()} style={{ color: '#000000' }} >
                      <td style={{ color: '#000000' }} className="text-center">{index + 1}</td>
                      <td style={{ color: '#000000' }}>{data.uhid}</td>
                      <td style={{ color: '#000000' }}>
                        {data.assessmentOfLineTubesInfectionsJson &&
                          data.assessmentOfLineTubesInfectionsJson !== "null"
                          ? JSON.parse(data.assessmentOfLineTubesInfectionsJson).map(
                            (val, index, array) => (
                              <span key={val.detailID}>
                                {val.details}
                                {index < array.length - 1 && ", "}
                              </span>
                            )
                          )
                          : "--"}
                      </td>
                      <td style={{ color: '#000000' }}>
                        {data.cardiacAssessmentJson && data.cardiacAssessmentJson !==
                          null
                          ? JSON.parse(data.cardiacAssessmentJson).map((val, index, array) => (
                            <span key={val.detailID}>
                              {val.details}
                              {index < array.length - 1 && ", "}
                            </span>
                          ))
                          : "--"}
                      </td>
                      {/* <td style={{ color: '#000000' }}>
                        {data.crossReferralJson && data.crossReferralJson !==
                          null
                          ? JSON.parse(data.crossReferralJson).map((val, index, array) => (
                            <span key={val.detailID}>
                              {val.details}
                              {index < array.length - 1 && ", "}
                            </span>
                          ))
                          : "--"}
                      </td> */}
                      {/* <td style={{ color: '#000000' }}>
                        {data.generalAssessmentJson && data.generalAssessmentJson !==
                          null
                          ? JSON.parse(data.generalAssessmentJson).map((val, index, array) => (
                            <span key={val.detailID}>
                              {val.details}
                              {index < array.length - 1 && ", "}
                            </span>
                          ))
                          : "--"}
                      </td> */}
                      <td style={{ color: '#000000' }}>
                        {data.giAssessmentJson && data.giAssessmentJson !==
                          null
                          ? JSON.parse(data.giAssessmentJson).map((val, index, array) => (
                            <span key={val.detailID}>
                              {val.details}
                              {index < array.length - 1 && ", "}
                            </span>
                          ))
                          : "--"}
                      </td>
                      <td style={{ color: '#000000' }}>
                        {data.icuAdmisssionJson && data.icuAdmisssionJson !==
                          null
                          ? JSON.parse(data.icuAdmisssionJson).map((val, index, array) => (
                            <span key={val.detailID}>
                              {val.details}
                              {index < array.length - 1 && ", "}
                            </span>
                          ))
                          : "--"}
                      </td>
                      {/* <td style={{ color: '#000000' }}>
                        {data.pertinentInvestigationReportOnAdmissionJson && data.pertinentInvestigationReportOnAdmissionJson !==
                          null
                          ? JSON.parse(data.pertinentInvestigationReportOnAdmissionJson).map((val, index, array) => (
                            <span key={val.detailID}>
                              {val.details}
                              {index < array.length - 1 && ", "}
                            </span>
                          ))
                          : "--"}
                      </td> */}
                      {/* <td style={{ color: '#000000' }}>
                        {data.problemnsJson && data.problemnsJson !==
                          null
                          ? JSON.parse(data.problemnsJson).map((val, index, array) => (
                            <span key={val.detailID}>
                              {val.details}
                              {index < array.length - 1 && ", "}
                            </span>
                          ))
                          : "--"}
                      </td> */}
                      <td style={{ color: '#000000' }}>
                        {data.repiratoryAssessmentJson && data.repiratoryAssessmentJson !==
                          null
                          ? JSON.parse(data.repiratoryAssessmentJson).map((val, index, array) => (
                            <span key={val.detailID}>
                              {val.details}
                              {index < array.length - 1 && ", "}
                            </span>
                          ))
                          : "--"}
                      </td>
                      <td style={{ color: '#000000' }}>
                        {data.neuroAssessmentJson && data.neuroAssessmentJson !==
                          null
                          ? JSON.parse(data.neuroAssessmentJson).map((val, index, array) => (
                            <span key={val.detailID}>
                              {val.details}
                              {index < array.length - 1 && ", "}
                            </span>
                          ))
                          : "--"}
                      </td>
                      <td style={{ color: '#000000' }}>
                        {data.renalAssessmentJson && data.renalAssessmentJson !==
                          null
                          ? JSON.parse(data.renalAssessmentJson).map((val, index, array) => (
                            <span key={val.detailID}>
                              {val.details}
                              {index < array.length - 1 && ", "}
                            </span>
                          ))
                          : "--"}
                      </td>
                      <td style={{ color: '#000000' }}>
                        {data.metabolicAssessmentJson && data.metabolicAssessmentJson !==
                          null
                          ? JSON.parse(data.metabolicAssessmentJson).map((val, index, array) => (
                            <span key={val.detailID}>
                              {val.details}
                              {index < array.length - 1 && ", "}
                            </span>
                          ))
                          : "--"}
                      </td>
                      <td style={{ color: '#000000' }}>
                        {data.nutritionalAssessmentJson && data.nutritionalAssessmentJson !==
                          null
                          ? JSON.parse(data.nutritionalAssessmentJson).map((val, index, array) => (
                            <span key={val.detailID}>
                              {val.details}
                              {index < array.length - 1 && ", "}
                            </span>
                          ))
                          : "--"}
                      </td>
                      {/* <td style={{ color: '#000000' }}>
                        {data.planOfTreatmentJson && data.planOfTreatmentJson !==
                          null
                          ? JSON.parse(data.planOfTreatmentJson).map((val, index, array) => (
                            <span key={val.detailID}>
                              {val.details}
                              {index < array.length - 1 && ", "}
                            </span>
                          ))
                          : "--"}
                      </td> */}
                      {/* <td style={{ color: '#000000' }}>
                        {data.patientEducationFamilyCounsellingAboutPrognosisJson && data.patientEducationFamilyCounsellingAboutPrognosisJson !==
                          null
                          ? JSON.parse(data.patientEducationFamilyCounsellingAboutPrognosisJson).map((val, index, array) => (
                            <span key={val.detailID}>
                              {val.details}
                              {index < array.length - 1 && ", "}
                            </span>
                          ))
                          : "--"}
                      </td> */}
                      <td style={{ color: '#000000' }}>
                        {data.planOfTheDayJson && data.planOfTheDayJson !==
                          null
                          ? JSON.parse(data.planOfTheDayJson).map((val, index, array) => (
                            <span key={val.detailID}>
                              {val.details}
                              {index < array.length - 1 && ", "}
                            </span>
                          ))
                          : "--"}
                      </td>

                      {/* <td style={{ color: '#000000' }}>
                        {data.diagnosis && data.diagnosis !==
                          null
                          ? JSON.parse(data.diagnosis).map((val, index, array) => (
                            <span key={val.detailID}>
                              {val.details}
                              {index < array.length - 1 && ", "}
                            </span>
                          ))
                          : "--"}
                      </td> */}
                      <td style={{ color: '#000000' }}>{data.createdDate}</td>

                      <td>
                        <div className="action-button">
                          <div>
                            <img
                              src={imgPrint}
                              alt=""
                              onClick={() => handleOnPrint(index)}
                              style={{ width: "19px" }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>



        {showAlertToster === 1 ? (
          <AlertToster message={showMessage} handle={setShowAlertToster} />
        ) : (
          ""
        )}
        {showSuccessToster === 1 ? (
          <SuccessToster
            message={showMessage}
            handle={setShowSuccessToster}
          />
        ) : (
          ""
        )}
      </div>

      <Loader val={loader} />
    </>
  );
}
