let UpdateClientMasterPackage = async (rowId,designation ,data)=>{
    let url= window.UserbaseUrl+`/api/Users/UpdateClient?Id=${rowId}&packageID=${designation}`;
    let head={'Content-Type':'application/json','accept':'*/*',}
    let response ="";
    await fetch(url,{
        method:'PUT',
        headers:head,
        body:JSON.stringify(data)
    }).then((res)=> res.json()).then(data=>{response=data}).catch(error=>{response=error});
    return response;
}
export default UpdateClientMasterPackage;