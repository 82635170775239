import React, { useEffect, useState } from 'react'
import LineVitalGrap from '../../../Components/LineVitalGrap';
import TimeCalculate from '../../../Code/TimeCalculate';
import GetAllDashboardParameterColumnAssign from '../../../Api/GetAllDashboardParameterColumnAssign';
import { useSelector } from 'react-redux';

export default function RBSR(props) {
  let [rbs, setRbs] = useState("");
  let [rbsId, setRbsId] = useState("");
  let [rbsTime, setRbsTime] = useState("");
  let [rbsColor, setRbsColor] = useState("");

  let [uhId, setUhId] = useState("")

  // set grap vital data
  let [grapVitalData, setGrapVitalData] = useState({
    showGrap: 0,
    vitalIdSearchNew: '',
    patientName: '',
    UHID: '',
    userId: ''
  })

  let getData = () => {
    setRbs("");
    setRbsId("");
    setRbsTime("");
    setRbsColor("");

    props.patientData.VitalParametersList && props.patientData.VitalParametersList.map((vital, ind) => {
      if (vital.VitalID.toString() === "10") { 
        setRbs(Math.round(vital.VitalValue * 100) / 100)
        setRbsColor(vital.VitalColor)
        if (props.handleColor !== undefined) {
          props.handleColor(vital.VitalColor !== null ? vital.VitalColor : "white", props.columnId)
      }

        setRbsId(vital.VitalID)
        setUhId(props.patientData.UhId)

        if (vital.VitalDateTime !== '') {
          setRbsTime(TimeCalculate(vital.VitalDateTime))
        }

      }
    })
  }

  let modelCloseFun = () => {
    setGrapVitalData({
      showGrap: 0,
      vitalIdSearchNew: '',
      UHID: '',
      userId: ''
    })
  }

  let [paramenterData, setPatameterData] = useState([])
  let response = useSelector((state) => state.AllColumnsParamters)
  let getColumnsParameters = () => {
    let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
       if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

  }

  useEffect(() => {
    getColumnsParameters()
    getData()
  }, [props.patientData])

  return (
    <>
      <div className={`overflow-max vitalClass ${(rbsColor !== "#008000" && rbsColor !== "#94e094" && rbsColor !== "#F5F8FF" && rbsColor !== "" ? "blink" : "")} `} style={{ border: (rbsColor !== "#008000" && rbsColor !== "#94e094" && rbsColor !== "#F5F8FF" && rbsColor !== "" ? "2px solid " + rbsColor : "") }}>
{

}
        {rbs.length !== 0 ? rbs ? <span>
          {
            paramenterData && paramenterData.map((val, ind) => {
              if (val.parameterId === 89) {
                return (
                  <>
                    <span style={{ color: rbsColor }}>{rbs}</span><br />


                  </>

                )
              }

              else if (val.parameterId === 90) {
                return (
                  <>
                    {
                      rbsTime.split("-")[1] === "D" || (rbsTime.split("-")[0].split(":")[0] >= 2 && rbsTime.split("-")[1] === "H") ? <span style={{ 'color': 'red', fontSize: "0.7rem" }} className="blink"><i className="bi bi-clock"></i> {rbsTime.toLowerCase().replace("-", "")}</span>

                        : (rbsTime.split(":")[0] >= 1 && rbsTime.split("-")[1] === "H") ? <span style={{ "color": 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {rbsTime.toLowerCase().replace("-", "")}</span>

                          : (rbsTime.split("-")[0].split(":")[0] <= 1 && rbsTime.split("-")[1] === "H") || (rbsTime.split("-")[0] >= 10 && rbsTime.split("-")[1] === "M") ? <span style={{ 'color': '#ffa500', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {rbsTime}</span> : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {rbsTime.toLowerCase().replace("-", "")}</span>}
                    <br />
                  </>

                )
              }

              else if (val.parameterId === 91) {
                return (
                  <>
                    <div className="" title='RBS Graph' ><i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapVitalData({ showGrap: 1, vitalIdSearchNew: [rbsId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "RBS Graph", patientName: props.patientData.PntName }) }}></i></div>

                  </>

                )
              }


            })

          }


        </span> : 'N/A' : "N/A"}
      </div>
      {grapVitalData.showGrap ? <LineVitalGrap grapVitalData={grapVitalData} modelCloseFun={modelCloseFun} /> : ""}

    </>
  )
}
