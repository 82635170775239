import React, { useEffect, useRef, useState } from 'react'
import TosterUnderProcess from '../../../Component/TosterUnderProcess'
import DropdownWithSearch from '../../../Component/DropdownWithSearch'
import Heading from '../../../Component/Heading'
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import { t } from 'i18next';
import Toster from '../../../Component/Toster';
import GetFoodList from '../../../Dietetics/API/FoodIntake/GetFoodList';
import GetUnitList from '../../../Dietetics/API/FoodIntake/GetUnitList';
import SaveFoodIntake from '../../../Dietetics/API/FoodIntake/SaveFoodIntake';

export default function RestrainIntake() {

    const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;


    let [showUnderProcess, setShowUnderProcess] = useState(0)
    let [showToster, setShowToster] = useState(0)
    let [tosterMessage, setTosterMessage] = useState("")
    let [tosterValue, setTosterValue] = useState(0)
    let [showLoder, setShowLoder] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [isShowToaster, setisShowToaster] = useState(0);
    let [clearDropdown, setClearDropdown] = useState(0)
    let [editFood, setEditFood] = useState("");
    let [editUnit, setEditUnit] = useState("");

    let [sendForm, setSendForm] = useState({
        "userId": window.userId,
        "clientId": window.clientId,
        "foodId": 0,
        "givenFoodQuantity": '',
        "givenFoodUnitID": 0,
        "givenFoodDate": new Date().toISOString().slice(0, 16),
        "isGiven": 1,
        "entryType": "N",
    })

    let [foodList, setFoodList] = useState();
    let [unitList, setUnitList] = useState();

    //Handle Change
    let handleChange = (e) => {
      

        setEditFood("");
        clearValidationErrMessage();
        let name = e.target.name;
        let value = e.target.value;
        if (name === "givenFoodQuantity" && value.length > 4) {
            value = value.slice(0, 4);
            document.getElementById('errQty').innerHTML = "maximum length should be up to 4 digits";
            document.getElementById('errQty').style.display = "block";
        } else {
            document.getElementById('errQty').style.display = "none";
        }
        setSendForm(sendForm => ({
            ...sendForm,
            [name]: value
        }));
    }

    //Get FoodList
    let getFood = async () => {
        let foodList = await GetFoodList();
        if (foodList.status === 1) {
            setFoodList(foodList.responseValue);
        }
    };
    const handleFoodChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        const foodId = e.target.value
        clearValidationErrMessage();
        getUnit(foodId)
        setSendForm(sendForm => ({
            ...sendForm,
            [name]: value
        }));
    };

    //GetUnitList
    let getUnit = async (value) => {
        let unitListResponse = await GetUnitList(value);
        if (unitListResponse.status === 1) {
            setUnitList(unitListResponse.responseValue);
            // console.log("unitList", unitListResponse.responseValue);
        }
    }

    const handlerSave = async () => {
        if (sendForm.foodId === '' || sendForm.foodId === 0 || sendForm.foodId === null || sendForm.foodId === undefined) {
            document.getElementById('errFood').innerHTML = "Select intake";
            document.getElementById('errFood').style.display = "block";
        }
        else if (sendForm.givenFoodQuantity === '' || sendForm.givenFoodQuantity === null || sendForm.givenFoodQuantity === undefined) {
            document.getElementById('errQty').innerHTML = "enter quantity";
            document.getElementById('errQty').style.display = "block";
        }

        else if (sendForm.givenFoodUnitID === '' || sendForm.givenFoodUnitID === 0 || sendForm.givenFoodUnitID === null || sendForm.givenFoodUnitID === undefined) {
            document.getElementById('errUnit').innerHTML = "select unit";
            document.getElementById('errUnit').style.display = "block";
        }

        else {
            setShowUnderProcess(1);
            const foodObj = {
                uhId: uhID,
                "foodId": sendForm.foodId,
                "givenFoodQuantity": sendForm.givenFoodQuantity,
                "givenFoodUnitID": sendForm.givenFoodUnitID,
                "givenFoodDate": sendForm.givenFoodDate,
                "clientId": clientID,
                "userId": window.userId,
                "isGiven": 1,
                "entryType": "N",
            }

            const response = await SaveFoodIntake(foodObj);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Data saved successfully..");
                setTimeout(() => {
                    setShowToster(0);
                    handleClear(1);
                }, 1500)
            }
            else {
                setShowUnderProcess(0);
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTimeout(() => {
                    setShowToster(0);
                }, 1500)
            }
        }
    }

    //Clear Error Message
    const clearValidationErrMessage = () => {
        document.getElementById('errFood').style.display = "none";
        document.getElementById('errQty').style.display = "none";
        document.getElementById('errUnit').style.display = "none";
    }
    //Handle Clear
    const handleClear = (value) => {
        setClearDropdown(value)
        clearValidationErrMessage();
        // setUpdateBool(0);
        setSendForm({
            "userId": window.userId,
            "clientId": window.clientId,
            "foodId": 0,
            "givenFoodQuantity": '',
            "givenFoodUnitID": 0,
            "givenFoodDate": new Date().toISOString().slice(0, 16)
        })
        setEditFood(0)
    }
    const handleDateChange = (e) => {
        setSendForm((sendForm) => ({
            ...sendForm,
            givenFoodDate: e.target.value,
        }));
    };
    useEffect(() => {
        getFood();
        setSendForm((sendForm) => ({
            ...sendForm,
            givenFoodDate: new Date().toISOString().slice(0, 16),
        }));
    }, [])

    return (
        <>
            {/* {/ -----------------------Start Forgot Password Modal Popup--------------------- /} */}

            <div className='modal fade' id="RestraintIntakeModal" aria-labelledby="RestraintIntakeModalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modalDelete w-100" style={{ margin: '3% auto' }}>
                    <div className="modal-content position-relative">
                        <div className="modal-header modalheading">
                            <h5 className="modal-title">Patient Intake</h5>
                            <i className="fa fa-times" data-bs-dismiss="modal" aria-label="Close"></i>
                        </div>

                        <div className="modal-body text-center1">
                            <div className='med-box1'>
                                <div className='inner-content1'>
                                    <div className='icuPhyInput mb-3  commnet-txt'>
                                        <label for="givenFoodDate" className="form-label"> Date/Time<span className="icustarMandatory"></span> </label>
                                        <input type="datetime-local" className="form-control form-control-sm" id="givenFoodDate" placeholder="Select Date and Time" name="givenFoodDate" value={sendForm.givenFoodDate} onChange={handleDateChange} />
                                    </div>
                                    <div className='icuPhyInput mb-3  commnet-txt'>
                                        <label for="foodId" className="form-label"> Food/Fluid<span className="icustarMandatory">*</span> </label>
                                        {foodList && <DropdownWithSearch defaulNname={t("Select Food")} name="foodId" list={foodList} valueName="id" displayName="foodName" editdata={editFood} getvalue={handleFoodChange} clear={clearDropdown} clearFun={handleClear} />}
                                        <small id="errFood" className="form-text text-danger" style={{ display: "none" }} ></small>
                                    </div>
                                    <div className='icuPhyInput mb-3  commnet-txt'>
                                        <label for="givenFoodQuantity" className="form-label"> Quantity<span className="icustarMandatory">*</span> </label>
                                        <input type="number" className="form-control form-control-sm" id="givenFoodQuantity" placeholder="Enter quantity" name="givenFoodQuantity" value={sendForm.givenFoodQuantity} onChange={handleChange} />
                                        <small id="errQty" className="form-text text-danger" style={{ display: "none" }} ></small>
                                    </div>
                                    <div className='icuPhyInput mb-3  commnet-txt'>
                                        <label for="givenFoodUnitID" className="form-label">Unit<span className="icustarMandatory">*</span> </label>
                                        <select className="form-select form-select-sm" aria-label=".form-select-sm example" id="givenFoodUnitID" name="givenFoodUnitID" value={sendForm.givenFoodUnitID} onChange={handleChange} >
                                            <option value="0">Select Unit</option>{" "}
                                            {unitList &&
                                                unitList.map((val, index) => {
                                                    return (
                                                        <option value={val.unitId}>{val.unitName}</option>
                                                    );
                                                })}
                                        </select>
                                        <small id="errUnit" className="form-text text-danger" style={{ display: "none" }} ></small>
                                    </div>
                                    <div className="relative mt-2 icubtns">
                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />
                                                    : <div>
                                                        <>
                                                            <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-2" onClick={handlerSave}><img src={saveButtonIcon} className='icnn' alt='' />{t("Save")}</button>
                                                            <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt='' />{t("Clear")}</button>
                                                        </>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>


                        </div>


                    </div>
                </div>
            </div>
            {/* {/ -----------------------End Forgot Password Modal Popup--------------------- /} */}


        </>
    )
}
