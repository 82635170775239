
async function GetDoctorScheduleList(clientID) {
    let token = 'bearer ' + window.AppToken;
    let url = window.AppbaseUrl + "/api/DoctorSchedule/GetDoctorSchedule?clientId="+clientID;
    let head = { "Content-Type": "application/JSON", accept: '*/*', 'Authorization': token };
    let data={};
    let response = await fetch(url, {
      headers: head,
      method: 'GET'
    })
      .then((res) => res.json(data))
      .then();
  
    return response;
  }
  export default GetDoctorScheduleList;
  
  
  