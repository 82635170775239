//import {React}from 'react'
import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts';
 
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting';

import { Link, json } from 'react-router-dom'
import "./css/bodysection.css"
import Navbar from '../../../../../../Component/Navbar'
import IPDSharePageSidebar from '../../IPDSharePageSidebar'


//import GetPatientPersonalDashboard from '../../../../../Api/IPD/PersonalDashboard/GetPatientPersonalDashboard'
//import BodyPart from '../../../../../Api/Bodypart/BodyPart'

import OffcanvasLogo from "../../../../../../assets/images/Navbar/offcanvas-logo.png"
import fullbody from './images/fullbody.png'
import fullbodyFemale from './images/fullbodyFemale.png'
import logo from './images/logo.png'
import avtar from './images/avtar.png'
import icu from './images/icu.png'
import bed from './images/bed.png'
import days from './images/days.png'
import monitor from './images/monitor.png'
import maximize from './images/maximize.png'
import g1 from './images/g1.png'
import bloodPressure from './images/blood-pressure.png'
import g2 from './images/g2.png'
import rr from './images/rr.png'
import g3 from './images/g3.png'
import pulse1 from './images/pulse1.png'
import g4 from './images/g4.png'
import co2 from './images/co2.png'
import g5 from './images/g5.png'
import investigation from './images/investigation.png'
import report from './images/report.png'
import red from './images/red.png'
import notes from './images/notes.png'
import ventilator from './images/ventilator.png'
import mask from './images/mask.png'
import glucose from './images/glucose.png'
import plaster from './images/plaster.png'
import p1 from './images/p1.png'
import bp from './images/bp.png'
import pulse from './images/pulse.png'
import yellow from './images/yellow.png'
import Ammonia from './images/Ammonia.png'
import rtfeed from './images/rtfeed.png'
import graph from './images/graph.png'
import exclamationMark1 from './images/exclamation-mark1.png'
import N from './images/N.png'
import F from './images/F.png'
import temperature from './images/temperature.png'
import Sugar from './images/Sugar.png'
import Lipid from './images/Lipid.png'
import Liver from './images/Liver.png'
import Thyroid from './images/Thyroid.png'
import xray from './images/xray.png'
import expand from './images/expand.png'
import kidney from './images/kidney.png'
import iconSuggestiveDiagnosis from './images/icon-Suggestive-Diagnosis.svg'
import iconDoctorDiagnosis from './images/icon-DoctorDiagnosis.svg'
import ImgMonitorDataVitals from './images/ImgMonitorDataVitals.png'
import ImgMonitorDataECG from './images/ImgMonitorDataECG.png'
import ecgGraphRunning from './images/ecgGraphRunning.gif'
import grphYellow from './images/grphYellow.gif'
import grphBlue from './images/grphBlue.gif'
import grphLightBlue from './images/grphLightBlue.gif'
import imgExperiment from './images/imgExperiment.png'
import stethoRectangleDiagnosisIcon from './images/stethoRectangleDiagnosis.png'
import iconMedication from './images/iconMedication.png'
import investigationBoxImg from './images/investigation-box-img.png'


import pulseVitalIcon from '../../../../../../../src/assets/images/icons/pulseVitalIcon.svg'
import bpSysDysVitalIcon from '../../../../../../../src/assets/images/icons/bpSysDysVitalIcon.svg'
import bloodPressureVitalIcon from '../../../../../../../src/assets/images/icons/bloodPressureVitalIcon.svg'
import tempratureVitalIcon from '../../../../../../../src/assets/images/icons/tempratureVitalIcon.svg'
import sketchVitalIcon from '../../../../../../../src/assets/images/icons/sketchVitalIcon.svg'
import ventilationVmIcon from '../../../../../../../src/assets/images/icons/ventilationVmIcon.svg'
import ventilationPeepIcon from '../../../../../../../src/assets/images/icons/ventilationPeepIcon.svg'
import ventilationFio2Icon from '../../../../../../../src/assets/images/icons/ventilationFio2Icon.svg'
import bloodPressureVantilationPAPIcon from '../../../../../../../src/assets/images/icons/bloodPressureVantilationPAPIcon.svg'
import NoDataFound from '../../../../../../../src/assets/images/icons/No data-rafiki.svg'

import GetTestResultListByUhid from '../../../../../API/IPD/personalDashboardIndexSecond/GetTestResultListByUhid'
import GetPatientIPDAllHistoryById from '../../../../../API/IPD/personalDashboardIndexSecond/GetPatientIPDAllHistoryById'
import GetPatientDetailsByUHID from '../../../../../API/IPD/personalDashboardIndexSecond/GetPatientDetailsByUHID'
import GetPatientLifeSupportAssign from '../../../../../API/IPD/personalDashboardIndexSecond/GetPatientLifeSupportAssign'
import { HubConnectionBuilder } from '@microsoft/signalr'
import ECGBodyPartsVirtualView from '../../../../../API/Bodypart/ECGBodyPartsVirtualView'

export default function PersonalDashboardIndexSecond(props) {
    // let [IPDHistoryList, setIPDHistoryList] = useState()
    // let [vitalList, setVitalList] = useState()
    // console.log('vitalList', vitalList)
    let [patientDetailsList, setPatientDetailsList] = useState()
    let [patientLifeSupportList, setPatientLifeSupportList] = useState()   
    let [virtualViewSignalrData, setVirtualViewSignalrData] = useState([])
    let [vitalParametersList, setVitalParametersList] = useState([])
    let [diagonsisList, setDiagonsisList] = useState([])  
    let [prescreptionParameterList, setprescreptionParameterList] = useState([])
    let [ecgMainData, setECGMainData] = useState([]);
    let [lifeSupportList, setLifeSupportList] = useState([]);
    let [investigationParameterList2, setInvestigationParameterList2] = useState([]);  
    let [patientIPDAllHistory, setPatientIPDAllHistory] = useState([]);  
    let [patientComplainHistory, setpatientComplainHistory] = useState([]);  
    let [diseaseWiseTestInvestigationList, setDiseaseWiseTestInvestigationList] = useState([]);  
    let [diseaseWiseTestVitalList, setDiseaseWiseTestVitalList] = useState([]);   
    let [provableDiseaseList, setProvableDiseaseList] = useState([]);  
   
    
// moon

    // console.log('diagonsisList', diagonsisList)
    // console.log('vitalParametersList', vitalParametersList)
    // console.log('investigationParameterList', investigationParameterList)
    // console.log('prescreptionParameterList', prescreptionParameterList)
    // console.log('ecgMainData', ecgMainData)
    // console.log('virtualViewSignalrData', virtualViewSignalrData)
    // { console.log("lifeSupportList ", lifeSupportList)}
    // { console.log("investigationParameterList2 ", investigationParameterList2)}
    // { console.log("vitalParametersList2 ", vitalParametersList2)}
    //  console.log("patientIPDAllHistory ", patientIPDAllHistory)
    //  console.log("patientComplainHistory ", patientComplainHistory)
    //  console.log("storevitalproblems ", storevitalproblems) 




    let uhID = JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid;    
    // let pmID = JSON.parse(window.sessionStorage.getItem("IPDpatientList")).pmId;   
    
    // let uhID = JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid;
    let IPDpatientList = JSON.parse(window.sessionStorage.getItem("IPDpatientList"));
    let deptId = IPDpatientList[0].deptId; 
    let clientData = JSON.parse(sessionStorage.getItem("LoginData"));    
    let clientId = clientData.clientId;   
    let userId = clientData.userId;  


  let functionGetECGBodyPartsVirtualView = async () =>{
    let response = await ECGBodyPartsVirtualView();  
      if(response.status === 1){
        setECGMainData(response.responseValue.ecgMainData) 
      }else{
        console.log('ecgMainData: ', response.message)
      }     
}


let functionVitalGraphData = async (data) =>{
    
 if(data !== null) { 

    let duration = 72;

    function sopo2(){
    let getSPO2Keys = data.filter((keys) => keys.vitalID === 56).map((keys) => { return keys.vitalID})
    // console.log('getSPO2Keys', getSPO2Keys);
    const options = {method: 'GET'};
    fetch(`${window.AppbaseUrl}/api/PatientVital/GetPatientVitalGraphByDuration?uhid=${uhID}&duration=${duration}&clientId=${clientId}&vitalIdSearchNew=${getSPO2Keys}`, options)    
    .then(response => response.json())    
    .then(response => {              
        if(response.status === 1){
            let spo2vitalValue = response.responseValue.patientGraph.map((keys) => {return keys.vitalDetails}).map((vitalDetailsString) => JSON.parse(vitalDetailsString)).flat();
            // console.log('spo2vitalValue', spo2vitalValue);
            let spo2vitalValueInOne = spo2vitalValue.map((keys) => keys.vitalValue)
            let spo2VitalDateTimeInOne  = spo2vitalValue.map((keys) => keys.vitalDateTime) 
               
        Highcharts.chart('graphSpO2', {            
            chart: {
                type: 'line'
            },
            title: {
                text: `Last ${duration} Hrs`
            },            
            xAxis: {
                categories: spo2VitalDateTimeInOne
            },
            yAxis: {
                title: {
                    text: 'SPO2 (%)'
                }
            },                     
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: false
                }
            },
            series: [{
                name: 'SPO2',
                data: spo2vitalValueInOne
            }, 
            // {
            //     name: 'SPO2Three',
            //     data: [90, 45, 85, 100]
            // }
        ]
        
                
        });  

        }else{
            console.log('spo2 Vital Message', response.message);  
        }   
       
    })
    .catch(err => console.error(err));
    }
    sopo2();
   // ##################### end of function sopo2


    function bloodPressure(){
    let getBPKeys = data.filter((keys) => keys.vitalID === 4 || keys.vitalID == 6).map((keys) => { return keys.vitalID})
    // console.log('getBPKeys', getBPKeys); 
    const options = {method: 'GET'};    
    fetch(`${window.AppbaseUrl}/api/PatientVital/GetPatientVitalGraphByDuration?uhid=${uhID}&duration=${duration}&clientId=${clientId}&vitalIdSearchNew=${getBPKeys}`, options)
    .then(response => response.json())    
    .then(response => {       
        // console.log('BPResponse', response);               
        if(response.status === 1){
            // let BPvitalValues = response.responseValue.patientGraph.map((keys) => {return keys.vitalDetails}).map((vitalDetailsString) => JSON.parse(vitalDetailsString)).flat();
            let BPvitalValues = response.responseValue.patientGraph.map((keys) => {return keys.vitalDetails}).map((vitalDetailsString) => JSON.parse(vitalDetailsString)).flat();
            // console.log('BPvitalValues', BPvitalValues);
            let bpSysVitalArraysOne = BPvitalValues.filter((keys) => keys.vitalid === 4)  
            let bpSysvitalValues = bpSysVitalArraysOne.map((keys) => keys.vitalValue)
            let bpSysvitalDateTime = bpSysVitalArraysOne.map((keys) => keys.vitalDateTime) 

            let bpDiaVitalArraysInOne = BPvitalValues.filter((keys) => keys.vitalid === 6).map((keys) => keys.vitalValue)
                   
        Highcharts.chart('graphBloodPressue', {            
            chart: {
                type: 'line'
            },
            title: {
                text: `Last ${duration} Hrs`
            },
            // subtitle: {
            //     text: 'Source: ' +
            //         '<a href="https://en.wikipedia.org/wiki/List_of_cities_by_average_temperature" ' +
            //         'target="_blank">Wikipedia.com</a>'
            // },

            xAxis: {
                categories: bpSysvitalDateTime
            },

           
            yAxis: {
                title: {
                    text: 'Blood Pressure (mmHg)'
                }
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: false
                }
            },

            // tooltip: {
            //     headerFormat: '<b>{series.name}</b><br />',
            //     pointFormat: 'x = {point.x}, y = {point.y}'
            // },

            series: [{
                name: 'BP Sys',
                data: bpSysvitalValues
            }, 
            {
                name: 'BP Dias',
                data: bpDiaVitalArraysInOne
            }
        ]        
        }); 

        }else{
            console.log('BP Vital Message', response.message);  
        }  
        
    }).catch(err => console.error(err)); 
    }
    bloodPressure();
    // ##################### end of function bloodPressure 

    
    function heartRate(){
        let getHRKeys = data.filter((keys) => keys.vitalID === 74).map((keys) => { return keys.vitalID})
        // console.log('getBPKeys', getBPKeys); 
        const options = {method: 'GET'};    
        fetch(`${window.AppbaseUrl}/api/PatientVital/GetPatientVitalGraphByDuration?uhid=${uhID}&duration=${duration}&clientId=${clientId}&vitalIdSearchNew=${getHRKeys}`, options)
        .then(response => response.json())    
        .then(response => {       
            // console.log('HRResponse', response);               
            if(response.status === 1){
                // let BPvitalValues = response.responseValue.patientGraph.map((keys) => {return keys.vitalDetails}).map((vitalDetailsString) => JSON.parse(vitalDetailsString)).flat();
                let HRvitalValuesArrayInOne = response.responseValue.patientGraph.map((keys) => {return keys.vitalDetails}).map((vitalDetailsString) => JSON.parse(vitalDetailsString)).flat();
                // console.log('HRvitalValuesArrayInOne', HRvitalValuesArrayInOne);
                // let bpSysVitalArraysOne = BPvitalValues.filter((keys) => keys.vitalid === 4)   
                let hrvitalValues = HRvitalValuesArrayInOne.map((keys) => keys.vitalValue)
                let hrvitalDateTime = HRvitalValuesArrayInOne.map((keys) => keys.vitalDateTime) 
                // console.log('hrvitalValues:', hrvitalValues , 'hrvitalDateTime:', hrvitalDateTime)   
                
                      
            Highcharts.chart('graphHeartRate', {            
                chart: {
                    type: 'line'
                },
                title: {
                    text: `Last ${duration} Hrs`
                },                
    
                xAxis: {
                    categories: hrvitalDateTime
                }, 

                yAxis: {
                    title: {
                        text: 'Heart Rate (BPM)'
                            }
                        },
                
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: true
                        },
                        enableMouseTracking: false
                    }
                }, 
    
                series: [{
                    name: 'Heart Rate',
                    data: hrvitalValues
                }, 
                
            ]        
            }); 
    
            }else{
                console.log('HR Vital Message', response.message);  
            }  
            
        }).catch(err => console.error(err)); 
        }
        heartRate();
        // ##################### end of function heartRate 

        
    function pulseRate(){
        let getPRKeys = data.filter((keys) => keys.vitalID === 3).map((keys) => { return keys.vitalID})
        // console.log('getBPKeys', getBPKeys); 
        const options = {method: 'GET'};    
        fetch(`${window.AppbaseUrl}/api/PatientVital/GetPatientVitalGraphByDuration?uhid=${uhID}&duration=${duration}&clientId=${clientId}&vitalIdSearchNew=${getPRKeys}`, options)
        .then(response => response.json())    
        .then(response => {       
            // console.log('PRResponse', response);               
            if(response.status === 1){                
                let PRvitalValuesArrayInOne = response.responseValue.patientGraph.map((keys) => {return keys.vitalDetails}).map((vitalDetailsString) => JSON.parse(vitalDetailsString)).flat();
                // console.log('PRvitalValuesArrayInOne', PRvitalValuesArrayInOne);                  
                let prvitalValues = PRvitalValuesArrayInOne.map((keys) => keys.vitalValue)
                let prvitalDateTime = PRvitalValuesArrayInOne.map((keys) => keys.vitalDateTime) 
                // console.log('prvitalValues:', prvitalValues)   
                // console.log('prvitalDateTime:', prvitalDateTime)   
                
                      
            Highcharts.chart('graphPulseRate', {            
                chart: {
                    type: 'line'
                },
                title: {
                    text: `Last ${duration} Hrs`
                },                
    
                xAxis: {
                    categories: prvitalDateTime
                }, 

                yAxis: {
                    title: {
                        text: 'Pulse Rate (BPM)'
                            }
                        },
                
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: true
                        },
                        enableMouseTracking: false
                    }
                }, 
    
                series: [{
                    name: 'Pulse Rate',
                    data: prvitalValues
                }, 
                
            ]        
            }); 
    
            }else{
                console.log('Pulse Rate Vital Message', response.message);  
            }  
            
        }).catch(err => console.error(err)); 
        }
        pulseRate();
        // ##################### end of function pulseRate

        
    function respiratoryRate(){
        let getRRKeys = data.filter((keys) => keys.vitalID === 7).map((keys) => { return keys.vitalID})
        // console.log('getBPKeys', getBPKeys); 
        const options = {method: 'GET'};    
        fetch(`${window.AppbaseUrl}/api/PatientVital/GetPatientVitalGraphByDuration?uhid=${uhID}&duration=${duration}&clientId=${clientId}&vitalIdSearchNew=${getRRKeys}`, options)
        .then(response => response.json())    
        .then(response => {       
            // console.log('PRResponse', response);               
            if(response.status === 1){                
                let RRvitalValuesArrayInOne = response.responseValue.patientGraph.map((keys) => {return keys.vitalDetails}).map((vitalDetailsString) => JSON.parse(vitalDetailsString)).flat();
                // console.log('RRvitalValuesArrayInOne', RRvitalValuesArrayInOne);                  
                let rrvitalValues = RRvitalValuesArrayInOne.map((keys) => keys.vitalValue)
                let rrvitalDateTime = RRvitalValuesArrayInOne.map((keys) => keys.vitalDateTime) 
                // console.log('rrvitalValues:', rrvitalValues)   
                // console.log('rrvitalDateTime:', rrvitalDateTime)   
                
                      
            Highcharts.chart('graphRespiratoryRate', {            
                chart: {
                    type: 'line'
                },
                title: {
                    text: `Last ${duration} Hrs`
                },                
    
                xAxis: {
                    categories: rrvitalDateTime
                }, 

                yAxis: {
                    title: {
                        text: 'Respiratory Rate (/m)'
                            }
                        },
                
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: true
                        },
                        enableMouseTracking: false
                    }
                }, 
    
                series: [{
                    name: 'Respiratory Rate',
                    data: rrvitalValues
                }, 
                
            ]        
            }); 
    
            }else{
                console.log('Respiratory Rate Vital Message', response.message);  
            }  
            
        }).catch(err => console.error(err)); 
        }
        respiratoryRate();
        // ##################### end of function respiratoryRate


        // moon
    function tempRate(){
        let getTempKeys = data.filter((keys) => keys.vitalID === 5).map((keys) => { return keys.vitalID})
        // console.log('getBPKeys', getBPKeys); 
        const options = {method: 'GET'};    
        fetch(`${window.AppbaseUrl}/api/PatientVital/GetPatientVitalGraphByDuration?uhid=${uhID}&duration=${duration}&clientId=${clientId}&vitalIdSearchNew=${getTempKeys}`, options)
        .then(response => response.json())    
        .then(response => {       
            // console.log('PRResponse', response);               
            if(response.status === 1){                
                let TempvitalValuesArrayInOne = response.responseValue.patientGraph.map((keys) => {return keys.vitalDetails}).map((vitalDetailsString) => JSON.parse(vitalDetailsString)).flat();
                // console.log('TempvitalValuesArrayInOne', TempvitalValuesArrayInOne);                  
                let TempvitalValues = TempvitalValuesArrayInOne.map((keys) => keys.vitalValue)
                let TempvitalDateTime = TempvitalValuesArrayInOne.map((keys) => keys.vitalDateTime) 
                // console.log('TempvitalValues:', TempvitalValues)   
                // console.log('TempvitalDateTime:', TempvitalDateTime)   
                
                      
            Highcharts.chart('graphTemperature', {            
                chart: {
                    type: 'line'
                },
                title: {
                    text: `Last ${duration} Hrs`
                },                
    
                xAxis: {
                    categories: TempvitalDateTime
                }, 

                 yAxis: {
                title: {
                    text: 'Temperature (°F)'
                        }
                    },
                
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: true
                        },
                        enableMouseTracking: false
                    }
                }, 
    
                series: [{
                    name: 'Temperature Rate',
                    data: TempvitalValues
                }, 
                
            ]        
            }); 
    
            }else{
                console.log('Temperature Vital Message', response.message);  
            }  
            
        }).catch(err => console.error(err)); 
        }
        tempRate();
        // ##################### end of function tempRate     

}
}



    let functionGetpatientHistory = async () => {  
    const options = {method: 'GET'};
    fetch(`${window.AppbaseUrl}/api/PatientIPDPrescription/PatientIPDAllHistory?UhId=${uhID}&DeptId=${deptId}&UserId=${userId}&ClientId=${clientId}`, options)
    .then(response => response.json())    
    .then(response => {        
        setpatientComplainHistory(response.responseValue.patientComplainHistory);
    })
    .catch(err => console.error(err));         
    }     

    
    let functionGetVitalWiseProblem = async (data) => { 
        // console.log('functionGetVitalWiseProblem', data);
        if(data.vitalParametersList !== null)
        {
            // let getVitlaIdByVitalScore = virtualViewSignalrData.vitalParametersList && virtualViewSignalrData.vitalParametersList.filter((key) => key.vitalScore > 0).map((key) => key.vitalID)
            let getVitlaIdByVitalScore = data.vitalParametersList && data.vitalParametersList.filter((key) => key.vitalScore > 0).map((key) => key.vitalID)
            let getSubTestIDBySubTestScore = data.investigationParameterList &&  data.investigationParameterList.filter((key) => key.subTestScore > 0).map((key) => key.subTestID)        
            
                let options = {method: 'GET'};  
              await fetch(`${window.AppbaseUrl}/api/KnowMedApis/getvitalWiseProblem?vitalID=${getVitlaIdByVitalScore}&investigationID=${getSubTestIDBySubTestScore}`, options)
                .then(response => response.json())               
                .then(response => {            
                   
                    if(response.status === 1)   {
                        let storevitalproblemsInAvar = (response.responseValue[0].vitalList).map((key) => key.vitalproblems);                     
                        let storeStringVitalproblems = storevitalproblemsInAvar.join(',');  

                        // getdiseaseWisetest ########################################                       
                        let options = {method: 'GET'};
                        fetch(`${window.AppbaseUrl}/api/KnowMedApis/getdiseaseWisetest?diseaseID=${storeStringVitalproblems}&age=${data.pntAge}`, options)
                        .then(response => response.json())                       
                        .then(response => {                           
                            // console.log('getdiseaseWisetest', response)                                                       
                            setDiseaseWiseTestInvestigationList(response.responseValue[0].investigationList);
                            setDiseaseWiseTestVitalList(response.responseValue[0].vitalList);
                        }).catch(err => console.error(err));
                        
                        // ProvableDiseaseList ########################################                        
                        let provableMethod = {method: 'POST'};
                        fetch(`${window.AppbaseUrl}/api/KnowMedApis/ProvableDiseaseList?Age=${data.pntAge}&Gender=${data.pntGender}&UserId=${userId}&SuggestedProblemId=${storeStringVitalproblems}`, provableMethod)
                        .then(response => response.json())                       
                        .then(response => {                                                      
                            // console.log('ProvableDiseaseList', response)                             
                            setProvableDiseaseList(response.responseValue);
                        }).catch(err => console.error(err));


                    } else {
                        console.log('getvitalWiseProblem:', response.message)
                    }                         
                         
                }).catch(err => console.error(err));  
        
                }   
        } 
        


    useEffect(() => {        
        functionGetpatientHistory();
        functionGetECGBodyPartsVirtualView(); 
        
        
        try {            
            // const connection = new HubConnectionBuilder().withUrl("http://172.16.61.31:7085/PatientDashboard").withAutomaticReconnect().build();
            const connection = new HubConnectionBuilder().withUrl(`${window.PatientMonitorDashboard}/PatientDashboard`).withAutomaticReconnect().build();            
            connection.start().then((result) => {               
                let clientData = JSON.parse(sessionStorage.getItem("LoginData"));
                let clientId = clientData.clientId;
                let userId = clientData.userId;

                // console.log('clientId:', clientId, 'userId:', userId, 'uhID:', uhID);
                // uhID
                // let staticUHID = UHID00181;
                connection.invoke("GetPatientDetailsByUHID", clientId, userId, uhID).then(function (response) {  
                    if(response.status === 1){
                        console.log("GetPatientDetailsByUHID ", response);
                        setVirtualViewSignalrData(response.responseValue)  
                        setDiagonsisList(response.responseValue.diagonsisList)                        
                        setprescreptionParameterList(response.responseValue.prescreptionParameterListMedvantage)                      
                      
                        functionGetVitalWiseProblem(response.responseValue);
                        functionVitalGraphData(response.responseValue.vitalParametersList)

                        // Start vitalParametersList monitor data  ################################## 
                        let vitalParametersList = response.responseValue.vitalParametersList;
                        const vitalID = [56, 4, 6, 74, 3, 7, 10, 5];
                        // let vitalIdString = "";
                        let tempArrVitalList = []; 
                        for(let i = 0; i < vitalID.length; i++)
                        {
                            vitalParametersList && vitalParametersList.forEach((vitalKey)=>{
                                
                                if(vitalID[i] === vitalKey.vitalID) {                                    
                                    tempArrVitalList.push({
                                        ['vitalName_'+vitalKey.vitalID]: vitalKey.vitalName,
                                        ['vitalValue_'+vitalKey.vitalID]: vitalKey.vitalValue,
                                        ['vitalColor_'+vitalKey.vitalID]: vitalKey.vitalColor
                                    })
                                }
                            })
                        }  
                                          
                        let mergetempArrVitalList = Object.assign({}, ...tempArrVitalList);                                                 
                          setVitalParametersList(mergetempArrVitalList); 
                        //End Get vitalParametersList monitor data ##################################



                        // Start lifeSupportList data  ##############################################
                        let getLifeSupportList = response.responseValue.lifeSupportList;                        
                        const arraylifeSupportListvmId = [98, 179];
                        let templifeSupportList = [];
                        for (let i = 0; i < arraylifeSupportListvmId.length; i++) {
                            getLifeSupportList && getLifeSupportList.forEach((lifeKeys) => {
                                if(arraylifeSupportListvmId[i] === lifeKeys.vmId) {
                                    templifeSupportList.push({
                                        ['id_'+lifeKeys.vmId ]: lifeKeys.vmValue,                                       
                                        ['name_'+lifeKeys.vmId]: lifeKeys.ventiModeName                                        
                                    })
                                }
                            })                            
                        }                       
                        let mergeTempLifeSupportList = Object.assign({}, ...templifeSupportList);
                        setLifeSupportList(mergeTempLifeSupportList)
                        // End lifeSupportList data  ################################################
  
                                            
                         
                        // Start investigationParameterList data  ################################
                        let getInvestigationParameterList = response.responseValue.investigationParameterList;                        
                        const arrayInvestigationSubTestID = [610, 611, 250, 612, 206, 607, 608, 609, 614, 496, 497, 1430, 490, 644, 645, 646, 741, 206, 744, 613, 189];
                        // let investigationString = "";
                        let tempInvestigationParameterList = [];
                        for (let i = 0; i < arrayInvestigationSubTestID.length; i++) {
                            getInvestigationParameterList && getInvestigationParameterList.forEach((InvestigationKeys) => {
                                if(arrayInvestigationSubTestID[i] === InvestigationKeys.subTestID) {                                    
                                    tempInvestigationParameterList.push({
                                        ['subTestValue_'+InvestigationKeys.subTestID]: InvestigationKeys.subTestValue,                                       
                                        ['subTestName_'+InvestigationKeys.subTestID]: InvestigationKeys.subTestName,                                        
                                        ['subTestColor_'+InvestigationKeys.subTestID]: InvestigationKeys.subTestColor                                        
                                    })
                                }
                            })                            
                        }               
                        let mergetempInvestigationParameterList = Object.assign({}, ...tempInvestigationParameterList);
                        setInvestigationParameterList2(mergetempInvestigationParameterList)

                        // End investigationParameterList data  ##################################

                        // functionGetVitalWiseProblem();
                    }
                    else {
                        console.log('GetPatientDetailsByUHID', response.message)                                          
                    } 
                })
            })

            
        }
        catch (e) {
            console.log("error", e.message)
        } 

       

    }, []) 



    return (
        <>
            {/* <Navbar />
            <IPDSharePageSidebar /> */}           

                    <div className="cont">
                        <div className="col1_ flex1_">
                            <div className="left-sec_">
                                <div className='vitalMoniterDataSection'>
                                    <img src={ImgMonitorDataVitals} alt="ImgMonitorDataVitals" className='imgMonitor'/>
                                    <div className='vitalMoniterData'>  

                                        <div className="vitalMoniterDataItems repeat">
                                            <div><img src={grphYellow} className="imgGraph" /></div>  
                                            <div style={{color:'white'}} className='textDataBox pointer' title='SPO2 in Graph' data-bs-toggle="modal" data-bs-target="#openSPO2GraphModal">
                                                <div className='vitalNameText'>SPO2</div>
                                                <div className='vitalValText'>                                                    
                                                    <span title="SPO2" style={{color: vitalParametersList.vitalColor_56 != null ? vitalParametersList.vitalColor_56 : 'white'}}>{vitalParametersList.vitalValue_56 != null ? vitalParametersList.vitalValue_56 : '-' }</span>
                                                    </div>
                                            </div>
                                        </div>                                      
                                        
                                        <div className="vitalMoniterDataItems repeat">
                                            <div><img src={grphYellow} className="imgGraph" /></div>
                                            <div style={{color:'white'}} className='textDataBox pointer' title='BP Sys / BP Dias in Graph' data-bs-toggle="modal" data-bs-target="#openBPGraphModal">
                                                <div className='vitalNameText'>BP</div>
                                                <div className='vitalValText slashData'>                                                      
                                                   <span title="BP Sys" style={{color: vitalParametersList.vitalColor_4 != null ? vitalParametersList.vitalColor_4 : 'white'}}>{vitalParametersList.vitalValue_4 != null ? vitalParametersList.vitalValue_4 : '-' }</span>                                                 
                                                    <span className='slash'>/</span>                                                   
                                                    <span title="BP Dias" style={{color: vitalParametersList.vitalColor_6 != null ? vitalParametersList.vitalColor_6 : 'white'}}>{vitalParametersList.vitalValue_6 != null ? vitalParametersList.vitalValue_6 : '-' }</span>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="vitalMoniterDataItems repeat">
                                            <div><img src={g1} className="imgGraph" /></div>
                                            <div style={{color:'white'}} className='textDataBox' title='Heart Rate'>
                                                <div className='vitalNameText'>HR</div>
                                                <div className='vitalValText'>
                                                <span style={{color: vitalParametersList.vitalColor_74 != null ? vitalParametersList.vitalColor_74 : 'white'}}>{vitalParametersList.vitalValue_74 != null ? vitalParametersList.vitalValue_74 : '-' }</span>

                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="vitalMoniterDataItems repeat">
                                            <div><img src={grphLightBlue} className="imgGraph" /></div>
                                            <div className="textDataBoxWrapper">

                                           
                                            <div style={{color:'white'}} className='textDataBox pointer' title='Heart Rate in Graph' data-bs-toggle="modal" data-bs-target="#openHRGraphModal">
                                                <div className='vitalNameText'>HR</div>
                                                <div className='vitalValText'>
                                                <span title="Heart Rate" style={{color: vitalParametersList.vitalColor_74 != null ? vitalParametersList.vitalColor_74 : 'white'}}>{vitalParametersList.vitalValue_74 != null ? vitalParametersList.vitalValue_74 : '-' }</span>

                                                </div>
                                            </div>

                                          
                                            <div style={{color:'white'}} className='textDataBox pointer' title='Pulse Rate in Graph' data-bs-toggle="modal" data-bs-target="#openPRGraphModal">
                                                <div className='vitalNameText'>PR</div>
                                                <div className='vitalValText'>                                                    
                                                    <span title='Pulse Rate' style={{color: vitalParametersList.vitalColor_3 != null ? vitalParametersList.vitalColor_3 : 'white'}}>{vitalParametersList.vitalValue_3 != null ? vitalParametersList.vitalValue_3 : '-' }</span>

                                                </div>
                                            </div>

                                        </div>                                            
                                        </div>

                                       
                                        <div className="vitalMoniterDataItems repeat">
                                        <div><img src={grphBlue} className="imgGraph" /></div>
                                        <div className="textDataBoxWrapper">

                                            <div style={{color:'white'}} className='textDataBox pointer' title='Respiratory Rate in Graph' data-bs-toggle="modal" data-bs-target="#openRRGraphModal">
                                                <div className='vitalNameText'>RR </div>
                                                <div className='vitalValText'>                                                    
                                                <span title='Respiratory Rate' style={{color: vitalParametersList.vitalColor_7 != null ? vitalParametersList.vitalColor_7 : 'white'}}>{vitalParametersList.vitalValue_7 != null ? vitalParametersList.vitalValue_7 : '-' }</span>

                                                </div>
                                            </div>

                                            {/* moon */}
                                            <div style={{color:'white'}} className='textDataBox pointer' title='Temperature in Graph' data-bs-toggle="modal" data-bs-target="#openTemperatureGraphModal">
                                                <div className='vitalNameText'>Temp</div>
                                                <div className='vitalValText'>                                                   
                                                <span title='Temperature' style={{color: vitalParametersList.vitalColor_5 != null ? vitalParametersList.vitalColor_5 : 'white'}}>{vitalParametersList.vitalValue_5 != null ? vitalParametersList.vitalValue_5 : '-' }</span>
                                                </div>
                                            </div>
                                        </div> 
                                        </div>
                                        <div className='ecgGraphWrapperRunning'>
                                            {/* <img src={g1} className="imgGraph" /> */}
                                            <img src={ecgGraphRunning} className="imgGraph" />
                                            </div>
                                        <div className='ecgGraphTextWrapper'>                                            
                                            {ecgMainData[0] != null ? 
                                            <>
                                            <div className='ecgGraphTextItems'>
                                                <div className='ecgGraphTextTitle'>RR Interval</div>
                                                <div className='ecgGraphTextValue' title='RR Interval'>{ecgMainData[0].globalRRInterval}</div>
                                            </div>

                                            <div className='ecgGraphTextItems'>
                                                <div className='ecgGraphTextTitle'>PR Interval</div>
                                                <div className='ecgGraphTextValue' title='PR Interval'>{ecgMainData[0].globalPRInterval}</div>
                                            </div>   

                                            <div className='ecgGraphTextItems'>
                                                <div className='ecgGraphTextTitle'>QRS Count</div>
                                                <div className='ecgGraphTextValue'><span title='QRS Count'>{ecgMainData[0].globalQRSCount} </span> / <span  title='QRS Duration'>{ecgMainData[0].globalQRSDuration} </span> </div>
                                            </div>      

                                            <div className='ecgGraphTextItems'>
                                                <div className='ecgGraphTextTitle'>QT Interval</div>
                                                <div className='ecgGraphTextValue'> <span  title='QT Interval'>{ecgMainData[0].globalQTInterval} </span> / <span title='QT Corrected'>{ecgMainData[0].globalQTCorrected} </span> </div>
                                            </div>    

                                            <div className='ecgGraphTextItems'>
                                                <div className='ecgGraphTextTitle'>P Duration</div>
                                                <div className='ecgGraphTextValue'><span title='P Duration'>{ecgMainData[0].globalPDuration} </span></div>
                                            </div>                                           
                                            
                                            </> 
                                            :                                             
                                            <div className='ecg-data-notfound'>ECG data not found.</div>
                                            } 

                                        </div>

                                        {/* <div className="someVitalAndECGWrapper">
                                            <div className='vitalHRBox' title='Heart Rate'>
                                                <div className='TextTitle'>HR</div>
                                                <div className='TextValue'>
                                                <span style={{color: vitalParametersList.vitalColor_74 != null ? vitalParametersList.vitalColor_74 : 'white'}}>{vitalParametersList.vitalValue_74 != null ? vitalParametersList.vitalValue_74 : '-' }</span>

                                                </div>
                                            </div>

                                            <div className='ecgGraphTextWrapper'>                                            
                                            {ecgMainData[0] != null ? 
                                            <>                                          

                                            <div className='ecgGraphTextItems'>
                                                <div className='ecgGraphTextTitle'>PR</div>
                                                <div className='ecgGraphTextValue' title='PR Interval'>{ecgMainData[0].globalPRInterval}</div>
                                            </div>   

                                            <div className='ecgGraphTextItems'>
                                                <div className='ecgGraphTextTitle'>QRS</div>
                                                <div className='ecgGraphTextValue'><span title='QRS Count'>{ecgMainData[0].globalQRSCount} </span> / <span  title='QRS Duration'>{ecgMainData[0].globalQRSDuration} </span> </div>
                                            </div>      

                                            <div className='ecgGraphTextItems'>
                                                <div className='ecgGraphTextTitle'>QT</div>
                                                <div className='ecgGraphTextValue'><span title='QT Corrected'>{ecgMainData[0].globalQTCorrected} </span> / <span  title='QT Interval'>{ecgMainData[0].globalQTInterval} </span> </div>
                                            </div>    

                                            <div className='ecgGraphTextItems'>
                                                <div className='ecgGraphTextTitle'>Duration</div>
                                                <div className='ecgGraphTextValue'><span title='Duration'>{ecgMainData[0].globalPDuration} </span></div>
                                            </div>                                           
                                            
                                            </> 
                                            :                                             
                                            <div className='ecg-data-notfound'>ECG data not found.</div>
                                            } 

                                        </div>
                                     
                                        </div> */}

                                        
                                    </div>
                                </div>

                                {/* <div className='ecgMoniterDataSection mt-1'>
                                    <img src={ImgMonitorDataECG} alt="ImgMonitorDataECG" className='imgMonitor'/>
                                    <div className='ecgMoniterData'>  

                                        <div className='ecgGraphWrapper'><img src={g1} className="imgGraph" /></div>
                                        <div className='ecgGraphTextWrapper'>                                            
                                            {ecgMainData[0] != null ? 
                                            <>
                                            <div className='ecgGraphTextItems'>
                                                <div className='ecgGraphTextTitle'>RR</div>
                                                <div className='ecgGraphTextValue' title='RR Interval'>{ecgMainData[0].globalRRInterval}</div>
                                            </div>

                                            <div className='ecgGraphTextItems'>
                                                <div className='ecgGraphTextTitle'>PR</div>
                                                <div className='ecgGraphTextValue' title='PR Interval'>{ecgMainData[0].globalPRInterval}</div>
                                            </div>   

                                            <div className='ecgGraphTextItems'>
                                                <div className='ecgGraphTextTitle'>QRS</div>
                                                <div className='ecgGraphTextValue'><span title='QRS Count'>{ecgMainData[0].globalQRSCount} </span> / <span  title='QRS Duration'>{ecgMainData[0].globalQRSDuration} </span> </div>
                                            </div>      

                                            <div className='ecgGraphTextItems'>
                                                <div className='ecgGraphTextTitle'>QT</div>
                                                <div className='ecgGraphTextValue'><span title='QT Corrected'>{ecgMainData[0].globalQTCorrected} </span> / <span  title='QT Interval'>{ecgMainData[0].globalQTInterval} </span> </div>
                                            </div>    

                                            <div className='ecgGraphTextItems'>
                                                <div className='ecgGraphTextTitle'>Duration</div>
                                                <div className='ecgGraphTextValue'><span title='Duration'>{ecgMainData[0].globalPDuration} </span></div>
                                            </div>                                           
                                            
                                            </> 
                                            :                                             
                                            <div className='ecg-data-notfound'>Data not found.</div>
                                            } 

                                        </div>
                                        
                                    </div>
                                </div> */}

                                <div className="body-platelates-table-section mt-2" style={{width:'448px'}}>                               
                                 <table>
                                    <tr>
                                    <td>Na+ <span style={{color: investigationParameterList2.subTestColor_610 != null ? investigationParameterList2.subTestColor_610 : ''}}>{investigationParameterList2.subTestValue_610 != null ? investigationParameterList2.subTestValue_610 : '-'}</span></td>
                                    <td>K+ <span style={{color: investigationParameterList2.subTestColor_611 != null ? investigationParameterList2.subTestColor_611 : ''}}>{investigationParameterList2.subTestValue_611 != null ? investigationParameterList2.subTestValue_611 : '-'}</span></td>
                                    <td>Mg <span style={{color: investigationParameterList2.subTestColor_250 != null ? investigationParameterList2.subTestColor_250 : ''}}>{investigationParameterList2.subTestValue_250 != null ? investigationParameterList2.subTestValue_250 : '-'}</span></td> 
                                    <td>Ca++ <span style={{color: investigationParameterList2.subTestColor_612 != null ? investigationParameterList2.subTestColor_612 : ''}}>{investigationParameterList2.subTestValue_612 != null ? investigationParameterList2.subTestValue_612 : '-'}</span></td>                               
                                    </tr>
                                 </table>                                
                                </div>

                                <div className="body-platelates-table-section mt-2" style={{width:'448px'}}>                               
                                 <table>
                                    <tr>
                                    <td>ALB <span style={{color: investigationParameterList2.subTestColor_206 != null ? investigationParameterList2.subTestColor_206 : ''}}>{investigationParameterList2.subTestValue_206 != null ? investigationParameterList2.subTestValue_206 : '-'}</span></td>
                                    <td>Ph <span style={{color: investigationParameterList2.subTestColor_607 != null ? investigationParameterList2.subTestColor_607 : ''}}>{investigationParameterList2.subTestValue_607 != null ? investigationParameterList2.subTestValue_607 : '-'}</span></td>
                                    <td>PCO2 <span style={{color: investigationParameterList2.subTestColor_608 != null ? investigationParameterList2.subTestColor_608 : ''}}>{investigationParameterList2.subTestValue_608 != null ? investigationParameterList2.subTestValue_608 : '-'}</span></td> 
                                    <td>PO2 <span style={{color: investigationParameterList2.subTestColor_609 != null ? investigationParameterList2.subTestColor_609 : ''}}>{investigationParameterList2.subTestValue_609 != null ? investigationParameterList2.subTestValue_609 : '-'}</span></td>
                                    <td>Lactate <span style={{color: investigationParameterList2.subTestColor_614 != null ? investigationParameterList2.subTestColor_614 : ''}}>{investigationParameterList2.subTestValue_614 != null ? investigationParameterList2.subTestValue_614 : '-'}</span></td>                                
                                    </tr>
                                 </table>                                
                                </div> 

                            </div>

                            <div className="investigation-box-wrapper" style={{display:'none'}}>
                                <div className="investigation-box">
                                    <img src={investigationBoxImg} alt="" />
                                    <div className="content"></div>
                                </div>
                            </div>
                            
                            <div className="prol">
                                <div className="profile-sym maxx">
                                    <div className="profile-sym-in">
                                        <div className="prof"><img src={Thyroid} /></div>                                        
                                        <div className="prof">Blood Counts and Anemia</div>
                                    </div>

                                    <div className="watchout-cnt">

                                        <div className="test-cnt1">
                                            <table className='tableBodyPartsResult'>                                               
                                                <tr>
                                                    <td><div className="test-cnt">Hemoglobin</div></td>
                                                    <td>
                                                        <div className="test-cnt res" style={{color: investigationParameterList2.subTestColor_1430 != null ? investigationParameterList2.subTestColor_1430 : ''}}>
                                                        {investigationParameterList2.subTestValue_1430 != null ? investigationParameterList2.subTestValue_1430 : '-'}
                                                        </div>
                                                        </td>
                                                </tr>
                                                <tr>
                                                    <td><div className="test-cnt">Haematocrit</div></td>
                                                    <td><div className="test-cnt res">-</div></td>
                                                </tr>
                                                <tr>
                                                    <td><div className="test-cnt">MCV</div></td>
                                                    <td><div className="test-cnt res">-</div></td>
                                                </tr>

                                            </table>
                                        </div>

                                    </div>
                                   
                                </div>

                                
                                <div className="profile-sym maxx">
                                    <div className="profile-sym-in">
                                        <div className="prof"><img src={Lipid} /></div>                                       
                                        <div className="prof">Minerals and Vitamins</div>
                                        <div className="box-pending"> <span> Pending</span> <i className='bi bi-clock'></i> </div>

                                    </div>

                                    <div className="watchout-cnt">  
                                        <div className="test-cnt1">
                                            <table className='tableBodyPartsResult'>
                                              <tr>
                                                    <td><div className="test-cnt">VITAMIN-D</div></td>
                                                    <td>
                                                    <div className="test-cnt res" style={{color: investigationParameterList2.subTestColor_490 != null ? investigationParameterList2.subTestColor_490 : ''}}>
                                                        {investigationParameterList2.subTestValue_490 != null ? investigationParameterList2.subTestValue_490 : '-'}
                                                    </div>
                                                    </td>
                                                </tr>
                                              <tr>
                                                    <td><div className="test-cnt">VITAMIN A (RETINOL)</div></td>
                                                    <td>
                                                    <div className="test-cnt res" style={{color: investigationParameterList2.subTestColor_644 != null ? investigationParameterList2.subTestColor_644 : ''}}>
                                                        {investigationParameterList2.subTestValue_644 != null ? investigationParameterList2.subTestValue_644 : '-'}
                                                    </div>
                                                    </td>
                                                </tr>
                                              <tr>
                                                    <td><div className="test-cnt"> VITAMIN B1 ( THIAMINE)</div></td>
                                                    <td>
                                                    <div className="test-cnt res" style={{color: investigationParameterList2.subTestColor_645 != null ? investigationParameterList2.subTestColor_645 : ''}}>
                                                        {investigationParameterList2.subTestValue_645 != null ? investigationParameterList2.subTestValue_645 : '-'}
                                                    </div>
                                                    </td>
                                                </tr>
                                              <tr>
                                                    <td><div className="test-cnt">VITAMIN B2 ( RIBOFLAVIN)</div></td>
                                                    <td>
                                                    <div className="test-cnt res" style={{color: investigationParameterList2.subTestColor_646 != null ? investigationParameterList2.subTestColor_646 : ''}}>
                                                        {investigationParameterList2.subTestValue_646 != null ? investigationParameterList2.subTestValue_646 : '-'}
                                                    </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                </div>                                
                               

                               
                                <div className="profile-sym maxx">
                                    <div className="profile-sym-in">
                                        <div className="prof"><img src={Sugar} /></div>                                        
                                        <div className="prof">Diabetes Monitoring</div>
                                    </div>

                                    <div className="watchout-cnt">
                                        <div className="test-cnt1">
                                            <table className='tableBodyPartsResult'>
                                                <tr>
                                                    <td> <div className="test-cnt res1">Blood Sugar (Fasting)</div></td>
                                                    <td>                                                      
                                                        <div className="test-cnt res" style={{color: investigationParameterList2.subTestColor_189 != null ? investigationParameterList2.subTestColor_189 : ''}}>
                                                        {investigationParameterList2.subTestValue_189 != null ? investigationParameterList2.subTestValue_189 : '-'}
                                                        </div>
                                                        </td>
                                                </tr>    

                                                <tr>
                                                    <td> <div className="test-cnt res1">Glucose</div></td>
                                                    <td>                                                        
                                                        <div className="test-cnt res" style={{color: investigationParameterList2.subTestColor_613 != null ? investigationParameterList2.subTestColor_613 : ''}}>
                                                        {investigationParameterList2.subTestValue_613 != null ? investigationParameterList2.subTestValue_613 : '-'}
                                                        </div>
                                                    </td>
                                                </tr>   

                                                {/* <tr>
                                                    <td> <div className="test-cnt res1">HbA1c (Glycosylated Haemologbin)</div></td>
                                                    <td><div className="test-cnt res res1">9.1</div></td>
                                                </tr>                                                 */}

                                            </table>
                                        </div>

                                    </div>
                                </div>
                        
                        
                                <div className="profile-sym maxx">
                                    <div className="profile-sym-in">
                                        <div className="prof"><img src={Liver} /></div>                                        
                                        <div className="prof">Liver Function Test</div>
                                    </div>

                                    <div className="watchout-cnt">  
                                        <div className="test-cnt1">
                                            <table className='tableBodyPartsResult'>
                                              <tr>
                                                    <td><div className="test-cnt">Serum Bilirubin (Total)</div></td>
                                                    <td> 
                                                        <div className="test-cnt res" style={{color: investigationParameterList2.subTestColor_741 != null ? investigationParameterList2.subTestColor_741 : ''}}>
                                                        {investigationParameterList2.subTestValue_741 != null ? investigationParameterList2.subTestValue_741 : '-'}
                                                        </div>                                                        
                                                    </td>
                                                </tr>
                                              <tr>
                                                    <td><div className="test-cnt">Serum Albumin </div></td>
                                                    <td>                                                        
                                                        <div className="test-cnt res" style={{color: investigationParameterList2.subTestColor_206 != null ? investigationParameterList2.subTestColor_206 : ''}}>
                                                        {investigationParameterList2.subTestValue_206 != null ? investigationParameterList2.subTestValue_206 : '-'}
                                                        </div> 
                                                    </td>
                                                </tr>
                                              <tr>
                                                    <td><div className="test-cnt">Serum Alkaline Phosphatase</div></td>
                                                    <td>                                                        
                                                        <div className="test-cnt res" style={{color: investigationParameterList2.subTestColor_744 != null ? investigationParameterList2.subTestColor_744 : ''}}>
                                                        {investigationParameterList2.subTestValue_744 != null ? investigationParameterList2.subTestValue_744 : '-'}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>


                                <div className="profile-sym maxx">
                                    <div className="profile-sym-in">
                                        <div className="prof"><img src={kidney} /></div>                                        
                                        <div className="prof">Kidney Function Test</div>
                                    </div>

                                    <div className="watchout-cnt_">  
                                        <div className="test-cnt1">
                                        <table className='tableBodyPartsResult'>
                                                <tr>
                                                    <td> <div className="test-cnt">Blood Urea</div></td>
                                                    <td>
                                                        <div className="test-cnt res" style={{color: investigationParameterList2.subTestColor_496 != null ? investigationParameterList2.subTestColor_496 : ''}}>
                                                        {investigationParameterList2.subTestValue_496 != null ? investigationParameterList2.subTestValue_496 : '-'}
                                                        </div>
                                                    </td>
                                                </tr>   

                                                <tr>
                                                    <td> <div className="test-cnt">Serum Creatinine</div></td>
                                                    <td>
                                                    <div className="test-cnt res" style={{color: investigationParameterList2.subTestColor_497 != null ? investigationParameterList2.subTestColor_497 : ''}}>
                                                        {investigationParameterList2.subTestValue_497 != null ? investigationParameterList2.subTestValue_497 : '-'}
                                                        </div>
                                                    </td>
                                                </tr>                                                

                                            </table>
                                        </div>
                                    </div>
                                </div>
                        
                                <div className="profile-sym maxx" style={{maxWidth:'350px', marginTop:'10px'}}>
                                    <div className="profile-sym profile-sym d-flex justify-content-between">
                                        <div>
                                        <div className="profile-sym-in">
                                            <div className="prof"><img src={Thyroid} /></div>
                                            <div className="prof">Mineral Profile</div>
                                        </div>
                                        <div className="profile-sym-in descrip">Everything looks good</div>
                                        </div>

                                        <div>
                                        <div className="profile-sym-in mtt_">
                                            <div className="prof"><img src={Thyroid} /></div>
                                            <div className="prof">Thyroid Profile</div>
                                        </div>
                                        <div className="profile-sym-in descrip">All parameters within normal limit</div>
                                        </div>

                                    </div>                                    
                                </div>
                         
                            </div>   
                                                       
                        </div>

                        <div className="col2_ flex1_">
                            <div className="nameExperimentSignContainer">
                                <div className="ptDetails">
                                    <div><span className='ptName' title='Patient Name'>{virtualViewSignalrData.pntName}</span>   <spn className="ptUHID" title='Patient UHID'>{virtualViewSignalrData.uhId}</spn> </div>
                                    <div> <span className='ptGender' title='Patient Gender'>{virtualViewSignalrData.pntGender}</span> / <span className='ptAge' title='Patient Age'>{virtualViewSignalrData.pntAge}</span> <spn className="ptDep" title='Patient Department'>{virtualViewSignalrData.ptDep}</spn>, <spn className="ptWard" title='Patient Ward'>{virtualViewSignalrData.ward}</spn></div>
                                    
                                </div>
                                <div className="experiment pointer">                                    
                                    <img src={imgExperiment} alt="imgExperiment" />
                                </div>
                                <div className="sign">
                                    <span>Sign/Symptoms</span>
                                    <img src={maximize} className='pointer'/>                                
                                </div>
                            </div>   

                            <div className="bodypart-cnt-in">
                                <div className="bodypart">                                      
                                      {/* <div className='fio1'>FiO2 <span>{lifeSupportList.id_179 != null ? lifeSupportList.id_179 : '-'}%</span> </div>                                           */}
                                        <>
                                           {virtualViewSignalrData.pntGender === "Male" ? 
                                           <img src={fullbody} className="bdy" />
                                            : 
                                            <img src={fullbodyFemale} className="bdy" />
                                           }
                                        </>

                                   
                                    <div id="ventilator" className="ventilatorpart-in">                                      

                                        <div className="ventilatorpart1"></div> 
                                        <div className="ventilatorpart">
                                            <div className="textwrapper">
                                                <table className='table-virtual-regular tableVenti'>
                                                    <tbody>
                                                    <tr>
                                                        <td>Mode</td>
                                                        <td> 
                                                        <span className='ventiVal' title={lifeSupportList.name_98 != null ? lifeSupportList.name_98 : 'No Data'}>{lifeSupportList.name_98 != null ? lifeSupportList.name_98 : '-'}</span> 
                                                        </td>                                                        
                                                    </tr>                                                    
                                                    <tr>
                                                        <td>PEEP</td>
                                                        <td><span>{lifeSupportList.id_98 != null ? lifeSupportList.id_98 : '-'}</span> </td>                                                        
                                                    </tr>
                                                    <tr>
                                                        <td>FiO2</td>
                                                        <td>
                                                            <span className='ventiVal'>{lifeSupportList.id_179 != null ? lifeSupportList.id_179 : '-'}%</span>                                                            
                                                        </td>                                                        
                                                    </tr>

                                                    <tr>
                                                        <td>I:E</td>
                                                        <td> 
                                                            <b>-</b>                                                          
                                                             </td>                                                        
                                                    </tr>

                                                    <tr>
                                                        <td>p-max</td>
                                                        <td>
                                                            <b>-</b>                                                            
                                                        </td>                                                        
                                                    </tr>
                                                      
                                                    </tbody> 
                                                </table>
                                            </div>
                                            <img src={ventilator} alt="Ventilator" />
                                            </div>                                            
                                          
                                         {/* oxygenSupporList*/}

                                        {
                                        // console.log('oxygenSupporList:', virtualViewSignalrData.oxygenSupporList)
                                        virtualViewSignalrData.oxygenSupporList && virtualViewSignalrData.oxygenSupporList[0].supportTypeName != null ? 
                                        <div className="maskpart" title={virtualViewSignalrData.oxygenSupporList[0].supportTypeName}>
                                        <img src={mask} alt='mask' />
                                        </div> 
                                        : 
                                        ''
                                        }                                       


                                        {/* {patientLifeSupportList && patientLifeSupportList.map((val, index) => {
                                            if (val.uhid === uhID) {
                                                return (
                                                    <div className="fio2" key={index}>
                                                        FiO2 <span>{val.fio2}</span>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })} */}
                                    </div> 


                                    <div id="glucose">
                                        <div className="glucosepart"><img src={glucose} /></div>
                                    </div>

                                    {                                        
                                    virtualViewSignalrData.dvtPumpList && virtualViewSignalrData.dvtPumpList.length > 0 ? 
                                    <div id="plaster">
                                        <div className="plasterpart" title='DVT Pump'><img src={plaster} /></div>
                                        <div className="p1part"><img src={p1} /></div>
                                        <div className="dvp">DVT Pump<br />{virtualViewSignalrData.dvtPumpList[0].dvtPumpDateTime}</div>
                                    </div>                                       
                                    : 
                                    ''
                                    }


                                    {/* <div id="plaster">
                                        <div className="plasterpart"><img src={plaster} /></div>
                                        <div className="p1part"><img src={p1} /></div>
                                        <div className="dvp">DVT Pump<br />21/06/2023 02:30AM</div>
                                    </div> */}

                                    <div id="pulserate" className="pulse-in">
                                        <div className="pulse1"></div>
                                        <div className="pulse2"></div>
                                        <div className="pulsepart"><img src={pulse} /></div>
                                    </div>

                                    <div id="bloodpressure" className="bp-in">
                                        <div className="bp1"></div>
                                        <div className="bp2"></div>
                                        <div className="bppart"><img src={bp} /></div>
                                    </div>

                                    <div id="ECGpart" className="ecg-in">
                                        <div className="ecg1"></div>
                                        <div className="ecg2"></div>
                                    </div>

                                    {/* <div className="kidneyprofile-in">
                                        <div className="kidneyprofile1"></div>
                                        <div className="kidneyprofile2"></div>
                                    </div> */}

                                    {/* <div className="Diabetes-in">
                                        <div className="Diabetes1"></div>
                                        <div className="Diabetes2"></div>
                                    </div> */}

                                    {/* <div className="liver-in">
                                        <div className="liver1"></div>
                                        <div className="liver2"></div>
                                    </div> */}

                                    {/* <div className="lipid-in">
                                        <div className="lipid1"></div>
                                        <div className="lipid2"></div>
                                    </div> */}

                                    <div className="redcircle3">
                                        <i className="fa fa-circle text-danger-glow blink"></i>
                                    </div>

                                    <div className="liver-function-test-group" title='Liver Function Test'>
                                        <div className="round-one"></div>
                                        <div className="line-one"></div>
                                        <div className="line-two"></div>
                                        <div className="round-two"></div>
                                    </div>


                                    <div className="kidney-function-test-group" title='Kidney Function Test'>
                                        <div className="round-one"></div>
                                        <div className="line-one"></div>
                                        <div className="line-two"></div>
                                        <div className="round-two"></div>
                                    </div>


                                </div>
                            </div>
                            
                        </div>

                        <div className="col3_ flex1_">
                            <div className="right-sec">

                                <div className="left-sec">
                                    <div className="left-sec-n">
                                        <div className="left-sec-in"><img src={monitor} /><span> Patient History</span></div>
                                        <div className="left-sec-in"><img src={maximize} className="resizebtn" /> </div>
                                    </div>
                                    <div className="left-sec-Monitor" style={{overflowY:'auto', height:'110px'}}>
                                        <table className="table-virtual-regular border-bottom">
                                        <thead>
                                            <tr>
                                                <th className='text-center'>#</th>
                                                <th>Problem Name</th>
                                            </tr>
                                        </thead>

                                            <tbody> 
                                           
                                            {patientComplainHistory.length > 0 ? ( 
                                                patientComplainHistory.filter((key) => key.pdmId === 2).map((key, index) =>                                                
                                                <tr>
                                                    <td className='text-center'>{index + 1}</td>
                                                    <td>
                                                        {key.problemName}
                                                    </td>
                                                </tr>
                                                )
                                            )  
                                            : <tr><td colSpan={2}><div className='nodatafoundText nodatafoundTextCenter'>Data not found.</div></td></tr>
                                            }                                            
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="left-sec">

                                    <div className="doctor-cds-container">
                                        <div className="doctor-wrapper" title='Doctor’s Input'>
                                            <div className="left-sec-n">
                                            <div className="left-sec-in"><img src={monitor} /><span> Doctor’s Input</span></div>
                                            <div className="left-sec-in"><img src={maximize} className="resizebtn" /> </div>
                                            </div>
                                            <div className="left-sec-n doctor-diagnosis-bg" title="Doctor's diagnosis">
                                            <div className="left-sec-in"><img src={stethoRectangleDiagnosisIcon} /></div>                                            
                                            </div>

                                            <div className="doctor-diagnosis-list-container" title="Doctor's diagnosis">
                                            <div className="diagnosisListWrapper">
                                                   {diagonsisList!= null ? (
                                                    diagonsisList.map((diagonsisListKeys) => { return (
                                                    <div className="diagnosisListItems">{diagonsisListKeys.problemName}</div>
                                                    ) }) 
                                                   ) : 
                                                   <div className='nodatafoundText nodatafoundTextCenter w-100'>Data not found.</div>
                                                   }                                                    
                                                    
                                                </div>
                                            </div>

                                            <div className="left-sec-n doctor-diagnosis-bg" title="Doctor's Medication">
                                            <div className="left-sec-in"><img src={iconMedication} /></div>                                            
                                            </div>
                                            <div style={{height:'110px', overflowY:'auto'}} title="Doctor's Medication">
                                                <table className="table-virtual-regular border stripped">
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center'>#</th>
                                                            <th>Drug  Name</th>                                                   
                                                            {/* <th>Date Time</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>                                                
                                                        {prescreptionParameterList != null ? 
                                                            (prescreptionParameterList.map((prescreptionParameterListKeys, index) => 
                                                            {return(
                                                                    <tr>
                                                                    <td className='text-center'>{index + 1}</td>
                                                                    <td>{prescreptionParameterListKeys.drugName}</td>
                                                                    {/* <td>{prescreptionParameterListKeys.prescreptionDateTime}</td>                                             */}
                                                                </tr>
                                                                )})
                                                            ) :  <tr><td colSpan={2}><div className='nodatafoundText nodatafoundTextCenter'>Data not found.</div></td></tr>
                                                        }                                           
                                                
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="cds-wrapper" title='CDS Generated Data'>
                                           <div className="left-sec-n">
                                            <div className="left-sec-in"><img src={monitor} /><span> CDS Generated Data</span></div>
                                            <div className="left-sec-in"><img src={maximize} className="resizebtn" /> </div>
                                            </div>

                                            <div className="left-sec-n cds-diagnosis-bg" title="CDS Diagnosis">
                                            <div className="left-sec-in"><img src={stethoRectangleDiagnosisIcon} /></div>                                            
                                            </div>

                                            <div className="cds-diagnosis-list-container" title="CDS Diagnosis">                                            
                                                <table className="table-virtual-regular border stripped_ cds-diagnosis-list-table">
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center'>#</th>
                                                            <th>Problem Name</th>   
                                                            <th className='text-center'>%</th>   
                                                        </tr>
                                                    </thead>
                                                    <tbody>                                                                                                              
                                                      
                                                        {provableDiseaseList && provableDiseaseList.length > 0 ? 
                                                            (provableDiseaseList.map((keys, index) => 
                                                            {return(
                                                                    <tr>
                                                                    <td className='text-center'>{index + 1}</td>
                                                                    <td title={`Problem Category: ${keys.problemCategory}`}>{keys.problemName}</td>
                                                                    <td className='text-center' title='Disease Percentage'>{keys.diseasePercentage}%</td>                                                                    
                                                                </tr>
                                                                )})
                                                            ) :  <tr><td colSpan={3}><div className='nodatafoundText nodatafoundTextCenter'>Data not found.</div></td></tr>
                                                        } 
                                                         
                                                    </tbody>
                                                </table>  
                                            
                                            </div>

                                            <div className="left-sec-n cds-diagnosis-bg" title="CDS Medication">
                                            <div className="left-sec-in"><img src={iconMedication} /></div>                                            
                                            </div>
                                            <div style={{height:'110px', overflowY:'auto'}} title="CDS Medication">
                                                <table className="table-virtual-regular border stripped">
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center'>#</th>
                                                            <th>Drug  Name</th>   
                                                        </tr>
                                                    </thead>
                                                    <tbody>                                                         
                                                       
                                                       {/* { console.log('diseaseWiseTestInvestigationList', diseaseWiseTestInvestigationList)} */}
                                                        {diseaseWiseTestInvestigationList && diseaseWiseTestInvestigationList.length > 0 ? 
                                                            (diseaseWiseTestInvestigationList.map((keys, index) => 
                                                            {return(
                                                                    <tr>
                                                                    <td className='text-center'>{index + 1}</td>
                                                                    <td>{keys.medicineName}</td>
                                                                    {/* <td>{prescreptionParameterListKeys.prescreptionDateTime}</td>                                             */}
                                                                </tr>
                                                                )})
                                                            ) :  <tr><td colSpan={2}><div className='nodatafoundText nodatafoundTextCenter'>Data not found.</div></td></tr>
                                                        } 
                                                         
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className="left-sec-n">
                                        <div className="left-sec-in"><img src={monitor} /><span> Diagnosis</span></div>
                                        <div className="left-sec-in"><img src={maximize} className="resizebtn" /> </div>
                                    </div>
                                    <div className="left-sec-Monitor">
                                        <table className="table-virtual-regular border-bottom">
                                            <tbody>
                                            <tr>
                                                <td className='p-0'>
                                                    <div className="dd-yellow">
                                                    <img src={iconDoctorDiagnosis} />                                                   
                                                    </div>
                                                </td>

                                                <td>
                                                <div style={{overflow: 'auto', height: '100px'}}>
                                                <div className="diagnosisListWrapper">
                                                   {diagonsisList!= null ? (
                                                    diagonsisList.map((diagonsisListKeys) => { return (
                                                    <div className="diagnosisListItems">{diagonsisListKeys.problemName}</div>
                                                    ) }) 
                                                   ) : 
                                                   <div className='nodatafoundText'>Data not found.</div>
                                                   }                                                    
                                                    
                                                </div>    
                                                </div>
                                                    
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='p-0'>
                                                <div className="sd-sky">  
                                                <img src={iconSuggestiveDiagnosis} />                                                
                                                </div>
                                                </td>
                                                <td>Acute Coronary Syndrome (ACS): The patient's acute onset chest pain, diaphoresis, and hyperacute T-waves without ST elevation could be indicative of ACS. Her history of diabetes and hyperlipidemia, both risk factors for coronary artery disease, could have contributed to the development of ACS. The presence of elevated cardiac enzymes could further suggest this diagnosis.</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div> */}
                                </div>

                                {/* <div className="left-sec">
                                    <div className="left-sec-n">
                                        <div className="left-sec-in"><img src={monitor} /><span> Medication</span></div>
                                        <div className="left-sec-in"><img src={maximize} className="resizebtn" /> </div>
                                    </div>
                                    <div style={{height:'110px', overflowY:'auto'}}>
                                        <table className="table-virtual-regular border stripped">
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>#</th>
                                                    <th>Drug  Name</th>                                                   
                                                    <th>Date Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>                                                
                                                {prescreptionParameterList != null ? 
                                                    (prescreptionParameterList.map((prescreptionParameterListKeys, index) => 
                                                    {return(
                                                            <tr>
                                                            <td className='text-center'>{index + 1}</td>
                                                            <td>{prescreptionParameterListKeys.drugName}</td>
                                                            <td>{prescreptionParameterListKeys.prescreptionDateTime}</td>                                            
                                                        </tr>
                                                        )})
                                                    ) : <div className='nodatafoundText'>Data not found.</div>
                                                }                                           
                                           
                                            </tbody>
                                        </table>
                                    </div>
                                </div> */}



                                <div className="left-sec">
                                    <div className="left-sec-n">
                                        <div className="left-sec-in"><img src={monitor} /><span> Suggestive Investigation</span></div>
                                        <div className="left-sec-in"><img src={maximize} className="resizebtn" /> </div>
                                    </div>
                                    <div className="left-sec-Monitor">
                                        <div className='d-flex flex-wrap gap-1 p-2'>
                                            {/* <div className='suggest-inveti'>Serum Alkaline Phosphatase</div>
                                            <div className='suggest-inveti'>Serum Bilirubin (Total)</div>
                                            <div className='suggest-inveti bgG-textW'>RBC</div> */}
                                            {/* getdiseaseWisetestvitalList  */}                                           
                                           
                                            {diseaseWiseTestVitalList != null 
                                            ? 
                                            (diseaseWiseTestVitalList.map((keys) => 
                                            <div className='suggest-inveti'>{keys.subTest}</div>)
                                            ) 
                                            : 
                                            <div className='nodatafoundText nodatafoundTextCenter'>Data not found.</div>
                                            }

                                            
                                        </div>
                                    </div>
                                </div>

                                
                                <div className='alert-text'>Alerts and Suggestions</div>
                                <div className="rt-sec">
                                    <div className="rt-Ammonia"><img src={Ammonia} style={{ width: '25px' }} /></div>
                                    <div className="rt-Ammonia"><span className="a0">Ammonia Result</span><span className="a1">48</span> <span className="a2">performed on 14-06-2023 02:16AM</span></div>
                                </div>

                                <div className="rt-sec" style={{ borderLeft: '2px solid #2C50EE' }}>
                                    <div className="rt-Ammonia"><img src={rtfeed} style={{ width: '30px' }} />
                                        <span style={{ color: '#FC0F0F', fontSize: '11px', padding: '0 5px', }}>RT Feed on hold</span>
                                        <span style={{ color: '#041D4A', 'font-size': '11px' }}>Hold From : 26/06/2023 10:39 AM</span>
                                        <p className="rema"><strong>Remark :</strong> POST OP, RT HOLD Type : Post opt, before every feed  cinnamon fine
                                            crushed 5gm to be given in 10ml water along with Diadzine (soflavone) and ECG</p>
                                    </div>
                                </div>

                                <div className="rt-sec">
                                    <div className="rt-Ammonia"><img src={exclamationMark1} style={{ width: '15px' }} /></div>
                                    <div className="rt-Ammonia"><span>ABG is not performed with in 6 hours</span></div>
                                </div>

                                <div className="rt-sec" style={{ borderLeft: '2px solid #FC0F0F' }}>
                                    <div className="rt-Ammonia"><img src={N} style={{ width: '8px', marginRight: '5px' }} /></div>
                                    <div className="rt-Ammonia"><span>Infusion NORAD running at Flow Rate : 5.40ml/hr</span></div>
                                </div>

                                <div className="rt-sec" style={{ borderLeft: '2px solid #FC0F0F' }}>
                                    <div className="rt-Ammonia"><img src={F} style={{ width: '8px', marginRight: '5px' }} /></div>
                                    <div className="rt-Ammonia"><span>Infusion FENTA running at Flow Rate : 2ml/hr</span></div>
                                </div>

                                <div className="rt-sec">
                                    <div className="rt-Ammonia"><img src={temperature} style={{ width: '15px' }} /></div>
                                    <div className="rt-Ammonia"><span>Normal or Low grade fever, Value : <strong style={{ color: '#008806' }}>98.8F</strong></span></div>
                                </div>

                            </div>
                        </div>

                    </div>



                    {/*#################### Modal SPO2 Graph Start Here*/}
                    <div className="modal fade modal-standard" id="openSPO2GraphModal" data-bs-backdrop="static">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">SPO2</h1>                            
                            <span title='Close Window' className="closee" data-bs-dismiss="modal"><i className="fa fa-times"></i></span>
                        </div>
                        <div className="modal-body">
                        <div id="graphSpO2"></div>
                        </div>      
                        </div>
                    </div>
                    </div>


                   {/*#################### Modal Blood Pressure Graph Start Here */}
                    <div className="modal fade modal-standard" id="openBPGraphModal" data-bs-backdrop="static">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">BP Sys / BP Dias</h1>                            
                            <span title='Close Window' className="closee" data-bs-dismiss="modal"><i className="fa fa-times"></i></span>
                        </div>
                        <div className="modal-body">
                        <div id="graphBloodPressue"></div>
                        </div>      
                        </div>
                    </div>
                    </div>

                   {/*#################### Modal Heart Rate Graph Start Here */}
                    <div className="modal fade modal-standard" id="openHRGraphModal" data-bs-backdrop="static">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Heart Rate</h1>                            
                            <span title='Close Window' className="closee" data-bs-dismiss="modal"><i className="fa fa-times"></i></span>
                        </div>
                        <div className="modal-body">
                        <div id="graphHeartRate"></div>
                        </div>      
                        </div>
                    </div>
                    </div>


                    {/*#################### Modal Heart Rate Graph Start Here  */}
                    <div className="modal fade modal-standard" id="openPRGraphModal" data-bs-backdrop="static">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Pulse Rate</h1>                            
                            <span title='Close Window' className="closee" data-bs-dismiss="modal"><i className="fa fa-times"></i></span>
                        </div>
                        <div className="modal-body">
                        <div id="graphPulseRate"></div>
                        </div>      
                        </div>
                    </div>
                    </div>

                    {/*#################### Modal Respiratory Rate Graph Start Here */}
                    <div className="modal fade modal-standard" id="openRRGraphModal" data-bs-backdrop="static">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Respiratory Rate</h1>                            
                            <span title='Close Window' className="closee" data-bs-dismiss="modal"><i className="fa fa-times"></i></span>
                        </div>
                        <div className="modal-body">
                        <div id="graphRespiratoryRate"></div>
                        </div>      
                        </div>
                    </div>
                    </div>


                    {/*#################### Modal Temperature Graph Start Here moon */}
                    <div className="modal fade modal-standard" id="openTemperatureGraphModal" data-bs-backdrop="static">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Temperature</h1>                            
                            <span title='Close Window' className="closee" data-bs-dismiss="modal"><i className="fa fa-times"></i></span>
                        </div>
                        <div className="modal-body">
                        <div id="graphTemperature"></div>
                        </div>      
                        </div>
                    </div>
                    </div>
                    
                
        </>
    )
}