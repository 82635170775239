import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import BoxContainer from '../../../../../Component/BoxContainer';
import Heading from '../../../../../Component/Heading';
import Toster from '../../../../../Component/Toster';
import TosterUnderProcess from '../../../../../Component/TosterUnderProcess';
import GetGender from '../../../../../Registartion/API/GET/GetGender';
import { parseBooleans } from 'xml2js/lib/processors';
import saveButtonIcon from '../../../../../assets/images/icons/save.svg';
import clearIcon from '../../../../../assets/images/icons/clear.svg';
import IconDelete from '../../../../../assets/images/icons/delete.svg';
import IconEdit from '../../../../../assets/images/icons/edit.svg';
import InsertFamilyMemberDetails from '../../../../API/FamilyMemberDetails/InsertFamilyMemberDetails';
import GetFamilyMemberDetailsList from '../../../../API/FamilyMemberDetails/GetFamilyMemberDetails';
import TableContainer from '../../../../../Component/TableContainer';
import DeleteFamilyMemberDetails from '../../../../API/FamilyMemberDetails/DeleteFamilyMemberDetails';
import { List } from 'immutable';
import UpdateFamilyMemberHistory from '../../../../API/FamilyMemberDetails/UpdateFamilyMemberDetails';


export default function FamilyMemberDetails() {
    const { t } = useTranslation();
    let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [showToster, setShowToster] = useState(0);
    let [tosterMessage, setTosterMessage] = useState("");
    let [tosterValue, setTosterValue] = useState(0);
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [updateBool, setUpdateBool] = useState(0);
    let [isDiseased, setisDiseased] = useState(0);
    let [getGender, setGender] = useState('');
    let [getRelationShip, setRelationShip] = useState('');
    let [getGenderList, setGenderList] = useState('');
    let [getAgeType, setAgeType] = useState(1);
    let [getDeceasedAgeType, setDeceasedAgeType] = useState('');
    let [getOnsetAgeType, setOnsetAgeType] = useState(1);
    let [getAdditionalInformation, setAdditionalInformation] = useState('');
    let [getName, setName] = useState('');
    let [getAge, setAge] = useState('');
    let [getDateOfBirth, setDateOfBirth] = useState('');
    let [getOutcome, setOutcome] = useState('');
    let [getDeceasedAge, setDeceasedAge] = useState('');
    let [getDeceasedDate, setDeceasedDate] = useState('');
    let [getOnsetAge, setOnsetAge] = useState('');
    let [getGeneralDate, setGeneraldate] = useState('');
    let [getGeneralStatus, setGeneralStatus] = useState(1);
    let [getFamilyMemberDetailsList, setFamilyMemberDetailsList] = useState('');
    let [getRowId, setRowId] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    const clientID = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
    const userId = JSON.parse(sessionStorage.getItem("LoginData")).userId;

    // const uhid = JSON.parse(sessionStorage.getItem("IPDactivePatient")).Uhid;


    let uhid = window.sessionStorage.getItem("activePatient")
        ? JSON.parse(window.sessionStorage.getItem("activePatient")).Uhid
        : window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : [];



    // const [age, setAge] = useState('');
    // const [dateOfBirth, setDateOfBirth] = useState('');
    // const [type, setType] = useState('Year');
    // let [getIsChecked, setIsChecked] = useState(0);


    let FunGender = async () => {
        const GetResponse = await GetGender();
        if (GetResponse.status === 1) {
            setGenderList(GetResponse.responseValue)
        }
    }

    const handleRelationShip = () => {
        const getvalue = document.getElementById("dropdownRelationship").value;
        setRelationShip(getvalue);
    };
    const handleGender = () => {
        const getvalue = document.getElementById("dropdownSex").value;
        setGender(getvalue);
    };
    const handleAgeType = () => {
        const getvalue = document.getElementById("dropdownAgeType").value;
        setAgeType(getvalue);
    };
    const handleDeceasedAgeType = () => {
        const getvalue = document.getElementById("dropdowntxtDeceasedAgeType").value;
        setDeceasedAgeType(getvalue);
    };
    const handleOnsetAgeType = () => {
        const getvalue = document.getElementById("dropdownOnsetAgeType").value;
        setOnsetAgeType(getvalue);
    };
    const handleGeneralStatus = () => {
        const getvalue = document.getElementById("dropdownGeneralStatus").value;
        setGeneralStatus(getvalue);
    };



    let handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (name === "txtName") {
            document.getElementById("errtxtName").style.display = "none";
            setName(value);
        } else if (name === "txtDateOfBirth") {
            const age = calculateAgeFromDOB(value, getAgeType);
            setDateOfBirth(value);
            setAge(age);
        } else if (name === "txtAge") {
            document.getElementById("errtxtAge").style.display = "none";
            const dob = calculateDOBFromAge(value, getAgeType);
            const formattedDOB = formatDateToYYYYMMDD(dob);
            setDateOfBirth(formattedDOB);
            setAge(value);
        } else if (name === "txtOutcome") {
            document.getElementById("errtxtOutcome").style.display = "none";
            setOutcome(value);
        }
        else if (name === "txtDeceasedAge") {
            document.getElementById("errtxtDeceasedAge").style.display = "none";
            setDeceasedAge(value);
        }
        else if (name === "txtDeceasedDate") {
            document.getElementById("errtxtDeceasedDate").style.display = "none";
            setDeceasedDate(value);
        }
        else if (name === "txtOnsetAge") {
            document.getElementById("errtxtOnsetAge").style.display = "none";
            setOnsetAge(value);
        }
        else if (name === "txtAdditionalInformation") {
            document.getElementById("errtxtAdditionalInformation").style.display = "none";
            setAdditionalInformation(value);
        }
        else if (name === "txtGeneralDate") {
            document.getElementById("errtxtGeneralDate").style.display = "none";
            setGeneraldate(value);
        }

    }

    const handleCheckboxChange = (event) => {
        // Update the state based on the checkbox value
        if (event.target.checked === true) {
            setisDiseased(1);
        } else {
            setisDiseased(0);
        };
        console.log("CheckBox", event.target.checked);
    }

    // function calculateDateOfBirth(age, type) {
    //     console.log("sdD",age, type);
    //     const today = new Date();
    //     const yearOfBirth = today.getFullYear() - age;
    //     const monthOfBirth = today.getMonth();
    //     const dayOfBirth = today.getDate();

    //     switch (type.toLowerCase()) {
    //       case 'year':
    //         return yearOfBirth;
    //       case 'month':

    //         if ((monthOfBirth + 1) < 0) {
    //             const yearsToSubtract = Math.ceil(Math.abs(monthOfBirth + 1) / 12);
    //             today.setFullYear(today.getFullYear() - yearsToSubtract);
    //             today.setMonth(12 - (Math.abs(monthOfBirth + 1) % 12));
    //         }

    //         return today.toDateString(); // Adding 1 because months are 0-indexed in JavaScript
    //       case 'day':
    //         return dayOfBirth;
    //       default:
    //         throw new Error('Invalid type');
    //     }
    //   }

    //     const handleAgeChange = (event) => {
    //       const age = parseInt(event.target.value);
    //       setAge(age);
    //       console.log("Age",event.target.value )

    //       // Calculate date of birth using helper function
    //       const datePart = calculateDateOfBirth(age, type);
    //       setDateOfBirth(datePart);
    //       console.log("ABC", datePart);
    //     };

    //     const handleTypeChange = () => {
    //         const getvalue = document.getElementById("dropdownAgeType").value;
    //         setType(getvalue);

    //          const  getName = parseInt(getvalue) === 1 ? 'year'
    //              : parseInt(getvalue) === 2 ? 'month'
    //              : parseInt(getvalue) === 3 ?  'day'
    //              : ''



    //       console.log("AgeType", getName)
    //        const datePart = calculateDateOfBirth(age, getName);
    //        setDateOfBirth(datePart);
    //        console.log("ABC", datePart);
    //     }


    let handleGet = async () => {

        var objGet = {
            uhid: uhid,
            userId: window.userId,
            clientId: clientID
        }

        let getResponse = await GetFamilyMemberDetailsList(objGet);
        if (getResponse.status === 1) {
            console.log("Response", getResponse.responseValue);
            setFamilyMemberDetailsList(getResponse.responseValue)
        } else {

        }

    }


    const handleClear = () => {

        setGeneralStatus(1);
        setGeneraldate('');
        setName('');
        setRelationShip(1);
        setGender(0);
        setDateOfBirth('');
        setAge('');
        setAgeType(1);
        setOutcome('');
        setisDiseased(0);
        setDeceasedAge('');
        setDeceasedAgeType(1);
        setDeceasedDate('');
        setOnsetAge('');
        setOnsetAgeType(1);
        setAdditionalInformation('');
    }


    let handleDeleteRow = async () => {
        // setLoder(1)
        setShowUnderProcess(1);
        let objDelete = {
            uhid: uhid,
            id: getRowId,
            userId: window.userId,
            clientId: clientID
        }
        console.log('delete', objDelete);
        let response = await DeleteFamilyMemberDetails(objDelete)
        if (response.status === 1) {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Data Deleted SuccessFully!")
            setTosterValue(0)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
            handleGet();
            handleClear();
        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage(response.responseValue)
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }


    const handleSave = async () => {

        // if(getGeneralStatus === null || getGeneralStatus === ""){
        //     document.getElementById('errtxtSpherePowerRight').innerHTML = "Please Enter Sphere Power Right";
        //     document.getElementById('errtxtSpherePowerRight').style.display = "block";
        //     return false;
        // }
        if (getGeneralDate === null || getGeneralDate === "") {
            document.getElementById('errtxtGeneralDate').innerHTML = "Please Select General Date";
            document.getElementById('errtxtGeneralDate').style.display = "block";
            return false;
        }
        if (getName === null || getName === "") {
            document.getElementById('errtxtName').innerHTML = "Please Enter Name";
            document.getElementById('errtxtName').style.display = "block";
            return false;
        }
        // if(getRelationShip === null || getRelationShip === ""){
        //     document.getElementById('errtxtSpherePowerRight').innerHTML = "Please Enter Sphere Power Right";
        //     document.getElementById('errtxtSpherePowerRight').style.display = "block";
        //     return false;
        // }
        // if(getGender === null || getGender === ""){
        //     document.getElementById('errtxtSpherePowerRight').innerHTML = "Please Enter Sphere Power Right";
        //     document.getElementById('errtxtSpherePowerRight').style.display = "block";
        //     return false;
        // }
        if (getDateOfBirth === null || getDateOfBirth === "") {
            document.getElementById('errtxtDateOfBirth').innerHTML = "Please Enter Date Of Birth";
            document.getElementById('errtxtDateOfBirth').style.display = "block";
            return false;
        }
        if (getAge === null || getAge === "") {
            document.getElementById('errtxtAge').innerHTML = "Please Enter Age";
            document.getElementById('errtxtAge').style.display = "block";
            return false;
        }
        // if(getAgeType === null || getAgeType === ""){
        //     document.getElementById('errtxtSpherePowerRight').innerHTML = "Please Enter Sphere Power Right";
        //     document.getElementById('errtxtSpherePowerRight').style.display = "block";
        //     return false;
        // }
        if (getOutcome === null || getOutcome === "") {
            document.getElementById('errtxtOutcome').innerHTML = "Please Enter Outcome";
            document.getElementById('errtxtOutcome').style.display = "block";
            return false;
        }
        if (isDiseased === null || isDiseased === "") {
            document.getElementById('errtxtIsDeceased').innerHTML = "Please Select Deceased";
            document.getElementById('errtxtIsDeceased').style.display = "block";
            return false;
        }
        if (isDiseased === 1) {
            if (getDeceasedAge === null || getDeceasedAge === "") {
                document.getElementById('errtxtDeceasedAge').innerHTML = "Please Enter Deceased Age";
                document.getElementById('errtxtDeceasedAge').style.display = "block";
                return false;
            }
            // if(getDeceasedAgeType === null || getDeceasedAgeType === ""){
            //     document.getElementById('errtxtSpherePowerRight').innerHTML = "Please Enter Sphere Power Right";
            //     document.getElementById('errtxtSpherePowerRight').style.display = "block";
            //     return false;
            // }
            if (getDeceasedDate === null || getDeceasedDate === "") {
                document.getElementById('errtxtDeceasedDate').innerHTML = "Please Enter Deceased Date";
                document.getElementById('errtxtDeceasedDate').style.display = "block";
                return false;
            }
        }
        if (getOnsetAge === null || getOnsetAge === "") {
            document.getElementById('errtxtOnsetAge').innerHTML = "Please Enter Onset Age";
            document.getElementById('errtxtOnsetAge').style.display = "block";
            return false;
        }
        // if(getOnsetAgeType === null || getOnsetAgeType === ""){
        //     document.getElementById('errtxtSpherePowerRight').innerHTML = "Please Enter Sphere Power Right";
        //     document.getElementById('errtxtSpherePowerRight').style.display = "block";
        //     return false;
        // }
        // if(getAdditionalInformation === null || getAdditionalInformation === ""){
        //     document.getElementById('errtxtSpherePowerRight').innerHTML = "Please Enter Sphere Power Right";
        //     document.getElementById('errtxtSpherePowerRight').style.display = "block";
        //     return false;
        // }

        var obj = {
            id: getRowId,
            uhid: uhid,
            userId: userId,
            clientId: clientID,
            generalStatus: parseInt(getGeneralStatus),
            generaldate: getGeneralDate,
            name: getName,
            relationship: parseInt(getRelationShip),
            sex: parseInt(getGender),
            dob: getDateOfBirth,
            age: getAge,
            ageType: parseInt(getAgeType),
            outcome: getOutcome,
            isDeceased: parseInt(isDiseased),
            deceasedAge: getDeceasedAge,
            deceasedAgeType: parseInt(getDeceasedAgeType),
            deceasedDate: getDeceasedDate,
            onsetAge: getOnsetAge,
            onsetAgeType: parseInt(getOnsetAgeType),
            additionalInformation: getAdditionalInformation
        }
        if (updateBool === 0) {
            setShowUnderProcess(1);
            console.log("Object", obj);
            let response = await InsertFamilyMemberDetails(obj);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setShowToster(1);
                setTosterMessage("Data Save SuccessFully!");
                setTosterValue(0);
                setTimeout(() => {
                    setShowToster(0);
                }, 2000);

                handleClear();
                handleGet();

            } else {
                setShowUnderProcess(0);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTosterValue(1);
                setTimeout(() => {
                    setShowToster(0);
                }, 2000);
            }

        } else {

            setShowUnderProcess(1);
            console.log("Object", obj);
            let response = await UpdateFamilyMemberHistory(obj);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setShowToster(1);
                setTosterMessage("Data Save SuccessFully!");
                setTosterValue(0);
                setTimeout(() => {
                    setShowToster(0);
                }, 2000);
                setUpdateBool(0);
                handleClear();
                handleGet();

            } else {
                setShowUnderProcess(0);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTosterValue(1);
                setTimeout(() => {
                    setShowToster(0);
                }, 2000);
            }

        }

    }

    let handleEdit = async (list) => {

        setRowId(list.id);
        setUpdateBool(1);
        setGeneralStatus(list.generalStatusId);
        setGeneraldate(list.generalDate);
        setName(list.name);
        setRelationShip(list.relationShipId);
        setGender(list.sexId);
        setDateOfBirth(list.dob);
        setAge(list.age);
        setAgeType(list.ageTypeID);
        setOutcome(list.outcome);
        setisDiseased(list.isDeceased);
        if (list.isDeceased === 1) {
            setDeceasedAge(list.deceasedAge);
            setDeceasedAgeType(list.deceasedAgeTypeId);
            setDeceasedDate(list.deceasedDate);
        }
        setOnsetAge(list.onsetAge);
        setOnsetAgeType(list.onsetAgeTypeId);
        setAdditionalInformation(list.additionalInformation);
    }




    useEffect(() => {
        FunGender();
        handleGet();
    }, [])


    const calculateAgeFromDOB = function (dob, unit) {
        console.log('dob', dob)
        if (!dob) { return }
        const todayDate = new Date();
        const birthDate = new Date(dob);
        if (unit === 1 || (!unit)) {
            const getAge = Math.floor((todayDate - birthDate) / 3.154e+10);
            return getAge;
        }
        else if (unit === 2) {
            const getYear = Math.floor((todayDate - birthDate) / 3.154e+10);
            const getMonth = (todayDate.getMonth() + 1) - (birthDate.getMonth() + 1);
            const getTotalMonth = (getYear * 12) + getMonth;
            return getTotalMonth;
        }
        else if (unit === 3) {
            const getDays = Math.floor((todayDate - birthDate) / 8.64e+7);
            return getDays;
        }
    };
    const calculateDOBFromAge = function (age, unit) {
        if (!age) { return; }
        const todayDate = new Date();
        if (parseInt(unit) === 1 || (!unit)) {
            const getYear = todayDate.getFullYear() - age;
            const dob = new Date(`${getYear}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`);
            return dob;
        }
        else if (parseInt(unit) === 2) {
            const getYear = Math.floor(age / 12);
            const getMonth = age % 12;
            const birthYear = todayDate.getFullYear() - getYear;
            const birthMonth = (todayDate.getMonth() + 1) - getMonth;
            const dob = new Date(`${birthYear}-${birthMonth}-${todayDate.getDate()}`);
            return dob;
        }
        else if (parseInt(unit) === 3) {
            const getDays = new Date(todayDate.getTime() - age * 24 * 60 * 60 * 1000);
            return getDays;
        }
    };
    const formatDateToYYYYMMDD = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }




    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <Heading text={t("Family Member Details")} />
                            <BoxContainer>
                                <div className="mb-1 me-2">
                                    <label htmlFor="caretakerName" className="form-label">{t("General Information")}<span className="starMandatory">*</span></label>
                                    <div className="SphereCustomrow">
                                        <div className="SphereCustom-inn">
                                            <span>Status </span>
                                            <select className="form-select form-select-sm" value={getGeneralStatus} id="dropdownGeneralStatus" onChange={handleGeneralStatus}>
                                                <option value="1">Partial</option>
                                                <option value="2">Completed</option>
                                                <option value="3">Entered-In-Error</option>
                                                <option value="4">Health-Unknown</option>
                                            </select>
                                        </div>
                                        <div className="SphereCustom-inn">
                                            <span>Date </span>
                                            <span>
                                                <input type="date" className="form-control form-control-sm" value={getGeneralDate} name="txtGeneralDate" onChange={handleChange} />
                                                <small id="errtxtGeneralDate" className="form-text text-danger" style={{ display: 'none' }}></small>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-1 me-2">
                                    <label htmlFor="caretakerName" className="form-label">{t("Family Member Details")}<span className="starMandatory">*</span></label>
                                    <div className="SphereCustomrow">
                                        <div className="SphereCustom-inn">
                                            <span>Enter Name </span>
                                            <input type="text" className="form-control form-control-sm" value={getName} name="txtName" onChange={handleChange} />
                                            <small id="errtxtName" className="form-text text-danger" style={{ display: 'none' }}></small>
                                        </div>

                                        <div className="SphereCustom-inn">
                                            <span>Relationship </span>
                                            <select className="form-select form-select-sm" value={getRelationShip} id="dropdownRelationship" onChange={handleRelationShip}>
                                                <option value="1">Parent</option>
                                                <option value="2">Sibling</option>
                                                <option value="3">Child</option>
                                                <option value="4">Other</option>
                                            </select>
                                        </div>
                                        <div className="SphereCustom-inn">
                                            <span>Sex </span>
                                            <select className="form-select form-select-sm" id="dropdownSex" value={getGender} onChange={handleGender}>
                                                <option value="0">{t("Select Gender")}</option>
                                                {getGenderList && getGenderList.map((val, index) => { return (<option value={val.id}>{val.name}</option>) })}
                                            </select>
                                        </div>
                                        <div className="SphereCustom-inn">
                                            <span>Date Of Birth </span>
                                            <span>
                                                <input type="date" className="form-control form-control-sm" value={getDateOfBirth} name="txtDateOfBirth" onChange={handleChange} />
                                                <small id="errtxtDateOfBirth" className="form-text text-danger" style={{ display: 'none' }}></small>
                                            </span>
                                        </div>
                                        <div className="SphereCustom-inn">
                                            <span>Age </span>
                                            <span>
                                                <input type="number" className="form-control form-control-sm" value={getAge} name="txtAge" onChange={handleChange} />
                                                <small id="errtxtAge" className="form-text text-danger" style={{ display: 'none' }}></small>
                                            </span>
                                        </div>
                                        <div className="SphereCustom-inn">
                                            <span>Age Type </span>
                                            <span>
                                                <select className="form-select form-select-sm" id="dropdownAgeType" value={getAgeType} onChange={handleAgeType}>
                                                    <option value="1">Year</option>
                                                    <option value="2">Month</option>
                                                    <option value="3">Day</option>
                                                </select>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-1 me-2">
                                    <label htmlFor="caretakerName" className="form-label">{t("Conditions")}<span className="starMandatory">*</span></label>
                                    <div className="SphereCustomrow">
                                        <div className="SphereCustom-inn">
                                            <span>Outcome </span>
                                            <span>
                                                <input type="text" className="form-control form-control-sm" value={getOutcome} name="txtOutcome" onChange={handleChange} />
                                                <small id="errtxtOutcome" className="form-text text-danger" style={{ display: 'none' }}></small>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-1 me-2">
                                    <label htmlFor="caretakerName" className="form-label">{t("Deceased")}<span className="starMandatory">*</span></label>
                                    <div className="SphereCustomrow">
                                        <div className="SphereCustom-inn">
                                            <span>Deceased</span>
                                            <span>
                                                {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/> */}
                                                <input type="checkbox" className="form-check-input" checked={isDiseased === 1 ? true : false} name="checkBoxDeceased" onChange={handleCheckboxChange} />
                                                <small id="errtxtIsDeceased" className="form-text text-danger" style={{ display: 'none' }}></small>
                                            </span>
                                        </div>
                                        {isDiseased === 1 ?
                                            <>
                                                <div className="SphereCustom-inn">
                                                    <span>Age </span>
                                                    <span>
                                                        <input type="number" className="form-control form-control-sm" value={getDeceasedAge} name="txtDeceasedAge" onChange={handleChange} />
                                                        <small id="errtxtDeceasedAge" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </span>
                                                </div>
                                                <div className="SphereCustom-inn">
                                                    <span>Age Type </span>
                                                    <select className="form-select form-select-sm" id="dropdowntxtDeceasedAgeType" value={getDeceasedAgeType} onChange={handleDeceasedAgeType}>
                                                        <option value="1">Year</option>
                                                        <option value="2">Month</option>
                                                        <option value="3">Day</option>
                                                    </select>
                                                </div>
                                                <div className="SphereCustom-inn">
                                                    <span>Date</span>
                                                    <span>
                                                        <input type="date" className="form-control form-control-sm" value={getDeceasedDate} name="txtDeceasedDate" onChange={handleChange} />
                                                        <small id="errtxtDeceasedDate" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </span>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>
                                <div className="mb-1 me-2">
                                    <label htmlFor="caretakerName" className="form-label">{t("Onset")}<span className="starMandatory">*</span></label>
                                    <div className="SphereCustomrow">
                                        <div className="SphereCustom-inn">
                                            <span>Age </span>
                                            <span>
                                                <input type="number" className="form-control form-control-sm" value={getOnsetAge} name="txtOnsetAge" onChange={handleChange} />
                                                <small id="errtxtOnsetAge" className="form-text text-danger" style={{ display: 'none' }}></small>
                                            </span>
                                        </div>
                                        <div className="SphereCustom-inn">
                                            <span>Age Type </span>
                                            <select className="form-select form-select-sm" id="dropdownOnsetAgeType" value={getOnsetAgeType} onChange={handleOnsetAgeType}>
                                                <option value="1">Year</option>
                                                <option value="2">Month</option>
                                                <option value="3">Day</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-1 me-2">
                                    <label htmlFor="caretakerName" className="form-label">{t("Information")}</label>
                                    <div className="SphereCustomrow">
                                        <span>Additional Instructions: </span>
                                        <span>
                                            <textarea className="form-control form-control-sm" name="txtAdditionalInformation" value={getAdditionalInformation} onChange={handleChange} ></textarea>
                                            {/* <input type="text" className="form-control form-control-sm" value={getAdditionalInformation} name="txtAdditionalInformation" onChange={handleChange} /> */}
                                            <small id="errtxtAdditionalInformation" className="form-text text-danger" style={{ display: 'none' }}></small>
                                        </span>
                                    </div>
                                </div>
                                <div className="mb-1 relative">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                    <div>
                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />

                                                    : <div>
                                                        {updateBool === 0 ?
                                                            <>
                                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleSave}><img src={saveButtonIcon} className='icnn' alt='' />{t("Save")}</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt='' />{t("Cancel")}</button>
                                                            </>
                                                            :
                                                            <>
                                                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={handleSave}>UPDATE</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { setUpdateBool(0); }}>{t("Cancel")}</button>
                                                            </>
                                                        }
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </BoxContainer>
                            <div className="col-12 mt-3">
                                <div className='handlser'>
                                    <Heading text={t("Family Member Details")} />
                                    <div style={{ position: 'relative' }}>
                                        <input type="text" className='form-control form-control-sm' placeholder={t("Search")} value={'searchTerm'} onChange={'handleSearch'} />
                                        <span className="tblsericon"><i className="fas fa-search"></i></span>
                                    </div>
                                </div>
                                <div className="med-table-section ipdtable" style={{ "height": "74vh" }}>

                                    <TableContainer>
                                        <thead>
                                            <tr>
                                                <th className="text-center" style={{ "width": "3%" }}>#</th>
                                                <th>{t("Status")}</th>
                                                <th>Date</th>
                                                <th>Name</th>
                                                <th>Relation</th>
                                                <th>{t("Sex")}</th>
                                                <th>{t("Date Of Birth")}</th>
                                                <th>{t("Age")}</th>
                                                <th>{t("Outcome")}</th>
                                                <th style={{textAlign:'left'}}>{t("Deceased")}</th>
                                                <th>{t("Onset Age")}</th>
                                                <th>{t("Additional Information")}</th>
                                                <th>{t("User Name")}</th>
                                                <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {getFamilyMemberDetailsList
                                                && getFamilyMemberDetailsList.filter((val) => `${val.generalStatus}`.toLowerCase().includes(searchTerm.toLowerCase())).map((val, ind) => {
                                                    return (
                                                        <tr key={val.id}>
                                                            <td className="text-center">{ind + 1}</td>
                                                            <td>{val.generalStatus}</td>
                                                            <td>{val.generalDate}</td>
                                                            <td>{val.name} </td>
                                                            <td> {val.relationShip}</td>
                                                            <td>{val.sex}</td>
                                                            <td> {val.dob}</td>
                                                            <td>{val.age}  {val.ageType} </td>
                                                            <td> {val.outcome}</td>
                                                            <td style={{whiteSpace:'nowrap',textAlign:'left'}}>
                                                            <strong>Deceased :&nbsp;</strong> {val.isDeceased === 1 ? 'Yes' : 'No'} <br />
                                                                <strong>Age:&nbsp;</strong> {val.deceasedAge} {val.deceasedAgeType}<br />
                                                                <strong>Date:&nbsp;</strong> {val.deceasedDate}
                                                            </td>
                                                            <td>{val.onsetAge} {val.onsetAgeType}</td>
                                                            <td style={{maxWidth:'200px', wordBreak:'break-all'}}>{val.additionalInformation}</td>
                                                            <td> {val.userName}</td>
                                                            <td>
                                                                <div className="action-button">
                                                                    <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={IconEdit} onClick={() => { handleEdit(val) }} alt='' /></div>
                                                                    <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={IconDelete} onClick={() => { setRowId(val.id) }} alt='' /></div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}

                                        </tbody>
                                    </TableContainer>
                                    <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                                        <div className="modal-dialog modalDelete">
                                            <div className="modal-content">

                                                <div className="modal-body modelbdy text-center">
                                                    <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                    <div className='popDeleteTitle mt-3'>{t("Delete?")}</div>
                                                    <div className='popDeleteContent'>{t("Are_you_sure_you_want_to_delete?")}</div>
                                                </div>
                                                <div className="modal-footer1 text-center">

                                                    <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                                                    <button type="button" className="btn-delete popBtnDelete" onClick={handleDeleteRow} data-bs-dismiss="modal">{t("Delete")}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


