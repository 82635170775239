import React from 'react'
import printuse from '../../../src/assets/css/printuse.css'

// import SarvodayaLogo from '../../../assets/images/icons/sarvodayalogo.png'

export default function InformedConsentForSedation() {
    return (
        <>
            <div className="main-table" style={{ padding: '10px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <table className='microtble'>
                    {/* ....header.. */}
                    <tr>
                        <td colSpan={8}>
                            <table>
                                <tr>
                                    <td>
                                        <div className="sarvodya-header">
                                            <div className='lft-header'>
                                                {/* <img src={sarvodayalogo} alt='' className='sv-log' /> */}
                                            </div>
                                            <div className='rght-header mt-2'>
                                                <div> SARVODAYA HOSPITAL <div className='addrs'>SECTOR-8, FARIDABAD | HELPLINE: 1800 313 1414</div></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td colSpan={8} style={{ verticalAlign: 'top', textAlign: 'center' }}>
                            <div className='micro-heading mt-2 mb-2'>
                                INFORMED CONSENT FOR SEDATION
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <table className='formdetails '>
                                <tr>
                                    <td>
                                        <div className='nursingshiftt mt-1'>
                                            <div><strong>Patient Full Name : </strong> <input type="text" id='Patient_Name' /></div>
                                            <div><strong>MR NO : </strong> <input type="text" id='Mr_No' /></div>
                                            <div><strong>Age/Sex : </strong> <input type="text" id='Age_Sex' /></div>
                                            <div><strong> Weight : </strong> <input type="text" id='Waightt' /></div>
                                            <div><strong>Patient Full Name : </strong> </div>
                                            <div><strong>MR NO : </strong> <span style={{ width: '60px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div>
                                            <div><strong>Age/Sex : </strong> <span style={{ width: '60px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div>
                                            <div><strong> Weight : </strong> <span style={{ width: '60px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div>
                                        </div>
                                        <div className='nursingshiftt mt-2'>
                                            <div><strong>Diagnosis : </strong> <input type="text" id='Diagnosis' /></div>
                                            <div> <strong>Procedure : </strong>  <input type="text" id='Procedure' /></div>
                                            <div><strong> Admitting Doctor : </strong> <input type="text" id='Admitting_Doctor' /></div>
                                        </div>
                                        <div className='nursingshiftt mt-2 mb-1'>
                                            <div><strong>Time of Arrival : </strong><input type="text" id='Time_of_Arrival' /></div>
                                            <div><strong>Time of Assessment : </strong> <input type="text" id='Time_of_Assessment' /></div>
                                            <div><strong>Name of Anaesthetist : </strong> <input type="text" id='Name_of_Anaesthetist'/></div>
                                        </div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='parainfo mt-4'><strong>I</strong>, the patient &nbsp;<span style={{ width: '160px', borderBottom: '1px dashed black', display: 'inline-block' }}><strong>sabi</strong></span> or Representative
                                &nbsp; <span style={{ width: '160px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span> have (please tick the correct
                                option above and below)</div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='parainfo2'>
                                <div> <input type="checkbox" /> </div>
                                <div>  <label> Read </label></div>
                            </div>
                            <div className='parainfo2'>
                                <div> <input type="checkbox" /> </div>
                                <div>  <label> I/we have been explained the current clinical condition of me/my patient. </label></div>
                            </div>
                            <div className='parainfo2'>
                                <div> <input type="checkbox" /> </div>
                                <div><label> Been explained this consent form in English, <span style={{ width: '160px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span> which I fully understand, that I would require sedation services for getting above procedure performed. </label></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='paragraphinfo mt-3'>
                                I understand that sedation is a drug induced state of reduced awareness and decreased ability to respond. Sedation is not sleep. I may be able to respond during the procedure. My ability to respond normally returns when the effects of the sedatives wear off.
                            </div>
                            <div className='hourlydetils mt-3'>
                                <strong> I have been explained about the intended benefits of undergoing sedation. The intended benefits from this sedation are:</strong>
                            </div>
                            <div className='hourlylist'>
                                <ul>
                                    <li>Pain is lessened or eliminated during the procedure</li>
                                    <li>Stress and anxiety are greatly reduced</li>
                                    <li>Comfortable throughout the procedure</li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='hourlydetils mt-3'>
                                <strong>I understand that there are risks or limitations of sedation these include:</strong>
                            </div>
                            <div className='hourlylist'>
                                <ul>
                                    <li><strong>Aspiration</strong>:If your stomach is not empty (fasting for less than 6 hours) you may aspirate food which can lodge in your lungs
                                    </li>
                                    <li><strong>Apnea</strong>: Unable to take spontaneous breath will require artificial ventilator</li>
                                    <li><strong>Airway Obstruction</strong>: you could get an airway obstruction if something (possibly your tongue) blocks or covers your airway . This would make it hard to breathe.</li>
                                    <li><strong>Bronchospasm: </strong>: You could feel a sudden tightening around the airway muscle, making it hard to breathe.</li>
                                    <li><strong>Drug reaction</strong>: You could have pain, bruising or some clotting on the surface where the IV is placed. Allergic reaction to drug can also occur.</li>
                                    <li><strong>Oxygen Desaturation</strong>: Your oxygen levels can fall below normal and you would have to be given more oxygen. • Others: Nausea, Vomiting, Hypotension, Prolonged Sedation, Aspiration.</li>
                                    <li><strong>Others</strong> : Nausea, Vomiting, Hypotension, Prolonged Sedation, Aspiration</li>
                                    <li><strong>Rare</strong> : Stroke, Brain Damage, Heart Attack, Cardiac Arrest</li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8} className='pt-0 mb-0 ps-4'>
                            <div className='riskpara '>
                                <strong>Risk: <span style={{ width: '500px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span></strong>

                                <div className='lines'></div>
                                <div className='lines'></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='paragraphinfo mt-2'>
                                I understand that if any of the above-mentioned event occurs or even the rare possibility of these event, the anesthesiologist has all the rights to convert sedation into general anesthesia. I give consent for the same.                            </div>

                            <div className='hourlydetils mt-2'>
                                <strong>I understand that the alternatives to sedation are :</strong>
                            </div>
                            <div className='hourlylist mt-2'>
                                <ul>
                                    <li><strong>No Sedation</strong>: The necessary procedure is performed under local anesthetic with the patient fully aware.
                                    </li>
                                    <li><strong>General Anesthetic</strong>:  A patient under general anesthetic has no awareness and must have their breathing temporarily supported with ventilator machine.</li>

                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ pageBreakBefore: 'always' }}>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-4'>
                                I have also been explained about all possible problems related to recovery and understand that sometimes admission to an Intensive Care Unit and/or extension of duration of hospitalization may be required and/or there may be requirement of extra medicines or treatments, thereby leading to increase in the treatment expenses, depending upon the body's response to the sedation.
                            </div>
                            <div className='paragraphinfo mt-2'>
                                I also understand that I must follow all the recommended treatments and instructions of my doctor. I have informed doctor all my previous illnesses, drug reactions and other relevant facts.                            </div>
                            <div className='paragraphinfo mt-4'>
                                I declare that I have received and fully understood the information provided in this consent form, that I have been given opportunity to ask questions relating to sedation procedure, its risks, consequences, alternatives, potential complications and intended benefits, and that all my questions have been answered to my entire satisfaction.
                            </div>
                            <div className='paragraphinfo mt-4'>
                                Post sedation Education:
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-4'>
                                <strong>Children</strong> Supervise your child closely for next eight hours. No food or drink for two hours. No bath, no play that requires balance, strength, and coordination for 12 hours, no use of electrical devices for eight hours.
                            </div>
                            <div className='paragraphinfo mt-2'>
                                <strong>Adults </strong> Supervise your child closely for next eight hours. No food or drink for two hours. No bath, no play that requires balance, strength, and coordination for 12 hours, no use of electrical devices for eight hours.
                            </div>
                            <div className='paragraphinfo mt-2 mb-4'>
                                I have read this consent and understand and agree to proposed plan of sedation. I am giving consent voluntarily without any coercion, misinterpretation, or undue influence.                            </div>

                        </td>
                    </tr>                

                    <tr>
                        <td colSpan={8} className='pe-0 ps-0 mt-0 mb-0 pt-0 pb-0' style={{ border: '0' }}>
                            <table className="ptents-tbl className='mt-0 pt-0'">
                                <tr>
                                    <th></th>
                                    <th >Signature/Thumb Impression</th>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                </tr>
                                <tr>
                                    <td>Patient</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td> Surrogate Guardian
                                        <br />
                                        (if applicable#) </td>
                                    <td></td>
                                    <td>(Write name and relationship with patient)</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Reason for   <br />
                                        Surrogate consent</td>
                                    <td colSpan={4}></td>
                                </tr>
                                <tr>
                                    <td> Witness </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td> Interpreter <br /> (if applicable) </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan={5}>
                                        <div><strong>*</strong>Right Hand for Males & left-hand females # Only if Patient is minor or unable to give consent</div>
                                        <div className='mt-4'>I, the undersigned doctor have explained the nature, potential risks and complications, intended benefits, expected post procedure course, and possible alternatives to the planned operation/ procedure, to the patient /patient representative. I am confident that he/she has understood the information fully as described in this document.
                                            Consent obtained Signature</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} className='ps-0 pe-0 pb-0 pt-0'>
                                        <table className=''>
                                            <tr>
                                                <th> Consent obtained by Doctor </th>
                                                <th>Signature</th>
                                                <th>Name</th>
                                                <th>Reg. No:</th>
                                                <th>Date:</th>
                                                <th>Time:</th>
                                            </tr>
                                            <tr>
                                                <td> sabi </td>
                                                <td> sabi </td>
                                                <td> sabi </td>
                                                <td> 290707 </td>
                                                <td> 12-03-24 </td>
                                                <td> 2:00 pm </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                </table>
            </div>
        </>
    )
}
