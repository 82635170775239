import React from 'react'
import printuse from '../../../src/assets/css/printuse.css'
// import SarvodayaLogo from '../../../assets/images/icons/sarvodayalogo.png'

export default function ConsentFormMagneticResonanceMRI() {
    return (
        <>
            <div className="main-table" style={{ padding: '10px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}
                <table className='microtble'>

                    {/* ....header.. */}
                    <tr>
                        <td colSpan={8}>
                            <div className="sarvodya-header">
                                <div className='lft-header mb-3'>
                                    {/* <img alt='' className='sv-log'/>   */}
                                </div>
                                <div className='rght-header'>
                                    <div> SARVODAYA HOSPITAL <div className='addrs'>SECTOR-8, FARIDABAD | HELPLINE: 1800 313 1414</div></div>
                                </div>
                            </div>

                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td colSpan={8}>
                            <div className='heading-form mt-4 mb-4'>
                                CONSENT FORM - MAGNETIC RESONANCE IMAGING (MRI)
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <table className='tbl-bordr mt-0' style={{ border: '1px solid black' }}>
                                <tr>
                                    <td colSpan={2}><div className='assesmnt mt-3 mb-3'>Date of Consent: <input type="text" id='Date_of_Consent' />  </div></td>
                                    <td rowSpan={4} style={{ borderLeft: '1px solid black', textAlign: 'center' }}>Patients Label</td>
                                </tr>
                                <tr>
                                    <td ><div className='assesmnt mt-2 mb-2'>Time of Consent: <input type="text" id='Time_of_Consent' />  </div></td>
                                    <td ><div className='assesmnt mt-2 mb-2'>Ward.:<input type="text" id='Ward_of_Consent' /> </div></td>
                                </tr>
                                <tr>
                                    <td ><div className='assesmnt mt-3 mb-3'>Diagnosis:  <input type="text" id='Diagnosis_of_Consent' /> </div></td>
                                    <td ><div className='assesmnt mt-3 mb-3'>Procedure:  <input type="text" id='Procedure_of_Consent' />  </div></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <table className='mt-1 mb-1'>
                                <th colSpan={2}>Date</th>
                                <th colSpan={4} style={{ textAlign: 'center' }}>Admitting Consultant:</th>
                                <th colSpan={2} style={{ textAlign: 'center' }}>Time of Arrival:</th>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='parainfo mt-4'><strong>I</strong>, <span style={{ width: '190px', borderBottom: '1px dashed black', display: 'inline-block' }}><strong>sabi</strong></span> or Representative
                                &nbsp; <span style={{ width: '180px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span> of Patient have ,</div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='parainfo2 mt-2'>
                                <div> <input type="checkbox" /> </div>
                                <div>  <label> Read & Understood </label></div>
                            </div>
                            <div className='parainfo2'>
                                <div> <input type="checkbox" /> </div>
                                <div>  <label>I/We have been explained <br />my/my patient's current clinical condition. </label></div>
                            </div>
                            <div className='paragraphinfo mt-3'>
                                I have also been explained this consent form in English/Hindi <span style={{ width: '100px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span>   (Other Language specify)which I fully.
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-1'>
                                I have been informed that I need to undergo  <span style={{ width: '350px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  MAGNETIC RESONANCE IMAGING (MRI).</strong></span>   understand.
                            </div>
                            <div className='paragraphinfo mt-2'>
                                I have been explained that MRI (Magnetic Resonance Imaging) is a diagnostic imaging procedure to obtain additional information that may aid in diagnosing and treating medical conditions. MRI does not use x-rays or radiation, instead its uses a magnetic field and radio waves are used to create an image of internal body structures.
                            </div>
                            <div className='paragraphinfo mt-2'>
                                MRI is a painless procedure that requires, you lie quietly on a padded table, and which gently glides into the magnet. While the scanner is performing your scan, you will hear some humming and thumping sounds. These are normal and should not worry you. In some cases, a contrast agent may be injected into your vein in order to give a clearer image of the area being examined.                            </div>

                        </td>
                    </tr>               
                    <tr>
                        <td>
                        <div className='hourlydetils mt-2'>
                                <strong>Intended Benefits :</strong> The benefit of the study is that your doctor will use the results to
                            </div>
                            <div className='hourlylist'>
                                <ul>
                                    <li> To helps in better .<strong>diagnosis</strong>    </li>
                                    <li> Generally, painless  </li>
                                    <li> Does not use radiation          </li>
                                </ul>
                            </div>
                            <div className='hourlydetils mt-2'>
                                <strong>All procedures carry certain drawbacks. Possible Risk sand Complications for this procedure are:</strong>
                            </div>
                            <div className='hourlylist'>
                                <ul>
                                    <li> Allergic reaction may occur from administration of contrast agent  </li>
                                    <li> Anxiety  </li>
                                    <li> Any Other <span style={{ width: '320px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span>      </li>
                                </ul>
                            </div>
                            <div className='hourlydetils mt-2'>
                                <strong>Possible Alternatives:</strong> I have been explained and also been suggested the alternatives Investigations such as,
                            </div>
                            <div className='hourlylist'>
                                <ul>
                                    <li> <strong>Computed Tomography </strong> </li>
                                    <li> <strong>Ultrasonography (USG) </strong></li>

                                </ul>
                            </div>
                            <div className='paragraphinfo mt-2'>
                                However, I understand the limitations of these alternatives as explained by my doctor.
                            </div>
                        </td>
                    </tr>       
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-2'>
                                <strong><span> <input type="checkbox" /></span> USE OF CONTRAST AGENT (tick if applicable):</strong> I have been explained that a contrast medium may be used (injectable route or per rectal or intrathecal or intra articular route) during the investigation. I understand that contrast media usually contains gadolinium to which some patients may be allergic to. Though safe, contrast media can cause allergic reactions. These reactions are usually mild (like rash, itching, redness, nausea, etc) but may rarely be serious and life threatening and one may need emergency attention including intubation and admission to ICU. There is possibility of delayed reaction within skin in patient with renal impairment and gadolinium retention.

                            </div>
                        </td>
                    </tr>

                </table>

            </div>
        </>
    )
}
