async function GetStateMaster(id) {
    // let url = window.HRServiceUrl+ `/api/CityMaster/GetCityMasterByStateId`;
    // 172.16.61.31:7083/api/CityMaster/GetCityMasterByStateId
    let url = window.AdminbaseUrl+ `/api/StateMaster/GetStateMasterByCountryId?id=${id}`;
   
  let head = { "Content-Type": "application/JSON", accept : '*/*' };
  
  let data = {};
  let response = fetch(url, {
    headers: head,
    method : 'GET'
  })
    .then((res) => res.json())
    .then(data);
  
  return response;
  }
  export default GetStateMaster;