const GetAllTrackItemByDemandId = async (demandId, clientId, theStat) => {
   const url = window.InventoryBaseUrl+ '/api/InventoryRaiseDemand/GetTrackDemandByDemandID?ClientID='+clientId+'&GetDemandID='+demandId+'&AllotStatus='+theStat;
  //  const url = 'https://localhost:7264/api/InventoryRaiseDemand/GetTrackDemandByDemandID?ClientID='+clientId+'&GetDemandID='+demandId+'&AllotStatus='+theStat;
  const head = {'content-type':'application/json','accept':'*/*'};
  const data ={};
  const response = await fetch(url,{
    method:'GET',
    header: head,
  }).then(res => res.json()).then(data);
  return response;
}
export default GetAllTrackItemByDemandId;