import React, { useEffect, useState } from 'react'
import GetPatientDetailsforSarvodaya from './API/GetPatientDetailsforSarvodaya'
import GetInvestigationDetails from './API/GetInvestigationDetails';
import Loader from '../../Component/Loader';
import SuccessToster from '../../Component/SuccessToster';
import AlertToster from '../../Component/AlertToster';
import Heading from '../../Component/Heading';
import { t } from 'i18next';
import DropdownWithSearch from '../../Component/DropdownWithSearch';
import ProvisonalDiagnosisMainComponent from './ProvisonalDiagnosisMainComponent';
import TosterUnderProcess from '../../Component/TosterUnderProcess';
import save from '../../assets/images/icons/save.svg';
import SarIconupdateicon from '../../assets/images/icons/SarIconupdateicon.svg';
import clear from '../../assets/images/icons/clear.svg';
import Toster from '../../Component/Toster';
import InsertProcedureWithoutSedation from './API/ProcedureWithoutSedation/InsertProcedureWithoutSedation';
import IPDAllergiesComponent from '../../Clinical/Pages/Component/IPDAllergiesComponent';
import GetQuestionsBySet from './API/ProcedureWithoutSedation/GetQuestionsBySet';
import { json } from 'react-router-dom';
import MedicineProcedure from './MedicineProcedure';
import InsertQuestionnaire from './API/ProcedureWithoutSedation/InsertQuestionnaire';
import InteraProceduralMedication from '../../Clinical/Pages/IPD/IPDSharePages/ICUChart/InteraProceduralMedication';
import PostSedationMedication from './API/ProcedureWithoutSedation/PostSedationMedication';
import InsertDiagnosisPreSedation from './API/ProcedureWithoutSedation/InsertDiagnosisPreSedation';
import UpdateDiagnosisWithoutSedation from './API/ProcedureWithoutSedation/UpdateDiagnosisWithoutSedation';
import GetAllProcedureWithoutSedation from './API/ProcedureWithoutSedation/GetAllProcedureWithoutSedation';
import ProcedureMaster from './API/ProcedureMaster';
import SedationMedicationComponent from '../../Clinical/Pages/IPD/IPDSharePages/ICUChart/SedationMedicationComponent';
import DiagnosisForSedation from './DiagnosisForSedation';
import UpdateProcedureStatus from './API/ProcedureWithoutSedation/UpdateProcedureStatus';
import PatientDeatils from '../../Component/PatientDeatils';

export default function ProcedureWithoutSedation() {

    const userId = JSON.parse(sessionStorage.getItem("LoginData")).userId;
    const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    const getPmID = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].pmId;

    let [showLoder, setShowLoder] = useState(0);
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    const [showUnderProcess, setShowUnderProcess] = useState(0);
    const [showToster, setShowToster] = useState(0);
    const [tosterMessage, setTosterMessage] = useState("");
    const [tosterValue, setTosterValue] = useState(0);

    let [saveDiagnosis, setSaveDiagnosis] = useState(0);
    let [saveSedationDiagnosis, setSaveSedationDiagnosis] = useState([]);
    let [savePostSedationDiagnosis, setSavePostSedationDiagnosis] = useState([]);
    let [isPreDiagnisdisable, setIsPreDiagnisdisable] = useState(true);
    let [isPostDiagnisdisable, setIsPostDiagnisdisable] = useState(false);
    let [isQuestioniredisable, setIsQuestioniredisable] = useState(false);
    let [saveAllergie, setSaveAllergies] = useState(0)

    const [patientData, setPatientData] = useState();
    let [procedureList, setProcedureList] = useState([])
    let [clearDropdown, setClearDropdown] = useState(0);
    let [editProcedure, setEditProcedure] = useState('');

    const [timeoutChkList, setTimeoutChkList] = useState([]);
    const [questionList, setQuestionList] = useState([]);
    const [optionList, setOptionList] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [categoryId, setCategoryId] = useState();
    const [medicationData, setMedicationData] = useState([])
    let [showMessage, setShowMeassage] = useState("")
    let [updateBool, setUpdateBool] = useState(0);

    let [sedationList, setSedationList] = useState([])
    let [questionireList, setQuestionireList] = useState()
    let [preProcedureList, setPreProcedureList] = useState("");
    let [postProcedureList, setPostProcedureList] = useState("");
    let [allergyList, setAllergyList] = useState()
    let [allergyJSONList, setAllergyJSONList] = useState()
    let [diagnosisList, setDiagnosisList] = useState()
    let [prescriptionList, setPrescriptionList] = useState()
    let [rowId, setRowId] = useState("")
    // console.log("timeoutChkList--->", timeoutChkList)
    // console.log("questionList", questionList)
    // console.log("optionList", optionList)


    const [sendForm, setSendForm] = useState({
        isProcedureDone: 1,
        procedureId: 0,
        procedureIndication: "",
        procedureDate: "",
        assesmentTime: "",
        userId: window.userId,
        clientId: window.clientId
    })

    //Get Sedation Details
    // const getSedation = async () => {
    //     let response = await GetAllProcedureWithoutSedation(uhID, clientID)
    //     if (response.status === 1) {
    //         setSedationList(response.responseValue)
    //         const questions = response.responseValue[0]?.questionaire?.answers ? JSON.parse(response.responseValue[0].questionaire.answers) : [];
    //         const prePostProcedureLists = response.responseValue[0]?.prePostProcedure[0] || [];

    //         const diagnosisData = response.responseValue[0]?.diagnosis || [];
    //         const allergiesData = response.responseValue[0]?.allergies || [];

    //         const allergyJSONData = allergiesData.map(allergy => JSON.parse(allergy.allergiesJson));
    //         const prescriptionData = response.responseValue[0]?.prescription || [];
    //         setPrescriptionList(prescriptionData);
    //         setAllergyJSONList(allergyJSONData);

    //         setDiagnosisList(diagnosisData);
    //         setAllergyList(allergiesData);

    //         setQuestionireList(questions);

    //         setPrePostProcedureList(prePostProcedureLists);
    //         console.log("sedationList", response.responseValue);
    //         console.log("questionireList", questions);
    //         console.log("prePostProcedureList", prePostProcedureLists);
    //         console.log("diagnosisList", diagnosisData);
    //         console.log("allergyList", allergiesData);
    //         console.log("allergyJSONList", allergyJSONData);
    //         console.log("prescriptionList", prescriptionData);
    //     }
    // }
    const getSedation = async () => {
        let response = await GetAllProcedureWithoutSedation(uhID, clientID);
        if (response.status === 1) {
            setSedationList(response.responseValue);
            const questions = response.responseValue[0]?.questionaire?.answers ? JSON.parse(response.responseValue[0].questionaire.answers) : [];
            const prePostProcedureLists = response.responseValue[0]?.prePostProcedure || [];
            const diagnosisData = response.responseValue[0]?.diagnosis || [];
            const allergiesData = response.responseValue[0]?.allergies || [];
            const allergyJSONData = allergiesData.map(allergy => JSON.parse(allergy.allergiesJson));
            const prescriptionData = response.responseValue[0]?.prescription || [];
            setPrescriptionList(prescriptionData);
            setAllergyJSONList(allergyJSONData);
            setDiagnosisList(diagnosisData);
            setAllergyList(allergiesData);
            setQuestionireList(questions);
            var preData = "";
            var postData = "";
            prePostProcedureLists.map((arr) => {
                if (arr.preProcedureDiagnosis) {
                    preData = preData.length === 0 ? arr.preProcedureDiagnosis : preData + ', ' + arr.preProcedureDiagnosis;
                }
            });
            prePostProcedureLists.map((arr) => {
                if (arr.postProcedureDiagnosis) {
                    postData = postData.length === 0 ? arr.postProcedureDiagnosis : postData + ', ' + arr.postProcedureDiagnosis;
                }
            });
            setPreProcedureList(preData);
            setPostProcedureList(postData);

        }
    }

    // GetTimeOut Set List
    const getTimeoutChkList = async () => {
        let setId = 2;
        let response = await GetQuestionsBySet(setId);
        if (response.status === 1) {
            setTimeoutChkList(response.responseValue);
            setQuestionList(response.responseValue.questions);
            setOptionList(response.responseValue.options);

            console.log("questionList", response.responseValue)
            let categoryID = response.responseValue.questions.categoryID;
            setCategoryId(categoryID)
        }
    }

    //Get Patient Details
    const getPatientData = async () => {
        let data = await GetPatientDetailsforSarvodaya(uhID)
        if (data.status === 1) {
            setPatientData(data.responseValue[0])
        }
    }

    //GEt Procedure
    let getProcedure = async () => {
        let items = await ProcedureMaster()
        if (items.status === 1) {
            setProcedureList(items.responseValue)
        }
    }

    //Handle Change
    let handleChange = async (e) => {
        const { name, value, checked } = e.target;
        if (name === "isProcedureDone") {
            setSendForm((prevData) => ({
                ...prevData,
                [name]: checked,
            }));
        } else {
            setSendForm((prevData) => ({
                ...prevData,
                [name]: value,
                "userId": window.userId,
                "clientId": window.clientId
            }));
        }
    };

    // Function to handle checkbox click

    // const handleMedicationDataUpdate = (temp, data) => {
    //     setMedicationData(temp, data);
    // };


    const handleCheckboxChange = (questionId, option) => {
        let dataContainer = [...selectedOptions];
        const optionID = 'optType' + questionId + option.id + '' + option.optionText;
        const getValue = document.getElementById(optionID).checked;
        let index = dataContainer.findIndex((arr) => arr.questionID === questionId);
        if (index !== -1) {
            document.getElementById('optType' + dataContainer[index].questionID + dataContainer[index].optionID + '' + dataContainer[index].optionText).checked = false;
            dataContainer.splice(index, 1);
            if (getValue) {
                dataContainer.push({
                    questionID: questionId,
                    optionID: option.id,
                    optionText: option.optionText,
                    optionTextValue: option.optionText,
                    userID: window.userId
                });
            }
        }
        else {
            dataContainer.push({
                questionID: questionId,
                optionID: option.id,
                optionText: option.optionText,
                optionTextValue: option.optionText,
                userID: window.userId
            });
        }
        console.log('object arr', dataContainer)
        setSelectedOptions(dataContainer);
    };

    //Save Data
    const handleSave = async () => {
        setSaveDiagnosis(1)
        setSaveAllergies(1)
        // const isProcedureDoneData = document.getElementById("isProcedureDone").checked;
        const objSedation = {
            uhid: uhID,
            pmId: getPmID,
            isProcedureDone: 0,
            procedureId: sendForm.procedureId,
            procedureIndication: sendForm.procedureIndication,
            procedureDate: sendForm.procedureDate,
            assesmentTime: sendForm.assesmentTime,
            userId: userId,
            clientId: clientID
        };

        const questionaireObj = {
            uhID: uhID,
            setID: 2,
            pmID: getPmID,
            categoryID: 2,
            questionaireSetID: "2",
            questionnaireDateTime: sendForm.questionnaireDateTime,
            jsonData: JSON.stringify(selectedOptions)
        }
        // const medJson = {
        //     uhId: uhID,
        //     pmID: getPmID,
        //     jsonarray: JSON.stringify(medicationData),
        //     userId: userId,
        //     clientId: clientID
        // }
        const preDiagnosisObj = {
            uhId: uhID,
            pmID: getPmID,
            diagnosis: JSON.stringify(saveSedationDiagnosis),
            userId: userId,
            clientId: clientID
        }

        // console.log("objSedation", objSedation)
        // console.log("questionaireObj", questionaireObj)
        // return;
        // if (sendForm.procedureID === '' || sendForm.procedureID === 0 || sendForm.procedureID === null || sendForm.procedureID === undefined) {
        //   document.getElementById('errProcedure').innerHTML = "Select procedure";
        //   document.getElementById('errProcedure').style.display = "block";
        // }
        const response = await InsertProcedureWithoutSedation(objSedation);

        if (response.status === 1) {
            const responseNew = await InsertQuestionnaire(questionaireObj);
            // let medData = await PostSedationMedication(medJson)
            let preDiagnosis = await InsertDiagnosisPreSedation(preDiagnosisObj)
            console.log("preDiagnosisObj", preDiagnosisObj)
            // return
            setShowUnderProcess(0);
            setTosterValue(0);
            setShowToster(1);
            setTosterMessage("Data saved successfully..");
            getSedation();
            setTimeout(() => {
                setShowToster(0);
                // handlePrintAssessment();
                handleClear();
            }, 1500)
        }
        else {
            setShowUnderProcess(0);
            setTosterValue(1);
            setShowToster(1);
            //   handlePrintAssessment();
            setTosterMessage(response.responseValue);
            // setTosterMessage(response.responseValue || responseNew.responseValue || "An error occurred while saving data.");
            setTimeout(() => {
                setShowToster(0);
            }, 1500)
        }
        // if (responseNew.status !== 0) {
        //     setShowUnderProcess(0);
        //     setTosterValue(1);
        //     setShowToster(1);
        //     setTosterMessage(responseNew.responseValue);
        //     setTimeout(() => {
        //         setShowToster(0);
        //     }, 1500)
        // }
    }


    // get data for update 
    // let handleUpdate = (sedation) => {
    //     setUpdateBool(1)
    //     if (prePostProcedureList.preProcedureDiagnosis.length !== 0) {
    //         setIsPostDiagnisdisable(true)
    //         setIsPreDiagnisdisable(false)
    //     }
    //     else {
    //         setIsPostDiagnisdisable(false)
    //         setIsPreDiagnisdisable(true)
    //     }
    //     setRowId(sedation.id)

    // }

    let handleUpdate = (sedation) => {
        setUpdateBool(1);
        if (sedation.prePostProcedure.length !== 0 && sedation.prePostProcedure[0].preProcedureDiagnosis) {
            setIsPostDiagnisdisable(true);
            setIsPreDiagnisdisable(false);
        } else {
            setIsPostDiagnisdisable(false);
            setIsPreDiagnisdisable(true);
        }
        setIsQuestioniredisable(true)
        setRowId(sedation.id);
    }

    //Handle Update
    const handlerUpdate = async (sedation) => {
        let temp = JSON.parse(window.sessionStorage.getItem("IPDpatientList"))
        let doctorId = 0
        temp.forEach((val, ind) => {
            if (val.uhId === uhID) {
                doctorId = val.doctorId
            }
        })
        const updateObj = {
            uhId: uhID,
            pmID: getPmID,
            diagnosis: JSON.stringify(savePostSedationDiagnosis),
            userId: userId,
            clientId: clientID,
            doctorId: doctorId,

        }
        const updatestatus = {
            id: rowId,
        }
        setShowUnderProcess(1);
        const response = await UpdateDiagnosisWithoutSedation(updateObj);
        if (response.status === 1) {
            const responseStatus = await UpdateProcedureStatus(updatestatus);
            setShowUnderProcess(0);
            setTosterValue(0);
            setShowToster(1);
            setTosterMessage("Updated Successfully..");
            getSedation();
            setTimeout(() => {
                setShowToster(0);
                handleClear();

            }, 1500)
        }
        else {
            setShowUnderProcess(0);
            setTosterValue(1);
            setShowToster(1);
            setTosterMessage(response.responseValue);
            setTimeout(() => {
                setShowToster(0);
            }, 1500)
        }
    }


    //Handle Clear
    let handleClear = (value) => {
        setClearDropdown(value);
        setUpdateBool(0)
        setEditProcedure(0);
        setIsQuestioniredisable(false)
        setIsPostDiagnisdisable(false);
        setIsPreDiagnisdisable(true);
        setSendForm({
            // isProcedureDone: ,
            procedureId: 0,
            procedureIndication: "",
            procedureDate: "",
            assesmentTime: "",
            userId: window.userId,
            clientId: window.clientId
        });
        const checkboxes = document.querySelectorAll('.clrchk input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });

        setSelectedOptions([]);
    };
    const getCurrentDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const getCurrentTime = () => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };
    const handlePrintSedation = (index) => {
        // console.log('param', param);
        let obj = {
            sedationList: sedationList[index]
        }
        console.log("aaaaaa", obj)
        // return
        // setRowId(param.id)
        window.sessionStorage.setItem('sedationList', JSON.stringify(obj));
        window.open("/ReceiptProcedureWithoutSedationPrint/", 'noopener,noreferrer')
    };

    const formatTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedMinutes = (minutes < 10 ? '0' : '') + minutes;
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}:${formattedMinutes} ${ampm}`;
    };

    // useEffect(() => {
    // }, [])
    useEffect(() => {
        getSedation();
        getPatientData();
        getProcedure();
        getTimeoutChkList();
        setSendForm(prevData => ({
            ...prevData,
            procedureDate: getCurrentDate()
        }));
        setSendForm(prevData => ({
            ...prevData,
            procedureDate: getCurrentDate(),
            assesmentTime: `${getCurrentDate()} ${getCurrentTime()}`
        }));
    }, [])
    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <Heading text='Procedure Without Sedation' id='top' />
                            <PatientDeatils />

                            <div className="fieldsett-in">
                                <div className="fieldsett">
                                    <span className='fieldse'>{t("Assessment Details")}</span>
                                    <div className="inner-content mt-2">
                                        <div className="d-flex flex-wrap align-content-end gap-3">
                                            {/* <div className="mb-2 me-2">
                                                <label htmlFor="admissionDate" className="form-label">Date of Admission<span className="starMandatory"></span></label>
                                                <input id="admissionDate" type="text" className="form-control form-control-sm" name=""
                                                    // value={patientData ? new Date(patientData.admitDate.split('/').reverse().join('-')).toLocaleDateString() : ''}
                                                    value={patientData && patientData.admitDate !== "0000-00-00"
                                                        ? new Date(patientData.admitDate.split('/').reverse().join('-')).toLocaleDateString('en-IN', {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: 'numeric'
                                                        })
                                                        : ''}

                                                    // value={patientData && patientData.admitDate !== "0000-00-00" ? new Date(patientData.admitDate.split('/').reverse().join('-')).toLocaleDateString() : patientData && patientData.admitDate}
                                                    disabled />
                                            </div> */}
                                            <div className="mb-2 me-2">
                                                <label htmlFor="assesmentTime" className="form-label">Time of Assessment<span className="starMandatory"></span></label>
                                                <input type="datetime-local" className="form-control form-control-sm" id="assesmentTime" name="assesmentTime" value={sendForm.assesmentTime} onChange={handleChange} disabled={isQuestioniredisable} />
                                            </div>
                                            {/* <div className="mb-2 me-2">
                                                <label htmlFor="*" className="form-label">MR No.<span className="starMandatory"></span></label>
                                                <input type="text" className="form-control form-control-sm" value={patientData && patientData.uhId} disabled placeholder="MR No." />
                                            </div> */}
                                            {/* <div className="mb-2 me-2">
                                                <label htmlFor="*" className="form-label">Ward/Bed<span className="starMandatory"></span></label>
                                                <input type="text" className="form-control form-control-sm" value={patientData && patientData.wardName + "/" + patientData.bedName} disabled placeholder="Ward/Bed" />
                                            </div> */}
                                            <div className="mb-2 me-2" style={{ maxWidth: '300px' }}>
                                                <label htmlFor="Code" className="form-label">Diagnosis<span className="starMandatory"></span></label>
                                                <ProvisonalDiagnosisMainComponent isProvisional={0} saveDiagnosis={saveDiagnosis} setSaveDiagnosis={setSaveDiagnosis} />
                                            </div>
                                            <div className="mb-2 me-2">
                                                <label htmlFor="Code" className="form-label">Procedure<span className="starMandatory"></span></label>
                                                {procedureList && <DropdownWithSearch defaulNname={t("Select Procedure")} name="procedureId" list={procedureList} valueName="id" displayName="procedureName" editdata={editProcedure} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                                <small id="errProcedure" className="invalid-feedback" style={{ display: 'none' }}></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="fieldsett mt-3">
                                    <span className='fieldse'>{t("Part A to be filled by Nurses")}</span>
                                    <div className="inner-content mt-2">
                                        <div className="d-flex flex-wrap align-content-end gap-3">
                                            <div className="mb-2 me-2">
                                                <label htmlFor="procedureDate" className="form-label">Date of Procedure<span className="starMandatory"></span></label>
                                                <input type="date" className="form-control form-control-sm" id="procedureDate" name="procedureDate" value={sendForm.procedureDate} onChange={handleChange} disabled={isQuestioniredisable} />
                                            </div>
                                            <div className="mb-2 me-2 disableDiag">
                                                <label htmlFor="diagnosis" className="form-label">Pre Procedure Diagnosis<span className="starMandatory"></span></label>
                                                {/* <input type="text" className="form-control form-control-sm" id="diagnosis" name="diagnosis" onChange={handleChange} placeholder='Pre Procedure Diagnosis'/> */}
                                                <DiagnosisForSedation setSaveDiagnosis={setSaveSedationDiagnosis} saveDiagnosis={saveSedationDiagnosis} isDisable={isPreDiagnisdisable} />
                                            </div>
                                            <div className="mb-2 me-2 disableDiag">
                                                <label htmlFor="diagnosis" className="form-label">Post Procedure Diagnosis<span className="starMandatory"></span></label>
                                                {/* <input type="text" className="form-control form-control-sm" id="diagnosis" name="diagnosis" onChange={handleChange} placeholder='Post Procedure Diagnosis' disabled /> */}
                                                <DiagnosisForSedation setSaveDiagnosis={setSavePostSedationDiagnosis} saveDiagnosis={savePostSedationDiagnosis} isDisable={isPostDiagnisdisable} />

                                            </div>
                                            <div className="mb-2 me-2">
                                                <label htmlFor="procedureIndication" className="form-label">Indication of procedure<span className="starMandatory"></span></label>
                                                <input type="text" className="form-control form-control-sm" id="procedureIndication" name="procedureIndication" onChange={handleChange} disabled={isQuestioniredisable} />
                                            </div>
                                            <div className="mb-2 me-2">
                                                <label htmlFor="Code" className="form-label">Height<span className="starMandatory"></span></label>
                                                <input id="height" type="text" className="form-control form-control-sm" name="wardName" value={patientData && patientData.height} disabled placeholder="Height" />
                                            </div>
                                            <div className="mb-2 me-2">
                                                <label htmlFor="Code" className="form-label">Weight<span className="starMandatory"></span></label>
                                                <input id="weight" type="text" className="form-control form-control-sm" name="wardName" value={patientData && patientData.weight} disabled placeholder="Weight" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-12 col-sm-12">
                                    <div className='allegydiagnosismain'>

                                        <div className='allergy-inn'>
                                            <div className="fieldsett-in">
                                                <div className="fieldsett">
                                                    <span className='fieldse'>{t("Allergy")}</span>
                                                    <div className="inner-content mt-2" style={{ minHeight: '18vh', maxHeight: '25vh', overflow: 'auto' }}>
                                                        <IPDAllergiesComponent saveAllergie={saveAllergie} setSaveAllergies={setSaveAllergies} />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* -----------------------------------Start Time Out Check List----------------------------------------------------- */}
                            <div className="fieldsett-in mt-2">
                                <div className="fieldsett">
                                    <span className='fieldse'>{t("Time Out Checklist")}</span>
                                    <div className="inner-content mt-2">
                                        <div className="d-flex flex-wrap align-content-end gap-3">
                                            <div className="mb-2 me-2">
                                                <label htmlFor="questionnaireDateTime" className="form-label">Checklist Time Out<span className="starMandatory"></span></label>
                                                <input type="datetime-local" className="form-control form-control-sm" id="questionnaireDateTime" name="questionnaireDateTime" disabled={isQuestioniredisable} value={sendForm.questionnaireDateTime} onChange={handleChange} />
                                            </div>
                                            {questionList && questionList.map(question => (
                                                <div key={question.id} className="mb-2 me-2 restraintChk-in clrchk">
                                                    <label htmlFor="" className="form-label">{question.questionText}</label>
                                                    <div className='monit-checkbox checklabel'>
                                                        {optionList && optionList
                                                            .filter(option => option.questionMasterID === question.id)
                                                            .map(option => (
                                                                <div key={option.id} className="risk-factors-check regularCheck">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="me-2 custom-checkbox form-check-input"
                                                                        id={"optType" + question.id + option.id + '' + option.optionText}
                                                                        onClick={() => handleCheckboxChange(question.id, option)}
                                                                        disabled={isQuestioniredisable}
                                                                    />
                                                                    <label htmlFor={"optType" + option.optionText}>{option.optionText}</label>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className="fieldsett-in mt-2">
                                <div className="fieldsett mt-2">
                                    <span className='fieldse'>{t("Intraprocedural Medication Record")}</span>
                                    <div className="roww">
                                        <div className={` boxcontainer1 med-table-section  ipdtablemedication`} style={{ maxHeight: '18vh', overflow: 'auto' }}>
                                            {/* <MedicineProcedure onMedicationDataUpdate={handleMedicationDataUpdate} /> */}
                                            <SedationMedicationComponent getData={getPatientData} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* -----------------------------------End Time Out Check List----------------------------------------------------- */}
                            <div className="col-12 mt-2">
                                <div className='whitebg1'>
                                    <div className='row'>
                                        <div className="col-12">
                                            <div className='whitebg' style={{ padding: "3px" }}>
                                                <div className="d-flex  gap-2 mt-2 samplebtnn">
                                                    {/* {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                                        <>
                                                            {showToster === 1 ?
                                                                <Toster value={tosterValue} message={tosterMessage} />

                                                                : <div>
                                                                    <>
                                                                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleSave}><img src={save} className='icnn' alt='' />Save</button>
                                                                        <button type="button" className="btn btn-clear btn-sm mb-1" onClick={handleClear}><img src={clear} className='icnn' alt='' />Clear</button>
                                                                    </>

                                                                </div>}
                                                        </>
                                                    } */}

                                                    {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                                        <>
                                                            {showToster === 1 ?
                                                                <Toster value={tosterValue} message={tosterMessage} />

                                                                : <div>
                                                                    {updateBool === 0 ?
                                                                        <>
                                                                            <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleSave}><img src={save} className='icnn' alt='' />Save</button>
                                                                            <button type="button" className="btn btn-clear btn-sm mb-1" onClick={handleClear}><img src={clear} className='icnn' alt='' />Clear</button>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={handlerUpdate}>Update</button>
                                                                            <button type="button" className="btn btn-clear btn-sm mb-1" onClick={handleClear}>Cancel</button>
                                                                        </>
                                                                    }
                                                                </div>}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* -----------------------------------------Start Get Data Section------------------------------- */}
                            <div className='whitebg mt-2 mb-2' style={{ paddingTop: '0' }}>
                                {/* <PatientMonitoringListTable /> */}

                                <div className="row">
                                    <div className="col-md-12 col-sm-12">

                                        <div className='handlser'>
                                            <Heading text={t("Procedure Without Sedation List")} />
                                        </div>
                                        <div className="fieldsett-in">
                                            <div className='med-table-section' style={{ maxHeight: '35vh', overflow: 'auto' }}>
                                                <table className='med-table thborder_ border_ striped billingTable'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center'>#</th>
                                                            <th className='text-center'>Time of Assessment</th>
                                                            <th className='text-center'>Diagnosis</th>
                                                            <th className='text-center'>Procedure</th>
                                                            <th className='text-center'>Date of Procedure</th>
                                                            <th className='text-center'>Pre Procedure Diagnosis</th>
                                                            <th className='text-center'>Post Procedure Diagnosis</th>
                                                            <th className='text-center'>Indication of procedure</th>
                                                            <th className='text-center'>Time Out Checklist</th>
                                                            <th className='text-center'>Medication</th>
                                                            <th className='text-center'>Allergy</th>
                                                            <th className='text-center'>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sedationList && sedationList.map((sedation, ind) => (
                                                            <tr key={ind}>
                                                                <td className='text-center'>{ind + 1}</td>
                                                                <td>{formatTime(sedation.assesmentTime)}</td>
                                                                <td>
                                                                    {diagnosisList && diagnosisList.map((diagnosis) => {
                                                                        return (
                                                                            <span>{diagnosis.details}, &nbsp;</span>
                                                                        )
                                                                    })}
                                                                </td>
                                                                <td className='text-center'>{sedation.procedureName}</td>
                                                                <td>
                                                                    {sedationList && sedation.procedureDate !== "0000-00-00"
                                                                        ? new Date(sedation.procedureDate.split('/').reverse().join('-')).toLocaleDateString('en-IN', {
                                                                            day: '2-digit',
                                                                            month: '2-digit',
                                                                            year: 'numeric'
                                                                        })
                                                                        : ''}
                                                                </td>

                                                                <td className='text-center'>
                                                                    {preProcedureList}
                                                                </td>

                                                                <td>
                                                                    {postProcedureList}

                                                                </td>
                                                                <td>{sedation.procedureIndication}</td>
                                                                <td>
                                                                    {questionireList && questionireList.map((ques, index) => (
                                                                        <span key={index}><strong>Question:</strong> {ques.questionName} - <strong>Answer:</strong> {ques.optionText}<br /></span>
                                                                    ))}
                                                                </td>

                                                                <td>
                                                                    {sedationList && sedationList.map((sedation, index) => (
                                                                        <div key={index}>
                                                                            {console.log("Prescription data for sedation ", index + 1, ":", sedation.prescription)}
                                                                            {sedation.prescription && sedation.prescription.map((prescription, idx) => (
                                                                                <div key={idx}>
                                                                                    <div><strong>Medicine:</strong> {prescription.drugName}</div>
                                                                                    <div><strong>Dosage Strength:</strong> {prescription.dosageStrength}</div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ))}
                                                                </td>
                                                                <td>
                                                                    {allergyJSONList && allergyJSONList.map((allergies, i) => (
                                                                        <div key={i}>
                                                                            {allergies.map((allergy, index) => (
                                                                                <div key={index}>
                                                                                    <div><strong>Allergy Name:</strong> {allergy.parameterName}</div>
                                                                                    <div><strong>Remark:</strong> {allergy.remark}</div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ))}
                                                                </td>
                                                                <td className='text-center' style={{ whiteSpace: 'nowrap' }}>
                                                                    <span className='icnupdte' onClick={() => { handleUpdate(sedation) }} title='Update Post Diagnosis'><img src={SarIconupdateicon} alt='' /></span>
                                                                    <span className='printRestForm' onClick={() => handlePrintSedation(ind)} title='Print'><i className='fa fa-print'></i></span>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* -----------------------------------------End Get Data Section------------------------------- */}

                        </div>
                    </div>

                    {
                        showLoder === 1 ? <Loader val={showLoder} /> : ""
                    }
                    {/* Toaster */}
                    {
                        isShowToaster === 1 ?
                            <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                    }

                    {
                        showAlertToster === 1 ?
                            <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                    }
                </div>

            </section>
        </>
    )
}
