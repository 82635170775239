




 let GetAllCashLessPeddingRequest = async(fromDate,toDate,formStatus) => {
   
    let clientId = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId

    let URL = window.BillingbaseUrl +`/api/RequestForCashless/GetAllCashLessPeddingRequest?ClientId=${clientId}&fromDate=${fromDate}&toDate=${toDate}&formStatus=${formStatus}`;  
    const head = {'content-type':'application/json','accept':'*/*',}
    let data = {};
    let response = await fetch(URL,{
      method: "GET",
      header: head,
  
    }).then(res => res.json()).then(data);
    return response;
  }
  export default  GetAllCashLessPeddingRequest;