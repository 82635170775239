import React, { useEffect, useState } from 'react'
import ReactDOMServer from 'react-dom/server';
import { Editor, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import GetConsentMaster from '../../Admin/Api/ConsentForms/ConsentMaster/GetConsentMaster';
import GetAllConsentDataFieldByConsent from './API/GetAllConsentDataFieldByConsent';
import InsertPatientConsentData from './API/InsertPatientConsentData';
import AlertToster from '../../Component/AlertToster';
import SuccessToster from '../../Component/SuccessToster';
import save from "../../assets/images/icons/save.svg"
import GetAllPatientConsentData from './API/GetAllPatientConsentData';
import UpdatePatientConsentData from './API/UpdatePatientConsentData';

export default function ConsentForm() {

    const [parsedHTML, setParsedHTML] = useState('');
    let [formMasterList, setFormMasterList] = useState([])
    // let [dataFieldList, setDataFieldList] = useState([{ dataId: "fname", dataType: "text" }, { dataId: "lname", dataType: "text" }, { dataId: "male", dataType: "radio" }, { dataId: "female", dataType: "radio" }, { dataId: "urdu", dataType: "checkbox" }, { dataId: "hindi", dataType: "checkbox" }])
    let [dataFieldList, setDataFieldList] = useState([])
    let [sendForm, setSendForm] = useState([])
    let [consentID, setConsentID] = useState(null)
    let [showAlertToster, setShowAlertToster] = useState(0)
    let [showAlertMessage, setShowAlertMessage] = useState("")
    let [showToster, setShowtoster] = useState(0)
    let [isUpdate, setIsUpdate] = useState(0)


    let handleChange = (e) => {
        let tempdata = [...sendForm]
        for (let i = 0; i <= dataFieldList.length - 1; i++) {
            let temData = {}
            console.log("consentId", consentID)
            if ((dataFieldList[i].dataType.toString().toLowerCase() === "text".toString().toLowerCase()) || (dataFieldList[i].dataType.toString().toLowerCase() === "textarea".toString().toLowerCase()) || (dataFieldList[i].dataType.toString().toLowerCase() === "number".toString().toLowerCase()) || (dataFieldList[i].dataType.toString().toLowerCase() === "date".toString().toLowerCase()) || (dataFieldList[i].dataType.toString().toLowerCase() === "time".toString().toLowerCase())) {

                if (dataFieldList[i].dataId === e.target.id) {
                    let data = document.getElementById(dataFieldList[i].dataId).value
                    let index = tempdata.findIndex(value => value.dataFieldId === dataFieldList[i].dataId);
                    if (index === -1) {
                        temData = {
                            [dataFieldList[i].dataId]: data,
                            "dataFieldId": dataFieldList[i].dataId,
                            "dataValue": data,
                            "consentID": consentID,
                            "consentDataFieldID": dataFieldList[i].id,
                            "datatype": dataFieldList[i].dataType
                        }

                        setSendForm([...sendForm, temData])

                    }
                    else {
                        tempdata[index][dataFieldList[i].dataId] = data
                        tempdata[index]["dataFieldId"] = dataFieldList[i].dataId
                        tempdata[index]["dataValue"] = data
                        tempdata[index]["consentID"] = consentID
                        tempdata[index]["consentDataFieldID"] = dataFieldList[i].id
                        tempdata[index]["datatype"] = dataFieldList[i].dataType

                        setSendForm([...tempdata])
                    }
                    return
                }
            }
            else if (dataFieldList[i].dataType.toString().toLowerCase() === "checkbox".toString().toLowerCase()) {
                if (dataFieldList[i].dataId === e.target.id) {

                    let data = document.getElementById(dataFieldList[i].dataId).checked
                    let index = tempdata.findIndex(value => value.dataFieldId === dataFieldList[i].dataId);
                    if (index === -1) {
                        temData = {

                            [dataFieldList[i].dataId]: data,
                            "dataFieldId": dataFieldList[i].dataId,
                            "dataValue": data,
                            "consentID": consentID,
                            "consentDataFieldID": dataFieldList[i].id,
                            "datatype": dataFieldList[i].dataType



                        }
                        setSendForm([...sendForm, temData])

                    }
                    else {
                        tempdata[index][dataFieldList[i].dataId] = data
                        tempdata[index]["dataFieldId"] = dataFieldList[i].dataId
                        tempdata[index]["dataValue"] = data
                        tempdata[index]["consentID"] = consentID
                        tempdata[index]["consentDataFieldID"] = dataFieldList[i].id
                        tempdata[index]["datatype"] = dataFieldList[i].dataType


                        setSendForm([...tempdata])
                    }
                    return
                }

            }
            else if (dataFieldList[i].dataType.toString().toLowerCase() === "radio".toString().toLowerCase()) {
                if (dataFieldList[i].dataId === e.target.id) {

                    let data = document.getElementById(dataFieldList[i].dataId).checked
                    let index = tempdata.findIndex(value => value.dataFieldId === dataFieldList[i].dataId);
                    if (index === -1) {
                        temData = {

                            [dataFieldList[i].dataId]: data,
                            "dataFieldId": dataFieldList[i].dataId,
                            "dataValue": data,
                            "consentID": consentID,
                            "consentDataFieldID": dataFieldList[i].id,
                            "datatype": dataFieldList[i].dataType



                        }
                        setSendForm([...sendForm, temData])

                    }
                    else {
                        tempdata[index][dataFieldList[i].dataId] = data
                        tempdata[index]["dataFieldId"] = dataFieldList[i].dataId
                        tempdata[index]["dataValue"] = data
                        tempdata[index]["consentID"] = consentID
                        tempdata[index]["consentDataFieldID"] = dataFieldList[i].id
                        tempdata[index]["datatype"] = dataFieldList[i].dataType


                        setSendForm([...tempdata])
                    }
                    return
                }

            }

        }
    }

    let handleChangedropdown = async (e) => {
        let id = e.target.value
        setConsentID(id)
        let getTemplateDataFields = await GetAllConsentDataFieldByConsent(id)
        if (getTemplateDataFields.status === 1) {
            let data = getTemplateDataFields.responseValue
            setParsedHTML(data[0].templateText)
            let datafield = []
            for (var i = 0; i <= data.length - 1; i++) {
                let temp = {
                    id: data[i].id,
                    dataId: data[i].dataFieldId,
                    dataType: data[i].dataType
                }
                datafield.push(temp)

            }
            console.log("data", datafield)

            setDataFieldList(datafield)
            getConsentData(id)
        }
        else {
            setParsedHTML('')
        }
    }

    let getConsentData = async (id) => {
        let uhid = JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid
        let response = await GetAllPatientConsentData(id, uhid)
        if (response.status === 1) {
            setIsUpdate(1)
            let data = response.responseValue
            let datafield = []
            for (var i = 0; i <= data.length - 1; i++) {

                if ((data[i].dataType.toString().toLowerCase() === "text".toString().toLowerCase()) || (data[i].dataType.toString().toLowerCase() === "textarea".toString().toLowerCase()) || (data[i].dataType.toString().toLowerCase() === "number".toString().toLowerCase()) || (data[i].dataType.toString().toLowerCase() === "date".toString().toLowerCase()) || (data[i].dataType.toString().toLowerCase() === "time".toString().toLowerCase())) {

                    document.getElementById(data[i].dataFieldId).value = data[i].dataValue
                    let temp = {
                        [data[i].dataFieldId]: data[i].dataValue,
                        "dataFieldId": data[i].dataFieldId,
                        "dataValue": data[i].dataValue,
                        "consentID": data[i].consentID,
                        "consentDataFieldID": data[i].consentDataFieldID,
                        "datatype": data[i].dataType
                    }
                    datafield.push(temp)


                }
                else if (data[i].dataType.toString().toLowerCase() === "checkbox".toString().toLowerCase()) {

                    document.getElementById(data[i].dataFieldId).checked = data[i].dataValue
                    let temp = {
                        [data[i].dataFieldId]: data[i].dataValue,
                        "dataFieldId": data[i].dataFieldId,
                        "dataValue": data[i].dataValue,
                        "consentID": data[i].consentID,
                        "consentDataFieldID": data[i].consentDataFieldID,
                        "datatype": data[i].dataType
                    }
                    datafield.push(temp)


                }
                else if (data[i].dataType.toString().toLowerCase() === "radio".toString().toLowerCase()) {

                    document.getElementById(data[i].dataFieldId).checked = data[i].dataValue
                    let temp = {
                        [data[i].dataFieldId]: data[i].dataValue,
                        "dataFieldId": data[i].dataFieldId,
                        "dataValue": data[i].dataValue,
                        "consentID": data[i].consentID,
                        "consentDataFieldID": data[i].consentDataFieldID,
                        "datatype": data[i].dataType
                    }
                    datafield.push(temp)


                }

            }
            setSendForm([...sendForm, ...datafield])

        }
        else {
            setIsUpdate(0)

        }

    }

    let getData = async () => {
        let response = await GetConsentMaster(window.clientId)
        if (response.status === 1) {
            setFormMasterList(response.responseValue)
        }
        else {
            setFormMasterList([])
        }
    }

    let handlePrint = () => {
        var originalContent = document.body.innerHTML;
        const updatedHTML = generateUpdatedHTML(parsedHTML, sendForm);
        const printContainer = updatedHTML;

        document.body.innerHTML = printContainer;
        sendForm.map((val, ind) => {
            if (((val.datatype.toString().toLowerCase() === "date".toString().toLowerCase()) || (val.datatype.toString().toLowerCase() === "time".toString().toLowerCase()) || val.datatype.toString().toLowerCase() === "text".toString().toLowerCase()) || (val.datatype.toString().toLowerCase() === "textarea".toString().toLowerCase()) || (val.datatype.toString().toLowerCase() === "number".toString().toLowerCase())) {
                document.getElementById(val.dataFieldId).value = val.dataValue;
            }
            else if (val.datatype.toString().toLowerCase() === "checkbox".toString().toLowerCase()) {
                document.getElementById(val.dataFieldId).checked = val.dataValue;
            }
            else if (val.datatype.toString().toLowerCase() === "radio".toString().toLowerCase()) {
                document.getElementById(val.dataFieldId).checked = val.dataValue;
            }
        })
        window.print();
        window.location.reload()
        // document.body.innerHTML = originalContent;
    }
    let handleSave = async () => {
        // InsertPatientConsentData
        let makedata = {
            "uhid": JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid,
            "jsonConsentDetails": JSON.stringify(sendForm),
            "clientId": window.clientId,
            "userId": window.userId
        }
        console.log("data", makedata)



        let response = await InsertPatientConsentData(makedata)
        if (response.status === 1) {
            setShowtoster(1)

            handlePrint()
        }
        else {
            setShowAlertToster(1)
            setShowAlertMessage(response.responseValue)
        }
    }

    let handleUpdate = async () => {
        let makedata = {
            "uhid": JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid,
            "jsonConsentDetails": JSON.stringify(sendForm),
            "clientId": window.clientId,
            "userId": window.userId
        }
        let resp = await UpdatePatientConsentData(makedata)
        if (resp.status === 1) {
            setShowtoster(1)
            handlePrint()
        }
        else {
            setShowAlertToster(1)
            setShowAlertMessage(resp.responseValue)
        }
    }

    const generateUpdatedHTML = (htmlContent, formData) => {
        let updatedHTML = htmlContent;
        formData.forEach(data => {
            updatedHTML = updatedHTML.replace(`{{${data.dataFieldId}}}`, data.dataValue);
        });
        return updatedHTML;
    };

    useEffect(() => {
        getData()
       
    }, []);
    return (
        <>


            <select className="form-select form-select-sm mb-3" name="ageUnit" id="ddlAgeUnit" aria-label=".form-select-sm example" onChange={handleChangedropdown}>
                <option value="0">{"Select Form"}</option>
                {
                    formMasterList && formMasterList.map((val, ind) => {
                        return (
                            <option value={val.id}>{val.formTitle}</option>

                        )
                    })
                }

            </select>

            <div id="parsedHtmldiv"
                // contentEditable
                dangerouslySetInnerHTML={{ __html: parsedHTML }}
                onInput={handleChange} // Use onInput instead of onChange for contenteditable elements
            // style={{ border: '1px solid #ccc', minHeight: '100px', padding: '10px' }}
            />
            {
                isUpdate === 0 ?
                    <button className='saveprintopd ps-3 pe-3 btn-save-fill' onClick={handleSave} >
                        <img src={save} className='icnn' alt='' /> Save & Print
                    </button> :
                    <button className='saveprintopd ps-3 pe-3 btn-save-fill' onClick={handleUpdate} >
                        <img src={save} className='icnn' alt='' /> Update & Print
                    </button>
            }





            {
                showAlertToster === 1 ? <AlertToster handle={setShowAlertToster} message={showAlertMessage} /> : ""
            }
            {
                showToster === 1 ? <SuccessToster handle={setShowtoster} message={"Data Save Successfully!!"} /> : ""
            }
        </>
    )
}

