import React, { useState } from 'react'
import { useEffect } from 'react';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import Toster from '../../../Component/Toster';
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Heading from '../../../Component/Heading';
import BoxContainer from '../../../Component/BoxContainer';
import TableContainer from '../../../Component/TableContainer';
import SuccessToster from '../../../Component/SuccessToster';
import AlertToster from '../../../Component/AlertToster';
import Loader from '../../../Component/Loader';
import DropdownWithSearch from '../../../Component/DropdownWithSearch';
import { t } from 'i18next';
import GetDepartmentUnitMaster from '../Api/DepartmentUnitMaster/GetDepartmentUnitMaster';
import GetDoctorDepartmentUnitMapping from '../Api/DoctorDepartmentUnitMapping/GetDoctorDepartmentUnitMapping';
import GetAllDoctor from '../Api/DoctorDepartmentUnitMapping/GetAllDoctor';
import PostDoctorDepartmentUnitMapping from '../Api/DoctorDepartmentUnitMapping/PostDoctorDepartmentUnitMapping';
import DeleteDoctorDepartmentUnitMapping from '../Api/DoctorDepartmentUnitMapping/DeleteDoctorDepartmentUnitMapping';
import PutDoctorDepartmentUnitMapping from '../Api/DoctorDepartmentUnitMapping/PutDoctorDepartmentUnitMapping';
import Search from '../../../Code/Serach';

export default function DoctorDepartmentUnitMapping() {
  let [doctorList, setDoctorList] = useState("")
  let [doctorUnitMappingList, setDoctorUnitMappingList] = useState("")
  let [doctorUnitMappingListMain, setDoctorUnitMappingListMain] = useState("")
  let [departmentUnitList, setDepartmentUnitList] = useState("")
  let [showUnderProcess, setShowUnderProcess] = useState(0);
  let [showToster, setShowToster] = useState(0);
  let [tosterMessage, setTosterMessage] = useState("");
  let [tosterValue, setTosterValue] = useState(0);
  let [showLoder, setShowLoder] = useState(0);
  let [isShowToaster, setisShowToaster] = useState(0);
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [showErrMessage, setShowErrMessage] = useState('');
  let [showSuccessMsg, setShowSuccessMsg] = useState('');
  let [updateBool, setUpdateBool] = useState(0);
  let [rowId, setRowId] = useState(0);

  let [clearDropdown, setClearDropdown] = useState(0)
  let [editDoctor, setEditDoctor] = useState("")
  let [editDepartmentUnit, setEditDepartmentUnit] = useState("")
  let [sendForm, setSendForm] = useState({
    "userId": window.userId,
    "clientId": window.clientId, 
    doctorId: '',
    departmentUnitId: '',
    isPrimary: false,
  })

  //Handle Change
  let handleChange = async (e) => {
    clearValidationErrMessage();
    const { name, value, checked } = e.target;

    if (name === "isPrimary") {
      setSendForm((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setSendForm((prevData) => ({
        ...prevData,
        [name]: value,
        "userId": window.userId,
        "clientId": window.clientId 
      }));
    }

    setEditDoctor("");
    setEditDepartmentUnit("");
  };

  //Doctor Departmernt Unit Mapping
  const getDoctorUnitMapping = async () => {
    setShowLoder(1);
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    const response = await GetDoctorDepartmentUnitMapping(clientID);
    if (response.status === 1) {
      setDoctorUnitMappingList(response.responseValue);
      setDoctorUnitMappingListMain(response.responseValue)
      setShowLoder(0)
    }
    else {
      setShowLoder(0);
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
      setTimeout(() => {
        setShowAlertToster(0);
      }, 1500)
    }
  }

  //handle search
  let handleSearch = (e) => {
    let resp = Search(doctorUnitMappingListMain, e.target.value)
    if (e.target !== "") {
      if (resp.length !== 0) {
        setDoctorUnitMappingList(resp)
      }
      else {
        setDoctorUnitMappingList([])
      }
    }
    else {
      setDoctorUnitMappingList(doctorUnitMappingListMain)
    }
  }

 
  //Get Doctor List
  const getDoctor = async () => {
    setShowLoder(1)
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    const response = await GetAllDoctor(clientID);
    if (response.status === 1) {
      setDoctorList(response.responseValue);
      setShowLoder(0)
    }
    else {
      setShowLoder(0);
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
      setTimeout(() => {
        setShowAlertToster(0)
      }, 1500)
    }
  }
  //Get Department Unit List
  const getDepartmentUnit = async () => {
    setShowLoder(1)
    const response = await GetDepartmentUnitMaster();
    if (response.status === 1) {
      setDepartmentUnitList(response.responseValue);
      setShowLoder(0)
    }
    else {
      setShowLoder(0);
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
      setTimeout(() => {
        setShowAlertToster(0)
      }, 1500)
    }
  }

  //Handle Save
  const handlerSave = async () => {
    const IsPrimaryData = document.getElementById("isPrimary").checked;
    if (sendForm.doctorId === '' || sendForm.doctorId === null || sendForm.doctorId === undefined) {
      document.getElementById('errDoctorId').innerHTML = "Doctor is required";
      document.getElementById('errDoctorId').style.display = "block";
    }
    else if (sendForm.departmentUnitId === '' || sendForm.departmentUnitId === null || sendForm.departmentUnitId === undefined) {
      document.getElementById('errdepartmentUnitId').innerHTML = "Department unit is required";
      document.getElementById('errdepartmentUnitId').style.display = "block";
    }
    else {
      setShowUnderProcess(1);
      const response = await PostDoctorDepartmentUnitMapping({
        ...sendForm,
        isPrimary: IsPrimaryData,
      });
      if (response.status === 1) {
        setShowUnderProcess(0);
        setTosterValue(0);
        setShowToster(1);
        setTosterMessage("Data Saved Successfully..");
        setTimeout(() => {
          setShowToster(0);
          handleClear();
          getDoctorUnitMapping();

        }, 1500)
      }
      else {
        setShowUnderProcess(0);
        setTosterValue(1);
        setShowToster(1);
        setTosterMessage(response.responseValue);
        setTimeout(() => {
          setShowToster(0);
        }, 1500)
      }
    }
  }

  //Handle Button Change
  let handleUpdate = async (id, doctorId, departmentUnitId, isPrimary, doctorName, departmentUnitName) => {
    document.getElementById("isPrimary").checked = isPrimary;
    setUpdateBool(1);
    setSendForm(sendForm => ({
      ...sendForm,
      "id": id,
      "doctorId": doctorId,
      "departmentUnitId": departmentUnitId,
      "isPrimary": isPrimary
    }))
    setEditDoctor(doctorName)
    setEditDepartmentUnit(departmentUnitName)
    document.getElementById("isPrimary").value = isPrimary;
  }


  //Handle Update
  const handlerUpdate = async () => {
    const IsPrimaryData = document.getElementById("isPrimary").checked;
    if (sendForm.doctorId === '' || sendForm.doctorId === null || sendForm.doctorId === undefined) {
      document.getElementById('errDoctorId').innerHTML = "Doctor is required";
      document.getElementById('errDoctorId').style.display = "block";
    }
    else if (sendForm.departmentUnitId === '' || sendForm.departmentUnitId === null || sendForm.departmentUnitId === undefined) {
      document.getElementById('errdepartmentUnitId').innerHTML = "Department unit is required";
      document.getElementById('errdepartmentUnitId').style.display = "block";
    }
    else {
      setShowUnderProcess(1);
      const response = await PutDoctorDepartmentUnitMapping({
        ...sendForm,
        isPrimary: IsPrimaryData,
      });
      console.log('save Response', response);
      if (response.status === 1) {
        setShowUnderProcess(0);
        setTosterValue(0);
        setShowToster(1);
        setTosterMessage("Updated Successfully..");
        setTimeout(() => {
          setShowToster(0);
          handleClear();
          getDoctorUnitMapping();

        }, 1500)
      }
      else {
        setShowUnderProcess(0);
        setTosterValue(1);
        setShowToster(1);
        setTosterMessage(response.responseValue);
        setTimeout(() => {
          setShowToster(0);
        }, 1500)
      }
    }
  }

  //Handle Delete
  const handleDelete = async () => {
    setShowLoder(1);
    const response = await DeleteDoctorDepartmentUnitMapping(rowId);
    if (response.status === 1) {
      setShowLoder(0)
      setisShowToaster(1);
      setShowSuccessMsg("Deleted Successfully")
      setTimeout(() => {
        setisShowToaster(0);
        getDoctorUnitMapping();
      }, 1500)
      handleClear();
    }
    else {
      setShowLoder(0);
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
      setTimeout(() => {
        setShowAlertToster(0);
      }, 1500)
    }
  }

  //Clear Error Message
  const clearValidationErrMessage = () => {
    document.getElementById('errDoctorId').style.display = "none";
    document.getElementById('errdepartmentUnitId').style.display = "none";
  }

  //Handle Clear
  const handleClear = (value) => {
    clearValidationErrMessage();
    setClearDropdown(value)
    setUpdateBool(0);
    setSendForm({ "userId": window.userId, "clientId": window.clientId, "doctorId": '', "departmentUnitId": '', "isPrimary": false, })
    setEditDoctor(0);
    setEditDepartmentUnit(0);
    document.getElementById("isPrimary").checked = false;
  }

  useEffect(() => {
    getDoctorUnitMapping();
    getDoctor();
    getDepartmentUnit();
  }, []);

  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Heading text="Doctor Department Unit Mapping" />
              <BoxContainer>
                <div className="col-2 mb-2 me-2">
                  <label htmlFor="doctorId" className="form-label">Doctor<span className="starMandatory">*</span></label>
                  {doctorList && <DropdownWithSearch defaulNname={t("Select Doctor")} name="doctorId" list={doctorList} valueName="id" displayName="name" editdata={editDoctor} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                  <small id="errDoctorId" className="invalid-feedback" style={{ display: 'none' }}></small>
                </div>
                <div className="col-2 mb-2 me-2">
                  <label htmlFor="departmentUnitId" className="form-label">Department Unit<span className="starMandatory">*</span></label>
                  {departmentUnitList && <DropdownWithSearch defaulNname={t("Select Department Unit")} name="departmentUnitId" list={departmentUnitList} valueName="id" displayName="unitName" editdata={editDepartmentUnit} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                  <small id="errdepartmentUnitId" className="invalid-feedback" style={{ display: 'none' }}></small>
                </div>

                <div className="mb-2 me-2">
                  <label className="form-label">&nbsp;</label>
                  <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="isPrimary" name="isPrimary" role="switch" />
                    <label className="form-check-label" htmlFor="isPrimary" style={{ lineHeight: '30px' }} >
                      IsPrimary
                    </label>
                  </div>
                </div>


                <div className="mb-2 relative">
                  <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                  <div>
                    {showUnderProcess === 1 ? <TosterUnderProcess /> :
                      <>
                        {showToster === 1 ?
                          <Toster value={tosterValue} message={tosterMessage} />

                          : <div>
                            {updateBool === 0 ?
                              <>
                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handlerSave}><img src={saveButtonIcon} className='icnn' alt='' />Save</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt='' />Clear</button>
                              </>
                              :
                              <>
                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={handlerUpdate}>Update</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={handleClear}>Cancel</button>
                              </>
                            }
                          </div>}
                      </>
                    }
                  </div>
                </div>
              </BoxContainer>
            </div>
            <div className="col-12 mt-2">
              <div className='handlser'>
                <Heading text="Doctor Department Unit Mapping List" />
                <div style={{ position: 'relative' }}>
                  <input type="text" className='form-control form-control-sm' placeholder={t("Search")} onChange={handleSearch} />
                  <span className="tblsericon"><i className="fas fa-search"></i></span>
                </div>
              </div>
              <div className="med-table-section" style={{ "height": "75vh" }}>
                <TableContainer>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>Doctor</th>
                      <th>Department Unit</th>
                      <th>IsPrimary</th>
                      <th style={{ "width": "10%" }} className="text-center">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {doctorUnitMappingList && doctorUnitMappingList.map((val, ind) => {
                      return (
                        <tr key={val.id}>
                          <td className="text-center">{ind + 1}</td>
                          <td>{val.doctorName}</td>
                          <td>{val.departmentUnitName}</td>
                          <td>{val.isPrimary === false ? "No" : "Yes"}</td>
                          <td>
                            <div className="action-button">
                              <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={editBtnIcon} className='' alt='' onClick={() => { handleUpdate(val.id, val.doctorId, val.departmentUnitId, val.isPrimary, val.doctorName, val.departmentUnitName) }} /></div>
                              <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowId(val.id) }} />
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}


                  </tbody>
                </TableContainer>
                {/* -----------------------Start Delete Modal Popup-------------------   */}

                {/*  <!-- Modal -->  */}
                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                  <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                      <div className="modal-body modelbdy text-center">
                        <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                        <div className='popDeleteTitle mt-3'>Delete?</div>
                        <div className='popDeleteContent'>Are you sure want to delete?</div>
                      </div>
                      <div className="modal-footer1 text-center">

                        <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn-delete popBtnDelete" onClick={handleDelete} data-bs-dismiss="modal">Delete</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

              </div>
            </div>
          </div>
        </div>
        {
          showLoder === 1 ? <Loader val={showLoder} /> : ""
        }
        {/* Toaster */}
        {
          isShowToaster === 1 ?
            <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
        }

        {
          showAlertToster === 1 ?
            <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
        }
      </section>
    </>
  )
}
