import React, { useEffect, useState } from 'react'
import "../../assets/css/patientMonitoringDashboard.css";
import SearchComponent from './SearchComponent';
import tableDragger from 'table-dragger'
import UpdateAllDashboardColumnSequence from '../Api/UpdateAllDashboardColumnSequence';
import { current } from '@reduxjs/toolkit';
export default function AllTable(props) {

    let [showRow, setShowRow] = useState([])
    let [columnId, setColumnId] = useState(null)

    let [cloumnVisibility, setCloumnVisibility] = useState({
        // lifeSupporVisibility: true,
        // diagnosisVisibility: true,
        // wardVisibility: true,
        // infusionVisibility: true,
        // consultantVisibility: true,
        // nSVisibility: true,
        // bPRVisibility: true,
        // sPO2RVisibility: true,
        // pulseRRVisibility: true,
        // tempRRVisibility: true,
    })


    // let handleColumnVisibility = async (name, id) => {
    //     console.log("callll", id)
    //     let table = document.getElementById('alltable')
    //     let allHead = table.getElementsByTagName('th')
    //     let sendDataArray = []
    //     let tempsendArray = []
    //     for (var i = 1; i <= allHead.length - 1; i++) {
    //         let senddata = JSON.parse(allHead[i].getAttribute("data-json"))
    //         if (senddata.columnId !== id) {
    //             tempsendArray.push(senddata)
    //         }
    //     }
    //     for (var i = 0; i <= tempsendArray.length - 1; i++) {

    //         let data = {
    //             "id": tempsendArray[i].id,
    //             "columnId": tempsendArray[i].columnId,
    //             "sequenceNumber": i + 1,
    //             "assignedTo": tempsendArray[i].assignedTo,
    //             "userId": window.userId,
    //             "clientId": window.clientId
    //         }
    //         sendDataArray.push(data)

    //     }
    //     // sendDataArray = sendDataArray.filter(n => n)
    //     console.log("tempsendArray", tempsendArray, sendDataArray)
    //     if (sendDataArray.length === 0) {
    //         sendDataArray[0] = {
    //             "id": 0,
    //             "columnId": 0,
    //             "sequenceNumber": 0,
    //             "assignedTo": 0,
    //             "userId": window.userId,
    //             "clientId": window.clientId
    //         }
    //     }
    //     let sendData = {
    //         jsonSequence: JSON.stringify(sendDataArray)
    //     }
    //     let response = await UpdateAllDashboardColumnSequence(sendData);
    //     if (response.status === 1) {
    //         console.log("Data Save")
    //         props.handleshowHide(-1)
    //         window.location.reload(true)


    //     }
    //     else {
    //         window.location.reload(true)
    //     }

    //     setCloumnVisibility(value => ({
    //         ...value, [name]: false
    //     }))


    // }
    const handleColumnVisibility = async (name, id) => {

        const table = document.getElementById('alltable');
        const allHead = Array.from(table.getElementsByTagName('th')).slice(1); // Exclude the first element
        console.log("callll", allHead);

        const tempsendArray = allHead.map(head => JSON.parse(head.getAttribute("data-json")))
            .filter(senddata => senddata.columnId !== columnId);

        const sendDataArray = tempsendArray.map((senddata, index) => ({
            id: senddata.id,
            columnId: senddata.columnId,
            sequenceNumber: index + 1,
            assignedTo: senddata.assignedTo,
            userId: window.userId,
            clientId: window.clientId,
            isShow: 1

        }));

        console.log("tempsendArray", tempsendArray, sendDataArray);

        if (sendDataArray.length === 0) {
            sendDataArray.push({
                id: 0,
                columnId: 0,
                sequenceNumber: 0,
                assignedTo: 0,
                userId: window.userId,
                clientId: window.clientId
            });
        }

        const sendData = {
            jsonSequence: JSON.stringify(sendDataArray)
        };

        const response = await UpdateAllDashboardColumnSequence(sendData);

        if (response.status === 1) {
            console.log("Data Save");
            props.handleshowHide(-1);
            window.location.reload(true);
        } else {
            window.location.reload(true);
        }

        setCloumnVisibility(value => ({
            ...value,
            [name]: false
        }));
    };
    let getAllColumnIds = async () => {
        let table = document.getElementById('alltable')
        let allHead = table.getElementsByTagName('th')
        let sendDataArray = []
        for (var i = 1; i <= allHead.length - 1; i++) {
            console.log("rrrrrr", JSON.parse(allHead[i].getAttribute("data-json")))
            let senddata = JSON.parse(allHead[i].getAttribute("data-json"))
            if (senddata  !== null) {


                let data = {
                    "id": senddata.id,
                    "columnId": senddata.columnId,
                    "sequenceNumber": i,
                    "assignedTo": senddata.assignedTo,
                    "userId": window.userId,
                    "clientId": window.clientId,
                    "isShow": 1
                }
                sendDataArray.push(data)
            }

        }

        let sendData = {
            jsonSequence: JSON.stringify(sendDataArray)
        }
        let response = await UpdateAllDashboardColumnSequence(sendData);
        if (response.status === 1) {
            console.log("Data Save")
            props.handleshowHide(-1)
            window.location.reload(true)


        }
        else {
            window.location.reload(true)
        }


    }

    let cuurentposition = 0
    let scrollDiv = (e) => {
        // if (document.querySelector('.sindu_dragger')) {
        //     const note = document.querySelector('.sindu_dragger');
        //     // const notes = document.querySelector('.gu-mirror');
        //     const parent = document.getElementById("firstSection").offsetWidth
        //     const center = parent / 2
        //     const limits = 50
        //     let wrapper_pos = document.getElementById("firstSection")
        //     let mousePos = Math.min(Math.max(e.pageX, wrapper_pos.offsetLeft + limits), wrapper_pos.offsetLeft + parent - limits);
        //     var temp
        //     if (e.pageX > center) {
        //         temp = (mousePos + 20 - 5) * (note.offsetWidth - parent) / parent;
        //         console.log("mouse",  note.offsetWidth, parent, mousePos)
        //         note.scrollLeft += 10
        //     } else {
        //         temp = (mousePos - 50) * (note.offsetWidth - parent) / parent;
        //         note.scrollLeft -= 10

        //     }

        //     // parent.stop().animate({
        //     //     'margin-left': '-' + tmp + 'px'
        //     // }, 'fast', 'easeOutSine');


        //     // if (parseInt(notes.style.left.replace("px", "")) > cuurentposition) {

        //     //     note.scrollLeft += parseInt(notes.style.left.replace("px", ""))
        //     //     cuurentposition = parseInt(notes.style.left.replace("px", ""))

        //     // }
        //     // else if (!parseInt(notes.style.left.replace("px", "")) < 0) {
        //     //     note.scrollLeft -= parseInt(notes.style.left.replace("px", ""))
        //     //     cuurentposition = parseInt(notes.style.left.replace("px", ""))

        //     // }
        //     // else {
        //     //     note.scrollLeft = 0
        //     //     cuurentposition = 0
        //     // }


        // }
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        // let isMouseMoving = false;
        // Define the threshold distance from the edges (you can adjust this value)
        const threshold = 50;

        // Check if the mouse is near the screen edges
        const isNearLeftEdge = mouseX < threshold;
        const isNearRightEdge = window.innerWidth - mouseX < threshold;
        const isNearTopEdge = mouseY < threshold;
        const isNearBottomEdge = window.innerHeight - mouseY < threshold;
        if (isNearLeftEdge || isNearRightEdge) {
            // isMouseMoving = true
            if (document.querySelector('.sindu_dragger')) {
                const note = document.querySelector('.sindu_dragger');
                const parent = document.getElementById('firstSection');
                // const parent = window.innerWidth;
                const center = parent.offsetWidth / 2;
                const limits = 50;
                const sensitivity = 1; // Adjust the sensitivity for smoother scrolling

                // Calculate the mouse position within the parent container
                let mousePos = Math.min(
                    Math.max(e.pageX - parent.offsetLeft, limits),
                    parent.offsetWidth - limits
                );

                let targetScroll;
                if (mousePos > center) {
                    targetScroll = note.scrollLeft + 10; // Adjust the scroll speed
                } else {
                    targetScroll = note.scrollLeft - 10; // Adjust the scroll speed
                }

                // Apply smooth scrolling
                smoothScroll(note, note.scrollLeft, targetScroll, sensitivity);
            }
        }
        // else {
        //     isMouseMoving = false
        // }

        // setInterval(() => {
        //     if (!isMouseMoving) {
        //         // Mouse is not moving, perform your actions here
        //         if (document.querySelector('.sindu_dragger')) {
        //             const note = document.querySelector('.sindu_dragger');
        //             const parent = document.getElementById('firstSection');
        //             // const parent = window.innerWidth;
        //             const center = parent.offsetWidth / 2;
        //             const limits = 50;
        //             const sensitivity = 1; // Adjust the sensitivity for smoother scrolling

        //             // Calculate the mouse position within the parent container
        //             let mousePos = Math.min(
        //                 Math.max(e.pageX - parent.offsetLeft, limits),
        //                 parent.offsetWidth - limits
        //             );

        //             let targetScroll;
        //             if (mousePos > center) {
        //                 targetScroll = note.scrollLeft + 10; // Adjust the scroll speed
        //             } else {
        //                 targetScroll = note.scrollLeft - 10; // Adjust the scroll speed
        //             }

        //             // Apply smooth scrolling

        //             smoothScroll(note, note.scrollLeft, targetScroll, sensitivity);
        //         }
        //         isMouseMoving= false
        //     }
        // }, 300);


        // let wrapper = document.querySelector('.sindu_dragger')
        // let content = document.querySelector('.gu-mirror')
        // var wrapper_width = wrapper.outerWidth(),
        //     content_width = content.outerWidth(),
        //     limits = 50,
        //     center = wrapper_width / 2,
        //     wrapper_pos = wrapper.offset(),
        //     mousePos = Math.min(Math.max(e.pageX, wrapper_pos.left + limits), wrapper_pos.left + wrapper_width - limits);

        // //calculate new left margin
        // var tmp
        // if (e.pageX > center) {
        //     tmp = (mousePos + 50 - 5) * (content_width - wrapper_width) / wrapper_width;
        // } else {
        //     tmp = (mousePos - 50) * (content_width - wrapper_width) / wrapper_width;

        // }

        // content.stop().animate({
        //     'margin-left': '-' + tmp + 'px'
        // }, 'fast', 'easeOutSine');


    }

    function smoothScroll(element, start, end, sensitivity) {
        let current = start;

        function updateScroll() {
            current += (end - current) * sensitivity;
            element.scrollLeft = current;

            if (Math.abs(end - current) > 1) {
                requestAnimationFrame(updateScroll);
            }
        }

        updateScroll();
    }

    let moveCol = () => {

        tableDragger(document.querySelector("#alltable"))
            .on('drag', (e) => {
                const note = document.querySelector('.sindu_dragger');
                note.style.display = 'flex';
                note.style.overflow = 'scroll';
                note.style.width = "100%";



            })
            .on('drop', async (from, to, el, mode) => {
                if (from !== to) {

                    await getAllColumnIds()
                    props.handleshowHide(-1)
                }

                else {
                    window.location.reload()
                }
                window.removeEventListener('mousemove', scrollDiv);

            })
            .on('shadowMove', (from, to, el, mode) => {
                window.addEventListener('mousemove', scrollDiv);



                // scrollDiv()

                console.log(`moving a column`);
            })
            .on('out', (e, mode) => {
                console.log(`extra dont know`);
            });



    }


    useEffect(() => {
        let temp = []
        props.originalColumn.map((val, ind) => {
            setCloumnVisibility(value => ({
                ...value, ["Visibility" + val.columnId]: true
            }))
        })
        setShowRow(temp)

    }, [props.originalColumn])

    useEffect(() => {
        // console.log("props, ", props.originalColumn)
    }, [])

    return (
        <div className="firstSection" id="firstSection" style={{ maxWidth: "100%", overflow: "visible" }}>
            <table className="table-monitor  table" id="alltable" >
                <thead className="fixedTheadFirst" style={{ width: "100%" }}>
                    <tr key="TableheadKey">
                        <th style={{ width: "40px", textAlign: "center" }} >#</th>
                        {/* <th style={{ width: "220px" }}>
                            Patient Detail{" "}
                            <i
                                className="bi bi-dash-circle remove-icon"
                                title="Remove Column"
                            ></i>
                        </th> */}
                        {/* <td></td> */}
                        {props.originalColumn && props.originalColumn.map((value, index) => {
                            return (

                                cloumnVisibility["Visibility" + value.columnId]
                                &&
                                <th style={{ width: value.columnWidth + "px" }} name={value.columnId} id={value.columnId} data-json={JSON.stringify(value)}>
                                    <div onClick={moveCol}>
                                        {value.columnName}{" "}
                                        {/* <div className='d-flex flex-row gap-1'>
                                                <span style={{ background: color1, width: "10px", height: "10px", borderRadius: "10px" }}></span>
                                                <span style={{ background: color2, width: "10px", height: "10px", borderRadius: "10px" }}></span>
                                                <span style={{ background: color3, width: "10px", height: "10px", borderRadius: "10px" }}></span>
                                            </div> */}
                                    </div>
                                    <i style={{ zIndex: "9999" }}
                                        data-bs-toggle="modal" data-bs-target="#deleteModal"
                                        className="bi bi-dash-circle remove-icon"
                                        title="Remove Column" onClick={() => { setColumnId(value.columnId) }}
                                    ></i>
                                </th>

                            )

                        })}

                    </tr>
                </thead>

                <tbody>

                    {/* shwo data for patient deatls and diagnosis or first half*/}
                    {/* {callPatientDatamemo && callPatientDatamemo} */}
                    {
                        props.fullPatientDataList && props.fullPatientDataList.slice(0, 3).map((val, ind) => {
                            return (
                                <tr key={ind}>
                                    <td>
                                        <div className="overflow-max" style={{ textAlign: "center" }}>
                                            {ind + 1}
                                        </div>
                                    </td>
                                    {/* patient Details */}
                                    {/* <td>
                                        <PatientDeatils val={val} />
                                    </td> */}
                                    {props.originalColumn && props.originalColumn.map((values, ind) => {
                                        return (
                                            cloumnVisibility["Visibility" + values.columnId] &&
                                            // <td onClick={() => { props.handleshowHide(-1) }}>
                                            <td>
                                                <SearchComponent columnId={values.columnId} val={val} />
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {/* <!-- Modal --> */}
            <div className={`modal d-${columnId === null ? 'none' : 'block'}`} >
                <div className="modal-dialog modalDelete" style={{ width: "300px" }}>
                    <div className="modal-content">

                        <div className="modal-body modelbdy text-center">
                            <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                            <div className='popDeleteTitle mt-3'>Remove?</div>
                            <div className='popDeleteContent'>Are you sure you want to remove this column?</div>
                        </div>
                        <div className="modal-footer1 text-center">

                            <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal" onClick={() => { setColumnId(null) }}>No</button>
                            <button type="button" className="btn-delete popBtnDelete" onClick={handleColumnVisibility} data-bs-dismiss="modal">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
