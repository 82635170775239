async function GetAllInvestigationResultOfSHFC(data) {
  const clientID = window.clientId;
  let url = window.LabServicebaseUrl + "/api/DirectTestResult/GetDirectInvestigationResult?testId=" + data.testId + "&sampleCollectionSubID=" + data.sampleCollectionSubID + "&clientId=" + clientID ;
  let head = { "Content-Type": "application/JSON", accept: '*/*' };


  let response = fetch(url, {
    headers: head,
    method: 'GET'
  }).then((res) => res.json()).then(data);

  return response;
}
export default GetAllInvestigationResultOfSHFC;
