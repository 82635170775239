import React, { useEffect, useState } from 'react'
import GetAllDashboardParameterColumnAssign from '../../../Api/GetAllDashboardParameterColumnAssign'
import { useSelector } from 'react-redux'

export default function AllFieldsPRL(props) {
  let [Pr, setPr] = useState(
    {
      VitalValue: "N/A",
      VitalColor: "gray"
    }
  )

  let [paramenterData, setPatameterData] = useState([])
  let response = useSelector((state) => state.AllColumnsParamters)
  let getColumnsParameters = () => {
      let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
         if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

  }
  
  useEffect(() => {
    getColumnsParameters()
    let Pr = {};
    props.val.AdmitTimeVitalsList && props.val.AdmitTimeVitalsList.map((val) => {
      if (val.VitalID === 3) {
        Pr.VitalValue = val.VitalValue

        Pr['VitalColor'] = val.VitalColor

        setPr(Pr)


      }

    })
  }, [props])
  return (
    <>
      {

        paramenterData && paramenterData.map((val, ind) => {
          if (val.parameterId === 38) {
            return (
              <div className="overflow-max vitalClass text-white_" style={{ color: Pr.VitalColor }}>{Pr.VitalValue} <br />{/*<span>{temperature.time}</span>*/}</div>
            )
          }

        })

      }

    </>
  )
}
