import React, { useEffect, useState } from 'react'
import IconIcuAdd from '../../../../../../assets/images/icons/IconIcuAdd.svg';
import setRestrictions from '../../../../../../assets/images/icons/setRestrictionsIcon.svg';
import save from '../../../../../../assets/images/icons/save.svg'
import clear from '../../../../../../assets/images/icons/clear.svg'
import TosterUnderProcess from '../../../../../../Component/TosterUnderProcess'
import Toster from '../../../../../../Component/Toster'
import GetIntakeOutputList from '../ICUChartAPi/GetIntakeOutputList';
import InsertPatientIntakeOutputRange from '../ICUChartAPi/InsertPatientIntakeOutputRange';
import GetPatientIntakeOutputRange from '../ICUChartAPi/GetPatientIntakeOutputRange';

export default function SetRestrictions(props) {
  const [showModal, setShowModal] = useState(false);
  let [showUnderProcess, setShowUnderProcess] = useState(0)
  let [showToster, setShowToster] = useState(0)
  let [tosterMessage, setTosterMessage] = useState("")
  let [tosterValue, setTosterValue] = useState(0)
  const [sendRangeForm, setRangeForm] = useState({ intakeTypeId: '', minRange: '', maxRange: '', remarks: '', intakeType: '' });
  const [intakeOutputList, setIntakeOutputList] = useState([])
  const [brandList, setBrandList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [getPmid, setPmid] = useState(JSON.parse(window.sessionStorage.getItem("IPDpatientList"))[0].pmId);
  const [remarks, setRemarks] = useState('');
  const [minRange, setMinRange] = useState('');
  const [maxRange, setMaxRange] = useState('');
  const [getIntakeOutputData, setIntakeOutputData] = useState([]);
  const closeModal = () => {
    setShowModal(false);
  }

  const handleShowModal = () => {
    setShowModal(true);
  };

  const getIntakeOutputLisData = async () => {
    const IntakeOutputResp = await GetIntakeOutputList();
    if (IntakeOutputResp.status === 1) {
      setIntakeOutputList(IntakeOutputResp.responseValue);
      setBrandList(IntakeOutputResp.responseValue.brandList);
      setTaskList(IntakeOutputResp.responseValue.taskList);
      console.log('brand List', IntakeOutputResp.responseValue.brandList);
      console.log('task List', IntakeOutputResp.responseValue.taskList);
    }
  };
  let handleRangeChange = (e) => {

    console.log("Handling change:", e.target.name, e.target.value);
    //document.getElementById('errVitalRange').style.display = "none";
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'minRange' || name === 'maxRange') {

      value = parseFloat(value);
    }
    if (name === "intakeTypeId") {
      document.getElementById("errIntakeTypeId").style.display = "none";
    }
    else if (name === "minRange") {
      document.getElementById("errMinRange").style.display = "none";

    }
    else if (name === "maxRange") {
      document.getElementById("errMaxRange").style.display = "none";

    }
    else if (name === "remarks") {
      document.getElementById("remarksId").style.display = "none";

    }

    setRangeForm(sendRangeForm => ({
      ...sendRangeForm,
      [name]: value
    }))


  };
  //const selectedBrand = brandList.find((item) => item.dosageFormID.toString() === sendRangeForm.intakeOutputTypeId.toString());



  const insertPatientIntakeOutputRange = async () => {


    sendRangeForm["userId"] = window.userId
    sendRangeForm["pmId"] = getPmid

    // console.log('checkData', sendRangeForm);
    // return;


    console.log('maxRange:', sendRangeForm.maxRange);
    console.log('minRange:', sendRangeForm.minRange);
    console.log('range comparison:', parseFloat(sendRangeForm.maxRange) < parseFloat(sendRangeForm.minRange));
    console.log('maxRange as float:', parseFloat(sendRangeForm.maxRange));
    console.log('minRange as float:', parseFloat(sendRangeForm.minRange));
    if (sendRangeForm.intakeTypeId === '' || sendRangeForm.intakeTypeId === undefined || sendRangeForm.intakeTypeId === null) {
      document.getElementById('intakeTypeId').innerHTML = "Please select Vital";
      document.getElementById('intakeTypeId').style.display = "block";
    }
    else if (sendRangeForm.minRange === '' || sendRangeForm.minRange === undefined || sendRangeForm.minRange === null) {
      document.getElementById('errMinRange').innerHTML = "Please enter minimum Range";
      document.getElementById('errMinRange').style.display = "block";
    }
    else if (sendRangeForm.maxRange === '' || sendRangeForm.maxRange === undefined || sendRangeForm.maxRange === null) {
      document.getElementById('errMaxRange').innerHTML = "Please enter maximum Range";
      document.getElementById('errMaxRange').style.display = "block";
    }

    else if (parseFloat(sendRangeForm.maxRange) < parseFloat(sendRangeForm.minRange)) {

      console.log('Float Maximum Range', parseFloat(sendRangeForm.maxRange));
      console.log('Float Mainimum Range', parseFloat(sendRangeForm.minRange));
      console.log(document.getElementById('errMaxRange').innerHTML = "Maximum Range must be greater than minimum range");
      console.log(document.getElementById('errMaxRange').style.display = "block");
      document.getElementById('errMaxRange').innerHTML = "Maximum Range must be greater than minimum range";
      document.getElementById('errMaxRange').style.display = "block";
    }
    else {
      //const selectedItem = brandList.find((item) => item.id === sendRangeForm.intakeOutputTypeId);
      //sendRangeForm['intakeType'] = selectedItem ? 'I' : 'O';
      const selectedBrand = brandList.find((item) => {
        const dosageFormID = item && item.id;
        const intakeTypeId = sendRangeForm.intakeTypeId;
        console.log("dosageFormID:", dosageFormID);
        console.log("intakeTypeId:", intakeTypeId);
        if (dosageFormID !== undefined && intakeTypeId !== undefined) {
          return dosageFormID.toString() === intakeTypeId.toString();
        }

        return false;
      });
      if (selectedBrand) {
        sendRangeForm['intakeType'] = 'I';
      } else {
        sendRangeForm['intakeType'] = 'O';
      }


      let response = await InsertPatientIntakeOutputRange(sendRangeForm)
      console.log("the flow has passed the api call")
      if (response.status === 1) {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage("Data Saved SuccessFully!")
        setTosterValue(0)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
        //props.setGetData(1)
        handleRangeClear();
        console.log('Intake Output Range Insertion', response)
      }
      else {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage(response.responseValue)
        setTosterValue(1)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)

      }
    }


  }

  let handleRangeClear = () => {

    setRangeForm({
      minRange: '',
      maxRange: '',
      remarks: '',

    });
    document.getElementById('errMinRange').style.display = 'none';
    document.getElementById('errMaxRange').style.display = 'none';
    document.getElementById('remarksId').style.display = 'none';


    //setClearDropdown();

  };

  const getPatientIntakeOutputRangeData = async () => {
    try {
      const ioData = await GetPatientIntakeOutputRange();
      if (ioData.status === 1) {
        console.log('Intake Output data', ioData);
        console.log('Intake Output data range table', getIntakeOutputData);

        setIntakeOutputData(ioData.responseValue);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };
  useEffect(() => {
    getIntakeOutputLisData();
    getPatientIntakeOutputRangeData();
  }, []);
  useEffect(() => {
    console.log('Intake Output data range table11111', getIntakeOutputData);
  }, [getIntakeOutputData]);
  return (
    <>
      <div className='icuChartHeading mb-2'>
        <div className='icuChartHeading-inn'>
          <div className='ICUHead-lft'>
            <span><img src={setRestrictions} alt='' /></span>
            <span><h3>Set Restriction</h3></span>
          </div>
        </div>
        <div className='icuChartHeading-inn pointSpan' style={{display:props.isPrint===1?"none":"block"}}>
          <span><img src={IconIcuAdd} onClick={handleShowModal} alt='' /></span>
        </div>
      </div>
      <div className="med-table-section IcuPhysicalTbl" style={{ "height": "35vh" }}>

        <table className="med-table striped table-responsive">
          <thead>
            <tr>
              <th className="text-center" style={{ "width": "5%" }}>#</th>
              <th>Range Type</th>
              <th>Minimum Range</th>
              <th>Maximum Range</th>
            </tr>
          </thead>
          <tbody>
            {getIntakeOutputData && getIntakeOutputData.map((val, ind) => {

              return (
                <tr key={val.id}>
                  <td className="text-center">{ind + 1}</td>
                  <td>
                    {/* Update the rendering logic for RangeType */}
                    {val.intakeTypeId &&
                      ((brandList.find(item => item.id === val.intakeTypeId)
                        ? brandList.find(item => item.id === val.intakeTypeId).brandName
                        : '') ||
                        (taskList.find(item => item.id === val.intakeTypeId)
                          ? taskList.find(item => item.id === val.intakeTypeId).remark
                          : ''))}
                  </td>
                  <td>{val.minRange}</td>
                  <td>{val.maxRange}</td>
                </tr>
              )
            })}

          </tbody>

        </table>
      </div>


      <div className={`modal fade bk-dropfilter ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
        <div className='modal-dialog' >
          <div className="modal-content p-0">
            <div className="modal-header modalheading">
              <h5 className="modal-title">Set Intake Output Range</h5>
              <i className="fa fa-times" onClick={closeModal}></i>
            </div>

            <div className="modal-body">
              {/*  modal body content  */}
              <div className='row' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div >
                  <label htmlFor="intakeTypeId" className="form-label">Intake/Otput List<span className="icustarMandatory">*</span></label>
                  {/*<div>
                    {vitalMasterList && <DropdownWithSearch defaulNname="Select Vital" name="intakeOutputTypeId" list={vitalMasterList} valueName="id" displayName="vitalName" editdata="" getvalue={handleRangeChange} clear={clearDropdown} clearFun={handleRangeClear} />}
                  </div>*/}
                  <select
                    id="intakeTypeId"
                    name="intakeTypeId"
                    value={sendRangeForm.intakeTypeId}
                    onChange={handleRangeChange}
                  >
                    <optgroup label="Input">
                      {brandList.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.brandName}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Output">
                      {taskList.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.remark}
                        </option>
                      ))}
                    </optgroup>
                  </select>
                  <small
                    id="errIntakeTypeId"
                    className="form-text text-danger"
                    style={{ display: 'none' }}
                  ></small>
                </div>
                <div>
                  <label htmlFor="minimumRange" className="form-label mt-3">
                    Minimum Range
                    <span className="icustarMandatory">*</span></label>
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    id="minRange"
                    name="minRange"
                    value={sendRangeForm.minRange}
                    onChange={handleRangeChange}
                    placeholder="Enter Minimum Range"
                  />
                  <small
                    id="errMinRange"
                    className="form-text text-danger"
                    style={{ display: 'none' }}
                  ></small>
                </div>

                <div>
                  <label htmlFor="minimumRange" className="form-label mt-3">
                    Maximum Range<span className="icustarMandatory">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    id="maxRange"
                    name="maxRange"
                    value={sendRangeForm.maxRange}
                    onChange={handleRangeChange}
                    placeholder="Enter Maximum Range"
                  />
                  <small
                    id="errMaxRange"
                    className="form-text text-danger"
                    style={{ display: 'none' }}
                  ></small>
                </div>

              </div>
              <div>
                <label htmlFor="remarks" className="form-label mt-3">
                  Remarks
                </label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="remarks"
                  name="remarks"
                  value={sendRangeForm.remarks}
                  onChange={handleRangeChange}
                  placeholder="Enter Remarks"
                />
                <small
                  id="remarksId"
                  className="form-text text-danger"
                  style={{ display: 'none' }}
                ></small>
              </div>
              <small id="errIntakeOutputRange" className="form-text text-danger" style={{ display: 'none' }}></small>
            </div>

            <div className='inner-content'>
              <div className='d-flex flex-wrap gap-3 p-2 boxcontainer justify-content-end'>
                <div className="mb-2 justify-content-end">
                  <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                  <div className='diet-btn vital-btn '>
                    <div className='inner-content'>
                      <div className='d-flex flex-wrap gap-3 p-2 boxcontainer justify-content-end'>
                        <div className="mb-2 justify-content-end">
                          <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                          <div className='diet-btn vital-btn '>
                            {showUnderProcess === 1 ? (
                              <TosterUnderProcess />
                            ) : (
                              <>
                                {showToster === 1 ? (
                                  <Toster value={tosterValue} message={tosterMessage} />
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-save btn-save-fill btn-sm"
                                    name='vitalTime'
                                    onClick={insertPatientIntakeOutputRange}
                                  >
                                    <img src={save} className='icnn' alt='' /> Save
                                  </button>
                                )}
                                <button
                                  type="button"
                                  className="btn btn-save btn-sm btnbluehover"
                                  onClick={handleRangeClear}
                                >
                                  <img src={clear} className='icnn' alt='' /> Clear
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>

  )
}
