import React, { useState } from 'react'
import { useEffect } from 'react';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import Toster from '../../../Component/Toster';
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Heading from '../../../Component/Heading';
import BoxContainer from '../../../Component/BoxContainer';
import TableContainer from '../../../Component/TableContainer';
import SuccessToster from '../../../Component/SuccessToster';
import AlertToster from '../../../Component/AlertToster';
import Loader from '../../../Component/Loader';
import { t } from 'i18next';
import Search from '../../../Code/Serach';
import GetConsentMaster from '../../Api/ConsentForms/ConsentMaster/GetConsentMaster';
import PostConsentMaster from '../../Api/ConsentForms/ConsentMaster/PostConsentMaster';
import PutConsentMaster from '../../Api/ConsentForms/ConsentMaster/PutConsentMaster';
import DeleteConsentMaster from '../../Api/ConsentForms/ConsentMaster/DeleteConsentMaster';
import DropdownWithSearch from '../../../Component/DropdownWithSearch';
import GetConsentDataField from '../../Api/ConsentForms/ConsentDataField/GetConsentDataField';
import PostConsentDataField from '../../Api/ConsentForms/ConsentDataField/PostConsentDataField';
import PutConsentDataField from '../../Api/ConsentForms/ConsentDataField/PutConsentDataField';
import DeleteConsentDataField from '../../Api/ConsentForms/ConsentDataField/DeleteConsentDataField';

export default function ConsentDataField() {
    let [consentList, setConsentList] = useState("")
    let [consentDataFieldList, setConsentDataFieldList] = useState("")
    let [consentDataFieldListMain, setConsentDataFieldListMain] = useState("")
    let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [showToster, setShowToster] = useState(0);
    let [tosterMessage, setTosterMessage] = useState("");
    let [tosterValue, setTosterValue] = useState(0);
    let [showLoder, setShowLoder] = useState(0);
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [updateBool, setUpdateBool] = useState(0);
    let [rowId, setRowId] = useState(0);
    let [clearDropdown, setClearDropdown] = useState(0)
    let [editConsent, setEditConsent] = useState("")
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;

    let [sendForm, setSendForm] = useState({
        "userId": window.userId,
        "clientId": window.clientId,
        "consentID": 0,
        "dataFieldId": '',
        "dataFieldName": '',
        "dataType": '',
        "dataSize": null
    })

    //Handle Change
    let handleChange = (e) => {
        setEditConsent("");
        clearValidationErrMessage();
        let name = e.target.name;
        let value = e.target.value;

        if (name === "dataSize" && value.length > 8) {
            value = value.slice(0, 8);
            document.getElementById('errdataSize').innerHTML = "Data Size should be up to 8 digits";
            document.getElementById('errdataSize').style.display = "block";
        } else {
            document.getElementById('errdataSize').style.display = "none";
        }


        setSendForm(sendForm => ({
            ...sendForm,
            [name]: value
        }));
    }

    //Get Data List
    const getdata = async () => {
        setShowLoder(1);
        const response = await GetConsentDataField(clientID);
        if (response.status === 1) {
            setConsentDataFieldList(response.responseValue);
            setConsentDataFieldListMain(response.responseValue)
            setShowLoder(0)
        }
        else {
            setShowLoder(0);
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }
    //Get Consent 
    const getConsent = async () => {
        setShowLoder(1);
        try {
            const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
            const response = await GetConsentMaster(clientID);

            if (!response) {
                console.error("Empty response received from GetConsentMaster API.");
                return;
            }

            if (response.status === 1) {
                setConsentList(response.responseValue);
                setShowLoder(0);
            } else {
                setShowLoder(0);
                setShowAlertToster(1);
                setShowErrMessage(response.responseValue);
                setTimeout(() => {
                    setShowAlertToster(0);
                }, 1500);
            }
        } catch (error) {
            console.error("Error in getdata:", error);
            setShowLoder(0);
            // setShowAlertToster(1);
            // setShowErrMessage("No data available.");
            setTimeout(() => {
                // setShowAlertToster(0);
            }, 1500);
        }
    };


    //handle search
    let handleSearch = (e) => {
        let resp = Search(consentDataFieldListMain, e.target.value)
        if (e.target !== "") {
            if (resp.length !== 0) {
                setConsentDataFieldList(resp)
            }
            else {
                setConsentDataFieldList([])
            }
        }
        else {
            setConsentDataFieldList(consentDataFieldListMain)
        }
    }


    //Handle Save
    const handlerSave = async () => {
        if (sendForm.consentID === '' || sendForm.consentID === 0 || sendForm.consentID === null || sendForm.consentID === undefined) {
            document.getElementById('errconsentId').innerHTML = "Please select consent";
            document.getElementById('errconsentId').style.display = "block";
        }
        else if (sendForm.dataFieldName === '' || sendForm.dataFieldName === null || sendForm.dataFieldName === undefined) {
            document.getElementById('errdataFieldName').innerHTML = "Data field name is required";
            document.getElementById('errdataFieldName').style.display = "block";
        }
        else if (sendForm.dataType === '' || sendForm.dataType === null || sendForm.dataType === undefined) {
            document.getElementById('errdataType').innerHTML = "Data field name is required";
            document.getElementById('errdataType').style.display = "block";
        }

        else {
            setShowUnderProcess(1);
            const obj = {
                "consentID": sendForm.consentID,
                "dataFieldName": sendForm.dataFieldName,
                "dataFieldId": sendForm.dataFieldId,
                "dataType": sendForm.dataType,
                "dataSize": sendForm.dataSize,
                "clientId": clientID,
                "userId": window.userId
            }
            const response = await PostConsentDataField(obj);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Data Saved Successfully..");
                setTimeout(() => {
                    setShowToster(0);
                    handleClear();
                    getdata();

                }, 1500)
            }
            else {
                setShowUnderProcess(0);
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTimeout(() => {
                    setShowToster(0);
                }, 1500)
            }
        }
    }

    //Handle Button Change
    let handleUpdate = async (id, consentID, dataFieldName,dataFieldId, dataType, dataSize, formTitle) => {
        setUpdateBool(1)
        clearValidationErrMessage();
        setSendForm(sendForm => ({
            ...sendForm,
            "id": id,
            "consentID": consentID,
            "dataFieldName": dataFieldName,
            "dataFieldId": dataFieldId,
            "dataType": dataType,
            "dataSize": dataSize,
            "clientId": clientID,
            "userId": window.userId
        }))
        setEditConsent(formTitle)
    }


    //Handle Update
    const handlerUpdate = async () => {
        if (sendForm.consentID === '' || sendForm.consentID === 0 || sendForm.consentID === null || sendForm.consentID === undefined) {
            document.getElementById('errconsentId').innerHTML = "Please select consent";
            document.getElementById('errconsentId').style.display = "block";
        }
        else if (sendForm.dataFieldName === '' || sendForm.dataFieldName === null || sendForm.dataFieldName === undefined) {
            document.getElementById('errdataFieldName').innerHTML = "Data field name is required";
            document.getElementById('errdataFieldName').style.display = "block";
        }
        else if (sendForm.dataType === '' || sendForm.dataType === null || sendForm.dataType === undefined) {
            document.getElementById('errdataType').innerHTML = "Data field name is required";
            document.getElementById('errdataType').style.display = "block";
        }
        else {
            setShowUnderProcess(1);
            console.log('sendFormupdate', sendForm)
            // return;
            const obj = {
                "id": sendForm.id,
                "consentID": sendForm.consentID,
                "dataFieldName": sendForm.dataFieldName,
                "dataFieldId": sendForm.dataFieldId,
                "dataType": sendForm.dataType,
                "dataSize": sendForm.dataSize,
                "clientId": clientID,
                "userId": window.userId
            }
            const response = await PutConsentDataField(obj);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Updated Successfully..");
                setTimeout(() => {
                    setShowToster(0);
                    handleClear();
                    getdata();

                }, 1500)
            }
            else {
                setShowUnderProcess(0);
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTimeout(() => {
                    setShowToster(0);
                }, 1500)
            }
        }
    }

    //Handle Delete
    const handleDelete = async () => {
        setShowLoder(1);
        const obj = {
            id: rowId
        }
        const response = await DeleteConsentDataField(obj);
        if (response.status === 1) {
            setShowLoder(0)
            setisShowToaster(1);
            setShowSuccessMsg("Deleted Successfully")
            setTimeout(() => {
                setisShowToaster(0);
                getdata();
            }, 1500)
            handleClear();
        }
        else {
            setShowLoder(0);
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }

    //Clear Error Message
    const clearValidationErrMessage = () => {
        document.getElementById('errconsentId').style.display = "none";
        document.getElementById('errdataFieldName').style.display = "none";
        document.getElementById('errdataType').style.display = "none";
    }

    //Handle Clear

    const handleClear = (value) => {
        setClearDropdown(value)
        clearValidationErrMessage();
        setUpdateBool(0);
        setSendForm({
            "userId": window.userId,
            "clientId": window.clientId,
            "consentID": 0,
            "dataFieldName": '',
            "dataType": '',
            "dataSize": null
        })
        document.getElementById("dataSize").value = null;
        setEditConsent(0)
    }

    useEffect(() => {
        getdata();
        getConsent();
    }, []);

    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <Heading text="Consent Data Field" />
                            <BoxContainer>
                                <div className="mb-2 me-2">
                                    <label htmlFor="formTitle" className="form-label">{t("Consent")} <span className="starMandatory">*</span></label>
                                    {consentList && <DropdownWithSearch defaulNname={t("Select consent")} name="consentID" list={consentList} valueName="id" displayName="formTitle" editdata={editConsent} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                    <small id="errconsentId" className="invalid-feedback" style={{ display: 'none' }}></small>
                                </div>

                                <div className="mb-2 me-2">
                                    <label htmlFor="dataFieldName" className="form-label">{t("Data Field Name")} <span className="starMandatory">*</span></label>
                                    <input type="text" className="form-control form-control-sm" id="dataFieldName" name='dataFieldName' value={sendForm.dataFieldName} onChange={handleChange} placeholder={t("Enter Data Field Name")} />
                                    <small id="errdataFieldName" className="invalid-feedback" style={{ display: 'none' }}></small>
                                </div>
                                <div className="mb-2 me-2">
                                    <label htmlFor="dataFieldId" className="form-label">Data Field Id <span className="starMandatory"></span></label>
                                    <input type="text" className="form-control form-control-sm" id="dataFieldId" name='dataFieldId' value={sendForm.dataFieldId} onChange={handleChange} placeholder={t("Enter Data Field Name")} />
                                    {/* <small id="errdataFieldName" className="invalid-feedback" style={{ display: 'none' }}></small> */}
                                </div>
                                <div className="mb-2 me-2">
                                    <label htmlFor="dataType" className="form-label">{t("Data Type")} <span className="starMandatory">*</span></label>
                                    <input type="text" className="form-control form-control-sm" id="dataType" name='dataType' value={sendForm.dataType} onChange={handleChange} placeholder={t("Enter Data Type")} />
                                    <small id="errdataType" className="invalid-feedback" style={{ display: 'none' }}></small>
                                </div>
                                <div className="mb-2 me-2">
                                    <label htmlFor="dataSize" className="form-label">{t("Data Size")} <span className="starMandatory"></span></label>
                                    <input type="number" className="form-control form-control-sm" id="dataSize" name='dataSize' value={sendForm.dataSize} onChange={handleChange} placeholder={t("Enter Data Size")} />
                                    {/* <input type="number" className="form-control form-control-sm" id="dataSize" name='dataSize' value={sendForm.dataSize !== null ? sendForm.dataSize : ''} onChange={handleChange} placeholder={t("Enter Data Size")} /> */}
                                    <small id="errdataSize" className="invalid-feedback" style={{ display: 'none' }}></small>
                                </div>

                                <div className="mb-2 relative">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                    <div>
                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />

                                                    : <div>
                                                        {updateBool === 0 ?
                                                            <>
                                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handlerSave}><img src={saveButtonIcon} className='icnn' alt='' />Save</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt='' />Clear</button>
                                                            </>
                                                            :
                                                            <>
                                                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={handlerUpdate}>Update</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={handleClear}>Cancel</button>
                                                            </>
                                                        }
                                                    </div>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </BoxContainer>
                        </div>
                        <div className="col-12 mt-2">
                            <div className='handlser'>
                                <Heading text="Consent Data Field List" />
                                <div style={{ position: 'relative' }}>
                                    <input type="text" className='form-control form-control-sm' placeholder={t("Search")} onChange={handleSearch} />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
                            <div className="med-table-section" style={{ "height": "75vh" }}>
                                <TableContainer>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>#</th>
                                            <th>Consent</th>
                                            <th>Data Field Name</th>
                                            <th>Data Field Id</th>
                                            <th>Data Type</th>
                                            <th>Data Size</th>
                                            <th style={{ "width": "10%" }} className="text-center">Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {consentDataFieldList && consentDataFieldList.length > 0 ? (
                                            consentDataFieldList.map((val, ind) => (
                                                <tr key={val.id}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td>{val.formTitle}</td>
                                                    <td>{val.dataFieldName}</td>
                                                    <td>{val.dataFieldId}</td>
                                                    <td>{val.dataType}</td>
                                                    <td>{val.dataSize}</td>
                                                    <td>
                                                        <div className="action-button">
                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom">
                                                                <img src={editBtnIcon} className="" alt="" onClick={() => { handleUpdate(val.id, val.consentID, val.dataFieldName,val.dataFieldId, val.dataType, val.dataSize, val.formTitle); }} />
                                                            </div>
                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal">
                                                                <img src={deleteBtnIcon} className="" alt="" onClick={() => { setRowId(val.id); }} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4" className="text-center">
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>

                                </TableContainer>
                                {/* -----------------------Start Delete Modal Popup-------------------   */}

                                {/*  <!-- Modal -->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">

                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'>Delete?</div>
                                                <div className='popDeleteContent'>Are you sure want to delete?</div>
                                            </div>
                                            <div className="modal-footer1 text-center">

                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={handleDelete} data-bs-dismiss="modal">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

                            </div>
                        </div>
                    </div>
                </div>
                {
                    showLoder === 1 ? <Loader val={showLoder} /> : ""
                }
                {/* Toaster */}
                {
                    isShowToaster === 1 ?
                        <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                }

                {
                    showAlertToster === 1 ?
                        <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                }
            </section>
        </>
    )
}
