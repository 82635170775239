import React, { useEffect, useState } from 'react'
import PrintPageHeader from '../../../Component/PrintPageHeader'
import PatientLabel from '../../../Component/PatientLabel'
// import SarvodayaLogo from '../../../assets/images/icons/sarvodayalogo.png'
export default function ReceiptClearSlip() {
    let [clearancedata, setClearanceData] = useState()
    let [investigationJSON, setinvestigationJSON] = useState()
    let [diagnosisJSON, setdiagnosisJSON] = useState()
    let [tempJSON, settempJSON] = useState()
    let [clientdata, setClientdata] = useState([])

    let getData = () => {
        console.log('object show');
        let data = JSON.parse(window.sessionStorage.getItem("ClearanceSlipPrintData"));
        setClearanceData(data)
        setinvestigationJSON(JSON.parse(data.rowData.investigationJson))
        // setgetantibioticJson(JSON.parse(data.rowData.antibioticJson))
        let diagnosisJsonCleaned = data.rowData.diagnosisJson.replace(/\u0000/g, ''); // Remove null characters
        setdiagnosisJSON(JSON.parse(diagnosisJsonCleaned));
        //console.log("data", data)
    }


    const ClientData = () => {
        let data = JSON.parse(window.sessionStorage.getItem("LoginData")).clientdata;
        console.log('ClientData', data)
        setClientdata(data)
    }

    let handlepritnt = () => {
        document.title = 'Clearance Slip Receipt';
        window.print("");
        window.close();
    }

    useEffect(() => {
        getData();
        // let clientdata = JSON.parse(window.sessionStorage.getItem("LoginData")).clientdata
        //setClientdata(clientdata)
        ClientData()
        setTimeout(() => {
            handlepritnt();
        }, 1200)

    }, []);

    return (
        <>
            <div className="main-table" style={{ padding: '5px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>

                <div className='clearslipprintpage'>
                    <table className='clearslip'>
                        <tr>
                          
                               
                                    {/* ....header.. */}
                                  
                                        <td colSpan={6}>
                                            <table>
                                                <tr>
                                                    {/* <td colSpan={6}>
                                        <div className="sarvodya-header">
                                            <div className='lft-header '>
                                                <img src={clientdata && clientdata.logoUrl} alt='' className='sv-log' />
                                            </div>
                                            <div className='rght-header mt-2'>
                                                <div> {clientdata && clientdata.clientName}<div className='addrs'>{clientdata && clientdata.address}</div></div>
                                            </div>
                                        </div>
                                    </td> */}
                                                    <td colSpan={8}>
                                                        <PrintPageHeader />
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    {/* ....header.. end*/}

                                    <tr>
                                        <td>
                                            <table>
                                                <tr>
                                                    <td colSpan={3} style={{ verticalAlign: 'top' }}>
                                                        <div className='micro-heading' >
                                                            CLEARANCE SLIP
                                                        </div>
                                                    </td>
                                                    <td colSpan={3} style={{ width: '44%' }}>
                                                        <table className="tb-rght mt-2" style={{ borderLeft: 'none', width: '90%' }}>

                                                            <div className='mt-0 pt-0 mb-0 pb-0 d-flex'>
                                                                <span style={{ borderLeft: "1px solid black" }}>

                                                                    <PatientLabel />
                                                                </span>
                                                            </div>

                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={6} >
                                            <div className='procedure-heading mt-3 mb-3' style={{ fontSize: '20px' }}>
                                                (FOR PROCEDURE/INVESTIGATION)
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={6} >
                                            <table className='clrdtlsmain'>
                                                <tr>
                                                    {/* <td colSpan={2}><strong>Bed No.: </strong><span style={{ width: '180px', borderBottom: '1px dashed black', display: 'inline-block' }}>{clearancedata && clearancedata.bedName}</span> </td>
                                    <td colSpan={2} style={{ width: '31%' }}><strong>IP No: </strong><span style={{ width: '150px', borderBottom: '1px dashed black', display: 'inline-block' }}>{clearancedata && clearancedata.ipNo}</span> </td>
                                    <td colSpan={2} style={{ width: '31%' }}><strong>Date: </strong><span style={{ width: '150px', borderBottom: '1px dashed black', display: 'inline-block' }}>{clearancedata && clearancedata.createdDate}</span> </td> */}
                                                    <td>
                                                        <div className='clinlcdetls'>
                                                            <div><strong>Bed No.: </strong><span style={{ width: '180px', borderBottom: '1px dashed black', display: 'inline-block' }}>{clearancedata && clearancedata.bedName}</span> </div>
                                                            <div><strong>IP No: </strong><span style={{ width: '150px', borderBottom: '1px dashed black', display: 'inline-block' }}>{clearancedata && clearancedata.ipNo}</span> </div>
                                                            <div><strong>Date: </strong><span style={{ width: '150px', borderBottom: '1px dashed black', display: 'inline-block' }}>{clearancedata && clearancedata.createdDate}</span> </div>

                                                        </div>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td colSpan={6}><strong>Billing Category : </strong><span style={{ width: '440px', borderBottom: '1px dashed black', display: 'inline-block' }}>{clearancedata && clearancedata.billingCategoryName}</span> </td>

                                                </tr>
                                                <tr>
                                                    <td colSpan={6} style={{ verticalAlign: 'top' }}>
                                                        <div style={{ display: 'flex', gap: '3px' }}>
                                                            <strong>Procedure/Investigation to be done :</strong>
                                                            <span style={{ width: '390px', borderBottom: '1px dashed black', display: 'inline-block' }}>{investigationJSON && investigationJSON.map((val) => val.TestName).join(', ')}</span>
                                                        </div>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={6} style={{ verticalAlign: 'top' }}>
                                                        <div style={{ display: 'flex', gap: '3px' }}>
                                                            <strong>Diagnosis:</strong>
                                                            <span style={{ width: '590px', borderBottom: '1px dashed black', display: 'inline-block' }}>{diagnosisJSON && diagnosisJSON.map((val) => val.details).join(', ')}</span>
                                                        </div>
                                                    </td>

                                                </tr>
                                                {/* <tr>
                                    <td colSpan={6}><strong>Diagnosis:</strong> <span style={{ width: '590px', borderBottom: '1px dashed black', display: 'inline-block' }}>{diagnosisJSON && diagnosisJSON.map((val) => val.details).join(', ')}</span> </td>

                                </tr> */}
                                                <tr>
                                                    <td colSpan={6}><strong>Advised by:</strong> <span style={{ width: '585px', borderBottom: '1px dashed black', display: 'inline-block' }}>{clearancedata && clearancedata.advisedBy}</span> </td>

                                                </tr>
                                                <tr>
                                                    <td colSpan={6}><strong>Procedure Charges:</strong> <span style={{ width: '515px', borderBottom: '1px dashed black', display: 'inline-block' }}>{clearancedata && clearancedata.sum}</span> </td>

                                                </tr>
                                                <tr>
                                                    <td colSpan={6}><strong>Approved by:</strong> <span style={{ width: '575px', borderBottom: '1px dashed black', display: 'inline-block' }}></span> </td>

                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                         
                </div>

            </div>
        </>
    )
}
