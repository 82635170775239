import React from 'react'
import {useState,useEffect} from 'react'
import SuccessToster from "../../Component/SuccessToster";
import AlertToster from "../../Component/AlertToster";
import GetPatientDetailsforSarvodaya from './API/GetPatientDetailsforSarvodaya';
import GetPatinetCondition from './API/GetPatinetCondition';
import GetTransferMethod from './API/GetTransferMethod';
import GetPatientRisk from './API/GetPatientRisk';
import Select from 'react-select';
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import imgPrint from '../../assets/images/icons/imgPrint.svg'
import clearIcon from '../../assets/images/icons/clear.svg';
// 

import BPSystolic from '../../assets/images/vitalsicons/BPSystolic.svg'
import bp from '../../assets/images/vitalsicons/bp.svg'
import temprature from '../../assets/images/vitalsicons/temprature.svg'
import lungs from '../../assets/images/vitalsicons/lungs.svg'
import pulse from '../../assets/images/vitalsicons/pulse.svg'
import spo from '../../assets/images/vitalsicons/spo.svg'
import POSTInsertPatientVital from '../../Clinical/API/OPD/Vitals/POSTInsertPatientVital';
import GetLanguageData from './API/GetLanguageData';
import ProvisonalDiagnosisMainComponent from './ProvisonalDiagnosisMainComponent';
import PostPatientTransferForm from './API/PostPatientTransferForm';
import GetUsers from './API/GetUsers';
import editBtnIcon from '../../assets/images/icons/edit.svg';
import GetAreaMaster from './API/GetAreaMaster';
import GetPatientTransfer from './API/GetPatientTransfer';
import updateIcon from '../../assets/images/icons/updateIcon.svg';
import GetInvestigationDetails from './API/GetInvestigationDetails';
import PatientDeatils from '../../Component/PatientDeatils.js';
// 
export default function PatientTransferForm(props) {
  const currentDate = new Date();
  const DateFormated = currentDate.toISOString().substr(0, 10);
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  const formattedTime = `${hours}:${minutes}:${seconds}`;
  let formattedDate = '';

  const [PatientData, setPatientData] = useState();
  const [PatientCondition, setPatientCondition] = useState([]);
  const [Risk, setRisk] = useState([]);
  const [PatientTransfer, setPatientTransfer] = useState([]);
  const [isClearable,] = useState(true);
  const [isFieldDisabled, setisFieldDisabled] = useState(false)
  const [isProcedureFieldDisabled, setisProcedureFieldDisabled] = useState(true)
  const [isSearchable,] = useState(true);
  let [InvestigationList, setInvestigationList] = useState([])
  const [TransferMethod, setTransferMethod] = useState([]);
  const [LanguageList, setLanguageList] = useState([]);
  const [selectedConditionId, setSelectedConditionId] = useState(null);
  const [selectedTransfetMethod, setSelectedTransfetMethod] = useState(null);
  const [selectedRiskFactor, setSelectedRiskFactor] = useState(null);
  const [LanguageBarrierYes, setLanguageBarrierYes] = useState(0)
  const [LanguageBarrierNo, setLanguageBarrierNo] = useState(0)
  const [FromArea, setFromArea] = useState('');
  const [ProcedureYes, setProcedureYes] = useState(0);
  const [ProcedureNo, setProcedureNo] = useState(0);
  let [saveDiagnosis, setSaveDiagnosis] = useState([]);
  const [ToArea, setToArea] = useState('');
  const [LanguageSpecify, setLanguageSpecify] = useState('');
  const [ReasonForTransfer, setReasonForTransfer] = useState('');
  const [PreMedGiven, setPreMedGiven] = useState('');
  const [CricInfo, setCricInfo] = useState('');
  const [recommendations, setrecommendations] = useState('');
  const [Time, setTime] = useState(formattedTime);
  const [date, setDate] = useState(DateFormated);
  const [PatientVitals , setPatientVitals] = useState([])
  let [showUnderProcess, setShowUnderProcess] = useState(0)
  let [showToster, setShowToster] = useState(0)
  let [tosterMessage, setTosterMessage] = useState("")
  let [tosterValue, setTosterValue] = useState(0)
  let [handoverTonameID,sethandoverTonameID]=useState(JSON.parse(window.sessionStorage.getItem("LoginData")).name);
  let [sendForm, setSendForm] = useState({})
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [AreaTableFrom, setAreaTableFrom] = useState([]);
  let [handOverFromDrodown, sethandOverFromDrodown] = useState([]);
  let [procedureDropdown, setprocedureDropdown] = useState([]);
  let [handOverFromDrodownBefore, sethandOverFromDrodownBefore] = useState([]);
  let [handoverTonameDropdownBefore, sethandoverTonameDropdownBefore] = useState([]);
  let [handoverFromBefore, sethandoverFromBefore] = useState(null);
  let [handoverToBefore, sethandoverToBefore] = useState(null);
  let [AreaTableTo, setAreaTableTo] = useState([]);
  const [rowID, setrowID] = useState(0)
  let [showSuccessToster, setShowSuccessToster] = useState(0)
  let [showMessage, setShowMeassage] = useState("");
  let [handoverFrom, sethandoverFrom] = useState(null);
  let [ProcedurepainScale, setProcedurepainScale] = useState("");
  let [painScale, setpainScale] = useState("");
  let [timeofAssessment, settimeofAssessment] = useState(getCurrentDateTime());
  let [ProcedureCricInfo, setProcedureCricInfo] = useState("");
  let [handoverbeforDate, sethandoverbeforDate] = useState("");
  const [handoverTonameDropdown, sethandoverTonameDropdown] = useState([])
  const [handoverToname, sethandoverToname] = useState(null)
  const [handoverDate, sethandoverDate] = useState('')
  const [handoverTime, sethandoverTime] = useState('')
  const [Procedure, setProcedure] = useState(null)
  const [isUpdateBtnShow, setisUpdateBtnShow] = useState(false)

  function getCurrentDateTime() {
    const now = new Date();
   
    const ISTTime = new Date(now.getTime() );
    
    // Get hours and minutes
    const hours = ISTTime.getHours().toString().padStart(2, '0');
    const minutes = ISTTime.getMinutes().toString().padStart(2, '0');
    
    return hours + ':' + minutes;
  }



  let requiredUHID = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].uhId;
  let requiredpid = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].pid;
  let requiredpmId = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].pmId;
  let VitalDetails = window.sessionStorage.getItem("IPDpatientsendData")[0][5] ? JSON.parse(sessionStorage.getItem("IPDpatientsendData"))[0][5].jsonVital : '';
  let Pid = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].pid;
  const clientID=JSON.parse(sessionStorage.getItem("LoginData")).clientId;
  let [userID] = useState(JSON.parse(sessionStorage.getItem("LoginData")).userId);




  const handleCheckChange = (e, val , data , items) => {
    const {name ,  value} = e.target
    if(name === 'Condition'){
      setSelectedConditionId(val.id);
     console.log('Selected condition id:', val.id);
    }
    if(name === "RiskFactor"){
      setSelectedRiskFactor(data.id)
      console.log('Selected Risk id:', data.id);
    }
    if(name === "methodTransfer"){
      setSelectedTransfetMethod(items.id)
      console.log('Selected Transfer id:', items.id);
    }
    if(name === "LanguageBarrierYes"){
      document.getElementById("specifyLang").style.display = 'block'
      setLanguageBarrierYes(1)
      setLanguageBarrierNo(0)
    }
    if(name === "LanguageBarrierNo"){
      document.getElementById("specifyLang").style.display = 'none'
      setLanguageBarrierYes(0)
      setLanguageBarrierNo(1)
    }
    if(name === "ProcedureNo"){
      setProcedureNo(1)
      setProcedureYes(0)
    }
    if(name === "ProcedureYes"){
      setProcedureNo(0)
      setProcedureYes(1)
    }

    
  };


if (PatientData && PatientData.admitDate && PatientData.admitDate !== "") {
  const parts = PatientData.admitDate.split('/');
  formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;

} 


let handleVitalChange = (e) => {
  
  let name = e.target.name;
  let value = e.target.value;

   if (name === "vmValueBPSys") {
      document.getElementById("errBPS").style.display = "none";

  }
  else if (name === "vmValueBPDias") {
      document.getElementById("errBPD").style.display = "none";

  }
  else if (name === "vmValueSPO2") {
      document.getElementById("errSPO2").style.display = "none";

  }
  else if (name === "vmValueRespiratoryRate") {
      document.getElementById("errRR").style.display = "none";

  }
  else if (name === "vmValueHeartRate") {
      document.getElementById("errHR").style.display = "none";

  }
  else if (name === "vmValuePulse") {
      document.getElementById("errPR").style.display = "none";

  }
  else if (name === "vmValueRbs") {
      document.getElementById("errRBS").style.display = "none";

  }
  else if (name === "vmValueTemperature") {
      document.getElementById("errTemp").style.display = "none";

  }
  setSendForm(sendForm => ({
      ...sendForm,
      [name]: value
  }))

};




let saveData = async () => {
 
  sendForm["handoverTonameId"] = window.handoverTonameId

  let bpsVal = document.getElementById('vmValueBPSys').value;
  let bpdVal = document.getElementById('vmValueBPDias').value;
  let spo2Val = document.getElementById('vmValueSPO2').value;
  let RRVal = document.getElementById('vmValueRespiratoryRate').value;
  let PRVal = document.getElementById('vmValuePulse').value;
  let TempVal = document.getElementById('vmValueTemperature').value;

  let obj={
    uhid : requiredUHID,
    clientId : clientID,
    userId : userID,
    vmValueBPSys : bpsVal,
    vmValueBPDias : bpdVal,
    vmValueSPO2 : spo2Val,
    vmValueRespiratoryRate : RRVal,
    vmValuePulse : PRVal,
    vmValueTemperature : TempVal,
    vitalTime : Time,
    vitalDate : date,
    pid : requiredpid,
    pmId : requiredpmId
  }

   console.log("VitalDetails" , obj)
 
  if(bpsVal === '' && bpdVal ==='' && spo2Val==='' && RRVal==='' && PRVal==="" && TempVal ==='')
  {
      document.getElementById('errVital').innerHTML = "Please enter any vital";
      document.getElementById('errVital').style.display = "block";
  }

  // else if (bpsVal > 500) {
  //     document.getElementById('errBPS').innerHTML = "Enter valid value";
  //     document.getElementById('errBPS').style.display = "block";
  // }
  // else if (bpdVal > 1000) {
  //     document.getElementById('errBPD').innerHTML = "Enter valid value";
  //     document.getElementById('errBPD').style.display = "block";
  // }
  // else if (spo2Val > 100) {
  //     document.getElementById('errSPO2').innerHTML = "Enter valid value";
  //     document.getElementById('errSPO2').style.display = "block";
  // }
  // else if (RRVal > 50) {
  //     document.getElementById('errRR').innerHTML = "Enter valid value";
  //     document.getElementById('errRR').style.display = "block";
  // }
  // else if (PRVal > 100) {
  //     document.getElementById('errPR').innerHTML = "Enter valid value";
  //     document.getElementById('errPR').style.display = "block";
  // }
 
  // else if (TempVal > 110) {
  //     document.getElementById('errTemp').innerHTML = "Enter valid value";
  //     document.getElementById('errTemp').style.display = "block";
  // }

 
  else {
     
      setShowUnderProcess(1)
      let response = await POSTInsertPatientVital(obj)
      if (response.status === 1) {
          setShowUnderProcess(0)
          setShowSuccessToster(1)
          setShowToster(1)
          setShowMeassage(`Data Saved Successfully`)
          handleClear()
          setTosterValue(1)
          setTimeout(() => {
            setShowSuccessToster(1)
          }, 2000)
         
          // handleClear();
          console.log(response)
      }
      else {
          setShowUnderProcess(0)
          setShowToster(1)
          setTosterMessage(response.responseValue)
          setTosterValue(1)
          setTimeout(() => {
              setShowToster(0)
          }, 2000)

      }
  }
}


  const GetPatientData= async()=>{
    let data = await GetPatientDetailsforSarvodaya(requiredUHID)
    if(data.status === 1){
      setPatientData(data.responseValue[0])
      console.log("PatientData" , data.responseValue)
      
    }
   }

   const GetConditions=async()=>{
    let data = await GetPatinetCondition()
    if(data.status === 1){
    console.log("PCondition" , data.responseValue)
    setPatientCondition(data.responseValue)
    }

   }

   const GetRisk=async()=>{
    let data = await GetPatientRisk()
    if(data.status === 1){
    console.log("GetRisk" , data.responseValue)
    setRisk(data.responseValue)
    }
   }
   
   const GetMethodTransfer=async()=>{
    let data = await GetTransferMethod()
    if(data.status === 1){
    console.log("GetTransferMethod" , data.responseValue)
    setTransferMethod(data.responseValue)
    }
   }

   const GetVitalsDetails = ()=>{
    setPatientVitals(VitalDetails)
    console.log("VitalDetails", VitalDetails)
   }


   const GetLanguage = async()=>{

    let data =  await GetLanguageData()
    if(data.status === 1){
      setLanguageList(data.responseValue)
      console.log("LangData" , data.responseValue)
    }

   }


const handleOnChange=(e)=>{
 const {name , value } = e.target
 if(name === "FromArea"){
  setFromArea(value)
 }
 if(name === "ToArea"){
  setToArea(value)
 }
 if(name === "Reason"){
  setReasonForTransfer(value)
 }
 if(name === "Reason"){
  setReasonForTransfer(value)
 }
 if(name === "premedication"){
  setPreMedGiven(value)
 }
 if(name === "criticalInfo"){
  setCricInfo(value)
 }
 if(name === "specificRecomm"){
  setrecommendations(value)
 }
 if(name === "date"){
  setDate(value)
 }
 if(name === "time"){
  setTime(value)
 }
 if(name === "languageSpecify"){
  setLanguageSpecify(value)
 }
 if(name === "handoverDate"){
  sethandoverDate(value)
 }
 if(name === "handoverTime"){
  sethandoverTime(value)
 }
 if(name === "handoverBeforeDate"){
  sethandoverbeforDate(value)
 }
 if(name === "painScale"){
  setpainScale(value)
 }
 if(name === "ProcedurepainScale"){
  setProcedurepainScale(value)
 }
 if(name === "timeofAssessment"){
  settimeofAssessment(value)
 }
 if(name === "ProcedureCricInfo"){
  setProcedureCricInfo(value)
 }

}


const handleSelectChange = (selectedOption , setSelectedFunction) => {
  setSelectedFunction(selectedOption);

};



  let UserAccess = async () => {
    let usermaster = await GetUsers();
    if (usermaster.status === 1) {
      console.log("usermaster", usermaster.responseValue)
     
      sethandoverTonameDropdown(usermaster.responseValue.map(User=>({
        value: User.id,
        label : User.name
      })));
    
    }
  }
  let handoverDropdown = async () => {
    let usermaster = await GetUsers();
    if (usermaster.status === 1) {
      console.log("usermaster", usermaster.responseValue)
     
      sethandOverFromDrodown(usermaster.responseValue.map(User=>({
        value: User.id,
        label : User.name
      })));
    
    }
  }
  let handoverFromBeforeDropdown = async () => {
    let usermaster = await GetUsers();
    if (usermaster.status === 1) {
      console.log("usermaster", usermaster.responseValue)
     
      sethandOverFromDrodownBefore(usermaster.responseValue.map(User=>({
        value: User.id,
        label : User.name
      })));
    
    }
  }
  let handoverToBeforeDropdown = async () => {
    let usermaster = await GetUsers();
    if (usermaster.status === 1) {
      console.log("usermaster", usermaster.responseValue)
     
      sethandoverTonameDropdownBefore(usermaster.responseValue.map(User=>({
        value: User.id,
        label : User.name
      })));
    
    }
  }

const GetArea=async()=>{
  let data = await GetAreaMaster()
  if(data.status === 1){
    setAreaTableFrom(data.responseValue)
    setAreaTableTo(data.responseValue)
    console.log("AreaMaster" ,data.responseValue)
  }
}




const handleOnSave= async()=>{

  let bpsVal = document.getElementById('vmValueBPSys').value;
  let bpdVal = document.getElementById('vmValueBPDias').value;
  let spo2Val = document.getElementById('vmValueSPO2').value;
  let RRVal = document.getElementById('vmValueRespiratoryRate').value;
  let PRVal = document.getElementById('vmValuePulse').value;
  let TempVal = document.getElementById('vmValueTemperature').value;
  let ProbpsVal = document.getElementById('ProcedurevmValueBPSys').value;
  let ProbpdVal = document.getElementById('ProcedurevmValueBPDias').value;
  let Prospo2Val = document.getElementById('ProcedurevmValueSPO2').value;
  let ProRRVal = document.getElementById('ProcedurevmValueRespiratoryRate').value;
  let ProPRVal = document.getElementById('ProcedurevmValuePulse').value;
  let ProTempVal = document.getElementById('ProcedurevmValueTemperature').value;
 

  if(Procedure === null ){
    setShowAlertToster(1)
    setShowMeassage("Please Select Procedure")
    return
  }

  if(FromArea === '' || FromArea === undefined){
    setShowAlertToster(1)
    setShowMeassage("Please Select From Area")
    return
  }
  if(ToArea === '' || ToArea === undefined){
    setShowAlertToster(1)
    setShowMeassage("Please Select To Area")
    return
  }
  if(ReasonForTransfer === '' || ToArea === undefined){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Reason for Transfer")
    return
  }
  if(selectedTransfetMethod === null ){
    setShowAlertToster(1)
    setShowMeassage("Please Select Transfer Method")
    return
  }
 
  if(selectedConditionId === null ){
    setShowAlertToster(1)
    setShowMeassage("Please Select Condition of Patient")
    return
  }
  if(LanguageBarrierYes === 0 && LanguageBarrierNo === 0 ){
    setShowAlertToster(1)
    setShowMeassage("Please Choose Language Barrier")
    return
  }
  if(LanguageBarrierYes === 1 && LanguageSpecify == '' ){
    setShowAlertToster(1)
    setShowMeassage("Please Select Language")
    return
  }
  if(selectedRiskFactor === null ){
    setShowAlertToster(1)
    setShowMeassage("Please Select Risk Factor")
    return
  }
 

  if(bpsVal == ''){
    setShowAlertToster(1)
    setShowMeassage("Please Enter BPS Value" )
    return
  }
  if(bpsVal > 500 ){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Valid BPS Value " )
    return
  }

  if(bpdVal == ''){
    setShowAlertToster(1)
    setShowMeassage("Please Enter BPD Value" )
    return
  }
  if(bpdVal > 1000){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Valid BPD Value" )
    return
  }
  if(spo2Val == ''){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Spo2 Value" )
    return
  }
  if(spo2Val > 100){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Valid Spo2 Value" )
    return
  }
  if(RRVal == ''){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Valid RR Value" )
    return
  }
  if(RRVal > 50){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Valid RR Value" )
    return
  }
  if(PRVal == ''){
    setShowAlertToster(1)
    setShowMeassage("Please Enter PR Value" )
    return
  }
  if(PRVal > 220){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Valid PR Value" )
    return
  }
  if(TempVal == ''){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Temperature Value" )
    return
  }
  if(TempVal > 120){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Valid Temperature Value" )
    return
  }
  if(painScale !== "" && painScale > 10){
    setShowAlertToster(1)
    setShowMeassage("Pain Scale Should be between 0 to 10" )
    return
  }
  if(handoverFromBefore === null){
    setShowAlertToster(1)
    setShowMeassage("Please Select Handover By")
    return
  }
  if(handoverToBefore === null){
    setShowAlertToster(1)
    setShowMeassage("Please Select Handover To")
    return
  }
  if(handoverbeforDate === ''){
    setShowAlertToster(1)
    setShowMeassage("Please Select Handover Date Time")
    return
  }

  if(ProcedureYes === 0 && ProcedureNo === 0){
    setShowAlertToster(1)
    setShowMeassage("Please select is Procedure Completed" )
    return
    }

   if(ProbpsVal == ''){
    setShowAlertToster(1)
    setShowMeassage("Please Enter BPS Value" )
    return
  }
  if(ProbpsVal > 500 ){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Valid BPS Value " )
    return
  }

  if(ProbpdVal == ''){
    setShowAlertToster(1)
    setShowMeassage("Please Enter BPD Value" )
    return
  }
  if(ProbpdVal > 1000){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Valid BPD Value" )
    return
  }
  if(Prospo2Val == ''){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Spo2 Value" )
    return
  }
  if(Prospo2Val > 100){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Valid Spo2 Value" )
    return
  }
  if(ProRRVal == ''){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Valid RR Value" )
    return
  }
  if(ProRRVal > 50){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Valid RR Value" )
    return
  }
  if(ProPRVal == ''){
    setShowAlertToster(1)
    setShowMeassage("Please Enter PR Value" )
    return
  }
  if(ProPRVal > 220){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Valid PR Value" )
    return
  }
  if(ProTempVal == ''){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Temperature Value" )
    return
  }
  if(ProTempVal > 120){
    setShowAlertToster(1)
    setShowMeassage("Please Enter Valid Temperature Value" )
    return
  }
  if(ProcedurepainScale !== '' && ProcedurepainScale > 10){
    setShowAlertToster(1)
    setShowMeassage("Pain Scale value should be between 0 to 10")
    return
  }
  if(handoverFrom === null){
    setShowAlertToster(1)
    setShowMeassage("Please Select Handover By")
    return
  }
  if(handoverToname === null){
    setShowAlertToster(1)
    setShowMeassage("Please Select Handover To")
    return
  }
  if(handoverDate === ''){
    setShowAlertToster(1)
    setShowMeassage("Please Select Handover Date")
    return
  }
  if(handoverTime === ''){
    setShowAlertToster(1)
    setShowMeassage("Please Select Handover Time")
    return
  }


  let obj={
    uhid : requiredUHID,
    clientId : clientID,
    userId : userID,
    vmValueBPSys : ProbpsVal,
    vmValueBPDias : ProbpdVal,
    vmValueRespiratoryRate : ProRRVal,
    vmValueSPO2 : Prospo2Val,
    vmValuePulse : ProPRVal,
    vmValueTemperature : ProTempVal,
    vitalTime : Time,
    vitalDate : date,
    pid : requiredpid,
    pmId : requiredpmId,
    painScaleUsed: ProcedurepainScale,
    handoverFrom: handoverFrom.value,
    handoverTo: handoverToname.value,
    handoverDate: handoverDate,
    handoverTime: handoverTime,
    handoverFromName : handoverFrom.label,
    handoverToName: handoverToname.label,
    isProcedureCompleted : ProcedureYes,
    anyCriticalinformation : ProcedureCricInfo
  }


  const PayLoad = {
    uhid : requiredUHID, 
    clientId: clientID,
    datetimeOfAssessment: timeofAssessment,
    transferFrom: FromArea, 
    transferTo: ToArea,
    transferDate: date,
    transferTime: Time,
    transferReason: ReasonForTransfer,
    transferMethodStatusId: selectedTransfetMethod,
    healthStatusId: selectedConditionId,
    languageBarrier: LanguageBarrierYes == null ? 0 : LanguageBarrierYes,
    languageId: LanguageSpecify == "" ? 0 : LanguageSpecify,
    riskcategoryStatusId: selectedRiskFactor,
    painScaleUsed: painScale,
    criticalInformation: CricInfo,
    specificRecommendation: recommendations,
    handoverFrom: handoverFromBefore.value,
    handoverTo: handoverToBefore.value,
    handoverDateTime: handoverbeforDate,
    procedureId : Procedure.value,
    handoverDetailsAfterProcedureJson: JSON.stringify(obj),
    afetrProcedureDateTime: date,
    afetrProcedureUserId: userID,
    userId: userID
  

  }
  console.log("savedData" , PayLoad)
 
  let data = await PostPatientTransferForm(PayLoad)
  if(data.status === 1){
    saveData()
    setSaveDiagnosis(1)
    setShowSuccessToster(1)
       
      
        setShowMeassage(`Data Saved Successfully`)
        GetMainData()
        setisUpdateBtnShow(false)
         setTimeout(() => {
        setShowSuccessToster(1)
        
      }, 2000);
    } else {
        setShowAlertToster(1)
        setShowMeassage(data.responseValue)
      setTimeout(() => {
        setShowAlertToster(0)
      }, 2000);
   
  }
  

}



// const handleUpdate=async()=>{
 

//   let ProbpsVal = document.getElementById('ProcedurevmValueBPSys').value;
//   let ProbpdVal = document.getElementById('ProcedurevmValueBPDias').value;
//   let Prospo2Val = document.getElementById('ProcedurevmValueSPO2').value;
//   let ProRRVal = document.getElementById('ProcedurevmValueRespiratoryRate').value;
//   // let HRVal = document.getElementById('vmValueHeartRate').value;
//   let ProPRVal = document.getElementById('ProcedurevmValuePulse').value;
//   let ProTempVal = document.getElementById('ProcedurevmValueTemperature').value;
//   // let RBSVal = document.getElementById('vmValueRbs').value;

//     if(ProcedureYes === 0 && ProcedureNo === 0){
//     setShowAlertToster(1)
//     setShowMeassage("Please select is Procedure Completed" )
//     return
//     }

//    if(ProbpsVal == ''){
//     setShowAlertToster(1)
//     setShowMeassage("Please Enter BPS Value" )
//     return
//   }
//   if(ProbpsVal > 500 ){
//     setShowAlertToster(1)
//     setShowMeassage("Please Enter Valid BPS Value " )
//     return
//   }

//   if(ProbpdVal == ''){
//     setShowAlertToster(1)
//     setShowMeassage("Please Enter BPD Value" )
//     return
//   }
//   if(ProbpdVal > 1000){
//     setShowAlertToster(1)
//     setShowMeassage("Please Enter Valid BPD Value" )
//     return
//   }
//   if(Prospo2Val == ''){
//     setShowAlertToster(1)
//     setShowMeassage("Please Enter Spo2 Value" )
//     return
//   }
//   if(Prospo2Val > 100){
//     setShowAlertToster(1)
//     setShowMeassage("Please Enter Valid Spo2 Value" )
//     return
//   }
//   if(ProRRVal == ''){
//     setShowAlertToster(1)
//     setShowMeassage("Please Enter Valid RR Value" )
//     return
//   }
//   if(ProRRVal > 50){
//     setShowAlertToster(1)
//     setShowMeassage("Please Enter Valid RR Value" )
//     return
//   }
//   if(ProPRVal == ''){
//     setShowAlertToster(1)
//     setShowMeassage("Please Enter PR Value" )
//     return
//   }
//   if(ProPRVal > 220){
//     setShowAlertToster(1)
//     setShowMeassage("Please Enter Valid PR Value" )
//     return
//   }
//   if(ProTempVal == ''){
//     setShowAlertToster(1)
//     setShowMeassage("Please Enter Temperature Value" )
//     return
//   }
//   if(ProTempVal > 120){
//     setShowAlertToster(1)
//     setShowMeassage("Please Enter Valid Temperature Value" )
//     return
//   }
//   if(ProcedurepainScale !== '' && ProcedurepainScale > 10){
//     setShowAlertToster(1)
//     setShowMeassage("Pain Scale value should be between 0 to 10")
//     return
//   }
//   if(handoverFrom === null){
//     setShowAlertToster(1)
//     setShowMeassage("Please Select Handover By")
//     return
//   }
//   if(handoverToname === null){
//     setShowAlertToster(1)
//     setShowMeassage("Please Select Handover To")
//     return
//   }
//   if(handoverDateTime === ''){
//     setShowAlertToster(1)
//     setShowMeassage("Please Select Handover Date")
//     return
//   }


//   let obj={
//     uhid : requiredUHID,
//     clientId : clientID,
//     userId : userID,
//     vmValueBPSys : ProbpsVal,
//     vmValueBPDias : ProbpdVal,
//     vmValueRespiratoryRate : ProRRVal,
//     vmValueSPO2 : Prospo2Val,
//     vmValuePulse : ProPRVal,
//     vmValueTemperature : ProTempVal,
//     vitalTime : Time,
//     vitalDate : date,
//     pid : requiredpid,
//     pmId : requiredpmId,
//     painScaleUsed: ProcedurepainScale,
//     handoverFrom: handoverFrom.value,
//     handoverTo: handoverToname.value,
//     handoverDateTime: handoverDateTime,
//     handoverFromName : handoverFrom.label,
//     handoverToName: handoverToname.label,
//     isProcedureCompleted : ProcedureYes,
//     anyCriticalinformation : ProcedureCricInfo
//   }

//   const PayLoad = {
//     id: rowID,
//     handoverDetailsAfterProcedureJson: JSON.stringify(obj),
//     afetrProcedureDateTime: date,
//     afetrProcedureUserId: userID,
//     userId: userID
  

//   }
// console.log("UpdateData" ,PayLoad )

// let data = await PutPatientTransferForm(PayLoad)
// if(data.status === 1){

//   setShowSuccessToster(1)
     
    
//       setShowMeassage(`Data Updated Successfully`)
//       setisUpdateBtnShow(false)
//       setisFieldDisabled(false)
//       handleClear()
//       GetMainData()
//        setTimeout(() => {
//       setShowSuccessToster(1)
      
//     }, 2000);
//   } else {
//       setShowAlertToster(1)
//       setShowMeassage(data.responseValue)
//     setTimeout(() => {
//       setShowAlertToster(0)
//     }, 2000);
 
// }

// }


const handleClear=()=>{
  document.getElementById('vmValueBPSys').value = ""
document.getElementById('vmValueBPDias').value = ""
document.getElementById('vmValueSPO2').value = ""
document.getElementById('vmValueRespiratoryRate').value = ""
document.getElementById('vmValuePulse').value = ""
document.getElementById('vmValueTemperature').value = ""
document.getElementById('ProcedurevmValueBPSys').value = ""
document.getElementById('ProcedurevmValueBPDias').value = ""
document.getElementById('ProcedurevmValueSPO2').value = ""
document.getElementById('ProcedurevmValueRespiratoryRate').value = ""
document.getElementById('ProcedurevmValuePulse').value = ""
document.getElementById('ProcedurevmValueTemperature').value = ""

  setFromArea(0)
  setToArea(0)
  setReasonForTransfer('')
  setCricInfo("")
  setProcedure(null)
  sethandoverFrom(null)
  sethandoverFromBefore(null)
  sethandoverToname(null)
  sethandoverToBefore(null)
  sethandoverDate('')
  sethandoverTime('')
  setLanguageBarrierNo(0)
  setLanguageBarrierYes(0)
  setLanguageSpecify(0)
  setProcedureCricInfo("")
  setPreMedGiven('')
  setProcedureNo(0)
  setProcedureYes(0)
  setSelectedConditionId(null)
  setSelectedRiskFactor(null)
  setSelectedTransfetMethod(null)
  setrecommendations('')
  setpainScale('')
  setProcedurepainScale('')
  setisFieldDisabled(false)
  setisProcedureFieldDisabled(true)
  sethandoverToname(null)
  
}
  

const handlePrint =(index)=>{
  const obj ={
    doa : formattedDate,
    ward : PatientData && PatientData.wardName,
    rowData : PatientTransfer[index]
  }

console.log("printData" ,obj)
window.sessionStorage.setItem("PatientTransferFormPrint" , JSON.stringify(obj))
window.open("/receiptpatienttransferformprint/", "noopener,noreferrer");
}


const handleCancel=()=>{
  setisUpdateBtnShow(false)
  handleClear()
}


const edit=(data,index)=>{

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

 const transferDate = formatDate(data.transferDate);
setisUpdateBtnShow(true)
setrowID(data.id)
setReasonForTransfer(data.transferReason)
setFromArea(data.transferFrom)
setToArea(data.transferTo)
setTime(data.transferTime)


setDate(transferDate);
setSelectedTransfetMethod(data.transferMethodStatusId)
setSelectedConditionId(data.healthStatusId)
setSelectedRiskFactor(data.riskcategoryStatusId)
setLanguageBarrierYes(data.languageBarrier)
setrecommendations(data.specificRecommendation)
setisFieldDisabled(true)

sethandoverFromBefore({
  label : data.handOverFromName,
  value : data.handoverFrom
})
sethandoverToBefore({
  label : data.handToName,
  value : data.handTo
})
sethandoverbeforDate(data.handoverDateTime)
setisProcedureFieldDisabled(false)
console.log("clickedData" , data)
setCricInfo(data.criticalInformation)
if(data.languageBarrier === 1){
  document.getElementById("specifyLang").style.display = 'block'
  setLanguageSpecify(data.languageId)
}



}


const GetMainData=async()=>{
let data = await GetPatientTransfer(requiredUHID)
if(data.status === 1){
  const mappedData = data.responseValue.map(item => {
    return {
      ...item,
      diagnosisFormated: item.diagnosisJson ? JSON.parse(item.diagnosisJson) : [],
      
    };
  });
  setPatientTransfer(mappedData)
  console.log("PatientTransfer" , mappedData)
  let allDiagnosis = [];
  data.responseValue.forEach(item => {
    allDiagnosis.push(item.diagnosisFormated);
 

});
}
}
let getData = async () => {
  let items = await GetInvestigationDetails()
  if (items.status === 1) {
      setInvestigationList(items.responseValue.map(val => ({
        value: val.id,
        label: val.itemName
      })));
      
      console.log("Investigation data" , items.responseValue)

  }

}



   useEffect(() => {
    const updateTime = () => {
      const currentDate = new Date();
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');
      const formattedTime = `${hours}:${minutes}`;
      setTime(formattedTime);
    };
   
    UserAccess()
    GetMainData()
    getData()
    GetConditions()
    GetPatientData()
    GetRisk()
    handoverFromBeforeDropdown()
    handoverToBeforeDropdown()
    handoverDropdown()
    GetLanguage()
    GetArea()
    GetVitalsDetails()
    GetMethodTransfer()


    updateTime();
    const intervalId = setInterval(updateTime, 1000);
    return () => clearInterval(intervalId);

 
  }, [])


    return (
        <>


     <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          
          <div className="col-12"><div className="med-box  mb-1"><div className="title">Patient Transfer Form - Diagnostics/Procedures</div></div></div>
           
              <div className="med-box">
        
                <div className="inner-content">
                <PatientDeatils/>
                  <div className="row">
                     <div className='fieldsett-in col-6'>
                   <div className='fieldsett'>
                    <span className='fieldse'>Assessment Details</span>


                     <div className='row px-2'>

                    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-3">
                      <label htmlFor="Code" className="form-label">Time of Assessment<span className="starMandatory"></span></label>
                      <input  id="dob" type="time" className="form-control form-control-sm"  value={timeofAssessment} name="timeofAssessment"  onChange={handleOnChange}/>
                    </div>

        

                      </div>

                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 px-4">
                    <ProvisonalDiagnosisMainComponent isProvisional = {0} saveDiagnosis={saveDiagnosis} setSaveDiagnosis={setSaveDiagnosis}/>
                     </div>
                     <div className="col-xxl-12 col-xl-6 col-lg-6 col-md-6 mb-1 mt-1 mb-3">
                      <label htmlFor="ddlitemmaster" className="form-label ">Procedure<span className="starMandatory">*</span></label>
                      <Select value={Procedure} is  options={InvestigationList} className=" create-select" id="serviceType" placeholder="Select Procedure" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, setProcedure)} />
                      <small id="errEquipment" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>


                  </div>
              


                      </div>
                    
          

                     <div className='fieldsett-in col-6'>
                   <div className='fieldsett'>
                    <span className='fieldse'>Transfer Details</span>
                     <div className='row px-2'>


                     <div className='row px-2'>

<div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-3">
<label htmlFor="Code" className="form-label">From Area<span className="starMandatory">*</span></label>
<select  className="form-control form-control-sm" value={FromArea} name="FromArea"   onChange={handleOnChange}>
<option value='0' >Select</option>
  {AreaTableFrom && AreaTableFrom.map((val,index)=>{
    return(
      <>
       <option key={index} value={val.id} >{val.areaName}</option>
       </>
    )
  })}
</select>
</div>

<div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-3">
<label htmlFor="Code" className="form-label">To Area<span className="starMandatory">*</span></label>
<select  className="form-control form-control-sm" value={ToArea} name='ToArea'   onChange={handleOnChange}>
<option value='0' >Select</option>
  {AreaTableTo && AreaTableTo.map((val,index)=>{
    return(
      <>
       <option key={index} value={val.id} >{val.areaName}</option>
       </>
    )
  })}
</select>
</div>
<div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-3">
   <label htmlFor="Code" className="form-label">Date<span className="starMandatory"></span></label>
   <input  id="ddalarmtime" type="date" value={date} className="form-control form-control-sm" name="date"   onChange={handleOnChange}/>
 </div>

 <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-3">
   <label htmlFor="Code" className="form-label">Time<span className="starMandatory"></span></label>
   <input  id="ddalarmtime" type="time" value={Time} className="form-control form-control-sm" name="time"   onChange={handleOnChange}/>
 </div>


<div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3" id="paymentModediv">
<label htmlFor="Code" className="form-label">Reason for Transfer/Name of Procedure<span className="starMandatory">*</span></label>
<input id="ddwarningviewtime" type="text" value={ReasonForTransfer} className="form-control form-control-sm"   placeholder='Reason for Transfer/Name of Procedure' name="Reason" onChange={handleOnChange} style={{height: '8vh'}}/>
</div>


<label htmlFor="Code" className="form-label ">Method of Transfer<span className="starMandatory"></span></label>
<div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 ">

   {TransferMethod && TransferMethod.map((items)=>{
      return (
         <div className="d-flex column-gap-3 mt-2 me-3">
         <label htmlFor="canRead" className="form-label">{items.remark}<span className="starMandatory"></span></label>
          <div className="form-check">
          <input   name="methodTransfer" className="form-check-input" type="checkbox"   id={`method-${items.id}`} onChange={(e) => handleCheckChange(e, null, null, items)}
          checked={selectedTransfetMethod === items.id} />
         </div>

           </div>
            )
             })}


</div >

</div>
                      </div>

                  </div>
                  </div>

                  
                
               

                  </div>
               
                </div>
               
              </div>



              <div className="med-box">

                <div className="inner-content">
                  <div className="row">
                 
                
                

                  </div>
               
                </div>
               
              </div>
            
               
            <div className="med-box">
              <div className="inner-content">
              <div className="row">
                <div className='fieldsett-in col-md-6'>
                <div className='fieldsett'>
                <span className='fieldse'>Assessment of Patient</span>

                  <div className='row px-2'>

                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                    <div><label htmlFor="Code" className="form-label mt-2 ">General Condition of Patient<span className="starMandatory">*</span></label></div>
                    <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-2">
                      {PatientCondition && PatientCondition.map((val)=>{
                        return (
                          <div className="d-flex column-gap-3 mt-2 me-3">
                          <label htmlFor="canRead" className="form-label">{val.remark}<span className="starMandatory"></span></label>
                          <div className="form-check">
                            <input  value={val.id} name="Condition"   className="form-check-input" type="checkbox" onChange={(e) => handleCheckChange(e,val)}
                             checked={selectedConditionId === val.id}/>
                          </div>
                    
                        </div>
                        )
                      })}
                    

                    </div>
                    </div >

                   
                    
                  
                  


                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-2 mt-2">
                    <div><label htmlFor="Code" className="form-label mt-2 ">Language Barrier<span className="starMandatory">*</span></label></div>
                       <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-2">
                          <div className="d-flex column-gap-3 mt-2 me-3">
                        <label htmlFor="canRead" className="form-label">Yes<span className="starMandatory"></span></label>
                        <div className="form-check">
                          <input  id="canRead" name="LanguageBarrierYes"   checked={LanguageBarrierYes} className="form-check-input" type="checkbox" onChange={handleCheckChange} />
                        </div>
                      </div>

                      <div className="d-flex column-gap-3 mt-2 me-3">
                        <label htmlFor="canWrite" className="form-label">No<span className="starMandatory"></span></label>
                        <div className="form-check">
                          <input  id="canWrite" name="LanguageBarrierNo"   checked={LanguageBarrierNo} className="form-check-input" type="checkbox"  onChange={handleCheckChange} />
                        </div>
                    
                      </div>
                      <div >
                    <span className="starMandatory"></span>
                    <div className='mt-1' id="specifyLang" style={{display: 'none'}}>
                    
                    <select  className="form-control form-control-sm"   value={LanguageSpecify} name='languageSpecify' onChange={handleOnChange}>
                    <option value='0' >Select</option>
                      {LanguageList && LanguageList.map((val,index)=>{
                        return(
                          <>
                           
                           <option key={index} value={val.id} >{val.language}</option>
                           </>
                        )
                      })}
                     
                    </select>
                    </div>
                      
                     </div>
                       </div>
                     

                    </div>


                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-4 mt-1">
                    <div><label htmlFor="Code" className="form-label mt-2 ">Fall Risk Category<span className="starMandatory">*</span></label></div>
                       <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-2">
                           {Risk && Risk.map((data)=>{
                            return(
                           <div className="d-flex column-gap-3 mt-2 me-3">
                           <label htmlFor="canRead" className="form-label">{data.remark}<span className="starMandatory"></span></label>
                        <div className="form-check">
                          <input value={data.id} name="RiskFactor"   className="form-check-input" type="checkbox"   id={`risk-${data.id}`} onChange={(e) => handleCheckChange(e, null, data)}
                          checked={selectedRiskFactor === data.id} />
                        </div>
                     
                      </div>
                            )
                           })}
                       

                       </div>
                    

                    </div>
                  
                  </div>

                  </div>
                  </div>
                 
           
                <div className='fieldsett-in col-md-6'>
                <div className='fieldsett'>
                <span className='fieldse'>Vital Signs</span>
                     <div className='vitals-cnt customeInput'>                  
                        <div className='vitals-cnt-in'>
                            <img src={BPSystolic} alt="" className='icnn1' />
                            <input type='number'   placeholder='BPS(mm Hg)' name='vmValueBPSys' id='vmValueBPSys' onChange={handleVitalChange}  />
                            <small id="errBPS" className="absolute-alert" style={{ display: 'none' }}></small>
                        </div>                        
                       
                   
                    <div className='vitals-cnt-in'>
                        <img src={bp} alt='' className='icnn1' />
                        <input type='number'   placeholder='BPD(mm Hg)' name='vmValueBPDias' id='vmValueBPDias'  onChange={handleVitalChange} />
                        <small id="errBPD" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={spo} alt='' className='icnn1' />
                        <input type='number'   placeholder='SPO2' name='vmValueSPO2' id='vmValueSPO2'  onChange={handleVitalChange}  />
                        <small id="errSPO2" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={lungs} alt='' className='icnn1' />
                        <input type='number'   placeholder='RR/m' name='vmValueRespiratoryRate' id='vmValueRespiratoryRate' onChange={handleVitalChange}  />
                        <small id="errRR" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>

    
                    <div className='vitals-cnt-in'>
                        <img src={pulse} alt='' className='icnn1' />
                        <input type='number'   placeholder='Pulse(Beats)' name='vmValuePulse' id='vmValuePulse' onChange={handleVitalChange}  />
                        <small id="errPR" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={temprature} alt='' className='icnn1' />
                        <input type='number'   placeholder='Temp(°F)' name='vmValueTemperature' id='vmValueTemperature' onChange={handleVitalChange}  />
                        <small id="errTemp" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>

                    </div>
                 
                  </div>
                <div className='fieldsett mt-3'>
                
               
                    <div className="row">
                    <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-12 mb-3">
                       <label htmlFor="Code" className="form-label">Pain Scale Used<span className="starMandatory"></span></label>
                       <input  id="Ward" type="number"    value={painScale} className="form-control form-control-sm" name="painScale"   placeholder='Pain Scale Used' onChange={handleOnChange}/>
                     </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-12 mb-3">
                       <label htmlFor="Code" className="form-label">Any Pre-medication given<span className="starMandatory"></span></label>
                       <input  id="Ward"   type="text" value={PreMedGiven}  className="form-control form-control-sm" name="premedication"  placeholder='Any Pre-medication given'  onChange={handleOnChange}/>
                     </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-12 mb-3">
                       <label htmlFor="Code" className="form-label">Any Critical Information<span className="starMandatory"></span></label>
                       <input  id="Ward"   type="text"  value={CricInfo} className="form-control form-control-sm" name="criticalInfo"  placeholder='Any Critical Information' onChange={handleOnChange}/>
                     </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-12 mb-3">
                       <label htmlFor="Code" className="form-label"> Specific Recommendation<span className="starMandatory"></span></label>
                       <input  id="Ward" type="text"    value={recommendations} className="form-control form-control-sm" name="specificRecomm"  placeholder='Any Specific Recommendation' onChange={handleOnChange}/>
                     </div>
               
                   
                   
                   
                </div>
                  </div>
                <div className='fieldsett mt-3'>
                <span className='fieldse'>Handover Details</span>
                    
                    <div className="row">
              
                     <div className="col-xxl-4 col-xl-4 col-lg-3 col-md-6 mb-3">
                      <label htmlFor="ddlitemmaster" className="form-label ">Handover By<span className="starMandatory">*</span></label>
                      <Select value={handoverFromBefore} is   options={handOverFromDrodownBefore} className=" create-select" id="serviceType" placeholder="Select handover From" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption , sethandoverFromBefore)} />
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-3 col-md-6 mb-3">
                      <label htmlFor="ddlitemmaster" className="form-label ">Handover To<span className="starMandatory">*</span></label>
                      <Select value={handoverToBefore}  is  options={handoverTonameDropdownBefore} className=" create-select" id="serviceType" placeholder="Select handover to" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption , sethandoverToBefore)} />
                    </div>
                   
                    <div className="col-xxl-4 col-xl-4 col-lg-3 col-md-6 mb-3">
                       <label htmlFor="Code" className="form-label">Handover Date/Time<span className="starMandatory">*</span></label>
                       <input  id="Ward" type="datetime-local"  value={handoverbeforDate}   className="form-control form-control-sm" name="handoverBeforeDate"    onChange={handleOnChange}/>
                     </div>
                   
                </div>
                  </div>
                  </div>
                
        
                  </div>
         </div>
              </div>
         


        
        
     
             
           
        
        <div className="row">
          
                
        </div>
       


         <div className="col-12 mt-1">
         <div className="med-box">
         <div className="inner-content">
                <div className='fieldsett-in col-md-12'>
                <div className='fieldsett'>
                <span className='fieldse'>After Procedure</span>
                <div className="col-xxl-6 col-xl-4 col-lg-4 col-md-6">
                    <div>
                      <label htmlFor="Code" className="form-label mt-2 ">Procedure Completed<span className="starMandatory">*</span></label>
                      <label htmlFor="Code" className="form-label ms-4">Any Critical Information<span className="starMandatory"></span></label>
                      </div>
                       <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">
                          <div className="d-flex column-gap-3 mt-2 me-3">
                        <label htmlFor="canRead" className="form-label">Yes<span className="starMandatory"></span></label>
                        <div className="form-check">
                          <input  id="canRead"   name="ProcedureYes" checked={ProcedureYes} className="form-check-input" type="checkbox" onChange={handleCheckChange} />
                        </div>
                      </div>

                      <div className="d-flex column-gap-3 mt-2 me-3">
                        <label htmlFor="canWrite" className="form-label">No<span className="starMandatory"></span></label>
                        <div className="form-check">
                          <input  id="canWrite"   name="ProcedureNo" checked={ProcedureNo} className="form-check-input" type="checkbox"  onChange={handleCheckChange} />
                        </div>
                      
                    
                      </div>
                      <div >
                    <span className="starMandatory"></span>
                   
                      
                     </div>
                     <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 mb-3">
                       
                       <input  id="Ward"   type="text"   value={ProcedureCricInfo} className="form-control form-control-sm" name="ProcedureCricInfo"  placeholder='Any Critical Information' onChange={handleOnChange}/>
                     </div>
                       </div>
                      
                     

                    </div>



                     <div className='vitals-cnt customeInput'>                  
                        <div className='vitals-cnt-in'>
                            <img src={BPSystolic} alt="" className='icnn1' />
                            <input type='number'   placeholder='BPS(mm Hg)' name='vmValueBPSys' id='ProcedurevmValueBPSys' onChange={handleVitalChange}  />
                            <small id="errBPS" className="absolute-alert" style={{ display: 'none' }}></small>
                        </div>                        
                       
                   
                    <div className='vitals-cnt-in'>
                        <img src={bp} alt='' className='icnn1' />
                        <input type='number'   placeholder='BPD(mm Hg)' name='vmValueBPDias' id='ProcedurevmValueBPDias'  onChange={handleVitalChange} />
                        <small id="errBPD" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={spo} alt='' className='icnn1' />
                        <input type='number'   placeholder='SPO2' name='vmValueSPO2' id='ProcedurevmValueSPO2'  onChange={handleVitalChange}  />
                        <small id="errSPO2" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={lungs} alt='' className='icnn1' />
                        <input type='number'   placeholder='RR/m' name='vmValueRespiratoryRate' id='ProcedurevmValueRespiratoryRate' onChange={handleVitalChange}  />
                        <small id="errRR" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>


                    <div className='vitals-cnt-in'>
                        <img src={pulse} alt='' className='icnn1' />
                        <input type='number'   placeholder='Pulse(Beats)' name='vmValuePulse' id='ProcedurevmValuePulse' onChange={handleVitalChange}  />
                        <small id="errPR" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={temprature} alt='' className='icnn1' />
                        <input type='number'   placeholder='Temp(°F)' name='vmValueTemperature' id='ProcedurevmValueTemperature' onChange={handleVitalChange}  />
                        <small id="errTemp" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>





                    </div>
                         <div className="row">
                         
                         <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-12 mb-3">
                       <label htmlFor="Code" className="form-label">Pain Scale Used<span className="starMandatory"></span></label>
                       <input  id="Ward" type="number"   value={ProcedurepainScale} className="form-control form-control-sm" name="ProcedurepainScale"   placeholder='Pain Scale Used' onChange={handleOnChange}/>
                     </div>

                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-3">
                      <label htmlFor="ddlitemmaster" className="form-label ">Handover By<span className="starMandatory">*</span></label>
                      <Select value={handoverFrom} is  options={handOverFromDrodown} className=" create-select" id="serviceType" placeholder="Select handover From" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption , sethandoverFrom)} />
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-3">
                      <label htmlFor="ddlitemmaster" className="form-label ">Handover To<span className="starMandatory">*</span></label>
                      <Select value={handoverToname} is  options={handoverTonameDropdown} className=" create-select" id="serviceType" placeholder="Select handover to" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption , sethandoverToname)} />
                    </div>
                   
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-3">
                       <label htmlFor="Code" className="form-label">Handover Date<span className="starMandatory">*</span></label>
                       <input  id="Ward" type="date"   value={handoverDate}  className="form-control form-control-sm" name="handoverDate"    onChange={handleOnChange}/>
                     </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-3">
                       <label htmlFor="Code" className="form-label">Handover Time<span className="starMandatory">*</span></label>
                       <input  id="Ward" type="time"   value={handoverTime}  className="form-control form-control-sm" name="handoverTime"    onChange={handleOnChange}/>
                     </div>
                    
                    
                         </div>

                

                  </div>
                  </div>
                  </div>
         </div>
         </div>

          <div className="col-12 ">
             <div className="med-box mt-2">
               <div className="inner-content">
               <div className="row">

                <div className="inner-content text-right">
                  <div className="mb-2 mt-2 relative">
                  <div>
                            {isUpdateBtnShow !== true ? <>
                              <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleOnSave}><img src={saveButtonIcon} className='icnn' />Save</button>
                              <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' />Clear</button>
                            </> :
                              <>
                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1 mx-1" ><img src={updateIcon} className='icnn' alt="" />Update</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleCancel} >Cancel</button>
                              </>
                            }
                          </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mt-3">
              <div className="med-table-section" style={{ "height": "80vh" }}>
                <table className="med-table border striped">
                  <thead style={{zIndex: '0'}}>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>UHID</th>
                      <th>Procedure</th>
                      <th>Diagnosis</th>
                      <th>Transfer From</th>
                      <th>Transfer To</th>
                      <th>Transfer Reason</th>
                      <th>Transfer Time</th>
                      <th style={{ "width": "10%" }} className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {PatientTransfer && PatientTransfer.map((data, index) => {
                      
                        return(
                         <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td>{requiredUHID}</td>
                          <td>{data.procedureName}</td>
                          <td>{data.diagnosisFormated.map((val, index) => (
                              <span key={index}>{val.details}
                                 {index < data.diagnosisFormated.length - 1 && <br />}
                              </span>
                            ))}
                          </td>

                          <td>{data.transferFromName}</td>
                          <td>{data.transferToName}</td>
                          <td>{data.transferReason}</td>
                          <td>{data.transferTime}</td>
                          <td>
                          
                            <div className="action-button">
                            <div
                                data-bs-toggle="tooltip"
                                data-bs-title="Edit Row"
                                data-bs-placement="bottom"
                                className={`${data.handoverDetailsAfterProcedure == '' ? 'd-block' : 'd-none'}`}
                              >
                                <img src={editBtnIcon}  alt='' onClick={() => { edit(data, index) }} />
                              </div>
                              <div data-bs-title="Delete Row" data-bs-placement="bottom" className={`${data.handoverDetailsAfterProcedure == '' ? 'd-none' : 'd-block'}`} ><img src={imgPrint} style={{width: '17px'}} className='' alt='' onClick={()=>handlePrint(index)} />
                            
                              </div>
                             
                            </div>
                          </td>
                        </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>

        </div>

        {showAlertToster === 1 ? (
              <AlertToster message={showMessage} handle={setShowAlertToster} />
            ) : (
              ""
            )}
           {showSuccessToster === 1 ? (
              <SuccessToster
                message={showMessage}
                handle={setShowSuccessToster}
              />
            ) : (
              ""
            )}
      </section>
 
        </>
    )
}


