function ValidationPhysicalActivity(activityId = '', totalTimeInMinutes = "",fromDateTime = "", fromTime = "", remark = "") {
    if (activityId === '') {
        console.log('Empty Activity')
        return ['Enter Activity', "errActivity", false];
    }
    else if (fromDateTime === '') {
        console.log('Empty Date')
        return ['Enter Date ', "errDate", false];
    }
    else if (fromTime === '') {
        return ['Enter Time', "errTime", false];
    }
    else if (totalTimeInMinutes === '') {
        return ['Enter Time in Minutes', "errMinutes",false];
    }
    else if (remark === "") {
        return ['Enter remarks', "errRemarks", false];
    }
    else {
        return ["", "", true];
    }

}

export default ValidationPhysicalActivity;