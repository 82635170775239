import React, { useEffect, useState } from 'react'
import PostAllTasks from '../../Api/Schedule/POST/PostAllTasks';
import view from '../../../../src/assets/images/icons/view.svg';
export default function TaskReport() {
  let [getTask, setTask] = useState([]);
  let [rowDataTable, setrowDataTable] = useState(null);
  let [rowDataResponse, setrowDataResponse] = useState(null);
  let [isshowtaskmodel, setisshowtaskmodel] = useState(0);

  let getTaskList = async () => {
    const userID = JSON.parse(window.sessionStorage.getItem("LoginData")).userId;
    const response = await PostAllTasks(userID);
    if (response.status === 1) {
      setTask(response.responseValue);
      console.log("TaskList", response.responseValue)
      // console.log("template", response.responseValue[0].templateDetails[0])
      console.log(userID)
    }
  }
  function isValidDate(dateString) {
    const dateObject = new Date(dateString);
    return !isNaN(dateObject.getTime());
  }

  const handleshowmodal = (index) => {
    setisshowtaskmodel(1)
    let data = getTask[index]
    setrowDataTable(data)
    setrowDataResponse(data.response)
    console.log(data.response)



  }

  useEffect(() => {
    getTaskList();
  }, []);
  return (
    <>
      <section className="main-content pt-3 mt-5">
        <div className="col-12">
          <div className="med-box">
            <div className="title mx-2">Task Report</div>
            <div className="med-table-section mt-4" style={{ height: '788px' }}>
              <table className="med-table border_ striped">
                <thead>
                  <tr className='table-primary' style={{ color: 'black' }}>
                    <th className="text-center" style={{ "width": "5%" }}>#</th>
                    <th>Response value</th>
                    <th>CategoryId</th>
                    <th>NotificationTitle</th>
                    <th>NotificationTemplateId </th>
                    <th>SenderId</th>
                    <th>RecieverId</th>
                    <th>VerificationType</th>
                    <th>VerifiedBy</th>


{/* 
                    <th>notificationTitle</th>
                  
                    <th>firebaseTemplate</th>
                    <th>signalRTemplate</th>
                    <th>whatsappTemplate</th>
                    <th>createdDate</th> */}
                    <th style={{ "width": "5%" }} className="text-center">Action</th>





                  </tr>
                </thead>
                <tbody>
                  {getTask && getTask.map((data, index) => {
                    

                    return (

                      <tr>
                        <td className="text-center">{index + 1}</td>

                        <td>
    {data.responseValue}
    {data.templateDetails.length > 0 &&
      data.templateDetails.map((template, templateIndex) => (
        <React.Fragment key={templateIndex}>
          {template.id > 0 &&
            <>
              {' '}{/* Space separator */} 
        
              {template.smsTemplate}
              {/* {template.mailTemplate} */}
              {template.notificationTitle} 
              {template.firebaseTemplate} 
              {template.signalRTemplate} 
              {/* {template.firebaseTemplate == null ? '----' : template.firebaseTemplate},
              {template.signalRTemplate == null ? '----' : template.signalRTemplate}, */}
              {template.whatsappTemplate} 
              {template.createdDate}
            </>
          }
        </React.Fragment>
      ))
    }
  </td>
                        {/* <td>{data.responseValue}</td> */}
                        <td>{data.categoryId}</td>
                        <td>{data.notificationTitle}</td>
                        <td>{data.notificationTemplateId}</td>
                        <td>{data.senderId}</td>
                        <td>{data.recieverId}</td>
                        <td>{data.verificationType}</td>
                        <td>{data.verifiedBy}</td>
                        {/* {data.templateDetails.length === 0 ? (
                          <>


                            <td >----</td>
                            <td >----</td>
                            <td >----</td>
                            <td >----</td>
                            <td >----</td>
                          </>

                        ) : (
                          data.templateDetails.map((template, templateIndex) => (
                            <>
                           
                              <td>{template.notificationTitle}</td>
                         
                              <td>{template.firebaseTemplate == null ? '----' : template.firebaseTemplate}</td>
                              <td>{template.signalRTemplate == null ? '----' : template.signalRTemplate}</td>
                              <td>{template.whatsappTemplate}</td>
                              <td>{template.createdDate}</td>
                            </>
                          ))
                        )} */}
                        <td><div className={`action-button ${data.isResponseRequired === true ? 'd-block' : 'd-none'}`}><img src={view} alt='' title='view' onClick={() => handleshowmodal(index)} /></div></td>






                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {isshowtaskmodel === 1 ?
            <div className={`modal d-${isshowtaskmodel === 1 ? "block" : ""}`} id="EditModal" data-bs-backdrop="static">

              <div className="modal-dialog modal-lg">

                <div className="modal-content p-0">

                  <div className="modal-header">

                    <h1 className="modal-title fs-5 text-white" id="exampleModalLabel"> Task  List</h1>

                    <button type="button" className="btn-close_ btnModalClose" title="Close Window" onClick={() => { setisshowtaskmodel(0) }}>

                      <i className="bi bi-x-octagon"></i>

                    </button>

                  </div>

                  <div className="modal-body p-0">

                    <div className="row">

                      <div className="col-12">

                        <div className="med-box">
                          <div className="inner-content">

                            <div className="row">
                              <div className='col-12'>



                                <table className="table-certificate border">
                                  <thead>

                                    <tr>
                                      <th className='text-center'>#</th>
                                      <td className='text-center'>Response</td>
                                    </tr>
                                  </thead>
                                  <tbody>


                                    <tr key="1">
                                      <td className='text-center'>1</td>
                                      
                                      <td className='text-center'>
                                        {/* {rowDataResponse === null ? 'No Data' : 'No Data'} */}
                                        {rowDataResponse.length === 0 ? 'No Data Found' : rowDataResponse}
                                        </td>

                                    </tr>



                                  </tbody>
                                </table>


                              </div>




                            </div>

                          </div>

                        </div>

                      </div>

                    </div>

                  </div>



                </div>

              </div>

            </div> : ''
          }
        </div>
      </section>

    </>
  )
}
