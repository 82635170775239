import React from 'react'
import printuse from '../../../src/assets/css/printuse.css'
// import SarvodayaLogo from '../../../assets/images/icons/sarvodayalogo.png'

export default function ConsentForEndoscopicUltrasonographyERCP() {
    return (
        <>
            <div className="main-table" style={{ padding: '10px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <table className='microtble'>

                    {/* ....header.. */}
                    <tr>
                        <td colSpan={8}>
                            <div className="sarvodya-header">
                                <div className='lft-header mb-3'>
                                    {/* <img alt='' className='sv-log'/>                                   */}
                                </div>
                                <div className='rght-header'>
                                    <div> SARVODAYA HOSPITAL <div className='addrs'>SECTOR-8, FARIDABAD | HELPLINE: 1800 313 1414</div></div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td colSpan={8}>
                            <div className='heading-form mt-3 mb-3'>
                                CONSENT FOR ENDOSCOPIC ULTRASONOGRAPHY (ERCP)
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <table className='tbl-bordr mt-0' style={{ border: '1px solid black' }}>
                                <tr>
                                    <td colSpan={2}><div className='assesmnt mt-3 mb-3'>Date of Consent: <input type="text" id='Date_of_Consent' />  </div></td>
                                    <td rowSpan={4} style={{ borderLeft: '1px solid black', textAlign: 'center' }}>Patients Label</td>
                                </tr>
                                <tr>
                                    <td ><div className='assesmnt mt-2 mb-2'>Time of Consent: <input type="text" id='Time_of_Consent' />  </div></td>
                                    <td ><div className='assesmnt mt-2 mb-2'>Ward.:<input type="text" id='Ward_of_Consent' /> </div></td>
                                </tr>
                                <tr>
                                    <td ><div className='assesmnt mt-3 mb-3'>Diagnosis:  <input type="text" id='Diagnosis_of_Consent' /> </div></td>
                                    <td ><div className='assesmnt mt-3 mb-3'>Procedure:  <input type="text" id='Procedure_of_Consent' />  </div></td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={8}>
                            <div className='ptndetais mt-2'>
                                <div> <strong>I, </strong> <span style={{ width: '280px', borderBottom: '1px dashed black', display: 'inline-block' }}>
                                    sabi</span>  </div>
                                <div><label> <input type="checkbox" /> </label>    The Patient or </div>
                                <div>  <label> <input type="checkbox" /> </label> representative of patient have </div>

                            </div>
                        </td>
                    </tr>                  
                    <tr>
                        <td colSpan={8}>
                        <div className='parainfo2 mt-1'>
                                <strong>(Please tick the correct option above and below)</strong>
                            </div>
                            <div className='parainfo2 mt-1'>
                                I/We read the content in details.
                            </div>
                            <div className='paragraphinfo mt-1'>
                                I/We have been explained the current
                            </div>
                            <div className='paragraphinfo mt-1'>
                                I/We have been explained the current clinical condition of me/my patient.
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-1'>
                                Been explained this consent form in <span style={{ width: '200px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span>which I fully understand and understood the information  provided about Operation/procedure.

                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-2'>
                                <strong>1.</strong>
                                <span>  You have been advised to undergo an endoscopic procedure called ERCP. The procedure is performed to try and relieve obstruction in the bile or pancreatic ducts either due to gallstones or narrowing of the ducts. It is done under sedation or general anaesthesia. The procedure takes about 30-60 min. You need to get hospitalized a day prior to the procedure and usually discharged the day next to the procedure.
                                </span>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-2'>
                                <div> <strong>What is an ERCP?</strong></div>
                                <strong>1.</strong>
                                <span>  ERCP is a procedure that allows the endoscopist to examine the tubes (ducts) that drain bile from your liver and gallbladder and digestive juices from the pancreas. To do this, a flexible telescope (endoscope) is passed into the mouth, through the stomach to the duodenum to find the small opening (called Ampulla of Vater) where the bile and digestive Juices drain into the intestine. A thin tube is then passed through the endoscope and up into the Ampulla so that dye, which can be seen on fluoroscopy (X-Ray), can be injected.
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-1'>
                                <strong>2.</strong>
                                <span>    Sometimes a small cut in the Ampulla is made to help relieve an obstruction in the ducts. This is called a sphincterotomy, and is painless. In other cases, a tube, called a stent is placed through the endoscope into an area where the bile duct is blocked to allow the bile to drain.
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-1'>
                                <strong>3.</strong>
                                <span> Biopsy (sampling) of the ducts is sometimes also required. This is done by passing a small instrument (forceps) through the endoscope to "pinch" out a tiny bit of the lining, which is sent to the laboratory for analysis.
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-2'>
                                <div> <strong>Risks of the procedure</strong></div>
                                <div>There are three sets of procedural risks you should be aware of:</div>
                                <div>  <strong>1.
                                    Risks associated with intravenous sedation</strong></div>
                                <div>  Sedation can occasionally cause problems with breathing, heart rate and blood pressure. If any of these problems do occur, they are normally short lived and can be managed by doctors and nurses.
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo pt-0'>
                                <div>  <strong>2.
                                    Risks associated with endoscopic examination and biopsy</strong></div>
                                <div>
                                    As with any invasive procedure, ERCP has the possibility of complications. A sore throat after the procedure is the most common side effect. More serious complications occur infrequently but can include:
                                </div>
                                <div>Mechanical damage to teeth. For this reason dentures are removed and you will be asked about loose teeth, crowns or bridgework
                                </div>
                                <div>
                                    Chest infection can occur after the procedure if some fluid passes into the lungs. The risk of this is greater with procedures requiring heavier sedation. Treatment with antibiotics is necessary.
                                </div>
                                <div>
                                    Bleeding from the site of a biopsy that can stop spontaneously or will require radiological or- usually minor and stops on its own
                                </div>
                                <div>
                                    Perforation (or tear) of the lining or wall of the digestive tract. This is very rare with a diagnostic examination only, but can occur more often with the more complex procedures.

                                </div>
                                <div>A perforation would require admission to hospital for treatment with fluids and antibiotics, and might require surgery to repair the tear.</div>

                            </div>
                        </td>
                    </tr>
                </table>

            </div>
        </>
    )
}
