async function GetAllWorkFlowTransaction(workflowId, userId, clientId) {
  let url = window.UserbaseUrl + "/api/WorkFlowTransaction/GetAllWorkFlowTransaction?workFlowId="+workflowId+"&userId="+userId+"&clientId="+clientId;
  // let url ="https://localhost:7235/api/WorkFlowMaster/GetAllWorkFlowMaster?ClientId=" + ClientID;
let head = { "Content-Type": "application/JSON", accept : '*/*' };

let response = await fetch(url, {
  headers: head,
  method: 'GET'
})
  .then((res) => res.json())
  .then();

return response;
}
export default GetAllWorkFlowTransaction;