import React, { useState, useEffect, useRef } from 'react'
import Heading from '../../Component/Heading';
import BoxContainer from '../../Component/BoxContainer';
import TableContainer from '../../Component/TableContainer';

import editbtn from '../../assets/images/icons/editbtn.svg'
import delbtn from '../../assets/images/icons/delbtn.svg'
import save from '../../assets/images/icons/save.svg'
import reset from '../../assets/images/icons/reset.svg'
import transfer from '../../assets/images/icons/transfer.svg'
import del from '../../assets/images/icons/del.svg'

import calender from '../../assets/images/icons/calender.svg'
import clock from '../../assets/images/icons/clock.svg'
import dish from '../../assets/images/icons/dish.svg'
import quantity from '../../assets/images/icons/quantity.svg'
import unit from '../../assets/images/icons/unit.svg'
import suppliment from '../../assets/images/icons/suppliment.svg'
import DropdownWithSearch from '../../Component/DropdownWithSearch';
import GetSupplementList from '../API/SupplementIntake/GetSupplementList';
import GetUnitList from '../API/SupplementIntake/GetUnitList';
import validationSupp from '../API/SupplementIntake/ValidationSupp';
import SaveSuppliment from '../API/SupplementIntake/SaveSuppliment';
import GetAllSupplementList from '../API/SupplementIntake/GetAllSupplementList';
import Toster from "../../Component/Toster";
import TosterUnderProcess from '../../Component/TosterUnderProcess';
import Search from '../../Code/Serach';
import deleteBtnIcon from '../../assets/images/icons/delete.svg';
import DeleteSupplement from '../API/SupplementIntake/DeleteSupplement';
import Loader from '../../Component/Loader';
import PatientTabs from './PatientTabs';
import { t } from 'i18next';


export default function SupplementIntake() {
  let [sendForm, setSendForm] = useState({ "userId": window.userId })
  let [txtDate, settxtDate] = useState();
  let [showLoder, setShowLoder] = useState(0);
  let [time, setTime] = useState('');
  let [supId, setSupId] = useState();
  let [supplementData, setSupplementData] = useState();
  let [supplementDataList, setsupplementDataList] = useState([]);
  let [supplementList, setsupplementList] = useState([]);
  let [supplimentName, setsupplimentName] = useState();
  let [unitList, setUnitList] = useState();
  let [loader, setLoader] = useState(0);
  let [currentdatemax, setcurrentdatemax] = useState();
  let [qty, setQty] = useState();
  let [unit, setunit] = useState();
  let [showUnderProcess, setShowUnderProcess] = useState(0);
  let [showToster, setShowToster] = useState(0)
  let [tosterMessage, setTosterMessage] = useState("")
  let [tosterValue, setTosterValue] = useState(0)
  let [clearDropdown, setClearDropdown] = useState(0)
  let [rowID, setRowID] = useState([]);
  let [supTempList, setSupTempList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
  const [selectedSupplement, setSelectedSupplement] = useState('');
  let [filteredSupNameList, setSupFilteredNameList] = useState([]);
  let [searchBySupName, setSearchBySupName] = useState('')
  const containerRef = useRef(null);

  let getDate = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    today = yyyy + "-" + mm + "-" + dd;
    console.log(today);
    document.getElementById("txtDate").value = today;

  };
  let getSupplementList = async (query) => {
    const supplementList = await GetSupplementList(query)
    if (supplementList.status === 1) {
      setSupplementData(supplementList.responseValue);
      setSupTempList(supplementList.responseValue.slice(0, 15));
    }
  }

  // Function to update the current time
  const updateCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;
    setTime(formattedTime);
  };

  let handleChange = (e) => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const time = `${hours}:${minutes}`;
    document.getElementById('errTime').style.display = "none";
    document.getElementById('errDate').style.display = "none";
    document.getElementById('errQuantity').style.display = "none";
    document.getElementById('errSupplement').style.display = "none";
    document.getElementById('errUnit').style.display = "none";
    let name = e.target.name;
    let value = e.target.value;
    console.log("enter", name)

    setSendForm(sendForm => ({
      ...sendForm,
      [name]: value
    }))
    if (name === "txtDate") {
      settxtDate(value)
      getAllSupplementList(value);
    }
    if (name === "time") {
      
        setTime(e.target.value)
      
    }
    if (name === "supplimentName") {
      setsupplimentName(value)
    }
    if (name === "supplimentName") {
      getUnit(value)
    }
    if (name === "quantity") {
      if (/^\d{0,4}$/.test(value)) {
        setQty(value);
      }
    }

    console.log('sendForm', sendForm);
  }
  /////////////Unit List by SupplementName 
  let getUnit = async (val) => {
    let getUnitData = await GetUnitList(val)
    if (getUnitData.status === 1) {
      console.log('uul', getUnitData);
      setUnitList(getUnitData.responseValue);
    }
  }
  ////// Dropdown Supplement List
  // let getSupplementList = async () => {

  //   let supplementList = await GetSupplementList()
  //   if (supplementList.status === 1) {
  //     setSupplementData(supplementList.responseValue);
  //   }
  //   console.log('supplementList', supplementList);
  // }



  let getAllSupplementList = async (txtDate) => {
    const txtGetDate = document.getElementById("txtDate").value;
    //const txtGetDate= '2023-10-03';
    console.log('txtDate', txtGetDate);
    let uhid = window.sessionStorage.getItem("activeUHIDdiet") ? JSON.parse(window.sessionStorage.getItem("activeUHIDdiet")).Uhid : JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid;
    //let uhid = 'UHID00608';
    let entryType = 'D'
    setLoader(1)
    let allSupplementList = await GetAllSupplementList(uhid, txtDate, entryType);
    if (allSupplementList.status === 1) {
      setsupplementList(allSupplementList.foodIntakeList)
      setsupplementDataList(allSupplementList.foodIntakeList);
      setLoader(0)
    }
    else {
      setLoader(0)
      setsupplementDataList([]); // Set empty array when no data is found
    }
    console.log('supplementList', allSupplementList);
  }

  let handleSearch = (e) => {
    let searchValue = e.target.value;
    if (searchValue !== "") {
      let result = Search(supplementList, searchValue);
      if (result.length !== 0) {
        setsupplementDataList(result);
      }
      // else {
      //     setPatientListTemp(patientList)
      // }
    } else {
      setsupplementDataList(supplementList);
    }
  };

  let clear = (value) => {
    setSendForm({
      supplimentName: '',
    })
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    document.getElementById('errTime').style.display = "none";
    document.getElementById('errDate').style.display = "none";
    document.getElementById('errQuantity').style.display = "none";
    document.getElementById('errSupplement').style.display = "none";
    document.getElementById('errUnit').style.display = "none";
    // settxtDate(yyyy + "-" + mm + "-" + dd);
    getAllSupplementList(yyyy + "-" + mm + "-" + dd);
    setsupplimentName('');
    setSelectedSupplement('')
    setSearchBySupName('')
    setClearDropdown(value);
    setQty("");
    document.getElementById('time').value = '';
    document.getElementById('quantity').value = '';
    document.getElementById('unit').value = 0;
    document.getElementById('ddlDataContainersup').style.display = 'none'

  }
  let saveSupplement = async () => {

    document.getElementById('errTime').style.display = "none";
    document.getElementById('errDate').style.display = "none";
    document.getElementById('errQuantity').style.display = "none";
    document.getElementById('errSupplement').style.display = "none";
    document.getElementById('errUnit').style.display = "none";

    // if (sendForm.time === '' || sendForm.time === null || sendForm.time === undefined || sendForm.time === 0) {
    //   document.getElementById('errTime').innerHTML = "Please Select Time";
    //   document.getElementById('errTime').style.display = "block";
    //   return false;
    // }

    if (selectedSupplement === '' || selectedSupplement === null || selectedSupplement === undefined) {
      document.getElementById('errSupplement').innerHTML = "Select Supplement";
      document.getElementById('errSupplement').style.display = "block";
      return false;
    }

    if (sendForm.quantity === '' || sendForm.quantity === null || sendForm.quantity === undefined) {
      document.getElementById('errQuantity').innerHTML = "Enter Quantity";
      document.getElementById('errQuantity').style.display = "block";
      return false;
    }


    if (sendForm.unit === '' || sendForm.unit === null || sendForm.unit === undefined || sendForm.unit === 0) {
      document.getElementById('errUnit').innerHTML = "Select Unit";
      document.getElementById('errUnit').style.display = "block";
      return false;
    }

    if (sendForm.txtDate === undefined) {
      let date = new Date()
      var dd = date.getDate();
      var mm = date.getMonth() + 1; //January is 0!
      var yyyy = date.getFullYear();
      sendForm.txtDate = yyyy + "-" + mm + "-" + dd;
    }
    var valResponse = {
      intakeDateTime: sendForm.txtDate + ' ' + time,
      brandID: supId,
      doseStrength: parseInt(sendForm.quantity),
      doseUnitID: parseInt(sendForm.unit),
      entryType: "D",
      uhid: window.sessionStorage.getItem("activeUHIDdiet") ? JSON.parse(window.sessionStorage.getItem("activeUHIDdiet")).Uhid : JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid,
      //uhid: 'UHID00608',
      userID: userID,
    }
    console.log('valResponse', valResponse);
    setShowUnderProcess(1);
    let data = await SaveSuppliment(valResponse);
    console.log("csd", data.status)
    if (data.status === 1) {
      let date = new Date()
      var dd = date.getDate();
      var mm = date.getMonth() + 1; //January is 0!
      var yyyy = date.getFullYear();
      setShowUnderProcess(0);
      setTosterValue(0);
      setShowToster(1);
      setTosterMessage("Data Save Successfully!");
      getAllSupplementList(yyyy + "-" + mm + "-" + dd);
      setTimeout(() => {
        setShowToster(0);
        clear(1);

      }, 2000)
      setLoader(0)

    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage(data.responseValue)
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
      // setLoader(0)

    }
  }
  let getRowID = (medicationId) => {
    setRowID(medicationId);
  }
  let deleteRow = async () => {
    console.log(rowID);
    setShowUnderProcess(1);
    //let  uhid = 'UHID00608';
    let uhid = window.sessionStorage.getItem("activeUHIDdiet") ? JSON.parse(window.sessionStorage.getItem("activeUHIDdiet")).Uhid : JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid;;
    let data = await DeleteSupplement(rowID, uhid);
    console.log('s'.data);
    if (data.status === 1) {
      let date = new Date()
      var dd = date.getDate();
      var mm = date.getMonth() + 1; //January is 0!
      var yyyy = date.getFullYear();
      setShowUnderProcess(0);
      setShowToster(1);
      setTosterValue(0);
      setTosterMessage("Data Deleted Successfully!");
      setTimeout(() => {
        setShowToster(0);
        clear(1);
        getAllSupplementList(yyyy + "-" + mm + "-" + dd);
      }, 2000)
    }
    else {
      setShowUnderProcess(0);
      setShowToster(1);
      setTosterValue(1);
      setTosterMessage(data.responseValue);
      setTimeout(() => {
        setShowToster(0);
      }, 2000)
    }
  }

  let curdate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    setcurrentdatemax(formattedDate)
    document.getElementById('txtDate').value = formattedDate;
    return formattedDate;
  };
  const handleSelectSup = (param) => {
    console.log("param", param)
    setSelectedSupplement(param.supplimentName);
    getUnit(param.supplimentId)
    setSupId(param.supplimentId);
    document.getElementById('ddlDataContainersup').style.display = "none";
    document.getElementById('errSupplement').style.display = "none";
  }
  const fetchSupData = () => {
    if (!isLoading && supTempList.length < supplementData.length) { // Check if not already loading and there is more data to fetch
      setIsLoading(true);
      const startIndex = supTempList.length;
      const endIndex = Math.min(startIndex + 15, supplementData.length);
      const newData = supplementData.slice(startIndex, endIndex);
      console.log('newData ->', newData)
      setSupTempList(prevData => [...prevData, ...newData]);
      setIsLoading(false);
    }
  };
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 20 && !isLoading) {
      fetchSupData();
    }
  };
  useEffect(() => {
    curdate();
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [containerRef]);

  const handleSearchSupName = (searchValue) => {
    setSelectedSupplement('');
    setSearchBySupName(searchValue);
    let ddlDataContainerValue = document.getElementById('ddlDataContainersup');
    ddlDataContainerValue.style.display = searchValue.length < 3 ? 'none' : 'block';
    if (searchValue.length >= 2) {
      const data = supplementData;
      const filteredData = data.filter((val, i, arr) => {
        var tempData = val.supplimentName.toLowerCase().includes(searchValue.toLowerCase());
        return tempData;
      });
      setSupFilteredNameList(filteredData);
      getSupplementList(searchValue);
    } else {
      setSupFilteredNameList([]);
    }

  }
  useEffect(() => {
    setLoader(1)
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    getDate();
    getSupplementList();
    updateCurrentTime();
    getAllSupplementList(yyyy + "-" + mm + "-" + dd);
  }, []);
  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <PatientTabs />

          <div className="row">

            {/* <div className="col-12 mt-2">  
              <div className='selection'>
                  <div className='selection-in'>
                    <div className='selection-in-sec'>
                       <div className='nameage'>Riya Mishra<span className='ager'>28/M</span></div>
                       <div className='nameage'>UHID<span className='uhid'>2253275</span></div>
                       <div className='dell'><i className='fa fa-times'></i></div>
                    </div>
                  </div>
              </div>  
            </div> */}
            <div className="col-12">

              <div className="fieldsett-in">
                <div className="fieldsett">
                  <span className='fieldse'>Recommended Supplement</span>
                  <BoxContainer>

                    <div className="mb-2 me-2">
                      <img src={calender} className="icnn" />{" "}
                      <label htmlFor="txtDate" className="form-label">
                        Date
                        <span className="starMandatory">*</span>
                      </label>
                      <input
                        type="date"
                        value={txtDate}
                        className="form-control form-control-sm"
                        id="txtDate"
                        // max={currentdatemax}
                        name="txtDate"
                        placeholder="Enter Date"
                        onChange={handleChange}
                      />
                      <small
                        id="errDate"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>
                    <div className="mb-2 me-2">
                      <img src={clock} className="icnn" />{" "}
                      <label htmlFor="time" className="form-label">
                        Time
                        <span className="starMandatory">*</span>
                      </label>
                      <input
                        type="time"
                        className="form-control form-control-sm"
                        id="time"
                        name="time"
                        value={time}
                        placeholder="Enter Time"
                        onChange={handleChange}
                      />
                      <small
                        id="errTime"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>
                    <div className="mb-2 me-2">
                      <label htmlFor="countryId" className="form-label">Supplement <span className="starMandatory">*</span></label>
                      <div className="mb-2 me-2">
                        <input type="text" value={selectedSupplement === '' ? searchBySupName : selectedSupplement} onChange={(e) => { handleSearchSupName(e.target.value) }} className="form-control form-control-sm" id="supplimentName" placeholder="search by Name" name="supplimentName" />
                        <div className="box-container uldrp" id='ddlDataContainersup' style={{ display: 'none' }} ref={containerRef} onScroll={handleScroll}>
                          {supTempList.length > 0 &&
                            supTempList.map((val, index) => {
                              return (
                                <ul class="list-items">
                                  <li style={{ textDecoration: 'none' }} onClick={() => { handleSelectSup(val) }}>{val.supplimentName}</li>
                                </ul>
                              );
                            })}
                        </div>
                        <small id="errSupplement" className="form-text text-danger" style={{ display: 'none' }}></small>
                      </div>
                      {/* {supplementData && <DropdownWithSearch defaulNname="Select Supplement" id="supplimentName" name="supplimentName" list={supplementData} valueName="supplimentId" displayName="supplimentName" editdata={""} clear={clearDropdown} getvalue={handleChange} clearFun={clear} />} */}

                    </div>
                    <div className="mb-2 me-2">
                      <img src={quantity} className='icnn' /> <label htmlFor="Quantity" className="form-label">Quantity <span className="starMandatory">*</span></label>
                      <input type="number" className="form-control form-control-sm" id="quantity" name="quantity" value={qty} onChange={handleChange}
                        placeholder="Enter Quantity" />
                      <small id="errQuantity" className="form-text text-danger" style={{ display: 'none' }}></small>

                    </div>
                    <div className="mb-2 me-2">
                      <img src={unit} className='icnn' /> <label htmlFor="Unit" className="form-label">Unit <span className="starMandatory">*</span></label>
                      <select className="form-select form-select-sm" aria-label=".form-select-sm example" id='unit' name='unit' onChange={handleChange}>
                        <option value='0'>Select Unit</option>{" "}
                        {unitList && unitList.map((val, index) => {
                          return (
                            <option value={val.unitID}>{val.unitName}</option>
                          );
                        })}
                      </select>
                      <small id="errUnit" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>

                    <div className="rt-btns">
                      <BoxContainer>
                        <div className="mb-2 mt-3">
                          {/* <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label> */}
                          {showUnderProcess === 1 ? <><TosterUnderProcess />  </> :
                            showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} />
                              :
                              <div className='diet-btn'>
                                <button type="button" className="btn btn-save btn-save-fill btn-sm" onClick={saveSupplement}><img src={save} className='icnn' /> Save</button>
                                <button type="button" className="btn btn-save btn-sm btnbluehover" onClick={() => { clear(1) }}><img src={reset} className='icnn' /> Reset</button>
                                {/* <button type="button" className="btn btn-save btn-sm btnbluehover"><img src={transfer} className='icnn'/> Repeat Last Day Food</button>
                    <button type="button" className="btn btn-save btn-sm btnbluehover"><img src={del} className='icnn'/> Delete All Food</button> */}
                              </div>
                          }
                        </div>
                      </BoxContainer>
                    </div>
                  </BoxContainer>
                </div>
              </div>


            </div>

            <div className="col-12 mt-2">
              <div className='listdetailsct'>
                <div className='listdetailsct-in'>
                  <div className='listd-in showing'>Recommended Supplement List</div>
                </div>
                <div className='listdetailsct-in'>

                  {/* <div className='listd-in'>
                    <select className="form-select form-select-sm" aria-label=".form-select-sm example">
                        <option selected>All Day Meal</option>
                    </select>
                  </div> */}
                  <div className='listd-in'>

                    <form className="d-flex ms-auto ser" role="search">
                      <input
                        type="search"
                        className="form-control form-control-sm"
                        placeholder="Search.."
                        onChange={handleSearch}
                      />

                      <i className="fa fa-search"></i>
                    </form>
                  </div>
                </div>
              </div>


              <div className="med-table-section" style={{ "height": "67vh" }}>
                <TableContainer>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>Suppliment Name</th>
                      <th>Quantity</th>
                      <th>Date Time</th>
                      {/* <th>Time</th> */}
                      <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {supplementDataList.length > 0 ? (
                      supplementDataList.map((val, ind) => {
                        return (
                          <tr key={val.id}>
                            <td className="text-center">{ind + 1}</td>
                            <td>{val.supplimentName}</td>
                            <td>{val.doseStrength} {val.unitName}</td>
                            <td>{val.medicationEntryDate && val.medicationEntryDate.split(" ")[0]} {val.medicationEntryDate && val.medicationEntryTime}</td>
                            {/* <td>{val.medicationEntryDate && val.medicationEntryTime}</td> */}
                            <td>
                              <div className="action-button">
                                <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowID(val.medicationId); }} />
                                </div>
                              </div>
                            </td>
                          </tr>

                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </TableContainer>
              </div>

            </div>

          </div>
        </div>
        {/* -----------------------Start Delete Modal Popup-------------------    */}

        <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
          <div className="modal-dialog modalDelete">
            <div className="modal-content">
              <div className="modal-body modelbdy text-center">
                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                <div className='popDeleteTitle mt-3'> Delete?</div>
                <div className='popDeleteContent'> Are you sure you want to delete?</div>
              </div>
              <div className="modal-footer1 text-center">

                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn-delete popBtnDelete" onClick={deleteRow} data-bs-dismiss="modal">Delete</button>
              </div>
            </div>
          </div>
        </div>
        {/* -----------------------End Delete Modal Popup---------------------  */}
        {
          showLoder === 1 ? <Loader val={showLoder} /> : ""
        }


      </section>
      <Loader val={loader} />
    </>
  )
}
