import React, { useState, useEffect } from 'react'
import BoxContainer from '../../../Component/BoxContainer'
import TableContainer from '../../../Component/TableContainer'
import Toster from '../../../Component/Toster'
import TosterUnderProcess from '../../../Component/TosterUnderProcess'
import Heading from '../../../Component/Heading'
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import Search from '../../../Code/Serach'
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import PostDepartmentUnitMaster from '../Api/DepartmentUnitMaster/PostDepartmentUnitMaster'
import GetDepartmentUnitMaster from '../Api/DepartmentUnitMaster/GetDepartmentUnitMaster'
import DeleteDepartmentUnitMaster from '../Api/DepartmentUnitMaster/DeleteDepartmentUnitMaster'
import PutDepartmentUnitMaster from '../Api/DepartmentUnitMaster/PutDepartmentUnitMaster'
import ValidationDepartmentUnitMaster from '../../../Validation/StaffDuty/ValidationDepartmentUnitMaster'
import Loader from '../../../Component/Loader'
import SuccessToster from '../../../Component/SuccessToster'
import AlertToster from '../../../Component/AlertToster'
export default function DepartmentUnitMaster() {
    let [departmentUnitList, setDepartmentUnitList] = useState()
    let [departmentUnitListMain, setDepartmentUnitListMain] = useState()
    let [updateBool, setUpdateBool] = useState(0)
    let [sendForm, setSendForm] = useState({ "userId": window.userId, "clientId": window.clientId })
    let [loder, setLoder] = useState(1);
    let [rowId, setRowId] = useState('');

    let [showUnderProcess, setShowUnderProcess] = useState(0)
    let [showToster, setShowToster] = useState(0)
    let [tosterMessage, setTosterMessage] = useState("")
    let [tosterValue, setTosterValue] = useState(0)
    const { t } = useTranslation();

    let [showErrMessage, setShowErrMessage] = useState('');
    let [showLoder, setShowLoder] = useState(0);
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [showAlertToster, setShowAlertToster] = useState(0);



    //Handle Save
    let saveForm = async () => {
        let valresponse = ValidationDepartmentUnitMaster(sendForm.unitName)

        if (valresponse) {
            setShowUnderProcess(1)
            let response = await PostDepartmentUnitMaster(sendForm);
            if (response.status === 1) {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage("Data Save SuccessFully!")
                setTosterValue(0)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)

                handleClear();
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage(response.responseValue)
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }
            getdata()
        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Field can't be blank!")
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }


    // let getdata = async () => {
    //     let getResponse = await GetDepartmentUnitMaster();

    //     if (getResponse.status === 1) {
    //         setDepartmentUnitList(getResponse.responseValue);
    //         setDepartmentUnitListMain(getResponse.responseValue);
    //     } else {
    //         setShowErrMessage(getResponse.responseValue);
    //         setShowLoder(0);
    //         setShowAlertToster(1);
    //         setTimeout(() => {
    //             setShowAlertToster(0);
    //         }, 1500)
    //     }
    // }

    let getdata = async () => {
        try {
            let getResponse = await GetDepartmentUnitMaster();
            if (getResponse.status === 1) {
                setDepartmentUnitList(getResponse.responseValue);
                setDepartmentUnitListMain(getResponse.responseValue);
            } else {
                console.error("Failed to fetch data:", getResponse.responseValue);
                setShowErrMessage(getResponse.responseValue);
                setShowLoder(0);
                setShowAlertToster(1);
                setTimeout(() => {
                    setShowAlertToster(0);
                }, 1500);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    //Handle Search
    let handleSearch = (e) => {
        let resp = Search(departmentUnitListMain, e.target.value)
        if (e.target.value !== "") {
            if (resp.length !== 0) {
                setDepartmentUnitList(resp)
            }
            else {
                setDepartmentUnitList([])
            }
        }
        else {
            setDepartmentUnitList(departmentUnitListMain)
        }
    }
    //Handle Change
    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setSendForm(sendForm => ({
            ...sendForm,
            [name]: value
        }))
    }


    //Handle Delete
    let handleDeleteRow = async () => {
        // setLoder(1)
        setShowUnderProcess(1)
        let obj = {
            id: rowId
        }
        let response = await DeleteDepartmentUnitMaster(obj)
        if (response.status === 1) {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Data Deleted SuccessFully!")
            setTosterValue(0)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
            getdata();
            handleClear();
        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage(response.responseValue)
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }
    //Handle Button Change
    let handleUpdate = async (id, unitName, UserId) => {
        console.log("id", id)
        setUpdateBool(1)
        setSendForm(sendForm => ({
            ...sendForm,
            "Id": id,
            "unitName": unitName,
            "UserId": window.userId,
        }))

        document.getElementById("unitName").value = unitName;
    }



    // Handle Update
    let saveUpdate = async () => {
        let valresponse = ValidationDepartmentUnitMaster(sendForm.unitName)

        if (valresponse) {
            setShowUnderProcess(1)
            let response = await PutDepartmentUnitMaster(sendForm)
            if (response.status === 1) {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage("Data Updated SuccessFully!")
                setTosterValue(0)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)

                setUpdateBool(0)
                getdata()
                handleClear();
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage(response.responseValue)
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }

        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Field can't be blank!")
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }

    //Handle Clear
    let handleClear = () => {
        setSendForm({ "userId": window.userId, "clientId": window.clientId })
        document.getElementById("unitName").value = "";
        setUpdateBool(0)
    }
    useEffect(() => {
        getdata()
    }, []);
    document.body.dir = i18n.dir();
    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <Heading text={t("Department Unit Master")} />
                            <BoxContainer>

                                <div className="mb-2 me-2">
                                    <label htmlFor="lifeSupport" className="form-label">{t("Department Unit Name")}<span className="starMandatory">*</span></label>
                                    <input type="text" name="unitName" id="unitName" onChange={handleChange} className="form-control form-control-sm" placeholder={t("Enter department unit")} value={sendForm.unitName}/>
                                    <small id="errUnitName" className="invalid-feedback" style={{ display: 'none' }}></small>
                                </div>

                                <div className="mb-2 relative">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                    <div>
                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />

                                                    : <div>
                                                        {updateBool === 0 ?
                                                            <>
                                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={saveForm}><img src={saveButtonIcon} className='icnn' />{t("Save")}</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' />{t("Clear")}</button>
                                                            </>
                                                            :
                                                            <>
                                                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={saveUpdate}>{t("UPDATE")}</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { setUpdateBool(0); handleClear() }}>{t("Cancel")}</button>
                                                            </>
                                                        }
                                                    </div>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </BoxContainer>
                        </div>
                        <div className="col-12 mt-2">
                            <div className='handlser'>
                                <Heading text={t("All Department Unit List")} />
                                <div style={{ position: 'relative' }}>
                                    <input type="text" className='form-control form-control-sm' placeholder={t("Search")} onChange={handleSearch} />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
                            <div className="med-table-section" style={{ "height": "75vh" }}>
                                <TableContainer>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>#</th>
                                            <th>{t("Department Unit Name")}</th>
                                            <th style={{ "width": "10%" }} className="text-center"> {t("Action")}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {departmentUnitList && departmentUnitList.map((val, ind) => {
                                            return (
                                                <tr key={val.id}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td>{val.unitName}</td>
                                                    <td>
                                                        <div className="action-button">
                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={editBtnIcon} className='' alt='' onClick={() => { handleUpdate(val.id, val.unitName, val.userId) }} /></div>
                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowId(val.id) }} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}


                                    </tbody>
                                </TableContainer>
                                {/* -----------------------Start Delete Modal Popup-------------------   */}

                                {/*  <!-- Modal -->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">

                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>

                                                <div className='popDeleteTitle mt-3'>{t("Delete?")}</div>
                                                <div className='popDeleteContent'> {t("Are_you_sure_you_want_to_delete?")}</div>
                                            </div>
                                            <div className="modal-footer1 text-center">

                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={handleDeleteRow} data-bs-dismiss="modal">{t("Delete")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

                            </div>
                        </div>
                    </div>
                </div>
                {
                    showLoder === 1 ? <Loader val={showLoder} /> : ""
                }
                {/* Toaster */}
                {
                    isShowToaster === 1 ?
                        <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                }

                {
                    showAlertToster === 1 ?
                        <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                }

            </section>
        </>
    )
}
