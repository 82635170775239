import React, { useState, useEffect } from 'react'
import saveButtonIcon from '../../../assets/images/icons/send.svg';
import imgReset from '../../../assets/images/icons/reset.svg'
import BoxContainer from '../../../Component/BoxContainer';

import NoDataFound from '../../../Component/NODataFound'
import AlertToster from '../../../Component/AlertToster';
import Heading from '../../../../src/Component/Heading';
import GetPharmacyMaster from '../../CurrentStock/API/GetPharmacyMaster'
import GetPharmacyInwardStock from '../../CurrentStock/API/GetPharmacyInwardStock'
import DropdownWithSearch from '../../../Component/DropdownWithSearch';
import GetPharmacyAllStockByPharmacyId from '../../Sale/API/GetPharmacyAllStockByPharmacyId'
import GetGodownMaster from '../../Purchase/API/GetGodownMaster'
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import clearIcon from '../../../assets/images/icons/clear.svg';
import TosterUnderProcess from '../../../Component/TosterUnderProcess'
import Toster from '../../../Component/Toster'
import SuccessToster from '../../../../src/Component/SuccessToster'
import GetUnitMaster from '../../UnitMaster/API/GetUnitMaster';
import PostTransferPharmacyToGodown from '../API/PostTransferPharmacyToGodown'
import ValidationTransferPharamcyToGodown from '../../../Validation/Pharmacy/ValidationTransferPharamcyToGodown'
import Loader from '../../../Component/Loader';

export default function PharmacyCurrentStock() {

  const { t } = useTranslation();
  document.body.dir = i18n.dir();
  let [pharmacyStockList, setPharmacyStockList] = useState([])
  let [godownList, setGodownList] = useState([]);
  let [showImage, setShowImage] = useState(0);
  let [pharmacyProdctList, setPharmacyProdctList] = useState([]);
  let [pharmacyList, setPharmacyList] = useState([]);
  let [unitList, setUnitList] = useState([])
  let [productList, setProductList] = useState([]);
  let [productId, setProductId] = useState();
  let [unitId, setUnitId] = useState();
  let [baseUnit, setBaseUnit] = useState();
  let [batchNo, setBatchNo] = useState();
  let [convertedUnitId, setConvertedUnitId] = useState();
  let [godownId, setGodownId] = useState();
  let [multipliedBy, setMultipliedBy] = useState();
  let [pharmacyId, setPharmacyId] = useState();
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [itemList, setItemList] = useState()
  let [clearDropdown, setClearDropdown] = useState(0)
  let [sendJsonPharmacyTransfer, setSendJsonPharmacyTransfer] = useState([])
  const [inputValues, setInputValues] = useState({ "userId": window.userId });
  let [showMessage, setShowMeassage] = useState("")
  let [showSuccessToster, setShowSuccessToster] = useState(0)
  const [searchInput, setSearchInput] = useState('');
  let [isShowIssueModel, setIsShowIssueModel] = useState(0);
  let [rowId, setrowId] = useState('');
  let [showUnderProcess, setShowUnderProcess] = useState(0);
  let [showToster, setShowToster] = useState(0);
  let [tosterValue, setTosterValue] = useState(0);
  let [tosterMessage, setTosterMessage] = useState("");
  let [loder, setLoder] = useState(1)


  const getALLPharmacyStock = async () => {
    setLoder(1)

    let pharmacyStock = await GetPharmacyInwardStock()
    let pharmacyresponse = await GetPharmacyMaster();
    let response = await GetGodownMaster();
    if (pharmacyStock.status === 1) {
      setLoder(0)
      setPharmacyStockList(pharmacyStock.responseValue)

    }

    if (pharmacyresponse.status === 1) {
      setPharmacyList(pharmacyresponse.responseValue)

    }
    if (response.status === 1) {
      setGodownList(response.responseValue)
      console.log("godownList", godownList)
    }
  }



  const getPharmacyStockById = async (pharmacyId) => {

    setShowToster(1)
    setShowUnderProcess(1)
    setTimeout(() => {
      setShowToster(0)
      setShowUnderProcess(0)
    }, 4000)

    let pharIdStock = await GetPharmacyAllStockByPharmacyId(pharmacyId)





    if (pharIdStock.status === 1) {
      setPharmacyProdctList(pharIdStock.responseValue)

    }


  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log("value", value)



    if (name === "pharmacyId") {
      setPharmacyId(value);
      getPharmacyStockById(value)
    }

    if (name === "pharmacyId") {
      setPharmacyId(value);

    }

    setInputValues(inputValues => ({
      ...inputValues,
      [name]: value,
    }));
  }

  let handleCheckBox = async (ind) => {
    let tempArray = [];

    for (var i = 0; i < pharmacyProdctList.length; i++) {
      const findID = pharmacyProdctList[i].id;
      // console.log("findID", findID)
      const isChecked = document.getElementById(findID).checked;

      console.log("isChecked", isChecked)

      if (isChecked === true) {

        const transferQty = document.getElementById(`transferQty${i}`).value;

        tempArray.push({
          productId: pharmacyProdctList[i].productId,
          unitId: pharmacyProdctList[i].unitID,
          hsnCodeId: pharmacyProdctList[i].hsnCodeId,
          batchNo: pharmacyProdctList[i].batchNo,
          batchMfg: formatDate(pharmacyProdctList[i].batchMfg),
          batchExpiry: formatDate(pharmacyProdctList[i].batchExpiry),
          unitPrice: pharmacyProdctList[i].baseUnitPrice,
          quantity: transferQty,

          isknowmed: 1
        });



      }
      else if (document.getElementById(findID).checked = false) {
        setSendJsonPharmacyTransfer([])
      }
    }
    console.log("tempArray", tempArray)

    setSendJsonPharmacyTransfer([...tempArray]);



  }

  function formatDate(dateString) {
    // Parse the original date string
    const originalDate = new Date(dateString);

    // Format the date as "YYYY-MM-DDTHH:mm:ss"
    const formattedDate = originalDate.toISOString().split('T')[0] + 'T00:00:00';

    return formattedDate;
  }

  let handleAllCheckBox = async (id) => {
    // let totalAmount = 0;
    if (id === -1) {
      let a = document.getElementById(-1).checked;

      let arr = [];
      if (a) {
        pharmacyProdctList
          .map((val, ind) => {

            const transferQty = document.getElementById(`transferQty${ind}`).value;

            document.getElementById(val.id).checked = true;

            arr.push(
              {
                productId: pharmacyProdctList[ind].productId,
                unitId: pharmacyProdctList[ind].unitID,
                hsnCodeId: pharmacyProdctList[ind].hsnCodeId,
                batchNo: pharmacyProdctList[ind].batchNo,
                batchMfg: formatDate(pharmacyProdctList[ind].batchMfg),
                batchExpiry: formatDate(pharmacyProdctList[ind].batchExpiry),
                unitPrice: pharmacyProdctList[ind].baseUnitPrice,
                quantity: transferQty,

                isknowmed: 1
              });
            // let amount = document.getElementById("returnAmount" + ind).value;
            // totalAmount += parseInt(amount);
          });
        console.log("arr", arr)

        setSendJsonPharmacyTransfer([...arr]);
        // setTotalReturnAmount(totalAmount);
      } else {
        pharmacyProdctList.map((val, ind) => {
          document.getElementById(val.id).checked = false;

        });
        document.getElementById(-1).checked = false;
        console.log("arr", [...arr])
        setSendJsonPharmacyTransfer([]);
        // setTotalReturnAmount(0);
        console.log("sendJsonPharmacyTransfer", [sendJsonPharmacyTransfer])
      }

    }
  }



  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);

  let handleSave = async () => {
    console.log("sendJsonPharmacyTransfer", sendJsonPharmacyTransfer)
    try {

      let valresponse = ValidationTransferPharamcyToGodown(inputValues.godownId, inputValues.pharmacyId)

      if (valresponse[0]) {
        console.log("isAnyCheckboxChecked", isAnyCheckboxChecked)
        if (!isAnyCheckboxChecked) {
          setShowMeassage("select any item")
          setShowAlertToster(1)
          setTimeout(() => {
            setShowToster(0)
          }, 2000)
          return;
        }

        const returnData = {

          godownId: inputValues.godownId,
          pharmacyId: inputValues.pharmacyId,
          JsonPurchaseTransfer: JSON.stringify(sendJsonPharmacyTransfer),
          userId: window.userId,

        };
        let response = await PostTransferPharmacyToGodown(returnData);
        if (response.status === 1) {
          setShowMeassage("Data Saved Successfully!!")
          setShowSuccessToster(1)
          setTimeout(() => {
            setShowToster(0)
          }, 2000)
          setIsAnyCheckboxChecked()
          setSendJsonPharmacyTransfer([])
          resetForm(1);
        }
        else {
          setShowMeassage(response.responseValue)
          setShowAlertToster(1)
          setTimeout(() => {
            setShowToster(0)
          }, 2000)
        }

      }
      else {

        setShowToster(1)
        setShowMeassage(valresponse[1])
        setShowAlertToster(1)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
      }


    }
    catch (e) {
      setShowAlertToster(1)
      setShowMeassage()
    }

  }

  const resetForm = (value) => {
    setPharmacyId("");
    setInputValues({
      // batchNo: '',
    })
    setClearDropdown(value)
    // getAllStockDetails(0);
    setPharmacyProdctList()
    setSendJsonPharmacyTransfer([])
    setIsAnyCheckboxChecked()
    getALLPharmacyStock();

  }

  useEffect(() => {

    getALLPharmacyStock();
    // getPharmacyStockById(pharmacyId)

  }, [])

  return (
    <>

      <section className='main-content mt-5 pt-3'>
        <div className='container-fluid'>
          {/* {(eyeClick === false && modifyClick === false) && */}
          <div className='row'>
            <div className="col-12">
              <div className='whitebg' style={{ margin: "0 0 10px 0" }}>
                <div className="row">
                  <div className="col-md-12 col-sm-12 analuze" >
                    <div className="fieldsett-in">
                      <div className="fieldsett">
                        <span className='fieldse'>{t("Pharmacy Stock Transfer")}</span>
                        <BoxContainer>




                          {pharmacyList &&
                            <div className="drpWithSearch me-2 mb-2" style={{ maxWidth: '200px' }}>
                              <DropdownWithSearch defaulNname="Select Pharmacy" id={pharmacyId} name='pharmacyId'
                                getvalue={handleChange} list={pharmacyList} valueName="id" displayName="pharmacy"
                                editdata={""} clear={clearDropdown} clearFun={resetForm} />
                            </div>
                          }

                          {godownList &&
                            <div className="drpWithSearch me-2 mb-2" style={{ maxWidth: '200px' }}>
                              <DropdownWithSearch defaulNname="Select Godown" id={godownId} name='godownId'
                                getvalue={handleChange} list={godownList} valueName="id" displayName="godown"
                                editdata={""} clear={clearDropdown} clearFun={resetForm} />
                            </div>
                          }
                          {showUnderProcess === 1 ? <TosterUnderProcess /> :
                            <>
                              {showToster === 1 ?
                                <Toster value={tosterValue} message={tosterMessage} />

                                :

                                <div>
                                  <button type="button" className="btn btn-clear btn-sm" onClick={() => { resetForm(1) }}><img src={clearIcon} className='icnn' />{t("Clear")}</button>
                                </div>}
                            </>
                          }

                        </BoxContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='handlser'>
                <Heading text='Pharmacy Current Stock List' />
                <div style={{ position: 'relative' }}>
                  <input type="text" name="searchBox" className='form-control form-control-sm' placeholder={t("Search")} value={searchInput} />
                  <span className="tblsericon"><i className="fas fa-search"></i></span>
                </div>
              </div>
            </div>
            <div className="col-12 mt-2">
              <div className="med-table-section" style={{ "height": "auto", position: 'relative' }}>


                {pharmacyId ? (
                  pharmacyProdctList && pharmacyProdctList.length > 0 ? (

                    <table className='med-table border striped'>
                      <thead>
                        <tr>
                          <th className="" >{t("S.No.")}</th>
                          <th className='text-center'>{t("Pharmacy Name")}</th>
                          <th className='text-center'>{t("Product_Name")}</th>
                          <th className='text-center'>{t("Batch_No")}.</th>
                          <th className='text-center'>{t("Unit Name")}</th>
                          <th className='text-center'>{t("In Stock")}</th>
                          <th className='text-center'>{t("Expiry Date")}</th>
                          <th className='text-center'>{t("Expiry Days")}</th>
                          <th className='text-center'>{t("Transfer Quantity")}</th>
                          <th><li className="d-flex flex-row ps-1 gap-2">
                            <input type="checkbox" id={-1} onChange={() => { handleAllCheckBox(-1); }} />
                            <span>Select All</span>
                          </li></th>
                        </tr>
                      </thead>
                      <tbody>
                        {pharmacyProdctList && pharmacyProdctList.map((list, index) => {

                          return (

                            <>

                              <tr>
                                <td className='' style={{ paddingLeft: '7px', fontSize: '13px' }}>{index + 1}</td>
                                <td className='text-center'><span style={{ color: '#7696F1', fontSize: '13px' }}>{list.pharmacy}</span></td>
                                <td className='text-center'><span style={{ color: '#7696F1', fontSize: '13px' }}>{list.brandName}</span></td>
                                <td className='text-center'><span style={{ fontSize: '13px', color: '#929292' }}>{list.batchNo}</span></td>
                                <td className='text-center'><span style={{ color: '#858585', fontSize: '13px' }}>{list.conversionUnit}</span></td>
                                <td className='text-center'><span style={{ color: '#858585', fontSize: '13px' }}>{list.stockStatus}</span></td>
                                <td className='text-center'><span style={{ color: '#858585', fontSize: '13px' }}>{list.batchExpiry} </span></td>
                                <td className='text-center'><span style={{ color: '#858585', fontSize: '13px' }}>{list.daysLeftToExpiry} Days</span></td>
                                <td className='text-center'>
                                  <input
                                    type='number'
                                    id={`transferQty${index}`}
                                    name='transferQty'
                                    placeholder="Enter Transfer Quantity"

                                  />
                                </td>
                                <td style={{ textAlign: 'center' }}><input type="checkbox" id={list.id} name="isChecked"
                                  onChange={() => { handleCheckBox(index); setIsAnyCheckboxChecked(true); }} />
                                </td>

                              </tr>
                            </>
                          )
                        })}
                      </tbody>
                    </table>

                  ) : (
                    <NoDataFound />
                  )
                ) : (

                  pharmacyStockList && pharmacyStockList.length > 0 ? (
                    <table className='med-table border striped'>
                      <thead>
                        <tr>
                          <th className="" >{t("S.No.")}</th>
                          <th className='text-center'>{t("Pharmacy Name")}</th>
                          <th className='text-center'>{t("Product_Name")}</th>
                          <th className='text-center'>{t("Batch_No")}.</th>
                          <th className='text-center'>{t("Unit Name")}</th>
                          <th className='text-center'>{t("In Stock")}</th>
                          <th className='text-center'>{t("Expiry Date")}</th>
                          <th className='text-center'>{t("Expiry Days")}</th>


                        </tr>
                      </thead>
                      <tbody>
                        {pharmacyStockList && pharmacyStockList.map((list, index) => {

                          return (

                            <>

                              <tr>
                                <td className='' style={{ paddingLeft: '7px', fontSize: '13px' }}>{index + 1}</td>
                                <td className='text-center'><span style={{ color: '#7696F1', fontSize: '13px' }}>{list.pharmacy}</span></td>
                                <td className='text-center'><span style={{ color: '#7696F1', fontSize: '13px' }}>{list.brandName}</span></td>
                                <td className='text-center'><span style={{ fontSize: '13px', color: '#929292' }}>{list.batchNo}</span></td>
                                <td className='text-center'><span style={{ color: '#858585', fontSize: '13px' }}>{list.conversionUnit}</span></td>
                                <td className='text-center'><span style={{ color: '#858585', fontSize: '13px' }}>{list.stockStatus}</span></td>
                                <td className='text-center'><span style={{ color: '#858585', fontSize: '13px' }}>{list.batchExpiry} </span></td>
                                <td className='text-center'><span style={{ color: '#858585', fontSize: '13px' }}>{list.daysLeftToExpiry} Days</span></td>


                              </tr>
                            </>
                          )
                        })}
                      </tbody>
                    </table>


                  ) : (

                    <NoDataFound />
                  )
                )}


              </div>
            </div>
          </div>
          <div className='col-md-12 mt-4'>

            <div className='med-table-section text-right'>

              <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1_ me-1" onClick={handleSave}><img src={saveButtonIcon} className='icnn' />Transfer</button>
              <button type="button" className="btn btn-clear btn-sm mb-1_ me-1" onClick={() => { resetForm(1) }}><img src={imgReset} />Reset</button>
            </div>
            {
              showAlertToster === 1 ? <AlertToster message={showMessage} handle={setShowAlertToster} /> : ""
            }
            {
              showSuccessToster === 1 ? <SuccessToster message={showMessage} handle={setShowSuccessToster} /> : ""
            }
            {
              loder === 1 ? <Loader val={loder} /> : ""
            }

          </div>
        </div>
      </section>
    </>
  )
}