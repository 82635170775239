import React, { useEffect, useState } from 'react'
import lifeSupportICU from '../../../../../../assets/images/icons/lifeSupportICU.svg'
import GetLifeSupportDataForICU from '../ICUChartAPi/GetLifeSupportDataForICU';


export default function LifeSupport(props) {
  const [getClientId, setClientId] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientId);
  const [getUHID, setUHID] = useState(JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid);
  const [lifeSupportData, setLifeSupportData] = useState({ lifeSupportMode: [], machine: [] });
  const [lifesupport, setLifeSupport] = useState([])
  const [lifesupportDate, setLifeSupportDate] = useState([])
  const [uniqueDates, setUniqueDates] = useState([])

  const formatDate = (date) => {
    const formattedDate = new Date(date);
    const day = formattedDate.getDate().toString().padStart(2, '0');
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
    const year = formattedDate.getFullYear().toString().slice(-2);
    return `${day}-${month}-${year}`;
  };

  // Get Life Support Data
  const getLifeSupportData = async () => {
    try {
      const response = await GetLifeSupportDataForICU(getUHID, getClientId);
      if (response.status === 1) {
        let lifesupportData = []
        let lifesupporDate = []
        const { lifeSupportMode, machine } = response.responseValue;
        setLifeSupportData(lifeSupportMode);
        for (var i = 0; i < lifeSupportMode.length; i++) {
          lifesupportData.push(lifeSupportMode[i]);
          lifesupporDate.push(lifeSupportMode[i].datee);
        }
        setLifeSupport([...lifesupportData]);
        setLifeSupportDate([...lifesupporDate]);
        lifesupportData = [];
        lifesupporDate = [];

   

      }
    } catch (error) {
      console.error('Error fetching life support data:', error);
    }
  };
 
  useEffect(() => {
    getLifeSupportData();
  }, []);

  return (
    <>
      <div className='icuChartHeading mb-2'>
        <div className='icuChartHeading-inn'>
          <div className='ICUHead-lft'>
            <span><img src={lifeSupportICU} alt='' /></span>
            <span><h3>LifeSupport</h3></span>
          </div>
        </div>
      </div>
      <div className="med-table-section Icu-componenttbl" style={{ "height": "35vh", paddingBottom: '10px' }}>
        <table className="med-table table-responsive">
          {/* <thead>
            <tr>
              <th style={{ minWidth: "150px" }} >Machine</th>
              {lifeSupportData.lifeSupportMode.map((mode) => (
                <th key={mode.dateTime} style={{ minWidth: "100px" }} className='text-center'>{formatDate(mode.dateTime)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {lifeSupportData.machine.map((machine) => (
              <tr key={machine.lifeSupportModeID}>
                <td>{machine.lifeSupportMode}</td>
                {lifeSupportData.lifeSupportMode.map((mode) => (
                  <>
                    <td key={mode.dateTime} className='text-center' style={{ whiteSpace: 'nowrap', fontStyle: 'italic' }}>
                      <div className='cathercolor'>
                        {formatDate(mode.dateTime) === formatDate(machine.fromDateTime) && (
                          `Insert-${new Date(machine.fromDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', })}`
                        )}
                      </div>
                      <div className='cathercolor1'>
                        {formatDate(mode.dateTime) === formatDate(machine.toDateTime) && (
                          `Remove-${new Date(machine.toDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', })}`
                        )}
                      </div>
                    </td>

                    <td>
                      {formatDate(mode.dateTime) === formatDate(machine.fromDateTime) ? (
                        `Insert-${machine.fromDateTime.split('T')[1].slice(0, 5)}`
                      ) : (
                        formatDate(mode.dateTime) === formatDate(machine.toDateTime) ? (
                          `Remove-${machine.toDateTime.split('T')[1].slice(0, 5)}`
                        ) : (
                          ''
                        )
                      )}
                    </td>
                  </>
                ))}
              </tr>
            ))} */}

          {/* {uniqueDates && uniqueDates.map((dateList, index) => {
            return (
              <> */}
                <thead>
                  <tr>
                    <th colSpan={25}>
                      {/* <div className='listDate'></div> */}
                    </th>
                  </tr>
                  <tr>
                    <th>Date</th>
                    <th>Life Support</th>
                    
                    <th>07 AM</th>
                    <th>08 AM</th>
                    <th>09 AM</th>
                    <th>10 AM</th>
                    <th>11 AM</th>
                    <th>12 PM</th>
                    <th>01 PM</th>
                    <th>02 PM</th>
                    <th>03 PM</th>
                    <th>04 PM</th>
                    <th>05 PM</th>
                    <th>06 PM</th>
                    <th>07 PM</th>
                    <th>08 PM</th>
                    <th>09 PM</th>
                    <th>10 PM</th>
                    <th>11 PM</th>
                    <th>12 AM</th>
                    <th>01 AM</th>
                    <th>02 AM</th>
                    <th>03 AM</th>
                    <th>04 AM</th>
                    <th>05 AM</th>
                    <th>06 AM</th>
                  </tr>
                </thead>

                <tbody>
                  {lifesupport && lifesupport.map((vitalsList, index) => {
                    
                      return (
                        <tr>
                          <td>{vitalsList.datee}</td>
                          <td>{vitalsList.id === 184?"MVesp": vitalsList.id === 184?"RRspon":vitalsList.id === 175?"Ppeak":vitalsList.id === 151?"FiO2":vitalsList.id === 98?"Peep":vitalsList.id === 166?"Pressure":vitalsList.id === 178?"Breath Frequncy":vitalsList.id === 185?"Mode":vitalsList.id === 158?"TidaleVolume":vitalsList.vitalName}</td>
                          
                          <td>{vitalsList.aM07}</td>
                          <td>{vitalsList.aM08}</td>
                          <td>{vitalsList.aM09}</td>
                          <td>{vitalsList.aM10}</td>
                          <td>{vitalsList.aM11}</td>
                          <td>{vitalsList.pM12}</td>
                          <td>{vitalsList.pM01}</td>
                          <td>{vitalsList.pM02}</td>
                          <td>{vitalsList.pM03}</td>
                          <td>{vitalsList.pM04}</td>
                          <td>{vitalsList.pM05}</td>
                          <td>{vitalsList.pM06}</td>
                          <td>{vitalsList.pM07}</td>
                          <td>{vitalsList.pM08}</td>
                          <td>{vitalsList.pM09}</td>
                          <td>{vitalsList.pM10}</td>
                          <td>{vitalsList.pM11}</td>
                          <td>{vitalsList.aM12}</td>
                          <td>{vitalsList.aM01}</td>
                          <td>{vitalsList.aM02}</td>
                          <td>{vitalsList.aM03}</td>
                          <td>{vitalsList.aM04}</td>
                          <td>{vitalsList.aM05}</td>
                          <td>{vitalsList.aM06}</td>
                        </tr>
                      )

                  })}

                </tbody>
              {/* </>
            )
          })} */}


          {/* </tbody> */}
        </table>
      </div>
    </>
  );
}

