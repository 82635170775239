import React, { useState } from 'react'
import { useEffect } from 'react';
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import clearIcon from '../../assets/images/icons/clear.svg';
import deleteBtnIcon from '../../assets/images/icons/delete.svg';
import editBtnIcon from '../../assets/images/icons/edit.svg';
import GetShiftList from '../Master/Api/ShiftMaster/GetShiftList';
import Heading from '../../Component/Heading';
import BoxContainer from '../../Component/BoxContainer';
import DropdownWithSearch from '../../Component/DropdownWithSearch';
import TosterUnderProcess from '../../Component/TosterUnderProcess';
import Toster from '../../Component/Toster';
import Loader from '../../Component/Loader';
import SuccessToster from '../../Component/SuccessToster';
import AlertToster from '../../Component/AlertToster';
import GetAllNurseByShift from './Api/GetAllNurseByShift';
import PostAssignPatientToNurse from './Api/PostAssignPatientToNurse';
import GetAssignPatientToNurse from './Api/GetAssignPatientToNurse';
import TableContainer from '../../Component/TableContainer';
import RemoveDuplicateData from '../../Code/RemoveDuplicateData';
import DeletePatientNurseShiftAssign from './Api/DeletePatientNurseShiftAssign';
import UpdateAssignPatientToNurse from './Api/UpdateAssignPatientToNurse';
import Search from '../../Code/Serach';
import { t } from 'i18next';

export default function AssignPatientToNurse() {
  let [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
  let [clientID, setClientID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientId);
  let [showUnderProcess, setShowUnderProcess] = useState(0);
  let [showToster, setShowToster] = useState(0);
  let [tosterMessage, setTosterMessage] = useState("");
  let [tosterValue, setTosterValue] = useState(0);
  let [showLoder, setShowLoder] = useState(0);
  let [isShowToaster, setisShowToaster] = useState(0);
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [showErrMessage, setShowErrMessage] = useState('');
  let [showSuccessMsg, setShowSuccessMsg] = useState('');
  let [assignPatientToNurseList, setAssignPatientToNurseList] = useState([]);
  let [assignPatientToNurseListMain, setAssignPatientToNurseListMain] = useState([]);
  let [nurseList, setNurseList] = useState([]);
  let [shiftList, setShiftList] = useState([]);
  let [updateBool, setUpdateBool] = useState(0);
  let [editShiftDdl, setEditShiftDdl] = useState('');
  let [editNurseDdl, setEditNurseDdl] = useState('');
  let [clearDropdown, setClearDropdown] = useState(0);
  let [rowId, setRowId] = useState(0);
  let [sendForm, setSendForm] = useState({
    uhId: '',
    shiftId: '',
    assignedUserId: '',
    "userId": window.userId,
    "clientId": window.clientId,
  })


  let handleChange = async (e) => {
    clearValidationErrMessage();
    setEditShiftDdl("");
    setEditNurseDdl("");
    const { name, value } = e.target;

    if (name === 'shiftId') {
      GetNurseByShift(value);
    }
    setSendForm((prevData) => ({ ...prevData, [name]: value }));
  };


  //Get Shift
  const getShiftList = async () => {
    const response = await GetShiftList();
    if (response.status === 1) {
      setShiftList(response.responseValue);
    }
    else {
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
      setTimeout(() => {
        setShowAlertToster(0);
      }, 1500)
    }
  }

  //Get Nurse By Shift
  const GetNurseByShift = async (shiftId) => {
    setShowLoder(1);
    setNurseList([]);
    setSendForm((prevData) => ({ ...prevData, nurse: '' }));
    const response = await GetAllNurseByShift({ clientId: window.clientId, shiftId });
    if (response.status === 1) {
      // setNurseList(response.responseValue);
      let temp = RemoveDuplicateData(response.responseValue, "name")
      setNurseList(temp)
      setShowLoder(0);
    } else {
      setShowLoder(0);
      setShowErrMessage(response.responseValue);
      setShowAlertToster(1);
      setTimeout(() => {
        setShowAlertToster(0);
      }, 1500);
    }
  };

  //GetAll PatientNurse Assign
  const getPatientNurse = async () => {
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    const response = await GetAssignPatientToNurse(clientID)
    if (response.status === 1) {
      setAssignPatientToNurseList(response.responseValue)
      setAssignPatientToNurseListMain(response.responseValue)
    }
  };
  //Handle Search
  let handleSearch = (e) => {
    let resp = Search(assignPatientToNurseListMain, e.target.value)
    if (e.target.value !== "") {
      if (resp.length !== 0) {
        setAssignPatientToNurseList(resp)
      }
      else {
        setAssignPatientToNurseList([])

      }
    }
    else {
      setAssignPatientToNurseList(assignPatientToNurseListMain)
    }
  }

  //Handle Save
  const handlerSave = async () => {
    if (sendForm.uhId === '' || sendForm.uhId === null || sendForm.uhId === undefined) {
      document.getElementById('erruPatientUhid').innerHTML = "Patient UHID is Required";
      document.getElementById('erruPatientUhid').style.display = "block";
    }
    else if (sendForm.shiftId === '' || sendForm.shiftId === null || sendForm.shiftId === undefined) {
      document.getElementById('errShift').innerHTML = "Shift is Required";
      document.getElementById('errShift').style.display = "block";
    }
    else if (sendForm.assignedUserId === '' || sendForm.assignedUserId === null || sendForm.assignedUserId === undefined) {
      document.getElementById('errNurse').innerHTML = "Nurse is Required";
      document.getElementById('errNurse').style.display = "block";
    }

    else {
      setShowUnderProcess(1);
      var obj =
      {
        "uhId": sendForm.uhId,
        "shiftId": sendForm.shiftId,
        "assignedUserId": sendForm.assignedUserId,
        "userId": window.userId,
        "clientId": window.clientId,
      }
      const response = await PostAssignPatientToNurse(obj);
      if (response.status === 1) {
        setShowUnderProcess(0);
        setTosterValue(0);
        setShowToster(1);
        setTosterMessage("Assign Successfully..");
        setTimeout(() => {
          setShowToster(0);
          handleClear(1);
        }, 1500)
        getPatientNurse();
      }
      else {
        setShowUnderProcess(0);
        setTosterValue(1);
        setShowToster(1);
        setTosterMessage(response.responseValue);
        setTimeout(() => {
          setShowToster(0);
        }, 1500)
      }
    }
  }

  //HandleButtonChange
  let handleUpdate = async (id, uhid, shiftId, assignedUserId, userId, shiftTimeRange, name) => {
    setUpdateBool(1)
    setSendForm(sendForm => ({
      ...sendForm,
      "id": id,
      "uhId": uhid,
      "shiftId": shiftId,
      "assignedUserId": assignedUserId,
      "userId": window.userId
    }))
    document.getElementById("uhId").value = uhid;
    setEditShiftDdl(shiftTimeRange);
    setEditNurseDdl(name);
  }


  //Handle Update
  const handlerUpdate = async () => {
    if (sendForm.uhId === '' || sendForm.uhId === null || sendForm.uhId === undefined) {
      document.getElementById('erruPatientUhid').innerHTML = "Patient UHID is Required";
      document.getElementById('erruPatientUhid').style.display = "block";
    }
    else if (sendForm.shiftId === '' || sendForm.shiftId === null || sendForm.shiftId === undefined) {
      document.getElementById('errShift').innerHTML = "Shift is Required";
      document.getElementById('errShift').style.display = "block";
    }
    else if (sendForm.assignedUserId === '' || sendForm.assignedUserId === null || sendForm.assignedUserId === undefined) {
      document.getElementById('errNurse').innerHTML = "Nurse is Required";
      document.getElementById('errNurse').style.display = "block";
    }

    else {
      setShowUnderProcess(1);
      const response = await UpdateAssignPatientToNurse({
        ...sendForm,
        "uhId": sendForm.uhId,
        "shiftId": sendForm.shiftId,
        "assignedUserId": sendForm.assignedUserId,
        "userId": window.userId,
        "clientId": window.clientId
      });
      if (response.status === 1) {
        setShowUnderProcess(0);
        setTosterValue(0);
        setShowToster(1);
        setTosterMessage("Updated Successfully..");
        setTimeout(() => {
          setShowToster(0);
          handleClear();
          getPatientNurse();

        }, 1500)
      }
      else {
        setShowUnderProcess(0);
        setTosterValue(1);
        setShowToster(1);
        setTosterMessage(response.responseValue);
        setTimeout(() => {
          setShowToster(0);
        }, 1500)
      }
    }
  }



  const handleDelete = async () => {
    const id = rowId;
    const userId = window.userId;
    setShowLoder(1);
    const response = await DeletePatientNurseShiftAssign({ id, userId });

    if (response.status === 1) {
      setShowLoder(0)
      setisShowToaster(1);
      setShowSuccessMsg("Deleted Successfully")
      setTimeout(() => {
        setisShowToaster(0);
        getPatientNurse();
      }, 1500)
      handleClear();
    } else {
      setShowLoder(0);
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
      setTimeout(() => {
        setShowAlertToster(0);
      }, 1500)
    }
  }



  const clearValidationErrMessage = () => {
    document.getElementById('erruPatientUhid').style.display = "none";
    document.getElementById('errShift').style.display = "none";
    document.getElementById('errNurse').style.display = "none";
  }

  //Handle Clear
  const handleClear = (value) => {
    clearValidationErrMessage();
    setUpdateBool(0);
    setClearDropdown(value);
    document.getElementById("uhId").value = "";
    setEditShiftDdl(0);
    setEditNurseDdl(0);
    setSendForm({
      uhid: '',
      shift: '',
      nurse: '',
      "userId": window.userId,
      "clientId": window.clientId,
    })
  }

  useEffect(() => {
    getShiftList();
    getPatientNurse();
    setNurseList([]);
    setEditNurseDdl('');
    setSendForm((prevData) => ({ ...prevData, assignedUserId: '' }));
  }, [sendForm.shiftId]);

  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Heading text="Assign Patient To Nurse" />
              <BoxContainer>
                <div className="col-2 mb-2 me-2">
                  <label htmlFor="uhId" className="form-label">UHID<span className="starMandatory">*</span></label>
                  <input type="text" className="form-control form-control-sm" name="uhId" id="uhId" placeholder='Enter Patient UIHID' onChange={handleChange} />
                  <small id="erruPatientUhid" className="invalid-feedback" style={{ display: 'none' }}></small>
                </div>
                <div className="col-2 mb-2 me-2">
                  <label htmlFor="shiftId" className="form-label">Shift<span className="starMandatory">*</span></label>
                  {shiftList &&
                    <DropdownWithSearch
                      defaulNname="Select Shift"
                      name="shiftId"
                      list={shiftList.map(val => ({
                        id: val.id,
                        displayName: `${val.shiftName}  (${val.shiftTimeFrom} - ${val.shiftTimeTo})`,
                      }))}
                      valueName="id"
                      displayName="displayName"
                      editdata={editShiftDdl}
                      getvalue={handleChange}
                      clear={clearDropdown}
                      clearFun={handleClear}
                    />
                  }
                  <small id="errShift" className="invalid-feedback" style={{ display: 'none' }}></small>
                </div>
                <div className="col-2 mb-2 me-2">
                  <label htmlFor="assignedUserId" className="form-label">Nurse<span className="starMandatory">*</span></label>
                  {nurseList &&
                    <DropdownWithSearch
                      defaulNname="Select Nurse"
                      name="assignedUserId"
                      list={nurseList}
                      valueName="assignedUserId"
                      displayName="name"
                      editdata={editNurseDdl}
                      getvalue={handleChange}
                      clear={clearDropdown}
                      clearFun={handleClear}
                    />
                  }
                  <small id="errNurse" className="invalid-feedback" style={{ display: 'none' }}></small>

                </div>


                <div className="mb-2 relative">
                  <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                  <div>
                    {showUnderProcess === 1 ? <TosterUnderProcess /> :
                      <>
                        {showToster === 1 ?
                          <Toster value={tosterValue} message={tosterMessage} />

                          : <div>
                            {updateBool === 0 ?
                              <>
                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handlerSave}><img src={saveButtonIcon} className='icnn' alt='' />Save</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { handleClear(1) }}><img src={clearIcon} className='icnn' alt='' />Clear</button>
                              </>
                              :
                              <>
                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={handlerUpdate}>Update</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { handleClear(1) }}>Cancel</button>
                              </>
                            }
                          </div>}
                      </>
                    }
                  </div>
                </div>
              </BoxContainer>
            </div>
            <div className="col-12 mt-2">
              <div className='handlser'>
                <Heading text="Assign Patient To Nurse List" />
                <div style={{ position: 'relative' }}>
                  <input type="text" className='form-control form-control-sm' placeholder={t("Search")} onChange={handleSearch} />
                  <span className="tblsericon"><i className="fas fa-search"></i></span>
                </div>
              </div>

              <div className="med-table-section" style={{ "height": "75vh" }}>
                <TableContainer>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>Patient Name</th>
                      <th>Shift Name</th>
                      <th>Assigned Nurse</th>
                      <th style={{ "width": "10%" }} className="text-center">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {assignPatientToNurseList && assignPatientToNurseList.map((val, ind) => {
                      return (
                        <tr key={val.id}>
                          <td className="text-center">{ind + 1}</td>
                          <td>{val.patientName}({val.uhid})</td>
                          <td>{val.shiftName} ({val.shiftTimeFrom} - {val.shiftTimeTo})</td>
                          <td>{val.name}</td>
                          <td>
                            <div className="action-button">
                              {/* <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={editBtnIcon} className='' alt='' /></div> */}
                              <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom">
                                <img src={editBtnIcon} className="" alt=""
                                  onClick={() => {
                                    const shiftTimeRange = `${val.shiftName}  (${val.shiftTimeFrom} - ${val.shiftTimeTo})`;
                                    handleUpdate(val.id, val.uhid, val.shiftId, val.assignedUserId, val.userId, shiftTimeRange, val.name);
                                  }}
                                />
                              </div>
                              <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowId(val.id, val.userId) }} />
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}


                  </tbody>
                </TableContainer>
                {/* -----------------------Start Delete Modal Popup-------------------   */}

                {/*  <!-- Modal -->  */}
                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                  <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                      <div className="modal-body modelbdy text-center">
                        <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                        <div className='popDeleteTitle mt-3'>Delete?</div>
                        <div className='popDeleteContent'>Are you sure want to delete?</div>
                      </div>
                      <div className="modal-footer1 text-center">

                        <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn-delete popBtnDelete" data-bs-dismiss="modal" onClick={handleDelete}>Delete</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

              </div>
            </div>
          </div>
        </div>
        {
          showLoder === 1 ? <Loader val={showLoder} /> : ""
        }
        {/* Toaster */}
        {
          isShowToaster === 1 ?
            <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
        }

        {
          showAlertToster === 1 ?
            <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
        }
      </section>
    </>
  )
}
