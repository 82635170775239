import React, { useEffect, useState } from 'react'
import ProvisonalDiagnosisMainComponent from './ProvisonalDiagnosisMainComponent'
import Heading from '../../Component/Heading'
import DropdownWithSearch from '../../Component/DropdownWithSearch'
import GetInvestigationDetails from './API/GetInvestigationDetails'
import VitalsComponent from '../../Clinical/Pages/Component/VitalsComponent'
import GetAllStatus from '../../Dietetics/API/FoodIntake/GetAllStatus'
import GetDoctorList from '../../Registartion/API/GET/GetDoctorList'
import InvestigationsRows from './InvestigationsRows'
import MedicationComponent from '../../Clinical/Pages/IPD/IPDSharePages/ICUChart/MedicationComponent'
import bp from '../../assets/images/vitalsicons/bp.svg'
import pulse from '../../assets/images/vitalsicons/pulse.svg'
import BPSystolic from '../../assets/images/vitalsicons/BPSystolic.svg'
import spo from '../../assets/images/vitalsicons/spo.svg'
import POSTInsertPatientVital from '../../Clinical/API/OPD/Vitals/POSTInsertPatientVital'
import save from "../../assets/images/icons/save.svg"
import ProcedureWithSedationInsert from './API/ProcedureWithSedationInsert'
import AlertToster from '../../Component/AlertToster'
import SuccessToster from '../../Component/SuccessToster'
import Loader from '../../Component/Loader'
import imgPrint from "../../assets/images/icons/imgPrint.svg";
import deleteBtnIcon from "../../assets/images/icons/deletebtn.svg";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import ProcedureMaster from './API/ProcedureMaster'
import ProcedureWithSedationGet from './API/ProcedureWithSedationGet'
import PatientDeatils from '../../Component/PatientDeatils'

export default function ProcedureWithSedation() {
    const { t } = useTranslation();

    const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;

    let [sendForm, setSendForm] = useState({
        "clientId": window.clientId,
        "uhId": uhID,
        "procedureId": 0,
        "preSedProcedureId": 0,
        "ansethetistId": 0,
        "informedConsent": 0,
        "prevoiusSedation": 0,
        "allergies": 0,
        "nbm": 0,
        "asaGrade": 0,
        "malamPattiClass": "",
        "denture": 0,
        "cvs": "",
        "rs": "",
        "pa": "",
        "cns": "",
        "sysRespAssementStatusId": 0,
        "cardiovascularSysStatusId": 0,
        "generalExaminationStatusId": 0,
        "musculoskeletalStatusId": 0,
        "renalStatusId": 0,
        // "id": 0,
        "dateTimeOfAssessment": new Date().toISOString().split('T')[0] + "T" + new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
        "dateTimeOfProcedure": new Date().toISOString().split('T')[0] + "T" + new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
        "investigationJson": "",
        "userId": window.userId
    })
    let [sendFormVital, setSendFormVital] = useState({})
    let [InvestigationJSON, setInvestigationJSON] = useState([]);

    const handleInvestigationJSON = (temp, data) => {
        setInvestigationJSON(temp, data);

    };
    let [getprocedureData, setGetprocedureData] = useState([])
    const [patientData, setPatientData] = useState();
    let [procedureList, setProcedureList] = useState([])
    let [ASAGradeList, setASAGradeList] = useState([])
    let [clearDropdown, setClearDropdown] = useState(0);
    let [editProcedure, setEditProcedure] = useState('');
    let [saveDiagnosis, setSaveDiagnosis] = useState(0);
    let [saveVitals, setSaveVitals] = useState(0)
    let [systemRespiratoryAssessment, setSystemRespiratoryAssessment] = useState([])
    let [cardiovascularSystem, setCardiovascularSystem] = useState([])
    let [CNSMusculoskeletalList, setCNSMusculoskeletalList] = useState([])
    let [generalExaminationStatusList, setGeneralExaminationStatusList] = useState([])
    let [renalStatusList, setRenalStatusList] = useState([])
    let [doctorList, setDoctorList] = useState([])
    let [loader, setLoader] = useState(0)
    let [showAlertToster, setShowAlertToster] = useState(0)
    let [showAlertMessage, setShowAlertMessage] = useState("")
    let [showToster, setShowtoster] = useState(0)
    let [rowId, setRowId] = useState(null)

    let getdata = async () => {
        let patientdataList = JSON.parse(window.sessionStorage.getItem("IPDpatientList"))
        let temp = []
        patientdataList.map((val, ind) => {
            if (val.uhId === uhID) {
                temp.push(val.wardName)
                temp.push(val.patientName)
                temp.push(val.uhId)
                temp.push(val.admittedDate)
                temp.push(val.pmId)
                temp.push(val.name)
            }
        })

        setPatientData(temp)
        let items = await ProcedureMaster()
        if (items.status === 1) {
            setProcedureList(items.responseValue)
        }

        let doctorList = await GetDoctorList()
        if (doctorList.status === 1) {
            setDoctorList(doctorList.responseValue)
        }

       
        let ASAmaster = await GetAllStatus("ASA Grade")
        if (ASAmaster.status === 1) {
            setASAGradeList(ASAmaster.responseValue)
        }
        let systemRespiratoryAssessmentMaster = await GetAllStatus("System Respiratory Assessment")
        if (systemRespiratoryAssessmentMaster.status === 1) {
            setSystemRespiratoryAssessment(systemRespiratoryAssessmentMaster.responseValue)
        }
        let cardiovascularSystemMaster = await GetAllStatus("Cardiovascular System")
        if (cardiovascularSystemMaster.status === 1) {
            setCardiovascularSystem(cardiovascularSystemMaster.responseValue)
        }
        let CNSMusculoskeletalMaster = await GetAllStatus("CNS/Musculoskeletal")
        if (CNSMusculoskeletalMaster.status === 1) {
            setCNSMusculoskeletalList(CNSMusculoskeletalMaster.responseValue)
        }
        let generalExaminationStatusMaster = await GetAllStatus("General Examination")
        if (generalExaminationStatusMaster.status === 1) {
            setGeneralExaminationStatusList(generalExaminationStatusMaster.responseValue)
        }
        let renalStatusMaster = await GetAllStatus("Hepatic/Renal")
        if (renalStatusMaster.status === 1) {
            setRenalStatusList(renalStatusMaster.responseValue)
        }
    }
    let getprocedureWithSedation = async () => {
        setLoader(1)
        let response = await ProcedureWithSedationGet(uhID)
        if (response.status === 1) {
            setLoader(0)

            setGetprocedureData(response.responseValue)
        }
        else {
            setGetprocedureData([])
            setLoader(0)

        }
    }
    let handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value

        if (name === "dateTimeOfAssessment") {
            setSendForm((sendForm) => ({ ...sendForm, ["dateTimeOfAssessment"]: new Date().toISOString().split('T')[0] + "T" + value }))
        }
        else if (name === "informedConsent") {
            let ischecked = document.getElementById("informedConsent_1").checked ? 1 : 0
            setSendForm((sendForm) => ({ ...sendForm, ["informedConsent"]: ischecked }))
        }
        else if (name === "prevoiusSedation") {
            let ischecked = document.getElementById("prevoiusSedation_1").checked ? 1 : 0
            setSendForm((sendForm) => ({ ...sendForm, ["prevoiusSedation"]: ischecked }))
        }
        else if (name === "allergies") {
            let ischecked = document.getElementById("allergies_1").checked ? 1 : 0
            setSendForm((sendForm) => ({ ...sendForm, ["allergies"]: ischecked }))
        }
        else if (name === "nbm") {
            let ischecked = document.getElementById("nbm_1").checked ? 1 : 0
            setSendForm((sendForm) => ({ ...sendForm, ["nbm"]: ischecked }))
        }
        else if (name === "asaGrade") {
            // let ischecked = document.getElementById("nbm_1").checked ? true : false
            setSendForm((sendForm) => ({ ...sendForm, ["asaGrade"]: parseInt(e.target.id) }))
        }
        else if (name === "denture") {
            let ischecked = document.getElementById("denture_1").checked ? 1 : 0
            setSendForm((sendForm) => ({ ...sendForm, ["denture"]: ischecked }))
        }
        else if (name === "sysRespAssementStatusId") {
            // let ischecked = document.getElementById("nbm_1").checked ? true : false
            setSendForm((sendForm) => ({ ...sendForm, ["sysRespAssementStatusId"]: parseInt(e.target.id) }))
        }
        else if (name === "cardiovascularSysStatusId") {
            // let ischecked = document.getElementById("nbm_1").checked ? true : false
            setSendForm((sendForm) => ({ ...sendForm, ["cardiovascularSysStatusId"]: parseInt(e.target.id) }))
        }
        else if (name === "musculoskeletalStatusId") {
            // let ischecked = document.getElementById("nbm_1").checked ? true : false
            setSendForm((sendForm) => ({ ...sendForm, ["musculoskeletalStatusId"]: parseInt(e.target.id) }))
        }
        else if (name === "generalExaminationStatusId") {
            // let ischecked = document.getElementById("nbm_1").checked ? true : false
            setSendForm((sendForm) => ({ ...sendForm, ["generalExaminationStatusId"]: parseInt(e.target.id) }))
        }
        else if (name === "renalStatusId") {
            // let ischecked = document.getElementById("nbm_1").checked ? true : false
            setSendForm((sendForm) => ({ ...sendForm, ["renalStatusId"]: parseInt(e.target.id) }))
        }
        else {
            setSendForm((sendForm) => ({ ...sendForm, [name]: value }))

        }
    }
    let handleOnChange = (e) => {
        // document.getElementById('errVital').style.display = "none";
        let name = e.target.name;
        let value = e.target.value;
        if (name === "vitalDate") {
            document.getElementById("errDate").style.display = "none";
        }
        else if (name === "vitalTime") {
            document.getElementById("errTime").style.display = "none";

        }
        else if (name === "vmValueBPSys") {
            document.getElementById("errBPS").style.display = "none";

        }
        else if (name === "vmValueBPDias") {
            document.getElementById("errBPD").style.display = "none";

        }
        else if (name === "vmValueSPO2") {
            document.getElementById("errSPO2").style.display = "none";

        }
        else if (name === "vmValueRespiratoryRate") {
            document.getElementById("errRR").style.display = "none";

        }
        else if (name === "vmValueHeartRate") {
            document.getElementById("errHR").style.display = "none";

        }
        else if (name === "vmValuePulse") {
            document.getElementById("errPR").style.display = "none";

        }
        else if (name === "vmValueRbs") {
            document.getElementById("errRBS").style.display = "none";

        }
        else if (name === "vmValueTemperature") {
            document.getElementById("errTemp").style.display = "none";

        }
        setSendFormVital(sendFormVital => ({
            ...sendFormVital,
            [name]: value
        }))

    };
    let saveData = async () => {
        sendFormVital["uhid"] = uhID
        // sendForm["uhid"] = JSON.parse(window.sessionStorage.getItem("activePatient")).Uhid
        sendFormVital["userId"] = window.userId
        sendFormVital["vitalDate"] = new Date().toISOString().split('T')[0]
        sendFormVital["vitalTime"] = new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
        // let dateVal = document.getElementById('vitalDate').value;
        // let timeVal = document.getElementById('vitalTime').value;
        let bpsVal = document.getElementById('vmValueBPSys').value;
        let bpdVal = document.getElementById('vmValueBPDias').value;
        let spo2Val = document.getElementById('vmValueSPO2').value;
        // let RRVal = document.getElementById('vmValueRespiratoryRate').value;
        // let HRVal = document.getElementById('vmValueHeartRate').value;
        let PRVal = document.getElementById('vmValuePulse').value;
        // let TempVal = document.getElementById('vmValueTemperature').value;
        // let RBSVal = document.getElementById('vmValueRbs').value;
        // if (dateVal === '' || dateVal === undefined || dateVal === null) {
        //     document.getElementById('errDate').innerHTML = "Please select Date";
        //     document.getElementById('errDate').style.display = "block";
        // }
        if (bpsVal === '' && bpdVal === '' && spo2Val === '' && PRVal === '') {
            document.getElementById('errVital').innerHTML = "Please enter any vital";
            document.getElementById('errVital').style.display = "block";
        }
        // else if (timeVal === '' || timeVal === undefined || timeVal === null) {
        //     document.getElementById('errTime').innerHTML = "Please select Time";
        //     document.getElementById('errTime').style.display = "block";
        // }
        else if (bpsVal > 500) {
            document.getElementById('errBPS').innerHTML = "Enter valid value";
            document.getElementById('errBPS').style.display = "block";
        }
        else if (bpdVal > 1000) {
            document.getElementById('errBPD').innerHTML = "Enter valid value";
            document.getElementById('errBPD').style.display = "block";
        }
        else if (spo2Val > 100) {
            document.getElementById('errSPO2').innerHTML = "Enter valid value";
            document.getElementById('errSPO2').style.display = "block";
        }
        // else if (RRVal > 50) {
        //     document.getElementById('errRR').innerHTML = "Enter valid value";
        //     document.getElementById('errRR').style.display = "block";
        // }
        // else if (HRVal > 220) {
        //     document.getElementById('errHR').innerHTML = "Enter valid value";
        //     document.getElementById('errHR').style.display = "block";
        // }
        else if (PRVal > 220) {
            document.getElementById('errPR').innerHTML = "Enter valid value";
            document.getElementById('errPR').style.display = "block";
        }
        // else if (TempVal > 110) {
        //     document.getElementById('errTemp').innerHTML = "Enter valid value";
        //     document.getElementById('errTemp').style.display = "block";
        // }
        // else if (RBSVal > 2400) {
        //     document.getElementById('errRBS').innerHTML = "Enter valid value";
        //     document.getElementById('errRBS').style.display = "block";
        // }

        else {


            let response = await POSTInsertPatientVital(sendFormVital)
            if (response.status === 1) {
                // setShowUnderProcess(0)
                // setShowToster(1)
                // setTosterMessage("Data Saved SuccessFully!")
                // setTosterValue(0)
                // setTimeout(() => {
                //     setShowToster(0)
                // }, 2000)
                handleClear();
                console.log(response)
            }
            else {
                // setShowUnderProcess(0)
                // setShowToster(1)
                // setTosterMessage(response.responseValue)
                // setTosterValue(1)
                // setTimeout(() => {
                //     setShowToster(0)
                // }, 2000)

            }
        }
    }
    let handleClear = (e) => {
        setClearDropdown(1)
        document.getElementById("informedConsent_1").checked = false;
        document.getElementById("informedConsent_2").checked = false;
        document.getElementById("prevoiusSedation_1").checked = false;
        document.getElementById("prevoiusSedation_2").checked = false;
        document.getElementById("allergies_1").checked = false;
        document.getElementById("allergies_2").checked = false;
        document.getElementById("nbm_1").checked = false;
        document.getElementById("nbm_2").checked = false;
        document.getElementById("denture_1").checked = false;
        document.getElementById("denture_2").checked = false;

        // vitals
        document.getElementById('errBPS').style.display = "none";
        document.getElementById("errBPD").style.display = "none";
        document.getElementById("errSPO2").style.display = "none";
        // document.getElementById("errRR").style.display = "none";
        // document.getElementById("errHR").style.display = "none";
        document.getElementById("errPR").style.display = "none";
        // document.getElementById("errRBS").style.display = "none";
        // document.getElementById("errTemp").style.display = "none";
        document.getElementById('vmValueBPSys').value = '';
        document.getElementById('vmValueBPDias').value = '';
        // document.getElementById('vmValueTemperature').value = '';
        // document.getElementById('vmValueRespiratoryRate').value = '';
        // document.getElementById('vmValueHeartRate').value = '';
        document.getElementById('vmValuePulse').value = '';
        document.getElementById('vmValueSPO2').value = '';


        for (let j = 0; j <= systemRespiratoryAssessment.length - 1; j++) {
            document.getElementById(systemRespiratoryAssessment[j].id).checked = false
        }
        for (let j = 0; j <= cardiovascularSystem.length - 1; j++) {
            document.getElementById(cardiovascularSystem[j].id).checked = false
        }
        for (let j = 0; j <= CNSMusculoskeletalList.length - 1; j++) {
            document.getElementById(CNSMusculoskeletalList[j].id).checked = false
        }
        for (let j = 0; j <= generalExaminationStatusList.length - 1; j++) {
            document.getElementById(generalExaminationStatusList[j].id).checked = false
        }
        for (let j = 0; j <= renalStatusList.length - 1; j++) {
            document.getElementById(renalStatusList[j].id).checked = false
        }
        for (let j = 0; j <= ASAGradeList.length - 1; j++) {
            document.getElementById(ASAGradeList[j].id).checked = false
        }
        setSendForm({
            "clientId": window.clientId,
            "uhId": uhID,
            "procedureId": 0,
            "preSedProcedureId": 0,
            "ansethetistId": 0,
            "informedConsent": 0,
            "prevoiusSedation": 0,
            "allergies": 0,
            "nbm": 0,
            "asaGrade": 0,
            "malamPattiClass": "",
            "denture": 0,
            "cvs": "",
            "rs": "",
            "pa": "",
            "cns": "",
            "sysRespAssementStatusId": 0,
            "cardiovascularSysStatusId": 0,
            "generalExaminationStatusId": 0,
            "musculoskeletalStatusId": 0,
            "renalStatusId": 0,
            "id": 0,
            "dateTimeOfAssessment": new Date().toISOString().split('T')[0] + "T" + new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
            "dateTimeOfProcedure": new Date().toISOString().split('T')[0] + "T" + new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
            "investigationJson": "",
            "userId": window.userId
        })
        setTimeout(() => {
            setClearDropdown(0)
        }, 60)
        return
    }
    let handleSave = async () => {
        sendForm["investigationJson"] = JSON.stringify(InvestigationJSON)
        let resp = handleValidation()
        if (resp[0]) {
            setLoader(1)
            sendForm["pmId"] = patientData[4]
            let response = await ProcedureWithSedationInsert(sendForm)
            if (response.status === 1) {
                saveData()
                setSaveDiagnosis(1)
                setShowtoster(1)
                handleClear()
                getprocedureWithSedation()
                setLoader(0)
            }
            else {
                setLoader(0)
                setShowAlertToster(1)
                setShowAlertMessage("Data Not Save")
            }
        }
        else {
            setShowAlertToster(1)
            setShowAlertMessage("Please Fill " + resp[1].replace("Id", ""))
        }
    }
    let handleprint = (val) => {
        window.open(`/receiptprocedurewithsedationprint/?uhID=${uhID}&index=${val}`, "noopener,noreferrer");
    }
    let handleDeleteRow = async () => {
        // let response = await DeleteNursingAssessment(rowId)
        // if (response.status === 1) {
        //     getnursing()
        //     setShowtoster(1)
        // }
        // else {

        // }
    }
    let handleValidation = () => {
        console.log("sendform", sendForm)
        for (let key in sendForm) {
            if (sendForm[key] === "") {
                return [false, key]
            }
        }
        // sendForm.map((val, ind) => {

        // })
        return [true, ""]
    }
    useEffect(() => {
        getdata()
        getprocedureWithSedation()
    }, [])
    return (
        <>
            <Heading text='Procedure With Sedation Form' id='top' />
            <PatientDeatils />
            <div className="fieldsett-in">
                <div className="fieldsett">
                    <span className='fieldse'>{"Assessment Details"}</span>
                    <div className="inner-content mt-2">
                        <div className='d-flex flex-column flex-md-row gap-2'>
                            <div className="d-flex flex-wrap align-content-end_ gap-3 w-100 w-md-50">
                                {/* <div className="mb-2 me-2">
                                    <label htmlFor="assessmentDate" className="form-label">Date of Assessment<span className="starMandatory">*</span></label>
                                    <input id="assessmentDate" type="date" className="form-control form-control-sm" name="assessmentDate" value={sendForm.dateTimeOfAssessment !== "" ? sendForm.dateTimeOfAssessment.split("T")[0] : new Date().toISOString().split('T')[0]} onChange={handleChange} />
                                </div> */}
                                <div className="mb-2 me-2">
                                    <label htmlFor="dateTimeOfAssessment" className="form-label">Time of Assessment<span className="starMandatory">*</span></label>
                                    <input id="dateTimeOfAssessment" type="time" className="form-control form-control-sm" name="dateTimeOfAssessment" value={sendForm.dateTimeOfAssessment !== "" ? sendForm.dateTimeOfAssessment.split("T")[1] : new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })} onChange={handleChange} />
                                </div>
                                <div className="mb-2 me-2">
                                    <label htmlFor="Code" className="form-label">Ward<span className="starMandatory">*</span></label>
                                    <input id="wardId" type="text" className="form-control form-control-sm" name="wardName" value={patientData && patientData[0]} disabled placeholder="Ward" />
                                </div>
                                <div className="mb-2 me-2">
                                    <label htmlFor="Code" className="form-label">Procedure<span className="starMandatory">*</span></label>
                                    {procedureList && <DropdownWithSearch defaulNname={"Select Procedure"} name="procedureId" list={procedureList} valueName="id" displayName="procedureName" editdata={editProcedure} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                </div>

                                <div className='mb-2 me-2' style={{width:"25vw"}}>
                                    <label htmlFor="Code" className="form-label">Diagnosis<span className="starMandatory">*</span></label>

                                    <ProvisonalDiagnosisMainComponent
                                        isProvisional={0}
                                        saveDiagnosis={saveDiagnosis}
                                        setSaveDiagnosis={setSaveDiagnosis}
                                    />
                                </div>
                            </div>


                        </div>


                    </div>
                </div>
            </div>

            <div className="fieldsett-in">
                <div className="fieldsett">
                    <span className='fieldse'></span>
                    <div className="inner-content mt-1" >

                        <div className='row ms-1 me-1 fieldsett pws_overflow' >
                            <div className='col-12 col-md-4' style={{ borderRight: "1px solid #81a1db" }}>
                                <div className='d-flex flex-row gap-3'>
                                    <div className="d-flex flex-row gap-3">
                                        <label htmlFor="assessmentDate" className="form-label"><strong>Patient Full Name:</strong> </label>
                                        <label htmlFor="assessmentDate" className="form-label">{patientData && patientData[1]}</label>
                                    </div>
                                    <div className="d-flex flex-row gap-3">
                                        <label htmlFor="assessmentDate" className="form-label"><strong>Patient MR No.:</strong> </label>
                                        <label htmlFor="assessmentDate" className="form-label">{patientData && patientData[2]}</label>
                                    </div>

                                </div>
                            </div>
                            <div className='col-12 col-md-3' style={{ borderRight: "1px solid #81a1db" }}>
                                <label htmlFor="assessmentDate" className="form-label"><strong>Date & Time Of Procedure: </strong> </label> &nbsp;
                                <label htmlFor="assessmentDate" className="form-label"> {new Date().toISOString().split('T')[0] + " " + new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })}</label>
                            </div>
                            <div className='col-12 col-md-3' style={{ borderRight: "1px solid #81a1db" }}>
                                <label htmlFor="assessmentDate" className="form-label"><strong>Admitting Doctor: </strong> </label> &nbsp;
                                <label htmlFor="assessmentDate" className="form-label">{patientData &&patientData[5]}</label>
                            </div>
                            <div className='col-12 col-md-2'>
                                <label htmlFor="assessmentDate" className="form-label"><strong>Department: </strong> </label> &nbsp;
                                <label htmlFor="assessmentDate" className="form-label"></label>
                            </div>

                        </div>

                        <div className='row mt-1 ms-1 me-1 pb-2 fieldsett pws_overflow' >
                            <div className='row'>
                                <label htmlFor="assessmentDate" className="form-label text-center col-md-10 col-12"><strong>A.PRE SEDATION ASSESSMENT</strong> </label>
                                <label htmlFor="assessmentDate" className="form-label col-md-2 col-12"><strong>Time: </strong>
                                    <label htmlFor="assessmentDate" className="form-label">{new Date().toISOString().split('T')[0] + " " + new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })}</label>
                                </label>
                            </div>
                            <div className='row mt-3'>
                                <div className='d-flex flex-row gap-3 col-12 col-md-4'>
                                    <label htmlFor="assessmentDate" className="form-label"><strong>Name of Procedure: </strong> </label> &nbsp;
                                    {procedureList && <DropdownWithSearch defaulNname={"Select Procedure"} name="preSedProcedureId" list={procedureList} valueName="id" displayName="procedureName" editdata={editProcedure} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                </div>
                                <div className='d-flex flex-row gap-3 col-12 col-md-4'>
                                    <label htmlFor="assessmentDate" className="form-label"><strong>Name of Anesthetist: </strong> </label> &nbsp;
                                    {doctorList && <DropdownWithSearch defaulNname={"Select Dietician"} name="ansethetistId" list={doctorList} valueName="id" displayName="name" editdata={""} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}

                                </div>
                            </div>

                        </div>

                        <div className='row mt-1 ms-1 me-1 pb-2 fieldsett pws_overflow' >
                            <div className='col-12 col-md-6' style={{ borderRight: "1px solid #81a1db" }}>
                                <label htmlFor="assessmentDate" className="form-label"><strong>Pre procedure checklist: </strong> </label> &nbsp;
                                <div className='row'>
                                    <div className='col-6' >
                                        <div className='d-flex flex-row align-items-center gap-2 '>
                                            <label htmlFor="assessmentDate" className="form-label"><strong>Informed Consent: </strong> </label> &nbsp;
                                            <div className="risk-factors-check regularCheck">
                                                <input type="radio" className="me-2 custom-checkbox form-check-input" id='informedConsent_1' name='informedConsent' onChange={handleChange} />Yes
                                            </div>
                                            <div className="risk-factors-check regularCheck">
                                                <input type="radio" className="me-2 custom-checkbox form-check-input" id='informedConsent_2' name='informedConsent' onChange={handleChange} />No
                                            </div>
                                        </div>
                                        <div className='d-flex flex-row align-items-center gap-2 '>
                                            <label htmlFor="assessmentDate" className="form-label"><strong>Previous anethesia/sedation: </strong> </label> &nbsp;
                                            <div className="risk-factors-check regularCheck">
                                                <input type="radio" className="me-2 custom-checkbox form-check-input" id='prevoiusSedation_1' name='prevoiusSedation' onChange={handleChange} />Yes
                                            </div>
                                            <div className="risk-factors-check regularCheck">
                                                <input type="radio" className="me-2 custom-checkbox form-check-input" id='prevoiusSedation_2' name='prevoiusSedation' onChange={handleChange} />No
                                            </div>
                                        </div>

                                    </div>

                                    <div className='col-6'>
                                        <div className='d-flex flex-row align-items-center gap-2 '>
                                            <label htmlFor="assessmentDate" className="form-label"><strong>Allergies: </strong> </label> &nbsp;
                                            <div className="risk-factors-check regularCheck">
                                                <input type="radio" className="me-2 custom-checkbox form-check-input" id='allergies_1' name='allergies' onChange={handleChange} />Yes
                                            </div>
                                            <div className="risk-factors-check regularCheck">
                                                <input type="radio" className="me-2 custom-checkbox form-check-input" id='allergies_2' name='allergies' onChange={handleChange} />No
                                            </div>
                                        </div>
                                        <div className='d-flex flex-row align-items-center gap-2 '>
                                            <label htmlFor="assessmentDate" className="form-label"><strong>NBM: </strong> </label> &nbsp;
                                            <div className="risk-factors-check regularCheck">
                                                <input type="radio" className="me-2 custom-checkbox form-check-input" id='nbm_1' name='nbm' onChange={handleChange} />Yes
                                            </div>
                                            <div className="risk-factors-check regularCheck">
                                                <input type="radio" className="me-2 custom-checkbox form-check-input" id='nbm_2' name='nbm' onChange={handleChange} />No
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-6' >
                                <label htmlFor="assessmentDate" className="form-label"><strong>ASA Grade: </strong> </label> &nbsp;
                                <div className='d-flex flex-wrap flex-row_ gap-3'>
                                    {
                                        ASAGradeList && ASAGradeList.map((val, ind) => {
                                            return (
                                                <div className="risk-factors-check regularCheck">
                                                    <div className=' d-flex flex-row  align-items-center'>
                                                        <input type="radio" className="me-2 custom-checkbox form-check-input" id={val.id} name='asaGrade' onChange={handleChange} /><label>{val.remark}</label>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>


                            </div>
                        </div>

                        <div className='row mt-1 ms-1 me-1 pb-2 fieldsett' >
                            <div className='col-12 col-md-6' style={{ borderRight: "1px solid #81a1db" }}>
                                <label htmlFor="assessmentDate" className="form-label"><strong>Airway Assessment: </strong> </label> &nbsp;
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='d-flex flex-row align-items-center gap-2 '>
                                            <label htmlFor="assessmentDate" className="form-label"><strong>Malam Patti Class: </strong> </label> &nbsp;
                                            <input id="wardId" type="text" className="form-control form-control-sm" name="malamPattiClass" value={sendForm && sendForm.malamPattiClass} placeholder="Enter Malam patti" onChange={handleChange} />
                                        </div>
                                        <div className='d-flex flex-row align-items-center gap-2 '>
                                            <label htmlFor="assessmentDate" className="form-label"><strong>Denture: </strong> </label> &nbsp;
                                            <div className="risk-factors-check regularCheck">
                                                <input type="radio" className="me-2 custom-checkbox form-check-input" id='denture_1' name='denture' onChange={handleChange} />Yes
                                            </div>
                                            <div className="risk-factors-check regularCheck">
                                                <input type="radio" className="me-2 custom-checkbox form-check-input" id='denture_2' name='denture' onChange={handleChange} />No
                                            </div>
                                        </div>

                                    </div>

                                    <div className='col-6'>
                                        <div className='d-flex flex-row align-items-center gap-2 '>
                                            <label htmlFor="assessmentDate" className="form-label"><strong>Other: </strong> </label> &nbsp;
                                            <input id="wardId" type="text" className="form-control form-control-sm" name="" placeholder="Enter here..." />
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <label htmlFor="assessmentDate" className="form-label"><strong>Current Medication: </strong> </label> &nbsp;

                                <MedicationComponent height={"130px"} />


                            </div>
                        </div>

                        <div className='row mt-1 ms-1 me-1 pb-2 fieldsett pws_overflow' >
                            <div className='col-12 col-md-6' style={{ borderRight: "1px solid #81a1db" }}>
                                <label htmlFor="assessmentDate" className="form-label"><strong>System Respiratory Assessment: </strong> </label> &nbsp;
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='d-flex flex-wrap flex-row_ gap-2'>
                                            {
                                                systemRespiratoryAssessment && systemRespiratoryAssessment.map((val, ind) => {
                                                    return (
                                                        <div className="risk-factors-check regularCheck">
                                                            <div className=' d-flex flex-row  align-items-center'>
                                                                <input type="radio" className="me-2 custom-checkbox form-check-input" id={val.id} name='sysRespAssementStatusId' onChange={handleChange} /><label>{val.remark}</label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <label htmlFor="assessmentDate" className="form-label"><strong>Clinical Evaluation: </strong> </label> &nbsp;
                                {/* <VitalsComponent saveVitals={saveVitals} setSaveVitals={setSaveVitals} /> */}
                                <table>
                                    <tr >
                                        <td className='b-2'>
                                            <div className='vitals-cnt-in '>
                                                <img src={BPSystolic} className='icnn1' />
                                                <input type='number' placeholder='BPS(mm Hg)' name='vmValueBPSys' id='vmValueBPSys' onChange={handleOnChange} />

                                                <small id="errBPS" className="absolute-alert" style={{ display: 'none' }}></small>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='vitals-cnt-in '>
                                                <img src={bp} className='icnn1' />
                                                <input type='number' placeholder='BPD(mm Hg)' name='vmValueBPDias' id='vmValueBPDias' onChange={handleOnChange} />
                                                <small id="errBPD" className="absolute-alert" style={{ display: 'none' }}></small>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='vitals-cnt-in'>
                                                <img src={pulse} className='icnn1' />
                                                <input type='number' placeholder='Pulse(Beats)' name='vmValuePulse' id='vmValuePulse' onChange={handleOnChange} />
                                                <small id="errPR" className="absolute-alert" style={{ display: 'none' }}></small>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='vitals-cnt-in'>
                                                <img src={spo} className='icnn1' />
                                                <input type='number' placeholder='SPO2' name='vmValueSPO2' id='vmValueSPO2' onChange={handleOnChange} />
                                                <small id="errSPO2" className="absolute-alert" style={{ display: 'none' }}></small>
                                            </div>
                                        </td>
                                    </tr>
                                </table>



                            </div>
                        </div>

                        <div className='row mt-1 ms-1 me-1 pb-2 fieldsett' >
                            <div className='col-12 col-md-6' style={{ borderRight: "1px solid #81a1db" }}>
                                <label htmlFor="assessmentDate" className="form-label"><strong>Cardiovascular System: </strong> </label> &nbsp;
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='d-flex flex-wrap flex-row_ gap-2'>
                                            {
                                                cardiovascularSystem && cardiovascularSystem.map((val, ind) => {
                                                    return (
                                                        <div className="risk-factors-check regularCheck">
                                                            <div className=' d-flex flex-row  align-items-center'>
                                                                <input type="radio" className="me-2 custom-checkbox form-check-input" id={val.id} name='cardiovascularSysStatusId' onChange={handleChange} /><label>{val.remark}</label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 d-flex flex-column gap-2'>
                                <table className='table_'>
                                    <tr className='mb-2'>
                                        <td className='mb-2'>
                                            <tr>
                                                <td>
                                                    <label htmlFor="assessmentDate" className="form-label"><strong>CVS: </strong> </label>

                                                </td>
                                                <td>
                                                    <input id="cvs" type="text" className="form-control form-control-sm" name="cvs" value={sendForm && sendForm.cvs} placeholder="Enter CVS" onChange={handleChange} />

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label htmlFor="assessmentDate" className="form-label"><strong>RS: </strong> </label>


                                                </td>
                                                <td>
                                                    <input id="rs" type="text" className="form-control form-control-sm" name="rs" value={sendForm && sendForm.rs} placeholder="Enter RS" onChange={handleChange} />


                                                </td>
                                            </tr>
                                        </td>
                                        <td>
                                            <tr>
                                                <td>
                                                    <label htmlFor="assessmentDate" className="form-label"><strong>P/A: </strong> </label>



                                                </td>
                                                <td>
                                                    <input id="pa" type="text" className="form-control form-control-sm" name="pa" value={sendForm && sendForm.pa} placeholder="Enter P/A" onChange={handleChange} />



                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label htmlFor="assessmentDate" className="form-label"><strong>CNS: </strong> </label>
                                                </td>
                                                <td>
                                                    <input id="cns" type="text" className="form-control form-control-sm" name="cns" value={sendForm && sendForm.cns} placeholder="Enter CNS" onChange={handleChange} />
                                                </td>
                                            </tr>
                                        </td>
                                    </tr>


                                </table>
                                {/* <div className='d-flex flex-row align-items-center gap-2 '>
                                </div> */}
                                {/* <div className='d-flex flex-row align-items-center gap-2 '>
                                </div> */}
                                {/* <div className='d-flex flex-row align-items-center gap-2 '>
                                </div> */}
                                {/* <div className='d-flex flex-row align-items-center gap-2 '>
                                </div> */}
                            </div>
                        </div>

                        <div className='row mt-1 ms-1 me-1 pb-2 fieldsett' >
                            <div className='col-12 col-md-6' style={{ borderRight: "1px solid #81a1db" }}>
                                <label htmlFor="assessmentDate" className="form-label"><strong>CNS/ Musculoskeletal: </strong> </label> &nbsp;
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='d-flex flex-wrap flex-row_ gap-2'>
                                            {
                                                CNSMusculoskeletalList && CNSMusculoskeletalList.map((val, ind) => {
                                                    return (
                                                        <div className="risk-factors-check regularCheck">
                                                            <div className=' d-flex flex-row  align-items-center'>
                                                                <input type="radio" className="me-2 custom-checkbox form-check-input" id={val.id} name='musculoskeletalStatusId' onChange={handleChange} /><label>{val.remark}</label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='d-flex flex-wrap flex-row_ gap-2'>
                                    {
                                        generalExaminationStatusList && generalExaminationStatusList.map((val, ind) => {
                                            return (
                                                <div className="risk-factors-check regularCheck">
                                                    <div className=' d-flex flex-row  align-items-center'>
                                                        <input type="radio" className="me-2 custom-checkbox form-check-input" id={val.id} name='generalExaminationStatusId' onChange={handleChange} /><label>{val.remark}</label>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='row mt-1 ms-1 me-1 pb-2 fieldsett' >
                            <div className='col-12 col-md-6' style={{ borderRight: "1px solid #81a1db" }}>
                                <label htmlFor="assessmentDate" className="form-label"><strong>Hepatic/ Renal: </strong> </label> &nbsp;
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='d-flex flex-wrap flex-row_ gap-2'>
                                            {
                                                renalStatusList && renalStatusList.map((val, ind) => {
                                                    return (
                                                        <div className="risk-factors-check regularCheck">
                                                            <div className=' d-flex flex-row  align-items-center'>
                                                                <input type="radio" className="me-2 custom-checkbox form-check-input" id={val.id} name='renalStatusId' onChange={handleChange} /><label>{val.remark}</label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='' style={{ padding: '20px' }}>
                                    <label htmlFor="assessmentDate" className="form-label"><strong>Investigations: </strong> </label> &nbsp;
                                    <div style={{ height: "100px", overflow: "auto" }}>

                                        <InvestigationsRows investigationsJson={handleInvestigationJSON} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-end mt-3  gap-3 '>
                    <button className='saveprintopd ps-3 pe-3 btn-save-fill' onClick={handleSave} >
                        <img src={save} className='icnn' alt='' /> Save 
                    </button>
                    <button className='saveprintopd ps-3 pe-3 btn-save-fill' onClick={handleClear} >
                        {/* <img src={save} className='icnn' alt='' />  */}
                        Clear
                    </button>
                </div>
            </div>




            <div className="med-table-section mt-3" style={{ height: "30vh" }}>
                <table className="med-table border_ striped">
                    <thead style={{ zIndex: "0" }}>
                        <tr>
                            <th className="text-center" style={{ width: "5%" }}>
                                #
                            </th>
                            <th>Date of Assessment</th>
                            <th>Time of Assessment</th>
                            <th>Procedure</th>
                            <th>Pre Procedure Name</th>
                            <th>Ansethetist Name</th>
                            <th>Informed Consent</th>
                            <th>Prevoius Sedation</th>
                            <th>Allergies</th>
                            <th>nbm</th>
                            <th>ASA Grade Name</th>
                            <th>MalamPatti Class</th>
                            <th>Denture</th>
                            <th>CVS</th>
                            <th>RS</th>
                            <th>P/A</th>
                            <th>CNS</th>
                            <th>SysResp Assement Name</th>
                            <th>Cardiovascular SysName</th>
                            <th>General ExaminationName</th>
                            <th>Musculoskeletal Name</th>
                            <th>Renal Status Name</th>
                            <th>Investigation</th>
                            <th>Date/Time Of Procedure</th>
                            <th>Vitals</th>
                            <th>Diagnosis</th>
                            <th style={{ width: "5%" }} className="text-center">
                                {"Action"}
                            </th>

                        </tr>
                    </thead>

                    <tbody>
                        {
                            getprocedureData && getprocedureData.map((val, ind) => {
                                return (
                                    <tr>
                                        <td className="text-center" style={{ width: "5%" }}>
                                            {ind + 1}
                                        </td>
                                        <td>{val.dateTimeOfAssessment.split("T")[0]}</td>
                                        <td>{val.dateTimeOfAssessment.split("T")[1]}</td>
                                        <td>{val.procedureName}</td>
                                        <td>{val.preProcedureName}</td>
                                        <td>{val.ansethetistName}</td>
                                        <td>{val.informedConsent === 1 ? "Yes" : "No"}</td>
                                        <td>{val.prevoiusSedation === 1 ? "Yes" : "No"}</td>
                                        <td>{val.isAllergies === 1 ? "Yes" : "No"}</td>
                                        <td>{val.isNbm === 1 ? "Yes" : "No"}</td>
                                        <td>{val.asaGradeName}</td>
                                        <td>{val.malamPattiClass}</td>
                                        <td>{val.denture === 1 ? "Yes" : "No"}</td>
                                        <td>{val.cvs}</td>
                                        <td>{val.rs}</td>
                                        <td>{val.pa}</td>
                                        <td>{val.cns}</td>
                                        <td>{val.sysRespAssementName}</td>
                                        <td>{val.cardiovascularSysName}</td>
                                        <td>{val.generalExaminationName}</td>
                                        <td>{val.musculoskeletalName}</td>
                                        <td>{val.renalStatusName}</td>
                                        <td>
                                            <div className='d-flex flex-row ' style={{ whiteSpace: "nowrap" }}>
                                                {val.investigationJson.length !== 0 ? JSON.parse(val.investigationJson).map((val) => val.itemName).join(', ') : ""
                                                }
                                            </div>
                                        </td>
                                        <td>{val.dateTimeOfProcedure.split("T")[0] + " " + val.dateTimeOfProcedure.split("T")[1]}</td>
                                        <td>
                                            <div className='d-flex flex-row ' style={{ whiteSpace: "nowrap" }}>

                                                {val.vitals.length !== 0 ? JSON.parse(val.vitals).map((val) => val.vmValue).join(', ') : ""
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex flex-row ' style={{ whiteSpace: "nowrap" }}>
                                                {val.diagnosis.length !== 0 ? JSON.parse(val.diagnosis).map((val) => val.itemName).join(', ') : ""
                                                }
                                            </div>
                                        </td>

                                        <td style={{ width: "5%" }} className="text-center pointer">
                                            <div className='d-flex flex-row gap-2'>
                                                <img
                                                    src={imgPrint}
                                                    alt=""
                                                    onClick={() => { handleprint(ind) }}
                                                    style={{ width: "19px" }}
                                                />
                                                <div className="action-button" >
                                                    <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' style={{ width: "20px" }} alt='' onClick={() => { setRowId(val.id) }} />
                                                    </div>
                                                </div>

                                            </div>

                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            {/*  <!-- Modal -->  */}
            <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                        <div className="modal-body modelbdy text-center">
                            <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                            <div className='popDeleteTitle mt-3'> {t("Delete?")}</div>
                            <div className='popDeleteContent'> {t("Are_you_sure_you_want_to_delete?")}</div>
                        </div>
                        <div className="modal-footer1 text-center">

                            <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                            <button type="button" className="btn-delete popBtnDelete" onClick={handleDeleteRow} data-bs-dismiss="modal">{t("Delete")}</button>
                        </div>
                    </div>
                </div>
            </div>
            {
                showAlertToster === 1 ? <AlertToster handle={setShowAlertToster} message={showAlertMessage} /> : ""
            }
            {
                showToster === 1 ? <SuccessToster handle={setShowtoster} message={"Data Save Successfully!!"} /> : ""
            }
            <Loader val={loader} />
        </>
    )
}

