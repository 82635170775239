let InsertPatientInitialAssessment = async (obj)=>{
    // let url = "https://localhost:7225/api/PatientInitialAssessment/InsertPatientInitialAssessment"
    let url = window.AppbaseUrl + "/api/PatientInitialAssessment/InsertPatientInitialAssessment"
    let head = { "Content-Type": "application/JSON", accept: '*/*' };
    let data = {}
    let response = fetch(url, {
        headers: head,
        method: 'POST',
        body:JSON.stringify(obj)
    }).then((res) => res.json()).then(data);
  
    return response;
  }
  export default InsertPatientInitialAssessment;