import React, { useState, useEffect, useRef } from 'react'
import imgReset from '../../../assets/images/icons/reset.svg'
import imgPrint from '../../../assets/images/icons/imgPrint.svg'
import imgPaymentMode from '../../../assets/images/icons/imgPaymentMode.svg'
import imgCardNo from '../../../assets/images/icons/imgCardNo.svg'
import imgBank from '../../../assets/images/icons/imgBank.svg'
import imgCheque from '../../../assets/images/icons/imgCheque.svg'
import imgRef from '../../../assets/images/icons/imgRef.svg'
import imgBill from '../../../assets/images/icons/imgBill.svg'
import imgCompany from '../../../assets/images/icons/imgCompany.svg'
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg'
import uhid from '../../../assets/images/icons/UHID1.svg'
import UserIcon from '../../../assets/images/icons/UserIcon.svg'
import smartphone from '../../../assets/images/icons/smartphone.svg'
import GetKnowmedItems from '../../../../src/Pharmacy/Purchase/API/GetKnowmedItems'
import GetUnitMaster from '../../../Pharmacy/UnitMaster/API/GetUnitMaster'
import DropdownWithSearch from '../../../../src/Component/DropdownWithSearch';
import imgGross from '../../../assets/images/icons/gross.svg'
import imgDiscount from '../../../assets/images/icons/discount (1).svg'
import PostSaleItems from '../../../../src/Pharmacy/Sale/API/PostSaleItems'
import GetPatientDetailsByUHID from '../../../../src/Clinical/API/IPD/personalDashboardIndexSecond/GetPatientDetailsByUHID'
import SuccessToster from '../../../../src/Component/SuccessToster'
import AlertToster from '../../../../src/Component/AlertToster'
import GetAllCurrentStock from '../../CurrentStock/API/GetAllCurrentStock'
import GetAllCurrentStockByProductId from '../../CurrentStock/API/GetAllCurrentStockByProductId'
import GetAllSale from '../../Sale/API/GetAllSale';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import genderIcon from "../../../assets/images/icons/genders.svg";
import ageIcon from "../../../assets/images/icons/ageIcon.svg";
import clearIcon from '../../../assets/images/icons/clear.svg';
import ValidationPharmacySale from '../../../Validation/Pharmacy/ValidationPharmacySale'
import GetAllSaleByBillNo from '../API/GetAllSaleByBillNo'
import GetPharmacyMaster from '../../CurrentStock/API/GetPharmacyMaster'
import GetPharmacyAllStockByPharmacyId from '../API/GetPharmacyAllStockByPharmacyId'
import GetPharmacyStockByProductId from '../API/GetPharmacyStockByProductId'
import GetPharmacyStockByBatchNo from '../API/GetPharmacyStockByBatchNo'
import GetLedgerMaster from '../../LedgerMaster/API/GetLedgerMaster'
import getPatientDetailByUhid from '../../../Billing/API/getPatientDetailByUhid'
import getBankNameList from '../../../Billing/API/getBankNameList'
import GetallPaymentMode from '../../../Billing/API/GetallPaymentMode'
import getCompanyType from "../../../Billing/API/companyType";
export default function PharmacySale() {

    let [jsonPrintData, setJsonPrintData] = useState([])
    let [stockList, setStockList] = useState([])
    let [ledgerList, setLedgerList] = useState([])
    let [productStockList, setProductStockList] = useState([])
    let [batchStockList, setBatchStockList] = useState([]);
    let [pharmacyList, setPharmacyList] = useState([]);
    let [ledgerId, setLedgerId] = useState()
    let [productId, setProductId] = useState()
    let [pharmacyId, setPharmacyId] = useState()
    let [discount, setDiscount] = useState(0)
    let [allSale, setAllSale] = useState()
    let [billNo, setBillNo] = useState()
    let [quantity, setQuantity] = useState(0)
    let [unitId, setUnitId] = useState()
    let [batchNo, setBatchNo] = useState()
    let [unitPrice, setUnitPrice] = useState()
    let [showAlertToster, setShowAlertToster] = useState(0)
    let [showMessage, setShowMeassage] = useState("")
    let [showSuccessToster, setShowSuccessToster] = useState(0)
    let [clearDropdown, setClearDropdown] = useState(0)
    let [showToster, setShowToster] = useState(0)
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [totalPayableAmount, setTotalPayableAmount] = useState(0);
    const [totalGrossAmount, setTotalGrossAmount] = useState(0);
    const [totalDiscountRs, setTotalDiscountRs] = useState(0);
    const { t } = useTranslation();
    const [stockDetailsMap, setStockDetailsMap] = useState({});
    let [byCard, setByCard] = useState();
    let [refNo, setRefNo] = useState();
    let [chequeNo, setchequeNo] = useState(0);
    let [insCardNo, setinsCardNo] = useState(0)
    let [chequeDate, setchequeDate] = useState('')
    let [paymentMode, setPaymentMode] = useState(0);
    const [inputValues, setInputValues] = useState({ "userId": window.userId });
    const [rows, setRows] = useState([{ id: 1 }]); // Initial default row
    let [bankList, setBankList] = useState([]);
    const [isPaymentDisabled, setisPaymentDisabled] = useState(false);
    let [PaymentModeList, setPaymentModeList] = useState([]);
    let [bank, setBank] = useState(0);



    let GetPaymentModes = async()=>{
        let PaymentMode = await GetallPaymentMode()
        if(PaymentMode.status === 1){
          setPaymentModeList(PaymentMode.responseValue)
          console.log("PaymentMode",PaymentMode.responseValue)
        }
      }


    let HandlePaymentDetails = (e) => {
        
        if (e.target.name === "byCard") {
          setByCard(e.target.value);
        }
        if (e.target.name === "refNo") {
          setRefNo(e.target.value);
          document.getElementById("paymentModeRefNo").style.display = "block";
        }
        if(e.target.name === "bnkCardNo"){
          setinsCardNo(e.target.value);
        }
        if(e.target.name === "chequeNo"){
          setchequeNo(e.target.value);
        }
        if(e.target.name === "chequeDate"){
          setchequeDate(e.target.value);
        }  
        if (e.target.name === "selectBank") {
            setBank(e.target.value);
          } 
      };

      let handlePaymentMode = (e) => {
        let mode = e.target.value
        setPaymentMode(mode)
        if (mode == "0") {
          document.getElementById("paymentModeCard").style.display = "none";
          document.getElementById("paymentModeRefNo").style.display = "none";
          document.getElementById("bnkdetails").style.display = "none";
        } else if (mode === "1") {
          document.getElementById("paymentModeCard").style.display = "none";
          document.getElementById("paymentModeRefNo").style.display = "none";
          document.getElementById("bnkdetails").style.display = "none";
        } else if (mode === "2") {
     
          document.getElementById("paymentModeCard").style.display = "block";
          document.getElementById("paymentModeRefNo").style.display = "none";
          document.getElementById("bnkdetails").style.display = "none";
        } else if (mode === "3") {
          GetBankList();
          document.getElementById("bnkdetails").style.display = "block";
          document.getElementById("paymentModeCard").style.display = "none";
          document.getElementById("paymentModeRefNo").style.display = "none";
        } else if (mode === "4") {
          setPaymentMode(4);
          document.getElementById("paymentModeCard").style.display = "none";
          document.getElementById("paymentModeRefNo").style.display = "block";
          document.getElementById("bnkdetails").style.display = "none";
        }
        console.log("payment Value" , mode)
      };

      let GetBankList = async () => {
        var response = await getBankNameList();
        setBankList(response.responseValue);
        console.log("this is responsesss", response);
      };

    let getStockList = async (pharmacyId) => {

        try {
            let pharmacyresponse = await GetPharmacyMaster();
            let stockresponse = await GetPharmacyAllStockByPharmacyId(pharmacyId)
            let productstckresponse = await GetLedgerMaster()

            if (stockresponse.status === 1) {
                setStockList(stockresponse.responseValue)
                const stockres = stockresponse.responseValue[0];

            }

            if (pharmacyresponse.status === 1) {
                setPharmacyList(pharmacyresponse.responseValue)

            }

            if (productstckresponse.status === 1) {
                setLedgerList(productstckresponse.responseValue)

            }

        }
        catch (e) {
            setShowAlertToster(1)
            setShowMeassage(e.message)
        }
    }



    let getDetailsByProduct = async (productId) => {

        try {
            let pharId = pharmacyId;
            let prodId = productId;
            let productstckresponse = await GetPharmacyStockByProductId(pharId, prodId)




            if (productstckresponse.status === 1) {
                setProductStockList(productstckresponse.responseValue)

            }

        }
        catch (e) {
            setShowAlertToster(1)
            setShowMeassage(e.message)
        }
    }

    let getdetailsByBatch = async (rowId, batchNo) => {

        try {

            let pharId = pharmacyId;
            let produtId = productId;
            let batchId = batchNo;
            let batchstckresponse = await GetPharmacyStockByBatchNo(pharId, produtId, batchId)
            const stockDetails = batchstckresponse.responseValue[0];
            console.log("stockDetails", stockDetails)

            if (batchstckresponse.status === 1 && batchstckresponse.responseValue.length > 0) {
                setStockDetailsMap(prevMap => ({
                    ...prevMap,
                    [productId]: {
                        unitID: stockDetails.unitId,
                        isknowmed: stockDetails.isknowmed,
                        batchNo: stockDetails.batchNo,
                        unitPrice: stockDetails.unitPrice
                    }
                }));

                // setBatchStockList(batchstckresponse.responseValue)
                document.getElementById("unitName_" + rowId).value = stockDetails.unitName
                document.getElementById("unitPrice_" + rowId).value = stockDetails.unitPrice
                document.getElementById("batchExpiry_" + rowId).value = stockDetails.dfm
                document.getElementById("daysLeftToExpiry_" + rowId).value = stockDetails.expiryDate
                document.getElementById("closingStock_" + rowId).value = stockDetails.stock
                document.getElementById("discount_" + rowId).value = stockDetails.setSellingDiscount
                setDiscount(stockDetails.setSellingDiscount)
                const updatedRows = rows.map(row => {
                    if (row.id === rowId) {
                        const netAmount = stockDetails.unitPrice * row.quantity

                        return { ...row, netAmount };
                    }
                    return row;
                });
                setRows(updatedRows);
                // console.log("rows", rows)

                // const updatedRows = rows.map(row => {
                //     if (row.id === rowId) {
                //         const unitPrice = stockDetails.unitPrice
                //         const discount = stockDetails.setSellingDiscount
                //         return { ...row, unitPrice,discount};
                //     }
                //     return row;
                // });
                // setRows(updatedRows);


                handlePriceQuantityChange(rowId, stockDetails.unitPrice, updatedRows.find(row => row.id === rowId).quantity);
            }



            else {
                setStockDetailsMap(prevMap => ({
                    ...prevMap,
                    [productId]: {
                        unitID: '',
                        isknowmed: '',
                        batchNo: '',
                        unitPrice: ''
                    }
                }));


                document.getElementById("unitName_" + rowId).value = ''

                document.getElementById("unitPrice_" + rowId).value = ''
                document.getElementById("batchExpiry_" + rowId).value = ''
                document.getElementById("daysLeftToExpiry_" + rowId).value = ''
                document.getElementById("closingStock_" + rowId).value = ''
                document.getElementById("discount_" + rowId).value = ''


            }


        }
        catch (e) {
            // setShowAlertToster(1)
            // setShowMeassage('An unexpected error occurred.')
            console.log("No data found")
        }


    }


    const getPatientDetails = async (uhId) => {
        try {

            let pmIdresponse = await GetPatientDetailsByUHID(uhId);
            console.log("pmIdresponse", pmIdresponse)
            if (uhId.trim() !== '' && pmIdresponse.status === 1) {
                setInputValues(inputValues => ({
                    ...inputValues,
                    pmId: pmIdresponse.responseValue[0].pmId,
                    patientName: pmIdresponse.responseValue[0].patientName,
                    mobileNo: pmIdresponse.responseValue[0].mobileNo,
                    gender: pmIdresponse.responseValue[0].gender,
                    age: pmIdresponse.responseValue[0].age,
                    crNo: pmIdresponse.responseValue[0].crNo,
                }));
              
            }
            

        } catch (e) {
            setShowAlertToster(1)
            setShowMeassage('An unexpected error occurred.')
        }
    };


    const handleInputChange = event => {
        const { name, value } = event.target;
        const updatedValue = name === 'uhId' ? value.toUpperCase() : value;
        setInputValues(inputValues => ({
            ...inputValues,
            [name]: updatedValue,
        }));

        if (name === 'uhId') {
            // getPatientDetails(value);
            if (value.trim() === '') {

                resetForm();
            } else {
                getPatientDetails(value);
            }
        }

        if (name === 'pharmacyId') {
            setPharmacyId(value)
            getStockList(value);
        }

        if (name === 'ledgerId') {
            setLedgerId(value)

        }

    };


    const handleRowInputChange = (event, rowId) => {
        const { name, value } = event.target;
        if (event.target.name === "productId") {
            setProductId(event.target.value);
        }
        if (name === 'productId') {
            setProductId(value)
            getDetailsByProduct(value);
        }


        if (name === 'batchNo') {
            setBatchNo(value)
            getdetailsByBatch(rowId, value);
        }
        const updatedRows = [...rows];
        console.log("ddd", rows)
        const rowIndex = updatedRows.findIndex(row => row.id === rowId);
        if (name === "productId") {

            updatedRows[rowIndex][name] = value;
        } else {

            updatedRows[rowIndex][name] = value;
        }
        updatedRows[rowIndex][name] = value;


        updatedRows[rowIndex][quantity] = value;
        if (name === 'quantity' && value < 0) {
            setTotalDiscountRs(0)
            setTotalGrossAmount(0)
            setShowMeassage("quantity cannot be a negative number")
            setShowAlertToster(1)
            setTimeout(() => {
                setShowToster(0)

            }, 2000)

        }
        // const disct = document.getElementById("discount_" + rowId).value 
        // if (disct=== true) {
        //     updatedRows[rowIndex][discount] = disct;
        // }

        // const checkLength = parseFloat(value);

        // Check for negative numbers
        // if (name === 'discount' && checkLength < 0) {
        //     // setTotalDiscount(0)
        //     // setTotalPayableAmount(totalPayableAmount)
        //     setTotalDiscountRs(0)
        //     setTotalGrossAmount(0)
        //     setShowMeassage("Discount cannot be a negative number")
        //     setShowAlertToster(1)
        //     setTimeout(() => {
        //         setShowToster(0)

        //     }, 2000)
        // } else if (name === 'discount' && checkLength > 100) {
        //     // Check for values above 100
        //     // setTotalDiscount(0)
        //     // setTotalPayableAmount(totalAmount)
        //     setTotalDiscountRs(0)
        //     setShowMeassage("Discount percentage should be lower than 100")
        //     setShowAlertToster(1)
        //     setTimeout(() => {
        //         setShowToster(0)
        //     }, 2000)
        //     setDiscount(0)
        // }
        // else {
        //     handlePriceQuantityChangeD(event)
        // }


        if (name === 'quantity') {

            const { unitPrice, quantity } = updatedRows[rowIndex];

            // const discount = document.getElementById("discount_" + rowId).value
            // if (name === 'discountRs') {
            //     updatedRows[rowIndex].discount = null;
            //     document.getElementById("discount_" + rowId).value = ''
            // }


            let netAmount;
            // if (name === 'discountRs') {
            //     netAmount = (unitPrice * quantity) - discountRs;
            //     updatedRows[rowIndex].discountRs = parseFloat(discountRs);

            // } else {
            //     netAmount = (unitPrice * quantity) - (((unitPrice * quantity) * (discount || 0)) / 100);
            //     const discountRs = (unitPrice * quantity) * ((discount || 0) / 100);
            //     updatedRows[rowIndex].discountRs = parseFloat(discountRs.toFixed(2));

            // }

            // updatedRows[rowIndex].netAmount = netAmount;

            // if (name === 'quantity') {
            // const { unitPrice, quantity, discount } = updatedRows[rowIndex];
            const discount = document.getElementById("discount_" + rowId).value;
            updatedRows[rowIndex].discount = parseFloat(discount).toFixed(2);
            netAmount = (unitPrice * quantity) - (((unitPrice * quantity) * (discount || 0)) / 100);
            const discountRs = (unitPrice * quantity) * ((discount || 0) / 100);
            updatedRows[rowIndex].netAmount = (netAmount)
            updatedRows[rowIndex].discountRs = parseFloat(discountRs.toFixed(2));
            handlePriceQuantityChange(rowId, unitPrice, discount, quantity);
            recalculateAmounts(updatedRows)
            // } else if (name === 'discount' || name === 'discountRs') {

            // handlePriceQuantityChange(rowId, unitPrice, quantity, discount);
            //     recalculateAmounts(updatedRows)

            // }

            setRows(updatedRows);
            console.log("rows", ...rows)


            // if (name === "productId") {
            //     funcStockByProductId(rowId, event.target.value);

            // }


        };
    }

    const isRowFilled = (row) => {

        const requiredFields = ['productId', 'quantity',];

        return requiredFields.every(field => !!row[field]);
    };

    const addRow = () => {

        const previousRow = rows[rows.length - 1];
        if (isRowFilled(previousRow)) {
            const newRow = {
                id: rows.length + 1,
                name: `Row ${rows.length + 1}`,
            };
            setRows([...rows, newRow]);
            console.log("newRow", rows)
        } else {
            setShowAlertToster(1)
            setShowMeassage("Previous row is not filled completely")
        }
    };




    // const addRow = () => {
    //     const newRow = {
    //         id: rows.length + 1,
    //         name: `Row ${rows.length + 1}`,
    //     }
    //     // const newRowId = rows.length + 1; // Generate sequential IDs

    //     setRows([...rows, newRow]);
    //     setRows([...rows, newRow]);
    //     console.log("rows",rows)

    // };

    // const removeRow = (id) => {

    //     // Ensure that the first row is never removed

    //     if (id !== 1) {

    //         setRows(rows.filter(row => row.id !== id));

    //     }

    // };

    const handlePriceQuantityChange = (rowId, unitPrice, discount, quantity) => {
        const updatedRows = rows.map(row => {

            if (row.id === rowId) {
                const netAmount = (unitPrice * quantity) - (((unitPrice * quantity) * discount) / 100);
                const discountRs = (unitPrice * quantity) * ((discount || 0) / 100);
                return { ...row, unitPrice, quantity, discount, discountRs, netAmount };
            }
            return row;
        });
        setRows(updatedRows);
        console.log("updatedRows", rows)
        const netTotalAmount = updatedRows.reduce((sum, row) => sum + (row.netAmount || 0), 0);
        const totalDiscountRs = updatedRows.reduce((sum, row) => sum + (row.discountRs || 0), 0);
        // const totalGrossAmount = netTotalAmount + totalDiscountRs
        setTotalDiscountRs(totalDiscountRs);
        // setTotalGrossAmount(totalGrossAmount);
        setTotalPayableAmount((netTotalAmount));
    };

    // const handlePriceQuantityChangeD = (rowId, unitPrice, quantity, discount) => {
    //     const updatedRows = rows.map(row => {
    //         if (row.id === rowId) {
    //             const netAmount = (unitPrice * quantity) - (((unitPrice * quantity) * discount) / 100);
    //             // const discountRs = (unitPrice * quantity) * ((discount || 0) / 100);
    //             return { ...row, unitPrice, quantity, netAmount };
    //         }
    //         return row;
    //     });
    //     setRows(updatedRows);
    //     const netTotalAmount = updatedRows.reduce((sum, row) => sum + (row.netAmount || 0), 0);
    //     const totalDiscountRs = updatedRows.reduce((sum, row) => sum + (row.discountRs || 0), 0);
    //     // const totalGrossAmount = netTotalAmount + totalDiscountRs
    //     setTotalDiscountRs(totalDiscountRs);
    //     // setTotalGrossAmount(totalGrossAmount);
    //     setTotalPayableAmount(netTotalAmount.toFixed(2));
    // };


    const recalculateAmounts = (updatedRows) => {

        const newNetTotalAmount = updatedRows.reduce((sum, row) => sum + (row.netAmount || 0), 0);
        setTotalPayableAmount(newNetTotalAmount);


        const netNetTotalDiscountRs = calculateTotalDiscount(updatedRows);
        setTotalDiscountRs(parseFloat(netNetTotalDiscountRs.toFixed(2)));


        const newTotalPayableAmount = newNetTotalAmount + netNetTotalDiscountRs;
        setTotalGrossAmount(newTotalPayableAmount);
    };


    const calculateTotalDiscount = (rows) => {

        return rows.reduce((sum, row) => sum + (row.discountRs || 0), 0);
    };




    const removeRow = (id) => {

        if (id !== 1) {
            const updatedRows = rows.filter(row => row.id !== id);
            setRows(updatedRows);


            recalculateAmounts(updatedRows);

        }
    };

    const handleAddData = async () => {

        try {
            const collectedData = rows.map((row, index) => {
                const productId = rows[index].productId;
                const stockDetails = stockDetailsMap[productId] || {};
                return {
                    productId: rows[index].productId,
                    unitId: stockDetails.unitID,
                    batchNo: stockDetails.batchNo,
                    unitPrice: stockDetails.unitPrice,
                    quantity: document.getElementById(`quantity_${row.id}`).value,
                    netAmount: document.getElementById(`netAmount_${row.id}`).value,
                    isknowmed: stockDetails.isknowmed
                }

            });


            const isDataValid = collectedData.every(item => {
                return ValidationPharmacySale(

                    item.productId,
                    item.unitId,
                    item.batchNo,
                    item.unitPrice,
                    item.quantity,

                );
            });

            console.log("collectedData", collectedData)
            if (isDataValid) {

                const filteredData = collectedData.filter(item => Object.values(item).some(value => value !== null && value !== ''));

                const saleData = {
                    ledgerId: inputValues.ledgerId,
                    pharmacyId: inputValues.pharmacyId,
                    uhId: inputValues.uhId,
                    pmId: inputValues.pmId,
                    grossAmount: totalGrossAmount,
                    discount: totalDiscountRs,
                    netAmount: totalPayableAmount,
                    paymentMode: paymentMode,
                    transactionId: byCard,
                    bankId:bank,
                    chequeNo: chequeNo,
                    chequeDate: chequeDate,        
                    userId: window.userId,
                    jsonSale: JSON.stringify(filteredData),
                };

                let response = await PostSaleItems(saleData);

                if (response.status === 1) {
                    setShowMeassage("Data Saved Successfully!!")
                    setShowSuccessToster(1)
                    setTimeout(() => {
                        setShowToster(0)
                    }, 2000)




                    let allsaleresponse = await GetAllSale();
                    console.log("allsaleresponse", allsaleresponse)
                    if (allsaleresponse.status === 1) {
                        const allSales = allsaleresponse.responseValue;

                        if (allSales.length > 0) {
                            const lastSale = allSales[allSales.length - 1];

                            const billNo = lastSale.billNo;
                            const billDate = lastSale.billDate;
                            const pharmacy = lastSale.pharmacy;
                            const gstNo = lastSale.gstNo;
                            const licenseNo = lastSale.licenseNo;
                            const contactNo = lastSale.contactNo;
                            let response = await GetAllSaleByBillNo(billNo);
                            console.log("response", response)
                            if (response.status === 1) {

                                const allSales = response.responseValue;

                                const jsonSaleData = allSales.map((list, index) => {
                                    return {
                                        itemName: list.brandName,
                                        unitName: list.unitName,
                                        batchNo: list.batchNo,
                                        unitPrice: list.unitPrice,
                                        quantity: list.quantity

                                    }

                                });
                                console.log("jsonSaleData", jsonSaleData)
                                const UHID = saleData.uhId
                                let data = await getPatientDetailByUhid(UHID, billNo);
                                if (data.status === 1) {
                                    const patientData = data.responseValue;

                                    window.sessionStorage.setItem("PrintSaleData", JSON.stringify({
                                        "uhId": saleData.uhId, "pmId": saleData.pmId, "billNo": billNo, "billDate": billDate, "pharmacy": pharmacy, "gstNo": gstNo,
                                        "contactNo": contactNo, "licenseNo": licenseNo,
                                        "grossAmount": saleData.grossAmount, "discount": saleData.discount,
                                        "netAmount": saleData.netAmount, "jsonSaleData": jsonSaleData, "patientData": patientData
                                    }));
                                    window.open("/salePrint/", 'noopener,noreferrer');
                                    resetForm(1);
                                }
                            }
                        }
                    }
                }

                else {
                    setShowMeassage(response.responseValue)
                    setShowAlertToster(1)
                    setTimeout(() => {
                        setShowToster(0)
                    }, 2000)
                }
            }
            else {

                setShowToster(1)
                setShowMeassage("Field can't be blank!")
                setShowAlertToster(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }
        }
        catch (e) {
            setShowAlertToster(1)
            setShowMeassage(e)
        }

    }



    const resetForm = (value) => {
        setInputValues({
            uhId: '',
            patientName: '',
            age: '',
            gender: '',
            crNo: '',
            mobileNo: '',
            grossAmount: '',
            netAmount: '',
            userId: window.userId,
        });


        setTotalDiscountRs(0)
        setTotalGrossAmount(0);
        setTotalDiscount(0);
        setTotalPayableAmount(0);
        setClearDropdown(value)

        setStockList([]);
        setProductStockList([])
        setPaymentMode(0)

        const updatedRows = rows.map(row => {
            if (row.id === 1) {
                return {
                    ...row,

                    unitName: document.getElementById(`unitName_${row.id}`).value = '',
                    unitPrice: document.getElementById(`unitPrice_${row.id}`).value = '',
                    // batchNo: document.getElementById(`batchNo_${row.id}`).value = '',
                    batchExpiry: document.getElementById(`batchExpiry_${row.id}`).value = '',
                    daysLeftToExpiry: document.getElementById(`daysLeftToExpiry_${row.id}`).value = '',
                    closingStock: document.getElementById(`closingStock_${row.id}`).value = '',
                    quantity: document.getElementById(`quantity_${row.id}`).value = '',
                    discount: document.getElementById(`discount_${row.id}`).value = '',
                    // discountRs: document.getElementById(`discountRs_${row.id}`).value = '',
                    netAmount: document.getElementById(`netAmount_${row.id}`).value = '',

                };
            }
            return row;
        });
        setRows(updatedRows);
        setRows([{ id: 1 }]);
    };

    useEffect(() => {
GetPaymentModes()
        getStockList();
        document.getElementById("paymentModeCard").style.display = "none";
        document.getElementById("paymentModeRefNo").style.display = "none";
        document.getElementById("bnkdetails").style.display = "none";
        // getProductStockList()
        console.log("Updated Rows:", rows);

    }, [rows])
    document.body.dir = i18n.dir();
    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-12'>
                        <div className="med-box">
                        <div className="inner-content" >
                            <div className="row">
                                 <div className="fieldsett-in">
                                <div className="fieldsett">
                                    <span className="fieldse">{t("Pharmacy")}</span>
                                    <div className="row mt-2 px-2">
                                        <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6">
                                            <div className="drpWithSearch">
                                                {pharmacyList &&
                                                    <div className="drpWithSearch me-2 mb-2" style={{ maxWidth: '200px' }}>
                                                        <DropdownWithSearch defaulNname="Select Pharmacy" id={pharmacyId} name='pharmacyId'
                                                            getvalue={handleInputChange} list={pharmacyList} valueName="id" displayName="pharmacy"
                                                            editdata={""} clear={clearDropdown} clearFun={resetForm} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6">
                                            <div className="drpWithSearch">
                                                {ledgerList &&
                                                    <div className="drpWithSearch me-2 mb-2" style={{ maxWidth: '200px' }}>
                                                        <DropdownWithSearch defaulNname="Select Ledger" id={ledgerId} name='ledgerId'
                                                            getvalue={handleInputChange} list={ledgerList} valueName="id" displayName="ledgerName"
                                                            editdata={""} clear={clearDropdown} clearFun={resetForm} />
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                           
                        </div>
                        <div className='col-12'>

<div className="fieldsett-in">
    <div className="fieldsett">
        <span className="fieldse">{t("Customer_Details")}</span>
        <div className="row mt-2 px-2">
            <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6">
                <div className="mb-2">
                    <img src={uhid} className="icnn" alt="" />
                    <label htmlFor="uhId" className="form-label">{t("Uhid")} <span className="starMandatory">*</span></label>
                    <input type="text" className="form-control form-control-sm" id="uhId" name="uhId" value={inputValues.uhId} placeholder={t("UHID")} onChange={handleInputChange} />
                </div>
            </div>

            <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6">
                <div className="mb-2">
                    <img src={UserIcon} className="icnn" alt="" />
                    <label for="patientName" className="form-label">Name <span className="starMandatory"></span></label>
                    <input type="text" className="form-control form-control-sm" id="patientName" name="patientName" value={inputValues.patientName} placeholder="" />
                </div>
            </div>

            <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6">
                <div className="mb-2">
                    <img src={smartphone} className="icnn" alt="" />
                    <label for="mobileNo" className="form-label">Mobile No<span className="starMandatory"></span></label>
                    <input type="number" className="form-control form-control-sm" id="mobileNo" name="mobileNo" value={inputValues.mobileNo} placeholder="" />
                </div>
            </div>

            <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6">
                <div className="mb-2">
                    <img src={genderIcon} className='icnn' alt='' />
                    <label htmlFor="gender" className="form-label">Gender<span className="starMandatory"></span></label>
                    <input type="text" className="form-control form-control-sm" id="gender" name="gender" value={inputValues.gender} placeholder="" />
                </div>
            </div>

            <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6">
                <div className="mb-2">
                    <img src={ageIcon} className='icnn' alt='' />
                    <label htmlFor="age" className="form-label">Age<span className="starMandatory"></span></label>
                    <input type="text" className="form-control form-control-sm" id="age" name="age" value={inputValues.age} placeholder="" />
                </div>
            </div>

            <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6">
                <div className="mb-2">

                    <label htmlFor="crNo" className="form-label">CR. No<span className="starMandatory"></span></label>
                    <input type="text" className="form-control form-control-sm" id="crNo" name="crNo" value={inputValues.crNo} placeholder="" />
                </div>
            </div>
        </div>
    </div>
</div>

                            </div>
                           <div className='col-12 p-2'>
                           <div className="fieldsett">
                           <span className='fieldse'>Item Details</span>
                                <div className='med-table-section' style={{ minHeight: '35vh', overflow: 'auto' }}>
                                    <table className='med-table border_ striped billingTable'>
                                        <thead>
                                            <tr>
                                                <th className='text-center'>#</th>
                                                <th style={{ width: '20%' }}>{t("Item")}</th>
                                                <th>{t("Batch_No")}</th>
                                                <th>{t("Unit_Name")}</th>
                                                <th>{t("Unit_Price")}</th>
                                                <th>{t("Batch Mfg")}</th>
                                                <th>{t("Expiry Date")}</th>
                                                <th>{t("Available_Quantity")}</th>
                                                <th>{t("Selling Discount(%)")}</th>
                                                <th>{t("Quantity")}</th>
                                                {/* <th>{t("Discount(Rs)")}</th> */}
                                                <th>{t("NetAmount")}</th>
                                                <th className='text-center'>{t("Action")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows.map((row, index) => {
                                                return (
                                                    <tr key={row.id}>
                                                        <td className='text-center'>{index + 1}</td>

                                                        <td>
                                                            <div className="drpWithSearch">
                                                                {stockList && <DropdownWithSearch defaulNname={t("Search")} id={`productId_${row.id}`} name='productId'
                                                                    getvalue={(event) => handleRowInputChange(event, row.id)} list={stockList} valueName="productId" displayName="brandName"
                                                                    editdata={""} clear={clearDropdown} clearFun={resetForm} style={{ width: '250px' }} />}
                                                            </div>

                                                        </td>

                                                        <td>
                                                            <div className="drpWithSearch" style={{ width: '150px' }}>
                                                                {productStockList && <DropdownWithSearch defaulNname={t("Search")} id={`batchNo_${row.id}`} name='batchNo'
                                                                    getvalue={(event) => handleRowInputChange(event, row.id)} list={productStockList} valueName="batchNo" displayName="batchNo"
                                                                    editdata={""} clear={clearDropdown} clearFun={resetForm} />}
                                                            </div>

                                                        </td>



                                                        {/* <td>{unitList && <DropdownWithSearch defaulNname="Search" id={`unitId_${row.id}`} name='unitId'
                                                            getvalue={(event) => handleRowInputChange(event, row.id)} list={unitList} valueName="id" displayName="unitName"
                                                            editdata={""} clear={clearDropdown} clearFun={resetForm} />}</td> */}

                                                        <td><input type='text' id={`unitName_${row.id}`} name='unitName' disabled placeholder={t("")} /></td>
                                                        {/* <td><input type='text' id={`batchNo_${row.id}`} name='batchNo' readOnly placeholder={t("batch_No")} /></td> */}
                                                        <td><input type='text' id={`unitPrice_${row.id}`} name='unitPrice' disabled placeholder={t("")} /></td>
                                                        <td><input type='text' id={`batchExpiry_${row.id}`} name='batchExpiry' disabled placeholder={t("")} /></td>


                                                        <td><input type='text' id={`daysLeftToExpiry_${row.id}`} name='daysLeftToExpiry' disabled placeholder={t("")} /></td>
                                                        <td><input type='text' id={`closingStock_${row.id}`} name='closingStock' disabled placeholder={t("")} /></td>

                                                        {/* <td><input type='text' id={`batchNo_${row.id}`} name='batchNo' value={row.batchNo} onChange={(event) => handleRowInputChange(event, row.id)} 
                                                        placeholder='Enter Batch No.' /></td> */}

                                                        {/* <td><input type='number' id={`price_${row.id}`} name='price' value={row.price} unitPrice
                                                            placeholder='Enter Price' /></td> */}



                                                        <td><input type='number' id={`discount_${row.id}`} name='discount' disabled
                                                            placeholder={t("")} /></td>

                                                        <td style={{ width: '7%' }}><input type='number' id={`quantity_${row.id}`} name='quantity' onChange={(event) => handleRowInputChange(event, row.id)}
                                                            placeholder={t("Enter_Quantity")} /></td>

                                                        {/* <td><input type='number' id={`discountRs_${row.id}`} name='discountRs' value={row.discountRs} placeholder={t("Enter_discount")} onChange={(event) => handleRowInputChange(event, row.id)} /></td> */}


                                                        <td style={{ width: '8%' }}><input type='number' id={`netAmount_${row.id}`} name='netAmount' value={row.netAmount} disabled placeholder={t("Enter_Net_Amount")}
                                                        /></td>
                                                        <td>
                                                            <div className='action-button'><i className="bi bi-plus" onClick={addRow}></i> &nbsp;<i className="bi bi-trash3" onClick={() => removeRow(row.id)}></i></div>
                                                        </td>
                                                    </tr>

                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='row pe-4 ps-2'>
                      
                      <div className="col-md-6 mt-3">
                          <div className="fieldsett">
                              <span className='fieldse'>Transaction Details</span>
                              <div className="inner-content">                                  
                                  <div class="mb-2" id="paymentModediv">
                                      <label for="PaymentMode" class="form-label">
                                          <img src={imgPaymentMode} alt='' /> Payment Mode{" "}
                                          <span class="starMandatory">*</span>
                                      </label>
                                      <select id="Payment" disabled={isPaymentDisabled} class="form-control form-control-sm" value={paymentMode} onChange={handlePaymentMode}  >
                                          <option value="0" selected>Select Payment Mode</option>
                                          {PaymentModeList && PaymentModeList.map((val, index) => {
                                              return (
                                                  <option key={index} value={val.id}>{val.paymentMethodName}</option>
                                              )
                                          })}
                                      </select>
                                  </div>

                                  <div class="mb-2" id="paymentModeCard">
                                      <label for="byCard" class="form-label">
                                          <img src={imgCardNo} alt='' /> Transaction Id
                                          <span class="starMandatory">*</span>
                                      </label>
                                      <input
                                          type="text"
                                          class="form-control form-control-sm"
                                          id="byCard"
                                          placeholder="Transaction No."
                                          name="byCard"
                                          onChange={HandlePaymentDetails}
                                      />
                                  </div>
                                  <div class="mb-2" id="paymentModeRefNo">
                                      <label for="byOnline" class="form-label">
                                          <img src={imgCardNo} alt='' /> RefNo
                                          <span class="starMandatory">*</span>
                                      </label>
                                      <input
                                          type="text"
                                          class="form-control form-control-sm"
                                          id="refNo"
                                          placeholder="Enter UPI Ref. Number"
                                          name="refNo"
                                          onChange={HandlePaymentDetails}
                                      />
                                  </div>
                                  <div className="container-fluid mb-2 p-0" id="bnkdetails">
                                      <div class="row">
                                          <div className="col-md-4">
                                              <label for="bank" class="form-label">
                                                  <img src={imgBank} alt='' /> Bank
                                                  <span class="starMandatory">*</span>
                                              </label>
                                              <select className="form-control form-control-sm" id="selectBank" name='selectBank'  onChange={HandlePaymentDetails}>
                                                  <option value="0">Select Bank</option>
                                                  {bankList &&
                                                      bankList.map((val, ind) => {
                                                          return (
                                                              <option value={val.id}>{val.bankName}</option>
                                                          );
                                                      })}
                                              </select>
                                            
                                          </div>
                                          <div className="col-md-4">
                                              <label for="chequeNo" class="form-label">
                                                  <img src={imgCheque} alt='' /> Cheque No.
                                                  <span class="starMandatory">*</span>
                                              </label>
                                              <input
                                                  type="text"
                                                  class="form-control form-control-sm"
                                                  id="chequeNo"
                                                  placeholder="Enter Cheque No."
                                                  name="chequeNo"
                                                  onChange={HandlePaymentDetails}
                                              />
                                          </div>
                                          <div className="col-md-4">
                                              <label for="chequeDate" class="form-label">
                                                  <img src={imgCheque} alt='' /> Cheque Date.
                                                  <span class="starMandatory">*</span>
                                              </label>
                                              <input
                                                  type="date"
                                                  class="form-control form-control-sm"
                                                  id="chequeDate"
                                                  name="chequeDate"
                                                  min={new Date().toISOString().split('T')[0]}
                                                  onChange={HandlePaymentDetails}
                                              />
                                          </div>
                                      </div>
                                  </div>

                                
                              </div>
                          </div>
                      </div>


                      <div className='col-md-6 mt-3'>
                      <div className="fieldsett">
                              <span className='fieldse'>Amount_Details</span>
                         
                              {/* <div className="title">{t("Amount_Details")}</div> */}
                              <div className='med-table-section'>
                                  <table className='med-table border-bottom border_ striped_ mt-1'>
                                      <tbody>
                                          <tr>
                                              <td><b className='color546788'>{t("Total_Amount")}</b></td>
                                              <td className='text-right'><b className='color546788'>{parseFloat(totalGrossAmount).toFixed(2)}</b></td>
                                          </tr>
                                          <tr>
                                              <td><b className='color546788'>{t("Total_Discount")}</b></td>
                                              <td className='text-right'><b className='color546788'>{parseFloat(totalDiscountRs).toFixed(2)}</b></td>
                                          </tr>
                                          {/* <tr>
                                              <td><b className='color546788'>Advance Amount(Rs)</b></td>
                                              <td><b className='color546788'>500.00</b></td>
                                          </tr>
                                          <tr>
                                              <td><b className='color546788'>Balance Amount(Rs)</b></td>
                                              <td><b className='color546788'>500.00</b></td>
                                          </tr> */}
                                          <tr>
                                              <td><b className='color546788'>{t("Total_Payable_Amount")}</b></td>
                                              <td className='text-right'><b className='color546788'>{parseFloat(totalPayableAmount).toFixed(2)}</b></td>
                                          </tr>
                                          {/* <tr>
                                              <td><b className='color319731'>Paid Amount(Rs)</b></td>
                                              <td><b className='color319731'>500.00</b></td>
                                          </tr> */}
                                      </tbody>
                                  </table>
                              </div>
                          
                      </div>
                      </div>
                      {
                          showAlertToster === 1 ? <AlertToster message={showMessage} handle={setShowAlertToster} /> : ""
                      }
                      {
                          showSuccessToster === 1 ? <SuccessToster message={showMessage} handle={setShowSuccessToster} /> : ""
                      }

                  </div>

                  <div className='row mt-3'>
                      <div className='col-12'>
                          
                              <div className="inner-content text-right">
                                  <div className="mb-2 mt-2 relative">
                                      <div>
                                          <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleAddData}><img src={saveButtonIcon} className='icnn' />{t("Save & Print")}</button>
                                          <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={() => { resetForm(1) }}><img src={clearIcon} className='icnn' />{t("Clear")}</button>
                                          {/* <button type="button" className="btn btn-save btn-sm mb-1_ me-1"><img src={imgPrint}/> Print</button>  */}
                                    
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row mt-3">
                       
                    </div>

                    <div className='row mt-3'>
                       
                    </div>

                  


                </div>
            </section>
        </>
    )
}


