import React, { useEffect, useState } from 'react'
import Barcode from 'react-barcode';
export default function PatientLabel() {
    let [patientData, setPatientData] = useState([])

    const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;
    useEffect(() => {
        let patientdataList = JSON.parse(window.sessionStorage.getItem("IPDpatientList"))
        patientdataList.map((val, ind) => {
            if (val.uhId === uhID) {
                setPatientData(patientdataList[0])
                console.log("enter", patientdataList)
            }
        })
    }, [])
    return (
        <>
            <div className="ps-2 ">
            <div className='d-flex flex-row gap-2 justify-content-center align-items-center pt-1' >
                     <Barcode value={uhID} width={1} height={15}  displayValue={false}/>
                </div>
                <div className='d-flex flex-row gap-2 '>
                    <div className='pt-1' style={{ fontSize: "12px" }}><strong>Patient Name:</strong> <span style={{ width: 'auto', borderBottom: '1px dashed black', display: 'inline-block', fontWeight: '400', fontSize: "12px" }}>{patientData.length !== 0 && patientData.patientName}</span> </div>
                    <div className='pt-1 pb-1' style={{ fontSize: "12px" }}><strong>MRN No.</strong> <span style={{ width: '80px', borderBottom: '1px dashed black', display: 'inline-block', fontWeight: '400', fontSize: "12px" }}>{patientData.length !== 0 && patientData.uhId}</span> </div>

                </div>
                {/* <tr>
                    <th colSpan={6} className='pt-1 pb-1'>MRD No. <span style={{ width: '220px', borderBottom: '1px dashed black', display: 'inline-block', fontWeight: '400' }}>{patientData.length !== 0 && patientData.uhId}</span> </th>
                </tr> */}
                <div className='d-flex flex-row gap-2'>
                    <div className='pt-1 pb-1' style={{ fontSize: "12px" }}><strong>Age/Sex: </strong><span style={{ width: '70px', borderBottom: '1px dashed black', display: 'inline-block', fontWeight: '400' }}>{patientData.length !== 0 && patientData.age} / {patientData.length !== 0 && patientData.gender}</span> </div>
                    <div className='pt-1 pb-1' style={{ fontSize: "12px" }}><strong>DOA: </strong><span style={{ width: '90px', borderBottom: '1px dashed black', display: 'inline-block', fontWeight: '400' }}>{patientData.length !== 0 && patientData.admittedDate}</span> </div>
                    {/* <th colSpan={3} className='pt-1 pb-1'>Sex: <span style={{ width: '80px', borderBottom: '1px dashed black', display: 'inline-block', fontWeight: '400' }}></span> </th> */}
                </div>
                <div className='d-flex flex-row gap-2'>
                    <div className='pt-1 pb-1' style={{ fontSize: "12px" }}><strong>Unit:</strong> <span style={{ width: '70px', borderBottom: '1px dashed black', display: 'inline-block', fontWeight: '400' }}></span> </div>
                </div>
            </div>
        </>
    )
}
