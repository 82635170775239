import { useEffect, useState } from "react"
import ValidationCountryMaster from '../../../Validation/SuperAdmin/Master/ValidationCountryMaster'
import PostCountryMaster from "../../Api/Master/CountryMaster/PostCountryMaster"
import GetCountryMaster from "../../Api/Master/CountryMaster/GetCountryMaster"
import DeleteCountryMaster from "../../Api/Master/CountryMaster/DeleteCountryMaster"
import PutCountryMaster from "../../Api/Master/CountryMaster/PutCountryMaster"
import TableContainer from "../../../Component/TableContainer"
import Heading from "../../../Component/Heading"
import BoxContainer from "../../../Component/BoxContainer"
import Toster from '../../../Component/Toster'
import TosterUnderProcess from '../../../Component/TosterUnderProcess'
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import { t } from "i18next"

export default function CountryMaster() {

    let [countryList, setCountryList] = useState()
    let [updateBool, setUpdateBool] = useState(0)
    let [sendForm, setSendForm] = useState({ "userId": window.superAdminUserId })
    let [loder, setLoder] = useState(1)
    let [showToaster, setShowToaster] = useState(0)
    let [message, setMessage] = useState("")
    let [rowId, setRowId] = useState('')

    let [showUnderProcess, setShowUnderProcess] = useState(0)
    let [showToster, setShowToster] = useState(0)
    let [tosterMessage, setTosterMessage] = useState("")
    let [tosterValue, setTosterValue] = useState(0)
    const [searchTerm, setSearchTerm] = useState('');
    console.log("superAdminUserId", window.superAdminUserId)
    const [numberError, setNumberError] = useState(false);

    // Function to handle changes in the search term
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    //Handle Save
    // let saveForm = async () => {
    //     let valresponse = ValidationCountryMaster(sendForm.countryName, sendForm.countryCode)

    //     if (valresponse) {
    //         setShowUnderProcess(1)
    //         let response = await PostCountryMaster(sendForm);
    //         if (response.status === 1) {
    //             setShowUnderProcess(0)
    //             setShowToster(1)
    //             setTosterMessage("Data Save Successfully!")
    //             setTosterValue(0)
    //             setTimeout(() => {
    //                 setShowToster(0)
    //             }, 2000)

    //             handleClear();
    //         }
    //         else {
    //             setShowUnderProcess(0)
    //             setShowToster(1)
    //             setTosterMessage(response.responseValue)
    //             setTosterValue(1)
    //             setTimeout(() => {
    //                 setShowToster(0)
    //             }, 2000)
    //         }
    //         getdata()
    //     }
    //     else {
    //         setShowUnderProcess(0)
    //         setShowToster(1)
    //         setTosterMessage("Field can't be blank or contain numbers!")
    //         setTosterValue(1)
    //         setTimeout(() => {
    //             setShowToster(0)
    //         }, 2000)
    //     }
    // }

    //Handle Save
    let saveForm = async () => {
        if (numberError) {
            setShowToster(1)
            setTosterMessage("Field can't contain numbers!")
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
            return;
        }

        let valresponse = ValidationCountryMaster(sendForm.countryName, sendForm.countryCode)

        if (valresponse) {
            setShowUnderProcess(1)
            let response = await PostCountryMaster(sendForm);
            if (response.status === 1) {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage("Data Saved Successfully!")
                setTosterValue(0)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)

                handleClear();
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage(response.responseValue)
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }
            getdata()
        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Field can't be blank!")
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }



    //Get data
    let getdata = async () => {
        let getResponse = await GetCountryMaster();
        if (getResponse.status === 1) {
            // setLoder(0)
            setCountryList(getResponse.responseValue)
        }
    }


    // Handle Change
    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        // Check if the input is valid (only alphabetic characters)
        if (name === "countryName" && !/^[a-zA-Z]*$/g.test(value)) {
            setNumberError(true); // Set numberError to true if the input contains non-alphabetic characters
        } else {
            setNumberError(false); // Set numberError to false if the input is valid
        }

        setSendForm(sendForm => ({
            ...sendForm,
            [name]: value
        }));
    }


    //Handle Delete
    let handleDeleteRow = async () => {
        // setLoder(1)
        setShowUnderProcess(1)
        let response = await DeleteCountryMaster(rowId)
        if (response.status === 1) {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Data Deleted Successfully!")
            setTosterValue(0)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
            getdata()
            handleClear();
        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage(response.responseValue)
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }

    //Handle Button Change
    let handleUpdate = async (id, countryCode, countryName, superAdminUserId) => {
        setUpdateBool(1)
        setNumberError(false);
        setSendForm(sendForm => ({
            ...sendForm,
            "id": id,
            "countryName": countryName,
            "countryCode": countryCode,
            "userId": superAdminUserId,

        }))

        document.getElementById("countryName").value = countryName;
        document.getElementById("countryCode").value = countryCode;
    }

    // Handle Update
    let saveUpdate = async () => {
        if (numberError) {
            setShowToster(1)
            setTosterMessage("Field can't contain numbers!")
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
            return;
        }

        let valresponse = ValidationCountryMaster(sendForm.countryName, sendForm.countryCode)

        if (valresponse) {
            setShowUnderProcess(1)
            let response = await PutCountryMaster(sendForm)
            if (response.status === 1) {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage("Data Updated Successfully!")
                setTosterValue(0)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
                setUpdateBool(0)
                getdata()
                handleClear();
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage(response.responseValue)
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }
        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Field can't be blank!")
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }

    //Handle Clear
    let handleClear = () => {
        setSendForm({ "userId": window.superAdminUserId })
        document.getElementById("countryName").value = "";
        document.getElementById("countryCode").value = "";
        setUpdateBool(0)
        setNumberError(false);
    }
    useEffect(() => {
        getdata()
    }, [])

    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <Heading text='Country Master' />
                            <BoxContainer>
                                <div className="mb-2 me-2">
                                    <label htmlFor="countryCode" className="form-label">Country Code <span className="starMandatory">*</span></label>
                                    <input type="number" name="countryCode" id="countryCode" onChange={handleChange} className="form-control form-control-sm" placeholder="Enter Country code" />
                                </div>
                                <div className="mb-2 me-2">
                                    <label htmlFor="countryName" className="form-label">Country Name <span className="starMandatory">*</span></label>
                                    {/* <input type="text" name="countryName" id="countryName" onChange={handleChange} className="form-control form-control-sm" placeholder="Enter Country name" /> */}

                                    <input
                                        type="text"
                                        name="countryName"
                                        id="countryName"
                                        onChange={handleChange}
                                        className={`form-control form-control-sm ${numberError ? 'is-invalid' : ''}`}
                                        placeholder="Enter Country Name"
                                        pattern="[A-Za-z]+"
                                    />
                                    {numberError && <div className="invalid-feedback">Please enter a valid name without numbers.</div>}
                                </div>

                                <div className="mb-2 relative">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                    <div>
                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />

                                                    : <div>
                                                        {updateBool === 0 ?
                                                            <>
                                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={saveForm}><img src={saveButtonIcon} className='icnn' alt='' />Save</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt='' />Clear</button>
                                                            </>
                                                            :
                                                            <>
                                                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={saveUpdate}>Update</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { setUpdateBool(0); handleClear() }}>Cancel</button>
                                                            </>
                                                        }
                                                    </div>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </BoxContainer>
                        </div>
                        <div className="col-12 mt-2">
                            <div className='handlser'>
                                <Heading text="All Country List" />
                                <div style={{ position: 'relative' }}>
                                    <input type="text" className='form-control form-control-sm' placeholder="Search..." value={searchTerm} onChange={handleSearch} />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
                            <div className="med-table-section" style={{ "height": "74vh" }}>
                                <TableContainer>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>#</th>
                                            <th>Country Code</th>
                                            <th>Country Name</th>
                                            <th style={{ "width": "10%" }} className="text-center">Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {countryList && countryList.filter((val) => `${val.countryCode} ${val.countryName}`.toLowerCase().includes(searchTerm.toLowerCase())).map((val, ind) => {
                                            return (
                                                <tr key={val.id}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td>{val.countryCode}</td>
                                                    <td>{val.countryName}</td>
                                                    <td>
                                                        <div className="action-button">
                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={editBtnIcon} className='' alt='' onClick={() => { handleUpdate(val.id, val.countryCode, val.countryName, val.superAdminUserId) }} title="Edit" /></div>
                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowId(val.id) }} title="Delete" /></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </TableContainer>
                                {/*  <!------------------- Start Delete Modal ---------------------------------->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">

                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'> Delete?</div>
                                                <div className='popDeleteContent'> Are you sure you want to delete?</div>
                                            </div>
                                            <div className="modal-footer1 text-center">

                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={handleDeleteRow} data-bs-dismiss="modal">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

                            </div>

                        </div>


                    </div>
                </div>

            </section>
            {/* <Loder val={loder} /> */}
        </>
    )
}
