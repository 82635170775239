async function StopInfusionorFluid(obj) {
    //const url = `https://localhost:7225/api/PatietnFluid/StopFluidorInfusion?infusionRowId=${obj.infusionRowId}&userId=${userId}`;
    const url = `${window.AppbaseUrl}/api/PatietnFluid/StopFluidorInfusion?infusionRowId=${obj.infusionRowId}&userId=${obj.userId}`;
    let head = { 'Content-Type': 'application/JSON', accept: '*/*' };
    let data = {};
    let response = await fetch(url, {
        headers: head,
        method: 'POST',
        body:JSON.stringify(obj)
    }).then((res) => res.json()).then(data);
   console.log('response stop',response);
    return response;
}

export default StopInfusionorFluid;