import React, { useEffect, useState } from 'react'

import POSTInsertPatientVital from '../../API/OPD/Vitals/POSTInsertPatientVital';
import BPSystolic from '../../../assets/images/vitalsicons/BPSystolic.svg'
import bp from '../../../assets/images/vitalsicons/bp.svg'
import temprature from '../../../assets/images/vitalsicons/temprature.svg'
import lungs from '../../../assets/images/vitalsicons/lungs.svg'
import heart from '../../../assets/images/vitalsicons/heart.svg'
import pulse from '../../../assets/images/vitalsicons/pulse.svg'
import spo from '../../../assets/images/vitalsicons/spo.svg'
import rbs from '../../../assets/images/vitalsicons/rbs.svg'

import { useTranslation } from 'react-i18next';
import  i18n from "i18next";

export default function VitalsComponent({saveVitals = 0, setSaveVitals}) {
    const { t } = useTranslation();
    document.body.dir = i18n.dir();

    let [sendForm, setSendForm] = useState({}) //Chnage from  useState("") to useState({}) coz string data type state not accepting object value (11/29/23)
    let [showUnderProcess, setShowUnderProcess] = useState(0)
    let [showToster, setShowToster] = useState(0)
    let [tosterMessage, setTosterMessage] = useState("")
    let [tosterValue, setTosterValue] = useState(0)
    const [currentTime, setCurrentTime] = useState(new Date());
    let [showVentiUnderProcess, setShowVentiUnderProcess] = useState(0)
    let [showVentiToster, setShowVentiToster] = useState(0)
    let [ventiTosterMessage, setVentiTosterMessage] = useState("")
    let [ventiTosterValue, setVentiTosterValue] = useState(0)
    let [peepVal, setPeepVal] = useState('')
    let [fiotow, setFiotow] = useState('')
    let [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);

    let saveData = async () => {
        sendForm["uhid"] = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : ""
        // sendForm["uhid"] = JSON.parse(window.sessionStorage.getItem("activePatient")).Uhid
        sendForm["userId"] = window.userId
        sendForm["vitalDate"] = document.getElementById('vitalDate').value
        sendForm["vitalTime"] = document.getElementById('vitalTime').value
        let dateVal = document.getElementById('vitalDate').value;
        let timeVal = document.getElementById('vitalTime').value;
        let bpsVal = document.getElementById('vmValueBPSys').value;
        let bpdVal = document.getElementById('vmValueBPDias').value;
        let spo2Val = document.getElementById('vmValueSPO2').value;
        let RRVal = document.getElementById('vmValueRespiratoryRate').value;
        let HRVal = document.getElementById('vmValueHeartRate').value;
        let PRVal = document.getElementById('vmValuePulse').value;
        let TempVal = document.getElementById('vmValueTemperature').value;
        let RBSVal = document.getElementById('vmValueRbs').value;
        if (dateVal === '' || dateVal === undefined || dateVal === null) {
            document.getElementById('errDate').innerHTML = "Please select Date";
            document.getElementById('errDate').style.display = "block";
        }
        if (bpsVal === '' && bpdVal === '' && spo2Val === '' && RRVal === '' && HRVal === '' && PRVal === '' && TempVal === '' && RBSVal === '') {
            document.getElementById('errVital').innerHTML = "Please enter any vital";
            document.getElementById('errVital').style.display = "block";
        }
        else if (timeVal === '' || timeVal === undefined || timeVal === null) {
            document.getElementById('errTime').innerHTML = "Please select Time";
            document.getElementById('errTime').style.display = "block";
        }
        else if (bpsVal > 500) {
            document.getElementById('errBPS').innerHTML = "Enter valid value";
            document.getElementById('errBPS').style.display = "block";
        }
        else if (bpdVal > 1000) {
            document.getElementById('errBPD').innerHTML = "Enter valid value";
            document.getElementById('errBPD').style.display = "block";
        }
        else if (spo2Val > 100) {
            document.getElementById('errSPO2').innerHTML = "Enter valid value";
            document.getElementById('errSPO2').style.display = "block";
        }
        else if (RRVal > 50) {
            document.getElementById('errRR').innerHTML = "Enter valid value";
            document.getElementById('errRR').style.display = "block";
        }
        else if (HRVal > 220) {
            document.getElementById('errHR').innerHTML = "Enter valid value";
            document.getElementById('errHR').style.display = "block";
        }
        else if (PRVal > 220) {
            document.getElementById('errPR').innerHTML = "Enter valid value";
            document.getElementById('errPR').style.display = "block";
        }
        else if (TempVal > 110) {
            document.getElementById('errTemp').innerHTML = "Enter valid value";
            document.getElementById('errTemp').style.display = "block";
        }
        else if (RBSVal > 2400) {
            document.getElementById('errRBS').innerHTML = "Enter valid value";
            document.getElementById('errRBS').style.display = "block";
        }

        else {

            setShowUnderProcess(1)
            let response = await POSTInsertPatientVital(sendForm)
            if (response.status === 1) {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage("Data Saved SuccessFully!")
                setTosterValue(0)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
                handleClear();
                console.log(response)
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage(response.responseValue)
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)

            }
        }
    }

    let handleOnChange = (e) => {
        document.getElementById('errVital').style.display = "none";
        let name = e.target.name;
        let value = e.target.value;
        if (name === "vitalDate") {
            document.getElementById("errDate").style.display = "none";
        }
        else if (name === "vitalTime") {
            document.getElementById("errTime").style.display = "none";

        }
        else if (name === "vmValueBPSys") {
            document.getElementById("errBPS").style.display = "none";

        }
        else if (name === "vmValueBPDias") {
            document.getElementById("errBPD").style.display = "none";

        }
        else if (name === "vmValueSPO2") {
            document.getElementById("errSPO2").style.display = "none";

        }
        else if (name === "vmValueRespiratoryRate") {
            document.getElementById("errRR").style.display = "none";

        }
        else if (name === "vmValueHeartRate") {
            document.getElementById("errHR").style.display = "none";

        }
        else if (name === "vmValuePulse") {
            document.getElementById("errPR").style.display = "none";

        }
        else if (name === "vmValueRbs") {
            document.getElementById("errRBS").style.display = "none";

        }
        else if (name === "vmValueTemperature") {
            document.getElementById("errTemp").style.display = "none";

        }
        setSendForm(sendForm => ({
            ...sendForm,
            [name]: value
        }))

    };

    let handleClear = () => {
        document.getElementById('errVital').style.display = "none";
        document.getElementById('errBPS').style.display = "none";
        document.getElementById("errBPD").style.display = "none";
        document.getElementById("errSPO2").style.display = "none";
        document.getElementById("errRR").style.display = "none";
        document.getElementById("errHR").style.display = "none";
        document.getElementById("errPR").style.display = "none";
        document.getElementById("errRBS").style.display = "none";
        document.getElementById("errTemp").style.display = "none";
        document.getElementById('vmValueBPSys').value = '';
        document.getElementById('vmValueBPDias').value = '';
        document.getElementById('vmValueTemperature').value = '';
        document.getElementById('vmValueRespiratoryRate').value = '';
        document.getElementById('vmValueHeartRate').value = '';
        document.getElementById('vmValuePulse').value = '';
        document.getElementById('vmValueSPO2').value = '';
        // document.getElementById('weight').value = '';
        // document.getElementById('height').value = '';
        document.getElementById('vmValueRbs').value = '';
        document.getElementById('vitalTime').value = '';
        // document.getElementById('vitalDate').value = '';
        document.getElementById('vitalDate').value = curdate();
        document.getElementById('vitalTime').value = formattedTime;
    };
    let curdate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        document.getElementById('vitalDate').value = formattedDate;
        return formattedDate;
    };
    const formattedTime = currentTime.toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
    });

    useEffect(() => {
        console.log("saveVitals", saveVitals)

        if (saveVitals !== 1) {
            curdate();
            document.getElementById('vitalTime').value = formattedTime;
        }
        else {
            saveData()
            setSaveVitals(0)
        }
        // curdate();
        // document.getElementById('vitalTime').value = formattedTime;
    }, [saveVitals]);



    return (
        <div className='vitallt'>
            <div className="boxcontainer whitebackgroundnopad wb" >
                <div className='d-flex justify-content-between'>
                    <div className='tblheading'>Vital Input</div>

                    <div className='mt-2  d-flex gap-1 vital-btn-date'>

                        <div>
                            <div className='vitals-cnt-in10'>
                                <input type='date' name="vitalDate" id='vitalDate' onChange={handleOnChange} />
                            </div>
                            <small id="errDate" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>

                        <div>
                            <div className='vitals-cnt-in10'>
                                <input type='time' name="vitalTime" id='vitalTime' onChange={handleOnChange} />
                            </div>
                            <small id="errTime" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>

                    </div>


                </div>

                <div className='vital-sub-heading'>General</div>
                <div className='vitals-cnt customeInput'>
                    <div className='vitals-cnt-in'>
                        <img src={BPSystolic} className='icnn1' />
                        <input type='number' placeholder='BPS(mm Hg)' name='vmValueBPSys' id='vmValueBPSys' onChange={handleOnChange} />

                        <small id="errBPS" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>


                    <div className='vitals-cnt-in'>
                        <img src={bp} className='icnn1' />
                        <input type='number' placeholder='BPD(mm Hg)' name='vmValueBPDias' id='vmValueBPDias' onChange={handleOnChange} />
                        <small id="errBPD" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={spo} className='icnn1' />
                        <input type='number' placeholder='SPO2' name='vmValueSPO2' id='vmValueSPO2' onChange={handleOnChange} />
                        <small id="errSPO2" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={lungs} className='icnn1' />
                        <input type='number' placeholder='RR/m' name='vmValueRespiratoryRate' id='vmValueRespiratoryRate' onChange={handleOnChange} />
                        <small id="errRR" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    {/* <div className='vitals-cnt-in'>
                        <img src={bp} className='icnn1' />
                        <input type='text' placeholder='BP_S-BP_D' style={{ width: "65px" }} />
                    </div> */}
                    <div className='vitals-cnt-in'>
                        <img src={heart} className='icnn1' />
                        <input type='number' placeholder='HR' name='vmValueHeartRate' id='vmValueHeartRate' onChange={handleOnChange} />
                        <small id="errHR" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>

                    {/* <div className='vitals-cnt-in'>
                        <img src={temprature} className='icnn1' />
                        <input type='text' placeholder='Temp(C)' style={{ width: "50px" }} />   
                    </div> */}


                    <div className='vitals-cnt-in'>
                        <img src={pulse} className='icnn1' />
                        <input type='number' placeholder='Pulse(Beats)' name='vmValuePulse' id='vmValuePulse' onChange={handleOnChange} />
                        <small id="errPR" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={temprature} className='icnn1' />
                        <input type='number' placeholder='Temp(°F)' name='vmValueTemperature' id='vmValueTemperature' onChange={handleOnChange} />
                        <small id="errTemp" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    {/* <div className='vitals-cnt-in'>
                        <img src={height1} className='icnn1' />
                        <input type='number' placeholder='Ht (cm)' name='height' id='height' onChange={handleOnChange} />
                    </div> */}
                    {/* <div className='vitals-cnt-in'>
                        <img src={weight1} className='icnn1' />
                        <input type='number' placeholder='Wt (kg)' name='weight' id='weight'  onChange={handleOnChange} />
                    </div> */}
                    <div className='vitals-cnt-in'>
                        <img src={rbs} className='icnn1' />
                        <input type='number' placeholder='RBS (mg/dl)' name='vmValueRbs' id='vmValueRbs' onChange={handleOnChange} />
                        <small id="errRBS" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>



                    {/* <div className='vitals-cnt-in'>
                        <img src={heart2} className='icnn1' />
                        <input type='text' placeholder='Iabp Ratio' style={{ width: "60px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={plat} className='icnn1' />
                        <input type='text' placeholder='P(Plat)' style={{ width: "40px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={liver} className='icnn1' />
                        <input type='text' placeholder='Trigger' style={{ width: "50px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={temprature} className='icnn1' />
                        <input type='text' placeholder='Peripheral Temp.' style={{ width: "95px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={fungus} className='icnn1' />
                        <input type='text' placeholder='Fungus' style={{ width: "50px" }} />
                    </div> */}


                </div>
                <small id="errVital" className="form-text text-danger" style={{ display: 'none' }}></small>


                {/* <div className='inner-content'>
                    <div className='d-flex flex-wrap   gap-3 p-2 boxcontainer justify-content-end'>
                        <div className="mb-2 justify-content-end">
                            <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                            <div className='diet-btn vital-btn '>

                               
                                {showUnderProcess === 1 ? <TosterUnderProcess /> : <>
                                    {
                                        showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} /> :
                                            <button type="button" className="btn btn-save btn-save-fill btn-sm " name='vitalTime' onClick={saveData} ><img src={save} className='icnn' /> {t("Save")}</button>

                                    }
                                </>}

                                <button type="button" className="btn btn-save btn-sm btnbluehover" onClick={handleClear}><img src={clear} className='icnn' /> {t("Clear")}</button>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>




        </div>

    )
}
