function ValidationTransferPharamcyToGodown ( godownId = '', pharmacyId = '') {
  
    if(godownId !== ''  && pharmacyId !== '')
    {
        return [true, ""]
    }
    else if (godownId === '') {
        return [false, "Please Enter Godown"]
    }

    else if (pharmacyId === '') {
        return [false, "Please Enter Pharmacy"]
    }

     
    }
    export default ValidationTransferPharamcyToGodown;

