let InsertPatientVitalRange = async(valResponse)=>{
    let url = window.AppbaseUrl + '/api/PatientVitalRange/InsertPatientVitalRange';
      
      let head ={'Content-Type':'application/json-patch+json','accept':'*/',};
      let data={};
      let response= await fetch(url,{
          method:'POST',
          headers:head,
          body:JSON.stringify(valResponse)
      }).then(res =>res.json()).then(data);
      return response;
  }
  export default InsertPatientVitalRange;