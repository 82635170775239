

let GetBillingActivity = async(uhid) => {
  
    let URL = window.AppbaseUrl+`/api/BillingActivity/GetBillingActivity?uhId=${uhid}&clientId=${window.clientId}`; 
      const head = {'content-type':'application/json','accept':'*/*',}
      let data = {};
      let response = await fetch(URL,{
        method: "GET",
        header: head,
      }).then(res => res.json()).then(data);
      return response;
    }
    export default  GetBillingActivity;
  