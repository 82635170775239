import React, { useEffect, useState } from 'react'
import GetPatientDetailsforSarvodaya from '../API/GetPatientDetailsforSarvodaya';
import l from '@linways/table-to-excel';
import PrintPageHeader from '../../../Component/PrintPageHeader';
import PatientLabel from '../../../Component/PatientLabel';

export default function ReceiptProcedureWithoutSedation() {
    const [sedationList, setSedationList] = useState({});
    const [prescriptionList, setPrescriptionList] = useState([]);
    const [prePostProcedureList, setPrePostProcedureList] = useState([]);
    const [questionnaireList, setQuestionnaireList] = useState([0,1,2,3,4,5,6,7,8]);
    const [patientData, setPatientData] = useState();
    const [allergyJsonList, setAllergyJsonList] = useState();
    const [diagnosisList, setDiagnosisList] = useState();

    console.log("sedationList---", sedationList);
    console.log("prescriptionList---", prescriptionList);
    console.log("prePostProcedureList---", prePostProcedureList);
    console.log("questionaireList---", questionnaireList);
    console.log("patientData---", patientData);
    console.log("allergyJsonList---", allergyJsonList);
    console.log("diagnosisList---", diagnosisList);
    console.log("patientData---", patientData);

    const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    let [clientData, setClientData] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientdata)

    //Get Data From Session
    const getSedationFormData = () => {
        const sedationData = JSON.parse(window.sessionStorage.getItem('sedationList')).sedationList || {};
        console.log('aaaaaaa', sedationData)

        if (sedationData && sedationData.questionaire) {
            const questionnaireAnswers = sedationData.questionaire.answers ? JSON.parse(sedationData.questionaire.answers) : [];
            setQuestionnaireList(questionnaireAnswers);
        }
        if (sedationData.prePostProcedure) {
            setPrePostProcedureList(sedationData.prePostProcedure);
        }
        if (sedationData.prescription) {
            setPrescriptionList(sedationData.prescription);
        }

        if (sedationData.allergies) {
            const allergies = sedationData.allergies[0].allergiesJson ? JSON.parse(sedationData.allergies[0].allergiesJson) : [];
            setAllergyJsonList(allergies);
        }
        if (sedationData.diagnosis) {
            setDiagnosisList(sedationData.diagnosis);
        }
        setSedationList(sedationData);
    };

    //Get Patient Details
    const getPatientData = async () => {
        let data = await GetPatientDetailsforSarvodaya(uhID)
        if (data.status === 1) {
            setPatientData(data.responseValue[0])
        }
    }

    let handlepritnt = () => {
        document.title = 'Procedure Without Sedation';
        window.print("");
        window.close();

    }
    // Function to format time (HH:mm AM/PM) from the datetime string
    const formatTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        return `${formattedHours}:${formattedMinutes} ${ampm}`;
    }

    const procedureDate = new Date(sedationList.procedureDate);
    const formattedProcedureDate = `${procedureDate.getDate().toString().padStart(2, '0')}-${(procedureDate.getMonth() + 1).toString().padStart(2, '0')}-${procedureDate.getFullYear()}`;
    useEffect(() => {
        getSedationFormData();
        getPatientData();
        setTimeout(() => {
            handlepritnt();
        }, 1200)
    }, []);

    return (
        <>
            <div className="main-table" style={{ padding: '5px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <table className='microtble pt-4 mt-4'>
                    {/* ....header.. */}
                    <tr>
                        <td>
                            <table>
                                <tr>
                                    <td colSpan={8}>
                                        <PrintPageHeader />
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td style={{ verticalAlign: 'top', textAlign: 'center' }}>
                            <div className='micro-heading mt-1 mb-1' >
                                PROCEDURE WITHOUT SEDATION
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td className='pt-0 mb-2 ps-0 pe-0'>
                            <table className='table pt-0 mb-0 pb-0' style={{ border: '1px solid black' }}>
                                <tr>
                                    <td style={{ borderRight: '1px solid', paddingBottom: '10px' }}>
                                        <table className="table-p10" style={{ width: '100%' }}>
                                            <tr>
                                                <td style={{ width: '134px' }}><strong>Date of Admission:</strong></td>
                                                <td style={{ borderBottom: '1px dashed black' }}>
                                                    {patientData && patientData.admitDate !== "0000-00-00"
                                                        ? new Date(patientData.admitDate.split('/').reverse().join('-')).toLocaleDateString('en-IN', {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: 'numeric'
                                                        })
                                                        : ''}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ whiteSpace: 'nowrap' }}><strong>Time of Assessment:</strong></td>
                                                <td style={{ borderBottom: '1px dashed black' }}>
                                                    {formatTime(sedationList && sedationList.assesmentTime)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Ward/Bed:</strong></td>
                                                <td style={{ borderBottom: '1px dashed black' }}>
                                                    {patientData && patientData.wardName}/{patientData && patientData.bedName}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td><strong>Diagnosis:</strong></td>
                                                <td style={{ borderBottom: '1px dashed black' }}>
                                                    {diagnosisList && diagnosisList.map((diagnosis, index) => (
                                                        <span key={index}>
                                                            {diagnosis.details}{index !== diagnosisList.length - 1 ? ', ' : ''}
                                                        </span>
                                                    ))}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td><strong>Procedure:</strong></td>
                                                <td style={{ borderBottom: '1px dashed black' }}>
                                                    {sedationList && sedationList.procedureName}
                                                </td>
                                            </tr>
                                        </table>
                                    </td>

                                    <td style={{ verticalAlign: "top", width:'40%' }}>
                                        <div className='mt-0 pt-0 mb-0 pb-0 d-flex'>
                                            <PatientLabel />
                                        </div>
                                    </td>
                                </tr>



                            </table>
                        </td>
                    </tr>
                </table>

                <table className='nursetabl mt-1'>
                    <tr>
                        <td colSpan={8}>
                            <strong> Part A(to be filled by Nurses)</strong>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4} className='pt-0 mb-0 mt-0' >
                            <div className=" pt-1 mb-0 mt-0">
                                <strong>Date of procedure:&nbsp;</strong>
                                <span>
                                    {formattedProcedureDate}
                                </span>
                            </div>
                        </td>
                        <td colSpan={4} >
                            <div className='wht-flx'>
                                <div ><strong>Height:&nbsp;</strong> <span>{patientData && patientData.height} cm </span> </div>
                                <div><strong>Weight:</strong> &nbsp;<span>{patientData && patientData.weight} cm</span> </div>
                            </div>
                        </td>

                    </tr>
                    <tr >
                        <td colSpan={4} className='pt-0 mb-0 mt-0'>
                            <div className=" pt-1 mb-0 mt-0">
                                <strong>Pre procedure diagnosis:&nbsp;</strong>
                                {prePostProcedureList && prePostProcedureList.map((proc, ind) => {
                                    return (
                                        <>
                                            {proc.preProcedureDiagnosis && (
                                                <span>{proc.preProcedureDiagnosis}</span>
                                            )}
                                            {proc.preProcedureDiagnosis && ind < prePostProcedureList.length - 1 && (
                                                <span>,&nbsp;</span>
                                            )}
                                        </>
                                    )
                                })}
                            </div>
                        </td>
                        <td rowSpan={4} colSpan={4} style={{ verticalAlign: 'top' }}>
                            <strong> Allergies (if any):&nbsp;</strong><br />
                            {allergyJsonList && allergyJsonList.map((allergy, index) => (
                                <span key={index}>
                                    <strong> {allergy.parameterName}</strong>: &nbsp;{allergy.ParameterValue}, &nbsp;
                                    <strong> Remark:</strong> {allergy.remark},<br />
                                </span>
                            ))}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4} className='pt-0 mb-0 mt-0'>
                            <div className=" pt-1 mb-0 mt-0">
                                <strong>Post procedure diagnosis:&nbsp;</strong>
                                {prePostProcedureList && prePostProcedureList.map((proc, ind) => {
                                    return (
                                        <>
                                            {proc.postProcedureDiagnosis && (
                                                <span>{proc.postProcedureDiagnosis}</span>
                                            )}
                                            {proc.postProcedureDiagnosis && ind < prePostProcedureList.length - 1 && (
                                                <span>,&nbsp;</span>
                                            )}
                                        </>
                                    )
                                })}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4} className='pt-0 mb-0 mt-0' >
                            <div className=" pt-1 mb-0 mt-0" >
                                <strong>Procedure done:</strong> <span>
                                    {sedationList.isProcedureDone === "null" || sedationList.isProcedureDone === 0 || sedationList.isProcedureDone === "0" || sedationList.isProcedureDone === null ? "No" : "Yes"}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4} className='pt-0 mb-0 mt-0'>
                            <div className=" pt-1 mb-0 mt-0">
                                <strong>  Indications for procedure:</strong> <span>
                                    {sedationList.procedureIndication}
                                </span>
                            </div>
                        </td>
                    </tr>




                </table>


                <table className='ptents-tbl tbldata2_ tdcntr_ mt-1'>
                    <tr>
                        <td className='p-0' style={{ border: 'none' }}>
                            <table>
                                <tr>
                                    <td colSpan={4}>
                                        <div className="" style={{ textAlign: 'center' }}>
                                            <strong>  TIME OUT CHECKLIST</strong>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=''>
                                        <div className="" >
                                            <strong> The following have been verified</strong>
                                        </div>
                                    </td>
                                    <td className=''>
                                        <div className="">
                                            <strong>  YES</strong>
                                        </div>
                                    </td>
                                    <td className=''>
                                        <div className="">
                                            <strong> NO:</strong>
                                        </div>
                                    </td>
                                    <td className=''>
                                        <div className=" ">
                                            <strong> NA:</strong>
                                        </div>
                                    </td>
                                </tr>

                                {questionnaireList.map((quest, ind) => {
                                    return (
                                        <tr>
                                            <td className=''>
                                                <div className="" style={{minHeight:'28px'}}>
                                                    {quest.questionName}
                                                </div>
                                            </td>
                                            <td colSpan={0} className=''>
                                                {quest.optionText === "Yes" ? "Yes" : ""}
                                            </td>
                                            <td colSpan={0} className=''>
                                                {quest.optionText === "No" ? "No" : ""}
                                            </td>
                                            <td colSpan={0} className=''>
                                                {quest.optionText === "NA" ? "NA" : ""}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </table>
                        </td>
                        <td className='p-0' style={{ border: 'none', verticalAlign: 'top' }}>
                            <table className='nursetabl'>
                                <tr>
                                    <td className='borderLeftNone' style={{ padding: '5px 6px' }}><strong>  Time-Out (Time):</strong> <span> {formatTime(sedationList && sedationList.assesmentTime)} </span></td>
                                </tr>
                                <tr>
                                    <td className='borderLeftNone'>
                                        <strong>Signature of Doctor</strong>
                                        <div className='signatureHeight'></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='borderLeftNone'>
                                        <strong>Signature of Anesthetist (if present)</strong>
                                        <div className='signatureHeight'></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='borderLeftNone'>
                                        <strong>Signature of Technician</strong>
                                        <div className='signatureHeight'></div>
                                    </td>
                                </tr>
                            </table>

                        </td>
                    </tr>

                </table>

                <table>

                    <tr>
                        <td colSpan={8} style={{ textAlign: 'center' }}>
                            <strong>INTRAPROCEDURAL MEDICATION RECORD</strong>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={8} className='pe-0 ps-0 mt-0 mb-0 pt-0 pb-0'>
                            <table className="ptents-tbl tbldata2 tdcntr">
                                <thead>
                                    <tr>
                                        <th rowSpan={2} >DATE</th>
                                        <th rowSpan={2}>TIME</th>
                                        <th rowSpan={2}>DRUG</th>
                                        <th rowSpan={2}>ROUTE</th>
                                        <th colSpan={3}>DOCTOR</th>
                                        <th colSpan={2}>ADMINISTERED</th>
                                    </tr>
                                    <tr>
                                        <th>SIGN</th>
                                        <th>REG. NO.</th>
                                        <th>SIGN</th>
                                        <th>EMP. NO.</th>
                                        <th>Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {prescriptionList.map((prescription, index) => {
                                        if (prescription.drugId === 0) {
                                            return null;
                                        }

                                        const createdDate = new Date(prescription.createdDate);
                                        const formattedDate = `${createdDate.getDate()}-${createdDate.getMonth() + 1}-${createdDate.getFullYear()}`;
                                        const formattedTime = `${createdDate.getHours()}:${createdDate.getMinutes().toString().padStart(2, '0')}`;

                                        return (
                                            <tr key={index}>
                                                <td style={{whiteSpace:'nowrap'}}>{formattedDate}</td>
                                                <td>{formattedTime}</td>
                                                <td>{prescription.drugName}</td>
                                                <td>{prescription.dosageForm}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </table>


            </div>
        </>
    )
}
