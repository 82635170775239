import React, { useState } from 'react'
import { useEffect } from 'react';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import Toster from '../../../Component/Toster';
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Heading from '../../../Component/Heading';
import BoxContainer from '../../../Component/BoxContainer';
import TableContainer from '../../../Component/TableContainer';
import SuccessToster from '../../../Component/SuccessToster';
import AlertToster from '../../../Component/AlertToster';
import Loader from '../../../Component/Loader';
import { t } from 'i18next';
import Search from '../../../Code/Serach';
import Select from 'react-select';
import GetWorkFlowMaster from '../../Api/WorkFlow/WorkFlowMaster/GetWorkFlowMaster';
import DropdownWithSearch from '../../../Component/DropdownWithSearch';
import GetWorkflowLevelSetting from '../../Api/WorkFlow/WorkflowLevelSetting/GetWorkflowLevelSetting';
import PostWorkflowLevelSetting from '../../Api/WorkFlow/WorkflowLevelSetting/PostWorkflowLevelSetting';
import PutWorkflowLevelSetting from '../../Api/WorkFlow/WorkflowLevelSetting/PutWorkflowLevelSetting';
import DeleteWorkflowLevelSetting from '../../Api/WorkFlow/WorkflowLevelSetting/DeleteWorkflowLevelSetting';
import GetDesignationMaster from '../../../SuperAdmin/Api/UtilityMaster/DesignationMaster/GetDesignationMaster';
import GetUserListByClientId from '../../Api/UserService/GetUserListByClientId';

export default function WorkflowLevelSetting() {
    let [workFlowList, setWorkFlowList] = useState("")
    let [getDesignation, setDesignation] = useState("")
    let [getTheUser, setTheUser] = useState("")
    let [levelSettingList, setLevelSettingList] = useState("")
    let [levelSettingListMain, setLevelSettingListMain] = useState("")
    let [designationList, setDesignationList] = useState([])
    let [userList, setUserList] = useState([])
    let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [showToster, setShowToster] = useState(0);
    let [tosterMessage, setTosterMessage] = useState("");
    let [tosterValue, setTosterValue] = useState(0);
    let [showLoder, setShowLoder] = useState(0);
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [updateBool, setUpdateBool] = useState(0);
    let [rowId, setRowId] = useState(0);
    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    let [showSearchBoxItem, setShowSearchBoxItem] = useState(-1);

    let [clearDropdown, setClearDropdown] = useState(0)
    let [editWorkFlow, setEditWorkFlow] = useState("")
    const userID = JSON.parse(window.sessionStorage.getItem("LoginData")).userId;
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    let data = {
        rowID: 1,
        designationId: '',
        designationName: '',
        userId: '',
        userName: ''
    }
    const [workFlowUserRow, setWorkFlowUserRow] = useState([{ rowID: 1 }]);
    // let [workFlowUserRow, setWorkFlowUserRow] = useState([data]);


    // const handleAddRow = (lastRowID) => {

    //     setWorkFlowUserRow([...workFlowUserRow, { ...data, rowID: lastRowID + 1 }]);
    //     console.log("workFlowUserRow", workFlowUserRow)      
    // };

    const handleAddRow = (lastRowID) => {
        const lastRow = workFlowUserRow[workFlowUserRow.length - 1];
        if (!lastRow.designationId || !lastRow.userId) {
            document.getElementById('errDesignation' + lastRow.rowID).style.display = !lastRow.designationId ? 'block' : 'none';
            document.getElementById('errUser' + lastRow.rowID).style.display = !lastRow.userId ? 'block' : 'none';
            return;
        }
        setWorkFlowUserRow([...workFlowUserRow, { ...data, rowID: lastRowID + 1 }]);
        console.log("workFlowUserRow", workFlowUserRow);
    };


    const handleSelectChange = (selectedOption, name, rowID) => {
        setWorkFlowUserRow((prevRows) =>
            prevRows.map((row) =>
                row.rowID === rowID
                    ? {
                        ...row,
                        [name]: selectedOption ? selectedOption.value : '',
                        [`${name === 'designationId' ? 'designationName' : 'userName'}`]: selectedOption ? selectedOption.label : '',
                    }
                    : row
            )
        );
        if (selectedOption) {
            if (name === 'designationId') {
                document.getElementById('errDesignation' + rowID).style.display = 'none';
            } else if (name === 'userId') {
                document.getElementById('errUser' + rowID).style.display = 'none';
            }
        }
    };



    const handleDeleteRow = (index) => {
        if (workFlowUserRow.length === 1) {
            setWorkFlowUserRow([{ ...data, rowID: 1 }]);
        } else {
            const newRows = [...workFlowUserRow];
            newRows.splice(index, 1);
            setWorkFlowUserRow(newRows);
        }
    };  
    



    let [sendForm, setSendForm] = useState({
        workflowID: 0,
        level: "",
        escalationTime: "",
        workFlowUserJson: "",
        entryBy: userID,
        "clientId": clientID
    })

    //Handle Change
    let handleChange = (e) => {
        clearValidationErrMessage();
        const { name, value, checked } = e.target;
        setEditWorkFlow("");
        setDesignation("");
        setTheUser("");
        setSendForm((prevData) => ({
            ...prevData,
            [name]: value,
            entryBy: userID,
        }));
    };

    //Get Data
    const getdata = async () => {
        const response = await GetWorkflowLevelSetting(clientID);
        if (response.status === 1) {
            setLevelSettingList(response.responseValue);
            setLevelSettingListMain(response.responseValue)
            setShowLoder(0)
        }
    }
    //Get workflow
    const getWorkFlow = async () => {
        const response = await GetWorkFlowMaster(clientID);
        if (response.status === 1) {
            setWorkFlowList(response.responseValue);
            setShowLoder(0)
        }
    }
    //Get designation
    const getdesignation = async () => {
        const response = await GetDesignationMaster();
        if (response.status === 1) {
            setDesignationList(response.responseValue.map(val => ({
                value: val.id,
                label: val.designationName
            })));
            setShowLoder(0)
        }
    }
    //Get designation
    const getUser = async () => {
        const response = await GetUserListByClientId(clientID);

        if (response.status === 1) {
            setUserList(response.responseValue.map(val => ({
                value: val.id,
                label: val.name
            })));
            setShowLoder(0)
        }
    }


    //handle search
    let handleSearch = (e) => {
        let resp = Search(levelSettingListMain, e.target.value)
        if (e.target !== "") {
            if (resp.length !== 0) {
                setLevelSettingList(resp)
            }
            else {
                setLevelSettingList([])
            }
        }
        else {
            setLevelSettingList(levelSettingListMain)
        }
    }




    //Handle Save
    const handlerSave = async () => {

        if (sendForm.workflowID === '' || sendForm.workflowID === null || sendForm.workflowID === undefined || sendForm.workflowID === 0) {
            document.getElementById('errworkFlow').innerHTML = "Work flow is required";
            document.getElementById('errworkFlow').style.display = "block";
        }
        else if (sendForm.level === "" || sendForm.level === null || sendForm.level === undefined) {
            document.getElementById('errLevel').innerHTML = "Level is required";
            document.getElementById('errLevel').style.display = "block";
        }

        else if (sendForm.escalationTime === "" || sendForm.escalationTime === null || sendForm.escalationTime === undefined || sendForm.escalationTime === 0) {
            document.getElementById('errEscalation').innerHTML = "Escalation is required";
            document.getElementById('errEscalation').style.display = "block";
        }

        else {
            let isValid = true;
            workFlowUserRow.forEach(row => {
                if (!row.designationId || !row.userId) {
                    isValid = false;
                    if (!row.designationId) {
                        document.getElementById('errDesignation' + row.rowID).style.display = 'block';
                    }
                    if (!row.userId) {
                        document.getElementById('errUser' + row.rowID).style.display = 'block';
                    }
                }
            });

            if (!isValid) {
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage("Please fill all required fields in the rows.");
                setTimeout(() => {
                    setShowToster(0);
                }, 1500);
                return;
            }
            setShowUnderProcess(1);

            const obj = {
                "workflowID": sendForm.workflowID,
                "level": sendForm.level,
                "escalationTime": sendForm.escalationTime,
                "workFlowUserJson": JSON.stringify(workFlowUserRow),
                "entryBy": userID,
                "clientId": clientID
            }
            console.log("obj", obj)
            // return;
            const response = await PostWorkflowLevelSetting(obj);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Data saved successfully.");
                setTimeout(() => {
                    setShowToster(0);
                    handleClear();
                    getdata();
                }, 1500)
            }
            else {
                setShowUnderProcess(0);
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTimeout(() => {
                    setShowToster(0);
                }, 1500)
            }
        }
    }

    //Handle Button Change    

    let handleUpdate = async (list) => {
        setUpdateBool(1);
        clearValidationErrMessage();
        setSendForm(sendForm => ({
            ...sendForm,
            id: list.id,
            workflowID: list.workflowID,
            level: list.level,
            escalationTime: list.escalationTime,
        }));
        setEditWorkFlow(list.workFlow);

        if (list.jsonWorkFlowUserData && list.jsonWorkFlowUserData !== "null") {
            const parsedData = JSON.parse(list.jsonWorkFlowUserData).map((val, index) => ({
                rowID: index + 1,
                designationId: val.DesignationId,
                designationName: val.designationName,
                userId: val.UserId,
                userName: val.userName
            }));
            console.log("parsedData", parsedData);
            setWorkFlowUserRow(parsedData);
        } else {
            setWorkFlowUserRow([data]);
        }
    };



    //Handle Update
    const handlerUpdate = async () => {
        if (sendForm.workflowID === '' || sendForm.workflowID === null || sendForm.workflowID === undefined || sendForm.workflowID === 0) {
            document.getElementById('errWorkFlow').innerHTML = "Work flow is required";
            document.getElementById('errWorkFlow').style.display = "block";
        }
        else if (sendForm.level === "" || sendForm.level === null || sendForm.level === undefined) {
            document.getElementById('errLevel').innerHTML = "Level is required";
            document.getElementById('errLevel').style.display = "block";
        }
        else if (sendForm.escalationTime === "" || sendForm.escalationTime === null || sendForm.escalationTime === undefined || sendForm.escalationTime === 0) {
            document.getElementById('errEscalation').innerHTML = "Escalation is required";
            document.getElementById('errEscalation').style.display = "block";
        }
        else {
            let isValid = true;
            workFlowUserRow.forEach(row => {
                if (!row.designationId || !row.userId) {
                    isValid = false;
                    if (!row.designationId) {
                        document.getElementById('errDesignation' + row.rowID).style.display = 'block';
                    }
                    if (!row.userId) {
                        document.getElementById('errUser' + row.rowID).style.display = 'block';
                    }
                }
            });

            if (!isValid) {
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage("Please fill all required fields in the rows.");
                setTimeout(() => {
                    setShowToster(0);
                }, 1500);
                return;
            }
            setShowUnderProcess(1);
            const objUpdate = {
                "id": sendForm.id,
                "workflowID": sendForm.workflowID,
                "level": sendForm.level,
                "escalationTime": sendForm.escalationTime,
                "workFlowUserJson": JSON.stringify(workFlowUserRow),
                "entryBy": userID,
                "clientId": clientID
            }

            const response = await PutWorkflowLevelSetting(objUpdate);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Updated Successfully.");
                setTimeout(() => {
                    setShowToster(0);
                    handleClear();
                    getdata();

                }, 1500)
            }
            else {
                setShowUnderProcess(0);
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTimeout(() => {
                    setShowToster(0);
                }, 1500)
            }
        }
    }

    //Handle Delete
    const handleDelete = async () => {
        setShowLoder(1);
        let obj = {
            id: rowId
        }
        const response = await DeleteWorkflowLevelSetting(obj);
        if (response.status === 1) {
            setShowLoder(0)
            setisShowToaster(1);
            setShowSuccessMsg("Deleted Successfully.")
            setTimeout(() => {
                setisShowToaster(0);
                getdata();
            }, 1500)
            handleClear();
        }
        else {
            setShowLoder(0);
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }

    //Clear Error Message
    const clearValidationErrMessage = () => {
        document.getElementById('errworkFlow').style.display = "none";
        document.getElementById('errLevel').style.display = "none";
        document.getElementById('errEscalation').style.display = "none";
    }

    //Handle Clear
    const handleClear = (value) => {
        setClearDropdown(value)
        clearValidationErrMessage();
        setUpdateBool(0);
        setEditWorkFlow(0)
        setDesignation(0);
        setTheUser(0);
        setSendForm({
            "clientId": clientID,
            entryBy: userID,
            workflowID: 0,
            level: "",
            escalationTime: "",
        })
        workFlowUserRow.forEach(row => {
            document.getElementById('designationId' + row.rowID).value = 0;
            document.getElementById('userId' + row.rowID).value = "";
            document.getElementById('errDesignation' + row.rowID).style.display = "none";
            document.getElementById('errUser' + row.rowID).style.display = "none";
        });
        setWorkFlowUserRow([{ rowID: 1, },]);

    }

    useEffect(() => {
        getdata();
        getWorkFlow();
        getUser();
        getdesignation();
    }, []);

    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div div className="med-box">
                                <Heading text="Work Flow Level Setting" />
                                <div className="inner-content">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <label htmlFor="workFlow" className="form-label">{t("Work Flow")} <span className="starMandatory">*</span></label>
                                            {workFlowList && <DropdownWithSearch defaulNname="Select work flow" name="workflowID" list={workFlowList} valueName="id" displayName="workFlow" editdata={editWorkFlow} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                            <small id="errworkFlow" className="invalid-feedback" style={{ display: 'none' }}></small>
                                        </div>
                                        <div className="col-sm-4">
                                            <label htmlFor="workFlow" className="form-label">{t("Level")} <span className="starMandatory">*</span></label>
                                            <input type="number" className="form-control form-control-sm" id="level" name='level' onChange={handleChange} value={sendForm.level} placeholder={t("Enter no. of levels.")} />
                                            <small id="errLevel" className="invalid-feedback" style={{ display: 'none' }}></small>
                                        </div>
                                        <div className="col-sm-4">
                                            <label htmlFor="workFlow" className="form-label">{t("Escalation Time")} <span className="starMandatory">*</span></label>
                                            <input type="number" className="form-control form-control-sm" id="escalationTime" name='escalationTime' onChange={handleChange} value={sendForm.escalationTime} placeholder={t("In minutes")} />
                                            <small id="errEscalation" className="invalid-feedback" style={{ display: 'none' }}></small>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className='quotationrows'>
                                            <div className='supplrcontent mt-2 p-2'>
                                                {workFlowUserRow && workFlowUserRow.map((val, index) => {
                                                    return (
                                                        <div className='addnCharge inner-content_ mt-2 mb-2' key={val.rowID}>
                                                            <div className='addnCharge-in'>
                                                                <span>
                                                                    <label htmlFor="workFlow" className="form-label">
                                                                        {t("Designation")} <span className="starMandatory"></span>
                                                                    </label>
                                                                </span>
                                                                <span style={{ minWidth: '250px' }}>
                                                                    <Select value={designationList.find(option => option.value === val.designationId) || null}
                                                                        options={designationList}
                                                                        className="create-select"
                                                                        id={`designationId${val.rowID}`}
                                                                        placeholder={t("Select designation")}
                                                                        name={`designationId${val.rowID}`}
                                                                        isSearchable={true}
                                                                        isClearable={index === workFlowUserRow.length - 1}
                                                                        onChange={(selectedOption) => handleSelectChange(selectedOption, 'designationId', val.rowID)}
                                                                        style={{ zIndex: '4' }}
                                                                    />
                                                                    <small id={`errDesignation${val.rowID}`} className="invalid-feedback" style={{ display: 'none' }}>Please select designation.</small>
                                                                </span>
                                                            </div>
                                                            <div className='addnCharge-in'>
                                                                <span>
                                                                    <label htmlFor="workFlow" className="form-label">
                                                                        {t("User")} <span className="starMandatory"></span>
                                                                    </label>
                                                                </span>
                                                                <span style={{ minWidth: '250px' }}>
                                                                    <Select
                                                                        value={userList.find(option => option.value === val.userId) || null}
                                                                        options={userList}
                                                                        className="create-select"
                                                                        id={`userId${val.rowID}`}
                                                                        placeholder={t("Select user")}
                                                                        name={`userId${val.rowID}`}
                                                                        isSearchable={true}
                                                                        isClearable={index === workFlowUserRow.length - 1}
                                                                        onChange={(selectedOption) => handleSelectChange(selectedOption, 'userId', val.rowID)}
                                                                        style={{ zIndex: '4' }}
                                                                    />
                                                                    <small id={`errUser${val.rowID}`} className="invalid-feedback" style={{ display: 'none' }}>Please select user.</small>
                                                                </span>
                                                            </div>
                                                            <div className='addnCharge-in'>
                                                                <div className='action-button cmlBtn' style={{ alignItems: 'baseline', marginTop: '4px' }}>
                                                                    <span>                                                                        
                                                                        <div>
                                                                            {index === workFlowUserRow.length - 1 ? (
                                                                                <>
                                                                                    <i className="bi bi-trash3" title='Remove row' onClick={() => { handleDeleteRow(index) }}></i>&nbsp;
                                                                                    <i className="bi bi-plus" onClick={() => { handleAddRow(workFlowUserRow.length > 0 ? workFlowUserRow[workFlowUserRow.length - 1].rowID : 0) }} title='Add new row'></i>
                                                                                </>
                                                                            ) : (
                                                                                <i className="bi bi-trash3" title='Remove row' onClick={() => { handleDeleteRow(index) }}></i>
                                                                            )}
                                                                        </div>

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-2 relative">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                        <div className='col-md-4'>
                                            {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                                <>
                                                    {showToster === 1 ?
                                                        <Toster value={tosterValue} message={tosterMessage} />

                                                        : <div>
                                                            {updateBool === 0 ?
                                                                <>
                                                                    <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handlerSave}><img src={saveButtonIcon} className='icnn' alt='' />Save</button>
                                                                    <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt='' />Clear</button>
                                                                </>
                                                                :
                                                                <>
                                                                    <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={handlerUpdate}>Update</button>
                                                                    <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}>Cancel</button>
                                                                </>
                                                            }
                                                        </div>}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-2">
                            <div className='handlser'>
                                <Heading text="Work Flow Level Setting" />
                                <div style={{ position: 'relative' }}>
                                    <input type="text" className='form-control form-control-sm' placeholder={t("Search")} onChange={handleSearch} />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
                            <div className="med-table-section" style={{ "height": "75vh" }}>
                                <TableContainer>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ width: "5%" }}>#</th>
                                            <th>Work Flow</th>
                                            <th>Level</th>
                                            <th>Designation</th>
                                            <th>User</th>
                                            <th>Escalation Time (In minutes)</th>
                                            <th style={{ width: "10%" }} className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {levelSettingList && levelSettingList.length > 0 ? (
                                            levelSettingList.map((list, ind) => (
                                                <tr key={list.id}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td>{list.workFlow}</td>
                                                    <td>{list.level}</td>
                                                    <td>
                                                        {list.jsonWorkFlowUserData && list.jsonWorkFlowUserData !== "null" && JSON.parse(list.jsonWorkFlowUserData).map((val, i, arr) => (
                                                            <span className='addnldt' key={i}>
                                                                {val.designationName}{i < arr.length - 1 && ','}<br />
                                                            </span>
                                                        ))}
                                                    </td>
                                                    <td>

                                                        {list.jsonWorkFlowUserData && list.jsonWorkFlowUserData !== "null" && JSON.parse(list.jsonWorkFlowUserData).map((val, i, arr) => (
                                                            <span className='addnldt' key={i}>
                                                                {val.userName}{i < arr.length - 1 && ','}<br />
                                                            </span>
                                                        ))}
                                                    </td>
                                                    <td>{list.escalationTime}</td>
                                                    <td>
                                                        <div className="action-button">
                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom">
                                                                <img src={editBtnIcon} className="" alt="" onClick={() => { handleUpdate(list); }} />
                                                            </div>
                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal">
                                                                <img src={deleteBtnIcon} className="" alt="" onClick={() => { setRowId(list.id); }} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7" className="text-center">
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>


                                </TableContainer>
                                {/* -----------------------Start Delete Modal Popup-------------------   */}

                                {/*  <!-- Modal -->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">

                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'>Delete?</div>
                                                <div className='popDeleteContent'>Are you sure want to delete?</div>
                                            </div>
                                            <div className="modal-footer1 text-center">

                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={handleDelete} data-bs-dismiss="modal">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

                            </div>
                        </div>
                    </div>
                </div>
                {
                    showLoder === 1 ? <Loader val={showLoder} /> : ""
                }
                {/* Toaster */}
                {
                    isShowToaster === 1 ?
                        <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                }

                {
                    showAlertToster === 1 ?
                        <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                }
            </section >
        </>
    )
}
