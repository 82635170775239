let SaveDoctorSchedule = async (obj)=>{
    let url= window.AppbaseUrl+'/api/DoctorSchedule/InsertDoctorSchedule';
    let head={'Content-Type':'application/json','accept':'*/*',}
    let response ="";
    await fetch(url,{
        method:'POST',
        headers:head,
        body:JSON.stringify(obj)
    }).then((res)=> res.json()).then(data=>{response=data}).catch(error=>{response=error});
    return response;
}
export default SaveDoctorSchedule;