import React from 'react'
import { useState, useEffect } from 'react'
import imgPrint from '../../assets/images/icons/imgPrint.svg'
import Loder from '../../Component/Loader'
import commenticon from '../../assets/images/icons/commenticon.png';
import Send from '../../assets/images/icons/send.png';
import Ellipse from '../../assets/images/icons/Ellipse.png';
import Cross from '../../assets/images/icons/Cross.png';
import GetAllChallan from '../API/ChallanForm/GetAllChallan';
import GetAllLPGDevices from '../API/LpgGasDevices/GetAllLPGDevices';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import { useNavigate } from "react-router-dom";




export default function LpgGasDevices() {
 
  const navigate = useNavigate();


  const {t} = useTranslation();
  document.body.dir = i18n.dir();

  const [ChallanReportChart, setChallanReportChart] = useState([])
  const [showLoder, setShowLoder] = useState(0);
  

  const ListData = async()=>{
    let data = await GetAllLPGDevices()
    if(data.status === 1){
        setChallanReportChart(data.responseValue)
        console.log("data" ,data.responseValue)
    }
  }


  useEffect(() => {
    ListData()
  }, [])




  return (
    <section className="main-content pt-3 mt-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mb-2">
            <div className="med-box">
              <div className="title mx-2">LPG Gas Devices</div>
            </div>
          </div>

          <div className="col-12">
            <div className="med-box">
              <div className="title mx-2">Gas Devices List</div>
              <div className="med-table-section mt-4" style={{ height: '788px' }}>
                <table className="med-table border striped">
                  <thead>
                    <tr className='table-primary' style={{ color: 'black' }}>
                      <th className="text-center" style={{ "width": "5%" }}>{t("#")}</th>
                      <th>Device ID </th>
                      <th>Leak Status</th>
                      <th>Leak Status Date</th>
                      <th>Location</th>
                
                    </tr>
                  </thead>
                  <tbody>
                    {ChallanReportChart && ChallanReportChart.map((data, index) => {

                      return (
                        <tr>
                          <td className="text-center">{index + 1}</td>
                          <td>{data.deviceId}</td>
                          <td>{data.leakStatus}</td>
                          <td>{data.leakStatusDate}</td>
                          <td>{data.locationName}</td>
                     
   


                          <td>
                        
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="col-12 mt-4">

      </div>


      {/* -----------------------Start Delete Modal Popup-------------------    */}


      {/* -----------------------End Delete Modal Popup---------------------  */}
      {
        showLoder === 1 ? <Loder val={showLoder} /> : ""
      }


    </section>


  )
}
