import React, { useEffect, useState } from "react";
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Toster from '../../../Component/Toster';
import Loder from '../../../Component/Loader';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from "../../../assets/images/icons/clear.svg";
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import updateIcon from '../../../assets/images/icons/updateIcon.svg';
// import editBtnIcon from '../../assets/images/icons/edit.svg';
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import Heading from "../../../Component/Heading";
import BoxContainer from "../../../Component/BoxContainer";
import TableContainer from "../../../Component/TableContainer";




export default function TrainingTopicMaster() {

    const { t } = useTranslation();
    const [showUnderProcess, setShowUnderProcess] = useState(0);
    const [showToster, setShowToster] = useState(0);
    const [tosterMessage, setTosterMessage] = useState("");
    const [tosterValue, setTosterValue] = useState(0);
    const [showLoder, setShowLoder] = useState(0);
    const [rowID, setRowID] = useState(0);
    const [isUpdateBtnShow, setIsUpdateBtnShow] = useState(false);
    const [IsDisable, setIsDisable] = useState(false)
    const [SelectedTopic, setSelectedTopic] = useState(null)
    const [TopicList,setTopicList] = useState(null)
    const [description,setDescription] = useState("")
    const [SelectPriorities,setSelectPriorities] = useState(null)
    const [PrioritiesList,setPrioritiesList] = useState(null)
    const [SelectType,setSelectType] = useState(null)
    const [TypeList,setTypeList] = useState(null)
    const [isClearable,] = useState(true);
    const [isSearchable,] = useState(true);
    const [editRowIndex, setEditRowIndex] = useState(null);
    const [trainingMasterList,setTrainingMasterList] = useState()
    let clientId = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
    let [userID, setUserID] = useState(JSON.parse(sessionStorage.getItem("LoginData")).userId);
    let userId = JSON.parse(sessionStorage.getItem("LoginData")).userId;



    const handleOnChange = async()=>{

    }

    const getData = ()=>{

    }

    const handleSelectChange = ()=>{

    }

    const handleSelectChangeState = ()=>{

    }

    const handleOnSave = ()=>{

    }

    const handleClear = ()=>{

    }

    const handleUpdate = ()=>{

    }

    const handleCancel =()=>{

    }

    const edit = ()=>{

    }

    const deleteRow=()=>{

    }

    useEffect(()=>{
        getData();
    },[])


  return (
    <section className="main-content mt-5 pt-3">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="med-box">
            {/* <div className="title">{t("Company Master")}</div> */}
            <Heading text={t("Training Topic Master")} />
            <BoxContainer>
            <div className="inner-content">
              <div className='row'>

                {/* <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-2">
                  <label htmlFor="companyName" className="form-label">{t("Company Name")}<span className="starMandatory">*</span></label>
                  <input type="text" className="form-control form-control-sm" id ='companyName' name='companyName' value={companyName} placeholder={t("Enter Company Name")} onChange={handleOnChange} />
                  <small id="errcompanyName" className="form-text text-danger" style={{ display: 'none' }}></small>
                </div> */}

                
                {/* <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-2">
                  <label htmlFor="companylogo" className="form-label">{t("Company Logo")}<span className="starMandatory">*</span></label>
                  <input type="text" className="form-control form-control-sm" id='companylogo' name='companylogo' value={companylogo} placeholder={t("Enter_Company_Logo")} onChange={handleOnChange} />
                  <small id="errcompanylogo" className="form-text text-danger" style={{ display: 'none' }}></small>
                </div> */}

                {/* <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-2">
                  <label htmlFor="companycontact" className="form-label">{t("Company Contact")}<span className="starMandatory">*</span></label>
                  <input type="text" className="form-control form-control-sm" id='companycontact' name='companycontact' value={companycontact} placeholder={t("Enter Company Contact")} onChange={handleOnChange} />
                  <small id="errcompanycontact" className="form-text text-danger" style={{ display: 'none' }}></small>
                </div> */}

                {/* <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-2">
                  <label htmlFor="companyaddress" className="form-label">{t("Company Address")}<span className="starMandatory">*</span></label>
                  <input type="text" className="form-control form-control-sm" id='companyaddress' name='companyaddress' value={companyaddress} placeholder={t("Enter Company Address")} onChange={handleOnChange} />
                  <small id="errcompanyaddress" className="form-text text-danger" style={{ display: 'none' }}></small>
                </div> */}

                {/* <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-2">
                  <label htmlFor="companyemail" className="form-label">{t("Company Email")}<span className="starMandatory">*</span></label>
                  <input type="text" className="form-control form-control-sm" id='companyemail' name='companyemail' value={companyemail} placeholder={t("Enter Company Email")} onChange={handleOnChange} />
                  <small id="errcompanyemail" className="form-text text-danger" style={{ display: 'none' }}></small>
                </div> */}
  {/* "id": 1,
  "topicId": 2,
  "topicDescription": "demotest",
  "prioritiesId": 2,
  "typeId": 2,
  "wardId": 2,
  "softwareId": 2,
  "frequencyId": 2,
  "groupId": 2,
  "userId": 99,
  "status": true,
  "clientId": 176 */}

                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-2">
                      <label htmlFor="ddltopicmaster" className="form-label ">{t("Topic")}<span className="starMandatory">*</span></label>
                      <Select value={SelectedTopic} options={TopicList} className=" create-select" id="serviceType" placeholder={t("Select Topic")} isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, setSelectedTopic)} />
                      <small id="errSelectedCountry" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>

                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-2">
                     <label htmlFor="description" className="form-label">{t("Topic Description")}<span className="starMandatory">*</span></label>
                     <input type="text" className="form-control form-control-sm" id='description' name='description' value={description} placeholder={t("Enter Description")} onChange={handleOnChange} />
                     <small id="errdescription" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>
                    
                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-2">
                      <label htmlFor="ddltopicmaster" className="form-label ">{t("Priorities")}<span className="starMandatory">*</span></label>
                      <Select value={SelectPriorities}  isDisabled={IsDisable} options={PrioritiesList} className=" create-select" id="serviceType" placeholder={t("Select Priorities")} isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChangeState(selectedOption, setSelectPriorities)} />
                      <small id="errSelectedState" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>


                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-2">
                      <label htmlFor="ddltopicmaster" className="form-label ">{t("Type")}<span className="starMandatory">*</span></label>
                      <Select value={SelectType} isDisabled={IsDisable} options={TypeList} className=" create-select" id="serviceType" placeholder={t("Select Type")} isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, setSelectType)} />
                      <small id="errSelectedCity" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>

                   

                  
                <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3 relative">
                  <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>

                  {showUnderProcess === 1 ? <><TosterUnderProcess />  </> :
                    showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} />
                      :
                      <div>
                        {isUpdateBtnShow !== true ? <>
                          <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleOnSave}><img src={saveButtonIcon} className='icnn' alt="" />{t("Save")}</button>
                          <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}>
                            <img src={clearIcon} className='icnn' alt="" />
                          {t("Clear")}</button>
                        </> :
                          <>
                             <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1 mx-1" onClick={handleUpdate} ><img src={updateIcon} className='icnn' alt="" />Update</button>
                            <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleCancel}>{t("Cancel")}</button>
                          </>
                        }
                      </div>
                  }
                </div>
              </div>
            </div>
            </BoxContainer>
  
          </div>
        </div>


        {/* table is made using getAllItemMaster API and mapped the data   */}


        <div className="col-12 mt-2">
        <Heading text={t("Training Topic Master List")} />
          <div className="med-table-section" style={{ "height": "75vh" }}>
            <TableContainer>
            <thead>
                <tr>
                  <th className="text-center" style={{ "width": "5%" }}>{t("#")}</th>
                  <th>{t("Company Name")}</th>
                  <th>{t("Company Logo")}</th>
                  <th>{t("Company Contact")}</th>
                  <th>{t("Company Address")}</th>
                  <th>{t("Country")}</th>
                  <th>{t("State")}</th>
                  <th>{t("City")}</th>
                  <th>{t("Company Email")}</th>
                  <th>{t("Remark")}</th>
                 
                 

                  
                  <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {trainingMasterList && trainingMasterList.map((data, index) => {
                 const isEditing = index === editRowIndex;
                  return (
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td>{data.companyName}</td>
                      <td>{data.companyLogo}</td>
                      <td>{data.companyContact}</td>
                      <td>{data.companyAddress}</td>
                      <td>{data.companyCountryID}</td>
                      <td>{data.companyStateID}</td>
                      <td>{data.companyCityID}</td>
                      <td>{data.companyEmail}</td>
                      <td>{data.remark}</td>
                      <td>
                        <div className="action-button">
                          <div
                            data-bs-toggle="tooltip"
                            data-bs-title="Edit Row"
                            data-bs-placement="bottom"

                          >
                            <img src={editBtnIcon} className={isEditing ? 'edited-row' : ''} alt='' onClick={() => { edit(data, index) }} />
                          </div>
                          <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowID(data.id); }} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </TableContainer>
            {/* <table className="med-table border_ striped"> */}
            {/* </table> */}
          </div>
        </div>
      </div>
    </div>
    {/* -----------------------Start Delete Modal Popup-------------------    */}

    <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div className="modal-dialog modalDelete">
        <div className="modal-content">
          <div className="modal-body modelbdy text-center">
            <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
            <div className='popDeleteTitle mt-3'> {t("Delete?")}</div>
            <div className='popDeleteContent'>{t("Are_you_sure_you_want_to_delete?")}</div>
          </div>
          <div className="modal-footer1 text-center">

            <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
            <button type="button" className="btn-delete popBtnDelete" data-bs-dismiss="modal" onClick={deleteRow} >{t("Delete")}</button>
          </div>
        </div>
      </div>
    </div>
    {/* -----------------------End Delete Modal Popup---------------------  */}
    
  </section>
  )
}


