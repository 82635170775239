import React, { useEffect, useState } from 'react'
import TimeCalculate from '../../../Code/TimeCalculate';
import LineVitalGrap from '../../../Components/LineVitalGrap';
import { useSelector } from 'react-redux';

export default function AllfieldRRR(props) {
    let [rr, setRr] = useState("");
    let [rrId, setRrId] = useState("");
    let [rrTime, setRrTime] = useState("");
    let [rrColor, setRrColor] = useState("");
    let [uhId, setUhId] = useState("")

    // set grap vital data
    let [grapVitalData, setGrapVitalData] = useState({
        showGrap: 0,
        vitalIdSearchNew: '',
        patientName: '',
        UHID: '',
        userId: ''
    })
    let getData = () => {
        setRr("");
        setRrId("");
        setRrTime("");
        setRrColor("");

        props.val.VitalParametersList && props.val.VitalParametersList.map((vital, ind) => {
            if (vital.VitalID.toString() === "7") {
                setRr(Math.round(vital.VitalValue * 100) / 100)
                setRrColor(vital.VitalColor)
                if (props.handleColor !== undefined) {
                    props.handleColor(vital.VitalColor !== null ? vital.VitalColor : "white", props.columnId)
                }

                setRrId(vital.VitalID)
                setUhId(props.val.UhId)
                if (vital.VitalDateTime !== '') {
                    setRrTime(TimeCalculate(vital.VitalDateTime))
                }

            }
        })
    }


    let modelCloseFun = () => {
        setGrapVitalData({
            showGrap: 0,
            vitalIdSearchNew: '',
            UHID: '',
            userId: ''
        })
    }

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
           if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

    }
    useEffect(() => {
        getColumnsParameters()
        getData()
    }, [props.val])

    return (
        <>
            {/* <div className={`overflow-max vitalClass ${(rrColor !== "#008000" && rrColor !== "#94e094" && rrColor !== "#F5F8FF" && rrColor !== "" ? "blink" : "")} `} style={{ border: (rrColor !== "#008000" && rrColor !== "#94e094" && rrColor !== "#F5F8FF" && rrColor !== "" ? "2px solid " + rrColor : "") }}> */}
            <div key={props.key} className={`overflow-max vitalClass`} >

                {rr.length != 0 ? rr ? <span>
                    {
                        paramenterData && paramenterData.map((val, ind) => {
                            if (val.parameterId === 47) {
                                return (<>
                                    <span style={{ color: rrColor }}>{rr}</span><br />
                                </>)
                            }
                            else if (val.parameterId === 48) {

                                return (<>
                                    {
                                        rrTime.split("-")[1] === "D" || (rrTime.split("-")[0].split(":")[0] >= 2 && rrTime.split("-")[1] === "H") ? <span style={{ 'color': 'red', fontSize: "0.7rem" }} className="blink"><i className="bi bi-clock"></i> {rrTime.toLowerCase().replace("-", "")}</span>

                                            : (rrTime.split(":")[0] >= 1 && rrTime.split("-")[1] === "H") ? <span style={{ "color": 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {rrTime.toLowerCase().replace("-", "")}</span>

                                                : (rrTime.split("-")[0].split(":")[0] <= 1 && rrTime.split("-")[1] === "H") || (rrTime.split("-")[0] >= 10 && rrTime.split("-")[1] === "M") ? <span style={{ 'color': '#ffa500', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {rrTime.toLowerCase().replace("-", "")}</span> : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {rrTime.toLowerCase().replace("-", "")}</span>}
                                    <br />
                                </>)
                            }
                            else if (val.parameterId === 49 && props.from === undefined) {

                                return (<>
                                    <div className="" title='RR Graph' ><i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapVitalData({ showGrap: 1, vitalIdSearchNew: [rrId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "RR Graph", patientName: props.val.PntName }) }}></i></div>
                                </>)
                            }
                        })
                    }




                </span> : 'N/A' : "N/A"}
            </div>
            {grapVitalData.showGrap ? <LineVitalGrap grapVitalData={grapVitalData} modelCloseFun={modelCloseFun} /> : ""}

        </>
    )
}



export function AllfieldRRRG(props) {
    let [rr, setRr] = useState("");
    let [rrId, setRrId] = useState("");
    let [rrTime, setRrTime] = useState("");
    let [rrColor, setRrColor] = useState("");
    let [uhId, setUhId] = useState("")

    // set grap vital data
    let [grapVitalData, setGrapVitalData] = useState({
        showGrap: 0,
        vitalIdSearchNew: '',
        patientName: '',
        UHID: '',
        userId: ''
    })
    let getData = () => {
        setRr("");
        setRrId("");
        setRrTime("");
        setRrColor("");

        props.val.VitalParametersList && props.val.VitalParametersList.map((vital, ind) => {
            if (vital.VitalID.toString() === "7") {
                setRr(Math.round(vital.VitalValue * 100) / 100)
                setRrColor(vital.VitalColor)
                if (props.setColors !== undefined) {
                    props.setColors((prevArray) => ({ ...prevArray, "RR": vital.VitalColor }))
                }

                setRrId(vital.VitalID)
                setUhId(props.val.UhId)
                if (vital.VitalDateTime !== '') {
                    setRrTime(TimeCalculate(vital.VitalDateTime))
                }

            }
        })
    }


    let modelCloseFun = () => {
        setGrapVitalData({
            showGrap: 0,
            vitalIdSearchNew: '',
            UHID: '',
            userId: ''
        })
    }

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
           if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

    }
    useEffect(() => {
        getColumnsParameters()
        getData()
    }, [props.val])

    return (
        <>
            {/* <div className={`overflow-max vitalClass ${(rrColor !== "#008000" && rrColor !== "#94e094" && rrColor !== "#F5F8FF" && rrColor !== "" ? "blink" : "")} `} style={{ border: (rrColor !== "#008000" && rrColor !== "#94e094" && rrColor !== "#F5F8FF" && rrColor !== "" ? "2px solid " + rrColor : "") }}> */}
            <div key={props.key} >

                {rr.length != 0 ? rr ? <span>
                    {
                        paramenterData && paramenterData.map((val, ind) => {

                            if (val.parameterId === 49) {

                                return (<>
                                    <div className="" title='RR Graph' ><i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapVitalData({ showGrap: 1, vitalIdSearchNew: [rrId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "RR Graph", patientName: props.val.PntName }) }}></i></div>
                                </>)
                            }
                        })
                    }




                </span> : 'N/A' : "N/A"}
            </div>
            {grapVitalData.showGrap ? <LineVitalGrap grapVitalData={grapVitalData} modelCloseFun={modelCloseFun} /> : ""}

        </>
    )
}