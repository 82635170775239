
import React, { useEffect, useState } from 'react'
import i18n, { t } from "i18next";
import DropdownWithSearch from '../../../Component/DropdownWithSearch'
import Heading from '../../../Component/Heading';
import clearIcon from '../../../assets/images/icons/clear.svg';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import plussign from '../../../assets/images/dmsimg/plus_sign.png';
import downloadarrow from '../../../assets/images/dmsimg/downloadarrow.png'
import { wrap } from 'highcharts';

export default function AddSupplierQuotation() {

    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="med-box">
                                <div className='demandMain-inn p-2'>
                                    <div className="headingDemand text-wrap pe-4 ps-2" style={{ borderLeft: '2px solid #1d4999' }}>Add Supplier Quotation</div>
                                </div>
                                <div className="inner-content mt-2 me-0" >
                                    <div className='row p-2'>
                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                            <label htmlFor="" className="form-label">{t("Item Name")}<span className="starMandatory">*</span></label>
                                            <input type="text" className="form-control form-control-sm" id="" name="" placeholder="MOTHER BOARD H610" />
                                            <small id="" className="form-text text-danger" style={{ display: 'none' }}></small>
                                        </div>
                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3 " >
                                            <label htmlFor="" className="form-label">{t("Item Code")}<span className="starMandatory">*</span></label>
                                            <input type="text" className="form-control form-control-sm" id="" name="" placeholder="H610M S2H DDR4" />
                                            <small id="" className="form-text text-danger" style={{ display: 'none' }}></small>
                                        </div>
                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                            <label htmlFor="" className="form-label">{t("Brand")}<span className="starMandatory">*</span></label>
                                            <input type="text" className="form-control form-control-sm" id="" name="" placeholder="Gigabyte" />
                                            <small id="" className="form-text text-danger" style={{ display: 'none' }}></small>
                                        </div>
                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                            <label htmlFor="challanNo" className="form-label">{t("Unit")}<span className="starMandatory">*</span></label>
                                            <select name="" className='form-select form-select-sm' id="">
                                                <option value="">Nos.</option>
                                                <option value="">5.00</option>
                                                <option value="">10.00</option>
                                                <option value="">20.00</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className='supplrcontent p-2'>
                                        <div className="title ps-0 pt-0" style={{ background: 'none' }}>{t("Supplier Quotation Details")}</div>
                                        <div className='row'>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                                <label htmlFor="" className="form-label">{t("Select Supplier")}<span className="starMandatory">*</span></label>
                                                <input type="text" className="form-control form-control-sm" id="" name="" placeholder="Select Supplier" />
                                                <small id="" className="form-text text-danger" style={{ display: 'none' }}></small>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                                <label htmlFor="" className="form-label">{t("Quantity")}<span className="starMandatory">*</span></label>
                                                <input type="text" className="form-control form-control-sm" id="" name="" placeholder="Enter Quantity" />
                                                <small id="" className="form-text text-danger" style={{ display: 'none' }}></small>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3 " >
                                                <label htmlFor="" className="form-label">{t("Rate")}<span className="starMandatory">*</span></label>
                                                <input type="text" className="form-control form-control-sm" id="" name="" placeholder="Enter Rate" />
                                                <small id="" className="form-text text-danger" style={{ display: 'none' }}></small>
                                            </div>

                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                                <label htmlFor="" className="form-label">{t("Discount")}<span className="starMandatory">*</span></label>
                                                <div className="input-group input-group-sm">
                                                    <input type="number" className="form-control form-control-sm" placeholder="0" style={{ maxWidth: '50px' }} />
                                                    <span className="input-group-text frm-selectper p-0" >
                                                        <select className='form-select form-select-sm' >
                                                            <option value="discountTypePer">%</option>
                                                            <option value="discountTypeRupee">&#8377;</option>
                                                        </select>
                                                    </span>
                                                    <input type="number" className="form-control form-control-sm" style={{ whiteSpace: 'nowrap', minWidth: '80px' }} placeholder="00.00" />
                                                    <div className="invalid-feedback" style={{ display: 'none' }}></div>
                                                    <div className="invalid-feedback" style={{ display: 'none' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                                <label htmlFor="" className="form-label">{t("Rate After Discount")}<span className="starMandatory">*</span></label>
                                                <input type="text" className="form-control form-control-sm" id="" name="" placeholder="Rate After Discount" />
                                                <small id="" className="form-text text-danger" style={{ display: 'none' }}></small>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                                <label htmlFor="" className="form-label">{t("GST Included in Rate")}<span className="starMandatory">*</span></label>
                                                <div className="accordion-body">
                                                    <div className="d-flex pe-2">
                                                        <div className=''>
                                                            Yes<label className="form-label" for="flexSwitchCheckChecked"> <span className="starMandatory"></span></label>
                                                        </div>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" role="switch" id="isEquipment" name='isEquipment' /> No
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3 " >
                                                <label htmlFor="" className="form-label">{t("GST(%)")}<span className="starMandatory">*</span></label>
                                                <input type="text" className="form-control form-control-sm" id="" name="" placeholder="Enter GST %" />
                                                <small id="" className="form-text text-danger" style={{ display: 'none' }}></small>
                                            </div>
                                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3 " >
                                                <label htmlFor="" className="form-label">{t(" Final Amount")}<span className="starMandatory">*</span></label>
                                                <input type="text" className="form-control form-control-sm" id="" name="" placeholder=" Final Amount" />
                                                <small id="" className="form-text text-danger" style={{ display: 'none' }}></small>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3 d-flex flex-wrap gap-0" >
                                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-3" >
                                                    <label htmlFor="" className="form-label">{t("Warranty/Expiry")}<span className="starMandatory">*</span></label>
                                                    <div className='daymontsupr d-flex'> <input type="text" className="form-control form-control-sm" id="" name="" placeholder="Warranty/Expiry" style={{borderRight: '0' }} />
                                                        <select name="" className='form-select form-select-sm' id="" style={{  borderLeft: '0' }}>
                                                            <option value="">Months</option>
                                                            <option value="">Day</option>
                                                            <option value="">Year</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-3" >
                                                    <label htmlFor="" className="form-label">{t("Delivery Time")}<span className="starMandatory">*</span></label>
                                                    <div className='daymontsupr d-flex '>
                                                        <input type="text" className="form-control form-control-sm" id="" name="" placeholder="Enter Delivery Time" style={{borderRight: '0' }} />
                                                        <select name="" className='form-select form-select-sm' id="" style={{ borderLeft: '0' }}>
                                                            <option value="">Months</option>
                                                            <option value="">Day</option>
                                                            <option value="">Year</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-3">
                                                    <label htmlFor="" className="form-label">{t("Additional Charge")}<span className="starMandatory">*</span></label>
                                                    <select name="" className='form-select form-select-sm' id="" style={{color: '#929292'}}>
                                                        <option value="">Select Additional Charge</option>
                                                        <option value="">5.00</option>
                                                        <option value="">10.00</option>
                                                        <option value="">20.00</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3 pe-2 d-flex flex-wrap gap-0">
                                            <div className='row'>
                                                 <div className="col-xxl-6 col-xl-3 col-lg-3 col-md-3 mb-3">
                                                    <label htmlFor="" className="form-label">{t("Additional Charge")}<span className="starMandatory">*</span></label>
                                                    <input type="text" className="form-control form-control-sm" id="" name="" placeholder="Enter Amount" />
                                                </div>
                                                <div className="col-xxl-6 col-xl-3 col-lg-3 col-md-3 mb-3 " >
                                                    <label htmlFor="" className="form-label">{t("Freight")}<span className="starMandatory">*</span></label>
                                                    <input type="text" className="form-control form-control-sm" id="" name="" placeholder="Enter Freight" />
                                                </div>
                                            </div>
                                               
                                                <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 mb-3 ps-1 me-4 ms-2">
                                                    <label htmlFor="" className="form-label" style={{textwrap:'nowrap'}}>{t("Fixed Rate Quotation")}<span className="starMandatory">*</span></label>
                                                    <div className="accordion-body">
                                                        <div className="d-flex pe-2">
                                                            <div className=''>
                                                                Yes<label className="form-label" for="flexSwitchCheckChecked"> <span className="starMandatory"></span></label>
                                                            </div>
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input" type="checkbox" role="switch" id="" name='' /> No
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 mb-3 ps-2 pe-2" >
                                                    <label htmlFor="" className="form-label">{t("Quotation Valid Till")}<span className="starMandatory">*</span></label>
                                                    <input type="date" className="form-control form-control-sm" id="" name="" placeholder="Select Valid Date" />
                                                </div>
                                            </div>
                                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3 ps-0 pe-2">
                                                <div className='addbtnsuplr dflex justify-content-end'><button type='button'><img src={plussign} className='icnn' alt='' />Add more supplier quotation</button></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-1 relative">
                                    <div className="d-flex flex-wrap justify-content-end gap-3 p-2">
                                        <button type="button" className="btn btn-clear btn-sm me-1" ><img src={clearIcon} className='icnn' alt='' />{t("Clear")}</button>
                                        <button type="button" className="btn btn-save btn-save-fill btn-sm me-1" >{t("Submit Quotation")} <img src={downloadarrow} className='icnn' alt='' /> </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
        </>
    )
}
