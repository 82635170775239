import React, { useEffect, useRef, useState } from 'react';
import i18n, { t } from "i18next";
import vectorPrint from '../../../assets/images/icons/vectorPrint.png';
import NoDataFound from '../../../assets/images/icons/No data-rafiki.svg'
import eyeveiw from '../../../assets/images/dmsimg/eye.png'
import tblshorting from '../../../assets/images/dmsimg/shorting.png'
import GetDemandsForStore from '../../API/UserDemand/GET/GetDemandsForStore';
import { useNavigate } from 'react-router-dom';
import StoreDemandItemDetails from './StoreDemandItemDetails';
import arrowdd from '../../../assets/images/icons/arrowdd.svg'
import Search from '../../../Code/Serach';
export default function StoreAllDemands() {

    // // Get current date
    // const currentDate = new Date();

    // // Format date to YYYY-MM-DD
    // const formattedDate = currentDate.toISOString().split('T')[0];

    const currentDate = new Date();

    const clientID = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
    const navigate = useNavigate();

    const [allDemand, setallDemand] = useState(1)
    const [Pending, setPending] = useState(0)
    const [Approved, setApproved] = useState(0)
    const [fromDate, setFromDate] = useState(new Date(currentDate.setDate(currentDate.getDate() - 30)).toISOString().split('T')[0]);
    const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
    const [getDemandType, setDemandType] = useState(0);
    const [demandList, setDemandList] = useState([]);
    const [demandListMain, setDemandListMain] = useState([]);
    const [noDataStatus, setNoDataStatus] = useState(false);
    const [getAllotmentStatus, setAllotmentStatus] = useState('All');
    const [sendID, setSendID] = useState('')

    //Select filter
    const [selected, setSelected] = useState('Last 30 Days');
    const selectBoxListRef = useRef(null);
    const options = ['All', 'Today', 'Last 7 Days', 'Last 30 Days', 'One Year', 'Custom'];

    const handledemandTabChange = () => {
        setallDemand(1)
        setPending(0)
        setApproved(0)
        setAllotmentStatus('All')
        funGetAllApprovedDemands(fromDate, toDate, clientID, getDemandType, 'All')

    }
    const handlePendingTabChange = () => {
        setallDemand(0)
        setPending(1)
        setApproved(0)
        setAllotmentStatus('Pending')
        funGetAllApprovedDemands(fromDate, toDate, clientID, getDemandType, 'Pending')

    }
    const handleApprovedTabChange = () => {
        setallDemand(0)
        setPending(0)
        setApproved(1)
        setAllotmentStatus('Approved')
        funGetAllApprovedDemands(fromDate, toDate, clientID, getDemandType, 'Approved')
    }

    const handleFromDate = (event) => {

        setFromDate(event.target.value)
        funGetAllApprovedDemands(event.target.value, toDate, clientID, getDemandType, getAllotmentStatus);
    }
    const handleToDate = (event) => {


        if (event.target.value < fromDate) {
            document.getElementById('errToDate').innerHTML = 'To date cannot be smaller than from date';
            document.getElementById('errToDate').style.display = 'block'
            setToDate(event.target.value);
        }
        else {
            document.getElementById('errToDate').style.display = 'none';
            setToDate(event.target.value);
            funGetAllApprovedDemands(fromDate, event.target.value, clientID, getDemandType, getAllotmentStatus);
            ///funGetAllDemands();
        }
    }

    // To navigate ot Store All items details //
    const funToNavigatePage = (demandId) => {

        setSendID(demandId);
        window.sessionStorage.setItem('storeDemandId', demandId);
        window.sessionStorage.setItem('allotmentStatus', getAllotmentStatus);
        // navigate('/DemandItemDetails/')
        navigate('/storeDemandItemDetails/', { state: { demandID: demandId } });
    }

    //// function to get the list of demand on basis of its types and date////////////
    const funGetAllApprovedDemands = async (fromDate, toDate, clientID, getDemandType, getAllotmentStatus) => {

        const resDemand = await GetDemandsForStore(fromDate, toDate, clientID, getDemandType, getAllotmentStatus)
        if (resDemand.status === 1) {
            setDemandList(resDemand.responseValue); setDemandListMain(resDemand.responseValue); setNoDataStatus(false)
        }
        else {
            setDemandList([])
            setNoDataStatus(true)
        }
    }

    //select filter
    const calculateDateRange = (option) => {

        let fromDate, toDate;

        switch (option) {
            case 'All':
                fromDate = '1970-01-01'; // Start of Unix epoch
                toDate = new Date().toISOString().split('T')[0];
                break;
            case 'Today':
                fromDate = new Date().toISOString().split('T')[0];
                toDate = fromDate;
                break;
            // case 'Last 7 Days':
            //     fromDate = new Date(currentDate.setDate(currentDate.getDate() - 7)).toISOString().split('T')[0];
            //     toDate = new Date().toISOString().split('T')[0];
            //     break;
            case 'Last 7 Days':
                let sevenDaysAgo = new Date();
                sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
                fromDate = sevenDaysAgo.toISOString().split('T')[0];
                toDate = new Date().toISOString().split('T')[0];
                break;

            case 'Last 30 Days':
                let monthAgo = new Date();
                monthAgo.setDate(monthAgo.getDate() - 30);
                fromDate = monthAgo.toISOString().split('T')[0];
                toDate = new Date().toISOString().split('T')[0];
                // fromDate = new Date(currentDate.setDate(currentDate.getDate() - 30)).toISOString().split('T')[0];
                // toDate = new Date().toISOString().split('T')[0];
                break;
            case 'One Year':
                let yearAgo = new Date();
                yearAgo.setFullYear(yearAgo.getFullYear()-1);
                fromDate = yearAgo.toISOString().split('T')[0];
                toDate = new Date().toISOString().split('T')[0];
                // fromDate = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1)).toISOString().split('T')[0];
                // toDate = new Date().toISOString().split('T')[0];
                break;
            default:
                fromDate = '0';
                toDate = '0';
        }

        return { fromDate, toDate };
    };

    // to select options for filtering
    const handleOptionClick = (option) => {
        setSelected(option);
        selectBoxListRef.current.classList.remove('active');

        if (option !== 'Custom') {
            const { fromDate, toDate } = calculateDateRange(option);
            
            setFromDate(fromDate);
            setToDate(toDate);
            funGetAllApprovedDemands(fromDate, toDate, clientID, getDemandType, getAllotmentStatus);
        }
    };

    // to hide the dropdown of select filter when user clicks outside of it.
    const toggleDropdown = () => {
        selectBoxListRef.current.classList.toggle('active');
    };

    const handleClickOutside = (event) => {
        if (selectBoxListRef.current && !selectBoxListRef.current.contains(event.target)) {
            selectBoxListRef.current.classList.remove('active');
        }
    };

    /////////////// To search specific in list /////////////////////
    //handle search
    let handleSearch = (e) => {
        let resp = Search(demandListMain, e.target.value)
        if (e.target !== "") {
            if (resp.length !== 0) {
                setDemandList(resp)
            }
            else {
                setDemandList([])
            }
        }
        else {
            setDemandList(demandListMain)
        }
    }
    /////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        funGetAllApprovedDemands(fromDate, toDate, clientID, getDemandType, getAllotmentStatus);
        //select filter
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
        //select filter
    }, [fromDate, toDate, getDemandType])


    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="med-box">
                                <div className='row mt-2 mb-1 me-2 ms-2' >
                                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 mb-3">
                                        {/* <div className="headingDemand text-wrap" style={{ borderLeft: '2px solid #1d4999', paddingLeft: '10px' }}>Store Demand</div> */}
                                        <div className="titleHeading" style={{ borderLeft: '2px solid #1d4999', paddingLeft: '10px' }}>{t("Store Demand")}</div>

                                    </div>
                                    <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 mb-3">
                                        {/* <div className='demandMainn'>
                                            <div className='purchasedemand'>
                                                <div className='d-flex flex-wrap align-items-center'>
                                                    <span className='demandText'>From</span>
                                                    <span> <input type='date' className='form-control form-control-sm' value={fromDate} id='fromDateID' name='fromDateName' style={{ color: '#2A62C8', fontWeight: '500', fontSize: '14px', backgroundColor: '#F9FAFC', border: '0px' }} onChange={handleFromDate} /></span>
                                                </div>
                                            </div>
                                            <div className='purchasedemand'>
                                                <div className='d-flex flex-wrap align-items-center'>
                                                    <span className=''>To</span>
                                                    <span> <input type='date' className='form-control form-control-sm' value={toDate} id='toDateID' name='toDateName' style={{ color: '#2A62C8', fontWeight: '500', fontSize: '14px', backgroundColor: '#F9FAFC', border: '0px' }} onChange={handleToDate} /></span><br />                                                    
                                                </div>
                                            </div>                                            

                                        </div> */}
                                        <div className='demandMainn demandMainn1'>

                                            <div className="select-box">
                                                <div className="select-box__current" tabIndex="1" onClick={toggleDropdown}>
                                                    <div className="select-box__value"><p className="select-box__input-text">{selected}</p></div>
                                                    <img className="select-box__icon" src={arrowdd} alt="Arrow Icon" aria-hidden="true" />
                                                </div>
                                                <ul className="select-box__list" ref={selectBoxListRef}>
                                                    {options.map((option, index) => (<>

                                                        <li key={index}>
                                                            <label className="select-box__option" onClick={() => handleOptionClick(option)}>{option}</label>
                                                        </li></>
                                                    ))}
                                                </ul>
                                            </div>

                                            {selected === 'Custom' && (
                                                <div className='customrange-cn'>
                                                    <div className="customrange">
                                                        <span className='datetxt'>From:</span>
                                                        <input type="date" className="form-control" value={fromDate} onChange={handleFromDate} />
                                                    </div>
                                                    <div className="customrange">
                                                        <span className='datetxt'>To:</span>
                                                        <input type="date" className="form-control" value={toDate} onChange={handleToDate} />
                                                        <div id="errToDate" style={{ color: 'red', display: 'none' }}>To date cannot be smaller than from date</div>
                                                    </div>
                                                </div>
                                            )}

                                            {/* <DateFilter /> */}
                                        </div>

                                    </div>

                                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 mb-3">
                                        <form className="d-flex ser">
                                            <i className="fa fa-search"></i>
                                            <input type="search" className="form-control form-control-sm" placeholder="Search.." onChange={handleSearch} />
                                        </form>

                                    </div>
                                </div>
                                <div className='tabbtnmedonds '>
                                    <button type='button' className={`${allDemand === 1 ? 'active-tab-btn' : 'inactive-tab-btn'}`} onClick={handledemandTabChange}>All Demands</button>
                                    <button type='button' className={`${Pending === 1 ? 'active-tab-btn' : 'inactive-tab-btn'}`} onClick={handlePendingTabChange}>Pending</button>
                                    <button type='button' className={`${Approved === 1 ? 'active-tab-btn' : 'inactive-tab-btn'}`} onClick={handleApprovedTabChange}>Approved</button>
                                </div>

                                <div className="med-table-section med-table-section alldemandtbl pt-0" style={{ height: '84vh', padding: '0 5px', position: 'relative' }}>
                                    <table className='med-table border_ striped'>
                                        <thead>
                                            <tr style={{ background: '#EAF6FF', borderRadius: '10px' }}>
                                                <th className="text-center" style={{ "width": "4%", color: '#546788', fontWeight: '500', fontSize: '14px' }}>#</th>
                                                <th style={{ "width": "8%", color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Demand No.")} <img src={tblshorting} alt="" style={{ marginLeft: '5px' }} /></th>
                                                <th style={{ "width": "15%", color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Demand Person")} <img src={tblshorting} alt="" style={{ marginLeft: '5px' }} /></th>
                                                <th style={{ "width": "15%", color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Department")} <img src={tblshorting} alt="" style={{ marginLeft: '5px' }} /></th>
                                                <th style={{ "width": "12%", color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Contact")} <img src={tblshorting} alt="" style={{ marginLeft: '5px' }} /></th>
                                                <th style={{ "width": "6%", color: '#546788', fontWeight: '500', fontSize: '14px', whiteSpace: 'pre-wrap' }}>{t("Number of Item(s)")} <img src={tblshorting} alt="" style={{ marginLeft: '5px' }} /></th>
                                                <th style={{ "width": "12%", color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Demand Date")} <img src={tblshorting} alt="" style={{ marginLeft: '5px' }} /></th>

                                                <th style={{ "width": "12%", color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Priority")} <img src={tblshorting} alt="" style={{ marginLeft: '5px' }} /></th>
                                                <th className="text-center" style={{ "width": "6%", color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Action")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {noDataStatus ? (
                                                <tr>
                                                    <td colSpan="9" className="text-center">
                                                        <div className='imageNoDataFound'>
                                                            <img src={NoDataFound} alt="imageNoDataFound" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) :
                                                <>

                                                    {allDemand === 1 && demandList.map((list, ind) => {

                                                        return (
                                                            <tr key={ind + 1}>
                                                                <td className="text-center" style={{ fontWeight: '400', fontSize: '14px' }}>{ind + 1}</td>
                                                                <td><span style={{ fontWeight: '400', fontSize: '14px' }}>{list.demandNumber}</span></td>
                                                                <td><span className='' style={{ fontWeight: '400', fontSize: '14px' }}>{list.userName}</span><br />
                                                                    <span className='' style={{ fontWeight: '300', fontSize: '14px', color: '#929292' }}>{list.designationName}</span>
                                                                </td>
                                                                <td><span className='' style={{ fontWeight: '400', fontSize: '14px' }}>{list.deptName}</span></td>
                                                                <td><span className='' style={{ fontWeight: '400', fontSize: '14px' }}>{list.contactNumber ? list.contactNumber : '--NA--'}</span></td>
                                                                <td><span className='' style={{ fontWeight: '400', fontSize: '14px' }}>{list.numberOfItems}</span></td>
                                                                <td><span className='' style={{ fontWeight: '400', fontSize: '14px' }}>{list.demandDateTime.split(' ')[0]} | <span style={{ fontWeight: '500', fontSize: '14px' }}>{list.demandDateTime.split(' ')[1].split(':').slice(0, 2).join(':')}</span></span></td>
                                                                <td><span className={`demandpriorty ${list.demandType === 2 ? 'clrurgnt' : list.demandType === 3 ? 'clrveyurgnt' : ''}`} style={{ fontWeight: '500', fontSize: '14px' }}>{list.demandType === 1 ? 'Normal' : list.demandType === 2 ? 'Urgent' : 'Must'}</span></td>
                                                                <td className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><i className="fa fa-eye actionedit viewaction " onClick={() => funToNavigatePage(list.demandId)}></i><img src={vectorPrint} alt="" style={{ paddingLeft: '10px' }} /></td>
                                                            </tr>
                                                        )
                                                    })}


                                                    {Pending === 1 && demandList.map((list, ind) => {

                                                        return (
                                                            <tr key={ind + 1}>
                                                                <td className="text-center" style={{ fontWeight: '400', fontSize: '14px' }}>{ind + 1}</td>
                                                                <td><span style={{ fontWeight: '400', fontSize: '14px' }}>{list.demandNumber}</span></td>
                                                                <td><span className='' style={{ fontWeight: '400', fontSize: '14px' }}>{list.userName}</span><br />
                                                                    <span className='' style={{ fontWeight: '300', fontSize: '14px', color: '#929292' }}>{list.designationName}</span>
                                                                </td>
                                                                <td><span className='' style={{ fontWeight: '400', fontSize: '14px' }}>{list.deptName}</span></td>
                                                                <td><span className='' style={{ fontWeight: '400', fontSize: '14px' }}>{list.contactNumber}</span></td>
                                                                <td><span className='' style={{ fontWeight: '400', fontSize: '14px' }}>{list.numberOfItems}</span></td>
                                                                <td><span className='' style={{ fontWeight: '400', fontSize: '14px' }}>{list.demandDateTime.split(' ')[0]} | <span style={{ fontWeight: '500', fontSize: '14px' }}>{list.demandDateTime.split(' ')[1].split(':').slice(0, 2).join(':')}</span></span></td>
                                                                <td><span className={`demandpriorty ${list.demandType === 2 ? 'clrurgnt' : list.demandType === 3 ? 'clrveyurgnt' : ''}`} style={{ fontWeight: '500', fontSize: '14px' }}>{list.demandType === 1 ? 'Normal' : list.demandType === 2 ? 'Urgent' : 'Must'}</span></td>
                                                                <td className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><i className="fa fa-eye actionedit viewaction " onClick={() => funToNavigatePage(list.demandId)}></i><img src={vectorPrint} alt="" style={{ paddingLeft: '10px' }} /></td>
                                                            </tr>
                                                        )
                                                    })}


                                                    {Approved === 1 && demandList.map((list, ind) => {

                                                        return (
                                                            <tr key={ind + 1}>
                                                                <td className="text-center" style={{ fontWeight: '400', fontSize: '14px' }}>{ind + 1}</td>
                                                                <td><span style={{ fontWeight: '400', fontSize: '14px' }}>{list.demandNumber}</span></td>
                                                                <td><span className='' style={{ fontWeight: '400', fontSize: '14px' }}>{list.userName}</span><br />
                                                                    <span className='' style={{ fontWeight: '300', fontSize: '14px', color: '#929292' }}>{list.designationName}</span>
                                                                </td>
                                                                <td><span className='' style={{ fontWeight: '400', fontSize: '14px' }}>{list.deptName}</span></td>
                                                                <td><span className='' style={{ fontWeight: '400', fontSize: '14px' }}>{list.contactNumber}</span></td>
                                                                <td><span className='' style={{ fontWeight: '400', fontSize: '14px' }}>{list.numberOfItems}</span></td>
                                                                <td><span className='' style={{ fontWeight: '400', fontSize: '14px' }}>{list.demandDateTime.split(' ')[0]} | <span style={{ fontWeight: '500', fontSize: '14px' }}>{list.demandDateTime.split(' ')[1].split(':').slice(0, 2).join(':')}</span></span></td>
                                                                <td><span className={`demandpriorty ${list.demandType === 2 ? 'clrurgnt' : list.demandType === 3 ? 'clrveyurgnt' : ''}`} style={{ fontWeight: '500', fontSize: '14px' }}>{list.demandType === 1 ? 'Normal' : list.demandType === 2 ? 'Urgent' : 'Must'}</span></td>
                                                                <td className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><i className="fa fa-eye actionedit viewaction " onClick={() => funToNavigatePage(list.demandId)}></i><img src={vectorPrint} alt="" style={{ paddingLeft: '10px' }} /></td>
                                                            </tr>
                                                        )
                                                    })}


                                                </>}
                                        </tbody>
                                    </table>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </section>
            {sendID && <StoreDemandItemDetails />}
        </>
    )
}
