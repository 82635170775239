import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import '../../../../../../assets/css/CertificateCard.css';
import Vector from "../../../../../../assets/images/icons/Vector.svg";
import Contact from "../../../../../../assets/images/icons/Contact.svg";
import mainlogo from '../../../../../../assets/images/Navbar/blankLogo.svg';
export default function CaseSheetPrint() {

    let [bool, setBool] = useState(true);
    let [dateTime, setDateTime] = useState();
    let [printdata, setPrintData] = useState([]);
    // let [jsonPrintData, setJsonPrintData] = useState([]);
    let [billNo, setBillNo] = useState();
    let [uhid, setUhid] = useState();
    let [grossAmount, setGrossAmount] = useState();
    let [discount, setDiscount] = useState();
    let [netAmount, setNetAmount] = useState();
    let [billData, setBillData] = useState();
    let date = new Date()



    const caseSheet = JSON.parse(window.sessionStorage.getItem("PrintCaseSheet"));
    let [patientData, setPatientData] = useState(JSON.parse(window.sessionStorage.getItem("IPDpatientList"))[0]);
    let [clientData, setClientData] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientdata);
    let [userName, setUseName] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).name);

    useEffect(() => {
        setDateTime(date.toLocaleDateString() + " " + date.toLocaleTimeString());
        setTimeout(() => {
            handlepritnt();
        }, 1200)

    }, [])

    function stripHtml(html) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

    let handlepritnt = () => {
        document.title = 'Case Summary'; // Set the desired title here
        setBool(false)
        window.print("");
        window.close();

    }
    return (

        <>

             <div className='main-content pt-3 mt-5'>
                <div className="card-wrapper">
                    <div className="quater-border right-top"></div>
                    <div className="quater-border left-bottom"></div>
                    <div className='water-mark'>
                        <div className='clientText'>{clientData.clientName}</div>
                    </div>
                    <div className="dis-hed">
                        <div className="discharge-logo">
                            <div className="logo-main_">
                                {/* <img src={mainlogo} /> */}
                                {clientData.logoUrl == null ? <img src={mainlogo} alt='Brand Logo' title='Brand Logo' /> : <img src={clientData.logoUrl} alt='Brand Logo' title='Brand Logo' />}
                            </div>
                        </div>
                        <div className="address-section">
                            <div className='organizationName' style={{ color: 'black' }}>{clientData.clientName}</div>
                            <div className='organizationAddress' style={{ color: 'black', fontSize: '16px' }}>{clientData.address}</div>
                            {clientData.emailID == null ? '' : <div className='email' style={{ color: 'black', fontSize: '14px' }}>Email: {clientData.emailID}</div>}
                            {clientData.mobileNo == null ? '' : <div className='organizationContact' style={{ color: 'black', fontSize: '14px' }}>Phone: +{clientData.countryCode} {clientData.mobileNo}</div>}
                        </div>

                        <div className="address-section">
                            <div className='phone text-right'> {dateTime}</div>
                        </div>
                    </div>

                    {/* -----------------------Patient's Details------------------- */}

                    <div className="pat-dtls">
                        <div className="document1-title">Case Summary</div>
                    </div>
                    <div className="pat-dtls">
                        <table className="table-certificate cert-top1 border mb-2">
                            <tr>
                                <td> <b>UHID :</b>  <span className='value'>{patientData.uhId}</span></td>
                                <td> <b>Patient :</b>  <span className='value'>{patientData.patientName}</span></td>
                                <td><b>Gender :</b>  <span className='value'>{patientData.gender}</span></td>
                                <td><b>CrNo :</b> <span className='value'>{patientData.crNo}</span></td>
                            </tr>
                            <tr>
                                <td><b>IPNo :</b> <span className='value'>{patientData.ipNo}</span></td>
                                <td><b>Age :</b> <span className='value'> {patientData.age} {patientData.ageType}</span></td>
                                <td colSpan={2}> <b>Mobile No. :</b> <span className='value'>{patientData.mobileNo}</span></td>
                            </tr>
                        </table>
                       <div dangerouslySetInnerHTML={{ __html: caseSheet.details || '' }} className='mt-2'></div>
                    </div>
                    {/* <div className="d-flex justify-content-between" style={{ marginTop: "30px" }} >
                        <div className="text-center">
                            <div> <img src="http://172.16.61.31:7095/MediaFiles/tecnicianSign.png" alt="" style="width: 125px; margin-top: 2px;"/></div>
                            <div>
                                <b>Resident Sign</b>
                                <b>Signature of Patient/Relative</b>
                            </div>
                        </div>
                        <div className="text-center">
                            <div> <img src="http://172.16.61.31:7095/MediaFiles/doctorSign.png" alt="" style="width: 125px; margin-top: 2px;"/></div>
                            <div>
                                <b>Consultant Incharge Sign</b>
                                <b>Signature of Witness</b>
                            </div>
                        </div>
                    </div> */}
                   
                </div>

            </div>

        </>
    )
}
