import React, { useEffect, useState } from 'react'
import Heading from '../../../../../../Component/Heading';
import BoxContainer from '../../../../../../Component/BoxContainer';
import Supplements from '../../../../../../assets/images/PatientAnalyzingGraph/Supplements.svg'
import Vital from '../../../../../../assets/images/PatientAnalyzingGraph/Vital.svg'
import Nutrient from '../../../../../../assets/images/PatientAnalyzingGraph/Nutrient.svg'
import Investigation from '../../../../../../assets/images/PatientAnalyzingGraph/Investigation.svg'
import ECGData from '../../../../../../assets/images/PatientAnalyzingGraph/ECGData.svg'
import IntakeType from '../../../../../../assets/images/PatientAnalyzingGraph/IntakeType.svg'
import date from '../../../../../../assets/images/PatientAnalyzingGraph/date.svg'
import time from '../../../../../../assets/images/PatientAnalyzingGraph/time.svg'
import GetInvestigationList from '../API/GetInvestigationList';
import DropdownWithSearch from '../../../../../../Component/DropdownWithSearch';
import GetVitalList from '../API/GetVitalList';
import GetSupplimentList from '../API/GetSupplimentList';
import GetFoodList from '../API/GetFoodList';
import GetNutrientList from '../API/GetNutrientList';
import GetPatientAnalysingGraph from '../API/GetPatientAnalysingGraph';
import { HighchartsReact } from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import Highcharts from 'highcharts';
import { type } from '@testing-library/user-event/dist/type';
import { connect } from 'react-redux';
import NODataFound from '../../../../../../Component/NODataFound';
export default function PatientAnalyzingGraph() {
  let now = new Date();
  let hours = String(now.getHours()).padStart(2, '0');
  let minutes = String(now.getMinutes()).padStart(2, '0');
  let seconds = String(now.getSeconds()).padStart(2, '0');

  HighchartsExporting(Highcharts);
  let [investigationList, setInvestigationList] = useState();
  let [selectedInvestigationList, setSelectedInvestigationList] = useState([{ id: 188, testname: "Serum Creatinine" }, { id: 210, testname: "Blood Urea Nitrogen" }, { id: 741, testname: "Serum Bilirubin (Total)" }, { id: 742, testname: "S.G.P.T./ ALTV" }, { id: 743, testname: "S.G.O.T./ AST" }, { id: 744, testname: "Serum Alkaline Phosphatase" },
  { id: 493, testname: "Tri - ido Thyronine (T3)" }, { id: 494, testname: "Thyroxine (T4)" }, { id: 495, testname: "TSH3" }, { id: 188, testname: "Serum Creatinine" },

  ]);
  let [vitalList, setVitalList] = useState();
  let [selectedVitalList, setSelectedVitalList] = useState([{ id: 3, vitalName: "Pulse" }, { id: 4, vitalName: "BP_Sys" }, { id: 6, vitalName: "BP_Dias" }, { id: 56, vitalName: "SPO2" }, { id: 7, vitalName: "RR" }, { id: 74, vitalName: "HR" }, { id: 5, vitalName: "Temp." }]);
  // let [selectedVitalList, setSelectedVitalList] = useState([{ id: 4, vitalName: "BP_Sys" }]);
  let [supplimentList, setSupplimentList] = useState();
  let [selectedSupplimentList, setSelectedSupplimentList] = useState([]);
  let [foodList, setFoodList] = useState();
  let [selectedFoodList, setSelectedFoodList] = useState([]);
  let [nutrientList, setNutrientList] = useState();
  let [selectedNutrientList, setSelectedNutrientList] = useState([]);
  let [ecgList, setEcgList] = useState();
  let [selectedEcgList, setSelectedEcgList] = useState([]);
  let [toHour, setToHour] = useState('24');
  let [fromTime, setFromTime] = useState("08" + ":" + "00" + ":" + "00");
  let [intakeType, setIntakeType] = useState('');
  let [fromDate, setFromDate] = useState(new Date().toISOString().split("T")[0]);
  let [sendSelectedVitals, setSendSelectedVitals] = useState('');
  let UHID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid ? JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid : [];
  let [graph, setGraph] = useState();
  let [backColos, setBackColos] = useState([])
  let getInvestigationList = async () => {
    var response = await GetInvestigationList();
    // console.log('result',response)
    if (response.status === 1) {
      console.log('result', response)
      setInvestigationList(response.responseValue)
    }
  }
  let getVitalList = async () => {
    var response = await GetVitalList();
    // console.log('result',response)
    if (response.status === 1) {
      console.log('result vital', response)
      setVitalList(response.responseValue)
    }
  }
  let getSupplimentList = async () => {
    var response = await GetSupplimentList();
    // console.log('result',response)
    if (response.status === 1) {
      console.log('result Suppliment', response)
      setSupplimentList(response.responseValue)
    }
  }
  let getFoodList = async () => {
    var response = await GetFoodList();
    // console.log('result',response)
    if (response.status === 1) {
      console.log('result food', response)
      setFoodList(response.responseValue)
    }
  }
  let getNutrientList = async () => {
    var response = await GetNutrientList();
    // console.log('result',response)
    if (response.status === 1) {
      console.log('result Nutrient', response)
      setNutrientList(response.responseValue)
    }
  }
  let getEcgList = async () => {
    let ecgArr = [{ ecgName: 'I' }, { ecgName: 'II' }, { ecgName: 'III' }, { ecgName: 'AVR' }, { ecgName: 'AVL' }, { ecgName: 'AVF' }, { ecgName: 'V1' }, { ecgName: 'V2' }, { ecgName: 'V3' }, { ecgName: 'V4' }, { ecgName: 'V5' }, { ecgName: 'V6' },]
    setEcgList(ecgArr)

  }
  //Handle Start Here
  let handleChange = (e) => {
    // console.log('e', e.target.value);
    // document.getElementById('errInvestigation').style.display = "none";
    // document.getElementById('errVital').style.display = "none";
    // document.getElementById('errSupplement').style.display = "none";
    // document.getElementById('errNutrient').style.display = "none";
    // document.getElementById('errFoodSupplementDrug').style.display = "none";
    // document.getElementById('errECG').style.display = "none";
    //  setSelectedInvestigationList(e.target.value)
    if (e.target.name === "investigation") {
      var data = e.target.value;
      let arr = [...selectedInvestigationList];
      for (var i = 0; i < investigationList.length; i++) {
        if (investigationList[i].id === data) {
          console.log('id', investigationList[i].id);
          console.log('name', investigationList[i].testname);
          if (arr.length === 0) {
            arr.push({
              id: investigationList[i].id,
              testname: investigationList[i].testname
            });
          }
          else {
            var index = arr.findIndex((val) => val.id == investigationList[i].id);
            if (index != -1) {
              document.getElementById('errInvestigation').style.display = "block";
            }
            else {
              arr.push({
                id: investigationList[i].id,
                testname: investigationList[i].testname
              });
            }
          }
          console.log('arr', arr);
          setSelectedInvestigationList(arr)

          break;
        }
      }
    }
    if (e.target.name === "Vital") {
      var slctdVital = e.target.value;
      console.log('slctdVital', slctdVital);
      var arr = [...selectedVitalList];
      // var tempVitalList=[...selectedVitalList];
      for (var i = 0; i < vitalList.length; i++) {
        if (vitalList[i].id === slctdVital) {
          if (arr.length === 0) {
            arr.push({
              id: slctdVital,
              vitalName: vitalList[i].vitalName
            });
          }
          else {
            var index = arr.findIndex((val) => val.id === slctdVital);
            console.log('index', index);
            if (index !== -1) {
              document.getElementById('errVital').style.display = "block";
            }
            else {
              arr.push({
                id: slctdVital,
                vitalName: vitalList[i].vitalName
              });
            }
          }
          console.log('setSelectedVitalList', arr);
          setSelectedVitalList(arr);
        }
      }
    }
    if (e.target.name === "Supplement") {
      var slctdSupplement = e.target.value;
      console.log('slctdVital', slctdSupplement);
      var arr = [...selectedSupplimentList]
      for (var i = 0; i < supplimentList.length; i++) {
        if (supplimentList[i].supplimentId === slctdSupplement) {
          if (arr.length === 0) {
            arr.push({
              supplimentId: slctdSupplement,
              supplimentName: supplimentList[i].supplimentName
            });
          }
          else {
            var index = arr.findIndex((val) => val.supplimentId === slctdSupplement);
            console.log('index', index);
            if (index !== -1) {
              document.getElementById('errSupplement').style.display = "block";
            }
            else {
              arr.push({
                supplimentId: slctdSupplement,
                supplimentName: supplimentList[i].supplimentName
              });
            }
          }
          console.log('setSelectedSupp', arr)
          setSelectedSupplimentList(arr);
        }
      }
    }
    if (e.target.name === "Nutrient") {
      var slctdNutrient = e.target.value;
      console.log('slctdVital', slctdNutrient);
      var arr = [...selectedNutrientList]
      for (var i = 0; i < nutrientList.length; i++) {
        if (nutrientList[i].id === slctdNutrient) {
          if (arr.length === 0) {
            arr.push({
              id: slctdNutrient,
              nutrientName: nutrientList[i].nutrientName
            });
          }
          else {
            var index = arr.findIndex((val) => val.id === slctdNutrient);
            console.log('index', index);
            if (index !== -1) {
              document.getElementById('errNutrient').style.display = "block";
            }
            else {
              arr.push({
                id: slctdNutrient,
                nutrientName: nutrientList[i].nutrientName
              });
            }
          }
          console.log('setSelectedNutrient', arr)
          setSelectedNutrientList(arr);
        }
      }
    }
    if (e.target.name === "FoodSupplementDrug") {
      var slctdFoodSupplementDrug = e.target.value;
      console.log('slctdFoodSupplementDrug', slctdFoodSupplementDrug);
      var arr = [...selectedFoodList]
      for (var i = 0; i < foodList.length; i++) {
        if (foodList[i].id === slctdFoodSupplementDrug) {
          if (arr.length === 0) {
            arr.push({
              id: slctdFoodSupplementDrug,
              foodName: foodList[i].foodName
            });
          }
          else {
            var index = arr.findIndex((val) => val.id === slctdFoodSupplementDrug);
            console.log('index', index);
            if (index !== -1) {
              document.getElementById('errFoodSupplementDrug').style.display = "block";
            }
            else {
              arr.push({
                id: slctdFoodSupplementDrug,
                foodName: foodList[i].foodName
              });
            }
          }
          console.log('setSelectedNutrient', arr)
          setSelectedFoodList(arr);
        }
      }
    }
    if (e.target.name === "ECG") {
      var slctdECG = e.target.value;
      console.log('slctdFoodSupplementDrug', slctdECG);
      var arr = [...selectedEcgList]
      for (var i = 0; i < ecgList.length; i++) {
        if (ecgList[i].ecgName === slctdECG) {
          if (arr.length === 0) {
            arr.push({
              ecgName: slctdECG,
            });
          }
          else {
            var index = arr.findIndex((val) => val.ecgName === slctdECG);
            console.log('index', index);
            if (index !== -1) {
              document.getElementById('errECG').style.display = "block";
            }
            else {
              arr.push({
                ecgName: slctdECG,
              });
            }
          }
          console.log('setSelectedEcg', arr)
          setSelectedEcgList(arr);
        }
      }
    }
    if (e.target.name === "fromDate") {
      setFromDate(e.target.value);
    }
    if (e.target.name === "fromTime") {
      setFromTime(e.target.value);
    }
    if (e.target.name === "toHour") {
      setToHour(e.target.value);
    }



  }
  //Used To Remove Selected Vitals
  let removeSelectedVital = (index) => {
    var tempArr = [...selectedVitalList]
    console.log('index :', index);
    tempArr.splice(index, 1);
    setSelectedVitalList(tempArr)
  }
  //Used To Remove Selected Investigation
  let removeSelectedInvestigation = (index) => {
    var tempArr = [...selectedInvestigationList]
    console.log('index inves:', index);
    tempArr.splice(index, 1);
    setSelectedInvestigationList(tempArr)
  }
  //Used To Remove Selected Supplement
  let removeSelectedSupplement = (index) => {
    var tempArr = [...selectedSupplimentList]
    console.log('index inves:', index);
    tempArr.splice(index, 1);
    setSelectedSupplimentList(tempArr)
  }
  //Used To Remove Selected Nutrient
  let removeSelectedNutrient = (index) => {
    var tempArr = [...selectedNutrientList]
    console.log('index inves:', index);
    tempArr.splice(index, 1);
    setSelectedNutrientList(tempArr)
  }
  //Used To Remove Selected Food Supplement Drug
  let removeSelectedFoodSupplemntDrg = (index) => {
    var tempArr = [...selectedFoodList]
    console.log('index inves:', index);
    tempArr.splice(index, 1);
    setSelectedFoodList(tempArr)
  }
  //Used To Remove Selected Food Supplement Drug
  let removeSelectedEcg = (index) => {
    var tempArr = [...selectedEcgList]
    console.log('index inves:', index);
    tempArr.splice(index, 1);
    setSelectedEcgList(tempArr)
  }
  //Used To Search 
  let handleSearch = async () => {
    var vitalColName = "";
    var investigationColName = "";
    let fromTimeFormat = "";
    const ddlToHour = document.getElementById('ddlToHour').value;
    let hrEle = fromTime.split(':')[0];
    let minEle = fromTime.split(':')[1];
    if (hrEle >= 0 && hrEle <= 24 && minEle >= 0 && minEle <= 60) {
      let AMorPM = 'AM';
      if (hrEle >= 12) AMorPM = 'PM';
      hrEle = (hrEle % 12);
      fromTimeFormat = hrEle === 0 ? 12 + ':' + minEle + ' ' + AMorPM : hrEle + ':' + minEle + ' ' + AMorPM;
      console.log('res', fromTimeFormat);
    }

    for (var i = 0; i < selectedVitalList.length; i++) {
      vitalColName = vitalColName + selectedVitalList[i].id + ',';
    }
    for (var i = 0; i < selectedInvestigationList.length; i++) {
      investigationColName = investigationColName + selectedInvestigationList[i].id + ',';
    }
    // console.log('tempVitalList', vitalColName);
    // console.log('tempInves', investigationColName);
    // if (fromTimeFormat === "") {
    //   document.getElementById('errFromDate').style.display = "block";
    // }
    // else if (fromDate === "") {
    //   document.getElementById('errFromDate').style.display = "block";
    // }
    // else if (ddlToHour === "0") {
    //   document.getElementById('errToHour').style.display = "block";
    // }
    // else {
    var obj = {
      // UHID: "UHID00347",
      // UHID: "UHID00181",
      // date: fromDate,
      // timeFrom: fromTimeFormat,
      // toHour: ddlToHour,
      // vitalIdSearch: vitalColName,
      // subtestIDSearch: investigationColName
      UHID: JSON.parse(sessionStorage.getItem("IPDactivePatient")).Uhid,
      date: fromDate,
      timeFrom: fromTime,
      toHour: toHour,
      vitalIdSearch: vitalColName,
      subtestIDSearch: investigationColName
    }
    let response = await GetPatientAnalysingGraph(obj);
    // console.log('obj', obj);
    // console.log('response', response);
    if (response.status === 1) {
      let check = response.responseValues
      if (check.vitals.length !== 0 && check.investigations.length !== 0 && check.output.length !== 0) {
        graphData(response.responseValues);
      }
      else {

      }
    }
    // }
  }

  //Used To Make Data For Graph
  let graphData = (values) => {
    let arrInvestigation = [];
    let arrVitals = [];
    let investigation = "";
    let arrLabel = [];
    let seriesArr = [];
    let a = []
    let timearray =
      [

        "08:00 AM",
        "08:30 AM",
        "09:00 AM",
        "09:30 AM",
        "10:00 AM",
        "10:30 AM",
        "11:00 AM",
        "11:30 AM",
        "12:00 PM",
        "12:30 PM",
        "01:00 PM",
        "01:30 PM",
        "02:00 PM",
        "02:30 PM",
        "03:00 PM",
        "03:30 PM",
        "04:00 PM",
        "04:30 PM",
        "05:00 PM",
        "05:30 PM",
        "06:00 PM",
        "06:30 PM",
        "07:00 PM",
        "07:30 PM",
        "08:00 PM",
        "08:30 PM",
        "09:00 PM",
        "09:30 PM",
        "10:00 PM",
        "10:30 PM",
        "11:00 PM",
        "11:30 PM",
        "12:00 AM",
        "12:30 AM",
        "01:00 AM",
        "01:30 AM",
        "02:00 AM",
        "02:30 AM",
        "03:00 AM",
        "03:30 AM",
        "04:00 AM",
        "04:30 AM",
        "05:00 AM",
        "05:30 AM",
        "06:00 AM",
        "06:30 AM",
        "07:00 AM",
        "07:30 AM",


      ]



    if (values.output !== "") {
      let data = [...timearray]
      var output = values.output;

      let name = ""
      for (var s = 0; s < output.length; s++) {
        let findTime = data.findIndex(value => value === output[s].searchHour)
        if (findTime !== -1) {
          data[findTime] = {
            id: output[s].vmId,
            name: output[s].resultDate + "(" + output[s].searchHour + ")",
            y: Number(output[s].vmValue) > 0 ? Number(output[s].vmValue) : null
          }
        }
        name = output[s].remark
      }
      seriesArr.push({
        name: name,
        data: data,
        type: "column"
      });
    }

    if (values.intake !== "") {
      let data = [...timearray]
      var intake = values.intake;

      let name = ""
      for (var s = 0; s < intake.length; s++) {
        let findTime = data.findIndex(value => value === intake[s].searchHour)
        if (findTime !== -1) {
          data[findTime] = {
            id: intake[s].vmId,
            name: intake[s].newDate + "(" + intake[s].searchHour + ")",
            y: Number(intake[s].vmValue) > 0 ? Number(intake[s].vmValue) : null,
          }
        }
        name = intake[s].remark
      }
      console.log("data", data)
      seriesArr.push({
        name: name,
        data: data,
        type: "column"
      });
    }




    for (var i = 0; i < selectedInvestigationList.length; i++) {
      var data = [...timearray];
      if (values.investigations !== "") {
        var investigations = values.investigations;
        for (var s = 0; s < investigations.length; s++) {
          if (investigations[s].subTestID === selectedInvestigationList[i].id) {
            let findTime = data.findIndex(value => value === investigations[s].searchHour)
            if (findTime !== -1) {
              data[findTime] = {
                id: investigations[s].subTestID,
                name: investigations[s].date + "(" + investigations[s].searchHour + ")",
                y: Number(investigations[s].result) > 0 ? Number(investigations[s].result) : null
              }
            }

          }
        }
      }

      seriesArr.push({
        name: selectedInvestigationList[i].testname,
        data: data,
        type: "line"
      });
    }
    //Vitals


    // var seriesVital = [];
    let labelMain = []
    for (var j = 0; j < selectedVitalList.length; j++) {
      var data = [...timearray];

      // for (var k = 0; k < values.length; k++) {
      if (values.vitals !== "") {
        var vital = values.vitals;
        for (var s = 0; s < vital.length; s++) {
          if (vital[s].vmID === selectedVitalList[j].id) {
            let findTime = data.findIndex(value => value === vital[s].searchHour)
            if (findTime !== -1) {
              data[findTime] = {
                id: vital[s].vmID,
                name: vital[s].date + "(" + vital[s].searchHour + ")",
                y: Number(vital[s].vmValue) > 0 ? Number(vital[s].vmValue) : null
              }
            }

          }
        }
      }



      // }
      seriesArr.push({
        name: selectedVitalList[j].vitalName,
        data: data,
        type: "line"
      });
    }


    // let labelTime = timearray.map((val) => { if (val.includes("aM")) { return val.replace("aM", "") + " AM" } else { return val.replace("pM", "") + " PM" } })
    // labelMain.push(labelTime)




    for (var k = 0; k < values.length; k++) {
      arrLabel.push(values[k].valueDateTime);
    }
    console.log("arrLane;l", arrLabel)
    let backColor = []

    arrLabel.map((val, ind) => {
      if (val.split(" ")[1] >= "06:00:00" && val.split(" ")[1] <= "19:00:00") {

        let t = {
          id: 'test',
          from: ind, // Start of the plot band
          to: ind + 1, // End of the plot band
          color: '#252931',// Color value
          //  color: 'rgba(255, 0, 0, 0.2)',
          label: {
            //  text: 'Important Range',
            align: 'center',
            useHTML: true

          }
        }
        backColor.push(t)

      }
      if (val.split(" ")[1] > "19:00:00" && val.split(" ")[1] < "06:00:00") {

        let t = {
          id: 'test',
          from: ind, // Start of the plot band
          to: ind + 1, // End of the plot band
          color: '#4a4844',// Color value
          //  color: 'rgba(255, 0, 0, 0.2)',
          label: {
            //  text: 'Important Range',
            align: 'center',
            useHTML: true

          }
        }
        backColor.push(t)

      }
    })
    setBackColos([...backColor])
    console.log("<<<<<-----------backcolor--------->>>>> ", backColor)
    console.log('arrInvestigation', arrInvestigation)
    console.log('arrLabel', arrLabel);
    console.log('investigation', investigation);
    console.log('arrVitals', arrVitals);
    // console.log('seriesVital', seriesArr);
    // seriesArr.push({
    //   name: "Investigation",
    //   data: arrInvestigation
    // });

    console.log('seriesArr', seriesArr)
    // grapConfig(arrLabel, seriesArr,arrVitals)
    // grapConfig(arrLabel, seriesArr, seriesVital, backColor)


    grapConfig(timearray, seriesArr, backColor)
  }


  let grapConfig = (labelData, seriesData, backColor) => {

    console.log('backColor--->>>> : ', backColor);
    setGraph({
      chart: {
        displayErrors: true,
        // scrollablePlotArea: {
        //   minheight: 100,
        //   opacity: 0.6
        // },
        height: (6 / 16 * 100) + '%', // 16:9 ratio,
        // plotBackgroundImage: 'http://www.java2s.com/style/download.png',

        zoomType: "xy",
      },
      title: {
        text: 'Patient Analyzing Graph'
      },

      yAxis: {
        gridLineWidth: 0.4,
        gridLineColor: '#4F5659',
        title: {
          text: 'Values'
        }
      },

      xAxis: {

        gridLineWidth: 0.4,
        gridLineColor: '#4F5659',
        categories: labelData,
        type: "category",
        // min: 0,
        // max:50,
        plotBands: [backColor],
        labels: {
          rotation: -45,

          // step: 1 // Show all labels without skipping

        },
        breaks: [{
          from: 0,
          to: 0,
          breakSize: 1
        }],

        scrollbar: {
          enabled: true
        },

      },
      tooltip: {
        formatter: function () {

          return '<b>Name:</b> ' + this.series.name + '<br><b>Time:</b> ' + this.key + '<br> <b>Value:</b> ' + this.y;
        }

        //headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        //pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> of total<br/>'
      },


      exporting: {
        enabled: true
      },


      plotOptions: {
        series: {
          // pointWidth: 50,
          dataLabels: {
            align: 'top',
            enabled: true,
          },
          connectNulls: true
        },


      },
      series: [
        ...seriesData
      ],
      // series: [
      //   ...vitalSeriesData
      // ],
      credits: {
        enabled: false,
      }

    })
  }

  useEffect(() => {
    getInvestigationList();
    getVitalList();
    getSupplimentList();
    getFoodList();
    getNutrientList();
    getEcgList();
  }, []);
  return (
    <>
      <section className="main-content_ row">
        <div className="container-fluid">
          <div className="row">

            <div className="col-12">
              <div className='whitebg'>
                <div className="row">
                  <div className="col-md-8 col-sm-12">
                    <div className="fieldsett-in">
                      <div className="fieldsett">
                        <span className='fieldse'>Patient Analyzing Graph</span>

                        <div className='row px-1 py-3'>

                          {/* <div className="col-md-4 col-sm-12">
                            <div className="mb-2 me-2">
                              <img src={Supplements} className='icnn' />
                              <label htmlFor="Supplements" className="form-label">Supplements</label>
                              <span className='df1' style={{ cursor: 'pointer' }} onClick={() => { setSelectedSupplimentList([]) }}><i className='fa fa-minus'></i></span>
                              {supplimentList && <DropdownWithSearch defaulNname="Select Supplement" name="Supplement" list={supplimentList} valueName="supplimentId" displayName="supplimentName" getvalue={handleChange} editdata={0} clear={0} clearFun={""} id="ddlSuppliment" />}
                              <small id="errSupplement" className="form-text text-danger" style={{ display: 'none' }}>Supplement Already Selected</small>
                              <div className="addvalue narrt1">
                                {selectedSupplimentList && selectedSupplimentList.map((val, index) => {
                                  return (
                                    <div className="addvalue-in">{val.supplimentName}<span className="closeadd" onClick={() => { removeSelectedSupplement(index) }}><i className="fa fa-times"></i></span></div>
                                  )
                                })}
                              </div>
                              
                            </div>
                            <div className="mb-2 me-2">
                              <div className='deat'>
                                <img src={Nutrient} className='icnn' />
                                <label htmlFor="Nutrient" className="form-label">Nutrient</label>
                                <span className='df1' style={{ cursor: 'pointer' }} onClick={() => { setSelectedNutrientList([]) }}><i className='fa fa-minus' ></i></span>
                                {nutrientList && <DropdownWithSearch defaulNname="Select Nutrient" name="Nutrient" list={nutrientList} valueName="id" displayName="nutrientName" getvalue={handleChange} editdata={0} clear={0} clearFun={""} id="ddlNutrient" />}
                                <small id="errNutrient" className="form-text text-danger" style={{ display: 'none' }}>Nutrient Already Selected</small>
                                <div className="addvalue narrt1">
                                  {selectedNutrientList && selectedNutrientList.map((val, index) => {
                                    return (
                                      <div className="addvalue-in">{val.nutrientName}<span className="closeadd" onClick={() => { removeSelectedNutrient(index) }}><i className="fa fa-times"></i></span></div>

                                    )
                                  })}
                                  
                                </div>
                              </div>
                            </div>
                          </div> */}

                          <div className="d-flex flex-row col-md-12 col-sm-12">
                            <div className="mb-2 me-2">
                              <div className='deat'>
                                <img src={Vital} className='icnn' />
                                <label htmlFor="Vital" className="form-label">Vital</label>
                                <span className='df1' style={{ cursor: 'pointer' }} onClick={() => { setSelectedVitalList([]) }}><i className='fa fa-minus'></i></span>
                                {/* <input type="text" className="form-control form-control-sm" id="Vital" name="Vital" placeholder="Enter Vital" /> */}
                                {vitalList && <DropdownWithSearch defaulNname="Select Vital" name="Vital" list={vitalList} valueName="id" displayName="vitalName" getvalue={handleChange} editdata={0} clear={0} clearFun={""} id="ddlVital" />}
                                <small id="errVital" className="form-text text-danger" style={{ display: 'none' }}>Vital Already Selected</small>
                                <div className="addvalue narrt1">
                                  {selectedVitalList && selectedVitalList.map((val, index) => {
                                    return (
                                      <div className="addvalue-in">{val.vitalName}<span className="closeadd" onClick={() => { removeSelectedVital(index) }}><i className="fa fa-times"></i></span></div>
                                    )
                                  })}
                                  {/* <div className="addvalue-in">Heart Rate<span className="closeadd"><i className="fa fa-times"></i></span></div>
                                        <div className="addvalue-in">Pulse<span className="closeadd"><i className="fa fa-times"></i></span></div>
                                        <div className="addvalue-in">Spo2<span className="closeadd"><i className="fa fa-times"></i></span></div>
                                        <div className="addvalue-in">Temperature<span className="closeadd"><i className="fa fa-times"></i></span></div>
                                        <div className="addvalue-in">BP_Sys<span className="closeadd"><i className="fa fa-times"></i></span></div>
                                        <div className="addvalue-in">Heart Rate<span className="closeadd"><i className="fa fa-times"></i></span></div>
                                        <div className="addvalue-in">Pulse<span className="closeadd"><i className="fa fa-times"></i></span></div> */}
                                </div>
                              </div>
                            </div>
                            <div className="mb-2 me-2">
                              <div className='deat'>
                                <img src={Investigation} className='icnn' />
                                <label htmlFor="Investigation" className="form-label">Investigation</label>
                                <span className='df1' style={{ cursor: 'pointer' }} onClick={() => { setSelectedInvestigationList([]) }}><i className='fa fa-minus'></i></span>

                                {/* <input type="text" className="form-control form-control-sm" id="Investigation" name="Investigation" placeholder="Enter Investigation" /> */}
                                {investigationList && <DropdownWithSearch defaulNname="Select Investigation" name="investigation" list={investigationList} valueName="id" displayName="testname" getvalue={handleChange} editdata={0} clear={0} clearFun={""} id="ddlInvetigationList" />}
                                {/* {problemList && <DropdownWithSearch defaulNname="Select problem" name="abnormalSymptomID" list={problemList} valueName="id" displayName="problemName" editdata={editProblem} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />} */}
                                <small id="errInvestigation" className="form-text text-danger" style={{ display: 'none' }}>Investigation Already Selected</small>
                                <div className="addvalue narrt1">
                                  {selectedInvestigationList && selectedInvestigationList.map((list, index) => {
                                    return (
                                      <div className="addvalue-in">{list.testname}<span className="closeadd" onClick={() => { removeSelectedInvestigation(index) }}><i className="fa fa-times"></i></span></div>


                                    )
                                  })}
                                </div>


                              </div>
                            </div>
                          </div>

                          {/* <div className="col-md-4 col-sm-12">
                            <div className="mb-2 me-2">
                              <img src={Supplements} className='icnn' />
                              <label htmlFor="FoodSupplementDrug" className="form-label">Food/Supplement/Drug</label>
                              <span className='df1' style={{ cursor: 'pointer' }} onClick={() => { setSelectedFoodList([]) }}><i className='fa fa-minus' ></i></span>
                            </div>
                            {foodList && <DropdownWithSearch defaulNname="Select Food/Supplement/Drug" name="FoodSupplementDrug" list={foodList} valueName="id" displayName="foodName" getvalue={handleChange} editdata={0} clear={0} clearFun={""} id="ddlFoodSupplementDrug" />}
                           
                            <small id="errFoodSupplementDrug" className="form-text text-danger" style={{ display: 'none' }}>Food/Supplement/Drug Already Selected</small>
                            <div className="addvalue narrt1">
                              {selectedFoodList && selectedFoodList.map((val, index) => {
                                return (
                                  <div className="addvalue-in">{val.foodName}<span className="closeadd" onClick={() => { removeSelectedFoodSupplemntDrg(index) }}><i className="fa fa-times"></i></span></div>

                                )
                              })}
                            </div>

                            <div className="mb-2 me-2">
                              <img src={ECGData} className='icnn' />
                              <label htmlFor="ECGData" className="form-label">ECG Data</label>
                              <span className='df1' style={{ cursor: 'pointer' }} onClick={() => { setSelectedEcgList([]) }}><i className='fa fa-minus' ></i></span>
                              {ecgList && <DropdownWithSearch defaulNname="Select ECG" name="ECG" list={ecgList} valueName="ecgName" displayName="ecgName" getvalue={handleChange} editdata={0} clear={0} clearFun={""} id="ddlEcg" />}
                              <small id="errECG" className="form-text text-danger" style={{ display: 'none' }}>ECG Already Selected</small>
                              <div className="addvalue narrt1">
                                {selectedEcgList && selectedEcgList.map((val, index) => {
                                  return (
                                    <div className="addvalue-in">{val.ecgName}<span className="closeadd" onClick={() => { removeSelectedEcg(index) }}><i className="fa fa-times"></i></span></div>

                                  )
                                })}
                              </div>
                            </div>
                          </div> */}

                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 analuze">
                    <div className="fieldsett-in">
                      <div className="fieldsett">
                        <span className='fieldse'>Analyzing Graph Date</span>
                        <BoxContainer>
                          <div className="mb-2 me-2">
                            <img src={date} className='icnn' /> <label htmlFor="FromDate" className="form-label">From Date<span className="starMandatory">*</span></label>
                            <input type="date" className="form-control form-control-sm inn1" id="FromDate" name="fromDate" value={fromDate} placeholder="Select Request Date" onChange={handleChange} />
                            <small id="errFromDate" className="form-text text-danger" style={{ display: 'none' }}>Please Select From Date</small>
                          </div>
                          <div className="mb-2 me-2">
                            <img src={time} className='icnn' /> <label htmlFor="FromTime" className="form-label">From Time<span className="starMandatory">*</span></label>
                            <input type="time" className="form-control form-control-sm inn1" id="FromTime" name="fromTime" value={fromTime} placeholder="Enter Request Time" onChange={handleChange} />
                            <small id="errFromTime" className="form-text text-danger" style={{ display: 'none' }}>Please Select From Time</small>
                          </div>
                          <div className="mb-2 me-2">
                            <img src={time} className='icnn' /> <label htmlFor="BloodGroup" className="form-label" >To Hour</label>
                            <select className="form-select form-select-sm inn1" aria-label=".form-select-sm example" name='toHour' value={toHour} id='ddlToHour' onChange={handleChange}>
                              <option value="0">Select Hour</option>
                              <option value="1">1Hour</option>
                              <option value="2">2Hour</option>
                              <option value="3">3Hour</option>
                              <option value="4">4Hour</option>
                              <option value="5">5Hour</option>
                              <option value="6">6Hour</option>
                              <option value="7">7Hour</option>
                              <option value="8">8Hour</option>
                              <option value="9">9Hour</option>
                              <option value="10">10Hour</option>
                              <option value="11">11Hour</option>
                              <option value="12">12Hour</option>
                              <option value="13">13Hour</option>
                              <option value="14">14Hour</option>
                              <option value="15">15Hour</option>
                              <option value="16">16Hour</option>
                              <option value="17">17Hour</option>
                              <option value="18">18Hour</option>
                              <option value="19">19Hour</option>
                              <option value="20">20Hour</option>
                              <option value="21">21Hour</option>
                              <option value="22">22Hour</option>
                              <option value="23">23Hour</option>
                              <option value="24">24Hour</option>
                            </select>
                            <small id="errToHour" className="form-text text-danger" style={{ display: 'none' }}>Please Select To Hour</small>
                          </div>
                          <div className="mb-2 me-2">
                            <img src={IntakeType} className='icnn' /> <label htmlFor="Component" className="form-label">Intake Type</label>
                            <select className="form-select form-select-sm inn1" aria-label=".form-select-sm example" name='intakeType' id='ddlIntakeType' onChange={handleChange}>
                              <option value="0">Select Intake Type</option>
                            </select>
                          </div>
                        </BoxContainer>
                        <div className='searchbtnn'>
                          <button onClick={handleSearch}><i className='fa fa-search'></i>Search Result</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mt-2 " >
              <div className="med-table-section" style={{ minHeight: "520px" }}>
                <Heading text='Patient Analyzing Graph' />
                {
                  console.log("graph", graph)
                }
                {
                  graph ? <HighchartsReact highcharts={Highcharts} options={graph} /> : <NODataFound />
                }

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
