import React from 'react'
import printuse from '../../../assets/css/printuse.css'
// import SarvodayaLogo from '../../../assets/images/icons/sarvodayalogo.png'

export default function IcuAdmissionNote() {
    return (
        <>
            <div className="main-table" style={{ padding: '10px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}


                <table className='mainsedationtbl'>
                    {/* ....header.. */}
                    <tr>
                        <td colSpan={6}>
                            <table>
                                <tr>
                                    <td>
                                        <div className="sarvodya-header mt-4">
                                            <div className='lft-header'>
                                                {/* <img src={sarvodayalogo} alt='' className='sv-log' /> */}
                                            </div>
                                            <div className='rght-header mt-2'>
                                                <div> SARVODAYA HOSPITAL <div className='addrs'>SECTOR-8, FARIDABAD | HELPLINE: 1800 313 1414</div></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td colSpan={6} style={{ verticalAlign: 'top' }}>
                            <div className='micro-heading' >
                                ICU ADMISSION NOTE
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table>
                                <tr>
                                    <td colSpan={2}>
                                        <div>Name of Patient : <span>ss</span></div>
                                    </td>
                                    <td colSpan={2}>
                                        <div>MR NO : <span>ss</span></div>
                                    </td>
                                    <td colSpan={2}>
                                        <div>IP NO : <span>ss</span></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <div>Age / Gender : <span>ss</span></div>
                                    </td>
                                    <td colSpan={2}>
                                        <div>Intensivity/ Consultant <span>ss</span></div>
                                    </td>
                                    <td colSpan={2}>
                                        <div><span></span></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <div>Bed No : <span>ss</span></div>
                                    </td>
                                    <td colSpan={2}>
                                        <div>Date of Admission to ICU <span>ss</span></div>
                                    </td>
                                    <td colSpan={2}>
                                        <div><span></span></div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    <td >
                                        <div>1.</div>
                                    </td>
                                    <td colSpan={5}>
                                        <div>  Reason for ICU Admission : <span>ss</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    <td >
                                        <div>2.</div>
                                    </td>
                                    <td colSpan={5}>
                                        <div> Co-orbit Illnesses & Present treatment: <span>ss</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll'>
                                <tr>
                                    <td>
                                        <div>3.</div>
                                    </td>
                                    <td colSpan={5}>
                                        <div> Pertinent Examination Finding: <span>ss</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll admtble'>
                                <tr>
                                    <td colSpan={4}>
                                        <div>  Genaral: <span>ss</span></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll admtble'>

                                <tr>
                                    <td colSpan={4}>
                                        <div>  RS: <span>ss</span></div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll admtble'>

                                <tr>
                                    <td colSpan={4}>
                                        <div>  CVS: <span>ss</span></div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll admtble'>

                                <tr>
                                    <td colSpan={4}>
                                        <div>  CNS: <span>ss</span></div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll admtble'>

                                <tr>
                                    <td colSpan={4}>
                                        <div> Endocrine: <span>ss</span></div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll admtble'>

                                <tr>
                                    <td colSpan={4}>
                                        <div>Heamatological: <span>ss</span></div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll admtble'>

                                <tr>
                                    <td colSpan={4}>
                                        <div>GI: <span>ss</span></div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bdrtbl' colSpan={6}>
                            <table className='admisntbll admtble'>

                                <tr>
                                    <td colSpan={4}>
                                        <div> Musculoskeletal: <span>ss</span></div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>


        </>
    )
}
