import React from 'react'
import { useState, useEffect } from 'react'
import SuccessToster from "../../Component/SuccessToster";
import AlertToster from "../../Component/AlertToster";
import GetPatientDetailsforSarvodaya from './API/GetPatientDetailsforSarvodaya';
import GetprocedureNotes from './API/GetprocedureNotes';
import PostProcedureNotes from './API/PostProcedureNotes';
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import GetPatientDetailsByUHID from '../../../src/Clinical/API/IPD/personalDashboardIndexSecond/GetPatientDetailsByUHID'
import ProvisonalDiagnosisMainComponent from './ProvisonalDiagnosisMainComponent';
import BPSystolic from '../../assets/images/vitalsicons/BPSystolic.svg'
import bp from '../../assets/images/vitalsicons/bp.svg'
import GetProcedures from './API/GetProcedures';
import lungs from '../../assets/images/vitalsicons/lungs.svg'
import InvestigationsRows from './InvestigationsRows';
import ProvisionalDiagnosiRows from './ProvisionalDiagnosiRows';
import imgPrint from '../../assets/images/icons/imgPrint.svg'
import ProcedureNotesProcedureRows from './ProcedureNotesProcedureRows';
import POSTInsertPatientVital from '../../Clinical/API/OPD/Vitals/POSTInsertPatientVital';
import Select from 'react-select';
import PatientDeatils from '../../Component/PatientDeatils';
export default function SarvodayaProcedureNotes() {
  const currentDate = new Date();
  const DateFormated = currentDate.toISOString().substr(0, 10);
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  const formattedTime = `${hours}:${minutes}:${seconds}`;
  let formattedDate = '';
  function getCurrentDateTime() {

    const now = new Date();
    const ISTOffset = 330 * 60000; 
    const ISTTime = new Date(now.getTime() + ISTOffset);
    return ISTTime.toISOString().slice(0, 16).replace('T', ' ');
  }
  const [patientData, setPatientData] = useState([]);
  const [DrugName, setDrugName] = useState("");
  const [procedureName, setprocedureName] = useState(null);
  const [procedureNameTable, setprocedureNameTable] = useState([]);
    const [drugId, setDrugId] = useState(1);
  const [DrugNameJSON, setDrugNameJSON] = useState("");
  let [Saverow, setSaverow] = useState([])
  const [adviceId, setadviceId] = useState(1);
  const [advice, setadvice] = useState("");
  const [adviceJSON, setadviceJSON] = useState("");
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [InstructionsId, setInstructionsId] = useState(1);
  const [Instructions, setInstructions] = useState("");
  const [InstructionsJSON, setInstructionsJSON] = useState("");

  let [InvestigationJSON, setInvestigationJSON] = useState([]);
  let [saveDiagnosis, setSaveDiagnosis] = useState([]);
  let [TableData, setTableData] = useState([]);
  let [sendForm, setSendForm] = useState({})

  let [showUnderProcess, setShowUnderProcess] = useState(0)
  let [showToster, setShowToster] = useState(0)
   let [tosterMessage, setTosterMessage] = useState("")
   let [tosterValue, setTosterValue] = useState(0)
   let [showAlertToster, setShowAlertToster] = useState(0);
  let [showSuccessToster, setShowSuccessToster] = useState(0)
  let [sedationYes, setsedationYes] = useState(0)
  let [sedationNo, setsedationNo] = useState(0)
  let [Stable, setStable] = useState(0)
  let [unStable, setunStable] = useState(0)
   let [showMessage, setShowMeassage] = useState("");
   let [pDate, setpDate] = useState(getCurrentDateTime());
   let [time, settime] = useState(formattedTime);
   let [advicedate, setadvicedate] = useState(getCurrentDateTime());
   let [fluidSend, setfluidSend] = useState("");
   let [vitalTime, setvitalTime] = useState("");
   let [vitalDate, setvitalDate] = useState("");
   let [fluidSendJSON, setfluidSendJSON] = useState([]);
 

  let requiredUHID = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].uhId;
  let requiredpid = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].pid;
  let requiredpmId = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].pmId;
  // let VitalDetails = JSON.parse(sessionStorage.getItem("IPDpatientsendData"))[0][5].jsonVital;
  let Pid = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].pid;
  const clientID=JSON.parse(sessionStorage.getItem("LoginData")).clientId;
  let [userID] = useState(JSON.parse(sessionStorage.getItem("LoginData")).userId);
  const GetPatientData= async()=>{
    let data = await GetPatientDetailsforSarvodaya(requiredUHID)
    if(data.status === 1){
      setPatientData(data.responseValue[0])
      console.log("PatientData" , data.responseValue)
    }
  
   }

  
   if (patientData && patientData.admitDate) {
     const parts = patientData.admitDate.split('/');
     formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`; 
   
   }

   const handleInvestigationJSON = (temp,data) => {
    setInvestigationJSON(temp,data);
  
};

const handleClearInvestigation=(row)=>{
  setSaverow([row])
  }


   const handleAddDrug = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (DrugName.length > 0) {
        
        const newDrug = { drugName: DrugName};
        setDrugNameJSON([...DrugNameJSON, newDrug]);
        setDrugName("");
      }
       else {
        alert("Please Enter Drug Name");
      }
    }
  };
   const handleAddAdvice = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (advice.length > 0) {
     
        const newDrug = { advice: advice,  };
        setadviceJSON([...adviceJSON, newDrug]);
        setadvice("");
      }
       else {
        alert("Please Enter Advice");
      }
    }
  };
   const handleAddInstructions = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (Instructions.length > 0) {
       
        const newDrug = { Instructions: Instructions };
        setInstructionsJSON([...InstructionsJSON, newDrug]);
        setInstructions("");
      }
       else {
        alert("Please Enter Advice");
      }
    }
  };
   const handleAddFluid = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (fluidSend.length > 0) {
       
        const newDrug = { fluidSend: fluidSend };
        setfluidSendJSON([...fluidSendJSON, newDrug]);
        setfluidSend("");
      }
       else {
        alert("Please Enter Advice");
      }
    }
  };




  const removeDrug = (index) => {
    const updatedDrugList = DrugNameJSON.filter((_, i) => i !== index);
    setDrugNameJSON(updatedDrugList);
  };

  const removeAdvice = (index) => {
    const updatedAdviceList = adviceJSON.filter((_, i) => i !== index);
    setadviceJSON(updatedAdviceList);
  };
  const removeInstructions = (index) => {
    const updatedAdviceList = InstructionsJSON.filter((_, i) => i !== index);
    setInstructionsJSON(updatedAdviceList);
  };
  const removeFluidSample = (index) => {
    const updatedAdviceList = fluidSendJSON.filter((_, i) => i !== index);
    setfluidSendJSON(updatedAdviceList);
  };

  
const handleOnChange=(e)=>{
  const {value , name} = e.target
  if(name === "DrugName"){
    setDrugName(value)
  }
  if(name === "advice"){
    setadvice(value)
  }
  if(name === "Instructions"){
    setInstructions(value)
  }
  if(name === "fluidSend"){
    setfluidSend(value)
  }
}


let handleVitalChange = (e) => {
  
  let name = e.target.name;
  let value = e.target.value;

   if (name === "vmValueBPSys") {
      document.getElementById("errBPS").style.display = "none";

  }
  else if (name === "vmValueBPDias") {
      document.getElementById("errBPD").style.display = "none";

  }

  else if (name === "vmValueRespiratoryRate") {
      document.getElementById("errRR").style.display = "none";

  }
  else if (name === "vitalDate") {
      setvitalDate(value)

  }
  else if (name === "vitalTime") {
    setvitalTime(value)

  }

  setSendForm(sendForm => ({
    ...sendForm,
    [name]: value
}))

 


};



const handleOnSave=async()=>{
  let bpsVal = document.getElementById('vmValueBPSys').value;
  let bpdVal = document.getElementById('vmValueBPDias').value;

  let RRVal = document.getElementById('vmValueRespiratoryRate').value;
  const advices = advice.split('\n').map(item => item.trim()); 
  const drugs = DrugName.split('\n').map(item => item.trim()); 
  const InstructionsData = Instructions.split('\n').map(item => item.trim()); 
  const fluidSendData = fluidSend.split('\n').map(item => item.trim()); 
  const adviceString = advices.join(', ');
  const drugsString = drugs.join(', ');
  const InstructionsFormated = InstructionsData.join(', ');
  const fluidSendFormated = fluidSendData.join(', ');

if(procedureName === null){
  setShowMeassage(`Please Select Procedure`)
  setShowAlertToster(1)
  return
}
if(sedationNo === 0 && sedationYes === 0){
  setShowMeassage(`Please Choose Sedation`)
  setShowAlertToster(1)
  return
}


if(bpsVal == ""){
  setShowMeassage(`Please Enter Bps `)
  setShowAlertToster(1)
  return
}
if(bpdVal == ""){
  setShowMeassage(`Please Enter Bpd `)
  setShowAlertToster(1)
  return
}
if(RRVal == ""){
  setShowMeassage(`Please Enter RR `)
  setShowAlertToster(1)
  return
}
if(vitalDate == ""){
  setShowMeassage(`Please Enter Vital Date `)
  setShowAlertToster(1)
  return
}
if(vitalTime == ""){
  setShowMeassage(`Please Enter Vital Time `)
  setShowAlertToster(1)
  return
}
if(Stable === 0 && unStable === 0){
  setShowMeassage(`Please Mental Stability `)
  setShowAlertToster(1)
  return
}

if(advice === '' ){
  setShowMeassage(`Please Enter Advice `)
  setShowAlertToster(1)
  return
}
// if(advicedate === '' ){
//   setShowMeassage(`Please Enter Advice Date`)
//   setShowAlertToster(1)
//   return
// }

  const obj = {
  "dateTimeOfProcedure": pDate ,
  "uhid": requiredUHID,
  "pmId": requiredpmId,
  "clientId": clientID,
  "userId": userID,
  "isSedated": sedationYes,
  "radiologyInvestigation": JSON.stringify(InvestigationJSON),
  "drugGiven": drugsString,
  "fluidSampleSentTo": fluidSendFormated,
  "instructions": InstructionsFormated,
  "advice": adviceString,
  "adviseDateTime":  getCurrentDateTime(),
  "mentalFuction": Stable,
   "procedureId" : procedureName.value
  }

 console.log("obj" , obj)
 
 let clearData ={
  setClear : 1
 }

  let data = await PostProcedureNotes(obj)
  if(data.status === 1){
    saveData()
    setSaveDiagnosis(1)
    setShowSuccessToster(1)
    setShowMeassage(`Data Saved Successfully`)
    
    window.sessionStorage.setItem("runClearData" , JSON.stringify(clearData))
    GetData()
    handleClear()
    setTimeout(() => {
      window.sessionStorage.removeItem("runClearData");
    }, 1000);
     setTimeout(() => {
      setShowSuccessToster(1)
      }, 2000);
    } else {
    setShowAlertToster(1)
    setShowMeassage(data.responseValue)
    setTimeout(() => {
     setShowAlertToster(0)
    }, 2000);
   
  }

  console.log("SavedData" , obj)
}

 const handlePrint=(index)=>{
  let obj ={
  rowData : TableData[index],
  PatientData : JSON.parse(sessionStorage.getItem("IPDpatientList"))[0]
  }
  window.sessionStorage.setItem("ProcedureNotes",JSON.stringify(obj));
  window.open("/ProcedureNotesprint/", "noopener,noreferrer");
 }




let saveData = async () => {
 
  // sendForm["uhid"] = JSON.parse(window.sessionStorage.getItem("activePatient")).Uhid
  sendForm["handoverTonameId"] = window.handoverTonameId

  let bpsVal = document.getElementById('vmValueBPSys').value;
  let bpdVal = document.getElementById('vmValueBPDias').value;

  let RRVal = document.getElementById('vmValueRespiratoryRate').value;


  let obj={
    uhid : requiredUHID,
    clientId : clientID,
    userId : userID,
    vmValueBPSys : bpsVal,
    vmValueBPDias : bpdVal,
    vmValueRespiratoryRate : RRVal,
    vitalTime : vitalTime,
    vitalDate : vitalDate,
    pid : requiredpid,
    pmId : requiredpmId
  }

   console.log("VitalDetails" , obj)
 
  if(bpsVal === '' && bpdVal ===''  && RRVal==='' )
  {
      document.getElementById('errVital').innerHTML = "Please enter any vital";
      document.getElementById('errVital').style.display = "block";
  }

  // else if (bpsVal > 500) {
  //     document.getElementById('errBPS').innerHTML = "Enter valid value";
  //     document.getElementById('errBPS').style.display = "block";
  // }
  // else if (bpdVal > 1000) {
  //     document.getElementById('errBPD').innerHTML = "Enter valid value";
  //     document.getElementById('errBPD').style.display = "block";
  // }
  // else if (spo2Val > 100) {
  //     document.getElementById('errSPO2').innerHTML = "Enter valid value";
  //     document.getElementById('errSPO2').style.display = "block";
  // }
  // else if (RRVal > 50) {
  //     document.getElementById('errRR').innerHTML = "Enter valid value";
  //     document.getElementById('errRR').style.display = "block";
  // }
  // else if (PRVal > 100) {
  //     document.getElementById('errPR').innerHTML = "Enter valid value";
  //     document.getElementById('errPR').style.display = "block";
  // }
 
  // else if (TempVal > 110) {
  //     document.getElementById('errTemp').innerHTML = "Enter valid value";
  //     document.getElementById('errTemp').style.display = "block";
  // }

 
  else {
     

      let response = await POSTInsertPatientVital(obj)
      if (response.status === 1) {
          setShowUnderProcess(0)
          setShowSuccessToster(1)
          setShowToster(1)
          setShowMeassage(`Data Saved Successfully`)
          handleClear()
         
          setTosterValue(1)
          setTimeout(() => {
            setShowSuccessToster(1)
          }, 2000)
         
          // handleClear();
          console.log(response)
      }
      else {
          setShowUnderProcess(0)
          setShowToster(1)
          setTosterMessage(response.responseValue)
          setTosterValue(1)
          setTimeout(() => {
              setShowToster(0)
          }, 2000)

      }
  }
}


const handleClear=()=>{
  let clearData ={
    setClear : 1
   }
  window.sessionStorage.setItem("runClearData" , JSON.stringify(clearData))
  GetData()
  setTimeout(() => {
    window.sessionStorage.removeItem("runClearData");
  }, 1000);
  handleClearInvestigation()
setsedationNo(0)
setsedationYes(0)
setStable(0)
setunStable(0)
setprocedureName(null)
setadvice('')
  setDrugName('')
  setInstructions('')
  setadvicedate('')
  setfluidSend('')
}


const handleChange=(e)=>{
const {value , name} = e.target
if(name === "pDate"){
setpDate(value)
}
if(name === "pTime"){
settime(value)
}
if(name === "advicedate"){
setadvicedate(value)
}
if(name === "fluidSend"){
setfluidSend(value)
}
}

const handleSelectChange = (selectedOption, errorElementId, setSelectedFunction) => {
  document.getElementById(errorElementId).style.display = 'none';
  setSelectedFunction(selectedOption);
};


const handleCheckBox=(e)=>{
  const {value , name} = e.target
  if(name === "SedationYes"){
    setsedationYes(1)
    setsedationNo(0)
  }
  if(name === "SedationNo"){
    setsedationNo(1)
    setsedationYes(0)
  }
  if(name === "stable"){
    setStable(1)
    setunStable(0)
  }
  if(name === "unstable"){
    setStable(0)
    setunStable(1)
  }
}


const getProcedures =async()=>{
  let data = await GetProcedures()
  if(data.status === 1){
    setprocedureNameTable(data.responseValue.map(val => ({
      value: val.id,
      label: val.procedureName
    })));
  }
 
}

// const GetData=async()=>{
//   let data = await GetprocedureNotes(requiredUHID, requiredpmId)
//   if(data.status === 1){
//     setTableData(data.responseValue)
//     console.log("MainData" ,data.responseValue )
//   }
// else{
//   setShowMeassage(`No Data Found`)
//   setShowAlertToster(1)
// }
// }



const GetData=async()=>{
  let data = await GetprocedureNotes(requiredUHID, requiredpmId)
  if(data.status === 1){
    const mappedData = data.responseValue.map(item => {
      return {
        ...item,
     
        radiologyInvestigationsFormated : item.radiologyInvestigations ? JSON.parse(item.radiologyInvestigations) : [],
        diagnosisFormated : item.diagnosis ? JSON.parse(item.diagnosis) : [],  
      
       
        // investigationFormated : item.investigationJson ? JSON.parse(item.investigationJson) : [],
      };
    });
       setTableData(mappedData)
 
 
         
          let allradiologyInvestigationsFormated = []
          let allDiagnosis = []
       
     
         
           
           
 
              data.responseValue.forEach((item, index) => {
             
             
                allradiologyInvestigationsFormated.push(item.radiologyInvestigationsFormated)
                allDiagnosis.push(item.diagnosisFormated)
              
       
             
 
             
            })
 
    console.log("MainData" , mappedData)
  }
 
}


  useEffect(() => {
    
    GetPatientData()
    getProcedures()
    GetData()
   
    const updateTime = () => {
      const currentDate = new Date();
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');
      const seconds = String(currentDate.getSeconds()).padStart(2, '0');
      const formattedTime = `${hours}:${minutes}`;
      settime(formattedTime);
    };
    updateTime();
    const intervalId = setInterval(updateTime, 1000);
    return () => clearInterval(intervalId);

  }, [])

  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="col-12"><div className="med-box  mb-1"><div className="title">Procedure Notes</div></div></div>
          <div className="med-box">
            <div className="inner-content">
            <PatientDeatils/>
              <div className="">
              <div className='fieldsett-in col-12'>
                  <div className='fieldsett'>
                    <span className='fieldse'>Procedure Details</span>
                    <div className='row px-2'>


                      <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-3">
                        <label htmlFor="Code" className="form-label">Date/Time of Procedure<span className="starMandatory"></span></label>
                        <input id="dop" type="datetime-local"  value={pDate} className="form-control form-control-sm"  name="pDate" onChange={handleChange} />
                      </div>

                      {/* <div className="col-xxl-6 col-xl-12 col-lg-6 col-md-12 mb-3">
                        <label htmlFor="Code" className="form-label">Time of Procedure<span className="starMandatory"></span></label>
                        <input id="top" type="time" value={time} className="form-control form-control-sm"  name="pTime"  onChange={handleChange}/>
                      </div>
                                */}

                   

                 
                    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 px-4 mt-2 mb-3">
                {/* <ProvisionalDiagnosisRows/> */}
                    <ProvisonalDiagnosisMainComponent isProvisional = {0} saveDiagnosis={saveDiagnosis} setSaveDiagnosis={setSaveDiagnosis}/>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-3">
                      <label htmlFor="ddlitemmaster" className="form-label ">Procedure<span className="starMandatory">*</span></label>
                      <Select value={procedureName} options={procedureNameTable} className=" create-select" id="serviceType" placeholder="Select procedure" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, "errprocedureNameby", setprocedureName)} />
                      <small id="errprocedureNameby" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>
                <div>
                </div>

                    </div>

                  </div>
                </div>

                <div className='fieldsett-in col-12'>
                  <div className='fieldsett'>
                    <span className='fieldse'>Sedetaion</span>
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                  
                    <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-2">
                    <div><label htmlFor="Code" className="form-label mt-2 me-3 ">Sedation<span className="starMandatory">*</span></label></div>
                   
                          <div className="d-flex column-gap-3 mt-2 me-3">
                          <label htmlFor="canRead" className="form-label">Yes<span className="starMandatory"></span></label>
                          <div className="form-check">
                            <input name="SedationYes" checked={sedationYes}  className="form-check-input" type="checkbox" onChange={handleCheckBox}
                             />
                          </div>
                    
                        </div>
                          <div className="d-flex column-gap-3 mt-2 me-3">
                          <label htmlFor="canRead" className="form-label">No<span className="starMandatory"></span></label>
                          <div className="form-check">
                            <input name="SedationNo"  checked={sedationNo} className="form-check-input" type="checkbox" onChange={handleCheckBox}
                             />
                          </div>
                    
                        </div>
                      
  
                    

                    </div>
                    </div >

                  </div>
                 
                
                </div>
                <div className="row">
                   <div className='fieldsett-in col-6'>
                
                  <div className='fieldsett mt-3' style={{maxHeight : '139px' , minHeight : '139px'}}>
                    <span className='fieldse'>Radiological Investigations Done</span>
                    <div className="row">
                      <div style={{maxHeight : '120px' , minHeight : '120px',overflow: 'auto'}}>
                         <InvestigationsRows  investigationsJson = {handleInvestigationJSON} clearData={handleClearInvestigation}/>
                      </div>
                    
      
               </div>

                  </div>
                
                </div>
                <div className='fieldsett-in col-6' style={{maxHeight : '139px' , minHeight : '139px'}}>
             
                  <div className='fieldsett mt-3' >
                    <span className='fieldse'>Drug Given for Sedation</span>
                    <div className="row" style={{maxHeight : '120px' , minHeight : '120px',overflow: 'auto'}}>
                      
                      <div className="input-group mt-2">
                    <textarea className="form-control" placeholder='Enter Drug Name'  name='DrugName' value={DrugName} onChange={handleOnChange} aria-label="With textarea" style={{border: 'none'}}></textarea>
                     </div>
                    
                    
      
               </div>

                  </div>
                </div>
                </div>
               

               

         
                <div className="row">
               <div className='fieldsett-in col-md-4'>
               <div className='fieldsett' style={{padding: '20px'}}>
               <span className='fieldse'>Biopsy/FNAC/Fluid Sample sent to</span>
                <div className="input-group">
             
                        <textarea className="form-control" placeholder='Enter Biopsy/FNAC/Fluid Sample sent to'  name='fluidSend'  value={fluidSend} onChange={handleOnChange} aria-label="With textarea" style={{border: 'none'}}></textarea>
                      </div>
                      <div >
                  {/* {fluidSendJSON && fluidSendJSON.map((val , index)=>{
                    return(
                      <>
                      <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                      <i className="bi bi-x drugNameRemove" onClick={() => removeFluidSample(index)}></i>
                      <button key={index} className='drugNamebutton mx-1 mt-2'>{val.fluidSend}</button>
                      </div>
                      </>
                    )
                  })} */}
                 </div>
              </div>
              </div>
               <div className='fieldsett-in col-md-4'>
               <div className='fieldsett' style={{padding: '20px'}}>
               <span className='fieldse'>Post Procedure Observations & Instructions </span>
              <div className="row">

                     <div className="input-group">
                     
                       <textarea className="form-control" placeholder='Enter Instructions'    name='Instructions' value={Instructions} onChange={handleOnChange} aria-label="With textarea" style={{border: 'none'}}></textarea>
                     </div>

                     <div >
                  {/* {InstructionsJSON && InstructionsJSON.map((val , index)=>{
                    return(
                      <>
                      <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                      <i className="bi bi-x drugNameRemove" onClick={() => removeInstructions(index)}></i>
                      <button key={index} className='drugNamebutton mx-1 mt-2'>{val.Instructions}</button>
                      </div>
                      </>
                    )
                  })} */}
                 </div>
     
              </div>
              </div>
              </div>

 
                <div className='fieldsett-in col-md-4'>
                <div className='fieldsett' >
                <span className='fieldse'>Vitals </span>
               <div className="row">
               <div className='vitals-cnt customeInput'>                  
                        <div className='vitals-cnt-in'>
                            <img src={BPSystolic} alt="" className='icnn1' />
                            <input type='number'  placeholder='BPS(mm Hg)' name='vmValueBPSys' id='vmValueBPSys' onChange={handleVitalChange}  />
                            <small id="errBPS" className="absolute-alert" style={{ display: 'none' }}></small>
                        </div>                        
                       
                   
                    <div className='vitals-cnt-in'>
                        <img src={bp} alt='' className='icnn1' />
                        <input type='number'  placeholder='BPD(mm Hg)' name='vmValueBPDias' id='vmValueBPDias'  onChange={handleVitalChange} />
                        <small id="errBPD" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={lungs} alt='' className='icnn1' />
                        <input type='number' placeholder='RR/m' name='vmValueRespiratoryRate' id='vmValueRespiratoryRate' onChange={handleVitalChange}  />
                        <small id="errRR" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    <div className='vitals-cnt-in'>
                      
                        <input type='date' value={vitalDate} name='vitalDate' id='vmValueRespiratoryRate' onChange={handleVitalChange} style={{border: 'none'}}  />
                        <small id="errRR" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    <div className='vitals-cnt-in mt-1'>
                      
                        <input type='time' value={vitalTime}  name='vitalTime' id='vmValueRespiratoryRate' onChange={handleVitalChange}  />
                        <small id="errRR" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    </div>
                  
      
               </div>
               </div>
               </div>
        </div>

           <div className="row ">
           <div className='fieldsett-in col-6'>
                  <div className='fieldsett' style={{minHeight: '100px' , maxHeight :'100px' , padding: '20px'}}>
                    <span className='fieldse'>Higher Mental Function</span>
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mt-2">
                  
                    <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-2">
                   
                   
                          <div className="d-flex column-gap-3 mt-2 me-3">
                          <label htmlFor="canRead" className="form-label">Stable<span className="starMandatory"></span></label>
                          <div className="form-check">
                            <input name="stable" checked={Stable}  className="form-check-input" type="checkbox" onChange={handleCheckBox}
                             />
                          </div>
                    
                        </div>
                          <div className="d-flex column-gap-3 mt-2 me-3">
                          <label htmlFor="canRead" className="form-label">Unstabale<span className="starMandatory"></span></label>
                          <div className="form-check">
                            <input name="unstable"  checked={unStable} className="form-check-input" type="checkbox" onChange={handleCheckBox}
                             />
                          </div>
                    
                        </div>
                      
  
                    

                    </div>
                    </div >

                  </div>
                
              
                </div>
                <div className='fieldsett-in col-6'>
               <div className='fieldsett' style={{padding: '20px'}}>
               <span className='fieldse'>Advices</span>
              <div className="row">
              <div className="input-group">
                <textarea className="form-control" placeholder='Enter Advices'  name='advice' value={advice}  onChange={handleOnChange} aria-label="With textarea" style={{border: 'none'}}></textarea>
                </div>
                <div >
              
                </div>
              </div>
                       {/* <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">
                        <label htmlFor="Code" className="form-label">Date of advice<span className="starMandatory"></span></label>
                        <input id="dop" type="datetime-local" className="form-control form-control-sm" value={advicedate} onChange={handleChange} name="advicedate" />
                      </div> */}
              </div>

                   
              </div>
           </div>
       

       

              </div>

            </div>

          </div>

          <div className="col-12 ">
            <div className="med-box mt-2">
              <div className="inner-content">
                <div className="row">

                  <div className="inner-content text-right">
                    <div className="mb-2 mt-2 relative">
                      <div>

                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1_ me-1" onClick={handleOnSave} ><img src={saveButtonIcon} className="icnn" alt='' />Save</button>
                        <button type="button" className="btn btn-clear btn-sm mb-1_ me-1"  onClick={handleClear}><img alt='' />Clear</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>


              
          <div className="col-12 mt-3">
              <div className="med-table-section" style={{ "height": "80vh" }}>
                <table className="med-table border striped">
                  <thead style={{zIndex: '0'}}>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>UHID</th>
                      <th>Diagnosis</th>
                      <th>Procedure</th>
                      <th>Radiology Investigations</th>
                      <th>Drug Given</th>
                      <th>Fluid Sample Sent To</th>
                      <th>Advices</th>
                     
                      <th>Mental FuctionT ype</th>
                      <th style={{ "width": "10%" }} className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TableData && TableData.map((data, index) => {
                      
                        return(
                         <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td>{requiredUHID}</td>
                          <td>{data.diagnosisFormated.map((val, index) => (
                              <span key={index}>{val.details}
                                 {index < data.diagnosisFormated.length - 1 && <br />}
                              </span>
                            ))}</td>
                            <td>{data.procedureName}</td> 
                            <td>{data.radiologyInvestigationsFormated.map((val, index) => (
                              <span key={index}>{val.itemName}
                                 {index < data.radiologyInvestigationsFormated.length - 1 && <br />}
                              </span>
                            ))}</td>
                             <td>{data.drugGiven}</td>
                             <td>{data.fluidSampleSentTo}
                            </td>
                             <td>{data.advice}</td>


                          <td>{data.mentalFuctionType}</td>  
  
                          <td>
                          
                            <div className="action-button">
                          
                              <div data-bs-title="Delete Row" data-bs-placement="bottom"  ><img src={imgPrint} style={{width: '20px'}} className='' alt='' onClick={()=>handlePrint(index)} />
                            
                              </div>
                             
                            </div>
                          </td>
                        </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
        {showAlertToster === 1 ? (
              <AlertToster message={showMessage} handle={setShowAlertToster} />
            ) : (
              ""
            )}
           {showSuccessToster === 1 ? (
              <SuccessToster
                message={showMessage}
                handle={setShowSuccessToster}
              />
            ) : (
              ""
            )}

      </section>

    </>
  )
}
