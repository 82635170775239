
async function GetInventoryItemMaster(ItemCategoryID, UrgencyLevelID, ClientID) {
  // let url = window.InventoryBaseUrl + "/api/InventoryItemMaster/GetInventoryItem?ClientID=" + ClientID;
  let url = window.InventoryBaseUrl + `/api/InventoryItemMaster/GetInventoryItem?ItemCategoryID=${ItemCategoryID}&UrgencyLevelID=${UrgencyLevelID}&ClientID=${ClientID}`;
  let head = {
    "Content-Type": "application/JSON", accept: '*/*'
  };

  let response = await fetch(url, {
    headers: head,
    method: 'GET'
  })
    .then((res) => res.json())
    .then();

  return response;
}
export default GetInventoryItemMaster;

