import React, { useEffect } from 'react'
import { useState, useRef } from 'react'
import Search, { FindByQuery, SearchIndex } from '../../Code/Serach'
import DownKey, { UpKey } from "../../Code/ListSelect";
import ProvisionalDiagonisys from "../../assets/images/OPD/provisionalDiagonisys.svg"
import GetDiagnosis from './API/GetDiagnosis';
import GetProblemList from '../../Clinical/API/KnowMedsAPI/GetProblemList';
import PostDiagnosisData from './API/PostDiagnosisData';
import AlertToster from '../../Component/AlertToster';
export default function ProvisonalDiagnosisMainComponent({ isProvisional = 0, saveDiagnosis = 0, setSaveDiagnosis }) {
    let [consultantData, setConsultantData] = useState([])
    let [showSearchBoxProblemConsultant, setShowSearchBoxProblemConsultant] = useState(-1)
    let [showSearchBoxProblem, setShowSearchBoxProblem] = useState(-1)


    let [problemList, setProblemList] = useState([])
    let [problemListTemp, setProblemListTemp] = useState([])

    let [sendData, setSendData] = useState([])
    let [symptomsData, setSymptomsData] = useState([])
    // let [showDignosis, setShowDignosis] = useState(1)
    // let [symptomsPopUp, setSymptomsPopUp] = useState(0)
    // let [allergiesPopUP, setAllergiesPopUP] = useState(0)
    // let [consultantPopUP, setConsultantPopUP] = useState(0)
    // let [diagnosisSuggestionPopUP, setDiagnosisSuggestionPopUP] = useState(0)
    // let [showAntibiogram, setShowAntibiogram] = useState(0)
    let [showAlert, setShowAlert] = useState(0)
    let [alertmsg, setAlertmsg] = useState("")

    let row = { "problemId": 0, "problemName": "", "pdmId": 0 }
    let liSelected = useRef()
    let index = useRef(-1)
    let next = useRef()
    let oldData = useRef(0)

    let activeUHID = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : []

    // let setData = () => {
    //     setSymptomsData([])
    //     setConsultantData([])
    //     setSendData([])

    //     let temp = window.sessionStorage.getItem("IPDpatientsendData") ? JSON.parse(window.sessionStorage.getItem("IPDpatientsendData")) : []
    //     let activeUHID = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : []
    //     let symptomsDatas = []
    //     let consultantDatas = []

    //     temp.map((value, index) => {
    //         value.map((val, ind) => {
    //             if (value[0] === activeUHID) {
    //                 let key = Object.keys(val)
    //                 if (key[0] === "jsonDiagnosis") {
    //                     if (val.jsonDiagnosis[0] != null) {
    //                         val.jsonDiagnosis.map((val, ind) => {

    //                             if (val != null) {
    //                                 if (val.pdmId === 2) {
    //                                     setSymptomsData([...symptomsDatas, val])
    //                                     symptomsDatas.push(val)
    //                                 }
    //                                 if (val.pdmId === 4) {
    //                                     setConsultantData([...consultantDatas, val])
    //                                     consultantDatas.push(val)
    //                                 }
    //                             }
    //                         })
    //                     }
    //                 }
    //             }

    //         })

    //     })
    //     setSendData([...symptomsDatas, ...consultantDatas])

    //     symptomsDatas = []
    //     consultantDatas = []
    // }



    let handleSearch = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        // let data = [...sendData].filter(n => n)


        if (value != "") {
            if (name === "symptomsData") {
                
                let response = FindByQuery(problemList, value, "problemName")
                if (response.length != 0) {
                    setProblemListTemp(response)

                    setShowSearchBoxProblem(1)
                }
                else {
                    setShowSearchBoxProblem(-1)
                }

            }
            else if (name === "consultantData") {
                getdata(value)
                let response = FindByQuery(problemList, value, "problemName")
                if (response.length != 0) {
                    setProblemListTemp(response)

                    setShowSearchBoxProblemConsultant(1)
                }
                else {
                    setShowSearchBoxProblemConsultant(-1)
                }

            }

        }
        else {
            setShowSearchBoxProblem(-1)
            setShowSearchBoxProblemConsultant(-1)
        }

    }


    let handleKeyPress = (e) => {
        let value = e.target.value;
        let name = e.target.name
        let ul = ""
        if (e.keyCode === 13) {
            let data = [...sendData].filter(n => n)

            if (name === "symptomsData") {
                if (showSearchBoxProblem === -1) {
                    row["problemId"] = 0
                    row["problemName"] = value
                    row["pdmId"] = 2
                }
                else {
                    ul = document.getElementById('symptomsDataList');
                    row["problemId"] = ul.getElementsByTagName('li')[index.current].value
                    row["problemName"] = ul.getElementsByTagName('li')[index.current].innerText
                    row["pdmId"] = 2
                    setShowSearchBoxProblem(-1)
                    liSelected.current = ""
                    index.current = -1
                    next.current = ""
                    oldData.current = 0
                }
                setSymptomsData([...symptomsData, row])
                setSendData([...sendData, row])

                setSendData([...data, row])
                // SaveIPDData([...data, row], "jsonDiagnosis")

                document.getElementById(name).value = ""
                document.getElementById(name).focus()
            }
            else if (name === "consultantData") {
                if (showSearchBoxProblemConsultant === -1) {
                    row["problemId"] = 0
                    row["problemName"] = value
                }
                else {
                    ul = document.getElementById('consultantDataList');
                    row["problemId"] = ""
                    row["problemName"] = value
                    setShowSearchBoxProblemConsultant(-1)
                    liSelected.current = ""
                    index.current = -1
                    next.current = ""
                    oldData.current = 0
                }
                setConsultantData([...consultantData, row])
                setSendData([...sendData, row])

                setSendData([...data, row])
                // SaveIPDData([...data, row], "jsonDiagnosis")
                document.getElementById(name).value = ""
                document.getElementById(name).focus()
            }

        }

        else if (e.keyCode === 40) {
            // down
            if (name === "symptomsData") {
                if (showSearchBoxProblem !== -1) {
                    ul = document.getElementById('symptomsDataList');
                    DownKey(ul, liSelected, index, next, oldData)
                }
            }
            else if (name === "consultantData") {
                if (showSearchBoxProblemConsultant !== -1) {
                    ul = document.getElementById('consultantDataList');
                    DownKey(ul, liSelected, index, next, oldData)
                }

            }

        }

        else if (e.keyCode === 38) {
            // Up
            if (name === "consultantData") {
                if (showSearchBoxProblem !== -1) {
                    ul = document.getElementById('symptomsDataList');
                    UpKey(ul, liSelected, index, next, oldData)

                }
            }
            else if (name === "consultantData") {
                if (showSearchBoxProblemConsultant !== -1) {
                    ul = document.getElementById('consultantDataList');
                    UpKey(ul, liSelected, index, next, oldData)
                }

            }

        }
    }



    let handleClick = (boxname, id, name) => {
        let data = [...sendData].filter(n => n)
        if (boxname === "symptomsData") {
            let t = 0
            row["problemId"] = id
            row["problemName"] = name
            row["pdmId"] = 2

            setShowSearchBoxProblem(-1)
            setSendData([...consultantData, row])
            let flag = 0
            symptomsData.map((v, i) => {
                if (v.problemId === id) {
                    flag = 1
                    return
                }
            })
            if (flag === 0) {
                setSendData([...data, row])
                // SaveIPDData([...data, row], "jsonDiagnosis")

                setSymptomsData([...symptomsData, row])
            }
            let r = SearchIndex(problemList, "problemName", name)
            let tt = [...problemList]
            tt.splice(r, 1)
            setProblemList(tt)


            let temp = [...data]
            data.map((val, ind) => {
                if (val.pdmId === 2 && val.problemId === 0) {
                    delete temp[ind]
                }
            })
            setSendData([...temp.filter(n => n), row])
            // SaveIPDData([...temp.filter(n => n), row], "jsonDiagnosis")

            document.getElementById("symptomsData").focus()

            document.getElementById("symptomsData").value = "";

        }
        else if (boxname === "consultantData") {
            let t = 0
            row["problemId"] = id
            row["problemName"] = name
            row["pdmId"] = 4
            setShowSearchBoxProblemConsultant(-1)
            setSendData([...consultantData, row])

            let flag = 0
            symptomsData.map((v, i) => {
                if (v.problemId === id) {
                    flag = 1
                    return
                }
            })
            if (flag === 0) {
                setSendData([...data, row])
                // SaveIPDData([...data, row], "jsonDiagnosis")

                setConsultantData([...consultantData, row])
                setSendData([...consultantData, row])
            }
            let r = SearchIndex(problemList, "problemName", name)
            let tt = [...problemList]
            tt.splice(r, 1)
            setProblemList(tt)

            let temp = [...data]
            data.map((val, ind) => {
                if (val.pdmId === 4 && val.problemId === 0) {
                    delete temp[ind]
                }
            })
            // getDataFood([...consultantData, row])
            document.getElementById("consultantData").focus();

            setSendData([...temp.filter(n => n), row])
            // SaveIPDData([...temp.filter(n => n), row], "jsonDiagnosis")

            document.getElementById("consultantData").value = "";
        }
    }


    let handleRemove = (ind, problemId, name, problemName) => {
        let tempsymptomsData = [...symptomsData]
        let tempconsultantData = [...consultantData]
        let tempSenddata = [...sendData]

        if (name === "symptomsData") {
            sendData.map((val, index) => {
                if (val.pdmId === 2 && val.problemId === problemId && val.problemName === problemName) {
                    tempSenddata.splice(index, 1)
                    return
                }
            })
            console.log("ind", ind)
            tempsymptomsData.splice(ind, 1)
        }
        else if (name === "consultantData") {
            sendData.map((val, index) => {
                if (val.pdmId === 4 && val.problemId === problemId && val.problemName === problemName) {
                    tempSenddata.splice(index, 1)
                    return
                }
            })
            tempconsultantData.splice(ind, 1)
        }

        setSymptomsData(tempsymptomsData)
        setConsultantData(tempconsultantData)
        setSendData(tempSenddata)
        // SaveIPDData(tempSenddata, "jsonDiagnosis")
    }

    let getdata = async (value) => {
        let response = await GetProblemList(value)
        let data = await GetDiagnosis(activeUHID)
        if (data.status === 1) {
            let modifydata = data.responseValue.map(val => { return { "problemId": val.pmID, "problemName": val.details, "pdmId": val.pdmID } })
            setSendData(modifydata)
            setConsultantData(modifydata)
        }
        if (response.status === 1) {
            setProblemList(response.responseValue, data.responseValue)
            setProblemListTemp(response.responseValue, data.responseValue)
        }
    }
    let saveDiagnosisList = async () => {
        if(document.getElementById("provisionalDiagnosis"))
        {
            
            sendData.map(val => val["isProvisionalDiagnosis"] = document.getElementById("provisionalDiagnosis").checked)
        }
        console.log(sendData)


        let resp = await PostDiagnosisData(activeUHID, JSON.stringify(sendData))
        if (resp.status === 1) {
            console.log("success")
        }
        else {
            setShowAlert(1)
            console.log("cnsdac", resp)
            setAlertmsg(resp.responseValue)
        }
    }
    useEffect(() => {
        if (saveDiagnosis !== 1) {

            getdata()
        }
        else {
            saveDiagnosisList()
            setSaveDiagnosis(0)
        }
    }, [saveDiagnosis])



    return (
        <>
            <div className='diag-cnt h-25 row'>
                <div className='d-none img-text-box-back-opd diag-inn col-md-3 col-12' style={{ whiteSpace: 'nowrap' }}>
                    <div className='diagImg d-flex flex-row h-100 justify-content-center align-items-center'>
                        {/* <div><img src={ProvisionalDiagonisys} className='compcon' alt='' /></div> */}
                        {
                            isProvisional === 0 ?

                                <div><label className='cheifc'>Consultant Diagnosis</label></div>:
                                <div><label className='cheifc'>Provisional Diagnosis</label></div>
                    }

                    </div>
                    {/* {
                        isProvisional === 1 ?
                            <div className="d-flex regularCheck column-gap-2 align-items-center mt-1 justify-content-end">
                                <label className='fw-lighter fst-italic provi' htmlFor='provisionalDiagnosis'>Provisional Diagnosis</label>
                                <div className="form-check"><input className="form-check-input" type="checkbox" id="provisionalDiagnosis" defaultChecked={isProvisional === 1 ? true : false} /></div>
                            </div> : ""
                    } */}

                </div>
                <div className='diag-inn col-md-6 col-12' style={{ flexGrow: '1', }}>
                    <input autoComplete="off" type="text" className='text-box-opd ' placeholder='Consultant Diagnosis' name='consultantData' id="consultantData" onKeyDown={handleKeyPress} onChange={(e) => { handleSearch(e) }} />
                    {showSearchBoxProblemConsultant === 1 ?
                        <div id="consultantDataListdiv" className='position-absolute opdmedicationsearchbox '>
                            <ul id="consultantDataList">
                                {problemListTemp && problemListTemp.map((val, ind) => {
                                    return (
                                        [1, 2, 3, 4].map((id, ind) => {
                                            if (val.problemTypeID === id) {
                                                return (

                                                    <li className='pointer' onClick={(e) => { handleClick("consultantData", val.id, val.problemName) }}>{val.problemName}</li>
                                                )
                                            }
                                        })
                                    )
                                })}
                            </ul>
                            <div className='full-screen-div-opd' onClick={() => { setShowSearchBoxProblemConsultant(-1) }}></div>

                        </div>
                        : ""}

                    <div className='d-flex flex-wrap gap-2 ' style={{ overflowX: "auto", height: "50px", }}>
                        {
                            consultantData && consultantData.map((val, ind) => {
                                return (
                                    <div className='d-flex flex-row justify-content-center align-items-center gap-2 ps-0 pe-1 opdcancletab'>
                                        <label className=''>{val.problemName[0].toUpperCase() + val.problemName.slice(1, val.problemName.length).toLowerCase()}</label>
                                        <i className="fa-solid fa-xmark" onClick={() => { handleRemove(ind, val.problemId, "consultantData", val.problemName) }}></i>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>


            </div>
            {
                showAlert === 1 ? <AlertToster handle={setShowAlert} message={alertmsg} /> : ""
            }
        </>
    )
}
