import React, { useEffect, useState } from 'react'
import "./assests/css/style.css"
import "../ScifiDashboard/assests/css/PatientDetailsDigitalTwin.css"
import heartRateGif from "./assests/images/heartRate.gif"
import textGif from './assests/images/backtext.gif'



import InfoCotainer from './Component/InfoCotainer'
import TopGraphSection from './LeftSection/TopGraphSection'
import MiddleInvestigationSection from './LeftSection/MiddleInvestigationSection'
import BottomVitalSection from './LeftSection/BottomVitalSection'
import ExperimentModeSection from './RightSection/ExperimentModeSection'
import RadiologySection from './RightSection/RadiologySection'
import NotificationSection from './RightSection/NotificationSection'
import PatientDetails from './MiddleSection/PatientDetails'
import VentilatorSection from './MiddleSection/VentilatorSection'
import CannulaSection from './MiddleSection/CannulaSection'
import BodySection from './MiddleSection/BodySection'
import IOSection from './MiddleSection/IOSection'
import { HubConnectionBuilder } from '@microsoft/signalr'
import * as signalR from "@microsoft/signalr"
import TimeCalculate from '../PatientMonitorDashboard/Code/TimeCalculate'
import AmoniaResult from './Component/AmoniaResult'

export default function ScifiDashboard({ uhid }) {

  let [connection, setConnection] = useState(null);
  let [patientdata, setPatientData] = useState([])
  let [patientDiagonsisData, setPatientDiagonsisData] = useState([])
  let [patientMedicineData, setPatientMedicineData] = useState([])
  let [patientVentilatorData, setPatientVentilatorDataData] = useState([])
  let [patientIOData, setPatientIOData] = useState([])
  let [patientVitalData, setPatientVitalData] = useState([])
  let [patientInvestigationData, setPatientInvestigationData] = useState([])


  useEffect(() => {
    let makeConnection = new HubConnectionBuilder().withUrl(window.PatientMonitorDashboard + "/PatientDashboard").withAutomaticReconnect().configureLogging(signalR.LogLevel.Information).build();
    setConnection(makeConnection);

    return () => {
      if (makeConnection) {
        makeConnection.stop()
      }
    }
  }, [])

  useEffect(() => {
    if (connection) {
      const startConnection = async () => {
        try {
          await connection.start({ transport: 'longPolling' });
          console.assert(connection.state === signalR.HubConnectionState.Connected);
          console.log("SignalR Connected.");

          getData(connection)
        } catch (err) {
          console.assert(connection.state === signalR.HubConnectionState.Disconnected);
          console.log(err);
          setTimeout(startConnection, 5000);
        }
      };

      startConnection();

      connection.onclose(async error => {
        console.assert(connection.state === signalR.HubConnectionState.Disconnected);
        console.log(`Connection closed due to error "${error}". Try refreshing this page to restart the connection.`);
        await startConnection();
      });
    }
  }, [connection])



  let getData = async () => {
    try {
      if (connection) {

        const response = await connection.invoke("GetPatientDetailsByUHID", window.clientId, window.userId, uhid !== undefined ? uhid : "UHID01248");
        console.log("uhid", response)
        if (response.status === 1) {
          let resp = response.responseValue
          let makeData = {
            "pId": resp.pId,
            "uhId": resp.uhId,
            "pntName": resp.pntName,
            "pntAge": resp.pntAge,
            "pntGender": resp.pntGender,
            "pntPID": resp.pntPID,
            "ptDep": resp.ptDep,
            "ptBed": resp.ptBed,
            "ptAdmitDays": resp.ptAdmitDays,
            "consultant": resp.consultant
          }
          setPatientData(makeData)
          setPatientDiagonsisData(resp.diagonsisList)
          setPatientMedicineData(resp.prescreptionParameterListMedvantage !== null ? resp.prescreptionParameterListMedvantage : resp.prescreptionParameterList)
          setPatientVentilatorDataData(resp.lifeSupportList)
          setPatientIOData([resp.patientInputOutputList, resp.patientAnuria_OliguriaList])
          setPatientVitalData(resp.vitalParametersList)
          setPatientInvestigationData(resp.investigationParameterList)
          console.log("medic", resp)
        }
        else {
          setPatientData([])
          setPatientDiagonsisData([])
          setPatientMedicineData([])
          setPatientVentilatorDataData([])
          setPatientIOData([])
          setPatientVitalData([])
          setPatientInvestigationData([])
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div className='main-container'>

      <div className='d-flex flex-row'>
        <div className='col-4'>
          <div className='d-flex flex-column gap-1 ms-2'>
            <TopGraphSection patientVitalData={patientVitalData} />
            <MiddleInvestigationSection patientInvestigationData={patientInvestigationData} />
            <BottomVitalSection patientInvestigationData={patientInvestigationData} />
          </div>
        </div>
        <div className='col-4 body-container '>
          <div className='text-gif-container'>
            <img src={textGif} className='text-gif' alt='textgif' />
          </div>
          <PatientDetails Patientdata={patientdata} />
          <VentilatorSection patientVentilatorData={patientVentilatorData} />
          <CannulaSection />
          <div className='heartrate-img-container'>
            <img src={heartRateGif} className='heart-rate-img' alt="body-img" />
          </div>
          <BodySection />
          <IOSection patientIOData={patientIOData} />
        </div>
        <div className='col-4'>
          <div className='rt-sec1'>
            <div className='expsec'>
              <ExperimentModeSection />
              <RadiologySection />
            </div>
            <div className='labsdat'>
              <InfoCotainer title="Diagnosis" component={<div className='scrolling-content'>
                {
                  patientDiagonsisData && patientDiagonsisData.map((val, ind) => {

                    return (
                      <p className=' info-paragraph' >
                        {ind + 1}) {val.problemName}.
                      </p>
                    )
                  })
                }
              </div>}>
              </InfoCotainer>
              <InfoCotainer title="Patient History" component={<div className='scrolling-content'>
                {
                  patientMedicineData && patientMedicineData.map((val, ind) => {

                    return (
                      <p className=' info-paragraph' >
                        {ind + 1}) {val.drugName}.
                      </p>
                    )
                  })
                }
              </div>}>
              </InfoCotainer>
              <InfoCotainer title="Medication" component={<div className='scrolling-content'>
                {
                  patientMedicineData && patientMedicineData.map((val, ind) => {

                    return (
                      <p className=' info-paragraph' >
                        {ind + 1}) {val.drugName} &nbsp;{TimeCalculate(val.prescreptionDateTime)}.
                      </p>
                    )
                  })
                }
              </div>}>
              </InfoCotainer>

              <AmoniaResult title="Ammonia Result"/>
              {/* <InfoCotainer title="Diagnosis" data={patientDiagonsisData} />
              <InfoCotainer title="Patient History" />
              <InfoCotainer title="Medication" /> */}
              <NotificationSection />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
