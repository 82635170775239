import React from 'react'
import { useState, useEffect } from 'react'
import imgPrint from '../../assets/images/icons/imgPrint.svg'
import Loder from '../../Component/Loader'
import GetAllLPGDevices from '../API/LpgGasDevices/GetAllLPGDevices';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import { useNavigate } from "react-router-dom";
import GetAllDeviceQualityMonitoring from '../API/DeviceQualityMonitoring/GetAllDeviceQualityMonitoring';




export default function DeviceQualityMonitoring() {
 
  const navigate = useNavigate();


  const {t} = useTranslation();
  document.body.dir = i18n.dir();

  const [QualityMonitoringList, setQualityMonitoringList] = useState([])
  const [showLoder, setShowLoder] = useState(0);
  

  const ListData = async()=>{
    let data = await GetAllDeviceQualityMonitoring()
    if(data.status === 1){
        setQualityMonitoringList(data.responseValue)
        console.log("data" ,data.responseValue)
    }
  }


  useEffect(() => {
    ListData()
  }, [])




  return (
    <section className="main-content pt-3 mt-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mb-2">
            <div className="med-box">
              <div className="title mx-2">Device Quality Monitoring</div>
            </div>
          </div>

          <div className="col-12">
            <div className="med-box">
              <div className="title mx-2">Device Quality Monitoring List</div>
              <div className="med-table-section mt-4" style={{ height: 'calc(100vh - 190px)' }}>
                <table className="med-table border striped">
                  <thead>
                    <tr className='table-primary' style={{ color: 'black' }}>
                      <th className="text-center" style={{ width : '5%'}} >{t("#")}</th>
                      <th title='Device Type'>Device Type </th>
                      <th title='Mac Address'>Mac Address</th>
                      <th title='Particulate Matter 1 concentration'>Pm1</th>
                      <th title='Particulate Matter 2.5 concentration'>Pm2_5</th>
                      <th title='Particulate Matter 10 concentration'> Pm10</th>
                      <th title='Particulate Matter 1 in air'>Pm1_air</th>
                      <th title='Particulate Matter 2.5 in air '>Pm2_5_air</th>
                      <th title='Particulate Matter 10 in air'>Pm10_air</th>
                      <th title='Particles with diameter 0.3 micrometers'>Particles_0_3</th>
                      <th title='Particles with diameter 0.5 micrometers'>particles_0_5</th>
                      <th title='Particles with diameter 1.0 micrometers'>particles_1_0</th>
                      <th title='Particles with diameter 2.5 micrometers'>particles_2_5</th>
                      <th title='Particles with diameter 5.0 micrometers'>particles_5_0</th>
                      <th title='Particles with diameter 10.0 micrometers'>particles_10_0</th> 
                      <th title='Total Volatile Organic Compounds(ppb)'>tvoc</th>
                      <th title='CO2 equivalents(ppm)'>eco2</th>
                      <th title='Temperature'>Temperature</th>
                      <th title='Humidity'>Humidity</th>
                       <th title='Gas sensor raw resistance value'>rs_0</th>
                      <th title='Gas sensor raw resistance value'>rs_2</th>
                      <th title='Gas sensor raw resistance value '>rs_3</th>
                      <th title='Air Quality Index'>aqi</th>
                      <th title='AQI String'>aqi_Str</th> 
                      <th>Quality Status</th>
                      
                
                    </tr>
                  </thead>
                  <tbody>
                    {QualityMonitoringList && QualityMonitoringList.map((data, index) => {

                      return (
                        <tr>
                          <td className="text-center">{index + 1}</td>
                          <td>{data.deviceType}</td>
                          <td>{data.macAddress}</td>
                          <td>{data.pm1 + 'μg/m³'}</td>
                          <td>{data.pm2_5 + 'μg/m'}</td>
                          <td>{data.pm10 + 'μg/m³'}</td>
                          <td>{data.pm1_air + 'μg/m³'}</td>
                          <td>{data.pm2_5_air + 'μg/m³'}</td>
                          <td>{data.pm10_air + 'μg/m³'}</td>
                         
                         
                        
                          <td title='Particle Count'>{data.particles_0_3 + 'PC'}</td>
                          <td title='Particle Count'>{data.particles_0_5 + 'PC'}</td>
                          <td title='Particle Count'>{data.particles_1_0 + 'PC'}</td>
                          <td title='Particle Count'>{data.particles_2_5 + 'PC'}</td>
                          <td title='Particle Count'>{data.particles_5_0 + 'PC'}</td>
                          <td title='Particle Count'>{data.particles_10_0 + 'PC'}</td>
                          <td>{data.tvoc + 'ppb'}</td>
                          <td>{data.eco2 + 'ppm'}</td>
                          <td>{data.temp + 'C'}</td>
                          <td>{data.humidity + '%'}</td>
                          <td>{data.rs_0 + 'Ω'}</td>
                          <td>{data.rs_2 + 'Ω'}</td>
                          <td>{data.rs_3 + 'Ω'}</td>
                          <td>{data.aqi}</td>
                          <td>{data.aqi_str}</td>
                          <td>{data.qualityStatus == 1 ? 'Good' : data.qualityStatus == 2 ? 'Moderate' : data.qualityStatus == 3 ? 'Poor' : data.qualityStatus == 4 ? 'Unhealthy' : data.qualityStatus }</td>
              
                     
   


                          <td>
                        
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="col-12 mt-4">

      </div>


      {/* -----------------------Start Delete Modal Popup-------------------    */}


      {/* -----------------------End Delete Modal Popup---------------------  */}
      {
        showLoder === 1 ? <Loder val={showLoder} /> : ""
      }


    </section>


  )
}
