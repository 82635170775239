async function UpdateProcedureStatus(data) {

    let url = window.OTBaseURL + 'ProcedureWithoutSedation/UpdateProcedureStatus?id='+data.id
    let head = {
        'Content-Type': 'application/JSON',
        accept: '*/*',
    }
    let response =
        await fetch(url, {
            method: 'POST',
            headers: head,
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then(data)


    return response;
}
export default UpdateProcedureStatus;
