import React, { useState, useEffect } from 'react'
import Heading from '../../../Component/Heading';
import GetUserProductivity from '../../API/AdminDashBoard/GetUserProductivity';
import noData from '../../../assets/images/icons/No data-rafiki.svg';
const DeveloperProductivity = () => {
    const [getDeveloperProductvityList, setDeveloperProductvityList] = useState([]);
    const funGetDeveloperProductivity = async () => {
            const {status, responseValue} = await GetUserProductivity(window.userId); 
            status ? setDeveloperProductvityList(responseValue) : setDeveloperProductvityList([]);
        }
    useEffect(()=>{
        funGetDeveloperProductivity();
    },[]);
    return (
        <>
            <Heading text='Developer Productivity' />
            <div className='bacl_ dashBoardProgressBar' style={{ height: '42vh', overflow: 'auto' }}>
            {getDeveloperProductvityList.length > 0 ?
                getDeveloperProductvityList && getDeveloperProductvityList.map((val, ind) => {
                    const progressBarStyle = {
                        width: `${val.productivity}%`
                    };
                    return (
                        <>
                            <div className='progTop'>
                                <p className='devName'>{val.name}</p>
                            </div>
                            <div className="progress mt-3" role="progressbar" >
                                <div className="progress-bar" style={progressBarStyle}>{val.productivity + ' %'}</div>
                            </div>
                        </>
                    )
                }) 
                :
                <img className='haveNodataImg' src={noData} alt="No Data" />
            }
            </div>
        </>
    )
}

export default DeveloperProductivity;