async function GetWorkFlowMaster(ClientID) {
    let url = window.UserbaseUrl + "/api/WorkFlowMaster/GetAllWorkFlowMaster?ClientId=" + ClientID;
    // let url ="https://localhost:7235/api/WorkFlowMaster/GetAllWorkFlowMaster?ClientId=" + ClientID;
  let head = { "Content-Type": "application/JSON", accept : '*/*' };
  
  let response = await fetch(url, {
    headers: head,
    method: 'GET'
  })
    .then((res) => res.json())
    .then();

  return response;
}
  export default GetWorkFlowMaster;