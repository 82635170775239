import React from 'react'
import ventilatorGif from "../assests/images/ventiGif.gif"
import usercropGif from "../assests/images/cropuserGIF.gif"
import amonia from "../assests/images/amonia.png"

import rtfeed from "../assests/images/rtfeed.png"
import abg from "../assests/images/abg.png"
import nord from "../assests/images/nord.png"
import fenta from "../assests/images/fenta.png"
import temp from "../assests/images/temp.png"
export default function AmoniaResult(props) {

    return (
        <div className='info-container'>
            <div className='info-data-container'>
                <img src={ventilatorGif} className='infoGif' alt='infoGif' />
                <div className='d-flex flex-row align-items-center gap-3'>
                    <span className='infoHeading'>{props.title}
                    </span>
                    <img src={usercropGif} className='userDiagnosis' alt='userGif' />
                </div>
                <div className='pt-2 scrolling-container1'>
                    {/* {props.component} */}
                    <ul className='amoniares-in'>
                      <li>
                        <div className='ampic'><img src={amonia} alt=''/></div>
                        <div className='amres'>performed on 14-06-2023 02:16AM</div>
                      </li>

                      <li>
                        <div className='ampic'><img src={rtfeed} alt=''/></div>
                        <div className='amres'>
                        <div className='rte'><span>RT Feed on hold</span><span>Hold From : 26/06/2023 10:39 AM</span></div>
                        Remark : POST OP, RT HOLD Type : Post opt, before every 
                        feed  cinnamon fine crushed 5gm to be given in 10ml water along with Diadzine
                         (soflavone) and ECG</div>
                      </li>

                      <li>
                        <div className='ampic'><img src={abg} alt=''/></div>
                        <div className='amres'>ABG is not performed with in 6 hours</div>
                      </li>

                      <li>
                        <div className='ampic'><img src={nord} className='ns' alt=''/></div>
                        <div className='amres'>Infusion NORAD running at Flow Rate : 5.40ml/hr</div>
                      </li>

                      <li>
                        <div className='ampic'><img src={fenta} className='ns' alt=''/></div>
                        <div className='amres'>Infusion FENTA running at Flow Rate : 2ml/hr</div>
                      </li>
                      <li>
                        <div className='ampic'><img src={temp} alt=''/></div>
                        <div className='amres'>Normal or Low grade fever, Value : <strong style={{color:'#65FF6C'}}>98.8F</strong></div>
                      </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}