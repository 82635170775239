import React, { useState, useEffect } from 'react'
import TableContainer from '../../../../src/Component/TableContainer';
import Heading from '../../../../src/Component/Heading';
import BoxContainer from '../../../../src/Component/BoxContainer';
import Toster from '../../../../src/Component/Toster'
import TosterUnderProcess from '../../../../src/Component/ToStartUnderProcess'
import PostGodownMaster from '../API/PostGodownMaster'
import PutGodownMaster from '../API/PutGodownMaster'
import DeleteGodownMaster from '../API/DeleteGodownMaster'
import ValidationGodownMaster from '../../../../src/Validation/Pharmacy/ValidationGodownMaster'
import GetGodownMaster from '../API/GetGodownMaster';
import IconEdit from '../../../assets/images/icons/IconEdit.svg'
import IconDelete from '../../../assets/images/icons/IconDelete.svg'
import { useTranslation } from 'react-i18next';
import  i18n from "i18next";
import clearIcon from '../../../assets/images/icons/clear.svg';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import GetLocationMaster from '../../../../src/Admin/Api/Master/LocationMaster/GetLocationMaster'
import DropdownWithSearch from '../../../Component/DropdownWithSearch'
import Select from 'react-select';

export default function GodownMaster() {

  let [godownList, setGodownList] = useState()
  let [godownValue, setGodownValue] = useState()
  let [editLocation, setEditLocation] = useState()
  let [updateBool, setUpdateBool] = useState(0)
  let [sendForm, setSendForm] = useState({ "userId": window.userId })
  let [loder, setLoder] = useState(1)
  let [rowId, setRowId] = useState('');
  let [clearDropdown, setClearDropdown] = useState(0)
  let [showUnderProcess, setShowUnderProcess] = useState(0)
  let [showToster, setShowToster] = useState(0)
  let [tosterMessage, setTosterMessage] = useState("")
  let [tosterValue, setTosterValue] = useState(0)
  const {t} = useTranslation();
  const [newlyAddedRowIndex, setNewlyAddedRowIndex] = useState(null);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [LocationDropdown, setLocationDropdown] = useState([])
  const [LocationName, setLocationName] = useState(null)
  const [isClearable,] = useState(true);
  const [isSearchable,] = useState(true);
  const [LocationIDDropdown, SetLocationIDDropdown] = useState([])
  const [LocationID, setLocationID] = useState(null)
  const [showLoder, setShowLoder] = useState(0);


  //Handle Save
  let saveForm = async () => {
// let locationId = LocationID
// sendForm["locationId"] = LocationID;
console.log("sendForm",sendForm)
if(sendForm.godown == '' ||sendForm.godown == undefined ){
  setTosterValue(1)
  setTosterMessage("Please Enter Godown Name!")
  setShowToster(1)
  setTosterValue(1)
  setTimeout(() => {
    setShowToster(0)
    setNewlyAddedRowIndex(null);
  }, 2000)
  return
}
if(LocationID == null){
  setTosterValue(1)
  setTosterMessage("Please Select Location!")
  setShowToster(1)
  setTosterValue(1)
  setTimeout(() => {
    setShowToster(0)
    setNewlyAddedRowIndex(null);
  }, 2000)
  return
}


   
      setShowUnderProcess(1)
      let response = await PostGodownMaster(sendForm);
      console.log("sendForm", sendForm)
      if (response.status === 1) {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage("Data Save Successfully!")
        setTosterValue(0)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
        setNewlyAddedRowIndex(0);
        handleClear();
      }
      else {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage(response.responseValue)
        setTosterValue(1)
        setTimeout(() => {
          setShowToster(0)
          setNewlyAddedRowIndex(null);
        }, 2000)
      }
      getdata()
    
  
  }

  //Get data
  let getdata = async () => {
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    let getResponse = await GetGodownMaster(); 
    let Location = await GetLocationMaster(clientID)
    // console.log("getlocation", getlocation)

    if (getResponse.status === 1) {
      setGodownList(getResponse.responseValue)
    }

    // if (getlocation.status === 1) {
    //   setLocationDropdown(getlocation.responseValue.map(location=>({
    //     value : location.id,
    //     label: `${location.floorName} Floor ${location.buildingName} Building ${location.roomNumber}`
      
    //   })));
    //   }

    if (Location.status === 1) {
      setShowLoder(0);
      SetLocationIDDropdown(Location.responseValue.map(location => ({
        value: location.id,
        label: `${location.floorName} Floor ${location.buildingName} Building ${location.roomNumber}`

      })));
      //  console.log("Location",Location.responseValue)
    }

  }
  //Handle Change

  // const handleSelectChange = (selectedOption, errorElementId, setSelectedFunction) => {
  //   document.getElementById(errorElementId).style.display = 'none';
  //   setSelectedFunction(selectedOption);
  //   console.log("selectedOption",selectedOption)
  //   setLocationName(selectedOption.value)
  // };

  const handleSelectChange = (selectedOption, errorElementId, setSelectedFunction) => {
   
    document.getElementById(errorElementId).style.display = 'none';
    setSelectedFunction(selectedOption);
if(selectedOption !==null){
   setSendForm(sendForm => ({
      ...sendForm,
      locationId: selectedOption.value
     
    }))
}
   
  };


  let handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;


 
    setSendForm(sendForm => ({
      ...sendForm,
      [name]: value
    }))

  
  }



  //Handle Delete
  let handleDeleteRow = async () => {
    // setLoder(1)
    setShowUnderProcess(1)
    let obj = {
      id: rowId
    }
    let response = await DeleteGodownMaster(obj)
    if (response.status === 1) {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage("Data Deleted Successfully!")
      handleClear()
      setUpdateBool(0)
      setTosterValue(0)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
      getdata()
      setClearDropdown(0)
    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage(response.responseValue)
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
    }
  }

  //Handle Button Change
  let handleUpdate = async (id, godown, locationId, locationID, userId, buildingName, floorName, roomNumber) => {

    
    setUpdateBool(1)
    setSendForm(sendForm => ({
      ...sendForm,
      "id": id,
      "godown": godown,
      'locationId': locationId,
      "userId": userId

    }))
   
    console.log("sendForm",sendForm)
    setLocationID({
      value: locationID,
      label: `${floorName} Floor ${buildingName} Building ${roomNumber}`
    })
    document.getElementById("godown").value = godown;
    
  }


  // Handle Update
  let saveUpdate = async () => {
    if(sendForm.godown == '' ||sendForm.godown == undefined ){
      setTosterValue(1)
      setTosterMessage("Please Enter Godown Name!")
      setShowToster(1)
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
        setNewlyAddedRowIndex(null);
      }, 2000)
      return
    }
    if(LocationID == null){
      setTosterValue(1)
      setTosterMessage("Please Select Location!")
      setShowToster(1)
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
        setNewlyAddedRowIndex(null);
      }, 2000)
      return
    }
    console.log("sendForm",sendForm)

      setShowUnderProcess(1)
      let response = await PutGodownMaster(sendForm)
      if (response.status === 1) {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage("Data Updated Successfully!")
        setTosterValue(0)
        setTimeout(() => {
          setShowToster(0)
          setNewlyAddedRowIndex(null);
        }, 2000)

        setUpdateBool(0)
        getdata()
        handleClear();
      }
      else {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage(response.responseValue)
        setTosterValue(1)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
      }

    
   
  }

  //Handle Clear
  let handleClear = (value) => {
    setSendForm({ "userId": window.userId })
    document.getElementById("godown").value = "";
    setUpdateBool(0)
    
    setNewlyAddedRowIndex(null);
    setLocationID(null)
    
  }
  useEffect(() => {
    getdata()
  }, [])
  document.body.dir = i18n.dir();
  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Heading text={t("Godown Master")} />
              <div className="med-box">
                        <div className="inner-content">
                           
                                <div className="row">
                                     <div className="fieldsett-in">
                                     <div className='fieldsett'>
                                    <span className="fieldse">Godown Master</span>
                                    <div className="row mt-2 px-2">
                                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-2 me-2">
                  <label htmlFor="godown" className="form-label">{t("Godown Name")}<span class="starMandatory">*</span></label>
                  <input type='text' className='form-control form-control-sm' id='godown' name='godown' onChange={handleChange} placeholder={t("Enter Godown Name")} />
                </div>

                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-2 me-2">
                      <label htmlFor="ddlitemmaster" className="form-label ">{t("Location")}<span className="starMandatory">*</span></label>
                      <Select value={LocationID} options={LocationIDDropdown} className=" create-select" id="locationId" name='locationId' placeholder={t("Choose_Location")} isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, "errLocationName", setLocationID)} />
                      <small id="errLocationName" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>

                <div className="d-flex justify-content-end mt-4 mb-2 relative">
                  <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                  <div>
                    {showUnderProcess === 1 ? <TosterUnderProcess /> :
                      <>
                        {showToster === 1 ?
                          <Toster value={tosterValue} message={tosterMessage} />

                          : <div>
                            {updateBool === 0 ?
                              <>
                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={saveForm}><img src={saveButtonIcon} className='icnn' />{t("Save")}</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' />{t("Clear")}</button>
                              </>
                              :
                              <>
                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={saveUpdate}>{t("Update")}</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { setUpdateBool(0); handleClear() }}>{t("Cancel")}</button>
                              </>
                            }
                          </div>}
                      </>
                    }
                  </div>
                </div>
                                      </div>
                                      </div>
                                      </div>
                                      </div>
                                      </div>
                                      </div>

          
            </div>
            <div className="col-12 mt-2">
              <Heading text={t("Godown List")} />
              <div className="med-table-section" style={{ "height": "75vh" }}>
                <TableContainer>
                  <thead style={{zIndex:'0'}}>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>{t("Godown Name")}</th>
                      <th>{t("Location")}</th>
                      <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {godownList && godownList.map((key, ind) => {
                     
                      return (
                        <tr value={key}>
                          <td className="text-center">{ind + 1}</td>
                          <td>{key.godown}</td>
                          <td>{key.buildingName + " " + key.floorName + " " + key.roomNumber}</td>
                          <td>
                            <div className="action-button">
                              <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={IconEdit} onClick={() => { handleUpdate(key.id, key.godown, key.locationId, key.LocationID, key.userId, key.buildingName, key.floorName, key.roomNumber) }} alt='' /></div>
                              <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={IconDelete} onClick={() => { setRowId(key.id) }} alt='' /></div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}


                  </tbody>
                </TableContainer>
                {/* -----------------------Start Delete Modal Popup-------------------   */}

                {/*  <!-- Modal -->  */}
                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                  <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                      <div className="modal-body modelbdy text-center">
                        <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                        <div className='popDeleteTitle mt-3'>{t("Delete?")}</div>
                        <div className='popDeleteContent'> {t("Are_you_sure_you_want_to_delete?")}</div>
                      </div>
                      <div className="modal-footer1 text-center">

                        <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                        <button type="button" className="btn-delete popBtnDelete" onClick={handleDeleteRow} data-bs-dismiss="modal">{t("Delete")}</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  )
}
