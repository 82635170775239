import React from 'react'
import radiologyGif from "../assests/images/radiologyGIF.gif"
import smoking from "../assests/images/smoking.png"
import drinking from "../assests/images/drinking.png"
export default function RadiologySection() {
    return (
        <>
            <div className='radiology'>
                <img src={radiologyGif} className='radiologyGif' alt="" />
                <span className='radiologyText'>RADIOLOGY</span>
            </div>

            <div className='activity-cn'>
                <div className='act-in'>
                    <div className='act'><img src={smoking} alt=''/></div>
                    <div className='act'>Smoking</div>
                </div>
                <div className='act-in'>
                    <div className='act'><img src={drinking} alt=''/></div>
                    <div className='act'>Drinking</div>
                </div>
            </div>
        </>
    )
}
