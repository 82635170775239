import React, { useEffect, useState } from 'react'
import Loader from '../../../Component/Loader'
import SuccessToster from '../../../Component/SuccessToster'
import AlertToster from '../../../Component/AlertToster'
import TosterUnderProcess from '../../../Component/TosterUnderProcess'
import Toster from '../../../Component/Toster'
import DropdownWithSearch from '../../../Component/DropdownWithSearch'
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg'
import iconStar from '../../../assets/images/icons/iconStar.svg'
import Heading from '../../../Component/Heading';
import plussign from '../../../assets/images/dmsimg/plus_sign.png';
import downloadarrow from '../../../assets/images/dmsimg/downloadarrow.png'
import { wrap } from 'highcharts';
import { t } from 'i18next'
import InsertSupplierQuotation from '../../API/SupplierQuotation/InsertSupplierQuotation'
import GetVendorMaster from '../../API/VendorMaster/GetVendorMaster'
import GetInventoryItemMaster from '../../API/CreateNewItem/GetInventoryItemMaster'
import GetBrandMaster from '../../API/Master/BrandMaster/GetBrandMaster'
import GetInventoryUnitMaster from '../../API/Master/InventoryUnitMaster/GetInventoryUnitMaster'
import SearchItem from '../../API/UserDemand/GET/SearchItem'
import GetAdditionalChargeType from '../../API/Master/AdditionalChargeType/GetAdditionalChargeType'

export default function SupplierQuotation() {
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    let [supplierList, setSupplierList] = useState([]);
    let [itemList, setItemList] = useState([]);
    let [brandList, setBrandList] = useState([]);
    let [unitList, setUnitList] = useState([]);
    let [chargeTypeList, setChargeTypeList] = useState([]);
    let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [showToster, setShowToster] = useState(0);
    let [tosterMessage, setTosterMessage] = useState("");
    let [tosterValue, setTosterValue] = useState(0);
    let [showLoder, setShowLoder] = useState(0);
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [updateBool, setUpdateBool] = useState(0);

    let [clearDropdown, setClearDropdown] = useState(0)
    let [editItem, setEditItem] = useState("")



    //Get Data
    const getdata = async () => {
        const [supplierResponse, itemResponse, chargeTypeResponse] = await Promise.all([
            GetVendorMaster(clientID),
            GetInventoryItemMaster(clientID),
            GetAdditionalChargeType(clientID)

        ]);
        if (supplierResponse.status === 1) {
            setSupplierList(supplierResponse.responseValue);
        }
        if (itemResponse.status === 1) {
            const itemdata = itemResponse.responseValue;
            setItemList(itemdata);
        }
        if (chargeTypeResponse.status === 1) {
            const itemdata = chargeTypeResponse.responseValue;
            setChargeTypeList(itemdata);
        }
    }

    const [rows, setRows] = useState([{ id: 1, selectedItem: null }]);
    let [sendForm, setSendForm] = useState({
        "userID": window.userId,
        "clientID": window.clientId,
        "itemID": 0,
        "itemCode": "",
        "brandID": 0,
        "unitID": 0,
        "quotationSubJson": ""
    })

    const newRow = {
        supplierID: 0,
        quantity: "",
        rate: "",
        disPer: "",
        discount: "",
        rateAfterDiscount: "",
        gstPer: "",
        gstAmount: "",
        isGSTIncluded: 0,
        finalAmount: "",
        warrentyExpDuration: "",
        warrentyExpUnit: 0,
        deliveryDuration: "",
        deliveryUnit: 0,
        additionalChargeTypeID: 0,
        additionalAmount: "",
        freightAmount: "",
        isFixedRateQuotation: 0,
        quotationValidity: "",
        discountType: ""
    };

    const handleItemChange = (e) => {
        clearValidationErrMessage();
        const selectedItem = itemList.find(item => item.id === parseInt(e.target.value));
        console.log('selectedItem', selectedItem)
        if (selectedItem) {
            const parsedBrands = JSON.parse(selectedItem.jsonItemBrand || '[]');
            const parsedUnits = JSON.parse(selectedItem.jsonItemUnit || '[]');
            setBrandList(parsedBrands);
            setUnitList(parsedUnits);
            setSendForm({
                ...sendForm,
                itemID: selectedItem.id,
                itemCode: selectedItem.itemCode,
                brandID: 0,
                unitID: 0
            });
        }
    };


    const [quotationRows, setQuotationRows] = useState([newRow]);

    //Handle Change
    let handleChange = (e, index) => {
        clearValidationErrMessage();
        setEditItem();
        // let name = e.target.name;
        // let value = e.target.value;
        const { name, value, checked } = e.target;
        setSendForm(sendForm => ({
            ...sendForm,
            [name]: value
        }))
        if (name === "isGSTIncluded") {
            setRows((updatedRows) => ({
                ...updatedRows,
                [name]: checked,
            }));
        }
        if (name === "isFixedRateQuotation") {
            setRows((updatedRows) => ({
                ...updatedRows,
                [name]: checked,
            }));
        }

        const updatedRows = [...quotationRows];
        if (name === "supplierID" || name === "quantity" || name === "rate" || name === "disPer" || name === "discount"
            || name === "rateAfterDiscount" || name === "gstPer" || name === "gstAmount" || name === "isGSTIncluded"
            || name === "finalAmount" || name === "warrentyExpDuration" || name === "warrentyExpUnit" || name === "deliveryDuration"
            || name === "deliveryUnit" || name === "additionalChargeTypeID" || name === "additionalAmount"
            || name === "freightAmount" || name === "isFixedRateQuotation" || name === "quotationValidity" || name === "discountType") {
            updatedRows[index][name] = value;

            if (name === "quantity" || name === "rate" || name === "disPer" || name === "discount" || name === "gstPer" || name === "gstAmount" || name === "finalAmount") {
                let discount = 0;
                let quantity = parseFloat(updatedRows[index].quantity || 0);
                let rate = parseFloat(updatedRows[index].rate || 0);
                let gstPer = parseFloat(updatedRows[index].gstPer || 0);
                let disPer = parseFloat(updatedRows[index].disPer || 0);
                let totalAmmountSub = parseFloat(quantity * rate);


                if (updatedRows[index]["discountType"] !== "discountTypeRupee") {
                    discount = (parseFloat(totalAmmountSub) * (disPer / 100)).toFixed(2);
                } else {
                    discount = value;
                }
                updatedRows[index].discount = parseFloat(discount);
                let rateAfterDiscount = parseFloat(totalAmmountSub - discount)
                updatedRows[index].rateAfterDiscount = parseFloat(rateAfterDiscount);

                let gstAmount = (rateAfterDiscount * (gstPer / 100)).toFixed(2);
                updatedRows[index].gstAmount = parseFloat(gstAmount);

                let finalAmount = parseFloat(rateAfterDiscount) + parseFloat(gstAmount)
                updatedRows[index].finalAmount = parseFloat(finalAmount);
            }
            if (name === "discountType") {
                if (value === "discountTypeRupee") {
                    updatedRows[index]["disPer"] = "";
                    updatedRows[index]["discount"] = "";
                    document.getElementById("disPerErr_" + index).style.display = "none";
                    document.getElementById("disPer_" + index).disabled = true;
                    document.getElementById("discount_" + index).disabled = false;
                } else {
                    updatedRows[index]["disPer"] = "";
                    updatedRows[index]["discount"] = "";
                    document.getElementById("disPerErr_" + index).style.display = "none";
                    document.getElementById("disPer_" + index).disabled = false;
                    document.getElementById("discount_" + index).disabled = true;
                }
                updatedRows[index]["discountType"] = value;
            }

            if (name === "isGSTIncluded") {
                console.log('checked : ', checked)
                if (checked) {
                    console.log('quotationRows', quotationRows[index])
                    const finalAmount = quotationRows[index].finalAmount;
                    const gstAmount = quotationRows[index].gstAmount;
                    quotationRows[index].finalAmount = finalAmount - gstAmount;
                    quotationRows[index].gstPer = "";
                    quotationRows[index].gstAmount = "";
                    document.getElementById("gstPer_" + index).disabled = true;
                } else {
                    document.getElementById("gstPer_" + index).disabled = false;
                }
            }

            if (name === "supplierID" && value !== "0") {
                document.getElementById(`supplierErr_${index}`).style.display = 'none';
            }
            if (name === "quantity" && value.trim() !== "") {
                document.getElementById(`quantityErr_${index}`).style.display = 'none';
            }
            if (name === "rate" && value.trim() !== "") {
                document.getElementById(`rateErr_${index}`).style.display = 'none';
            }
        }


        setRows(updatedRows)
    }
    //add and remove function supplier Quotation rows
    const addQuotationRow = () => {
        setQuotationRows((prevRows) => [...prevRows, newRow]);
    };

    const removeQuotationRow = (ind) => {
        let updateRow = [...quotationRows]
        if (quotationRows.length === 1) {
            updateRow.splice(ind, 1)
            setQuotationRows([newRow])
        }
        else if (quotationRows.length > 1) {
            updateRow.splice(ind, 1)
            setQuotationRows([...updateRow])
        }
    };


    //Validation For rows on save

    const validateRows = () => {
        let isValid = true;
        quotationRows.forEach((row, index) => {

            if (row.supplierID === 0) {
                document.getElementById(`supplierErr_${index}`).style.display = 'block';
                isValid = false;
            }

            else if (row.quantity === "" || row.quantity.trim().length === 0) {
                document.getElementById(`quantityErr_${index}`).style.display = 'block';
                isValid = false;
            }

            else if (row.rate === "" || row.rate.trim().length === 0) {
                document.getElementById(`rateErr_${index}`).style.display = 'block';
                isValid = false;
            }


        });
        return isValid;
    };


    //Handle Save
    const handlerSave = async () => {

        if (!validateRows()) {
            return;
        }
        const quotationData = rows.map((row, index) => {
            return {
                supplierID: parseInt(rows[index].supplierID),
                quantity: parseInt(rows[index].quantity),
                rate: parseFloat(rows[index].rate),
                discount: rows[index].discount,
                rateAfterDiscount: rows[index].rateAfterDiscount,
                gstPer: parseInt(rows[index].gstPer),
                gstAmount: parseFloat(rows[index].gstAmount),
                isGSTIncluded: rows[index].isGSTIncluded === "on" ? 1 : 0,
                finalAmount: rows[index].finalAmount,
                warrentyExpDuration: parseInt(rows[index].warrentyExpDuration),
                warrentyExpUnit: parseInt(rows[index].warrentyExpUnit),
                deliveryDuration: parseInt(rows[index].deliveryDuration),
                deliveryUnit: parseInt(rows[index].deliveryUnit),
                additionalChargeTypeID: parseInt(rows[index].additionalChargeTypeID),
                additionalAmount: parseFloat(rows[index].additionalAmount),
                freightAmount: parseFloat(rows[index].freightAmount),
                isFixedRateQuotation: rows[index].isFixedRateQuotation === "on" ? 1 : 0,
                quotationValidity: rows[index].quotationValidity,
            }
        });

        if (sendForm.itemID === '' || sendForm.itemID === null || sendForm.itemID === undefined || sendForm.itemID === 0 || sendForm.itemID === '0') {
            document.getElementById('errItem').innerHTML = "Select item";
            document.getElementById('errItem').style.display = "block";
        }

        else {
            setShowUnderProcess(1);
            const obj = {
                "userId": window.userId,
                "clientId": window.clientId,
                itemID: parseInt(sendForm.itemID),
                itemCode: sendForm.itemCode,
                brandID: parseInt(sendForm.brandID),
                unitID: parseInt(sendForm.unitID),
                quotationSubJson: JSON.stringify(quotationData)
            }
            console.log("quotationObj", obj)
            return;
            const response = await InsertSupplierQuotation(obj);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Data saved successfully..");
                setTimeout(() => {
                    setShowToster(0);
                    handleClear();
                }, 1500)
                setSendForm({});
                setRows([newRow]);
            }
            else {
                setShowUnderProcess(0);
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTimeout(() => {
                    setShowToster(0);
                }, 1500)
            }
        }
    }

    //handle Clear
    const handleClear = (value, index) => {
        clearValidationErrMessage();
        setClearDropdown(value);
        setEditItem(0)
        setSendForm({
            "userID": window.userId,
            "clientID": window.clientId,
            "itemID": 0,
            "itemCode": "",
            "brandID": 0,
            "unitID": 0,
            "quotationSubJson": ""
        })
        document.getElementById("itemID").value = 0;
        setBrandList("");
        setUnitList("");
        setQuotationRows([newRow]);

    };
    const clearValidationErrMessage = () => {
        document.getElementById('errItem').style.display = 'none';

    }
    useEffect(() => {
        getdata();
    }, [])

    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="med-box">
                                <div className='demandMain-inn p-2'>
                                    <div className="title mt-2 pe-2 ps-2 bordertitle">{t("Add Supplier Quotation")} <img src={iconStar} alt="" /></div>
                                </div>
                                <div className="inner-content mt-2 me-0" >
                                    <div className='row p-2'>
                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                            <label htmlFor="" className="form-label">{t("Item Name")}<span className="starMandatory">*</span></label>
                                            {/* {itemList && <DropdownWithSearch defaulNname={t("Select Item")} name="itemID" list={itemList} valueName="id" displayName="itemName" editdata={editItem} getvalue={handleItemChange} clear={clearDropdown} clearFun={handleClear} />} */}

                                            <select id='itemID' name='itemID' className="form-select form-select-sm"
                                                onChange={handleItemChange}
                                            //  onChange={(event) => handleItemChange(event)}
                                            >
                                                <option value="0" selected>Select Item</option>
                                                {itemList && itemList.map((val, ind) => {
                                                    return (
                                                        <option value={val.id}>{val.itemName}</option>
                                                    )
                                                }
                                                )}
                                            </select>
                                            <small id="errItem" className="form-text text-danger" style={{ display: 'none' }}></small>
                                        </div>

                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3 " >
                                            <label htmlFor="" className="form-label">{t("Item Code")}<span className="starMandatory"></span></label>
                                            <input type="text" className="form-control form-control-sm" id="itemCode" name="itemCode" value={sendForm.itemCode} onChange={handleChange} placeholder="Ictem Code" />
                                            <small id="" className="form-text text-danger" style={{ display: 'none' }}></small>
                                        </div>
                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                            <label htmlFor="" className="form-label">{t("Brand")}<span className="starMandatory"></span></label>
                                            <select className='form-select form-select-sm' id="brandID" name="brandID" onChange={handleChange}>
                                                <option value="0">Select Brand</option>
                                                {brandList?.length > 0 && brandList.map((brand) => (
                                                    <option value={brand.BrandId}>{brand.BrandName}</option>
                                                ))}
                                            </select>

                                            <small id="" className="form-text text-danger" style={{ display: 'none' }}></small>
                                        </div>
                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                            <label htmlFor="challanNo" className="form-label">{t("Unit")}<span className="starMandatory"></span></label>
                                            <select className='form-select form-select-sm' id="unitID" name="unitID" onChange={handleChange}>
                                                <option value="0">Select Unit</option>
                                                {unitList?.length > 0 && unitList.map((unit) => (
                                                    <option value={unit.UnitId}>{unit.UnitName}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>

                                    <div className='quotationrows'>
                                        {quotationRows && quotationRows.map((val, ind) => {
                                            return (
                                                <div className='supplrcontent mt-2 p-2'>
                                                    <div className='headmultirow'>
                                                        <div className="multirowheading mb-2">{t("Supplier Quotation Details")}</div>
                                                        <div onClick={() => removeQuotationRow(ind)}><i className="fa fa-minus"></i></div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                                            <label htmlFor="" className="form-label">{t("Select Supplier")}<span className="starMandatory">*</span></label>

                                                            <select id={`supplierID_${ind}`} name='supplierID' value={val.supplierID} className="form-select form-select-sm" onChange={(event) => handleChange(event, ind)}>
                                                                <option value="0" selected>Select Item</option>
                                                                {supplierList && supplierList.map((val, ind) => {
                                                                    return (
                                                                        <option value={val.id}>{val.vendorName}</option>
                                                                    )
                                                                }
                                                                )}
                                                            </select>
                                                            <small id={`supplierErr_${ind}`} className="form-text text-danger" style={{ display: 'none' }}>Select a supplier</small>
                                                        </div>
                                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                                            <label htmlFor="" className="form-label">{t("Quantity")}<span className="starMandatory">*</span></label>
                                                            <input type="number" className="form-control form-control-sm" id={`quantity_${ind}`} name='quantity' value={val.quantity} placeholder="Enter Quantity" onChange={(event) => handleChange(event, ind)} />
                                                            <small id={`quantityErr_${ind}`} className="form-text text-danger" style={{ display: 'none' }}>Enter Quantity</small>
                                                        </div>
                                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3 " >
                                                            <label htmlFor="" className="form-label">{t("Rate")}<span className="starMandatory">*</span></label>
                                                            <input type="number" className="form-control form-control-sm" id={`rate_${ind}`} name='rate' value={val.rate} placeholder="Enter Rate" onChange={(event) => handleChange(event, ind)} />
                                                            <small id={`rateErr_${ind}`} className="form-text text-danger" style={{ display: 'none' }}>Enter rate</small>
                                                        </div>

                                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                                            <label htmlFor="" className="form-label">{t("Discount")}<span className="starMandatory"></span></label>
                                                            <div className="input-group input-group-sm">
                                                                <input type="number" className="form-control form-control-sm" placeholder="0" style={{ maxWidth: '50px' }} id={`disPer_${ind}`} name='disPer' value={val.disPer} onChange={(event) => handleChange(event, ind)} min="1" max="100" />
                                                                <span className="input-group-text frm-selectper p-0" >
                                                                    <select className='form-select form-select-sm' id={`discountType_${ind}`} name='discountType' onChange={(event) => handleChange(event, ind)} >
                                                                        <option value="discountTypePer">%</option>
                                                                        <option value="discountTypeRupee">&#8377;</option>
                                                                    </select>
                                                                </span>
                                                                <input type="number" className="form-control form-control-sm" style={{ whiteSpace: 'nowrap', minWidth: '80px' }} placeholder="00.00" id={`discount_${ind}`} value={val.discount} name='discount' onChange={(event) => handleChange(event, ind)} />
                                                                <div className="invalid-feedback" id={`disPerErr_${ind}`} style={{ display: 'none' }}>Discount cannot exceed 100%.</div>
                                                                <div className="invalid-feedback" id={`discountErr_${ind}`} style={{ display: 'none' }}>Discount cannot more than total amount.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                                            <label htmlFor="" className="form-label">{t("Rate After Discount")}<span className="starMandatory"></span></label>
                                                            <input type="number" className="form-control form-control-sm" id={`rateAfterDiscount_${ind}`} name='rateAfterDiscount' value={val.rateAfterDiscount} placeholder="Rate After Discount" disabled />
                                                            <small id="" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                        </div>
                                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" >
                                                            <label htmlFor="" className="form-label">{t("GST Included in Rate")}<span className="starMandatory"></span></label>
                                                            <div className="accordion-body">
                                                                <div className="d-flex pe-2">
                                                                    <div className=''>
                                                                        Yes<label className="form-label" for="flexSwitchCheckChecked"> <span className="starMandatory"></span></label>
                                                                    </div>
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" type="checkbox" role="switch" id={`isGSTIncluded_${ind}`} name='isGSTIncluded' onChange={(event) => handleChange(event, ind)} /> No
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3 " >
                                                            <label htmlFor="" className="form-label">{t("GST(%)")}<span className="starMandatory"></span></label>
                                                            <div className="input-group input-group-sm">
                                                                <input type="number" className="form-control form-control-sm" placeholder="0" style={{ maxWidth: '50px' }} id={`gstPer_${ind}`} name='gstPer' value={val.gstPer} onChange={(event) => handleChange(event, ind)} min="1" max="100" />

                                                                <input type="number" className="form-control form-control-sm" style={{ whiteSpace: 'nowrap', minWidth: '80px' }} placeholder="00.00" id={`gstAmount_${ind}`} value={val.gstAmount} name='gstAmount' onChange={(event) => handleChange(event, ind)} disabled />

                                                            </div>


                                                        </div>
                                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3 " >
                                                            <label htmlFor="" className="form-label">{t(" Final Amount")}<span className="starMandatory"></span></label>
                                                            <input type="number" className="form-control form-control-sm" id={`finalAmount_${ind}`} name='finalAmount' value={val.finalAmount} placeholder=" Final Amount" onChange={(event) => handleChange(event, ind)} disabled />
                                                            <small id="" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3 d-flex flex-wrap gap-0" >
                                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-3" >
                                                                <label htmlFor="" className="form-label">{t("Warranty/Expiry")}<span className="starMandatory"></span></label>
                                                                <div className='daymontsupr d-flex'>
                                                                    <input type="text" className="form-control form-control-sm" id={`warrentyExpDuration_${ind}`} name='warrentyExpDuration' value={val.warrentyExpDuration} onChange={(event) => handleChange(event, ind)} placeholder="Warranty/Expiry" style={{ borderRight: '0' }} />
                                                                    <select className='form-select form-select-sm' style={{ borderLeft: '0' }} id={`warrentyExpUnit_${ind}`} name='warrentyExpUnit' value={val.warrentyExpUnit} onChange={(event) => handleChange(event, ind)} >
                                                                        <option value="1">Year</option>
                                                                        <option value="2">Months</option>
                                                                        <option value="3">Day</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-3" >
                                                                <label htmlFor="" className="form-label">{t("Delivery Time")}<span className="starMandatory"></span></label>
                                                                <div className='daymontsupr d-flex '>
                                                                    <input type="text" className="form-control form-control-sm" id={`deliveryDuration_${ind}`} name='deliveryDuration' value={val.deliveryDuration} onChange={(event) => handleChange(event, ind)} placeholder="Enter Delivery Time" style={{ borderRight: '0' }} onChange={(event) => handleChange(event, ind)} />
                                                                    <select className='form-select form-select-sm' style={{ borderLeft: '0' }} id={`deliveryUnit_${ind}`} name='deliveryUnit' value={val.deliveryUnit} onChange={(event) => handleChange(event, ind)} >
                                                                        <option value="1">Year</option>
                                                                        <option value="2">Months</option>
                                                                        <option value="3">Day</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-3">
                                                                <label htmlFor="" className="form-label">{t("Additional Charge")}<span className="starMandatory"></span></label>
                                                                <select className='form-select form-select-sm' style={{ color: '#929292' }} id={`additionalChargeTypeID_${ind}`} name='additionalChargeTypeID' value={val.additionalChargeTypeID} onChange={(event) => handleChange(event, ind)} >
                                                                    <option value="">Select Additional Charge</option>
                                                                    {chargeTypeList && chargeTypeList.map((charge, ind) => {
                                                                        return (

                                                                            <option value={charge.id}>{charge.typeName}</option>
                                                                        )
                                                                    })}

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3 pe-2 d-flex flex-wrap gap-0">
                                                            <div className='row'>
                                                                <div className="col-xxl-6 col-xl-3 col-lg-3 col-md-3 mb-3">
                                                                    <label htmlFor="" className="form-label">{t("Additional Charge")}<span className="starMandatory"></span></label>
                                                                    <input type="number" className="form-control form-control-sm" id={`additionalAmount_${ind}`} name='additionalAmount' value={val.additionalAmount} placeholder="Enter Amount" onChange={(event) => handleChange(event, ind)} />
                                                                </div>
                                                                <div className="col-xxl-6 col-xl-3 col-lg-3 col-md-3 mb-3 " >
                                                                    <label htmlFor="" className="form-label">{t("Freight")}<span className="starMandatory"></span></label>
                                                                    <input type="number" className="form-control form-control-sm" id={`freightAmount_${ind}`} name='freightAmount' value={val.freightAmount} placeholder="Enter Freight" onChange={(event) => handleChange(event, ind)} />
                                                                </div>
                                                            </div>

                                                            <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 mb-3 ps-1 me-4 ms-2">
                                                                <label htmlFor="" className="form-label" style={{ textwrap: 'nowrap' }}>{t("Fixed Rate Quotation")}<span className="starMandatory"></span></label>
                                                                <div className="accordion-body">
                                                                    <div className="d-flex pe-2">
                                                                        <div className=''>
                                                                            Yes<label className="form-label" for="flexSwitchCheckChecked"> <span className="starMandatory"></span></label>
                                                                        </div>
                                                                        <div className="form-check form-switch">
                                                                            <input className="form-check-input" type="checkbox" role="switch" id={`isFixedRateQuotation_${ind}`} name='isFixedRateQuotation' onChange={(event) => handleChange(event, ind)} /> No
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 mb-3 ps-2 pe-2" >
                                                                <label htmlFor="" className="form-label">{t("Quotation Valid Till")}<span className="starMandatory"></span></label>
                                                                <input type="date" className="form-control form-control-sm" id={`quotationValidity_${ind}`} name='quotationValidity' value={val.quotationValidity} placeholder="Select Valid Date" onChange={(event) => handleChange(event, ind)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3 ps-0 pe-2">
                                                            <div className='addbtnsuplr d-flex justify-content-end'><button type='button' onClick={addQuotationRow}><img src={plussign} className='icnn' alt='' />Add more supplier quotation</button></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                                <hr />
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 pt-1 relative">
                                    <div className="d-flex flex-wrap justify-content-end gap-3 p-2">


                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />

                                                    : <div>
                                                        <>
                                                            <button type="button" className="btn btn-clear btn-sm me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt='' />{t("Clear")}</button>
                                                            <button type="button" className="btn btn-save btn-save-fill btn-sm me-1" onClick={handlerSave} >{t("Submit Quotation")} <img src={downloadarrow} className='icnn' alt='' /> </button>
                                                        </>

                                                    </div>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {
                    showLoder === 1 ? <Loader val={showLoder} /> : ""
                }
                {/* Toaster */}
                {
                    isShowToaster === 1 ?
                        <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                }

                {
                    showAlertToster === 1 ?
                        <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                }
            </section>


        </>
    )
}
