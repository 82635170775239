import React, { useEffect, useState } from "react";
import TosterUnderProcess from '../../Component/TosterUnderProcess';
import Toster from '../../Component/Toster';
import Loder from '../../Component/Loader';
// import deleteIcon from '../../../../../assets/images/icons/delete.svg'
import GetLightingControl from "../API/LightingControl/GetLightingControl";
import PostLightingControl from "../API/LightingControl/PostLightingControl";
import DeleteLightingControl from "../API/LightingControl/DeleteLightingControl";
import PutLightingControl from "../API/LightingControl/PutLightingControl";
import GetLocationDropdown from "../API/LocationEnvironment/GetLocationDropdown";
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import clearIcon from '../../assets/images/icons/clear.svg';
import deleteBtnIcon from '../../assets/images/icons/delete.svg';
import editBtnIcon from '../../assets/images/icons/edit.svg';
import Select, { useStateManager } from 'react-select';
import GetStatusMaster from "../../Admin/Api/Master/StatusMaster/GetStatusMaster";
import GeitemMaster from "../API/AlarmWarningLog/GetitemMaster"
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import GetVenderscope from "../API/LightingControl/VenderScore/GetVenderscope";
import GetInvestigation from "../API/LightingControl/VenderScore/GetInvestigation";
import { DD } from "konva/lib/DragAndDrop";
import GetAllVenderRangeScore from "../API/LightingControl/VenderScore/InsertVitalRangeScore";
import InsertVitalRangeScore from "../API/LightingControl/VenderScore/InsertVitalRangeScore";
import updateIcon from '../../assets/images/icons/updateIcon.svg';
import { date } from "@linways/table-to-excel";
import DeleteVenderscore from "../API/LightingControl/VenderScore/DeleteVenderscore";
import UpdateVitalRangeScore from "../API/LightingControl/VenderScore/UpdateVitalRangeScore";
import GetApivenderscore from "../API/LightingControl/VenderScore/GetApivenderscore";

export default function VendorScore() {

  const { t } = useTranslation();
  document.body.dir = i18n.dir();
  const [rows, setRows] = useState([{
    rangeFrom: "",
    rangeTo: "",
    colorCode: "",
    isBlink: 0,
    score: ""
  }]);
  const [sendForm, setSendForm] = useState({ userId: window.userId });
  const [showUnderProcess, setShowUnderProcess] = useState(0);
  const [Vendervital, setVenodrVital] = useState([]);
  const [showToster, setShowToster] = useState(0);
  const [tosterMessage, setTosterMessage] = useState("");
  const [RangeFrom, setRangeFrom] = useState("");
  const [RangeTo, setRangeTo] = useState("");
  const [color, setColor] = useState("");
  const [Score, setScore] = useState("");
  const [Isblink, setIsBlink] = useState("");
  const [tosterValue, setTosterValue] = useState(0);
  const [showLoder, setShowLoder] = useState(0);
  const [rowID, setRowID] = useState(0);
  const [isUpdateBtnShow, setIsUpdateBtnShow] = useState(false);
  const [LightID, setLightID] = useState(null)
  const [OnTimingTo, setOnTimingTo] = useState('')
  const [AssociatedCensorID, setAssociatedCensorID] = useState(null)
  const [Dgender, SetDgender] = useState('Male')
  const [AgeUnit, SetAgeUnit] = useState('Year')
  const [LightIdDropdown, setLightIdDropdown] = useState([])
  const [DeviceID, setDeviceID] = useState(null)
  const [rowss, setRowss] = useState([{ id: 1 }]);
  const [DeviceIDDropdown, setDeviceIDDropdown] = useState([])
  const [LocationIDDropdown, SetLocationIDDropdown] = useState([])
  const [LocationID, setLocationID] = useState(null)
  const [OnTimingFrom, setOnTimingFrom] = useState("")
  const [dimLevelID, setdimLevelID] = useState(null)
  const [DimLevelIDDropdown, setDimLevelIDDropdown] = useState([])
  const [LightControlTable, setLightControlTable] = useState([])
  const [AssociatedCensorDropdown, setAssociatedCensorDropdown] = useState([])
  const [newlyAddedRowIndex, setNewlyAddedRowIndex] = useState(null);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [isNewRowAdded, setisNewRowAdded] = useState(false)
  const [RangeVitalTable, setRangeVitalTable] = useState([])
  const [RegType, setRegType] = useState('0')
  const [isClearable,] = useState(true);
  const [isSearchable,] = useState(true);
  let [getPatientGender, setGetPatientGender] = useState([]);

  let [userID, setUserId] = useState(JSON.parse(sessionStorage.getItem("LoginData")).userId);
  let [getclientId, setClientId] = useState(JSON.parse(sessionStorage.getItem("LoginData")).clientId);
  let dynamicRowdata = {
    rangeFrom: "",
    rangeTo: "",
    colorCode: "",
    isBlink: 0,
    score: "",
  }
  const ageobj = [
    { id: 1, label: 'ventilator ' },
    { id: 2, label: 'oxygen ' },

  ];



  //  const handleRegType = async(e)=>{
  //   let Reg = e.target.value
  //   setRegType(Reg)
  //   console.log("regValue" , Reg)
  //   let data=await GetVenderscope()
  //     if(data.status === 1  ){
  //       if(Reg == "1"){
  //           setVenodrVital(data.responseValue.map(val=>({
  //           value: val.id,
  //           label : val.vitalName
  //         })))
  //         console.log("aaaa>>" ,RegType )
  //       }

  //       }
  //  }

  const lifesupportdata = [
    { id: -1, itemName: 'Oxygen' },
    { id: -2, itemName: 'ventilator' },


  ]

  const Fluid = [
    { id: 23019, itemName: 'NORAD' },



  ]

  const handleRegType = async (e) => {
    const Reg = e.target.value;
    setRegType(Reg);
    // console.log("regValue", Reg);
    if (Reg == "vital") {
      let vital = await GetVenderscope()
      setVenodrVital(vital.responseValue.map(val => ({
        value: val.id,
        label: val.vitalName
      })))

    }



    if (Reg == "investigation") {
      let invest = await GetInvestigation()
      console.log("Amir", invest.responseValue)
      setVenodrVital(invest.responseValue.map(val => ({
        value: val.id,
        label: val.subTestName

      })))
    }
    if (Reg == "life Support") {
      setVenodrVital(lifesupportdata.map(val => ({
        value: val.id,
        label: val.itemName
      })))
    }
    if (Reg == "Fluid") {
      setVenodrVital(Fluid.map(val => ({
        value: val.id,
        label: val.itemName
      })))
    }






  };
  const handleRegType2 = async (value, id) => {
    const Reg = value;
    setRegType(Reg);
    if (Reg === "vital") {
      let vital = await GetVenderscope()
      if (vital.status === 1) {
        setVenodrVital(vital.responseValue.map(val => ({

          value: val.id,
          label: val.vitalName
        })))
        return await vital.responseValue.filter(val => val.id === id)
      }


    }



    if (Reg == "investigation") {
      let invest = await GetInvestigation()
      // console.log("Amir", invest.responseValue)
      if (invest.status === 1) {
        setVenodrVital(invest.responseValue.map(val => ({
          value: val.id,
          label: val.subTestName

        })))
        return await invest.responseValue.filter(val => val.id === id)
      }
    }
    if (Reg == "life Support") {
      setVenodrVital(lifesupportdata.map(val => ({
        value: val.id,
        label: val.itemName
      })))
      return await lifesupportdata.filter(val => val.id === id)
    }






  };


  // const rangevitalget=async()=>{
  //   let data=GetApivenderscore()
  // }


  let rangevitalget = async () => {
    let data = await GetApivenderscore();
    if (data.status === 1) {
      // console.log("tenantMaster", tenantmaster.responseValue)
      setShowLoder(0);
      setRangeVitalTable(data.responseValue);
      console.log("RangeVitalTable", data.responseValue)
    }
  }




  const handleOnChange = (e, ind) => {


    const { name, value } = e.target;
    let temp = [...rows]
    console.log("data", e.target.value, e.target.name, ind, temp)

    if (name === 'ddgender') {
      SetDgender(value)

    }
    let agefrom = e.target.value
    if (name === 'ageFrom') {
      document.getElementById('errAgeFrom').style.display = 'none';

      setOnTimingFrom(agefrom)

    }

    if (name === 'ddlAgeUnit') {
      SetAgeUnit(value)
    }
    let ageto = e.target.value
    if (name === 'ageTo') {
      document.getElementById('errOnTimingTo').style.display = 'none';
      setOnTimingTo(ageto)
    }
    if (name === 'rangeFrom') {

      temp[ind][name] = value
      document.getElementById('errRangeFrom').style.display = 'none';


      // setRows([...temp])
    }
    if (name === 'rangeTo') {
      temp[ind][name] = value
      document.getElementById('errrangeTo').style.display = 'none';
      // setRows([...temp])

    }
    if (name === 'colorCode') {
      temp[ind][name] = value
      document.getElementById('errrColor').style.display = 'none';
      // setRows([...temp])

    }
    if (name === 'isBlink') {
      temp[ind][name] = value
      // setRows([...temp])

    }
    if (name === 'score') {
      temp[ind][name] = value
      document.getElementById('errScore').style.display = 'none';


    }
    setRows([...temp])


  };
  const handleSelectChange = (selectedOption, errorElementId, setSelectedFunction) => {
    document.getElementById(errorElementId).style.display = 'none';
    setSelectedFunction(selectedOption);
  };

  const handleOnSave = async (index) => {
    let temp = [...rows]

    if (RegType === null) {
      document.getElementById('errRegType').innerHTML = 'Please Select  Parameter Type';
      document.getElementById('errRegType').style.display = 'block';
      return;
    }
    else if (AssociatedCensorID === null) {
      document.getElementById('errAssociatedCensorID').innerHTML = 'Please Select  Parameter Name';
      document.getElementById('errAssociatedCensorID').style.display = 'block';
      return;
    }
    else if (Dgender === null) {
      document.getElementById('errDeviceID').innerHTML = 'Please Select  Gender';
      document.getElementById('errDeviceID').style.display = 'block';
      return;
    }


    else if (OnTimingFrom === '') {
      document.getElementById('errAgeFrom').innerHTML = 'Please Select  age from';
      document.getElementById('errAgeFrom').style.display = 'block';
      return;
    }

    else if (parseFloat(OnTimingFrom) > parseFloat(OnTimingTo)) {
      document.getElementById('errAgeFrom').innerHTML = 'Age from should not be greater than Age To';
      document.getElementById('errAgeFrom').style.display = 'block';
      return;
    }



    else if (OnTimingTo === '') {
      document.getElementById('errOnTimingTo').innerHTML = 'Please Enter age to';
      document.getElementById('errOnTimingTo').style.display = 'block';
      return;
    }
    else if (AgeUnit === null) {
      document.getElementById('errDimLevel').innerHTML = 'Please Select  Dim Level';
      document.getElementById('errDimLevel').style.display = 'block';
      return;
    }

    // For Dynamic Rows

    else if (temp[0].rangeFrom === "" || temp[0].rangeFrom == '0' || temp[0].rangeFrom === null) {
      document.getElementById('errRangeFrom').innerHTML = 'Please Enter Range From';
      document.getElementById('errRangeFrom').style.display = 'block';
      return;
    }


    else if (parseFloat(temp[0].rangeFrom) > parseFloat(temp[0].rangeTo)) {
      document.getElementById('errRangeFrom').innerHTML = 'Range from should not be greater than Range To';
      document.getElementById('errRangeFrom').style.display = 'block';
      return;
    }


    else if (temp[0].rangeTo === "" || temp[0].rangeTo === null) {
      document.getElementById('errrangeTo').innerHTML = 'Please Enter Range To';
      document.getElementById('errrangeTo').style.display = 'block';
      return;
    }

    else if (temp[0].colorCode === "" || temp[0].colorCode === null) {
      document.getElementById('errrColor').innerHTML = 'select Color';
      document.getElementById('errrColor').style.display = 'block';
      return;
    }
    else if (temp[0].score === "" || temp[0].score === null) {
      document.getElementById('errScore').innerHTML = 'Please Enter Scores';
      document.getElementById('errScore').style.display = 'block';
      return;
    }




    console.log("rowssss", rows)
    const obj = {

      parameterName: RegType,
      parameterID: AssociatedCensorID.value,
      gender: Dgender,
      ageFrom: OnTimingFrom,
      ageTo: OnTimingTo,
      ageUnitID: AgeUnit,
      jsonArray: JSON.stringify(rows),
      userId: userID,
      clientID: window.clientId


    };

    console.log("amirrrr >>>", obj)






    let data = await InsertVitalRangeScore(obj);
    if (data.status === 1) {

      setShowUnderProcess(0);
      setShowToster(1);
      setTosterValue(0);
      setTosterMessage("Data Saved Successfully!");
      rangevitalget()

      setisNewRowAdded(true);
      handleClear();
      setTimeout(() => {
        setShowToster(0);
        setisNewRowAdded(false);
      }, 2000);

    } else {
      setShowUnderProcess(0);
      setShowToster(1);
      setTosterValue(1);
      setTosterMessage("Already Exist!");
      setTosterMessage(data.responseValue);
      setTimeout(() => {
        setShowToster(0);
      }, 2000);
    }
  };

  const handleClear = () => {
    document.getElementById('errRegType').style.display = 'none';
    document.getElementById('errAssociatedCensorID').style.display = 'none';
    document.getElementById('errDeviceID').style.display = 'none';
    // document.getElementById('errLocationID').style.display = 'none';
    document.getElementById('errAgeFrom').style.display = 'none';
    document.getElementById('errOnTimingTo').style.display = 'none';
    document.getElementById('errDimLevel').style.display = 'none';
    document.getElementById('errRangeFrom').style.display = 'none';
    document.getElementById('errrangeTo').style.display = 'none';
    document.getElementById('errrColor').style.display = 'none';
    document.getElementById('errScore').style.display = 'none';

    // document.getElementById('errLightID').style.display = 'none';
    // document.getElementById('errDeviceID').style.display = 'none';
    // document.getElementById('errLocationID').style.display = 'none';
    // document.getElementById('errOnTimingFrom').style.display = 'none';
    // document.getElementById('errDimLevel').style.display = 'none';
    // document.getElementById('errAssociatedCensorID').style.display = 'none';

    setLightID(null)
    setAssociatedCensorID(null)
    setDeviceID(null)
    setLocationID(null)
    setRegType("Select Reg Type")
    setOnTimingFrom('')
    setOnTimingTo('')
    setdimLevelID(null)
    setRows([{
      rangeFrom: "",
      rangeTo: "",
      colorCode: "",
      isBlink: 0,
      score: ""
    }])
  };


  const edit = async (data, index) => {
    setRowID(data.id)
    setIsUpdateBtnShow(true);
    let parameterIDName = await handleRegType2(data.parameterName, data.parameterID)
    console.log("ttttttttttttttttttt", parameterIDName, data.parameterName, data.parameterID)
    setRegType(data.parameterName)
    setTimeout(() => {
      if (data.parameterName === "vital") {
        setAssociatedCensorID({
          label: parameterIDName[0].vitalName,
          value: data.parameterID,
        })
      }
      else if (data.parameterName === "investigation") {
        setAssociatedCensorID({
          label: parameterIDName[0].subTestName,
          value: data.parameterID,
        })
      }
      else if (data.parameterName === "life Support") {
        setAssociatedCensorID({
          label: parameterIDName[0].itemName,
          value: data.parameterID,
        })
      }

    }, 600)


    SetDgender(data.gender)
    setOnTimingFrom(data.ageFrom)
    setOnTimingTo(data.ageTo)



    let temp = [...rows]
    temp[0]["id"] = data.id
    temp[0]['rangeFrom'] = data.rangeFrom
    temp[0]['rangeTo'] = data.rangeTo
    temp[0]['colorCode'] = data.colorCode
    temp[0]['isBlink'] = data.isBlink
    temp[0]['score'] = data.score
    document.getElementById("isBlink_0").checked = data.isBlink === 1 ? true : false
    // setRangeTo(data.rangeTo)
    // setColor(data.colorCode)
    // setIsBlink(data.isBlink)
    // setScore(data.score)
    setRows([...temp])
  }

  const handleUpdate = async () => {

    // if (RegType === null) {
    //   document.getElementById('errRegType').innerHTML = 'Please Select  Reg Type';
    //   document.getElementById('errRegType').style.display = 'block';
    //   return;
    // }
    // else if (AssociatedCensorID === null) {
    //   document.getElementById('errAssociatedCensorID').innerHTML = 'Please Select  Par';
    //   document.getElementById('errAssociatedCensorID').style.display = 'block';
    //   return;
    // }
    // else if (Dgender === null) {
    //   document.getElementById('errDeviceID').innerHTML = 'Please Select  Gender';
    //   document.getElementById('errDeviceID').style.display = 'block';
    //   return;
    // }


    // else if (OnTimingFrom === null) {
    //   document.getElementById('errLocationID').innerHTML = 'Please Select  Location';
    //   document.getElementById('errLocationID').style.display = 'block';
    //   return;
    // }
    
    let temp = [...rows]

    if (RegType === null) {
      document.getElementById('errRegType').innerHTML = 'Please Select  Parameter Type';
      document.getElementById('errRegType').style.display = 'block';
      return;
    }
    else if (AssociatedCensorID === null) {
      document.getElementById('errAssociatedCensorID').innerHTML = 'Please Select  Parameter Name';
      document.getElementById('errAssociatedCensorID').style.display = 'block';
      return;
    }
    else if (Dgender === null) {
      document.getElementById('errDeviceID').innerHTML = 'Please Select  Gender';
      document.getElementById('errDeviceID').style.display = 'block';
      return;
    }


    else if (OnTimingFrom === '') {
      document.getElementById('errAgeFrom').innerHTML = 'Please Select  age from';
      document.getElementById('errAgeFrom').style.display = 'block';
      return;
    }

    else if (parseFloat(OnTimingFrom) > parseFloat(OnTimingTo)) {
      document.getElementById('errAgeFrom').innerHTML = 'Age from should not be greater than Age To';
      document.getElementById('errAgeFrom').style.display = 'block';
      return;
    }



    else if (OnTimingTo === '') {
      document.getElementById('errOnTimingTo').innerHTML = 'Please Enter age to';
      document.getElementById('errOnTimingTo').style.display = 'block';
      return;
    }
    else if (AgeUnit === null) {
      document.getElementById('errDimLevel').innerHTML = 'Please Select  Dim Level';
      document.getElementById('errDimLevel').style.display = 'block';
      return;
    }

    // For Dynamic Rows

    else if (temp[0].rangeFrom === "" || temp[0].rangeFrom == '0' || temp[0].rangeFrom === null) {
      document.getElementById('errRangeFrom').innerHTML = 'Please Enter Range From';
      document.getElementById('errRangeFrom').style.display = 'block';
      return;
    }


    else if (parseFloat(temp[0].rangeFrom) > parseFloat(temp[0].rangeTo)) {
      document.getElementById('errRangeFrom').innerHTML = 'Range from should not be greater than Range To';
      document.getElementById('errRangeFrom').style.display = 'block';
      return;
    }


    else if (temp[0].rangeTo === "" || temp[0].rangeTo === null) {
      document.getElementById('errrangeTo').innerHTML = 'Please Enter Range To';
      document.getElementById('errrangeTo').style.display = 'block';
      return;
    }

    else if (temp[0].colorCode === "" || temp[0].colorCode === null) {
      document.getElementById('errrColor').innerHTML = 'select Color';
      document.getElementById('errrColor').style.display = 'block';
      return;
    }
    else if (temp[0].score === "" || temp[0].score === null) {
      document.getElementById('errScore').innerHTML = 'Please Enter Scores';
      document.getElementById('errScore').style.display = 'block';
      return;
    }


    // else if (OnTimingTo.trim() === '' || OnTimingTo === undefined) {
    //   document.getElementById('errOnTimingTo').innerHTML = 'Please Enter On Timing';
    //   document.getElementById('errOnTimingTo').style.display = 'block';
    //   return;
    // }
    // else if (AgeUnit === null) {
    //   document.getElementById('errDimLevel').innerHTML = 'Please Select  Dim Level';
    //   document.getElementById('errDimLevel').style.display = 'block';
    //   return;
    // }






    const obj = {
      id: rowID,
      parameterName: RegType,
      parameterID: AssociatedCensorID.value,
      gender: Dgender,

      ageFrom: OnTimingFrom,
      ageTo: OnTimingTo,
      ageUnitID: AgeUnit,
      jsonArray: JSON.stringify(rows),

      userId: userID,
      clientID: getclientId


    };

    const data = await UpdateVitalRangeScore(obj);
    console.log("aaaaaaa", data)
    if (data.status === 1) {
      setShowUnderProcess(0);
      setTosterValue(0);
      setShowToster(1);
      setTosterMessage('Data Updated Successfully!');
      rangevitalget()

      handleClear()
      setTimeout(() => {
        setShowToster(0);


      }, 2000);
      setIsUpdateBtnShow(false);
    } else {
      setShowUnderProcess(0);
      setShowToster(1);
      setTosterMessage("Already Exist");
      setTosterValue(1);
      setTimeout(() => {
        setShowToster(0);
      }, 2000);
    }
  }

  const deleteRow = async () => {
  

 
    let data = await DeleteVenderscore(rowID,getclientId);
    console.log("sdsdsdsdsstest fdfdfddfdf" ,data)
    if (data.status === 1) {
      setShowUnderProcess(0);
      setTosterValue(0);
      setShowToster(1);
      
      setTosterMessage("Data Deleted Successfully!!");
      console.log("sdsdsdsdsstest rtrtrtrtrtrtrtrtrttrt" ,data)
      setNewlyAddedRowIndex(false)
      setIsUpdateBtnShow(false)
      setisNewRowAdded(false)
      rangevitalget()
      handleClear()

      setTimeout(() => {
        setShowToster(0);



      }, 1000)
    }
    else {
      setShowUnderProcess(0)
      setShowToster(0)
      setTosterMessage(data.responseValue)
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0);
      }, 2000)
    }
  };


  const handleCancel = () => {
    handleClear()
    setIsUpdateBtnShow(false);
    setEditRowIndex(null)
    setNewlyAddedRowIndex(null);

  };

  let handleAddRow = () => {
    setRows([...rows, dynamicRowdata])
  }
  // const removeRow = (id) => {
  //   if (id !== 1) {
  //     const updatedRows = rowss.filter((row) => row.id !== id);
  //     setRowss(updatedRows);
  //   }
  // };

  const removeRow = (index) => {
    let temp = [...rows];
    // Ensure that the first row is never removed
    temp.splice(index, 1);
    if (temp.length > 0) {
      setRows(temp);
    }
  };

  useEffect(() => {

    rangevitalget()
  }, []);


  return (
    <>

      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="med-box">
                <div className="title">{t("Dashboard Range Score")}</div>
                <div className="inner-content">
                  <div className='row'>

                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                      <label htmlFor="ddlitemmaster" className="form-label ">{t("Parameter Type")}<span className="starMandatory">*</span></label>
                      <select value={RegType} className="form-select form-select-sm" id="regTypeName" aria-label=".form-select-sm example" onChange={handleRegType}>
                        <option value="Select Reg Type">Select Parameter Type</option>
                        <option value="vital">Vital</option>
                        <option value="investigation">Investigation</option>
                        <option value="life Support">Life Support</option>
                        <option value="Fluid">Fluid </option>
                      </select>

                      <small id="errRegType" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>

                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                      <label htmlFor="ddlitemmaster" className="form-label ">{t("Parameter Name")} <span className="starMandatory">*</span></label>
                      <Select value={AssociatedCensorID} options={Vendervital} className=" create-select" id="serviceType" placeholder={t("Parameter Name")} isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, "errAssociatedCensorID", setAssociatedCensorID)} />
                      <small id="errAssociatedCensorID" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>

                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                      <label htmlFor="ddlitemmaster" className="form-label ">{t("Gender")}<span className="starMandatory">*</span></label>
                      {/* <Select value={DeviceID} options={DeviceIDDropdown} className=" create-select" id="serviceType" placeholder={t("Select  Gender")} isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, "errDeviceID", setDeviceID)} /> */}
                      <select className="form-select form-select-sm" value={Dgender} id="ddgender" name="ddgender" aria-label=".form-select-sm example" onChange={handleOnChange} >
                        <option value="Male" selected>{t("Male")}</option>
                        <option value="Female">{t("Female")}</option>
                        <option value="Common">{t("Common")}</option>
                      </select>
                      <small id="errDeviceID" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>

                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                      <label htmlFor="ddlitemmaster" value="AgeFrom" className="form-label ">{t("Age From")}<span className="starMandatory">*</span></label>
                      <input value={OnTimingFrom} id="ddOnTimingFrom" type="number" className="form-control form-control-sm" name="ageFrom" placeholder={t("Age From")} onChange={handleOnChange} />
                      <small id="errAgeFrom" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>


                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                      <label htmlFor="Code" className="form-label">{t("Age To")}<span className="starMandatory">*</span></label>
                      <input value={OnTimingTo} id="ddOnTimingFrom" type="number" className="form-control form-control-sm" name="ageTo" placeholder={t("Age To")} onChange={handleOnChange} />
                      <small id="errOnTimingTo" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>

                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3">
                      <label htmlFor="ddlitemmaster" className="form-label ">{t("Age Unit")}<span className="starMandatory">*</span></label>
                      <select value={AgeUnit} className="form-select form-select-sm" id="ddlAgeUnit" aria-label="form-select-sm example" name='ddlAgeUnit' onChange={handleOnChange}>
                        <option value="Year" selected>{t("Year")}</option>
                        <option value="Month">{t("Month")}</option>
                        <option value="Day">{t("Day")}</option>
                      </select>
                      {/* <Select value={dimLevelID} options={DimLevelIDDropdown} className=" create-select" id="serviceType" placeholder={t("Age Unit")} isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, "errDimLevel", setdimLevelID)} /> */}
                      <small id="errDimLevel" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>


                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    {/* <div className="med-box"> */}
                    <div
                      className="med-table-section"
                      style={{ minHeight: "105px" }}
                    >
                      <table className="med-table border_ striped billingTable">
                        <thead style={{ zIndex: '0' }}>
                          <tr>
                            <th className="text-center">#</th>
                            <th>{t("Range From")}<span className="text-danger fs-6">*</span></th>
                            <th>{t("Range To")}<span className="text-danger fs-6">*</span></th>
                            <th>{t("Color")}<span className="text-danger fs-6">*</span></th>
                            <th style={{ "textAlign": "center" }}>{t("Is Blink")}</th>
                            <th>{t("Score")}<span className="text-danger fs-6">*</span></th>
                            <th className="text-center">{t("Status")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows.map((row, index) => {
                            return (
                              <tr key={index}>
                                <td className="text-center">{index + 1}</td>
                                <td>

                                  <input
                                    type="text"
                                    id={`discription_${index}`}
                                    name="rangeFrom"
                                    value={row.rangeFrom}
                                    onChange={(e) => handleOnChange(e, index)}
                                    placeholder={t("Range From")}
                                  />
                                  <small id="errRangeFrom" className="form-text text-danger" style={{ display: 'none', fontSize: '15px' }}></small>

                                </td>

                                <td>
                                  <input
                                    type="text"
                                    id={`serialNo_${index}`}
                                    name="rangeTo"
                                    value={row.rangeTo}
                                    onChange={(e) => handleOnChange(e, index)}
                                    placeholder={t("Range To")}
                                  />
                                  <small id="errrangeTo" className="form-text text-danger" style={{ display: 'none', fontSize: '15px' }}></small>
                                </td>

                                <td>
                                  <input
                                    type="color"
                                    id={`quantity_${index}`}
                                    name="colorCode"
                                    value={row.colorCode}
                                    onChange={(e) => handleOnChange(e, index)}

                                  />
                                  <small id="errrColor" className="form-text text-danger" style={{ display: 'none', fontSize: '15px' }}></small>
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id={`isBlink_${index}`}
                                    name="isBlink"
                                    value={row.isBlink === 1 ? 0 : 1}
                                    defaultChecked={row.isBlink === 1 ? true : false}
                                    onChange={(e) => handleOnChange(e, index)}
                                    placeholder={t("Is Blink")}
                                  />

                                </td>

                                <td>
                                  <input
                                    type="number"
                                    id={`quantity${index}`}
                                    name="score"
                                    value={row.score}
                                    onChange={(e) => handleOnChange(e, index)}
                                    placeholder={t("Score")}
                                  />
                                  <small id="errScore" className="form-text text-danger" style={{ display: 'none', fontSize: '15px' }}></small>
                                </td>
                                <td>
                                  <div className="action-button" >
                                    <i className="bi bi-plus" onClick={handleAddRow}></i>{" "}
                                    &nbsp;
                                    {<i
                                      className="bi bi-trash3"
                                      onClick={() => removeRow(index)}

                                    ></i>}
                                  </div>


                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3 relative">
                      <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                      {showUnderProcess === 1 ? <><TosterUnderProcess />  </> :
                        showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} />
                          :
                          <div>
                            {isUpdateBtnShow !== true ? <>
                              <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleOnSave}><img src={saveButtonIcon} className='icnn' />{t("Save")}</button>
                              <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' />{t("Clear")}</button>
                            </> :
                              <>
                                <button type="button" className="btn btn-save btn-sm mb-1 me-1 mx-1" onClick={handleUpdate} >{t("Update")}</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleCancel} >{t("Cancel")}</button>
                              </>
                            }
                          </div>
                      }
                    </div>
                    <div className="col-12 mt-3">
                      <div className="med-table-section" style={{ "height": "80vh", zIndex: 0 }}>
                        <table className="med-table border_ striped">
                          <thead style={{ zIndex: '0' }}>
                            <tr>
                              <th className="text-center" style={{ "width": "5%" }}>{t("#")}</th>
                              <th>{t("Parameter Type")}</th>
                              <th >{t("Parameter Name")}</th>
                              <th>{t("Gender")}</th>
                              <th>{t("Age From")}</th>
                              <th>{t("Age To")}</th>
                              <th>{t("Age Unit")}</th>
                              <th>{t("Range From")}</th>
                              <th>{t("Range To")}</th>
                              <th>{t("Color Code")}</th>
                              <th>{t("Is Blink")}</th>
                              <th>{t("Score")}</th>
                              <th></th>

                              <th></th>
                              <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {RangeVitalTable && RangeVitalTable.map((data, index) => {
                              const isNewRow = newlyAddedRowIndex === index;
                              const isEditing = index === editRowIndex;
                              const ParmeteID = data.parameterName
                              console.log("aaaaa>>", data.parameterName == 'life Support' ? 'Ayaz' : '')

                              return (
                                <tr className={index === RangeVitalTable.length - 1 && isNewRowAdded ? 'new-row' : '' || isNewRow ? 'new-row' : ''} key={index}>
                                  <td className="text-center">{index + 1}</td>
                                  <td>{data.parameterName}</td>
                                  {/* <td>{data.parameterName == 'life Support' && data.parameterID == -2 ? 'Ventilator' : data.parameterID == -1 ? 'Oxygen' : data.resultParameterName}</td> */}
                                  <td>
                                    {data.parameterName == 'life Support' && data.parameterID == -2 ? 'Ventilator' : data.parameterID == -1 ? 'Oxygen' : data.parameterID == 23019 ? 'NORAD' : data.resultParameterName}
                                  </td>
                                  <td>{data.gender}</td>
                                  <td>{data.ageFrom}</td>
                                  <td>{data.ageTo}</td>
                                  <td>{data.ageUnitID}</td>
                                  <td>{data.rangeFrom}</td>
                                  <td>{data.rangeTo}</td>
                                  <td>{data.colorCode}</td>
                                  <td>{data.isBlink}</td>
                                  <td>{data.score}</td>
                                  <td></td>


                                  <td></td>

                                  <td>
                                    <div className="action-button">
                                      <div
                                        data-bs-toggle="tooltip"
                                        data-bs-title="Edit Row"
                                        data-bs-placement="bottom"

                                      >
                                        <img src={editBtnIcon} className={isEditing ? 'edited-row' : ''} alt='' onClick={() => { edit(data, index) }} />
                                      </div>
                                      <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => setRowID(data.id) } />
                                      </div>
                                    </div>
                                  </td>
                                  {/* <td style={{ border: 'none', width: '7%' }}>
                                                    <div className="action-button">
 
                                                        <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowID(data.id); }} />
                                                        </div>
                                                    </div>
                                                </td> */}
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>

                </div>
              </div>
            </div>

          </div>







        </div>
        {/* -----------------------Start Delete Modal Popup-------------------    */}

        <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
          <div className="modal-dialog modalDelete">
            <div className="modal-content">
              <div className="modal-body modelbdy text-center">
                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                <div className='popDeleteTitle mt-3'> {t("Delete?")}</div>
                <div className='popDeleteContent'>{t("Are_you_sure_you_want_to_delete?")}</div>
              </div>
              <div className="modal-footer1 text-center">

                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                <button type="button" className="btn-delete popBtnDelete" data-bs-dismiss="modal" onClick={deleteRow} >{t("Delete")}</button>
              </div>
            </div>
          </div>
        </div>
        {/* -----------------------End Delete Modal Popup---------------------  */}
        {
          showLoder === 1 ? <Loder val={showLoder} /> : ""
        }
      </section>
    </>
  )
}


