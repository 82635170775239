import React from 'react'

export default function ConsentFormForComputedTomographyCT() {
    return (
        <>
            <div className="main-table" style={{ padding: '10px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <table className='microtble'>

                    {/* ....header.. */}
                    <tr>
                        <td colSpan={8}>
                            <div className="sarvodya-header">
                                <div className='lft-header mb-3'>
                                    {/* <img alt='' className='sv-log'/>                                   */}
                                </div>
                                <div className='rght-header'>
                                    <div> SARVODAYA HOSPITAL <div className='addrs'>SECTOR-8, FARIDABAD | HELPLINE: 1800 313 1414</div></div>
                                </div>
                            </div>

                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td colSpan={8}>
                            <div className='heading-form mt-3 mb-3'>
                                CONSENT FORM FOR COMPUTED TOMOGRAPHY (CT)
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <table className='tbl-bordr mt-0' style={{ border: '1px solid black' }}>
                                <tr>
                                    <td colSpan={2}><div className='assesmnt mt-3 mb-3'>Date of Consent:  <span style={{ width: '395px', borderBottom: '1px dashed black', display: 'inline-block' }}> 13-12-24</span> </div></td>
                                    <td rowSpan={4} style={{ borderLeft: '1px solid black', textAlign: 'center' }}>Patients Label</td>
                                </tr>
                                <tr>
                                    <td ><div className='assesmnt mt-2 mb-2'>Time of Consent: <span style={{ width: '160px', borderBottom: '1px dashed black', display: 'inline-block' }}> 12:00am</span> </div></td>
                                    <td ><div className='assesmnt mt-2 mb-2'>Ward.: <span style={{ width: '155px', borderBottom: '1px dashed black', display: 'inline-block' }}>Emergency</span> </div></td>
                                </tr>
                                <tr>
                                    <td ><div className='assesmnt mt-3 mb-3'>Diagnosis:  <span style={{ width: '190px', borderBottom: '1px dashed black', display: 'inline-block' }}> fever</span> </div></td>
                                    <td ><div className='assesmnt mt-3 mb-3'>Procedure:  <span style={{ width: '140px', borderBottom: '1px dashed black', display: 'inline-block' }}> admit</span> </div></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='ptndetais mt-2'>
                                <div> <strong>I, </strong> <span style={{ width: '280px', borderBottom: '1px dashed black', display: 'inline-block' }}>
                                    sabi</span>  </div>
                                <div><label> <input type="checkbox" /> </label>    The Patient or </div>
                                <div>  <label> <input type="checkbox" /> </label> representative of patient have </div>

                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='parainfo2 mt-2'>
                                <div> <input type="checkbox" /> <label> Read & Understood </label> </div>
                                <div><input type="checkbox" /> <label>I/We have been explained my/my patient's current clinical condition. </label> </div>
                            </div>

                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-2'>
                                I have also been explained this consent form in English/Hindi <span style={{ width: '100px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span>   (Other Language specify)which I fully.
                            </div>
                            <div>Which i fully understand</div>
                            <div className='paragraphinfo '>
                                I have been informed by my treating Doctor that i need to undergo Computed Tomography (CT).
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-2'>
                                I understand that all Radiology investigations (except MRI & Ultrasound) involve use of X-rays which help us derive information about the structure and function of tissues inside the body thus helping the doctor to decide on further management of the patient's disease/ailment.
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-0'>
                                MRI is a painless procedure that requires, you lie quietly on a padded table, and which gently glides into the magnet. While the scanner is performing your scan, you will hear some humming and thumping sounds. These are normal and should not worry you. In some cases, a contrast agent may be injected into your vein in order to give a clearer image of the area being examined.                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-2'>
                                I have been explained that diagnostic use of x-rays in CT uses a very small quantity of radiation and the chances of harmful effects of radiation are very minimal thus rendering the risk acceptable. I understand that x-rays are naturally present around us (like background radiation) and that despite all precautions the possibility of harmful effects of radiation cannot be completely eliminated.
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div className='hourlydetils mt-2'>
                                <strong>Intended Benefits :</strong> The benefit of the study is that your doctor will use the results to
                            </div>
                            <div className='hourlylist'>
                                <ul>
                                    <li> To helps in better .<strong>diagnosis</strong>    </li>
                                    <li> Generally, painless  </li>

                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='hourlydetils mt-2'>
                                <strong>All procedures carry certain drawbacks. Possible Risk sand Complications for this procedure are:</strong>
                            </div>
                            <div className='hourlylist'>
                                <ul>
                                    <li> Radiation to a fetus may have consequences to the unborn child if you are pregnant </li>
                                    <li> Pain, bleeding, bruising, redness or swelling at the IV site  </li>
                                    <li> Allergic reactions to contrast such as Hives, pruritis, rashes etc.  </li>
                                    <li> The IV dye if used contains iodine that may cause kidney damage in those with poor kidney function  </li>
                                    <li> Any Other <span style={{ width: '320px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span>      </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-2'>
                                I have understood that x-rays are a form of ionizing radiation and can be potentially harmful. I understand that x- rays can lead to harm to the fetus (unborn baby), damage to reproductive cells, changes to body cells making the individual more prone to develop cancers, or genetic aberrations which can be passed on the next generation                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <div className='hourlydetils mt-2'>
                                <strong>Possible Alternatives:</strong> I have been explained and also been suggested the alternatives Investigations such as,
                            </div>
                            <div className='hourlylist'>
                                <ul>
                                    <li> <strong>Computed Tomography </strong> </li>
                                    <li> <strong>Ultrasonography (USG) </strong></li>

                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-2'>
                                However, I understand the limitations of these alternatives as explained by my doctor.
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-2'>
                                I have been explained about the details, nature, risks and possible alternatives of this investigation including the option of not undertaking the test.
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-2'>
                                <strong><span> <input type="checkbox" /></span> USE OF CONTRAST AGENT (tick if applicable):</strong> I have been explained that a contrast medium may be used (oral or injectable route) during the investigation. I understand that contrast media usually contain lodine to which some patients may be allergic. Though safe, contrast media can cause allergic reactions. These reactions are usually mild (like

                            </div>
                        </td>
                    </tr>



                </table>

            </div>
        </>
    )
}
