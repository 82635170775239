import React, { useEffect, useState } from 'react'
import Heading from '../../Component/Heading';
import BoxContainer from '../../Component/BoxContainer';
import TableContainer from '../../Component/TableContainer';
import name from '../../BloodBank/images/name.svg'
import age from '../../BloodBank/images/age.svg'
import genders from '../../BloodBank/images/genders.svg'
import department from '../../BloodBank/images/department.svg'
import bloddgroup from '../../BloodBank/images/bloddgroup.svg'
import product from '../../BloodBank/images/product.svg'
import requestunit from '../../BloodBank/images/requestunit.svg'
import dob from '../../BloodBank/images/dob.svg'
import time from '../../BloodBank/images/time.svg'
import Remark from '../../BloodBank/images/Remark.svg'
import SuccessToster from "../../Component/SuccessToster";
import AlertToster from "../../Component/AlertToster";
import bloodproduct from '../../BloodBank/images/bloodproduct.svg'
import ward from '../../BloodBank/images/ward.svg'
import printer from '../../BloodBank/images/printer.svg'
import exportfile from '../../BloodBank/images/exportfile.svg'
import GetBloodRequest from '../Api/BloodDonorRegestration/GetBlood/GetBloodRequest/GetBloodRequest';
import GetAllBloodProduct from '../Api/BloodDonorRegestration/GetBlood/GetAllBloodProduct';
import BloodRequestValidation from '../../Validation/BloodBank/BloodRequestValidation';
import GetBloodGroup from '../Api/BloodDonorRegestration/GetBlood/GetBloodGroup';
import TosterUnderProcess from '../../Component/TosterUnderProcess';
import Toster from '../../Component/Toster';
import PostBloodRequest from '../Api/BloodDonorRegestration/PostBlood/PostBloodRequest/PostBloodRequest';
import GetDepartmentList from '../../Registartion/API/GET/GetDepartmentList'
import GetWardForBloodBank from '../Api/BloodDonorRegestration/GetBlood/GetBloodRequest/GetWardForBloodBank';
import saveBtnIcon from '../../assets/images/icons/saveButton.svg';
import clearBtnIcon from '../../assets/images/icons/clear.svg';
import uhid from '../../assets/images/icons/UHID1.svg';
import GetPatientData from '../Api/GetPatientData';



export default function BloodRequest() {

  let [bloodRequestList, setBloodRequestList] = useState([]);
  let [departmentList, setDepartmentList] = useState([]);
  let [wardList, setWardList] = useState([]);
  let [patientName, setPatientName] = useState('');
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [showSuccessToster, setShowSuccessToster] = useState(0)
  let [showMessage, setShowMeassage] = useState("");
  let [patientAge, setPatientAge] = useState('');
  let [patientUhid, setPatientUhid] = useState('');
  let [patientGender, setPatientGender] = useState('');
  let [hospitalName, setHospitalName] = useState('');
  let [requestDate, setRequestDate] = useState('');
  let [requestTime, setRequestTime] = useState('');
  let [hospitalAddress, setHospitalAddress] = useState('');
  let [rqstQty, setRqstQty] = useState('');
  let [productName, setProductName] = useState('');
  let [bloodGroup, setBloodGroup] = useState('');
  let [showUnderProcess, setShowUnderProcess] = useState(0);
  let [showToster, setShowToster] = useState(0);
  let [tosterValue, setTosterValue] = useState(0);
  let [tosterMessage, setTosterMessage] = useState("");
  let [bloodproductname, setbloodproductname] = useState("");
  const [fieldDisable, setfieldDisable] = useState(false)
  let [UHID, setUHID] = useState("");
  let [arrBindList, setArrBindList] = useState([]);
  let [PatientDetails, setPatientDetails] = useState([]);
  let listData = [];
  let [arrProduct, setArrProduct] = useState([]);



let handleChange = async(e) => {
  document.getElementById("errAddProduct").style.display = 'none'
  const {name , value} = e.target
  if(name === 'UHID'){
    setUHID(value);
      let data = await GetPatientData(value);
      let dt = data.responseValue[0];
      setPatientDetails(dt);
      console.log("Pateint Data>>>" ,dt )
  }
  if(name === "bloodproductname"){
    setbloodproductname(value)
    
  }

//  else if(e.target.name === 'PatientName')
//   {
//     document.getElementById('errName').style.display = 'none';
//     setPatientName(e.target.value);
//   }
//   else if(e.target.name === 'Age')
//   {
//     document.getElementById('errAge').style.display = 'none';
//     setPatientAge(e.target.value);
//   }
  else if(e.target.name === 'RequestDate')
  {
    document.getElementById('errDate').style.display = 'none';
    setRequestDate(value);
  }
  else if(e.target.name === 'RequestTime')
  {
    document.getElementById('errTime').style.display = 'none';
    setRequestTime(value);
  }
  else if(e.target.name === 'Hospital')
  {
    document.getElementById('errHospital').style.display = 'none';
    setHospitalName(value);
  }
  else if(e.target.name === 'HospitalAddress')
  {
    document.getElementById('errHospitalAddress').style.display = 'none';
    setHospitalAddress(value);
  }
}

  let getBloodRequestList = async () => {
    let data = await GetBloodRequest();
    setBloodRequestList(data.responseValue);
  }
  let getAllBloodProductList = async () => {
    let data = await GetAllBloodProduct();
    setProductName(data.responseValue);
  }
  let getAllBloodGroup  = async () => {
    let data = await GetBloodGroup();
    setBloodGroup(data.responseValue);
  }
  let getDepartmentList = async () => {
    let data = await GetDepartmentList();
    setDepartmentList(data.responseValue);
  }
  let getWardList = async () => {
    let data =await GetWardForBloodBank();
    setWardList(data.responseValue)
  }
  /////////////////////////////////////////////////////////////////////////

  let getGender = (gender) => {
    setPatientGender(gender);
  }



  let addProduct = async () => {
    document.getElementById('errUnit').style.display = 'none';
    document.getElementById('errProduct').style.display = 'none';
    //document.getElementById('errHospital').setAttribute('disabled')
    // document.getElementById('Hospital').setAttribute("disabled","disabled");
    // document.getElementById('UHID').setAttribute("disabled","disabled");
    // document.getElementById('Age').setAttribute("disabled","disabled");
    // document.getElementById('PatientName').setAttribute("disabled","disabled");
    // document.getElementById('RequestDate').setAttribute("disabled","disabled");
    // document.getElementById('RequestTime').setAttribute("disabled","disabled");
    // document.getElementById('BloodGroup').setAttribute("disabled","disabled");
    
    // document.getElementById('HospitalAddress').setAttribute("disabled","disabled");

    const prdctID = document.getElementById('bloodProduct').value;
    const prdctName = document.getElementById('bloodProduct');
    const selectedOption = prdctName.options[prdctName.selectedIndex];
    const selectedOptionName = selectedOption.text;
    const unit = document.getElementById('rqstQty').value;
    let temp = [...arrProduct]
    var duplicate = temp.findIndex((arry)=> arry.ProductID == prdctID);
 
    if (UHID == ""){
      setShowAlertToster(1)
      setShowMeassage("Please Enter UHID")
      return
    }
    if(bloodproductname == '' || bloodproductname == "0"){
      setShowAlertToster(1)
      setShowMeassage("Please Select Blood Product")
      return
    }

    if(duplicate != -1){
        
      var id1 = 'errAddProduct';
      document.getElementById(id1).style.display = 'block';
      document.getElementById(id1).innerHTML = selectedOptionName + ' already exists.' ;
      return
     }
   
   
      if(temp.length === 0){
       
        temp.push({
          ProductID: prdctID,
          requestedQuantity: unit,
          productName: selectedOptionName
        })

      }
        else{ 
      
          temp.push({
            ProductID: prdctID,
            requestedQuantity: unit,
            productName: selectedOptionName
          })
        }
      
      console.log('select name : ',   selectedOptionName)
      
        
    
       setArrProduct(temp)
       //clearArr();
       console.log('arrproduct : ', temp)
     
    
    
   
     
    }
  
    
   
  

  let saveData  = async () => {
    document.getElementById('errUnit').style.display = 'none';
    document.getElementById('errHospitalAddress').style.display = 'none';
    document.getElementById('errHospital').style.display = 'none';
    document.getElementById('errTime').style.display = 'none';
    document.getElementById('errDate').style.display = 'none';
    document.getElementById('errAge').style.display = 'none';
    document.getElementById('errUHID').style.display = 'none';
    document.getElementById('errBlood').style.display = 'none';
    document.getElementById('errProduct').style.display = 'none';
    document.getElementById('errName').style.display = 'none';
    
    const productID = parseInt(document.getElementById('bloodProduct').value);
    // const wardID = document.getElementById('ward').value;
    const bldGroup = parseInt(document.getElementById('BloodGroup').value);
    const reqstQty = parseInt(document.getElementById('rqstQty').value);
    // const departmentID = document.getElementById('departmentID').value;
    // const genderType = patientGender === 'M' ? document.getElementById('male').value : document.getElementById('female').value;

    console.log('product ID aa rhi : ',productID);
    console.log('reqstQty ID aa rhi : ',reqstQty);
    
    const res = BloodRequestValidation(UHID, requestDate, requestTime,hospitalName, hospitalAddress,bldGroup,productID,reqstQty);
    var id = res[1];

    if(UHID == ''){
      setShowAlertToster(1)
      setShowMeassage("Please Enter UHID")
      return
    }
    if(PatientDetails.patientName == '' || PatientDetails.patientName == undefined || PatientDetails.patientName == null){
      setShowAlertToster(1)
      setShowMeassage("UHID is not Valid")
      return
    }
    if(requestDate == ''){
      setShowAlertToster(1)
      setShowMeassage("Please Select Request Date")
      return
    }
    if(requestTime == ''){
      setShowAlertToster(1)
      setShowMeassage("Please Select Request Time")
      return
    }
    if(bldGroup == ''){
      setShowAlertToster(1)
      setShowMeassage("Please Enter Blood Group")
      return
    }
    if(productID == '' || productID == '0'){
      setShowAlertToster(1)
      setShowMeassage("Please Select Product")
      return
    }
    if(reqstQty == '' ){
      setShowAlertToster(1)
      setShowMeassage("Please Select Request Quantity")
      return
    }
    if(hospitalName == ''){
      setShowAlertToster(1)
      setShowMeassage("Please Enter Hospital Name")
      return
    }
    if(arrProduct == ''){
      setShowAlertToster(1)
      setShowMeassage("Please Add Product")
      return
    }


  
  

      let saveTemp = [];
      for(var i =0 ; i<arrProduct.length; i++) {
        saveTemp.push({
          ProductID : arrProduct[i].ProductID,
          requestedQuantity : arrProduct[i].requestedQuantity
        })
      }

      console.log('save temp m kya aa rha h : ', JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
      
      
      let obj = {
        patientUHID : UHID,
        age : PatientDetails && PatientDetails.age,
        department : PatientDetails && PatientDetails.deptId,
        gender : PatientDetails && PatientDetails.gender,
        ward : PatientDetails && PatientDetails.ward,
        tranfusionDate : requestDate +' ' + requestTime,
        bloodGroupID : bldGroup,
        hospitalName : hospitalName,
        hospitalAddress : hospitalAddress,
        patientName : PatientDetails && PatientDetails.patientName,
        isUrgent: 0,
        jsonbloodrequest : JSON.stringify(saveTemp),
        userId: JSON.parse(window.sessionStorage.getItem("LoginData")).userId,
      }

      console.log('ur obj : ', obj);
      

      let data = await PostBloodRequest(obj);
      if(data.status ===  1)
      {
        setShowUnderProcess(0);
        setTosterValue(0);
        setShowToster(1);
        setTosterMessage('Request saved successfully');
        setTimeout(() => {
          clearArr();
          getBloodRequestList();
          setShowToster(0);
        },2000);
      }
      else{
        setShowUnderProcess(0);
        setShowToster(1);
        setTosterMessage(data.responseValue);
        setTosterValue(1);
        setTimeout(() => {
          setShowToster(0);
        },2000)
      }


   
  
  }

  let remove = (index) => {
    let temp = [...arrProduct]
    temp.splice(index, 1)

    setArrProduct(temp)
  }

  const getPatientData= async()=>{
    let data = await GetPatientData(UHID);
    let dt = data.responseValue[0];
    setPatientDetails(dt);
    console.log("Pateint Data>>>" ,dt )
  }

  

  let clearArr = async () => {
    // document.getElementById('bloodProduct').value = 0;
    // document.getElementById('rqstQty').value = 0;
    // document.getElementById('BloodGroup').value = 0;
    setPatientAge('');
    setPatientUhid('');
    setPatientName('');
    setRequestDate('');
    setRequestTime('');
    setHospitalAddress('');
    setHospitalName('');
    setArrProduct([]);
  }

  let clearErrorDisplay = (e) => {
    if(e.target.id === 'BloodGroup') {
      document.getElementById('errBlood').style.display = 'none';
    }

    else if(e.target.id ==='bloodProduct'){
      document.getElementById('errProduct').style.display = 'none';
    }

    else if(e.target.id ==='rqstQty'){
      document.getElementById('errUnit').style.display = 'none';
    }

  }

  
  useEffect(() => {
    
    getBloodRequestList();
    getAllBloodProductList();
    getAllBloodGroup();
    getDepartmentList();
    getWardList();
    getPatientData()
  }, [])
  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Heading text='Blood Request' />
              <div className="col-12">
                <div className="med-box">
                  <div className="inner-content">
                    <div className="row">
                 <div className="col-md-6 fieldsett-in">
              
                   <div className="fieldsett">
                  <span className='fieldse'>Patient Details</span>
                  <div className='row mt-2 px-2'>
                    <div className='col-xxl-6 col-xl-4 col-lg-4 col-md-6 mb-2'>
                      <div className="mb-2">
                        <div className='d-flex align-items-baseline'>
                          <img src={uhid} className='icnn' alt='' /> <label htmlFor="UHID" className="form-label">UHID<span className="starMandatory">*</span></label>
                        </div>
                        <input type="text" className="form-control form-control-sm" id="UHID" value={UHID} name="UHID" onChange={handleChange} placeholder="Enter UHID" />
                      </div>
                      <small id='errUHID' className='form-text text-danger' style={{ display: 'none' }}></small>
                    </div>
                    <div className='col-xxl-6 col-xl-4 col-lg-4 col-md-6 mb-2'>
                      <div className="mb-2">
                        <div className='d-flex align-items-baseline'>
                          <img src={name} className='icnn' alt='' /> <label htmlFor="PatientName" className="form-label">Patient Name<span className="starMandatory"></span></label>
                        </div>
                        <input type="text" className="form-control form-control-sm" id="PatientName" disabled name="PatientName" value={PatientDetails && PatientDetails.patientName} onChange={handleChange} placeholder="Patient Name" />
                      </div>
                      <small id='errName' className='form-text text-danger' style={{ display: 'none' }}></small>
                    </div>
                    <div className='col-xxl-6 col-xl-4 col-lg-4 col-md-6 mb-2'>
                      <div className='mb-2'>
                        <div className='d-flex align-items-baseline'>
                          <img src={age} className='icnn' alt='' /> <label htmlFor="Age" className="form-label">Age<span className="starMandatory"></span></label>
                        </div>
                        <input type="number" className="form-control form-control-sm" id="Age" disabled name="Age" onChange={handleChange}  value={PatientDetails && PatientDetails.age} placeholder="Age" />
                      </div>
                      <small id='errAge' className='form-text text-danger' style={{ display: 'none' }}></small>
                    </div>
                    <div className='col-xxl-6 col-xl-4 col-lg-4 col-md-6 mb-2'>
                      <div className='mb-2'>
                        <div className='d-flex align-items-baseline'>
                          <img src={genders} className='icnn' alt='' /> <label htmlFor="Age" className="form-label">Gender<span className="starMandatory"></span></label>
                        </div>
                        <input type="text" className="form-control form-control-sm" id="Age" disabled name="Age" onChange={handleChange} value={PatientDetails && PatientDetails.gender} placeholder="Gender" />
                      </div>
                      <small id='errAge' className='form-text text-danger' style={{ display: 'none' }}></small>
                    </div>
            
                    <div className='col-xxl-6 col-xl-4 col-lg-4 col-md-6 mb-2'>
                      <div className="mb-2">
                        <div className='d-flex align-items-baseline'>
                          <img src={department} className='icnn' alt='' /> <label htmlFor="Department" className="form-label">Department</label>
                        </div>
                        <input type="text" className="form-control form-control-sm" id="Age" disabled name="Age" onChange={handleChange} value={PatientDetails && PatientDetails.departName} placeholder="Department" />
                      
                      </div>
                    </div>
                    <div className='col-xxl-6 col-xl-4 col-lg-4 col-md-6 mb-2'>
                      <div className="mb-2">
                        <div className='d-flex align-items-baseline'>
                          <img src={ward} className='icnn' alt='' /> <label htmlFor="Department" className="form-label">Ward</label>
                        </div>
                        <input type="text" className="form-control form-control-sm" id="Age" disabled name="Age" onChange={handleChange} value={PatientDetails && PatientDetails.ward} placeholder="Ward" />
                      
                      </div>
                    </div>
              
                  </div>


                
               
                </div>
               
              </div>
              <div className="col-md-6 fieldsett-in">
                     <div className="fieldsett">
                  <span className='fieldse'>Blood Request</span>
                  <div className='row mt-2 px-2'>
             
                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6'>
                      <div className="mb-2">
                        <div className='d-flex align-items-baseline'>
                          <img src={dob} className='icnn' alt='' /> <label htmlFor="RequestDate" className="form-label">Request Date<span className="starMandatory">*</span></label>
                        </div>

                        <input type="date" className="form-control form-control-sm" id="RequestDate" name="RequestDate" value={requestDate} onChange={handleChange} placeholder="Enter Request Date" />
                      </div>
                      <small id='errDate' className='form-text text-danger' style={{ display: 'none' }}></small>
                    </div>
                    
                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6'>
                      <div className="mb-2">
                        <div className='d-flex align-items-baseline'>
                          <img src={time} className='icnn' alt='' /> <label htmlFor="RequestTime" className="form-label">Request Time<span className="starMandatory">*</span></label>
                        </div>
                        <input type="time" className="form-control form-control-sm" id="RequestTime" name="RequestTime" value={requestTime} onChange={handleChange} placeholder="Enter Request Time"  />
                      </div>
                      <small id='errTime' className='form-text text-danger' style={{ display: 'none' }}></small>
                   
                  </div>
                  <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6'>
                      <div className="mb-2">
                        <div className='d-flex align-items-baseline'>
                          <img src={ward} className='icnn' alt='' /> <label htmlFor="BloodGroup" className="form-label">Blood Group<span className="starMandatory">*</span></label>
                        </div>

                        
                        <select className="form-select form-select-sm" aria-label=".form-select-sm example" id="BloodGroup" onChange={clearErrorDisplay}>
                          <option value='0'>Select Group</option>
                          {bloodGroup && bloodGroup.map((list, index) => {
                            return(
                              <option value={list.id}>{list.groupName}</option>
                            )
                          })}
                        </select>
                        <small id='errBlood' className='form-text text-danger' style={{ display: 'none' }}></small>
                      </div>
                      
                    </div>
                    

                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6'>
                      <div className="mb-2">
                        <div className='d-flex align-items-baseline'>
                          <img src={product} className='icnn' alt='' /> <label htmlFor="BloodProduct" className="form-label">Blood Product<span className="starMandatory">*</span></label>
                        </div>

                        <select className="form-select form-select-sm" aria-label=".form-select-sm example" id='bloodProduct' name='bloodproductname' value={bloodproductname} onChange={handleChange}>
                          <option value='0'>Select Product</option>
                          {productName && productName.map((list, index) => {
                            return (
                              <option value={list.id}>{list.productName}</option>
                            )
                          })}
                        </select>
                        <small id='errProduct' className='form-text text-danger' style={{ display: 'none' }}></small>
                      </div>
                    </div>

                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6'>
                      <div className="mb-2">
                        <div className='d-flex align-items-baseline'>
                          <img src={bloodproduct} className='icnn' alt='' /> <label htmlFor="rqstQty" className="form-label">Request Qty<span className="starMandatory">*</span></label>
                        </div>

                        <select className="form-select form-select-sm" aria-label=".form-select-sm example" id='rqstQty'>
                          <option value='0'>Select Unit</option>
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                          <option value='4'>4</option>
                          <option value='5'>5</option>
                          <option value='6'>6</option>
                        </select>
                        <small id='errUnit' className='form-text text-danger' style={{ display: 'none' }}></small>
                      </div>
                    </div>
                    
                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6'>
                      <div className="mb-2">
                        <div className='d-flex align-items-baseline'>
                          <img src={Remark} className='icnn' alt='' /> <label htmlFor="Hospital" className="form-label">Hospital<span className="starMandatory">*</span></label>
                        </div>
                        <input type="text" className="form-control form-control-sm" id="Hospital" name="Hospital" value={hospitalName} onChange={handleChange} placeholder="Enter Hospital Name" />
                      </div>
                      <small id='errHospital' className='form-text text-danger' style={{ display: 'none' }}></small>
                    </div>
             
                    
                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6'>
                      <div className="mb-2">
                        <div className='d-flex align-items-baseline'>
                          <img src={Remark} className='icnn' alt='' /> <label htmlFor="HospitalAddress" className="form-label">Hospital Address<span className="starMandatory"></span></label>
                        </div>
                        <textarea className="form-control form-control-sm" id="HospitalAddress" name="HospitalAddress" value={hospitalAddress} onChange={handleChange} placeholder="Enter Hospital Address" style={{maxWidth: '239px'}}/>
                      </div>
                      <small id='errHospitalAddress' className='form-text text-danger' style={{ display: 'none' }}></small>
                    </div>
                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 d-flex align-items-center'>
                    <label>&nbsp;</label>
                      <div className="mb-2">
                     
                      
                        <div className='add-blood-product' style={{ cursor: 'pointer' }} onClick={addProduct}>
                          <i className='fa fa-plus' ></i>
                        </div>
                      </div>
                    </div>
                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6'>
                      <div className="mb-2">
                        <div className='addvalue'>
                          {
                            arrProduct && arrProduct.map((list, index) => {
                              return (
                                <>
                                <div className='addvalue-in'><span>{list.productName}</span>/<span>{list.requestedQuantity}</span>
                                  <span className='closeadd' onClick={() => { remove(index) }}><i className='fa fa-times'></i></span>
                                </div>
                                
                                </>
                                
                              )
                            })
                          }
                          
                        </div>
                        <small id='errAddProduct' className='form-text text-danger' style={{ display: 'none' }}></small>
                      </div>
                    </div>
                  </div>

                
                 


                </div>
                  </div> 
                    </div>
                  </div>
                </div>
              </div>
              

              <div className="rt-btns">
                <BoxContainer>
                  <div className="mb-2 relative">
                  {showUnderProcess === 1 ? <><TosterUnderProcess />  </> :
                      showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} />
                        :
                        <div>
                          
                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={saveData}> <img src={saveBtnIcon} className='icnn' alt='' />Save</button>
                            <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={clearArr}><img src={clearBtnIcon} className='icnn' alt='' />Clear</button>
                          
                        </div>
                    }

                    {/* <div>
                      <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={saveData}>Save</button>
                      <button type="button" className="btn btn-clear btn-sm mb-1 me-1">Clear</button>
                    </div> */}
                  </div>
                </BoxContainer>
              </div>
            </div>

            <div className="col-12 mt-2">
             
              <div className='listdetailsct'>
                <div className='listdetailsct-in'>
                  <div className='listd-in'><img src={dob} className='icnn' alt='' /> <span style={{ color: '#1D4999', fontWeight: 'bold', fontSize: '14px' }}>Select Date</span></div>
                  <div className='listd-in'>
                    <select className="form-select form-select-sm" aria-label=".form-select-sm example">
                      <option selected>May 10, 2023 - May 16, 2023</option>
                    </select>
                  </div>
                </div>
                <div className='listdetailsct-in'>
                  <div className='listd-in'>
                    <form className="d-flex ms-auto ser" role="search">
                      <input type="search" className="form-control form-control-sm" placeholder="Search.." />
                      <i className="fa fa-search"></i>
                    </form>
                  </div>
                  <div className='listd-in'><img src={exportfile} className='icnn' alt='' /></div>
                  <div className='listd-in'><img src={printer} className='icnn' alt=''/></div>
                </div>
              </div>

              <div className="med-table-section" style={{ "height": "75vh" }}>
                <TableContainer>
                  <thead>
                    <tr>
                      <th className="" style={{ "width": "5%" }}>#</th>
                      <th>UHID</th>
                      <th>Patient Name</th>
                      <th>Hospital Info</th>
                      <th>Bloodgroup</th>
                      <th>Product</th>

                    </tr>
                  </thead>
                  <tbody>
                    {bloodRequestList && bloodRequestList.map((list, index) => {
                      return (
                        <tr>
                          <td className='' style={{ paddingLeft: '7px', fontSize: '13px' }}>{index + 1}</td>
                          <td>{list.patientUHID}</td>
                          <td><span style={{ color: '#858585', fontSize: '13px' }}>{list.patientName}</span></td>
                          <td><span style={{ color: '#7696F1', fontSize: '13px' }}>{list.hospitalName}</span><br /><span style={{ color: '#858585', fontSize: '13px' }}>{list.hospitalpAddress}</span><br /></td>
                          <td><span style={{ color: '#858585', fontSize: '13px' }}>{list.groupName}</span></td>
                          <td><span style={{ color: '#858585', fontSize: '13px' }}>{JSON.parse(list.productInfo).map((val) => {return(val.productName + '/' + val.requestedQuantity + ' ')})}</span></td>
                          
                        </tr>
                      )
                    })}



                  </tbody>
                </TableContainer>
              </div>

            </div>


          </div>
        </div>
        {showAlertToster === 1 ? (
              <AlertToster message={showMessage} handle={setShowAlertToster} />
            ) : (
              ""
            )}
           {showSuccessToster === 1 ? (
              <SuccessToster
                message={showMessage}
                handle={setShowSuccessToster}
              />
            ) : (
              ""
            )}
      </section>
    </>
  )
}
