import React, { useEffect, useRef, useState } from 'react'
import graphGIF from "../assests/images/graphGif.gif"
import ecgTextGraph from "../assests/images/ecgNameGIF.gif"
import ecgTextGraph1 from "../assests/images/ecg.gif"
import clock from "../assests/images/clock.png"
import * as dat from 'dat.gui';
import TimeCalculate from '../../PatientMonitorDashboard/Code/TimeCalculate';
export default function TopGraphSection(props) {
    let [vitalsData, setVitalData] = useState(null)
    useEffect(() => {
        (function () {

            var ECGPulse = function () {
                // this function is strict...
                this.demo = document.getElementById('demo');
                this.ctx = this.demo.getContext('2d');
                this.w = this.demo.getBoundingClientRect().width;
                this.h = this.demo.getBoundingClientRect().height;
                this.px = 0;
                this.opx = 0;
                this.py = this.h / 2;
                this.opy = this.py;
                this.speed = 0.5;
                this.scanBarWidth = 20;
                this.frame = null;

                this.demo.width = this.w;
                this.demo.height = this.h;

                this.ctx.strokeStyle = '#F4F900';
                this.ctx.lineWidth = 0.8;


                this.loop = function () {
                    this.px += this.speed;
                    this.ctx.clearRect(this.px, 0, this.scanBarWidth, this.h);

                    this.ctx.beginPath();
                    this.ctx.moveTo(this.opx, this.opy);
                    this.ctx.lineTo(this.px, this.py);
                    this.ctx.stroke();

                    for (var i = 1; i < 5; i++) {
                        this.ctx.beginPath();
                        this.ctx.moveTo(this.opx, this.opy - 100 * i);
                        this.ctx.lineTo(this.px, this.py - 100 * i);
                        this.ctx.stroke();

                        this.ctx.beginPath();
                        this.ctx.moveTo(this.opx, this.opy + 100 * i);
                        this.ctx.lineTo(this.px, this.py + 100 * i);
                        this.ctx.stroke();
                    }

                    this.opx = this.px;
                    this.opy = this.py;

                    if (this.opx > this.w) {
                        this.px = this.opx = -this.speed;
                        // console.log('opx > w');
                        //           window.cancelAnimationFrame(this.frame);
                        //           return;
                    }

                    this.frame = requestAnimationFrame(this.loop.bind(this));
                };

                this.frame = requestAnimationFrame(this.loop.bind(this));

                this.demo.onmousemove = function (e) {
                    var r = this.demo.getBoundingClientRect();
                    let arr = [623, 627, 632, 642, 650, 657, 662, 663, 669, 674, 677, 678, 672]
                    for (var i; i < arr.length; i++) {
                        console.log("cdscsdacscsdc")
                        this.py += arr[i] - r.top;
                    }
                    // py = e.clientY - r.top;
                }.bind(this);
            };

            var ecg = new ECGPulse();
            var gui = new dat.GUI();
            gui.add(ecg, 'speed', 0.1, 6);



        }());
        let temp = {}
        props.patientVitalData && props.patientVitalData.map((val, ind) => {
            if (val.vitalID.toString() === "74") {
                temp.hr = { color: val.vitalColor, date: TimeCalculate(val.vitalDateTime), value: val.vitalValue }
            }
            if (val.vitalID.toString() === "56") {
                temp.spo2 = { color: val.vitalColor, date: TimeCalculate(val.vitalDateTime), value: val.vitalValue }
            }
            if (val.vitalID.toString() === "10") {
                temp.rbs = { color: val.vitalColor, date: TimeCalculate(val.vitalDateTime), value: val.vitalValue }

            }
            if (val.vitalID.toString() === "4") {
                temp.bps = { color: val.vitalColor, date: TimeCalculate(val.vitalDateTime), value: val.vitalValue }

            }
            if (val.vitalID.toString() === "6") {
                temp.bpd = { color: val.vitalColor, date: TimeCalculate(val.vitalDateTime), value: val.vitalValue }

            }
            if (val.vitalID.toString() === "3") {
                temp.pr = { color: val.vitalColor, date: TimeCalculate(val.vitalDateTime), value: val.vitalValue }

            }
            if (val.vitalID.toString() === "7") {
                temp.rr = { color: val.vitalColor, date: TimeCalculate(val.vitalDateTime), value: val.vitalValue }

            }
            if (val.vitalID.toString() === "5") {
                temp.temp = { color: val.vitalColor, date: TimeCalculate(val.vitalDateTime), value: val.vitalValue }

            }
            if (val.vitalID.toString() === "2") {
                temp.weight = { color: val.vitalColor, date: TimeCalculate(val.vitalDateTime), value: val.vitalValue }

            }
            if (val.vitalID.toString() === "1") {
                temp.height = { color: val.vitalColor, date: TimeCalculate(val.vitalDateTime), value: val.vitalValue }

            }

        })
        setVitalData(temp)
    }, [props.patientVitalData])
    return (
        <>
            <div className='graphs-container'>
                <div className='d-flex flex-column graph-top'>
                    <div className='d-flex flex-column gap-1 '>
                        <div className='d-flex flex-row justify-content-between gap-1 top-row'>
                            <div className='d-flex flex-row justify-content-center align-items-center' style={{ position: "relative" }}>
                                <canvas id="demo" width="386px" height="50px"></canvas>
                            </div>
                            <div className='graph-left-container'>
                                <div className='d-flex flex-row justify-content-around'>
                                    <div className='d-flex flex-column'>
                                        <div className='d-flex flex-row gap-4'>
                                            <div className='graph-left-container-heading'>SPO2</div>
                                            <div className='d-flex flex-row gap-1  pt-1_ align-items-center'>
                                                <img src={clock} alt="clock" className='clk' />
                                                {/* <div className='graph-left-container-time'>1-m</div> */}
                                                <div className='graph-left-container-time'>{vitalsData && vitalsData.spo2 && vitalsData.spo2.date}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className='graph-left-container-value'>{vitalsData && vitalsData.spo2 && vitalsData.spo2.value}</span>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column gap-1_ pe-2'>
                                        <label className='graph-left-container-time mb-1'>97 %</label>
                                        <div className='d-flex flex-column gap-1_'>
                                            <div className='graph-left-container-bar'></div>
                                            <div className='graph-left-container-bar'></div>
                                            <div className='graph-left-container-bar'></div>
                                            <div className='graph-left-container-bar'></div>
                                            <div className='graph-left-container-bar'></div>
                                        </div>

                                        <label className='graph-left-container-time pb-1 text-danger'>86 %</label>
                                    </div>
                                </div>



                            </div>
                        </div>
                        <div className='d-flex flex-row justify-content-between gap-1 top-row'>
                            <div className='d-flex flex-row justify-content center align-items-center'>
                                <img src={graphGIF} alt='graph' className='graphGif' />
                            </div>
                            <div className='graph-left-container' style={{ borderRadius: '0px' }}>

                                <div className='d-flex flex-column justify-content-around ps-2'>
                                    <div className='d-flex flex-row'>
                                        <div className='d-flex flex-row gap-2'>
                                            <div className='graph-left-container-heading text-danger_' style={{ letterSpacing: "0.08em" }}>BP</div>
                                            <div className='d-flex flex-row gap-1 pt-1_ align-items-center'>
                                                <img src={clock} alt="clock"  className='clk' />
                                                <div className='graph-left-container-time'>{vitalsData && vitalsData.bps && vitalsData.bps.date}</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='d-flex flex-row gap-1_ pe-2'>
                                        <div>
                                            <span className='graph-left-container-value'>{vitalsData && vitalsData.bps && vitalsData.bps.value}</span>
                                            <span className='graph-left-container-value'>/</span>
                                            <span className='graph-left-container-value'>{vitalsData && vitalsData.bpd && vitalsData.bpd.value}</span>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className='d-flex flex-row justify-content-between gap-1 top-row'>
                            <div className='d-flex flex-row justify-content center align-items-center'>
                                <img src={graphGIF} alt='graph' className='graphGif' />
                            </div>
                            <div className='graph-left-container' style={{ borderRadius: '0px' }}>
                                <div className='d-flex flex-column justify-content-around ps-2'>
                                    <div className='d-flex flex-row'>
                                        <div className='d-flex flex-row gap-3'>
                                            <div className='graph-left-container-heading' style={{ letterSpacing: "0.02em" }}>RBS/Temp</div>
                                            <div className='d-flex flex-row gap-1 pt-1_ align-items-center ps-4'>
                                                <img src={clock} alt="clock"  className='clk' />
                                                <div className='graph-left-container-time'>{vitalsData && vitalsData.rbs && vitalsData.rbs.date}</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='d-flex flex-row gap-1_ pe-2'>
                                        <div>
                                            <span className='graph-left-container-value'>{vitalsData && vitalsData.rbs && vitalsData.rbs.value}</span>
                                            <span className='graph-left-container-value'>/</span>
                                            <span className='graph-left-container-value'>{vitalsData && vitalsData.temp && vitalsData.temp.value}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-row justify-content-between gap-1 top-row'>
                            <div className='d-flex flex-row justify-content center align-items-center'>
                                <img src={graphGIF} alt='graph' className='graphGif' />
                            </div>
                            <div className='graph-left-container' style={{ borderRadius: '0px' }}>
                                <div className='d-flex flex-column justify-content-around ps-2'>
                                    <div className='d-flex flex-row'>
                                        <div className='d-flex flex-row gap-3'>
                                            <div className='graph-left-container-heading' style={{ letterSpacing: "0.02em" }}>PR/RR</div>
                                            <div className='d-flex flex-row gap-1 pt-1_ align-items-center ps-2'>
                                                <img src={clock} alt="clock"  className='clk'/>
                                                <div className='graph-left-container-time'>{vitalsData && vitalsData.pr && vitalsData.pr.date}</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='d-flex flex-row gap-1_ pe-2'>
                                        <div>
                                            <span className='graph-left-container-value'>{vitalsData && vitalsData.pr && vitalsData.pr.value}<span style={{ fontSize: "14px" }}> RPM</span></span>
                                            <span className='graph-left-container-value' style={{ color: "#F4F900" }}>/</span>
                                            <span className='graph-left-container-value' style={{ color: "#F4F900" }}>{vitalsData && vitalsData.rr && vitalsData.rr.value}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='d-flex flex-row justify-content-between gap-1 top-row'>
                            <div className='d-flex flex-column w-100 position-relative'>
                                <div className='d-flex flex-row justify-content-between'>
                                        <span className="ecgdata">ECG</span>
                                        <span className='ecg-time'>12:01 PM (04:00pm)</span>
                                </div>
                                <div className='d-flex flex-row justify-content-between'>
                                    <img src={ecgTextGraph} alt="ecg graph" className='ecgTextGraph w-100' />
                                </div>

                                <div className='d-flex flex-row justify-content-evenly align-items-center w-100 ecg-bottom'>
                                    <div className='d-flex flex-column gap-1_ align-items-center p-1' style={{ borderRight: "1px solid #494949" }}>
                                        <span className='ecg-top-text'>HEART RATE(BPM):</span>
                                        <span className='ecg-bottom-text'>2</span>
                                    </div>
                                    <div className='d-flex flex-column gap-1_ align-items-center p-1' style={{ borderRight: "1px solid #494949" }}>
                                        <span className='ecg-top-text'>PR Interval(ms):</span>
                                        <span className='ecg-bottom-text'>2</span>
                                    </div>
                                    <div className='d-flex flex-column gap-1_ align-items-center p-1' style={{ borderRight: "1px solid #494949" }}>
                                        <span className='ecg-top-text'>QRS Complex(ms):</span>
                                        <span className='ecg-bottom-text'>2</span>
                                    </div>
                                    <div className='d-flex flex-column gap-1_ align-items-center p-1' style={{ borderRight: "1px solid #494949" }}>
                                        <span className='ecg-top-text'>QT/QTC Interval:</span>
                                        <span className='ecg-bottom-text'>2</span>
                                    </div>
                                    <div className='d-flex flex-column gap-1_ align-items-center p-1'>
                                        <span className='ecg-top-text'>Time Elapsed:</span>
                                        <span className='ecg-bottom-text'>2</span>
                                    </div>
                                </div>
                            </div>
                            <div className=' graph-left-container' style={{ height: "87px", borderRadius: '0 0 10px 0' }}>
                                <div className='d-flex flex-column justify-content-around ps-2'>
                                    <div className='d-flex flex-row'>
                                        <div className='d-flex flex-row gap-2'>
                                            <div className='graph-left-container-heading' style={{ letterSpacing: "0.02em" }}>ECG</div>
                                            <div className='d-flex flex-row gap-3 pt-1_ align-items-center ps-2'>
                                                <div className='d-flex flex-row gap-1'>
                                                    <img src={clock} alt="clock"  className='clk' />
                                                    <div className='graph-left-container-time'>1-m</div>
                                                </div>

                                                <div className='graph-left-container-value' style={{ color: "#F4F900" }}>8</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='d-flex flex-row gap-1_ pe-2'>
                                        <div className='w-100'>
                                            <span className='graph-left-container-value text-center d-flex flex-row justify-content-center w-100' style={{ fontSize: "45px", marginTop: "-15px" }} ><span>80</span></span>
                                            {/* <span className='graph-left-container-value' style={{ color: "#F4F900" }}>/</span>
                                            <span className='graph-left-container-value' style={{ color: "#F4F900" }}>8</span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}
