
import React, { useEffect, useState } from 'react'
import TimeCalculate from '../../../Code/TimeCalculate';
import LineInvestigationGrap from '../../../Components/LineInvestigationGrap';
import GetAllDashboardParameterColumnAssign from '../../../Api/GetAllDashboardParameterColumnAssign';
import Highcharts from 'highcharts';
import { useSelector } from 'react-redux';
import { getPatientMonitorDashboard } from '../../../../Reduce/PatientMonitordashboard/PatientMonitordashboard';
import store from '../../../../Store';

export default function AllFieldALBR(props) {
    let [alb, setAlb] = useState("");
    let [albId, setAlbId] = useState("");
    let [albTime, setAlbTime] = useState("");
    let [albColor, setAlbColor] = useState("");
    let [albAlert, setAlbAlert] = useState(0);
    let [uhId, setUhId] = useState("")

    // set grap Investigation data
    let [grapInvestigationData, setGrapInvestigationData] = useState({
        showGrap: 0,
        subTestId: '',
        patientName: '',
        UHID: '',
        userId: ''
    })
    let patientMonitorDashboard = useSelector((state) => state.PatientMonitorDashboard.alertData)

    let getData = () => {
        setAlb("");
        setAlbId("");
        setAlbTime("");
        setAlbColor("");
        setAlbAlert("")
        props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
            if (investigation.SubTestID.toString() === "206") {
                setAlb(Math.round(investigation.SubTestValue * 100) / 100)
                setAlbColor(investigation.SubTestColor)
                setAlbId(investigation.SubTestID)
                setUhId(props.patientData.UhId)
                if (props.handleColor !== undefined) {
                    props.handleColor(investigation.SubTestColor !== null ? investigation.SubTestColor : "white", props.columnId)
                }
                if (investigation.SubTestDateTime !== '') {
                    setAlbTime(TimeCalculate(investigation.SubTestDateTime))
                }
                let chartdata = []
                if (props.patientData.PatientNutritionAchievementList !== null && props.patientData.PatientNutritionAchievementList !== undefined) {
                    props.patientData.PatientNutritionAchievementList.map((val, ind) => {

                        if (val.NutrientID === 1420) {
                            if (val.FoodPercentage !== 0) {
                                let slice1 = {
                                    name: '',
                                    y: val.FoodPercentage,
                                    sliced: false,
                                    color: val.FoodPercentageColor
                                }
                                chartdata.push(slice1)
                            }
                            if (val.MedicinePercentage !== 0) {
                                let slice2 = {
                                    name: '',
                                    y: val.MedicinePercentage,
                                    sliced: false,
                                    color: val.MedicinePercentageColor
                                }
                                chartdata.push(slice2)

                            }
                            if (val.FluidPercentage !== 0) {
                                let slice3 = {
                                    name: '',
                                    y: val.FluidPercentage,
                                    sliced: false,
                                    color: val.FluidPercentageColor
                                }
                                chartdata.push(slice3)

                            }
                            if (val.RemainingPercentage !== 0) {
                                let slice4 = {
                                    name: '',
                                    y: val.RemainingPercentage,
                                    color: "white",
                                    sliced: false,
                                }
                                chartdata.push(slice4)

                            }

                            setTimeout(() => {
                                if (document.getElementById("albId")) {
                                    Highcharts.chart('albId',
                                        {
                                            chart: {

                                                plotShadow: false,
                                                spacing: [0, 0, 0, 0],
                                                margin: 0,
                                                type: 'pie',
                                                backgroundColor: 'transparent',
                                                width: 35,
                                                height: 35

                                            },
                                            legend: {
                                                backgroundColor: '#FFFFFF',
                                            }
                                            ,
                                            title: {
                                                text: null,
                                                align: null
                                            },
                                            tooltip: {
                                                pointFormat: null
                                            },
                                            accessibility: {
                                                point: {
                                                    valueSuffix: '%'
                                                }
                                            },
                                            plotOptions: {
                                                pie: {
                                                    size: '100%',
                                                    allowPointSelect: true,
                                                    cursor: 'pointer',
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    // colors: ['#f00', '#0f0', '#00f'],


                                                }
                                            },
                                            credits: {
                                                enabled: false,
                                            },
                                            series: [{
                                                enableMouseTracking: false,
                                                data: [...chartdata]
                                            },

                                            ]

                                        }
                                    )
                                }

                            }, 100)

                        }
                    })
                }
                if (investigation.SubTestColor !== null && investigation.SubTestColor !== "#008000" && investigation.SubTestColor !== "#94e094" && investigation.SubTestColor !== "#F5F8FF" && investigation.SubTestColor !== "") {
                    setAlbAlert(0)
                    let data = patientMonitorDashboard["alb_17"]
                    if (investigation.SubTestColor.includes("Red")) {
                        let present = data.filter(val => val === investigation.SubTestColor)
                        if (present.length === 0) {
                            store.dispatch(getPatientMonitorDashboard({ ...patientMonitorDashboard, ["alb_17"]: [investigation.SubTestColor, data[1], data[2]] }))
                            // console.log("patientMonitorDashboardspo2", { ...patientMonitorDashboard, ["alb_17"]: [investigation.SubTestColor, data[1], data[2]] })

                        }
                    }

                    else if (investigation.SubTestColor.includes("#c5756d")) {
                        let present = data.filter(val => val === investigation.SubTestColor)
                        if (present.length === 0) {
                            store.dispatch(getPatientMonitorDashboard({ ...patientMonitorDashboard, ["alb_17"]: [data[0], investigation.SubTestColor, data[2]] }))
                        }
                    }
                    else if (investigation.SubTestColor.includes("yellow")) {
                        let present = data.filter(val => val === investigation.SubTestColor)
                        if (present.length === 0) {
                            store.dispatch(getPatientMonitorDashboard({ ...patientMonitorDashboard, ["alb_17"]: [data[0], data[1], investigation.SubTestColor] }))
                        }

                    }

                }
            }
        })
    }

    let modelCloseFun = () => {
        setGrapInvestigationData({
            showGrap: 0,
            subTestId: '',
            patientName: '',
            UHID: '',
            userId: ''
        })
    }

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
        if (resp.length !== 0) {
            setPatameterData(resp[0].data)
        }

    }


    useEffect(() => {
        getColumnsParameters()
        getData()
    }, [props.patientData])
    return (
        <>
            {/* <div className={`overflow-max investigations ${(albAlert === 1 ? "blink" : "")} `} style={{ border: (albAlert === 1 ? "2px solid " + albColor : "") }}> */}
            <div className={`overflow-max investigations `}>

                {alb.length != 0 ? alb ? <span>
                    {
                        paramenterData && paramenterData.map((val, ind) => {
                            if (val.parameterId === 56) {
                                return (
                                    <>
                                        <span style={{ color: albColor }}>{alb}</span><br />

                                    </>

                                )
                            }

                            else if (val.parameterId === 57) {
                                return (
                                    <>
                                        {
                                            albTime.split("-")[1] === "D" && albTime.split("-")[0] >= 4 ? <span style={{ 'color': 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i>  {albTime.toLowerCase().replace("-", "")}</span> : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {albTime.toLowerCase().replace("-", "")}</span>}
                                        <br />
                                    </>

                                )
                            }
                        })

                    }
                    <div className="d-flex flex-row gap-2 justify-content-center align-items-center pt-1" title='ALB Graph' >
                        {

                            paramenterData && paramenterData.map((val, ind) => {
                                if (val.parameterId === 58) {
                                    return (
                                        <>
                                            <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [albId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "ALB Graph", patientName: props.patientData.PntName }) }}></i>

                                            {/* <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='ALB Graph' > */}
                                            {/* {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                                                if (val.SubtestID === albId) {
                                                    return (
                                                        <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                                                    )
                                                }
                                            })} */}


                                        </>

                                    )
                                }
                                else if (val.parameterId === 111) {
                                    return (
                                        <div id="albId" className='piechartcontainer' style={{ width: "35x", height: "35px" }}>
                                        </div>
                                    )
                                }
                            })

                        }
                    </div>


                </span > : 'N/A' : "N/A"
                }


            </div >
            {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}
        </>
    )
}
