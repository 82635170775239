export default async function GetDicomServerInstance(uhid) {

    console.log("Fsdfh")
    let url = window.AppbaseUrl + "/api/DicomServer/GetDicomServerInstance?uhId=" + uhid+"&clientId="+window.clientId+"&userId="+window.userId
    let head = { 'Content-Type': 'application/json', 'accept': '*/*', };
    let data = {};
    let responsonse = "";
    await fetch(url, {
        method: "GET",
        headers: head,
        // body: JSON.stringify(sendData),
    })
        .then(res => res.json())
        .then(data => { responsonse = data })
        .catch(error => { responsonse = error })


    return responsonse
}
