import React, { useCallback, useEffect, useState } from 'react'
import Patientname, { IsActive, PatientAdmitTime, PatientAge, PatientBed, PatientCalculator, PatientCamera, PatientChat, PatientDepartment, PatientGender, PatientProfile, PatientUhId, PatientWard } from '../DynamicComponent/LeftTableData/PatientDeatils/AllFieldPatientDeatils'
import PatientDiagonsisList, { PatientHomeCareSymtomsList } from '../DynamicComponent/LeftTableData/Diagnosis/AllFieldDiagnosis'
import PatientLifeSupport, { PatientInfusionPump, PatientInfusionPumpDataList, PatientVentiMode } from '../DynamicComponent/LeftTableData/LifeSupport/AllFieldLifeSupport'
import { FoodIntakePopUp, MedicineCheckPopUp, PAtientPACSDeatils, PatientClinicalNotifications, PatientECGGraph, PatientInvestigationDetails, PhysicalActivityPopUp, SupplementPopUp } from '../DynamicComponent/LeftTableData/Investigation/AllFieldsInvestigation'
import PatientBPR, { PatientBPRG } from '../DynamicComponent/RightTableData/BPR/AllFieldBPR'
import PatientSpo2R, { PatientSpo2RG } from '../DynamicComponent/RightTableData/SPO2R/AllFieldSPO2R'
import TempR from '../DynamicComponent/RightTableData/TempR/TempR'
import RBSR from '../DynamicComponent/RightTableData/RBSR/RBSR'
import AllfieldRRR, { AllfieldRRRG } from '../DynamicComponent/RightTableData/RRR/AllfieldRRR'
import PatientHRR, { PatientHRRG } from '../DynamicComponent/RightTableData/HRR/AllFieldHRR'
import PatientPRR, { PatientPRRG } from '../DynamicComponent/RightTableData/PRR/AllFieldPRR'
import CaplusplusR, { CaplusplusRG } from '../DynamicComponent/RightTableData/CaplusplusR/CaplusplusR'
import KPlusR, { KPlusRG } from '../DynamicComponent/RightTableData/KplusR/KPlusR'
import NaPlusR, { NaPlusRG } from '../DynamicComponent/RightTableData/NaplusR/NaPlusR'
import MgR, { MgRG } from '../DynamicComponent/RightTableData/MgR/MgR'
import PatientPHR, { PatientPHRG } from '../DynamicComponent/RightTableData/PHR/AllFieldPHR'
import PCO2R, { PCO2RG } from '../DynamicComponent/RightTableData/PCO2R/PCO2R'
import EtCO2R, { EtCO2RG } from '../DynamicComponent/RightTableData/EtCO2R/EtCO2R'
import PO2R, { PO2RG } from '../DynamicComponent/RightTableData/PO2R/PO2R'
import LactateR, { LactateRG } from '../DynamicComponent/RightTableData/LactateR/LactateR'
import HCO3R, { HCO3RG } from '../DynamicComponent/RightTableData/HCO3R/HCO3R'
import CreatinineR from '../DynamicComponent/RightTableData/CreatinineR/CreatinineR'
import BUreaR from '../DynamicComponent/RightTableData/BUreaR/BUreaR'
import IOR from '../DynamicComponent/RightTableData/IOR/IOR'
import SGOTR from '../DynamicComponent/RightTableData/SGOTR/SGOTR'
import SGTR from '../DynamicComponent/RightTableData/SGPTR/SGTR'

export default function GridCard(props) {


    let [colors, setColors] = useState([
        {
            "columnId": 1,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 2,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 3,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 4,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 5,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 6,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 7,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 8,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 9,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 10,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 11,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 12,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 13,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 14,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 15,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 16,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 17,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 18,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 19,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 20,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 21,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 22,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 23,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 24,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 25,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 26,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 27,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 28,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 29,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 30,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 31,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 32,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 33,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 34,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        },
        {
            "columnId": 35,
            "data": {
                "BP": "gray",
                "SPO2": "gray",
                "RR": "gray",
                "HR": "gray",
                "PR": "gray",
                "RBS": "gray",
                "Temp": "gray",
                "Ca+": "gray",
                "K+": "gray",
                "Na+": "gray",
                "Mg+": "gray",
                "PH": "gray",
                "PCO2": "gray",
                "EtCO2": "gray",
                "PO2": "gray",
                "Lactate": "gray",
                "HCO3": "gray",
                "Creatinine": "gray",
                "BUreaR": "gray",
                "IO": "gray",
                "SGOT": "gray",
                "SGT": "gray",
            }
        }
    ])
    const handleColor = (color = "white", columnId) => {
        document.getElementById("color" + columnId + "" + props.ind).style.backgroundColor = convertHexColorToRGBA(color)

        // setColors(temp)
    }
    let convertHexColorToRGBA = (color, alpha = 0.15) => {
        // Create a temporary canvas element if it doesn't exist
        if (color !== undefined) {
            // let getcolor = color
            // console.log("colors", getcolor, key)
            let canvas = document.getElementById('temporaryCanvas');
            if (!canvas) {
                canvas = document.createElement('canvas');
                canvas.id = 'temporaryCanvas';
                document.body.appendChild(canvas);
            }

            const ctx = canvas.getContext('2d');

            // Set the fillStyle to the input color
            ctx.fillStyle = color;

            // Draw a single pixel and extract the RGBA value
            ctx.fillRect(0, 0, 1, 1);
            const pixel = ctx.getImageData(0, 0, 1, 1).data;

            // Construct the RGBA string with the fixed alpha value
            const rgbaColor = `rgba(${pixel[0]}, ${pixel[1]}, ${pixel[2]}, ${alpha})`;

            return rgbaColor;
        }

    };
    useEffect(() => {
        console.log("props.originalColumn", props.originalColumn)
    }, [props.originalColumn])

    return (

        <div key={props.ind + "GridPMD"} className={` ${props.activeIndex !== null && props.activeIndex === props.ind ? "gridcardSelect" : "gridcard"}`} >
            <div className='d-flex flex-row gap-2 justify-content-between align-items-center' style={{ borderBottom: "1.5px solid #808080bd", fontSize: "14px", fontWeight: "750" }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" onClick={() => { props.setActiveIndex(props.ind); }} >

                <div className='col-4_ d-flex flex-row ' >
                    <Patientname patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "name"} />&nbsp;&nbsp;
                    <PatientUhId patientData={JSON.parse(props.data.patientDataList)} key={props.ind} />&nbsp;&nbsp;
                    <PatientGender patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "gender"} /> &nbsp;-&nbsp;
                    <PatientAge patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "age"} />&nbsp;&nbsp;
                    (<PatientAdmitTime patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "admtTime"} />)&nbsp;&nbsp;
                </div>
                <div className='col-7_ d-flex flex-row'>
                    <PatientDepartment patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "dep"} />&nbsp;
                    {/* <PatientWard patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "ward"} />&nbsp; */}
                    - &nbsp;
                    <PatientBed patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "bed"} />
                </div>
                {/* <IsActive patientData={JSON.parse(props.data.patientDataList)}/> */}
            </div>
            {/* <hr /> */}
            <div className='d-flex flex-row gap-2 justify-content-between align-items-center mt-1' >
                <div className='col-8_ d-flex flex-row gap-1 align-items-center'>
                    <div className='d-flex flex-column'>
                        <PatientDiagonsisList patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "diagn"} />
                        <PatientHomeCareSymtomsList patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "homediagn"} />
                    </div>
                </div>
                <div className='col-4_ d-flex flex-row gap-1 align-items-center '>
                    <PatientProfile patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "prof"} />
                    <PatientCamera patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "camera"} />
                    <PatientCalculator patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "calc"} />
                    <PatientChat patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "chat"} />
                </div>
            </div>
            <div className='d-flex flex-row gap-2 justify-content-between align-items-center mt-1' >
                <div className='d-flex flex-row gap-1 align-items-center' >
                    <PatientLifeSupport patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "lifesupp"} />
                    <PatientVentiMode patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "ventmode"} />
                    {/* <PatientInfusionPump patientData={JSON.parse(props.data.patientDataList)} /> */}

                </div>
            </div>

            <div className='d-flex flex-row gap-2 justify-content-between align-items-center mt-1' >
                <div className='d-flex flex-row gap-1 align-items-center' >
                    <PatientInfusionPump patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "imfpump"} />
                    <SupplementPopUp patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "supply"} />
                    <FoodIntakePopUp patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "food"} />
                    <MedicineCheckPopUp patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "medic"} />
                    <PhysicalActivityPopUp patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "physical"} />
                    <PatientInvestigationDetails patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "invest"} />
                    <PAtientPACSDeatils patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "pacs"} />
                    <PatientClinicalNotifications patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "clinical"} />
                    <PatientECGGraph patientData={JSON.parse(props.data.patientDataList)} key={props.ind + "ecg"} />
                    <PatientInfusionPump patientData={JSON.parse(props.data.patientDataList)} />
                </div>
            </div>

            {/* Vitals */}
            <div className='d-flex flex-row gap-2 justify-content-between align-items-center mt-1' >
                <div className='vitals-wrap' >

                    {
                        JSON.parse(props.data.patientDataList).VitalParametersList && JSON.parse(props.data.patientDataList).VitalParametersList.filter(value => value.VitalID.toString() === "4" || value.VitalID.toString() === "6").length !== 0 ?
                            props.originalColumn && props.originalColumn.filter(value => value.columnId === 12).length !== 0 ?

                                <div className='vitals-container d-flex flex-column  align-items-center justify-content-center_' id={"color" + 12 + "" + props.ind}>
                                    <div className='d-flex flex-row gap-1'>
                                        <span>BP</span>
                                        <PatientBPRG val={JSON.parse(props.data.patientDataList)} columnId={12} key={props.ind + "bp"} />
                                    </div>
                                    <PatientBPR val={JSON.parse(props.data.patientDataList)} columnId={12} handleColor={handleColor} key={props.ind + "bp"} from="grid" />
                                </div> : ""
                            :
                            ""
                    }
                    {
                        JSON.parse(props.data.patientDataList).VitalParametersList && JSON.parse(props.data.patientDataList).VitalParametersList.filter(value => value.VitalID.toString() === "56").length !== 0 ?
                            props.originalColumn && props.originalColumn.filter(value => value.columnId === 13).length !== 0 ?

                                <div className='vitals-container d-flex flex-column  align-items-center  justify-content-center_' id={"color" + 13 + "" + props.ind}>
                                    <div className='d-flex flex-row gap-1'>
                                        <span>SPO2</span>
                                        <PatientSpo2RG val={JSON.parse(props.data.patientDataList)} columnId={13} key={props.ind + "spo2"} />

                                    </div>
                                    <PatientSpo2R val={JSON.parse(props.data.patientDataList)} columnId={13} handleColor={handleColor} key={props.ind + "spo2"} from="grid" />
                                </div> : ""
                            : ""
                    }

                    {
                        JSON.parse(props.data.patientDataList).VitalParametersList && JSON.parse(props.data.patientDataList).VitalParametersList.filter(value => value.VitalID.toString() === "7").length !== 0 ?
                            props.originalColumn && props.originalColumn.filter(value => value.columnId === 14).length !== 0 ?

                                <div className='vitals-container d-flex flex-column  align-items-center  justify-content-center_' id={"color" + 14 + "" + props.ind} >
                                    <div className='d-flex flex-row gap-1'>
                                        <span>RR</span>
                                        <AllfieldRRRG val={JSON.parse(props.data.patientDataList)} columnId={14} key={props.ind + "rr"} />
                                    </div>
                                    <AllfieldRRR val={JSON.parse(props.data.patientDataList)} columnId={14} handleColor={handleColor} key={props.ind + "rr"} from="grid" />
                                </div> : ""
                            :
                            ""
                    }

                    {
                        JSON.parse(props.data.patientDataList).VitalParametersList && JSON.parse(props.data.patientDataList).VitalParametersList.filter(value => value.VitalID.toString() === "74").length !== 0 ?
                            props.originalColumn && props.originalColumn.filter(value => value.columnId === 15).length !== 0 ?

                                <div className='vitals-container d-flex flex-column  align-items-center  justify-content-center_' id={"color" + 15 + "" + props.ind}>
                                    <div className='d-flex flex-row gap-1'>
                                        <span>HR</span>
                                        <PatientHRRG val={JSON.parse(props.data.patientDataList)} columnId={15} key={props.ind + "hr"} />
                                    </div>
                                    <PatientHRR val={JSON.parse(props.data.patientDataList)} columnId={15} handleColor={handleColor} key={props.ind + "hr"} from="grid" />
                                </div> : ""
                            :
                            ""
                    }

                    {
                        JSON.parse(props.data.patientDataList).VitalParametersList && JSON.parse(props.data.patientDataList).VitalParametersList.filter(value => value.VitalID.toString() === "3").length !== 0 ?
                            props.originalColumn && props.originalColumn.filter(value => value.columnId === 16).length !== 0 ?

                                <div className='vitals-container d-flex flex-column  align-items-center  justify-content-center_' id={"color" + 16 + "" + props.ind}>
                                    <div className='d-flex flex-row gap-1'>
                                        <span>PR</span>
                                        <PatientPRRG val={JSON.parse(props.data.patientDataList)} columnId={16} key={props.ind + "pr"} />
                                    </div>
                                    <PatientPRR val={JSON.parse(props.data.patientDataList)} columnId={16} handleColor={handleColor} key={props.ind + "pr"} from="grid" />
                                </div> : ""
                            :
                            ""
                    }



                    {
                        JSON.parse(props.data.patientDataList).VitalParametersList && JSON.parse(props.data.patientDataList).VitalParametersList.filter(value => value.VitalValue.toString() === "10").length !== 0 ?
                            props.originalColumn && props.originalColumn.filter(value => value.columnId === 28).length !== 0 ?
                                <div className='vitals-container d-flex flex-column  align-items-center  justify-content-center' id={"color" + 28 + "" + props.ind}>
                                    <span>RBS</span>
                                    <RBSR patientData={JSON.parse(props.data.patientDataList)} columnId={28} handleColor={handleColor} key={props.ind + "pr"} from="grid" />
                                </div> : ""
                            : ""
                    }

                    {
                        JSON.parse(props.data.patientDataList).VitalParametersList && JSON.parse(props.data.patientDataList).VitalParametersList.filter(value => value.VitalID.toString() === "5").length !== 0 ?


                            props.originalColumn && props.originalColumn.filter(value => value.columnId === 29).length !== 0 ?

                                <div className='vitals-container d-flex flex-column  align-items-center  justify-content-center' id={"color" + 29 + "" + props.ind}>
                                    <span>Temp.</span>
                                    <TempR patientData={JSON.parse(props.data.patientDataList)} columnId={29} handleColor={handleColor} key={props.ind + "pr"} from="grid" />
                                </div>
                                : ""
                            :

                            ""
                    }


                </div>
            </div>

            {/* Investigations */}
            <div className='d-flex flex-row gap-1 justify-content-between align-items-center mt-1' >
                <div className='investigation-wrap' >
                    {
                        JSON.parse(props.data.patientDataList).InvestigationParameterList && JSON.parse(props.data.patientDataList).InvestigationParameterList.filter(value => value.SubTestID.toString() === "607").length !== 0 ?
                            props.originalColumn && props.originalColumn.filter(value => value.columnId === 22).length !== 0 ?

                                <div className='investigation-container d-flex flex-column  align-items-center  justify-content-center' id={"color" + 22 + "" + props.ind}>
                                    <div className='d-flex flex-row gap-1'>
                                        <span>PH</span>
                                        <PatientPHRG patientData={JSON.parse(props.data.patientDataList)} columnId={22} key={props.ind + "ph+"} />
                                    </div>
                                    <PatientPHR patientData={JSON.parse(props.data.patientDataList)} columnId={22} handleColor={handleColor} key={props.ind + "ph+"} from="grid" />
                                </div> : ""
                            :
                            ""
                    }

                    {
                        JSON.parse(props.data.patientDataList).InvestigationParameterList && JSON.parse(props.data.patientDataList).InvestigationParameterList.filter(value => value.SubTestID.toString() === "608").length !== 0 ?
                            props.originalColumn && props.originalColumn.filter(value => value.columnId === 23).length !== 0 ?

                                <div className='investigation-container d-flex flex-column  align-items-center  justify-content-center' id={"color" + 23 + "" + props.ind}>
                                    <div className='d-flex flex-row gap-1'>
                                        <span>PCO2</span>
                                        <PCO2RG patientData={JSON.parse(props.data.patientDataList)} columnId={23} key={props.ind + "pco2"} />
                                    </div>
                                    <PCO2R patientData={JSON.parse(props.data.patientDataList)} columnId={23} handleColor={handleColor} key={props.ind + "pco2"} from="grid" />
                                </div> : ""
                            :
                            ""
                    }

                    {
                        JSON.parse(props.data.patientDataList).InvestigationParameterList && JSON.parse(props.data.patientDataList).InvestigationParameterList.filter(value => value.SubTestID.toString() === "609").length !== 0 ?
                            props.originalColumn && props.originalColumn.filter(value => value.columnId === 25).length !== 0 ?

                                <div className='investigation-container d-flex flex-column  align-items-center  justify-content-center' id={"color" + 25 + "" + props.ind}>
                                    <div className='d-flex flex-row gap-1'>
                                        <span>PO2</span>
                                        <PO2RG patientData={JSON.parse(props.data.patientDataList)} columnId={25} key={props.ind + "po2"} />
                                    </div>
                                    <PO2R patientData={JSON.parse(props.data.patientDataList)} columnId={25} handleColor={handleColor} key={props.ind + "po2"} from="grid" />
                                </div> : ""
                            :
                            ""

                    }

                    {
                        JSON.parse(props.data.patientDataList).InvestigationParameterList && JSON.parse(props.data.patientDataList).InvestigationParameterList.filter(value => value.SubTestID.toString() === "612").length !== 0 ?
                            props.originalColumn && props.originalColumn.filter(value => value.columnId === 18).length !== 0 ?

                                <div className='investigation-container d-flex flex-column  align-items-center justify-content-center' id={"color" + 18 + "" + props.ind}>
                                    <div className='d-flex flex-row gap-1'>
                                        <span>Ca+</span>
                                        <CaplusplusRG patientData={JSON.parse(props.data.patientDataList)} columnId={18} key={props.ind + "ca+"} />
                                    </div>
                                    <CaplusplusR patientData={JSON.parse(props.data.patientDataList)} columnId={18} handleColor={handleColor} key={props.ind + "ca+"} from="grid" />
                                </div> : ""
                            :
                            ""
                    }

                    {
                        JSON.parse(props.data.patientDataList).InvestigationParameterList && JSON.parse(props.data.patientDataList).InvestigationParameterList.filter(value => value.SubTestID.toString() === "611").length !== 0 ?
                            props.originalColumn && props.originalColumn.filter(value => value.columnId === 19).length !== 0 ?

                                <div className='investigation-container d-flex flex-column  align-items-center  justify-content-center' id={"color" + 19 + "" + props.ind}>
                                    <div className='d-flex flex-row gap-1'>
                                        <span>K+</span>
                                        <KPlusRG patientData={JSON.parse(props.data.patientDataList)} columnId={19} key={props.ind + "k+"} />

                                    </div>
                                    <KPlusR patientData={JSON.parse(props.data.patientDataList)} columnId={19} key={props.ind + "k+"} from="grid" />
                                </div> : ""
                            :
                            ""
                    }


                    {
                        console.log("dsa", JSON.parse(props.data.patientDataList).InvestigationParameterList)
                    }
                    {
                        JSON.parse(props.data.patientDataList).InvestigationParameterList && JSON.parse(props.data.patientDataList).InvestigationParameterList.filter(value => value.SubTestID.toString() === "610").length !== 0 ?
                            props.originalColumn && props.originalColumn.filter(value => value.columnId === 20).length !== 0 ?

                                <div className='investigation-container d-flex flex-column  align-items-center  justify-content-center' id={"color" + 20 + "" + props.ind}>
                                    <div className='d-flex flex-row gap-1'>
                                        <span>Na+</span>
                                        <NaPlusRG patientData={JSON.parse(props.data.patientDataList)} columnId={20} key={props.ind + "na+"} />

                                    </div>
                                    <NaPlusR patientData={JSON.parse(props.data.patientDataList)} columnId={20} handleColor={handleColor} key={props.ind + "na+"} from="grid" />
                                </div> : ""
                            : ""

                    }

                    {
                        JSON.parse(props.data.patientDataList).InvestigationParameterList && JSON.parse(props.data.patientDataList).InvestigationParameterList.filter(value => value.SubTestID.toString() === "250").length !== 0 ?
                            props.originalColumn && props.originalColumn.filter(value => value.columnId === 21).length !== 0 ?

                                <div className='investigation-container d-flex flex-column  align-items-center  justify-content-center' id={"color" + 21 + "" + props.ind}>
                                    <div className='d-flex flex-row gap-1'>

                                        <span>Mg+</span>
                                        <MgRG patientData={JSON.parse(props.data.patientDataList)} columnId={21} key={props.ind + "mg+"} />

                                    </div>
                                    <MgR patientData={JSON.parse(props.data.patientDataList)} columnId={21} handleColor={handleColor} key={props.ind + "mg+"} from="grid" />
                                </div> : ""
                            :
                            ""

                    }











                    {/* 
                    <div className='investigation-container d-flex flex-column  align-items-center  justify-content-center' style={{ backgroundColor: convertHexColorToRGBA(colors.EtCO2) }}>
                        <div className='d-flex flex-row gap-1'>
                            <span>EtCO2</span>
                            <EtCO2RG patientData={JSON.parse(props.data.patientDataList)} columnId={24} setColors={setColors} key={props.ind + "etco2"} />

                        </div>
                        <EtCO2R patientData={JSON.parse(props.data.patientDataList)} columnId={24} setColors={setColors} key={props.ind + "etco2"} from="grid" />
                    </div>
                    <div className='investigation-container d-flex flex-column  align-items-center  justify-content-center' style={{ backgroundColor: convertHexColorToRGBA(colors.Lactate) }}>
                        <div className='d-flex flex-row gap-1'>
                            <span>Lactate</span>
                            <LactateRG patientData={JSON.parse(props.data.patientDataList)} columnId={26} setColors={setColors} key={props.ind + "lactate"} />
                        </div>
                        <LactateR patientData={JSON.parse(props.data.patientDataList)} columnId={26} setColors={setColors} key={props.ind + "lactate"} from="grid" />
                    </div>
                    <div className='investigation-container d-flex flex-column  align-items-center  justify-content-center' style={{ backgroundColor: convertHexColorToRGBA(colors.HCO3) }}>
                        <div className='d-flex flex-row gap-1'>
                            <span>HCO3</span>
                            <HCO3RG patientData={JSON.parse(props.data.patientDataList)} columnId={27} setColors={setColors} key={props.ind + "hco3"} />
                        </div>
                        <HCO3R patientData={JSON.parse(props.data.patientDataList)} columnId={27} setColors={setColors} key={props.ind + "hco3"} from="grid" />
                    </div>
                                     
                            <div className='vitals-container d-flex flex-column  align-items-center  justify-content-center'>
                                <span>Creatinine</span>
                                <CreatinineR patientData={JSON.parse(props.data.patientDataList)} columnId={30} />
                            </div>
                            <div className='vitals-container d-flex flex-column  align-items-center  justify-content-center'>
                                <span>BUrea</span>
                                <BUreaR patientData={JSON.parse(props.data.patientDataList)} columnId={31} />
                            </div>
                            <div className='vitals-container d-flex flex-column  align-items-center  justify-content-center'>
                                <span>IO</span>
                                <IOR patientData={JSON.parse(props.data.patientDataList)} columnId={32} />
                            </div>
                            <div className='vitals-container d-flex flex-column  align-items-center  justify-content-center'>
                                <span>SGOT</span>
                                <SGOTR patientData={JSON.parse(props.data.patientDataList)} columnId={33} />
                            </div>
                            <div className='vitals-container d-flex flex-column  align-items-center  justify-content-center'>
                                <span>SGT</span>
                                <SGTR patientData={JSON.parse(props.data.patientDataList)} columnId={34} />
                            </div>
                     */}


                </div>
            </div>
        </div>

    )
}
