import React, { useState } from "react";
import PostDischargeCard from "./API/PostDischargeCard";
import { Link, useNavigate } from 'react-router-dom'
import GetDischargeCard from "./API/GetDischargeCard";
import { useEffect } from "react";
import save from '../../../../../assets/images/icons/save.svg'
import print from '../../../../../assets/images/icons/print.svg'
import clear from '../../../../../assets/images/icons/clear.svg'
import bed from '../../../../../assets/images/icons/bed.svg'
import SuccessToster from "../../../../../Component/SuccessToster";
import AlertToster from "../../../../../Component/AlertToster";
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import Loader from "../../../../../Component/Loader";

export default function IPDDischargeBottom(props) {
  const { t } = useTranslation();
  document.body.dir = i18n.dir();

  const navigate = new useNavigate();
  let [showToster, setShowToster] = useState(0)
  let [showAlertToster, setShowAlertToster] = useState(0)
  let [showMessage, setShowMessage] = useState(0)
  let [loader, setLoader] = useState(0);
  const [currentDate, setCurrentDate] = useState('');

  console.log("props.dischargeTypeName", props.dischargeTypeName)
  useEffect(() => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}`;
    setCurrentDate(formattedDateTime);
  }, []);

  function formatDate(date) {
    const d = new Date(date);
    const day = ("0" + d.getDate()).slice(-2);
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }
  let handleSave = async () => {
    if (props.followUpDate === "" || props.followUpDate === null || props.followUpDate === undefined) {
      setShowAlertToster(1);
      setShowMessage(t(' Select Follow-Up-Date '));
      return false;
    }
    if (props.dischargeType === "" || props.dischargeType === null || props.dischargeType === undefined) {
      setShowAlertToster(1);
      setShowMessage(t('Select discharge type '));
      return false;
    }
    if (props.allMedication.length === 0 || props.allMedication === null || props.allMedication === undefined) {
      setShowAlertToster(1);
      setShowMessage(t('Fill medication details'));
      return false;
    }
    if (props.allComplain === "" || props.allComplain === null || props.allComplain === undefined || props.allComplain.length === 0) {
      setShowAlertToster(1);
      setShowMessage(t('Fill complaints & diagnosis'));
      return false;

    }
    else {
      let serachsys = props.allComplain.filter(value => value.pdmId === 2)
      if (serachsys.length === 0) {
        setShowAlertToster(1);
        setShowMessage(t('Fill chief complaints '));
        return false;
      }
      else {

        let serachconsultant = props.allComplain.filter(value => value.pdmId === 4)
        if (serachconsultant.length === 0) {
          setShowAlertToster(1);
          setShowMessage(t('Please fill consultant diagnosis '));
          return false;
        }
      }

      let procedure = {
        problemId: 0,
        problemName: props.sendprocedure,
        pdmId: 5,
      };
      let sendremarks = {
        problemId: 0,
        problemName: props.sendremarks,
        pdmId: 12,
      };
      let sendinvestigation = {
        problemId: 0,
        problemName: props.sendinvestigation,
        pdmId: 13,
      };
      props.allComplain.push(procedure);
      props.allComplain.push(sendremarks);
      props.allComplain.push(sendinvestigation);
      // props.allMedication.pop()

      var obj = {
        JsonMedication: JSON.stringify(props.allMedication),
        JsonDaignosis: JSON.stringify(props.allComplain),
        followUpDate: props.followUpDate,
        // dischargeTypeId: 2,
        dischargeTypeId: parseInt(props.dischargeType, 10),
        uhID: window.JSON.parse(sessionStorage.getItem("IPDactivePatient")).Uhid,
        // uhID:'UHID00287',
        deptID: 1,
        userID: window.userId,
      };

      // return
      setLoader(1)
      let reponse = await PostDischargeCard(obj);
      if (reponse.status === 1) {
        setLoader(0)
        window.sessionStorage.setItem(
          "PrintDischarge",
          JSON.stringify({
            allMedication: props.allMedication,
            allComplain: props.allComplain,
            followUpDate: props.followUpDate,
            dischargeDate: currentDate,
            dischargeTypeName: props.dischargeTypeName,
            // dischargeTypeId: 2,
            dischargeTypeId: parseInt(props.dischargeType, 10),
            uhID: window.JSON.parse(sessionStorage.getItem("IPDactivePatient")).Uhid,
            deptID: 1,
            userID: window.userId,
          })
        );
        setShowToster(1);
        setTimeout(() => {
          window.open("/printDischargeCard/", "noopener,noreferrer");
        }, 1500);

      }
      else {
        setShowAlertToster(1);
        setShowMessage(reponse.responseValue);
      }

    }
  };

  // let handleDischarge = async () => {
  //   if (props.followUpDate === "" || props.followUpDate === null || props.followUpDate === undefined) {
  //     setShowAlertToster(1);
  //     setShowMessage(t(' Select Follow-Up-Date '));
  //     return false;
  //   }
  //   if (props.dischargeType === "" || props.dischargeType === null || props.dischargeType === undefined) {
  //     setShowAlertToster(1);
  //     setShowMessage(t('Select discharge type '));
  //     return false;
  //   }
  //   if (props.allMedication.length === 0 || props.allMedication === null || props.allMedication === undefined) {
  //     setShowAlertToster(1);
  //     setShowMessage(t('Fill medication details'));
  //     return false;
  //   }
  //   if (props.allComplain === "" || props.allComplain === null || props.allComplain === undefined || props.allComplain.length === 0) {
  //     setShowAlertToster(1);
  //     setShowMessage(t('Fill complaints & diagnosis'));
  //     return false;

  //   }
  //   else {
  //     let serachsys = props.allComplain.filter(value => value.pdmId === 2)
  //     if (serachsys.length === 0) {
  //       setShowAlertToster(1);
  //       setShowMessage(t('Fill chief complaints '));
  //       return false;
  //     }
  //     else {

  //       let serachconsultant = props.allComplain.filter(value => value.pdmId === 4)
  //       if (serachconsultant.length === 0) {
  //         setShowAlertToster(1);
  //         setShowMessage(t('Please fill consultant diagnosis '));
  //         return false;
  //       }
  //     }

  //     let procedure = {
  //       problemId: 0,
  //       problemName: props.sendprocedure,
  //       pdmId: 5,
  //     };
  //     let sendremarks = {
  //       problemId: 0,
  //       problemName: props.sendremarks,
  //       pdmId: 12,
  //     };
  //     let sendinvestigation = {
  //       problemId: 0,
  //       problemName: props.sendinvestigation,
  //       pdmId: 13,
  //     };
  //     props.allComplain.push(procedure);
  //     props.allComplain.push(sendremarks);
  //     props.allComplain.push(sendinvestigation);

  //     var obj = {
  //       JsonMedication: JSON.stringify(props.allMedication),
  //       JsonDaignosis: JSON.stringify(props.allComplain),
  //       followUpDate: props.followUpDate,
  //       dischargeTypeId: parseInt(props.dischargeType, 10),
  //       uhID: window.JSON.parse(sessionStorage.getItem("IPDactivePatient")).Uhid,
  //       deptID: 1,
  //       userID: window.userId,
  //     };

  //     // return
  //     setLoader(1)
  //     if (obj !== '') {
  //       setLoader(0)
  //       window.sessionStorage.setItem(
  //         "PrintDischarge",
  //         JSON.stringify({
  //           allMedication: props.allMedication,
  //           allComplain: props.allComplain,
  //           followUpDate: props.followUpDate,
  //           dischargeTypeId: parseInt(props.dischargeType, 10),
  //           uhID: window.JSON.parse(sessionStorage.getItem("IPDactivePatient")).Uhid,
  //           deptID: 1,
  //           userID: window.userId,
  //         })
  //       );
  //       setShowToster(1);
  //       setTimeout(() => {
  //         window.open("/printDischargeCard/", "noopener,noreferrer");
  //       }, 1500);

  //     }
  //     else {
  //       setShowAlertToster(1);
  //       setShowMessage("Something went wrong!!");
  //     }

  //   }
  // };
  let handleDischarge = async () => {

    console.log('props', props)

    // Helper function to show an alert with a message
    const showAlert = (message) => {
      setShowAlertToster(1);
      setShowMessage(t(message));
    };

    // Check for required fields

    if (!props.dischargeType) {
      showAlert('Select discharge type');
      return false;
    }
    if (parseInt(props.dischargeType) === 2)
      if (!props.followUpDate) {
        showAlert('Select Follow-Up-Date');
        return false;
      }
    if (props.allMedication.length === 0) {
      showAlert('Fill medication details');
      return false;
    }


    if (!props.allComplain || props.allComplain.length === 0) {
      showAlert('Fill complaints & diagnosis');
      return false;
    }

    // Check for specific complaints and diagnosis
    const chiefComplaints = props.allComplain.filter(value => value.pdmId === 2);
    if (chiefComplaints.length === 0) {
      showAlert('Fill chief complaints');
      return false;
    }

    const consultantDiagnosis = props.allComplain.filter(value => value.pdmId === 4);
    if (consultantDiagnosis.length === 0) {
      showAlert('Please fill consultant diagnosis');
      return false;
    }

    // Add additional information to the complaints array
    const additionalData = [
      { problemId: 0, problemName: props.sendprocedure, pdmId: 5 },
      { problemId: 0, problemName: props.sendremarks, pdmId: 12 },
      { problemId: 0, problemName: props.sendinvestigation, pdmId: 13 },
    ];

    props.allComplain.push(...additionalData);

    // Create the object to be saved
    let temp = []
    props.allMedication.map((val, ind) => {
      if (val.drugData !== "") {
        temp.push(val)
      }
    })
    console.log("dddddd", props.allMedication)
    const obj = {

      JsonMedication: JSON.stringify(temp),
      JsonDaignosis: JSON.stringify(props.allComplain),
      followUpDate: props.followUpDate,
      dischargeTypeId: parseInt(props.dischargeType, 10),
      uhID: window.JSON.parse(sessionStorage.getItem("IPDactivePatient")).Uhid,
      deptID: 1,
      userID: window.userId,
    };

    // Show loader
    setLoader(1);

    if (obj) {
      setLoader(0);
      window.sessionStorage.setItem(
        "PrintDischarge",
        JSON.stringify({
          allMedication: temp,
          allComplain: props.allComplain,
          followUpDate: formatDate(props.followUpDate),
          dischargeDate: currentDate,
          dischargeTypeName: props.dischargeTypeName,
          dischargeTypeId: parseInt(props.dischargeType, 10),
          uhID: window.JSON.parse(sessionStorage.getItem("IPDactivePatient")).Uhid,
          deptID: 1,
          userID: window.userId,
        })
      );
      // setShowToster(1);
      setTimeout(() => {
        window.open("/printDischargeCard/", "noopener,noreferrer");
      }, 1500);
    } else {
      // showAlert("Something went wrong!!");
    }
  };

  return (
    <>
      <div className={`boxcontainer mt-1 boxs disrt`} >
        <div className="d-flex flex-row align-items-center flex-wrap gap-2 ipddisbtn" style={{ "justifyContent": "center" }}>
          {/* <button className="btn btn-save btn-sm btnbluehover">
        <img src={clear} className="icnn" alt=""/>Clear
        </button> */}


          {/* <button className="btn btn-save btn-sm btnbluehover" onClick={handleLastPrint}>
        <img src={print} className="icnn" alt="" /> Last Print
        </button> */}


          {/* <Link to='/ipdpatientlist/'> */}
          {/* <button className="btn btn-save btn-sm btnbluehover" onClick={handleDischarge}>
          <img src={bed} className="icnn" alt=""/> Discharge
        </button> */}

          {/* </Link> */}

          <button className="btn btn-save btn-sm btn-save-fill" onClick={handleDischarge}>
            <img src={save} className="icnn" alt="" />{t("Save & Print")}
          </button>
          <button className="btn btn-save btn-sm btn-save-fill" onClick={handleSave}>
            {/* <img src={save} className="icnn" alt="" />{t("Discharge_Print")} */}
            <img src={save} className="icnn" alt="" />{t("Final Discharge")}
          </button>
        </div>
        {
          showToster === 1 ?
            <SuccessToster handle={setShowToster} message={t("Patient_Discharge_SuccessFully")} /> : ""
        }

        {
          showAlertToster === 1 ?
            <AlertToster handle={setShowAlertToster} message={showMessage} /> : ""
        }
      </div>
      <Loader val={loader} />
    </>

  );
}
