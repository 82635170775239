import React, { useEffect, useState } from 'react'


import editbtn from '../../../../../assets/images/icons/editbtn.svg'
import delbtn from '../../../../../assets/images/icons/delbtn.svg'

import spo2 from '../../../../../assets/images/icons/spo2.svg'
import BP from '../../../../../assets/images/icons/BP.svg'
import PR from '../../../../../assets/images/icons/PR.svg'
import RR from '../../../../../assets/images/icons/RR.svg'
import weight from '../../../../../assets/images/icons/weight.svg'
import height from '../../../../../assets/images/icons/height.svg'
import temperature from '../../../../../assets/images/icons/temperature.svg'
import time from '../../../../../assets/images/icons/time.svg'
import takenby from '../../../../../assets/images/icons/takenby.svg'
import action from '../../../../../assets/images/icons/action.svg'
import save from '../../../../../assets/images/icons/save.svg'
import clear from '../../../../../assets/images/icons/clear.svg'
import GetData from '../../../../../assets/images/icons/GetData.svg'
import microphone from '../../../../../assets/images/icons/microphone.svg'

import BPSystolic from '../../../../../assets/images/vitalsicons/BPSystolic.svg'
import bp from '../../../../../assets/images/vitalsicons/bp.svg'
import temprature from '../../../../../assets/images/vitalsicons/temprature.svg'
import lungs from '../../../../../assets/images/vitalsicons/lungs.svg'
import heart from '../../../../../assets/images/vitalsicons/heart.svg'
import pulse from '../../../../../assets/images/vitalsicons/pulse.svg'
import spo from '../../../../../assets/images/vitalsicons/spo.svg'
import height1 from '../../../../../assets/images/vitalsicons/height1.svg'
import weight1 from '../../../../../assets/images/vitalsicons/weight1.svg'
import rbs from '../../../../../assets/images/vitalsicons/rbs.svg'
import heart2 from '../../../../../assets/images/vitalsicons/heart2.svg'
import plat from '../../../../../assets/images/vitalsicons/plat.svg'
import liver from '../../../../../assets/images/vitalsicons/liver.svg'
import fungus from '../../../../../assets/images/vitalsicons/fungus.svg'

import Pallor from '../../../../../assets/images/vitalsicons/Pallor.svg'
import Icterus from '../../../../../assets/images/vitalsicons/Icterus.svg'
import Cyanosis from '../../../../../assets/images/vitalsicons/Cyanosis.svg'
import Clubbing from '../../../../../assets/images/vitalsicons/Clubbing.svg'
import Lymphadenopathy from '../../../../../assets/images/vitalsicons/Lymphadenopathy.svg'
import Skin from '../../../../../assets/images/vitalsicons/Skin.svg'
import Tongue from '../../../../../assets/images/vitalsicons/Tongue.svg'
import Throat from '../../../../../assets/images/vitalsicons/Throat.svg'
import Conjunctivae from '../../../../../assets/images/vitalsicons/Conjunctivae.svg'
import Pupils from '../../../../../assets/images/vitalsicons/Pupils.svg'
import Nails from '../../../../../assets/images/vitalsicons/Nails.svg'
import IdentificationMarks from '../../../../../assets/images/vitalsicons/IdentificationMarks.svg'

import Lips from '../../../../../assets/images/vitalsicons/Lips.svg'
import Teeth from '../../../../../assets/images/vitalsicons/Teeth.svg'
import Gums from '../../../../../assets/images/vitalsicons/Gums.svg'

import lungs1 from '../../../../../assets/images/vitalsicons/lungs1.svg'
import PeripheralPulses from '../../../../../assets/images/vitalsicons/PeripheralPulses.svg'
import PAP from '../../../../../assets/images/vitalsicons/PAP.svg'
import CI from '../../../../../assets/images/vitalsicons/CI.svg'
import SVR from '../../../../../assets/images/vitalsicons/SVR.svg'
import PVR from '../../../../../assets/images/vitalsicons/PVR.svg'
import SvO2 from '../../../../../assets/images/vitalsicons/SvO2.svg'


import Deformity from '../../../../../assets/images/vitalsicons/Deformity.svg'
import LIMB from '../../../../../assets/images/vitalsicons/LIMB.svg'

import FIO2 from '../../../../../assets/images/vitalsicons/FIO2.svg'
import PEEP from '../../../../../assets/images/vitalsicons/PEEP.svg'
import BoxContainer from '../../../../../Component/BoxContainer'
import Heading from '../../../../../Component/Heading'
import POSTInsertPatientVital from '../../../../API/OPD/Vitals/POSTInsertPatientVital'
import TosterUnderProcess from '../../../../../Component/TosterUnderProcess'
import Toster from '../../../../../Component/Toster'
import PostVentilatorDetails from '../../../IPD/IPDSharePages/VentilatorDetails/PostVentilatorDetails';
import { useTranslation } from 'react-i18next';
import GetAllPatientVitalsHourly from '../../../../API/OPD/Vitals/GetAllPatientVitalsHourly'
import i18n from "i18next";
export default function OPDVLeft(props) {
    const { t } = useTranslation();
    document.body.dir = i18n.dir();

    let [sendForm, setSendForm] = useState({
        vmValueBPSys: "",
        vmValueBPDias: "",
        vmValueSPO2: "",
        vmValueRespiratoryRate: "",
        vmValueHeartRate: "",
        vmValuePulse: "",
        vmValueRbs: "",
        vmValueTemperature: ""


    }) //Chnage from  useState("") to useState({}) coz string data type state not accepting object value (11/29/23)
    let [showUnderProcess, setShowUnderProcess] = useState(0)
    let [showToster, setShowToster] = useState(0)
    let [tosterMessage, setTosterMessage] = useState("")
    let [tosterValue, setTosterValue] = useState(0)
    const [currentTime, setCurrentTime] = useState(new Date());
    let [showVentiUnderProcess, setShowVentiUnderProcess] = useState(0)
    let [showVentiToster, setShowVentiToster] = useState(0)
    let [ventiTosterMessage, setVentiTosterMessage] = useState("")
    let [ventiTosterValue, setVentiTosterValue] = useState(0)
    let [peepVal, setPeepVal] = useState('')
    let [fiotow, setFiotow] = useState('')
    let [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
    let [vitalsDate, setVitalsDate] = useState([]);
    let [respLen, setRespLen] = useState(0);
    let [patientViatlsData, setPatientViatlsData] = useState([]);
    let [vitalNumber, setVitalNumber] = useState([4, 7, 56, 3, 5, 74, 10, 6,]);
    let vitalImg = [{ icon: BP, name: "BP" }, { icon: RR, name: "RR" }, { icon: spo2, name: "spo2" }, { icon: PR, name: "PR" }, { icon: temperature, name: "Temp." }, { icon: heart, name: 'HR' }, { icon: BP, name: "RBS" }, { icon: height, name: "Ht." }, { icon: weight, name: "Wt." }];
    let [testVitals, setTestVitals] = useState();
    let [currentdatemax, setcurrentdatemax] = useState();
    // let [currenttimemax,setcurrenttimemax]=useState();
    let [vitaltime, setvitaltime] = useState();
    let uhid = ""
    if (props.callingpage === 0) {
        uhid = window.sessionStorage.getItem("activePatient") ? JSON.parse(window.sessionStorage.getItem("activePatient")).Uhid : ""
    }
    else if (props.callingpage === 1) {
        uhid = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : ""
    };
    const [loader, setLoader] = useState(1);
    let makeData = (data) => {

        let temp = []
        let main = []
        let flag = -1
        data.map((val, ind) => {
            // console.log("val", val, ind)

            vitalNumber.map((vv, ii) => {

                // let resp = FindByQuery(JSON.parse(val.json), vv.toString(), "vmId")
                let resp = JSON.parse(val.json).filter(vals => vals.vmId.toString() === vv.toString())
                // console.log("data", resp, val)
                if (resp.length !== 0) {
                    // console.log("BP=>"+ ii, resp[0].vmId)
                    if (resp.length !== 0 && resp[0].vmId !== 6 && resp[0].vmId !== 4) {

                        temp.push(resp[0].vmValue)

                    }
                    else if (resp.length !== 0 && resp[0].vmId === 4) {
                        if (flag !== -1) {
                            temp[flag] = temp[flag] + resp[0].vmValue.toString()
                        }
                        else {
                            temp.push(resp[0].vmValue)
                            flag = temp.length - 1
                        }
                    }
                    else if (resp.length !== 0 && resp[0].vmId === 6) {
                        if (flag !== -1) {
                            temp[flag] = temp[flag] + "/" + resp[0].vmValue.toString()
                        }
                        else {
                            temp.push(resp[0].vmValue)
                            flag = temp.length - 1
                        }
                    }
                }
                else {
                    temp.push("-")

                }

            })
            // console.log("main", main)
            main.push(temp)
            temp = []
            flag = -1

        })

        function transpose(a) {
            return Object.keys(a[0]).map(function (c) {
                return a.map(function (r) { return r[c]; });
            });
        }
        // console.log("asa", transpose(main))
        setTestVitals(transpose(main))
    }

    let validationVitals = () => {
        let bpsVal = document.getElementById('vmValueBPSys').value;
        let bpdVal = document.getElementById('vmValueBPDias').value;
        let spo2Val = document.getElementById('vmValueSPO2').value;
        let RRVal = document.getElementById('vmValueRespiratoryRate').value;
        let HRVal = document.getElementById('vmValueHeartRate').value;
        let PRVal = document.getElementById('vmValuePulse').value;
        let TempVal = document.getElementById('vmValueTemperature').value;
        let RBSVal = document.getElementById('vmValueRbs').value;
        // if (bpsVal === '' && bpdVal === '' && spo2Val === '' && RRVal === '' && HRVal === '' && PRVal === '' && TempVal === '' && RBSVal === '') {
        //     document.getElementById('errVital').innerHTML = "Please enter any vital";
        //     document.getElementById('errVital').style.display = "block";
        //     return 1
        // }
        if (bpsVal > 500) {
            document.getElementById('errBPS').innerHTML = "Enter valid value";
            document.getElementById('errBPS').style.display = "block";
            return 1
        }
        else if (bpdVal > 1000) {
            document.getElementById('errBPD').innerHTML = "Enter valid value";
            document.getElementById('errBPD').style.display = "block";
            return 1
        }
        else if (spo2Val > 100) {
            document.getElementById('errSPO2').innerHTML = "Enter valid value";
            document.getElementById('errSPO2').style.display = "block";
            return 1
        }
        else if (RRVal > 50) {
            document.getElementById('errRR').innerHTML = "Enter valid value";
            document.getElementById('errRR').style.display = "block";
            return 1
        }
        else if (HRVal > 220) {
            document.getElementById('errHR').innerHTML = "Enter valid value";
            document.getElementById('errHR').style.display = "block";
            return 1
        }
        else if (PRVal > 220) {
            document.getElementById('errPR').innerHTML = "Enter valid value";
            document.getElementById('errPR').style.display = "block";
            return 1
        }
        else if (TempVal > 110) {
            document.getElementById('errTemp').innerHTML = "Enter valid value";
            document.getElementById('errTemp').style.display = "block";
            return 1
        }
        else if (RBSVal > 2400) {
            document.getElementById('errRBS').innerHTML = "Enter valid value";
            document.getElementById('errRBS').style.display = "block";
            return 1
        }
        else {
            return 0
        }
    }
    let getVitalDatas = async () => {
        // let response = await GetAllPatientVital(uhid)
        let response = await GetAllPatientVitalsHourly(curdate(), uhid);
        //let response = await GetAllPatientVitalsHourly('2023-01-11', 2597700);
        // console.log('response', response);
        if (response.status === 1 && response.responseValue.length !== 0) {
            setLoader(0);

            // console.log('dataaa', response.responseValue);
            // let data = getvitaldata(response.responseValue)
            setPatientViatlsData(response.responseValue);
            makeData(response.responseValue)
            props.setGetData(0)
            setVitalsDate(response.responseValue[0].date);
            setRespLen(response.responseValue.length);
        }
        else {
            setLoader(0);
        }

        // let len = response.responseValue.length;
        // if (len > 0) {
        // }
    }

    let saveData = async () => {
        if (props.callingpage === 0) {
            sendForm["uhid"] = window.sessionStorage.getItem("activePatient") ? JSON.parse(window.sessionStorage.getItem("activePatient")).Uhid : ""
        }
        else if (props.callingpage === 1) {
            sendForm["uhid"] = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : ""

        }
        // sendForm["uhid"] = JSON.parse(window.sessionStorage.getItem("activePatient")).Uhid
        sendForm["userId"] = window.userId
        sendForm["vitalDate"] = document.getElementById('vitalDate').value
        sendForm["vitalTime"] = document.getElementById('vitalTime').value
        let dateVal = document.getElementById('vitalDate').value;
        let timeVal = document.getElementById('vitalTime').value;
        let bpsVal = document.getElementById('vmValueBPSys').value;
        let bpdVal = document.getElementById('vmValueBPDias').value;
        let spo2Val = document.getElementById('vmValueSPO2').value;
        let RRVal = document.getElementById('vmValueRespiratoryRate').value;
        let HRVal = document.getElementById('vmValueHeartRate').value;
        let PRVal = document.getElementById('vmValuePulse').value;
        let TempVal = document.getElementById('vmValueTemperature').value;
        let RBSVal = document.getElementById('vmValueRbs').value;
        if (dateVal === '' || dateVal === undefined || dateVal === null) {
            document.getElementById('errDate').innerHTML = "Please select Date";
            document.getElementById('errDate').style.display = "block";
        }
        if (bpsVal === '' && bpdVal === '' && spo2Val === '' && RRVal === '' && HRVal === '' && PRVal === '' && TempVal === '' && RBSVal === '') {
            document.getElementById('errVital').innerHTML = "Please enter any vital";
            document.getElementById('errVital').style.display = "block";
        }
        else if (timeVal === '' || timeVal === undefined || timeVal === null) {
            document.getElementById('errTime').innerHTML = "Please select Time";
            document.getElementById('errTime').style.display = "block";
        }
        else if (bpsVal > 500) {
            document.getElementById('errBPS').innerHTML = "Enter valid value";
            document.getElementById('errBPS').style.display = "block";
        }
        else if (bpdVal > 1000) {
            document.getElementById('errBPD').innerHTML = "Enter valid value";
            document.getElementById('errBPD').style.display = "block";
        }
        else if (spo2Val > 100) {
            document.getElementById('errSPO2').innerHTML = "Enter valid value";
            document.getElementById('errSPO2').style.display = "block";
        }
        else if (RRVal > 50) {
            document.getElementById('errRR').innerHTML = "Enter valid value";
            document.getElementById('errRR').style.display = "block";
        }
        else if (HRVal > 220) {
            document.getElementById('errHR').innerHTML = "Enter valid value";
            document.getElementById('errHR').style.display = "block";
        }
        else if (PRVal > 220) {
            document.getElementById('errPR').innerHTML = "Enter valid value";
            document.getElementById('errPR').style.display = "block";
        }
        else if (TempVal > 110) {
            document.getElementById('errTemp').innerHTML = "Enter valid value";
            document.getElementById('errTemp').style.display = "block";
        }
        else if (RBSVal > 2400) {
            document.getElementById('errRBS').innerHTML = "Enter valid value";
            document.getElementById('errRBS').style.display = "block";
        }

        else {

            setShowUnderProcess(1)
            console.log('form-------------', sendForm);
            let response = await POSTInsertPatientVital(sendForm)
            if (response.status === 1) {
                handleClear()
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage("Data Saved SuccessFully!")
                setTosterValue(0)
                setTimeout(() => {
                    setShowToster(0)


                }, 2000)

                props.setGetData(Math.random())
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage(response.responseValue)
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)

            }

        }
        getVitalDatas();

    }

    let handleOnChange = (e) => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const time = `${hours}:${minutes}`;
        document.getElementById('errVital').style.display = "none";
        let name = e.target.name;
        let value = e.target.value;
        if (value.length !== 0) {
            if (name === "vitalDate") {
                document.getElementById("errDate").style.display = "none";
                document.getElementById("errTemp").style.display = "none";
                if (value.toString().length <= 3) {
                    let values = validationVitals()
                    if (values === 0) {
                        setSendForm(sendForm => ({
                            ...sendForm,
                            [name]: value
                        }))
                    }


                }
            }
            else if (name === "vitalTime") {
                // document.getElementById("errTime").style.display = "none";
                // document.getElementById("errTemp").style.display = "none";
                if (value > time) {
                    return false
                }
                else {
                    // setfromDate(datevalue);
                    setvitaltime(value);
                    setSendForm(sendForm => ({
                        ...sendForm,
                        [name]: value
                    }))
                }
                // if (value.toString().length <= 3) {
                //     let values = validationVitals()
                //     if (values === 0) {
                //         setSendForm(sendForm => ({
                //             ...sendForm,
                //             [name]: value
                //         }))
                //     }

                // }
            }
            else if (name === "vmValueBPSys") {
                document.getElementById("errBPS").style.display = "none";
                document.getElementById("errTemp").style.display = "none";
                if (value.toString().length <= 3) {
                    let values = validationVitals()

                    if (values === 0) {
                        setSendForm(sendForm => ({
                            ...sendForm,
                            [name]: value
                        }))
                    }
                    else {
                        setSendForm(sendForm => ({
                            ...sendForm,
                            [name]: ""
                        }))
                    }

                }
            }
            else if (name === "vmValueBPDias") {
                document.getElementById("errBPD").style.display = "none";
                document.getElementById("errTemp").style.display = "none";
                if (value.toString().length <= 3) {
                    let values = validationVitals()
                    if (values === 0) {
                        setSendForm(sendForm => ({
                            ...sendForm,
                            [name]: value
                        }))
                    }
                    else {
                        setSendForm(sendForm => ({
                            ...sendForm,
                            [name]: ""
                        }))
                    }

                }
            }
            else if (name === "vmValueSPO2") {
                document.getElementById("errSPO2").style.display = "none";
                document.getElementById("errTemp").style.display = "none";
                if (value.toString().length <= 3) {
                    let values = validationVitals()
                    if (values === 0) {
                        setSendForm(sendForm => ({
                            ...sendForm,
                            [name]: value
                        }))
                    }
                    else {
                        setSendForm(sendForm => ({
                            ...sendForm,
                            [name]: ""
                        }))
                    }


                }
            }
            else if (name === "vmValueRespiratoryRate") {
                document.getElementById("errRR").style.display = "none";
                document.getElementById("errTemp").style.display = "none";
                if (value.toString().length <= 3) {
                    let values = validationVitals()
                    if (values === 0) {
                        setSendForm(sendForm => ({
                            ...sendForm,
                            [name]: value
                        }))
                    }
                    else {
                        setSendForm(sendForm => ({
                            ...sendForm,
                            [name]: ""
                        }))
                    }

                }
            }
            else if (name === "vmValueHeartRate") {
                document.getElementById("errHR").style.display = "none";
                document.getElementById("errTemp").style.display = "none";
                if (value.toString().length <= 3) {
                    let values = validationVitals()
                    if (values === 0) {
                        setSendForm(sendForm => ({
                            ...sendForm,
                            [name]: value
                        }))
                    }
                    else {
                        setSendForm(sendForm => ({
                            ...sendForm,
                            [name]: ""
                        }))
                    }

                }
            }
            else if (name === "vmValuePulse") {
                document.getElementById("errPR").style.display = "none";
                document.getElementById("errTemp").style.display = "none";
                if (value.toString().length <= 3) {
                    let values = validationVitals()
                    if (values === 0) {
                        setSendForm(sendForm => ({
                            ...sendForm,
                            [name]: value
                        }))
                    }
                    else {
                        setSendForm(sendForm => ({
                            ...sendForm,
                            [name]: ""
                        }))
                    }

                }
            }
            else if (name === "vmValueRbs") {
                document.getElementById("errRBS").style.display = "none";
                if (value.toString().length <= 3) {
                    let values = validationVitals()
                    if (values === 0) {
                        setSendForm(sendForm => ({
                            ...sendForm,
                            [name]: value
                        }))
                    }
                    else {
                        setSendForm(sendForm => ({
                            ...sendForm,
                            [name]: ""
                        }))
                    }

                }

            }
            else if (name === "vmValueTemperature") {
                document.getElementById("errTemp").style.display = "none";
                const regex = /^\d{1,3}(\.\d{1,2})?$/;

                if (regex.test(value.toString())) {
                    let values = validationVitals()
                    if (values === 0) {
                        setSendForm(sendForm => ({
                            ...sendForm,
                            [name]: value
                        }))
                    }
                    else {
                        setSendForm(sendForm => ({
                            ...sendForm,
                            [name]: ""
                        }))
                    }

                }
            }
        }
        else {
            setSendForm(sendForm => ({
                ...sendForm,
                [name]: value
            }))
        }



    };

    let handleClear = () => {
        document.getElementById('errVital').style.display = "none";
        document.getElementById('errBPS').style.display = "none";
        document.getElementById("errBPD").style.display = "none";
        document.getElementById("errSPO2").style.display = "none";
        document.getElementById("errRR").style.display = "none";
        document.getElementById("errHR").style.display = "none";
        document.getElementById("errPR").style.display = "none";
        document.getElementById("errRBS").style.display = "none";
        document.getElementById("errTemp").style.display = "none";
        document.getElementById('vmValueBPSys').value = '';
        document.getElementById('vmValueBPDias').value = '';
        document.getElementById('vmValueTemperature').value = '';
        document.getElementById('vmValueRespiratoryRate').value = '';
        document.getElementById('vmValueHeartRate').value = '';
        document.getElementById('vmValuePulse').value = '';
        document.getElementById('vmValueSPO2').value = '';
        // document.getElementById('weight').value = '';
        // document.getElementById('height').value = '';
        document.getElementById('vmValueRbs').value = '';
        document.getElementById('vitalTime').value = '';
        // document.getElementById('vitalDate').value = '';
        document.getElementById('vitalDate').value = curdate();
        document.getElementById('vitalTime').value = formattedTime;
        setSendForm(
            {
                vmValueBPSys: "",
                vmValueBPDias: "",
                vmValueSPO2: "",
                vmValueRespiratoryRate: "",
                vmValueHeartRate: "",
                vmValuePulse: "",
                vmValueRbs: "",
                vmValueTemperature: ""
            }
        )
        console.log('hi')
    };
    let curdate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        setcurrentdatemax(formattedDate)
        document.getElementById('vitalDate').value = formattedDate;
        return formattedDate;
    };
    const initializeCurrenttime = () => {
        const input = document.getElementById('vitalTime');

        if (input) {
            const now = new Date();
            const hours = now.getHours().toString().padStart(2, '0'); // Ensure two digits
            const minutes = now.getMinutes().toString().padStart(2, '0'); // Ensure two digits

            const vitaltime = `${hours}:${minutes}`;

            input.setAttribute('max', vitaltime);
        }
    };
    const formattedTime = currentTime.toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
    });
    let handlerChangeVenti = (e) => {
        document.getElementById("errFio").style.display = "none"
        document.getElementById("errPeep").style.display = "none"
        if (e.target.name === "fiotow") {
            if (e.target.value.toString().length <= 3) {
                setFiotow(e.target.value);
            }
        }
        else if (e.target.name === "peepVal") {
            if (e.target.value.toString().length <= 2) {
                setPeepVal(e.target.value);
            }
        }
    }

    let handlerSaveVenti = async () => {
        let getPmID = "";
        const getActiveUhid = sessionStorage.getItem("IPDactivePatient") ? JSON.parse(sessionStorage.getItem("IPDactivePatient")).Uhid : JSON.parse(sessionStorage.getItem("activePatient")).Uhid;
        const getpatientList = sessionStorage.getItem("IPDpatientList") ? JSON.parse(sessionStorage.getItem("IPDpatientList")) : JSON.parse(sessionStorage.getItem("patientList"));
        getpatientList.map((val, i) => {
            if (val.uhId === getActiveUhid) {
                getPmID = val.pmId;
                return;
            }
        });
        if (fiotow === '' || fiotow < 1 || fiotow === undefined || fiotow === null) {
            document.getElementById("errFio").style.display = "block"
        }
        else if (peepVal === '' || peepVal < 1 || peepVal === undefined || peepVal === null) {
            document.getElementById("errPeep").style.display = "block"
        }
        else {
            const clientID = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
            const obj = {
                id: 0,
                pmId: getPmID,
                userId: userID,
                pid: 0,
                peep: peepVal,
                fiO2: fiotow,
                isFromMachine: false,
                ventiMode: 0,
                ClientId: clientID
            }

            setShowVentiUnderProcess(1)
            const response = await PostVentilatorDetails(obj);
            if (response.status === 1) {
                props.setGetData(Math.random())
                setShowVentiUnderProcess(0)
                setShowVentiToster(1)
                setVentiTosterMessage("Data Save SuccessFully!")
                setVentiTosterValue(0)
                setTimeout(() => {
                    setShowVentiToster(0)
                }, 2000)
                setFiotow('');
                setPeepVal('');
                handleClear()

            }

            else {
                setShowVentiUnderProcess(0)
                setShowVentiToster(1)
                setVentiTosterMessage(response.responseValue)
                setVentiTosterValue(1)
                setTimeout(() => {
                    setShowVentiToster(0)
                }, 2000)
                handleClear()
            }


        }
    }
    useEffect(() => {
        curdate();
        getVitalDatas();
        initializeCurrenttime();
        // document.getElementById('vitalTime').value = formattedTime;
    }, []);

    useEffect(() => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const time = `${hours}:${minutes}`;
        setvitaltime(time)
        document.getElementById('vitalTime').value = time;
        // const currenttime = new Date().toLocaleTimeString('en-US', { hour12: false }).slice(0, 5); 
        // setcurrenttime(currenttime);


    }, []);



    return (
        <div className='vitallt'>
            <div className="boxcontainer whitebackgroundnopad wb" style={{ minHeight: "17vh" }}>
                <div className='d-flex justify-content-between'>
                    <div className='tblheading'>Vital Input</div>

                    <div className='mt-2  d-flex gap-1 vital-btn-date'>

                        <div>
                            <div className='vitals-cnt-in10'>
                                <input type='date' name="vitalDate" id='vitalDate' onChange={handleOnChange} max={currentdatemax} />
                            </div>
                            <small id="errDate" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>

                        <div>
                            <div className='vitals-cnt-in10'>
                                <input type='time' name="vitalTime" id='vitalTime' value={vitaltime} onChange={handleOnChange} />
                            </div>
                            <small id="errTime" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>

                    </div>


                </div>

                <div className='vital-sub-heading'>General10</div>
                <div className='vitals-cnt customeInput'>

                    <div className='vitals-cnt-in'>
                        <img src={BPSystolic} className='icnn1' />
                        <input type='number' placeholder='BPS(mm Hg)' value={sendForm.vmValueBPSys} name='vmValueBPSys' id='vmValueBPSys' autoFocus={true} onChange={handleOnChange} style={{ width: '27px' }} />
                        <small id="errBPS" className="absolute-alert" style={{ display: 'none' }}></small>
                        <span className='slashh'>/</span>
                        <input type='number' placeholder='BPD(mm Hg)' value={sendForm.vmValueBPDias} name='vmValueBPDias' id='vmValueBPDias' onChange={handleOnChange} style={{ width: '82px' }} />
                        <small id="errBPD" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>

                    {/* <div className='vitals-cnt-in'>
                        <img src={BPSystolic} className='icnn1' />
                        <input type='number' placeholder='BPS(mm Hg)' value={sendForm.vmValueBPSys} name='vmValueBPSys' id='vmValueBPSys' autoFocus={true} onChange={handleOnChange} />
                        <small id="errBPS" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>

                    <div className='vitals-cnt-in'>
                        <img src={bp} className='icnn1' />
                        <input type='number' placeholder='BPD(mm Hg)' value={sendForm.vmValueBPDias} name='vmValueBPDias' id='vmValueBPDias' onChange={handleOnChange} />
                        <small id="errBPD" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div> */}
                    <div className='vitals-cnt-in'>
                        <img src={spo} className='icnn1' />
                        <input type='number' placeholder='SPO2' value={sendForm.vmValueSPO2} name='vmValueSPO2' id='vmValueSPO2' onChange={handleOnChange} style={{ width: '90px' }} />
                        <small id="errSPO2" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={lungs} className='icnn1' />
                        <input type='number' placeholder='RR/m' value={sendForm.vmValueRespiratoryRate} name='vmValueRespiratoryRate' id='vmValueRespiratoryRate' onChange={handleOnChange} />
                        <small id="errRR" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    {/* <div className='vitals-cnt-in'>
                        <img src={bp} className='icnn1' />
                        <input type='text' placeholder='BP_S-BP_D' style={{ width: "65px" }} />
                    </div> */}
                    <div className='vitals-cnt-in'>
                        <img src={heart} className='icnn1' />
                        <input type='number' placeholder='HR' value={sendForm.vmValueHeartRate} name='vmValueHeartRate' id='vmValueHeartRate' onChange={handleOnChange} />
                        <small id="errHR" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>

                    {/* <div className='vitals-cnt-in'>
                        <img src={temprature} className='icnn1' />
                        <input type='text' placeholder='Temp(C)' style={{ width: "50px" }} />   
                    </div> */}


                    <div className='vitals-cnt-in'>
                        <img src={pulse} className='icnn1' />
                        <input type='number' placeholder='Pulse(Beats)' value={sendForm.vmValuePulse} name='vmValuePulse' id='vmValuePulse' onChange={handleOnChange} />
                        <small id="errPR" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={temprature} className='icnn1' />
                        <input type='number' placeholder='Temp(°F)' value={sendForm.vmValueTemperature} name='vmValueTemperature' id='vmValueTemperature' onChange={handleOnChange} style={{ width: '85px' }} />
                        <small id="errTemp" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>
                    {/* <div className='vitals-cnt-in'>
                        <img src={height1} className='icnn1' />
                        <input type='number' placeholder='Ht (cm)' name='height' id='height' onChange={handleOnChange} />
                    </div> */}
                    {/* <div className='vitals-cnt-in'>
                        <img src={weight1} className='icnn1' />
                        <input type='number' placeholder='Wt (kg)' name='weight' id='weight'  onChange={handleOnChange} />
                    </div> */}
                    <div className='vitals-cnt-in'>
                        <img src={rbs} className='icnn1' />
                        <input type='number' placeholder='RBS (mg/dl)' value={sendForm.vmValueRbs} name='vmValueRbs' id='vmValueRbs' onChange={handleOnChange} />
                        <small id="errRBS" className="absolute-alert" style={{ display: 'none' }}></small>
                    </div>



                    {/* <div className='vitals-cnt-in'>
                        <img src={heart2} className='icnn1' />
                        <input type='text' placeholder='Iabp Ratio' style={{ width: "60px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={plat} className='icnn1' />
                        <input type='text' placeholder='P(Plat)' style={{ width: "40px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={liver} className='icnn1' />
                        <input type='text' placeholder='Trigger' style={{ width: "50px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={temprature} className='icnn1' />
                        <input type='text' placeholder='Peripheral Temp.' style={{ width: "95px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={fungus} className='icnn1' />
                        <input type='text' placeholder='Fungus' style={{ width: "50px" }} />
                    </div> */}


                </div>
                <small id="errVital" className="form-text text-danger" style={{ display: 'none' }}></small>

                {/* <div className='mainsubheading'>Head To Toe</div>
                <div className='vitals-cnt'>
 
                    <div className='vitals-cnt-in'>
                        <img src={Pallor} className='icnn1' />
                        <input type='text' placeholder='Pallor' style={{ width: "40px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={Icterus} className='icnn1' />
                        <input type='text' placeholder='Icterus' style={{ width: "40px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={Cyanosis} className='icnn1' />
                        <input type='text' placeholder='Cyanosis' style={{ width: "50px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={Clubbing} className='icnn1' />
                        <input type='text' placeholder='Clubbing' style={{ width: "55px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={Lymphadenopathy} className='icnn1' />
                        <input type='text' placeholder='Lymphadenopathy' style={{ width: "105px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={Skin} className='icnn1' />
                        <input type='text' placeholder='Skin' style={{ width: "30px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={Tongue} className='icnn1' />
                        <input type='text' placeholder='Tongue' style={{ width: "50px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={Throat} className='icnn1' />
                        <input type='text' placeholder='Throat' style={{ width: "50px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={Conjunctivae} className='icnn1' />
                        <input type='text' placeholder='Conjunctivae' style={{ width: "75px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={Pupils} className='icnn1' />
                        <input type='text' placeholder='Pupils' style={{ width: "50px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={Nails} className='icnn1' />
                        <input type='text' placeholder='Nails' style={{ width: "35px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={IdentificationMarks} className='icnn1' />
                        <input type='text' placeholder='Identification Marks' style={{ width: "110px" }} />
                    </div>
                </div>

                <div className='mainsubheading'>Oral</div>
                <div className='vitals-cnt'>

                    <div className='vitals-cnt-in'>
                        <img src={Lips} className='icnn1' />
                        <input type='text' placeholder='Lips' style={{ width: "50px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={Teeth} className='icnn1' />
                        <input type='text' placeholder='Teeth' style={{ width: "50px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={Gums} className='icnn1' />
                        <input type='text' placeholder='Gums' style={{ width: "50px" }} />
                    </div>

                </div>

                <div className='mainsubheading'>CVS</div>
                <div className='vitals-cnt'>

                    <div className='vitals-cnt-in'>
                        <img src={lungs1} className='icnn1' />
                        <input type='text' placeholder='CV P(my)' style={{ width: "60px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={lungs1} className='icnn1' />
                        <input type='text' placeholder='SV V' style={{ width: "50px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={lungs1} className='icnn1' />
                        <input type='text' placeholder='PAW P(my)' style={{ width: "70px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={PeripheralPulses} className='icnn1' />
                        <input type='text' placeholder='Peripheral Pulses_R' style={{ width: "115px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={PeripheralPulses} className='icnn1' />
                        <input type='text' placeholder='Peripheral Pulses_L' style={{ width: "115px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={PAP} className='icnn1' />
                        <input type='text' placeholder='PAP' style={{ width: "50px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={CI} className='icnn1' />
                        <input type='text' placeholder='CI' style={{ width: "30px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={SVR} className='icnn1' />
                        <input type='text' placeholder='SVR' style={{ width: "50px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={PVR} className='icnn1' />
                        <input type='text' placeholder='PVR' style={{ width: "50px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={SvO2} className='icnn1' />
                        <input type='text' placeholder='SvO2' style={{ width: "50px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={lungs} className='icnn1' />
                        <input type='text' placeholder='CO' style={{ width: "30px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={heart2} className='icnn1' />
                        <input type='text' placeholder='Pedal Edema' style={{ width: "75px" }} />
                    </div>

                </div>


                <div className='mainsubheading'>Ortho</div>
                <div className='vitals-cnt'>

                    <div className='vitals-cnt-in'>
                        <img src={Deformity} className='icnn1' />
                        <input type='text' placeholder='Deformity Types & Deg' style={{ width: "135px" }} />
                    </div>
                    <div className='vitals-cnt-in'>
                        <img src={LIMB} className='icnn1' />
                        <input type='text' placeholder='LIMB Length Discrepan' style={{ width: "127px" }} />
                    </div>

                </div> */}

                <div className='inner-content'>
                    <div className='d-flex flex-wrap   gap-3 p-2 boxcontainer justify-content-end'>
                        <div className="justify-content-end">
                            <div className='diet-btn vital-btn '>

                                {/* <button type="button" className="btn btn-save btn-save-fill btn-sm"><img src={GetData} className='icnn' /> Get Data</button>
                            <button type="button" className="btn btn-save btn-save-fill btn-sm" name='vitalDate' ><img src={microphone} className='icnn' /> Voice Data</button> */}
                                {showUnderProcess === 1 ? <TosterUnderProcess /> : <>
                                    {
                                        showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} /> :
                                            <button type="button" className="btn btn-save btn-save-fill btn-sm " name='vitalTime' onClick={saveData} ><img src={save} className='icnn' /> {t("Save")}</button>

                                    }
                                </>}

                                <button type="button" className="btn btn-save btn-sm btnbluehover" onClick={handleClear}><img src={clear} className='icnn' /> {t("Clear")}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* <div className="mt-2 boxcontainer">
                <div className='tblheading'>Ventilator Details</div>
                <p className='note'><strong>Note :</strong> Keep the PEEP value as 5 when you assign the ventilator first time.</p>
                <BoxContainer>
                    <div className="mb-2 me-2">
                        <img src={FIO2} className='icnn' /> <label htmlFor="FIO2" className="form-label">FIO2</label>
                        <label className="form-control form-control-sm mt-2">40</label>
                    </div>
                    <div className="mb-2 me-2">
                        <img src={PEEP} className='icnn' /> <label htmlFor="PEEP" className="form-label">PEEP</label>
                        <label className="form-control form-control-sm mt-2">10</label>
                    </div>
                    <div className="mb-2 me-2">
                        <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                        <div className='diet-btn'>
                            <button type="button" className="btn btn-save btn-save-fill btn-sm mt-2"><img src={save} className='icnn' /> Save</button>
                        </div>
                    </div>
                </BoxContainer>
            </div> */}
            <div className="boxcontainer whitebackgroundnopad wb" >
                <div className='tblheading'>{t("Vital Chart History")}</div>
                <div className='wb45'>
                    <div className="med-table-section noshadow" style={{ "height": "31vh" }}>
                        <table className='med-table border striped' >

                            <tbody>
                                <tr style={{ flexWrap: 'nowrap' }}>
                                    <td><b>{vitalsDate}</b></td>
                                    <td className='text-left' colspan={respLen} > <b>{t("Hours")}</b></td>
                                </tr>
                                <tr style={{ flexWrap: 'nowrap' }}>
                                    <td ><b>{t("Vitals /Time")}</b></td>
                                    {patientViatlsData && patientViatlsData.map((list, index) => {
                                        // { console.log('patientViatlsData', patientViatlsData) }
                                        return (
                                            <td ><b>{list.showVitalTime}</b></td>
                                        )
                                    })}
                                </tr>
                                {
                                    patientViatlsData && vitalNumber.map((val, ind) => {
                                        // console.log("val", val)
                                        return (
                                            <tr>
                                                <td>
                                                    <div className='d-flex align-items-center '>
                                                        {val !== 6 ? <img src={vitalImg[ind].icon} className='icnn' /> : ""}
                                                        {val !== 6 ? <span>{vitalImg[ind].name}</span> : ""}
                                                    </div>
                                                </td>
                                                {
                                                    val !== 6 ? testVitals && testVitals[ind].map((v, i) => { return (<td>{v}</td>) }) : ""
                                                }
                                            </tr>
                                        )
                                    })



                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* <div className="boxcontainer whitebackgroundnopad wb" style={{ minHeight: "60vh" }}>
                <div className='tblheading'>Ventilator Details</div>
                <p className='note'><strong>Note :</strong> Keep the PEEP value as 5 when you assign the ventilator first time.</p>
                <BoxContainer>
                    <div className="mb-2 me-2">
                        <img src={FIO2} className='icnn' /> <label htmlFor="FIO2" className="form-label">FIO2</label>
                        <input type="number" className="form-control form-control-sm mt-2" placeholder='FIO2' value={fiotow} name='fiotow' onChange={handlerChangeVenti} />
                        <small id="errFio" className="form-text text-danger" style={{ display: 'none' }}>Please Fill FIO2</small>
                    </div>
                    <div className="mb-2 me-2">
                        <img src={PEEP} className='icnn' /> <label htmlFor="PEEP" className="form-label" >PEEP</label>

                        <input type="number" className="form-control form-control-sm mt-2" placeholder='PEEP' value={peepVal} name="peepVal" onChange={handlerChangeVenti} />
                        <small id="errPeep" className="form-text text-danger" style={{ display: 'none' }}>Pleae Fill Peep</small>
                    </div>
                    <div className="mb-2 me-2">
                        <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                        <div className='diet-btn'>
                            {showVentiUnderProcess === 1 ? <TosterUnderProcess /> : <>
                                {
                                    showVentiToster === 1 ? <Toster value={ventiTosterValue} message={ventiTosterMessage} /> :
                                        <button type="button" className="btn btn-save btn-save-fill btn-sm mt-2" onClick={handlerSaveVenti}><img src={save} className='icnn' /> Save</button>

                                }
                            </>}

                        </div>
                    </div>
                </BoxContainer>
            </div> */}

        </div>

    )
}
