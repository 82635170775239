import React, { useState } from 'react'
import { useEffect } from 'react';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import Toster from '../../../Component/Toster';
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Heading from '../../../Component/Heading';
import BoxContainer from '../../../Component/BoxContainer';
import TableContainer from '../../../Component/TableContainer';
import SuccessToster from '../../../Component/SuccessToster';
import AlertToster from '../../../Component/AlertToster';
import Loader from '../../../Component/Loader';
import GetDesignationList from '../Api/ShiftDesignationMapping/GetDesignationList';
import DropdownWithSearch from '../../../Component/DropdownWithSearch';
import SaveTaskCategory from '../Api/TaskCategoryMaster/SaveTaskCategory';
import GetTaskCategoryList from '../Api/TaskCategoryMaster/GetTaskCategoryList';
import UpdateTaskCategory from '../Api/TaskCategoryMaster/UpdateTaskCategory';
import DeleteTaskCategory from '../Api/TaskCategoryMaster/DeleteTaskCategory';
import GetMenuList from '../Api/TaskCategoryMaster/GetMenuList';
import Search from '../../../Code/Serach';
import { t } from 'i18next';


export default function TaskCategoryMaster() {
  let [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
  let [clientID, setClientID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientId);
  let [showUnderProcess, setShowUnderProcess] = useState(0);
  let [showToster, setShowToster] = useState(0);
  let [tosterMessage, setTosterMessage] = useState("");
  let [tosterValue, setTosterValue] = useState(0);
  let [showLoder, setShowLoder] = useState(0);
  let [isShowToaster, setisShowToaster] = useState(0);
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [showErrMessage, setShowErrMessage] = useState('');
  let [showSuccessMsg, setShowSuccessMsg] = useState('');
  let [designationList, setDesignationList] = useState([]);
  let [menuList, setMenuList] = useState([]);
  let [taskCategoryMasterList, setTaskCategoryMasterList] = useState([]);
  let [taskCategoryMasterListMain, setTaskCategoryMasterListMain] = useState([]);
  let [shiftList, setShiftList] = useState('');
  let [updateBool, setUpdateBool] = useState(0);
  let [rowID, setRowId] = useState(0);
  let [editDesignationDdl, setEditeditDesignationDdltDdl] = useState('');
  let [editMenuDDL, setEditMenuDDL] = useState('');
  let [clearDropdown, setClearDropdown] = useState(0);
  let [isOrder, setIsOrder] = useState(true);
  let [sendForm, setSendForm] = useState({
    category: '',
    designation: '',
    menu: '',
  })
  let handleChange = async (e) => {
    clearValidationErrMessage();
    setEditeditDesignationDdltDdl("");
    setEditMenuDDL("");
    const { name, value } = e.target;
    setSendForm((prevData) => ({ ...prevData, [name]: value }));

  }
  const getTaskCategoryList = async () => {
    setShowLoder(1);
    const response = await GetTaskCategoryList(clientID);
    if (response.status === 1) {
      setTaskCategoryMasterList(response.responseValue);
      setTaskCategoryMasterListMain(response.responseValue);
      setShowLoder(0)
    }
    else {
      setShowLoder(0);
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
      setTimeout(() => {
        setShowAlertToster(0);
      }, 1500)
    }
  }

  const getDesignationList = async () => {
    const response = await GetDesignationList();
    console.log("test dropdown data",response)
    if (response.status === 1) {
      setDesignationList(response.responseValue);
    }
    else {
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
      setTimeout(() => {
        setShowAlertToster(0);
      }, 1500)
    }
  }

  const getMenuList = async () => {
    const response = await GetMenuList();
    if (response.status === 1) {
      setMenuList(response.responseValue);
    }
    else {
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
      setTimeout(() => {
        setShowAlertToster(0);
      }, 1500)
    }
  }


  //handle search
  let handleSearch = (e) => {
    let resp = Search(taskCategoryMasterListMain, e.target.value)
    if (e.target !== "") {
      if (resp.length !== 0) {
        setTaskCategoryMasterList(resp)
      }
      else {
        setTaskCategoryMasterList([])
      }
    }
    else {
      setTaskCategoryMasterList(taskCategoryMasterListMain)
    }
  }

  const handlerSave = async () => {
    if (sendForm.category === '' || sendForm.category === null || sendForm.category === undefined) {
      document.getElementById('errCategoryName').innerHTML = "Category is Required";
      document.getElementById('errCategoryName').style.display = "block";
    }
    else if (sendForm.designation === '' || sendForm.designation === 0 || sendForm.designation === null || sendForm.designation === undefined) {
      document.getElementById('errDesignation').innerHTML = "Designation is Required";
      document.getElementById('errDesignation').style.display = "block";
    }
    // else if (sendForm.url === '' || sendForm.url === null || sendForm.url === undefined) {
    //   document.getElementById('errUrl').innerHTML = "Url is Required";
    //   document.getElementById('errUrl').style.display = "block";
    // }
    else if (sendForm.menu === '' || sendForm.menu === null || sendForm.menu === undefined) {
      document.getElementById('errMenu').innerHTML = "Menu is Required";
      document.getElementById('errMenu').style.display = "block";
    }

    else {
      // setShowUnderProcess(1);
      var obj = {

        "id": rowID,
        "designationId": sendForm.designation,
        "categoryName": sendForm.category,
        "menuId": sendForm.menu,
        "userID": userID,
        "clientId": clientID,
        "isOrder": isOrder,

      }
      const response = await SaveTaskCategory(obj);
      if (response.status === 1) {
        setShowUnderProcess(0);
        setTosterValue(0);
        setShowToster(1);
        setTosterMessage("Updated Successfully..");
        setTimeout(() => {
          setShowToster(0);
          handleClear(1);
          getTaskCategoryList();

        }, 1500)
      }
      else {
        setShowUnderProcess(0);
        setTosterValue(1);
        setShowToster(1);
        setTosterMessage(response.responseValue);
        setTimeout(() => {
          setShowToster(0);
        }, 1500)
      }
    }
  }
  const handleEdit = (params, value) => {
    setUpdateBool(1);
    clearValidationErrMessage();
    setRowId(params.id);
    setEditeditDesignationDdltDdl(params.designationName);
    setEditMenuDDL(params.menuData === null ? "" : params.menuData.menuName);
    setSendForm({
      category: params.categoryName,
      designation: params.designationId,
      menu: params.menuId,
    })
  }
  // const handlerUpdate = async () => {
  //   if (sendForm.category === '' || sendForm.category === null || sendForm.category === undefined) {
  //     document.getElementById('errCategoryName').innerHTML = "Category is Required";
  //     document.getElementById('errCategoryName').style.display = "block";
  //   }
  //   else if (sendForm.designation === '' || sendForm.designation === null || sendForm.designation === undefined) {
  //     document.getElementById('errDesignation').innerHTML = "Designation is Required";
  //     document.getElementById('errDesignation').style.display = "block";
  //   }
  //   else if (sendForm.url === '' || sendForm.url === null || sendForm.url === undefined) {
  //     document.getElementById('errUrl').innerHTML = "Url is Required";
  //     document.getElementById('errUrl').style.display = "block";
  //   }
  //   else {
  //     setShowUnderProcess(1);
  //     var obj = {

  //       "id": rowID,
  //       "designationId": sendForm.designation,
  //       "categoryName": sendForm.category,
  //       "url": sendForm.url,
  //       "userID": userID,
  //       "clientId": clientID
  //     }
  //     console.log('update Obj', obj);

  //     const response = await UpdateTaskCategory(obj);
  //     console.log('save Response', response);
  //     if (response.status === 1) {
  //       setShowUnderProcess(0);
  //       setTosterValue(0);
  //       setShowToster(1);
  //       setTosterMessage("Updated Successfully..");
  //       setTimeout(() => {
  //         setShowToster(0);
  //         handleClear(1);
  //         getTaskCategoryList();

  //       }, 1500)
  //     }
  //     else {
  //       setShowUnderProcess(0);
  //       setTosterValue(1);
  //       setShowToster(1);
  //       setTosterMessage(response.responseValue);
  //       setTimeout(() => {
  //         setShowToster(0);
  //       }, 1500)
  //     }
  //   }
  // }
  // const handleDelete = async () => {
  //   var obj = {
  //     rowID: rowID,
  //     userID: userID
  //   }
  //   setShowLoder(1);
  //   const response = await DeleteTaskCategory(obj);
  //   if (response.status === 1) {
  //     setShowLoder(0)
  //     setisShowToaster(1);
  //     setShowSuccessMsg("Deleted Successfully")
  //     setTimeout(() => {
  //       setisShowToaster(0);
  //       getTaskCategoryList();
  //     }, 1500)
  //     handleClear(1);
  //   }
  //   else {
  //     setShowLoder(0);
  //     setShowAlertToster(1);
  //     setShowErrMessage(response.responseValue);
  //     setTimeout(() => {
  //       setShowAlertToster(0);
  //     }, 1500)
  //   }
  // }
  const clearValidationErrMessage = () => {
    document.getElementById('errCategoryName').style.display = "none";
    document.getElementById('errDesignation').style.display = "none";
    // document.getElementById('errUrl').style.display = "none";
    document.getElementById('errMenu').style.display = "none";
  }
  const handleClear = (value) => {
    clearValidationErrMessage();
    setRowId('')
    setUpdateBool(0);
    setEditeditDesignationDdltDdl("");
    setEditMenuDDL("");
    setClearDropdown(value);
    setIsOrder(true);
    setSendForm({
      category: '',
      designation: '',
      menu: '0',
    });


  }

  useEffect(() => {
    getTaskCategoryList();
    getDesignationList();
    getMenuList();
  }, []);

  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Heading text="Task Category Master" />
              <BoxContainer>
                <div className="col-2 mb-2 me-2">
                  <label htmlFor="category" className="form-label">Category Name<span className="starMandatory">*</span></label>
                  <input type="text" className="form-control form-control-sm" name="category" id="category" value={sendForm.category} disabled />
                  <small id="errCategoryName" className="invalid-feedback" style={{ display: 'none' }}></small>
                </div>
                <div className="col-2 mb-2 me-2">
                  <label htmlFor="ShiftFrom" className="form-label">Designation<span className="starMandatory">*</span></label>
                  {designationList &&
                    <DropdownWithSearch
                      defaulNname="Select Designation"
                      name="designation"
                      list={designationList}
                      valueName="id"
                      displayName="designationName"
                      editdata={editDesignationDdl}
                      getvalue={handleChange}
                      clear={clearDropdown}
                      clearFun={handleClear}
                    />
                  }
                  <small id="errDesignation" className="invalid-feedback" style={{ display: 'none' }}></small>

                </div>

                {/* <div className="col-2 mb-2 me-2">
                  <label htmlFor="url" className="form-label">Url<span className="starMandatory">*</span></label>
                  <input type="text" className="form-control form-control-sm" name="url" id="url" placeholder='Enter Url' value={sendForm.url} onChange={handleChange} />
                  <small id="errUrl" className="invalid-feedback" style={{ display: 'none' }}></small>
                </div> */}

                <div className="col-2 mb-2 me-2">
                  <label htmlFor="url" className="form-label">Menu<span className="starMandatory">*</span></label>
                  {menuList &&
                    <DropdownWithSearch
                      defaulNname="Select Menu"
                      name="menu"
                      list={menuList}
                      valueName="id"
                      displayName="menuName"
                      editdata={editMenuDDL}
                      getvalue={handleChange}
                      clear={clearDropdown}
                      clearFun={handleClear}
                    />
                  }
                  <small id="errMenu" className="invalid-feedback" style={{ display: 'none' }}></small>
                </div>
                <div className="col-2 mb-2 me-2">
                  <label htmlFor="url" className="form-label">Duty Type<span className="starMandatory">*</span></label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="isOrder" id="Order" onClick={() => { setIsOrder(true) }} defaultChecked={isOrder === true ? true : false} />
                      <label className="form-check-label" for="Order" style={{ fontSize: '14px', color: '#0000009c' }}>Order</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="isOrder" id="CheckList" onClick={() => { setIsOrder(false) }} defaultChecked={isOrder === false ? true : false} />
                      <label className="form-check-label" for="CheckList" style={{ fontSize: '14px', color: '#0000009c' }}>Check List</label>
                    </div>
                  </div>
                  <small id="errDutyType" className="invalid-feedback" style={{ display: 'none' }}></small>
                </div>



                <div className="mb-2 relative">
                  <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                  <div>
                    {showUnderProcess === 1 ? <TosterUnderProcess /> :
                      <>
                        {showToster === 1 ?
                          <Toster value={tosterValue} message={tosterMessage} />

                          : <div>
                            {updateBool === 1 ?

                              <>
                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={handlerSave}>Update</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { handleClear(1) }}>Cancel</button>
                              </>
                              :
                              ''
                            }
                          </div>}
                      </>
                    }
                  </div>
                </div>
                {/* <>
                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handlerSave}><img src={saveButtonIcon} className='icnn' />Save</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { handleClear(1) }}><img src={clearIcon} className='icnn' />Clear</button>
                              </> */}

              </BoxContainer>

            </div>
            <div className="col-12 mt-2">
              <div className='handlser'>
                <Heading text="Task Category Master List" />
                <div style={{ position: 'relative' }}>
                  <input type="text" className='form-control form-control-sm' placeholder={t("Search")} onChange={handleSearch} />
                  <span className="tblsericon"><i className="fas fa-search"></i></span>
                </div>
              </div>
              <div className="med-table-section" style={{ "height": "75vh" }}>
                <TableContainer>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>Category</th>
                      <th>Designation Name</th>
                      <th>Menu Name</th>
                      <th style={{ "width": "10%" }} className="text-center">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {taskCategoryMasterList && taskCategoryMasterList.map((val, ind) => {
                      return (
                        <tr key={val.id}>
                          <td className="text-center">{ind + 1}</td>
                          <td>{val.categoryName}</td>
                          <td>{val.designationName}</td>
                          <td>{val.menuData !== null ? val.menuData.menuName : ''}</td>
                          <td>
                            <div className="action-button">
                              <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={editBtnIcon} className='' alt='' onClick={() => { handleEdit(val, 1) }} /></div>
                              {/* <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowId(val.id) }} />
                              </div> */}
                            </div>
                          </td>
                        </tr>
                      )
                    })}


                  </tbody>
                </TableContainer>
                {/* -----------------------Start Delete Modal Popup-------------------   */}

                {/*  <!-- Modal -->  */}
                {/* <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                  <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                      <div className="modal-body modelbdy text-center">
                        <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                        <div className='popDeleteTitle mt-3'>Delete?</div>
                        <div className='popDeleteContent'>Are you sure want to delete?</div>
                      </div>
                      <div className="modal-footer1 text-center">

                        <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn-delete popBtnDelete" onClick={handleDelete} data-bs-dismiss="modal">Delete</button>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

              </div>
            </div>
          </div>
        </div>
        {
          showLoder === 1 ? <Loader val={showLoder} /> : ""
        }
        {/* Toaster */}
        {
          isShowToaster === 1 ?
            <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
        }

        {
          showAlertToster === 1 ?
            <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
        }
      </section>
    </>
  )
}
