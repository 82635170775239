import React, { useState } from 'react'
import { useEffect } from 'react';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import Toster from '../../../Component/Toster';
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Heading from '../../../Component/Heading';
import BoxContainer from '../../../Component/BoxContainer';
import TableContainer from '../../../Component/TableContainer';
import SuccessToster from '../../../Component/SuccessToster';
import AlertToster from '../../../Component/AlertToster';
import Loader from '../../../Component/Loader';
import DropdownWithSearch from '../../../Component/DropdownWithSearch';
import GetShiftDesignationMapping from '../Api/ShiftDesignationMapping/GetShiftDesignationMapping';
import SaveShiftDesignationMapping from '../Api/ShiftDesignationMapping/SaveShiftDesignationMapping';
import UpdateShiftDesignationMapping from '../Api/ShiftDesignationMapping/UpdateShiftDesignationMapping';
import DeleteShiftDesignationMapping from '../Api/ShiftDesignationMapping/DeleteShiftDesignationMapping';
import GetShiftList from '../Api/ShiftMaster/GetShiftList';
import GetDesignationList from '../Api/ShiftDesignationMapping/GetDesignationList';
import Search from '../../../Code/Serach';
import { t } from 'i18next';

export default function ShiftDesignationMapping() {
    let [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
    let [clientID, setClientID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientId);
    let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [showToster, setShowToster] = useState(0);
    let [tosterMessage, setTosterMessage] = useState("");
    let [tosterValue, setTosterValue] = useState(0);
    let [showLoder, setShowLoder] = useState(0);
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [shiftDesignationMappingList, setShiftDesignationMappingList] = useState([]);
    let [shiftDesignationMappingListMain, setShiftDesignationMappingListMain] = useState([]);
    let [shiftList, setShiftList] = useState([]);
    let [designationList, setDesignationList] = useState([]);
    let [updateBool, setUpdateBool] = useState(0);
    let [rowID, setRowId] = useState(0);
    let [editShiftDdl, setEditShiftDdl] = useState('');
    let [editDesignationDdl, setEditeditDesignationDdltDdl] = useState('');
    let [clearDropdown, setClearDropdown] = useState(0);
    let[sendForm,setSendForm]=useState({
        shift:'',
        designation:'',
    })
    let handleChange= async(e)=>{
       clearValidationErrMessage()
       setEditeditDesignationDdltDdl("")
       setEditShiftDdl("")
        const {name, value}= e.target;
        setSendForm((prevData)=>({...prevData,[name]:value})); 
      
    }
   const getMappingtList= async()=>{
    setShowLoder(1);
    const response = await GetShiftDesignationMapping();
    if(response.status === 1){
      setShiftDesignationMappingList(response.responseValue);
      setShiftDesignationMappingListMain(response.responseValue);
      setShowLoder(0)
    }
    else{
      setShowLoder(0);
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
      setTimeout(()=>{
        setShowAlertToster(0);
      },1500)
    }
   }
   const getShiftList= async()=>{
    const response = await GetShiftList();
    if(response.status === 1){
        setShiftList(response.responseValue);
    }
    else{
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
      setTimeout(()=>{
        setShowAlertToster(0);
      },1500)
    }
   }
   const getDesignationList= async()=>{
    const response = await GetDesignationList();
    if(response.status === 1){
        setDesignationList(response.responseValue);
    }
    else{
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
      setTimeout(()=>{
        setShowAlertToster(0);
      },1500)
    }
   }
   
  //handle search
  let handleSearch = (e) => {
    let resp = Search(shiftDesignationMappingListMain, e.target.value)
    if (e.target !== "") {
      if (resp.length !== 0) {
        setShiftDesignationMappingList(resp)
      }
      else {
        setShiftDesignationMappingList([])
      }
    }
    else {
      setShiftDesignationMappingList(shiftDesignationMappingListMain)
    }
  }

   const handlerSave = async()=>{
    if(sendForm.shift === '' || sendForm.shift === null || sendForm.shift === undefined){
      document.getElementById('errShift').innerHTML="Shift is Required";
      document.getElementById('errShift').style.display="block";
    }
    else if(sendForm.designation === '' || sendForm.designation === null || sendForm.designation === undefined){
      document.getElementById('errDesignation').innerHTML="Designation is Required";
      document.getElementById('errDesignation').style.display="block";
    }
   
    else{
      setShowUnderProcess(1);
      var obj =
      {
        "id": 0,
        "shiftId":  sendForm.shift,
        "designationId":  sendForm.designation,
        "userId": userID,
        "clientId": clientID 
      }
      const response = await SaveShiftDesignationMapping(obj);
      if(response.status === 1){
        setShowUnderProcess(0);
        setTosterValue(0);
        setShowToster(1);
        setTosterMessage("Saved Successfully..");
        setTimeout(()=>{
          setShowToster(0);
          handleClear(1);
          getMappingtList();
        },1500)
      }
      else{
        setShowUnderProcess(0);
        setTosterValue(1);
        setShowToster(1);
        setTosterMessage(response.responseValue);
        setTimeout(()=>{
          setShowToster(0);
        },1500)
      }
    }
   }
   const handleEdit=(params)=>{
    setUpdateBool(1);
    setRowId(params.id);
    setEditShiftDdl(params.shiftName);
    setEditeditDesignationDdltDdl(params.designation);
    setSendForm({
      shift:params.shiftId,
      designation:params.desiignationId,
      
  })
   }
   const handlerUpdate = async()=>{
    if(sendForm.shift === '' || sendForm.shift === null || sendForm.shift === undefined){
        document.getElementById('errShift').innerHTML="Shift is Required";
        document.getElementById('errShift').style.display="block";
      }
      else if(sendForm.designation === '' || sendForm.designation === null || sendForm.designation === undefined){
        document.getElementById('errDesignation').innerHTML="Designation is Required";
        document.getElementById('errDesignation').style.display="block";
      }
    else{
      setShowUnderProcess(1);
      var obj ={
        "id": rowID,
        "shiftId": sendForm.shift,
        "designationId": sendForm.designation,
        "userId": userID,
        "clientId": clientID
       
      }
      const response = await UpdateShiftDesignationMapping(obj);
      if(response.status === 1){
        setShowUnderProcess(0);
        setTosterValue(0);
        setShowToster(1);
        setTosterMessage("Updated Successfully..");
        setTimeout(()=>{
          setShowToster(0);
          handleClear(1);
          getMappingtList();
          
        },1500)
      }
      else{
        setShowUnderProcess(0);
        setTosterValue(1);
        setShowToster(1);
        setTosterMessage(response.responseValue);
        setTimeout(()=>{
          setShowToster(0);
        },1500)
      }
    }
   }
   const handleDelete = async()=>{
    setShowLoder(1);
    const response = await DeleteShiftDesignationMapping(rowID);
    if(response.status === 1){
      setShowLoder(0)
      setisShowToaster(1);
      setShowSuccessMsg("Deleted Successfully")
      setTimeout(()=>{
        setisShowToaster(0);
        getMappingtList();
      },1500)
    }
    else{
      setShowLoder(0);
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
      setTimeout(()=>{
        setShowAlertToster(0);
      },1500)
    }
   }
   const clearValidationErrMessage=()=>{
    document.getElementById('errShift').style.display="none";
    document.getElementById('errDesignation').style.display="none";
   }
   const handleClear=(value)=>{
    clearValidationErrMessage();
    setUpdateBool(0);
    setClearDropdown(value);
    setEditShiftDdl("");
    setEditeditDesignationDdltDdl("");
    setSendForm({
        shift:'',
        designation:'',
    })
   }
    useEffect(()=>{
        getMappingtList();
        getShiftList();
        getDesignationList();
    },[]);
  
  return (
    <>
      <section className="main-content mt-5 pt-3"> 
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Heading text="Shift Designation Mapping" />
              <BoxContainer>
                <div className="col-2 mb-2 me-2">
                  <label htmlFor="shift" className="form-label">Shift</label>
                  {/* <select className="form-select" name="shift" id="shift" value={sendForm.shift} onChange={handleChange}>
                    <option value=''>Select Shift</option>
                  </select> */}
                 {shiftList && 
                    <DropdownWithSearch
                        defaulNname="Select Shift"
                        name="shift"
                        list={shiftList.map(val => ({
                            id: val.id,
                            displayName: `${val.shiftName}  (${val.shiftTimeFrom} - ${val.shiftTimeTo})`,
                        }))}
                        valueName="id"
                        displayName="displayName"
                        editdata={editShiftDdl}
                        getvalue={handleChange}
                        clear={clearDropdown}
                        clearFun={handleClear}
                    />
                 }
                  <small id="errShift" className="invalid-feedback" style={{ display: 'none' }}></small>
                  


                </div>
                <div className="col-2 mb-2 me-2">
                  <label htmlFor="designation" className="form-label">Designation</label>
                  {/* <input type="time" className="form-control form-control-sm" name="designation" id="designation"  value={sendForm.designation} onChange={handleChange}/> */}
                  {designationList && 
                    <DropdownWithSearch
                        defaulNname="Select Designation"
                        name="designation"
                        list={designationList}
                        valueName="id"
                        displayName="designationName"
                        editdata={editDesignationDdl}
                        getvalue={handleChange}
                        clear={clearDropdown}
                        clearFun={handleClear}
                    />
                 }
                  <small id="errDesignation" className="invalid-feedback" style={{ display: 'none' }}></small>

                </div>
               

                <div className="mb-2 relative">
                  <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                  <div>
                    {showUnderProcess === 1 ? <TosterUnderProcess /> :
                      <>
                        {showToster === 1 ?
                          <Toster value={tosterValue} message={tosterMessage} />

                          : <div>
                            {updateBool === 0 ?
                              <>
                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handlerSave}><img src={saveButtonIcon} className='icnn' />Save</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={()=>{handleClear(1)}}><img src={clearIcon} className='icnn' />Clear</button>
                              </>
                              :
                              <>
                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={handlerUpdate}>Update</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={()=>{handleClear(1)}}>Cancel</button>
                              </>
                            }
                          </div>}
                      </>
                    }
                  </div>
                </div>
              </BoxContainer>
            </div>
            <div className="col-12 mt-2">
              <div className='handlser'>
                <Heading text="Shift Designation Mapping List" />
                <div style={{ position: 'relative' }}>
                  <input type="text" className='form-control form-control-sm' placeholder={t("Search")} onChange={handleSearch} />
                  <span className="tblsericon"><i className="fas fa-search"></i></span>
                </div>
              </div>
              <div className="med-table-section" style={{ "height": "75vh" }}>
                <TableContainer>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>Shift Name</th>
                      <th>Designation</th>
                      <th style={{ "width": "10%" }} className="text-center">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {shiftDesignationMappingList && shiftDesignationMappingList.map((val, ind) => {
                      return (
                        <tr key={val.id}>
                          <td className="text-center">{ind + 1}</td>
                          <td>{val.shiftName} ({val.shiftFrom} - {val.shiftTo})</td>
                          <td>{val.designation}</td>
                          <td>
                            <div className="action-button">
                              <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={editBtnIcon} className='' alt='' onClick={() => {handleEdit(val)}} /></div>
                              <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowId(val.id) }} />
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}


                  </tbody>
                </TableContainer>
                {/* -----------------------Start Delete Modal Popup-------------------   */}

                {/*  <!-- Modal -->  */}
                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                  <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                      <div className="modal-body modelbdy text-center">
                        <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                        <div className='popDeleteTitle mt-3'>Delete?</div>
                       <div className='popDeleteContent'>Are you sure want to delete?</div>
                      </div>
                      <div className="modal-footer1 text-center">

                        <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn-delete popBtnDelete" onClick={handleDelete} data-bs-dismiss="modal">Delete</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

              </div>
            </div>
          </div>
        </div>
        {
                    showLoder === 1 ? <Loader val={showLoder} /> : ""
                }
                {/* Toaster */}
                {
                    isShowToaster === 1 ?
                        <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                }
               
                {
                    showAlertToster === 1 ?
                        <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                }
      </section>
    </>
  )
}
