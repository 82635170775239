async function InsertDiagnosisPreSedation(data) {
    let url = window.AppbaseUrl + '/api/ProcedureWithoutSedation/InsertPreDiagnosis?uhID='+data.uhId+'&jsonDaignosis='+data.diagnosis+'&userId='+window.userId+'&clientID='+window.clientId
    let head = {
        'Content-Type': 'application/JSON',
        accept: '*/*',
    }
    let response =
        await fetch(url, {
            method: 'POST',
            headers: head,
            body: JSON.stringify({})
        })
            .then((res) => res.json())
            .then(data)


    return response;
}
export default InsertDiagnosisPreSedation;
