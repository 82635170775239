

export default async function GetDepositDetailsByPmId(UHID,pmId){    
   
  let clientId = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId
    let URL = window.BillingbaseUrl +`/api/AddAdvance/GetAllAdvanceListByUhidAndPmid?uhid=${UHID}&clientId=${clientId}&pmId=${pmId}`;
 
    let head = {"Content-Type":"application/json", 'accept':'*/*'};
 
    
    let response = "";
    response = await fetch(URL, {
        headers:head,
        method:"GET"        
    })
    .then(res => res.json())  
    .catch(error => response=error);    
    return response;
 }