import React, { useEffect, useState } from "react";
import GetProjectWiseTask from "../API/Report/GetProjectWisTask";
import GetProjectMaster from '../API/ProjectMaster/GetProjectMaster'
import TableContainer from '../../Component/TableContainer'
import Heading from '../../Component/Heading'
import DropdownWithSearch from '../../Component/DropdownWithSearch'
import exportFromJSON from "export-from-json";
import SuccessToster from '../../Component/SuccessToster'
import WarningToaster from '../../Component/WarningToaster'
import AlertToster from '../../Component/AlertToster'
import noData from '../../assets/images/icons/No data-rafiki.svg';
import save from "../../assets/images/icons/save.svg";

import { useTranslation } from 'react-i18next';


export default function ProjectWiseTask() {

    const [getProjectWiseTaskList, setProjectWiseTaskList] = useState([])
    const [getSelectedOptionProject, setSelectedOptionProject] = useState('');
    const [getProjectList, setProjectList] = useState([])
    const [searchInput, setSearchInput] = useState('');
    const [loder, setLoder] = useState(1)
    const [showToster, setShowToster] = useState(0)
    const [message, setMessage] = useState("")
    const { t } = useTranslation();
    const [editProjectName, seteditProjectName] = useState("")
    const [clearDropdown, setClearDropdown] = useState(0)
    const [getSaveUpdateBool, setSaveUpdateBool] = useState(0)

    let funGetProjectWiseTask = async () => {
        if (getSelectedOptionProject === '0' || getSelectedOptionProject === undefined || getSelectedOptionProject === null || getSelectedOptionProject === "") {
            setMessage("Project not fill !")
            setShowToster(3)
            setLoder(0)
        }
        else {
            setLoder(1)
            let getResult = await GetProjectWiseTask(getSelectedOptionProject, window.userId)
            if (getResult.status === 1) {
                setLoder(0)
                setProjectWiseTaskList(getResult.responseValue);
            }
        }
    };

    const funGetProject = async () => {
        const {status, responseValue} = await GetProjectMaster();
        status ? setProjectList(responseValue) : setProjectList([]);
    };
    //Handle Change
    const handleChange = (e) => {
        const {name, value} = e.target;
        if (name === "projectID") {
            setSelectedOptionProject(value)
        }
    };
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        if (name === 'searchBox') {
            setSearchInput(value)
        }
    };
    const clearValues = async (value) => {
        setClearDropdown(value)
        setSelectedOptionProject('');
        seteditProjectName("");
        setSaveUpdateBool(0);
    };
    function reformatDateString(s) {
        if (typeof s === 'string' && s.trim() !== '') {
            var b = s.split(/\D/);
            var day = parseInt(b[2], 10).toString();
            var month = parseInt(b[1], 10).toString();
            var year = b[0];
            return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
        } else {
            return ''; // Return an empty string or handle the error as needed
        }
    };
    let handleExportFile = () => {
        var arrFormat = [];
        getProjectWiseTaskList.map((val, ind) => {
            let param = ['SR', 'Project Name', 'Developer Name', 'Story', 'Sprint Backlog', 'Assign Date'];

            arrFormat.push({
                [param[0]]: ind + 1,
                [param[1]]: val.projectName,
                [param[2]]: val.name,
                [param[3]]: val.wantAbleTo + ' ' + val.soThat,
                [param[4]]: val.sprintBacklogText,
                [param[5]]: reformatDateString(val.assignDate)
            });
        });

        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
        const data = arrFormat;
        const fileName = 'ProjectWiseTaskList_' + '' + formattedDate;
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType });
    }
    useEffect(() => {
        funGetProject();
    }, []);
    return (
        <>
            <section className="main-content pt-3 mt-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="med-box">
                                <div className="title">Get Project Wise task List</div>
                                <div className="inner-content">
                                    <div className="d-flex flex-wrap align-content-end">

                                        <div className="mb-2 me-2 dropsearch">
                                            <label htmlFor="itemNumber" className="form-label">Project <span className="starMandatory">*</span></label>
                                            <div className='drpWithSearch'>
                                                {getProjectList && <DropdownWithSearch defaulNname={t("Select Project")} name="projectID" list={getProjectList} valueName="id" displayName="projectName" editdata={editProjectName} getvalue={handleChange} clear={clearDropdown} clearFun={clearValues} />}
                                            </div>
                                        </div>
                                        <div className="mb-2 relative">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                            <div>
                                                {getSaveUpdateBool === 0 ?
                                                    <>
                                                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={funGetProjectWiseTask}><img src={save} className='icnn' />Get Report</button>
                                                        
                                                    </>
                                                    :
                                                    <>
                                                        {/* <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1"><img src={save} className='icnn' />Update</button>
                                                        <button type="button" className="btn btn-clear btn-sm mb-1 me-1"><img src={reset} className='icnn' /> Clear</button> */}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-1">
                            <div className='handlser'>
                                <Heading text="Project Wise task List" />
                                <div style={{ position: 'relative', display: 'flex' }}>
                                    <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleExportFile}>Export To CSV</button>
                                    <input value={searchInput} onChange={handleOnChange} name="searchBox" type="search" className="form-control form-control-sm" placeholder="Search...." aria-label="Search" aria-describedby="search-addon" />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>

                                </div>
                            </div>

                            <div className="med-table-section relative" style={{ "height": "77vh" }}>
                                <TableContainer>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>S.No.</th>
                                            <th>Project Name</th>
                                            <th>Developer Name</th>
                                            <th>Story</th>
                                            <th>Sprint Backlog</th>
                                            <th>Assign Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getProjectWiseTaskList.length > 0 ?
                                            getProjectWiseTaskList && getProjectWiseTaskList.filter((val) => `${val.projectName} ${val.name}${val.wantAbleTo + ' ' + val.soThat}${val.sprintBacklogText}`.toLowerCase().includes(searchInput.toLowerCase())).map((val, ind) => {
                                            return (
                                                <tr key={val.agendaId}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td>{val.projectName}</td>
                                                    <td>{val.name}</td>
                                                    <td>{val.wantAbleTo + ' ' + val.soThat}</td>
                                                    <td>{val.sprintBacklogText}</td>
                                                    <td>{reformatDateString(val.assignDate)}</td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <img className='haveNodataImg' src={noData} alt="No Data" />}
                                    </tbody>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>
                {showToster === 1 ? <SuccessToster message={message} handle={setShowToster} /> : ""}
                {showToster === 2 ? <WarningToaster message={message} handle={setShowToster} /> : ""}
                {showToster === 3 ? <AlertToster message={message} handle={setShowToster} /> : ""}
            </section>
            {/* <Loder val={loder} /> */}
        </>
    )
}