
async function InsertHistory (uhid, jsonHistory) {

    let url = window.AppbaseUrl + `/api/PatientIPDPrescription/InsertHistory?uhID=${uhid}&jsonHistory=${jsonHistory}&userId=${window.userId}&clientID=${window.clientId}`
    let head = {
      'Content-Type': 'application/JSON',
      accept: '*/*',
    }
    let response =
      await fetch(url, {
        method: 'POST',
        headers: head,
        body: JSON.stringify({})
      })
        .then((res) => res.json())  
    return response;
  }
  export default InsertHistory;
  