import React, { useEffect, useState } from 'react'
import TimeCalculate from '../../../Code/TimeCalculate';
import LineInvestigationGrap from '../../../Components/LineInvestigationGrap';
import GetAllDashboardParameterColumnAssign from '../../../Api/GetAllDashboardParameterColumnAssign';
import Highcharts from 'highcharts';
import { useSelector } from 'react-redux';

export default function CaplusplusR(props) {
    let [caplus, setCaplus] = useState("");
    let [caplusId, setCaplusId] = useState("");
    let [caplusTime, setCaplusTime] = useState("");
    let [caplusColor, setCaplusColor] = useState("");


    let [caplusSerum, setCaplusSerum] = useState("");
    let [caplusSerumId, setCaplusSerumId] = useState("");
    let [caplusSerumTime, setCaplusSerumTime] = useState("");
    let [caplusSerumColor, setCaplusSerumColor] = useState("");


    let [uhId, setUhId] = useState("")

    // set grap Investigation data
    let [grapInvestigationData, setGrapInvestigationData] = useState({
        showGrap: 0,
        subTestId: '',
        patientName: '',
        UHID: '',
        userId: ''
    })

    let getData = () => {
        setCaplus("");
        setCaplusId("");
        setCaplusTime("");
        setCaplusColor("");
        setCaplusSerum("");
        setCaplusSerumId("");
        setCaplusSerumTime("");
        setCaplusSerumColor("");

        props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
            if (investigation.SubTestID.toString() === "612") {
                setCaplus(Math.round(investigation.SubTestValue * 100) / 100)
                setCaplusColor(investigation.SubTestColor)
                if (props.handleColor !== undefined) {
                    props.handleColor(investigation.SubTestColor !== null ? investigation.SubTestColor : "white", props.columnId)
                }

                setCaplusId(investigation.SubTestID)
                setUhId(props.patientData.UhId)
                if (investigation.SubTestDateTime !== '') {
                    setCaplusTime(TimeCalculate(investigation.SubTestDateTime))
                }




            }
            if (investigation.SubTestID.toString() === "187") {

                setCaplusSerum(Math.round(investigation.SubTestValue * 100) / 100)
                setCaplusSerumId(investigation.SubTestID)
                setCaplusSerumColor(investigation.SubTestColor)
            }
        })
    }

    let createPieChart = () => {
        if (props.patientData.PatientNutritionAchievementList !== null && props.patientData.PatientNutritionAchievementList !== undefined) {
            props.patientData.PatientNutritionAchievementList.map((val, ind) => {
                if (val.NutrientID === 2) {
                    console.log("ennnnnnnnnnnnnnnnnnnnnnnnnnnnn")

                    let chartdata = []
                    let slice1 = {
                        name: 'Food ' + val.FoodPercentage.toFixed(2) + '%',
                        y: val.FoodPercentage,
                        // y: 20.00,
                        sliced: false,
                        color: val.FoodPercentageColor

                    }


                    let slice2 = {
                        name: 'Medicine ' + val.MedicinePercentage.toFixed(2) + '%',
                        y: val.MedicinePercentage,
                        // y: 50.00,
                        sliced: false,
                        color: val.MedicinePercentageColor
                    }


                    let slice3 = {
                        name: 'Fluid ' + val.FluidPercentage.toFixed(2) + '%',
                        y: val.FluidPercentage,
                        // y: 20.00,
                        sliced: false,
                        color: val.FluidPercentageColor
                    }



                    let slice4 = {
                        name: 'Remaining ' + val.RemainingPercentage.toFixed(2) + '%',
                        y: val.RemainingPercentage,
                        // y: 100.00,
                        color: "white",
                        sliced: false,
                    }
                    chartdata = [slice1, slice2, slice3, slice4]
                    if (document.getElementById("caplusId")) {


                        setTimeout(() => {
                            Highcharts.chart('caplusId',
                                {
                                    chart: {
                                        type: 'pie',
                                        backgroundColor: null,
                                        plotBorderWidth: null,
                                        plotShadow: false,
                                        width: 65,
                                        height: 65

                                    },
                                    legend: {
                                        backgroundColor: '#FFFFFF',
                                    }
                                    ,
                                    title: {
                                        text: null,
                                        align: null
                                    },
                                    tooltip: {
                                        pointFormat: null,
                                        enabled: true // Enable tooltips
                                    },
                                    // accessibility: {
                                    //   point: {
                                    //     valueSuffix: '%'
                                    //   }
                                    // },
                                    plotOptions: {
                                        pie: {
                                            allowPointSelect: true,
                                            cursor: 'pointer',
                                            dataLabels: {
                                                enabled: false
                                            },
                                            showInLegend: false,
                                            borderRadius: 0,
                                            borderWidth: 0
                                        }
                                    },
                                    credits: {
                                        enabled: false,
                                    },
                                    series: [{
                                        enableMouseTracking: true,

                                        data: [...chartdata]
                                    },

                                    ]

                                }
                            )
                        }, 100)
                    }



                }
            })
        }
    }

    let modelCloseFun = () => {
        setGrapInvestigationData({
            showGrap: 0,
            subTestId: '',
            patientName: '',
            UHID: '',
            userId: ''
        })
    }

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
        if (resp.length !== 0) {
            if (resp.length !== 0) {
                setPatameterData(resp[0].data)
            }
        }
    }


    useEffect(() => {
        getColumnsParameters()
        createPieChart()
        getData()
    }, [props.patientData])
    return (
        <>
            {/* <div key={props.key} className={`overflow-max investigations ${(caplusColor !== "#008000" && caplusColor !== "#94e094" && caplusColor !== "#F5F8FF" && caplusColor !== "" ? "blink" : "")} `} style={{ border: (caplusColor !== "#008000" && caplusColor !== "#94e094" && caplusColor !== "#F5F8FF" && caplusColor !== "" ? "2px solid " + caplusColor : "") }}> */}
            <div key={props.key} className={`overflow-max investigations `} >

                {caplus.length != 0 ? caplus ? <span>

                    {
                        paramenterData && paramenterData.map((val, ind) => {
                            if (val.parameterId === 59) {
                                return (
                                    <>
                                        <span style={{ color: caplusColor }}>{caplus}</span> {caplusSerum ? <span style={{ color: caplusSerumColor }}>({caplusSerum})</span> : ""}<br />
                                    </>

                                )
                            }

                            else if (val.parameterId === 60) {
                                return (
                                    <>
                                        {
                                            caplusTime.split("-")[1] === "D" && caplusTime.split("-")[0] >= 4 ? <span style={{ 'color': 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {caplusTime.toLowerCase().replace("-", "")}</span> : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {caplusTime.toLowerCase().replace("-", "")}</span>}

                                    </>

                                )
                            }
                        })

                    }


                    <div className="d-flex flex-row gap-2 justify-content-center align-items-center pt-1" title='ALB Graph' >
                        {

                            paramenterData && paramenterData.map((val, ind) => {
                                if (val.parameterId === 61 && props.from === undefined) {
                                    return (
                                        <>
                                            <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [caplusId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "Calcium (Ca++) Graph", patientName: props.patientData.PntName }) }}></i>

                                            {/* <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='ALB Graph' > */}
                                            {/* {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                                                if (val.SubtestID === albId) {
                                                    return (
                                                        <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                                                    )
                                                }
                                            })} */}


                                        </>

                                    )
                                }
                                // else if (val.parameterId === 112 && props.from === undefined) {
                                //     return (

                                //         <div id="caplusId" style={{ width: "30x", height: "30px" }}>
                                //         </div>
                                //     )
                                // }
                            })

                        }
                    </div>


                </span > : 'N/A' : 'N/A'}

                <div className="d-flex flex-row gap-2 justify-content-center align-items-center pt-1" title='Calcium (Ca+) Graph' >
                    {

                        paramenterData && paramenterData.map((val, ind) => {
                            if (val.parameterId === 112) {
                                return (
                                    <div id="caplusId" className='piechartcontainer'></div>
                                )
                            }
                        })

                    }
                </div>
            </div >
            {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}

        </>
    )
}


export function CaplusplusRG(props) {
    let [caplus, setCaplus] = useState("");
    let [caplusId, setCaplusId] = useState("");
    let [caplusTime, setCaplusTime] = useState("");
    let [caplusColor, setCaplusColor] = useState("");


    let [caplusSerum, setCaplusSerum] = useState("");
    let [caplusSerumId, setCaplusSerumId] = useState("");
    let [caplusSerumTime, setCaplusSerumTime] = useState("");
    let [caplusSerumColor, setCaplusSerumColor] = useState("");


    let [uhId, setUhId] = useState("")

    // set grap Investigation data
    let [grapInvestigationData, setGrapInvestigationData] = useState({
        showGrap: 0,
        subTestId: '',
        patientName: '',
        UHID: '',
        userId: ''
    })

    let getData = () => {
        setCaplus("");
        setCaplusId("");
        setCaplusTime("");
        setCaplusColor("");
        setCaplusSerum("");
        setCaplusSerumId("");
        setCaplusSerumTime("");
        setCaplusSerumColor("");

        props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
            if (investigation.SubTestID.toString() === "612") {
                setCaplus(Math.round(investigation.SubTestValue * 100) / 100)
                setCaplusColor(investigation.SubTestColor)
                if (props.setColors !== undefined) {
                    props.setColors((prevArray) => ({ ...prevArray, "Ca+": investigation.SubTestColor }))
                }

                setCaplusId(investigation.SubTestID)
                setUhId(props.patientData.UhId)
                if (investigation.SubTestDateTime !== '') {
                    setCaplusTime(TimeCalculate(investigation.SubTestDateTime))
                }

                let chartdata = []
                if (props.patientData.PatientNutritionAchievementList !== null && props.patientData.PatientNutritionAchievementList !== undefined) {
                    props.patientData.PatientNutritionAchievementList.map((val, ind) => {

                        if (val.NutrientID === 2) {
                            let slice1 = {
                                name: '',
                                // y: val.FoodPercentage,
                                y: 20.00,
                                sliced: false,
                                color: val.FoodPercentageColor

                            }


                            let slice2 = {
                                name: '',
                                // y: val.MedicinePercentage,
                                y: 50.00,
                                sliced: false,
                                color: val.MedicinePercentageColor
                            }


                            let slice3 = {
                                name: '',
                                // y: val.FluidPercentage,
                                y: 20.00,
                                sliced: false,
                                color: val.FluidPercentageColor
                            }



                            let slice4 = {
                                name: '',
                                // y: val.RemainingPercentage,
                                y: 100.00,
                                color: "white",
                                sliced: false,
                            }
                            chartdata = [slice1, slice2, slice3, slice4]
                            // chartdata = [slice2,slice4]


                            if (document.getElementById("caplusId")) {
                                setTimeout(() => {
                                    Highcharts.chart('caplusId',
                                        {
                                            chart: {

                                                plotShadow: false,
                                                spacing: [0, 0, 0, 0],
                                                margin: 0,
                                                type: 'pie',
                                                backgroundColor: 'transparent',
                                                width: 35,
                                                height: 35

                                            },
                                            legend: {
                                                backgroundColor: '#FFFFFF',
                                            }
                                            ,
                                            title: {
                                                text: null,
                                                align: null
                                            },
                                            tooltip: {
                                                pointFormat: null
                                            },
                                            // accessibility: {
                                            //   point: {
                                            //     valueSuffix: '%'
                                            //   }
                                            // },
                                            plotOptions: {
                                                pie: {
                                                    size: '100%',
                                                    allowPointSelect: true,
                                                    cursor: 'pointer',
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    // colors: ['#f00', '#0f0', '#00f'],


                                                }
                                            },
                                            credits: {
                                                enabled: false,
                                            },
                                            series: [{
                                                enableMouseTracking: false,

                                                data: [...chartdata]
                                            },

                                            ]

                                        }
                                    )
                                }, 100)
                            }



                        }
                    })
                }


            }
            if (investigation.SubTestID.toString() === "187") {

                setCaplusSerum(Math.round(investigation.SubTestValue * 100) / 100)
                setCaplusSerumId(investigation.SubTestID)
                setCaplusSerumColor(investigation.SubTestColor)
            }
        })
    }

    let modelCloseFun = () => {
        setGrapInvestigationData({
            showGrap: 0,
            subTestId: '',
            patientName: '',
            UHID: '',
            userId: ''
        })
    }

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
        if (resp.length !== 0) {
            setPatameterData(resp[0].data)
        }

    }


    useEffect(() => {
        getColumnsParameters()

        getData()
    }, [props.patientData])
    return (
        <>
            {/* <div key={props.key} className={`overflow-max investigations ${(caplusColor !== "#008000" && caplusColor !== "#94e094" && caplusColor !== "#F5F8FF" && caplusColor !== "" ? "blink" : "")} `} style={{ border: (caplusColor !== "#008000" && caplusColor !== "#94e094" && caplusColor !== "#F5F8FF" && caplusColor !== "" ? "2px solid " + caplusColor : "") }}> */}
            <div key={props.key} className={`overflow-max investigations `} >

                {caplus.length != 0 ? caplus ? <span>
                    {

                        paramenterData && paramenterData.map((val, ind) => {
                            if (val.parameterId === 61) {
                                return (
                                    <>
                                        <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [caplusId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "Calcium (Ca++) Graph", patientName: props.patientData.PntName }) }}></i>

                                    </>

                                )
                            }

                        })

                    }



                </span > : 'N/A' : 'N/A'}
            </div >
            {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}

        </>
    )
}