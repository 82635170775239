let GetRegistartionField = async(regType) => {
   
    let URL = window.UserbaseUrl + `/api/RegTypeFilds/GetAllRegTypeFilds?regTypeId=${regType}&clientId=176`;   
    const head = {'content-type':'application/json','accept':'*/*',}
    let data = {};
    let response = await fetch(URL,{
      method: "GET",
      header: head,
  
    }).then(res => res.json()).then(data);
    return response;
  }
  export default  GetRegistartionField;