
// async function PostVendorMaster(data) {

//     let url = window.InventoryBaseUrl + "/api/VendorMaster/InsertVendorMaster";
//     let head = {
//       'Content-Type': 'application/JSON',
//       accept: '*/*',
//     }
//     let response =
//       await fetch(url, {
//         method: 'POST',
//         headers: head,
//         body: JSON.stringify(data)
//       })
//         .then((res) => res.json())
//         .then(data)
  
  
//     return response;
//   }
//   export default PostVendorMaster;

  
async function PostVendorMaster(data) {

  let url = window.InventoryBaseUrl + "/api/VendorMaster/InsertVendorMaster";
  // let url = "https://localhost:7264/api/VendorMaster/InsertVendorMaster";
  let head = {
    'Content-Type': 'application/JSON',
    accept: '*/*',
  }
  let response =
    await fetch(url, {
      method: 'POST',
      headers: head,
      body: JSON.stringify(data)
    })
      .then((res) => res.json())
      .then(data)


  return response;
}
export default PostVendorMaster;

