import React, { useEffect, useState } from 'react'
import TosterUnderProcess from '../../Component/TosterUnderProcess';
import Toster from '../../Component/Toster';
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import clearIcon from '../../assets/images/icons/clear.svg';
import UserIcon from "../../assets/images/icons/UserIcon.svg";
import IconMRNo from "../../assets/images/icons/IconMRNo.svg";
import IconAge from "../../assets/images/icons/IconAge.svg";
import IconGender from "../../assets/images/icons/IconGender.svg";
import IconDob from "../../assets/images/icons/IconDob.svg";
import IconWard from "../../assets/images/icons/IconWard.svg";
import IconRestraints from "../../assets/images/icons/IconRestraints.svg";
import IconDiagnosis from "../../assets/images/icons/IconDiagnosis.svg";
import IconDateTime from "../../assets/images/icons/IconDateTime.svg";
import IconReasonRestraint from "../../assets/images/icons/IconReasonRestraint.svg";
import { t } from 'i18next';
import Heading from '../../Component/Heading';
import GetPatientDetailsforSarvodaya from './API/GetPatientDetailsforSarvodaya';
import GetAllStatusByTypeModule from './API/GetAllStatusByTypeModule';
import ProvisionalDiagnosiRows from './ProvisionalDiagnosiRows';
import InsertPatientRestraint from './API/InsertPatientRestraint';
import GetUserList from '../../Admin/Api/Schedule/GET/GetUserList';
import ProvisonalDiagnosisMainComponent from './ProvisonalDiagnosisMainComponent';
import SuccessToster from '../../Component/SuccessToster';
import Loader from '../../Component/Loader';
import AlertToster from '../../Component/AlertToster';
import RestraintVitals from './RestraintComponent/RestraintVitals';
import RestrainIntake from './RestraintComponent/RestrainIntake';
import RestraintOutput from './RestraintComponent/RestraintOutput';
import PatientMonitoringListTable from './RestraintComponent/PatientMonitoringListTable';
import GetPatientRestraint from './API/GetPatientRestraint';
import PatientDeatils from '../../Component/PatientDeatils';

export default function RestraintMonitoringForm() {
    const data = {
        "userId": window.userId,
        "clientId": window.clientId,
        uhId: 0,
        restraintStartTime: '',
        restraintStopTime: '',
        restraintReason: '',
        restraintTypeId: 0,
        deviceId: 0,
        bodyLocationId: 0,
        positionId: 0,
        jsonRestraint: ''
    }
    const newRow = {

        patientBehavior: "",
        nursingCare: "",
        limbCirculation: "",
        skinSensation: "",
        movementRange: "",
        skinIntegrity: "",
        patientPosition: "",
        monitoredBy: "",
        remark: "",
        monitoringTime: "",
    };

    const [showUnderProcess, setShowUnderProcess] = useState(0);
    const [showToster, setShowToster] = useState(0);
    const [tosterMessage, setTosterMessage] = useState("");
    const [tosterValue, setTosterValue] = useState(0);
    let [updateBool, setUpdateBool] = useState(0)
    const [searchTerm, setSearchTerm] = useState('');
    const [rows, setRows] = useState([{ id: 1 }]);
    const [sendForm, setSendForm] = useState([data])
    const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    const [patientData, setPatientData] = useState();
    const [typeDeviceList, setTypeDeviceList] = useState();
    const [typeLocationList, setTypeLocationList] = useState();
    const [typePositionsList, setTypePositionsList] = useState();
    const [typePBList, setTypePBList] = useState();
    const [typeNCList, setTypeNCList] = useState();
    const [typeLCList, setTypeLCList] = useState();
    const [typeSSList, setTypeSSList] = useState();
    const [typeROMList, setTypeROMList] = useState();
    const [typeSIList, setTypeSIList] = useState();
    const [typePHrlyList, setTypePHrlyList] = useState();
    const [userList, setUserList] = useState();

    let [showLoder, setShowLoder] = useState(0);
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    const [restraintList, setRestraintList] = useState();
    const [patientRestraintList, setPatientRestraintList] = useState([]);

    let [saveDiagnosis, setSaveDiagnosis] = useState(0);

    // State variables for restraint table
    const [restrainRows, setRestrainRows] = useState([newRow]);

    let getPatientRestraint = async () => {
        let response = await GetPatientRestraint(clientID, uhID);
        if (response.status === 1) {
            setRestraintList(response.responseValue);
            let tempArr = response.responseValue;
            setPatientRestraintList(tempArr.map((arr) => JSON.parse(arr.patientrestraintMonitoring) || []));
        }
    }



    //Get Patient Details
    const getPatientData = async () => {
        let data = await GetPatientDetailsforSarvodaya(uhID)
        if (data.status === 1) {
            setPatientData(data.responseValue[0])
        }
    }
    //Get User
    const getAllUser = async () => {
        let data = await GetUserList(window.clientId)
        if (data.status === 1) {
            setUserList(data.responseValue)
        }
    }


    //Get Types of Device Used
    const restraintTypes = [
        "Type of Device Used",
        "Restraints Location",
        "Positions",
        "Patient Behavior",
        "Nursing Care",
        "Limb Circulation",
        "Skin Sensation",
        "Range of Movement",
        "Skin Integrity 2hrly",
        "Position of the Patient 2hrly",
    ];
    const getRestraintsTypeData = async (type) => {
        let response = await GetAllStatusByTypeModule(type)
        if (response.status === 1) {
            switch (type) {
                case "Type of Device Used":
                    setTypeDeviceList(response.responseValue);
                    break;
                case "Restraints Location":
                    setTypeLocationList(response.responseValue);
                    break;
                case "Positions":
                    setTypePositionsList(response.responseValue);
                    break;
                case "Patient Behavior":
                    setTypePBList(response.responseValue);
                    break;
                case "Nursing Care":
                    setTypeNCList(response.responseValue);
                    break;
                case "Limb Circulation":
                    setTypeLCList(response.responseValue);
                    break;
                case "Skin Sensation":
                    setTypeSSList(response.responseValue);
                    break;
                case "Range of Movement":
                    setTypeROMList(response.responseValue);
                    break;
                case "Skin Integrity 2hrly":
                    setTypeSIList(response.responseValue);
                    break;
                case "Position of the Patient 2hrly":
                    setTypePHrlyList(response.responseValue);
                    break;
                default:
            }
        }
    }

    //Table Row add and remove function Restrain List
    const addRestrainRow = () => {
        setRestrainRows((prevRows) => [...prevRows, newRow]);
        console.log("newRow", newRow);
    };

    const removeRestrainRow = (ind) => {
        let updateRow = [...restrainRows]
        if (restrainRows.length === 1) {
            updateRow.splice(ind, 1)
            setRestrainRows([newRow])
        }
        else if (restrainRows.length > 1) {
            updateRow.splice(ind, 1)
            setRestrainRows([...updateRow])
        }
    };


    //Handle Change
    let handleChange = (e, index) => {
        clearValidationErrMessage();
        let name = e.target.name;
        let value = e.target.value;
        setSendForm(sendForm => ({
            ...sendForm,
            [name]: value
        }))
        const updatedRows = [...restrainRows];
        if (name === "patientBehavior" || name === "nursingCare" || name === "limbCirculation" || name === "skinSensation" || name === "movementRange" || name === "skinIntegrity" || name === "patientPosition" || name === "monitoredBy" || name === "remark" || name === "monitoringTime") {
            updatedRows[index][name] = value;
        }
        setRows(updatedRows)
    }



    //Handle Save
    const handlerSave = async () => {
        setSaveDiagnosis(1)
        const restrainedData = rows.map((row, index) => {
            return {
                patientBehavior: rows[index].patientBehavior,
                nursingCare: rows[index].nursingCare,
                limbCirculation: rows[index].limbCirculation,
                skinSensation: rows[index].skinSensation,
                movementRange: rows[index].movementRange,
                skinIntegrity: rows[index].skinIntegrity,
                patientPosition: rows[index].patientPosition,
                monitoredBy: rows[index].monitoredBy,
                remark: rows[index].remark,
                monitoringTime: rows[index].monitoringTime,
            }
        });


        if (restrainedData.length === 0) {
            document.getElementById('errRestrainMsg').innerHTML = "Select atleast one row";
            document.getElementById('errRestrainMsg').style.display = "block";
            return;
        }
        const isAnyRowEmpty = restrainedData.some(row => {
            return Object.values(row).some(value => value === '');
        });
        if (isAnyRowEmpty) {
            document.getElementById('errRestrainMsg').innerHTML = "Select all restraint type";
            document.getElementById('errRestrainMsg').style.display = "block";
            return;
        }

        // console.log("restrainedData", restrainedData)
        if (sendForm.restraintStartTime === '' || sendForm.restraintStartTime === null || sendForm.restraintStartTime === undefined) {
            document.getElementById('errStartTime').innerHTML = "Select start time";
            document.getElementById('errStartTime').style.display = "block";
        }
        else if (sendForm.restraintStopTime === '' || sendForm.restraintStopTime === null || sendForm.restraintStopTime === undefined) {
            document.getElementById('errStopTime').innerHTML = "Select stop time";
            document.getElementById('errStopTime').style.display = "block";
        }
        else {
            setShowUnderProcess(1);
            const obj = {
                "userId": window.userId,
                "clientId": window.clientId,
                uhId: uhID,
                restraintStartTime: sendForm.restraintStartTime,
                restraintStopTime: sendForm.restraintStopTime,
                restraintReason: sendForm.restraintReason,
                restraintTypeId: sendForm.restraintTypeId,
                deviceId: sendForm.deviceId,
                bodyLocationId: sendForm.bodyLocationId,
                positionId: sendForm.positionId,
                jsonRestraint: JSON.stringify(restrainedData)
            }
            // console.log("dataobj,", obj)
            // return;
            const response = await InsertPatientRestraint(obj);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Data saved successfully..");
                setTimeout(() => {
                    setShowToster(0);
                    handleClear();
                }, 1500)
                getPatientRestraint();
                setSendForm({});
                setRows([newRow]);
            }
            else {
                setShowUnderProcess(0);
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTimeout(() => {
                    setShowToster(0);
                }, 1500)
            }
        }
    }

    //handle Clear
    const handleClear = () => {
        clearValidationErrMessage();
        setSendForm(prevSendForm => ({
            ...prevSendForm,
            restraintStartTime: "",
            restraintReason: "",
            restraintTypeId: 0,
            deviceId: 0,
            bodyLocationId: 0,
            positionId: 0,
            restraintStopTime: ""
        }));
        setRestrainRows([newRow]);
    };

    //Clear Error Message
    const clearValidationErrMessage = () => {
        document.getElementById('errStartTime').style.display = "none";
        document.getElementById('errStopTime').style.display = "none";
        document.getElementById('errRestrainMsg').style.display = "none";
    }

    const formatDOB = (dob) => {
        const [day, month, year] = dob.split('/');
        return `${year}-${month}-${day}`;
    };
    const handlePrintRestraint = (param) => {
        console.log('param', param);
        window.sessionStorage.setItem('restraintList', JSON.stringify(param));
        window.open("/ReceiptRestraintMonitoringFormprint/", 'noopener,noreferrer')
    };

    const formatDateTime = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString('en-IN', options);
    };
    useEffect(() => {
        getPatientRestraint();
    }, [])
    useEffect(() => {
        getPatientData();
        getAllUser();
        restraintTypes.forEach((restraintType) => {
            getRestraintsTypeData(restraintType);
        });
        getPatientRestraint();
    }, [restrainRows])

    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="col-12"><div className="med-box mb-1"><div className="title">Restraint Monitoring Form</div></div></div>
                    <div className='whitebg'>

                        {/* -------------------------------Patient Details Section------------------------------------- */}
                        <PatientDeatils/>

                        {/* <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="fieldsett-in">
                                    <div className="fieldsett">
                                        <span className='fieldse'>{t("Patient Details")}</span>
                                        <div className="inner-content mt-2">
                                            <div className="d-flex flex-wrap align-content-end gap-3">
                                                <div className="mb-2 me-2">
                                                    <img src={UserIcon} className="icnn" alt="" />
                                                    <label htmlFor="*" className="form-label">Patient Name<span className="starMandatory"></span></label>
                                                    <input type="text" className="form-control form-control-sm" value={patientData && patientData.patientName} disabled placeholder="Patient name" />
                                                </div>
                                                <div className="mb-2 me-2">
                                                    <img src={IconMRNo} className="icnn" alt="" />
                                                    <label htmlFor="*" className="form-label">MR No.<span className="starMandatory"></span></label>
                                                    <input type="text" className="form-control form-control-sm" value={patientData && patientData.uhId} disabled placeholder="MR No." />
                                                </div>
                                                <div className="mb-2 me-2">
                                                    <img src={IconAge} className="icnn" alt="" />
                                                    <label htmlFor="*" className="form-label">Age<span className="starMandatory"></span></label>
                                                    <input type="text" className="form-control form-control-sm" value={patientData && patientData.age} disabled placeholder="Age" />
                                                </div>
                                                <div className="mb-2 me-2">
                                                    <img src={IconGender} className="icnn" alt="" />
                                                    <label htmlFor="*" className="form-label">Gender<span className="starMandatory"></span></label>
                                                    <input type="text" className="form-control form-control-sm" value={patientData && patientData.gender} disabled placeholder="Gender" />

                                                </div>
                                                <div className="mb-2 me-2">
                                                    <img src={IconDob} className="icnn" alt="" />
                                                    <label htmlFor="*" className="form-label">DOB<span className="starMandatory"></span></label>
                                                    <input type="date" className="form-control form-control-sm" value={patientData && formatDOB(patientData.dob)} disabled placeholder="Date of birth" />
                                                </div>
                                                <div className="mb-2 me-2">
                                                    <img src={IconWard} className="icnn" alt="" />
                                                    <label htmlFor="*" className="form-label">Ward/Bed<span className="starMandatory"></span></label>
                                                    <input type="text" className="form-control form-control-sm" value={patientData && patientData.wardName + "/" + patientData.bedName} disabled placeholder="Ward" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* -------------------------------Restrain Details Section------------------------------------- */}
                        <div className="row mt-4">
                            <div className="col-md-12 col-sm-12">
                                <div className="fieldsett-in">
                                    <div className="fieldsett">
                                        <span className='fieldse'>{t("Restrain Details")}</span>
                                        <div className="inner-content mt-2">
                                            <div className="d-flex flex-wrap align-content-end gap-3">
                                                {/* <div className="mb-2 me-2">
                                                    <img src={IconRestraints} className="icnn" alt="" />
                                                    <label htmlFor="empID*" className="form-label">Primary Consultant<span className="starMandatory"></span></label>
                                                    <input type="text" className="form-control form-control-sm" value={patientData && patientData.doctorName} disabled placeholder="Consultant" />
                                                </div> */}
                                                <div className="mb-2 me-2" style={{ maxWidth: '300px' }}>
                                                    <img src={IconRestraints} className="icnn" alt="" />
                                                    <label htmlFor="empID*" className="form-label">Diagnosis<span className="starMandatory"></span></label>
                                                    <ProvisonalDiagnosisMainComponent isProvisional={0} saveDiagnosis={saveDiagnosis} setSaveDiagnosis={setSaveDiagnosis} />
                                                </div>

                                                <div className="mb-2 me-2">
                                                    <img src={IconDateTime} className="icnn" alt="" />
                                                    <label htmlFor="restraintStartTime" className="form-label">Date & Time Restrained<span className="starMandatory">*</span></label>
                                                    <input type="datetime-local" className="form-control form-control-sm" name="restraintStartTime" id="restraintStartTime" value={sendForm.restraintStartTime} onChange={handleChange} placeholder="Start date" />
                                                    <small id="errStartTime" className="invalid-feedback" style={{ display: 'none' }}></small>
                                                </div>
                                                <div className="mb-2 me-2">
                                                    <img src={IconDateTime} className="icnn" alt="" />
                                                    <label htmlFor="restraintStopTime" className="form-label">Date & Time Restrain Discontinued<span className="starMandatory">*</span></label>
                                                    <input type="datetime-local" className="form-control form-control-sm" id="restraintStopTime" value={sendForm.restraintStopTime} name='restraintStopTime' onChange={handleChange} placeholder="Stop date" />
                                                    <small id="errStopTime" className="invalid-feedback" style={{ display: 'none' }}></small>
                                                </div>
                                                <div className="mb-2 me-2">
                                                    <img src={IconReasonRestraint} className="icnn" alt="" />
                                                    <label htmlFor="restraintReason" className="form-label">Reason for Restrain<span className="starMandatory"></span></label>
                                                    <input type="text" className="form-control form-control-sm" id="restraintReason" name="restraintReason" value={sendForm.restraintReason} onChange={handleChange} placeholder="Reason" />
                                                </div>
                                            </div>

                                            <div className="restraintChk-sec mt-2">
                                                <div className="mb-2 me-2 restraintChk-in">
                                                    <img src={IconRestraints} className="icnn" alt="" />
                                                    <label htmlFor="restraintTypeId" className="form-label">Types Of Restraint </label>
                                                    <select id='restraintTypeId' name='restraintTypeId' value={sendForm.restraintTypeId} onChange={handleChange} className="form-select form-select-sm">
                                                        <option value="0" selected>Select Types Of Restraint</option>
                                                        <option value="1">Physical</option>
                                                        <option value="2">Chemical</option>
                                                    </select>
                                                </div>
                                                <div className="mb-2 me-2 restraintChk-in">
                                                    <img src={IconRestraints} className="icnn" alt="" />
                                                    <label htmlFor="deviceId" className="form-label">Types of Device Used </label>
                                                    <select id='deviceId' name='deviceId' className="form-select form-select-sm" value={sendForm.deviceId} onChange={handleChange}>
                                                        <option value="0" selected>Select Types of Device Used</option>
                                                        {typeDeviceList && typeDeviceList.map((val, ind) => {
                                                            return (
                                                                <option value={val.id}>{val.remark}</option>
                                                            )
                                                        }
                                                        )}
                                                    </select>
                                                </div>


                                                <div className="mb-2 me-2 restraintChk-in">
                                                    <img src={IconRestraints} className="icnn" alt="" />
                                                    <label htmlFor="bodyLocationId" className="form-label">Location Of Physical Restraints </label>
                                                    <select id='bodyLocationId' name='bodyLocationId' className="form-select form-select-sm" value={sendForm.bodyLocationId} onChange={handleChange}>
                                                        <option value="0" selected>Select Location Of Physical Restraints</option>
                                                        {typeLocationList && typeLocationList.map((val, ind) => {
                                                            return (
                                                                <option value={val.id}>{val.remark}</option>
                                                            )
                                                        }
                                                        )}
                                                    </select>
                                                </div>


                                                <div className="mb-2 me-2 restraintChk-in">
                                                    <img src={IconRestraints} className="icnn" alt="" />
                                                    <label htmlFor="positionId" className="form-label">Position</label>
                                                    <select id='positionId' name='positionId' className="form-select form-select-sm" value={sendForm.positionId} onChange={handleChange}>
                                                        <option value="0" selected>Select Position</option>
                                                        {typePositionsList && typePositionsList.map((val, ind) => {
                                                            return (
                                                                <option value={val.id}>{val.remark}</option>
                                                            )
                                                        }
                                                        )}
                                                    </select>
                                                </div>
                                                <div className="mb-2 me-2 restraintChk-in">
                                                    <span className='restinfo'>
                                                        {/* <i className="bi bi-info-circle" data-bs-toggle="modal" data-bs-title="positioninfo" data-bs-target="#positioninfoModal"></i>                                      */}
                                                        <i className="bi bi-info-circle-fill" data-bs-toggle="modal" data-bs-title="positioninfo" data-bs-target="#positioninfoModal"></i>

                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 
                    <div className="row mt-2">
                        <div className="col-md-12 col-sm-12">
                            <div className="fieldsett-in">
                                <div className="fieldsett">
                                    <span className='fieldse'>{t("Diagnosis")}</span>
                                    <div className="inner-content mt-2" style={{ maxHeight: '25vh', overflow: 'auto' }}>
                                        <ProvisonalDiagnosisMainComponent isProvisional={0} saveDiagnosis={saveDiagnosis} setSaveDiagnosis={setSaveDiagnosis} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}


                    {/* -------------------------------Restrain List Section------------------------------------- */}
                    <div className='whitebg mt-2' style={{ paddingTop: '0' }}>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className='handleinfo'>
                                    <Heading text={t("Restrain List")} />
                                    {/* <span className='restinfo'>
                                        <i className="bi bi-info-circle" data-bs-toggle="modal" data-bs-title="positioninfo" data-bs-target="#positioninfoModal"></i>
                                    </span> */}
                                </div>
                                <div className="fieldsett-in">
                                    <div className='med-table-section' style={{ maxHeight: '40vh', overflow: 'auto' }}>
                                        <table className='med-table thborder_ border_ striped billingTable'>
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>#</th>
                                                    <th className='text-center'>Date/Time</th>
                                                    <th className='text-center'>PB</th>
                                                    <th className='text-center'>NC</th>
                                                    <th className='text-center'>LC</th>
                                                    <th className='text-center'>SS</th>
                                                    <th className='text-center'>ROM</th>
                                                    <th className='text-center'>SI</th>
                                                    <th className='text-center'>Position</th>
                                                    <th className='text-center'>Comment</th>
                                                    <th className='text-center'>Staff Name & Emp. Id</th>
                                                    <th className='text-center'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {restrainRows && restrainRows.map((row, ind) => {
                                                    return (
                                                        <tr key={row.id}>
                                                            <td className='text-center'>{ind + 1}</td>
                                                            <td>
                                                                {/* <input type="datetime-local" className="form-control form-control-sm" id={`monitoringTime_${row.id}`} name='monitoringTime' placeholder="monitoringTime" /> */}
                                                                <input type="datetime-local" className="form-control form-control-sm" value={row.monitoringTime} id={`monitoringTime_${row.id}`} name='monitoringTime' onChange={(event) => handleChange(event, ind)} placeholder="monitoringTime" />
                                                            </td>
                                                            <td>
                                                                <select id={`patientBehavior_${row.id}`} value={row.patientBehavior} name='patientBehavior' className="form-select form-select-sm" onChange={(event) => handleChange(event, ind)}>
                                                                    <option value="0" selected>Select PB</option>
                                                                    {typePBList && typePBList.map((val, ind) => {
                                                                        return (
                                                                            <option value={val.id}>{val.remark}</option>
                                                                        )
                                                                    }
                                                                    )}
                                                                </select>

                                                            </td>
                                                            <td>
                                                                <select id={`nursingCare_${row.id}`} name='nursingCare' value={row.nursingCare} className="form-select form-select-sm" onChange={(event) => handleChange(event, ind)}>
                                                                    <option value="0" selected>Select NC</option>
                                                                    {typeNCList && typeNCList.map((val, ind) => {
                                                                        return (
                                                                            <option value={val.id}>{val.remark}</option>
                                                                        )
                                                                    }
                                                                    )}
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select id={`limbCirculation_${row.id}`} name='limbCirculation' value={row.limbCirculation} className="form-select form-select-sm" onChange={(event) => handleChange(event, ind)}>
                                                                    <option value="0" selected>Select LC</option>
                                                                    {typeLCList && typeLCList.map((val, ind) => {
                                                                        return (
                                                                            <option value={val.id}>{val.remark}</option>
                                                                        )
                                                                    }
                                                                    )}
                                                                </select>
                                                            </td>

                                                            <td>
                                                                <select id={`skinSensation_${row.id}`} name='skinSensation' value={row.skinSensation} className="form-select form-select-sm" onChange={(event) => handleChange(event, ind)}>
                                                                    <option value="0" selected>Select SS</option>
                                                                    {typeSSList && typeSSList.map((val, ind) => {
                                                                        return (
                                                                            <option value={val.id}>{val.remark}</option>
                                                                        )
                                                                    }
                                                                    )}
                                                                </select>
                                                            </td>

                                                            <td>
                                                                <select id={`movementRange_${row.id}`} name='movementRange' value={row.movementRange} className="form-select form-select-sm" onChange={(event) => handleChange(event, ind)}>
                                                                    <option value="0" selected>Select ROM</option>
                                                                    {typeROMList && typeROMList.map((val, ind) => {
                                                                        return (
                                                                            <option value={val.id}>{val.remark}</option>
                                                                        )
                                                                    }
                                                                    )}
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select id={`skinIntegrity_${row.id}`} name='skinIntegrity' value={row.skinIntegrity} className="form-select form-select-sm" onChange={(event) => handleChange(event, ind)}>
                                                                    <option value="0" selected>Select SI</option>
                                                                    {typeSIList && typeSIList.map((val, ind) => {
                                                                        return (
                                                                            <option value={val.id}>{val.remark}</option>
                                                                        )
                                                                    }
                                                                    )}
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select id={`patientPosition_${row.id}`} name='patientPosition' value={row.patientPosition} className="form-select form-select-sm" onChange={(event) => handleChange(event, ind)}>
                                                                    <option value="0" selected>Select Position</option>
                                                                    {typePHrlyList && typePHrlyList.map((val, ind) => {
                                                                        return (
                                                                            <option value={val.id}>{val.remark}</option>
                                                                        )
                                                                    }
                                                                    )}
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control form-control-sm" id={`remark_${row.id}`} name="remark" value={row.remark} placeholder="Comment" onChange={(event) => handleChange(event, ind)} />
                                                            </td>
                                                            <td>
                                                                <select id={`monitoredBy_${row.id}`} name='monitoredBy' value={row.monitoredBy} className="form-select form-select-sm" onChange={(event) => handleChange(event, ind)}>
                                                                    <option value="0" selected>Select User</option>
                                                                    {userList && userList.map((val, ind) => {
                                                                        return (
                                                                            <option value={val.id}> {val.name} ({val.empNo})</option>
                                                                        )
                                                                    }
                                                                    )}
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <div className='action-button cmlBtn'>
                                                                    <i className="bi bi-plus" onClick={addRestrainRow}></i> &nbsp;
                                                                    <i className="bi bi-trash3" onClick={() => removeRestrainRow(ind)}></i></div>
                                                            </td>

                                                        </tr>

                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                        <small id="errRestrainMsg" className="invalid-feedback" style={{ display: 'none' }}></small>
                                    </div>
                                </div>
                            </div>
                            {/* -------------------------------For Cemical Restraint Section-------------------------------- */}

                            <div className="col-md-12 col-sm-12 mt-3">
                                <div className='handlser'>
                                    <Heading text={t("For Chemical Restaint")} />
                                </div>
                                <div className='btnvitalsection'>
                                    <div className="fieldsett-in">
                                        <div className='ChemicalSection'>
                                            <span data-bs-toggle="modal" data-bs-title="Forgot" data-bs-target="#RestraintVitalsModal">Save Vitals</span>
                                            <span data-bs-toggle="modal" data-bs-title="Forgot" data-bs-target="#RestraintIntakeModal">Save Intake</span>
                                            <span data-bs-toggle="modal" data-bs-title="Forgot" data-bs-target="#RestraintOutputModal">Save Output</span>
                                        </div>
                                    </div>
                                    <div className='savbtnright'>
                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />

                                                    : <div>
                                                        <>
                                                            <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handlerSave}><img src={saveButtonIcon} className='icnn' alt='' />Save</button>
                                                            <button type="button" className="btn btn-clear btn-sm mb-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt='' />Clear</button>
                                                        </>

                                                    </div>}
                                            </>
                                        }
                                    </div>
                                </div>
                                <RestraintVitals />
                                <RestrainIntake />
                                <RestraintOutput />

                                {/* ----------------------------------------------Info Popup--------------------------------------- */}
                                <div className='modal fade' id="positioninfoModal" aria-labelledby="positioninfoModalLabel" aria-hidden="true" data-bs-backdrop="static">
                                    <div className="modal-dialog modalDelete_ modal-lg" style={{ margin: '3% auto' }}>
                                        <div className="modal-content position-relative">


                                            {/* <button type="button" className="btn-close_ position-absolute top-0 start-100 translate-middle bg-dark bg-gradient text-white border-0 rounded-circle_ rounded" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg"></i></button> */}
                                            <div className="modal-header modalheading">
                                                <h5 className="modal-title">Note</h5>
                                                <i className="fa fa-times" data-bs-dismiss="modal" aria-label="Close"></i>
                                            </div>

                                            <div className="modal-body text-center1">
                                                <div>
                                                    <div>
                                                        <div className='hourlydetils mt-0 pt-0 infoheadrest'>
                                                            <strong> Hourly</strong>
                                                        </div>
                                                        <div className='hourlylist inforestraint'>
                                                            <ul>
                                                                <li>Assess the general condition/behavior of the patient (Calm, agitated, confused, restless)</li>
                                                                <li>Assess Patient general comfort</li>
                                                                <li>Assess circulation and sensation and range of movement of restrained limb</li>
                                                                <li>Assess the need for activities of daily living (hunger, thirst, personal hygiene, toileting)</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='hourlydetils infoheadrest'>
                                                            <strong>2 Hourly</strong>
                                                        </div>
                                                        <div className='hourlylist inforestraint'>
                                                            <ul>
                                                                <li>Inspect skin for redness/injury/breakdown by removing the restraints</li>
                                                                <li>Change the patient's position</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                                {/* ----------------------------------------------Info Popup--------------------------------------- */}

                            </div>

                        </div>
                    </div>

                    {/* -----------------------------------------Start Get Data Section------------------------------- */}
                    <div className='whitebg mt-2 mb-2' style={{ paddingTop: '0' }}>
                        {/* <PatientMonitoringListTable /> */}

                        <div className="row">
                            <div className="col-md-12 col-sm-12">

                                <div className='handlser'>
                                    <Heading text={t("Patient Restrain List")} />
                                </div>
                                <div className="fieldsett-in">
                                    <div className='med-table-section' style={{ maxHeight: '35vh', overflow: 'auto' }}>
                                        <table className='med-table thborder_ border_ striped billingTable1'>
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>#</th>
                                                    <th className='text-center'>Restrain start time</th>
                                                    <th className='text-center'>Restrain end time</th>
                                                    <th className='text-center'>Reason for restraint</th>
                                                    <th className='text-center'>Types of restraint</th>
                                                    <th className='text-center'>Types of device</th>
                                                    <th className='text-center'>Location</th>
                                                    <th className='text-center'>Position</th>
                                                    <th className='text-center'>Diagnosis</th>
                                                    <th className='text-center'>Restrain List</th>

                                                    <th className='text-center'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {restraintList && restraintList.map((restraint, index) => (
                                                    <tr key={restraint.id}>
                                                        <td className='text-center'>{index + 1}</td>
                                                        <td className='text-center' style={{ whiteSpace: 'nowrap' }}>{formatDateTime(restraint.restraintStartTime)}</td>
                                                        <td className='text-center' style={{ whiteSpace: 'nowrap' }}>{formatDateTime(restraint.restraintStopTime)}</td>
                                                        <td className='text-center'>{restraint.restraintReason}</td>
                                                        <td className='text-center'>{restraint.restraintTypeName}</td>
                                                        <td className='text-center'>{restraint.deviceName}</td>
                                                        <td className='text-center'>{restraint.bodyLocationName}</td>
                                                        <td className='text-center'>{restraint.positionName}</td>
                                                        <td>
                                                            {restraint.diagnosis && restraint.diagnosis.length > 0 && (
                                                                <div className='monitRest1'>
                                                                    {JSON.parse(restraint.diagnosis).map((item, i) => (
                                                                        item.details && (
                                                                            <span key={i}>{item.details.trim()}, </span>
                                                                        )
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </td>

                                                        <td>
                                                            <td>

                                                                {/* <strong> Patient Behavior Name: </strong>
                                                                {patientRestraintList[index] && patientRestraintList[index].map((item, i) => (
                                                                    item.PatientBehaviorName && (
                                                                        <>
                                                                            <span key={i}>{item.PatientBehaviorName.trim()}, </span>
                                                                        </>
                                                                    )
                                                                ))}<br /> */}


                                                                {patientRestraintList[index] && patientRestraintList[index].some(item => item.PatientBehaviorName) && (
                                                                    <>
                                                                        <strong> Patient Behavior Name:&nbsp;</strong>
                                                                        {patientRestraintList[index].map((item, i) => (
                                                                            item.PatientBehaviorName && (
                                                                                <span key={i}>{item.PatientBehaviorName.trim()}, </span>
                                                                            )
                                                                        ))}
                                                                        <br />
                                                                    </>
                                                                )}

                                                                {patientRestraintList[index] && patientRestraintList[index].some(item => item.PatientBehaviorName) && (
                                                                    <>
                                                                        <strong>  Nursing Care Name:&nbsp;</strong>
                                                                        {patientRestraintList[index].map((item, i) => (
                                                                            item.PatientBehaviorName && (
                                                                                <span key={i}>{item.NursingCareName.trim()}, </span>
                                                                            )
                                                                        ))}
                                                                        <br />
                                                                    </>
                                                                )}
                                                                {patientRestraintList[index] && patientRestraintList[index].some(item => item.PatientBehaviorName) && (
                                                                    <>
                                                                        <strong> Limb Circulation Name:&nbsp;</strong>
                                                                        {patientRestraintList[index].map((item, i) => (
                                                                            item.PatientBehaviorName && (
                                                                                <span key={i}>{item.LimbCirculationName.trim()}, </span>
                                                                            )
                                                                        ))}
                                                                        <br />
                                                                    </>
                                                                )}
                                                                {patientRestraintList[index] && patientRestraintList[index].some(item => item.PatientBehaviorName) && (
                                                                    <>
                                                                        <strong>Skin Sensation Name:&nbsp;</strong>
                                                                        {patientRestraintList[index].map((item, i) => (
                                                                            item.PatientBehaviorName && (
                                                                                <span key={i}>{item.SkinSensationName.trim()}, </span>
                                                                            )
                                                                        ))}
                                                                        <br />
                                                                    </>
                                                                )}
                                                                {patientRestraintList[index] && patientRestraintList[index].some(item => item.PatientBehaviorName) && (
                                                                    <>
                                                                        <strong>Movement Range Name:&nbsp;</strong>
                                                                        {patientRestraintList[index].map((item, i) => (
                                                                            item.PatientBehaviorName && (
                                                                                <span key={i}>{item.MovementRangeName.trim()}, </span>
                                                                            )
                                                                        ))}
                                                                        <br />
                                                                    </>
                                                                )}
                                                                {patientRestraintList[index] && patientRestraintList[index].some(item => item.PatientBehaviorName) && (
                                                                    <>
                                                                        <strong>Skin Integrity Name:&nbsp;</strong>
                                                                        {patientRestraintList[index].map((item, i) => (
                                                                            item.PatientBehaviorName && (
                                                                                <span key={i}>{item.SkinIntegrityName.trim()}, </span>
                                                                            )
                                                                        ))}
                                                                        <br />
                                                                    </>
                                                                )}
                                                                {patientRestraintList[index] && patientRestraintList[index].some(item => item.PatientBehaviorName) && (
                                                                    <>
                                                                        <strong>Patient Position Name:&nbsp;</strong>
                                                                        {patientRestraintList[index].map((item, i) => (
                                                                            item.PatientBehaviorName && (
                                                                                <span key={i}>{item.PatientPositionName.trim()}, </span>
                                                                            )
                                                                        ))}
                                                                        <br />
                                                                    </>
                                                                )}
                                                                {patientRestraintList[index] && patientRestraintList[index].some(item => item.PatientBehaviorName) && (
                                                                    <>
                                                                        <strong>Comment:&nbsp;</strong>
                                                                        {patientRestraintList[index].map((item, i) => (
                                                                            item.PatientBehaviorName && (
                                                                                <span key={i}>{item.Remark.trim()}, </span>
                                                                            )
                                                                        ))}
                                                                        <br />
                                                                    </>
                                                                )}
                                                                {patientRestraintList[index] && patientRestraintList[index].some(item => item.PatientBehaviorName) && (
                                                                    <>
                                                                        <strong>Monitoring Date Time:&nbsp;</strong>
                                                                        {patientRestraintList[index].map((item, i) => (
                                                                            item.PatientBehaviorName && (
                                                                                <span key={i} style={{ whiteSpace: 'nowrap' }}>{formatDateTime(item.MonitoringTime.trim())}, </span>
                                                                            )
                                                                        ))}
                                                                        <br />
                                                                    </>
                                                                )}
                                                            </td>
                                                        </td>
                                                        <td className='text-center' style={{ verticalAlign: 'middle' }} onClick={() => handlePrintRestraint(restraint)}><span className='printRestForm'><i className='fa fa-print'></i></span></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -----------------------------------------End Get Data Section------------------------------- */}


                    {
                        showLoder === 1 ? <Loader val={showLoder} /> : ""
                    }
                    {/* Toaster */}
                    {
                        isShowToaster === 1 ?
                            <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                    }

                    {
                        showAlertToster === 1 ?
                            <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                    }

                </div>

            </section>

        </>
    )
}


