export default async function GetAllDashboardParameterColumnAssign(id) {
    let url = ``
    url = window.AppbaseUrl + `/api/DashboardParameterColumnAssign/GetAllDashboardParameterColumnAssign?UserId=${window.userId}&ClientId=${window.clientId}&ColumnId=${id}&AssignedTo=${window.userId}`
    let head = { 'Content-Type': 'application/json', 'accept': '*/*', };
    let data = {};
    let responsonse = await fetch(url, {
        method: 'GET',
        headers: head,
    })
        .then(res => res.json())
        .then(data)
    // console.log(responsonse)

    return responsonse
}
