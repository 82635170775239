import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import { getMedicationListData, handleDeleteRowCode } from '../../../../../Code/Medication';
import SaveIPDData from '../../../../../Code/SaveIPDData';
import { useSelector } from 'react-redux';
import { FindByQuery } from '../../../../../Code/Serach';
import PostDrugInteraction from '../../../../API/OPD/Prescription/KnowMedsAPI/PostDrugInteraction';
import PostContraIndicationList from '../../../../API/OPD/Prescription/KnowMedsAPI/PostContraIndicationList';
import POSTDeleteMedicationRow from '../../../../API/OPD/Prescription/POSTDeleteMedicationRow';
import TableContainer from '../../../../../Component/TableContainer';
import AlertToster from '../../../../../Component/AlertToster';
import SuccessToster from '../../../../../Component/SuccessToster';
import PopUpDrugIteraction from '../../../OPD/OPDSharePage/OPDPrescription/PopUp/PopUpDrugIteraction';
import PostAssignMachineToPatient from '../../../../../Pharmacy/NotificationAPI/InsertPrescriptionNotification'
import PostPatientIPDPrescription from "../../../../API/IPD/Prescription/PostPatientIPDPrescription"
import save from '../../../../../assets/images/icons/save.svg'
import stopMedicine from '../../../../../assets/images/icons/stopIconICU.svg'
import fluidIcon from '../../../../../assets/images/icons/fluidIconMed.svg'
import injectionIcon from '../../../../../assets/images/icons/injectionMed.svg'
import tablet from '../../../../../assets/images/icons/tabletMed.svg'
import capsule from '../../../../../assets/images/icons/capsuleMed.svg'
import medIcon from '../../../../../assets/images/icons/lotion.svg'
import IconIcuAdd from '../../../../../assets/images/icons/IconIcuAdd.svg';
import IconICUMedication from '../../../../../assets/images/icons/IconICUMedication.svg';
import rationalIcon from '../../../../../assets/images/icons/rationalPrescription.svg';
import frequencyIcon from '../../../../../assets/images/icons/frequencyMedication.svg';
import flowrateIcon from '../../../../../assets/images/icons/flowrate.svg';
import GetPatientIPDAllHistory from '../../../../API/IPD/Prescription/GetPatientIPDAllHistory';

export default function MedicationAddComponent(props) {
    const { t } = useTranslation();
    document.body.dir = i18n.dir()

    let getOnlySpecificData = async () => {
        let temp = window.sessionStorage.getItem("IPDpatientsendData") ? JSON.parse(window.sessionStorage.getItem("IPDpatientsendData")) : []
        let activeUHID = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : []
        let response = await GetPatientIPDAllHistory(activeUHID)
        if (response.status === 1) {
            let val = response.responseValue
            let key = Object.keys(val)
            let flags = 0
            key.map((vals, ind) => {
                if (vals === "runningPrescription") {
                    SaveIPDData(val.runningPrescription, "jsonArray")
                }

                else if (vals === "prescriptionHistory") {
                    SaveIPDData(val.prescriptionHistory, "prescriptionHistory")
                }

                flags = 1

            })

        }
    }
    const getDrugColor = (drugName) => {
        if (drugName.toLowerCase().startsWith('flu')) {
            return '#FFEBAF';
        } else if (drugName.toLowerCase().startsWith('inf')) {
            return '#EAFFE1';
        } else if (drugName.toLowerCase().startsWith('cap')) {
            return '#E6EDFF';
        } else if (drugName.toLowerCase().startsWith('tab')) {
            return '#FFE8ED';
        } else {
            return '#F5E9DD';
        }
    };


    let row = {
        id: 0,
        drugId: "",
        drugName: "",
        dosageForm: "",
        dosageStrength: "",
        doseUnit: "",
        doseFrequency: "",
        duration: "0,Days",
        remark: "",
        isAntibiotic: "",
        rationalId: 0,
        stopBy: "",
        medicineId: 0,
        flowRate: "",
        problemName: ""

    }
    let [medicationData, setMedicationData] = useState([])
    const [flowRates, setFlowRates] = useState([]);
    let [medicationList, setMedicationList] = useState([])
    let [medicationListTemp, setMedicationListTemp] = useState([])

    let [frequncyList, setFrequncyList] = useState([])
    let [frequncyListTemp, setFrequncyListTemp] = useState([])

    let [rationalList, setRationalList] = useState([])
    let [rationalListTemp, setRationalListTemp] = useState([])

    let [showSearchBoxMedic, setShowSearchBoxMedic] = useState(-1)
    let [showSearchBoxFrequncy, setShowSearchBoxFrequncy] = useState(-1)
    let [showSearchBoxRational, setShowSearchBoxRational] = useState(-1)

    let [showMessage, setShowMeassage] = useState("")
    let [showAlertToster, setShowAlertToster] = useState(0)
    let [showSuccessToster, setShowSuccessToster] = useState(0)

    let [drugInteractionId, setDrugInteractionId] = useState([])
    let [drugInteractionResponse, setDrugInteractionIdResponse] = useState([])

    // let [problemId, setProblemId] = useState([])
    let [contraIndicationResponse, setContraIndicationResponse] = useState([])
    let [sideEffectResponse, setSideEffectResponse] = useState([])

    let [showPopupDrugInteraction, setShowPopupDDrugInteraction] = useState(0)
    const [isShowFrequncy, setIsShowFrequncy] = useState(1)
    const [getUhid, setUhid] = useState(JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid);
    const [getClientId, setClientId] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientId);
    const [getUserID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
    const [getPmid, setPmid] = useState(JSON.parse(window.sessionStorage.getItem("IPDpatientList"))[0].pmId);
    const [getDoctId, setDoctId] = useState(JSON.parse(window.sessionStorage.getItem("IPDpatientList"))[0].doctorId);
    const [getDeptId, setDeptId] = useState(JSON.parse(window.sessionStorage.getItem("IPDpatientList"))[0].deptId);



    let handleDeleteRow = async (ind, id, value, drugId) => {
        handleDeleteRowCode(ind, id, value, drugId, row, drugInteractionId, medicationData, setMedicationData, setShowMeassage, setShowSuccessToster, setShowAlertToster)
        SaveIPDData([...medicationData, row], "jsonArray")
        setTimeout(() => {
            if (value !== 0) {
                getOnlySpecificData()
            }
        }, 1000)
    }

    let patientsendDataChange = useSelector((state) => state.IPDPatientSendData)

    let handleChange = (e, ind) => {


        try {
            let temp = [...medicationData]
            let value = e.target.value
            let name = e.target.name
            console.log("Value typed", value)
            if (e.target.value !== "") {
                if (name === "drugData") {
                    temp[ind]["drugId"] = 0
                    temp[ind]["drugName"] = value
                    temp[ind]["dosageForm"] = ""
                    temp[ind]["dosageStrength"] = ""
                    temp[ind]["doseUnit"] = ""
                    temp[ind]["isAntibiotic"] = 0

                    setMedicationData([...temp])
                    SaveIPDData([...temp], "jsonArray")
                    let response = FindByQuery(medicationList, value, "brandName")
                    if (value.toString().trim().toLowerCase() !== "flu".trim().toLowerCase()) {

                        if (response.length != 0) {
                            setMedicationListTemp(response)
                            setShowSearchBoxMedic(ind)
                        }
                        else {
                            setMedicationListTemp([t("No Data Found"), ""])
                            setShowSearchBoxMedic(-1)

                        }


                        let flag = 0
                        medicationData.map((val, ind) => {
                            if (val.id === 0 && val.drugId === "") {
                                flag = 1
                                return
                            }
                        })
                        if (flag !== 1) {
                            setMedicationData([...medicationData])
                            SaveIPDData([...medicationData, row], "jsonArray")

                        }
                        setIsShowFrequncy(1);
                        console.log('setIsShowFrequncy', isShowFrequncy);

                    }
                    else {

                        if (response.length != 0) {
                            setMedicationListTemp(response)
                            setShowSearchBoxMedic(ind)
                        }
                        else {
                            setMedicationListTemp([t("No Data Found"), ""])
                            setShowSearchBoxMedic(-1)

                        }
                        let flag = 0
                        medicationData.map((val, ind) => {
                            if (val.id === 0 && val.drugId === "") {
                                flag = 1
                                return
                            }
                        })
                        if (flag !== 1) {
                            setMedicationData([...medicationData])
                            SaveIPDData([...medicationData, row], "jsonArray")

                        }
                        setIsShowFrequncy(0)
                        console.log('MedicationData', medicationData);
                    }


                }
                else if (name === "frequencyData") {
                    temp[ind]["doseFrequency"] = value
                    setMedicationData([...temp])
                    SaveIPDData([...temp], "jsonArray")

                    let response = FindByQuery(frequncyList, value, "frequencyName")

                    if (response.length != 0) {
                        setFrequncyListTemp(response)
                        setShowSearchBoxFrequncy(ind)
                    }
                    else {
                        setShowSearchBoxFrequncy([t("No Data Found"), ""])
                        setShowSearchBoxFrequncy(-1)

                    }
                    // temp[ind]["doseFrequency"] = value;
                    // // Preserve the existing flowRate data
                    // const existingFlowRate = temp[ind]["flowRate"];
                    // temp[ind]["flowRate"] = existingFlowRate !== undefined ? existingFlowRate : "";
                    // setMedicationData([...temp]);
                    // SaveIPDData([...temp], "jsonArray");

                    // let response = FindByQuery(frequncyList, value, "frequencyName");

                    // if (response.length != 0) {
                    //     setFrequncyListTemp(response);
                    //     setShowSearchBoxFrequncy(ind);
                    // } else {
                    //     setShowSearchBoxFrequncy([t("No Data Found"), ""]);
                    //     setShowSearchBoxFrequncy(-1);
                    // }
                    console.log('MedicationData', medicationData);
                }
                else if (name === "flowRate") {
                    temp[ind]["flowRate"] = value; // Save flow rate data
                    setMedicationData([...temp]);
                    SaveIPDData([...temp], "jsonArray");
                    // setFlowRates((prevFlowRates) => {
                    //     const newFlowRates = [...prevFlowRates];
                    //     newFlowRates[ind] = value;
                    //     return newFlowRates;
                    // });
                    console.log('MedicationData', medicationData);
                }
                else if (name === "durationText") {
                    if (temp[ind]["duration"] !== "0,Days" && temp[ind]["duration"] !== "") {
                        let textdata = temp[ind]["duration"].split(",")[0]
                        let selectdata = temp[ind]["duration"].split(",")[1]
                        if (selectdata.length !== 0) {
                            temp[ind]["duration"] = value + "," + selectdata
                        }
                        else {
                            temp[ind]["duration"] = value + "," + "Days"
                        }
                        console.log('MedicationData', medicationData);

                    }
                    else {
                        temp[ind]["duration"] = value + "," + "Days"
                    }
                    setMedicationData([...temp])
                    SaveIPDData([...temp], "jsonArray")
                    console.log('MedicationData', medicationData);

                }
                else if (name === "duration") {

                    if (temp[ind]["duration"] !== "0,Days") {
                        let textdata = temp[ind]["duration"].split(",")[0]
                        let selectdata = temp[ind]["duration"].split(",")[1]
                        if (textdata.length !== 0) {
                            temp[ind]["duration"] = textdata + "," + value
                        }
                        else {
                            temp[ind]["duration"] = 0 + "," + value
                        }
                        console.log('MedicationData', medicationData);
                    }
                    else {
                        temp[ind]["duration"] = 0 + "," + value
                    }


                    setMedicationData([...temp])
                    SaveIPDData([...temp], "jsonArray")
                    console.log('MedicationData', medicationData);
                }
                else if (name === "rationalData") {
                    temp[ind]["rationalId"] = value
                    document.getElementById("rationalData" + ind).value = value;
                    setMedicationData([...temp])
                    SaveIPDData([...temp], "jsonArray")

                    let response = FindByQuery(rationalList, value, "problemName")

                    if (response.length != 0) {
                        setFrequncyListTemp(response)
                        setShowSearchBoxRational(ind)
                    }
                    else {
                        setShowSearchBoxRational([t("No Data Found"), ""])
                        setShowSearchBoxRational(-1)

                    }
                    console.log('MedicationData', medicationData);
                }
                else if (name === "remark") {
                    temp[ind]["remark"] = e.target.value
                    setMedicationData([...temp])
                    SaveIPDData([...temp], "jsonArray")

                    console.log('MedicationData', medicationData);
                }
            }
            else {
                if (name === "drugData") {
                    temp[ind]["drugId"] = 0
                    temp[ind]["drugName"] = ""
                    temp[ind]["dosageForm"] = ""
                    temp[ind]["dosageStrength"] = ""
                    temp[ind]["doseUnit"] = ""
                    temp[ind]["isAntibiotic"] = 0
                    setMedicationData([...temp])
                    SaveIPDData([...temp], "jsonArray")
                    console.log('MedicationData', medicationData);
                }
                else if (name === "frequencyData") {
                    temp[ind]["doseFrequency"] = ""
                    setMedicationData([...temp])
                    SaveIPDData([...temp], "jsonArray")

                    // temp[ind]["duration"] = value

                    temp[ind]["flowRate"] = "";
                    setFlowRates((prevFlowRates) => {
                        const newFlowRates = [...prevFlowRates];
                        newFlowRates[ind] = "";
                        return newFlowRates;
                    });
                    console.log('MedicationData', medicationData);
                }
                else if (name === "flowRate") {
                    temp[ind]["flowRate"] = "";
                    setMedicationData([...temp]);
                    SaveIPDData([...temp], "jsonArray");

                    setFlowRates((prevFlowRates) => {
                        const newFlowRates = [...prevFlowRates];
                        newFlowRates[ind] = "";
                        return newFlowRates;
                    });
                    console.log('MedicationData', medicationData);
                }
                else if (name === "durationText") {
                    temp[ind]["duration"] = ""
                    setMedicationData([...temp])
                    SaveIPDData([...temp], "jsonArray")
                    console.log('MedicationData', medicationData);
                }
                else if (name === "duration") {
                    temp[ind]["duration"] = ""

                    setMedicationData([...temp])
                    SaveIPDData([...temp], "jsonArray")
                    console.log('MedicationData', medicationData);

                }
                else if (name === "rationalData") {
                    temp[ind]["rationalId"] = ""
                    setMedicationData([...temp])
                    SaveIPDData([...temp], "jsonArray")
                    console.log('MedicationData', medicationData);

                }
                else if (name === "remark") {
                    temp[ind]["remark"] = ""
                    setMedicationData([...temp])
                    SaveIPDData([...temp], "jsonArray")
                    console.log('MedicationData', medicationData);

                }
                setShowSearchBoxMedic(-1)
                setShowSearchBoxFrequncy(-1)
                setShowSearchBoxRational(-1)


            }
        }
        catch (e) {
            setShowAlertToster(1)
            setShowMeassage(e.message)
        }
    }

    let sendNotification = async (datas) => {
        let activeUHID = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : []
        let patientname = JSON.parse(window.sessionStorage.getItem("IPDpatientList")).filter((val) => val.uhId.toString() === activeUHID.toString())
        let doctorName = JSON.parse(window.sessionStorage.getItem("LoginData")).name
        console.log("dsfsdfsda", doctorName)
        let data = {
            "userId": 323,
            "Uhid": activeUHID,
            "medicineData": datas,
            "patientName": patientname[0].patientName,
            "doctorName": doctorName
        }
        let sendData = {
            "notificationTemplateId": 0,
            "notificationTitle": "prescribe medicine",
            "senderId": window.userId,
            "recieverId": 99,
            "isSent": 0,
            "responseValue": JSON.stringify(data),
            "isSystemGenerated": true,
            "status": true,
            "methodName": "receivePrescription"
        }
        console.log("sedn data", sendData)
        let response = await PostAssignMachineToPatient(sendData)
        if (response.status === 1) {
            console.log("res", response)
        }
    }
    let handleClick = (name, ind, data) => {

        try {
            let temp = [...medicationData]
            // console.log("cdsc", data[5])
            if (name === "drugData") {
                console.log("csdcsdcsdcsdc145236")
                let response = FindByQuery(temp, data[1], "drugName")
                if (response.length === 0) {
                    if (response.toString().trim().toLowerCase() !== "flu".toString().trim().toLowerCase()) {
                        temp[ind]["drugId"] = data[0]
                        temp[ind]["drugName"] = data[1]
                        temp[ind]["dosageForm"] = data[2]
                        temp[ind]["dosageStrength"] = data[3]
                        temp[ind]["doseUnit"] = data[4]
                        temp[ind]["isAntibiotic"] = data[5]
                        // sendNotification(temp[ind])
                        setDrugInteractionId([...drugInteractionId, data[0]])
                        GetDrugInteractionn([drugInteractionId[drugInteractionId.length - 1], data[0]])
                        document.getElementById("drugData" + ind).value = data[2] + "-" + data[1] + "-" + data[3]
                        setShowSearchBoxMedic(-1)
                        setMedicationData(temp)
                        SaveIPDData(temp, "jsonArray")
                    }
                    else {
                        temp[ind]["drugId"] = data[0]
                        temp[ind]["drugName"] = data[1]
                        temp[ind]["dosageForm"] = data[2]
                        temp[ind]["dosageStrength"] = data[3]
                        temp[ind]["doseUnit"] = data[4]
                        temp[ind]["isAntibiotic"] = data[5]
                        // sendNotification(temp[ind])
                        setDrugInteractionId([...drugInteractionId, data[0]])
                        GetDrugInteractionn([drugInteractionId[drugInteractionId.length - 1], data[0]])
                        document.getElementById("drugData" + ind).value = data[2] + "-" + data[1] + "-" + data[3]
                        setShowSearchBoxMedic(-1)
                        setMedicationData(temp)
                        SaveIPDData(temp, "jsonArray")
                        setIsShowFrequncy(0)
                    }

                }
                else {
                    setShowAlertToster(1)
                    setShowMeassage(t("Medicine Already Exits"))
                    setShowSearchBoxMedic(-1)
                }

            }
            else if (name === "frequencyData") {
                // console.log("data", data)
                temp[ind]["doseFrequency"] = data
                document.getElementById("frequencyData" + ind).value = data

                setShowSearchBoxFrequncy(-1)
                setMedicationData(temp)
                SaveIPDData(temp, "jsonArray")

            }

            else if (name === "rationalData") {
                temp[ind]["rationalId"] = data[0]
                temp[ind]["problemName"] = data[1]

                setShowSearchBoxRational(-1)
                document.getElementById("rationalData" + ind).value = data[1];
                setMedicationData(temp)
                SaveIPDData(temp, "jsonArray")

            }
        }
        catch (e) {
            setShowAlertToster(1)
            setShowMeassage(e.message)
        }

    }
    let getProblemId = () => {
        let problemId = []
        let temp = window.sessionStorage.getItem("patientsendData") ? JSON.parse(window.sessionStorage.getItem("patientsendData")) : []
        let activeUHID = window.sessionStorage.getItem("activePatient") ? JSON.parse(window.sessionStorage.getItem("activePatient")).Uhid : []
        temp.map((value, index) => {
            value.map((val, ind) => {
                if (value[0] === activeUHID) {
                    let key = Object.keys(val)
                    if (key[0] === "jsonDiagnosis") {
                        val.jsonDiagnosis.map((val, ind) => {
                            if (val != null) {
                                // setProblemId([...problemId, val.problemId])
                                problemId.push(val.problemId)

                            }
                        })
                    }
                }
            })
        })
        return problemId
    }
    let GetDrugInteractionn = async (senddata) => {

        let problemId = getProblemId()
        // let drugresponse = await PostDrugInteraction({ "brandId": "11884,11885,11886,11887,11888,12537", "medicineName": "string" })
        // let contraResponse = await PostContraIndicationList({ "brandId": "38802,38898", "diseaseName": "834" })
        // let sideEffectResponse = await ADRReportPost({"brandId":"3219","problemId":"14166,2891,577"})

        let drugresponse = await PostDrugInteraction({ "brandId": senddata.toString(), "medicineName": "string" })
        let contraResponse = await PostContraIndicationList({ "brandId": senddata.toString(), "diseaseName": problemId.toString() })
        // let sideEffectResponse = await ADRReportPost({ "brandId": senddata.toString(), "problemId": problemId.toString() })
        if (drugresponse.status === 1 && drugresponse.responseValue.length != 0) {
            console.log("1")
            setShowPopupDDrugInteraction(1)
            setDrugInteractionIdResponse(drugresponse.responseValue)
        }
        else {
            setDrugInteractionIdResponse([])

        }
        if (contraResponse.status === 1 && contraResponse.responseValue.length != 0) {
            console.log("2")

            setShowPopupDDrugInteraction(1)
            setContraIndicationResponse(contraResponse.responseValue)
        }
        else {
            setContraIndicationResponse([])

        }
        if (sideEffectResponse.status === 1 && sideEffectResponse.responseValue[0].otherSideEffect.length != 0) {
            console.log("3")

            setShowPopupDDrugInteraction(1)
            setSideEffectResponse(sideEffectResponse.responseValue[0].otherSideEffect)
        }
        else {
            setSideEffectResponse([])

        }
    }
    let handleSave = async () => {
        console.log("data", medicationData)
        let sendData = {
            "uhId": getUhid,
            "deptId": getDeptId,
            "pmId": getPmid,
            "userId": getUserID,
            "doctorId": getDoctId,
            "jsonArray": JSON.stringify(medicationData),

            "clientId": getClientId
        }
        let response = await PostPatientIPDPrescription(sendData)
        if (response.status === 1) {
            getOnlySpecificData()
            setData()
            props.setData()
        }
    }



    let handleStop = async (ind, id) => {
        try {
            let temp = [...medicationData]
            let sendData = {
                "id": id,
                "isStop": 1,
                "userId": window.userId
            }
            let response = await POSTDeleteMedicationRow(sendData)
            if (response.status === 1) {
                temp.splice(ind, 1)
                setMedicationData([...temp])
                SaveIPDData([...temp], "jsonArray")

                setShowMeassage(t("Medicine Stop Successfully"))
                setShowSuccessToster(1)
                getOnlySpecificData()
            }
            else {
                setShowMeassage(response.responseValue)
                setShowAlertToster(1)
            }
        }
        catch (e) {
            setShowAlertToster(1)
            setShowMeassage(e)
        }

    }

    useEffect(() => {
        // getMedicationList();
        getMedicationListData(setMedicationList, setMedicationListTemp, setFrequncyList, setFrequncyListTemp, setRationalList, setRationalListTemp, setShowAlertToster, setShowMeassage)
    }, [])

    // useEffect(() => {
    //     SaveIPDData(medicationData, "jsonArray")
    // }, [medicationData])


    let setData = () => {

        function query(data, query, value) {
            return data
                .filter(item => item[value] == query)

        }

        let temp = window.sessionStorage.getItem("IPDpatientsendData") ? JSON.parse(window.sessionStorage.getItem("IPDpatientsendData")) : []
        let activeUHID = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : []
        let medicationsData = []
        setMedicationData([row])
        let druginteractionId = []
        temp.map((value, index) => {
            value.map((val, ind) => {
                if (value[0] === activeUHID) {
                    let key = Object.keys(val)
                    if (key[0] === "jsonArray") {
                        val.jsonArray.map((val, i) => {
                            if (val != null) {
                                setDrugInteractionId([...druginteractionId, val.drugId])
                                // setMedicationData([...medicationsData, val])
                                if (val.stopBy === null || val.stopBy === "") {
                                    let resp = rationalList ? query(rationalList, val.rationalId, "id") : []
                                    if (resp.length !== 0) {
                                        val["problemName"] = resp[0].problemName
                                    }
                                    else {
                                        val["problemName"] = ""

                                    }
                                    medicationsData.push(val)
                                }
                            }


                        })
                    }
                }
            })
        })
        if (medicationsData.length != 0) {
            let index = medicationsData.findIndex(temp => temp.id === 0);
            if (index != -1) {
                setMedicationData([row, ...medicationsData])
            }
            else {
                setMedicationData([row, ...medicationsData])

            }
        }
        else {
            setMedicationData([row, ...medicationsData])

        }
    }

    let handleKeyDown = (e) => {
        if (e.keyCode === 9) {
            GetDrugInteractionn(drugInteractionId)
        }
    }
    let addRow = () => {
        setMedicationData([row, ...medicationData])

    }
    useEffect(() => {
        setData()
    }, [patientsendDataChange])

    return (
        <>
            {/* <div className='icuChartHeading mb-2'>
                <div className='icuChartHeading-inn'>
                    <div className='ICUHead-lft'>
                        <span><img src={IconICUMedication} alt='' /></span>
                        <span><h3>Medication</h3></span>
                    </div>
                </div>

                <div className='icuChartHeading-inn pointSpan'>

                    <span><img src={IconIcuAdd} onClick={addRow} alt='' /></span>
                </div>
            </div> */}
            <div className='roww mt-2'>
                <div className={` boxcontainer med-table-section ipdtablemedication`} style={{ height: "35vh", }}>
                    <TableContainer>
                        {/* <thead>
                        <th className="wrap-content">#</th>
                        <th><img src={meddetails} className='icnim' />{t("Medication Details")}</th>
                        <th><img src={frequency} className='icnim' />{t("Frequency")}</th>
                        <th><img src={duration} className='icnim' />{t("Duration")}</th>
                        <th><img src={rational} className='icnim' />{t("RATIONALE")}</th>
                        <th><img src={remark1} className='icnim' />{t("Remark")}</th>
                        <th><img src={status} className='icnim' />{t("Action")}</th>
                    </thead> */}
                        <thead>
                            <th className="wrap-content">#</th>
                            <th>{t("Medication Details")}</th>
                            <th>{t("Frequency")}</th>
                            <th>{t("Duration")}</th>
                            <th>{t("RATIONALE")}</th>
                            <th>{t("Remark")}</th>
                            <th>{t("Action")}</th>
                        </thead>
                        <tbody className='pb-5' >
                            {medicationData && medicationData.map((val, ind) => {
                                return (
                                    <tr key={ind}  >
                                        <td>{ind + 1}</td>
                                        <td className='position-relative'>
                                            {/* {medicationListTemp && <AutoComplete searchKey="dosageFormName" suggestions={medicationListTemp} />}  */}
                                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                                {val.drugName.toLowerCase().startsWith('flu') && <img src={fluidIcon} alt="Flu Icon" style={{ height: '20px', position: 'absolute', left: '5px', top: '50%', transform: 'translateY(-50%)' }} />}
                                                {val.drugName.toLowerCase().startsWith('inf') && <img src={injectionIcon} alt="Inf Icon" style={{ height: '20px', position: 'absolute', left: '5px', top: '50%', transform: 'translateY(-50%)' }} />}
                                                {val.drugName.toLowerCase().startsWith('tab') && <img src={tablet} alt="Tab Icon" style={{ height: '20px', position: 'absolute', left: '5px', top: '50%', transform: 'translateY(-50%)' }} />}
                                                {val.drugName.toLowerCase().startsWith('cap') && <img src={capsule} alt="Cap Icon" style={{ height: '20px', position: 'absolute', left: '5px', top: '50%', transform: 'translateY(-50%)' }} />}
                                                {!val.drugName.toLowerCase().startsWith('flu') && !val.drugName.toLowerCase().startsWith('cap') && !val.drugName.toLowerCase().startsWith('tab') && !val.drugName.toLowerCase().startsWith('inf') && <img src={capsule} style={{ height: '20px', position: 'absolute', left: '5px', top: '50%', transform: 'translateY(-50%)' }} />}
                                                <input autoComplete="off" style={{ zIndex: 25, paddingLeft: '30px', backgroundColor: getDrugColor(val.drugName) }} type='text' className='opdmedicationinput' name='drugData' value={val.drugName.toUpperCase() ? val.drugName.toUpperCase() : ""} placeholder={t('Dosage Form - Medicine Name - Strength')} id={`drugData${ind}`} onChange={(e) => { handleChange(e, ind) }} onKeyDown={handleKeyDown} />
                                            </div>

                                            {showSearchBoxMedic === ind ?
                                                <div id="medicListDiv" className='position-absolute opdmedicationsearchbox'>
                                                    <ul id="drugul">
                                                        {medicationListTemp && medicationListTemp.map((val, index) => {
                                                            return (

                                                                <li className='pointer' onClick={(e) => { handleClick("drugData", ind, [val.id, val.brandName, val.dosageFormName, val.doseStrength, val.doseUnitID, val.isAntibiotic]) }}>
                                                                    {val.brandName.toUpperCase()}

                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                    <div className='full-screen-div-opd' onClick={() => { setShowSearchBoxMedic(-1) }}></div>

                                                </div>
                                                : ""}
                                        </td>
                                        <td>

                                            {
                                                isShowFrequncy === 1 ?
                                                    <>
                                                        <input autoComplete="off" type='text' className='opdmedicationinput' name="frequencyData" id={`frequencyData${ind}`} value={val.doseFrequency != "" ? val.doseFrequency : val.flowRate.length !== 0 ? val.flowRate : ""} placeholder={t('OD_BD_TDS')} style={{ width: "130px" }} onChange={(e) => { handleChange(e, ind) }} />
                                                        {showSearchBoxFrequncy === ind ?
                                                            <div className='position-absolute opdmedicationsearchbox'>
                                                                <ul id="drugdataList">
                                                                    {frequncyListTemp && frequncyListTemp.map((val, index) => {
                                                                        return (

                                                                            <li className='pointer' onClick={(e) => { handleClick("frequencyData", ind, val.frequencyName) }}>{val.frequencyName}</li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                                <div className='full-screen-div-opd' onClick={() => { setShowSearchBoxFrequncy(-1) }}></div>

                                                            </div> : ""

                                                        }
                                                    </> : isShowFrequncy === 0 ?
                                                        <input autoComplete="off" type='text' className='opdmedicationinput' name="flowRate" id={`flowRate${ind}`} value={val.doseFrequency != "" ? val.doseFrequency : val.flowRate.length !== 0 ? val.flowRate : ""} placeholder="flow rate" style={{ width: "130px" }} onChange={(e) => { handleChange(e, ind) }} /> : ""
                                            }
                                        </td>
                                        {/*<td style={{ position: 'relative' }}>
                                            {
                                                isShowFrequncy === 1 ?
                                                    <>
                                                        <div style={{ position: 'relative', width: '130px' }}>
                                                            <input
                                                                autoComplete="off"
                                                                type='text'
                                                                className='opdmedicationinput'
                                                                name="frequencyData"
                                                                id={`frequencyData${ind}`}
                                                                value={val.doseFrequency != "" ? val.doseFrequency : val.flowRate.length !== 0 ? val.flowRate : ""}
                                                                placeholder={t('OD_BD_TDS')}
                                                                style={{ width: "100%" }}
                                                                onChange={(e) => { handleChange(e, ind) }}
                                                            />
                                                          
                                                            {val.doseFrequency.length !== 0 && val.flowRate.length === 0 && (
                                                                <img src={frequencyIcon} alt="Frequency Data Icon" style={{ position: 'absolute', top: '50%', right: '5px', transform: 'translateY(-50%)' }} />
                                                            )}
                                                        </div>
                                                        {showSearchBoxFrequncy === ind &&
                                                            <div className='position-absolute opdmedicationsearchbox'>
                                                                <ul id="drugdataList">
                                                                    {frequncyListTemp && frequncyListTemp.map((val, index) => {
                                                                        return (
                                                                            <li className='pointer' key={index} onClick={(e) => { handleClick("frequencyData", ind, val.frequencyName) }}>{val.frequencyName}</li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                                <div className='full-screen-div-opd' onClick={() => { setShowSearchBoxFrequncy(-1) }}></div>
                                                            </div>
                                                        }
                                                    </>
                                                    :
                                                    isShowFrequncy === 0 ?
                                                        <>
                                                            <div style={{ position: 'relative', width: '130px' }}>
                                                                <input
                                                                    autoComplete="off"
                                                                    type='text'
                                                                    className='opdmedicationinput'
                                                                    name="flowRate"
                                                                    id={`flowRate${ind}`}
                                                                    value={val.doseFrequency != "" ? val.doseFrequency : val.flowRate.length !== 0 ? val.flowRate : ""}
                                                                    placeholder="flow rate"
                                                                    style={{ width: "100%" }}
                                                                    onChange={(e) => { handleChange(e, ind) }}
                                                                />
                                                               
                                                                {val.flowRate.length !== 0 && val.doseFrequency.length === 0 && (
                                                                    <img src={flowrateIcon} alt="Flow Rate Icon" style={{ position: 'absolute', top: '50%', right: '5px', transform: 'translateY(-50%)' }} />
                                                                )}
                                                            </div>
                                                        </>
                                                        : ""
                                            }
                                        </td>*/}

                                        {/*<td>
                                            <div className='d-flex flex-row gap-2'>
                                                <input className='opdmedicationinput' value={val.duration != "" ? val.duration !== "Days" ? val.duration.split(",")[0] : 0 : "duration"} type='number' min="1" max="5" style={{ width: "50px" }} name="durationText" onChange={(e) => { handleChange(e, ind) }} />
                                                <select className='opdmedicationinput' style={{ width: "115px" }} id={`durationData${ind}`} value={val.duration != "" ? val.duration.split(",")[1] : "duration"} name='duration' onChange={(e) => { handleChange(e, ind) }}>
                                                    <option value={"Days"}>{t("Day")}</option>
                                                    <option value={"Week"}>{t("Week")}</option>
                                                    <option value={"Month"}>{t("Month")}</option>

                                                </select>
                                            </div>
                                    </td>*/}
                                        <td>
                                            <div className='d-flex flex-row gap-2'>

                                                <input className='opdmedicationinput'
                                                    value={val.duration != "" ? val.duration !== "Days" ? val.duration.split(",")[0] : 0 : "duration"}
                                                    type='number'
                                                    min="1"
                                                    max="5"
                                                    style={{ width: "50px" }}
                                                    name="durationText"
                                                    onChange={(e) => { handleChange(e, ind) }}
                                                />
                                                ({t("D")})

                                            </div>
                                        </td>

                                        <td className='position-relative'>
                                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                                <img src={rationalIcon} style={{ height: '20px', position: 'absolute', left: '5px', top: '50%', transform: 'translateY(-50%)' }} />

                                                <input type='text' autoComplete="off" className='opdmedicationinput' style={{ width: "150px", paddingLeft: '30px' }} id={`rationalData${ind}`} value={val.problemName != 0 ? val.problemName : ""} placeholder={t('RATIONALE')} name="rationalData" onChange={(e) => { handleChange(e, ind) }} />
                                            </div>
                                            {showSearchBoxRational === ind ?
                                                <div className='position-absolute opdmedicationsearchbox'>
                                                    <ul >
                                                        {rationalListTemp && rationalListTemp.map((val, index) => {
                                                            return (
                                                                [6, 7, 8].map((id, i) => {
                                                                    if (val.problemTypeID === id) {
                                                                        return (
                                                                            <li className='pointer' onClick={(e) => { handleClick("rationalData", ind, [val.id, val.problemName]) }}>{val.problemName}</li>
                                                                        )
                                                                    }
                                                                })
                                                            )
                                                        })}
                                                    </ul>
                                                    <div className='full-screen-div-opd' onClick={() => { setShowSearchBoxRational(-1) }}></div>

                                                </div>
                                                : ""}
                                        </td>
                                        <td><input type='text' autoComplete="off" className='opdmedicationinput' style={{ width: "200px" }} id={`remark${ind}`} value={val.remark} name="remark" onChange={(e) => { handleChange(e, ind) }} placeholder={t('Remark')} /></td>
                                        <td align='center'>
                                            {
                                                val.id === 0 ?
                                                    <div className='d-flex flex-row gap-2 pe-2 ipdm' style={{ justifyContent: "space-between" }}>
                                                        {/* <i className="fa-solid fa-plus fa-xl" id={`addprescription` + ind} style={{ color: "#3ca735" }} onClick={() => { handleAddRow(ind) }}></i> */}
                                                        <i className="fa-solid fa-trash-can fa-xl" style={{ color: "#ff0000", fontSize: "18px" }} onClick={() => { handleDeleteRow(ind, val.id, 0, val.drugId) }}></i>
                                                        {
                                                            ind === 0 ?
                                                                <span><img src={IconIcuAdd} onClick={addRow} alt='' /></span> : ""
                                                        }
                                                    </div> :
                                                    <div className='d-flex flex-row gap-2 ipdm' style={{ justifyContent: "space-between" }}>
                                                        {/*<i className="fa-solid fa-stopwatch fa-xl" title='Stop Medicine' style={{ color: "#db7a1f", fontSize: "18px",cursor:'pointer' }} onClick={() => { handleStop(ind, val.id) }}></i>*/}
                                                        {/* <span style={{ cursor:'pointer', width:'20px' }}  onClick={() => { handleStop(ind, val.id) }} title='Stop Medicine' alt=''/> */}
                                                        <img style={{ cursor: 'pointer', width: '20px' }} src={stopMedicine} onClick={() => { handleStop(ind, val.id) }} title='Stop Medicine' alt='' />
                                                        <i className="fa-solid fa-trash-can fa-xl" title='Delete Medicine' style={{ color: "#ff0000", fontSize: "18px", cursor: 'pointer' }} onClick={() => { handleDeleteRow(ind, val.id, 1, val.drugId) }}></i>
                                                        {
                                                            ind === 0 ?
                                                                <span><img src={IconIcuAdd} onClick={addRow} alt='' /></span> : ""
                                                        }

                                                    </div>
                                            }
                                        </td>

                                    </tr>
                                )
                            })}



                        </tbody>
                    </TableContainer>
                    {/*<div className = 'col-md-12 ml-2' style={{textAlign : 'right'}}>
                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleSave}><img src={save} className='icnn' />Save</button>
                    </div>*/}


                </div>
                <div className='d-flex lfex-row justify-content-end mt-2'>
                    <span className='col-md-12 ml-2' style={{ textAlign: 'right' }}>
                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleSave}><img src={save} className='icnn' />Save</button>
                    </span>
                </div>
                {
                    showAlertToster === 1 ? <AlertToster message={showMessage} handle={setShowAlertToster} /> : ""
                }
                {
                    showSuccessToster === 1 ? <SuccessToster message={showMessage} handle={setShowSuccessToster} /> : ""
                }

                {
                    showPopupDrugInteraction === 1 ? <PopUpDrugIteraction drugInteractionData={drugInteractionResponse} contraIndicationnData={contraIndicationResponse} sideEffectData={sideEffectResponse} show={showPopupDrugInteraction} func={setShowPopupDDrugInteraction} getData={setMedicationData} medicationData={medicationData} />
                        : ""
                }

            </div>
        </>
    )
}
