import React from 'react'

export default function Heading(props) {
  return (
    <>
      {/* <div className="med-box">
        <div className="title">{props.text}</div>        
      </div> */}

      <div className='heading text-wrap mb-2'>
        {props.text}
      </div>



    </>



  )
}

