

const DeletePatientNurseShiftAssign = async (params) => {
    let url = window.AppbaseUrl + `/api/PatientNurseShiftAssign/DeletePatientNurseShiftAssign?id=${params.id}&userId=${params.userId}`;
    let head = { 'Content-Type': 'application/json', 'accept': '*/*', }
    let resposne = "";
    await fetch(url, {
        method: 'DELETE',
        headers: head,
        body: JSON.stringify()
    }).then((res) => res.json()).then(data => { resposne = data }).catch(error => { resposne = error });
    return resposne;
}
export default DeletePatientNurseShiftAssign;