async function GetCastSheetList(param) {
  let url =`${window.AppbaseUrl}/api/CaseSheet/GetCaseSheetList?UHID=${param.UHID}&ClientId=${param.ClientId}`;
let head = { "Content-Type": "application/JSON", accept : '*/*' };

let data ={}
let response = fetch(url, {
  headers: head,
  method : 'GET'
})
  .then((res) => res.json())
  .then(data);

return response;
}
export default GetCastSheetList;
  