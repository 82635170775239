import React, { useEffect } from 'react'
// import LoderIcon from "../assets/images/Animation/LoginIcon.gif"
import LoderIcon from "../assets/images/Animation/LoginIcon.gif"

export default function Loader(props) {

  // useEffect(() => {
  //   if (props.val === 1) {
  //     document.body.style.overflow = "hidden";
  //     document.body.style.top = 0;
  //     document.body.style.position = "fixed";
  //   }
  //   else{
  //     document.body.style.overflow = "auto";
  //     document.body.style.position = "";
  //   }
  // }, [props])
  return (
    <div className='loding-image ' style={{ 'display': `${props.val === 1 ? 'block' : 'none'}` }}>
      <img src={LoderIcon} className="load_img" style={{ width: '100px', height: '100px' }} alt="LoginIcon" />
    </div>
  )
}
