import React from 'react'

export default function ActionPopUp({ heading, subheading, methodName = "", handlefunction, setActionPopupData }) {
    return (
        <>
            <div className={`modal  d-${heading.length !== 0 ? "block " : "none"}`} id="actionpoupmodal" tabIndex="-1" aria-labelledby="actionpoupmodalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                        <div className="modal-body modelbdy text-center">
                            {
                                methodName === "Delete" ?
                                    <div className='popDeleteIcon'>
                                        <i className="fa fa-trash"></i>
                                    </div>
                                    : ""
                            }
                            <div className='popDeleteTitle mt-3'> {heading}</div>
                            <div className='popDeleteContent'>{subheading}</div>
                        </div>
                        <div className="modal-footer1 text-center">

                            <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal" onClick={() => { setActionPopupData({ heading: "" }) }}>Cancel</button>
                            <button type="button" className="btn-delete popBtnDelete" onClick={() => { handlefunction(); setActionPopupData({ heading: "" }) }} data-bs-dismiss="modal">{methodName}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
