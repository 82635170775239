import React, { useState } from 'react'
import Vector from "../assets/images/icons/Vector.svg";
import Contact from "../assets/images/icons/Contact.svg";
export default function PrintPageHeader() {
    let [clientData, setClientData] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientdata)
    
    return (
        <>
            <table>
                <tr>
                    <td>
                        <div className="sarvodya-header">
                            <div className='lft-header'>
                                {/* <img src={sarvodayalogo} alt='' className='sv-log' /> */}
                                <img src={clientData.logoUrl} alt="Company Logo"
                                    style={{ width: "165px", }} />
                            </div>
                            <div className='rght-header mt-0 '>
                                {/* <div> SARVODAYA HOSPITAL <div className='addrs'>SECTOR-8, FARIDABAD | HELPLINE: 1800 313 1414</div></div> */}
                                <div className="CompanyDetailsHeading icusht">
                                    <div className="companyNameChallan icusht mt-0 pt-0 mb-0 pb-0" >
                                       {clientData.clientName}
                                    </div>
                                    <div className="companyLocation icusht mt-0 pt-0 mb-0 pb-0">
                                        {clientData.address}
                                    </div>
                                    <div className="companyContact icusht mt-0 pt-0 mb-0 pb-0">
                                        <img src={Vector} alt=""></img> {clientData.emailID}

                                        <img src={Contact} alt=""></img> {clientData.mobileNo}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </>
    )
}
