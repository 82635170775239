import React, { useState } from 'react';

const DateRangePicker = ({ onClose, onDateSelect }) => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [fromCurrentDate, setFromCurrentDate] = useState(new Date());
  const [toCurrentDate, setToCurrentDate] = useState(new Date());
  const today = new Date();

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const renderDays = () => {
    return daysOfWeek.map(day => <div className="day-name" key={day}>{day}</div>);
  };

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const handleDateClick = (date, isFromDate) => {
    if (date > today) return; // Restrict selecting future dates

    if (isFromDate) {
      setFromDate(date);
      if (toDate && date >= toDate) {
        setToDate(null);
      }
    } else {
      setToDate(date);
      if (fromDate && date < fromDate) {
        setFromDate(date);
      }
    }

    if (fromDate && toDate) {
      onDateSelect(fromDate, toDate);
      onClose();
    }
  };

  const renderCells = (currentDate, isFromDate) => {
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = getDaysInMonth(year, month);

    const cells = [];
    for (let i = 0; i < firstDay; i++) {
      cells.push(<div className="cell empty" key={`empty-${isFromDate}-${i}`} />);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const isSelected = (isFromDate && fromDate && date.getTime() === fromDate.getTime()) || (!isFromDate && toDate && date.getTime() === toDate.getTime());
      const isInRange = fromDate && toDate && date >= fromDate && date <= toDate;
      const isDisabled = date > today;
      cells.push(
        <div
          className={`cell${isSelected ? ' selected' : isInRange ? ' in-range' : ''}${isDisabled ? ' disabled' : ''}`}
          key={day}
          onClick={() => !isDisabled && handleDateClick(date, isFromDate)}
        >
          {day}
        </div>
      );
    }

    return cells;
  };

  const nextMonth = (isFromDate) => {
    if (isFromDate) {
      setFromCurrentDate(new Date(fromCurrentDate.getFullYear(), fromCurrentDate.getMonth() + 1, 1));
    } else {
      setToCurrentDate(new Date(toCurrentDate.getFullYear(), toCurrentDate.getMonth() + 1, 1));
    }
  };

  const prevMonth = (isFromDate) => {
    if (isFromDate) {
      setFromCurrentDate(new Date(fromCurrentDate.getFullYear(), fromCurrentDate.getMonth() - 1, 1));
    } else {
      setToCurrentDate(new Date(toCurrentDate.getFullYear(), toCurrentDate.getMonth() - 1, 1));
    }
  };

  const nextYear = (isFromDate) => {
    if (isFromDate) {
      setFromCurrentDate(new Date(fromCurrentDate.getFullYear() + 1, fromCurrentDate.getMonth(), 1));
    } else {
      setToCurrentDate(new Date(toCurrentDate.getFullYear() + 1, toCurrentDate.getMonth(), 1));
    }
  };

  const prevYear = (isFromDate) => {
    if (isFromDate) {
      setFromCurrentDate(new Date(fromCurrentDate.getFullYear() - 1, fromCurrentDate.getMonth(), 1));
    } else {
      setToCurrentDate(new Date(toCurrentDate.getFullYear() - 1, toCurrentDate.getMonth(), 1));
    }
  };

  return (
    <div className="calendar-box">
      <div className="calender-data-main">
        <div className="calender-months">
          <div className="calender-header">
            <button className='btn btn-primary' onClick={() => prevYear(true)}>«</button>
            <button className='btn btn-primary' onClick={() => prevMonth(true)}>‹</button>
            <div>{fromCurrentDate.toLocaleString('default', { month: 'long' })} {fromCurrentDate.getFullYear()}</div>
            <button className='btn btn-primary' onClick={() => nextMonth(true)}>›</button>
            <button className='btn btn-primary' onClick={() => nextYear(true)}>»</button>
          </div>
          <div className="days-of-week">
            {renderDays()}
          </div>
          <div className="cells">
            {renderCells(fromCurrentDate, true)}
          </div>
        </div>
        <div className="calender-months">
          <div className="calender-header">
            <button className='btn btn-primary' onClick={() => prevYear(false)}>«</button>
            <button className='btn btn-primary' onClick={() => prevMonth(false)}>‹</button>
            <div>{toCurrentDate.toLocaleString('default', { month: 'long' })} {toCurrentDate.getFullYear()}</div>
            <button className='btn btn-primary' onClick={() => nextMonth(false)}>›</button>
            <button className='btn btn-primary' onClick={() => nextYear(false)}>»</button>
          </div>
          <div className="days-of-week">
            {renderDays()}
          </div>
          <div className="cells">
            {renderCells(toCurrentDate, false)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
