
async function PutSetting(data) {

    // let url = `${window.AdminbaseUrl}/api/CurrencyMaster/InsertCurrency?currencyName=${data.currencyName}&userId=${data.userId}`;
    // let url = window.AdminbaseUrl+`/api/SettingMaster/InsertSettingMaster?settingName=${data.SettingName}&userId=${window.userId}&clientId=${window.clientId}`;
    // let url = window.AdminbaseUrl+`/api/SettingMaster/DeleteSettingMaster?id=${data.id}&userId=${window.userId}&clientId=${window.clientId}`;
    let url = window.AdminbaseUrl+`/api/SettingMaster/UpdateSettingMaster?id=${data.id}&settingName=${data.settingName}&userId=${window.userId}&&clientId=${window.clientId}`;

    let head = { "Content-Type": "application/JSON", accept: '*/*'};
  
    let response = await fetch(url, {
      headers: head,
      method: 'PUT',
      body: JSON.stringify(data)
    })
      .then((res) => res.json())
      .then();
  
    return response;
  }
  export default PutSetting;