import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import Vector from "../../../assets/images/icons/Vector.svg";
import Contact from "../../../assets/images/icons/Contact.svg";
// import SarvodayaLogo from '../../../assets/images/icons/sarvodayalogo.png'
import PrintPageHeader from '../../../Component/PrintPageHeader';
export default function ReceiptRequestionFormWholeBlood() {
    let [bool, setBool] = useState(true);
    let [dateTime, setDateTime] = useState();
    let date = new Date()



    let [bloodReqPrintData, setSaleData] = useState(JSON.parse(window.sessionStorage.getItem("PrintBloodRequestData")))
    let [clientData, setClientData] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientdata)
    let [empId, setEmpId] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userName)
    let productInfo = JSON.parse(window.sessionStorage.getItem("PrintBloodRequestData")).productInfo
    let donorInfo = JSON.parse(window.sessionStorage.getItem("PrintBloodRequestData")).donorDetails
    let bloodGroupingInfo = JSON.parse(window.sessionStorage.getItem("PrintBloodRequestData")).bloodGrouping
    let diagnosisInfo = JSON.parse(window.sessionStorage.getItem("PrintBloodRequestData")).diagnoInfo

    // setProductInfo(productInfo)
    console.log("donorInfo", donorInfo)
    const formatDateTime = (dateTimeString) => {
        const options = {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',

            hour12: true // 24-hour format
        };
        return new Date(dateTimeString).toLocaleString('en-IN', options);
    };


    useEffect(() => {

        setDateTime(date.toLocaleDateString() + " " + date.toLocaleTimeString());



        setTimeout(() => {
            handlepritnt();
        }, 1200)

    }, [])

    let handlepritnt = () => {

        setBool(false)
        window.print("");
        window.close();

    }
    return (
        <>
            <div className="main-table" style={{ padding: '10px', width: '100%', maxWidth: '900px', margin: 'auto' }}>
                {/* Content goes here */}

                <table className='clearslip pt-0 mt-2'>
                    {/* ....header.. */}
                    <tr>
                        <td colSpan={6}>
                            <PrintPageHeader />
                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td className='mt-0 pt-0 mb-0' colSpan={4} style={{ verticalAlign: 'top' }}>
                            <div className='transfusion-heading mt-1 pt-1 mb-2 pb-2'>
                                DEPARTMENT OF TRANSFUSION MEDICINE (BLOOD CENTRE)
                                <br /> REQUISITION FORM FOR FRESH WHOLE BLOOD/COMPONENTS
                            </div>
                        </td>
                        <td className='mt-1 pt-1 mb-2 pb-2' colSpan={4} style={{ verticalAlign: 'top', textAlign: 'right' }}>
                            <strong>  BC Licence No.  </strong><span>674-B (H)</span>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8} style={{ padding: '0' }}>
                            <table>
                                <tr>
                                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px' }}><div className='mt-0 mb-2'><strong>Patient's Name : </strong><span style={{ width: 'auto', borderBottom: '1px dashed black', display: 'inline-block' }}>&nbsp; {bloodReqPrintData.patientInfo[0].patientName}</span></div> </td>
                                    <td><div className='mt-0 mb-2' style={{ fontSize: '14px' }}><strong>Age/Sex: </strong><span style={{ width: '70px', borderBottom: '1px dashed black', display: 'inline-block' }}>&nbsp; {bloodReqPrintData.patientInfo[0].age}/{bloodReqPrintData.patientInfo[0].gender}</span> </div></td>
                                    <td><div className='mt-0 mb-2' style={{ fontSize: '14px' }}><strong>IP No :</strong><span style={{ width: '60px', borderBottom: '1px dashed black', display: 'inline-block' }}>&nbsp; {bloodReqPrintData.patientInfo[0].pid}</span></div> </td>
                                    <td style={{ whiteSpace: 'nowrap', fontSize: '14px' }}><div className='mt-0 mb-2'><strong>MRN No :</strong><span style={{ width: '60px', borderBottom: '1px dashed black', display: 'inline-block' }}> &nbsp;{bloodReqPrintData.patientInfo[0].uhId}</span></div> </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8} className='ps-0 pe-0'>
                            <table>
                                <tr>
                                    <td colSpan={4} ><div className='mt-0 mb-2'><strong>Father's/Husband Name. </strong><span style={{ width: '200px', borderBottom: '1px dashed black', display: 'inline-block' }}>&nbsp;{bloodReqPrintData.patientInfo[0].guardianName}</span></div> </td>
                                    <td colSpan={4} ><div className='mt-0 mb-2'><strong>Hospital Name </strong><span style={{ width: '200px', borderBottom: '1px dashed black', display: 'inline-block' }}>&nbsp; {clientData.clientName}</span></div> </td>

                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8} className='ps-0 pe-0 mt-1 mb-1'>
                            <table>
                                <tr>
                                    <td colSpan={3}>
                                        <div className='Clinicalwid mt-0 mb-2 d-flex'><strong> Ward No./Bed N. </strong><span style={{ width: '120px', borderBottom: '1px dashed black', display: 'inline-block', marginLeft: '5px' }}>&nbsp; {bloodReqPrintData.patientInfo[0].wardName}/{bloodReqPrintData.patientInfo[0].bedName}</span>
                                        </div>
                                    </td>
                                    <td colSpan={5}>
                                        <div className='d-flex mt-0 mb-2'><div className='Clinicalwid'><strong>Clinical diagnosis with history :</strong><span style={{ width: '300px', borderBottom: '1px dashed black', display: 'inline-block', marginLeft: '5px' }}>{diagnosisInfo && diagnosisInfo.map((val, index) => (
                                            index > 0 ? `, ${val.details}` : val.details
                                        ))}</span></div></div>

                                    </td>

                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}><div className='mt-0 mb-2 d-flex'><strong> Indication Of Transfusion:</strong><span style={{ marginLeft: '5px', width: '100px', borderBottom: '1px dashed black', display: 'inline-block' }}>&nbsp; {bloodReqPrintData.indicationTransfusionName}</span> </div></td>
                        <td colSpan={5}><div className='mt-0 mb-2 d-flex'><strong> Doctor IN charge :</strong><span style={{ marginLeft: '5px', width: '220px', borderBottom: '1px dashed black', display: 'inline-block' }}> &nbsp;{bloodReqPrintData.patientInfo[0].doctorName}</span></div> </td>

                    </tr>
                    <tr>
                        <td className='mt-0' colSpan={8} ><div className='mt-0 mb-2'> <strong> Routine/Emergency/Life Saving (With justification): </strong><span style={{ width: '290px', borderBottom: '1px dashed black', display: 'inline-block' }}> {bloodReqPrintData.transfusionTypeName}</span> </div></td>

                    </tr>
                    <tr>
                        <td colSpan={4} ><strong>History of previous transfusion:yes/No </strong><span style={{ marginLeft: '5px', width: '250px', borderBottom: '1px dashed black', display: 'inline-block' }}>Yes/No </span> </td>
                        <td colSpan={4} ><strong>Patient Blood Group </strong><span style={{ marginLeft: '5px', width: '40px', borderBottom: '1px dashed black', display: 'inline-block' }}> &nbsp;{bloodReqPrintData.patientInfo[0].bloodGroupName}</span> </td>

                    </tr>

                    <tr>
                        <td colSpan={8} style={{ lineHeight: '25px' }}>
                            <strong>Reaction if any :</strong><span style={{ marginLeft: '5px', width: '340px', borderBottom: '1px dashed black', display: 'inline-block' }}> &nbsp;{bloodReqPrintData.reaction}</span>
                            <strong>If patient is woman: Has she ever been pregnant</strong>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8} style={{ lineHeight: '25px' }}>
                            <strong>/pregnant at present Yes/No GPA :</strong><span style={{ marginLeft: '5px', width: '270px', borderBottom: '1px dashed black', display: 'inline-block' }}>&nbsp; {bloodReqPrintData.isPregnant}</span>
                            <strong>History of HDNB/ Still birth/Miscarriage. </strong><span style={{ width: '60px', borderBottom: '1px dashed black', display: 'inline-block' }}></span>
                        </td>
                    </tr>


                    <tr>
                        <td className='mt-1 pt-1' colSpan={8}>
                            <div className='platecountt'>
                                <div style={{ marginBottom: '6px', marginTop: '6px' }}><strong>Hb %(with date)</strong><span style={{ marginLeft: '5px', width: '111px', borderBottom: '1px dashed black', display: 'inline-block', whiteSpace: 'nowrap' }}>&nbsp; {bloodReqPrintData.hb}</span></div>
                                <div style={{ marginBottom: '6px', marginTop: '6px' }}><strong>Platelet Count (with date)</strong><span style={{ marginLeft: '5px', width: '111px', borderBottom: '1px dashed black', display: 'inline-block' }}> &nbsp;{bloodReqPrintData.plateletCount}</span></div>
                                <div style={{ marginBottom: '6px', marginTop: '6px' }}><strong>PT/ APTT </strong><span style={{ marginLeft: '5px', width: '111px', borderBottom: '1px dashed black', display: 'inline-block' }}>&nbsp; {bloodReqPrintData.pt}</span></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}><strong>Date/Time :</strong><span style={{ marginLeft: '5px', width: '135px', borderBottom: '1px dashed black', display: 'inline-block', whiteSpace: 'nowrap' }}>&nbsp;{dateTime}</span> </td>
                        <td colSpan={6} ><strong>Name in Capital & Signature of Medical Officer </strong><span style={{ marginLeft: '5px', width: '115px', borderBottom: '1px dashed black', display: 'inline-block' }}></span> </td>

                    </tr>
                    <tr>
                        <td colSpan={8}><div className='mt-1 mb-1 pt-0' style={{ marginTop: '6px' }}><strong>With Designation & </strong></div></td>
                    </tr>
                    <tr>
                        <td colSpan={4} ><strong>Stamp of Nursing Home/Hospital :</strong><span style={{ width: '190px', borderBottom: '1px dashed black', display: 'inline-block', whiteSpace: 'nowrap' }}></span> </td>
                        <td colSpan={4} ><strong>MOBILE NUMBER :</strong><span style={{ width: '85px', borderBottom: '1px dashed black', display: 'inline-block', whiteSpace: 'nowrap' }}>&nbsp; {bloodReqPrintData.patientInfo[0].mobileNo}</span> </td>

                    </tr>
                    {/* <tr>
                        <td className='pt-0 mt-0 mb-0' colSpan={8}>
                            <table className="tblbdr ">
                                
                                <tr>
                                    <th colSpan={6} style={{ border: '1px solid' }}>Requirement: </th>
                                    <th colSpan={2} style={{ border: '1px solid' }}>No. of Units:  </th>
                                </tr>
                                <tr>
                                    <th colSpan={6} style={{ border: '1px solid' }}>PRBC: </th>
                                    <td colSpan={2} style={{ border: '1px solid' }}>cxx:  </td>
                                </tr>
                                <tr>
                                    <th colSpan={6} style={{ border: '1px solid' }}>LEUCODEPLETED PRBC: </th>
                                    <td colSpan={2} style={{ border: '1px solid' }}>cxx:  </td>
                                </tr>
                                <tr>
                                    <th colSpan={6} style={{ border: '1px solid' }}>FRESH FROZEN PLASMA (FFP) </th>
                                    <td colSpan={2} style={{ border: '1px solid' }}>cxx:  </td>
                                </tr>
                                <tr>
                                    <th colSpan={6} style={{ border: '1px solid' }}>FRESH WHOLE BLOOD </th>
                                    <td colSpan={2} style={{ border: '1px solid' }}>cxx:  </td>
                                </tr>
                            </table>
                        </td>
                    </tr> */}

                    <tr>

                        <td className='mt-2 pt-2 mb-2' colSpan={8}>
                            <table className='tblbdr'>
                                <thead>
                                    <tr>
                                        <th colSpan={6} style={{ border: '1px solid' }}>Requirement: </th>
                                        <th colSpan={2} style={{ border: '1px solid' }}>No. of Units:  </th>
                                    </tr>
                                </thead>

                                <tbody>


                                    {productInfo && productInfo.map((list, index) => {

                                        return (


                                            <tr>

                                                <th colSpan={6} style={{ border: '1px solid' }}>{list.productName}</th>
                                                <td colSpan={6} style={{ border: '1px solid' }}>{list.requestedQuantity}</td>



                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr >
                        <td className='mt-2 mb-2 pt-2 pb-2' colSpan={8} >
                            <div className='procedure-heading ' style={{ textAlign: 'left', fontSize: '15px' }}>
                                BLOOD/COMPONENTS ONCE ISSUED SHALL NOT BE TAKEN BACK
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td style={{ whiteSpace: 'nowrap' }}><div className='mb-2'><strong>Sample drawn by: Name.</strong></div></td>
                        <td><div className='mb-2'><span style={{ width: '200px', borderBottom: '1px dashed black', display: 'inline-block' }}> &nbsp;{bloodReqPrintData.sampleDrawnByName}</span></div></td>
                        <td><div className='mb-2'><strong>Signature:</strong></div></td>
                        <td><div className='mb-2'><span style={{ width: '100px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div></td>
                        <td><div className='mb-2'><strong>Emp.Id: </strong></div></td>
                        <td><div className='mb-2'><span style={{ width: '100px', borderBottom: '1px dashed black', display: 'inline-block' }}>{empId}</span></div></td>
                    </tr>
                    <tr>
                        <td style={{ whiteSpace: 'nowrap' }}><div><strong>Request sent on: Date </strong></div></td>
                        <td><div><span style={{ width: '200px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div></td>
                        <td ><div><strong>Time :</strong></div></td>
                        <td><div><span style={{ width: '100px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div></td>
                    </tr>


                    <tr>
                        <td colSpan={8} style={{ border: 'none' }}>
                            <table className="table mt-0 pt-0 mb-0">
                                <tr>
                                    <td>
                                        <div className='tb-reqest'>
                                            <div className='mytxt'>
                                                <div> 1. </div>  <div>Requisition form must be filled up up completely in all respects.</div>
                                            </div>
                                            <div className='mytxt'>
                                                <div> 2. </div>
                                                <div>  ml of patient's in each of a EDTA & PLAIN sterile tube must be sent with the requisition form, signed by duty doctor & sample should not be sent in syringe.</div>
                                            </div>
                                            <div className='mytxt'>
                                                <div> 3. </div>
                                                <div>  A new born baby up to 4 months old, send the mother's blood sample along with the baby.</div>
                                            </div>
                                            <div className='mytxt'>
                                                <div>   4. </div>
                                                <div> Sample tube should be properly labeled & signed with date by phlebotomist.</div>
                                            </div>
                                            <div className='mytxt'>
                                                <div>   5. </div>
                                                <div>   Product once issued will not be taken back in blood Centre.</div>
                                            </div>
                                            <div className='mytxt'>
                                                <div>   6. </div>
                                                <div>  Issue of depends on availability.</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr style={{ pageBreakBefore: 'always' }}>
                                    <th className='mt-2 pt-2 mb-0' colSpan={8} style={{ border: 'none' }}>
                                        <div className='tblstxt mb-0 mt-0 pt-0 ms-4'>
                                            FOR BLOOD CENTRE USE
                                        </div>
                                        <div className=' mt-0 pt-0 mb-0' style={{ border: '1px solid' }}>
                                            <div className="tblsample mt-1 pt-1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                {/* <div className='tblsamplebx'>Sample/Requisition form received by: Name.: <span style={{ width: '100px', borderBottom: '1px dashed black', display: 'inline-block' }}>{bloodReqPrintData.receivedByName}</span></div>
                                                <div className='tblsamplebx'>Signature:<span style={{ width: '40px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div>
                                                <div className='tblsamplebx'>Date/Time:<span style={{ width: '40px', borderBottom: '1px dashed black', display: 'inline-block' }}>{formatDateTime(bloodReqPrintData.receivedDate)}</span></div>
 */}





                                            </div>
                                            <div className='tblsample' style={{ fontWeight: "500" }}>
                                                <table className='clearslip pt-0 mt-2' width="100%">
                                                    <tr>
                                                        <td colSpan="4">
                                                            <div className=' mt-1 mb-1'>
                                                                <strong> Sample/Requisition form received by: Name.:</strong> <span style={{ width: '350px', borderBottom: '1px dashed black', display: 'inline-block' }}>&nbsp;{bloodReqPrintData.receivedByName}</span>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ lineHeight: '35px' }}> <strong>Signature:</strong> </td>
                                                        <td> <span style={{ width: '300px', borderBottom: '1px dashed black', display: 'inline-block' }}></span>
                                                        </td>
                                                        <td style={{ lineHeight: '35px' }}> <strong>Date/Time:</strong></td>
                                                        <td style={{ lineHeight: '12px' }}>
                                                            <span style={{ width: '300px', borderBottom: '1px dashed black', display: 'inline-block' }}>
                                                                &nbsp;{formatDateTime(bloodReqPrintData.receivedDate)}</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div className="tblsample mt-2 mb-0 pt-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div className='tblsamplebx' style={{ lineHeight: '15px' }}><strong>Sample / Req. form received. No.</strong> <span style={{ width: '90px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div>
                                                <div className='tblsamplebx' style={{ border: '1px solid' }}>Adequate / Non Adequate</div>
                                                <div className='tblsamplebx' style={{ border: '1px solid' }}>Haemolysed / Non Haemolysed</div>

                                            </div>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th className='mt-2 pt-0 mb-0 ' colSpan={8} style={{ border: 'none' }}>
                                        <div className='tblstxt mb-0 mt-2 pt-0  ms-4'>
                                            PATIENT BLOOD GROUPING & ICT
                                        </div>
                                    </th>
                                </tr>


                                {/* <tr>
                                    <td colSpan={8} >
                                        <table className='table'>



                                            <tr>
                                                {bloodGroupingInfo && bloodGroupingInfo.map((list, index) => {

                                                    return (
                                                        <th style={{ border: '1px solid' }}>{list.Module}</th>

                                                    )
                                                })} */}
                                {/* <th style={{ border: '1px solid' }}>Forward Grouping</th>
                                                <th style={{ border: '1px solid' }}>ReverseGrouping</th> */}
                                {/* <th style={{ border: '1px solid' }}>Auto Control</th> */}
                                {/* </tr>

                                            <tr>
                                                {bloodGroupingInfo && bloodGroupingInfo.map((list, index) => {

                                                    return (
                                                        <td style={{ border: '1px solid' }}>{list.Remark}</td>


                                                    )
                                                })}
                                                <td rowspan="2" style={{ border: '1px solid' }}>{bloodReqPrintData.autoControl}</td>
                                            </tr>
                                            <tr>
                                                {bloodGroupingInfo && bloodGroupingInfo.map((list, index) => {

                                                    return (
                                                        <td style={{ border: '1px solid' }}>{list.Value}</td>

                                                        // <td colSpan={2} style={{ border: '1px solid' }}>Positive/ Negative</td>
                                                    )
                                                })}

                                            </tr>
                                        </table>
                                    </td>
                                </tr> */}

                                {/* <tr>
                                    <td colSpan={8}>
                                        <table className='table'>
                                            <tr>
                                                <th style={{ border: '1px solid' }}>Forward Grouping</th>
                                                 <th style={{ border: '1px solid' }}>Reverse Grouping</th>
                                                <th style={{ border: '1px solid' }}>Auto Control</th>
                                            </tr>
                                            <tr>
                                                {bloodGroupingInfo && bloodGroupingInfo.map((list, index) => {

                                                    return (
                                                         <th style={{ border: '1px solid' }}>{list.Remark}</th>

                                                    )
                                                })}
                                            </tr>

                                        </table>
                                    </td>

                                </tr> */}

                                <tr>
                                    <td colSpan={8}>
                                        <table className='table'>
                                            <tr>
                                                <th style={{ border: '1px solid' }}>Forward Grouping</th>
                                                <th style={{ border: '1px solid' }}>Reverse Grouping</th>
                                                <th style={{ border: '1px solid' }}>Auto Control</th>
                                            </tr>
                                            <tr>
                                                <th style={{ border: '1px solid' }}>
                                                    {bloodGroupingInfo && bloodGroupingInfo.map((list, index) => {
                                                        if (list.Module === 'ForwardGrouping') {
                                                            return (
                                                                <span style={{ borderRight: '1px solid #000', display: 'inline-block', width: '70px' }} key={index}>{list.Remark}{' '}</span>
                                                            );
                                                        }
                                                    })}
                                                </th>


                                                <th style={{ border: '1px solid' }}>
                                                    {bloodGroupingInfo && bloodGroupingInfo.map((list, index) => {
                                                        if (list.Module === 'ReverseGrouping') {
                                                            return (
                                                                <span style={{ borderRight: '1px solid #000', display: 'inline-block', width: '70px' }} key={index}>{list.Remark}</span>
                                                            );
                                                        }
                                                    })}
                                                </th>
                                                <th rowspan="2" style={{ border: '1px solid' }}>
                                                    {bloodReqPrintData.autoControl}
                                                </th>
                                            </tr>

                                            <tr>
                                                <td style={{ border: '1px solid' }}>
                                                    {bloodGroupingInfo && bloodGroupingInfo.map((list, index) => {
                                                        if (list.Module === 'ForwardGrouping') {
                                                            return (
                                                                <span style={{ borderRight: '1px solid #000', display: 'inline-block', width: '70px' }} key={index}>{list.Value}{' '}</span>
                                                            );
                                                        }
                                                    })}
                                                </td>
                                                <td style={{ border: '1px solid' }}>
                                                    {bloodGroupingInfo && bloodGroupingInfo.map((list, index) => {
                                                        if (list.Module === 'ReverseGrouping') {
                                                            return (
                                                                <span style={{ borderRight: '1px solid #000', display: 'inline-block', width: '70px' }} key={index}>{list.Value}</span>
                                                            );
                                                        }
                                                    })}
                                                </td>

                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={8} className='ps-0 pe-0'>
                                        <table>
                                            <tr>
                                                <td colSpan={8} className='ps-0 pe-0'>
                                                    <div className="tblsample mt-1 pt-1 mb-1 pb-1" >
                                                        <div className='tblsamplebx'><strong> Patient's Blood Group :</strong> ABO <span style={{ width: '210px', borderBottom: '1px dashed black', display: 'inline-block' }}>&nbsp;{bloodReqPrintData.patientInfo[0].bloodGroupName}</span></div>
                                                        <div className='tblsamplebx'><strong>Rh (D) :</strong> <span style={{ width: '180px', borderBottom: '1px dashed black', display: 'inline-block' }}>&nbsp;{bloodReqPrintData.patientInfo[0].bloodGroupName}</span></div>
                                                    </div>
                                                    <div className="tblsample mt-1 pt-1 mb-1 pb-1" >
                                                        <div className='tblsamplebx'> <strong>Patient's ICT :</strong>Positive/Negative <span style={{ width: '210px', borderBottom: '1px dashed black', display: 'inline-block' }}>&nbsp;{bloodReqPrintData.autoControl}</span></div>
                                                    </div>
                                                    <div className="tblsample mt-1 pt-1 mb-2 pb-2" >
                                                        <div className='tblsamplebx'><strong>COMPATIBILITY DETAILS : </strong></div>
                                                        <div className='tblsamplebx' style={{ border: '1px solid' }}>Column agglutination Technique/Tube Technique, IS/AHG</div>
                                                        <div className='tblsamplebx' style={{ border: '1px solid' }}>IS/AHG</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>


                                {/* <tr>
                                    <td>
                                        <table>
                                            <tr>
                                                <td style={{ border: '1px solid' }}>Unit No./ Donor Name</td>
                                                <td style={{ border: '1px solid' }}>Blood Component Name  </td>
                                                <td style={{ border: '1px solid' }}>Comp. Exp.  Date</td>
                                                <td style={{ border: '1px solid' }}>Bag Segment No.</td>
                                                <td style={{ border: '1px solid' }}>Comp. Volume(ml)</td>
                                                <td style={{ border: '1px solid' }}>ABO Rh (D)</td>
                                                <td style={{ border: '1px solid' }}>Cross Match Compatible Yes/No</td>
                                                <td style={{ border: '1px solid' }}>Cross Match done Time </td>

                                            </tr>
                                        </table>
                                    </td>
                                </tr> */}

                                <tr>
                                    <td className='pt-0 mt-0 mb-0 ps-0 pe-0' colSpan={8}>
                                        <table className='tblbdr'>
                                            <thead>
                                                <tr>
                                                    <th style={{ border: '1px solid' }}>Unit No./ Donor Name: </th>
                                                    <th style={{ border: '1px solid' }}>Blood Component Name  </th>
                                                    <th style={{ border: '1px solid' }}>Comp. Exp.  Date </th>
                                                    <th style={{ border: '1px solid' }}>Bag Segment No.  </th>
                                                    <th style={{ border: '1px solid' }}>Comp. Volume(ml) </th>
                                                    <th style={{ border: '1px solid' }}>ABO Rh (D) </th>
                                                    <th style={{ border: '1px solid' }}>Cross Match Compatible Yes/No  </th>
                                                    <th style={{ border: '1px solid' }}>Cross Match done Time  </th>
                                                </tr>
                                            </thead>

                                            <tbody>


                                                {donorInfo && donorInfo.map((list, index) => {

                                                    return (


                                                        <tr>

                                                            <td style={{ border: '1px solid' }}>{list.donorName}</td>
                                                            <td style={{ border: '1px solid' }}>{list.productName}</td>
                                                            <td style={{ border: '1px solid' }}>{list.expiryDate}</td>
                                                            <td style={{ border: '1px solid' }}>{list.segmentNo}</td>
                                                            <td style={{ border: '1px solid' }}>{list.bloodQuantity}</td>
                                                            <td style={{ border: '1px solid' }}>{list.bloodGroup}</td>
                                                            <td style={{ border: '1px solid' }}>{list.isCrossMatch}</td>
                                                            <td style={{ border: '1px solid' }}>{list.crossMatchTime}</td>


                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='technician mt-4 pt-4 mb-2 pb-2'> Technician Signature</div>
                        </td>
                    </tr>
                </table>
            </div>
        </>
    )
}
