



async function PostDiagnosisData (requiredUHID, DiagnosticJSON) {

    let url = window.AppbaseUrl + `/api/PatientIPDPrescription/InsertDiagnosis?uhID=${requiredUHID}&jsonDaignosis=${DiagnosticJSON}&userId=${window.userId}&clientID=${ window.clientId}`
    let head = {
      'Content-Type': 'application/JSON',
      accept: '*/*',
    }
    let response =
      await fetch(url, {
        method: 'POST',
        headers: head,
        body: JSON.stringify("")
      })
        .then((res) => res.json())
        // .then(res)
  
  
    return response;
  }
  export default PostDiagnosisData;
  