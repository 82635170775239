async function GetWardDepartmentAssign(id) {
    // let url = window.AppbaseUrl+"/api/WardDepartmentAssign/GetWardbyDepartment";
    let url = window.AppbaseUrl+"/api/WardDepartmentAssign/GetAllWardDepartmentAssign?clientId="+id;
    let head = { "Content-Type": "application/JSON", accept : '*/*' };
    let data ={}
    let response = fetch(url, {
    headers: head,
    method : 'GET'
  }).then((res) => res.json()).then(data);

  return response;
}
export default GetWardDepartmentAssign;
