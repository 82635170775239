import React, { useEffect, useState } from 'react'
import RBCGIF from "../assests/images/RBCGIF.gif"
import loaderGif from "../assests/images/LoaderGif.gif"
import textGif from '../assests/images/backtext.gif'
import ventilatorGif from "../assests/images/ventiGif.gif"
import kidneyGif from "../assests/images/kidney.gif"
import heartGif from "../assests/images/heart.gif"
import lungsGif from "../assests/images/lungs1.png"
import bloodcount from "../assests/images/bloodcount.png"
import liver from "../assests/images/liver.png"
import instine from "../assests/images/instine.png"

export default function BottomVitalSection(props) {
    let [investigationList, setInvestigationList] = useState([])
    useEffect(() => {
        let temp = []
        let testIdarr = [1430]
        props.patientInvestigationData && props.patientInvestigationData.map((val, ind) => {
            if (val.subTestID)
                temp.push({
                    value: val.subTestValue,
                    name: val.subTestName
                })
        })
        setInvestigationList(temp)
    }, [props])
    return (
        <>
            <div className='vital-container'>

                <div className='data-wrap'>
                    <div className='bg-cotainer'>
                        <div className='d-flex flex-column'>
                            <span className='data-h'>Heart Investigations</span>
                            <div className='d-flex flex-row gap-1'>
                                <div className='leftpic'><img src={heartGif} alt='' /></div>
                                <div className='datashow'>
                                    <div className='datashowdetails'>
                                        <span className='wordwrap'>Blood Urea</span>
                                        <span className='labvalue' style={{ color: "#FBFF2C" }}>155.3</span>
                                    </div>
                                    <div className='datashowdetails'>
                                        <span className='wordwrap'>Blood Urea Nitrogen</span>
                                        <span className='labvalue' style={{ color: "#03D40C" }}>155.3</span>
                                    </div>
                                    <div className='datashowdetails'>
                                        <span className='wordwrap'>Serum Creatinine</span>
                                        <span className='labvalue' style={{ color: "#FF7C32" }}>155.3</span>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <img src={ventilatorGif} className='bgdetails' alt="" />
                </div>

                <div className='data-wrap'>
                    <div className='bg-cotainer'>
                        <div className='d-flex flex-column'>
                            <span className='data-h'>Lungs Investigations</span>
                            <div className='d-flex flex-row gap-1'>
                                <div className='leftpic'><img src={lungsGif} alt='' /></div>
                                <div className='datashow'>
                                    <div className='datashowdetails'>
                                        <span className='wordwrap'>Blood Urea</span>
                                        <span className='labvalue' style={{ color: "#FBFF2C" }}>155.3</span>
                                    </div>
                                    <div className='datashowdetails'>
                                        <span className='wordwrap'>Blood Urea Nitrogen</span>
                                        <span className='labvalue' style={{ color: "#03D40C" }}>155.3</span>
                                    </div>
                                    <div className='datashowdetails'>
                                        <span className='wordwrap'>Serum Creatinine</span>
                                        <span className='labvalue' style={{ color: "#FF7C32" }}>155.3</span>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <img src={ventilatorGif} className='bgdetails' alt="" />
                </div>

                <div className='data-wrap'>
                    <div className='bg-cotainer'>
                        <div className='d-flex flex-column'>
                            <span className='data-h'>Liver Investigations</span>
                            <div className='d-flex flex-row gap-1'>
                                <div className='leftpic'><img src={liver} alt='' /></div>
                                <div className='datashow'>
                                    <div className='pending'>
                                        <span>RESULTS ARE PENDING</span>
                                        <img src={loaderGif} alt="loader" className='loaderGIF' />
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <img src={ventilatorGif} className='bgdetails' alt="" />
                </div>

                <div className='data-wrap'>
                    <div className='bg-cotainer'>
                        <div className='d-flex flex-column'>
                            <span className='data-h'>Kidney & Electrolyte Profile</span>
                            <div className='d-flex flex-row gap-1'>
                                <div className='leftpic'><img src={kidneyGif} alt='' /></div>
                                <div className='datashow'>
                                    <div className='datashowdetails'>
                                        <span className='wordwrap'>Blood Urea</span>
                                        <span className='labvalue' style={{ color: "#FBFF2C" }}>155.3</span>
                                    </div>
                                    <div className='datashowdetails'>
                                        <span className='wordwrap'>Blood Urea Nitrogen</span>
                                        <span className='labvalue' style={{ color: "#03D40C" }}>155.3</span>
                                    </div>
                                    <div className='datashowdetails'>
                                        <span className='wordwrap'>Serum Creatinine</span>
                                        <span className='labvalue' style={{ color: "#FF7C32" }}>155.3</span>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <img src={ventilatorGif} className='bgdetails' alt="" />
                </div>

                <div className='data-wrap'>
                    <div className='bg-cotainer'>
                        <div className='d-flex flex-column'>
                            <span className='data-h'>Intestine Investigation</span>
                            <div className='d-flex flex-row gap-1'>
                                <div className='leftpic'><img src={instine} alt='' /></div>
                                <div className='datashow'>
                                    <div className='pending'>
                                        <span>RESULTS ARE PENDING</span>
                                        <img src={loaderGif} alt="loader" className='loaderGIF' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={ventilatorGif} className='bgdetails' alt="" />
                </div>


                <div className='data-wrap'>
                    <div className='bg-cotainer'>
                        <div className='d-flex flex-column'>
                            <span className='data-h'>Blood Counts and Anemia</span>
                            <div className='d-flex flex-row gap-1'>
                                <div className='leftpic'><img src={bloodcount} alt='' /></div>
                                <div className='datashow'>
                                    <div className='datashowdetails'>
                                        <span className='wordwrap'>Hemoglobin</span>
                                        <span className='labvalue' style={{ color: "#FBFF2C" }}>11.3</span>
                                    </div>
                                    <div className='datashowdetails'>
                                        <span className='wordwrap'>MCV</span>
                                        <span className='labvalue' style={{ color: "#03D40C" }}>10.3</span>
                                    </div>
                                    <div className='datashowdetails'>
                                        <span className='wordwrap'>Haematocrit</span>
                                        <span className='labvalue' style={{ color: "#FF7C32" }}>15.3</span>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <img src={ventilatorGif} className='bgdetails' alt="" />
                </div>





            </div>
        </>
    )
}
