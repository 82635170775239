import React, { useEffect, useState, useRef } from 'react'
import TosterUnderProcess from '../../Component/TosterUnderProcess';
import Toster from '../../Component/Toster';
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import clearIcon from '../../assets/images/icons/clear.svg';
import GetPatientDetailsByUHID from '../../../src/Clinical/API/IPD/personalDashboardIndexSecond/GetPatientDetailsByUHID'
import GetProblemList from '../../../src/Clinical/API/OPD/Prescription/KnowMedsAPI/GetProblemList'
import SaveIPDData from '../../Code/SaveIPDData'
import DownKey, { UpKey } from "../../Code/ListSelect";
import Search, { FindByQuery, SearchIndex } from '../../Code/Serach'
import OPDDiagnosisSuggestion from '../../Clinical/Pages/OPD/OPDSharePage/OPDPrescription/OPDDiagnosisSuggestion'
import ProvisionalDiagonisys from "../../assets/images/OPD/provisionalDiagonisys.svg"
import GetDiagnosis from "../../BloodBank/Api/BloodDonorRegestration/GetBlood/GetDiagnosis"
import Select from 'react-select';
import GetIndicationTransfusion from "../../BloodBank/Api/BloodDonorRegestration/GetBlood/GetIndicationTransfusion"
import GetRoutine from "../../BloodBank/Api/BloodDonorRegestration/GetBlood/GetRoutine"
import GetRequirement from "../../BloodBank/Api/BloodDonorRegestration/GetBlood/GetRequirement"
import DropdownWithSearch from '../../../src/Component/DropdownWithSearch';
import GetUserList from '../../../src/Admin/Api/Schedule/GET/GetUserList'
import GetForwardGroupList from "../../BloodBank/Api/BloodDonorRegestration/GetBlood/GetForwardGroupList"
import GetReverseGroupList from "../../BloodBank/Api/BloodDonorRegestration/GetBlood/GetReverseGroupList"
import GetAutoControlList from "../../BloodBank/Api/BloodDonorRegestration/GetBlood/GetAutoControlList"
import GetAllDonorList from '../../BloodBank/Api/BloodDonorRegestration/GetBlood/GetAllDonorList'
import DiagnosisBloodBank from '../../BloodBank/Pages/DiagnosisBloodBank';
// import PostDiagnosisData from '../../BloodBank/Api/BloodDonorRegestration/PostBlood/PostDiagnosisData'
import PostBloodRequest from '../../BloodBank/Api/BloodDonorRegestration/PostBlood/PostBloodRequest/PostBloodRequest'
import SuccessToster from '../../../src/Component/SuccessToster'
import AlertToster from '../../../src/Component/AlertToster'
import IconWard from "../../assets/images/icons/IconWard.svg";
import IconRestraints from "../../assets/images/icons/IconRestraints.svg";
import UserIcon from "../../assets/images/icons/UserIcon.svg";
import IconMRNo from "../../assets/images/icons/IconMRNo.svg";
import IconAge from "../../assets/images/icons/IconAge.svg";
import IconGender from "../../assets/images/icons/IconGender.svg";
import IconDob from "../../assets/images/icons/IconDob.svg";
import IconDateTime from "../../assets/images/icons/IconDateTime.svg";
import department from '../../BloodBank/images/department.svg'
import addressIcon from '../../BloodBank/images/address.svg'
import product from '../../BloodBank/images/product.svg'
import Remark from '../../BloodBank/images/Remark.svg'
import GetRequestForm from '../../BloodBank/Api/BloodDonorRegestration/GetBlood/GetRequestForm';
import imgPrint from "../../assets/images/icons/imgPrint.svg";
import PatientDeatils from '../../Component/PatientDeatils';
import ProvisonalDiagnosisMainComponent from './ProvisonalDiagnosisMainComponent';


export default function RequestFormForFreshWholeBlood() {

  const [showUnderProcess, setShowUnderProcess] = useState(0);
  const [showToster, setShowToster] = useState(0);
  const [tosterMessage, setTosterMessage] = useState("");
  const [tosterValue, setTosterValue] = useState(0);
  const [inputValues, setInputValues] = useState('');
  let [patientName, setPatientName] = useState('');
  let [uhid, setUhId] = useState('');
  let [showAlertToster, setShowAlertToster] = useState(0)
  let [showMessage, setShowMeassage] = useState("")
  const [selectedItems, setSelectedItems] = useState([]);
  let [showSuccessToster, setShowSuccessToster] = useState(0)
  const [selectedDiagnosis, setselectedDiagnosis] = useState(null);
  const [selectedTransfusion, setselectedTransfusion] = useState(null);
  const [selectedRoutine, setselectedRoutine] = useState(null);
  const [selectedUser, setselectedUser] = useState(null);
  const [selectedUserRequ, setselectedUserRequ] = useState(null);
  const [selectedForwGrop, setselectedForwGrop] = useState(null);
  const [selectedRevGrop, setselectedRevGrop] = useState(null);
  const [requirementData, setRequirementData] = useState([]);
  const [routineData, setRoutineData] = useState([]);
  const [forwGroupData, setForwGroupData] = useState([]);
  const [revGroupData, setRevGroupData] = useState([]);
  const [selectedControl, setselectedControl] = useState(null);
  const [controlData, setControlData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [userRequData, setUserRequData] = useState([]);
  const [donorData, setDonorData] = useState([]);
  const [diagnosisInfo, setDiagnosisInfo] = useState([]);
  const [transfusionData, setTransfusionData] = useState([]);
  const [typeModule, setTypeModule] = useState("IndicationTransfusion");
  const [typeModuleRoutine, setTypeModuleRoutine] = useState("TransfusionType");
  const [typeModuleForwGrp, setTypeModuleForwGrp] = useState("ForwardGrouping");
  const [typeModuleRevGrp, setTypeModuleRevGrp] = useState("ReverseGrouping");
  const [typeModuleControl, setTypeModuleControl] = useState("AutoControl");
  const [isClearable,] = useState(true);
  const [isSearchable,] = useState(true);
  const [rows, setRows] = useState([{ id: 1 }]);
  const [rowsGrp, setRowsGrp] = useState([{ id: 1 }]);
  const [rowsGrpRev, setRowsGrpRev] = useState([{ id: 1 }]);
  const [rowsDonor, setRowsDonor] = useState([{ id: 1 }]);
  let [clearDropdown, setClearDropdown] = useState(0)
  const [isUrgent, setIsUrgent] = useState(0);
  const [tranfusionDate, setTransfusionDate] = useState('');
  let [autoControl, setAutoControl] = useState('');
  const loginData = JSON.parse(window.sessionStorage.getItem("LoginData")) || {};
  const initialClientData = loginData.clientdata || {};
  const initialClientName = initialClientData.clientName || '';
  const [getRequestData, setGetRequestData] = useState([]);
  const [bloodGrouping, setBloodGrouping] = useState([]);
  const [productInfo, setProductInfo] = useState([]);
  const [donorInfo, setDonorInfo] = useState([]);
  let [saveDiagnosis, setSaveDiagnosis] = useState([]);
  const clientData = JSON.parse(window.sessionStorage.getItem("LoginData")) || {};
  const clientName = clientData.clientdata?.clientName || '';
  const clientAddress = clientData.clientdata?.address || '';
  let userId = JSON.parse(sessionStorage.getItem("LoginData")).userId
  let clientID = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
  // let UHID = document.getElementById("uhId").value

  // const handleDiagnosticJSON = (consultantData, row) => {
  //   setDiagnosticJSON(consultantData, row);

  // };

  let requiredUHID = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].uhId;


  const getPatientDetails = async () => {

    try {
      let pmIdresponse = await GetPatientDetailsByUHID(requiredUHID);

      console.clear();
      console.log('yog',pmIdresponse);
      let data = await GetRequestForm(requiredUHID, clientID);
      console.log("data", data)
      if (data.status === 1) {
        const requestFormdata = data.responseValue.map(item => {
          return {
            ...item,
            bloodGrouping: item.bloodGrouping ? JSON.parse(item.bloodGrouping) : [],
            productInfo: item.productInfo ? JSON.parse(item.productInfo) : [],
            donorDetails: item.donorDetails ? JSON.parse(item.donorDetails) : [],
            diagnosis: item.diagnosis ? JSON.parse(item.diagnosis) : [],


          };
        });
        setGetRequestData(requestFormdata)
        let allBloodGrouping = [];
        let allProductInfo = [];
        let allDonorDetails = [];
        let allDiagnosis = [];

        data.responseValue.forEach(item => {
          allBloodGrouping.push(item.bloodGrouping);
          allProductInfo.push(item.productInfo);
          allDonorDetails.push(item.donorDetails);
          allDiagnosis.push(item.diagnosis);

        });


      }

      // if (uhid.trim() !== '' && pmIdresponse.status === 1) {
      //   setInputValues(inputValues => ({
      //     ...inputValues,

      //     patientName: pmIdresponse.responseValue[0].patientName,
      //     age: pmIdresponse.responseValue[0].age,
      //     gender: pmIdresponse.responseValue[0].gender,
      //     mrdNo: pmIdresponse.responseValue[0].ipNo,
      //     doa: pmIdresponse.responseValue[0].admitDate,
      //     fatherHusName: pmIdresponse.responseValue[0].guardianName,
      //     ward: pmIdresponse.responseValue[0].wardName,
      //     doctor: pmIdresponse.responseValue[0].doctorName,
      //     bed: pmIdresponse.responseValue[0].bedName,
      //     hosName: clientName,
      //     hosAddress: clientAddress,
      //     bloodGroupName: pmIdresponse.responseValue[0].bloodGroupName,
      //     bloodGroupID: pmIdresponse.responseValue[0].bloodGroupId,
      //     department: pmIdresponse.responseValue[0].deptId,


      //   }));
      //   setPatientName(patientName)

      // }
      if (pmIdresponse.status == 1) {
        setInputValues(inputValues => ({
          ...inputValues,

          patientName: pmIdresponse.responseValue[0].patientName,
          age: pmIdresponse.responseValue[0].age,
          gender: pmIdresponse.responseValue[0].gender,
          mrdNo: pmIdresponse.responseValue[0].ipNo,
          doa: pmIdresponse.responseValue[0].admitDate,
          fatherHusName: pmIdresponse.responseValue[0].guardianName,
          ward: pmIdresponse.responseValue[0].wardName,
          doctor: pmIdresponse.responseValue[0].doctorName,
          bed: pmIdresponse.responseValue[0].bedName,
          hosName: clientName,
          hosAddress: clientAddress,
          bloodGroupName: pmIdresponse.responseValue[0].bloodGroupName,
          bloodGroupID: pmIdresponse.responseValue[0].bloodGroupId,
          department: pmIdresponse.responseValue[0].deptId,
        }));
      }

    } catch (e) {
      setShowAlertToster(1)
      setShowMeassage('An unexpected error occurred.')
    }
  };



  let getdata = async () => {


    let responseTransfusion = await GetIndicationTransfusion(typeModule)
    let responseRoutine = await GetRoutine(typeModuleRoutine)
    let responseRequirement = await GetRequirement()
    let responseUser = await GetUserList(clientID)
    let responseForwGroup = await GetForwardGroupList(typeModuleForwGrp)
    let responseRevGroup = await GetReverseGroupList(typeModuleRevGrp)
    let responseControl = await GetAutoControlList(typeModuleControl)
    let responseDonor = await GetAllDonorList()


    if (responseTransfusion.status === 1) {
      setTransfusionData(responseTransfusion.responseValue)


    }

    if (responseRoutine.status === 1) {
      setRoutineData(responseRoutine.responseValue)


    }

    if (responseUser.status === 1) {

      setUserData(responseUser.responseValue)



    }

    if (responseUser.status === 1) {
      setUserRequData(responseUser.responseValue)

    }

    if (responseForwGroup.status === 1) {
      setForwGroupData(responseForwGroup.responseValue)

    }

    if (responseRevGroup.status === 1) {
      setRevGroupData(responseRevGroup.responseValue)

    }

    if (responseControl.status === 1) {
      setControlData(responseControl.responseValue)

    }

    if (responseRequirement.status === 1) {
      setRequirementData(responseRequirement.responseValue)

    }
    if (responseDonor.status === 1) {
      setDonorData(responseDonor.responseValue)

    }


  }



  const handlePrint = (data) => {
    console.log("data", data)
    const ptName = (data.patientInfo)
    const bloodGrp = (data.bloodGrouping)
    const diagnoInfo = (data.diagnosis)
    console.log("diagnoInfo", diagnoInfo)
    const donorDetails = (data.donorDetails)
    const indicationTransfusionName = data.indicationTransfusionName
    const patientUHID = data.patientUHID
    const plateletCount = data.plateletCount
    const productInfo = (data.productInfo)
    const pt = data.pt
    const reaction = data.reaction
    const receivedByName = data.receivedByName
    const receivedDate = data.receivedDate
    const sampleDrawnByName = data.sampleDrawnByName
    const transfusionTypeName = data.transfusionTypeName
    const hb = data.hb
    const autoControl = data.autoControl
    const isPregnant = data.isPregnant


    window.sessionStorage.setItem("PrintBloodRequestData", JSON.stringify({
      "uhId": patientUHID, "patientInfo": ptName, "bloodGrouping": bloodGrp, "donorDetails": donorDetails, "indicationTransfusionName": indicationTransfusionName,
      "plateletCount": plateletCount, "productInfo": productInfo, "pt": pt, "reaction": reaction, "receivedByName": receivedByName, "receivedDate": receivedDate,
      "sampleDrawnByName": sampleDrawnByName, "transfusionTypeName": transfusionTypeName, "hb": hb, "autoControl": autoControl, "isPregnant": isPregnant, "diagnoInfo": diagnoInfo

    }));
    window.open("/ReceiptRequestionFormWholeBloodPrint/", 'noopener,noreferrer');
  }




  const handleInputChange = (event, rowId, i) => {

    const { name, value } = event.target;
    const updatedValue = name === 'uhId' ? value.toUpperCase() : value;
    setInputValues(inputValues => ({
      ...inputValues,
      [name]: updatedValue,
    }));

    const updatedRows = [...rows];
    const updatedRowsGrp = [...rowsGrp];
    const updatedRowsGrpRev = [...rowsGrpRev];
    const updatedRowsDonor = [...rowsDonor];
    const rowIndex = updatedRows.findIndex(row => row.id === rowId);
    const rowIndexGrp = updatedRowsGrp.findIndex(row => row.id === rowId);
    const rowIndexGrpRev = updatedRowsGrpRev.findIndex(row => row.id === rowId);
    const rowIndexDonor = updatedRowsDonor.findIndex(row => row.id === rowId);
    if (name === "ProductID") {

      updatedRows[rowIndex][name] = value;
    }



    if (name === 'statusId') {

      updatedRowsGrp[rowIndexGrp][name] = value;
    }

    if (name === 'statusIdRev') {

      updatedRowsGrpRev[rowIndexGrpRev][name] = value;
    }

    if (name === 'donorNameId') {

      updatedRowsDonor[rowIndexDonor][name] = value;
    }

    if (name === 'productId') {

      updatedRowsDonor[rowIndexDonor][name] = value;
    }
    console.log("urgent", isUrgent)

    const newValue = event.target.checked ? 0 : 1; // If checkbox is checked, set to 1, else set to 0
    setIsUrgent(newValue); // Update state


    console.log("updatedRows", updatedRows)

    // if (name === 'uhId') {
    //   setUhId(updatedValue)



    //   if (updatedValue.trim() === '') {

    //     resetForm();
    //   } else {
    //     getPatientDetails(updatedValue);
    //   }

    //   window.sessionStorage.setItem("jasonDiagnosisData", JSON.stringify({
    //     "uhId": updatedValue,

    //   }));

    // }



    if (name === "tranfusionDate") {
      setTransfusionDate(value)
    }

    if (name === "autoControl") {
      setAutoControl(value)
    }

  }



  const addRowBloodRequest = () => {

    const newRow = {
      id: rows.length + 1,
      name: `Row ${rows.length + 1}`,
    }
    const newRowId = rows.length + 1; // Generate sequential IDs

    setRows([...rows, newRow]);


    console.log("rows", rows)


  };

  const removeRowBloodRequest = (id) => {

    // Ensure that the first row is never removed

    if (id !== 1) {

      setRows(rows.filter(row => row.id !== id));

    }

  };

  const addRowBloodGrouping = () => {
    const newRow = {
      id: rowsGrp.length + 1,
      name: `Row ${rowsGrp.length + 1}`,
    }
    const newRowId = rowsGrp.length + 1; // Generate sequential IDs

    setRowsGrp([...rowsGrp, newRow]);


  };

  const removeRowBloodGrouping = (id) => {

    // Ensure that the first row is never removed

    if (id !== 1) {

      setRowsGrp(rowsGrp.filter(row => row.id !== id));

    }

  };

  const addRowBloodGroupingRev = () => {
    const newRow = {
      id: rowsGrpRev.length + 1,
      name: `Row ${rowsGrpRev.length + 1}`,
    }
    const newRowId = rowsGrpRev.length + 1; // Generate sequential IDs

    setRowsGrpRev([...rowsGrpRev, newRow]);


  };

  const removeRowBloodGroupingRev = (id) => {

    // Ensure that the first row is never removed

    if (id !== 1) {

      setRowsGrpRev(rowsGrpRev.filter(row => row.id !== id));

    }

  };

  const addRowBloodDonor = () => {
    const newRow = {
      id: rowsDonor.length + 1,
      name: `Row ${rowsDonor.length + 1}`,
    }
    const newRowId = rowsDonor.length + 1; // Generate sequential IDs

    setRowsDonor([...rowsDonor, newRow]);


  };

  const removeRowBloodDonor = (id) => {

    // Ensure that the first row is never removed

    if (id !== 1) {

      setRowsDonor(rowsDonor.filter(row => row.id !== id));

    }

  };


  const handleAddData = async () => {
    // const bloodRequestData = rows.map((row, index) => {

    //   return {
    //     ProductID: rows[index].ProductID,
    //     requestedQuantity: document.getElementById(`requestedQuantity_${row.id}`).value,



    //   }

    // });

    const bloodRequestData = rows.map((row, index) => {
      const productId = rows[index].ProductID;
      const requestedQuantity = document.getElementById(`requestedQuantity_${row.id}`).value;

      // Perform validation
      if (!productId || !requestedQuantity) {
        // Show error message or handle the validation failure as needed
        setShowAlertToster(1);
        setShowMeassage('Blood Requirement fields can not be balnk');
        // Return null or an appropriate value to indicate validation failure
        return null;
      }

      return {
        ProductID: productId,
        requestedQuantity: requestedQuantity,
      };
    });
    console.log("bloodRequestData", bloodRequestData)






    // const bloodGroupingICTData = rowsGrp.map((row, index) => {

    //   return {
    //     statusId: rowsGrp[index].statusId,
    //     value: document.getElementById(`value_${row.id}`).value,


    //   }

    // });

    const bloodGroupingICTData = rowsGrp.map((row, index) => {
      const statusId = rowsGrp[index].statusId;
      const value = document.getElementById(`value_${row.id}`).value;

      // Perform validation
      if (!statusId || !value) {
        setShowAlertToster(1);
        setShowMeassage('Forward Grouping fields  can not be blank');
        return null;
      }

      return {
        statusId: statusId,
        value: value,
      };
    });
    console.log("bloodGroupingICTData", bloodGroupingICTData)



    // const bloodGroupingICTDataRev = rowsGrpRev.map((row, index) => {

    //   return {
    //     statusId: rowsGrpRev[index].statusIdRev,
    //     value: document.getElementById(`valueRev_${row.id}`).value,


    //   }

    // });

    const bloodGroupingICTDataRev = rowsGrpRev.map((row, index) => {
      const statusId = rowsGrpRev[index].statusIdRev;
      const value = document.getElementById(`valueRev_${row.id}`).value;

      // Perform validation
      if (!statusId || !value) {
        setShowAlertToster(1);
        setShowMeassage('Reverse Grouping fields  can not be blank');
        return null;
      }

      return {
        statusId: statusId,
        value: value,
      };
    });
    console.log("bloodGroupingICTDataRev", bloodGroupingICTDataRev)

    // const bloodDonorData = rowsDonor.map((row, index) => {

    //   return {
    //     registrationID: rowsDonor[index].donorNameId,
    //     productId: rowsDonor[index].productId,
    //     segmentNo: document.getElementById(`segmentNo_${row.id}`).value,
    //     quantity: document.getElementById(`quantity_${row.id}`).value,
    //     isCrossMatch: document.getElementById(`isCrossMatch_${row.id}`).value,
    //     crossMatchTime: document.getElementById(`crossMatchTime_${row.id}`).value,


    //   }

    // });

    const bloodDonorData = rowsDonor.map((row, index) => {
      const registrationID = rowsDonor[index].donorNameId;
      const productId = rowsDonor[index].productId;
      const segmentNo = document.getElementById(`segmentNo_${row.id}`).value;
      const quantity = document.getElementById(`quantity_${row.id}`).value;
      const isCrossMatch = document.getElementById(`isCrossMatch_${row.id}`).value;
      const crossMatchTime = document.getElementById(`crossMatchTime_${row.id}`).value;

      // Perform validation
      if (!registrationID || !productId || !segmentNo || !quantity || !isCrossMatch || !crossMatchTime) {
        setShowAlertToster(1);
        setShowMeassage('Blood Donor fields  can not be blank');
        return null;
      }

      return {
        registrationID: registrationID,
        productId: productId,
        segmentNo: segmentNo,
        quantity: quantity,
        isCrossMatch: isCrossMatch,
        crossMatchTime: crossMatchTime,
      };
    });
    console.log("bloodDonorData", bloodDonorData)

    const mergedData = [...bloodGroupingICTData, ...bloodGroupingICTDataRev];
    const bloodRequisitionData = {
      patientUHID: requiredUHID,
      age: inputValues.age,
      gender: inputValues.gender,
      department: inputValues.department,
      ward: inputValues.ward,
      tranfusionDate: tranfusionDate,
      hospitalName: inputValues.hosName,
      hospitalAddress: inputValues.hosAddress,
      patientName: inputValues.patientName,
      isUrgent: isUrgent,
      indicationTransfusionId: inputValues.indicationTransfusionId,
      transfusionTypeId: inputValues.transfusionTypeId,
      bloodGroupID: inputValues.bloodGroupID,
      reaction: document.getElementById("reaction").value,
      isPregnant: document.getElementById("isPregnant").value,
      hb: document.getElementById("hb").value,
      plateletCount: document.getElementById("plateletCount").value,
      receivedDate: document.getElementById("receivedDate").value,
      pt: document.getElementById("pt").value,
      userId: JSON.parse(sessionStorage.getItem("LoginData")).userId,
      jsonbloodrequest: JSON.stringify(bloodRequestData),
      jsonbloodGroupingICT: JSON.stringify(mergedData),
      jsonDonor: JSON.stringify(bloodDonorData),
      sampleDrawnByUserId: inputValues.sampleDrawnByUserId,
      receivedById: inputValues.receivedById,
      autoControl: inputValues.autoControl,
      clientId: clientID







    };
    console.log("bloodRequisitionData", bloodRequisitionData)





    // const jsonDiagnosis = JSON.stringify(DiagnosticJSON)

    // console.log("jsonDiagnosis", jsonDiagnosis)

    // let response = await PostDiagnosisData(inputValues.uhId, userId, jsonDiagnosis, clientID);
    // console.log("response", response)

    setSaveDiagnosis(1)
    let responseBloodRequstn = await PostBloodRequest(bloodRequisitionData);

    if (responseBloodRequstn.status === 1) {
      setShowMeassage("Data Saved Successfully!!")
      setShowSuccessToster(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
      getPatientDetails()
      resetForm(1);

    }
    else {
      // setShowMeassage(responseBloodRequstn.responseValue)
      // setShowAlertToster(0)
      // setTimeout(() => {
      //   setShowToster(0)
      // }, 2000)
    }



  }



  const resetForm = (value) => {
    setInputValues({
      uhId: '',
      patientName: '',
      age: '',
      gender: '',
      mrdNo: '',
      hosName: '',
      ward: '',
      doctor: '',
      doa: '',
      bed: '',
      hosAddress: '',
      bloodGroupName: '',
      isPregnant: '',
      plateletCount: '',
      pt: '',
      fatherHusName:''
    });


    setUhId('')
    setRows([{ id: 1 }]);
    setRowsDonor([{ id: 1 }]);
    setRowsGrp([{ id: 1 }]);
    setRowsGrpRev([{ id: 1 }]);

    setClearDropdown(value)
    setGetRequestData([])
    document.getElementById('indicationTransfusionId').value = 0;
    document.getElementById('transfusionTypeId').value = 0;
    document.getElementById('sampleDrawnByUserId').value = 0;
    document.getElementById('receivedById').value = 0;
    document.getElementById('receivedDate').value = '';
    document.getElementById('receivedDate').value = '';
    document.getElementById('tranfusionDate').value = '';
    document.getElementById('reaction').value = '';
    document.getElementById('isPregnant').value = '';
    document.getElementById('pt').value = '';
    document.getElementById('plateletCount').value = '';
    document.getElementById('hb').value = '';
    document.getElementById('autoControl').value = 0;
    var element = document.getElementsByName('requestedQuantity')[0];
    if (element) {
      element.value = '';
    }
    var elementVal = document.getElementsByName('value')[0];
    if (elementVal) {
      elementVal.value = '';
    }
    var elementValRev = document.getElementsByName('valueRev')[0];
    if (elementValRev) {
      elementValRev.value = '';
    }
    var elementSeg = document.getElementsByName('segmentNo')[0];
    if (elementSeg) {
      elementSeg.value = '';
    }
    var elementQty = document.getElementsByName('quantity')[0];
    if (elementQty) {
      elementQty.value = '';
    }
    var elementCrossMatch = document.getElementsByName('isCrossMatch')[0];
    if (elementCrossMatch) {
      elementCrossMatch.value = '';
    }
    var elementcrossMatchTime = document.getElementsByName('crossMatchTime')[0];
    if (elementcrossMatchTime) {
      elementcrossMatchTime.value = '';
    }

  }


  useEffect(() => {
    getPatientDetails()
    getdata()
    setIsUrgent(0)


  }, [])

  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12"><div className="med-box mb-1"><div className="title">Department of Transfussion Medicine(Blood Center)</div></div>
              <PatientDeatils />
            </div>

            <div className="col-12">
              <div className="med-box">

                <div className="inner-content">
                  <div className='fieldsett-in col-md-12'>
                    <div className='fieldsett'>
                      <span className='fieldse'>Requistion Form for Whole Blood / Components</span>

                      <div className='row'>

                        {/* <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <img src={IconMRNo} className="icnn" alt="" />
                          <label htmlFor="uhId" className="form-label">UHID<span className="starMandatory"></span></label>
                          <input id="uhId" type="text" className="form-control form-control-sm" value={inputValues.uhId} placeholder='Enter UHID' name="uhId" onChange={handleInputChange} />
                        </div> */}


                        {/* <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <img src={UserIcon} className="icnn" alt="" />
                          <label htmlFor="patientName" className="form-label">Patient Name<span className="starMandatory"></span></label>
                          <input id="patientName" type="text" className="form-control form-control-sm" value={inputValues.patientName} disabled placeholder='' name="patientName" />
                        </div> */}



                        {/* <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" id="paymentModediv">
                          <img src={IconAge} className="icnn" alt="" />
                          <label htmlFor="age" className="form-label">Age<span className="starMandatory"></span></label>
                          <input id="age" type="number" className="form-control form-control-sm" value={inputValues.age} disabled placeholder="" name="age" />
                        </div> */}

                        {/* <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3" id="paymentModediv">
                          <img src={IconGender} className="icnn" alt="" />
                          <label htmlFor="gender" className="form-label">Gender<span className="starMandatory"></span></label>
                          <input id="gender" type="text" className="form-control form-control-sm" value={inputValues.gender} disabled placeholder="" name="gender" />
                        </div> */}





                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <img src={UserIcon} className="icnn" alt="" />
                          <label htmlFor="fatherHusName" className="form-label">Father's / Husband Name<span className="starMandatory"></span></label>
                          <input id="fatherHusName" type="text" className="form-control form-control-sm" value={inputValues.fatherHusName} placeholder='' disabled name="fatherHusName" />
                        </div>

                        {/* <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <img src={department} className='icnn' alt='' />
                          <label htmlFor="mrdNo" className="form-label">Department<span className="starMandatory">*</span></label>
                          <input id="department" type="text" className="form-control form-control-sm" value={inputValues.department} readOnly placeholder='' name="department" />
                        </div> */}

                        {/* <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <img src={IconWard} className="icnn" alt="" />
                          <label htmlFor="ward" className="form-label">Ward<span className="starMandatory"></span></label>
                          <input id="ward" type="text" className="form-control form-control-sm" value={inputValues.ward} placeholder='' name="ward" disabled />
                        </div> */}

                        {/* <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <img src={IconWard} className="icnn" alt="" />
                          <label htmlFor="ward" className="form-label">Bed<span className="starMandatory"></span></label>
                          <input id="bed" type="text" className="form-control form-control-sm" value={inputValues.bed} placeholder='' name="bed" disabled />
                        </div> */}


                        {/* <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <img src={IconRestraints} className="icnn" alt="" />
                          <label htmlFor="doctor" className="form-label">Doctor InCharge<span className="starMandatory"></span></label>
                          <input id="doctor" type="text" className="form-control form-control-sm" value={inputValues.doctor} placeholder='' name="doctor " disabled />
                        </div> */}

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <img src={IconWard} className="icnn" alt="" />
                          <label htmlFor="Code" className="form-label">Hospital Name<span className="starMandatory"></span></label>
                          <input id="hosName" type="text" className="form-control form-control-sm" value={inputValues.hosName} placeholder='' name="hosName" disabled />
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <img src={addressIcon} className='icnn' alt='' />
                          <label htmlFor="Code" className="form-label">Hospital Address<span className="starMandatory"></span></label>
                          <input id="hosAddress" type="text" className="form-control form-control-sm" value={inputValues.hosAddress} placeholder='' name="hosAddress" disabled />
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <img src={product} className='icnn' alt='' />
                          <label htmlFor="Code" className="form-label">Patient Blood Group<span className="starMandatory"></span></label>
                          <input id="bloodGroupName" type="text" className="form-control form-control-sm" value={inputValues.bloodGroupName} disabled placeholder='Enter Patient Blood Group' name="bloodGroupName " />
                        </div>
                        
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <img src={IconRestraints} className="icnn" alt="" />
                          <label htmlFor="diagnosisTrans" className="form-label">Indication of Transfusion<span className="starMandatory"></span></label>
                          <select id='indicationTransfusionId' name='indicationTransfusionId' className="form-select form-select-sm" onChange={handleInputChange}>
                            <option value="0" selected>Select Transfusion</option>
                            {transfusionData && transfusionData.map((val, ind) => {
                              return (
                                <option value={val.id}>{val.remark}</option>
                              )
                            }
                            )}
                          </select>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <img src={IconRestraints} className="icnn" alt="" />
                          <label htmlFor="diagnosisTrans" className="form-label">Indication Type Transfusion<span className="starMandatory"></span></label>
                          <select id='transfusionTypeId' name='transfusionTypeId' className="form-select form-select-sm" onChange={handleInputChange}>
                            <option value="0" selected>Select Transfusion</option>
                            {routineData && routineData.map((val, ind) => {
                              return (
                                <option value={val.id}>{val.remark}</option>
                              )
                            }
                            )}
                          </select>
                        </div>




                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <img src={IconDateTime} className="icnn" alt="" />
                          <label htmlFor="doa" className="form-label">Date of Transfusion<span className="starMandatory"></span></label>
                          <input id="tranfusionDate" type="date" className="form-control form-control-sm" name="tranfusionDate" onChange={handleInputChange} />
                        </div>



                        


                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <img src={Remark} className='icnn' alt='' />
                          <label htmlFor="reaction" className="form-label">Reacton if any<span className="starMandatory"></span></label>
                          <input id="reaction" type="text" className="form-control form-control-sm" placeholder='Enter Reacton if any' name="reaction" onChange={handleInputChange} />
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <img src={Remark} className='icnn' alt='' />
                          <label htmlFor="Code" className="form-label">Pregnancy Status<span className="starMandatory"></span></label>
                          <select id="isPregnant" className="form-control form-control-sm">
                            <option value="" selected>Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <img src={Remark} className='icnn' alt='' />
                          <label htmlFor="hb" className="form-label">Hb %<span className="starMandatory"></span></label>
                          <input id="hb" type="number" className="form-control form-control-sm" placeholder='Enter' name="hb " />
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <img src={Remark} className='icnn' alt='' />
                          <label htmlFor="plateletCount" className="form-label">Platlets Count<span className="starMandatory"></span></label>
                          <input id="plateletCount" type="number" className="form-control form-control-sm" placeholder='Enter Platlets Count' name="plateletCount" />
                        </div>



                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <img src={Remark} className='icnn' alt='' />
                          <label htmlFor="pt" className="form-label">PT<span className="starMandatory"></span></label>
                          <input id="pt" type="number" className="form-control form-control-sm" placeholder='Enter' name="pt" />
                        </div>



                        <div className="risk-factors-check regularCheck">
                          <input type="checkbox" name='isUrgent' className="me-2 custom-checkbox form-check-input" checked={isUrgent === 0} // Set checked attribute based on state
                            onChange={handleInputChange} />Is Urgent
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>


            <div className="col-12 mt-1">
              <div className="med-box">

                <div className="inner-content">
                  <div className='fieldsett-in col-md-12'>
                    <div className='fieldsett'>
                      <span className='fieldse'>Clinical Diagnosis with History</span>
                      <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                          <label htmlFor="ddlitemmaster" className="form-label"><span className="starMandatory"></span></label>
                          <ProvisonalDiagnosisMainComponent isProvisional={0} saveDiagnosis={saveDiagnosis} setSaveDiagnosis={setSaveDiagnosis} />
                        </div>



                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>

          <div className="col-12 mt-2"><div className="med-box mb-1"><div className="title">Blood Requirement</div></div></div>
          <div className='row mt-2'>
            <div className='col-12'>
              <div className='med-box'>
                <div className='med-table-section' style={{ paddingBottom: '15px' }}>
                  <table className='med-table border_ striped billingTable'>
                    <thead>
                      <tr>
                        <th className='text-center'>#</th>
                        <th>Requirement</th>
                        <th>No. Of Units</th>

                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((row, index) => {
                        return (
                          <tr key={row.id}>
                            <td className='text-center'>{index + 1}</td>

                            <td>
                              <div className="drpWithSearch">
                                {requirementData && <DropdownWithSearch defaulNname="Select Product" id={`ProductID_${row.id}`} name='ProductID'
                                  getvalue={(event) => handleInputChange(event, row.id)} list={requirementData} valueName="id" displayName="productName"
                                  editdata={""} clear={clearDropdown} clearFun={resetForm} style={{ width: '250px' }} />}
                              </div>

                            </td>

                            <td><input type='number' className="form-control form-control-sm" id={`requestedQuantity_${row.id}`} name='requestedQuantity' placeholder='Enter Units' /></td>



                            <td>
                              <div className='action-button'><i className="bi bi-plus" onClick={addRowBloodRequest}></i><i className="bi bi-trash3" onClick={() => removeRowBloodRequest(row.id)}></i></div>
                            </td>

                          </tr>

                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>



          {/* <div className="col-12 ">
            <div className="med-box mt-2">
              <div className="inner-content">
                <div className='fieldsett-in col-md-12'>
                  <div className='fieldsett'>
                    <span className='fieldse'>Sample Drawn/Receive By</span>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <div className="row">

                          <div className="col-md-6 mb-3">
                            <label htmlFor="diagnosis" className="form-label">Sample Drawn By<span className="starMandatory"></span></label>
                            <select id='sampleDrawnByUserId' name='sampleDrawnByUserId' className="form-select form-select-sm" onChange={handleInputChange}>
                              <option value="0" selected>Select User</option>
                              {userData && userData.map((val, ind) => {
                                return (
                                  <option value={val.id}>{val.name}</option>
                                )
                              }
                              )}
                            </select>
                          </div>
                         
                          <div className="col-md-6 mb-3">
                            <label htmlFor="diagnosis" className="form-label">Requisition Form Received By<span className="starMandatory"></span></label>
                            <select id='receivedById' name='receivedById' className="form-select form-select-sm" onChange={handleInputChange}>
                              <option value="0" selected>Select User</option>
                              {userData && userData.map((val, ind) => {
                                return (
                                  <option value={val.id}>{val.name}</option>
                                )
                              }
                              )}
                            </select>
                          </div>

                          <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                            <label htmlFor="doa" className="form-label">Received Date<span className="starMandatory">*</span></label>
                            <input id="receivedDate" type="date" className="form-control form-control-sm" name="receivedDate" onChange={handleInputChange} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="col-12 ">
            <div className="med-box mt-2">
              <div className="inner-content">
                <div className='fieldsett-in col-md-12'>
                  <div className='fieldsett'>
                    <span className='fieldse'>Sample Drawn/Receive By</span>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <div className="row">
                          <div className="col-md-4 mb-3">
                            <img src={UserIcon} className="icnn" alt="" />
                            <label htmlFor="diagnosis" className="form-label">Sample Drawn By<span className="starMandatory"></span></label>
                            <select id='sampleDrawnByUserId' name='sampleDrawnByUserId' className="form-select form-select-sm" onChange={handleInputChange}>
                              <option value="0" selected>Select User</option>
                              {userData && userData.map((val, ind) => {
                                return (
                                  <option value={val.id}>{val.name}</option>
                                )
                              }
                              )}
                            </select>
                          </div>
                          <div className="col-md-4 mb-3">
                            <img src={UserIcon} className="icnn" alt="" />
                            <label htmlFor="diagnosis" className="form-label">Requisition Form Received By<span className="starMandatory"></span></label>
                            <select id='receivedById' name='receivedById' className="form-select form-select-sm" onChange={handleInputChange}>
                              <option value="0" selected>Select User</option>
                              {userRequData && userRequData.map((val, ind) => {
                                return (
                                  <option value={val.id}>{val.name}</option>
                                )
                              }
                              )}
                            </select>
                          </div>
                          <div className="col-md-4 mb-3">
                            <img src={IconDateTime} className="icnn" alt="" />
                            <label htmlFor="doa" className="form-label">Received Date<span className="starMandatory"></span></label>
                            <input id="receivedDate" type="datetime-local" className="form-control form-control-sm" name="receivedDate" onChange={handleInputChange} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>





          <div className="col-12 mt-2"><div className="med-box mb-1"><div className="title">Patient Blood Grouping & ICT</div></div></div>

          <div className='row'>
            <div className='col-12'>
              <div className='med-box'>
                <div className='med-table-section' style={{ paddingBottom: '15px' }}>
                  <table className='med-table border_ striped billingTable'>
                    <thead>
                      <tr>
                        <th className='text-center'>#</th>
                        <th>Forward Grouping Status Name</th>
                        <th>Value</th>

                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rowsGrp.map((row, index) => {
                        return (
                          <tr key={row.id}>
                            <td className='text-center'>{index + 1}</td>

                            <td>
                              <div className="drpWithSearch">
                                {forwGroupData && <DropdownWithSearch defaulNname="Select Status" id={`statusId_${row.id}`} name='statusId'
                                  getvalue={(event) => handleInputChange(event, row.id)} list={forwGroupData} valueName="id" displayName="remark"
                                  editdata={""} clear={clearDropdown} clearFun={resetForm} style={{ width: '250px' }} />}
                              </div>

                            </td>

                            <td><input type='number' className="form-control form-control-sm" id={`value_${row.id}`} name='value' placeholder='Enter Value' /></td>



                            <td>
                              <div className='action-button'><i className="bi bi-plus" onClick={addRowBloodGrouping}></i><i className="bi bi-trash3" onClick={() => removeRowBloodGrouping(row.id)}></i></div>
                            </td>

                          </tr>

                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-1'>
            <div className='col-12'>
              <div className='med-box'>
                <div className='med-table-section' style={{ paddingBottom: '15px' }}>
                  <table className='med-table border_ striped billingTable'>
                    <thead>
                      <tr>
                        <th className='text-center'>#</th>
                        <th>Reverse Grouping Status Name</th>
                        <th>Value</th>

                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rowsGrpRev.map((row, index) => {
                        return (
                          <tr key={row.id}>
                            <td className='text-center'>{index + 1}</td>

                            <td>
                              <div className="drpWithSearch">
                                {revGroupData && <DropdownWithSearch defaulNname="Select Status" id={`statusIdRev_${row.id}`} name='statusIdRev'
                                  getvalue={(event) => handleInputChange(event, row.id)} list={revGroupData} valueName="id" displayName="remark"
                                  editdata={""} clear={clearDropdown} clearFun={resetForm} style={{ width: '250px' }} />}
                              </div>

                            </td>

                            <td><input type='number' className="form-control form-control-sm" id={`valueRev_${row.id}`} name='valueRev' placeholder='Enter Value' /></td>



                            <td>
                              <div className='action-button'><i className="bi bi-plus" onClick={addRowBloodGroupingRev}></i><i className="bi bi-trash3" onClick={() => removeRowBloodGroupingRev(row.id)}></i></div>
                            </td>

                          </tr>

                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="med-box mt-1">
              <div className="inner-content">
                <div className='fieldsett-in col-md-12'>
                  <div className='fieldsett'>

                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <div className="row">


                          <div className="col-md-4 mb-3">
                            <label htmlFor="autoControl" className="form-label">Auto Control<span className="starMandatory"></span></label>
                            <select id='autoControl' name='autoControl' className="form-select form-select-sm" onChange={handleInputChange}>
                              <option value="0" selected>Select Control</option>
                              {controlData && controlData.map((val, ind) => {
                                return (
                                  <option value={val.id}>{val.remark}</option>
                                )
                              }
                              )}
                            </select>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-2"><div className="med-box mb-1"><div className="title">Blood Donor List</div></div></div>
          <div className='row mt-2'>
            <div className='col-12'>
              <div className='med-box'>
                <div className='med-table-section' style={{ paddingBottom: '15px'}}>
                  <table className='med-table border_ striped billingTable'>
                    <thead>
                      <tr>
                        <th className='text-center'>#</th>
                        <th>Donor Name</th>
                        <th>Blood Component Name</th>
                        {/* <th>Comp. Exp. Date</th> */}
                        <th>Bag Segment Name</th>
                        <th>Comp. Volume (ml)</th>
                        {/* <th>ABO Rh (D)</th> */}
                        <th>Cross Match Compatible</th>
                        <th>Time</th>
                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rowsDonor.map((row, index) => {
                        return (
                          <tr key={row.id}>
                            <td className='text-center'>{index + 1}</td>

                            <td>
                              <div className="drpWithSearch">
                                {donorData && <DropdownWithSearch defaulNname="Select Donor" id={`donorNameId_${row.id}`} name='donorNameId'
                                  getvalue={(event) => handleInputChange(event, row.id)} list={donorData} valueName="id" displayName="donorName"
                                  editdata={""} clear={clearDropdown} clearFun={resetForm} style={{ width: '250px' }} />}
                              </div>

                            </td>

                            <td>
                              <div className="drpWithSearch">
                                {requirementData && <DropdownWithSearch defaulNname="Select Blood Component" id={`productId_${row.id}`} name='productId'
                                  getvalue={(event) => handleInputChange(event, row.id)} list={requirementData} valueName="id" displayName="productName"
                                  editdata={""} clear={clearDropdown} clearFun={resetForm} style={{ width: '250px' }} />}
                              </div>

                            </td>



                            {/* <td><input type='text' className="form-control form-control-sm" id={`compDate_${row.id}`} name='compDate' placeholder='Enter Units' /></td> */}

                            <td><input type='number' className="form-control form-control-sm" id={`segmentNo_${row.id}`} name='segmentNo' placeholder='Enter Units' /></td>

                            <td><input type='number' className="form-control form-control-sm" id={`quantity_${row.id}`} name='quantity' placeholder='Enter Units' /></td>

                            <td><select id={`isCrossMatch_${row.id}`} name='isCrossMatch' className="form-control form-control-sm">
                              <option value="" selected>Select</option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select></td>

                            <td><input type='datetime-local' className="form-control form-control-sm" id={`crossMatchTime_${row.id}`} name='crossMatchTime' placeholder='Enter Units' /></td>

                            <td>
                              <div className='action-button'><i className="bi bi-plus" onClick={addRowBloodDonor}></i> <i className="bi bi-trash3" onClick={() => removeRowBloodDonor(row.id)}></i></div>
                            </td>

                          </tr>

                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>



          <div className="col-12 ">
            <div className="med-box mt-2">
              <div className="inner-content">
                <div className="row">

                  <div className="inner-content text-right">
                    <div className="mb-2 mt-2 relative">
                      <div>
                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1_ me-1" onClick={handleAddData}><img src={saveButtonIcon} className="icnn" alt='' />Save</button>
                        <button type="button" className="btn btn-clear btn-sm mb-1_ me-1" onClick={() => { resetForm(1) }}><img alt='' />Clear</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <div className="med-table-section" style={{ "height": "80vh" }}>
            <table className="med-table border striped">
              <thead style={{ zIndex: '0' }}>
                <tr>
                  <th className="text-center" style={{ "width": "5%" }}>#</th>
                  <th>UHID</th>
                  <th>Patient Name</th>
                  <th>Blood Group</th>
                  <th>Indication Transfusion Name</th>
                  <th>Transfusion Type Name</th>
                  <th>Diagnosis</th>
                  <th>Blood Grouping & ICT</th>
                  <th>Product Info. </th>
                  <th>Donor Info. </th>
                  <th style={{ "width": "10%" }} className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {getRequestData && getRequestData.map((data, index) => {

                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{data.patientUHID}</td>
                      <td>{data.patientInfo[0].patientName}</td>
                      <td>{data.patientInfo[0].bloodGroupName}</td>
                      <td>{data.indicationTransfusionName}</td>
                      <td>{data.transfusionTypeName}</td>
                      {data.diagnosis && (
                        <td>{data.diagnosis.map((val, index) => (
                          <span key={index}>{val.details}
                            {index < data.diagnosis.length - 1 && <br />}
                          </span>
                        ))}
                        </td>
                      )}
                      {data.bloodGrouping && (
                        <td>{data.bloodGrouping.map((val, index) => (
                          <span key={index}>{val.Module}, {val.Remark}
                            {index < data.bloodGrouping.length - 1 && <br />}
                          </span>
                        ))}
                        </td>
                      )}

                      {data.productInfo && (
                        <td>{data.productInfo.map((val, index) => (
                          <span key={index}>{val.productName}, {val.requestedQuantity}
                            {index < data.productInfo.length - 1 && <br />}
                          </span>
                        ))}
                        </td>
                      )}

                      {data.donorDetails && (
                        <td>{data.donorDetails.map((val, index) => (
                          <span key={index}>{val.donorName}, {val.productName}, {val.donationDate}, {val.segmentNo}, {val.bloodQuantity}, {val.crossMatchTime}
                            {index < data.donorDetails.length - 1 && <br />}
                          </span>
                        ))}
                        </td>
                      )}

                      <td>

                        <div className="action-button">
                          <div data-bs-title="Delete Row" data-bs-placement="bottom"  ><img src={imgPrint} style={{ width: '20px' }} className='' alt='' onClick={() => handlePrint(data)} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>

        {
          showAlertToster === 1 ? <AlertToster message={showMessage} handle={setShowAlertToster} /> : ""
        }
        {
          showSuccessToster === 1 ? <SuccessToster message={showMessage} handle={setShowSuccessToster} /> : ""
        }


      </section>

    </>
  )
}


