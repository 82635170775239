import React from 'react'

export default function TableContainer({ children }) {
  return (


      <table className='med-table border_ striped' >
        {children}
      </table>
  )
}
