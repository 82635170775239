import React, { useState } from 'react';




export default function FinanceNavbar(props) {
  console.log('props', props)
  const [isOpen, setIsOpen] = useState(false);

  const toggleSearchBox = () => {
    setIsOpen(!isOpen);
  }
  return (
    <>
      <div className='topheadermain' style={{ backgroundColor: "white" }}>
        <div className='row m-0'>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12'>
            <div className='heading '>{!props.menutext ? 'HRMS' : props.menutext}</div>
          </div>
          <div className='col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12'>
            <div className='headerdetailsrightt'>
              <div class={`searchbox  ${isOpen ? "d-block" : "d-none"}`}>
                <div className='searchicon ps-3'>
                  <img  alt="sideiconimagesHRM Logo" />
                </div>
                <div className={`searchinput`} >
                  <input type="text" class="form-control form-control-sm" placeholder="Search" />
                </div>
              </div>
              <div className='mx-2' onClick={toggleSearchBox}> <img  alt="sideiconimagesHRM Logo" /></div>
              <div className='mx-2'><img  alt="sideiconimagesHRM Logo" /></div>
              <div className='notificationright toggle-containernew'>
                <div className='bellnotifications contentmain'>
                  <div className='nofifict'>
                    <img className='pe-2'  alt="sideiconimagesHRM Logo" />
                    Notifications <span className='px-1'>(02)</span>
                  </div>
                </div>
                <div className='belliconn toggle-button'>
                  <img  alt="sideiconimagesHRM Logo" />
                </div>
              </div>
              <div className='headerdate mx-2 me-0'>August 8th 2022. 09:00 AM</div>
              <div className='profile me-0'>
                <div class="dropdown">
                  <img  alt="TopUserProfile " className="btn dropdown-toggle" data-bs-toggle="dropdown" style={{ border: 'none' }} />
                  <ul class="dropdown-menu mydropdownprofile">
                    <li>
                      <div className='userdp'>
                        <div><img  alt="TopUserProfile"></img></div>
                        <div className=''>
                          <div className='profilestyle'>Sabiha baig</div>
                          <div className='profiledesignation'>Website Designer</div>
                        </div>
                      </div> <hr className='mt-1 mb-1' />
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <img  alt="myprofile" />
                        <span>My Profile</span></a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <img  alt="sideiconimages" />
                        <span>Settings</span></a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <img  alt="sideiconimages" />
                        <span> Log out</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
