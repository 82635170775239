import React from 'react'
// import Diagnosis from './LeftTableData/Diagnosis/Diagnosis'
import LifeSupport from './LeftTableData/LifeSupport/LifeSupport'
import Consultant from './LeftTableData/Consultant/Consultant'
import Ward from './LeftTableData/Ward/Ward'
import PatientDeatils from './LeftTableData/PatientDeatils/PatientDeatils'
import PatientBPL from './LeftTableData/BPL/AllFieldsBPL'
// import { useState } from 'react'
import Investigation from './LeftTableData/Investigation/Investigation'
import InfusionDetail from './LeftTableData/InfusionDetail/InfusionDetail'
import NSDetails from './LeftTableData/NSDetail/NSDetails'
import PatientSPO2L from './LeftTableData/SPO2L/AllFieldSpo2L'
import AllFieldsPRL from './LeftTableData/PRL/AllFieldsPRL'
import PatientTempL from './LeftTableData/TempL/AllFieldTempL'
import PatientBPR from './RightTableData/BPR/AllFieldBPR'
import PatientSpo2R from './RightTableData/SPO2R/AllFieldSPO2R'
import AllfieldRRR from './RightTableData/RRR/AllfieldRRR'
import PatientHRR from './RightTableData/HRR/AllFieldHRR'
import PatientPRR from './RightTableData/PRR/AllFieldPRR'
import AllFieldALBR from './RightTableData/ALBR/AllFieldALBR'
import CaplusplusR from './RightTableData/CaplusplusR/CaplusplusR'
import KPlusR from './RightTableData/KplusR/KPlusR'
import NaPlusR from './RightTableData/NaplusR/NaPlusR'
import MgR from './RightTableData/MgR/MgR'
import PCO2R from './RightTableData/PCO2R/PCO2R'
import EtCO2R from './RightTableData/EtCO2R/EtCO2R'
import PO2R from './RightTableData/PO2R/PO2R'
import LactateR from './RightTableData/LactateR/LactateR'
import HCO3R from './RightTableData/HCO3R/HCO3R'
import RBSR from './RightTableData/RBSR/RBSR'
import TempR from './RightTableData/TempR/TempR'
import CreatinineR from './RightTableData/CreatinineR/CreatinineR'
import BUreaR from './RightTableData/BUreaR/BUreaR'
import IOR from './RightTableData/IOR/IOR'
import SGOTR from './RightTableData/SGOTR/SGOTR'
import SGTR from './RightTableData/SGPTR/SGTR'
import PatientPHR from './RightTableData/PHR/AllFieldPHR'
import AllMedication from './LeftTableData/Medication/AllMedication'

export default function SearchComponent(props) {

    if (props.columnId === 1) {
        return (<PatientDeatils val={props.val} columnId={props.columnId} />)
    }
    else if (props.columnId === 2) {
        return (<LifeSupport val={props.val} columnId={props.columnId} />)
    }
    else if (props.columnId === 3) {
        return (<Investigation val={props.val} columnId={props.columnId} />)
    }
    else if (props.columnId === 4) {
        return (<InfusionDetail val={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 5) {
        return (<Ward val={props.val} columnId={props.columnId} />)
    }
    else if (props.columnId === 6) {
        return (<Consultant val={props.val} columnId={props.columnId}/>)
    }
    else if (props.columnId === 7) {
        return (<NSDetails val={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 8) {
        return (<PatientBPL val={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 9) {
        return (<PatientSPO2L val={JSON.parse(props.val.patientDataList)} columnId={props.columnId}  />)
    }
    else if (props.columnId === 10) {
        return (<AllFieldsPRL val={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 11) {
        return (<PatientTempL val={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 12) {
        return (<PatientBPR val={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 13) {
        return (<PatientSpo2R val={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 14) {
        return (<AllfieldRRR val={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 15) {
        return (<PatientHRR val={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 16) {
        return (<PatientPRR val={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 17) {
        return (<AllFieldALBR patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 18) {
        return (<CaplusplusR patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 19) {
        return (<KPlusR patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 20) {
        return (<NaPlusR patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 21) {
        return (<MgR patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 22) {
        return (<PatientPHR patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 23) {
        return (<PCO2R patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 24) {
        return (<EtCO2R patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 25) {
        return (<PO2R patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 26) {
        return (<LactateR patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 27) {
        return (<HCO3R patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId}/>)
    }
    else if (props.columnId === 28) {
        return (<RBSR patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 29) {
        return (<TempR patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 30) {
        return (<CreatinineR patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 31) {
        return (<BUreaR patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 32) {
        return (<IOR patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} />)
    }
    else if (props.columnId === 33) {
        return (<SGOTR patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId}/>)
    }
    else if (props.columnId === 34) {
        return (<SGTR patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId}/>)
    }
    else if (props.columnId === 35) {
        return (<AllMedication patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId}/>)
    }



}

