
import React, { useState, useEffect } from 'react'
import GetAgendaForDdl from '../API/Agenda/GetAgendaForDdl';
import GetMeetingForDdl from '../API/Meeting/GetMeetingForDdl';
import GetParticipant from '../API/Participant/GetParticipant';
import DeleteParticipant from '../API/Participant/DeleteParticipant';
import PostParticipant from '../API/Participant/PostParticipant';
import PutParticipant from '../API/Participant/PutParticipant';
import SuccessToster from '../../Component/SuccessToster'
import WarningToaster from '../../Component/WarningToaster'
import AlertToster from '../../Component/AlertToster'
import Loder from '../../Component/Loader'
import editbtn from '../../assets/images/icons/editbtn.svg'
import delbtn from '../../assets/images/icons/delbtn.svg'
import save from "../../assets/images/icons/save.svg";
import reset from "../../assets/images/icons/reset.svg";
import Heading from '../../Component/Heading'
import DropdownWithSearch from '../../Component/DropdownWithSearch'
import { useTranslation } from 'react-i18next'
import noData from '../../assets/images/icons/No data-rafiki.svg';
import i18n from 'i18next'

export default function Participant() {

    const [getAgendaList, setAgendaList] = useState([])
    const [getMeetingList, setMeetingList] = useState([])
    const [getParticipantList, setParticipantList] = useState([])
    const [getId, setId] = useState('')
    const [getName, setName] = useState('')
    const [getRole, setRole] = useState('')
    const [getEmail, setEmail] = useState('')
    const [getOrganization, setOrganization] = useState('')
    const [getSelectedAgenda, setSelectedAgenda] = useState([''])
    const [getSelectedMeeting, setSelectedMeeting] = useState([''])
    const [getSaveUpdateBool, setSaveUpdateBool] = useState(0)
    const [clearDropdown, setClearDropdown] = useState(0)
    const [loder, setLoder] = useState(1)
    const [showToster, setShowToster] = useState(0)
    const [message, setMessage] = useState("")
    const [searchInput, setSearchInput] = useState('');
    const [editAgenda, seteditAgenda] = useState("")
    const [editMeeting, seteditMeeting] = useState("")
    const { t } = useTranslation();

    //Handle Change
    const handleChange = async (e) => {
        const {name, value} = e.target;
        if (name === "AgendaID") {
            setSelectedAgenda(value)
            await funGetMeeting(value)
        }
        else {
            setSelectedMeeting(value)
        }
    };

    const funGetParticipant = async () => {
        const {status, responseValue} = await GetParticipant()
        if (status === 1) {
            setLoder(0)
            setParticipantList(responseValue);
        }
    };

    const funGetAgenda = async () => {
        const {status, responseValue} = await GetAgendaForDdl()
        if (status) {
            setLoder(0)
            setAgendaList(responseValue);
        }
    };

    const funGetMeeting = async (agendaId) => {
        const {status, responseValue} = await GetMeetingForDdl(agendaId, window.userId)
        if (status) {
            setLoder(0)
            setMeetingList(responseValue);
        }
    };

    const handleTextboxChange = (event) => {
        if (event.target.name === "name") {
            setName(event.target.value);
        }
        if (event.target.name === "role") {
            setRole(event.target.value);
        }
        if (event.target.name === "email") {
            setEmail(event.target.value);
        }
        if (event.target.name === "organization") {
            setOrganization(event.target.value);
        }
    };

    const clearValues = (value) => {
        setSaveUpdateBool(0);
        setName('');
        setRole('');
        setEmail('');
        setOrganization('');
        setClearDropdown(value)
        seteditMeeting("");
    };

    const funSaveParticipant = async () => {
        const numbersRegex = /^[0-9]*$/;
        const specialCharsRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        const Agenda=getSelectedAgenda;
        if (Agenda === '0' || Agenda === undefined || Agenda === null || Agenda === "" || Agenda==='') {
            setMessage("Fill Agenda !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSelectedMeeting === '0' || getSelectedMeeting === undefined || getSelectedMeeting === null || getSelectedMeeting === "") {
            setMessage("Fill Meeting !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getName === '0' || getName === undefined || getName === null || getName === "") {
            setMessage("Fill Participant Name !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getRole === '0' || getRole === undefined || getRole === null || getRole === "") {
            setMessage("Fill Role !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getEmail === '0' || getEmail === undefined || getEmail === null || getEmail === "") {
            setMessage("Fill Email !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getOrganization === '0' || getOrganization === undefined || getOrganization === null || getOrganization === "") {
            setMessage("Fill Organization !")
            setShowToster(3)
            setLoder(0)
        }

        
        else {
            var obj = {
                name: getName,
                role: getRole,
                email: getEmail,
                Organization: getOrganization,
                meetingId: getSelectedMeeting,
                userID: window.userId,
                clientId: JSON.parse(window.sessionStorage.getItem('LoginData')).clientId
            }
            setLoder(1)
            let response = await PostParticipant(obj);
            if (response.status === 1) {
                setMessage("Data saved successfully !")
                setShowToster(1)
                setLoder(0)
                setSaveUpdateBool(0)
                funGetParticipant()
                clearValues(1)
            }
            else {
                setLoder(0);
                setMessage(response.responseValue)
                setShowToster(1)
            }
        }
    };

    const FuneditParticipant = (Id, name, role, email, organization, MeetingID, title) => {
        setSaveUpdateBool(1)
        setId(Id);
        setName(name);
        setRole(role);
        setEmail(email);
        setOrganization(organization);
        setSelectedMeeting(MeetingID);
        seteditMeeting(title);
    };

    const funUpdateParticipant = async () => {
        const specialCharsRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        const numbersRegex = /^[0-9]*$/;
        if (getName === '0' || getName === undefined || getName === null || getName === "") {
            setMessage("Fill Name !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getRole === '0' || getRole === undefined || getRole === null || getRole === "") {
            setMessage("Fill Role !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getEmail === '0' || getEmail === undefined || getEmail === null || getEmail === "") {
            setMessage("Fill Email !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getOrganization === '0' || getOrganization === undefined || getOrganization === null || getOrganization === "") {
            setMessage("Fill Organization !")
            setShowToster(3)
            setLoder(0)
        }
        else if (getSelectedMeeting === '0' || getSelectedMeeting === undefined || getSelectedMeeting === null || getSelectedMeeting === "") {
            setMessage("Fill Meeting !")
            setShowToster(3)
            setLoder(0)
        }
        else {
            var obj = {
                Id: getId,
                name: getName,
                role: getRole,
                email: getEmail,
                Organization: getOrganization,
                meetingId: getSelectedMeeting,
                userID: window.userId,
                clientId: JSON.parse(window.sessionStorage.getItem('LoginData')).clientId
            }
            setLoder(1)
            let result = await PutParticipant(obj);
            if (result.status === 1) {
                setLoder(0)
                setMessage("Data update successfully !")
                setShowToster(1)
                setSaveUpdateBool(0)
                funGetParticipant()
                clearValues(1)
            }
            else {
                setMessage(result.responseValue)
                setShowToster(1)
                setLoder(0)
            }
        }
    };


    const funDeletedParticipant = async () => {
        var obj = {
            Id: getId,
        }
        setLoder(1)
        const result = await DeleteParticipant(obj);
        if (result.status === 1) {
            setLoder(0)
            setMessage("Data delete successfully !")
            setShowToster(1)
            clearValues()
            //funGetMeeting()
            funGetParticipant()
        }
        else {
            setMessage(result.responseValue)
            setShowToster(1)
            setLoder(0)
        }
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        if (name === 'searchBox') {
            setSearchInput(value)
        }
    };

    function reformatDateString(s) {
        if (typeof s === 'string' && s.trim() !== '') {
            var b = s.split(/\D/);
            var day = parseInt(b[2], 10).toString();
            var month = parseInt(b[1], 10).toString();
            var year = b[0];
            return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
        } else {
            return ''; // Return an empty string or handle the error as needed
        }
    }

    useEffect(() => {
        funGetParticipant()
        funGetAgenda()
    }, []);

    return (
        <>
            <section className="main-content pt-3 mt-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="med-box">
                                <div className="title">Add Participant</div>
                                <div className="inner-content">
                                    <div className="d-flex flex-wrap align-content-end">
                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="Agenda" className="form-label">Agenda<span className="starMandatory">*</span></label>
                                            {getAgendaList && <DropdownWithSearch defaulNname={t("Select Agenda")} name="AgendaID" list={getAgendaList} valueName="agendaId" displayName="itemTitle" editdata={editAgenda} getvalue={handleChange} clear={clearDropdown} clearFun={clearValues} />}
                                        </div>
                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="meeting" className="form-label">Meeting<span className="starMandatory">*</span></label>
                                            {getMeetingList && <DropdownWithSearch defaulNname={t("Select Meeting")} name="MeetingID" list={getMeetingList} valueName="meetingId" displayName="title" editdata={editMeeting} getvalue={handleChange} clear={clearDropdown} clearFun={clearValues} />}
                                        </div>
                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="name" className="form-label">Participant Name<span className="starMandatory">*</span></label>
                                            <input type="text" className="form-control form-control-sm" value={getName} onChange={handleTextboxChange} id="txtname" name="name" placeholder='Enter Name' />
                                        </div>
                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="role" className="form-label">Role<span className="starMandatory">*</span></label>
                                            <input type="text" className="form-control form-control-sm" value={getRole} onChange={handleTextboxChange} id="txtrole" name="role" placeholder='Enter role' />
                                            {/* <input type="date" className="form-control form-control-sm" value={getStartDate} onChange={handleTextboxChangeStartDate} id="txtstartDate" name="startDate" placeholder='Enter Start Date' /> */}
                                        </div>
                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="email" className="form-label">Email<span className="starMandatory">*</span></label>
                                            <input type="text" className="form-control form-control-sm" value={getEmail} onChange={handleTextboxChange} id="txtemail" name="email" placeholder='Enter email' />
                                        </div>
                                        <div className="mb-2 flex-grow-1 me-2">
                                            <label htmlFor="organization" className="form-label">Organization<span className="starMandatory">*</span></label>
                                            <input type="text" className="form-control form-control-sm" value={getOrganization} onChange={handleTextboxChange} id="txtorganization" name="organization" placeholder='Enter organization' />
                                        </div>



                                        <div className="mb-2 relative">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                            <div>
                                                {getSaveUpdateBool === 0 ?
                                                    <>
                                                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={funSaveParticipant}><img src={save} className='icnn' />Save</button>
                                                        <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={() => { clearValues(1) }}><img src={reset} className='icnn' /> {t("Clear")}</button>
                                                    </>
                                                    :
                                                    <>
                                                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={funUpdateParticipant}><img src={save} className='icnn' />Update</button>
                                                        <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={() => { setSaveUpdateBool(0); clearValues(1) }}><img src={reset} className='icnn' /> {t("Clear")}</button>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-1">
                            <div className='handlser'>
                                <Heading text="Participant List" />
                                <div style={{ position: 'relative' }}>
                                    <input value={searchInput} onChange={handleOnChange} name="searchBox" type="search" className="form-control form-control-sm" placeholder="Search...." aria-label="Search" aria-describedby="search-addon" />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
                            <div className="med-table-section relative" style={{ "height": "77vh" }}>
                                <table className="med-table border_ striped">
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>S.No.</th>
                                            <th>Participant name</th>
                                            <th>Role</th>
                                            <th>Email</th>
                                            <th>Organization</th>
                                            <th>Meeting</th>
                                            <th style={{ "width": "10%" }} className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getParticipantList.length > 0 ?
                                            getParticipantList && getParticipantList.filter((val) => `${val.role} ${val.meetingDate}`.toLowerCase().includes(searchInput.toLowerCase())).map((val, ind) => {
                                            
                                            return (
                                                <tr key={val.id}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td>{val.name}</td>
                                                    <td>{val.role}</td>
                                                    <td>{val.email}</td>
                                                    <td>{val.organization}</td>
                                                    <td>{val.title}</td>
                                                    <td>
                                                        <div className="action-button">
                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom" title="Edit Row"><span className='btnbg' style={{ background: "#FFEDD2" }} onClick={() => { FuneditParticipant(val.id, val.name, val.role, val.email, val.organization, val.meetingId, val.title) }}> <img src={editbtn} className='' /></span></div>
                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" title="Delete Row" data-bs-target="#deleteModal"><span onClick={() => { setId(val.id) }} className='btnbg' style={{ background: "#FFEFEF" }}> <img src={delbtn} className='icnn' /></span ></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <img className='haveNodataImg' src={noData} alt="No Data" />}
                                    </tbody>
                                </table>
                                {/* -----------------------Start Delete Modal Popup-------------------   */}

                                {/*  <!-- Modal -->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">
                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'> Delete?</div>
                                                <div className='popDeleteContent'> Are you sure you want to delete?</div>
                                            </div>
                                            <div className="modal-footer1 text-center">
                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={funDeletedParticipant} data-bs-dismiss="modal">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}
                            </div>
                        </div>
                    </div>
                </div>
                {showToster === 1 ? <SuccessToster message={message} handle={setShowToster} /> : ""}
                {showToster === 2 ? <WarningToaster message={message} handle={setShowToster} /> : ""}
                {showToster === 3 ? <AlertToster message={message} handle={setShowToster} /> : ""}
            </section>
            <Loder val={loder} />
        </>
    )

}
