import React, { useState, useEffect } from 'react'
import saveButtonIcon from '../../../assets/images/icons/send.svg';
import fillform from '../../../assets/images/icons/fillform.svg'
import BoxContainer from '../../../Component/BoxContainer';
import TableContainer from '../../../Component/TableContainer';
import NoDataFound from '../../../Component/NODataFound'
import AlertToster from '../../../Component/AlertToster';
import Heading from '../../../../src/Component/Heading';
import GetGodownStockByGodownId from '../API/GetGodownStockByGodownId'
import GetAllCurrentStock from '../API/GetAllCurrentStock'
import GetKnowmedItems from '../../Purchase/API/GetKnowmedItems'
import DropdownWithSearch from '../../../Component/DropdownWithSearch';
import GetGodownMaster from '../../Purchase/API/GetGodownMaster'
import GetPharmacyMaster from '../API/GetPharmacyMaster'
import imgReset from '../../../assets/images/icons/reset.svg'
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import clearIcon from '../../../assets/images/icons/clear.svg';
import PostTransferGodownToPharmacy from '../API/PostTransferGodownToPharmacy'
import SuccessToster from '../../../../src/Component/SuccessToster'
import GetUnitMaster from '../../UnitMaster/API/GetUnitMaster';
import PostConversionMaster from '../../ConversionMaster/API/PostConversionMaster'
import TosterUnderProcess from '../../../Component/TosterUnderProcess'
import Toster from '../../../Component/Toster'
import ValidationTransferGodownToPharmacy from '../../../Validation/Pharmacy/ValidationTransferGodownToPharmacy'
import Loader from '../../../Component/Loader';

const CurrentStock = () => {


  const { t } = useTranslation();
  document.body.dir = i18n.dir();
  let [stockList, setStockList] = useState([])
  let [allGodownStockList, setAllGodownStockList] = useState([])
  let [showImage, setShowImage] = useState(0);
  let [isChecked, setIschecked] = useState();
  let [loder, setLoder] = useState(1)
  let [godownList, setGodownList] = useState([]);
  let [pharmacyList, setPharmacyList] = useState([]);
  let [unitList, setUnitList] = useState([])
  let [productList, setProductList] = useState([]);
  let [productId, setProductId] = useState();
  let [conversionUnitPrice, setConversionUnitPrice] = useState();
  let [sellingDiscount, setSellingDiscount] = useState();
  let [tempArray, setTempArray] = useState();
  let [transferQty, setTransferQty] = useState();
  let [unitId, setUnitId] = useState();
  let [baseUnit, setBaseUnit] = useState();
  let [batchNo, setBatchNo] = useState();
  let [convertedUnitId, setConvertedUnitId] = useState();
  let [godownId, setGodownId] = useState();
  let [multipliedBy, setMultipliedBy] = useState();
  let [pharmacyId, setPharmacyId] = useState();
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [itemList, setItemList] = useState()
  let [clearDropdown, setClearDropdown] = useState(0)
  let [sendJsonPharmacyTransfer, setSendJsonPharmacyTransfer] = useState([])
  const [inputValues, setInputValues] = useState({ "userId": window.userId });
  let [showMessage, setShowMeassage] = useState("")
  let [showSuccessToster, setShowSuccessToster] = useState(0)
  const [searchInput, setSearchInput] = useState('');
  let [isShowIssueModel, setIsShowIssueModel] = useState(0);
  let [rowId, setrowId] = useState('');
  let [showUnderProcess, setShowUnderProcess] = useState(0);
  let [showToster, setShowToster] = useState(0);
  let [tosterValue, setTosterValue] = useState(0);
  let [tosterMessage, setTosterMessage] = useState("");
  const [rows, setRows] = useState([]);

  const getFunction = async () => {
    setLoder(1)
    let response = await GetGodownMaster();
    let godownAllStock = await GetAllCurrentStock();
    console.log("erewr", response)
    let pharmacyresponse = await GetPharmacyMaster();
    let unitresponse = await GetUnitMaster();
    let itemresponse = await GetKnowmedItems();
    if (response.status === 1) {
      setGodownList(response.responseValue)
      console.log("godownList", godownList)
    }

    if (godownAllStock.status === 1) {
      setLoder(0)
      setAllGodownStockList(godownAllStock.responseValue)

    }
   

    if (pharmacyresponse.status === 1) {
      setPharmacyList(pharmacyresponse.responseValue)

    }

    if (unitresponse.status === 1) {
      setUnitList(unitresponse.responseValue.table)

    }


    if (itemresponse.status === 1) {
      setProductList(itemresponse.responseValue)

    }


  }




  const getGodownStockByGodownId = async (godownId) => {
    setLoder(1)
    

      let response = await GetGodownStockByGodownId(godownId);
      if (response.status === 1) {
        setLoder(0)
        setStockList(response.responseValue)
      }
      
  }


  const handleChange = (event, list) => {
    const { name, value } = event.target;
    console.log("value", value)



    if (event.target.name === "godownId") {
      setGodownId(value);
    }

    if (event.target.name === "multipliedBy") {
      setMultipliedBy(value);
    }

    if (event.target.name === "productId") {
      setProductId(value);

    }
    if (event.target.name === "conversionUnitPrice") {
      setConversionUnitPrice(value);

    }

    if (event.target.name === "transferQty") {
      setTransferQty(value);

    }

    if (event.target.name === "sellingDiscount") {
      setSellingDiscount(value);

    }

    if (event.target.name === "convertedUnitId") {
      setConvertedUnitId(value);

    }

    if (name === 'transferQty' && parseFloat(value) < 0) {
      setShowMeassage('Quantity can not be a negative number')
      setShowAlertToster(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
      return;
    }

    setInputValues(inputValues => ({
      ...inputValues,
      [name]: value,
    }));

    if (name === "godownId") {
      getGodownStockByGodownId(value);

    }
  }



  let handleCheckBox = async (ind) => {

    let tempArray = [];
   

    for (var i = 0; i < stockList.length; i++) {
      const findID = stockList[i].id;
      const isChecked = document.getElementById(findID).checked;


      console.log("isChecked", isChecked)



      if (isChecked === true) {

        const conversionUnitPrice = document.getElementById(`conversionUnitPrice${i}`).value;
        const transferQty = document.getElementById(`transferQty${i}`).value;
        const sellDisct = document.getElementById(`sellingDiscount${i}`).value;


        tempArray.push({
          productId: stockList[i].productId,
          unitId: stockList[i].unitID,
          hsnCodeId: stockList[i].hsnCodeId,
          batchNo: stockList[i].batchNo,
          batchMfg: formatDate(stockList[i].batchMfg),
          batchExpiry: formatDate(stockList[i].batchExpiry),
          unitPrice: stockList[i].baseUnitPrice,
          mrp: conversionUnitPrice,
          quantity: transferQty,
          setSellingDiscount: sellDisct,
          isknowmed: 1
        });



      }

      else if (document.getElementById(findID).checked = false) {

        setSendJsonPharmacyTransfer([])
      }

      setSendJsonPharmacyTransfer([...tempArray])
    }

    console.log("tempArray", tempArray)

  }

  function formatDate(dateString) {
  
    const originalDate = new Date(dateString);

    
    const formattedDate = originalDate.toISOString().split('T')[0] + 'T00:00:00';

    return formattedDate;
  }



  let handleAllCheckBox = async (id) => {
   
    if (id === -1) {
      let a = document.getElementById(-1).checked;

      let arr = [];
      if (a) {
        stockList
          .map((val, ind) => {
            const conversionUnitPrice = document.getElementById(`conversionUnitPrice${ind}`).value;
            const transferQty = document.getElementById(`transferQty${ind}`).value;
            const sellDisct = document.getElementById(`sellingDiscount${ind}`).value;
            document.getElementById(val.id).checked = true;

            arr.push(
              {
                productId: stockList[ind].productId,
                unitId: stockList[ind].unitID,
                hsnCodeId: stockList[ind].hsnCodeId,
                batchNo: stockList[ind].batchNo,
                batchMfg: stockList[ind].batchMfg,
                batchExpiry: stockList[ind].batchExpiry,
                unitPrice: stockList[ind].baseUnitPrice,
                mrp: conversionUnitPrice,
                quantity: transferQty,
                setSellingDiscount: sellDisct,
                isknowmed: 1
              });
           
          });
        console.log("arr", arr)

        setSendJsonPharmacyTransfer([...arr]);
      
      } else {
        stockList.map((val, ind) => {
          document.getElementById(val.id).checked = false;

        });
        document.getElementById(-1).checked = false;
        console.log("arr", [...arr])
        setSendJsonPharmacyTransfer([]);
      
        console.log("sendJsonPharmacyTransfer", [sendJsonPharmacyTransfer])
      }

    }
  }



  const getConversionDetails = (list) => {
    setIsShowIssueModel(1);
   
    setProductId(list.productId)
    setBatchNo(list.batchNo)
    setUnitId(list.unitID)
    setBaseUnit(list.baseUnit)
   
  };



  let updateConversion = async (godownId) => {
    var obj = {

      productId: productId,
      batchNo: batchNo,
      unitID: unitId,
      conversionUnitId: convertedUnitId,
      multipliedBy: multipliedBy,
      userId: JSON.parse(window.sessionStorage.getItem("LoginData")).userId,
      isknowmed: 1
    }

    let data = await PostConversionMaster(obj);
    if (data.status === 1) {
      setShowUnderProcess(0);
      getGodownStockByGodownId(godownId)
      setTosterValue(0);
      setShowToster(1);
      setTosterMessage('Updated Successfully!');
      setTimeout(() => {
        setShowToster(0);
        setConvertedUnitId(convertedUnitId)
      }, 2000);

    }
    else {
      setShowUnderProcess(0);

      setShowToster(1);
      setTosterMessage(data.responseValue);
      setTosterValue(1);
      setTimeout(() => {
        setShowToster(0);
      }, 2000)
    }
  }

  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);


  let handleSave = async () => {
    console.log("sendJsonPharmacyTransfer", sendJsonPharmacyTransfer)
    try {

      let valresponse = ValidationTransferGodownToPharmacy(inputValues.godownId, inputValues.pharmacyId)

      if (valresponse[0]) {
        console.log("isAnyCheckboxChecked", isAnyCheckboxChecked)
        if (!isAnyCheckboxChecked) {
          setShowMeassage("select any item")
          setShowAlertToster(1)
          setTimeout(() => {
            setShowToster(0)
          }, 2000)
          return;
        }

        const returnData = {

          godownId: inputValues.godownId,
          pharmacyId: inputValues.pharmacyId,
          JsonPurchaseTransfer: JSON.stringify(sendJsonPharmacyTransfer),
          userId: window.userId,

        };

        let response = await PostTransferGodownToPharmacy(returnData);
        if (response.status === 1) {
          setShowMeassage("Data Saved Successfully!!")
          setShowSuccessToster(1)
          setTimeout(() => {
            setShowToster(0)
          }, 2000)
          setIsAnyCheckboxChecked()
          setSendJsonPharmacyTransfer([])
          resetForm(1);
        }
        else {
          setShowMeassage(response.responseValue)
          setShowAlertToster(1)
          setTimeout(() => {
            setShowToster(0)
          }, 2000)
        }




      }
      else {

        setShowToster(1)
        setShowMeassage(valresponse[1])
        setShowAlertToster(1)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
      }


    }
    catch (e) {
      setShowAlertToster(1)
      setShowMeassage()
    }

  }



  const resetForm = (value) => {
    setGodownId("");
    setInputValues({
      // batchNo: '',
    })
    setClearDropdown(value)
    // getAllStockDetails(0);
    setStockList()
    setSendJsonPharmacyTransfer([])
    setIsAnyCheckboxChecked()
    getFunction()
  }


  useEffect(() => {

    getFunction();

  }, [])


  return (
    <>

      <section className='main-content mt-5 pt-3'>
        <div className='container-fluid'>
         
          <div className='row'>
            <div className="col-12">
              <div className='whitebg' style={{ margin: "0 0 10px 0" }}>
                <div className="row">
                  <div className="col-md-12 col-sm-12 analuze" >
                    <div className="fieldsett-in">
                      <div className="fieldsett">
                        <span className='fieldse'>{t("Godown Stock Transfer")}</span>
                        <BoxContainer>


                          {godownList &&
                            <div className="drpWithSearch me-2 mb-2" style={{ maxWidth: '200px' }}>
                              <DropdownWithSearch defaulNname="Select Godown" id={godownId} name='godownId'
                                getvalue={handleChange} list={godownList} valueName="id" displayName="godown"
                                editdata={""} clear={clearDropdown} clearFun={resetForm} />
                            </div>
                          }

                          {pharmacyList &&
                            <div className="drpWithSearch me-2 mb-2" style={{ maxWidth: '200px' }}>
                              <DropdownWithSearch defaulNname="Select Pharmacy" id={pharmacyId} name='pharmacyId'
                                getvalue={handleChange} list={pharmacyList} valueName="id" displayName="pharmacy"
                                editdata={""} clear={clearDropdown} clearFun={resetForm} />
                            </div>
                          }

                          {/* <div className='searchbtnn d-flex mb-2'>
                            <button type="button" className="btn btn-save btn-sm" onClick={() => { getGodownStockByProductId(productId, godownId) }}><i className='fa fa-search'></i>{t("Search_Result")}</button>
                          </div> */}

                          {showUnderProcess === 1 ? <TosterUnderProcess /> :
                            <>
                              {showToster === 1 ?
                                <Toster value={tosterValue} message={tosterMessage} />

                                :
                                <div>
                                  <button type="button" className="btn btn-clear btn-sm" onClick={() => { resetForm(1) }}><img src={clearIcon} className='icnn' />{t("Clear")}</button>
                                </div>
                              }
                            </>
                          }

                        </BoxContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='handlser'>
                <Heading text='Godown Current Stock List' />
                <div style={{ position: 'relative' }}>
                  <input type="text" name="searchBox" className='form-control form-control-sm' placeholder={t("Search")} value={searchInput} />
                  <span className="tblsericon"><i className="fas fa-search"></i></span>
                </div>
              </div>
            </div>
            <div className="col-12 mt-2">
              <div className="med-table-section" style={{ "height": "auto", position: 'relative' }}>


                {godownId ? (
                  stockList && stockList.length > 0 ? (
                    <table className='med-table border striped'>
                      <thead>
                        <tr>
                          <th className="" >{t("S.No.")}</th>
                          <th className='text-center'>{t("Product_Name")}</th>
                          <th className='text-center'>{t("Batch_No")}.</th>
                          
                          <th className='text-center'>{t("In Stock")}</th>
                         
                          <th className='text-center'>{t("Converted Unit")}</th>
                          <th className='text-center'>{t("Expiry Date")}</th>
                          <th className='text-center'>{t("Expiry")}</th>
                          <th className='text-center'>{t("Price/Unit ")}</th>
                          <th className='text-center'>{t("Selling Price")}</th>
                         
                          <th className='text-center'>{t("Transfer Quantity")}</th>
                          <th className='text-center'>{t("Selling Discount")}</th>

                         
                          <th><li className="d-flex flex-row ps-1 gap-2">
                            <input type="checkbox" id={-1} onChange={() => { handleAllCheckBox(-1); }} />
                            <span>Select All</span>
                          </li></th>

                        </tr>
                      </thead>
                      <tbody>
                        {stockList && stockList.map((list, index) => {

                          return (

                            <>

                              <tr>
                                <td className='' style={{ paddingLeft: '7px', fontSize: '13px' }}>{index + 1}</td>
                                <td className='text-center'><span style={{ color: '#7696F1', fontSize: '13px' }}>{list.brandName}</span></td>
                                <td className='text-center'><span style={{ fontSize: '13px', color: '#929292' }}>{list.batchNo}</span></td>
                                
                                <td className='text-center'><span style={{ color: '#858585', fontSize: '13px' }}>{list.stockStatus}</span></td>
                                

                              
                                <td className='text-center' data-bs-title="Edit Row" data-bs-placement="bottom" onClick={() => { setIsShowIssueModel(1); getConversionDetails(list) }} > {list.conversionUnit ? (
                                  <span style={{ color: '#858585', fontSize: '13px' }}>{list.conversionUnit}</span>
                                ) : (
                                  <img src={fillform} alt='' title='edit form' />
                                )}</td>
                               
                                <td className='text-center'><span style={{ color: '#858585', fontSize: '13px' }}>{list.batchExpiry} </span></td>
                                <td className='text-center'><span style={{ color: '#858585', fontSize: '13px' }}>{list.daysLeftToExpiry} Days</span></td>
                                <td className='text-center'><span style={{ color: '#858585', fontSize: '13px' }}>{list.baseUnitPrice}/{list.baseUnit}</span></td>
                              

                                <td className='text-center'>
                                  <input
                                    type='number'
                                    id={`conversionUnitPrice${index}`}
                                    name='conversionUnitPrice'
                                    placeholder="Enter Conversion Price"
                                    onChange={handleChange}
                                  /> / {list.conversionUnit}
                                </td>

                                <td className='text-center'>
                                  <input
                                    type='number'
                                    id={`transferQty${index}`}
                                    name='transferQty'
                                    placeholder="Enter Transfer Quantity"
                                    onChange={handleChange}
                                  />
                                </td>

                                <td className='text-center'>
                                  <input
                                    type='number'
                                    id={`sellingDiscount${index}`}
                                    name='sellingDiscount'
                                    placeholder="Enter Selling Discount"
                                    onChange={handleChange}
                                  />
                                </td>

                                <td style={{ textAlign: 'center' }}><input type="checkbox" id={list.id} name="isChecked"

                                  onChange={() => { handleCheckBox(index); setIsAnyCheckboxChecked(true); }} />
                                </td>

                              </tr>
                            </>
                          )
                        })}
                      </tbody>
                    </table>

                  ) : (
                    <NoDataFound />
                  )
                  
                ) : (
                 
                  allGodownStockList && allGodownStockList.length > 0 ? (
                    < table className='med-table border striped'>
                      <thead>
                        <tr>
                          <th className="" >{t("S.No.")}</th>
                          <th className='text-center'>{t("Godown Name")}</th>
                          <th className='text-center'>{t("Product Name")}</th>
                          <th className='text-center'>{t("Batch_No")}.</th>
                          {/* <th className='text-center'>{t("Batch_No")}.</th> */}
                          <th className='text-center'>{t("Unit Name")}</th>

                          <th className='text-center'>{t("In Stock")}</th>

                          <th className='text-center'>{t("Expiry")}</th>
                          <th className='text-center'>{t("Expiry Days ")}</th>





                        </tr>
                      </thead>
                      <tbody>
                        {allGodownStockList && allGodownStockList.map((list, index) => {

                          return (

                            <>

                              <tr>
                                <td className='' style={{ paddingLeft: '7px', fontSize: '13px' }}>{index + 1}</td>
                                <td className='text-center'><span style={{ color: '#7696F1', fontSize: '13px' }}>{list.godown}</span></td>
                                <td className='text-center'><span style={{ fontSize: '13px', color: '#929292' }}>{list.brandName}</span></td>

                                <td className='text-center'><span style={{ color: '#858585', fontSize: '13px' }}>{list.batchNo}</span></td>
                                <td className='text-center'><span style={{ color: '#858585', fontSize: '13px' }}>{list.baseUnit}</span></td>

                                <td className='text-center'><span style={{ color: '#858585', fontSize: '13px' }}>{list.stockStatus}</span></td>


                                <td className='text-center'><span style={{ color: '#858585', fontSize: '13px' }}>{list.batchExpiry} </span></td>
                                <td className='text-center'><span style={{ color: '#858585', fontSize: '13px' }}>{list.daysLeftToExpiry} Days</span></td>






                              </tr>
                            </>
                          )
                        })}
                      </tbody>
                    </table>
                  ) : (

                    <NoDataFound />
                  )
                )}

              </div>
            </div>
          </div>
          <div className='col-md-12 mt-4'>

            <div className='med-table-section text-right'>

              <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1_ me-1" onClick={handleSave}><img src={saveButtonIcon} className='icnn' />Transfer</button>
              <button type="button" className="btn btn-clear btn-sm mb-1_ me-1" onClick={() => { resetForm(1) }}><img src={imgReset} />Reset</button>
            </div>
            {
              showAlertToster === 1 ? <AlertToster message={showMessage} handle={setShowAlertToster} /> : ""
            }
            {
              showSuccessToster === 1 ? <SuccessToster message={showMessage} handle={setShowSuccessToster} /> : ""
            }
          </div>

          {isShowIssueModel === 1 ?
            <div className={`modal d-${isShowIssueModel === 1 ? "block" : ""}`} id="EditModal" data-bs-backdrop="static">

              <div className="modal-dialog modal-lg">

                <div className="modal-content p-0">

                  <div className="modal-header">

                    <h1 className="modal-title fs-5 text-white" id="exampleModalLabel">Unit Conversion </h1>

                    <button type="button" className="btn-close_ btnModalClose" title="Close Window" onClick={() => { setIsShowIssueModel(0) }}>

                      <i className="bi bi-x-octagon"></i>

                    </button>

                  </div>

                  <div className="modal-body p-0">

                    <div className="row">

                      <div className="col-12">

                        <div className="med-box"> <div className="inner-content">

                          <div className="row">
                         
                            <div className="col-md-4 mb-2">

                              <label htmlFor="productId" className="form-label">Product Name<span className="starMandatory">*</span>

                              </label>

                              <input type="text" className="form-control form-control-sm" id="productId" value={productId} placeholder="Patient Name" name="productId" disabled />


                            </div>

                            <div className="col-md-4 mb-2">

                              <label htmlFor="batchNo" className="form-label">Batch No.<span className="starMandatory">*</span>

                              </label>

                              <input type="text" className="form-control form-control-sm" id="batchNo" value={batchNo} placeholder="Department" name="batchNo" disabled />


                            </div>
                            <div className="col-md-4 mb-2">

                              <label htmlFor="currentUnitId" className="form-label">Current Unit<span className="starMandatory">*</span>

                              </label>

                              <input type="text" className="form-control form-control-sm" id="currentUnitId" value={baseUnit} placeholder="Hospital Name" name="currentUnitId" disabled />


                            </div>

                            <div className="col-md-4 mb-2">

                              <label htmlFor="convertedUnitId" className="form-label"> Converted Unit<span className="starMandatory">*</span>

                              </label>

                              {/* <input type="text" className="form-control form-control-sm" id="convertedUnitId" placeholder="Hospital Name" name="convertedUnitId" /> */}
                              {unitList &&
                                <div className="drpWithSearch me-2 mb-2" style={{ maxWidth: '200px' }}>
                                  <DropdownWithSearch defaulNname="Select Converted Unit" id={convertedUnitId} name='convertedUnitId'
                                    getvalue={handleChange} list={unitList} valueName="id" displayName="unitName"
                                    editdata={""} clear={clearDropdown} clearFun={resetForm} />
                                </div>
                              }

                            </div>

                            <div className="col-md-4 mb-2">

                              <label htmlFor="multipliedBy" className="form-label">Multiplied By<span className="starMandatory">*</span>

                              </label>

                              <input type="number" className="form-control form-control-sm" id="multipliedBy" placeholder="Hospital Name" name="multipliedBy" onChange={handleChange} />

                            </div>

                    

                            <div className="col-md-4 mb-2">
                              <label htmlFor="exampleFormControlInput1" className="form-label"> &nbsp; </label>
                              <div>

                                {showUnderProcess === 1 ? <><TosterUnderProcess />  </> :
                                  showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} />
                                    :
                                    <div>
                                      {
                                        <>
                                          <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={() => { updateConversion(godownId) }}>Submit</button>
                                        </>
                                      }
                                    </div>
                                }



                              </div>

                            </div>

                          </div>

                        </div>

                        </div>

                      </div>

                    </div>

                  </div>



                </div>

              </div>

            </div> : ''
          }
        </div>
        {
                    loder === 1 ? <Loader val={loder} /> : ""
                }
      </section >

    </>


  )
}


export default CurrentStock
