import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import GetAllPastMedicalHistory from '../IPD/Assessment/API/GetAllPastMedicalHistory';
import GetAllAllergies from './API/GetAllAllergies';
import InsertPatientAllergies from './API/InsertPatientAllergies';
import { SearchIndex } from '../../../Code/Serach';
import GetAllSavedAllergiesByUhid from './API/GetAllSavedAllergiesByUhid';

export default function IPDAllergiesComponent({ saveAllergie = 0, setSaveAllergies }) {
    const userId = JSON.parse(sessionStorage.getItem("LoginData")).userId;
    const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    const [allergyList, setAllergyList] = useState();
    const [allergyParameterList, setAllergyParameterList] = useState();
    let [showAlert, setShowAlert] = useState(0)
    let [getSaveData, setGetSaveData] = useState([])
    let [alertmsg, setAlertmsg] = useState("")
    let row = {
        "subCategoryParameterIdAssignId": 0,
        "parameterValueId": "",
        "parameterStatement": 0,
        "clinicalDataTypeId": "",
        "remark": "",
        "checked": true,
        "DATE": new Date().toLocaleDateString('en-GB')
    }
    let [sendData, setSendData] = useState([])
    let [allergiesDataListTemp, setAllergiesDataListTemp] = useState([])


    const parseParameterJson = (jsonString) => {
        if (!jsonString) return [];
        return JSON.parse(jsonString);
    };

    let handleChange = (val) => {
        let temp = [...sendData]
        console.log("senddaa", sendData, val)
        let response = SearchIndex(sendData, "subCategoryParameterIdAssignId", val.parameterId)
        if (response.length === 0) {
            row["subCategoryParameterIdAssignId"] = val.parameterId
            row["parameterValueId"] = 1
            row["parameterStatement"] = val.parameterName
            row["clinicalDataTypeId"] = val.clinicalDatatypeId
            row["checked"] = true
            row["remark"] = ""
            temp.push(row)
            // allergiesDataListTemp[response]["checked"] = true
        }
        else {
            // allergiesDataListTemp[response].checked = false
            document.getElementById(val.parameterId).checked = false
            document.getElementById(val.parameterId + "_" + "text").value = ""
            temp.splice(response, 1)
            setAllergiesDataListTemp(allergiesDataListTemp)
        }
        setSendData(temp)
    }
    let handleTextChnage = (val) => {
        let temp = [...sendData]
        // let response = SearchIndex(sendData, "subCategoryParameterIdAssignId", val.parameterId)
        let response = sendData.findIndex(obj => obj.subCategoryParameterIdAssignId === val.parameterId)
        if (response !== -1) {
            temp[response]["remark"] = document.getElementById(val.parameterId + "_" + "text").value

        }
        console.log("temp", temp)
        setSendData(temp)
    }


    //Get History Details
    const getAllergy = async (typeId, data) => {
        let response = await GetAllAllergies(clientID);
        if (response.status === 1) {
            const allergyListData = response.responseValue;
            setAllergyList(allergyListData);
            for (let i = 0; i <= allergyListData.length - 1; i++) {
                if (typeId === 1) {

                    if (allergyListData[i].subCategoryName === "YES") {

                        if (JSON.parse(allergyListData[i].parameterJson)[0].parameterId !== null) {
                            const parameterList = parseParameterJson(allergyListData[i].parameterJson);
                            setAllergyParameterList(parameterList);
                            setAllergiesDataListTemp(parameterList)
                            console.log()
                            return
                        }

                    }
                    else {
                        setAllergyParameterList([]);
                        setAllergiesDataListTemp([])

                    }

                }
                else {
                    if (allergyListData[i].subCategoryName === "NO") {
                        if (JSON.parse(allergyListData[i].parameterJson)[0].parameterId !== null) {
                            const parameterList = parseParameterJson(allergyListData[i].parameterJson);
                            setAllergyParameterList(parameterList);
                            setAllergiesDataListTemp(parameterList)
                            return
                        }

                    }
                    else {
                        setAllergyParameterList([]);
                        setAllergiesDataListTemp([])
                    }


                }
            }


        }
    };

    let handleYesNo = (e) => {
        let value = e.target.id
        if (value === "1") {
            getAllergy(1)
            setData()
        }
        else {
            getAllergy(0)
            setData()

        }

    }

    //save Allergy
    let saveAllergies = async () => {

        console.log("data", sendData)
        let uhId = uhID
        let allergies = JSON.stringify(sendData)
        let resp = await InsertPatientAllergies(uhId, allergies)
        if (resp.status === 1) {
            console.log("success")
        }
        else {
            setShowAlert(1)
            console.log("cnsdac", resp)
            setAlertmsg(resp.responseValue)
        }
    }

    let setData = (datas = "") => {
        // let data =datas ===""? getSaveData:datas
        // let temp = [...sendData]
        console.log("senddata", sendData)

        setTimeout(() => {
            sendData.map((val, ind) => {
                document.getElementById(val.subCategoryParameterIdAssignId).checked = true
                // row["subCategoryParameterIdAssignId"] = val.parameterId
                // row["parameterValueId"] = 1
                // row["parameterStatement"] = val.parameterName
                // row["clinicalDataTypeId"] = val.clinicalDatatypeId
                // row["checked"] = true
                // temp.push(row)
            })
        }, 600)
    }

    let getData = async () => {
        let response = await GetAllSavedAllergiesByUhid(uhID)
        if (response.status === 1) {
            if (response.responseValue.allergiesJson !== null) {
                console.log("aaaaaaaa", response.responseValue[0].subCategoryName)
                getAllergy(response.responseValue[0].subCategoryName === "YES" ? 1 : 0)
                response.responseValue[0].subCategoryName === "YES" ? document.getElementById("1").checked = true : document.getElementById("0").checked = true
                setGetSaveData(response.responseValue[0])
                let temp = []
                setTimeout(() => {
                    let data = JSON.parse(response.responseValue[0].allergiesJson)
                    for (let i = 0; i <= data.length - 1; i++) {
                        document.getElementById(data[i].ParameterId).checked = true
                        document.getElementById(data[i].ParameterId + "_" + "text").value = data[i].remark
                        row["subCategoryParameterIdAssignId"] = data[i].ParameterId
                        row["parameterValueId"] = 1
                        row["parameterStatement"] = data.parameterName
                        row["clinicalDataTypeId"] = data.clinicalDatatypeId
                        row["remark"] = data[i].remark
                        row["checked"] = true
                        temp.push(row)
                        row = {
                            "subCategoryParameterIdAssignId": 0,
                            "parameterValueId": "",
                            "parameterStatement": 0,
                            "clinicalDataTypeId": "",
                            "remark": "",
                            "checked": true,
                            "DATE": new Date().toLocaleDateString('en-GB')
                        }
                    }
                    setSendData(temp)
                    temp = []
                }, 100)

            }
        }
        else {

        }

    }

    useEffect(() => {
        if (saveAllergie !== 1) {
            getData()
        }
        else {
            saveAllergies()
            setSaveAllergies(0)
        }
        // getData()
    }, [saveAllergie])

    return (
        <>
            <div className="row">
                <div className="col-md-12 col-sm-12">
                   
                    <div className="restraintChk-sec_">
                        <div className="mb-2 me-2 restraintChk-in">
                            <div className="mb-2 me-2 restraintChk-in">
                                <div className='alllebel'>
                                    <span><label htmlFor="" className="form-label">Advise Reaction</label></span>
                                    <span>
                                        <div className='monit-checkbox checklabel'>
                                            <div className="risk-factors-check regularCheck">
                                                <input type="radio" className="me-2 custom-checkbox form-check-input" id='1' name='advice' onChange={handleYesNo} />Yes
                                            </div>
                                            <div className="risk-factors-check regularCheck">
                                                <input type="radio" className="me-2 custom-checkbox form-check-input" id='0' name='advice' onChange={handleYesNo} />No
                                            </div>
                                        </div>
                                    </span>
                                </div>


                            </div>
                        </div>

                        <div className="mb-2 me-2 restraintChk-in">
                            <div className='monit-checkbox_ checklabel'>
                                <div className="med-table-section" style={{ maxHeight: '13vh', overflow: 'scroll' }}>
                                    <table className="med-table border_ striped">
                                        <thead>
                                            <tr>
                                                <th>{t("Reaction")}</th>
                                                <th>{t("Check")}</th>
                                                <th>{t("If Any Specify")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allergyParameterList && allergyParameterList.map((val, index) => {
                                                return (

                                                    <tr>
                                                        <td >
                                                            {val.parameterName}
                                                        </td>
                                                        <td >
                                                            <div className="risk-factors-check regularCheck">
                                                                <input type="checkbox" className="me-2 custom-checkbox form-check-input" defaultChecked={val.checked} onClick={() => handleChange(val)} id={val.parameterId} name={val.parameterName} />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control form-control-sm" id={val.parameterId + "_" + "text"} name='remark' onChange={() => handleTextChnage(val)} disabled={sendData.some(obj => obj.subCategoryParameterIdAssignId === val.parameterId) === true ? false : true} />
                                                        </td>
                                                    </tr>


                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>



                        </div>

                    </div>
                </div>
            </div >
            {/* <button onClick={saveAllergies}>Save</button> */}
        </>
    )
}
