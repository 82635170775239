import React, { useEffect, useRef, useState } from "react";
import GetProblemList from "../../Clinical/API/KnowMedsAPI/GetProblemList";
import Heading from "../../Component/Heading";
import BoxContainer from "../../Component/BoxContainer";
import TableContainer from "../../Component/TableContainer";

import save from "../../assets/images/icons/save.svg";
import reset from "../../assets/images/icons/reset.svg";
// import transfer from "../../assets/images/icons/transfer.svg";
// import del from "../../assets/images/icons/del.svg";

import AlertToster from "../../Component/AlertToster";
// import editbtn from "../../assets/images/icons/editbtn.svg";
// import delbtn from "../../assets/images/icons/delbtn.svg";
import SuccessToster from "../../Component/SuccessToster";
// import calender from "../../assets/images/icons/calender.svg";
// import clock from "../../assets/images/icons/clock.svg";
// import dish from "../../assets/images/icons/dish.svg";
// import quantity from "../../assets/images/icons/quantity.svg";
// import unitIcon from "../../assets/images/icons/unit.svg";

import GetFoodList from "../API/FoodIntake/GetFoodList";
import GetUnitList from "../API/FoodIntake/GetUnitList";
import GetUnitListSP from '../API/SupplementIntake/GetUnitList';
import DropdownWithSearch from "../../Component/DropdownWithSearch";
import GetFoodIntakeList from "../API/FoodIntake/GetFoodIntakeList";
import SaveFoodIntake from "../API/FoodIntake/SaveFoodIntake";
import TosterUnderProcess from "../../Component/TosterUnderProcess";
import IconEdit from '../../assets/images/icons/IconEdit.svg'
import IconDelete from '../../assets/images/icons/IconDelete.svg'
import Toster from "../../Component/Toster";
import DieteticsFoodIntakeValidation from "../API/FoodIntake/DieteticsFoodIntakeValidation";
import Search from "../../Code/Serach";
import GetSupplementList from '../API/SupplementIntake/GetSupplementList';
import SaveSuppliment from '../API/SupplementIntake/SaveSuppliment';
import GetAllSupplementList from '../API/SupplementIntake/GetAllSupplementList';
import Loader from "../../Component/Loader";
import DeleteSupplement from "../API/SupplementIntake/DeleteSupplement";
import deleteBtnIcon from "../../assets/images/icons/delete.svg"
import meal from "../../assets/images/icons/meal.svg"
import supplement from "../../assets/images/icons/supplement.png"
import PatientTabs from "./PatientTabs";
import SaveAssignRTFeedForExternal from "../API/FoodIntake/SaveAssignRTFeedForExternal";
import SaveAssignOralFeedForExternal from "../API/FoodIntake/SaveAssignOralFeedForExternal";
import GetAssignOralFeedForExternal from "../API/FoodIntake/GetAssignOralFeedForExternal";
import GetAllStatus from "../API/FoodIntake/GetAllStatus";
import SaveAssignRTHoldForExternal from "../API/FoodIntake/SaveAssignRTHoldForExternal";
import GetTypesOfFeed from "../API/FoodIntake/GetTypesOfFeed";
import GetFoodName from "../API/FoodIntake/GetFoodName";
import delbtn from "../../assets/images/icons/delbtn.svg";
import { t } from "i18next";
import IntakeByDiet from "../API/FoodIntake/IntakeByDiet";
import IntakeBySupplement from "../API/SupplementIntake/IntakeBySupplement";
import DeleteFoodIntake from "../API/FoodIntake/DeleteFoodIntake";
export default function DiseaseWiseFoodIntakePopUp() {

    const containerRef = useRef(null);
    let [searchByNameList, setSearchByNameList] = useState([]);
    let [problemlist, setproblemlist] = useState('');
    let [selectedproblem, setselectedproblem] = useState('');
    let [searchByName, setSearchByName] = useState('');
    let [filteredNameList, setFilteredNameList] = useState([]);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showMessage, setShowMeassage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const getproblemlistt = async (value) => {
        let response = await GetProblemList(value);
        console.log('problist--------', response.responseValue);
        setproblemlist(response.responseValue);
    
      }
      const handleSearchProblemname = (searchValue) => {
        setselectedproblem('');
        setSearchByName(searchValue);
        let ddlDataContainerValue2 = document.getElementById('ddlDataContainers2');
        ddlDataContainerValue2.style.display = searchValue.length < 3 ? 'none' : 'block';
        if (searchValue.length >= 2) {
          const data = searchByNameList;
          const filteredData = data.filter((val, i, arr) => {
            var tempData = val.problemname.toLowerCase().includes(searchValue.toLowerCase());
            return tempData;
          });
          setFilteredNameList(filteredData);
          getproblemlistt(searchValue)
        } else {
          setFilteredNameList([]);
        }
    
      }
      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 20 && !isLoading) {

    
        }
    
      };
      const handleSelectProblems = (param) => {
        setselectedproblem(param.problemName);
        getproblemlistt(param)
        
        document.getElementById("errFood").style.display = "none";
        document.getElementById('ddlDataContainers2').style.display = "none";
      }

    return (<>
        <div className="modal" id="diseasefoodintake">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">

                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                        <h4 className="modal-title" style={{ "color": "#FFF" }}>Pateint Intake Type</h4>

                        <button type="button" style={{ "color": "var(--primary-bg-btn-color)", "background": " #fff", "height": "30px", "width": "30px", "border-radius": "18px" }} data-bs-dismiss="modal"><span style={{ "color": "color: var(--primary-bg-btn-color);" }} >X</span></button>
                    </div>

                    {/* <!-- Modal body --> */}
                    <div className="modal-body">
                        <div className="row">
                            {/* <button type="submit" className="btn btn-primary">
      Save
      </button> */}
                            {/* <div className="col-md-2">
  
                    
                      <input type="number" className="form-control form-control-sm" id="quantity" name="quantity" value={qty} 
                        placeholder="Duration in hours" />
                      <small id="errQuantitySp" className="form-text text-danger" style={{ display: 'none' }}></small>

             
                    </div> */}
                            <div className="col-md-4">

                                {/* <label htmlFor="Quantity" className="form-label">Quantity <span className="starMandatory">*</span></label> */}
                                <label htmlFor="disease" className="form-label" style={{ position: "relative" }}>
                                    Disease
                                    <span className="starMandatory">*</span>
                                </label>
                                <div className="mb-2 me-2">
                                    <input type="text" value={selectedproblem === '' ? searchByName : selectedproblem} onChange={(e) => { handleSearchProblemname(e.target.value) }} className="form-control form-control-sm" id="problemname" placeholder="search by Name" name="problemname" />
                                    <div className="box-container uldrp" id='ddlDataContainers2' style={{ display: 'none' }} ref={containerRef} onScroll={handleScroll}>
                                        {problemlist.length > 0 &&
                                            problemlist.map((val, index) => {
                                                return (
                                                    <ul class="list-items">
                                                        <li style={{ textDecoration: 'none' }} onClick={() => { handleSelectProblems(val) }}>{val.problemName}</li>
                                                    </ul>
                                                );
                                            })}
                                    </div>
                                    <small
                                        id="errFood"
                                        className="form-text text-danger"
                                        style={{ display: "none" }}
                                    ></small>
                                </div>
                                <small id="errQuantitySp" className="form-text text-danger" style={{ display: 'none' }}></small>


                            </div>


                        </div>



                        <div className="col-12 mt-3">
                            <div className="med-table-section" style={{ "height": "33vh", zIndex: 0 }}>
                                <table className="med-table border  striped">


                                    <thead style={{ zIndex: '0' }}>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>#</th>
                                            <th>Food Name</th>
                                            <th><input type="checkbox" className="form-check-input" style={{marginRight:'10px'}}/>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <td>1</td>
                                        <td>Soup</td>
                                        <td><input type="checkbox" className="form-check-input" style={{marginRight:'10px'}}/>
                                        <span><img src={IconEdit} className="icnn"style={{height:'20px'}}></img></span>
                                        <span><img src={IconDelete} className="icnn"style={{height:'20px'}}></img></span></td>
                                    </tbody>





                                    

                                    {showAlertToster === 1 ? (
                                        <AlertToster message={showMessage} handle={setShowAlertToster} />
                                    ) : (
                                        ""
                                    )}

                                </table>
                            </div>
                            <div className="row justify-content-end me-2">
                                <div className="col-md-1">
                                    <button type="button" className="btn btn-save btn-save-fill btn-sm" > Save</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <!-- Modal footer --> */}


                </div>
            </div>
        </div>
    </>)
}