async function DeleteVendorMaster(id) {
  let url = window.InventoryBaseUrl + `/api/VendorMaster/DeleteVendorMaster?Id=` + id;
  // let url = window.InventoryBaseUrl + "/api/VendorMaster/DeleteVendorMaster";
  let head = {
    'Content-Type': 'application/JSON',
    accept: '*/*',
  }
  let data = {};
  let response =
    await fetch(url, {
      headers: head,
      method: "DELETE",
    }).then((res) => res.json())
      .then(data)
  return response;


}
export default DeleteVendorMaster;