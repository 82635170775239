import { t } from 'i18next';
import React, { useState, useEffect } from 'react'
import IconEdit from '../../../assets/images/icons/IconEdit.svg'
import IconDelete from '../../../assets/images/icons/IconDelete.svg'
import Heading from '../../../Component/Heading';
import TableContainer from '../../../Component/TableContainer';
import GetAllDoctorOrdersForAssignedUser from '../Api/DoctorOrdersForAssignedUser/GetAllDoctorOrdersForAssignedUser';
import Loader from '../../../Component/Loader';
import SuccessToster from '../../../Component/SuccessToster';
import AlertToster from '../../../Component/AlertToster';
import Search from '../../../Code/Serach';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import PostRespondDoctorOrdersByStaff from '../Api/DoctorOrdersForAssignedUser/PostRespondDoctorOrdersByStaff';
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Toster from '../../../Component/Toster';
import GetPatientDetailsByUHID from '../../../PatientMonitorDashboard/Api/GetPatientDetailsByUHID';
import GetMenuByDepartmentIdAndUserId from '../../../PatientMonitorDashboard/Api/GetMenuByDepartmentIdAndUserId';
import GetHRDutyAssignAssignedUser from '../Api/DoctorOrdersForAssignedUser/GetHRDutyAssignAssignedUser';
import GetHRFixedDutyForAssignedUser from '../Api/DoctorOrdersForAssignedUser/GetHRFixedDutyForAssignedUser';
import GetAutomateDutyForAssignedUser from '../Api/DoctorOrdersForAssignedUser/GetAutomateDutyForAssignedUser';
export default function Duties() {
    const todayDate = new Date();
    let [dutyList, setDutyList] = useState([]);
    let [dutyListMain, setDutyListMain] = useState([]);
    let [sendForm, setSendForm] = useState
        ({
            "assignedTo": window.userId,
            "clientId": window.clientId,
        })
    let [showLoder, setShowLoder] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showToster, setShowToster] = useState(0);
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [tosterValue, setTosterValue] = useState(0);
    let [tosterMessage, setTosterMessage] = useState("");
    let [isShowPopup, setIsShowPopup] = useState(0);
    let [filterDataList, setFilterDataList] = useState([]);
    let [hrDutyList, setHrDutyList] = useState([]);
    let [fromDate, setFromDate] = useState(todayDate.toISOString().split('T')[0]);
    let [toDate, setToDate] = useState(todayDate.toISOString().split('T')[0]);
    let [hrFixeddutyList, setHRFixeddutyList] = useState([]);
    let [automateDutyList, setAutomateDutyList] = useState([]);
    let [supportTicketList, setSupportTicketList] = useState([]);
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    const DesignationID = JSON.parse(window.sessionStorage.getItem("LoginData")).designationId;
    let [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
    const [selectedDutyType, setSelectedDutyType] = useState(1);
    const [searchHRFixedDutyList, setSearchHRFixedDutyList] = useState([]);
    const [searchHRDailyDutyList, setSearchHRDailyDutyList] = useState([]);
    const [searchAutomateDutyList, setSearchAutomateDutyList] = useState([]);
    //Handle Change
    let handleChange = (e) => {
        clearValidationErrMessage();

        let name = e.target.name;
        let value = e.target.value;
        if (name === 'staffComment') {
            document.getElementById('staffComment').style.display = "block";
        }
        setSendForm(sendForm => ({
            ...sendForm,
            [name]: value
        }));
    }
    //Clear Error Message
    const clearValidationErrMessage = () => {
        document.getElementById('staffComment').style.display = "none";
    }

    //Get HR Duty to designation ID
    const getHRDuty = async () => {
        setShowLoder(1);
        var obj = {
            clientId: clientID,
            designationId: DesignationID

        }
        const response = await GetHRDutyAssignAssignedUser(obj)
        if (response.status === 1) {
            setHrDutyList(response.responseValue);
            setSearchHRDailyDutyList(response.responseValue);
        }
        else {
            setShowLoder(0);
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }

    //Get All DutyList
    const GetDuties = async () => {
        const txtFromDate = document.getElementById('txtformDate').value;
        const txtToDate = document.getElementById('txttoDate').value;
        var param = {
            // assignedUserId: 179,
            assignedUserId: userID,
            clientId: window.clientId,
            fromDate: txtFromDate,
            toDate: txtToDate,
            dutyType: 1,
        }
        var param2 = {
            // assignedUserId: 179,
            assignedUserId: userID,
            clientId: window.clientId,
            fromDate: txtFromDate,
            toDate: txtToDate,
            dutyType: 2,
        }
        var param3 = {
            // assignedUserId: 179,
            assignedUserId: userID,
            clientId: window.clientId,
            fromDate: txtFromDate,
            toDate: txtToDate,
            dutyType: 3,
        }
        try {
            setShowLoder(1);
            const [response1, response2, response3] = await Promise.all([
                GetAllDoctorOrdersForAssignedUser(param), //Used TO Call Api for Orders
                GetHRFixedDutyForAssignedUser(param2),//Used TO Call Api for HR Fixed Duty
                GetAutomateDutyForAssignedUser(param3)//Used TO Call Api for Automate Duty
            ]);
            if (response1.status === 1) {
                setDutyList(response1.responseValue);
                setFilterDataList(response1.responseValue);
                setDutyListMain(response1.responseValue)
            }
            else {
                setShowLoder(0);
                setShowAlertToster(1);
                setShowErrMessage(response1.responseValue);
                setTimeout(() => {
                    setShowAlertToster(0);
                }, 1500)
                return;
            }
            if (response2.status === 1) {
                setHRFixeddutyList(response2.responseValue);
                setSearchHRFixedDutyList(response2.responseValue);
            }
            else {
                setShowLoder(0);
                setShowAlertToster(1);
                setShowErrMessage(response2.responseValue);
                setTimeout(() => {
                    setShowAlertToster(0);
                }, 1500)
                return;
            }
            if (response3.status === 1) {
                setAutomateDutyList(response3.responseValue);
                setSearchAutomateDutyList(response3.responseValue);
            }
            else {
                setShowLoder(0);
                setShowAlertToster(1);
                setShowErrMessage(response3.responseValue);
                setTimeout(() => {
                    setShowAlertToster(0);
                }, 1500)
                return;
            }
        }
        catch (error) {
            setShowLoder(0);
            console.error('error', error);
        }
        finally {
            setShowLoder(0);
        }
    }

    // Handle Update Comment button
    let handleUpdateComment = async (id, notificationId, staffComment) => {
        document.getElementById("commentModal").classList.add("show");
        document.getElementById("commentModal").classList.add("fade");
        document.getElementById("commentModal").style.display = "block";
        setSendForm({
            ...sendForm,
            "id": id,
            "notificationId": notificationId,
            "staffComment": staffComment,
            "assignedTo": window.userId,
            "clientId": window.clientId,
        });
        document.getElementById("staffComment").value = staffComment;
        document.getElementById('staffComment').style.display = "block";
    }

    //Handle Save
    const handlerSave = async () => {
        const { notificationId, staffComment } = sendForm;
        if (staffComment === '' || staffComment === null || staffComment === undefined) {
            document.getElementById('errStaffComment').innerHTML = "Field Can't be blank!";
            document.getElementById('errStaffComment').style.display = "block";
        } else {
            setShowUnderProcess(1);
            var obj = {
                "id": notificationId,
                "staffComment": staffComment,
                "assignedTo": userID,
                // "assignedTo": window.userId,
                "clientId": window.clientId,
            }
            const response = await PostRespondDoctorOrdersByStaff(obj);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Staff Comment Updated Successfully");
                setTimeout(() => {
                    setShowToster(0);
                    document.querySelector('body').removeAttribute('style');
                    document.querySelector('body').removeAttribute('className');
                    document.getElementById("commentModal").classList.remove("show");
                    document.getElementById("commentModal").style.display = "none";
                    document.getElementById("commentModal").removeAttribute("aria-modal");
                    document.getElementById("commentModal").removeAttribute("role");
                }, 2000);
                GetDuties();
                handleClear();
            } else {
                setIsShowPopup(0);
                setShowUnderProcess(0);
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTimeout(() => {

                    setShowToster(0);
                }, 1500);
            }
        }
    };
    //handle search
    let handleSearch = (e) => {
        const {name,value}=e.target;
        if(name ==="search-order-list"){
            let resp = Search(dutyListMain, e.target.value)
            if (e.target !== "") {
                if (resp.length !== 0) {
                    setDutyList(resp)
                }
                else {
                    setDutyList([])
                }
            }
            else {
                setDutyList(dutyListMain)
            }
        }
        else if(name ==="searchbox-HR-Fixed-Duty"){
            let respHRFixedDuty = Search(searchHRFixedDutyList, value)
            if (value !== "") {
                if (respHRFixedDuty.length !== 0) {
                    setHRFixeddutyList(respHRFixedDuty)
                }
                else {
                    setHRFixeddutyList([])
                }
            }
            else {
                setHRFixeddutyList(searchHRFixedDutyList)
            }
        }
        else if(name ==="search-Automate-Duty"){
            let respAutomateDuty = Search(searchAutomateDutyList, value)
            if (value !== "") {
                if (respAutomateDuty.length !== 0) {
                    setAutomateDutyList(respAutomateDuty)
                }
                else {
                    setAutomateDutyList([])
                }
            }
            else {
                setAutomateDutyList(searchAutomateDutyList)
            }
        }
    }

    //Handle Clear
    const handleClear = () => {
        clearValidationErrMessage();
        setSendForm({ "userId": window.userId, "clientId": window.clientId, })
        document.getElementById("staffComment").value = "";
    }

    const closeModal = () => {
        document.querySelector('body').removeAttribute('style');
        document.querySelector('body').removeAttribute('className');
        document.getElementById("commentModal").classList.remove("show");
        document.getElementById("commentModal").style.display = "none";
        document.getElementById("commentModal").removeAttribute("aria-modal");
        document.getElementById("commentModal").removeAttribute("role");
        document.getElementById('errStaffComment').style.display = "none";
        handleClear();
        clearValidationErrMessage();
    };

    const handleFilter = (e) => {
        const { name, value } = e.target;
        if (name === "fromDate") {
            setFromDate(value)
        }
        else if (name === "toDate") {
            setToDate(value)
        }
    }
    const handleShowFilteredData = () => {
        GetDuties();
    }

    const handleClearFilter = () => {
        setFromDate(todayDate.toISOString().split('T')[0]);
        setToDate(todayDate.toISOString().split('T')[0]);
        setTimeout(() => {
            GetDuties();
        }, 1000)
    }
    const handleRedirect = async (patientDetails, taskDetails) => {
        const menuDetails = taskDetails.menuData;
        let resp = await GetPatientDetailsByUHID(patientDetails.uhID);
        if (resp.status === 1) {
            let deptmenu = await GetMenuByDepartmentIdAndUserId(resp.responseValue[0].deptId)
            if (deptmenu.status === 1) {
                window.sessionStorage.setItem("IPDpatientList", JSON.stringify(
                    resp.responseValue,
                ))
                window.sessionStorage.setItem("departmentmenu", JSON.stringify({
                    "menuList": deptmenu.responseValue.menuList,
                    "departmentList": deptmenu.responseValue.departmentList,
                }))
                window.sessionStorage.setItem("IPDpatientsendData", JSON.stringify(
                    [[patientDetails.uhID]],
                ))
                window.sessionStorage.setItem("IPDactivePatient", JSON.stringify({ Uhid: patientDetails.uhID }))
                window.sessionStorage.setItem("activePage", JSON.stringify({
                    "WardId": resp.responseValue[0].wardId,
                    "wardName": resp.responseValue[0].wardName,
                    "DepartmentId": resp.responseValue[0].deptId,
                    "departmentName": patientDetails.departmentName,
                    "menuName": "Patient Personal Dashboard",
                    "menuId": 51
                }))
                window.open(menuDetails.url)
            }

            // newWindow["uhid"] = props.patientData.UhId
            // window["clientId"] = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId
            // setPatientProfilePopup(1)
        }
    }

    const hasData = dutyList
        ? dutyList.some((val) => val.taskDetails && val.taskDetails.some((task) => task.dutyType === 2))
        : false;

    useEffect(() => {
        setTimeout(() => {
            GetDuties();
            getHRDuty();
        }, 1000)
    }, []);
    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 mt-3">
                            <div className="med-box">
                                <div className="title">Duties </div>
                                <div className="inner-content">
                                    <div className="d-flex flex-wrap align-content-end">
                                        <div className="mb-2 me-2">
                                            <label htmlFor="bedName" className="form-label">From Date</label>
                                            <input type='date' className='form-control form-control-sm' name='fromDate' id='txtformDate' value={fromDate} onChange={handleFilter} />
                                        </div>

                                        <div className="mb-2 me-2">
                                            <label htmlFor="bedName" className="form-label">To Date</label>
                                            <input type='date' className='form-control form-control-sm' name='toDate' id='txttoDate' value={toDate} onChange={handleFilter} />
                                        </div>

                                        <div className="mb-2 relative">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                            <div>
                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleShowFilteredData}><i className="fa fa-search-plus icnn" aria-hidden="true"></i> Show </button>
                                                <button type="button" className="btn btn-save  btn-sm mb-1 me-1" onClick={handleClearFilter}><img src={clearIcon} className='icnn' alt='' /> Clear </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-12 mt-1">
                            <div className="accordion orders-accordian" id="accordionExample">
                                {/* --------------------------------------Accordian Section Order----------------------------------------------- */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <div className="accordion-button" data-bs-toggle="collapse" data-bs-target="#orders" aria-expanded="true" aria-controls="orders">
                                            Order
                                        </div>
                                    </h2>
                                    <div id="orders" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                        <div className="accordion-body accod-in">
                                            <div className='handlser customHeading'>
                                                <Heading text={t("Order List")} />
                                                <div style={{ position: 'relative' }}>
                                                    <input type="text" className='form-control form-control-sm' placeholder={t("Search")} name='search-order-list' onChange={handleSearch} />
                                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                                </div>
                                            </div>
                                            <div className="med-table-section" style={{ "max-height": "65vh", minHeight: '30vh' }}>
                                                <table className="med-table border striped_">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center" style={{ "width": "3%" }}>#</th>
                                                            <th style={{ "width": "15%" }}>{t("Patient Name")}</th>
                                                            <th style={{ "width": "82%", padding: '0px' }}>
                                                                <div style={{ overflow: 'auto' }}>
                                                                    <table className="med-table border" style={{ width: '100%', minWidth: '900px' }}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ "width": "15%", borderBottom: "0" }}>{t("Order")}</th>
                                                                                <th className='text-center_' style={{ "width": "10%", borderBottom: "0" }}>{t("Completed")}</th>
                                                                                <th style={{ "width": "20%", borderBottom: "0" }}>{t("Staff Comment")}</th>
                                                                                <th style={{ "width": "20%", borderBottom: "0" }}>{t("Incharge Comment")}</th>
                                                                                <th className='text-center_' style={{ "width": "15%", borderBottom: "0" }}>{t("Task Handover")}</th>
                                                                                <th className='text-center_' style={{ "width": "10%", borderBottom: "0" }}>{t("Status")}</th>
                                                                                <th className="text-center_" style={{ "width": "10%", borderBottom: "0" }}>{t("Action")}</th>
                                                                            </tr>
                                                                        </thead>
                                                                    </table>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {dutyList && dutyList.map((val, ind) => {
                                                            return (
                                                                <tr key={val.id}>
                                                                    <td className="text-center">{ind + 1}</td>
                                                                    <td style={{ "width": "15%" }}>
                                                                        <span style={{ fontWeight: 'bold' }}>{val.patientName}</span>
                                                                        <span>({val.uhID})</span>
                                                                    </td>
                                                                    <td style={{ "width": "82%", padding: '0px' }}>
                                                                        <div style={{ maxHeight: '250px', overflow: 'auto' }}>
                                                                            <table className="med-table border" style={{ width: '100%', minWidth: '900px' }}>
                                                                                <tbody>
                                                                                    {val.taskDetails && val.taskDetails.map((task, i) => {

                                                                                        return (
                                                                                            <tr key={i + 1}>
                                                                                                <td style={{ color: task.isCompleted === true ? 'Green' : 'Red', width: '15%' }}>{task.categoryName}</td>
                                                                                                <td className="text-center_" style={{ color: task.isCompleted === true ? 'Green' : 'Red', width: '10%' }}>{task.isCompleted === true ? 'Yes' : 'No'}</td>
                                                                                                <td style={{ "width": "20%" }}>{task.staffComment}</td>
                                                                                                <td style={{ "width": "20%" }}>{task.inchargeComment}</td>
                                                                                                <td className='text-center_' style={{ "width": "15%" }}>{task.handoverDetails === null ? 'No' : 'Yes'}</td>
                                                                                                <td className='text-center_' style={{ "width": "10%" }}>
                                                                                                    {task.isDNC === true && task.inchargeComment !== null && task.isCompleted === false ?
                                                                                                        <span className='statsRejected'>Rejected</span> :
                                                                                                        task.isDNC === false && task.staffComment !== null && task.inchargeComment === null && task.isCompleted === false ?
                                                                                                            <span className='statsPending'>Pending</span> :
                                                                                                            task.isDNC === false && task.staffComment !== null && task.isCompleted === true ?
                                                                                                                <span className='statsComplete'>Approved</span> : ''
                                                                                                    }
                                                                                                </td>
                                                                                                <td style={{ "width": "10%" }}>
                                                                                                    <div className="action-button" style={{ justifyContent: 'left', columnGap: '5px' }}>
                                                                                                        {task.isDNC === false && task.isCompleted === false && task.inchargeComment === null ?
                                                                                                            <>
                                                                                                                <div data-bs-title="Edit Row" className='btnChat' title='Comment' onClick={() => handleUpdateComment(val.id, task.notificationId, task.staffComment)}><i className="bi bi-chat-square-text"></i></div>
                                                                                                                <div><i className="fa-solid fa-diamond-turn-right" title='Click To Complete Duty' onClick={() => { handleRedirect(val, task) }}></i></div>
                                                                                                            </>
                                                                                                            : ""
                                                                                                        }
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        );

                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                        {!dutyList || dutyList.length === 0 && (
                                                            <tr>
                                                                <td colSpan="3" className="text-center">
                                                                    {t("No data available Please select another date range.")}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* --------------------------------------Accordian Section HR Duties----------------------------------------------- */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <div className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#HRDuties" aria-expanded="false" aria-controls="HRDuties">
                                            HR Duty
                                        </div>
                                    </h2>
                                    <div id="HRDuties" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body accod-in">
                                            <div className='handlser customHeading'>
                                                <Heading text={t("HR Fixed Duty")} />
                                                <div style={{ position: 'relative' }}>
                                                    <input type="text" className='form-control form-control-sm' placeholder={t("Search")} name='searchbox-HR-Fixed-Duty' onChange={handleSearch} />
                                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                                </div>
                                            </div>
                                            <div className="med-table-section" style={{ "max-height": "65vh", minHeight: '30vh' }}>
                                                <table className="med-table border striped_">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center" style={{ "width": "3%" }}>#</th>
                                                            <th style={{ "width": "15%" }}>{t("Patient Name")}</th>
                                                            {/* <th>{t("Order Details")}</th> */}
                                                            <th style={{ "width": "82%", padding: '0px' }}>
                                                                <div style={{ overflow: 'auto' }}>
                                                                    <table className="med-table border" style={{ width: '100%', minWidth: '900px' }}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ "width": "15%", borderBottom: "0" }}>{t("Order")}</th>
                                                                                <th className='text-center_' style={{ "width": "10%", borderBottom: "0" }}>{t("Completed")}</th>
                                                                                <th style={{ "width": "20%", borderBottom: "0" }}>{t("Staff Comment")}</th>
                                                                                <th style={{ "width": "20%", borderBottom: "0" }}>{t("Incharge Comment")}</th>
                                                                                <th className='text-center_' style={{ "width": "15%", borderBottom: "0" }}>{t("Task Handover")}</th>
                                                                                <th className='text-center_' style={{ "width": "10%", borderBottom: "0" }}>{t("Status")}</th>
                                                                                <th className="text-center_" style={{ "width": "10%", borderBottom: "0" }}>{t("Action")}</th>
                                                                            </tr>
                                                                        </thead>
                                                                    </table>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {hrFixeddutyList &&
                                                            hrFixeddutyList
                                                                .filter((val) => val.taskDetails && val.taskDetails.some((task) => task.dutyType === 2))
                                                                .map((val, ind) => {
                                                                    return (
                                                                        <tr key={val.id}>
                                                                            <td className="text-center">{ind + 1}</td>
                                                                            <td style={{ "width": "15%" }}>
                                                                                <span style={{ fontWeight: 'bold' }}>{val.patientName}</span>
                                                                                <span>({val.uhID})</span>
                                                                            </td>
                                                                            <td style={{ "width": "82%", padding: '0px' }}>
                                                                                <div style={{ maxHeight: '250px', overflow: 'auto' }}>
                                                                                    <table className="med-table border" style={{ width: '100%', minWidth: '900px' }}>
                                                                                        <tbody>
                                                                                            {val.taskDetails &&
                                                                                                val.taskDetails.map((task, i) => {

                                                                                                    return (
                                                                                                        <tr key={i + 1}>
                                                                                                            <td style={{ color: task.isCompleted === true ? 'Green' : 'Red', width: '15%' }}>{task.categoryName}</td>
                                                                                                            <td className="text-center_" style={{ color: task.isCompleted === true ? 'Green' : 'Red', width: '10%' }}>{task.isCompleted === true ? 'Yes' : 'No'}</td>
                                                                                                            <td style={{ "width": "20%" }}>{task.staffComment}</td>
                                                                                                            <td style={{ "width": "20%" }}>{task.inchargeComment}</td>
                                                                                                            <td className='text-center_' style={{ "width": "15%" }}>{task.handoverDetails === null ? 'No' : 'Yes'}</td>
                                                                                                            <td className='text-center_' style={{ "width": "10%" }}>
                                                                                                                {task.isDNC === true && task.inchargeComment !== null && task.isCompleted === false ?
                                                                                                                    <span className='statsRejected'>Rejected</span> :
                                                                                                                    task.isDNC === false && task.staffComment !== null && task.inchargeComment === null && task.isCompleted === false ?
                                                                                                                        <span className='statsPending'>Pending</span> :
                                                                                                                        task.isDNC === false && task.staffComment !== null && task.isCompleted === true ?
                                                                                                                            <span className='statsComplete'>Approved</span> : ''
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td style={{ "width": "10%" }}>
                                                                                                                <div className="action-button" style={{ justifyContent: 'left', columnGap: '5px' }}>
                                                                                                                    {task.isDNC === false && task.isCompleted === false && task.inchargeComment === null ?
                                                                                                                        <>
                                                                                                                            <div data-bs-title="Edit Row" className='btnChat' title='Comment' onClick={() => handleUpdateComment(val.id, task.notificationId, task.staffComment)}><i className="bi bi-chat-square-text"></i></div>
                                                                                                                            <div><i className="fa-solid fa-diamond-turn-right" title='Click To Complete Duty' onClick={() => { handleRedirect(val, task) }}></i></div>
                                                                                                                        </>
                                                                                                                        : ""
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    );

                                                                                                })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                        {!hasData && (
                                                            <tr>
                                                                <td colSpan="3" className="text-center">
                                                                    {t("No data available Please select another date range.")}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>

                                                <div className='customHeading mt-3' style={{ "max-height": "30vh", overflow: 'auto' }}>
                                                    <Heading text={t("HR Daily Duty")} />
                                                    <table className="med-table border">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center" style={{ "width": "5%" }}>#</th>
                                                                <th>Designation</th>
                                                                <th>Task Category</th>
                                                                <th>Task</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {hrDutyList && hrDutyList.map((val, ind) => {
                                                                return (
                                                                    <tr>
                                                                        <td className="text-center">{ind + 1}</td>
                                                                        <td>{val.designationName}</td>
                                                                        <td>{val.categoryDetails ? val.categoryDetails.categoryName : ''}</td>
                                                                        <td>{val.task}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* --------------------------------------Accordian Section Automate----------------------------------------------- */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <div className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#automte" aria-expanded="false" aria-controls="automte">
                                            Automate
                                        </div>
                                    </h2>
                                    <div id="automte" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body accod-in">
                                            <div className='handlser customHeading'>
                                                <Heading text={t("Automate")} />
                                                <div style={{ position: 'relative' }}>
                                                    <input type="text" className='form-control form-control-sm' placeholder={t("Search")} name='search-Automate-Duty' onChange={handleSearch} />
                                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                                </div>
                                            </div>
                                            <div className="med-table-section" style={{ "max-height": "65vh", minHeight: '30vh' }}>
                                                <table className="med-table border striped_">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center" style={{ "width": "3%" }}>#</th>
                                                            <th style={{ "width": "15%" }}>{t("Patient Name")}</th>
                                                            {/* <th>{t("Order Details")}</th> */}
                                                            <th style={{ "width": "82%", padding: '0px' }}>
                                                                <div style={{ overflow: 'auto' }}>
                                                                    <table className="med-table border" style={{ width: '100%', minWidth: '900px' }}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ "width": "15%", borderBottom: "0" }}>{t("Order")}</th>
                                                                                <th className='text-center_' style={{ "width": "10%", borderBottom: "0" }}>{t("Completed")}</th>
                                                                                <th style={{ "width": "20%", borderBottom: "0" }}>{t("Staff Comment")}</th>
                                                                                <th style={{ "width": "20%", borderBottom: "0" }}>{t("Incharge Comment")}</th>
                                                                                <th className='text-center_' style={{ "width": "15%", borderBottom: "0" }}>{t("Task Handover")}</th>
                                                                                <th className='text-center_' style={{ "width": "10%", borderBottom: "0" }}>{t("Status")}</th>
                                                                                <th className="text-center_" style={{ "width": "10%", borderBottom: "0" }}>{t("Action")}</th>
                                                                            </tr>
                                                                        </thead>
                                                                    </table>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {automateDutyList &&
                                                            automateDutyList
                                                                .filter((val) => val.taskDetails && val.taskDetails.some((task) => task.dutyType === 3))
                                                                .map((val, ind) => {
                                                                    return (
                                                                        <tr key={val.id}>
                                                                            <td className="text-center">{ind + 1}</td>
                                                                            <td style={{ "width": "15%" }}>
                                                                                <span style={{ fontWeight: 'bold' }}>{val.patientName}</span>
                                                                                <span>({val.uhID})</span>
                                                                            </td>
                                                                            <td style={{ "width": "82%", padding: '0px' }}>
                                                                                <div style={{ maxHeight: '250px', overflow: 'auto' }}>
                                                                                    <table className="med-table border" style={{ width: '100%', minWidth: '900px' }}>
                                                                                        <tbody>
                                                                                            {val.taskDetails &&
                                                                                                val.taskDetails.map((task, i) => {
                                                                                                    return (
                                                                                                        <tr key={i + 1}>
                                                                                                            <td style={{ color: task.isCompleted === true ? 'Green' : 'Red', width: '15%' }}>{task.categoryName}</td>
                                                                                                            <td className="text-center_" style={{ color: task.isCompleted === true ? 'Green' : 'Red', width: '10%' }}>{task.isCompleted === true ? 'Yes' : 'No'}</td>
                                                                                                            <td style={{ "width": "20%" }}>{task.staffComment}</td>
                                                                                                            <td style={{ "width": "20%" }}>{task.inchargeComment}</td>
                                                                                                            <td className='text-center_' style={{ "width": "15%" }}>{task.handoverDetails === null ? 'No' : 'Yes'}</td>
                                                                                                            <td className='text-center_' style={{ "width": "10%" }}>
                                                                                                                {task.isDNC === true && task.inchargeComment !== null && task.isCompleted === false ?
                                                                                                                    <span className='statsRejected'>Rejected</span> :
                                                                                                                    task.isDNC === false && task.staffComment !== null && task.inchargeComment === null && task.isCompleted === false ?
                                                                                                                        <span className='statsPending'>Pending</span> :
                                                                                                                        task.isDNC === false && task.staffComment !== null && task.isCompleted === true ?
                                                                                                                            <span className='statsComplete'>Approved</span> : ''
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td style={{ "width": "10%" }}>
                                                                                                                <div className="action-button" style={{ justifyContent: 'left', columnGap: '5px' }}>
                                                                                                                    {task.isDNC === false && task.isCompleted === false && task.inchargeComment === null ?
                                                                                                                        <>
                                                                                                                            <div data-bs-title="Edit Row" className='btnChat' title='Comment' onClick={() => handleUpdateComment(val.id, task.notificationId, task.staffComment)}><i className="bi bi-chat-square-text"></i></div>
                                                                                                                            <div><i className="fa-solid fa-diamond-turn-right" title='Click To Complete Duty' onClick={() => { handleRedirect(val, task) }}></i></div>
                                                                                                                        </>
                                                                                                                        : ""
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    );
                                                                                                })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                        {!dutyList || (dutyList.length === 0 && (
                                                            <tr>
                                                                <td colSpan="3" className="text-center">
                                                                    {t("No data available Please select another date range.")}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* --------------------------------------Accordian Section Support Ticket----------------------------------------------- */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <div className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#supportTicket" aria-expanded="false" aria-controls="supportTicket">
                                            Support Ticket
                                        </div>
                                    </h2>
                                    <div id="supportTicket" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body loader accod-in">
                                            No data available
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*  <!------------------- Start Nurse Comment Modal ---------------------------------->  */}

                            <div className="modal fade bk-dropfilter" id="commentModal" tabIndex="-1" aria-labelledby="commentModalLabel" aria-hidden="true" data-bs-backdrop="static">
                                <div className="modal-dialog modal-md modalDelete1">
                                    <div className="modal-content">
                                        <div className="modal-body modal-lg modelbdy">
                                            <div className="med-box1">
                                                <div className="inner-content">
                                                    <div className="d-flex flex-wrap align-content-end">
                                                        <div className="mb-2 me-2 commnet-txt">
                                                            <label htmlFor="staffComment" className="form-label">Staff Comment<span className="starMandatory">*</span></label>
                                                            <textarea type="text" className="form-control" id="staffComment" placeholder={t("Enter staff comment")} name="staffComment" onChange={handleChange}></textarea>
                                                            <small id="errStaffComment" className="invalid-feedback" style={{ display: 'none' }}></small>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer1 text-center relative">
                                                        <div>
                                                            {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                                                <>
                                                                    {showToster === 1 ?
                                                                        <Toster value={tosterValue} message={tosterMessage} />
                                                                        : <div>
                                                                            <button type="button" className="btn btn-clear btn-sm mb-1 me-2" data-bs-dismiss="modal" onClick={closeModal}><img src={clearIcon} className='icnn' alt='' />Cancel</button>
                                                                            <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handlerSave}><img src={saveButtonIcon} className='icnn' alt='' />Save</button>
                                                                        </div>
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

                        </div>
                    </div>
                </div>
                {
                    showLoder === 1 ? <Loader val={showLoder} /> : ""
                }
                {/* Toaster */}

                {
                    isShowToaster === 1 ?
                        <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                }
                {
                    showAlertToster === 1 ?
                        <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                }
            </section>
        </>
    )
}
