import React, { useEffect, useState } from 'react'
import '../../../../../assets/css/CertificateCard.css'
import logo from '../../../../../assets/images/Navbar/offcanvas-logo.png'
import bar1 from '../../../../../assets/images/icons/bar1.png'
import bar2 from '../../../../../assets/images/icons/bar2.png'
import GetMedvantageIPDFileData from '../../../../API/IPD/IPDFile/GetMedvantageIPDFileData'
import GetLabIPDFileData from '../../../../API/IPD/IPDFile/GetLabIPDFileData';
import GetIntakeIPDFileData from '../../../../API/IPD/IPDFile/GetIntakeIPDFileData';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import Loader from '../../../../../Component/Loader'
import GetPatientDetailsByUHID from '../../../../API/IPD/personalDashboardIndexSecond/GetPatientDetailsByUHID'
import Vector from "../../../../../assets/images/icons/Vector.svg";
import Contact from "../../../../../assets/images/icons/Contact.svg";
import { json } from 'react-router-dom'
import GetOutputListForIPDFile from '../../../../API/IPD/IPDFile/GetOutputListForIPDFile'
import GetDataForPrint from '../../../../../Radiology/API/PrintReport/GET/GetDataForPrint'
import GetPatientDetails from '../PatientPersonalDashboard/Api/GetPatientDetails'
import GetRadiologyReport from '../../../../API/IPD/IPDFile/GetRadiologyReport'


export default function IPDFile() {

    const { t } = useTranslation();
    document.body.dir = i18n.dir()

    let UHID = JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid;
    let [patientDetails, setPatientDetails] = useState([]);
    let [progressNote, setProgressNote] = useState([]);
    let [complaintSign, setComplaintSign] = useState([]);
    let [patientHistory, setPatientHistory] = useState([]);
    let [procedureNote, setProcedureNote] = useState([]);
    let [physicalExamination, setPhysicalExamination] = useState([]);
    let [goals, setGoals] = useState([]);
    let [vitals, setVitals] = useState([]);
    let [vitalsDate, setVitalsDate] = useState([]);
    let [prescription, setPrescription] = useState([]);
    let [prescriptionMedDate, setPrescriptionMedDate] = useState([]);
    let [labIPDFileDatas, setLabIPDFileData] = useState([]);
    let [resultDateTime, setResultDateTime] = useState([]);
    let [testName, setTestName] = useState([]);
    let [subTestName, setSubTestName] = useState([]);
    let [labUniqueDate, setLabUniqueDate] = useState([]);
    let [intakeIPDFileData, setIntakeIPDFileData] = useState([]);
    let [outPutIPDFileData, setOutPutIPDFileData] = useState([]);
    let [intakeDate, setIntakeDate] = useState([]);
    let [loader, setLoader] = useState(1);
    let [clientData, setClientData] = useState(1);
    let [printDataList, setPrintDataList] = useState([]);
    const clientID = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
    let [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
    const parser = new DOMParser();

    let getData = async () => {
        //Vitals
        let vitalData = [];
        let vitalDate = [];
        //Prescription
        let prescriptionData = [];
        let prescriptionDate = [];
        //Lab
        let labData = [];
        let labTest = [];
        let labSubtest = [];
        let labDate = [];
        //intake
        let intakeData = [];
        let intakeDate = [];
        let medIPDFileResp = await GetMedvantageIPDFileData(UHID);
        let labIPDFileResp = await GetLabIPDFileData(UHID);
        let intakeIPDFileResp = await GetIntakeIPDFileData(UHID);
        let outputIPDFileResp = await GetOutputListForIPDFile(UHID);
        const radiologyResponse = await GetRadiologyReport(UHID);
        // let patientData = await GetPatientDetailsByUHID(UHID)

        // if (patientData.status === 1) {
        //     setPatientDetails(patientData.responseValue[0]);
        //     // console.log('patientData.responseValue', patientData.responseValue[0]);
        // }
        //Get med IPD File Data
        if (medIPDFileResp.status === 1) {
            // console.log('medIPDFileResp', medIPDFileResp);
            setPatientDetails(medIPDFileResp.responseValue.patientDetails[0]);
            setProgressNote(medIPDFileResp.responseValue.progressNote);
            setComplaintSign(medIPDFileResp.responseValue.complaintSign);
            setPatientHistory(medIPDFileResp.responseValue.patientHistory);
            setProcedureNote(medIPDFileResp.responseValue.procedureNote);
            setPhysicalExamination(medIPDFileResp.responseValue.physicalExamination);
            setGoals(medIPDFileResp.responseValue.goals);
            //Get Vitals Data & Date
            for (var i = 0; i < medIPDFileResp.responseValue.vitals.length; i++) {
                vitalData.push(medIPDFileResp.responseValue.vitals[i]);
                vitalDate.push(medIPDFileResp.responseValue.vitals[i].datee);
            }
            setVitals([...vitalData]);
            setVitalsDate([...vitalDate]);
            vitalData = [];
            vitalDate = [];

            //Get Prescription Data & Date
            for (var i = 0; i < medIPDFileResp.responseValue.prescription.length; i++) {
                prescriptionData.push(medIPDFileResp.responseValue.prescription[i]);
                prescriptionDate.push(medIPDFileResp.responseValue.prescription[i].medDate);
            }
            setPrescription([...prescriptionData]);
            setPrescriptionMedDate([...prescriptionDate]);
            prescriptionData = [];
            prescriptionDate = [];
        };

        //Get Lab IPD Data
        // if (labIPDFileResp.status === 1) {
        //     setLabIPDFileData(labIPDFileResp.responseValue.labIPDFileData);
        //     console.log("labIPDFileDatass", labIPDFileDatas[0])
        //     const dataOfInvestigation = JSON.parse(labIPDFileDatas[0]);
        //     console.log("dataOfInvestigation", dataOfInvestigation)
        // };

        if (labIPDFileResp.status === 1) {
            // Set labIPDFileData state
            let AllData = []
            setLabIPDFileData(labIPDFileResp.responseValue.labIPDFileData);
            for (var i = 0; i <= labIPDFileResp.responseValue.labIPDFileData.length - 1; i++) {
                let data = JSON.parse(labIPDFileResp.responseValue.labIPDFileData[i].dataOfInvestigation)
                let headerdata = JSON.parse(labIPDFileResp.responseValue.labIPDFileData[i].headerData)
                let tempArr = []
                for (var j = 0; j <= data.length - 1; j++) {
                    let data2 = JSON.parse(data[j].Investigation)
                    let tempdat2 = [...data2]
                    if (data2.length < headerdata.length) {
                        // let leftData = headerdata.length - data2.length;
                        let newArray = []
                        for (var k = 0; k <= headerdata.length - 1; k++) {
                            let findData = data2.filter(value => parseInt(value.subtestID) === parseInt(headerdata[k].subtestId))
                            if (findData.length === 0) {
                                let temp = {
                                    subtestID: k,
                                    result: "NA"
                                }
                                tempdat2 = [
                                    ...tempdat2.slice(0, k),
                                    temp,
                                    ...tempdat2.slice(k)
                                ];
                                // tempdat2.push(temp)
                            }
                        }
                        let temp = {}
                        temp.name = labIPDFileResp.responseValue.labIPDFileData[i].testname
                        temp.data = tempdat2
                        temp.headdata = headerdata
                        temp.date = data[j].resultDateTimeMain
                        tempArr.push(temp)

                    }
                    else {
                        let temp = {}
                        temp.name = labIPDFileResp.responseValue.labIPDFileData[i].testname
                        temp.data = data2
                        temp.headdata = headerdata
                        temp.date = data[j].resultDateTimeMain
                        tempArr.push(temp)
                    }
                }
                AllData.push(tempArr)

            }
            setLabIPDFileData(AllData)
        }

        // Get Intake IPD data
        if (intakeIPDFileResp.status === 1) {
            setLoader(0)
            setIntakeIPDFileData(intakeIPDFileResp.responseValue.intakeIPDFileData)
            let AllIntakeData = [];
            for (var i = 0; i < intakeIPDFileResp.responseValue.intakeIPDFileData.length; i++) {
                const data = intakeIPDFileResp.responseValue.intakeIPDFileData[i].foodAndSupplementDataMain;
                const data1 = JSON.parse(data);
                let tempIntakeData = []
                console.log("foodAndSupplementDataMain", data1)
                for (var j = 0; j < data1.length; j++) {
                    const foodAndSupplementData = data1[j].FoodAndSupplementData
                    console.log("foodAndSupplementData", foodAndSupplementData)
                }

                const tempObj = {};
                tempObj.givenDate = intakeIPDFileResp.responseValue.intakeIPDFileData[i].givenDate
                tempObj.total = intakeIPDFileResp.responseValue.intakeIPDFileData[i].totalMain
                tempObj.foodAndSupplementDataMain = data1
                tempIntakeData.push(tempObj);
                AllIntakeData.push(tempIntakeData)
            }
            setIntakeIPDFileData(AllIntakeData)
            console.log("AllIntakeData", AllIntakeData)
        }

        else {
            setLoader(0)
        }

        //  Get Output Data
        if (outputIPDFileResp.status === 1) {
            setOutPutIPDFileData(outputIPDFileResp.responseValue);
            console.log("OUTDATA", outPutIPDFileData)
            let AllOtputData = [];
            for (var i = 0; i < outputIPDFileResp.responseValue.length; i++) {
                const data = outputIPDFileResp.responseValue[i].outputDataMain;
                const dataMain = JSON.parse(data);
                console.log("outputDatamain", dataMain)
                let tempoutputData = [];
                let datas = []
                for (var j = 0; j < dataMain.length; j++) {
                    const data1 = dataMain[j].outputData;
                    console.log("yyyy", JSON.parse(data1))
                    // datas.push(JSON.parse(data1)[0])
                    datas.push(JSON.parse(data1))


                }
                const tempoutputObj = {};
                tempoutputObj.outputDate = outputIPDFileResp.responseValue[i].outputDate
                tempoutputObj.totalMain = outputIPDFileResp.responseValue[i].totalMain
                tempoutputObj.datamain = dataMain
                tempoutputObj.data = datas
                tempoutputData.push(tempoutputObj)

                AllOtputData.push(tempoutputData)
                console.log("AllOtputData", AllOtputData)
            }
            setOutPutIPDFileData(AllOtputData);
            console.log("outPutIPDFileData", AllOtputData)
        }
        else {
            setLoader(0)

        }

        // Get radiology data
        if (radiologyResponse.status === 1) {
            setPrintDataList(radiologyResponse.responseValue)
            console.log("Radiology data", printDataList)
        }

    };



    const distinctItem = (Array) => {
        const uniqueItems = new Set();
        Array.forEach((item) => {
            uniqueItems.add(item);
        });
        return uniqueItems;
    };

    //Find Unique Date for Vitals
    const uniqueDates = distinctItem(vitalsDate);

    //Find Unique Date for prescription
    const prescriptionUniqueDates = distinctItem(prescriptionMedDate);

    //Find Unique testName and SubtestName
    const uniqueTestNames = distinctItem(testName);
    const uniqueSubTestNames = distinctItem(subTestName);
    const uniqueLabDates = distinctItem(labUniqueDate);

    //////////
    let arrData = [];
    let tempObj = {};
    // for (var i = 0; i < uniqueTestNames.length; i++) {
    //     for (var j = 0; j < labIPDFileData.length; j++) {
    //         if (uniqueTestNames[i] === labIPDFileData[j].testname) {

    //         }
    //     }
    //     arrData.push()
    // }

    //Find intake unique date
    const uniqueIntakeDates = distinctItem(intakeDate);

    // let handleIPDPrint = () => {
    //     alert();
    //     window.print()
    // }

    let handleIPDPrint = () => {
        let objIpdFile = {
            vitals: vitals,
            progressNote: progressNote,
            complaintSign: complaintSign,
            patientHistory: patientHistory,
            procedureNote: procedureNote,
            physicalExamination: physicalExamination,
            goals: goals,
            prescription: prescription,
            labIPDFileData: labIPDFileDatas,
            intakeIPDFileData: intakeIPDFileData,

        }
        window.sessionStorage.setItem("ipdfileReportList", JSON.stringify(objIpdFile))

        // return;
        window.open("/printipdfile/", 'noopener,noreferrer')
    }
    // Fetch diagnosis in comma separated
    let diagnosis; // Declare diagnosis variable outside the if block

    if (patientDetails) {
        // Stringify the array
        const dd = JSON.stringify([patientDetails]);

        // Parse the string back into an object
        const tt = JSON.parse(dd);

        // Check if tt has elements and details property
        if (tt.length > 0 && tt[0].details) {
            // Parse details property
            const detailsArray = JSON.parse(tt[0].details);

            // Map details and join with comma
            diagnosis = detailsArray.map(item => item.details).join(", ");
            console.log("Diagnosis:", diagnosis);
        } else {
            console.error("Details property not found or empty in patientDetails.");
        }
    } else {
        console.error("patientDetails is undefined or null.");
    }


    function convertTo12HourFormat(time) {
        // Parse the time string to extract the hour part
        const hour = parseInt(time, 10);

        // Check if the hour is in the valid range (0-23)
        if (hour >= 0 && hour <= 23) {
            // Convert the hour to 12-hour format
            const formattedHour = (hour % 12 === 0) ? 12 : hour % 12;

            // Determine if it's AM or PM
            const period = hour < 12 ? 'AM' : 'PM';

            // Construct the formatted time string
            return formattedHour + ' ' + period;
        } else {
            return 'Invalid time format';
        }
    }

    useEffect(() => {
        setClientData(JSON.parse(window.sessionStorage.getItem("LoginData")).clientdata)
        getData();
    }, []);

    return (

        <>

            {/* <section className="main-content pt-3 mt-5">
                <div className="container-fluid">
                    
                        </div>
                        </section> */}
            <div className="row">
                <div className="col-12 hideOnprint">
                    <div className="med-box d-flex justify-content-between">
                        <div className="title">{t("IPD File")}</div>
                        <div>
                            <span className='printRestForm' onClick={handleIPDPrint}><i className="bi bi-printer-fill fs-4 pe-3 " ></i> </span>
                        </div>
                    </div>
                </div>

                <div className="col-12 mt-1">
                    <div className='card-wrapper' id='printIpdFile'>
                        {/* <div className='waterMark'>
                            <img src={logo} alt="" />
                        </div> */}

                        {/* Patient Details  */}
                        <div className='pdetailstxt'>
                            <table className='ipdtbl table-certificate'>
                                <tr>
                                    <td align='left'>
                                        {/* <img src={logo} alt=''/> */}
                                        <img src={clientData.logoUrl} alt="Company Logo"
                                            style={{ width: "165px", }} />
                                    </td>
                                    <td align='right'>
                                        <div className='era'>{clientData.clientName}<br />
                                            <span>{clientData.address}</span>
                                        </div>
                                        <div className="companyContact_ era icusht mt-0 pt-0 mb-0 pb-0">
                                            <img src={Vector} alt=""></img>&nbsp; {clientData.emailID},&nbsp;

                                            <img src={Contact} alt=""></img>&nbsp; {clientData.mobileNo}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ 'height': '2px', 'background': '#F3F7FF', 'padding': '0px' }} colSpan={2}></td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <div className="col-12">
                                            <div className='head text-center document-title'>{t("Patient_Details")}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" >
                                        <table className='pattbl' style={{ width: "1100px" }}>

                                            <>
                                                <tr >
                                                    <td style={{ width: "150px" }}><strong>{t("Patient Name")} :</strong></td>
                                                    <td>{patientDetails.patientName}</td>
                                                    <td style={{ width: "150px" }}><strong>{t("Uhid")} :</strong></td>
                                                    <td align='right' style={{ width: "150px" }}>{patientDetails.uhid}</td>
                                                </tr>
                                                <tr >
                                                    <td style={{ width: "150px" }}><strong>{t("Guardian_Nm")} :</strong></td>
                                                    <td>{patientDetails.guardianName ? patientDetails.guardianName : '-'}</td>
                                                    <td style={{ width: "150px" }}> <strong>{t("Consultant_Name")} :</strong></td>
                                                    <td align='right'>{patientDetails.name}</td>
                                                </tr>
                                                <tr >
                                                    <td style={{ width: "150px" }}><strong>{t("Age/Gender ")}:</strong></td>
                                                    <td>{patientDetails.ageGender}</td>
                                                    <td style={{ width: "150px" }}><strong>{t("IPNo")} :</strong></td>
                                                    <td align='right'>{patientDetails.ipNo}</td>
                                                </tr>
                                                <tr >
                                                    <td style={{ width: "150px" }}><strong>{t("Mobile_No")} :</strong></td>
                                                    <td>{patientDetails.mobileNo}</td>
                                                    <td style={{ width: "150px" }}><strong>{t("Admission_Date")} :</strong></td>
                                                    <td align='right'>{patientDetails.admitDateTime}</td>
                                                </tr>
                                                <tr >
                                                    <td><strong>Address :</strong></td>
                                                    <td>{patientDetails.address}</td>
                                                    <td><strong>{t("Department")} :</strong></td>
                                                    <td align='right'>{patientDetails.departmentName}</td>
                                                </tr>

                                                <tr >
                                                    <td><strong>{t("Cr No")}:</strong></td>
                                                    <td>{patientDetails.crNo}</td>
                                                    <td><strong>{t("Ward_Nm")} :</strong></td>
                                                    <td align='right'> {patientDetails.wardName}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }}>
                                                        <span className='pDiagnosis'>
                                                            <strong style={{ fontSize: '14px' }}>Provisional Diagnosis :</strong>
                                                        </span>
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>{diagnosis}</td>
                                                </tr>
                                                {/* <tr>
                                                        <td colSpan={4}>
                                                            <div className="col-12">
                                                                <div className='head1'>{t("Provisional Diagnosis")}</div>
                                                                <p className='subhead'>{patientDetails.details}</p>
                                                            </div>
                                                        </td>
                                                    </tr> */}

                                            </>




                                        </table>
                                    </td>
                                </tr>

                            </table>

                            {/* <table className='barfixe'>
                                <tr>
                                    <td align='left'>
                                        <p className='uhidtxt'>{t("Uhid")}</p>
                                        <img src={bar1} />
                                    </td>
                                    <td align='right'>
                                        <p className='uhidtxt'>{t("IPNo")}</p>
                                        <img src={bar2} />
                                    </td>
                                </tr>
                            </table> */}
                        </div>

                        {/* Complaint & symptoms */}
                        <div className='pt-3'>
                            {complaintSign.length > 0 ?
                                <div className='hcomplaintxt mb-3'>


                                    <div className='head head1'>{t("Complain/Sign & Symptoms")}</div>
                                    <table className='pattbl border grayth table-certificate'>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '3%' }}>#</th>
                                                <th style={{ width: '10%' }}>{t("Date-Time")}</th>
                                                <th>{t("Complain/Sign & Symptoms")}</th>
                                                <th style={{ width: '15%' }}>{t("Written_By")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>


                                            {complaintSign && complaintSign.map((comList, index) => {
                                                return (
                                                    <tr>
                                                        <td className='key'>{index + 1}</td>
                                                        <td>{comList.datee}</td>
                                                        <td>{comList.details}</td>
                                                        <td>{comList.name}</td>
                                                    </tr>
                                                )
                                            })
                                            }

                                        </tbody>
                                    </table>


                                </div>
                                : <></>}
                        </div>


                        {/* Progress Note */}
                        <div>
                            {progressNote.length > 0 ?
                                <div className='hcomplaintxt mb-3'>
                                    <div className='head head1'>{t("Progress Note")}</div>
                                    <table className='pattbl_ border grayth table-certificate'>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '3%' }}>#</th>
                                                <th style={{ width: '10%' }}>{t("Date-Time")}</th>
                                                <th>{t("Progress Note")}</th>
                                                <th style={{ width: '15%' }}>{t("Written_By")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {progressNote && progressNote.map((progList, index) => {
                                                return (
                                                    <tr>
                                                        <td className='key'>{index + 1}</td>
                                                        <td>{progList.datee}</td>
                                                        <td>
                                                            <div dangerouslySetInnerHTML={{ __html: progList.details }} />
                                                        </td>
                                                        <td>{progList.name ? progList.name : '-'}</td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                : <></>}
                        </div>



                        {/* Patient History */}
                        <div>{patientHistory.length > 0 ?
                            <div className='hcomplaintxt mb-3'>
                                <div className='head head1'>{t("Patient History")}</div>
                                <table className='pattbl border grayth table-certificate'>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '3%' }}>#</th>
                                            <th style={{ width: '10%' }}>{t("Date-Time")}</th>
                                            <th>{t("Patient History")}</th>
                                            <th style={{ width: '15%' }}>{t("Written_By")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {patientHistory && patientHistory.map((PatHisList, index) => {
                                            return (
                                                <tr>
                                                    <td className='key'>{index + 1}</td>
                                                    <td>{PatHisList.datee}</td>
                                                    <td>
                                                        <div dangerouslySetInnerHTML={{ __html: PatHisList.details }} />
                                                    </td>
                                                    <td>{PatHisList.name ? PatHisList.name : '-'}</td>
                                                </tr>
                                            )
                                        })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            : <></>}</div>

                        {/* Prescription */}
                        <div>{prescription.length > 0 ? <div className='hcomplaintxt mb-3'>
                            <div className='head1' style={{ marginBottom: '0px' }}>{t("Prescribed Medicine")}</div>
                            <table className='pattbl grayth table-certificate border border-bottom_ striped'>
                                {prescriptionUniqueDates && [...prescriptionUniqueDates].map((medDateList, index) => {
                                    return (
                                        <>
                                            <thead>
                                                <tr>
                                                    <th colSpan={7}>
                                                        <div className='listDate'>{t("DATE")}: {medDateList}</div>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>{t("Dosage Form")}</th>
                                                    <th>{t("Medicine")}</th>
                                                    <th>{t("Strength")}</th>
                                                    <th>{t("Frequency")}</th>
                                                    <th>{t("Time")}</th>
                                                    <th>{t("Duration")}</th>
                                                    <th>{t("Remark")}</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {prescription && prescription.map((medList, index) => {
                                                    if (medDateList === medList.medDate) {
                                                        return (
                                                            <tr>
                                                                <td>{medList.dosageForm}</td>
                                                                <td>{medList.drugName}</td>
                                                                <td>{medList.dosageStrength}</td>
                                                                <td>{medList.doseFrequency}</td>
                                                                <td>{medList.medTime}</td>
                                                                <td>{medList.duration}</td>
                                                                <td>{medList.remark}</td>
                                                            </tr>
                                                        )
                                                    }

                                                })}

                                            </tbody>
                                        </>
                                    )
                                })}

                            </table>
                        </div> : <></>}</div>
                        {/* Vitals */}
                        <div>{vitals.length > 0 ?
                            <div className='hcomplaintxt mb-3'>
                                <div className='head1' style={{ marginBottom: '0px' }}>{t("Vitals Reports")}</div>
                                <table className='pattbl grayth table-certificate border border-bottom_ striped'>
                                    {uniqueDates && [...uniqueDates].map((dateList, index) => {
                                        return (
                                            <>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={25}>
                                                            <div className='listDate'>{t("DATE")}: {dateList}</div>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>{t("Vitals")}</th>
                                                        <th>12 AM</th>
                                                        <th>01 AM</th>
                                                        <th>02 AM</th>
                                                        <th>03 AM</th>
                                                        <th>04 AM</th>
                                                        <th>05 AM</th>
                                                        <th>06 AM</th>
                                                        <th>07 AM</th>
                                                        <th>08 AM</th>
                                                        <th>09 AM</th>
                                                        <th>10 AM</th>
                                                        <th>11 AM</th>
                                                        <th>12 PM</th>
                                                        <th>01 PM</th>
                                                        <th>02 PM</th>
                                                        <th>03 PM</th>
                                                        <th>04 PM</th>
                                                        <th>05 PM</th>
                                                        <th>06 PM</th>
                                                        <th>07 PM</th>
                                                        <th>08 PM</th>
                                                        <th>09 PM</th>
                                                        <th>10 PM</th>
                                                        <th>11 PM</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {vitals && vitals.map((vitalsList, index) => {
                                                        if (dateList === vitalsList.datee) {
                                                            return (
                                                                <tr>
                                                                    <td>{vitalsList.vitalName}</td>
                                                                    <td>{vitalsList.aM12}</td>
                                                                    <td>{vitalsList.aM01}</td>
                                                                    <td>{vitalsList.aM02}</td>
                                                                    <td>{vitalsList.aM03}</td>
                                                                    <td>{vitalsList.aM04}</td>
                                                                    <td>{vitalsList.aM05}</td>
                                                                    <td>{vitalsList.aM06}</td>
                                                                    <td>{vitalsList.aM07}</td>
                                                                    <td>{vitalsList.aM08}</td>
                                                                    <td>{vitalsList.aM09}</td>
                                                                    <td>{vitalsList.aM10}</td>
                                                                    <td>{vitalsList.aM11}</td>
                                                                    <td>{vitalsList.pM12}</td>
                                                                    <td>{vitalsList.pM01}</td>
                                                                    <td>{vitalsList.pM02}</td>
                                                                    <td>{vitalsList.pM03}</td>
                                                                    <td>{vitalsList.pM04}</td>
                                                                    <td>{vitalsList.pM05}</td>
                                                                    <td>{vitalsList.pM06}</td>
                                                                    <td>{vitalsList.pM07}</td>
                                                                    <td>{vitalsList.pM08}</td>
                                                                    <td>{vitalsList.pM09}</td>
                                                                    <td>{vitalsList.pM10}</td>
                                                                    <td>{vitalsList.pM11}</td>
                                                                </tr>
                                                            )
                                                        }

                                                    })}

                                                </tbody>
                                            </>
                                        )
                                    })}

                                </table>
                            </div>
                            : <></>}</div>
                        {/* Procedure Note */}
                        <div>{procedureNote.length > 0 ?
                            <div className='hcomplaintxt mb-3'>
                                <div className='head head1'>{t("Procedure Note")}</div>
                                <table className='pattbl border grayth table-certificate'>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '3%' }}>#</th>
                                            <th style={{ width: '10%' }}>{t("Date-Time")}</th>
                                            <th>{t("Procedure Note")}</th>
                                            <th style={{ width: '15%' }}>{t("Written_By")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {procedureNote && procedureNote.map((procList, index) => {
                                            return (
                                                <tr>
                                                    <td className='key'>{index + 1}</td>
                                                    <td>{procList.datee}</td>
                                                    <td>{procList.details}</td>
                                                    <td>{procList.name ? procList.name : '-'}</td>
                                                </tr>
                                            )
                                        })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            : <></>}</div>

                        {/* Investigation */}
                        <div>{labIPDFileDatas && labIPDFileDatas.length > 0 ?
                            <div className='hcomplaintxt mb-3'>
                                <div className='head head2 border' style={{ color: '#1d4999', background: '#d3d3d3' }}>{t("Investigation Report")}</div>
                                {labIPDFileDatas && labIPDFileDatas.map((list) => {
                                    return (
                                        <div className='tttt'>
                                            <div className='head head1'>{list[0].name}
                                            </div>
                                            <table className='pattbl border grayth table-certificate'>
                                                <thead>
                                                    <tr>
                                                        <th style={{ fontSize: '10px', width: '135px' }}>{t("Date-Time")}</th>
                                                        {
                                                            list[0].headdata.map((subtest) => (
                                                                <th style={{ fontSize: '11px', textAlign: 'center' }}>{subtest.SubtestName}</th>
                                                            ))
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {list && list.map((sublist, index) => {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td className='key'>{sublist.date}</td>
                                                                    {
                                                                        sublist.data.map((data) => {
                                                                            return (
                                                                                <td key={data.subtestID} style={{ fontSize: '10px', textAlign: 'center' }}>
                                                                                    {data.result}
                                                                                </td>
                                                                            );
                                                                        })
                                                                    }
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })}


                            </div>
                            : <></>}</div>

                        {/* Diet Intake */}
                        <div>{intakeIPDFileData.length > 0 ?
                            <div className='hcomplaintxt mb-3'>
                                <div className='head1' style={{ marginBottom: '0px' }}>{t("Intake")}</div>
                                <table className='pattbl grayth table-certificate border striped'>
                                    {intakeIPDFileData && intakeIPDFileData.map((list, index) => {
                                        return (
                                            <>
                                                <>
                                                    <tr>
                                                        <td colspan={3} style={{ background: "aliceblue", borderTop: 'none', borderLeft: 'none' }}><div className='listDate'>{t("DATE")}: {list[0].givenDate}</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td className='brdnone'>
                                                            <table className='pattbl grayth table-certificate striped'>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ background: "aliceblue", borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}><div className='listDate'>{t("Time")}</div></td>
                                                                    </tr>
                                                                    {

                                                                        list[0].foodAndSupplementDataMain.map((data) => (
                                                                            <tr>
                                                                                <td style={{ borderBottom: 'none', borderRight: 'none', borderLeft: 'none' }}>{convertTo12HourFormat(data.HourTotal)} </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td className='brdnone'>
                                                            <table className='pattbl grayth table-certificate striped'>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ background: "aliceblue", borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}><div className='listDate'>{t("Input Type")}</div></td>
                                                                    </tr>
                                                                    {list[0].foodAndSupplementDataMain.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td style={{ borderBottom: 'none', borderLeft: 'none', borderRight: 'none' }}>
                                                                                {item.FoodAndSupplementData.map((foodData, foodIndex) => (
                                                                                    <React.Fragment key={foodIndex}>
                                                                                        <span style={{ color: foodData.TypeCategory === 'Supplement' ? 'Red' : '#00af00' }}>
                                                                                            {foodData.FoodName} {foodData.GivenQuantity}
                                                                                            {foodData.GivenDoseUnitName && ` ${foodData.GivenDoseUnitName}`}
                                                                                        </span>
                                                                                        {foodIndex < item.FoodAndSupplementData.length - 1 && ', '}
                                                                                    </React.Fragment>
                                                                                ))}
                                                                            </td>
                                                                        </tr>
                                                                    ))}

                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td className='brdnone'>
                                                            <table className='pattbl grayth table-certificate striped'>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ background: "aliceblue", borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}><div className='listDate'>{t("Quantity")} ({t("ml")})</div></td>
                                                                    </tr>
                                                                    {
                                                                        list[0].foodAndSupplementDataMain.map((data) => (
                                                                            <tr>
                                                                                <td style={{ borderBottom: 'none', borderLeft: 'none', borderRight: 'none' }}>{data.Total} </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </td>

                                                    </tr>
                                                    <tr className='trhover'>
                                                        <td colSpan={2}><strong>{t("Total")}</strong></td>
                                                        {
                                                            list.map((data) => (
                                                                <td><strong>{data.total} ml</strong></td>
                                                            ))
                                                        }
                                                    </tr>
                                                </>
                                            </>
                                        )
                                    })}

                                </table>
                            </div>
                            : <></>}</div>
                        {/* Diet Output */}
                        <div>{outPutIPDFileData.length > 0 ?
                            <div className='hcomplaintxt mb-3'>
                                <div className='head1' style={{ marginBottom: '0px' }}>{t("Output")}</div>
                                <table className='pattbl grayth table-certificate border striped'>
                                    {outPutIPDFileData && outPutIPDFileData.map((list, index) => {
                                        return (
                                            <>
                                                <>
                                                    <tr>
                                                        <td colspan={3} style={{ background: "aliceblue", borderTop: 'none', borderLeft: 'none' }}><div className='listDate'>{t("DATE")}: {list[0].outputDate}</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td className='brdnone'>
                                                            <table className='pattbl grayth table-certificate striped'>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ background: "aliceblue", borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}><div className='listDate'>{t("Time")}</div></td>
                                                                    </tr>
                                                                    {

                                                                        list[0].datamain.map((data) => (
                                                                            <tr>
                                                                                <td style={{ borderBottom: 'none', borderRight: 'none', borderLeft: 'none' }}>{convertTo12HourFormat(data.outputHour)} </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td className='brdnone'>
                                                            <table className='pattbl grayth table-certificate striped'>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ background: "aliceblue", borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}><div className='listDate'>{t("Output Type")}</div></td>
                                                                    </tr>
                                                                    {list[0].data.map((item, index) => (
                                                                        <tr>
                                                                            <td style={{ borderBottom: 'none', borderLeft: 'none', borderRight: 'none' }}>
                                                                                {
                                                                                    item.map((ttt, ind) => (
                                                                                        <>
                                                                                            {ttt.outputName} {'(' + ttt.quantity + ' ' + ttt.unitName + ') '}
                                                                                            {ind < item.length - 1 && ', '}
                                                                                        </>
                                                                                    ))}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td className='brdnone'>
                                                            <table className='pattbl grayth table-certificate striped'>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ background: "aliceblue", borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}><div className='listDate'>{t("Quantity")} ({t("ml")})</div></td>
                                                                    </tr>
                                                                    {
                                                                        list[0].datamain.map((data) => (
                                                                            <tr>
                                                                                <td style={{ borderBottom: 'none', borderLeft: 'none', borderRight: 'none' }}>{data.total} </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </td>

                                                    </tr>
                                                    <tr className='trhover'>
                                                        <td colSpan={2}><strong>{t("Total")}</strong></td>
                                                        {
                                                            list.map((data) => (
                                                                <td><strong>{data.totalMain} ml</strong></td>
                                                            ))
                                                        }
                                                    </tr>
                                                </>
                                            </>
                                        )
                                    })}

                                </table>
                            </div>
                            : <></>}</div>

                        {/* Physical Examination */}
                        <div>{physicalExamination.length > 0 ?
                            <div className='hcomplaintxt mb-3'>
                                <div className='head head1'>{t("Physical Examination")}</div>
                                <table className='pattbl border grayth table-certificate'>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '3%' }}>#</th>
                                            <th style={{ width: '10%' }}>{t("Date-Time")}</th>
                                            <th>{t("Physical Examination")}</th>
                                            <th style={{ width: '15%' }}>{t("Written_By")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {physicalExamination && physicalExamination.map((phyList, index) => {
                                            return (
                                                <tr>
                                                    <td className='key'>{index + 1}</td>
                                                    <td>{phyList.datee}</td>
                                                    <td>{phyList.details}</td>
                                                    <td>{phyList.name ? phyList.name : '-'}</td>
                                                </tr>
                                            )
                                        })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            : <></>}</div>

                        {/* Goals */}
                        <div>{goals.length > 0 ?
                            <div className='hcomplaintxt mb-3'>
                                <div className='head head1'>{t("Goals")}</div>
                                <table className='pattbl border grayth table-certificate'>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '3%' }}>#</th>
                                            <th style={{ width: '10%' }}>{t("Date-Time")}</th>
                                            <th>{t("Goals")}</th>
                                            <th style={{ width: '15%' }}>{t("Written_By")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {goals && goals.map((GoalsList, index) => {
                                            return (
                                                <tr>
                                                    <td className='key'>{index + 1}</td>
                                                    <td>{GoalsList.datee}</td>
                                                    <td>
                                                        <div dangerouslySetInnerHTML={{ __html: GoalsList.details }} />
                                                    </td>
                                                    <td>{GoalsList.name ? GoalsList.name : '-'}</td>

                                                </tr>
                                            )
                                        })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            : <></>}</div>




                        {/* LAB */}
                        {/* <div>{labIPDFileData.length > 0 ?
                            <div className='hcomplaintxt mb-3'>
                                <div className='head1' style={{ marginBottom: '0px' }}>{t("Investigation Report")}</div>
                                <table className='pattbl grayth table-certificate border border-bottom_ striped'>
                                    {uniqueTestNames && [...uniqueTestNames].map((testList, index) => {
                                        return (
                                            <>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={20}>
                                                            <div className='listDate'>{testList}</div>
                                                        </th>
                                                    </tr>

                                                    <tr>
                                                        <th style={{ width: '6%' }}>Date</th>
                                                        {labIPDFileData && labIPDFileData.map((labHeadList, index) => {
                                                            if (labHeadList.testname === testList) {
                                                                return (
                                                                    <th>{labHeadList.subTestName}</th>
                                                                )
                                                            }
                                                        })

                                                        }
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {uniqueLabDates && [...uniqueLabDates].map((uniqueDate, i) => {
                                                        return (
                                                            <tr>
                                                                <td>{uniqueDate}</td>
                                                                {labIPDFileData && labIPDFileData.map((labBodyList, index) => {
                                                                    if (testList === labBodyList.testname) {
                                                                        return (
                                                                            <>
                                                                                {uniqueSubTestNames && [...uniqueSubTestNames].map((subTestName, ind) => {
                                                                                    if (subTestName === labBodyList.subTestName && uniqueDate === labBodyList.collectionDateTime && labBodyList.result.length !== 0) {
                                                                                        return (
                                                                                            <td>{labBodyList.result}</td>
                                                                                        )
                                                                                    }
                                                                                    else if (subTestName !== labBodyList.subTestName && uniqueDate !== labBodyList.collectionDateTime && ind === 1) {
                                                                                        return (
                                                                                            <td>-</td>
                                                                                        )
                                                                                    }


                                                                                }

                                                                                )}
                                                                            </>
                                                                        )
                                                                    }
                                                                })}
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr>
                                                    </tr>
                                                </tbody>
                                            </>
                                        )
                                    })}

                                </table>
                            </div>
                            : <></>}</div> */}




                    </div>
                </div>

                {/* RadioLogyReport */}
                <div className='col-md-12'>
                    <div className='card-wrapper'>
                        <div className='head head2 border ps-1' style={{ color: '#1d4999', background: '#d3d3d3' }}>{t("Radiology Report")}</div>
                        <div className='hcomplaintxt1 mb-3'>
                            {printDataList && printDataList.map((list, i) => {
                                console.log("list", list)
                                return (
                                    <>
                                        <div className="col-12 ">
                                            <div className='card-wrapper_' id='printUltraSoundReport' style={{ border: '1px solid #00000014' }}>
                                                <table className="tableHeaderFooter">
                                                    <tbody>
                                                        <tr>
                                                            <td className='p-0'>
                                                                <div className='pat-dtls fontbld'>
                                                                    {JSON.parse(list.radiologydata).map((val, ind) => {
                                                                        console.log("radiologydata", val)
                                                                        return (
                                                                            <>
                                                                                <div className='head1 mt-2_'>{val.TestName}</div>
                                                                                <div className="mt-2 px-1 " style={{ display: 'flex', gap: '10px', fontSize: '12px', marginTop: '10px' }}>
                                                                                    <strong>Testname:</strong>{val.TestName} <strong>Date:</strong>{list.testDate}
                                                                                </div>
                                                                                {val.OrganParameterModel.map((tt, ind) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div className="mt-2 px-1 mb-1" style={{ background: '#f0f8ff' }}><strong className='organClr'>{tt.OrganName}</strong></div>
                                                                                            <div className="d-flex gap-3 flex-wrap px-1" style={{ fontSize: '12px' }}>
                                                                                                {JSON.parse(tt.Parameter).map((li) => {
                                                                                                    return (<div><b>{li.ParameterName} :</b> {li.ParameterValue}&nbsp;{li.ParameterUnitName}</div>)
                                                                                                })}
                                                                                            </div>
                                                                                            <div className='px-1' style={{ display: 'flex', gap: '10px', fontSize: '12px', marginTop: '10px' }}><b>REMARK:</b> <i>{tt.Remark}</i>  </div>
                                                                                            {/* <div style={{ fontSize: '40px' }} dangerouslySetInnerHTML={{ __html: PatHisList.details }} /> */}
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                                <div className='px-1' style={{ display: 'flex', gap: '10px', fontSize: '12px', marginTop: '10px' }}><b>Impression:</b> <i dangerouslySetInnerHTML={{ __html: val.Impression }}></i>  </div>

                                                                            </>
                                                                        )
                                                                    })}
                                                                    {/* <div className="mt-2" style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}><b className="fs-5 fw-bold">IMPRESSION:</b><span style={{ marginTop: '5px' }} id={"txtImpression" + list.testId + '' + list.testresultID}></span></div> */}
                                                                    {/* <div style={{ display: 'flex', gap: '10px', fontSize: '12px' }}> <b>NOTE:</b> <i style={{ color: 'red' }}>MILDLY ENLARGED FATTY LIVER (GRADE 1). LEFT SIDED SMALL RENAL CONCRETION. LOW LEVEL ECHOES IN URINARY BLADDER-CYSTITIS</i>  </div> */}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>



                                                </table>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>




            {/* </div> */}
            {
                loader === 1 ? <Loader val={loader} /> : ""
            }
        </>
    )
}
