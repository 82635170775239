import React from 'react'
import printuse from '../../../src/assets/css/printuse.css'
import bloodcleaningstructure from '../../../src/assets/images/icons/bloodcleaningstructure.png'
// import SarvodayaLogo from '../../../assets/images/icons/sarvodayalogo.png'

export default function InformedConsentForHaemodialysis() {
    return (
        <>
            <div className="main-table" style={{ padding: '10px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <table className='coronarytble'>
                    {/* ....header.. */}
                    <tr>
                        <td colSpan={8}>
                            <div className="sarvodya-header">
                                <div className='lft-header mb-3'>
                                    {/* <img alt='' className='sv-log'/>     */}
                                </div>
                                <div className='rght-header'>
                                    <div> SARVODAYA HOSPITAL <div className='addrs'>SECTOR-8, FARIDABAD | HELPLINE: 1800 313 1414</div></div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td colSpan={8}>
                            <div className='heading-form mt-2 mb-2'>
                                INFORMED CONSENT FOR HAEMODIALYSIS
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8} >
                            <table className='tbl-bordr mt-0' style={{ border: '1px solid black' }}>
                                <tr>
                                    <td colSpan={2}><div className='assesmnt mt-2 mb-2'>Date of Consent:  <input type="text" id='Date_of_Consent' /> </div></td>
                                    <td rowSpan={4} style={{ borderLeft: '1px solid black', textAlign: 'center' }}>Patients Label</td>
                                </tr>
                                <tr>
                                    <td ><div className='assesmnt mt-2 mb-2'>Time of Consent: <input type="text" id='Time_of_Consent' /> </div></td>
                                    <td ><div className='assesmnt mt-2 mb-2'>Ward.: <input type="text" id='Ward_of_Consent' /></div></td>
                                </tr>
                                <tr>
                                    <td ><div className='assesmnt mt-2 mb-2'>Diagnosis:  <input type="text" id='Diagnosis_of_Consent' /> </div></td>
                                    <td ><div className='assesmnt mt-2 mb-2'>Procedure:  <input type="text" id='Procedure_of_Consent' />  </div></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-2'>
                                You will be asked to sign this form each time you come for Dialysis within this month, following which a fresh farm shall be initiated every month.
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='parainfo mt-2 mb-2'><strong>I</strong>, &nbsp;<span style={{ width: '390px', borderBottom: '1px dashed black', display: 'inline-block' }}>
                                <strong> sabi</strong></span>the patient or Representative
                            </div>
                            <div className='parainfo mt-2 mb-2 ps-0 pe-0'> <span style={{ width: '390px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span>
                                have (please tick the correct option above and below)
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='parainfo2 mt-2 mb-1'>
                                <div> <input type="checkbox" /> </div>
                                <div>  <label> Read </label></div>
                            </div>
                            <div className='parainfo2'>
                                <div> <input type="checkbox" /> </div>
                                <div><label> Been explained this consent form , <span style={{ width: '160px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span> in (name of language) which I fully
                                    understand and understood the information provided about DIALYSIS in this consent form. </label></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <div className='paragraphinfo mt-3'>
                                I am aware that Dialysis is a treatment procedure for kidney failure wherein blood is filtered through a dialysis membrane and reintroduced into the body. Per se, it is not a curative treatment.
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={8} className='ps-0 pe-0'>
                            <table className='maintbl mt-0 '>
                                <tr>
                                    <td colSpan={8}>
                                        <div className='paragraphinfo mt-3'>
                                            I am now aware of the intended benefits, possible risks & complications, and available alternatives to Dialysis given below. I am also aware that results of Dialysis can vary from patient to patient; and I declare that no guarantees have been made to me regarding success of this procedure, I am aware that while majority of patients have an uneventful Haemodialysis session, some cases may sometimes develop complications.
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} style={{ verticalAlign: 'top' }}>
                                        <div className='infobreif'>
                                            <strong>Brief description of the procedure</strong> Haemodialysis (Intermittent), CRRT and SLED are similar procedures and use the same principle (membrane filteration). At the start of the session, needles are inserted into the AV fistula/graft and taped into place, In some patients, an existing vascular access port (e.g. Internal Jugular/Subclavian vein) may be used to connect the dialysis machine instead of an AV fistula/graft. Blood is slowly removed and transferred through a tube to a dialysis machine which is made up of a series of membranes. These membranes filter waste products from blood, which are passed into the dialysate fluid. The 'dirty' dialysate fluid is pumped out of the dialyser and the 'clean' blood is passed back into the body through the second needle. The needles are removed at the end of the session. A dialysis session takes around four hours to complete because blood needs to be removed, filtered and replaced slowly. In CAPO, tube is inserted into the abdominal cavity and the peritoneum is used as the filtering membrane.
                                        </div>
                                    </td>
                                    <td colSpan={4}> <img src={bloodcleaningstructure} /> </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>

            </div>
        </>
    )
}
