import React, { useEffect, useState } from 'react'


import editbtn from '../../../../../assets/images/icons/editbtn.svg'
import delbtn from '../../../../../assets/images/icons/delbtn.svg'

import spo2 from '../../../../../assets/images/icons/spo2.svg'
import BP from '../../../../../assets/images/icons/BP.svg'
import PR from '../../../../../assets/images/icons/PR.svg'
import RR from '../../../../../assets/images/icons/RR.svg'
import weight from '../../../../../assets/images/icons/weight.svg'
import height from '../../../../../assets/images/icons/height.svg'
import temperature from '../../../../../assets/images/icons/temperature.svg'
import time from '../../../../../assets/images/icons/time.svg'
import takenby from '../../../../../assets/images/icons/takenby.svg'
import action from '../../../../../assets/images/icons/action.svg'
import save from '../../../../../assets/images/icons/save.svg'
import clear from '../../../../../assets/images/icons/clear.svg'
import GetData from '../../../../../assets/images/icons/GetData.svg'
import microphone from '../../../../../assets/images/icons/microphone.svg'

import BPSystolic from '../../../../../assets/images/vitalsicons/BPSystolic.svg'
import bp from '../../../../../assets/images/vitalsicons/bp.svg'
import temprature from '../../../../../assets/images/vitalsicons/temprature.svg'
import lungs from '../../../../../assets/images/vitalsicons/lungs.svg'
import heart from '../../../../../assets/images/vitalsicons/heart.svg'
import pulse from '../../../../../assets/images/vitalsicons/pulse.svg'
import spo from '../../../../../assets/images/vitalsicons/spo.svg'
import height1 from '../../../../../assets/images/vitalsicons/height1.svg'
import weight1 from '../../../../../assets/images/vitalsicons/weight1.svg'
import rbs from '../../../../../assets/images/vitalsicons/rbs.svg'
import heart2 from '../../../../../assets/images/vitalsicons/heart2.svg'
import plat from '../../../../../assets/images/vitalsicons/plat.svg'
import liver from '../../../../../assets/images/vitalsicons/liver.svg'
import fungus from '../../../../../assets/images/vitalsicons/fungus.svg'

import Pallor from '../../../../../assets/images/vitalsicons/Pallor.svg'
import Icterus from '../../../../../assets/images/vitalsicons/Icterus.svg'
import Cyanosis from '../../../../../assets/images/vitalsicons/Cyanosis.svg'
import Clubbing from '../../../../../assets/images/vitalsicons/Clubbing.svg'
import Lymphadenopathy from '../../../../../assets/images/vitalsicons/Lymphadenopathy.svg'
import Skin from '../../../../../assets/images/vitalsicons/Skin.svg'
import Tongue from '../../../../../assets/images/vitalsicons/Tongue.svg'
import Throat from '../../../../../assets/images/vitalsicons/Throat.svg'
import Conjunctivae from '../../../../../assets/images/vitalsicons/Conjunctivae.svg'
import Pupils from '../../../../../assets/images/vitalsicons/Pupils.svg'
import Nails from '../../../../../assets/images/vitalsicons/Nails.svg'
import IdentificationMarks from '../../../../../assets/images/vitalsicons/IdentificationMarks.svg'

import Lips from '../../../../../assets/images/vitalsicons/Lips.svg'
import Teeth from '../../../../../assets/images/vitalsicons/Teeth.svg'
import Gums from '../../../../../assets/images/vitalsicons/Gums.svg'

import lungs1 from '../../../../../assets/images/vitalsicons/lungs1.svg'
import PeripheralPulses from '../../../../../assets/images/vitalsicons/PeripheralPulses.svg'
import PAP from '../../../../../assets/images/vitalsicons/PAP.svg'
import CI from '../../../../../assets/images/vitalsicons/CI.svg'
import SVR from '../../../../../assets/images/vitalsicons/SVR.svg'
import PVR from '../../../../../assets/images/vitalsicons/PVR.svg'
import SvO2 from '../../../../../assets/images/vitalsicons/SvO2.svg'


import Deformity from '../../../../../assets/images/vitalsicons/Deformity.svg'
import LIMB from '../../../../../assets/images/vitalsicons/LIMB.svg'

import FIO2 from '../../../../../assets/images/vitalsicons/FIO2.svg'
import PEEP from '../../../../../assets/images/vitalsicons/PEEP.svg'
import BoxContainer from '../../../../../Component/BoxContainer'
import Heading from '../../../../../Component/Heading'
import POSTInsertPatientVital from '../../../../API/OPD/Vitals/POSTInsertPatientVital'
import TosterUnderProcess from '../../../../../Component/TosterUnderProcess'
import Toster from '../../../../../Component/Toster'

import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import Loder from '../../../../../Component/Loader'
import OPDVRight from '../../../OPD/OPDSharePage/OPDVital/OPDVRight'
import GetAllPatientVitalsHourly from '../../../../API/OPD/Vitals/GetAllPatientVitalsHourly'
import InsertPatientVitalRange from './ICUChartAPi/InsertPatientVitalRange'
import VitalMaster from './ICUChartAPi/VitalMaster'
import DropdownWithSearch from '../../../../../Component/DropdownWithSearch'
import { FailedToNegotiateWithServerError } from '@microsoft/signalr/dist/esm/Errors'
import IconVitals from '../../../../../assets/images/icons/IconVitals.svg';
import IconIcuAdd from '../../../../../assets/images/icons/IconIcuAdd.svg';
import IconICUSetting from '../../../../../assets/images/icons/IconICUSetting.svg';
import GetAllPatientVitalByDate from '../../../../API/OPD/Vitals/GetAllPatientVitalByDate'




export default function ICUVitals(props) {
    const { t } = useTranslation();
    document.body.dir = i18n.dir();

    let [sendForm, setSendForm] = useState({}) //Chnage from  useState("") to useState({}) coz string data type state not accepting object value (11/29/23)
    let [showUnderProcess, setShowUnderProcess] = useState(0)
    let [showToster, setShowToster] = useState(0)
    let [tosterMessage, setTosterMessage] = useState("")
    let [tosterValue, setTosterValue] = useState(0)
    const [currentTime, setCurrentTime] = useState(new Date());
    let [peepVal, setPeepVal] = useState('')
    let [fiotow, setFiotow] = useState('')
    let [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
    const [showModal, setShowModal] = useState(false);
    const [showRangeModal, setShowRangeModal] = useState(false);
    let [patientViatlsData, setPatientViatlsData] = useState([]);
    let [vitalsDate, setVitalsDate] = useState([]);
    let [respLen, setRespLen] = useState(0);
    const [loader, setLoader] = useState(1);
    let vitalImg = [{ icon: BP, name: "BP" }, { icon: RR, name: "RR" }, { icon: spo2, name: "spo2" }, { icon: PR, name: "PR" }, { icon: temperature, name: "Temp." }, { icon: heart, name: 'HR' }, { icon: BP, name: "RBS" }, { icon: height, name: "Ht." }, { icon: weight, name: "Wt." }]
    let uhid = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : ""
    const [getPmid, setPmid] = useState(JSON.parse(window.sessionStorage.getItem("IPDpatientList"))[0].pmId);
    const [sendRangeForm, setRangeForm] = useState({ vitalId: '', minRange: '', maxRange: '', remark: '' });
    //const [sendRangeForm, setRangeForm] = useState({ userId: window.userId, vitalMasterId: '', remark: '', minRange: '', maxRange: '', });
    const [vitalMasterList, setVitalMasterList] = useState([]);
    // const [selectedItem, setSelectedItem] = useState('');
    const [remark, setRemark] = useState('');
    const [minRange, setMinRange] = useState('');
    const [maxRange, setMaxRange] = useState('');
    let [updateBool, setUpdateBool] = useState(0)
    const [clearDropdown, setClearDropdown] = useState(0)
    // if (props.callingpage === 0) {
    //     uhid = window.sessionStorage.getItem("activePatient") ? JSON.parse(window.sessionStorage.getItem("activePatient")).Uhid : ""
    // }
    // else if (props.callingpage === 1) {
    //     uhid = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : ""
    // }
    let [ventilatorList, setVentilatorList] = useState([]);
    let [rowID, setRowID] = useState('');

    let curdate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        console.log('current date', formattedDate)
        return formattedDate;
    };

    let getVitalDatas = async () => {
        // let response = await GetAllPatientVitalsHourly(curdate(), uhid);
        let response = await GetAllPatientVitalByDate(curdate(), uhid);
        console.log('getVitals data', response)
        if (response.status === 1 && response.responseValue.length !== 0) {
            setLoader(0);
            setPatientViatlsData(response.responseValue);
            makeData(response.responseValue)
            //props.setGetData(0)
            // setVitalsDate(response.responseValue[0].date);
            // setRespLen(response.responseValue.length);
        }
        else {
            setLoader(0);
        }
    }
    const getVitalMaster = async () => {
        const VitalMasterResp = await VitalMaster();
        if (VitalMasterResp.status === 1) {
            setVitalMasterList(VitalMasterResp.responseValue);
        }
    };

    let [testVitals, setTestVitals] = useState()
    // let [vitalNumber, setVitalNumber] = useState([4, 7, 56, 3, 5, 10, 1, 2, 6])
    let [vitalNumber, setVitalNumber] = useState([4, 7, 56, 3, 5, 74, 10, 6,])
    let makeData = (data) => {

        let temp = [...data]
        let main = []
        let flag = -1

        
        let bp_sys = {}
        let bp_dias = {}
        data.map((val, ind) => {
            if (val.vmId === 4 || val.vmId === 6) {
                if (val.vmId === 4) {
                    bp_sys = val
                }
                else {
                    bp_dias = val
                }
            }
            else if (val.vmId === 74) {
                temp[ind].icon = heart
            }
            else if (val.vmId === 3) {
                temp[ind].icon = pulse
            }
            else if (val.vmId === 7) {
                temp[ind].icon = lungs
            }
            else if (val.vmId === 56) {
                temp[ind].icon = spo
            }
            else if (val.vmId === 5) {
                temp[ind].icon = rbs
            }
            else if (val.vmId === 10) {
                temp[ind].icon = temprature
            }
            // else {

            // }
            // console.log("val", val, ind)

            // vitalNumber.map((vv, ii) => {

            //     // let resp = FindByQuery(JSON.parse(val.json), vv.toString(), "vmId")
            //     let resp = JSON.parse(val.json).filter(vals => vals.vmId.toString() === vv.toString())
            //     // console.log("data", resp, val)
            //     if (resp.length !== 0) {
            //         // console.log("BP=>"+ ii, resp[0].vmId)
            //         if (resp.length !== 0 && resp[0].vmId !== 6 && resp[0].vmId !== 4) {

            //             temp.push(resp[0].vmValue)

            //         }
            //         else if (resp.length !== 0 && resp[0].vmId === 4) {
            //             if (flag !== -1) {
            //                 temp[flag] = temp[flag] + resp[0].vmValue.toString()
            //             }
            //             else {
            //                 temp.push(resp[0].vmValue)
            //                 flag = temp.length - 1
            //             }
            //         }
            //         else if (resp.length !== 0 && resp[0].vmId === 6) {
            //             if (flag !== -1) {
            //                 temp[flag] = temp[flag] + "/" + resp[0].vmValue.toString()
            //             }
            //             else {
            //                 temp.push(resp[0].vmValue)
            //                 flag = temp.length - 1
            //             }
            //         }
            //     }
            //     else {
            //         temp.push("-")

            //     }

            // })
            // // console.log("main", main)
            // main.push(temp)
            // temp = []
            // flag = -1

        })
        let makebp = {}
        setTimeout(() => {
            if (bp_sys.length !== 0) {
                makebp["icon"] = bp
                makebp["vitalName"] = "BP_Sys/Dias"
                makebp["vmId"] = [4, 6]
                makebp["datee"] = bp_sys.datee
                makebp["12AM"] = bp_sys["12AM"] + "/" + bp_dias["12AM"]
                makebp["01AM"] = bp_sys["01AM"] + "/" + bp_dias["01AM"]
                makebp["02AM"] = bp_sys["02AM"] + "/" + bp_dias["02AM"]
                makebp["03AM"] = bp_sys["03AM"] + "/" + bp_dias["03AM"]
                makebp["04AM"] = bp_sys["04AM"] + "/" + bp_dias["04AM"]
                makebp["05AM"] = bp_sys["05AM"] + "/" + bp_dias["05AM"]
                makebp["06AM"] = bp_sys["06AM"] + "/" + bp_dias["06AM"]
                makebp["07AM"] = bp_sys["07AM"] + "/" + bp_dias["07AM"]
                makebp["08AM"] = bp_sys["08AM"] + "/" + bp_dias["08AM"]
                makebp["09AM"] = bp_sys["09AM"] + "/" + bp_dias["09AM"]
                makebp["10AM"] = bp_sys["10AM"] + "/" + bp_dias["10AM"]
                makebp["11AM"] = bp_sys["11AM"] + "/" + bp_dias["11AM"]
                makebp["12PM"] = bp_sys["12PM"] + "/" + bp_dias["12PM"]
                makebp["01PM"] = bp_sys["01PM"] + "/" + bp_dias["01PM"]
                makebp["02PM"] = bp_sys["02PM"] + "/" + bp_dias["02PM"]
                makebp["03PM"] = bp_sys["03PM"] + "/" + bp_dias["03PM"]
                makebp["04PM"] = bp_sys["04PM"] + "/" + bp_dias["04PM"]
                makebp["05PM"] = bp_sys["05PM"] + "/" + bp_dias["05PM"]
                makebp["06PM"] = bp_sys["06PM"] + "/" + bp_dias["06PM"]
                makebp["07PM"] = bp_sys["07PM"] + "/" + bp_dias["07PM"]
                makebp["08PM"] = bp_sys["08PM"] + "/" + bp_dias["08PM"]
                makebp["09PM"] = bp_sys["09PM"] + "/" + bp_dias["09PM"]
                makebp["10PM"] = bp_sys["10PM"] + "/" + bp_dias["10PM"]
                makebp["11PM"] = bp_sys["11PM"] + "/" + bp_dias["11PM"]
                if (vitalNumber.length > temp.length) {
                    for(var i = 0 ; i < vitalNumber.length -1; i++ )

                     {
                        let dumpData = {
                            "vitalName": "",
                            "vmId": 0,
                            "datee": "",
                            "12AM": "-",
                            "01AM": "-",
                            "02AM": "-",
                            "03AM": "-",
                            "04AM": "-",
                            "05AM": "-",
                            "06AM": "-",
                            "07AM": "-",
                            "08AM": "-",
                            "09AM": "-",
                            "10AM": "-",
                            "11AM": "-",
                            "12PM": "-",
                            "01PM": "-",
                            "02PM": "-",
                            "03PM": "-",
                            "04PM": "-",
                            "05PM": "-",
                            "06PM": "-",
                            "07PM": "-",
                            "08PM": "-",
                            "09PM": "-",
                            "10PM": "-",
                            "11PM": "-"
                        }
                        let notfound = temp.findIndex(temp => temp.vmId === vitalNumber[i])
                        if(notfound === -1)
                        {
                            let y = {
                                "4":["bp_sys", bp],
                                "7":["RespRate", lungs],
                                "56":["Spo2", spo],
                                "3":["Pulse", pulse],
                                "5":["RBS",rbs],
                                "74":["HeartRate", heart],
                                "10":["Temp.", temprature],
                                "6":["bp_Dias", bp],
                            }
                            dumpData["vitalName"] = y[vitalNumber[i].toString()][0]
                            dumpData["icon"] = y[vitalNumber[i].toString()][1]
                            temp.push(dumpData)
                        }
                    }
                }



                let bpsysInd = temp.findIndex((val) => val.vmId === 4)
                let bpdiasInd = temp.findIndex((val) => val.vmId === 6)
                temp.splice(bpsysInd, 1)
                temp.splice(bpdiasInd, 1)


                setPatientViatlsData([makebp, ...temp])
            }
            else {
                if (vitalNumber.length > temp.length) {
                    for(var i = 0 ; i < vitalNumber.length -1; i++ )

                     {
                        let dumpData = {
                            "vitalName": "",
                            "vmId": 0,
                            "datee": "",
                            "12AM": "-",
                            "01AM": "-",
                            "02AM": "-",
                            "03AM": "-",
                            "04AM": "-",
                            "05AM": "-",
                            "06AM": "-",
                            "07AM": "-",
                            "08AM": "-",
                            "09AM": "-",
                            "10AM": "-",
                            "11AM": "-",
                            "12PM": "-",
                            "01PM": "-",
                            "02PM": "-",
                            "03PM": "-",
                            "04PM": "-",
                            "05PM": "-",
                            "06PM": "-",
                            "07PM": "-",
                            "08PM": "-",
                            "09PM": "-",
                            "10PM": "-",
                            "11PM": "-"
                        }
                        let notfound = temp.findIndex(temp => temp.vmId === vitalNumber[i])
                        if(notfound === -1)
                        {
                            let y = {
                                "4":["bp_sys", bp],
                                "7":["RespRate", lungs],
                                "56":["Spo2", spo],
                                "3":["Pulse", pulse],
                                "5":["RBS",rbs],
                                "74":["HeartRate", heart],
                                "10":["Temp.", temprature],
                                "6":["bp_Dias", bp],
                            }
                            dumpData["vitalName"] = y[vitalNumber[i].toString()][0]
                            dumpData["icon"] = y[vitalNumber[i].toString()][1]
                            temp.push(dumpData)
                        }
                    }
                }
                setPatientViatlsData([...temp])
            }

        }, 600)

        // function transpose(a) {
        //     return Object.keys(a[0]).map(function (c) {
        //         return a.map(function (r) { return r[c]; });
        //     });
        // }
        // // console.log("asa", transpose(main))
        // setTestVitals(transpose(main))
    }




    let saveData = async () => {
        // if (props.callingpage === 0) {
        //     sendForm["uhid"] = window.sessionStorage.getItem("activePatient") ? JSON.parse(window.sessionStorage.getItem("activePatient")).Uhid : ""
        // }
        // else if (props.callingpage === 1) {
        //     sendForm["uhid"] = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : ""

        // }
        sendForm["uhid"] = JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid

        sendForm["userId"] = window.userId
        sendForm["vitalDate"] = document.getElementById('vitalDate').value
        sendForm["vitalTime"] = document.getElementById('vitalTime').value
        let dateVal = document.getElementById('vitalDate').value;
        let timeVal = document.getElementById('vitalTime').value;
        let bpsVal = document.getElementById('vmValueBPSys').value;
        let bpdVal = document.getElementById('vmValueBPDias').value;
        let spo2Val = document.getElementById('vmValueSPO2').value;
        let RRVal = document.getElementById('vmValueRespiratoryRate').value;
        let HRVal = document.getElementById('vmValueHeartRate').value;
        let PRVal = document.getElementById('vmValuePulse').value;
        let TempVal = document.getElementById('vmValueTemperature').value;
        let RBSVal = document.getElementById('vmValueRbs').value;


        if (dateVal === '' || dateVal === undefined || dateVal === null) {
            document.getElementById('errDate').innerHTML = "Please select Date";
            document.getElementById('errDate').style.display = "block";
        }
        if (bpsVal === '' && bpdVal === '' && spo2Val === '' && RRVal === '' && HRVal === '' && PRVal === '' && TempVal === '' && RBSVal === '') {
            document.getElementById('errVital').innerHTML = "Please enter any vital";
            document.getElementById('errVital').style.display = "block";
        }
        else if (timeVal === '' || timeVal === undefined || timeVal === null) {
            document.getElementById('errTime').innerHTML = "Please select Time";
            document.getElementById('errTime').style.display = "block";
        }
        else if (bpsVal > 500) {
            document.getElementById('errBPS').innerHTML = "Enter valid value";
            document.getElementById('errBPS').style.display = "block";
        }
        else if (bpdVal > 1000) {
            document.getElementById('errBPD').innerHTML = "Enter valid value";
            document.getElementById('errBPD').style.display = "block";
        }
        else if (spo2Val > 100) {
            document.getElementById('errSPO2').innerHTML = "Enter valid value";
            document.getElementById('errSPO2').style.display = "block";
        }
        else if (RRVal > 50) {
            document.getElementById('errRR').innerHTML = "Enter valid value";
            document.getElementById('errRR').style.display = "block";
        }
        else if (HRVal > 220) {
            document.getElementById('errHR').innerHTML = "Enter valid value";
            document.getElementById('errHR').style.display = "block";
        }
        else if (PRVal > 220) {
            document.getElementById('errPR').innerHTML = "Enter valid value";
            document.getElementById('errPR').style.display = "block";
        }
        else if (TempVal > 110) {
            document.getElementById('errTemp').innerHTML = "Enter valid value";
            document.getElementById('errTemp').style.display = "block";
        }
        else if (RBSVal > 2400) {
            document.getElementById('errRBS').innerHTML = "Enter valid value";
            document.getElementById('errRBS').style.display = "block";
        }

        else {

            setShowUnderProcess(1)
            let response = await POSTInsertPatientVital(sendForm)

            if (response.status === 1) {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage("Data Saved SuccessFully!")
                setTosterValue(0)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
                //props.setGetData(1)
                handleClear();
                getVitalDatas();
                handleCloseModal();
                console.log('Vital Insertion data', response)
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage(response.responseValue)
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)

            }
        }
    }

    const insertPatientVitalRange = async () => {


        sendRangeForm["userId"] = window.userId
        sendRangeForm["pmId"] = getPmid

        // console.log('checkData', sendRangeForm);
        // return;


        console.log('maxRange:', sendRangeForm.maxRange);
        console.log('minRange:', sendRangeForm.minRange);
        console.log('range comparison:', parseFloat(sendRangeForm.maxRange) < parseFloat(sendRangeForm.minRange));
        console.log('maxRange as float:', parseFloat(sendRangeForm.maxRange));
        console.log('minRange as float:', parseFloat(sendRangeForm.minRange));
        if (sendRangeForm.vitalId === '' || sendRangeForm.vitalId === undefined || sendRangeForm.vitalId === null) {
            document.getElementById('vitalMasterId').innerHTML = "Please select Vital";
            document.getElementById('vitalMasterId').style.display = "block";
        }
        else if (sendRangeForm.minRange === '' || sendRangeForm.minRange === undefined || sendRangeForm.minRange === null) {
            document.getElementById('errMinRange').innerHTML = "Please enter minimum Range";
            document.getElementById('errMinRange').style.display = "block";
        }
        else if (sendRangeForm.maxRange === '' || sendRangeForm.maxRange === undefined || sendRangeForm.maxRange === null) {
            document.getElementById('errMaxRange').innerHTML = "Please enter maximum Range";
            document.getElementById('errMaxRange').style.display = "block";
        }

        else if (parseFloat(sendRangeForm.maxRange) < parseFloat(sendRangeForm.minRange)) {
            console.log('Entering else if block');
            console.log('Float Maximum Range', parseFloat(sendRangeForm.maxRange));
            console.log('Float Mainimum Range', parseFloat(sendRangeForm.minRange));
            console.log(document.getElementById('errMaxRange').innerHTML = "Maximum Range must be greater than minimum range");
            console.log(document.getElementById('errMaxRange').style.display = "block");
            document.getElementById('errMaxRange').innerHTML = "Maximum Range must be greater than minimum range";
            document.getElementById('errMaxRange').style.display = "block";
        }
        else {
            let response = await InsertPatientVitalRange(sendRangeForm)

            if (response.status === 1) {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage("Data Saved SuccessFully!")
                setTosterValue(0)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
                //props.setGetData(1)
                handleRangeClear();
                console.log('Vital Range Insertion', response)
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage(response.responseValue)
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)

            }
        }


    }
    // try {

    //     let tempArr = [];

    //     var valResponse = {
    //         pmId: getPmid,
    //         vitalMasterId: sendRangeForm.vitalMasterId,
    //         remark: sendRangeForm.remark,
    //         userId: window.userId,
    //         minRange: sendRangeForm.minRange,
    //         maxRange: sendRangeForm.maxRange
    //     };
    //     tempArr.push(valResponse)
    //     console.log('insertedPatientVitalRange', valResponse);
    //     console.log('tempArr', tempArr);
    //     await InsertPatientVitalRange(tempArr);
    //     setRangeForm({ userId: window.userId, vitalMasterId: '', remarks: '' });

    //     closeModal();
    // }
    // catch (error) {
    //     console.error('Error setting vital range :', error);
    // }



    let handleRangeChange = (e) => {

        console.log("Handling change:", e.target.name, e.target.value);
        //document.getElementById('errVitalRange').style.display = "none";
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'minRange' || name === 'maxRange') {

            value = parseFloat(value);
        }
        if (name === "vitalId") {
            document.getElementById("vitalMasterId").style.display = "none";
        }
        else if (name === "minRange") {
            document.getElementById("errMinRange").style.display = "none";

        }
        else if (name === "maxRange") {
            document.getElementById("errMaxRange").style.display = "none";

        }
        else if (name === "remark") {
            document.getElementById("remarksId").style.display = "none";

        }

        setRangeForm(sendRangeForm => ({
            ...sendRangeForm,
            [name]: value
        }))


    };

    let handleOnChange = (e) => {
        document.getElementById('errVital').style.display = "none";
        let name = e.target.name;
        let value = e.target.value;
        if (name === "vitalDate") {
            document.getElementById("errDate").style.display = "none";
        }
        else if (name === "vitalTime") {
            document.getElementById("errTime").style.display = "none";

        }
        else if (name === "vmValueBPSys") {
            document.getElementById("errBPS").style.display = "none";

        }
        else if (name === "vmValueBPDias") {
            document.getElementById("errBPD").style.display = "none";

        }
        else if (name === "vmValueSPO2") {
            document.getElementById("errSPO2").style.display = "none";

        }
        else if (name === "vmValueRespiratoryRate") {
            document.getElementById("errRR").style.display = "none";

        }
        else if (name === "vmValueHeartRate") {
            document.getElementById("errHR").style.display = "none";

        }
        else if (name === "vmValuePulse") {
            document.getElementById("errPR").style.display = "none";

        }
        else if (name === "vmValueRbs") {
            document.getElementById("errRBS").style.display = "none";

        }
        else if (name === "vmValueTemperature") {
            document.getElementById("errTemp").style.display = "none";

        }
        setSendForm(sendForm => ({
            ...sendForm,
            [name]: value
        }))

    };

    let handleClear = () => {
        document.getElementById('errVital').style.display = "none";
        document.getElementById('errBPS').style.display = "none";
        document.getElementById("errBPD").style.display = "none";
        document.getElementById("errSPO2").style.display = "none";
        document.getElementById("errRR").style.display = "none";
        document.getElementById("errHR").style.display = "none";
        document.getElementById("errPR").style.display = "none";
        document.getElementById("errRBS").style.display = "none";
        document.getElementById("errTemp").style.display = "none";
        document.getElementById('vmValueBPSys').value = '';
        document.getElementById('vmValueBPDias').value = '';
        document.getElementById('vmValueTemperature').value = '';
        document.getElementById('vmValueRespiratoryRate').value = '';
        document.getElementById('vmValueHeartRate').value = '';
        document.getElementById('vmValuePulse').value = '';
        document.getElementById('vmValueSPO2').value = '';
        // document.getElementById('weight').value = '';
        // document.getElementById('height').value = '';
        document.getElementById('vmValueRbs').value = '';
        document.getElementById('vitalTime').value = '';
        // document.getElementById('vitalDate').value = '';
        document.getElementById('vitalDate').value = curdate();
        document.getElementById('vitalTime').value = formattedTime;
    };
    let handleRangeClear = () => {

        setRangeForm({
            minRange: '',
            maxRange: '',
            remark: '',

        });
        document.getElementById('errMinRange').style.display = 'none';
        document.getElementById('errMaxRange').style.display = 'none';
        document.getElementById('remarksId').style.display = 'none';


        setClearDropdown();

    };


    const formattedTime = currentTime.toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
    });

    const handleShowModal = () => {
        setShowModal(true);
    };
    const handleShowRangeModal = () => {
        setShowRangeModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const closeModal = () => {
        setShowRangeModal(false);
    }
    useEffect(() => {
        curdate();
        document.getElementById('vitalTime').value = formattedTime;

    }, []);
    useEffect(() => {
        getVitalDatas()

    }, [props.getData]);
    useEffect(() => {
        getVitalMaster();
    }, [getPmid]);


    return (
        <>
            {/* ---------------------------Start Vitals------------------------- */}
            <div className='icuChartHeading mb-2'>
                <div className='icuChartHeading-inn'>
                    <div className='ICUHead-lft'>
                        <span><img src={IconVitals} alt='' /></span>
                        <span><h3>Vitals</h3></span>
                    </div>
                </div>
                <div className='icuChartHeading-inn pointSpan' style={{ display: props.isPrint === 1 ? "none" : "block" }}>
                    <span><img src={IconICUSetting} onClick={handleShowRangeModal} alt='' /></span>
                    <span><img src={IconIcuAdd} onClick={handleShowModal} alt='' /></span>
                </div>
            </div>
            <div className="med-table-section Icu-component" style={{ "height": "35vh" }}>
                <table className='med-table tblICUChart'>
                    <thead>
                        <tr style={{ flexWrap: 'nowrap' }}>
                            <th></th>
                            <th>Name</th>
                            <th>{t("Vitals /Time")}</th>
                            <th>07 AM</th>
                            <th>08 AM</th>
                            <th>09 AM</th>
                            <th>10 AM</th>
                            <th>11 AM</th>
                            <th>12 PM</th>
                            <th>01 PM</th>
                            <th>02 PM</th>
                            <th>03 PM</th>
                            <th>04 PM</th>
                            <th>05 PM</th>
                            <th>06 PM</th>
                            <th>07 PM</th>
                            <th>08 PM</th>
                            <th>09 PM</th>
                            <th>10 PM</th>
                            <th>11 PM</th>
                            <th>12 AM</th>
                            <th>01 AM</th>
                            <th>02 AM</th>
                            <th>03 AM</th>
                            <th>04 AM</th>
                            <th>05 AM</th>
                            <th>06 AM</th>
                            {/* {[...Array(24)].map((_, index) => (
                                <th key={index + 1} className="text-center">
                                    {index < 9 ? `0${index + 1}:00` : `${index + 1}:00`}
                                </th>
                            ))} */}

                            {/* {patientViatlsData && patientViatlsData.map((list, index) => {
                                return (
                                    <th key={index}>{list.showVitalTime}</th>
                                )
                            })} */}
                        </tr>
                    </thead>
                    <tbody>
                        {patientViatlsData && patientViatlsData.map((val, ind) => {
                            return (
                                <tr key={ind}>
                                    <td><img src={"" + val.icon} alt={val.vitalName} />  </td>
                                    <td>{val.vitalName}</td>
                                    <td>{val.datee}</td>
                                    <td>{val["07AM"]}</td>
                                    <td>{val["08AM"]}</td>
                                    <td>{val["09AM"]}</td>
                                    <td>{val["10AM"]}</td>
                                    <td>{val["11AM"]}</td>
                                    <td>{val["12PM"]}</td>
                                    <td>{val["01PM"]}</td>
                                    <td>{val["02PM"]}</td>
                                    <td>{val["03PM"]}</td>
                                    <td>{val["04PM"]}</td>
                                    <td>{val["05PM"]}</td>
                                    <td>{val["06PM"]}</td>
                                    <td>{val["07PM"]}</td>
                                    <td>{val["08PM"]}</td>
                                    <td>{val["09PM"]}</td>
                                    <td>{val["10PM"]}</td>
                                    <td>{val["11PM"]}</td>
                                    <td>{val["12AM"]}</td>
                                    <td>{val["01AM"]}</td>
                                    <td>{val["02AM"]}</td>
                                    <td>{val["03AM"]}</td>
                                    <td>{val["04AM"]}</td>
                                    <td>{val["05AM"]}</td>
                                    <td>{val["06AM"]}</td>
                                    {/* <td>
                                        <div className='d-flex align-items-center '>
                                            {val !== 6 ? <img src={vitalImg[ind].icon} className='icnn' alt={`${vitalImg[ind].name} icon`} /> : ""}
                                            {val !== 6 ? <span>{vitalImg[ind].name}</span> : ""}
                                        </div>
                                    </td>
                                    {
                                        val !== 6 ? testVitals && testVitals[ind].map((v, i) => { return (<td key={i}>{v}</td>) }) : ""
                                    } */}

                                    {/* {[...Array(24)].map((_, index) => {
                                        const vitalDataForTime = testVitals && testVitals[ind] ? testVitals[ind][index] : null;
                                        return (
                                            <td key={index}>{vitalDataForTime !== undefined ? vitalDataForTime : "-"}</td>
                                        );
                                    })} */}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

            </div>


            {/* ---------------------------End Vitals------------------------- */}
            <div>


                {/* Modal */}
                <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Vital Input Modal</h5>
                                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                            </div>
                            <div className="modal-body">
                                <div className='d-flex justify-content-between'>
                                    <div className='tblheading'>Vital Input</div>

                                    <div className='mt-2  d-flex gap-1 vital-btn-date'>

                                        <div>
                                            <div className='vitals-cnt-in10'>
                                                <input type='date' name="vitalDate" id='vitalDate' onChange={handleOnChange} />
                                            </div>
                                            <small id="errDate" className="form-text text-danger" style={{ display: 'none' }}></small>
                                        </div>

                                        <div>
                                            <div className='vitals-cnt-in10'>
                                                <input type='time' name="vitalTime" id='vitalTime' onChange={handleOnChange} />
                                            </div>
                                            <small id="errTime" className="form-text text-danger" style={{ display: 'none' }}></small>
                                        </div>

                                    </div>


                                </div>

                                <div className='mainsubheading pt-2'>General</div>
                                <div className='vitals-cnt customeInput'>
                                    {/* ... ( vital inputs) */}
                                    <div className='vitals-cnt customeInput'>


                                        <div className='vitals-cnt-in'>
                                            <img src={BPSystolic} className='icnn1' alt='' />
                                            <input type='number' placeholder='BPS(mm Hg)' name='vmValueBPSys' id='vmValueBPSys' onChange={handleOnChange} />

                                            <small id="errBPS" className="absolute-alert" style={{ display: 'none' }}></small>
                                        </div>


                                        <div className='vitals-cnt-in'>
                                            <img src={bp} className='icnn1' alt='' />
                                            <input type='number' placeholder='BPD(mm Hg)' name='vmValueBPDias' id='vmValueBPDias' onChange={handleOnChange} />
                                            <small id="errBPD" className="absolute-alert" style={{ display: 'none' }}></small>
                                        </div>
                                        <div className='vitals-cnt-in'>
                                            <img src={spo} className='icnn1' alt='' />
                                            <input type='number' placeholder='SPO2' name='vmValueSPO2' id='vmValueSPO2' onChange={handleOnChange} />
                                            <small id="errSPO2" className="absolute-alert" style={{ display: 'none' }}></small>
                                        </div>
                                        <div className='vitals-cnt-in'>
                                            <img src={lungs} className='icnn1' alt='' />
                                            <input type='number' placeholder='RR/m' name='vmValueRespiratoryRate' id='vmValueRespiratoryRate' onChange={handleOnChange} />
                                            <small id="errRR" className="absolute-alert" style={{ display: 'none' }}></small>
                                        </div>

                                        <div className='vitals-cnt-in'>
                                            <img src={heart} className='icnn1' alt='' />
                                            <input type='number' placeholder='HR' name='vmValueHeartRate' id='vmValueHeartRate' onChange={handleOnChange} />
                                            <small id="errHR" className="absolute-alert" style={{ display: 'none' }}></small>
                                        </div>


                                        <div className='vitals-cnt-in'>
                                            <img src={pulse} className='icnn1' alt='' />
                                            <input type='number' placeholder='Pulse(Beats)' name='vmValuePulse' id='vmValuePulse' onChange={handleOnChange} />
                                            <small id="errPR" className="absolute-alert" style={{ display: 'none' }}></small>
                                        </div>
                                        <div className='vitals-cnt-in'>
                                            <img src={temprature} className='icnn1' alt='' />
                                            <input type='number' placeholder='Temp(°F)' name='vmValueTemperature' id='vmValueTemperature' onChange={handleOnChange} />
                                            <small id="errTemp" className="absolute-alert" style={{ display: 'none' }}></small>
                                        </div>

                                        <div className='vitals-cnt-in'>
                                            <img src={rbs} className='icnn1' alt='' />
                                            <input type='number' placeholder='RBS (mg/dl)' name='vmValueRbs' id='vmValueRbs' onChange={handleOnChange} />
                                            <small id="errRBS" className="absolute-alert" style={{ display: 'none' }}></small>
                                        </div>


                                    </div>
                                </div>
                                <small id="errVital" className="form-text text-danger" style={{ display: 'none' }}></small>
                            </div>

                            <div className='inner-content'>
                                <div className='d-flex flex-wrap gap-3 p-2 boxcontainer justify-content-end'>
                                    <div className="mb-2 justify-content-end">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                        <div className='diet-btn vital-btn '>
                                            {/*code for save and clear  */}
                                            <div className='inner-content'>
                                                <div className='d-flex flex-wrap   gap-3 p-2 boxcontainer justify-content-end'>
                                                    <div className="mb-2 justify-content-end">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                                        <div className='diet-btn vital-btn '>

                                                            {/* <button type="button" className="btn btn-save btn-save-fill btn-sm"><img src={GetData} className='icnn' /> Get Data</button>
                                                <button type="button" className="btn btn-save btn-save-fill btn-sm" name='vitalDate' ><img src={microphone} className='icnn' /> Voice Data</button> */}
                                                            {showUnderProcess === 1 ? <TosterUnderProcess /> : <>
                                                                {
                                                                    showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} /> :
                                                                        <button type="button" className="btn btn-save btn-save-fill btn-sm " name='vitalTime' onClick={saveData} ><img src={save} className='icnn' /> {t("Save")}</button>

                                                                }
                                                            </>}

                                                            <button type="button" className="btn btn-save btn-sm btnbluehover" onClick={handleClear}><img src={clear} className='icnn' /> {t("Clear")}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`modal fade ${showRangeModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showRangeModal ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Vital Range</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>

                            <div className="modal-body">
                                <div className='row' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    {/*<select
                                    className="form-select form-select-sm"
                                    id="vital"
                                    name="vital"
                                    value={selectedItem ? selectedItem.vitalName : ''}
                                    onChange={(e) => {
                                        setSelectedItem(
                                            vitalMasterList.find(
                                                (category) => category.vitalName === e.target.value
                                            )
                                        );
                                        handleRangeChange(e);
                                      }}>
                                      <option value="">Select Vital</option>
                                      {vitalMasterList.map((category) => (
                                        <option key={category.id} value={category.vitalName}>
                                            {category.vitalName}
                                        </option>
                                    ))}
                                      </select>*/}
                                    <div >
                                        <label htmlFor="vitalId" className="form-label">Vital Range<span className="starMandatory">*</span></label>
                                        <div>
                                            {vitalMasterList && <DropdownWithSearch defaulNname="Select Vital" name="vitalId" list={vitalMasterList} valueName="id" displayName="vitalName" editdata="" getvalue={handleRangeChange} clear={clearDropdown} clearFun={handleRangeClear} />}
                                        </div>


                                        <small
                                            id="vitalMasterId"
                                            className="form-text text-danger"
                                            style={{ display: 'none' }}
                                        ></small>
                                    </div>
                                    <div>
                                        <label htmlFor="minimumRange" className="form-label mt-3">
                                            Minimum Range
                                            <span className="starMandatory">*</span></label>
                                        <input
                                            type="number"
                                            className="form-control form-control-sm"
                                            id="minRange"
                                            name="minRange"
                                            value={sendRangeForm.minRange}
                                            onChange={handleRangeChange}
                                            placeholder="Enter Minimum Range"
                                        />
                                        <small
                                            id="errMinRange"
                                            className="form-text text-danger"
                                            style={{ display: 'none' }}
                                        ></small>
                                    </div>

                                    <div>
                                        <label htmlFor="minimumRange" className="form-label mt-3">
                                            Maximum Range<sup className='starMandatory'>*</sup>
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control form-control-sm"
                                            id="maxRange"
                                            name="maxRange"
                                            value={sendRangeForm.maxRange}
                                            onChange={handleRangeChange}
                                            placeholder="Enter Maximum Range"
                                        />
                                        <small
                                            id="errMaxRange"
                                            className="form-text text-danger"
                                            style={{ display: 'none' }}
                                        ></small>
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="remarks" className="form-label mt-3">
                                        Query
                                        <span className="starMandatory">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        id="remark"
                                        name="remark"
                                        value={sendRangeForm.remark}
                                        onChange={handleRangeChange}
                                        placeholder="Enter Remarks"
                                    />
                                    <small
                                        id="remarksId"
                                        className="form-text text-danger"
                                        style={{ display: 'none' }}
                                    ></small>
                                </div>
                                <small id="errVitalRange" className="form-text text-danger" style={{ display: 'none' }}></small>
                            </div>

                            <div className='inner-content'>
                                <div className='d-flex flex-wrap gap-3 p-2 boxcontainer justify-content-end'>
                                    <div className="mb-2 justify-content-end">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                        <div className='diet-btn vital-btn '>
                                            <div className='inner-content'>
                                                <div className='d-flex flex-wrap gap-3 p-2 boxcontainer justify-content-end'>
                                                    <div className="mb-2 justify-content-end">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                                        <div className='diet-btn vital-btn '>
                                                            {showUnderProcess === 1 ? (
                                                                <TosterUnderProcess />
                                                            ) : (
                                                                <>
                                                                    {showToster === 1 ? (
                                                                        <Toster value={tosterValue} message={tosterMessage} />
                                                                    ) : (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-save btn-save-fill btn-sm"
                                                                            name='vitalTime'
                                                                            onClick={insertPatientVitalRange}
                                                                        >
                                                                            <img src={save} className='icnn' alt='' /> Save
                                                                        </button>
                                                                    )}
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-save btn-sm btnbluehover"
                                                                        onClick={handleRangeClear}
                                                                    >
                                                                        <img src={clear} className='icnn' alt='' /> Clear
                                                                    </button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>


    );
}
