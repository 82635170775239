import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react'
import Heading from '../../../Component/Heading';
import Toster from '../../../Component/Toster';
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import viewicn from "../../../assets/images/PatientListIcons/viewicn.svg"
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import GetImageData from '../../Api/Master/ImageDraw/GetImageData';
import PostImageData from '../../Api/Master/ImageDraw/PostImageData';
import SuccessToster from '../../../Component/SuccessToster';
import AlertToster from '../../../Component/AlertToster';
import DeleteImage from '../../Api/Master/ImageDraw/DeleteImage';
function ImageDraw() {
    let [updateBool, setUpdateBool] = useState(0)
    let [showUnderProcess, setShowUnderProcess] = useState(0)
    let [showToster, setShowToster] = useState(0)
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showAlertToster, setShowAlertToster] = useState(0)
    let [rowId, setRowId] = useState('')
    let [tosterMessage, setTosterMessage] = useState("")
    let [tosterValue, setTosterValue] = useState(0)
    const [drawingData, setDrawingData] = useState([]);
    let [imageData, setImageData] = useState([]);
    let [sendForm, setSendForm] = useState({
        "imageName": '',
        "image": ''
    })
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    const userId = JSON.parse(window.sessionStorage.getItem("LoginData")).userId;
    const getImageData = async () => {
        const response = await GetImageData();
        setImageData(response.responseValue)
    }
    const canvasRef = useRef(null);

    const handleImageChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        clearValidationErrMessage();
        setSendForm(sendForm => ({
            ...sendForm,
            [name]: value,
        }))
    }
    // State to store drawing status
    const [isDrawing, setIsDrawing] = useState(false);

    // State to store previous coordinates
    const [prevCoord, setPrevCoord] = useState({ x: 0, y: 0 });

    // Function to handle mouse down event, initiating drawing
    const startDrawing = (event) => {
        setIsDrawing(true);
        setPrevCoord({ x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY });
    };
    const [color, setColor] = useState('#000000'); // Initial color is black

    // Function to handle mouse move event, drawing lines
    const draw = (event) => {
        if (!isDrawing) return;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        ctx.beginPath();
        ctx.moveTo(prevCoord.x, prevCoord.y);
        ctx.lineTo(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
        ctx.strokeStyle = color; // Set the stroke color
        ctx.stroke();
        setPrevCoord({ x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY });

        // Save drawing data
        setDrawingData([...drawingData, { x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY, color }]);
    };

    // Function to handle mouse up event, ending drawing
    const endDrawing = () => {
        setIsDrawing(false);
    };
    // Function to save the canvas content as an image
    const createSvgData = (drawingData) => {
        if (drawingData.length < 2) {
            // throw new Error('Drawing data should contain at least two points');
            setShowAlertToster(1)
            setShowErrMessage('Drawing data should contain at least two points')
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }

        // Convert drawing data to SVG path string
        const pathString = drawingData.map(point => `${point.x} ${point.y}`).join(' L ');

        // Construct SVG content with the path string
        const svgContent = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
            <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="500" height="300">
                <path stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#000" fill="none" d="M ${pathString}"/>
            </svg>`;

        return svgContent;
    };
    //Clear Error Message
    const clearValidationErrMessage = () => {
        document.getElementById('errimage').style.display = "none";
    }
    const saveImage = async () => {
        // Convert drawing data to SVG format
        const svgData = createSvgData(drawingData); // Pass drawingData from state
        if (sendForm.imageName === '' || sendForm.imageName === null || sendForm.imageName === undefined) {
            document.getElementById("errimage").innerHTML = "Enter image name"
            document.getElementById("errimage").style.display = "block"
        }
        else {
            const obj = {
                imageName: sendForm.imageName,
                imagePath: svgData,
                userId: userId,
                clientId: clientID
            };
            console.log("obj:", obj);
            console.log("SVG Data:", svgData);
            const jsonString = JSON.stringify(obj);
            console.log("jsonString:", jsonString);
            // return;
            const response = await PostImageData(obj);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Data saved successfully..");
                setTimeout(() => {
                    setShowToster(0);
                }, 2000)
                getImageData();
                clearCanvas();
            }
            else {
                setShowUnderProcess(0);
                setShowAlertToster(1)
                setShowErrMessage(response.responseValue)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }
        }

    };
    let handleDelete = async () => {
        setShowUnderProcess(1);
        let obj = {
            id: rowId
        }
        let response = await DeleteImage(obj)
        if (response.status === 1) {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Data Deleted SuccessFully!")
            setTosterValue(0)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
            getImageData();
            clearCanvas();
        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage(response.responseValue)
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }
    // Function to clear the canvas
    const clearCanvas = () => {
        clearValidationErrMessage();
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        setDrawingData([]);
        setSendForm(sendForm => ({
            ...sendForm,
            imageName: '',
        }))
    };
    // Function to change drawing color
    const handleColorChange = (event) => {
        setColor(event.target.value);
    };

    useEffect(() => {
        getImageData();
    }, [])
    return (
        <>
            <section className="main-content pt-3 mt-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="med-box">
                                <div className="title">{t("Image Draw")} </div>
                                <div className="inner-content">
                                    <div className="d-flex flex-wrap align-content-end">

                                        <div className="mb-2 me-2">
                                            <label htmlFor="imageName" className="form-label">{t("Image Name")}<span className="starMandatory">*</span></label>
                                            <input type="text" className="form-control form-control-sm" placeholder={t("Enter image name")} name="imageName" value={sendForm.imageName} onChange={handleImageChange} />
                                            <small id="errimage" className="invalid-feedback" style={{ display: 'none' }}></small>
                                        </div>
                                        <div className='mb-2 relative'>
                                            <label htmlFor="bedName" className="form-label">{t("Draw Image")}<span className="starMandatory">*</span></label>
                                            <div className="temperature-chart">

                                                <canvas
                                                    ref={canvasRef}
                                                    onMouseDown={startDrawing}
                                                    onMouseMove={draw}
                                                    onMouseUp={endDrawing}
                                                    onMouseOut={endDrawing}
                                                    width={600} // Set the width of the canvas
                                                    height={200} // Set the height of the canvas
                                                />
                                            </div>
                                            <small id="errimagePath" className="invalid-feedback" style={{ display: 'none' }}></small>

                                        </div>&nbsp;
                                        <div className="mb-2 relative">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                            <div>
                                                {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                                    <>
                                                        {showToster === 1 ?
                                                            <Toster value={tosterValue} message={tosterMessage} />

                                                            : <div>
                                                                {updateBool === 0 ?
                                                                    <>
                                                                        {/* <input className='color_ btn btn-clear btn-sm mb-1 me-1' type="color" value={color} onChange={handleColorChange} /> */}
                                                                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={saveImage}><img src={saveButtonIcon} className='icnn' alt='' />{t("Save")} </button>
                                                                        <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={clearCanvas}><img src={clearIcon} className='icnn' alt='' />{t("Clear")}</button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={"saveUpdate"}>{t("UPDATE")}</button>
                                                                        <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { setUpdateBool(0); "handleClear"() }}>{t("Cancel")}</button>
                                                                    </>
                                                                }
                                                            </div>}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-1">
                            <div className='handlser'>
                                <Heading text={t("Image List")} />
                                {/* <Heading text={content} /> */}
                                <div style={{ position: 'relative' }}>
                                    <input type="text" className='form-control form-control-sm' placeholder={t("Search")} value={''} onChange={"handleSearch"} />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
                            <div className="med-table-section" style={{ "height": "74vh" }}>
                                <table className="med-table tblDraw border_ striped">
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>{t("S.No.")} </th>
                                            <th>{t("Image Name")}</th>
                                            <th>{t("Image")}</th>
                                            <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {imageData && imageData.map((list, index) => {
                                            return (
                                                <tr>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td>{list.imageName}</td>
                                                    <td>
                                                        <div className="svgDraw">
                                                            <img src={list.imagePath} alt="image" />
                                                        </div>
                                                    </td>
                                                    <td className='text-center'>
                                                        <button type="button" data-bs-toggle="modal" className="btndelt" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal" onClick={() => { setRowId(list.id) }} ><i className="bi bi-trash3"></i></button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>


                {/*  <!------------------- Start Delete Modal ---------------------------------->  */}
                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                    <div className="modal-dialog modalDelete">
                        <div className="modal-content">

                            <div className="modal-body modelbdy text-center">
                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                <div className='popDeleteTitle mt-3'>{t("Delete?")}</div>
                                <div className='popDeleteContent'>{t("Are_you_sure_you_want_to_delete?")}</div>
                            </div>
                            <div className="modal-footer1 text-center">

                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                                <button type="button" className="btn-delete popBtnDelete" onClick={handleDelete} data-bs-dismiss="modal">{t("Delete")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* {showToster === 1 ? (
                <SuccessToster
                    handle={setShowToster}
                    message="Image saved successfully !!"
                />
            ) : (
                ""
            )} */}
            {
                showAlertToster === 1 ?
                    <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
            }
        </>
    )
}

export default ImageDraw
