import React from 'react'
import printuse from '../../../src/assets/css/printuse.css'
// import SarvodayaLogo from '../../../assets/images/icons/sarvodayalogo.png'

export default function ConsentFormForProcedure() {
    return (
        <>
            <div className="main-table" style={{ padding: '10px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <table className='microtble'>

                    {/* ....header.. */}
                    <tr>
                        <td colSpan={8}>
                            <div className="sarvodya-header">
                                <div className='lft-header mb-3'>
                                    {/* <img alt='' className='sv-log'/>                                   */}
                                </div>
                                <div className='rght-header'>
                                    <div> SARVODAYA HOSPITAL <div className='addrs'>SECTOR-8, FARIDABAD | HELPLINE: 1800 313 1414</div></div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td colSpan={8}>
                            <div className='heading-form mt-3 mb-3'>
                                CONSENT FORM FOR PROCEDURE
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8}>
                            <table className='tbl-bordr mt-0' style={{ border: '1px solid black' }}>
                                <tr>
                                    <td colSpan={2}><div className='assesmnt mt-3 mb-3'>Date of Consent: <input type="text" id='Date_of_Consent' />  </div></td>
                                    <td rowSpan={4} style={{ borderLeft: '1px solid black', textAlign: 'center' }}>Patients Label</td>
                                </tr>
                                <tr>
                                    <td ><div className='assesmnt mt-2 mb-2'>Time of Consent: <input type="text" id='Time_of_Consent' />  </div></td>
                                    <td ><div className='assesmnt mt-2 mb-2'>Ward.:<input type="text" id='Ward_of_Consent' /> </div></td>
                                </tr>
                                <tr>
                                    <td ><div className='assesmnt mt-3 mb-3'>Diagnosis:  <input type="text" id='Diagnosis_of_Consent' /> </div></td>
                                    <td ><div className='assesmnt mt-3 mb-3'>Procedure:  <input type="text" id='Procedure_of_Consent' />  </div></td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={8}>
                            <div className='ptndetais mt-3'>
                                <div> <strong>I, </strong> <span style={{ width: '280px', borderBottom: '1px dashed black', display: 'inline-block' }}>
                                    sabi</span>  </div>
                                <div><label> <input type="checkbox" /> </label>    The Patient or </div>
                                <div>  <label> <input type="checkbox" /> </label> representative of patient have </div>

                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={8}>
                            <div className='parainfo2 mt-1'>
                                <strong>(Please tick the correct option above and below)</strong>
                            </div>
                            <div className='parainfo2 mt-1'>
                                I/We read the content in details.
                            </div>
                            <div className='paragraphinfo mt-1'>
                                I/We have been explained the current clinical condition of me/my patient.
                            </div>
                            <div className='paragraphinfo mt-1 mb-2'>
                                Been explained this consent form in <span style={{ width: '200px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span>which I fully understand and understood the information  provided about Operation/procedure.

                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8} style={{ border: '1px solid' }}>
                            <table className=''>
                                <tr style={{ borderBottom: '1px solid' }}>
                                    <td>
                                        <div className='paragraphinfo mt-2 mb-2'>
                                            I am informed that as a part of treatment the procedure <span style={{ width: '200px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span> is necessary for Therapeutic / Diagnostic purpose (Tick the applicable).


                                        </div>
                                        <div className='paragraphinfo mt-2 mb-2'>
                                            The Procedure is necessary due to the indications as mentioned <span style={{ width: '200px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span>

                                        </div>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: '1px solid' }}>
                                    <td>
                                        <div className='paragraphinfo mt-2 mb-2'>
                                            The type of anesthesia. <span style={{ width: '310px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span>
                                            to be given as per the requirement are clearly explained to me.


                                        </div>
                                        <div className='paragraphinfo mt-2 mb-2'>
                                            The possible complications that may arise as a part of the procedure are clearly explained to me, <span style={{ width: '100px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span>

                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='paragraphinfo mt-2 mb-2'>
                                            I have understood the above facts and agree to them.
                                        </div>
                                        <div className='paragraphinfo mt-2 mb-2'>
                                            I hereby authorize the doctor to perform the above procedure in any condition, considered appropriate
                                            by him/her. In case of any complication, I will not blame the attending doctor/ hospital.
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='paragraphinfo mt-3 mb-2'>
                                Any other significant risks (specific to the patient): <span style={{ width: '200px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span>

                            </div>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='paragraphinfo mt-2'>
                                Risks of not having the procedure : <span style={{ width: '200px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span>

                            </div>
                            <div className='ptndetais mt-1'>
                                <div> <strong>The expected outcome and likelihood of success is:  </strong>  </div>
                                <div><label> <input type="checkbox" /> </label>    Good </div>
                                <div><label> <input type="checkbox" /> </label>    Fair </div>
                                <div>  <label> <input type="checkbox" /> </label> Poor </div>

                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='paragraphinfo mt-3 mb-1'>
                                I have been explained the implications of not undergoing this procedure and the alternative methods of the operation/procedure are <span style={{ width: '200px', borderBottom: '1px dashed black', display: 'inline-block' }}> <strong>  sabi</strong></span>
                            </div>

                        </td>
                    </tr>
                </table>

            </div>
        </>
    )
}
