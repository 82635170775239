
async function GetLocationList() {
    let token = 'bearer ' + window.AppToken;
    let url = window.AdminbaseUrl + "/api/Location/GetLocationMaster";
    let head = { "Content-Type": "application/JSON", accept: '*/*', 'Authorization': token };
    let data={};
    let response = await fetch(url, {
      headers: head,
      method: 'GET'
    })
      .then((res) => res.json(data))
      .then();
  
    return response;
  }
  export default GetLocationList;
  
  
  