import React, { useEffect, useRef, useState } from 'react'
import IPDTopVitals from './IPDTopVitals'
import IPDHistory from './IPDHistory'
import IPDPatientComplaintConsultant from './IPDPatientComplaintConsultant'
import IPDMedication from './IPDMedication'
import IPDMedicationHistory from './IPDMedicationHistory'
import IPDVentilator from './IPDVentilator'
import IPDVentiBottom from './IPDVentiBottom'
import BottomButtons from './BottomButtons'
import GetPatientIPDAllHistory from '../../../../API/IPD/Prescription/GetPatientIPDAllHistory'
import SaveIPDData from '../../../../../Code/SaveIPDData'
import store from '../../../../../Store'
import { getIPDPatientData } from '../../../../../Reduce/IPD/IPDPatientData'
import { useSelector } from 'react-redux'
import Loader from '../../../../../Component/Loader'
import IPDPatientLabData from './IPDPatientLabData'
import Search from '../../../../../Code/Serach'
import { useLocation } from 'react-router-dom'
import VoiceCommand from '../../../../API/VoiceCommand'
import ChiefVoiceCommand from '../../../../API/RemotePatientMonitorDashboard/ChiefVoiceCommand'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

export default function IPDPrecriptionIndex(props) {

  let [loader, setLoader] = useState(1)
  let location = useLocation()

  let [voiceVital, setVoiceVital] = useState('');
  let [saveVoice, setSaveVoice] = useState('');
  let [chiefData, setChiefData] = useState([]);
  let [isChange, setIsChange] = useState(0)

  let getOnlySpecificData = async () => {
    setLoader(1)
    let temp = window.sessionStorage.getItem("IPDpatientsendData") ? JSON.parse(window.sessionStorage.getItem("IPDpatientsendData")) : []
    let activeUHID = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : []
    let response = await GetPatientIPDAllHistory(activeUHID)
    if (response.status === 1) {
      let val = response.responseValue
      let key = Object.keys(val)
      console.log("running", val)
      key.map((vals, ind) => {
        if (vals === "runningPrescription") {
          SaveIPDData(val.runningPrescription, "jsonArray")
        }

        if (vals === "prescriptionHistory") {
          SaveIPDData(val.prescriptionHistory, "prescriptionHistory")
          setIsChange(Math.random())
        }


      })

    }
    setLoader(0)
  }

  let getData = async () => {
    setLoader(1)

    let temp = window.sessionStorage.getItem("IPDpatientsendData") ? JSON.parse(window.sessionStorage.getItem("IPDpatientsendData")) : []
    let activeUHID = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : []
    let t = []
    temp.map((values, index) => {
      if (values[0] === activeUHID) {
        values.map((val, ind) => {
          let keys = Object.keys(val)
          t.push(keys[0])
        })
      }
    })
    // if (!t.includes("dataget")) {
    let response = await GetPatientIPDAllHistory(activeUHID)
    if (response.status === 1) {
      let val = response.responseValue
      let key = Object.keys(val)
      let flags = 0
      key.map((vals, ind) => {
        if (vals === "runningPrescription") {
          SaveIPDData(val.runningPrescription, "jsonArray")
        }
        else if (vals === "patientVitals") {
          SaveIPDData(val.patientVitals, "jsonVital")
        }
        else if (vals === "patientHistoryCategoryResultExistance") {
          SaveIPDData(val.patientHistoryCategoryResultExistance, "patientHistoryCategoryResultExistance")
        }
        else if (vals === "patientComplainHistory") {
          SaveIPDData(val.patientComplainHistory, "jsonDiagnosis")
        }
        else if (vals === "prescriptionHistory") {
          SaveIPDData(val.prescriptionHistory, "prescriptionHistory")
        }
        else if (vals === "patientCategoryResult") {
          SaveIPDData(val.patientCategoryResult, "patientCategoryResult")
        }
        else if (vals === "patientExaminationResult") {
          SaveIPDData(val.patientExaminationResult, "patientExaminationResult")
        }
        else if (vals === "patientHistoryCategoryResult") {
          SaveIPDData(val.patientHistoryCategoryResult, "patientHistoryCategoryResult")
        }
        else if (vals === "patientInvestigation") {
          SaveIPDData(val.patientInvestigation, "patientInvestigation")
        }
        flags = 1

      })
      SaveIPDData(1, "dataget")
      if (flags === 1) {
        store.dispatch(getIPDPatientData(val))
      }
    }
    // }
    else {
      temp.map((values, index) => {
        // console.log("index", index)
        if (values[0] === activeUHID) {
          store.dispatch(getIPDPatientData(values))
        }
      })
    }
    setLoader(0)

  }

  let IPDUHIDChange = useSelector((state) => state.IPDUHIDChange)

  const {
    transcript,
    resetTranscript,
    listening,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      // console.log('Browser does not support speech recognition.');
    }
  }, [browserSupportsSpeechRecognition]);

  const processTranscript = async (transcript) => {
    if (transcript) {
      let jsonVital = {
        voiceCommand: transcript
      }
      let jsonChief = {
        voiceCommand1: transcript
      };
      // console.log("jsonVital", jsonVital)
      // console.log('isListen', listening)

      let response = await VoiceCommand(jsonVital);
      let complaintsResponse = await ChiefVoiceCommand(jsonChief);
      // console.log("complaintsResponse---->", complaintsResponse)
      if (response.length !== 0) {
        // console.log("response ", response.voiceCommand)
        let data = response.voiceCommand
        // console.log("DATAAAAA", data)
        // if (data.spo2 !== 0 || data.bpSys !== 0 || data.bpDias !== 0 || data.pulseValue !== 0 || data.respiratoryRate !== 0 || data.Temperature !== 0 || data.weight !== 0) {
        //   setVoiceVital(data);
        // }
        if (data.spo2 !== 0 || data.BP_Sys !== 0 || data.BP_Dias !== 0 || data.Pulse !== 0 || data.respRate !== 0 || data.Temperature !== 0 || data.Weight !== 0 || data.Height !== 0) {
          setVoiceVital(data);
        }

      }
      if ((response.voiceCommand.Temperature === 0 && response.voiceCommand.BP_Sys === 0 && response.voiceCommand.Weight === 0) && complaintsResponse.length !== 0) {
        let chiefData = complaintsResponse.voiceCommand
        if (chiefData.length > 0) {
          // console.log("chiefData-------------------------->", chiefData)
          setChiefData(chiefData)
        }
      }

    }
  };

  const startListening = () => {
    SpeechRecognition.startListening({ continuous: false, language: 'en-US' });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      resetTranscript()
    }, 5000);
    return () => clearTimeout(timer);
  })

  useEffect(() => {
    // let temp = window.sessionStorage.getItem("IPDpatientsendData") ? JSON.parse(window.sessionStorage.getItem("IPDpatientsendData")) : []
    // let activeUHID = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : []
    // let flag = 0
    // temp.map((values, index) => {
    //   if (values[0] === activeUHID) {

    //     if (values.length > 1) {
    //       flag = 1
    //     }

    //   }
    // })
    // if (flag === 0) {
    //   getData()
    // }
    // else {
    //   store.dispatch(getIPDPatientData(temp))
    //   setLoader(0)
    // }
    // console.log("enter", flag)
    getData()

  }, [IPDUHIDChange])

  useEffect(() => {
    // console.log('listening', listening ? 'true' : 'false');
    if (!listening) {
      // console.log('listening', 'api called::', transcript)
      const regex = /\b(save|safe|sabe|cabe)\b/g;
      let output = []
      output = transcript.match(regex)
      // console.log("outtt", output)
      output !== null && output.length !== 0 ? setSaveVoice(output[0]) :
        // console.log("fffffffff", output && output[0])
        processTranscript(transcript);
    }
  }, [listening])

  return (
    <>
      <div className="row">
        <div className='col-md-9 col-sm-12 plt'>
          <IPDTopVitals voiceVital={voiceVital}  startListening={startListening} listening={listening} transcript={transcript}/>
          <IPDHistory />
          <IPDPatientComplaintConsultant chiefData={chiefData} />
          <IPDMedication getData={getOnlySpecificData} />
          <IPDMedicationHistory isChange={isChange}/>
        </div>
        <div className='col-md-3 col-sm-12 prt'>
          <IPDVentilator />
          <IPDVentiBottom />
          <IPDPatientLabData />
        </div>
      </div>
      <div className='roww mt-2 ipdbrn'>
        <BottomButtons getData={getData} saveVoice={saveVoice} />
      </div>
      <Loader val={loader} />
    </>
  )
}
