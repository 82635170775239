import React, { useEffect, useState } from 'react'
import printuse from '../../../assets/css/printuse.css'
// import sarvodayalogo from '../../../assets/images/icons/sarvodayalogo.png'
import GetAllPatientInitialDetails from '../../../Clinical/Pages/IPD/Assessment/API/GetAllPatientInitialDetails';
import GetPatientDetailsforSarvodaya from '../API/GetPatientDetailsforSarvodaya';
import Vector from "../../../assets/images/icons/Vector.svg";
import Contact from "../../../assets/images/icons/Contact.svg";
import PatientLabel from '../../../Component/PatientLabel';
import PrintPageHeader from '../../../Component/PrintPageHeader';
export default function ReceiptInitialAssesmentPatiantAdult() {

    const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    const [assementList, setAssementList] = useState([]);
    const [patientData, setPatientData] = useState();

    let [assessmentData, setAssessmentData] = useState()
    let [diagnosisData, setDiagnosisData] = useState()
    let [chiefComplaintData, setChiefComplaintData] = useState()
    let [pastMedicalAllergyData, setPastMedicalAllergyData] = useState()
    let [pastMedicalSurgeryData, setPastMedicalSurgeryData] = useState()
    let [medication, setMedication] = useState()
    let [allergyJson, setAllergyJson] = useState()
    let [medicalJson, setMedicalJson] = useState()

    console.log("assessmentData", assessmentData)
    console.log("diagnosisData", diagnosisData)
    console.log("chiefComplaintData", chiefComplaintData)
    console.log("pastMedicalAllergyData", pastMedicalAllergyData)
    console.log("pastMedicalSurgeryData", pastMedicalSurgeryData)
    console.log("medication", medication)
    console.log("allergyJson", allergyJson)
    console.log("medicalJson", medicalJson)
    let [clientData, setClientData] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientdata)
    //Get Patient Details
    const getPatientData = async () => {
        let data = await GetPatientDetailsforSarvodaya(uhID)
        if (data.status === 1) {
            setPatientData(data.responseValue[0])
        }
    }
    //Get Initial List
    let getInitialAssement = async () => {
        let response = await GetAllPatientInitialDetails(clientID, uhID);
        if (response.status === 1) {
            setAssementList(response.responseValue);
            console.log("assementList", response.responseValue)
            const { assessmentDetils, daignosisDetails, cheifComplaintsHistoryOfpresentilness, pastMedicalAndAllergiesHistory, pastMedicalSurgicalHistory, presentMedication } = response.responseValue;
            setAssessmentData(assessmentDetils);
            setDiagnosisData(daignosisDetails);
            setChiefComplaintData(cheifComplaintsHistoryOfpresentilness);
            setPastMedicalAllergyData(pastMedicalAndAllergiesHistory);
            setPastMedicalSurgeryData(pastMedicalSurgicalHistory);
            setMedication(presentMedication);
            // if (pastMedicalAndAllergiesHistory && pastMedicalAndAllergiesHistory.length > 0) {
            //     const allergyData = pastMedicalAndAllergiesHistory.find(item => item.categoryName === 'Allergies');
            //     const medicalData = pastMedicalAndAllergiesHistory.find(item => item.categoryName === 'Past Medical History');

            //     if (allergyData) {
            //         setAllergyJson(JSON.parse(allergyData.medicalHistoryJson));
            //     }
            //     if (medicalData) {
            //         setMedicalJson(JSON.parse(medicalData.medicalHistoryJson));
            //     }
            // }

            if (pastMedicalAndAllergiesHistory && pastMedicalAndAllergiesHistory.length > 0) {
                const allergyData = pastMedicalAndAllergiesHistory.find(item => item.categoryName === 'Allergies');
                const medicalData = pastMedicalAndAllergiesHistory.find(item => item.categoryName === 'Past Medical History');

                if (allergyData) {
                    setAllergyJson(JSON.parse(allergyData.medicalHistoryJson));
                } else {
                    setAllergyJson([]);
                }

                if (medicalData) {
                    setMedicalJson(JSON.parse(medicalData.medicalHistoryJson));
                } else {
                    setMedicalJson([]);
                }
            } else {
                setAllergyJson([]);
                setMedicalJson([]);
            }



        }
    }

    let handlepritnt = () => {
        document.title = 'Initial Assessment Patient Adult';
        window.print("");
        window.close();
    }

    const formatDateTime = (dateString) => {
        try {
            const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
            const date = new Date(dateString);
            if (isNaN(date.getTime())) {
                throw new Error('Invalid date');
            }
            return date.toLocaleDateString('en-IN', options);
        } catch (error) {
            console.error('Error formatting date:', error);
            return '';
        }
    };


    useEffect(() => {
        setTimeout(() => {
            handlepritnt();
        }, 1200)

        getInitialAssement();
        getPatientData();
    }, []);

    return (
        <>
            <div className="main-table" style={{ padding: '5px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <table className='microtble pt-4 mt-4'>
                    {/* ....header.. */}
                    <tr>
                        <td colSpan={6}>
                            <table>
                                {/* <tr>
                                    <td>
                                        <div className="sarvodya-header">
                                            <div className='lft-header'>
                                                <img src={clientData.logoUrl} alt="Company Logo"
                                                    style={{ width: "211px", }} />
                                            </div>
                                            <div className='rght-header mt-2'>
                                                <div className="CompanyDetailsHeading">
                                                    <div className="companyNameChallan">
                                                        {clientData.clientName}
                                                    </div>
                                                    <div className="companyLocation">
                                                        {clientData.address}
                                                    </div>
                                                    <div className="companyContact">
                                                        <img src={Vector} alt=""></img> {clientData.emailID}

                                                        <img src={Contact} alt=""></img> {clientData.mobileNo}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr> */}
                                <tr>
                                    <td colSpan={8}>
                                        <PrintPageHeader />

                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td colSpan={6} style={{ verticalAlign: 'top' }}>
                            <div className='micro-heading' style={{ textAlign: 'center' }}>
                                INITIAL ASSESSMENT INPATIENT-ADULT
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={6} className='pt-0 mb-0 ps-0 pe-0'>
                            <table className='table pt-0 mb-0 pb-0' style={{ border: '1px solid black' }}>
                                <tr>
                                    <td colSpan={2}>
                                        {/* <div className='assesmnt_'><strong>Date of Admission: &nbsp;</strong><span style={{ borderBottom: '1px solid black', display: 'inline-block' }}>{patientData && patientData.admitDate}</span> </div> */}
                                    </td>
                                    <td rowSpan={4} style={{ borderLeft: "1px solid black" }}>
                                        <PatientLabel />
                                    </td>
                                </tr>
                                <tr>
                                    <td >
                                        <div className='d-flex gap-2 pt-0 mb-0 mt-0 pb-0'>
                                            <div className='assesmnt1'><strong>Time of Assessment:  </strong>
                                                <span style={{ width: '40px', borderBottom: '1px solid black', display: 'inline-block', marginLeft: '5px' }}>
                                                    {assessmentData && assessmentData.length > 0 ? assessmentData[0].assesmentDateTime.split(' ')[1].slice(0, 5) : ''}
                                                </span>
                                            </div>
                                            <div className='assesmnt1 pt-0 mb-0 mt-0 pb-0'><strong>Ward:</strong> <span style={{ width: '80px', borderBottom: '1px solid black', display: 'inline-block', marginLeft: '5px' }}>{patientData && patientData.wardName}</span></div>

                                        </div>
                                    </td>
                                    {/* <td>
                                        <div className='assesmnt1'><strong>Ward:</strong> <span style={{ width: '110px', borderBottom: '1px solid black', display: 'inline-block' }}>{patientData && patientData.wardName}</span></div>
                                    </td> */}
                                </tr>

                                <tr>
                                    <td className='pt-0 mb-0 mt-0 pb-0' style={{ verticalAlign: 'top' }}>
                                        <div className='assesmnt1 pt-0 mb-0 mt-0 pb-0'>
                                            <strong>Diagnosis:&nbsp;</strong>
                                            {diagnosisData && diagnosisData.map((val, ind) => {
                                                return (
                                                    <span>{val.details}
                                                        {ind !== diagnosisData.length - 1 && <strong>,&nbsp;</strong>}
                                                    </span>
                                                )
                                            }
                                            )}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ verticalAlign: 'top' }}>
                                        <div className='assesmnt1 pt-0 mb-0 mt-0 pb-0'><strong>Procedure:&nbsp;</strong>
                                            {assessmentData && assessmentData.length > 0 ? assessmentData[0].procedureName : ''}
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>

                        {allergyJson && (
                            <td colSpan={6} className='pt-0 mb-0 mt-0' style={{ border: '1px solid' }}>
                                <div className="alrgytbl pt-1 mb-0 mt-0">
                                    <div><strong>ALLERGIES/ADVERSE REACTIONS:</strong></div>
                                    <div>
                                        <label>
                                            Yes
                                            <input type="checkbox" checked={allergyJson.some(item => item.ParameterValue === "YES")} />
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            No
                                            <input type="checkbox" checked={allergyJson.every(item => item.ParameterValue === "NO")} />
                                        </label>
                                    </div>
                                </div>

                                <div className="alrgytbl pt-1 mb-1 mt-1 pb-1">
                                    {/* {allergyJson.map((item, index) => (
                                        <div key={index}>
                                            <label>
                                                {item.parameterName}
                                                <input type="checkbox" checked={item.ParameterValue === "YES"} />
                                            </label>
                                        </div>
                                    ))} */}
                                    <div>
                                        <label>
                                            Medication
                                            <input type="checkbox" checked={allergyJson.some(item => item.parameterName === "Medication" && item.ParameterValue === "YES")} />
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Blood Transfusion
                                            <input type="checkbox" checked={allergyJson.some(item => item.parameterName === "Blood TransFusion" && item.ParameterValue === "YES")} />
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Food
                                            <input type="checkbox" checked={allergyJson.some(item => item.parameterName === "Food" && item.ParameterValue === "YES")} />
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Not Known
                                            <input type="checkbox" checked={allergyJson.every(item => item.ParameterValue !== "YES" && item.ParameterValue !== "NO")} />
                                        </label>
                                    </div>
                                </div>

                                <div className="alrgytbl pt-0 mb-1 mt-0">
                                    <div><strong>If Yes Specify:</strong></div>
                                    <div>
                                        <span style={{ width: '595px', borderBottom: '1px solid black', display: 'inline-block' }}>
                                            {allergyJson && allergyJson.map((allergy, ind) => {
                                                return (
                                                    <span> {allergy.remark}
                                                        {ind !== allergyJson.length - 1 && <strong>,&nbsp;</strong>}
                                                    </span>
                                                )
                                            })}
                                        </span>

                                    </div>
                                </div>
                            </td>
                        )}
                    </tr>
                    <tr>
                        <td colSpan={6} className='pt-0 mb-0 mt-0' style={{ border: '1px solid' }}>
                            <table>
                                <tr>
                                    <td colSpan={6}>
                                        <div style={{ width: '675px', minHeight: '30px', wordWrap: 'break-word' }}>
                                            <strong> CHIEF COMPLAINTS & HISTORY OF PRESENT ILLNESS:&nbsp;</strong>
                                            {/* {chiefComplaintData && chiefComplaintData.length > 0 ? (
                                                chiefComplaintData.map((val, ind) => (
                                                    <span>{val.details}<strong>,</strong>&nbsp;</span>
                                                ))
                                            ) : (
                                                <span>No data</span>
                                            )} */}

                                            {chiefComplaintData && chiefComplaintData.length > 0 ? (
                                                chiefComplaintData.map((val, ind) => (
                                                    <span key={val.detailID}>
                                                        {val.details}
                                                        {ind !== chiefComplaintData.length - 1 && <strong>,&nbsp;</strong>}
                                                    </span>
                                                ))
                                            ) : (
                                                <span>No data</span>
                                            )}
                                        </div>

                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={6} className='pt-0 mb-0 mt-0 pb-0' style={{ border: '1px solid' }}>
                            <table>
                                <tr>
                                    <td colSpan={6}>
                                        <strong>  PAST MEDICAL HISTORY (WITH DURATION OF ILLNESS): &nbsp;</strong>
                                        {/* {pastMedicalSurgeryData && pastMedicalSurgeryData.map((val, ind) => {
                                            return (
                                                <span>{val.details}<strong>,</strong>&nbsp;</span>
                                            )
                                        }
                                        )} */}
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={6} className='pt-0 mb-0 mt-0' style={{ border: '1px solid', padding: '0' }}>
                            <table className='blhistry'>
                                <thead>
                                    <tr style={{ borderBottom: '1px solid' }}>
                                        <td><strong>Illnesses</strong></td>
                                        <td> <strong>Yes</strong></td>
                                        <td><strong>No</strong></td>
                                        <td><strong>Duration</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {medicalJson && medicalJson.map((val, ind) => {
                                        return (
                                            <tr style={{ borderBottom: '1px solid' }}>
                                                <td>{val.parameterName}</td>
                                                <td>{val.ParameterValue === "YES" ? "Yes" : ""}</td>
                                                <td>{val.ParameterValue === "NO" ? "No" : ""}</td>
                                                <td>
                                                    {val.date !== "0000-00-00 00:00:00" ? (
                                                        new Date(val.date).toLocaleDateString('en-GB')
                                                    ) : (
                                                        ""
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }

                                    <tr style={{ borderBottom: '1px solid' }}>
                                        <td colSpan={6}>  Other </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={6} className='pt-0 mb-0 mt-0' style={{ border: '1px solid' }}>
                            <table>
                                <tr>
                                    <td colSpan={6}>
                                        <div style={{ width: '675px', minHeight: '30px', wordWrap: 'break-word' }}>
                                            <strong>  PAST SURGICAL HISTORY: &nbsp;</strong>
                                            {pastMedicalSurgeryData && pastMedicalSurgeryData.length > 0 ? (
                                                pastMedicalSurgeryData.map((val, ind) => (
                                                    <span>{val.details}

                                                        {ind !== pastMedicalSurgeryData.length - 1 && <strong>,&nbsp;</strong>}
                                                    </span>
                                                ))
                                            ) : (
                                                <span>No data</span>
                                            )}
                                        </div>

                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={6} className='pt-0 mb-0 mt-0' style={{ border: '1px solid', padding: '0' }}>
                            <table className='blhistry'>
                                <tr style={{ borderBottom: '1px solid' }}>
                                    <td colSpan="7" style={{ whiteSpace: 'nowrap' }}><strong>Present Medication (For Medication Reconciliation):</strong></td>
                                </tr>
                                <tr style={{ borderBottom: '1px solid' }}>
                                    <td><strong>Sr.No</strong></td>
                                    <td><strong>Current Medication</strong></td>
                                    <td><strong>Dose</strong></td>
                                    <td><strong>Route</strong></td>
                                    <td><strong>Frequency</strong></td>
                                    <td><strong>Date & Time of last dose </strong> </td>
                                    <td><strong>To be continued during hospital stay </strong> </td>
                                </tr>


                                {medication && medication.length > 0 ? (
                                    medication
                                        .filter(val => val.drugId !== 0)
                                        .map((val, ind) => (
                                            <tr style={{ borderBottom: '1px solid' }}>
                                                <td>{ind + 1}</td>
                                                <td>{val.drugName}</td>
                                                <td>{val.dosageStrength}</td>
                                                <td>{val.dosageForm}</td>
                                                <td>{val.doseFrequency}</td>
                                                <td></td>
                                                <td>
                                                    <div className="alrgytbl pt-1 mb-1 mt-1 pb-1">
                                                        <div>
                                                            <label>
                                                                Yes
                                                                <input type="checkbox" id="" />
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                No
                                                                <input type="checkbox" id="" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                ) : (
                                    <tr>
                                        <td colSpan="7">No data</td>
                                    </tr>
                                )}

                                {/* {medication && medication
                                    .filter(val => val.drugId !== 0)
                                    .map((val, ind) => (
                                        <tr style={{ borderBottom: '1px solid' }}>
                                            <td>{ind + 1}</td>
                                            <td>{val.drugName}</td>
                                            <td>{val.dosageStrength}</td>
                                            <td>{val.dosageForm}</td>
                                            <td>{val.doseFrequency}</td>
                                            <td></td>
                                            <td>
                                                <div className="alrgytbl pt-1 mb-1 mt-1 pb-1">
                                                    <div>
                                                        <label>
                                                            Yes
                                                            <input type="checkbox" id="" />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>
                                                            No
                                                            <input type="checkbox" id="" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))} */}



                                {/* {medication && medication.map((val, ind) => {
                                    return (
                                        <tr style={{ borderBottom: '1px solid' }}>
                                            <td>{ind + 1} </td>
                                            <td>{val.drugName} </td>
                                            <td>{val.dosageStrength} </td>
                                            <td> {val.dosageForm}</td>
                                            <td>{val.doseFrequency} </td>
                                            <td> </td>
                                            <td>
                                                <div className="alrgytbl pt-1 mb-1 mt-1 pb-1">
                                                    <div>
                                                        <label>
                                                            Yes
                                                            <input type="checkbox" id="" />
                                                        </label>
                                                    </div>
                                                    <div> <label>
                                                        No
                                                        <input type="checkbox" id="" />
                                                    </label>
                                                    </div>  </div>
                                            </td>
                                        </tr>
                                    )
                                }
                                )} */}




                            </table>
                        </td>
                    </tr>





                </table>
            </div>
        </>
    )
}
