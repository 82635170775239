

async function UpdateAssignDashboardHeaderParameter(id,dashboardParameterId,clientID,userID,assignUserId,data) {
    let url = window.SendXMLDatabaseUl + `/api/Dashboardheaderparameters/UpdateAssignDashboardHeaderParameter?id=${id}&dashboardParameterId=${dashboardParameterId}&clientId=${clientID}&userId=${userID}&assignUserId=${assignUserId}`;
    let token = window.SuperAdminToken;
    let head = {
        'Content-Type': 'application/JSON',
        accept: '*/*',
        'Authorization': token
    }
  
  
    let response = 
    await fetch(url, {
        method: 'PUT',
        headers: head,
        body: JSON.stringify(data)
    })
        .then((res) => res.json())
        .then(data)
  
    return response;
  }
  export default UpdateAssignDashboardHeaderParameter
  
  
  