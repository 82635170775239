import React, { useEffect, useState } from 'react'
import PatientWardName, { PatientAdmitDays, PatientDeaprtmentName } from './AllFieldWard'
import GetAllDashboardParameterColumnAssign from '../../../Api/GetAllDashboardParameterColumnAssign'
import { useSelector } from 'react-redux'

export default function Ward(props) {
    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
           if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

    }
    useEffect(() => {
        getColumnsParameters()
    }, [props])
    return (
        <div className="overflow-max d-flex flex-wrap" >
            {
                paramenterData && paramenterData.map((val, ind) => {
                    if (val.parameterId === 5) {
                        return (<>
                            <PatientWardName patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 7) {
                        return (<>
                            <PatientDeaprtmentName patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                    else if (val.parameterId === 8) {
                        return (<>
                            <PatientAdmitDays patientData={JSON.parse(props.val.patientDataList)} columnId={props.columnId} parameterId={val.parameterId} paramenterData={val} /> &nbsp;
                        </>)
                    }
                })
            }

            {/* <PatientWardName patientData={JSON.parse(props.val.patientDataList)} />
            <PatientDeaprtmentName patientData={JSON.parse(props.val.patientDataList)} />
            <PatientAdmitDays patientData={JSON.parse(props.val.patientDataList)} /> */}
        </div>
    )
}
