async function GetICUAdmissionNote(uhid) {
    let token = 'bearer ' + window.AppToken;
    let url = window.AppbaseUrl + `/api/ICUAdmissionNote/GetICUAdmissionNote?UHID=${uhid}&clientId=${window.clientId}`;
    let head = { "Content-Type": "application/JSON", accept: '*/*', 'Authorization': token };
  
    let response = await fetch(url, {
        headers: head,
        method: 'GET'
    })
        .then((res) => res.json())
        .then();
  
    return response;
  }
  export default GetICUAdmissionNote;
  
  