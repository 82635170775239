async function GetQuestionsBySet(setId) {
    let token = 'bearer ' + window.AppToken;
    let url = window.CounsellingUrl + "api/SetMaster/GetQuestionsBySet?setId=" + setId;
    let head = { "Content-Type": "application/JSON", accept: '*/*', 'Authorization': token };

    let response = await fetch(url, {
        headers: head,
        method: 'GET'
    })
        .then((res) => res.json())
        .then();

    return response;
}
export default GetQuestionsBySet;
