export default async function GetAllConsentDataFieldByConsent(ConsentId){    
   
    let URL = window.AppbaseUrl + `/api/ConsentDataField/GetAllConsentDataFieldByConsent?ConsentID=${ConsentId}&clientID=${window.clientId}`;  
   let head = {"Content-Type":"application/json", 'accept':'*/*'};
   
   let response = "";
   response = await fetch(URL, {
       headers:head,
       method:"GET"        
   })
   .then(res => res.json())  
   .catch(error => response=error);    
   return response;
}