import React, { useEffect, useState } from 'react'
import GetBrandList from '../../Clinical/API/KnowMedsAPI/GetBrandList'
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import Search, { FindByQuery } from '../../Code/Serach'
import GetDiagnosis from './API/GetDiagnosis';
import GetInvestigationDetails from './API/GetInvestigationDetails';
import { clear } from '@testing-library/user-event/dist/clear';
 
export default function InvestigationsRows({investigationsJson, clearData}) {


    let [userID, setUserID] = useState(JSON.parse(sessionStorage.getItem("LoginData")).userId);
    const [cleared, setCleared] = useState(false);
    const { t } = useTranslation();
    document.body.dir = i18n.dir()
    let row = {

        itemId: 0,
        itemName: "",
        userId : userID

    }

    let [saveRow, setSaveRow] = useState([row]);
    let [InvestigationList, setInvestigationList] = useState([])
    let [itemDetailsTemp, setItemDetailsTemp] = useState([row]);
    let [itemDetails, setItemDetails] = useState([row]);
    // let [InvestigationListTemp, setInvestigationListTemp] = useState([])
    // let [InvestigationData, setInvestigationData] = useState([row])
    let [showSearchBoxMedic, setShowSearchBoxMedic] = useState(-1)
 
    let [showMessage, setShowMeassage] = useState("")
    let [showAlertToster, setShowAlertToster] = useState(0)
    let [showSuccessToster, setShowSuccessToster] = useState(0)
    let requiredUHID = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].uhId;
   



let clearCheck = window.sessionStorage.getItem("runClearData")


    let getData = async () => {
        let items = await GetInvestigationDetails()
        if (items.status === 1) {
            // setInvestigationList(items.responseValue)
            // setInvestigationListTemp(items.responseValue)
            setItemDetailsTemp(items.responseValue);
            setItemDetails(items.responseValue);
            console.log("Investigation data" , items.responseValue)
        }
      
      
 
    }

         let handleChange = (e, ind) => {
            let temp = [...saveRow]
            let value = e.target.value
            let name = e.target.name
            console.log("Value typed", value)
            if(name === "itemName"){
            temp[ind]["itemName"] = value;
            // setInvestigationData([...temp]);
            let response = Search(itemDetails, value);
            if (response != 0) {
                setItemDetailsTemp(response);
                setShowSearchBoxMedic(ind);
            }
            }
         
    
      
    }
 
    let handleKeyDown = (e) => {
        if (e.keyCode === 9) {
  
        }
    }
   
   
    let handleDeleteRow = async (ind, id, value, drugId) => {
        let temp = [...saveRow];
        temp.splice(ind, 1);

    if (temp.length > 0) {
        setSaveRow(temp);
    } 
     
  
    }

    let handlClick = (ind, data) => {
        let temp = [...saveRow];
       

 
    
          temp[ind]["itemId"] = data.id;
          temp[ind]["itemName"] = data.itemName;
         
      
          document.getElementById("itemName" + ind).value = data.itemName;
          setShowSearchBoxMedic(-1);
          setSaveRow(temp);
          investigationsJson(temp,data)
          
          
   
      };

const handleAddRow=()=>{
    setSaveRow([...saveRow, row]);
}


const handleClear = () => {
  setSaveRow([row]); 
}

if (clearCheck && JSON.parse(clearCheck) && !cleared) { 
  handleClear();
  setCleared(true);
  setTimeout(() => {
      setCleared(false); 
  }, 1000); 
}


    
    useEffect(() => {
        getData()
    
        
       
        
    }, [])
  

    return (
        <>
          {saveRow && saveRow.map((val, ind) => {
            return(
           <>
                      <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 d-flex gap-2 p-0 mt-2'>
                    <input  type='text' className='opdmedicationinput' name='itemName'  value={val.itemName !== "" ? val.itemName : "" } placeholder={t('Investigations')} id={`itemName${ind}`} onChange={(e) => { handleChange(e, ind) }} style={{width: '-webkit-fill-available'}} />
                   
                     {showSearchBoxMedic === ind ? (
                                  <div className="position-absolute opdmedicationsearchbox" id="listBox">
                                    <ul>
                                      {itemDetailsTemp &&
                                        itemDetailsTemp.map((v, index) => {
                                          return (
                                            <li key={index}
                                              onClick={() => {
                                                handlClick(ind, v);
                                              }}
                                            >
                                              {v.itemName}
                                            </li>
                                          );
                                        })}
                                    </ul>
                                  </div>
                                ) : (
                                  ""
                                )}
                   
            
                  <div className="action-button">
                            
                         <i className="bi bi-plus" onClick={handleAddRow} title="Add Item"  style={{background: '#9be79b54'}}></i>
                           <i className="bi bi-trash3" title="Remove Item" style={{background: '#FFEFEF' , color : 'red'}} onClick={() => { handleDeleteRow(ind)}} id={`removeRowId${ind}`}></i>
                           
                          </div>
                
                </div>
              
            </>
            )
          })
        }
        </>
    )
}