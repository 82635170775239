
async function DeleteSubscriptionTypeMaster(id) {
    let url = window.InventoryBaseUrl + `/api/SubscriptionTypeMaster/DeleteSubscriptionType?Id=` + id;
let head = {
    "Content-Type": "application/JSON",
    accept: "*/*"
};
let data = {};
let response =
    await fetch(url, {
        headers: head,
        method: "DELETE",
    }).then((res) => res.json())
        .then(data)
return response;
}
export default DeleteSubscriptionTypeMaster;


