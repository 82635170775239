import React, { useEffect, useState } from 'react'
import LineInvestigationGrap from '../../../Components/LineInvestigationGrap';
import TimeCalculate from '../../../Code/TimeCalculate';
import GetAllDashboardParameterColumnAssign from '../../../Api/GetAllDashboardParameterColumnAssign';
import Highcharts from 'highcharts';
import { useSelector } from 'react-redux';

export default function MgR(props) {
    let [mg, setMg] = useState("");
    let [mgId, setMgId] = useState("");
    let [mgTime, setMgTime] = useState("");
    let [mgColor, setMgColor] = useState("");


    let [uhId, setUhId] = useState("")

    // set grap Investigation data
    let [grapInvestigationData, setGrapInvestigationData] = useState({
        showGrap: 0,
        subTestId: '',
        patientName: '',
        UHID: '',
        userId: ''
    })

    let getData = () => {
        setMg("");
        setMgId("");
        setMgTime("");
        setMgColor("");


        props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
            if (investigation.SubTestID.toString() === "250") {
                setMg(Math.round(investigation.SubTestValue * 100) / 100)
                setMgColor(investigation.SubTestColor)
                if (props.handleColor !== undefined) {
                    props.handleColor(investigation.SubTestColor !== null ? investigation.SubTestColor : "white", props.columnId)
                }

                setMgId(investigation.SubTestID)
                setUhId(props.patientData.UhId)

                if (investigation.SubTestDateTime !== '') {
                    setMgTime(TimeCalculate(investigation.SubTestDateTime))
                }
                let chartdata = []
                if (props.patientData.PatientNutritionAchievementList !== null && props.patientData.PatientNutritionAchievementList !== undefined) {
                    props.patientData.PatientNutritionAchievementList.map((val, ind) => {

                        if (val.NutrientID === 1178) {
                            if (val.FoodPercentage !== 0) {
                                let slice1 = {
                                    name: '',
                                    y: val.FoodPercentage,
                                    sliced: false,
                                    color: val.FoodPercentageColor
                                }
                                chartdata.push(slice1)
                            }
                            if (val.MedicinePercentage !== 0) {
                                let slice2 = {
                                    name: '',
                                    y: val.MedicinePercentage,
                                    sliced: false,
                                    color: val.MedicinePercentageColor
                                }
                                chartdata.push(slice2)

                            }
                            if (val.FluidPercentage !== 0) {
                                let slice3 = {
                                    name: '',
                                    y: val.FluidPercentage,
                                    sliced: false,
                                    color: val.FluidPercentageColor
                                }
                                chartdata.push(slice3)

                            }
                            if (val.RemainingPercentage !== 0) {
                                let slice4 = {
                                    name: '',
                                    y: val.RemainingPercentage,
                                    color: "white",
                                    sliced: false,
                                }
                                chartdata.push(slice4)

                            }

                            setTimeout(() => {
                                if (document.getElementById("mgId")) {
                                    Highcharts.chart('mgId',
                                        {
                                            chart: {

                                                plotShadow: false,
                                                // spacing: [0, 0, 0, 0],
                                                margin: 0,
                                                spacingBottom: 50,
                                                type: 'pie',
                                                backgroundColor: 'transparent',
                                                width: 35,
                                                height: 35

                                            },
                                            legend: {
                                                backgroundColor: '#FFFFFF',
                                            }
                                            ,
                                            title: {
                                                text: null,
                                                align: null
                                            },
                                            tooltip: {
                                                pointFormat: null
                                            },
                                            accessibility: {
                                                point: {
                                                    valueSuffix: '%'
                                                }
                                            },
                                            plotOptions: {
                                                pie: {
                                                    size: '100%',
                                                    allowPointSelect: true,
                                                    cursor: 'pointer',
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    // colors: ['#f00', '#0f0', '#00f'],


                                                }
                                            },
                                            credits: {
                                                enabled: false,
                                            },
                                            series: [{
                                                enableMouseTracking: false,
                                                data: [...chartdata]
                                            },

                                            ]

                                        }
                                    )
                                }

                            }, 100)

                        }
                    })
                }

            }
        })
    }

    let modelCloseFun = () => {
        setGrapInvestigationData({
            showGrap: 0,
            subTestId: '',
            patientName: '',
            UHID: '',
            userId: ''
        })
    }

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
           if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

    }

    useEffect(() => {
        getColumnsParameters()

        getData()
    }, [props.patientData])
    return (
        <>
            {/* <div key={props.key} className={`overflow-max investigations ${(mgColor !== "#008000" && mgColor !== "#94e094" && mgColor !== "#F5F8FF" && mgColor !== "" ? "blink" : "")} `} style={{ border: (mgColor !== "#008000" && mgColor !== "#94e094" && mgColor !== "#F5F8FF" && mgColor !== "" ? "2px solid " + mgColor : "") }}> */}
            <div key={props.key} className={`overflow-max investigations`}>

                {mg.length != 0 ? mg ? <span>

                    {
                        paramenterData && paramenterData.map((val, ind) => {
                            if (val.parameterId === 68) {
                                return (
                                    <>
                                        <span style={{ color: mgColor }}>{mg}</span>
                                        <br />

                                    </>

                                )
                            }

                            else if (val.parameterId === 69) {
                                return (
                                    <>
                                        {
                                            mgTime.split("-")[1] === "D" && mgTime.split("-")[0] >= 4 ? <span style={{ 'color': 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {mgTime.toLowerCase().replace("-", "")}</span>
                                                : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {mgTime.toLowerCase().replace("-", "")}</span>}
                                        <br />
                                    </>

                                )
                            }

                            // else if (val.parameterId === 70) {
                            //     return (
                            //         <>
                            //             <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='Magnesium (Mg) Graph' >
                            //                 {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                            //                     if (val.SubtestID === mgId) {
                            //                         return (
                            //                             <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                            //                         )
                            //                     }
                            //                 })}
                            //                 <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [mgId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "Magnesium (Mg) Graph", patientName: props.patientData.PntName }) }}></i></div>

                            //             {/* <br /> */}
                            //         </>

                            //     )
                            // }


                        })

                    }
                    <div className="d-flex flex-row gap-2 justify-content-center align-items-center pt-1" title='Mg Graph' >
                        {

                            paramenterData && paramenterData.map((val, ind) => {
                                if (val.parameterId === 70 && props.from === undefined) {
                                    return (
                                        <>
                                            {/* <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [albId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "ALB Graph", patientName: props.patientData.PntName }) }}></i> */}
                                            <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [mgId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "Magnesium (Mg) Graph", patientName: props.patientData.PntName }) }}></i>

                                            {/* <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='ALB Graph' > */}
                                            {/* {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                                                if (val.SubtestID === albId) {
                                                    return (
                                                        <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                                                    )
                                                }
                                            })} */}


                                        </>

                                    )
                                }
                                else if (val.parameterId === 115 && props.from === undefined) {
                                    return (
                                        <div id="mgId" className='' style={{ width: "30x", height: "30px" }}>
                                        </div>
                                    )
                                }
                            })

                        }
                    </div>

                </span> : 'N/A' : 'N/A'}

            </div>
            {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}

        </>
    )
}


export  function MgRG(props) {
    let [mg, setMg] = useState("");
    let [mgId, setMgId] = useState("");
    let [mgTime, setMgTime] = useState("");
    let [mgColor, setMgColor] = useState("");


    let [uhId, setUhId] = useState("")

    // set grap Investigation data
    let [grapInvestigationData, setGrapInvestigationData] = useState({
        showGrap: 0,
        subTestId: '',
        patientName: '',
        UHID: '',
        userId: ''
    })

    let getData = () => {
        setMg("");
        setMgId("");
        setMgTime("");
        setMgColor("");


        props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
            if (investigation.SubTestID.toString() === "250") {
                setMg(Math.round(investigation.SubTestValue * 100) / 100)
                setMgColor(investigation.SubTestColor)
                if (props.setColors !== undefined) {
                    props.setColors((prevArray) => ({ ...prevArray, "Mg+": investigation.SubTestColor }))
                }

                setMgId(investigation.SubTestID)
                setUhId(props.patientData.UhId)

                if (investigation.SubTestDateTime !== '') {
                    setMgTime(TimeCalculate(investigation.SubTestDateTime))
                }
                let chartdata = []
                if (props.patientData.PatientNutritionAchievementList !== null && props.patientData.PatientNutritionAchievementList !== undefined) {
                    props.patientData.PatientNutritionAchievementList.map((val, ind) => {

                        if (val.NutrientID === 1178) {
                            if (val.FoodPercentage !== 0) {
                                let slice1 = {
                                    name: '',
                                    y: val.FoodPercentage,
                                    sliced: false,
                                    color: val.FoodPercentageColor
                                }
                                chartdata.push(slice1)
                            }
                            if (val.MedicinePercentage !== 0) {
                                let slice2 = {
                                    name: '',
                                    y: val.MedicinePercentage,
                                    sliced: false,
                                    color: val.MedicinePercentageColor
                                }
                                chartdata.push(slice2)

                            }
                            if (val.FluidPercentage !== 0) {
                                let slice3 = {
                                    name: '',
                                    y: val.FluidPercentage,
                                    sliced: false,
                                    color: val.FluidPercentageColor
                                }
                                chartdata.push(slice3)

                            }
                            if (val.RemainingPercentage !== 0) {
                                let slice4 = {
                                    name: '',
                                    y: val.RemainingPercentage,
                                    color: "white",
                                    sliced: false,
                                }
                                chartdata.push(slice4)

                            }

                            setTimeout(() => {
                                if (document.getElementById("mgId")) {
                                    Highcharts.chart('mgId',
                                        {
                                            chart: {

                                                plotShadow: false,
                                                // spacing: [0, 0, 0, 0],
                                                margin: 0,
                                                spacingBottom: 50,
                                                type: 'pie',
                                                backgroundColor: 'transparent',
                                                width: 35,
                                                height: 35

                                            },
                                            legend: {
                                                backgroundColor: '#FFFFFF',
                                            }
                                            ,
                                            title: {
                                                text: null,
                                                align: null
                                            },
                                            tooltip: {
                                                pointFormat: null
                                            },
                                            accessibility: {
                                                point: {
                                                    valueSuffix: '%'
                                                }
                                            },
                                            plotOptions: {
                                                pie: {
                                                    size: '100%',
                                                    allowPointSelect: true,
                                                    cursor: 'pointer',
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    // colors: ['#f00', '#0f0', '#00f'],


                                                }
                                            },
                                            credits: {
                                                enabled: false,
                                            },
                                            series: [{
                                                enableMouseTracking: false,
                                                data: [...chartdata]
                                            },

                                            ]

                                        }
                                    )
                                }

                            }, 100)

                        }
                    })
                }

            }
        })
    }

    let modelCloseFun = () => {
        setGrapInvestigationData({
            showGrap: 0,
            subTestId: '',
            patientName: '',
            UHID: '',
            userId: ''
        })
    }

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
           if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

    }

    useEffect(() => {
        getColumnsParameters()

        getData()
    }, [props.patientData])
    return (
        <>
            {/* <div key={props.key} className={`overflow-max investigations ${(mgColor !== "#008000" && mgColor !== "#94e094" && mgColor !== "#F5F8FF" && mgColor !== "" ? "blink" : "")} `} style={{ border: (mgColor !== "#008000" && mgColor !== "#94e094" && mgColor !== "#F5F8FF" && mgColor !== "" ? "2px solid " + mgColor : "") }}> */}
            <div key={props.key} className={`overflow-max investigations`}>

                {mg.length != 0 ? mg ? <span>

                   
                        {

                            paramenterData && paramenterData.map((val, ind) => {
                                if (val.parameterId === 70) {
                                    return (
                                        <>
                                            {/* <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [albId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "ALB Graph", patientName: props.patientData.PntName }) }}></i> */}
                                            <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [mgId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "Magnesium (Mg) Graph", patientName: props.patientData.PntName }) }}></i>

                                            {/* <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='ALB Graph' > */}
                                            {/* {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                                                if (val.SubtestID === albId) {
                                                    return (
                                                        <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                                                    )
                                                }
                                            })} */}


                                        </>

                                    )
                                }
                         
                            })

                        }

                </span> : 'N/A' : 'N/A'}

            </div>
            {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}

        </>
    )
}
