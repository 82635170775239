import React, { useEffect, useState } from 'react'
import i18n, { t } from "i18next";
import uploadimage from '../../../assets/images/dmsimg/uploadimg.svg';
import rejectImage from '../../../assets/images/icons/x-circle.png'
import backitem from '../../../assets/images/dmsimg/Backtbl.svg'

import { useLocation } from 'react-router-dom';
import GetItemDetailsByDemandId from '../../API/UserDemand/GET/GetItemDetailsByDemandId';
import vectorPrint from '../../../assets/images/icons/vectorPrint.png'
import GetItemInformation from '../../API/UserDemand/GET/GetItemInformation';

import vectorMsg from '../../../assets/images/icons/chat-square-text.png';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg'
import { getIn } from 'immutable';
import ApproveItemsInDemand from '../../API/UserDemand/PUT/ApproveItemsInDemand';
import NoDataFound from '../../../assets/images/icons/No data-rafiki.svg'
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Toster from '../../../Component/Toster';
import Search from '../../../Code/Serach';
import InserWorkflowTransaction from '../../../Admin/Api/WorkFlow/WorkflowTransaction/POST/InsertWorkflowTransaction';


export default function DemandItemDetails() {
    const [showDetails, setShowDetails] = useState(false);
    const [itemList, setItemList] = useState([])
    const [itemListMain, setItemListMain] = useState([])
    const [demnadPersonName, setDemandPersonName] = useState('');
    const [demnadNumber, setDemandNumber] = useState('');
    const [demandDate, setDemandDate] = useState('');
    const [priorityType, setPriorityType] = useState('');

    const [getItemName, setItemName] = useState('')
    const [getBrandName, setBrandName] = useState('')
    const [getItemCode, setItemCode] = useState('')
    const [getSeverity, setSeverity] = useState('')
    const [getCategory, setCategory] = useState('')
    const [getSpecification, setSpecification] = useState('')
    const [getImageUrl, setImageUrl] = useState('');
    // Define a state variable to track the input value
    const [inputValues, setInputValues] = useState('');
    const [noDataStatus, setNoDataStatus] = useState(false);
    // const [getIndex, setIndex] = useState('-1');
    // const [isShowPopUp, setIsShowPopUp] = useState(false);
    const [finalArr, setFinalArr] = useState([]);
    let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [showToster, setShowToster] = useState(0);
    let [tosterMessage, setTosterMessage] = useState("");
    let [tosterValue, setTosterValue] = useState(0);

    // const [itemList, setitemList] = useState([]);
    // const [itemListMain, setitemListMain] = useState([]);



    // const location = useLocation();
    const demandID = sessionStorage.getItem('demandId');

    const clientID = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
    const approveStatus = sessionStorage.getItem('approveStatus');
    const workFlowType = sessionStorage.getItem('workFlowType')

    const handleRowClick = () => {
        setShowDetails(true);

    };

    const handleCloseDetails = () => {
        setTimeout(() => {
            setShowDetails(false);
        }, 200)
    };

    const funDemandIdItems = async () => {
        const res = await GetItemDetailsByDemandId(demandID, clientID, approveStatus);

        if (res.status === 0) {
            setTimeout(() => {
                setNoDataStatus(true)
            }, 2000)
        }
        else if (res.responseValue && res.responseValue.length > 0) {

            const demandDate = res.responseValue[0].demandDate.split(' ')[0];
            const demandType = res.responseValue[0].demandType;
            const priority = demandType === 1 ? 'Normal' : demandType === 2 ? 'Urgent' : 'Must'
            setItemList(res.responseValue);
            setItemListMain(res.responseValue);
            setDemandPersonName(res.responseValue[0].demandPerson);
            setDemandNumber(res.responseValue[0].demandNumber)
            setDemandDate(demandDate);
            setPriorityType(priority);
        }


    }

    const funGetItemDetails = async (itemId, brandId) => {
        const resItem = await GetItemInformation(itemId, brandId, clientID);
        if (resItem.status === 1) {
            const finalRes = resItem.responseValue[0]

            setItemName(finalRes.itemName); setBrandName(finalRes.brandName); setItemCode(finalRes.itemCode); setSeverity(finalRes.urgencyLevelName)
            setCategory(finalRes.categoryName); setSpecification(finalRes.specification); setImageUrl(finalRes.imgURL)
        }
        else {
            setNoDataStatus(true)
        }
    }

    // Function to handle input change
    // const handleInputChange = (e, demandQty, ind, itemList) => {


    //     const newValue = parseInt(e.target.value);
    //     const newInputValues = [...inputValues];
    //     newInputValues[ind] = newValue;
    //     setInputValues(newInputValues);

    //     if(isNaN(newValue)){
    //        document.getElementById("checkBoxID" + ind).checked = false;
    //     }
    //     else{
    //         let inputTempArrQty = [...finalArr];
    //     inputTempArrQty[ind] = {
    //         demandId:itemList.demandId,
    //         itemId:itemList.itemId,
    //         brandId:itemList.brandId,
    //         demandNumber: itemList.demandNumber,
    //         demandDate: itemList.demandDate,
    //         approvedQty: newValue,  // Update the approvedQty
    //         approverRemark : document.getElementById('remarkID' + ind).value,  // Copy the existing object
    //     };

    //     const targetInputBox = document.getElementById("checkBoxID" + ind).checked = true;
    //     setFinalArr(inputTempArrQty);
    //     }

    //     // Check if the input value exceeds demand and available quantity
    //     if (newValue > demandQty) {
    //         // Display error message if conditions are met

    //         document.getElementById('errorID' + ind).innerHTML = 'Approved more than demanded.';
    //         document.getElementById('errorID' + ind).style.display = 'block'
    //     } else {
    //         // Hide error message if conditions are not met
    //         document.getElementById('errorID' + ind).style.display = 'none'
    //     }
    // };

    // Function to handle input change
    const handleInputChange = (e, demandQty, itemId, itemList) => {
        const newValue = parseInt(e.target.value);
        const newInputValues = [...inputValues];
        newInputValues[itemId] = newValue;
        setInputValues(newInputValues);

        if (isNaN(newValue)) {
            document.getElementById("checkBoxID" + itemId).checked = false;

        } else {




            if (itemList.id === itemId) {

                let inputTempArrQtyCopy = [...finalArr];
                inputTempArrQtyCopy.push({
                    id: itemList.id,
                    demandId: itemList.demandId,
                    itemId: itemList.itemId,
                    brandId: itemList.brandId,
                    demandNumber: itemList.demandNumber,
                    demandDate: itemList.demandDate,
                    approvedQty: newValue, // Update the approvedQty
                    approverRemark: document.getElementById('remarkID' + itemId).value, // Copy the existing object
                })

                setFinalArr(inputTempArrQtyCopy);
            }
            document.getElementById("checkBoxID" + itemId).checked = true;
        }

        // Check if the input value exceeds demand and available quantity
        if (newValue > demandQty) {
            // Display error message if conditions are met
            document.getElementById("checkBoxID" + itemId).checked = false;
            document.getElementById('errorID' + itemId).innerHTML = 'Approved more than demanded.';
            document.getElementById('errorID' + itemId).style.display = 'block';
        } else {
            // Hide error message if conditions are not met
            document.getElementById('errorID' + itemId).style.display = 'none';
        }
    };


    // for remark input /
    const handleChange = (e, id) => {

        const updatedArr = finalArr.map(item => {
            if (item.id === id) {
                // Update the storeRemark property for the item with the matching id
                return { ...item, approverRemark: e.target.value };
            }
            return item; // Return the original item if the id doesn't match
        });

        setFinalArr(updatedArr);
    }

    // to check one by one data and push it in array //
    const funAddFinalArray = (list, ind) => {
        const targetInputBox = document.getElementById("checkBoxID" + list.id).checked;

        if (targetInputBox === false) {
            let newFinalArr = [...finalArr];
            for (let i = 0; i < newFinalArr.length; i++) {
                if (i === ind) {
                    newFinalArr.splice(i, 1)
                }
            }
            setFinalArr(newFinalArr);
        }
        else {

            let newFinalArr = [...finalArr];
            newFinalArr.push({
                id: list.id,
                demandId: list.demandId,
                itemId: list.itemId,
                brandId: list.brandId,
                demandNumber: list.demandNumber,
                demandDate: list.demandDate,
                approvedQty: document.getElementById('inputID' + list.id).value,  // Update the approvedQty
                approverRemark: document.getElementById('remarkID' + list.id).value,

            });
            console.log('newFinalArr : ', newFinalArr)

            setFinalArr(newFinalArr);

        }
    }

    // to check all the items of the list////
    const funCheckAllItem = () => {
        const mainInputBox = document.getElementById("mainCheckID").checked;
        let tempAllArray = [...finalArr];
        if (itemList.length > 0 && mainInputBox === true) {
            for (let i = 0; i < itemList.length; i++) {
                const itemExists = tempAllArray.some(item => item.itemName === itemList[i].itemName && item.brandName === itemList[i].brandName);
                // const getApprovedQty = document.getElementById('inputID' + itemList[i].id).value;

                if (!itemExists) {
                    document.getElementById('checkBoxID' + itemList[i].id).checked = true;
                    // tempAllArray.push({
                    //     id: itemList[i].id,
                    //     demandId: itemList[i].demandId,
                    //     itemId: itemList[i].itemId,
                    //     brandId: itemList[i].brandId,
                    //     demandNumber: itemList[i].demandNumber,
                    //     demandDate: itemList[i].demandDate,
                    //     approvedQty: getApprovedQty,  // Update the approvedQty
                    //     approverRemark: document.getElementById('remarkID' + itemList[i].id).value,
                    // });
                }
            }

            // setFinalArr(tempAllArray);
        }
        else {
            for (let i = 0; i < itemList.length; i++) {
                document.getElementById('checkBoxID' + itemList[i].id).checked = false;


            }
            // setFinalArr([]);
        }
    }

    const funApproveItems = async (approveStatus) => {
        const tempArr = [];
        // const targetInputBox = document.getElementById("checkBoxID" + list.id).checked;
        for(let i = 0 ; i <itemList.length; i++){
            var targetInputBox = document.getElementById("checkBoxID" + itemList[i].id).checked;
            if(targetInputBox){
                var approveQty = document.getElementById('inputID' + itemList[i].id).value;
                var finalRemark = document.getElementById('remarkID' + itemList[i].id).value;
                tempArr.push({
                    id: itemList[i].id,
                    demandId: itemList[i].demandId,
                    itemId: itemList[i].itemId,
                    brandId: itemList[i].brandId,
                    demandNumber: itemList[i].demandNumber,
                    demandDate: itemList[i].demandDate,
                    approvedQty: approveQty,  // Update the approvedQty
                    approverRemark: finalRemark,

                });
            }
        }
console.log('tempArrrrr : ', tempArr)
       
        const checkedItemIds = []; // Initialize an array to store IDs of checked items

        // Loop through the itemList to check if the checkbox with corresponding index is checked
        for (let i = 0; i < finalArr.length; i++) {

            const checkbox = document.getElementById('checkBoxID' + finalArr[i].id);

            if (checkbox.checked) {
                // Check if approvedQty for this item is empty
                console.log('checkbox.checked : ', checkbox.checked)
                if (approveStatus === 'Approve' && (!finalArr[i].approvedQty)) {
                    alert('Please enter approved quantity for checked items');
                    return;

                }
                checkedItemIds.push(finalArr[i].id); // Push the ID of the checked item
            }
        }

        // Filter out the items from finalArr based on the checked item IDs
        const checkedItems = finalArr.filter(item => checkedItemIds.includes(item.id));



        if (checkedItems.length === 0) {
            alert('Please select any item');
            return;
        } else {

            if (approveStatus === 'Approve') {
                let finalCheckedItemsApprove = checkedItems.map(item => {
                    return {
                        ...item,
                        approveStatus: 1
                    };
                });

                const toSendObj = {
                    id: 0,
                    userID: window.userId,
                    clientID: clientID,
                    userDemandUpdateJson: JSON.stringify(finalCheckedItemsApprove)
                }

                console.log('toSendObj : ', toSendObj);
                console.log('finalCheckedItemsApprove : ', finalCheckedItemsApprove);
                
                if(workFlowType === 'Approve'){
                    let updateRes = await ApproveItemsInDemand(toSendObj);
                setShowUnderProcess(0);
                if (updateRes.status === 1) {
                    for(let i= 0; i<finalCheckedItemsApprove.length;i++){
                        const workflowObj = {
                            "workFlowId":JSON.parse(sessionStorage.getItem("activePage")).workflowId,
                            "userId": window.userId,
                            "designationId": JSON.parse(sessionStorage.getItem("LoginData")).designationId,
                            "dbName": "inventoryservices",
                            "tableName": "userdemandsub",
                            "transactionID": finalCheckedItemsApprove[i].id,
                            "clientId": clientID
                        }
    
                       const resInsert = await InserWorkflowTransaction(workflowObj);
                       console.log('resInsert : ', resInsert)
                    }
                    setInputValues([]);
                    funDemandIdItems();
                    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
                    checkboxes.forEach((checkbox) => {
                        checkbox.checked = false;
                    });
                    const remarkInputs = document.querySelectorAll('input[name="remarkName"]');
                    remarkInputs.forEach((input) => {
                        input.value = ''; // Clear remark input value
                    });
                    const Inputs = document.querySelectorAll('input[name="inputName"]');
                    Inputs.forEach((input) => {
                        input.value = ''; // Clear remark input value
                    });
                    setShowUnderProcess(0);
                    setTosterValue(0);
                    setShowToster(1);
                    setTosterMessage("Items Approved.");
                    setTimeout(() => {
                        setShowToster(0);
                    }, 2000)
                }
                else {
                    setShowUnderProcess(0);
                    setTosterValue(1);
                    setShowToster(1);
                    setTosterMessage(updateRes.responseValue);
                    setTimeout(() => {
                        setShowToster(0);
                    }, 2000)
                }
                }
                else{
                    for(let i= 0; i<finalCheckedItemsApprove.length;i++){
                        const workflowObj = {
                            "workFlowId":JSON.parse(sessionStorage.getItem("activePage")).workflowId,
                            "userId": window.userId,
                            "designationId": JSON.parse(sessionStorage.getItem("LoginData")).designationId,
                            "dbName": "inventoryservices",
                            "tableName": "userdemandsub",
                            "transactionID": finalCheckedItemsApprove[i].id,
                            "clientId": clientID
                        }
    
                       const resInsert = await InserWorkflowTransaction(workflowObj);
                       console.log('resInsert : ', resInsert)
                    }
                    setInputValues([]);
                    funDemandIdItems();
                    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
                    checkboxes.forEach((checkbox) => {
                        checkbox.checked = false;
                    });
                    const remarkInputs = document.querySelectorAll('input[name="remarkName"]');
                    remarkInputs.forEach((input) => {
                        input.value = ''; // Clear remark input value
                    });
                    const Inputs = document.querySelectorAll('input[name="inputName"]');
                    Inputs.forEach((input) => {
                        input.value = ''; // Clear remark input value
                    });
                    setShowUnderProcess(0);
                    setTosterValue(0);
                    setShowToster(1);
                    setTosterMessage("Items Approved.");
                    setTimeout(() => {
                        setShowToster(0);
                    }, 2000)
                }

                
                
            }

            else {
                let finalCheckedItemsReject = checkedItems.map(item => {
                    return {
                        ...item,
                        approveStatus: -1,
                        approvedQty: 0
                    };
                });

                const toSendObj = {
                    id: 0,
                    userID: window.userId,
                    clientID: clientID,
                    userDemandUpdateJson: JSON.stringify(finalCheckedItemsReject)
                }
                
                    let updateRes = await ApproveItemsInDemand(toSendObj);
                setShowUnderProcess(0);
                if (updateRes.status === 1) {
                    setInputValues([]);
                    funDemandIdItems();
                    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
                    checkboxes.forEach((checkbox) => {
                        checkbox.checked = false;
                    });
                    const remarkInputs = document.querySelectorAll('input[name="remarkName"]');
                    remarkInputs.forEach((input) => {
                        input.value = ''; // Clear remark input value
                    });
                    setShowUnderProcess(0);
                    setTosterValue(0);
                    setShowToster(1);
                    setTosterMessage("Items Rejected.");
                    setTimeout(() => {
                        setShowToster(0);
                    }, 2000)
                }
                else {
                    setShowUnderProcess(0);
                    setTosterValue(1);
                    setShowToster(1);
                    setTosterMessage(updateRes.responseValue);
                    setTimeout(() => {
                        setShowToster(0);
                    }, 2000)
                }
                
                
            }

            // Proceed with your logic for approved items
        }
    }

    //handle search
    let handleSearch = (e) => {
        let resp = Search(itemListMain, e.target.value)
        if (e.target !== "") {
            if (resp.length !== 0) {
                setItemList(resp)
            }
            else {
                setItemList([])
            }
        }
        else {
            setItemList(itemListMain)
        }
    }


    useEffect(() => {
        funDemandIdItems();
    }, [])


    return (
        <>

            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className={`col-xxl-${showDetails ? '9' : '12'} col-xl-${showDetails ? '8' : '12'} col-lg-12 col-md-12 col-sm-12 mt-1 ps-0 `}>
                            <div className="med-box" style={{ boxShadow: 'inherit' }}>
                                <div className="row mt-0 pt-0">
                                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 mt-0">
                                        <div className="titleHeading mt-2 pe-2 ps-2" style={{ whiteSpace: 'nowrap' }}>  <img src={backitem} className='icnn' alt='' />{t(" Demand Item Details")}</div>
                                    </div>

                                </div>
                                <div className="row  mt-0 pt-0">
                                    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 mt-0">

                                        <div className='listdetailsct aldmndflxLeft' style={{ boxShadow: 'none' }}>
                                            <div className='listdetailsct-in' style={{ padding: '0px 5px' }}>
                                                <div className='listd-in'>
                                                    <span className='demndnmbr'>Demand Person</span>
                                                </div>
                                                <div className='listd-in'>
                                                    <span className='clrurgnt'>{demnadPersonName}</span>
                                                </div>
                                            </div>
                                            <div className='listdetailsct-in' style={{ borderLeft: '1px solid #5E707B', padding: '0px 5px' }}>
                                                <div className='listd-in'>
                                                    <span className='demndnmbr'>Demand No.</span>
                                                </div>
                                                <div className='listd-in'>
                                                    <span className='clrurgnt'>{demnadNumber}</span>
                                                </div>
                                            </div>
                                            <div className='listdetailsct-in' style={{ borderLeft: '1px solid #5E707B', padding: '0px 5px' }}>
                                                <div className='listd-in'>
                                                    <span className='demndnmbr'>Demand Date</span>
                                                </div>
                                                <div className='listd-in'>
                                                    <span className='clrurgnt'>{demandDate}</span>
                                                </div>
                                            </div>
                                            <div className='listdetailsct-in' style={{ borderLeft: '1px solid #5E707B', padding: '0px 5px' }}>
                                                <div className='listd-in'>
                                                    <span className='demndnmbr'>Priority</span>
                                                </div>
                                                <div className='listd-in' >
                                                    <span className=''>{priorityType}</span>
                                                </div>
                                            </div>
                                            {/* <div className='listdetailsct-in' style={{ borderLeft: '1px solid #5E707B', padding: '0px 5px' }}>
                                                <div className='listd-in'>
                                                    <span className='demndnmbr'>Print Demand</span>
                                                </div>
                                                <div className='listd-in' >
                                                    <span className=''><img src={vectorPrint} alt="" /></span>
                                                </div>
                                            </div> */}


                                        </div>
                                    </div>
                                    <div className='col-xxl-2 col-xl-2 col-lg-2 col-md-2 mt-0'>
                                        <div className='listdetailsct aldmndflx' >
                                            <div className='listdetailsct-in' style={{ boxShadow: 'none' }}>
                                                <div className='listd-in'>
                                                    <form className="d-flex ms-auto ser" role="search">
                                                        <i className="fa fa-search"></i>
                                                        <input type="search" className="form-control form-control-sm" placeholder="Search.." onChange={handleSearch} />
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="med-table-section demanditems pt-2" style={{ height: '72.8vh', padding: '0 5px', position: 'relative' }}>
                                    <table className='med-table border_ striped'>
                                        <thead>
                                            <tr style={{ background: '#EAF6FF' }}>
                                                <th className="text-center" style={{ "width": "3%" }}>
                                                    <input type='checkbox' className='form-check-input' id='mainCheckID' onClick={funCheckAllItem} />
                                                </th>
                                                <th className="text-center" style={{ "width": "3%" }}>#</th>
                                                <th style={{ "width": "4%" }}>{t("")}</th>
                                                <th style={{ "width": "18%" }}>{t("Item Name")}</th>
                                                <th style={{ "width": "12%" }}>{t("Brand")}</th>
                                                <th style={{ "width": "8%" }}>{t("Item Code")}</th>
                                                <th style={{ "width": "8%" }}>{t("Demand Qty")}</th>
                                                <th style={{ "width": "5%" }}>{t("Available Qty")} </th>
                                                <th style={{ "width": "5%" }}>{t("Approve Qty")} </th>
                                                <th className="text-center" style={{ "width": "4%" }}>{t("Unit")}</th>
                                                <th className="text-center" style={{ "width": "5%" }}>{t("Action")} </th>



                                            </tr>
                                        </thead>
                                        <tbody>
                                            {noDataStatus ? (
                                                <tr>
                                                    <td colSpan="9" className="text-center">
                                                        <div className='imageNoDataFound'>
                                                            <img src={NoDataFound} alt="imageNoDataFound" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) :
                                                <>
                                                    {itemList && itemList.map((list, ind) => {
                                                    console.log('listtttgfgggg : ', list)
                                                        return ( <>
                                                            {list.approveStatus === 0? <tr >

                                                                {/* <td className="text-center"> <input type="checkbox" id={'checkBoxID' + list.id} name="" value="" onClick={() => { funAddFinalArray(list, ind) }} /></td> */}
                                                                <td className="text-center"> <input type="checkbox" id={'checkBoxID' + list.id} name="" value=""  /></td>

                                                                {/* <td className="text-center"> <input type="checkbox" id={'checkBoxID' + list.id} name="" value="" onClick={() => { funAddFinalArray(list, ind) }} /></td> */}
                                                                <td className="text-center">{ind + 1}</td>
                                                                <td className="text-center invImg"><img src={list.imgUrl} className='icnn' alt='' style={{ width: '30px', height: '40px' }} /></td>
                                                                <td><div className='persondemandd'>{list.itemName}</div></td>
                                                                <td><div className='storedepartmentt'>{list.brandName}</div></td>
                                                                <td><div className='storecontct'>{list.itemCode}</div></td>
                                                                <td><div className='Demandqwnt'>{list.demandQty}</div></td>
                                                                <td><div className='availableqvt'>{list.availableQty === 0 ? 'Out of stock' : list.availableQty}</div></td>

                                                                <td>
                                                                    <div>
                                                                        <input type='number' className='form-control form-control-sm' name='inputName' id={'inputID' + list.id} onChange={(e) => handleInputChange(e, list.demandQty, list.id, list)} />
                                                                        {/* <input type='number' className='form-control form-control-sm' name='inputName' id={'inputID' + list.id}  /> */}
                                                                        <small id={'errorID' + list.id} className="form-text text-danger" style={{ display: 'none' }}></small>
                                                                    </div>
                                                                </td>
                                                                <td className="text-center"><div className='storecontct'>Mtrs</div></td>
                                                                <td><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <i className="fa fa-eye actionedit viewaction " onClick={() => { handleRowClick(); funGetItemDetails(list.itemId, list.brandId) }}></i>
                                                                    <div className=' action-button cmlBtn' >
                                                                        <div className='p-1' id='#PersonWorkspace' data-bs-toggle="modal" data-bs-target={"#exampleModal" + list.id}> <img src={vectorMsg} alt="" style={{ paddingLeft: '10px' }} /></div>
                                                                    </div>

                                                                </div>
                                                                    <div className="modal fade" id={"exampleModal" + list.id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                        <div className="modal-dialog  modal-lg" style={{ margin: '10% auto' }}>
                                                                            <div className="modal-content">
                                                                                <div className="modal-header_ bg-clrr mt-0 pt-0 mb-0 pb-0">
                                                                                    <div className="heading text-wrap">Remark</div>
                                                                                    <div>
                                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                                                                        //  onClick={() => setShowModal(false)}
                                                                                        ></button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="modal-body mt-0 pt-0">
                                                                                    <div className="med-box" style={{ boxShadow: 'inherit' }}>
                                                                                        <div className="inner-content mt-0 pt-0">
                                                                                            <div className='row'>

                                                                                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3" >
                                                                                                    <label htmlFor="" className="form-label">{t("Enter Remark")}<span className="starMandatory">*</span></label>
                                                                                                    <input type="text" className="form-control form-control-sm" placeholder="Please enter remark here" id={'remarkID' + list.id} name='remarkName' onChange={(event) => handleChange(event, list.id)} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div className="mb-2 mt-3 relative">
                                                                                        <div>
                                                                                            <div className="d-flex flex-wrap align-content-end justify-content-end gap-3">
                                                                                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1" ><img src={clearIcon} className='icnn' alt='' />{t("Cancel")}</button>
                                                                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1">{t("Save")} <img src={saveButtonIcon} className='icnn' /></button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> */}

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </td>

                                                            </tr> :
                                                            <tr >
                                                                <td className="text-center"> <input type="checkbox" id={'checkBoxID' + list.id} name="" value="" disabled /></td>

                                                                {/* <td className="text-center"> <input type="checkbox" id={'checkBoxID' + list.id} name="" value="" onClick={() => { funAddFinalArray(list, ind) }} /></td> */}

                                                                {/* <td className="text-center"> <input type="checkbox" id={'checkBoxID' + list.id} name="" value="" onClick={() => { funAddFinalArray(list, ind) }} /></td> */}
                                                                <td className="text-center">{ind + 1}</td>
                                                                <td className="text-center invImg"><img src={list.imgUrl} className='icnn' alt='' style={{ width: '30px', height: '40px' }} /></td>
                                                                <td><div className='persondemandd'>{list.itemName}</div></td>
                                                                <td><div className='storedepartmentt'>{list.brandName}</div></td>
                                                                <td><div className='storecontct'>{list.itemCode}</div></td>
                                                                <td><div className='Demandqwnt'>{list.demandQty}</div></td>
                                                                <td><div className='availableqvt'>{list.availableQty === 0 ? 'Out of stock' : list.availableQty}</div></td>

                                                                    <td>
                                                                    <div>
                                                                        <input type='number' className='form-control form-control-sm' value={list.approvedQty} id={'inputID' + list.id} disabled
                                                                         />
                                                                        <small id={'errorID' + list.id} className="form-text text-danger" style={{ display: 'none' }}></small>
                                                                    </div>
                                                                </td>
                                                                {/* <td>
                                                                    <div>
                                                                        <input type='number' className='form-control form-control-sm' name='inputName' id={'inputID' + list.id} onChange={(e) => handleInputChange(e, list.demandQty, list.id, list)} />
                                                                        <small id={'errorID' + list.id} className="form-text text-danger" style={{ display: 'none' }}></small>
                                                                    </div>
                                                                </td> */}
                                                                <td className="text-center"><div className='storecontct'>Mtrs</div></td>
                                                                <td><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <i className="fa fa-eye actionedit viewaction " onClick={() => { handleRowClick(); funGetItemDetails(list.itemId, list.brandId) }}></i>
                                                                    <div className=' action-button cmlBtn' >
                                                                        <div className='p-1'  data-bs-toggle="modal" > <img src={vectorMsg} alt="" style={{ paddingLeft: '10px' }} /></div>
                                                                    </div>

                                                                </div>

                                                                </td>

                                                            </tr>
                                                            }
                                                        </>

                                                        )
                                                    })}
                                                </>}

                                        </tbody>
                                    </table>
                                </div>
                                <hr />
                                {(noDataStatus === false) &&
                                    <div className="mb-3 mt-3 relative">
                                        <div>
                                            {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                                <>
                                                    {showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} /> :
                                                        <div>
                                                            <div className="d-flex flex-wrap align-content-end justify-content-end gap-3">
                                                                <button type="button" className="btn btn-reject  btn-sm mb-1 mt-1 me-1" onClick={() => { funApproveItems('Reject') }}>{t("Reject Item")} <img src={rejectImage} className='icnn' alt='' style={{ width: '20px', paddingLeft: '5px' }} /></button>
                                                                {workFlowType === 'Approve' ? <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 mt-1 me-1" onClick={() => { funApproveItems('Approve') }}>{t("Approve Item")} <img src={uploadimage} className='icnn' alt='' style={{ width: '20px', paddingLeft: '5px' }} /></button> :
                                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 mt-1 me-1" onClick={() => { funApproveItems('Approve') }}>{t("Forward Item")} <img src={uploadimage} className='icnn' alt='' style={{ width: '20px', paddingLeft: '5px' }} /></button>}
                                                            </div></div>
                                                    }
                                                </>}

                                        </div>
                                    </div>}

                            </div>
                        </div>
                        {showDetails && <div className="col-xxl-3 col-xl-4 col-lg-12 col-md-12 col-sm-12 mt-1 ps-0">
                            <div className="med-box" style={{ boxShadow: 'inherit' }}>
                                <div className="inner-content clrbg" >
                                    <div className='storeitemspurchase'>
                                        <div className="d-flex flex-wrap justify-content-between pb-2 pt-0 mt-0 ps-0 pe-0">
                                            <div className="heading text-wrap ps-0 pe-0" style={{ background: 'transparent' }}>Item Details</div>
                                            <div>
                                                <button type="button" className="btn-close" onClick={handleCloseDetails}></button>
                                            </div>
                                        </div>
                                        <div className='rightitems'>
                                            <div className='storestock mt-0 pt-0'>
                                                <div className='headng-Pulse'>
                                                    <div className='pulsoxi'>{getItemName}</div>
                                                    <div className='insignia'>{getBrandName}</div>
                                                </div>
                                                <div className='storitems'>
                                                    <div><img src={window.InventoryBaseUrl + '/' + getImageUrl} style={{ width: '80px', paddingLeft: '5px' }} alt='' /></div>
                                                    <div>
                                                        <p className='itemcode'>  Item Code  </p>
                                                        <p className='itemnumbr'>{getItemCode}</p>
                                                        <p className='units'>Unit  </p>
                                                        <p className='unitpeice'> peice 2</p>
                                                    </div>
                                                    <div>
                                                        <p className='itemcode'> Category  </p>
                                                        <p className='itemnumbr'>{getCategory ? getCategory : '--NA--'}</p>
                                                        <p className='units'>Severity  </p>
                                                        <p className='demandpriorty' >{getSeverity}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='specificatin'>
                                                <div className='specifctn' style={{ color: '#546788' }}>Specification</div>
                                                <div className='blodoner'>{getSpecification ? getSpecification : '--NA--'}</div>
                                            </div>
                                            <div className='specificatin'>
                                                <div className='specifctn' style={{ color: '#546788' }}>Justification</div>
                                                <div className='blodoner'> PVT WARD, MRI EXT 8 FLOOR </div>
                                            </div>
                                            <div className='specificatin'>
                                                <div className='specifctn' style={{ color: '#546788' }}>Remarks</div>
                                                <div className='blodoner'> Lorem ipsum dolor. </div>
                                            </div>
                                        </div>
                                        <div className="med-box rightitems mt-4">
                                            <div className='storitems'>
                                                <div>
                                                    <p className='itemcode'> Last Issuing  </p>
                                                    <p className='itemnumbr'>21 Jan 2024</p>
                                                </div>
                                                <div>
                                                    <p className='itemcode'> Last Issue Qty.  </p>
                                                    <p className='itemnumbr'>03</p>

                                                </div>
                                                <div>
                                                    <p className='itemcode'> Total Issued  </p>
                                                    <p className='itemnumbr'>10</p>
                                                </div>
                                            </div>
                                            <div className='headng-Pulse mt-2 mb-2'>
                                                <div className='otherdetls'>Issue log:</div>
                                            </div>

                                            {/* ....tbl... */}
                                            <div className="med-table-section demanditems pt-2 ps-0 pe-0" style={{ height: '37vh', background: 'transparent' }}>
                                                <table className='med-table border_ striped ps-0 pe-0'>
                                                    <thead>
                                                        <tr style={{ background: '#EAF6FF' }}>
                                                            <th className="text-center" style={{ "width": "3%" }}>#</th>
                                                            <th style={{ "width": "8%" }}>{t("Demand No.")}</th>
                                                            <th style={{ "width": "20%" }}>{t("Issue Date")}</th>
                                                            <th style={{ "width": "8%" }}>{t("Qty")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center">1</td>
                                                            <td><div>164523</div></td>
                                                            <td><div className='datetime'>23 Feb 2024 | <span>12:20</span></div></td>
                                                            <td><div className='Demandqwnt'>3</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">2</td>
                                                            <td><div>164523</div></td>
                                                            <td><div className='datetime'>23 Feb 2024 | <span>12:20</span></div></td>
                                                            <td><div className='Demandqwnt'>3</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">3</td>
                                                            <td><div>164523</div></td>
                                                            <td><div className='datetime'>23 Feb 2024 | <span>12:20</span></div></td>
                                                            <td><div className='Demandqwnt'>3</div></td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* ......... */}


                                        </div>

                                    </div>
                                </div>

                            </div>


                        </div>}
                        {/* {isShowPopUp && <div className={`modal d-${isShowPopUp === true ? 'block' : 'none'}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"  >
                            <div className="modal-dialog  modal-lg" style={{ margin: '10% auto' }}>
                                <div className="modal-content">
                                    <div className="modal-header_ bg-clrr mt-0 pt-0 mb-0 pb-0">
                                        <div className="heading text-wrap">Remark</div>
                                        <div>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                              onClick={handleCloseModal}
                                            ></button>
                                        </div>
                                    </div>
                                    <div className="modal-body mt-0 pt-0">
                                        <div className="med-box" style={{ boxShadow: 'inherit' }}>
                                            <div className="inner-content mt-0 pt-0">
                                                <div className='row'>

                                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3" >
                                                        <label htmlFor="" className="form-label">{t("Enter your remark")}</label>
                                                        <input type="text" className="form-control form-control-sm" placeholder="Please enter remark" id={'remarkID'+getIndex} name='remarkName'  />

                                                    </div>
                                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-2" >
                                                        <div className="d-flex flex-wrap align-content-end justify-content-end gap-3">
                                                            <button type="button" className="btn btn-clear btn-sm mb-1 mt-1 me-1" ><img src={clearIcon} className='icnn' alt='' />{t("Cancel")}</button>
                                                            <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 mt-1 me-1">{t("Save")} <img src={saveButtonIcon} className='icnn' alt='' style={{ width: '20px', paddingLeft: '5px' }} /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>} */}

                        {/* {isShowPopUp === 1 ?

                            <div className={`modal d-${isShowPopUp === 1 ? 'block' : 'none'}`} id="codesModal" data-bs-backdrop="static" >
                                <div className="modal-dialog modalDelete" style={{ maxWidth: '550px' }}>
                                    <div className="modal-content" >

                                        <button type="button" className="btn-close_ btnModalClose" data-bs-dismiss="modal" aria-label="Close" title="Close Window"><i className="bi bi-x-octagon" onClick={handleCloseModal}></i></button>



                                    </div>
                                </div>
                            </div>
                            : ''} */}
                    </div>

                </div>

            </section>
        </>
    )
}
