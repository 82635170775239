async function GetOptionMaster(data) {
    let url = window.CounsellingUrl + "api/OptionMaster/GetOptionMaster?ClientId=" + window.clientId;
    let head = { "Content-Type": "application/JSON", accept: '*/*' };
    let response = fetch(url, {
        headers: head,
        method: 'GET'
    }).then((res) => res.json()).then(data);

    return response;
}
export default GetOptionMaster;




