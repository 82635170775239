import TimeCalculate from "../../../Code/TimeCalculate"

export default function PatientWardName(props) {
    return (
        <span className='text-white'>{props.patientData.Ward ? props.patientData.Ward : "N/A"}</span>
    )
}

function PatientDeaprtmentName(props) {
    return (
        <span className='borderLineColor'>{props.patientData.PtDep && props.patientData.PtDep}</span>
    )
}

function PatientAdmitDays(props) {
    return (
        <span className='text-white'>{props.patientData.PtAdmitDays && props.patientData.PtAdmitDays.split(" ")[0]} <span className='fw-semibold borderLineColor'>({TimeCalculate(props.patientData.PtAdmitDays)})</span></span>

    )
}


export {PatientDeaprtmentName, PatientAdmitDays}
