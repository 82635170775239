import React from 'react'
import { useEffect, useState } from 'react'
import PrintPageHeader from '../../../Component/PrintPageHeader';
import PatientLabel from '../../../Component/PatientLabel';
// import sarvodayalogo from '../../../assets/images/icons/sarvodayalogo.png'
export default function MicrobiologyInvestigation() {



    let [microInvestigationData, setmicroInvestigationData] = useState();
    let [microInvestigationJSON, setmicroInvestigationJSON] = useState();
    let [microDiagnosisJSON, setDiagnosisJSON] = useState([]);
    let [getantibioticJson, setgetantibioticJson] = useState([]);
    const [clientData, setclientData] = useState()



    let getMicrobiologyInvestigationDetails = () => {
        console.log('object show');
        let data = JSON.parse(window.sessionStorage.getItem("MicroBiologyInvestigation"));
        setmicroInvestigationData(data)
        console.log("maiData", data)
        setmicroInvestigationJSON(JSON.parse(data.rowData.investigationJson))
        setgetantibioticJson(JSON.parse(data.rowData.antibioticJson))
        let diagnosisJsonCleaned = data.rowData.diagnosisJson
        setDiagnosisJSON(JSON.parse(diagnosisJsonCleaned));

        console.log("data", data)


    }



    const ClientData = () => {
        let data = JSON.parse(window.sessionStorage.getItem("LoginData")).clientdata;
        console.log('ClientData', data)
        setclientData(data)
    }

    useEffect(() => {
        getMicrobiologyInvestigationDetails();
        ClientData()
        setTimeout(() => {
            handlepritnt();
        }, 1200)

    }, []);
    let handlepritnt = () => {
        document.title = 'MicroBiology Investigation';
        window.print("");
        window.close();

    }
    return (
        <>
            <div className="main-table" style={{ padding: '5px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <div className='microbiologyprint'>
                    <table>
                        <tr>
                            <td colSpan={6} className='ps-0 pe-0'>
                                <table className='microbiologyprint '>
                                    {/* ....header.. */}
                                    <tr>
                                        <td colSpan={6}>
                                            <table>
                                                <tr>
                                                    <td colSpan={8}>
                                                        <PrintPageHeader />

                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    {/* ....header.. end*/}
                                    <tr>
                                        <td colSpan={6} className='ps-0 pe-0'>
                                            <table>
                                                <tr>
                                                    <td colSpan={6} style={{ verticalAlign: 'top', textAlign: 'center' }}>
                                                        <div className='micro-heading mt-3 mb-3'>
                                                            MICROBIOLOGY INVESTIGATION REQUEST
                                                        </div>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td colSpan={6} >
                                                        <div className='mt-0 pt-0 mb-0 pb-0 investigationheader'>
                                                            <PatientLabel />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={6} className='ps-0 pe-0 mt-4 pt-4'>
                                            <table className='microinvestigation'>
                                                <tr>
                                                    <th colSpan={3}>Ward/Bed No.: <span style={{ fontWeight: '500', marginLeft: '5px', width: '240px', borderBottom: '1px solid black', display: 'inline-block' }}>{`${microInvestigationData && microInvestigationData.ward}/${microInvestigationData && microInvestigationData.bedNo}`}</span></th>

                                                    <th colSpan={3} style={{ width: '49%' }}>IP No: <span style={{ fontWeight: '500', marginLeft: '5px', width: '280px', borderBottom: '1px  solid black', display: 'inline-block' }}>{microInvestigationData && microInvestigationData.ipNo}</span> </th>
                                                </tr>
                                                <tr>
                                                    <th colSpan={2}>MRN No.: <span style={{ fontWeight: '500', marginLeft: '5px', width: '180px', borderBottom: '1px  solid black', display: 'inline-block' }}>{microInvestigationData && microInvestigationData.rowData.uhid}</span> </th>
                                                    <th colSpan={2} style={{ width: '31%' }}>Date: <span style={{ fontWeight: '500', marginLeft: '5px', width: '150px', borderBottom: '1px  solid black', display: 'inline-block' }}>{microInvestigationData && microInvestigationData.date}</span> </th>
                                                    <th colSpan={2} style={{ width: '31%' }}>Time: <span style={{ fontWeight: '500', marginLeft: '5px', width: '150px', borderBottom: '1px  solid black', display: 'inline-block' }}>{microInvestigationData && microInvestigationData.time && microInvestigationData.time.split(':').slice(0, 2).join(':')}</span> </th>

                                                </tr>
                                                <tr>
                                                    <th colSpan={6}>Consultant Dr.: <span style={{ fontWeight: '500', marginLeft: '5px', width: '598px', borderBottom: '1px  solid black', display: 'inline-block' }}>{microInvestigationData && microInvestigationData.doctorName}</span> </th>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={6} >
                                            <table className="tb-rght mb-4">
                                                <tr>
                                                    <th style={{ verticalAlign: 'top' }}>Provisional Diagnosis: </th>
                                                    <th colSpan={5}><div><span style={{ fontWeight: '500', marginLeft: '5px', width: '560px', borderBottom: '1px  solid black', display: 'inline-block' }}>{microDiagnosisJSON && microDiagnosisJSON.map((val) => val.details).join(', ')}</span></div> </th>

                                                </tr>
                                                <tr>
                                                    <th style={{ verticalAlign: 'top' }}>Antibiotic History:</th>
                                                    <th colSpan={5}> <span style={{ fontWeight: '500', marginLeft: '5px', width: '570px', borderBottom: '1px  solid black', display: 'inline-block' }}>{getantibioticJson && getantibioticJson.map((val) => val.details).join(', ')}</span> </th>
                                                </tr>

                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={6}>
                                            <table className="tb-rght mb-4">
                                                <tr>
                                                    <th colSpan={6}>Name of Specimen: <span style={{ fontWeight: '500', marginLeft: '5px', width: '530px', borderBottom: '1px  solid black', display: 'inline-block' }}>{microInvestigationData && microInvestigationData.rowData.specimenTypeName}</span> </th>

                                                </tr>
                                                <tr>
                                                    <th colSpan={6}>Site (in case of pus and swab sample) (Mandatory): <span style={{ fontWeight: '500', marginLeft: '5px', width: '272px', borderBottom: '1px  solid black', display: 'inline-block' }}>{microInvestigationData && microInvestigationData.rowData.siteName}</span> </th>
                                                </tr>
                                                <tr>
                                                    <th colSpan={6}>Investigation Required: <span style={{ fontWeight: '500', marginLeft: '5px', borderBottom: '1px  solid black', display: 'inline-block' }}> {microInvestigationJSON && microInvestigationJSON.map((val) => val.TestName).join(', ')}</span> </th>
                                                </tr>

                                                {/* <tr> <th colSpan={6}> e) Other Stain: <span style={{ width: '560px', borderBottom: '1px  solid black', display: 'inline-block' }}></span></th></tr> */}
                                                {/* <tr>
                                    <th colSpan={6}>
                                        f) Bacterial Culture and Sensitivity (Aerobic)
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan={6}>
                                        g) Blood culture and Sensitivity (Aerobic) (Please tick appropriate Site)
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan={6} style={{ paddingLeft: '25px' }}>(Site: Peripheral line/ Central Line/Arterial Line/HD Cath/Perma Cath)</th>
                                </tr>
                                <tr> <th colSpan={6}> h) Other tests: <span style={{ width: '560px', borderBottom: '1px  solid black', display: 'inline-block' }}></span></th></tr> */}

                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={6}>
                                            <table className="tb-rght">
                                                <tr>
                                                    <th colSpan={3}>Received by (Name and Sign).: <span style={{ fontWeight: '500', marginLeft: '5px', width: '90px', borderBottom: '1px  solid black', display: 'inline-block' }}></span> </th>
                                                    <th colSpan={3}>Send by (Name and Sign).: <span style={{ fontWeight: '500', marginLeft: '5px', borderBottom: '1px  solid black', display: 'inline-block' }}>{microInvestigationData && microInvestigationData.rowData.advisedBy} </span> </th>
                                                </tr>
                                                <tr>

                                                    <th colSpan={3}>Date and Time:. <span style={{ fontWeight: '500', marginLeft: '5px', width: '160px', borderBottom: '1px  solid black', display: 'inline-block' }}>{microInvestigationData && microInvestigationData.date},{microInvestigationData && microInvestigationData.time}</span> </th>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

        </>
    )
}
