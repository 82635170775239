import React, { useEffect, useState } from "react";
import TosterUnderProcess from '../../Component/TosterUnderProcess';
import Toster from '../../Component/Toster';
import Loder from '../../Component/Loader';
import Select from 'react-select';
import updatee from '../../assets/images/icons/updatee.svg';
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import clearIcon from '../../assets/images/icons/clear.svg';
import deleteBtnIcon from '../../assets/images/icons/delete.svg';
import editBtnIcon from '../../assets/images/icons/edit.svg';
import PostDialysisType from "../API/DialysisTypeMaster/PostDialysisType";
import DeleteDialysisType from "../API/DialysisTypeMaster/DeleteDialysisType";
import PutDialysisType from "../API/DialysisTypeMaster/PutDialysisType";
import GetAllDialysisBooking from "../API/DialysisBooking/GetAllDialysisBooking";
import GetadvicedDoctorsList from "../API/DialysisBooking/GetadvicedDoctorsList";
import GetDialysisType from "../API/DialysisTypeMaster/GetDialysisType";
import GetDialysisPerformDoctor from "../API/DialysisBooking/GetDialysisPerformDoctor";
import PostDialysisBooking from "../API/DialysisBooking/PostDialysisBooking";
import getPatientDetailByUhid from "../API/DialysisBooking/getPatientDetailByUhid";
import Heading from "../../Component/Heading";




 export default function DialysisBooking() {
  const [showUnderProcess, setShowUnderProcess] = useState(0);
  const [showToster, setShowToster] = useState(0);
  const [tosterMessage, setTosterMessage] = useState("");
  const [tosterValue, setTosterValue] = useState(0);
  const [showLoder, setShowLoder] = useState(0);
  const [rowID, setRowID] = useState(0);
  const [isUpdateBtnShow, setIsUpdateBtnShow] = useState(false);
  const [pmID, setpmID] = useState('');
  const [UHID, setUHID] = useState('');
  const [dialysisRoom, setdialysisRoom] = useState('');
  const [bookingTime, setbookingTime] = useState('');
  const [selecteddialysisType, setselecteddialysisType] = useState(null);
  const [DialysisTypeTable, setDialysisTypeTable] = useState([]);
  const [adviceDoctorsList, setadviceDoctorsList] = useState([]);
  const [adviceDoctors, setadviceDoctors] = useState([]);
  const [PerformDoctors, setPerformDoctors] = useState([]);
  const [PerformDoctorsList, setPerformDoctorsList] = useState([]);
  const [selectedPerformDoctors, setselectedPerformDoctors] = useState(null);
  const [bookingDate, setbookingDate] = useState('');
  const [referredBy, setreferredBy] = useState('');
  const [selectedAdviceDoctor, setselectedAdviceDoctor] = useState(null);
  const [dialysisBookingTable, setdialysisBookingTable] = useState([])
  const [newlyAddedRowIndex, setNewlyAddedRowIndex] = useState(null);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [PatientDetailsbyUHID, setPatientDetailsbyUHID] = useState(1);
  const [isNewRowAdded, setIsNewRowAdded] = useState(false);
  const [isClearable, ] = useState(true);
  const [isSearchable, ] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');


  let [userID, setUserID] = useState(JSON.parse(sessionStorage.getItem("LoginData")).userId);

  const clientID  = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
  



  const handleSelectChange = (selectedOption, errorElementId, setSelectedFunction) => {
    document.getElementById(errorElementId).style.display = 'none';
    setSelectedFunction(selectedOption);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
};


  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };


const DropdownsList = async()=>{
  let DialysisType = await GetDialysisType()
  if(DialysisType.status === 1){
    setDialysisTypeTable(DialysisType.responseValue.map(val=>({
      value: val.id,
      label: val.dialysisType
    })))
  }


let AdviceDoctor = await GetadvicedDoctorsList(clientID);
if(AdviceDoctor.status === 1){
  setadviceDoctorsList(AdviceDoctor.responseValue.map(val=>({
    value: val.id,
    label: val.name
  })))
  console.log("doctors" , AdviceDoctor.responseValue)
}

let PerformDoctor = await GetDialysisPerformDoctor()
if(PerformDoctor.status === 1){
  setPerformDoctorsList(PerformDoctor.responseValue.map(val=>({
    value: val.id,
    label: val.name
  })))
  console.log("PerformDoctors" ,PerformDoctor.responseValue)
}


}


  const handleOnChange = async(e) => {
    
    setIsNewRowAdded(false)
    setNewlyAddedRowIndex(null);

  
    const { name, value } = e.target;
  
    if (name === 'UHID') {
      let uhid = e.target.value.toUpperCase()
        setUHID(uhid);
        let data = await getPatientDetailByUhid(uhid)
        if(data.status === 1){
          setPatientDetailsbyUHID(data.responseValue[0])
          console.log("pData>>>" , data.responseValue)
        }
        if(uhid.lengh !== 9 || !PatientDetailsbyUHID){
          
           document.getElementById("pName").value = ""
           document.getElementById("pmID").value = ""
           document.getElementById("gender").value = ""
           document.getElementById("DName").value = ""
           
        }
    
    }
  if (name === 'bookingTime'){
    setbookingTime(value)
  }
  if(name === 'referredBy'){
    setreferredBy(value)
  }
  if(name === 'bookingDate'){
    setbookingDate(value)
  }
  if(name === 'dialysisRoom'){
    setdialysisRoom(value)
  }
  document.getElementById('errUHID').style.display = 'none';
 document.getElementById('errreferredBy').style.display = 'none';
 document.getElementById('errdialysistype').style.display = 'none';
  document.getElementById('erradviceDoctor').style.display = 'none';
 document.getElementById('errperformdoctor').style.display = 'none';
  document.getElementById('errdialysisRoom').style.display = 'none';
 document.getElementById('errbookingDate').style.display = 'none';
  document.getElementById('errbookingTime').style.display = 'none';



  };


const GetallDialysisbooking= async()=>{
let DialysisBooking = await GetAllDialysisBooking()
if(DialysisBooking.status === 1){
    setdialysisBookingTable(DialysisBooking.responseValue)
    setadviceDoctors(DialysisBooking.responseValue[0].adviceDoctors)
    setPerformDoctors(DialysisBooking.responseValue[0].performDoctors)
    console.log('booking',DialysisBooking.responseValue)
    console.log('advice doctors' ,DialysisBooking.responseValue[0].adviceDoctors)
    console.log('performDoctors' ,DialysisBooking.responseValue[0].performDoctors)
}
}


  const handleOnSave = async () => {

    let adviceDoctorJson = selectedAdviceDoctor?.map((doctor) => ({
      doctorId: doctor.value,
      userId: userID,
      clientId: clientID,
    }));
    
    // Create an array for perform doctors
    let performDoctor = selectedPerformDoctors?.map((val)=>({
      doctorId: val.value,
      userId: userID,
      clientId: clientID,
    }))

      




    console.log(selectedAdviceDoctor)
let PName = document.getElementById("pName").value;
 
    if (UHID.trim() === '') {
      document.getElementById('errUHID').innerHTML = 'Please Enter UHID';
      document.getElementById('errUHID').style.display = 'block';
      return;
    }

    if (UHID.length !==9 || PName === '') {
      document.getElementById('errUHID').innerHTML = 'UHID is Invalid';
      document.getElementById('errUHID').style.display = 'block';
      return;
    }
    if (referredBy.trim() === '') {
      document.getElementById('errreferredBy').innerHTML = 'Please Enter Referred By';
      document.getElementById('errreferredBy').style.display = 'block';
      return;
    }
    if (selecteddialysisType === null || undefined) {
      document.getElementById('errdialysistype').innerHTML = 'Please Enter Referred By';
      document.getElementById('errdialysistype').style.display = 'block';
      return;
    }
    if (selectedAdviceDoctor === null || undefined) {
      document.getElementById('erradviceDoctor').innerHTML = 'Please Select Dialysis Advice Doctor';
      document.getElementById('erradviceDoctor').style.display = 'block';
      return;
    }
    if (selectedPerformDoctors === null || undefined) {
      document.getElementById('errperformdoctor').innerHTML = 'Please Select Dialysis Perform Doctor';
      document.getElementById('errperformdoctor').style.display = 'block';
      return;
    }
    if (dialysisRoom === ""){
      document.getElementById('errdialysisRoom').innerHTML = 'Please Enter Room No.';
      document.getElementById('errdialysisRoom').style.display = 'block';
      return;
    }
    if (bookingDate === ''){
      document.getElementById('errbookingDate').innerHTML = 'Please Select Booking Date';
      document.getElementById('errbookingDate').style.display = 'block';
      return;
    }
    if (bookingTime === ''){
      document.getElementById('errbookingTime').innerHTML = 'Please Select Booking Time';
      document.getElementById('errbookingTime').style.display = 'block';
      return;
    }

    let PMID = document.getElementById("pmID").value
    const obj = {
      pmId: PMID,
      uhid: UHID,
      dialysisRoomNo: dialysisRoom,
      dyalysisTypeID: selecteddialysisType.value,
      dialysisBookingDate: bookingDate,
      dialysisTime: bookingTime,
      referredBy: referredBy,
      jsondialysisadvicedoctors: JSON.stringify(adviceDoctorJson),
      jsondialysisperformdoctor: JSON.stringify(performDoctor),
      userId: userID,
      clientId: clientID
  
    };
    console.log('obj', obj)

    let data = await PostDialysisBooking(obj);
    if (data.status === 1) {
      setShowUnderProcess(0);
      setShowToster(1);
      setTosterValue(0);
      setTosterMessage("Data Saved Successfully!");
      GetallDialysisbooking()
      setIsNewRowAdded(true);
      handleClear();
      setTimeout(() => {
        setShowToster(0);
 setIsNewRowAdded(null)

      }, 2000);
    } else {
      setShowUnderProcess(0);
      setShowToster(1);
      setTosterValue(1);
      setTosterMessage("Already Exist!");
      setTosterMessage(data.responseValue);
      setTimeout(() => {
        setShowToster(0);
      }, 2000);
    }
  };

  const handleClear = () => {
    document.getElementById("pName").value = ""
    document.getElementById("pmID").value = ""
    document.getElementById("gender").value = ""
    document.getElementById("DName").value = ""
    setPatientDetailsbyUHID([])
    document.getElementById('errpmID').style.display = 'none';
    document.getElementById('errUHID').style.display = 'none';
    document.getElementById('errreferredBy').style.display = 'none';
    document.getElementById('errdialysistype').style.display = 'none';
     document.getElementById('erradviceDoctor').style.display = 'none';
     document.getElementById('errperformdoctor').style.display = 'none';
    document.getElementById('errdialysisRoom').style.display = 'none';
    document.getElementById('errbookingDate').style.display = 'none';
    document.getElementById('errbookingTime').style.display = 'none';
    setpmID('')
    setUHID('')
    setbookingDate('')
    setbookingTime('')
    setreferredBy('')
    setselecteddialysisType(null)
    setdialysisRoom('')
    setselectedAdviceDoctor(null)
    setselectedPerformDoctors(null)

 

  };


  const edit = (Dialysis , index) => {
    setRowID(Dialysis.id)
    setpmID(Dialysis.dialysisType)
    setIsUpdateBtnShow(true)
    setNewlyAddedRowIndex(index)
  }

  // const handleUpdate = async () => {

   
  //   if (pmID === '') {
  //     document.getElementById('errdialysisType').innerHTML = 'Please Enter PmID';
  //     document.getElementById('errdialysisType').style.display = 'block';
  //     return;
  //   }



  //   const obj = {
   
  //   };
  //   const data = await PutDialysisType(obj);
  //   if (data.status === 1) {
  //     setShowUnderProcess(0);
  //     setTosterValue(0);
  //     setShowToster(1);
  //     setTosterMessage('Data Updated Successfully!');
  //     GetallDialysisbooking()
  //     handleClear();
  //     setIsNewRowAdded(false);
  //     setTimeout(() => {
  //       setShowToster(0);
  //       setNewlyAddedRowIndex(null);
  //     }, 2000);

  //     setIsUpdateBtnShow(false);
  //   } else {

  //     setShowUnderProcess(0);
  //     setShowToster(1);
  //     setTosterMessage(data.responseValue);
  //     setTosterValue(1);
  //     setTimeout(() => {
  //       setShowToster(0);
  //     }, 2000);
  //   }
  // };
  const handleCancel = () => {
    handleClear()
    setIsUpdateBtnShow(false);
    setEditRowIndex(null)
    setNewlyAddedRowIndex(null);

  };

  // const deleteRow = async () => {
  //   setShowUnderProcess(1);
   
  //   const obj = {
  //     id: rowID,
  //     userId: userID,
  //     clientId : clientID
  //   }

  //   let data = await DeleteDialysisType(obj);
  //   if (data.status === 1) {
  //     setShowUnderProcess(0);
  //     setTosterValue(0);
  //     setShowToster(1);
  //     setTosterMessage("Data Deleted Successfully!!");
  //     GetallDialysisbooking()
  //     setIsUpdateBtnShow(false)
  //     setNewlyAddedRowIndex(false)
     
  //     handleClear()
  //     setTimeout(() => {
  //       setShowToster(0);


  //     }, 1000)
  //   }
  //   else {
  //     setShowUnderProcess(0)
  //     setShowToster(0)
  //     setTosterMessage(data.responseValue)
  //     setTosterValue(1)
  //     setTimeout(() => {
  //       setShowToster(0);
  //     }, 2000)
  //   }
  // };




  useEffect(() => {
    GetallDialysisbooking();
    DropdownsList()
    console.log(clientID)
   
  }, []);


  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="med-box">
               
                <div className="inner-content">

                <div className='fieldsett-in col-md-12'>
                <div className="fieldsett">
                    <span className='fieldse'>Dialysis Booking</span>
                  <div className='row'>
              
                     {PatientDetailsbyUHID && (
                              <>
                              
                              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">UHID<span className="starMandatory">*</span></label>
                          <input value={UHID} id="dialysisType" type="text" className="form-control form-control-sm" name="UHID" placeholder= "Enter UHID" onChange={handleOnChange} />
                          <small id="errUHID" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>
                    
                 
                 
                              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Patient Name<span className="starMandatory"></span></label>
                          <input value={PatientDetailsbyUHID.patientName} id="pName" type="text" className="form-control form-control-sm" name="pmID" placeholder= "Patient Name" disabled onChange={handleOnChange} />
                          <small id="errpmID" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">PmID<span className="starMandatory"></span></label>
                          <input value={PatientDetailsbyUHID.pmid} id="pmID" type="text" className="form-control form-control-sm" name="pmID" placeholder= "PmID" disabled onChange={handleOnChange} />
                          <small id="errpmID" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>


                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Gender<span className="starMandatory"></span></label>
                          <input value={PatientDetailsbyUHID.gender} id="gender" type="text" className="form-control form-control-sm" name="pmID" placeholder= "Gender" disabled onChange={handleOnChange} />
                          <small id="errpmID" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Department Name<span className="starMandatory"></span></label>
                          <input value={PatientDetailsbyUHID.departName} id="DName" type="text" className="form-control form-control-sm" name="pmID" placeholder= "Department" disabled onChange={handleOnChange} />
                          <small id="errpmID" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>
                      


                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Referred By<span className="starMandatory">*</span></label>
                          <input value={referredBy} id="referredBy" type="text" className="form-control form-control-sm" name="referredBy" placeholder= "Enter Referred By" onChange={handleOnChange} />
                          <small id="errreferredBy" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                      <label htmlFor="ddlitemmaster" className="form-label">Dialysis Type<span className="starMandatory">*</span></label>
                      <Select value={selecteddialysisType} placeholder="Select Dialysis Type" options={DialysisTypeTable} className="create-select" id="DialysisTypeTable" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, "errdialysistype", setselecteddialysisType)} />
                      <small id="errdialysistype" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                      <label htmlFor="ddlitemmaster" className="form-label">Dialysis Advice Doctors<span className="starMandatory">*</span></label>
                      <Select value={selectedAdviceDoctor} isMulti placeholder="Select Advice Doctor" options={adviceDoctorsList} className="create-select create-select-overflow" id="adviceDoctor" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, "erradviceDoctor", setselectedAdviceDoctor)} />
                      <small id="erradviceDoctor" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                      <label htmlFor="ddlitemmaster" className="form-label">Dialysis Perform Doctors<span className="starMandatory">*</span></label>
                      <Select value={selectedPerformDoctors} placeholder="Select Dialysis Perform Doctor" isMulti options={PerformDoctorsList} className="create-select create-select-overflow" id="DialysisTypeTable" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, "errdialysistype", setselectedPerformDoctors)} />
                      <small id="errperformdoctor" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Dialysis Room<span className="starMandatory">*</span></label>
                          <input value={dialysisRoom} id="dialysisRoom" type="number" className="form-control form-control-sm" name="dialysisRoom" placeholder= "Enter Dialysis Room" onChange={handleOnChange} />
                          <small id="errdialysisRoom" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Booking Date<span className="starMandatory">*</span></label>
                          <input value={bookingDate} min={getCurrentDate()} id="bookingDate" type="date" className="form-control form-control-sm" name="bookingDate"  onChange={handleOnChange} />
                          <small id="errbookingDate" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Booking Time<span className="starMandatory">*</span></label>
                          <input value={bookingTime} min={getCurrentTime()} id="bookingTime" type="time" className="form-control form-control-sm" name="bookingTime"  onChange={handleOnChange} />
                          <small id="errbookingTime" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>
                              
                              
                              </>
                     )}
              
     
                 
                 
                  



                        <div className="col-xxl-4 col-xl-3 col-lg-4 col-md-6 mb-3 relative">
                          <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>

                          {showUnderProcess === 1 ? <><TosterUnderProcess />  </> :
                            showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} />
                              :
                              <div>
                                {isUpdateBtnShow !== true ? <>
                                  <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleOnSave}><img src={saveButtonIcon} className='icnn' alt="" />Save</button>
                                  <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt="" />Clear</button>
                                </> :
                                  <>
                                    <button type="button" className="btn btn-save btn-sm mb-1 me-1 "> <img src={updatee} className='icnn' alt=""/> Update</button>
                                    <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleCancel} >Cancel</button>
                                  </>
                                }
                              </div>
                          }
                        </div>
                      </div>
                    </div>
                    </div>
                    </div>
   

              </div>
            </div>


            


            <div className="col-12 mt-3">
            <div className='handlser'>
                                <Heading text="Dialysis Booking List" />
                                {/* <Heading text={content} /> */}
                                <div style={{ position: 'relative' }}>
                                    <input type="text" className='form-control form-control-sm' placeholder="Search"  value={searchTerm} onChange={handleSearch} />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
              <div className="med-table-section" style={{ "height": "80vh" }}>
                <table className="med-table border_ striped">
                  <thead style={{ zIndex: '0' }}>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>

                    
                      <th>UHID</th>
                      <th>Dialysis Type</th>
                      <th>Dialysis Room No.</th>
                      <th>Dialysis Advice Doctors</th>
                      <th>Dialysis Perform Doctors</th>
                      <th>Referred By</th>
                      <th>Dialysis Booking Date/Time</th>
             
                      {/* <th style={{ "width": "10%" }} className="text-center">Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {dialysisBookingTable && dialysisBookingTable.filter((val) => `${val.uhid} ${val.referredBy}`.toLowerCase().includes(searchTerm.toLowerCase())).map((data, index) => {
                      const isNewRow = newlyAddedRowIndex === index;
                      const isEditing = index === editRowIndex;
                      
                      const dialysisBookingDate = data.dialysisBookingDate ? new Date(data.dialysisBookingDate) : null;

                      const formatDate = (date) => {
                      if (!date) return '';
                      const day = date.getDate().toString().padStart(2, '0');
                      const month = (date.getMonth() + 1).toString().padStart(2, '0');
                      const year = date.getFullYear();
                      const hours = date.getHours().toString().padStart(2, '0');
                      const minutes = date.getMinutes().toString().padStart(2, '0');
                      return `${day}/${month}/${year} At ${hours}:${minutes}`;
                    };
                      
                      return (
                        <tr className={index === dialysisBookingTable.length - 1 && isNewRowAdded ? 'new-row' : '' || isNewRow ? 'new-row' : ''} key={index}>
                          <td className="text-center">{index + 1}</td>
                        
                          <td>{data.uhid}</td>
                          <td>{data.dialysisType}</td>
                          <td>{data.dialysisRoomNo}</td>
                        
             {data.adviceDoctors && (
  <td>
    {data.adviceDoctors.map((val, index) => (
      <span key={index}>
        {val.name}
        {index < data.adviceDoctors.length - 1 && <br />} 
      </span>
    ))}
  </td>
)}
      {data.performDoctors && (
  <td  >
    {data.performDoctors.map((val, index) => (
      <span  key={index}>
    {val.name}
        {index < data.performDoctors.length - 1 && <br />}
      </span>
    ))}
  </td>
)}
                          
  
                          <td>{data.referredBy}</td>
                          <td>{formatDate(dialysisBookingDate)}</td>
                        
                          
      

                          <td>
                            {/* <div className="action-button">
                              <div
                                data-bs-toggle="tooltip"
                                data-bs-title="Edit Row"
                                data-bs-placement="bottom"

                              >
                                <img src={editBtnIcon} className={isEditing ? 'edited-row' : ''} alt='' onClick={() => { edit(data, index) }} />
                              </div>
                              <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowID(data.id); }} />
                              </div>
                            </div> */}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* -----------------------Start Delete Modal Popup-------------------    */}

        <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
          <div className="modal-dialog modalDelete">
            <div className="modal-content">
              <div className="modal-body modelbdy text-center">
                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                <div className='popDeleteTitle mt-3'>Delete?</div>
                <div className='popDeleteContent'>Are you sure you want to delete?</div>
              </div>
              <div className="modal-footer1 text-center">

                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn-delete popBtnDelete" data-bs-dismiss="modal" >Delete</button>
              </div>
            </div>
          </div>
        </div>
        {/* -----------------------End Delete Modal Popup---------------------  */}
        {
          showLoder === 1 ? <Loder val={showLoder} /> : ""
        }
      </section>

    </>

  )
}


