import React, { useEffect, useState } from 'react'
import GetRegistartionFor from '../API/RegistartionFormDynamic/GetRegistartionFor'
import GetRegistartionField from '../API/RegistartionFormDynamic/GetRegistartionField'
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import clearIcon from '../../assets/images/icons/clear.svg';

export default function RegistrationForm() {
    const [regTypeList, setregTypeList] = useState([])
    const [RegistrationFields, setRegistrationFields] = useState([])
    const [RegTypeID, setRegTypeID] = useState("")
    const [isDisplayNone, setisDisplayNone] = useState(false)
    const [formValues, setFormValues] = useState({});

    const getRegType=async()=>{
        let data = await GetRegistartionFor();
        if(data.status === 1){
            setregTypeList(data.responseValue);
            console.log("regType" , data.responseValue)
        }
    }

    const handleOnChange = async (e) => {
        const { name, value } = e.target;
        if (name === "regType") {
          setRegTypeID(value);
          let data = await GetRegistartionField(value);
          if (data.status === 1) {
            setRegistrationFields(data.responseValue);
            console.log("RegistrationFields", data.responseValue);
            setisDisplayNone(!isDisplayNone)
       
          }
        }
      };
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
          ...formValues,
          [name]: value,
        });
      };

      const handleSave = () => {
        console.log("Form Values:", formValues);
      }
    useEffect(() => {
        getRegType()
    }, [])
    
    
  return (
  <>
  
  <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div class="col-12"><div class="med-box  mb-1 d-flex justify-content-between">
                <div class="title">
                Registration Form
                </div>
                <div className='p-2'>
                <select id="Payment" className="form-control form-control-sm" name="regType" value={RegTypeID} onChange={handleOnChange} >
                <option value="0">Select Registration For</option>
                    {regTypeList && regTypeList.map((val, index)=>{
                         
                        return(
                            
                            <option value={val.id}>{val.type}</option>
                        )
                    })}
                    
                   
                           </select>
                </div>
            {/* <div class="title pe-3" style={{fontSize: '12px'}}>GSTIN: {LicenseDetails.licenseNo}</div> */}
            </div></div>
            <div className="col-12">
              <div className="med-box">
                <div className="inner-content" style={{ paddingRight: '22px' }}>
                  <div className="row">
                   
                      <div className='fieldsett-in col-md-12'>
                        <div className='fieldsett'>
                          <span className='fieldse'>Registration Details</span>
                      
                          <div className="row">

                            <div className={`col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3 ${isDisplayNone === true ? 'd-none' : ''}`}>
                           
                              <label for="UHID" className="form-label">
                                Name <span className="starMandatory">*</span>
                              </label>

                              <input
                                type="text"
                                className="form-control form-control-sm ms-2"
                                id="Name"

                                placeholder="Name"
                                name="Name"

                              />


                            </div>
                            <div className={`col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3 ${isDisplayNone === true ? 'd-none' : ''}`}>
                           
                              <label for="UHID" className="form-label">
                                Roll Number <span className="starMandatory">*</span>
                              </label>

                              <input
                                type="text"
                                className="form-control form-control-sm ms-2"
                                id="Name"

                                placeholder="Roll Number"
                                name="Name"

                              />


                            </div>



                            <div className={`col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3 ${isDisplayNone === true ? 'd-none' : ''}`}>
                              <div className="mb-2">
                                <div className="d-flex align-items-baseline">
                                  <label htmlFor="FullName*" className="form-label">Date of Birth</label>
                                </div>

                                <input type="date" className="form-control form-control-sm" id="PName" name="donor"/>
                              </div>
                            </div>
                            <div className={`col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3 ${isDisplayNone === true ? 'd-none' : ''}`}>
                              <div className="mb-2">
                                <div className="d-flex align-items-baseline">
                                <label htmlFor="gender" className="form-label">Gender</label>
                                </div>
                                <input type="text" className="form-control form-control-sm" id="Pgender" name="donor" placeholder="Gender" />

                              </div>
                            </div>

                            <div className={`col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3 ${isDisplayNone === true ? 'd-none' : ''}`}>
                              <div className="mb-2">
                                <div className="d-flex align-items-baseline">
                                 <label htmlFor="dob" className="form-label">Nationality</label>
                                </div>

                                <input type="text" className="form-control form-control-sm" id='PAge' name='regDate' placeholder='Nationality'  />
                              </div>
                            </div>

                            <div className={`col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3 ${isDisplayNone === true ? 'd-none' : ''}`}>
                              <div className="mb-2">
                                <div className='d-flex align-items-baseline'>
                                <label htmlFor="bloodGroup" className="form-label">Address</label>
                                </div>

                                <input type="text" className="form-control form-control-sm" id="PWard"  name="ddlBloodGroup" placeholder="Address"  />
                              </div>
                            </div>

                            <div className={`col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3 ${isDisplayNone === true ? 'd-none' : ''}`}>
                              <div className="mb-2">
                                <div className='d-flex align-items-baseline'>
                                  <label htmlFor="identity" className="form-label">City</label>
                                </div>
                                <input type="text"  className="form-control form-control-sm" id="Pdepartment" name="ddlIdentityType" placeholder="City"  />
                              </div>
                            </div>
                            <div className={`col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3 ${isDisplayNone === true ? 'd-none' : ''}`}>
                              <div className="mb-2">
                                <div className='d-flex align-items-baseline'>
                                  <label htmlFor="identity" className="form-label">State</label>
                                </div>
                                <input type="text"  className="form-control form-control-sm" id="Pdepartment" name="ddlIdentityType" placeholder="State"  />
                              </div>
                            </div>
                            <div className={`col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3 ${isDisplayNone === true ? 'd-none' : ''}`}>
                              <div className="mb-2">
                                <div className='d-flex align-items-baseline'>
                                  <label htmlFor="identity" className="form-label">Postal Code</label>
                                </div>
                                <input type="number"  className="form-control form-control-sm" id="Pdepartment" name="ddlIdentityType" placeholder="Postal Code"  />
                              </div>
                            </div>
                            <div className={`col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3 ${isDisplayNone === true ? 'd-none' : ''}`}>
                              <div className="mb-2">
                                <div className='d-flex align-items-baseline'>
                                  <label htmlFor="identity" className="form-label">Contact Number</label>
                                </div>
                                <input type="number"  className="form-control form-control-sm" id="Pdepartment" name="ddlIdentityType" placeholder="Contact Number"  />
                              </div>
                            </div>
                            <div className={`col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3 ${isDisplayNone === true ? 'd-none' : ''}`}>
                              <div className="mb-2">
                                <div className='d-flex align-items-baseline'>
                                  <label htmlFor="identity" className="form-label">Email Address</label>
                                </div>
                                <input type="text"  className="form-control form-control-sm" id="Pdepartment" name="ddlIdentityType" placeholder="Email Address"  />
                              </div>
                            </div>
                            <div className={`col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3 ${isDisplayNone === true ? 'd-none' : ''}`}>
                              <div className="mb-2">
                                <div className='d-flex align-items-baseline'>
                                  <label htmlFor="identity" className="form-label">Parent/Guardian Full Name</label>
                                </div>
                                <input type="text"  className="form-control form-control-sm" id="Pdepartment" name="ddlIdentityType" placeholder="Parent/Guardian Full Name"  />
                              </div>
                            </div>
                            <div className={`col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3 ${isDisplayNone === true ? 'd-none' : ''}`}>
                              <div className="mb-2">
                                <div className='d-flex align-items-baseline'>
                                  <label htmlFor="identity" className="form-label">Relationship to Student</label>
                                </div>
                                <input type="text"  className="form-control form-control-sm" id="Pdepartment" name="ddlIdentityType" placeholder="Relationship to Student"  />
                              </div>
                            </div>
                            <div className={`col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3 ${isDisplayNone === true ? 'd-none' : ''}`}>
                              <div className="mb-2">
                                <div className='d-flex align-items-baseline'>
                                  <label htmlFor="identity" className="form-label">Parent/Guardian Contact Number</label>
                                </div>
                                <input type="number"  className="form-control form-control-sm" id="Pdepartment" name="ddlIdentityType" placeholder="Parent/Guardian Contact Number"  />
                              </div>
                            </div>
                            <div className={`col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3 ${isDisplayNone === true ? 'd-none' : ''}`}>
                              <div className="mb-2">
                                <div className='d-flex align-items-baseline'>
                                  <label htmlFor="identity" className="form-label">Previous School Name</label>
                                </div>
                                <input type="text"  className="form-control form-control-sm" id="Pdepartment" name="ddlIdentityType" placeholder="Previous School Name"  />
                              </div>
                            </div>
                            <div className={`col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3 ${isDisplayNone === true ? 'd-none' : ''}`}>
                              <div className="mb-2">
                                <div className='d-flex align-items-baseline'>
                                  <label htmlFor="identity" className="form-label">Previous School Address</label>
                                </div>
                                <input type="text"  className="form-control form-control-sm" id="Pdepartment" name="ddlIdentityType" placeholder="Previous School Address"  />
                              </div>
                            </div>
                            <div className={`col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3 ${isDisplayNone === true ? 'd-none' : ''}`}>
                              <div className="mb-2">
                                <div className='d-flex align-items-baseline'>
                                  <label htmlFor="identity" className="form-label">Grade/Year Completed</label>
                                </div>
                                <input type="number"  className="form-control form-control-sm" id="Pdepartment" name="ddlIdentityType" placeholder="Grade/Year Completed"  />
                              </div>
                            </div>

                            <div className={`col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3 ${isDisplayNone === true ? 'd-none' : ''}`}>
                              <div className="mb-2">
                                <div className='d-flex align-items-baseline'>
                                  <label htmlFor="identity" className="form-label">Grade/Year Applying For</label>
                                </div>
                                <input type="number"  className="form-control form-control-sm" id="Pdepartment" name="ddlIdentityType" placeholder="Grade/Year Applying For"  />
                              </div>
                            </div>

                            {RegistrationFields && RegistrationFields.map((val , ind)=>{
                                return(
                                    <>
                                  <div key={ind} className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 mb-3">
                              <div className="mb-2">
                                <div className='d-flex align-items-baseline'>
                                  <label htmlFor="identity" className="form-label">{val.label}</label>
                                </div>
                                <input type="text" className="form-control form-control-sm" id="Pdepartment" name={val.name} placeholder={val.label} onChange={handleInputChange}  />
                              </div>
                            </div>
                                    </>
                                )
                            })} 
                           

                          </div>
                                

                        </div>
                        <div className="row justify-content-end">
                                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3 mt-3 rt-btns ">
                                                   <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                                   <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1"  onClick = {handleSave}><img src={saveButtonIcon} className='icnn' alt="" />Submit</button>
                                                    <button type="button" className="btn btn-clear btn-sm mb-1 me-1" ><img src={clearIcon} className='icnn' alt= ""/>Clear</button>
                                                   </div>
                                      </div>

                      </div>



                  
                  
                  </div>


                </div>



              </div>

            </div>
          </div>




        </div>

      </section>
  </>
  )
}
