async function DeleteWorkFlowMaster(data) {
    // let token = window.SuperAdminToken;
    let url = window.UserbaseUrl + "/api/WorkFlowMaster/DeleteWorkFlowMaster";
    // let url = "https://localhost:7235/api/WorkFlowMaster/DeleteWorkFlowMaster";
    let head = {
        "Content-Type": "application/JSON",
        accept: "*/*",
        // 'Authorization': token,
    };
    let response =
      await fetch(url, {
        method: 'DELETE',
        headers: head,
        body: JSON.stringify(data)
      })
        .then((res) => res.json())
        .then(data)
  
  
    return response;
  }
  export default DeleteWorkFlowMaster;