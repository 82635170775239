

export default async function GetPatientDetailsforSarvodaya(requiredUHID){    
    const clientID=JSON.parse(sessionStorage.getItem("LoginData")).clientId;
    let URL = window.SendXMLDatabaseUl +`/api/PatientPersonalDashboard/GetPatientDetailsByUHID?UHID=${requiredUHID}`;
    //  let URL = window.BillingbaseUrl +`/api/Billing/getBillingPatientDetailsByUhId?uhId=${UHID}&billNo=${BillNo}`;
    let head = {"Content-Type":"application/json", 'accept':'*/*'};
    
    let response = "";
    response = await fetch(URL, {
        headers:head,
        method:"GET"        
    })
    .then(res => res.json())  
    .catch(error => response=error);    
    return response;
}