async function GetRadiologyReport(uhid) {
    let url = window.RadiologyservicesUrl + `/api/IpDFileRadiology/IpDFileRadiologyList?UhID=${uhid}`;
    let head = { 'Content-Type': 'application/JSON', accept: '*/*' };
    let data = {};

    let response = await fetch(url, {
        headers: head,
        method: 'GET'
    }).then((res) => res.json()).then(data);

    return response;
}

export default GetRadiologyReport;