async function GetPatientRestraintById(ClientId, uhid,Id) {
    let url = window.AppbaseUrl + `/api/PatientRestraint/GetPatientRestraintById?ClientId=${ClientId}&UhId=${uhid}&Id=${Id}`;
    let head = { "Content-Type": "application/JSON", accept: '*/*' };
    let data = {}
    let response = fetch(url, {
        headers: head,
        method: 'GET'
    })
        .then((res) => res.json())
        .then(data);

    return response;
}
export default GetPatientRestraintById;