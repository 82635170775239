function ValidationsBloodissueListSave ( bagReciviedBy = '', bagReciviedByContact = '', bagReciviedByAddress = '') {
  
    if(bagReciviedBy !== 0 && bagReciviedBy.trim().length !== 0 && bagReciviedByContact !== 0 && bagReciviedByContact.trim().length !== 0 && bagReciviedByAddress !== 0 && bagReciviedByAddress.trim().length !== 0){
        return [true, ""]
    }
    else if (bagReciviedBy === "" || bagReciviedBy.trim().length === 0) {
        return [false, "Please Enter bag Recivied By"]
    }

    else if (bagReciviedByContact === "" || bagReciviedByContact.trim().length === 0) {
        return [false, "Please Enter bag Recivied By Contact"]
    }

    else if (bagReciviedByAddress === "" || bagReciviedByAddress.trim().length === 0) {
        return [false, "Please Enter bag Recivied By Address"]
    }
  
     
    }
    export default ValidationsBloodissueListSave;

    