import React, { useEffect, useState } from 'react'
import IOPopup from '../../../Components/IOPopup';
import IOGraph from '../../../Components/IOGraph';
import GetAllDashboardParameterColumnAssign from '../../../Api/GetAllDashboardParameterColumnAssign';
import { useSelector } from 'react-redux';

export default function IOR(props) {

    let [io, setIo] = useState("");
    let [ioId, setIoId] = useState("");
    let [ioTime, setIoTime] = useState("");
    let [ioColor, setIoColor] = useState("");
    let [uhId, setUhId] = useState("")

    let [showIOPopUP, setShowIOPopUP] = useState(0)
    let [showIOGraph, setShowIOGraph] = useState(0)
    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
        if (resp.length !== 0) {
            setPatameterData(resp[0].data)
        }

    }


    useEffect(() => {
        getColumnsParameters()

    }, [props.patientData])
    return (
        <>
            {/* <div key={props.key} className={`overflow-max investigations ${(ioColor !== "#008000" && ioColor !== "#94e094" && ioColor !== "#F5F8FF" && ioColor !== "" ? "blink" : "")} `} style={{ border: (ioColor !== "#008000" && ioColor !== "#94e094" && ioColor !== "#F5F8FF" && ioColor !== "" ? "2px solid " + ioColor : "") }}> */}
            <div key={props.key} className={`overflow-max investigations`}>

                <span style={{ color: ioColor }} onClick={() => { setShowIOPopUP(1) }}>

                    {
                        paramenterData && paramenterData.map((val, ind) => {
                            return (
                                <>
                                    <>
                                        {
                                            props.patientData.PatientAnuria_OliguriaList !== null && props.patientData.PatientAnuria_OliguriaList !== undefined ?

                                                <span >
                                                    {
                                                        <div>
                                                            <span>
                                                                {
                                                                    val.parameterId === 101 ? props.patientData.PatientInputOutputList[0].Input + "/" : ""
                                                                }
                                                                <span style={{ color: props.patientData.PatientAnuria_OliguriaList[0].UrineOutputColor }} title={props.patientData.PatientAnuria_OliguriaList[0].UrineOutputMessage}>
                                                                    {
                                                                        val.parameterId === 102 ? props.patientData.PatientInputOutputList[0].Output : ""
                                                                    }
                                                                </span>
                                                            </span>
                                                            <span>
                                                                {
                                                                    val.parameterId === 103 ?
                                                                        <span style={{ color: props.patientData.PatientAnuria_OliguriaList[0].UrineProblemColor }} >
                                                                            <i className="bi bi-lungs-fill"></i>&nbsp;
                                                                            {
                                                                                props.patientData.PatientAnuria_OliguriaList[0].UrineProblem
                                                                            }

                                                                        </span> : ""
                                                                }
                                                            </span>

                                                        </div>

                                                    }
                                                </span>


                                                :

                                                <div className='d-flex flex-row justify-content-center'>
                                                    {
                                                        props.patientData.PatientInputOutputList !== null && props.patientData.PatientInputOutputList !== undefined ?

                                                            <span>
                                                               
                                                                    {
                                                                        val.parameterId === 101 ? props.patientData.PatientInputOutputList[0].Input + "/" : ""
                                                                    }
                                                                    <span >
                                                                        {
                                                                            val.parameterId === 102 ? props.patientData.PatientInputOutputList[0].Output : ""
                                                                        }
                                                                    </span>
                                                               
                                                                {
                                                                    val.parameterId === 103 ? props.patientData.PatientInputOutputList[0].IsLowUrine === 1 ?
                                                                        <div style={{ marginBottom: '5px' }} className='pticons justify-content-center'>
                                                                            <i className='bi bi-clock-history imgHover' title={"Low Urine in last 24 hrs" + "\n Input " + props.patientData.PatientInputOutputList[0].Input + " ml" + "\n Output " + props.patientData.PatientInputOutputList[0].Output + " ml"}></i>
                                                                        </div> : "" : ""
                                                                }
                                                            </span>
                                                            : ""



                                                    }


                                                </div>
                                        }


                                    </>

                                    { props.patientData.PatientInputOutputList !== null && props.patientData.PatientInputOutputList !== undefined ? val.parameterId === 104 ? <i i className="fas fa-chart-line text-white pointer" title='Input/Output Graph' onClick={() => { setShowIOGraph(1) }}></i> : "":""}
                                </>
                            )
                        })

                    }

                </span><br />


            </div >

            {

                showIOPopUP === 1 ? <IOPopup patientdata={props.patientData} showpopup={showIOPopUP} modelCloseFun={setShowIOPopUP} /> : ""
            }
            {
                showIOGraph === 1 ? <IOGraph showIOGraph={showIOGraph} patientdata={props.patientData} modelCloseFun={setShowIOGraph} /> : ""
            }
        </>
    )
}
