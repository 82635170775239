import React,{ useEffect, useState } from 'react'
import TosterUnderProcess from '../../Component/TosterUnderProcess';
import Toster from '../../Component/Toster';
import Loder from '../../Component/Loader';
import clearIcon from '../../assets/images/icons/clear.svg';

import AlertToster from "../../Component/AlertToster";
import bloddgroup from '../../BloodBank/images/bloddgroup.svg'
import GetBloodGroup from '../Api/BloodDonorRegestration/GetBlood/GetBloodGroup';
import GetBloodStock from '../Api/BloodDonorRegestration/GetBlood/GetBloodStock';

export default function BloodStockReport() {
 

  const [showLoder, setShowLoder] = useState(0);
  
  const [showUnderProcess, setShowUnderProcess] = useState(0);
  const [showToster, setShowToster] = useState(0);
  const [tosterValue, setTosterValue] = useState(0);
  let [showMessage, setShowMeassage] = useState("");
  const [bloodGroup, setbloodGroup] = useState('');
  const [bloodGroupList, setbloodGroupList] = useState('');
  const [tosterMessage, setTosterMessage] = useState("");
  const [BloodStockDetails, setBloodStockDetails] = useState([]);
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [userID, setUserID] = useState(JSON.parse(sessionStorage.getItem("LoginData")).userId);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    if (name === 'bloodGroup') {
      setbloodGroup(value);
    }
}

 

  const handleClear=()=>{
   setbloodGroup('')
  }


  let getBloodGroup = async () => {
    
    let data = await GetBloodGroup();
    if(data.status === 1){
        setbloodGroupList(data.responseValue);
        console.log("bloodGroup" , data.responseValue)
    }

    
  }

  const getBloodstockdetails=async()=>{
    let data = await GetBloodStock(bloodGroup)
    if(data.status === 1){
        setBloodStockDetails(data.responseValue)
        console.log("BloodStockDetails" , data.responseValue)
    }
  }

  useEffect(() => {
    getBloodGroup()
    getBloodstockdetails()
  }, [])
  return (
   <>
    <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="med-box">
                <div className="inner-content">
                <div className='fieldsett-in'>
                <div className='fieldsett'>
                  <span className='fieldse'>Blood Stock Report</span>
                  <div className='row'>
                 
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3">

                    <img src={bloddgroup} className='icnn' alt='' /><label htmlFor="bloodGroup" className="form-label">Blood Group<span className="starMandatory">*</span></label>
                        <select className="form-select form-select-sm" id='ddlBloodGroup' name='bloodGroup' value={bloodGroup} aria-label=".form-select-sm example" onChange={handleOnChange}>
                          <option>Select Blood Group</option>
                          {bloodGroupList && bloodGroupList.map((list) => {
                            return (
                              <option value={list.id}>{list.groupName}</option>
                            )
                          })}
                        </select>
                        <small id='errBloodGroup' className='form-text text-danger' style={{ display: 'none' }}></small>
                      {/* <label htmlFor="Code" className="form-label">Blood Group<span className="starMandatory"></span></label>
                      <input id="bloodGroup" type="text" value={bloodGroup} className="form-control form-control-sm" onChange={handleOnChange} placeholder = "bloodGroup" name="bloodGroup"  />
                      <small id="errbloodGroup" className="form-text text-danger" style={{ display: 'none' }}></small> */}
                    </div>
 
                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3 relative">
                      <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>

                      {showUnderProcess === 1 ? <><TosterUnderProcess />  </> :
                        showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} />
                          :
                          <div>
                      
                              <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={getBloodstockdetails}  ><i className="bi bi-search me-2"></i>Search</button>
                              <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt='' />Clear</button>
        
                          </div>
                      }
                    </div>
                  </div>
                  </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-12 mt-3">
              <div className="med-table-section" style={{ "height": "80vh" }}>
                <table className="med-table border_ striped">
                  <thead style={{ zIndex: '0' }}>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>Product Name</th>
                      <th>Blood Group</th>
                      <th>Stock Available</th>
                      <th>Last Updated</th>
                      {/* <th>Total Amount </th> */}
                      {/* <th style={{ "width": "10%" }} className="text-center">Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                  
                      
                  
                  
              
                   
            
                  {BloodStockDetails && BloodStockDetails.map((data, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td>{data.productName}</td>
                        <td>{data.groupName}</td>
                        <td>{data.availablestock}</td>
                        <td>{data.lastUpdate}</td>
                        {/* <td>{data.totalAmountSumByTpaReferenceNo}</td> */}
                        <td>
              
      </td>
    </tr>
  ))}


  
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* -----------------------Start Delete Modal Popup-------------------    */}

        
        {/* -----------------------End Delete Modal Popup---------------------  */}
        {
          showLoder === 1 ? <Loder val={showLoder} /> : ""
        }
      </section>

{showAlertToster === 1 ? (
              <AlertToster message={showMessage} handle={setShowAlertToster} />
            ) : (
              ""
            )}
   </>
  )
}
