import React, { useEffect, useState } from "react";
import GetDeveloperWiseTask from "../API/Report/GetDeveloperWiseTask";
import GetUser from '../API/OutsideAPI/GetUser'
import TableContainer from '../../Component/TableContainer'
import Heading from '../../Component/Heading'
import DropdownWithSearch from '../../Component/DropdownWithSearch'
import exportFromJSON from "export-from-json";
import SuccessToster from '../../Component/SuccessToster'
import WarningToaster from '../../Component/WarningToaster'
import AlertToster from '../../Component/AlertToster'
import save from "../../assets/images/icons/save.svg";
import noData from '../../assets/images/icons/No data-rafiki.svg';
import { useTranslation } from 'react-i18next';


export default function GetDeveloperWiseTaskList() {

    const [getDeveloperWiseTaskList, setDeveloperWiseTaskList] = useState([])
    const [getSelectedOptionUser, setSelectedOptionUser] = useState('');
    //let [getProjectList, setProjectList] = useState([])
    const [searchInput, setSearchInput] = useState('');
    const [loder, setLoder] = useState(1)
    const [showToster, setShowToster] = useState(0)
    const [message, setMessage] = useState("")
    const { t } = useTranslation();
    const [getUserList, setUserList] = useState([])
    const [getFromDate, setFromDate] = useState('')
    const [getToDate, setToDate] = useState('')

    const [editUserName, seteditUserName] = useState("")
    const [clearDropdown, setClearDropdown] = useState(0)
    const [getSaveUpdateBool, setSaveUpdateBool] = useState(0)

    const funGetDeveloperWiseTask = async () => {
        if (getSelectedOptionUser === '0' || getSelectedOptionUser === undefined || getSelectedOptionUser === null || getSelectedOptionUser === "") {
            setMessage("Project not fill !")
            setShowToster(3)
            setLoder(0)
        }
        if (getFromDate === '0' || getFromDate === undefined || getFromDate === null || getFromDate === "") {
            setMessage("From date not fill !")
            setShowToster(3)
            setLoder(0)
        }
        if (getToDate === '0' || getToDate === undefined || getToDate === null || getToDate === "") {
            setMessage("From date not fill !")
            setShowToster(3)
            setLoder(0)
        }
        else {
            setLoder(1)
            var obj = {
                assignedTo: getSelectedOptionUser,
                userId: window.userId,
                FromDate: getFromDate,
                ToDate: getToDate,
                clientId: JSON.parse(window.sessionStorage.getItem('LoginData')).clientId
            }
            console.log('ObjValue', obj)
            let getResult = await GetDeveloperWiseTask(getSelectedOptionUser, window.userId, getFromDate, getToDate)
            if (getResult.status === 1) {
                setLoder(0)
                console.log('ResultData', getResult.responseValue)
                setDeveloperWiseTaskList(getResult.responseValue);
            }
        }
    };

    const funGetUser = async () => {
        const {status, responseValue} = await GetUser();
        status ? setUserList(responseValue) : setUserList([]);
    }
    //Handle Change
    const handleChange = (e) => {
        const {name, value} = e.target;
        if (name === "userId") {
            setSelectedOptionUser(value)
        }
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        if (name === 'searchBox') {
            setSearchInput(value);
        }
    };

    const handleTextboxChange = (event) => {
        if (event.target.name === "fromDate") {
            setFromDate(event.target.value);
        }
        if (event.target.name === "toDate") {
            setToDate(event.target.value);
        }
    };
    const clearValues = async (value) => {
        setClearDropdown(value)
        setSelectedOptionUser('');
        setSaveUpdateBool(0);
    };
    function reformatDateString(s) {
        if (typeof s === 'string' && s.trim() !== '') {
            var b = s.split(/\D/);
            var day = parseInt(b[2], 10).toString();
            var month = parseInt(b[1], 10).toString();
            var year = b[0];
            return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
        } else {
            return ''; // Return an empty string or handle the error as needed
        }
    };
    let handleExportFile = () => {
        var arrFormat = [];
        getDeveloperWiseTaskList.map((val, ind) => {
            let param = ['SR', 'Project Name', 'Developer Name', 'Story', 'Sprint Backlog', 'Assign Date', 'Status'];

            arrFormat.push({
                [param[0]]: ind + 1,
                [param[1]]: val.projectName,
                [param[2]]: val.name,
                [param[3]]: val.story,
                [param[4]]: val.sprintBacklogText,
                [param[5]]: reformatDateString(val.assignDate),
                [param[6]]: val.statusText,
            });
        });

        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
        const data = arrFormat;
        const fileName = 'DeveloperWiseTaskList_' + '' + formattedDate;
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType });
    }
    useEffect(() => {
        funGetUser();
    }, []);

    return (
        <>
            <section className="main-content pt-3 mt-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="med-box">
                                <div className="title">Get Developer task Details</div>
                                <div className="inner-content">
                                    <div className="d-flex align-content-end flex-wrap">
                                        <div className="mb-2 me-2">
                                            <label htmlFor="UserName" className="form-label">Developer<span className="starMandatory">*</span></label>
                                            <div className='drpWithSearch'>
                                                {getUserList && <DropdownWithSearch defaulNname={t("Select User")} name="userId" list={getUserList} valueName="id" displayName="name" editdata={editUserName} getvalue={handleChange} clear={clearDropdown} clearFun={clearValues} />}
                                            </div>
                                        </div>
                                        <div className="mb-2 me-2">
                                            <label htmlFor="fromDate" className="form-label">From Date<span className="starMandatory">*</span></label>
                                            <input type="date" className="form-control form-control-sm" value={getFromDate} onChange={handleTextboxChange} id="txtfromDate" name="fromDate" placeholder='Enter From Date' />
                                        </div>
                                        <div className="mb-2 me-2">
                                            <label htmlFor="toDate" className="form-label">To Date<span className="starMandatory">*</span></label>
                                            <input type="date" className="form-control form-control-sm" value={getToDate} onChange={handleTextboxChange} id="txttoDate" name="toDate" placeholder='Enter To Date' />
                                        </div>
                                        <div className="mb-2 relative">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                            <div>
                                                {getSaveUpdateBool === 0 ?
                                                    <>
                                                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={funGetDeveloperWiseTask}><img src={save} className='icnn' />Get Report</button>
                                                        {/* <button type="button" className="btn btn-clear btn-sm mb-1 me-1"><img src={reset} className='icnn' /> Clear</button> */}
                                                    </>
                                                    :
                                                    <>
                                                        {/* <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1"><img src={save} className='icnn' />Update</button>
                                                        <button type="button" className="btn btn-clear btn-sm mb-1 me-1"><img src={reset} className='icnn' /> Clear</button> */}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-1">
                            <div className='handlser'>
                                <Heading text="Developer Task List" />
                                <div style={{ position: 'relative', display: 'flex' }}>
                                    <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleExportFile}>Export To CSV</button>
                                    <input value={searchInput} onChange={handleOnChange} name="searchBox" type="search" className="form-control form-control-sm" placeholder="Search...." aria-label="Search" aria-describedby="search-addon" />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>

                                </div>
                            </div>

                            <div className="med-table-section relative" style={{ "height": "77vh" }}>
                                <TableContainer>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>S.No.</th>
                                            <th>Project Name</th>
                                            <th>Developer Name</th>
                                            <th>Story</th>
                                            <th>Sprint Backlog</th>
                                            <th>Assign Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getDeveloperWiseTaskList.length > 0 ? 
                                            getDeveloperWiseTaskList.filter((val) => `${val.projectName} ${val.name}${val.story}${val.sprintBacklogText}`.toLowerCase().includes(searchInput.toLowerCase())).map((val, ind) => {
                                                return (
                                                    <tr key={val.agendaId}>
                                                        <td className="text-center">{ind + 1}</td>
                                                        <td>{val.projectName}</td>
                                                        <td>{val.name}</td>
                                                        <td>{val.story}</td>
                                                        <td>{val.sprintBacklogText}</td>
                                                        <td>{reformatDateString(val.assignDate)}</td>
                                                        <td>{val.statusText}</td>
                                                    </tr>
                                                );
                                            })
                                         : 
                                         <img className='haveNodataImg' src={noData} alt="No Data" />
                                        }
                                    </tbody>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>
                {showToster === 1 ? <SuccessToster message={message} handle={setShowToster} /> : ""}
                {showToster === 2 ? <WarningToaster message={message} handle={setShowToster} /> : ""}
                {showToster === 3 ? <AlertToster message={message} handle={setShowToster} /> : ""}
            </section>
            {/* <Loder val={loder} /> */}
        </>
    )
}