import React, { useEffect, useRef, useState } from 'react'
import calender from '../../../../../../assets/images/icons/calendar.svg'
import clock from '../../../../../../assets/images/icons/clock.svg'
import BoxContainer from '../../../../../../Component/BoxContainer'
import upDownIcon from '../../../../../../assets/images/icons/upDownIcon.svg'
import IconEdit from '../../../../../../assets/images/icons/IconEdit.svg'
import IconDelete from '../../../../../../assets/images/icons/IconDelete.svg'
import reset from '../../../../../../assets/images/icons/RotateIcon.png'
import notes from '../../../../../../assets/images/icons/PatientHistory1.svg'
import IconPrint from '../../../../../../assets/images/icons/print.svg'
import savewhite from '../../../../../../assets/images/icons/save.svg'
import clearIcon from '../../../../../../assets/images/icons/clear.svg'
import { exists, t } from 'i18next'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import SuccessToster from '../../../../../../Component/SuccessToster'
import AlertToster from '../../../../../../Component/AlertToster'
import TextEditor from '../../../../../../Component/TextEditor'
import TosterUnderProcess from '../../../../../../Component/TosterUnderProcess'
import Toster from '../../../../../../Component/Toster'
import SaveCastSheet from '../Api/SaveCastSheet'
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils'
import GetCastSheetList from '../Api/GetCastSheetList'
import UpdateCaseSheet from '../Api/UpdateCaseSheet'
import DeleteCastSheet from '../Api/DeleteCastSheet'


export default function CaseSheet() {

    let [castSheetList, setCastSheetList] = useState([])
    let [templateByUserIdList, setTemplateByUserIdList] = useState([])
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showLoder, setShowLoder] = useState(0);
    let [body, setBody] = useState('')
    let [updateBool, setUpdateBool] = useState(0)
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [loder, setLoder] = useState(1)
    let [rowId, setRowId] = useState('');
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showUnderProcess, setShowUnderProcess] = useState(0)
    let [showToster, setShowToster] = useState(0)
    let [subbTitle, setSubbtitle] = useState('')
    let [detailsDate, setDetailsDate] = useState('')
    let [detailsTime, setDetailsTime] = useState('')
    let [isShared, setIsShared] = useState(0)
    let [currentdatemax, setcurrentdatemax] = useState();
    let [clearDropdown, setClearDropdown] = useState(0)
    let [tosterMessage, setTosterMessage] = useState("")
    let [DetailName, setDetailName] = useState("")
    let [tosterValue, setTosterValue] = useState(0)
    let [id, setId] = useState()
    let [isShowTemplateModel, setIsShowTemplateModel] = useState(0)
    let [toggleMic, setToggleMic] = useState(0)
    let [saveVoice, setSaveVoice] = useState('');
    let isSave = saveVoice === "save" ? 1 : 0;
    const [isDisabled, setIsDisabled] = useState(false);
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    const userID = JSON.parse(window.sessionStorage.getItem("LoginData")).userId;
    const UHID = JSON.parse(window.sessionStorage.getItem("IPDpatientList"))[0].uhId;
   
    const {
        transcript,
        resetTranscript,
        listening,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    useEffect(() => {
        if (!browserSupportsSpeechRecognition) {
            console.log('Browser does not support speech recognition.');
        }
    }, [browserSupportsSpeechRecognition]);

    useEffect(() => {
        processTranscript(transcript);
    }, [transcript]);

    const curdate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        setcurrentdatemax(formattedDate)
        document.getElementById('detailsDateProgress').value = formattedDate;
        return formattedDate;
    };
    const processTranscript = (transcript) => {
        const excludedWords = ['save data']; // Add any words or phrases you want to exclude
        const excludedRegex = new RegExp(`\\b(${excludedWords.join('|')})\\b`, 'i');

        const lines = transcript.split(/\n|\.|\?/).filter(line => {
            // Check if the line contains any excluded words
            return !excludedRegex.test(line) && line.trim() !== '';
        });

        const formattedText = lines.map(line => `<p>${line.trim()}</p>`).join('');
        setBody(formattedText);
    };
    const startListening = () => {
        SpeechRecognition.startListening({ continuous: true, language: 'en-US' });
        setToggleMic(1)
    };
    useEffect(() => {
        if (listening) {
            const regex = /\b(save|safe|sabe|cabe)\b/g;
            const output = transcript.match(regex);

            if (output !== null && output.length !== 0) {
                setSaveVoice(output[0]);
                if (body !== '') {
                    saveForm(id); // Call the saveForm function to save the data
                }
                handleClear()
            } else {
                processTranscript(transcript);
            }
        }
    }, [listening, transcript]);
    useEffect(() => {
        curdate();
        if (isSave) {
            handleClear()
            isSave = 0;
        }
    }, [isSave])
    // Function to get the current date and time
    const getCurrentDateTime = () => {
        const now = new Date();
        const date = now.toISOString().split('T')[0]; // Format: YYYY-MM-DD
        const time = now.toTimeString().slice(0, 5); // Format: HH:MM
        setDetailsDate(date);
        setDetailsTime(time);
    };
    const handleChange = (e) => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const time = `${hours}:${minutes}`;
        const { value, name } = e.target;
        if (name === "body") {
            value === '<p><br></p>' ? setBody('') : setBody(value);
        }
        else if (name === "subTittle") {
            setSubbtitle(value)
            getTemplateNotes(value)
        }

        else if (name === "detailsDate") {
            setDetailsDate(value)

        }

        else if (name === "detailsTime") {
            if (value > time) {
                return false
            }
            else {
                setDetailsTime(e.target.value)
            }
            // setDetailsTime(value)

        }

    }
    //Get data
    const getdata = async (id, ind) => {
        setClearDropdown(1);
        setId(id);
        const obj={
            UHID:UHID,
            ClientId:clientID
        }
        const {status,responseValue} = await GetCastSheetList(obj);
        if (status === 1) {
            setCastSheetList(responseValue);
        }
    };
    const getTemplateNotes = async (subbTitle) => {

        let getResponse = 1//await GetTemplateByUserId(id, subbTitle, userID, isShared, clientID);

        if (getResponse.status === 1) {
            // setLoder(0)
            setTemplateByUserIdList(getResponse.responseValue)
            const data = getResponse.responseValue

            const body = data.length == 0 ? '' : data[0].body

            setBody(body)

        }

    };

    const saveForm = async (id) => {
        const obj = {
            uhid:UHID ,
            caseSheetDetails:body ,
            caseSheetDateTime:detailsDate + ' ' + detailsTime ,
            userId: userID,
            clientId: clientID,
            rowId: 0

        }
        const valresponse =  body ?  true : false;
            if (valresponse) {
                setShowUnderProcess(1)
                const{status,responseValue} = await SaveCastSheet(obj);
                if (status === 1) {
                    setShowUnderProcess(0)
                    setisShowToaster(1);
                    setShowSuccessMsg("Data saved Successfully")
                    processTranscript("")
                    setTimeout(() => {
                        setisShowToaster(0);
                    }, 1500);

                    getdata(id)

                }
                else {
                    setShowUnderProcess(0)
                    setShowToster(1)
                    setTosterMessage(responseValue)
                    setTosterValue(1)
                    setTimeout(() => {
                        setShowToster(0)
                    }, 2000)
                }
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage("Field can't be blank!")
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }
    };
    const handlePrint = (data) => {
        window.sessionStorage.setItem("PrintCaseSheet", JSON.stringify(data));
        window.open("/CaseSheetPrint/", 'noopener,noreferrer');
    }
    //Handle Delete
    const handleDeleteRow = async (id) => {
        // setLoder(1)
        setShowUnderProcess(1)
        let obj = {
            UHID,UHID,
            rowId: rowId
        }
        let response = await DeleteCastSheet(obj)
        if (response.status === 1) {
            setShowUnderProcess(0)
            setisShowToaster(1)
            setShowSuccessMsg("Data Deleted Successfully!")
            setTimeout(() => {
                setisShowToaster(0)
            }, 1500)
            getdata(id)
        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage(response.responseValue)
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    };
    const handleClear = (value) => {
        setIsDisabled(false)
        setClearDropdown(value)
        setBody('')
        getCurrentDateTime();
        resetTranscript();
    };
    const handleReset = async (id) => {
        setIsDisabled(false)
        getdata(id);
        getCurrentDateTime();
    };
    const handleUpdate = (param) => {
       const date=param.detailsDate.split('T');
         setIsDisabled(true)
         setUpdateBool(1)
         setRowId(param.id)
         setDetailsDate(date.length > 0 ? date[0]:'');
         setDetailsTime(date.length > 1 ? date[1]:'');
         setBody(param.details)
    };
    const saveUpdate = async () => {
        
        const obj = {
            uhid:UHID ,
            caseSheetDetails:body ,
            caseSheetDateTime:detailsDate + ' ' + detailsTime ,
            userId: userID,
            clientId: clientID,
            rowId: rowId

        }
        const valresponse = true;
        if (body != '') {
            if (valresponse) {
                setShowUnderProcess(1)
                let response = await UpdateCaseSheet(obj);
                if (response.status === 1) {
                    setUpdateBool(0)
                    setShowUnderProcess(0)
                    setisShowToaster(1);
                    setShowSuccessMsg("Data Updated Successfully")
                    processTranscript("")
                    setTimeout(() => {
                        setisShowToaster(0);
                    }, 1500);
                    getdata(id)
                }
                else {
                    setShowUnderProcess(0)
                    setShowToster(1)
                    setTosterMessage(response.responseValue)
                    setTosterValue(1)
                    setTimeout(() => {
                        setShowToster(0)
                    }, 2000)
                }
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage("Field can't be blank!")
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }
        }
        else {
            setShowAlertToster(1);
            setShowErrMessage("Please fill details")
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500);
        }
    };
    useEffect(() => {
        getdata(1);
        getCurrentDateTime();
    }, []);


    return (
        <>
            

            {/* ---- Progress Notes Tab Start------ */}

            <div className="tab-content mt-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-ProgressNote" role="tabpanel" aria-labelledby="nav-ProgressNote-tab" tabIndex="0">

                    <div className='patientMain_ row'>
                        <div className='patient-lft_ col-md-4 pe-1 mb-2'>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 p-0">
                                        <div className="fieldsett-in">
                                            <div className="fieldsett">
                                                <span className='fieldse'>Case Sheet</span>
                                                <BoxContainer>
                                                    <div className="col-md-12">
                                                        <div className="d-flex gap-2 align-content-end">
                                                            <div className="mb-2 me-2" style={{ flex: '1' }}>
                                                                <img src={calender} className='icnn' /> <label htmlFor="detailsDate" className="form-label">Date</label>
                                                                <input type="date" className="form-control form-control-sm" id="detailsDateProgress" max={currentdatemax}
                                                                    name="detailsDate" placeholder="Enter Date" value={detailsDate} onChange={handleChange} />
                                                            </div>
                                                            <div className="mb-2 me-2" style={{ flex: '1' }}>
                                                                <img src={clock} className='icnn' /> <label htmlFor="detailsDate" className="form-label">Time</label>
                                                                <input type="time" className="form-control form-control-sm" id="detailsTimeProgress" name="detailsTime"  placeholder="Enter Time" value={detailsTime} onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className='resetMain'>
                                                            <div className='tempEditorpt'>
                                                                <TextEditor getTextvalue={handleChange} setValue={body} name="body" id="body" />
                                                                <div className='resetimg'>
                                                                    <img src={reset} alt='' onClick={() => { handleReset(id) }} title='Reset' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className='d-flex justify-content-between w-100'>
                                                            <div>
                                                                {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                                                    <>
                                                                        {showToster === 1 ?
                                                                            <Toster value={tosterValue} message={tosterMessage} />

                                                                            : <div>
                                                                                {updateBool === 0 ?
                                                                                    <>
                                                                                        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" data-bs-toggle="modal" data-bs-title="save" data-bs-placement="bottom" data-bs-target="#successModal"><img src={savewhite} className='icnn' />Save</button>
                                                                                        <button type="button" className="btn btn-save btn-sm btnbluehover mb-1 me-1" onClick={() => { handleClear(1) }}>
                                                                                            <img src={clearIcon} className='icnn' />Clear
                                                                                        </button>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <button type="button" className="btn btn-save btn-sm mb-1 me-1" data-bs-toggle="modal" data-bs-title="save" data-bs-placement="bottom" data-bs-target="#updateModal" >{t("UPDATE")}</button>
                                                                                        <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { setUpdateBool(0); handleClear() }}>{t("Cancel")}</button>
                                                                                    </>
                                                                                }
                                                                            </div>}
                                                                    </>
                                                                }
                                                            </div>

                                                            <div className='d-flex gap-2 align-items-center miccnt'>
                                                                <div className='voiceMic1 d-flex flex-row justify-content-center align-items-center '>
                                                                    <span onClick={startListening}>
                                                                        <i className={listening ? "bi bi-mic-fill" : "bi bi-mic-mute-fill"} title='Start Listening'></i>
                                                                    </span>
                                                                </div>

                                                                <div className='btnStopListen1'>
                                                                    <i className="bi bi-stop-circle" title='Stop Listening' onClick={SpeechRecognition.stopListening}></i>
                                                                </div>

                                                                <div className='voiceLoader'>
                                                                    <div className='list_'>
                                                                        {listening ?
                                                                            <div className='voiceLoder'><i className="fa fa-spinner fa-spin"></i></div>
                                                                            :
                                                                            ''
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </BoxContainer>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='patient-rt_ col-md-8 ps-2'>
                            <div className="col-12">

                                <div className="med-box">

                                    <div className='listdetailsct'>
                                        {/* <div className='listdetailsct-in'>
                                            <label className="labelPageIndex" >Showing <span className="pageIndex">1-3 </span> of  <span className="pageIndex">21</span> entries</label>
                                        </div> */}
                                        <div className='listdetailsct-in'>
                                            <div className='listd-in'>
                                                <form className="d-flex ms-auto ser" role="search">
                                                    {/* <input type="search" className="form-control form-control-sm" placeholder="Search.." /> */}
                                                    {/* <i className="fa fa-search"></i> */}
                                                </form>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="med-table-section" style={{ "height": "77vh" }}>

                                        <table className='med-table tblalign_ border_ striped'>
                                            <thead>
                                                <tr>
                                                    <th className="text-center" style={{ "width": "5%" }}>#</th>
                                                    <th style={{ width: '10%' }}>Date/Time <img className="IconFilter" src={upDownIcon} alt="upDownIcon" /></th>
                                                    <th style={{ width: '10%' }}>Cast Sheet <img className="IconFilter" src={upDownIcon} alt="upDownIcon" /></th>
                                                    <th style={{ "width": "10%" }} className="text-center">Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {castSheetList && castSheetList.map((val, ind) => {
                                                    return (
                                                        <tr key={val.id}>
                                                            <td className="text-center">{ind + 1}</td>
                                                            <td><span style={{ color: '#7696F1', fontSize: '13px' }}>{val.formattedDate}</span></td>
                                                            <td><div dangerouslySetInnerHTML={{ __html: val.details }} style={{ margin: '2px', padding: '2px' }} /></td>
                                                          
                                                            <td style={{verticalAlign:'top !important'}}>
                                                                <div className="action-button">
                                                                    <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom" title="Edit Row" onClick={() => handleUpdate(val)}><img src={IconEdit} alt='' /></div>
                                                                    <div className="actionItem" title="Print"><img src={IconPrint} className='imgprint' alt="IconPrint" onClick={() => { handlePrint(val, ind) }} /></div>
                                                                    <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal" ><img src={IconDelete} alt="Delete" onClick={() => { setRowId(val.id) }} title='Delete' /></div>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                        {/* } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {/*  <!--  Delete Pop-Up Modal -->  */}

                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                    <div className="modal-dialog modalDelete">
                        <div className="modal-content">
                            <div className="modal-body modelbdy text-center">
                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                <div className='popDeleteTitle mt-3'> Delete?</div>
                                <div className='popDeleteContent'> Are you sure you want to delete?</div>
                            </div>
                            <div className="modal-footer1 text-center">
                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn-delete popBtnDelete" data-bs-dismiss="modal" onClick={() => { handleDeleteRow(id) }}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* -------------------------------------------------------Save Alert----------------------------------------------- */}
                <div className="modal fade" id="successModal" tabIndex="-1" aria-labelledby="successModalLabel" aria-hidden="true" data-bs-backdrop="static">
                    <div className="modal-dialog modalSave">
                        <div className="modal-content">
                            <div className="modal-body modelbdy text-center">
                                <div className='popSaveIcon'><i class="fa-solid fa-circle-check"></i></div>
                                <div className='popDeleteTitle mt-3'> Save?</div>
                                <div className='popDeleteContent'> Are you sure you want to Save?</div>
                            </div>
                            <div className="modal-footer1 text-center">
                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn-delete popBtnDelete" data-bs-dismiss="modal" onClick={() => { saveForm(id) }}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* -------------------------------------------------------Update Alert----------------------------------------------- */}

                <div className="modal fade" id="updateModal" tabIndex="-1" aria-labelledby="successModalLabel" aria-hidden="true" data-bs-backdrop="static">
                    <div className="modal-dialog modalSave">
                        <div className="modal-content">
                            <div className="modal-body modelbdy text-center">
                                <div className='popSaveIcon'><i class="fa-solid fa-circle-check"></i></div>
                                <div className='popDeleteTitle mt-3'> Update?</div>
                                <div className='popDeleteContent'> Are you sure you want to Update?</div>
                            </div>
                            <div className="modal-footer1 text-center">
                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn-delete popBtnDelete" data-bs-dismiss="modal" onClick={saveUpdate}>{t("UPDATE")}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {
                showAlertToster === 1 ?
                    <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
            }

            {
                isShowToaster === 1 ?
                    <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
            }
        </>
    )
}

