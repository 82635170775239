
async function DeleteImage(obj) {
    let url = window.AppbaseUrl + "/api/ImageDraw/DeleteImage?id=" + obj.id;
    let head = {
        'Content-Type': 'application/JSON',
        accept: '*/*',
    }
    let response =
        await fetch(url, {
            method: 'DELETE',
            headers: head,
            body: JSON.stringify(obj)
        })
            .then((res) => res.json())
            .then(obj)
    return response;
}
export default DeleteImage;


