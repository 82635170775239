import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import TableContainer from '../../../../src/Component/TableContainer';
import Heading from '../../../../src/Component/Heading';
import BoxContainer from '../../../../src/Component/BoxContainer';
import Toster from '../../../../src/Component/Toster'
import TosterUnderProcess from '../../../../src/Component/ToStartUnderProcess'
import IconEdit from '../../../assets/images/icons/IconEdit.svg'
import IconDelete from '../../../assets/images/icons/IconDelete.svg'
import clearIcon from '../../../assets/images/icons/clear.svg';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import GetLeaveMaster from '../../API/GetLeaveMaster';
import i18n from "i18next";
import PostLeaveMaster from '../../API/PostLeaveMaster';
import ValidationLeaveMaster from '../../../Validation/HrService/ValidationLeaveMaster';
import DeleteLeaveMaster from '../../API/DeleteLeaveMaster';
import PutLeaveMaster from '../../API/PutLeaveMaster';
export default function LeaveMaster() {


    const [isHalfAllowed, setIsHalfAllowed] = useState(0);
    const { t } = useTranslation();
    let [showUnderProcess, setShowUnderProcess] = useState(0)
    let [showToster, setShowToster] = useState(0)
    let [tosterMessage, setTosterMessage] = useState("")
    let [tosterValue, setTosterValue] = useState(0)
    let [updateBool, setUpdateBool] = useState(0)
    let [isCarryForward, setIsCarryForward] = useState(0);
    let [isRh, setIsRh] = useState(0);
    let [isAnyTime, setIsAnyTime] = useState(0);
    let [leaveName, setLeaveName] = useState("");
    let [leaveShName, setLeaveShName] = useState("");
    let [rowId, setRowId] = useState('');
    let [leaveMasterList, setLeaveMasterList] = useState([]);
    let clientId = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
    let userId = JSON.parse(sessionStorage.getItem("LoginData")).userId;
    let [id, setId] = useState('');
    let [leaveStatus, setLeaveStatus] = useState();
    let [calculateBy, setCalculateBy] = useState();
    let [tagColor, setTagColor] = useState('');
    let [isDefault, setIsDefault] = useState();
    let [redeemedAs, setRedeemedAs] = useState(0);
    let [isExtraDuty, setIsExtraDuty] = useState(0);
    let [extraDutyTime, setExtraDutyTime] = useState(0);
    let [shortName, setShortName] = useState('');



    const getData = async () => {
        let data = await GetLeaveMaster(clientId);
        if (data.status === 1) {
            setLeaveMasterList(data.responseValue);
        }

    }


    const handleChange = (event) => {
        const { name, value } = event.target;
        // const { name, value, checked } = event.target;

        if (name === 'leaveName') {
            setLeaveName(value);
        }

        if (name === 'shortName') {
            setShortName(value);
        }

        if (name === 'isExtraDuty') {
            const newValue = event.target.checked ? 1 : 0;
            setIsExtraDuty(newValue);
        }

        if (name === 'extraDutyTime') {
            setExtraDutyTime(value);
        }


        if (name === 'tagColor') {
            setTagColor(value);
            console.log("tagColor", tagColor)
        }

        if (name === 'isDefault') {

            const newValue = event.target.checked ? 1 : 0;
            setIsDefault(newValue);
            console.log("isDefault", isDefault)
        }

       

        if (name === 'redeemedAs' && value === 'true') {

            setRedeemedAs(1);
        }
        else {
            setRedeemedAs(0);
        }

    }

    const handleCheckboxLeaveStatusEnabld = (event) => {
        if (event.target.checked) {
            setLeaveStatus(1);
        }
    };

    const handleCheckboxLeaveStatusDisabld = (event) => {
        if (event.target.checked) {
            setLeaveStatus(0);
        }
    };
    console.log("leaveStatus", leaveStatus)

    const handleCheckboxCalShiftWise = (event) => {
        if (event.target.checked) {
            setCalculateBy(1);
        }
    };

    const handleCheckboxCalHourly = (event) => {
        if (event.target.checked) {
            setCalculateBy(0);
        }
    };

    console.log("calculateBy", calculateBy)

    let saveForm = async () => {

        const obj = {

            leaveName: leaveName,
            shortName: shortName,
            leaveStatus: leaveStatus,
            tagColor: tagColor,
            isDefault: isDefault,
            redeemedAs: redeemedAs,
            isExtraDuty: isExtraDuty,
            calculateBy: calculateBy,
            extraDutyTime: extraDutyTime,
            clientId: clientId,
            userId: userId

        }



        let valresponse = ValidationLeaveMaster(leaveName)

        if (valresponse[0]) {
            setShowUnderProcess(1)
            let response = await PostLeaveMaster(obj);

            if (response.status === 1) {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage("Data Saved SuccessFully!")
                setTosterValue(0)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
                getData()
                handleClear()
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage(response.responseValue)
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)

                }, 2000)
            }

        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage(valresponse[1])
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }

    let handleDeleteRow = async () => {
        // setLoder(1)
        setShowUnderProcess(1)
        let obj = {
            id: rowId
        }
        let response = await DeleteLeaveMaster(obj)
        if (response.status === 1) {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Data Deleted SuccessFully!")
            setTosterValue(0)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
            getData()
        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage(response.responseValue)
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }

    let handleUpdate = async (data) => {

        setUpdateBool(1)
        setId(data.id)
        document.getElementById("leaveName").value = data.leaveName;
        document.getElementById("shortName").value = data.shortName;
        document.getElementById("tagColor").value = data.tagColor;
        document.getElementById("extraDutyTime").value = data.extraDutyTime;

        setLeaveName(data.leaveName)
        setLeaveStatus(data.leaveStatus)
        setTagColor(data.tagColor)
        setIsDefault(data.isDefault)
        setRedeemedAs(data.redeemedAs)
        setIsExtraDuty(data.isExtraDuty)
        setExtraDutyTime(data.extraDutyTime)
        setCalculateBy(data.calculateBy)
        setShortName(data.shortName)
    }

    

    let saveUpdate = async () => {

        let obj = {
            id: id,
            leaveName: leaveName,
            shortName: shortName,
            leaveStatus: leaveStatus,
            tagColor: tagColor,
            isDefault: isDefault,
            redeemedAs: redeemedAs,
            isExtraDuty: isExtraDuty,
            calculateBy: calculateBy,
            extraDutyTime: extraDutyTime,
            clientId: clientId,
            userId: userId
        }

        console.log(obj)

        let valresponse = ValidationLeaveMaster(leaveName)

        if (valresponse[0]) {
            setShowUnderProcess(1)
            let response = await PutLeaveMaster(obj)
            if (response.status === 1) {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage("Data Updated SuccessFully!")
                setTosterValue(0)
                setTimeout(() => {
                    setShowToster(0)

                }, 2000)

                setUpdateBool(0)
                getData()
                handleClear()
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage(response.responseValue)
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }

        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage(valresponse[1])
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }

    const handleClear = () => {

        document.getElementById("leaveName").value = "";
        setLeaveName("");


    }



    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <Heading text={t("Leave Master")} />
                            <BoxContainer>



                                <div className="mb-2 me-2">
                                    <label htmlFor="leaveName" className="form-label">{t("Leave Name")}<span className="starMandatory">*</span></label>
                                    <input type='text' className='form-control form-control-sm' id='leaveName' name='leaveName' onChange={handleChange} placeholder={t("Enter Leave Name")} />
                                </div>

                                <div className="mb-2 me-2">
                                    <label htmlFor="leaveName" className="form-label">{t("Short Name")}<span className="starMandatory">*</span></label>
                                    <input type='text' className='form-control form-control-sm' id='shortName' name='shortName' onChange={handleChange} placeholder={t("Enter Short Name")} />
                                </div>

                                <div className="mb-2 me-2">
                                    <div><label htmlFor="Code" className="form-label mt-2 ">Status<span className="starMandatory">*</span></label><small id='errGait' className='form-text text-danger' style={{ display: 'none' }}></small></div>
                                    <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                        <div className="d-flex column-gap-3 mt-2 me-3">
                                            <label htmlFor="canRead" className="form-label">Enabled<span className="starMandatory"></span></label>
                                            <div className="form-check">
                                                <input name="Condition" className="form-check-input" type="checkbox" checked={leaveStatus === 1}
                                                    onChange={handleCheckboxLeaveStatusEnabld} />
                                            </div>

                                        </div>

                                        <div className="d-flex column-gap-3 mt-2 me-3">
                                            <label htmlFor="canRead" className="form-label">Disabled<span className="starMandatory"></span></label>
                                            <div className="form-check">
                                                <input name="Condition" className="form-check-input" type="checkbox" checked={leaveStatus === 0}
                                                    onChange={handleCheckboxLeaveStatusDisabld} />
                                            </div>

                                        </div>
                                    </div>
                                </div >

                                <div className="mb-2 me-2">
                                    <label htmlFor="tagColor" className="form-label">{t("Tag Color")}<span className="starMandatory"></span></label>
                                    <input type='color' className='form-control form-control-sm' id='tagColor' name='tagColor' onChange={handleChange} />
                                </div>


                                <div className="risk-factors-check regularCheck  me-3">
                                    <input type="checkbox" name='isDefault' className="me-2 custom-checkbox form-check-input" checked={isDefault === 1}
                                        onChange={handleChange} />Is Default
                                </div> 

                              


                                <div className="risk-factors-check regularCheck  me-3">
                                    <input type="checkbox" name='isExtraDuty' className="me-2 custom-checkbox form-check-input" checked={isExtraDuty === 1}
                                        onChange={handleChange} />Is Extra Duty
                                </div>

                                <div className="mb-2 me-2">

                                    <label htmlFor="Code" className="form-label">Redeemed As<span className="starMandatory"></span></label>
                                    <select id="redeemedAs" name='redeemedAs' value={redeemedAs} className="form-select form-select-sm" onChange={handleChange}>
                                        <option value="" selected>Select</option>
                                        <option value="true">Leave Comp off</option>
                                        <option value="false">Paid Comp off</option>
                                    </select>
                                    <small id='errAsthma' className='form-text text-danger' style={{ display: 'none' }}></small>
                                </div>

                                <div className="mb-2 me-2">
                                    <div><label htmlFor="Code" className="form-label mt-2 ">Leave Calculate By<span className="starMandatory"></span></label><small id='errGait' className='form-text text-danger' style={{ display: 'none' }}></small></div>
                                    <div className="d-flex column-gap-1 flex-wrap regularCheck col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">


                                        <div className="d-flex column-gap-3 mt-2 me-3">
                                            <label htmlFor="canRead" className="form-label">Shift Wise<span className="starMandatory"></span></label>
                                            <div className="form-check">
                                                <input name="Condition" className="form-check-input" type="checkbox" checked={calculateBy === 1}
                                                    onChange={handleCheckboxCalShiftWise} />
                                            </div>

                                        </div>

                                        <div className="d-flex column-gap-3 mt-2 me-3">
                                            <label htmlFor="canRead" className="form-label">Hourly<span className="starMandatory"></span></label>
                                            <div className="form-check">
                                                <input name="Condition" className="form-check-input" type="checkbox" checked={calculateBy === 0}
                                                    onChange={handleCheckboxCalHourly} />
                                            </div>

                                        </div>
                                    </div>
                                </div >

                                <div className="mb-2 me-2">
                                    <label htmlFor="leaveName" className="form-label">{t("Extra Duty Time")}<span className="starMandatory"></span></label>
                                    <input type='datetime-local' className='form-control form-control-sm' id='extraDutyTime' name='extraDutyTime' onChange={handleChange} placeholder={t("Enter Leave Name")} />
                                </div>


                                <div className="mb-2 relative me-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                    <div>
                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />

                                                    : <div>
                                                        {updateBool === 0 ?
                                                            <>
                                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={saveForm}><img src={saveButtonIcon} className='icnn' />{t("Save")}</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' />{t("Clear")}</button>
                                                            </>
                                                            :
                                                            <>
                                                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={saveUpdate}>{t("Update")}</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { setUpdateBool(0); handleClear() }}>{t("Cancel")}</button>
                                                            </>
                                                        }
                                                    </div>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </BoxContainer>
                        </div>
                        <div className="col-12 mt-2">
                            <Heading text={t("Leave Master List")} />
                            <div className="med-table-section" style={{ "height": "75vh" }}>
                                <TableContainer>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>#</th>
                                            <th>{t("Leave Name")}</th>
                                            <th>{t("Short Name")}</th>
                                            <th>{t("Leave Status")}</th>
                                            <th>{t("Tag Color")}</th>
                                            <th>{t("Is Default")}</th>
                                            <th>{t("Is Extra Duty")}</th>
                                            <th>{t("Redeemed As")}</th>
                                            <th>{t("Calculate By")}</th>
                                            <th>{t("Extra Duty Time")}</th>
                                            <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {leaveMasterList && leaveMasterList.map((list, index) => {
                                            return (
                                                <tr>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td>{list.leaveName}</td>
                                                    <td>{list.shortName}</td>
                                                    <td>{list.leaveStatus === 1 ? 'Enabled' : 'Disabled'}</td>
                                                    <td>{list.tagColor}</td>
                                                    <td>{list.isDefault === 1 ? 'Yes' : 'No'}</td>
                                                    <td>{list.isExtraDuty === 1 ? 'Yes' : 'No'}</td>
                                                    <td>{list.redeemedAs === 1 ? 'Leave Comp Off' : 'Paid Comp Off'}</td>
                                                    <td>{list.calculateBy === 1 ? 'Shift Wise' : 'Hourly'}</td>
                                                    <td>{list.extraDutyTime}</td>
                                                    <td>
                                                        <div className="action-button">
                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={IconEdit} alt='' onClick={() => { handleUpdate(list, index) }} /></div>
                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={IconDelete} onClick={() => { setRowId(list.id) }} alt='' /></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </TableContainer>
                                {/* -----------------------Start Delete Modal Popup-------------------   */}

                                {/*  <!-- Modal -->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">

                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'>{t("Delete?")}</div>
                                                <div className='popDeleteContent'> {t("Are_you_sure_you_want_to_delete?")}</div>
                                            </div>
                                            <div className="modal-footer1 text-center">

                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                                                <button type="button" className="btn-delete popBtnDelete" data-bs-dismiss="modal" onClick={handleDeleteRow} >{t("Delete")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>




    )
}
