import React, { useState, useEffect } from 'react'
import bill from '../../assets/images/icons/bill.svg'
import patient from '../../assets/images/icons/patient.svg'
import Remark from '../../assets/images/icons/Remark.svg'
import sample from '../../assets/images/icons/sample.svg'
import UHID1 from '../../assets/images/icons/UHID1.svg'
import ward from '../../assets/images/icons/ward.svg'
import age from '../../assets/images/icons/age.svg'
import calender from '../../assets/images/icons/calender.svg'
import center from '../../assets/images/icons/center.svg'
import department from '../../assets/images/icons/department.svg';
import dot from '../../assets/images/icons/dot-23836.png';
import saveBtnIcon from "../../assets/images/icons/saveButton.svg";
import clearBtnIcon from "../../assets/images/icons/clear.svg";
import BoxContainer from '../../Component/BoxContainer'
import Heading from '../../Component/Heading'
import TableContainer from '../../Component/TableContainer'
import Loder from '../../Component/Loader'
import TosterUnderProcess from '../../Component/TosterUnderProcess'
import Toster from '../../Component/Toster'
import GetPatientDetails from '../API/PerformTest/GET/GetPatientDetails'
import GetTestList from '../API/PerformTest/GET/GetTestList'
import GetDataByTestID from '../API/PerformTest/GET/GetDataByTestID'
import NoDataFound from '../../assets/images/icons/No data-rafiki.svg'
import AlertToster from '../../Component/AlertToster'
import CallApi_PerformTest from '../API/PerformTest/POST/PerformTest'
import TextEditor from '../../Component/TextEditor'
import remarkIcon from '../../assets/images/icons/edit.svg'
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
export default function PerformTest() {
  let [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
  let [showLoder, setShowLoder] = useState(0);
  let [getTxtBillNo, setTxtBillNo] = useState('');
  let [showButtons, setShowButtons] = useState(false);
  let [showTxtBox, setShowTxtBox] = useState(false);
  let [isChecked, setIsChecked] = useState(true);
  let [activeTestName, setActiveTestName] = useState('');
  let [activeTestID, setActiveTestID] = useState('');
  let [patientDetails, setPatientDetails] = useState([]);
  let [testList, setTestList] = useState([]);
  let [testData, setTestData] = useState([]);
  let [showImage, setShowImage] = useState(0);
  // ########################## USE STATE FOR LOADERS ######################
  let [showUnderProcess, setShowUnderProcess] = useState(0);
  let [showToster, setShowToster] = useState(0);
  let [tosterMessage, setTosterMessage] = useState("");
  let [tosterValue, setTosterValue] = useState(0);
  let [saveButtonDisable, setSaveButtonDisable] = useState(false);
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [showErrMessage, setShowErrMessage] = useState('');
  let [editorValue, setEditorValue] = useState("");
  let [isDisabledTextEditor, setisDisabledTextEditor] = useState(false);
  let [txtRemark, stetxtRemark] = useState("");
  let [remarkList, setRemarkList] = useState([]);
  let [showRemark, setRhowRemark] = useState(0);
  let [activeOrganID, setActiveOrganID] = useState('');
  const { t } = useTranslation();
  
  const clientID=JSON.parse(sessionStorage.getItem("LoginData")).clientId;
  //const userID=JSON.parse(sessionStorage.getItem("LoginData")).userId;

  const handleTextboxChange = (event) => {
    if (event.target.name === "BillNo") {
      setTxtBillNo(event.target.value);
    }
   
  };
  let handleTexteditor = (e)=>{
    
    setEditorValue(e.target.value)
  }
  const handlerGetPatientDetailsAndSubtestList = async(param)=>{
    if(getTxtBillNo.trim() === "" || getTxtBillNo.length < 1 || getTxtBillNo === undefined){
         setShowAlertToster(1);
        setShowErrMessage("Invalid Bill Number..");
        setTestData([])
        setTestList([])
    }
    else{
      setShowLoder(1);
    if(param !== 1){
      handlerClear();
      setTestData([]);
      setShowTxtBox(false);
      setActiveTestName('');
      setActiveTestID('');
    }
    const responsePatientDetails= await GetPatientDetails(getTxtBillNo);
    const responseTestList= await GetTestList(getTxtBillNo,clientID);
    if(responsePatientDetails.status === 1){
        setPatientDetails(responsePatientDetails.responseValue[0]);
        setShowLoder(0);
    }
    else{
        setShowLoder(0);
        setShowAlertToster(1);
        setShowErrMessage(responseTestList.responseValue);
    }
    if(responseTestList.status === 1){
      
      if(responseTestList.responseValue.testType[0].testID === null)
      {
        setShowImage(1)
      }
       else{
        setTestList(responseTestList.responseValue.testType);
        setShowLoder(0);
        if(responseTestList.responseValue.testType.length === 0){
            setShowImage(1);
        }
        else{
            setShowImage(0);
        }
       }
    }
    else{
        setShowImage(1);
        setShowLoder(0);
        setShowAlertToster(1);
        setShowErrMessage("Invalid Bill Number..");
    }
    }
  }
  let getTestDataByTestID = async(param)=>{
    const response = await GetDataByTestID(param,clientID);
    if(response.status === 1){
        setTestData(response.responseValue);
        if(response.responseValue.length > 0){
            setActiveTestName(response.responseValue[0].testName);
            setActiveTestID(response.responseValue[0].testId);
            setShowButtons(true);
            setShowTxtBox(true);
            handlerClear();
        }
        else{
            setActiveTestName('');
            setActiveTestID('');
            setShowButtons(false);
            setShowTxtBox(false);
           
        }
       
    }
    else{
        setShowAlertToster(1);
        setShowErrMessage(response.responseValue);
    }
    
   
  }
  
 let handlerPerformTest = async ()=>{
   setShowUnderProcess(1);
     var dataArr=[];
     var dataArrParser=[];
     for(var i = 0; i < testData.length; i++){
        dataArrParser = JSON.parse(testData[i].parameter);
        
        for(var j =0; j < dataArrParser.length; j++){
            const input_boxID= "param_txtbox_val"+testData[i].testId+''+testData[i].organId+''+dataArrParser[j].parameterId;
          const getValue= document.getElementById(input_boxID).value;
          if(getValue !=="" && getValue !== undefined && getValue !== null && getValue.trim() !== ''){
            dataArr.push({
              parameterID:dataArrParser[j].ParameterId,
              parameterResult:getValue,
              organID:testData[i].organId
            });
          }
        }
      }
      var obj ={
        JsonData:JSON.stringify(dataArr),
        uhid:patientDetails.uhId,
        billNumber:patientDetails.billNo,
        pmID:patientDetails.pmid,
        testId:activeTestID,
        normalRangeText:'normalRangeText',
        impression:editorValue,
        resultRemark:JSON.stringify(remarkList),
        userID:userID,
        gender: patientDetails.gender.toUpperCase(),
        ageUnit:patientDetails.agetype,
        age: patientDetails.age,
        clientID:clientID
      }
      const response = await CallApi_PerformTest(obj);
      if(response.status === 1){
        setShowUnderProcess(0);
         setTosterValue(0);
         setShowToster(1);
         setTosterMessage("Test Performed Successfully");
         setTimeout(() => {
             setShowToster(0);
              handlerGetPatientDetailsAndSubtestList(1);
              // handlerClear();
              setShowButtons(false);
              //setActiveTestName('');
              //setActiveTestID('');

         }, 2000)
      }
      else {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage(response.responseValue)
        setTosterValue(1)
        setTimeout(() => {
            setShowToster(0)
        }, 2000)
    }
 }
 let handlerClear=()=>{
  var dataArrParser=[];
  for(var i = 0; i < testData.length; i++){
     dataArrParser = JSON.parse(testData[i].parameter);
     for(var j =0; j < dataArrParser.length; j++){
         const input_boxID= "param_txtbox_val"+testData[i].testId+''+testData[i].organId+''+dataArrParser[j].parameterId;
         document.getElementById(input_boxID).value='';
     }
   }
   setEditorValue('');
   setActiveOrganID('');
  document.getElementById('txtRemark').value="";
  stetxtRemark("")
  setRemarkList([]);

 }
 let handlerShowRemarkPopUp = (params) => {
  setActiveOrganID(params.organId);
  if (remarkList.length > 0) {
    for (var i = 0; i < remarkList.length; i++) {
      if (remarkList[i].testID === activeTestID && remarkList[i].testResultRowID === params.testresultID && remarkList[i].organId === params.organId) {
        document.getElementById('txtRemark').value = remarkList[i].remark;
        stetxtRemark(remarkList[i].remark)
        break
      }
      else {
        document.getElementById('txtRemark').value="";
        stetxtRemark("")
      }
    }
  }
}
let handlerSaveRemark = (params) => {
  const getData = document.getElementById("txtRemark").value;
  let tempArrData = [...remarkList];
  if (tempArrData.length === 0) {
    tempArrData.push({
      testID: activeTestID,
      organId: activeOrganID,
      remark: getData
    })
  }
  else {
    const index = tempArrData.findIndex((arr) => arr.testID === activeTestID && arr.organId === activeOrganID);
    if (index != -1) {
      if(getData === ""){
        tempArrData.splice(index, 1)
      }
      else{
        tempArrData.splice(index, 1, {
          testID: activeTestID,
          organId: activeOrganID,
          remark: getData
        })
      }
    }
    else {
      if(getData !== ""){
        tempArrData.push({
          testID: activeTestID,
          organId: activeOrganID,
          remark: getData
        })
      }
    }
  }
  setRemarkList(tempArrData);

}
  useEffect(() => {
    
  }, [])
  document.body.dir = i18n.dir();
  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className='whitebg'>
                <div className="row">
                  <div className="col-md-4 col-sm-12 analuze">
                    <div className="fieldsett-in">
                      <div className="fieldsett">
                        <span className='fieldse'>{t("Perform_Test")}</span>
                        <div className="mt-2 me-2 col-12" >
                          <img src={bill} className='icnn' alt='icnn' /> <label htmlFor="Bill" className="form-label">{t("Bill_No.")}</label>
                        </div>
                        <BoxContainer>
                          <div className="mb-2 me-2">
                            <input type="text" value={getTxtBillNo} onChange={handleTextboxChange} className="form-control form-control-sm" id="txtBillNo" name="BillNo" placeholder={t("Enter_Bill_No.")} />
                          </div>

                          <div className="mb-2 me-2">
                            <div className='searchbtnn'>
                              <button onClick={handlerGetPatientDetailsAndSubtestList}><i className='fa fa-search'></i>{t("Search_Result")}</button>
                            </div>
                          </div>
                        </BoxContainer>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-12">
                    <div className="fieldsett-in">
                      <div className="fieldsett">
                        <span className='fieldse'>{t("Patient_Details")}</span>
                        <BoxContainer>
                        <div className="mb-2 me-2">
                            <img src={UHID1} className='icnn' alt='icnn' />
                            <label htmlFor="FoodSupplementDrug" className="form-label">{t("Uhid")}</label>
                            <input type='text' disabled className='form-control form-control-sm' placeholder={t("UHID")} value={patientDetails.uhId} />
                          </div>
                          <div className="mb-2 me-2">
                            <img src={UHID1} className='icnn' alt='icnn' />
                            <label htmlFor="FoodSupplementDrug" className="form-label">{t("IP_No")}</label>
                            <input type='text' disabled className='form-control form-control-sm' placeholder={t("IP_No")} value={patientDetails.ipNo} />
                          </div>
                          <div className="mb-2 me-2" >
                            <img src={patient} className='icnn' alt='icnn' />
                            <label htmlFor="FoodSupplementDrug" className="form-label">{t("Patient_Name")}</label>
                            {
                            patientDetails.length === 0 ?
                              <input type='text' disabled className='form-control form-control-sm' placeholder={t("Patient_Name")} /> 
                             :  <input type='text' disabled className='form-control form-control-sm' placeholder={t("Patient_Name")} value={patientDetails.patientName + ' ' + patientDetails.age + ' ' + patientDetails.gender} />
                            }
                            {/* <input type='text' disabled className='form-control form-control-sm' placeholder='Patient Name' value={patientDetails.patientName + ' ' + patientDetails.age + ' ' + patientDetails.gender} />  */}
                           
                           
                            
                          </div>
                          <div className="mb-2 me-2">
                            <img src={UHID1} className='icnn' alt='icnn' />
                            <label htmlFor="FoodSupplementDrug" className="form-label">{t("Visit_No")}.</label>
                            <input type='text' disabled className='form-control form-control-sm' placeholder={t("Visit_No")} value={patientDetails.crNo} />
                          </div>
                          
                          <div className="mb-2 me-2">
                            <img src={center} className='icnn' alt='icnn' />
                            <label htmlFor="FoodSupplementDrug" className="form-label">{t("Center")}</label>
                            <input type='text' disabled className='form-control form-control-sm' placeholder={t("Center")} value={''} />
                          </div>
                          <div className="mb-2 me-2">
                            <img src={department} className='icnn' alt='icnn' />
                            <label htmlFor="FoodSupplementDrug" className="form-label">{t("Department")}</label>
                            <input type='text' disabled className='form-control form-control-sm' placeholder={t("Department")} value={patientDetails.departName} />
                          </div>
                          <div className="mb-2 me-2">
                            <img src={ward} className='icnn' alt='icnn' />
                            <label htmlFor="FoodSupplementDrug" className="form-label">{t("ward")}</label>
                            <input type='text' disabled className='form-control form-control-sm' placeholder={t("ward")} value={patientDetails.wardName} />
                          </div>
                          
                          <div className="mb-2 me-2">
                            <img src={calender} className='icnn' alt='icnn' />
                            <label htmlFor="FoodSupplementDrug" className="form-label">{t("Bill_Date")}.</label>
                            <input type='text' disabled className='form-control form-control-sm' placeholder={t("Bill_Date")} value={patientDetails.billDate} />
                          </div>
                        </BoxContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-12 mt-2">
              <div className='whitebg1'>
                <div className='row'>
                  <div className="col-md-4 col-sm-12 plt">
                    <div className='whitebg' style={{height:'70vh'}}>
                      <Heading text={t("Test_List")} />
                      <div className="med-table-section" style={{ "height": "64vh", position:'relative'  }}>
                       {showImage === 1 ? <div className='imageNoDataFound'><img src={NoDataFound} alt="imageNoDataFound" /></div>:
                        <TableContainer>
                          <thead>
                            <tr>
                              <th className="text-center" style={{ "width": "5%" }}>#</th>
                              <th>{t("testNamePlaceholder")}</th>
                              <th>{t("Status")}</th>
                              <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {testList && testList.map((val, ind) => {
                              
                              return (<>
                                {(val.testID !== null) &&
                                  <tr key={val.id}>
                                  <td>{ind+1}</td>
                                  <td>{val.testname}</td>
                                  <td> {val.isPerformedTest === "YES" ? "DONE" : "NOT DONE"}</td>
                                  {val.isPerformedTest === 'NO' ? 
                                  <td><i className="fa fa-edit actionedit" onClick={()=>{getTestDataByTestID(val.testID)}}></i></td> :
                                  <td  title='Disabled' disabled><i className="fa fa-edit actionedit" style={{backgroundColor:'#00000040'}} disabled></i></td>}
                                </tr>

                              }
                              </>
                                
                              )
                            })}
                          </tbody>
                        </TableContainer>
                       }
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8 col-sm-12 prt">
                    <div className="whitebg">
                      <div className="row">
                        <div className='col-12'>
                          <div className="titile-txt">
                            <div className="title-h">
                              <div className="heading mb-2">{t("testNamePlaceholder")} - <span>{activeTestName}</span></div>
                                { 
                                    testData && testData.map((val,ind)=>{
                                        return(

                                            <div className='mt-2 repeat'>
                                                <div className='ms-2'><b className='fs-6'> {val.organName}</b><img src={remarkIcon} alt="remark" data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#remarkModal" style={{ marginLeft: '8px', borderRadius: '20px', cursor: 'pointer' }} title='Remark' onClick={() => { handlerShowRemarkPopUp(val) }} /></div>
                                                {/* <div className='d-flex repeat gap-3 align-items-center'>
                                                    {JSON.parse(val.parameter).map((list)=>{
                                                      console.log('list : ', list)
                                                        return(
                                                          <>
                                                            <div>
                                                                <div><label htmlFor="" className='form-label'>{list.ParameterName}</label></div>
                                                                <div><input type='text' className='form-control form-control-sm' id={"param_txtbox_val"+val.testId+''+val.organId+''+list.parameterId} /> </div>
                                                            </div>
                                                            <div className='mt-3 fs-6 fst-italic'><span>{list.UnitName}</span></div>
                                                            </>
                                                        )
                                                    })}
                                                </div> */}
                                                <BoxContainer>
                                                {JSON.parse(val.parameter).map((list)=>{
                                                      
                                                        return(
                                                          <>
                                                            <div>
                                                                <div><label htmlFor="" className='form-label' style={{fontWeight:'bold'}}>{list.ParameterName}</label></div>
                                                                <div><input type='text' className='form-control form-control-sm' id={"param_txtbox_val"+val.testId+''+val.organId+''+list.parameterId} /> </div>
                                                            </div>
                                                            <div className='form-label mt-4'><span>{list.UnitName}</span></div>&nbsp;
                                                            </>
                                                        )
                                                    })}
                                                </BoxContainer>
                                            </div>

                                        )   
                                    })
                                    
                                }
                            </div>
                          </div>
                          
                          <div>
                          </div>
                        </div>
                        {showTxtBox === true &&
                         <div className='col-12 mt-1 ms-2'> 
                         <div className='med-table-section box-shadow-none mt-3'>
                              <b >Impression</b>
                              <div className={isDisabledTextEditor ? 'textEditorDisabled':''}>
                                <TextEditor getTextvalue={handleTexteditor} name="abc" id="abc" setValue={editorValue} />
                              </div>
                            </div>
                              {/* <div><b>Impression</b></div>                           
                              <textarea className='form-control' style={{resize:'none'}} value={txtImpression} name='impression' onChange={handleTextboxChange}></textarea>                            */}
                          </div>
                        }
                       {showButtons === true && 
                        <>
                          {showUnderProcess === 1 ? <TosterUnderProcess /> :
                            <>
                              
                                  <div className='col-12 mt-3'>
                                    <div className='d-flex justify-content-end gap-2'>
                                    {showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} /> : 
                                    <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handlerPerformTest}><img src={saveBtnIcon} className="icnn" alt="saveButtonIcon" />{t("Save")}</button>
                                    }
                                    <button type="button" className="btn btn-clear btn-sm mb-1" onClick={handlerClear}><img src={clearBtnIcon} className="icnn" alt=''/>{t("Clear")}</button>  
                                    </div>
                                  </div>
                                
                            </>
                          }
                        </>
                       }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={`modal fade`} id="remarkModal" tabIndex="-1" aria-labelledby="remarkModal" aria-hidden="true">
                        <div className="modal-dialog modalDelete">
                          <div className="modal-content">

                            <div className="modal-body modelbdy text-center">
                              <div className='popDeleteTitle mt-3'> Remark</div>
                              <div className='popDeleteContent'> <textarea col="10" rows="5" id={'txtRemark'}></textarea></div>
                            </div>
                            <div className="modal-footer1 text-center">

                              <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal" onClick={() => { setRhowRemark(0) }}>Cancel</button>
                              <button type="button" className="btn-delete popBtnDelete" onClick={handlerSaveRemark} data-bs-dismiss="modal">Save</button>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className={`modal fade`} id="remarkModal" tabIndex="-1" aria-labelledby="remarkModal" aria-hidden="true" data-bs-backdrop="static"  data-bs-keyboard="false">
                        <div className="modal-dialog modalDelete" style={{margin:'5% auto'}}>
                          <div className="modal-content">

                            <div className="modal-body modelbdy_ text-center_">
                            <div className='fieldsett-in'>
                              <div className='fieldsett'>
                              <span className="fieldse">{t("Remarks")}</span>
                              <div className="inner-content">
                                <div className="mb-2 mt-2">
                                <img src="/static/media/UHID1.3a584370815bb1421aa6f7c648e28ba6.svg" className="icnn" alt="icnn"/>
                                <label for="FoodSupplementDrug" className="form-label">{t("Remark")}</label>
                                <textarea rows="3" id={'txtRemark'} className='form-control'></textarea>
                                </div>
                                <div className='d-flex flex-wrap justify-content-end'>
                                <div> <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal" onClick={() => { setRhowRemark(0) }}>{t("Cancel")}</button></div>
                                <div> <button type="button" className="btn-delete popBtnDelete" onClick={handlerSaveRemark} data-bs-dismiss="modal">{t("Save")}</button></div>
                                </div>

                              </div>
                              </div>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
      </section>

      {/* <div className='chatcnt'><img src={chat} className='icnn' alt=''/> </div> */}
      {
        showLoder === 1 ? <Loder val={showLoder} /> : ""
      }
      {
        showAlertToster === 1 ? <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
      }
    </>
  )
}
