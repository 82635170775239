
  async function GetAllCurrency() {

    let url = window.AdminbaseUrl + "/api/CurrencyMaster/SelectCurrency?id=1"
    let head = { "Content-Type": "application/JSON", accept: '*/*' };
  
    let response = await fetch(url, {
      headers: head,
      method: 'GET'
    })
      .then((res) => res.json())
      .then();
  
    return response;
  }
  export default GetAllCurrency;