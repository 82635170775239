import React, { useEffect, useState } from 'react'
import ventilatorGif from "../assests/images/ventiGif.gif"
import Vectorline from "../assests/images/Vectorline.png"
 

export default function VentilatorSection(props) {
  let [lifeSupportValue, setLifeSupportValue] = useState("")
  let [peepValue, setPeepValue] = useState("-")
  let [ventiMode, setVentiMode] = useState("-")

  let handleLifeSuppoert = (val, id) => {
    if (val.VmId === 179) {
      setLifeSupportValue(val.VmValue + "%" + "(p-" + id + ")")
    }
    else {
      setLifeSupportValue(id + "%" + "(p-" + val.VmValue + ")")
    }
  }

  useEffect(() => {
    setLifeSupportValue("")
    let id179 = ""
    let id98 = ""
    setVentiMode(props.patientVentilatorData && props.patientVentilatorData.length !== 0 ? props.patientVentilatorData[0].ventiModeName : "")
    props.patientVentilatorData && props.patientVentilatorData.map((val, index) => {
      if (val.vmId === 179) {
        id179 = val.vmValue;
        setPeepValue(val.vmValue)
        handleLifeSuppoert(val, id98)

      } else if (val.vmId === 98) {
        id98 = val.vmValue
        handleLifeSuppoert(val, id179)
      }
    });
  }, [props])
  return (
    <>
      <div className='arrow-venti'>
        <div className='wrap-ventilator'>
          <div className='ventilator-cotainer' >
            <div className='d-flex flex-column gap-1 ventilator-data'>
              <span className='venti'>Ventilator</span>
              <div className='ventiin'>
                <span className='d-flex flex-row gap-2'>
                  <label>Mode</label>
                  <label>{ventiMode && ventiMode}</label>
                </span>
                <span className='d-flex flex-row gap-1'>
                  <label>I:E</label>
                  <label>1:2</label>
                </span>
                <span className='d-flex flex-row gap-1'>
                  <label>PEEP</label>
                  <label>{peepValue && peepValue}</label>
                </span>
                <span className='d-flex flex-row gap-1'>
                  <label>max</label>
                  <label>40</label>
                </span>
              </div>

            </div>
          </div>
          <img src={ventilatorGif} className='ventilatorGif' alt="ventilator" />
        </div>
        <div className='arrow'><img src={Vectorline} alt='' className='vectorpc' /></div>
      </div>
    </>
  )
}
