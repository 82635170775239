async function DeletePhysicalActivityTracker(data) {

    let url = window.AppbaseUrl+'/api/PatientPhysicalActivityTracker/DeletePatientPhysicalActivity?id='+data;   
    // let url = "https://localhost:7225/api/PatientPhysicalActivityTracker/DeletePatientPhysicalActivity?id="+data;
    let head = { "Content-Type": "application/JSON", accept : '*/*' };


  let response = fetch(url, {
    headers: head,
    method : 'DELETE'
  }).then((res) => res.json()).then(data);

  return response;
}
export default DeletePhysicalActivityTracker;