import { useEffect, useState } from "react";
import TimeCalculate from "../../../Code/TimeCalculate";
import LineVitalGrap from "../../../Components/LineVitalGrap";
import GetAllDashboardParameterColumnAssign from "../../../Api/GetAllDashboardParameterColumnAssign";
import { useSelector } from "react-redux";

export default function PatientBPR(props) {
    let [bpSys, setBpSys] = useState("");
    let [bpSysId, setBpSysId] = useState("");
    let [bpSysTime, setBpSysTime] = useState("");
    let [bpSysColor, setBpSysColor] = useState("");

    let [bpDys, setBpDys] = useState("");
    let [bpDysId, setBpDysId] = useState("");
    let [bpDysTime, setBpDysTime] = useState("");
    let [bpDysColor, setBpDysColor] = useState("");
    let [bpMap, setBpMap] = useState("");

    let [uhid, setUhId] = useState("")

    // set grap vital data
    let [grapVitalData, setGrapVitalData] = useState({
        showGrap: 0,
        vitalIdSearchNew: '',
        patientName: '',
        UHID: '',
        userId: ''
    })

    let getData = () => {
        setBpSys("");
        setBpSysId("");
        setBpSysTime("");
        setBpSysColor("");
        setBpDys("");
        setBpDysId("");
        setBpDysTime("");
        setBpDysColor("");
        setUhId("");
        props.val.VitalParametersList && props.val.VitalParametersList.map((vital, ind) => {
            if (vital.VitalID.toString() === "4") {
                setBpSys(Math.round(vital.VitalValue * 100) / 100)
                setBpSysColor(vital.VitalColor)
                if (props.handleColor !== undefined) {
                    props.handleColor(vital.VitalColor !== null ? vital.VitalColor : "white", props.columnId)
                }
                setBpSysId(vital.VitalID)
                setUhId(props.val.UhId)
                if (vital.VitalDateTime !== '') {
                    setBpSysTime(TimeCalculate(vital.VitalDateTime))
                }

            }

            if (vital.VitalID.toString() === "6") {
                setBpDys(Math.round(vital.VitalValue * 100) / 100)
                setBpDysColor(vital.VitalColor)
                setBpDysId(vital.VitalID)
                setUhId(props.val.UhId)
                setBpMap(Math.round((bpSys + (2 * vital.VitalValue)) / 3, 2))

                if (vital.VitalDateTime !== '') {
                    setBpDysTime(TimeCalculate(vital.VitalDateTime))
                }

            }
        })
    }

    let modelCloseFun = () => {
        setGrapVitalData({
            showGrap: 0,
            vitalIdSearchNew: '',
            UHID: '',
            userId: ''
        })
    }

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
        if (resp.length !== 0) {

            setPatameterData(resp[0].data)

        }

    }

    useEffect(() => {
        getColumnsParameters()
        getData()
    }, [props.val])
    return (
        <>
            {/* <div className={`overflow-max vitalClass ${(bpSysColor !== "#008000" && bpSysColor !== "#94e094" && bpSysColor !== "#F5F8FF" && bpSysColor !== "" ? "blink" : "")} `} style={{ border: (bpSysColor !== "#008000" && bpSysColor !== "#94e094" && bpSysColor !== "#F5F8FF" && bpSysColor !== "" ? "2px solid " + bpSysColor : "") }}> */}
           
            <div key={props.key} className={`overflow-max vitalClass `}>

                {bpSys.length != 0 ? <>{bpSys ? <span>
                    {
                        paramenterData && paramenterData.map((val, ind) => {
                            if (val.parameterId === 40) {
                                return (
                                    <span style={{ color: bpSysColor }}>{bpSys} </span>

                                )
                            }
                            else if (val.parameterId === 41) {
                                return (
                                    <>
                                        <span style={{ color: bpDysColor }}> / {bpDys}</span>{" "}<br />
                                    </>

                                )
                            }
                            else if (val.parameterId === 42) {
                                return (
                                    <>
                                        {
                                            bpSysTime.split("-")[1] === "D" || (bpSysTime.split("-")[0].split(":")[0] >= 2 && bpSysTime.split("-")[1] === "H") ? <span style={{ 'color': 'red', fontSize: "0.7rem" }} className="blink"><i className="bi bi-clock"></i>  {bpSysTime.toLowerCase().replace("-", "")}</span>
                                                : (bpSysTime.split("-")[0].split(":")[0] >= 1 && bpSysTime.split("-")[1] === "H") ? <span style={{ "color": 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {bpSysTime.toLowerCase().replace("-", "")}</span>
                                                    : (bpSysTime.split("-")[0].split(":")[0] <= 1 && bpSysTime.split("-")[1] === "H") || (bpSysTime.split("-")[0] >= 10 && bpSysTime.split("-")[1] === "M") ? <span style={{ 'color': '#ffa500', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {bpSysTime.toLowerCase().replace("-", "")}</span> : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {bpSysTime.toLowerCase().replace("-", "")}</span>
                                        }
                                        <br />
                                    </>

                                )
                            }
                            else if (val.parameterId === 44) {
                                return (
                                    <>
                                        <span className="text-white">Map-{bpMap}</span> <br />
                                    </>

                                )
                            }
                            else if (val.parameterId === 43 && props.from === undefined) {
                                return (
                                    <>
                                        <div className="" title='BP Graph' ><i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapVitalData({ showGrap: 1, vitalIdSearchNew: [bpSysId, bpDysId], UHID: uhid, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "BP Graph", patientName: props.val.PntName }) }}></i></div>
                                        {/* <br /> */}
                                    </>

                                )
                            }


                        })

                    }



                </span> : 'N/A'}</> : "N/A"}

            </div>

            {grapVitalData.showGrap ? <LineVitalGrap grapVitalData={grapVitalData} modelCloseFun={modelCloseFun} /> : ""}

        </>
    )
}



export function PatientBPRG(props) {
    let [bpSys, setBpSys] = useState("");
    let [bpSysId, setBpSysId] = useState("");
    let [bpSysTime, setBpSysTime] = useState("");
    let [bpSysColor, setBpSysColor] = useState("");

    let [bpDys, setBpDys] = useState("");
    let [bpDysId, setBpDysId] = useState("");
    let [bpDysTime, setBpDysTime] = useState("");
    let [bpDysColor, setBpDysColor] = useState("");
    let [bpMap, setBpMap] = useState("");

    let [uhid, setUhId] = useState("")

    // set grap vital data
    let [grapVitalData, setGrapVitalData] = useState({
        showGrap: 0,
        vitalIdSearchNew: '',
        patientName: '',
        UHID: '',
        userId: ''
    })

    let getData = () => {
        setBpSys("");
        setBpSysId("");
        setBpSysTime("");
        setBpSysColor("");
        setBpDys("");
        setBpDysId("");
        setBpDysTime("");
        setBpDysColor("");
        setUhId("");
        props.val.VitalParametersList && props.val.VitalParametersList.map((vital, ind) => {
            if (vital.VitalID.toString() === "4") {
                setBpSys(Math.round(vital.VitalValue * 100) / 100)
                setBpSysColor(vital.VitalColor)

                setBpSysId(vital.VitalID)
                setUhId(props.val.UhId)
                if (vital.VitalDateTime !== '') {
                    setBpSysTime(TimeCalculate(vital.VitalDateTime))
                }

            }

            if (vital.VitalID.toString() === "6") {
                setBpDys(Math.round(vital.VitalValue * 100) / 100)
                setBpDysColor(vital.VitalColor)
                setBpDysId(vital.VitalID)
                setUhId(props.val.UhId)
                setBpMap(Math.round((bpSys + (2 * vital.VitalValue)) / 3, 2))

                if (vital.VitalDateTime !== '') {
                    setBpDysTime(TimeCalculate(vital.VitalDateTime))
                }

            }
        })
    }

    let modelCloseFun = () => {
        setGrapVitalData({
            showGrap: 0,
            vitalIdSearchNew: '',
            UHID: '',
            userId: ''
        })
    }

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)

        if (resp.length !== 0) {
            setPatameterData(resp[0].data)
        }

    }

    useEffect(() => {
        getColumnsParameters()
        getData()


    }, [props.val])
    return (
        <>
            {/* <div className={`overflow-max vitalClass ${(bpSysColor !== "#008000" && bpSysColor !== "#94e094" && bpSysColor !== "#F5F8FF" && bpSysColor !== "" ? "blink" : "")} `} style={{ border: (bpSysColor !== "#008000" && bpSysColor !== "#94e094" && bpSysColor !== "#F5F8FF" && bpSysColor !== "" ? "2px solid " + bpSysColor : "") }}> */}
            <div key={props.key} >

                {bpSys.length != 0 ? <>{bpSys ? <span>
                    {
                        paramenterData && paramenterData.map((val, ind) => {

                            if (val.parameterId === 43) {
                                return (
                                    <>
                                        <div className="" title='BP Graph' ><i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapVitalData({ showGrap: 1, vitalIdSearchNew: [bpSysId, bpDysId], UHID: uhid, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "BP Graph", patientName: props.val.PntName }) }}></i></div>
                                        {/* <br /> */}
                                    </>

                                )
                            }


                        })

                    }



                </span> : 'N/A'}</> : "N/A"}

            </div>

            {grapVitalData.showGrap ? <LineVitalGrap grapVitalData={grapVitalData} modelCloseFun={modelCloseFun} /> : ""}

        </>
    )
}

