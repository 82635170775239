const getIdentificationbyCountryId= async(countryId)=>{
    const url=window.AdminbaseUrl+"/api/Identification/GetIdentificationsByCountryId?countryId="+countryId;
    const head={'content-type':'application/json','accept':'*/*'}
    let data={};
    let response= await fetch(url,{
        method:"GET",
        header:head,
    }).then(res=> res.json()).then(data);
    return response;
}
export default getIdentificationbyCountryId;