import React, { useEffect, useState } from 'react'
import TimeCalculate from '../../../Code/TimeCalculate';
import LineInvestigationGrap from '../../../Components/LineInvestigationGrap';
import GetAllDashboardParameterColumnAssign from '../../../Api/GetAllDashboardParameterColumnAssign';
import { useSelector } from 'react-redux';

export default function EtCO2R(props) {
    let [etco2, setEtco2] = useState("");
    let [etco2Id, setEtco2Id] = useState("");
    let [etco2Time, setEtco2Time] = useState("");
    let [etco2Color, setEtco2Color] = useState("");

    let [uhId, setUhId] = useState("")

    // set grap Investigation data
    let [grapInvestigationData, setGrapInvestigationData] = useState({
        showGrap: 0,
        subTestId: '',
        patientName: '',
        UHID: '',
        userId: ''
    })

    let getData = () => {
        setEtco2("");
        setEtco2Id("");
        setEtco2Time("");
        setEtco2Color("");
        props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
            if (investigation.SubTestID.toString() === "608") {
                setEtco2(Math.round(investigation.SubTestValue * 100) / 100)
                setEtco2Color(investigation.SubTestColor)
                if (props.setColors !== undefined) {
                    let temp =  [...props.colors]
                    temp.map((val)=>{
                        if(val.columnId === props.columnId)
                        {
                            val.data["EtCO2"] = investigation.SubTestColor
                        }
          
                    })
          
                props.setColors(temp)
                }

                setEtco2Id(investigation.SubTestID)
                setUhId(props.patientData.UhId)

                if (investigation.SubTestDateTime !== '') {
                    setEtco2Time(TimeCalculate(investigation.SubTestDateTime))


                }

            }
        })
    }

    let modelCloseFun = () => {
        setGrapInvestigationData({
            showGrap: 0,
            subTestId: '',
            patientName: '',
            UHID: '',
            userId: ''
        })
    }
    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
           if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

    }

    useEffect(() => {
        getColumnsParameters()

        getData()
    }, [props.patientData])
    return (
        <>
            {/* <div key={props.key} className={`overflow-max investigations ${(etco2Color !== "#008000" && etco2Color !== "#94e094" && etco2Color !== "#F5F8FF" && etco2Color !== "" ? "blink" : "")} `} style={{ border: (etco2Color !== "#008000" && etco2Color !== "#94e094" && etco2Color !== "#F5F8FF" && etco2Color !== "" ? "2px solid " + etco2Color : "") }}> */}
            <div key={props.key} className={`overflow-max investigations `} >


                {
                    paramenterData && paramenterData.map((val, ind) => {
                        if (val.parameterId === 47) {
                            return (
                                <>
                                    <span style={{ color: etco2Color }}>{etco2}</span><br />



                                </>

                            )
                        }

                        else if (val.parameterId === 48) {
                            return (
                                <>
                                    {
                                        etco2Time.split("-")[1] === "D" && etco2Time.split("-")[0] >= 4 ? <span style={{ 'color': 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {etco2Time.toLowerCase().replace("-", "")}</span>
                                            : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {etco2Time.toLowerCase().replace("-", "")}</span>}
                                    <br />
                                </>

                            )
                        }

                        else if (val.parameterId === 49 && props.from === undefined) {
                            return (
                                <>
                                    <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='PO2 Graph' >
                                        {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                                            if (val.SubtestID === etco2Id) {
                                                return (
                                                    <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                                                )
                                            }
                                        })}
                                        <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [etco2Id], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "PO2 Graph", patientName: props.patientData.PntName }) }}></i></div>

                                    {/* <br /> */}
                                </>

                            )
                        }


                    })

                }

                {/* <span>{etco2_time}</span> */}

            </div>
            {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}
        </>
    )
}




export  function EtCO2RG(props) {
    let [etco2, setEtco2] = useState("");
    let [etco2Id, setEtco2Id] = useState("");
    let [etco2Time, setEtco2Time] = useState("");
    let [etco2Color, setEtco2Color] = useState("");

    let [uhId, setUhId] = useState("")

    // set grap Investigation data
    let [grapInvestigationData, setGrapInvestigationData] = useState({
        showGrap: 0,
        subTestId: '',
        patientName: '',
        UHID: '',
        userId: ''
    })

    let getData = () => {
        setEtco2("");
        setEtco2Id("");
        setEtco2Time("");
        setEtco2Color("");
        props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
            if (investigation.SubTestID.toString() === "608") {
                setEtco2(Math.round(investigation.SubTestValue * 100) / 100)
                setEtco2Color(investigation.SubTestColor)
                if (props.setColors !== undefined) {
                    props.setColors((prevArray) => ({ ...prevArray, "EtCO2": investigation.SubTestColor }))
                }

                setEtco2Id(investigation.SubTestID)
                setUhId(props.patientData.UhId)

                if (investigation.SubTestDateTime !== '') {
                    setEtco2Time(TimeCalculate(investigation.SubTestDateTime))


                }

            }
        })
    }

    let modelCloseFun = () => {
        setGrapInvestigationData({
            showGrap: 0,
            subTestId: '',
            patientName: '',
            UHID: '',
            userId: ''
        })
    }
    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
           if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

    }

    useEffect(() => {
        getColumnsParameters()

        getData()
    }, [props.patientData])
    return (
        <>
            {/* <div key={props.key} className={`overflow-max investigations ${(etco2Color !== "#008000" && etco2Color !== "#94e094" && etco2Color !== "#F5F8FF" && etco2Color !== "" ? "blink" : "")} `} style={{ border: (etco2Color !== "#008000" && etco2Color !== "#94e094" && etco2Color !== "#F5F8FF" && etco2Color !== "" ? "2px solid " + etco2Color : "") }}> */}
            <div key={props.key} className={`overflow-max investigations  `}>


                {
                    paramenterData && paramenterData.map((val, ind) => {
                       

                         if (val.parameterId === 49) {
                            return (
                                <>
                                    <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='PO2 Graph' >
                                        {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                                            if (val.SubtestID === etco2Id) {
                                                return (
                                                    <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                                                )
                                            }
                                        })}
                                        <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [etco2Id], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "PO2 Graph", patientName: props.patientData.PntName }) }}></i></div>

                                    {/* <br /> */}
                                </>

                            )
                        }


                    })

                }

                {/* <span>{etco2_time}</span> */}

            </div>
            {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}
        </>
    )
}
