
async function GetClearanceSlip(data) {
    let requiredUHID = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].uhId;
    const clientID=JSON.parse(sessionStorage.getItem("LoginData")).clientId;
    let url = window.SendXMLDatabaseUl +`/api/ClearanceSlipForm/GetClearanceSlipForm?UHID=${requiredUHID}&ClientId=${clientID}`; 
    let head = {
      'Content-Type': 'application/JSON',
      accept: '*/*',
    }
    let response =
      await fetch(url, {
        method: 'GET',
        headers: head,
        body: JSON.stringify(data)
      })
        .then((res) => res.json())
        .then(data)
  
  
    return response;
  }
  export default GetClearanceSlip;

  