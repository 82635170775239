async function GetDoctor() {
    let token = 'bearer ' + window.AppToken;
    const clientID=JSON.parse(sessionStorage.getItem("LoginData")).clientId;
    let url = window.UserbaseUrl + "/api/Users/GetDoctor?clientId="+clientID;
    let head = { "Content-Type": "application/JSON", accept: '*/*', 'Authorization': token };
  
    let response = await fetch(url, {
        headers: head,
        method: 'GET'
    })
        .then((res) => res.json())
        .then();
  
    return response;
  }
  export default GetDoctor;
  
  