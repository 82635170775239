async function GetUserList(userId = '') {
  let url = window.UserbaseUrl + "/api/Users/GetUserList?clientId=" + userId === ''? window.clientId:userId;
  let head = { "Content-Type": "application/JSON", accept: '*/*' };


  let response = await fetch(url, {
    headers: head,
    method: 'GET'
  })
    .then((res) => res.json())
    .then();
  console.log("rr", response)
  return response;
}
export default GetUserList;