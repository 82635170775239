
async function InsertNursingAssessment (data) {

    let url = window.AppbaseUrl + `/api/NursingAssessment/InsertNursingAssessment`
    let head = {
      'Content-Type': 'application/JSON',
      accept: '*/*',
    }
    let response =
      await fetch(url, {
        method: 'POST',
        headers: head,
        body: JSON.stringify(data)
      })
        .then((res) => res.json())  
    return response;
  }
  export default InsertNursingAssessment;
  