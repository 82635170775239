import TimeCalculate from "./TimeCalculate"

let CalculateIsActive = (object) => {
    let flag = 0

    let allVital = [...object]
    let alltime = allVital.map((val) => {
        if (val.VitalID === 74 || val.VitalID === 5 || val.VitalID === 3 || val.VitalID === 56 || val.VitalID === 7 || val.VitalID === 6 || val.VitalID === 4) {
            console.log("calculate time ", TimeCalculate(val.VitalDateTime))
            return TimeCalculate(val.VitalDateTime)
        }

    })
    console.log("csdnkjcskdca", alltime)
    alltime.map((val) => {
        let time = val !== undefined ? val.split("-") : []

        if(time.length !== 3)
        {
            if (time[1] === "M") {
                flag = 1
            }
        }
        else{
            if(time[2] === "M")
            {
                flag = 1
            }
        }
       
    })

    return flag
}

// CalculateIsActive();

export default CalculateIsActive