async function GetAllBankByCountry(ClientID) {
    let url = window.UserbaseUrl + `/api/BankCountryMapping/GetAllBankCountryMapping?clientId=${ClientID}`;
    let head = {
      "Content-Type": "application/JSON", accept: '*/*'
    };
  
    let response = await fetch(url, {
      headers: head,
      method: 'GET'
    })
      .then((res) => res.json())
      .then();
  
    return response;
  }
  export default GetAllBankByCountry;
  
  