let GetVisionPrescreptionList = async (obj)=>{
    let url = window.AppbaseUrl+'/api/VisionPrescription/GetVisionPrescription?uhid='+obj.uhid+'&userId='+obj.userId+'&clientId='+obj.clientId;
    let head = { "Content-Type": "application/JSON", accept: '*/*' };
    let data = {}
    let response = fetch(url, {
        headers: head,
        method: 'GET'
    }).then((res) => res.json()).then(data);

    return response;
}
export default GetVisionPrescreptionList;