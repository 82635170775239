export default async function SettingAPI(datas) {
    let sendData = {
        "parameterId": 0,
        "parameterName": "string",
        "userId": 0
    }
    let url = window.AppbaseUrl + "/api/DashboardColumnSequence/DashboardColumnSequenceSettings?clientId=" + window.clientId+"&userId="+window.userId
    let head = { 'Content-Type': 'application/json', 'accept': '*/*', };
    let data = {};
    let responsonse = "";
    await fetch(url, {
        method: "GET",
        headers: head,
        // body: JSON.stringify(sendData),
    })
        .then(res => res.json())
        .then(data => { responsonse = data })
        .catch(error => { responsonse = error })


    return responsonse
}