import React, { useEffect, useState } from 'react'
import Heading from '../../../../../../Component/Heading'
import Toster from '../../../../../../Component/Toster'
import TosterUnderProcess from '../../../../../../Component/TosterUnderProcess'
import IconEdit from '../../../../../../assets/images/icons/IconEdit.svg'
import IconDelete from '../../../../../../assets/images/icons/IconDelete.svg'
import Search from '../../../../../../Code/Serach'
import saveButtonIcon from '../../../../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../../../../assets/images/icons/clear.svg';
import IconICULineCathetorline from '../../../../../../assets/images/icons/IconICULineCathetorline.svg';
import IconIcuAdd from '../../../../../../assets/images/icons/IconIcuAdd.svg';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import Loader from '../../../../../../Component/Loader'
import SuccessToster from '../../../../../../Component/SuccessToster'
import AlertToster from '../../../../../../Component/AlertToster'
import GetAllPhysicalActivityTraker from '../ICUChartAPi/GetAllPhysicalActivityTraker'
import DropdownWithSearch from '../../../../../../Component/DropdownWithSearch'
import InsertPatientPhysicalActivity from '../../PatientPhysicalActivity/API/InsertPatientPhysicalActivity'
import ValidationICUChartPhysicalActivity from '../ICUChartValidation/ValidationICUChartPhysicalActivity'
import GetLineCathetorTypeMaster from '../../../../../../Admin/Api/Master/LineCathetorTypeMaster/GetLineCathetorTypeMaster'
import GetAllPatientLineCathetor from '../ICUChartAPi/PatientLineCathetorAPI/GetAllPatientLineCathetor'
import ValidationLineAndCathetor from '../ICUChartValidation/ValidationLineAndCathetor'
import InsertPatientLineCathetor from '../ICUChartAPi/PatientLineCathetorAPI/InsertPatientLineCathetor'
import UpdatePatientLineCathetor from '../ICUChartAPi/PatientLineCathetorAPI/UpdatePatientLineCathetor'

export default function LineAndCathetor(props) {
  const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;
  console.log("Uhid2", uhID)
  let [rowId, setRowId] = useState('')
  let [patientLineCathetorList, setPatientLineCathetorList] = useState([])
  let [sendForm, setSendForm] = useState({ "userId": window.userId, "clientId": window.clientId })
  let [clearDropdown, setClearDropdown] = useState(0)
  let [updateBool, setUpdateBool] = useState(0)

  let [showUnderProcess, setShowUnderProcess] = useState(0)
  let [showToster, setShowToster] = useState(0)
  let [tosterMessage, setTosterMessage] = useState("")
  let [tosterValue, setTosterValue] = useState(0)
  let [editLineCathetor, setEditLineCathetor] = useState("")
  const [showRemovalDateTime, setShowRemovalDateTime] = useState(false);


  let [showLoder, setShowLoder] = useState(0);
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [showErrMessage, setShowErrMessage] = useState('');
  let [showSuccessMsg, setShowSuccessMsg] = useState('');
  let [isShowToaster, setisShowToaster] = useState(0);
  const [lineCathetorTypeList, setLineCathetorTypeList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  document.body.dir = i18n.dir();
  const [tableHeaders, setTableHeaders] = useState([]);

  //Get Patient Line and Cathetor
  const getPatientLlineCathetor = async () => {
    try {
      const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;
      setShowLoder(1);
      const response = await GetAllPatientLineCathetor(uhID);
      if (response && response.status === 1) {
        setPatientLineCathetorList(response.responseValue);
      } else {
        setShowAlertToster(1);
        setShowErrMessage(response ? response.responseValue : 'Unexpected error');
      }
    } catch (error) {
      setShowAlertToster(1);
      setShowErrMessage('Unexpected error');
    } finally {
      setShowLoder(0);
      setTimeout(() => {
        setShowAlertToster(0);
      }, 1500);
    }
  };


  const getDisplayText = (activity, hour) => {
    const { timeFrom, timeTo, totalTimeInMinutes } = activity;
    const fromDateTime = new Date(timeFrom);
    const toDateTime = new Date(timeTo);

    const fromHour = fromDateTime.getHours();
    const toHour = toDateTime.getHours();
    if (hour >= fromHour && hour <= toHour) {
      return `${totalTimeInMinutes} min`;
    } else {
      return "-";
    }
  };



  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };


  const getLineCathetor = async () => {
    try {
      setShowLoder(1);
      const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
      const response = await GetLineCathetorTypeMaster(clientID);
      if (response && response.status === 1) {
        setLineCathetorTypeList(response.responseValue);
      } else {
        setShowAlertToster(1);
        setShowErrMessage(response ? response.responseValue : 'Unexpected error');
      }
    } catch (error) {
      setShowAlertToster(1);
      setShowErrMessage('Unexpected error');
    } finally {
      setShowLoder(0);
      setTimeout(() => {
        setShowAlertToster(0);
      }, 1500);
    }
  };



  // Handle Change
  let handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setEditLineCathetor("")
    setSendForm((prevForm) => ({
      ...prevForm,
      [name]: value
    }));
  };


  //Handle Save
  let saveForm = async () => {
    let valresponse = ValidationLineAndCathetor(sendForm.lineCathetorTypeId, sendForm.insertionDateTime);
    if (valresponse) {
      setShowUnderProcess(1);
      console.log('uhID save', uhID);
      var obj = {
        "uhid": uhID,
        "lineCathetorTypeId": sendForm.lineCathetorTypeId,
        "insertionDateTime": sendForm.insertionDateTime,
        "userId": window.userId,
        "clientId": window.clientId
      }
      let response = await InsertPatientLineCathetor(obj);
      if (response.status === 1) {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage("Data Save Successfully!")
        setTosterValue(0)
        setTimeout(() => {
          setShowToster(0)
          // handleCloseModal();
        }, 2000)
        handleClear(1);
        getPatientLlineCathetor();
        setShowRemovalDateTime(false);
      }
      else {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage(response.responseValue)
        setTosterValue(1)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
      }
    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage("Field can't be blank!")
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
    }
  }

  // get data for update 
  const handleUpdate = (id, removalDateTime) => {
    setUpdateBool(1);
    setSendForm((prevForm) => ({
      ...prevForm,
      id,
      removalDateTime,
    }));
    setShowRemovalDateTime(true);
    document.getElementById("removalDateTime").value = removalDateTime;
  };

  // Handle Update
  let saveUpdate = async () => {
    const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;
    // let valresponse = ValidationLineAndCathetor(sendForm.removalDateTime);
    let valresponse = 1;

    if (valresponse) {
      setShowUnderProcess(1)
      let response = await UpdatePatientLineCathetor(sendForm)
      if (response.status === 1) {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage("Data Updated SuccessFully!")
        setTosterValue(0)
        setTimeout(() => {
          setShowToster(0)
          handleCloseModal();
        }, 2000)

        setUpdateBool(0)
        getPatientLlineCathetor();
        handleClear();
      }
      else {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage(response.responseValue)
        setTosterValue(1)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
      }
    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage("Field can't be blank!")
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
    }
  }


  //Handle Clear
  let handleClear = (value) => {
    setSendForm({ "userId": window.userId, "clientId": window.clientId })
    setShowRemovalDateTime(false);
    setClearDropdown(value)
    setEditLineCathetor(0);
    document.getElementById('insertionDateTime').value = '';
    document.getElementById('removalDateTime').value = '';
  }


  useEffect(() => {
    getPatientLlineCathetor();
    getLineCathetor();
    console.log("Patient Line Catheter List", patientLineCathetorList);
  }, [])

  useEffect(() => {
    if (patientLineCathetorList && patientLineCathetorList.length > 0) {
      const allDates = patientLineCathetorList.reduce((dates, val) => {
        if (val.insertionDateTime) dates.push(val.insertionDateTime.split('T')[0]);
        if (val.removalDateTime) dates.push(val.removalDateTime.split('T')[0]);
        return dates;
      }, []);

      const startDate = new Date(Math.min(...allDates.map(date => new Date(date).getTime())));
      const endDate = new Date(Math.max(...allDates.map(date => new Date(date).getTime())));

      const dateRange = [];
      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        dateRange.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
      }

      setTableHeaders(dateRange);
    }
  }, [patientLineCathetorList]);

  const [selectedRow, setSelectedRow] = useState(null);
  const handleRowClick = (rowId) => {
    setSelectedRow((prevSelectedRow) =>
      prevSelectedRow === rowId ? null : rowId
    );
  };


  return (
    <>
      <div className='icuChartHeading  mb-2'>
        <div className='icuChartHeading-inn'>
          <div className='ICUHead-lft'>
            <span><img src={IconICULineCathetorline} alt='' /></span>
            <span><h3>Line & Catheter</h3></span>
          </div>
        </div>
        <div className='icuChartHeading-inn pointSpan' style={{display:props.isPrint===1?"none":"block"}}>
          <span><img src={IconIcuAdd} alt='' onClick={handleShowModal} /></span>
        </div>
      </div>
      <div className="med-table-section Icu-componenttbl" style={{ "height": "35vh" }}>
        {patientLineCathetorList.length === 0 ? (
          <div className="no-dataavailble">
            No Data Available.
          </div>
        ) : (
          <table className="med-table table-responsive">
            <thead>
              <tr>
                <th style={{ minWidth: "150px" }}>Line</th>
                {tableHeaders.map((date) => (
                  <th key={date} className='text-center' style={{ minWidth: "100px" }}>{new Date(date).toLocaleDateString('en-GB').replace(/\//g, '-')}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {patientLineCathetorList
                .filter((val) => val.insertionDateTime)
                .map((entry) => (
                  <tr key={entry.id}>
                    <td>{entry.lineCathetorType}</td>
                    {tableHeaders.map((date) => {
                      const matchingEntry = patientLineCathetorList.find(
                        (val) =>
                          val.lineCathetorTypeId === entry.lineCathetorTypeId &&
                          val.insertionDateTime &&
                          val.insertionDateTime.split('T')[0] === date
                      );

                      const removalEntry = patientLineCathetorList.find(
                        (val) =>
                          val.lineCathetorTypeId === entry.lineCathetorTypeId &&
                          val.removalDateTime &&
                          val.removalDateTime.split('T')[0] === date
                      );

                      return (
                        <td key={date} className='text-center' style={{ whiteSpace: 'nowrap', fontStyle: 'italic' }}>
                          <div className='cathercolor'>
                            {matchingEntry
                              ?
                              `Insert-${new Date(matchingEntry.insertionDateTime).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                              })} `
                              : ''}
                              </div>
                              <div className='cathercolor1'>
                            {removalEntry
                              ? `Remove-${new Date(removalEntry.removalDateTime).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                              })}`
                              : ''}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                ))}
            </tbody>


          </table>
        )}

        {/* Modal */}
        <div className={`modal fade bk-dropfilter ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
          <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header modalheading">
                <h5 className="modal-title">Patient Line & Catheter</h5>
                <i className="fa fa-times" onClick={handleCloseModal}></i>
              </div>
              <div className="modal-body">
                <div className='med-box1'>
                  <div className='inner-content1'>
                    <div className='icu-pophead'>
                      <div className='icuPhyInput mb-3' style={{ display: updateBool === 0 ? 'block' : 'none' }}>
                        <label htmlFor="lineCathetorTypeId" className="form-label">Line Catheter Type<span className="icustarMandatory">*</span></label>
                        {lineCathetorTypeList && <DropdownWithSearch defaulNname="Select Line Cathetor Type" name="lineCathetorTypeId" list={lineCathetorTypeList} valueName="id" displayName="lineCathetorType" editdata={editLineCathetor} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                      </div>

                      <div className='icuPhyInput mb-3' style={{ display: updateBool === 0 ? 'block' : 'none' }}>
                        <label for="insertionDateTime" className="form-label"> Insertion Date Time<span className="icustarMandatory">*</span> </label>
                        <input type="datetime-local" className="form-control form-control-sm" id="insertionDateTime" placeholder="select Insertion Date and Time" name="insertionDateTime" onChange={handleChange} />
                      </div>

                      <div className='icuPhyInput mb-3' style={{ display: updateBool === 1 ? 'block' : 'none' }}>
                        <label for="removalDateTime" className="form-label">Removal Date Time</label>
                        <input type="datetime-local" className="form-control form-control-sm" id="removalDateTime" placeholder="select Removal Time In Minutes" name="removalDateTime" onChange={handleChange} />
                      </div>
                      <div className='icuPhyInput mb-3'>
                        <label for="removalDateTime" className="form-label">&nbsp;</label>
                        <div className="relative icubtns">
                          {showUnderProcess === 1 ? <TosterUnderProcess /> :
                            <>
                              {showToster === 1 ?
                                <Toster value={tosterValue} message={tosterMessage} />
                                : <div>
                                  {updateBool === 0 ?
                                    <>
                                      <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-2" onClick={saveForm}><img src={saveButtonIcon} className='icnn' alt='' />{t("Save")}</button>
                                      <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={() => { handleClear(1) }}><img src={clearIcon} className='icnn' alt='' />{t("Clear")}</button>
                                    </>
                                    :
                                    <>
                                      <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={saveUpdate}>{t("UPDATE")}</button>
                                      <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { setUpdateBool(0); handleClear() }}>{t("Cancel")}</button>
                                    </>
                                  }
                                </div>}
                            </>
                          }
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="col-12 mt-1">
                    <div className='handlser headingIcuChart'>
                      <Heading text={t("Patient Line & Catheter List")} />
                    </div>
                    <div className="med-table-section" style={{ "height": "30vh" }}>
                      <table className="med-table border_ striped">
                        <thead>
                          <tr>
                            <th className="text-center" style={{ "width": "5%" }}>{t("S.No.")} </th>
                            <th>Line Catheter</th>
                            <th>Insertion Date Time</th>
                            <th>Removal Date Time</th>
                            <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {patientLineCathetorList && patientLineCathetorList.map((val, ind) => {
                            return (
                              <tr>
                                <td className="text-center">{ind + 1}</td>
                                <td>{val.lineCathetorType}</td>
                                <td>{new Date(val.insertionDateTime).toLocaleDateString('en-GB').replace(/\//g, '-')}&nbsp;{new Date(val.insertionDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>

                                <td>
                                  {val.removalDateTime !== null ?
                                    `${new Date(val.removalDateTime).toLocaleDateString('en-GB').replace(/\//g, '-')} ${new Date(val.removalDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}` :
                                    ''}
                                </td>
                                <td>
                                  <div className="action-button">
                                    <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom" title="Edit Row" onClick={() => { handleUpdate(val.id, val.removalDateTime) }}><img src={IconEdit} alt='' /></div>
                                    {/* <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={IconDelete} alt='' /></div> */}
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {
          showLoder === 1 ? <Loader val={showLoder} /> : ""
        }
        {
          isShowToaster === 1 ?
            <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
        }
        {
          showAlertToster === 1 ?
            <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
        } */}
      </div>
    </>
  )
}
