import React from 'react'
import FinanceNavbar from './FinanceNavbar'
import FinanceSidebar from './FinanceSidebar'
import "../assets/css/FinanceCSS.css";

export default function FinaceCommonLayout({component}) {
  return (
    <>
    <FinanceNavbar/>
    <FinanceSidebar/>
    {component}
    </>
  )
}
