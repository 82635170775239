
import React, { useState, useEffect } from 'react';
import SuccessToster from "../../../../../Component/SuccessToster";
import AlertToster from "../../../../../Component//AlertToster";

import Select from 'react-select';
import saveButtonIcon from '../../../../../assets/images/icons/saveButton.svg';

import clearIcon from '../../../../../assets/images/icons/clear.svg';
import GetAllCatheterAssociated from './API/GetAllCatheterAssociated';
import POSTCatheterAssociated from './API/POSTCatheterAssociated';
import GetAllPastMedicalHistory from '../../Assessment/API/GetAllPastMedicalHistory';
import ProblemComponent from './ProblemComponent';
import GetMedications from './API/GetMedications';



// 
export default function CatheterAssociated() {
  const currentDate = new Date();
  const DateFormated = currentDate.toISOString().substr(0, 10);
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
//   const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  const formattedTime = `${hours}:${minutes}`;
  let formattedDate = '';

  const [PatientData, setPatientData] = useState();
  const [PatientCondition, setPatientCondition] = useState([]);
  const [ComplaintJSON, setComplaintJSON] = useState([]);
  const [Risk, setRisk] = useState([]);
  const [catheterAssociatedReport, setcatheterAssociatedReport] = useState([]);
  const [patientsymptom, setPatientsymptom] = useState([]);
  const [isClearable,] = useState(true);
  let [Saverow, setSaverow] = useState([])
  const [isFieldDisabled, setisFieldDisabled] = useState(false)
  const [isSelectedMedsFieldDisabled, setisSelectedMedsFieldDisabled] = useState(true)
  const [isSearchable,] = useState(true);
  let [InvestigationList, setInvestigationList] = useState([])
  let [medicationList, setmedicationList] = useState([])

  const [SignAndSymptom, setSignAndSymptom] = useState('');
  const [ForReports, setForReports] = useState('');
  const [Time, setTime] = useState(formattedTime);
  const [date, setDate] = useState(DateFormated);
  const [PatientVitals , setPatientVitals] = useState([])
  let [showUnderProcess, setShowUnderProcess] = useState(0)
  let [showToster, setShowToster] = useState(0)
  let [tosterMessage, setTosterMessage] = useState("")
  let [tosterValue, setTosterValue] = useState(0)


  let [showAlertToster, setShowAlertToster] = useState(0);
  let [showSuccessToster, setShowSuccessToster] = useState(0)
  let [showMessage, setShowMeassage] = useState("");
  const [SelectedMeds, setSelectedMeds] = useState(null)
  const [isUpdateBtnShow, setisUpdateBtnShow] = useState(false)
  

  function getCurrentDateTime() {
    const now = new Date();
   
    const ISTTime = new Date(now.getTime() );
    
    // Get hours and minutes
    const hours = ISTTime.getHours().toString().padStart(2, '0');
    const minutes = ISTTime.getMinutes().toString().padStart(2, '0');
    
    return hours + ':' + minutes;
  }


  const handleSave=async()=>{
   
    if (SelectedMeds == null) {
      setShowAlertToster(1)
      setShowMeassage('Antibiotic Name is Required..!')
      return
    }

    let obj = {
      "medicineId": SelectedMeds.value,
      "medicineName": SelectedMeds.label,
      "catheterDate": date,
      "catheterTime": Time,
      "report": ForReports,
      "jsonDiagnosis": JSON.stringify(ComplaintJSON),
      "userId": window.userId,
      "clientId":  JSON.parse(sessionStorage.getItem("LoginData")).clientId,
    }
     console.log("obj" , obj)
     let clearData ={
      setClear : 1
     }
 
    let data = await POSTCatheterAssociated(obj)
    if(data.status === 1){
      setShowSuccessToster(1)
      setShowMeassage("Data Saved Successfully")
      window.sessionStorage.setItem("runClearData" , JSON.stringify(clearData))
      handleClearSigns()
      handleClear()
      GetData()
      getMedicationData()
      setTimeout(() => {
        window.sessionStorage.removeItem("runClearData");
      }, 1000);
      setTimeout(() => {
        setShowSuccessToster(1)
        
      }, 2000);
    }
    else{
      setShowUnderProcess(0);
      setShowToster(1);
      setTosterValue(1);
      setShowAlertToster(1)
      setTosterMessage(data.responseValue);
      setTimeout(() => {
        setShowToster(0);
      }, 2000);
    }
  }

const handleClear=()=>{
setSelectedMeds(null)
setForReports("")


let clearData ={
  setClear : 1
 }
window.sessionStorage.setItem("runClearData" , JSON.stringify(clearData))
    handleClearSigns()
 
    setTimeout(() => {
      window.sessionStorage.removeItem("runClearData");
    }, 1000);
    clearRow()
// setComplaintJSON("")
}

const clearRow=(row)=>{
  setComplaintJSON([row])
 }
const handleComplaintData = (temp,data) => {
  // console.log("temp",temp)
  // console.log("data",data)
  setComplaintJSON(temp,data);

};
  const GetData = async()=>{
    let data = await GetAllCatheterAssociated()
    if(data.status === 1){
      setcatheterAssociatedReport(data.responseValue)
      console.log("Data" , data.responseValue)
    }
  }

  const getMedicationData=async()=>{
    let data = await GetMedications()
    if(data.status ===1){
      // console.log("medicines" , data.responseValue)
      setmedicationList(data.responseValue.map(val => ({
        value: val.id,
        label: val.brandName
      })))
    }
  }

  const handleClearSigns=(row)=>{
    setSaverow([row])
    }


  const handleOnChange = (e)=>{
    const {name,value}=e.target
      if(name ==="date"){
      setDate(value)
      }
      if(name === "time"){
        setTime(value)
      }
      if(name === "Symptom"){
        setSignAndSymptom(value)
       }
       if(name === "report"){
        setForReports(value)
       }

  }

  const handleSelectChange = (selectedOption , setSelectedFunction) => {
    setSelectedFunction(selectedOption);
  
  };
  
  useEffect(() => {
    GetData()
    // GetSymptoms();
    getMedicationData()
  }, []);  


    return (
        <>
     <section className="">
        <div className="container-fluid">
          
          <div className="col-12">
            <div className="med-box  mb-1">
              <div className="title">Catheter Associated</div>
              </div>
              </div>
           
              <div className="med-box">
        
                <div className="inner-content">
                
                  <div className="row">
                     <div className='fieldsett-in col-12 col-sm-4'>
                   <div className='fieldsett'>
                    <span className='fieldse'>Catheter Details</span>

                <div className='row px-2'>
                    {/* <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-3">
                      <label htmlFor="Code" className="form-label">Time of Assessment<span className="starMandatory"></span></label>
                      <input  id="dob" type="time" className="form-control form-control-sm"  value={timeofAssessment} name="timeofAssessment"  />
                </div> */}
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3">
                  <label htmlFor="Code" className="form-label">Date<span className="starMandatory"></span></label>
                  <input  id="ddalarmtime" type="date" value={date} className="form-control form-control-sm" name="date" onChange={handleOnChange}  />
                </div>

                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3">
                  <label htmlFor="Code" className="form-label">Time<span className="starMandatory"></span></label>
                  <input  id="ddalarmtime" type="time" value={Time} className="form-control form-control-sm" name="time" onChange={handleOnChange} />
                </div>
                   <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">
                      <label htmlFor="ddlitemmaster" className="form-label ">Antibiotic Name<span className="starMandatory">*</span></label>
                      <Select value={SelectedMeds} options={medicationList} className=" create-select" id="serviceType" placeholder="Select Antibiotic" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, setSelectedMeds)} />
                      <small id="errEquipment" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>

                    </div>

                      
                     {/* <div className="col-xxl-12 col-xl-6 col-lg-6 col-md-6 mb-1 mt-1 mb-3">
                      <label htmlFor="ddlitemmaster" className="form-label ">Antibiotic Name<span className="starMandatory">*</span></label>
                      <Select value={SelectedMeds} is  options={InvestigationList} className=" create-select" id="serviceType" placeholder="Select Antibiotic" isSearchable={isSearchable} isClearable={isClearable}  />
                      <small id="errEquipment" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div> */}

                    {/* <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3" id="paymentModediv">
                      <label htmlFor="Code" className="form-label">Sign And Symptom<span className="starMandatory">*</span></label>
                      <input id="ddwarningviewtime" type="text" value={SignAndSymptom} className="form-control form-control-sm" placeholder='Please enter sign & symptom' name="Symptom"  style={{height: '8vh'}} onChange={handleOnChange}/>
                    </div>   */}
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3" id="paymentModediv">
                      <ProblemComponent ComplaintData={handleComplaintData} clear={clearRow} />
                    </div>  

                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3" id="paymentModediv">
                      <label htmlFor="Code" className="form-label">Report<span className="starMandatory">*</span></label>
                      <input id="ddwarningviewtime" type="text" value={ForReports} className="form-control form-control-sm" placeholder='Please enter Report' name="report"  style={{height: '8vh'}} onChange={handleOnChange}/>
                    </div> 

                  </div>
                      </div>

                     <div className='fieldsett-in col-12 col-sm-8'>
                   <div className='fieldsett'>
                    <span className='fieldse'>Catheter Associated</span>
                     <div className='row px-2'>
                       <div className='row px-2'>

              <div className="col-12 mt-3">
                 <div className="med-table-section" style={{maxHeight : '345px',minHeight: '345px',overflow: 'auto'}}>
                  <table className="med-table border striped">
                   <thead style={{zIndex: '0'}}>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>

                    <th>Medicine Name</th>
                    <th>Catheter Date</th>
                    <th>CatheterTime</th>
                    <th>Report</th>
                    {/* <th>CreatedDate</th> */}
                    <th>ProblemName</th>
                    </tr>

                  </thead>
                  <tbody>
                    {catheterAssociatedReport && catheterAssociatedReport.map((data, index) => {
                      
                        return(
                         <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          
                          <td>{data.medicineName}</td>
                          <td>{data.catheterDate}</td>
                          <td>{data.catheterTime}</td>
                          <td>{data.report}</td>
                          {/* <td>{data.createdDate}</td> */}
                          {/* <td>{data.problemName}</td> */}
                          <td>
                            {data.problemName &&
                              JSON.parse(data.problemName).map(
                                (val, index) => (
                                  <span key={val.ProblemId}>
                                    {val.ProblemName}
                                    {index <
                                      (JSON.parse(data.problemName) || [])
                                        .length -
                                      1 && ", "}
                                  </span>
                                )
                              )}
                          </td>
                        </tr>

                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>


{/* <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-3">
<label htmlFor="Code" className="form-label">From Area<span className="starMandatory">*</span></label>
<select  className="form-control form-control-sm" value={FromArea} name="FromArea" >
<option value='0' >Select</option>
  {AreaTableFrom && AreaTableFrom.map((val,index)=>{
    return(
      <>
       <option key={index} value={val.id} >{val.areaName}</option>
       </>
    )
  })}
</select>
</div>

<div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-3">
<label htmlFor="Code" className="form-label">To Area<span className="starMandatory">*</span></label>
<select  className="form-control form-control-sm" value={ToArea} name='ToArea'   >
<option value='0' >Select</option>
  {AreaTableTo && AreaTableTo.map((val,index)=>{
    return(
      <>
       <option key={index} value={val.id} >{val.areaName}</option>
       </>
    )
  })}
</select>
</div> */}
{/* <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-3">
   <label htmlFor="Code" className="form-label">Date<span className="starMandatory"></span></label>
   <input  id="ddalarmtime" type="date" value={date} className="form-control form-control-sm" name="date"   />
 </div> */}

 {/* <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-3">
   <label htmlFor="Code" className="form-label">Time<span className="starMandatory"></span></label>
   <input  id="ddalarmtime" type="time" value={Time} className="form-control form-control-sm" name="time"   />
 </div> */}



{/* <label htmlFor="Code" className="form-label ">Method of Transfer<span className="starMandatory"></span></label> */}

                    </div>
                      </div>

                  </div>
                  </div>

                  </div>
               
                </div>
               
              </div>

          <div className="col-12 ">
             <div className="med-box mt-2">
               <div className="inner-content">
               <div className="row">

                <div className="inner-content text-right">
                  <div className="mb-2 mt-2 relative">
                  <div>
                            {isUpdateBtnShow !== true ? <>
                              <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleSave}><img src={saveButtonIcon} className='icnn' />Save</button>
                              <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' />Clear</button>
                            </> :
                              <>
                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1 mx-1">Update</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1">Cancel</button>
                              </>
                            }
                          </div>
                   </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showAlertToster === 1 ? (
              <AlertToster message={tosterMessage} handle={setShowAlertToster} />
            ) : (
              ""
            )}
           {showSuccessToster === 1 ? (
              <SuccessToster
                message={showMessage}
                handle={setShowSuccessToster}
              />
            ) : (
              ""
            )}
      </section>
 
        </>
    )
}



