import UpdateDashboardParameterColumnAssign from "../Api/UpdateDashboardParameterColumnAssign"

let targetDataId = -1
let onStart = (e) => {
    // console.log(e)
}
let onDragOver = (e, col) => {
    // console.log("dcsdcs", col)
    targetDataId = col
}
let onDrop = (e) => {
    // console.log("cdsc", e)
    let data = JSON.parse(e.target.getAttribute("data-value"))
    if (targetDataId !== -1 && targetDataId <= 3 && data.columnId <= 3) {
        if (data.columnId !== targetDataId) {
            let sendData = {
                "id": data.paramenterData.id,
                "parameterId": data.parameterId,
                "columnId": targetDataId,
                "assignedTo": data.paramenterData.assignedTo,
                "userId": data.paramenterData.userId,
                "clientId": window.clientId
            }
            updateColumn(sendData)
            // console.log("result", sendData)
        }


    }

    // console.log("enter", JSON.parse(e.target.getAttribute("data-value")))

}

let updateColumn = async (data) => {
    let resp = await UpdateDashboardParameterColumnAssign(data)
    // console.log("fdsdsdv", resp)
    if (resp.status === 1) {
        window.location.reload();
    }
    else {
        window.location.reload();
    }

}
export { onStart, onDrop, onDragOver }

