// function ValidationHSNCodeMaster(hsnCode = 0, category = "", gst=0) {
//     if (hsnCode !== 0 && hsnCode.trim().length !== 0, category !=="" && category.trim().length !== 0, gst!== 0 && gst.trim().length !== 0) {
//         return [true, ""]
//     }

//     else if(hsnCode === 0 || hsnCode.trim().length === 0){
//         return [false, "HSN Code field can't be blank"]
//     }

//     else if(category === "" || category.trim().length === 0){
//         return [false, "Category field can't be blank"]
//     }

//     else if(gst === 0 || gst.trim().length === 0){
//         return [false, "GST field can't be blank"]
//     }

    
// }
// export default ValidationHSNCodeMaster;

function ValidationHSNCodeMaster(hsnCode = "", category = "", gst = "") {
    if (hsnCode && category.trim() && gst) {
        return [true, ""];
    }

    else if (!hsnCode || !hsnCode.trim()) {
        return [false, "HSN Code field can't be blank"];
    }

    else if (!category || !category.trim()) {
        return [false, "Category field can't be blank"];
    }

    else if (!gst || !gst.trim()) {
        return [false, "GST field can't be blank"];
    }
}

export default ValidationHSNCodeMaster;