let GetAllProcedureWithoutSedation = async(uhID) => {
    const clientID=JSON.parse(sessionStorage.getItem("LoginData")).clientId;
    let url = window.OTBaseURL +`ProcedureWithoutSedation/GetAll?uhId=${uhID}&clientId=${clientID}`; 
      const head = {'content-type':'application/json','accept':'*/*',}
      let data = {};
      let response = await fetch(url,{
        method: "GET",
        header: head,
      }).then(res => res.json()).then(data);
      return response;
    }
    export default  GetAllProcedureWithoutSedation;