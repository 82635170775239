function DonorVisitValidations (donorID = '', SegmentNo = '', DonationDate = '', BloodQuantity = '', Weight = '', HBLevel = '', BloodPressure = '',bloodPressureDistolic= '' ,  PulseRate = '', Temperature = '') {
  if(donorID === '' || donorID.toString().length === 0 || donorID === null)
  {
    return ['Please fill Donor ID','errDonor'];
  }
  if(SegmentNo === '' || SegmentNo.toString().length === 0 )
  {
    return ['Please fill SegmentNo','errSegmentNo'];
  }
  if(DonationDate === '' || DonationDate.toString().length === 0 )
  {
    return ['Please fill Donation Date','errDonationDate'];
  }
  if(BloodQuantity === '' || BloodQuantity.toString().length === 0 )
  {
    return ['Please fill BloodQuantity','errBloodQuantity'];
  }
  if(Weight === '' || Weight.toString().length === 0 )
  {
    return ['Please fill Weight','errWeight'];
  }
  if(HBLevel === '' || HBLevel.toString().length === 0 )
  {
    return ['Please fill HBLevel','errHBLevel'];
  }
  if(BloodPressure === '' || BloodPressure.toString().length === 0 )
  {
    return ['Please fill BPS','errBloodPressure'];
  }
  if(bloodPressureDistolic === '' || bloodPressureDistolic.toString().length === 0 )
  {
    return ['Please fill BPD','errbloodPressureDistolic'];
  }
  if(PulseRate === '' || PulseRate.toString().length === 0 )
  {
    return ['Please fill PulseRate','errPulseRate'];
  }
  if(Temperature === '' || Temperature.toString().length === 0 )
  {
    return ['Please fill Temperature','errTemperature'];
  }

  else
  {
    return true;
  }
}

export default DonorVisitValidations;