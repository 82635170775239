import React, { useEffect, useRef, useState } from 'react'
import TosterUnderProcess from '../../../Component/TosterUnderProcess'
import DropdownWithSearch from '../../../Component/DropdownWithSearch'
import Heading from '../../../Component/Heading'
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import { t } from 'i18next';
import Toster from '../../../Component/Toster';
import GetOutputTypeList from '../../../Clinical/API/Output/GetOutputTypeList';
import GetUnitList from '../../../Clinical/API/Output/GetUnitList';
import SavePatientOutput from '../../../Clinical/API/Output/SavePatientOutput';

export default function RestraintOutput() {

    let [isUpdateBtnShow, setIsUpdateBtnShow] = useState(false);
    let [showLoder, setShowLoder] = useState(0);
    let [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
    let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [showToster, setShowToster] = useState(0);
    let [tosterMessage, setTosterMessage] = useState("");
    let [tosterValue, setTosterValue] = useState(0);
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [quantity, setQuantity] = useState('');
    let [date, setDate] = useState();
    let [time, setTime] = useState('');
    let [outputType, setOutputType] = useState(0);
    let [unit, setUnit] = useState(0);
    let [patientOutputList, setPatientOutputList] = useState([]);
    let [outputTypeList, setOutputTypeList] = useState([]);
    let [unitList, setUnitList] = useState([]);
    let [rowID, setRowID] = useState(0);
    let [pmID, setpmID] = useState(0);
    let [clearDropdown, setClearDropdown] = useState(0);
    let [handlerEditUnit, setHandlerEditUnit] = useState('');
    let [handlerEditOutputType, setHandlerEditOutputType] = useState('');
    const getPmID = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].pmId;

    console.log("getPmID", getPmID)

    let handlerChange = (e) => {
        setHandlerEditUnit('');
        setHandlerEditOutputType('');
        clearErrorMsg();
        let name = e.target.name;
        let value = e.target.value;
        if (name === "quantity" && value.length > 4) {
            value = value.slice(0, 4);
            document.getElementById('errQuatity').innerHTML = "Maximum length should be up to 4 digits";
            document.getElementById('errQuatity').style.display = "block";
        } else {
            document.getElementById('errQuatity').style.display = "none";
        }
        if (name === "date") {
            setDate(value);
        }
        else if (name === "time") {
            setTime(value);
        }
        else if (name === "outputType") {
            setOutputType(value);
        }
        else if (name === "quantity") {
            setQuantity(value);
        }
        else if (name === "unit") {
            setUnit(value);
        }
    }

    let getOutputTypeList = async () => {
        let response = await GetOutputTypeList();
        console.log('res->', response)
        if (response.status === 1) {
            setOutputTypeList(response.responseValue)
        }
    }
    let getUnitList = async () => {
        let response = await GetUnitList();
        console.log('res unit->', response)
        if (response.status === 1) {
            setUnitList(response.responseValue)
        }
    }

    let handlerSave = async () => {
        clearErrorMsg();
        if (date === '' || date === null || date === undefined) {
            document.getElementById('errDate').innerHTML = "Please Fill Date";
            document.getElementById('errDate').style.display = "block";
            return false;
        }
        else if (time === '' || time === null || time === undefined) {
            document.getElementById('errTime').innerHTML = "Please Fill Time";
            document.getElementById('errTime').style.display = "block";
            return false;
        }
        else if (outputType === 0 || outputType === null || outputType === undefined) {
            document.getElementById('errOutputType').innerHTML = "Please Select Output Type";
            document.getElementById('errOutputType').style.display = "block";
            return false;
        }
        else if (quantity === '' || quantity === 0 || quantity === null || quantity === undefined) {
            document.getElementById('errQuatity').innerHTML = "Please Fill Quantity";
            document.getElementById('errQuatity').style.display = "block";
            return false;
        }
        else if (unit === 0 || unit === null || unit === undefined) {
            document.getElementById('errUnit').innerHTML = "Please Select Unit";
            document.getElementById('errUnit').style.display = "block";
            return false;
        }
        else {
            setShowUnderProcess(1);
            let getPmID = "";
            const getActiveUhid = JSON.parse(sessionStorage.getItem("IPDactivePatient")).Uhid;
            const getpatientList = JSON.parse(sessionStorage.getItem("IPDpatientList"));

            getpatientList.map((val, i) => {
                if (val.uhId === getActiveUhid) {
                    getPmID = val.pmId;
                    return;
                }
            });
            if (getPmID !== "" || getPmID !== null || getPmID !== undefined) {
                var obj = {
                    id: 0,
                    pmID: getPmID,
                    outputTypeID: outputType,
                    quantity: parseInt(quantity),
                    unitID: unit,
                    outputDate: date + ' ' + time,
                    userID: userID,
                    clientId: window.clientId
                }
                console.log('obj', obj)
            }
            let response = await SavePatientOutput(obj);
            console.log('response', response)
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Patient Output Saved Successfully");
                setTimeout(() => {
                    setShowToster(0);
                    handlerClear(1);
                }, 2000)
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage(response.responseValue)
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }
        }
    }

    let getCurrentDate = () => {
        var today = new Date();
        const month = (today.getMonth() + 1).toString().length === 1 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1);
        const day = today.getDate().toString().length === 1 ? '0' + today.getDate() : today.getDate();
        const currentDate = today.getFullYear() + '-' + month + '-' + day;
        return currentDate;
    }
    let getCurrentTime = () => {
        var today = new Date();
        const minutes = today.getMinutes().toString().length === 1 ? '0' + today.getMinutes() : today.getMinutes();
        const currentTime = today.getHours() + ':' + minutes;
        console.log('currentTime', currentTime)
        return currentTime;
    }
    let handlerClear = async (val) => {
        clearErrorMsg();
        setIsUpdateBtnShow(false);
        setQuantity('');
        setDate(getCurrentDate());
        setTime(getCurrentTime());
        setUnit(0);
        setOutputType(0);
        setClearDropdown(val);
        setHandlerEditUnit('');
        setHandlerEditOutputType('');
    }
    let clearErrorMsg = () => {
        document.getElementById('errDate').style.display = "none";
        document.getElementById('errTime').style.display = "none";
        document.getElementById('errOutputType').style.display = "none";
        document.getElementById('errQuatity').style.display = "none";
        document.getElementById('errUnit').style.display = "none";
    }
    useEffect(() => {
        getOutputTypeList();
        getUnitList();
        setDate(getCurrentDate());
        setTime(getCurrentTime());
    }, [])

    return (
        <>
            {/* {/ -----------------------Start Forgot Password Modal Popup--------------------- /} */}

            <div className='modal fade' id="RestraintOutputModal" aria-labelledby="RestraintOutputModalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modalDelete w-100" style={{ margin: '3% auto' }}>
                    <div className="modal-content position-relative">


                        {/* <button type="button" className="btn-close_ position-absolute top-0 start-100 translate-middle bg-dark bg-gradient text-white border-0 rounded-circle_ rounded" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg"></i></button> */}
                        <div className="modal-header modalheading">
                            <h5 className="modal-title">Patient Output</h5>
                            <i className="fa fa-times" data-bs-dismiss="modal" aria-label="Close"></i>
                        </div>

                        <div className="modal-body text-center1">
                            <div className='med-box1'>
                                <div className='inner-content1'>
                                    <div className='icuPhyInput mb-3  commnet-txt'>
                                        <label htmlFor="date" className="form-label">Date<span className="icustarMandatory">*</span></label>
                                        <input type='date' className="form-control form-control-sm" id='date' name='date' value={date} onChange={handlerChange} />
                                        <small id="errDate" className="form-text text-danger" style={{ display: 'none' }}></small>
                                    </div>
                                    <div className='icuPhyInput mb-3  commnet-txt'>
                                        <label htmlFor="time" className="form-label">{t("Time")}<span className="icustarMandatory">*</span></label>
                                        <input type='time' className="form-control form-control-sm" id='time' name='time' value={time} onChange={handlerChange} />
                                        <small id="errTime" className="form-text text-danger" style={{ display: 'none' }}></small>
                                    </div>
                                    <div className='icuPhyInput mb-3  commnet-txt'>
                                        <label htmlFor="ddlOutputType" className="form-label">{t("Output Type")}<span className="icustarMandatory">*</span></label>
                                        <DropdownWithSearch defaulNname={t("Select Output Type")} name="outputType" list={outputTypeList} valueName="id" displayName="remark" editdata={handlerEditOutputType} getvalue={handlerChange} clear={clearDropdown} clearFun={handlerClear} />
                                        <small id="errOutputType" className="form-text text-danger" style={{ display: 'none' }}></small>
                                    </div>
                                    <div className='icuPhyInput mb-3  commnet-txt'>
                                        <label htmlFor="time" className="form-label">{t("Quantity")}<span className="icustarMandatory">*</span></label>
                                        <input type="number" className="form-control form-control-sm" id="quantity" placeholder={t("Enter Quantity")} name='quantity' value={quantity} onChange={handlerChange} />
                                        <small id="errQuatity" className="form-text text-danger" style={{ display: 'none' }}></small>
                                    </div>
                                    <div className='icuPhyInput mb-3  commnet-txt'>
                                        <label htmlFor="ddlUnit" className="form-label">{t("Unit")}<span className="icustarMandatory">*</span></label>
                                        <DropdownWithSearch defaulNname={t("Select Unit")} name="unit" list={unitList} valueName="id" displayName="unitName" editdata={handlerEditUnit} getvalue={handlerChange} clear={clearDropdown} clearFun={handlerClear} />
                                        <small id="errUnit" className="form-text text-danger" style={{ display: 'none' }}></small>
                                    </div>
                                    <div className="relative mt-2 icubtns">
                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />

                                                    : <div>

                                                        <>
                                                            <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-2" onClick={handlerSave}><img src={saveButtonIcon} className='icnn' alt='' />{t("Save")}</button>
                                                            <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={() => { handlerClear(1) }}><img src={clearIcon} className='icnn' alt='' />{t("Clear")}</button>
                                                        </>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>


                        </div>


                    </div>
                </div>
            </div>
            {/* {/ -----------------------End Forgot Password Modal Popup--------------------- /} */}


        </>
    )
}
