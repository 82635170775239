async function PostTestMaster(TestName = "", CategoryId = "",subCategoryId = "",  SampleId = "", ItemId = "", InstructionId = "") {


    let url = window.LabServicebaseUrl + `/api/AllMasters/InsertTestName?TestName=${TestName}&CategoryId=${CategoryId}&SampleId=${SampleId}&ItemId=${ItemId}&InstructionId=${InstructionId}&UserId=${window.userId}&subCategoryId=${subCategoryId}&clientId=${window.clientId}`;
        let head = {
      'Content-Type': 'application/JSON',
      accept: '*/*',    
      
    }
    let response =
      await fetch(url, {
        method: 'POST',
        headers: head,
        // body: JSON.stringify(data)
      })
        .then((res) => res.json())
        // .then(data)
      
  
    return response;
  }
  export default PostTestMaster;