import React, { useEffect, useState } from "react";
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Toster from '../../../Component/Toster';
import Loder from '../../../Component/Loader';
import Select from 'react-select';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import update from '../../../assets/images/icons/update.svg';
import updatee from '../../../assets/images/icons/updatee.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import GetUnitList from "../../Api/EnvironmentParameterMaster/GetUnitList";
import GetAllEnvironmentParameter from "../../Api/EnvironmentParameterMaster/GetAllEnvironmentParameter";
import PostEnvironmentName from "../../Api/EnvironmentParameterMaster/PostEnvironmentName";
import DeleteEnvironmentParameter from "../../Api/EnvironmentParameterMaster/DeleteEnvironmentParameter";
import UpdateEnvironmentParameter from "../../Api/EnvironmentParameterMaster/UpdateEnvironmentParameter";





 export default function EnvironmentParameterMaster() {


  const [showUnderProcess, setShowUnderProcess] = useState(0);
  const [showToster, setShowToster] = useState(0);
  const [tosterMessage, setTosterMessage] = useState("");
  const [tosterValue, setTosterValue] = useState(0);
  const [showLoder, setShowLoder] = useState(0);
  const [rowID, setRowID] = useState(0);
  const [isUpdateBtnShow, setIsUpdateBtnShow] = useState(false);
  const [EnvironmentName, setEnvironmentName] = useState('');
  const [EnviromentParameterTable, setEnviromentParameterTable] = useState([])
  const [newlyAddedRowIndex, setNewlyAddedRowIndex] = useState(null);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [isNewRowAdded, setIsNewRowAdded] = useState(false);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [unitList, setUnitList] = useState([]);
  const [SelectedUnit, setSelectedUnit] = useState(null)

  let [userID, setUserID] = useState(JSON.parse(sessionStorage.getItem("LoginData")).userId);
  const clientID  = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
  



 


  const handleOnChange = (e) => {

    setIsNewRowAdded(false)
    setNewlyAddedRowIndex(null);
    document.getElementById('errEnvironmentName').style.display = 'none';
  
    const { name, value } = e.target;
    if (name === 'EnvironmentName') {
      setEnvironmentName(value);
    }

  };

  const handleSelectChange = (selectedOption, errorElementId, setSelectedFunction) => {
    document.getElementById(errorElementId).style.display = 'none';
    setSelectedFunction(selectedOption);
};

  const getUnitList = async () => {
    const data = await GetUnitList();
    if (data.status === 1) {
        setUnitList(data.responseValue.map(Unit => ({
            value: Unit.id,
            label: Unit.unitName
        })));
    }
    else {
        setShowLoder(0);
    }
};

const GetEnvironmentParameter = async()=>{
let environmentParameter = await GetAllEnvironmentParameter()
if(environmentParameter.status === 1){
  setEnviromentParameterTable(environmentParameter.responseValue)
  console.log("dataEnvironment" , environmentParameter.responseValue)
}
}


  const handleOnSave = async () => {
  

    if (EnvironmentName.trim() === '' || EnvironmentName === undefined ) {
      document.getElementById('errEnvironmentName').innerHTML = 'Please Enter Environment Name';
      document.getElementById('errEnvironmentName').style.display = 'block';
      return;
    }
    if (SelectedUnit === null) {
      document.getElementById('errddlUnit').innerHTML = 'Please Select Unit';
      document.getElementById('errddlUnit').style.display = 'block';
      return;
    }


    const obj = {
        parameterName: EnvironmentName,
        userId: userID,
        unitID: SelectedUnit.value
    }
  
   

    let data = await PostEnvironmentName(obj);
    if (data.status === 1) {
      setShowUnderProcess(0);
      setTosterValue(0);
      setShowToster(1);
      setTosterMessage("Data Saved Successfully!");
      GetEnvironmentParameter()
      handleClear()
      setIsNewRowAdded(true);
      handleClear();
      setTimeout(() => {
        setShowToster(0);
     setIsNewRowAdded(null)

      }, 2000);
    } else {
      setShowUnderProcess(0);
      setShowToster(1);
      setTosterValue(1);
      setTosterMessage("Already Exist!");
      setTosterMessage(data.responseValue);
      setTimeout(() => {
        setShowToster(0);
      }, 2000);
    }
  };

  const handleClear = () => {
    document.getElementById('errEnvironmentName').style.display = 'none';
    document.getElementById('errddlUnit').style.display = 'none';
    setEnvironmentName('')
    setSelectedUnit(null)

  };


  const edit = (data , index) => {
    setRowID(data.id)
    setEnvironmentName(data.parameterName)
    setSelectedUnit({
      value: data.unitID,
      
    })
    setIsUpdateBtnShow(true)
    setNewlyAddedRowIndex(index)
    console.log(data.unitID)
  }

  const handleUpdate = async () => {

    if (EnvironmentName.trim() === '' || EnvironmentName === undefined ) {
      document.getElementById('errEnvironmentName').innerHTML = 'Please Enter Environment Name';
      document.getElementById('errEnvironmentName').style.display = 'block';
      return;
    }
    if (SelectedUnit === null) {
      document.getElementById('errddlUnit').innerHTML = 'Please Select Unit';
      document.getElementById('errddlUnit').style.display = 'block';
      return;
    }



    const obj = {
      id: rowID,
       parameterName: EnvironmentName,
        userId: userID,
        unitID: SelectedUnit.value
    };
    const data = await UpdateEnvironmentParameter(obj);
    if (data.status === 1) {
      setShowUnderProcess(0);
      setTosterValue(0);
      setShowToster(1);
      setTosterMessage('Data Updated Successfully!');
      GetEnvironmentParameter()
      handleClear();
      setIsNewRowAdded(false);
      setTimeout(() => {
        setShowToster(0);
        setNewlyAddedRowIndex(null);
      }, 2000);

      setIsUpdateBtnShow(false);
    } else {

      setShowUnderProcess(0);
      setShowToster(1);
      setTosterMessage(data.responseValue);
      setTosterValue(1);
      setTimeout(() => {
        setShowToster(0);
      }, 2000);
    }
  };
  const handleCancel = () => {
    handleClear()
    setIsUpdateBtnShow(false);
    setEditRowIndex(null)
    setNewlyAddedRowIndex(null);

  };

  const deleteRow = async () => {
    setShowUnderProcess(1);
     let ID = rowID
   
    let data = await DeleteEnvironmentParameter(ID);
    if (data.status === 1) {
      setShowUnderProcess(0);
      setTosterValue(0);
      setShowToster(1);
      setTosterMessage("Data Deleted Successfully!!");
      GetEnvironmentParameter()
      setIsUpdateBtnShow(false)
      setNewlyAddedRowIndex(false)
     
      handleClear()
      setTimeout(() => {
        setShowToster(0);


      }, 1000)
    }
    else {
      setShowUnderProcess(0)
      setShowToster(0)
      setTosterMessage(data.responseValue)
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0);
      }, 2000)
    }
  };




  useEffect(() => {
    GetEnvironmentParameter();
    getUnitList()
   
  }, []);


  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="med-box">
               
                <div className="inner-content">
                <div className='fieldsett-in col-md-12'>
                <div className="fieldsett">
                    <span className='fieldse'>Environment Parameter Master</span>
                  <div className='row'>
              
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Parameter Name<span className="starMandatory">*</span></label>
                          <input value={EnvironmentName} id="EnvironmentName" type="text" className="form-control form-control-sm" name="EnvironmentName" placeholder= "Enter Parameter Name" onChange={handleOnChange} />
                          <small id="errEnvironmentName" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                            <label htmlFor="ddlitemmaster" className="form-label">Unit<span className="starMandatory">*</span></label>
                              <Select value={SelectedUnit} placeholder="Choose Unit" options={unitList} className="create-select"  id="itemcategory" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, "errddlUnit",setSelectedUnit)} />
                             <small id="errddlUnit" className="form-text text-danger" style={{ display: 'none' }}></small>
                             </div>

                        <div className="col-xxl-4 col-xl-3 col-lg-4 col-md-6 mb-3 relative">
                          <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>

                          {showUnderProcess === 1 ? <><TosterUnderProcess />  </> :
                            showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} />
                              :
                              <div>
                                {isUpdateBtnShow !== true ? <>
                                  <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleOnSave}><img src={saveButtonIcon} className='icnn' alt="" />Save</button>
                                  <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt="" />Clear</button>
                                </> :
                                  <>
                                    <button type="button" className="btn btn-save btn-sm mb-1 me-1 " onClick={handleUpdate} > <img src={updatee} className='icnn' alt=""/>Update</button>
                                    <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleCancel} >Cancel</button>
                                  </>
                                }
                              </div>
                          }
                        </div>
                      </div>
                    </div>
                    </div>
   </div>

              </div>
            </div>


            {/* table is made using getAllItemMaster API and mapped the data   */}


            <div className="col-12 mt-3">
              <div className="med-table-section" style={{ "height": "80vh" }}>
                <table className="med-table border_ striped">
                  <thead style={{ zIndex: '0' }}>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>Environment Parameter Name</th>
                      <th>Unit</th>

                      <th style={{ "width": "10%" }} className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {EnviromentParameterTable && EnviromentParameterTable.map((data, index) => {
                      const isNewRow = newlyAddedRowIndex === index;
                      const isEditing = index === editRowIndex;
                      return (
                        <tr className={index === EnviromentParameterTable.length - 1 && isNewRowAdded ? 'new-row' : '' || isNewRow ? 'new-row' : ''} key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td>{data.parameterName}</td>
                          <td>{data.unitName}</td>
                          
                          
      

                          <td>
                            <div className="action-button">
                              <div
                                data-bs-toggle="tooltip"
                                data-bs-title="Edit Row"
                                data-bs-placement="bottom"

                              >
                                <img src={editBtnIcon} className={isEditing ? 'edited-row' : ''} alt='' onClick={() => { edit(data, index) }} />
                              </div>
                              <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowID(data.id); }} />
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* -----------------------Start Delete Modal Popup-------------------    */}

        <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
          <div className="modal-dialog modalDelete">
            <div className="modal-content">
              <div className="modal-body modelbdy text-center">
                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                <div className='popDeleteTitle mt-3'>Delete?</div>
                <div className='popDeleteContent'>Are you sure you want to delete?</div>
              </div>
              <div className="modal-footer1 text-center">

                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn-delete popBtnDelete" data-bs-dismiss="modal" onClick={deleteRow} >Delete</button>
              </div>
            </div>
          </div>
        </div>
        {/* -----------------------End Delete Modal Popup---------------------  */}
        {
          showLoder === 1 ? <Loder val={showLoder} /> : ""
        }
      </section>

    </>

  )
}


