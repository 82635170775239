import React, { useState, useEffect } from 'react'
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import Loader from '../../../Component/Loader'
import SuccessToster from '../../../Component/SuccessToster'
import AlertToster from '../../../Component/AlertToster'
import Heading from '../../../Component/Heading';
import BoxContainer from '../../../Component/BoxContainer';
import DropdownWithSearch from '../../../Component/DropdownWithSearch';
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Toster from '../../../Component/Toster';
import GetDesignationList from '../Api/ShiftDesignationMapping/GetDesignationList';
import GetHRDutyAssign from '../Api/HRDutyAssign/GetHRDutyAssign';
import TableContainer from '../../../Component/TableContainer';
import DeleteHRDutyAssign from '../Api/HRDutyAssign/DeleteHRDutyAssign';
import PutHRDutyAssign from '../Api/HRDutyAssign/PutHRDutyAssign';
import PostHRDutyAssign from '../Api/HRDutyAssign/PostHRDutyAssign';
import GetTaskCategoryList from '../Api/TaskCategoryMaster/GetTaskCategoryList';
import { t } from 'i18next';

export default function HRDutyAssign() {
    let [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
    let [clientID, setClientID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientId);
    let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [showToster, setShowToster] = useState(0);
    let [tosterMessage, setTosterMessage] = useState("");
    let [tosterValue, setTosterValue] = useState(0);
    let [showLoder, setShowLoder] = useState(0);
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [hrDutyList, setHrDutyList] = useState([]);
    let [designationList, setDesignationList] = useState([]);
    let [taskCategoryList, setTaskCategoryList] = useState([]);
    let [updateBool, setUpdateBool] = useState(0);
    let [rowID, setRowId] = useState(0);
    let [editDesignationDdl, setEditDesignationDdl] = useState('');
    let [editTaskCategory, setEditTaskCategory] = useState('');
    let [clearDropdown, setClearDropdown] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    let [sendForm, setSendForm] = useState({
        "userId": window.userId,
        "clientId": window.clientId,
        designationId: '',
        categoryId: '',
        task: ''
    })
    // Function to handle changes in the search term
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };
    //HandleChange
    let handleChange = async (e) => {
        clearValidationErrMessage();
        setEditDesignationDdl("");
        setEditTaskCategory("");
        const { name, value } = e.target;
        setSendForm((prevData) => ({ ...prevData, [name]: value }));

    }

    //HAndle Save 
    const handlerSave = async () => {
        console.log('sendForm',sendForm)
      
        if (sendForm.designationId === '' || sendForm.designationId === null || sendForm.designationId === undefined) {
            document.getElementById('errDesignation').innerHTML = "Designation is Required";
            document.getElementById('errDesignation').style.display = "block";
        }
        else if (sendForm.categoryId === '' || sendForm.categoryId === null || sendForm.categoryId === undefined) {
            document.getElementById('errcategory').innerHTML = "Category is Required";
            document.getElementById('errcategory').style.display = "block";
        }
        else if (sendForm.task === '' || sendForm.task === null || sendForm.task === undefined) {
            document.getElementById('errTask').innerHTML = "Task is Required";
            document.getElementById('errTask').style.display = "block";
        }
        else {
            setShowUnderProcess(1);
            var obj = {
                "designationId": sendForm.designationId,
                "categoryId": sendForm.categoryId,
                "task": sendForm.task,
                "userId": window.userId,
                "clientId": window.clientId,

            }
            console.log('obj', obj)
            const response = await PostHRDutyAssign(obj);
            console.log('save Response', response);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Saved Successfully..");
                setTimeout(() => {
                    setShowToster(0);
                    handleClear(1);
                    getHRDuty();

                }, 1500)
            }
            else {
                setShowUnderProcess(0);
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTimeout(() => {
                    setShowToster(0);
                }, 1500)
            }
        }
    }
    //GetDesignation
    const getDesignationList = async () => {
        const response = await GetDesignationList();
        console.log('response', response)
        if (response.status === 1) {
            setDesignationList(response.responseValue);
        }
        else {
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }
    //GetHrDuty Assign
    const getHRDuty = async () => {
        setShowLoder(1);
        const response = await GetHRDutyAssign(clientID);
        console.log('rrr', response)
        if (response.status === 1) {
            setHrDutyList(response.responseValue);
            setShowLoder(0)
        }
        else {
            setShowLoder(0);
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }

    //Get Task Category
    let getTaskCategoryList = async () => {
        let response = await GetTaskCategoryList(clientID);
        if (response.status === 1) {
            setTaskCategoryList(response.responseValue);
            setShowLoder(0)
        }
        else {
            setShowLoder(0);
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }

    //Handle Update Edit Button
    let handleUpdate = async (id, designationId, categoryId, task, designationName, categoryName) => {
        setUpdateBool(1);
        setSendForm(sendForm => ({
            ...sendForm,
            "id": id,
            "designationId": designationId,
            "categoryId": categoryId,
            "task": task
        }))
        setEditDesignationDdl(designationName)
        setEditTaskCategory(categoryName)
        document.getElementById("task").value = task;
    }


    const handlerUpdate = async () => {
        if (sendForm.designationId === '' || sendForm.designationId === null || sendForm.designationId === undefined) {
            document.getElementById('errDesignation').innerHTML = "Designation is Required";
            document.getElementById('errDesignation').style.display = "block";
        }
        else if (sendForm.categoryId === '' || sendForm.categoryId === null || sendForm.categoryId === undefined) {
            document.getElementById('errcategory').innerHTML = "Category is Required";
            document.getElementById('errcategory').style.display = "block";
        }
        else if (sendForm.task === '' || sendForm.task === null || sendForm.task === undefined) {
            document.getElementById('errTask').innerHTML = "Task is Required";
            document.getElementById('errTask').style.display = "block";
        }
        else {
            setShowUnderProcess(1);
            var obj = {

                "id": sendForm.id,
                "designationId": sendForm.designationId,
                "categoryId": sendForm.categoryId,
                "task": sendForm.task,
                "userId": window.userId,
                "clientId": window.clientId,
            }

            const response = await PutHRDutyAssign(obj);
            console.log('save Response', response);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Updated Successfully..");
                setTimeout(() => {
                    setShowToster(0);
                    handleClear(1);
                    getHRDuty();

                }, 1500)
            }
            else {
                setShowUnderProcess(0);
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTimeout(() => {
                    setShowToster(0);
                }, 1500)
            }
        }
    }
    //Handle Delete
    const handleDelete = async () => {
        var obj = {
            rowID: rowID,
            userID: userID
        }
        setShowLoder(1);
        const response = await DeleteHRDutyAssign(obj);
        if (response.status === 1) {
            setShowLoder(0)
            setisShowToaster(1);
            setShowSuccessMsg("Deleted Successfully")
            setTimeout(() => {
                setisShowToaster(0);
                getHRDuty();
            }, 1500)
            handleClear(1);
        }
        else {
            setShowLoder(0);
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }

    const clearValidationErrMessage = () => {
        document.getElementById('errDesignation').style.display= "none";
        document.getElementById('errcategory').style.display= "none" ;
        document.getElementById('errTask').style.display= "none" ;

       
    }

    //Handel Clear
    const handleClear = (value) => {
        clearValidationErrMessage();
        setUpdateBool(0);
        setClearDropdown(value);
        setEditDesignationDdl(0);
        setEditTaskCategory(0);
        setSendForm({
            "userId": window.userId,
            "clientId": window.clientId,
            designationId: '',
            categoryId: '',
            task: ''
        })
        document.getElementById("task").value = "";
    }

    useEffect(() => {
        getDesignationList();
        getHRDuty();
        getTaskCategoryList();
    }, []);

    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <Heading text="HR Duty Assign" />
                            <BoxContainer>
                                <div className="col-2 mb-2 me-2">
                                    <label htmlFor="designationId" className="form-label">Designation<span className="starMandatory">*</span></label>
                                    {designationList && <DropdownWithSearch defaulNname="Select Designation" name="designationId" list={designationList} valueName="id" displayName="designationName" editdata={editDesignationDdl} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                    <small id="errDesignation" className="invalid-feedback" style={{ display: 'none' }}></small>

                                </div>

                                <div className="col-2 mb-2 me-2">
                                    <label htmlFor="categoryId" className="form-label">Task Category<span className="starMandatory">*</span></label>
                                    {taskCategoryList && <DropdownWithSearch defaulNname="Select Task Category" name="categoryId" list={taskCategoryList} valueName="id" displayName="categoryName" editdata={editTaskCategory} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                    <small id="errcategory" className="invalid-feedback" style={{ display: 'none' }}></small>
                                </div>

                                <div className="col-2 mb-2 me-2">
                                    <label htmlFor="task" className="form-label">Task<span className="starMandatory">*</span></label>
                                    <input type="text" className="form-control form-control-sm" name="task" id="task" placeholder='Enter Task' value={sendForm.url} onChange={handleChange} />
                                    <small id="errTask" className="invalid-feedback" style={{ display: 'none' }}></small>
                                </div>



                                <div className="mb-2 relative">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                    <div>
                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />

                                                    : <div>
                                                        {updateBool === 0 ?
                                                            <>
                                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handlerSave}><img src={saveButtonIcon} className='icnn' alt='' />Save</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { handleClear(1) }}><img src={clearIcon} className='icnn' alt='' />Clear</button>
                                                            </>
                                                            :
                                                            <>
                                                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={handlerUpdate}>Update</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { handleClear(1) }}>Cancel</button>
                                                            </>
                                                        }
                                                    </div>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </BoxContainer>
                        </div>
                        <div className="col-12 mt-2">
                            <div className='handlser'>
                                <Heading text="HR Duty Assign List" />
                                <div style={{ position: 'relative' }}>
                                    <input type="text" className='form-control form-control-sm' placeholder={t("Search")} value={searchTerm} onChange={handleSearch} />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
                            <div className="med-table-section" style={{ "height": "75vh" }}>
                                <TableContainer>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>#</th>
                                            <th>Designation</th>
                                            <th>Task Category</th>
                                            <th>Task</th>
                                            <th style={{ "width": "10%" }} className="text-center">Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {hrDutyList && hrDutyList.filter((val) => `${val.designationName} ${val.categoryDetails.categoryName} ${val.task}`.toLowerCase().includes(searchTerm.toLowerCase())).map((val, ind) => {
                                            return (
                                                <tr key={val.id}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td>{val.designationName}</td>
                                                    <td>{val.categoryDetails ? val.categoryDetails.categoryName : ''}</td>
                                                    <td>{val.task}</td>
                                                    <td>
                                                        <div className="action-button">
                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom" onClick={() => {
                                                                handleUpdate(
                                                                    val.id,
                                                                    val.designationId,
                                                                    val.categoryDetails.id,
                                                                    val.task,
                                                                    val.designationName,
                                                                    val.categoryDetails ? val.categoryDetails.categoryName : ''
                                                                );
                                                            }}><img src={editBtnIcon} className='' alt='' /></div>
                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowId(val.id) }} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}


                                    </tbody>
                                </TableContainer>
                                {/* -----------------------Start Delete Modal Popup-------------------   */}

                                {/*  <!-- Modal -->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">

                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'>Delete?</div>
                                                <div className='popDeleteContent'>Are you sure want to delete?</div>
                                            </div>
                                            <div className="modal-footer1 text-center">

                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={handleDelete} data-bs-dismiss="modal">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

                            </div>
                        </div>
                    </div>
                </div>
                {
                    showLoder === 1 ? <Loader val={showLoder} /> : ""
                }
                {/* Toaster */}
                {
                    isShowToaster === 1 ?
                        <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                }

                {
                    showAlertToster === 1 ?
                        <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                }
            </section>
        </>
    )
}
