import React, { useEffect, useState } from 'react'
import i18n, { t } from "i18next";
import TableContainer from '../../../Component/TableContainer';
import Heading from '../../../Component/Heading';
import DropdownWithSearch from '../../../Component/DropdownWithSearch'
import uploadimage from '../../../assets/images/dmsimg/uploadimg.svg'
import dropdownwhite from '../../../assets/images/dmsimg/dorpdownwhite.svg'
import dob from '../../../BloodBank/images/dob.svg'
import arrowimage from '../../../assets/images/dmsimg/arrowgray.svg'
import arrowimagered from '../../../assets/images/dmsimg/arrowred.svg'
import totaledemandimg from '../../../assets/images/dmsimg/totaldemands.svg'
import underdemandpic from '../../../assets/images/dmsimg/underdemands.svg'
import totaleitemsimg from '../../../assets/images/dmsimg/demandeditems.svg'
import indentgenerateimg from '../../../assets/images/dmsimg/generateddemands.svg'
import rejectedpic from '../../../assets/images/dmsimg/rejecteddemands.svg'
import demandarrowgrin from '../../../assets/images/dmsimg/arrowgreen.svg'


export default function ItemDashboard() {
    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="med-box" style={{ boxShadow: 'inherit' }}>
                        <div className="inner-content">
                            <div className="row">
                                <div className="col-12">
                                    <div className='listdetailsct' style={{ boxShadow: 'none' }}>
                                        <div className='listdetailsct-in'>
                                            <div className='listd-in'>
                                                <div className="title ddfont mt-2 pe-0 ps-0 " >{t("Welcome , Sabi")}</div>
                                            </div>
                                        </div>
                                        <div className='listdetailsct-in'>
                                            <div className='listd-in'>
                                                <img src={dob} className='trnsfrm' alt='' />
                                                <select className="form-select form-select-sm" aria-label=".form-select-sm example" style={{ padding: '10px 30px' }}>
                                                    <option selected>This Month</option>
                                                </select>
                                            </div>
                                            <div className='listd-in'>
                                                <select name='' id="" className="form-select form-select-sm" aria-label=".form-select-sm example" style={{ background: '#2A62C8', color: '#fff', margin: '0 10px', padding: '10px 30px' }}>
                                                    <option value="0">Download Report</option>
                                                    <option >ss</option>
                                                    <option >ss</option>
                                                </select>
                                                <img src={dropdownwhite} className='trnsfrm2' alt='' />
                                            </div>
                                        </div>
                                    </div>
                                    {/* ...........dashborad boxes............ */}
                                    <div className='dashboradboxes'>
                                        <div className="box-contntt" style={{ background: ' #F9FAFC' }}>
                                            <div className="totaldenadsboxes pb-1">
                                                <div className='fullboxdashboard'>
                                                    <img src={totaledemandimg} className='' alt='' />
                                                </div>
                                                <div className='fullboxdashboard'>
                                                    <div className='txtnumbclr' > 25 </div>
                                                    <div className='textclr1' style={{ color: 'rgba(84, 103, 136, 1)' }}> Total Demands <img src={arrowimage} className='icnn' alt='' /></div>
                                                </div>
                                            </div>
                                            <hr className="mt-0 mb-0 pt-1 pb-1"/>
                                            <div className='dashboardfooter mt-0 mb-0 pt-0 pb-0'>    
                                                 <img src={arrowimagered} className='' alt='' /><span className='contentredclr'> 12.5 % </span> <span className='contentgrayclr'> vs 65 last month</span>
                                            </div>
                                        </div>
                                        <div className="box-contntt" style={{ background: ' rgba(0, 176, 102, 0.05)' }}>
                                            <div className="totaldenadsboxes pb-1">
                                                <div className='fullboxdashboard'>
                                                    <img src={underdemandpic} className=' ' alt='' /> </div>
                                                <div className='fullboxdashboard'>
                                                    <div className='txtnumbclr'> 04 </div>
                                                    <div className='textclr1' style={{ color: 'rgba(57, 136, 111, 1)' }}> Under Demands <img src={arrowimage} className='icnn' alt='' /></div>
                                                </div>
                                            </div>
                                            <hr className="mt-0 mb-0 pt-1 pb-1"/>
                                            <div className='dashboardfooter mt-0 mb-0 pt-0 pb-0'>                                                  
                                                <span className='contentredclr' style={{ color: 'rgba(57, 136, 111, 1)' }}>2</span> <span className='contentgrayclr'> Approved Demands</span>                                                
                                            </div>
                                        </div>

                                        <div className="box-contntt" style={{ background: 'rgba(255, 155, 4, 0.05)' }}>
                                            <div className="totaldenadsboxes pb-1">
                                                <div className='fullboxdashboard'>
                                                    <img src={totaleitemsimg} className='dashboardimg ' alt='' /> </div>
                                                <div className='fullboxdashboard'>
                                                    <div className='txtnumbclr' > 25 </div>
                                                    <div className='textclr1' style={{ color: 'rgba(255, 155, 4, 1)' }}>Total Demanded Items <img src={arrowimage} className='icnn' alt='' /></div>
                                                </div>
                                            </div>
                                            <hr className="mt-0 mb-0 pt-1 pb-1"/>
                                            <div className='dashboardfooter mt-0 mb-0 pt-0 pb-0'>                                                  
                                               <img src={demandarrowgrin} className='' alt='' /> <span className='contentredclr' style={{ color: 'rgba(57, 136, 111, 1)' }}> 12.5% </span> <span className='contentgrayclr'> vs 65 last month</span>
                                               
                                            </div>
                                        </div>

                                        <div className="box-contntt" style={{ background: ' rgba(42, 98, 200, 0.05)' }}>
                                            <div className="totaldenadsboxes pb-1">
                                                <div className='fullboxdashboard'>
                                                    <img src={indentgenerateimg} className='dashboardimg ' alt='' /> </div>
                                                <div className='fullboxdashboard'>
                                                    <div className='txtnumbclr' > 25 </div>
                                                    <div className='textclr1' style={{ color: 'rgba(42, 98, 200, 1)' }}> Indent Generated <img src={arrowimage} className='icnn' alt='' /></div>
                                                </div>
                                            </div>
                                            <hr className="mt-0 mb-0 pt-1 pb-1"/>
                                            <div className='dashboardfooter mt-0 mb-0 pt-0 pb-0'>    
                                               <span className='contentredclr' style={{ color: 'rgba(57, 136, 111, 1)' }}>2</span> <span className='contentgrayclr'> Under Verification at Store Level</span>
                                            </div>
                                        </div>
                                        <div className="box-contntt" style={{ background: ' rgba(255, 4, 4, 0.05)' }}>
                                            <div className="totaldenadsboxes pb-1">
                                                <div className='fullboxdashboard'>
                                                    <img src={rejectedpic} className='dashboardimg ' alt='' /> </div>
                                                <div className='fullboxdashboard'>
                                                    <div className='txtnumbclr'> 25 </div>
                                                    <div className='textclr1' style={{ color: 'rgba(237, 14, 14, 1)' }}> Rejected Demands <img src={arrowimage} className='icnn' alt='' /></div>
                                                </div>
                                            </div>
                                            <hr className="mt-0 mb-0 pt-1 pb-1"/>
                                            <div className='dashboardfooter mt-0 mb-0 pt-0 pb-0'>    
                                                     <img src={demandarrowgrin} className='' alt='' /> <span className='contentredclr' style={{ color: 'rgba(57, 136, 111, 1)' }}> 12.5% </span> <span className='contentgrayclr'> vs 10 last month</span>
                                               
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xxl-8 col-xl-6 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 ">
                                    <div className='bdrtbl pt-0 ps-2 pe-2'>
                                        <div className='listdetailsct' style={{ boxShadow: 'none' }}>
                                            <div className='listdetailsct-in'>
                                                <div className='listd-in'>
                                                    <div className="title mt-0 pe-0 ps-0">{t("Recent Demands")}</div>
                                                </div>
                                            </div>
                                            <div className='listdetailsct-in'>
                                                <div className='listd-in'>
                                                    <img src={dob} className='icnn trnsfrm' alt='' />
                                                    <select className="form-select form-select-sm" aria-label=".form-select-sm example" style={{ padding: '10px 30px' }}>
                                                        <option selected>This Month</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="med-table-section dashbrpading pb-2" style={{ "height": "55vh" }}>
                                            <table className='med-table border_ striped' >
                                                <thead>
                                                    <tr style={{ background: '#EAF6FF' }}>
                                                        <th className="text-center" style={{ "width": "3%" }}>#</th>
                                                        <th style={{ "width": "8%" }}>{t("Demand No.")}</th>
                                                        <th style={{ "width": "20%" }}>{t("Demand Date")}</th>
                                                        <th style={{ "width": "20%" }}>{t("Approval / Rejection Date")}</th>
                                                        <th style={{ "width": "12%" }}>{t("Voucher No.")}</th>
                                                        <th style={{ "width": "12%" }}>{t("Priorty")}</th>
                                                        <th className="text-center" style={{ "width": "6%" }}>{t("Action")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="text-center">1</td>
                                                        <td><div>164523</div></td>
                                                        <td><div className='datetime'>23 Feb 2024 | <span>12:20</span></div></td>
                                                        <td><div className='datetime'>23 Feb 2024 | <span>12:20</span></div></td>
                                                        <td><div className='Demandqwnt'>10</div></td>
                                                        <td><div className='demandpriorty'>Normal</div></td>
                                                        <td className="text-center"><i className="fa fa-eye actionedit viewaction "></i></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">2</td>
                                                        <td><div>164523</div></td>
                                                        <td><div className='datetime'>23 Feb 2024 | <span>12:20</span></div></td>
                                                        <td><div className='datetime'>23 Feb 2024 | <span>12:20</span></div></td>
                                                        <td><div className='Demandqwnt'>10</div></td>
                                                        <td><div className='demandpriorty clrurgnt'>Urgent</div></td>
                                                        <td className="text-center"><i className="fa fa-eye actionedit viewaction "></i></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">3</td>
                                                        <td><div>164523</div></td>
                                                        <td><div className='datetime'>23 Feb 2024 | <span>12:20</span></div></td>
                                                        <td><div className='datetime'>23 Feb 2024 | <span>12:20</span></div></td>
                                                        <td><div className='Demandqwnt'>10</div></td>
                                                        <td><div className='demandpriorty clrveyurgnt'>Very Urgent</div></td>
                                                        <td className="text-center"><i className="fa fa-eye actionedit viewaction "></i></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-4 col-xl-6 col-lg-12 col-md-12 col-sm-12 ps-0 pe-0">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}
