// async function GetLocationMaster(data) {
//     let url = window.AdminbaseUrl+"/api/Location/GetLocationMaster";
//     let head = { "Content-Type": "application/JSON", accept : '*/*' };
//     let response = fetch(url, {
//     headers: head,
//     method : 'GET'
//   }).then((res) => res.json()).then(data);

//   return response;
// }
// export default GetLocationMaster;
async function GetLocationMaster(clientID) {
  let token = 'bearer ' + window.AppToken;
  let url = window.AppbaseUrl + "/api/LocationMaster/GetAllLocation?ClientId=" + clientID;
  let head = { "Content-Type": "application/JSON", accept: '*/*', 'Authorization': token };

  let response = await fetch(url, {
    headers: head,
    method: 'GET'
  })
    .then((res) => res.json())
    .then();

  return response;
}
export default GetLocationMaster;

