import React, { useEffect, useId, useState } from 'react'
// import Patientname, { PatientADRReport, PatientAdmitTime, PatientAge, PatientCalculator, PatientCamera, PatientDepartment, PatientFeedBack, PatientGender, PatientProfile, PatientSortIndex, PatientUhId, PatientWard } from './LeftTableData/PatientDeatils/AllFieldPatientDeatils'
import "../../assets/css/patientMonitoringDashboard.css";
// import PatientLifeSupport, { PatientDVTPump, PatientInfusionPump, PatientInfusionPumpDataList, PatientVentiMode } from './LeftTableData/LifeSupport/AllFieldLifeSupport';
// import PatientDeatils from './LeftTableData/PatientDeatils/PatientDeatils';
// import LifeSupport from './LeftTableData/LifeSupport/LifeSupport';
// import Diagnosis from './LeftTableData/Diagnosis/Diagnosis';
// import Ward from './LeftTableData/Ward/Ward';
// import Consultant from './LeftTableData/Consultant/Consultant';
import SearchComponent from './SearchComponent';

// import tableDragger from 'table-dragger'
// import UpdateDashboardColumnSequence from '../Api/UpdateDashboardColumnSequence';
import UpdateAllDashboardColumnSequence from '../Api/UpdateAllDashboardColumnSequence';
import { onDragOver, onDrop } from '../Code/DragDropData';
import { useSelector } from 'react-redux';
export default function LeftTable(props) {

    let [showRow, setShowRow] = useState([])
    let [columnId, setColumnId] = useState(null)
    let [cloumnVisibility, setCloumnVisibility] = useState({
        // lifeSupporVisibility: true,
        // diagnosisVisibility: true,
        // wardVisibility: true,
        // infusionVisibility: true,
        // consultantVisibility: true,
        // nSVisibility: true,
        // bPRVisibility: true,
        // sPO2RVisibility: true,
        // pulseRRVisibility: true,
        // tempRRVisibility: true,
    })
    let patientMonitorDashboard = useSelector((state) => state.PatientMonitorDashboard.alertData)
    function mergeTables(table1Id, table2Id) {
        var table1 = document.getElementById(table1Id);
        var table2 = document.getElementById(table2Id);
        var mergedTable = document.createElement("table");

        // Copy attributes from Table 1
        mergedTable.id = table1.id;
        mergedTable.className = table1.className;
        mergedTable.style = table1.style.cssText;

        // Copy headers from Table 1
        var headerRow1 = mergedTable.createTHead().insertRow(0);
        for (var h = 0; h < table1.rows[0].cells.length; h++) {
            var cell = headerRow1.insertCell(h);
            cell.textContent = table1.rows[0].cells[h].textContent;
            // Create th element
            var th = document.createElement("th");
            th.textContent = cell.textContent;
            // Copy attributes from Table 1 header cell to merged table header cell
            for (var i = 0; i < table1.rows[0].cells[h].attributes.length; i++) {
                var attr = table1.rows[0].cells[h].attributes[i];
                th.setAttribute(attr.name, attr.value);
            }
            cell.replaceWith(th);
        }

        // Copy data rows from Table 1
        for (var i = 1; i < table1.rows.length; i++) {
            var newRow = mergedTable.insertRow();
            for (var j = 0; j < table1.rows[i].cells.length; j++) {
                var newCell = newRow.insertCell(j);
                newCell.textContent = table1.rows[i].cells[j].textContent;
            }
        }

        // Copy headers from Table 2
        var headerRow2 = mergedTable.createTHead().insertRow(1);
        for (var h = 0; h < table2.rows[0].cells.length; h++) {
            var cell = headerRow2.insertCell(h);
            cell.textContent = table2.rows[0].cells[h].textContent;
            // Create th element
            var th = document.createElement("th");
            th.textContent = cell.textContent;
            // Copy attributes from Table 2 header cell to merged table header cell
            for (var i = 0; i < table2.rows[0].cells[h].attributes.length; i++) {
                var attr = table2.rows[0].cells[h].attributes[i];
                th.setAttribute(attr.name, attr.value);
            }
            cell.replaceWith(th);
        }

        // Copy data rows from Table 2
        for (var i = 1; i < table2.rows.length; i++) {
            var newRow = mergedTable.insertRow();
            for (var j = 0; j < table2.rows[i].cells.length; j++) {
                var newCell = newRow.insertCell(j);
                newCell.textContent = table2.rows[i].cells[j].textContent;
            }
        }

        // Display the merged table
        return mergedTable;
    }

    let handleColumnVisisbility = async (name) => {

        // const table = document.getElementById('alltable');
        const table = mergeTables("table", "table2");
        const allHead = Array.from(table.getElementsByTagName('th')).slice(1); // Exclude the first element

        const tempsendArray = allHead.map(head => JSON.parse(head.getAttribute("data-json")))
            .filter(senddata => senddata.columnId !== columnId);

        const sendDataArray = tempsendArray.map((senddata, index) => ({
            id: senddata.id,
            columnId: senddata.columnId,
            sequenceNumber: index + 1,
            assignedTo: senddata.assignedTo,
            userId: window.userId,
            clientId: window.clientId,
            isShow: 1

        }));

        console.log("tempsendArray", tempsendArray, sendDataArray);
        
        if (sendDataArray.length === 0) {
            sendDataArray.push({
                id: 0,
                columnId: 0,
                sequenceNumber: 0,
                assignedTo: 0,
                userId: window.userId,
                clientId: window.clientId
            });
        }

        const sendData = {
            jsonSequence: JSON.stringify(sendDataArray)
        };

        const response = await UpdateAllDashboardColumnSequence(sendData);

        if (response.status === 1) {
            console.log("Data Save");
            props.handleshowHide(-1);
            window.location.reload(true);
        } else {
            window.location.reload(true);
        }

        setCloumnVisibility(value => ({
            ...value,
            [name]: false
        }));
    }

    let getAllColumnIds = async () => {
        let table = document.getElementById('alltable')
        let allHead = table.getElementsByTagName('th')
        let sendDataArray = []
        for (var i = 1; i <= allHead.length - 1; i++) {
            // console.log("alll columns", allHead[i].getAttribute("id"))
            // console.log("alll columns", allHead[i].getAttribute("data-json"))
            let senddata = JSON.parse(allHead[i].getAttribute("data-json"))
            let data = {
                "id": senddata.id,
                "columnId": senddata.columnId,
                "sequenceNumber": i,
                "assignedTo": senddata.assignedTo,
                "userId": window.userId,
                "clientId": window.clientId
            }
            sendDataArray.push(data)

        }

        let sendData = {
            jsonSequence: JSON.stringify(sendDataArray)
        }
        let response = await UpdateAllDashboardColumnSequence(sendData);
        if (response.status === 1) {
            // console.log("Data Save")
            // props.handleshowHide(-1)

        }
        else {
            // window.location.reload(true)
            window.location.reload(true)
            // props.handleshowHide(-1)

        }

        // console.log("sendData ", sendDataArray)

    }



    let moveCol = () => {
        props.handleshowHide(0)
        // tableDragger(document.querySelector("#table"))
        //     .on('drag', (e) => {
        //         console.log('pick a column');
        //     })
        //     .on('drop', async (from, to, el, mode) => {
        //         if(from !==  to)
        //         {

        //             await getAllColumnIds()
        //             props.handleshowHide(-1)
        //         }

        //     })
        //     .on('shadowMove', (from, to, el, mode) => {
        //         console.log(`moving a column`);
        //     })
        //     .on('out', (e, mode) => {
        //         console.log(`extra dont know`);
        //     });



    }


    useEffect(() => {
        // console.log("data to show", props.originalColumn)
        let temp = []
        props.originalColumn.map((val, ind) => {
            setCloumnVisibility(value => ({
                ...value, ["Visibility" + val.columnId]: true
            }))
        })
        setShowRow(temp)

    }, [props.originalColumn])
    useEffect(() => {
        // console.log("pppppp", props.fullColumns.length,props.originalColumn.length )
        // console.log("patientMonitorDashboard, ", patientMonitorDashboard[Object.keys(patientMonitorDashboard).filter((val) => parseInt(val.split("_")[1]) === 47)[0]][0])
    }, [])

    return (
        <>
            <div className="firstSection" style={{ width: (props.originalColumn.length && props.fullColumns <= 11 ? "100%" : "") }}>


                <table className="table-monitor  table" id="table">
                    <thead className="fixedTheadFirst" key={"TableheadKey11"}>
                        <tr key={"TableheadKey1"}>
                            <th style={{ width: "40px", textAlign: "center" }} >#</th>
                            {/* <th style={{ width: "220px" }}>
                            Patient Detail{" "}
                            <i
                                className="bi bi-dash-circle remove-icon"
                                title="Remove Column"
                            ></i>
                        </th> */}
                            {/* <td></td> */}
                            {props.originalColumn && props.originalColumn.map((value, index) => {
                                let allObje = Object.keys(patientMonitorDashboard)
                                let color1 = ""
                                let color2 = ""
                                let color3 = ""
                                // allObje.map((val, ind) => {
                                //     let splitData = Number(val.split("_")[1])
                                //     // console.log("columns", value.columnId)
                                //     if (value.columnId === splitData) {
                                //         color1 = patientMonitorDashboard[val][0]
                                //         color2 = patientMonitorDashboard[val][1]
                                //         color3 = patientMonitorDashboard[val][2]
                                //     }
                                // })

                                return (

                                    cloumnVisibility["Visibility" + value.columnId]
                                    &&
                                    <th key={index} style={{ width: value.columnWidth + "px" }} name={value.columnId} id={value.columnId} data-json={JSON.stringify(value)} >
                                        {/* <div onClick={moveCol}> */}
                                        <div>
                                            {value.columnName}{" "}
                                            <div className='d-flex flex-row gap-1'>
                                                <span style={{ background: color1, width: "10px", height: "10px", borderRadius: "10px" }}></span>
                                                <span style={{ background: color2, width: "10px", height: "10px", borderRadius: "10px" }}></span>
                                                <span style={{ background: color3, width: "10px", height: "10px", borderRadius: "10px" }}></span>
                                            </div>
                                        </div>
                                        <i data-bs-toggle="modal" data-bs-target="#deleteModal"
                                            className="bi bi-dash-circle remove-icon"
                                            title="Remove Column" onClick={() => { setColumnId(value.columnId); props.setIsGridView(0) }}
                                        ></i>

                                    </th>

                                )

                            })}

                        </tr>
                    </thead>

                    <tbody>

                        {/* shwo data for patient deatls and diagnosis or first half*/}
                        {/* {callPatientDatamemo && callPatientDatamemo} */}
                        {
                            props.fullPatientDataList && props.fullPatientDataList.slice(0, props.showNextData).map((val, ind) => {
                                return (
                                    <tr key={ind}>
                                        <td>
                                            <div className="overflow-max" style={{ textAlign: "center" }}>
                                                {ind + 1}
                                            </div>
                                        </td>
                                        {/* patient Details */}
                                        {/* <td>
                                        <PatientDeatils val={val} />
                                    </td> */}
                                        {props.originalColumn && props.originalColumn.map((values, ind) => {
                                            return (
                                                cloumnVisibility["Visibility" + values.columnId] &&
                                                <td onDragOver={(e) => { onDragOver(e, values.columnId) }} onDragEnd={onDrop}>
                                                    <SearchComponent columnId={values.columnId} val={val} />
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            {/* <!-- Modal --> */}
            <div className={`modal d-${columnId === null ? 'none' : 'block'}`} >
                <div className="modal-dialog modalDelete" style={{ width: "300px" }}>
                    <div className="modal-content">

                        <div className="modal-body modelbdy text-center">
                            <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                            <div className='popDeleteTitle mt-3'>Remove?</div>
                            <div className='popDeleteContent'>Are you sure you want to remove this column?</div>
                        </div>
                        <div className="modal-footer1 text-center">

                            <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal" onClick={()=>{setColumnId(null)}}>No</button>
                            <button type="button" className="btn-delete popBtnDelete" onClick={handleColumnVisisbility} data-bs-dismiss="modal">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
{/* 
                        {cloumnVisibility.lifeSupporVisibility &&
                            <th style={{ width: "175px" }} >
                                Life Support{" "}
                                <i
                                    className="bi bi-dash-circle remove-icon"
                                    title="Remove Column" onClick={() => { handleColumnVisisbility("lifeSupporVisibility") }}
                                ></i>
                            </th>
                        }
                        {cloumnVisibility.diagnosisVisibility &&
                            <th style={{ width: "325px" }} >
                                Diagnosis{" "}
                                <i
                                    className="bi bi-dash-circle remove-icon"
                                    title="Remove Column"
                                    onClick={() => { handleColumnVisisbility("diagnosisVisibility") }}
                                ></i>
                            </th>
                        }
                        {cloumnVisibility.wardVisibility &&
                            <th style={{ width: "185px" }} >
                                Ward{" "}
                                <i
                                    className="bi bi-dash-circle remove-icon"
                                    title="Remove Column" onClick={() => { handleColumnVisisbility("wardVisibility") }}
                                ></i>
                            </th>
                        }
                        {cloumnVisibility.infusionVisibility &&
                            <th style={{ width: "280px" }} >
                                Infusion Detail{" "}
                                <i
                                    className="bi bi-dash-circle remove-icon"
                                    title="Remove Column" onClick={() => { handleColumnVisisbility("infusionVisibility") }}
                                ></i>
                            </th>
                        }
                        {cloumnVisibility.consultantVisibility &&
                            <th style={{ width: "200px" }} >
                                Consultant{" "}
                                <i
                                    className="bi bi-dash-circle remove-icon"
                                    title="Remove Column" onClick={() => { handleColumnVisisbility("consultantVisibility") }}
                                ></i>
                            </th>
                        }
                        {cloumnVisibility.nSVisibility &&
                            <th style={{ width: "290px" }} >
                                NS Detail{" "}
                                <i
                                    className="bi bi-dash-circle remove-icon"
                                    title="Remove Column" onClick={() => { handleColumnVisisbility("nSVisibility") }}
                                ></i>
                            </th>
                        }
                        {cloumnVisibility.bPRVisibility &&
                            <th style={{ width: "100px" }} >
                                BP-R{" "}
                                <i
                                    className="bi bi-dash-circle remove-icon"
                                    title="Remove Column" onClick={() => { handleColumnVisisbility("bPRVisibility") }}
                                ></i>
                            </th>
                        }
                        {cloumnVisibility.sPO2RVisibility &&
                            <th style={{ width: "100px" }} >
                                SPO2-R{" "}
                                <i
                                    className="bi bi-dash-circle remove-icon"
                                    title="Remove Column" onClick={() => { handleColumnVisisbility("sPO2RVisibility") }}
                                ></i>
                            </th>
                        }
                        {cloumnVisibility.pulseRRVisibility &&
                            <th style={{ width: "100px" }} >
                                Pulse-R{" "}
                                <i
                                    className="bi bi-dash-circle remove-icon"
                                    title="Remove Column" onClick={() => { handleColumnVisisbility("pulseRRVisibility") }}
                                ></i>
                            </th>
                        }
                        {cloumnVisibility.tempRRVisibility &&
                            <th style={{ width: "100px" }} >
                                Temp-R{" "}
                                <i
                                    className="bi bi-dash-circle remove-icon"
                                    title="Remove Column" onClick={() => { handleColumnVisisbility("tempRRVisibility") }}
                                ></i>
                            </th>
                        } */}