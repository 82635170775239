
async function InsertSettingParameter(data) {

    // let url = `${window.AdminbaseUrl}/api/CurrencyMaster/InsertCurrency?currencyName=${data.currencyName}&userId=${data.userId}`;
    let url = window.AdminbaseUrl + `/api/SettingMaster/InsertSettingParameter?settingParameterName=${data.settingParameterName}&settingId=${data.settingId}&userId=${window.userId}&clientId=${window.clientId}`;

    let head = { "Content-Type": "application/JSON", accept: '*/*' };

    let response = await fetch(url, {
        headers: head,
        method: 'POST'
    })
        .then((res) => res.json())
        .then();

    return response;
}
export default InsertSettingParameter;