async function GetOrganDepartmentMapping(clientID) {
    // let token = 'bearer ' + window.AppToken;
    let url = window.AppbaseUrl + "/api/OrganDepartmentMapping/GetAllOrganDepartmentMapping?ClientId=" + clientID;
    let head = { "Content-Type": "application/JSON", accept: '*/*' };
  
    let response = await fetch(url, {
      headers: head,
      method: 'GET'
    })
      .then((res) => res.json())
      .then();
  
    return response;
  }
  export default GetOrganDepartmentMapping;
  
  