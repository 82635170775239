import React, { useState, useEffect } from 'react'
import TableContainer from '../../../../src/Component/TableContainer';
import Heading from '../../../../src/Component/Heading';
import BoxContainer from '../../../../src/Component/BoxContainer';
import Toster from '../../../../src/Component/Toster'
import TosterUnderProcess from '../../../../src/Component/ToStartUnderProcess'
import PostPharmacyMaster from '../API/PostPharmacyMaster'
import PutPharmacyMaster from '../API/PutPharmacyMaster'
import DeletePharmacyMaster from '../API/DeletePharmacyMaster'
import ValidationPharmacyMaster from '../../../../src/Validation/Pharmacy/ValidationPharmacyMaster'
import GetPharmacyMaster from '../API/GetPharmacyMaster';
import IconEdit from '../../../assets/images/icons/IconEdit.svg'
import IconDelete from '../../../assets/images/icons/IconDelete.svg'
import { useTranslation } from 'react-i18next';
import  i18n from "i18next";
import clearIcon from '../../../assets/images/icons/clear.svg';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import GetLocationMaster from '../../../../src/Admin/Api/Master/LocationMaster/GetLocationMaster'
import DropdownWithSearch from '../../../Component/DropdownWithSearch'
import Select from 'react-select';

export default function PharmacyMaster() {

  let [pharmacyList, setPharmacyList] = useState([])
  let [godownValue, setGodownValue] = useState()
  let [ContactNo, setContactNo] = useState('')
  let [licenseNo, setLicenseNo] = useState('')
  let [id, setId] = useState('');
  let [gstNo, setGstNo] = useState('')
  let [pharmacy, setPharmacy] = useState('')
  let [editLocation, setEditLocation] = useState()
  let [updateBool, setUpdateBool] = useState(0)
  let [sendForm, setSendForm] = useState({ "userId": window.userId, "clientId": window.clientId  })
  let [loder, setLoder] = useState(1)
  let [rowId, setRowId] = useState('');
  let [clearDropdown, setClearDropdown] = useState(0)
  let [showUnderProcess, setShowUnderProcess] = useState(0)
  let [showToster, setShowToster] = useState(0)
  let [tosterMessage, setTosterMessage] = useState("")
  let [tosterValue, setTosterValue] = useState(0)
  const {t} = useTranslation();
  const [newlyAddedRowIndex, setNewlyAddedRowIndex] = useState(null);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [isClearable,] = useState(true);
  const [isSearchable,] = useState(true);
  const [LocationIDDropdown, SetLocationIDDropdown] = useState([])
  let [LocationID, setLocationID] = useState(null)
  const [showLoder, setShowLoder] = useState(0);
  let clientId = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
  let userId = JSON.parse(sessionStorage.getItem("LoginData")).userId;

  //Handle Save
  let saveForm = async () => {
if(pharmacy.trim() === "" || pharmacy === undefined){
  setTosterMessage("Pharmacy Name is required!")
  setShowToster(1)
  setTosterValue(1)
  setTimeout(() => {
    setShowToster(0)
    setTosterValue(1)
  }, 2000)
  return
}
if(LocationID === null){
  setTosterMessage("Location is required!")
  setShowToster(1)
  setTosterValue(1)
  setTimeout(() => {
    setShowToster(0)
    setTosterValue(1)
  }, 2000)
  return
}

const obj = {
  pharmacy: pharmacy,
  locationId: LocationID.value,
  gstNo: gstNo,
  licenseNo: licenseNo,
  contactNo: ContactNo,
  userId: userId

}
console.log("sendForm",sendForm)
    let valresponse = ValidationPharmacyMaster(pharmacy, LocationID)
   
    if (valresponse) {
      setShowUnderProcess(1)
      let response = await PostPharmacyMaster(obj);
      console.log("sendForm", sendForm)
      if (response.status === 1) {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage("Data Save SuccessFully!")
        setTosterValue(0)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
        setNewlyAddedRowIndex(0);
        handleClear();
      }
      else {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage(response.responseValue)
        setTosterValue(1)
        setTimeout(() => {
          setShowToster(0)
          setNewlyAddedRowIndex(null);
        }, 2000)
      }
      getdata()
    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage("Field can't be blank!")
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
    }
  }

  //Get data
  let getdata = async () => {
   
    let getResponse = await GetPharmacyMaster(); 
    let Location = await GetLocationMaster(clientId)
    // console.log("getlocation", getlocation)

    if (getResponse.status === 1) {
      setPharmacyList(getResponse.responseValue)
    }

    // if (getlocation.status === 1) {
    //   setLocationDropdown(getlocation.responseValue.map(location=>({
    //     value : location.id,
    //     label: `${location.floorName} Floor ${location.buildingName} Building ${location.roomNumber}`
      
    //   })));
    //   }

    if (Location.status === 1) {
      setShowLoder(0);
      SetLocationIDDropdown(Location.responseValue.map(location => ({
        value: location.id,
        label: `${location.floorName} Floor ${location.buildingName} Building ${location.roomNumber}`

      })));
      //  console.log("Location",Location.responseValue)
    }

  }
  //Handle Change

  // const handleSelectChange = (selectedOption, errorElementId, setSelectedFunction) => {
  //   document.getElementById(errorElementId).style.display = 'none';
  //   setSelectedFunction(selectedOption);
  //   console.log("selectedOption",selectedOption)
  //   setLocationName(selectedOption.value)
  // };

  const handleSelectChange = (selectedOption, errorElementId, setSelectedFunction) => {
    document.getElementById(errorElementId).style.display = 'none';
    setSelectedFunction(selectedOption);
  };


  let handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;


 
    // setSendForm(sendForm => ({
    //   ...sendForm,
    //   [name]: value
    // }))

    if(name === 'pharmacy'){
      setPharmacy(value)
    }

    if(name === 'gstNo'){
      setGstNo(value)
    }

    if(name === 'licenseNo'){
      setLicenseNo(value)
    }

    if(name === 'contactNo'){
      const checkLength = value;
      if (checkLength.length > 10) {
        return false
      }
      else{
        setContactNo(value)
      }
      
    }

  
  }



  //Handle Delete
  let handleDeleteRow = async () => {
    // setLoder(1)
    setShowUnderProcess(1)
    let obj = {
      id: rowId
    }
    let response = await DeletePharmacyMaster(obj)
    if (response.status === 1) {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage("Data Deleted SuccessFully!")
      handleClear()
      setUpdateBool(0)
      setTosterValue(0)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
      getdata()
      setClearDropdown(0)
    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage(response.responseValue)
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
    }
  }

  //Handle Button Change
  // let handleUpdate = async (id, pharmacy, locationId, gstNo, licenseNo, contactNo, locationID, userId, key) => {
  //   setUpdateBool(1)
  //   setSendForm(sendForm => ({
  //     ...sendForm,
  //     "id": id,
  //     "godown": pharmacy,
  //     'locationId': locationId,
  //     'gstNo': gstNo,
  //     'licenseNo': licenseNo,
  //     'contactNo': contactNo,
  //     "userId": userId

  //   }))
  
  //   setLocationID({
  //     value: key.locationID,
  //     label: `${key.floorName} Floor ${key.buildingName} Building ${key.roomNumber}`
  //   })
  //   document.getElementById("pharmacy").value = pharmacy;
  //   document.getElementById("gstNo").value = gstNo;
  //   document.getElementById("licenseNo").value = licenseNo;
  //   document.getElementById("contactNo").value = contactNo;
  // }

  let handleUpdate = async (key) => {
    setUpdateBool(1)
   
    document.getElementById("pharmacy").value = key.pharmacy;
    document.getElementById("gstNo").value = key.gstNo;
    document.getElementById("licenseNo").value = key.licenseNo;
    document.getElementById("contactNo").value = key.contactNo;

    setLocationID({
      value: key.locationId,
      label: `${key.floorName} Floor ${key.buildingName} Building ${key.roomNumber}`
    })
    setRowId(key.id)
   setContactNo(key.contactNo);
   setPharmacy(key.pharmacy);
   setGstNo(key.gstNo);
   setLicenseNo(key.licenseNo);
  
    
  }


  // Handle Update
  let saveUpdate = async () => {
    if(pharmacy.trim() === "" || pharmacy === undefined){
      setTosterMessage("Pharmacy Name is required!")
      setShowToster(1)
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
        setTosterValue(1)
      }, 2000)
      return
    }
    if(LocationID === null){
      setTosterMessage("Location is required!")
      setShowToster(1)
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
        setTosterValue(1)
      }, 2000)
      return
    }
    const obj = {
      id: rowId,
      pharmacy: pharmacy,
      locationId: LocationID.value,
      gstNo: gstNo,
      licenseNo: licenseNo,
      contactNo: ContactNo,
      userId: userId

  }

  console.log("obj", obj)

    let valresponse = ValidationPharmacyMaster(pharmacy , LocationID)
    
    console.log("valresponse", valresponse);
    if (valresponse) {
      setShowUnderProcess(1)
      let response = await PutPharmacyMaster(obj)
      if (response.status === 1) {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage("Data Updated SuccessFully!")
        setTosterValue(0)
        setTimeout(() => {
          setShowToster(0)
          setNewlyAddedRowIndex(null);
        }, 2000)

        setUpdateBool(0)
        getdata()
        handleClear();
      }
      else {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage(response.responseValue)
        setTosterValue(1)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
      }

    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage("Field can't be blank!")
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
    }
  }

  //Handle Clear
  let handleClear = (value) => {
   setPharmacy('')
    document.getElementById("gstNo").value = "";
    document.getElementById("licenseNo").value = "";
    setUpdateBool(0)
    setEditLocation(0)
    setLocationID(null)
    setContactNo('')
    
  }
  useEffect(() => {
    getdata()
  }, [])
  document.body.dir = i18n.dir();
  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
            <div className="med-box">
                        <div className="inner-content">
                           
                                <div className="row">
                                     <div className="fieldsett-in">
                                     <div className='fieldsett'>
                                    <span className="fieldse">Pharmacy Store Master</span>
                                    <div className="row mt-2 px-2">

                                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-2">
                  <label htmlFor="pharmacy" className="form-label">{t("Pharmacy Name")}<span class="starMandatory">*</span></label>
                  <input type='text' className='form-control form-control-sm' id='pharmacy' value={pharmacy} name='pharmacy' onChange={handleChange} placeholder={t("Enter Pharmacy Name")} />
                </div>

                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-3">
                      <label htmlFor="ddlitemmaster" className="form-label ">{t("Location")}<span className="starMandatory">*</span></label>
                      <Select value={LocationID} options={LocationIDDropdown} className=" create-select" id="locationId" placeholder={t("Choose_Location")} isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, "errLocationName", setLocationID)} />
                      <small id="errLocationName" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>

                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-2">
                  <label htmlFor="gstNo" className="form-label">{t("GST No.")}<span className="starMandatory"></span></label>
                  <input type='text' className='form-control form-control-sm' id='gstNo' name='gstNo' onChange={handleChange} placeholder={t("Enter GST No.")} />
                </div>

                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-2">
                  <label htmlFor="licenseNo" className="form-label">{t("License No.")}<span className="starMandatory"></span></label>
                  <input type='text' className='form-control form-control-sm' id='licenseNo' name='licenseNo' onChange={handleChange} placeholder={t("Enter License No.")} />
                </div>

                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-2">
                  <label htmlFor="contactNo" className="form-label">{t("Contact No.")}<span className="starMandatory"></span></label>
                  <input type='number' className='form-control form-control-sm' id='contactNo' value={ContactNo} name='contactNo' onChange={handleChange} placeholder={t("Enter Contact No.")} />
                </div>


                <div className="d-flex justify-content-end mt-4 mb-2 relative">
                  <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                  <div>
                    {showUnderProcess === 1 ? <TosterUnderProcess /> :
                      <>
                        {showToster === 1 ?
                          <Toster value={tosterValue} message={tosterMessage} />

                          : <div>
                            {updateBool === 0 ?
                              <>
                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={saveForm}><img src={saveButtonIcon} className='icnn' />{t("Save")}</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' />{t("Clear")}</button>
                              </>
                              :
                              <>
                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={saveUpdate}>{t("Update")}</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={handleClear}>{t("Cancel")}</button>
                              </>
                            }
                          </div>}
                      </>
                    }
                  </div>
                </div>
                                      </div>
                                      </div>
                                      </div>
                                      </div>
                                      </div>
                                      </div>
       

     

            </div>
            <div className="col-12 mt-2">
              <Heading text={t("Pharmacy List")} />
              <div className="med-table-section" style={{ "height": "75vh" }}>
                <TableContainer>
                  <thead style={{zIndex:'0'}}>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>{t("Pharmacy Name")}</th>
                      <th>{t("Location")}</th>
                      <th>{t("GST No.")}</th>
                      <th>{t("License No.")}</th>
                      <th>{t("Contact No.")}</th>
                      <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {pharmacyList && pharmacyList.map((key, ind) => {
                     
                      return (
                        <tr value={key}>
                          <td className="text-center">{ind + 1}</td>
                          <td>{key.pharmacy}</td>
                          <td>{key.floorName} Floor {key.buildingName} Building {key.roomNumber}</td>
                          <td>{key.gstNo}</td>
                          <td>{key.licenseNo}</td>
                          <td>{key.contactNo}</td>
                          <td>
                            <div className="action-button">
                              {/* <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={IconEdit} onClick={() => { handleUpdate(key.id, key.pharmacy, key.locationId, key.gstNo, key.licenseNo, key.contactNo, key.LocationID, key.userId, key) }} alt='' /></div> */}
                              <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={IconEdit} onClick={() => { handleUpdate(key, ind) }} alt='' /></div>
                              <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={IconDelete} onClick={() => { setRowId(key.id) }} alt='' /></div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}


                  </tbody>
                </TableContainer>
                {/* -----------------------Start Delete Modal Popup-------------------   */}

                {/*  <!-- Modal -->  */}
                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                  <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                      <div className="modal-body modelbdy text-center">
                        <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                        <div className='popDeleteTitle mt-3'>{t("Delete?")}</div>
                        <div className='popDeleteContent'> {t("Are_you_sure_you_want_to_delete?")}</div>
                      </div>
                      <div className="modal-footer1 text-center">

                        <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                        <button type="button" className="btn-delete popBtnDelete" onClick={handleDeleteRow} data-bs-dismiss="modal">{t("Delete")}</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  )
}
