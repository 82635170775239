export default async function DeleteBedSarvodhya(obj) {
    let url = window.AppbaseUrl+'/api/BedMaster/DeleteBedSarvodhya';
    let head = { 'Content-Type': 'application/json', 'accept': '*/*', };
    let data = {};
    let responsonse = "";
    await fetch(url, {
        method: "DELETE",
        headers: head,
        body: JSON.stringify(obj),
    })
    .then(res => res.json())
        .then(data => { responsonse = data })
        .catch(error => { responsonse = error })


    return responsonse
}


