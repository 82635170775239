import React, { useState } from 'react'
import { useEffect } from 'react';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import Toster from '../../../Component/Toster';
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Heading from '../../../Component/Heading';
import BoxContainer from '../../../Component/BoxContainer';
import TableContainer from '../../../Component/TableContainer';
import SuccessToster from '../../../Component/SuccessToster';
import AlertToster from '../../../Component/AlertToster';
import Loader from '../../../Component/Loader';
import { t } from 'i18next';
import Search from '../../../Code/Serach';
import GetMenuWorkflowMapping from '../../Api/WorkFlow/MenuWorkflowMapping/GetMenuWorkflowMapping';
import GetWorkFlowMaster from '../../Api/WorkFlow/WorkFlowMaster/GetWorkFlowMaster';
import GetAllMenuMaster from '../../../SuperAdmin/Api/Master/MenuMaster/GetAllMenuMaster';
import PostMenuWorkflowMapping from '../../Api/WorkFlow/MenuWorkflowMapping/PostMenuWorkflowMapping';
import PutMenuWorkflowMapping from '../../Api/WorkFlow/MenuWorkflowMapping/PutMenuWorkflowMapping';
import DeleteMenuWorkflowMapping from '../../Api/WorkFlow/MenuWorkflowMapping/DeleteMenuWorkflowMapping';
import DropdownWithSearch from '../../../Component/DropdownWithSearch';

export default function MenuWorkflowMapping() {

    let [menuWorkFlowList, setMenuWorkFlowList] = useState([])
    let [menuWorkFlowListMain, setMenuWorkFlowListMain] = useState("")
    let [menuList, setMenuList] = useState([])
    let [workFlowList, setWorkFlowList] = useState("")
    let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [showToster, setShowToster] = useState(0);
    let [tosterMessage, setTosterMessage] = useState("");
    let [tosterValue, setTosterValue] = useState(0);
    let [showLoder, setShowLoder] = useState(0);
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [updateBool, setUpdateBool] = useState(0);
    let [rowId, setRowId] = useState(0);

    let [clearDropdown, setClearDropdown] = useState(0)
    let [editWorkFlow, setEditWorkFlow] = useState("")
    let [editMenu, setEditMenu] = useState("")
    const userID = JSON.parse(window.sessionStorage.getItem("LoginData")).userId;
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;

    let [sendForm, setSendForm] = useState({
        "userId": userID,
        "clientId": clientID,
        workflowId: 0,
        menuId: 0
    })

    //Handle Change
    let handleChange = (e) => {
        clearValidationErrMessage();
        const { name, value, checked } = e.target;
        setEditWorkFlow("");
        setEditMenu("");
        setSendForm((prevData) => ({
            ...prevData,
            [name]: value,
            userId: userID,
        }));
    };

    //Get Data
    const getdata = async () => {
        // setShowLoder(1);
        const response = await GetMenuWorkflowMapping(clientID);
        if (response.status === 1) {
            setMenuWorkFlowList(response.responseValue);
            setMenuWorkFlowListMain(response.responseValue)
            console.log("menuworkflowmapping", response.responseValue)
            setShowLoder(0)
        }
        else {
            setShowLoder(0);
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }
    //Get Data
    const getWorkFlow = async () => {
        const response = await GetWorkFlowMaster(clientID);
        if (response.status === 1) {
            setWorkFlowList(response.responseValue);
            setShowLoder(0)
        }
    }
    //Get data
    let getMenu = async () => {
        let getMenu = await GetAllMenuMaster();
        if (getMenu.status === 1) {
            setMenuList(getMenu.responseValue)

        }
    }

    //handle search
    let handleSearch = (e) => {
        let resp = Search(menuWorkFlowListMain, e.target.value)
        if (e.target !== "") {
            if (resp.length !== 0) {
                setMenuWorkFlowList(resp)
            }
            else {
                setMenuWorkFlowList([])
            }
        }
        else {
            setMenuWorkFlowList(menuWorkFlowListMain)
        }
    }


    //Handle Save
    const handlerSave = async () => {
        if (sendForm.menuId === 0 || sendForm.menuId === null || sendForm.menuId === undefined) {
            document.getElementById('errMenu').innerHTML = "Select menu";
            document.getElementById('errMenu').style.display = "block";
        }
        if (sendForm.workflowId === 0 || sendForm.workflowId === null || sendForm.workflowId === undefined) {
            document.getElementById('errworkFlow').innerHTML = "Select work flow";
            document.getElementById('errworkFlow').style.display = "block";
        }

        else {
            setShowUnderProcess(1);
            const obj = {
                "menuId": sendForm.menuId,
                "workflowId": sendForm.workflowId,
                "userId": userID,
                "clientId": clientID
            }
            // console.log("obj", obj)
            // return;
            const response = await PostMenuWorkflowMapping(obj);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Data saved successfully.");
                setTimeout(() => {
                    setShowToster(0);
                    handleClear();
                    getdata();
                }, 1500)
            }
            else {
                setShowUnderProcess(0);
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTimeout(() => {
                    setShowToster(0);
                }, 1500)
            }
        }
    }

    //Handle Button Change
    let handleUpdate = async (id, menuId, workflowId, menuName, workFlow) => {
        setUpdateBool(1)
        clearValidationErrMessage();
        setSendForm(sendForm => ({
            ...sendForm,
            "id": id,
            "menuId": menuId,
            "workflowId": workflowId           
        }))
        setEditMenu(menuName);
        setEditWorkFlow(workFlow);
    }



    //Handle Update
    const handlerUpdate = async () => {
        if (sendForm.menuId === 0 || sendForm.menuId === null || sendForm.menuId === undefined) {
            document.getElementById('errMenu').innerHTML = "Select menu";
            document.getElementById('errMenu').style.display = "block";
        }
        else if (sendForm.workflowId === 0 || sendForm.workflowId === null || sendForm.workflowId === undefined) {
            document.getElementById('errworkFlow').innerHTML = "Select work flow";
            document.getElementById('errworkFlow').style.display = "block";
        }
        else {
            // setShowUnderProcess(1);
            const objUpdate = {
                "id": sendForm.id,
                "menuId": sendForm.menuId,
                "workflowId": sendForm.workflowId,
                "userId": userID,
                "clientId": clientID
            }
            // console.log("objUpdate", objUpdate)
            // return;
            const response = await PutMenuWorkflowMapping(objUpdate);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Updated Successfully.");
                setTimeout(() => {
                    setShowToster(0);
                    handleClear();
                    getdata();

                }, 1500)
            }
            else {
                setShowUnderProcess(0);
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTimeout(() => {
                    setShowToster(0);
                }, 1500)
            }
        }
    }

    //Handle Delete
    const handleDelete = async () => {
        setShowLoder(1);
        let obj = {
            id: rowId
        }
        const response = await DeleteMenuWorkflowMapping(obj);
        if (response.status === 1) {
            setShowLoder(0)
            setisShowToaster(1);
            setShowSuccessMsg("Deleted Successfully.")
            setTimeout(() => {
                setisShowToaster(0);
                getdata();
            }, 1500)
            handleClear();
        }
        else {
            setShowLoder(0);
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }

    //Clear Error Message
    const clearValidationErrMessage = () => {
        document.getElementById('errMenu').style.display = "none";
        document.getElementById('errworkFlow').style.display = "none";
    }

    //Handle Clear
    const handleClear = (value) => {
        setClearDropdown(value)
        clearValidationErrMessage();
        setUpdateBool(0);
        setEditWorkFlow(0);
        setEditMenu(0);
        setSendForm({
            "userId": userID,
            "clientId": clientID,
            "menuId": 0,
            "workflowId": 0
        })
    }

    useEffect(() => {
        getdata();
        getMenu();
        getWorkFlow();
    }, []);

    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <Heading text="Menu Work Flow Mapping" />
                            <BoxContainer>

                                <div className="mb-2 me-2">
                                    <label htmlFor="workFlowType" className="form-label">{t("Menu")} <span className="starMandatory">*</span></label>
                                    {menuList && <DropdownWithSearch defaulNname="Select menu" name="menuId" list={menuList} valueName="id" displayName="menuName" editdata={editMenu} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                    <small id="errMenu" className="invalid-feedback" style={{ display: 'none' }}></small>
                                </div>
                                <div className="mb-2 me-2">
                                    <label htmlFor="workFlow" className="form-label">{t("Work Flow")} <span className="starMandatory">*</span></label>
                                    {workFlowList && <DropdownWithSearch defaulNname="Select work flow" name="workflowId" list={workFlowList} valueName="id" displayName="workFlow" editdata={editWorkFlow} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                    <small id="errworkFlow" className="invalid-feedback" style={{ display: 'none' }}></small>
                                </div>

                                <div className="mb-2 relative">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                    <div>
                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />

                                                    : <div>
                                                        {updateBool === 0 ?
                                                            <>
                                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handlerSave}><img src={saveButtonIcon} className='icnn' alt='' />Save</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt='' />Clear</button>
                                                            </>
                                                            :
                                                            <>
                                                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={handlerUpdate}>Update</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}>Cancel</button>
                                                            </>
                                                        }
                                                    </div>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </BoxContainer>
                        </div>
                        <div className="col-12 mt-2">
                            <div className='handlser'>
                                <Heading text="Menu Work Flow Mapping List" />
                                <div style={{ position: 'relative' }}>
                                    <input type="text" className='form-control form-control-sm' placeholder={t("Search")} onChange={handleSearch} />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
                            <div className="med-table-section" style={{ "height": "75vh" }}>
                                <TableContainer>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>#</th>
                                            <th>Menu</th>
                                            <th>Work Flow</th>
                                            <th style={{ "width": "10%" }} className="text-center">Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {menuWorkFlowList && menuWorkFlowList.length > 0 ? (
                                            menuWorkFlowList.map((list, ind) => (
                                                <tr key={list.id}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td>{list.menuName}</td>
                                                    <td>{list.workFlow}</td>
                                                    <td>
                                                        <div className="action-button">
                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom">
                                                                <img src={editBtnIcon} className="" alt="" onClick={() => { handleUpdate(list.id, list.menuId, list.workflowId, list.menuName, list.workFlow); }} />
                                                            </div>
                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal">
                                                                <img src={deleteBtnIcon} className="" alt="" onClick={() => { setRowId(list.id); }} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4" className="text-center">
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>

                                </TableContainer>
                                {/* -----------------------Start Delete Modal Popup-------------------   */}

                                {/*  <!-- Modal -->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">

                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'>Delete?</div>
                                                <div className='popDeleteContent'>Are you sure want to delete?</div>
                                            </div>
                                            <div className="modal-footer1 text-center">

                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={handleDelete} data-bs-dismiss="modal">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

                            </div>
                        </div>
                    </div>
                </div>
                {
                    showLoder === 1 ? <Loader val={showLoder} /> : ""
                }
                {/* Toaster */}
                {
                    isShowToaster === 1 ?
                        <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                }

                {
                    showAlertToster === 1 ?
                        <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                }
            </section>
        </>
    )
}
