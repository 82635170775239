let GetNutrientByFood = async (foodId,foodQuantity )=>{
    let url = window.AppbaseUrl+"/api/KnowMedApis/getNutrientByFood?foodId="+foodId+"&foodQuantity="+foodQuantity;
    let head ={"content-type": "application/json", accept:'*/*'};

    let response = fetch (url, {
        headers:head,
        method:'GET'
    })
    .then((res)=> res.json())
    .then();
    return response;
}
export default GetNutrientByFood;