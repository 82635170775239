import React from "react";
import active from "../../assets/images/icons/active.svg";
import hold from "../../assets/images/icons/hold.svg";
import imgPaymentMode from "../../assets/images/icons/imgPaymentMode.svg";
import imgCardNo from "../../assets/images/icons/imgCardNo.svg";
import imgBank from "../../assets/images/icons/imgBank.svg";
import imgCheque from "../../assets/images/icons/imgCheque.svg";
import imgRef from "../../assets/images/icons/imgRef.svg";
import imgBill from "../../assets/images/icons/imgBill.svg";
import imgCompany from "../../assets/images/icons/imgCompany.svg";
import Page from "../../assets/images/icons/Page-1.svg";
import user3 from "../../assets/images/icons/user (3).svg";
import question from "../../assets/images/icons/question.svg";
import view from '../../assets/images/icons/view.svg';
import category from "../../assets/images/icons/category.svg";
import medicalAssistance from "../../assets/images/icons/medical-assistance.svg";
import medicalRoom from "../../assets/images/icons/medical-room.svg";
import imgDepartment from "../../assets/images/icons/imgDepartment.svg";
import imgBillNo from "../../assets/images/icons/imgBillNo.svg";
import PatientDetail from "../API/getPatientDetailByUhid";
import Search from "../../Code/Serach";
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import clearIcon from '../../assets/images/icons/clear.svg';
import { useState } from "react";
import { useEffect } from "react";
import SuccessToster from "../../Component/SuccessToster";
import AlertToster from "../../Component/AlertToster";
import PatientDetailforadvancepayment from "../API/PatientDetailforadvancepayment";
import GetAdvancePaymentList from "../API/GetAdvancePaymentList";
import UpdateAdvance from "../API/UpdateAdvance";
import PostAdvancePaymentDetails from "../API/POST/PostAdvancePaymentDetails";
import imgPrint from "../../assets/images/icons/imgPrint.svg";
import Heading from "../../Component/Heading";
import GetBankNameList from "../API/getBankNameList";
import getCompanyType from "../API/companyType";
import GetallPaymentMode from "../API/GetallPaymentMode";
import imgDiscount from "../../assets/images/icons/discount.svg";
import GetDepositDetailsByPmId from "../API/GetDepositDetailsByPmId";

export default function AdvancePayment() {

  const currentDate = new Date();
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  let [UHID, setUHID] = useState('');
  let [patientDetails, setPatientDetails] = useState(1);
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [showSuccessToster, setShowSuccessToster] = useState(0)
  let [advanceStatus, setadvanceStatus] = useState(0)
  let [showMessage, setShowMeassage] = useState("");
  let [showPaymentDetails, setshowPaymentDetails] = useState(false);
  const [isShowDepositDetails, setisShowDepositDetails] = useState(0)
  let [advancedpaid, setadvancedpaid] = useState('');
  let [advancedpaidBy, setadvancedpaidBy] = useState('');
  let [contactNo, setcontactNo] = useState('');
  let [PatientType, setPatientType] = useState('');
  let [remark, setremark] = useState('');
  let [billType, setBillType] = useState([]);
  let [FromDate, setFromDate] = useState(formatDate(currentDate));
  let [ToDate, setToDate] = useState(formatDate(currentDate));
  let [searchUHID, setsearchUHID] = useState('');
  let [AlertBorder, setAlertBorder] = useState(false);
  const [rowID, setRowID] = useState("");
  let [byCard, setByCard] = useState();
  let [isFieldDisabled, setisFieldDisabled] = useState(false);
  let [BankName, setBankName] = useState('');
  let [PaymentModeList, setPaymentModeList] = useState([]);
  let [RowPatientData, setRowPatientData] = useState([]);
  let [DepositedetailsByPmid, setDepositedetailsByPmid] = useState([]);
  let [pamentMode, setPaymentMode] = useState(0);
  let [refNo, setRefNo] = useState('');
  let [bankList, setBankList] = useState([])
  let [discountBy, setDiscountBy] = useState();
  let [companyType, setCompanyType] = useState([]);
  let [chequeNo, setchequeNo] = useState('');
  let [chequeDate, setchequeDate] = useState('')
  let [insCardNo, setinsCardNo] = useState(0)
  let [AdvancepaymentList, setAdvancepaymentList] = useState([]);
  let [userID, setUserID] = useState(JSON.parse(sessionStorage.getItem("LoginData")).userId);

  let clientId = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId










  let handleUhidEvent = async (e) => {


    let UHID = e.target.value.toUpperCase();
    setUHID(UHID);
    if (UHID.length !== 9) {

      document.getElementById("Pgender").value = ""
      document.getElementById("PName").value = ""
      document.getElementById("PAge").value = ""
      document.getElementById("PWard").value = ""
      document.getElementById("Pdepartment").value = ""
      document.getElementById("UHID").value = ""

      setPatientDetails([])
    }


    else {

      setAlertBorder(false)
      let data = await PatientDetailforadvancepayment(UHID);
      console.log('data', data.responseValue)
      let dt = data.responseValue[0];
      setPatientDetails(dt);
      setPatientType(dt.status)
      setshowPaymentDetails(true)


      if(dt.status === 0 || dt.status === null || dt.status === 2 || dt.status === 1){
        setShowAlertToster(1)
        setShowMeassage("Patient is not eligible to deposit the amount")
        setisFieldDisabled(true)
      }
      else{
        setisFieldDisabled(false)
      }
    }





  };

  const handleOnChange = (e) => {

    const inputValue = e.target.value;
    const isValidInput = /^[A-Za-z\s]+$/.test(inputValue);


    const { value, name } = e.target;

    if (name === 'advancedpaid') {
      setadvancedpaid(value)
      setAlertBorder(false)
    }
    else if (name === 'advancedpaidBy') {
      setAlertBorder(false)
      setadvancedpaidBy(value)
    }
    else if (name === 'advanceStatus') {
      setAlertBorder(false)
      setadvanceStatus(!advanceStatus)
    }
    else if (name === 'contactNo') {
      const checkLength = value;
      if (checkLength.length > 10) {
        return false

      }
      else {
        setAlertBorder(false);
        setcontactNo(value);
      }


    }
    else if (isValidInput || inputValue === '') {
      setAlertBorder(false)
      setremark(inputValue)
    }
  }

  const handleOnReset = () => {

    document.getElementById("Pgender").value = ""
    document.getElementById("PName").value = ""
    document.getElementById("PAge").value = ""
    document.getElementById("PWard").value = ""
    document.getElementById("Pdepartment").value = ""
    document.getElementById("UHID").value = ""

    setadvancedpaid('')
    setadvancedpaidBy('')
    setcontactNo('')
    setremark('')
    setisFieldDisabled(false)
    setUHID('')
    setshowPaymentDetails(false)
    setPatientDetails([])
    document.getElementById("paymentModeCard").style.display = "none";
    document.getElementById("paymentModeRefNo").style.display = "none";
    document.getElementById("bnkdetails").style.display = "none";
    document.getElementById("chequeDate").style.display = "none";
    document.getElementById("chequeNo").style.display = "none";
    setPaymentMode('')



  }

  const handleOnsubmit = async () => {
    if (UHID.trim() === "") {
      setAlertBorder(true)
      setShowAlertToster(1)
      setShowMeassage("UHID is required..!!")
      return;
    }
    let Bycard = document.getElementById("byCard").value
    let Bycheque = document.getElementById("chequeNo").value
    let Online = document.getElementById("refNo").value
    let Bank = document.getElementById("selectBank").value
    let data = await PatientDetailforadvancepayment(UHID);
    if (UHID.trim() === "") {
      setAlertBorder(true)
      setShowAlertToster(1)
      setShowMeassage("UHID is required..!!")
      return;
    }
    else if (data.status === 0) {
      setShowAlertToster(1)
      setShowMeassage("UHID is not Valid...!!")
      return;
    }
    else if (advancedpaid == '') {
      setAlertBorder(true)
      setShowAlertToster(1)
      setShowMeassage("Please Enter Deposit Amount..!!")
      return;
    }
    else if (advancedpaid == 0) {
      setAlertBorder(true)
      setShowAlertToster(1)
      setShowMeassage("Deposit Amount should not be 0")
      return;
    }
    else if (advancedpaid < 0) {
      setAlertBorder(true)
      setShowAlertToster(1)
      setShowMeassage("Deposit amount should not be in negative..!!")
      return;
    }
    if (advancedpaidBy.trim() == '') {
      setAlertBorder(true)
      setShowAlertToster(1)
      setShowMeassage("Please enter deposited by..!!")

    }
    else if (contactNo == '') {
      setAlertBorder(true)
      setShowAlertToster(1)
      setShowMeassage("Please enter contact number..!!")
      return;
    }
    else if (contactNo.length !== 10) {
      setAlertBorder(true)
      setShowAlertToster(1)
      setShowMeassage("Please enter valid contact number..!!")
      return;
    }
    else if (pamentMode == 0) {
      setShowAlertToster(1)
      setShowMeassage("Please select payment mode..!!")
      return;
    }
    else if (pamentMode == 2 && Bycard === "") {
      setShowAlertToster(1)
      setShowMeassage("Please enter transaction number..!!")
      return;
    }
    else if (pamentMode == 3 && BankName === "") {
      setShowAlertToster(1)
      setShowMeassage("Please select bank..!!")
      return;
    }
    else if (pamentMode == 3  && chequeNo === '') {
      setShowAlertToster(1)
      setShowMeassage("Please enter cheque No..!!")
      return;
    }
    else if (pamentMode == 3 && BankName !== '' && chequeNo !== '' && chequeDate === '') {
      setShowAlertToster(1)
      setShowMeassage("Please select cheque date..!!")
      return;
    }
    else if (pamentMode == 4 && Online == "") {
      setShowAlertToster(1)
      setShowMeassage("Please enter transaction number..!!")
      return;
    }


    else {
      let pname = patientDetails.patientName
      let pAge = patientDetails.age
      let pGender = patientDetails.gender

      const obj = {
        uhid: UHID,
        advanceLimit: advancedpaid,
        advanceSumitedBy: advancedpaidBy,
        contactNo: contactNo,
        remark: remark,
        userID: userID,
        paymentModeId: pamentMode,
        limitStatus: advanceStatus === true ? 2 : 1,
        clientId : clientId,
        pmId : patientDetails && patientDetails.pmId


      }
      console.log("datadepositpost" , obj)
      
      const objSession = {
        uhid: UHID,
        advanceLimit: advancedpaid,
        advanceSumitedBy: advancedpaidBy,
        contactNo: contactNo,
        remark: remark,
        userID: userID,
        paymentModeId: pamentMode,
        paymentTransactionNumber: refNo,
        bankId: BankName,
        chequeNo: chequeNo,
        chequeDate: chequeDate,
        patientGender: pGender,
        patientAge: pAge,
        patientName: pname,



      }

      console.log('obj', obj)

      let data = await PostAdvancePaymentDetails(obj)
      window.sessionStorage.setItem("DepositAmountDetails", JSON.stringify(objSession))
      if (data.status === 1) {
        setShowSuccessToster(1)
        setShowMeassage(`Deposit Amount Paid Successfully for ${UHID}`)

        window.sessionStorage.setItem("DepositAmountDetails", JSON.stringify(objSession))
        window.open("/depositamountprint/", "noopener,noreferrer");
        GetAdvancedPaymentList()
        handleOnReset()
        document.getElementById("Pgender").value = ""
        document.getElementById("PName").value = ""
        document.getElementById("PAge").value = ""
        document.getElementById("PWard").value = ""
        // document.getElementById("Pdepartment").value = ""
        document.getElementById("UHID").value = ""
      }
    }


  }


  const handleDateChange = (e) => {
    const { name, value } = e.target

    if (name === "FromDate") {
      setFromDate(value)
    }
    else if (name === "ToDate") {
      setToDate(value)
    }
    else if (name === "searchUHID") {
      setsearchUHID(value)
    }
    GetAdvancedPaymentList(FromDate, ToDate, searchUHID)
  }


  let HandlePaymentDetails = (e) => {
    if (e.target.name === "discountBy") {
      setDiscountBy(e.target.value);
    }
    if (e.target.name === "byCard") {
      setByCard(e.target.value);
    }
    if (e.target.name === "refNo") {
      setRefNo(e.target.value);
      document.getElementById("paymentModeRefNo").style.display = "block";
    }
    if (e.target.name === "bnkCardNo") {
      setinsCardNo(e.target.value);
    }
    if (e.target.name === "chequeNo") {
      setchequeNo(e.target.value);
    }
    if (e.target.name === "bankName") {
      setBankName(e.target.value);
    }
    if (e.target.name === "chequeDate") {
      setchequeDate(e.target.value);
    }
  };

  let companyTypeList = async (e) => {
    let billtypeId = e.target.value;
    var response = await getCompanyType(billtypeId);
    setCompanyType(response.responseValue);

  }




  const GetAdvancedPaymentList = async () => {
    let advancepayment = await GetAdvancePaymentList(searchUHID, FromDate, ToDate)
    if (advancepayment.status === 1) {
      setAdvancepaymentList(advancepayment.responseValue)
      console.log("advancepayment", advancepayment.responseValue)
    }
  }



const handleShowwDepositDetails=async(index)=>{
  let rowData = AdvancepaymentList[index]
  console.log("gggg" , rowData)
  let data = await PatientDetailforadvancepayment(rowData.uhid);
  let dt = data.responseValue[0];
  setRowPatientData(dt)
  setisShowDepositDetails(1)

  let depostitDetails = await GetDepositDetailsByPmId(rowData.uhid , rowData.pmId)
  if(depostitDetails.status === 1){
    console.log("depostitdetails" , depostitDetails.responseValue)
    setDepositedetailsByPmid(depostitDetails.responseValue)
  }
}




  //*****saveDetails */






  //*******Print */
  let handlePrintBill = async (rowData) => {
    sessionStorage.setItem('AdvancePaymentData', JSON.stringify(rowData));
    console.log("rowData", rowData)

  };

  const handleUpdateApproveCredit = async (index) => {
    setRowID(index)
    let RowIndex = AdvancepaymentList[index]

    const obj = {
      id: RowIndex.id,
      limitStatus: 1,
      uhid : RowIndex.uhid,
      pmId : RowIndex.pmId,
      clientId: clientId,
    }
    console.log(obj)
    let approveCredt = await UpdateAdvance(obj)
    if (approveCredt.status === 1) {
      setShowSuccessToster(1)
      setShowMeassage("Deposit Amount Activated Successfully..!!");
      GetAdvancedPaymentList()
    }
  }

  const handleUpdateHoldCredit = async (index) => {
    let RowIndex = AdvancepaymentList[index]

    const obj = {
      id: RowIndex.id,
      limitStatus: 2,
      uhid : RowIndex.uhid,
      pmId : RowIndex.pmId,
      clientId: clientId,
    }
    console.log(obj)
    let approveCredt = await UpdateAdvance(obj)
    if (approveCredt.status === 1) {
      setShowSuccessToster(1)

      setShowMeassage("Deposited Amount Hold Successfully..!!")
      GetAdvancedPaymentList()
    }
  }

  let GetBankList = async () => {
    var response = await GetBankNameList();
    setBankList(response.responseValue);
    console.log("this is responsesss", response);
  };


  let handlePaymentMode = (e) => {
    let mode = e.target.value
    setchequeDate('')
    setBankName('')
    setByCard('')
    setRefNo('')
    setchequeNo('')
    document.getElementById('byCard').value = ""
    
    setPaymentMode(mode)
    if (mode == "0") {
      document.getElementById("paymentModeCard").style.display = "none";
      document.getElementById("paymentModeRefNo").style.display = "none";
      document.getElementById("bnkdetails").style.display = "none";
      document.getElementById("chequeDate").style.display = "none";
      document.getElementById("chequeNo").style.display = "none";
    } else if (mode === "1") {
      document.getElementById("paymentModeCard").style.display = "none";
      document.getElementById("paymentModeRefNo").style.display = "none";
      document.getElementById("bnkdetails").style.display = "none";
      document.getElementById("chequeDate").style.display = "none";
      document.getElementById("chequeNo").style.display = "none";
    } else if (mode === "2") {

      document.getElementById("paymentModeCard").style.display = "block";
      document.getElementById("chequeDate").style.display = "none";
      document.getElementById("chequeNo").style.display = "none";
      document.getElementById("paymentModeRefNo").style.display = "none";
      document.getElementById("bnkdetails").style.display = "none";
      document.getElementById("chequeDate").style.display = "none";
      document.getElementById("chequeNo").style.display = "none";
    } else if (mode === "3") {
      GetBankList();
      document.getElementById("bnkdetails").style.display = "block";
      document.getElementById("paymentModeCard").style.display = "none";
      document.getElementById("paymentModeRefNo").style.display = "none";
      document.getElementById("chequeDate").style.display = "block";
      document.getElementById("chequeNo").style.display = "block";
    } else if (mode === "4") {
      setPaymentMode(4);
      document.getElementById("paymentModeCard").style.display = "none";
      document.getElementById("paymentModeRefNo").style.display = "block";
      document.getElementById("bnkdetails").style.display = "none";
      document.getElementById("chequeDate").style.display = "none";
      document.getElementById("chequeNo").style.display = "none";
    }
    console.log("payment Value", mode)
  };

  let GetPaymentModes = async () => {
    let PaymentMode = await GetallPaymentMode()
    if (PaymentMode.status === 1) {
      setPaymentModeList(PaymentMode.responseValue)
      console.log("PaymentMode", PaymentMode.responseValue)
    }
  }

  useEffect(() => {

    GetAdvancedPaymentList()
    GetPaymentModes()
    GetBankList()


  }, []);

  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
          <div className="col-12"><div className="med-box  mb-1"><div className="title">Deposit Amount</div></div></div>

            <div className="col-12">
              <div className="med-box">
                <div className="inner-content" >

                  <div className="row">
                    {patientDetails && (
                      <>
                        <div className='fieldsett-in col-md-6'>
                          <div className="fieldsett">
                          <div className="d-flex justify-content-end">
                        <span className="badge rounded-pill text-dark" style={{ fontWeight: '600', padding: '5px 10px' , background: '#cae6ff'}}>Patient Type : {patientDetails && !patientDetails.patientType  ? 'Not Eligible' : patientDetails.patientType} </span>
                        </div>
                            <span className='fieldse'>Patient Details</span>
                            <div className="row mb-2">

                              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3">
                                <img src={Page} alt='' />{" "}
                                <label for="UHID" className="form-label">
                                  UHID <span className="starMandatory">*</span>
                                </label>

                                <input
                                  type="text"
                                  className="form-control form-control-sm "
                                  id="UHID"
                                  value={UHID}
                                  placeholder="UHID"
                                  name="UHID"
                                  maxLength={11}
                                  onChange={handleUhidEvent}
                                />


                              </div>

                              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3">
                                <div className="mb-2">
                                  <div className="d-flex align-items-baseline">
                                    <img src={user3} className='icnn' alt='' /><label htmlFor="FullName*" className="form-label">Patient Name</label>
                                  </div>
                                  <input type="text" className="form-control form-control-sm" id="PName" name="donor" placeholder="Patient Name" value={patientDetails.patientName} disabled />
                                </div>
                              </div>

                              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3">
                                <div className="mb-2">
                                  <div className="d-flex align-items-baseline">
                                    <img src={category} className='icnn' alt='' /> <label htmlFor="gender" className="form-label">Gender</label>
                                  </div>
                                  <input type="text" className="form-control form-control-sm" id="Pgender" name="donor" placeholder="Gender" value={patientDetails.gender} disabled />

                                </div>
                              </div>

                              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3">
                                <div className="mb-2">
                                  <div className="d-flex align-items-baseline">
                                    <img src={question} className='icnn' alt='' /><label htmlFor="dob" className="form-label">Age <span className="starMandatory"></span></label>
                                  </div>

                                  <input type="text" className="form-control form-control-sm" id='PAge' name='regDate' placeholder="Age" value={patientDetails.age} disabled />
                                </div>
                              </div>

                              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3">
                                <div className="mb-2">
                                  <div className='d-flex align-items-baseline'>
                                    <img src={medicalRoom} className='icnn' alt='' /><label htmlFor="bloodGroup" className="form-label">Ward</label>
                                  </div>

                                  <input type="text" className="form-control form-control-sm" id="PWard" value={patientDetails.wardName} name="ddlBloodGroup" placeholder="Ward" disabled />
                                </div>
                              </div>
                              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3">
                              <div className="mb-2">
                                <div className='d-flex align-items-baseline'>
                                  <img src={imgDepartment} className='icnn' alt='' /><label htmlFor="identity" className="form-label">Department</label>
                                </div>
                                <input type="text" value={patientDetails.departName} className="form-control form-control-sm" id="Pdepartment" name="ddlIdentityType" placeholder="Department" disabled />
                              </div>
                            </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className='fieldsett-in col-md-6'>
                      <div className="fieldsett">
                        <span className='fieldse'>Deposit Details</span>


                        <div className="row ps-2">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3">
                            <label htmlFor="Code" className="form-label">Deposit Amount<span className="starMandatory">*</span></label>
                            <input id="advancedpaid" value={advancedpaid} type="number" className="form-control form-control-sm" name="advancedpaid" disabled={isFieldDisabled} placeholder="Deposit Amount" onChange={handleOnChange} />
                            <small id="errwarmingviewtime" className="form-text text-danger" style={{ display: 'none' }}></small>
                          </div>

                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3">
                            <label htmlFor="Code" className="form-label">Deposited by<span className="starMandatory">*</span></label>
                            <input id="advancedpaidBy" value={advancedpaidBy} type="text" className="form-control form-control-sm" name="advancedpaidBy"  disabled={isFieldDisabled} placeholder="Deposit Paid By" onChange={handleOnChange} />
                            <small id="erradvancedpaidBy" className="form-text text-danger" style={{ display: 'none' }}></small>
                          </div>

                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3">
                            <label htmlFor="Code" className="form-label">Contact No.<span className="starMandatory">*</span></label>
                            <input id="contactNo" value={contactNo} type="number" className="form-control form-control-sm" name="contactNo" placeholder="Contact No." disabled={isFieldDisabled} onChange={handleOnChange} />
                            <small id="errcontactNo" className="form-text text-danger" style={{ display: 'none' }}></small>
                          </div>



                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3" id="paymentModediv">
                            <label for="PaymentMode" className="form-label">
                              <img src={imgPaymentMode} alt='' /> Payment Mode{" "}
                              <span className="starMandatory">*</span>
                            </label>
                            <select id="Payment" className="form-control form-control-sm" value={pamentMode} onChange={handlePaymentMode} disabled={isFieldDisabled} >
                              <option value="0" selected>Select Payment Mode</option>
                              {PaymentModeList && PaymentModeList.map((val, index) => {
                                return (
                                  <option key={index} value={val.id}>{val.paymentMethodName}</option>
                                )
                              })}
                            </select>
                          </div>

                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3" id="paymentModeCard" style={{ display: 'none' }}>
                            <label for="byCard" className="form-label">
                              <img src={imgCardNo} alt='' /> Transaction Number
                              <span className="starMandatory">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="byCard"
                              placeholder="Transaction No."
                              name="byCard"
                              onChange={HandlePaymentDetails}
                              disabled={isFieldDisabled}
                            />
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3" id="paymentModeRefNo" style={{ display: 'none' }}>
                            <label for="byOnline" className="form-label">
                              <img src={imgCardNo} alt='' /> Transaction Number
                              <span className="starMandatory">*</span>
                            </label>
                            <input
                              value={refNo}
                              type="text"
                              className="form-control form-control-sm"
                              id="refNo"
                              placeholder="Enter Transaction Number"
                              name="refNo"
                              onChange={HandlePaymentDetails}
                              
                            />
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3" id="bnkdetails" style={{ display: 'none' }}>


                            <label for="bank" className="form-label">
                              <img src={imgBank} alt='' /> Bank
                              <span className="starMandatory">*</span>
                            </label>
                            <select value={BankName} className="form-control form-control-sm" id="selectBank" name="bankName" onChange={HandlePaymentDetails}>
                              <option value="0">Select Bank</option>
                              {bankList &&
                                bankList.map((val, ind) => {
                                  return (
                                    <option key={ind} value={val.id}>{val.bankName}</option>
                                  );
                                })}
                            </select>
  
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3" id="chequeNo" style={{ display: 'none' }}>
                            <label for="chequeNo" className="form-label">
                              <img src={imgCheque} alt='' /> Cheque No.
                              <span className="starMandatory">*</span>
                            </label>
                            <input
                              value={chequeNo}
                              type="text"
                              className="form-control form-control-sm"
                              id="chequeNo"
                              placeholder="Enter Cheque No."
                              name="chequeNo"
                              onChange={HandlePaymentDetails}
                            />
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3" id="chequeDate" style={{ display: 'none' }}>
                            <label for="chequeDate" className="form-label">
                              <img src={imgCheque} alt='' /> Cheque Date.
                              <span className="starMandatory">*</span>
                            </label>
                            <input
                              value={chequeDate}
                              type="date"
                              className="form-control form-control-sm"
                              id="chequeDate"
                              name="chequeDate"
                              min={new Date().toISOString().split('T')[0]}
                              onChange={HandlePaymentDetails}
                            />
                          </div>



                          <div className="container-fluid mb-2 p-0" id="crdBillDetails" style={{ display: 'none' }}>
                            <div className="row">
                              <div className="col-md-4">
                                <label for="cardNo" className="form-label">
                                  <img src={imgCardNo} alt='' /> Card No.
                                  <span className="starMandatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="bnkCardNo"
                                  placeholder="Transaction No."
                                  name="bnkCardNo"
                                  onChange={HandlePaymentDetails}
                                />
                              </div>

                            </div>
                          </div>


                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3">
                            <label htmlFor="Code" className="form-label">Remark<span className="starMandatory"></span></label>
                            <textarea id="remark" value={remark} type="text" rows="3" className="form-control form-control-sm" name="remark" placeholder="Remark" disabled={isFieldDisabled} onChange={handleOnChange} />
                            <small id="errremark" className="form-text text-danger" style={{ display: 'none' }}></small>
                          </div>

                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3">
                            <label for="ddlPaymentType" className="form-label">Do you want to Hold the deposited amount?</label>
                            <div className="form-check form-switch">
                              <input className="form-check-input" type="checkbox" role="switch" id="isCashlessSwitchCheck" name="advanceStatus" disabled={isFieldDisabled} value={advanceStatus} onChange={handleOnChange} />
                            </div>
                          </div>

                        
                        </div>


                      </div>

                      <div className="rt-btns">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3 mt-3 rt-btns ">
                          <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                          <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" disabled={isFieldDisabled} onClick={handleOnsubmit}><img src={saveButtonIcon} className='icnn' alt="" />Submit</button>
                          <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleOnReset}><img src={clearIcon} className='icnn' alt="" />Clear</button>
                        </div>

                      </div>

                    </div>
                  </div>





                </div>

              </div>

            </div>
          </div>


        </div>
        {showAlertToster === 1 ? (
          <AlertToster message={showMessage} handle={setShowAlertToster} />
        ) : (
          ""
        )}
        {showSuccessToster === 1 ? (
          <SuccessToster
            message={showMessage}
            handle={setShowSuccessToster}
          />
        ) : (
          ""
        )}
        <div className="container-fluid col-12 mt-3">
          <div className="med-table-section" >
            <div className='handlser ps-2 mb-3'>
              <Heading text="Deposited Amount List" />
              <div style={{ position: 'relative', display: 'flex' }}>

                <label className="title me-2 mt-1" style={{ fontSize: '12px' }}>From<span className="starMandatory"></span></label>
                <input type="date" name="FromDate" value={FromDate} className='form-control form-control-sm' style={{padding: '13px'}} onChange={handleDateChange} />
                <label htmlFor="Code" className="form-label title me-2 mt-1" style={{ fontSize: '12px' }}>To<span className="starMandatory"></span></label>
                <input type="date" name="ToDate" value={ToDate} className='form-control form-control-sm' style={{padding: '13px'}} onChange={handleDateChange} />
                <input type="text" placeholder="Search by UHID" name="searchUHID" value={searchUHID} className='form-control form-control-sm px-2' onChange={handleDateChange} />
                <div className="ms-5">
                  <span className="tblsericon"><i className="fas fa-search" onClick={GetAdvancedPaymentList}></i></span>
                </div>


              </div>
            </div>
          </div>

          <div className="med-table-section" style={{ "height": "80vh" }}>
            <table className="med-table border_ striped">
              <thead style={{ zIndex: '0' }}>
                <tr>
                  <th className="text-center" style={{ "width": "5%" }}>#</th>
                  <th>UHID</th>
                  <th>Deposited</th>
                  {/* <th>Deposited Amount Paid By</th>
                  <th>Contact No.</th>
                  <th>Remark</th> */}
                  <th>Status</th>
                  <th className="text-right">Action</th>
                  {/* <th>Status</th> */}
                  {/* <th>Action</th> */}

                 
                  {/* <th style={{ "width": "10%" }} className="text-center">Action</th> */}
                </tr>
              </thead>
              <tbody>

                {AdvancepaymentList && AdvancepaymentList.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{data.uhid}</td>
                      <td>{data.advanceLimit}</td>
                      
                      <td><span style={{ width: "50px" }} className={` d-block text-center rounded-1 p-1 ${data.limitStatus == 1 ? "green-transparent" : 'red-transparent'} `}>{data.limitStatus === 1 ? 'Active' : 'Hold'}</span></td>

                      <td>
                        <div className="action-button justify-content-end">
                        <div onClick={()=>handleShowwDepositDetails(index)}>
                            <img src={view} alt='' title='Details'/>
                           </div>

                          <div className={data.limitStatus == 2 ? 'd-block' : data.limitStatus == 1 ? 'd-none' : ''}
                            onClick={() => { handleUpdateApproveCredit(index); }}
                          >
                            <img src={active} alt='' title='Activate' />
                          </div>
                          <div className={data.limitStatus == 1 ? 'd-block' : data.limitStatus == 2 ? 'd-none' : ''}><img src={hold} alt='' title='Hold' onClick={() => { handleUpdateHoldCredit(index); }} />
                          </div>

                          

                        </div>
                      </td>

                    </tr>
                  )
                })}




              </tbody>
            </table>
          </div>
        </div>
        {showAlertToster === 1 ? (
          <AlertToster message={showMessage} handle={setShowAlertToster} />
        ) : (
          ""
        )}
        {showSuccessToster === 1 ? (
          <SuccessToster
            message={showMessage}
            handle={setShowSuccessToster}
          />
        ) : (
          ""
        )}
      </section>


      {isShowDepositDetails === 1 ?
  <div className={`modal d-${isShowDepositDetails === 1 ?"block":""}`} id="EditModal" data-bs-backdrop="static">

<div className="modal-dialog modal-lg">

  <div className="modal-content p-0">

    <div className="modal-header">

      <h1 className="modal-title fs-5 text-white" id="exampleModalLabel">Deposited Amount Details  </h1>

      <button type="button" className="btn-close_ btnModalClose"  title="Close Window" onClick={()=>{setisShowDepositDetails(0)}}>

        <i className="bi bi-x-octagon"></i>

      </button>

    </div>
     
      <div className="modal-body p-0">

<div className="row">

  <div className="col-12">

    <div className="med-box">
      <div className="inner-content">

      <div className="row">
      <div className='col-12'>
      <table className='med-table border_ striped'>
                    <tbody>
                       
                        <tr>
                            <td className='fw-bold'>UHID :</td>                           
                            <td className='value'>{RowPatientData.uhId}</td>
                            <td className='fw-bold'>CRNO :</td>
                            <td className='value'>{RowPatientData.crNo}</td>
                        </tr>
                        <tr>
                            <td className='fw-bold'>IPNO :</td>
                            <td className='value'>{RowPatientData.ipNo}</td>
                            <td className='fw-bold'>Gender :</td>
                            <td className='value'>{RowPatientData.gender}</td>
                        </tr>
                        <tr>
                            <td className='fw-bold'>Patient Name :</td>
                            <td className='value'>{RowPatientData.patientName}</td>
                            <td className='fw-bold'>Age :</td>
                            <td className='value'>{RowPatientData.age} {RowPatientData.agetype}</td>
                        </tr>
                        <tr>
                            <td className='fw-bold'>Patient Mobile No :</td>
                            <td className='value'>{RowPatientData.mobileNo}</td>
                            <td className='fw-bold'>Ward Name :</td>
                            <td className='value'>{RowPatientData.wardName}</td>
                        </tr>
                        <tr>
                            <td className='fw-bold'>Department :</td>
                            <td className='value'>{RowPatientData.departName}</td>                           
                            <td className='fw-bold'>Patient Type</td>                           
                            <td className='value' >{RowPatientData.patientType}</td>                           
                        </tr>
                     
                        </tbody>
                    </table>

          </div>

          <div className='col-12 mt-3 mb-3'>
          <table className='med-table border striped'>
                        <thead>
                            <tr>
                               <th className='text-center'>#</th>
                               <th className='text-center'>UHID</th>
                               <th className='text-right'>Deposited Amount</th>
                               <th className='text-center'>Deposited Amount Paid by</th>
                               <th className='text-right'>Contact No,</th>
                               <th className='text-right'>Payment Method</th>
                               <th className='text-right'>Remark</th>
                            </tr>
                        </thead>
                       
                        <tbody>

                     
                           {DepositedetailsByPmid && DepositedetailsByPmid.map((val,index)=>{
                            return (
                             <tr >
                            <td className='text-center'>{index + 1}</td>
                            <td>{val.uhid}</td>
                            <td className='text-center'>{val.advanceLimit}</td>
                            <td className='text-center'>{val.advanceSumitedBy}</td>
                            <td className='text-center'>{val.contactNo}</td>
                            <td className='text-center'>{val.paymentMethodName}</td>
                            <td className='text-center'>{val.remark}</td>
                           </tr>   

                            )
                           })}
                                                              
  
                        </tbody>

                    </table>
          </div>

      

      </div>

    </div>

    </div>

  </div>

</div>

      </div>
      
    

  </div>

</div>

</div> :''
}
    </>
  );
}
