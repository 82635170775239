import { BrowserRouter } from 'react-router-dom';
import ApiRoutes from './Routes/ApiRoutes';
import "./assets/css/App.css";
import ErrorBoundaryWithNavigate from './PatientMonitorDashboard/Components/ErrorBoundary';


function App() {


  return (
    <BrowserRouter>
      <ErrorBoundaryWithNavigate>
        <ApiRoutes />
      </ErrorBoundaryWithNavigate>
    </BrowserRouter>
  );

}

export default App;
