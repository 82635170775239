import React from 'react'
import printuse from '../../../assets/css/printuse.css'


export default function ReceiptReferralConsultantForm() {
    return (
        <>
            <div className="main-table" style={{ padding: '5px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <table className='clearslip pt-4 mt-4'>
                    {/* ....header.. */}
                    <tr>
                        <td colSpan={6}>
                            <table>
                                <tr>
                                    <td>
                                        <div className="sarvodya-header">
                                            <div className='lft-header '>
                                                <img alt='' className='sv-log' />
                                            </div>
                                            <div className='rght-header'>
                                                <div> SARVODAYA HOSPITAL <div className='addrs'> SECTOR-8, FARIDABAD | HELPLINE: 1800 313 1414</div></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td colSpan={6}>
                            <div className='heading-form mt-2 mb-2'>
                                REFERRAL CONSULTATION FORM
                            </div></td>
                    </tr>
                    <tr>
                        <td colSpan={6}>
                            <table className='table' style={{ border: '1px solid black' }}>
                                <tr>
                                    <td colSpan={2}><div className='assesmnt'>Date of Admission:   <span style={{ width: '395px', borderBottom: '1px solid black', display: 'inline-block' }}></span> </div></td>
                                    <td rowSpan={4} style={{ borderLeft: '1px solid black', textAlign: 'center' }}>Patients Label</td>
                                </tr>
                                <tr>
                                    <td ><div className='assesmnt'>Time of Assessment:   <span style={{ width: '130px', borderBottom: '1px solid black', display: 'inline-block' }}></span> </div></td>
                                    <td ><div className='assesmnt'>Ward.: <span style={{ width: '140px', borderBottom: '1px solid black', display: 'inline-block' }}></span></div></td>
                                </tr>
                                <tr>
                                    <td >
                                        <div className='assesmnt'>Diagnosis:
                                            <span style={{ width: '130px', borderBottom: '1px solid black', display: 'inline-block' }}></span>
                                        </div>
                                    </td>
                                    <td ><div className='assesmnt'>Procedure.:  <span style={{ width: '130px', borderBottom: '1px solid black', display: 'inline-block' }}></span> </div></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={6}>
                            <table className="tablenotes">
                                <tr style={{ background: 'black' }}>
                                    <td colSpan={5}>
                                        <div className="detail">
                                            DETAILED NOTES:
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>
                                        <div className="alignfnt">
                                            <div><strong>Type of Referral:</strong></div>
                                            <div> <label>
                                                immediate
                                                <input type="checkbox" id="" />
                                            </label></div>
                                            <div> <label>
                                                Routine
                                                <input type="checkbox" id="" />
                                            </label></div>
                                            <div> <label>
                                                Urgent
                                                <input type="checkbox" id="" />
                                            </label>
                                            </div>
                                            <div>(To be filled by RMO)</div>
                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>
                                        <div className="alignfnt">
                                            <div><strong> Reason for Referral:</strong></div>
                                            <div> <label>
                                                Opinion
                                                <input type="checkbox" id="" />
                                            </label></div>
                                            <div> <label>
                                                Co-management
                                                <input type="checkbox" id="" />
                                            </label></div>
                                            <div><label>
                                                Take over
                                                <input type="checkbox" id="" />
                                            </label></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5}>
                                        <div className="alignfnt">
                                            <strong> Indication for Referral:</strong>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5}> <div className="alignfnt"><strong>Consultant Notes: </strong></div></td>
                                </tr>
                                <tr> <td> <div className="lines"></div></td></tr>
                                <tr> <td> <div className="lines"></div></td></tr>
                                <tr> <td> <div className="lines"></div></td></tr>
                                <tr> <td> <div className="lines"></div></td></tr>
                                <tr> <td> <div className="lines"></div></td></tr>
                                <tr> <td> <div className="lines"></div></td></tr>
                                <tr> <td> <div className="lines"></div></td></tr>
                                <tr> <td> <div className="lines"></div></td></tr>
                                <tr> <td> <div className="lines"></div></td></tr>
                                <tr> <td> <div className="lines"></div></td></tr>
                                <tr> <td> <div className="lines"></div></td></tr>
                            </table>
                        </td>
                    </tr>
                    <tr style={{ pageBreakBefore: 'always' }}>
                        <td colSpan={6} style={{ verticalAlign: 'top', textAlign: 'center' }}>
                            <div className='micro-heading' >
                                Restraint Reviewed
                            </div>
                        </td>

                    </tr>

                    <tr>
                        <td className='pt-0 mb-0 ps-0 pe-0' colSpan={6}>
                            <table className="ptents-tbl rewptndata">
                                <tr>
                                    <th style={{ width: '16%' }}>Date</th>
                                    <th style={{ width: '16%' }}>Time </th>
                                    <th style={{ width: '18%' }}> Name of <br />Pateint/Attendant<br />
                                        & Relationship, in <br /> case of Attendant  </th>
                                    <th style={{ width: '18%' }}>Signature <br /> Patient/Attendant</th>
                                    <th style={{ width: '16%' }}> Name of<br />
                                        the Doctor</th>
                                    <th style={{ width: '16%' }}> Signature of<br />
                                        the Doctor</th>
                                </tr>
                                <tr>
                                    <td>ss</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>ss</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>ss</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr style={{ pageBreakBefore: 'always' }}> <td colSpan={6}> <div className="lines"></div></td></tr>
                    <tr> <td colSpan={6}> <div className="lines"></div></td></tr>
                    <tr> <td colSpan={6}> <div className="lines"></div></td></tr>
                    <tr> <td colSpan={6}> <div className="lines"></div></td></tr>
                    <tr> <td colSpan={6}> <div className="lines"></div></td></tr>
                    <tr> <td colSpan={6}> <div className="lines"></div></td></tr>
                    <tr> <td colSpan={6}> <div className="lines"></div></td></tr>
                    <tr> <td colSpan={6}> <div className="lines"></div></td></tr>
                    <tr> <td colSpan={6}> <div className="lines"></div></td></tr>
                </table>
            </div>
        </>
    )
}
