import React, { useEffect, useRef, useState } from 'react'
import TosterUnderProcess from '../../../Component/TosterUnderProcess'
import DropdownWithSearch from '../../../Component/DropdownWithSearch'
import Heading from '../../../Component/Heading'
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import { t } from 'i18next';
import Toster from '../../../Component/Toster';
import POSTInsertPatientVital from '../../../Clinical/API/OPD/Vitals/POSTInsertPatientVital';

export default function RestraintVitals() {
    const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    let [showUnderProcess, setShowUnderProcess] = useState(0)
    let [showToster, setShowToster] = useState(0)
    let [tosterMessage, setTosterMessage] = useState("")
    let [tosterValue, setTosterValue] = useState(0)
    let [showLoder, setShowLoder] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [isShowToaster, setisShowToaster] = useState(0);
    let [sendForm, setSendForm] = useState({
        "userId": window.userId,
        "clientId": window.clientId,
        "vmValueBPSys": '',
        "vmValueBPDias": '',
        "vmValueSPO2": '',
        "vmValueTemperature": '',
        "vitalDate": "",
        "vitalTime": ""
    })

  
    let handleChange = (e) => {
        clearValidationErrMessage();
        let name = e.target.name;
        let value = e.target.value;
        
    
        // if (name === 'vitalTime') {
        //     const timeValue = new Date(`2000-01-01T${value}`);
        //     value = timeValue.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        // }
        if (name === 'vitalTime') {
            const [hours, minutes] = value.split(':');
            value = `${hours}:${minutes}`;
        }
        setSendForm(sendForm => ({
            ...sendForm,
            [name]: value
        }))
    }

    const handlerSave = async () => {
        if (sendForm.vmValueBPSys === '' || sendForm.vmValueBPSys === null || sendForm.vmValueBPSys === undefined) {
            document.getElementById('errBPSys').innerHTML = "BP Sys is required";
            document.getElementById('errBPSys').style.display = "block";
        }
        else if (sendForm.vmValueBPDias === '' || sendForm.vmValueBPDias === null || sendForm.vmValueBPDias === undefined) {
            document.getElementById('errBPDias').innerHTML = "BP Dias is required";
            document.getElementById('errBPDias').style.display = "block";
        }
        else if (sendForm.vmValueBPSys > 500) {
            document.getElementById('errBPSys').innerHTML = "Enter valid value";
            document.getElementById('errBPSys').style.display = "block";
        }
        else if (sendForm.vmValueBPDias > 1000) {
            document.getElementById('errBPDias').innerHTML = "Enter valid value";
            document.getElementById('errBPDias').style.display = "block";
        }
        else if (sendForm.vmValueSPO2 === '' || sendForm.vmValueSPO2 === null || sendForm.vmValueSPO2 === undefined) {
            document.getElementById('errSPO2').innerHTML = "SPO2 is required";
            document.getElementById('errSPO2').style.display = "block";
        }
        else if (sendForm.vmValueSPO2 < 1 || sendForm.vmValueSPO2 > 100) {
            document.getElementById('errSPO2').innerHTML = "Enter valid value";
            document.getElementById('errSPO2').style.display = "block";
        }
        else if (sendForm.vmValueTemperature === '' || sendForm.vmValueTemperature === null || sendForm.vmValueTemperature === undefined) {
            document.getElementById('errTemperature').innerHTML = "Temperature is required";
            document.getElementById('errTemperature').style.display = "block";
        }
        else if (sendForm.vmValueTemperature > 110) {
            document.getElementById('errTemperature').innerHTML = "Enter valid value";
            document.getElementById('errTemperature').style.display = "block";
        }
        else {
            setShowUnderProcess(1);
            // const currentDate = new Date();
            // const formattedDate = currentDate.toISOString().split('T')[0];
            const obj = {
                uhId: uhID,
                "vmValueBPSys": sendForm.vmValueBPSys,
                "vmValueBPDias": sendForm.vmValueBPDias,
                "vmValueSPO2": sendForm.vmValueSPO2,
                "vmValueTemperature": sendForm.vmValueTemperature,
                "vitalDate": sendForm.vitalDate,
                "vitalTime": sendForm.vitalTime,
                "clientId": clientID,
                "userId": window.userId,
            }
            // console.log("objVital", obj)
            // return;
            const response = await POSTInsertPatientVital(obj);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Data Saved Successfully..");
                setTimeout(() => {
                    setShowToster(0);
                    handleClear();
                }, 1500)
            }
            else {
                setShowUnderProcess(0);
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTimeout(() => {
                    setShowToster(0);
                }, 1500)
            }
        }
    }

    //Handle Clear
    const handleClear = (value) => {
        clearValidationErrMessage();
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0];
        const formattedTime = currentDate.toTimeString().split(' ')[0];
        // setUpdateBool(0);
        setSendForm({
            "userId": window.userId,
            "clientId": window.clientId,
            "vmValueBPSys": '',
            "vmValueBPDias": '',
            "vmValueSPO2": '',
            "vmValueTemperature": '',
            vitalDate: formattedDate,
            vitalTime: formattedTime,

        })
        document.getElementById("vmValueBPSys").value = "";
        document.getElementById("vmValueBPDias").value = "";
        document.getElementById("vmValueSPO2").value = "";
        document.getElementById("vmValueTemperature").value = "";
    }

    //Clear Error Message
    const clearValidationErrMessage = () => {
        document.getElementById('errBPSys').style.display = "none";
        document.getElementById('errBPDias').style.display = "none";
        document.getElementById('errSPO2').style.display = "none";
        document.getElementById('errTemperature').style.display = "none";
    }
    useEffect(() => {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0];
        const formattedTime = currentDate.toTimeString().split(' ')[0];
        setSendForm((sendForm) => ({
            ...sendForm,
            vitalDate: formattedDate,
            vitalTime: formattedTime,
        }));

    }, [])

    return (
        <>
            {/* {/ -----------------------Start Forgot Password Modal Popup--------------------- /} */}
            <div className='modal fade' id="RestraintVitalsModal" aria-labelledby="RestraintVitalsModalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modalDelete w-100" style={{ margin: '3% auto' }}>
                    <div className="modal-content position-relative">
                        {/* <button type="button" className="btn-close_ position-absolute top-0 start-100 translate-middle bg-dark bg-gradient text-white border-0 rounded-circle_ rounded" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg"></i></button> */}
                        <div className="modal-header modalheading">
                            <h5 className="modal-title">Patient Vitals</h5>
                            <i className="fa fa-times" data-bs-dismiss="modal" aria-label="Close" onClick={handleClear}></i>
                        </div>

                        <div className="modal-body text-center1">
                            <div className='med-box1'>
                                <div className='inner-content1'>
                                    <div className='icuPhyInput mb-3  commnet-txt'>
                                        <label for="vitalDate" className="form-label"> Vital Date<span className="icustarMandatory"></span> </label>
                                        <input type="date" className="form-control form-control-sm" id="vitalDate" name="vitalDate" value={sendForm.vitalDate} onChange={handleChange} />
                                    </div>
                                    <div className='icuPhyInput mb-3  commnet-txt'>
                                        <label for="vitalTime" className="form-label"> Vital Time<span className="icustarMandatory"></span> </label>
                                        <input type="time" className="form-control form-control-sm" id="vitalTime" name="vitalTime" value={sendForm.vitalTime} onChange={handleChange} />
                                    </div>
                                    <div className='icuPhyInput mb-3  commnet-txt'>
                                        <label for="vmValueBPSys" className="form-label"> BP Sys<span className="icustarMandatory">*</span> </label>
                                        <input type="number" className="form-control form-control-sm" id="vmValueBPSys" placeholder="Enter BP Sys" name="vmValueBPSys" value={sendForm.vmValueBPSys} onChange={handleChange} />
                                        <small id="errBPSys" className="invalid-feedback" style={{ display: 'none' }}></small>
                                    </div>
                                    <div className='icuPhyInput mb-3  commnet-txt'>
                                        <label for="vmValueBPDias" className="form-label"> BP Dias<span className="icustarMandatory">*</span> </label>
                                        <input type="number" className="form-control form-control-sm" id="vmValueBPDias" placeholder="Enter BP Dias" name="vmValueBPDias" value={sendForm.vmValueBPDias} onChange={handleChange} />
                                        <small id="errBPDias" className="invalid-feedback" style={{ display: 'none' }}></small>
                                    </div>
                                    <div className='icuPhyInput mb-3  commnet-txt'>
                                        <label for="vmValueSPO2" className="form-label"> SPO2<span className="icustarMandatory">*</span> </label>
                                        <input type="number" className="form-control form-control-sm" id="vmValueSPO2" placeholder="Enter SPO2" name="vmValueSPO2" value={sendForm.vmValueSPO2} onChange={handleChange} />
                                        <small id="errSPO2" className="invalid-feedback" style={{ display: 'none' }}></small>
                                    </div>
                                    <div className='icuPhyInput mb-3  commnet-txt'>
                                        <label for="vmValueTemperature" className="form-label">Temperature<span className="icustarMandatory">*</span> </label>
                                        <input type="number" className="form-control form-control-sm" id="vmValueTemperature" placeholder="Enter Temperature" name="vmValueTemperature" value={sendForm.vmValueTemperature} onChange={handleChange} />
                                        <small id="errTemperature" className="invalid-feedback" style={{ display: 'none' }}></small>
                                    </div>
                                    <div className="relative mt-2 icubtns">
                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />
                                                    : <div>
                                                        <>
                                                            <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-2" onClick={handlerSave}><img src={saveButtonIcon} className='icnn' alt='' />{t("Save")}</button>
                                                            <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt='' />{t("Clear")}</button>
                                                        </>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {/ -----------------------End Forgot Password Modal Popup--------------------- /} */}


        </>
    )
}
