import React from 'react'
import { useEffect, useState } from 'react'
import PrintPageHeader from '../../../Component/PrintPageHeader';
import PatientLabel from '../../../Component/PatientLabel';
// import SarvodayaLogo from '../../../assets/images/icons/sarvodayalogo.png'

export default function ReceiptRequestForm() {

    let [MainData, setMainData] = useState();
    let [MedicationJSON, setMedicationJSON] = useState();
    let [investigationJson, setInvestigationJson] = useState([]);
    let [prophylaxisJson, setprophylaxisJson] = useState([]);
    let [clientData, setclientData] = useState();

 

    let getImmunoDetails = () => {
        console.log('object show');
        let data = JSON.parse(window.sessionStorage.getItem("Immuneohemotologydata"));
        setMainData(data)
        let medJSON = data.rowData.medicationJson ? data.rowData.medicationJson : ''
        let testJSON = data.rowData.investigationJson
        setMedicationJSON(medJSON ? JSON.parse(medJSON) : '')
        setInvestigationJson(JSON.parse(testJSON))
        console.log("medJSON", JSON.parse(testJSON))
        console.log("data", data)
    }

    const ClientData = () => {
        let data = JSON.parse(window.sessionStorage.getItem("LoginData")).clientdata;
        console.log('ClientData', data)
        setclientData(data)
    }



    useEffect(() => {
        getImmunoDetails();
        ClientData()
        setTimeout(() => {
            handlepritnt();
        }, 1200)

    }, []);
    let handlepritnt = () => {
        document.title = 'ImmunohematologyReceipt';
        window.print("");
        window.close();

    }
    return (
        <>
            <div className="main-table" style={{ padding: '5px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <div className='Immunohematologyprint'>
                    <table>
                        <tr>
                            <td className='ps-0 pe-0'>
                                <table className='microtble pt-0 mt-0'>
                                    {/* ....header.. */}
                                    <tr>
                                        <td colSpan={6} >
                                            <table className='table '>
                                                <tr>
                                                    <td colSpan={8}>
                                                        <PrintPageHeader />

                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    {/* ....header.. end*/}
                                    {/* <tr>
                                        <td colSpan={6}>
                                            <table>
                                                <tr>
                                                    <td colSpan={2} style={{ verticalAlign: 'top', }}>
                                                        <div className='micro-heading' style={{ textTransform: 'uppercase' }}>
                                                            Request Form For Immunohematology Tests/Blood Group
                                                        </div>
                                                    </td>
                                                    <td colSpan={4} style={{ verticalAlign: 'top', }}>
                                                        <table className="tb-rght" style={{ borderLeft: 'none' }}>
                                                            <div className='mt-0 pt-0 mb-0 pb-0 d-flex'>
                                                                <PatientLabel />
                                                            </div>

                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td colSpan={6} className='ps-0 pe-0'>
                                            <table className='ps-0 pe-0'>
                                                <tr>
                                                    <td className='ps-0 pe-0' colSpan={6} style={{ verticalAlign: 'top', textAlign: 'center' }}>
                                                    <div className='micro-heading hedingfont mt-3 mb-3' style={{ textTransform: 'uppercase' }}>
                                                            Request Form For Immunohematology Tests/Blood Group
                                                        </div>

                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td className='ps-0 pe-0' colSpan={6} >
                                                        <div className='mt-0 pt-0 mb-0 pb-0 investigationheader'>
                                                            <PatientLabel />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr>
                                    <td colSpan={6} className='ps-0 pe-0 mt-4 pt-4'>
                                            <table>
                                                <tr> <th colSpan={6}>
                                                    <div className="mt-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div className='tbl-sectin'>Date <span >{MainData && MainData.date}</span></div>
                                                        <div className='tbl-sectin'>Department: <span >{MainData && MainData.department}</span></div>
                                                    </div></th>
                                                </tr>
                                                <tr> <th colSpan={6}> <div className="tblrequst" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div className='tbl-sectin'>IP No.: <span >{MainData && MainData.ipNo}</span></div>
                                                    <div className='tbl-sectin'>MRN No.: <span >{MainData && MainData.rowData.uhid}</span></div>
                                                    <div className='tbl-sectin'>Ward/Bed-: <span >{`${MainData && MainData.ward}/${MainData && MainData.bedNo}`}</span></div>
                                                </div></th>
                                                </tr>
                                                <tr>
                                                    <th colSpan={6}>
                                                        <div>Provisional Dignosis :<span style={{ fontWeight: '500' }}>{MainData && MainData.rowData.diagnosisFormated.map((val) => val.details).join(" , ")}</span></div>
                                                    </th>
                                                </tr>
                                                <tr> <th colSpan={6}>
                                                    <div>History of blood transfusion :<span style={{ fontWeight: '500' }}> {MainData && MainData.rowData.historyOfBloodTrans.map((val) => val.details)}</span></div>
                                                </th>
                                                </tr>
                                                <tr> <th colSpan={6}> <div className="" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div className={`tbl-sectin ${MainData && MainData.gender == "Male" ? 'd-none' : 'd-block'}`}>H/O pregnancy:<span className={`${MainData && MainData.gender == "Male" ? 'd-none' : 'd-block'}`} >{MainData && MainData.rowData.historyOfPreg.map((val) => val.details)}</span></div>
                                                    <div className={`tbl-sectin ${MainData && MainData.gender == "Male" ? 'd-none' : 'd-block'}`}>Anti D prophylaxis: <span >{MainData && MainData.rowData.prophyaxisFormated.map((val) => val.drugName)}</span></div>
                                                </div></th>
                                                </tr>
                                                <tr> <th colSpan={6}> <div className="" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div className={`tbl-sectin ${MainData && MainData.gender == "Male" ? 'd-none' : 'd-block'}`}>Medication:<span >{MainData && MainData.rowData.medicationFormated.map((val) => val.drugName)}</span></div>
                                                    <div className={`tbl-sectin ${MainData && MainData.gender == "Male" ? 'd-none' : 'd-block'}`}>Consultant: <span >{MainData && MainData.doctorName}</span></div>
                                                </div></th>
                                                </tr>
                                                <tr> <th colSpan={6}>
                                                    Test required
                                                </th>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    {investigationJson && investigationJson.map((val, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td className='tbl-sectin fw-bold'>{index + 1} {val.TestName}<span ></span></td>
                                                </tr>


                                            </>
                                        )
                                    })}
                                    <table className='mt-3'>
                                        <tr>
                                            <th>
                                                Blood Group
                                            </th>
                                            <th >
                                                Requested by doctor
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className='fw-bold'>
                                                {MainData && MainData.rowData.bloodGroupName}
                                            </td>
                                            <td className='fw-bold'>

                                            </td>
                                        </tr>
                                    </table>


                                </table>

                            </td>
                        </tr>
                    </table>
                </div>


            </div>


        </>
    )
}
