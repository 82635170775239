import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react'
// import '../Assets/CSS/Print.css'
import mainlogo from '../../../assets/images/Navbar/offcanvas-logo.png'
import '../../../../src/assets/css/CertificateCard.css'
import GetAllSaleByBillNo from '../API/GetAllSaleByBillNo'
import GetAllSale from '../API/GetAllSale'
import JsBarcode from 'jsbarcode';
import { json } from 'react-router-dom';
import Vector from "../../../assets/images/icons/Vector.svg";
import Contact from "../../../assets/images/icons/Contact.svg";



export default function SalePrint() {

    let [bool, setBool] = useState(true);
    let [dateTime, setDateTime] = useState();
    let [printdata, setPrintData] = useState([]);
    // let [jsonPrintData, setJsonPrintData] = useState([]);
    let [billNo, setBillNo] = useState();
    let [uhid, setUhid] = useState();
    let [grossAmount, setGrossAmount] = useState();
    let [discount, setDiscount] = useState();
    let [netAmount, setNetAmount] = useState();
    let [billData, setBillData] = useState();
    let date = new Date()
    let [patientData, setPatientData] = useState([]);


    var converter = require('number-to-words');


    let [saleData, setSaleData] = useState(JSON.parse(window.sessionStorage.getItem("PrintSaleData")))
    let [clientData, setClientData] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientdata)
    let [userName, setUseName] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).name)

    useEffect(() => {
        // functionGetLoginData()
        setDateTime(date.toLocaleDateString() + " " + date.toLocaleTimeString());
        // getPurchaseSubDetails()
        // getPTData();

        setTimeout(() => {
            handlepritnt();
        }, 1200)

    }, [])



    let handlepritnt = () => {
        document.title = 'Sale Slip'; // Set the desired title here
        setBool(false)
        window.print("");
        window.close();

    }
    return (

        <>

            <div className="opdSlipContainer pt-3">

                {/* ----------------Header Sec-------------- */}
                <div className="dis-hed">
                    <div className="discharge-logo">
                        <div className="logo-main">
                            <img src={clientData.logoUrl} alt="Company Logo"
                                style={{ width: "211px", }} />
                        </div>
                    </div>


                    {/* <div className="address-section">
                        <div className='proprietor'>Medvantage Hospital</div>
                        <div className='proprietor'>ميدوانتاج  مستشفى</div> */}
                    {/* <div className='phone'>+91-7795688088</div>*/}
                    {/* <div className='phone'> {dateTime}</div>
                    </div> */}

                    <div className="CompanyDetailsHeading">
                        <div className="companyNameChallan">
                            {clientData.clientName}
                        </div>
                        <div className="companyLocation">
                            {clientData.address}
                        </div>
                        <div className="companyContact">
                            <img src={Vector} alt=""></img> {clientData.emailID}

                            <img src={Contact} alt=""></img> {clientData.mobileNo}
                        </div>
                    </div>

                </div>

                {/* <div className="pat-dtls">
                    <div className='document-title'>SALE SLIP</div>
                </div> */}


                <div className="pat-dtls">
                    <div className='document-title'>Bill Details</div>
                    <table className='table-certificate_ border-bottom_ tableWhiteSmoke mt-2_'>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="whiteSmokeheader">Pharmacy Name : <span className="containerDetails">{saleData.pharmacy}</span></div>
                                    <div className="whiteSmokeheader">GST No. : <span className="containerDetails">{saleData.gstNo}</span></div>
                                    <div className="whiteSmokeheader">Licence No. : <span className="containerDetails">{saleData.licenseNo}</span></div>
                                    <div className="whiteSmokeheader">Contact No. : <span className="containerDetails">{saleData.contactNo}</span></div>
                                    <div className="whiteSmokeheader">Address : <span className="containerDetails">{saleData.address}</span></div>

                                </td>
                            </tr>
                            <tr>

                                {/* <th>UHID : </th><td className='ps-3 fw-semibold'>{saleData.uhId}</td>
                                <th>PMID: </th><td className='ps-3 fw-semibold'>{saleData.pmId}</td>
                                <th>Bill No. : </th><td className='ps-3 pe-1 fw-semibold'>{saleData.billNo} </td> */}
                                <td>
                                    <div className="whiteSmokeheader">Bill No</div>
                                    <div className="containerDetails">{saleData.billNo}</div>
                                </td>

                                <td>
                                    <div className="whiteSmokeheader">Bill Date</div>
                                    <div className="containerDetails">{saleData.billDate}</div>
                                </td>
                                <td>
                                    <div className="whiteSmokeheader">UHID</div>
                                    <div className="containerDetails">{saleData.uhId}</div>
                                </td>
                                <td>
                                    <div className="whiteSmokeheader">CRNO</div>
                                    <div className="containerDetails">{saleData.patientData[0].crNo}</div>
                                </td>

                            </tr>

                            <tr>
                                {/* <th>Gross Amount: </th><td className='ps-3 fw-semibold'>{saleData.grossAmount}</td>
                                <th>Discount: </th><td className='ps-3 fw-semibold'>{saleData.discount}</td>
                                <th>Net Amount : </th><td className='ps-3 fw-semibold'>{saleData.netAmount}</td> */}
                                <td>
                                    <div className="whiteSmokeheader">IPNO</div>
                                    <div className="containerDetails">{saleData.patientData[0].ipNo}</div>
                                </td>

                                <td>
                                    <div className="whiteSmokeheader">Gender</div>
                                    <div className="containerDetails">{saleData.patientData[0].gender}</div>
                                </td>

                                <td>
                                    <div className="whiteSmokeheader">Patient Name</div>
                                    <div className="containerDetails">{saleData.patientData[0].patientName}</div>
                                </td>

                                <td>
                                    <div className="whiteSmokeheader">Age</div>
                                    <div className="containerDetails">{saleData.patientData[0].age} {saleData.patientData[0].agetype}</div>
                                </td>

                            </tr>

                            <tr>
                                <td>
                                    <div className="whiteSmokeheader">Patient Mobile No</div>
                                    <div className="containerDetails">{saleData.patientData[0].mobileNo}</div>
                                </td>

                                <td>
                                    <div className="whiteSmokeheader"> Ward Name</div>
                                    <div className="containerDetails">{saleData.patientData[0].wardName}</div>
                                </td>
                                <td colSpan={2}>
                                    <div className="whiteSmokeheader">Department</div>
                                    <div className="containerDetails">{saleData.patientData[0].departName}</div>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>


                <div className="pat-dtls cert-top2" >
                    <div className='document-title'>Item Details</div>
                    <table className='table-certificate_ border-bottom_ tableWhiteSmoke mt-2_'>
                        <thead>
                            <tr>
                                <th className='text-center'>#</th>
                                <th>Item Name</th>
                                <th className='text-center'>Unit Name</th>
                                <th className='text-center'>Batch No.</th>
                                <th className='text-center'>Quantity</th>
                                <th className='text-right'>Unit Price</th>


                            </tr>
                        </thead>

                        <tbody style={{ height: '100px' }}>


                            {saleData.jsonSaleData && saleData.jsonSaleData.map((list, index) => {
                                return (


                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{list.itemName}</td>
                                        <td className='text-center'>{list.unitName}</td>
                                        <td className='text-center'>{list.batchNo}</td>
                                        <td className='text-center'>{list.quantity}</td>
                                        <td className='text-right'>{list.unitPrice}</td>


                                    </tr>
                                )
                            })}
                            <tr>
                                <td className='text-right' colSpan={5}>Gross Amount(Rs)</td>
                                <td className='text-right'>{saleData.grossAmount}</td>
                            </tr>

                            <tr>
                                <td className='text-right' colSpan={5}>Total Discount(Rs)</td>
                                <td className='text-right'>{saleData.discount}</td>
                            </tr>
                            <tr>
                                <td className='text-right' colSpan={5} >Net Amount(Rs)</td>
                                <td className='text-right'>{saleData.netAmount}</td>
                            </tr>
                            <tr>
                                <td className='text-right' colSpan={4}>Net Amount in words</td>
                                <td className='text-right' colSpan={2}>{converter.toWords(saleData.netAmount).toUpperCase()}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <div className="pat-dtls cert-top2" style={{ minHeight: '400px' }}>

                    <table className='table-certificate border-bottom'>
                        <div className='document-title'>Payment Details</div>
                        <tbody>
                            <tr>
                                <td style={{ width: '30%' }}><strong>Paid Amount(Rs)</strong></td>
                                <td className='value'>: <strong>{saleData.netAmount}</strong> </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div className='cert-title-details cert-top3 d-flex justify-content-between'>
                    <div><b>User Name : {userName}</b></div><div><b>Authorised Signature</b></div>
                </div>

                <div className="opdSlipFooter">
                    <a href="https://criteriontechnologies.com/" target="_blank" rel="noopener noreferrer">www.criteriontechnologies.com</a>
                </div>
            </div >

        </>
    )
}
