import React, { useState } from 'react'
import StethoscopeWaveFromPopup from '../../../Components/StethoscopeWaveFromPopup'
import stethoscope from "../../../../assets/images/patientmonitordashboard/stethoscope.svg"
import medicIcon from "../../../../assets/images/patientmonitordashboard/medicine.svg"
import MedicineChecklist from '../../../Components/MedicineChecklist'
import FoodIntakeChecklist from '../../../Components/FoodIntakeChecklist'
import SupplementChecklist from '../../../Components/SupplementChecklist'
import PhysicalActivityByUHID from '../../../Components/PhysicalActivityByUHID'
import food from '../../../../assets/images/patientmonitordashboard/food.png'
import supplementIcon from "../../../../assets/images/patientmonitordashboard/supplement.svg"
import ActivityTracker from '../../../../assets/images/icons/activity tracker.svg'
import InvestigationReport from '../../../Components/InvestigationReport'
import ClinicalNotification from '../../../Components/ClinicalNotification'
import SuggestedFood from '../../../Components/SuggestedFood'
import ECGGraphIcon from "../../../../assets/images/patientmonitordashboard/MachineECG.svg"
import ECGGraph from '../../../Components/ECGGraph'
import { onDrop, onStart } from '../../../Code/DragDropData'
import PacsPopUp from '../../../Components/PacsPopUp'

export default function ShowStethoScopePopUp(props) {
  let [showStethoScope, setShowStethoScope] = useState(0)
  let modelCloseFun = () => {
    setShowStethoScope(0)
  }
  return (
    <>

      <img key={props.key} style={{filter:"invert(1)"}} className='pointer imgHover pticons' src={stethoscope} title="Stethoscope" width="24px" draggable="true" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} onDragStart={onStart} onDrop={(e) => onDrop(e, "abc")} onClick={() => { setShowStethoScope(1) }} alt='' />
      {
        showStethoScope ? <StethoscopeWaveFromPopup ShowStethoScope={showStethoScope} modelCloseFun={modelCloseFun} patientdata={props.patientData} /> : ""
      }
    </>
  )
}

function MedicineCheckPopUp(props) {
  let [ShowMedicinePopup, setShowMedicinePopup] = useState(0)

  let modelCloseFun = () => {
    setShowMedicinePopup(0)
  }
  return (
    <>
      <img key={props.key} className='pointer imgHover pticons' src={medicIcon} title="Medicine" onClick={() => { setShowMedicinePopup(1) }} alt='' draggable="true" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} onDragStart={onStart} onDragEnd={onDrop} />

      {
        ShowMedicinePopup === 1 ? <MedicineChecklist ShowMedicinePopup={ShowMedicinePopup} modelCloseFun={modelCloseFun} patientdata={props.patientData} /> : ""

      }
    </>
  )
}

function FoodIntakePopUp(props) {
  let [ShowFoodIntakePopup, setShowFoodIntakePopup] = useState(0)

  let modelCloseFun = () => {
    setShowFoodIntakePopup(0)
  }
  return (
    <>
      <img key={props.key} className='pointer imgHover pticons' src={food} title="FoodIntake" onClick={() => { setShowFoodIntakePopup(1) }} alt='' draggable="true" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} onDragStart={onStart} onDragEnd={onDrop} />

      {
        ShowFoodIntakePopup === 1 ? <FoodIntakeChecklist ShowFoodIntakePopup={ShowFoodIntakePopup} modelCloseFun={modelCloseFun} patientdata={props.patientData} /> : ""

      }
    </>
  )
}


function SupplementPopUp(props) {
  let [ShowSupplementPopup, setShowSupplementPopup] = useState(0)

  let modelCloseFun = () => {
    setShowSupplementPopup(0)
  }
  return (
    <>
      <img key={props.key} className='pointer imgHover pticons' src={supplementIcon} title="Supplement" onClick={() => { setShowSupplementPopup(1) }} alt='' draggable="true" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} onDragStart={onStart} onDragEnd={onDrop} />

      {
        ShowSupplementPopup === 1 ? <SupplementChecklist ShowSupplementPopup={ShowSupplementPopup} modelCloseFun={modelCloseFun} patientdata={props.patientData} /> : ""

      }
    </>
  )
}

function PhysicalActivityPopUp(props) {
  let [ShowPhysicalActivityPopup, setShowPhysicalActivityPopup] = useState(0)

  let modelCloseFun = () => {
    setShowPhysicalActivityPopup(0)
  }
  return (
    <>
      <img key={props.key} className='pointer imgHover pticons' src={ActivityTracker} title="PhysicalActivity" onClick={() => { setShowPhysicalActivityPopup(1) }} alt='' draggable="true" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} onDragStart={onStart} onDragEnd={onDrop} />
      {
        ShowPhysicalActivityPopup === 1 ? <PhysicalActivityByUHID ShowPhysicalActivityPopup={ShowPhysicalActivityPopup} modelCloseFun={modelCloseFun} patientdata={props.patientData} /> : ""
      }
    </>
  )
}



function PatientInvestigationDetails(props) {
  let [investigationpopup, setInvestigationPopup] = useState(0)
  let modelCloseFun = () => {
    setInvestigationPopup(0)
  }

  return (
    <>
      <i key={props.key} className='pointer fa-solid fa-microscope pointer text-white pt-1 pticons' title='Investigation Deatils' style={{ fontSize: "15.5px" }} onClick={() => { setInvestigationPopup(1) }} draggable="true" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData, "paramenterData": props.paramenterData })} onDragStart={onStart} onDragEnd={onDrop}></i>
      {
        investigationpopup ? <InvestigationReport investigationpopup={investigationpopup} modelCloseFun={modelCloseFun} patientdata={props.patientData} /> : ""
      }
    </>
  )
}


function PAtientPACSDeatils(props) {
  let [pacsPopup, setPacsPopup] = useState(0)

  let modelCloseFun = () => {
    setPacsPopup(0)
  }
  // let handlePacs = (uhid, date) => {
  //   let CryptoJS = require("crypto-js");

  //   let url = `http://182.156.200.177:8042/webviewer/index.html?PID=${uhid}&admissionDate=${date}`
  //   // console.log("url", url)
  //   let index = url.indexOf('?');
  //   let queryparams = [url.slice(0, index), url.slice(index + 1)];
  //   // let userDetails = '&userID=' + window.userId + '&accessToken=' + window.AppToken.toString()
  //   let userDetails = '&userID=' + 1234567 + '&accessToken=' + "QzMwNzhGQkM2QzVFNDkxODg3QTNFMjUzQzdBQ0Y4N0EtMTIzNDU2Nw==".toString()
  //   // console.log("userDetails", userDetails)
  //   let finalString = queryparams[1].toString() + userDetails;
  //   //var encrypted = CryptoJS.AES.encrypt(finalString, 'sandeep23097');
  //   //var decrypted = CryptoJS.AES.decrypt(encrypted, 'sandeep23097');
  //   let iv = CryptoJS.enc.Utf8.parse("ABCDEFGHIJKLMNOP");
  //   let key = CryptoJS.enc.Utf8.parse("encrypt123456789");
  //   let encrypted = CryptoJS.AES.encrypt(finalString, key, { iv })
  //   window.open(queryparams[0] + '?' + encrypted.toString(), '_blank');
  // }
  return (
    <>
      {
        props.patientData.PacsList !== null ?

          <i key={props.key} className='fa-solid fa-x-ray pointer pticons' title='PACS Deatil' style={{ fontSize: "16px", color: '#337ab7' }} onClick={() => { setPacsPopup(1) }} onDragStart={onStart} onDragEnd={onDrop}></i>
          : ""
      }
      {
        pacsPopup ? <PacsPopUp pacsPopup={pacsPopup} modelCloseFun={modelCloseFun} patientdata={props.patientData} /> : ""
      }
    </>
  )
}


function PatientClinicalNotifications(props) {
  let [clinicalnotificationpopup, setClinicalnotificationPopup] = useState(0)
  let modelCloseFun = () => {
    setClinicalnotificationPopup(0)
  }
  return (
    <>
      <i key={props.key} className='fa-regular fa-bell-slash pointer pticons pt-1' title='View Clinical Notifications' onClick={() => { setClinicalnotificationPopup(1) }} style={{ fontSize: "17px", color: "#ec0726" }} draggable="true" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} onDragStart={onStart} onDragEnd={onDrop}></i>
      {
        clinicalnotificationpopup ? <ClinicalNotification clinicalnotificationpopup={clinicalnotificationpopup} modelCloseFun={modelCloseFun} patientdata={props.patientData} /> : ""
      }
    </>
  )
}

function PatientSuggestedFood(props) {
  let [suggestedFoodpopup, setSuggestedFoodPopup] = useState(0)

  let modelCloseFun = () => {
    setSuggestedFoodPopup(0)
  }
  return (
    <>
      <i key={props.key} className='pointer pticons' title='Suggested Food Deatils' onClick={() => { setSuggestedFoodPopup(1) }} draggable="true" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} onDragStart={onStart} onDragEnd={onDrop}><img src={food} /></i>

      {
        suggestedFoodpopup ? <SuggestedFood suggestedFoodpopup={suggestedFoodpopup} modelCloseFun={modelCloseFun} patientdata={props.patientData} /> : ""
      }
    </>
  )
}
function PatientECGGraph(props) {
  let [ecgGraphpopup, setEcgGraphPopup] = useState(0)

  let modelCloseFun = () => {
    setEcgGraphPopup(0)
  }
  return (
    <>
      {
        props.patientData.ECGList !== null ?
          <img key={props.key} src={ECGGraphIcon} title="ECG Graph" className='pointer imgHover pticons' width="24px" draggable="true" onClick={() => { setEcgGraphPopup(1) }} data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} onDragStart={onStart} onDragEnd={onDrop} /> : ""
      }

      {
        ecgGraphpopup ? <ECGGraph ecgGraphpopup={ecgGraphpopup} modelCloseFun={modelCloseFun} patientdata={props.patientData} /> : ""
      }
    </>
  )
}

export { MedicineCheckPopUp, FoodIntakePopUp, SupplementPopUp, PhysicalActivityPopUp, PatientInvestigationDetails, PAtientPACSDeatils, PatientClinicalNotifications, PatientECGGraph }
