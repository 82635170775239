async function GetItemMaster(data) {
    let url = window.BillingbaseUrl+"/api/ItemMaster/GetAllItemMaster?clientId="+window.clientId;
    let head = { "Content-Type": "application/JSON", accept : '*/*' };


  let response = fetch(url, {
    headers: head,
    method : 'GET'
  }).then((res) => res.json()).then(data);

  return response;
}
export default GetItemMaster;
