import React, { useState } from 'react'
import { useEffect } from 'react';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import Toster from '../../../Component/Toster';
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Heading from '../../../Component/Heading';
import BoxContainer from '../../../Component/BoxContainer';
import TableContainer from '../../../Component/TableContainer';
import SuccessToster from '../../../Component/SuccessToster';
import AlertToster from '../../../Component/AlertToster';
import Loader from '../../../Component/Loader';
import GetShiftList from '../Api/ShiftMaster/GetShiftList';
import SaveShiftMaster from '../Api/ShiftMaster/SaveShiftMaster';
import UpdateShift from '../Api/ShiftMaster/UpdateShift';
import DeleteShift from '../Api/ShiftMaster/DeleteShift';
import Search from '../../../Code/Serach';
import { t } from 'i18next';


export default function ShiftMaster() {
    let [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
    let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [showToster, setShowToster] = useState(0);
    let [tosterMessage, setTosterMessage] = useState("");
    let [tosterValue, setTosterValue] = useState(0);
    let [showLoder, setShowLoder] = useState(0);
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [shiftList, setShiftList] = useState('');
    let [shiftListMain, setShiftListMain] = useState('');
    let [updateBool, setUpdateBool] = useState(0);
    let [rowID, setRowId] = useState(0);
    let[sendForm,setSendForm]=useState({
        shiftName:'',
        shiftFrom:'',
        shiftTo:''
    })
    let handleChange= async(e)=>{
      clearValidationErrMessage()
      console.log('e.target',e.target)
        const {name, value}= e.target;
        setSendForm((prevData)=>({...prevData,[name]:value})); 
      
    }
   const getShiftList= async()=>{
    setShowLoder(1);
    const response = await GetShiftList();
    if(response.status === 1){
      setShiftList(response.responseValue);
      setShiftListMain(response.responseValue);
      setShowLoder(0)
    }
    else{
      setShowLoder(0);
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
      setTimeout(()=>{
        setShowAlertToster(0);
      },1500)
    }
   }
  //handle search
  let handleSearch = (e) => {
    let resp = Search(shiftListMain, e.target.value)
    if (e.target !== "") {
      if (resp.length !== 0) {
        setShiftList(resp)
      }
      else {
        setShiftList([])
      }
    }
    else {
      setShiftList(shiftListMain)
    }
  }


   const handlerSave = async()=>{
    if(sendForm.shiftName === '' || sendForm.shiftName === null || sendForm.shiftName === undefined){
      document.getElementById('errShiftName').innerHTML="Shift Name is Required";
      document.getElementById('errShiftName').style.display="block";
    }
    else if(sendForm.shiftFrom === '' || sendForm.shiftFrom === null || sendForm.shiftFrom === undefined){
      document.getElementById('errShiftFrom').innerHTML="Shift From is Required";
      document.getElementById('errShiftFrom').style.display="block";
    }
    else if(sendForm.shiftTo === '' || sendForm.shiftTo === null || sendForm.shiftTo === undefined){
      document.getElementById('errShiftTO').innerHTML="Shift To is Required";
      document.getElementById('errShiftTO').style.display="block";
    }
    else if(sendForm.shiftFrom > sendForm.shiftTo){
      document.getElementById('errShiftFrom').innerHTML="Shift From Can Not Be Greater Than Shift To";
      document.getElementById('errShiftFrom').style.display="block";
    }
    else if(sendForm.shiftTo  < sendForm.shiftFrom ){
      document.getElementById('errShiftTO').innerHTML="Shift To Can Not Be Lower Than Shift From";
      document.getElementById('errShiftTO').style.display="block";
    }
    else{
      setShowUnderProcess(1);
      var obj ={
       
          "id": 0,
          "userID": userID,
          "shiftName": sendForm.shiftName,
          "shiftFromTime": sendForm.shiftFrom,
          "shiftToTime": sendForm.shiftTo
       
      }
      const response = await SaveShiftMaster(obj);
      console.log('save Response',response);
      if(response.status === 1){
        setShowUnderProcess(0);
        setTosterValue(0);
        setShowToster(1);
        setTosterMessage("Saved Successfully..");
        setTimeout(()=>{
          setShowToster(0);
          handleClear();
          getShiftList();
          
        },1500)
      }
      else{
        setShowUnderProcess(0);
        setTosterValue(1);
        setShowToster(1);
        setTosterMessage(response.responseValue);
        setTimeout(()=>{
          setShowToster(0);
        },1500)
      }
    }
   }
   const handleEdit=(params)=>{
    setUpdateBool(1);
    setRowId(params.id);
    setSendForm({
      shiftName:params.shiftName,
      shiftFrom:params.shiftTimeFrom,
      shiftTo:params.shiftTimeTo
  })
   }
   const handlerUpdate = async()=>{
    console.log('update data',sendForm);
    if(sendForm.shiftName === '' || sendForm.shiftName === null || sendForm.shiftName === undefined){
      document.getElementById('errShiftName').innerHTML="Shift Name is Required";
      document.getElementById('errShiftName').style.display="block";
    }
    else if(sendForm.shiftFrom === '' || sendForm.shiftFrom === null || sendForm.shiftFrom === undefined){
      document.getElementById('errShiftFrom').innerHTML="Shift From is Required";
      document.getElementById('errShiftFrom').style.display="block";
    }
    else if(sendForm.shiftTo === '' || sendForm.shiftTo === null || sendForm.shiftTo === undefined){
      document.getElementById('errShiftTO').innerHTML="Shift To is Required";
      document.getElementById('errShiftTO').style.display="block";
    }
    else if(sendForm.shiftFrom > sendForm.shiftTo){
      document.getElementById('errShiftFrom').innerHTML="Shift From Can Not Be Greater Than Shift To";
      document.getElementById('errShiftFrom').style.display="block";
    }
    else if(sendForm.shiftTo  < sendForm.shiftFrom ){
      document.getElementById('errShiftTO').innerHTML="Shift To Can Not Be Lower Than Shift From";
      document.getElementById('errShiftTO').style.display="block";
    }
    else{
      setShowUnderProcess(1);
      var obj ={
       
          "id": rowID,
          "userID": userID,
          "shiftName": sendForm.shiftName,
          "shiftFromTime": sendForm.shiftFrom,
          "shiftToTime": sendForm.shiftTo
       
      }
      console.log('update Obj',obj);
    
      const response = await UpdateShift(obj);
      console.log('save Response',response);
      if(response.status === 1){
        setShowUnderProcess(0);
        setTosterValue(0);
        setShowToster(1);
        setTosterMessage("Updated Successfully..");
        setTimeout(()=>{
          setShowToster(0);
          handleClear();
          getShiftList();
          
        },1500)
      }
      else{
        setShowUnderProcess(0);
        setTosterValue(1);
        setShowToster(1);
        setTosterMessage(response.responseValue);
        setTimeout(()=>{
          setShowToster(0);
        },1500)
      }
    }
   }
   const handleDelete = async()=>{
    setShowLoder(1);
    const response = await DeleteShift(rowID);
    if(response.status === 1){
      setShowLoder(0)
      setisShowToaster(1);
      setShowSuccessMsg("Deleted Successfully")
      setTimeout(()=>{
        setisShowToaster(0);
        getShiftList();
      },1500)
    }
    else{
      setShowLoder(0);
      setShowAlertToster(1);
      setShowErrMessage(response.responseValue);
      setTimeout(()=>{
        setShowAlertToster(0);
      },1500)
    }
   }
   const clearValidationErrMessage=()=>{
    document.getElementById('errShiftName').style.display="none";
    document.getElementById('errShiftFrom').style.display="none";
    document.getElementById('errShiftTO').style.display="none";
   }
   const handleClear=()=>{
    clearValidationErrMessage();
    setUpdateBool(0);
    setSendForm({
        shiftName:'',
        shiftFrom:'',
        shiftTo:''
    })
   }
    useEffect(()=>{
      getShiftList();
    },[]);
  
  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Heading text="Shift Master" />
              <BoxContainer>
                <div className="col-2 mb-2 me-2">
                  <label htmlFor="TestName" className="form-label">Shift Name<span className="starMandatory">*</span></label>
                  <input type="text" className="form-control form-control-sm" name="shiftName" id="shiftName"  placeholder='Enter Shift Name'  value={sendForm.shiftName} onChange={handleChange}/>
                  <small id="errShiftName" className="invalid-feedback" style={{ display: 'none' }}></small>
                </div>
                <div className="col-2 mb-2 me-2">
                  <label htmlFor="ShiftFrom" className="form-label">Shift From<span className="starMandatory">*</span></label>
                  <input type="time" className="form-control form-control-sm" name="shiftFrom" id="shiftFrom"  value={sendForm.shiftFrom} onChange={handleChange}/>
                  <small id="errShiftFrom" className="invalid-feedback" style={{ display: 'none' }}></small>

                </div>
                <div className="col-2 mb-2 me-2">
                  <label htmlFor="ShiftTo" className="form-label">Shift to<span className="starMandatory">*</span></label>
                  <input type="time" className="form-control form-control-sm" name="shiftTo" id="shiftTo" value={sendForm.shiftTo} onChange={handleChange}/>
                  <small id="errShiftTO" className="invalid-feedback" style={{ display: 'none' }}></small>

                </div>


                <div className="mb-2 relative">
                  <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                  <div>
                    {showUnderProcess === 1 ? <TosterUnderProcess /> :
                      <>
                        {showToster === 1 ?
                          <Toster value={tosterValue} message={tosterMessage} />

                          : <div>
                            {updateBool === 0 ?
                              <>
                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handlerSave}><img src={saveButtonIcon} className='icnn' />Save</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={handleClear}><img src={clearIcon} className='icnn' />Clear</button>
                              </>
                              :
                              <>
                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={handlerUpdate}>Update</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={handleClear}>Cancel</button>
                              </>
                            }
                          </div>}
                      </>
                    }
                  </div>
                </div>
              </BoxContainer>
            </div>
            <div className="col-12 mt-2">
              <div className='handlser'>
                <Heading text="Shift List" />
                <div style={{ position: 'relative' }}>
                  <input type="text" className='form-control form-control-sm' placeholder={t("Search")} onChange={handleSearch} />
                  <span className="tblsericon"><i className="fas fa-search"></i></span>
                </div>
              </div>
              <div className="med-table-section" style={{ "height": "75vh" }}>
                <TableContainer>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>Shift Name</th>
                      <th>Shift From</th>
                      <th>Shift To</th>
                      <th style={{ "width": "10%" }} className="text-center">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {shiftList && shiftList.map((val, ind) => {
                      return (
                        <tr key={val.id}>
                          <td className="text-center">{ind + 1}</td>
                          <td>{val.shiftName}</td>
                          <td>{val.shiftTimeFrom}</td>
                          <td>{val.shiftTimeTo}</td>
                          <td>
                            <div className="action-button">
                              <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={editBtnIcon} className='' alt='' onClick={() => {handleEdit(val)}} /></div>
                              <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowId(val.id) }} />
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}


                  </tbody>
                </TableContainer>
                {/* -----------------------Start Delete Modal Popup-------------------   */}

                {/*  <!-- Modal -->  */}
                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                  <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                      <div className="modal-body modelbdy text-center">
                        <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                        <div className='popDeleteTitle mt-3'>Delete?</div>
                       <div className='popDeleteContent'>Are you sure want to delete?</div>
                      </div>
                      <div className="modal-footer1 text-center">

                        <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn-delete popBtnDelete" onClick={handleDelete} data-bs-dismiss="modal">Delete</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

              </div>
            </div>
          </div>
        </div>
        {
                    showLoder === 1 ? <Loader val={showLoder} /> : ""
                }
                {/* Toaster */}
                {
                    isShowToaster === 1 ?
                        <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                }
               
                {
                    showAlertToster === 1 ?
                        <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                }
      </section>
    </>
  )
}
