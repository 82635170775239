import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import TableContainer from '../../../../src/Component/TableContainer';
import Heading from '../../../../src/Component/Heading';
import BoxContainer from '../../../../src/Component/BoxContainer';
import Toster from '../../../../src/Component/Toster'
import TosterUnderProcess from '../../../../src/Component/ToStartUnderProcess'
import IconEdit from '../../../assets/images/icons/IconEdit.svg'
import IconDelete from '../../../assets/images/icons/IconDelete.svg'
import clearIcon from '../../../assets/images/icons/clear.svg';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import GetAllEmployeeMaster from '../../API/EmployeeMaster/GetAllEmployeeMaster';
import i18n from "i18next";
import PostEmployeeMaster from '../../API/EmployeeMaster/PostEmployeeMaster';
import ValidationEmployeeMaster from '../../../Validation/HrService/ValidationEmployeeMaster';
import DeleteLeaveMaster from '../../API/DeleteLeaveMaster';
import PutLeaveMaster from '../../API/PutLeaveMaster';
import GetHRCategoryMaster from '../../API/GetHRCategoryMaster';
import GetDepartmentList from '../../../Registartion/API/GET/GetDepartmentList';
import GetDesignationMaster from '../../../SuperAdmin/Api/UtilityMaster/DesignationMaster/GetDesignationMaster';
export default function EmployeeMaster() {


  const [isHalfAllowed, setIsHalfAllowed] = useState(0);
  const { t } = useTranslation();
  let [showUnderProcess, setShowUnderProcess] = useState(0)
  let [showToster, setShowToster] = useState(0)
  let [tosterMessage, setTosterMessage] = useState("")
  let [tosterValue, setTosterValue] = useState(0)
  let [updateBool, setUpdateBool] = useState(0)
  let [isCarryForward, setIsCarryForward] = useState(0);
  let [isRh, setIsRh] = useState(0);
  let [isAnyTime, setIsAnyTime] = useState(0);
  let [leaveName, setLeaveName] = useState("");
  let [leaveShName, setLeaveShName] = useState("");
  let [rowId, setRowId] = useState('');
  let [allEmployeeMasterList, setAllEmployeeMasterList] = useState([]);
  let clientId = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
  let userId = JSON.parse(sessionStorage.getItem("LoginData")).userId;
  let [id, setId] = useState('');
  let [hRCategoryMasterList, setHRCategoryMasterList] = useState([]);
  let [departmentList, setDepartmentList] = useState([]);
  let [designationList, setDesignationList] = useState([]);
  let [empId, setEmpId] = useState('');
  let [punchId, setPunchId] = useState('');
  let [empName, setEmpName] = useState('');
  let [empCategoryId, setEmpCategoryId] = useState('');
  let [dateOfJoining, setDateOfJoining] = useState('');
  let [departmentId, setDepartmentId] = useState('');
  let [designationId, setDesignationId] = useState('');
  let [primaryMobile, setPrimaryMobile] = useState('');
  let [secondaryMobile, setSecondaryMobile] = useState('');
  let [emailId, setEmailId] = useState('');






  const getData = async () => {
    let data = await GetAllEmployeeMaster(clientId);
    let dataHRCategory = await GetHRCategoryMaster(clientId);
    let dataDepartment = await GetDepartmentList();
    let dataDesignation = await GetDesignationMaster(); 
    if (data.status === 1) {
      setAllEmployeeMasterList(data.responseValue);
    }
   
        if (dataHRCategory.status === 1) {
            setHRCategoryMasterList(dataHRCategory.responseValue);
        }

        if (dataDepartment.status === 1) {
          setDepartmentList(dataDepartment.responseValue);
      }

      if (dataDesignation.status === 1) {
        setDesignationList(dataDesignation.responseValue);
    }


  }


  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'empId') {
      setEmpId(value);
    }

    if (name === 'punchId') {
      setPunchId(value);
    }

    if (name === 'empName') {
      setEmpName(value);
    }

    if (name === 'empCategoryId') {
      setEmpCategoryId(value);
    }

    if (name === 'dateOfJoining') {
      setDateOfJoining(value);
    }
    if (name === 'departmentId') {
      setDepartmentId(value);
    }
    if (name === 'designationId') {
      setDesignationId(value);
    }
    if (name === 'primaryMobile') {
      setPrimaryMobile(value);
    }
    if (name === 'secondaryMobile') {
      setSecondaryMobile(value);
    }
    if (name === 'emailId') {
      setEmailId(value);
    }

  }

  let saveForm = async () => {

    const obj = {

      empId: empId,
      punchId: punchId,
      empName: empName,
      empCategoryId: empCategoryId,
      dateOfJoining: dateOfJoining,
      departmentId: departmentId,
      designationId: designationId,
      primaryMobile: primaryMobile,
      secondaryMobile: secondaryMobile,
      emailId: emailId,
      clientId: clientId,
      userId: userId

    }

    let valresponse = ValidationEmployeeMaster(empId, punchId, empName, empCategoryId, dateOfJoining, departmentId, designationId, primaryMobile)

    if (valresponse[0]) {
      setShowUnderProcess(1)
      let response = await PostEmployeeMaster(obj);

      if (response.status === 1) {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage("Data Saved SuccessFully!")
        setTosterValue(0)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
        getData()
       
      }
      else {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage(response.responseValue)
        setTosterValue(1)
        setTimeout(() => {
          setShowToster(0)

        }, 2000)
      }

    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage(valresponse[1])
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
    }
  }

  let handleDeleteRow = async () => {
    // setLoder(1)
    setShowUnderProcess(1)
    let obj = {
      id: rowId
    }
    let response = await DeleteLeaveMaster(obj)
    if (response.status === 1) {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage("Data Deleted SuccessFully!")
      setTosterValue(0)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
      getData()
    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage(response.responseValue)
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
    }
  }

  let handleUpdate = async (data) => {

    setUpdateBool(1)
    setId(data.id)
    document.getElementById("leaveName").value = data.leaveName;
    document.getElementById("leaveShName").value = data.leaveShortName;
    setIsHalfAllowed(data.isHalfAllowed)
    setIsCarryForward(data.isCarryForward)
    setIsRh(data.isRh)
    setIsAnyTime(data.isAnyTime)
    setLeaveName(data.leaveName)
    setLeaveShName(data.leaveShortName)

  }

  let saveUpdate = async () => {

    let obj = {
      id: id,
      leaveName: leaveName,
      leaveShortName: leaveShName,
      isHalfAllowed: isHalfAllowed,
      isCarryForward: isCarryForward,
      isRh: isRh,
      isAnyTime: isAnyTime,
      clientId: clientId,
      userId: userId,
    }

    console.log(obj)

    let valresponse = ValidationEmployeeMaster(leaveName, leaveShName)

    if (valresponse[0]) {
      setShowUnderProcess(1)
      let response = await PutLeaveMaster(obj)
      if (response.status === 1) {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage("Data Updated SuccessFully!")
        setTosterValue(0)
        setTimeout(() => {
          setShowToster(0)

        }, 2000)

        setUpdateBool(0)
        getData()
        handleClear()
      }
      else {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage(response.responseValue)
        setTosterValue(1)
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
      }

    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage(valresponse[1])
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
      }, 2000)
    }
  }

  const handleClear = () => {

    document.getElementById("leaveName").value = "";
    document.getElementById("leaveShName").value = "";
    setIsHalfAllowed(0);
    setIsCarryForward(0);
    setIsRh(0);
    setIsAnyTime(0);
    setLeaveName("");
    setLeaveShName("");

  }








  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Heading text={t("Employee Master")} />
              <BoxContainer>



                <div className="mb-2 me-2">
                  <label htmlFor="empId" className="form-label">{t("Employee Id")}<span className="starMandatory">*</span></label>
                  <input type='text' className='form-control form-control-sm' id='empId' name='empId' onChange={handleChange} placeholder={t("Enter Emp Id")} />
                </div>

                <div className="mb-2 me-2">
                  <label htmlFor="punchId" className="form-label">{t("Punch Id")}<span className="starMandatory">*</span></label>
                  <input type='text' className='form-control form-control-sm' id='punchId' name='punchId' onChange={handleChange} placeholder={t("Enter Punch Id")} />
                </div>

                <div className="mb-2 me-2">
                  <label htmlFor="empName" className="form-label">{t("Employee Name")}<span className="starMandatory">*</span></label>
                  <input type='text' className='form-control form-control-sm' id='empName' name='empName' onChange={handleChange} placeholder={t("Enter Employee Name")} />
                </div>


                <div className="mb-2 me-2">
                 
                  <label htmlFor="empCategoryId" className="form-label">{t("Employee Category")} <span className="starMandatory">*</span></label>
                  <select className="form-select form-select-sm" id="empCategoryId" name="empCategoryId" onChange={handleChange}>
                    <option value="0">{t("Select Category")}</option>
                    {hRCategoryMasterList && hRCategoryMasterList.map((val, index) => {
                      return (
                        <option value={val.id}>{val.categoryName}</option>
                      )
                    })}
                  </select>
                </div>

                <div className="mb-2 me-2">
                  <label htmlFor="dateOfJoining" className="form-label">{t("Date Of Joining")}<span className="starMandatory">*</span></label>
                  <input type='date' className='form-control form-control-sm' id='dateOfJoining' name='dateOfJoining' onChange={handleChange} placeholder={t("Enter DOJ")} />
                </div>

                <div className="mb-2 me-2">
                 
                 <label htmlFor="departmentId" className="form-label">{t("Department")} <span className="starMandatory">*</span></label>
                 <select className="form-select form-select-sm" id="departmentId" name="departmentId" onChange={handleChange}>
                   <option value="0">{t("Select Department")}</option>
                   {departmentList && departmentList.map((val, index) => {
                     return (
                       <option value={val.id}>{val.departmentName}</option>
                     )
                   })}
                 </select>
               </div>

               <div className="mb-2 me-2">
                 
                  <label htmlFor="designationId" className="form-label">{t("Designation")} <span className="starMandatory">*</span></label>
                  <select className="form-select form-select-sm" id="designationId" name="designationId" onChange={handleChange}>
                    <option value="0">{t("Select Designation")}</option>
                    {designationList && designationList.map((val, index) => {
                      return (
                        <option value={val.id}>{val.designationName}</option>
                      )
                    })}
                  </select>
                </div>

                <div className="mb-2 me-2">
                  <label htmlFor="primaryMobile" className="form-label">{t("Primary Mobile")}<span className="starMandatory">*</span></label>
                  <input type='number' className='form-control form-control-sm' id='primaryMobile' name='primaryMobile' onChange={handleChange} placeholder={t("Enter Primary Mobile")} />
                </div>

                <div className="mb-2 me-2">
                  <label htmlFor="secondaryMobile" className="form-label">{t("Secondary Mobile")}<span className="starMandatory"></span></label>
                  <input type='number' className='form-control form-control-sm' id='secondaryMobile' name='secondaryMobile' onChange={handleChange} placeholder={t("Enter Secondary Mobile")} />
                </div>

                <div className="mb-2 me-2">
                  <label htmlFor="emailId" className="form-label">{t("Email Id")}<span className="starMandatory"></span></label>
                  <input type='text' className='form-control form-control-sm' id='emailId' name='emailId' onChange={handleChange} placeholder={t("Enter Email Id")} />
                </div>




                <div className="mb-2 relative me-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                  <div>
                    {showUnderProcess === 1 ? <TosterUnderProcess /> :
                      <>
                        {showToster === 1 ?
                          <Toster value={tosterValue} message={tosterMessage} />

                          : <div>
                            {updateBool === 0 ?
                              <>
                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={saveForm}><img src={saveButtonIcon} className='icnn' />{t("Save")}</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' />{t("Clear")}</button>
                              </>
                              :
                              <>
                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={saveUpdate}>{t("Update")}</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { setUpdateBool(0); handleClear() }}>{t("Cancel")}</button>
                              </>
                            }
                          </div>}
                      </>
                    }
                  </div>
                </div>
              </BoxContainer>
            </div>
            <div className="col-12 mt-2">
              <Heading text={t("Employee Master List")} />
              <div className="med-table-section" style={{ "height": "75vh" }}>
                <TableContainer>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>{t("Employee Id")}</th>
                      <th>{t("Punch Id")}</th>
                      <th>{t("Employee Name")}</th>
                      <th>{t("Employee Category")}</th>
                      <th>{t("Date Of Joining")}</th>
                      <th>{t("Department")}</th>
                      <th>{t("Desination")}</th>
                      <th>{t("Primary Mobile")}</th>
                      <th>{t("Secondary Mobile")}</th>
                      <th>{t("Email Id")}</th>
                      <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {allEmployeeMasterList && allEmployeeMasterList.map((list, index) => {
                      return (
                        <tr>
                          <td className="text-center">{index + 1}</td>
                          <td>{list.empId}</td>
                          <td>{list.punchid}</td>
                          <td>{list.empName}</td>
                          <td>{list.categoryName}</td>
                          <td>{list.dateOfJoining}</td>
                          <td>{list.departmentId}</td>
                          <td>{list.designationId}</td>
                          <td>{list.primaryMobile}</td>
                          <td>{list.secondaryMobile}</td>
                          <td>{list.emailId}</td>
                          <td>
                            <div className="action-button">
                              <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={IconEdit} alt='' onClick={() => { handleUpdate(list, index) }} /></div>
                              <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={IconDelete} onClick={() => { setRowId(list.id) }} alt='' /></div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}

                  </tbody>
                </TableContainer>
                {/* -----------------------Start Delete Modal Popup-------------------   */}

                {/*  <!-- Modal -->  */}
                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                  <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                      <div className="modal-body modelbdy text-center">
                        <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                        <div className='popDeleteTitle mt-3'>{t("Delete?")}</div>
                        <div className='popDeleteContent'> {t("Are_you_sure_you_want_to_delete?")}</div>
                      </div>
                      <div className="modal-footer1 text-center">

                        <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                        <button type="button" className="btn-delete popBtnDelete" data-bs-dismiss="modal" onClick={handleDeleteRow} >{t("Delete")}</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

              </div>
            </div>
          </div>
        </div>

      </section>
    </>




  )
}
