async function GetInfusionAndFluidList(uhid) {
    //const url = "https://localhost:7225/api/PatietnFluid/GetFluidAndInfusionList?UHID="+uhid;
    const url = `${window.AppbaseUrl}/api/PatietnFluid/GetFluidAndInfusionList?UHID=${uhid}`;
    let head = { 'Content-Type': 'application/JSON', accept: '*/*' };
    let data = {};

    let response = await fetch(url, {
        headers: head,
        method: 'GET'
    }).then((res) => res.json()).then(data);
   console.log('response',response);
    return response;
}

export default GetInfusionAndFluidList;