import React from 'react'
import { onStart } from '../../../Code/DragDropData'
import upgraph from "../../../../assets/images/patientmonitordashboard/uparrow.png"
import downgraph from "../../../../assets/images/patientmonitordashboard/downgraph.png"
export default function AllMedication(props) {
    return (
        <>

            {
                props.patientData.MedicationsData !== null ?
                    <div key={props.key} className='d-flex flex-inline gap-1 wrap pb-1 pt-1' draggable="true" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} onDragStart={onStart}>
                        {props.patientData.MedicationsData !== null && props.patientData.MedicationsData !== undefined ?
                            <div className='d-flex flex-column mbc-05' style={{ overflow: "auto", maxHeight: "auto" }}>
                                {
                                    props.patientData.MedicationsData.map((val, ind) => {
                                        if(val.IsGraph === 1)
                                        {
                                            return (<div className='d-flex flex-row gap-1'><div className='list-commas fw-bold' title='Diagnosis'> {val.DrugName } </div> <img className='bg-white_' src={upgraph} width={15} height={15} style={{filter:'invert(1)'}}  alt=''/></div>)
                                        }
                                        else if(val.IsGraph === 2)
                                        {
                                            return (<div className='d-flex flex-row gap-1'><div className='list-commas fw-bold' title='Diagnosis'> {val.DrugName } </div><img className='bg-white_' src={downgraph} width={15} height={15}  style={{filter:'invert(1)'}} alt=''/></div>)
                                        }
                                        else{
                                            return (<div className='d-flex flex-row gap-1'><div className='list-commas fw-bold' title='Diagnosis'> {val.DrugName } </div></div>)
                                        }
                                    })
                                }
                            </div>
                            : "N/A"
                        }
                    </div> : "N/A"
            }
        </>

    )
}
