async function GetAllTestItems() {
  let url = window.BillingbaseUrl+"/api/ItemMaster/GetFixedItemsList?clientId="+window.clientId;

  let head = { "Content-Type": "application/JSON", accept : '*/*' };
  let data={};
let response = fetch(url, {
  headers: head,
  method : 'GET'
}).then((res) => res.json()).then(data);

return response;
}
export default GetAllTestItems;
