import React, { useEffect, useState } from 'react';
import GetActivityList from './API/GetActivityList';
import InsertPatientPhysicalActivity from './API/InsertPatientPhysicalActivity';
import Heading from '../../../../../Component/Heading';
import BoxContainer from '../../../../../Component/BoxContainer';
import DropdownWithSearch from '../../../../../Component/DropdownWithSearch';
import TosterUnderProcess from '../../../../../Component/TosterUnderProcess';
import Toster from '../../../../../Component/Toster';
import TableContainer from '../../../../../Component/TableContainer';
import { useTranslation } from 'react-i18next';
import saveButtonIcon from "../../../../../../src/assets/images/icons/saveButton.svg"
import clearIcon from "../../../../../../src/assets/images/icons/clear.svg"
import i18n from "i18next";
import GetAllPhysicalActivityTracker from './API/GetAllPhysicalActivityTracker';
import { createLogger } from '@microsoft/signalr/dist/esm/Utils';
import PutPhysicalActivityTracker from './API/PutPatientPhysicalActivity';
import DeletePatientPhysicalActivity from './API/DeletePatientPhysicalActivity';
import ValidationPhysicalActivity from './Validation/PhysicalActivityValiddation';


export default function PatientPhysicalActivity() {
    const [activityList, setActivityList] = useState([]);
    const [allPhysicalActivityTracker, setAllPhysicalActivityTracker] = useState();
    const [getTableData, setGetTableData] = useState([]);
    let [updateBool, setUpdateBool] = useState(0)
    let [activelistedit, setaActivelistedit] = useState("")
    let [sendForm, setSendForm] = useState({ "userId": window.userId, clientId: window.clientId, uhid: "", totalTimeInMinutes: "", timeFrom: "", remark: "", activityId: "" })
    let [fromDate, setfromDate] = useState("")
    const [currentTime, setCurrentTime] = useState('');
    let [toDate, settoDate] = useState("")
    let [totalTimeInMinutes, setTotalTimeInMinutes] = useState("")
    let [getUhid, setUhid] = useState(JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid);
    let [getClientId, setClientId] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientId);
    const [selectedDate, setSelectedDate] = useState(null);
    let [showUnderProcess, setShowUnderProcess] = useState(0)
    let [showToster, setShowToster] = useState(0)
    let [tosterMessage, setTosterMessage] = useState("")
    let [tosterValue, setTosterValue] = useState(0)
    let [clearDropdown, setClearDropdown] = useState(0)
    let [currentdatetime, setcurrentDatetime] = useState('')
    let [currenttime, setcurrenttime] = useState('');
    let [saveupdate, setsaveupdate] = useState(0);
    let [rowId, setrowId] = useState('');
    let [currentDate, setcurrentDate] = useState('');
    let [clearbtnsave, setclearbtnsave] = useState(0);
    const { t } = useTranslation();

    const getData = async () => {
        const activityListResp = await GetActivityList();
        const allPhysicalActivityTrackerResp = await GetAllPhysicalActivityTracker(getUhid);

        if (activityListResp.status === 1) {
            setActivityList(activityListResp.responseValue);
            //setAllPhysicalActivityTracker(allPhysicalActivityTrackerResp.responseValue);

        }
        if (allPhysicalActivityTrackerResp.status === 1) {
            setAllPhysicalActivityTracker(allPhysicalActivityTrackerResp.responseValue);
        }

    };
    //Handle Save
    let saveForm = async () => {
    
        let res = ValidationPhysicalActivity(sendForm.activityId, sendForm.totalTimeInMinutes, currentDate, currentTime, sendForm.remark)

        let valresponse = res[2]
        if (valresponse) {
            setShowUnderProcess(1)
            let response = await InsertPatientPhysicalActivity(sendForm, fromDate, getUhid, getClientId);
            if (response.status === 1) {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage("Data Save SuccessFully!")
                setTosterValue(0)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)

                handleClear(1);
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage(response.responseValue)
                setTosterValue(1)

                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }
            getData()
        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Field can't be blank!")
            setTosterValue(1)
            document.getElementById(res[1]).style.display = "block";
            document.getElementById(res[1]).innerText = res[0];
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }

        // console.log("date time:", formattedDate)
    }
    //Handle Clear
    let handleClear = (val) => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const time = `${hours}:${minutes}`;
        document.getElementById('fromDateTime').value = '';
        document.getElementById('fromTime').value = '';
        document.getElementById('totaltimeinMinutes').value = '';
        document.getElementById('remark').value = '';
        setfromDate("")
        setClearDropdown(val);
        setaActivelistedit("");
        setcurrentDate('');
        setCurrentTime(time);

        setSendForm({ "userId": window.userId })
        //document.getElementById("code").value = "";
        setUpdateBool(0)
        setclearbtnsave(1);
        setsaveupdate(0)
    }
    let handlekeydown = (event) => {
        // Check if the pressed key is a valid digit or a minus sign
        const isValidKey = /^\d$/.test(event.key) || (event.key === '-' && event.target.selectionStart === 0);
        const isBackspace = event.key === 'Backspace';
        // If the pressed key is not valid, prevent the default behavior
        if (!isValidKey && !isBackspace) {
            event.preventDefault();
        }
    }
    //Handle Change
    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setaActivelistedit("")
        document.getElementById("errActivity").style.display = "none"
        document.getElementById("errDate").style.display = "none"
        document.getElementById("errTime").style.display = "none"
        document.getElementById("errMinutes").style.display = "none"
        document.getElementById("errRemarks").style.display = "none"
        setSendForm(sendForm => ({
            ...sendForm,
            [name]: value
        }))
    }
    let handleDateChange = (e) => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const time = `${hours}:${minutes}`;

        const { name, value } = e.target;
        const datevalue = document.getElementById('fromDateTime').value;
        const timevalue = document.getElementById('fromTime').value;
        if (name === 'fromDateTime') {
            // console.log("inputdatetime-------",value);
            setcurrentDate(value);
            document.getElementById("errDate").style.display = "none"

            document.getElementById('fromDateTime').setAttribute('max', currentdatetime);
        }
        if (name === 'fromTime') {
            document.getElementById("errTime").style.display = "none"

            if (value > time) {
                return false
            }
            else {
                // setfromDate(datevalue);
                setCurrentTime(value);
            }

        }
        setfromDate(datevalue + " " + timevalue);
    }
    const initializeCurrentDatetime = () => {
        // const currentDate = new Date().toDateString().slice(0, 16); 
        const currentDate = new Date().toISOString().split('T')[0];

        console.log("ppppp", currentDate, currenttime)
        // console.log('current date time----',currentDate)
        setcurrentDatetime(currentDate);

    };
    // const initializeCurrenttime = () => {
    //     const input = document.getElementById('fromTime');

    //     if (input) {
    //         const now = new Date();
    //         const hours = now.getHours().toString().padStart(2, '0'); // Ensure two digits
    //         const minutes = now.getMinutes().toString().padStart(2, '0'); // Ensure two digits

    //         const currentTime = `${hours}:${minutes}`;
    //         input.setAttribute('max', currentTime);
    //     }
    // };
    let handleedit = (val) => {
        // const allPhysicalActivityTrackerdata=allPhysicalActivityTracker[ind]
        // document.getElementById('activityId').getvalue=allPhysicalActivityTrackerdata.activityId;

        // document.getElementsByName('activityId')= allPhysicalActivityTrackerdata.activityName;
        setcurrentDate(val.timeFrom.slice(0, 10));
        setCurrentTime(val.timeFrom.slice(11, 16));
        setfromDate(val.timeFrom);
        // setSendForm({totalTimeInMinutes:allPhysicalActivityTrackerdata.totalTimeInMinutes})
        // document.getElementById('fromDateTime').value = allPhysicalActivityTrackerdata.timeFrom.slice(0,10); // Assuming 'fromDateTime' corresponds to the 'timeFrom' field
        // document.getElementById('fromTime').value = ; // Assuming 'fromTime' corresponds to the 'timeTo' field
        // document.getElementById('totaltimeinMinutes').value = allPhysicalActivityTrackerdata.totalTimeInMinutes; // Assuming 'toDateTime' corresponds to the 'totalTimeInMinutes' field
        document.getElementById('remark').value = val.remark;
        setaActivelistedit(val.activityName);
        setSendForm(val)

        setsaveupdate(1);
        setclearbtnsave(0);
    }
    let handleUpdate = async (val) => {
        // setSendForm({
        //     Id:154,
        //     activityId:activelistedit,
        //     timeFrom:fromDate,
        //     totalTimeInMinute:sendForm.totalTimeInMinutes,
        //     remark:sendForm.remark,

        // });
        let res = ValidationPhysicalActivity(sendForm.activityId, sendForm.totalTimeInMinutes, currentDate, currentTime, sendForm.remark)

        let valresponse = res[2]

        let tempForm = {
            Id: sendForm.id,
            activityId: sendForm.activityId,
            timeFrom: fromDate,
            totalTimeInMinutes: sendForm.totalTimeInMinutes,
            remark: sendForm.remark,
        }
        console.log("update data", tempForm);
        if(valresponse){
            setShowUnderProcess(1)
           let response= await PutPhysicalActivityTracker(tempForm);
           if (response.status === 1) {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Data Updated SuccessFully!")
            setTosterValue(0)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)

            handleClear(1);
        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage(response.responseValue)
            setTosterValue(1)

            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }

        }
        else{
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Field can't be blank!")
            setTosterValue(1)
            document.getElementById(res[1]).style.display = "block";
            document.getElementById(res[1]).innerText = res[0];
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
        getData();
       
    }
    let handleDeleteRow = async () => {
        // setLoder(1)
        setShowUnderProcess(1);


        let response = await DeletePatientPhysicalActivity(rowId)
        if (response.status === 1) {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Data Deleted SuccessFully!")
            setTosterValue(0)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
            getData();
            handleClear(1);
        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage(response.responseValue)
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }
    useEffect(() => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const time = `${hours}:${minutes}`;
        setCurrentTime(time);
        document.getElementById('fromTime').value = time;
        // const currenttime = new Date().toLocaleTimeString('en-US', { hour12: false }).slice(0, 5); 
        // setcurrenttime(currenttime);


    }, []);
    
    useEffect(() => {
        getData();
        initializeCurrentDatetime();
        // initializeCurrenttime();

    }, []);
    return (
        <>

            <div className="container-fluid">
                <div className="row">
                    <div className='col-md-12'>
                        <Heading text="Patient Physical Activity" />
                    </div>
                </div>

                <div className='med-box'>
                    <div className='inner-content'>
                        <div className='row m-0'>
                            <div className='col-md-2 mb-2'>
                                <label htmlFor="activityId" className="form-label">Activity List</label>
                                <div>
                                    {activityList && <DropdownWithSearch defaulNname="Select activity" name="activityId" list={activityList} valueName="id" displayName="activityName" editdata={activelistedit} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                </div>
                                <small id="errActivity" className="form-text text-danger" style={{ display: "none" }}>
                                </small>

                            </div>

                            <div className='col-md-2 mb-2'>
                                <label for="fromDateTime" className="form-label"> From Date   </label>
                                <input type="date" className="form-control form-control-sm" id="fromDateTime" placeholder="Enter Start Date and Time" name="fromDateTime" value={currentDate} onChange={handleDateChange} max={currentdatetime} />
                                <small id="errDate" className="form-text text-danger" style={{ display: "none" }}>
                                </small>
                            </div>
                            <div className='col-md-2 mb-2'>
                                <label for="fromDateTime" className="form-label"> From Time  </label>
                                <input
                                    type="time"
                                    className="form-control form-control-sm"
                                    id="fromTime"
                                    placeholder="Enter Start Date and Time"
                                    name="fromTime"
                                    value={currentTime}
                                    max={currentTime}
                                    onChange={handleDateChange} />
                                <small id="errTime" className="form-text text-danger" style={{ display: "none" }}>
                                </small>
                            </div>
                            <div className='col-md-2 mb-2'>
                                <label for="totalTimeInMinutes" className="form-label">Total time In Minutes </label>
                                <input type="text" className="form-control form-control-sm" id="totaltimeinMinutes" value={sendForm.totalTimeInMinutes} placeholder="Enter Total Time In Minutes" name="totalTimeInMinutes" onKeyDown={handlekeydown} onChange={handleChange} />
                                <small id="errMinutes" className="form-text text-danger" style={{ display: "none" }}>
                                </small>

                            </div>

                            {/* <div className='col-md-2 mb-2'>
                                <label for="toDateTime" className="form-label"> To Date Time </label>
                                <input type="datetime-local" className="form-control form-control-sm" id="toDateTime" placeholder="Enter Bed Name" name="toDateTime" onChange={handleDateChange}/>

                            </div> */}

                            <div className='col-md-2 mb-2'>
                                <label htmlFor="remark" className="form-label">Remark </label>
                                <input type="text" className="form-control form-control-sm" id="remark" name='remark' onChange={handleChange} placeholder="Enter Remark" />
                                <small id="errRemarks" className="form-text text-danger" style={{ display: "none" }}>
                                </small>
                            </div>

                            <div className='col-md-2 mb-2'>
                                <div className="relative">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                    <div>
                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />

                                                    : <div>

                                                        <> {saveupdate === 0 || clearbtnsave === 1 ? <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={saveForm}><img src={saveButtonIcon} className='icnn' alt='' />{t("Save")}</button> : <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleUpdate}><img src={saveButtonIcon} className='icnn' alt=''
                                                        />{t("Update")}</button>}
                                                            <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={() => { handleClear(1) }}><img src={clearIcon} className='icnn' alt='' />{t("Clear")}</button>
                                                        </>


                                                    </div>}

                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12">
                        <div className='handlser'>
                            <Heading text="Patient Physical Activity List" />
                            {/* <div style={{ position: 'relative' }}>
                            <input type="text" className='form-control form-control-sm' placeholder={t("Search")} onChange={handleSearch} />
                            <span className="tblsericon"><i className="fas fa-search"></i></span>
                        </div> */}
                        </div>
                        <div className="med-table-section" style={{ height: "calc(100vh - 290px)" }}>
                            <TableContainer>
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{ "width": "5%" }}>#</th>
                                        <th>Activity Name</th>
                                        <th>From Date Time</th>
                                        <th>To Date Time</th>
                                        <th>Total Time in Minutes</th>
                                        <th>Given By</th>
                                        <th>Remark</th>
                                        <th>Action</th>

                                    </tr>
                                </thead>

                                <tbody>
                                    {allPhysicalActivityTracker && allPhysicalActivityTracker.map((val, ind) => {

                                        return (
                                            <tr key={val.id}>
                                                <td className="text-center">{ind + 1}</td>
                                                <td>{val.activityName}</td>
                                                <td>{val.timeFrom}</td>
                                                <td>{val.timeTo}</td>
                                                <td>{val.totalTimeInMinutes}</td>
                                                <td>{val.name}</td>
                                                <td>{val.remark}</td>
                                                <td>
                                                    <div className="action-button" style={{ justifyContent: 'left' }}>
                                                        <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom" onClick={() => handleedit(val)}><i className="fa fa-edit actionedit" ></i></div>
                                                        <div data-bs-toggle="modal" data-bs-title="Delete Row" onClick={() => { setrowId(val.id) }} data-bs-placement="bottom" data-bs-target="#deleteModal"><i className="fa fa-trash actiondel" ></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </TableContainer>

                        </div>

                    </div>
                </div>





            </div>

            {/*  <!------------------- Start Delete Modal ---------------------------------->  */}
            <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                        <div className="modal-body modelbdy text-center">
                            <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                            <div className='popDeleteTitle mt-3'> {t("Delete?")}</div>
                            <div className='popDeleteContent'>{t("Are_you_sure_you_want_to_delete?")}</div>
                        </div>
                        <div className="modal-footer1 text-center">

                            <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                            <button type="button" className="btn-delete popBtnDelete" onClick={handleDeleteRow} data-bs-dismiss="modal">{t("Delete")}</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}


        </>
    );
}
