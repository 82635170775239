
async function DeleteVisionPrescreption(data) {

    let url = window.AppbaseUrl + '/api/VisionPrescription/DeleteVisionPrescription?uhid='+data.uhid+'&id='+data.id+'&userId='+data.userId+'&clientId='+data.clientId;
    let head = {
      'Content-Type': 'application/JSON',
      accept: '*/*',
    }
    let response =
      await fetch(url, {
        method: 'DELETE',
        headers: head,
        body: JSON.stringify(data)
      })
        .then((res) => res.json())
        .then(data)
  
  
    return response;
  }
  export default DeleteVisionPrescreption;


