import React, { useEffect, useState } from 'react'
import clearIcon from '../../../assets/images/icons/clear.svg';
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Toster from '../../../Component/Toster';
import Loader from '../../../Component/Loader';
import GetGodownMaster from '../../Purchase/API/GetGodownMaster';
import GetGodownStockByGodownId from '../../CurrentStock/API/GetGodownStockByGodownId';

export default function StockDetails() {
    const [showLoder, setShowLoder] = useState(0);
    const [godownName, setgodownName] = useState('');
  const [showUnderProcess, setShowUnderProcess] = useState(0);
  const [showToster, setShowToster] = useState(0);
  const [tosterValue, setTosterValue] = useState(0);
  const [tosterMessage, setTosterMessage] = useState("");
  const [GodownList, setGodownList] = useState([]);
  const [StockList, setStockList] = useState([]);

    const handleOnChange= async(e)=>{
        const {name , value } = e.target
if(name === "godown"){
setgodownName(value)
let response = await GetGodownStockByGodownId(value);
if (response.status === 1) {
  setStockList(response.responseValue)
  console.log(response.responseValue)
}
}
    }

    const getGodownList = async()=>{
        let data = await GetGodownMaster();
        if(data.status === 1){
            console.log("godwon" , data.responseValue)
            setGodownList(data.responseValue)
        }
    }

    const getGodownStockByGodownId = async () => {
 
          let response = await GetGodownStockByGodownId(godownName);
          if (response.status === 1) {
            setStockList(response.responseValue)
            console.log(response.responseValue)
          }
          
      }
      const handleClear=()=>{
        setgodownName("")
        setStockList([])
        
      }

    useEffect(() => {
        getGodownList()
        getGodownStockByGodownId()
    }, [])
  return (
 <>
   <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
          <div className="col-12"><div className="med-box  mb-1"><div className="title">Stock Details</div></div></div>
            <div className="col-12">
              <div className="med-box">


                <div className="inner-content">
                <div className='fieldsett-in col-md-12'>
                <div className='fieldsett'>
                  <span className='fieldse'>Stock Details</span>

                  <div className='row'>

                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                    <label for="PaymentMode" className="form-label">Godown<span className="starMandatory">*</span></label>
                    <select id="Payment"className="form-control form-control-sm" name='godown' value={godownName} onChange={handleOnChange}>

                        <option value="0">Select Godown</option>
                        {GodownList && GodownList.map((val , ind)=>{
                            return (
                                 <option value={val.id}>{val.godown}</option>
                            )
                            
                           
                        })}
                    </select>
                   
                    </div>


                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 mb-3 relative">
                      <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>

                      {showUnderProcess === 1 ? <><TosterUnderProcess />  </> :
                        showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} />
                          :
                          <div>
                      
                              <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={getGodownStockByGodownId} ><i className="bi bi-search mx-2"></i>Search</button>
                              <button type="button" className="btn btn-clear btn-sm mb-1 me-1"  onClick={handleClear}><img src={clearIcon} className='icnn' alt="" />Clear</button>
                   
                          </div>
                      }
                    </div>
                  </div>
                  </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-12 mt-3">
              <div className="med-table-section" style={{ "height": "80vh" }}>
                <table className="med-table border_ striped">
                  <thead style={{ zIndex: '0' }}>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>Product Name</th>
                      <th>Batch No.</th>
                      <th>In Stock</th>
                      <th>Expiry Date</th>
                      <th>Expiry</th>
                      <th>Price/Unit</th>
                    </tr>
                  </thead>
                  <tbody>
                  
                
             {StockList && StockList.map((data,index)=>{
              return(
                <tr >
               <td className="text-center">{index + 1}</td>
                <td>{data.brandName}</td>
                <td>{data.batchNo}</td>
                <td>{data.stockStatus}</td>
                <td>{data.batchExpiry}</td>
                <td>{data.daysLeftToExpiry}</td>
                <td>{data.baseUnitPrice}/Kg</td>
             
              </tr>
              )
             })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* -----------------------Start Delete Modal Popup-------------------    */}

    
        {/* -----------------------End Delete Modal Popup---------------------  */}
        {
          showLoder === 1 ? <Loader val={showLoder} /> : ""
        }
      </section>
 
 </>
  )
}
