

async function GetStockInReport(ClientID,FromDate,ToDate,PoNo,BillNo,SupplierID,GodownID) {
    // let token = 'bearer ' + window.AppToken;
    let url = window.InventoryBaseUrl + `/api/StockIn/GetStockInReport?ClientID=${ClientID}&FromDate=${FromDate}&ToDate=${ToDate}&PoNo=${PoNo}&BillNo=${BillNo}&SupplierID=${SupplierID}&GodownID=${GodownID}`;
    let head = {
        "Content-Type": "application/JSON", accept: '*/*'
        // , 'Authorization': token
    };

    let response = await fetch(url, {
        headers: head,
        method: 'GET'
    })
        .then((res) => res.json())
        .then();

    return response;
}
export default GetStockInReport;

