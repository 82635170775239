import React, { useRef } from 'react'
import ExistingComplain from "../../assets/images/OPD/existingComplain.svg"
import { useState } from 'react'
import Search, { FindByQuery, SearchIndex } from '../../Code/Serach'
import GetProblemList from '../../Clinical/API/OPD/Prescription/KnowMedsAPI/GetProblemList'
import { useEffect } from 'react'
import DownKey, { UpKey } from "../../Code/ListSelect";
import { t } from "i18next";
import AlertToster from '../../Component/AlertToster'
import GetSymtoms from './API/GetSymtoms'
import InsertSymtoms from './API/InsertSymtoms'


export default function ChiefCaomplainComponent({ saveSymtoms = 0, setSaveSymtoms }) {
    let [consultantData, setConsultantData] = useState([])
    let [showSearchBoxProblemConsultant, setShowSearchBoxProblemConsultant] = useState(-1)
    let [showSearchBoxProblem, setShowSearchBoxProblem] = useState(-1)


    let [problemList, setProblemList] = useState([])
    let [problemListTemp, setProblemListTemp] = useState([])

    let [sendData, setSendData] = useState([])
    let [symptomsData, setSymptomsData] = useState([])
    // let [showDignosis, setShowDignosis] = useState(1)
    // let [symptomsPopUp, setSymptomsPopUp] = useState(0)
    // let [allergiesPopUP, setAllergiesPopUP] = useState(0)
    // let [consultantPopUP, setConsultantPopUP] = useState(0)
    // let [diagnosisSuggestionPopUP, setDiagnosisSuggestionPopUP] = useState(0)
    // let [showAntibiogram, setShowAntibiogram] = useState(0)
    let [showAlert, setShowAlert] = useState(0)
    let [alertmsg, setAlertmsg] = useState("")

    let row = { "detailID": 0, "details": "", "detailsDate": new Date().toISOString().split('T')[0] }
    let liSelected = useRef()
    let index = useRef(-1)
    let next = useRef()
    let oldData = useRef(0)

    let activeUHID = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : []

    // let setData = () => {
    //     setSymptomsData([])
    //     setConsultantData([])
    //     setSendData([])

    //     let temp = window.sessionStorage.getItem("IPDpatientsendData") ? JSON.parse(window.sessionStorage.getItem("IPDpatientsendData")) : []
    //     let activeUHID = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : []
    //     let symptomsDatas = []
    //     let consultantDatas = []

    //     temp.map((value, index) => {
    //         value.map((val, ind) => {
    //             if (value[0] === activeUHID) {
    //                 let key = Object.keys(val)
    //                 if (key[0] === "jsonDiagnosis") {
    //                     if (val.jsonDiagnosis[0] != null) {
    //                         val.jsonDiagnosis.map((val, ind) => {

    //                             if (val != null) {
    //                                 if (val.detailsDate === 2) {
    //                                     setSymptomsData([...symptomsDatas, val])
    //                                     symptomsDatas.push(val)
    //                                 }
    //                                 if (val.detailsDate === 4) {
    //                                     setConsultantData([...consultantDatas, val])
    //                                     consultantDatas.push(val)
    //                                 }
    //                             }
    //                         })
    //                     }
    //                 }
    //             }

    //         })

    //     })
    //     setSendData([...symptomsDatas, ...consultantDatas])

    //     symptomsDatas = []
    //     consultantDatas = []
    // }



    let handleSearch = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        // let data = [...sendData].filter(n => n)


        if (value != "") {
            if (name === "symptomsData") {
                let response = FindByQuery(problemList, value, "problemName")
                if (response.length != 0) {
                    setProblemListTemp(response)

                    setShowSearchBoxProblem(1)
                }
                else {
                    setShowSearchBoxProblem(-1)
                }

            }
            else if (name === "consultantData") {
                let response = FindByQuery(problemList, value, "details")
                if (response.length != 0) {
                    setProblemListTemp(response)

                    setShowSearchBoxProblemConsultant(1)
                }
                else {
                    setShowSearchBoxProblemConsultant(-1)
                }

            }

        }
        else {
            setShowSearchBoxProblem(-1)
            setShowSearchBoxProblemConsultant(-1)
        }

    }


    let handleKeyPress = (e) => {
        let value = e.target.value;
        let name = e.target.name
        let ul = ""
        if (e.keyCode === 13) {
            let data = [...sendData].filter(n => n)

            if (name === "symptomsData") {
                if (showSearchBoxProblem === -1) {
                    row["detailID"] = 0
                    row["details"] = value
                    row["detailsDate"] = new Date().toISOString().split('T')[0]
                }
                else {
                    ul = document.getElementById('symptomsDataList');
                    row["detailID"] = ul.getElementsByTagName('li')[index.current].value
                    row["details"] = ul.getElementsByTagName('li')[index.current].innerText
                    row["detailsDate"] = new Date().toISOString().split('T')[0]
                    setShowSearchBoxProblem(-1)
                    liSelected.current = ""
                    index.current = -1
                    next.current = ""
                    oldData.current = 0
                }
                setSymptomsData([...symptomsData, row])
                setSendData([...sendData, row])

                setSendData([...data, row])
                // SaveIPDData([...data, row], "jsonDiagnosis")

                document.getElementById(name).value = ""
                document.getElementById(name).focus()
            }
            else if (name === "consultantData") {
                if (showSearchBoxProblemConsultant === -1) {
                    row["detailID"] = 0
                    row["details"] = value
                }
                else {
                    ul = document.getElementById('consultantDataList');
                    row["detailID"] = ""
                    row["details"] = value
                    setShowSearchBoxProblemConsultant(-1)
                    liSelected.current = ""
                    index.current = -1
                    next.current = ""
                    oldData.current = 0
                }
                setConsultantData([...consultantData, row])
                setSendData([...sendData, row])

                setSendData([...data, row])
                // SaveIPDData([...data, row], "jsonDiagnosis")
                document.getElementById(name).value = ""
                document.getElementById(name).focus()
            }

        }

        else if (e.keyCode === 40) {
            // down
            if (name === "symptomsData") {
                if (showSearchBoxProblem !== -1) {
                    ul = document.getElementById('symptomsDataList');
                    DownKey(ul, liSelected, index, next, oldData)
                }
            }
            else if (name === "consultantData") {
                if (showSearchBoxProblemConsultant !== -1) {
                    ul = document.getElementById('consultantDataList');
                    DownKey(ul, liSelected, index, next, oldData)
                }

            }

        }

        else if (e.keyCode === 38) {
            // Up
            if (name === "consultantData") {
                if (showSearchBoxProblem !== -1) {
                    ul = document.getElementById('symptomsDataList');
                    UpKey(ul, liSelected, index, next, oldData)

                }
            }
            else if (name === "consultantData") {
                if (showSearchBoxProblemConsultant !== -1) {
                    ul = document.getElementById('consultantDataList');
                    UpKey(ul, liSelected, index, next, oldData)
                }

            }

        }
    }



    let handleClick = (boxname, id, name) => {
        let data = [...sendData].filter(n => n)
        if (boxname === "symptomsData") {
            let t = 0
            row["detailID"] = id
            row["details"] = name
            row["detailsDate"] = new Date().toISOString().split('T')[0]

            setShowSearchBoxProblem(-1)
            setSendData([...consultantData, row])
            let flag = 0
            symptomsData.map((v, i) => {
                if (v.detailID === id) {
                    flag = 1
                    return
                }
            })
            if (flag === 0) {
                setSendData([...data, row])
                // SaveIPDData([...data, row], "jsonDiagnosis")

                setSymptomsData([...symptomsData, row])
            }
            let r = SearchIndex(problemList, "problemName", name)
            let tt = [...problemList]
            tt.splice(r, 1)
            setProblemList(tt)


            let temp = [...data]
            data.map((val, ind) => {
                if (val.detailsDate === 2 && val.detailID === 0) {
                    delete temp[ind]
                }
            })
            setSendData([...temp.filter(n => n), row])
            // SaveIPDData([...temp.filter(n => n), row], "jsonDiagnosis")

            document.getElementById("symptomsData").focus()

            document.getElementById("symptomsData").value = "";

        }
        else if (boxname === "consultantData") {
            let t = 0
            row["detailID"] = id
            row["details"] = name
            row["detailsDate"] = 4
            setShowSearchBoxProblemConsultant(-1)
            setSendData([...consultantData, row])

            let flag = 0
            symptomsData.map((v, i) => {
                if (v.detailID === id) {
                    flag = 1
                    return
                }
            })
            if (flag === 0) {
                setSendData([...data, row])
                // SaveIPDData([...data, row], "jsonDiagnosis")

                setConsultantData([...consultantData, row])
                setSendData([...consultantData, row])
            }
            let r = SearchIndex(problemList, "problemName", name)
            let tt = [...problemList]
            tt.splice(r, 1)
            setProblemList(tt)

            let temp = [...data]
            data.map((val, ind) => {
                if (val.detailsDate === 4 && val.detailID === 0) {
                    delete temp[ind]
                }
            })
            // getDataFood([...consultantData, row])
            document.getElementById("consultantData").focus();

            setSendData([...temp.filter(n => n), row])
            // SaveIPDData([...temp.filter(n => n), row], "jsonDiagnosis")

            document.getElementById("consultantData").value = "";
        }
    }


    let handleRemove = (ind, detailID, name, details) => {
        let tempsymptomsData = [...symptomsData]
        let tempconsultantData = [...consultantData]
        let tempSenddata = [...sendData]

        if (name === "symptomsData") {
            sendData.map((val, index) => {
                if (val.detailID === detailID && val.details === details) {
                    tempSenddata.splice(index, 1)
                    return
                }
            })
            console.log("ind", ind)
            tempsymptomsData.splice(ind, 1)
        }
        else if (name === "consultantData") {
            sendData.map((val, index) => {
                if (val.detailsDate === 4 && val.detailID === detailID && val.details === details) {
                    tempSenddata.splice(index, 1)
                    return
                }
            })
            tempconsultantData.splice(ind, 1)
        }

        setSymptomsData(tempsymptomsData)
        setConsultantData(tempconsultantData)
        setSendData(tempSenddata)
        // SaveIPDData(tempSenddata, "jsonDiagnosis")
    }

    let getdata = async () => {
        let response = await GetProblemList()
        let data = await GetSymtoms(activeUHID)
        if (data.status === 1) {
            let modifydata = data.responseValue.map(val => { return { "detailID": val.pmID, "details": val.details, "detailsDate": val.detailsDate } })
            setSendData(modifydata)
            setSymptomsData(modifydata)
        }
        if (response.status === 1) {
            setProblemList(response.responseValue, data.responseValue)
            setProblemListTemp(response.responseValue, data.responseValue)
        }
    }
    let saveDiagnosisList = async () => {
        // sendData.map(val => val["isProvisionalDiagnosis"] = document.getElementById("provisionalDiagnosis").checked)
        // console.log(sendData)


        let resp = await InsertSymtoms(activeUHID, JSON.stringify(sendData))
        if (resp.status === 1) {
            console.log("success")
        }
        else {
            setShowAlert(1)
            console.log("cnsdac", resp)
            setAlertmsg(resp.responseValue)
        }
    }
    useEffect(() => {
        if (saveSymtoms !== 1) {

            getdata()
        }
        else {
            saveDiagnosisList()
            setSaveSymtoms(0)
        }
    }, [saveSymtoms])



    return (
        <>
            <div className='row p-0 m-0 opd-prescription-box'>
                <div className='d-none m-0  col-sm-3 col-12 img-text-box-back-opd pdd1'>
                    <div className='diagImg d-flex flex-row h-100 justify-content-center align-items-center'>

                        {/* <img src={ExistingComplain} className='compcon' alt='' /> */}
                        <label className='cheifc'>Chief Complaints</label>
                    </div>
                </div>
                <div className='p-2 m-0 col-sm-9 col-12'>

                    <input autoComplete="off" type="text" className='text-box-opd ' placeholder={t('ENTER_CHIEF_COMPLAINTS')} name="symptomsData" id="symptomsData" onKeyDown={handleKeyPress} onChange={(e) => { handleSearch(e) }} />
                    {showSearchBoxProblem === 1 ?
                        <div id="symptomsDataListdiv" className='position-absolute opdmedicationsearchbox'>
                            <ul id="symptomsDataList">
                                {problemListTemp && problemListTemp.map((val, ind) => {
                                    return (
                                        [6, 7].map((id, index) => {
                                            if (val.problemTypeID === id) {
                                                return (

                                                    <li className='pointer' onClick={(e) => { handleClick("symptomsData", val.id, val.problemName) }}>{val.problemName}</li>
                                                )
                                            }
                                        })
                                    )
                                })}
                            </ul>
                            <div className='full-screen-div-opd' onClick={() => { setShowSearchBoxProblem(-1) }}></div>

                        </div>
                        : ""}

                    <div className='d-flex flex-wrap gap-2' style={{ overflowX: "auto", height: '65px' }}>

                        {
                            symptomsData && symptomsData.map((val, ind) => {
                                return (
                                    <div className='d-flex flex-row justify-content-center align-items-center gap-2 pe-1 opdcancletab'>
                                        <label className=''>{val.details[0].toUpperCase() + val.details.slice(1, val.details.length).toLowerCase()}</label>
                                        <i className="fa-solid fa-xmark" onClick={() => { handleRemove(ind, val.detailID, "symptomsData", val.details) }}></i>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>



            </div>
            {
                showAlert === 1 ? <AlertToster handle={setShowAlert} message={alertmsg} /> : ""
            }
        </>
    )
}
