function ValidationEmployeeMaster(empId = "", punchId = "", empName='', empCategoryId='', dateOfJoining='', departmentId='', designationId='', primaryMobile='') {
    if (empId && punchId && empName && empCategoryId && dateOfJoining && departmentId && designationId && primaryMobile) {
        return [true, ""];
    }

    else if (!empId || !empId.trim()) {
        return [false, "Employee Id field can't be blank"];
    }

    else if (!punchId || !punchId.trim()) {
        return [false, "PunchId field can't be blank"];
    }
    else if (!empName || !empName.trim()) {
        return [false, "Employee Name field can't be blank"];
    }
    else if (!empCategoryId || !empCategoryId.trim()) {
        return [false, "Emp Category Id field can't be blank"];
    }
    else if (!dateOfJoining || !dateOfJoining.trim()) {
        return [false, " Date Of Joining field can't be blank"];
    }
    else if (!departmentId || !departmentId.trim()) {
        return [false, " Department Id field can't be blank"];
    }
    else if (!designationId || !designationId.trim()) {
        return [false, " designation Id field can't be blank"];
    }
    else if (!primaryMobile || !primaryMobile.trim()) {
        return [false, " Primary Mobile field can't be blank"];
    }
}

export default ValidationEmployeeMaster;