async function InsertPatientAllergies(uhId, allergies) {
      let url = window.AppbaseUrl + `/api/PatientInitialAssessment/InsertPatientAllergies?clientId=${window.clientId}&userId=${window.userId}&uhId=${uhId}&allergies=${allergies}`;
    // let url = `https://localhost:7225/api/PatientInitialAssessment/InsertPatientAllergies`;
      let head = {
        'Content-Type': 'application/JSON',
        accept: '*/*',
      }
      let response =
        await fetch(url, {
          method: 'POST',
          headers: head,
          body: JSON.stringify({})
        })
          .then((res) => res.json())
    
    
      return response;
    }
    export default InsertPatientAllergies;