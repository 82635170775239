import React, { useEffect, useState } from 'react'
import viewicn from '../../../../../../assets/images/PatientListIcons/viewicn.svg'
import iconSave from '../../../../../../assets/images/icons/save.svg'
import iconClear from '../../../../../../assets/images/icons/clear.svg'
import TextEditor from '../../../../../../Component/TextEditor'
import GetAllTestItems from '../API/GetAllTestItems'
import GetSubTest from '../../../../../../Pathology/Api/GetSubTest'
import PostSubTestData from '../API/POST/PostSubTestData'
import TosterUnderProcess from '../../../../../../Component/TosterUnderProcess'
import Toster from '../../../../../../Component/Toster'
import PostImageForEcho from '../API/POST/PostImageForEcho'
import AlertToster from '../../../../../../Component/AlertToster'
import Loader from '../../../../../../Component/Loader'
import NODataFound from '../../../../../../Component/NODataFound'


export default function TestResultMain() {

  const [isChecked, setIsChecked] = useState(true);
  let [editorValueEcho, setEditorValueEcho] = useState("")
  let [editorValueWalk, setEditorValueWalk] = useState("")
  let [editorValueVo2, setEditorValueVo2] = useState("")
  let [uploadImage, setUploadImage] = useState('');
  let [showButton, setShowButton] = useState(0)
  let [testName, setTestName] = useState('')
  let [currentdatemax, setcurrentdatemax] = useState();
  let [selectDateForSubTest, setSelectDateForSubTest] = useState(new Date().toISOString().split("T")[0]);
  var tzoffset = (new Date()).getTimezoneOffset() * 60000
  let [selectTimeForSubTest, setSelectTimeForSubTest] = useState((new Date(Date.now() - tzoffset)).toISOString().slice(0, -1).split("T")[1].split(":")[0] + ":" + (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1).split("T")[1].split(":")[1]);
  let [selectDateForFinding, setSelectDateForFinding] = useState(new Date().toISOString().split("T")[0]);
  let [selectTimeForFinding, setSelectTimeForFinding] = useState((new Date(Date.now() - tzoffset)).toISOString().slice(0, -1).split("T")[1].split(":")[0] + ":" + (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1).split("T")[1].split(":")[1]);
  let [arr, setArr] = useState([]);
  let [testItem, setTestItem] = useState([]);
  let [subTestItem, setSubTestItem] = useState([]);
  let [testID, setTestID] = useState();
  let [showUnderProcess, setShowUnderProcess] = useState(0);
  let [showToster, setShowToster] = useState(0);
  let [tosterMessage, setTosterMessage] = useState("");
  let [tosterValue, setTosterValue] = useState(0);
  let [showUnderProcessTemp, setShowUnderProcessTemp] = useState(0);
  let [showTosterTemp, setShowTosterTemp] = useState(0);
  let [tosterMessageTemp, setTosterMessageTemp] = useState("");
  let [tosterValueTemp, setTosterValueTemp] = useState(0);
  let [showAlertToster, setShowAlertToster] = useState(0);
  let [showErrMessage, setShowErrMessage] = useState('');
  let [loader, setLoader] = useState(0);

  let handleOnChange = (e) => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const time = `${hours}:${minutes}`;
    let value = e.target.value;
    if (e.target.name === "vitalDate") {
      document.getElementById("errDate").style.display = "none";
      setSelectDateForSubTest(e.target.value);
    }
    else if (e.target.name === "vitalTime") {
      document.getElementById("errTime").style.display = "none";
      if (value > time) {
        return false
      }
      else {
        setSelectTimeForSubTest(e.target.value)
      }
    }
  }



  let handleOnChangeFinding = (e) => {
    if (e.target.name === "vitalDateFinding") {
      document.getElementById("errDateFinding").style.display = "none";
      setSelectDateForFinding(e.target.value);
    }
    else if (e.target.name === "vitalTimeFinding") {
      document.getElementById("errTimeFinding").style.display = "none";
      setSelectTimeForFinding(e.target.value);
    }

  }



  let curdate = () => {
    // Create a new Date object
    var currentDate = new Date();

    // Get the current date and time components
    var day = currentDate.getDate();
    var month = currentDate.getMonth() + 1; // Months are 0-based, so we add 1
    var year = currentDate.getFullYear();
    var hours = currentDate.getHours();
    var minutes = currentDate.getMinutes();

    // Format the components as "dd-mm-yyyy HH:mm"
    var formattedDateTime = year + '-' + (month < 10 ? '0' : '') + month + '-' + day + ' ' + (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes;
    return formattedDateTime;
  };
  let curdates = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    setcurrentdatemax(formattedDate)
    document.getElementById('vitalDate').value = formattedDate;
    return formattedDate;
  };
  // ----------------------- function to get all test list --------------------
  let funGetTestItem = async () => {
    setLoader(1)
    let getTestItem = await GetAllTestItems();
    if (getTestItem.status === 1) {

      setTestItem(getTestItem.responseValue)
      setLoader(0)
    }
    else {
      setLoader(0)
    }


  }
  //--------------------------------------------------------------------------------

  //------------------------- function for get subtest on the basis of test id ---------------------
  let getSubtestData = async (itemId, testName) => {
    if (subTestItem.length > 0) {

      for (var i = 0; i < subTestItem.length; i++) {
        document.getElementById("subtestDisabled" + subTestItem[i].id).value = '';
      }
    }

    setArr([])
    setLoader(1)

    let getSubTestList = await GetSubTest(itemId);
    setTestID(itemId)
    setShowButton(1);
    if (getSubTestList.status === 1) {
      setLoader(0)

      setTestName(testName)
      setSubTestItem(getSubTestList.responseValue);
    }
    else {
      setLoader(0)
      setTestName([])
      setSubTestItem([]);


    }
  }
  // -----------------------------------------------------------------------------------------------

  //  --------------------------------function for checkboxes---------------------------------------
  let getDataDetailForCheckbox = (id) => {
    const targetInputBox = document.getElementById("checkSubTest" + id).checked;

    if (targetInputBox === false) {

      document.getElementById("subtestDisabled" + id).setAttribute('disabled', 'disabled');
      let temp = [...arr]
      for (var i = 0; i < temp.length; i++) {
        if (temp[i].subtestId === id) {
          temp.splice(i, 1)

        }
      }
      setArr(temp)

    }
    else {
      let temp = [...arr]
      const subtestValue = document.getElementById("subtestDisabled" + id).value;
      document.getElementById('subtestDisabled' + id).removeAttribute("disabled");
      temp.push({
        subtestId: id,
        result: subtestValue,
        //unitId: unit,

      })
      setArr([...temp])
    }
  }
  // ---------------------------- function to fill subtest values ---------------------------------
  let clearSubTest = () => {

    for (var k = 0; k < subTestItem.length; k++) {
      document.getElementById('checkSubTest' + subTestItem[k].id).checked = true;
      document.getElementById('subtestDisabled' + subTestItem[k].id).value = '';
      document.getElementById('subtestDisabled' + subTestItem[k].id).removeAttribute("disabled");
    }

    // document.getElementById('vitalDate').value = '';
    // document.getElementById('vitalTime').value = '';

  }
  // ------------------------------------------------------------------------------------------------
  let handleFillSubtestValue = (key, unit) => {
    const getValue = document.getElementById('subtestDisabled' + key).value;

    let temp = [...arr]

    if (temp.length === 0) {
      temp.push({
        subtestId: key,
        result: getValue,
        unitId: unit,

      })
      setArr([...temp])

    }
    else {
      var index = arr.findIndex((i) => i.subtestId == key);

      if (index != -1) {
        let temp = [...arr]

        temp.splice(index, 1,
          {
            subtestId: key,
            result: getValue,
            unitId: unit,

          })
        setArr([...temp])

      }
      else {
        temp.push({
          subtestId: key,
          result: getValue,
          unitId: unit
        })
        setArr([...temp])

      }
    }
  }
  // -------------------------------------------------------------------------------------------------

  // ------------------------- Save function for subtest ------------------------------------------------
  let handleSave = async () => {
    var finalArr = [];
    let tempArr = [];
    let dateVal = document.getElementById('vitalDate').value;
    let timeVal = document.getElementById('vitalTime').value;
    if (dateVal === '' || dateVal === undefined || dateVal === null) {
      document.getElementById('errDate').innerHTML = "Please select Date";
      document.getElementById('errDate').style.display = "block";
    }
    else if (timeVal === '' || timeVal === undefined || timeVal === null) {
      document.getElementById('errTime').innerHTML = "Please select Time";
      document.getElementById('errTime').style.display = "block";
    }
    else {
      if (subTestItem.length !== 0) {
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].result !== '') {
            finalArr.push({
              itemID: testID,
              subTestID: arr[i].subtestId,
              result: arr[i].result,
              resultRemark: '',
              isMachineResult: 0,
              isNormalResult: 0,
              isImage: false,
              fileURl: '',
              resultDateTime: selectDateForSubTest + ' ' + selectTimeForSubTest,
              investigationTypeId: 1,
              // itemID: getTestID,
              unitID: arr[i].unitId,
              userID: JSON.parse(window.sessionStorage.getItem("LoginData")).userId

            })
          }
        }

        if (finalArr.length > 0) {
          for (var j = 0; j < subTestItem.length; j++) {

            if (document.getElementById('checkSubTest' + subTestItem[j].id).checked === true) {

              tempArr.push({
                rowID: subTestItem[j].id
              })
            }
          }

          if (tempArr.length === finalArr.length) {
            var obj = {
              uhid: JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid,
              //uhid:'UHID00613',
              //uhid:JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid,
              //billMasterId: getBillMasterId,
              //uhId: getUHID,
              //pmId: getPMID,
              investigationResultJson: JSON.stringify(finalArr),
              //testId: getTestID,
              //sampleCollectionSubId: sampleCollectionSubId,
              //billNo: getTxtBillNo,
              userId: window.userId,
              clientId: window.clientId
            }

            let response = await PostSubTestData(obj);
            if (response.status === 1) {
              setArr([]);
              clearSubTest()
              setShowUnderProcess(0);
              setTosterValue(0);
              setShowToster(1);
              setTosterMessage('Data Saved Successfully!');
              setTimeout(() => {
                setShowToster(0)
              }, 2000)
              setEditorValueWalk("")
              setEditorValueVo2("")
            }
          }
          else {

            setShowAlertToster(1);
            setShowErrMessage('Please check the box or fill value')
          }

        }
        else {

          setShowAlertToster(1);
          setShowErrMessage('Please check the box or fill value')
        }
      }
    }

  }
  // --------------------------------------------------------------------------------------------------

  // -------------------------------- To save template data -----------------------------------------
  let makeDataForTemplate = () => {

    var finalArrForTemplate = [];
    for (var i = 0; i <= 2; i++) {
      let txtResult = i === 0 ? editorValueEcho : i === 1 ? editorValueWalk : editorValueVo2;
      let insertTestID = i === 0 ? 3951 : i === 1 ? 4442 : 4443;
      let insertSubTestID = i === 0 ? 2536 : i === 1 ? 1652 : 1653;
      let imageStatus = uploadImage !== undefined && uploadImage !== null && uploadImage.length !== 0 ? true : false
      // txtResult !=='' || txtResult !== null || txtResult !== undefined ?
      if (txtResult !== '' && txtResult !== null && txtResult !== undefined && txtResult !== '<p><br></p>') {
        finalArrForTemplate.push({
          itemID: insertTestID,
          subTestID: insertSubTestID,
          result: 0,
          resultRemark: txtResult,
          isMachineResult: 0,
          isNormalResult: 0,
          isImage: imageStatus,
          // fileURl:uploadImage,
          resultDateTime: selectDateForFinding + ' ' + selectTimeForFinding,
          investigationTypeId: 2,
          // itemID: getTestID,
          unitID: 0,
          userID: JSON.parse(window.sessionStorage.getItem("LoginData")).userId
        })
      }

      // :''
    }
    return finalArrForTemplate;
  }
  let handleSaveTemplate = async () => {
    let finalArrForTemplate = await makeDataForTemplate()
    let dateValFinding = document.getElementById('vitalDateFinding').value;
    let timeValFinding = document.getElementById('vitalTimeFinding').value;

    if (dateValFinding === '' || dateValFinding === undefined || dateValFinding === null) {
      document.getElementById('errDateFinding').innerHTML = "Please Select Date";
      document.getElementById('errDateFinding').style.display = "block";
    }
    else if (timeValFinding === '' || timeValFinding === undefined || timeValFinding === null) {
      document.getElementById('errTimeFinding').innerHTML = "Please Select Time";
      document.getElementById('errTimeFinding').style.display = "block";
    }
    else {
      if (finalArrForTemplate.length !== 0) {
        var objTemp = {
          uhid: JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid,
          investigationResultJson: "",
          userId: JSON.parse(window.sessionStorage.getItem("LoginData")).userId,
          clientId: window.clientId
        }



        var objForImage = {
          uhId: JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid,
          // uhid: 'UHID00854',
          category: '2dEcho',
          dateTime: curdate(),
          userId: JSON.parse(window.sessionStorage.getItem("LoginData")).userId,
          clientId: window.clientId
        }

        if (uploadImage !== undefined && uploadImage !== null && uploadImage.length !== 0) {

          if (finalArrForTemplate.length !== 0) {
            let imageResponse = await PostImageForEcho(objForImage, uploadImage)
            finalArrForTemplate[0]["fileURL"] = imageResponse.responseValue[0].url

            if (imageResponse.status === 1) {

              if (objTemp !== undefined) {
                objTemp["investigationResultJson"] = JSON.stringify(finalArrForTemplate)
                let templateResponse = await PostSubTestData(objTemp);
                if (templateResponse.status === 1) {
                  setArr([]);
                  setShowUnderProcessTemp(0);
                  setTosterValueTemp(0);
                  setShowTosterTemp(1);
                  setTosterMessageTemp('Data Saved Successfully!');
                  setTimeout(() => {
                    setShowTosterTemp(0)
                  }, 2000)
                }
              }

            }

          }
        }
        else {
          objTemp["investigationResultJson"] = JSON.stringify(finalArrForTemplate)
          let templateResponse = await PostSubTestData(objTemp);
          if (templateResponse.status === 1) {
            setArr([]);
            setShowUnderProcessTemp(0);
            setTosterValueTemp(0);
            setShowTosterTemp(1);
            setTosterMessageTemp('Data Saved Successfully!');
            setTimeout(() => {
              setShowTosterTemp(0)
            }, 2000)
          }
        }
      }
      else {
        if (uploadImage !== undefined && uploadImage !== null && uploadImage.length !== 0) {
          var objForImage = {
            uhId: JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid,
            // uhid: 'UHID00854',
            category: '2dEcho',
            dateTime: curdate(),
            userId: JSON.parse(window.sessionStorage.getItem("LoginData")).userId,

          }
          let imageResponse = await PostImageForEcho(objForImage, uploadImage)
          objTemp = {
            uhid: JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid,
            investigationResultJson: "",
            userId: JSON.parse(window.sessionStorage.getItem("LoginData")).userId
          }

          finalArrForTemplate.push({
            itemID: 3951,
            subTestID: 2536,
            result: 0,
            resultRemark: '',
            isMachineResult: 0,
            isNormalResult: 0,
            isImage: true,
            fileURL: imageResponse.responseValue[0].url,
            resultDateTime: selectDateForFinding + ' ' + selectTimeForFinding,
            investigationTypeId: 2,
            // itemID: getTestID,
            unitID: 0,
            userID: JSON.parse(window.sessionStorage.getItem("LoginData")).userId,
            clientId: window.clientId
          })
          objTemp["investigationResultJson"] = JSON.stringify(finalArrForTemplate)
          let templateResponse = await PostSubTestData(objTemp);
          if (templateResponse.status === 1) {
            setArr([]);
            setShowUnderProcessTemp(0);
            setTosterValueTemp(0);
            setShowTosterTemp(1);
            setTosterMessageTemp('Data Saved Successfully!');
            setTimeout(() => {
              setShowTosterTemp(0)
            }, 2000)
          }
        }
      }
    }


  }
  // -------------------------------------------------------------------------------------------------

  // ------------------------------ To fill template data -----------------------------------------------
  let handleTexteditorEcho = (e) => {

    setEditorValueEcho(e.target.value)

  }
  // ------------------------------------------------------------------------------------------------------
  // ------------------------------ To fill template data -----------------------------------------------
  let handleTexteditorWalk = (e) => {


    setEditorValueWalk(e.target.value)

  }
  // ------------------------------------------------------------------------------------------------------
  // ------------------------------ To fill template data -----------------------------------------------
  let handleTexteditorVo2 = (e) => {


    setEditorValueVo2(e.target.value)
  }
  // ------------------------------------------------------------------------------------------------------
  let handlerSelectImage = (e) => {
    const formData = new FormData();
    formData.append('formFile', e.target.files[0])
    setUploadImage(formData)

  }
  useEffect(() => {
    curdates();
    funGetTestItem();

  }, [])
  return (
    <>
      {/* <div className='d-flex vital-cn1'>
            <div className='vital-1'>
              <div className='whitebackgroundnopad'>
                <TestResultLeft />
              </div>
            </div>
            <div className='vital-1'>
              <div className='whitebackgroundnopad'>
                <TestResultRight />
              </div>  
            </div>
      </div> */}

      <div className="container-fluid_">

        <div className="row">
          <div className="col-lg-7 mb-1 pe-0">
            <div className="med-box gap-2" style={{ display: 'grid', gridTemplateColumns: '300px auto' }}>
              <div>
                <div className="title">Test</div>
                <div className="col-12 mt-2" style={{ height: '82vh', overflow: 'auto' }}>
                  <table className='med-table border_ striped'>
                    <thead>
                      <tr>
                        <th className='text-center'>#</th>
                        <th>Test</th>
                        {/* <th>Status</th> */}
                        <th className='text-center'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {testItem && testItem.map((val, ind) => {

                        return (
                          <tr key={ind}>
                            <td className='text-center'>{ind + 1}</td>
                            <td>{val.itemName}</td>
                            {/* <td>Yes</td> */}
                            <td className='text-center' onClick={() => { getSubtestData(val.id, val.itemName); }}>
                              <img src={viewicn} viewicn className="bgicn" alt="View" title="View" />
                            </td>
                          </tr>
                        )
                      })
                      }
                      {/* <tr>
           <td className='text-center'>1</td>
            <td>Kidney Function Test</td>
            <td>Yes</td>
            <td className='text-center'>             
              <img src={viewicn} viewicn className="bgicn" alt="View" title="View"/>              
            </td>
          </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="med-box shadow-none">

                <div className='d-flex justify-content-between pe-1'>
                  <div className="title">Subtest List For {testName}</div>

                  <div className='mt-2  d-flex gap-1 vital-btn-date'>
                    <div>
                      <div className='vitals-cnt-in10' style={{ width: '135px' }}>
                        <input type='date' name="vitalDate" id='vitalDate' max={currentdatemax}
                          className='w-100' value={selectDateForSubTest} onChange={handleOnChange} />
                      </div>
                      <small id="errDate" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>

                    <div>
                      <div className='vitals-cnt-in10' style={{ width: '135px' }}>
                        <input type='time' name="vitalTime" id='vitalTime' className='w-100' value={selectTimeForSubTest} onChange={handleOnChange} />
                      </div>
                      <small id="errTime" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>
                  </div>
                </div>


                <div className="col-12 mt-2" style={{ height: '76vh', overflow: 'auto' }}>
                  {
                    subTestItem.length !== 0 ?
                      <table className='med-table border_ striped'>
                        <thead>
                          <tr>
                            <th>Action</th>
                            <th>Sub Test</th>
                            <th>Value</th>
                            <th>Unit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subTestItem && subTestItem.map((list, ind) => {
                            return (
                              <tr key={ind}>
                                <td><input type="checkbox" id={'checkSubTest' + list.id} name='checkSubTest' defaultChecked={isChecked} role='switch' onClick={() => { getDataDetailForCheckbox(list.id, list.subTestName, list.unitID) }}></input></td>
                                <td>{list.subTestName}</td>
                                <td>
                                  <input type="number" className='form-control form-control-sm w-50' id={'subtestDisabled' + list.id} onChange={() => { handleFillSubtestValue(list.id, list.unitID) }} />
                                </td>
                                <td>{list.unitName}</td>
                              </tr>
                            )
                          })
                          }
                          {/* <tr>
          <td><input type="checkbox" id={'checkSubTest'} name='checkSubTest' defaultChecked={isChecked} role='switch'  onClick={() => { getDataDetailForCheckbox()}}></input></td>
          <td>ABC</td>
          <td>
            <input type="number" className='form-control form-control-sm w-50' onChange={() => { handleFillSubtestValue() }}/>
          </td>
          <td>ms/U</td>
        </tr> */}
                          {/* <tr>
          <td><input type="checkbox" id={'checkSubTest'} name='checkSubTest' defaultChecked={''} role='switch' ></input></td>
          <td>ABC</td>
          <td>
            <input type="text" className='form-control form-control-sm w-50'/>
          </td>
          <td>ms/U</td>
        </tr>
        <tr>
          <td><input type="checkbox" id={'checkSubTest'} name='checkSubTest' defaultChecked={''} role='switch' ></input></td>
          <td>ABC</td>
          <td>
            <input type="text" className='form-control form-control-sm w-50'/>
          </td>
          <td>ms/U</td>
        </tr>  */}
                        </tbody>
                      </table>

                      :
                      <NODataFound />

                  }

                </div>
                {/* <div className='col-12'>       
        <div className="d-flex justify-content-end pt-1">
        <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleSave}>
        <img src={iconSave} className="icnn" alt=''/>Save </button>
        <button type="button" className="btn btn-clear btn-sm mb-1 me-1">
        <img src={iconClear} className="icnn" alt=''/>Clear</button>
        </div>       
      </div> */}


                <div className='col-12'>
                  {showButton === 1 && <>
                    <div className="d-flex justify-content-end pt-1">
                      {showUnderProcess === 1 ? <><TosterUnderProcess /></> : showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} /> :
                        <>
                          <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleSave}>
                            <img src={iconSave} className="icnn" alt='' />Save </button>
                          <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={clearSubTest}>
                            <img src={iconClear} className="icnn" alt='' />Clear</button>
                        </>}

                    </div>
                  </>}
                </div>

              </div>

            </div>
          </div>



          <div className="col-lg-5 mb-1">
            <div className="med-box">
              <div className='d-flex justify-content-between pe-1'>
                <div className="title">Findings</div>
                <div className='mt-2  d-flex gap-1 vital-btn-date'>
                  <div>
                    <div className='vitals-cnt-in10' style={{ width: '135px' }}>
                      <input type='date' name="vitalDateFinding" id='vitalDateFinding' className='w-100' value={selectDateForFinding} onChange={handleOnChangeFinding} />
                    </div>
                    <small id="errDateFinding" className="form-text text-danger" style={{ display: 'none' }}></small>
                  </div>
                  <div>
                    <div className='vitals-cnt-in10' style={{ width: '135px' }}>
                      <input type='time' name="vitalTimeFinding" id='vitalTimeFinding' className='w-100' value={selectTimeForFinding} onChange={handleOnChangeFinding} />
                    </div>
                    <small id="errTimeFinding" className="form-text text-danger" style={{ display: 'none' }}></small>
                  </div>
                </div>
              </div>



              <div className="col-12 mt-2" style={{ height: '77.8vh', overflow: 'auto' }}>
                <table className='med-table border_ striped'>
                  <tbody>
                    <tr>
                      <td className='pb-2'>
                        <div className='d-flex justify-content-between mb-1 align-items-end'>
                          {/* <div><b>2D Echo</b></div> */}
                          <div>
                            <input className="form-control form-control-sm" id="formFileSm" type="file" onChange={handlerSelectImage} />
                          </div>
                        </div>

                        {/* <div>
                          <TextEditor getTextvalue={handleTexteditorEcho} name="abc" id="abc" setValue={editorValueEcho} />
                        </div> */}
                      </td>
                    </tr>
                    <tr>
                      <td className='pb-2'>
                        <div><b>6 mins walk</b></div>
                        <div>
                          <TextEditor getTextvalue={handleTexteditorWalk} name="abc" id="abc" setValue={editorValueWalk} />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='pb-2'>
                        <div><b>Vo2max</b></div>
                        <div>
                          <TextEditor getTextvalue={handleTexteditorVo2} name="abc" id="abc" setValue={editorValueVo2} />
                        </div>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>

              <div className='col-12'>
                <div className="d-flex justify-content-end pt-1">
                  {showUnderProcessTemp === 1 ? <><TosterUnderProcess /></> : showTosterTemp === 1 ? <Toster value={tosterValueTemp} message={tosterMessageTemp} /> :
                    <>
                      <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleSaveTemplate}>
                        <img src={iconSave} className="icnn" alt='' />Save </button>
                      {/* <button type="button" className="btn btn-clear btn-sm mb-1 me-1">
        <img src={iconClear} className="icnn" alt=''/>Clear</button> */}
                    </>}

                </div>
              </div>


            </div>
          </div>
        </div>

      </div>
      {
        showAlertToster === 1 ?
          <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
      }
      <Loader val={loader} />
    </>

  )
}
