
async function DeleteCastSheet(data) {

    let url = `${window.AppbaseUrl}/api/CaseSheet/DeleteCaseSheet?UHID=${data.UHID}&Key=${data.rowId}`;
    let head = {
      'Content-Type': 'application/JSON',
      accept: '*/*',
    }
    let response =
      await fetch(url, {
        method: 'DELETE',
        headers: head,
        body: ''
      })
        .then((res) => res.json())
        .then(data)
  
  
    return response;
  }
  export default DeleteCastSheet;
  