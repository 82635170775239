import React, { useEffect, useState } from 'react'
import Heading from '../../../Component/Heading'
import BoxContainer from '../../../Component/BoxContainer'
import DropdownWithSearch from '../../../Component/DropdownWithSearch'
import GetGender from '../../../Registartion/API/GET/GetGender'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default function MedicineAnalytics() {
    const [chartType, setChartType] = useState('column');
    const [selectedMedicines, setSelectedMedicines] = useState(['Azithral', 'Paracetamol']);
    let [genderList, setGenderList] = useState([])
    let [ageGroupList, setAgeGroupList] = useState([])
    let [diseaseList, setDiseaseList] = useState([])

    const medicines = {
        Azithral: {
            name: 'Azithral 500',
            data: [800, 600, 700, 800, 900],
            background: '#2CABF9',
            patients: '240+ patients',
            patients1: '50+'
        },
        Paracetamol: {
            name: 'Paracetamol 500',
            data: [900, 700, 800, 900, 1000],
            background: '#6644BF',
            patients: '220+ patients',
            patients1: '100+'
        },
        AtoZ: {
            name: 'AtoZ',
            data: [1000, 800, 900, 1000, 1200],
            background: '#00ff00',
            patients: '180+ patients',
            patients1: '50+'
        },
        Ebastin: {
            name: 'Ebastin',
            data: [1200, 900, 1000, 1100, 1400],
            background: '#FF6940',
            patients: '150+ patients',
            patients1: '40+'
        },
        Dolo: {
            name: 'Dolo 600 mg',
            data: [1400, 1000, 1200, 1200, 1600],
            background: '#6E88B9',
            patients: '130+ patients',
            patients1: '30+'
        },
        Genone: {
            name: 'Genone 400',
            data: [1600, 1200, 1400, 1300, 1800],
            background: '#E759F4',
            patients: '110+ patients',
            patients1: '110+'
        },
    };

    const options = {
        chart: {
            type: chartType,
        },
        title: {
            text: '',
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
            title: {
                text: 'Appointments Dates (mg)',
            },
        },
        yAxis: [
            {
                title: {
                    text: 'Strength (mg)',
                },
                min: 0,
                max: 2000,
            },
            {
                title: {
                    text: 'Frequency',
                },
                opposite: true,
                min: 0,
                max: 10,
                tickInterval: 1,
            }
        ],
        tooltip: {
            useHTML: true,
            formatter: function () {
                const medicine = medicines[this.series.userOptions.key];
                return `
                    <div style="background: ${medicine.background}; border: 2px solid ${medicine.background}; border-radius: 5px; padding: 5px 10px; color: #FFFFFF;text-align:center;">
                        <strong>${this.series.name}</strong> (${this.y}mg)<br>${medicine.patients}
                    </div>`;
            },
        },
        plotOptions: {
            column: {
                borderRadius: '50%',
                groupPadding: 0.4,
                pointPadding: 0.0,
                borderWidth: 0,
                pointWidth: 20,
                dataLabels: {
                    enabled: false,
                    useHTML: true,
                    inside: true,
                    style: {
                        color: '#FFFFFF',
                        textOutline: 'none'
                    },
                    formatter: function () {
                        const medicine = medicines[this.series.userOptions.key];
                        return `<div style="transform: rotate(90deg); white-space: nowrap;">${medicine.name}</div>`;
                    }
                }
            },
        },
        series: selectedMedicines.map((med) => ({
            name: medicines[med].name,
            data: medicines[med].data,
            color: medicines[med].background,
            key: med,
        })),
    };

    const handleMedicineClick = (med) => {
        setSelectedMedicines((prev) =>
            prev.includes(med) ? prev.filter((item) => item !== med) : [...prev, med]
        );
    };

    let getData = async () => {
        let responseGender = await GetGender()
        if (responseGender.status === 1) {
            setGenderList(responseGender.responseValue)
        }

    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <div className='main-content mt-5 pt-3'>
                <div className='container-fluid'>
                    <div className="topbarmedicine">
                        <div className="topbarmedicine-h pb-3">
                            {/* <h3>Medicine Analytics</h3> */}
                            <Heading text="Medicine Analytics" />
                        </div>
                        <div className="medicineanalytics-in">
                            <div className="medicineanalytics">
                                {/* <select className="form-control" id="diseaseSelect">
                                    <option>Select Disease</option>
                                </select> */}
                                {diseaseList && <DropdownWithSearch defaulNname="Select Disease" name="headId" list={diseaseList} valueName="id" displayName="headName" editdata={""} getvalue={"handleChange"} clear={"clearDropdown"} clearFun={"handleClear"} />}
                            </div>
                            <div className="medicineanalytics">
                                <input type="date" className="form-control" id="dateRange" placeholder="Select Date Range" />
                            </div>
                            <div className="medicineanalytics">
                                {/* <select className="form-control" id="ageGroupSelect">
                                    <option>Select Age Group</option>
                                </select> */}
                                {ageGroupList && <DropdownWithSearch defaulNname="Select Age Group" name="headId" list={ageGroupList} valueName="id" displayName="name" editdata={""} getvalue={"handleChange"} clear={"clearDropdown"} clearFun={"handleClear"} />}

                            </div>
                            <div className="medicineanalytics">
                                {/* <select className="form-control" id="genderSelect">
                                    <option>Select Gender</option>
                                    {
                                        genderList && genderList.map((val, ind) => (
                                            <option value={val.id}>{val.name}</option>
                                        ))
                                    }
                                </select> */}
                                {genderList && <DropdownWithSearch defaulNname="Select Gender" name="headId" list={genderList} valueName="id" displayName="name" editdata={""} getvalue={"handleChange"} clear={"clearDropdown"} clearFun={"handleClear"} />}

                            </div>
                        </div>
                    </div>

                    <div className="medicine-chart-container">
                        <div className="chart-wrapper leftsidebar">
                            <h4 className='medicinenames'>Medicines</h4>
                            <ul>
                                {Object.keys(medicines).map((med) => (
                                    <li
                                        key={med}
                                        onClick={() => handleMedicineClick(med)}
                                        style={{
                                            background: selectedMedicines.includes(med) ? medicines[med].background : '#EAEAEA',
                                            color: selectedMedicines.includes(med) ? '#FFFFFF' : '#000000',
                                        }} >
                                        {medicines[med].name}<span className='numbersp'>{medicines[med].patients1}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="chart-wrapper rightsidebar">
                            <div className="chart-type-switcher">
                                <div className="chart-type-switcher-in">
                                    <div className='barhart'
                                        onClick={() => setChartType('column')}
                                        style={{
                                            background: chartType === 'column' ? '#000' : '#EAEAEA',
                                            color: chartType === 'column' ? '#FFFFFF' : '#000000'
                                        }}>
                                        Bar Chart
                                    </div>
                                    <div className='linrchart'
                                        onClick={() => setChartType('line')}
                                        style={{
                                            background: chartType === 'line' ? '#000' : '#EAEAEA',
                                            color: chartType === 'line' ? '#FFFFFF' : '#000000'
                                        }}>
                                        Line Chart
                                    </div>
                                </div>
                            </div>
                            <HighchartsReact highcharts={Highcharts} options={options} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
