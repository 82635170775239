import React, { useEffect } from 'react'
import TimeCalculate from '../../../Code/TimeCalculate'
import PatientProfileDetails from '../../../Components/PatientProfileDetails'
import SupportivePopUp from '../../../Components/SupportivePopUp'
import PatientScorePieChart from '../../../Components/PatientScorePieChart'
import { useState } from 'react'
import ADRReport from '../../../Components/ADRReport'
import PiChart from '../../../Components/PiChart'
import CalculatorPopup from '../../../Components/CalculatorPopup'
import ChatingPopup from '../../../Components/ChatingPopup'
import chat from '../../../../assets/images/patientmonitordashboard/chat.png'
import FamilyHistoryPopUp from '../../../Components/FamilyHistoryPopUp'
import { onStart, onDrop } from '../../../Code/DragDropData'
import GetMenuByDepartmentIdAndUserId from '../../../Api/GetMenuByDepartmentIdAndUserId'
import GetPatientDetailsByUHID from '../../../Api/GetPatientDetailsByUHID'
import CalculateIsActive from '../../../Code/IsActiveCalculate'

export default function Patientname(props) {
    // console.log("data",props.patientData.pntName )
    return (
        <span draggable="true" className="pntName" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} onDragStart={onStart} title={`Patient Name (${props.patientData.PntName.toString().toUpperCase()})`}> {props.patientData.PntName.toString().toUpperCase()}</span>
    )
}

function PatientAge(props) {
    return (
        <span key={props.key} className='ptAgeGenderPID fw-semibold' title='Age' data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} draggable="true" onDragStart={onStart} onDragEnd={onDrop} id="PatientDetail" >{props.patientData.PntAge}</span>
    )
}

function PatientGender(props) {
    return (

        <span key={props.key} className='ptAgeGenderPID fw-semibold' title='Gender' data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} draggable="true" onDragStart={onStart} onDragEnd={onDrop}>{props.patientData.PntGender}</span>

    )
}

function PatientUhId(props) {
    return (

        <span key={props.key} className='ptAgeGenderPID fw-semibold' title='Patient UHID' data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} draggable="true" onDragStart={onStart} onDragEnd={onDrop}>{props.patientData.UhId}</span>

    )
}

function PatientWard(props) {
    return (

        <span key={props.key} className='borderLineColor ptDepBed fw-bold' title='Ward' data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} draggable="true" onDragStart={onStart} onDragEnd={onDrop}>{props.patientData.Ward}</span>

    )
}

function PatientBed(props) {
    return (

        <span key={props.key} className='borderLineColor ptDepBed fw-bold' title='Bed' data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} draggable="true" onDragStart={onStart} onDragEnd={onDrop}>{props.patientData.PtBed}</span>

    )
}

function PatientDepartment(props) {
    return (

        <span key={props.key} className='borderLineColor ptDepBed fw-bold' title='Department' data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} draggable="true" onDragStart={onStart} onDragEnd={onDrop}>{props.patientData.PtDep}</span>

    )
}

function PatientAdmitTime(props) {
    return (

        <span key={props.key} title='Patient Admitted Day ' className='ptDepBed fw-bold' data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} draggable="true" onDragStart={onStart} onDragEnd={onDrop}>{TimeCalculate(props.patientData.PtAdmitDays)}</span>

    )
}

function PatientCamera(props) {
    return (

        <i key={props.key} className="bi bi-camera-fill pticons" title="Open Camera" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} draggable="true" onDragStart={onStart} onDragEnd={onDrop}></i>

    )
}

function PatientProfile(props) {
    // let [patientProfilepopup, setPatientProfilePopup] = useState(0)
    let handlePatientProfilepopup = async () => {
        let resp = await GetPatientDetailsByUHID(props.patientData.UhId)
        if (resp.status === 1) {
            let deptmenu = await GetMenuByDepartmentIdAndUserId(resp.responseValue[0].deptId)
            if (deptmenu.status === 1) {
                window.sessionStorage.setItem("IPDpatientList", JSON.stringify(
                    resp.responseValue,
                ))
                window.sessionStorage.setItem("departmentmenu", JSON.stringify({
                    "menuList": deptmenu.responseValue.menuList,
                    "departmentList": deptmenu.responseValue.departmentList,
                }))
                window.sessionStorage.setItem("IPDpatientsendData", JSON.stringify(
                    [[props.patientData.UhId]],
                ))
                window.sessionStorage.setItem("IPDactivePatient", JSON.stringify({ Uhid: props.patientData.UhId }))
                // console.log("ne data", props.patientData.PtDep, props)
                window.sessionStorage.setItem("activePage", JSON.stringify({
                    "WardId": resp.responseValue[0].wardId,
                    "wardName": resp.responseValue[0].wardName,
                    "DepartmentId": resp.responseValue[0].deptId,
                    "departmentName": props.patientData.PtDep,
                    "menuName": "Patient Personal Dashboard",
                    "menuId": 51
                }))

                window.open("/patientpersonalDashboardipd/")
            }

            // newWindow["uhid"] = props.patientData.UhId
            // window["clientId"] = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId
            // setPatientProfilePopup(1)
        }
    }

    // let handlePatientProfilepopup = () => {
    //     setPatientProfilePopup(1)
    // }
    // let modelCloseFun = () => {
    //     setPatientProfilePopup(0)
    // }
    return (
        <>
            <i  key={props.key} className="bi bi-box-arrow-right pticons" title="Enter Profile" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} draggable="true" onDragStart={onStart} onDragEnd={onDrop} onClick={handlePatientProfilepopup}></i>
            {/* {
                patientProfilepopup ? <PatientProfileDetails patientProfilepopup={patientProfilepopup} modelCloseFun={modelCloseFun} patientdata={props.patientData} /> : ""
            } */}
        </>

    )

}

function PatientFeedBack(props) {
    let [supportivepopup, setSupportivePopup] = useState(0)

    let handlesetSupportivePopup = () => {
        setSupportivePopup(1)
    }
    let modelCloseFun = () => {
        setSupportivePopup(0)
    }
    return (
        <>
            {props.patientData.FeedbackParameterList && <i key={props.key} className="bi bi-star-fill pticons" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} draggable="true" onDragStart={onStart} onDragEnd={onDrop} style={{ color: props.patientData.FeedbackParameterList[0].FeedBackColor }} title='Supportive' onClick={handlesetSupportivePopup}></i>}

            {
                supportivepopup ? <SupportivePopUp SupportivePopUp={SupportivePopUp} modelCloseFun={modelCloseFun} patientdata={props.patientData} /> : ""
            }
        </>
    )
}

function PatientADRReport(props) {
    let [ADRReportPop, setADRReportPop] = useState(0)


    let handleADR = () => {
        setADRReportPop(1)
    }
    let modelCloseFun = () => {
        setADRReportPop(0)
    }
    return (
        <>
            <i key={props.key} className="bi bi-capsule text-primary pticons" title='ADR Report' data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} draggable="true" onDragStart={onStart} onDragEnd={onDrop} onClick={handleADR}></i>

            {
                ADRReportPop ? <ADRReport ADRReportPop={ADRReportPop} modelCloseFun={modelCloseFun} patientdata={props.patientData} /> : ""
            }
        </>
    )
}

function PatientSortIndex(props) {
    // console.log("props sortindex", props.sortedIndex)
    let [patientScorePieChartpopup, setPatientScorePieChartPopup] = useState(0)

    let modelCloseFun = () => {
        setPatientScorePieChartPopup(0)
    }
    return (
        <>
            <span key={props.key} className='' title="sortedIndex" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} draggable="true" onDragStart={onStart} onDragEnd={onDrop} onClick={() => { setPatientScorePieChartPopup(1) }} >{<PiChart patientScore={props.sortedIndex} />}</span>

            {
                patientScorePieChartpopup ? <PatientScorePieChart patientScorePieChartpopup={patientScorePieChartpopup} modelCloseFun={modelCloseFun} patientdata={props.patientData} /> : ""
            }
        </>
    )
}

function PatientCalculator(props) {
    let [calculatorPop, setCalculatorPop] = useState(0)


    let handleCalulator = () => {
        setCalculatorPop(1)
    }

    let modelCloseFun = () => {
        setCalculatorPop(0)
    }
    return (
        <>
            <i key={props.key} className="bi bi-calculator-fill pticons" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} draggable="true" onDragStart={onStart} onDragEnd={onDrop} title='Calcultator' onClick={handleCalulator}></i>

            {
                calculatorPop ? <CalculatorPopup showCal={calculatorPop} modelCloseFun={modelCloseFun} uhId={props.patientData.UhId} PntName={props.patientData.PntName} /> : ""
            }
        </>
    )
}

function PatientChat(props) {
    let [chatPop, setChatPop] = useState(0)


    let handleCalulator = () => {
        setChatPop(1)
    }

    let modelCloseFun = () => {
        setChatPop(0)
    }
    return (
        <>
            <img key={props.key} src={chat} alt='' className='pointer imgHover pticons' data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} draggable="true" onDragStart={onStart} onDragEnd={onDrop} title='Chat' onClick={handleCalulator} />

            {
                chatPop ? <ChatingPopup ShowChatPopup={chatPop} modelCloseFun={modelCloseFun} patientdata={props.patientData} /> : ""
            }
        </>
    )
}

function FamilyHistory(props) {
    let [familyHistory, setFamilyHistoryPop] = useState(0)


    let setFamilyHistoryShow = () => {
        setFamilyHistoryPop(1)
    }

    let modelCloseFun = () => {
        setFamilyHistoryPop(0)
    }
    return (
        <>
            <i key={props.key} className="bi bi-card-checklist pticons pointer imgHover" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} draggable="true" onDragStart={onStart} onDragEnd={onDrop} title='Family History' onClick={() => { setFamilyHistoryShow(1) }}></i>

            {
                familyHistory ? <FamilyHistoryPopUp familyHistoryShow={familyHistory} modelCloseFun={modelCloseFun} patientdata={props.patientData} /> : ""
            }
        </>
    )
}

function Supportive(props) {
    let [supportive, setSupportivePop] = useState(0)


    let setSupportiveShow = () => {
        setSupportivePop(1)
    }

    let modelCloseFun = () => {
        setSupportivePop(0)
    }
    return (

        <>
            {
                props.patientData.FeedbackParameterList && <i className="bi bi-star-fill pticons pointer imgHover" style={{ color: props.patientData.FeedbackParameterList[0].FeedBackColor }} data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "id": props.rowId })} draggable="true" onDragStart={onStart} onDragEnd={onDrop} title='Supportive' onClick={() => { setSupportiveShow(1) }}></i>
            }



            {
                supportive ? <SupportivePopUp supportiveShow={supportive} modelCloseFun={modelCloseFun} patientdata={props.patientData} /> : ""
            }
        </>
    )
}

function IsActive(props) {
    let [isActives, setIsActives] = useState(0)
    useEffect(() => {
        // console.log("time", CalculateIsActive(props.patientData.VitalParametersList !== null?props.patientData.VitalParametersList:[]))
        setIsActives(CalculateIsActive(props.patientData.VitalParametersList !== null?props.patientData.VitalParametersList:[]))
        // console.log("data",CalculateIsActive(props.patientData.VitalParametersList !== null?props.patientData.VitalParametersList:[]))
    }, [props])
    return (<>
        {
            isActives === 1 ?
                <span  key={props.key} draggable="true" className="pntName" style={{color:"green", fontWeight:"600"}} data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} onDragStart={onStart} title={`Patient IsActive `}> Active</span>
                :
                <span key={props.key} draggable="true" className="pntName blink" style={{color:"red", fontWeight:"600"}} data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} onDragStart={onStart} title={`Patient IsActive `}>Inactive</span>
        }
    </>)
}

export {Patientname, PatientAge, PatientGender, PatientUhId, PatientWard, PatientBed, PatientDepartment, PatientAdmitTime, PatientCamera, PatientProfile, PatientADRReport, PatientFeedBack, PatientSortIndex, PatientCalculator, PatientChat, FamilyHistory, Supportive,IsActive }