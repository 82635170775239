let UpdateStatusSprintBacklog = async (obj) => {
     // let url = window.SpringBoardServicesUrl + "api/SprintBacklogMaster/UpdateStatusSprintBacklog";
     let url = "https://api.medvantage.tech:7097/" + "api/SprintBacklogMaster/UpdateStatusSprintBacklog";
     const head = { 'Content-Type': 'application/json-patch+json', 'acept': '*/*', };
     let data = {};
     let response = await fetch(url, {
          method: "PUT",
          headers: head,
          body: JSON.stringify(obj)
     }).then((res) => res.json()).then(data);
     return response;
}

export default UpdateStatusSprintBacklog;