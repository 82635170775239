const GetItemDetailsByDemandId = async (demandID, clientID, approveStatus) =>{
   const url =window.InventoryBaseUrl+ '/api/InventoryRaiseDemand/GetItemDetailsByDemandId?ClientID='+clientID+'&GetDemandID='+demandID+'&AllotmentStatus='+approveStatus;
   // const url = 'https://localhost:7264/api/InventoryRaiseDemand/GetItemDetailsByDemandId?ClientID='+clientID+'&GetDemandID='+demandID+'&AllotmentStatus='+approveStatus;
  const head = {'content-type' : 'application/json','accept':'*/*'};
  const data = {};
  const response = await fetch(url, {
      method: 'GET',
      header: head,
  }).then(res => res.json()).then(data);
  return response;
};
export default GetItemDetailsByDemandId;