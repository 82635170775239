
  async function POSTCatheterAssociated(data) {

    let url = "https://localhost:7225/api/CatheterAssociated/InsertCatheterAssociated"
    let head = {
      'Content-Type': 'application/JSON',
      accept: '*/*',
    }
    let response =
      await fetch(url, {
        method: 'POST',
        headers: head,
        body: JSON.stringify(data)
      })
        .then((res) => res.json())
        .then(data)
  
  
    return response;
  }
  export default POSTCatheterAssociated;

  