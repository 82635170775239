// let GetVendorMaster = async() => {
//   const clientID=JSON.parse(sessionStorage.getItem("LoginData")).clientId;
//     const url =  window.InventoryBaseUrl + "/api/VendorMaster/GetAllVendorMaster";
//     const head = {'content-type':'application/json','accept':'*/*',}
//     let data = {};
//     let response = await fetch(url,{
//       method: "GET",
//       header: head,
  
//     }).then(res => res.json()).then(data);
//     return response;
//   }
//   export default  GetVendorMaster;


  async function GetVendorMaster(ClientID) {
    // let token = 'bearer ' + window.AppToken;
    let url = window.InventoryBaseUrl + "/api/VendorMaster/GetAllVendorMaster?ClientId=" + ClientID;
    let head = { "Content-Type": "application/JSON", accept: '*/*'
    // , 'Authorization': token
 };
  
    let response = await fetch(url, {
      headers: head,
      method: 'GET'
    })
      .then((res) => res.json())
      .then();
  
    return response;
  }
  export default GetVendorMaster;
  
  
