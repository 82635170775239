const SearchItem = async (item, clientID) =>{
   const url = window.InventoryBaseUrl + '/api/SearchAndGetItem/SearchItem?Item='+item+'&clientID='+clientID;
  // const url = 'https://localhost:7264/api/SearchAndGetItem/SearchItem?Item='+item+'&clientID='+clientID;
  const head = {'content-type' : 'application/json','accept':'*/*'};
  const data = {};
  const response = await fetch(url, {
      method: 'GET',
      header: head,
  }).then(res => res.json()).then(data);
  return response;
};
export default SearchItem;