async function GetAllCatheterAssociated( ) {

    let url = "https://localhost:7225/api/CatheterAssociated/GetAllCatheterAssociated"
    // let url = ` https://localhost:7225/api/PatientInitialAssessment/GetAllPastMedicalHistory?ClientId${ClientId}&Id=${Id}`;
    let data = {}
    let response = fetch(url, {
        // headers: head,
        method: 'GET'
    })
        .then((res) => res.json())
        .then(data);

    return response;
}
export default GetAllCatheterAssociated;