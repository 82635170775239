async function GetDeveloperWiseTask(assignedTo,UserId,fromDate,toDate) {
    let url = window.SpringBoardServicesUrl+"api/Report/GetDeveloperWiseTask?assignedTo="+assignedTo+"&UserId="+UserId+"&fromDate="+fromDate+"&toDate="+toDate;
    let head = { "Content-Type": "application/JSON", accept : '*/*' };
    let data={};
  let response = fetch(url, {
    headers: head,
    method : 'GET'
  }).then((res) => res.json()).then(data);

  return response;
  //console.log('CheckAPI',response)
}
export default GetDeveloperWiseTask;
