import React from 'react'
import ventilatorGif from "../assests/images/ventiGif.gif"
import usercropGif from "../assests/images/cropuserGIF.gif"
export default function InfoCotainer(props) {

    return (
        <div className='info-container'>
            <div className='info-data-container'>
                <img src={ventilatorGif} className='infoGif' alt='infoGif' />
                <div className='d-flex flex-row align-items-center gap-3'>
                    <span className='infoHeading'>{props.title}
                    </span>
                    <img src={usercropGif} className='userDiagnosis' alt='userGif' />
                </div>
                <div className='pt-2 scrolling-container'>
                    {props.component}
                </div>
            </div>
        </div>
    )
}
