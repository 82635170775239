import React, { useEffect, useState } from "react";
import Heading from "../../Component/Heading";
import TableContainer from "../../Component/TableContainer";
import GetDoctorOrdersForHead from "./Api/GetDoctorOrdersForHead";
import Loader from "../../Component/Loader";
import AlertToster from "../../Component/AlertToster";
import approvedIcon from '../../assets/images/icons/approved-icon.png'
import rejectIcon from '../../assets/images/icons/reject-icon.png'
import ActionTakenByHeadOnDoctorOrders from "./Api/ActionTakenByHeadOnDoctorOrders";
import SuccessToster from "../../Component/SuccessToster";
import clearIcon from '../../assets/images/icons/clear.svg';
import DropdownWithSearch from "../../Component/DropdownWithSearch";
import GetShiftList from "../Master/Api/ShiftMaster/GetShiftList";
import GetAllNurseByShift from "../AssignPatientToNurse/Api/GetAllNurseByShift";
import TosterUnderProcess from "../../Component/TosterUnderProcess";
import Toster from "../../Component/Toster";
import Search from "../../Code/Serach";
import { t } from "i18next";
import { error } from "highcharts";
import GetAutomateDutyList from "./Api/GetAutomateDutyList";
import GetHROrderDutyList from "./Api/GetHROrderDutyList";
import GetHRDutyAssignAssignedUser from "../Master/Api/DoctorOrdersForAssignedUser/GetHRDutyAssignAssignedUser";
export default function DoctorOrdersForHead() {
    const todayDate = new Date();
    let [userID, setUserID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).userId);
    const [clientID, setsetClientID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientId);
    const [DesignationID, setDesignationID] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).designationId);
    const [showLoder, setShowLoder] = useState(0);
    const [showToster, setShowToster] = useState(0);
    const [isShowToaster, setisShowToaster] = useState(0);
    const [showSuccessMsg, setShowSuccessMsg] = useState('');
    const [showAlertToster, setShowAlertToster] = useState(0);
    const [showErrMessage, setShowErrMessage] = useState('');
    // handover toaster
    const [showUnderProcess, setShowUnderProcess] = useState(0);
    const [showHandoverToster, setShowHandoverToster] = useState(0);
    const [tosterValue, setTosterValue] = useState(0);
    const [tosterMessage, setTosterMessage] = useState("");
    const [orderList, setOrderList] = useState([]);
    const [orderListMain, setOrderListMain] = useState([]);
    const [showRejectDutyModal, setShowRejectDutyModal] = useState(0);
    const [showConfirmationModal, setShowConfirmationModal] = useState(0);
    const [taskDetails, setTaskDetails] = useState([]);
    const [rejectDutyRemark, setRejectDutyRemark] = useState('');
    let [fromDate, setFromDate] = useState(todayDate.toISOString().split('T')[0]);
    let [toDate, setToDate] = useState(todayDate.toISOString().split('T')[0]);
    const [showHandoverModal, setshowHandoverModal] = useState(0);
    const [shiftList, setShiftList] = useState([]);
    const [nurseList, setNurseList] = useState([]);
    const [editShiftDdl, setEditShiftDdl] = useState('');
    const [editNurseDdl, setEditNurseDdl] = useState('');
    const [clearDropdown, setClearDropdown] = useState(0);
    const [handoverNurse, setHandoverNurse] = useState('');
    const [handoverShift, setHandoverShift] = useState('');
    const [hrFixedDutyList, setHrFixedDutyList] = useState([]);
    const [hrDailyDutyList, setHrDailyDutyList] = useState([]);
    const [automateDutyList, setAutomateDutyList] = useState([]);
    const [supportTicketList, setSupportTicketList] = useState([]);
    const [searchHRFixedDutyList, setSearchHRFixedDutyList] = useState([]);
    const [searchHRDailyDutyList, setSearchHRDailyDutyList] = useState([]);
    const [searchAutomateDutyList, setSearchAutomateDutyList] = useState([]);
    // Funtion Section Start Here//
    //handle search
    let handleSearch = (e) => {
        const {name,value}=e.target;
        if(name === "search-order-list"){
            let resp = Search(orderListMain, e.target.value)
            if (e.target !== "") {
                if (resp.length !== 0) {
                    setOrderList(resp)
                }
                else {
                    setOrderList([])
                }
            }
            else {
                setOrderList(orderListMain)
            }
        }
        else if(name === "searchbox-HR-Fixed-Duty"){
            let respHRFixedDuty = Search(searchHRFixedDutyList, value)
            if (value !== "") {
                if (respHRFixedDuty.length !== 0) {
                    setHrFixedDutyList(respHRFixedDuty)
                }
                else {
                    setHrFixedDutyList([])
                }
            }
            else {
                setHrFixedDutyList(searchHRFixedDutyList)
            }
            
        }
       else if(name === "search-HR-DailyDuty"){
            let respHRDailyDuty = Search(searchHRDailyDutyList, value)
            if (value !== "") {
                if (respHRDailyDuty.length !== 0) {
                    setHrDailyDutyList(respHRDailyDuty)
                }
                else {
                    setHrDailyDutyList([])
                }
            }
            else {
                setHrDailyDutyList(searchHRDailyDutyList)
            }
            
        }
       else if(name === "search-Automate-Duty"){
            let respAutomateDuty = Search(searchAutomateDutyList, value)
            if (value !== "") {
                if (respAutomateDuty.length !== 0) {
                    setAutomateDutyList(respAutomateDuty)
                }
                else {
                    setAutomateDutyList([])
                }
            }
            else {
                setAutomateDutyList(searchAutomateDutyList)
            }
            
        }
    }
    
    const getOrderList = async () => {
        const txtFromDate = document.getElementById('txtformDate').value;
        const txtToDate = document.getElementById('txttoDate').value;
        if(txtFromDate > txtToDate){
            document.getElementById('errFromDate').innerHTML="From Date Can Not Greater Than To Date";
            document.getElementById('errFromDate').style.display="block"
            return;
        }
        var param = {
            clientID: clientID,
            fromDate: txtFromDate,
            toDate: txtToDate,
            dutyType:1 // Duty Type 1 for Orders
        }
        var param2 = {
            clientID: clientID,
            fromDate: txtFromDate,
            toDate: txtToDate,
            dutyType:2 // Duty Type 1 for Orders
        }
        var param3 = {
            clientID: clientID,
            fromDate: txtFromDate,
            toDate: txtToDate,
            dutyType:3 // Duty Type 1 for Orders
        }

        try{
            setShowLoder(1);
            const [response1,response2,response3] = await Promise.all([
                GetDoctorOrdersForHead(param), //Used TO Call Api for Orders
                GetHROrderDutyList(param2),//Used TO Call Api for HR Fixed Duty
                GetAutomateDutyList(param3)//Used TO Call Api for Automate Duty
            ]);
            if (response1.status === 1) {
                    setOrderList(response1.responseValue)
                    setOrderListMain(response1.responseValue)
                }
                else {
                    setShowLoder(0);
                    setShowAlertToster(1);
                    setShowErrMessage(response1.responseValue);
                    setTimeout(() => {
                        setShowAlertToster(0);
                    }, 1500)
                    return;
            }
            if (response2.status === 1) {
                    setHrFixedDutyList(response2.responseValue)
                    setSearchHRFixedDutyList(response2.responseValue)
                }
                else {
                    setShowLoder(0);
                    setShowAlertToster(1);
                    setShowErrMessage(response2.responseValue);
                    setTimeout(() => {
                        setShowAlertToster(0);
                    }, 1500)
                    return;
            }
            if (response3.status === 1) {
                setAutomateDutyList(response3.responseValue)
                setSearchAutomateDutyList(response3.responseValue)
                }
                else {
                    setShowLoder(0);
                    setShowAlertToster(1);
                    setShowErrMessage(response3.responseValue);
                    setTimeout(() => {
                        setShowAlertToster(0);
                    }, 1500)
                    return;
            }


        }
        catch(error){
            setShowLoder(0);
            console.error('error',error)
        }
        finally{
            setShowLoder(0);
        }
        

       
       
    }
    //Get HR Daily Duty(Designation Wise) 
    const getHRDuty = async () => {
        setShowLoder(1);
        var obj = {
            clientId: clientID,
            designationId: DesignationID

        }
        const response = await GetHRDutyAssignAssignedUser(obj)
        if (response.status === 1) {
            setHrDailyDutyList(response.responseValue);
            setSearchHRDailyDutyList(response.responseValue);
        }
        else {
            setShowLoder(0);
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }
    const handleChangeRejectDuty = (e) => {
        document.getElementById('rejectDutyModalErr').innerHTML = "";
        setRejectDutyRemark(e.target.value)
    }
    const handleCloseRejectDutyPopUp = () => {
        document.getElementById('rejectDutyModalErr').innerHTML = "";
        setTaskDetails([]);
        setShowRejectDutyModal(0);
    }
    const handleReject = async () => {
        if (rejectDutyRemark === '' || rejectDutyRemark === null || rejectDutyRemark === undefined) {

            document.getElementById('rejectDutyModalErr').innerHTML = "Remark is Mandatory";
        }
        else {
            var obj = {
                "id": taskDetails.notificationId,
                "isDNC": true,
                "inchargeComment": rejectDutyRemark,
                "userId": userID,
                "clientId": clientID,
            }
            setShowRejectDutyModal(0);
            setShowLoder(1);
            const response = await ActionTakenByHeadOnDoctorOrders(obj);
            if (response.status === 1) {
                setTaskDetails([]);
                setRejectDutyRemark('')
                setShowLoder(0);
                setisShowToaster(1);
                setShowSuccessMsg('Order Rejected Successfully..!!')
                getOrderList();
                setTimeout(() => {
                    setisShowToaster(0);
                }, 1200);
            }
            else {
                setTaskDetails([]);
                setRejectDutyRemark('')
                setShowLoder(0);
                setShowAlertToster(1);
                setShowErrMessage(response.responseValue);
                setTimeout(() => {
                    setShowAlertToster(0);
                }, 1500)
            }

        }
    }
    const handleApproveOrder = async () => {
        var obj = {
            "id": taskDetails.notificationId,
            "isApproved": 1,
            "approvedBy": userID,
            "userId": userID,
            "clientId": clientID,
        }
        setShowConfirmationModal(0);
        setShowLoder(1);
        const response = await ActionTakenByHeadOnDoctorOrders(obj);
        if (response.status === 1) {
            setTaskDetails([]);
            setShowLoder(0);
            setisShowToaster(1);
            setShowSuccessMsg('Order Approved Successfully..!!')
            getOrderList();
            setTimeout(() => {
                setisShowToaster(0);
            }, 1200);
        }
        else {
            setTaskDetails([]);
            setShowLoder(0);
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }
    const handleCloseConfirmationModal = async () => {
        setShowConfirmationModal(0);
        setTaskDetails([]);
    }
    const handleFilter = (e) => {
        handleClearFilterValidationMsg();
        const { name, value } = e.target;
        if (name === "fromDate") {
            setFromDate(value)
        }
        else if (name === "toDate") {
            setToDate(value)
        }
    }
    const handleShowFilteredData = () => {
        getOrderList();
    }

    const handleClearFilter = () => {
        handleClearFilterValidationMsg();
        setFromDate(todayDate.toISOString().split('T')[0]);
        setToDate(todayDate.toISOString().split('T')[0]);
        setTimeout(() => {
            getOrderList();
        }, 1000)

    }
    const handleOpenHandoverModal = (taskDetails) => {
        setTaskDetails(taskDetails)
        setshowHandoverModal(1);
        getShiftList();
    }
    const handleCloseHandoverModal = () => {
        setTaskDetails([])
        setHandoverShift('');
        setHandoverNurse('');
        setshowHandoverModal(0);
    }
    //Get Shift
    const getShiftList = async () => {
        const response = await GetShiftList();
        if (response.status === 1) {
            setShiftList(response.responseValue);
        }
        else {
            setShowAlertToster(1);
            setShowErrMessage(response.responseValue);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500)
        }
    }
    //Get Nurse By Shift
    const GetNurseByShift = async (shiftId) => {
        // setShowLoder(1);
        setNurseList([]);
        const response = await GetAllNurseByShift({ clientId: window.clientId, shiftId });
        if (response.status === 1) {
            // setNurseList(response.responseValue);
            //let temp = RemoveDuplicateData(response.responseValue, "name")
            setNurseList(response.responseValue)
            //setShowLoder(0);
        }
        else {
            // setShowLoder(0);
            // setShowErrMessage(response.responseValue);
            // setShowAlertToster(1);
            // setTimeout(() => {
            //     setShowAlertToster(0);
            // }, 1500);
        }
    };
    let handleChange = async (e) => {
        handleClearValidationMsg();
        setEditShiftDdl("");
        setEditNurseDdl("");
        const { name, value } = e.target;

        if (name === 'shiftId') {
            GetNurseByShift(value);
            setHandoverShift(value);
        }
        if (name === 'assignedUserId') {
            setHandoverNurse(value);
        }

    };
    const handleClearDdl = (value) => {
        // clearValidationErrMessage();
        setClearDropdown(value);
        setEditShiftDdl(0);
        setEditNurseDdl(0);
        setHandoverNurse('');
        setHandoverShift('');
    }
    const handleHandoverOrder = async () => {

        document.getElementById('errNurse').innerHTML = "Select Nurse";
        document.getElementById('errShift').innerHTML = "Select Shift";
        if (handoverShift === '' || handoverShift === undefined || handoverShift === null) {
            document.getElementById('errShift').style.display = "block";
        }
        else if (handoverNurse === '' || handoverNurse === undefined || handoverNurse === null) {
            document.getElementById('errNurse').style.display = "block";
        }
        else {
            setShowUnderProcess(1);
            var obj = {

                "id": taskDetails.notificationId,
                "assignedTo": handoverNurse,
                "isHandover": 1,
                "userId": userID,
                "clientId": clientID,

            }
            const response = await ActionTakenByHeadOnDoctorOrders(obj);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowHandoverToster(1);
                setTosterMessage("Order Handover Successfully..!!");
                setTimeout(() => {
                    setShowHandoverToster(0);
                    handleCloseHandoverModal();
                    getOrderList();
                }, 1500)
            }
            else {
                setShowUnderProcess(0);
                setTosterValue(1);
                setShowHandoverToster(1);
                setTosterMessage(response.responseValue);
                setTimeout(() => {
                    setShowHandoverToster(0);
                }, 1500)
            }
        }
    }
    const handleClearValidationMsg = () => {
        document.getElementById('errShift').style.display = "none";
        document.getElementById('errNurse').style.display = "none";
       
    }
    const handleClearFilterValidationMsg = () => {
        document.getElementById('errFromDate').style.display="none"
       
    }
    useEffect(() => {
        setTimeout(() => {
            getOrderList();
            getHRDuty();
        }, 1000)
    }, [])
    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 mt-3">
                            <div className="med-box">
                                <div className="title">Doctor Orders For Head </div>
                                <div className="inner-content">
                                    <div className="d-flex flex-wrap align-content-end">
                                        <div className="mb-2 me-2">
                                            <label htmlFor="bedName" className="form-label">From Date</label>
                                            <input type='date' className='form-control form-control-sm' name='fromDate' id='txtformDate' value={fromDate} onChange={handleFilter} />
                                            <small id="errFromDate" className="invalid-feedback" style={{ display: 'none' }}></small>
                                        </div>

                                        <div className="mb-2 me-2">
                                            <label htmlFor="bedName" className="form-label">To Date</label>
                                            <input type='date' className='form-control form-control-sm' name='toDate' id='txttoDate' value={toDate} onChange={handleFilter} />
                                           
                                        </div>

                                        <div className="mb-2 relative">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                            <div>
                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleShowFilteredData}><i className="fa fa-search-plus icnn" aria-hidden="true"></i> Show </button>
                                                <button type="button" className="btn btn-save  btn-sm mb-1 me-1" onClick={handleClearFilter}><img src={clearIcon} className='icnn' alt='' /> Clear </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-2">
                            <div className="accordion orders-accordian" id="accordionExample">
                                {/* --------------------------------------Accordian Section Order----------------------------------------------- */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <div className="accordion-button" data-bs-toggle="collapse" data-bs-target="#orders" aria-expanded="true" aria-controls="orders">
                                            Order
                                        </div>
                                    </h2>
                                    <div id="orders" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                        <div className="accordion-body accod-in">
                                            <div className='handlser customHeading'>
                                                <Heading text={t("Order List")} />
                                                <div style={{ position: 'relative' }}>
                                                    <input type="text" className='form-control form-control-sm' placeholder={t("Search")} name="search-order-list" onChange={handleSearch} />
                                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                                </div>
                                            </div>
                                            <div className="med-table-section" style={{ "max-height": "65vh", minHeight: '30vh' }}>
                                                <table className="med-table border striped_">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center" style={{ "width": "3%" }}>#</th>
                                                            <th style={{ width: '7%' }}>Staff Name</th>
                                                            <th style={{ width: '90%', padding: '0px' }}>
                                                                <div style={{ overflow: 'auto' }}>
                                                                    <table className="med-table_" style={{ width: '100%', minWidth: '1000px' }}>
                                                                        <tr>
                                                                            <th style={{ width: '20%', borderBottom: "0" }}>Patient Name</th>
                                                                            <th style={{ width: '10%', borderBottom: "0" }}>Shift</th>
                                                                            <th style={{ width: '70%', padding: '0px', borderBottom: "0", borderTop: "0" }}>
                                                                                <div>
                                                                                    <table className="med-table_" style={{ minWidth: '600px' }}>
                                                                                        <tr>
                                                                                            <th style={{ width: '20%', borderBottom: "0" }}>Order</th>
                                                                                            <th className="text-center_" style={{ width: '15%', borderBottom: "0" }}>Completed</th>
                                                                                            <th style={{ width: '20%', borderBottom: "0" }}>Staff Comment</th>
                                                                                            <th style={{ width: '20%', borderBottom: "0" }}>Incharge Comment</th>
                                                                                            <th className="text-center_" style={{ width: '15%', borderBottom: "0" }}>Task Handover</th>
                                                                                            <th className="text-center_" style={{ width: '10%', borderBottom: "0" }}>Action</th>
                                                                                        </tr>
                                                                                    </table>
                                                                                </div>
                                                                            </th>

                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </th>

                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {orderList && orderList.map((list, ind) => {
                                                            return (
                                                                <tr key={list.id}>
                                                                    <td className="text-center" style={{ "width": "3%" }}>{ind + 1}</td>
                                                                    <td style={{ width: '7%' }}>{list.assignedToName}</td>
                                                                    <td className="border-bottom-none_" style={{ width: '90%', padding: '0' }}>
                                                                        <div style={{ maxHeight: '250px', overflow: 'auto' }}>
                                                                            <table className="med-table border" style={{ minWidth: '1000px' }}>
                                                                                <tbody>
                                                                                    {list.patientDetails && list.patientDetails.map((patientDetails, i) => {
                                                                                        return (
                                                                                            <tr key={i + 1}>
                                                                                                <td style={{ width: '20%' }}>
                                                                                                    <span style={{ fontWeight: 'bold' }}>{patientDetails.patientName}</span>
                                                                                                    <span>({patientDetails.uhID})</span>
                                                                                                </td>
                                                                                                <td style={{ width: '10%' }}>{patientDetails.shiftName}</td>
                                                                                                {patientDetails.taskDetails.length !== 0 ? (
                                                                                                    <td style={{ width: '70%', padding: '0' }}>
                                                                                                        <div>
                                                                                                            <table className="med-table border_ borderT0" style={{ minWidth: '600px' }}>
                                                                                                                <tbody>
                                                                                                                    {patientDetails.taskDetails && patientDetails.taskDetails.map((taskDetails, pi) => {
                                                                                                                            return (
                                                                                                                                <tr key={pi + 1}>
                                                                                                                                    <td style={{ color: taskDetails.isCompleted === true ? 'Green' : 'Red', width: '20%' }}>{taskDetails.categoryName}</td>
                                                                                                                                    <td className="text-center_" style={{ color: taskDetails.isCompleted === true ? 'Green' : 'Red', width: '15%' }}>{taskDetails.isCompleted === true ? 'Yes' : 'No'}</td>
                                                                                                                                    <td style={{ width: '20%' }}>{taskDetails.staffComment}</td>
                                                                                                                                    <td style={{ width: '20%' }}>{taskDetails.inchargeComment}</td>
                                                                                                                                    <td style={{ width: '15%' }} className="text-center_">{taskDetails.handoverDetails === null ? 'No' : 'Yes'}</td>
                                                                                                                                    <td style={{ width: '10%' }} className="text-center_">
                                                                                                                                        {taskDetails.isCompleted === false && taskDetails.staffComment !== null && taskDetails.isDNC === false
                                                                                                                                            ?
                                                                                                                                            <>
                                                                                                                                                <i className="bi bi-check-circle-fill text-success fs-5 pointer" onClick={() => { setShowConfirmationModal(1); setTaskDetails(taskDetails) }}></i>
                                                                                                                                                <i className="bi bi-x-circle-fill text-danger fs-5 pointer" onClick={() => { setShowRejectDutyModal(1); setTaskDetails(taskDetails); }}></i>
                                                                                                                                            </>
                                                                                                                                            : ''}
                                                                                                                                        {taskDetails.isCompleted === false && taskDetails.staffComment !== null && taskDetails.inchargeComment !== null && taskDetails.isDNC === true && taskDetails.isHandover === false
                                                                                                                                            ?
                                                                                                                                            <>
                                                                                                                                                <i className="fa-solid fa-money-bill-transfer pointer" title="Handover Order" onClick={() => { handleOpenHandoverModal(taskDetails) }}></i>
                                                                                                                                            </>
                                                                                                                                            : ''}
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            );
                                                                                                                        
                                                                                                                    })}
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                ) : <td className="notask">Task Not Assign</td>}
                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* --------------------------------------Accordian Section HR Duties----------------------------------------------- */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <div className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#HRDuties" aria-expanded="false" aria-controls="HRDuties">
                                            HR Duty
                                        </div>
                                    </h2>
                                    <div id="HRDuties" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body accod-in">

                                            {/* -----------------------------------------start Tab Section-------------------------------------------- */}

                                            <div className='orders-navtabs'>
                                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="HRFixed-tab" data-bs-toggle="tab" data-bs-target="#HRFixed" type="button" role="tab" aria-controls="HRFixed" aria-selected="true">HR Fixed Duty</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="HRDailyDuty-tab" data-bs-toggle="tab" data-bs-target="#HRDailyDuty" type="button" role="tab" aria-controls="HRDailyDuty" aria-selected="false">HR Daily Duty</button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="tab-content" id="myTabContent">

                                                {/* --------------------------------Start HR Fixed Duty Section-------------------------------------------- */}
                                                <div className="tab-pane fade show active intab" id="HRFixed" role="tabpanel" aria-labelledby="HRFixed-tab">
                                                    <div className='handlser customHeading'>
                                                        <Heading text={t("HR Fixed Duty")} />
                                                        <div style={{ position: 'relative' }}>
                                                            <input type="text" className='form-control form-control-sm' name="searchbox-HR-Fixed-Duty" placeholder={t("Search")} onChange={handleSearch} />
                                                            <span className="tblsericon"><i className="fas fa-search"></i></span>
                                                        </div>
                                                    </div>
                                                    <div className="med-table-section" style={{ "max-height": "65vh", minHeight: '30vh' }}>
                                                        <table className="med-table border striped_">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-center" style={{ "width": "3%" }}>#</th>
                                                                    <th style={{ width: '7%' }}>Staff Name</th>
                                                                    <th style={{ width: '90%', padding: '0px' }}>
                                                                        <div style={{ overflow: 'auto' }}>
                                                                            <table className="med-table_" style={{ width: '100%', minWidth: '1000px' }}>
                                                                                <tr>
                                                                                    <th style={{ width: '20%', borderBottom: "0" }}>Patient Name</th>
                                                                                    <th style={{ width: '10%', borderBottom: "0" }}>Shift</th>
                                                                                    <th style={{ width: '70%', padding: '0px', borderBottom: "0", borderTop: "0" }}>
                                                                                        <div>
                                                                                            <table className="med-table_" style={{ minWidth: '600px' }}>
                                                                                                <tr>
                                                                                                    <th style={{ width: '20%', borderBottom: "0" }}>Order</th>
                                                                                                    <th className="text-center_" style={{ width: '15%', borderBottom: "0" }}>Completed</th>
                                                                                                    <th style={{ width: '20%', borderBottom: "0" }}>Staff Comment</th>
                                                                                                    <th style={{ width: '20%', borderBottom: "0" }}>Incharge Comment</th>
                                                                                                    <th className="text-center_" style={{ width: '15%', borderBottom: "0" }}>Task Handover</th>
                                                                                                    <th className="text-center_" style={{ width: '10%', borderBottom: "0" }}>Action</th>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </div>
                                                                                    </th>

                                                                                </tr>
                                                                            </table>
                                                                        </div>
                                                                    </th>

                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {hrFixedDutyList && hrFixedDutyList.map((list, ind) => {
                                                                    return (
                                                                        <tr key={list.id}>
                                                                            <td className="text-center" style={{ "width": "3%" }}>{ind + 1}</td>
                                                                            <td style={{ width: '7%' }}>{list.assignedToName}</td>
                                                                            <td className="border-bottom-none_" style={{ width: '90%', padding: '0' }}>
                                                                                <div style={{ maxHeight: '250px', overflow: 'auto' }}>
                                                                                    <table className="med-table border" style={{ minWidth: '1000px' }}>
                                                                                        <tbody>
                                                                                            {list.patientDetails && list.patientDetails.map((patientDetails, i) => {
                                                                                                return (
                                                                                                    <tr key={i + 1}>
                                                                                                        <td style={{ width: '20%' }}>
                                                                                                            <span style={{ fontWeight: 'bold' }}>{patientDetails.patientName}</span>
                                                                                                            <span>({patientDetails.uhID})</span>
                                                                                                        </td>
                                                                                                        <td style={{ width: '10%' }}>{patientDetails.shiftName}</td>
                                                                                                        {patientDetails.taskDetails.length !== 0 ? (
                                                                                                            <td style={{ width: '70%', padding: '0' }}>
                                                                                                                <div>
                                                                                                                    <table className="med-table border_ borderT0" style={{ minWidth: '600px' }}>
                                                                                                                        <tbody>
                                                                                                                            {patientDetails.taskDetails && patientDetails.taskDetails.map((taskDetails, pi) => {
                                                                                                                               
                                                                                                                                    return (
                                                                                                                                        <tr key={pi + 1}>
                                                                                                                                            <td style={{ color: taskDetails.isCompleted === true ? 'Green' : 'Red', width: '20%' }}>{taskDetails.categoryName}</td>
                                                                                                                                            <td className="text-center_" style={{ color: taskDetails.isCompleted === true ? 'Green' : 'Red', width: '15%' }}>{taskDetails.isCompleted === true ? 'Yes' : 'No'}</td>
                                                                                                                                            <td style={{ width: '20%' }}>{taskDetails.staffComment}</td>
                                                                                                                                            <td style={{ width: '20%' }}>{taskDetails.inchargeComment}</td>
                                                                                                                                            <td style={{ width: '15%' }} className="text-center_">{taskDetails.handoverDetails === null ? 'No' : 'Yes'}</td>
                                                                                                                                            <td style={{ width: '10%' }} className="text-center_">
                                                                                                                                                {taskDetails.isCompleted === false && taskDetails.staffComment !== null && taskDetails.isDNC === false
                                                                                                                                                    ?
                                                                                                                                                    <>
                                                                                                                                                        <i className="bi bi-check-circle-fill text-success fs-5 pointer" onClick={() => { setShowConfirmationModal(1); setTaskDetails(taskDetails) }}></i>
                                                                                                                                                        <i className="bi bi-x-circle-fill text-danger fs-5 pointer" onClick={() => { setShowRejectDutyModal(1); setTaskDetails(taskDetails); }}></i>
                                                                                                                                                    </>
                                                                                                                                                    : ''}
                                                                                                                                                {taskDetails.isCompleted === false && taskDetails.staffComment !== null && taskDetails.inchargeComment !== null && taskDetails.isDNC === true && taskDetails.isHandover === false
                                                                                                                                                    ?
                                                                                                                                                    <>
                                                                                                                                                        <i className="fa-solid fa-money-bill-transfer pointer" title="Handover Order" onClick={() => { handleOpenHandoverModal(taskDetails) }}></i>
                                                                                                                                                    </>
                                                                                                                                                    : ''}
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    );
                                                                                                                                
                                                                                                                               
                                                                                                                            })}
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        ) : <td className="notask">Task Not Assign</td>}
                                                                                                    </tr>
                                                                                                )
                                                                                            })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* -----------------------------------End Order Section---------------------------------------------- */}

                                                {/* -----------------------------------Start HR Duty Section---------------------------------------------- */}

                                                <div className="tab-pane fade intab" id="HRDailyDuty" role="tabpanel" aria-labelledby="HRDailyDuty-tab">

                                                <div className='handlser customHeading'>
                                                        <Heading text={t("HR Daily Duty")} />
                                                        <div style={{ position: 'relative' }}>
                                                            <input type="text" className='form-control form-control-sm' placeholder={t("Search")} name="search-HR-DailyDuty" onChange={handleSearch} />
                                                            <span className="tblsericon"><i className="fas fa-search"></i></span>
                                                        </div>
                                                    </div>
                                                    <div className="med-table-section" style={{ "max-height": "65vh", minHeight: '30vh' }}>
                                                        <table className="med-table border striped_">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center" style={{ "width": "5%" }}>#</th>
                                                                <th>Designation</th>
                                                                <th>Task Category</th>
                                                                <th>Task</th>
                                                            </tr>
                                                        </thead>

                                                            <tbody>
                                                                {hrDailyDutyList && hrDailyDutyList.map((val, ind) => {
                                                                    return (
                                                                    <tr>
                                                                        <td className="text-center">{ind + 1}</td>
                                                                        <td>{val.designationName}</td>
                                                                        <td>{val.categoryDetails ? val.categoryDetails.categoryName : ''}</td>
                                                                        <td>{val.task}</td>
                                                                    </tr>
                                                                )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* -----------------------------------Enf HR Duty Section---------------------------------------------- */}

                                            </div>
                                            {/* -----------------------------------------End Tab Section-------------------------------------------- */}

                                        </div>
                                    </div>
                                </div>
                                {/* --------------------------------------Accordian Section Automate----------------------------------------------- */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <div className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#automte" aria-expanded="false" aria-controls="automte">
                                            Automate
                                        </div>
                                    </h2>
                                    <div id="automte" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body accod-in">
                                            <div className='handlser customHeading'>
                                                <Heading text={t("Automate")} />
                                                <div style={{ position: 'relative' }}>
                                                    <input type="text" className='form-control form-control-sm' placeholder={t("Search")} name="search-Automate-Duty" onChange={handleSearch} />
                                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                                </div>
                                            </div>
                                            <div className="med-table-section" style={{ "max-height": "65vh", minHeight: '30vh' }}>
                                                <table className="med-table border striped_">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center" style={{ "width": "3%" }}>#</th>
                                                            <th style={{ width: '7%' }}>Staff Name</th>
                                                            <th style={{ width: '90%', padding: '0px' }}>
                                                                <div style={{ overflow: 'auto' }}>
                                                                    <table className="med-table_" style={{ width: '100%', minWidth: '1000px' }}>
                                                                        <tr>
                                                                            <th style={{ width: '20%', borderBottom: "0" }}>Patient Name</th>
                                                                            <th style={{ width: '10%', borderBottom: "0" }}>Shift</th>
                                                                            <th style={{ width: '70%', padding: '0px', borderBottom: "0", borderTop: "0" }}>
                                                                                <div>
                                                                                    <table className="med-table_" style={{ minWidth: '600px' }}>
                                                                                        <tr>
                                                                                            <th style={{ width: '20%', borderBottom: "0" }}>Order</th>
                                                                                            <th className="text-center_" style={{ width: '15%', borderBottom: "0" }}>Completed</th>
                                                                                            <th style={{ width: '20%', borderBottom: "0" }}>Staff Comment</th>
                                                                                            <th style={{ width: '20%', borderBottom: "0" }}>Incharge Comment</th>
                                                                                            <th className="text-center_" style={{ width: '15%', borderBottom: "0" }}>Task Handover</th>
                                                                                            <th className="text-center_" style={{ width: '10%', borderBottom: "0" }}>Action</th>
                                                                                        </tr>
                                                                                    </table>
                                                                                </div>
                                                                            </th>

                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </th>

                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {automateDutyList && automateDutyList.map((list, ind) => {
                                                            return (
                                                                <tr key={list.id}>
                                                                    <td className="text-center" style={{ "width": "3%" }}>{ind + 1}</td>
                                                                    <td style={{ width: '7%' }}>{list.assignedToName}</td>
                                                                    <td className="border-bottom-none_" style={{ width: '90%', padding: '0' }}>
                                                                        <div style={{ maxHeight: '250px', overflow: 'auto' }}>
                                                                            <table className="med-table border" style={{ minWidth: '1000px' }}>
                                                                                <tbody>
                                                                                    {list.patientDetails && list.patientDetails.map((patientDetails, i) => {
                                                                                        return (
                                                                                            <tr key={i + 1}>
                                                                                                <td style={{ width: '20%' }}>
                                                                                                    <span style={{ fontWeight: 'bold' }}>{patientDetails.patientName}</span>
                                                                                                    <span>({patientDetails.uhID})</span>
                                                                                                </td>
                                                                                                <td style={{ width: '10%' }}>{patientDetails.shiftName}</td>
                                                                                                {patientDetails.taskDetails.length !== 0 ? (
                                                                                                    <td style={{ width: '70%', padding: '0' }}>
                                                                                                        <div>
                                                                                                            <table className="med-table border_ borderT0" style={{ minWidth: '600px' }}>
                                                                                                                <tbody>
                                                                                                                    {patientDetails.taskDetails && patientDetails.taskDetails.map((taskDetails, pi) => {
                                                                                                                     
                                                                                                                            return (
                                                                                                                                <tr key={pi + 1}>
                                                                                                                                    <td style={{ color: taskDetails.isCompleted === true ? 'Green' : 'Red', width: '20%' }}>{taskDetails.categoryName}</td>
                                                                                                                                    <td className="text-center_" style={{ color: taskDetails.isCompleted === true ? 'Green' : 'Red', width: '15%' }}>{taskDetails.isCompleted === true ? 'Yes' : 'No'}</td>
                                                                                                                                    <td style={{ width: '20%' }}>{taskDetails.staffComment}</td>
                                                                                                                                    <td style={{ width: '20%' }}>{taskDetails.inchargeComment}</td>
                                                                                                                                    <td style={{ width: '15%' }} className="text-center_">{taskDetails.handoverDetails === null ? 'No' : 'Yes'}</td>
                                                                                                                                    <td style={{ width: '10%' }} className="text-center_">
                                                                                                                                        {taskDetails.isCompleted === false && taskDetails.staffComment !== null && taskDetails.isDNC === false
                                                                                                                                            ?
                                                                                                                                            <>
                                                                                                                                                <i className="bi bi-check-circle-fill text-success fs-5 pointer" onClick={() => { setShowConfirmationModal(1); setTaskDetails(taskDetails) }}></i>
                                                                                                                                                <i className="bi bi-x-circle-fill text-danger fs-5 pointer" onClick={() => { setShowRejectDutyModal(1); setTaskDetails(taskDetails); }}></i>
                                                                                                                                            </>
                                                                                                                                            : ''}
                                                                                                                                        {taskDetails.isCompleted === false && taskDetails.staffComment !== null && taskDetails.inchargeComment !== null && taskDetails.isDNC === true && taskDetails.isHandover === false
                                                                                                                                            ?
                                                                                                                                            <>
                                                                                                                                                <i className="fa-solid fa-money-bill-transfer pointer" title="Handover Order" onClick={() => { handleOpenHandoverModal(taskDetails) }}></i>
                                                                                                                                            </>
                                                                                                                                            : ''}
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            );
                                                                                                                    })}
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                ) : <td className="notask">Task Not Assign</td>}
                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* --------------------------------------Accordian Section Support Ticket----------------------------------------------- */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <div className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#supportTicket" aria-expanded="false" aria-controls="supportTicket">
                                            Support Ticket
                                        </div>
                                    </h2>
                                    <div id="supportTicket" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body loader accod-in">
                                            No data available
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>






                    {/*  <!-- Modal -->  */}
                    {showRejectDutyModal === 1 ?
                        <div className={`modal d-${showRejectDutyModal === 1 ? "block" : ""}`} id="rejectModal" data-bs-backdrop="static">
                            <div className="modal-dialog modalDelete">
                                <div className="modal-content">
                                    <div className="modal-body modelbdy text-center">
                                        <div className='popDeleteIcon' style={{ paddingRight: '5px' }}><i className="fa-solid fa-square-xmark"></i></div>
                                        <div className='popDeleteTitle mt-3'>Reject</div>
                                        <div className='popDeleteContent'>Are you sure want to reject?</div>
                                        <div className='popDeleteContent'>
                                            <textarea className="form-control form-control-sm" placeholder="Fill Remark" value={rejectDutyRemark} onChange={handleChangeRejectDuty}></textarea>
                                            <span className="text-danger d-block" id="rejectDutyModalErr"></span>
                                        </div>
                                    </div>
                                    <div className="modal-footer1 text-center">
                                        <button type="button" className="btncancel popBtnCancel me-2" onClick={handleCloseRejectDutyPopUp}>Cancel</button>
                                        <button type="button" className="btn-delete popBtnDelete" onClick={handleReject} data-bs-dismiss="modal">Reject</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''}
                    {/*  <!-- Modal -->  */}
                    {showConfirmationModal === 1 ?
                        <div className={`modal d-${showConfirmationModal === 1 ? "block" : "none"}`} id="confirmationModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                            <div className="modal-dialog modalDelete">
                                <div className="modal-content">

                                    <div className="modal-body modelbdy text-center">
                                        <div className='popDeleteIcon'><i className="fa-solid fa-question"></i></div>
                                        <div className='popDeleteContent'>Do you want to approve duty?</div>
                                    </div>
                                    <div className="modal-footer1 text-center">

                                        <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal" onClick={handleCloseConfirmationModal}>Cancel</button>
                                        <button type="button" className="btn-delete popBtnDelete" onClick={handleApproveOrder} data-bs-dismiss="modal">Yes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''}
                    {showHandoverModal === 1 ?
                        <div className={`modal d-${showHandoverModal === 1 ? "block" : "none"}`} id="handoverModal" tabIndex="-1" aria-labelledby="handoverModalLabel" aria-hidden="true">
                            <div className="modal-dialog modalDelete">
                                <div className="modal-content">

                                    <div className="modal-body">
                                        <div className='popDeleteIcon text-center'><i className="fa-solid fa-money-bill-transfer"></i></div>
                                        <div className='popDeleteContent text-center'>Handover Order</div>
                                        <div className="col-12 mt-3 mb-4">
                                            <label htmlFor="shiftId" className="form-label position-relative m-0">Shift<span className="starMandatory">*</span></label>
                                            {shiftList &&
                                                <DropdownWithSearch
                                                    defaulNname="Select Shift"
                                                    name="shiftId"
                                                    list={shiftList.map(val => ({
                                                        id: val.id,
                                                        displayName: `${val.shiftName}  (${val.shiftTimeFrom} - ${val.shiftTimeTo})`,
                                                    }))}
                                                    valueName="id"
                                                    displayName="displayName"
                                                    editdata={editShiftDdl}
                                                    getvalue={handleChange}
                                                    clear={clearDropdown}
                                                    clearFun={handleClearDdl}
                                                />
                                            }
                                            <small id="errShift" className="invalid-feedback" style={{ display: 'none' }}></small>
                                        </div>
                                        <div className="col-12 mt-2">
                                            <label htmlFor="assignedUserId" className="form-label position-relative m-0">Nurse<span className="starMandatory">*</span></label>
                                            {nurseList &&
                                                <DropdownWithSearch
                                                    defaulNname="Select Nurse"
                                                    name="assignedUserId"
                                                    list={nurseList}
                                                    valueName="assignedUserId"
                                                    displayName="name"
                                                    editdata={editNurseDdl}
                                                    getvalue={handleChange}
                                                    clear={clearDropdown}
                                                    clearFun={handleClearDdl}
                                                />
                                            }
                                            <small id="errNurse" className="invalid-feedback" style={{ display: 'none' }}></small>

                                        </div>
                                    </div>
                                    <div className="modal-footer1 text-center">
                                        <div>
                                            {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                                <>
                                                    {showHandoverToster === 1 ?
                                                        <Toster value={tosterValue} message={tosterMessage} />
                                                        : <div>
                                                            <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal" onClick={handleCloseHandoverModal}>Cancel</button>
                                                            <button type="button" className="btn-delete popBtnDelete" onClick={handleHandoverOrder} data-bs-dismiss="modal">Handover</button>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''}
                    {/* {/ -----------------------End  Modal Popup--------------------- /} */}
                </div>
                {
                    showLoder === 1 ? <Loader val={showLoder} /> : ""
                }
                {/* Toaster */}
                {
                    isShowToaster === 1 ?
                        <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                }
                {
                    showAlertToster === 1 ?
                        <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                }
            </section>
        </>
    )
}