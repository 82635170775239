import React, { useEffect, useState } from 'react'
import Heading from '../../../../../../Component/Heading'
import BoxContainer from '../../../../../../Component/BoxContainer'
import TableContainer from '../../../../../../Component/TableContainer'
import Toster from '../../../../../../Component/Toster'
import TosterUnderProcess from '../../../../../../Component/TosterUnderProcess'
import IconEdit from '../../../../../../assets/images/icons/IconEdit.svg'
import IconDelete from '../../../../../../assets/images/icons/IconDelete.svg'
import Search from '../../../../../../Code/Serach'
import saveButtonIcon from '../../../../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../../../../assets/images/icons/clear.svg';
import IconPhysicalActivity from '../../../../../../assets/images/icons/IconPhysicalActivity.svg';
import IconIcuAdd from '../../../../../../assets/images/icons/IconIcuAdd.svg';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import Loader from '../../../../../../Component/Loader'
import SuccessToster from '../../../../../../Component/SuccessToster'
import AlertToster from '../../../../../../Component/AlertToster'
import GetAllPhysicalActivityTraker from '../ICUChartAPi/GetAllPhysicalActivityTraker'
import DropdownWithSearch from '../../../../../../Component/DropdownWithSearch'
import GetAllPhysicalActivityTracker from '../../PatientPhysicalActivity/API/GetAllPhysicalActivityTracker'
import GetActivityList from '../../PatientPhysicalActivity/API/GetActivityList'
import InsertPatientPhysicalActivity from '../../PatientPhysicalActivity/API/InsertPatientPhysicalActivity'
import ValidationICUChartPhysicalActivity from '../ICUChartValidation/ValidationICUChartPhysicalActivity'
import GetCurrentPatientPhysicalActivity from '../ICUChartAPi/GetCurrentPatientPhysicalActivity'

export default function ICUChartPhysicalActivity(props) {

    let [rowId, setRowId] = useState('')
    let [physicalActivity, setPhysicalActivity] = useState([])
    let [sendForm, setSendForm] = useState({ "userId": window.userId, "clientId": window.clientId })
    let [clearDropdown, setClearDropdown] = useState(0)
    let [updateBool, setUpdateBool] = useState(0)

    let [showUnderProcess, setShowUnderProcess] = useState(0)
    let [showToster, setShowToster] = useState(0)
    let [tosterMessage, setTosterMessage] = useState("")
    let [tosterValue, setTosterValue] = useState(0)
    let [fromDate, setfromDate] = useState("")
    let [toDate, settoDate] = useState("")

    let [showLoder, setShowLoder] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [isShowToaster, setisShowToaster] = useState(0);
    const [activityList, setActivityList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const { t } = useTranslation();
    document.body.dir = i18n.dir();


    console.log('physicalActivity', physicalActivity)
    console.log('from Date check', fromDate)


    //Get Physical Activity
    const getPhysicalActivity = async () => {
        try {
            const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;
            setShowLoder(1);
            const response = await GetCurrentPatientPhysicalActivity(uhID);
            if (response && response.status === 1) {
                setPhysicalActivity(response.responseValue);
            } else {
                setShowAlertToster(1);
                setShowErrMessage(response ? response.responseValue : 'Unexpected error');
            }
        } catch (error) {
            setShowAlertToster(1);
            setShowErrMessage('Unexpected error');
        } finally {
            setShowLoder(0);
            setTimeout(() => {
                setShowAlertToster(0);
            }, 1500);
        }
    };
    const getDisplayText = (activity, hour) => {
        const { actualHour, totalTimeInMinutes } = activity;
        const activityHour = parseInt(actualHour.split(":")[0], 10);
        if (hour === activityHour) {
            return `${totalTimeInMinutes} min`;
        } else {
            return "-";
        }
    };


    const handleShowModal = () => {
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const getData = async () => {
        const activityListResp = await GetActivityList();

        if (activityListResp.status === 1) {
            setActivityList(activityListResp.responseValue);
        }
    };

    let handleDateChange = (e) => {
        if (e.target.name === 'fromDateTime') {
            console.log('fromDateTime 1', e.target.name)
            setfromDate(e.target.value);

        } else if (e.target.name === 'toDateTime') {
            settoDate(e.target.value);
        }
    }

    // Handle Change
    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'fromDateTime') {
            const formattedDate = new Date(value).toISOString();
            setfromDate(formattedDate);
        }

        setSendForm((prevForm) => ({
            ...prevForm,
            [name]: value
        }));
    };


    //Handle Save
    let saveForm = async () => {
        const d = new Date();
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const day = d.getDate();
        const todayDate = year + '-' + (month.toString().length === 1 ? '0' + month : month) + '-' + (day.toString().length === 1 ? '0' + day : day);
        const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;
        //const formattedDate = `${parsedDate.toISOString().slice(0, 10)} ${parsedDate.toISOString().slice(11, 16)}`;

        console.log('fromDate', fromDate);
        console.log('todayDate', todayDate + ' ' + fromDate);
        let valresponse = ValidationICUChartPhysicalActivity(sendForm.activityId, fromDate, sendForm.totalTimeInMinutes);
        const formattedDate = todayDate + ' ' + fromDate;
        if (valresponse) {
            setShowUnderProcess(1)
            let response = await InsertPatientPhysicalActivity(sendForm, formattedDate, uhID, window.clientId);
            if (response.status === 1) {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage("Data Save Successfully!")
                setTosterValue(0)
                setTimeout(() => {
                    setShowToster(0)
                    handleClear(1);
                    handleCloseModal();
                }, 2000)
                getPhysicalActivity();
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage(response.responseValue)
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }
        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Field can't be blank!")
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }

    //Handle Clear
    let handleClear = (value) => {
        setSendForm({ "userId": window.userId, "clientId": window.clientId })
        setClearDropdown(value)
        document.getElementById('fromDateTime').value = '';
        document.getElementById('toDateTime').value = '';
        document.getElementById('remark').value = '';

    }


    useEffect(() => {
        getPhysicalActivity();
        getData();
    }, [])
    return (
        <>
            <div className='icuChartHeading  mb-2'>
                <div className='icuChartHeading-inn'>
                    <div className='ICUHead-lft'>
                        <span><img src={IconPhysicalActivity} alt='' /></span>
                        <span><h3>Physical Activity</h3></span>
                    </div>
                </div>
                <div className='icuChartHeading-inn pointSpan' style={{display:props.isPrint===1?"none":"block"}}>
                    <span><img src={IconIcuAdd} alt='' onClick={handleShowModal} /></span>
                </div>
            </div>
            <div className="med-table-section IcuPhysicalTbl" style={{ "height": "35vh" }}>
                {physicalActivity.length === 0 ? (
                    <div className="no-dataavailble">
                        No data available.
                    </div>
                ) : (
                    <table className="med-table striped table-responsive">
                        <thead>
                            <tr>
                                <th style={{ minWidth: "150px" }}>Activity</th>
                                {[...Array(24)].map((_, index) => (
                                    <th key={index + 1} className="text-center">
                                        {index < 9 ? `0${index + 1}:00` : `${index + 1}:00`}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {physicalActivity.map((activity) => (
                                <tr key={activity.activityID}>
                                    <td>{activity.activityName}</td>
                                    {[...Array(24)].map((_, index) => (
                                        <td key={index + 1} className="text-center" style={{ whiteSpace: 'nowrap' }}>
                                            {index + 1 === parseInt(activity.actualHour.split(":")[0], 10) ? `${activity.totalTimeInMinutes} min` : "-"}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                {/* Modal */}
                <div className={`modal fade bk-dropfilter ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header modalheading">
                                <h5 className="modal-title">Patient Physical Activity</h5>
                                <i className="fa fa-times" onClick={handleCloseModal}></i>
                            </div>
                            <div className="modal-body">
                                {/* <div className="row">
                                        <div className='col-md-12'>
                                            <Heading text="Patient Physical Activity" />
                                        </div>
                                    </div> */}

                                <div className='med-box1'>
                                    <div className='inner-content1'>
                                        <div className='icu-pophead d-flex flex-wrap align-content-end'>
                                            <div className='icuPhyInput mb-3 commnet-txt'>
                                                <label htmlFor="activityId" className="form-label">Activity List<span className="icustarMandatory">*</span></label>
                                                <div>
                                                    {activityList && <DropdownWithSearch defaulNname="Select activity" name="activityId" list={activityList} valueName="id" displayName="activityName" editdata="" getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                                </div>
                                            </div>

                                            <div className='icuPhyInput mb-3  commnet-txt'>
                                                <label for="fromDateTime" className="form-label"> From Date Time <span className="icustarMandatory">*</span> </label>
                                                <input type="time" className="form-control form-control-sm" id="fromDateTime" placeholder="Enter Start Date and Time" name="fromDateTime" onChange={handleDateChange} />
                                            </div>
                                            <div className='icuPhyInput mb-3 commnet-txt'>
                                                <label for="totalTimeInMinutes" className="form-label">Total time In Minutes<span className="icustarMandatory">*</span> </label>
                                                <input type="text" className="form-control form-control-sm" id="toDateTime" placeholder="Enter Total Time In Minutes" name="totalTimeInMinutes" onChange={handleChange} />

                                            </div>

                                            <div className='icuPhyInput mb-3 commnet-txt'>
                                                <label htmlFor="remark" className="form-label">Remark</label>
                                                <input type="text" className="form-control form-control-sm" id="remark" name='remark' onChange={handleChange} placeholder="Enter Remark" />
                                            </div>

                                            <div className="relative mt-2 icubtns">
                                                {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                                    <>
                                                        {showToster === 1 ?
                                                            <Toster value={tosterValue} message={tosterMessage} />

                                                            : <div>

                                                                <>
                                                                    <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-2" onClick={saveForm}><img src={saveButtonIcon} className='icnn' alt='' />{t("Save")}</button>
                                                                    <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={() => { handleClear(1) }}><img src={clearIcon} className='icnn' alt='' />{t("Clear")}</button>
                                                                </>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>

                {/* {
                    showLoder === 1 ? <Loader val={showLoder} /> : ""
                }
                {
                    isShowToaster === 1 ?
                        <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                }
                {
                    showAlertToster === 1 ?
                        <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                } */}
            </div>
        </>
    )
}
