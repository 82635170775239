import React from 'react'
import TableContainer from '../../../Component/TableContainer'
import Heading from '../../../Component/Heading'
import CurrentDateBacklogStatus from './CurrentDateBacklogStatus'
import DeveloperProductivity from './DeveloperProductivity'
import StoryList from './StoryList'
import ProjectBacklogStatus from './ProjectBacklogStatus';
import noData from '../../../assets/images/icons/No data-rafiki.svg';
export default function AdminDashBoard() {
    return (
        <>
            <section className="main-content pt-3 mt-5">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-12 maindashb">
                            <Heading text='DashBoard' />
                        </div>

                        <div className="col-md-5 col-sm-12 mt-1 maindashb1 pe-0">
                            <div className='backlogStatus relative'>
                                <DeveloperProductivity />
                            </div>
                        </div>

                        <div className="col-md-7 col-sm-12 mt-1 maindashb1">
                            <div className='backlogStatus med-table-section relative' style={{ height: '45vh' }}>
                                <CurrentDateBacklogStatus />
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ display: "none1" }}>
                        <div className="col-md-5 col-sm-12 mt-3 maindashb1 pe-0">
                            <div className='backlogStatus med-table-section relative' style={{ height: '42vh' }}>
                                <StoryList />
                            </div>

                        </div>
                        <div className="col-md-4 col-sm-12 mt-3 maindashb1 pe-0">
                            <div className='backlogStatus med-table-section relative' style={{ height: '42vh' }}>
                                <ProjectBacklogStatus />
                            </div>

                        </div>
                        <div className="col-md-3 col-sm-12 mt-3 maindashb1">
                            <div className='backlogStatus med-table-section'>
                                <div className='handlser'>
                                </div>
                                    <Heading text='Notifications' />
                                    <div className='relative' style={{ height: '343px', overflow:'auto' }}>
                                    <TableContainer>
                                        {/* <img src={noData} alt='no data' /> */}
                                        <img className='haveNodataImg' src={noData} alt="No Data" />
                                    </TableContainer>
                                    </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
