import React, { useEffect, useState } from 'react'
import i18n, { t } from "i18next";
import TableContainer from '../../../Component/TableContainer';
import Heading from '../../../Component/Heading';
import PersonWorkspace from '../../../assets/images/dmsimg/PersonWorkspace.svg'
import clearIcon from '../../../assets/images/icons/clear.svg';
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg'
import DropdownWithSearch from '../../../Component/DropdownWithSearch'
import editBtnIcon from '../../../assets/images/icons/edit.svg';
import deleteBtnIcon from '../../../assets/images/icons/delete.svg';
import GetGodownMaster from '../../API/Master/GodownMaster/GetGodownMaster';
import GetVendorMaster from '../../API/VendorMaster/GetVendorMaster';
import GetServiceTypeMaster from '../../API/ServiceTypeMaster/GetServiceTypeMaster';
import GetInventoryItemMaster from '../../API/CreateNewItem/GetInventoryItemMaster';
import SaveStoockIn from '../../API/StockIn/SaveStoockIn';
import Loader from '../../../Component/Loader';
import SuccessToster from '../../../Component/SuccessToster';
import AlertToster from '../../../Component/AlertToster';
import TosterUnderProcess from '../../../Component/TosterUnderProcess';
import Toster from '../../../Component/Toster';
import GetAdditionalChargeType from '../../API/Master/AdditionalChargeType/GetAdditionalChargeType';

export default function StockIn() {
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    let [godownList, setGodownList] = useState("")
    let [supplierList, setSupplierList] = useState("")

    let [itemList, setItemList] = useState("")
    let [serviceList, setServiceList] = useState("")
    let [addChargeTypeList, setAddChargeTypeList] = useState("")

    // console.log("itemList----->", itemList);

    let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [showToster, setShowToster] = useState(0);
    let [tosterMessage, setTosterMessage] = useState("");
    let [tosterValue, setTosterValue] = useState(0);
    let [showLoder, setShowLoder] = useState(0);
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [updateBool, setUpdateBool] = useState(0);

    let [clearDropdown, setClearDropdown] = useState(0)
    let [editGodown, setEditGodown] = useState("")
    let [editItemName, setEditItemName] = useState("")
    let [editSupplier, setEditSupplier] = useState("")
    let [editAdditionalCharge, setEditAdditionalCharge] = useState("")


    const [rows, setRows] = useState([{ id: 1, selectedItem: null }]);
    const [chargerows, setChargeRows] = useState([{ id: 1, selectedChargeType: null }]);


    //Get Data
    const getdata = async () => {
        const [godownResponse, supplierResponse, itemResponse, serviceResponse, addChargeResponse] = await Promise.all([
            GetGodownMaster(clientID),
            GetVendorMaster(clientID),
            GetInventoryItemMaster(clientID),
            GetServiceTypeMaster(clientID),
            GetAdditionalChargeType(clientID)
        ]);
        if (godownResponse.status === 1) {
            setGodownList(godownResponse.responseValue);
        }
        if (supplierResponse.status === 1) {
            setSupplierList(supplierResponse.responseValue);
        }
        if (itemResponse.status === 1) {
            setItemList(itemResponse.responseValue);
        }

        if (serviceResponse.status === 1) {
            setServiceList(serviceResponse.responseValue);
        }
        if (addChargeResponse.status === 1) {
            setAddChargeTypeList(addChargeResponse.responseValue);
        }
    }



    let [sendForm, setSendForm] = useState({
        "userID": window.userId,
        "clientID": window.clientId,
        "poNo": "",
        "billNo": "",
        "challanNo": "",
        "billDate": "",
        "godownID": 0,
        "supplierID": 0,
        "totalAmount": 0,
        "totalDiscount": 0,
        "totalGST": 0,
        "totalGrossAmount": 0,
        "additionalChargeTypeID": 0,
        "additionalChargeAmount": "",
        "freightAmount": "",
        "inwardSubJson": ""
    })

    const newRow = {
        itemID: 0,
        brandID: 0,
        itemCode: "",
        batchNo: "",
        expDate: "",
        unitID: 0,
        qty: "",
        rate: "",
        disPer: "",
        discount: "",
        gstPerr: "",
        gst: "",
        totalAmount: "",
        serviceTypeID: 0,
        serviceEngineerName: "",
        serviceEngineerContact: "",
        discountType: ""
    };
    const [inStockRows, setInStockRows] = useState([newRow]);

    //Handle Change
    let handleChange = (e, index) => {
        clearValidationErrMessage();
        let name = e.target.name;
        let value = e.target.value;

        if (name === "disPer" && parseInt(value, 10) > 100) {
            return;
        }
        setEditGodown("");
        setEditSupplier("");
        setEditItemName("");
        setEditAdditionalCharge("");
        setSendForm(sendForm => ({
            ...sendForm,
            [name]: value
        }));

        const updatedRows = [...inStockRows];
        if (name === "itemID" || name === "brandID" || name === "itemCode" || name === "batchNo" || name === "expDate" || name === "unitID" || name === "qty" || name === "rate" || name === "disPer" || name === "discount" || name === "gstPerr" || name === "gst" || name === "totalAmount" || name === "serviceTypeID" || name === "serviceEngineerName" || name === "serviceEngineerContact" || name === "discountType") {
            updatedRows[index][name] = value;

            if (name === "itemID") {
                const selectedItem = itemList.find((item) => item.id === parseInt(value));
                if (selectedItem) {
                    const parsedUnitOptions = selectedItem.jsonItemUnit ? JSON.parse(selectedItem.jsonItemUnit) : [];
                    const defaultUnit = parsedUnitOptions.find(unit => unit.isDefault === 1);
                    updatedRows[index].unitOptions = parsedUnitOptions;
                    updatedRows[index].unitID = defaultUnit ? defaultUnit.UnitId : '';
                    updatedRows[index].gstPerr = selectedItem.gstRatePer || 0;
                    updatedRows[index].brandOptions = selectedItem.jsonItemBrand ? JSON.parse(selectedItem.jsonItemBrand) : [];

                    updatedRows[index].selectedItem = selectedItem;
                } else {
                    updatedRows[index].unitOptions = [];
                    updatedRows[index].unitID = '';
                    updatedRows[index].gstPerr = '';
                    updatedRows[index].brandOptions = [];

                    updatedRows[index].selectedItem = null;
                }
            }

            if (name === "qty" || name === "rate" || name === "gstPerr" || name === "disPer" || name === "discount" || name === "totalAmount") {
                let discount = 0;
                let qty = parseFloat(updatedRows[index].qty || 0);
                let rate = parseFloat(updatedRows[index].rate || 0);
                let gstPerr = parseFloat(updatedRows[index].gstPerr || 0);
                let disPer = parseFloat(updatedRows[index].disPer || 0);
                let totalAmmountSub = parseFloat(qty * rate);
                let gst = (totalAmmountSub * (gstPerr / 100)).toFixed(2);
                updatedRows[index].gst = gst;

                if (updatedRows[index]["discountType"] !== "discountTypeRupee") {
                    discount = ((parseFloat(totalAmmountSub) + parseFloat(gst)) * (disPer / 100)).toFixed(2);
                } else {
                    discount = value;
                }
                updatedRows[index].discount = discount;
                let totalAmount = parseFloat(totalAmmountSub) + parseFloat(gst) - parseFloat(discount);
                updatedRows[index].totalAmount = totalAmount.toFixed(2);
            }

            if (name === "discountType") {
                if (value === "discountTypeRupee") {
                    updatedRows[index]["disPer"] = "";
                    updatedRows[index]["discount"] = "";
                    document.getElementById("disPerErr_" + index).style.display = "none";
                    document.getElementById("disPer_" + index).disabled = true;
                    document.getElementById("discount_" + index).disabled = false;
                } else {
                    updatedRows[index]["disPer"] = "";
                    updatedRows[index]["discount"] = "";
                    document.getElementById("disPerErr_" + index).style.display = "none";
                    document.getElementById("disPer_" + index).disabled = false;
                    document.getElementById("discount_" + index).disabled = true;
                }
                updatedRows[index]["discountType"] = value;
            }

            if (name === "discount") {
                if (parseFloat(value) > parseFloat(updatedRows[index].totalAmount)) {
                    document.getElementById("discountErr_" + index).style.display = "block";
                } else {
                    document.getElementById("discountErr_" + index).style.display = "none";
                }
            }
        }

        setRows(updatedRows);
    }

    //Table Row add and remove 
    const addStockRow = () => {
        setInStockRows((prevRows) => [...prevRows, newRow]);

    };

    const removeStockRow = (ind) => {
        let updateRow = [...inStockRows]
        if (inStockRows.length === 1) {
            updateRow.splice(ind, 1)
            setInStockRows([newRow])
        }
        else if (inStockRows.length > 1) {
            updateRow.splice(ind, 1)
            setInStockRows([...updateRow])
        }
    };

    // for additional charge rows
    const newChargeRow = {
        additionalChargeTypeID: 0,
        amount: "",
    };
    const [chargeTypeRows, setChargeTypeRows] = useState([newChargeRow]);

    //additional charge Row add and remove 
    const addChargeRow = () => {
        setChargeTypeRows((prevRows) => [...prevRows, newChargeRow]);

    };

    const removeChargeRow = (index) => {
        let updatedRows = [...chargeTypeRows]
        if (chargeTypeRows.length === 1) {
            updatedRows.splice(index, 1)
            setChargeTypeRows([newChargeRow])
        }
        else if (chargeTypeRows.length > 1) {
            updatedRows.splice(index, 1)
            setChargeTypeRows([...updatedRows])
        }
    };


    //Handle Change
    let handleChargeChange = (e, index) => {
        clearValidationErrMessage();
        let name = e.target.name;
        let value = e.target.value;
        setSendForm(sendForm => ({
            ...sendForm,
            [name]: value
        }))
        const updatedRows = [...chargeTypeRows];
        if (name === "additionalChargeTypeID" || name === "amount") {
            updatedRows[index][name] = value;
        }
        setChargeRows(updatedRows)
    }



    //Handle Save
    const handlerSave = async () => {
        clearValidationErrMessage();

        const inStockData = rows.map((row, index) => {
            return {
                itemID: rows[index].itemID,
                brandID: rows[index].brandID,
                itemCode: rows[index].itemCode,
                batchNo: rows[index].batchNo,
                expDate: rows[index].expDate,
                unitID: rows[index].unitID,
                qty: rows[index].qty,
                rate: rows[index].rate,
                discount: rows[index].discount,
                gst: rows[index].gst,
                totalAmount: rows[index].totalAmount,
                serviceTypeID: rows[index].serviceTypeID,
                serviceEngineerName: rows[index].serviceEngineerName,
                serviceEngineerContact: rows[index].serviceEngineerContact,
            }
        });
        const additionalChargeData = chargerows.map((row, index) => {
            return {
                additionalChargeTypeID: chargerows[index].additionalChargeTypeID,
                amount: chargerows[index].amount,

            }
        });


        if (inStockData.length === 0) {
            document.getElementById('errstockSub').innerHTML = "Select atleast one row";
            document.getElementById('errstockSub').style.display = "block";
            return;
        }

        const isValid = rows.every((row, index) => {
            const itemID = document.getElementById(`itemID_${index}`).value;
            const qty = document.getElementById(`qty_${index}`).value;
            const rate = document.getElementById(`rate_${index}`).value;

            if (itemID === '0') {
                document.getElementById(`errItemID_${index}`).innerHTML = "Please select an item.";
                document.getElementById(`errItemID_${index}`).style.display = "block";
                return false;
            }

            if (qty === '' || qty <= 0) {
                document.getElementById(`errQty_${index}`).innerHTML = "Please enter quantity.";
                document.getElementById(`errQty_${index}`).style.display = "block";
                return false;
            }
            if (rate === '' || rate <= 0) {
                document.getElementById(`errRate_${index}`).innerHTML = "Please enter rate.";
                document.getElementById(`errrate_${index}`).style.display = "block";
                return false;
            }

            return true;
        });
        if (!isValid) {
            return;
        }


        if (sendForm.poNo === '' || sendForm.poNo.trim().length === '' || sendForm.poNo === null || sendForm.poNo === undefined) {
            document.getElementById('errPONo').innerHTML = "Enter PO No.";
            document.getElementById('errPONo').style.display = "block";
        }
        else if (sendForm.billNo === '' || sendForm.billNo === null || sendForm.billNo === undefined) {
            document.getElementById('errBillNo').innerHTML = "Enter Bill No.";
            document.getElementById('errBillNo').style.display = "block";
        }
        else if (sendForm.billDate === '' || sendForm.billDate === null || sendForm.billDate === undefined) {
            document.getElementById('errBillDate').innerHTML = "Select bill date.";
            document.getElementById('errBillDate').style.display = "block";
        }
        else if (sendForm.godownID === '' || sendForm.godownID === null || sendForm.godownID === undefined) {
            document.getElementById('errGodown').innerHTML = "Select godown.";
            document.getElementById('errGodown').style.display = "block";
        }
        else if (sendForm.supplierID === '' || sendForm.supplierID === null || sendForm.supplierID === undefined) {
            document.getElementById('errSupplier').innerHTML = "Select supplier";
            document.getElementById('errSupplier').style.display = "block";
        }
        else {
            setShowUnderProcess(1);
            const obj = {
                "userId": window.userId,
                "clientId": window.clientId,
                poNo: sendForm.poNo,
                billNo: sendForm.billNo,
                challanNo: sendForm.challanNo,
                billDate: sendForm.billDate,
                godownID: sendForm.godownID,
                supplierID: sendForm.supplierID,
                totalAmount: totalExcAmountValue,
                totalDiscount: totalDiscountValue,
                totalGST: totalGSTValue,
                totalGrossAmount: totalGrossAmountValue,
                additionalChargeTypeID: sendForm.additionalChargeTypeID,
                additionalChargeAmount: sendForm.additionalChargeAmount,
                freightAmount: sendForm.freightAmount,
                inwardSubJson: JSON.stringify(inStockData),
                additionalchargeJson: JSON.stringify(additionalChargeData)
            }
            // console.log("dataobj", obj)
            // return;
            const response = await SaveStoockIn(obj);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setTosterValue(0);
                setShowToster(1);
                setTosterMessage("Data saved successfully..");
                setTimeout(() => {
                    setShowToster(0);
                    handleClear();
                }, 2000)
                getdata();
                setSendForm({});
                setRows([newRow]);
                setChargeRows([newChargeRow]);
            }
            else {
                setShowUnderProcess(0);
                setTosterValue(1);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTimeout(() => {
                    setShowToster(0);
                }, 1500)
            }
        }
    }


    //Clear Error Message
    const clearValidationErrMessage = () => {

        const errChallanNo = document.getElementById('errChallanNo');
        if (errChallanNo) {
            errChallanNo.style.display = "none";
        }
        const errstockSub = document.getElementById('errstockSub');
        if (errstockSub) {
            errstockSub.style.display = "none";
        }
        const errPONo = document.getElementById('errPONo');
        if (errPONo) {
            errPONo.style.display = "none";
        }
        const errBillNo = document.getElementById('errBillNo');
        if (errBillNo) {
            errBillNo.style.display = "none";
        }
        const errBillDate = document.getElementById('errBillDate');
        if (errBillDate) {
            errBillDate.style.display = "none";
        }
        const errGodown = document.getElementById('errGodown');
        if (errGodown) {
            errGodown.style.display = "none";
        }
        const errSupplier = document.getElementById('errSupplier');
        if (errSupplier) {
            errSupplier.style.display = "none";
        }

    }

    let handleClear = (value) => {
        clearValidationErrMessage();
        setClearDropdown(value);
        setEditGodown(0)
        setEditSupplier(0)
        setEditAdditionalCharge(0)
        setSendForm({
            "userID": window.userId,
            "clientID": window.clientId,
            "poNo": "",
            "billNo": "",
            "challanNo": "",
            "billDate": "",
            "godownID": 0,
            "supplierID": 0,
            "totalAmount": 0,
            "totalDiscount": 0,
            "totalGST": 0,
            "totalGrossAmount": 0,
            "additionalChargeTypeID": 0,
            "additionalChargeAmount": "",
            "freightAmount": "",
            "inwardSubJson": ""
        })
        document.getElementById("totalAmount").value = 0
        document.getElementById("totalDiscount").value = 0
        document.getElementById("totalGST").value = 0
        document.getElementById("totalGrossAmount").value = 0
        setInStockRows([newRow]);
        setChargeRows([newChargeRow]);
        setUpdateBool(0)
    }

    // Function to calculate total amount, total discount, and total GST
    const calculateTotals = () => {
        let totalAmount = 0;
        let totalDiscount = 0;
        let totalGST = 0;
        let amount = 0;

        inStockRows.forEach(row => {
            totalAmount += parseFloat(row.totalAmount || 0);
            totalDiscount += parseFloat(row.discount || 0);
            totalGST += parseFloat(row.gst || 0);
        });
        chargeTypeRows.forEach(rows => {
            amount += parseFloat(rows.amount || 0);
        })
        // const additionalChargeAmount = parseFloat(sendForm.additionalChargeAmount || 0);
        // const freightAmount = parseFloat(sendForm.freightAmount || 0);
        // const totalGrossAmount = totalAmount + additionalChargeAmount + freightAmount;
        const totalExcAmount = totalAmount - totalGST + totalDiscount;
        const totalGrossAmount = totalExcAmount + totalGST - totalDiscount + amount;
        // const totalGrossAmount = totalAmount + totalGST - totalDiscount;
        console.log("totalGrossAmount", totalGrossAmount)
        return {
            totalAmount: totalAmount.toFixed(2),
            totalDiscount: totalDiscount.toFixed(2),
            totalGST: totalGST.toFixed(2),
            totalExcAmount: totalExcAmount.toFixed(2),
            totalGrossAmount: totalGrossAmount.toFixed(2),
            amount: amount.toFixed(2)
        };
    };
    // Calculate totals
    const {
        totalAmount: totalAmountValue,
        totalDiscount: totalDiscountValue,
        totalGST: totalGSTValue,
        totalExcAmount: totalExcAmountValue,
        totalGrossAmount: totalGrossAmountValue
    } = calculateTotals();





    useEffect(() => {
        getdata();
    }, [])
    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="med-box">
                                <div className="title mt-2 pe-2 ps-2">{t("Stock In")}</div>
                                <div className="inner-content mt-2 me-0">
                                    <div className='row'>
                                        <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3 ps-2" >
                                            <label htmlFor="poNo" className="form-label">{t("PO No")}<span className="starMandatory">*</span></label>
                                            <input type="text" className="form-control form-control-sm" id="poNo" name="poNo" value={sendForm.poNo} onChange={handleChange} placeholder="Enter PO No" />
                                            <small id="errPONo" className="form-text text-danger" style={{ display: 'none' }}></small>
                                        </div>
                                        <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3 ps-2" >
                                            <label htmlFor="billNo" className="form-label">{t("Enter Bill/Invoice/challan No.")}<span className="starMandatory">*</span></label>
                                            <input type="text" className="form-control form-control-sm" id="billNo" name="billNo" value={sendForm.billNo} onChange={handleChange} placeholder="Enter Bill/Invoice No" />
                                            <small id="errBillNo" className="form-text text-danger" style={{ display: 'none' }}></small>
                                        </div>
                                        <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3 ps-2" >
                                            <label htmlFor="billDate" className="form-label">{t("Invoice Date")}<span className="starMandatory">*</span></label>
                                            <input type="date" className="form-control form-control-sm" id="billDate" name="billDate" placeholder="Invoice date" value={sendForm.billDate} onChange={handleChange} />
                                            <small id="errBillDate" className="form-text text-danger" style={{ display: 'none' }}></small>
                                        </div>
                                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-3 ps-2" >
                                            <label htmlFor="godownID" className="form-label">{t("Godown")}<span className="starMandatory">*</span></label>
                                            {godownList && <DropdownWithSearch defaulNname={t("Select Godown")} name="godownID" list={godownList} valueName="id" displayName="godownName" editdata={editGodown} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                            <small id="errGodown" className="form-text text-danger" style={{ display: 'none' }}></small>
                                        </div>
                                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-3 ps-2" >
                                            <label htmlFor="supplierID" className="form-label">{t("Supplier Name")}<span className="starMandatory">*</span></label>
                                            {supplierList && <DropdownWithSearch defaulNname={t("Select Supplier")} name="supplierID" list={supplierList} valueName="id" displayName="vendorName" editdata={editSupplier} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                            <small id="errSupplier" className="form-text text-danger" style={{ display: 'none' }}></small>
                                        </div>

                                    </div>
                                </div>
                                <div className="med-table-section" style={{ height: '40vh', padding: '0 5px' }}>
                                    <table className='med-table striped billingTable' >
                                        <thead>
                                            <tr style={{ background: '#EAF6FF' }}>
                                                <th className="text-center" style={{ "width": "3%" }}>#</th>
                                                <th style={{ "width": "10%" }}>{t("Item Name")}</th>
                                                <th style={{ "width": "10%" }}>{t("Brand")}</th>
                                                <th style={{ "width": "5%" }}>{t("Item Code")}</th>
                                                <th>{t("Serial / Batch No.")}</th>
                                                <th>{t("Exp. Date / Warranty Till")}</th>
                                                <th style={{ "width": "6%" }}>{t("Unit")} </th>
                                                <th style={{ "width": "4%" }}>{t("Quantity")}</th>
                                                <th style={{ "width": "4%" }}>{t("Rate")}</th>
                                                <th style={{ "width": "7%" }}>{t("GST")}</th>
                                                <th style={{ "width": "13%" }}>{t("Discount")}</th>
                                                <th style={{ "width": "7%" }}>{t("Total Amount")}</th>
                                                <th className="text-center" style={{ "width": "8%" }}>{t("Action")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {inStockRows && inStockRows.map((row, ind) => {
                                                return (
                                                    <tr key={row.id}>
                                                        <td className="text-center">{ind + 1}</td>
                                                        <td>
                                                            {/* {itemList && <DropdownWithSearch defaulNname={t("Select item")} id={`itemID_${row.id}`}  name="itemID" list={itemList} valueName="id" displayName="itemName" editdata={editItemName} getvalue={(event) => handleChange(event, ind)} clear={clearDropdown} clearFun={handleClear} />} */}

                                                            <select id={`itemID_${ind}`} name='itemID' value={row.itemID} className="form-select form-select-sm" onChange={(event) => handleChange(event, ind)}>
                                                                <option value="0" selected>Select Item</option>
                                                                {itemList && itemList.map((val, ind) => {
                                                                    return (
                                                                        <option value={val.id}>{val.itemName}</option>
                                                                    )
                                                                }
                                                                )}
                                                            </select>
                                                            <small id={`errItemID_${ind}`} className="form-text text-danger" style={{ display: 'none' }}></small>
                                                        </td>
                                                        <td>
                                                            <select className="form-select form-select-sm" id={`brandID_${ind}`} value={row.brandID} name="brandID" onChange={(event) => handleChange(event, ind)}>
                                                                <option value="">Select Brand</option>
                                                                {row.brandOptions &&
                                                                    row.brandOptions.map((brand) => (
                                                                        <option key={brand.BrandId} value={brand.BrandId}>
                                                                            {brand.BrandName}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </td>
                                                        <td><input type='text' className='form-control form-control-sm' id={`itemCode_${ind}`} value={row.itemCode} name='itemCode' onChange={(event) => handleChange(event, ind)} /></td>
                                                        <td><input type='text' className='form-control form-control-sm' id={`batchNo_${ind}`} value={row.batchNo} name='batchNo' onChange={(event) => handleChange(event, ind)} /></td>
                                                        <td><input type='date' className='form-control form-control-sm' id={`expDate_${ind}`} value={row.expDate} name='expDate' onChange={(event) => handleChange(event, ind)} /></td>
                                                        <td>
                                                            <select className="form-select form-select-sm" id={`unitID_${ind}`} value={row.unitID} name="unitID" onChange={(event) => handleChange(event, ind)}>
                                                                <option value="">Select Unit</option>
                                                                {row.unitOptions &&
                                                                    row.unitOptions.map((unit) => (
                                                                        <option key={unit.UnitId} value={unit.UnitId}>
                                                                            {unit.UnitName}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input type='number' className='form-control form-control-sm' id={`qty_${ind}`} value={row.qty} name='qty' onChange={(event) => handleChange(event, ind)} placeholder="0" />
                                                            <small id={`errQty_${ind}`} className="form-text text-danger" style={{ display: 'none' }}></small>
                                                        </td>
                                                        <td>
                                                            <input type='number' className='form-control form-control-sm' style={{ fontWeight: '600' }} id={`rate_${ind}`} value={row.rate} name='rate' onChange={(event) => handleChange(event, ind)} placeholder="0.00" /></td>
                                                        <small id={`errRate_${ind}`} className="form-text text-danger" style={{ display: 'none' }}></small>
                                                        <td>
                                                            <input type='number' className='form-control form-control-sm' id={`gst_${ind}`} value={row.gst} name='gst' onChange={(event) => handleChange(event, ind)} readOnly />
                                                        </td>
                                                        <td>
                                                            <div className="input-group input-group-sm">
                                                                <input type="number" style={{ maxWidth: '50px' }} className='form-control form-control-sm' placeholder="0" id={`disPer_${ind}`} value={row.disPer} name='disPer' onChange={(event) => handleChange(event, ind)} min="1" max="100" />
                                                                <span className="input-group-text frm-selectper">
                                                                    <select className='form-select form-select-sm' id={`discountType_${ind}`} name='discountType' onChange={(event) => handleChange(event, ind)}>
                                                                        <option value="discountTypePer">%</option>
                                                                        <option value="discountTypeRupee">&#8377;</option>
                                                                    </select>
                                                                </span>
                                                                <input type="number" className="form-control form-control-sm" style={{ whiteSpace: 'nowrap', minWidth: '80px' }} placeholder="00.00" id={`discount_${ind}`} value={row.discount} name='discount' onChange={(event) => handleChange(event, ind)} />
                                                                <div className="invalid-feedback" id={`disPerErr_${ind}`} style={{ display: 'none' }}>Discount cannot exceed 100%.</div>
                                                                <div className="invalid-feedback" id={`discountErr_${ind}`} style={{ display: 'none' }}>Discount cannot more than total amount.</div>
                                                            </div>


                                                        </td>

                                                        <td>
                                                            <input type='number' className='form-control form-control-sm' style={{ fontWeight: '600' }} id={`totalAmount_${ind}`} value={row.totalAmount} name='totalAmount' onChange={(event) => handleChange(event, ind)} readOnly />



                                                            <div className="modal fade" id={"exampleModal" + ind} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                <div className="modal-dialog  modal-lg" style={{ margin: '10% auto' }}>
                                                                    <div className="modal-content">
                                                                        <div className="modal-header_ bg-clrr mt-0 pt-0 mb-0 pb-0">
                                                                            <div className="heading text-wrap">Equipment Additional Details</div>
                                                                            <div>
                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="modal-body mt-0 pt-0">
                                                                            <div className="med-box" style={{ boxShadow: 'inherit' }}>
                                                                                <div className="inner-content mt-0 pt-0">
                                                                                    <div className='row'>
                                                                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3">
                                                                                            <label htmlFor="" className="form-label">{t("Service Type")}<span className="starMandatory">*</span></label>
                                                                                            <select className="form-select form-select-sm" aria-label="form-select-sm example" id={`serviceTypeID_${ind}`} value={row.serviceTypeID} name='serviceTypeID' onChange={(event) => handleChange(event, ind)}>
                                                                                                <option value="0">Select Service Type</option>
                                                                                                {serviceList && serviceList.map((service) => {
                                                                                                    return (
                                                                                                        <option key={service.id} value={service.id}>
                                                                                                            {service.serviceType}
                                                                                                        </option>
                                                                                                    )
                                                                                                })}
                                                                                            </select>
                                                                                        </div>
                                                                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3" >
                                                                                            <label htmlFor="" className="form-label">{t("Service Engineer Name")}<span className="starMandatory">*</span></label>
                                                                                            <input type="text" className="form-control form-control-sm" placeholder="Enter Service Engineer Name" id={`serviceEngineerName_${ind}`} value={row.serviceEngineerName} name='serviceEngineerName' onChange={(event) => handleChange(event, ind)} />
                                                                                        </div>
                                                                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-2" >
                                                                                            <label htmlFor="" className="form-label">{t("Service Engineer Contact No.")}<span className="starMandatory">*</span></label>
                                                                                            <input type="number" className="form-control form-control-sm" placeholder="Enter Service Engineer Contact No." id={`serviceEngineerContact_${ind}`} value={row.serviceEngineerContact} name='serviceEngineerContact' onChange={(event) => handleChange(event, ind)} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ) : ''} */}
                                                        </td>
                                                        <td>

                                                            <div className=' action-button cmlBtn'>
                                                                {row.selectedItem?.isEquipment === 1 && (
                                                                    <div className='p-1' id='#PersonWorkspace' data-bs-toggle="modal" data-bs-target={"#exampleModal" + ind}>
                                                                        <img src={PersonWorkspace} alt='' style={{ width: "15px" }} title='Add additional details' />
                                                                    </div>
                                                                )}
                                                                <i className="bi bi-trash3" onClick={() => removeStockRow(ind)} title='Remove row'></i>&nbsp;
                                                                <i className="bi bi-plus" onClick={addStockRow} title='Add new row'></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                    <small id="errstockSub" className="invalid-feedback" style={{ display: 'none' }}></small>
                                </div>
                                <hr />

                                <div className="med-table-section" style={{ height: '30vh', padding: '0 5px' }}>                                 
                                    <div className="title pe-2 ps-2">{t("Additional Charge Details")}</div>
                                    {chargeTypeRows && chargeTypeRows.map((rows, index) => {
                                        return (
                                            <div className='addnCharge inner-content mt-2 mb-2' key={rows.id}>
                                                <div className='addnCharge-in'>
                                                    <span><label htmlFor="" className="form-label">{index + 1}</label></span>

                                                </div>
                                                <div className='addnCharge-in'>
                                                    <span><label htmlFor={`additionalChargeTypeID_${index}`} className="form-label">{t("Additional Charge Type :")}</label></span>
                                                    <span>
                                                        <select id={`additionalChargeTypeID_${index}`} name='additionalChargeTypeID' value={rows.additionalChargeTypeID} className="form-select form-select-sm" onChange={(event) => handleChargeChange(event, index)}>
                                                            <option value="0" selected>Select Charge Type</option>
                                                            {addChargeTypeList && addChargeTypeList.map((val, ind) => {
                                                                return (
                                                                    <option value={val.id}>{val.typeName}</option>
                                                                )
                                                            }
                                                            )}
                                                        </select>
                                                    </span>
                                                </div>
                                                <div className='addnCharge-in'>
                                                    <span><label htmlFor={`amount_${index}`}  className="form-label">{t("Amount :")}</label></span>
                                                    <span>
                                                        <input type='number' className='form-control form-control-sm' id={`amount_${index}`} value={rows.amount} name='amount' placeholder='Enter additional charge' onChange={(event) => handleChargeChange(event, index)} />
                                                    </span>
                                                </div>
                                                <div className='addnCharge-in'>
                                                    <span>
                                                        <div className='billingTable action-button cmlBtn'>
                                                            <i className="bi bi-trash3 me-0" onClick={() => removeChargeRow(index)} title='Remove row'></i>&nbsp;
                                                            <i className="bi bi-plus" onClick={addChargeRow} title='Add new row'></i>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>

                                <div className='d-flex flex-wrap mb-3 ps-2 pe-2'>
                                    <div className='col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 dflexxx'>
                                        <div className='col-xxl-3 totalcountt bdrclrr1'>
                                            <div className='totllft'>Total Amount Exc. GST (Rs)</div>
                                            {/* <div className='totlcountrigt'><span id="totalAmount">{totalAmountValue}</span></div> */}
                                            <div className='totlcountrigt'><span id="totalAmount">{totalExcAmountValue}</span></div>
                                        </div>
                                        <div className='col-xxl-3 totalcountt bdrclrr2'>
                                            <div className='totllft'>Total Discount (Rs)</div>
                                            <div className='totlcountrigt'><span id="totalDiscount">{totalDiscountValue}</span></div>
                                        </div>
                                        <div className='col-xxl-3 totalcountt bdrclrr2'>
                                            <div className='totllft'>Total GST (Rs)</div>
                                            <div className='totlcountrigt'><span id="totalGST">{totalGSTValue}</span></div>
                                        </div>
                                        <div className='col-xxl-3 totalcountt bdrclrr4' style={{ borderRight: 'none' }}>
                                            <div className='totllft'>Total Gross Amount (Rs)</div>
                                            {/* <div className='totlcountrigt'><span id='totalGrossAmount'></span>{totalGrossAmountValue}</div> */}
                                            <div className='totlcountrigt'><span id='totalGrossAmount'></span>{totalGrossAmountValue}</div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 pt-1 relative">
                                        <div>
                                            <div className="d-flex flex-wrap justify-content-end gap-3">

                                                {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                                    <>
                                                        {showToster === 1 ?
                                                            <Toster value={tosterValue} message={tosterMessage} />

                                                            : <div>
                                                                <>
                                                                    <button type="button" className="btn btn-clear btn-sm me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt='' />{t("Clear")}</button>
                                                                    <button type="button" className="btn btn-save btn-save-fill btn-sm me-1" onClick={handlerSave}>{t("Save")} <img src={saveButtonIcon} className='icnn' alt='' /> </button>
                                                                </>

                                                            </div>}
                                                    </>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {
                    showLoder === 1 ? <Loader val={showLoder} /> : ""
                }
                {/* Toaster */}
                {
                    isShowToaster === 1 ?
                        <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""
                }

                {
                    showAlertToster === 1 ?
                        <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                }
            </section>
        </>
    )
}
