import React, { useEffect, useId, useState } from 'react'
import LineInvestigationGrap from '../../../Components/LineInvestigationGrap';
import TimeCalculate from '../../../Code/TimeCalculate';
import GetAllDashboardParameterColumnAssign from '../../../Api/GetAllDashboardParameterColumnAssign';
import { useSelector } from 'react-redux';

export default function SGTR(props) {
    let [sgpt, setSgpt] = useState("");
    let [sgptId, setSgptId] = useState("");
    let [sgptTime, setSgptTime] = useState("");
    let [sgptColor, setSgptColor] = useState("");

    let [uhId, setUhId] = useState("")

    // set grap Investigation data
    let [grapInvestigationData, setGrapInvestigationData] = useState({
        showGrap: 0,
        subTestId: '',
        patientName: '',
        UHID: '',
        userId: ''
    })

    let getData = () => {
        setSgpt("");
        setSgptId("");
        setSgptTime("");
        setSgptColor("");

        props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
            if (investigation.SubTestID.toString() === "742" || investigation.SubTestID.toString() === "1636") {
                setSgpt(Math.round(investigation.SubTestValue * 100) / 100)
                setSgptColor(investigation.SubTestColor)
                if (props.handleColor !== undefined) {
                    props.handleColor(investigation.SubTestColor !== null ? investigation.SubTestColor : "white", props.columnId)
                }

                setSgptId(investigation.SubTestID)
                setUhId(props.patientData.UhId)

                if (investigation.SubTestDateTime !== '') {
                    setSgptTime(TimeCalculate(investigation.SubTestDateTime))


                }

            }
        })
    }

    let modelCloseFun = () => {
        setGrapInvestigationData({
            showGrap: 0,
            subTestId: '',
            patientName: '',
            UHID: '',
            userId: ''
        })
    }

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
           if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

    }

    const keyId = useId();
    useEffect(() => {
        getColumnsParameters()

        getData()
    }, [props.patientData])
    return (
        <>
            {/* <div key={props.key} className={`overflow-max investigations ${(sgptColor !== "#008000" && sgptColor !== "#94e094" && sgptColor !== "#F5F8FF" && sgptColor !== "" ? "blink" : "")} `} style={{ border: (sgptColor !== "#008000" && sgptColor !== "#94e094" && sgptColor !== "#F5F8FF" && sgptColor !== "" ? "2px solid " + sgptColor : "") }}> */}
            <div key={props.key} className={`overflow-max investigations`}>

                {sgpt.length != 0 ? sgpt ? <span>

                    {
                        paramenterData && paramenterData.map((val, ind) => {
                            if (val.parameterId === 108) {
                                return (
                                    <>
                                        <span style={{ color: sgptColor }}>{sgpt}</span><br />



                                    </>

                                )
                            }

                            else if (val.parameterId === 109) {
                                return (
                                    <>
                                        {
                                            sgptTime.split("-")[1] === "D" && sgptTime.split("-")[0] ? <span style={{ 'color': 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {sgptTime.toLowerCase().replace("-", "")}</span>
                                                : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {sgptTime.toLowerCase().replace("-", "")}</span>}
                                        <br />
                                    </>

                                )
                            }

                            else if (val.parameterId === 110) {
                                return (
                                    <>

                                        <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='S.G.P.T Graph' >
                                            {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                                                if (val.SubtestID === sgptId) {
                                                    return (
                                                        <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                                                    )
                                                }
                                            })}
                                            <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [sgptId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "S.G.P.T Graph", patientName: props.patientData.PntName }) }}></i></div>

                                        {/* <br /> */}
                                    </>

                                )
                            }


                        })

                    }


                </span> : 'N/A' : "N/A"}
            </div>
            {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}
        </>
    )
}
