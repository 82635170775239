import React, { useEffect, useState } from 'react'
import Heading from '../../Component/Heading'
import DropdownWithSearch from '../../Component/DropdownWithSearch'
import GetInvestigationDetails from './API/GetInvestigationDetails'
import GetPatientDetailsforSarvodaya from './API/GetPatientDetailsforSarvodaya'
import GetAllStatus from '../../Dietetics/API/FoodIntake/GetAllStatus'
// import ProvisonalDiagnosisMainComponent from '../../Sarvodaya/Procedure/ProvisonalDiagnosisMainComponent';
import ChiefCaomplainComponent from '../../Sarvodaya/Procedure/ChiefCaomplainComponent'

import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import VitalsComponent from '../../Clinical/Pages/Component/VitalsComponent'
import IPDAllergiesComponent from '../../Clinical/Pages/Component/IPDAllergiesComponent'
import { SearchIndex } from '../../Code/Serach'
import GetDoctorList from '../../Registartion/API/GET/GetDoctorList'
import InsertNursingAssessment from './API/InsertNursingAssessment'
import GetNursingAssessment from './API/GetNursingAssessment'
import save from "../../assets/images/icons/save.svg"
import imgPrint from "../../assets/images/icons/imgPrint.svg";
import deleteBtnIcon from "../../assets/images/icons/deletebtn.svg";
import DeleteNursingAssessment from './API/DeleteNursingAssessment'
import AlertToster from '../../Component/AlertToster'
import SuccessToster from '../../Component/SuccessToster'
import Loader from '../../Component/Loader'
import ProvisonalDiagnosisMainComponent from './ProvisonalDiagnosisMainComponent'
import POSTInsertPatientVital from '../../Clinical/API/OPD/Vitals/POSTInsertPatientVital'
import PatientDeatils from '../../Component/PatientDeatils'

export default function NursingAdmissionInitalAssessment() {
    const { t } = useTranslation();
    const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;

    let [procedureList, setProcedureList] = useState([])
    let [modeofarrivalList, setModeOfArrivalList] = useState([])
    let [psychologyStatusList, setPsychologyStatusList] = useState([])
    let [patientStatusList, setPainStatusList] = useState([])
    let [painCharacterList, setPainCharacterList] = useState([])
    let [appetiteList, setAppetiterList] = useState([])
    let [weightList, setWeightList] = useState([])
    let [patientList, setPatientList] = useState([])
    let [orientPatientList, setOrientPatientList] = useState([])
    let [orientPatientAttendantList, setOrientPatientAttendantList] = useState([])
    let [doctorList, setDoctorList] = useState([])
    let [loader, setLoader] = useState(0)

    let [showAlertToster, setShowAlertToster] = useState(0)
    let [showAlertMessage, setShowAlertMessage] = useState("")
    let [showToster, setShowtoster] = useState(0)

    let [getNursingData, setGetNursingData] = useState([])
    let [sendForm, setSendForm] = useState({
        "uhId": uhID,
        "assessmentDate": new Date().toISOString().split('T')[0],
        "assessmentTime": new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
        "arrivalId": 0,
        "procedureId": 0,
        "psychologicalStatusId": 0,
        "isPain": false,
        "score": "",
        "duration": "",
        "painScale": "",
        "painLocation": "",
        "painCharacterId": 0,
        "patientStatusId": 0,
        "isPatientVulnerable": false,
        "pain1": false,
        "pain2": false,
        "pain3": false,
        "isAccompaniedRelative": false,
        "relativeName": "",
        "relationshipWithPatient": "",
        "relativeMobileNo": "",
        "appetiteId": 0,
        "weightId": 0,
        "patientTypeId": 0,
        "dieticianId": 0,
        "informedTime": new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
        "userId": window.userId,
        "clientId": window.clientId,
        "orientJson": "",
        "height": "",
        "weight": ""
    })
    let [clearDropdown, setClearDropdown] = useState(0);
    let [editProcedure, setEditProcedure] = useState('');
    let [editModeofArrival, setModeofArrival] = useState('');
    let [editPsychologyStatus, setEditPsychologyStatus] = useState('');
    let [editPatientStatus, setEditPatientStatus] = useState('');
    let [editAppetiteStatus, setEditAppetiteStatus] = useState('');
    let [editweightStatus, setWeightStatus] = useState('');
    let [editPatientTypeStatus, setEditPatientTypeStatus] = useState('');
    let [editPainCharacterStatus, setEditPainCharacterStatus] = useState('');
    let [editdoctor, setEditdoctor] = useState('');
    let [saveDiagnosis, setSaveDiagnosis] = useState(0);

    let [rowId, setRowId] = useState(null)
    // let [editOrientPatientStatus, setEditOrientPatientStatus] = useState('');
    // let [editOrientPatientAttendantStatus, seteditOrientPatientAttendantStatus] = useState('');
    let [orientPatientData, setOrientPatientData] = useState([])
    const [patientData, setPatientData] = useState();

    let [saveAllergie, setSaveAllergies] = useState(0);
    let [saveVitals, setSaveVitals] = useState(0)
    let [saveSymtoms, setSaveSymtoms] = useState(0)

    let row = {
        "orientId": 0,
        "isPatient": "",
        "checked": true
    }

    let getnursing = async () => {
        setLoader(1)
        try {
            let responseNursing = await GetNursingAssessment(uhID);
            if (responseNursing.status === 1) {
                setGetNursingData(responseNursing.responseValue)
                setLoader(0)
            }
            else {
                setLoader(0)
            }
        }
        catch {
            setLoader(0)
        }

    }
    //Get Data
    let getData = async () => {


        let items = await GetInvestigationDetails()
        if (items.status === 1) {
            setProcedureList(items.responseValue)
            // console.log("Investigation data", items.responseValue)
        }
        let data = await GetPatientDetailsforSarvodaya(uhID)
        if (data.status === 1) {
            setPatientData(data.responseValue[0])
        }

        let statusMaster = await GetAllStatus("Mode of arrival")
        if (statusMaster.status === 1) {
            setModeOfArrivalList(statusMaster.responseValue)
        }
        let psychologyStatus = await GetAllStatus("Psychological Status")
        if (psychologyStatus.status === 1) {
            setPsychologyStatusList(psychologyStatus.responseValue)
        }
        let painStatus = await GetAllStatus("Patient Status")
        if (painStatus.status === 1) {
            setPainStatusList(painStatus.responseValue)
        }
        let painCharacterStatus = await GetAllStatus("Pain Character")
        if (painCharacterStatus.status === 1) {
            setPainCharacterList(painCharacterStatus.responseValue)
        }

        let appetiteStatus = await GetAllStatus("Nutritional Screening")
        if (appetiteStatus.status === 1) {
            setAppetiterList(appetiteStatus.responseValue)
        }
        let weightStatus = await GetAllStatus("Nutritional Screening")
        if (weightStatus.status === 1) {
            setWeightList(weightStatus.responseValue)
        }
        let patientStatus = await GetAllStatus("Type of patient")
        if (patientStatus.status === 1) {
            setPatientList(patientStatus.responseValue)
        }

        let orientPatientStatus = await GetAllStatus("Orient Patient")
        if (orientPatientStatus.status === 1) {
            setOrientPatientList(orientPatientStatus.responseValue)
        }
        let orientPatientAttendantStatus = await GetAllStatus("Patient Attendent Orient")
        if (orientPatientAttendantStatus.status === 1) {
            setOrientPatientAttendantList(orientPatientAttendantStatus.responseValue)
        }
        let doctorList = await GetDoctorList()
        if (doctorList.status === 1) {
            setDoctorList(doctorList.responseValue)
        }
    }

    let handleClear = () => {
        setClearDropdown(1)
        setSendForm({
            "uhId": uhID,
            "assessmentDate": "",
            "assessmentTime": "",
            "arrivalId": 0,
            "procedureId": 0,
            "psychologicalStatusId": 0,
            "isPain": false,
            "score": "",
            "duration": "",
            "painScale": "",
            "painLocation": "",
            "painCharacterId": 0,
            "patientStatusId": 0,
            "isPatientVulnerable": false,
            "pain1": false,
            "pain2": false,
            "pain3": false,
            "isAccompaniedRelative": false,
            "relativeName": "",
            "relationshipWithPatient": "",
            "relativeMobileNo": "",
            "appetiteId": 0,
            "weightId": 0,
            "patientTypeId": 0,
            "dieticianId": 0,
            "informedTime": "",
            "userId": window.userId,
            "clientId": window.clientId,
            "orientJson": "",
            "height": "",
            "weight": ""
        })
        document.getElementById("pain_1").checked = false
        document.getElementById("pain_0").checked = false
        document.getElementById("pain1").checked = false
        document.getElementById("pain2").checked = false
        document.getElementById("pain3").checked = false
        document.getElementById("vulnerable_1").checked = false
        document.getElementById("vulnerable_0").checked = false
        document.getElementById("isAccompaniedRelative_1").checked = false
        document.getElementById("isAccompaniedRelative_0").checked = false
        document.getElementById("dietician_1").checked = false
        document.getElementById("dietician_0").checked = false
        for (let j = 0; j <= orientPatientAttendantList.length - 1; j++) {
            document.getElementById(orientPatientAttendantList[j].id).checked = false
        }
        for (let i = 0; i <= orientPatientList.length - 1; i++) {
            document.getElementById(orientPatientList[i].id).checked = false
        }
        setTimeout(() => {
            setClearDropdown(0)
        }, 60)


        return
    }

    let handleDeleteRow = async () => {
        let response = await DeleteNursingAssessment(rowId)
        if (response.status === 1) {
            getnursing()
            setShowtoster(1)
        }
        else {

        }
    }
    
    let handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (name === "pain") {
            let ischecked = document.getElementById("pain_1").checked ? true : false
            setSendForm((sendForm) => ({ ...sendForm, ["isPain"]: ischecked }))
        }
        else if (name === "Vulnerable") {
            let ischecked = document.getElementById("vulnerable_1").checked ? true : false
            setSendForm((sendForm) => ({ ...sendForm, ["isPatientVulnerable"]: ischecked }))
        }
        else if (name === "Dietician") {
            let ischecked = document.getElementById("dietician_1").checked ? true : false
            setSendForm((sendForm) => ({ ...sendForm, ["Dietician"]: ischecked }))
        }
        else if (name === "isAccompaniedRelative") {
            let ischecked = document.getElementById("isAccompaniedRelative_1").checked ? true : false
            setSendForm((sendForm) => ({ ...sendForm, ["isAccompaniedRelative"]: ischecked }))
        }
        else if (name === "pain1" || name === "pain2" || name === "pain3") {
            setSendForm((sendForm) => ({ ...sendForm, [name]: document.getElementById(name).checked }))
        }

        else if (name === "Orientpatient") {
            let temp = [...orientPatientData]
            let response = SearchIndex(orientPatientData, "orientId", value)
            if (response.length === 0) {
                row["orientId"] = value
                row["isPatient"] = 1
                row["checked"] = true
                setOrientPatientData([...temp, row])
            }
            else {
                document.getElementById(value).checked = false
                temp.splice(response, 1)
                setOrientPatientData(temp)
            }
        }
        else if (name === "Orientpatientattendant") {
            let temp = [...orientPatientData]
            let response = SearchIndex(orientPatientData, "orientId", value)
            if (response.length === 0) {
                row["orientId"] = value
                row["isPatient"] = 0
                row["checked"] = true
                setOrientPatientData([...temp, row])

            }
            else {
                document.getElementById(value).checked = false
                temp.splice(response, 1)
                setOrientPatientData(temp)

            }
        }
        else if (name === "relativeMobileNo") {
            if (sendForm.relativeMobileNo.length < 10) {
                const formattedValue = value.replace(/[^0-9]/g, '');
                setSendForm((sendForm) => ({ ...sendForm, ["relativeMobileNo"]: formattedValue }))
            }
            else if (value.length < 10) {
                const formattedValue = value.replace(/[^0-9]/g, '');
                setSendForm((sendForm) => ({ ...sendForm, ["relativeMobileNo"]: formattedValue }))
            }

        }
        else {
            setSendForm((sendForm) => ({ ...sendForm, [name]: value }))
            setSendForm((sendForm) => ({ ...sendForm, ["ward"]: patientData.wardName }))
        }
    }

    let handleSave = async () => {
        let makeData = {
            ...sendForm,
            "orientJson": JSON.stringify(orientPatientData)
        }
        let resp = handleValidation()
        console.log("validation", resp)
        if (resp[0]) {
            setLoader(1)
            let response = await InsertNursingAssessment(makeData)
            if (response.status === 1) {
                setSaveVitals(1)
                setSaveSymtoms(1)
                setSaveDiagnosis(1)
                setSaveAllergies(1)
                handleSaveVital()
                getData()
                setLoader(0)
                setShowtoster(1)
                getnursing()
                handleClear()
                console.log("Data Save")

            }
            else {
                setSaveSymtoms(1)

                setLoader(0)
                setShowAlertToster(1)
                setShowAlertMessage("Data Not Save")

            }
        }
        else {
            setShowAlertToster(1)
            setShowAlertMessage("Please Fill " + resp[1].replace("Id", ""))
        }
    }

    let handleSaveVital = async () => {
        let makeData = {
            "uhid": uhID,
            "userId": window.userId,
            "vitalDate": new Date().toISOString().split('T')[0],
            "vitalTime": new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
            "height": sendForm.height,
            "weight": sendForm.weight,

        }
        let resp = await POSTInsertPatientVital(makeData)
        if (resp.status === 1) { }
        else { }
    }
    let handleValidation = (formdata) => {
        for (let key in formdata) {
            if (key !== "" && key !== "pain1" && key !== "pain2" && key !== "pain3")
                if (formdata[key] === 0 || formdata[key] === "") {
                    return [false, key]
                }
        }
        // sendForm.map((val, ind) => {

        // })
        return [true, ""]
    }
    let handleprint = (val) => {
        window.open(`/NursingAdmissionInitialAssessmentADULTprint/?uhID=${uhID}&index=${val}`, "noopener,noreferrer");
    }
    useEffect(() => {
        getData()
        getnursing()
    }, [])

    return (
        <>

            <Heading text='Nursing Admission Inital Assessment (ADULT)' id='top' />
            {/* <div className='tblheading'>Vital Input</div> */}
            <PatientDeatils />
            <div className="fieldsett-in">
                <div className="fieldsett">
                    <span className='fieldse'>{"Assessment Details"}</span>
                    <div className="inner-content mt-2">
                        <div className='d-flex flex-column flex-md-row gap-2'>
                            <div className="d-flex flex-wrap align-content-end gap-3 w-100 w-md-50">
                                <div className="mb-2 me-2">
                                    <label htmlFor="assessmentDate" className="form-label">Date of Assessment<span className="starMandatory">*</span></label>
                                    <input id="assessmentDate" type="date" className="form-control form-control-sm" name="assessmentDate" value={sendForm.assessmentDate !== "" ? sendForm.assessmentDate : new Date().toISOString().split('T')[0]} onChange={handleChange} />
                                </div>
                                <div className="mb-2 me-2">
                                    <label htmlFor="assessmentTime" className="form-label">Time of Assessment<span className="starMandatory">*</span></label>
                                    <input id="assessmentTime" type="time" className="form-control form-control-sm" name="assessmentTime" value={sendForm.assessmentTime !== "" ? sendForm.assessmentTime : new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })} onChange={handleChange} />
                                </div>
                                <div className="mb-2 me-2">
                                    <label htmlFor="Code" className="form-label">Ward<span className="starMandatory">*</span></label>
                                    <input id="wardId" type="text" className="form-control form-control-sm" name="wardName" value={patientData && patientData.wardName} disabled placeholder="Ward" />
                                </div>
                                <div className="mb-2 me-2">
                                    <label htmlFor="Code" className="form-label">Procedure<span className="starMandatory">*</span></label>
                                    {procedureList && <DropdownWithSearch defaulNname={"Select Procedure"} name="procedureId" list={procedureList} valueName="id" displayName="itemName" editdata={editProcedure} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                </div>
                                <div className="mb-2 me-2">
                                    <label htmlFor="Code" className="form-label">Mode of Arrival<span className="starMandatory">*</span></label>
                                    {modeofarrivalList && <DropdownWithSearch defaulNname={"Select Mode of Arrival"} name="arrivalId" list={modeofarrivalList} valueName="id" displayName="remark" editdata={editModeofArrival} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                </div>
                            </div>
                            <div className="w-100 w-md-50 mb-2 me-2">
                                <label htmlFor="Code" className="form-label">Diagnosis<span className="starMandatory">*</span></label>
                                <ProvisonalDiagnosisMainComponent
                                    isProvisional={0}
                                    saveDiagnosis={saveDiagnosis}
                                    setSaveDiagnosis={setSaveDiagnosis}
                                />
                            </div>

                        </div>


                    </div>
                </div>
            </div>

            <div className="fieldsett-in">
                <div className='d-flex flex-column flex-md-row gap-2'>
                    <div className="fieldsett w-100 w-md-50">
                        <span className='fieldse'>{t("Chief Complaints & History of Present Illness")}</span>
                        <div className="inner-content mt-2" style={{ maxHeight: '25vh', overflow: 'auto' }}>
                            <ChiefCaomplainComponent saveSymtoms={saveSymtoms} setSaveSymtoms={setSaveSymtoms} />
                        </div>
                    </div>

                    <div className="fieldsett w-100 w-md-50">
                        <span className='fieldse'>{"Psychological Status"}</span>
                        <div className="inner-content mt-2">
                            {psychologyStatusList && <DropdownWithSearch defaulNname={"Select Psychological Status"} name="psychologicalStatusId" list={psychologyStatusList} valueName="id" displayName="remark" editdata={editPsychologyStatus} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                        </div>
                    </div>
                </div>
            </div>




            <div className="fieldsett-in">
                <div className='d-flex flex-column flex-md-row gap-2'>
                    <div className="fieldsett w-100 w-md-50">
                        <span className='fieldse'>{t("Vitals Signs at the time of arrival")}</span>
                        <div className="inner-content mt-2" style={{ maxHeight: '150px', overflow: 'auto' }}>
                            <VitalsComponent saveVitals={saveVitals} setSaveVitals={setSaveVitals} />
                        </div>
                    </div>


                    <div className="fieldsett w-100 w-md-50">
                        <span className='fieldse'>{t("")}</span>
                        <div className="inner-content mt-2" >
                            <div className='d-flex flex-wrap align-content-center gap-2'>
                                <div className='monit-checkbox checklabel'>
                                    <div className="risk-factors-check regularCheck">
                                        <label className="form-label">Pain :</label>
                                    </div>
                                    <div className="risk-factors-check regularCheck">
                                        <input type="radio" className="me-2 custom-checkbox form-check-input" id='pain_1' name='pain' onChange={handleChange} />Yes
                                    </div>
                                    <div className="risk-factors-check regularCheck">
                                        <input type="radio" className="me-2 custom-checkbox form-check-input" id='pain_0' name='pain' onChange={handleChange} />No
                                    </div>
                                </div>

                                <div className='monit-checkbox checklabel'>
                                    <div className="risk-factors-check regularCheck">
                                        <label className="form-label">Score :</label>
                                    </div>
                                    <div className="risk-factors-check regularCheck">

                                        <input type='number' name='score' placeholder='Please Enter Score' id='score' value={sendForm.score} className='form-control form-control-sm' onChange={handleChange} />
                                    </div>

                                </div>
                                <div className='monit-checkbox checklabel'>
                                    <div className="risk-factors-check regularCheck">
                                        <label className="form-label">Duration :</label>
                                    </div>
                                    <div className="risk-factors-check regularCheck">

                                        <input type='text' name='duration' placeholder='Please Enter Duration' id='duration' value={sendForm.duration} className='form-control form-control-sm' onChange={handleChange} />
                                    </div>

                                </div>
                                <div className='monit-checkbox checklabel'>
                                    <div className="risk-factors-check regularCheck">
                                        <label className="form-label">If yes name of pain scale :</label>
                                    </div>
                                    <div className="risk-factors-check regularCheck">

                                        <input type='text' name='painScale' placeholder='Please Enter Pain Scale' id='painScale' value={sendForm.painScale} className='form-control form-control-sm' onChange={handleChange} />
                                    </div>

                                </div>

                            </div>
                            <div className='monit-checkbox checklabel'>
                                <div className="risk-factors-check regularCheck">
                                    <input type="checkbox" className="me-2 custom-checkbox form-check-input" id='pain1' name='pain1' onChange={handleChange} />
                                    <strong>7 Years to 12 years</strong> (Wong-Bako FCES Pain Rating Scale)
                                </div>
                                <div className="risk-factors-check regularCheck">
                                    <input type="checkbox" className="me-2 custom-checkbox form-check-input" id='pain2' name='pain2' onChange={handleChange} />
                                    <strong> {">"}12 Years to 12 years</strong> (Numeric Pain intensity Scale Visual Analou=gue Scale)
                                </div>
                            </div>
                            <div className='monit-checkbox checklabel'>
                                <div className="risk-factors-check regularCheck">
                                    <input type="checkbox" className="me-2 custom-checkbox form-check-input" id='pain3' name='pain3' onChange={handleChange} />
                                    CPOT (Critical care pain observation tool)
                                </div>
                            </div>

                            <div className="mb-2 me-2 w-25">
                                <label htmlFor="Code" className="form-label">Pain Character<span className="starMandatory">*</span></label>
                                {painCharacterList && <DropdownWithSearch defaulNname={"Select Pain Character"} name="painCharacterId" list={painCharacterList} valueName="id" displayName="remark" editdata={editPainCharacterStatus} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                            </div>

                            <div className='monit-checkbox checklabel'>
                                <div className="risk-factors-check regularCheck">
                                    <label className="form-label">Location:</label>
                                </div>
                                <div className="risk-factors-check regularCheck">

                                    <input type='text' name='painLocation' placeholder='Please Enter Pain Location' value={sendForm.painLocation} id='painLocation' className='form-control form-control-sm' onChange={handleChange} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>






            <div className="fieldsett-in">

                <div className='d-flex flex-column flex-md-row gap-2'>

                    <div className="fieldsett w-100 w-md-50">
                        <span className='fieldse'>{"Patient Status"}</span>
                        <div className="inner-content mt-2">
                            <div className="d-flex flex-wrap align-content-end gap-3">
                                <div className="mb-2 me-2">
                                    <label htmlFor="Code" className="form-label">Pain Status<span className="starMandatory">*</span></label>
                                    {patientStatusList && <DropdownWithSearch defaulNname={"Select Patient Status"} name="patientStatusId" list={patientStatusList} valueName="id" displayName="remark" editdata={editPatientStatus} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                </div>
                            </div>

                            <div className='monit-checkbox checklabel'>
                                <div className="risk-factors-check regularCheck">
                                    <label className="form-label">Patient Vulnerable :</label>
                                </div>
                                <div className="risk-factors-check regularCheck">
                                    <input type="radio" className="me-2 custom-checkbox form-check-input" id='vulnerable_1' name='Vulnerable' onChange={handleChange} />Yes
                                </div>
                                <div className="risk-factors-check regularCheck">
                                    <input type="radio" className="me-2 custom-checkbox form-check-input" id='vulnerable_0' name='Vulnerable' onChange={handleChange} />No
                                </div>
                                <div className='monit-checkbox checklabel'>
                                    <div className="risk-factors-check regularCheck">
                                        <label className="form-label">Height :</label>
                                    </div>
                                    <div className="risk-factors-check regularCheck">
                                        <input type='number' value={sendForm.height} className='form-control form-control-sm me-2' name='height' onChange={handleChange} />cm
                                    </div>

                                </div>
                                <div className='monit-checkbox checklabel'>
                                    <div className="risk-factors-check regularCheck">
                                        <label className="form-label">Weight :</label>
                                    </div>
                                    <div className="risk-factors-check regularCheck">
                                        <input type='number' value={sendForm.weight} className='form-control form-control-sm me-2' name='weight' onChange={handleChange} /> kg
                                    </div>

                                </div>
                            </div>


                            <div className='monit-checkbox checklabel'>
                                <div className="risk-factors-check regularCheck">
                                    <label className="form-label">Accompanied By Relative :</label>
                                </div>
                                <div className="risk-factors-check regularCheck">
                                    <input type="radio" className="me-2 custom-checkbox form-check-input" id='isAccompaniedRelative_1' name='isAccompaniedRelative' onChange={handleChange} />Yes
                                </div>
                                <div className="risk-factors-check regularCheck">
                                    <input type="radio" className="me-2 custom-checkbox form-check-input" id='isAccompaniedRelative_0' name='isAccompaniedRelative' onChange={handleChange} />No
                                </div>
                                {/* <div className='monit-checkbox checklabel'>
                                <div className="risk-factors-check regularCheck">
                                    <label className="form-label">If any name of the relative :</label>
                                </div>
                                <div className="risk-factors-check regularCheck">
                                    <input type='text' name='relativeName' id='relativeName' className='form-control form-control-sm' />
                                </div>

                            </div>
                            <div className='monit-checkbox checklabel'>
                                <div className="risk-factors-check regularCheck">
                                    <label className="form-label">Relationship with patient :</label>
                                </div>
                                <div className="risk-factors-check regularCheck">
                                    <input type='text' name='relationshipWithPatient' id='relationshipWithPatient' className='form-control form-control-sm' />
                                </div>

                            </div> */}
                            </div>

                            <div className='monit-checkbox checklabel'>
                                <div className='monit-checkbox checklabel'>
                                    <div className="risk-factors-check regularCheck">
                                        <label className="form-label">Contact Person Name :</label>
                                    </div>
                                    <div className="risk-factors-check regularCheck">
                                        <input type='text' placeholder='Please Enter Person Name' name='relativeName' id='relativeName' value={sendForm.relativeName} className='form-control form-control-sm' onChange={handleChange} />
                                    </div>
                                </div>
                                <div className='monit-checkbox checklabel'>
                                    <div className="risk-factors-check regularCheck">
                                        <label className="form-label">Relationship :</label>
                                    </div>
                                    <div className="risk-factors-check regularCheck">
                                        <input type='text' placeholder='Please Enter Relationship' name='relationshipWithPatient' value={sendForm.relationshipWithPatient} id='relationshipWithPatient' className='form-control form-control-sm' onChange={handleChange} />
                                    </div>

                                </div>
                                <div className='monit-checkbox checklabel'>
                                    <div className="risk-factors-check regularCheck">
                                        <label className="form-label">Contact No. :</label>
                                    </div>
                                    <div className="risk-factors-check regularCheck">
                                        <input type='number' name='relativeMobileNo' maxLength="10" placeholder='Please Enter Contact No.' value={sendForm.relativeMobileNo} id='relativeMobileNo' className='form-control form-control-sm' onChange={handleChange} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="fieldsett w-100 w-md-50">
                        <span className='fieldse'>{t("Allergies")}</span>
                        <div className="inner-content" style={{ maxHeight: '25vh', overflow: 'auto' }}>
                            <IPDAllergiesComponent saveAllergie={saveAllergie} setSaveAllergies={setSaveAllergies} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="fieldsett-in">
                <div className='d-flex flex-column flex-md-row gap-2'>

                    <div className="fieldsett w-100 w-md-50">
                        <span className='fieldse'>{"Nutritional Screening"}</span>
                        <div className="inner-content mt-2">
                            <div className='monit-checkbox checklabel'>
                                <div className="mb-2 me-2">
                                    <label htmlFor="Code" className="form-label">Last 3 month appetite<span className="starMandatory">*</span></label>
                                    {appetiteList && <DropdownWithSearch defaulNname={"Select Last 3 month appetite"} name="appetiteId" list={appetiteList} valueName="id" displayName="remark" editdata={editAppetiteStatus} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                </div>
                                <div className="mb-2 me-2">
                                    <label htmlFor="Code" className="form-label">Last 3 month weight<span className="starMandatory">*</span></label>
                                    {weightList && <DropdownWithSearch defaulNname={"Select Last 3 month weight"} name="weightId" list={weightList} valueName="id" displayName="remark" editdata={editweightStatus} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                </div>
                                <div className="mb-2 me-2">
                                    <label htmlFor="Code" className="form-label">Type of patient<span className="starMandatory">*</span></label>
                                    {patientList && <DropdownWithSearch defaulNname={"Select Type of patient"} name="patientTypeId" list={patientList} valueName="id" displayName="remark" editdata={editPatientTypeStatus} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                </div>
                                <div className="risk-factors-check regularCheck">
                                    <label className="form-label">Dietician Informed :</label>
                                </div>
                                <div className="risk-factors-check regularCheck">
                                    <input type="radio" className="me-2 custom-checkbox form-check-input" id='dietician_1' name='Dietician' onChange={handleChange} />Yes
                                </div>
                                <div className="risk-factors-check regularCheck">
                                    <input type="radio" className="me-2 custom-checkbox form-check-input" id='dietician_0' name='Dietician' onChange={handleChange} />No
                                </div>

                                <div className="mb-2 me-2">
                                    <label htmlFor="Code" className="form-label">If Yes, Mention the Name of  dietician :<span className="starMandatory">*</span></label>
                                    {doctorList && <DropdownWithSearch defaulNname={"Select Dietician"} name="dieticianId" list={doctorList} valueName="id" displayName="name" editdata={editPainCharacterStatus} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}
                                </div>
                                {/* <div className='monit-checkbox checklabel'>
                                <div className="risk-factors-check regularCheck"><span className="starMandatory">*</span>
                                    <label className="form-label">If Yes, Mention the Name of  dietician :</label>
                                </div>
                                {patientList && <DropdownWithSearch defaulNname={"Select Type of patient"} name="patientTypeId" list={patientList} valueName="id" displayName="remark" editdata={editPatientTypeStatus} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />}


                            </div> */}
                                <div className='monit-checkbox checklabel'>
                                    <div className="risk-factors-check regularCheck">
                                        <label className="form-label">Informed at(time) :</label>
                                    </div>
                                    <div className="risk-factors-check regularCheck">
                                        <input type='time' name='informedTime' id='informedTime' value={sendForm.informedTime !== "" ? sendForm.informedTime : new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })} className='form-control form-control-sm' onChange={handleChange} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="fieldsett w-100 w-md-50">
                        <span className='fieldse'>{"Orient Patient"}</span>
                        <div className="inner-content mt-2">
                            <div className='monit-checkbox checklabel'>
                                <div className="mb-2 me-2">
                                    <label htmlFor="Code" className="form-label">Orient patient if<span className="starMandatory">*</span></label>
                                    {/* {orientPatientList && <DropdownWithSearch defaulNname={"Select Orient Patient"} name="Orientpatient" list={orientPatientList} valueName="id" displayName="remark" editdata={editOrientPatientStatus} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />} */}
                                    <div className='d-flex flex-column flex-md-row gap-3'>
                                        {
                                            orientPatientList && orientPatientList.map((val, ind) => {
                                                return (
                                                    <div className="risk-factors-check regularCheck">
                                                        <input type="checkbox" className="me-2 custom-checkbox form-check-input" onClick={handleChange} id={val.id} name={"Orientpatient"} value={val.id} />{val.remark}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="mb-2 me-2">
                                    <label htmlFor="Code" className="form-label">Orient patient attendant if <span className="starMandatory">*</span></label>
                                    {/* {orientPatientAttendantList && <DropdownWithSearch defaulNname={"Select Orient patient attendant if"} name="Orientpatientattendant" list={orientPatientAttendantList} valueName="id" displayName="remark" editdata={editOrientPatientAttendantStatus} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} />} */}
                                    <div className='d-flex flex-column flex-md-row gap-3'>
                                        {
                                            orientPatientAttendantList && orientPatientAttendantList.map((val, ind) => {
                                                return (
                                                    <div className="risk-factors-check regularCheck">
                                                        <input type="checkbox" className="me-2 custom-checkbox form-check-input" onClick={handleChange} id={val.id} name={"Orientpatientattendant"} value={val.id} />{val.remark}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-row justify-content-end mt-3  gap-3 '>
                    <button className='saveprintopd ps-3 pe-3 btn-save-fill' onClick={handleSave} >
                        <img src={save} className='icnn' alt='' /> Save
                    </button>
                    <button className='saveprintopd ps-3 pe-3 btn-save-fill' onClick={handleClear} >
                        {/* <img src={save} className='icnn' alt='' /> */}
                        Clear
                    </button>
                </div>

            </div>





            <div className="med-table-section mt-3" style={{ height: "80vh" }}>
                <table className="med-table border_ striped">
                    <thead style={{ zIndex: "0" }}>
                        <tr>
                            <th className="text-center" style={{ width: "5%" }}>
                                #
                            </th>
                            <th>Date of Assessment</th>
                            <th>Time of Assessment</th>
                            <th>Procedure</th>
                            <th>Mode of Arrival</th>
                            <th style={{ width: "5%" }} className="text-center">
                                {"Action"}
                            </th>

                        </tr>
                    </thead>

                    <tbody>
                        {
                            getNursingData && getNursingData.map((val, ind) => {
                                return (
                                    <tr>
                                        <td className="text-center" style={{ width: "5%" }}>
                                            {ind + 1}
                                        </td>
                                        <td>{val.assessmentDate.split("T")[0]}</td>
                                        <td>{val.assessmentTime.split("T")[1]}</td>
                                        <td>{val.procedureName}</td>
                                        <td>{val.arrivalName}</td>
                                        <td style={{ width: "5%" }} className="text-center pointer">
                                            <div className='d-flex flex-row gap-2'>
                                                <img
                                                    src={imgPrint}
                                                    alt=""
                                                    onClick={() => { handleprint(ind) }}
                                                    style={{ width: "19px" }}
                                                />
                                                <div className="action-button" >
                                                    <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' style={{ width: "20px" }} alt='' onClick={() => { setRowId(val.id) }} />
                                                    </div>
                                                </div>

                                            </div>

                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

            {/* -----------------------Start Delete Modal Popup-------------------   */}

            {/*  <!-- Modal -->  */}
            <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                        <div className="modal-body modelbdy text-center">
                            <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                            <div className='popDeleteTitle mt-3'> {t("Delete?")}</div>
                            <div className='popDeleteContent'> {t("Are_you_sure_you_want_to_delete?")}</div>
                        </div>
                        <div className="modal-footer1 text-center">

                            <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                            <button type="button" className="btn-delete popBtnDelete" onClick={handleDeleteRow} data-bs-dismiss="modal">{t("Delete")}</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}
            {
                showAlertToster === 1 ? <AlertToster handle={setShowAlertToster} message={showAlertMessage} /> : ""
            }
            {
                showToster === 1 ? <SuccessToster handle={setShowtoster} message={"Data Save Successfully!!"} /> : ""
            }
            <Loader val={loader} />
        </>

    )
}
