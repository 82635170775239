import AlertToster from "../../../../../Component/AlertToster";
import Loader from "../../../../../Component/Loader";
import SuccessToster from "../../../../../Component/SuccessToster";
import Toster from "../../../../../Component/Toster";
import TosterUnderProcess from "../../../../../Component/TosterUnderProcess";
import deleteBtnIcon from "../../../../../assets/images/icons/delete.svg"
import save from "../../../../../assets/images/icons/clear.svg"
import clear from "../../../../../assets/images/icons/save.svg"
import IconEdit from '../../../../../assets/images/icons/IconEdit.svg'
import IconDelete from '../../../../../assets/images/icons/IconDelete.svg'
import saveButtonIcon from '../../../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../../../assets/images/icons/clear.svg';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Heading from "../../../../../Component/Heading";
import BoxContainer from "../../../../../Component/BoxContainer";
import TableContainer from "../../../../../Component/TableContainer";
import InsertVisionPrescription from "../../../../API/VisionPrescreption/InsertVisionPrescreption";
import GetVisionPrescreptionList from "../../../../API/VisionPrescreption/GetVisionPrescreption";
import DeleteVisionPrescreption from "../../../../API/VisionPrescreption/DeleteVisionPrescreption";
import UpdateVisionPrescreption from "../../../../API/VisionPrescreption/UpdateVisionPrescreption";



export default function IPDVisionPrescreption() {
    const { t } = useTranslation();
    let [showLoder, setShowLoder] = useState(0);
    let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [showToster, setShowToster] = useState(0);
    let [tosterMessage, setTosterMessage] = useState("");
    let [tosterValue, setTosterValue] = useState(0);
    let [isShowToaster, setisShowToaster] = useState(0);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [showSuccessMsg, setShowSuccessMsg] = useState('');
    let [updateBool, setUpdateBool] = useState(0);
    let [eyeType, setEyeType] = useState(1);
    let [SelectProductType, setSelectProductType] = useState(1);
    let [getSpherePowerRight, setSpherePowerRight] = useState('');
    let [getSpherePowerLeft, setSpherePowerLeft] = useState('');
    let [getCylinderPowerRight, setCylinderPowerRight] = useState('');
    let [getCylinderPowerLeft, setCylinderPowerLeft] = useState('');
    let [getAxisRight, setAxisRight] = useState('');
    let [getAxisLeft, setAxisLeft] = useState('');
    let [getPrismRight, setPrismRight] = useState('');
    let [getPrismBaseRight, setPrismBaseRight] = useState('');
    let [getPrismLeft, setPrismLeft] = useState('');
    let [getPrismBaseLeft, setPrismBaseLeft] = useState('');
    let [getAddPowerRight, setAddPowerRight] = useState('');
    let [getAddPowerLeft, setAddPowerLeft] = useState('');
    let [getDiameter, setDiameter] = useState('');
    let [getDuration, setDuration] = useState('');
    let [getColour, setColour] = useState('');
    let [getBrand, setBrand] = useState('');
    let [getAdditionalInformation, setAdditionalInformation] = useState('');
    let [getVisionPrescreptionList, setVisionPrescreptionList] = useState('');
    let [getRowId, setRowId] = useState(0);

    const [searchTerm, setSearchTerm] = useState('');

    const clientID = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
    const userId = JSON.parse(sessionStorage.getItem("LoginData")).userId;

    // const uhid = JSON.parse(sessionStorage.getItem("IPDactivePatient")).Uhid;


    let uhid = window.sessionStorage.getItem("activePatient")
        ? JSON.parse(window.sessionStorage.getItem("activePatient")).Uhid
        : window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : [];


    const handleSelectEye = () => {
        const getvalue = document.getElementById("dropdownEye").value;
        setEyeType(parseInt(getvalue));
    };

    const handleSelectProductType = () => {
        const getvalue = document.getElementById("dropdownProductType").value;
        setSelectProductType(getvalue);
    };

    const handleSelectPrismBaseRight = () => {
        const getvalue = document.getElementById("dropdownPrismBaseRight").value;
        setPrismBaseRight(getvalue);
    };

    const handleSelectPrismBaseLeft = () => {
        const getvalue = document.getElementById("dropdownPrismBaseLeft").value;
        setPrismBaseLeft(getvalue);
    };

    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "txtSpherePowerLeft") {
            document.getElementById("errtxtSpherePowerLeft").style.display = "none";
            setSpherePowerLeft(value);
        } else if (name === "txtSpherePowerRight") {
            document.getElementById("errtxtSpherePowerRight").style.display = "none";
            setSpherePowerRight(value);
        } else if (name === "txtCylinderPowerRight") {
            document.getElementById("errtxtCylinderPowerRight").style.display = "none";
            setCylinderPowerRight(value);
        } else if (name === "txtCylinderPowerLeft") {
            document.getElementById("errtxtCylinderPowerLeft").style.display = "none";
            setCylinderPowerLeft(value);
        } else if (name === "txtAxisRight") {
            document.getElementById("errtxtAxisRight").style.display = "none";
            setAxisRight(value);
        } else if (name === "txtAxisLeft") {
            document.getElementById("errtxtAxisLeft").style.display = "none";
            setAxisLeft(value);
        } else if (name === "txtPrismRight") {
            document.getElementById("errtxtPrismRight").style.display = "none";
            setPrismRight(value);
        } else if (name === "txtPrismLeft") {
            document.getElementById("errtxtPrismLeft").style.display = "none";
            setPrismLeft(value);
        } else if (name === "txtAddPowerRight") {
            document.getElementById("errtxtAddPowerRight").style.display = "none";
            setAddPowerRight(value);
        } else if (name === "txtAddPowerLeft") {
            document.getElementById("errtxtAddPowerLeft").style.display = "none";
            setAddPowerLeft(value);
        } else if (name === "txtDiameter") {
            document.getElementById("errtxtDiameter").style.display = "none";
            setDiameter(value);
        } else if (name === "txtDuration") {
            document.getElementById("errtxtDuration").style.display = "none";
            setDuration(value);
        } else if (name === "txtColour") {
            document.getElementById("errtxtColour").style.display = "none";
            setColour(value);
        } else if (name === "txtBrand") {
            document.getElementById("errtxtBrand").style.display = "none";
            setBrand(value);
        } else if (name === "txtAdditionalInformation") {
            document.getElementById("errtxtAdditionalInformation").style.display = "none";
            setAdditionalInformation(value);
        }
    }



    let handleGet = async () => {

        var objGet = {
            uhid: uhid,
            userId: window.userId,
            clientId: clientID
        }

        let getResponse = await GetVisionPrescreptionList(objGet);
        if (getResponse.status === 1) {
            setVisionPrescreptionList(getResponse.responseValue)
        } else {

        }

    }

    let handleDeleteRow = async () => {
        // setLoder(1)
        setShowUnderProcess(1);
        let objDelete = {
            uhid: uhid,
            id: getRowId,
            userId: window.userId,
            clientId: clientID
        }
        console.log('delete', objDelete);
        let response = await DeleteVisionPrescreption(objDelete)
        if (response.status === 1) {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Data Deleted SuccessFully!")
            setTosterValue(0)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
            handleGet();
            handleClear();
        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage(response.responseValue)
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }


    let handleEdit = async (list) => {
        setUpdateBool(1);
        setRowId(list.id);

        if (list.eye === "Right") {
            setEyeType(1);
            if (list.productType === "Spectacles") {
                setSelectProductType(1);
            } else {
                setSelectProductType(2);
            }
            setSpherePowerRight(list.sphereR);
            setCylinderPowerRight(list.cylinderR);
            setAxisRight(list.axisR);
            setPrismRight(list.prismAmountR);
            setPrismBaseRight(list.prismBaseR);
            setAddPowerRight(list.powerR);
            setDiameter(list.diameter);
            setDuration(list.duration);
            setColour(list.colour);
            setBrand(list.brand);
            setAdditionalInformation(list.remark);

        }

        if (list.eye === "Left") {
            setEyeType(2);
            if (list.productType === "Spectacles") {
                setSelectProductType(1);
            } else {
                setSelectProductType(2);
            }
            setSpherePowerLeft(list.sphereL);
            setCylinderPowerLeft(list.cylinderL);
            setAxisLeft(list.axisL);
            setPrismLeft(list.prismAmountL);
            setPrismBaseLeft(list.prismBaseL);
            setAddPowerLeft(list.powerL);
            setDiameter(list.diameter);
            setDuration(list.duration);
            setColour(list.colour);
            setBrand(list.brand);
            setAdditionalInformation(list.remark);

        }
        if (list.eye === "Both") {
            setEyeType(3);
            if (list.productType === "Spectacles") {
                setSelectProductType(1);
            } else {
                setSelectProductType(2);
            }
            setSpherePowerRight(list.sphereR);
            setCylinderPowerRight(list.cylinderR);
            setAxisRight(list.axisR);
            setPrismRight(list.prismAmountR);
            setPrismBaseRight(list.prismBaseR);
            setAddPowerRight(list.powerR);
            setSpherePowerLeft(list.sphereL);
            setCylinderPowerLeft(list.cylinderL);
            setAxisLeft(list.axisL);
            setPrismLeft(list.prismAmountL);
            setPrismBaseLeft(list.prismBaseL);
            setAddPowerLeft(list.powerL);
            setDiameter(list.diameter);
            setDuration(list.duration);
            setColour(list.colour);
            setBrand(list.brand);
            setAdditionalInformation(list.remark);
        }

    }

    useEffect(() => {
        handleGet();
    }, [])

    let handleSave = async () => {

        if (eyeType === 1) {

            if (getSpherePowerRight === null || getSpherePowerRight === "") {
                document.getElementById('errtxtSpherePowerRight').innerHTML = "Please Enter Sphere Power Right";
                document.getElementById('errtxtSpherePowerRight').style.display = "block";
                return false;
            }
            if (getCylinderPowerRight === null || getCylinderPowerRight === "") {
                document.getElementById('errtxtCylinderPowerRight').innerHTML = "Please Enter Cylinder Power Right";
                document.getElementById('errtxtCylinderPowerRight').style.display = "block";
                return false;
            }
            if (getAxisRight === null || getAxisRight === "") {
                document.getElementById('errtxtAxisRight').innerHTML = "Please Enter Axis Right";
                document.getElementById('errtxtAxisRight').style.display = "block";
                return false;
            }
            if (getPrismRight === null || getPrismRight === "") {
                document.getElementById('errtxtPrismRight').innerHTML = "Please Enter Prism Right";
                document.getElementById('errtxtPrismRight').style.display = "block";
                return false;
            }
            // if(getPrismBaseRight === null || getPrismBaseRight === ""){
            //     document.getElementById('errtxtPrismBaseRight').innerHTML = "Please Enter Prism Base Right";
            //     document.getElementById('errtxtPrismBaseRight').style.display = "block";
            //     return false;
            // }
            if (getAddPowerRight === null || getAddPowerRight === "") {
                document.getElementById('errtxtAddPowerRight').innerHTML = "Please Enter Power Right";
                document.getElementById('errtxtAddPowerRight').style.display = "block";
                return false;
            }
            if (getDiameter === null || getDiameter === "") {
                document.getElementById('errtxtDiameter').innerHTML = "Please Enter Diameter";
                document.getElementById('errtxtDiameter').style.display = "block";
                return false;
            }
            if (getDuration === null || getDuration === "") {
                document.getElementById('errtxtDuration').innerHTML = "Please Enter Duration";
                document.getElementById('errtxtDuration').style.display = "block";
                return false;
            }
            if (getColour === null || getColour === "") {
                document.getElementById('errtxtColour').innerHTML = "Please Enter Colour";
                document.getElementById('errtxtColour').style.display = "block";
                return false;
            }
            if (getBrand === null || getBrand === "") {
                document.getElementById('errtxtBrand').innerHTML = "Please Enter Brand";
                document.getElementById('errtxtBrand').style.display = "block";
                return false;
            }

            var obj = {
                eyeType: eyeType,
                productType: SelectProductType,
                sphereR: getSpherePowerRight,
                cylinderR: getCylinderPowerRight,
                axisR: getAxisRight,
                prismAmountR: getPrismRight,
                prismBaseR: getPrismBaseRight,
                powerR: getAddPowerRight,
                diameter: getDiameter,
                duration: getDuration,
                colour: getColour,
                brand: getBrand,
                remark: getAdditionalInformation,
                userId: userId,
                clientId: clientID,
                uhid: uhid,
                id: getRowId,
            }

            if (updateBool === 0) {
                setShowUnderProcess(1);
                let response = await InsertVisionPrescription(obj);
                if (response.status === 1) {
                    setShowUnderProcess(0);
                    setShowToster(1);
                    setTosterMessage("Data Save SuccessFully!");
                    setTosterValue(0);
                    setTimeout(() => {
                        setShowToster(0);
                    }, 2000);

                    handleClear();
                    handleGet();

                } else {
                    setShowUnderProcess(0);
                    setShowToster(1);
                    setTosterMessage(response.responseValue);
                    setTosterValue(1);
                    setTimeout(() => {
                        setShowToster(0);
                    }, 2000);
                }
            } else {

                setShowUnderProcess(1);
                let response = await UpdateVisionPrescreption(obj);
                if (response.status === 1) {
                    setShowUnderProcess(0);
                    setShowToster(1);
                    setTosterMessage("Data Update SuccessFully!");
                    setTosterValue(0);
                    setTimeout(() => {
                        setShowToster(0);
                    }, 2000);

                    handleClear();
                    handleGet();

                } else {
                    setShowUnderProcess(0);
                    setShowToster(1);
                    setTosterMessage(response.responseValue);
                    setTosterValue(1);
                    setTimeout(() => {
                        setShowToster(0);
                    }, 2000);
                }
            }

        } else if (eyeType === 2) {


            if (getSpherePowerLeft === null || getSpherePowerLeft === "") {
                document.getElementById('errtxtSpherePowerLeft').innerHTML = "Please Enter Sphere Power Left";
                document.getElementById('errtxtSpherePowerLeft').style.display = "block";
                return false;
            }
            if (getCylinderPowerLeft === null || getCylinderPowerLeft === "") {
                document.getElementById('errtxtCylinderPowerLeft').innerHTML = "Please Enter Cylinder Power Left";
                document.getElementById('errtxtCylinderPowerLeft').style.display = "block";
                return false;
            }
            if (getAxisLeft === null || getAxisLeft === "") {
                document.getElementById('errtxtAxisLeft').innerHTML = "Please Enter Axis Left";
                document.getElementById('errtxtAxisLeft').style.display = "block";
                return false;
            }
            if (getPrismLeft === null || getPrismLeft === "") {
                document.getElementById('errtxtPrismLeft').innerHTML = "Please Enter Prism Left";
                document.getElementById('errtxtPrismLeft').style.display = "block";
                return false;
            }
            // if(getPrismBaseLeft === null || getPrismBaseLeft === ""){
            //     document.getElementById('errtxtPrismBaseLeft').innerHTML = "Please Enter Base Left";
            //     document.getElementById('errtxtPrismBaseLeft').style.display = "block";
            //     return false;
            // }
            if (getAddPowerLeft === null || getAddPowerLeft === "") {
                document.getElementById('errtxtAddPowerLeft').innerHTML = "Please Enter Power Left";
                document.getElementById('errtxtAddPowerLeft').style.display = "block";
                return false;
            }
            if (getDiameter === null || getDiameter === "") {
                document.getElementById('errtxtDiameter').innerHTML = "Please Enter Diameter";
                document.getElementById('errtxtDiameter').style.display = "block";
                return false;
            }
            if (getDuration === null || getDuration === "") {
                document.getElementById('errtxtDuration').innerHTML = "Please Enter Duration";
                document.getElementById('errtxtDuration').style.display = "block";
                return false;
            }
            if (getColour === null || getColour === "") {
                document.getElementById('errtxtColour').innerHTML = "Please Enter Colour";
                document.getElementById('errtxtColour').style.display = "block";
                return false;
            }
            if (getBrand === null || getBrand === "") {
                document.getElementById('errtxtBrand').innerHTML = "Please Enter Brand";
                document.getElementById('errtxtBrand').style.display = "block";
                return false;
            }


            var obj = {
                eyeType: eyeType,
                productType: SelectProductType,
                sphereL: getSpherePowerLeft,
                cylinderL: getCylinderPowerLeft,
                axisL: getAxisLeft,
                prismAmountL: getPrismLeft,
                prismBaseL: getPrismBaseLeft,
                powerL: getAddPowerLeft,
                diameter: getDiameter,
                duration: getDuration,
                colour: getColour,
                brand: getBrand,
                remark: getAdditionalInformation,
                userId: userId,
                clientId: clientID,
                uhid: uhid,
                id: getRowId,
            }
            if (updateBool === 0) {
                setShowUnderProcess(1);
                let response = await InsertVisionPrescription(obj);
                if (response.status === 1) {
                    setShowUnderProcess(0);
                    setShowToster(1);
                    setTosterMessage("Data Save SuccessFully!");
                    setTosterValue(0);
                    setTimeout(() => {
                        setShowToster(0);
                    }, 2000);

                    handleClear();
                    handleGet();

                } else {
                    console.log("response", response);
                    setShowUnderProcess(0);
                    setShowToster(1);
                    setTosterMessage(response.responseValue);
                    setTosterValue(1);
                    setTimeout(() => {
                        setShowToster(0);
                    }, 2000);
                }
            } else {
                setShowUnderProcess(1);
                let response = await UpdateVisionPrescreption(obj);
                if (response.status === 1) {
                    setShowUnderProcess(0);
                    setShowToster(1);
                    setTosterMessage("Data Update SuccessFully!");
                    setTosterValue(0);
                    setTimeout(() => {
                        setShowToster(0);
                    }, 2000);

                    handleClear();
                    handleGet();

                } else {
                    setShowUnderProcess(0);
                    setShowToster(1);
                    setTosterMessage(response.responseValue);
                    setTosterValue(1);
                    setTimeout(() => {
                        setShowToster(0);
                    }, 2000);
                }
            }
        }
        else if (eyeType === 3) {

            if (getSpherePowerRight === null || getSpherePowerRight === "") {
                document.getElementById('errtxtSpherePowerRight').innerHTML = "Please Enter Spehere Power Right";
                document.getElementById('errtxtSpherePowerRight').style.display = "block";
                return false;
            }
            if (getSpherePowerLeft === null || getSpherePowerLeft === "") {
                document.getElementById('errtxtSpherePowerLeft').innerHTML = "Please Enter Spehere Power Left";
                document.getElementById('errtxtSpherePowerLeft').style.display = "block";
                return false;
            }
            if (getCylinderPowerRight === null || getCylinderPowerRight === "") {
                document.getElementById('errtxtCylinderPowerRight').innerHTML = "Please Enter Cylinder Power Right";
                document.getElementById('errtxtCylinderPowerRight').style.display = "block";
                return false;
            }
            if (getCylinderPowerLeft === null || getCylinderPowerLeft === "") {
                document.getElementById('errtxtCylinderPowerLeft').innerHTML = "Please Enter Cylinder Power Left";
                document.getElementById('errtxtCylinderPowerLeft').style.display = "block";
                return false;
            }
            if (getAxisRight === null || getAxisRight === "") {
                document.getElementById('errtxtAxisRight').innerHTML = "Please Enter Axis Righ";
                document.getElementById('errtxtAxisRight').style.display = "block";
                return false;
            }
            if (getAxisLeft === null || getAxisLeft === "") {
                document.getElementById('errtxtAxisLeft').innerHTML = "Please Enter Axis Left";
                document.getElementById('errtxtAxisLeft').style.display = "block";
                return false;
            }
            if (getPrismRight === null || getPrismRight === "") {
                document.getElementById('errtxtPrismRight').innerHTML = "Please Enter Prism Right";
                document.getElementById('errtxtPrismRight').style.display = "block";
                return false;
            }
            if (getPrismLeft === null || getPrismLeft === "") {
                document.getElementById('errtxtPrismLeft').innerHTML = "Please Enter Prism Left";
                document.getElementById('errtxtPrismLeft').style.display = "block";
                return false;
            }
            // if(getPrismBaseRight === null || getPrismBaseRight === ""){
            //     document.getElementById('errtxtPrismBaseRight').innerHTML = "Please Enter Prism Base Right";
            //     document.getElementById('errtxtPrismBaseRight').style.display = "block";
            //     return false;
            // }
            // if(getPrismBaseLeft === null || getPrismBaseLeft === ""){
            //     document.getElementById('errtxtPrismBaseLeft').innerHTML = "Please Enter Prism Base Left";
            //     document.getElementById('errtxtPrismBaseLeft').style.display = "block";
            //     return false;
            // }
            if (getAddPowerRight === null || getAddPowerRight === "") {
                document.getElementById('errtxtAddPowerRight').innerHTML = "Please Enter Add Power Right";
                document.getElementById('errtxtAddPowerRight').style.display = "block";
                return false;
            }
            if (getAddPowerLeft === null || getAddPowerLeft === "") {
                document.getElementById('errtxtAddPowerLeft').innerHTML = "Please Enter Power Left";
                document.getElementById('errtxtAddPowerLeft').style.display = "block";
                return false;
            }
            if (getDiameter === null || getDiameter === "") {
                document.getElementById('errtxtDiameter').innerHTML = "Please Enter Diameter";
                document.getElementById('errtxtDiameter').style.display = "block";
                return false;
            }
            if (getDuration === null || getDuration === "") {
                document.getElementById('errtxtDuration').innerHTML = "Please Enter Duration";
                document.getElementById('errtxtDuration').style.display = "block";
                return false;
            }
            if (getColour === null || getColour === "") {
                document.getElementById('errtxtColour').innerHTML = "Please Enter Colour";
                document.getElementById('errtxtColour').style.display = "block";
                return false;
            }
            if (getBrand === null || getBrand === "") {
                document.getElementById('errtxtBrand').innerHTML = "Please Enter Brand";
                document.getElementById('errtxtBrand').style.display = "block";
                return false;
            }

            var obj = {
                uhid: uhid,
                eyeType: eyeType,
                productType: SelectProductType,
                sphereR: getSpherePowerRight,
                sphereL: getSpherePowerLeft,
                cylinderR: getCylinderPowerRight,
                cylinderL: getCylinderPowerLeft,
                axisR: getAxisRight,
                axisL: getAxisLeft,
                prismAmountR: getPrismRight,
                prismBaseR: getPrismBaseRight,
                prismAmountL: getPrismLeft,
                prismBaseL: getPrismBaseLeft,
                powerR: getAddPowerRight,
                powerL: getAddPowerLeft,
                diameter: getDiameter,
                duration: getDuration,
                colour: getColour,
                brand: getBrand,
                remark: getAdditionalInformation,
                userId: userId,
                clientId: clientID,
                id: getRowId,
            }
            if (updateBool === 0) {
                setShowUnderProcess(1);
                let response = await InsertVisionPrescription(obj);
                if (response.status === 1) {
                    setShowUnderProcess(0);
                    setShowToster(1);
                    setTosterMessage("Data Save SuccessFully!");
                    setTosterValue(0);
                    setTimeout(() => {
                        setShowToster(0);
                    }, 2000);

                    handleClear();
                    handleGet();

                } else {
                    console.log("response", response);
                    setShowUnderProcess(0);
                    setShowToster(1);
                    setTosterMessage(response.responseValue);
                    setTosterValue(1);
                    setTimeout(() => {
                        setShowToster(0);
                    }, 2000);
                }
            } else {
                setShowUnderProcess(1);
                let response = await UpdateVisionPrescreption(obj);
                if (response.status === 1) {
                    setShowUnderProcess(0);
                    setShowToster(1);
                    setTosterMessage("Data Update SuccessFully!");
                    setTosterValue(0);
                    setTimeout(() => {
                        setShowToster(0);
                    }, 2000);

                    handleClear();
                    handleGet();

                } else {
                    setShowUnderProcess(0);
                    setShowToster(1);
                    setTosterMessage(response.responseValue);
                    setTosterValue(1);
                    setTimeout(() => {
                        setShowToster(0);
                    }, 2000);
                }
            }
        }


    };


    let handleClear = async () => {

        setEyeType(1);
        setSelectProductType(1);
        setSpherePowerRight('');
        setSpherePowerLeft('');
        setCylinderPowerRight('');
        setCylinderPowerLeft('');
        setAxisRight('');
        setAxisLeft('');
        setPrismBaseRight('');
        setPrismLeft('');
        setPrismRight('');
        setPrismBaseLeft('');
        setAddPowerRight('');
        setAddPowerLeft('');
        setDiameter('');
        setDuration('');
        setColour('');
        setBrand('');
        setAdditionalInformation('');
    }


    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 inputwidth">
                            <Heading text={t("Lens Specifications")} />
                            <BoxContainer>
                                <div className="mb-1 me-2">
                                    <label htmlFor="caretakerName" className="form-label">{t("Product Type")}<span className="starMandatory"></span></label>
                                    <select className="form-select form-select-sm" id="dropdownProductType" onChange={handleSelectProductType}>
                                        <option value="1">Spectacles</option>
                                        <option value="2">Contact Lense</option>
                                    </select>
                                </div>
                                <div className="mb-1 me-2">
                                    <label htmlFor="caretakerName" className="form-label">{t("Eye")}<span className="starMandatory">*</span></label>
                                    <select className="form-select form-select-sm" id="dropdownEye" value={eyeType} onChange={handleSelectEye}>
                                        <option value="1">Right</option>
                                        <option value="2">Left</option>
                                        <option value="3">Both</option>
                                    </select>
                                </div>
                                <div className="mb-1 me-2">
                                    <label htmlFor="caretakerName" className="form-label">{t("Sphere Power")}<span className="starMandatory">*</span></label>
                                    <div className="SphereCustom">
                                        {eyeType === 1 ?
                                            <div className="SphereCustom-inn" id="SpherePowerRight">
                                                <span>Right Eye: </span>
                                                <span>
                                                    <input type="number"  className="form-control form-control-sm" value={getSpherePowerRight} name="txtSpherePowerRight" onChange={handleChange} />
                                                <small id="errtxtSpherePowerRight" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </span>
                                            </div>
                                            : eyeType === 2 ?
                                                <div className="SphereCustom-inn" id="SpherePowerLeft">
                                                    <span>Left Eye: </span>
                                                    <span><input type="number"  className="form-control form-control-sm" value={getSpherePowerLeft} name="txtSpherePowerLeft" onChange={handleChange} />
                                                    <small id="errtxtSpherePowerLeft" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </span>
                                                </div>
                                                :
                                                <>
                                                    <div className="SphereCustom-inn" id="SpherePowerRight">
                                                        <span>Right Eye: </span>
                                                        <span><input type="number"  className="form-control form-control-sm" value={getSpherePowerRight} name="txtSpherePowerRight" onChange={handleChange} />
                                                        <small id="errtxtSpherePowerRight" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                        </span>
                                                    </div>
                                                    <div className="SphereCustom-inn" id="SpherePowerLeft">
                                                        <span>Left Eye: </span>
                                                        <span><input type="number"  className="form-control form-control-sm" value={getSpherePowerLeft} name="txtSpherePowerLeft" onChange={handleChange} />
                                                        <small id="errtxtSpherePowerLeft" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                        </span>
                                                    </div>
                                                </>
                                        }

                                    </div>
                                </div>

                                <div className="mb-1 me-2">
                                    <label htmlFor="caretakerName" className="form-label">{t("Cylinder Power")}<span className="starMandatory">*</span></label>
                                    <div className="SphereCustom">
                                        {eyeType === 1 ?
                                            <div className="SphereCustom-inn" id="CylinderPowerRight" >
                                                <span>Right Eye: </span>
                                                <span><input type="number"  className="form-control form-control-sm" value={getCylinderPowerRight} name="txtCylinderPowerRight" onChange={handleChange} />
                                                <small id="errtxtCylinderPowerRight" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                </span>
                                            </div>
                                            : eyeType === 2 ?
                                                <div className="SphereCustom-inn" id="CylinderPowerLeft">
                                                    <span>Left Eye: </span>
                                                    <span><input type="number"  className="form-control form-control-sm" value={getCylinderPowerLeft} name="txtCylinderPowerLeft" onChange={handleChange} />
                                                    <small id="errtxtCylinderPowerLeft" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </span>
                                                </div>
                                                :
                                                <>
                                                    <div className="SphereCustom-inn" id="CylinderPowerRight" >
                                                        <span>Right Eye: </span>
                                                        <span><input type="number"  className="form-control form-control-sm" value={getCylinderPowerRight} name="txtCylinderPowerRight" onChange={handleChange} />
                                                        <small id="errtxtCylinderPowerRight" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                        </span>
                                                    </div>
                                                    <div className="SphereCustom-inn" id="CylinderPowerLeft">
                                                        <span>Left Eye: </span>
                                                        <span><input type="number"  className="form-control form-control-sm" value={getCylinderPowerLeft} name="txtCylinderPowerLeft" onChange={handleChange} />
                                                        <small id="errtxtCylinderPowerLeft" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                        </span>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>

                                <div className="mb-1 me-2">
                                    <label htmlFor="caretakerName" className="form-label">{t("Axis")}<span className="starMandatory">*</span></label>
                                    <div className="SphereCustom">
                                        {eyeType === 1 ?
                                            <div className="SphereCustom-inn" id="AxisRight">
                                                <span>Right Eye: </span>
                                                <span><input type="number"  className="form-control form-control-sm" value={getAxisRight} name="txtAxisRight" onChange={handleChange} />
                                                <small id="errtxtAxisRight" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                </span>
                                            </div>
                                            : eyeType === 2 ?
                                                <div className="SphereCustom-inn" id="AxisLeft">
                                                    <span>Left Eye: </span>
                                                    <span><input type="number"  className="form-control form-control-sm" value={getAxisLeft} name="txtAxisLeft" onChange={handleChange} />
                                                    <small id="errtxtAxisLeft" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </span>
                                                </div>
                                                :
                                                <>
                                                    <div className="SphereCustom-inn" id="AxisRight">
                                                        <span>Right Eye: </span>
                                                        <span><input type="number"  className="form-control form-control-sm" value={getAxisRight} name="txtAxisRight" onChange={handleChange} />
                                                        <small id="errtxtAxisRight" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                        </span>
                                                    </div>

                                                    <div className="SphereCustom-inn" id="AxisLeft">
                                                        <span>Left Eye: </span>
                                                        <span><input type="number"  className="form-control form-control-sm" value={getAxisLeft} name="txtAxisLeft" onChange={handleChange} />
                                                        <small id="errtxtAxisLeft" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                        </span>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>

                                <div className="mb-1 me-2">
                                    <label htmlFor="caretakerName" className="form-label">{t("Prism")}<span className="starMandatory">*</span></label>
                                    {eyeType === 1 ?
                                        <div className="SphereCustomrow" id="PrismRight">
                                            <div className="SphereCustom-inn">
                                                <span>Right Eye: </span>
                                                <span><input type="number"  className="form-control form-control-sm" value={getPrismRight} name="txtPrismRight" onChange={handleChange} />
                                                <small id="errtxtPrismRight" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                </span>
                                            </div>

                                            <div className="SphereCustom-inn" >
                                                <span>Base: </span>
                                                <span><select className="form-select form-select-sm" id="dropdownPrismBaseRight" value={getPrismBaseRight} on onChange={handleSelectPrismBaseRight}>
                                                    <option value="Up">Up</option>
                                                    <option value="Down">Down</option>
                                                    <option value="In">In</option>
                                                    <option value="Out">Out</option>
                                                </select></span>
                                            </div>
                                        </div>
                                        : eyeType === 2 ?
                                            <div className="SphereCustomrow" id="PrismLeft">
                                                <div className="SphereCustom-inn" >
                                                    <span>Left Eye: </span>
                                                    <span><input type="number"  className="form-control form-control-sm" value={getPrismLeft} name="txtPrismLeft" onChange={handleChange} />
                                                    <small id="errtxtPrismLeft" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </span>
                                                </div>

                                                <div className="SphereCustom-inn">
                                                    <span>Base: </span>
                                                    <span><select className="form-select form-select-sm" id="dropdownPrismBaseLeft" value={getPrismBaseLeft} onChange={handleSelectPrismBaseLeft}>
                                                        <option value="Up">Up</option>
                                                        <option value="Down">Down</option>
                                                        <option value="In">In</option>
                                                        <option value="Out">Out</option>
                                                    </select></span>
                                                </div>
                                            </div>

                                            :
                                            <>
                                                <div className="SphereCustomrow" id="PrismRight">
                                                    <div className="SphereCustom-inn">
                                                        <span>Right Eye: </span>
                                                        <span><input type="number"  className="form-control form-control-sm" value={getPrismRight} name="txtPrismRight" onChange={handleChange} />
                                                        <small id="errtxtPrismRight" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                        </span>
                                                    </div>

                                                    <div className="SphereCustom-inn" >
                                                        <span>Base: </span>
                                                        <span><select className="form-select form-select-sm" id="dropdownPrismBaseRight" value={getPrismBaseRight} onChange={handleSelectPrismBaseRight}>
                                                            <option value="Up">Up</option>
                                                            <option value="Down">Down</option>
                                                            <option value="In">In</option>
                                                            <option value="Out">Out</option>
                                                        </select></span>
                                                    </div>
                                                </div>

                                                <div className="SphereCustomrow" id="PrismLeft">
                                                    <div className="SphereCustom-inn" >
                                                        <span>Left Eye: </span>
                                                        <span><input type="number"  className="form-control form-control-sm" value={getPrismLeft} name="txtPrismLeft" onChange={handleChange} />
                                                        <small id="errtxtPrismLeft" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                        </span>
                                                    </div>

                                                    <div className="SphereCustom-inn">
                                                        <span>Base: </span>
                                                        <span><select className="form-select form-select-sm" id="dropdownPrismBaseLeft" value={getPrismBaseLeft} onChange={handleSelectPrismBaseLeft}>
                                                            <option value="Up">Up</option>
                                                            <option value="Down">Down</option>
                                                            <option value="In">In</option>
                                                            <option value="Out">Out</option>
                                                        </select></span>
                                                    </div>
                                                </div>

                                            </>
                                    }
                                </div>

                                <div className="mb-1 me-2">
                                    <label htmlFor="caretakerName" className="form-label">{t("Add Power")}<span className="starMandatory">*</span></label>
                                    <div className="SphereCustom">
                                        {eyeType === 1 ?
                                            <div className="SphereCustom-inn" id="AddPowerRight">
                                                <span>Right Eye: </span>
                                                <span><input type="number"  className="form-control form-control-sm" value={getAddPowerRight} name="txtAddPowerRight" onChange={handleChange} />
                                                <small id="errtxtAddPowerRight" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                </span>
                                            </div>
                                            : eyeType === 2 ?
                                                <div className="SphereCustom-inn" id="AddPowerLeft">
                                                    <span>Left Eye: </span>
                                                    <span><input type="number"  className="form-control form-control-sm" value={getAddPowerLeft} name="txtAddPowerLeft" onChange={handleChange} />
                                                    <small id="errtxtAddPowerLeft" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                    </span>
                                                </div>
                                                :
                                                <>
                                                    <div className="SphereCustom-inn" id="AddPowerRight">
                                                        <span>Right Eye: </span>
                                                        <span><input type="number"  className="form-control form-control-sm" value={getAddPowerRight} name="txtAddPowerRight" onChange={handleChange} />
                                                        <small id="errtxtAddPowerRight" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                        </span>
                                                    </div>
                                                    <div className="SphereCustom-inn" id="AddPowerLeft">
                                                        <span>Left Eye: </span>
                                                        <span><input type="number"  className="form-control form-control-sm" value={getAddPowerLeft} name="txtAddPowerLeft" onChange={handleChange} />
                                                        <small id="errtxtAddPowerLeft" className="form-text text-danger" style={{ display: 'none' }}></small>
                                                        </span>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>

                                <div className="mb-1 me-2">
                                    <label htmlFor="caretakerName" className="form-label">{t("Contact Lens Details (if applicable)")}<span className="starMandatory">*</span></label>
                                    <div className="SphereCustomrow">
                                        <div className="SphereCustom-inn">
                                            <span>Diameter: </span>
                                            <span><input type="number"  className="form-control form-control-sm" value={getDiameter} name="txtDiameter" onChange={handleChange} />
                                            <small id="errtxtDiameter" className="form-text text-danger" style={{ display: 'none' }}></small>
                                            </span>
                                        </div>

                                        <div className="SphereCustom-inn">
                                            <span>Duration: </span>
                                            <span><input type="text" className="form-control form-control-sm" value={getDuration} name="txtDuration" onChange={handleChange} />
                                            <small id="errtxtDuration" className="form-text text-danger" style={{ display: 'none' }}></small>
                                            </span>
                                        </div>

                                        <div className="SphereCustom-inn">
                                            <span>Colour: </span>
                                            <span><input type="text" className="form-control form-control-sm" value={getColour} name="txtColour" onChange={handleChange} />
                                            <small id="errtxtColour" className="form-text text-danger" style={{ display: 'none' }}></small>
                                            </span>
                                        </div>

                                        <div className="SphereCustom-inn">
                                            <span>Brand: </span>
                                            <span><input type="text" className="form-control form-control-sm" value={getBrand} name="txtBrand" onChange={handleChange} />
                                            <small id="errtxtBrand" className="form-text text-danger" style={{ display: 'none' }}></small>
                                            </span>
                                        </div>

                                        <div className="SphereCustom-inn">
                                            <span>Additional Instructions: </span>
                                            <span>
                                                <textarea className="form-control form-control-sm" value={getAdditionalInformation} name="txtAdditionalInformation" onChange={handleChange} ></textarea>
                                                {/* <input type="text" className="form-control form-control-sm" value={getAdditionalInformation} name="txtAdditionalInformation" onChange={handleChange} /> */}
                                            <small id="errtxtAdditionalInformation" className="form-text text-danger" style={{ display: 'none' }}></small>
                                            </span>
                                        </div>

                                    </div>
                                </div>


                                <div className="mb-1 relative">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                    <div>
                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />

                                                    : <div>
                                                        {updateBool === 0 ?
                                                            <>
                                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleSave}><img src={saveButtonIcon} className='icnn' alt='' />{t("Save")}</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt='' />{t("Cancel")}</button>
                                                            </>
                                                            :
                                                            <>
                                                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={handleSave}>{t("UPDATE")}</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { setUpdateBool(0); handleClear() }}>{t("Cancel")}</button>
                                                            </>
                                                        }
                                                    </div>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </BoxContainer>

                        </div>
                        <div className="col-12 mt-3">
                            <div className='handlser'>
                                <Heading text={t("Lens Specifications List")} />
                                <div style={{ position: 'relative' }}>
                                    <input type="text" className='form-control form-control-sm' placeholder={t("Search")} value={'searchTerm'} onChange={'handleSearch'} />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
                            <div className="med-table-section ipdtable" style={{ "height": "74vh" }}>

                                <TableContainer>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "3%" }} rowSpan={2}>#</th>
                                            <th rowSpan={2}>{t("Product Type")}</th>
                                            <th rowSpan={2}>{t("Eye")}</th>
                                            <th colSpan={2}>{t("Sphere Power")}</th>
                                            <th colSpan={2}>{t("Cylinder Power")}</th>
                                            <th colSpan={2}>{t("Axis")}</th>
                                            <th colSpan={2}>{t("Prism")}</th>
                                            <th colSpan={2}>{t("Prism Base")}</th>
                                            <th colSpan={2}>{t("Power")}</th>
                                            <th rowSpan={2} style={{textAlign:'left'}}>{t("Contact Lens Details")}</th>
                                            <th rowSpan={2}>{t("Date Time")}</th>
                                            <th rowSpan={2}>{t("User Name")}</th>
                                            <th rowSpan={2} style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                                        </tr>
                                        <tr>
                                            <th>{t("Right")}</th>
                                            <th>{t("Left")}</th>
                                            <th>{t("Right")}</th>
                                            <th>{t("Left")}</th>
                                            <th>{t("Right")}</th>
                                            <th>{t("Left")}</th>
                                            <th>{t("Right")}</th>
                                            <th>{t("Left")}</th>
                                            <th>{t("Right")}</th>
                                            <th>{t("Left")}</th>
                                            <th>{t("Right")}</th>
                                            <th>{t("Left")}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {getVisionPrescreptionList
                                            && getVisionPrescreptionList.filter((val) => `${val.productType}`.toLowerCase().includes(searchTerm.toLowerCase())).map((val, ind) => {
                                                return (
                                                    <tr key={val.id}>
                                                        <td className="text-center">{ind + 1}</td>
                                                        <td>{val.productType}</td>
                                                        <td>{val.eye}</td>
                                                        <td>{val.sphereR} </td>
                                                        <td> {val.sphereL}</td>
                                                        <td>{val.cylinderR}</td>
                                                        <td> {val.cylinderL}</td>
                                                        <td>{val.axisR} </td>
                                                        <td> {val.axisL}</td>
                                                        <td>{val.prismAmountR} </td>
                                                        <td>{val.prismBaseR}</td>
                                                        <td>{val.prismAmountL}</td>
                                                        <td> {val.prismBaseL}</td>
                                                        <td>{val.powerR} </td>
                                                        <td>{val.powerL}</td>
                                                        <td style={{textAlign:'left'}}>
                                                            <strong>Diameter:&nbsp; </strong>{val.diameter} <br />
                                                            <strong>Duration:&nbsp;</strong> {val.duration}<br />
                                                            <strong>Colour:&nbsp;</strong> {val.colour}<br />
                                                            <strong>Brand:&nbsp;</strong> {val.brand} <br />
                                                            <strong>Additional Instructions:&nbsp;</strong> {val.remark}
                                                        </td>
                                                        <td>{val.createdDate}</td>
                                                        <td>{val.userName}</td>
                                                        <td>
                                                            <div className="action-button">
                                                                <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={IconEdit} onClick={() => { handleEdit(val) }} alt='' /></div>
                                                                <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={IconDelete} onClick={() => { setRowId(val.id) }} alt='' /></div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}

                                    </tbody>
                                </TableContainer>
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">

                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'>{t("Delete?")}</div>
                                                <div className='popDeleteContent'>{t("Are_you_sure_you_want_to_delete?")}</div>
                                            </div>
                                            <div className="modal-footer1 text-center">

                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={handleDeleteRow} data-bs-dismiss="modal">{t("Delete")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>


            </section>
        </>
    )
}