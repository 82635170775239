async function TaskCategoryMaster(clientId) {
    let url = window.AppbaseUrl+'/api/TaskCategoryMaster/GetAllTaskCategory?clientId=' + clientId;
    let head = { "Content-Type": "application/JSON", accept : '*/*' };
    
    let data  = {};

  let response = fetch(url, {
    headers: head,
    method : 'GET'
  }).then((res) => res.json()).then(data);

  return response;
}
export default TaskCategoryMaster;
