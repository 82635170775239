import React, { useEffect, useState } from 'react'
import GetBrandList from '../../Clinical/API/KnowMedsAPI/GetBrandList'
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import Search, { FindByQuery } from '../../Code/Serach'
 
export default function AntiDProphylaxis({ antiDProphylaxis ,extraMedName}) {
    const { t } = useTranslation();
    document.body.dir = i18n.dir()
    let row = {

        id: 0,
        drugId: "",
        drugName: "",
        medicineId: 0,

    }
    
    let [medicationList, setMedicationList] = useState([])
    let [medicationListTemp, setMedicationListTemp] = useState([])
    let [medicationData, setMedicationData] = useState([row])
    let [showSearchBoxMedic, setShowSearchBoxMedic] = useState(-1)
 
    let [showMessage, setShowMeassage] = useState("")
    let [showAlertToster, setShowAlertToster] = useState(0)
    let [showSuccessToster, setShowSuccessToster] = useState(0)
 
   
    let getData = async () => {
        let response = await GetBrandList()
        if (response.status === 1) {
            setMedicationList(response.responseValue)
            setMedicationListTemp(response.responseValue)
            // console.log("antibioticdata" , response.responseValue)
        }
 
    }
    let handleChange = (e, ind) => {
 
 
        try {
            let temp = [...medicationData]
            let value = e.target.value
            let name = e.target.name
            console.log("Value typed", value)
            if (e.target.value !== "") {
                if (name === "drugData") {
                    temp[ind]['id'] = 0
                    temp[ind]["drugId"] = 0
                    temp[ind]["drugName"] = value
                  
 
 
                    let response = FindByQuery(medicationList, value, "brandName")
                    if (value.toString().trim().toLowerCase() !== "flu".trim().toLowerCase()) {
 
                        if (response.length != 0) {
                            setMedicationListTemp(response)
                            setShowSearchBoxMedic(ind)
                            extraMedName(temp[ind],value);
                        }
                        else {
                            setMedicationListTemp([t("No Data Found"), ""])
                            setShowSearchBoxMedic(-1)
 
                        }
 
 
                        let flag = 0
                        medicationData.map((val, ind) => {
                            if (val.id === 0 && val.drugId === "") {
                                flag = 1
                                return
                            }
                        })
                        if (flag !== 1) {
                    
 
                        }
                 
                    }
                    else {
 
                        if (response.length != 0) {
                            setMedicationListTemp(response)
                            setShowSearchBoxMedic(ind)
                        }
                        else {
                            setMedicationListTemp([t("No Data Found"), ""])
                            setShowSearchBoxMedic(-1)
 
                        }
                        let flag = 0
                        medicationData.map((val, ind) => {
                            if (val.id === 0 && val.drugId === "") {
                                flag = 1
                                return
                            }
                        })
                        if (flag !== 1) {
                           
 
                        }
                       
                        console.log('MedicationData', medicationData);
                    }
 
 
                }
  
 
                    console.log('MedicationData', medicationData);
 
             

            }
            else {
                if (name === "drugData") {
                    temp[ind]["drugId"] = 0
                    temp[ind]["drugName"] = ""
                    temp[ind]['id'] = 0
                  
                
                    setMedicationData([...temp])
 
                    console.log('MedicationData', medicationData);
                }
     
                setShowSearchBoxMedic(-1)
           
 
 
            }
        }
        catch (e) {
            setShowAlertToster(1)
            setShowMeassage(e.message)
        }
    }
  
 
    let handleClick = (name, ind, data) => {
 
        try {
            let temp = [...medicationData]
            // console.log("cdsc", data[5])
            if (name === "drugData") {
                console.log("csdcsdcsdcsdc145236")
                let response = FindByQuery(temp, data[1], "drugName")
                if (response.length === 0) {
                    if (response.toString().trim().toLowerCase() !== "flu".toString().trim().toLowerCase()) {
                        temp[ind]["drugId"] = data[0]
                        temp[ind]["drugName"] = data[1]
                        temp[ind]["dosageForm"] = data[2]
                        temp[ind]["dosageStrength"] = data[3]
                        temp[ind]["doseUnit"] = data[4]
                        temp[ind]["isAntibiotic"] = data[5]
                        temp[ind]["id"] = data[0]

                        // sendNotification(temp[ind])
                        // setDrugInteractionId([...drugInteractionId, data[0]])
                        // GetDrugInteractionn([drugInteractionId[drugInteractionId.length - 1], data[0]])
                        document.getElementById("drugData" + ind).value = data[2] + "-" + data[1] + "-" + data[3]
                        setShowSearchBoxMedic(-1)
                        setMedicationData(temp)
                        antiDProphylaxis(temp);
                        extraMedName(temp);
                        console.log("antibiotic" , medicationListTemp)
                        
                    }
                    else {
                        temp[ind]["drugId"] = data[0]
                        temp[ind]["drugName"] = data[1]
                        temp[ind]["dosageForm"] = data[2]
                        temp[ind]["dosageStrength"] = data[3]
                        temp[ind]["doseUnit"] = data[4]
                        temp[ind]["isAntibiotic"] = data[5]
                        temp[ind]["id"] = data[0]
                        // sendNotification(temp[ind])
                        // setDrugInteractionId([...drugInteractionId, data[0]])
                        // GetDrugInteractionn([drugInteractionId[drugInteractionId.length - 1], data[0]])
                        document.getElementById("drugData" + ind).value = data[2] + "-" + data[1] + "-" + data[3]
                        setShowSearchBoxMedic(-1)
                        setMedicationData(temp)
 
                        // setIsShowFrequncy(0)
                    }
 
                }
                else {
                    setShowAlertToster(1)
                    setShowMeassage(t("Medicine Already Exits"))
                    setShowSearchBoxMedic(-1)
                }
 
            }
            // else if (name === "frequencyData") {
            //     // console.log("data", data)
            //     temp[ind]["doseFrequency"] = data
            //     document.getElementById("frequencyData" + ind).value = data
 
            //     setShowSearchBoxFrequncy(-1)
            //     setMedicationData(temp)
 
 
            // }
 
            // else if (name === "rationalData") {
            //     temp[ind]["rationalId"] = data[0]
            //     temp[ind]["problemName"] = data[1]
 
            //     setShowSearchBoxRational(-1)
            //     document.getElementById("rationalData" + ind).value = data[1];
            //     setMedicationData(temp)
 
 
            // }
        }
        catch (e) {
            setShowAlertToster(1)
            setShowMeassage(e.message)
        }
 
    }
    let handleKeyDown = (e) => {
        if (e.keyCode === 9) {
            // GetDrugInteractionn(drugInteractionId)
        }
    }
   
   
    let handleDeleteRow = async (ind, id, value, drugId) => {
        let temp = [...medicationData];
        temp.splice(ind, 1);

    if (temp.length > 0) {
        setMedicationData(temp);
    } 
     
     
    }


const handleAddRow=()=>{
    setMedicationData([...medicationData, row, ]);
}

    
    useEffect(() => {
        getData()
    }, [])

    return (
        <>
          {medicationData && medicationData.map((val, ind) => {
            return(
          
    
                <>

                <div className='col-12 d-flex gap-2 p-0 mt-2'>
                    <input autoComplete="off" style={{width: '-webkit-fill-available'}} type='text' className='opdmedicationinput w-0' name='drugData' value={val.drugName.toUpperCase() ? val.drugName.toUpperCase() : ""} placeholder={t('Dosage Form - Medicine Name - Strength')} id={`drugData${ind}`} onChange={(e) => { handleChange(e, ind) }} onKeyDown={handleKeyDown}  />
                   
                    {/* {medicationListTemp && <AutoComplete searchKey="dosageFormName" suggestions={medicationListTemp} />}  */}
                   
                    {showSearchBoxMedic === ind ?
                        <div id="medicListDiv" className='position-absolute opdmedicationsearchbox'>
                            <ul id="drugul">
                                {medicationListTemp && medicationListTemp.map((val, index) => {
                                    return (
 
                                        <li className='pointer' onClick={(e) => { handleClick("drugData", ind, [val.id, val.brandName, val.dosageFormName, val.doseStrength, val.doseUnitID, val.isAntibiotic]) }}>{val.brandName.toUpperCase()}</li>
                                    )
                                })}
                            </ul>
                            <div className='full-screen-div-opd' onClick={() => { setShowSearchBoxMedic(-1) }}></div>
 
                        </div>
                        : ""}
                
                <div className="action-button">
                               
                               <i className="bi bi-plus" onClick={handleAddRow} title="Add Item"  style={{background: '#9be79b54'}}></i>
                                  <i className="bi bi-trash3" title="Remove Item" style={{background: '#FFEFEF' , color : 'red'}} onClick={() => { handleDeleteRow(ind)}} id={`removeRowId${ind}`}></i> 
                                               </div>
                </div>
               
         </>
            )
          })
        }
        </>
    )
}