import React, { useEffect, useState } from "react";
import GetDieteticsPatientList from "../API/GetDieteticsPatientList";
import { Link, useNavigate } from "react-router-dom";
import OffcanvasLogo from "../../assets/images/Navbar/offcanvas-logo.png";
import { getIPDUHIDChangeData } from "../../Reduce/IPD/IPDUHIDChange";
import store from "../../Store";
import Search from "../../Code/Serach";
import Navbar from "../../Component/Navbar";
import searchIcon from "../../assets/images/Navbar/search.svg";
import GetAPIDepartmentMaster from "../../Admin/Api/Master/DepartmentMasterAPI/GetAPIDepartmentMaster";
// import viewicn from "../../../assets/images/PatientListIcons/viewicn.svg";
import viewicn from "../../../src/assets/images/PatientListIcons/viewicn.svg";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

export default function DieteticsPatientList(props) {
  let patientsendData = window.sessionStorage.getItem("IPDpatientsendData")
    ? JSON.parse(window.sessionStorage.getItem("IPDpatientsendData"))
    : [];

  let [patientList, setPatientList] = useState([]);
  let [patientListTemp, setPatientListTemp] = useState([]);
  let [departmentList, setDepartmentList] = useState([]);
  const { t } = useTranslation();
  let getAllDeptList = async () => {
    let response = await GetAPIDepartmentMaster();
    if (response.status === 1) {
      setDepartmentList(response.responseValue);
    }
  };

  // let navigate = useNavigate()

  let getDieteticsPatientList = async () => {
    let id = JSON.parse(
      window.sessionStorage.getItem("activePage")
    ).DepartmentId;
    let response = await GetDieteticsPatientList(id);
    if (response.status === 1) {
      
      setPatientList(response.responseValue);
      setPatientListTemp(response.responseValue);
    }
    
  };

  let handleActiveUHID = (val) => {
   
    let oldPatientList = window.sessionStorage.getItem("activePatientDatadiet")
      ? JSON.parse(window.sessionStorage.getItem("activePatientDatadiet"))
      : [];

    window.sessionStorage.setItem(
      "activeUHIDdiet",
      JSON.stringify({ Uhid: val.uhId })
    );
    window.sessionStorage.setItem(
      "activePatientDatadiet",
      JSON.stringify([...oldPatientList, val])
    );
    props.setShowMenu(1);
  };

  let handleSearch = (e) => {
    if (e.target.value !== "") {
      let result = Search(patientList, e.target.value);
      if (result.length != 0) {
        setPatientListTemp(result);
      }
      // else {
      //     setPatientListTemp(patientList)
      // }
    } else {
      setPatientListTemp(patientList);
    }
  };

  let handleChange = (e) => {
    getDieteticsPatientList(e.target.value);
  };
  useEffect(() => {
    getDieteticsPatientList(13);
    getAllDeptList();
    window.sessionStorage.removeItem("activeUHIDdiet");
    window.sessionStorage.removeItem("activePatientDatadiet");
  }, []);
  document.body.dir = i18n.dir();
  return (
    <>
      {/* {props.showNavbar === 1 ? (
        <div className="layOutSurgeryOTNavbar">
          <div>
            <div className="offcanvas-logo">
              <Link to="/dashboard/">
                <img src={OffcanvasLogo} />
              </Link>
            </div>
          </div>

          <Navbar />
        </div>
      ) : (
        ""
      )} */}
      <div className="col-12 mt-5 pt-2">
        <div className="med-box">
          <div
            className={`${props.showNavbar === 1 ? "otDashboardWrapper" : ""} `}
          >
            <div className="tabular-section pb-2">
              {/* <ul className="nav nav-pills  ipdTab" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#admintted"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                    name="0"
                  >
                    {t("ADMITTED_PATIENTS")}
                  </button>
                </li> */}
              {/* <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#consultation" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Consultation Patient</button>
                </li> */}
              {/* <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#admintted"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                    name="1"
                  >
                   {t("Discharge_Patient")}
                  </button>
                </li> */}
              {/* </ul> */}
            </div>
            <div className="mb-2 me-2"></div>
            <div className="row m-0">
              <div className="col-sm-9 ps-3 title">{t("Dietetics_Patient_List")}</div> 
              <div className="col-sm-3 pe-3 d-flex justify-content-end">
                <div className="admittedSearchBox">
                <input
                  type="text"
                  placeholder={t("Search...")}
                  className="searchBarOPD_"
                  onChange={handleSearch}
                />
                <img src={searchIcon} className="searchBarIcon" />
                </div>                
              </div>
            </div>
            <div
              className="med-table-section mt-3"
              style={{ height: 'calc(100vh - 190px)'}}
            >
              <table className="med-table border_ border-bottom_ striped med-table-ipd">
                <thead>
                  <th className="text-center pe-1" style={{ width: "3%" }}>
                    {t("S.No.")}
                  </th>
                  <th style={{width:'12%'}}>{t("Patient_Name")}</th>
                  {/* <th>{t("Uhid")}</th> */}
                  <th>{t("IP_No")}</th>
                  <th>{t("Phone")}</th>
                  <th>{t("Admit_Date")}</th>
                  <th>{t("Consultant")}</th>
                  <th>{t("Diagnosis")}</th>
                  {/* <th>Nurse Details</th> */}
                  <th>{t("WARD_BED")}</th>
                  <th className="text-center">{t("Action")}</th>
                </thead>
                <tbody>
                  {patientListTemp &&
                    patientListTemp.map((val, ind) => {
                      return (
                        <tr>
                          <td className="text-center pe-1">{ind + 1}</td>
                          {/* <td>
                            {val.patientName} {val.age}/{val.gender}
                          </td> */}
                          <td className='pe-3'>
                            <div className='txtb'>{val.patientName.toUpperCase()}</div>
                            <div className="d-flex gap-1" style={{fontSize:'12px'}}> <div className="d-flex"><span title="Age"> {val.age ? val.age :''}{val.ageType ? val.ageType :''}</span> / <span title="Gender"> {val.gender} </span>  </div>  <span className='uhidnao' title="UHID">- {val.uhId}</span></div>
                           
                          </td>

                          {/* <td>{val.uhId}</td> */}
                          <td>{val.ipNo}</td>
                          <td>{val.mobileNo}</td>
                          <td>{val.admittedDate}</td>
                          <td>{val.doctorName}</td>
                          <td>
                            <span className="diagnisisText" title={val.diagnosis ? val.diagnosis : "-"}>{val.diagnosis ? val.diagnosis : "-"}</span>
                            </td>
                          <td>
                            {val.wardName}/{val.bedName}
                          </td>
                          <td className="pointer text-center">
                            <Link to="/foodIntake/" onClick={() => { handleActiveUHID(val);}}>                              
                              <img src={viewicn} className='bgicn' alt="View" title="View" />
                            </Link>  
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div className="pagginationSection" style={{marginTop: '11px'}}>
              <div className="paginationItemContainer">
                <div className="d-flex gap-2 align-items-center">
                  <span className="spanText" style={{minWidth: '140px'}}>                   
                    The Page You're On
                  </span>
                  <select
                    name=""
                    id=""
                    className="form-select form-select-sm pagginationDrp"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <span className="spanText">Previous</span>
                  <i className="bi bi-arrow-left"></i>
                  <i className="bi bi-arrow-right"></i>
                  <span className="spanText">Next</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
