import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import lifeSupportICU from "../../../../../../assets/images/icons/lifeSupportICU.svg";
import GetLifeSupportDataForICU from "../ICUChartAPi/GetLifeSupportDataForICU";
import IconIcuAdd from "../../../../../../assets/images/icons/IconIcuAdd.svg";
import GetInfusionAndFluidList from "../../../../../API/IPD/ICUChart/GET/Infusion/GetInfusionAndFluidList";
import StartInfusionorFluid from "../../../../../API/IPD/ICUChart/GET/POST/Infusion/StartInfusionorFluid";
import SuccessToster from "../../../../../../Component/SuccessToster";
import AlertToster from "../../../../../../Component/AlertToster";
import StopInfusionorFluid from "../../../../../API/IPD/ICUChart/GET/POST/Infusion/StopInfusionorFluid";
import NoDataFoundIcon from '../../../../../../assets/images/icons/No data-rafiki.svg';


export default function InfusionICUChart() {
  const { t } = useTranslation();
  document.body.dir = i18n.dir();
  const [getClientId, setClientId] = useState(
    JSON.parse(window.sessionStorage.getItem("LoginData")).clientId
  );
  const [getUHID, setUHID] = useState(
    JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid
  );
  const [getUserID, setUserID] = useState(
    JSON.parse(window.sessionStorage.getItem("LoginData")).userId
  );
  const [lifeSupportData, setLifeSupportData] = useState({
    lifeSupportMode: [],
    machine: [],
  });
  const [lifesupport, setLifeSupport] = useState([]);
  const [lifesupportDate, setLifeSupportDate] = useState([]);
  const [uniqueDates, setUniqueDates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showUnderProcess, setShowUnderProcess] = useState(0)
  const [showToster, setShowToster] = useState(0)
  const [tosterMessage, setTosterMessage] = useState("")
  const [tosterValue, setTosterValue] = useState(0)
  const [isStart, setIsStart] = useState(0);
  const [infusionAndFluidList, setInfusionAndFluidList] = useState([]);
   const [isShowToaster, setisShowToaster] = useState(0);
  const [showSuccessMsg, setShowSuccessMsg] = useState('');
  const [showAlertToster, setShowAlertToster] = useState(0);
  const [showErrMessage, setShowErrMessage] = useState('');
  const [isDisabled,setisDisabled]=useState(false);
  const formatDate = (date) => {
    const formattedDate = new Date(date);
    const day = formattedDate.getDate().toString().padStart(2, "0");
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
    const year = formattedDate.getFullYear().toString().slice(-2);
    return `${day}-${month}-${year}`;
  };
 
  // Get Life Support Data
  const getLifeSupportData = async () => {
    try {
      const response = await GetLifeSupportDataForICU(getUHID, getClientId);
      if (response.status === 1) {
        let lifesupportData = [];
        let lifesupporDate = [];
        const { lifeSupportMode, machine } = response.responseValue;
        setLifeSupportData(machine);
        for (var i = 0; i < machine.length; i++) {
          lifesupportData.push(machine[i]);
          lifesupporDate.push(machine[i].datee);
        }
        setLifeSupport([...lifesupportData]);
        setLifeSupportDate([...lifesupporDate]);
        lifesupportData = [];
        lifesupporDate = [];
      }
    } catch (error) {
      console.error("Error fetching life support data:", error);
    }
  };
  const getInfusionAndFluidList = async ()=>{
    const { responseValue, status, message } = await GetInfusionAndFluidList(getUHID);
    if (status) {
      setInfusionAndFluidList(responseValue);
    }
    else {
            setShowAlertToster(1);
            setShowErrMessage(responseValue);           
            setTimeout(()=>{
              setShowAlertToster(0);
              setShowErrMessage('');
            },1700)
    }
  };
  const handleShowModal = () => {
    setShowModal(true);
    getInfusionAndFluidList();
    
  };
  const handleCloseModal = () => {
    infusionAndFluidList.forEach((val,ind)=>{
       document.getElementById(`errMsgFlowRate${ind}`).style.display="none";
       document.getElementById(`errMsgQuantity${ind}`).style.display="none";
    });
     
    setShowModal(false);
  };
  const handleChange = (e,ind)=>{
    const {value,name}=e.target;
    const errMsgId=name === "flowRate" ? `errMsgFlowRate${ind}` : `errMsgQuantity${ind}`;
    document.getElementById(errMsgId).style.display="none";
    const updatedValue = infusionAndFluidList.map((val,index)=>{
          if(index === ind){
            return {...val,[name] : value}
          }
          return val;
    });
    setInfusionAndFluidList(updatedValue);

  };
  const handleStart =async (param,ind)=>{
    if(!parseFloat(param.flowRate)){
       document.getElementById(`errMsgFlowRate${ind}`).style.display="block";
    }  
    else if(!parseFloat(param.quantity)){
      document.getElementById(`errMsgQuantity${ind}`).style.display="block";
    }
    else{
        setisDisabled(true);
        const obj={
          uhid:getUHID,
          drugName:param.drugName,
          drugId:param.drugId,
          quantity:param.quantity,
          flowRate: param.flowRate,
          infusionRowId:0,
          clientId:getClientId,
          userId:getUserID

        }
      const {responseValue,status,message}= await StartInfusionorFluid(obj);
      if(status === 1){
          setisShowToaster(1);
          setShowSuccessMsg(message);
          getInfusionAndFluidList();
          setisDisabled(false);
          setTimeout(()=>{
            setisShowToaster(0);
            setShowToster('');
          },1500)
      }
      else if(status === 400){
        setShowAlertToster(1);
            setShowErrMessage('Something went wrong..!!');
            setisDisabled(false);
            setTimeout(()=>{
              setShowAlertToster(0);
              setShowErrMessage('');
        },1500)
      }
      else{
            setShowAlertToster(1);
            setShowErrMessage(responseValue ?? '');
            setisDisabled(false);
            setTimeout(()=>{
              setShowAlertToster(0);
              setShowErrMessage('');
            },1500)
      }
    }
     
  };
  const handleStop = async(param,ind)=>{
    setisDisabled(true);
    const obj={
      infusionRowId:param.infusionRowId,
      userId:getUserID
    }
    const {message,status} = await StopInfusionorFluid(obj);
    if(status === 1){
          setisShowToaster(1);
          setShowSuccessMsg(message);
          getInfusionAndFluidList();
          setisDisabled(false);
          setTimeout(()=>{
            setisShowToaster(0);
            setShowToster('');
          },1500)
      }
      else if(status === 400){
        setShowAlertToster(1);
            setShowErrMessage('Something went wrong..!!');
            setisDisabled(false);
            setTimeout(()=>{
              setShowAlertToster(0);
              setShowErrMessage('');
        },1500)
      }
      else{
            setShowAlertToster(1);
            setShowErrMessage(message);
            setisDisabled(false);
            setTimeout(()=>{
              setShowAlertToster(0);
              setShowErrMessage('');
            },1500)
      }
  }
  useEffect(() => {
    getLifeSupportData();
  }, []);

  return (
    <>
      <div className="icuChartHeading mb-2">
        <div className="icuChartHeading-inn">
          <div className="ICUHead-lft">
            <span>
              <img src={lifeSupportICU} alt="" />
            </span>
            <span>
              <h3>Infusion</h3>
            </span>
          </div>
        </div>
         <div className='icuChartHeading-inn pointSpan' >
              <span><img src={IconIcuAdd} alt='' onClick={handleShowModal}/></span>
           </div>
      </div>

      <div
        className="med-table-section Icu-componenttbl"
        style={{ height: "35vh", paddingBottom: "10px" }}
      >
        <table className="med-table table-responsive">
          {/* <thead>
              <tr>
                <th style={{ minWidth: "150px" }} >Machine</th>
                {lifeSupportData.lifeSupportMode.map((mode) => (
                  <th key={mode.dateTime} style={{ minWidth: "100px" }} className='text-center'>{formatDate(mode.dateTime)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {lifeSupportData.machine.map((machine) => (
                <tr key={machine.lifeSupportModeID}>
                  <td>{machine.lifeSupportMode}</td>
                  {lifeSupportData.lifeSupportMode.map((mode) => (
                    <>
                      <td key={mode.dateTime} className='text-center' style={{ whiteSpace: 'nowrap', fontStyle: 'italic' }}>
                        <div className='cathercolor'>
                          {formatDate(mode.dateTime) === formatDate(machine.fromDateTime) && (
                            `Insert-${new Date(machine.fromDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', })}`
                          )}
                        </div>
                        <div className='cathercolor1'>
                          {formatDate(mode.dateTime) === formatDate(machine.toDateTime) && (
                            `Remove-${new Date(machine.toDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', })}`
                          )}
                        </div>
                      </td>
  
                      <td>
                        {formatDate(mode.dateTime) === formatDate(machine.fromDateTime) ? (
                          `Insert-${machine.fromDateTime.split('T')[1].slice(0, 5)}`
                        ) : (
                          formatDate(mode.dateTime) === formatDate(machine.toDateTime) ? (
                            `Remove-${machine.toDateTime.split('T')[1].slice(0, 5)}`
                          ) : (
                            ''
                          )
                        )}
                      </td>
                    </>
                  ))}
                </tr>
              ))} */}

          {/* {uniqueDates && uniqueDates.map((dateList, index) => {
              return (
                <> */}
          <thead>
            <tr>
              <th colSpan={25}>{/* <div className='listDate'></div> */}</th>
            </tr>
            <tr>
              <th>Date</th>
              <th>Drug Name</th>

              <th>07 AM</th>
              <th>08 AM</th>
              <th>09 AM</th>
              <th>10 AM</th>
              <th>11 AM</th>
              <th>12 PM</th>
              <th>01 PM</th>
              <th>02 PM</th>
              <th>03 PM</th>
              <th>04 PM</th>
              <th>05 PM</th>
              <th>06 PM</th>
              <th>07 PM</th>
              <th>08 PM</th>
              <th>09 PM</th>
              <th>10 PM</th>
              <th>11 PM</th>
              <th>12 AM</th>
              <th>01 AM</th>
              <th>02 AM</th>
              <th>03 AM</th>
              <th>04 AM</th>
              <th>05 AM</th>
              <th>06 AM</th>
            </tr>
          </thead>

          <tbody>
            {lifesupport &&
              lifesupport.map((vitalsList, index) => {
                return (
                  <tr>
                    <td>{vitalsList.datee}</td>
                    <td>{vitalsList.drugName}</td>
                    <td>{vitalsList.aM07}</td>
                    <td>{vitalsList.aM08}</td>
                    <td>{vitalsList.aM09}</td>
                    <td>{vitalsList.aM10}</td>
                    <td>{vitalsList.aM11}</td>
                    <td>{vitalsList.pM12}</td>
                    <td>{vitalsList.pM01}</td>
                    <td>{vitalsList.pM02}</td>
                    <td>{vitalsList.pM03}</td>
                    <td>{vitalsList.pM04}</td>
                    <td>{vitalsList.pM05}</td>
                    <td>{vitalsList.pM06}</td>
                    <td>{vitalsList.pM07}</td>
                    <td>{vitalsList.pM08}</td>
                    <td>{vitalsList.pM09}</td>
                    <td>{vitalsList.pM10}</td>
                    <td>{vitalsList.pM11}</td>
                    <td>{vitalsList.aM12}</td>
                    <td>{vitalsList.aM01}</td>
                    <td>{vitalsList.aM02}</td>
                    <td>{vitalsList.aM03}</td>
                    <td>{vitalsList.aM04}</td>
                    <td>{vitalsList.aM05}</td>
                    <td>{vitalsList.aM06}</td>
                  </tr>
                );
              })}
          </tbody>
          {/* </>
              )
            })} */}

          {/* </tbody> */}
        </table>
      </div>
      {/* Modal */}
      <div className={`modal fade bk-dropfilter ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header modalheading">
              <h5 className="modal-title">Infusion / Fluid Details</h5>
              <i className={`fa fa-times ${isDisabled ? 'custom-disabled':''}`} onClick={handleCloseModal}></i>
            </div>
            <div className="modal-body">

              <div className="row">
                <div className="col-md-12">
                  <table className="med-table border_ striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Infusion/Fluid</th>
                        <th>Flow Rate</th>
                        <th>Quantity(ml)</th>
                        <th>Stopped Date Time</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {infusionAndFluidList.length > 0 ?
                      infusionAndFluidList && infusionAndFluidList.map((val, ind) => {
                        return (
                          <tr>
                            <td>{(ind + 1)}</td>
                            <td><input autocomplete="off" type="text" className="opdmedicationinput w-100" name="frequencyData" id={`drugName${val.ind}`} value={`${val.drugName} (${val.frequency})`} /></td>
                            <td><input autocomplete="off" type="text" className="opdmedicationinput w-100" name="flowRate" id={`flowRate${val.ind}`} placeholder="Flow Rate" value={val.flowRate} onChange={(e)=>{handleChange(e,ind)}} /><br /><span className="text-danger ms-1" id={`errMsgFlowRate${ind}`} style={{display:'none'}}>fill flow rate..!!</span></td>
                            <td><input autocomplete="off" type="text" className="opdmedicationinput w-100" name="quantity" id={`quantity${val.ind}`} placeholder="Quantity" value={val.quantity} onChange={(e)=>{handleChange(e,ind)}} /><br /><span className="text-danger ms-1 " id={`errMsgQuantity${ind}`} style={{display:'none'}}>fill quantity..!!</span></td>
                            <td><input autocomplete="off" type="text" className="opdmedicationinput w-100" name="quantity" id={`quantity${val.ind}`}  value={val.infusionStopTime} disabled /></td>
                            <td> {val.infusionStarted === 0 ? <span className="opdmedicationinput w-100">Not Started</span>
                                  : val.infusionStarted === 1 && val.isInfusionStop === 0 ? <span className="opdmedicationinput w-100 text-success">Ongoing</span>
                                  : val.infusionStarted === 1 && val.isInfusionStop === 1 ? <span className="opdmedicationinput w-100 text-danger">Stopped</span>
                                  :''

                                }
                            </td>
                            <td>
                              <div className="d-flex flex-row gap-2">
                                {
                                  val.isLast === 1 ? 
                                    val.actionButton === "Stop" ?
                                    <div style={{ cursor: 'pointer' }}>
                                      <i className={`bi bi-slash-circle-fill fs-5 ${isDisabled ? 'custom-disabled':''}`} title="Stop" onClick={()=>{handleStop(val,ind)}}></i>
                                    </div>
                                    : 
                                    <div style={{ cursor: 'pointer' }}>
                                      <i className={`bi bi-send-fill fs-5 ${isDisabled ? 'custom-disabled':''}`} title="Start" onClick={()=>{handleStart(val,ind)}}></i>
                                    </div>
                                  : ''
                                }
                                
                                
                              </div>
                            </td>
                          </tr>
                        )
                      })

                    :<tr>
                    <td colSpan={7} className="text-center"><div><img src={NoDataFoundIcon} style={{width: '25%'}}/></div></td>                    
                    </tr>
                    }
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isShowToaster === 1 ? <SuccessToster handle={setShowToster} message={showSuccessMsg} /> : ""  }
        { showAlertToster === 1 ? <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""} 
         
      </div>
    </>
  );
}
