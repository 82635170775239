import React, { useState, useEffect } from 'react'
import BoxContainer from '../../../../Component/BoxContainer';
import Heading from '../../../../../src/Component/Heading';
import clearIcon from '../../../../assets/images/icons/clear.svg';
import DropdownWithSearch from '../../../../Component/DropdownWithSearch';
import GetGodownMaster from '../../../Purchase/API/GetGodownMaster';
import GetProduct from '../../../ProductMaster/API/GetProduct';
import GetGodownStockByGodownId from '../../../CurrentStock/API/GetGodownStockByGodownId';
import GetGodownStockByProductId from '../../../CurrentStock/API/GetGodownStockByProductId';
import GetAllCurrentStockByPidBno from '../../../CurrentStock/API/GetAllCurrentStockByPidBno';
import AlertToster from '../../../../../src/Component/AlertToster'
import PostRemoveStock from '../API/PostRemoveStock';
import SuccessToster from '../../../../Component/SuccessToster';
import GetGodownCurrentStockByGdId from '../API/GetGodownCurrentStockByGdId';
import Loader from '../../../../Component/Loader';
import GetGodownCurreStockByPIdBNo from '../../../CurrentStock/API/GetGodownCurreStockByPIdBNo';
import GetKnowmedItems from '../../../Purchase/API/GetKnowmedItems';


const RemoveStock = () => {

    let [godownList, setGodownList] = useState([])
    let [itemList, setItemList] = useState([])
    let [productList, setProductList] = useState([])
    let [batchList, setBatchList] = useState([])
    let [stockList, setStockList] = useState([])
    let [jsonRemove, setJsonRemove] = useState([])
    let [godownId, setGodownId] = useState('')
    let [productId, setProductId] = useState('')
    let [batchNo, setBatchNo] = useState('')
    let [removeQty, setRemoveQty] = useState('')
    let [removeRemarks, setRemoveRemarks] = useState('')
    let [totalAmount, setTotalAmount] = useState(0)
    let [isChecked, setIsChecked] = useState(Array(productList.length).fill(false));
    let [showMessage, setShowMeassage] = useState("")
    let [showAlertToster, setShowAlertToster] = useState(0)
    let clientId = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
    let userId = JSON.parse(sessionStorage.getItem("LoginData")).userId;
    let [showSuccessToster, setShowSuccessToster] = useState(0)
    const [showToster, setShowToster] = useState(0);
    let [godownCurrentList, setGodownCurrentList] = useState([])
    let [loder, setLoder] = useState(1)
    let [clearDropdown, setClearDropdown] = useState(0)
    let [removeAmounts, setRemoveAmounts] = useState([])



    const getData = async () => {
        setLoder(1)
      
        let response = await GetGodownMaster()
        let responseItems = await GetKnowmedItems()
        if (response.status === 1) {
            setLoder(0)
            setGodownList(response.responseValue)
        }
        if (responseItems.status === 1) {
            setLoder(0)
            setItemList(responseItems.responseValue)
        }
    

    }

    const getGodownProduct = async (godownId) => {
        setLoder(1)
      
        let response = await GetGodownStockByGodownId(godownId)
        let responseStock = await GetGodownCurrentStockByGdId(godownId)
        if (response.status === 1) {
            setLoder(0)
            setProductList(response.responseValue)
        }
        if (responseStock.status === 1) {
            setLoder(0)
            setGodownCurrentList(responseStock.responseValue)
        }
       
    }

    const getBatchNo = async (productId, godownId) => {
        setLoder(1)
       
        let response = await GetGodownStockByProductId(productId, godownId)
        if (response.status === 1) {
            setLoder(0)
            setBatchList(response.responseValue)
        }
       
    }

    const getStockByBatchNo = async (productId, batchNo) => {

        setLoder(1)
      
        let response = await GetGodownCurreStockByPIdBNo(batchNo, productId)
        if (response.status === 1) {
            setLoder(0)
            setStockList(response.responseValue)
        }
       
    }



    const funcCalNetAmountRemove = (event, data, i) => {
        const { name, value } = event.target;
        // setRemoveAmounts(removeAmounts)
        const removeQty = document.getElementById(`removeQty${i}`).value;
        console.log(removeQty)

        if (name === (`removeQty${i}`) && !isNaN(removeQty) && removeQty <= parseFloat(data.stockStatus)) {

            const removeQtyValue = parseFloat(removeQty);
            console.log("removeQtyValue", removeQtyValue);

            const unitPrice = parseFloat(data.baseUnitPrice);
            console.log("unitPrice", unitPrice);

            if (!isNaN(removeQtyValue) && !isNaN(unitPrice)) {
                const netAmount = parseFloat(removeQtyValue * unitPrice);
                console.log("netAmount", netAmount);
                const updatedRemoveAmounts = [...removeAmounts];
                updatedRemoveAmounts[i] = netAmount;
                setRemoveAmounts(updatedRemoveAmounts);


            } else {


                const updatedRemoveAmounts = [...removeAmounts];
                updatedRemoveAmounts[i] = '';
                setRemoveAmounts(updatedRemoveAmounts);

            }
        } else {

            const updatedRemoveAmounts = [...removeAmounts];
            updatedRemoveAmounts[i] = '';
            setRemoveAmounts(updatedRemoveAmounts);

            setShowAlertToster(1)
            setShowMeassage('Remove Quantity can not be greater than available quantity')
        }
    }


    const handleChange = (event) => {

        const { name, value } = event.target;


        if (event.target.name === "godownId") {
            setGodownId(value);
            getGodownProduct(value);
        }

        if (event.target.name === "productId") {
            setProductId(value);
            getBatchNo(value, godownId);

        }

        if (event.target.name === "batchNo") {

            setBatchNo(value);
            getStockByBatchNo(value, productId);
        }

        if (event.target.name === "removeRemarks") {
            setRemoveRemarks(value);
        }

    }


    const handleCheckBox = (data, i) => {

        let currentArray = [...jsonRemove];
        const rowIndex = currentArray.findIndex(item => item.id === data.id);
        const updatedIsChecked = [...isChecked];
        const isCheckedd = document.getElementById(data.id).checked;
        updatedIsChecked[i] = isCheckedd;
        setIsChecked(updatedIsChecked);

        if (isCheckedd) {
            const remQty = document.getElementById(`removeQty${i}`).value;
            currentArray.push({
                id: data.id,
                productId: data.productId,
                unitId: data.unitID,
                batchNo: data.batchNo,
                removeQty: remQty,
                unitPrice: parseFloat(data.baseUnitPrice),
                hsnCodeId: data.hsnCodeId,
                batchMfg: data.batchMfg,
                batchExpiry: data.batchExpiry,
                userId: userId,
                isknowmed: data.isknowmed
            });
            // const total = removeAmounts.reduce((sum, amount) => sum + (amount || 0), 0);
            // setTotalAmount(total);
            let amount = document.getElementById("removeAmount" + i).value;
            totalAmount += parseInt(amount);
            setTotalAmount(totalAmount);
        } else {

            if (rowIndex !== -1) {

                currentArray.splice(rowIndex, 1);
                let amount = document.getElementById("removeAmount" + i).value;
                totalAmount -= parseInt(amount);
                setTotalAmount(totalAmount);
                // removeAmounts.splice(rowIndex, 1);
                // document.getElementById(`removeQty${i}`).value='';
                // document.getElementById(`removeAmount${i}`).value='';
                // const totalAmt = removeAmounts.reduce((sum, amount) => sum + (amount || 0), 0);
                // setTotalAmount(totalAmt);

            }
        }

        console.log("tempArray", currentArray);

        setJsonRemove(currentArray);


    };


    let handleSave = async () => {

        const removeData = {

            godownId: godownId,
            jsonRemove: JSON.stringify(jsonRemove),
            removeRemark: removeRemarks,
            removeNetAmount: totalAmount,
            userId: userId,
            clientId: clientId

        };
        console.log("removeData", removeData)

        let response = await PostRemoveStock(removeData);
        if (response.status === 1) {
            setShowMeassage("Data Saved Successfully!!")
            setShowSuccessToster(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
            getData()
            handleClear(1)


        }
        else {
            setShowMeassage(response.responseValue)
            setShowAlertToster(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }

    }


    const handleClear = (value) => {
        setClearDropdown(value);
        setGodownId('');
        setProductId('');
        setBatchNo('');
        setJsonRemove([]);
        setRemoveRemarks('')
        
        setTotalAmount('');
        setIsChecked(Array(productList.length).fill(false));
        document.getElementById('removeRemarks').value = '';
    }

    useEffect(() => {

        getData();


    }, [])

    return (
        <>

            <section className='main-content mt-5 pt-3'>
                <div className='container-fluid'>

                    <div className='row'>
                        <div className="col-12">
                            <div className='whitebg' style={{ margin: "0 0 10px 0" }}>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 analuze">
                                        <div className="fieldsett-in">
                                            <div className="fieldsett">
                                                <span className='fieldse'>Remove Stock</span>
                                                <BoxContainer>
                                                    {godownList &&
                                                        <div className="drpWithSearch me-2 mb-2" style={{ maxWidth: '200px' }}>
                                                            <DropdownWithSearch defaulNname="Select Godown" id={godownId} name='godownId'
                                                                getvalue={handleChange} list={godownList} valueName="id" displayName="godown"
                                                                editdata={""} clear={clearDropdown} clearFun={handleClear} />
                                                        </div>
                                                    }


                                                    {godownCurrentList &&
                                                        <div className="drpWithSearch me-2 mb-2" style={{ maxWidth: '200px' }}>
                                                            <DropdownWithSearch defaulNname="Select Product" id={productId} name='productId'
                                                                getvalue={handleChange} list={godownCurrentList} valueName="productId" displayName="brandName"
                                                                editdata={""} clear={clearDropdown} clearFun={handleClear} />
                                                        </div>
                                                    }

                                                    {batchList &&
                                                        <div className="drpWithSearch me-2 mb-2" style={{ maxWidth: '200px' }}>
                                                            <DropdownWithSearch defaulNname="Select BatchNo" id={batchNo} name='batchNo'
                                                                getvalue={handleChange} list={batchList} valueName="batchNo" displayName="batchNo"
                                                                editdata={""} clear={clearDropdown} clearFun={handleClear} />
                                                        </div>
                                                    }

                                                    <div>
                                                        <button type="button" className="btn btn-clear btn-sm" onClick={() => { handleClear(1) }}><img src={clearIcon} className='icnn' />Clear</button>
                                                    </div>
                                                </BoxContainer>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='handlser'>
                                <Heading text={"Stock List"} />

                            </div>
                        </div>
                        <div className="col-12 mt-2">
                            <div className="med-table-section" style={{ "height": "490px", position: 'relative' }}>

                                {productId && batchNo ? (

                                    <table className='med-table border striped'>
                                        <thead>
                                            <tr>
                                                <th className="" >#</th>
                                                <th>Godown Name</th>
                                                <th>Item Name</th>
                                                <th>Batch No.</th>
                                                <th>Unit Name</th>
                                                <th>Unit Price</th>
                                                <th>Quantity</th>
                                                <th>Remove Quantity</th>
                                                <th>Remove Net Amount</th>

                                                <th><li className="d-flex flex-row ps-1 gap-2">
                                                    <input type="checkbox" id={-1} />
                                                    <span>Select All</span>
                                                </li></th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {stockList && stockList.map((list, index) => {

                                                return (

                                                    <>
                                                        <tr key={index}>
                                                            <td className='' style={{ paddingLeft: '7px', fontSize: '13px' }}>{index + 1}</td>


                                                            <td><span style={{ fontSize: '13px', color: '#929292' }}>{list.godown}</span></td>
                                                            <td><span style={{ fontSize: '13px', color: '#929292' }}>{list.brandName}</span></td>
                                                            <td><span style={{ fontSize: '13px', color: '#929292' }}>{list.batchNo}</span></td>
                                                            <td><span style={{ fontSize: '13px', color: '#929292' }}>{list.baseUnit}</span></td>
                                                            <td><span style={{ fontSize: '13px', color: '#929292' }}>{list.baseUnitPrice}</span></td>
                                                            <td><span style={{ color: '#858585', fontSize: '13px' }}>{list.stockStatus}</span></td>

                                                            <td>
                                                                <input
                                                                    type='number'
                                                                    name={`removeQty${index}`}
                                                                    readOnly={isChecked[index]}
                                                                    id={`removeQty${index}`}

                                                                    placeholder="Enter Remove Quantity"
                                                                    onChange={(event) => funcCalNetAmountRemove(event, list, index)}
                                                                />
                                                            </td>

                                                            <td><input type='number' id={`removeAmount${index}`} name='removeAmount'
                                                                placeholder="Enter Return Quantity" value={removeAmounts[index]} /></td>

                                                            <td style={{ textAlign: 'center' }}><input type="checkbox" id={list.id} onChange={(event) => { handleCheckBox(list, index) }} /></td>

                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                ) : (
                                    godownId && productId ? (

                                        <table className='med-table border striped'>
                                            <thead>
                                                <tr>
                                                    <th className="" >#</th>
                                                    <th>Godown Name</th>
                                                    <th>Item Name</th>
                                                    <th>Batch No.</th>
                                                    <th>Unit Name</th>
                                                    <th>Unit Price</th>
                                                    <th>Quantity</th>
                                                    <th>Remove Quantity</th>
                                                    <th>Remove Net Amount</th>

                                                    <th><li className="d-flex flex-row ps-1 gap-2">
                                                        <input type="checkbox" id={-1} />
                                                        <span>Select All</span>
                                                    </li></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {batchList && batchList.map((list, index) => {

                                                    return (

                                                        <>
                                                            <tr key={index}>
                                                                <td className='' style={{ paddingLeft: '7px', fontSize: '13px' }}>{index + 1}</td>


                                                                <td><span style={{ fontSize: '13px', color: '#929292' }}>{list.godown}</span></td>
                                                                <td><span style={{ fontSize: '13px', color: '#929292' }}>{list.brandName}</span></td>
                                                                <td><span style={{ fontSize: '13px', color: '#929292' }}>{list.batchNo}</span></td>
                                                                <td><span style={{ fontSize: '13px', color: '#929292' }}>{list.baseUnit}</span></td>
                                                                <td><span style={{ fontSize: '13px', color: '#929292' }}>{list.baseUnitPrice}</span></td>
                                                                <td><span style={{ color: '#858585', fontSize: '13px' }}>{list.stockStatus}</span></td>

                                                                <td>
                                                                    <input
                                                                        type='number'
                                                                        name={`removeQty${index}`}
                                                                        readOnly={isChecked[index]}
                                                                        id={`removeQty${index}`}
                                                                        placeholder="Enter Return Quantity"
                                                                        onChange={(event) => funcCalNetAmountRemove(event, list, index)} />
                                                                </td>

                                                                <td><input type='number' id={`removeAmount${index}`} name='removeAmount'
                                                                    placeholder="Enter Return Quantity" value={removeAmounts[index]} /></td>

                                                                <td style={{ textAlign: 'center' }}><input type="checkbox" id={list.id} onChange={(event) => { handleCheckBox(list, index) }} /></td>

                                                            </tr>
                                                        </>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    ) : (
                                        godownId ? (

                                            <table className='med-table border striped'>
                                                <thead>
                                                    <tr>
                                                        <th className="" >#</th>


                                                        <th>Godown Name</th>
                                                        <th>Item Name</th>
                                                        <th>Batch No.</th>
                                                        <th>Unit Name</th>
                                                        <th>Unit Price</th>

                                                        <th>Quantity</th>
                                                        <th>Remove Quantity</th>
                                                        <th>Remove Net Amount</th>

                                                        <th><li className="d-flex flex-row ps-1 gap-2">
                                                            <input type="checkbox" id={-1} />
                                                            <span>Select All</span>
                                                        </li></th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {productList && productList.map((list, index) => {

                                                        return (

                                                            <>
                                                                <tr key={index}>
                                                                    <td className='' style={{ paddingLeft: '7px', fontSize: '13px' }}>{index + 1}</td>


                                                                    <td><span style={{ fontSize: '13px', color: '#929292' }}>{list.godown}</span></td>
                                                                    <td><span style={{ fontSize: '13px', color: '#929292' }}>{list.brandName}</span></td>
                                                                    <td><span style={{ fontSize: '13px', color: '#929292' }}>{list.batchNo}</span></td>
                                                                    <td><span style={{ fontSize: '13px', color: '#929292' }}>{list.baseUnit}</span></td>

                                                                    <td><span style={{ fontSize: '13px', color: '#929292' }}>{list.baseUnitPrice}</span></td>

                                                                    <td><span style={{ color: '#858585', fontSize: '13px' }}>{list.stockStatus}</span></td>

                                                                    <td>
                                                                        <input
                                                                            type='number'
                                                                            name={`removeQty${index}`}
                                                                            readOnly={isChecked[index]}
                                                                            id={`removeQty${index}`}
                                                                            placeholder="Enter Return Quantity"
                                                                            onChange={(event) => funcCalNetAmountRemove(event, list, index)}

                                                                        />
                                                                    </td>

                                                                    <td><input type='number' id={`removeAmount${index}`} name='removeAmount'
                                                                        placeholder="Enter Return Quantity" value={removeAmounts[index]} /></td>



                                                                    <td style={{ textAlign: 'center' }}><input type="checkbox" id={list.id} onChange={(event) => { handleCheckBox(list, index) }} /></td>

                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        ) : null
                                    )
                                )}
                            </div>
                        </div>
                    </div>


                    <div className='row'>
                        <div className='col-md-6 mt-3'>
                            <div className='med-box'>
                                <div className='inner-content'>
                                    <div className="mb-2">

                                        <label for="returnNetAmount" className="form-label">Remove Net Amount <span className="starMandatory">*</span></label>
                                        <input type="text" className="form-control form-control-sm" id="returnNetAmount" name="returnNetAmount" value={totalAmount}
                                            placeholder='enter return remarks' />
                                    </div>
                                </div>

                                <div className='inner-content'>
                                    <div className="mb-2">

                                        <label for="returnRemarks" className="form-label">Remarks<span className="starMandatory">*</span></label>
                                        <input type="text" className="form-control form-control-sm" id="removeRemarks" name="removeRemarks"
                                            placeholder='enter return remarks' onChange={handleChange} />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='col-md-6 mt-3'>
                            <div className='med-table-section text-right'>
                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1_ me-1" onClick={handleSave}>Submit</button>
                                <button type="button" className="btn btn-clear btn-sm mb-1_ me-1" onClick={() => { handleClear(1) }}>Reset</button>
                            </div>

                        </div>


                    </div>
                </div>
                {
                    loder === 1 ? <Loader val={loder} /> : ""
                }
            </section>

            {
                showAlertToster === 1 ? <AlertToster message={showMessage} handle={setShowAlertToster} /> : ""
            }
            {
                showSuccessToster === 1 ? <SuccessToster message={showMessage} handle={setShowSuccessToster} /> : ""
            }


        </>

    )
}

export default RemoveStock
