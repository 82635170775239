import React, { useEffect, useState } from 'react'
import { onStart } from '../../../Code/DragDropData'



export default function PatientDiagonsisList(props) {

    return (
        <>

            {
                props.patientData.DiagonsisList !== null ?
                    <div key={props.key} className='d-flex flex-inline gap-1 wrap pb-1 pt-1' draggable="true" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} onDragStart={onStart}>
                        {props.patientData.DiagonsisList !== null && props.patientData.DiagonsisList !== undefined ?
                            <div className='d-flex flex-wrap mbc-05' style={{ overflow: "auto", maxHeight: "30px"}}>
                                {
                                    props.patientData.DiagonsisList.map((val, ind) => {
                                        return (<div className='list-commas fw-bold' title='Diagnosis'> {val.ProblemName} </div>)
                                    })
                                }
                            </div>
                            : ""
                        }
                    </div> : ""
            }
        </>

    )
}
function PatientHomeCareSymtomsList(props) {
    return (
        <>
        
            
            {
                props.patientData.PatientHomeCareSymtomsList !== null ?
                    <div key={props.key} className='d-flex flex-inline gap-1 wrap pb-1 pt-1' draggable="true" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} onDragStart={onStart}>
                        {props.patientData.PatientHomeCareSymtomsList !== null && props.patientData.PatientHomeCareSymtomsList !== undefined ?
                            <div className='d-flex flex-wrap mbc-05' style={{maxHeight:'30px', overflow:'auto'}}>
                                {
                                    props.patientData.PatientHomeCareSymtomsList.map((val, ind) => {
                                        return (<div className='list-commas ' style={{}} title='Diagnosis'> {val.Details} </div>)
                                    })
                                }
                            </div>
                            : ""
                        }
                    </div> : ""
            }
        </>

    )
}


export { PatientDiagonsisList, PatientHomeCareSymtomsList }

