// let GetSupplementList = async ()=>{
async function GetSupplementList(query) {
    let url = window.AppbaseUrl + "/api/KnowMedApis/getSupplimentList?alphabet=" + query;
    let head = { "content-type": "application/json", accept: '*/*' };

    let response = fetch(url, {
        headers: head,
        method: 'GET'
    })
        .then((res) => res.json())
        .then();
    return response;
}
export default GetSupplementList;