import React, { useEffect, useState } from 'react'
import BoxContainer from '../../../../../Component/BoxContainer'
import Heading from '../../../../../Component/Heading'
import { t } from 'i18next'
import TosterUnderProcess from '../../../../../Component/TosterUnderProcess';
import Toster from '../../../../../Component/Toster';
import { date } from 'react-i18next/icu.macro';
import InsertRiskAssessment from '../../../../API/RiskAssessment/InsertRiskAssessment';
import UpdateRiskAssessment from '../../../../API/RiskAssessment/UpdateRiskAssessment';
import IconEdit from '../../../../../assets/images/icons/IconEdit.svg'
import IconDelete from '../../../../../assets/images/icons/IconDelete.svg'
import saveButtonIcon from '../../../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../../../assets/images/icons/clear.svg';
import GetRiskAssessmentList from '../../../../API/RiskAssessment/GetRiskAssessment';
import TableContainer from '../../../../../Component/TableContainer';
import DeleteRiskAssessment from '../../../../API/RiskAssessment/DeleteRiskAssessment';
export default function RiskAssessment() {

  let [showUnderProcess, setShowUnderProcess] = useState(0);
    let [showToster, setShowToster] = useState(0);
    let [tosterMessage, setTosterMessage] = useState("");
    let [tosterValue, setTosterValue] = useState(0);
    let [updateBool, setUpdateBool] = useState(0);
    let [getGeneralStatus, setGeneralStatus] = useState(1);
    let [getGeneralIdentifier, setGeneralIdentifier] = useState('');
    let [getSubject, setSubject] = useState('');
    let [getEncounter, setEncounter] = useState('');
    let [getEncounterDate, setEncounterDate] = useState('');
    let [getCondition, setCondition] = useState('');
    let [getPerformer, setPerformer] = useState('');
    let [getMethod, setMethod] = useState('');
    let [getBasis, setBasis] = useState('');
    let [getOutcome, setOutcome] = useState('');
    let [getProbability, setProbability] = useState('');
    let [getQualitativeRisk, setQualitativeRisk] = useState(1);
    let [getRelativeRisk, setRelativeRisk] = useState('');
    let [getStartDate, setStartDate] = useState('');
    let [getEndDate, setEndDate] = useState('');
    let [getRowId, setRowId] = useState(0);
    let [getRiskAssessmentList, setRiskAssessmentList] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    const clientID = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
    const userId = JSON.parse(sessionStorage.getItem("LoginData")).userId;

    // const uhid = JSON.parse(sessionStorage.getItem("IPDactivePatient")).Uhid;


    let uhid = window.sessionStorage.getItem("activePatient")
        ? JSON.parse(window.sessionStorage.getItem("activePatient")).Uhid
        : window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : [];


    const handleQualitativeRisk = () => {
      const getvalue = document.getElementById("dropdownQualitativeRisk").value;
      setQualitativeRisk(getvalue);
  };
  const handleGeneralStatus = () => {
      const getvalue = document.getElementById("dropdownGeneralStatus").value;
      setGeneralStatus(getvalue);
  };


    let handleChange = (e) => {
      let name = e.target.name;
      let value = e.target.value;
      if (name === "txtGeneralIdentifier") {
          document.getElementById("errtxtGeneralIdentifier").style.display = "none";
          setGeneralIdentifier(value);
      } else if (name === "txtSubject") {
          document.getElementById("errtxtSubject").style.display = "none";
          setSubject(value);
      } else if (name === "txtEncounter") {
          document.getElementById("errtxtEncounter").style.display = "none";
          setEncounter(value);
      } else if (name === "txtEncounterDate") {
          document.getElementById("errtxtEncounterDate").style.display = "none";
          setEncounterDate(value);
      } else if (name === "txtCondition") {
          document.getElementById("errtxtCondition").style.display = "none";
          setCondition(value);
      } else if (name === "txtPerformer") {
          document.getElementById("errtxtPerformer").style.display = "none";
          setPerformer(value);
      } else if (name === "txtMethod") {
          document.getElementById("errtxtMethod").style.display = "none";
          setMethod(value);
      } else if (name === "txtBasis") {
          document.getElementById("errtxtBasis").style.display = "none";
          setBasis(value);
      } else if (name === "txtOutcome") {
          document.getElementById("errtxtOutcome").style.display = "none";
          setOutcome(value);
      } else if (name === "txtProbability") {
          document.getElementById("errtxtProbability").style.display = "none";
          setProbability(value);
      } else if (name === "txtRelativeRisk") {
          document.getElementById("errtxtRelativeRisk").style.display = "none";
          setRelativeRisk(value);
      } else if (name === "txtStartDate") {
          document.getElementById("errtxtStartDate").style.display = "none";
          setStartDate(value);
      } else if (name === "txtEndDate") {
          document.getElementById("errtxtEndDate").style.display = "none";
          setEndDate(value);
      }
    }

      const handleSave = async () => {

        if (getGeneralIdentifier === null || getGeneralIdentifier === "") {
            document.getElementById('errtxtGeneralIdentifier').innerHTML = "Please Enter General Identifier";
            document.getElementById('errtxtGeneralIdentifier').style.display = "block";
            return false;
        }
        if (getGeneralStatus === null || getGeneralStatus === "") {
            document.getElementById('errtxtGeneralStatus').innerHTML = "Please Select General Status";
            document.getElementById('errtxtGeneralStatus').style.display = "block";
            return false;
        }
        if (getSubject === null || getSubject === "") {
            document.getElementById('errtxtSubject').innerHTML = "Please Enter Subject";
            document.getElementById('errtxtSubject').style.display = "block";
            return false;
        }
        if (getEncounter === null || getEncounter === "") {
            document.getElementById('errtxtEncounter').innerHTML = "Please Enter Encounter";
            document.getElementById('errtxtEncounter').style.display = "block";
            return false;
        }
        if (getOutcome === null || getOutcome === "") {
            document.getElementById('errtxtOutcome').innerHTML = "Please Enter Outcome";
            document.getElementById('errtxtOutcome').style.display = "block";
            return false;
        }
        if (getEncounterDate === null || getEncounterDate === "") {
            document.getElementById('errtxtEncounterDate').innerHTML = "Please Select Encounter Date";
            document.getElementById('errtxtEncounterDate').style.display = "block";
            return false;
        }
        if (getCondition === null || getCondition === "") {
            document.getElementById('errtxtCondition').innerHTML = "Please Enter Condition";
            document.getElementById('errtxtCondition').style.display = "block";
            return false;
        }
        if (getPerformer === null || getPerformer === "") {
            document.getElementById('errtxtPerformer').innerHTML = "Please Enter Performer";
            document.getElementById('errtxtPerformer').style.display = "block";
            return false;
        }
        if (getMethod === null || getMethod === "") {
            document.getElementById('errtxtMethod').innerHTML = "Please Enter Method";
            document.getElementById('errtxtMethod').style.display = "block";
            return false;
        }
        if (getBasis === null || getBasis === "") {
            document.getElementById('errtxtBasis').innerHTML = "Please Enter Basis";
            document.getElementById('errtxtBasis').style.display = "block";
            return false;
        }
        if (getOutcome === null || getOutcome === "") {
            document.getElementById('errtxtOutcome').innerHTML = "Please Enter Outcome";
            document.getElementById('errtxtOutcome').style.display = "block";
            return false;
        }
        if (getProbability === null || getProbability === "") {
            document.getElementById('errtxtProbability').innerHTML = "Please Enter Probability";
            document.getElementById('errtxtProbability').style.display = "block";
            return false;
        }
        if (getRelativeRisk === null || getRelativeRisk === "") {
            document.getElementById('errtxtRelativeRisk').innerHTML = "Please Enter Relative Risk";
            document.getElementById('errtxtRelativeRisk').style.display = "block";
            return false;
        }
        if (getStartDate === null || getStartDate === "") {
            document.getElementById('errtxtStartDate').innerHTML = "Please Enter Start Date";
            document.getElementById('errtxtStartDate').style.display = "block";
            return false;
        }
        if (getEndDate === null || getEndDate === "") {
            document.getElementById('errtxtEndDate').innerHTML = "Please Enter End Date";
            document.getElementById('errtxtEndDate').style.display = "block";
            return false;
        }

        var obj = {
          id: getRowId,
          uhid: uhid,
          userId: userId,
          clientId: clientID,
          generalIdentifier: getGeneralIdentifier,
          generalStatus: getGeneralStatus,
          subject: getSubject,
          encounter: getEncounter,
          encounterDate: getEncounterDate,
          condition: getCondition,
          performer: getPerformer,
          method: getMethod,
          basis: getBasis,
          outcome: getOutcome,
          probability: getProbability,
          qualitativeRisk: getQualitativeRisk,
          relativeRisk: getRelativeRisk,
          startDate: getStartDate,
          endDate:getEndDate
        }

        if (updateBool === 0) {
            setShowUnderProcess(1);
            console.log("Object", obj);
            let response = await InsertRiskAssessment(obj);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setShowToster(1);
                setTosterMessage("Data Save SuccessFully!");
                setTosterValue(0);
                setTimeout(() => {
                    setShowToster(0);
                }, 2000);

                 handleClear();
                 handleGet();

            } else {
                setShowUnderProcess(0);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTosterValue(1);
                setTimeout(() => {
                    setShowToster(0);
                }, 2000);
            }

        } else {

            setShowUnderProcess(1);
            console.log("Object", obj);
            let response = await UpdateRiskAssessment(obj);
            if (response.status === 1) {
                setShowUnderProcess(0);
                setShowToster(1);
                setTosterMessage("Data Save SuccessFully!");
                setTosterValue(0);
                setTimeout(() => {
                    setShowToster(0);
                }, 2000);
                setUpdateBool(0);
                 handleClear();
                 handleGet();

            } else {
                setShowUnderProcess(0);
                setShowToster(1);
                setTosterMessage(response.responseValue);
                setTosterValue(1);
                setTimeout(() => {
                    setShowToster(0);
                }, 2000);
            }

        }
    }

    useEffect(() => {
      handleGet();
  }, [])

    let handleGet = async () => {
      var objGet = {
          uhid: uhid,
          userId: userId,
          clientId: clientID
      }
      let getResponse = await GetRiskAssessmentList(objGet);
      if (getResponse.status === 1) {
        setRiskAssessmentList(getResponse.responseValue)
      } else {
      }
  }


  let handleDeleteRow = async () => {
    // setLoder(1)
    setShowUnderProcess(1);
    let objDelete = {
        uhid: uhid,
        id: getRowId,
        userId: userId,
        clientId: clientID
    }
    console.log('delete', objDelete);
    let response = await DeleteRiskAssessment(objDelete)
    if (response.status === 1) {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage("Data Deleted SuccessFully!")
        setTosterValue(0)
        setTimeout(() => {
            setShowToster(0)
        }, 2000)
        handleGet();
        handleClear();
    }
    else {
        setShowUnderProcess(0)
        setShowToster(1)
        setTosterMessage(response.responseValue)
        setTosterValue(1)
        setTimeout(() => {
            setShowToster(0)
        }, 2000)
    }
}


    let handleClear = async () => {
        setUpdateBool(0)
        setGeneralStatus(1);
        setGeneralIdentifier('');
        setSubject('');
        setEncounter('');
        setEncounterDate('');
        setCondition('');
        setPerformer('');
        setMethod('');
        setBasis('');
        setOutcome('');
        setProbability('');
        setQualitativeRisk(1);
        setRelativeRisk('');
        setStartDate('');
        setEndDate('');
        setRowId(0)
    }

    let handleEdit = async (list) => {
      setUpdateBool(1)
      setGeneralStatus(list.generalStatus);
      setGeneralIdentifier(list.generalIdentifier);
      setSubject(list.subject);
      setEncounter(list.encounter);
      setEncounterDate(list.encounterDate);
      setCondition(list.condition);
      setPerformer(list.performer);
      setMethod(list.method);
      setBasis(list.basis);
      setOutcome(list.outcome);
      setProbability(list.probability);
      setQualitativeRisk(list.qualitativeRisk);
      setRelativeRisk(list.relativeRisk);
      setStartDate(list.startDate);
      setEndDate(list.endDate);
      setRowId(list.id)
    }

  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 inputwidth">
              <Heading text={t("Risk Assessment")} />
              <BoxContainer>
                <div className="mb-1 me-2">
                  <label htmlFor="caretakerName" className="form-label">{t("General Information")}<span className="starMandatory">*</span></label>
                  <div className="SphereCustomrow">
                    <div className="SphereCustom-inn">
                      <span>Identifier </span>
                      <span>
                        <input type="text" className="form-control form-control-sm" value={getGeneralIdentifier} name="txtGeneralIdentifier" onChange={handleChange} />
                        <small id="errtxtGeneralIdentifier" className="form-text text-danger" style={{ display: 'none' }}></small>
                      </span>
                    </div>


                    <div className="SphereCustom-inn">
                      <span>Status </span>
                      <select className="form-select form-select-sm" value={getGeneralStatus} id="dropdownGeneralStatus" onChange={handleGeneralStatus}>
                        <option value="1">Registerd</option>
                        <option value="2">Preliminary</option>
                        <option value="3">Final</option>
                        <option value="4">Amended</option>
                        <option value="5">Entered-In-Error</option>
                        <option value="6">Cancelled</option>
                      </select>
                    </div>

                  </div>
                </div>

                <div className="mb-1 me-2">
                  <label htmlFor="caretakerName" className="form-label">{t("Subject and Encounter")}<span className="starMandatory">*</span></label>
                  <div className="SphereCustomrow">
                    <div className="SphereCustom-inn">
                      <span>Subject</span>
                      <span>
                        <input type="text" className="form-control form-control-sm" value={getSubject} name="txtSubject" onChange={handleChange} />
                        <small id="errtxtSubject" className="form-text text-danger" style={{ display: 'none' }}></small>
                      </span>
                    </div>
                    <div className="SphereCustom-inn">
                      <span>Encounter</span>
                      <span>
                        <input type="text" className="form-control form-control-sm" value={getEncounter} name="txtEncounter" onChange={handleChange} />
                        <small id="errtxtEncounter" className="form-text text-danger" style={{ display: 'none' }}></small>
                      </span>
                    </div>
                    <div className="SphereCustom-inn">
                      <span>Date</span>
                      <span>
                        <input type="date" className="form-control form-control-sm" value={getEncounterDate} name="txtEncounterDate" onChange={handleChange} />
                        <small id="errtxtEncounterDate" className="form-text text-danger" style={{ display: 'none' }}></small>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-1 me-2">
                  <label htmlFor="caretakerName" className="form-label">{t("Condition and Performer")}<span className="starMandatory">*</span></label>
                  <div className="SphereCustomrow">
                    <div className="SphereCustom-inn">
                      <span>Condition</span>
                      <span>
                        <input type="text" className="form-control form-control-sm" value={getCondition} name="txtCondition" onChange={handleChange} />
                        <small id="errtxtCondition" className="form-text text-danger" style={{ display: 'none' }}></small>
                      </span>
                    </div>
                    <div className="SphereCustom-inn">
                      <span>Performer</span>
                      <span>
                        <input type="text" className="form-control form-control-sm" value={getPerformer} name="txtPerformer" onChange={handleChange} />
                        <small id="errtxtPerformer" className="form-text text-danger" style={{ display: 'none' }}></small>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-1 me-2">
                  <label htmlFor="caretakerName" className="form-label">{t("Method and Basis")}<span className="starMandatory">*</span></label>
                  <div className="SphereCustomrow">
                    <div className="SphereCustom-inn">
                      <span>Method</span>
                      <span>
                        <input type="text" className="form-control form-control-sm" value={getMethod} name="txtMethod" onChange={handleChange} />
                        <small id="errtxtMethod" className="form-text text-danger" style={{ display: 'none' }}></small>
                      </span>
                    </div>
                    <div className="SphereCustom-inn">
                      <span>Basis (Observation)</span>
                      <span>
                        <textarea className="form-control form-control-sm" name="txtBasis" value={getBasis} onChange={handleChange} ></textarea>
                        {/* <input type="text" className="form-control form-control-sm" value={getAdditionalInformation} name="txtAdditionalInformation" onChange={handleChange} /> */}
                        <small id="errtxtBasis" className="form-text text-danger" style={{ display: 'none' }}></small>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-1 me-2">
                  <label htmlFor="caretakerName" className="form-label">{t("Prediction")}<span className="starMandatory">*</span></label>
                  <div className="SphereCustomrow">
                    <div className="SphereCustom-inn">
                      <span>Outcome</span>
                      <span>
                        <input type="text" className="form-control form-control-sm" value={getOutcome} name="txtOutcome" onChange={handleChange} />
                        <small id="errtxtOutcome" className="form-text text-danger" style={{ display: 'none' }}></small>
                      </span>
                    </div>
                    <div className="SphereCustom-inn">
                      <span>Probability</span>
                      <span>
                        <input type="text" className="form-control form-control-sm" value={getProbability} name="txtProbability" onChange={handleChange} />
                        <small id="errtxtProbability" className="form-text text-danger" style={{ display: 'none' }}></small>
                      </span>
                    </div>
                 
                  <div className="SphereCustom-inn">
                    <span>Qualitative Risk</span>
                    <select className="form-select form-select-sm" value={getQualitativeRisk} id="dropdownQualitativeRisk" onChange={handleQualitativeRisk}>
                      <option value="1">High</option>
                      <option value="2">Medium</option>
                      <option value="3">Low</option>
                    </select>
                  </div>

                  <div className="SphereCustom-inn">
                    <span>Relative Risk</span>
                    <span>
                      <input type="text" className="form-control form-control-sm" value={getRelativeRisk} name="txtRelativeRisk" onChange={handleChange} />
                      <small id="errtxtRelativeRisk" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </span>
                  </div>
                </div>
                </div>
                <div className="mb-1 me-2">
                  <label htmlFor="caretakerName" className="form-label">{t("When (Period)")}<span className="starMandatory">*</span></label>
                  <div className="SphereCustomrow">
                    <div className="SphereCustom-inn">
                      <span>Start Date</span>
                      <span>
                        <input type="date" className="form-control form-control-sm" value={getStartDate} name="txtStartDate" onChange={handleChange} />
                        <small id="errtxtStartDate" className="form-text text-danger" style={{ display: 'none' }}></small>
                      </span>
                    </div>
                  <div className="SphereCustom-inn">
                    <span>End Date</span>
                    <span>
                      <input type="date" className="form-control form-control-sm" value={getEndDate} name="txtEndDate" onChange={handleChange} />
                      <small id="errtxtEndDate" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </span>
                  </div>
                </div>
                </div>
                <div className="mb-1 relative">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                    <div>
                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />

                                                    : <div>
                                                        {updateBool === 0 ?
                                                            <>
                                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleSave}><img src={saveButtonIcon} className='icnn' alt='' />{t("Save")}</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt='' />{t("Cancel")}</button>
                                                            </>
                                                            :
                                                            <>
                                                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={handleSave}>UPDATE</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={handleClear}>{t("Cancel")}</button>
                                                            </>
                                                        }
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>

              </BoxContainer>
              <div className="col-12 mt-3">
                                <div className='handlser'>
                                    <Heading text={t("Risk Assessment List")} />
                                    <div style={{ position: 'relative' }}>
                                        <input type="text" className='form-control form-control-sm' placeholder={t("Search")} value={'searchTerm'} onChange={'handleSearch'} />
                                        <span className="tblsericon"><i className="fas fa-search"></i></span>
                                    </div>
                                </div>
                                <div className="med-table-section ipdtable" style={{ "height": "74vh" }}>

                                    <TableContainer>
                                        {/* <thead>
                                            <tr>
                                                <th className="text-center" style={{ "width": "3%" }}>#</th>
                                                <th>{t("General Identifier")}</th>
                                                <th>General Status</th>
                                                <th>Subject</th>
                                                <th>Encounter</th>
                                                <th>{t("Encounter Date")}</th>
                                                <th>{t("Condition")}</th>
                                                <th>{t("Performer")}</th>
                                                <th>{t("Method")}</th>
                                                {/* <th style={{textAlign:'left'}}>{t("Deceased")}</th> */}
                                                {/* <th>{t("Basis")}</th>
                                                <th>{t("Outcome")}</th>
                                                <th>{t("Qualitative Risk")}</th>
                                                <th>{t("Relative Risk")}</th>
                                                <th>{t("Start Date")}</th>
                                                <th>{t("EndD ate")}</th>
                                                <th>{t("outcome")}</th>
                                                <th>{t("User Name")}</th>
                                                <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                                            </tr>
                                        </thead> */} 

                                        <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "3%" }} rowSpan={2}>#</th>
                                            <th colSpan={2}>{t("General Information")}</th>
                                            <th colSpan={3}>{t("Subject and Encounter")}</th>
                                            <th colSpan={2}>{t("Condition and Performer")}</th>
                                            <th colSpan={2}>{t("Method and Basis")}</th>
                                            <th colSpan={4}>{t("Prediction")}</th>
                                            <th colSpan={2}>{t("When (Period)")}</th>
                                            {/* <th colSpan={2}>{t("Prism Base")}</th>
                                            <th colSpan={2}>{t("Power")}</th>
                                            <th rowSpan={2} style={{textAlign:'left'}}>{t("Contact Lens Details")}</th>
                                            <th rowSpan={2}>{t("Date Time")}</th> */}
                                            <th rowSpan={2}>{t("User Name")}</th>
                                            <th rowSpan={2} style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                                        </tr>
                                        <tr>
                                            <th>{t("Identifier")}</th>
                                            <th>{t("Status")}</th>
                                            <th>{t("Subject")}</th>
                                            <th>{t("Encounter")}</th>
                                            <th>{("Date")}</th>
                                            <th>{t("Condition")}</th>
                                            <th>{t("Performer")}</th>
                                            <th>{t("Method")}</th>
                                            <th>{t("Basis (Observation)")}</th>
                                            <th>{t("Outcome")}</th>
                                            <th>{t("Probability")}</th>
                                            <th>{t("Qualitative Risk")}</th>
                                            <th>{t("Relative Risk")}</th>
                                            <th>{t("Start Date")}</th>
                                            <th>{t("End Date")}</th>
                                            
                                        </tr>
                                    </thead>

                                        <tbody>
                                            {getRiskAssessmentList
                                                && getRiskAssessmentList.filter((val) => `${val.generalStatus}`.toLowerCase().includes(searchTerm.toLowerCase())).map((val, ind) => {
                                                    return (
                                                        <tr key={val.id}>
                                                            <td className="text-center">{ind + 1}</td>
                                                            <td>{val.generalIdentifier}</td>
                                                            <td>{val.generalStatusValue}</td>
                                                            <td>{val.subject} </td>
                                                            <td> {val.encounter}</td>
                                                            <td>{val.encounterDate}</td>
                                                            <td> {val.condition}</td>
                                                            <td>{val.performer}</td>
                                                            <td> {val.method}</td>
                                                           <td style={{maxWidth:'200px', wordBreak:'break-all'}}>{val.basis}</td>
                                                            <td>{val.outcome}</td>
                                                            <td>{val.probability}</td>
                                                            <td>{val.qualitativeRiskValue}</td>
                                                            <td>{val.relativeRisk}</td>
                                                            <td>{val.startDate}</td>
                                                            <td>{val.endDate}</td>
                                                            <td> {val.username}</td>
                                                            <td>
                                                                <div className="action-button">
                                                                    <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={IconEdit} onClick={() => { handleEdit(val) }} alt='' /></div>
                                                                    <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={IconDelete} onClick={() => { setRowId(val.id) }} alt='' /></div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}

                                        </tbody>
                                    </TableContainer>
                                    <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                                        <div className="modal-dialog modalDelete">
                                            <div className="modal-content">

                                                <div className="modal-body modelbdy text-center">
                                                    <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                    <div className='popDeleteTitle mt-3'>{t("Delete?")}</div>
                                                    <div className='popDeleteContent'>{t("Are_you_sure_you_want_to_delete?")}</div>
                                                </div>
                                                <div className="modal-footer1 text-center">

                                                    <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                                                    <button type="button" className="btn-delete popBtnDelete" onClick={handleDeleteRow} data-bs-dismiss="modal">{t("Delete")}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}