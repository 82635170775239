import React, { useEffect, useState } from "react";
import equipementExport from "../../assets/images/icons/equipementExport.png";
import iconTotalEquipment from "../../assets/images/icons/iconTotalEquipment.png";
import iconTotalWorking from "../../assets/images/icons/iconTotalWorking.png";
import iconTotalNotWorking from "../../assets/images/icons/TotalNotWorking.png";
import iconCondemn from "../../assets/images/icons/iconCondemn.png";
import iconListLocation from "../../assets/images/icons/iconListLocation.png";
import iconListDepartment from "../../assets/images/icons/iconListDepartment.png";
import GetEquipmentDashboard from "../API/EquipmentMaster/GetEquipmentDashboard";

export default function EquipmentDashboard() {
  const [checkListValue, setCheckListValue] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  //  console.log('checkValue', checkListValue)
  // console.log('values', checkListValue)

  // let checkListChange = (e) =>{
  //   let name = e.target.name;
  //   let value = e.target.value;
  //   setCheckListValue((pre) => [...pre, value])

  // }

  const getDashboardDetails = async () => {
    const taskApi = await GetEquipmentDashboard();
    if (taskApi.status === 1) {
      setDashboardData(taskApi.responseValue);
    } else {
    }
  };


  useEffect(() => {
    console.log("hit");
    getDashboardDetails();
  }, []);
  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="equipement-dashboard-wrppaer">
            <div className="equipement-title-search-box-wrapper">
              <div className="equipement-title">
                <div className="title"> Equipment Dashboard</div>
                <div className="subtitle">
                  Track, Manage and reuse your inventory items.
                </div>
              </div>

              <div className="search-box-notification">
                <div className="relative">
                  <input type="search" name="" id="" placeholder="Search" />{" "}
                  <i className="bi bi-search"></i>
                </div>
                <div className="relative">
                  <i className="bi bi-bell"></i>
                  <i className="bi bi-circle-fill"></i>
                </div>
              </div>
            </div>
            <div className="equipement-boxes-wrapper">
              <div className="equipement-box-item">
                <div className="text-content">
                  <div className="box-title">Total Equipment</div>
                  <div className="box-total">
                    {dashboardData.length > 0 &&
                      dashboardData[0].totalEquipment}
                  </div>
                  <div className="box-last-month">Since last month</div>
                </div>

                <div className="box-img">
                  <img src={iconTotalEquipment} alt="iconTotalEquipment" />
                </div>
              </div>

              <div className="equipement-box-item">
                <div className="text-content">
                  <div className="box-title">Total Working</div>
                  <div className="box-total">
                    {dashboardData.length > 0 && dashboardData[0].totalWorking}
                  </div>
                  <div className="box-last-month">Since last month</div>
                </div>

                <div className="box-img">
                  <img src={iconTotalWorking} alt="iconTotalEquipment" />
                </div>
              </div>

              <div className="equipement-box-item">
                <div className="text-content">
                  <div className="box-title">Total NotWorking</div>
                  <div className="box-total">
                    {dashboardData.length > 0 &&
                      dashboardData[0].totalNotWorking}
                  </div>
                  <div className="box-last-month">Since last month</div>
                </div>

                <div className="box-img">
                  <img src={iconTotalNotWorking} alt="iconTotalEquipment" />
                </div>
              </div>

              <div className="equipement-box-item">
                <div className="text-content">
                  <div className="box-title">Condemn</div>
                  <div className="box-total">
                    {dashboardData.length > 0 && dashboardData[0].condemn}
                  </div>
                  <div className="box-last-month">Since last month</div>
                </div>

                <div className="box-img">
                  <img src={iconCondemn} alt="iconTotalEquipment" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div
                className="med-table-section"
                style={{ marginTop: "-113px", borderRadius: "4px 4px 0 0" }}
              >
                <div
                  className="equipement-filter-wrapper px-2"                  
                >                  
                </div>

                <div
                  style={{ height: "calc(100vh - 290px)", overflowY: "auto" }}
                >
                  <table className="med-table table-equipment border_ border-bottom striped_">
                    <thead>
                      <tr>
                        <th className="text-center">
                          {" "}
                          <input type="checkbox" />{" "}
                        </th>
                        <th className="text-center" style={{width:'45px'}}>S. No.</th>
                        <th>Equipment Name</th>
                        <th>Serial No.</th>
                        <th>Coding</th>
                        <th>Location</th>
                        <th>Department</th>
                        <th>Status</th>
                        <th>AMC/CMC</th>
                        <th className="text-center">Export</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData &&
                        dashboardData.map((item, index) => (
                          <tr>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                name="checkList"
                                value={index}                                
                              />
                            </td>
                            <td className="text-center">{index + 1}</td>
                            <td> <span className="equipment-name">{item.equipmentName}</span> </td>
                            <td>{item.serialNumber}</td>
                            <td>{item.coding}</td>
                            <td>
                              {" "}
                              <img
                                src={iconListLocation}
                                alt="iconListLocation"
                              />{" "}
                              {item.locationName}
                            </td>
                            <td>
                              <img
                                src={iconListDepartment}
                                alt="iconListDepartment"
                              />{" "}
                              {item.departmentName}
                            </td>
                            <td>
                              {" "}
                              <span className="text-working">
                                {item.status}
                              </span>{" "}
                            </td>
                            <td>
                              {" "}
                              <span className="text-warranty">Warranty</span>
                            </td>
                            <td className="text-center">
                              <img
                                src={equipementExport}
                                alt=""
                                className="pointer"
                                style={{height:'19px'}}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
