import React,{ useEffect, useState } from 'react'
import Vector from "../../assets/images/icons/Vector.svg";
import Contact from "../../assets/images/icons/Contact.svg";


export default function PrintDonorVisit() {
  
    let [bool, setBool] = useState(true);
    let [loginData, setLoginData] = useState([]);
    let [DonorVisitData, setDonorVisitData] = useState([]);
    let [Donordetail, setDonordetail] = useState([]);
    let [Objdetail, setObjdetail] = useState([]);

    let functionGetLoginData = ()=> {      
        let response = JSON.parse(window.sessionStorage.getItem("LoginData"));            
        let getLoginData = response.clientdata        
        setLoginData(getLoginData)        
       }

       const getDonorVisitData=()=>{
        let data = window.sessionStorage.getItem("DonorVisitDetails");
        setDonorVisitData(JSON.parse(data))
        setDonordetail(JSON.parse(data).donorDetails)
        setObjdetail(JSON.parse(data).dataObj)
        console.log("dataaaa" , JSON.parse(data))
        console.log("donorDetails" , JSON.parse(data).donorDetails)
       }


        useEffect(() => {

       getDonorVisitData()
       functionGetLoginData()
       setTimeout(() => {
        handlepritnt();
        }, 1000)


        }, [])
     
    //    useEffect(() => {
    //     functionGetLoginData();
    //     setDateTime(date.toLocaleDateString() + " " + date.toLocaleTimeString());
    //     setTimeout(() => {
    //         handlepritnt();
    //     }, 1000)
    // }, []);

    let handlepritnt = () => {
        document.title = 'Donor Visit Print'; 
        setBool(false)
        window.print("");
        window.close();


    }
  return (
    <>
            



    <div className="opdSlipContainer">
        
    <div className='water-mark'>
        {/* {loginData.logoUrl == null ? <img src={mainlogo} alt='Brand Logo' title='Brand Logo'/> : <img src={loginData.logoUrl} alt='Brand Logo' title='Brand Logo'/>} */}
        <div className='clientText'>{loginData.clientName}</div>
        </div>

        {/* ----------------Header Sec-------------- */} 
        <div
                className="dis-hed"
                style={{
                  height: "auto",
                  padding: "8px",
                  background: "rgb(245 245 245)",
                  alignItems: "center",
                }}
              >
                <div className="">
                  <img
                    src={loginData.logoUrl}
                    alt="Company Logo"
                    style={{width: "211px", }}
                  />
                </div>

                <div className="CompanyDetailsHeading">
                  <div className="companyNameChallan">
                  {loginData.clientName}
                  </div>
                  <div className="companyLocation">
                  {loginData.address}
                  </div>
                  <div className="companyContact">
                    <img src={Vector} alt=""></img> {loginData.emailID}
                    
                    <img src={Contact} alt=""></img> {loginData.mobileNo}
                  </div>
                </div>
                
              </div>
       

        <div className="pat-dtls text-center">
            <div className='document-title'>DONOR VISIT SLIP</div>
        </div>               

        <div className="pat-dtls">
        <div className='document-title'>Donor Details</div>
            <table className='table-certificate cert-top1 border'>
                <tbody className=''>
                    {/* <div className='document-title fs-6'>ADMIT PATIENT DETAILS</div> */}
                  <tr className=''>
                        <th>Donor Id : </th><td className='ps-3 fw-semibold'>{Donordetail && Donordetail.id}</td>
                        <th>Donor Name : </th><td className='ps-3 fw-semibold'>{Donordetail && Donordetail.donorName}</td>
                        <th>DOB : </th><td className='ps-3 pe-1 fw-semibold'>{Donordetail && Donordetail.dob} </td>
                    </tr>

                    <tr>
                        <th>Gender : </th><td className='ps-3 fw-semibold'>{Donordetail && Donordetail.gender}</td>
                        <th>Blood-Group : </th><td className='ps-3 fw-semibold'>{Donordetail && Donordetail.groupName}</td>
                        <th>Identity Type : </th><td className='ps-3 fw-semibold'>{Donordetail && Donordetail.idName}</td>
                    </tr>

                    <tr>
                        <th>Identity No. : </th><td className='ps-3 fw-semibold'>{Donordetail && Donordetail.identityNumber}</td>
                        <th>Contact : </th><td className='ps-3 fw-semibold'>{Donordetail && Donordetail.contactNumber}</td>
                        <th>Address : </th><td className='ps-3 fw-semibold'>{Donordetail && Donordetail.address}</td>
                    </tr> 

                </tbody>
            </table>


          

        </div>
        <div className="pat-dtls">
        <div className='document-title'>Donor Specific Information</div>
            <table className='table-certificate cert-top1 border'>
                <tbody className=''>
                    {/* <div className='document-title fs-6'>ADMIT PATIENT DETAILS</div> */}
                  <tr className=''>
                        <th>UHID : </th><td className='ps-3 fw-semibold'>{Objdetail && Objdetail.Objdetail}</td>
                        <th>Segment No: </th><td className='ps-3 fw-semibold'>{Objdetail && Objdetail.segmentNo}</td>
                        <th>Donation type : </th><td className='ps-3 pe-1 fw-semibold'>{Objdetail && Objdetail.donationTypeID} </td>
                    </tr>

                    <tr>
                        <th>Donation Date : </th><td className='ps-3 fw-semibold'>{Objdetail && Objdetail.donationDate}</td>
                        <th>Donation Time: </th><td className='ps-3 fw-semibold'>{Donordetail && Donordetail.groupName}</td>
                        <th>Blood Quantity : </th><td className='ps-3 fw-semibold'>{Objdetail && Objdetail.bloodQuantity}</td>
                    </tr>

                    <tr>
                        <th>Guardian Name : </th><td className='ps-3 fw-semibold'>{Objdetail && Objdetail.guardianName}</td>
                        <th>Relationship : </th><td className='ps-3 fw-semibold'>{Objdetail && Objdetail.guardianRelationID}</td>
                        <th>Remark : </th><td className='ps-3 fw-semibold'>{Objdetail && Objdetail.remark}</td>
                    </tr> 

                </tbody>
            </table>


          

        </div>
        <div className="pat-dtls">
        <div className='document-title'>Vital Details</div>
            <table className='table-certificate cert-top1 border'>
                <tbody className=''>
                    {/* <div className='document-title fs-6'>ADMIT PATIENT DETAILS</div> */}
                  <tr className=''>
                        <th>Weight : </th><td className='ps-3 fw-semibold'>{Objdetail && Objdetail.weight}</td>
                        <th>HB Level: </th><td className='ps-3 fw-semibold'>{Objdetail && Objdetail.hbLevel}</td>
                        <th>BP : </th><td className='ps-3 pe-1 fw-semibold'>{Objdetail && Objdetail.bp} </td>
                    </tr>

                    <tr>
                        <th>Pulse Rate : </th><td className='ps-3 fw-semibold'>{Objdetail && Objdetail.pulse}</td>
                        {/* <th>Investigation prior to donate : </th><td className='ps-3 fw-semibold'>{Objdetail && Objdetail.guardianName}</td> */}
                        <th>Temperature: </th><td className='ps-3 fw-semibold'>{Objdetail && Objdetail.temp}</td>
                    </tr>



                </tbody>
            </table>


          

        </div>
       

       

        <div className='signatureSection cert-top3'>
            <div className='borderBottom'>Authorized  Signature</div>
        </div>

        <div className="opdSlipFooter">
            <a href="https://criteriontechnologies.com/" target="_blank" rel="noopener noreferrer">www.criteriontechnologies.com</a>
        </div>
    </div>

</>
  )
}
