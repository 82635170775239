
  
async function DeleteVenderscore (id,data) {
  
  const clientId =  window.clientId;
 // `/api/FeedForExternal/GetPatientFeedDataByUhid?uhid=${uhid}&clientId=${getclientId}`;
    let url =  window.AppbaseUrl + `/api/VitalRangeScore/DeleteVitalRangeScore?id=${id}&clientId=${clientId}`;
    let head = {
      'Content-Type': 'application/JSON',
      accept: '*/*',
    }
    let response =
      await fetch(url, {
        method: 'DELETE',
        headers: head,
        body: JSON.stringify(data)
      })
        .then((res) => res.json())
        .then(data)
  
  
    return response;
  }
  export default  DeleteVenderscore;
  
  