import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import ProcedureWithSedationGet from '../API/ProcedureWithSedationGet';
import GetAllStatus from '../../../Dietetics/API/FoodIntake/GetAllStatus';
import PatientLabel from '../../../Component/PatientLabel';
import PrintPageHeader from '../../../Component/PrintPageHeader';

export default function ReceiptProcedureWithSedation() {

    let [getprocedureData, setGetprocedureData] = useState([])
    let [ASAGradeList, setASAGradeList] = useState([])
    let [systemRespiratoryAssessment, setSystemRespiratoryAssessment] = useState([])
    let [cardiovascularSystem, setCardiovascularSystem] = useState([])
    let [CNSMusculoskeletalList, setCNSMusculoskeletalList] = useState([])
    let [generalExaminationStatusList, setGeneralExaminationStatusList] = useState([])
    let [renalStatusList, setRenalStatusList] = useState([])
    let [patientData, setPatientData] = useState([])
    const location = useLocation();
    let getData = async () => {
        const queryParams = new URLSearchParams(location.search);
        const uhID = queryParams.get('uhID');
        const index = queryParams.get('index');
        let response = await ProcedureWithSedationGet(uhID)
        if (response.status === 1) {
            let patientdataList = JSON.parse(window.sessionStorage.getItem("IPDpatientList"))
            let temp = []
            patientdataList.map((val, ind) => {
                if (val.uhId === uhID) {
                    temp.push(val.patientName)
                    temp.push(val.uhId)
                    temp.push(val.name)
                    temp.push(val.wardName)
                }
            })
            setPatientData(temp)
            setGetprocedureData(response.responseValue[index])
            let ASAmaster = await GetAllStatus("ASA Grade")
            if (ASAmaster.status === 1) {
                setASAGradeList(ASAmaster.responseValue)
            }

            let statusMaster = await GetAllStatus("System Respiratory Assessment")
            if (statusMaster.status === 1) {
                setSystemRespiratoryAssessment(statusMaster.responseValue)
            }
            let cardiovascularSystemMaster = await GetAllStatus("Cardiovascular System")
            if (cardiovascularSystemMaster.status === 1) {
                setCardiovascularSystem(cardiovascularSystemMaster.responseValue)
            }
            let CNSMusculoskeletalMaster = await GetAllStatus("CNS/Musculoskeletal")
            if (CNSMusculoskeletalMaster.status === 1) {
                setCNSMusculoskeletalList(CNSMusculoskeletalMaster.responseValue)
            }
            let generalExaminationStatusMaster = await GetAllStatus("General Examination")
            if (generalExaminationStatusMaster.status === 1) {
                setGeneralExaminationStatusList(generalExaminationStatusMaster.responseValue)
            }
            let renalStatusMaster = await GetAllStatus("Hepatic/Renal")
            if (renalStatusMaster.status === 1) {
                setRenalStatusList(renalStatusMaster.responseValue)
            }
            setTimeout(()=>{
                window.print();
                window.close()
            }, 500)
        }
        else {
            setGetprocedureData([])

        }
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <>
            <div className="main-table" style={{ padding: '5px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <table className='microtble pt-4 mt-4'>
                    {/* ....header.. */}
                    <tr>
                        <td colSpan={8}>
                            <PrintPageHeader />

                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td colSpan={8} style={{ verticalAlign: 'top', textAlign: 'center' }}>
                            <div className='micro-heading pt-1 pb-1' >
                                PROCEDURE WITH SEDATION FORM
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={8} className='pt-0 mb-2 ps-0 pe-0'>
                            <table className='table pt-0 mb-0 pb-0' style={{ border: '1px solid black' }}>
                                <tr>
                                    <td colSpan={4}>
                                        <div className='assesmnt mt-0 pt-0 mb-0 pb-0'>
                                            <strong>Date of Assessment:</strong> <span style={{ borderBottom: '1px dashed black', display: 'inline-block' }}>{getprocedureData.dateTimeOfAssessment && getprocedureData.dateTimeOfAssessment.split("T")[0]} </span> </div>
                                    </td>
                                    <td rowSpan={4} style={{ borderLeft: "1px solid black", width:'40%' }}>
                                        <PatientLabel />
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2"><div className='assesmnt d-flex mt-0 pt-0 mb-0 pb-0'><strong>Time of Assessment:</strong> <span style={{ width: '60px', borderBottom: '1px dashed black', marginLeft: '5px', display: 'inline-block' }}>{getprocedureData.dateTimeOfAssessment && getprocedureData.dateTimeOfAssessment.split("T")[1]}</span> </div></td>
                                    <td colspan="2"><div className='assesmnt d-flex mt-0 pt-0 mb-0 pb-0'><strong>Ward.:</strong><span style={{ width: '60px', borderBottom: '1px dashed black', marginLeft: '5px', display: 'inline-block' }}>{patientData && patientData[3]}</span></div></td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div className='assesmnt d-flex mt-0 pt-0 mb-0 pb-0'><strong>Diagnosis:</strong>
                                            <span style={{ width: '290px', borderBottom: '1px dashed black', marginLeft: '5px', display: 'inline-block' }}>                                                {
                                                getprocedureData.length !== 0 && getprocedureData.diagnosis.length !== 0 ? JSON.parse(getprocedureData.diagnosis).map((val) => val.details).join(', ') : ""
                                            }
                                            </span>
                                        </div>
                                    </td>
                                   
                                </tr>
                                <tr>
                                <td colspan="4"><div className='assesmnt d-flex mt-0 pt-0 mb-0 pb-0'><strong>Procedure.:</strong> <span style={{ width: '290px', marginLeft: '5px', borderBottom: '1px dashed black', marginLeft: '5px', display: 'inline-block' }}>
                                        {getprocedureData && getprocedureData.procedureName}
                                    </span> </div></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    {/* <tr>
                        <td className='mt-4 pt-4 ps-0 pe-0' colSpan={8}>
                            <table className='ptndetailsmore '>
                                <tr>
                                    <td colSpan={2}>
                                        <div>Patient Full Name:</div>
                                        <div>Patient MR. No:</div>
                                    </td>
                                    <td colSpan={2}><div>Date & Time of <br /> Procedure: </div></td>
                                    <td colSpan={2}><div style={{ verticalAlign: 'top' }}>Admitting Doctor:</div></td>
                                    <td colSpan={2} style={{ verticalAlign: 'top' }}>Department:</td>
                                </tr>
                            </table>
                        </td>
                    </tr> */}
                    <tr>
                        <td className='mt-3 pt-3 ps-0 pe-0' colSpan={8} >
                            <table className=''>
                                <tr>
                                    <td className='mt-0 mb-0 pt-0 ps-0 pe-0' colSpan={8} >
                                        <table className='ptndetailsmore mb-0 '>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div><strong>Patient Full Name:</strong> &nbsp; {patientData && patientData[0]}</div>
                                                    {/* <div><strong>Patient MRN. No:</strong>&nbsp; {patientData && patientData[1]}</div> */}
                                                </td>
                                                <td colSpan={2}><div><strong>Date & Time of <br /> Procedure:</strong> {new Date().toISOString().split('T')[0] + " " + new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })}</div></td>
                                                <td colSpan={2} style={{ verticalAlign: 'top' }}><div ><strong>Admitting Doctor:</strong> &nbsp; {patientData && patientData[2]}</div></td>
                                                <td colSpan={2} style={{ verticalAlign: 'top' }}><strong>Department:</strong></td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="mt-0 pt-0 ps-0 pe-0" colspan="8" style={{ border: '1px solid black' }}>
                                        <table>
                                            <tr> <td colSpan={6}><div className='sdtinpre mb-1' >A.PRE SEDATION ASSESSMENT</div></td>
                                                <td colSpan={2}  ><div style={{ textAlign: 'center', fontWeight: '900' }}>Time : <span>{new Date().toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' })}</span></div></td>
                                            </tr>
                                            <tr> <td colSpan={8}>
                                                <div><strong>Name of Procedure: </strong><span style={{ width: '50%', borderBottom: '1px dashed black', display: 'inline-block' }}>
                                                    {getprocedureData && getprocedureData.preProcedureName}
                                                </span></div></td>

                                            </tr>
                                            <tr><td colSpan={8}><div className='mb-1'><strong>Name of Anesthetist:</strong> <span style={{ width: '50%', borderBottom: '1px dashed black', display: 'inline-block' }}>
                                                {getprocedureData && getprocedureData.ansethetistName}
                                            </span></div></td></tr>

                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='mt-0 pt-0 ps-0 pe-0' colSpan={8} style={{ border: '1px solid' }}>
                            <table className='mt-0 pt-0'>
                                <tr>
                                    <td colSpan={4} style={{ width: '57.1%' }}>
                                        <div className='presdtinpre mb-1'><strong>Pre procedure checklist :</strong></div>
                                        <div className="Restrainform">
                                            <div>Informed Consent:</div>
                                            <div> <label>  Yes <input type="checkbox" checked={getprocedureData && getprocedureData.informedConsent === 1 ? true : false} />  </label>    </div>
                                            <div> <label>  No<input type="checkbox" checked={getprocedureData && getprocedureData.informedConsent === 0 ? true : false} />    </label> </div>
                                        </div>
                                        <div className="Restrainform">
                                            <div>Previous anesthesia/sedation :</div>
                                            <div> <label>  Yes <input type="checkbox" checked={getprocedureData && getprocedureData.prevoiusSedation === 1 ? true : false} />  </label>    </div>
                                            <div> <label>  No<input type="checkbox" checked={getprocedureData && getprocedureData.prevoiusSedation === 0 ? true : false} />    </label> </div>
                                        </div>
                                        <div className="Restrainform">
                                            <div>Allergies:</div>
                                            <div> <label>  Yes <input type="checkbox" checked={getprocedureData && getprocedureData.isAllergies === 1 ? true : false} />  </label>    </div>
                                            <div> <label>  No<input type="checkbox" checked={getprocedureData && getprocedureData.isAllergies === 0 ? true : false} />    </label> </div>
                                        </div>
                                        <div className="Restrainform">
                                            <div>NBM:</div>
                                            <div> <label>  Yes <input type="checkbox" checked={getprocedureData && getprocedureData.isNbm === 1 ? true : false} />  </label>    </div>
                                            <div> <label>  No<input type="checkbox" checked={getprocedureData && getprocedureData.isNbm === 0 ? true : false} />    </label> </div>
                                        </div>
                                    </td>
                                    <td colSpan={4} style={{ verticalAlign: 'top', borderLeft: '1px solid' }}> <div className='presdtinpre mb-1'><strong>ASA Grade</strong></div>
                                    <div className="hepaticprintt pb-2 pt-1">

                                            {
                                                ASAGradeList && ASAGradeList.map((val, ind) => {
                                                    return (
                                                        <div>   <label> <input type="checkbox" checked={getprocedureData && getprocedureData.asaGrade === val.id ? true : false} />  {val.remark}    </label> </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='mt-0 pt-0 ps-0 pe-0' colSpan={8} style={{ border: '1px solid' }}>
                            <table className=''>
                                <tr>
                                    <td colSpan={4}>
                                        <div className='presdtinpre mb-1'><strong>Airway Assessment:</strong></div>
                                        <div className='presdtinpre mb-1'><strong>Malam Patti Class:&nbsp; {getprocedureData && getprocedureData.malamPattiClass}</strong></div>
                                        <div className="Restrainform">
                                            <div>Denture: :</div>
                                            <div> <label>  Yes <input type="checkbox" checked={getprocedureData && getprocedureData.denture === 1 ? true : false} />  </label>    </div>
                                            <div> <label>  No<input type="checkbox" checked={getprocedureData && getprocedureData.denture === 0 ? true : false} />    </label> </div>
                                        </div>
                                        <div className="Restrainform">
                                            <div>Other :</div>
                                            {/* <div> <label>  Yes <input type="checkbox" checked={getprocedureData && getprocedureData.isNbm === 1 ? true : false} />  </label>    </div>
                                            <div> <label>  No<input type="checkbox" checked={getprocedureData && getprocedureData.isNbm === 1 ? true : false} />    </label> </div> */}
                                        </div>
                                    </td>
                                    <td colSpan={4} style={{ verticalAlign: 'top', borderLeft: '1px solid' }}>
                                        <div className='presdtinpre mb-1'><strong> Current Medication :</strong></div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td className='mt-0 pt-0 ps-0 pe-0' colSpan={8} style={{ border: '1px solid' }}>
                            <table className=''>
                                <tr>
                                    <td colSpan={4}>
                                        <div className='presdtinpre mb-1'><strong>SYSTEM:</strong></div>
                                        <div className='presdtinpre mb-1 mt-0'><strong>RESPIRATORY ASSESSMENT:</strong></div>
                                        <div className="hepaticprintt pb-2">


                                            {
                                                systemRespiratoryAssessment && systemRespiratoryAssessment.map((val, ind) => {
                                                    return (
                                                        <div>{val.remark} : <input type="checkbox" checked={getprocedureData && getprocedureData.sysRespAssementStatusId === val.id ? true : false} />  </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </td>
                                    <td className='pe-0 ps-0' colSpan={4} style={{ verticalAlign: 'top', borderLeft: '1px solid', width: '42.8%' }}>
                                        <div className='presdtinpre pb-3 pt-0 pe-2 ps-2 ' style={{ borderBottom: '1px solid' }}><strong> CLINICAL EVALUATION :</strong></div>
                                        <div>
                                            {
                                                getprocedureData.length !== 0 && getprocedureData.vitals.length !== 0 ? JSON.parse(getprocedureData.vitals).map((val) => {
                                                    if (val.vmId === 4) {
                                                        return (
                                                            <div> BP <span style={{ width: '20%', borderBottom: '1px dashed black', display: 'inline-block' }}> {val.vmValue}/ {JSON.parse(getprocedureData.vitals).filter(val=>val.vmId === 6)[0].vmValue } </span> mm of Hg </div>

                                                        )
                                                    }
                                                    else if (val.vmId === 3) {
                                                        return (

                                                            <div> Pulse <span style={{ width: '20%', borderBottom: '1px dashed black', display: 'inline-block' }}>{val.vmValue}</span>  /min</div>
                                                        )
                                                    }
                                                    else if (val.vmId === 56) {
                                                        return (

                                                            <div> SpO2 <span style={{ width: '20%', borderBottom: '1px dashed black', display: 'inline-block' }}>{val.vmValue}</span></div>

                                                        )
                                                    }

                                                }) : ""
                                            }

                                        </div>

                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='mt-0 pt-0 ps-0 pe-0' colSpan={8} style={{ border: '1px solid' }}>
                            <table className='mt-0 pt-0'>
                                <tr>
                                    <td colSpan={4} style={{ verticalAlign: 'top' }}>
                                        <div className='presdtinpre mb-1 mt-1 '><strong>CARDIOVASCULAR SYSTEM</strong></div>
                                        <div className=''>
                                        <div className="hepaticprintt pb-2">
                                            {
                                                cardiovascularSystem && cardiovascularSystem.map((val, ind) => {
                                                    return (
                                                        <div>{val.remark} : <input type="checkbox" checked={getprocedureData && getprocedureData.cardiovascularSysStatusId === val.id ? true : false} />  </div>

                                                    )
                                                })
                                            }
                                            {/* <div>IHD : <input type="checkbox" />  </div>
                                            <div> RHD : <input type="checkbox" />  </div>
                                            <div> CHFO : <input type="checkbox" />  </div> */}
                                        </div>
                                        {/* <div className="Restrainform">
                                            <div>Dyspnea on exertion / MET : <input type="checkbox" />  </div>

                                        </div> */}
                                        </div>
                                    </td>
                                    <td colSpan={4} style={{ verticalAlign: 'top', borderLeft: '1px solid', width: '42.8%' }}>

                                        <div className='mb-1'>
                                            <div> CVS <span style={{ width: '20%', borderBottom: '1px dashed black', display: 'inline-block' }}>{getprocedureData && getprocedureData.cvs}</span></div>
                                            <div> RS <span style={{ width: '20%', borderBottom: '1px dashed black', display: 'inline-block' }}>{getprocedureData && getprocedureData.rs}</span>  </div>
                                            <div> P/A <span style={{ width: '20%', borderBottom: '1px dashed black', display: 'inline-block' }}>{getprocedureData && getprocedureData.pa}</span></div>
                                            <div> CNS <span style={{ width: '20%', borderBottom: '1px dashed black', display: 'inline-block' }}>{getprocedureData && getprocedureData.cns}</span></div>

                                        </div>

                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='mt-0 pt-0 ps-0 pe-0' colSpan={8} style={{ border: '1px solid' }}>
                            <table className=''>
                                <tr>
                                    <td colSpan={4}>
                                        <div className='presdtinpre mb-1 mt-1'><strong>CNS/MUSCULOSKELETAL</strong></div>
                                        <div className='hepaticprintt pb-2'>
                                        {
                                            CNSMusculoskeletalList && CNSMusculoskeletalList.map((val, ind) => {
                                                return (
                                                    <div>{val.remark} : <input type="checkbox" checked={getprocedureData && getprocedureData.musculoskeletalStatusId === val.id ? true : false} />  </div>
                                                )
                                            })
                                        }
                                        {/* <div className="Restrainform">
                                            <div>CVA : <input type="checkbox" />  </div>
                                            <div>Seizures : <input type="checkbox" />  </div>

                                        </div>
                                        <div className="Restrainform">
                                            <div>Neuromuscular Disorders <input type="checkbox" />  </div>

                                        </div> */}
                                        </div>
                                    </td>
                                    <td colSpan={4} style={{ verticalAlign: 'top', borderLeft: '1px solid', width: '42.8%' }}>

                                    <div className='hepaticprintt pb-2 pt-1'>
                                            {
                                                generalExaminationStatusList && generalExaminationStatusList.map((val, ind) => {
                                                    return (
                                                        <div>{val.remark} : <input type="checkbox" checked={getprocedureData && getprocedureData.generalExaminationStatusId === val.id ? true : false} />  </div>

                                                    )
                                                })
                                            }
                                            {/* <div>Icterus  : <input type="checkbox" />  </div>
                                            <div>Edema   : <input type="checkbox" />  </div>
                                            <div>LNP   : <input type="checkbox" />  </div>
                                            <div>Other   : <input type="checkbox" />  </div> */}

                                        </div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='mt-0 pt-0 ps-0 pe-0' colSpan={8} style={{ border: '1px solid' }}>
                            <table className='pt-0 pb-0'>
                                <tr>
                                    <td colSpan={4} style={{ verticalAlign: 'top' }}>
                                    
                                        <div className='presdtinpre mb-1 mt-1'><strong>HEPATIC / RENAL</strong></div>
                                        <div className='hepaticprintt pb-2'>
                                        {
                                            renalStatusList && renalStatusList.map((val, ind) => {
                                                return (
                                                    <div className=''>{val.remark}  : <input type="checkbox" checked={getprocedureData && getprocedureData.renalStatusId === val.id ? true : false} />  </div>

                                                )
                                            })
                                        }
                                        {/* <div className="Restrainform">
                                            <div>Hepatitis  : <input type="checkbox" />  </div>
                                            <div>Hepatic Failure  : <input type="checkbox" />  </div>
                                            <div>Oliguria  : <input type="checkbox" />  </div>
                                        </div>
                                        <div className="Restrainform">
                                            <div>Chronic renal failure : <input type="checkbox" />  </div>

                                        </div> */}
                                        </div>
                                    </td>
                                    <td colSpan={4} style={{ verticalAlign: 'top', borderLeft: '1px solid', width: '42.8%' }}>
                                        <div className='presdtinpre mb-1'><strong>Investigations:</strong></div>

                                        {
                                            getprocedureData.length !== 0 && getprocedureData.investigationJson.length !== 0 ? JSON.parse(getprocedureData.investigationJson).map((val) => {
                                                return (
                                                    <div className='ps-0 pe-2'> {val.itemName} </div>
                                                )
                                            }) : ""
                                        }
                                        {/* <div className="statustbl3  pt-1 mb-0 mt-0 ps-0 pe-0">
                                            <div className='ps-0 pe-2'> HB </div>
                                            <div className='ps-0 pe-2'>PT/INR</div>
                                        </div>
                                        <div className="statustbl3  pt-1 mb-0 mt-0 ps-0 pe-0">
                                            <div className='ps-0 pe-2'> BSL </div>

                                            <div className='ps-0 pe-2'> LFT </div>


                                        </div>
                                        <div className="statustbl3  pt-1 mb-0 mt-0 ps-0 pe-0">
                                            <div className='ps-0 pe-2'>RFT</div>
                                            <div className='ps-0 pe-2'>Serology</div>
                                            <div className='ps-0 pe-2'>Others</div>

                                        </div> */}


                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>




            </div>
        </>
    )
}

