import React, { useEffect, useState } from 'react'
import IconICUChart24HrsFeed from '../../../../../../assets/images/icons/IconICUChart24HrsFeed.svg'
import GetFoodIntake from '../ICUChartAPi/GetFoodIntake';
import GetNutrientByFood from '../ICUChartAPi/GetNutrientByFood';

export default function TwentyFourHourFeedLimit() {
  const [getClientId, setClientId] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientId);
  const [getUHID, setUHID] = useState(JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid);
  let [foodIntakeList, setFoodIntakeList] = useState([]);
  const [nutrientList, setNutrientList] = useState([]);
  const [foodId, setFoodId] = useState(null);
  const [foodQty, setFoodQty] = useState(null);
  const [mergedArray, setMergedArray] = useState([]);


  console.log("foodIntakeList===", foodIntakeList)


  //Get All FoodIntake List
  // let getFoodIntakeList = async () => {
  //   const currentDate = new Date();
  //   const day = currentDate.getDate().toString().padStart(2, '0');
  //   const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  //   const year = currentDate.getFullYear();
  //   const txtGetDate = `${year}-${month}-${day}`;
  //   let entryType = "N";
  //   let foodIntakeList = await GetFoodIntake(getUHID, txtGetDate, entryType);
  //   if (foodIntakeList.status === 1) {
  //     setFoodIntakeList(foodIntakeList.foodIntakeList);
  //   }
  // };

  let getFoodIntakeList = async () => {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear();
    const txtGetDate = `${year}-${month}-${day}`;
    let entryType = "N";
    let foodIntakeList = await GetFoodIntake(getUHID, txtGetDate, entryType);
    if (foodIntakeList.status === 1) {
      setFoodIntakeList(foodIntakeList.foodIntakeList);
      if (foodIntakeList.foodIntakeList.length > 0) {
        setFoodId(foodIntakeList.foodIntakeList[0].foodId);
        setFoodQty(foodIntakeList.foodIntakeList[0].foodQty);
      }
    }
  };
  //Get Nutrient By Food
  // let getNutrientByFood = async () => {
  //   if (foodId !== null && foodQty !== null) {
  //     let getNutrient = await GetNutrientByFood(foodId, foodQty);
  //     if (getNutrient.status === 1) {
  //       setNutrientList(getNutrient.nutrientList);
  //       console.log("nutrientList001===>", getNutrient)
  //     }
  //   }
  // }

  //Get Nutrient By Food
  let getNutrientByFood = async (id, qty) => {
    if (id !== null && qty !== null) {
      let getNutrient = await GetNutrientByFood(id, qty);
      if (getNutrient.status === 1) {
        setNutrientList((prevList) => [
          ...prevList,
          { ...getNutrient, foodId: id, foodQty: qty },
        ]);

        console.log("nutrientResponse:", getNutrient);

        return getNutrient
      
 
        
      }
    }
  };


  //UseEffect
  useEffect(() => {
    getFoodIntakeList();
  }, []);


  useEffect(() => {
    let temp = []

    const fetchData = async () => {
      for (const entry of foodIntakeList) {
       let getNutrient =  await getNutrientByFood(entry.foodId, entry.foodQty);
        getNutrient.responseValue.map((val, ind) => {
          setMergedArray(vals => [...vals, val])
          temp.push(val)
        })
      }
      
    };
    
    console.log("Merged Nutrient List t:", temp);

    fetchData();
  }, [foodIntakeList]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     for (const entry of foodIntakeList) {
  //       await getNutrientByFood(entry.foodId, entry.foodQty);
  //     }

  //     let mergedArray = nutrientList.flat();
  //     console.log("Merged Nutrient List:", mergedArray);
  //       let summedArray = [];
  //     mergedArray.forEach(item => {
  //       const existingItem = summedArray.find(
  //         i => i.nutrientID === item.nutrientID && i.unitID === item.unitID
  //       );  
  //       if (existingItem) {
  //         existingItem.nutrientValue += item.nutrientValue;
  //       } else {
  //         summedArray.push({ ...item });
  //       }
  //     });  
  //     summedArray.sort((a, b) => {
  //       if (a.nutrientID !== b.nutrientID) {
  //         return a.nutrientID - b.nutrientID;
  //       }
  //       return a.unitID - b.unitID;
  //     });

  //     console.log("Merged Nutrient List summed:", summedArray);
  //   };

  //   fetchData();
  // }, [foodIntakeList]);

  return (
    <>
      <div className='icuChartHeading mb-2'>
        <div className='icuChartHeading-inn'>
          <div className='ICUHead-lft'>
            <span><img src={IconICUChart24HrsFeed} alt='' /></span>
            <span><h3>24 hrs Feed Limit</h3></span>
          </div>
        </div>
      </div>
      <div className="med-table-section Icu-componenttbl" style={{ "height": "35vh", paddingBottom: '10px' }}>
        <table className="med-table table-responsive">
          <thead>
            <tr>
              <th style={{ minWidth: "150px" }} ></th>

            </tr>
          </thead>
          <tbody>

          </tbody>
        </table>
      </div>
    </>
  )
}
