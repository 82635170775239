import React, { useEffect, useState } from 'react'

export default function MiddleInvestigationSection(props) {
    let [investigationList, setInvestigationList] = useState([])
    useEffect(() => {
        let temp = []
        props.patientInvestigationData && props.patientInvestigationData.map((val, ind) => {
            temp.push({
                value: val.subTestValue,
                name: val.subTestName
            })
        })
        setInvestigationList(temp)
    }, [props])
    return (
        <>
            <div className='d-flex flex-row justify-content-evenly align-items-center investigation-container1'>
                {
                    investigationList && investigationList.splice(0, 4).map((val, ind) => {
                        return (
                            <div className='col-2 d-flex flex-row justify-content-center gap-2' style={{ borderRight: "1px solid #006B8C" }}><span>{val.name}</span><span>{val.value}</span></div>
                        )
                    })
                }
                {/* <div className='col-3 d-flex flex-row justify-content-center gap-2' style={{ borderRight: "1px solid #006B8C" }}><span>Na+</span><span>140</span></div>
                <div className='col-3 d-flex flex-row justify-content-center gap-2' style={{ borderRight: "1px solid #006B8C" }}><span>Mg</span><span>9</span></div>
                <div className='col-3 d-flex flex-row justify-content-center gap-2'><span>Mg</span><span>9</span></div> */}
            </div>
            <div className='d-flex flex-row justify-content-evenly align-items-center investigation-container1'>
                
            {
                    investigationList && investigationList.splice(4, 4).map((val, ind) => {
                        return (
                            <div className='col-2 d-flex flex-row justify-content-center gap-2' style={{ borderRight: "1px solid #006B8C" }}><span>{val.name}</span><span>{val.value}</span></div>
                        )
                    })
                }
                {/* <div className='col-3 d-flex flex-row justify-content-center gap-2' style={{ borderRight: "1px solid #006B8C" }}><span>Na+</span><span>140</span></div>
                <div className='col-3 d-flex flex-row justify-content-center gap-2' style={{ borderRight: "1px solid #006B8C" }}><span>Mg</span><span>9</span></div>
                <div className='col-3 d-flex flex-row justify-content-center gap-2'><span>Mg</span><span>9</span></div> */}
            </div>
        </>
    )
}
