async function GetAllSavedAllergiesByUhid(uhid) {

    let url = window.AppbaseUrl+`/api/PatientInitialAssessment/GetAllSavedAllergiesByUhid?ClientId=${window.clientId}&UHID=${uhid}`;
    // let url = `https://localhost:7225/api/PatientInitialAssessment/GetAllAllergies?ClientId=${ClientId}`;
    let data = {}
    let response = fetch(url, {
        // headers: head,
        method: 'GET'
    })
        .then((res) => res.json())
        .then(data);

    return response;
}
export default GetAllSavedAllergiesByUhid;