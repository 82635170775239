import React, { useEffect, useState } from 'react'
import Heading from '../../../Component/Heading'
import BoxContainer from '../../../Component/BoxContainer'
import TableContainer from '../../../Component/TableContainer'
import Toster from '../../../Component/Toster'
import TosterUnderProcess from '../../../Component/TosterUnderProcess'
import PostAPIWardMaster from '../../Api/Master/WardMasterAPI/PostAPIWardMaster'
import GetAPIWardMaster from '../../Api/Master/WardMasterAPI/GetAPIWardMaster'
import DeleteAPIWardMaster from '../../Api/Master/WardMasterAPI/DeleteAPIWardMaster'
import PutAPIWardMaster from '../../Api/Master/WardMasterAPI/PutAPIWardMaster'
import ValidationWardMaster from '../../../Validation/Admin/Master/ValidationWardMaster'
import IconEdit from '../../../assets/images/icons/IconEdit.svg'
import IconDelete from '../../../assets/images/icons/IconDelete.svg'
import Search from '../../../Code/Serach'
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import saveButtonIcon from '../../../assets/images/icons/saveButton.svg';
import clearIcon from '../../../assets/images/icons/clear.svg';
import PostCurrency from '../../Api/Master/CurrencyMaster/PostCurrency';
import GetAllCurrency from '../../Api/Master/CurrencyMaster/GetAllCurrency';
import DeleteCurrency from '../../Api/Master/CurrencyMaster/DeleteCurrency'
import UpdateCurrency from '../../Api/Master/CurrencyMaster/UpdateCurrency';
import GetClient from '../../Api/Master/ClientDepartmentAssign/GetClient';
import getdepartment from '../../Api/Master/ClientDepartmentAssign/GetDepartment'
import DropdownWithSearch from '../../../Component/DropdownWithSearch';
import postclientdeptassign from '../../Api/Master/ClientDepartmentAssign/PostClientDepartmentAssign';
import getclientdeptassign from '../../Api/Master/ClientDepartmentAssign/GetPostClientDepartmentAssign'
import { createLogger } from '@microsoft/signalr/dist/esm/Utils'

export default function ClientDepartmentAssign() {
    let [dischargeTypeList, setdischargeTypeList] = useState([])
    let [dischargeTypeListMain, setdischargeTypeListMain] = useState()
    let [updateBool, setUpdateBool] = useState(0)
    let [sendForm, setSendForm] = useState({ "userId": window.userId })
    let [loder, setLoder] = useState(1)
    let [rowId, setRowId] = useState('')
    let [showUnderProcess, setShowUnderProcess] = useState(0)
    let [showToster, setShowToster] = useState(0)
    let [tosterMessage, setTosterMessage] = useState("")
    let [tosterValue, setTosterValue] = useState(0);
    let [client, setclient] = useState();
    let [department, setdepartment] = useState();
    let [clearDropdown, setClearDropdown] = useState(0);
    let [clientdepartment, setclientdepartment] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    // let[clientId,setclientId]=useState('');
    const { t } = useTranslation();

    let getclient = async () => {
        let data = await GetClient();
        console.log("data", data);
        setclient(data.responseValue);
    }
    let GetDepartment = async () => {
        let data = await getdepartment();
        setdepartment(data.responseValue)
    }
    let GetClientDepartment=async()=>{
        let data=await getclientdeptassign();
        console.log('ffffffffffffff',data);
        setclientdepartment(data.responseValue)
    }
    //Handle Save
    let saveForm = async () => {
        let send = {
            clientId: sendForm.clientId,
            departmentId: selectedIds
        }
        console.log("depart", send)
        // let valresponse = ValidationWardMaster(sendForm.currencyName)
            let response = await postclientdeptassign(send);
            if (response.status === 1) {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage("Data Save SuccessFully!")
                setTosterValue(0)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)

                handleClear();
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage(response.responseValue)
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }
            // getdata()
        
            GetClientDepartment()

    }
    
    //Get data
    let getdata = async () => {
        const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
        let getResponse = await GetAllCurrency();
        if (getResponse.status === 1) {
            setdischargeTypeList(getResponse.responseValue)
            setdischargeTypeListMain(getResponse.responseValue)
        }

    }
    //Handle Search
    let handleSearch = (e) => {
        let resp = Search(dischargeTypeListMain, e.target.value)
        if (e.target.value !== "") {
            if (resp.length !== 0) {
                setdischargeTypeList(resp)
            }
            else {
                setdischargeTypeList([])

            }
        }
        else {
            setdischargeTypeList(dischargeTypeListMain)
        }
    }
    //Handle Change
    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        console.log("csdcssdc",name )
        if (name === "clientId") {
            setSendForm(sendForm => ({
                ...sendForm,
                [name]: value,
            }))
            // setclientId(value);

        }
        else {
            setSelectedIds(val => ([...val, value]))
        }


        // console.log("ffff", clientId)
    }

    //Handle Delete
    let handleDeleteRow = async () => {
        // setLoder(1)
        setShowUnderProcess(1);
        let obj = {
            id: rowId
        }
        let response = await DeleteCurrency(obj)
        if (response.status === 1) {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Data Deleted SuccessFully!")
            setTosterValue(0)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
            getdata();
            handleClear();
        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage(response.responseValue)
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }
    //Handle Button Change
    let handleUpdate = async (id, currencyName, userId) => {
        setUpdateBool(1)
        setSendForm(sendForm => ({
            ...sendForm,
            "id": id,
            "currencyName": currencyName,
            "userId": userId,
        }))

        document.getElementById("currencyName").value = currencyName;
    }


    // Handle Update
    let saveUpdate = async () => {
        let valresponse = ValidationWardMaster(sendForm.currencyName)

        if (valresponse) {
            setShowUnderProcess(1)
            let response = await UpdateCurrency(sendForm)
            if (response.status === 1) {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage("Data Updated SuccessFully!")
                setTosterValue(0)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)

                setUpdateBool(0)
                getdata()
                handleClear();
            }
            else {
                setShowUnderProcess(0)
                setShowToster(1)
                setTosterMessage(response.responseValue)
                setTosterValue(1)
                setTimeout(() => {
                    setShowToster(0)
                }, 2000)
            }

        }
        else {
            setShowUnderProcess(0)
            setShowToster(1)
            setTosterMessage("Field can't be blank!")
            setTosterValue(1)
            setTimeout(() => {
                setShowToster(0)
            }, 2000)
        }
    }

    //Handle Clear
    let handleClear = () => {
        setSendForm({ "userId": window.userId, })
        setUpdateBool(0);
        setClearDropdown(1);
        document.getElementById('clientName').value="0";
    }
    useEffect(() => {
        getdata();
        getclient();
        GetDepartment();
        GetClientDepartment();
    }, [])
    document.body.dir = i18n.dir();
    return (
        <>
            <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <Heading text={t("Client Department Assign")} />
                            <BoxContainer>
                                <div className="mb-2 me-2">
                                    <label htmlFor="clientName" className="form-label">{("Client Name")}<span className="starMandatory">*</span></label>
                                    <select className="form-select form-select-sm" id="clientName" aria-label=".form-select-sm example" name='clientId' onChange={handleChange} >
                                        <option value="0">{("Select Client Name")}</option>
                                        {client && client.map((val, ind) => {
                                            return (
                                                <option value={val.id}>{val.clientName}</option>
                                            )

                                        })}
                                    </select>
                                </div>
                                <div className="mb-2 me-2">
                                    <label htmlFor="department" className="form-label">{("Department")}<span className="starMandatory">*</span></label>
                                    {/* <select className="form-select form-select-sm" id="department" aria-label=".form-select-sm example" >
                                        <option value="0">{("Select Department")}</option>
                                        {department && department.map((val, ind) => {
                                            return (
                                                <>

                                                    <option value={val.id}><><input type='checkbox' className='form-control' />{val.departmentName}</></option>

                                                </>
                                            )

                                        })}
                                    </select> */}
                                    {department && <DropdownWithSearch defaulNname={t("Select_Parameter")} name="departmentId" id="departmentname" list={department} valueName="id" displayName="departmentName" editdata={""} getvalue={handleChange} clear={clearDropdown} clearFun={handleClear} isCheckBox={1} checkboxList={selectedIds} />}

                                </div>
                                <div className="mb-2 relative">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                    <div>
                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />

                                                    : <div>
                                                        {updateBool === 0 ?
                                                            <>
                                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={saveForm}><img src={saveButtonIcon} className='icnn' alt='' />{t("Save")}</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt='' />{t("Clear")}</button>
                                                            </>
                                                            :
                                                            <>
                                                                <button type="button" className="btn btn-save btn-sm mb-1 me-1" onClick={saveUpdate}>{t("UPDATE")}</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={() => { setUpdateBool(0); handleClear() }}>Cancel</button>
                                                            </>
                                                        }
                                                    </div>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </BoxContainer>

                        </div>
                        <div className="col-12 mt-3">
                            <div className='handlser'>
                                <Heading text={("Client Department Assign List")} />
                                <div style={{ position: 'relative' }}>
                                    <input type="text" className='form-control form-control-sm' placeholder={t("Search")} onChange={handleSearch} />
                                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
                            <div className="med-table-section" style={{ "height": "74vh" }}>
                                <TableContainer>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>#</th>
                                            <th>{("Client ")}</th>
                                            <th>{("Departments")}</th>
                                            {/* <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th> */}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {clientdepartment && clientdepartment.map((val, ind) => {
                                            return (
                                                <tr key={val.id}>
                                                    <td className="text-center">{ind + 1}</td>
                                                    <td>{val.clientName}</td>
                                                    <td>{val.departmentName}</td>
                                                    {/* <td>

                                                        <div className="action-button">
                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={IconEdit} onClick={() => { handleUpdate(val.id, val.currencyName, val.userId) }} alt='' /></div>
                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={IconDelete} onClick={() => { setRowId(val.id) }} alt='' /></div>

                                                        </div>
                                                    </td> */}
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </TableContainer>
                                {/*  <!------------------- Start Delete Modal ---------------------------------->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">

                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'>{t("Delete?")}</div>
                                                <div className='popDeleteContent'> {t("Are_you_sure_you_want_to_delete?")}</div>
                                            </div>
                                            <div className="modal-footer1 text-center">

                                                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                                                <button type="button" className="btn-delete popBtnDelete" onClick={handleDeleteRow} data-bs-dismiss="modal">{t("Delete")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}
                            </div>

                        </div>


                    </div>
                </div>


            </section>
        </>
    )
}
