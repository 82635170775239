import { useEffect, useState } from "react";
import TimeCalculate from "../../../Code/TimeCalculate";
import LineVitalGrap from "../../../Components/LineVitalGrap";
import GetAllDashboardParameterColumnAssign from "../../../Api/GetAllDashboardParameterColumnAssign";
import { useSelector } from "react-redux";

export default function PatientHRR(props) {
  let [hr, setHr] = useState("");
  let [hrId, setHrId] = useState("");
  let [hrTime, setHrTime] = useState("");
  let [hrColor, setHrColor] = useState("");
  let [uhId, setUhId] = useState("")

  // set grap vital data
  let [grapVitalData, setGrapVitalData] = useState({
    showGrap: 0,
    vitalIdSearchNew: '',
    patientName: '',
    UHID: '',
    userId: ''
  })

  let getData = () => {
    setHr("");
    setHrId("");
    setHrTime("");
    setHrColor("");

    props.val.VitalParametersList && props.val.VitalParametersList.map((vital, ind) => {
      if (vital.VitalID.toString() === "74") {
        setHr(Math.round(vital.VitalValue * 100) / 100)
        setHrColor(vital.VitalColor)
        if (props.handleColor !== undefined) {
          props.handleColor(vital.VitalColor !== null ? vital.VitalColor : "white", props.columnId)
      }

        setHrId(vital.VitalID)
        setUhId(props.val.UhId)
        if (vital.VitalDateTime !== '') {
          setHrTime(TimeCalculate(vital.VitalDateTime))
        }

      }
    })
  }

  let modelCloseFun = () => {
    setGrapVitalData({
      showGrap: 0,
      vitalIdSearchNew: '',
      UHID: '',
      userId: ''
    })
  }
  let [paramenterData, setPatameterData] = useState([])
  let response = useSelector((state) => state.AllColumnsParamters)
  let getColumnsParameters = () => {
    let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
       if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

  }


  useEffect(() => {
    getColumnsParameters()
    getData()
  }, [props.val])

  return (
    <>
      {/* <div className={`overflow-max vitalClass ${(hrColor !== "#008000" && hrColor !== "#94e094" && hrColor !== "#F5F8FF" && hrColor !== "" ? "blink" : "")} `} style={{ border: (hrColor !== "#008000" && hrColor !== "#94e094" && hrColor !== "#F5F8FF" && hrColor !== "" ? "2px solid " + hrColor : "") }}> */}
      <div key={props.key} className={`overflow-max vitalClass`}>

        {hr.length != 0 ? hr ? <span>
          {
            paramenterData && paramenterData.map((val, ind) => {
              if (val.parameterId === 50) {
                return (
                  <>
                    <span style={{ color: hrColor }}>{hr}</span><br />


                  </>

                )
              }

              else if (val.parameterId === 51) {
                return (
                  <>
                    {
                      hrTime.split("-")[1] === "D" || (hrTime.split("-")[0].split(":")[0] >= 2 && hrTime.split("-")[1] === "H") ? <span style={{ 'color': 'red', fontSize: "0.7rem" }} className="blink"><i className="bi bi-clock"></i> {hrTime.toLowerCase().replace("-", "")}</span>

                        : (hrTime.split(":")[0] >= 1 && hrTime.split("-")[1] === "H") ? <span style={{ "color": 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {hrTime.toLowerCase().replace("-", "")}</span>

                          : (hrTime.split("-")[0].split(":")[0] <= 1 && hrTime.split("-")[1] === "H") || (hrTime.split("-")[0] >= 10 && hrTime.split("-")[1] === "M") ? <span style={{ 'color': '#ffa500', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {hrTime.toLowerCase().replace("-", "")}</span> : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {hrTime.toLowerCase().replace("-", "")}</span>}
                    <br />
                  </>

                )
              }

              else if (val.parameterId === 52 && props.from === undefined) {
                return (
                  <>
                    <div className="" title='HR Graph' ><i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapVitalData({ showGrap: 1, vitalIdSearchNew: [hrId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "HR Graph", patientName: props.val.PntName }) }}></i></div>
                    {/* <br /> */}
                  </>

                )
              }


            })

          }

        </span> : 'N/A' : "N/A"}

      </div>
      {grapVitalData.showGrap ? <LineVitalGrap grapVitalData={grapVitalData} modelCloseFun={modelCloseFun} /> : ""}

    </>
  )
}



export function PatientHRRG(props) {
  let [hr, setHr] = useState("");
  let [hrId, setHrId] = useState("");
  let [hrTime, setHrTime] = useState("");
  let [hrColor, setHrColor] = useState("");
  let [uhId, setUhId] = useState("")

  // set grap vital data
  let [grapVitalData, setGrapVitalData] = useState({
    showGrap: 0,
    vitalIdSearchNew: '',
    patientName: '',
    UHID: '',
    userId: ''
  })

  let getData = () => {
    setHr("");
    setHrId("");
    setHrTime("");
    setHrColor("");

    props.val.VitalParametersList && props.val.VitalParametersList.map((vital, ind) => {
      if (vital.VitalID.toString() === "74") {
        setHr(Math.round(vital.VitalValue * 100) / 100)
        setHrColor(vital.VitalColor)
        if (props.setColors !== undefined) {
          let temp = [...props.colors]
          temp.map((val) => {
            if (val.columnId === props.columnId) {
              if (vital.VitalColor !== null) {
                val.data["HR"] = vital.VitalColor
              }
            }

          })

          props.setColors(temp)
        }

        setHrId(vital.VitalID)
        setUhId(props.val.UhId)
        if (vital.VitalDateTime !== '') {
          setHrTime(TimeCalculate(vital.VitalDateTime))
        }

      }
    })
  }

  let modelCloseFun = () => {
    setGrapVitalData({
      showGrap: 0,
      vitalIdSearchNew: '',
      UHID: '',
      userId: ''
    })
  }
  let [paramenterData, setPatameterData] = useState([])
  let response = useSelector((state) => state.AllColumnsParamters)
  let getColumnsParameters = () => {
    let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
       if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

  }


  useEffect(() => {
    getColumnsParameters()
    getData()
  }, [props.val])

  return (
    <>
      {/* <div className={`overflow-max vitalClass ${(hrColor !== "#008000" && hrColor !== "#94e094" && hrColor !== "#F5F8FF" && hrColor !== "" ? "blink" : "")} `} style={{ border: (hrColor !== "#008000" && hrColor !== "#94e094" && hrColor !== "#F5F8FF" && hrColor !== "" ? "2px solid " + hrColor : "") }}> */}
      <div key={props.key} className={`overflow-max vitalClass`}>

        {hr.length != 0 ? hr ? <span>
          {
            paramenterData && paramenterData.map((val, ind) => {


              if (val.parameterId === 52) {
                return (
                  <>
                    <div className="" title='HR Graph' ><i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapVitalData({ showGrap: 1, vitalIdSearchNew: [hrId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "HR Graph", patientName: props.val.PntName }) }}></i></div>
                    {/* <br /> */}
                  </>

                )
              }


            })

          }

        </span> : 'N/A' : "N/A"}

      </div>
      {grapVitalData.showGrap ? <LineVitalGrap grapVitalData={grapVitalData} modelCloseFun={modelCloseFun} /> : ""}

    </>
  )
}