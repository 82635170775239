import React, { useEffect, useState } from 'react'
import Heading from '../../Component/Heading'
import save from "../../assets/images/icons/save.svg"
import PatientDeatils from '../../Component/PatientDeatils'
import InsertICUAdmissionNote from './API/InsertICUAdmissionNote'
import ProvisonalDiagnosisMainComponent from './ProvisonalDiagnosisMainComponent'
import GetICUAdmissionNote from './API/GetICUAdmissionNote'
import AlertToster from '../../Component/AlertToster'
import SuccessToster from '../../Component/SuccessToster'
import Loader from '../../Component/Loader'

export default function ICUAdmissionNotes() {
    const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;

    let [saveDiagnosis, setSaveDiagnosis] = useState(0);
    let [icunNotesList, setICUNotes] = useState([])
    let [loader, setLoader] = useState(0)
    let [showAlertToster, setShowAlertToster] = useState(0)
    let [showAlertMessage, setShowAlertMessage] = useState("")
    let [showToster, setShowtoster] = useState(0)

    let [sendForm, setSendForm] = useState(
        {
            "userId": window.userId,
            "clientId": window.clientId,
            "uhid": uhID,
            "icuAdmisssion": "",
            "presentTreatment": "",
            "pertinentExamination": "",
            "pertinentGeneral": "",
            "pertinentRS": "",
            "pertinentCVS": "",
            "pertinentCNS": "",
            "pertinentEndocrine": "",
            "pertinentHematological": "",
            "pertinentGI": "",
            "pertinentMusculoskeletal": "",
            "pertinentInvestigationReportOnAdmission": "",
            "problemns": "",
            "planOfTreatment": "",
            "crossReferral": "",
            "patientEducationFamilyCounsellingAboutPrognosis": ""
        }
    )
    let handleSave = async () => {
        let temp = { ...sendForm }
        console.log("sendform", sendForm)
        for (const key in sendForm) {
            if (key !== "userId" && key !== "clientId" && key !== "uhid") {
                temp[key] = JSON.stringify([{
                    detailsId: 0,
                    details: sendForm[key],
                    detailsDate: new Date().toISOString().split("T")[0],
                }])
            }
        }


        let response = await InsertICUAdmissionNote(temp)
        if (response.status === 1) {
            setSaveDiagnosis(1)
            handleClear()
        }
        else {

        }
    }
    let getData = async () => {
        setLoader(1)
        let response = await GetICUAdmissionNote(uhID)
        if (response.status === 1) {
            setLoader(0)
            setICUNotes(response.responseValue)
        }
        else {
            setLoader(0)
        }
    }



    let handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        setSendForm((sendForm) => ({ ...sendForm, [name]: value }))
    }


    let handleClear = () => {
        setSendForm({
            "userId": window.userId,
            "clientId": window.clientId,
            "uhid": uhID,
            "icuAdmisssion": "",
            "presentTreatment": "",
            "pertinentExamination": "",
            "pertinentGeneral": "",
            "pertinentRS": "",
            "pertinentCVS": "",
            "pertinentCNS": "",
            "pertinentEndocrine": "",
            "pertinentHematological": "",
            "pertinentGI": "",
            "pertinentMusculoskeletal": "",
            "pertinentInvestigationReportOnAdmission": "",
            "problemns": "",
            "planOfTreatment": "",
            "crossReferral": "",
            "patientEducationFamilyCounsellingAboutPrognosis": ""
        })
    }
    useEffect(() => { getData() }, [])
    return (
        <>
            <Heading text='ICU Admission Notes' id='top' />
            <PatientDeatils />
            <div className="fieldsett-in">
                <div className="fieldsett">
                    <span className='fieldse'>{""}</span>
                    <div className="inner-content mt-2">
                        <div className="row" >
                            <div className="col-sm-6  mt-2">
                                <div className="fieldsett-in">
                                    <div className="fieldsett">
                                        <span className='fieldse'>{"Reason for ICU admission"}</span>
                                        <div className="inner-content mt-2">
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                name="icuAdmisssion"
                                                value={sendForm.icuAdmisssion}
                                                onChange={handleChange}
                                                // onKeyDown={handleKeyDown}
                                                placeholder="Enter Reason for ICU admission"
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-sm-6 mt-2">
                                <div className="fieldsett-in">
                                    <div className="fieldsett">
                                        <span className='fieldse'>{" Co-morbid illnessess and present tratment"}</span>
                                        <div className="inner-content mt-2">
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                name="presentTreatment"
                                                value={sendForm.presentTreatment}
                                                onChange={handleChange}
                                                // onKeyDown={handleKeyDown}
                                                placeholder="Enter Co-morbid illnessess and present tratment"
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                {/* <label htmlFor="Code" className="form-label ">
                                   
                                </label> */}

                            </div>
                            <div className="col-sm-6 mt-2">
                                <div className="fieldsett-in">
                                    <div className="fieldsett">
                                        <span className='fieldse'>{"Pertient examination finding"}</span>
                                        <div className="inner-content mt-2">
                                            <div className='row'>
                                                <div className='col-md-6 col-12'>
                                                    <table className='med-table'>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ padding: "0", margin: "0", width: "0" }}>
                                                                    <label htmlFor="Code" className="form-label">General: </label>
                                                                </td>
                                                                <td >
                                                                    <input id="wardId" type="text" className="form-control form-control-sm" name="pertinentGeneral" value={sendForm.pertinentGeneral} placeholder="Enter General" onChange={handleChange} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td >
                                                                    <label htmlFor="Code" className="form-label">RS: </label>
                                                                </td>
                                                                <td >
                                                                    <input id="wardId" type="text" className="form-control form-control-sm" name="pertinentRS" value={sendForm.pertinentRS} placeholder="Enter RS" onChange={handleChange} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td >
                                                                    <label htmlFor="Code" className="form-label">CVS: </label>
                                                                </td>
                                                                <td >
                                                                    <input id="wardId" type="text" className="form-control form-control-sm" name="pertinentCVS" value={sendForm.pertinentCVS} placeholder="Enter CVS" onChange={handleChange} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td >
                                                                    <label htmlFor="Code" className="form-label">CNS: </label>
                                                                </td>
                                                                <td >
                                                                    <input id="wardId" type="text" className="form-control form-control-sm" name="pertinentCNS" value={sendForm.pertinentCNS} placeholder="Enter CNS" onChange={handleChange} />
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className='col-md-6 col-12'>
                                                    <table className='med-table'>
                                                        <tbody>
                                                            <tr>
                                                                <td >
                                                                    <label htmlFor="Code" className="form-label">Endocrine: </label>
                                                                </td>
                                                                <td >
                                                                    <input id="wardId" type="text" className="form-control form-control-sm" name="pertinentEndocrine" value={sendForm.pertinentEndocrine} placeholder="Enter Endocrine" onChange={handleChange} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <label htmlFor="Code" className="form-label">Hematological: </label>
                                                                </td>
                                                                <td >
                                                                    <input id="wardId" type="text" className="form-control form-control-sm" name="pertinentHematological" value={sendForm.pertinentHematological} placeholder="Enter Hematological" onChange={handleChange} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <label htmlFor="Code" className="form-label">GI: </label>
                                                                </td>
                                                                <td >
                                                                    <input id="wardId" type="text" className="form-control form-control-sm" name="pertinentGI" value={sendForm.pertinentGI} placeholder="Enter GI" onChange={handleChange} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <label htmlFor="Code" className="form-label">Musculoskeletal: </label>
                                                                </td>
                                                                <td >
                                                                    <input id="wardId" type="text" className="form-control form-control-sm" name="pertinentMusculoskeletal" value={sendForm.pertinentMusculoskeletal} placeholder="Enter Musculoskeletal" onChange={handleChange} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <label htmlFor="Code" className="form-label pb-2">
                                   
                                </label> */}

                            </div>
                            <div className="col-sm-6 mt-2">
                                <div className="fieldsett-in">
                                    <div className="fieldsett">
                                        <span className='fieldse'>{"Problems"}</span>
                                        <div className="inner-content mt-2">
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                name="problemns"
                                                value={sendForm.problemns}
                                                onChange={handleChange}
                                                // onKeyDown={handleKeyDown}
                                                placeholder="Enter Problemns"
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="fieldsett-in">
                                    <div className="fieldsett">
                                        <span className='fieldse'>{"Working Diagnosis"}</span>
                                        <div className="inner-content mt-2 ">
                                            <ProvisonalDiagnosisMainComponent
                                                isProvisional={0}
                                                saveDiagnosis={saveDiagnosis}
                                                setSaveDiagnosis={setSaveDiagnosis}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 mt-2">
                                <div className="fieldsett-in">
                                    <div className="fieldsett">
                                        <span className='fieldse'>{"Cross Referral"}</span>
                                        <div className="inner-content mt-2">
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                name="crossReferral"
                                                value={sendForm.crossReferral}
                                                onChange={handleChange}
                                                // onKeyDown={handleKeyDown}
                                                placeholder="Enter Reason for ICU admission"
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="col-sm-6 mt-2">
                                <div className="fieldsett-in">
                                    <div className="fieldsett">
                                        <span className='fieldse'>{"Patient education & family Consuling about prognosis"}</span>
                                        <div className="inner-content mt-2">
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                name="patientEducationFamilyCounsellingAboutPrognosis"
                                                value={sendForm.patientEducationFamilyCounsellingAboutPrognosis}
                                                onChange={handleChange}
                                                // onKeyDown={handleKeyDown}
                                                placeholder="Enter Patient education & family Consuling about prognosis"
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-end mt-3  gap-3 '>
                    <button className='saveprintopd ps-3 pe-3 btn-save-fill' onClick={handleSave} >
                        <img src={save} className='icnn' alt='' /> Save
                    </button>
                    <button className='saveprintopd ps-3 pe-3 btn-save-fill' onClick={"handleClear"} >
                        {/* <img src={save} className='icnn' alt='' />  */}
                        Clear
                    </button>
                </div>
            </div>

            <div className="med-table-section mt-3" style={{ height: "30vh" }}>
                <Heading text='ICU Admission Notes List' id='top' />

                <table className="med-table border_ striped">
                    <thead style={{ zIndex: "0" }}>
                    </thead>
                    <tbody>
                        <tr>
                            <th className="text-center" style={{ width: "5%" }}>
                                #
                            </th>
                            <th>
                                Reason for ICU admission
                            </th>
                            <th>
                                Co-morbid illnessess and present tratment
                            </th>
                            <th>
                                CNS
                            </th>
                            <th>
                                CVS
                            </th>
                            <th>
                                Endocrine
                            </th>
                            <th>
                                Examination
                            </th>
                            <th>
                                GI
                            </th>
                            <th>
                                General
                            </th>
                            <th>
                                Hematological
                            </th>
                            <th>
                                RS
                            </th>
                            <th>
                                Working Diagnosis
                            </th>
                            <th>
                                Cross Referral
                            </th>
                            <th>
                                Patient education & family Consuling about prognosis
                            </th>
                            <th>
                                Action
                            </th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            icunNotesList && icunNotesList.map((val, ind) => {
                                return (
                                    <tr>
                                        <td className="text-center" style={{ width: "5%" }}>
                                            {ind + 1}
                                        </td>
                                        <td>
                                            {
                                                JSON.parse(val.icuAdmisssionJson !== "" ? val.icuAdmisssionJson : "[{}]")[0].details
                                            }
                                        </td>
                                        <td>
                                            {
                                                JSON.parse(val.presentTreatment !== "" ? val.presentTreatment : "[{}]")[0].details
                                            }
                                        </td>
                                        <td>
                                            {
                                                JSON.parse(val.pertinentCNS !== "" ? val.pertinentCNS : "[{}]")[0].details
                                            }
                                        </td>
                                        <td>
                                            {
                                                JSON.parse(val.pertinentCVS !== "" ? val.pertinentCVS : "[{}]")[0].details
                                            }
                                        </td>
                                        <td>
                                            {
                                                JSON.parse(val.pertinentEndocrine !== "" ? val.pertinentEndocrine : "[{}]")[0].details
                                            }
                                        </td>
                                        <td>
                                            {
                                                JSON.parse(val.pertinentExamination !== "" ? val.pertinentExamination : "[{}]")[0].details
                                            }
                                        </td>
                                        <td>
                                            {
                                                JSON.parse(val.pertinentGI !== "" ? val.pertinentGI : "[{}]")[0].details
                                            }
                                        </td>
                                        <td>
                                            {
                                                JSON.parse(val.problemnsJson !== "" ? val.problemnsJson : "[{}]")[0].details
                                            }
                                        </td>
                                        <td>
                                            {
                                                JSON.parse(val.pertinentGeneral !== "" ? val.pertinentGeneral : "[{}]")[0].details
                                            }
                                        </td>
                                        <td>
                                            {
                                                JSON.parse(val.pertinentHematological !== "" ? val.pertinentHematological : "[{}]")[0].details
                                            }
                                        </td>
                                        <td>
                                            {
                                                JSON.parse(val.pertinentRS !== "" ? val.pertinentRS : "[{}]")[0].details
                                            }
                                        </td>

                                        <td>
                                            {
                                                JSON.parse(val.pertinentInvestigationReportOnAdmissionJson !== "" ? val.pertinentInvestigationReportOnAdmissionJson : "[{}]")[0].details
                                            }
                                        </td>
                                        
                                        

                                        <td>
                                            {
                                                JSON.parse(val.crossReferralJson !== "" ? val.crossReferralJson : "[{}]")[0].details
                                            }
                                        </td>
                                        <td>
                                            {
                                                JSON.parse(val.patientEducationFamilyCounsellingAboutPrognosisJson !== "" ? val.patientEducationFamilyCounsellingAboutPrognosisJson : "[{}]")[0].details
                                            }
                                        </td>

                                        <td>
                                            Action
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

            {
                showAlertToster === 1 ? <AlertToster handle={setShowAlertToster} message={showAlertMessage} /> : ""
            }
            {
                showToster === 1 ? <SuccessToster handle={setShowtoster} message={"Data Save Successfully!!"} /> : ""
            }
            <Loader val={loader} />
        </>
    )
}
