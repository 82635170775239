let GetMenuList = async (headID)=>{
    let url= window.AdminbaseUrl+'/api/MenuMaster/GetAllMenuMaster';
    let head={'Content-Type':'application/json','accept':'*/*',}
    let response ="";
    await fetch(url,{
        method:'GET',
        headers:head
    }).then((res)=> res.json()).then(data=>{response=data}).catch(error=>{response=error});
    return response;
}
export default GetMenuList;