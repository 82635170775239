export default async function GetAllPatientConsentData(ConsentId, uhid){    
   
    let URL = window.AppbaseUrl + `/api/PatientConsentData/GetAllPatientConsentData?ConsentID=${ConsentId}&clientID=${window.clientId}&Uhid=${uhid}`;  
   let head = {"Content-Type":"application/json", 'accept':'*/*'};
   
   let response = "";
   response = await fetch(URL, {
       headers:head,
       method:"GET"        
   })
   .then(res => res.json())  
   .catch(error => response=error);    
   return response;
}