

  let GetBloodGroup = async() => {
  
    let URL = window.BloodbaseUrl+'/api/BloodGroup/GetAllBloodGroup'; 
      const head = {'content-type':'application/json','accept':'*/*',}
      let data = {};
      let response = await fetch(URL,{
        method: "GET",
        header: head,
      }).then(res => res.json()).then(data);
      return response;
    }
    export default  GetBloodGroup;
  