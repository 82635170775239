import React, { useEffect, useState } from 'react'
import LineInvestigationGrap from '../../../Components/LineInvestigationGrap';
import TimeCalculate from '../../../Code/TimeCalculate';
import GetAllDashboardParameterColumnAssign from '../../../Api/GetAllDashboardParameterColumnAssign';
import { useSelector } from 'react-redux';

export default function PCO2R(props) {
    let [pco2, setPco2] = useState("");
    let [pco2Id, setPco2Id] = useState("");
    let [pco2Time, setPco2Time] = useState("");
    let [pco2Color, setPco2Color] = useState("");

    let [uhId, setUhId] = useState("")

    // set grap Investigation data
    let [grapInvestigationData, setGrapInvestigationData] = useState({
        showGrap: 0,
        subTestId: '',
        patientName: '',
        UHID: '',
        userId: ''
    })

    let getData = () => {
        setPco2("");
        setPco2Id("");
        setPco2Time("");
        setPco2Color("");

        props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
            if (investigation.SubTestID.toString() === "608") {
                setPco2(Math.round(investigation.SubTestValue * 100) / 100)
                setPco2Color(investigation.SubTestColor)
                if (props.handleColor !== undefined) {
                    props.handleColor(investigation.SubTestColor !== null ? investigation.SubTestColor : "white", props.columnId)
                }

                setPco2Id(investigation.SubTestID)
                setUhId(props.patientData.UhId)

                if (investigation.SubTestDateTime !== '') {
                    setPco2Time(TimeCalculate(investigation.SubTestDateTime))


                }

            }
        })
    }

    let modelCloseFun = () => {

        setGrapInvestigationData({
            showGrap: 0,
            subTestId: '',
            patientName: '',
            UHID: '',
            userId: ''
        })
    }

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
        if (resp.length !== 0) {
            setPatameterData(resp[0].data)
        }

    }

    useEffect(() => {
        getColumnsParameters()

        getData()
    }, [props.patientData])
    return (
        <>
            {/* <div key={props.key} className={`overflow-max investigations ${(pco2Color !== "#008000" && pco2Color !== "#94e094" && pco2Color !== "#F5F8FF" && pco2Color !== "" ? "blink" : "")} `} style={{ border: (pco2Color !== "#008000" && pco2Color !== "#94e094" && pco2Color !== "#F5F8FF" && pco2Color !== "" ? "2px solid " + pco2Color : "") }}> */}
            <div key={props.key} className={`overflow-max investigations `} >

                {pco2.length != 0 ? pco2 ? <span>
                    {
                        paramenterData && paramenterData.map((val, ind) => {
                            if (val.parameterId === 74) {
                                return (
                                    <>
                                        <span style={{ color: pco2Color }}>{pco2}</span><br />


                                    </>

                                )
                            }

                            else if (val.parameterId === 75) {
                                return (
                                    <>
                                        {
                                            pco2Time.split("-")[1] === "D" && pco2Time.split("-")[0] >= 4 ? <span style={{ 'color': 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {pco2Time.toLowerCase().replace("-", "")}</span>
                                                : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {pco2Time.toLowerCase().replace("-", "")}</span>}
                                        <br />
                                    </>

                                )
                            }

                            else if (val.parameterId === 76 && props.from === undefined) {
                                return (
                                    <>
                                        <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='PCO2 Graph' >
                                            {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                                                if (val.SubtestID === pco2Id) {
                                                    return (
                                                        <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                                                    )
                                                }
                                            })}
                                            <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [pco2Id], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "PCO2 Graph", patientName: props.patientData.PntName }) }}></i></div>

                                        {/* <br /> */}
                                    </>

                                )
                            }


                        })

                    }


                    {/* <span>{pco2_time}</span> */}
                </span> : 'N/A' : "N/A"}

            </div>
            {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}
        </>
    )
}



export function PCO2RG(props) {
    let [pco2, setPco2] = useState("");
    let [pco2Id, setPco2Id] = useState("");
    let [pco2Time, setPco2Time] = useState("");
    let [pco2Color, setPco2Color] = useState("");

    let [uhId, setUhId] = useState("")

    // set grap Investigation data
    let [grapInvestigationData, setGrapInvestigationData] = useState({
        showGrap: 0,
        subTestId: '',
        patientName: '',
        UHID: '',
        userId: ''
    })
    // let patientMonitorDashboard = useSelector((state) => state.PatientMonitorDashboard.alertData)


    let getData = () => {
        setPco2("");
        setPco2Id("");
        setPco2Time("");
        setPco2Color("");

        props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
            if (investigation.SubTestID.toString() === "608") {
                setPco2(Math.round(investigation.SubTestValue * 100) / 100)
                setPco2Color(investigation.SubTestColor)
                if (props.setColors !== undefined) {
                    props.setColors((prevArray) => ({ ...prevArray, "PCO2": investigation.SubTestColor }))
                }

                setPco2Id(investigation.SubTestID)
                setUhId(props.patientData.UhId)

                if (investigation.SubTestDateTime !== '') {
                    setPco2Time(TimeCalculate(investigation.SubTestDateTime))


                }

            }

        })
    }

    let modelCloseFun = () => {

        setGrapInvestigationData({
            showGrap: 0,
            subTestId: '',
            patientName: '',
            UHID: '',
            userId: ''
        })
    }

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
        if (resp.length !== 0) {
            setPatameterData(resp[0].data)
        }

    }

    useEffect(() => {
        getColumnsParameters()

        getData()
    }, [props.patientData.UhId])
    return (
        <>
            {/* <div key={props.key} className={`overflow-max investigations ${(pco2Color !== "#008000" && pco2Color !== "#94e094" && pco2Color !== "#F5F8FF" && pco2Color !== "" ? "blink" : "")} `} style={{ border: (pco2Color !== "#008000" && pco2Color !== "#94e094" && pco2Color !== "#F5F8FF" && pco2Color !== "" ? "2px solid " + pco2Color : "") }}> */}
            <div key={props.key} className={`overflow-max investigations  `}>
                {pco2 !== "" ? pco2 ? <span>
                    {
                        paramenterData && paramenterData.map((val, ind) => {


                            if (val.parameterId === 76) {
                                return (
                                    <>
                                        <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='PCO2 Graph' >
                                            {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                                                if (val.SubtestID === pco2Id) {
                                                    return (
                                                        <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                                                    )
                                                }
                                            })}
                                            <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [pco2Id], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "PCO2 Graph", patientName: props.patientData.PntName }) }}></i></div>

                                        {/* <br /> */}
                                    </>

                                )
                            }


                        })

                    }


                    {/* <span>{pco2_time}</span> */}
                </span> : 'N/A' : "N/A"}

            </div>
            {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}
        </>
    )
}