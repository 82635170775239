async function GetAllSavedPastHistory(ClientId, UHID) {

    let url = window.AppbaseUrl + `/api/PatientInitialAssessment/GetAllSavedPastHistory?ClientId=${ClientId}&UHID=${UHID}`;
    let head = { "Content-Type": "application/JSON", accept: '*/*' };
    let data = {}
    let response = fetch(url, {
        headers: head,
        method: 'GET'
    })
        .then((res) => res.json())
        .then(data);

    return response;
}
export default GetAllSavedPastHistory;
