import React, { useEffect, useState } from 'react'
import LineInvestigationGrap from '../../../Components/LineInvestigationGrap';
import TimeCalculate from '../../../Code/TimeCalculate';
import GetAllDashboardParameterColumnAssign from '../../../Api/GetAllDashboardParameterColumnAssign';
import { useSelector } from 'react-redux';

export default function HCO3R(props) {

  let [hco3, setHco3] = useState("");
  let [hco3Id, setHco3Id] = useState("");
  let [hco3Time, setHco3Time] = useState("");
  let [hco3Color, setHco3Color] = useState("");


  let [uhId, setUhId] = useState("")

  // set grap Investigation data
  let [grapInvestigationData, setGrapInvestigationData] = useState({
    showGrap: 0,
    subTestId: '',
    patientName: '',
    UHID: '',
    userId: ''
  })

  let getData = () => {
    setHco3("");
    setHco3Id("");
    setHco3Time("");
    setHco3Color("");

    props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
      if (investigation.SubTestID.toString() === "617") {
        setHco3(Math.round(investigation.SubTestValue * 100) / 100)
        setHco3Color(investigation.SubTestColor)
        if (props.handleColor !== undefined) {
          props.handleColor(investigation.SubTestColor !== null ? investigation.SubTestColor : "white", props.columnId)
        }



        setHco3Id(investigation.SubTestID)
        setUhId(props.patientData.UhId)
        if (investigation.SubTestDateTime !== '') {
          setHco3Time(TimeCalculate(investigation.SubTestDateTime))


        }

      }
    })
  }

  let modelCloseFun = () => {
    setGrapInvestigationData({
      showGrap: 0,
      subTestId: '',
      patientName: '',
      UHID: '',
      userId: ''
    })
  }


  let [paramenterData, setPatameterData] = useState([])
  let response = useSelector((state) => state.AllColumnsParamters)
  let getColumnsParameters = () => {
    let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
       if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

  }



  useEffect(() => {
    getColumnsParameters()

    getData()
  }, [props.patientData])
  return (
    <>
      {/* <div key={props.key} className={`overflow-max investigations ${(hco3Color !== "#008000" && hco3Color !== "#94e094" && hco3Color !== "#F5F8FF" && hco3Color !== "" ? "blink" : "")} `} style={{ border: (hco3Color !== "#008000" && hco3Color !== "#94e094" && hco3Color !== "#F5F8FF" && hco3Color !== "" ? "2px solid " + hco3Color : "") }}> */}
      <div key={props.key} className={`overflow-max investigations `}>

        {hco3.length != 0 ? hco3 ? <span>
          {
            paramenterData && paramenterData.map((val, ind) => {
              if (val.parameterId === 86) {
                return (
                  <>
                    <span style={{ color: hco3Color }}>{hco3}</span><br />



                  </>

                )
              }

              else if (val.parameterId === 87) {
                return (
                  <>
                    {
                      hco3Time.split("-")[1] === "D" && hco3Time.split("-")[0] >= 4 ? <span style={{ 'color': 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {hco3Time.toLowerCase().replace("-", "")}</span>
                        : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {hco3Time.toLowerCase().replace("-", "")}</span>}
                    <br />
                  </>

                )
              }

              else if (val.parameterId === 88 && props.from === undefined) {
                return (
                  <>
                    <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='HCO3 Graph' >
                      {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                        if (val.SubtestID === hco3Id) {
                          return (
                            <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                          )
                        }
                      })}
                      <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [hco3Id], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "HCO3 Graph", patientName: props.patientData.PntName }) }}></i></div>

                    {/* <br /> */}
                  </>

                )
              }


            })

          }


          {/* <span>{hco3_time}</span> */}
        </span> : 'N/A' : 'N/A'}
      </div>
      {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}
    </>
  )
}


export function HCO3RG(props) {

  let [hco3, setHco3] = useState("");
  let [hco3Id, setHco3Id] = useState("");
  let [hco3Time, setHco3Time] = useState("");
  let [hco3Color, setHco3Color] = useState("");


  let [uhId, setUhId] = useState("")

  // set grap Investigation data
  let [grapInvestigationData, setGrapInvestigationData] = useState({
    showGrap: 0,
    subTestId: '',
    patientName: '',
    UHID: '',
    userId: ''
  })

  let getData = () => {
    setHco3("");
    setHco3Id("");
    setHco3Time("");
    setHco3Color("");

    props.patientData.InvestigationParameterList && props.patientData.InvestigationParameterList.map((investigation, ind) => {
      if (investigation.SubTestID.toString() === "617") {
        setHco3(Math.round(investigation.SubTestValue * 100) / 100)
        setHco3Color(investigation.SubTestColor)
        if (props.setColors !== undefined) {
          props.setColors((prevArray) => ({ ...prevArray, "HCO3": investigation.SubTestColor }))
        }

        setHco3Id(investigation.SubTestID)
        setUhId(props.patientData.UhId)
        if (investigation.SubTestDateTime !== '') {
          setHco3Time(TimeCalculate(investigation.SubTestDateTime))


        }

      }
    })
  }

  let modelCloseFun = () => {
    setGrapInvestigationData({
      showGrap: 0,
      subTestId: '',
      patientName: '',
      UHID: '',
      userId: ''
    })
  }


  let [paramenterData, setPatameterData] = useState([])
  let response = useSelector((state) => state.AllColumnsParamters)
  let getColumnsParameters = () => {
    let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
       if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

  }



  useEffect(() => {
    getColumnsParameters()

    getData()
  }, [props.patientData])
  return (
    <>
      <div key={props.key} className={`overflow-max investigations ${(hco3Color !== "#008000" && hco3Color !== "#94e094" && hco3Color !== "#F5F8FF" && hco3Color !== "" ? "blink" : "")} `} style={{ border: (hco3Color !== "#008000" && hco3Color !== "#94e094" && hco3Color !== "#F5F8FF" && hco3Color !== "" ? "2px solid " + hco3Color : "") }}>

        {hco3.length != 0 ? hco3 ? <span>
          {
            paramenterData && paramenterData.map((val, ind) => {

              if (val.parameterId === 88) {
                return (
                  <>
                    <div className="d-flex flex-row gap-2 justify-content-center pt-1" title='HCO3 Graph' >
                      {props.patientData.PatientFluidBottleList && props.patientData.PatientFluidBottleList.map((val, ind) => {
                        if (val.SubtestID === hco3Id) {
                          return (
                            <i className='fa-solid fa-bottle-droplet ' style={{ color: "#75eafd", fontSize: "16px" }}></i>
                          )
                        }
                      })}
                      <i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapInvestigationData({ showGrap: 1, subTestId: [hco3Id], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "HCO3 Graph", patientName: props.patientData.PntName }) }}></i></div>

                    {/* <br /> */}
                  </>

                )
              }


            })

          }


          {/* <span>{hco3_time}</span> */}
        </span> : 'N/A' : 'N/A'}
      </div>
      {grapInvestigationData.showGrap ? <LineInvestigationGrap grapInvestigationData={grapInvestigationData} modelCloseFun={modelCloseFun} /> : ""}
    </>
  )
}
