import React, { useEffect, useRef, useState } from "react";
import Heading from "../../Component/Heading";
import BoxContainer from "../../Component/BoxContainer";
import TableContainer from "../../Component/TableContainer";
import save from "../../assets/images/icons/save.svg";
import reset from "../../assets/images/icons/reset.svg";
import transfer from "../../assets/images/icons/transfer.svg";
import del from "../../assets/images/icons/del.svg";
import editbtn from "../../assets/images/icons/editbtn.svg";
import delbtn from "../../assets/images/icons/delbtn.svg";
import calender from "../../assets/images/icons/calender.svg";
import clock from "../../assets/images/icons/clock.svg";
import dish from "../../assets/images/icons/dish.svg";
import quantity from "../../assets/images/icons/quantity.svg";
import unitIcon from "../../assets/images/icons/unit.svg";
import { async } from "q";
import GetFoodList from "../API/FoodIntake/GetFoodList";
import GetUnitList from "../API/FoodIntake/GetUnitList";
import DropdownWithSearch from "../../Component/DropdownWithSearch";
import GetFoodIntakeList from "../API/FoodIntake/GetFoodIntakeList";
import SaveFoodIntake from "../API/FoodIntake/SaveFoodIntake";
import TosterUnderProcess from "../../Component/TosterUnderProcess";
import Toster from "../../Component/Toster";
import DieteticsFoodIntakeValidation from "../API/FoodIntake/DieteticsFoodIntakeValidation";
import Search from "../../Code/Serach";
import PatientTabs from "./PatientTabs";
import Loader from "../../Component/Loader";
import GetFoodName from "../API/FoodIntake/GetFoodName";
import DeleteFoodIntake from "../API/FoodIntake/DeleteFoodIntake";
import SuccessToster from "../../Component/SuccessToster";
import AlertToster from "../../Component/AlertToster";

export default function FoodIntake() {
  let [foodList, setFoodList] = useState();
  let [unitList, setUnitList] = useState();
  let [foodIntakeList, setFoodIntakeList] = useState([]);
  let [foodIntakeListtemp, setFoodIntakeListtemp] = useState([]);
  let [txtDate, settxtDate] = useState();
  let [time, setTime] = useState('');
  let [foodTempList, setFoodTempList] = useState([]);
  let [rowID, setRowID] = useState('');
  let [searchByNameList, setSearchByNameList] = useState([])

  const [selectedFood, setSelectedFood] = useState('');
  let [searchByName, setSearchByName] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  let [currentdatemax, setcurrentdatemax] = useState();

  let [Quantity, setQuantity] = useState();
  let [food, setFood] = useState();
  let [unit, setUnit] = useState();
  let [showUnderProcess, setShowUnderProcess] = useState(0);
  let [showToster, setShowToster] = useState(0);
  let [tosterValue, setTosterValue] = useState(0);
  let [tosterMessage, setTosterMessage] = useState("");
  let [clearDropdown, setClearDropdown] = useState(0);
  let [loader, setLoader] = useState(0);
  const containerRef = useRef(null);
  let [filteredNameList, setFilteredNameList] = useState([]);
  let [showSuccessToster, setShowSuccessToster] = useState(0)
  let [showMessage, setShowMeassage] = useState("");
  let [showAlertToster, setShowAlertToster] = useState(0);
  // let [foodListTemp, setFoodListTemp ]= useState();
  // let [showSearchBoxProblem, setShowSearchBoxProblem] = useState(-1)

  let getFoodList = async () => {
    let foodList = await GetFoodList();
    if (foodList.status === 1) {
      setFoodList(foodList.responseValue);
    }
  };
  let getAllFood = async (query) => {
    const response = await GetFoodName(query);
    if (response.status === 1) {
      setSearchByNameList(response.responseValue);
      setFoodTempList(response.responseValue.slice(0, 15));
    }
  }
  let getFoodIntakeList = async (txtDate) => {
    let date = new Date()
    var dd = date.getDate();
    var mm = date.getMonth() + 1; //January is 0!
    var yyyy = date.getFullYear();

    const txtGetDate = document.getElementById("txtDate").value || yyyy + "-" + mm + "-" + dd;
    console.log("txtGetDate", txtDate);
    let uhid = window.sessionStorage.getItem("activeUHIDdiet") ? JSON.parse(window.sessionStorage.getItem("activeUHIDdiet")).Uhid : JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid;
    let entryType = 'D'
    setLoader(1)

    let foodIntakeList = await GetFoodIntakeList(uhid, txtGetDate, entryType);
    if (foodIntakeList.status === 1) {
      setFoodIntakeList(foodIntakeList.foodIntakeList);
      setFoodIntakeListtemp(foodIntakeList.foodIntakeList);
      console.log("ff", foodIntakeList);
      setLoader(0)

    }
    else {
      setFoodIntakeListtemp([]); // Set empty array when no data is found\
      setLoader(0)

    }
  };
  // let getUnitList = async() =>{
  //   let unitList = await GetUnitList();
  //   if (unitList.status===1){
  //     setUnitList(unitList.responseValue);
  //     console.log('un',unitList);
  //   }
  // }
  let getData = async (value) => {
    let unitList = await GetUnitList(value);
    console.log("cdcsd", unitList);
    if (unitList.status === 1) {
      console.log("uni", unitList);
      setUnitList(unitList.responseValue);
    }
    else {

    }
  }

  // Function to update the current time
  const updateCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;
    setTime(formattedTime);
  };

  let handleChange = (e) => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const time = `${hours}:${minutes}`;
    clearError(1);
    let value = e.target.value
    let name = e.target.name
    if (name === "txtDate") {
      settxtDate(value)
      getFoodIntakeList(value);
      console.log('txtDateee', value);
    }
    else if (name === "time") {
     
        setTime(e.target.value)

    }
    else if (name === "quantity") {
      if (/^\d{0,4}$/.test(e.target.value)) {
        setQuantity(value);
      }
    }
    else if (name === "unit") {
      setUnit(value);
    }
  }
  let clear = (value) => {
    getDate();
    setQuantity("");
    setClearDropdown(value);
    setSelectedFood('')
    setSearchByName('')
    setFood('');
    document.getElementById("time").value = "";
    document.getElementById("unit").value = 0;
    clearError();
    document.getElementById('ddlDataContainers').style.display = 'none'

    //  document.getElementById('foodName').value = 0;
    // document.getElementById('ddlUnitList').value = 0;
  };
  let clearError = async () => {
    document.getElementById("errDate").style.display = "none";
    document.getElementById("errTime").style.display = "none";
    document.getElementById("errFood").style.display = "none";
    document.getElementById("errQuantity").style.display = "none";
    document.getElementById("errUnit").style.display = "none";
  };
  let handleChangeDropdown = (e) => {
    clearError();
    let value = e.target.value;
    let name = e.target.name;
    getData(value);
    setFood(value);
  };
  let getDate = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    today = yyyy + "-" + mm + "-" + dd;
    console.log(today);
    document.getElementById("txtDate").value = today;
  };

  // SAVE FOOD INTAKE
  let saveFoodIntake = async () => {
    clearError();
    const txtGetDate = document.getElementById("txtDate").value;
    console.log("txtDate", txtGetDate);
    //for validation
    const isValidateFood = DieteticsFoodIntakeValidation(
      txtGetDate,
      time,
      food,
      Quantity,
      unit
    );
    console.log("fa", isValidateFood);
    var id = isValidateFood[1];
    if (isValidateFood === true) {
      let foodObj = {
        givenFoodDate: txtGetDate + " " + time,
        givenFoodQuantity: Quantity,
        foodId: food,
        entryType: "D",
        givenFoodUnitID: unit,
        uhid: window.sessionStorage.getItem("activeUHIDdiet") ? JSON.parse(window.sessionStorage.getItem("activeUHIDdiet")).Uhid : JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid,
        recommendedUserID: window.userId,
      };
      // setLoader(1)
      setShowUnderProcess(1);
      let data = await SaveFoodIntake(foodObj);
      if (data.status === 1) {
        setShowUnderProcess(0);
        setTosterValue(0); //0 for save and warning 1 for Error
        setShowToster(1);
        clear(1);
        setTosterMessage("Data Saved Successfully!");
        getFoodIntakeList();
        setTimeout(() => {
          setShowToster(0);

        }, 2000)
        // setLoader(0)


      }
      else {
        setShowUnderProcess(0)
        setShowToster(1)//0 for save and warning 1 for Error
        setTosterMessage(data.responseValue)
        setTosterValue(1)
        clear(1);
        setTimeout(() => {
          setShowToster(0)
        }, 2000)
        setLoader(0)

      }
    } else {
      document.getElementById(id).style.display = "block";
      document.getElementById(id).innerHTML = isValidateFood[0];
    }
  };

  const handleDelete = async () => {
    const param = {
      Uhid: window.sessionStorage.getItem("activeUHIDdiet") ? JSON.parse(window.sessionStorage.getItem("activeUHIDdiet")).Uhid : JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid,
      foodId: rowID
    }
    console.log("param", param)
    // return
    const response = await DeleteFoodIntake(param)
    if (response.status === 1) {
      setShowSuccessToster(1);
      setShowMeassage("Food Deleted Succefully")
      getFoodIntakeList();
      setTimeout(() => {
        setShowSuccessToster(0)
      }, 2000)
    }
    else {
      setShowAlertToster(1)
      setShowMeassage(response.responseValue)
      setTosterValue(1)
      setTimeout(() => {
        setShowAlertToster(0)
      }, 2000)
    }
  }
  let handleSearch = (e) => {
    if (e.target.value !== "") {
      let result = Search(foodIntakeList, e.target.value);
      if (result.length != 0) {
        setFoodIntakeListtemp(result);
      }
      // else {
      //     setPatientListTemp(patientList)
      // }
    } else {
      setFoodIntakeListtemp(foodIntakeList);
    }
  };
  const handleKeyDown = (event) => {
    // Check if the pressed key is a valid digit or a minus sign
    const isValidKey = /^\d$/.test(event.key) || (event.key === '-' && event.target.selectionStart === 0);
    const isBackspace = event.key === 'Backspace';
    // If the pressed key is not valid, prevent the default behavior
    if (!isValidKey && !isBackspace) {
      event.preventDefault();
    }
  };
  const handleSelectFood = (param) => {
    console.log("param", param)
    setSelectedFood(param.foodName);
    getData(param.foodID);
    setFood(param.foodID);
    document.getElementById('ddlDataContainers').style.display = "none";
    document.getElementById("errFood").style.display = "none";

  }
  const fetchFoodData = () => {
    if (!isLoading && foodTempList.length < searchByNameList.length) { // Check if not already loading and there is more data to fetch
      setIsLoading(true);
      const startIndex = foodTempList.length;
      const endIndex = Math.min(startIndex + 15, searchByNameList.length);
      const newData = searchByNameList.slice(startIndex, endIndex);
      console.log('newData food ->', newData)
      setFoodTempList(prevData => [...prevData, ...newData]);
      setIsLoading(false);
    }
  };
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 20 && !isLoading) {
      fetchFoodData();
    }

  };

  let curdate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    setcurrentdatemax(formattedDate)
    document.getElementById('txtDate').value = formattedDate;
    return formattedDate;
  };
  useEffect(() => {
    curdate();
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [containerRef]);

  const handleSearchFoodName = (searchValue) => {
    setSelectedFood('');
    setSearchByName(searchValue);
    let ddlDataContainerValue = document.getElementById('ddlDataContainers');
    ddlDataContainerValue.style.display = searchValue.length < 3 ? 'none' : 'block';
    if (searchValue.length >= 2) {
      const data = searchByNameList;
      const filteredData = data.filter((val, i, arr) => {
        var tempData = val.foodName.toLowerCase().includes(searchValue.toLowerCase());
        return tempData;
      });
      setFilteredNameList(filteredData);
      getAllFood(searchValue);
    } else {
      setFilteredNameList([]);
    }

  }
  useEffect(() => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    getFoodList();
    // getFoodIntakeList();
    getFoodIntakeList(yyyy + "-" + mm + "-" + dd);
    getDate();
    updateCurrentTime();
  }, []);
  return (
    <>
      <div className="main-content pt-3 mt-5">
        <div className="container-fluid">
          <PatientTabs />

          <div className="row">
            <div className="col-12">
              <div className="fieldsett-in">
                <div className="fieldsett">
                  <span className="fieldse">Recommended Food</span>
                  <BoxContainer>
                    <div className="mb-2 me-2">
                      <img src={calender} className="icnn" />{" "}
                      <label htmlFor="txtDate" className="form-label">
                        Date
                        <span className="starMandatory">*</span>
                      </label>
                      <input
                        type="date"
                        value={txtDate}
                        className="form-control form-control-sm"
                        id="txtDate"
                        name="txtDate"
                        placeholder="Enter Date"
                        // max={currentdatemax}
                        onChange={handleChange}
                      />
                      <small
                        id="errDate"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>
                    <div className="mb-2 me-2">
                      <img src={clock} className="icnn" />{" "}
                      <label htmlFor="time" className="form-label">
                        Time
                        <span className="starMandatory">*</span>
                      </label>
                      <input
                        type="time"
                        className="form-control form-control-sm"
                        id="time"
                        name="time"
                        value={time}
                        placeholder="Enter Time"
                        onChange={handleChange}
                      />
                      <small
                        id="errTime"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>

                    <div className="mb-2 me-2">
                      <img src={dish} className="icnn" />{" "}
                      <label htmlFor="dish" className="form-label">
                        Food
                        <span className="starMandatory">*</span>
                      </label>
                      <div className="mb-2 me-2">
                        <input type="text" value={selectedFood === '' ? searchByName : selectedFood} onChange={(e) => { handleSearchFoodName(e.target.value) }} className="form-control form-control-sm" id="foodName" placeholder="search by Name" name="foodname" />
                        <div className="box-container uldrp" id='ddlDataContainers' style={{ display: 'none' }} ref={containerRef} onScroll={handleScroll}>
                          {foodTempList.length > 0 &&
                            foodTempList.map((val, index) => {
                              return (
                                <ul class="list-items">
                                  <li style={{ textDecoration: 'none' }} onClick={() => { handleSelectFood(val) }}>{val.foodName}</li>
                                </ul>
                              );
                            })}
                        </div>
                        <small
                          id="errFood"
                          className="form-text text-danger"
                          style={{ display: "none" }}
                        ></small>
                      </div>
                      {/* {foodList && (
                        <DropdownWithSearch
                          defaulNname="Select Food"
                          name="foodName"
                          id="foodName"
                          list={foodList}
                          valueName="id"
                          displayName="foodName"
                          getvalue={handleChangeDropdown}
                          editdata={""}
                          clear={clearDropdown}
                          clearFun={clear}
                        />
                      )} */}
                    </div>
                    <div className="mb-2 me-2">
                      <img src={quantity} className="icnn" />{" "}
                      <label htmlFor="Quantity" className="form-label">
                        Quantity
                        <span className="starMandatory">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="quantity"
                        name="quantity"
                        value={Quantity}
                        onChange={handleChange}
                        placeholder="Enter Quantity"
                        onKeyDown={handleKeyDown}
                      />
                      <small
                        id="errQuantity"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>

                    <div className="mb-2 me-2">
                      <img src={unitIcon} className="icnn" />{" "}
                      <label htmlFor="Unit" className="form-label">
                        Unit
                        <span className="starMandatory">*</span>
                      </label>
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        id="unit"
                        name="unit"
                        onChange={handleChange}
                      >
                        <option value="0">Select Unit</option>{" "}
                        {unitList &&
                          unitList.map((val, index) => {
                            return (
                              <option value={val.unitId}>{val.unitName}</option>
                            );
                          })}
                      </select>
                      <small
                        id="errUnit"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>
                    <div className="rt-btns mt-3">
                      <BoxContainer>
                        <div className="mb-2">
                          {/* <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label> */}
                          {showUnderProcess === 1 ? <><TosterUnderProcess />  </> :
                            showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} />
                              :
                              <div className='diet-btn'>
                                <button type="button" className="btn btn-save btn-save-fill btn-sm" onClick={saveFoodIntake}><img src={save} className='icnn' /> Save</button>
                                <button type="button" className="btn btn-save btn-sm btnbluehover" onClick={() => clear(1)}><img src={reset} className='icnn' /> Reset</button>
                                {/* <button type="button" className="btn btn-save btn-sm btnbluehover"><img src={transfer} className='icnn'/> Repeat Last Day Food</button>
                    <button type="button" className="btn btn-save btn-sm btnbluehover"><img src={del} className='icnn'/> Delete All Food</button> */}
                              </div>
                          }
                        </div>
                      </BoxContainer>
                    </div>
                  </BoxContainer>
                </div>
              </div>


            </div>

            <div className="col-12 mt-2">
              <div className="listdetailsct">
                <div className="listdetailsct-in">
                  <div className="listd-in showing">
                    Recommended Food List
                  </div>
                </div>
                <div className="listdetailsct-in">
                  {/* <div className='listd-in'>
                    <select className="form-select form-select-sm" aria-label=".form-select-sm example">
                        <option selected>All Day Meal</option>
                    </select>
                  </div> */}
                  <div className="listd-in">
                    <form className="d-flex ms-auto ser" role="search">
                      <input
                        type="search"
                        className="form-control form-control-sm"
                        placeholder="Search.."
                        onChange={handleSearch}
                      />

                      <i className="fa fa-search"></i>
                    </form>
                  </div>
                </div>
              </div>
              {/* <div className='listdetailsct cir1'>
                <div className='listdetailsct cir2'>
                  <div className='listdetailsct-in'><div className='cir c-orange'></div>Cooked Food</div>
                  <div className='listdetailsct-in'><div className='cir c-blue'></div>Sent By Mess</div>
                  <div className='listdetailsct-in'><div className='cir c-green'></div>Given Food</div>
                  <div className='listdetailsct-in'><div className='cir c-red'></div>Cancelled Food</div>
                </div>
              </div> */}

              <div className="med-table-section" style={{ height: "67vh" }}>
                {/* <TableContainer>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>Food</th>
                      <th>Quantity</th>
                      <th>Time</th>
                      <th>Cooked By</th>
                      <th>Sent By Mess</th>
                      <th style={{ "width": "10%" }} className="text-center">Action</th>
                    </tr>
                  </thead>

                  <tbody>

                    <tr>
                      <td className="text-center">1</td>
                      <td><span className="cir c-orange"></span>Ginger</td>
                      <td>1 gm</td>
                      <td>00:00</td>
                      <td>Rinika</td>
                      <td>06/09/2022 06:36 PM</td>
                      <td>
                        <div className="action-button">
                          <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><span className='btnbg'  style={{ background: "#FFEDD2" }}><img src={editbtn} className=''/></span></div>
                          <div data-bs-toggle="tooltip" data-bs-title="Delete Row" data-bs-placement="bottom"><span className='btnbg'  style={{ background: "#FFEFEF" }}><img src={delbtn} className='icnn'/></span></div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">1</td>
                      <td><span className="cir c-orange"></span>Ginger</td>
                      <td>1 gm</td>
                      <td>00:00</td>
                      <td>Rinika</td>
                      <td>06/09/2022 06:36 PM</td>
                      <td>
                        <div className="action-button">
                          <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><span className='btnbg'  style={{ background: "#FFEDD2" }}><img src={editbtn} className=''/></span></div>
                          <div data-bs-toggle="tooltip" data-bs-title="Delete Row" data-bs-placement="bottom"><span className='btnbg'  style={{ background: "#FFEFEF" }}><img src={delbtn} className='icnn'/></span></div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">1</td>
                      <td><span className="cir c-orange"></span>Ginger</td>
                      <td>1 gm</td>
                      <td>00:00</td>
                      <td>Rinika</td>
                      <td>06/09/2022 06:36 PM</td>
                      <td>
                        <div className="action-button">
                          <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><span className='btnbg'  style={{ background: "#FFEDD2" }}><img src={editbtn} className=''/></span></div>
                          <div data-bs-toggle="tooltip" data-bs-title="Delete Row" data-bs-placement="bottom"><span className='btnbg'  style={{ background: "#FFEFEF" }}><img src={delbtn} className='icnn'/></span></div>
                        </div>
                      </td>
                    </tr>
                    


                  </tbody>
                </TableContainer> */}

                <TableContainer>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ width: "5%" }}>
                        #
                      </th>
                      <th>Food</th>
                      <th>Quantity</th>
                      <th>Date Time</th>
                      {/* <th>Time</th> */}
                      {/* <th>Cooked By</th> */}
                      {/* <th>Sent By Mess</th> */}
                      <th style={{ width: "10%" }} className="text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {foodIntakeListtemp.length > 0 ? (
                      foodIntakeListtemp.map((val, ind) => {
                        return (
                          <tr key={val.id}>
                            <td className="text-center">{ind + 1}</td>
                            <td>{val.foodName}</td>
                            <td>{val.foodQty} {val.unitName}</td>
                            <td>{val.foodEntryDate && val.foodEntryDate.split(" ")[0]} {val.foodEntryTime}</td>
                            {/* <td>{val.foodEntryTime}</td> */}
                            {/* <td></td> */}
                            {/* <td>{val.dischargeType}</td> */}
                            <td>
                              <div className="action-button">
                                <div data-bs-toggle="modal" data-bs-title="Intake Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><span className='btnbg' style={{ background: "#FFEFEF" }}><img src={delbtn} className='icnn' onClick={() => setRowID(val.dietId)} /></span></div>
                              </div>
                            </td>
                          </tr>

                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No Record Found</td>
                      </tr>
                    )}

                  </tbody>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -----------------------Start Delete Modal Popup-------------------    */}

      <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div className="modal-dialog modalDelete">
          <div className="modal-content">
            <div className="modal-body modelbdy text-center">
              <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
              <div className='popDeleteTitle mt-3'> Delete?</div>
              <div className='popDeleteContent'> Are you sure you want to delete?</div>
            </div>
            <div className="modal-footer1 text-center">

              <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
              <button type="button" className="btn-delete popBtnDelete" onClick={handleDelete} data-bs-dismiss="modal">Delete</button>
            </div>
          </div>
        </div>
      </div>
      {/* -----------------------End Delete Modal Popup---------------------  */}
      {showSuccessToster === 1 ?
        <SuccessToster
          message={showMessage}
          handle={setShowSuccessToster}
        />
        : ""}
      {showAlertToster === 1 ? (
        <AlertToster message={showMessage} handle={setShowAlertToster} />
      ) : (
        ""
      )}
      <Loader val={loader} />
    </>
  );
}
