import React, { useCallback, useEffect, useState } from 'react'
import TableContainer from '../../../Component/TableContainer';
import PostComment from '../../API/Comments/PostComment';
import GetComment from '../../API/Comments/GetComment';
import GetCurrentDatePendingBacklog from '../../API/AdminDashBoard/GetAdminDashBoard';
import Heading from '../../../Component/Heading';
import chat from '../../../assets/images/patientmonitordashboard/chat.png';
import noData from '../../../assets/images/icons/No data-rafiki.svg';
import { HubConnectionBuilder } from '@microsoft/signalr'
import UpdateIsReadStatus from '../../API/Comments/UpdateIsReadStatus';

const CurrentDateBacklogStatus = () => {
    const [getCurrentDatePendingBacklogList, setCurrentDatePendingBacklogList] = useState([]);
    const [getComment, setGetComment] = useState([]);
    const [sendMessage, setSendMessage] = useState('');
    const [projectId, setProjectId] = useState(0);
    const clientId = JSON.parse(window.sessionStorage.getItem('LoginData')).clientId;
    const userId = JSON.parse(window.sessionStorage.getItem('LoginData')).userId;
    const [searchInput, setSearchInput] = useState('');
    const [connection, setConnection] = useState();

    const getInitials = (name, numInitials) => {
        const words = name.split(' ');
        const initials = words.map(word => word[0]).join('').slice(0, numInitials);
        return initials.toUpperCase();
    };

    function reformatDateString(s) {
        if (typeof s === 'string' && s.trim() !== '') {
            var b = s.split(/\D/);
            var day = parseInt(b[2], 10).toString();
            var month = parseInt(b[1], 10).toString();
            var year = b[0];
            return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
        }
        else {
            return '';
        }
    };

    const handleOnChangeCurrentDateBacklog = (e) => {
        const { name, value } = e.target;
        if (name === 'searchBoxCurrentDateBacklog') {
            setSearchInput(value)
        }
    };

    const funGetCurrentDatePendingBacklog = async () => {
        const { status, responseValue } = await GetCurrentDatePendingBacklog(window.userId);
        if (status) {
            setCurrentDatePendingBacklogList(responseValue);
        } else {
            setCurrentDatePendingBacklogList([]);
        }
        // responseValue.map((val)=>{
        //     signalRForChat(val.projectId)
        // })
    };
    // const funGetCurrentDatePendingBacklog = useCallback(async () => {
    //     try {
    //         const { status, responseValue } = await GetCurrentDatePendingBacklog(userId);
    //         if (status) {
    //             setCurrentDatePendingBacklogList(responseValue);
    //         } else {
    //             setCurrentDatePendingBacklogList([]);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching current date pending backlog:", error);
    //     }
    // }, [userId]);

    const handleChat = (param) => {
        setProjectId(param.projectId);
        getData(param.projectId);
        signalRForChat(param.projectId);
    };

    function scrollToBottom() {
        var chatPopup = document.getElementById('chatMesages');
        chatPopup.scrollTop = chatPopup.scrollHeight;
    };

    const getData = async (param) => {

        const { status, responseValue } = await GetComment(param, clientId, userId);
        status ? setGetComment(responseValue) : setGetComment([]);

        setTimeout(() => {
            scrollToBottom();
            if (document.getElementById("handleChatModal").style.display !== "none") {
                UpdateIsreadStatus(param);
            }
        }, 200);

    };

    const handleChange = (e) => {
        setSendMessage(e.target.value);
    };

    const SaveData = async () => {
        if (sendMessage.trim() !== '' && sendMessage) {
            const obj = {
                senderId: userId ?? 0,
                message: sendMessage,
                projectId: parseInt(projectId),
                clientId: clientId ?? 0,
                methodName: "GetMessage"
            };
            const response = await PostComment(obj);
            if (response.status === 1) {
                clearValues();
                getData(projectId);
            }
            else {
                getData(projectId)
            }
        }
    };

    const UpdateIsreadStatus = async (param) => {
        const obj = {
            senderId: userId ?? 0,
            projectId: parseInt(param),
        };

        const response = await UpdateIsReadStatus(obj);
        if (response.status === 1) {
            funGetCurrentDatePendingBacklog();
        }
    };

    const clearValues = () => {
        setSendMessage('');
    };

    const signalRForChat = (projectId) => {
        try {
            const connection = new HubConnectionBuilder().withUrl(window.SpringBoardServicesUrl + "Comment").build();
            // const connection = new HubConnectionBuilder().withUrl("https://localhost:7170/Comment")
            // .withAutomaticReconnect()
            // .configureLogging(signalR.LogLevel.Information)
            // .build();

            setConnection(connection)
            connection.start().then((result) => {

                const clientData = JSON.parse(sessionStorage.getItem("LoginData"));
                const clientId = clientData.clientId;
                console.log("csdcsacsd", projectId, clientId)
                connection.invoke("AddUser", parseInt(projectId), clientId).then(function (response) {
                    console.log("adduser", response)
                    connection.on("GetMessage", (response) => {
                        // console.log("GetMessage", response)
                        // setNewMessageCount(newMessageCount + 1);
                        getData(projectId, clientId);
                        // setGetUserChat([...getUserChat, ...response])
                        //for notification counter
                        funGetCurrentDatePendingBacklog();
                    })
                    connection.on("OnNewUserConnected", (response) => {
                        console.log("OnNewUserConnected", response)
                        // setGetUserChat([...getUserChat, ...response])

                    })
                })
            })
        }
        catch (e) {
            console.log(e.message)
        }
    };

    const handleCommentBox = (e) => {
        if (e.keyCode === 13) {
            SaveData();
        }
    };

    useEffect(() => {
        funGetCurrentDatePendingBacklog();

    }, []);

    return (
        <>
            <div className='handlser'>
                <Heading text="Current Date Backlog Status" />
                <div style={{ position: 'relative' }}>
                    <input value={searchInput} onChange={handleOnChangeCurrentDateBacklog} name="searchBoxCurrentDateBacklog" type="search" className="form-control form-control-sm" placeholder="Search...." aria-label="Search" aria-describedby="search-addon" />
                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                </div>
            </div>

            <TableContainer>
                <thead>
                    <tr>
                        <th className="text-center" style={{ "width": "5%" }}>S.No.</th>
                        <th>Developer</th>
                        <th>Project</th>
                        <th>Sprint Description</th>
                        <th>Assign Date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>

                <tbody>
                    {getCurrentDatePendingBacklogList.length > 0 ?
                        getCurrentDatePendingBacklogList && getCurrentDatePendingBacklogList.filter((val) => `${val.name} ${val.projectName} ${val.sprintBacklogText} ${val.estimatedHour} ${reformatDateString(val.assignDate)} ${val.statusText} `.toLowerCase().includes(searchInput.toLowerCase())).map((val, ind) => {
                            return (
                                <tr key={val.id}>
                                    <td className="text-center">{ind + 1}</td>
                                    <td>{val.name}</td>
                                    <td>{val.projectName}</td>
                                    <td>{val.sprintBacklogText} ({val.estimatedHour})</td>
                                    <td>{reformatDateString(val.assignDate)}</td>
                                    <td>{val.statusText}</td>
                                    <td>
                                        {val.commentCount > 0 ? 
                                            <>
                                                {/* <img
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#handleChatModal"
                                                    className='pointer'
                                                    src={chat}
                                                    alt='chat'
                                                    onClick={() => handleChat(val)}
                                                /> */}
                                                 <div className='relative'>
                                                 <i className='bi bi-chat-dots pointer iconChatDots' 
                                                  data-bs-toggle="modal"
                                                data-bs-target="#handleChatModal"                                                   
                                                 onClick={() => handleChat(val)}></i>
                                                <sup className='comment-notification'>{val.commentCount}</sup>
                                                </div>
                                            </>
                                        : 
                                            <>
                                            
                                                {/* <img
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#handleChatModal"
                                                    className='pointer'
                                                    src={chat}
                                                    alt='chat'
                                                    onClick={() => handleChat(val)}                                                   
                                                /> */}
                                                <div>
                                                 <i 
                                                 className='bi bi-chat-dots-fill pointer iconChatDots'
                                                 data-bs-toggle="modal"
                                                data-bs-target="#handleChatModal"
                                                onClick={() => handleChat(val)}
                                                 ></i>
                                                 </div>

                                                {/* <sup className='comment-icon'>fill</sup> */}
                                            </>
                                        }
                                    </td>
                                </tr>
                            )
                        })
                        :
                        <img className='haveNodataImg' src={noData} alt="No Data" />
                    }
                </tbody>
            </TableContainer>


            {/* #####################################CHAT Modal POP UP ##########################################*/}
            <div className='modal fade' id="handleChatModal" data-bs-backdrop="static" >
                <div className="modal-dialog modal-md commentModal">
                    <div className="modal-content p-0">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 text-white" id="exampleModalLabel">
                                <label htmlFor="">Comments</label>
                            </h1>
                            <button type="button" className="btn-close_ btnModalClose" data-bs-dismiss="modal" aria-label="Close" title='Close Window'  ><i className="bi bi-x-octagon"></i></button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="row">
                                <div className="col-12">

                                    <div className="med-box">

                                        <section className="msger">
                                            <header className="msger-header">
                                                <div className="msger-header-title">
                                                    <i className="fas fa-comment-alt"></i> {getComment.length > 0 ? getComment[0].projectName ?? '' : ''}
                                                </div>
                                            </header>
                                            <main className="msger-chat" id='chatMesages'>
                                                {
                                                    getComment && getComment.map((val, index) => (
                                                        val.isSender === 'receiver' ? (
                                                            <div className="comment-receiver">
                                                                <div className="msg-img">
                                                                    {getInitials(val.name, 2)}
                                                                </div>

                                                                <div className="msg-bubble">
                                                                    <div className="msg-info">
                                                                        <div className="msg-info-name">{val.name.split(' ')[0]}</div>
                                                                        <div className="msg-info-time">{val.commentDateTime}</div>
                                                                    </div>

                                                                    <div className="msg-text">{val.message}</div>
                                                                </div>

                                                                <div>&nbsp;</div>

                                                            </div>
                                                        ) : (
                                                            <div className="comment-sender">
                                                                <div>&nbsp;</div>
                                                                <div className="msg-bubble">
                                                                    <div className="msg-info">
                                                                        <div className="msg-info-name">{val.name.split(' ')[0]}</div>
                                                                        <div className="msg-info-time">{val.commentDateTime}</div>
                                                                    </div>

                                                                    <div className="msg-text">{val.message}</div>
                                                                </div>

                                                                <div className="msg-img">
                                                                    {getInitials(val.name, 2)}
                                                                </div>

                                                            </div>
                                                        )

                                                    ))
                                                }
                                            </main>

                                            <div className="comment-inputs">
                                                <textarea name="comment-box" className='comment-box' id="" value={sendMessage} placeholder='Enter comment...' onChange={handleChange} onKeyDown={handleCommentBox}></textarea>
                                                <button type="button" className="comment-btn" onClick={SaveData}>
                                                    <i className='bi bi-send'></i>
                                                </button>
                                            </div>
                                        </section>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CurrentDateBacklogStatus;







