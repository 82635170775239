import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    alertData: {
        "bp_12":["", "", ""],
        "spo2_13":["", "", ""],
        "rr_14":["", "", ""],
        "hr_15":["", "", ""],
        "pr_16":["", "", ""],
        "alb_17":["", "", ""],
        "ca++_18":["", "", ""],
        "k+_19":["", "", ""],
        "na+_20":["", "", ""],
        "mg_21":["", "", ""],
        "ph_22":["", "", ""],
        "pco2_23":["", "", ""],
        "etco2_24":["", "", ""],
        "po2_25":["", "", ""],
        "lactate_26":["", "", ""],
        "hco3_27":["", "", ""],
        "rbs_28":["", "", ""],
        "temp_29":["", "", ""],
        "creatinine_30":["", "", ""],
        "burea_31":["", "", ""],
        "io_32":["", "", ""],
        "sgot_33":["", "", ""],
        "sgpt_34":["", "", ""],
    }
}

export const PatientMonitorDashboardSlice = createSlice({
    name: 'PatientData',
    initialState,
    reducers:{
        getPatientMonitorDashboard(state, action){
            // state.alertData = ""
            state.alertData = action.payload
            return state
        },
    }
})

export const {getPatientMonitorDashboard} = PatientMonitorDashboardSlice.actions
export default PatientMonitorDashboardSlice.reducer