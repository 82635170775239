let GetAllDoctorOrdersForAssignedUser = async (assignedUserId,clientId,fromDate, toDate )=>{
    let url = window.AppbaseUrl+'/api/DoctorOrders/GetAllDoctorOrdersForAssignedUser?assignedUserId='+assignedUserId+'&clientId='+clientId+'&fromDate='+fromDate+'&toDate='+toDate;
    let head ={"content-type": "application/json", accept:'*/*'};

    let response = fetch (url, {
        headers:head,
        method:'GET'
    })
    .then((res)=> res.json())
    .then();
    return response;
}
export default GetAllDoctorOrdersForAssignedUser;