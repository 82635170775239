
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
// import Heading from "../../../Component/Heading";
import Heading from "../../Component/Heading";
import BoxContainer from "../../Component/BoxContainer";
import TableContainer from "../../Component/TableContainer";
import TosterUnderProcess from '../../Component/TosterUnderProcess';
import Toster from '../../Component/Toster';
// import IconEdit from '../../../assets/images/icons/IconEdit.svg'
import IconEdit from '../../assets/images/icons/IconEdit.svg'
import IconDelete from '../../assets/images/icons/IconDelete.svg'
import Loder from '../../Component/Loader'
import saveButtonIcon from '../../assets/images/icons/saveButton.svg'
import clearIcon from "../../assets/images/icons/clear.svg"
import deleteBtnIcon from '../../assets/images/icons/delete.svg'
import updateIcon from '../../assets/images/icons/updateIcon.svg'
import editBtnIcon from '../../assets/images/icons/edit.svg'
import GetHolidayMaster from '../API/HolidayMaster/GetHolidayMaster';
import PostHolidayMaster from '../API/HolidayMaster/PostHolidayMaster';
import PutHolidayMaster from '../API/HolidayMaster/PutHolidayMaster';
import DeleteHolidayMaster from '../API/HolidayMaster/DeleteHolidayMaster';

export default function HolidayMaster() {

    const { t } = useTranslation();
    const [HolidayName, setHolidayName]= useState('')
    const [HolidayDate, setHolidayDate]= useState('')
    const [showUnderProcess, setShowUnderProcess] = useState(0);
    const [showToster, setShowToster] = useState(0);
    const [tosterMessage, setTosterMessage] = useState("");
    const [tosterValue, setTosterValue] = useState(0);
    const [showLoder, setShowLoder] = useState(0);
    const [isNewRowAdded, setIsNewRowAdded] = useState(false);
    const [rowID, setRowID] = useState(0);
    const [editRowIndex, setEditRowIndex] = useState(null);
    const [isUpdateBtnShow, setIsUpdateBtnShow] = useState(0);
    let [updateBool, setUpdateBool] = useState(0)
    let [holidayMasterList, setHolidayMasterList] = useState([])
    const [IsRestrictedHoliday, setIsRestrictedHoliday] = useState(0);
    const [IsOPDHoliday, setIsOPDHoliday] = useState(0);
    const [newlyAddedRowIndex, setNewlyAddedRowIndex] = useState(null);
    let clientId = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
    let userId = JSON.parse(sessionStorage.getItem("LoginData")).userId;

    const toggleIsRestrictedHoliday = () => {
        setIsRestrictedHoliday(IsRestrictedHoliday ? 0 : 1)
      }

      const toggleIsOPDHoliday = () => {
        setIsOPDHoliday(IsOPDHoliday ? 0 : 1)
      }

  const handleChange = (event)=>{

    document.getElementById('errholidayName').style.display = 'none'
    document.getElementById('errholidaydate').style.display = 'none'
    document.getElementById('errIsRestrictedHoliday').style.display = 'none'
    document.getElementById('errIsOPDHoliday').style.display = 'none'

    const {name,value}= event.target;
     if(name === 'holidayName'){
        setHolidayName(value);
     }
     if(name === 'holidayDate'){
        setHolidayDate(value);
     }
     if(name === 'isRestrictedHoliday'){
        const newValue = event.target.checked ? 0 : 1;
        setIsRestrictedHoliday(newValue);
     }
     if(name === 'isOPDHoliday'){
        const newValue = event.target.checked ? 0 : 1;
        setIsOPDHoliday(newValue);
     }

  }

  const getData = async()=>{
    let data = await GetHolidayMaster(clientId)
      if(data.status === 1){
        setHolidayMasterList(data.responseValue);
        console.log("test data hr",data)

      }
  }

  const saveForm = async()=>{

    if(HolidayName === ''){
        document.getElementById('errholidayName').innerHTML = 'Enter Name';
        document.getElementById('errholidayName').style.display = 'block';
        return;
    }
    else if(HolidayDate.trim() === '' || HolidayDate.trim() === undefined){
        document.getElementById('errholidaydate').innerHTML = 'Select Holiday Date';
        document.getElementById('errholidaydate').style.display = 'block';
        return;
    }


    const obj = {
        holidayName : HolidayName,
        holidayDate : HolidayDate,
        isRestrictedHoliDay :IsRestrictedHoliday ,
        isOPDHoliDay : IsOPDHoliday,
        clientId: clientId,
        userId: userId
    }
     let response = await PostHolidayMaster(obj)
     if(response.status === 1){
        setShowUnderProcess(0)
            setShowToster(1)
            setTosterValue(0)
            setTosterMessage("Data Save SuccessFully!")
            setNewlyAddedRowIndex(0);
            getData();
            handleClear();
            setTimeout(() => {
              setShowToster(0)
            }, 2000)
      }

      else{
        setShowUnderProcess(0);
        setShowToster(1);
        setTosterValue(1);
        setTosterMessage("Already Exist!");
        setTosterMessage(response.responseValue)
        // setTosterValue(1)
        setTimeout(() => {
          setShowToster(0)
        
        }, 2000)
      }
  }

  const handleUpdate =async()=>{

    if(HolidayName === null){
        document.getElementById('errholidayName').innerHTML = 'Enter Name';
        document.getElementById('errholidayName').style.display = 'block';
        return;
    }
    else if(HolidayDate.trim() === '' || HolidayDate.trim() === undefined){
        document.getElementById('errholidaydate').innerHTML = 'Select Holiday Date';
        document.getElementById('errholidaydate').style.display = 'block';
        return;
    }

    const obj = {
        id : rowID,
        holidayName : HolidayName,
        holidayDate : HolidayDate,
        isRestrictedHoliDay :IsRestrictedHoliday ,
        isOPDHoliDay : IsOPDHoliday,
        clientId: clientId,
        userId: userId
    }

    const data = await PutHolidayMaster(obj)
     if(data.status === 1){
        setShowUnderProcess(0);
        console.log("updat test work or not",data)
        setTosterValue(0);
        setShowToster(1);
        setTosterMessage('Data Updated Successfully!');
        setIsUpdateBtnShow(true);
        getData();
        handleClear()
        setTimeout(() => {
          setShowToster(0);
          // setNewlyAddedRowIndex(null);
        }, 2000);
     }
     else {
        setShowUnderProcess(0);

        setTosterMessage(data.responseValue);
        setTosterValue(1);
        setTimeout(() => {
          setShowToster(0);
        }, 2000);
     }
  }

  const edit = async(data,index) => {
    document.getElementById('errholidayName').style.display = 'none'
    document.getElementById('errholidaydate').style.display = 'none'
    document.getElementById('errIsRestrictedHoliday').style.display = 'none'
    document.getElementById('errIsOPDHoliday').style.display = 'none'
    setRowID(data.id)
    setIsUpdateBtnShow(true);
    setHolidayName(data.holidayName)
    setHolidayDate(data.holidayDate)
    setIsRestrictedHoliday(data.isRestrictedHoliDay)
    setIsOPDHoliday(data.isOPDHoliDay)
    setNewlyAddedRowIndex(index)
    console.log("test  id",data.id)
  }

 const deleteRow = async()=>{
   
    const obj = {
        id : rowID
       
    }
   let data = await DeleteHolidayMaster(obj)
     if(data.status === 1){
    setShowUnderProcess(0);
    setTosterValue(0);
    setShowToster(1);
    setTosterMessage("Data Deleted Successfully!!");
    setNewlyAddedRowIndex(false)
    setIsNewRowAdded(false)
    getData();
    handleClear();
    setTimeout(() => {
      setShowToster(0);
      handleClear()

    }, 1000)

     }
     else {
        setShowUnderProcess(0)
        setShowToster(0)
        setTosterMessage(data.responseValue)
        setTosterValue(1)
        setTimeout(() => {
          setShowToster(0);
        }, 2000)
     }

 }

  const handleCancel=()=>{
    handleClear()
    setIsUpdateBtnShow(0);
    setEditRowIndex(null);
    setNewlyAddedRowIndex(null);
  }

  const handleClear = ()=>{
    document.getElementById('errholidayName').style.display = 'none'
    document.getElementById('errholidaydate').style.display = 'none'
    document.getElementById('errIsRestrictedHoliday').style.display = 'none'
    document.getElementById('errIsOPDHoliday').style.display = 'none'
    // setRowID("")
    setHolidayName("")
    setHolidayDate("")
    setIsRestrictedHoliday(0)
    setIsOPDHoliday(0)
  }

  useEffect(() => {
    getData();
   
}, [])

  return (
    <>
     <section className="main-content mt-5 pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <Heading text={t("Holiday Master")} />
                            <BoxContainer>

                                <div className="mb-2 me-2">
                                    <label htmlFor="holidayName" className="form-label">{t("Holiday Name")}<span className="starMandatory">*</span></label>
                                    <input type='text' className='form-control form-control-sm' id='holidayName' name='holidayName' value={HolidayName} onChange={handleChange} placeholder={t("Enter Holiday Name")} />
                                    <small id="errholidayName" className="form-text text-danger" style={{ display: 'none' }}></small>
                                </div>

                                <div className="mb-2 me-2">
                                    <label htmlFor="Code" className="form-label">{t("Holiday Date")}<span className="starMandatory">*</span></label>
                                    <input type="date" className="form-control form-control-sm" id="ddstartdate" value={HolidayDate} name="holidayDate" placeholder={t("Select Holiday Date")} onChange={handleChange} />
                                    <small id="errholidaydate" className="form-text text-danger" style={{ display: 'none' }}></small>
                                </div>

                                <div className="risk-factors-check regularCheck mt-3 me-3">
                                <label htmlFor="IsRestrictedHoliday" className="form-label">{t("Is Restricted Holiday")}<span className="starMandatory"></span></label>
                                     <div className="form-check ms-3">
                                   <input checked={IsRestrictedHoliday} id="IsRestrictedHoliday" name="IsRestrictedHoliday" className="form-check-input" type="checkbox" onClick={toggleIsRestrictedHoliday} />
                                   <small id="errIsRestrictedHoliday" className="form-text text-danger" style={{ display: 'none' }}></small>
                                    </div>
                                </div>


                                <div className="risk-factors-check regularCheck mt-3 me-3">
                                <label htmlFor="IsOPDHoliday" className="form-label">{t("Is OPD Holiday")}<span className="starMandatory"></span></label>
                                <div className="form-check ms-3">
                                   <input checked={IsOPDHoliday} id="IsOPDHoliday" name="IsOPDHoliday" className="form-check-input" type="checkbox" onClick={toggleIsOPDHoliday} />
                                   <small id="errIsOPDHoliday" className="form-text text-danger" style={{ display: 'none' }}></small>
                                    </div>
                                </div>
                                  


                                <div className="mb-2 relative me-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>
                                    <div>
                                        {showUnderProcess === 1 ? <TosterUnderProcess /> :
                                            <>
                                                {showToster === 1 ?
                                                    <Toster value={tosterValue} message={tosterMessage} />

                                                    : <div>
                                                        {isUpdateBtnShow === 0 ?
                                                            <>
                                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={saveForm}>{t("Save")}</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}>{t("Clear")}</button>
                                                            </>
                                                            :
                                                            <>
                                                                <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1 mx-1" onClick={handleUpdate} ><img src={updateIcon} className='icnn' alt="" />Update</button>
                                                                <button type="button" className="btn btn-clear btn-sm mb-1" onClick={handleCancel}>{t("Cancel")}</button>
                                                            </>
                                                        }
                                                    </div>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </BoxContainer>
                        </div>
                        
                        <div className="col-12 mt-2">
                            <Heading text={t("Holiday Master List")} />
                            <div className="med-table-section" style={{ "height": "75vh" }}>
                                <TableContainer>
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{ "width": "5%" }}>#</th>
                                            <th>{t("Holiday Name")}</th>
                                            <th>{t("Holiday Date")}</th>
                                            <th>{t("Is Restricted Holiday")}</th>
                                            <th>{t("Is OPD Holiday")}</th>
                                            <th style={{ "width": "10%" }} className="text-center">{t("Action")}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {holidayMasterList && holidayMasterList.map((data, index) => {
                                            const isEditing = index === editRowIndex;
                                            return (
                                                <tr>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td>{data.holidayName}</td>
                                                    <td>{data.holidayDate}</td>
                                                    <td>{data.isRestrictedHoliDay === 1 ? 'Yes' : 'No'}</td>
                                                    <td>{data.isOPDHoliDay === 1 ? 'Yes' : 'No'}</td>
                                                    <td>
                                                        <div className="action-button">
                                                            <div data-bs-toggle="tooltip" data-bs-title="Edit Row" data-bs-placement="bottom"><img src={editBtnIcon} className={isEditing ? 'edited-row' : ''} alt='' onClick={() => { edit(data, index) }} /></div>
                                                                                                                  
                                                            <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowID(data.id); }} /></div>
                                                            
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </TableContainer>
                                {/* -----------------------Start Delete Modal Popup-------------------   */}

                                {/*  <!-- Modal -->  */}
                                <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                                    <div className="modal-dialog modalDelete">
                                        <div className="modal-content">

                                            <div className="modal-body modelbdy text-center">
                                                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                                                <div className='popDeleteTitle mt-3'>{t("Delete?")}</div>
                                                <div className='popDeleteContent'> {t("Are_you_sure_you_want_to_delete?")}</div>
                                            </div>
                                            <div className="modal-footer1 text-center">

                                            <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                                            <button type="button" className="btn-delete popBtnDelete" data-bs-dismiss="modal" onClick={deleteRow} >{t("Delete")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}

                            </div>
                        </div>
                    </div>
                </div>

            </section>
    </>
   
  )
}


  


