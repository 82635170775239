import React, { useEffect, useState } from 'react'
import GetPatientOutputForDashboard from '../Api/GetPatientOutputForDashboard'
import GetPatientIntakeForDashboard from '../Api/GetPatientIntakeForDashboard'
import NODataFound from '../../Component/NODataFound'
import Loader from '../../Component/Loader'

export default function IOPopup(props) {

    let [inputData, setInputData] = useState([])
    let [outData, setOutData] = useState([])
    let [loder, setLoader] = useState(1)
    let getData = async () => {
        let respintake = await GetPatientIntakeForDashboard(props.patientdata.UhId, 24)
        if (respintake.status === 1) {
            setInputData(JSON.parse(respintake.responseValue[0].jsonData))
            let respout = await GetPatientOutputForDashboard(props.patientdata.UhId, 24)
            if (respout.status === 1) {
                setOutData(JSON.parse(respout.responseValue[0].jsonData))
                setLoader(0)
            }
            else {
                setLoader(0)

            }
            setLoader(0)
        }
        else {
            setLoader(0)

        }

    }
    useEffect(() => {
        getData()
    }, [props])
    return (
        <>

            <div className={`modal d-${props.showpopup === 0 ? 'none' : 'block'}`}>
                <div className="modal-dialog modal-lg modal-dialog-scrollable_ pprofile_">
                    <div className="modal-content">
                        {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill" onClick={() => { props.modelCloseFun(1) }}>
                            <label className='text-center pt-2' style={{ width: '25px', height: '25px', borderRadius: '15px', backgroundColor: 'red', 'cursor': 'pointer' }}>X</label>
                        </span> */}
                        {/* <BoxHeading title="Family History" patientName={props.patientdata.PntName} uhid={props.patientdata.UhId} /> */}

                        <span className="closee" onClick={() => { props.modelCloseFun(0) }}><i className='fa fa-times'></i></span>
                        <div className='p-profile'>
                            <div className='p-profile-h'>IO History</div>
                            <div className='p-profile-h'>
                                <div className='pname'><span>{props.patientdata.PntName} </span></div>
                                <div className='pname'>- {props.patientdata.UhId}</div>
                            </div>
                        </div>

                        <div className='tabbtnn m-0 p-2' style={{ maxWidth: '325px' }}>
                            <ul className="nav nav-tabs" id="myTab" role="tablist" style={{ background: '#e0eaf8' }}>
                                <li className="nav-item" role="presentation">
                                    <div className="opdvisit-in nav-link active" data-bs-toggle="tab" data-bs-target="#FamilyHistory" type="button" role="tab" aria-controls="FamilyHistory" aria-selected="true">Input</div>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <div className="opdvisit-in nav-link" data-bs-toggle="tab" data-bs-target="#MedicalHistory" type="button" role="tab" aria-controls="MedicalHistory" aria-selected="false">Output</div>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content" id="myTabContent">
                            <div className="px-2 tab-pane fade show active" id="FamilyHistory" role="tabpanel" >
                                <div className='heading-box mb-0'>24 hour Input</div>
                                <div className='mt-1_ ps-5_ pe-4_ row_'>
                                    <div className='gridb'>
                                        <div className="pdtable" style={{ maxHeight: '350px', overflowY: 'auto' }}>
                                            {
                                                inputData.length !== 0 ?
                                                    <table className='med-table_ border_ striped_'>
                                                        <thead>
                                                            <tr>
                                                                <th className='text-center'>#</th>
                                                                <th>Type</th>
                                                                <th>Food Name</th>
                                                                <th>Quantity</th>
                                                                <th>Unit</th>
                                                                <th>Given Food Date</th>
                                                                {/* <th>Vaccine</th>
                                            <th>Disease</th> */}
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {inputData && inputData.map((val, ind) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{ind + 1}</td>
                                                                        <td>{val.Type}</td>
                                                                        <td>{val.foodName}</td>
                                                                        <td>{val.Quantity}</td>
                                                                        <td>{val.unitName}</td>
                                                                        <td>{val.GivenFoodDate}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>


                                                    :
                                                    <NODataFound />
                                            }

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="px-2 tab-pane fade" id="MedicalHistory" role="tabpanel" >
                                <div className='heading-box mb-0'>24 hour Output</div>
                                <div className='mt-1_ ps-5_ pe-4_ row_'>
                                    <div className='gridb'>
                                        <div className="pdtable" style={{ maxHeight: '350px', overflowY: 'auto' }}>
                                            {
                                                outData.length !== 0 ?
                                                    <table className='med-table_ border_ striped_'>
                                                        <thead>
                                                            <tr>
                                                                <th className='text-center'>#</th>
                                                                <th>Name</th>
                                                                <th>Quantity</th>
                                                                <th>Unit</th>
                                                                <th>Given Food Date</th>
                                                                {/* <th>Vaccine</th>
                                            <th>Disease</th> */}
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {outData && outData.map((val, ind) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{ind + 1}</td>
                                                                        <td>{val.outputName}</td>
                                                                        <td>{val.quantity}</td>
                                                                        <td>{val.unitName}</td>
                                                                        <td>{val.outputDate}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    :
                                                    <NODataFound />
                                            }


                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <Loader val={loder} />
        </>
    )
}

