import { useEffect, useState } from "react";
import GetAllDashboardParameterColumnAssign from "../../../Api/GetAllDashboardParameterColumnAssign";
import { useSelector } from "react-redux";

export default function PatientTempL(props) {

    let [temp, setTemp] = useState(
        {
            VitalValue: "N/A",
            VitalColor: "gray"
        }
    )

    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
           if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

    }
    
    useEffect(() => {
        getColumnsParameters()
        let temp = {};
        // console.log("  props.val.AdmitTimeVitalsList", props)
        props.val.AdmitTimeVitalsList && props.val.AdmitTimeVitalsList.map((val) => {
            if (val.VitalID === 5) {
                temp.VitalValue = val.VitalValue

                temp['VitalColor'] = val.VitalColor

                setTemp(temp)


            }

        })
    }, [props])
    return (
        <>
            {

                paramenterData && paramenterData.map((val, ind) => {
                    if (val.parameterId === 39) {
                        return (
                            <div className="overflow-max vitalClass text-white_" style={{ color: temp.VitalColor }}>{temp.VitalValue} <br />{/*<span>{temperature.time}</span>*/}</div>

                        )
                    }

                })

            }
        </>
    )
}