
async function PostCurrency(data) {
    // let url = window.AppbaseUrl + "/api/CurrencyMaster/InsertCurrency?currencyName=INR&userId=176"
    let url = `${window.AdminbaseUrl}/api/CurrencyMaster/InsertCurrency?currencyName=${data.currencyName}&userId=${data.userId}`;

    let head = { "Content-Type": "application/JSON", accept: '*/*'};
  
    let response = await fetch(url, {
      headers: head,
      method: 'POST'
    })
      .then((res) => res.json())
      .then();
  
    return response;
  }
  export default PostCurrency;