import React, { useRef } from 'react'
import ExistingComplain from "../../../../../assets/images/OPD/existingComplain.svg"
import ProvisionalDiagonisys from "../../../../../assets/images/OPD/provisionalDiagonisys.svg"
import { useState } from 'react'
import Search, { FindByQuery, SearchIndex } from '../../../../../Code/Serach'
import GetProblemList from '../../../../API/OPD/Prescription/KnowMedsAPI/GetProblemList'
import { useEffect } from 'react'
import SaveIPDData from '../../../../../Code/SaveIPDData'
import { useSelector } from 'react-redux'
import OPDSymptomsPopUp from '../../../OPD/OPDSharePage/OPDPrescription/OPDSymptomsPopUp'
import OPDAllergiesPopUP from '../../../OPD/OPDSharePage/OPDPrescription/PopUp/OPDAllergiesPopUP'
import OPDConsultantDiagnosis from '../../../OPD/OPDSharePage/OPDPrescription/OPDConsultantDiagnosis'
import OPDDiagnosisSuggestion from '../../../OPD/OPDSharePage/OPDPrescription/OPDDiagnosisSuggestion'
import Antibiogram from '../../../OPD/OPDSharePage/OPDPrescription/PopUp/Antibiogram'
import DownKey, { UpKey } from "../../../../../Code/ListSelect";
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import AlertToster from '../../../../../Component/AlertToster'


export default function ProblemComponent({ComplaintData}) {

    const { t } = useTranslation();
    document.body.dir = i18n.dir()


    let [symptomsData, setSymptomsData] = useState([])
    let [consultantData, setConsultantData] = useState([])

    let [showSearchBoxProblem, setShowSearchBoxProblem] = useState(-1)
    let [showSearchBoxProblemConsultant, setShowSearchBoxProblemConsultant] = useState(-1)

    let [problemList, setProblemList] = useState([])
    let [problemListTemp, setProblemListTemp] = useState([])

    let [sendData, setSendData] = useState([])

    let [showDignosis, setShowDignosis] = useState(1)
    let [symptomsPopUp, setSymptomsPopUp] = useState(0)
    let [allergiesPopUP, setAllergiesPopUP] = useState(0)
    const [cleared, setCleared] = useState(false);
    let [consultantPopUP, setConsultantPopUP] = useState(0)
    let [diagnosisSuggestionPopUP, setDiagnosisSuggestionPopUP] = useState(0)
    let [showAntibiogram, setShowAntibiogram] = useState(0)
    let [showAlert, setShowAlert] = useState(0)
    let clearCheck = window.sessionStorage.getItem("runClearData")

    let row = {

        problemId: 0,
        problemName: "",

     }

    let liSelected = useRef()
    let index = useRef(-1)
    let next = useRef()
    let oldData = useRef(0)

    let getdata = async () => {
        let response = await GetProblemList()
        if (response.status === 1) {
            setProblemList(response.responseValue)
            setProblemListTemp(response.responseValue)
        }
    }

    let handleKeyPress = (e) => {
        let value = e.target.value;
        let name = e.target.name
        let ul = ""
        if (e.keyCode === 13) {
            let data = [...sendData].filter(n => n)

            if (name === "symptomsData") {
                if (showSearchBoxProblem === -1) {
                    row["problemId"] = 0
                    row["problemName"] = value
                    row["pdmId"] = 2
                }
                else {
                    ul = document.getElementById('symptomsDataList');
                    row["problemId"] = ul.getElementsByTagName('li')[index.current].value
                    row["problemName"] = ul.getElementsByTagName('li')[index.current].innerText
                    row["pdmId"] = 2
                    setShowSearchBoxProblem(-1)
                    liSelected.current = ""
                    index.current = -1
                    next.current = ""
                    oldData.current = 0
                }
                setSymptomsData([...symptomsData, row])
                setSendData([...sendData, row])

                setSendData([...data, row])
                SaveIPDData([...data, row], "jsonDiagnosis")

                document.getElementById(name).value = ""
                document.getElementById(name).focus()
            }
            else if (name === "consultantData") {
                if (showSearchBoxProblemConsultant === -1) {
                    row["problemId"] = 0
                    row["problemName"] = value
                    row["pdmId"] = 4
                }
                else {
                    ul = document.getElementById('consultantDataList');
                    row["problemId"] = ul.getElementsByTagName('li')[index.current].value
                    row["problemName"] = ul.getElementsByTagName('li')[index.current].innerText
                    row["pdmId"] = 4
                    setShowSearchBoxProblemConsultant(-1)
                    liSelected.current = ""
                    index.current = -1
                    next.current = ""
                    oldData.current = 0
                }
                setConsultantData([...consultantData, row])
                setSendData([...sendData, row])

                setSendData([...data, row])
                SaveIPDData([...data, row], "jsonDiagnosis")
                document.getElementById(name).value = ""
                document.getElementById(name).focus()
            }

        }

        else if (e.keyCode === 40) {
            // down
            if (name === "symptomsData") {
                if (showSearchBoxProblem !== -1) {
                    ul = document.getElementById('symptomsDataList');
                    DownKey(ul, liSelected, index, next, oldData)
                }
            }
            else if (name === "consultantData") {
                if (showSearchBoxProblemConsultant !== -1) {
                    ul = document.getElementById('consultantDataList');
                    DownKey(ul, liSelected, index, next, oldData)
                }

            }

        }

        else if (e.keyCode === 38) {
            // Up
            if (name === "consultantData") {
                if (showSearchBoxProblem !== -1) {
                    ul = document.getElementById('symptomsDataList');
                    UpKey(ul, liSelected, index, next, oldData)

                }
            }
            else if (name === "consultantData") {
                if (showSearchBoxProblemConsultant !== -1) {
                    ul = document.getElementById('consultantDataList');
                    UpKey(ul, liSelected, index, next, oldData)
                }

            }

        }
    }



    let handleSearch = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        let data = [...sendData].filter(n => n)

        if (value != "") {
            if (name === "symptomsData") {
                let response = Search(problemList, value)
                // let response = FindByQuery(problemList, value, "problemName")
                if (response.length != 0) {
                    setProblemListTemp(response)
                    setShowSearchBoxProblem(1)
                }
            

            }
           

        }
        else {
            setShowSearchBoxProblem(-1)
            setShowSearchBoxProblemConsultant(-1)
        }

    }

    let handleClick = (boxname, id, name) => {
        let data = [...sendData].filter(n => n)

        if (boxname == "symptomsData") {
            let t = 0
            row["problemId"] = id
            row["problemName"] = name
           
            
            setShowSearchBoxProblem(-1)
            let flag = 0
            symptomsData.map((v, i) => {
                if (v.problemId === id) {
                    flag = 1
                    return
                }
            })
            if (flag === 0) {
                setSendData([...data, row])
                

                setSymptomsData([...symptomsData, row])
                ComplaintData([...symptomsData, row]);
            }
            let r = SearchIndex(problemList, "problemName", name)
            let tt = [...problemList]
            tt.splice(r, 1)
            setProblemList(tt)


            let temp = [...data]
            data.map((val, ind) => {
                if (val.pdmId === 2 && val.problemId === 0) {
                    delete temp[ind]
                }
            })
            setSendData([...temp.filter(n => n), row])
           

            document.getElementById("symptomsData").focus()

            document.getElementById("symptomsData").value = "";

        }
      
    }


    let handleRemove = (ind, problemId, name, problemName) => {
        let tempsymptomsData = [...symptomsData]
        let tempconsultantData = [...consultantData]
        let tempSenddata = [...sendData]

        if (name === "symptomsData") {
            sendData.map((val, index) => {
                if (val.pdmId === 2 && val.problemId === problemId && val.problemName === problemName) {
                    tempSenddata.splice(index, 1)
                    return
                }
            })
            console.log("ind", ind)
            tempsymptomsData.splice(ind, 1)
        }
        else if (name === "consultantData") {
            sendData.map((val, index) => {
                if (val.pdmId === 4 && val.problemId === problemId && val.problemName === problemName) {
                    tempSenddata.splice(index, 1)
                    return
                }
            })
            tempconsultantData.splice(ind, 1)
        }

        setSymptomsData(tempsymptomsData)
      
        setSendData(tempSenddata)
     
    }


    
    const handleClear = () => {
        setSymptomsData([]); 
        setSendData([row])
      }
      
    if (clearCheck && JSON.parse(clearCheck) && !cleared) { 
      handleClear();
      setCleared(true);
      setTimeout(() => {
          setCleared(false); 
      }, 1000); 
    }
  

    useEffect(() => {
      
        getdata()
    }, [])

 
    return (
        <>
            <div className={`roww  gap-2 gap-lg-0 mt-2`} >
                <div className='col-12 p-0  pe-lg-21 boxcontainer boxs'>
                    <div className='d-flex flex-column'>
                        <div className={`row p-0 m-0 opd-prescription-box `} >
                            <div className='m-0  col-sm-3 col-12 img-text-box-back-opd pdd'>
                                <div className='d-flex flex-row gap-2  m-0' onClick={() => { }} >
                                    {/* <img src={ExistingComplain} className='compcon' alt='' /> */}
                                    <label className='cheifc'>{t("Sign and Symptoms")}</label>
                                </div>
                            </div>
                            <div className='p-2 m-0 col-sm-9 col-12'>

                                <input autoComplete="off" type="text" className='text-box-opd ' placeholder={t('Enter Sign and Symptoms')} name="symptomsData" id="symptomsData" onKeyDown={handleKeyPress} onChange={(e) => { handleSearch(e) }} />
                                {showSearchBoxProblem === 1 ?
                                    <div id="symptomsDataListdiv" className='position-absolute opdmedicationsearchbox'>
                                        <ul id="symptomsDataList">
                                            {problemListTemp && problemListTemp.map((val, ind) => {
                                                return (
                                                    [6, 7].map((id, index) => {
                                                        if (val.problemTypeID === id) {
                                                            return (

                                                                <li className='pointer' onClick={(e) => { handleClick("symptomsData", val.id, val.problemName) }}>{val.problemName}</li>
                                                            )
                                                        }
                                                    })
                                                )
                                            })}
                                        </ul>
                                        <div className='full-screen-div-opd' onClick={() => { setShowSearchBoxProblem(-1) }}></div>

                                    </div>
                                    : ""}

                                <div className='d-flex flex-wrap gap-2' style={{ overflowX: "auto", height: '65px' }}>

                                    {
                                        symptomsData && symptomsData.map((val, ind) => {
                                            return (
                                                <div className='d-flex flex-row justify-content-center align-items-center gap-2 pe-1 opdcancletab'>
                                                    <label className=''>{val.problemName[0] + val.problemName.slice(1, val.problemName.length)}</label>
                                                    <i className="fa-solid fa-xmark" onClick={() => { handleRemove(ind, val.problemId, "symptomsData", val.problemName) }}></i>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>




                        </div>


                      
                    </div>
                </div>



            </div>
         
        </>
    )
}