import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react'
// import SarvodayaLogo from '../../../assets/images/icons/SarvodayaLogo.png'
import Vector from "../../../assets/images/icons/Vector.svg";
import Contact from "../../../assets/images/icons/Contact.svg";
import PrintPageHeader from '../../../Component/PrintPageHeader';
export default function ReceiptQuentionnare() {

    let date = new Date()
    let [dateTime, setDateTime] = useState();

    let [clientData, setClientData] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientdata)
    let [gender, setGender] = useState(JSON.parse(window.sessionStorage.getItem("IPDpatientList"))[0].gender)
    let [questionairePrintData, setQuestionairePrintData] = useState(JSON.parse(window.sessionStorage.getItem("PrintQuestionaire")))
    let jsonQuestionaireData = questionairePrintData.jsonQuestionaire

    console.log("jsonQuestionaireData", jsonQuestionaireData)


    useEffect(() => {
        // functionGetLoginData()
        setDateTime(date.toLocaleDateString() + " " + date.toLocaleTimeString());
        // getPurchaseSubDetails()
        // getPTData();

        setTimeout(() => {
            handlepritnt();
        }, 1200)

    }, [])

    let handlepritnt = () => {
        document.title = 'MRI Questionaire'; // Set the desired title here

        window.print("");
        window.close();

    }
    return (
        <>
            <div className="main-table" style={{ padding: '5px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <table className='clearslip pt-4 mt-4'>
                    {/* ....header.. */}
                    <tr>
                        <td colSpan={8}>
                            <PrintPageHeader />

                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td className='pt-1 mt-1 pb-2 mb-2 ps-0 pe-0' colSpan={8} style={{ verticalAlign: 'top' }}>
                            <div className='micro-heading' style={{ paddingLeft: '5px' }} >
                                MRI QUESTIONNAIRE
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className='ps-0 pe-0' colSpan={8}>
                            <table className='ps-0 pe-0'>
                                <tr>
                                    <td className='mt-0 pt-0 mb-2 pb-2' colSpan={8} ><strong>Tentative Date & Time of Appointment : </strong>
                                        <span style={{ width: '50%', borderBottom: '1px dashed black', display: 'inline-block' }}>&nbsp;{questionairePrintData.tentativeDateTime}</span> </td>
                                </tr>
                                <tr>
                                   <td colSpan={8}>
                                    <div className='mri-dtls'>
                                    <div><strong>Pregnancy :</strong><span style={{ width: '20%', borderBottom: '1px dashed black', display: 'inline-block', lineHeight: '26px' }}>&nbsp;{gender === "Male" ? "NA" : questionairePrintData.isPregnent}</span></div>
                                    {/* <td colSpan={2}>No: <span style={{ width: '30px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></td> */}
                                    <div><strong>Drug Allergy(if Any): &nbsp;</strong><span style={{ width: '30%', borderBottom: '1px dashed black', display: 'inline-block', lineHeight: '26px' }}>{questionairePrintData.allergies}</span></div>
                                    <div><strong>Asthma : &nbsp;</strong><span style={{ width: '70%', borderBottom: '1px dashed black', display: 'inline-block', lineHeight: '26px' }}>{questionairePrintData.isAsthama === 1 ? "Yes" : "No"},{questionairePrintData.asthama}</span></div>
                               
                                    </div>
                                   </td>
                                    </tr>
                                <tr>
                                    <td colSpan={8} className='pt-2'><strong>Serum Urea/Creatinine (For Contrast Studies): &nbsp;</strong><span style={{ width: '60%', borderBottom: '1px dashed black', display: 'inline-block', lineHeight: '26px' }}> {questionairePrintData.urea} / {questionairePrintData.creatinine}</span> </td>
                                </tr>
                                <tr>
                                    
                                    <td>
                                    <div className='pb-1'> <strong>Do you have any of these? (to be filled by patient)</strong></div>
                                    </td>

                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='ps-0 pe-0' colspan="8">
                            <div className="pacemaker-boxes" >
                                <div className="boxxfirst" style={{ width: '70%' }}>
                                    <div className='eyesimplt'>
                                        <div>Pacemaker/</div>
                                        <div className='ss'>पेसमेकर</div>
                                    </div>
                                    <div className='yesno' >

                                        <div className='rectanlbox1'> <label>Y</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Pacemaker ?" && item.optionText === 'Yes'
                                        ))} /></div>

                                        <div className='rectanlbox1'> <label>N</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Pacemaker ?" && item.optionText === 'No'
                                        ))} /></div>
                                    </div>




                                </div>
                                <div className="boxxfirst">
                                    <div className='eyesimplt'>
                                        <div>Ear/Cochlear Implant/</div>
                                        <div className='ss'>कान/कर्णावतप्रत्यारोपण</div>
                                    </div>
                                    {/* <div className='yesno' >
                                        <div className='rectanlbox'>Y</div>
                                        <div className='rectanlbox'>N</div>
                                    </div> */}

                                    <div className='yesno' >

                                        <div className='rectanlbox1'> <label>Y</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Ear / Cochlear Implant ?" && item.optionText === 'Yes'
                                        ))} /></div>

                                        <div className='rectanlbox1'>  <label>N</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Ear / Cochlear Implant ?" && item.optionText === 'No'
                                        ))} /></div>
                                    </div>
                                </div>
                                <div className="boxxfirst">
                                    <div className='eyesimplt'>
                                        <div>Brain /aneurysm clip /</div>
                                        <div className='ss'>मस्तिष्क/धमनीविस्फारक्लिप</div>
                                    </div>
                                    {/* <div className='yesno' >
                                        <div className='rectanlbox'>Y</div>
                                        <div className='rectanlbox'>N</div>
                                    </div> */}

                                    <div className='yesno' >

                                        <div className='rectanlbox1'> <label>Y</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Brain / Aneurysm clip ?" && item.optionText === 'Yes'
                                        ))} /></div>

                                        <div className='rectanlbox1'>   <label>N</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Brain / Aneurysm clip ?" && item.optionText === 'No'
                                        ))} /></div>
                                    </div>
                                </div>
                                <div className="boxxfirst">
                                    <div className='eyesimplt'>
                                        <div>Metal Implant in eyes/</div>
                                        <div className='ss'>आँख मधातुकाप्रत्यारोपण</div>
                                    </div>
                                    {/* <div className='yesno' >
                                        <div className='rectanlbox'>Y</div>
                                        <div className='rectanlbox'>N</div>
                                    </div> */}

                                    <div className='yesno' >

                                        <div className='rectanlbox1'><label>Y</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Metal Implant in eyes ?" && item.optionText === 'Yes'
                                        ))} /></div>

                                        <div className='rectanlbox1'>  <label>N</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Metal Implant in eyes ?" && item.optionText === 'No'
                                        ))} /></div>
                                    </div>
                                </div>
                                <div className="boxxfirst">
                                    <div className='eyesimplt'>
                                        <div>Metal Implants fregments/</div>
                                        <div className='ss'>धातुप्रत्यारोपण</div>
                                    </div>
                                    {/* <div className='yesno' >
                                        <div className='rectanlbox'>Y</div>
                                        <div className='rectanlbox'>N</div>
                                    </div> */}

                                    <div className='yesno' >

                                        <div className='rectanlbox1'>  <label>Y</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Metal Implant fragments ?" && item.optionText === 'Yes'
                                        ))} /></div>

                                        <div className='rectanlbox1'> <label>N</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Metal Implant fragments ?" && item.optionText === 'No'
                                        ))} /></div>
                                    </div>

                                </div>
                                <div className="boxxfirst">
                                    <div className='eyesimplt'>
                                        <div>Reaction to MRI Contrast</div>
                                        <div className='ss'>(MRI) Contrast<br />
                                            {/* धमनीविस्फारक्लिप */}
                                            विपरीत प्रतिक्रिया
                                        </div>
                                    </div>
                                    {/* <div className='yesno' >
                                        <div className='rectanlbox'>Y</div>
                                        <div className='rectanlbox'>N</div>
                                    </div> */}
                                    <div className='yesno' >

                                        <div className='rectanlbox1'> <label>Y</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Reaction to MRI Contrast ?" && item.optionText === 'Yes'
                                        ))} /></div>

                                        <div className='rectanlbox1'>  <label>N</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Reaction to MRI Contrast ?" && item.optionText === 'No'
                                        ))} /></div>
                                    </div>
                                </div>

                            </div>
                            <div className="pacemaker-boxes" >
                                <div className="boxxfirst" style={{ width: '70%' }}>
                                    <div className='eyesimplt'>
                                        <div>Other metal objects/</div>
                                        <div className='ss'>अन्यधातुकीवस्तुएं </div>
                                    </div>
                                    {/* <div className='yesno' >
                                        <div className='rectanlbox'>Y</div>
                                        <div className='rectanlbox'>N</div>
                                    </div> */}

                                    <div className='yesno' >

                                        <div className='rectanlbox1'> <label>Y</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Other metal objects ?" && item.optionText === 'Yes'
                                        ))} /></div>

                                        <div className='rectanlbox1'> <label>N</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Other metal objects ?" && item.optionText === 'No'
                                        ))} /></div>
                                    </div>
                                </div>
                                <div className="boxxfirst">
                                    <div className='eyesimplt'>
                                        <div>Implanted electrical device /</div>
                                        <div className='ss'>प्रत्यारोपितबिजलीकेउपकरण</div>
                                    </div>
                                    {/* <div className='yesno' >
                                        <div className='rectanlbox'>Y</div>
                                        <div className='rectanlbox'>N</div>
                                    </div> */}
                                    <div className='yesno' >

                                        <div className='rectanlbox1'> <label>Y</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Implanted electrical device ?" && item.optionText === 'Yes'
                                        ))} /></div>

                                        <div className='rectanlbox1'> <label>N</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Implanted electrical device ?" && item.optionText === 'No'
                                        ))} /></div>
                                    </div>
                                </div>
                                <div className="boxxfirst">
                                    <div className='eyesimplt'>
                                        <div>Neurostimulator</div>
                                        <div className='ss'>न्यूरोस्टिम्युलेटर</div>
                                    </div>
                                    {/* <div className='yesno' >
                                        <div className='rectanlbox'>Y</div>
                                        <div className='rectanlbox'>N</div>
                                    </div> */}
                                    <div className='yesno' >

                                        <div className='rectanlbox1'> <label>Y</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Neurostimulator ?" && item.optionText === 'Yes'
                                        ))} /></div>

                                        <div className='rectanlbox1'>  <label>N</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Neurostimulator ?" && item.optionText === 'No'
                                        ))} /></div>
                                    </div>
                                </div>
                                <div className="boxxfirst">
                                    <div className='eyesimplt'>
                                        <div>Stents(Cardiac)/</div>
                                        <div className='ss'>दयकेस्टट</div>
                                    </div>
                                    {/* <div className='yesno' >
                                        <div className='rectanlbox1'>Y</div>
                                        <div className='rectanlbox1'>N</div>
                                    </div> */}
                                    <div className='yesno' >

                                        <div className='rectanlbox1'>  <label>Y</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Stents (Cardiac) ?" && item.optionText === 'Yes'
                                        ))} /></div>

                                        <div className='rectanlbox1'><label>N</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Stents (Cardiac) ?" && item.optionText === 'No'
                                        ))} /></div>
                                    </div>
                                </div>
                                <div className="boxxfirst">
                                    <div className='eyesimplt'>
                                        <div>Magnetic dental Implants/</div>
                                        <div className='ss'>चुंबकीयदंतप्रत्यारोपण</div>
                                    </div>
                                    {/* <div className='yesno' >
                                        <div className='rectanlbox'>Y</div>
                                        <div className='rectanlbox'>N</div>
                                    </div> */}
                                    <div className='yesno' >

                                        <div className='rectanlbox1'><label>Y</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Magnetic dental Implant ?" && item.optionText === 'Yes'
                                        ))} /></div>

                                        <div className='rectanlbox1'> <label>N</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any Magnetic dental Implant ?" && item.optionText === 'No'
                                        ))} /></div>
                                    </div>
                                </div>
                                <div className="boxxfirst">
                                    <div className='eyesimplt'>
                                        <div>H/O Recent Previous Contrast CT (MRI)
                                            CT/ MRI </div>
                                        <div className='ss'>अध्यय० का इतिहास
                                        </div>
                                    </div>
                                    {/* <div className='yesno' >
                                        <div className='rectanlbox'>Y</div>
                                        <div className='rectanlbox'>N</div>
                                    </div> */}
                                    <div className='yesno' >

                                        <div className='rectanlbox1'><label>Y</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any H/O Recent Previous Contrast CT (MRI) ?" && item.optionText === 'Yes'
                                        ))} /></div>

                                        <div className='rectanlbox1'> <label>N</label><input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                            item.questionName === "Do you have any H/O Recent Previous Contrast CT (MRI) ?" && item.optionText === 'No'
                                        ))} /></div>
                                    </div>
                                </div>

                            </div>
                        </td>
                    </tr>
                    <tr> 
                    <td colSpan={8}>
                        <div className="affix-pixtext mb-2 mt-3" style={{ paddingLeft: '5px' }}> <strong>Are you carrying these?</strong></div>
                    </td>
                    </tr>
                    <tr >
                        <td colSpan={8} className='ps-0 pe-0'>
                            <div style={{ padding: '0px 10px' }}>
                                <table>
                                    <tr>
                                        <td className='ps-0 pe-0' colSpan={8}>
                                            <table className="table tblbdr mb-0 pt-0">
                                                <tr>
                                                    <th colSpan={6}>Previous MRI पिछलाएमआरआई : <span>&nbsp;Y&nbsp;<input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                                        item.questionName === "Are you carrying Previous MRI ?" && item.optionText === 'Yes'
                                                    ))} />&nbsp;&nbsp;&nbsp;N&nbsp;<input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                                        item.questionName === "Are you carrying Previous MRI ?" && item.optionText === 'No'
                                                    ))} /></span> </th>
                                                    <th colSpan={2}>Previous CT पिछला सीटी : <span>&nbsp;Y&nbsp;<input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                                        item.questionName === "Are you carrying Previous CT ?" && item.optionText === 'Yes'
                                                    ))} />&nbsp;&nbsp;&nbsp;N&nbsp;<input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                                        item.questionName === "Are you carrying Previous CT ?" && item.optionText === 'No'
                                                    ))} /> </span></th>
                                                </tr>
                                                <tr>
                                                    <th colSpan={6}>Previous X-Ray पिछला एक्स-रे :  <span>&nbsp;Y&nbsp;<input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                                        item.questionName === "Are you carrying Previous X-Ray ?" && item.optionText === 'Yes'
                                                    ))} />&nbsp;&nbsp;&nbsp;N&nbsp;<input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                                        item.questionName === "Are you carrying Previous X-Ray ?" && item.optionText === 'No'
                                                    ))} /></span></th>
                                                    <th colSpan={2} >Previous Ultrasound पिछलाअल्ट्रासाउंड : <span>&nbsp;Y&nbsp;<input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                                        item.questionName === "Are you carrying Previous Ultrasound ?" && item.optionText === 'Yes'
                                                    ))} />&nbsp;&nbsp;&nbsp;N&nbsp;<input type="checkbox" checked={jsonQuestionaireData && jsonQuestionaireData.some((item, index) => (
                                                        item.questionName === "Are you carrying Previous Ultrasound ?" && item.optionText === 'No'
                                                    ))} /></span> </th>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className='ps-0 pe-0'>
                            <div style={{ padding: '0px 10px' }}>
                                <table className='mt-3 ps-0 pe-0'>
                                    <tr>
                                        <td colSpan={8}><strong>Patient / Attendant Signature </strong><span style={{ width: '430px', borderBottom: '1px dashed black', display: 'inline-block' }}></span> </td>

                                    </tr>
                                    <tr>
                                        <td colSpan={8}><div className='mt-3 mb-1'><strong>MRI Technician Signature </strong><span style={{ width: '450px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div> </td>

                                    </tr>
                                    <tr> <td colSpan={8}>
                                        <div className="affix-pixtext mb-0"> <strong></strong></div>
                                    </td></tr>
                                </table>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </>
    )
}
