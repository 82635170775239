// async function GetTaskNotAssignUser(data) {
//     let url = window.SpringBoardServicesUrl+"api/SprintBacklogMaster/GetTaskNotAssigneUserList="+data;
//     let head = { "Content-Type": "application/JSON", accept : '*/*' };

//   let response = fetch(url, {
//     headers: head,
//     method : 'GET'
//   }).then((res) => res.json()).then(data);

//   return response;
// }
// export default GetTaskNotAssignUser;

async function GetTaskNotAssignUser(data) {
  try {
      let url = window.SpringBoardServicesUrl + "api/SprintBacklogMaster/GetTaskNotAssigneUserList?ReportDate=" + data;
      let head = { "Content-Type": "application/json", accept: "*/*" };

      let response = await fetch(url, {
          headers: head,
          method: 'GET'
      });

      if (!response.ok) {
          throw new Error('Failed to fetch data');
      }

      let jsonData = await response.json();
      return jsonData;
  } catch (error) {
      console.error('Error in fetching data:', error);
      throw error;
  }
}

export default GetTaskNotAssignUser;
