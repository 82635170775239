import React, { useEffect, useState } from 'react';
import i18n, { t } from "i18next";
import GetAllTrackItemByDemandId from './API/GET/GetAllTrackItemByDemandId';
import Search from '../../../Code/Serach';

import NoDataFound from '../../../assets/images/icons/No data-rafiki.svg';
import NoImage from '../../../assets/images/icons/icons8-remove-image-64.png';

export default function TrackDemandItemList() {

  const [noDataStatus, setNoDataStatus] = useState(false);
  const [itemList, setItemList] = useState([])
  const [itemListMain, setItemListMain] = useState([])

  const demandID = sessionStorage.getItem('trackDemandId');
  const clientID = JSON.parse(sessionStorage.getItem("LoginData")).clientId;

  const showApprovedItemsOnly = (show) => {
    // Your logic to handle showing/hiding approved items based on the 'show' parameter
    if (show) {
        // Logic to show approved items
       
        funDemandIdItems(1)
    } else {
        // Logic to hide approved items
        
        funDemandIdItems(0)
    }
}

  // Fun to bind the list of items on basis of demandId and clientId
  const funDemandIdItems = async (theStat) => {
    const res = await GetAllTrackItemByDemandId(demandID, clientID, theStat)
    if (res.status === 1) {
      setItemList(res.responseValue);
      setItemListMain(res.responseValue);
    }
    else {
      setTimeout(() => {
        setNoDataStatus(true)
      }, 2000)
    }
  }

  // to searchh
  let handleSearch = (e) => {
    let resp = Search(itemListMain, e.target.value)
    if (e.target !== "") {
      if (resp.length !== 0) {
        setItemList(resp)
      }
      else {
        setItemList([])
      }
    }
    else {
      setItemList(itemListMain)
    }
  }

  useEffect(() => {
    funDemandIdItems(0);
  }, [])
  return (<>

    <section className="main-content mt-5 pt-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="med-box">
              <div className='trackhead'>
                <div className='trackhead-inn'>
                  <div className="titleHeading text-wrap" style={{ borderLeft: '2px solid #1d4999', paddingLeft: '10px' }}>Demand Item Details</div>
                </div>
                <div className='trackhead-inn-combine'>
                  <div className="tradeApproved">
                    <div className='d-flex flex-wrap align-items-center'>

                      <span className='demandText'>Only show approved demands</span>
                      <div className="form-check form-switch ms-2">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onClick={(e) => {showApprovedItemsOnly(e.target.checked)}}/>
                      </div>
                    </div>

                  </div>
                  <form className="d-flex ser">
                    <i className="fa fa-search"></i>
                    <input type="search" className="form-control form-control-sm" placeholder="Search.." onChange={handleSearch} />
                  </form>
                </div>
                {/* <div className='trackhead-inn'>
                  <form className="d-flex ser">
                    <i className="fa fa-search"></i>
                    <input type="search" className="form-control form-control-sm" placeholder="Search.." />
                  </form>
                </div> */}

              </div>
              <div className="med-table-section med-table-section alldemandtbl pt-0" style={{ height: '84vh', padding: '0 5px', position: 'relative' }}>
                <table className='med-table border_ striped'>
                  <thead>
                    <tr style={{ background: ' #EAF6FF' }}>
                      <th className="text-center" style={{ "width": "4%", color: '#546788', fontWeight: '500', fontSize: '14px' }}>#</th>
                      <th className="text-center" style={{ color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Img")} </th>
                      <th style={{ color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Product Name & Brand")} </th>
                      <th style={{ color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Item Code")} </th>
                      <th style={{ color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Category")} </th>
                      <th style={{ color: '#546788', fontWeight: '500', fontSize: '14px', }}>{t("Unit")} </th>
                      <th style={{ color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Demanded Qty")} </th>
                      <th style={{ color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Approved Qty")} </th>
                      <th style={{ color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Approval Status")} </th>
                      <th style={{ color: '#546788', fontWeight: '500', fontSize: '14px' }}>{t("Store Status")} </th>

                    </tr>
                  </thead>
                  <tbody>
                    {noDataStatus ? (
                      <tr>
                        <td colSpan="9" className="text-center">
                          <div className='imageNoDataFound'>
                            <img src={NoDataFound} alt="imageNoDataFound" />
                          </div>
                        </td>
                      </tr>
                    ) : <>
                      {itemList && itemList.map((list, ind) => {
                        console.log('listtt : ', list)
                        return (
                          <tr key={ind}>
                            <td className='text-center'>{ind + 1}</td>
                            <td className="text-center invImg"><img src={list.imgUrl} className='icnn' alt='' style={{ width: '30px', height: '40px' }} /></td>
                            <td ><span className='trackItemList'>{list.itemName?list.itemName:'--NA--'}</span><br /><span style={{ color: '#929292', fontWeight: '400', fontSize: '14px' }}>{list.brandName}</span></td>
                            <td><span className='trackItemList'>{list.itemCode?list.itemCode:'--NA--'}</span></td>
                            <td><span className='trackItemList'>{list.categoryName?list.categoryName:'--NA--'}</span></td>
                            <td><span className='trackItemList'>{'--NA--'}</span></td>
                            <td><span className='trackItemListQty'>{list.demandQty}</span></td>
                            <td><span className='trackItemListQty'>{list.approvedQty}</span>   </td>
                            <td>
                              {list.approveStatus === 1 ? (
                                <span className='trackApproved'>Approved</span>
                              ) : list.approveStatus === 0 ? (
                                <span className='trackPending'>Pending</span>
                              ) : (<>
                                <span className='trackRejected'>Rejected</span>
                                <br />
                              </>
                               
                              )}
                              
                            </td>
                            <td>
                              {list.allotStatus === 1 ? (
                                <span className='trackApproved'>Alloted</span>
                              ) : 
                                <span className='trackPending'>Pending</span>
                              }
                              
                            </td>

                          </tr>
                        )
                      })}
                    </>}
                    {/* <tr>
                      <td className='text-center'>1</td>
                      <td className="text-center invImg"><img src={iphoneImg} alt="" /></td>
                      <td ><span style={{color:'black' , fontWeight:'400', fontSize:'14px'}}>Iphone 14 Pro</span><br/><span style={{color:'#929292',fontWeight:'400', fontSize:'14px'}}>Apple</span></td>
                      <td><span style={{color:'black' , fontWeight:'400', fontSize:'14px'}}>IT989</span></td>
                      <td><span style={{color:'black' , fontWeight:'400', fontSize:'14px'}}>Electronics</span></td>
                      <td><span style={{color:'black' , fontWeight:'400', fontSize:'14px'}}>Piece</span></td>
                      <td><span style={{color:'#2A62C8' , fontWeight:'500', fontSize:'14px'}}>4</span></td>
                      <td><span style={{color:'#2A62C8' , fontWeight:'500', fontSize:'14px'}}>2</span></td>
                      <td><span style={{color:'black' , fontWeight:'400', fontSize:'14px'}}>Just to test</span></td>
                      <td><span style={{color:'black' , fontWeight:'400', fontSize:'14px'}}>My remark</span></td>
                      <td><span style={{color:'#167642' , fontWeight:'500', fontSize:'14px'}}>Approved</span></td>
                    </tr> */}
                    {/* <tr>
                      <td className='text-center'>1</td>
                      <td className="text-center invImg"><img src={iphoneImg} alt="" /></td>
                      <td ><span style={{color:'black' , fontWeight:'400', fontSize:'14px'}}>Iphone 14 Pro</span><br/><span style={{color:'#929292',fontWeight:'400', fontSize:'14px'}}>Apple</span></td>
                      <td><span style={{color:'black' , fontWeight:'400', fontSize:'14px'}}>IT989</span></td>
                      <td><span style={{color:'black' , fontWeight:'400', fontSize:'14px'}}>Electronics</span></td>
                      <td><span style={{color:'black' , fontWeight:'400', fontSize:'14px'}}>Piece</span></td>
                      <td><span style={{color:'#2A62C8' , fontWeight:'500', fontSize:'14px'}}>4</span></td>
                      <td><span style={{color:'#2A62C8' , fontWeight:'500', fontSize:'14px'}}>2</span></td>
                      <td><span style={{color:'black' , fontWeight:'400', fontSize:'14px'}}>Just to test</span></td>
                      <td><span style={{color:'black' , fontWeight:'400', fontSize:'14px'}}>My remark</span></td>
                      <td><span style={{color:'red' , fontWeight:'500', fontSize:'14px'}}>Rejected</span><br/>
                        <span style={{color:'grey' , fontWeight:'300', fontSize:'12px', cursor:'pointer'}} id='#PersonWorkspace' data-bs-toggle="modal" data-bs-target={"#exampleModal" }>See Remarks</span>
                        <div className="modal fade" id={"exampleModal"} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                <div className="modal-dialog  modal-lg" style={{ margin: '10% auto' }}>
                                                                    <div className="modal-content">
                                                                        <div className="modal-header_ bg-clrr mt-0 pt-0 mb-0 pb-0">
                                                                            <div className="heading text-wrap">Remark</div>
                                                                            <div>
                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                                                                                                                                                      
                                                                                ></button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="modal-body mt-0 pt-0">
                                                                            <div className="med-box" style={{ boxShadow: 'inherit' }}>
                                                                                <div className="inner-content mt-0 pt-0">
                                                                                    <div className='row'>
                                                                                        
                                                                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-3" >
                                                                                            
                                                                                            <input type="text" className="form-control form-control-sm"  placeholder="Please enter remark here" id={'remarkID'}  name='remarkName'   readOnly/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                      </td>
                    </tr> */}
                    {/* <tr>
                      <td className='text-center'>1</td>
                      <td className="text-center invImg"><img src={iphoneImg} alt="" /></td>
                      <td ><span style={{color:'black' , fontWeight:'400', fontSize:'14px'}}>Iphone 14 Pro</span><br/><span style={{color:'#929292',fontWeight:'400', fontSize:'14px'}}>Apple</span></td>
                      <td><span style={{color:'black' , fontWeight:'400', fontSize:'14px'}}>IT989</span></td>
                      <td><span style={{color:'black' , fontWeight:'400', fontSize:'14px'}}>Electronics</span></td>
                      <td><span style={{color:'black' , fontWeight:'400', fontSize:'14px'}}>Piece</span></td>
                      <td><span style={{color:'#2A62C8' , fontWeight:'500', fontSize:'14px'}}>4</span></td>
                      <td><span style={{color:'#2A62C8' , fontWeight:'500', fontSize:'14px'}}>2</span></td>
                      <td><span style={{color:'black' , fontWeight:'400', fontSize:'14px'}}>Just to test</span></td>
                      <td><span style={{color:'black' , fontWeight:'400', fontSize:'14px'}}>My remark</span></td>
                      <td><span style={{color:'#167642' , fontWeight:'500', fontSize:'14px'}}>Approved</span></td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
