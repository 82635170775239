function ValidationLeaveMaster(leaveName = "",) {
    if (leaveName) {
        return [true, ""];
    }

    else if (!leaveName) {
        return [false, "Leave Name field can't be blank"];
    }

}

export default ValidationLeaveMaster;