const GetStoreItemDetailsByDemandId = async (demandID, clientID, allotmentStatus) =>{
   const url = window.InventoryBaseUrl+'/api/InventoryRaiseDemand/GetStoreItemDetailsByDemandId?ClientID='+clientID+'&GetDemandID='+demandID+'&AllotmentStatus='+allotmentStatus;
  // const url = 'https://localhost:7264/api/InventoryRaiseDemand/GetStoreItemDetailsByDemandId?ClientID='+clientID+'&GetDemandID='+demandID+'&AllotmentStatus='+allotmentStatus;
  const head = {'content-type' : 'application/json','accept':'*/*'};
  const data = {};
  const response = await fetch(url, {
      method: 'GET',
      header: head,
  }).then(res => res.json()).then(data);
  return response;
};
export default GetStoreItemDetailsByDemandId;