import { useEffect, useState } from "react";
import TimeCalculate from "../../../Code/TimeCalculate";
import LineVitalGrap from "../../../Components/LineVitalGrap";
import GetAllDashboardParameterColumnAssign from "../../../Api/GetAllDashboardParameterColumnAssign";
import { useSelector } from "react-redux";
import store from "../../../../Store";
import { getPatientMonitorDashboard } from "../../../../Reduce/PatientMonitordashboard/PatientMonitordashboard";

export default function PatientSpo2R(props) {
  let [spo2, setSpo2] = useState("");
  let [spo2Id, setSpo2Id] = useState("");
  let [spo2Time, setSpo2Time] = useState("");
  let [spo2Color, setSpo2Color] = useState("");
  let [spo2Alert, setSpo2Alert] = useState(0);
  let [uhId, setUhId] = useState("")
  // let store.dispatch(getIPDUHIDChangeData(patientList[ind].uhId))

  // set grap vital data
  let [grapVitalData, setGrapVitalData] = useState({
    showGrap: 0,
    vitalIdSearchNew: '',
    patientName: '',
    UHID: '',
    userId: ''
  })


  let patientMonitorDashboard = useSelector((state) => state.PatientMonitorDashboard.alertData)
  let getData = () => {
    setSpo2("");
    setSpo2Id("");
    setSpo2Time("");
    setSpo2Color("");
    setSpo2Alert("")
    props.val.VitalParametersList && props.val.VitalParametersList.map((vital, ind) => {
      if (vital.VitalID.toString() === "56") {
        setSpo2(Math.round(vital.VitalValue * 100) / 100)
        setSpo2Color(vital.VitalColor)
        if (props.handleColor !== undefined) {
          props.handleColor(vital.VitalColor !== null ? vital.VitalColor : "white", props.columnId)
      }

        setSpo2Id(vital.VitalID)
        setUhId(props.val.UhId)
        if (vital.VitalDateTime !== '') {
          setSpo2Time(TimeCalculate(vital.VitalDateTime))
        }
        if (vital.VitalColor !== "#008000" && vital.VitalColor !== "#94e094" && vital.VitalColor !== "#F5F8FF" && vital.VitalColor !== "") {
          setSpo2Alert(0)
          let data = patientMonitorDashboard["spo2_13"]
          if (vital.VitalColor.includes("Red")) {
            let present = data.filter(val => val === vital.VitalColor)
            if (present.length === 0) {
              store.dispatch(getPatientMonitorDashboard({ ...patientMonitorDashboard, ["spo2_13"]: [vital.VitalColor, data[1], data[2]] }))
              // console.log("patientMonitorDashboardspo2", { ...patientMonitorDashboard, ["spo2_13"]: [vital.VitalColor, data[1], data[2]] })

            }
          }

          else if (vital.VitalColor.includes("#c5756d")) {
            let present = data.filter(val => val === vital.VitalColor)
            if (present.length === 0) {
              store.dispatch(getPatientMonitorDashboard({ ...patientMonitorDashboard, ["spo2_13"]: [data[0], vital.VitalColor, data[2]] }))
            }
          }
          else if (vital.VitalColor.includes("yellow")) {
            let present = data.filter(val => val === vital.VitalColor)
            if (present.length === 0) {
              store.dispatch(getPatientMonitorDashboard({ ...patientMonitorDashboard, ["spo2_13"]: [data[0], data[1], vital.VitalColor] }))
            }

          }

        }

      }
    })
  }

  let modelCloseFun = () => {
    setGrapVitalData({
      showGrap: 0,
      vitalIdSearchNew: '',
      UHID: '',
      userId: ''
    })
  }

  let [paramenterData, setPatameterData] = useState([])
  let response = useSelector((state) => state.AllColumnsParamters)
  let getColumnsParameters = () => {
    let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
       if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

  }


  useEffect(() => {
    getColumnsParameters()
    getData()
  }, [props.val])

  return (
    <>
      {/* <div key={props.key} className={`overflow-max vitalClass ${(spo2Alert === 1 ? "blink" : "")} `} style={{ border: (spo2Alert === 1 ? "2px solid " + spo2Color : "") }}> */}
      <div key={props.key} className={`overflow-max vitalClass`}>
        {spo2.length != 0 ? spo2 ? <span>
          {
            paramenterData && paramenterData.map((val, ind) => {
              if (val.parameterId === 44) {
                return (
                  <>
                    <span style={{ color: spo2Color }}>{spo2}</span><br />
                  </>

                )
              }

              else if (val.parameterId === 45) {
                return (
                  <>
                    {
                      spo2Time.split("-")[1] === "D" || (spo2Time.split("-")[0].split(":")[0] >= 2 && spo2Time.split("-")[1] === "H") ? <span style={{ 'color': 'red', fontSize: "0.7rem" }} className="blink"><i className="bi bi-clock"></i> {spo2Time.toLowerCase().replace("-", "")}</span>

                        : (spo2Time.split(":")[0] >= 1 && spo2Time.split("-")[1] === "H") ? <span style={{ "color": 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {spo2Time.toLowerCase().replace("-", "")}</span>

                          : (spo2Time.split("-")[0].split(":")[0] <= 1 && spo2Time.split("-")[1] === "H") || (spo2Time.split("-")[0] >= 10 && spo2Time.split("-")[1] === "M") ? <span style={{ 'color': '#ffa500', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {spo2Time.toLowerCase().replace("-", "")}</span> : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {spo2Time.toLowerCase().replace("-", "")}</span>}
                    <br />
                  </>

                )
              }

              else if (val.parameterId === 46 && props.from === undefined) {
                return (
                  <>
                    <div className="" title='SPO2 Graph' ><i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapVitalData({ showGrap: 1, vitalIdSearchNew: [spo2Id], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "SPO2 Graph", patientName: props.val.PntName }) }}></i></div>
                    {/* <br /> */}
                  </>

                )
              }


            })

          }

        </span> : 'N/A' : "N/A"}
      </div>

      {grapVitalData.showGrap ? <LineVitalGrap grapVitalData={grapVitalData} modelCloseFun={modelCloseFun} /> : ""}

    </>
  )
}

export function PatientSpo2RG(props) {
  let [spo2, setSpo2] = useState("");
  let [spo2Id, setSpo2Id] = useState("");
  let [spo2Time, setSpo2Time] = useState("");
  let [spo2Color, setSpo2Color] = useState("");
  let [spo2Alert, setSpo2Alert] = useState(0);
  let [uhId, setUhId] = useState("")
  // let store.dispatch(getIPDUHIDChangeData(patientList[ind].uhId))

  // set grap vital data
  let [grapVitalData, setGrapVitalData] = useState({
    showGrap: 0,
    vitalIdSearchNew: '',
    patientName: '',
    UHID: '',
    userId: ''
  })


  let patientMonitorDashboard = useSelector((state) => state.PatientMonitorDashboard.alertData)
  let getData = () => {
    setSpo2("");
    setSpo2Id("");
    setSpo2Time("");
    setSpo2Color("");
    setSpo2Alert("")
    props.val.VitalParametersList && props.val.VitalParametersList.map((vital, ind) => {
      if (vital.VitalID.toString() === "56") {
        setSpo2(Math.round(vital.VitalValue * 100) / 100)
        setSpo2Color(vital.VitalColor)
        if (props.setColors !== undefined) {
          props.setColors((prevArray) => ({ ...prevArray, "SPO2": vital.VitalColor }))
        }

        setSpo2Id(vital.VitalID)
        setUhId(props.val.UhId)
        if (vital.VitalDateTime !== '') {
          setSpo2Time(TimeCalculate(vital.VitalDateTime))
        }
        if (vital.VitalColor !== "#008000" && vital.VitalColor !== "#94e094" && vital.VitalColor !== "#F5F8FF" && vital.VitalColor !== "") {
          setSpo2Alert(0)
          let data = patientMonitorDashboard["spo2_13"]
          if (vital.VitalColor.includes("Red")) {
            let present = data.filter(val => val === vital.VitalColor)
            if (present.length === 0) {
              store.dispatch(getPatientMonitorDashboard({ ...patientMonitorDashboard, ["spo2_13"]: [vital.VitalColor, data[1], data[2]] }))
              // console.log("patientMonitorDashboardspo2", { ...patientMonitorDashboard, ["spo2_13"]: [vital.VitalColor, data[1], data[2]] })

            }
          }

          else if (vital.VitalColor.includes("#c5756d")) {
            let present = data.filter(val => val === vital.VitalColor)
            if (present.length === 0) {
              store.dispatch(getPatientMonitorDashboard({ ...patientMonitorDashboard, ["spo2_13"]: [data[0], vital.VitalColor, data[2]] }))
            }
          }
          else if (vital.VitalColor.includes("yellow")) {
            let present = data.filter(val => val === vital.VitalColor)
            if (present.length === 0) {
              store.dispatch(getPatientMonitorDashboard({ ...patientMonitorDashboard, ["spo2_13"]: [data[0], data[1], vital.VitalColor] }))
            }

          }

        }

      }
    })
  }

  let modelCloseFun = () => {
    setGrapVitalData({
      showGrap: 0,
      vitalIdSearchNew: '',
      UHID: '',
      userId: ''
    })
  }

  let [paramenterData, setPatameterData] = useState([])
  let response = useSelector((state) => state.AllColumnsParamters)
  let getColumnsParameters = () => {
    let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
       if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

  }


  useEffect(() => {
    getColumnsParameters()
    getData()
  }, [props.val])

  return (
    <>
      <div key={props.key} >
        {spo2.length != 0 ? spo2 ? <>
          {

            paramenterData && paramenterData.map((val, ind) => {
              if (val.parameterId === 46) {

                return (
                  <>
                    <div className="" title='SPO2 Graph' ><i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapVitalData({ showGrap: 1, vitalIdSearchNew: [spo2Id], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "SPO2 Graph", patientName: props.val.PntName }) }}></i></div>
                    {/* <br /> */}
                  </>

                )
              }


            })

          }

        </> : 'N/A' : "N/A"}
      </div>

      {grapVitalData.showGrap ? <LineVitalGrap grapVitalData={grapVitalData} modelCloseFun={modelCloseFun} /> : ""}

    </>
  )
}

