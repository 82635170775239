async function ProcedureWithSedationGet (requiredUHID) {

    let url = window.OTBaseURL + `ProcedureWithSedation/Get?UHID=${requiredUHID}&clientId=${window.clientId}`
    let head = {
      'Content-Type': 'application/JSON',
      accept: '*/*',
    }
    let response =
      await fetch(url, {
        method: 'GET',
        headers: head,
      })
        .then((res) => res.json())
        // .then(res)
  
  
    return response;
  }
  export default ProcedureWithSedationGet;
  