let DeleteBloodVisit = async(obj) =>
{
  let url = window.BloodbaseUrl + 'api/VistDonor/DeleteVistDonor';
  let head = {'Content-Type':'application/json-patch+json','accept':'*/*',};
  let data = {};
  let response = await fetch(url, {
    method: 'DELETE',
    headers: head,
    body : JSON.stringify(obj),
  }).then(res => res.json()).then(data);
  return response;
}
export default DeleteBloodVisit;