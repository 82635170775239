import { useEffect, useState } from "react"
import GetAllDashboardParameterColumnAssign from "../../../Api/GetAllDashboardParameterColumnAssign"
import { useSelector } from "react-redux"

export default function PatientSPO2L(props) {
    let [spo2, setSpo2] = useState(
        {
            VitalValue: "N/A",
            VitalColor: "gray"
        }
    )
    let [paramenterData, setPatameterData] = useState([])
    let response = useSelector((state) => state.AllColumnsParamters)
    let getColumnsParameters = () => {
        let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
           if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

    }
    
    useEffect(() => {
        getColumnsParameters()

        let spo2 = {};
        // console.log("  props.val.AdmitTimeVitalsList", props)
        props.val.AdmitTimeVitalsList && props.val.AdmitTimeVitalsList.map((val) => {
            if (val.VitalID === 56) {
                spo2.VitalValue = val.VitalValue

                spo2['VitalColor'] = val.VitalColor

                setSpo2(spo2)


            }

        })
    }, [props])
    return (
        <>

            {

                paramenterData && paramenterData.map((val, ind) => {
                    if (val.parameterId === 37) {
                        return (
                            <div className="overflow-max vitalClass text-white_" style={{ color: spo2.VitalColor }} >{spo2.VitalValue} <br />{/*<span>{spo2.time}</span>*/}</div>

                        )
                    }
                    
                })

            }
           
        </>
    )
}