import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react'
import printuse from '../../../assets/css/printuse.css'
// import SarvodayaLogo from '../../../assets/images/icons/sarvodayalogo.png'
import Vector from "../../../assets/images/icons/Vector.svg";
import Contact from "../../../assets/images/icons/Contact.svg";
import PatientLabel from '../../../Component/PatientLabel';
import PrintPageHeader from '../../../Component/PrintPageHeader';
export default function ReceiptInitialAssesmentFormPhysiotherepy() {


    let date = new Date()
    let [dateTime, setDateTime] = useState();
    let [clientData, setClientData] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientdata)
    let [assemtPhysioPrintData, setAssemtPhysioPrintData] = useState(JSON.parse(window.sessionStorage.getItem("PrintAssessmentPhysioForm")))
    let contact = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].mobileNo;
    let procedureData = assemtPhysioPrintData.procedure
    let historyJson = assemtPhysioPrintData.historyData
    let diagnosisJson = assemtPhysioPrintData.diagnosisData
    let symtomnsJson = assemtPhysioPrintData.symptomsData

    console.log("procedureData", procedureData)
    useEffect(() => {
        // functionGetLoginData()
        setDateTime(date.toLocaleDateString() + " " + date.toLocaleTimeString());
        // getPurchaseSubDetails()
        // getPTData();

        setTimeout(() => {
            handlepritnt();
        }, 1200)

    }, [])

    let handlepritnt = () => {
        document.title = 'Assessment Form Physiotherapy'; // Set the desired title here

        window.print("");
        window.close();

    }

    return (
        <>
            <div className="main-table" style={{ padding: '5px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <table className='microtble pt-4 mt-4'>
                    {/* ....header.. */}
                    <tr>
                        <td colSpan={8}>
                            <PrintPageHeader />

                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td colSpan={6} style={{ verticalAlign: 'top', textAlign: 'center' }}>
                            <div className='micro-heading pt-1 pb-1 mt-1 mb-1' >
                                IN PATIENT INITIAL ASSESSMENT FORM PHYSIOTHERAPY
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td className='pt-0 mb-0 ps-0 pe-0' colSpan={6}>
                            <table className='table mb-0' style={{ border: '1px solid black' }}>
                                <tr>
                                    <td colSpan={4} className='pt-0 mb-0 '>
                                        <div className='d-flex gap-2 mt-0 pt-0 mb-0 pb-0'>
                                            <div className='d-flex'><strong>Date of Assessment:  </strong> <span style={{ width: '100px', borderBottom: '1px solid black', display: 'inline-block' }}>&nbsp;{assemtPhysioPrintData.formattedDate}</span> </div>
                                            <div className='d-flex'><strong>Time of Assessment: </strong><span style={{ width: '60px', borderBottom: '1px solid black', display: 'inline-block' }}>&nbsp;{assemtPhysioPrintData.formattedTime}</span></div>

                                        </div>
                                    </td>

                                    <td rowSpan={4} >
                                    <div className='mt-0 pt-0 mb-0 pb-0 headrlabeldata' style={{ borderLeft: '1px solid' }}>
                                    <PatientLabel /></div></td>

                                </tr>
                                <tr>
                                    <td colSpan={4}>
                                        <div className='d-flex gap-2 mt-0 pt-0 mb-0 pb-0'>
                                            <div className='d-flex '><strong>Reffered From: </strong>  <span style={{ width: '140px', borderBottom: '1px solid black', display: 'inline-block' }}>&nbsp;{assemtPhysioPrintData.refferedFrom}</span> </div>
                                            <div className='d-flex'><strong>Unit: </strong>  <span style={{ width: '50px', borderBottom: '1px solid black', display: 'inline-block' }}>&nbsp;{assemtPhysioPrintData.unit}</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>

                                    <td colSpan={4}>
                                    <div className='d-flex gap-2 mt-0 pt-0 mb-0 pb-0'>
                                            <div className='d-flex'><strong>Procedure: </strong> <span style={{ width: '200px', borderBottom: '1px solid black', display: 'inline-block' }}>&nbsp;{procedureData && procedureData.map((val, index) => val.itemName)}</span> </div>

                                            <div className='d-flex'><strong>Occupation:</strong> <span style={{ width: '150px', borderBottom: '1px solid black', display: 'inline-block' }}>&nbsp;{assemtPhysioPrintData.occupation}</span></div>

                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4}>
                                        <div className='assesmnt_'><strong>Contact No:</strong>
                                            <span style={{ width: '300px', borderBottom: '1px solid black', display: 'inline-block' }}>&nbsp;{contact}</span>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={6} className='mt-0 pt-0' style={{ border: '1px solid' }}>
                            <table lassName=''>
                                <tr>
                                    <td colSpan={6}>
                                        <div style={{ width: '675px', minHeight: '30px', wordWrap: 'break-word' }}>
                                            <strong>DIAGNOSIS</strong>: <span >&nbsp;{diagnosisJson && diagnosisJson.map((val, index) => (
                                                index > 0 ? `, ${val.details}` : val.details
                                            ))}</span>
                                        </div>

                                    </td>

                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={6} className='mt-0 pt-0' style={{ border: '1px solid' }}>
                            <table lassName=''>
                                <tr>
                                    <td colSpan={6}>
                                        <div style={{ width: '675px', minHeight: '30px', wordWrap: 'break-word' }}>
                                            <strong>CHIEF COMPLAINT</strong>: <span >{symtomnsJson && symtomnsJson.map((val, index) => (
                                                index > 0 ? `, ${val.details}` : val.details
                                            ))}</span>
                                        </div>
                                    </td>

                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={6} className='mt-0 pt-0' style={{ border: '1px solid' }}>
                            <table lassName=''>
                                <tr>
                                    <td colSpan={6}>
                                        <div style={{ width: '675px', minHeight: '30px', wordWrap: 'break-word' }}>
                                            <strong>PAST MEDICAL/SURGICAL HISTORY</strong>.: <span >{historyJson && historyJson.map((val, index) => (
                                                index > 0 ? `, ${val.details}` : val.details
                                            ))}</span>
                                        </div>
                                    </td>

                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='mt-0 pt-0 mb-0 pb-0' style={{ border: '1px solid', verticalAlign: 'top' }}>
                            <table className='physiodetailstbl'>
                                <tr>
                                    <td><div className='mt-0 mb-b'><strong>ON OBSERVATION</strong></div></td>
                                </tr>
                                <tr>
                                    <td style={{ width: '30%' }}><div>BUILD : </div></td>
                                    <td style={{ width: '10%' }}><div><input type="checkbox" checked={assemtPhysioPrintData.observation.isBuilt === "thin"} /><label>Thin</label></div></td>
                                    <td style={{ width: '10%' }}>  <div> <input type="checkbox" checked={assemtPhysioPrintData.observation.isBuilt === "fair"} /><label>FAIR</label></div></td>
                                    <td style={{ width: '20%' }}>  <div> <input type="checkbox" checked={assemtPhysioPrintData.observation.isBuilt === "wellBuilt"} /><label>WELL BUILT</label></div></td>
                                    <td colSpan={2} style={{ width: '30%' }}> <div> <input type="checkbox" checked={assemtPhysioPrintData.observation.isBuilt === "obese"} /><label>OBESE</label></div></td>
                                </tr>

                                <tr>
                                    <td>  <div>POSTURAL DEVIATION : </div></td>
                                    <td>  <div><input type="checkbox" checked={assemtPhysioPrintData.observation.isPostDeviation === "yes"} /><label>Yes</label></div></td>
                                    <td>    <div> <input type="checkbox" checked={assemtPhysioPrintData.observation.isPostDeviation === "no"} /><label>No</label></div></td>
                                    <td>  <div><div> <label>SPECIFY</label></div></div></td>
                                    <td colSpan={2}> <div className=""> <span style={{ width: '180px', borderBottom: '1px solid black', display: 'inline-block' }}>{assemtPhysioPrintData.observation.isSpecifyPostDev}</span>
                                    </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td> <div>MUSCLE WASTING : </div></td>
                                    <td><div><input type="checkbox" checked={assemtPhysioPrintData.observation.isMuscleWasting === "yes"} /><label>Yes</label></div></td>
                                    <td><div><div> <input type="checkbox" checked={assemtPhysioPrintData.observation.isMuscleWasting === "no"} /><label>NO</label></div></div></td>
                                    <td> <div><div> <label>SPECIFY</label></div></div></td>
                                    <td colSpan={2}> <div className=""> <span style={{ width: '180px', borderBottom: '1px solid black', display: 'inline-block' }}>{assemtPhysioPrintData.observation.isSpecifyMuscleWast}</span>
                                    </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td> <div>DEFORMITY : </div></td>
                                    <td><div><input type="checkbox" checked={assemtPhysioPrintData.observation.isDeformity === "yes"} /><label>Yes</label></div></td>
                                    <td><div><div> <input type="checkbox" checked={assemtPhysioPrintData.observation.isDeformity === "no"} /><label>NO</label></div></div></td>
                                    <td> <div><div> <label>SPECIFY</label></div></div></td>
                                    <td colSpan={2}> <div className=""> <span style={{ width: '180px', borderBottom: '1px solid black', display: 'inline-block' }}>{assemtPhysioPrintData.observation.isSpecifyDeformity}</span>
                                    </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>    <div>SWELLING :</div> </td>
                                    <td>  <div><input type="checkbox" checked={assemtPhysioPrintData.observation.isSwelling === "yes"} /><label>Yes</label></div></td>
                                    <td>     <div><div> <input type="checkbox" checked={assemtPhysioPrintData.observation.isSwelling === "no"} /><label>NO</label></div></div></td>
                                    <td>  <div><div><label>SPECIFY</label></div></div></td>
                                    <td colSpan={2}> <div className=""> <span style={{ width: '180px', borderBottom: '1px solid black', display: 'inline-block' }}>{assemtPhysioPrintData.observation.isSpecifySwell}</span>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td> <div>GAIT DEVIATION :</div></td>
                                    <td>  <div><input type="checkbox" checked={assemtPhysioPrintData.observation.isGaitDeviation === "yes"} /><label>Yes</label></div></td>
                                    <td>     <div><div> <input type="checkbox" checked={assemtPhysioPrintData.observation.isGaitDeviation === "no"} /><label>NO</label></div></div></td>
                                    <td>  <div><div> <label>SPECIFY</label></div></div></td>
                                    <td colSpan={2}> <div className=""> <span style={{ width: '180px', borderBottom: '1px solid black', display: 'inline-block' }}>{assemtPhysioPrintData.observation.isSpecifyGaitDev}</span>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>  <div>EXTERNAL APPLIANCES :</div></td>
                                    <td>  <div><input type="checkbox" checked={assemtPhysioPrintData.observation.isAppliances === "yes"} /><label>Yes</label></div></td>
                                    <td>     <div><div> <input type="checkbox" checked={assemtPhysioPrintData.observation.isAppliances === "no"} /><label>NO</label></div></div></td>
                                    <td>  <div><div><label>SPECIFY</label></div></div></td>
                                    <td colSpan={2}> <div className=""> <span style={{ width: '180px', borderBottom: '1px solid black', display: 'inline-block' }}>{assemtPhysioPrintData.observation.isSpecifyAppliance}</span>
                                    </div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    {/* ----------ON Palpation---------------------------- */}
                    <tr>
                        <td className='mt-0 pt-0 mb-0 pb-0' style={{ border: '1px solid', verticalAlign: 'top' }}>
                            <table className='physiodetailstbl'>
                                <tr>
                                    {/* <td colspan="6">
                                        <div className="printpalation">
                                            <div className='mt-0 mb-b'><strong>ON PALPATION</strong></div>
                                            <div style={{marginTop:'5px'}}>
                                                <input type="checkbox" checked={assemtPhysioPrintData.palpation.isInsignificant === "yes"} /><label>INSIGNIFICANT</label></div>
                                        </div>
                                    </td> */}
                                    <td colSpan={4}><strong>ON PALPATION</strong></td>
                                    <td colSpan={2}> <div style={{ marginTop: '5px' }}>
                                        <input type="checkbox" checked={assemtPhysioPrintData.palpation.isInsignificant === "yes"} /><label>INSIGNIFICANT</label>
                                    </div>
                                    </td>
                                </tr>



                                <tr>
                                    <td style={{ width: '30%' }}> <div>TENDERNESS : </div></td>
                                    <td style={{ width: '10%' }}><div><input type="checkbox" checked={assemtPhysioPrintData.palpation.isTenderness === "yes"} /><label>Yes</label></div></td>
                                    <td style={{ width: '10%' }}><div><div> <input type="checkbox" checked={assemtPhysioPrintData.palpation.isTenderness === "no"} /><label>NO</label></div></div></td>
                                    <td style={{ width: '20%' }}> <div><div> <label>SPECIFY</label></div></div></td>
                                    <td colSpan={2} style={{ width: '30%' }}> <div className=""> <span style={{ width: '180px', borderBottom: '1px solid black', display: 'inline-block' }}>{assemtPhysioPrintData.palpation.isSpecifyTenderness}</span>
                                    </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td> <div>WARMTH : </div></td>
                                    <td><div><input type="checkbox" checked={assemtPhysioPrintData.palpation.isWarmth === "yes"} /><label>Yes</label></div></td>
                                    <td><div><div> <input type="checkbox" checked={assemtPhysioPrintData.palpation.isWarmth === "no"} /><label>NO</label></div></div></td>
                                    <td> <div><div> <label>SPECIFY</label></div></div></td>
                                    <td colSpan={2}> <div className=""> <span style={{ width: '180px', borderBottom: '1px solid black', display: 'inline-block' }}>{assemtPhysioPrintData.palpation.isSpecifyWarmth}</span>
                                    </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>    <div>MUSCLE SPASM :</div> </td>
                                    <td>  <div><input type="checkbox" checked={assemtPhysioPrintData.palpation.isMuscleSpasm === "yes"} /><label>Yes</label></div></td>
                                    <td>     <div><div> <input type="checkbox" checked={assemtPhysioPrintData.palpation.isMuscleSpasm === "no"} /><label>NO</label></div></div></td>
                                    <td>  <div><div><label>SPECIFY</label></div></div></td>
                                    <td colSpan={2}> <div className=""> <span style={{ width: '180px', borderBottom: '1px solid black', display: 'inline-block' }}>{assemtPhysioPrintData.palpation.isSpecifyMuscleSpasm}</span>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td> <div>OEDEMA :</div></td>
                                    <td>  <div><input type="checkbox" checked={assemtPhysioPrintData.palpation.isOedema === "yes"} /><label>Yes</label></div></td>
                                    <td>     <div><div> <input type="checkbox" checked={assemtPhysioPrintData.palpation.isOedema === "no"} /><label>NO</label></div></div></td>
                                    <td>  <div><div> <label>SPECIFY</label></div></div></td>
                                    <td colSpan={2}> <div className=""> <span style={{ width: '180px', borderBottom: '1px solid black', display: 'inline-block' }}>{assemtPhysioPrintData.palpation.isSpecifyOedema}</span>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>  <div>CREPITUS :</div></td>
                                    <td>  <div><input type="checkbox" checked={assemtPhysioPrintData.palpation.isCrepitus === "yes"} /><label>Yes</label></div></td>
                                    <td>     <div><div> <input type="checkbox" checked={assemtPhysioPrintData.palpation.isCrepitus === "no"} /><label>NO</label></div></div></td>
                                    <td>  <div><div><label>SPECIFY</label></div></div></td>
                                    <td colSpan={2}> <div className=""> <span style={{ width: '180px', borderBottom: '1px solid black', display: 'inline-block' }}>{assemtPhysioPrintData.palpation.isSpecifyCrepitus}</span>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>  <div>TONE :</div></td>
                                    <td>  <div><input type="checkbox" checked={assemtPhysioPrintData.palpation.isTone === "yes"} /><label>Yes</label></div></td>
                                    <td>     <div><div> <input type="checkbox" checked={assemtPhysioPrintData.palpation.isTone === "no"} /><label>NO</label></div></div></td>
                                    <td>  <div><div><label>SPECIFY</label></div></div></td>
                                    <td colSpan={2}> <div className=""> <span style={{ width: '180px', borderBottom: '1px solid black', display: 'inline-block' }}>{assemtPhysioPrintData.palpation.isSpecifyTone}</span>
                                    </div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    {/* ----------ON Palpation---------------------------- */}




                    <tr>

                        <td className='mt-0 pt-0' style={{ border: '1px solid', verticalAlign: 'top' }}>
                            <table className='physioassmnt'>
                                <tr>
                                    <td colSpan={6}>
                                        <div><strong>FALL RISK SCREENING</strong></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <div><strong>FALL RISK SCREENING FOR ADULTS:</strong></div>
                                    </td>
                                    <td colSpan={2}>
                                        <div>AGE MORE THAN 65 YEARS :<input type="checkbox" checked={assemtPhysioPrintData.riskScreening.riskScreeningAdultsAge === "AGE MORE THAN 65 YEARS"} /></div>
                                    </td>
                                    <td colSpan={2}>
                                        <div>HISTORY OF FALL IN LAST 3 MONTH :<input type="checkbox" checked={assemtPhysioPrintData.riskScreening.riskScreeningAdultHistory === "HISTORY OF FALL IN LAST 3 MONTH"} /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        {/* <div>FALL RISK SCREENING FOR ADULTS:</div> */}
                                    </td>
                                    <td colSpan={2}>
                                        <div>WALK WITH ASSISTANCE:<input type="checkbox" checked={assemtPhysioPrintData.riskScreening.riskScreeningAdultWalk === "WALK WITH ASSISTANCE"} /></div>
                                    </td>
                                    <td colSpan={2}>
                                        <div>ANY NEUROLOGICAL PROBLEM :<input type="checkbox" checked={assemtPhysioPrintData.riskScreening.riskScreeningAdultNeuro === "ANY NEUROLOGICAL PROBLEM"} /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>
                                        <div className='mt-1 mb-1 pt-1 pb-1'>ANY NEUROLOGICAL PROBLEM
                                            IN CASE 2 OR MORE CRITERIA IS MET, INITIATE DETAILED FALL ASSESSMENT AND FALL PREVENTION PROTOCOL</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <div><strong>FALL RISK SCREENING FOR PAEDIATRICS :</strong></div>
                                    </td>
                                    <td colSpan={2}>
                                        <div>H/O FALL IN LAST 3 MONTHS :<input type="checkbox" checked={assemtPhysioPrintData.riskScreening.riskScreeningPaediaFall === "H/O FALL IN LAST 3 MONTHS"} /></div>
                                    </td>
                                    <td colSpan={2}>
                                        <div>DERARNGED MOBILITY :<input type="checkbox" checked={assemtPhysioPrintData.riskScreening.riskScreeningPaediaMobility === "DERARNGED MOBILITY"} /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        {/* <div><strong>FALL RISK SCREENING FOR PAEDIATRICS :</strong></div> */}
                                    </td>
                                    <td colSpan={2}>
                                        <div>NEUROLOGICAL PROBLEM (VERTIGO, SEIZURES,etc) </div>
                                    </td>
                                    <td colSpan={2}>
                                        <div><input type="checkbox" checked={assemtPhysioPrintData.riskScreening.riskScreeningPaediaNeurological == "NEUROLOGICAL PROBLEM"} /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>
                                        <div className='mt-1 mb-1 pt-1 pb-1'>IN CASE OF 2 OR MORE CRITERIA IS MET, INITIATE DETAILED FALL ASSESSMENT AND FALL PREVENTION PROTOCOL.</div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={6} className='mt-0 pt-0' style={{ border: '1px solid', verticalAlign: 'top' }}>
                            <table className=''>
                                <tr>
                                    <td colSpan={6}> <div className=' pt-1 pb-1'><strong>FALL RISK PREVENTION PROTOCOL</strong></div></td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>
                                        <div className='orientdetls mb-1  pb-1'>
                                            <div className='lfttxt'>
                                                <div>1. Orient the Patient and attendant about surrounding</div>
                                                <div>2. Instruct on use of bells</div>
                                            </div>

                                            <div className='lfttxt'> <div><input type="checkbox" checked={assemtPhysioPrintData.riskPrevention.riskPreventionProtocolSurround === "ORIENT THE PATIENT AND ATTENDANT ABOUNT THE SURROUNDING"} /></div>
                                                <div><input type="checkbox" checked={assemtPhysioPrintData.riskPrevention.riskPreventionProtocolBells === "INSTRUCT ON USE OF BELLS"} /></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div >
        </>
    )
}
