import React, { useEffect, useState } from 'react'
import printuse from '../../../assets/css/printuse.css'
import GetNursingAssessment from '../API/GetNursingAssessment';
import { useLocation } from 'react-router-dom';
import GetAllStatus from '../../../Dietetics/API/FoodIntake/GetAllStatus';
import GetAllPatientVitalsHourly from '../../../Clinical/API/OPD/Vitals/GetAllPatientVitalsHourly';
import GetAllAllergies from '../../../Clinical/Pages/Component/API/GetAllAllergies';
import PrintPageHeader from '../../../Component/PrintPageHeader';
import PatientLabel from '../../../Component/PatientLabel';
// import SarvodayaLogo from '../../../assets/images/icons/sarvodayalogo.png'

export default function NursingAdmissionInitialAssessmentADULT() {
    let [getNursingData, setGetNursingData] = useState([])
    let [modeofarrivalList, setModeOfArrivalList] = useState([])
    let [psychologyStatusList, setPsychologyStatusList] = useState([])
    let [patientStatusList, setPainStatusList] = useState([])
    let [appetiteList, setAppetiterList] = useState([])
    let [weightList, setWeightList] = useState([])
    let [patientList, setPatientList] = useState([])
    let [orientPatientList, setOrientPatientList] = useState([])
    let [orientPatientAttendantList, setOrientPatientAttendantList] = useState([])
    let [allergiesData, setAllergiesData] = useState([])
    const [patientWard, setPatientWard] = useState("");
    let [painCharacterList, setPainCharacterList] = useState([])

    const parseParameterJson = (jsonString) => {
        if (!jsonString) return [];
        return JSON.parse(jsonString);
    };

    const location = useLocation();
    let getData = async () => {
        const queryParams = new URLSearchParams(location.search);
        const uhID = queryParams.get('uhID');
        const index = queryParams.get('index');

        let patientdataList = JSON.parse(window.sessionStorage.getItem("IPDpatientList"))
        patientdataList.map((val, ind) => {
            if (val.uhId === uhID) {
                setPatientWard(val.wardName)
            }
        })
        // console.log("index", index)
        let responseNursing = await GetNursingAssessment(uhID);
        if (responseNursing.status === 1) {
            setGetNursingData(responseNursing.responseValue[index])
            setTimeout(() => {
                window.print()
                window.close()
            }, 600)
            let statusMaster = await GetAllStatus("Mode of arrival")
            if (statusMaster.status === 1) {
                setModeOfArrivalList(statusMaster.responseValue)
            }
            let psychologyStatus = await GetAllStatus("Psychological Status")
            if (psychologyStatus.status === 1) {
                setPsychologyStatusList(psychologyStatus.responseValue)
            }
            let painStatus = await GetAllStatus("Patient Status")
            if (painStatus.status === 1) {
                setPainStatusList(painStatus.responseValue)
            }

            let appetiteStatus = await GetAllStatus("Nutritional Screening")
            if (appetiteStatus.status === 1) {
                setAppetiterList(appetiteStatus.responseValue)
            }
            let weightStatus = await GetAllStatus("Nutritional Screening")
            if (weightStatus.status === 1) {
                setWeightList(weightStatus.responseValue)
            }
            let patientStatus = await GetAllStatus("Type of patient")
            if (patientStatus.status === 1) {
                setPatientList(patientStatus.responseValue)
            }
            let orientPatientStatus = await GetAllStatus("Orient Patient")
            if (orientPatientStatus.status === 1) {
                setOrientPatientList(orientPatientStatus.responseValue)
            }
            let orientPatientAttendantStatus = await GetAllStatus("Patient Attendent Orient")
            if (orientPatientAttendantStatus.status === 1) {
                setOrientPatientAttendantList(orientPatientAttendantStatus.responseValue)
            }

            let response = await GetAllAllergies(window.clientId);
            if (response.status === 1) {
                let nursingData = JSON.parse(responseNursing.responseValue[index].allergies)
                const allergyListData = response.responseValue;
                for (let i = 0; i <= allergyListData.length - 1; i++) {
                    if (nursingData[0].ParameterValue.toLowerCase() === "Yes".toLowerCase()) {

                        if (allergyListData[i].subCategoryName === "YES") {

                            if (JSON.parse(allergyListData[i].parameterJson)[0].parameterId !== null) {
                                const parameterList = parseParameterJson(allergyListData[i].parameterJson);
                                setAllergiesData(parameterList)
                                return
                            }

                        }


                    }
                    else {
                        if (allergyListData[i].subCategoryName === "NO") {
                            if (JSON.parse(allergyListData[i].parameterJson)[0].parameterId !== null) {
                                const parameterList = parseParameterJson(allergyListData[i].parameterJson);
                                setAllergiesData(parameterList)
                                return
                            }

                        }



                    }
                }
            }



        }
        else {
        }
    }
    useEffect(() => {
        getData()

    }, [])
    return (
        <>
            <div className="main-table" style={{ padding: '5px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <table className='microtble pt-1 mt-1'>
                    {/* ....header.. */}
                    <tr>
                        <td colSpan={6}>
                            <PrintPageHeader />
                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td colSpan={6} style={{ verticalAlign: 'top', textAlign: 'center' }}>
                            <div className='micro-heading' >
                                NURSING ADMISSION INITIAL ASSESSMENT (ADULT)
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={8} className='pt-0 mb-0 ps-0 pe-0'>
                            <table className='table pt-0 mb-0 pb-0 ps-0 pe-0' style={{ border: '1px solid black' }}>
                                <tr>
                                    <td colSpan={4}>
                                        <div className='d-flex gap-2 mt-0 pt-0 mb-0 pb-0'>
                                            <div className='assesmnt d-flex mt-0 pt-0 mb-0 pb-0'><strong>Date of Assessment:</strong>
                                                <span style={{ width: '85px', borderBottom: '1px dashed black', display: 'inline-block', marginLeft: '5px' }}>{getNursingData.length !== 0 && getNursingData.assessmentDate.split("T")[0]} </span> </div>

                                            <div className='assesmnt d-flex mt-0 pt-0 mb-0 pb-0'><strong>Time of Assessment: </strong> <span style={{ width: '55px', marginLeft: '5px', borderBottom: '1px dashed black', display: 'inline-block' }}>{getNursingData.length !== 0 && getNursingData.assessmentTime.split("T")[1]}</span> </div>

                                        </div>
                                    </td>
                                    <td rowSpan={4} >

                                        <div className='mt-0 pt-0 mb-0 pb-0 headrlabeldata' style={{ borderLeft: '1px solid' }}>
                                            <PatientLabel />
                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    {/* <td ><div className='assesmnt d-flex mt-0 pt-0 mb-0 pb-0'><strong>Time of Assessment: </strong> <span style={{ width: '60px', marginLeft: '5px', borderBottom: '1px dashed black', display: 'inline-block' }}>{getNursingData.length !== 0 && getNursingData.assessmentTime.split("T")[1]}</span> </div></td> */}
                                    <td >
                                    <div className='d-flex gap-2 mt-0 pt-0 mb-0 pb-0'>
                                       <div className='assesmnt d-flex mt-0 pt-0 mb-0 pb-0' ><strong>Ward.:</strong> <span style={{ width: '60px', marginLeft: '5px', borderBottom: '1px dashed black', display: 'inline-block' }}>{patientWard}</span></div>
                                        <div className='assesmnt d-flex mt-0 pt-0 mb-0 pb-0'><strong>Procedure.:</strong><span style={{ width: '260px', marginLeft: '5px', borderBottom: '1px dashed black', display: 'inline-block' }}>{getNursingData.length !== 0 && getNursingData.procedureName}</span>
                                        </div>
                                       </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className='assesmnt d-flex mt-0 pt-0 mb-0 pb-0'><strong>Diagnosis:</strong>
                                            <span style={{ width: '340px', marginLeft: '5px', borderBottom: '1px dashed black', display: 'inline-block' }}>&nbsp;
                                                {
                                                    getNursingData.length !== 0 && getNursingData.diagnosis.length !== 0 ? JSON.parse(getNursingData.diagnosis).map((val) => val.details).join(', ') : ""
                                                }

                                            </span>
                                        </div>
                                    </td>
                                    {/* <td ><div className='assesmnt d-flex mt-0 pt-0 mb-0 pb-0'><strong>Procedure.:</strong><span style={{ width: '100px', marginLeft: '5px', borderBottom: '1px dashed black', display: 'inline-block' }}>{getNursingData.length !== 0 && getNursingData.procedureName}</span></div></td>
                                */}
                                </tr>
                                <tr>
                                    <td colSpan={8}>
                                        <div className="alrgytbl pt-0 mb-1 mt-0 pb-0">
                                            <div><strong>Mode of arrival:</strong></div>
                                            {
                                                modeofarrivalList && modeofarrivalList.map((val, ind) => {
                                                    return (
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" id="" defaultChecked={getNursingData.arrivalId === val.id} />
                                                                {val.remark}
                                                            </label>
                                                        </div>
                                                    )
                                                })
                                            }


                                        </div></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
                <table className='mt-2'>
                    <tr>
                        <td colSpan={6} className='pt-0 pb-0 mb-0 mt-2' style={{ border: '1px solid' }}>
                            <table>
                                <tr>
                                    <td colSpan={8}>
                                        <div style={{ width: '675px', minHeight: '30px', wordWrap: 'break-word' }}>


                                            <strong> CHIEF COMPLAINTS:</strong> <span>
                                                {
                                                    getNursingData.length !== 0 && getNursingData.cheifComplaint.length !== 0 ? JSON.parse(getNursingData.cheifComplaint).map((val) => val.details).join(', ') : ""
                                                }
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8} className='pt-0 mb-0 mt-0' style={{ border: '1px solid' }}>

                            <div className=" pt-1 mb-0 mt-0">
                                <strong>PSYCHOLOGICAL STATUS</strong>  </div>
                            <div className="alrgytbl pt-1 mb-1 mt-0 pb-0">
                                {
                                    psychologyStatusList && psychologyStatusList.map((val,) => {
                                        return (
                                            <div>
                                                <label>
                                                    <input type="checkbox" id="" defaultChecked={getNursingData.psychologicalStatusId === val.id} />
                                                    {val.remark}
                                                </label>
                                            </div>
                                        )
                                    })
                                }


                            </div>

                        </td>
                    </tr>
                    <tr>
                        <td colSpan={8} className='pt-0 mb-0 mt-0' style={{ border: '1px solid', padding: '0' }}>
                            <table className='blhistry'>
                                <tr style={{ borderBottom: '1px solid' }}>
                                    <td colSpan={8} style={{ textAlign: 'center', fontSize: '13px' }}><strong> Vitals Signs at the time of Arrival</strong></td></tr>
                                <tr style={{ borderBottom: '1px solid' }}>
                                    <td><strong>Date/Time</strong></td>
                                    {
                                        getNursingData.length !== 0 && getNursingData.vitals.length !== 0 ? JSON.parse(getNursingData.vitals).map((val) => {
                                            return (<td>
                                                {
                                                    <strong> {val.VitalName}</strong>
                                                }
                                            </td>)
                                        }) : ""
                                    }
                                    {/* <td><strong> Time</strong> </td>
                                    <td> <strong>Temp (F)</strong> </td>
                                    <td><strong>Pulse/Min</strong> </td>
                                    <td> <strong>Respiration/Min</strong>  </td>
                                    <td> <strong>BP (mm of hg)</strong>  </td>
                                    <td><strong>SpO2</strong> </td>
                                    <td> <strong>GRBS </strong>  </td> */}
                                </tr>

                                <tr>
                                    <td>
                                        <div>
                                            {
                                                getNursingData.length !== 0 && getNursingData.vitals.length !== 0 ? JSON.parse(getNursingData.vitals)[0].vitalDateTime : ""
                                            }
                                        </div>
                                    </td>
                                    {
                                        getNursingData.length !== 0 && getNursingData.vitals.length !== 0 ? JSON.parse(getNursingData.vitals).map((val) => {
                                            return (<td>
                                                {
                                                    <div> {val.vmValue} {val.unit}</div>
                                                }
                                            </td>)
                                        }) : ""
                                    }

                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
                <table className='mt-2'>
                    <tr>
                        <td colSpan={8} className='pt-0 mb-0 mt-0' style={{ border: '1px solid', padding: '0' }}>
                            <table>
                                <tr>
                                    <td colSpan={8} className='pt-0 mb-0 mt-0' >
                                        <div className="alrgytbl pt-1 mb-0 mt-1 pb-0">
                                            <div>
                                                <label> <strong>PAIN :-</strong>
                                                    <input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.isPain === 1 ? true : false} /> Yes
                                                    <input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.isPain === 0 ? true : false} /> No </label>
                                            </div>
                                            <div> <label>Score </label> {getNursingData.length !== 0 && getNursingData.score}  </div>
                                            <div>
                                                <label>
                                                    Duration: &nbsp;
                                                    {getNursingData.length !== 0 && getNursingData.duration}
                                                </label>
                                            </div>
                                            <div>
                                                <label> if yes Name of pain scale:  </label>&nbsp;
                                                {getNursingData.length !== 0 && getNursingData.painScale}

                                            </div>
                                        </div>
                                        <div className="alrgytbl pt-1 mb-0 mt-1 pb-0 ps-0 pe-0">
                                            <div className='fontscl ps-0 pe-0'>
                                                <label className=' ps-0 pe-0'><input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.pain1 === 1 ? true : false} /><span><strong>7 Years to 12 years</strong> (Wong-Baker FACES Pain Rating Scale)</span>
                                                    <input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.pain2 === 1 ? true : false} /><span><strong> <i className="fa-solid fa-greater-than"></i> 12 Years</strong>(Numeric Pain Intensity Scale/Visual Analogue Scale)</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="alrgytbl pt-1 mb-1 mt-1 pb-0 ps-0 pe-0">
                                            <div style={{ fontSize: '11.5px' }}>
                                                <label><input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.pain3 === 1 ? true : false} /> CPOT (Critical care pain observation tool)
                                                </label>
                                            </div>
                                        </div>
                                        <div className="alrgytbl pt-0 mb-0 mt-0 pb-0">
                                            <div className='mrgnlft ps-1 pe-1'>
                                                <div > <strong> Pain charector <label>Sharp Dull, Aching Burning:&nbsp; &nbsp;</label></strong>
                                                    {
                                                        getNursingData.length !== 0 && getNursingData.painCharacterName
                                                    }
                                                </div>

                                            </div>
                                            <div className='mrgnlft'>
                                                <strong> Location:</strong> &nbsp;&nbsp; <label>
                                                    {
                                                        getNursingData.length !== 0 && getNursingData.painLocation
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td colSpan={8} className='pt-2 mb-0 ps-0 pe-0'>
                            <table className='table pt-0 mt-0 mb-0 pb-0' style={{ border: '1px solid black' }}>
                                <tr>
                                    <td colSpan={8}>
                                        <div className='mt-0 pt-0 ps-0 pe-0'><strong>Patient Status:</strong></div>
                                        <div className="statustbl mt-0 pt-0 mb-0 ps-0 pe-0">
                                            <div className='ptnsttsmain '>
                                                {
                                                    patientStatusList && patientStatusList.map((val, ind) => {
                                                        return (
                                                            <div className='ptntstatus mt-0 pt-0'><input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.patientStatusId === val.id ? true : false} /><span>{val.remark}</span></div>

                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>

                                        <div className="statustbl_ Vulnerabletbl pt-0 mb-1 pb-1 mt-0 ps-0 pe-0">

                                            <div className='d-fllex' style={{ width: '100%' }}>
                                                <div className='pe-3'>  <strong>Patient Vulnerable:- </strong> &nbsp;&nbsp;
                                                    <input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.isPatientVulnerable === 1 ? true : false} /> <span>Yes </span>
                                                    <input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.isPatientVulnerable === 0 ? true : false} /> <span>No</span> </div>

                                            </div>


                                            <div className=' d-flex pt-0 mb-0 pb-0 mt-0' style={{ width: '100%', verticalAlign: 'top' }}>
                                                <div className='pe-3 d-flex '><strong>Height </strong> <span style={{ marginLeft: '5px', width: '80px', borderBottom: '1px dashed black', display: 'inline-block', fontWeight: '500' }}>

                                                    {
                                                        getNursingData.length !== 0 && getNursingData.vitals.length !== 0 ? JSON.parse(getNursingData.vitals).map((val) => {
                                                            if (val.VitalName === "Height") {
                                                                return (<td>
                                                                    {
                                                                        <span> {val.vmValue} {val.unit}</span>
                                                                    }
                                                                </td>)
                                                            }
                                                        }) : ""
                                                    }
                                                </span>

                                                </div>
                                                <div className='d-flex'><strong>Weight</strong> <span style={{ marginLeft: '5px', width: '80px', borderBottom: '1px dashed black', display: 'inline-block', fontWeight: '500' }}>

                                                    {
                                                        getNursingData.length !== 0 && getNursingData.vitals.length !== 0 ? JSON.parse(getNursingData.vitals).map((val) => {
                                                            if (val.VitalName === "Weight") {
                                                                return (<td className='pt-0 mb-0 pb-0 mt-0' >
                                                                    {
                                                                        <span> {val.vmValue} {val.unit}</span>
                                                                    }
                                                                </td>)
                                                            }
                                                        }) : ""
                                                    }
                                                </span>
                                                </div>
                                            </div>
                                        </div>



                                    </td>

                                    {/* <td>
                                      
                                    </td> */}
                                </tr>
                                <tr style={{ borderTop: '1px solid black' }}>
                                    <td colSpan={8} style={{ verticalAlign: 'top' }}>
                                        <div className="statustbl spce pt-0 mb-0 mt-0 ps-0 pe-0">
                                            <ul>
                                                <li>
                                                    <div className=' pe-3'><strong> Accompanied By Relatives:- </strong>  </div>
                                                </li>
                                                <li>
                                                    <div>  <input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.isAccompaniedRelative === 1 ? true : false} /> Yes </div>
                                                </li>
                                                <li>
                                                    <div className='ps-1'>  <input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.isAccompaniedRelative === 0 ? true : false} /> No </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='rltvflx pt-0 pb-0 mb-0 mt-0 ps-0 pe-0'>
                                            <div>If Any Name of the Relatives</div>
                                            <div>Relationship with patient</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr style={{ borderTop: '1px solid black' }}>
                                    <td>
                                        <div className='contctdetails1 ps-0 pe-0'>
                                            <div className=''><strong>Contact Persons Name:</strong>  <label>{getNursingData.length !== 0 && getNursingData.relativeName}</label></div>
                                            <div className=''><strong>Relationship:</strong> <label>{getNursingData.length !== 0 && getNursingData.relationshipWithPatient}</label></div>
                                            <div className=''><strong>Contact No:</strong> <label>{getNursingData.length !== 0 && getNursingData.relativeMobileNo}</label></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr style={{ borderTop: '1px solid black' }}>
                                    <td colSpan={8} className='pt-0 mb-0 mt-0' >
                                        <div className="statustbl pt-1 mb-0 mt-0 ps-0 pe-0">
                                            <ul>
                                                <li>
                                                    <div className='ps-0 pe-3'><strong>Allergies /Adverse Reaction:-</strong></div> </li>
                                                <li> <div className='ps-0 pe-2'><input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.allergies.length !== 0 ? JSON.parse(getNursingData.allergies).some(obj => obj.ParameterValue.toLowerCase() === "YES".toLowerCase()) ? true : false : false} /> Yes   </div>  </li>
                                                <li> <div className='ps-0 pe-2'><input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.allergies.length !== 0 ? JSON.parse(getNursingData.allergies).some(obj => obj.ParameterValue.toLowerCase() === "NO".toLowerCase()) ? true : false : false} /> No </div></li>

                                                {
                                                    allergiesData && allergiesData.map((val, ind) => {
                                                        return (<>
                                                            <li><div className='ps-0 pe-2'><input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.allergies.length !== 0 ? JSON.parse(getNursingData.allergies).some(obj => obj.parameterName.toLowerCase() === val.parameterName.toLowerCase()) ? true : false : false} /> {val.parameterName} </div></li>

                                                        </>)
                                                    })

                                                }

                                                {/* <li><div className='ps-0 pe-2'><input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.allergies.length !== 0 ? JSON.parse(getNursingData.allergies).some(obj => obj.parameterName.toLowerCase() === "Medication".toLowerCase()) ? true : false : false} /> Medication </div></li>
                                                <li>  <div className='ps-0 pe-2'><input type="checkbox" id="" /> Blood Transfusion  </div></li>
                                                <li>  <div className='ps-0 pe-2'><input type="checkbox" id="" /> Food </div> </li>
                                                <li> <div>  <input type="checkbox" id="" /> Not Known </div></li> */}
                                            </ul>
                                        </div>
                                        <div className='ps-0 pe-0 mt-0 pt-0'>If Any Specify: <span style={{ width: '130px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div>
                                    </td>
                                </tr>


                            </table>
                        </td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td colSpan={8} className='pt-2 mb-1 ps-0 pe-0'>
                            <table className='table pt-0 mb-0 pb-0' style={{ border: '1px solid black' }}>
                                <tr>
                                    <td colSpan={8} className='pt-0 mb-0 mt-0' >
                                        <div className='ps-0 pe-0 pt-0 mt-0' style={{ textDecoration: 'underline' }}><strong>NUTRITIONAL SCREENING :</strong></div>
                                        <div className="statustbl pdng pt-0 mb-0 mt-0 ps-0 pe-0">
                                            <ul>
                                                <li>    <div> Last 3 month appetite </div>  </li>
                                                {
                                                    appetiteList && appetiteList.map((val, ind) => {
                                                        return (
                                                            <li>
                                                                <div> <input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.appetiteId === val.id ? true : false} />  {val.remark}   </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                                {/* <li>  <div>  <input type="checkbox" id="" /> Increased    </div>
                                                </li>
                                                <li> <div>  <input type="checkbox" id="" /> Decreased </div></li>
                                                <li>
                                                    <div>  <input type="checkbox" id="" /> No Change   </div></li> */}

                                            </ul>
                                        </div>
                                        <div className="statustbl pdng pt-0 mb-0 mt-0 ps-0 pe-0">
                                            <ul>
                                                <li>
                                                    <div> Last 3 month Weight</div>
                                                </li>
                                                {
                                                    weightList && weightList.map((val, ind) => {
                                                        return (
                                                            <li>
                                                                <div> <input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.weightId === val.id ? true : false} />  {val.remark}   </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="statustbl pdng pt-0 pb-0 mb-0 mt-0 ps-0 pe-0">
                                            <ul>
                                                <li>
                                                    <div> Type of patient</div>
                                                </li>
                                                {
                                                    patientList && patientList.map((val, ind) => {
                                                        return (
                                                            <li>
                                                                <div> <input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.patientTypeId === val.id ? true : false} />  {val.remark}   </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className="statustbl pt-0 pb-0 mb-0 mt-0 ps-0 pe-0">
                                            <ul>
                                                <li>
                                                    <div className=' pe-3 mt-0 pt-0 '> <strong> Dietician Informed :- </strong></div>
                                                </li>
                                                <li>
                                                    <div>  <input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.isPatientVulnerable === 1 ? true : false} /> Yes    </div>
                                                </li>
                                                <li>
                                                    <div>  <input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.isPatientVulnerable === 0 ? true : false} /> No    </div>
                                                </li>
                                            </ul>
                                        </div>

                                    </td>
                                    <td>
                                        If Yes, Mention the Name of dietician: <label>{getNursingData.length !== 0 && getNursingData.dieticianName}</label>
                                    </td>
                                    <td>Informed at (time): <label>{getNursingData.length !== 0 && new Date('1970-01-01T' + getNursingData.informedTime + 'Z').toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</label></td>
                                </tr>
                                <tr>
                                    <td colSpan={8} className='pt-0 pb-0 mb-0 mt-0' style={{ borderTop: '1px solid black' }}>
                                        <table>
                                            <tr>
                                                <td><div className='ps-0 pe-0'><strong>Orient patient attendant if:</strong> </div></td>
                                                <td><strong>Orient patient attendant if</strong></td>
                                                {/* <td><input type="checkbox" /> Unconscious/Disoriented </td> */}
                                            </tr>
                                            <tr>
                                                <td >
                                                    <div className="d-flex flex-row gap-2 statustbl_ pdng_ pt-0 mb-0 mt-0 ps-0 pe-0">
                                                        <div>
                                                            {
                                                                orientPatientList && orientPatientList.map((val, ind) => {
                                                                    return (
                                                                        <span>
                                                                            <div> <input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.nursingOrient.length !== 0 ? JSON.parse(getNursingData.nursingOrient).some(obj => obj.orientId === val.id) ? true : false : false} />  {val.remark}   </div>
                                                                        </span>
                                                                    )
                                                                })
                                                            }



                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{ verticalAlign: 'top' }}>
                                                    <div className="d-flex flex-row gap-2 statustbl_ pdng_ pt-0 mb-0 mt-0 ps-0 pe-0">
                                                        <div>
                                                            {
                                                                orientPatientAttendantList && orientPatientAttendantList.map((val, ind) => {
                                                                    return (
                                                                        <span>
                                                                            <div> <input type="checkbox" id="" checked={getNursingData.length !== 0 && getNursingData.nursingOrient.length !== 0 ? JSON.parse(getNursingData.nursingOrient).some(obj => obj.orientId === val.id) ? true : false : false} />  {val.remark}   </div>
                                                                        </span>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    </div>

                                                </td>
                                            </tr>


                                        </table>
                                    </td>

                                </tr>

                            </table>
                        </td>
                    </tr>

                </table>
            </div>
        </>
    )
}
