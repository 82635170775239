async function StartInfusionorFluid(obj) {
    //const url = "https://localhost:7225/api/PatietnFluid/StartFluidorInfusion";
     const url = `${window.AppbaseUrl}/api/PatietnFluid/StartFluidorInfusion`;
    let head = { 'Content-Type': 'application/JSON', accept: '*/*' };
    let data = {};
    let response = await fetch(url, {
        headers: head,
        method: 'POST',
        body:JSON.stringify(obj)
    }).then((res) => res.json()).then(data);
   console.log('response',response);
    return response;
}

export default StartInfusionorFluid;