import React, { useEffect, useState } from 'react'
import AlertToster from '../../Component/AlertToster'
import GetDicomServerInstance from '../../Clinical/API/RemotePatientMonitorDashboard/GetDicomServerInstance'

export default function PacsPopUp(props) {
    let [showAlert, setShowAlert] = useState(0)
    let [showAlertMessage, setShowAlertMessage] = useState("")
    let [pacsList, setPacsList] = useState([])

    let getData = async () => {
        let resp = await GetDicomServerInstance(props.patientdata.UhId)
        if (resp.status === 1) {
            console.log("dat", resp.responseValue)
            setPacsList(resp.responseValue)
        }
        else {
            setShowAlert(1)
            setShowAlertMessage(resp.responseValue)
        }
    }

    let handleShowPacs = (id, url) => {
        // window.open("http://172.16.19.96/orthanc/osimis-viewer/app/index.html?study=" + id, '_blank')
        window.open(url+"" + id, '_blank')
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <>
            <div className={`modal d-${props.pacsPopup === 0 ? 'none' : 'block'}`}>


                <div className="modal-dialog modal-xl">

                    <div className="modal-content">
                        <span className="closee" title='Close Window' onClick={() => { props.modelCloseFun(1) }}><i className='fa fa-times'></i></span>
                        <div className='p-profile'>
                            <div className='p-profile-h'>PACS Report</div>
                            <div className='p-profile-h'>
                                <div className='pname'><span>{props.patientdata.UhId}</span></div>
                                <div className='pname'>- {props.patientdata.PntName}</div>
                            </div>
                        </div>
                        <div className="modal-body p-1">
                            <div className="pdtable ps-2 pe-2" style={{ maxHeight: '350px', overflowY: 'auto' }}>
                                <table className='table_'>
                                    <thead>
                                        <tr>
                                            <th>Modality</th>
                                            <th>
                                                <div className='d-flex flex-row justify-content-between pe-5'>
                                                    <span>

                                                        Studies ID
                                                    </span>
                                                    <span>

                                                        Action
                                                    </span>
                                                </div>
                                            </th>
                                            {/* <th>Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            pacsList.length !== 0 && pacsList.map((val, ind) => {
                                                let vals = JSON.parse(val.studiesJson)

                                                return (
                                                    <tr style={{backgroundColor:"white"}}>
                                                        <td>{val.modality}</td>
                                                        <td>
                                                            {
                                                                vals.map((v) => {
                                                                    return (
                                                                        <td className='d-flex flex-row justify-content-between pe-5'>
                                                                            <span>
                                                                                {
                                                                                    v.studies_ID
                                                                                }
                                                                            </span>
                                                                            <i className="bi bi-eye-fill" style={{color:"black", fontSize:"18px"}} onClick={() => { handleShowPacs(v.studies_ID, val.serverURL) }}></i>

                                                                        </td>
                                                                    )
                                                                })
                                                            }
                                                        </td>

                                                    </tr>

                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>


                </div>

                {
                    showAlert === 1 ?
                        <AlertToster message={showAlertMessage} handle={setShowAlert} /> : ""
                }
            </div>
        </>
    )
}
