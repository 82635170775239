async function GetUserListByClientId(clientId) {
    
    let url = window.UserbaseUrl + "/api/Users/GetUserList?clientId="+clientId;
    const head = {'content-type' : 'application/json','accept':'*/*'};
  const data = {};
  const response = await fetch(url, {
      method: 'GET',
      header: head,
  }).then(res => res.json()).then(data);
  return response;
}

export default GetUserListByClientId