import React,{useState, useEffect} from 'react'
import TableContainer from '../../../Component/TableContainer'
import Heading from '../../../Component/Heading'
import GetPlanedCloseTask from '../../API/AdminDashBoard/GetPlanedCloseTask'
import noData from '../../../assets/images/icons/No data-rafiki.svg';
const ProjectBacklogStatus = () => {
    
    const [getPlanedCloseTask, setPlanedCloseTask] = useState([])
    const [searchInputProjectbacklog, setSearchInputProjectbacklog] = useState('');    

    const funGetProjectDateBacklog = async () => {
        const {status, responseValue} = await GetPlanedCloseTask(window.userId)
        status ? setPlanedCloseTask(responseValue) : setPlanedCloseTask([]);
    };

    const handleOnChangeProjectbacklog = (e) => {
        const { name, value } = e.target;
        if (name === 'searchBoxProjectbacklog') {
            setSearchInputProjectbacklog(value)
        }
    };

    useEffect(() => {
        funGetProjectDateBacklog();
    }, []);


    return (
        <>
            <div className='handlser'>
                <Heading text="Project Backlog Status" />
                <div style={{ position: 'relative' }}>
                    <input value={searchInputProjectbacklog} onChange={handleOnChangeProjectbacklog} name="searchBoxProjectbacklog" type="search" className="form-control form-control-sm" placeholder="Search...." aria-label="Search" aria-describedby="search-addon" />
                    <span className="tblsericon"><i className="fas fa-search"></i></span>
                </div>
            </div>
            <TableContainer>
                <thead>
                    <tr>
                        <th className="text-center" style={{ "width": "5%" }}>S.No.</th>
                        <th>Project Name</th>
                        <th>Planned task</th>
                        <th>Closed Task</th>
                        <th>Remaining Task</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        getPlanedCloseTask.length > 0 ?
                        getPlanedCloseTask && getPlanedCloseTask.filter((val) => `${val.projectName} `.toLowerCase().includes(searchInputProjectbacklog.toLowerCase())).map((val, ind) => {
                        return (
                            <tr key={val.id}>
                                <td className="text-center">{ind + 1}</td>
                                <td>{val.projectName}</td>
                                <td>{val.totalTask}</td>
                                <td>{val.completedTask}</td>
                                <td>{val.holdProgessTask + val.notStartTask}</td>
                            </tr>
                        )
                    })
                    :
                    <img className='haveNodataImg' src={noData} alt="No Data" />}
                </tbody>
            </TableContainer>
        </>
    )
}

export default ProjectBacklogStatus;