import React, { useEffect, useState } from 'react'
import Heading from '../../Component/Heading';
import BoxContainer from '../../Component/BoxContainer';
import TableContainer from '../../Component/TableContainer';
import editBtnIcon from '../../assets/images/icons/edit.svg';
import uhid from '../../BloodBank/images/uhid.svg'
import name from '../../BloodBank/images/name.svg'
import age from '../../BloodBank/images/age.svg'
import genders from '../../BloodBank/images/genders.svg'
import department from '../../BloodBank/images/department.svg'
import bloddgroup from '../../BloodBank/images/bloddgroup.svg'
import product from '../../BloodBank/images/product.svg'
import requestunit from '../../BloodBank/images/requestunit.svg'
import dob from '../../BloodBank/images/dob.svg'
import time from '../../BloodBank/images/time.svg'
import printer from '../../BloodBank/images/printer.svg'
import exportfile from '../../BloodBank/images/exportfile.svg'
import GetAllBloodRequestList from '../Api/BloodDonorRegestration/GetBlood/GetIusse/GetAllBloodRequestList';
import BloodIssueValidations from '../../Validation/BloodBank/BloodIssueValidations';
import PostBloodIssueStatus from '../Api/BloodDonorRegestration/PostBlood/PostBloodIssueStatus';
import ValidationsBloodissueListSave from '../../Validation/BloodBank/ValidationsBloodissueListSave'
import TosterUnderProcess from '../../Component/TosterUnderProcess'
import Toster from '../../Component/Toster'
import GetIssueList from '../Api/BloodDonorRegestration/GetBlood/GetIusse/GetIssueList';
import PostBloodIssueData from '../Api/BloodDonorRegestration/PostBlood/PostBloodRequest/PostBloodIssueData'

export default function BloodIssueReport() {

  let [showUnderProcess, setShowUnderProcess] = useState(0);
  let [showToster, setShowToster] = useState(0);
  let [tosterValue, setTosterValue] = useState(0);
  let [tosterMessage, setTosterMessage] = useState("");
  let [jsonAllBag, setJsonAllBag] = useState([])
  let [requestSubID, setRequestSubID] = useState('');
  let [getAllRequestList, setAllRequestList] = useState([]);
  let [patientName, setpatientName] = useState('');
  let [bloodGroupID, setBloodGroupID] = useState('');
  let [productID, setProductID] = useState('');
  let [department, setdepartment] = useState('');
  let [hospitalName, sethospitalName] = useState('');
  let [bloodGroup, setbloodGroup] = useState('');
  let [productName, setproductName] = useState('');
  let [requestedQuantity, setrequestedQuantity] = useState('');
  let [bagReciviedByAddress, setBagReciviedByAddress] = useState('');
  let [bagReciviedByContact, setBagReciviedByContact] = useState('');
  let [bagReciviedBy, setBagReciviedBy] = useState('');
  let [updateStatus, setupdateStatus] = useState('');
  let [rowId, setrowId] = useState('');
  let [remark, setremark] = useState('');
  let [isShowIssueModel, setIsShowIssueModel] = useState(0);
  let [getAllIssueList, setAllIssueList] = useState([]);
  let clearErrorMsg = () => {
    document.getElementById('errupdateStatus').style.display = "none";
  }
  let handleChange = (e) => {
    if (e.target.name === "remark") {
      setremark(e.target.value)
    }

    if (e.target.name === "bagReciviedBy") {
      setBagReciviedBy(e.target.value)
    }

    if (e.target.name === "bagReciviedByContact") {
      setBagReciviedByContact(e.target.value)
    }

    if (e.target.name === "bagReciviedByAddress") {
      setBagReciviedByAddress(e.target.value)
    }
  }

  const [selectedItems, setSelectedItems] = useState([0]);

 


  const selectedCount = selectedItems.filter(Boolean).length;

  let getAllRequest = async () => {
    let data = await GetAllBloodRequestList();
    console.log('test : ', data);
    setAllRequestList(data.responseValue);


  }

  let getAllIssueLists = async (list) => {
    setBloodGroupID(list.bloodGroupID);
    setProductID(list.productID)
    setpatientName(list.patientName)
    setdepartment(list.department)
    sethospitalName(list.hospitalName)
    setbloodGroup(list.groupName)
    setproductName(list.productName)
    setrequestedQuantity(list.requestedQuantity)
    setrowId(list.id)
    setRequestSubID(list.requestSubID)
    // console.log("bloodGroupID",bloodGroupID)
    // console.log("bloodGroupID",bloodGroupID)
    // let productID = list.productID;
    let productID = list.productID
    let bloodGroupID = list.bloodGroupID
    let data = await GetIssueList(bloodGroupID, productID);
    console.log("getAllIssueLists", data);
    setAllIssueList(data.responseValue);

  }

  let edit = (list) => {
    console.log('edit', list);

    setpatientName(list.patientName)
    setdepartment(list.department)
    sethospitalName(list.hospitalName)
    setbloodGroup(list.groupName)
    setproductName(list.productName)
    setrequestedQuantity(list.requestedQuantity)
    setrowId(list.id)
  }
  let clear = async () => {
    setremark('');
    setBagReciviedBy('')
    setBagReciviedByContact('')
    setBagReciviedByAddress('')
    setSelectedItems([0])
    // document.getElementById('ddlUpdateStatus').value = 0;
    const updateStatusElement = document.getElementById('ddlUpdateStatus');
    if (updateStatusElement) {
        updateStatusElement.value = 0;
    }
    setIsShowIssueModel(0);


  }

  let saveBloodIssue = async () => {

    const obj = {

      requestMainID: rowId,
      bloodGroupID: bloodGroupID,
      productID: productID,
      jsonAllBag: JSON.stringify(jsonAllBag),
      bagReciviedBy: bagReciviedBy,
      bagReciviedByContact: bagReciviedByContact,
      bagReciviedByAddress: bagReciviedByAddress,
      issuedQuantity: selectedCount,
      requestSubID: requestSubID,
      userID: JSON.parse(window.sessionStorage.getItem("LoginData")).userId,

    };
    console.log("obj", obj)
    let valresponse = ValidationsBloodissueListSave(bagReciviedBy, bagReciviedByContact, bagReciviedByAddress)
    if (valresponse[0]) {
      let response = await PostBloodIssueData(obj);
      if (response.status === 1) {
        setShowUnderProcess(0)
        // getAllRequest();
        setTosterValue(0);
        setShowToster(1)
        setTosterMessage("Data Saved SuccessFully!")
        setTosterValue(0)
        setTimeout(() => {
          setShowToster(0)
          clear();

        }, 2000)
      }
      else {
        setShowUnderProcess(0)
        // getAllRequest();
        setShowToster(1)
        console.log("csdcs", response.responseValue)
        setTosterMessage(response.responseValue ? response.responseValue : "Data Not Saved")
        setTosterValue(1)
        setTimeout(() => {
          setShowToster(0)
          // setTosterValue(0)
        }, 2000)
      }
    }
    else {
      setShowUnderProcess(0)
      setShowToster(1)
      setTosterMessage(valresponse[1])
      setTosterValue(1)
      setTimeout(() => {
        setShowToster(0)
        setTosterValue(0)
      }, 2000)
    }

  }

  let updateData = async () => {
    const updateStatus = document.getElementById('ddlUpdateStatus').value;
    const res = BloodIssueValidations();
    console.log('res', res);
    // document.getElementById('errupdateStatus').style.display = 'none';
   
    if (updateStatus == "" || updateStatus == '0') {
      document.getElementById('errupdateStatus').innerHTML = 'Please Select Update Status';
      document.getElementById('errupdateStatus').style.display = "block"
      return
    }
    
      let obj = {
        id: rowId,
        approvalStatus: updateStatus,
        remark: remark,
        userId: JSON.parse(window.sessionStorage.getItem("LoginData")).userId,
        
      }
      console.log('update obj : ', obj);
      let data = await PostBloodIssueStatus(obj);
      if (data.status === 1) {
        setShowUnderProcess(0);
        getAllRequest();
        setTosterValue(0);
        setShowToster(1);
        setTosterMessage('Updated Successfully!');
        setIsShowIssueModel(0)
        setTimeout(() => {
          setShowToster(0);
          clear();
          //getVisitorList();
        }, 2000);
      }
      else {
        setShowUnderProcess(0);
        getAllRequest();
        setShowToster(1);
        setTosterMessage(data.responseValue);
        setTosterValue(1);
        setTimeout(() => {
          setShowToster(0);
        }, 2000)
      }
    
   
  }


  useEffect(() => {


    getAllRequest();

  }, [])
  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Heading text='Blood Issue Report'/>
              <div className="col-12 mt-2">
                {/* <Heading text='Donor List' /> */}

                <div className="med-table-section" style={{ "height": "75vh" }}>
                  <TableContainer>
                    <thead>
                      <tr>
                        <th className="text-center" style={{ "width": "5%" }}>#</th>
                        <th>UHID</th>
                        <th>Patient Name</th>
                        <th>Age/Gender</th>
                        <th>Department</th>
                        <th>Hospital Name</th>
                        <th>Blood Group</th>
                        <th>Product</th>
                        <th>Req Unit</th>
                        <th>Issued Unit</th>
                        <th>Req Date/Time</th>
                        <th>Status</th>
                        <th style={{ "width": "10%" }} className="text-center">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      
                      {getAllRequestList && getAllRequestList.map((list, index) => {
                        return (
                          <tr  >
                            <td className="text-center">{index + 1}</td>
                            <td>{list.patientUHID}</td>
                            <td>{list.patientName}</td>
                            <td>{list.age} / {list.gender}</td>
                            <td>{list.department}</td>
                            <td>{list.hospitalName}</td>
                            <td>{list.groupName}</td>
                            <td>{list.productName}</td>
                            <td>{list.requestedQuantity}</td>
                            <td>{list.issuedQuantity}</td>
                            <td>{list.createdDate}</td>
                            <td><span style={{width:"70px",fontSize: '12px'}} className={`d-block text-center rounded-1 p-1 ${list.approvalStatus == 1 ? "yellow-transparent" : list.approvalStatus == 2 ? 'green-transparent' : 'red-transparent'} `}>{list.approvalStatustext}</span></td>
                            <td>
                              <div className="action-button">
                                <div data-bs-title="Edit Row" data-bs-placement="bottom"  ><img src={editBtnIcon} alt='' onClick={() => { setIsShowIssueModel(1); edit(list); }}/></div>
                                <div data-bs-toggle="modal" data-bs-target="#IssueModal" data-bs-title="Edit Row" data-bs-placement="bottom" onClick={() => {getAllIssueLists(list); }}><i className="fa fa-camera-retro actioncam"></i></div>
                              
                              </div>
                            </td>
                          </tr>
                        )
                      })}

                    </tbody>
                  </TableContainer>
                </div>

              </div>
            </div>




          </div>
        </div>

      </section>


   
  


    </>
  )
}
