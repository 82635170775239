import React from 'react'
import printuse from '../../../assets/css/printuse.css'
import { useEffect, useState } from 'react'
import PrintPageHeader from '../../../Component/PrintPageHeader';
import PatientLabel from '../../../Component/PatientLabel';
export default function ReceiptRbsMonitoringForm() {


    let [RBSMainData, setRBSMainData] = useState();
    let [RBSVitalDetails, setRBSVitalDetails] = useState();
    let [RBSMedicationdetails, setRBSMedicationdetails] = useState([]);
    let [diagnosisDetails, setdiagnosisDetails] = useState([]);
    let [RBSPatientData, setRBSPatientData] = useState([]);
    let [Consultant, setConsultant] = useState("");

    const [clientData, setclientData] = useState()



    let getRBSDetails = () => {

        let data = JSON.parse(window.sessionStorage.getItem("RBSGraphData"));
        console.log("aaaa", data)
        setRBSMainData(data.mainData)
        setConsultant(data.PatientData.doctorName)
        setRBSPatientData(data.PatientData)


    }

    let getDiagnosis = () => {
        let data = JSON.parse(window.sessionStorage.getItem("IPDpatientsendData"))[0][3].jsonDiagnosis;
        console.log("diagnosisData", data)
        setdiagnosisDetails(data)
    }



    const ClientData = () => {
        let data = JSON.parse(window.sessionStorage.getItem("LoginData")).clientdata;
        console.log('ClientData', data)
        setclientData(data)
    }

    useEffect(() => {
        getRBSDetails();
        ClientData()
        getDiagnosis()
        setTimeout(() => {
            handlepritnt();
        }, 1200)

    }, []);
    let handlepritnt = () => {
        document.title = 'RBS Monitoring Receipt';
        window.print("");
        window.close();

    }
    return (
        <>
            <div className="main-table" style={{ padding: '5px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

               <div className='rbsmonitoringprint'>
               <table className='pt-2 mt-2' >
                    {/* ....header.. */}
                    <tr>
                        <td colSpan={6}>
                            <table>
                                <tr>
                                    <td colSpan={6}>
                                        <table>
                                            <tr>
                                                <td colSpan={6}>
                                                    <PrintPageHeader />

                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td colSpan={6} className='pt-2 mt-2 ps-0 pe-0'>
                            <table className='pb-1 pt-2 mt-2'>
                                <tr>
                                    <td colSpan={5} style={{ verticalAlign: 'top' }}>
                                        <div className='micro-heading' >
                                            RBS Monitoring Chart
                                        </div>
                                    </td>
                                    <td rowSpan={4} style={{width: '36%' }}>
                                        <table className="tb-rght " >
                                        <div className='mt-0 pt-0 mb-0 pb-0 d-flex'>
                                                <PatientLabel />
                                            </div>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={6} className='ps-0 pe-0'>
                            <table >
                                <tr>
                                    <th colSpan={6} className='mb-3 pb-3'>IP No. : <span style={{ width: '100px', borderBottom: '1px dashed black', display: 'inline-block', fontWeight: '500' }}>{RBSPatientData && RBSPatientData.ipNo}</span> </th>
                                </tr>
                                <tr>
                                    <th className='mb-3 pb-3' style={{width:'30%'}}><div className='d-flex gap-0'> Consultant : <span style={{marginLeft:'5px', width: '130px', borderBottom: '1px dashed black', display: 'inline-block', fontWeight: '500' }}>{Consultant}</span> </div></th>
                                    <th className='mb-3 pb-3' style={{width:'auto'}}><div className='d-flex'>Diagnostic: <span style={{ marginLeft:'5px', width: 'auto', borderBottom: '1px dashed black', display: 'inline-block', fontWeight: '500' }}>{diagnosisDetails && diagnosisDetails.map((val) => val.problemName).join(" ,")}</span> </div></th>
                                    {/* <th colSpan={2} className='mb-3 pb-3'> Consultant : <span style={{ width: '140px', borderBottom: '1px dashed black', display: 'inline-block', fontWeight: '500' }}>{Consultant}</span> </th>
                                    <th colSpan={4} className='mb-3 pb-3' >Diagnostic: <span style={{ borderBottom: '1px dashed black', display: 'inline-block', fontWeight: '500' }}>{diagnosisDetails && diagnosisDetails.map((val)=>val.problemName).join(" , ")}</span> </th>  */}

                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={12} className='mt-0 mb-0 pt-0 pb-0'>
                            <table className="ptents-tbl tbldata2_">
                                <thead>
                                    <tr>
                                        <th rowSpan={2} style={{ textAlign: 'center' }}>Date</th>
                                        <th rowSpan={2} style={{ textAlign: 'center' }}>Time</th>
                                        <th rowSpan={2} style={{ width: '5%', textAlign: 'center' }}>Blood Glucose </th>
                                        <th colSpan={3} style={{ textAlign: 'center', width: '30%' }}>Administration of OHA'S</th>
                                        <th rowSpan={2} style={{ textAlign: 'center', }}>Staff Name & ID</th>
                                        <th rowSpan={2} style={{ width: '5%', textAlign: 'center' }}>RMO</th>
                                        <th rowSpan={2} style={{ width: '10%', textAlign: 'center' }}>Total Insuline</th>
                                    </tr>
                                    <tr>
                                        <th style={{ width: '4%', textAlign: 'center' }}>Time</th>
                                        <th style={{ width: '20%', textAlign: 'center' }}>Drug</th>
                                        <th style={{ width: '6%', textAlign: 'center' }}>Route</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }} className='ps-0 pe-0'>
                                            {RBSMainData && RBSMainData.map((val) => {
                                                return (
                                                    <>
                                                        <div className='rbsdatatbl' >{val.vitalDate}</div>

                                                    </>
                                                )
                                            })}
                                        </td>
                                        <td style={{ verticalAlign: 'top' }} className='ps-0 pe-0'>
                                            {RBSMainData && RBSMainData.map((val) => {
                                                return (
                                                    <>
                                                        <div className='rbsdatatbl' >{val.vitalTime}</div>
                                                    </>
                                                )
                                            })}
                                        </td>
                                        <td style={{ verticalAlign: 'top' }} className='ps-0 pe-0'>
                                            {RBSMainData && RBSMainData.map((val) => {
                                                return (
                                                    <>

                                                        <div className='rbsdatatbl' >{val.vmValue}</div>

                                                    </>
                                                )
                                            })}
                                        </td>
                                        <td style={{ verticalAlign: 'top' }} className='ps-0 pe-0'>
                                            {RBSMainData && RBSMainData.map((val) => {
                                                return (
                                                    <>

                                                        <div className='rbsdatatbl' >{val.createdDate}</div>

                                                    </>
                                                )
                                            })}
                                        </td>
                                        <td style={{ verticalAlign: 'top' }} className='ps-0 pe-0'>
                                            {RBSMainData && RBSMainData.map((val) => {
                                                return (
                                                    <>

                                                        <div className='rbsdatatbl'>{val.drugName == '' ? 'NA' : val.drugName}</div>

                                                    </>
                                                )
                                            })}
                                        </td>
                                        <td style={{ verticalAlign: 'top' }} className='ps-0 pe-0'>
                                            {RBSMainData && RBSMainData.map((val) => {
                                                return (
                                                    <>

                                                        <div className='rbsdatatbl' >{val.dosageForm}</div>

                                                    </>
                                                )
                                            })}
                                        </td>
                                        <td style={{ verticalAlign: 'top' }} className='ps-0 pe-0'>
                                            {RBSMainData && RBSMainData.map((val) => {
                                                return (
                                                    <>
                                                        <div className='rbsdatatbl' >{val.staffName}</div>
                                                    </>
                                                )
                                            })}
                                        </td>
                                        <td style={{ verticalAlign: 'top' }} className='ps-0 pe-0'>
                                            {RBSMainData && RBSMainData.map((val) => {
                                                return (
                                                    <>
                                                        <div className='rbsdatatbl'>{val.rmo}</div>
                                                    </>
                                                )
                                            })}
                                        </td>
                                        <td style={{ verticalAlign: 'top' }} className='ps-0 pe-0'>
                                            {RBSMainData && RBSMainData.map((val) => {
                                                return (
                                                    <>
                                                        <div className='rbsdatatbl'>{val.quantity}</div>
                                                    </>
                                                )
                                            })}
                                        </td>
                                    </tr>
                                </tbody>



                            </table>
                        </td>
                    </tr>

                </table>
               </div>
            </div>
        </>
    )
}
