import React, { useEffect, useRef, useState } from 'react'
import Height from "../../../../../assets/images/OPD/height.svg"
import Weight from "../../../../../assets/images/OPD/weight.svg"
import BloodPressure from "../../../../../assets/images/OPD/bloodpressure.svg"
import PulseRate from "../../../../../assets/images/OPD/pulse.svg"
import RespiratoryRate from "../../../../../assets/images/OPD/ChestPain.svg"
import SaveIPDData from '../../../../../Code/SaveIPDData'
import { useSelector } from 'react-redux'
import store from '../../../../../Store'
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import VoiceCommand from '../../../../API/VoiceCommand'
import GoogleSpeechToText from '../../../../API/GoogleSpeechToText'
import AlertToster from '../../../../../Component/AlertToster'
import Heart from "../../../../../assets/images/vitalsicons/heart.svg"

export default function IPDTopVitals({ voiceVital, startListening, listening, transcript }) {
    // console.log("voiceVital------------------------->", voiceVital)
    const { t } = useTranslation();
    document.body.dir = i18n.dir()
    const [isListening, setIsListening] = useState(false);
    const recognitionActive = useRef(false); // Flag to track if recognition is active
    const recognitionRef = useRef(null);
    let [showAlertToster, setShowAlertToster] = useState(0);
    let [showErrMessage, setShowErrMessage] = useState('');
    let [sendVitals, setSendVitals] = useState(
        [
            {
                "vmId": 56,
                "vmValue": "",
                "name": "spo2",
                "img": Height,
                "unit": "%",
                "shortname": t("SPO2"),
                "maxLimit": 100,

            },
            {
                "vmId": 4,
                "vmValue": "",
                "name": "BP_Sys",
                "img": BloodPressure,
                "unit": "mmHg",
                "shortname": t("BPS"),
                "maxLimit": 250

            },
            {
                "vmId": 6,
                "vmValue": "",
                "name": "BP_Dias",
                "img": BloodPressure,
                "unit": "mmHg",
                "shortname": t("BPD"),
                "maxLimit": 250

            },
            {
                "vmId": 3,
                "vmValue": "",
                "name": "Pulse",
                "img": PulseRate,
                "unit": "bpm",
                "shortname": t("PR"),
                "maxLimit": 170

            },
            {
                "vmId": 7,
                "vmValue": "",
                "name": "respRate",
                "img": RespiratoryRate,
                "unit": "bpm",
                "shortname": t("RR"),
                "maxLimit": 30

            },
            {
                "vmId": 5,
                "vmValue": "",
                "name": "Temperature",
                "img": Height,
                "unit": "°F",
                "shortname": t("Temp"),
                "maxLimit": 109

            },
            {
                "vmId": 74,
                "vmValue": "",
                "name": "HR",
                "img": Heart,
                "unit": "bpm",
                "shortname": t("HR"),
                "maxLimit": 220

            },
            // {
            //     "vmId": 2,
            //     "vmValue": "",
            //     "name": "Weight",
            //     "img": Weight,
            //     "unit": "kg",
            //     "shortname": t("Wt"),
            //     "maxLimit": 500

            // },
            // {
            //     "vmId": 1,
            //     "vmValue": "",
            //     "name": "Height",
            //     "img": Height,
            //     "unit": "CM",
            //     "shortname": t("Ht"),
            //     "maxLimit": 272
            // }
        ]
    )
    let [storeData, setStoreData] = useState({
        "bpDias": 0,
        "bpSys": 0,
        "hr": 0,
        "pulseValue": 0,
        "rbs": 0,
        "respiratoryRate": 0,
        "spo2": 0,
        "tempValue": 0,
        "urineOutput": 0,
        "weight": 0,
    })
    useEffect(() => {

        let temp = [...sendVitals]
        if (voiceVital.length !== 0) {
            Object.keys(voiceVital).map((val, ind) => {
                // console.log("vaall",)
                if (val === "spo2" && voiceVital.spo2 !== 0) {
                    temp[0].vmValue = voiceVital.spo2
                }
                else if (val === "BP_Sys" && voiceVital.BP_Sys !== 0) {
                    temp[1].vmValue = voiceVital.BP_Sys
                }
                else if (val === "BP_Dias" && voiceVital.BP_Dias !== 0) {
                    temp[2].vmValue = voiceVital.BP_Dias

                }
                else if (val === "Pulse" && voiceVital.Pulse !== 0) {
                    temp[3].vmValue = voiceVital.Pulse

                }
                else if (val === "respRate" && voiceVital.respRate !== 0) {
                    temp[4].vmValue = voiceVital.respRate

                }
                else if (val === "Temperature" && voiceVital.Temperature !== 0) {
                    temp[5].vmValue = voiceVital.Temperature

                }
                else if (val === "Weight" && voiceVital.Weight !== 0) {
                    temp[6].vmValue = voiceVital.Weight

                }
                else if (val === "Height" && voiceVital.Height !== 0) {
                    temp[7].vmValue = voiceVital.Height


                }

            })

        }
        setSendVitals([...temp])
        // setSendVitals([
        //     {
        //         "vmId": 56,
        //         "vmValue": voiceVital.spo2,
        //         "name": "spo2",
        //         "img": Height,
        //         "unit": "%",
        //         "shortname": t("SPO2")

        //     },
        //     {
        //         "vmId": 4,
        //         "vmValue": voiceVital.bpSys,
        //         "name": "BP_Sys",
        //         "img": BloodPressure,
        //         "unit": "mmHg",
        //         "shortname": t("BPS")

        //     },
        //     {
        //         "vmId": 6,
        //         "vmValue": voiceVital.bpDias,
        //         "name": "BP_Dias",
        //         "img": BloodPressure,
        //         "unit": "mmHg",
        //         "shortname": t("BPD")

        //     },
        //     {
        //         "vmId": 3,
        //         "vmValue": voiceVital.pulseValue,
        //         "name": "Pulse",
        //         "img": PulseRate,
        //         "unit": "bpm",
        //         "shortname": t("PR")

        //     },
        //     {
        //         "vmId": 7,
        //         "vmValue": voiceVital.respiratoryRate,
        //         "name": "respRate",
        //         "img": RespiratoryRate,
        //         "unit": "bpm",
        //         "shortname": t("RR")

        //     },
        //     {
        //         "vmId": 5,
        //         "vmValue": voiceVital.tempValue,
        //         "name": "Temperature",
        //         "img": Height,
        //         "unit": "(°F)",
        //         "shortname": t("Temp")

        //     },
        //     {
        //         "vmId": 2,
        //         "vmValue": voiceVital.weight,
        //         "name": "Weight",
        //         "img": Weight,
        //         "unit": "kg",
        //         "shortname": t("Wt")

        //     },
        //     {
        //         "vmId": 1,
        //         "vmValue": "",
        //         "name": "Height",
        //         "img": Height,
        //         "unit": "CM",
        //         "shortname": t("Ht")
        //     }])
        // if (voiceVital) {
        //     let temp = Object.keys(storeData);
        //     console.log('dddddddddd1', temp);
        //     for (var i = 0; i < temp.length; i++) {
        //         const keyName = temp[i];
        //         console.log('dddddddddd', voiceVital[keyName]);
        //         console.log('keyName', keyName);
        //         if (voiceVital[keyName] != 0) {
        //             console.log('res', voiceVital[keyName]);
        //             for (var j = 0; j < sendVitals.length; j++) {

        //                 if (sendVitals[j].name.toLocaleLowerCase() === keyName) {
        //                     console.log('debug 1', sendVitals[j])
        //                     sendVitals[j].vmValue = voiceVital[keyName]
        //                     break;
        //                 }
        //             }
        //             // setSendVitals((arr) => ({
        //             //     ...arr,
        //             //     [keyName]: voiceVital[keyName]
        //             // }));
        //         }
        //     }
        // }



    }, [voiceVital])
    // useEffect(() => {
    //     const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    //     if (!SpeechRecognition) {
    //         console.error("Speech recognition not supported in this browser.");
    //         return;
    //     }

    //     const recognition = new SpeechRecognition();
    //     recognition.lang = 'en-IN';
    //     recognition.interimResults = true;
    //     recognition.continuous = true; // Keep listening even if the user stops talking

    //     recognition.onresult = (event) => {
    //         console.log('A');
    //         const lastResultIndex = event.results.length - 1;
    //         const transcript = event.results[lastResultIndex][0].transcript;
    //         if (!event.results[lastResultIndex].isFinal) {
    //             handleVoiceInput(transcript.trim(), true); // handle intermediate results
    //         } else {
    //             handleVoiceInput(transcript.trim(), false); // handle final result
    //         }
    //         console.log('B');
    //     };

    //     recognition.onerror = (event) => {
    //         console.error('Speech recognition error:', event.error);
    //     };

    //     recognition.onend = () => {
    //         console.log('Start');
    //         recognitionActive.current = false;
    //         if (isListening) {
    //             console.log('Listening');
    //             recognition.start();
    //             recognitionActive.current = true;
    //         }
    //         console.log('End');
    //     };

    //     recognitionRef.current = recognition;

    //     return () => {
    //         recognition.stop();
    //         recognitionRef.current = null;
    //     };
    // }, [isListening]);

    // const handleVoiceInput = async (input, isIntermediate) => {
    //     if (!isIntermediate) {
    //         let json = {
    //             voiceCommand: input
    //         }
    //         console.log("cscccccccc")
    //         let response = await VoiceCommand(json);
    //         if (response.length !== 0) {
    //             console.log("response ", response.voiceCommand)
    //             let voiceVital = response.voiceCommand
    //             if (data.spo2 !== 0 || data.bpSys !== 0 || data.bpDias !== 0 || data.pulseValue !== 0 || data.respiratoryRate !== 0 || data.tempValue !== 0 || data.weight !== 0) {
    //                 console.log("andr ku shfjk sdhhdsjih", data)
    //                 setSendVitals([
    //                     {
    //                         "vmId": 56,
    //                         "vmValue": data.spo2,
    //                         "name": "spo2",
    //                         "img": Height,
    //                         "unit": "%",
    //                         "shortname": t("SPO2")

    //                     },
    //                     {
    //                         "vmId": 4,
    //                         "vmValue": data.bpSys,
    //                         "name": "BP_Sys",
    //                         "img": BloodPressure,
    //                         "unit": "mmHg",
    //                         "shortname": t("BPS")

    //                     },
    //                     {
    //                         "vmId": 6,
    //                         "vmValue": data.bpDias,
    //                         "name": "BP_Dias",
    //                         "img": BloodPressure,
    //                         "unit": "mmHg",
    //                         "shortname": t("BPD")

    //                     },
    //                     {
    //                         "vmId": 3,
    //                         "vmValue": data.pulseValue,
    //                         "name": "Pulse",
    //                         "img": PulseRate,
    //                         "unit": "bpm",
    //                         "shortname": t("PR")

    //                     },
    //                     {
    //                         "vmId": 7,
    //                         "vmValue": data.respiratoryRate,
    //                         "name": "respRate",
    //                         "img": RespiratoryRate,
    //                         "unit": "bpm",
    //                         "shortname": t("RR")

    //                     },
    //                     {
    //                         "vmId": 5,
    //                         "vmValue": data.tempValue,
    //                         "name": "Temperature",
    //                         "img": Height,
    //                         "unit": "(°F)",
    //                         "shortname": t("Temp")

    //                     },
    //                     {
    //                         "vmId": 2,
    //                         "vmValue": data.weight,
    //                         "name": "Weight",
    //                         "img": Weight,
    //                         "unit": "kg",
    //                         "shortname": t("Wt")

    //                     },
    //                     {
    //                         "vmId": 1,
    //                         "vmValue": "",
    //                         "name": "Height",
    //                         "img": Height,
    //                         "unit": "CM",
    //                         "shortname": t("Ht")
    //                     }])
    //             }

    //         }
    //     }
    // };

    // const toggleListening = () => {
    //     setIsListening(current => {
    //         const newState = !current;
    //         if (newState) {
    //             if (!recognitionActive.current) {
    //                 setTimeout(() => {
    //                     recognitionRef.current?.start();
    //                     recognitionActive.current = true;
    //                 }, 50);

    //             }
    //         } else {
    //             setTimeout(() => {
    //                 recognitionRef.current?.stop();
    //                 recognitionActive.current = false;                    
    //             }, 50);
    //         }
    //         return newState;
    //     });
    // };
    const toggleListening = () => {
        setIsListening(current => {
            const newState = !current;

            // Clear any existing timeout to avoid conflicts
            if (window.toggleTimeout) {
                clearTimeout(window.toggleTimeout);
            }

            window.toggleTimeout = setTimeout(() => {
                if (newState) {
                    if (!recognitionActive.current) {
                        recognitionRef.current?.start();
                        recognitionActive.current = true;
                    }
                } else {
                    if (recognitionActive.current) {
                        recognitionRef.current?.stop();
                        recognitionActive.current = false;
                    }
                }
            }, 50);

            return newState;
        });
    };




    let handleOnchange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        let temp = [...sendVitals]

        if (value !== "" && parseFloat(value) < 0) {
            // Prevent negative values
            // You can show an error message, set the input to 0, or handle it as you prefer
            // For example, setting it to 0:
            value = "0";
        }
        if (value !== "") {
            sendVitals.map(((val, ind) => {
                if (val.vmId === parseInt(name)) {
                    if ((val.maxLimit && parseInt(value) > val.maxLimit)) {
                        document.getElementById('vitalId' + parseInt(name)).style.border = "2px solid red";
                        document.getElementById('vitalLabel' + parseInt(name)).style.color = "red";
                        //temp[ind].vmValue = parseFloat(value);
                    } else {
                        temp[ind].vmValue = parseFloat(value);
                        document.getElementById('vitalId' + parseInt(name)).style.border = "1px solid #e5e5e5";
                        document.getElementById('vitalLabel' + parseInt(name)).style.color = "#1d4999";
                    }
                }
            }))
        }
        else {
            sendVitals.map(((val, ind) => {
                if (val.vmId === parseInt(name)) {
                    temp[ind].vmValue = ""
                }
            }))
        }


        setSendVitals(temp)
        SaveIPDData(temp, "jsonVital")
    }

    let patientsendDataChange = useSelector((state) => state.IPDPatientSendData)


    let setData = () => {
        let temp = window.sessionStorage.getItem("IPDpatientsendData") ? JSON.parse(window.sessionStorage.getItem("IPDpatientsendData")) : []
        let activeUHID = window.sessionStorage.getItem("IPDactivePatient") ? JSON.parse(window.sessionStorage.getItem("IPDactivePatient")).Uhid : []
        let tempVital = [...sendVitals]
        temp.map((value, index) => {
            value.map((val, ind) => {
                if (value[0] === activeUHID) {
                    let key = Object.keys(val)
                    if (key[0] === "jsonVital") {
                        if (val.jsonVital.length != 0) {
                            val.jsonVital.map((val, ind) => {
                                sendVitals.map((v, i) => {
                                    if (val.vmId === v.vmId) {
                                        tempVital[i]["vmValue"] = val.vmValue
                                    }
                                })
                            })
                            setSendVitals(tempVital)
                        }
                        else {
                            setSendVitals([
                                {
                                    "vmId": 56,
                                    "vmValue": "",
                                    "name": "spo2",
                                    "img": Height,
                                    "unit": "%",
                                    "shortname": t("SPO2"),
                                    "maxLimit": 100

                                },
                                {
                                    "vmId": 4,
                                    "vmValue": "",
                                    "name": "BP_Sys",
                                    "img": BloodPressure,
                                    "unit": "mmHg",
                                    "shortname": t("BPS"),
                                    "maxLimit": 250

                                },
                                {
                                    "vmId": 6,
                                    "vmValue": "",
                                    "name": "BP_Dias",
                                    "img": BloodPressure,
                                    "unit": "mmHg",
                                    "shortname": t("BPD"),
                                    "maxLimit": 250

                                },
                                {
                                    "vmId": 3,
                                    "vmValue": "",
                                    "name": "Pulse",
                                    "img": PulseRate,
                                    "unit": "bpm",
                                    "shortname": t("PR"),
                                    "maxLimit": 170

                                },
                                {
                                    "vmId": 7,
                                    "vmValue": "",
                                    "name": "respRate",
                                    "img": RespiratoryRate,
                                    "unit": "bpm",
                                    "shortname": t("RR"),
                                    "maxLimit": 30
                                },
                                {
                                    "vmId": 5,
                                    "vmValue": "",
                                    "name": "Temperature",
                                    "img": Height,
                                    "unit": "(°F)",
                                    "shortname": t("Temp"),
                                    "maxLimit": 109
                                },
                                {
                                    "vmId": 74,
                                    "vmValue": "",
                                    "name": "HR",
                                    "img": Heart,
                                    "unit": "bpm",
                                    "shortname": t("HR"),
                                    "maxLimit": 220
                    
                                },
                                // {
                                //     "vmId": 2,
                                //     "vmValue": "",
                                //     "name": "Weight",
                                //     "img": Weight,
                                //     "unit": "kg",
                                //     "shortname": t("Wt"),
                                //     "maxLimit": 300
                                // },
                                // {
                                //     "vmId": 1,
                                //     "vmValue": "",
                                //     "name": "Height",
                                //     "img": Height,
                                //     "unit": "CM",
                                //     "shortname": t("Ht"),
                                //     "maxLimit": 272
                                // }
                            ])
                        }
                    }
                }
            })
        })
        // SaveIPDData(tempVital, "jsonVital")

    }

    // useEffect(() => {
    //     if (props.loader === 1) {
    //         SaveIPDData(sendVitals, "jsonVital")
    //     }
    // }, [sendVitals])

    useEffect(() => {
        setData()
    }, [patientsendDataChange])

    return (
        <>


            <div className='d-flex justify-content-between  voicenvitals'>
                <div className={`flex-grow-1 d-flex flex-wrap  gap-1 ps-3 pt-2 pb-2 boxcontainer pe-3 boxs`}>
                    {/* {console.log('res 1111', sendVitals)} */}
                    {sendVitals && sendVitals.map((val, ind) => {
                        if (val.vmId === 4) {
                            return (
                                <div className=' d-flex flex-row didd' style={{ width: "190px", border: "1px solid #E5E5E5", borderRadius: "5px", 'margin-bottom': '0px', marginRight:'.5rem' }} >
                                    <div className="did-floating-label-content pe-2 ">
                                        <input autoComplete="off" className="did-floating-input" type="number" id={'vitalId' + val.vmId} style={{ maxWidth: "75px", border: "none" }} name={val.vmId} placeholder=" " value={val.vmValue != "" ? val.vmValue : ""} onChange={handleOnchange} />
                                        <label className={`${(val.vmValue === "") || (val.vmValue === 0) ? "did-floating-label" : !Number.isNaN(val.vmValue) ? "temp-did-floating-label" : "did-floating-label"} `} id={'vitalLabel' + val.vmId}> <img src={val.img} className='pe-1' />{val.shortname} <span className='vitalUnit'>{val.unit}</span></label>
                                    </div>
                                    <div className='pt-2'>/&nbsp;</div>
                                    <div className="did-floating-label-content pe-2 didd">
                                        <input autoComplete="off" className="did-floating-input" id={'vitalId' + 6} type="number" style={{ maxWidth: "75px", border: "none" }} name={6} placeholder=" " value={sendVitals[2].vmValue != "" ? sendVitals[2].vmValue : ""} onChange={handleOnchange} />
                                        <label className={`${(sendVitals[2].vmValue === "") || (sendVitals[2].vmValue === 0) ? "did-floating-label" : !Number.isNaN(val.vmValue) ? "temp-did-floating-label" : "did-floating-label"} `} id={'vitalLabel' + 6}> <img src={val.img} className='pe-1' />{sendVitals[2].shortname} <span className='vitalUnit'>{sendVitals[2].unit}</span></label>
                                    </div>
                                </div>
                            )
                        }
                        else if (val.vmId !== 6) {
                            return (

                                <div className="did-floating-label-content pe-2 didd">
                                    <input autoComplete="off" className="did-floating-input" type="number" id={'vitalId' + val.vmId} style={{ maxWidth: "108px" }} name={val.vmId} placeholder=" " value={val.vmValue != "" ? val.vmValue : ""} onChange={handleOnchange} />
                                    <label className={`${(val.vmValue === "") || (val.vmValue === 0) ? "did-floating-label" : !Number.isNaN(val.vmValue) ? "temp-did-floating-label" : "did-floating-label"} `} id={'vitalLabel' + val.vmId}> <img src={val.img} className='pe-1' />{val.shortname} <span className='vitalUnit'>{val.unit}</span></label>
                                </div>
                            )
                        }
                    })}

                    {/* <span style={{ flex: '1', display: 'flex', justifyContent: 'end' }} onClick={toggleListening}>
                    <i className={isListening ? "bi bi-mic-fill" : "bi bi-mic-mute-fill"}
                        style={{ fontSize: '22px', color: '#2866d8', cursor: 'pointer' }}></i>
                </span> */}


                    {/* <div className="did-floating-label-content pe-2">
                    <input className="did-floating-input" type="number" style={{ maxWidth: "108px" }} name="2" placeholder=" " value={sendVitals[6].vmValue != "" ? sendVitals[6].vmValue : ""} onChange={handleOnchange} />
                    <label className={`${sendVitals[6].vmValue === "" ? "did-floating-label" : !Number.isNaN(sendVitals[6].vmValue) ? "temp-did-floating-label" : "did-floating-label"} `}><img src={Weight} className='pe-1' />Wt <span className='vitalUnit'>(Kg)</span></label>
                </div>

                <div className="did-floating-label-content pe-2" >
                    <input className="did-floating-input" type="number" style={{ maxWidth: "108px" }} name="4" placeholder=" " value={sendVitals[1].vmValue != "" ? sendVitals[1].vmValue : ""} onChange={handleOnchange} />
                    <label className={`${sendVitals[1].vmValue === "" ? "did-floating-label" : !Number.isNaN(sendVitals[1].vmValue) ? "temp-did-floating-label" : "did-floating-label"} `}><img src={BloodPressure} className='pe-1' />BPS <span className='vitalUnit'>(mmHg)</span></label>
                </div>
                <div className="did-floating-label-content pe-2" >
                    <input className="did-floating-input" type="number" style={{ maxWidth: "108px" }} name="6" placeholder=" " value={sendVitals[2].vmValue != "" ? sendVitals[2].vmValue : ""} onChange={handleOnchange} />
                    <label className={`${sendVitals[2].vmValue === "" ? "did-floating-label" : !Number.isNaN(sendVitals[2].vmValue) ? "temp-did-floating-label" : "did-floating-label"} `}><img src={BloodPressure} className='pe-1' />BPD <span className='vitalUnit'>(mmHg)</span></label>
                </div>

                <div className="did-floating-label-content pe-2" >
                    <input className="did-floating-input" type="number" style={{ maxWidth: "108px" }} name="5" placeholder=" " value={sendVitals[5].vmValue != "" ? sendVitals[5].vmValue : ""} onChange={handleOnchange} />
                    <label className={`${sendVitals[5].vmValue === "" ? "did-floating-label" : !Number.isNaN(sendVitals[5].vmValue) ? "temp-did-floating-label" : "did-floating-label"} `}><img src={Height} className='pe-1' />Temp. <span className='vitalUnit'>(°F)</span></label>
                </div>


                <div className="did-floating-label-content pe-2" >
                    <input className="did-floating-input" type="number" style={{ maxWidth: "108px" }} name="56" placeholder=" " value={sendVitals[0].vmValue != "" ? sendVitals[0].vmValue : ""} onChange={handleOnchange} />
                    <label className={`${sendVitals[0].vmValue === "" ? "did-floating-label" : !Number.isNaN(sendVitals[0].vmValue) ? "temp-did-floating-label" : "did-floating-label"} `}><img src={Height} className='pe-1' />SPO2 <span className='vitalUnit'>(%)</span></label>
                </div>

                <div className="did-floating-label-content pe-2">
                    <input className="did-floating-input" type="number" style={{ maxWidth: "108px" }} name="3" placeholder=" " value={sendVitals[3].vmValue != "" ? sendVitals[3].vmValue : ""} onChange={handleOnchange} />
                    <label className={`${sendVitals[3].vmValue === "" ? "did-floating-label" : !Number.isNaN(sendVitals[3].vmValue) ? "temp-did-floating-label" : "did-floating-label"} `}><img src={PulseRate} className='pe-1' />PR <span className='vitalUnit'>(bpm)</span></label>
                </div>
                <div className="did-floating-label-content pe-2" >
                    <input className="did-floating-input" type="number" style={{ maxWidth: "108px" }} name="7" placeholder=" " value={sendVitals[4].vmValue != "" ? sendVitals[4].vmValue : ""} onChange={handleOnchange} />
                    <label className={`${sendVitals[4].vmValue === "" ? "did-floating-label" : !Number.isNaN(sendVitals[4].vmValue) ? "temp-did-floating-label" : "did-floating-label"} `}><img src={RespiratoryRate} className='pe-2' />RBS <span className='vitalUnit'>(mg/dl)</span></label>
                </div> */}


                    {/* <button className='btn-vitalhistory1 ' onClick={() => { console.log("babcb cscsd") }} >
                    <i className='fa fa-eye'></i> View More
                </button> */}
                </div>
                {/* Toaster */}
                {
                    showAlertToster === 1 ?
                        <AlertToster handle={setShowAlertToster} message={showErrMessage} /> : ""
                }
                <div className='d-flex flex-wrap ps-3 pt-2 pb-2 boxcontainer pe-3 ' style={{alignItems:'center'}}>
                    <div className='voiceMic d-flex flex-row justify-content-center align-items-center ' title='Voice Mic'>
                        <span onClick={startListening}>
                            <i className={`${listening ? "bi bi-mic-fill" : "bi bi-mic-mute-fill"} `}></i>
                        </span>
                    </div>

                    <div className='voiceLoader'>
                        <div className='list_'>
                            {listening ?
                                <div className='voiceLoder'><i className="fa fa-spinner fa-spin"></i></div>
                                :
                                ''
                            }
                        </div>
                    </div>

                    {!transcript ? '' : <div className='voiceText'>{transcript}</div>}
                </div>


            </div>
        </>

    )
}

