import React from 'react'

export default function NotificationSection() {
    return (
        <>
            <div className='d-flex flex-column gap-1'>
                <div className='d-flex flex-row gap-2'>
                    {/* <span>csdjcb</span>
                    <span>csdjcb</span> */}
                </div>
            </div>
        </>
    )
}
