import React ,  { useEffect, useState } from 'react'
import SuccessToster from "../../Component/SuccessToster";
import AlertToster from "../../Component/AlertToster";
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import GetInvestigationDetails from './API/GetInvestigationDetails';
import Select from 'react-select';
import GetPatientDetailsforSarvodaya from './API/GetPatientDetailsforSarvodaya';
import GetDiagnosis from './API/GetDiagnosis';
import GetAntiBioticHistory from './API/GetAntiBioticHistory';
import GetNameOfSpecimen from './API/GetNameOfSpecimen';
import GetSiteofPuse from './API/GetSiteofPuse';
import i18n from "i18next";
import imgPrint from '../../assets/images/icons/imgPrint.svg'
import MedicineProcedure from './MedicineProcedure';
import ProvisionalDiagnosiRows from './ProvisionalDiagnosiRows';
import InvestigationsRows from './InvestigationsRows';
import PostMicrobilogyInvestigation from './API/PostMicrobilogyInvestigation';
import PostDiagnosisData from './API/PostDiagnosisData';
import ProvisonalDiagnosisMainComponent from './ProvisonalDiagnosisMainComponent';
import GetMicrobiologyInvestigation from './API/GetMicrobiologyInvestigation.js';
import InvestigationMain from './InvestigationMain.js';
import { json } from 'react-router-dom';
import PatientDeatils from '../../Component/PatientDeatils.js';
import { t } from "i18next";
export default function MicrobiologyInvestigationRequest () {


    document.body.dir = i18n.dir()
  let data = {
    patientName : '',
    mrdNo : '', 
    age : '',
    gender : '',
    dateOfadmission : '',
    wardNo : '',
    bedNo : '',
    ipNo : '',
    MrNo : '',
    date : '',
    time : '',
    consultantDoctor : '',
    itemName : '',
    itemID : '',
    provisionalDiagnosis : '',
    antibioticHistory : '',
    nameOfSpecimen : '',
    siteofpuse : '',

  }

  
    const clientID=JSON.parse(sessionStorage.getItem("LoginData")).clientId;

    let [Investigationlist, setInvestigationlist] = useState([]);
    let [InvestigationJSON, setInvestigationJSON] = useState([]);
    let [Saverow, setSaverow] = useState([])
    let [getInvestigaionJSON, setgetInvestigaionJSON] = useState([]);
    let [getDiagnosis, setgetDiagnosis] = useState([]);
    let [getantibioticJson, setGetantibioticJson] = useState([]);

    let [saveDiagnosis, setSaveDiagnosis] = useState([]);
    const [medicationData, setMedicationData] = useState([])
    const [MicroBiologyInvestigation, setMicroBiologyInvestigation] = useState([])

    const [sendForm , setsendForm] = useState([data])
    let [userID, setUserID] = useState(JSON.parse(sessionStorage.getItem("LoginData")).userId);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectnameofSpecimen, setselectnameofSpecimen] = useState(null);
    const [nameofSpecimen, setnameofSpecimen] = useState([]);
    const [SiteNameList, setSiteNameList] = useState([]);
    const [selectedSiteName, setselectedSiteName] = useState(null);
    const [DiagnosisList, setDiagnosisList] = useState([]);
    const [AntibioticHistory, setAntibioticHistory] = useState([]);
    const [PatientData, setPatientData] = useState();
    const [isClearable, ] = useState(true);
    const [isSearchable, ] = useState(true);
    let [showAlertToster, setShowAlertToster] = useState(0);
  let [showSuccessToster, setShowSuccessToster] = useState(0)
  let [showMessage, setShowMeassage] = useState("");
  const [newlyAddedRowIndex, setNewlyAddedRowIndex] = useState(null);
  const [editRowIndex, setEditRowIndex] = useState(null);

  const currentDate = new Date();
  const DateFormated = currentDate.toISOString().substr(0, 10);
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  const formattedTime = `${hours}:${minutes}:${seconds}`;
 
 

    let requiredUHID = JSON.parse(sessionStorage.getItem("IPDpatientList"))[0].uhId;

  
       let getInvestagtions = async () =>{
        let items = await GetInvestigationDetails()
        if(items.status === 1){
          setInvestigationlist(items.responseValue);
          // console.log("items",items.responseValue)
    }
    }
    const handleClearInvestigation=(row)=>{
      setSaverow([row])
      }
    const handleMedicationDataUpdate = (temp,data) => {
        setMedicationData(temp,data);
      
    };
    const handleInvestigationJSON = (temp,data) => {
        setInvestigationJSON(temp,data);
      
    };
 const clearRow=(row)=>{
  setMedicationData([row])
 }

    // const handleExtraMed = (data) => {
    //     setextraMedName(data)
    // };
 

     let handlerSelectAll = () => {
      const isSelectedAll = document.getElementById("ddlSelectAllUser").checked;
      let tempArr = [];
      console.log('isSelectedAll', isSelectedAll)
      for (var i = 0; i < Investigationlist.length; i++) {
         let getID = Investigationlist[i].id;
         if (isSelectedAll === true) {
            document.getElementById(getID).checked = true;
            tempArr.push({
               id: getID
            })
         }
         else {
            document.getElementById(getID).checked = false;
         }
      }

      console.log('tempArr', tempArr)
     

   }

   const InvestigationChange = (id) => {
   
    const index = selectedItems.indexOf(id);
    if (index === -1) {
       setSelectedItems([...selectedItems, id]);
       console.log('value' , selectedItems)
    } else {
       const newSelectedItems = [...selectedItems];
       newSelectedItems.splice(index, 1);
       setSelectedItems(newSelectedItems);
    }
 };


 const GetPatientData= async()=>{
  let data = await GetPatientDetailsforSarvodaya(requiredUHID)
  if(data.status === 1){
    setPatientData(data.responseValue[0])
    console.log("PatientData" , data.responseValue)
  }

 }


 const handleSelectChange = (selectedOption, errorElementId, setSelectedFunction) => {
  // document.getElementById(errorElementId).style.display = 'none';
  setSelectedFunction(selectedOption);
  setNewlyAddedRowIndex(null);
};


const getProvisionalDiagnosis= async()=>{
  let data = await GetDiagnosis(requiredUHID)
  if(data.status === 1){
    setDiagnosisList(data.responseValue.map(val=>({
      value: val.detailID,
      label: val.details
    })))
    console.log("data",data.responseValue)
}
}
const getAntibioticHistory= async()=>{
let data = await GetAntiBioticHistory()
if(data.status === 1){
  setAntibioticHistory(data.responseValue.map(val=>({
  value: val.id,
  label: val.brandName
})))
}
// let data = JSON.parse(sessionStorage.getItem("IPDpatientsendData"))[0][2].prescriptionHistory
// console.log('antiHistory' , data)


}

const GetSpecimens = async()=>{
  let data = await GetNameOfSpecimen()
  if(data.status === 1){
    setnameofSpecimen(data.responseValue.map(val=>({
    value: val.id,
    label: val.sampleName
  })))
  }
}
const GetSiteName = async()=>{
  let data = await GetSiteofPuse()
  if(data.status === 1){
    setSiteNameList(data.responseValue.map(val=>({
    value: val.id,
    label: val.siteName
  })))
  }
}



const GetMicroBiology = async () => {
  let data = await GetMicrobiologyInvestigation(requiredUHID);
  if (data.status === 1) {
    const mappedData = data.responseValue.map(item => {
      return {
        ...item,
        antibiotics: item.antibioticJson ? JSON.parse(item.antibioticJson) : [],
        diagnosticFormnated : item.diagnosisJson ? JSON.parse(item.diagnosisJson) : [],
        investigationFormated : item.investigationJson ? JSON.parse(item.investigationJson) : [],
      };
    });

    setMicroBiologyInvestigation(mappedData);
    console.log("MicroBiologyInvestigation", mappedData);
    console.log("antibioticJSON", mappedData.antibioticJson);
 
    let allAntibiotics = [];
    let allDiagnosis = []
    let allInvestigations = []
    data.responseValue.forEach(item => {
          allAntibiotics.push(item.antibiotics);
          allDiagnosis.push(item.diagnosticFormnated)
          allInvestigations.push(item.investigationFormated)
          setGetantibioticJson(allAntibiotics)
         console.log("rahman",allAntibiotics);
     
    });

    const MainData = data.responseValue;
    // const AntibioticJSON = data.responseValue;

    const InvestigationJSONarray = [];
    // const antibioticDataArray = []
    // const observationDataArray = []
  

    
   
       MainData.forEach((item, index) => {
        const investigationJson = item.investigationJson;
        // const antiBioticJson = item.antibioticjson
        console.log("InvestigationJSONarray", JSON.parse(investigationJson));
        InvestigationJSONarray.push(JSON.parse(investigationJson));
        // antibioticDataArray.push(JSON.parse(antiBioticJson));
        // console.log("antibioticDataArray", JSON.parse(antiBioticJson));
      });
      
   
    setgetInvestigaionJSON(InvestigationJSONarray);
 
 
  }
};



let formattedDate = '';
if (PatientData && PatientData.admitDate) {
  const parts = PatientData.admitDate.split('/');
  formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`; 

}

const handelOnSave=async()=>{
   if (selectnameofSpecimen === null || selectnameofSpecimen === undefined){
    setShowAlertToster(1);
    setShowMeassage("Specimen name is required")
    return
   }
   if (selectedSiteName === null || selectedSiteName === undefined){
    setShowAlertToster(1);
    setShowMeassage("Site name is required")
    return
   }


    const obj ={
     uhid: requiredUHID,
     investigationJson: JSON.stringify(InvestigationJSON),
     specimenTypeId: parseInt(selectnameofSpecimen.value) ,
     antibioticHistoryJson: JSON.stringify(medicationData),
     siteTypeId: parseInt(selectedSiteName.value),
     clientId: clientID,

     userId: userID,
    }
    let clearData ={
      setClear : 1
     }
    setSaveDiagnosis(1)
    let data = await PostMicrobilogyInvestigation(obj);
    
    if (data.status === 1  ) {
        setShowSuccessToster(1)
        console.log("savedData" , obj)
        setShowMeassage(`Data Saved Successfully`)
        window.sessionStorage.setItem("runClearData" , JSON.stringify(clearData))
        handleClearInvestigation()
        GetMicroBiology()
        setNewlyAddedRowIndex(0);
        setTimeout(() => {
          window.sessionStorage.removeItem("runClearData");
        }, 1000);
        handleClear()
         setTimeout(() => {
        setShowSuccessToster(1)
        
      }, 2000);
    } else {
        setShowAlertToster(1)
        setShowMeassage(data.responseValue)
      setTimeout(() => {
        setShowAlertToster(0)
      }, 2000);
    }
}


const handlePrint=(index)=>{
  let obj = {

    pName : PatientData.patientName,
    age : PatientData.age,
    gender : PatientData.gender,
    ward : PatientData.wardName,
    bedNo : PatientData.bedName,
    ipNo : PatientData.ipNo, 
    MRno  : PatientData.ipNo, 
    dob : PatientData.admitDate,
    doctorName : PatientData.doctorName,
    date : DateFormated,
    time : formattedTime,
    rowData : MicroBiologyInvestigation[index]

  }
  window.sessionStorage.setItem("MicroBiologyInvestigation",JSON.stringify(obj));

  window.open("/MicrobiologyInvestigationprint/", "noopener,noreferrer");
  console.log("rowData" , obj)
}


 const handleClear=(temp)=>{
  setselectnameofSpecimen(null)
setselectedSiteName(null)


let clearData ={
  setClear : 1
 }
window.sessionStorage.setItem("runClearData" , JSON.stringify(clearData))
    handleClearInvestigation()
 
    setTimeout(() => {
      window.sessionStorage.removeItem("runClearData");
    }, 1000);
    clearRow()
 }

       useEffect(() => {
        getInvestagtions()
        GetPatientData()
        getProvisionalDiagnosis()
        getAntibioticHistory()
        GetSpecimens()
        GetSiteName()
        GetMicroBiology()
       
      }, [])

    return (
        <>
     <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
          <div className="col-12"><div className="med-box mb-1"><div className="title">Microbiology Investigation Request</div></div></div>
            <div className="col-12">
              <div className="med-box">

                <div className="inner-content">
                  <PatientDeatils/>
                  <div className="row">
                     
                <div className='fieldsett-in col-md-6'>
                <div className='fieldsett' >
                <span className='fieldse'>Diagnosis Details</span>
                  <div className='row px-4 mt-1'>

                 

                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
               
               {/* <ProvisionalDiagnosiRows dignosticDetails={handleDiagnosticJSON} /> */}
               <ProvisonalDiagnosisMainComponent isProvisional = {0} saveDiagnosis={saveDiagnosis} setSaveDiagnosis={setSaveDiagnosis}/>
               </div>


                  </div>
                  </div>
                  </div>
                  <div className='fieldsett-in col-md-6'>
                <div className='fieldsett' >
                  <div className='row'>

                 

                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
               
                <span className='fieldse'>Antibiotic History</span>
               <div className="row">
               <div className="" style={{maxHeight: '100px' , minHeight : '100px' , overflow : 'auto' }}>
                     <MedicineProcedure onMedicationDataUpdate={handleMedicationDataUpdate} clear={clearRow} />
                    </div>



               </div>
               
               </div>


                  </div>
                  </div>
                  </div>
                  </div>
               
                </div>
            
              </div>
            </div>


          

           
             
               <div className="col-12 mt-2">
                 <div className="med-box">
                  <div className="row">
                     <div className="inner-content d-flex flex-wrap">
                  
                <div className='fieldsett-in col-md-6'>
                <div className='fieldsett' style={{padding: '20px'}}>
                <span className='fieldse'></span>
               <div className="row">
              
                   
                       <div className="col-xxl-6 col-xl-4 col-lg-6 col-md-6 mb-3">
                       <label htmlFor="ddlitemmaster" className="form-label">Name Of Specimen<span className="starMandatory">*</span></label>
                       <Select  value={selectnameofSpecimen} placeholder="Select Name Of Specimen"  options={nameofSpecimen} className="create-select" id="DialysisTypeTable" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, "errdialysistype", setselectnameofSpecimen)} />
                       <small id="errperformdoctor" className="form-text text-danger" style={{ display: 'none' }}></small>
                     </div>

                     <div className="col-xxl-6 col-xl-4 col-lg-6 col-md-6 mb-3">
                       <label htmlFor="ddlitemmaster" className="form-label">Site(In case of Pus and Swap)<span className="starMandatory">*</span></label>
                       <Select  value={selectedSiteName} placeholder="Site(In case of Pus and Swap)"  options={SiteNameList} className="create-select" id="DialysisTypeTable" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, "errdialysistype", setselectedSiteName)} />
                       <small id="errperformdoctor" className="form-text text-danger" style={{ display: 'none' }}></small>
                     </div>
               </div>

               </div>
               </div>
               <div className='fieldsett-in col-md-6'>
                <div className='fieldsett' style={{minHeight: '110px'}}>
                <span className='fieldse'>Investigations Required</span>
                <div style={{minHeight: '90px' , maxHeight : '90px' , overflow : 'auto'}}>
                <InvestigationsRows  investigationsJson = {handleInvestigationJSON} clearData={handleClearInvestigation}/>
                </div>
               
     
               </div>
               </div>
               </div>
                  </div>
               
               </div>
               </div>
             
          </div>


          <div className="col-12 ">
             <div className="med-box mt-2">
               <div className="inner-content">
               <div className="row">

                <div className="inner-content text-right">
                  <div className="mb-2 mt-2 relative">
                    <div>
                      <button type="button" data-bs-toggle="modal" data-bs-target="#deleteModal" className="btn btn-save btn-save-fill btn-sm mb-1_ me-1"><img src={saveButtonIcon} className="icnn" alt='' />Save</button>
                      <button type="button" className="btn btn-clear btn-sm mb-1_ me-1" onClick={handleClear} ><img  alt='' />Clear</button>
                 
                  </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            

          <div className="col-12 mt-3">
              <div className="med-table-section" style={{ "height": "80vh" }}>
                <table className="med-table border striped">
                  <thead style={{zIndex: '0'}}>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                      <th>UHID</th>
                      <th>Test Type</th>
                      <th>Specimen Name</th>
                      <th>Site Name</th>
                      <th>Investigations</th>
                      <th>AntiBiotic history</th>
                      <th>Diagnosis</th>
                      <th style={{ "width": "10%" }} className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {MicroBiologyInvestigation && MicroBiologyInvestigation.map((data, index) => {
                       const isNewRow = newlyAddedRowIndex === index;
                        return(
                         <tr className={isNewRow ? 'new-row' : ''} key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td>{requiredUHID}</td>
                          <td>{data.testType}</td>
                          <td>{data.specimenTypeName}</td>
                          <td>{data.siteName}</td>
                         <td>
                             {data.investigationFormated.map((val, index) => (
                              <span key={index}>{val.TestName}
                                 {index < data.investigationFormated.length - 1 && <br />}
                                  </span>
                                 ))}
                         </td>
                         <td>
                             {data.antibiotics.map((val, index) => (
                              <span key={index}>{val.details}
                                 {index < data.antibiotics.length - 1 && <br />}
                                  </span>
                                 ))}
                         </td>

                          <td>{data.diagnosticFormnated.map((val, index) => (
                              <span key={index}>{val.details}
                                 {index < data.diagnosticFormnated.length - 1 && <br />}
                              </span>
                            ))}
                          </td>
                      
                          <td>
                          
                            <div className="action-button">
                            <div  data-bs-title="Delete Row" data-bs-placement="bottom"  ><img src={imgPrint} style={{width: '20px'}} className='' alt='' onClick={()=>handlePrint(index)} />
                              </div>
                            </div>
                          </td>
                        </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>



        </div>
        {showAlertToster === 1 ? (
              <AlertToster message={showMessage} handle={setShowAlertToster} />
            ) : (
              ""
            )}
           {showSuccessToster === 1 ? (
              <SuccessToster
                message={showMessage}
                handle={setShowSuccessToster}
              />
            ) : (
              ""
            )}

      </section>

       {/*  <!------------------- Start Delete Modal ---------------------------------->  */}
       <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modalDelete">
                    <div className="modal-content">

                        <div className="modal-body modelbdy text-center">
                            <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                            <div className='popDeleteTitle mt-3'> {t("Save?")}</div>
                            <div className='popDeleteContent'>{t("Are_you_sure_you_want_to_save?")}</div>
                        </div>
                        <div className="modal-footer1 text-center">

                            <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">{t("Cancel")}</button>
                            <button type="button" className="btn-delete popBtnDelete" onClick={handelOnSave} data-bs-dismiss="modal">{t("Save")}</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* {/ -----------------------End Delete Modal Popup--------------------- /} */}
 
        </>
    )
}


