let DeleteFoodIntake = async (param) => {
    let url = window.DietservicesUrl + "/api/FoodIntake/DeleteFoodIntake?Uhid=" + param.Uhid + "&foodId=" + param.foodId;
    const head = { 'Content-Type': 'application/json-patch+json', 'acept': '*/*', };
    let data = {};
    let response = await fetch(url, {
        method: "DELETE",
        headers: head,
        body: JSON.stringify()
    }).then((res) => res.json()).then(data);
    return response;
}
export default DeleteFoodIntake;