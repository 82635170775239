import React, { useEffect, useState } from 'react'
import LeftTable from './LeftTable';
import RightTable from './RightTable';
import AllTable from './AllTable';
import GridPMDIndex from '../GridPMDIndex';

export default function MakeTable({ isGridView,setIsGridView, showContextMenu, showHideColumn, originalColumn, fullPatientDataList, showNextData, handleshowHide, handleSearch }) {
    let [randomValue, setRandomValue] = useState(Math.random())

    useEffect(() => {

        let intervalId = setInterval(() => {
            setRandomValue(Math.random());
        }, 60000)
        return () => {
            clearInterval(intervalId);
        };
    }, [])
    return (
        <div>

            <>
                {

                    isGridView === 0 ?


                        <div className="scroll-in-section" id="scrollinsection" onContextMenu={showContextMenu}>
                            <div className="table-monitor-section">
                                {showHideColumn === -1 ?
                                    <>
                                        {
                                            originalColumn.length <= 11 ?
                                                <LeftTable setIsGridView={setIsGridView} fullPatientDataList={fullPatientDataList} showNextData={showNextData} originalColumn={originalColumn.slice(0, 5)} fullColumns={originalColumn.length} handleshowHide={handleshowHide} />
                                                :
                                                <>
                                                    <LeftTable setIsGridView={setIsGridView} fullPatientDataList={fullPatientDataList} showNextData={showNextData} originalColumn={originalColumn.slice(0, 5)} handleshowHide={handleshowHide} />


                                                    <RightTable setIsGridView={setIsGridView} fullPatientDataList={fullPatientDataList} showNextData={showNextData} originalColumn={originalColumn.slice(5, originalColumn.length)} handleshowHide={handleshowHide} />
                                                </>
                                        }
                                    </>
                                    : showHideColumn === 0 ?

                                        <AllTable fullPatientDataList={fullPatientDataList} showNextData={showNextData} originalColumn={originalColumn} handleshowHide={handleshowHide} /> : ""

                                }




                            </div>
                        </div>
                        :

                        <div className='scroll-in-section' id="scrollinsection">

                            <GridPMDIndex originalColumn={originalColumn} fullPatientDataList={fullPatientDataList} showNextData={showNextData} handleSearch={handleSearch}/>

                        </div>

                }
            </>

        </div>
    )
}
