import React, { useEffect, useState } from "react";
import TosterUnderProcess from '../../Component/TosterUnderProcess';
import Toster from '../../Component/Toster';
import Loder from '../../Component/Loader';
import Select from 'react-select';
import saveButtonIcon from '../../assets/images/icons/saveButton.svg';
import clearIcon from '../../assets/images/icons/clear.svg';
import deleteBtnIcon from '../../assets/images/icons/delete.svg';
import editBtnIcon from '../../assets/images/icons/edit.svg';
import PostDialysisType from "../API/DialysisTypeMaster/PostDialysisType";
import DeleteDialysisType from "../API/DialysisTypeMaster/DeleteDialysisType";
import PutDialysisType from "../API/DialysisTypeMaster/PutDialysisType";
import GetAllDialysisBooking from "../API/DialysisBooking/GetAllDialysisBooking";
import GetadvicedDoctorsList from "../API/DialysisBooking/GetadvicedDoctorsList";
import GetDialysisType from "../API/DialysisTypeMaster/GetDialysisType";
import GetDialysisPerformDoctor from "../API/DialysisBooking/GetDialysisPerformDoctor";
import PostDialysisBooking from "../API/DialysisBooking/PostDialysisBooking";
import GetDialysisPerform from "../API/DialysisPerform/GetDialysisPerform";
import PostDialysisPerform from "../API/DialysisPerform/PostDialysisPerform";
import getPatientDetailByUhid from "../API/DialysisBooking/getPatientDetailByUhid";



 export default function DialysisPerform() {

  const [showUnderProcess, setShowUnderProcess] = useState(0);
  const [showToster, setShowToster] = useState(0);
  const [tosterMessage, setTosterMessage] = useState("");
  const [tosterValue, setTosterValue] = useState(0);
  const [showLoder, setShowLoder] = useState(0);
  const [rowID, setRowID] = useState(0);
  const [isUpdateBtnShow, setIsUpdateBtnShow] = useState(false);
  const [pmID, setpmID] = useState('');
  const [UHID, setUHID] = useState('');
  const [predialysisWeight, setpredialysisWeight] = useState('');
  const [startTime, setstartTime] = useState('');
  const [selecteddialysisType, setselecteddialysisType] = useState(null);
  const [DialysisTypeTable, setDialysisTypeTable] = useState([]);
  const [EndDate, setEndDate] = useState('');
  const [adviceDoctors, setadviceDoctors] = useState([]);
  const [PerformDoctors, setPerformDoctors] = useState([]);
  const [EndTime, setEndTime] = useState('');
  const [PostdialysisWeight, setPostdialysisWeight] = useState('');
  const [actionTaken, setactionTaken] = useState('');
  const [bloodFlowRate, setbloodFlowRate] = useState('');
  const [dialysisFlowRate, setdialysisFlowRate] = useState('');
  const [dialysisStatus, setdialysisStatus] = useState('');
  const [resonPredialysisWeight, setresonPredialysisWeight] = useState('');
  const [selectedPerformDoctors, setselectedPerformDoctors] = useState(null);
  const [startDate, setstartDate] = useState('');
  const [referredBy, setreferredBy] = useState('');
  const [selectedAdviceDoctor, setselectedAdviceDoctor] = useState(null);
  const [dialysisPerformTable, setdialysisPerformTable] = useState([])
  const [newlyAddedRowIndex, setNewlyAddedRowIndex] = useState(null);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [isNewRowAdded, setIsNewRowAdded] = useState(false);
  const [isClearable, ] = useState(true);
  const [isSearchable, ] = useState(true);
  const [PatientDetailsbyUHID, setPatientDetailsbyUHID] = useState(1);
  let [userID, setUserID] = useState(JSON.parse(sessionStorage.getItem("LoginData")).userId);

  const clientID  = JSON.parse(sessionStorage.getItem("LoginData")).clientId;
  



  const handleSelectChange = (selectedOption, errorElementId, setSelectedFunction) => {
    document.getElementById(errorElementId).style.display = 'none';
    setSelectedFunction(selectedOption);
  };


  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };


const DropdownsList = async()=>{
  let DialysisType = await GetDialysisType()
  if(DialysisType.status === 1){
    setDialysisTypeTable(DialysisType.responseValue.map(val=>({
      value: val.id,
      label: val.dialysisType
    })))
  }



}


  const handleOnChange = async (e) => {

    document.getElementById('errdialysisStatus').style.display = 'none';
    document.getElementById('errUHID').style.display = 'none';
    document.getElementById('errdialysisFlowRate').style.display = 'none';
    document.getElementById('errdialysistype').style.display = 'none';
     document.getElementById('errbloodFlowRate').style.display = 'none';
     document.getElementById('erractionTaken').style.display = 'none';
    document.getElementById('errpredialysisWeight').style.display = 'none';
    document.getElementById('errstartDate').style.display = 'none';
    document.getElementById('errstartTime').style.display = 'none';
    document.getElementById('errendtime').style.display = 'none';
    document.getElementById('errenddate').style.display = 'none';
    document.getElementById('errPostdialysisWeight').style.display = 'none';
    document.getElementById('errresonPredialysisWeight').style.display = 'none';

    setIsNewRowAdded(false)
    setNewlyAddedRowIndex(null);

  
    const { name, value } = e.target;
    if (name === 'UHID') {
      let uhid = e.target.value.toUpperCase()
        setUHID(uhid);
        let data = await getPatientDetailByUhid(uhid)
        if(data.status === 1){
          setPatientDetailsbyUHID(data.responseValue[0])
          console.log("pData>>>" , data.responseValue)
        }
        if(uhid.lengh !== 9 || !PatientDetailsbyUHID){
          
           document.getElementById("pName").value = ""
           document.getElementById("pmID").value = ""
           document.getElementById("gender").value = ""
           document.getElementById("DName").value = ""
           
        }
    
    }
    if (name === 'startDate') {
        setstartDate(value);
    }
  if (name === 'startTime'){
    setstartTime(value)
  }
  if(name === 'predialysisWeight'){
    setpredialysisWeight(value)
  }

  
  if(name === 'endDate'){
    setEndDate(value)
  }
  if(name === 'endTime'){
    setEndTime(value)
  }
  if(name === 'PostdialysisWeight'){
    setPostdialysisWeight(value)
    if(value < predialysisWeight || value > predialysisWeight ){
    document.getElementById('mandatoryforreason').style.display = 'block'
    document.getElementById('mandatoryforaction').style.display = 'block'
    }
    else if (value == predialysisWeight){
      document.getElementById('mandatoryforreason').style.display = 'none'
      document.getElementById('mandatoryforaction').style.display = 'none'
    }
  }
  if(name === 'resonPredialysisWeight'){
    setresonPredialysisWeight(value)
  }
  if(name === 'actionTaken'){
    setactionTaken(value)
  }
  if(name === 'bloodFlowRate'){
    setbloodFlowRate(value)
  }
  if(name === 'dialysisFlowRate'){
    setdialysisFlowRate(value)
  }
  if(name === 'dialysisStatus'){
    setdialysisStatus(value)
  }
  if(name === 'dialysisStatus'){
    setdialysisStatus(value)
  }
  };


const GetallDialysisPerform= async()=>{
let DialysisPerform = await GetDialysisPerform()
if(DialysisPerform.status === 1){
    setdialysisPerformTable(DialysisPerform.responseValue)
    console.log("Dialysis Perform" , DialysisPerform.responseValue)
   
}
}


  const handleOnSave = async () => {


    let PName = document.getElementById("pName").value;
 
    if (UHID.trim() === '') {
      document.getElementById('errUHID').innerHTML = 'Please Enter UHID';
      document.getElementById('errUHID').style.display = 'block';
      return;
    }
    if (UHID.length !==9 || PName === '') {
      document.getElementById('errUHID').innerHTML = 'UHID is Invalid';
      document.getElementById('errUHID').style.display = 'block';
      return;
    }
  
    if (selecteddialysisType === null || undefined) {
      document.getElementById('errdialysistype').innerHTML = 'Please Select Dialysis Type';
      document.getElementById('errdialysistype').style.display = 'block';
      return;
    }
    if (startDate === '') {
      document.getElementById('errstartDate').innerHTML = 'Please Select Start Date';
      document.getElementById('errstartDate').style.display = 'block';
      return;
    }
    if (startTime === '') {
      document.getElementById('errstartTime').innerHTML = 'Please Select Start Time';
      document.getElementById('errstartTime').style.display = 'block';
      return;
    }
    if (predialysisWeight === '') {
      document.getElementById('errpredialysisWeight').innerHTML = 'Please Enter Predialysis Weight';
      document.getElementById('errpredialysisWeight').style.display = 'block';
      return;
    }
    if (predialysisWeight <= 0 ) {
      document.getElementById('errpredialysisWeight').innerHTML = 'Predialysis Weight Is Not valid';
      document.getElementById('errpredialysisWeight').style.display = 'block';
      return;
    }
    if (EndDate === ''){
      document.getElementById('errenddate').innerHTML = 'Please Select End Date';
      document.getElementById('errenddate').style.display = 'block';
      return;
    }
    if (EndTime === ''){
      document.getElementById('errendtime').innerHTML = 'Please Select End Time';
      document.getElementById('errendtime').style.display = 'block';
      return;
    }
    if (PostdialysisWeight  === ''){
      document.getElementById('errPostdialysisWeight').innerHTML = 'Please Enter Post dialysis Weight';
      document.getElementById('errPostdialysisWeight').style.display = 'block';
      return;
    }
    if (PostdialysisWeight <= 0){
      document.getElementById('errPostdialysisWeight').innerHTML = 'PostdialysisWeight Weight Is Not valid';
      document.getElementById('errPostdialysisWeight').style.display = 'block';
      return;
    }
    if (PostdialysisWeight > predialysisWeight || PostdialysisWeight < predialysisWeight && resonPredialysisWeight.trim() === '' ){
      document.getElementById('errresonPredialysisWeight').innerHTML = 'Please Enter Reason For Predialysis Weight';
      document.getElementById('errresonPredialysisWeight').style.display = 'block';
      return;
    }
    if (PostdialysisWeight > predialysisWeight || PostdialysisWeight < predialysisWeight  && actionTaken.trim() === ''){
      document.getElementById('erractionTaken').innerHTML = 'Please Enter Action Taken';
      document.getElementById('erractionTaken').style.display = 'block';
      return;
    }
    if (bloodFlowRate === ''){
      document.getElementById('errbloodFlowRate').innerHTML = 'Please Enter Blood Flow Rate';
      document.getElementById('errbloodFlowRate').style.display = 'block';
      return;
    }
    if (dialysisFlowRate === ''){
      document.getElementById('errdialysisFlowRate').innerHTML = 'Please Enter Dialysis Flow Rate';
      document.getElementById('errdialysisFlowRate').style.display = 'block';
      return;
    }
    if (dialysisStatus.trim() === ''){
      document.getElementById('errdialysisStatus').innerHTML = 'Please Enter Dialysis Status';
      document.getElementById('errdialysisStatus').style.display = 'block';
      return;
    }


    const obj = {
  uhid: UHID,
  dialysisTypeId: selecteddialysisType.value,
  startDate: startDate,
  startTime: startTime,
  weightPreDialysis: predialysisWeight,
  endDate: EndDate,
  endTime: EndTime,
  weightPostDialysis: PostdialysisWeight,
  reasonForWeightPreDialysis: resonPredialysisWeight,
  actionTaken: actionTaken,
  bloodFlowRate: bloodFlowRate,
  dialysisFlowRate: dialysisFlowRate,
  dialyzerStatus: dialysisStatus,

      userId: userID,
      clientId: clientID
  
    };
    console.log('obj', obj)

    let data = await PostDialysisPerform(obj);
    if (data.status === 1) {
      setShowUnderProcess(0);
      setShowToster(1);
      setTosterValue(0);
      setTosterMessage("Data Saved Successfully!");
      GetallDialysisPerform()
      setIsNewRowAdded(true);
      handleClear();
      setTimeout(() => {
        setShowToster(0);
 setIsNewRowAdded(null)

      }, 2000);
    } else {
      setShowUnderProcess(0);
      setShowToster(1);
      setTosterValue(1);
      setTosterMessage("Already Exist!");
      setTosterMessage(data.responseValue);
      setTimeout(() => {
        setShowToster(0);
      }, 2000);
    }
  };

  const handleClear = () => {
    document.getElementById("pName").value = ""
    document.getElementById("pmID").value = ""
    document.getElementById("gender").value = ""
    document.getElementById("DName").value = ""
    setPatientDetailsbyUHID([])
    document.getElementById('errdialysisStatus').style.display = 'none';
    document.getElementById('errUHID').style.display = 'none';
    document.getElementById('errdialysisFlowRate').style.display = 'none';
    document.getElementById('errdialysistype').style.display = 'none';
    document.getElementById('errbloodFlowRate').style.display = 'none';
    document.getElementById('erractionTaken').style.display = 'none';
    document.getElementById('errpredialysisWeight').style.display = 'none';
    document.getElementById('errstartDate').style.display = 'none';
    document.getElementById('errstartTime').style.display = 'none';
    document.getElementById('errendtime').style.display = 'none';
    document.getElementById('errenddate').style.display = 'none';
    document.getElementById('errPostdialysisWeight').style.display = 'none';
    document.getElementById('errresonPredialysisWeight').style.display = 'none';



    setUHID('')
    setstartDate('')
    setstartTime('')
    setpredialysisWeight('')
    setPostdialysisWeight('')
    setactionTaken('')
    setbloodFlowRate('')
    setdialysisFlowRate('')
    setdialysisStatus('')
    setresonPredialysisWeight('')
    setselecteddialysisType(null)
    
    setEndDate('')
    setEndTime('')

  };



 
  const handleCancel = () => {
    document.getElementById("pName").value = ""
    document.getElementById("pmID").value = ""
    document.getElementById("gender").value = ""
    document.getElementById("DName").value = ""
    setPatientDetailsbyUHID([])
    handleClear()
    setIsUpdateBtnShow(false);
    setEditRowIndex(null)
    setNewlyAddedRowIndex(null);

  };

 




  useEffect(() => {
    GetallDialysisPerform();
    DropdownsList()
   
   
  }, []);


  return (
    <>
      <section className="main-content mt-5 pt-3">
        <div className="container-fluid">
          <div className="row">
          {/* <div className="col-12"><div className="med-box  mb-1"><div className="title">Dialysis Perform</div></div></div> */}
            <div className="col-12">
              <div className="med-box">
              
                <div className="inner-content" >
                {/* <span className='fieldse'>Dialysis Perform</span> */}
                  <div className='row'>

                  <div className='fieldsett-in col-md-12'>
                    <div className="fieldsett">
                    <span className='fieldse'>Dialysis Perform</span>
                      <div className="row">
                      {PatientDetailsbyUHID && (
                              <>
                              
                              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">UHID<span className="starMandatory">*</span></label>
                          <input value={UHID} id="dialysisType" type="text" className="form-control form-control-sm" name="UHID" placeholder= "Enter UHID" onChange={handleOnChange} />
                          <small id="errUHID" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>
                    
                 
                 
                              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Patient Name<span className="starMandatory"></span></label>
                          <input value={PatientDetailsbyUHID.patientName} id="pName" type="text" className="form-control form-control-sm" name="pmID" placeholder= "Patient Name" disabled onChange={handleOnChange} />
                          <small id="errpmID" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">PmID<span className="starMandatory"></span></label>
                          <input value={PatientDetailsbyUHID.pmid} id="pmID" type="text" className="form-control form-control-sm" name="pmID" placeholder= "PmID" disabled onChange={handleOnChange} />
                          <small id="errpmID" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>


                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Gender<span className="starMandatory"></span></label>
                          <input value={PatientDetailsbyUHID.gender} id="gender" type="text" className="form-control form-control-sm" name="pmID" placeholder= "Gender" disabled onChange={handleOnChange} />
                          <small id="errpmID" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Department Name<span className="starMandatory"></span></label>
                          <input value={PatientDetailsbyUHID.departName} id="DName" type="text" className="form-control form-control-sm" name="pmID" placeholder= "Department" disabled onChange={handleOnChange} />
                          <small id="errpmID" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>
                 
                     


                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                      <label htmlFor="ddlitemmaster" className="form-label">Dialysis Type<span className="starMandatory">*</span></label>
                      <Select value={selecteddialysisType} placeholder="Select Dialysis Type" options={DialysisTypeTable} className="create-select" id="DialysisTypeTable" isSearchable={isSearchable} isClearable={isClearable} onChange={selectedOption => handleSelectChange(selectedOption, "errdialysistype", setselecteddialysisType)} />
                      <small id="errdialysistype" className="form-text text-danger" style={{ display: 'none' }}></small>
                    </div>


                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Start Date<span className="starMandatory">*</span></label>
                          <input value={startDate} id="startDate" type="date" className="form-control form-control-sm" name="startDate"  onChange={handleOnChange} />
                          <small id="errstartDate" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Start Time<span className="starMandatory">*</span></label>
                          <input value={startTime} id="startTime" type="time" className="form-control form-control-sm" name="startTime"  onChange={handleOnChange} />
                          <small id="errstartTime" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>


                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Pre Dialysis Weight<span className="starMandatory">*</span></label>
                          <input value={predialysisWeight} id="predialysisWeight" type="number" className="form-control form-control-sm" name="predialysisWeight" placeholder= "Enter Pre Dialysis Weight" onChange={handleOnChange} />
                          <small id="errpredialysisWeight" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">End Date<span className="starMandatory">*</span></label>
                          <input value={EndDate} min={getCurrentDate()} id="startDate" type="date" className="form-control form-control-sm" name="endDate"  onChange={handleOnChange} />
                          <small id="errenddate" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">End Time<span className="starMandatory">*</span></label>
                          <input value={EndTime} min={getCurrentTime()} id="startTime" type="time" className="form-control form-control-sm" name="endTime"  onChange={handleOnChange} />
                          <small id="errendtime" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Post Dialysis Weight<span className="starMandatory">*</span></label>
                          <input value={PostdialysisWeight} id="PostdialysisWeight" type="number" className="form-control form-control-sm" name="PostdialysisWeight" placeholder= "Enter Post Dialysis Weight" onChange={handleOnChange} />
                          <small id="errPostdialysisWeight" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Reason for Pre Dialysis Weight<span className="starMandatory" id="mandatoryforreason"style={{display: 'none'}}>*</span></label>
                          <input value={resonPredialysisWeight} id="resonPredialysisWeight" type="text" className="form-control form-control-sm" name="resonPredialysisWeight" placeholder= "Enter Reason for Pre Dialysis Weight" onChange={handleOnChange} />
                          <small id="errresonPredialysisWeight" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Action Taken<span className="starMandatory" id="mandatoryforaction" style={{display: 'none'}}>*</span></label>
                          <input value={actionTaken} id="actionTaken" type="text" className="form-control form-control-sm" name="actionTaken" placeholder= "Enter Action Taken" onChange={handleOnChange} />
                          <small id="erractionTaken" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Blood Flow Rate<span className="starMandatory">*</span></label>
                          <input value={bloodFlowRate} id="bloodFlowRate" type="text" className="form-control form-control-sm" name="bloodFlowRate" placeholder= "Enter Blood Flow Rate" onChange={handleOnChange} />
                          <small id="errbloodFlowRate" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Dialysis Flow Rate<span className="starMandatory">*</span></label>
                          <input value={dialysisFlowRate} id="dialysisFlowRate" type="text" className="form-control form-control-sm" name="dialysisFlowRate" placeholder= "Enter Dialysis Flow Rate" onChange={handleOnChange} />
                          <small id="errdialysisFlowRate" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                          <label htmlFor="Code" className="form-label">Dialysis Status<span className="starMandatory">*</span></label>
                          <input value={dialysisStatus} id="dialysisStatus" type="text" className="form-control form-control-sm" name="dialysisStatus" placeholder= "Enter Dialysis Status" onChange={handleOnChange} />
                          <small id="errdialysisStatus" className="form-text text-danger" style={{ display: 'none' }}></small>
                        </div>
                              
                              
                              </>
                     )}
                        
             

                      </div>
                      <div className="rt-btns">
                        <div className="col-xxl-4 col-xl-3 col-lg-4 col-md-6 mb-1 mt-3 rt-btns ">
                          <label htmlFor="exampleFormControlInput1" className="form-label">&nbsp;</label>

                          {showUnderProcess === 1 ? <><TosterUnderProcess />  </> :
                            showToster === 1 ? <Toster value={tosterValue} message={tosterMessage} />
                              :
                              <div>
                                {isUpdateBtnShow !== true ? <>
                                  <button type="button" className="btn btn-save btn-save-fill btn-sm mb-1 me-1" onClick={handleOnSave}><img src={saveButtonIcon} className='icnn' alt="" />Save</button>
                                  <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleClear}><img src={clearIcon} className='icnn' alt="" />Clear</button>
                                </> :
                                  <>
                                    <button type="button" className="btn btn-save btn-sm mb-1 me-1 " >Update</button>
                                    <button type="button" className="btn btn-clear btn-sm mb-1 me-1" onClick={handleCancel} >Cancel</button>
                                  </>
                                }
                              </div>
                          }
                        </div>
                      </div>
                      
                    </div>
                    </div>

              

                      </div>
                    </div>
   

              </div>
            </div>


            {/* table is made using getAllItemMaster API and mapped the data   */}


            <div className="col-12 mt-3">
              <div className="med-table-section" style={{ "height": "80vh" }}>
                <table className="med-table border_ striped">
                  <thead style={{ zIndex: '0' }}>
                    <tr>
                      <th className="text-center" style={{ "width": "5%" }}>#</th>
                  
                      <th>UHID</th>
                      <th>Dialysis Type</th>
                      <th>Start Date & Time</th>
                      <th>Pre Dialysis Weight</th>
                      <th>End Date & Time</th>
                      <th>Post Dialysis Weight</th>
                      <th>Reason For Dialysis Weight</th>
                      <th>Action Taken</th>
                      <th>Blood Flow Rate</th>
                      <th>Dialysis Flow Rate</th>
                      <th>Dialysis Status</th>
                   
             
                      {/* <th style={{ "width": "10%" }} className="text-center">Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {dialysisPerformTable && dialysisPerformTable.map((data, index) => {
                      const isNewRow = newlyAddedRowIndex === index;
                      const isEditing = index === editRowIndex;
                      
                      return (
                        <tr className={index === dialysisPerformTable.length - 1 && isNewRowAdded ? 'new-row' : '' || isNewRow ? 'new-row' : ''} key={index}>
                          <td className="text-center">{index + 1}</td>
                          
                          <td>{data.uhid}</td>
                          <td>{data.dialysisType}</td>
                          <td>{data.startDate}<br/><span>{data.startTime}</span></td>
                          <td>{data.weightPreDialysis}</td>
                          <td>{data.endDate}<br/><span>{data.endTime}</span></td>
                          <td>{data.weightPostDialysis}</td>
                          <td>{data.reasonForWeightPreDialysis}</td>
                          <td>{data.actionTaken}</td>
                          <td>{data.bloodFlowRate + "mL/min"}</td>
                          <td>{data.dialysisFlowRate + "ml/min"}</td>
                          <td>{data.dialyzerStatus}</td>
                        
                        
                          
      
{/* 
                          <td>
                            <div className="action-button">
                              <div
                                data-bs-toggle="tooltip"
                                data-bs-title="Edit Row"
                                data-bs-placement="bottom"

                              >
                                <img src={editBtnIcon} className={isEditing ? 'edited-row' : ''} alt='' onClick={() => { edit(data, index) }} />
                              </div>
                              <div data-bs-toggle="modal" data-bs-title="Delete Row" data-bs-placement="bottom" data-bs-target="#deleteModal"><img src={deleteBtnIcon} className='' alt='' onClick={() => { setRowID(data.id); }} />
                              </div>
                            </div>
                          </td> */}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* -----------------------Start Delete Modal Popup-------------------    */}

        <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
          <div className="modal-dialog modalDelete">
            <div className="modal-content">
              <div className="modal-body modelbdy text-center">
                <div className='popDeleteIcon'><i className="fa fa-trash"></i></div>
                <div className='popDeleteTitle mt-3'>Delete?</div>
                <div className='popDeleteContent'>Are you sure you want to delete?</div>
              </div>
              <div className="modal-footer1 text-center">

                <button type="button" className="btncancel popBtnCancel me-2" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn-delete popBtnDelete" data-bs-dismiss="modal" >Delete</button>
              </div>
            </div>
          </div>
        </div>
        {/* -----------------------End Delete Modal Popup---------------------  */}
        {
          showLoder === 1 ? <Loder val={showLoder} /> : ""
        }
      </section>

    </>

  )
}


