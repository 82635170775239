import React, { useEffect } from 'react'

import Fiosbg from '../../../../assets/images/patientmonitordashboard/V-FiO2v.svg'
import O2 from '../../../../assets/images/patientmonitordashboard/Nasalpronge.svg'
import InfusionPumpNImage from '../../../../assets/images/patientmonitordashboard/InfusionPumpN.svg'
import InfusionPumpFImage from '../../../../assets/images/patientmonitordashboard/InfusionPumpF.svg'
import DVTPumpList from '../../../../assets/images/patientmonitordashboard/dvtpumpList.svg'
import InfusionPumpDeatil from '../../../../assets/images/patientmonitordashboard/InfusionPumpsDeatils.svg'
import InfusionPumpDeatils from '../../../Components/InfusionPumpDeatils'
import TimeCalculate from '../../../Code/TimeCalculate'
import { useState } from 'react'
import { onStart } from '../../../Code/DragDropData'
import GernalInfusion from '../../../../assets/images/patientmonitordashboard/infusiongernal.svg'

export default function PatientLifeSupport(props) {
  let [lifeSupportValue, setLifeSupportValue] = useState("")

  let handleLifeSuppoert = (val, id) => {
    if (val.VmId === 179) {
      setLifeSupportValue(val.VmValue + "%" + "(p-" + id + ")")
    }
    else {
      setLifeSupportValue(id + "%" + "(p-" + val.VmValue + ")")
    }
  }

  useEffect(() => {
    setLifeSupportValue("")
    let id179 = ""
    let id98 = ""
    props.patientData.LifeSupportList && props.patientData.LifeSupportList.map((val, index) => {
      if (val.VmId === 179) {
        id179 = val.VmValue;
        handleLifeSuppoert(val, id98)

      } else if (val.VmId === 98) {
        id98 = val.VmValue
        handleLifeSuppoert(val, id179)
      }
    });
  }, [props])
  return (

    <div id="LifeSupport" draggable="true" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} onDragStart={onStart}>
      {props.patientData.LifeSupportList != null ?
        <>
          <>
            <span className='borderLineColor'> <img src={Fiosbg} style={{ width: "40px" }} title="V-FIO2" />  {lifeSupportValue && lifeSupportValue}     </span>
          </>
          <br />

        </>
        :
        <>
          {props.patientData.OxygenSupporList && props.patientData.OxygenSupporList.map((data, ind) => {
            return (
              <div className='d-flex flex-wrap flex-inline gap-2'>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200.52 242" transform="scale(-1, 1)" style={{ width: "12px", fill: "white" }}>
                  <title>O2</title>
                  <path className="cls-1" d="M110.89,210.15L121.37,247H230.9s-20.62-28.4-20.62-53.75c0,0-5.07-16.9,23.33-49.36,0,0,14.2-16.23,17.92-39.21l-50.71-9.47s-20.28-8.11-33.13,10.14c0,0-11.49,11.83-23.66,13.52L68.64,144.57s-13.18,5.75-2.37,15.21c0,0-4.39,8.34,1,10.43,0,0,3,3.77-2.37,18.31,0,0-7.44,23,36.51,17.24C101.43,205.76,108.66,202.29,110.89,210.15Z" transform="translate(-51.2 -5)" />
                  <path className="cls-1" d="M164.64,5.29L176.14,58s4.73,32.45-23.83,49.36l-92.12,31.1s-15.55-1.69-5.75-14.87c0,0,7.1-6.08,20.62-33.13,0,0,11.16-41.92,17.24-50C92.3,40.45,115.29,1.24,164.64,5.29Z" transform="translate(-51.2 -5)" />
                  <path className="cls-1" d="M172.42,5.29s83.84,11.16,79.1,90.26l-48.68-9.13s-18.59,1.69-18.59-25.35Z" transform="translate(-51.2 -5)" />
                </svg>
                {/* <img src={O2}  title="O2" /> */}
                <span className='borderLineColor'>{data.SupportTypeName} <span className='fw-semibold text-white'>{TimeCalculate(data.SupportDateTime)}</span></span>
              </div>
            )
          })}
        </>
      }
    </div>
  )
}


function PatientVentiMode(props) {
  if (props.patientData.LifeSupportList !== null) {
    return (<>
      {
        props.patientData.LifeSupportList !== null ?
          <span className="text-white " draggable="true" id="LifeSupport" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} onDragStart={onStart}>
            {props.patientData.LifeSupportList !== null
              ? props.patientData.LifeSupportList[0].VentiModeName !== "" ? <span title='Ventilator Mode' >{"VM: " + props.patientData.LifeSupportList[0].VentiModeName}</span> : props.patientData.LifeSupportList.filter(val => val.VmId === 185).length !== 0 ? <span title='Ventilator Mode' >{"VM: " + props.patientData.LifeSupportList.filter(val => val.VmId === 185)[0].VentiModeName}</span> : ""
              : ""}
          </span>
          :
          ""
      }

    </>)
  } else {
    return ""
  }

}

function PatientInfusionPump(props) {

  let [bpMap, setBpMap] = useState()
  let [anonymousFluid, setAnonymousFluid] = useState([])
  let [modalShow, setModalShow] = useState(0)
  let getbpMap = () => {
    let bpSys = 0;
    let bpDis = 0;
    props.patientData.VitalParametersList && props.patientData.VitalParametersList.map((data) => {

      if (data.VitalID.toString() === "4") {
        bpSys = data.VitalValue
        if (bpDis != 0) {
          setBpMap(Math.round((data.VitalValue + (2 * bpDis)) / 3, 2));
        }
      }
      else if (data.VitalID.toString() === "6") {
        bpDis = bpSys = data.VitalValue
        if (bpSys != 0) {
          setBpMap(Math.round((bpSys + (2 * data.VitalValue)) / 3, 2));
        }
      }
    })
    let temp = []

    props.patientData.InfusionPumpDataList !== null && props.patientData.InfusionPumpDataList.map((data, ind) => {

      // if (data.FluidDateTime !== "" && data.FluidName.trim().toLowerCase() !== "potassium chloride".trim().toLowerCase() && data.FluidName.trim().toLowerCase() !== "NORAD".toLocaleLowerCase() && data.FluidName.trim().toLowerCase() !== "FENTANYL".toLocaleLowerCase()) {
      if (data.FluidDateTime !== "") {
        if (TimeCalculate(data.FluidDateTime).split("-")[1] === "H" || TimeCalculate(data.FluidDateTime).split("-")[1] === "M") {
          temp.push(data)
        }
      }

      // }

    })
    setAnonymousFluid(temp)

  }

  useEffect(() => {
    getbpMap()
  }, [props])

  return (
    <div className='d-flex flex-row gap-1' key={props.key}>
      {props.patientData.InfusionPumpDataList != null ? props.patientData.InfusionPumpDataList.map((data, ind) => {
        if (data.FluidName.trim().toLowerCase() === "potassium chloride".trim().toLowerCase() && data.FluidDateTime !== "") {
          if (TimeCalculate(data.FluidDateTime).split("-")[1] === "H" || TimeCalculate(data.FluidDateTime).split("-")[1] === "M") {
            return (<img src={InfusionPumpNImage} title={data.FluidName} />)
          }
        }
        else if (data.FluidName.trim().toLowerCase() === "NORAD".toLocaleLowerCase() && data.FluidDateTime !== "") {
          if (TimeCalculate(data.FluidDateTime).split("-")[1] === "H" || TimeCalculate(data.FluidDateTime).split("-")[1] === "M") {
            return (<><img src={InfusionPumpNImage} title={data.FluidName} />{bpMap >= 65 ? <i title='Decrease the Norad dose' className="bi bi-arrow-down-circle-fill blink" style={{ color: "red", fontSize: "17px" }}></i> : ""}</>)
          }
        }
        else if (data.FluidName.trim().toLowerCase() === "FENTANYL".toLocaleLowerCase() && data.FluidDateTime !== "") {
          if (TimeCalculate(data.FluidDateTime).split("-")[1] === "H" || TimeCalculate(data.FluidDateTime).split("-")[1] === "M") {
            return (<img src={InfusionPumpFImage} title={data.FluidName} />)
          }
        }
        // else {
        //   return (<img src={GernalInfusion} title={`
        //     Fluid Name ${data.FluidName}\nFLow ${data.Value}\nUnit ${data.Unit}\nDate Time ${data.FluidDateTime}
        //   `} />)

        // }
        // else if (data.FluidName.trim().toLowerCase() === "INSULIN".toLocaleLowerCase()) {
        //   // return (<img src={InfusionPumpNImage} title={data.FluidName} />)
        // }

      }) : ""}
      <div >

        {
          anonymousFluid.length !== 0 ?
            <img src={GernalInfusion} title={`Infusion`} alt='fluid' onClick={() => { setModalShow(1) }} />
            : ""
        }

        <div className={`modal d-${modalShow === 0 ? "none" : "block"}`}>
          <div className="modal-dialog modal-dialog-centered_ modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5 text-white" id="exampleModalLabel">Infusion Details</h1>
                {/* <button type="button" className="btn-close" onClick={() => { setModalShow(0) }}></button> */}
                <span className="closee" title='Close Window' onClick={() => { setModalShow(0) }}><i className='fa fa-times'></i></span>
              </div>
              <div className='med-table-section pdtable p-2' style={{ height: "350px", position: 'relative' }}>
                <table>
                  <thead>
                    <tr>
                      <th>Fluid Name</th>
                      <th>Flow</th>
                      <th>Unit</th>
                      <th>Date Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {anonymousFluid && anonymousFluid.map((data, ind) => {
                      return (
                        <tr>
                          <td>{data.FluidName}</td>
                          <td>{data.Value}</td>
                          <td>{data.Unit}</td>
                          <td>{data.FluidDateTime}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>
  )
}

function PatientDVTPump(props) {
  return (
    <>
      {props.patientData.DVTPumpList && props.patientData.DVTPumpList.map((val) => {
        return (
          <span key={props.key} id="DVTPumpList" draggable="true" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} onDragStart={onStart} >
            <img src={DVTPumpList} alt="" className='ms-1' width="10px" title={"DVT Pump Machine Start Time : " + val.DVTPumpDateTime} />
          </span>
        )
      })}
    </>
  )
}

function PatientInfusionPumpDataList(props) {
  let [infusionPumpDeatilspopup, setInfusionPumpDeatilsPopup] = useState(0)
  let modelCloseFun = () => {
    setInfusionPumpDeatilsPopup(0)
  }
  return (

    <>
      {
        props.patientData.InfusionPumpDataList !== null ?
          <span key={props.key} id="LifeSupport" draggable="true" data-value={JSON.stringify({ "columnId": props.columnId, "parameterId": props.parameterId, "paramenterData": props.paramenterData })} onDragStart={onStart}>


            <img src={InfusionPumpDeatil} title="Infusion Pump Deatils" className='ps-1' width="24px" onClick={() => { setInfusionPumpDeatilsPopup(1) }} />


            {
              infusionPumpDeatilspopup ? <InfusionPumpDeatils infusionPumpDeatilspopup={infusionPumpDeatilspopup} modelCloseFun={modelCloseFun} patientdata={props.patientData} /> : ""
            }
          </span> :
          <></>
      }
    </>

  )
}
export { PatientVentiMode, PatientInfusionPump, PatientDVTPump, PatientInfusionPumpDataList }
