 

async function GetGodownMaster(ClientID) {
    // let token = 'bearer ' + window.AppToken;
    let url = window.InventoryBaseUrl + "/api/GodownMaster/GetGodownMaster?ClientID=" + ClientID;
    let head = { "Content-Type": "application/JSON", accept: '*/*'
    // , 'Authorization': token
 };
  
    let response = await fetch(url, {
      headers: head,
      method: 'GET'
    })
      .then((res) => res.json())
      .then();
  
    return response;
  }
  export default GetGodownMaster;
  
  