import React from 'react'
import printuse from '../../../src/assets/css/printuse.css'
// import SarvodayaLogo from '../../../assets/images/icons/sarvodayalogo.png'

export default function ConsentFormForRestraint() {
    return (
        <>
            <div className="main-table" style={{ padding: '5px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <table className='restraint pt-4 mt-4'>
                    {/* ....header.. */}
                    <tr>
                        <td colSpan={6}>
                            <table>
                                <tr>
                                    <td>
                                        <div className="sarvodya-header">
                                            <div className='lft-header '>
                                                <img alt='' className='sv-log' />
                                            </div>
                                            <div className='rght-header'>
                                                <div> SARVODAYA HOSPITAL <div className='addrs'> SECTOR-8, FARIDABAD | HELPLINE: 1800 313 1414</div></div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td>
                            <table className='detilsconsnt mt-2'>
                                <tr>
                                    <td colSpan={3} style={{ verticalAlign: 'top' }}>
                                        <div className='micro-heading' >
                                            CONSENT FORM FOR RESTRAINT
                                        </div>
                                    </td>
                                    <td colSpan={3}>
                                        <table className="tb-rght mt-2">
                                            <tr>
                                                <th colSpan={6}>Patient Name: <span style={{ width: '200px', borderBottom: '1px dashed black', display: 'inline-block' }}></span> </th>

                                            </tr>
                                            <tr>
                                                <th colSpan={6}>MRD No. <span style={{ width: '220px', borderBottom: '1px dashed black', display: 'inline-block' }}></span> </th>
                                            </tr>
                                            <tr>
                                                <th colSpan={3}>Age: <span style={{ width: '70px', borderBottom: '1px dashed black', display: 'inline-block' }}></span> </th>
                                                <th colSpan={3}>Sex: <span style={{ width: '80px', borderBottom: '1px dashed black', display: 'inline-block' }}></span> </th>
                                            </tr>
                                            <tr>
                                                <th colSpan={3}>DOA: <span style={{ width: '70px', borderBottom: '1px dashed black', display: 'inline-block' }}></span> </th>
                                                <th colSpan={3}>Unit: <span style={{ width: '75px', borderBottom: '1px dashed black', display: 'inline-block' }}></span> </th>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={6}>
                            <table className="Restrainttbl mt-3 mb-3" style={{ border: 'none' }}>
                                <tr>
                                    <td colSpan={6}>
                                        <div className='Restraintdetails mt-3 '>
                                            <div>Date & Time of Restraint <span style={{ width: '200px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div>
                                            <div>Consultant: <span style={{ width: '200px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div>
                                        </div>
                                        <div className='mt-3'>Reason for Restraint: <span style={{ width: '575px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div>
                                        <div className='mt-3'>Duration of Restraint: <span style={{ width: '575px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div>

                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div><strong>Statement of Consent & Acknowledgment
                                Consultant</strong></div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='pt-3'><strong>
                                I Doll Do not-consent to the use of restraint for the benefit of my patient.
                            </strong></div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='pt-3'><strong>
                                I have been fully informed of the potential benefits and risks of restraint use and hereby undertake full liability for any adverse outcomes related to my decision. I have had the opportunity to fully discuss restraint use with my Doctor and other personnel responsible for my medical care. All of my questions have been answered to my full satisfaction.                            </strong></div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='pt-3'>
                            <strong>
                            I understand that I have the right to alter my decisions concerning restraints at any time and that any change must be indicated in writing.
                          </strong></div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={6}>
                            <table className="Restrainttbl mt-4 mb-3" style={{ border: 'none' }}>
                                <tr>
                                    <td colSpan={6}>            
                                    <div className='Restraintdetails mt-3 '>
                                            <div>Name & Signature :</div>
                                            <div> <span style={{ width: '545px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div>
                                        </div>
                                        <div className='Restraintdetails mt-3 '>
                                            <div>Date :</div>
                                            <div> <span style={{ width: '545px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div>
                                        </div>
                                        <div className='Restraintdetails mt-3 '>
                                            <div>Time :</div>
                                            <div> <span style={{ width: '545px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div>
                                        </div>
                                        <div className='Restraintdetails mt-3'>
                                            <div>Name & Signature of Attendant : </div>
                                            <div> <span style={{ width: '545px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div>
                                        </div>
                                        <div className='Restraintdetails mt-3 '>
                                            <div>Relation with Patient :</div>
                                            <div> <span style={{ width: '545px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div>
                                        </div>
                                        <div className='Restraintdetails mt-3 '>
                                            <div>Age of Attendant :</div>
                                            <div> <span style={{ width: '545px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div>
                                        </div>
                                        <div className='Restraintdetails mt-3 '>
                                            <div>Date & Time :</div>
                                            <div> <span style={{ width: '545px', borderBottom: '1px dashed black', display: 'inline-block' }}></span></div>
                                        </div>
                                      
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                </table>
            </div>
        </>
    )
}
