import React, { useEffect, useHistory, useState } from 'react'
import printuse from '../../../assets/css/printuse.css'
import { useLocation } from 'react-router-dom';
// import sarvodayalogo from '../../../assets/images/icons/sarvodayalogo.png'
import GetAllStatusByTypeModule from '../API/GetAllStatusByTypeModule';
import GetPatientRestraintById from '../API/GetPatientRestraintById';
import Vector from "../../../assets/images/icons/Vector.svg";
import Contact from "../../../assets/images/icons/Contact.svg";
import PrintPageHeader from '../../../Component/PrintPageHeader';
import Barcode from 'react-barcode';

export default function ReceiptRestraintMonitoringForm() {
    let [patientRestList, setPatientRestList] = useState()
    let [patientrestraintmonitoringList, setPatientrestraintmonitoringList] = useState()
    let [diagnosisList, setDiagnosisList] = useState()
    let [restraintType, setRestraintType] = useState();
    let [vitalsList, setVitalsList] = useState();
    let [outputList, setOutputList] = useState();
    let [inTake, setInTake] = useState();
    const uhID = JSON.parse(window.sessionStorage.getItem('IPDactivePatient')).Uhid;
    const clientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId;
    let [clientData, setClientData] = useState(JSON.parse(window.sessionStorage.getItem("LoginData")).clientdata)
    const [restraintByIdList, setRestraintByIdList] = useState([]);


    const [typeDeviceList, setTypeDeviceList] = useState();
    const [typeLocationList, setTypeLocationList] = useState();
    const [typePositionsList, setTypePositionsList] = useState();

    // console.log("patientRestList", patientRestList)
    // console.log("PatientrestraintmonitoringList", patientrestraintmonitoringList)
    // console.log("diagnosisList", diagnosisList)
    // console.log("vitalsList", vitalsList)
    // console.log("outputList", outputList)

    //Get Restraint List By Id
    let getPatientRestraintById = async () => {
        let rowId = JSON.parse(window.sessionStorage.getItem("restraintList")).id
        let response = await GetPatientRestraintById(clientID, uhID, rowId);
        if (response.status === 1) {
            const restraintListByIdArray = response.responseValue;

            if (restraintListByIdArray && restraintListByIdArray.length > 0) {
                const restraintListById = restraintListByIdArray[0];

                setRestraintByIdList(restraintListById);
                console.log("restraintListById", restraintListById);

                const patientrestraintmonitoringList = restraintListById.patientrestraintMonitoring
                    ? JSON.parse(restraintListById.patientrestraintMonitoring)
                    : [];
                setPatientrestraintmonitoringList(patientrestraintmonitoringList);

                const diagnosisList = restraintListById.diagnosis
                    ? JSON.parse(restraintListById.diagnosis)
                    : [];
                setDiagnosisList(diagnosisList);

                const vitalsList = restraintListById.vitals
                    ? JSON.parse(restraintListById.vitals)
                    : [];
                setVitalsList(vitalsList);

                const outputList = restraintListById.outputs
                    ? JSON.parse(restraintListById.outputs)
                    : [];
                setOutputList(outputList);

                const inTakeList = restraintListById.inputs
                    ? JSON.parse(restraintListById.inputs)
                    : [];
                setInTake(inTakeList);

            }
        }
    };




    //Get Types of Device Used
    const restraintTypes = [
        "Type of Device Used",
        "Restraints Location",
        "Positions",
    ];

    const getRestraintsTypeData = async (type) => {
        let response = await GetAllStatusByTypeModule(type)
        if (response.status === 1) {
            switch (type) {
                case "Type of Device Used":
                    setTypeDeviceList(response.responseValue);
                    break;
                case "Restraints Location":
                    setTypeLocationList(response.responseValue);
                    break;
                case "Positions":
                    setTypePositionsList(response.responseValue);
                    break;

                default:
            }
        }
    }


    let handlepritnt = () => {
        document.title = 'Restraint Monitoring Form';
        window.print("");
        window.close();

    }
    const formatDateTime = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString('en-IN', options);
    };
    const formatTimefromdate = (timeString) => {
        const options = { hour: 'numeric', minute: 'numeric' };
        return new Date(timeString).toLocaleTimeString('en-US', options);
    };
    const formatDate = (dateTimeString) => {
        if (!dateTimeString) return '';
        const parts = dateTimeString.split(/[- :]/);
        const formattedDate = new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4], parts[5]);
        const formattedDateString = formattedDate.toLocaleString('en-US', { timeZone: 'UTC' });
        return formattedDateString;
    };

    // For Vitals
    const groupedVitals = (vitalsList ?? []).reduce((acc, val) => {
        const existingEntry = acc.find(entry => entry.vitalDateTime === val.vitalDateTime);

        if (existingEntry) {
            existingEntry[val.vitalName] = val.vmValue;
        } else {
            const newEntry = { vitalDateTime: val.vitalDateTime, [val.vitalName]: val.vmValue };
            acc.push(newEntry);
        }

        return acc;
    }, []);


    useEffect(() => {
        setTimeout(() => {
            handlepritnt();
        }, 1200)
        let id = JSON.parse(window.sessionStorage.getItem("restraintList")).id
        getPatientRestraintById(id);
        // getRestraintFormData();
        restraintTypes.forEach((restraintType) => {
            getRestraintsTypeData(restraintType);
        });
    }, []);
    return (
        <>
            <div className="main-table" style={{ padding: '5px', width: '100%', maxWidth: '1200px', margin: 'auto' }}>
                {/* Content goes here */}

                <table className='microtble'>
                    {/* ....header.. */}
                    <tr>
                        <td colSpan={6}>
                            <table>
                                <tr>
                                    <td colSpan={8}>
                                        <PrintPageHeader />
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    {/* ....header.. end*/}
                    <tr>
                        <td colSpan={6} style={{ verticalAlign: 'top', textAlign: 'center' }}>
                            <div className='micro-heading mt-3 mb-3'>
                                RESTRAINT MONITORING FORM
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={6}>
                            <table className='formdetails'>
                                <tr>
                                    <td colSpan={4} style={{ textAlign: 'right' }}>
                                        <Barcode value={uhID} width={1} height={15} displayValue={false} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className='pt-2 pb-2 mt-2 mb-2'> <strong> Patient Full Name:</strong>  <span style={{ width: '500px', borderBottom: '1px dashed black', display: 'inline-block' }}> {restraintByIdList && restraintByIdList.patientName ? restraintByIdList.patientName : ''}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4}> <strong>MRN NO:</strong> <span style={{ width: '100px', borderBottom: '1px dashed black', display: 'inline-block' }}>{restraintByIdList && restraintByIdList.uhID ? restraintByIdList.uhID : ''}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='pt-2 pb-2 mt-2 mb-2'> <strong>Age : </strong> <span style={{ width: '120px', borderBottom: '1px dashed black', display: 'inline-block' }}>{restraintByIdList && restraintByIdList.age ? restraintByIdList.age : ''}</span>
                                    </td>
                                    <td className='pt-2 pb-2 mt-2 mb-2'>  <strong>Gender:</strong>  <span style={{ width: '110px', borderBottom: '1px dashed black', display: 'inline-block' }}>{restraintByIdList && restraintByIdList.gender ? restraintByIdList.gender : ''}</span>
                                    </td>
                                    <td className='pt-2 pb-2 mt-2 mb-2'> <strong> DOA: </strong>
                                        <span style={{ width: '110px', borderBottom: '1px dashed black', display: 'inline-block', whiteSpace: 'nowrap' }}>
                                            {restraintByIdList && restraintByIdList.admitDateTime ? restraintByIdList.admitDateTime.split(' ')[0] : ''}
                                            {/* {restraintByIdList && restraintByIdList.admitDateTime ? restraintByIdList.admitDateTime : ''} */}
                                        </span>
                                    </td>
                                    <td className='pt-2 pb-2 mt-2 mb-2'>  <strong>Ward/Bed:</strong>  <span style={{ width: '110px', borderBottom: '1px dashed black', display: 'inline-block' }}>{restraintByIdList && restraintByIdList.wardName ? restraintByIdList.wardName : ''}/{restraintByIdList && restraintByIdList.bedName ? restraintByIdList.bedName : ''}</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='pt-0' colSpan={6}>
                            <table className="tblbdr ">
                                <tr>
                                    <td colSpan={2} style={{ border: '1px solid', width: '40%', verticalAlign: 'top' }}><strong>Primary Consultant:</strong> </td>
                                    <td colSpan={4} style={{ border: '1px solid', width: '60%', verticalAlign: 'top' }}><strong>Diagnosis: &nbsp;</strong>
                                        {diagnosisList && diagnosisList.map((val, ind) => {
                                            return (
                                                <span>{val.details}
                                                    {ind !== diagnosisList.length - 1 && <strong>,&nbsp;</strong>}
                                                </span>
                                            )
                                        }
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ border: '1px solid' }}><strong>Date & Time Restrained:</strong>  {restraintByIdList && restraintByIdList.restraintStartTime ? formatDateTime(restraintByIdList.restraintStartTime) : ''}</td>
                                    <td colSpan={4} style={{ border: '1px solid' }}><strong>Date & Time Restrain Discontinued:</strong> {restraintByIdList && restraintByIdList.restraintStopTime ? formatDateTime(restraintByIdList.restraintStopTime) : ''} </td>
                                </tr>
                                <tr>
                                    <td colSpan={6} style={{ border: '1px solid' }}><strong>Reason for Restraint: </strong> {restraintByIdList && restraintByIdList.restraintReason ? restraintByIdList.restraintReason : ''}</td>

                                </tr>
                                <tr>
                                    <td colSpan={6} className='mt-0 pt-0 mb-0 pb-0' style={{ border: '1px solid' }}>
                                        <div className="Restrainform mt-0 mb-0 pb-0 pt-0">
                                            <div className='mt-0 mb-0 pt-0 pb-0'><strong>Types of Restraint:</strong></div>
                                            <div>
                                                <label>
                                                    <input type="checkbox" id="1" checked={restraintByIdList && restraintByIdList.restraintTypeId === 1} readOnly />
                                                    Physical
                                                </label></div>
                                            <div>
                                                <label>
                                                    <input type="checkbox" id="2" checked={restraintByIdList && restraintByIdList.restraintTypeId === 2} readOnly />
                                                    Chemical
                                                </label>
                                            </div>

                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    <td className='mt-0 pt-0' colSpan={6} style={{ border: '1px solid' }}>
                                        <div className='mt-0 mb-0 pb-0'><strong>Type of Device Used  :</strong></div>
                                        <div className="Restrainform mt-0 mb-0 pb-0">
                                            {typeDeviceList && typeDeviceList.map((val, ind) => (
                                                <div key={ind}>
                                                    <label>
                                                        <input type="checkbox" id={val.id} checked={restraintByIdList && restraintByIdList.deviceId === val.id} readOnly />{val.remark}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='mt-0 pt-0' colSpan={6} style={{ border: '1px solid' }}>
                                        <div className='mt-0 mb-0 pb-0'><strong>Location of Physical Restraints:</strong></div>
                                        <div className="Restrainform mt-0 mb-0 pb-0">
                                            {typeLocationList && typeLocationList.map((val, ind) => (
                                                <div key={ind}>
                                                    <label><input type="checkbox" id={val.id} checked={restraintByIdList && restraintByIdList.bodyLocationId === val.id} readOnly />{val.remark}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='mt-0 pt-0 pb-0 mb-0' colSpan={6} style={{ border: '1px solid' }}>
                                        <div className="Restrainform">
                                            <div className='mt-0 pt-0 pb-0 mb-0'><strong>Positions :</strong></div>
                                            {typePositionsList && typePositionsList.map((val, ind) => (
                                                <div key={ind}>
                                                    <label><input type="checkbox" id={val.id} checked={restraintByIdList && restraintByIdList.positionId === val.id} readOnly />{val.remark}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='mt-0 pt-0' colSpan={6} style={{ border: '1px solid', borderBottom: '0px' }}>
                                        <div>
                                            <div className='hourlydetils mt-0 pt-0'>
                                                <strong> Hourly</strong>
                                            </div>
                                            <div className='hourlylist'>
                                                <ul>
                                                    <li>Assess the general condition/behavior of the patient (Calm, agitated, confused, restless)</li>
                                                    <li>Assess Patient general comfort</li>
                                                    <li>Assess circulation and sensation and range of movement of restrained limb</li>
                                                    <li>Assess the need for activities of daily living (hunger, thirst, personal hygiene, toileting)</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='hourlydetils'>
                                                <strong>2 Hourly</strong>
                                            </div>
                                            <div className='hourlylist'>
                                                <ul>
                                                    <li>Inspect skin for redness/injury/breakdown by removing the restraints</li>
                                                    <li>Change the patient's position</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='pt-0 mt-1 pb-0 mb-0 ps-0 pe-0' colSpan={6}>
                                        <table className="ptentsrest-tbl restraint ">
                                            <tr>
                                                <th >Patient Behavior <br />- PB </th>
                                                <th >Nursing Care <br />-NC  </th>
                                                <th>Limb Circulation </th>
                                                <th >Skin Sensation <br />-SS</th>
                                                <th> Range of Movement</th>
                                                <th> Skin Integrity 2hrty <br />-SI</th>
                                                <th> Position of the Patients <br />2 hourly</th>
                                            </tr>
                                            <tr style={{ verticalAlign: 'top' }}>
                                                <td className='mt-0 pt-0'>
                                                    <div className='btn-behvr-detls'>
                                                        <div> C = Calm</div>
                                                        <div>A = Agitated</div>
                                                        <div>CF = Confused</div>
                                                        <div>CB = Combative </div>
                                                        <div>R = Restless</div>
                                                    </div>
                                                </td>
                                                <td className='mt-0 pt-0'>
                                                    <div className='ptn-behvr-detls'>
                                                        <div> F = Food</div>
                                                        <div>Fl = Fluid</div>
                                                        <div>H = Hygiene</div>
                                                        <div>CB = Combative </div>
                                                        <div>T = Toileting</div>
                                                    </div>
                                                </td>
                                                <td className='mt-0 pt-0'>
                                                    <div className='ptn-behvr-detls'>
                                                        <div> N = Normal</div>
                                                        <div>P = Pae</div>
                                                        <div>C = Cyanotic</div>
                                                    </div>
                                                </td>
                                                <td className='mt-0 pt-0'>
                                                    <div className='ptn-behvr-detls'>
                                                        <div>G = Good</div>
                                                        <div>D = Diminished</div>
                                                        <div>T = Tingling</div>
                                                        <div>N = Numb</div>
                                                    </div>
                                                </td>
                                                <td className='mt-0 pt-0'>
                                                    <div className='ptn-behvr-detls'>
                                                        <div> = All</div>
                                                        <div>restrained</div>
                                                        <div>limbs</div>
                                                        <div>exercised for <br /> range of <br /> movement</div>
                                                    </div>
                                                </td>
                                                <td className='mt-0 pt-0'>
                                                    <div className='ptn-behvr-detls'>
                                                        <div> I = Intact</div>
                                                        <div>R = Redness</div>
                                                        <div>P = Pale</div>
                                                        <div>B = Broken</div>
                                                    </div>
                                                </td>
                                                <td className='mt-0 pt-0'>
                                                    <div className='ptn-behvr-detls'>
                                                        <div>R = Right</div>
                                                        <div>L = Left</div>
                                                        <div>B = Black</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={12} className='pe-0 ps-0 mt-0 mb-0 pt-0 pb-0' style={{ border: '0' }}>
                                                    <table className='ptents-tbl mt-0 pt-0 mb-0 pb-0'>
                                                        <tr>
                                                            {/* <th style={{ borderTop: '0' }}>Date/Time</th> */}
                                                            <th style={{ borderTop: '0' }}>Date</th>
                                                            <th style={{ borderTop: '0' }}>Time</th>
                                                            <th style={{ borderTop: '0' }}>PB</th>
                                                            <th style={{ borderTop: '0' }}>NC</th>
                                                            <th style={{ borderTop: '0' }}>LC</th>
                                                            <th style={{ borderTop: '0' }}>SS</th>
                                                            <th style={{ borderTop: '0' }}>ROM</th>
                                                            <th style={{ borderTop: '0' }}>SI</th>
                                                            <th style={{ borderTop: '0' }}>Position</th>
                                                            <th style={{ borderTop: '0' }}>Comment/Action</th>
                                                            <th style={{ borderTop: '0' }} >Staff Name & Emp ID</th>
                                                        </tr>
                                                        {patientrestraintmonitoringList && patientrestraintmonitoringList.map((val, ind) => {
                                                            return (
                                                                <tr>
                                                                    {/* <td>{val.MonitoringTime}</td> */}
                                                                    <td>{val.MonitoringTime ? new Date(val.MonitoringTime).toLocaleDateString('en-GB') : ''}</td>
                                                                    <td style={{ whiteSpace: 'nowrap' }}>{formatTimefromdate(val.MonitoringTime)}</td>
                                                                    <td>{val.PatientBehaviorName}</td>
                                                                    <td>{val.NursingCareName}</td>
                                                                    <td>{val.LimbCirculationName}</td>
                                                                    <td>{val.SkinSensationName}</td>
                                                                    <td>{val.MovementRangeName}</td>
                                                                    <td>{val.SkinIntegrityName}</td>
                                                                    <td>{val.PatientPositionName}</td>
                                                                    <td>{val.Remark}</td>
                                                                    <td>{val.MonitoredByName}</td>
                                                                </tr>
                                                            )
                                                        })

                                                        }
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                            <table className='mt-4 pt-4'>
                                <tr >
                                    <th className='mt-0 mb-0 pt-0 pb-0' style={{ textTransform: 'uppercase', fontSize: '13px' }}>For Chemical Restrained</th>
                                </tr>
                                <tr>
                                    <td colSpan={12} className='pe-0 ps-0 mt-1 mb-0 pt-1 pb-0'>
                                        <table className="ptents-tbl tbldata2">
                                            <thead>
                                                <tr>
                                                    {/* <th rowSpan={2}>Date/Time</th> */}
                                                    <th rowSpan={2}>Date</th>
                                                    <th rowSpan={2}>Time</th>
                                                    <th colSpan={3}>Vital Checked in every 2 hours</th>
                                                    <th colSpan={2}>Intake <br /> (NPO/RT/Oral)</th>
                                                    <th colSpan={2}>Out Put (Cath/Self <br /> void)</th>
                                                    <th rowSpan={2}>Sedation <br /> Score</th>
                                                    <th rowSpan={2}>Staff Name <br />  & Emp ID</th>
                                                </tr>
                                                <tr>
                                                    <th>Blood Pressure</th>
                                                    <th>SpO2</th>
                                                    <th>temp</th>
                                                    <th>Food</th>
                                                    <th>Fluid</th>
                                                    <th>Urine</th>
                                                    <th>Stool</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* {vitalsList && vitalsList.map((val, index) => (
                                                    <tr key={index}> */}
                                                {/* <td>{formatDate(val.vitalDateTime)}</td>
                                                        <td>{formatTimefromdate(val.vitalDateTime)}</td>
                                                        <td>
                                                            {val.vitalName === 'BP_Sys' ? `${val.vmValue} / ${vitalsList.find(
                                                                (item) =>
                                                                    item.vitalName === 'BP_Dias' && item.vitalDateTime === val.vitalDateTime
                                                            ).vmValue}`
                                                                : ''}
                                                        </td>

                                                        
                                                        <td>
                                                            {val.vitalName === 'spo2' ? val.vmValue : ''}
                                                        </td>
                                                        <td>{val.vitalName === 'Temperature' ? val.vmValue : ''}</td> */}
                                                {groupedVitals.map((entry, index) => (
                                                    <tr key={index}>
                                                        {/* <td>{formatDateTime(entry.vitalDateTime)}</td> */}
                                                        {/* <td>{entry.vitalDateTime}</td> */}
                                                        <td>{entry.vitalDateTime.split(' ')[0].split('-').reverse().join('-')}</td>
                                                        <td style={{ whiteSpace: 'nowrap' }}>{formatTimefromdate(entry.vitalDateTime)}</td>
                                                        {/* <td>{`${entry.BP_Sys || ''} / ${entry.BP_Dias || ''}`}</td> */}
                                                        <td>{`${entry.BP_Sys ? entry.BP_Sys : ''}${entry.BP_Sys && entry.BP_Dias ? ' / ' : ''}${entry.BP_Dias ? entry.BP_Dias : ''}`}</td>
                                                        <td>{entry.spo2 || ''}</td>
                                                        <td>{entry.Temperature || ''}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                ))}
                                                {outputList && outputList.map((output, index) => (
                                                    <tr key={index}>
                                                        {/* <td>{formatDateTime(output.outputDateTime)}</td> */}
                                                        {/* <td>{output.outputDateTime}</td> */}
                                                        <td>{output.outputDateTime.split(' ')[0].split('-').reverse().join('-')}</td>
                                                        <td>{formatTimefromdate(output.outputDateTime)}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{output.outputType === 'Urine' ? `${output.quantity} ${output.unitName}` : ''}</td>
                                                        <td>{output.outputType === 'Stool' ? `${output.quantity} ${output.unitName}` : ''}</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                ))}
                                                {inTake && inTake.map((val, index) => {
                                                    {/* const formattedDate = formatDateTime(val.GivenFoodDate); */ }
                                                    const formattedDate = formatDate(val.GivenFoodDate);
                                                    const formattedTime = formatTimefromdate(val.GivenFoodDate);
                                                    return (
                                                        <tr key={index}>
                                                            {/* <td>{formattedDate}</td> */}
                                                            <td>{val.GivenFoodDate.split(' ')[0].split('-').reverse().join('-')}</td>
                                                            <td>{formattedTime}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{val.Type === 'food' ? `${val.foodName} ${val.Quantity} ${val.unitName}` : ''}</td>
                                                            <td>{val.Type === 'fluid' ? `${val.foodName} ${val.Quantity} ${val.unitName}` : ''}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    );
                                                })}

                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className=' mt-3 pt-3 '>
                            <div className='docmntcont'>
                                <div className='mb-1 '>The Document is valid for 24 hrs.</div>
                                <div className='mb-1 '>Evalute the requirment of restraint after every 2 hrs. during evaluation and end it at the eralieast possible time.
                                </div>
                                <div>All The assessment to be Documented every  2 hrous </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

        </>
    )
}
