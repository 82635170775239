import { useEffect, useState } from "react";
import TimeCalculate from "../../../Code/TimeCalculate";
import LineVitalGrap from "../../../Components/LineVitalGrap";
import GetAllDashboardParameterColumnAssign from "../../../Api/GetAllDashboardParameterColumnAssign";
import { useSelector } from "react-redux";

export default function PatientPRR(props) {
  let [pr, setPr] = useState("");
  let [prId, setPrId] = useState("");
  let [prTime, setPrTime] = useState("");
  let [prColor, setPrColor] = useState("");
  let [uhId, setUhId] = useState("")


  // set grap vital data
  let [grapVitalData, setGrapVitalData] = useState({
    showGrap: 0,
    vitalIdSearchNew: '',
    patientName: '',
    UHID: '',
    userId: ''
  })

  let getData = () => {
    setPr("");
    setPrId("");
    setPrTime("");
    setPrColor("");

    props.val.VitalParametersList && props.val.VitalParametersList.map((vital, ind) => {
      if (vital.VitalID.toString() === "3") {
        setPr(Math.round(vital.VitalValue * 100) / 100)
        setPrColor(vital.VitalColor)
        if (props.handleColor !== undefined) {
          props.handleColor(vital.VitalColor !== null ? vital.VitalColor : "white", props.columnId)
      }

        setPrId(vital.VitalID)
        setUhId(props.val.UhId)
        if (vital.VitalDateTime !== '') {
          setPrTime(TimeCalculate(vital.VitalDateTime))
        }

      }
    })
  }

  let modelCloseFun = () => {
    setGrapVitalData({
      showGrap: 0,
      vitalIdSearchNew: '',
      UHID: '',
      userId: ''
    })
  }

  let [paramenterData, setPatameterData] = useState([])
  let response = useSelector((state) => state.AllColumnsParamters)
  let getColumnsParameters = () => {
    let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
       if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

  }

  useEffect(() => {
    getColumnsParameters()
    getData()
  }, [props.val])

  return (
    <>
      {/* <div className={`overflow-max vitalClass ${(prColor !== "#008000" && prColor !== "#94e094" && prColor !== "#F5F8FF" && prColor !== "" ? "blink" : "")} `} style={{ border: (prColor !== "#008000" && prColor !== "#94e094" && prColor !== "#F5F8FF" && prColor !== "" ? "2px solid " + prColor : "") }}> */}
      <div key={props.key} className={`overflow-max vitalClass`}>

        {pr.length != 0 ? pr ? <span>

          {
            paramenterData && paramenterData.map((val, ind) => {
              if (val.parameterId === 53) {
                return (
                  <>
                    <span style={{ color: prColor }}>{pr}</span><br />

                  </>

                )
              }

              else if (val.parameterId === 54) {
                return (
                  <>
                    {
                      prTime.split("-")[1] === "D" || (prTime.split("-")[0].split(":")[0] >= 2 && prTime.split("-")[1] === "H") ? <span style={{ 'color': 'red', fontSize: "0.7rem" }} className="blink"><i className="bi bi-clock"></i> {prTime.toLowerCase().replace("-", "")}</span>

                        : (prTime.split(":")[0] >= 1 && prTime.split("-")[1] === "H") ? <span style={{ "color": 'red', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {prTime.toLowerCase().replace("-", "")}</span>

                          : (prTime.split("-")[0].split(":")[0] <= 1 && prTime.split("-")[1] === "H") || (prTime.split("-")[0] >= 10 && prTime.split("-")[1] === "M") ? <span style={{ 'color': '#ffa500', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {prTime.toLowerCase().replace("-", "")}</span> : <span style={{ 'color': 'green', fontSize: "0.7rem" }}><i className="bi bi-clock"></i> {prTime.toLowerCase().replace("-", "")}</span>}
                    <br />
                  </>

                )
              }

              else if (val.parameterId === 55 && props.from === undefined) {
                return (
                  <>
                    <div className="" title='PR Graph' ><i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapVitalData({ showGrap: 1, vitalIdSearchNew: [prId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "PR Graph", patientName: props.val.PntName }) }}></i></div>

                    {/* <br /> */}
                  </>

                )
              }


            })

          }




        </span> : 'N/A' : "N/A"}
      </div>

      {grapVitalData.showGrap ? <LineVitalGrap grapVitalData={grapVitalData} modelCloseFun={modelCloseFun} /> : ""}

    </>
  )
}



export function PatientPRRG(props) {
  let [pr, setPr] = useState("");
  let [prId, setPrId] = useState("");
  let [prTime, setPrTime] = useState("");
  let [prColor, setPrColor] = useState("");
  let [uhId, setUhId] = useState("")


  // set grap vital data
  let [grapVitalData, setGrapVitalData] = useState({
    showGrap: 0,
    vitalIdSearchNew: '',
    patientName: '',
    UHID: '',
    userId: ''
  })

  let getData = () => {
    setPr("");
    setPrId("");
    setPrTime("");
    setPrColor("");

    props.val.VitalParametersList && props.val.VitalParametersList.map((vital, ind) => {
      if (vital.VitalID.toString() === "3") {
        setPr(Math.round(vital.VitalValue * 100) / 100)
        setPrColor(vital.VitalColor)


        setPrId(vital.VitalID)
        setUhId(props.val.UhId)
        if (vital.VitalDateTime !== '') {
          setPrTime(TimeCalculate(vital.VitalDateTime))
        }

      }
    })
  }

  let modelCloseFun = () => {
    setGrapVitalData({
      showGrap: 0,
      vitalIdSearchNew: '',
      UHID: '',
      userId: ''
    })
  }

  let [paramenterData, setPatameterData] = useState([])
  let response = useSelector((state) => state.AllColumnsParamters)
  let getColumnsParameters = () => {
    let resp = response.alertData.filter((val, ind) => val.columnId === props.columnId)
       if(resp.length !== 0)
        {
            setPatameterData(resp[0].data)
        }

  }

  useEffect(() => {
    getColumnsParameters()
    getData()
  }, [props.val])

  return (
    <>
      {/* <div className={`overflow-max vitalClass ${(prColor !== "#008000" && prColor !== "#94e094" && prColor !== "#F5F8FF" && prColor !== "" ? "blink" : "")} `} style={{ border: (prColor !== "#008000" && prColor !== "#94e094" && prColor !== "#F5F8FF" && prColor !== "" ? "2px solid " + prColor : "") }}> */}
      <div key={props.key} className={`overflow-max vitalClass`}>

        {pr.length != 0 ? pr ? <span>

          {
            paramenterData && paramenterData.map((val, ind) => {


              if (val.parameterId === 55) {
                return (
                  <>
                    <div className="" title='PR Graph' ><i className="fas fa-chart-line text-white pointer" onClick={() => { setGrapVitalData({ showGrap: 1, vitalIdSearchNew: [prId], UHID: uhId, userId: JSON.parse(window.sessionStorage.getItem("LoginData"))['userId'], name: "PR Graph", patientName: props.val.PntName }) }}></i></div>

                    {/* <br /> */}
                  </>

                )
              }


            })

          }




        </span> : 'N/A' : "N/A"}
      </div>

      {grapVitalData.showGrap ? <LineVitalGrap grapVitalData={grapVitalData} modelCloseFun={modelCloseFun} /> : ""}

    </>
  )
}