let UpdateCurrency=async(data)=>{
    let api=window.AdminbaseUrl+`/api/CurrencyMaster/UpdateCurrency?id=${data.id}&currencyName=${data.currencyName}&userId=${window.userId}`;
    let head={"Content-Type": "application/JSON", accept: '*/*'}
    let response = await fetch(api, {
        headers: head,
        method: 'PUT'
      })
        .then((res) => res.json())
        .then();
    
      return response;
}
export default UpdateCurrency;