let postclientdeptassign=async(data)=>{
    const url=window.UserbaseUrl+'/api/Users/SaveClientWiseDepartment';
    let token ='bearer '+ window.AppToken;
    let head={  'Content-Type': 'application/JSON',accept: '*/*', 'Authorization': token}
    let response=await fetch (url,{
        method:'POST',
        headers:head,
        body:JSON.stringify(data)
    }).then((res) => res.json())
    .then();
    return response;

}
export default postclientdeptassign;