async function GetAllItemMaster() {
    let ClientID = JSON.parse(window.sessionStorage.getItem("LoginData")).clientId
    let url = window.BillingbaseUrl + `/api/ItemMaster/GetAllItemMaster?clientId=${ClientID}`;
    let head = { "Content-Type": "application/JSON", accept: '*/*' };
    let data = {}
    let response = fetch(url, {
        headers: head,
        method: 'GET'
    })
        .then((res) => res.json())
        .then(data);

    return response;
}
export default GetAllItemMaster;